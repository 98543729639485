// ############################################################
/**
 * @todo Document this
 */
// ############################################################

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const MoreButton: React.FC<{
  onClick?: () => void
  className?: string
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
}> = ({ onClick, className, type }) => {
  // Hooks and vars
  let buttonType: React.ButtonHTMLAttributes<HTMLButtonElement>['type'] = 'button'

  if (type) {
    buttonType = type
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <button
      type={buttonType}
      onClick={onClick}
      className={`flex items-center text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor ${className}`}
    >
      <span>{`More >`}</span>
    </button>
  )
}
export default MoreButton
