// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import FullScreenDialogCommonComponent from '../../../common/dialogs/full-screen/FullScreenDialogCommonComponent'
import UserRegistrationViewComponent from '../../views/registration/UserRegistrationViewComponent'

interface UserRegistrationDialogComponentProps {
  isOpen?: boolean
  onClose?: any
  userData: any
  onRegistrationStartNextValid: any
  onCompetitorRegistrationUSEFNumberValid: any
  onCompetitorRegistrationProfileValid: any
  onOrganizerRegistrationUSEFNumberValid: any
  onOrganizerRegistrationProfileValid: any
  onCompetitorRegistrationImageChanged: any
  onCompetitionRegistrationCancelImageClick: any
  onVendorRegistrationVendorFormValid: any
  onVendorRegistrationProfileValid: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const UserRegistrationDialogComponent: React.FC<UserRegistrationDialogComponentProps> = (props) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <FullScreenDialogCommonComponent isOpen={props.isOpen} onClose={props.onClose}>
      <UserRegistrationViewComponent
        userData={props.userData}
        onRegistrationStartNextValid={props.onRegistrationStartNextValid}
        onCompetitorRegistrationUSEFNumberValid={props.onCompetitorRegistrationUSEFNumberValid}
        onCompetitorRegistrationProfileValid={props.onCompetitorRegistrationProfileValid}
        onCompetitorRegistrationImageChanged={props.onCompetitorRegistrationImageChanged}
        onCompetitorRegistrationCancelImageClick={props.onCompetitionRegistrationCancelImageClick}
        onOrganizerRegistrationUSEFNumberValid={props.onOrganizerRegistrationUSEFNumberValid}
        onOrganizerRegistrationProfileValid={props.onOrganizerRegistrationProfileValid}
        onVendorRegistrationVendorFormValid={props.onVendorRegistrationVendorFormValid}
        onVendorRegistrationProfileValid={props.onVendorRegistrationProfileValid}
      />
    </FullScreenDialogCommonComponent>
  )
}

UserRegistrationDialogComponent.defaultProps = {
  isOpen: false,
  onClose: null,
  userData: null,
  onRegistrationStartNextValid: null,
  onCompetitorRegistrationUSEFNumberValid: null,
  onCompetitorRegistrationProfileValid: null,
  onOrganizerRegistrationUSEFNumberValid: null,
  onCompetitorRegistrationImageChanged: null,
  onCompetitionRegistrationCancelImageClick: null,
  onOrganizerRegistrationProfileValid: null,
  onVendorRegistrationVendorFormValid: null,
  onVendorRegistrationProfileValid: null,
}

export default UserRegistrationDialogComponent
