import { MESSAGES_CONST } from '../../../../../const/messages-const'
import DataNotAvailable from '../../../../common/alerts/data-not-available/DataNotAvailable'
import EventReviewPublishInput from './EventReviewPublishInput'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const EventPaymentsReviewPublishForm = ({ data }: { data: string }) => {
  if (data === 'LeavePricesAsIs') {
    return (
      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        <EventReviewPublishInput
          className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-80 shrink-0"
          data="Absorb Fees"
        />
        <EventReviewPublishInput
          className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 grow"
          data={'Leave prices as they are. Organizer will pay 5% fee.'}
        />
        <div className="rounded-lg bg-SeabiscuitGrayThemeColor p-1 m-1 h-12 w-12 text-center flex items-center justify-center shrink-0">
          <img className="m-auto" src="/assets/cp_icons/Ok-3.svg" alt="checkIcon" />
        </div>
      </div>
    )
  } else if (data === 'IncreaseAllPrices') {
    return (
      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        <EventReviewPublishInput
          className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-80 shrink-0"
          data="Embed Fees"
        />
        <EventReviewPublishInput
          className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 grow"
          data={'Increase all prices by 5% to cover Pegasus’ fee.'}
        />
        <div className="rounded-lg bg-SeabiscuitGrayThemeColor p-1 m-1 h-12 w-12 text-center flex items-center justify-center shrink-0">
          <img className="m-auto" src="/assets/cp_icons/Ok-3.svg" alt="checkIcon" />
        </div>
      </div>
    )
  } else if (data === 'ChargeRidersAndSpectators') {
    return (
      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        <EventReviewPublishInput
          className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-80 shrink-0"
          data="Split Fees"
        />
        <EventReviewPublishInput
          className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 grow"
          data={'Riders & spectators pay the 2.9% credit card fee. Organizer pays remaining 2.1%.'}
        />
        <div className="rounded-lg bg-SeabiscuitGrayThemeColor p-1 m-1 h-12 w-12 text-center flex items-center justify-center shrink-0">
          <img className="m-auto" src="/assets/cp_icons/Ok-3.svg" alt="checkIcon" />
        </div>
      </div>
    )
  } else {
    return (
      <DataNotAvailable
        mode="text"
        containerClassName="text-SeabiscuitDark200ThemeColor justify-center flex"
        text={MESSAGES_CONST.STAFF_DATA_NOT_AVAILABLE}
      />
    )
  }
}

export default EventPaymentsReviewPublishForm
