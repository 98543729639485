/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react'

// Third-party
import { Add, AddCircleOutline, AutorenewRounded, HighlightOff } from '@mui/icons-material'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import Select from 'react-select'
import clsx from 'clsx'

// Styles
import { customStyles } from '../../components/customStyles/ReactSelectCustomStyle'

import helpers from '../../commonHelpers/helpers'
import CustomDatePicker from '../../components/common/inputs/CustomDatePicker'
import WrapperWithHeader from '../../components/common/wrappers/WrapperWithHeader'
import ViewsLoader from '../../components/loader/ViewsLoader'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import { HandleModalContext } from '../../layout/mainlayout/MainLayout'
import {
  IHorseCompetitorDocument,
  IMeasurementCard,
} from '../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { HorseCompetitonPaperworkModel } from '../../models/horse-competiton-paperwork/horse-competiton-paperwork.model'
import { getConvertedData, getReactPickerDate } from '../../models/interface.helper'
import FirestoreService from '../../services/firestoreService'
import FirebaseStorageService from '../../services/storageService'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  getSelectedHorseCompetitonPaperwork,
  horseSliceThunks,
  selectHorseReducer,
  setSelectedHorseCompetetionPaperwork,
} from '../../store/horses/horseSlice'
import { httpService } from '../../services/httpService'

// Constants
const CardType = [
  { label: 'Combined Driving Pony', value: 'Combined Driving Pony' },
  { label: 'Combined Driving Pony(Temporary)', value: 'Combined Driving Pony(Temporary)' },
  { label: 'Dressage Pony', value: 'Dressage Pony' },
  { label: 'Dressage Pony(Temporary)', value: 'Dressage Pony(Temporary)' },
  { label: 'Hackney Pony, Roadster Pony', value: 'Hackney Pony, Roadster Pony' },
  {
    label: 'Hackney Pony, Roadster Pony(Temporary)',
    value: 'Hackney Pony, Roadster Pony(Temporary)',
  },
  {
    label: 'Hunter / Jumper Horse or Welsh Cob(Standard)',
    value: 'Hunter / Jumper Horse or Welsh Cob(Standard)',
  },
  {
    label: 'Hunter / Jumper Horse or Welsh Cob(Temporary)',
    value: 'Hunter / Jumper Horse or Welsh Cob(Temporary)',
  },
  {
    label: 'Hunter / Jumper Pony or Welsh(Standard)',
    value: 'Hunter / Jumper Pony or Welsh(Standard)',
  },
  {
    label: 'Hunter / Jumper Pony or Welsh Cob(Temporary)',
    value: 'Hunter / Jumper Pony or Welsh Cob(Temporary)',
  },
  { label: 'Saddlebred Pony ', value: 'Saddlebred Pony ' },
  { label: 'Saddlebred Pony(Temporary)', value: 'Saddlebred Pony(Temporary)' },
  { label: 'Welsh Pony Permanent', value: 'Welsh Pony Permanent' },
  { label: 'Welsh Pony Temporary', value: 'Welsh Pony Temporary' },
]

const ShodStatus = [
  { label: 'Unshod', value: 'Unshod' },
  { label: 'Shod All Around', value: 'Shod All Around' },
  { label: 'Shod in Front', value: 'Shod in Front' },
  { label: 'Shod Behind', value: 'Shod Behind' },
]

const HorseHeight = [
  { label: '5', value: '5' },
  { label: '5.1', value: '5.1' },
  { label: '5.2', value: '5.2' },
  { label: '5.3', value: '5.3' },
  { label: '6', value: '6' },
  { label: '6.1', value: '6.1' },
  { label: '6.2', value: '6.2' },
  { label: '6.3', value: '6.3' },
  { label: '7', value: '7' },
  { label: '7.1', value: '7.1' },
  { label: '7.2', value: '7.2' },
  { label: '7.3', value: '7.3' },
  { label: '8', value: '8' },
  { label: '8.1', value: '8.1' },
  { label: '8.2', value: '8.2' },
  { label: '8.3', value: '8.3' },
  { label: '9', value: '9' },
  { label: '9.1', value: '9.1' },
  { label: '9.2', value: '9.2' },
  { label: '9.3', value: '9.3' },
  { label: '10', value: '10' },
  { label: '10.1', value: '10.1' },
  { label: '10.2', value: '10.2' },
  { label: '10.3', value: '10.3' },
  { label: '11', value: '11' },
  { label: '11.1', value: '11.1' },
  { label: '11.2', value: '11.2' },
  { label: '11.3', value: '11.3' },
  { label: '12', value: '12' },
  { label: '12.1', value: '12.1' },
  { label: '12.2', value: '12.2' },
  { label: '12.3', value: '12.3' },
  { label: '13', value: '13' },
  { label: '13.1', value: '13.1' },
  { label: '13.2', value: '13.2' },
  { label: '13.3', value: '13.3' },
  { label: '14', value: '14' },
  { label: '14.1', value: '14.1' },
  { label: '14.2', value: '14.2' },
  { label: '14.3', value: '14.3' },
  { label: '15', value: '15' },
  { label: '15.1', value: '15.1' },
  { label: '15.2', value: '15.2' },
  { label: '15.3', value: '15.3' },
  { label: '16', value: '16' },
  { label: '16.1', value: '16.1' },
  { label: '16.2', value: '16.2' },
  { label: '16.3', value: '16.3' },
  { label: '17', value: '17' },
  { label: '17.1', value: '17.1' },
  { label: '17.2', value: '17.2' },
  { label: '17.3', value: '17.3' },
  { label: '18', value: '18' },
  { label: '18.1', value: '18.1' },
  { label: '18.2', value: '18.2' },
  { label: '18.3', value: '18.3' },
  { label: '19', value: '19' },
  { label: '19.1', value: '19.1' },
  { label: '19.2', value: '19.2' },
  { label: '19.3', value: '19.3' },
  { label: '20', value: '20' },
  { label: '20.1', value: '20.1' },
  { label: '20.2', value: '20.2' },
  { label: '20.3', value: '20.3' },
  { label: '21', value: '21' },
  { label: '21.1', value: '21.1' },
  { label: '21.2', value: '21.2' },
  { label: '21.3', value: '21.3' },
  { label: '22', value: '22' },
  { label: '22.1', value: '22.1' },
  { label: '22.2', value: '22.2' },
  { label: '22.3', value: '22.3' },
  { label: '23', value: '23' },
  { label: '23.1', value: '23.1' },
  { label: '23.2', value: '23.2' },
  { label: '23.3', value: '23.3' },
  { label: '24', value: '24' },
  { label: '24.1', value: '24.1' },
  { label: '24.2', value: '24.2' },
  { label: '24.3', value: '24.3' },
  { label: '25', value: '25' },
]

const HeelSize = [
  { label: 'N/A', value: 'N/A' },
  { label: '1/8', value: '1/8' },
  { label: '1/4', value: '1/4' },
  { label: '3/8', value: '3/8' },
  { label: '1/2', value: '1/2' },
  { label: '5/8', value: '5/8' },
  { label: '3/4', value: '3/4' },
  { label: '7/8', value: '7/8' },
  { label: '1', value: '1' },
  { label: '1 1/8', value: '1 1/8' },
  { label: '1 1/4', value: '1 1/4' },
  { label: '1 3/8', value: '1 3/8' },
  { label: '1 1/2', value: '1 1/2' },
  { label: '1 5/8', value: '1 5/8' },
  { label: '1 3/4', value: '1 3/4' },
  { label: '1 7/8', value: '1 7/8' },
  { label: '2', value: '2' },
  { label: '2 1/8', value: '2 1/8' },
  { label: '2 1/4', value: '2 1/4' },
  { label: '2 3/8', value: '2 3/8' },
  { label: '2 1/2', value: '2 1/2' },
  { label: '2 5/8', value: '2 5/8' },
  { label: '2 3/4', value: '2 3/4' },
  { label: '2 7/8', value: '2 7/8' },
  { label: '3', value: '3' },
  { label: '3 1/8', value: '3 1/8' },
  { label: '3 1/4', value: '3 1/4' },
  { label: '3 3/8', value: '3 3/8' },
  { label: '3 1/2', value: '3 1/2' },
  { label: '3 5/8', value: '3 5/8' },
  { label: '3 3/4', value: '3 3/4' },
  { label: '3 7/8', value: '3 7/8' },
  { label: '4', value: '4' },
  { label: '4 1/8', value: '4 1/8' },
  { label: '4 1/4', value: '4 1/4' },
  { label: '4 3/8', value: '4 3/8' },
  { label: '4 1/2', value: '4 1/2' },
  { label: '4 5/8', value: '4 5/8' },
  { label: '4 3/4', value: '4 3/4' },
  { label: '4 7/8', value: '4 7/8' },
]

type Props = {
  handleModal?: any
  setActiveTab?: any
}

type IOnRemoveImageButtonClickFn = (args: IOnRemoveImageButtonClickProps) => Promise<void>

type IOnRemoveImageButtonClickProps = {
  index: number
  rowIndex?: number
  type:
    | 'horseMeasurementCardsImage'
    | 'horseVaccinationRecordsImages'
    | 'horseHealthRecordCertificatesImages'
    | 'horseCogginsInspectionCertificate'
}

type ISubmitFn = (data: any, keepUnsaved?: boolean) => Promise<void>

const HorseHealthStatus = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
]

const HorseVaccinationStatus = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
]

const HorseRhinopneumonitisStatus = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
]

const HorseInfluenzaStatus = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
]

// const FILE_NAME = "HorseRootEditCompetitionNumbersTab";
// const customErrorProps = {
//   fileName: FILE_NAME,
//   message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
// };

const defaultValue = new HorseCompetitonPaperworkModel({
  ...new HorseCompetitonPaperworkModel().toObject(),
  horseMeasurementCards: [
    {
      horseShodStatus: null,
      horseMeasurementDate: null,
      horseHeightMeasurement: null,
      horseMeasurementCardType: null,
      horseLeftHeelMeasurement: null,
      horseRightHeelMeasurement: null,
      horseMeasurementCardsImage: [],
    },
  ],
}).toObject()

const getImage = (image: string) => {
  return image.includes('&format=pdf')
    ? { type: 'file', url: '/assets/img/pdf.png' }
    : { type: 'image', url: image }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const HorseRootEditCompetitionNumbersTab = (props: Props) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const [saved, isSaved] = useState(true)
  const toastFunctions = useToasterHelper()
  const horseReducer = useAppSelector(selectHorseReducer)
  const handleModal = useContext(HandleModalContext)?.handleModal
  const horsesPaperwork = useAppSelector(getSelectedHorseCompetitonPaperwork)

  const selectedHorse = horseReducer.selected.horse.data
  const selectedHorseMapping = horseReducer.selected.mapping.data
  const selectedHorsePaperwork = horseReducer.selected.paperwork.data

  const imagesUrlsToDeleteFromStorage = useRef<string[]>([])

  const { control, handleSubmit, reset, watch, setValue } = useForm<IHorseCompetitorDocument>({
    mode: 'onChange',
    defaultValues: horsesPaperwork ?? defaultValue,
  })

  const horseMeasurementCards = useFieldArray({
    control,
    name: 'horseMeasurementCards',
  })

  const [loading, setLoading] = useState(false)

  const handlemanageToastConfirm = (
    index: number,
    type: IOnRemoveImageButtonClickProps['type'],
    rowIndex?: number
  ) => {
    handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
      removeItem: async function () {
        await onRemoveImageButtonClick({
          rowIndex,
          type,
          index,
        })
      },
    })
  }

  const horseMeasurementCardIniVal: IMeasurementCard = {
    horseShodStatus: null,
    horseMeasurementDate: null,
    horseHeightMeasurement: null,
    horseMeasurementCardType: null,
    horseLeftHeelMeasurement: null,
    horseRightHeelMeasurement: null,
    horseMeasurementCardsImage: [],
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (
      !!!horseReducer.selected.mapping.data?.id ||
      horseReducer.selected.mapping.data.id === selectedHorsePaperwork?.id
    )
      return

    dispatch(
      horseSliceThunks.getHorsePaperworkThunk({
        userHorseMappingDocId: horseReducer.selected.mapping.data?.id,
      })
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        let paperwork = (data.payload as any).paperwork as IHorseCompetitorDocument
        reset({
          ...paperwork,
          horseMeasurementCards: paperwork?.horseMeasurementCards?.length
            ? paperwork?.horseMeasurementCards ?? []
            : defaultValue.horseMeasurementCards,
        })
      }
    })
  }, [horseReducer.selected.mapping.data?.id])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const validateAction = (): boolean => {
    if (!selectedHorse) {
      toastFunctions.error({
        message: MESSAGES_CONST.PLEASE_CREATE_HORSE_FIRST,
      })
      return false
    }

    if (!!!selectedHorse?.id || !!!selectedHorseMapping?.id) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      return false
    }

    return true
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5
  /**
   * @param data Updates horse in db
   */
  const onSubmit: ISubmitFn = async (data, keepUnsaved = false) => {
    if (!validateAction()) return

    data = {
      ...data,
      id: selectedHorsePaperwork?.id,
      horseId: selectedHorse!.horseId,
      horseOwnerId: selectedHorse!.horseOwnerId,
    }

    setLoading(true)

    let horseCompetitionPaperworkModel = new HorseCompetitonPaperworkModel(data)

    if (imagesUrlsToDeleteFromStorage.current.length) {
      await httpService({
        method: 'POST',
        url: 'remove_images',
        data: {
          urls: imagesUrlsToDeleteFromStorage.current,
        },
      })
    }

    imagesUrlsToDeleteFromStorage.current = []

    try {
      if (!!selectedHorsePaperwork?.id) {
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSE_COMPETITION_PAPERWORK.NAME,
          selectedHorsePaperwork?.id,
          horseCompetitionPaperworkModel.toFirestore()
        )
      } else {
        horseCompetitionPaperworkModel.id = selectedHorseMapping?.id
        await FirestoreService.createItemWithCustomId(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSE_COMPETITION_PAPERWORK.NAME,
          selectedHorseMapping?.id!,
          horseCompetitionPaperworkModel.toFirestore()
        )
      }

      dispatch(
        setSelectedHorseCompetetionPaperwork({
          competitionPaperwork: getConvertedData(horseCompetitionPaperworkModel.toObject()),
        })
      )

      toastFunctions.success({
        message: MESSAGES_CONST.COMPETITOR_UPDATE_SUCCESSFULLY,
      })
    } catch (err: any) {
      helpers.logger({
        isError: true,
        message: err,
      })
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }

    setLoading(false)
    if (keepUnsaved !== true) {
      isSaved(true)
    }
  }

  const imageSetter = async (key: any, imageUrls: string[]) => {
    setValue(key, [...(watch(key) ?? []), ...imageUrls])

    await onSubmit(watch(), true)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onUploadCogginsCertificateClick = () => {
    if (!validateAction() || saved) return

    props.handleModal(true, MODAL_CONSTS.ADD_COGGINS_CERTIFICATE_IMAGE, {
      fileUploadType: 'horseCogginsInspectionCertificate',
      data: watch(),
      setter: imageSetter,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onUploadVaccinationRecordsClick = () => {
    if (!validateAction() || saved) return

    props.handleModal(true, MODAL_CONSTS.ADD_COGGINS_CERTIFICATE_IMAGE, {
      fileUploadType: 'horseVaccinationRecordsImages',
      data: watch(),
      setter: imageSetter,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onAddHorseMeasurementCardsClick = (rowIndex: number) => {
    if (!validateAction() || saved) return
    props.handleModal(true, MODAL_CONSTS.ADD_COGGINS_CERTIFICATE_IMAGE, {
      fileUploadType: 'horseMeasurementCardsImage',
      data: watch(),
      setter: async (_: any, imageUrls: string[]) => {
        setValue(`horseMeasurementCards.${rowIndex}.horseMeasurementCardsImage`, [
          ...(watch(`horseMeasurementCards.${rowIndex}.horseMeasurementCardsImage`) ?? []),
          ...imageUrls,
        ])
        await onSubmit(watch(), true)
      },
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onAddHorseHeadRecordImagesClick = () => {
    if (!validateAction() || saved) return

    props.handleModal(true, MODAL_CONSTS.ADD_COGGINS_CERTIFICATE_IMAGE, {
      fileUploadType: 'horseHealthRecordCertificatesImages',
      data: watch(),
      setter: imageSetter,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onAddMeasurementCardClick = () => {
    if (!validateAction() || saved) return

    horseMeasurementCards.append(horseMeasurementCardIniVal)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onRemoveImageButtonClick: IOnRemoveImageButtonClickFn = async (args) => {
    let imageUrl: string | null = null
    let horsesPaperwork = { ...watch() }
    const { type, index, rowIndex } = args

    try {
      switch (type) {
        case 'horseHealthRecordCertificatesImages':
          imageUrl = watch('horseHealthRecordCertificatesImages')?.[index]!
          horsesPaperwork.horseHealthRecordCertificatesImages?.splice?.(index, 1)
          await FirebaseStorageService.deleteFile(imageUrl)
          break

        case 'horseMeasurementCardsImage':
          if (typeof rowIndex === 'number') {
            imageUrl = watch(`horseMeasurementCards.${rowIndex}.horseMeasurementCardsImage`)[index]
            horsesPaperwork.horseMeasurementCards?.[rowIndex].horseMeasurementCardsImage?.splice?.(
              index,
              1
            )
            await FirebaseStorageService.deleteFile(imageUrl)
          }
          break

        case 'horseVaccinationRecordsImages':
          imageUrl = watch(`horseVaccinationRecordsImages`)?.[index]!
          horsesPaperwork.horseVaccinationRecordsImages?.splice?.(index, 1)
          await FirebaseStorageService.deleteFile(imageUrl)
          break

        case 'horseCogginsInspectionCertificate':
          imageUrl = watch(`horseCogginsInspectionCertificate`)?.[index]!
          horsesPaperwork.horseCogginsInspectionCertificate?.splice?.(index, 1)
          await FirebaseStorageService.deleteFile(imageUrl)
          break
      }

      await onSubmit(horsesPaperwork, true)

      dispatch(
        setSelectedHorseCompetetionPaperwork({
          competitionPaperwork: getConvertedData(horsesPaperwork),
        })
      )
    } catch (error) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  const onRemoveMeasurementCardButtonClick = (index: number) => {
    imagesUrlsToDeleteFromStorage.current = [
      ...imagesUrlsToDeleteFromStorage.current,
      ...(watch('horseMeasurementCards')?.[index]?.horseMeasurementCardsImage ?? []),
    ]
    horseMeasurementCards.remove(index)
  }

  return (
    <WrapperWithHeader
      title="Paperwork"
      description="Add, edit or remove horses paperwork"
      onSubmit={(e: any) =>
        handleSubmit(
          (data) => {
            onSubmit(data, false)
          },
          () => {}
        )(e)
      }
      headerButtonsContainer={
        <div className="flex items-center gap-2">
          {saved ? (
            <button
              type="button"
              onClick={() => isSaved(false)}
              className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
            >
              Edit
            </button>
          ) : (
            <button
              type="submit"
              disabled={horseReducer.selected.paperwork.status === 'loading' || loading}
              className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor saveBtn"
            >
              {loading ? (
                <AutorenewRounded fontSize="small" className="animate-spin" />
              ) : (
                'Save Changes'
              )}
            </button>
          )}
        </div>
      }
    >
      {horseReducer.selected.paperwork.status === 'loading' ? (
        <ViewsLoader className="flex items-center w-full justify-center" size="xl" color="red" />
      ) : (
        <>
          <div className="flex gap-4">
            <div className="flex flex-col justify-between w-1/5 flex-shrink-0">
              <div className="flex items-start mt-2 w-full">
                <label className="flex items-center mr-4 mt-4 w-1/5 text-SeabiscuitDark200ThemeColor text-sm">
                  <img
                    src="/assets/img/dark/Ruler.png"
                    className="mr-4 w-6"
                    alt="measurementCard"
                  />
                  Measurement card
                </label>
              </div>

              {!saved && (
                <div
                  onClick={onAddMeasurementCardClick}
                  className="flex items-center cursor-pointer mt-4"
                >
                  <AddCircleOutline className="text-SeabiscuitMainThemeColor" fontSize="small" />

                  <p className="ml-2 text-SeabiscuitMainThemeColor text-sm">Add measurement card</p>
                </div>
              )}
            </div>

            <div className="wrapper flex flex-col gap-4 flex-grow py-2">
              {horseMeasurementCards.fields.map((field: any, index) => {
                return (
                  <React.Fragment key={field.id}>
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-2">
                        {watch(`horseMeasurementCards.${index}.horseMeasurementCardsImage`)
                          .length ? (
                          <div className="flex gap-2">
                            {watch(
                              `horseMeasurementCards.${index}.horseMeasurementCardsImage`
                            )?.map((item: string, imageIndex: number) => {
                              return (
                                <div
                                  key={`horseMeasurementCardsImage${field.id}${imageIndex}`}
                                  className={clsx(
                                    'image-preview rounded-md w-24 h-24 z-[0] relative',
                                    { border: getImage(item).type === 'image' }
                                  )}
                                >
                                  <img
                                    src={getImage(item).url}
                                    onClick={() => window.open(item, '_blank')}
                                    className="w-full h-full object-cover mr-2 shrink-0 cursor-pointer rounded-md"
                                    alt="horseMeasurementCardsImage"
                                  />
                                  {!saved ? (
                                    <button
                                      onClick={() => {
                                        handlemanageToastConfirm(
                                          imageIndex,
                                          'horseMeasurementCardsImage',
                                          index
                                        )
                                      }}
                                      type="button"
                                      className="absolute top-[-9px] opacity-100 cursor-pointer hover:opacity-100 transition-all right-[-9px] flex items-center justify-center z-[1]"
                                    >
                                      <HighlightOff className="text-SeabiscuitDark200ThemeColor !z-[9999]" />
                                    </button>
                                  ) : null}
                                </div>
                              )
                            })}
                          </div>
                        ) : null}

                        {!saved ? (
                          <div
                            className="flex items-center gap-2 cursor-pointer"
                            onClick={() => onAddHorseMeasurementCardsClick(index)}
                          >
                            <div className="p-1 border border-[#E6EAF2] rounded-md flex items-center">
                              <Add className="text-SeabiscuitDark200ThemeColor flex items-center" />
                            </div>
                            <div className="text-SeabiscuitDark200ThemeColor text-sm">
                              Add image
                            </div>
                          </div>
                        ) : null}
                      </div>

                      <div className="flex flex-wrap gap-2">
                        <Controller
                          name={`horseMeasurementCards.${index}.horseMeasurementCardType`}
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => {
                            return (
                              <label className="text-sm text-gray-400">
                                Card Type
                                <Select
                                  isMulti={false}
                                  onChange={onChange}
                                  value={CardType.find(
                                    (c) => c.value === ((value as any)?.value ?? value)
                                  )}
                                  onBlur={onBlur}
                                  placeholder={<div className="flex text-[13px]">Select</div>}
                                  options={CardType}
                                  isDisabled={saved}
                                  className={`border-[#D3DAEE] border rounded-md w-40 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                                  isClearable={false}
                                  styles={customStyles}
                                  isSearchable={false}
                                />
                              </label>
                            )
                          }}
                        />

                        {/* Measurement date... */}

                        <Controller
                          name={`horseMeasurementCards.${index}.horseMeasurementDate`}
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => {
                            return (
                              <div className="flex flex-col">
                                <label className="text-sm text-gray-400">Measurement Date</label>
                                <CustomDatePicker
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={getReactPickerDate(value)}
                                  peekNextMonth
                                  dropdownMode="select"
                                  wrapperClassName="w-auto"
                                  showMonthDropdown
                                  showYearDropdown
                                  disabled={saved}
                                  placeholderText="Select"
                                  className={`rounded-md border-[#D3DAEE] border focus:ring-SeabiscuitMainThemeColor  placeholder:text-SeabiscuitLightParagraphTextColor text-SeabiscuitDark200ThemeColor placeholder:text-sm opacity-1 py-3 pl-10 !text-sm w-30 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor pt-2 placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                                />
                              </div>
                            )
                          }}
                        />

                        {/* Height measured... */}

                        <Controller
                          name={`horseMeasurementCards.${index}.horseHeightMeasurement`}
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <label className="text-sm text-gray-400">
                              Height Measured
                              <Select
                                isMulti={false}
                                onChange={onChange}
                                value={HorseHeight.find(
                                  (c) => c.value === ((value as any)?.value ?? value)
                                )}
                                onBlur={onBlur}
                                placeholder={<div className="flex text-[13px] p-0">Select</div>}
                                options={HorseHeight}
                                isDisabled={saved}
                                className={`border-[#D3DAEE] border rounded-md min-w-[125px] text-[2px] ${saved ? '!border-white  text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                                isClearable={false}
                                styles={customStyles}
                                isSearchable={false}
                              />
                            </label>
                          )}
                        />

                        {/* Left heel size... */}

                        <Controller
                          name={`horseMeasurementCards.${index}.horseLeftHeelMeasurement`}
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <label className="text-sm text-gray-400">
                              Left Heel Size
                              <Select
                                isMulti={false}
                                onChange={onChange}
                                value={HeelSize.find(
                                  (c) => c.value === ((value as any)?.value ?? value)
                                )}
                                onBlur={onBlur}
                                placeholder={<div className="flex text-[13px]">Select</div>}
                                options={HeelSize}
                                className={`border-[#D3DAEE] border rounded-md min-w-[125px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                                isClearable={false}
                                isDisabled={saved}
                                styles={customStyles}
                                isSearchable={false}
                              />
                            </label>
                          )}
                        />

                        {/* Right heel size... */}
                        <Controller
                          name={`horseMeasurementCards.${index}.horseRightHeelMeasurement`}
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <label className="text-sm text-gray-400">
                              Right Heel Size
                              <Select
                                isMulti={false}
                                onChange={onChange}
                                value={HeelSize.find(
                                  (c) => c.value === ((value as any)?.value ?? value)
                                )}
                                onBlur={onBlur}
                                placeholder={<div className="flex text-[13px]">Select</div>}
                                options={HeelSize}
                                isDisabled={saved}
                                className={`border-[#D3DAEE] border rounded-md min-w-[125px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                                isClearable={false}
                                styles={customStyles}
                                isSearchable={false}
                              />
                            </label>
                          )}
                        />

                        {/* Shod status... */}
                        <Controller
                          name={`horseMeasurementCards.${index}.horseShodStatus`}
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <label className="text-sm text-gray-400">
                              Shod Status
                              <Select
                                isMulti={false}
                                onChange={onChange}
                                value={ShodStatus.find(
                                  (c) => c.value === ((value as any)?.value ?? value)
                                )}
                                onBlur={onBlur}
                                placeholder={<div className="flex text-[13px]">Select</div>}
                                isDisabled={saved}
                                options={ShodStatus}
                                className={`border-[#D3DAEE] border rounded-md w-40 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                                isClearable={false}
                                styles={customStyles}
                                isSearchable={false}
                              />
                            </label>
                          )}
                        />

                        {!saved && index ? (
                          <div
                            onClick={() => onRemoveMeasurementCardButtonClick(index)}
                            className="cursor-pointer flex mt-[18px]"
                          >
                            <img src={'assets/og_icons/Cancel.svg'} alt="cancel" />
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {horseMeasurementCards.fields.length - 1 > index ? (
                      <hr className="w-full" />
                    ) : null}
                  </React.Fragment>
                )
              })}
            </div>
          </div>

          <hr className="w-full my-6" />

          <div className="flex items-center mt-2 w-full gap-4">
            <label className="flex items-center w-1/5 text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
              <img src="/assets/img/dark/Cloud.png" className="mr-4 w-6" alt="measurementCard" />
              Upload Coggins Certificate
            </label>
            <div className="wrapper flex flex-col gap-4 flex-grow py-2">
              <div className="flex flex-wrap rounded-md relative gap-2">
                {(watch('horseCogginsInspectionCertificate') ?? []).map(
                  (item: string, index: number) => {
                    return (
                      <div
                        key={`horseCogginsInspectionCertificate${item}${index}`}
                        className={clsx('image-preview rounded-md w-24 h-24 z-[0] relative', {
                          border: getImage(item).type === 'image',
                        })}
                      >
                        <img
                          src={getImage(item).url}
                          className="w-full h-full object-cover mr-2 shrink-0 cursor-pointer rounded-md"
                          alt="horseCogginsInspectionCertificate"
                          onClick={() => window.open(item, '_blank')}
                        />
                        {!saved ? (
                          <button
                            onClick={() => {
                              handlemanageToastConfirm(index, 'horseCogginsInspectionCertificate')
                            }}
                            type="button"
                            className="absolute top-[-9px] opacity-100 cursor-pointer hover:opacity-100 transition-all right-[-9px] flex items-center justify-center"
                          >
                            <HighlightOff className="text-SeabiscuitDark200ThemeColor !z-[9999]" />
                          </button>
                        ) : null}
                      </div>
                    )
                  }
                )}
              </div>

              {!saved ? (
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={onUploadCogginsCertificateClick}
                >
                  <div className="p-1 border border-[#E6EAF2] rounded-md flex items-center">
                    <Add className="text-SeabiscuitDark200ThemeColor flex items-center" />
                  </div>
                  <div className="text-SeabiscuitDark200ThemeColor text-sm">Add image</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex items-center mt-2 w-full gap-4">
            <label className="flex items-center w-1/5 text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
              <img
                src="/assets/img/dark/Certificate.png"
                className="mr-4 w-6"
                alt="measurementCard"
              />
              Coggins inspection date
            </label>
            <div className="wrapper flex flex-wrap w-4/5">
              <Controller
                name={`horseCogginsInspectionDate`}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <CustomDatePicker
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={getReactPickerDate(value)}
                      peekNextMonth
                      dropdownMode="select"
                      wrapperClassName="w-auto"
                      disabled={saved}
                      showMonthDropdown
                      popperPlacement="bottom"
                      showYearDropdown
                      placeholderText="Measurement date"
                      className={`rounded-md border-[#D3DAEE] border focus:ring-SeabiscuitMainThemeColor  placeholder:text-SeabiscuitLightParagraphTextColor text-SeabiscuitDark200ThemeColor placeholder:text-sm opacity-1 py-2.5 pl-4 !text-sm mr-2  w-60 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    />
                  )
                }}
              />
            </div>
          </div>

          <hr className="w-full my-6" />

          {/* Health related fields */}

          <div className="flex items-center mt-2 w-full gap-4">
            <label className="flex items-center w-1/5 text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
              <img src="/assets/img/dark/Cloud.png" className="mr-4 w-6" alt="measurementCard" />
              Upload health certificate records
            </label>

            <div className="wrapper flex flex-col gap-4 flex-grow py-2">
              <div className="flex flex-wrap rounded-md relative gap-2">
                {(watch('horseHealthRecordCertificatesImages') ?? []).map(
                  (item: string, index: number) => {
                    return (
                      <div
                        key={`horseHealthRecordCertificatesImages${item}${index}`}
                        className={clsx('image-preview rounded-md w-24 h-24 z-[0] relative', {
                          border: getImage(item).type === 'image',
                        })}
                      >
                        <img
                          src={getImage(item).url}
                          className="w-full h-full object-cover mr-2 shrink-0 cursor-pointer rounded-md"
                          alt="horseHealthRecordCertificatesImages"
                          onClick={() => window.open(item, '_blank')}
                        />
                        {!saved ? (
                          <button
                            onClick={() => {
                              handlemanageToastConfirm(index, 'horseHealthRecordCertificatesImages')
                            }}
                            type="button"
                            className="absolute top-[-9px] opacity-100 cursor-pointer hover:opacity-100 transition-all right-[-9px] flex items-center justify-center"
                          >
                            <HighlightOff className="text-SeabiscuitDark200ThemeColor !z-[9999]" />
                          </button>
                        ) : null}
                      </div>
                    )
                  }
                )}
              </div>

              {!saved ? (
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={onAddHorseHeadRecordImagesClick}
                >
                  <div className="p-1 border border-[#E6EAF2] rounded-md flex items-center ">
                    <Add className="text-SeabiscuitDark200ThemeColor flex items-center" />
                  </div>
                  <div className="text-SeabiscuitDark200ThemeColor text-sm">Add image</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex items-center mt-2 w-full gap-4">
            <label className="flex items-center w-1/5 text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
              <img
                src="/assets/img/dark/Certificate.png"
                className="mr-4 w-6"
                alt="measurementCard"
              />
              Health status current?
            </label>
            <div className="wrapper flex flex-wrap w-4/5">
              <Controller
                name="horseHealthStatus"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    isMulti={false}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={HorseHealthStatus}
                    value={HorseHealthStatus.find(
                      (c) => c.value === ((value as any)?.value ?? value)
                    )}
                    placeholder="Select..."
                    isDisabled={saved}
                    className={`border-[#D3DAEE] border rounded-md w-60 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={true}
                  />
                )}
              />
            </div>
          </div>

          <div className="flex items-center mt-[15px] w-full gap-4">
            <label className="flex items-center w-1/5 text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
              <img src="/assets/img/dark/Cloud.png" className="mr-4 w-6" alt="measurementCard" />
              Upload vaccination records
            </label>

            <div className="wrapper flex flex-col gap-4 flex-grow py-2">
              <div className="flex flex-wrap rounded-md relative gap-2">
                {(watch('horseVaccinationRecordsImages') ?? []).map(
                  (item: string, index: number) => {
                    return (
                      <div
                        key={`horseVaccinationRecordsImages${item}${index}`}
                        className={clsx('image-preview rounded-md w-24 h-24 z-[0] relative', {
                          border: getImage(item).type === 'image',
                        })}
                      >
                        <img
                          src={getImage(item).url}
                          className="w-full h-full object-cover mr-2 shrink-0 cursor-pointer rounded-md"
                          alt="horseVaccinationRecordsImages"
                          onClick={() => window.open(item, '_blank')}
                        />
                        {!saved ? (
                          <button
                            onClick={() => {
                              handlemanageToastConfirm(index, 'horseVaccinationRecordsImages')
                            }}
                            type="button"
                            className="absolute top-[-9px] opacity-100 cursor-pointer hover:opacity-100 transition-all right-[-9px] flex items-center justify-center"
                          >
                            <HighlightOff className="text-SeabiscuitDark200ThemeColor !z-[9999]" />
                          </button>
                        ) : null}
                      </div>
                    )
                  }
                )}
              </div>

              {!saved ? (
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={onUploadVaccinationRecordsClick}
                >
                  <div className="p-1 border border-[#E6EAF2] rounded-md flex items-center">
                    <Add className="text-SeabiscuitDark200ThemeColor flex items-center" />
                  </div>
                  <div className="text-SeabiscuitDark200ThemeColor text-sm">Add image</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex items-center mt-2 w-full gap-4">
            <label className="flex items-center w-1/5 text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
              <img
                src="/assets/og_icons/InsulinPen.svg"
                className="mr-4 w-6"
                alt="measurementCard"
              />
              Vaccination current?
            </label>
            <Controller
              name="horseCurrentVaccinationStatus"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  isMulti={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={HorseVaccinationStatus.find(
                    (c) => c.value === ((value as any)?.value ?? value)
                  )}
                  placeholder="Select..."
                  options={HorseVaccinationStatus}
                  isDisabled={saved}
                  className={`border-[#D3DAEE] border rounded-md w-60 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  isClearable={false}
                  styles={customStyles}
                  isSearchable={true}
                />
              )}
            />
          </div>

          <div className="flex items-center mt-2 w-full gap-4">
            <label className="flex items-center w-1/5 text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
              <img
                src="/assets/og_icons/InsulinPen.svg"
                className="mr-4 w-6"
                alt="measurementCard"
              />
              Rhinopneumonitis current?
            </label>
            <Controller
              name="horseRhinopneumonitisCurrentStatus"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  isMulti={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={HorseRhinopneumonitisStatus.find(
                    (c) => c.value === ((value as any)?.value ?? value)
                  )}
                  placeholder="Select..."
                  options={HorseRhinopneumonitisStatus}
                  isDisabled={saved}
                  className={`border-[#D3DAEE] border rounded-md w-60 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  isClearable={false}
                  styles={customStyles}
                  isSearchable={true}
                />
              )}
            />
          </div>

          <div className="flex items-center mt-2 w-full mb-8 gap-4">
            <label className="flex items-center w-1/5 text-SeabiscuitDark200ThemeColor text-sm flex-shrink-0">
              <img
                src="/assets/og_icons/InsulinPen.svg"
                className="mr-4 w-6"
                alt="measurementCard"
              />
              Influenza current?
            </label>
            <Controller
              name="horseInfluenzaStatus"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  isMulti={false}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={HorseInfluenzaStatus.find(
                    (c) => c.value === ((value as any)?.value ?? value)
                  )}
                  placeholder="Select..."
                  menuPlacement="top"
                  options={HorseInfluenzaStatus}
                  isDisabled={saved}
                  className={`border-[#D3DAEE] border rounded-md w-60 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  isClearable={false}
                  styles={customStyles}
                  isSearchable={true}
                />
              )}
            />
          </div>
        </>
      )}
    </WrapperWithHeader>
  )
}

export default HorseRootEditCompetitionNumbersTab
