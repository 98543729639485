// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useContext, useState } from 'react'
import { SignupStages } from './UserRegistrationViewComponentContextApi'
import UserRegistrationViewComponentButton from './global/UserRegistrationViewComponentButton'
import UserRegistrationViewComponentInput from './global/UserRegistrationViewComponentInput'

import backIcon from './assets/arrow-left.svg'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const Prototype03Invite: React.FC = () => {
  const [stage, setStage] = useContext(SignupStages)

  const [inviteList, setInviteList] = useState([{ email: '' }])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div>
      <div className="text-[24px] flex items-center gap-2">
        <button onClick={() => stageHandle('Init')}>
          <img src={backIcon} alt="back" />
        </button>
        Invite team members
      </div>
      <div className="mt-6">
        <p>SEND INVITATIONS TO TEAM MEMBERS TO JOIN PEGASUS</p>
      </div>
      <div className="mt-6 flex flex-col gap-2">
        {inviteList.map((item, index) => (
          <UserRegistrationViewComponentInput
            type="email"
            value={item.email}
            onChange={(e: any) =>
              setInviteList(
                inviteList.map((val, i) => (i === index ? { email: e.target.value } : val))
              )
            }
            placeholder="abc@email.com"
            icon="/assets/og_icons/Mail-4.svg"
          />
        ))}
      </div>
      <div className="text-center m-4">
        <button onClick={() => setInviteList([...inviteList, { email: '' }])}>
          <img src={`/assets/og_icons/Cancel-5.svg`} className=" rotate-45" alt="plus icon" />
        </button>
      </div>
      <div className="w-[70%] mt-[60px] mx-auto">
        <UserRegistrationViewComponentButton
          caption="SEND INVITES"
          type={1}
          disabled={!stage.selector}
          onClick={() => stageHandle('EventOrgVerify')}
        />
      </div>
    </div>
  )
}

export default Prototype03Invite
