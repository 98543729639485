import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

import {
  ISelectedEvent,
  initialSystemEventsState,
  selectedEvent,
  setBasicEventDetails,
} from '../../../store/events/eventsSlice'
import useGetEventData from './useGetEventData'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants
const BASIC_EVENT_DETAILS = initialSystemEventsState.selectedEvent.basicEventDetails

const useGetBasicEventDetails = (eventId: string | null) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const [basicEventDetailsLocal, setBasicEventDetailsLocal] =
    useState<ISelectedEvent['basicEventDetails']>(BASIC_EVENT_DETAILS)

  const { getAllData } = useGetEventData()

  const selectedEvent_ = useAppSelector(selectedEvent)

  useEffect(() => {
    if (!eventId) return

    // getAllData(eventId, ['v01_events', 'v01_event_fees'])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  useEffect(() => {
    const event = selectedEvent_.Event
    const eventFees = selectedEvent_.EventFees
    const eventDetails = selectedEvent_.EventDetails

    if (!eventId || !event || !eventFees) return

    const basicEventDetails_: ISelectedEvent['basicEventDetails'] = {
      id: eventId,
      owner: event.owner ?? null,
      status: event.status ?? null,
      created: event.created ?? null,
      modified: event.modified ?? null,
      registrationPrice: null,
      registrationPriceAlias: null,
      description: !!eventDetails?.summaryLong ? eventDetails?.summaryLong : 'N/A',
    }

    setBasicEventDetailsLocal(basicEventDetails_)
    dispatch(setBasicEventDetails(basicEventDetails_))
  }, [
    selectedEvent_.Event,
    selectedEvent_.EventFees,
    selectedEvent_.EventDetails,
    eventId,
    dispatch,
  ])

  return basicEventDetailsLocal
}

export default useGetBasicEventDetails
