// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useContext, useState } from 'react'
import { SignupStages } from './UserRegistrationViewComponentContextApi'
import UserRegistrationViewComponentButton from './global/UserRegistrationViewComponentButton'
import UserRegistrationViewComponentInput from './global/UserRegistrationViewComponentInput'

import locationIcon from './assets/address.svg'
import backIcon from './assets/arrow-left.svg'
import businessCat from './assets/category.svg'
import websiteIcon from './assets/location.svg'
import phoneIcon from './assets/phone-icon.svg'
import uploadIcon from './assets/upload.svg'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface UserRegistrationViewComponentVendorFormProps {
  onVendorRegistrationVendorFormValid: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const UserRegistrationViewComponentVendorForm: React.FC<
  UserRegistrationViewComponentVendorFormProps
> = (props) => {
  const [stage, setStage] = useContext(SignupStages)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  const [vendorForm, setVendorForm] = useState({
    businessName: '',
    businessCat: '',
    location: '',
    website: '',
    phoneNumber: '',
    email: '',
    logo: '',
    // password: "",
    // password2: ""
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const stageHandle = (value: string) => {
    props.onVendorRegistrationVendorFormValid(vendorForm)
    setStage({ ...stage, stage: value })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div>
      <div className="text-[24px] flex items-center gap-2">
        <button onClick={() => stageHandle('Init')}>
          <img src={backIcon} alt="back" />
        </button>
        <div>Vendor, sponsor, etc.</div>
      </div>
      <div className="mt-6 mb-1">Enter business details...</div>
      <div>
        <UserRegistrationViewComponentInput
          type="text"
          value={vendorForm.businessName}
          onChange={(e: any) => setVendorForm({ ...vendorForm, businessName: e.target.value })}
          placeholder="Business name"
          icon="/assets/og_icons/User-4.svg"
        />
        <UserRegistrationViewComponentInput
          type="text"
          value={vendorForm.businessCat}
          onChange={(e: any) => setVendorForm({ ...vendorForm, businessCat: e.target.value })}
          placeholder="Business category"
          icon={businessCat}
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="text"
          value={vendorForm.location}
          onChange={(e: any) => setVendorForm({ ...vendorForm, location: e.target.value })}
          placeholder="Location"
          icon={locationIcon}
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="text"
          value={vendorForm.website}
          onChange={(e: any) => setVendorForm({ ...vendorForm, website: e.target.value })}
          placeholder="Website"
          icon={websiteIcon}
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="tel"
          value={vendorForm.phoneNumber}
          onChange={(e: any) => setVendorForm({ ...vendorForm, phoneNumber: e.target.value })}
          placeholder="Phone number"
          icon={phoneIcon}
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="email"
          value={vendorForm.email}
          onChange={(e: any) => setVendorForm({ ...vendorForm, email: e.target.value })}
          placeholder="Business email"
          icon="/assets/og_icons/Mail-4.svg"
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="file"
          value={vendorForm.logo}
          onChange={(e: any) => setVendorForm({ ...vendorForm, logo: e.target.value })}
          placeholder="Upload Logo"
          icon={uploadIcon}
          className="mt-4"
          accept="image/*"
        />
        {/*<UserRegistrationViewComponentInput*/}
        {/*    type="password"*/}
        {/*    value={vendorForm.password}*/}
        {/*    onChange={(e:any) => setVendorForm({ ...vendorForm, password: e.target.value })}*/}
        {/*    placeholder="Your password"*/}
        {/*    icon={passwordIcon}*/}
        {/*    className="mt-4"*/}
        {/*/>*/}
        {/*<UserRegistrationViewComponentInput*/}
        {/*    type="password"*/}
        {/*    value={vendorForm.password2}*/}
        {/*    onChange={(e:any) => setVendorForm({ ...vendorForm, password2: e.target.value })}*/}
        {/*    placeholder="Confirm password"*/}
        {/*    icon={passwordIcon}*/}
        {/*    className="mt-4"*/}
        {/*/>*/}
      </div>
      <div className="w-[70%] my-[60px] mx-auto">
        <UserRegistrationViewComponentButton
          caption="NEXT"
          type={1}
          disabled={!stage.selector}
          onClick={() => stageHandle('Profile')}
        />
      </div>
    </div>
  )
}

UserRegistrationViewComponentVendorForm.defaultProps = {}

export default UserRegistrationViewComponentVendorForm
