import { ModelBaseModel } from '../model-base/model-base.model'
import { IUserDocument, TDocumentShareViaEmail } from './user-documents.interface'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */

export class UserDocumentModel extends ModelBaseModel<IUserDocument> {
  public id: string
  public signatoryId: string
  public riderId: string
  public signatoryName: string
  public signatoryProfilePicture: string
  public status: 'Signed' | 'Not Signed' | 'Shared'
  public eventId: string
  public documentName: string
  public documentUrl: string
  public eventName: string
  public eventLogo: string
  public documentOriginalName: string
  public documentNameAsPerPdf: string
  public pageNumberToSignOn: IUserDocument['pageNumberToSignOn']
  public coordinatesToSignOn: IUserDocument['coordinatesToSignOn']
  public documentShareViaEmail: TDocumentShareViaEmail[]
  public documentOwner: string
  public competitorId: string
  public signatoryDefaultRole: string
  public registrationDocId: IUserDocument['registrationDocId']
  public registrationByDayDocId: IUserDocument['registrationByDayDocId']
  public riderTeamMemberDocId: IUserDocument['riderTeamMemberDocId']
  public signatoryEmail: string
  public reminder: boolean
  public created: Date
  public modified: Date
  public activityUser: string
  public riderName: string
  public u: boolean

  public constructor(obj?: IUserDocument) {
    super()
    this.id = obj?.id ?? ''
    this.u = obj?.u ?? false
    this.signatoryId = obj?.signatoryId ?? ''
    this.riderId = obj?.riderId ?? ''
    this.signatoryName = obj?.signatoryName ?? ''
    this.pageNumberToSignOn = obj?.pageNumberToSignOn ?? null
    this.coordinatesToSignOn = obj?.coordinatesToSignOn ?? {
      x: null,
      y: null,
    }
    this.signatoryProfilePicture = obj?.signatoryProfilePicture ?? ''
    this.signatoryProfilePicture = obj?.signatoryProfilePicture ?? ''
    this.status = obj?.status ?? 'Not Signed'
    this.eventId = obj?.eventId ?? ''
    this.documentName = obj?.documentName ?? ''
    this.documentUrl = obj?.documentUrl ?? ''
    this.eventName = obj?.eventName ?? ''
    this.eventLogo = obj?.eventLogo ?? ''
    this.registrationDocId = obj?.registrationDocId ?? ''
    this.registrationByDayDocId = obj?.registrationByDayDocId ?? ''
    this.riderTeamMemberDocId = obj?.riderTeamMemberDocId ?? ''
    this.documentOriginalName = obj?.documentOriginalName ?? ''
    this.documentNameAsPerPdf = obj?.documentNameAsPerPdf ?? ''
    this.documentOwner = obj?.documentOwner ?? ''
    this.documentShareViaEmail = obj?.documentShareViaEmail ?? []
    this.competitorId = obj?.competitorId ?? ''
    this.signatoryDefaultRole = obj?.signatoryDefaultRole ?? ''
    this.signatoryEmail = obj?.signatoryEmail ?? ''
    this.reminder = obj?.reminder ?? false
    this.activityUser = obj?.activityUser ?? ''
    this.riderName = obj?.riderName ?? ''

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new UserDocumentModel({
      ...doc.data(),
      id: doc.id,
    })
  }
}
