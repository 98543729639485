// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { AddSharp, RemoveSharp } from '@mui/icons-material'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventScoringFormBuilderUtilMainRowAccordionSummaryComponent = styled((props: any) => (
  <MuiAccordionSummary
    expandIcon={
      props.isExpanded ? (
        <RemoveSharp sx={{ fontSize: '0.9rem', color: 'red' }} />
      ) : (
        <AddSharp sx={{ fontSize: '0.9rem', color: 'black' }} />
      )
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'color',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(-1),
  },
}))

export default EventScoringFormBuilderUtilMainRowAccordionSummaryComponent
