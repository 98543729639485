import { cloneDeep } from 'lodash'
import { useState } from 'react'
import helpers from '../../../../commonHelpers/helpers'
import MainModal from '../../../../components/modals/common/MainModal'
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { CustomError } from '../../../../helpers/helpers'
import { IEventStaffInterface } from '../../../../models/event-staff/event-staff.interface'
import { EventStaffModel } from '../../../../models/event-staff/event-staff.model'
import { IUserInterface } from '../../../../models/users/user.interface'
import FirestoreService from '../../../../services/firestoreService'
import customImageComponent from '../../../../components/common/CustomImageComponent'

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

// Types
type IScratchStaffModal = {
  show: boolean
  dataToPassOn: {
    user: {
      staffId: string
      eventId: string
      staffName: string
      staffRole: string
      isOtherStaff: boolean
      userProfilePicture: IUserInterface['userProfilePicture']
    }
    eventStaffDoc: IEventStaffInterface
    updateStaffDocState: (eventStaffDoc_: IEventStaffInterface | null) => void
  }
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

/**
 * @TODO Document this
 */
const ScratchStaffModal = (props: IScratchStaffModal) => {
  // Hooks and vars
  const toastFunctions = useToasterHelper()

  const [loading, setLoading] = useState(false)

  const staff = props.dataToPassOn?.user
  const eventStaffDoc = props.dataToPassOn?.eventStaffDoc

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const updateStaffDocInDb = async (updatedEventStaffDoc: IEventStaffInterface) => {
    let updated = true
    try {
      await FirestoreService.updateItem(
        COLLECTIONS.EVENT_STAFF.NAME,
        updatedEventStaffDoc?.id as string,
        new EventStaffModel(updatedEventStaffDoc).toFirestore()
      )
      toastFunctions.success({
        message: MESSAGES_CONST.STAFF_UPDATED,
      })
    } catch (error) {
      updated = false
      setLoading(false)
    } finally {
      if (updated) {
        props?.dataToPassOn?.updateStaffDocState(updatedEventStaffDoc)
        closeModal()
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onRemoveButtonClick = () => {
    let found = false
    let values: string[] = []
    let scratchedValues: string[] = []

    setLoading(true)

    try {
      let eventStaffDoc_ = cloneDeep(eventStaffDoc)

      if (!eventStaffDoc_)
        throw CustomError.somethingWentWrong({
          lineNumber: 67,
          fileName: 'ScratchStaffModal',
          devMessage: `eventStaffDoc_ is ${eventStaffDoc_}`,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      if (!eventStaffDoc_?.id)
        throw CustomError.somethingWentWrong({
          lineNumber: 67,
          fileName: 'ScratchStaffModal',
          devMessage: `eventStaffDoc_?.id is ${eventStaffDoc_?.id}`,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      if (staff?.isOtherStaff && eventStaffDoc_?.otherStaff)
        eventStaffDoc_.otherStaff = eventStaffDoc_?.otherStaff?.map((currStaff) => {
          values = cloneDeep(currStaff.value)
          scratchedValues = currStaff?.scratchedValues ?? []
          if (currStaff.title === staff.staffRole)
            values.forEach((currId) => {
              if (!found) {
                found = currId === staff.staffId
                if (found) scratchedValues.push(staff.staffId)
              }
            })
          return {
            ...currStaff,
            value: values,
            scratchedValues,
          }
        })
      else if (!staff?.isOtherStaff && eventStaffDoc_?.eventStaff)
        eventStaffDoc_.eventStaff = eventStaffDoc_?.eventStaff?.map((currStaff) => {
          values = cloneDeep(currStaff.value)
          scratchedValues = currStaff?.scratchedValues ?? []
          if (currStaff.title === staff.staffRole)
            values.forEach((currId) => {
              if (!found) {
                found = currId === staff.staffId
                if (found) scratchedValues.push(staff.staffId)
              }
            })
          return {
            ...currStaff,
            value: values,
            scratchedValues,
          }
        })

      if (!found)
        throw CustomError.somethingWentWrong({
          lineNumber: 67,
          fileName: 'ScratchStaffModal',
          devMessage: `unable to remove staff since, its not found in eventStaffDoc_`,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      updateStaffDocInDb(eventStaffDoc_)
    } catch (error: any) {
      setLoading(false)
      toastFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        message: error,
      })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  function closeModal() {
    props?.handleModal(false, MODAL_CONSTS.SCRATCH_STAFF_MODAL)
  }

  return (
    <MainModal
      size="md"
      show={props.show}
      type="SCRATCH_STAFF_MODAL"
      title="Remove from event?"
      titleClassName="!font-normal"
      buttons={[
        {
          label: 'REMOVE',
          loading,
          textClass: '!text-white',
          onClick: onRemoveButtonClick,
          fullWidth: true,
          bgClass: '!bg-SeabiscuitMainThemeColor',
          onHoverClass: 'hover:shadow-slate-300',
        },
        {
          label: 'CANCEL',
          onClick: closeModal,
          bgClass: '!bg-SeabiscuitLightThemeColor',
          fullWidth: true,
          borderClass: 'border border-transparent',
          textClass: '!text-SeabiscuitLightTextColor',
          onHoverClass: 'hover:shadow-slate-300',
        },
      ]}
    >
      <div className="border border-solid rounded-lg p-2 flex items-center capitalize mb-2 text-SeabiscuitDark200ThemeColor">
        {customImageComponent(
          staff?.userProfilePicture,
          staff?.staffName,
          'w-8 h-8 rounded-full mr-2 overflow-hidden'
        )}
        {staff?.staffName}
      </div>

      <div className="border border-solid rounded-lg p-2 capitalize mb-2 text-SeabiscuitDark200ThemeColor font-[Poppins] text-sm gap-2 flex">
        <img src="/assets/og_icons/Error-1.svg" className="w-6 h-6 mx-1" alt="alert" />
        <div>
          <p className="mb-5">
            When you click ‘remove’, this member will no longer be registered to participate in this
            event and they will be notified.
          </p>
          <p className="mb-5">
            Any paperwork they submitted during the registration process will not be deleted and
            will remain accessible under the ‘scratch’ tab.
          </p>
          <p className="mb-5">
            Refunds will not be automatically processed. You can refund the payments manually in the
            finances screen in line with your refund policy.
          </p>
        </div>
      </div>
    </MainModal>
  )
}

export default ScratchStaffModal
