import { useState } from 'react'
import { pdfBlobToImages, urlToDataUrl } from '../commonHelpers/helpers'

// Types
type IImagesAndCount = {
  imagesList: string[]
  pagesCount: number
} | null

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const usePdfToImage = () => {
  // Hooks and vars
  const [loading, setLoading] = useState(false)
  const [pdfPageCount, setPdfPageCount] = useState(0)
  const [error, setError] = useState<string | null>(null)
  const [imageUrls, setImageUrls] = useState<string[]>([])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const convert = async (pdfUrl: string) => {
    let imagesAndCount: IImagesAndCount = { imagesList: [], pagesCount: 0 }
    setLoading(true)
    try {
      const blob = await urlToDataUrl(pdfUrl)
      imagesAndCount = await pdfBlobToImages(blob)
      if (!imagesAndCount) throw new Error('Something went wrong')

      setImageUrls(imagesAndCount.imagesList)
      setPdfPageCount(imagesAndCount.pagesCount)
      setLoading(false)
    } catch (error: any) {
      console.log({ error })
      setLoading(false)
      setError(error?.message)
    } finally {
      return imagesAndCount
    }
  }

  return {
    convert,
    loading,
    pdfPageCount,
    imageUrls,
    error,
  }
}

export default usePdfToImage
