import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import Input from '../../../../../../components/common/inputs/Input'
import { PillButtonElement } from '../../../../../../components/elements/buttons/pill/PillButtonElement'
import { messageSchema } from './messageSchema'
import MessageHelperComp from '../../../../../../helpers/MessageHelper'
import { httpService } from '../../../../../../services/httpService'
import { MESSAGES_CONST } from '../../../../../../const/messages-const'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'
import { useState } from 'react'
import { AutorenewRounded } from '@mui/icons-material'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectProfileData, selectUseremail } from '../../../../../../store/user/userSlice'
import { AxiosError } from 'axios'
import { getUserFullName } from '../../../../../../helpers/helpers'

const MessageTab = () => {
  const toastFunctions = useToasterHelper()
  const [loading, setLoading] = useState(false)
  const email = useAppSelector(selectUseremail)
  const profileDetails = useAppSelector(selectProfileData)

  const {
    watch,
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      subject: '',
      message: '',
    },
    resolver: yupResolver(messageSchema),
  })

  const onSubmit = async (data: any) => {
    try {
      setLoading(true)

      const res = await httpService({
        url: 'send_message_via_email',
        method: 'POST',
        data: {
          ...data,
          email: [email],
          beingUsedBy: 'vendor',
          senderName: !!profileDetails.id ? getUserFullName(profileDetails) : 'Guest',
        },
      })

      if (res.status === false) {
        throw new Error(res?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG)
      } else {
        reset({
          subject: '',
          message: '',
        })

        toastFunctions.success({
          message: res.message,
        })
      }
    } catch (error: any) {
      console.error(error)
      if (error instanceof AxiosError) {
        toastFunctions.error({
          message: error?.response?.data?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })
      } else {
        toastFunctions.error({
          message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex flex-col gap-3 my-3">
      <div className="text-SeabiscuitDark200ThemeColor font-bold">Email account</div>
      <div className="flex flex-col gap-2 my-3">
        <Input
          type="text"
          name="subject"
          register={register}
          className="!h-12 px-4 !rounded-lg"
          value={watch('subject')}
          placeholder="Enter email subject..."
          validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
          invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
        />

        {!!errors?.subject?.message ? (
          <MessageHelperComp message={errors?.subject?.message} />
        ) : null}

        <Input
          type="textarea"
          name="message"
          rows={10}
          register={register}
          className="min-h-[300px] px-4 !rounded-lg"
          value={watch('message')}
          placeholder="Type message..."
          validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
          invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3 placeholder:text-SeabiscuitLightParagraphTextColor placeholder:!text-nr !text-nr"
        />

        {!!errors?.message?.message ? (
          <MessageHelperComp message={errors?.message?.message} />
        ) : null}

        <PillButtonElement
          text={
            <div className="flex items-center justify-center">
              {loading ? (
                <AutorenewRounded fontSize="small" className="animate-spin mx-auto" />
              ) : (
                'SEND >'
              )}
            </div>
          }
          visible={true}
          onButtonClicked={handleSubmit(onSubmit)}
          Class="!text-white !bg-SeabiscuitMainThemeColor w-[150px] rounded-lg !py-2.5 !h-[44.5px]"
        />
      </div>
    </div>
  )
}

export default MessageTab
