// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useEffect, useState } from 'react'

import DrawerComponent from './Components/DrawerComponent'
import GlobalButton from './Components/GlobalButton'

// Third party
import { yupResolver } from '@hookform/resolvers/yup'
import { Clear } from '@mui/icons-material'
import { useFieldArray, useForm } from 'react-hook-form'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import MessageHelperComp from '../../../../helpers/MessageHelper'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { sendEmailToSponsorVendorSchema } from '../../../../validations'
import { httpService } from '../../../../services/httpService'
import { useAppSelector } from '../../../../store/hooks'
import { selectUserReducerData } from '../../../../store/user/userSlice'
import { IUserNotificationInterace } from '../../../../models/user_notifications/notification.interface'
import { CONST } from '../../../../const/const'
import { UserNotificationModal } from '../../../../models/user_notifications/notification.model'
import FirestoreService from '../../../../services/firestoreService'
import { selectBasicEventDetails } from '../../../../store/events/eventsSlice'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants
// const DUMMY_EMAILS = [
//     "jcwilliams2090@gmail.com",
//     "sammygomez2090@gmail.com",
//     "billybob56@gmail.com",
// ]

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ButtonContainer: React.FC<{ children: any; className?: string }> = ({
  children,
  className,
}) => <div className={`w-[80%] flex flex-col gap-3 p-4 mx-auto ${className}`}>{children}</div>

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const SendEmailModal: React.FC<{
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}> = ({ dataToPassOn, handleModal }) => {
  // Hooks and vars
  const [title, setTitle] = useState('')
  const toastMethods = useToasterHelper()
  const { profileDetails } = useAppSelector(selectUserReducerData)
  const eventId = useAppSelector(selectBasicEventDetails)

  const userName = `${profileDetails?.userFirstName} ${profileDetails?.userLastName}`
  const userEmail = profileDetails?.userEmail
  const USER_NOTIFICATION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_NOTIFICATION
  const [buttonLoading, setButtonLoading] = useState(false)
  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: {
      errors: { email },
      errors,
    },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(sendEmailToSponsorVendorSchema),
    defaultValues: { email: [{ value: '' }] },
  })

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'email',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const addEmailHandle = () => {
    append({ value: '' })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const saveHandle = async (data: any) => {
    try {
      setButtonLoading(true)
      let emails = data?.email?.map((curr: any) => curr?.value)
      if (!emails.length)
        return toastMethods.error({ message: "Please add at least one reciever's email address" })

      await httpService({
        url: 'sendInvitationMail',
        method: 'POST',
        data: {
          emails,
          senderData: {
            senderName: userName,
            senderEmail: userEmail,
          },
        },
      })

      const notification_data: IUserNotificationInterace = {
        senderId: profileDetails?.id,
        receiver_type: USER_NOTIFICATION.FIELDS.RECEIVER_TYPE.VALUE.NON_LOGGED_IN,
        receiver_email: '',
        eventId: eventId.id,
        status: USER_NOTIFICATION.FIELDS.STATUS.VALUE.UNSEEN,
        notification_type: USER_NOTIFICATION.FIELDS.NOTIFICATION_TYPE.VALUE.INVITE_MEMBER,
      }

      if (emails?.length) {
        let i = 0

        while (i < emails?.length) {
          notification_data.receiver_email = emails[i]

          let insert_data = new UserNotificationModal(notification_data).toFirestore()

          await FirestoreService.createItem(USER_NOTIFICATION.NAME, insert_data)

          i++
        }
      }

      handleModal(false, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL, {
        emails,
      })

      let successModalMessage = ''
      switch (dataToPassOn?.type) {
        case 1:
          successModalMessage = 'Prize list sent'
          break
        case 2:
          successModalMessage = 'Invites sent'
          break
        case 3:
          successModalMessage = 'Invites sent'
          break
        case 4:
          successModalMessage = 'Invites sent'
          break
        case 5:
          successModalMessage = 'Invites sent'
          break
      }

      handleModal(true, MODAL_CONSTS.SUCCESS, {
        message: successModalMessage,
      })
      setButtonLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const typeHandle = () => {
    switch (dataToPassOn?.type) {
      case 1:
        setTitle('Add emails to send prize list')
        break
      case 2:
        setTitle('Add emails to invite to vendor or sponsor')
        break
      case 3:
        setTitle('Add emails to invite to nearbye businesses...')
        break
      case 4:
        setTitle('Add emails to Invite staff to event')
        break
      case 5:
        setTitle('Invite others to create an account')
        break
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    typeHandle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToPassOn?.type])

  const checkvalueDone = (index: number) => {
    const email = getValues(`email.${index}.value`)

    if (email !== '' && !(errors as any)?.email?.[index]) {
      return true
    }

    return false
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <DrawerComponent
      show={true}
      onHide={() => handleModal(false, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL)}
      title={title}
      maxWidth={600}
      titleClassName="!text-SeabiscuitDark200ThemeColor !font-normal"
    >
      <div className="pr-2 max-h-[calc(100vh_-_250px)] min-h-[60vh] overflow-auto">
        <div className="flex flex-col gap-2 text-SeabiscuitDark200ThemeColor">
          {fields.map((currField, index) => {
            return (
              <div key={currField.id} className="mb-2">
                <div
                  className={`text-[14px] w-full ${checkvalueDone(index) ? 'bg-SeabiscuitGrayThemeColor' : 'bg-white'}  border-solid border-[1px] border-[#D3DAEE] rounded-md flex items-center p-4 h-12 gap-2`}
                >
                  <img
                    src={
                      watch(`email.${index}.value`) && watch(`email.${index}.value`) !== ''
                        ? '/assets/og_icons/Mail-1.svg'
                        : '/assets/og_icons/Mail.svg'
                    }
                    alt="icon"
                    className={
                      watch(`email.${index}.value`) !== ''
                        ? '/assets/og_icons/Mail-1.svg'
                        : '/assets/og_icons/Mail.svg'
                    }
                  />
                  <input
                    className="w-full bg-transparent !outline-0 !border-none text-SeabiscuitDark200ThemeColor"
                    placeholder="Enter email address..."
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') addEmailHandle()
                    }}
                    {...register(`email.${index}.value`)}
                  />

                  {watch(`email.${index}.value`) !== '' && errors && !errors.email?.[index] ? (
                    <img src="/assets/og_icons/Ok-3.svg" alt="green check" title="Email is valid" />
                  ) : (
                    <Clear
                      fontSize={'small'}
                      style={{
                        color: 'grey',
                        fontWeight: '400',
                      }}
                      className="cursor-pointer"
                      onClick={() => remove(index)}
                    />
                  )}
                </div>

                {email && email?.[index]?.value?.message !== '' ? (
                  <MessageHelperComp
                    className="mx-2"
                    isError={true}
                    message={email?.[index]?.value?.message?.replace(/\[\d\].value/gi, '')}
                  />
                ) : null}
              </div>
            )
          })}
        </div>
        <button
          onClick={addEmailHandle}
          className="my-1 text-SeabiscuitMainThemeColor font-bold text-[12px] flex justify-center w-full items-center gap-2"
        >
          <img src={`/assets/og_icons/Cancel-2.svg`} className=" rotate-45" alt="plus icon" />
        </button>
      </div>
      <ButtonContainer className="mt-2 max-w-[260px]">
        <GlobalButton
          caption="Send Invite"
          arrowIcon={true}
          type={1}
          onClick={handleSubmit(saveHandle)}
          disabled={buttonLoading}
          loading={buttonLoading}
        />
      </ButtonContainer>
    </DrawerComponent>
  )
}

export default SendEmailModal
