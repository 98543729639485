import * as yup from 'yup'

// Types
import { IHorseData } from './horse.interface'
import { cloneDeep } from 'lodash'

// Models
import { ModelBaseModel } from '../model-base/model-base.model'

// Helpers
import { selectObjToString } from '../interface.helper'
import { ISelect } from '../users/user.interface'
import { IHorseTeamInterface } from '../horse-team/horseTeam.interface'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */

export class HorseModel extends ModelBaseModel<IHorseData> {
  public id?: string
  public horseId?: string
  public horseOwnerId: IHorseData['horseOwnerId']
  public horseName: string
  public horseNameNGram: string[] | undefined
  public horseBreed: string
  public horseBreeder: string
  public horseProfilePicture: string
  public horseHeight: string
  public horseDob: Date | string
  public horseAgeVerified?: ISelect | string
  public horseGender: string
  public horseDiscipline: string
  public horseColor: string
  public horseZone: string
  public horseMarking: string[] | string
  public horseCountry: string
  public horseMicrochipNumber?: string | number
  public horseMicrochipExpiration?: Date | string

  public horsePassportNumber?: string | number
  public horsePassportExpiration?: Date | string

  public horseDNACaseNumber?: string | number
  public horseDNACaseExpiration?: Date | string

  public horseTeamMembers: IHorseData['horseTeamMembers']
  public horseTeamMembers_: IHorseTeamInterface[]
  public horseStatus: string
  public horseFeiStatus: ISelect | string

  public horseFeiNumber: string | number
  public horseUsefNumber: string | number

  public horseUsdfNumber?: string | number
  public horseUsdfExpiration?: Date | string
  public horseUseaNumber?: string | number
  public horseUseaExpiration?: Date | string
  public horseUshjaNumber?: string | number
  public horseUshjaExpiration?: Date | string
  public horseAhhsNumber?: string | number
  public horseAhhsExpiration?: Date | string
  public horseAmhaNumber?: string | number
  public horseAmhaExpiration?: Date | string
  public horseArhpaNumber?: string | number
  public horseArhpaExpiration?: Date | string
  public horseAshaNumber?: string | number
  public horseAshaExpiration?: Date | string
  public horseUphaNumber?: string | number
  public horseUphaExpiration?: Date | string
  public horseWdaaNumber?: string | number
  public horseWdaaExpiration?: Date | string

  public horseOtherNumber: string | number
  public horseOtherDate: string | number
  public horsesOther?: [] | []

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: IHorseData) {
    super()
    this.id = obj?.id || ''
    this.horseId = obj?.horseId || ''
    this.horseOwnerId = obj?.horseOwnerId || null
    this.horseStatus = obj?.horseStatus || ''
    this.horseProfilePicture = obj?.horseProfilePicture || ''

    this.horseName = obj?.horseName || ''
    this.horseTeamMembers_ = obj?.horseTeamMembers_ ?? []
    this.horseBreed = selectObjToString(obj?.horseBreed)
    this.horseBreeder = selectObjToString(obj?.horseBreeder) || ''
    this.horseHeight = selectObjToString(obj?.horseHeight)
    this.horseDob = obj?.horseDob || new Date()
    this.horseAgeVerified = obj?.horseAgeVerified || ''
    this.horseGender = selectObjToString(obj?.horseGender)
    this.horseDiscipline = selectObjToString(obj?.horseDiscipline)
    this.horseZone = selectObjToString(obj?.horseZone)
    this.horseColor = selectObjToString(obj?.horseColor)
    this.horseMarking = selectObjToString(obj?.horseMarking) ?? ''
    this.horseCountry = selectObjToString(obj?.horseCountry)
    this.horseTeamMembers = obj?.horseTeamMembers || []
    this.horseNameNGram = obj?.horseNameNGram ?? []

    this.horseMicrochipNumber = obj?.horseMicrochipNumber || ''
    this.horseMicrochipExpiration = obj?.horseMicrochipExpiration || ''
    this.horsePassportNumber = obj?.horsePassportNumber || ''
    this.horsePassportExpiration = obj?.horsePassportExpiration || ''
    this.horseDNACaseNumber = obj?.horseDNACaseNumber || ''
    this.horseDNACaseExpiration = obj?.horseDNACaseExpiration || ''

    this.horseFeiNumber = obj?.horseFeiNumber || ''
    this.horseFeiStatus = obj?.horseFeiStatus ? selectObjToString(obj?.horseFeiStatus) : ''
    this.horseUsefNumber = obj?.horseUsefNumber || ''

    this.horseUsdfNumber = obj?.horseUsdfNumber || ''
    this.horseUsdfExpiration = obj?.horseUsdfExpiration || ''
    this.horseUseaNumber = obj?.horseUseaNumber || ''
    this.horseUseaExpiration = obj?.horseUseaExpiration || ''
    this.horseUshjaNumber = obj?.horseUshjaNumber || ''
    this.horseUshjaExpiration = obj?.horseUshjaExpiration || ''
    this.horseAhhsNumber = obj?.horseAhhsNumber || ''
    this.horseAhhsExpiration = obj?.horseAhhsExpiration || ''
    this.horseAmhaNumber = obj?.horseAmhaNumber || ''
    this.horseAmhaExpiration = obj?.horseAmhaExpiration || ''
    this.horseArhpaNumber = obj?.horseArhpaNumber || ''
    this.horseArhpaExpiration = obj?.horseArhpaExpiration || ''
    this.horseAshaNumber = obj?.horseAshaNumber || ''
    this.horseAshaExpiration = obj?.horseAshaExpiration || ''
    this.horseUphaNumber = obj?.horseUphaNumber || ''
    this.horseUphaExpiration = obj?.horseUphaExpiration || ''
    this.horseWdaaNumber = obj?.horseWdaaNumber || ''
    this.horseWdaaExpiration = obj?.horseWdaaExpiration || ''

    this.horseOtherNumber = obj?.horseOtherNumber || ''
    this.horseOtherDate = obj?.horseOtherDate || ''
    this.horsesOther = obj?.horsesOther || []

    this._calculateEventNameNGrams()
  }

  private _calculateEventNameNGrams() {
    let reg = new RegExp(/\s+/, 'g')
    let horsenospace = this.horseName.replace(reg, '')
    const words = horsenospace.split(' ')
    const lowercase_words = words.map((word) => word.toLowerCase())

    let ngram_words = new Set<string>()

    lowercase_words.forEach((word) => {
      let word_iterator = cloneDeep(word)

      while (word_iterator.length > 0) {
        ngram_words.add(cloneDeep(word_iterator))
        word_iterator = word_iterator.slice(0, -1)
      }
    })

    if (this.horseNameNGram && this.horseNameNGram.length > 0) {
      this.horseNameNGram = []
    }

    ngram_words.forEach((value) => {
      if (this.horseNameNGram) this.horseNameNGram.push(value)
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new HorseModel({
      ...doc.data(),
      id: doc?.id,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static paperworkValidationSchema() {
    return yup.object().shape({
      measurementCard: yup.array().of(yup.object().shape({})),
    })
  }
}
