// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { configureStore } from '@reduxjs/toolkit'

import systemReducer from './system/systemSlice'
import userReducer from './user/userSlice'
import eventsSlice from './events/eventsSlice'
import horseSlice from './horses/horseSlice'
import exhibitorSlice from './exhibitor/exhibitorSlice'
import filterSlice from './filters/filterSlice'
import bookmarksSlice from './bookmarks/bookmarksSlice'
import ticketslice from './tickets/ticketslice'
import filesSlice from './files/filesSlice'
import billsSlice from './bills/billsSlice'
import holdersSlice from './holders/holdersSlice'
import paperworkSlice from './paperworks/paperworkSlice'
import followingSlice from './following/followingSlice'
import registrationSlice from './registration/registrationSlice'
import registerExhibitorSlice from './registerExhibitor/registerExhibitorSlice'
import organizerSlice from './organizer/organizerSlice'
import profilePageSlice from './profilePage/profilePageSlice'
import registeredUserSlice from './registeredUser/registeredUserSlice'
import draftEventsSlice from './draftEvents/draftEventsSlice'
import registerMobileMenuSlice from './mobileMenu/mobileMenuSlice'
import riderSlice from './rider/riderSlice'

const store = configureStore({
  reducer: {
    files: filesSlice,
    user: userReducer,
    bills: billsSlice,
    horses: horseSlice,
    draftEvents: draftEventsSlice,
    events: eventsSlice,
    holder: holdersSlice,
    filters: filterSlice,
    tickets: ticketslice,
    system: systemReducer,
    organizer: organizerSlice,
    exhibitor: exhibitorSlice,
    bookmarks: bookmarksSlice,
    following: followingSlice,
    rider: riderSlice,
    paperworks: paperworkSlice,
    registration: registrationSlice,
    registerExhibitors: registerExhibitorSlice,
    profilePage: profilePageSlice,
    registeredUser: registeredUserSlice,
    mobileMenu: registerMobileMenuSlice,
  },
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type IReduxStatus = 'idle' | 'loading' | 'fulfilled' | 'failed'

export default store
