// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useContext, useEffect, useState } from 'react'
import { SignupStages } from './UserRegistrationViewComponentContextApi'
import UserRegistrationViewComponentButton from './global/UserRegistrationViewComponentButton'
import UserRegistrationViewComponentInput from './global/UserRegistrationViewComponentInput'

import { cloneDeep } from 'lodash'
import aboutIcon from './assets/about.svg'
import locationIcon from './assets/address.svg'
import backIcon from './assets/arrow-left.svg'
import arrowIcon from './assets/arrow.svg'
import businessIcon from './assets/business-account.svg'
import cardIcon from './assets/card.svg'
import categoryIcon from './assets/category.svg'
import editProfile from './assets/edit-profile.svg'
import facebookIcon from './assets/facebook.svg'
import imageIcon from './assets/images.svg'
import instagramIcon from './assets/instagram.svg'
import websiteIcon from './assets/location.svg'
import phoneIcon from './assets/phone-icon.svg'
import linkedinIcon from './assets/pinterest.svg'
import thumb from './assets/thumb.png'
import twitterIcon from './assets/twitter.svg'
import usefIcon from './assets/usef-icon.svg'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface UserRegistrationViewComponentVendorProfileFormProps {
  userData: any
  onVendorRegistrationProfileValid: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const UserRegistrationViewComponentVendorProfileForm: React.FC<
  UserRegistrationViewComponentVendorProfileFormProps
> = (props) => {
  const [stage] = useContext(SignupStages)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const [profileForm, setProfileForm] = useState({
    ariat: '',
    apparrel: '',
    location: '',
    website: '',
    phoneNumber: '',
    email: '',
    aboutImage: '',
    about: '',
    facebook: '',
    instagram: '',
    twitter: '',
    linkedin: '',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const stageHandle = (value: string) => {
    props.onVendorRegistrationProfileValid(profileForm)
    // setStage({ ...stage, stage: value })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const CardLink: React.FC<{ icon: string; caption: string; onClick?: () => void }> = ({
    icon,
    caption,
    onClick,
  }) => (
    <button
      onClick={onClick}
      className={`mt-4 h-[58px] text-gray-500 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-[#D3DAEE] border-[1px] bg-white`}
    >
      <div className="flex gap-2 flex-1 items-center">
        <span>
          <img src={icon} alt="icon" />
        </span>
        <span>{caption}</span>
      </div>
      <span>
        <img src={arrowIcon} alt="arrow" />
      </span>
    </button>
  )

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const ImageCard: React.FC<{ icon: string }> = ({ icon }) => (
    <label
      className={`mt-4 h-[58px] text-gray-500 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-[#D3DAEE] border-[1px] bg-white`}
    >
      <div>
        <img src={icon} alt="icon" />
      </div>
      <div className="w-[40px] h-[40px] overflow-hidden rounded-lg">
        <img src={thumb} alt="thumb" className="object-cover" />
      </div>
      <input type="file" accept="image/*" className="hidden" />
    </label>
  )

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const ProfileSection: React.FC = () => (
    <label className="flex w-[98px] h-[98px] rounded-full bg-gray-100 items-center justify-center mx-auto mt-6">
      <img src={editProfile} alt="edit" className="w-[25px] h-[25px]" />
      <input type="file" accept="image/*" className="hidden" />
    </label>
  )

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(
    () => {
      const newProfileForm = cloneDeep(profileForm)
      newProfileForm.ariat = props.userData.vendorBusinessName
      newProfileForm.apparrel = props.userData.vendorBusinessCategory
      newProfileForm.location = props.userData.vendorLocation
      newProfileForm.website = props.userData.vendorWebsite
      newProfileForm.phoneNumber = props.userData.vendorPhoneNumber
      newProfileForm.email = props.userData.vendorBusinessEmail
      newProfileForm.aboutImage = props.userData.vendorLogoUrl

      setProfileForm(newProfileForm)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.userData]
  )

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div>
      <div className="text-[24px] flex items-center gap-2">
        <button onClick={() => stageHandle('Init')}>
          <img src={backIcon} alt="back" />
        </button>
        <div>Profile</div>
      </div>
      <ProfileSection />
      <div className="mt-6">
        <div className="font-bold">BUSINESS DETAILS</div>
        <UserRegistrationViewComponentInput
          type="text"
          value={profileForm.ariat}
          onChange={(e: any) => setProfileForm({ ...profileForm, ariat: e.target.value })}
          placeholder="Ariat"
          icon={usefIcon}
        />
        <UserRegistrationViewComponentInput
          type="text"
          value={profileForm.apparrel}
          onChange={(e: any) => setProfileForm({ ...profileForm, apparrel: e.target.value })}
          placeholder="Apparrel"
          icon={categoryIcon}
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="text"
          value={profileForm.location}
          onChange={(e: any) => setProfileForm({ ...profileForm, location: e.target.value })}
          placeholder="Location"
          icon={locationIcon}
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="text"
          value={profileForm.website}
          onChange={(e: any) => setProfileForm({ ...profileForm, website: e.target.value })}
          placeholder="https://ariat.com"
          icon={websiteIcon}
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="tel"
          value={profileForm.phoneNumber}
          onChange={(e: any) => setProfileForm({ ...profileForm, phoneNumber: e.target.value })}
          placeholder="1 800 898 9798"
          icon={phoneIcon}
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="email"
          value={profileForm.email}
          onChange={(e: any) => setProfileForm({ ...profileForm, email: e.target.value })}
          placeholder="marketing@ariat.com"
          icon="/assets/og_icons/Mail-4.svg"
          className="mt-4"
        />
        <div className="font-bold mt-4">ABOUT</div>
        <ImageCard icon={imageIcon} />
        <UserRegistrationViewComponentInput
          type="textarea"
          value={profileForm.aboutImage}
          onChange={(e: any) => setProfileForm({ ...profileForm, aboutImage: e.target.value })}
          placeholder="About you"
          icon={aboutIcon}
          className="mt-4"
        />

        <UserRegistrationViewComponentInput
          type="text"
          value={profileForm.facebook}
          onChange={(e: any) => setProfileForm({ ...profileForm, facebook: e.target.value })}
          placeholder="https://facebok.com/...."
          icon={facebookIcon}
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="text"
          value={profileForm.instagram}
          onChange={(e: any) => setProfileForm({ ...profileForm, instagram: e.target.value })}
          placeholder="https://instagram.com/...."
          icon={instagramIcon}
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="text"
          value={profileForm.twitter}
          onChange={(e: any) => setProfileForm({ ...profileForm, twitter: e.target.value })}
          placeholder="https://twitter.com/...."
          icon={twitterIcon}
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="text"
          value={profileForm.linkedin}
          onChange={(e: any) => setProfileForm({ ...profileForm, linkedin: e.target.value })}
          placeholder="https://linkedin.com/...."
          icon={linkedinIcon}
          className="mt-4"
        />
        <div className="font-bold mt-4">FINANCIAL DETAILS</div>
        <CardLink caption="Add business credit card" icon={cardIcon} />
        <CardLink caption="Add business account details" icon={businessIcon} />
        <div className="font-bold mt-4">SECURITY DETAILS</div>
        {/*<CardLink*/}
        {/*    caption="Change password"*/}
        {/*    icon={passwordIcon}*/}
        {/*/>*/}
      </div>
      <div className="w-[70%] my-[60px] mx-auto">
        <UserRegistrationViewComponentButton
          caption="SAVE"
          type={1}
          icon={false}
          disabled={!stage.selector}
          onClick={() => stageHandle('3')}
        />
      </div>
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
UserRegistrationViewComponentVendorProfileForm.defaultProps = {
  userData: null,
  onVendorRegistrationProfileValid: null,
}

export default UserRegistrationViewComponentVendorProfileForm
