// Types
type IDetailRowProps = {
  title: string | null
  content: string | null
}

const ExhibitorFeesInput: React.FC<{
  className?: string
  data?: any
  icon?: any
}> = ({ className, data, icon }) => {
  return (
    <>
      {icon ? (
        <div className={`${className}`}>
          <img className="absolute left-3 top-3" src={icon} alt="icons" />
          {data}
        </div>
      ) : (
        <div className={`${className}`}>{data}</div>
      )}
    </>
  )
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const DetailRow = (props: IDetailRowProps) => {
  const { title, content } = props

  return (
    <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
      <ExhibitorFeesInput
        className="max-h-[15px] md:max-h-[initial] rounded-lg text-[12px] md:text-[14px] xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 md:bg-SeabiscuitGrayThemeColor md:p-3 m-1 w-full md:w-3/4"
        data={title}
      />

      <ExhibitorFeesInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-full md:w-1/4 text-center"
        data={content}
      />
    </div>
  )
}

type DataItem = {
  title: string
  content: string
}

type Props = {
  data: DataItem[]
}

const ExhibitorRidingDetailsAccordion: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data && data.length
        ? data.map((currentItem, index) => {
            return (
              <DetailRow
                key={`${JSON.stringify(currentItem)}${index}`}
                title={currentItem.title}
                content={currentItem.content}
              />
            )
          })
        : null}
    </>
  )
}

export default ExhibitorRidingDetailsAccordion
