// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import './customizing.css'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export interface PropTypes {
  title: string
  placeholder: string
  hint: string
  name: string
  register: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPoliciesFormElement: React.FC<PropTypes> = ({
  title,
  placeholder,
  hint,
  name,
  register,
}) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <div className="flex mt-2 gap-1">
        <label htmlFor="parking" className="text-xs font-semibold text-SeabiscuitDark200ThemeColor">
          {title}
        </label>
        {/* <Tooltip
                    title={<h1 className="tooltip_title">{hint}</h1>}
                    placement="top"
                    arrow
                >
                    <button>
                        <TooltipIcon className="!ml-1" />
                    </button>
                </Tooltip> */}
      </div>
      <textarea
        className="bg-SeabiscuitGrayThemeColor mt-1 rounded w-full h-40 text-base text-SeabiscuitDark200ThemeColor EventDetailFormComponent__input  placeholder:text-[16px] placeholder:font-light placeholder:text-gray-400 border-solid focus:ring-0 focus:border-transparent border border-transparent"
        placeholder={hint}
        {...register(name)}
        defaultValue=""
      />
    </>
  )
}

export default EventPoliciesFormElement
