import clsx from 'clsx'
import IProfileCard from '../types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const HorsePublicProfileTabSection = (props: IProfileCard['ITabSectionProps']) => {
  return (
    <div className="flex flex-col items-center pt-[22px] w-full  bg-white">
      <div className="flex flex-wrap rouded-lg w-full items-start justify-center">
        <div
          className={clsx(
            `grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid`,
            props.activeTab === 'details' ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'
          )}
          onClick={() => props.onTabClick('details')}
        >
          {props.activeTab === 'details' ? (
            <img src="/assets/og_icons/YearofHorse-2.svg" alt="details" />
          ) : (
            <img src="/assets/og_icons/YearofHorse-1.svg" alt="details" />
          )}
        </div>
        <div
          className={clsx(
            `grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid`,
            props.activeTab === 'team' ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'
          )}
          onClick={() => props.onTabClick('team')}
        >
          {props.activeTab === 'team' ? (
            <img src="/assets/cp_icons/User_Groups-2.svg" alt="team" />
          ) : (
            <img src="/assets/cp_icons/User_Groups-1.svg" alt="team" />
          )}
        </div>
        <div
          className={clsx(
            `grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid`,
            props.activeTab === 'lineage' ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'
          )}
          onClick={() => props.onTabClick('lineage')}
        >
          {props.activeTab === 'lineage' ? (
            <img src="/assets/cp_icons/Biotech-2.svg" alt="lineage" />
          ) : (
            <img src="/assets/cp_icons/Biotech-1.svg" alt="lineage" />
          )}
        </div>
      </div>
    </div>
  )
}

export default HorsePublicProfileTabSection
