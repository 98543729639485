// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useState } from 'react'
import { AutorenewRounded } from '@mui/icons-material'
import { tags } from './Tags'
import MessageHelperComp from '../../../../helpers/MessageHelper'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const Selector: React.FC<{
  caption: string
  selected: boolean
  onChange: IOnSelectionChanged
  value: string
}> = ({ caption, selected, onChange, value }) => (
  <div className="displineCheckBoxfilter">
    <input
      className="form-check-input rounded_checkboxes appearance-none h-4 w-4 border border-[#D3DAEE] bg-white checked:bg-pink-600 checked:border-none focus:outline-none focus:ring-0 focus:ring-offset-0 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
      type="checkbox"
      name="checkbox"
      checked={selected}
      value={value}
      id={`${caption}Id`}
      onChange={(e) => onChange({ tag: value, added: e.target.checked })}
    />
    <label
      htmlFor={`${caption}Id`}
      className="labelFilter cursor-pointer text-SeabiscuitDark200ThemeColor text-md"
    >
      {caption}
    </label>
  </div>
)

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const Prototype03Button: React.FC<{
  className?: string
  caption: string
  type: number
  disabled?: boolean
  onClick?: () => void
  icon?: boolean
  loading?: boolean
}> = ({ className, caption, type, disabled, onClick, loading }) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const buttonTypeClassGenerator = () => {
    switch (type) {
      case 1:
        return 'items-center py-4 rounded-xl w-full md:w-[200px] relative bg-SeabiscuitMainThemeColor text-white disabled:opacity-[.5] border-1 border-transparent border-solid text-sm'
      case 2:
        return 'items-center py-4 rounded-xl w-full md:w-[200px] relative bg-white border-[1px] border-solid text-sm border-SeabiscuitGray600ThemeColor text-SeabiscuitGray600ThemeColor disabled:opacity-[.5]'
      case 3:
        return 'items-center py-4 rounded-xl w-full md:w-[200px] relative bg-[#EEEEEF] text-[#484646] disabled:opacity-[.5] border-1 border-transparent border-solid text-sm'
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <button
      onClick={onClick}
      className={`${buttonTypeClassGenerator()} ${className ?? ''} cursor-pointer`}
      disabled={disabled}
    >
      {loading ? <AutorenewRounded fontSize="small" className="animate-spin" /> : caption}
    </button>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventCreateShowInitialDialogSecondaryProps {
  eventInformation: any
  onSelectionConfirmed: any
  onSelectionChanged: IOnSelectionChanged
  loading?: boolean
}

type IOnSelectionChanged = (args: { tag?: string; added?: boolean; reset?: boolean }) => void

//
let maxCount = tags.length / 3
let redundantElemsCount = tags.length % 3
let tempArr: string[] = []
let updatedArr: string[][] = []

while (tempArr.length !== tags.length) {
  if (!tempArr.length && redundantElemsCount) maxCount = maxCount + redundantElemsCount
  else maxCount = tags.length / 3

  let extractedChunk = [...tags].splice(tempArr.length, maxCount)
  updatedArr.push(extractedChunk)
  tempArr = [...tempArr, ...extractedChunk]
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventCreateShowInitialDialogSecondary: React.FC<
  EventCreateShowInitialDialogSecondaryProps
> = (props) => {
  // Hooks and vars
  const [selectedOption] = useState(false)

  const [errors, setErrors] = useState('')
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */
  const onSecondarySelectionConfirm = () => {
    setErrors(
      props.eventInformation?.tags.length === 0 ? 'Please choose discipline as it is required' : ''
    )
    if (props.eventInformation?.tags.length) {
      props.onSelectionConfirmed(selectedOption)
    }
  }

  const handleChange = (data: any, currTag: any) => {
    setErrors('')

    if (currTag === 'All') {
      if (data.added) {
        tags.map((item: any) => {
          return props.onSelectionChanged({
            added: data.added,
            tag: item,
          })
        })
      } else {
        return props.onSelectionChanged({ reset: true })
      }
    } else {
      props.onSelectionChanged(data)
    }
  }

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  return (
    <>
      <div className="text-lg capitalize text-SeabiscuitDark200ThemeColor mb-4 mx-2">
        Select Discipline
      </div>

      <div className="discplineSelectionCont flex flex-col md:flex-row mx-2 h-[56vh] overflow-auto">
        {updatedArr &&
          updatedArr.map((data, index) => {
            return (
              <div
                className="discplineSelectionCont w-full md:w-1/3 mx-2"
                key={`${JSON.stringify(data)}${index}`}
              >
                {data.map((currTag, index) => {
                  return (
                    <Selector
                      key={`${currTag}${index}`}
                      caption={currTag}
                      value={currTag}
                      selected={props.eventInformation?.tags.includes(currTag)}
                      onChange={(data) => {
                        handleChange(data, currTag)
                      }}
                    />
                  )
                })}
              </div>
            )
          })}
      </div>
      {/* <div className="discplineSelectionCont mx-2">

                {tags && tags.length ? tags.map((currTag, index) => {
                    return <Selector
                        key={`${currTag}${index}`}
                        caption={currTag}
                        value={currTag}
                        selected={props.eventInformation?.tags.includes(currTag)}
                        onChange={(data) => { handleChange(data, currTag, index) }}
                    />
                }) : null
                }

            </div> */}

      {errors ? <MessageHelperComp className="mx-2" isError={true} message={errors} /> : null}

      <div className="w-full mt-[30px] mx-auto flex justify-center flex-wrap gap-4">
        <Prototype03Button
          caption="NEXT"
          type={1}
          disabled={!!errors || props.loading}
          loading={props.loading}
          onClick={onSecondarySelectionConfirm}
        />
      </div>
    </>
  )
}

export default EventCreateShowInitialDialogSecondary
