import TimeLib from '../../../../lib/Time'

export const DATA_FIRESTORE_V01_USER_HORSE_MAPPING = {
  NAME: 'v01_user_horse_mapping',
  FIELDS: {
    V: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_ID: {
      KEY: 'userId',
      VALUES: {
        DEFAULT: null,
      },
    },

    HORSE_OWNER_ID: {
      KEY: 'horseOwnerId',
      VALUES: {
        DEFAULT: null,
      },
    },

    HORSE_ID: {
      KEY: 'horseId',
      VALUES: {
        DEFAULT: null,
      },
    },

    HORSE_SELECTED_FOR_COMPETETION: {
      KEY: 'horseSelectedForCompeletion',
      VALUES: {
        NO: false,
        YES: true,
        DEFAULT: false,
      },
    },

    IS_MY_HORSE: {
      KEY: 'isMyHorse',
      VALUES: {
        NO: false,
        YES: true,
        DEFAULT: false,
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    UPDATED: {
      KEY: 'modified',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
