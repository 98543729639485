import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { SignupStages } from './UserSignupViewComponentContextApi'
import Prototype03Button from './global/UserSignupViewComponentButton'
import useToasterHelper from '../../../../../helpers/ToasterHelper'

import { Tooltip } from '@mui/material'

import clsx from 'clsx'
import { USE_MODE_CONST } from '../../../../../const/use-mode'

const Selector: React.FC<{
  caption: string
  selected: string
  disabled?: boolean
  onChange: (value: string) => void
  value: string
  icon: string
  icon2: string
  tooltipText?: string | null
}> = ({ caption, selected, onChange, value, icon, icon2, tooltipText, disabled }) => (
  <button
    onClick={() => onChange(value)}
    className={clsx(
      `text-[14px] border-white border-solid border-[1px] rounded-xl flex w-full p-4 gap-4 items-center mt-3 text-white justify-between ${
        selected === value
          ? 'bg-white !text-SeabiscuitMainThemeColor border-white !border-transparent'
          : ''
      }`,
      disabled && 'bg-[#ffffff28]'
    )}
  >
    <div className="flex items-center gap-4">
      <span>
        <img src={selected === value ? icon : icon2} alt="icon" />
      </span>
      <span className="text-left">{caption}</span>
    </div>

    {!!tooltipText && (
      <Tooltip
        title={<h1 className="tooltip_title">{tooltipText}</h1>}
        placement="top"
        arrow
        className={`${selected === value ? '' : 'brightness-200'}`}
      >
        <img
          src={selected === value ? '/assets/og_icons/Help-2.svg' : '/assets/og_icons/Help.svg'}
          alt="questionMark"
          className=""
        />
      </Tooltip>
    )}
  </button>
)

const UserSignupViewComponentSignup: React.FC = () => {
  const [stage, setStage] = useContext(SignupStages)
  const toastFunctions = useToasterHelper()

  const selectorHandle = (value: string) => {
    if (value === 'vendor') {
      return null
    }
    setStage({ ...stage, selector: value })
  }

  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  const stageWay = () => {
    if (stage.selector === 'event') {
      // stageHandle("EventOrg");
      stageHandle('SignUp')
    }
    if (stage.selector === 'vendor') {
      stageHandle('VendorForm')
    }
    if (stage.selector === 'competitor' || stage.selector === USE_MODE_CONST.STAFF) {
      // stageHandle("CompOrgRoleType");
      stageHandle('SignUp')
    }
  }

  return (
    <div className="w-full ">
      <div>
        <div>
          <p className="text-[16px] text-white mt-4"></p>
          <div className="text-white m-0 md:text-[50px] sm:text-[40px] font-bold mt-10 mb-10 leading-10">
            Let’s get started
            <span className="text-SeabiscuitMainThemeColor md:text-[50px] sm:text-[40px] leading-10">
              .
            </span>
          </div>
        </div>
        <div>
          <p className="text-[18px] text-white mt-8">Select profile</p>
        </div>
        <div>
          <Selector
            caption="Exhibitor"
            value="competitor"
            icon="/assets/og_icons/User-2.svg"
            icon2="/assets/og_icons/User-4.svg"
            tooltipText="Riders, trainers, grooms, volunteers, etc. Use this account if you are a person creating a personal account."
            selected={stage.selector}
            onChange={selectorHandle}
          />
          <Selector
            caption="Event Organizer"
            value="event"
            icon2="/assets/og_icons/Clipboard-2.svg"
            icon="/assets/og_icons/Clipboard-3.svg"
            tooltipText="Show Managers only. Create your organizations business account to manage events, receive payments, etc."
            selected={stage.selector}
            onChange={selectorHandle}
          />
          <Selector
            caption="Show Staff & Volunteers"
            value={USE_MODE_CONST.STAFF}
            icon2="/assets/og_icons/iconStaff.svg"
            icon="/assets/og_icons/iconStaff-2.svg"
            tooltipText="Show Staff & Volunteers"
            selected={stage.selector}
            onChange={selectorHandle}
          />
          <Selector
            caption="Vendor or Sponsor"
            value="vendor"
            tooltipText="Sponsors, vendors, local businesses. Use this account to sponsor or vendor equestrian events."
            icon="/assets/og_icons/Department Shop-2.svg"
            icon2="/assets/og_icons/Department Shop-4.svg"
            disabled={true}
            selected={stage.selector}
            onChange={() =>
              toastFunctions.success({
                message: 'Coming Soon!',
              })
            }
          />
        </div>
        {stage.selector && stage.selector !== '' ? (
          <div className="w-[70%] mt-[40px] mx-auto">
            <Prototype03Button
              caption="NEXT"
              kind={4}
              disabled={!stage.selector}
              onClick={stageWay}
            />
          </div>
        ) : null}

        <div className="text-white my-2 text-center text-sm mt-8">
          Already have an account?{' '}
          <Link className="text-white underline cursor-pointer" to="/login">
            Sign in
          </Link>
        </div>
      </div>
    </div>
  )
}

export default UserSignupViewComponentSignup
