import { createAsyncThunk } from '@reduxjs/toolkit'
import { where } from 'firebase/firestore'
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { IHorseTeamInterface } from '../../../models/horse-team/horseTeam.interface'
import FirestoreService from '../../../services/firestoreService'
import { HorseTeamModel } from '../../../models/horse-team/horseTeam.model'
import { getConvertedData } from '../../../models/interface.helper'
import { UserHorseMappingModel } from '../../../models/user-horse-mapping/userHorseMapping.model'
import { IUserHorseMappingInterface } from '../../../models/user-horse-mapping/userHorseMapping.interface'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type IGetHorseTeamMembersThunk = {
  req: {
    userHorseMappingDocId: string
  }
}

const getHorseTeamMembersThunk = createAsyncThunk(
  'getHorseTeamMembersThunk',
  async (payload: IGetHorseTeamMembersThunk['req'], thunkApi) => {
    let message: string | null = null
    let horseTeam: IHorseTeamInterface[] = []
    let mapping: IUserHorseMappingInterface | null = null

    try {
      const horseMappingSnapshot = await FirestoreService.getItem(
        COLLECTIONS.USER_HORSE_MAPPING.NAME,
        payload.userHorseMappingDocId
      )

      if (!horseMappingSnapshot.exists()) {
        throw new Error('Horse not found')
      }

      mapping = getConvertedData(
        UserHorseMappingModel.fromFirestoreDoc(horseMappingSnapshot).toObject()
      )

      const horseSnapshot = await FirestoreService.getItem(
        COLLECTIONS.HORSES.NAME,
        mapping.horseId!!
      )

      if (!horseSnapshot.exists()) {
        throw new Error('Horse not found')
      }

      const horseTeamMemberSnapshots = await FirestoreService.filterItems(
        COLLECTIONS.HORSE_TEAM.NAME,
        [where(COLLECTIONS.HORSE_TEAM.FIELDS.USER_HORSE_MAPPING_ID.KEY, '==', mapping.id)]
      )

      horseTeamMemberSnapshots.forEach((currHorseTeamMemberSnapshot) => {
        horseTeam.push(
          getConvertedData(HorseTeamModel.fromFirestoreDoc(currHorseTeamMemberSnapshot).toObject())
        )
      })

      return {
        team: horseTeam,
      }
    } catch (error: any) {
      message = error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG
      return thunkApi.rejectWithValue({
        message,
      })
    }
  }
)

export { getHorseTeamMembersThunk }
