import { v4 as uuidv4 } from 'uuid'

export const ClassesList = [
  {
    key: 'equitation',
    category: [
      {
        code: 1900,
        name: 'Pony Hunter Breeding',
        subCategories: [
          {
            code: 1903,
            name: 'Pony Hunter Breeding Yearling',
            date: 'Thursday, 23rd',
            messurement: '2.9',
            judge: 'Amy Adams, Amy wilson',
            totalClasses: 5,
            minMaxFees: '$150/60',
            subClasses: [
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Division entry',
                classTag: '',
                fees: '150',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Class A',
                classTag: 'Under Saddle',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Class B',
                classTag: 'Over Fence',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Class C',
                classTag: 'Handy',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Class D',
                classTag: 'Handy',
                fees: '60',
              },
            ],
          },
          {
            code: 1904,
            name: 'Pony Hunter Breeding Yearling for',
            date: 'Thursday, 23rd',
            messurement: '2.9',
            judge: 'Amy Adams, Amy wilson',
            totalClasses: 5,
            minMaxFees: '$150/60',
            subClasses: [
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Division entry',
                classTag: '',
                fees: '150',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Class A',
                classTag: 'Under Saddle',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Class B',
                classTag: 'Over Fence',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Class C',
                classTag: 'Handy',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Class D',
                classTag: 'Handy',
                fees: '60',
              },
            ],
          },
        ],
      },
      {
        code: 2000,
        name: 'Pony Hunter Breeding 2',
        subCategories: [
          {
            code: 1904,
            name: 'Pony Hunter Breeding Yearling',
            date: 'Thursday, 23rd',
            messurement: '2.9',
            judge: 'Amy Adams, Amy wilson',
            totalClasses: 5,
            minMaxFees: '$150/60',
            subClasses: [
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Division entry',
                classTag: '',
                fees: '150',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Class A',
                classTag: 'Under Saddle',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Class B',
                classTag: 'Over Fence',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Class C',
                classTag: 'Handy',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Class D',
                classTag: 'Handy',
                fees: '60',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 'hunter',
    category: [
      {
        code: 1900,
        name: 'Pony Hunter Breeding',
        subCategories: [
          {
            code: 1904,
            name: 'Pony Hunter Breeding Yearling',
            date: 'Thursday, 23rd',
            messurement: '2.9',
            judge: 'Amy Adams, Amy wilson',
            totalClasses: 5,
            minMaxFees: '$150/60',
            subClasses: [
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Division entry',
                classTag: '',
                fees: '150',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Division entry',
                classTag: 'Under Saddle',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Division entry',
                classTag: 'Over Fence',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Division entry',
                classTag: 'Handy',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Division entry',
                classTag: 'Handy',
                fees: '60',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: 'jumper',
    category: [
      {
        code: 1900,
        name: 'Pony Hunter Breeding',
        subCategories: [
          {
            code: 1904,
            name: 'Pony Hunter Breeding Yearling',
            date: 'Thursday, 23rd',
            messurement: '2.9',
            judge: 'Amy Adams, Amy wilson',
            totalClasses: 5,
            minMaxFees: '$150/60',
            subClasses: [
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Division entry',
                classTag: '',
                fees: '150',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Division entry',
                classTag: 'Under Saddle',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Division entry',
                classTag: 'Over Fence',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Division entry',
                classTag: 'Handy',
                fees: '60',
              },
              {
                code: uuidv4(),
                competitorhorseName: 'chetak',
                competitorName: 'John',
                subClassName: 'Division entry',
                classTag: 'Handy',
                fees: '60',
              },
            ],
          },
        ],
      },
    ],
  },
]
