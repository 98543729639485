// Constants
import { DurationList } from '../../../../../fakeData/durationList'
import { FEES_CATEGORY_CONST } from '../EventDetailsViewComponentFees'

// Components
import ModalComponent from '../global/modalComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentFeeCart: React.FC<{ data: Array<any> }> = ({ data }) => {
  // Functions
  const getDurationText = (duration: string | { label: string; value: string }): string => {
    let valueToReturn = ''
    let durationSelected = (() => {
      let ds
      if (typeof duration === 'string') ds = DurationList.find((curr) => curr.value === duration)
      else ds = DurationList.find((curr) => curr.value === duration.value)
      return ds
    })()
    if (durationSelected) {
      valueToReturn = durationSelected.text
    }
    return valueToReturn
  }

  const getTitleOfFees = (row: any): string => {
    let price = row?.cost
    let valueToReturn = ''
    let percentage = row?.percentage
    let units = row?.available ?? row?.available
    let isGovernanceCategoryFee = row?.category === FEES_CATEGORY_CONST.GOVERNANCE

    if (isGovernanceCategoryFee) {
      valueToReturn = `$${price}`
      return valueToReturn
    }

    if (!Number(percentage)) if (Number(units)) valueToReturn = `${units} available`

    if (Number(price) && (row?.duration || isGovernanceCategoryFee) && !Number(percentage))
      valueToReturn = `${valueToReturn ? valueToReturn : '0 available'}, $${price} ${getDurationText(row?.duration)}`
    else if (!Number(percentage)) valueToReturn = `N/A`

    if (!units && Number(percentage))
      if (Number(percentage)) valueToReturn = `${percentage ?? 0}% refunded`

    return valueToReturn
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div className="border-transparent rounded-md py-4 sm:p-4 relative">
      <div className="mt-4 flex flex-col mb-0 sm:mb-10 justify-center">
        {data.map((row, index) => {
          if (!row?.status) return null

          return (
            <span className="w-100 flex mb-4" key={`${JSON.stringify(row)}${row?.title}${index}`}>
              <div className="mb-4 w-[120px] mr-0 shrink-0 text-SeabiscuitDark200ThemeColor text-[16px] pr-4 capitalize">
                {row.title}
              </div>
              <div className="mb-4 text-SeabiscuitDark200ThemeColor text-[16px] sm:block">
                <div className="flex text-SeabiscuitDark200ThemeColor mb-4">
                  {getTitleOfFees(row)}
                </div>

                {FEES_CATEGORY_CONST.GOVERNANCE !== row?.category ? (
                  <>
                    {row?.note ? (
                      <div className="last:hidden sm:last:block whitespace-nowrap last:whitespace-normal text-SeabiscuitLightParagraphTextColor text-ellipsis overflow-hidden">
                        {row.note}
                      </div>
                    ) : (
                      <div className="text-SeabiscuitLightParagraphTextColor">
                        No details were provided
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            </span>
          )
        })}
      </div>
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentAllFeesModal: React.FC<{
  show: boolean
  onHide?: () => void
  handleModal?: any
  dataToPassOn?: any
}> = ({ show, onHide, handleModal, dataToPassOn }) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <ModalComponent
      show={show}
      onHide={onHide}
      handleModal={handleModal}
      modalFor="eventsFees"
      type="full-modal"
      size="md"
      title_icon={dataToPassOn?.icon}
      title={dataToPassOn?.title}
    >
      {<EventDetailViewComponentFeeCart data={dataToPassOn?.data} />}
    </ModalComponent>
  )
}

export default EventDetailViewComponentAllFeesModal
