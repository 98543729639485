import React from 'react'

interface IProps {
  color?: string;
}

export const IconErrorSvg: React.FC<IProps> = ({ color = '#F7074F' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" fill="none" viewBox="0 0 20 19">
      <path
        fill={color}
        d="M10 .527c-.777 0-1.554.38-1.974 1.138L.286 15.648c-.817 1.475.288 3.353 1.974 3.353h15.481c1.686 0 2.791-1.878 1.975-3.353L11.975 1.665C11.555.906 10.777.527 10 .527zm0 1.449c.255 0 .508.138.662.416l7.742 13.983c.296.536-.051 1.126-.663 1.126H2.26c-.611 0-.959-.59-.662-1.126l7.74-13.983c.154-.278.408-.416.662-.416zM9.99 5.99a.75.75 0 00-.739.761v5a.75.75 0 101.5 0v-5a.749.749 0 00-.761-.76zM10 14.001a1 1 0 100 2 1 1 0 000-2z"
      ></path>
    </svg>
  )
}
