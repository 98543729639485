import CompetitorEventRegisterContainer from '../CompetitorEventRegisterContainer'

import EventRegistrationTabs from '../event-registration-tabs/EventRegistrationTabs'
import { motion } from 'framer-motion'

type Props = {
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
}

const CompetitorClinicAndOtherRoot = (props: Props) => {
  return (
    <CompetitorEventRegisterContainer
      detailPageUrl="detailPageUrl"
      title="Clinic and other"
      clinicPage={true}
    >
      <motion.div
        className="w-full mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 0.1,
        }}
      >
        <EventRegistrationTabs clinicPage={true} handleModal={props.handleModal} />
      </motion.div>
    </CompetitorEventRegisterContainer>
  )
}

export default CompetitorClinicAndOtherRoot
