import { useEffect, useState } from 'react'
import { IconPlusCircle } from '../../../components/icons/IconPlusCircle'
import { IPaperworkTeam } from '../../../store/paperworks/paperworkSlice'
import { AccordionWrapper } from './AccordionWrapper'
import customImageComponent from '../../../components/common/CustomImageComponent'

interface IPropsHorseAccordion {
  handleToggle: (value: number) => void
  openedMenu: number[]
  accordionIndex: number
  team: IPaperworkTeam[]
  getDocumentLink: (eventId: string) => void
  eventId: string | null
  addSignerHandler: () => void
}

export const HorseAccordionWrapper = ({
  team,
  getDocumentLink,
  eventId,
  addSignerHandler,
}: IPropsHorseAccordion) => {
  const [isOpen, setIsOpen] = useState(true)
  const [openedMenu_, setOpenedMenu_] = useState<number[]>([])
  const [signMissing, setSignMissing] = useState(0)

  useEffect(() => {
    const totalProblemsFound = team.reduce((sum, item) => {
      if (!item.member) {
        return sum
      }
      return sum + item.member.problem_found
    }, 0)
    setSignMissing(totalProblemsFound)
  }, [team])

  const handleToggle_ = (value: number) => {
    const exist = openedMenu_.includes(value)

    if (exist) {
      setOpenedMenu_(() => {
        return openedMenu_.filter((current) => {
          return current !== value
        })
      })
    } else {
      setOpenedMenu_((prevState) => [...prevState, value])
    }
  }
  const uniqueRoles = team && new Set(team.map((item) => item.member?.signatoryDefaultRole))

  const totalProblemsFound = team.reduce((sum, item) => {
    if (!item.member) {
      return sum
    }
    return sum + item.member.problem_found
  }, 0)

  return (
    <>
      <div className="mt-2 border-[#D3DAEE] border px-4 py-2 pl-2 rounded-lg transition-all">
        <div className="text-SeabiscuitDark200ThemeColor">
          <div
            onClick={(e: any) => {
              setIsOpen(!isOpen)
            }}
            className={
              'flex flex-col lg:flex-row lg:justify-between lg:items-center font-semibold accordionParent cursor-pointer'
            }
          >
            <div>
              <div className="flex items-center">
                <span className="w-10 h-10 rounded-md overflow-hidden mr-4">
                  {customImageComponent(
                    team[0] && team[0].horseProfilePicture,
                    team[0] && team[0].horseName,
                    'w-full h-full object-cover'
                  )}
                </span>
                <div className="flex flex-col">
                  <span className="text-md text-SeabiscuitDark200ThemeColor font-semibold capitalize">
                    {team[0].horseName || 'N/A'}
                    <div className="font-normal text-[12px] text-[#3C4B71B2]">
                      {team ? team.length : 0} team member
                      {team && team.length > 1 && 's'} • {uniqueRoles ? uniqueRoles.size : 0} role
                      {(uniqueRoles && uniqueRoles.size > 1) || !uniqueRoles ? 's' : ''}
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-3 lg:mt-0 items-center">
              <div className="font-normal text-[14px] text-SeabiscuitDark200ThemeColor">
                {signMissing === 0
                  ? 'All paperwork signed'
                  : `${signMissing} ${signMissing === 1 ? 'signature' : 'signatures'} missing`}
              </div>
              <div className="status text-SeabiscuitGreenThemeColor rounded-full p px-4 add_icon cursor-pointer">
                {isOpen ? (
                  <div className={'add_icon cursor-pointer'}>
                    <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                  </div>
                ) : (
                  <div className={'remove_icon cursor-pointer'}>
                    <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={`faqs-content my-2 ${isOpen ? 'activeTab' : ''}`}>
            {team &&
              team.map((paperworkMember, i) => {
                // if (!horse.member) return <></>

                return (
                  <AccordionWrapper
                    key={`${JSON.stringify(paperworkMember.member)}${i}`}
                    openedMenu={openedMenu_}
                    handleToggle={handleToggle_}
                    accordionIndex={i}
                    userData={paperworkMember}
                    getDocumentLink={getDocumentLink}
                    eventId={eventId}
                    cbSingSuccess={() => setSignMissing((v) => v - 1)}
                  />
                )
              })}

            <div>
              <button
                className={'mt-3 cursor-pointer mx-auto gap-2 flex items-center text-[#122B4666]'}
                onClick={addSignerHandler}
              >
                <IconPlusCircle />
                <span className={'underline'}>Add signer</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
