export const DATA_FIRESTORE_V01_EVENT_REVIEW_PUBLISH = {
  NAME: 'v01_event_review-publish',

  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    ID: {
      KEY: 'id',
    },
  },
}
