export const UI_VENDOR_AND_SPONSOR_CONST = {
  TABS: [
    {
      NAME: 'News',
      ICON: 'assets/og_icons/Newspaper-1.svg',
    },
    {
      NAME: 'Deals',
      ICON: 'assets/og_icons/Sale Price Tag-1.svg',
    },
    {
      NAME: 'Products',
      ICON: 'assets/og_icons/Price Tag-1.svg',
    },
    {
      NAME: 'Message',
      ICON: 'assets/og_icons/Speech Bubble-1.svg',
    },
    {
      NAME: 'About',
      ICON: 'assets/og_icons/Info-1.svg',
    },
  ],
  MESSAGES: {
    NEWS: {
      MESSAGE: 'Post a news update. Eg. Product demo at your event booth today.',
    },
    DEALS: {
      MESSAGE: 'Create a deal. Eg. A discount on an item of clothing using a discount code.',
    },
    PRODUCTS: {
      MESSAGE: 'Create a product. Eg. An item of clothing or a services contact form.',
    },
  },
  DUMMY_DATA: {
    NEWS: [
      {
        picture: '/assets/placeholders/Empty Event Portrait.png',
        title: 'Beth Cross signing at Ariat tent today!',
        headLine:
          'Ariat CEO Beth Cross signing today at Ariat tent at 12:30, Day 2, Kentucky Rolex Three Day.',
        description: `Mrs. Cross will host a fire side chat about how she started Ariat, the good and the bad. There will be seats avaiable for the first 50 spectators, and there will be Live Q&A`,
        modified: new Date(1639278435755),
        created: new Date(1639278435755),
      },
      {
        picture: '/assets/placeholders/Empty Event Portrait.png',
        title: 'Ariat’s not yet released boots on display at tent',
        headLine: `Never before seen boots are on display at the Ariat tent today!`,
        description: `Come over for a sneak peak. We will have multipel sizes available to try on, with staff there to help you find the right boot for you`,
        modified: new Date(1639278435755),
        created: new Date(1639278435755),
      },
      {
        picture: '/assets/placeholders/Empty Event Portrait.png',
        title: 'Beth Cross signing at Ariat tent today!',
        headLine: `Boyd Martin signing at Ariat tent 8:30 today!.`,
        description: `Boyd Martin currently leads the national points and is the favorite to sit on top of the leader...More.`,
        modified: new Date(1639278435755),
        created: new Date(1639278435755),
      },
    ],
    DEALS: [
      {
        category: 'apparel',
        picture: [
          '/assets/placeholders/Empty Event Portrait.png',
          '/assets/placeholders/Empty Event Portrait.png',
        ],
        title: 'Casual dual tone shirt...perfect for rigin on hot days!',
        description: `With beautiful stitching and a traditional R toe, these are classic cowboy through and through. Their comfort and versatility make them a go-to. Bonus: StretchFit makes them easier to pull on and expands to fit a wide range of calves.`,
        price: 180,
        discountPrice: 145,
        modified: new Date(1639278435755),
        created: new Date(1639278435755),
      },
      {
        category: 'apparel',
        picture: [
          '/assets/placeholders/Empty Event Portrait.png',
          '/assets/placeholders/Empty Event Portrait.png',
        ],
        title: 'Miramar Cardigan',
        description: `Snuggled up on the couch with your favorite book or out and about town, this long cardigan is irresistibly cozy. The beautiful open pointelle design is made from an ethically sourced wool blend in a neutral shade that goes with everything.`,
        price: 150,
        discountPrice: 125,
        modified: new Date(1639278435755),
        created: new Date(1639278435755),
      },
      {
        category: 'apparel',
        picture: [
          '/assets/placeholders/Empty Event Portrait.png',
          '/assets/placeholders/Empty Event Portrait.png',
        ],
        title: 'Toughy VentTek 360° Western Boot',
        description: `Like air conditioning for your feet. VentTEK™ panels boost airflow, while the self-cooling lining keeps you comfortable from toe to calf. It makes riding, walking, or working in the heat no big deal.`,
        price: 240,
        discountPrice: 190,
        modified: new Date(1639278435755),
        created: new Date(1639278435755),
      },
    ],
    PRODUCTS: [
      {
        category: 'apparel',
        picture: [
          '/assets/placeholders/Empty Event Portrait.png',
          '/assets/placeholders/Empty Event Portrait.png',
        ],
        title: 'Casual dual tone shirt...perfect for rigin on hot days!',
        description: `With beautiful stitching and a traditional R toe, these are classic cowboy through and through. Their comfort and versatility make them a go-to. Bonus: StretchFit makes them easier to pull on and expands to fit a wide range of calves.`,
        price: 180,
        discountPrice: 145,
        modified: new Date(1639278435755),
        created: new Date(1639278435755),
      },
      {
        category: 'apparel',
        picture: [
          '/assets/placeholders/Empty Event Portrait.png',
          '/assets/placeholders/Empty Event Portrait.png',
        ],
        title: 'Miramar Cardigan',
        description: `Snuggled up on the couch with your favorite book or out and about town, this long cardigan is irresistibly cozy. The beautiful open pointelle design is made from an ethically sourced wool blend in a neutral shade that goes with everything.`,
        price: 150,
        discountPrice: 125,
        modified: new Date(1639278435755),
        created: new Date(1639278435755),
      },
      {
        category: 'apparel',
        picture: [
          '/assets/placeholders/Empty Event Portrait.png',
          '/assets/placeholders/Empty Event Portrait.png',
        ],
        title: 'Toughy VentTek 360° Western Boot',
        description: `Like air conditioning for your feet. VentTEK™ panels boost airflow, while the self-cooling lining keeps you comfortable from toe to calf. It makes riding, walking, or working in the heat no big deal.`,
        price: 240,
        discountPrice: 190,
        modified: new Date(1639278435755),
        created: new Date(1639278435755),
      },
    ],
  },
}
