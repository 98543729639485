// Components
import ManageEventRegisterRevenueList from './Tabs/ManageEventRegisterRevenueList'

type IProps = {
  eventId: string
  eventTab: string
  searchValue: string
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ManageEventRevenueDisplayTabs = (props: IProps) => {
  // Hooks and vars
  const { eventId, eventTab, searchValue } = props

  return (
    <ManageEventRegisterRevenueList
      eventId={eventId}
      activeTab={eventTab}
      searchValue={searchValue}
    />
  )
}

export default ManageEventRevenueDisplayTabs
