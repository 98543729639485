export const horseColorList = [
  { label: 'Bay', value: 'bay' },
  { label: 'Black', value: 'black' },
  { label: 'Blue Dun', value: 'bluedun' },
  { label: 'Blue Roan', value: 'blueroan' },
  { label: 'Buckskin', value: 'buckskin' },
  { label: 'Chestnut', value: 'chestnut' },
  { label: 'Cremello', value: 'cremello' },
  { label: 'Dapple Grey', value: 'dapplegrey' },
  { label: 'Dun', value: 'dun' },
  { label: 'Fleabitten Grey', value: 'fleabittengrey' },
  { label: 'Gold Champagne', value: 'goldchampagne' },
  { label: 'Grey', value: 'grey' },
  { label: 'Grullo', value: 'grullo' },
  { label: 'Liver Chestnut', value: 'liverchestnut' },
  { label: 'Palomino', value: 'palomino' },
  { label: 'Perlino', value: 'perlino' },
  { label: 'Pie Bald', value: 'piebald' },
  { label: 'Red Dun', value: 'reddun' },
  { label: 'Skew Bald', value: 'skewbald' },
  { label: 'Strawberry Roan', value: 'strawberryroan' },
  { label: 'Other', value: 'other' },
]
