import { useState } from 'react'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import DraftDeals from './components/deals/DraftDeals'
import LiveDeals from './components/deals/LiveDeals'
import TabList from './components/tabs/TabList'

const VendorDeals = () => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <WrapperContainer title="Deals" className="">
      <div className="flex flex-col gap-[30px]">
        <TabList activeTab={activeTab} setActiveTab={setActiveTab} />

        {activeTab === 0 ? <LiveDeals /> : <DraftDeals />}
      </div>
    </WrapperContainer>
  )
}

export default VendorDeals
