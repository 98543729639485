import { createAsyncThunk } from '@reduxjs/toolkit'
import { where } from 'firebase/firestore'
import helpers from '../../../commonHelpers/helpers'
import { CONST } from '../../../const/const'
import { getConvertedData } from '../../../models/interface.helper'
import { IRefundRequestInterface } from '../../../models/refund-requests/refundRequest.interface'
import RefundRequestModel from '../../../models/refund-requests/refundRequest.model'
import FirestoreService from '../../../services/firestoreService'
import IExhibitorSlice from '../types'

const getRefundRequestsPerRecipientThunk = createAsyncThunk<
  IExhibitorSlice['IGetRefundRequestThunkSuccessResponse'],
  IExhibitorSlice['IGetRecipientThunkErrorResponse']
>(
  'getRefundRequestsPerRecipientThunk',
  async (arg: IExhibitorSlice['IGetRecipientThunkPayload'], thunkApi) => {
    let amountRefunded = 0
    let amountRequested = 0
    let refundRequests: IRefundRequestInterface[] = []
    let refundRequest: IRefundRequestInterface = null as any

    try {
      const docSnapshot = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.REFUND_REQUEST.NAME,
        [
          where(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.REFUND_REQUEST.FIELDS.RECIPIENT_USER_ID.KEY,
            '==',
            arg.recipientId
          ),
          where(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.REFUND_REQUEST.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            arg.registrationId
          ),
        ]
      )

      docSnapshot.docs.forEach((currRefundSnapshot) => {
        refundRequest = RefundRequestModel.fromFirestoreDoc(currRefundSnapshot).toObject()
        refundRequests.push(getConvertedData(refundRequest))
        amountRefunded += refundRequest.amountRefunded
        amountRequested += refundRequest.amountRequested
      })

      return {
        requests: refundRequests,
        amountRefunded,
        amountRequested,
      }
    } catch (error: any) {
      helpers.logger({
        message: error,
      })

      return thunkApi.rejectWithValue({
        message: error?.message,
      })
    }
  }
)

export { getRefundRequestsPerRecipientThunk }
