// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormBuilderUtilDivisionRowAccordionDetailsComponent = styled(
  MuiAccordionDetails
)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

export default EventPrizeListFormBuilderUtilDivisionRowAccordionDetailsComponent
