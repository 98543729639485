import { CONST } from '../../../../../../../const/const'
import { IExhibitorDataTypes } from './exhibitor.data.types'
import { ITabs } from '../../../../../../../store/organizer/organizerSlice.types'

const IMAGE_URLS = {
  OK: '/assets/cp_icons/Ok-3.svg',
  NOT_OK: '/assets/cp_icons/Cancel-2.svg',
}

const TAB_NAMES = {
  ALL: 'All',
  COMPLIANT: 'Compliant',
  NON_COMPLIANT: 'Non-Compliant',
  SCRATCHED: 'Scratched',
}

const TABS: ITabs[] = [
  { name: 'all', tab: TAB_NAMES.ALL },
  { name: 'complaint', tab: TAB_NAMES.COMPLIANT, disabled: true },
  { name: 'non-complaint', tab: TAB_NAMES.NON_COMPLIANT, disabled: true },
  { name: 'scratch', tab: TAB_NAMES.SCRATCHED, disabled: true },
]

const COLS = {
  NAME: 'Name',
  TEAM: 'Team',
  HORSE: 'Horse',
  PAYMENT: 'Payment',
  PROFILE: 'Profile',
  CONTACT: 'Contact',
  EXHIBITOR: 'Rider',
  PAPERWORK: 'Paperwork',
  ELIGIBILITY: 'Eligibility',
}

const EVENT_USERS_REGISTERED = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const REGISTER_TABLE_FIELDS = EVENT_USERS_REGISTERED.FIELDS

const PAYMENT_STATUS = { PAID: 'paid' }

const DEFAULT_PROFILE_PIC = '/assets/og_icons/User-1.svg'

const DEFAULT_VALUES = {
  EMPTY: '',
  UNKNOWN: 'Unknown',
  PROFILE_PIC: DEFAULT_PROFILE_PIC,
}

const QUEUEDDATA_INI_VAL: IExhibitorDataTypes['IQueuedData'] = {
  rehit: false,
  registrations: [],
  batchStatus: 'stop',
  scrollWasCancelled: false,
  registrationsWithTeamMembers: [],
}

export const MANAGE_CLINIC_EXHIBITOR_CONSTS = {
  COLS,
  TABS,
  TAB_NAMES,
  IMAGE_URLS,
  COLLECTIONS,
  DEFAULT_VALUES,
  PAYMENT_STATUS,
  QUEUEDDATA_INI_VAL,
  DEFAULT_PROFILE_PIC,
  EVENT_USERS_REGISTERED,
  REGISTER_TABLE_FIELDS,
}
