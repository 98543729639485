import { Clear } from '@mui/icons-material'
import { useState } from 'react'
import { MODAL_CONSTS } from '../../const/modal-const'
import AuthenticationModalLoginComponent from './authenticationModalComponent/AuthenticationModalLoginComponent'
import AuthenticationModalSignupComponent from './authenticationModalComponent/AuthenticationModalSignupComponent'

type Props = {
  show: boolean
  handleModal?: any
  dataToPassOn?: any
}

const AuthenticationModal = (props: Props) => {
  const [step] = useState<string>('login')

  const closeAuthModal = () => {
    props.handleModal(false, MODAL_CONSTS.AUTHENTICATION)
  }

  return (
    <>
      <div
        className={`fixed left-0 right-0 top-0 bottom-0 z-10  transition-all ${props.show ? 'visible' : 'invisible'}`}
      >
        <div
          className={`max-h-[463px] fixed bg-white px-4 pt-4 rounded-xl z-10 left-[calc(50%_-_1rem)] flex flex-col top-[calc(50%_-_1rem)] translate-x-[-50%] translate-y-[-60%] ${props.show ? '!translate-y-[-50%]' : ''} w-[calc(100%_-_2rem)] max-w-md m-2 opacity-${props.show ? '1' : '0'} transition-all `}
        >
          <div className="max-h-screen min-h-[calc(100vh-200px)]">
            <div className="text-right">
              <button onClick={closeAuthModal}>
                {/* <img className='hover:bg-[#F7074F1A] rounded-full p-1 w-7 h-7' src="/assets/cp_icons/Close.svg" /> */}
                <Clear
                  fontSize={'small'}
                  style={{
                    color: '#122B46',
                    fontWeight: '400',
                  }}
                />
              </button>
            </div>
            <h2 className="m-0 text-[25px] text-center text-SeabiscuitDark200ThemeColor font-bold">
              Log in to your account
            </h2>

            <div className="p-4">
              {step === 'login' ? (
                <AuthenticationModalLoginComponent
                  closeAuthModal={closeAuthModal}
                  handleModal={props.handleModal}
                  redirectAfterLogin={props.dataToPassOn}
                />
              ) : (
                <AuthenticationModalSignupComponent />
              )}
            </div>
          </div>
        </div>
        <div
          className={`fixed left-0 right-0 top-0 bottom-0 bg-black/30 opacity-${props.show ? '1' : '0'} transition-all`}
        ></div>
      </div>
    </>
  )
}

export default AuthenticationModal
