import { PDFDocument } from 'pdf-lib'

import { convertDateString, createTable, savePdf } from '../../../../helpers/pdf'
import { IEventInterface } from '../../../../models/events/event.interface'

export const createOrderOfGoByRiderPdf = async (event: IEventInterface, rows: string[][]) => {
  // Create a new PDF document
  const pdfDoc = await PDFDocument.create()

  const headers = ['Rider • Coach', 'Horse • Trainer', 'Class', 'Date', 'Ride time']
  const colWidths = [15, 15, 40, 15, 15] // in %

  const textAboveTheFirstTable = `**Alphabetical Order** • By First Name • ^^${rows.length} rides^^`

  const printedText = `^^By Rider • Printed ${convertDateString(new Date(), 'tdm')}^^`
  const titleText = 'Order of Go'
  const urlText = 'www.thepegasus.app'

  await createTable({
    pdfDoc,
    headers,
    rows,
    colWidths,
    textAboveTheFirstTable,
    printedText,
    titleText,
    urlText,
    event,
  })

  await savePdf(pdfDoc, `Event Reports - Order of Go by rider - ${event?.eventName}.pdf`)
}
