import { useIonRouter } from '@ionic/react'
import { AutorenewRounded } from '@mui/icons-material'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { CONST } from '../../../../const/const'
import useHorses from '../../../../hooks/users/competitor/profile/useHorses'
import { IHorseData } from '../../../../models/horse/horse.interface'
import customImageComponent from '../../../common/CustomImageComponent'
import ViewsLoader from '../../../loader/ViewsLoader'
import PROFILE_DETAIL_CARD_CONST from './profileDetailCard.const'
import useProfileDetailCard from './useProfileDetailCard'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from '../../../../store/hooks'
import { selectUserId } from '../../../../store/user/userSlice'

type IProps = {
  showHorseData?: IHorseData[]
  setActiveTab?: (value: React.SetStateAction<number>) => void
}
const DEFAULT_HORSE_PIC = '/assets/og_icons/YearofHorse.svg'

const ProfileDetailsCard = (props: IProps) => {
  const userId = useAppSelector(selectUserId)

  const { getMapping } = useHorses(userId)
  const { setActiveTab } = props

  const router = useIonRouter()
  const history = useHistory()
  const profileCard = useProfileDetailCard()
  const [mappingId, setMappingId] = useState<any>('')
  const [horseId, setHorseId] = useState<any>('')
  const { showHorseData } = props

  const handleGetMapping = async (owner: string) => {
    return await getMapping(owner)
  }

  useEffect(() => {
    if (showHorseData?.length) {
      if (showHorseData?.[0]?.horseOwnerId) {
        handleGetMapping(showHorseData[0].horseOwnerId).then((res) => {
          setMappingId(res?.[0]?.id)
          setHorseId(res?.[0]?.horseId)
          if (setActiveTab) setActiveTab(3)
        })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHorseData])

  if (profileCard.loading || profileCard.alreadyFollowed === null)
    return (
      <div className="min-h-[204px] flex items-center justify-center">
        <ViewsLoader size="lg" color="#F7074F" />
      </div>
    )

  if (showHorseData && showHorseData.length) {
    return (
      <>
        <span className="w-[110px] h-[110px] rounded-full border-[3px] border-white overflow-hidden">
          {customImageComponent(
            showHorseData[0]?.horseProfilePicture
              ? showHorseData[0]?.horseProfilePicture
              : DEFAULT_HORSE_PIC,
            showHorseData[0].horseName,
            'object-cover w-full bg-white h-full'
          )}
        </span>

        <span className="font-bold text-xl my-3 text-SeabiscuitDark200ThemeColor capitalize">
          {showHorseData[0].horseName}
        </span>

        {profileCard?.isMyProfile ? (
          <button
            type="button"
            className=" mt-3 mb-3 items-center  h-[45px] border-solid border !border-[#122B46] rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitDark200ThemeColor w-[80%] min-w-[150px] bg-white hover:bg-[#122B46] focus:bg-[#122B46] active:bg-[#122B46]  hover:text-white focus:text-white active:text-white "
            onClick={() => {
              router.push(`${CONST.ROUTES.HORSES.SUBMENU.SUB_URL}/${mappingId}/${horseId}`)
              history.push(`${CONST.ROUTES.HORSES.SUBMENU.SUB_URL}/${mappingId}/${horseId}`)
            }}
          >
            Edit Profile
          </button>
        ) : // <div className="flex justify-center w-full">
        //   <button
        //     type="button"
        //     // onClick={profileCard.onFollowBtnClick}

        //     className={
        //       clsx(
        //         "h-[40px] border mr-1 border-solid rounded-xl flex items-center text-[12px] px-4 capitalize text-SeabiscuitDark200ThemeColor border-SeabiscuitDark200ThemeColor w-[34%] justify-around",
        //         "bg-SeabiscuitGray500ThemeColor"
        //       )} >
        //     {profileCard.following ?
        //       <AutorenewRounded fontSize='small' className='animate-spin mx-auto w-[30px]' />
        //       : <>
        //         <img src="/assets/og_icons/Add User Male-1.svg" alt="add user" className="h-[20px] w-[20px]" />
        //         <span className='flex flex-col'>{profileCard.alreadyFollowed ? "Unfollow" : "Follow"}
        //           <span className='text-[7px] p-1 pt-0 pb-0'>Coming soon</span>
        //         </span>
        //       </>}
        //   </button>

        //   <button
        //     type="button"
        //     className="h-[40px] ml-1 border border-solid rounded-xl text-[12px] flex items-center justify-around px-4 capitalize text-SeabiscuitDark200ThemeColor border-SeabiscuitDark200ThemeColor pointer-events-none w-[34%] bg-SeabiscuitGray500ThemeColor"

        //   >
        //     <img src="/assets/og_icons/Speech Bubble-1.svg" alt="message" className="h-[20px] w-[20px]" />
        //     <span className="flex flex-col justify-end ">
        //       <span>Message</span>
        //       <span className='text-[7px] p-1 pt-0 pb-0'>Coming soon</span>
        //     </span>
        //   </button>
        // </div>
        null}
      </>
    )
  }

  return (
    <>
      <span className="w-[110px] h-[110px] rounded-full flex justify-center items-center border-[3px] bg-white border-white overflow-hidden">
        {customImageComponent(
          profileCard?.profilePicture
            ? profileCard?.profilePicture
            : PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC,
          profileCard.userName,
          '!w-[calc(100%-5px)] bg-white !h-[calc(100%-5px)] rounded-full'
        )}
      </span>

      <span className="font-bold text-xl my-3 text-SeabiscuitDark200ThemeColor capitalize">
        {profileCard.userName}
      </span>

      {profileCard?.isMyProfile ? (
        <button
          type="button"
          className=" mt-3 mb-3 items-center  h-[45px] border-solid border !border-[#122B46] rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitDark200ThemeColor w-[80%] min-w-[150px] bg-white hover:bg-[#122B46] focus:bg-[#122B46] active:bg-[#122B46] hover:text-white focus:text-white active:text-white "
          onClick={() => {
            router.push(CONST.ROUTES.PROFILE_SETTINGS.URL)
            history.push(CONST.ROUTES.PROFILE_SETTINGS.URL)
          }}
        >
          Edit Profile
        </button>
      ) : (
        <div className="flex justify-center w-full mb-2">
          <button
            type="button"
            disabled={profileCard.following || profileCard.loading}
            onClick={profileCard.onFollowBtnClick}
            className={clsx(
              'h-[35px] border mr-1 border-solid rounded-full flex items-center text-[12px] px-4 capitalize text-SeabiscuitDark200ThemeColor border-SeabiscuitDark200ThemeColor w-[34%] justify-around',
              profileCard.loading && 'pointer-events-none'
            )}
          >
            {profileCard.following ? (
              <AutorenewRounded fontSize="small" className="animate-spin mx-auto w-[30px]" />
            ) : (
              <>
                <img
                  src="/assets/og_icons/Add User Male-1.svg"
                  alt="add user"
                  className="h-[20px] w-[20px]"
                />
                {profileCard.alreadyFollowed ? 'Unfollow' : 'Follow'}
              </>
            )}
          </button>

          <button
            type="button"
            disabled={profileCard.loading}
            onClick={profileCard.messageUser}
            className="h-[35px] ml-1 border border-solid rounded-full text-[12px] flex items-center justify-around px-4 capitalize text-SeabiscuitDark200ThemeColor border-SeabiscuitDark200ThemeColor w-[34%]"
          >
            <img
              src="/assets/og_icons/Speech Bubble-1.svg"
              alt="message"
              className="h-[20px] w-[20px]"
            />
            <span className="flex flex-col justify-end">
              <span>Message</span>
            </span>
          </button>
        </div>
      )}
    </>
  )
}

export default ProfileDetailsCard
