/* eslint-disable react-hooks/exhaustive-deps */

// ############################################################
/**
 * @TODO Document this
 */
// ############################################################

import React, { useEffect, useState, Suspense } from 'react'

// Third party
import { where } from 'firebase/firestore'
import { motion } from 'framer-motion'

// Styles
import './HomeRootPage.css'

// Component imports
import HomeRootPageContentCategoriesSection from './HomeRootPageContentCategoriesSection'
import HomeRootPageContentUpCompetitionsSection from './HomeRootPageContentUpCompetitionsSection'

// Services
import FirestoreService from '../../../services/firestoreService'
import { selectEvents } from '../../../store/events/eventsSlice'

// Redux
import { useSelector } from 'react-redux'
import { setEventSteps } from '../../../store/events/eventsSlice'
import {
  selectedfilters,
  setActiveFilters,
  setMapVisibilityAc,
} from '../../../store/filters/filterSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  selectUserId,
  setCompetitorProfileData,
  setUserDocumentsData,
} from '../../../store/user/userSlice'

// Constants
import { CONST } from '../../../const/const'
import { USER_TYPES } from '../../profile/root/ProfileRootPageEditProfile'

// Models
import { EventPrizeListsModel } from '../../../models/event-prize-lists/event-prize-lists.model'

// Custom hooks
import useCompetitorProfileHook from '../../../hooks/users/competitor/profile/useCompetitorProfileHook'
import useDocumentHook from '../../../hooks/users/competitor/profile/useDocumentHook'

// Helpers
import { getConvertedData } from '../../../models/interface.helper'

// Services
import Fading from '../../../components/common/overlay/Fading'
import WrapperContainer from '../../../components/common/wrappers/WrapperContainer'
import useHomeFilters from '../../../hooks/useHomeFilters'
import { IEventBookmarkInterface } from '../../../models/event-bookmark/event-bookmark.interface'
import { EventBookmarkModel } from '../../../models/event-bookmark/event-bookmark.model'
import {
  selectBookmarkedEvents,
  setBookmarkedEvents,
} from '../../../store/bookmarks/bookmarksSlice'
import { selectUseMode } from '../../../store/system/systemSlice'
import MapContainer from './map/MapContainer'
import CardsLoader from '../../../components/loader/CardsLoader'
import HomeSectionHeaderComponent from '../../../components/home/headers/HomeSectionHeaderComponent'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
const EVENT_BOOKMARKS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_BOOKMARKS

type IHomeRootPage = {
  handleModal?: any
  isShowMap?: boolean
}

// Constants
const FETCH_BOOKMARKS_PER_PAGE = 100

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @TODO Document this
 */
const HomeRootPage: React.FC<IHomeRootPage> = ({ handleModal, isShowMap }) => {
  // Hooks and vars
  let title_
  let mounted = true

  const { mUserDocData } = useDocumentHook()
  const { mCompetitorProfile } = useCompetitorProfileHook({})

  const { fetchMore, filterByStatus } = useHomeFilters({ isPublished: true })

  const dispatch = useAppDispatch()
  const userId = useAppSelector(selectUserId)
  const useMode = useAppSelector(selectUseMode)
  const filterData = useSelector(selectedfilters)
  const bookmarkedEvents = useAppSelector(selectBookmarkedEvents)
  const allEventsR = useAppSelector(selectEvents)
  const allEvents = allEventsR.data

  const [endpoint, setEndpoint] = useState<string>('')
  const [data, setData] = useState<any>(new EventPrizeListsModel().toObject())

  const mapIsOpened = filterData.map.status

  title_ =
    filterData.search.searchValue !== ''
      ? 'Search for ' + filterData.search.searchValue
      : 'Upcoming Events'

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  useEffect(() => {
    if (window.location.pathname && mounted) {
      setEndpoint((window.location.pathname as any).split('/').pop())
    }
  }, [window.location.pathname])

  useEffect(() => {
    mounted = true
    return () => {
      mounted = false
    }
  }, [])

  /**
   * @info Fetches the bookmarks from db
   * @returns Fetched bookmarked events
   */
  const fetchBookmarkedEvents = async () => {
    let fetchedBookMarkedEvents: IEventBookmarkInterface[] = []
    const ORDER_BY = EVENT_BOOKMARKS.FIELDS.CREATED_AT.KEY
    const ORDER_DIRECTION = 'desc'

    try {
      const bookmarkedEventsSnap = await FirestoreService.filterItems(
        EVENT_BOOKMARKS.NAME,
        [where(EVENT_BOOKMARKS.FIELDS.USER_ID.KEY, '==', userId)],
        FETCH_BOOKMARKS_PER_PAGE,
        ORDER_BY,
        ORDER_DIRECTION
      )

      if (bookmarkedEventsSnap.size)
        bookmarkedEventsSnap.forEach((currDoc) => {
          fetchedBookMarkedEvents.push(
            getConvertedData(EventBookmarkModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
    } catch (error) {
      console.error(error)
      fetchedBookMarkedEvents = []
    } finally {
      if (fetchedBookMarkedEvents.length) {
        const listToAdd = bookmarkedEvents.length
          ? [...bookmarkedEvents, ...fetchedBookMarkedEvents]
          : fetchedBookMarkedEvents
        dispatch(setBookmarkedEvents(listToAdd))
      }
    }
  }

  useEffect(() => {
    if (userId) {
      fetchBookmarkedEvents()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    if (window.location.pathname && mounted)
      setEndpoint((window.location.pathname as any).split('/').pop())
  }, [window.location.pathname])

  useEffect(() => {
    if (mUserDocData) {
      let dataToThrow = { ...mUserDocData }
      dispatch(setUserDocumentsData(getConvertedData(dataToThrow)))
    }
  }, [mUserDocData])

  useEffect(() => {
    if (mCompetitorProfile) {
      let dataToThrow = { ...(getConvertedData(mCompetitorProfile) ?? {}) }
      dispatch(setCompetitorProfileData(dataToThrow))
    }
  }, [mCompetitorProfile])

  useEffect(() => {
    dispatch(
      setActiveFilters({
        ...filterData,
        groupFilters: {
          ...filterData.groupFilters,
          categoriesFilter: ['all'],
        },
      })
    )
  }, [])

  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        const doc = await FirestoreService.getItem('test_collection', 'nIeapdSf7xvRUDQaQhox')
        if (doc && mounted) {
          setData(EventPrizeListsModel.fromFirestoreDoc(doc).toObject())
        }
      }
      fetchData().catch()
    }
  }, [data, setData])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  useEffect(() => {
    dispatch(
      setEventSteps({
        detailPage: false,
        step: 0,
      })
    )
  }, [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  if (useMode === USER_TYPES.COMPETITOR.KEY) {
    if ((filterData?.search?.searchValue ?? '')?.trim() !== '')
      title_ = `Search for ${filterData.search.searchValue}...`
    else if (filterData.groupFilters?.categoriesFilter[0])
      title_ = `${filterData.groupFilters.categoriesFilter[0].trim()} events`

    title_ = title_.substring(0, 1).toUpperCase() + title_.substring(1)
  }

  const onMapButtonClick = () => {
    dispatch(setMapVisibilityAc(!mapIsOpened))
  }

  return (
    <WrapperContainer
      removePadding={true}
      title={
        `/${endpoint}` === CONST.ROUTES.HOME.URL
          ? 'Search Events'
          : `/${endpoint}` === CONST.ROUTES.SEARCH.URL
            ? 'Search equestrian events'
            : ''
      }
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="w-full mx-auto relative"
        transition={{
          delay: 0.1,
        }}
      >
        {allEventsR.hasMore && !mapIsOpened ? <Fading /> : null}

        <HomeRootPageContentCategoriesSection useMode={useMode} handleModal={handleModal} />

        <HomeSectionHeaderComponent
          filterByStatus={filterByStatus}
          title={title_}
          seeAllRouteUrl={CONST.ROUTES.UPCOMING_COMPETITIONS.URL}
          events={allEvents as any}
        />

        {mapIsOpened ? (
          <MapContainer />
        ) : allEventsR.status === 'loading' ? (
          <CardsLoader />
        ) : (
          <>
            <Suspense fallback={<div>Loading...</div>}>
              <HomeRootPageContentUpCompetitionsSection
                title={title_}
                filterByStatus={filterByStatus}
                fetchMore={fetchMore}
                hasMore={allEventsR.hasMore}
                tags={filterData?.groupFilters?.categoriesFilter}
                events={allEvents}
                handleModal={handleModal}
              />
            </Suspense>
          </>
        )}
        {isShowMap && (
          <div
            className={
              'absolute z-10 bottom-3 right-[48.5%] flex items-center flex-row rounded-[30px] p-2 px-[13px] cursor-pointer md:w-max 2xl:h-[50px] xl:h-[45px] 2xl:w-[110px] xl:w-[100px] bg-[#F7074F] text-white hover:bg-[#ed094c] transform hover:scale-105 transition-transform duration-200'
            }
            onClick={onMapButtonClick}
          >
            <img
              src={`assets/cp_icons/Map-2.svg`}
              alt="Map"
              className="mr-1 w-6 h-6"
              style={{
                filter:
                  'invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)',
              }}
            />
            <p className="ml-1 2xl:text-[16px] xl:text-[14px] w-[70%] text-center">
              {mapIsOpened ? 'Close' : 'Map'}
            </p>
          </div>
        )}
      </motion.div>
    </WrapperContainer>
  )
}

export default HomeRootPage
