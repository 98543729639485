import TimeLib from '../../../../lib/Time'

export const DATA_FIRESTORE_V01_FOLLOWING = {
  NAME: 'v01_following',
  FIELDS: {
    ID: {
      KEY: 'id',
      VALUES: {
        NULL: null,
      },
    },

    FOLLOWING_HORSE_ID: {
      KEY: 'followingHorseId',
      VALUES: {
        NULL: null,
      },
    },

    USED_FOR: {
      KEY: 'usedFor',
      VALUES: {
        DEFAULT: 'user',
        USER: 'user',
        HORSE: 'horse',
      },
    },

    FOLLOWER_USER_TYPE: {
      KEY: 'followerUserType',
      VALUES: {
        COMPETITOR: 'competitor',
        ORGANIZER: 'organizer',
        VENDOR: 'vendor',
        UNDEFINED: 'undefined',
      },
    },
    FOLLOWING_USER_TYPE: {
      KEY: 'followingUserType',
      VALUES: {
        COMPETITOR: 'competitor',
        ORGANIZER: 'organizer',
        VENDOR: 'vendor',
        UNDEFINED: 'undefined',
      },
    },

    FOLLOWING_USER_ID: {
      KEY: 'followingUserId',
      VALUES: {
        NULL: null,
      },
    },

    FOLLOWER_USER_ID: {
      KEY: 'followerUserId',
      VALUES: {
        NULL: null,
      },
    },

    IS_FOLLOWED_BLOCKED: {
      KEY: 'isFollowedBlocked',
      VALUES: {
        NULL: null,
        DEFAULT: false,
      },
    },

    CREATED_AT: {
      KEY: 'createdAt',
      VALUES: {
        NULL: null,
        CURRENT_DATE: TimeLib.utcTimestamp(),
      },
    },

    UPDATED_AT: {
      KEY: 'updatedAt',
      VALUES: {
        NULL: null,
        CURRENT_DATE: TimeLib.utcTimestamp(),
      },
    },
  },
}
