import clsx from 'clsx'
import { CONST } from '../../../../const/const'
import { myEventsTableStyles } from '../../../../pages/myEvent/data/myEventsTableStyles'
import DataNotAvailable from '../../../common/alerts/data-not-available/DataNotAvailable'
import InfiniteScrollDataTable from '../../../common/tables/InfiniteScrollDataTable'
import WrapperWithHeader from '../../../common/wrappers/WrapperWithHeader'
import ViewsLoader from '../../../loader/ViewsLoader'
import { capitalize } from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { createString } from '../../../../helpers/helpers'
import { useEffect, useState } from 'react'
import { where } from 'firebase/firestore'
import { EventModel } from '../../../../models/events/event.model'
import { IEventInterface } from '../../../../models/events/event.interface'
import FirestoreService from '../../../../services/firestoreService'
import { getConvertedData } from '../../../../models/interface.helper'

const BrowseEvents = () => {
  const [events, setEvents] = useState<IEventInterface[] | null>(null)
  const [hasMore, sethasMore] = useState<boolean>(false)
  const [searchstr, setSearchStr] = useState<string>('')
  const getAllEvents = async (isfilter?: boolean, strtosearch?: string) => {
    let eventList: IEventInterface[] = []
    let queries: any[] = [where('isPublished', '==', true)]
    let cursorid = strtosearch ? null : (events ?? []).at(-1)?.id ?? null
    if (isfilter && strtosearch?.length) {
      queries.push(where('eventNameNGram', 'array-contains', strtosearch))
    }
    const perPage = 15
    const eventSnaps = await FirestoreService.filterItems(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS.NAME,
      queries,
      perPage,
      undefined,
      undefined,
      cursorid
    )

    if (!eventSnaps.size || eventSnaps.size < perPage) {
      sethasMore(false)
    } else {
      if (eventSnaps.size) {
        sethasMore(true)
      }
    }

    eventSnaps.forEach((c) => {
      let event = EventModel.fromFirestoreDoc(c).toObject()
      eventList.push(getConvertedData(event) as IEventInterface)
    })
    if (events?.length && !strtosearch?.length) {
      setEvents([...events, ...eventList])
    } else {
      setEvents(eventList)
    }
  }

  useEffect(() => {
    getAllEvents()
  }, [])

  const getEventCols = () => {
    const cols = [
      {
        draggable: false,
        name: '',
        cell: (row: IEventInterface) => {
          return (
            <div className="flex items-center">
              <span className="w-[45px] h-[45px] block shrink-0 mr-4">
                <Link to={'/'}>
                  <img
                    src={
                      !row?.eventCardCoverImageUrl || row?.eventCardCoverImageUrl === ''
                        ? 'assets/img/eventStatic.svg'
                        : row?.eventCardCoverImageUrl
                    }
                    className="object-cover w-full h-full rounded-full"
                    onError={(e) => {
                      e.currentTarget.src = 'assets/img/eventStatic.svg'
                    }}
                    alt="icon"
                  />
                </Link>
              </span>
              <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold">
                <Link to={'/'}>{capitalize(createString(row?.eventName))}</Link>
              </span>
            </div>
          )
        },
        width: '20%',
      },
      {
        name: 'Discipline',
        draggable: false,
        cell: (row: IEventInterface) => (
          <>
            <img src="assets/img/allevent/Horsessign.svg" alt="icon" />
            {row?.tags && row?.tags.length ? (
              <p className="text-SeabiscuitDark200ThemeColor block ml-2 capitalize font-normal">
                {row?.tags[0]}
              </p>
            ) : (
              <p className="text-SeabiscuitDark200ThemeColor block ml-2 capitalize font-normal">
                N/A
              </p>
            )}
          </>
        ),
        width: '10%',
      },
      {
        name: 'Event Type',
        draggable: false,
        cell: (row: IEventInterface) => (
          <>
            <img src="assets/img/allevent/Laurelwreath.svg" alt="icon" />
            <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
              {row?.category}
            </p>
          </>
        ),
        width: '10%',
      },
      {
        name: 'Location',
        draggable: false,
        cell: (row: IEventInterface) => (
          <>
            <img src="assets/img/allevent/Location.svg" alt="icon" />
            <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
              {row?.state}
            </p>
          </>
        ),
        width: '10%',
      },
      {
        name: 'Start Date',
        draggable: false,
        cell: (row: IEventInterface) => (
          <>
            <img src="assets/img/allevent/Calendar.svg" alt="icon" />
            <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
              {row.eventStartDate && row.eventStartDate !== 'N/A'
                ? moment(row.eventStartDate).format('MMM DD')
                : 'N/A'}
            </p>
          </>
        ),
        width: '10%',
      },
      {
        name: 'Registered',
        draggable: false,
        cell: (row: IEventInterface) => {
          return (
            <>
              <img src="assets/og_icons/MySpace.svg" alt="icon" />
              <p className="ml-2">{row.registeredCount}</p>
            </>
          )
        },
        width: '8%',
      },
      {
        name: 'Vendors',
        draggable: false,
        cell: (row: IEventInterface, index: any) => {
          return (
            <>
              <img src="assets/og_icons/Shop.svg" alt="icon" />
              <p className="ml-2">{index}</p>
            </>
          )
        },
        width: '10%',
      },
      {
        name: 'Sponsor/vendor Price',
        draggable: false,
        cell: (row: IEventInterface) => {
          return (
            <>
              <img src="assets/og_icons/Us Dollar Circled.svg" alt="icon" />
              <p className="ml-2">N/A</p>
            </>
          )
        },
        width: '10%',
      },
      {
        name: 'View Event',
        draggable: false,
        cell: (row: IEventInterface) => {
          return (
            <Link
              to={`/events-details/${row.id}`}
              aria-disabled={!row.id}
              className="text-center mr-[-8px] w-[80px] h-[35px] flex items-center py-2 !ring-0 border border-transparent rounded-lg shadow-sm text-sm font-medium text-[#F7074F] bg-[#F7074F1A] hover:text-[#f7074f] focus:outline-none focus:ring-2 focus:ring-offset-2 hover:bg-[#F7074F0D] focus:ring-SeabiscuitMainThemeColor justify-center"
            >
              {' '}
              View &gt;
            </Link>
          )
        },
        width: '12%',
      },
    ]

    return cols
  }

  const filterUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStr(e.target.value)
    sethasMore(true)
    getAllEvents(true, e.target.value)
  }

  return (
    <div className="p-2 mt-2 ">
      <WrapperWithHeader
        title={'Search Shows'}
        minHeight="250px"
        width="97%"
        margin="0 auto"
        dividerStyles={{
          marginBottom: '8px',
        }}
        description={''}
      >
        <span>
          <div
            className="inputSearch  flex items-center relative mb-3 pt-4 w-[95%] pb-[20px] "
            style={{ margin: '0 auto' }}
          >
            <input
              defaultValue={searchstr}
              type="text"
              onChange={filterUsers}
              placeholder="Search.."
              className="rounded-full w-full border focus:border-[#D3DAEE] border-[#D3DAEE] text-SeabiscuitDark200ThemeColor focus:ring-0"
            />
            <img
              src="/assets/og_icons/HomeFIlter-1.svg"
              alt="filter"
              className="absolute text-SeabiscuitMainThemeColor right-4 w-[28px] border border-solid rounded-md bg-[rgba(247, 7, 79, 0.1)]"
            />
          </div>
          <span className="w-[90%] m-[0 auto]">
            <InfiniteScrollDataTable
              fetchMore={() => {
                getAllEvents()
              }}
              fixedHeader={true}
              columns={getEventCols()}
              hasMore={hasMore}
              data={events ?? []}
              progressComponent={<ViewsLoader size="md" color="red" className="pt-4 pb-4" />}
              noDataComponent={
                <DataNotAvailable
                  mode="graphic"
                  imageAlt="banner"
                  bottomTextLink={CONST.ROUTES.HOME.URL}
                  bottomText="Search for events >"
                  imageSource={'assets/placeholders/noRegister.svg'}
                  upperText={'No events yet exists'}
                />
              }
              className={clsx(
                'transition-all',
                events?.length && 'md:h-[300px] 2xl:h-[500px] !overflow-auto 232'
              )}
              customStyles={{
                ...myEventsTableStyles,
                headRow: {
                  style: {
                    minHeight: 'unset',
                    borderColor: '#D3DAEE',
                    // marginTop:"3px",
                    paddingTop: '6px',
                  },
                },
              }}
              noHeader={true}
            />
          </span>
        </span>
      </WrapperWithHeader>
    </div>
  )
}

export default BrowseEvents
