import { useContext, useState } from 'react'
import { SignupStages } from './UserSignupViewComponentContextApi'

import backIcon from '../assets/arrow-left.svg'
import UserSignupViewComponentButton from './global/UserSignupViewComponentButton'
import UserLoginViewComponentInput from './global/UserSignupViewComponentInput'

const UserSignupViewComponentSigninForm: React.FC = () => {
  const [stage, setStage] = useContext(SignupStages)

  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  const [signUpForm, setSignUpForm] = useState({
    email: '',
    password: '',
  })

  return (
    <div>
      <div className="text-[24px] flex items-center gap-2">
        <button onClick={() => stageHandle('Init')}>
          <img src={backIcon} alt="back" className="invert" />
        </button>
        <div className="text-white">BACK</div>
      </div>
      <div className="mt-6 overflow-hidden">
        <h1 className="text-[24px] font-norma text-white">Sign in</h1>
      </div>
      <div className="mt-6">
        <UserLoginViewComponentInput
          type="email"
          value={signUpForm.email}
          onChange={(e: any) => setSignUpForm({ ...signUpForm, email: e.target.value })}
          placeholder="Email"
          icon="/assets/og_icons/Mail-4.svg"
          className="mt-4"
        />
        <UserLoginViewComponentInput
          type="password"
          value={signUpForm.password}
          onChange={(e: any) =>
            setSignUpForm({
              ...signUpForm,
              password: e.target.value,
            })
          }
          placeholder="Password"
          icon="/assets/og_icons/Lock-4.svg"
          className="mt-4"
        />
      </div>
      <div className="w-[70%] mt-[60px] mx-auto">
        <UserSignupViewComponentButton
          caption="SIGN IN"
          kind={1}
          disabled={!stage.selector}
          onClick={() => stageHandle('Landing')}
        />
      </div>
      <div className="text-center text-white opacity-50 mt-8">OR</div>
      <div className="text-white my-10 text-center">
        Already have an account?
        <a className="text-SeabiscuitMainThemeColor" href="/signup">
          Sign up
        </a>
      </div>
    </div>
  )
}

export default UserSignupViewComponentSigninForm
