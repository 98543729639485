import { AddCircleOutlineOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { DocumentData, QueryDocumentSnapshot, where } from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'
import helpers from '../../../../../commonHelpers/helpers'
import { CONST } from '../../../../../const/const'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import TooltipIcon from '../../../../../helpers/TooltipIcon'
import useHorses from '../../../../../hooks/users/competitor/profile/useHorses'
import { IHorseTeamInterface } from '../../../../../models/horse-team/horseTeam.interface'
import { getConvertedData, getUserAsTeamMember } from '../../../../../models/interface.helper'
import { IUserHorseMappingInterface } from '../../../../../models/user-horse-mapping/userHorseMapping.interface'
import { ITeamMember, IUserInterface } from '../../../../../models/users/user.interface'
import { UserModel } from '../../../../../models/users/user.model'
import FirestoreService from '../../../../../services/firestoreService'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { RootState } from '../../../../../store/store'
import {
  selectHorseTeamMembers,
  selectTeamMembers,
  selectUserId,
  setHorseTeamMembers,
  setTeamMembers as setTeamMembersAc,
} from '../../../../../store/user/userSlice'
import { ITeamMemberRoles } from '../../../../../types/competitor_types'
import { ITEAMMEMBER_MODAL_TYPES } from '../../../../home/modals/UserProfileTeamTabAddTeamMember'
import ViewsLoader from '../../../../loader/ViewsLoader'
import ProfileTeamTabUserProfileSection from './ProfileTeamTabUserProfileSection'
import { getUserFullName } from '../../../../../helpers/helpers'

type IRemoveTeamMemberArgs = {
  type: ITeamMemberRoles
  id: string | null
  update?: boolean
  data?: { memberRole: ITeamMemberRoles; memberAuthorized: string }
}

type IProfile = { membersArr: ITeamMember[]; type: ITeamMemberRoles }

const TEAM_MEMBER_ROLES = CONST.UI.TEAM_MEMBERS_ROLES
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ProfileTeamTabCompetitorAuthorisedTab = ({
  handleModal,
  teamMembers,
  teamMembersLoading,
  loading,
}: {
  handleModal: any
  teamMembers: ITeamMember[]
  teamMembersLoading: boolean
  loading: boolean
}) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const userId = useAppSelector(selectUserId)

  const teamMembersList = useAppSelector(selectTeamMembers) as ITeamMember[]
  const HorseTeamList = useAppSelector(selectHorseTeamMembers) as ITeamMember[]

  const { mappings } = useHorses(userId)
  const { profileDetails } = useAppSelector((state: RootState) => state.user)

  const [teamMembersCategorised, setTeamMembersCategorised] = useState<IProfile[]>([])
  const [userData, setuserData] = useState<ITeamMember[]>([])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleGetUserData = async (userIdList: string[]) => {
    let usersSnaps: QueryDocumentSnapshot<DocumentData>[] = []
    let usersList: IUserInterface[] = []
    let currUser: IUserInterface | null = null
    usersSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, userIdList)
    usersSnaps.forEach((curr) => {
      currUser = getConvertedData(UserModel.fromFirestoreDoc(curr).toObject())
      usersList.push(currUser)
    })
    return usersList
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const riderFilter = useCallback(() => {
    const data = teamMembers.filter((item) => {
      return item.memberRole === TEAM_MEMBER_ROLES.RIDER
    })
    const data2 = HorseTeamList.filter((item) => {
      return item.memberRole === TEAM_MEMBER_ROLES.RIDER
    })

    if (data.length && data2.length) {
      if (data[0].memberId === data2[0].memberId && data[0].memberRole === data2[0].memberRole) {
        return data
      } else {
        return [...data, ...data2]
      }
    } else return [...data, ...data2]
  }, [teamMembers, HorseTeamList])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const groomMemberFilter = () => {
    const data = teamMembers.filter((item) => {
      return item.memberRole === TEAM_MEMBER_ROLES.GROOM
    })
    const data2 = HorseTeamList.filter((item) => {
      return item.memberRole === TEAM_MEMBER_ROLES.GROOM
    })

    if (data.length && data2.length) {
      if (data[0].memberId === data2[0].memberId && data[0].memberRole === data2[0].memberRole) {
        return data
      } else {
        return [...data, ...data2]
      }
    } else return [...data, ...data2]
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const TrainerFilter = useCallback(() => {
    const data = teamMembers.filter((item) => {
      return item.memberRole === TEAM_MEMBER_ROLES.TRAINER
    })
    const data2 = HorseTeamList.filter((item) => {
      return item.memberRole === TEAM_MEMBER_ROLES.TRAINER
    })

    if (data.length && data2.length) {
      if (data[0].memberId === data2[0].memberId && data[0].memberRole === data2[0].memberRole) {
        return data
      } else {
        return [...data, ...data2]
      }
    } else return [...data, ...data2]
  }, [teamMembers, HorseTeamList])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const CoachFilter = useCallback(() => {
    const data = teamMembers.filter((item) => {
      return item.memberRole === TEAM_MEMBER_ROLES.COACH
    })
    const data2 = HorseTeamList.filter((item) => {
      return item.memberRole === TEAM_MEMBER_ROLES.COACH
    })
    if (data.length && data2.length) {
      if (data[0].memberId === data2[0].memberId && data[0].memberRole === data2[0].memberRole) {
        return data
      } else {
        return [...data, ...data2]
      }
    } else return [...data, ...data2]
  }, [teamMembers, HorseTeamList])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const teamMemberFilter = useCallback(() => {
    const data = teamMembers.filter((item) => {
      return item.memberRole === TEAM_MEMBER_ROLES.TEAM_MEMBER
    })
    const data2 = HorseTeamList.filter((item) => {
      return item.memberRole === TEAM_MEMBER_ROLES.TEAM_MEMBER
    })

    if (data.length && data2.length) {
      if (data[0].memberId === data2[0].memberId && data[0].memberRole === data2[0].memberRole) {
        return data
      } else {
        return [...data, ...data2]
      }
    } else return [...data, ...data2]
  }, [teamMembers, HorseTeamList])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const removeTeamMember = (args: IRemoveTeamMemberArgs) => {
    let { type, id, update = false, data } = args
    if (update) {
      if (type === 'Team member') {
        const teamMembersUpdatedArr = teamMembersList.map((curr) => {
          if (curr.memberId === id) return { ...curr, ...data }
          else return curr
        })
        return dispatch(setTeamMembersAc([...(teamMembersUpdatedArr as any)]))
      } else {
        const HorseUpdate = HorseTeamList.map((curr) => {
          if (curr.memberId === id)
            return { ...curr, authorized: 'authorized', memberAuthorized: '1' }
          else return curr
        })
        return dispatch(setHorseTeamMembers([...(HorseUpdate as any)]))
      }
    }

    handleModal(true, MODAL_CONSTS.REMOVE_TEAM_MEMBER, {
      type,
      id,
      modal_type: ITEAMMEMBER_MODAL_TYPES.TEAM,
    })
  }

  useEffect(() => {
    let owner: ITeamMember | null = null
    let userName: string | null = null

    const ACTIVE = '1'
    const UNKNOWN = COLLECTIONS.USERS.FIELDS.FIRST_NAME.VALUES.UNKNOWN
    const DEFAULT_ROLE = COLLECTIONS.USERS.FIELDS.USER_DEFAULT_ROLE.VALUES.OWNER

    // if() JSON.stringify

    owner = (() => {
      userName = profileDetails ? getUserFullName(profileDetails) : UNKNOWN

      let ownerData: ITeamMember = {
        memberStatus: ACTIVE,
        memberId: userId ?? '',
        memberShipActive: false,
        memberRole: DEFAULT_ROLE,
        defaultRole: DEFAULT_ROLE,
        memberprofileSynced: false,
        memberName: userName,
        memberSafeSupportTraining: false,
        memberEmail: profileDetails?.userEmail ?? '',
        memberAddress: profileDetails?.userAddress ?? '',
        memberCountry: profileDetails?.userNationality ?? '',
        memberPhoneNumber: profileDetails?.userPhoneNumber ?? '',
        memberProfilePicture: profileDetails?.userProfilePicture ?? '',
        memberDob: profileDetails?.userDOB ? getConvertedData(profileDetails.userDOB) : '',
      }
      return ownerData
    })()

    setTeamMembersCategorised([
      { membersArr: [owner!], type: TEAM_MEMBER_ROLES.OWNER },
      { membersArr: teamMemberFilter(), type: TEAM_MEMBER_ROLES.TEAM_MEMBER },
      { membersArr: CoachFilter(), type: TEAM_MEMBER_ROLES.COACH },
      { membersArr: riderFilter(), type: TEAM_MEMBER_ROLES.RIDER },
      { membersArr: TrainerFilter(), type: TEAM_MEMBER_ROLES.TRAINER },
      { membersArr: groomMemberFilter(), type: TEAM_MEMBER_ROLES.GROOM },
    ])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMembersList, profileDetails, teamMembers, mappings])

  // Opens add team member modal
  const openAddTeamMemberModal = () => {
    handleModal(true, MODAL_CONSTS.TEAM, { modal_type: ITEAMMEMBER_MODAL_TYPES.TEAM })
  }

  const handleaddMembertoAuthorizeme = async (mappings: IUserHorseMappingInterface[]) => {
    let authorizeList: IHorseTeamInterface[] = []
    let UnauthorizeList: IHorseTeamInterface[] = []

    mappings.forEach((data) => {
      if (data.teamMembers?.length) {
        data.teamMembers.forEach((member) => {
          if (member.authorized === 'authorized' && member.memberAuthorized === '1') {
            authorizeList.push(member)
          } else {
            UnauthorizeList.push(member)
          }
        })
      }
    })

    await helpers.asyncWhileLoop({
      loopCount: authorizeList.length,
      functionToFire: async (index) => {
        let currentAuthuser = authorizeList[index]

        const fetch_user = await FirestoreService.filterItems(COLLECTIONS.USERS.NAME, [
          where(COLLECTIONS.USERS.FIELDS.ID.KEY, '==', currentAuthuser.memberId),
        ])

        if (fetch_user.size) {
          await helpers.asyncWhileLoop({
            loopCount: fetch_user.size,
            functionToFire: async (index) => {
              const data = fetch_user.docs[index]
              const currdata = getConvertedData(UserModel.fromFirestoreDoc(data).toObject())
              let teamdata1 = getUserAsTeamMember(currdata)
              let userDataModify = {
                ...profileDetails,
                userTeamMembers: [...(profileDetails.userTeamMembers ?? []), teamdata1],
              }
              await FirestoreService.updateItem(
                COLLECTIONS.USERS.NAME,
                userDataModify.id,
                userDataModify
              )
            },
          })
        }
      },
    })

    await helpers.asyncWhileLoop({
      loopCount: UnauthorizeList.length,
      functionToFire: async (index) => {
        let currentAuthuser = UnauthorizeList[index]

        const fetch_user = await FirestoreService.filterItems(COLLECTIONS.USERS.NAME, [
          where(COLLECTIONS.USERS.FIELDS.ID.KEY, '==', currentAuthuser.memberId),
        ])

        if (fetch_user.size) {
          await helpers.asyncWhileLoop({
            loopCount: fetch_user.size,
            functionToFire: async (index) => {
              const data = fetch_user.docs[index]
              const currdata = getConvertedData(UserModel.fromFirestoreDoc(data).toObject())
              let teamdata1 = getUserAsTeamMember(currdata)
              let filterData = profileDetails?.userTeamMembers?.filter(
                (data) => data.memberId !== teamdata1.memberId
              )
              let userDataModify = {
                ...profileDetails,
                userTeamMembers: filterData,
              }
              await FirestoreService.updateItem(
                COLLECTIONS.USERS.NAME,
                userDataModify.id,
                userDataModify
              )
            },
          })
        }
      },
    })
  }

  useEffect(() => {
    let userList: any = []
    let userIdList: string[] = []
    mappings.data.forEach((data) => {
      if (data.teamMembers?.length) {
        data.teamMembers.forEach((member) => {
          userList.push(member)
        })
      }
    })
    userIdList = userList.map((list: any) => {
      return list.memberId
    })

    handleGetUserData(userIdList).then((data) => {
      let filterData: any = []
      userList.forEach((user: any) => {
        let getmember = data.filter((data: any) => data.id === user.memberId)
        let condition = filterData.filter(
          (data: any) => data.memberId === user.memberId && data.memberRole === user.memberRole
        )
        if (!condition.length) {
          filterData.push({
            memberStatus: '1',
            id: user.id,
            horseId: user.horseId,
            authorized: user.authorized ?? 'unauthorized',
            memberAuthorized: user.memberAuthorized ?? '0',
            memberProfileSynced: false,
            userHorseMappingId: user.userHorseMappingId,
            memberShipActive: false,
            defaultRole: 'owner',
            memberprofileSynced: false,
            memberName: user.memberName ?? 'Unknown',
            memberSafeSupportTraining: false,
            memberId: getmember[0].id ?? '',
            memberEmail: getmember[0]?.userEmail ?? '',
            memberAddress: getmember[0]?.userAddress ?? '',
            memberCountry: getmember[0]?.userNationality ?? '',
            memberPhoneNumber: getmember[0]?.userPhoneNumber ?? '',
            memberProfilePicture: getmember[0]?.userProfilePicture ?? '',
            memberDob: getmember[0]?.userDOB ? getConvertedData(user.userDOB) : '',
            memberRole: user.memberRole ?? 'Team member',
          })
        }
      })
      setuserData(filterData)
      handleaddMembertoAuthorizeme(mappings.data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMembersLoading, loading, mappings])

  useEffect(() => {
    if (userData.length) {
      dispatch(setHorseTeamMembers(userData))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  useEffect(() => {
    let filterUnique = teamMembersList.map((member) => {
      const horseMember = HorseTeamList.filter((data) => data.memberId === member.memberId)
      return {
        ...member,
        memberRole: horseMember.length ? horseMember[0].memberRole : member.memberRole,
      } as ITeamMember
    })
    dispatch(setTeamMembersAc(filterUnique))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="xl:text-nr 2xl:text-base competitor_team_tab">
        <hr className="mt-4 mb-4"></hr>
        <div className="text-[#122B46] font-poppins text-base font-bold mb-0">
          Team Members Who Can Register You For Events
        </div>
        <div className="text-[#122B4680]">
          <p className="text-[rgba(18,43,70,0.50)] font-poppins text-sm font-normal mb-8 flex items-center">
            Add team members and authorize them to register you for events
            <Tooltip
              title={
                <h1 className="tooltip_title">
                  {`Only 'Authorized' people can register you for events.`} <br />
                  <br />
                  {`All team members can send you paperwork to sign and bills to pay during registration.`}
                </h1>
              }
              placement="top"
              arrow
            >
              <button style={{ marginLeft: '10px' }} onClick={(e) => e.preventDefault()}>
                <TooltipIcon color="#122B4680" className="!ml-0" />
              </button>
            </Tooltip>
          </p>
        </div>

        {/* <hr className="mt-4 mb-8"></hr> */}

        {teamMembersLoading ? (
          <ViewsLoader
            className="flex items-center w-full justify-center min-h-[30vh] mt-3"
            size="lg"
            color="#F7074F"
          />
        ) : (
          <>
            {/* Renders team members */}
            {teamMembersCategorised && teamMembersCategorised.length
              ? teamMembersCategorised.map((curr: IProfile, index: number) => {
                  const teamExists = teamMembersCategorised.find(
                    (data) => data.type === 'Team member'
                  )
                  const check = teamExists?.membersArr?.length ? 'Team member' : 'Owner'
                  if (curr.membersArr.length)
                    return (
                      <div key={`${curr.type}${index}`}>
                        {index > 4 ? <hr className="my-4 opacity-50" /> : null}

                        {curr.membersArr.map((item, index) => (
                          <ProfileTeamTabUserProfileSection
                            user={item}
                            key={`${curr.type}${index}`}
                            isOwner={item.memberId === userId}
                            removeTeamMember={removeTeamMember}
                            hideCross={item.memberId === userId}
                          />
                        ))}

                        {curr.type === check ? (
                          <>
                            <div
                              className="flex w-3/4 mb-3 ml-[27%] mt-2 text-sm items-center text-SeabiscuitMainThemeColor cursor-pointer"
                              onClick={openAddTeamMemberModal}
                            >
                              <AddCircleOutlineOutlined
                                className="mr-2 text-SeabiscuitMainThemeColor text-xs"
                                fontSize="small"
                              />
                              Add team member
                            </div>
                            <hr className="my-4" />
                            <div className="text-[#122B46] font-poppins text-base font-bold mb-0">
                              Horse Staff
                            </div>
                            <div className="text-[rgba(18,43,70,0.50)] font-poppins text-sm font-normal mb-8">
                              These team members are automatically added to your team when you add a
                              horse to your profile
                            </div>
                          </>
                        ) : null}
                      </div>
                    )

                  return null
                })
              : null}
          </>
        )}
      </div>
    </>
  )
}

export default ProfileTeamTabCompetitorAuthorisedTab
