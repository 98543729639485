import { FC } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import MainModal from '../../../../../components/modals/common/MainModal'

import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { EventSchedulingModel } from '../../../../../models/operations-tab/operations-tab.model'
import TogglesElement from '../../../../../components/elements/toggles/toggles/TogglesElement'
import { MANAGE_OPERATIONS_CONSTS } from '../../clinic/data/operations.data.const'

interface PublishOperationsModalProps {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    isPublish: {
      [key: string]: boolean
    }
    onChangeTogglePublish: (type: string, isPublish: { [key: string]: boolean }) => void
    selectedClass: { value: string; label: string }
    onSavePublish: (value: { [key: string]: boolean }) => void
  }
}

export const PublishOperationsModal: FC<PublishOperationsModalProps> = ({
  show,
  handleModal,
  dataToPassOn,
}) => {
  const eventSchedulingFormValidationSchema = EventSchedulingModel.validationSchema()

  const { control } = useForm({
    defaultValues: { title: '' },
    resolver: yupResolver(eventSchedulingFormValidationSchema),
    mode: 'onSubmit',
  })

  const closeModal = () => {
    handleModal(false, MODAL_CONSTS.PUBLISH_OPERATIONS_MODAL)
  }

  const ControlItem = ({ title, type, icon }: { title: string; type: string; icon: string }) => {
    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <img className="w-6" src={icon} alt="icons" />
          <div>
            <h6 className="text-SeabiscuitDark200ThemeColor text-[14px]">{title}</h6>
            <p className="text-SeabiscuitDark200ThemeColor/50 text-[10px]">N/A</p>
          </div>
        </div>
        <Controller
          name="title"
          control={control}
          render={() => {
            return (
              <TogglesElement
                checkedMessage="Live"
                on={dataToPassOn.isPublish[type]}
                buttonClassName="h-[36px] w-[100px]"
                textClassName="h-[25px]"
                onToggle={() => dataToPassOn.onChangeTogglePublish(type, dataToPassOn.isPublish)}
              />
            )
          }}
        />
      </div>
    )
  }

  return (
    <MainModal
      size="md"
      title="Publish"
      show={show}
      onHide={closeModal}
      type="PUBLISH_OPERATIONS_MODAL"
      buttons={[
        {
          label: 'SAVE',
          onClick: () => {
            dataToPassOn.onSavePublish(dataToPassOn.isPublish)
            closeModal()
          },
          textClass: 'text-white',
        },
      ]}
    >
      <p className="text-SeabiscuitDark200ThemeColor text-base mb-2">
        Toggle settings to ‘Live’ to publish riders and public.
      </p>
      <p className="text-SeabiscuitDark200ThemeColor text-base mb-2">
        Toggle settings back to private to hide from the riders and public.
      </p>
      <div className="mt-3 flex flex-col gap-6 border-solid p-3 border-[#D3DAEE] border rounded-2xl">
        <div className="flex items-center gap-4 mb-2">
          <img className="w-6" src={'assets/og_icons/Prize-1.svg'} alt="icons" />
          <h6 className="text-SeabiscuitDark200ThemeColor text-[20px]">
            {dataToPassOn.selectedClass.label}
          </h6>
        </div>
        <ControlItem
          icon="assets/og_icons/Shortlist-1.svg"
          title="Publish Order of Go"
          type={MANAGE_OPERATIONS_CONSTS.TAB_NAMES.ORDER.value}
        />
        <ControlItem
          icon="assets/og_icons/Prize-1.svg"
          title="Publish Scores"
          type={MANAGE_OPERATIONS_CONSTS.TAB_NAMES.SCORING.value}
        />
        <ControlItem
          icon="assets/og_icons/Money Bag-1.svg"
          title="Publish Prize Money"
          type={MANAGE_OPERATIONS_CONSTS.TAB_NAMES.PRIZE_MONEY.value}
        />
      </div>
    </MainModal>
  )
}
