import { CONST } from '../../../../../../const/const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//  Hunter class A
export const EPL_FB_JUMPER_L1 = {
  vMain: 1,
  vSub: 0,
  vFix: 0,
  disciplineType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DISCIPLINE.TYPE.STANDARD,
  discipline: 'Jumper L1',
  validationStatus: 'unknown',
  divisionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.DIVISION.TYPE.STANDARD,
  divisionItemsRules: null,
  divisions: [
    // ************************************************************
    //  <JUMPER L1> 3321 - Amateur Jumper (All Heights)
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3321',
      divisionTitle: 'Amateur Jumper (All Heights)',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <JUMPER L1> 3324 High Amateur Jumper
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3324',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'High Amateur Jumper',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '3324 High Amateur Jumper',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.JUMPER,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class 3324',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'A',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_JUMPER_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class 3324-A',
                validation: [],
                rules: null,
              },
              subSectionTables: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_JUMPER_TABLES_SINGLE_CHOICE,
                meta: {
                  enabled: false,
                  multiple: false,
                  options: ['N/A', '2', '2.1', '2a', '2b', '2ab', '2c', '2d', '3', '4'],
                  selected: ['N/A'],
                },
                value: '',
                validation: [],
                rules: null,
              },
            },
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.JUMPER,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class 3324',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'B',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_JUMPER_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class 3324-B',
                validation: [],
                rules: null,
              },
              subSectionTables: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_JUMPER_TABLES_SINGLE_CHOICE,
                meta: {
                  enabled: false,
                  multiple: false,
                  options: ['N/A', '2', '2.1', '2a', '2b', '2ab', '2c', '2d', '3', '4'],
                  selected: ['N/A'],
                },
                value: '',
                validation: [],
                rules: null,
              },
            },
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.JUMPER,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class 3324',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'C',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_JUMPER_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class 3324-C',
                validation: [],
                rules: null,
              },
              subSectionTables: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_JUMPER_TABLES_SINGLE_CHOICE,
                meta: {
                  enabled: false,
                  multiple: false,
                  options: ['N/A', '2', '2.1', '2a', '2b', '2ab', '2c', '2d', '3', '4'],
                  selected: ['N/A'],
                },
                value: '',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <JUMPER L1> 3323 Medium Amateur Jumper
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3323',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Medium Amateur Jumper',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3323 Medium Amateur Jumper',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <JUMPER L1> 3322 Low Amateur Jumper
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3322',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Low Amateur Jumper',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3322 Low Amateur Jumper',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L1> 3301 - Junior Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3301',
      divisionTitle: 'Junior Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <JUMPER L1> 3313 High Junior Jumper
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3313',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'High Junior Jumper',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3313 High Junior Jumper',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <JUMPER L1> 3316 Medium Junior Jumper
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3316',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Medium Junior Jumper',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3316 Medium Junior Jumper',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <JUMPER L1> 3319 Low Junior Jumper
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3319',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Low Junior Jumper',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '3319 Low Junior Jumper',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <JUMPER L1> 3350 Junior/Amateur Jumper Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3350',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Junior/Amateur Jumper Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '3350 Junior/Amateur Jumper Combined',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <JUMPER L1> 3312 High Junior/Amateur Combined Jumper
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3312',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'High Junior/Amateur Combined Jumper',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '3312 High Junior/Amateur Combined Jumper',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <JUMPER L1> 3315 Medium Junior/Amateur Combined Jumper
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3315',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Medium Junior/Amateur Combined Jumper',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '3315 Medium Junior/Amateur Combined Jumper',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <JUMPER L1> 3318 Low Junior/Amateur Combined Jumper
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3318',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Low Junior/Amateur Combined Jumper',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '3318 Low Junior/Amateur Combined Jumper',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L1> 9400 - Young Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '9400',
      divisionTitle: 'Young Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <JUMPER L1> 9401 Young Jumper 5 Year Old
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9400',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9401 Young Jumper 5 Year Old',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '9400 9401 Young Jumper 5 Year Old',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <JUMPER L1> 9402 Young Jumper 6 Year Old
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9402',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Young Jumper 6 Year Old',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '9402 Young Jumper 6 Year Old',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <JUMPER L1> 9403 Young Jumper 7 Year Old
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9403',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Young Jumper 7 Year Old',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '9403 Young Jumper 7 Year Old',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L1> 9450 - USHJA Young Jumper Qualifier
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '9450',
      divisionTitle: 'USHJA Young Jumper Qualifier',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <JUMPER L1> 9451 5 Year Old USHJA Young Jumper Championship
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9450',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '5 Year Old USHJA Young Jumper Championship',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '9450 5 Year Old USHJA Young Jumper Championship',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <JUMPER L1> 9452 6 Year Old USHJA Young Jumper Championship
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9452',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '6 Year Old USHJA Young Jumper Championship',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '9452 6 Year Old USHJA Young Jumper Championship',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <JUMPER L1> 9453 7 Year Old USHJA Young Jumper Championship
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9453',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '7 Year Old USHJA Young Jumper Championship',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '9453 7 Year Old USHJA Young Jumper Championship',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L1> 3500 - Child/Adult Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3500',
      divisionTitle: 'Child/Adult Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <JUMPER L1> 3501 Children’s Jumper
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3501',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Children’s Jumper',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '3501 Children’s Jumper',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <JUMPER L1> 3502 Adult Jumper
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.JUMPER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3502',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Adult Jumper',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '3502 Adult Jumper',
            validation: [],
            rules: null,
          },
          sectionTables: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.JUMPER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L1> 9500 - Pony Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '9500',
      divisionTitle: 'Pony Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L1> 3111 - Thoroughbred Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3111',
      divisionTitle: 'Thoroughbred Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L1> 3425 - U25 Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3425',
      divisionTitle: 'U25 Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L1> 3800 - Miscellaneous Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3800',
      divisionTitle: 'Miscellaneous Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L1> 14000 - USHJA Jumper Classic Series
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '14000',
      divisionTitle: 'USHJA Jumper Classic Series',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L1> 3400 - Young Rider Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3400',
      divisionTitle: 'Young Rider Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L1> 3700 - $25,000 & Up Grand Prix
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3700',
      divisionTitle: '$25,000 & Up Grand Prix',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L1> 3600 - ZONE 9 Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3600',
      divisionTitle: 'ZONE 9 Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
  ],
}
