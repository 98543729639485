import { Close } from '@mui/icons-material'
import { cloneDeep } from 'lodash'
import { useDispatch } from 'react-redux'
import { MODAL_CONSTS } from '../../const/modal-const'
import { IHorseData } from '../../models/horse/horse.interface'
import { useAppSelector } from '../../store/hooks'
import {
  getSelectedHorse,
  selectHorses,
  setHorses,
  setSelectedHorse,
} from '../../store/horses/horseSlice'
import { IUserInterfaceExtended } from '../../store/storeHelpers/userSlice/types'
import { selectProfileData, setProfileDetails } from '../../store/user/userSlice'

type Props = {
  show?: boolean
  handleModal?: any
  dataToPassOn?: any
}

const HorseTeamMemberRoleChangeConfirmation = (props: Props) => {
  //hooks and vars
  const {
    dataToPassOn: { newRole = '', selectedMember = {}, teamMemberId = '' },
  } = props
  const dispatch = useDispatch()
  const selectedHorse = useAppSelector(getSelectedHorse)
  const allHorseData = useAppSelector(selectHorses)
  const profileData = useAppSelector<IUserInterfaceExtended>(selectProfileData)

  const handleRoleChange = () => {
    // Single Selected Horse Role Update
    let allTeamMatesOfHorse = cloneDeep(selectedHorse?.horseTeamMembers ?? [])
    let acc: any[] = []

    if (selectedHorse && allTeamMatesOfHorse && allTeamMatesOfHorse.length) {
      allTeamMatesOfHorse.map((item: any) => {
        if (item.memberId === teamMemberId) {
          item.defaultRole = newRole
        }
        acc.push(item)
        return 1
      })

      dispatch(
        setSelectedHorse({
          ...selectedHorse,
          horseTeamMembers: acc,
        })
      )
    }

    // All Horses Update with roles
    if (allHorseData && allHorseData.length) {
      let allHorseTeamMemberDataClone = cloneDeep(allHorseData)
      allHorseTeamMemberDataClone = allHorseTeamMemberDataClone.reduce(
        (acc: IHorseData[], current: IHorseData) => {
          if (current.horseTeamMembers && current.horseTeamMembers.length) {
            let updatedTeamMembers: any[] = []
            current.horseTeamMembers.forEach((horseTeamMate: any) => {
              if (horseTeamMate.memberId === teamMemberId) {
                horseTeamMate.defaultRole = newRole
              }
              updatedTeamMembers.push(horseTeamMate)
            })
            current.horseTeamMembers = updatedTeamMembers
          }
          acc.push(current)
          return acc
        },
        []
      )

      dispatch(setHorses(allHorseTeamMemberDataClone))

      // Update user Team member's Role

      if (teamMemberId && teamMemberId !== '') {
        let userProfile = cloneDeep(profileData)
        let UpdateUserTeamMember: any[] = []
        userProfile?.userTeamMembers?.forEach((member: any) => {
          if (member.memberId === teamMemberId) {
            member.defaultRole = newRole
          }
          UpdateUserTeamMember.push(member)
        })

        dispatch(
          setProfileDetails({
            ...userProfile,
            userTeamMembers: UpdateUserTeamMember,
          })
        )
      }
    }

    props.handleModal(false, MODAL_CONSTS.TEAM_MEMBER_ROLE_CHANGE_WARNING)
  }

  return (
    <>
      <div
        className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
        id="exampleModalCenter"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pt-10 pb-7 px-8">
            <span
              onClick={() => props.handleModal(false, MODAL_CONSTS.TEAM_MEMBER_ROLE_CHANGE_WARNING)}
              className="absolute text right-2 top-2 text-SeabiscuitMainThemeColor cursor-pointer"
            >
              <Close className="text-[#122B46]" />
            </span>

            <div className="modal-header rounded-t-md font-Poppins">
              <h5
                className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2"
                id="exampleModalScrollableLabel"
              >
                Warning
              </h5>

              <p className="text-[14px] text-[#122B46]">
                Are you sure you want to assign this member's role as {newRole}, as this member is
                already assigned as a {selectedMember?.defaultRole}?
              </p>

              <div className="mt-20">
                <button
                  onClick={handleRoleChange}
                  className="w-full h-12 mx-auto flex items-center justify-center py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#F7074F] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor mt-2"
                >
                  Yes
                </button>

                <button
                  onClick={() => {
                    props?.handleModal(false, MODAL_CONSTS.TEAM_MEMBER_ROLE_CHANGE_WARNING)
                  }}
                  className="w-full h-12 mx-auto flex items-center justify-center py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor mt-2"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HorseTeamMemberRoleChangeConfirmation
