import React from 'react'
import clsx from 'clsx'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface PillButtonElementProps {
  Class?: any
  text: any
  value?: string | number
  onButtonClicked?: any
  selected?: boolean
  visible?: boolean
  disabled?: boolean
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const PillButtonElement: React.FC<PillButtonElementProps> = (props) => {
  const visible = props?.visible ?? true

  return (
    <button
      type="button"
      disabled={props?.disabled ?? false}
      onClick={(_) => props?.onButtonClicked?.(props.value, !props.selected)}
      className={clsx(
        props.Class,
        props.selected
          ? 'bg-[#F7074F0D] text-SeabiscuitMainThemeColor border-[#f7074f00]'
          : 'bg-white border-SeabiscuitGrayThemeColor disabled:hover:!text-SeabiscuitDark200ThemeColor',
        'px-2 text-center 2xl:h-[50px] h-[45px] relative rounded-[12px] 2xl:min-w-[110px] xl:min-w-[100px] text-SeabiscuitDark200ThemeColor transition ease-in duration-200 text-[15px] focus:outline-none border border-solid hover:text-SeabiscuitMainThemeColor !ml-0 !mr-[5px]',
        visible ? 'block' : 'hidden'
      )}
    >
      {props.text}
    </button>
  )
}

PillButtonElement.defaultProps = {
  Class: '',
  text: '',
  value: '',
  onButtonClicked: null,
  selected: false,
}

export { PillButtonElement }
