import React from 'react'
import { CONST } from '../../../../../const/const'
import clsx from 'clsx'
import HorizantalDivider from '../../../../../components/events/views/details/global/HorizantalDivider'
import NewsTab from './news/NewsTab'
import DealsTab from './deals/DealsTab'

import { useIonRouter } from '@ionic/react'
import AboutTab from './deals/AboutTab'
import ProductsTab from './products/ProductsTab'
import MessageTab from './message/MessageTab'

type IProps = {
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>
  currentTab: number
}

const TabList = (props: IProps) => {
  const router = useIonRouter()
  const redFilter =
    'brightness(0) saturate(100%) invert(22%) sepia(82%) saturate(5597%) hue-rotate(332deg) brightness(92%) contrast(111%)'

  return (
    <>
      <div
        className={`flex gap-3 items-center overflow-auto text-SeabiscuitDark200ThemeColor text-sm 2xl:text-nr flex-wrap`}
      >
        {CONST.UI.VENDOR_AND_SPONSOR.TABS.map((currTab, index) => {
          return (
            <button
              key={`currTab${index}`}
              className={clsx(
                'rounded-lg px-3 2xl:px-4 py-2 2xl:py-3 transition ease-in duration-200 flex items-center min-w-fit cursor-pointer gap-2',
                index === props.currentTab && 'text-[#F7074F] bg-[#F7074F18]'
              )}
              onClick={() => props.setCurrentTab(index)}
            >
              <img
                src={currTab.ICON}
                alt="icon"
                className="h-6 w-6"
                style={{
                  filter: index === props.currentTab ? redFilter : undefined,
                }}
              />
              {currTab.NAME}
            </button>
          )
        })}
        <span
          className="border border-solid border-SeabiscuitMainThemeColor px-3 py-1 rounded-3xl flex-shrink-0"
          onClick={() => handleSettingNavigation(router)}
        >
          <button
            type="button"
            className="flex gap-1.5 text-SeabiscuitMainThemeColor items-center "
          >
            <img src="/assets/og_icons/Settings-2.svg" alt="edit_profile" />
            Edit Profile
          </button>
        </span>
      </div>

      <HorizantalDivider />

      {props.currentTab === 0 ? <NewsTab /> : null}

      {props.currentTab === 1 ? <DealsTab /> : null}

      {props.currentTab === 4 ? <AboutTab /> : null}

      {props.currentTab === 2 ? <ProductsTab /> : null}

      {props.currentTab === 3 ? <MessageTab /> : null}
    </>
  )
}

const handleSettingNavigation = (props: any) => {
  props.push('/settings')
}

export default TabList
