import { useHistory } from 'react-router-dom'
import { useIonRouter } from '@ionic/react'
import { useEffect, useState } from 'react'

import EventVendorsFormComponent from '../../../components/events/forms/vendors/EventVendorsFormComponent'

import { EventVendorsModel } from '../../../models/event-vendors/event-vendors.model'

import FirestoreService from '../../../services/firestoreService'

import { CONST } from '../../../const/const'
import { useParams } from 'react-router'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface ShowsDetailPageEventVendorsFormSubSectionProps {
  onSetEventTab: (tab: string) => void
  handleModal?: any
  dataToPassOn?: any
  nextEventTab?: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsDetailPageEventVendorsFormSubSection: React.FC<
  ShowsDetailPageEventVendorsFormSubSectionProps
> = (props) => {
  const router = useIonRouter()
  const history = useHistory()
  const { eventId } = useParams<{ eventId: string }>()

  const [data, setData] = useState<any>(null)

  const validFormHandler = async (data: any) => {
    let updated = true
    let newList: any[] = []

    data.vendorOpportunities &&
      data.vendorOpportunities.length &&
      data.vendorOpportunities.forEach((currentItem: any) => {
        if (
          currentItem.name !== '' &&
          currentItem.note !== '' &&
          currentItem.number !== '' &&
          currentItem.price !== ''
        ) {
          newList.push(currentItem)
        }
      })

    const updated_data = new EventVendorsModel({
      ...data,
      vendorOpportunities: newList,
    })

    await new Promise((resolve) => {
      FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_VENDORS.NAME,
        eventId,
        updated_data.toFirestore()
      )
        .then((_: any) => {
          setData(updated_data.toObject())
          props.onSetEventTab(CONST.UI.EVENTS.CREATE_EDIT.TABS.NEARBY_BUSINESSES.VALUE)
        })
        .catch((reason) => {
          updated = false
          alert('202206261334')
          console.error('202206261334:' + reason)
        })
        .finally(() => {
          resolve(true)
        })
    })

    return {
      updated,
      onSetEventTab: props.onSetEventTab,
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const validAndExitFormHandler = async (data: any) => {
    const updated_data = new EventVendorsModel({
      ...data,
    })

    FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_VENDORS.NAME,
      eventId,
      updated_data.toFirestore()
    )
      .then((_: any) => {
        setData(updated_data.toObject())
        router.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
        history.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
      })
      .catch((reason) => {
        alert('202206261334')
        console.error('202206261334:' + reason)
      })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidFormHandler = async (data: any) => {
    alert('invalid')
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidAndExitFormHandler = async (data: any) => {
    alert('invalid')
    router.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
    history.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        const doc = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_VENDORS.NAME,
          eventId
        )
        if (doc) {
          setData(EventVendorsModel.fromFirestoreDoc(doc).toObject())
        }
      }
      fetchData().catch()
    }
  }, [data, setData, eventId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <EventVendorsFormComponent
        data={data}
        onValid={validFormHandler}
        handleModal={props.handleModal}
        dataToPassOn={props.dataToPassOn}
        onInvalid={invalidFormHandler}
        onValidAnExit={validAndExitFormHandler}
        onInvalidAndExit={invalidAndExitFormHandler}
        nextEventTab={props.nextEventTab}
      />
    </>
  )
}

export default ShowsDetailPageEventVendorsFormSubSection
