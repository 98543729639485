// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React from 'react'

import uploadIcon from './assets/upload-icon.svg'

import uploadToCloudImage from '../../../elements/images/UploadtotheCloud.png'
import SubheadingElement from '../../../elements/headings/subheading/SubheadingElement'

import ProgressBar from './ProgressBar'
import DrawerComponent from './Components/DrawerComponent'
import GlobalButton from './Components/GlobalButton'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const Modal: React.FC<{ show: boolean; onHide: () => void }> = ({ show, onHide }) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <DrawerComponent show={show} onHide={onHide} title="Add images" maxWidth={640}>
      <div className="text-SeabiscuitMainThemeColor bg-[#F7074F15] border-[1px] border-dashed rounded-xl flex flex-col gap-2 items-center p-6 border-SeabiscuitMainThemeColor">
        <img src={uploadIcon} alt="icon" />
        <div>Drag & Drop Images Here</div>
        <div>OR</div>
        <label className="py-4 px-6 text-center cursor-pointer bg-SeabiscuitMainThemeColor w-[270px] h-12 block rounded-xl shadow-md">
          <span className="text-white">Browse Files</span>
          <input type="file" id="fileSelector" className="hidden" />
        </label>
      </div>
      <div className="container-2 mt-6">
        <SubheadingElement text="Uploaded files" classname="sub-heading" />
        <div className="container-3">
          <ProgressBar percentage={50} imgSrc={uploadToCloudImage} imgName="someImage.png" />
          <ProgressBar percentage={100} imgSrc={uploadToCloudImage} imgName="someImage.png" />
        </div>
        <GlobalButton type={1} caption="SAVE" className="w-[270px] mx-auto block mt-20" />
      </div>
    </DrawerComponent>
  )
}

export default Modal
