// Component
import ManageClinicNOtherCard from '../common/card/ManageClinicNOtherCard'

// Constants
import { ICONS_SRC } from './ManageClinicNOtherRootPage'

const ManageClinicNOtherMessagesTab = () => {
  return (
    <>
      <ManageClinicNOtherCard
        disabled={true}
        title={'Exhibitor messages >'}
        category="message"
        icon={ICONS_SRC.EXHIBITOR}
        value1={'0'}
        value2={'0'}
        className={`w-full md:w-1/4`}
      />
      <ManageClinicNOtherCard
        disabled={true}
        title={'Vendor messages >'}
        category="message"
        icon={ICONS_SRC.VENDOR}
        value1={'0'}
        value2={'0'}
        className={`w-full md:w-1/4`}
      />
      <ManageClinicNOtherCard
        disabled={true}
        title={'Sponsor messages >'}
        category="message"
        icon={ICONS_SRC.SPONSOR}
        value1={'0'}
        value2={'0'}
        className={`w-full md:w-1/4`}
      />
      <ManageClinicNOtherCard
        disabled={true}
        title={'Spectator messages >'}
        category="message"
        icon={ICONS_SRC.SPECTATOR}
        value1={'3'}
        value2={'7'}
        className={`w-full md:w-1/4`}
      />

      {/* <ManageClinicNOtherGraphSection registrations={[]} /> */}
    </>
  )
}

export default ManageClinicNOtherMessagesTab
