import TimeLib from '../../../../lib/Time'
import { IEventPaymentSettingsInterface } from '../../../../models/event-payment-settings/event-payment-settings.interface'

export const DATA_FIRESTORE_V01_EVENT_PAYMENT_SETTINGS = {
  NAME: 'v01_event_payments_settings',
  FIELDS: {
    UPDATE: {
      KEY: 'u',
      VALUES: {
        DEFAULT: false,
        NO: false,
        YES: true,
      },
    },

    VERSION: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },

    ALLOW_REGISTRATION_WITHOUT_PAYMENT: {
      KEY: 'allowRegistrationWithoutPayment',
      VALUES: {
        DEFAULT: false,
        YES: true,
        NO: false,
      },
    },

    CHARGE_SALES_TAX: {
      KEY: 'chargeSalesTax',
      VALUES: {
        DEFAULT: true,
        YES: true,
        NO: false,
      },
    },

    PAYMENT_OPTION: {
      KEY: 'paymentOption',
      VALUES: {
        DEFAULT: 'LeavePricesAsIs' as IEventPaymentSettingsInterface['paymentOption'],
        /** @info `LeavePricesAsIs` - Leave prices as they are. Organizer will pay 5% fee. */
        LEAVE_PRICE_AS_IS: 'LeavePricesAsIs' as IEventPaymentSettingsInterface['paymentOption'],
        /** @info `IncreaseAllPrices` - Increase all prices by 5% to cover Pegasus’ fee.  */
        INCREASE_ALL_PRICES: 'IncreaseAllPrices' as IEventPaymentSettingsInterface['paymentOption'],
        /** @info `ChargeRidersAndSpectators` - Charge riders & spectators 2.9% + 30c credit card processing fee. Organizer pays remaining 2.1%. */
        CHARGE_RIDERS_AND_SPECTORORS:
          'ChargeRidersAndSpectators' as IEventPaymentSettingsInterface['paymentOption'],
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    MODIFIED: {
      KEY: 'modified',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
