export const DATA_FIRESTORE_V01_EVENT_POST_DETAILS = {
  NAME: 'v01_event_post_details',
  FIELDS: {
    TYPE: {
      KEY: 'type',
      VALUE: {
        REGULAR: 'regular',
        COMPETITION: 'competition',
      },
    },

    SUBTYPE: {
      KEY: 'subType',
      VALUE: {
        SHOW_JUMPING: 'show_jumping',
        EVENTING: 'eventing',
        DRESSAGE: 'dressage',
        EQUITATION: 'equitation',
        HUNTER: 'hunter',
        JUMPER: 'jumper',
        HUNTER_JUMPER: 'hunter_jumper',
      },
    },

    CATEGORIES: {
      KEY: 'category',
      VALUES: {
        LICENSED: 'licensed',
        UNLICENSED: 'unlicensed',
        CLINIC: 'clinic',
        OTHER: 'other',
      },
    },

    TAGS: {
      KEY: 'tags',
      VALUES: {
        EVENTING: 'eventing',
        DRESSAGE: 'dressage',
        EQUITATION: 'equitation',
        HUNTER: 'hunter',
        JUMPER: 'jumper',
        HUNTER_JUMPER: 'hunter_jumper',
      },
    },

    EVENT_CITY_STATE_COUNTRY_KEY: {
      KEY: 'eventCityStateCountryKey',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    EVENT_PRICE_USD_RANGE: {
      KEY: 'eventPriceUSDRange',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    EVENT_START_DATE_RANGE: {
      KEY: 'eventStartDateRange',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    EVENT_START_DATE_YYYY_MM_DD: {
      KEY: 'eventStartDateYYYYMMDD',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    EVENT_NAME: {
      KEY: 'eventName',
    },

    EVENT_NAME_N_GRAM: {
      KEY: 'eventNameNGram',
    },

    IS_LICENSED: {
      KEY: 'isLicensed',
    },

    EVENT_START_DATE: {
      KEY: 'eventStartDate',
    },

    EVENT_PRICE_USD: {
      KEY: 'eventPriceUSD',
    },

    CITY: {
      KEY: 'city',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    STATE: {
      KEY: 'state',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },

    COUNTRY: {
      KEY: 'country',
      VALUE: {
        UNKNOWN: 'unknown',
      },
    },
  },
}
