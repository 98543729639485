import React from 'react'

// Types

type ITotalRow = {
  item: string
  price: string
  modalThemeColor: `#${string}`
}

type IDetailRow = {
  item: string
  cost: number
}

type IEventReviewPublishInput = {
  className?: string
  data?: any
  onClick?: any
  moreBtn?: boolean
  icon?: string
  style?: React.CSSProperties
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const EventReviewPublishInput: React.FC<IEventReviewPublishInput> = (args) => {
  const { className, data, onClick, moreBtn, icon, style = {} } = args

  return (
    <>
      {onClick ? (
        <div
          onClick={onClick}
          className={`${className} text-SeabiscuitDark200ThemeColor`}
          style={style}
        >
          {data}
          {moreBtn ? <span className="cursor-pointer underline">more</span> : null}
        </div>
      ) : (
        <div className={`${className} text-SeabiscuitDark200ThemeColor`} style={style}>
          {icon ? <img className="w-5 h-5 absolute left-3" src={icon} alt="icon" /> : null}
          {data}
          {moreBtn ? <span className="cursor-pointer underline">more</span> : null}
        </div>
      )}
    </>
  )
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const DetailRow = ({ item, cost }: IDetailRow) => {
  return (
    <div className="flex flex-col md:flex-row md:items-start w-full">
      <EventReviewPublishInput
        className="rounded-lg h-11 bg-SeabiscuitGrayThemeColor text-sm p-3 m-1 ml-0 w-full md:w-8/12"
        data={item}
      />
      <EventReviewPublishInput
        className="rounded-lg bg-SeabiscuitGrayThemeColor relative flex items-center justify-center text-sm p-3 md:m-1 ml-0 w-full md:w-4/12"
        data={`$${cost}`}
      />
    </div>
  )
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const TotalRow = ({ price, modalThemeColor }: ITotalRow) => {
  return (
    <div className="flex flex-col md:flex-row md:items-start w-full">
      <EventReviewPublishInput
        data={`Total`}
        style={{
          backgroundColor: modalThemeColor,
        }}
        className="rounded-lg text-sm p-3 m-1 ml-0 w-full md:w-8/12 font-semibold"
      />
      <EventReviewPublishInput
        data={`$${price}`}
        style={{
          backgroundColor: modalThemeColor,
        }}
        className="rounded-lg relative flex items-center justify-center text-sm p-3 m-1 ml-0 w-full md:w-4/12 font-semibold"
      />
    </div>
  )
}

type ITotal = {
  tax: number
  fees: number
  tickets: number
  governance: number
  registrations: number
}

type Props = {
  totalPayments: ITotal
  paymentOption: string | null
  modalThemeColor: `#${string}`
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const BillDetailsGrandTotalData = ({ totalPayments, modalThemeColor, paymentOption }: Props) => {
  let total = Object.keys(totalPayments).reduce((totalAcc: number, currKey: string) => {
    totalAcc += totalPayments[currKey as keyof ITotal]
    return totalAcc
  }, 0)

  return (
    <>
      <DetailRow item="Registrations" cost={totalPayments?.registrations} />
      <DetailRow item="Fees" cost={totalPayments?.fees} />
      <DetailRow item="Mandatory Fees" cost={totalPayments?.governance} />
      <DetailRow item="Tickets" cost={totalPayments?.tickets} />
      <DetailRow item="Sales Tax" cost={totalPayments?.tax} />
      <TotalRow
        item={`Grand total`}
        price={`${parseFloat(total.toFixed(2))}`}
        modalThemeColor={modalThemeColor}
      />
    </>
  )
}

export default BillDetailsGrandTotalData
