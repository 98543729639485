import { ScheduleItem } from './ScheduleItem'
import React, { FC, useEffect } from 'react'
import moment from 'moment/moment'
import {
  Control,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormReset,
  UseFormWatch,
} from 'react-hook-form'
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form/dist/types/form'
import { useAppSelector } from '../../../../../store/hooks'
import { selectedEvent } from '../../../../../store/events/eventsSlice'
import { EventSchedulingModel } from '../../../../../models/event-scheduling/event-scheduling.model'
import { IFilters } from '../SchedulingScreenEventSchedulingFormComponent'
import { daysOfWeek } from '../../../../../helpers/time'

interface CustomSchedulesProps {
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  locationOptions: { label: string; value: string }[]
  control: Control<any>
  watch: UseFormWatch<any>
  setValue: UseFormSetValue<any>
  errors: any
  register: UseFormRegister<any>
  trigger: UseFormTrigger<any>
  reset: UseFormReset<any>
  remove: UseFieldArrayRemove
  filters: IFilters
}

const eventSchedulingFormDefaultValues = new EventSchedulingModel().toObject()
export const CustomSchedules: FC<CustomSchedulesProps> = ({
  handleModal,
  register,
  control,
  watch,
  trigger,
  setValue,
  errors,
  reset,
  locationOptions,
  remove,
  filters,
}) => {
  const selectedEventR = useAppSelector(selectedEvent)

  const areAllFieldsValid = (index: number) => {
    let tagIsValid = !!watch(`custom.${index}.tag`)
    let locationIsValid = !!watch(`custom.${index}.location`)
    let nameIsValid = !!watch(`custom.${index}.name`)?.trim()
    let noteIsValid = !!watch(`custom.${index}.note`)?.trim()
    let startDateIsValid = moment(watch(`custom.${index}.startDate`)).isValid()
    return nameIsValid && startDateIsValid && tagIsValid && locationIsValid && noteIsValid
  }

  useEffect(() => {
    if (selectedEventR.EventSchedule) reset(selectedEventR.EventSchedule)
    else reset(eventSchedulingFormDefaultValues)
  }, [reset, selectedEventR.EventSchedule, trigger])

  return (
    <>
      {watch('custom').map((field: any, index: number) =>
        (filters.facility.length <= 0 || filters.facility.includes(field.location)) &&
        (filters.day.length <= 0 ||
          filters.day.includes(daysOfWeek[new Date(field.startDate)?.getDay()])) ? (
          <ScheduleItem
            key={index}
            index={index}
            handleModal={handleModal}
            register={register}
            control={control}
            watch={watch}
            trigger={trigger}
            setValue={setValue}
            errors={errors}
            locationOptions={locationOptions}
            remove={remove}
            areAllFieldsValid={areAllFieldsValid}
            selector="custom"
          />
        ) : null
      )}
    </>
  )
}
