import React from 'react'

interface IProps {}

export const IconChevron: React.FC<IProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none" viewBox="0 0 11 7">
      <path stroke="#122B46" strokeLinecap="round" strokeWidth="2" d="M10 1L5.5 5.284 1 1"></path>
    </svg>
  )
}
