const UI_ROLES_CONST = [
  {
    label: 'Agent',
    value: 'Agent',
  },
  {
    label: 'Coach',
    value: 'Coach',
  },
  {
    label: 'Friend',
    value: 'Friend',
  },
  {
    label: 'Groom',
    value: 'Groom',
  },
  {
    label: 'Guardian',
    value: 'Guardian',
  },
  {
    label: 'Owner',
    value: 'Owner',
  },
  {
    label: 'Rider',
    value: 'Rider',
  },
  {
    label: 'Sponsor',
    value: 'Sponsor',
  },
  {
    label: 'Staff',
    value: 'Staff',
  },
  {
    label: 'Trainer',
    value: 'Trainer',
  },
]

export { UI_ROLES_CONST }
