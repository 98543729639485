/* eslint-disable no-loop-func */
import { useContext } from 'react'

// Types

// Third party
import { AddCircleOutline } from '@mui/icons-material'

// Redux

// Constants

import { useIonRouter } from '@ionic/react'
import helpers from '../../../../commonHelpers/helpers'
import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import useHorses from '../../../../hooks/users/competitor/profile/useHorses'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { IHorseTeamInterface } from '../../../../models/horse-team/horseTeam.interface'
import { IUserHorseMappingInterface } from '../../../../models/user-horse-mapping/userHorseMapping.interface'
import formFunctions from '../../../ui/form/form-functions/formFunctions'
import FormHeader from '../../../ui/form/form-header/FormHeader'
import HorseProfile from './components/horse-profile/HorseProfile'
import { LOCALSTORAGE_CONST } from '../../../../const/local-storage-const'
import { useLocation } from 'react-router'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from '../../../../store/hooks'
import { selectUserId } from '../../../../store/user/userSlice'

// Types
type IOnRemoveMemberClickFn = (props: {
  bypass: boolean
  currentRider: IHorseTeamInterface
  mappingDocId: IUserHorseMappingInterface['id']
}) => void

type IOnRemoveHorseClickFn = (props: {
  bypass?: boolean
  mappingDocId: IUserHorseMappingInterface['id']
}) => void

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ProfileRootPageHorseTab = () => {
  // Hooks and vars
  const router = useIonRouter()
  const history = useHistory()
  const toastFunction = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)
  const location = useLocation()

  const userId = useAppSelector(selectUserId)

  const formStyles = formFunctions.getFormStyles()
  const { mappings, removeHorseTeamMember, removeHorseFromDb } = useHorses(userId)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onProfilePicClick = (valueToPass: 'horse' | 'user', userId?: string | null) => {
    if (valueToPass === 'user' && userId) {
      router.push(`${CONST.ROUTES.USER_PROFILE.URL}/${userId}`)
      history.push(`${CONST.ROUTES.USER_PROFILE.URL}/${userId}`)
    } else {
      router.push(CONST.ROUTES.ALL_EVENT.URL, 'none', 'push', {
        as: valueToPass,
      })
      history.push(CONST.ROUTES.ALL_EVENT.URL)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onRemoveHorseClick: IOnRemoveHorseClickFn = async ({ mappingDocId }) => {
    const horseMapping = mappings.data.find((currMapping) => currMapping.id === mappingDocId)
    const mappingId = horseMapping?.id

    if (mappingId) {
      try {
        handleModalContext?.handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
          removeItem: async () => {
            await removeHorseFromDb({
              mappingDocId: mappingId,
              forceRemove: true,
            })
          },
          alertText: (
            <div>
              <h5 className="font-semibold text-xl">Are you sure you want to remove this horse?</h5>
              <p className="text-sm mt-2">
                When you click ‘remove’, you are removing this horse from yourself and your team.
              </p>
            </div>
          ),
        })
      } catch (error: any) {
        toastFunction.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })
      }
    } else {
      toastFunction.error({
        message: `Something went wrong while removing horse, mappingId is ${mappingId}`,
      })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onRemoveMemberClick: IOnRemoveMemberClickFn = (args) => {
    let { currentRider, bypass, mappingDocId } = args
    const horseTeamMemberDocId = currentRider.id

    try {
      if (typeof bypass !== 'boolean') bypass = false

      if (!horseTeamMemberDocId)
        throw Error('Something went wrong while removing team member', {
          cause: `horseTeamMemberDocId is empty`,
        })

      if (bypass)
        return removeHorseTeamMember({
          teamMemberDocId: horseTeamMemberDocId,
          mappingDocId,
        })

      handleModalContext?.handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
        removeItem: () => onRemoveMemberClick({ mappingDocId, bypass: true, currentRider }),
        alertText: 'Are you sure you want to remove this team member?',
      })
    } catch (error: any) {
      toastFunction.error({
        message: error?.message,
      })

      return helpers.logger({
        message: error?.cause ?? error,
      })
    }
  }

  return (
    <>
      <FormHeader title="Horse settings" description="Add or remove horses from your account" />

      <div className={formStyles.className} style={formStyles.styles}>
        <div className="xl:text-nr 2xl:text-base horse_tab gap-2 flex flex-col">
          {mappings.data.length ? (
            mappings.data.map((mapping, index) => {
              return (
                <HorseProfile
                  horseIndex={index}
                  mappingId={mapping.id}
                  horseId={mapping.horseId}
                  isMyHorse={mapping.isMyHorse}
                  horseName={mapping.horseName}
                  horseZone={mapping.horseZone}
                  onProfilePicClick={onProfilePicClick}
                  onRemoveHorseClick={onRemoveHorseClick}
                  horseTeams={mapping.teamMembers ?? []}
                  onRemoveMemberClick={onRemoveMemberClick}
                  horseDiscipline={mapping.horseDiscipline}
                  horseProfilePicture={mapping.horseProfilePicture}
                  key={`${index + 1}${JSON.stringify(mapping)}`}
                />
              )
            })
          ) : (
            <span className="w-full flex justify-center items-center">
              <div className="flex-col justify-center items-center">
                <div className="flex justify-center mb-4">You have not added any horses yet</div>
                <div className="flex justify-center">
                  <img
                    src={'/assets/og_icons/AddHorsePlaceholder.svg'}
                    alt="horse"
                    className="flex justify-center"
                  />
                </div>
                <div
                  onClick={() => {
                    localStorage.setItem(LOCALSTORAGE_CONST.ADD_HORSE_FROM, location.pathname)
                    router.push('/add-horse')
                    history.push('/add-horse')
                  }}
                  className="horseAdd flex items-center justify-center cursor-pointer mt-8"
                >
                  <p className="ml-2 text-SeabiscuitMainThemeColor">Add your first horse {'>'}</p>
                </div>
              </div>
            </span>
          )}
          {mappings.data.length ? (
            <div
              onClick={() => {
                localStorage.setItem(LOCALSTORAGE_CONST.ADD_HORSE_FROM, location.pathname)
                router.push('/add-horse')
                history.push('/add-horse')
              }}
              className="horseAdd flex items-center cursor-pointer mt-8"
            >
              <AddCircleOutline className="text-SeabiscuitMainThemeColor" />
              <p className="ml-2 text-SeabiscuitMainThemeColor">Add horse</p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default ProfileRootPageHorseTab
