import React from 'react'
import { AddCircleOutline } from '@mui/icons-material'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
type IAccordionComponent = {
  title: string
  image: string
  value: number
}
type Props = {
  title: string
  description: IAccordionComponent[]
  showHide: boolean
}
const Accordion = (props: Props) => {
  return (
    <>
      <div className="mt-2 border-[#122B464D] border px-4 py-2 rounded-lg cursor-pointer mb-2">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center">
            <img src="/assets/og_icons/Prize.svg" alt="prize" />
            <h1 className="text-SeabiscuitDark200ThemeColor text-[14px] font-semibold ml-2 capitalize">
              {props.title}
            </h1>
          </div>
          <div>
            {!props.showHide && (
              <div className="status text-gray-400 rounded-full">
                <AddCircleOutline />
              </div>
            )}
            {props.showHide && (
              <div className="status text-gray-400 rounded-full">
                <RemoveCircleOutlineOutlinedIcon />
              </div>
            )}
          </div>
        </div>
        {props.showHide &&
          props.description.map((item) => {
            return (
              <>
                <div className="flex w-full mt-2">
                  <div className="w-1/2 flex justify-start items-center text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize">
                    <img src={item.image} alt="alt" />
                    <p>{item.title}</p>
                  </div>
                  <div className="w-1/2 text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize">
                    <p>{item.value}</p>
                  </div>
                </div>
              </>
            )
          })}
      </div>
    </>
  )
}

export default Accordion
