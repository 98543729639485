// Third party
import { useEffect, useState } from 'react'
import { where } from 'firebase/firestore'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { CONST } from '../../../const/const'
import { getConvertedData } from '../../../models/interface.helper'
import FirestoreService from '../../../services/firestoreService'
import { TEventRegisteredUsers } from '../../../models/event-registered-users/event-registered-users.interface'
import { UserDocumentModel } from '../../../models/user-documents/user-documents.model'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { RiderTeamMemberModel } from '../../../models/rider-team-member/riderTeamMember.model'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  IPaperworkTeam,
  IPaperworkTeamMemberData,
  selectPaperworkRiderTeamMembers,
  selectRiderTeamMembersUpdates,
  setPaperworkRiderTeamMembers,
} from '../../../store/paperworks/paperworkSlice'
import { HorseAccordionWrapper } from './HorseAccordionWrapper'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type IExhibitorProfileTeamTabProps = {
  exhibitorAllData?: TEventRegisteredUsers | null
  isRecipient: boolean
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
}

const ExhibitorProfilePaperworkTab = ({
  exhibitorAllData,
  handleModal,
}: IExhibitorProfileTeamTabProps) => {
  const [openedMenu, setOpenedMenu] = useState<number[]>([])
  const [loading, setLoading] = useState(false)
  const [membersData, setMembersData] = useState<IRiderTeamMemberInterface[]>([])
  const riderTeamMembers = useAppSelector(selectPaperworkRiderTeamMembers)
  const riderTeamMembersUpdates = useAppSelector(selectRiderTeamMembersUpdates)
  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(async () => {
      const riderTeamMembers_: any = []
      const riderTeamMembersSnaps = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [
          where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.EVENT_ID.KEY, '==', exhibitorAllData?.eventId),
          where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.USER_ID.KEY, '==', exhibitorAllData?.userId),
          where(
            COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            exhibitorAllData?.id
          ),
        ]
      )

      if (riderTeamMembersSnaps.size) {
        riderTeamMembersSnaps.forEach((currDoc) => {
          const riderTeamMember = getConvertedData(
            RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject()
          )

          riderTeamMembers_.push(riderTeamMember)
        })
      }

      setMembersData(riderTeamMembers_)
    })()
  }, [riderTeamMembersUpdates])

  useEffect(() => {
    return () => {
      dispatch(setPaperworkRiderTeamMembers([]))
    }
  }, [dispatch])

  useEffect(() => {
    ;(async () => {
      if (!membersData[0]) return null
      if (!exhibitorAllData?.eventId) return null

      const documents = await getDocumentLink(exhibitorAllData.eventId)

      const result: any = {}

      membersData.flat().forEach((item) => {
        const horseId = item.horseId || 'null'

        if (!result[horseId]) {
          result[horseId] = []
        }

        const member =
          documents.find(
            (d) => d.signatoryId === item.riderId || d.signatoryId === item.teamMemberId
          ) || null
        const hasMember = result[horseId].find(
          (d: IRegistrationByDayInterface) => d.riderId === member?.signatoryId
        )

        if (!hasMember) {
          result[horseId].push({ ...item, member })
        }
      })

      const finalResult: IPaperworkTeam[][] = Object.values(result)

      dispatch(setPaperworkRiderTeamMembers(finalResult))
    })()
  }, [exhibitorAllData?.eventId, membersData, dispatch])
  const getDocumentLink = async (eventId: string) => {
    setLoading(true)

    let document_db = await FirestoreService.filterItems(COLLECTIONS.USERS_DOCUMENTS.NAME, [
      where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY, '==', eventId),
    ])

    let documents = document_db.docs.map((currDoc) =>
      getConvertedData(UserDocumentModel.fromFirestoreDoc(currDoc).toObject())
    )

    const membersData_: IPaperworkTeamMemberData[] = []
    documents.forEach((document) => {
      let add_status = false

      if (membersData_.length > 0) {
        membersData_.find((obj) => {
          if (obj.signatoryId === document.signatoryId) {
            obj.problem_found =
              document.status === 'Not Signed' ? obj.problem_found + 1 : obj.problem_found
            obj.paperwork_data.push(document)
            add_status = true
            return true
          }
          return false
        })
      }

      if (!add_status) {
        membersData_.push({
          signatoryId: document.signatoryId,
          signatoryName: document.signatoryName,
          signatoryProfilePicture: document.signatoryProfilePicture,
          signatoryDefaultRole: document.signatoryDefaultRole,
          problem_found: document.status === 'Not Signed' ? 1 : 0,
          paperwork_data: [document],
        })
      }
    })

    // setMembersData(membersData_)
    setLoading(false)
    return membersData_
  }

  useEffect(() => {
    if (exhibitorAllData?.id && exhibitorAllData?.eventId) getDocumentLink(exhibitorAllData.eventId)
  }, [exhibitorAllData])

  /** @info Handle Icon change */
  const handleToggle = (value: number) => {
    // const exist = openedMenu.includes(value)
    //
    // if (exist) {
    //   setOpenedMenu(() => {
    //     return openedMenu.filter((current) => {
    //       return current !== value
    //     })
    //   })
    // } else {
    //   setOpenedMenu((prevState) => [...prevState, value])
    // }
  }

  return (
    <>
      {loading ? (
        <div className="min-h-[100px] flex items-center justify-center">
          <ViewsLoader size="xl" color="red" />
        </div>
      ) : Array.isArray(riderTeamMembers) && riderTeamMembers[0] ? (
        riderTeamMembers.map((team, index) => {
          return (
            <HorseAccordionWrapper
              key={`${JSON.stringify(team)}${index}`}
              openedMenu={openedMenu}
              handleToggle={handleToggle}
              accordionIndex={index}
              team={team}
              addSignerHandler={() => {
                console.log('=>(ExhibitorProfilePaperworkTab.tsx:190) team', team)
                handleModal(true, MODAL_CONSTS.ADD_TEAM_MEMBER_TO_PAPERWORK, {
                  teamMembers: team,
                })
              }}
              getDocumentLink={getDocumentLink}
              eventId={exhibitorAllData?.eventId ?? null}
            />
          )
        })
      ) : (
        <div className="w-full flex justify-center items-center">No data found</div>
      )}
    </>
  )
}

export default ExhibitorProfilePaperworkTab
