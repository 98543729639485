const tags = [
  'All',
  'American Saddlebred',
  'American Quarter Horse',
  'Andalusian',
  'Arabian',
  'Baroque',
  'Barrel Racing',
  'Breed',
  'Carriage',
  'Connemara',
  'Cutting',
  'Dressage',
  'Driving',
  'Endurance',
  'Eventing',
  'Field Hunter',
  'Fox Hunting',
  'Friesian',
  'Gaited',
  'Gymkhana',
  'Hackney',
  'Halter',
  'Horse Management',
  'Horsemanship',
  'Hunter',
  'Hunter Jumper',
  'IHSA',
  'Leisure',
  'Lusitano',
  'Morgan',
  'Mounted Games',
  'Mounted Shooting',
  'National Show Horse',
  'NCEA',
  'OTTB',
  'Painthorse',
  'Paso Fino',
  'Pleasure',
  'Polo',
  'Polocrosse',
  'Pony Club',
  'Quiz',
  'Racing',
  'Ranch Riding',
  'Reining',
  'Rodeo',
  'Roping',
  'Saddle Seat',
  'Shetland',
  'Show Jumping',
  'Stallion at Stud',
  'Team Penning',
  'Tennessee Walking Horse',
  'Tetrathlon',
  'Trail',
  'Trail Riding',
  'Trotting',
  'Vaulting',
  'Welsh Pony/Cob',
  'Western Dressage',
  'Western Hunter',
  'Western Other',
  'Western Pleasure',
  'Western Riding',
  'Working Cow Horse',
  'Working Equitation',
  'Online',
  'Other',
]

export { tags }
