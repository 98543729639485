import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { IUserInterfaceExtended } from '../storeHelpers/userSlice/types'
import { PayloadAction } from '@reduxjs/toolkit'

type IInitialSystemState = {
  visitedUserDetails: {
    isMyProfile: boolean
    isOrganizerProfileView: boolean
    profileDetails: IUserInterfaceExtended
  } | null
  visitedUserProfileDetails: null | IUserInterfaceExtended
}

const initialSystemState: IInitialSystemState = {
  visitedUserDetails: null,
  visitedUserProfileDetails: null,
}

const profilePageSlice = createSlice({
  name: 'profilePage',
  initialState: initialSystemState,
  reducers: {
    setVisitedUserDetailsAc(
      state,
      action: PayloadAction<IInitialSystemState['visitedUserDetails']>
    ) {
      state.visitedUserDetails = action.payload
    },
    setVisitedUserProfileDetailsAc(
      state,
      action: PayloadAction<IInitialSystemState['visitedUserProfileDetails']>
    ) {
      state.visitedUserProfileDetails = action.payload
    },
  },
})

export const { setVisitedUserDetailsAc, setVisitedUserProfileDetailsAc } = profilePageSlice.actions

export const selectVisitedUserDetailsR = (state: RootState) => state.profilePage.visitedUserDetails
export const selectVisitedUserProfileDetailsR = (state: RootState) =>
  state.profilePage.visitedUserProfileDetails

export default profilePageSlice.reducer
