import { Clear } from '@mui/icons-material'
import { useState } from 'react'

type Props = {
  show: boolean
  handleModal: any
}

const tabData = [
  {
    id: 1,
    name: 'Equitation',
  },
  {
    id: 2,
    name: 'Hunter',
  },
  {
    id: 3,
    name: 'Classics',
  },
  {
    id: 4,
    name: 'Derbys',
  },
  {
    id: 5,
    name: 'Leagues',
  },
  {
    id: 6,
    name: 'Jumper',
  },
]

const Equitation = [
  {
    id: 1,
    title: 'Conformation Hunter Combined',
  },
  {
    id: 2,
    title: 'Amateur Owner 3’3” 18-35',
  },
  {
    id: 3,
    title: 'Amateur Owner 3’6” 18-35',
  },
  {
    id: 4,
    title: 'High Performance Confirmation',
  },
  {
    id: 5,
    title: 'Green Confirmation 3’6”',
  },
  {
    id: 6,
    title: 'Confirmation Hunter Combined',
  },
]
const Hunter = [
  {
    id: 1,
    title: 'Conformation Hunter Combined',
  },
  {
    id: 2,
    title: 'Amateur Owner 3’3” 18-35',
  },
  {
    id: 3,
    title: 'Amateur Owner 3’6” 18-35',
  },
  {
    id: 4,
    title: 'High Performance Confirmation',
  },
]

const ViewModal = (props: Props) => {
  const [seletcTab, setSelectTab] = useState(1)
  const tabSelected = (id: number) => {
    setSelectTab(id)
  }
  return (
    <div
      className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pt-10 pb-7 px-8">
          <span
            onClick={() => props.handleModal(false, 'viewButton')}
            className="absolute text right-2 top-2 text-SeabiscuitMainThemeColor cursor-pointer"
          >
            {/* <img
                            src="/assets/og_icons/Close.svg"
                            className="w-6"
                            alt="close icon"
                        /> */}
            <Clear
              fontSize={'small'}
              style={{
                color: '#122B46',
                fontWeight: '400',
              }}
            />
          </span>

          <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
            <h5
              className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2"
              id="exampleModalScrollableLabel"
            >
              Classes Entered
            </h5>
          </div>
          <div className="min-h-[10vh]">
            <ul className="flex justify-between">
              {tabData.map((tab) => {
                return (
                  <>
                    <li
                      className={`${seletcTab === tab.id && 'font-bold'} cursor-pointer text-SeabiscuitDark200ThemeColor text-[14px]`}
                      onClick={() => tabSelected(tab.id)}
                    >
                      {tab.name}
                    </li>
                  </>
                )
              })}
            </ul>
            <div className="w-full">
              {seletcTab === 1 && (
                <>
                  <div className="w-full pt-4 pb-4">
                    {Hunter.map((event) => {
                      return (
                        <>
                          <div className="flex items-center pl-4 border cursor-pointer mt-2 border-gray-200 rounded">
                            <label
                              htmlFor={`bordered-checkbox-${event.id}`}
                              className="w-full flex items-center py-4 text-sm font-medium text-gray-900 cursor-pointer"
                            >
                              <img src="assets/img/light/prize.png" alt="prize" />
                              <p>{event.title}</p>
                            </label>
                            <input
                              id={`bordered-checkbox-${event.id}`}
                              type="checkbox"
                              value=""
                              name="bordered-checkbox"
                              className="w-4 h-4 mr-2 text-green-600 bg-gray-100 border-[#D3DAEE] rounded focus:ring-green-500 focus:ring-2 "
                            />
                          </div>
                        </>
                      )
                    })}
                  </div>
                </>
              )}
              {seletcTab === 2 && (
                <>
                  <div className="w-full pt-4 pb-4">
                    {Equitation.map((event) => {
                      return (
                        <>
                          <div className="flex items-center pl-4 border mt-2 border-gray-200 rounded">
                            <label
                              htmlFor={`bordered-checkbox-${event.id}`}
                              className="w-full flex items-center py-4 text-sm font-medium text-gray-900"
                            >
                              <img src="assets/img/light/prize.png" alt="prize" />
                              <p>{event.title}</p>
                            </label>
                            <input
                              id={`bordered-checkbox-${event.id}`}
                              type="checkbox"
                              value=""
                              name="bordered-checkbox"
                              className="w-4 h-4 mr-2 text-green-600 bg-gray-100 border-[#D3DAEE] rounded focus:ring-green-500 focus:ring-2 "
                            />
                          </div>
                        </>
                      )
                    })}
                  </div>
                </>
              )}
              {seletcTab === 3 && (
                <>
                  <div className="w-full pt-4 pb-4">
                    {Hunter.map((event) => {
                      return (
                        <>
                          <div className="flex items-center pl-4 border mt-2 border-gray-200 rounded">
                            <label
                              htmlFor={`bordered-checkbox-${event.id}`}
                              className="w-full flex items-center py-4 text-sm font-medium text-gray-900"
                            >
                              <img src="assets/img/light/prize.png" alt="prize" />
                              <p>{event.title}</p>
                            </label>
                            <input
                              id={`bordered-checkbox-${event.id}`}
                              type="checkbox"
                              value=""
                              name="bordered-checkbox"
                              className="w-4 h-4 mr-2 text-green-600 bg-gray-100 border-[#D3DAEE] rounded focus:ring-green-500 focus:ring-2 "
                            />
                          </div>
                        </>
                      )
                    })}
                  </div>
                </>
              )}
              {seletcTab === 4 && (
                <>
                  <div className="w-full pt-4 pb-4">
                    {Equitation.map((event) => {
                      return (
                        <>
                          <div className="flex items-center pl-4 border mt-2 border-gray-200 rounded">
                            <label
                              htmlFor={`bordered-checkbox-${event.id}`}
                              className="w-full flex items-center py-4 text-sm font-medium text-gray-900"
                            >
                              <img src="assets/img/light/prize.png" alt="prize" />
                              <p>{event.title}</p>
                            </label>
                            <input
                              id={`bordered-checkbox-${event.id}`}
                              type="checkbox"
                              value=""
                              name="bordered-checkbox"
                              className="w-4 h-4 mr-2 text-green-600 bg-gray-100 border-[#D3DAEE] rounded focus:ring-green-500 focus:ring-2 "
                            />
                          </div>
                        </>
                      )
                    })}
                  </div>
                </>
              )}
              {seletcTab === 5 && (
                <>
                  <div className="w-full pt-4 pb-4">
                    {Hunter.map((event) => {
                      return (
                        <>
                          <div className="flex items-center pl-4 border mt-2 border-gray-200 rounded">
                            <label
                              htmlFor={`bordered-checkbox-${event.id}`}
                              className="w-full flex items-center py-4 text-sm font-medium text-gray-900"
                            >
                              <img src="assets/img/light/prize.png" alt="prize" />
                              <p>{event.title}</p>
                            </label>
                            <input
                              id={`bordered-checkbox-${event.id}`}
                              type="checkbox"
                              value=""
                              name="bordered-checkbox"
                              className="w-4 h-4 mr-2 text-green-600 bg-gray-100 border-[#D3DAEE] rounded focus:ring-green-500 focus:ring-2 "
                            />
                          </div>
                        </>
                      )
                    })}
                  </div>
                </>
              )}
              {seletcTab === 6 && (
                <>
                  <div className="w-full pt-4 pb-4">
                    {Hunter.map((event) => {
                      return (
                        <>
                          <div className="flex items-center pl-4 border mt-2 border-gray-200 rounded">
                            <label
                              htmlFor={`bordered-checkbox-${event.id}`}
                              className="w-full flex items-center py-4 text-sm font-medium text-gray-900"
                            >
                              <img src="assets/img/light/prize.png" alt="prize" />
                              <p>{event.title}</p>
                            </label>
                            <input
                              id={`bordered-checkbox-${event.id}`}
                              type="checkbox"
                              value=""
                              name="bordered-checkbox"
                              className="w-4 h-4 mr-2 text-green-600 bg-gray-100 border-[#D3DAEE] rounded focus:ring-green-500 focus:ring-2 "
                            />
                          </div>
                        </>
                      )
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
          <button
            onClick={() => props.handleModal(false, 'viewButton')}
            type="button"
            className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
          >
            CANCAL
          </button>
        </div>
      </div>
    </div>
  )
}

export default ViewModal
