import SpectatorTickesPurchaseWrapper from '../SpectatorTickesPurchaseWrapper'
import { IEventDetails } from '../../../models/event-details/event-details.interface'
import { TicketType } from '../../../models/event-ticketing/event-ticketing.interface'

import { useEffect } from 'react'

// import { Tooltip } from '@mui/material';
// import TooltipIcon from '../../../helpers/TooltipIcon';

type Props = {
  EventDetails: IEventDetails
  EventTickets: TicketType[]
  saveRef: any
  nextRef: any
  onSetEventTab: any
  setLoading: any
}

const SpeactatorTicketsPuchaseReceiptTab = (props: Props) => {
  const { saveRef, nextRef } = props
  // const { EventTickets: { tickets } } = props
  const handleSave = () => {
    // console.log("save3")
  }

  const handleNext = () => {
    //  console.log("next3")
  }

  useEffect(() => {
    saveRef.current = handleSave
    nextRef.current = handleNext
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SpectatorTickesPurchaseWrapper
      title="Tickets"
      description="Select tickets to purchase"
    ></SpectatorTickesPurchaseWrapper>
  )
}

export default SpeactatorTicketsPuchaseReceiptTab
