// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { validateCheckboxFieldChange } from '../../../../EventPrizeListFormBuilderUtilValidation'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */
export class EventPrizeListFormBuilderUtilValidationHunterStandardSectionMainCheckboxHelper {
  public field?: any
  public parentField?: any
  public rootField?: any
  public previousValue?: boolean
  public newValue?: boolean
  public setValue?: any
  public getValues?: any
  public setError?: any
  public clearErrors?: any
  public trigger?: any
  public disciplineIndex?: number
  public divisionIndex?: number
  public sectionIndex?: number
  public subSectionIndex?: number

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: any) {
    this.field = obj?.field ?? null
    this.parentField = obj?.parentField ?? null
    this.rootField = obj?.rootField ?? null
    this.previousValue = obj?.previousValue ?? null
    this.newValue = obj?.newValue ?? null
    this.setValue = obj?.setValue ?? null
    this.getValues = obj?.getValues ?? null
    this.setError = obj?.setError ?? null
    this.clearErrors = obj?.clearErrors ?? null
    this.trigger = obj?.trigger ?? null
    this.disciplineIndex = obj?.disciplineIndex ?? null
    this.divisionIndex = obj?.divisionIndex ?? null
    this.sectionIndex = obj?.sectionIndex ?? null
    this.subSectionIndex = obj?.subSectionIndex ?? null
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public async validate() {
    if (this.newValue === false) {
      this._clearErrors()
    } else {
      await this.trigger(
        `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionTotalPriceMoney.value`
      )

      validateCheckboxFieldChange(
        this.parentField?.subSections[0]?.subSectionUnderSaddleClass,
        this.parentField?.subSections[0],
        this.parentField,
        this.previousValue,
        this.newValue,
        this.setValue,
        this.getValues,
        this.setError,
        this.clearErrors,
        this.trigger,
        this.disciplineIndex,
        this.divisionIndex,
        this.sectionIndex,
        0
      )

      validateCheckboxFieldChange(
        this.parentField?.subSections[0]?.subSectionHandyClass,
        this.parentField?.subSections[0],
        this.parentField,
        this.previousValue,
        this.newValue,
        this.setValue,
        this.getValues,
        this.setError,
        this.clearErrors,
        this.trigger,
        this.disciplineIndex,
        this.divisionIndex,
        this.sectionIndex,
        0
      )

      validateCheckboxFieldChange(
        this.parentField?.subSections[0]?.subSectionConformationClass,
        this.parentField?.subSections[0],
        this.parentField,
        this.previousValue,
        this.newValue,
        this.setValue,
        this.getValues,
        this.setError,
        this.clearErrors,
        this.trigger,
        this.disciplineIndex,
        this.divisionIndex,
        this.sectionIndex,
        0
      )
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  private _setErrorMessage(error_message: string) {
    this.setError(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionUnderSaddleClass.value`,
      { type: 'custom', message: error_message }
    )
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  private _clearErrors() {
    this.clearErrors(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionUnderSaddleClass.value`
    )
    this.clearErrors(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionHandyClass.value`
    )
    this.clearErrors(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionConformationClass.value`
    )
    this.clearErrors(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionHeight.value`
    )
    this.clearErrors(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionTotalPriceMoney.value`
    )
    this.clearErrors(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionJudge.value`
    )
  }
}
