import { MODAL_CONSTS } from '../../const/modal-const'

const MODALS_INI_STATE = {
  ...Object.keys(MODAL_CONSTS).reduce((keysAccumulator: { [x: string]: boolean }, currKey: any) => {
    keysAccumulator[`${MODAL_CONSTS[currKey as keyof typeof MODAL_CONSTS]}`] = false
    return keysAccumulator
  }, {}),
}

export { MODALS_INI_STATE }
