import { useState } from 'react'
import Accordion from '../component/Accordion'
import { Clear } from '@mui/icons-material'

type Props = {
  show: boolean
  handleModal: any
}

const tabData = [
  {
    id: 1,
    name: 'Equitation',
  },
  {
    id: 2,
    name: 'Hunter',
  },
  {
    id: 3,
    name: 'Classics',
  },
  {
    id: 4,
    name: 'Derbys',
  },
  {
    id: 5,
    name: 'Leagues',
  },
  {
    id: 6,
    name: 'Jumper',
  },
]

const resultAccordian = [
  {
    id: 1,
    title: 'Conformation Hunter Combined',
    descritption: [
      {
        title: 'Placing',
        image: 'assets/img/light/prize.png',
        value: 534,
      },
      {
        title: 'Placing',
        image: 'assets/img/light/prize.png',
        value: 53,
      },
      {
        title: 'Placing',
        image: 'assets/img/light/prize.png',
        value: 23,
      },
    ],
  },
  {
    id: 2,
    title: 'Conformation Hunter',
    descritption: [
      {
        title: 'Placing d',
        image: 'assets/img/light/prize.png',
        value: 54551,
      },
      {
        title: 'Placingdf',
        image: 'assets/img/light/prize.png',
        value: 5222,
      },
    ],
  },
  {
    id: 4,
    title: 'Amateur Owner 3’3” 18-35',
    descritption: [
      {
        title: 'Placing',
        image: 'assets/img/light/prize.png',
        value: 65,
      },
      {
        title: 'Placing',
        image: 'assets/img/light/prize.png',
        value: 345346,
      },
    ],
  },
]

const ViewModalResult = (props: Props) => {
  const [seletcTab, setSelectTab] = useState(1)
  const tabSelected = (id: number) => {
    setSelectTab(id)
  }
  const [showAccordian, setShowAccordian] = useState<null | number>(null)
  const showHideAccordion = (id: number) => {
    if (showAccordian === id) {
      setShowAccordian(null)
    } else {
      setShowAccordian(id)
    }
  }
  return (
    <div
      className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pt-10 pb-7 px-8">
          <span
            onClick={() => props.handleModal(false, 'viewButton')}
            className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
          >
            {/* <img
                            src="/assets/og_icons/Close.svg"
                            className="w-6"
                            alt="close icon"
                        /> */}
            <Clear
              fontSize={'small'}
              style={{
                color: '#122B46',
                fontWeight: '400',
              }}
            />
          </span>

          <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
            <h5
              className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2"
              id="exampleModalScrollableLabel"
            >
              Results
            </h5>
          </div>
          <div className="min-h-1/2">
            <ul className="flex justify-between">
              {tabData.map((tab) => {
                return (
                  <>
                    <li
                      className={`${seletcTab === tab.id && 'font-bold'} cursor-pointer text-SeabiscuitDark200ThemeColor text-[14px]`}
                      onClick={() => tabSelected(tab.id)}
                    >
                      {tab.name}
                    </li>
                  </>
                )
              })}
            </ul>
            <div className="w-full">
              {seletcTab === 1 && (
                <>
                  <div className="w-full pt-4 pb-4">
                    {resultAccordian.map((item) => {
                      return (
                        <>
                          <span onClick={() => showHideAccordion(item.id)}>
                            <Accordion
                              title={item.title}
                              description={item.descritption}
                              showHide={item.id === showAccordian}
                            />
                          </span>
                        </>
                      )
                    })}
                  </div>
                </>
              )}
              {seletcTab === 2 && (
                <>
                  <div className="w-full pt-4 pb-4">
                    {resultAccordian.map((item) => {
                      return (
                        <>
                          <span onClick={() => showHideAccordion(item.id)}>
                            <Accordion
                              title={item.title}
                              description={item.descritption}
                              showHide={item.id === showAccordian}
                            />
                          </span>
                        </>
                      )
                    })}
                  </div>
                </>
              )}
              {seletcTab === 3 && (
                <>
                  <div className="w-full pt-4 pb-4">
                    {resultAccordian.map((item) => {
                      return (
                        <>
                          <span onClick={() => showHideAccordion(item.id)}>
                            <Accordion
                              title={item.title}
                              description={item.descritption}
                              showHide={item.id === showAccordian}
                            />
                          </span>
                        </>
                      )
                    })}
                  </div>
                </>
              )}
              {seletcTab === 4 && (
                <>
                  <div className="w-full pt-4 pb-4">
                    {resultAccordian.map((item) => {
                      return (
                        <>
                          <span onClick={() => showHideAccordion(item.id)}>
                            <Accordion
                              title={item.title}
                              description={item.descritption}
                              showHide={item.id === showAccordian}
                            />
                          </span>
                        </>
                      )
                    })}
                  </div>
                </>
              )}
              {seletcTab === 5 && (
                <>
                  <div className="w-full pt-4 pb-4">
                    {resultAccordian.map((item) => {
                      return (
                        <>
                          <span onClick={() => showHideAccordion(item.id)}>
                            <Accordion
                              title={item.title}
                              description={item.descritption}
                              showHide={item.id === showAccordian}
                            />
                          </span>
                        </>
                      )
                    })}
                  </div>
                </>
              )}
              {seletcTab === 6 && (
                <>
                  <div className="w-full pt-4 pb-4">
                    {resultAccordian.map((item) => {
                      return (
                        <>
                          <span onClick={() => showHideAccordion(item.id)}>
                            <Accordion
                              title={item.title}
                              description={item.descritption}
                              showHide={item.id === showAccordian}
                            />
                          </span>
                        </>
                      )
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
          <button
            onClick={() => props.handleModal(false, 'viewButton')}
            type="button"
            className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
          >
            CANCAL
          </button>
        </div>
      </div>
    </div>
  )
}

export default ViewModalResult
