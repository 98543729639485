// Types

type IProps = {
  title: string
  onSubmit?: any
  minHeight?: string
  width?: string
  margin?: string
  description?: string
  dontWrapWithForm?: boolean
  children: React.ReactElement
  dividerStyles?: React.CSSProperties
  headerButtonsContainer?: React.ReactElement | null
  onChange?: any
  removeBreakLine?: boolean
  noPaddingRt?: boolean
}

const WrapperWithHeader = (props: IProps) => {
  const { title, description, children, dontWrapWithForm } = props
  const formProps = {
    key: 'formProps',
    onSubmit: typeof props?.onSubmit === 'function' ? props.onSubmit : undefined,
    style: {
      ...(props?.minHeight && { minHeight: props?.minHeight }),
      ...(props?.width && { width: props?.width }),
      ...(props?.margin && { margin: props?.margin }),
    },
    onChange: props.onChange,
  }

  const ChildComponent = () => (
    <>
      <div className="mb-2 md:mb-0 flex items-start flex-wrap md:flex-nowrap">
        <div className="grow mb-3">
          <h1 className="text-SeabiscuitDark200ThemeColor text-[19px] font-bold mb-1 mt-3 ml-1">
            {title}
          </h1>
          <div className="text-SeabiscuitLightParagraphTextColor font-normal text-md max-w-[92%]">
            {description}
          </div>
        </div>
        {props?.headerButtonsContainer ? (
          <div className="w-fit mb-2 md:mb-0">{props.headerButtonsContainer}</div>
        ) : null}
      </div>
      {/* {!props.removeBreakLine && <hr
                className="mt-2 mb-8"
                style={props?.dividerStyles ?? {}} /> } */}

      <div
        className={`${
          ['Paid bills', 'Unpaid bills', 'Refunds'].find((data) => data === title)
            ? 'overflow-auto h-[80%] bg-white'
            : 'bg-white'
        }`}
        style={{ ...(props?.noPaddingRt ? {} : { paddingRight: '8px' }) }}
      >
        {children}
      </div>
    </>
  )

  if (dontWrapWithForm === true) return <ChildComponent />

  return (
    <form
      {...formProps}
      className={`${
        ['Paid bills', 'Unpaid bills', 'Refunds'].find((data) => data === title) ? 'h-[85%]' : ''
      }`}
    >
      <ChildComponent />
    </form>
  )
}

export default WrapperWithHeader
