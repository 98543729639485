import { AddCircleOutline, AutorenewRounded } from '@mui/icons-material'
import { where } from 'firebase/firestore'
import { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import helpers from '../../../../commonHelpers/helpers'
import FormHeader from '../../../../components/ui/form/form-header/FormHeader'
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { IDealInterface } from '../../../../models/deal/deal.interface'
import { DealModel } from '../../../../models/deal/deal.model'
import { getConvertedData } from '../../../../models/interface.helper'
import FirestoreService from '../../../../services/firestoreService'
import { useAppSelector } from '../../../../store/hooks'
import { selectUserId } from '../../../../store/user/userSlice'
import TABS from '../tabs/tabs.json'
import Deal from './Deal'
import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const LiveDeals = () => {
  const userId = useAppSelector(selectUserId)

  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)

  const toastFunctions = useToasterHelper()
  const handleModal = useContext(HandleModalContext)

  const { control, handleSubmit, register, watch, reset, setValue } = useForm({
    mode: 'onChange',
  })

  const dealsDataArray = useFieldArray({
    control,
    name: 'deals',
  })

  const VENDOR_AND_SPONSOR = CONST.UI.VENDOR_AND_SPONSOR
  const DUMMY_DEALS = VENDOR_AND_SPONSOR.DUMMY_DATA.DEALS

  useEffect(() => {
    fetchDeals(userId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  async function fetchDeals(userId: string | null) {
    if (userId) {
      FirestoreService.filterItems(COLLECTIONS.DEALS.NAME, [
        where(
          COLLECTIONS.DEALS.FIELDS.CREATOR_TYPE.KEY,
          '==',
          COLLECTIONS.DEALS.FIELDS.CREATOR_TYPE.VALUES.VENDOR
        ),
        where(
          COLLECTIONS.DEALS.FIELDS.STATUS.KEY,
          '==',
          COLLECTIONS.DEALS.FIELDS.STATUS.VALUES.LIVE
        ),
        where(COLLECTIONS.DEALS.FIELDS.CREATOR_ID.KEY, '==', userId),
      ])
        .then((dealSnapshots) => {
          const deals: IDealInterface[] = []
          dealSnapshots.forEach((currDealSnapshot) => {
            deals.push(
              getConvertedData(DealModel.fromFirestoreDoc(currDealSnapshot).clearUpdatedMark())
            )
          })
          reset({
            deals,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  const onAddButtonClick = () => {
    handleModal?.handleModal?.(true, MODAL_CONSTS.CREATE_AND_UPDATE_DEAL, {
      dealDoc: new DealModel().toObject(),
      updateDeal: (createdDeal: IDealInterface) => {
        if (createdDeal?.status === COLLECTIONS.DEALS.FIELDS.STATUS.VALUES.LIVE) {
          dealsDataArray.append(createdDeal)
        }
      },
    })
  }

  const onSubmit = async (data: any) => {
    try {
      setSaving(true)

      let deal: IDealInterface | null = null
      let updatedDeals: IDealInterface[] = []

      const deals = data.deals as IDealInterface[]

      const dealsToUpdate = deals.filter((deal) => {
        if (deal.delete !== true && deal.status === COLLECTIONS.DEALS.FIELDS.STATUS.VALUES.LIVE) {
          updatedDeals.push(
            getConvertedData({
              ...new DealModel(deal).toFirestore(),
              u: false,
              id: deal.id,
            })
          )
        }
        return !!deal.u && deal.delete !== true
      })
      const dealsToDelete = deals.filter((deal) => {
        return !!deal.delete && !!deal.id
      })

      await helpers.asyncWhileLoop({
        loopCount: dealsToUpdate.length,
        functionToFire: async (index) => {
          deal = dealsToUpdate[index]

          await FirestoreService.updateItem(
            COLLECTIONS.DEALS.NAME,
            deal.id,
            new DealModel(deal).toFirestore()
          )
        },
      })

      await helpers.asyncWhileLoop({
        loopCount: dealsToDelete.length,
        functionToFire: async (index) => {
          deal = dealsToDelete[index]

          await FirestoreService.deleteItem(COLLECTIONS.DEALS.NAME, deal.id!)
        },
      })

      reset({
        deals: updatedDeals,
      })

      toastFunctions.success({
        message: MESSAGES_CONST.DEALS_UPDATED,
      })
    } catch (error) {
      console.error(error)
      setLoading(false)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setSaving(false)
    }
  }

  const onEditButtonClick = (index: number) => {
    handleModal?.handleModal?.(true, MODAL_CONSTS.CREATE_AND_UPDATE_DEAL, {
      dealDoc: watch(`deals.${index}`),
      updateDeal: (updatedDeal: IDealInterface) => {
        if (updatedDeal?.status === COLLECTIONS.DEALS.FIELDS.STATUS.VALUES.DRAFT) {
          dealsDataArray.remove(index)
        } else {
          dealsDataArray.update(index, updatedDeal)
        }
      },
    })
  }

  const onRemoveButtonClick = (index: number, bypass = false) => {
    if (bypass !== true) {
      handleModal?.handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
        removeItem: function () {
          //   dealsDataArray.update(index, {
          //     ...watch(`deals.${index}`),
          //     delete: true,
          //   });
          dealsDataArray.remove(index)
        },
      })
      return 0
    }
  }

  return (
    <>
      <div>
        <FormHeader
          title={TABS[0].title}
          description={TABS[0].description}
          headerButtonsContainer={
            <div className="flex items-center gap-2">
              <button
                type="button"
                onClick={() => {
                  handleSubmit(onSubmit)()
                }}
                disabled={saving || loading}
                className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn min-w-[150px]"
              >
                {saving ? (
                  <AutorenewRounded fontSize="small" className="animate-spin" />
                ) : (
                  'Save changes'
                )}
              </button>
            </div>
          }
        />

        <div className="flex flex-col gap-4">
          {dealsDataArray.fields.length ? (
            (loading ? DUMMY_DEALS : dealsDataArray.fields).map((currDeal: any, currIndex) => {
              if (currDeal?.delete) {
                return null
              }

              return (
                <Deal
                  watch={watch}
                  loading={loading}
                  index={currIndex}
                  register={register}
                  setValue={setValue}
                  deal={currDeal}
                  name={`deals.${currIndex}`}
                  onEditButtonClick={onEditButtonClick}
                  onRemoveButtonClick={onRemoveButtonClick}
                  key={`deal${currIndex}`}
                />
              )
            })
          ) : (
            <>
              <DataNotAvailable
                mode="graphic"
                imageSource={'assets/placeholders/noResultsForSearch.svg'}
                imageAlt="persons in round frames"
                upperText={'You do not added any deals yet'}
                // bottomText={"not its not"}
              />
            </>
          )}
        </div>
        <button
          className="text-SeabiscuitMainThemeColor flex items-center gap-2 text-sm font-[400] mt-2"
          onClick={onAddButtonClick}
        >
          <AddCircleOutline />
          Add deal
        </button>
      </div>
    </>
  )
}

export default LiveDeals
