import { where } from 'firebase/firestore'
import { useContext, useEffect, useState } from 'react'
import { CONST } from '../../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../../../const/modal-const'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'
import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'
import { getConvertedData } from '../../../../../../models/interface.helper'
import { IProductInterface } from '../../../../../../models/product/product.interface'
import { ProductModel } from '../../../../../../models/product/product.model'
import FirestoreService from '../../../../../../services/firestoreService'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectUserId } from '../../../../../../store/user/userSlice'
import EmptyList from '../EmptyList'
import ProductCard from './ProductCard'
import { httpService } from '../../../../../../services/httpService'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const ProductsTab = () => {
  const userId = useAppSelector(selectUserId)

  const [loading, setLoading] = useState(true)
  const [deletingIndex, setDeletingIndex] = useState(-1)
  const [products, setProducts] = useState<IProductInterface[]>([])

  const toastFunctions = useToasterHelper()

  const VENDOR_AND_SPONSOR = CONST.UI.VENDOR_AND_SPONSOR
  const DUMMY_PRODUCTS = VENDOR_AND_SPONSOR.DUMMY_DATA.PRODUCTS

  const handleModal = useContext(HandleModalContext)

  useEffect(() => {
    fetchProducts(userId)
  }, [userId])

  async function fetchProducts(userId: string | null) {
    if (userId) {
      FirestoreService.filterItems(COLLECTIONS.PRODUCTS.NAME, [
        where(
          COLLECTIONS.PRODUCTS.FIELDS.CREATOR_TYPE.KEY,
          '==',
          COLLECTIONS.PRODUCTS.FIELDS.CREATOR_TYPE.VALUES.VENDOR
        ),
        where(
          COLLECTIONS.PRODUCTS.FIELDS.STATUS.KEY,
          '==',
          COLLECTIONS.PRODUCTS.FIELDS.STATUS.VALUES.LIVE
        ),
        where(COLLECTIONS.PRODUCTS.FIELDS.CREATOR_ID.KEY, '==', userId),
      ])
        .then((productSnapshots) => {
          const products: IProductInterface[] = []
          productSnapshots.forEach((currProductSnapshot) => {
            products.push(getConvertedData(ProductModel.fromFirestoreDoc(currProductSnapshot)))
          })
          setProducts(products)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  const onAddButtonClick = () => {
    handleModal?.handleModal?.(true, MODAL_CONSTS.CREATE_AND_UPDATE_PRODUCT, {
      productDoc: new ProductModel().toObject(),
      updateProduct: (createdProduct: IProductInterface) => {
        let createdProduct_ = [...products]
        if (createdProduct?.status === 'live') {
          createdProduct_.unshift(createdProduct)
          setProducts([...createdProduct_])
        }
      },
    })
  }

  const onEditButtonClick = (index: number) => {
    handleModal?.handleModal?.(true, MODAL_CONSTS.CREATE_AND_UPDATE_PRODUCT, {
      productDoc: products[index],
      updateProduct: (updatedProduct: IProductInterface) => {
        let updatedProduct_ = [...products]

        if (updatedProduct?.status === 'draft') {
          updatedProduct_.splice(index, 1)
        } else {
          updatedProduct_.splice(index, 1, updatedProduct)
        }

        setProducts([...updatedProduct_])
      },
    })
  }

  const onMoreButtonClick = (productIndex: number) => {
    handleModal?.handleModal?.(true, MODAL_CONSTS.PRODUCT, products[productIndex])
  }

  const onDeleteButtonClick = async (productIndex: number, bypass = false) => {
    if (bypass !== true) {
      handleModal?.handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
        removeItem: function () {
          onDeleteButtonClick(productIndex, true)
        },
      })
      return 0
    }

    try {
      setDeletingIndex(productIndex)

      if (products[productIndex].pictures?.length) {
        await httpService({
          url: 'remove_images',
          method: 'post',
          data: {
            urls: products[productIndex].pictures,
          },
        })
      }

      await FirestoreService.deleteItem(COLLECTIONS.PRODUCTS.NAME, products[productIndex]?.id!)

      let updatedProduct_ = [...products]
      updatedProduct_.splice(productIndex, 1)

      setProducts([...updatedProduct_])

      toastFunctions.success({
        message: MESSAGES_CONST.PRODUCT_DELETED,
      })
    } catch (error) {
      console.error(error)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setDeletingIndex(-1)
      return 0
    }
  }

  if (!products.length && !loading) {
    return (
      <EmptyList text={VENDOR_AND_SPONSOR.MESSAGES.PRODUCTS.MESSAGE} onClick={onAddButtonClick} />
    )
  }

  return (
    <div
      id="products-section"
      itemScope
      itemType="http://schema.org/ProductsArticle"
      className="max-h-[600px] overflow-auto"
    >
      {(loading ? DUMMY_PRODUCTS : products).map((currProduct: any, currIndex) => {
        return (
          <ProductCard
            {...currProduct}
            loading={loading}
            index={currIndex}
            loggedInUserId={userId}
            key={`product${currIndex}`}
            deletingIndex={deletingIndex}
            onMoreButtonClick={onMoreButtonClick}
            onDeleteButtonClick={onDeleteButtonClick}
            onEditButtonClick={onEditButtonClick}
          />
        )
      })}

      {!loading ? (
        <div className="mt-4 text-center">
          <button onClick={onAddButtonClick}>
            <img src="/assets/og_icons/plus-icon-2.svg" alt="add" />
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default ProductsTab
