import React from 'react'

export const IconMessage: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path
      fill="rgba(18, 43, 70, 0.5)"
      d="M5.25 4A3.262 3.262 0 002 7.25v9.5A3.262 3.262 0 005.25 20h13.5A3.261 3.261 0 0022 16.75v-9.5A3.262 3.262 0 0018.75 4H5.25zm0 1.5h13.5c.975 0 1.75.775 1.75 1.75v.553L12 12.398 3.5 7.803V7.25c0-.975.775-1.75 1.75-1.75zM3.5 9.508l8.144 4.402a.75.75 0 00.712 0L20.5 9.508v7.242a1.74 1.74 0 01-1.75 1.75H5.25a1.74 1.74 0 01-1.75-1.75V9.508z"
    ></path>
  </svg>
)
