import { Link } from 'react-router-dom'

// Types
type IProps = {
  url?: string
  imgSrc: string
  text: string
  linkText: string
  height?: number | null
  onClickOfLink?: () => void
  mainClasses?: string
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const NoDataAvailable = (props: IProps) => {
  return (
    <div
      className={`my-10 mt-20 mx-5 flex items-center justify-center flex-col w-full ${props?.mainClasses}`}
      style={{
        height: props.height ? `${props.height}px` : '40vh',
      }}
    >
      <div className="w-[300px] h-full object-contain">
        <img src={props.imgSrc} className="w-full h-full object-contain" alt="no data" />
      </div>

      <div className="text-black opacity-50 text-nr mt-10">{props.text}</div>
      {/* <span onClick={props?.onClickOfLink}>
                {props.url ?
                    <Link to={props.url}>
                        <div className='text-SeabiscuitMainThemeColor text-nr'>{props.linkText}</div>
                    </Link> :
                    <div className='text-SeabiscuitMainThemeColor text-nr cursor-pointer'>{props.linkText}</div>}
            </span> */}
    </div>
  )
}

export default NoDataAvailable
