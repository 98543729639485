import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { useIonRouter } from '@ionic/react'

import EventSponsorsFormComponent from '../../../components/events/forms/sponsors/EventSponsorsFormComponent'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { EventSponsorsModel } from '../../../models/event-sponsors/event-sponsors.model'

interface ShowsDetailPageEventSponsorsFormSubSectionProps {
  onSetEventTab: (tab: string) => void
  onSetNextEventTab: (tab: string) => void
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  nextEventTab: string
  eventTab?: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsDetailPageEventSponsorsFormSubSection: React.FC<
  ShowsDetailPageEventSponsorsFormSubSectionProps
> = (props) => {
  const router = useIonRouter()
  const history = useHistory()
  const { eventId } = useParams<{ eventId: string }>()

  const [data, setData] = useState<any>(null)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const validFormHandler = async (data: any) => {
    let updated = false

    let newList: any[] = []

    data.sponsorOpportunities &&
      data.sponsorOpportunities.length &&
      data.sponsorOpportunities.forEach((currentItem: any) => {
        if (
          currentItem.available !== '' &&
          currentItem.cost !== '' &&
          currentItem.sponsorOpportunityName !== '' &&
          currentItem.summary !== ''
        ) {
          newList.push(currentItem)
        }
      })

    const updated_data = new EventSponsorsModel({
      ...data,
      sponsorOpportunities: newList,
    })

    await new Promise((resolve) => {
      FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_SPONSORS.NAME,
        eventId,
        updated_data.toFirestore()
      )
        .then((_: any) => {
          setData(updated_data.toObject())
        })
        .catch((reason) => {
          updated = false
          alert('202206261334')
          console.error('202206261334:' + reason)
        })
        .finally(() => {
          resolve(true)
        })
    })

    return {
      updated,
      onSetEventTab:
        props.nextEventTab == '' ? props.onSetEventTab : props.onSetEventTab(props?.nextEventTab),
      onSetNextEventTab: props.onSetNextEventTab(''),
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const validAndExitFormHandler = async (data: any) => {
    const updated_data = new EventSponsorsModel({
      ...data,
    })

    FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_SPONSORS.NAME,
      eventId,
      updated_data.toFirestore()
    )
      .then((_: any) => {
        setData(updated_data.toObject())
        router.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
        history.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
      })
      .catch((reason) => {
        alert('202206261334')
        console.error('202206261334:' + reason)
      })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidFormHandler = async (data: any) => {
    props.onSetNextEventTab('')
    alert('invalid')
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidAndExitFormHandler = async (data: any) => {
    props.onSetNextEventTab('')
    alert('invalid')
    router.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
    history.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        const doc = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_SPONSORS.NAME,
          eventId
        )
        if (doc) {
          setData(EventSponsorsModel.fromFirestoreDoc(doc).toObject())
        }
      }
      fetchData().catch()
    }
  }, [data, setData, eventId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <EventSponsorsFormComponent
        data={data}
        onValid={validFormHandler}
        onInvalid={invalidFormHandler}
        onValidAnExit={validAndExitFormHandler}
        onInvalidAndExit={invalidAndExitFormHandler}
        handleModal={props.handleModal}
        nextEventTab={props.nextEventTab}
        eventTab={props.eventTab}
      />
    </>
  )
}

export default ShowsDetailPageEventSponsorsFormSubSection
