import { AutorenewRounded } from '@mui/icons-material'
import { where } from 'firebase/firestore'
import { cloneDeep } from 'lodash'
import { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import * as yup from 'yup'
import helpers from '../../commonHelpers/helpers'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import ViewsLoader from '../../components/loader/ViewsLoader'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import useGetEventData from '../../hooks/users/common/useGetEventData'
import { HandleModalContext } from '../../layout/mainlayout/MainLayout'
import { EventRegisteredUsersModel } from '../../models/event-registered-users/event-registered-users.model'
import { getConvertedData } from '../../models/interface.helper'
import { IUserInterface } from '../../models/users/user.interface'
import { UserModel } from '../../models/users/user.model'
import FirestoreService from '../../services/firestoreService'
import {
  selectSplitPaymentDetails,
  selectedEvent,
  setLoading,
  setSplitPaymentRecipient,
  setSplitPaymentRegistration,
} from '../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types

interface IProps {
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

// Constants

const UNKNOWN = 'Unknown'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const schema = yup.object().shape({
  userId: yup.string().length(28, 'Invalid userId').required(),
  eventId: yup.string().length(20, 'Invalid eventId').required(),
  recipientId: yup.string().length(28, 'Invalid recipientId').required(),
})

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const SplitPaymentPage = (props: IProps) => {
  // Hooks and vars
  const params = useParams()
  const dispatch = useAppDispatch()
  const recipientSet = useRef(false)
  const { getAllData } = useGetEventData()
  const toastFunction = useToasterHelper()
  const handleModal = useContext(HandleModalContext)?.handleModal

  const selectedEvent_ = useAppSelector(selectedEvent)
  const splitPayment = useAppSelector(selectSplitPaymentDetails)
  const { registration, currRecipient: recipient } = splitPayment

  const [paymentLoading, setPaymentLoading] = useState(false)
  const [detailsLoading, setDetailsLoading] = useState(true)
  const [message, setMessage] = useState<string | null>(null)
  const [userDetails, setUserDetails] = useState<IUserInterface | null>(null)
  const [urlDetails, setUrlDetails] = useState<null | typeof schema.__outputType>(null)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    const validateParams = async () => {
      schema
        .validate(params)
        .then((values) => {
          setUrlDetails(values)
        })
        .catch((err) => {
          setMessage(err?.message ?? null)
        })
    }
    validateParams()
  }, [params])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    let stopLoading = true
    const PENDING = COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PENDING

    if (!urlDetails) return

    if (Object.values(urlDetails).find((c) => !c)) setMessage(MESSAGES_CONST.SOMETHING_WENT_WRONG)

    const getPaymentDetails = async () => {
      setDetailsLoading(true)

      const registrationSnapshot = await FirestoreService.filterItems(
        COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        [
          where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY, '==', urlDetails.userId),
          where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', urlDetails.eventId),
          where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.KEY, '==', PENDING),
        ]
      )

      if (!registrationSnapshot.docs.length) return setMessage('Payment details could not be found')

      const registration = EventRegisteredUsersModel.fromFirestoreDoc(
        registrationSnapshot.docs[0]
      ).toObject()
      const recipient =
        registration.selectionDetails.selectedRecipients.find(
          (recipient) => recipient.recipientId === urlDetails.recipientId
        ) ?? null

      if (!recipient) return setMessage(MESSAGES_CONST.SOMETHING_WENT_WRONG)

      dispatch(setSplitPaymentRecipient(recipient))
      getAllData(urlDetails.eventId, ['v01_events'])
      dispatch(setSplitPaymentRegistration(getConvertedData(registration)))

      stopLoading = false
      return registration
    }

    getPaymentDetails()
      .then((registration_) => {
        if (!registration_) return setMessage(MESSAGES_CONST.SOMETHING_WENT_WRONG)
      })
      .catch((err) => {
        helpers.logger({
          isError: true,
          message: err,
        })
      })
      .finally(() => {
        if (stopLoading) setDetailsLoading(false)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, urlDetails])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    let user: IUserInterface | null = null

    if (!urlDetails) return setDetailsLoading(false)

    const getRecipientAndUserDetails = async (userId: string, recipientId: string) => {
      const users: { [x: string]: IUserInterface } = {}

      const userSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, [
        userId,
        recipientId,
      ])

      userSnaps.forEach((currUserDoc) => {
        users[currUserDoc.id] = UserModel.fromFirestoreDoc(currUserDoc).toObject()
      })

      if (!users?.[userId] || !users?.[recipientId]) setMessage(MESSAGES_CONST.SOMETHING_WENT_WRONG)

      return users
    }

    if (recipientSet.current) return setDetailsLoading(false)

    getRecipientAndUserDetails(urlDetails.userId, urlDetails.recipientId)
      .then((usersObj) => {
        setLoading(true)

        user = usersObj?.[urlDetails.userId] ?? null
        user && setUserDetails(user)
        user = usersObj?.[urlDetails.recipientId] ?? null

        if (splitPayment.currRecipient && user) {
          dispatch(
            setSplitPaymentRecipient({
              ...splitPayment.currRecipient,
              recipientEmail: user.userEmail ?? '',
              recipientDefaultRole: user.userDefaultRole,
              recipientName:
                `${user?.userFirstName ?? UNKNOWN} ${user?.userLastName ?? UNKNOWN}` ?? UNKNOWN,
            })
          )
          recipientSet.current = true
        }
      })
      .catch((err) => {
        helpers.logger({
          isError: true,
          message: err,
        })
      })
      .finally(() => {
        setDetailsLoading(false)
      })
  }, [dispatch, splitPayment.currRecipient, urlDetails])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const payAmount = async () => {
    try {
      if (!recipient || !urlDetails || !registration)
        throw new Error(MESSAGES_CONST.SOMETHING_WENT_WRONG)

      let registration_ = cloneDeep(registration)
      let selectedRecipients = registration_?.selectionDetails.selectedRecipients ?? []

      const recipient_ =
        selectedRecipients.find((recipient) => {
          if (recipient.recipientId === urlDetails?.recipientId) {
            return true
          }
          return false
        }) ?? null

      if (
        recipient_?.recipientPaymentStatus === 'paid' ||
        recipient_?.recipientPaymentStatus === 'refunded'
      )
        return toastFunction.info({
          message: `You already have paid $${recipient}`,
        })

      return handleModal?.(true, MODAL_CONSTS.SPLIT_PAYMENT_MODAL)
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setPaymentLoading(false)
    }
  }

  if (message)
    return (
      <div
        className=" p-4 m-5 font-semibold text-base text-red-800 rounded-lg bg-red-200 "
        role="alert"
      >
        {message}
      </div>
    )

  return (
    <WrapperContainer
      title="Split Payment"
      removePadding={true}
      headerStyles={{
        fontWeight: '600',
      }}
    >
      <div>
        {detailsLoading ? (
          <ViewsLoader
            className="flex items-center w-full justify-center h-screen"
            size="xl"
            color="red"
          />
        ) : (
          <div className="p-5 text-SeabiscuitDark200ThemeColor text-nr">
            <div>
              <h1 className="mb-4 font-bold text-lg">Payment Details</h1>
            </div>
            <div>
              <div className="flex items-center mb-2">
                <div className="max-w-[300px] w-full font-semibold">Event name:</div>
                <div className="capitalize">{selectedEvent_?.Event?.eventName ?? UNKNOWN}</div>
              </div>

              <div className="flex items-center mb-2">
                <div className="max-w-[300px] w-full font-semibold">Registered by:</div>
                <div className="capitalize">
                  {`${userDetails?.userFirstName ?? UNKNOWN} ${
                    userDetails?.userLastName ?? UNKNOWN
                  }`}
                </div>
              </div>

              <div className="flex items-center mb-2">
                <div className="max-w-[300px] w-full font-semibold">Recipient Name:</div>
                <div className="capitalize">{recipient?.recipientName ?? UNKNOWN}</div>
              </div>

              <div className="flex items-center mb-2">
                <div className="max-w-[300px] w-full font-semibold">Recipient Email:</div>
                <div>{recipient?.recipientEmail ?? UNKNOWN}</div>
              </div>

              <div className="flex items-center">
                <div className="max-w-[300px] w-full font-semibold">Amount to pay:</div>
                <div className="capitalize">${recipient?.recipientAmountToPay ?? 0}</div>
              </div>
            </div>

            <button
              type="button"
              className="flex items-center justify-center py-2.5 px-5 border mt-4 border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:text-SeabiscuitMainThemeColor hover:bg-SeabiscuitLightThemeColor focus:outline-none !ring-0 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
              onClick={payAmount}
              disabled={
                paymentLoading ||
                !!!(recipient?.recipientAmountToPay ?? 0) ||
                recipient?.recipientPaymentStatus === 'paid' ||
                recipient?.recipientPaymentStatus === 'refunded'
              }
            >
              {paymentLoading ? (
                <AutorenewRounded fontSize="small" className="animate-spin mx-auto" />
              ) : recipient?.recipientPaymentStatus === 'paid' ? (
                'Paid'
              ) : (
                `Pay $${recipient?.recipientAmountToPay ?? 0}`
              )}
            </button>
          </div>
        )}
      </div>
    </WrapperContainer>
  )
}

export default SplitPaymentPage
