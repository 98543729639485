import { useEffect, useState } from 'react'

// Services
import FirebaseStorageService from '../services/storageService'

// Third party
import { cloneDeep } from 'lodash'

// Constants
import { MESSAGES_CONST } from '../const/messages-const'
import helpers, { fileObjToBase64 } from '../commonHelpers/helpers'

// Hooks
import useToasterHelper from '../helpers/ToasterHelper'

// Types

type IFile = {
  file: File
  uploaded: boolean
  downloadUrl: string
  progress: number
  error: string
  base64Src: ArrayBuffer | string | null
  fileType: string
}
type IQueue = {
  front: number
  rear: number
  files: Array<IFile>
  uploadPath: string
  loading: boolean
}
type IUseQueueProps = {
  allowedFileExtensions?: string[]
  keep?: boolean
}

/** @info Queue utility for file upload */
const useQueue = (props: IUseQueueProps) => {
  // Hooks and vars
  const toastMethods = useToasterHelper()
  const [queue, setQueue] = useState<IQueue>({
    front: -1,
    rear: 0,
    files: [],
    uploadPath: '',
    loading: false,
  })

  /** @info Runs the queue if items remain present */
  useEffect(() => {
    if (queue.front > -1 && queue.rear >= queue.front) {
      dequeue()
    } else {
      setQueue((prevState) => ({
        ...prevState,
        loading: false,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue.front, queue.rear])

  // Functions

  /**
   * @info Converts fileList to files Array
   * @FileList FileList to convert to files Array
   */
  const getFilesArray = (fileList: FileList): File[] => {
    const filesArr_: File[] = []

    try {
      const filesKeyList = Object.keys(fileList)
      const validExtensions = props.allowedFileExtensions
      let currFileKey: string = ''
      let exts = ''

      for (let i = 0; i < filesKeyList.length; i++) {
        currFileKey = filesKeyList[i]
        let currFileObj = fileList[currFileKey as unknown as number]

        if (validExtensions)
          if (!validExtensions.includes(currFileObj.type)) {
            exts = validExtensions.map((curr) => curr.split('/')[1]).join(', ')
            toastMethods.info({
              message: `Removed files except, files with ${exts} extensions`,
              autoClose: 10000,
            })
            continue
          }
        filesArr_.push(currFileObj)
      }
    } catch (error) {
      console.log(error)
    } finally {
      return filesArr_
    }
  }

  /**
   * @param files The files to upload
   * @param uploadPath The path of the firestore storage
   * @info Inserts file to upload
   */
  const enqueueMultiplefiles = async (files: FileList | null, uploadPath: string) => {
    if (files !== null) {
      setQueue((prevState) => ({
        ...prevState,
        loading: true,
      }))

      const filesArr_: File[] = getFilesArray(files)
      let filesArr: IFile[] = []

      const handleFiles = async (index: number) => {
        filesArr = [
          ...filesArr,
          {
            downloadUrl: '',
            uploaded: false,
            file: filesArr_[index],
            progress: 0,
            error: '',
            base64Src: await fileObjToBase64(filesArr_[index]),
            fileType: getFileType(filesArr_[index]),
          },
        ]
      }

      for (let index = 0; index < filesArr_.length; index++) {
        await handleFiles(index)
      }

      if (props.keep === false) {
        return setQueue({
          ...queue,
          files: [...filesArr],
          uploadPath,
          front: 0,
          rear: filesArr.length - 1,
          loading: true,
        })
      } else {
        setQueue((prevState) => {
          return {
            ...prevState,
            files: [...prevState.files, ...filesArr],
            uploadPath,
            front: prevState.front > -1 ? prevState.front : 0,
            rear: prevState.front > -1 ? prevState.rear + filesArr.length : filesArr.length - 1,
          }
        })
      }
    }
  }

  /** @info Handles the current file upload progress */
  const uploadProgressCallback = (progress: number) => {
    setQueue((prevState) => ({
      ...prevState,
      files: (function () {
        let updatedFilesArr = cloneDeep(prevState.files)
        updatedFilesArr.splice(prevState.front, 1, {
          ...prevState.files[prevState.front],
          progress,
        })
        return updatedFilesArr
      })(),
    }))
  }

  // Returns type of file
  const getFileType = (file: File): string => {
    let type = file.type.split('/')[1]
    return type
  }

  /** @info Upload the current item */
  const dequeue = async () => {
    let uploaded = false
    let downloadUrl_ = ''
    let file = queue.files[queue.front].file
    let error = ''

    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(
        file,
        `${queue.uploadPath}/${file.name}-${Date.now()}`,
        uploadProgressCallback
      )

      uploaded = true
      downloadUrl_ = downloadUrl as string
    } catch (error: any) {
      helpers.logger({
        isError: true,
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      uploaded = false
      downloadUrl_ = ''
    } finally {
      setQueue((prevState) => ({
        ...prevState,
        front: prevState.front + 1,
        files: (function () {
          let updatedFilesArr = cloneDeep(prevState.files)
          updatedFilesArr.splice(prevState.front, 1, {
            ...prevState.files[prevState.front],
            uploaded,
            downloadUrl: downloadUrl_,
            error,
          })
          return updatedFilesArr
        })(),
      }))
    }
  }

  /** @info Removes an element from the queue */
  const removeQueueElement = async (fileIndex: number) => {
    if (typeof fileIndex === 'number') {
      const files = cloneDeep([...queue.files])
      setQueue((prevState) => ({ ...prevState, loading: true }))
      await FirebaseStorageService.deleteFile(queue.files[fileIndex].downloadUrl)
      files.splice(fileIndex, 1)

      setQueue((prevState) => ({
        ...prevState,
        files: [...files],
        front: prevState.front - 1,
        rear: prevState.rear - 1,
        loading: false,
      }))
    }
  }

  return { ...queue, enqueueMultiplefiles, removeQueueElement }
}

export default useQueue
