// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useEffect, useState } from 'react'
// import CloseIcon from "../assets/close-icon.svg";
import clsx from 'clsx'
import { Clear } from '@mui/icons-material'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export interface PropTypes {
  show: boolean
  children: any
  title: string
  maxWidth?: number
  onHide: () => void
  tabComponent?: any
  parentClasses?: string
  fullMinHeight?: boolean
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const DrawerComponent: React.FC<PropTypes> = (props: PropTypes) => {
  const modalBreakpoint = 1024
  const [transformInfo, setTransformInfo] = useState('')

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const transformCalculator = () => {
    if (window.innerWidth > modalBreakpoint) {
      setTransformInfo(
        props.show ? 'translateX(-50%) translateY(-50%)' : 'translateX(-50%) translateY(-30%)'
      )
    } else {
      setTransformInfo(
        props.show ? 'translateX(0%) translateY(0%)' : 'translateX(0%) translateY(100%)'
      )
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    transformCalculator()
    if (props.show) {
      window.addEventListener('resize', transformCalculator, true)
    } else {
      window.removeEventListener('resize', transformCalculator, true)
    }
    return () => window.removeEventListener('resize', transformCalculator, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <div
        className={`fixed left-0 right-0 top-0 bottom-0 z-50`}
        style={{
          visibility: props.show ? 'visible' : 'hidden',
          transition: 'all .3s',
        }}
      >
        <div
          className={`${
            props.fullMinHeight ? 'min-h-[80vh] mini:min-h-fit' : ''
          } fixed bg-white p-4 max-h-[100vh] mini:max-h-[calc(100vh_-_60px)] rounded-t-3xl mini:rounded-xl z-50 left-0 mini:left-[calc(50%_-_1rem)] flex flex-col bottom-[0] mini:bottom-auto mini:top-[50%] w-full mini:w-[calc(100%_-_2rem)] max-w-[1024px] m-0 mini:m-2`}
          style={{
            opacity: props.show ? 1 : window.innerWidth > modalBreakpoint ? 0 : 1,
            transform: transformInfo,
            transition: 'all .3s',
            maxWidth: window.innerWidth > modalBreakpoint ? props.maxWidth : undefined,
          }}
        >
          <div className="text-center mini:hidden">
            <button
              className="h-[30px] border-none outline-none bg-transparent"
              onClick={props.onHide}
            >
              <div className="w-[30px] h-[8px] bg-gray-300 rounded-full"></div>
            </button>
          </div>
          <div className="flex items-center">
            <div className="flex-1 mini:font-bold text-[25px] flex items-center gap-3">
              <div className="text-SeabiscuitDark200ThemeColor">{props.title}</div>
            </div>
            <div className="text-right">
              <button onClick={props.onHide} className="hidden mini:block">
                <Clear
                  fontSize={'small'}
                  style={{
                    color: 'grey',
                    fontWeight: '400',
                  }}
                />
              </button>
            </div>
          </div>
          {props.tabComponent && <div>{props.tabComponent}</div>}
          <div
            className={clsx(
              'overflow-auto max-h-screen mt-4',
              !!props?.parentClasses && props.parentClasses
            )}
          >
            {props.children}
          </div>
        </div>
        <div
          className={`fixed left-0 right-0 top-0 bottom-0 bg-black/30 transition-all`}
          style={{
            opacity: props.show ? 1 : 0,
          }}
          onClick={props.onHide}
        ></div>
      </div>
    </>
  )
}

export default DrawerComponent
