type Props = {
  children?: any
  title?: string
  description?: string
  over_flow?: boolean
  height_full?: boolean
}

const SpectatorTickesPurchaseWrapper = (props: Props) => {
  const { over_flow, height_full } = props
  return (
    <>
      <div className="py-[20px] px-[30px]">
        <div className="headerSection">
          <div className="inline-flex items-center justify-end h-6">
            <h2 className="text-SeabiscuitMainThemeColor text-xl 2xl:text-2xl font-semibold mr-1">
              {props.title}
            </h2>
          </div>
          <p className="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base">
            {props.description}
          </p>
        </div>
        <hr className="mt-4 h-3 w-full"></hr>
        <div
          className={`${!height_full ? 'max-h-[calc(100vh-420px)]' : ''} min-h-[388px] ${over_flow ? 'overflow-y-auto' : ''} pr-3 pt-[17px]`}
        >
          {props.children}
        </div>
      </div>
    </>
  )
}

export default SpectatorTickesPurchaseWrapper
