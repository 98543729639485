const MAP_CIRCLE_OPTIONS = {
  zIndex: 1,
  radius: 0,
  visible: true,
  strokeWeight: 1,
  editable: false,
  clickable: false,
  strokeOpacity: 1,
  draggable: false,
  fillOpacity: 0.278,
  strokeColor: 'white',
  fillColor: '#FF0000',
}

const AUTO_COMPLETE_INPUT_OPTIONS = {
  types: [],
  fields: ['address_components', 'geometry.location', 'place_id', 'formatted_address'],
}

const ONE_MILE = 1609.34

const TEMP_LOCATION = {
  lat: 39.724266,
  lng: -86.08862169999991,
}

export const HOME_ROOT_GET_LOCATION_FILTER_DATA = {
  ONE_MILE,
  TEMP_LOCATION,
  MAP_CIRCLE_OPTIONS,
  AUTO_COMPLETE_INPUT_OPTIONS,
}
