// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { Dialog, Transition } from '@headlessui/react'
import { FC, Fragment, useState } from 'react'

import React from 'react'
import { EventModel } from '../../../../models/events/event.model'
import EventCreateShowInitialDialogSecondary from './EventCreateShowInitialDialogSecondary'
import EventCreateShowInitialDialogPrimary from './EventCreateShowInitialDialogPrimary'
import { CONST } from '../../../../const/const'
import { Clear } from '@mui/icons-material'
import { IEventInterface } from '../../../../models/events/event.interface'

const COLLECTIONS_EVENTS_LATEST = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventCreateShowInitialDialogComponentProps {
  isOpen: boolean
  handleSubmitEventModal: (arg: IEventInterface) => void
  closeCreateEventModal: () => void
  createEventLoading: boolean
  setCreateEventLoading: (value: boolean) => void
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventCreateShowInitialDialogComponent: FC<EventCreateShowInitialDialogComponentProps> = ({
  isOpen,
  handleSubmitEventModal,
  closeCreateEventModal,
  setCreateEventLoading,
  createEventLoading,
}) => {
  const [creationStage, setCreationStage] = useState<'primary' | 'secondary'>('primary')
  const [eventInformation, setEventInformation] = useState({
    ...new EventModel().toFirestore(),
  })
  const [maincategory, setMainCategory] = useState<string>('clinic')
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  const onEventMainCategorySelected = (main_category_selection: string, eventName: string) => {
    setMainCategory(main_category_selection)

    const event = EventModel.fromObject({ ...new EventModel().toFirestore() })
    event.status = 'draft'
    event.eventName = eventName

    if (main_category_selection === 'licensed') {
      event.category = 'licensed'
      event.tags.push(COLLECTIONS_EVENTS_LATEST.FIELDS.TAGS.VALUES.EVENTING)
      event.tags.push(COLLECTIONS_EVENTS_LATEST.FIELDS.TAGS.VALUES.DRESSAGE)
      event.tags.push(COLLECTIONS_EVENTS_LATEST.FIELDS.TAGS.VALUES.HUNTER_JUMPER)
      event.tags.push(COLLECTIONS_EVENTS_LATEST.FIELDS.TAGS.VALUES.HUNTER)
      event.tags.push(COLLECTIONS_EVENTS_LATEST.FIELDS.TAGS.VALUES.JUMPER)
      event.tags.push(COLLECTIONS_EVENTS_LATEST.FIELDS.TAGS.VALUES.EQUITATION)
      event.isLicensed = true
      event.type = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.FIELDS.TYPE.VALUE.COMPETITION
      event.subType =
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_POSTS.FIELDS.SUBTYPE.VALUE.SHOW_JUMPING
    } else if (main_category_selection === 'unlicensed') {
      event.category = 'unlicensed'
      event.isLicensed = false
      event.type = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.FIELDS.TYPE.VALUE.REGULAR
    } else if (main_category_selection === 'clinic') {
      event.category = 'clinic'
      event.isLicensed = false
      event.type = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.FIELDS.TYPE.VALUE.REGULAR
    } else if (main_category_selection === 'other') {
      event.category = 'other'
      event.isLicensed = false
      event.type = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.FIELDS.TYPE.VALUE.REGULAR
    }

    setCreationStage('secondary')
    setEventInformation({ ...event })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onEventTagsChangedHandle = (args: { tag?: string; added?: boolean; reset?: boolean }) => {
    const { tag, added, reset } = args

    const newEvent = { ...eventInformation }

    if (reset) newEvent.tags = []
    else {
      if (!tag) return
      if (added) newEvent.tags?.push(tag)
      else {
        if (!added && tag !== 'All') {
          newEvent.tags = newEvent.tags.filter((current: any) => {
            return current !== 'All'
          })
        }

        if (newEvent.tags?.length && newEvent.tags?.length > 0) {
          const index = newEvent.tags?.indexOf(tag) || -1
          if (index > -1) {
            newEvent.tags?.splice(index, 1)
          } else {
            newEvent.tags?.splice(0, 1)
          }
        }
      }
    }
    setEventInformation({ ...newEvent })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onEventTagsSelectedHandle = async () => {
    await handleSubmitEventModal(eventInformation)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onCancelPressed = () => {
    setCreationStage('primary')
    setCreateEventLoading(false)
    closeCreateEventModal()
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onCancelPressed}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full xl:w-[60%] xl:max-w-[800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <span
                    onClick={onCancelPressed}
                    className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
                  >
                    {/* <img src="/assets/og_icons/Close.svg" className="w-6" alt="close icon" /> */}
                    <Clear
                      fontSize={'small'}
                      style={{
                        color: 'grey',
                        fontWeight: '400',
                      }}
                    />
                  </span>

                  <Dialog.Title
                    as="h3"
                    className={`text-[28px] 2xl:text-[34px] top-10 pt-[20px] font-bold leading-6 text-SeabiscuitDark200ThemeColor text-center capitalize ${
                      creationStage === 'primary' && 'text-center'
                    }`}
                  >
                    {creationStage === 'primary' ? 'Create new event' : `Create ${maincategory}`}
                  </Dialog.Title>
                  <div className="pt-8 mx-auto w-full ">
                    {creationStage === 'primary' ? (
                      <EventCreateShowInitialDialogPrimary
                        onSelection={onEventMainCategorySelected}
                      />
                    ) : (
                      <EventCreateShowInitialDialogSecondary
                        eventInformation={eventInformation}
                        onSelectionChanged={onEventTagsChangedHandle}
                        onSelectionConfirmed={onEventTagsSelectedHandle}
                        loading={createEventLoading}
                      />
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

EventCreateShowInitialDialogComponent.defaultProps = {
  isOpen: false,
}

export default EventCreateShowInitialDialogComponent
