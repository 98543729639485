import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { EventSchedulingModel } from '../../../models/event-scheduling/event-scheduling.model'
import EventSchedulingFormComponent from '../../../components/events/forms/scheduling/EventSchedulingFormComponent'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { useAppSelector } from '../../../store/hooks'
import { selectedEvent } from '../../../store/events/eventsSlice'
import { IEventScheduleInterface } from '../../../models/event-scheduling/event-scheduling.interface'

interface ShowsDetailPageEventSchedulingFormSubSectionProps {
  onSetEventTab: (tab: string) => void
  onSetNextEventTab: (tab: string) => void
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  nextEventTab: string
  eventTab?: string
}

const ShowsDetailPageEventSchedulingFormSubSection: React.FC<
  ShowsDetailPageEventSchedulingFormSubSectionProps
> = (props) => {
  const selectedEventR = useAppSelector(selectedEvent)

  const eventId = selectedEventR.Event?.id ?? ''

  const toasterFunctions = useToasterHelper()

  const validFormHandler = async (data_: IEventScheduleInterface) => {
    let updated = true

    try {
      const updated_data = new EventSchedulingModel({
        ...data_,
      })

      if (eventId) {
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_SCHEDULING.NAME,
          eventId,
          updated_data.toFirestore()
        )
      }
    } catch (reason) {
      updated = false
      console.error('validFormHandler:' + reason)
    } finally {
      return {
        onSetNextEventTab: props.onSetNextEventTab(''),
        updated,
        onSetEventTab:
          props.nextEventTab === ''
            ? props.onSetEventTab
            : props.onSetEventTab(props?.nextEventTab),
      }
    }
  }

  const validAndExitFormHandler = async (data_: any) => {
    return await validFormHandler(data_)
  }

  const invalidFormHandler = async () => {
    props.onSetNextEventTab('')
    toasterFunctions.error({ message: 'Please check required fields below.' })
  }

  const invalidAndExitFormHandler = async () => {
    props.onSetNextEventTab('')
    toasterFunctions.error({ message: 'Please check required fields below.' })
  }

  return (
    <>
      <EventSchedulingFormComponent
        handleModal={props.handleModal}
        onValid={validFormHandler}
        onInvalid={invalidFormHandler}
        onValidAnExit={validAndExitFormHandler}
        onInvalidAndExit={invalidAndExitFormHandler}
        nextEventTab={props.nextEventTab}
        eventTab={props.eventTab}
      />
    </>
  )
}

export default ShowsDetailPageEventSchedulingFormSubSection
