import ITypes from './InfoWithStatus.types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const InfoWithStatus = (props: ITypes['IInfoWithStatusProps']) => {
  // Hooks and vars
  const { label, value, className } = props

  return (
    <>
      <div className={className ?? ''}>
        {/* <img src={icon} alt="icon" className="w-6 h-6 mr-3" /> */}
        <span className="mr-3 text-SeabiscuitDark200ThemeColor">{`${label} :`}</span>
        {typeof value === 'boolean' ? (
          value ? (
            <img src={`/assets/cp_icons/Ok-3.svg`} alt="icon" className="w-6 h-6 mr-3" />
          ) : (
            <img src={`/assets/cp_icons/Cancel-2.svg`} alt="icon" className="w-6 h-6 mr-3" />
          )
        ) : (
          <span className="mr-3 text-SeabiscuitDark200ThemeColor ml-2">{value}</span>
        )}
      </div>
    </>
  )
}

export default InfoWithStatus
