// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { IEventBookmarkInterface } from './event-bookmark.interface'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export class EventBookmarkModel
  extends ModelBaseModel<IEventBookmarkInterface>
  implements IModelBaseModel
{
  public v: number
  public id: string | null
  public userId: string | null
  public eventId: string | null
  public updatedAt: Date | null | string
  public createdAt: Date | null | string
  public bookmarkedBy: string | null
  public mailSent: number

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: IEventBookmarkInterface) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.userId = obj?.userId ?? null
    this.eventId = obj?.eventId ?? null
    this.mailSent = obj?.mailSent ?? 0
    this.bookmarkedBy = obj?.bookmarkedBy ?? null

    this.updatedAt = this.utcTimestamp({
      key: 'updatedAt',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.updatedAt ?? null,
    })

    this.createdAt = this.utcTimestamp({
      key: 'createdAt',
      isTimestamp: true,
      value: obj?.createdAt ?? null,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new EventBookmarkModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
