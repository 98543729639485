// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

import { Link } from 'react-router-dom'
import IProfileCard from '../../types'
import { CONST } from '../../../../../../const/const'

/**
 * @TODO Document this
 */
const HorseLineage = (props: IProfileCard['IHorseLineageTabProps']) => {
  return (
    <>
      <div className="heading uppercase mb-3 font-md text-SeabiscuitDark200ThemeColor">
        Horse Lineage
      </div>

      {props.horseLineage ? (
        props.horseLineage.horseLineageMembers.length ? (
          props.horseLineage.horseLineageMembers.map((currentHorse, index) => {
            return (
              <div key={`team_member_tab_team_member${index}`}>
                <div
                  className="user flex flex-row flex-wrap items-center rounded-lg py-1"
                  key={`teamMember${index}`}
                >
                  <Link
                    to={`${CONST.ROUTES.HORSE_PUBLIC_PROFILE.URL}/${currentHorse.id}?tab=details`}
                  >
                    <img
                      src={
                        !!currentHorse.horseProfile
                          ? currentHorse?.horseProfile
                          : '/assets/og_icons/User.svg'
                      }
                      className="mr-3 rounded-full bg-SeabiscuitLightGrayThemeColor horseImg w-[45px] h-[45px] object-cover"
                      alt="horse team member"
                      onError={(e) => {
                        e.currentTarget.src = '/assets/og_icons/User.svg'
                      }}
                    />
                  </Link>
                  <div className="flex flex-col items-start">
                    <div className='"team_member_name text-SeabiscuitDark200ThemeColor text-[14px] font-medium capitalize'>
                      {currentHorse.horseName}
                    </div>
                    <div className="team_member_details text-[12px] text-SeabiscuitDark200ThemeColor opacity-[.5] capitalize">
                      {currentHorse?.lineageName ?? 'Unknown'}
                    </div>
                  </div>
                </div>

                {(props.horseLineage?.horseLineageMembers?.length ?? 0) - 1 > index && (
                  <hr className=" md:my-2 my-1" />
                )}
              </div>
            )
          })
        ) : (
          'No horses are added in the horse lineage'
        )
      ) : (
        <div className="text-SeabiscuitDark200ThemeColor opacity-50">
          No horse lineage data recorded
        </div>
      )}
    </>
  )
}

export default HorseLineage
