import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUserFullName } from '../../../../helpers/helpers'
import { ITeamMember } from '../../../../models/users/user.interface'
import TeamMembersTab from '../../../../pages/allEvents/root/Tabs/ProfileTeamMembersTab'
import { useAppSelector } from '../../../../store/hooks'
import { RootState } from '../../../../store/store'
import { selectUserId } from '../../../../store/user/userSlice'

type Props = {
  isMyProfile?: boolean
}

const OrganizerSideProfileUserStaffTab = (props: Props) => {
  const userId = useAppSelector(selectUserId)
  const profileDetails = useSelector((state: RootState) =>
    props.isMyProfile ? state.user.profileDetails : state.profilePage.visitedUserProfileDetails
  )

  const [teamMatesListWithMe, setTeamMatesListWithMe] = useState<any[]>([])
  const teamMembersList = useMemo(
    () => profileDetails?.userTeamMembers ?? [],
    [profileDetails]
  ) as ITeamMember[]

  useEffect(() => {
    var owner = (() => {
      const username = profileDetails ? getUserFullName(profileDetails) : 'unknown'
      let ownerData = {
        memberName: username ?? 'unknown',
        memberId: userId ?? '',
        defaultRole: 'Owner',
        memberProfilePicture: profileDetails?.userProfilePicture ?? '',
        memberStatus: '1',
        memberprofileSynced: false,
        memberAddress: profileDetails?.userAddress ?? '',
        memberEmail: profileDetails?.userEmail ?? '',
        memberShipActive: false,
        memberSafeSupportTraining: false,
        memberCountry: profileDetails?.userNationality ?? '',
      }
      return ownerData
    })()

    if (owner) setTeamMatesListWithMe([owner, ...teamMembersList])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMembersList, profileDetails])

  return (
    <div className="w-full pr-2 overflow-y-auto h-[500px]">
      <TeamMembersTab tabHeading="Staff2" team_members={teamMatesListWithMe} />
    </div>
  )
}

export default OrganizerSideProfileUserStaffTab
