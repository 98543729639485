export const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#D3DAEE',
      },
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
      color: '#1F4170',
      fontWeight: 'bold',
      fontSize: '12px',
      textAlign: 'left',
      display: 'flex',
    },
  },
  headRow: {
    style: {
      minHeight: 'unset',
      borderColor: '#D3DAEE',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      Text: 'center',
    },
  },
}
