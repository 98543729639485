export type ICountry = {
  value: string
  label: string
  threeLetterCountryCode: string
  suffixes: string[]
  phoneCode: string
  flag: string
  isCountry: boolean
  phoneNumberLength: number
}

export const COUNTRIES_LIST: ICountry[] = [
  {
    value: 'US',
    label: 'United States',
    threeLetterCountryCode: '840',
    suffixes: [
      '201',
      '202',
      '203',
      '205',
      '206',
      '207',
      '208',
      '209',
      '210',
      '212',
      '213',
      '214',
      '215',
      '216',
      '217',
      '218',
      '219',
      '220',
      '224',
      '225',
      '227',
      '228',
      '229',
      '231',
      '234',
      '239',
      '240',
      '248',
      '251',
      '252',
      '253',
      '254',
      '256',
      '260',
      '262',
      '267',
      '269',
      '270',
      '272',
      '274',
      '276',
      '281',
      '283',
      '301',
      '302',
      '303',
      '304',
      '305',
      '307',
      '308',
      '309',
      '310',
      '312',
      '313',
      '314',
      '315',
      '316',
      '317',
      '318',
      '319',
      '320',
      '321',
      '323',
      '325',
      '327',
      '330',
      '331',
      '334',
      '336',
      '337',
      '339',
      '346',
      '347',
      '351',
      '352',
      '360',
      '361',
      '364',
      '380',
      '385',
      '386',
      '401',
      '402',
      '404',
      '405',
      '406',
      '407',
      '408',
      '409',
      '410',
      '412',
      '413',
      '414',
      '415',
      '417',
      '419',
      '423',
      '424',
      '425',
      '430',
      '432',
      '434',
      '435',
      '440',
      '442',
      '443',
      '447',
      '458',
      '463',
      '464',
      '469',
      '470',
      '475',
      '478',
      '479',
      '480',
      '484',
      '501',
      '502',
      '503',
      '504',
      '505',
      '507',
      '508',
      '509',
      '510',
      '512',
      '513',
      '515',
      '516',
      '517',
      '518',
      '520',
      '530',
      '531',
      '534',
      '539',
      '540',
      '541',
      '551',
      '559',
      '561',
      '562',
      '563',
      '564',
      '567',
      '570',
      '571',
      '573',
      '574',
      '575',
      '580',
      '585',
      '586',
      '601',
      '602',
      '603',
      '605',
      '606',
      '607',
      '608',
      '609',
      '610',
      '612',
      '614',
      '615',
      '616',
      '617',
      '618',
      '619',
      '620',
      '623',
      '626',
      '628',
      '629',
      '630',
      '631',
      '636',
      '641',
      '646',
      '650',
      '651',
      '657',
      '660',
      '661',
      '662',
      '667',
      '669',
      '678',
      '681',
      '682',
      '701',
      '702',
      '703',
      '704',
      '706',
      '707',
      '708',
      '712',
      '713',
      '714',
      '715',
      '716',
      '717',
      '718',
      '719',
      '720',
      '724',
      '725',
      '727',
      '730',
      '731',
      '732',
      '734',
      '737',
      '740',
      '743',
      '747',
      '754',
      '757',
      '760',
      '762',
      '763',
      '765',
      '769',
      '770',
      '772',
      '773',
      '774',
      '775',
      '779',
      '781',
      '785',
      '786',
      '801',
      '802',
      '803',
      '804',
      '805',
      '806',
      '808',
      '810',
      '812',
      '813',
      '814',
      '815',
      '816',
      '817',
      '818',
      '828',
      '830',
      '831',
      '832',
      '843',
      '845',
      '847',
      '848',
      '850',
      '854',
      '856',
      '857',
      '858',
      '859',
      '860',
      '862',
      '863',
      '864',
      '865',
      '870',
      '872',
      '878',
      '901',
      '903',
      '904',
      '906',
      '907',
      '908',
      '909',
      '910',
      '912',
      '913',
      '914',
      '915',
      '916',
      '917',
      '918',
      '919',
      '920',
      '925',
      '928',
      '929',
      '930',
      '931',
      '934',
      '936',
      '937',
      '938',
      '940',
      '941',
      '947',
      '949',
      '951',
      '952',
      '954',
      '956',
      '959',
      '970',
      '971',
      '972',
      '973',
      '975',
      '978',
      '979',
      '980',
      '984',
      '985',
      '989',
    ],
    phoneCode: '+1',
    flag: '/assets/countryFlags/us.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'JO',
    label: 'Jordan',
    threeLetterCountryCode: '400',
    suffixes: ['62'],
    phoneCode: '+962',
    flag: '/assets/countryFlags/jo.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'MP',
    label: 'Northern Mariana Islands',
    threeLetterCountryCode: '580',
    suffixes: ['670'],
    phoneCode: '+1670',
    flag: '/assets/countryFlags/mp.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'RS',
    label: 'Serbia',
    threeLetterCountryCode: '688',
    suffixes: ['81'],
    phoneCode: '+381',
    flag: '/assets/countryFlags/rs.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'AD',
    label: 'Andorra',
    threeLetterCountryCode: '020',
    suffixes: ['76'],
    phoneCode: '+376',
    flag: '/assets/countryFlags/ad.svg',
    isCountry: true,
    phoneNumberLength: 6,
  },
  {
    value: 'TC',
    label: 'Turks and Caicos Islands',
    threeLetterCountryCode: '796',
    suffixes: ['649'],
    phoneCode: '+1649',
    flag: '/assets/countryFlags/tc.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'BM',
    label: 'Bermuda',
    threeLetterCountryCode: '060',
    suffixes: ['441'],
    phoneCode: '+1441',
    flag: '/assets/countryFlags/bm.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'BO',
    label: 'Bolivia',
    threeLetterCountryCode: '068',
    suffixes: ['91'],
    phoneCode: '+591',
    flag: '/assets/countryFlags/bo.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'LY',
    label: 'Libya',
    threeLetterCountryCode: '434',
    suffixes: ['18'],
    phoneCode: '+218',
    flag: '/assets/countryFlags/ly.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'ML',
    label: 'Mali',
    threeLetterCountryCode: '466',
    suffixes: ['23'],
    phoneCode: '+223',
    flag: '/assets/countryFlags/ml.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'AM',
    label: 'Armenia',
    threeLetterCountryCode: '051',
    suffixes: ['74'],
    phoneCode: '+374',
    flag: '/assets/countryFlags/am.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'MU',
    label: 'Mauritius',
    threeLetterCountryCode: '480',
    suffixes: ['30'],
    phoneCode: '+230',
    flag: '/assets/countryFlags/mu.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'MV',
    label: 'Maldives',
    threeLetterCountryCode: '462',
    suffixes: ['60'],
    phoneCode: '+960',
    flag: '/assets/countryFlags/mv.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'AS',
    label: 'American Samoa',
    threeLetterCountryCode: '016',
    suffixes: ['684'],
    phoneCode: '+1684',
    flag: '/assets/countryFlags/as.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'MK',
    label: 'North Macedonia',
    threeLetterCountryCode: '807',
    suffixes: ['89'],
    phoneCode: '+389',
    flag: '/assets/countryFlags/mk.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'ET',
    label: 'Ethiopia',
    threeLetterCountryCode: '231',
    suffixes: ['51'],
    phoneCode: '+251',
    flag: '/assets/countryFlags/et.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'GL',
    label: 'Greenland',
    threeLetterCountryCode: '304',
    suffixes: ['99'],
    phoneCode: '+299',
    flag: '/assets/countryFlags/gl.svg',
    isCountry: true,
    phoneNumberLength: 6,
  },
  {
    value: 'IQ',
    label: 'Iraq',
    threeLetterCountryCode: '368',
    suffixes: ['64'],
    phoneCode: '+964',
    flag: '/assets/countryFlags/iq.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'GT',
    label: 'Guatemala',
    threeLetterCountryCode: '320',
    suffixes: ['02'],
    phoneCode: '+502',
    flag: '/assets/countryFlags/gt.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'TT',
    label: 'Trinidad and Tobago',
    threeLetterCountryCode: '780',
    suffixes: ['868'],
    phoneCode: '+1868',
    flag: '/assets/countryFlags/tt.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'PE',
    label: 'Peru',
    threeLetterCountryCode: '604',
    suffixes: ['1'],
    phoneCode: '+51',
    flag: '/assets/countryFlags/pe.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'SR',
    label: 'Suriname',
    threeLetterCountryCode: '740',
    suffixes: ['97'],
    phoneCode: '+597',
    flag: '/assets/countryFlags/sr.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'SE',
    label: 'Sweden',
    threeLetterCountryCode: '752',
    suffixes: ['6'],
    phoneCode: '+46',
    flag: '/assets/countryFlags/se.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'NC',
    label: 'New Caledonia',
    threeLetterCountryCode: '540',
    suffixes: ['87'],
    phoneCode: '+687',
    flag: '/assets/countryFlags/nc.svg',
    isCountry: true,
    phoneNumberLength: 6,
  },
  {
    value: 'PF',
    label: 'French Polynesia',
    threeLetterCountryCode: '258',
    suffixes: ['89'],
    phoneCode: '+689',
    flag: '/assets/countryFlags/pf.svg',
    isCountry: true,
    phoneNumberLength: 6,
  },
  {
    value: 'BJ',
    label: 'Benin',
    threeLetterCountryCode: '204',
    suffixes: ['29'],
    phoneCode: '+229',
    flag: '/assets/countryFlags/bj.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'EE',
    label: 'Estonia',
    threeLetterCountryCode: '233',
    suffixes: ['72'],
    phoneCode: '+372',
    flag: '/assets/countryFlags/ee.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'FK',
    label: 'Falkland Islands',
    threeLetterCountryCode: '238',
    suffixes: ['00'],
    phoneCode: '+500',
    flag: '/assets/countryFlags/fk.svg',
    isCountry: true,
    phoneNumberLength: 6,
  },
  {
    value: 'GU',
    label: 'Guam',
    threeLetterCountryCode: '316',
    suffixes: ['671'],
    phoneCode: '+1671',
    flag: '/assets/countryFlags/gu.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'ZW',
    label: 'Zimbabwe',
    threeLetterCountryCode: '716',
    suffixes: ['63'],
    phoneCode: '+263',
    flag: '/assets/countryFlags/zw.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'SK',
    label: 'Slovakia',
    threeLetterCountryCode: '703',
    suffixes: ['21'],
    phoneCode: '+421',
    flag: '/assets/countryFlags/sk.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'NL',
    label: 'Netherlands',
    threeLetterCountryCode: '528',
    suffixes: ['1'],
    phoneCode: '+31',
    flag: '/assets/countryFlags/nl.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'EC',
    label: 'Ecuador',
    threeLetterCountryCode: '218',
    suffixes: ['93'],
    phoneCode: '+593',
    flag: '/assets/countryFlags/ec.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'SA',
    label: 'Saudi Arabia',
    threeLetterCountryCode: '682',
    suffixes: ['66'],
    phoneCode: '+966',
    flag: '/assets/countryFlags/sa.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'AE',
    label: 'United Arab Emirates',
    threeLetterCountryCode: '784',
    suffixes: ['71'],
    phoneCode: '+971',
    flag: '/assets/countryFlags/ae.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'AF',
    label: 'Afghanistan',
    threeLetterCountryCode: '004',
    suffixes: ['3'],
    phoneCode: '+93',
    flag: '/assets/countryFlags/af.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'CF',
    label: 'Central African Republic',
    threeLetterCountryCode: '140',
    suffixes: ['36'],
    phoneCode: '+236',
    flag: '/assets/countryFlags/cf.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'PA',
    label: 'Panama',
    threeLetterCountryCode: '591',
    suffixes: ['07'],
    phoneCode: '+507',
    flag: '/assets/countryFlags/pa.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'VA',
    label: 'Vatican City',
    threeLetterCountryCode: '336',
    suffixes: ['906698', '79'],
    phoneCode: '+3',
    flag: '/assets/countryFlags/va.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'SY',
    label: 'Syria',
    threeLetterCountryCode: '760',
    suffixes: ['63'],
    phoneCode: '+963',
    flag: '/assets/countryFlags/sy.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'VU',
    label: 'Vanuatu',
    threeLetterCountryCode: '548',
    suffixes: ['78'],
    phoneCode: '+678',
    flag: '/assets/countryFlags/vu.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'HN',
    label: 'Honduras',
    threeLetterCountryCode: '340',
    suffixes: ['04'],
    phoneCode: '+504',
    flag: '/assets/countryFlags/hn.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'KI',
    label: 'Kiribati',
    threeLetterCountryCode: '296',
    suffixes: ['86'],
    phoneCode: '+686',
    flag: '/assets/countryFlags/ki.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'CL',
    label: 'Chile',
    threeLetterCountryCode: '152',
    suffixes: ['6'],
    phoneCode: '+56',
    flag: '/assets/countryFlags/cl.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'BF',
    label: 'Burkina Faso',
    threeLetterCountryCode: '854',
    suffixes: ['26'],
    phoneCode: '+226',
    flag: '/assets/countryFlags/bf.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'KN',
    label: 'Saint Kitts and Nevis',
    threeLetterCountryCode: '659',
    suffixes: ['869'],
    phoneCode: '+1869',
    flag: '/assets/countryFlags/kn.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'BL',
    label: 'Saint Barthélemy',
    threeLetterCountryCode: '652',
    suffixes: ['90'],
    phoneCode: '+590',
    flag: '/assets/countryFlags/bl.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'MX',
    label: 'Mexico',
    threeLetterCountryCode: '484',
    suffixes: ['2'],
    phoneCode: '+52',
    flag: '/assets/countryFlags/mx.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'CN',
    label: 'China',
    threeLetterCountryCode: '156',
    suffixes: ['6'],
    phoneCode: '+86',
    flag: '/assets/countryFlags/cn.svg',
    isCountry: true,
    phoneNumberLength: 13,
  },
  {
    value: 'TL',
    label: 'Timor-Leste',
    threeLetterCountryCode: '626',
    suffixes: ['70'],
    phoneCode: '+670',
    flag: '/assets/countryFlags/tl.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'SS',
    label: 'South Sudan',
    threeLetterCountryCode: '728',
    suffixes: ['11'],
    phoneCode: '+211',
    flag: '/assets/countryFlags/ss.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'SZ',
    label: 'Eswatini',
    threeLetterCountryCode: '748',
    suffixes: ['68'],
    phoneCode: '+268',
    flag: '/assets/countryFlags/sz.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'UZ',
    label: 'Uzbekistan',
    threeLetterCountryCode: '860',
    suffixes: ['98'],
    phoneCode: '+998',
    flag: '/assets/countryFlags/uz.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'ID',
    label: 'Indonesia',
    threeLetterCountryCode: '360',
    suffixes: ['2'],
    phoneCode: '+62',
    flag: '/assets/countryFlags/id.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'PY',
    label: 'Paraguay',
    threeLetterCountryCode: '600',
    suffixes: ['95'],
    phoneCode: '+595',
    flag: '/assets/countryFlags/py.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'MC',
    label: 'Monaco',
    threeLetterCountryCode: '492',
    suffixes: ['77', '36'],
    phoneCode: '+3',
    flag: '/assets/countryFlags/mc.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'GD',
    label: 'Grenada',
    threeLetterCountryCode: '308',
    suffixes: ['473'],
    phoneCode: '+1473',
    flag: '/assets/countryFlags/gd.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'HR',
    label: 'Croatia',
    threeLetterCountryCode: '191',
    suffixes: ['85'],
    phoneCode: '+385',
    flag: '/assets/countryFlags/hr.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'PL',
    label: 'Poland',
    threeLetterCountryCode: '616',
    suffixes: ['8'],
    phoneCode: '+48',
    flag: '/assets/countryFlags/pl.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'BA',
    label: 'Bosnia and Herzegovina',
    threeLetterCountryCode: '070',
    suffixes: ['87'],
    phoneCode: '+387',
    flag: '/assets/countryFlags/ba.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'CA',
    label: 'Canada',
    threeLetterCountryCode: '124',
    suffixes: [''],
    phoneCode: '+1',
    flag: '/assets/countryFlags/ca.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'PT',
    label: 'Portugal',
    threeLetterCountryCode: '620',
    suffixes: ['51'],
    phoneCode: '+351',
    flag: '/assets/countryFlags/pt.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'BR',
    label: 'Brazil',
    threeLetterCountryCode: '076',
    suffixes: ['5'],
    phoneCode: '+55',
    flag: '/assets/countryFlags/br.svg',
    isCountry: true,
    phoneNumberLength: 11,
  },
  {
    value: 'MR',
    label: 'Mauritania',
    threeLetterCountryCode: '478',
    suffixes: ['22'],
    phoneCode: '+222',
    flag: '/assets/countryFlags/mr.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'IL',
    label: 'Israel',
    threeLetterCountryCode: '376',
    suffixes: ['72'],
    phoneCode: '+972',
    flag: '/assets/countryFlags/il.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'CW',
    label: 'Curaçao',
    threeLetterCountryCode: '531',
    suffixes: ['99'],
    phoneCode: '+599',
    flag: '/assets/countryFlags/cw.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'BN',
    label: 'Brunei',
    threeLetterCountryCode: '096',
    suffixes: ['73'],
    phoneCode: '+673',
    flag: '/assets/countryFlags/bn.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'AO',
    label: 'Angola',
    threeLetterCountryCode: '024',
    suffixes: ['44'],
    phoneCode: '+244',
    flag: '/assets/countryFlags/ao.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'MT',
    label: 'Malta',
    threeLetterCountryCode: '470',
    suffixes: ['56'],
    phoneCode: '+356',
    flag: '/assets/countryFlags/mt.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'BY',
    label: 'Belarus',
    threeLetterCountryCode: '112',
    suffixes: ['75'],
    phoneCode: '+375',
    flag: '/assets/countryFlags/by.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'TR',
    label: 'Turkey',
    threeLetterCountryCode: '792',
    suffixes: ['0'],
    phoneCode: '+90',
    flag: '/assets/countryFlags/tr.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'FI',
    label: 'Finland',
    threeLetterCountryCode: '246',
    suffixes: ['58'],
    phoneCode: '+358',
    flag: '/assets/countryFlags/fi.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'GI',
    label: 'Gibraltar',
    threeLetterCountryCode: '292',
    suffixes: ['50'],
    phoneCode: '+350',
    flag: '/assets/countryFlags/gi.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'BT',
    label: 'Bhutan',
    threeLetterCountryCode: '064',
    suffixes: ['75'],
    phoneCode: '+975',
    flag: '/assets/countryFlags/bt.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'ES',
    label: 'Spain',
    threeLetterCountryCode: '724',
    suffixes: ['4'],
    phoneCode: '+34',
    flag: '/assets/countryFlags/es.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'VE',
    label: 'Venezuela',
    threeLetterCountryCode: '862',
    suffixes: ['8'],
    phoneCode: '+58',
    flag: '/assets/countryFlags/ve.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'QA',
    label: 'Qatar',
    threeLetterCountryCode: '634',
    suffixes: ['74'],
    phoneCode: '+974',
    flag: '/assets/countryFlags/qa.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'CZ',
    label: 'Czechia',
    threeLetterCountryCode: '203',
    suffixes: ['20'],
    phoneCode: '+420',
    flag: '/assets/countryFlags/cz.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'KW',
    label: 'Kuwait',
    threeLetterCountryCode: '414',
    suffixes: ['65'],
    phoneCode: '+965',
    flag: '/assets/countryFlags/kw.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'ME',
    label: 'Montenegro',
    threeLetterCountryCode: '499',
    suffixes: ['82'],
    phoneCode: '+382',
    flag: '/assets/countryFlags/me.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'IN',
    label: 'India',
    threeLetterCountryCode: '356',
    suffixes: ['1'],
    phoneCode: '+91',
    flag: '/assets/countryFlags/in.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'NZ',
    label: 'New Zealand',
    threeLetterCountryCode: '554',
    suffixes: ['4'],
    phoneCode: '+64',
    flag: '/assets/countryFlags/nz.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'JM',
    label: 'Jamaica',
    threeLetterCountryCode: '388',
    suffixes: ['876'],
    phoneCode: '+1876',
    flag: '/assets/countryFlags/jm.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'KY',
    label: 'Cayman Islands',
    threeLetterCountryCode: '136',
    suffixes: ['345'],
    phoneCode: '+1345',
    flag: '/assets/countryFlags/ky.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'SM',
    label: 'San Marino',
    threeLetterCountryCode: '674',
    suffixes: ['78'],
    phoneCode: '+378',
    flag: '/assets/countryFlags/sm.svg',
    isCountry: true,
    phoneNumberLength: 6,
  },
  {
    value: 'CG',
    label: 'Republic of the Congo',
    threeLetterCountryCode: '178',
    suffixes: ['42'],
    phoneCode: '+242',
    flag: '/assets/countryFlags/cg.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'PK',
    label: 'Pakistan',
    threeLetterCountryCode: '586',
    suffixes: ['2'],
    phoneCode: '+92',
    flag: '/assets/countryFlags/pk.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'FR',
    label: 'France',
    threeLetterCountryCode: '250',
    suffixes: ['3'],
    phoneCode: '+33',
    flag: '/assets/countryFlags/fr.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'KZ',
    label: 'Kazakhstan',
    threeLetterCountryCode: '398',
    suffixes: ['6', '7'],
    phoneCode: '+7',
    flag: '/assets/countryFlags/kz.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'BH',
    label: 'Bahrain',
    threeLetterCountryCode: '048',
    suffixes: ['73'],
    phoneCode: '+973',
    flag: '/assets/countryFlags/bh.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'FJ',
    label: 'Fiji',
    threeLetterCountryCode: '242',
    suffixes: ['79'],
    phoneCode: '+679',
    flag: '/assets/countryFlags/fj.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'IS',
    label: 'Iceland',
    threeLetterCountryCode: '352',
    suffixes: ['54'],
    phoneCode: '+354',
    flag: '/assets/countryFlags/is.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'MM',
    label: 'Myanmar',
    threeLetterCountryCode: '104',
    suffixes: ['5'],
    phoneCode: '+95',
    flag: '/assets/countryFlags/mm.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'BD',
    label: 'Bangladesh',
    threeLetterCountryCode: '050',
    suffixes: ['80'],
    phoneCode: '+880',
    flag: '/assets/countryFlags/bd.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'PH',
    label: 'Philippines',
    threeLetterCountryCode: '608',
    suffixes: ['3'],
    phoneCode: '+63',
    flag: '/assets/countryFlags/ph.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'GQ',
    label: 'Equatorial Guinea',
    threeLetterCountryCode: '226',
    suffixes: ['40'],
    phoneCode: '+240',
    flag: '/assets/countryFlags/gq.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'IE',
    label: 'Ireland',
    threeLetterCountryCode: '372',
    suffixes: ['53'],
    phoneCode: '+353',
    flag: '/assets/countryFlags/ie.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'NP',
    label: 'Nepal',
    threeLetterCountryCode: '524',
    suffixes: ['77'],
    phoneCode: '+977',
    flag: '/assets/countryFlags/np.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'YE',
    label: 'Yemen',
    threeLetterCountryCode: '887',
    suffixes: ['67'],
    phoneCode: '+967',
    flag: '/assets/countryFlags/ye.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'KR',
    label: 'South Korea',
    threeLetterCountryCode: '410',
    suffixes: ['2'],
    phoneCode: '+82',
    flag: '/assets/countryFlags/kr.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'DK',
    label: 'Denmark',
    threeLetterCountryCode: '208',
    suffixes: ['5'],
    phoneCode: '+45',
    flag: '/assets/countryFlags/dk.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'OM',
    label: 'Oman',
    threeLetterCountryCode: '512',
    suffixes: ['68'],
    phoneCode: '+968',
    flag: '/assets/countryFlags/om.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'VC',
    label: 'Saint Vincent and the Grenadines',
    threeLetterCountryCode: '670',
    suffixes: ['784'],
    phoneCode: '+1784',
    flag: '/assets/countryFlags/vc.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'ER',
    label: 'Eritrea',
    threeLetterCountryCode: '232',
    suffixes: ['91'],
    phoneCode: '+291',
    flag: '/assets/countryFlags/er.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'AU',
    label: 'Australia',
    threeLetterCountryCode: '036',
    suffixes: ['1'],
    phoneCode: '+61',
    flag: '/assets/countryFlags/au.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'IR',
    label: 'Iran',
    threeLetterCountryCode: '364',
    suffixes: ['8'],
    phoneCode: '+98',
    flag: '/assets/countryFlags/ir.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'SV',
    label: 'El Salvador',
    threeLetterCountryCode: '222',
    suffixes: ['03'],
    phoneCode: '+503',
    flag: '/assets/countryFlags/sv.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'TZ',
    label: 'Tanzania',
    threeLetterCountryCode: '834',
    suffixes: ['55'],
    phoneCode: '+255',
    flag: '/assets/countryFlags/tz.svg',
    isCountry: true,
    phoneNumberLength: 6,
  },
  {
    value: 'GP',
    label: 'Guadeloupe',
    threeLetterCountryCode: '312',
    suffixes: ['90'],
    phoneCode: '+590',
    flag: '/assets/countryFlags/gp.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'SB',
    label: 'Solomon Islands',
    threeLetterCountryCode: '090',
    suffixes: ['77'],
    phoneCode: '+677',
    flag: '/assets/countryFlags/sb.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'KE',
    label: 'Kenya',
    threeLetterCountryCode: '404',
    suffixes: ['54'],
    phoneCode: '+254',
    flag: '/assets/countryFlags/ke.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'DO',
    label: 'Dominican Republic',
    threeLetterCountryCode: '214',
    suffixes: ['809', '829', '849'],
    phoneCode: '+1',
    flag: '/assets/countryFlags/do.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'GR',
    label: 'Greece',
    threeLetterCountryCode: '300',
    suffixes: ['0'],
    phoneCode: '+30',
    flag: '/assets/countryFlags/gr.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'GG',
    label: 'Guernsey',
    threeLetterCountryCode: '831',
    suffixes: ['4'],
    phoneCode: '+44',
    flag: '/assets/countryFlags/gg.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'RW',
    label: 'Rwanda',
    threeLetterCountryCode: '646',
    suffixes: ['50'],
    phoneCode: '+250',
    flag: '/assets/countryFlags/rw.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'PM',
    label: 'Saint Pierre and Miquelon',
    threeLetterCountryCode: '666',
    suffixes: ['08'],
    phoneCode: '+508',
    flag: '/assets/countryFlags/pm.svg',
    isCountry: true,
    phoneNumberLength: 6,
  },
  {
    value: 'TV',
    label: 'Tuvalu',
    threeLetterCountryCode: '798',
    suffixes: ['88'],
    phoneCode: '+688',
    flag: '/assets/countryFlags/tv.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'TW',
    label: 'Taiwan',
    threeLetterCountryCode: '158',
    suffixes: ['86'],
    phoneCode: '+886',
    flag: '/assets/countryFlags/tw.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'GY',
    label: 'Guyana',
    threeLetterCountryCode: '328',
    suffixes: ['92'],
    phoneCode: '+592',
    flag: '/assets/countryFlags/gy.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'SC',
    label: 'Seychelles',
    threeLetterCountryCode: '690',
    suffixes: ['48'],
    phoneCode: '+248',
    flag: '/assets/countryFlags/sc.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'BW',
    label: 'Botswana',
    threeLetterCountryCode: '072',
    suffixes: ['67'],
    phoneCode: '+267',
    flag: '/assets/countryFlags/bw.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'KH',
    label: 'Cambodia',
    threeLetterCountryCode: '116',
    suffixes: ['55'],
    phoneCode: '+855',
    flag: '/assets/countryFlags/kh.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'BB',
    label: 'Barbados',
    threeLetterCountryCode: '052',
    suffixes: ['246'],
    phoneCode: '+1246',
    flag: '/assets/countryFlags/bb.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'CO',
    label: 'Colombia',
    threeLetterCountryCode: '170',
    suffixes: ['7'],
    phoneCode: '+57',
    flag: '/assets/countryFlags/co.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'UA',
    label: 'Ukraine',
    threeLetterCountryCode: '804',
    suffixes: ['80'],
    phoneCode: '+380',
    flag: '/assets/countryFlags/ua.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'CI',
    label: 'Ivory Coast',
    threeLetterCountryCode: '384',
    suffixes: ['25'],
    phoneCode: '+225',
    flag: '/assets/countryFlags/ci.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'NR',
    label: 'Nauru',
    threeLetterCountryCode: '520',
    suffixes: ['74'],
    phoneCode: '+674',
    flag: '/assets/countryFlags/nr.svg',
    isCountry: true,
    phoneNumberLength: 6,
  },
  {
    value: 'NA',
    label: 'Namibia',
    threeLetterCountryCode: '516',
    suffixes: ['64'],
    phoneCode: '+264',
    flag: '/assets/countryFlags/na.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'TD',
    label: 'Chad',
    threeLetterCountryCode: '148',
    suffixes: ['35'],
    phoneCode: '+235',
    flag: '/assets/countryFlags/td.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'TO',
    label: 'Tonga',
    threeLetterCountryCode: '776',
    suffixes: ['76'],
    phoneCode: '+676',
    flag: '/assets/countryFlags/to.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'AR',
    label: 'Argentina',
    threeLetterCountryCode: '032',
    suffixes: ['4'],
    phoneCode: '+54',
    flag: '/assets/countryFlags/ar.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'NE',
    label: 'Niger',
    threeLetterCountryCode: '562',
    suffixes: ['27'],
    phoneCode: '+227',
    flag: '/assets/countryFlags/ne.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'MH',
    label: 'Marshall Islands',
    threeLetterCountryCode: '584',
    suffixes: ['92'],
    phoneCode: '+692',
    flag: '/assets/countryFlags/mh.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'CR',
    label: 'Costa Rica',
    threeLetterCountryCode: '188',
    suffixes: ['06'],
    phoneCode: '+506',
    flag: '/assets/countryFlags/cr.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'GH',
    label: 'Ghana',
    threeLetterCountryCode: '288',
    suffixes: ['33'],
    phoneCode: '+233',
    flag: '/assets/countryFlags/gh.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'AT',
    label: 'Austria',
    threeLetterCountryCode: '040',
    suffixes: ['3'],
    phoneCode: '+43',
    flag: '/assets/countryFlags/at.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'PS',
    label: 'Palestine',
    threeLetterCountryCode: '275',
    suffixes: ['70'],
    phoneCode: '+970',
    flag: '/assets/countryFlags/ps.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'CU',
    label: 'Cuba',
    threeLetterCountryCode: '192',
    suffixes: ['3'],
    phoneCode: '+53',
    flag: '/assets/countryFlags/cu.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'HU',
    label: 'Hungary',
    threeLetterCountryCode: '348',
    suffixes: ['6'],
    phoneCode: '+36',
    flag: '/assets/countryFlags/hu.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'FM',
    label: 'Micronesia',
    threeLetterCountryCode: '583',
    suffixes: ['91'],
    phoneCode: '+691',
    flag: '/assets/countryFlags/fm.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'BZ',
    label: 'Belize',
    threeLetterCountryCode: '084',
    suffixes: ['01'],
    phoneCode: '+501',
    flag: '/assets/countryFlags/bz.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'BS',
    label: 'Bahamas',
    threeLetterCountryCode: '044',
    suffixes: ['242'],
    phoneCode: '+1242',
    flag: '/assets/countryFlags/bs.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'AW',
    label: 'Aruba',
    threeLetterCountryCode: '533',
    suffixes: ['97'],
    phoneCode: '+297',
    flag: '/assets/countryFlags/aw.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'ST',
    label: 'São Tomé and Príncipe',
    threeLetterCountryCode: '678',
    suffixes: ['39'],
    phoneCode: '+239',
    flag: '/assets/countryFlags/st.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'RU',
    label: 'Russia',
    threeLetterCountryCode: '643',
    suffixes: ['3', '4', '5', '8', '9'],
    phoneCode: '+7',
    flag: '/assets/countryFlags/ru.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'LU',
    label: 'Luxembourg',
    threeLetterCountryCode: '442',
    suffixes: ['52'],
    phoneCode: '+352',
    flag: '/assets/countryFlags/lu.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'FO',
    label: 'Faroe Islands',
    threeLetterCountryCode: '234',
    suffixes: ['98'],
    phoneCode: '+298',
    flag: '/assets/countryFlags/fo.svg',
    isCountry: true,
    phoneNumberLength: 5,
  },
  {
    value: 'NI',
    label: 'Nicaragua',
    threeLetterCountryCode: '558',
    suffixes: ['05'],
    phoneCode: '+505',
    flag: '/assets/countryFlags/ni.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'TN',
    label: 'Tunisia',
    threeLetterCountryCode: '788',
    suffixes: ['16'],
    phoneCode: '+216',
    flag: '/assets/countryFlags/tn.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'CD',
    label: 'DR Congo',
    threeLetterCountryCode: '180',
    suffixes: ['43'],
    phoneCode: '+243',
    flag: '/assets/countryFlags/cd.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'ZA',
    label: 'South Africa',
    threeLetterCountryCode: '710',
    suffixes: ['7'],
    phoneCode: '+27',
    flag: '/assets/countryFlags/za.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'SL',
    label: 'Sierra Leone',
    threeLetterCountryCode: '694',
    suffixes: ['32'],
    phoneCode: '+232',
    flag: '/assets/countryFlags/sl.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'LS',
    label: 'Lesotho',
    threeLetterCountryCode: '426',
    suffixes: ['66'],
    phoneCode: '+266',
    flag: '/assets/countryFlags/ls.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'IT',
    label: 'Italy',
    threeLetterCountryCode: '380',
    suffixes: ['9'],
    phoneCode: '+39',
    flag: '/assets/countryFlags/it.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'DJ',
    label: 'Djibouti',
    threeLetterCountryCode: '262',
    suffixes: ['53'],
    phoneCode: '+253',
    flag: '/assets/countryFlags/dj.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'LK',
    label: 'Sri Lanka',
    threeLetterCountryCode: '144',
    suffixes: ['4'],
    phoneCode: '+94',
    flag: '/assets/countryFlags/lk.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'LC',
    label: 'Saint Lucia',
    threeLetterCountryCode: '662',
    suffixes: ['758'],
    phoneCode: '+1758',
    flag: '/assets/countryFlags/lc.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'WS',
    label: 'Samoa',
    threeLetterCountryCode: '882',
    suffixes: ['85'],
    phoneCode: '+685',
    flag: '/assets/countryFlags/ws.svg',
    isCountry: true,
    phoneNumberLength: 5,
  },
  {
    value: 'CC',
    label: 'Cocos (Keeling) Islands',
    threeLetterCountryCode: '166',
    suffixes: ['1'],
    phoneCode: '+61',
    flag: '/assets/countryFlags/cc.svg',
    isCountry: true,
    phoneNumberLength: 6,
  },
  {
    value: 'PR',
    label: 'Puerto Rico',
    threeLetterCountryCode: '630',
    suffixes: ['787', '939'],
    phoneCode: '+1',
    flag: '/assets/countryFlags/pr.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'GA',
    label: 'Gabon',
    threeLetterCountryCode: '266',
    suffixes: ['41'],
    phoneCode: '+241',
    flag: '/assets/countryFlags/ga.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'TM',
    label: 'Turkmenistan',
    threeLetterCountryCode: '795',
    suffixes: ['93'],
    phoneCode: '+993',
    flag: '/assets/countryFlags/tm.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'LV',
    label: 'Latvia',
    threeLetterCountryCode: '428',
    suffixes: ['71'],
    phoneCode: '+371',
    flag: '/assets/countryFlags/lv.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'SN',
    label: 'Senegal',
    threeLetterCountryCode: '686',
    suffixes: ['21'],
    phoneCode: '+221',
    flag: '/assets/countryFlags/sn.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'BE',
    label: 'Belgium',
    threeLetterCountryCode: '056',
    suffixes: ['2'],
    phoneCode: '+32',
    flag: '/assets/countryFlags/be.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'MD',
    label: 'Moldova',
    threeLetterCountryCode: '498',
    suffixes: ['73'],
    phoneCode: '+373',
    flag: '/assets/countryFlags/md.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'LI',
    label: 'Liechtenstein',
    threeLetterCountryCode: '438',
    suffixes: ['23'],
    phoneCode: '+423',
    flag: '/assets/countryFlags/li.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'MW',
    label: 'Malawi',
    threeLetterCountryCode: '454',
    suffixes: ['65'],
    phoneCode: '+265',
    flag: '/assets/countryFlags/mw.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'LB',
    label: 'Lebanon',
    threeLetterCountryCode: '422',
    suffixes: ['61'],
    phoneCode: '+961',
    flag: '/assets/countryFlags/lb.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'MN',
    label: 'Mongolia',
    threeLetterCountryCode: '496',
    suffixes: ['76'],
    phoneCode: '+976',
    flag: '/assets/countryFlags/mn.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'NO',
    label: 'Norway',
    threeLetterCountryCode: '578',
    suffixes: ['7'],
    phoneCode: '+47',
    flag: '/assets/countryFlags/no.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'VG',
    label: 'British Virgin Islands',
    threeLetterCountryCode: '092',
    suffixes: ['284'],
    phoneCode: '+1284',
    flag: '/assets/countryFlags/vg.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'CM',
    label: 'Cameroon',
    threeLetterCountryCode: '120',
    suffixes: ['37'],
    phoneCode: '+237',
    flag: '/assets/countryFlags/cm.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'TH',
    label: 'Thailand',
    threeLetterCountryCode: '764',
    suffixes: ['6'],
    phoneCode: '+66',
    flag: '/assets/countryFlags/th.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'NG',
    label: 'Nigeria',
    threeLetterCountryCode: '566',
    suffixes: ['34'],
    phoneCode: '+234',
    flag: '/assets/countryFlags/ng.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'RE',
    label: 'Réunion',
    threeLetterCountryCode: '638',
    suffixes: ['62'],
    phoneCode: '+262',
    flag: '/assets/countryFlags/re.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'CV',
    label: 'Cape Verde',
    threeLetterCountryCode: '132',
    suffixes: ['38'],
    phoneCode: '+238',
    flag: '/assets/countryFlags/cv.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'JE',
    label: 'Jersey',
    threeLetterCountryCode: '832',
    suffixes: ['4'],
    phoneCode: '+44',
    flag: '/assets/countryFlags/je.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'DZ',
    label: 'Algeria',
    threeLetterCountryCode: '012',
    suffixes: ['13'],
    phoneCode: '+213',
    flag: '/assets/countryFlags/dz.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'LA',
    label: 'Laos',
    threeLetterCountryCode: '418',
    suffixes: ['56'],
    phoneCode: '+856',
    flag: '/assets/countryFlags/la.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'BQ',
    label: 'Caribbean Netherlands',
    threeLetterCountryCode: '535',
    suffixes: ['99'],
    phoneCode: '+599',
    flag: '/assets/countryFlags/bq.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'AZ',
    label: 'Azerbaijan',
    threeLetterCountryCode: '031',
    suffixes: ['94'],
    phoneCode: '+994',
    flag: '/assets/countryFlags/az.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'SX',
    label: 'Sint Maarten',
    threeLetterCountryCode: '534',
    suffixes: ['721'],
    phoneCode: '+1721',
    flag: '/assets/countryFlags/sx.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'MA',
    label: 'Morocco',
    threeLetterCountryCode: '504',
    suffixes: ['12'],
    phoneCode: '+212',
    flag: '/assets/countryFlags/ma.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'AX',
    label: 'Åland Islands',
    threeLetterCountryCode: '248',
    suffixes: ['5818'],
    phoneCode: '+35818',
    flag: '/assets/countryFlags/ax.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'BG',
    label: 'Bulgaria',
    threeLetterCountryCode: '100',
    suffixes: ['59'],
    phoneCode: '+359',
    flag: '/assets/countryFlags/bg.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'BI',
    label: 'Burundi',
    threeLetterCountryCode: '108',
    suffixes: ['57'],
    phoneCode: '+257',
    flag: '/assets/countryFlags/bi.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'UG',
    label: 'Uganda',
    threeLetterCountryCode: '800',
    suffixes: ['56'],
    phoneCode: '+256',
    flag: '/assets/countryFlags/ug.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'MZ',
    label: 'Mozambique',
    threeLetterCountryCode: '508',
    suffixes: ['58'],
    phoneCode: '+258',
    flag: '/assets/countryFlags/mz.svg',
    isCountry: true,
    phoneNumberLength: 12,
  },
  {
    value: 'GE',
    label: 'Georgia',
    threeLetterCountryCode: '268',
    suffixes: ['95'],
    phoneCode: '+995',
    flag: '/assets/countryFlags/ge.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'GB',
    label: 'United Kingdom',
    threeLetterCountryCode: '826',
    suffixes: ['4'],
    phoneCode: '+44',
    flag: '/assets/countryFlags/gb.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'WF',
    label: 'Wallis and Futuna',
    threeLetterCountryCode: '876',
    suffixes: ['81'],
    phoneCode: '+681',
    flag: '/assets/countryFlags/wf.svg',
    isCountry: true,
    phoneNumberLength: 6,
  },
  {
    value: 'PN',
    label: 'Pitcairn Islands',
    threeLetterCountryCode: '612',
    suffixes: ['4'],
    phoneCode: '+64',
    flag: '/assets/countryFlags/pn.svg',
    isCountry: true,
    phoneNumberLength: 4,
  },
  {
    value: 'RO',
    label: 'Romania',
    threeLetterCountryCode: '642',
    suffixes: ['0'],
    phoneCode: '+40',
    flag: '/assets/countryFlags/ro.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'VN',
    label: 'Vietnam',
    threeLetterCountryCode: '704',
    suffixes: ['4'],
    phoneCode: '+84',
    flag: '/assets/countryFlags/vn.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'JP',
    label: 'Japan',
    threeLetterCountryCode: '392',
    suffixes: ['1'],
    phoneCode: '+81',
    flag: '/assets/countryFlags/jp.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'EG',
    label: 'Egypt',
    threeLetterCountryCode: '818',
    suffixes: ['0'],
    phoneCode: '+20',
    flag: '/assets/countryFlags/eg.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'LR',
    label: 'Liberia',
    threeLetterCountryCode: '430',
    suffixes: ['31'],
    phoneCode: '+231',
    flag: '/assets/countryFlags/lr.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'LT',
    label: 'Lithuania',
    threeLetterCountryCode: '440',
    suffixes: ['70'],
    phoneCode: '+370',
    flag: '/assets/countryFlags/lt.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'HT',
    label: 'Haiti',
    threeLetterCountryCode: '332',
    suffixes: ['09'],
    phoneCode: '+509',
    flag: '/assets/countryFlags/ht.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'VI',
    label: 'United States Virgin Islands',
    threeLetterCountryCode: '850',
    suffixes: ['340'],
    phoneCode: '+1340',
    flag: '/assets/countryFlags/vi.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'PG',
    label: 'Papua New Guinea',
    threeLetterCountryCode: '598',
    suffixes: ['75'],
    phoneCode: '+675',
    flag: '/assets/countryFlags/pg.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'HK',
    label: 'Hong Kong',
    threeLetterCountryCode: '344',
    suffixes: ['52'],
    phoneCode: '+852',
    flag: '/assets/countryFlags/hk.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'KG',
    label: 'Kyrgyzstan',
    threeLetterCountryCode: '417',
    suffixes: ['96'],
    phoneCode: '+996',
    flag: '/assets/countryFlags/kg.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'PW',
    label: 'Palau',
    threeLetterCountryCode: '585',
    suffixes: ['80'],
    phoneCode: '+680',
    flag: '/assets/countryFlags/pw.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'MG',
    label: 'Madagascar',
    threeLetterCountryCode: '450',
    suffixes: ['61'],
    phoneCode: '+261',
    flag: '/assets/countryFlags/mg.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'GM',
    label: 'Gambia',
    threeLetterCountryCode: '270',
    suffixes: ['20'],
    phoneCode: '+220',
    flag: '/assets/countryFlags/gm.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'TG',
    label: 'Togo',
    threeLetterCountryCode: '768',
    suffixes: ['28'],
    phoneCode: '+228',
    flag: '/assets/countryFlags/tg.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'TK',
    label: 'Tokelau',
    threeLetterCountryCode: '772',
    suffixes: ['90'],
    phoneCode: '+690',
    flag: '/assets/countryFlags/tk.svg',
    isCountry: true,
    phoneNumberLength: 5,
  },
  {
    value: 'SI',
    label: 'Slovenia',
    threeLetterCountryCode: '705',
    suffixes: ['86'],
    phoneCode: '+386',
    flag: '/assets/countryFlags/si.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'SG',
    label: 'Singapore',
    threeLetterCountryCode: '702',
    suffixes: ['5'],
    phoneCode: '+65',
    flag: '/assets/countryFlags/sg.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'GF',
    label: 'French Guiana',
    threeLetterCountryCode: '254',
    suffixes: ['94'],
    phoneCode: '+594',
    flag: '/assets/countryFlags/gf.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },

  {
    value: 'MS',
    label: 'Montserrat',
    threeLetterCountryCode: '500',
    suffixes: ['664'],
    phoneCode: '+1664',
    flag: '/assets/countryFlags/ms.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'MY',
    label: 'Malaysia',
    threeLetterCountryCode: '458',
    suffixes: ['0'],
    phoneCode: '+60',
    flag: '/assets/countryFlags/my.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'DE',
    label: 'Germany',
    threeLetterCountryCode: '276',
    suffixes: ['9'],
    phoneCode: '+49',
    flag: '/assets/countryFlags/de.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'AG',
    label: 'Antigua and Barbuda',
    threeLetterCountryCode: '028',
    suffixes: ['268'],
    phoneCode: '+1268',
    flag: '/assets/countryFlags/ag.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'MF',
    label: 'Saint Martin',
    threeLetterCountryCode: '663',
    suffixes: ['90'],
    phoneCode: '+590',
    flag: '/assets/countryFlags/mf.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'SO',
    label: 'Somalia',
    threeLetterCountryCode: '706',
    suffixes: ['52'],
    phoneCode: '+252',
    flag: '/assets/countryFlags/so.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'MO',
    label: 'Macau',
    threeLetterCountryCode: '446',
    suffixes: ['53'],
    phoneCode: '+853',
    flag: '/assets/countryFlags/mo.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'AL',
    label: 'Albania',
    threeLetterCountryCode: '008',
    suffixes: ['55'],
    phoneCode: '+355',
    flag: '/assets/countryFlags/al.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'YT',
    label: 'Mayotte',
    threeLetterCountryCode: '175',
    suffixes: ['62'],
    phoneCode: '+262',
    flag: '/assets/countryFlags/yt.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'DM',
    label: 'Dominica',
    threeLetterCountryCode: '212',
    suffixes: ['767'],
    phoneCode: '+1767',
    flag: '/assets/countryFlags/dm.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'ZM',
    label: 'Zambia',
    threeLetterCountryCode: '894',
    suffixes: ['60'],
    phoneCode: '+260',
    flag: '/assets/countryFlags/zm.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'AI',
    label: 'Anguilla',
    threeLetterCountryCode: '660',
    suffixes: ['264'],
    phoneCode: '+1264',
    flag: '/assets/countryFlags/ai.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'CK',
    label: 'Cook Islands',
    threeLetterCountryCode: '184',
    suffixes: ['82'],
    phoneCode: '+682',
    flag: '/assets/countryFlags/ck.svg',
    isCountry: true,
    phoneNumberLength: 5,
  },
  {
    value: 'MQ',
    label: 'Martinique',
    threeLetterCountryCode: '474',
    suffixes: ['96'],
    phoneCode: '+596',
    flag: '/assets/countryFlags/mq.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'GN',
    label: 'Guinea',
    threeLetterCountryCode: '324',
    suffixes: ['24'],
    phoneCode: '+224',
    flag: '/assets/countryFlags/gn.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
  {
    value: 'KM',
    label: 'Comoros',
    threeLetterCountryCode: '174',
    suffixes: ['69'],
    phoneCode: '+269',
    flag: '/assets/countryFlags/km.svg',
    isCountry: true,
    phoneNumberLength: 7,
  },
  {
    value: 'NU',
    label: 'Niue',
    threeLetterCountryCode: '570',
    suffixes: ['83'],
    phoneCode: '+683',
    flag: '/assets/countryFlags/nu.svg',
    isCountry: true,
    phoneNumberLength: 4,
  },
  {
    value: 'CH',
    label: 'Switzerland',
    threeLetterCountryCode: '756',
    suffixes: ['1'],
    phoneCode: '+41',
    flag: '/assets/countryFlags/ch.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'NF',
    label: 'Norfolk Island',
    threeLetterCountryCode: '574',
    suffixes: ['72'],
    phoneCode: '+672',
    flag: '/assets/countryFlags/nf.svg',
    isCountry: true,
    phoneNumberLength: 6,
  },
  {
    value: 'IM',
    label: 'Isle of Man',
    threeLetterCountryCode: '833',
    suffixes: ['4'],
    phoneCode: '+44',
    flag: '/assets/countryFlags/im.svg',
    isCountry: true,
    phoneNumberLength: 10,
  },
  {
    value: 'SD',
    label: 'Sudan',
    threeLetterCountryCode: '729',
    suffixes: ['49'],
    phoneCode: '+249',
    flag: '/assets/countryFlags/sd.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'TJ',
    label: 'Tajikistan',
    threeLetterCountryCode: '762',
    suffixes: ['92'],
    phoneCode: '+992',
    flag: '/assets/countryFlags/tj.svg',
    isCountry: true,
    phoneNumberLength: 9,
  },
  {
    value: 'CY',
    label: 'Cyprus',
    threeLetterCountryCode: '196',
    suffixes: ['57'],
    phoneCode: '+357',
    flag: '/assets/countryFlags/cy.svg',
    isCountry: true,
    phoneNumberLength: 8,
  },
]
