import React, { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import { Skeleton } from '@mui/material'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import clsx from 'clsx'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { v4 as uuidv4 } from 'uuid'

import MainModal from './common/MainModal'
import Button from '../common/buttons/Button'
import { SelectedClasses } from './components/SelectedClasses/SelectedClasses'

import './BulkAdd.css'

import { MODAL_CONSTS } from '../../const/modal-const'

import {
  IUSEFEntry,
  IUSEFESection,
  sectionCodesByDisciplines,
} from '../../pages/organizer/manage/clinic/data/reports/USEF'
import { RegistrationFeesType } from '../../models/event-fees/event-fees.interface'

const countryOptions = [{ label: 'Amercia', value: 'Amercia' }]

interface BulkAddProps {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string) => void
  dataToPassOn: { onSave: (classes: RegistrationFeesType[]) => void }
}
export const BulkAdd: FC<BulkAddProps> = ({ show, handleModal, dataToPassOn }) => {
  const [country, setCountry] = useState<string | null>(null)
  const [discipline, setDiscipline] = useState<string | null>(null)
  const [classes, setClasses] = useState<IUSEFEntry[] | null>(null)
  const [selectedClasses, setSelectedClasses] = useState<IUSEFEntry[] | null>(null)

  useEffect(() => {
    if (discipline) setClasses(sectionCodesByDisciplines[discipline as keyof IUSEFESection])
  }, [discipline])

  const dataIds = React.useMemo<UniqueIdentifier[]>(() => {
    if (selectedClasses) {
      return selectedClasses.map((selected) => selected.code)
    }
    return []
  }, [selectedClasses])

  const handleDragEnd = (event: any) => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = dataIds.indexOf(active.id)
      const newIndex = dataIds.indexOf(over.id)
      let newArray = arrayMove(selectedClasses ?? [], oldIndex, newIndex)
      setSelectedClasses([...newArray])
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  return (
    <MainModal hideCloseButton={true} show={show} type="BULK_ADD" size="5xl">
      <div className="pb-5 mb-5 flex flex-wrap justify-between items-center gap-2 border-b border-SeabiscuitDark200ThemeColor">
        <h4 className="text-SeabiscuitMainThemeColor text-[25px] font-bold">
          Bulk add classes to your event
        </h4>
        <div className="flex gap-8 items-center ml-auto">
          <button
            className="text-[14px] text-SeabiscuitDark200ThemeColor/50 underline hover:no-underline"
            onClick={() => handleModal(false, MODAL_CONSTS.BULK_ADD)}
          >
            Cancel
          </button>
          <Button
            className="h-11 normal-case"
            onClick={() => {
              if (selectedClasses)
                dataToPassOn.onSave(
                  selectedClasses.map((selected) => ({
                    order: Number(selected.code),
                    name: selected.class,
                    category: 'registrations',
                    note: '',
                    cost: 0,
                    actualCost: 0,
                    actualCostAlias: '$0',
                    costAlias: '$0',
                    uuid: uuidv4(),
                    increment: '',
                    qualifyingClass: { name: '', price: '0' },
                    location: '',
                    startDate: '',
                    startTimeHours: '00',
                    startTimeMinutes: '00',
                    startTimeFormat: 'AM',
                    tag: '',
                  }))
                )
              handleModal(false, MODAL_CONSTS.BULK_ADD)
            }}
          >
            Add classes
          </Button>
        </div>
      </div>
      <div className="flex gap-2 flex-wrap h-full justify-between">
        <div className="p-5 w-full md:w-[49%] overflow-auto flex flex-col border border-SeabiscuitLightThemeColorD3 rounded-lg">
          <div className="flex flex-wrap justify-between gap-2 pb-4 mb-4 border-b border-SeabiscuitProgressBarThemeColor">
            <div>
              <h4 className="text-SeabiscuitDark200ThemeColor text-[22px] font-bold">
                All Classes
              </h4>
              <p className="text-SeabiscuitDark200ThemeColor/50 text-[14px]">
                Select classes to add to event
              </p>
            </div>
            <div className="flex justify-end flex-wrap gap-2 ml-auto">
              <div className="text-right flex items-center gap-2">
                <div>
                  <Select
                    classNamePrefix="bulk-add"
                    isClearable={false}
                    isSearchable={false}
                    placeholder="Select"
                    className="p-0"
                    options={countryOptions}
                    value={country ? { value: country, label: country } : null}
                    onChange={(newValue) => setCountry(newValue?.value ?? null)}
                  />
                  <p className="text-[12px] text-SeabiscuitDark200ThemeColor/50">Country</p>
                </div>
                <div className="w-11 p-2.5 bg-SeabiscuitGrayThemeColor rounded-lg">
                  <img className="w-full" src="/assets/og_icons/Globe-1.svg" alt="" />
                </div>
              </div>
              <div className="text-right flex items-center gap-2">
                <div>
                  <Select
                    classNamePrefix="bulk-add"
                    placeholder="Select"
                    isClearable={false}
                    isSearchable={false}
                    className="p-0"
                    options={Object.keys(sectionCodesByDisciplines).map((discipline) => ({
                      label: discipline,
                      value: discipline,
                    }))}
                    value={discipline ? { value: discipline, label: discipline } : null}
                    onChange={(newValue) => setDiscipline(newValue?.value ?? null)}
                  />
                  <p className="text-[12px] text-SeabiscuitDark200ThemeColor/50">Discipline</p>
                </div>
                <div className="w-11 p-2.5 bg-SeabiscuitGrayThemeColor rounded-lg">
                  <img className="w-full" src="/assets/og_icons/YearofHorse-1.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
          {country && discipline ? (
            <ul className="flex flex-col gap-2">
              {classes?.map((item) => (
                <li
                  key={item.code}
                  className={clsx(
                    'relative text-SeabiscuitDark200ThemeColor border border-SeabiscuitLightThemeColorD3 rounded-lg p-3 pr-9 cursor-pointer hover:border-SeabiscuitDark600ThemeColor transition-all',
                    selectedClasses &&
                      selectedClasses?.findIndex((selected) => selected.code === item.code) !==
                        -1 &&
                      'bg-SeabiscuitGrayThemeColor'
                  )}
                  onClick={() => {
                    if (selectedClasses) {
                      const findIndex = selectedClasses.findIndex(
                        (selected) => selected.code === item.code
                      )
                      if (findIndex !== -1) {
                        selectedClasses.splice(findIndex, 1)
                        setSelectedClasses([...selectedClasses])
                      } else {
                        setSelectedClasses([...selectedClasses, item])
                      }
                    } else {
                      setSelectedClasses([item])
                    }
                  }}
                >
                  {item.class}{' '}
                  <span className="text-SeabiscuitDark200ThemeColor/50">• {item.code}</span>
                  <div
                    className={clsx(
                      'absolute z-10 right-3 top-1/2 -translate-y-1/2 rounded-full border-2 border-SeabiscuitDark200ThemeColor/50 w-5 h-5',
                      selectedClasses?.findIndex((selected) => selected.code === item.code) !== -1
                        ? ' border-none'
                        : ' border-2'
                    )}
                  >
                    {selectedClasses &&
                      selectedClasses?.findIndex((selected) => selected.code === item.code) !==
                        -1 && (
                        <img
                          className="absolute left-0 top-0 w-full h-full"
                          src="/assets/cp_icons/Ok-3.svg"
                          alt=""
                        />
                      )}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="flex flex-col justify-center items-center flex-1">
              <p className="mb-2 text-SeabiscuitDark200ThemeColor">
                Select country and discipline to add classes
              </p>
              <Skeleton className="mb-2" width="70%" height={48} variant="rounded" />
              <Skeleton className="mb-2" width="70%" height={48} variant="rounded" />
              <Skeleton className="mb-2" width="70%" height={48} variant="rounded" />
            </div>
          )}
        </div>
        <div className="p-5 w-full md:w-[49%] overflow-auto flex flex-col border border-SeabiscuitLightThemeColorD3 rounded-lg">
          <div className="flex flex-wrap justify-between gap-2 pb-4 mb-4 border-b border-SeabiscuitProgressBarThemeColor">
            <div>
              <h4 className="text-SeabiscuitDark200ThemeColor text-[22px] font-bold">
                Selected classes
              </h4>
              <p className="text-SeabiscuitDark200ThemeColor/50 text-[14px]">
                Drag and drop classes into order
              </p>
            </div>
            <div className="text-right flex items-center gap-2 ml-auto">
              <div>
                <p className="text-[14px] text-SeabiscuitDark200ThemeColor">
                  {selectedClasses?.length ?? 0} classes
                </p>
                <p className="text-[12px] text-SeabiscuitDark200ThemeColor/50">Total</p>
              </div>
              <div className="w-11 p-2.5 bg-SeabiscuitGrayThemeColor rounded-lg">
                <img className="w-full" src="/assets/og_icons/Prize-1.svg" alt="" />
              </div>
            </div>
          </div>
          {selectedClasses ? (
            <div className="flex flex-col gap-2">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
                  {selectedClasses?.map((item, index) => (
                    <SelectedClasses key={item.code} item={item} index={index} />
                  ))}
                </SortableContext>
              </DndContext>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center flex-1">
              <p className="mb-2 text-SeabiscuitDark200ThemeColor">
                Select country and discipline to add classes
              </p>
              <Skeleton className="mb-2" width="70%" height={48} variant="rounded" />
              <Skeleton className="mb-2" width="70%" height={48} variant="rounded" />
              <Skeleton className="mb-2" width="70%" height={48} variant="rounded" />
            </div>
          )}
        </div>
      </div>
    </MainModal>
  )
}
