import { Autorenew, Clear } from '@mui/icons-material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import { httpService } from '../../services/httpService'

type IAmountAvailItem = {
  amount: number
  currency: string
  source_types: {
    card: number
  }
}

type Props = {
  show?: boolean
  handleModal?: any
  dataToPassOn?: any
  amountAvail?: IAmountAvailItem[]
}

type error_type = {
  status: boolean
  message: string
}

const MIN_STRIPE_PAYOUT_AMOUNT = 1

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const NewTransferModal = (props: Props) => {
  //Hooks and Consts
  const { amountAvail } = props.dataToPassOn

  const [amountAvailableFunds, setAvailableFunds] = useState(0)

  const [step, setStep] = useState<string>('0')
  const [enteredAmount, setEnteredAmount] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [latestPayoutDetail, setLatestPayoutDetail] = useState<any>(null)
  const setFieldError = useState<error_type>({ status: false, message: '' })[1]

  const toastFunction = useToasterHelper()

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    const availableFunds_ = Number(
      amountAvail && amountAvail.length ? ((amountAvail[0]?.amount ?? 0) / 100).toFixed(2) : '0'
    )
    setAvailableFunds(availableFunds_)
  }, [amountAvail])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleSubmit = async () => {
    let enteredAmount_ = Number(enteredAmount)
    setLoading(true)

    try {
      if (!Number(amountAvailableFunds) || enteredAmount_ < 1) {
        setLoading(false)
        setFieldError({
          status: true,
          message: 'This field is required and entered value should be greater than $1.',
        })
        return
      }

      setFieldError({
        status: false,
        message: '',
      })

      const response = await await httpService({
        url: 'createPayout',
        method: 'POST',
        data: {
          amount: enteredAmount ?? 0,
          stripeConnectAccountId: props.dataToPassOn.stripeConnectAccountId,
        },
      })
      setLatestPayoutDetail((response as any)?.result)
      setStep('1')
      toastFunction.success({ message: MESSAGES_CONST.PAYOUT_REQUEST_SUBMIT_SUCCESSFULLY })
    } catch (err) {
      setLoading(false)
      toastFunction.success({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let enteredAmount_ = e.target.value
    // eslint-disable-next-line no-useless-escape
    let chunks = enteredAmount_.replace(/[^\d\.]/g, '').split('.')
    if (chunks.length > 2) chunks = chunks.slice(0, 2)
    enteredAmount_ = chunks.join('.')
    setEnteredAmount(enteredAmount_)
  }

  return (
    <>
      <div
        className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-2xl outline-none text-current pb-10 pt-8 px-8 max-h-[550px]">
            <span
              onClick={() => props.handleModal(false, MODAL_CONSTS.NEW_TRANSFER)}
              className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
            >
              <Clear className="text-[#122B46]" fontSize="small" />
            </span>

            <div className="modal-header rounded-t-xl font-Poppins">
              <h5 className="text-xl absolute top-3 leading-normal text-SeabiscuitDark200ThemeColor font-normal">
                {step === '0' ? 'Transfer money to your bank account' : 'Success!'}
              </h5>

              {step === '0' ? (
                <div className="formSection">
                  <div className="relative mt-6">
                    {/* <img
                                            src="/assets/og_icons/Data Transfer-1.svg"
                                            className="w-6 absolute top-4 left-4 z-50"
                                            alt="Transfer"
                                        /> */}
                    <div className="relative">
                      <input
                        type="text"
                        className="placeholder:text-[#122B4680] text-[#122B46] relative w-full border !border-[#122B461A] rounded-xl p-4 pl-14 focus-visible:outline-none focus-visible:ring-none focus:ring-0"
                        placeholder="Enter transfer amount"
                        title="Enter transfer amount"
                        value={enteredAmount}
                        onChange={onAmountChange}
                      />
                      <span className="absolute left-[24px] top-7 transform -translate-y-1/2 text-[#122B46]">
                        $
                      </span>
                    </div>
                  </div>

                  <div className="relative mt-2">
                    <img
                      src="/assets/og_icons/MoneyBag5.svg"
                      className="w-6 absolute top-4 left-4"
                      alt="MoneyBag"
                    />

                    <div className="placeholder:text-[#122B4680] text-[#122B46] relative w-full border !border-[#122B461A] rounded-xl p-4 pl-14 focus-visible:outline-none focus-visible:ring-none focus:ring-0 text-nr">
                      {`Amount Avaliable: $${+amountAvailableFunds}`}
                    </div>
                  </div>

                  <div className="relative mt-2">
                    <img
                      src="/assets/og_icons/Museum.svg"
                      className="w-6 absolute top-4 left-4"
                      alt="MoneyBag"
                    />
                    <input
                      className="placeholder:text-[#122B4680] text-[#122B46] relative w-full border border-[#122B461A] rounded-xl p-4 pl-14 focus-visible:outline-none focus-visible:ring-none"
                      disabled
                      value={`Transfer to account: ********${props.dataToPassOn.last4 ?? '-***'}`}
                    />
                  </div>

                  <div className="relative mt-2">
                    <img
                      src="/assets/og_icons/Error-1.svg"
                      className="w-6 absolute top-4 left-4"
                      alt="MoneyBag"
                    />
                    <div className="relative w-full border border-[#122B461A] rounded-xl p-4 text-[13px] pl-14 h-[176px] overflow-auto">
                      {/* <p className="text-[#122B46]">Disclaimer</p> */}
                      <p className="text-[#122B46]">
                        Once money is transferred to your bank account, it cannot be transferred
                        back.{' '}
                      </p>
                      <p className="text-[#122B46] mt-2">
                        To ensure you have enough funds in your account to manage refunds and event
                        related payments, Pegasus advises you not to transfer funds to your bank
                        account until your current events are closed.
                      </p>
                      <p className="text-[#122B46] mt-2">
                        Note. It can take 3-5 business days for funds to be available in your
                        connected account.
                      </p>
                    </div>
                  </div>

                  <div className="">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="w-full h-12 mx-auto flex items-center justify-center py-2 border border-transparent rounded-xl shadow-sm text-[14px] font-normal text-white bg-[#F7074F] focus:outline-none mt-2 disabled:bg-SeabiscuitLightThemeColor disabled:text-SeabiscuitDark200ThemeColor"
                      disabled={
                        amountAvailableFunds < MIN_STRIPE_PAYOUT_AMOUNT ||
                        Number(enteredAmount) < MIN_STRIPE_PAYOUT_AMOUNT
                      }
                    >
                      {loading ? <Autorenew className="animate-spin" /> : 'Transfer'}
                    </button>

                    <button
                      type="button"
                      onClick={() => props?.handleModal(false, MODAL_CONSTS.NEW_TRANSFER)}
                      className="w-full h-12 mx-auto text-[14px] flex items-center justify-center py-2 border border-transparent rounded-xl shadow-sm font-normal text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor focus:outline-none mt-2"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="successSection">
                  <div className="mt-8 border border-xl border-[#122B4633] p-4 rounded-2xl">
                    <p className="text-[#122B46]">Transfer details:</p>

                    <div className="payoutDetails mt-3 flex items-center">
                      <img
                        src="/assets/og_icons/Us Dollar Circled-1.svg"
                        className="w-6 mr-4"
                        alt="dollarIcon"
                      />
                      <span className="text-[#122B46] text-[13px]">
                        Amount transferred: ${amountAvailableFunds}
                      </span>
                    </div>

                    <div className="payoutDetails mt-3 flex items-center">
                      <img
                        src="/assets/og_icons/Tear-OffCalendar6.svg"
                        className="w-5 mr-4"
                        alt="OffCalendar6"
                      />
                      <span className="text-[#122B46] ml-1 text-[13px]">
                        Transfer date:{' '}
                        {latestPayoutDetail && moment(new Date()).format(`MMMM Do, YYYY`)}
                      </span>
                    </div>

                    <div className="payoutDetails mt-3 flex items-center">
                      <img src="/assets/og_icons/Museum.svg" className="w-6 mr-4" alt="Museum" />
                      <span className="text-[#122B46] text-[13px]">
                        Transferred to: ****{props.dataToPassOn.last4}
                      </span>
                    </div>

                    <div className="payoutDetails mt-3 flex items-center">
                      <img
                        src="/assets/og_icons/Fingerprint1.svg"
                        className="w-5 mr-4"
                        alt="fingerIcon"
                      />
                      <span className="text-[#122B46] text-[13px]">
                        Transaction ID:{' '}
                        {latestPayoutDetail && latestPayoutDetail.balance_transaction}
                      </span>
                    </div>

                    <div className="payoutDetails mt-3 flex items-center">
                      <img
                        src="/assets/og_icons/Error-1.svg"
                        className="w-6 mr-4"
                        alt="errorIcon"
                      />
                      <span className="text-[#122B46] text-[13px]">
                        It can 3-5 business days for the funds to show up in your account.
                      </span>
                    </div>
                  </div>
                  <div className="mt-20">
                    <button
                      type="button"
                      onClick={() => {
                        props?.handleModal(false, MODAL_CONSTS.NEW_TRANSFER)
                      }}
                      className="w-full h-12 mx-auto flex items-center justify-center py-2 border border-transparent rounded-xl shadow-sm text-sm font-medium text-[#1F417380] bg-SeabiscuitLightThemeColor focus:outline-none mt-2 uppercase"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewTransferModal
