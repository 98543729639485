const TAB_NAMES = {
  JUDGING: { label: 'Judging', value: 'judging' },
  ORDER: { label: 'Order of Go', value: 'order-of-go' },
  SCORING: { label: 'Scoring', value: 'scoring' },
  PRIZE_MONEY: { label: 'Prize Money', value: 'prize-money' },
}

const TABS = [
  { tab: TAB_NAMES.JUDGING.label, id: TAB_NAMES.JUDGING.value },
  { tab: TAB_NAMES.ORDER.label, id: TAB_NAMES.ORDER.value },
  { tab: TAB_NAMES.SCORING.label, id: TAB_NAMES.SCORING.value, disabled: true },
  { tab: TAB_NAMES.PRIZE_MONEY.label, id: TAB_NAMES.PRIZE_MONEY.value, disabled: true },
]

const PUBLISH_KEYS = {
  [TAB_NAMES.JUDGING.value]: 'publishJudging',
  [TAB_NAMES.ORDER.value]: 'publishOrder',
  [TAB_NAMES.SCORING.value]: 'publishScore',
  [TAB_NAMES.PRIZE_MONEY.value]: 'publishPrize',
}

export enum DateEnum {
  'h',
  'm',
  's',
  'f',
}

const COLUMNS_WIDTH: {
  [key: string]: string
} = {
  rider: '25%',
  horse: '46%',
  order: '8%',
  rideTime: '12%',
  drag: '9%',
}

export const MANAGE_OPERATIONS_CONSTS = {
  TABS,
  TAB_NAMES,
  PUBLISH_KEYS,
  COLUMNS_WIDTH,
}
