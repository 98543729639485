export const SheetFEIYoungRidersPreliminary = {
  id: 'SheetFEIYoungRidersPreliminary',
  name: 'FEI Young Riders Preliminary',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 300,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility- salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>MXK •</span> Extended trot</p><p><span>KA •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>N/A •</span> Transitions at M and K</p><p><span>KA •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DE •</span> Half pass to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>E •</span> Volte right (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>EH •</span> Shoulder-in right</p><p><span>HC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>C •</span> Collected walk</p><p><span>CM •</span> Collected walk</p><p><span>M •</span> Turn right</p><p><span>H •</span> Turn left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>HB(P) •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>Before P •</span> Collected walk</p><p><span>P •</span> Proceed in collected trot</p><p><span>PA •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DB •</span> Half pass to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>B •</span> Volte left (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>BM •</span> Shoulder-in left</p><p><span>MC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>C •</span> Proceed in collected canter left</p><p><span>CH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>HK •</span> Medium canter</p><p><span>K •</span> Collected canter</p><p><span>KAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>F •</span> Proceed towards X in collected canter</p><p><span>Between F&X •</span> Half pirouette to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>F •</span> Flying change of leg</p><p><span>FAK •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>KX •</span> Half-pass to the right</p><p><span>X •</span> Down the center line</p><p><span>I •</span> Flying change</p><p><span>C •</span> Turn left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>HXF •</span> On the diagonal 5 flying changes every 4th stride</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>FAK •</span> Collected canter</p><p><span>K •</span> Proceed towards X in collected canter</p><p><span>Between K&X •</span> Half pirouette to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>K •</span> Flying change of leg</p><p><span>KAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>FX •</span> Half-pass to the left</p><p><span>X •</span> Down the center line</p><p><span>I •</span> Flying change</p><p><span>C •</span> Turn right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description: '<p><span>MXK •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>K •</span> Collected canter and flying change of leg</p><p><span>KA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>A •</span> Down centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIYoungRidersTeam = {
  id: 'SheetFEIYoungRidersTeam',
  name: 'FEI Young Riders Team',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 320,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility- salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>MXK •</span> Medium trot</p><p><span>K •</span> Collected trot</p><p><span>KAF •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>FB •</span> Shoulder-in left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>B •</span> Volte left (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>BG •</span> Half-pass to the left</p><p><span>G •</span> On centre line</p><p><span>C •</span> Track to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>HXF •</span> Extended trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>N/A •</span> Transitions at H and F</p><p><span>FAK •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>KE •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>E •</span> Volte right (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>EG •</span> Half-pass to the right</p><p><span>G •</span> On centre line</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>Before C •</span> Collected walk</p><p><span>C •</span> Track to the left</p><p><span>H •</span> Turn left</p><p><span>Between G&M •</span> Half pirouette to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>Between G&H •</span> Half pirouette to the right</p><p><span>GM •</span> Collected walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>N/A •</span> The collected walk C-H-G-(M)-G-(H)-G-M</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>MRXV(K) •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>Before K •</span> Collected walk</p><p><span>K •</span> Proceed in collected canter left</p><p><span>KAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>FX •</span> Half-pass to the left</p><p><span>X •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>XM •</span> Half-pass to the right</p><p><span>M •</span> Flying change of leg</p><p><span>MCH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>H •</span> Proceed towards X in collected canter</p><p><span>Between H&X •</span> Half pirouette to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>HC •</span> Counter canter</p><p><span>C •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>M •</span> Proceed towards X in collected canter</p><p><span>Between M&X •</span> Half pirouette to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>MC •</span> Counter canter</p><p><span>C •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>HXF •</span> On the diagonal 5 flying changes of leg every 4th stride</p><p><span>FAK •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>KXM •</span> On the diagonal 5 flying changes of leg every 3rd stride</p><p><span>MCH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description: '<p><span>HXF •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>F •</span> Collected canter and flying change of leg</p><p><span>FA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIYoungRidersIndividual = {
  id: 'SheetFEIYoungRidersIndividual',
  name: 'FEI Young Riders Individual',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 320,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>AX •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility- salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the left</p><p><span>HE •</span> Shoulder-in left</p><p><span>E •</span> Turn left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>B •</span> Turn right</p><p><span>BF •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DB •</span> Half pass to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>B •</span> Volte left (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>BH •</span> Medium trot</p><p><span>H •</span> Collected trot</p><p><span>HC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>C •</span> Halt - immobility; Rein back 5 steps and immediately proceed in collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>MXK •</span> Extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>N/A •</span> Transitions at M and K</p><p><span>KA •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DE •</span> Half pass to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>E •</span> Volte right (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>EM •</span> Medium trot</p><p><span>M •</span> Collected trot</p><p><span>MC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>C •</span> Medium walk</p><p><span>CH •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>HB •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>B •</span> Collected walk</p><p><span>BPL •</span> Collected walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>L •</span> Proceed in collected canter left</p><p><span>LVKA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DE •</span> Half pass to the left</p><p><span>E •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>EG •</span> Half pass to the right</p><p><span>G •</span> On centre line</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>MXK •</span> On the diagonal 5 flying changes of leg every 4th stride</p><p><span>KAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>FX •</span> Collected canter</p><p><span>X •</span> Half pirouette to the left</p><p><span>XF •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>FA •</span> Counter canter</p><p><span>A •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>KX •</span> Collected canter</p><p><span>X •</span> Half pirouette to the right</p><p><span>XK •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>KA •</span> Counter canter</p><p><span>A •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>FXH •</span> On the diagonal 5 flying changes of leg every 3rd stride</p><p><span>HCM •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description: '<p><span>MXK •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>K •</span> Collected canter and flying change of leg</p><p><span>KA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}
