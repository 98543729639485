import React from 'react'

import EventFormContainerComponent from '../container/EventFormContainerComponent'
import SchedulingScreenEventSchedulingFormComponent from './SchedulingScreenEventSchedulingFormComponent'

interface EventSchedulingFormComponentProps {
  onValid: any
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  nextEventTab: string
  eventTab?: string
}

const EventSchedulingFormComponent: React.FC<EventSchedulingFormComponentProps> = (props) => {
  return (
    <EventFormContainerComponent>
      <SchedulingScreenEventSchedulingFormComponent
        onValid={props.onValid}
        onInvalid={props.onInvalid}
        onValidAndExit={props.onValidAnExit}
        onInvalidAndExit={props.onInvalidAndExit}
        handleModal={props.handleModal}
        nextEventTab={props.nextEventTab}
        eventTab={props.eventTab}
      />
    </EventFormContainerComponent>
  )
}

export default EventSchedulingFormComponent
