import React from 'react'

export const IconDelete: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
    <path
      fill="#122B46"
      fillOpacity="0.5"
      d="M8.25 0a.75.75 0 00-.717 1H5.32a2.75 2.75 0 00-2.288 1.225L1.85 4h-.1a.75.75 0 100 1.5h.383a.75.75 0 00.194.004l1.236 11.539A2.76 2.76 0 006.297 19.5h7.405a2.76 2.76 0 002.734-2.457l1.238-11.54a.75.75 0 00.19-.003h.386a.75.75 0 100-1.5h-.099l-1.183-1.775A2.752 2.752 0 0014.68 1h-2.213a.75.75 0 00-.717-1h-3.5zM5.32 2.5h9.36c.418 0 .807.208 1.04.557l.629.943H3.65l.63-.943v-.001c.232-.348.62-.556 1.04-.556zm-1.485 3h12.33l-1.22 11.383A1.241 1.241 0 0113.702 18H6.297a1.24 1.24 0 01-1.242-1.117L3.835 5.5z"
    ></path>
  </svg>
)
