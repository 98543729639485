import FirestoreService from './firestoreService'
import { CONST } from '../const/const'
import { where } from 'firebase/firestore'
import { HorseModel } from '../models/horse/horse.model'
import { HorseCompetitonPaperworkModel } from '../models/horse-competiton-paperwork/horse-competiton-paperwork.model'
import { IHorseData } from '../models/horse/horse.interface'
import { IHorseCompetitorDocument } from '../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { RegistrationByDayModel } from '../models/registrations-by-day/registrationByDay.model'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const getAllHorseInfo = async (userId: string, eventId: string) => {
  const competitionPaperworkSnap = await FirestoreService.filterItems(
    CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSE_COMPETITION_PAPERWORK.NAME
  )

  let competitionPaperwork = competitionPaperworkSnap?.docs.map((doc: any) => {
    return HorseCompetitonPaperworkModel.fromFirestoreDoc(doc).toObject()
  })

  const allHorsesSnap = await FirestoreService.filterItems(
    CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME
  )

  let allHorses = allHorsesSnap?.docs.map((doc: any) => {
    return HorseModel.fromFirestoreDoc(doc).toObject()
  })

  const registrationByDaySnap = await FirestoreService.filterItems(
    CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
    [
      where(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY,
        '==',
        eventId
      ),
      where(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.USER_ID.KEY,
        '==',
        userId
      ),
    ]
  )

  let registrationByDay = registrationByDaySnap?.docs.map((doc: any) => {
    return RegistrationByDayModel.fromFirestoreDoc(doc).toObject()
  })

  const registeredHorses: (IHorseData & { paperwork: IHorseCompetitorDocument })[] = []

  registrationByDay.forEach((registeredHorse) => {
    if (registeredHorse.horseId) {
      const filteredHorses = allHorses.filter((current) => current.id === registeredHorse.horseId)
      const filteredPaperwork = competitionPaperwork.filter(
        (current) => current.horseId === filteredHorses[0]?.horseId
      )
      registeredHorses.push({ ...filteredHorses[0], paperwork: filteredPaperwork[0] ?? [] })
    }
  })
  return registeredHorses ?? []
}

const HorseService = {
  getAllHorseInfo,
}

export default HorseService
