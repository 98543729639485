import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { EventNotificationsModel } from '../../../models/event-notifications/event-notifications.model'
import EventSendNotificationsFormComponent from '../../../components/events/forms/send-notifications/EventSendNotificationsFormComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface ShowsDetailPageEventSendNotificationsFormSubSectionProps {
  onSetEventTab: (tab: string) => void
  onSetNextEventTab: (tab: string) => void
  nextEventTab: string
  eventTab?: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsDetailPageEventSendNotificationsFormSubSection: React.FC<
  ShowsDetailPageEventSendNotificationsFormSubSectionProps
> = (props) => {
  const { eventId } = useParams<{ eventId: string }>()

  const [data, setData] = useState<any>(null)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (props.nextEventTab != '') {
      props.onSetEventTab(props.nextEventTab)
    }
  }, [props.nextEventTab])

  const validFormHandler = async (data: any) => {
    const updated_data = new EventNotificationsModel({
      ...data,
    })

    FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_NOTIFICATIONS.NAME,
      eventId,
      updated_data.toFirestore()
    )
      .then((_: any) => {
        setData(updated_data.toObject())
        props.onSetNextEventTab('')
      })
      .catch((reason) => {
        alert('202206261334')
        console.error('202206261334:' + reason)
      })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidFormHandler = async (data: any) => {
    props.onSetNextEventTab('')
    alert('invalid')
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        const doc = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_NOTIFICATIONS.NAME,
          eventId
        )
        if (doc) {
          setData(EventNotificationsModel.fromFirestoreDoc(doc).toObject())
        }
      }
      fetchData().catch()
    }
  }, [data, setData, eventId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <EventSendNotificationsFormComponent
        data={data}
        onValid={validFormHandler}
        onInvalid={invalidFormHandler}
        nextEventTab={props.nextEventTab}
      />
    </>
  )
}

export default ShowsDetailPageEventSendNotificationsFormSubSection
