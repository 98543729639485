// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useContext, useState } from 'react'
import { SignupStages } from './UserRegistrationViewComponentContextApi'
import UserRegistrationViewComponentButton from './global/UserRegistrationViewComponentButton'
import UserRegistrationViewComponentInput from './global/UserRegistrationViewComponentInput'

import backIcon from './assets/arrow-left.svg'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const UserRegistrationViewComponentSignupForm: React.FC = () => {
  const [stage, setStage] = useContext(SignupStages)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  const [signUpForm, setSignUpForm] = useState({
    username: '',
    email: '',
    password: '',
    password2: '',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div>
      <div className="text-[24px] flex items-center gap-2">
        <button onClick={() => stageHandle('Init')}>
          <img src={backIcon} alt="back" />
        </button>
      </div>
      <div>
        <h1 className="text-[24px] font-norma text-[#120D26]">Sign up</h1>
      </div>
      <div className="mt-6">
        <UserRegistrationViewComponentInput
          type="text"
          value={signUpForm.username}
          onChange={(e: any) => setSignUpForm({ ...signUpForm, username: e.target.value })}
          placeholder="Full name"
          icon="/assets/og_icons/User-4.svg"
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="email"
          value={signUpForm.email}
          onChange={(e: any) => setSignUpForm({ ...signUpForm, email: e.target.value })}
          placeholder="abc@email.com"
          icon="/assets/og_icons/Mail-4.svg"
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="password"
          value={signUpForm.password}
          onChange={(e: any) => setSignUpForm({ ...signUpForm, password: e.target.value })}
          placeholder="Your password"
          icon="/assets/og_icons/Lock-4.svg"
          className="mt-4"
        />
        <UserRegistrationViewComponentInput
          type="password"
          value={signUpForm.password2}
          onChange={(e: any) => setSignUpForm({ ...signUpForm, password2: e.target.value })}
          placeholder="Confirm password"
          icon="/assets/og_icons/Lock-4.svg"
          className="mt-4"
        />
      </div>
      <div className="w-[70%] mt-[60px] mx-auto">
        <UserRegistrationViewComponentButton
          caption="SIGN UP"
          type={1}
          disabled={!stage.selector}
          onClick={() => stageHandle('Landing')}
        />
      </div>
      <div className="text-center text-[#777E85] mt-10">OR</div>
      <div className="text-[#120D26] my-10 text-center">
        Already have an account?{' '}
        <a className="text-SeabiscuitMainThemeColor" href="/login">
          Sign in
        </a>
      </div>
    </div>
  )
}

export default UserRegistrationViewComponentSignupForm
