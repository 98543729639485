/* eslint-disable no-useless-escape */
import { where } from 'firebase/firestore'
import { cloneDeep, isObject } from 'lodash'
import moment, { MomentInput } from 'moment'
import { CONST } from '../const/const'
import { MESSAGES_CONST } from '../const/messages-const'
import { EventModel } from '../models/events/event.model'
import { getConvertedData, selectObjToString } from '../models/interface.helper'
import { UserDocumentModel } from '../models/user-documents/user-documents.model'
import { IUserInterface } from '../models/users/user.interface'
import { UserModel } from '../models/users/user.model'
import FirestoreService from '../services/firestoreService'
import { httpService } from '../services/httpService'
import { ISelectedEvent } from '../store/events/eventsSlice'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

// Types

type IFileObjToBase64Fn = (file: File) => Promise<string | ArrayBuffer | null>
type splitFileNameAndExtFn = (
  fullFileName: string,
  addDotToExtension: boolean
) => {
  fileName: string
  extension: string
}

type ICustomErrorProperties = {
  message: string
  fileName?: string | null
  devMessage?: string | null
  lineNumber?: number | null
  moduleName?: string | null
}

export type IEvaluatedFeeItem = {
  units?: number
  cost: number
  sold: number
  note: string
  name: string
  status: boolean
  duration: string
  category: string
  available: number
  costAlias: string
  uuid: string
} | null

interface ICapitalizeOptions {
  /** @info Default `true`, if `true` then capitalizes first letter of each word  */
  capitalizeAll?: boolean
  /** @info Default `false`, if `true` then capitalizes each letter of each word  */
  fullyCapitalize?: boolean
  /** @info If `valueToGenerateStringFrom` is a falsy value, then returnValueIfNotString is returned if passed  */
  returnValueIfNotString?: any
}

type ICapitalizeReturnValue<T extends ICapitalizeOptions> =
  T['returnValueIfNotString'] extends Exclude<any, undefined> ? string : T['returnValueIfNotString']

type ICapitalize = <T extends ICapitalizeOptions>(
  valueToCapitalize: any,
  options?: T
) => ICapitalizeReturnValue<T>

type ICreateString = (
  valueToGenerateStringFrom: (string | null | undefined)[] | string | null | undefined,
  seperator?: string
) => string

export interface IReactAutoComplete {
  address_components: [
    { long_name: string; short_name: string; types: [string, string] },
    { long_name: string; short_name: string; types: string[] },
    { long_name: string; short_name: string; types: string[] },
    { long_name: string; short_name: string; types: string[] },
  ]
  formatted_address: string
  geometry: {
    location: {
      lat: () => number
      lng: () => number
    }
  }
  place_id: string
  html_attributions: []
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
class CustomError {
  message: ICustomErrorProperties['message']
  fileName: ICustomErrorProperties['fileName']
  lineNumber: ICustomErrorProperties['lineNumber']
  devMessage: ICustomErrorProperties['devMessage']
  moduleName: ICustomErrorProperties['moduleName']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  constructor(args: ICustomErrorProperties) {
    const { message, fileName, lineNumber, devMessage, moduleName } = args
    this.fileName = fileName ?? null
    this.lineNumber = lineNumber ?? null
    this.moduleName = moduleName ?? null
    this.message = (message as any)?.message ?? message
    this.devMessage = (devMessage as any)?.message ?? devMessage ?? message ?? null
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static common(message: string) {
    return new CustomError({ message })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static somethingWentWrong(args: ICustomErrorProperties) {
    return new CustomError(args)
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @info Converts image url to base64
 */
const fileObjToBase64: IFileObjToBase64Fn = async (file) => {
  return await new Promise((resolve) => {
    var reader = new FileReader()
    reader.onloadend = function () {
      resolve(reader.result)
    }
    reader.readAsDataURL(file)
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @param place Details got from React auto complete library
 * @info Sets from values according to the details got from third party lib
 */
const handleLocation = (
  place: google.maps.places.PlaceResult | React.FormEvent<HTMLInputElement>
) => {
  if (typeof place === 'object') {
    let data = place as any as IReactAutoComplete
    let lat = data?.geometry?.location?.lat()
    let lng = data?.geometry?.location?.lng()
    let formatted_address = data?.formatted_address
    if (lat && lng && formatted_address) {
      return {
        lat: data?.geometry?.location?.lat(),
        lng: data?.geometry?.location?.lng(),
      }
    }
  }

  return null
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * Retrieves the user's current geolocation coordinates (latitude and longitude).
 *
 * @returns {Promise<{ lat: number, lng: number }>} A promise that resolves with an object
 * containing the user's latitude and longitude or rejects with an error if geolocation is
 * unavailable or an error occurs.
 *
 * @throws {Error} If geolocation is not available or if an error occurs during retrieval.
 *
 * This function relies on the Geolocation API provided by modern web browsers.
 */
function getCurrentLocation(): Promise<{ lat: number; lng: number }> {
  return new Promise((resolve, reject) => {
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const lat = position.coords.latitude
          const lng = position.coords.longitude
          resolve({ lat, lng })
        },
        function (error) {
          reject(error)
        }
      )
    } else {
      reject(new Error('Geolocation is not available.'))
    }
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @param fullFileName Name of the file with or withnot extension
 * @param addDotToExtension If true, returns extension with '.' Dot prefixed
 * @returns Object containing filname and extension
 */
const splitFileNameAndExt: splitFileNameAndExtFn = (fullFileName, addDotToExtension) => {
  const valueToReturn: { fileName: string; extension: string } = {
    fileName: fullFileName,
    extension: '',
  }

  let lastStrSegment: any = valueToReturn.fileName?.split('/')
  lastStrSegment = lastStrSegment?.[lastStrSegment?.length - 1] as any
  const tempFileNameSegments = lastStrSegment?.split('.') ?? []

  valueToReturn.fileName = tempFileNameSegments.slice(0, tempFileNameSegments.length - 1).join('-')
  valueToReturn.extension = tempFileNameSegments[tempFileNameSegments.length - 1] ?? ''

  if (addDotToExtension) valueToReturn.extension = '.' + valueToReturn.extension

  return valueToReturn
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const AnyArrayBufferToPngArrayBuffer = (arrayBuffer: ArrayBuffer): Promise<ArrayBuffer> => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const blob = new Blob([arrayBuffer])
    const url = URL.createObjectURL(blob)

    const image = new Image()
    image.src = url

    image.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height

      const context = canvas.getContext('2d')

      if (context) {
        context.drawImage(image, 0, 0)
        canvas.toBlob(
          (pngBlob) => {
            if (pngBlob) {
              const reader = new FileReader()
              reader.onloadend = () => {
                const pngArrayBuffer = reader.result as ArrayBuffer
                resolve(pngArrayBuffer)
              }
              reader.onerror = reject
              reader.readAsArrayBuffer(pngBlob)
            } else {
              reject(new Error('Failed to create PNG blob.'))
            }
          },
          'image/png',
          1
        )
      }
    }

    image.onerror = reject
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ArrayBufferToPngBase64 = (arrayBuffer: ArrayBuffer) => {
  return new Promise<any>((resolve) => {
    const blob = new Blob([arrayBuffer])
    const reader = new FileReader()
    reader.onloadend = () => {
      const dataUrl = reader.result
      if (dataUrl) {
        const base64String = (dataUrl as string).split(',')[1]
        resolve(base64String)
      }
    }
    reader.readAsDataURL(blob)
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const getEmail = async (emailOrUserName: string): Promise<string | null | unknown> => {
  let email: string | null = null

  try {
    if (!!!emailOrUserName) throw CustomError.common(MESSAGES_CONST.USER_NAME_OR_EMAIL_REQUIRED)

    const emailQuery = FirestoreService.filterItems(COLLECTIONS.USERS.NAME, [
      where(COLLECTIONS.USERS.FIELDS.USER_EMAIL.KEY, '==', emailOrUserName.toLowerCase()),
    ])

    const userNameQuery = FirestoreService.filterItems(COLLECTIONS.USERS.NAME, [
      where(COLLECTIONS.USERS.FIELDS.USER_NAME.KEY, '==', emailOrUserName.toLowerCase()),
    ])

    const [emailBasedSnaps, userBasedSnaps] = await Promise.all([emailQuery, userNameQuery])
    const combinedSnaps = [...emailBasedSnaps.docs, ...userBasedSnaps.docs]

    if (!combinedSnaps.length) throw CustomError.common(MESSAGES_CONST.USER_NOT_FOUND)

    const user = UserModel.fromFirestoreDoc(combinedSnaps[0]).toObject()
    email = user.userEmail

    return email
  } catch (error) {
    return error
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const isUsernameUnique = async (userName: string): Promise<unknown> => {
  try {
    if (!userName) throw new Error(MESSAGES_CONST.USER_NAME_REQUIRED)

    const userNameSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS.NAME, [
      where(
        COLLECTIONS.USERS.FIELDS.USER_NAME_NGRAMS.KEY,
        'array-contains',
        userName.toLowerCase()
      ),
    ])

    if (userNameSnaps.size) throw CustomError.common(MESSAGES_CONST.USER_NAME_EXISTS)

    return
  } catch (error) {
    return error
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const generateName = (fullName: string) => {
  return fullName
    .split(' ')
    .map((name) => name[0])
    .join('')
    .toUpperCase()
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const getEvaluatedFeeItem = (item: any): IEvaluatedFeeItem => {
  let cost: number = 0
  let sold: number = 0
  let name: string = ''
  let note: string = ''
  let uuid: string = ''
  let duration: string = ''
  let category: string = ''
  let costAlias: string = ''
  let available: number = 0
  let status: boolean = false
  let costMatchesWithAlias: boolean = false

  if (!item) return null

  if (item.hasOwnProperty('category') && typeof item.category === 'string' && !!item.category)
    category = item.category

  if (item.hasOwnProperty('uuid') && typeof item.uuid === 'string' && !!item.uuid) uuid = item.uuid

  if (item.hasOwnProperty('available') && typeof item.available === 'number')
    available = item.available
  else available = 0

  if (item.hasOwnProperty('sold') && typeof item.sold === 'number') sold = item.sold
  else sold = 0

  if (item.hasOwnProperty('status') && typeof item.status === 'boolean') status = item.status

  if (
    (item.hasOwnProperty('name') && typeof item.name === 'string' && !!item.name) ||
    (item.hasOwnProperty('title') && typeof item.title === 'string' && !!item.title)
  ) {
    name = item.name ?? item.title
  }

  if (item.hasOwnProperty('cost') && typeof item.cost === 'number' && !!item.cost) cost = item.cost

  if (
    item.hasOwnProperty('costAlias') &&
    typeof item.costAlias === 'string' &&
    !!Number(costAlias.replace(/[^0-9\..]+/gi, ''))
  )
    costAlias = item.costAlias
  else costAlias = '$0'

  if (item.hasOwnProperty('note') && typeof item.note === 'string' && !!item.note) note = item.note
  else note = ''

  if (item.hasOwnProperty('duration') && !!item.duration) {
    if (typeof item?.duration === 'object') duration = selectObjToString(item.duration)
    else duration = item.duration
  } else duration = 'N/A'

  if (!!cost && !!costAlias)
    costMatchesWithAlias = cost === Number(costAlias.replace(/[^0-9\..]+/gi, ''))

  if (!(!!category && cost > 0 && costMatchesWithAlias && !!name && status && !!uuid)) return null

  return {
    cost,
    name,
    sold,
    note,
    uuid,
    status,
    category,
    duration,
    costAlias,
    available: available - sold > available ? 0 : available - sold,
  }
}

const calculateTotalOrganizerRevenue = (selectedEvent: ISelectedEvent) => {
  let totalFeesRevenue: number = 0
  if (isObject(selectedEvent?.EventFees) && Object.keys(selectedEvent?.EventFees).length > 0) {
    Object.keys(selectedEvent?.EventFees).forEach((item) => {
      const currObj = (selectedEvent as any)?.EventFees[item]
      if (currObj?.available && Number(currObj?.available) > 0 && Number(currObj?.cost) > 0) {
        totalFeesRevenue += Number(currObj?.available) * Number(currObj?.cost)
      }
    })
  }

  let totalRegistrationrevenue: number = 0
  if (
    selectedEvent?.Event?.eventPriceUSD &&
    selectedEvent?.Event?.registrationAvailableCount &&
    Number(selectedEvent?.Event?.eventPriceUSD) > 0 &&
    Number(selectedEvent?.Event?.registrationAvailableCount)
  ) {
    totalRegistrationrevenue =
      Number(selectedEvent?.Event?.eventPriceUSD) *
      Number(selectedEvent?.Event?.registrationAvailableCount)
  }

  let totalTicketsRevenue: number = 0
  if (selectedEvent?.EventTickets?.tickets && selectedEvent?.EventTickets?.tickets?.length > 0) {
    selectedEvent?.EventTickets?.tickets.forEach((item) => {
      if (Number(item?.available) > 0) {
        totalTicketsRevenue += Number(item?.available) * Number(item?.cost)
      }
    })
  }

  return totalFeesRevenue + totalRegistrationrevenue + totalTicketsRevenue
}

const checkRidorIsMinor = async (riderId: string) => {
  let rider_db = await FirestoreService.getItem(COLLECTIONS.USERS.NAME, riderId)

  if (rider_db.exists()) {
    const rider = getConvertedData(UserModel.fromFirestoreDoc(rider_db).toObject())

    const isAdult = (birthdate: MomentInput) => {
      const age = moment().diff(moment(birthdate), 'years')
      const adultAgeThreshold = 18

      return age >= adultAgeThreshold
    }

    return { isMinor: isAdult(rider.userDOB), riderName: getUserFullName(rider) }
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * Capitalizes a string according to the provided options.
 *
 * @param args - The configuration options.
 * @param valueToCapitalize - The value to capitalize
 * @returns The capitalized string or the specified fallback value.
 *
 * In default value for `capitalizeAll` is `true`
 * If `valueToCapitalize` is not a string, the function returns `args.returnValueIfNotString`
 * if it's provided; otherwise, it returns an empty string. If `args.capitalizeAll` is `true`,
 * all letters in the string are capitalized; otherwise, only the first letter is capitalized.
 * if `args.fullyCapitalize` is `true` then all the letters of the passed string would be capitalized.
 */
const capitalize: ICapitalize = (valueToCapitalize = '', args) => {
  let splittedString: string[] = []
  let { returnValueIfNotString, capitalizeAll = true, fullyCapitalize = false } = args ?? {}

  if (typeof valueToCapitalize !== 'string') {
    if (returnValueIfNotString) return returnValueIfNotString
    else return ''
  }

  splittedString = valueToCapitalize?.split(' ')

  if (capitalizeAll)
    splittedString = splittedString.map((currChunk) => {
      return currChunk.length
        ? `${currChunk.substring(0, 1).toUpperCase()}${currChunk.substring(1)}`
        : currChunk
    })
  else if (splittedString?.[0])
    splittedString[0] = splittedString[0].length
      ? `${splittedString[0].substring(0, 1).toUpperCase()}${splittedString[0].substring(1)}`
      : splittedString[0].toUpperCase()

  return fullyCapitalize ? splittedString.join(' ')?.toUpperCase() : splittedString.join(' ')
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const getUserFullName = (args: {
  userName?: IUserInterface['userName'] | null
  userLastName?: IUserInterface['userLastName'] | null
  userFirstName?: IUserInterface['userFirstName'] | null
}): string => {
  let fullName = ''

  if (!!args.userFirstName) fullName = args.userFirstName.trim()

  if (!!fullName && !!args.userLastName)
    fullName = `${args.userFirstName} ${args.userLastName}`.trim()

  if (!!!fullName) fullName = args.userName?.trim() ?? ''

  return capitalize(fullName.trim())
}

const sendSignEmail = async (data: any, userData: IUserInterface) => {
  try {
    let data_enter = {
      signData: [
        {
          receiverMail: data.signatoryEmail,
          receiverName: data.signatoryName,
          signatoryId: data.signatoryId,
          signatoryRole: data.signatoryDefaultRole,
          paperworkName: data.documentOriginalName,
          paperworkUrl: data.documentUrl,
          isMinor: data.isMinor,
          riderName: data.riderName,
          riderId: data.riderId,
        },
      ],
      senderData: {
        senderName: `${userData?.userFirstName} ${userData?.userLastName}`,
        senderEmail: `${userData?.userEmail}`,
      },
      eventId: data.eventId,
      eventDraftId: data.event_register_id,
      email_type: 'remainder',
    }
    const response: any = await httpService({
      url: 'sendsignmail',
      method: 'POST',
      data: data_enter,
    })

    if (response.status) {
      const updatedocumentDb = async () => {
        try {
          const updateData = cloneDeep(data)
          updateData.reminder = true
          const user_doc = new UserDocumentModel(updateData).toFirestore()

          await FirestoreService.updateItem(
            COLLECTIONS.USERS_DOCUMENTS.NAME,
            updateData.id,
            user_doc
          )
          return true
        } catch (err) {
          console.log(err)
          return false
        }
      }

      const update_status = await updatedocumentDb()
      return update_status
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

const getOwnerByEventId = async (eventId: string) => {
  try {
    const event_data = await FirestoreService.getItem(COLLECTIONS.EVENTS.NAME, eventId)
    if (event_data.exists()) {
      const event = getConvertedData(EventModel.fromFirestoreDoc(event_data).toObject())
      if (event.owner) {
        const get_owner = await FirestoreService.getItem(COLLECTIONS.USERS.NAME, event.owner)
        const owner = getConvertedData(UserModel.fromFirestoreDoc(get_owner).toObject())
        return owner
      }
    }
    return false
  } catch (error) {
    return false
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * Creates a string from an array of values or a single value, optionally separated by a specified separator.
 *
 * @param valueToGenerateStringFrom - An array of strings, or a single string, to generate the output string from.
 * @param separator - (Optional) The separator to be used between individual values when generating the string. Default is ", ".
 * @returns A string generated from the input values, separated by the specified separator.
 *
 * @example
 * // Generate a string from an array of values with default separator
 * const result1 = createString(["apple", "banana", "cherry"]);
 * // Result: "apple, banana, cherry"
 *
 * @example
 * // Generate a string from a single value with a custom separator
 * const result2 = createString("12345", "-");
 * // Result: "1-2-3-4-5"
 *
 * @example
 * // Generate a string from an array of values with custom separator
 * const result3 = createString(["Alice", null, "Bob", undefined, "Charlie"], " | ");
 * // Result: "Alice | Bob | Charlie"
 */
const createString: ICreateString = (valueToGenerateStringFrom, seperator = ', ') => {
  let valueToReturn = ''

  if (Array.isArray(valueToGenerateStringFrom))
    valueToReturn = valueToGenerateStringFrom
      .filter((currChunkOfPassedValue) => {
        return !!currChunkOfPassedValue
      })
      .join(seperator)

  if (typeof valueToReturn === 'number') valueToReturn = `${valueToReturn}`
  else if (typeof valueToGenerateStringFrom === 'string') valueToReturn = valueToGenerateStringFrom

  return valueToReturn.replace(/\s+/g, ' ').trim()
}

const formatDate = (dateString: any): string => {
  const date = new Date(dateString)

  // Get the month, day, and year from the date object
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are 0-indexed, so we add 1
  const day = date.getDate().toString().padStart(2, '0')
  const year = date.getFullYear().toString()

  // Create the formatted date string in the MM/DD/YYYY format
  const formattedDate = `${month}/${day}/${year}`

  return formattedDate
}

export const toFixed = (number: number, decimals = 2) => {
  return Math.round(number * 10 ** decimals) / 10 ** decimals
}

const authHelpers = {
  getEmail,
  isUsernameUnique,
}

export {
  AnyArrayBufferToPngArrayBuffer,
  ArrayBufferToPngBase64,
  CustomError,
  handleLocation,
  getCurrentLocation,
  getEvaluatedFeeItem,
  generateName,
  authHelpers,
  calculateTotalOrganizerRevenue,
  capitalize,
  checkRidorIsMinor,
  createString,
  fileObjToBase64,
  getOwnerByEventId,
  getUserFullName,
  sendSignEmail,
  splitFileNameAndExt,
  formatDate,
}
