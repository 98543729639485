// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import L from 'leaflet'

import { GoogleMap, MarkerF } from '@react-google-maps/api'
import marker2x from 'leaflet/dist/images/marker-icon-2x.png'
import marker from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/leaflet.css'

delete L.Icon.Default.prototype['_getIconUrl' as any as keyof L.Icon.Default]

L.Icon.Default.mergeOptions({
  iconRetinaUrl: marker2x,
  iconUrl: marker,
  shadowUrl: markerShadow,
})

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentMap: React.FC<{ eventData?: any }> = (props) => {
  const center = (function () {
    let lat = isNaN(props?.eventData?.competitionLat) ? false : +props?.eventData?.competitionLat
    let lng = isNaN(props?.eventData?.competitionLong) ? false : +props?.eventData?.competitionLong
    if (!lat || !lng) return
    return {
      lat,
      lng,
    }
  })()

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  return (
    <div className="overflow-hidden">
      {props?.eventData?.competitionLat && (
        <div className="overflow-hidden rounded-[8px]">
          <div className="h-[400px] w-full">
            <GoogleMap zoom={10} center={center} mapContainerClassName="h-[100%]">
              {center ? (
                <MarkerF
                  position={center}
                  title={
                    props?.eventData?.competitionAddressLine1 ?? props?.eventData?.competitionName
                  }
                  icon="/assets/cp_icons/FinalMapPin.svg"
                />
              ) : null}
            </GoogleMap>
          </div>
        </div>
      )}
      <h4 className="text-SeabiscuitDark200ThemeColor text-[16px] my-[20px] font-bold">
        Directions
      </h4>
      <p className="text-SeabiscuitDark200ThemeColor text-[16px] mb-10">
        {props?.eventData?.competitionLocationNotes ?? ''}
      </p>
    </div>
  )
}

export default EventDetailViewComponentMap
