// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useState } from 'react'
import UserRegistrationViewComponentOfferDrawer from './UserRegistrationViewComponentOfferDrawer'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const UserRegistrationViewComponentLandingContainingDrawer: React.FC = () => {
  const [show, setShow] = useState(false)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div>
      <div className="text-[26px] text-gray-400 text-center">LANDING DESIGN</div>
      <div className="text-[26px] text-gray-300 text-center">LANDING DESIGN</div>
      <div className="text-[26px] text-gray-200 text-center">LANDING DESIGN</div>
      <div className="text-[26px] text-gray-100 text-center">LANDING DESIGN</div>
      <UserRegistrationViewComponentOfferDrawer show={show} onHide={() => setShow(false)} />
    </div>
  )
}

export default UserRegistrationViewComponentLandingContainingDrawer
