import { useEffect, useState } from 'react'

// Component imports
import ConfirmationCompsWrapper from './ConfirmationCompsWrapper'

// Redux
import { IEventTickets } from '../../../../../../models/event-details/event-details.interface'

// Types
import clsx from 'clsx'
import { cloneDeep, sumBy } from 'lodash'
import helpers from '../../../../../../commonHelpers/helpers'
import { CONST } from '../../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../../const/messages-const'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'
import { CustomError } from '../../../../../../helpers/helpers'
import { IRegistrationFeesInterface } from '../../../../../../models/registration-fees/registrationFees.interface'
import { IRegistrationTicketInterface } from '../../../../../../models/registration-tickets/registrationTicket.interface'
import { IRegistrationByDayInterface } from '../../../../../../models/registrations-by-day/registrationByDay.interface'
import { ITeamMember } from '../../../../../../models/users/user.interface'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import {
  selectPayTabTicketAccordion,
  selectRegistrationByDay,
  selectRegistrationTicketsR,
  selectTicketTabData,
  updateTicketTabRowInReduxAc,
} from '../../../../../../store/registration/registrationSlice'
import { IHandlePaymentDivided } from '../../../../competitorEventRegister/Tabs/CompetitorEventRegisterPayTab'
import SelectRecipient from './SelectRecipient'
import { EVENT_REGISTERED_CONST } from '../../../../../../models/event-registered-users/event-registered-users.constants'
import { IManageInfo } from '../../../../event-registration-tabs/EventRegistrationTabs'

type IProps = {
  setEventTab?: any
  splitPayment?: boolean
  selectedTicketsCount: number
  selectedTicketsTotal: number
  selectedRecipientCount?: number
  selectedTicketsUnitsCount: number
  selectedEventsTickets: IEventTickets[]
  membersAddedInRegistration?: ITeamMember[]
  handlePaymentDivided?: IHandlePaymentDivided
  manageInfo: IManageInfo
  isManage?: boolean
}

type IHandleRecipientChange = (args: IHandleRecipientChangeArgs) => void

type IHandleRecipientChangeArgs = {
  /** @info Id of the item that is holding the select list */
  docId: IRegistrationFeesInterface['id']
  /** @info Id of the doc containing selected member */
  selectedDocId: IRegistrationByDayInterface['id']
  memberId: string | null
  itemIndexInList?: number | null
}

const FILE_NAME = 'SelectedEventTicketList'
const CUSTOM_ERROR_PROPS = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

const SelectedEventTicketList = (props: IProps) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const splitPayment = props.splitPayment
  const toastFunctions = useToasterHelper()
  const ticketsTabData = useAppSelector(selectTicketTabData)
  const registrationsByDay = useAppSelector(selectRegistrationByDay)
  const registrationTickets = useAppSelector(selectRegistrationTicketsR)
  const ticketAccordionData = useAppSelector(selectPayTabTicketAccordion)

  const [open, setOpen] = useState(false)
  const [selectedUnits, setSelectedUnits] = useState<{
    totalItems: number
    totalPrice: number
  }>({ totalItems: 0, totalPrice: 0 })

  let heading = [
    { title: 'Line item', tooltipText: 'Tickets you added during registration' },
    { title: 'Number of units', tooltipText: 'Number of tickets you added per ticket item' },
    { title: 'Price per unit', tooltipText: 'Price per individual ticket' },
    // { title: "Total price", tooltipText: "Select a team member to send invoice for this line item" }
  ]

  if (splitPayment) {
    heading = [...heading, { title: 'Invoice recipient', tooltipText: '' }]
  }

  const handleRecipientChange: IHandleRecipientChange = (args) => {
    try {
      let registrationTicketRowIndex = -1
      let selectedRegistrationByDayIndex = -1
      let mutatedRegistrationTicket: null | IRegistrationTicketInterface = null
      let selectedRegistrationByDayInDb: null | IRegistrationByDayInterface = null

      selectedRegistrationByDayIndex = registrationsByDay.findIndex((currRegistrationByDay) => {
        return currRegistrationByDay.id === args.selectedDocId
      })

      selectedRegistrationByDayInDb = cloneDeep(
        registrationsByDay?.[selectedRegistrationByDayIndex]
      )
      registrationTicketRowIndex = Number(args.docId)
      mutatedRegistrationTicket =
        ticketsTabData?.[registrationTicketRowIndex]?.registrationTicket ?? null

      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal(
        {
          docId: args.docId,
          mutatedRegistrationTicket,
          selectedRegistrationByDayInDb,
          selectedDocId: args.selectedDocId,
        },
        ['0']
      )

      if (emptyVarName)
        throw CustomError.somethingWentWrong({
          ...CUSTOM_ERROR_PROPS,
          moduleName: 'handleRecipientChange',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
        })

      mutatedRegistrationTicket = {
        ...(mutatedRegistrationTicket as any),
        update: true,
        isPaidByOwner: false,
        recipientId: selectedRegistrationByDayInDb.riderId,
        recipientName: selectedRegistrationByDayInDb.riderName,
        recipientNameNGram: selectedRegistrationByDayInDb.riderNameNGram,
        recipientProfilePicture: selectedRegistrationByDayInDb.riderProfilePicture,
      }

      dispatch(
        updateTicketTabRowInReduxAc({
          ticketTabRowIndex: registrationTicketRowIndex,
          dataToUpdate: mutatedRegistrationTicket,
        })
      )
    } catch (error: any) {
      toastFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        message: error,
      })
    }
  }

  useEffect(() => {
    if (props.isManage) {
      const tickets = props.manageInfo.tickets

      const totalItems = parseFloat(
        sumBy(tickets, (ticket) => ticket.registrationTicket?.selectedUnitsCount ?? 0).toFixed(2)
      )

      const totalPrice = parseFloat(
        sumBy(
          tickets,
          (ticket) =>
            (ticket.registrationTicket?.selectedUnitsCount ?? 0) *
            (ticket.registrationTicket?.ticketPrice ?? 0)
        ).toFixed(2)
      )
      setSelectedUnits({
        totalItems,
        totalPrice,
      })
    } else {
      setSelectedUnits({
        totalItems: ticketAccordionData.totalItemsCount,
        totalPrice: parseFloat(ticketAccordionData.totalPrice.toFixed(2)),
      })
    }
  }, [props.isManage, props.manageInfo.tickets, ticketAccordionData])

  return (
    <ConfirmationCompsWrapper
      title="Tickets"
      id={FILE_NAME}
      setEventTab={props.setEventTab}
      redirectTab={CONST.UI.REGISTER.TABS.TICKETS}
      accordion={{ open, setOpen }}
      selected_units={`${selectedUnits.totalItems} ${props.isManage ? 'new' : ''} ${selectedUnits.totalItems > 1 ? 'Tickets' : 'Ticket'}, $${selectedUnits.totalPrice}`}
      showList={!!registrationTickets.length}
      colsClassName={
        splitPayment
          ? {
              0: '!w-1/4 flex items-center',
              1: '!w-1/4 flex items-center',
              2: '!w-1/4 flex items-center',
              3: '!w-1/4 flex items-center',
            }
          : {
              0: '!w-1/3 flex items-center',
              1: '!w-1/3 flex items-center',
              2: '!w-1/3 flex items-center',
            }
      }
      cols={heading}
    >
      <>
        {[...ticketsTabData, ...props.manageInfo.tickets].map((ticketRow, index) => {
          if (ticketRow.registrationTicket) {
            return (
              <div
                key={`${JSON.stringify(ticketRow)}${index}`}
                className="flex flex-col lg:flex-row items-center justify-between w-full mb-2"
              >
                <div className="lg:hidden w-full mb-1 font-medium text-[12px]">Line Item</div>

                <p
                  className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor line-clamp-1 w-full lg:w-1/${splitPayment ? '4' : '3'} p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
                >
                  {ticketRow.ticketTitle}
                </p>
                <div className="lg:hidden w-full mb-1 font-medium text-[12px] mt-2">
                  Number Of Units
                </div>

                <p
                  className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor line-clamp-1 w-full lg:w-1/${splitPayment ? '4' : '3'} p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center`}
                >
                  {ticketRow?.registrationTicket?.selectedUnitsCount ?? 0}
                </p>
                <div className="lg:hidden w-full mb-1 font-medium text-[12px] mt-2">
                  Price Per Unit
                </div>

                <p
                  className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor line-clamp-1 w-full lg:w-1/${splitPayment ? '4' : '3'} p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center`}
                >
                  {ticketRow.registrationTicket?.paymentStatus ===
                  EVENT_REGISTERED_CONST.PAYMENT_STATUS.PAID
                    ? EVENT_REGISTERED_CONST.PAYMENT_STATUS.PAID
                    : ticketRow.registrationTicket?.paymentStatus ===
                        EVENT_REGISTERED_CONST.PAYMENT_STATUS.CASH
                      ? EVENT_REGISTERED_CONST.PAYMENT_STATUS.CASH
                      : `$${ticketRow.ticketPrice}`}
                </p>

                {splitPayment ? (
                  <SelectRecipient
                    id={FILE_NAME}
                    docId={`${index}`}
                    itemIndexInList={index}
                    handleRecipientChange={handleRecipientChange}
                    recipientId={ticketRow.registrationTicket?.recipientId}
                    isLastIndex={
                      ticketRow.ticketItemId === registrationTickets.at(-1)?.ticketItemId
                    }
                    membersAddedInRegistration={props.membersAddedInRegistration ?? []}
                  />
                ) : null}
              </div>
            )
          }
        })}

        {/* Total */}
        <div className="flex items-start justify-start w-full mb-2 font-semibold text-SeabiscuitGreenThemeColor text-center">
          <p
            className={clsx(
              `flex-grow p-4 text-left mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor`,
              splitPayment ? 'w-1/4' : 'w-1/3'
            )}
          >
            {props.isManage && 'New'} Total
          </p>

          <p
            className={clsx(
              `flex-grow p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor`,
              splitPayment ? 'w-1/4' : 'w-1/3'
            )}
          >
            {selectedUnits.totalItems}
          </p>

          <p
            className={clsx(
              `flex-grow p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor`,
              splitPayment ? 'w-1/4' : 'w-1/3'
            )}
          >
            ${selectedUnits.totalPrice ?? 0}
          </p>

          {splitPayment ? (
            <p
              className={`w-[calc(25%-8px)] p-4 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor`}
            >
              {`${ticketAccordionData.uniqueRecipientCount} ${(ticketAccordionData.uniqueRecipientCount ?? 0) > 1 ? 'recipients' : 'recipient'}`}
            </p>
          ) : null}
        </div>
      </>
    </ConfirmationCompsWrapper>
  )
}

export default SelectedEventTicketList
