import React from 'react'
import './SwitchElement.css'
import clsx from 'clsx'

const SwitchElementUpdated: React.FC<{
  on: boolean
  onSwitch: () => void
  onText?: string
  offText?: string
  className?: string
}> = ({ on, onSwitch, onText, offText, className }) => {
  return (
    <button
      onClick={onSwitch}
      className={clsx(
        'relative tw-button-paperwork',
        on ? 'bg-SeabiscuitGreenThemeColor yes' : 'bg-gray-200 no',
        className
      )}
      value={on ? onText || 'On' : offText || 'Off'}
    >
      <span className="bg-SeabiscuitWhiteThemeColor"></span>
      <div
        className={
          'btn-text text-base  ' +
          (on ? 'yes text-SeabiscuitWhiteThemeColor' : 'no text-[#1F417380]')
        }
      >
        {on ? onText || 'On' : offText || 'Off'}
      </div>
    </button>
  )
}

export default SwitchElementUpdated
