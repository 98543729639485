import { useState } from 'react'
import { CONST } from '../../const/const'
import { SPECTATOR_TICKETS } from '../../const/ui/ui-spectator-tickets'
import SpectatorTicketsPurchaseDisplayTabs from './SpeactatorTicketsPurchaseDisplayTabs'
import { IEventDetails } from '../../models/event-details/event-details.interface'
import { TicketType } from '../../models/event-ticketing/event-ticketing.interface'

type Props = {
  handleModal: any
  EventDetails: IEventDetails
  EventTickets: TicketType[]
  isLoggedIn: boolean
}

type EventT = { tab: string; step: number }

const SpectatorTicketsPurchaseTabs = (props: Props) => {
  const { isLoggedIn } = props
  const tabsArrayToSearch = SPECTATOR_TICKETS
  const [eventTab, setEventTab] = useState<EventT>({
    tab: CONST.UI.SPECTATOR_TABS.TABS.TICKETS,
    step: 0,
  })
  const [loading, setLoading] = useState<boolean>(false)

  // Handles clicking on tabs
  const handleEventTab = (value: any) => {
    setEventTab((prevState) => ({ ...prevState, tab: value, step: 0 }))
  }

  return (
    <>
      <ul className="flex flex-wrap">
        {tabsArrayToSearch.map((curr, index: number) => {
          return (
            <li
              key={JSON.stringify(curr) + `${index}`}
              onClick={() => handleEventTab(curr.tabName)}
              className={`text-nr inline-flex items-center text-SeabiscuitDark200ThemeColor mr-2 bg-transparent cursor-pointer py-2 px-4 rounded-full mb-2 ${eventTab.tab === curr.tabName && '!bg-SeabiscuitWhiteThemeColor text-SeabiscuitMainThemeColor'}`}
            >
              {eventTab.tab === curr.tabName ? (
                <img className="mr-2" src={curr.activeIcon} alt={curr.tabName} />
              ) : (
                <img className="mr-2" src={curr.icon} alt={curr.tabName} />
              )}
              {curr.tabName}
            </li>
          )
        })}
      </ul>

      <div>
        <SpectatorTicketsPurchaseDisplayTabs
          eventTab={eventTab.tab}
          onSetEventTab={handleEventTab}
          handleModal={props.handleModal}
          EventDetails={props.EventDetails}
          EventTickets={props.EventTickets}
          isLoggedIn={isLoggedIn}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </>
  )
}

export default SpectatorTicketsPurchaseTabs
