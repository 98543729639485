import { useContext } from 'react'
import EventReviewPublishInput from './EventReviewPublishInput'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import DataNotAvailable from '../../../../common/alerts/data-not-available/DataNotAvailable'

const DetailRow = ({
  title,
  note,
  onClick,
}: {
  title: any
  note: any
  onClick: (text: any) => void
}) => {
  return (
    <div className="flex w-full">
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-80 text-ellipsis overflow-hidden whitespace-nowrap shrink-0"
        data={title}
      />
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 grow text-ellipsis whitespace-nowrap overflow-hidden cursor-pointer"
        onClick={() => onClick(note)}
        data={note}
      />
      <div className="rounded-lg bg-SeabiscuitGrayThemeColor p-1 h-12 m-1 w-12 text-center flex items-center justify-center shrink-0">
        <img className="m-auto" src="/assets/cp_icons/Ok-3.svg" alt="checkIcon" />
      </div>
    </div>
  )
}

type Props = {
  data?: any
  loading?: boolean
}

const EventPaperworkReviewPublishForm = (props: Props) => {
  // Hooks and vars
  const { data, loading } = props
  const handleModalContext = useContext(HandleModalContext)

  /**
   * @param text Text to show
   * @info Opens note modal
   */
  const openAddNotesModal = (text: string) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NOTE, {
      noteInputRef: text,
    })
  }

  if (loading) {
    return null
  } else {
    return data ? (
      <>
        {data.entryBlank ? (
          <DetailRow
            title="Entry Blank"
            onClick={openAddNotesModal}
            note="Pegasus replaces the need for entry blanks. You no longer need them. You cannot turn this on."
          />
        ) : null}

        {data.universalOrganizerWaver ? (
          <DetailRow
            title="Universal Organizer waiver"
            onClick={openAddNotesModal}
            note="This is a mandatory waiver that will provide protection to the show organizer specifcially. It cannot be turned off."
          />
        ) : null}

        {data.USEFWaiverAndReleaseOfLiability ? (
          <DetailRow
            title="USEF Waiver And Release Of Liability"
            onClick={openAddNotesModal}
            note="Mandatory for any event licensed by the USEF."
          />
        ) : null}

        {data.USEFEntryAgreement ? (
          <DetailRow
            title="USEF Entry Agreement"
            onClick={openAddNotesModal}
            note="Mandatory for any event licensed by the USEF."
          />
        ) : null}

        {data.USDFWaiverAndReleaseOfLiability ? (
          <DetailRow
            title="USDF Waiver And Release Of Liability"
            onClick={openAddNotesModal}
            note="Mandatory for any event licensed by the USEF."
          />
        ) : null}

        {data.USEAWaiverAndReleaseOfLiability ? (
          <DetailRow
            title="USEA Waiver And Release Of Liability"
            onClick={openAddNotesModal}
            note="Mandatory for any event licensed by the USEF."
          />
        ) : null}

        {data.USHJAWaiverAndReleaseOfLiability ? (
          <DetailRow
            title="USHJA Waiver And Release Of Liability"
            onClick={openAddNotesModal}
            note="Inlcude this document if you are hosting a hunter, jumper of hunter jumper event of any kind, licensed or schooling."
          />
        ) : null}
      </>
    ) : (
      <DataNotAvailable
        mode="text"
        containerClassName="text-SeabiscuitDark200ThemeColor justify-center flex"
        text={MESSAGES_CONST.PAPERWORK_DATA_NOT_AVAILABLE}
      />
    )
  }
}

export default EventPaperworkReviewPublishForm
