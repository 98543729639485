import SwitchElement from './SwitchElement'
import ToggleElement from './ToggleElement'

function TogglesElement({
  onToggle,
  buttonClassName = '',
  textClassName = '',
  switchClassName = '',
  on,
  checkedMessage = 'On',
  uncheckedMessage = 'Off',
  disabled = false,
}) {
  return (
    <ToggleElement onToggle={onToggle}>
      {({ onToggle }) => (
        <div>
          <SwitchElement
            textClassName={textClassName}
            buttonClassName={buttonClassName}
            switchClassName={switchClassName}
            on={on}
            onSwitch={onToggle}
            checkedMessage={checkedMessage}
            uncheckedMessage={uncheckedMessage}
            disabled={disabled}
          />
        </div>
      )}
    </ToggleElement>
  )
}

export default TogglesElement
