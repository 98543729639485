// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useContext } from 'react'
import UserRegistrationViewComponentDrawer from './global/UserRegistrationViewComponentDrawer'
import UserRegistrationViewComponentButton from './global/UserRegistrationViewComponentButton'
import { SignupStages } from './UserRegistrationViewComponentContextApi'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const UserRegistrationViewComponentOfferDrawer: React.FC<{ show: boolean; onHide: () => void }> = ({
  show,
  onHide,
}) => {
  const [stage, setStage] = useContext(SignupStages)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <UserRegistrationViewComponentDrawer
      show={show}
      onHide={onHide}
      type="full-modal"
      title="Success"
    >
      <p className="text-[14px] text-SeabiscuitDark200ThemeColor">
        Your account is now live. Complete your profile to streamline event registrations.
      </p>
      <div className="mt-4 w-[90%] mx-auto">
        <UserRegistrationViewComponentButton
          className="shadow-none"
          caption="COMPLETE PROFILE"
          type={1}
          onClick={() => stageHandle('Profile')}
          icon={false}
        />
        {stage.selector === 'event' && (
          <UserRegistrationViewComponentButton
            className="mt-2 shadow-none"
            caption="INVITE TEAM MEMBERS"
            type={2}
            onClick={() => stageHandle('Invite')}
            icon={false}
          />
        )}
        {stage.selector === 'vendor' && (
          <UserRegistrationViewComponentButton
            className="mt-2 shadow-none"
            caption="ADD PRODUCTS"
            type={2}
            onClick={() => stageHandle('Products')}
            icon={false}
          />
        )}
        <UserRegistrationViewComponentButton
          className="mt-2 shadow-none"
          caption="LATER"
          type={3}
          onClick={onHide}
          icon={false}
        />
      </div>
    </UserRegistrationViewComponentDrawer>
  )
}
export default UserRegistrationViewComponentOfferDrawer
