import { useState } from 'react'

import { useIonRouter } from '@ionic/react'

// Components

// Constants
import { CONST } from '../../../../../const/const'
import { MODAL_CONSTS } from '../../../../../const/modal-const'

// Third party
import clsx from 'clsx'
// import { AutorenewRounded } from "@mui/icons-material";

// Custom hooks
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import useToasterHelper from '../../../../../helpers/ToasterHelper'

// Helpers
import helpers from '../../../../../commonHelpers/helpers'
import MainModal from '../../../../../components/modals/common/MainModal'
import { IEventInterface } from '../../../../../models/events/event.interface'
import { EventModel } from '../../../../../models/events/event.model'
import { httpService } from '../../../../../services/httpService'
import { selectedEvent } from '../../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../../store/hooks'
import { useHistory } from 'react-router'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IManageEventModalProps = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

// Constanst

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const BUTTON_LIST = [
  {
    description: 'View event',
    alt: 'View event icon',
    icon: 'assets/og_icons/Search.svg',
    activeIcon: 'assets/og_icons/Search-4.svg',
    title: 'View public event page',
    disableWhenStatusContains: [],
  },
  {
    description: 'Edit event',
    alt: 'Create event icon',
    icon: 'assets/og_icons/Create.svg',
    activeIcon: 'assets/og_icons/Create 4.svg',
    title: 'Edit event details, schedule, etc.',
    disableWhenStatusContains: [COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CANCEL],
  },
  {
    description: 'Duplicate event',
    alt: 'Duplicate event icon',
    icon: '/assets/og_icons/Documents.svg',
    activeIcon: 'assets/og_icons/Documents-4.svg',
    title: 'Duplicate to create a new event.',
    disableWhenStatusContains: [],
  },
  {
    description: 'Close event',
    alt: 'Close event icon',
    icon: '/assets/og_icons/shutdown.svg',
    activeIcon: 'assets/og_icons/shutdown-4.svg',
    title: 'Officially end and save event',
    disableWhenStatusContains: [COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CANCEL],
  },
  {
    description: 'Cancel event',
    alt: 'Cancel event icon',
    icon: 'assets/og_icons/Cancel.svg',
    activeIcon: 'assets/og_icons/Cancel-4.svg',
    title: 'Cancel event and process refunds',
    disableWhenStatusContains: [COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CANCEL],
  },
]

const TYPES = {
  VIEW: 0,
  EDIT: 1,
  CLONE: 2,
  CLOSE: 3,
  CANCEL: 4,
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ManageEventModal = (props: IManageEventModalProps) => {
  // Hooks and vars
  const bodyClassName = props?.dataToPassOn?.bodyClassName ?? ''
  // const headingClassName = props?.dataToPassOn?.headingClassName ?? "";
  const title = props?.dataToPassOn?.title ? props?.dataToPassOn?.title : 'Manage event'

  const router = useIonRouter()
  const history = useHistory()
  const toastMethods = useToasterHelper()
  const [loading, setLoading] = useState(false)
  const [activeButtonIndex, setActiveButtonIndex] = useState(0)
  const selectedEvent_ = useAppSelector(selectedEvent).Event as IEventInterface

  const isCancelled = selectedEvent_?.status === COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CANCEL
  const eventAlreadyClosed = selectedEvent_?.status === COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.PAST

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const closeModal = () => {
    props?.handleModal(false, MODAL_CONSTS.MANAGE_EVENT_MODAL)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleClick = async () => {
    let eventId = props.dataToPassOn?.eventId

    if (!eventId)
      return toastMethods.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

    switch (activeButtonIndex) {
      case TYPES.VIEW:
        router.push(`${CONST.ROUTES.EVENT_DETAILS.URL}/${eventId}`)
        history.push(`${CONST.ROUTES.EVENT_DETAILS.URL}/${eventId}`)
        closeModal()
        break

      case TYPES.EDIT:
        router.push(`${CONST.ROUTES.ORGANIZER_EDIT.URL}/${eventId}`)
        history.push(`${CONST.ROUTES.ORGANIZER_EDIT.URL}/${eventId}`)
        closeModal()
        break

      case TYPES.CLOSE:
        closeEvent()
        closeModal()
        break

      case TYPES.CLONE:
        await duplicateEvent(eventId)
        closeModal()
        break

      case TYPES.CANCEL:
        cancelEvent()
        closeModal()
        break
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  function cancelEvent() {
    closeModal()
    props?.handleModal(true, MODAL_CONSTS.CANCEL_EVENT_MODAL, {
      eventId: props.dataToPassOn?.eventId,
      eventAlreadyClosed,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const closeEvent = () => {
    closeModal()
    props?.handleModal(true, MODAL_CONSTS.CLOSE_EVENT_CONFIRMATION, {
      eventId: props.dataToPassOn?.eventId,
      eventAlreadyClosed,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const duplicateEvent = async (eventId: string) => {
    try {
      setLoading(true)

      const res: any = await httpService({
        url: `replicate_event/${eventId}`,
        method: 'POST',
      })

      if (!res.status) throw new Error(MESSAGES_CONST.SOMETHING_WENT_WRONG)

      let event = EventModel.fromObject(res.data)
      toastMethods.success({
        autoClose: 10000,
        message: `${MESSAGES_CONST.EVENT_DUPLICATED} with name ${event?.eventName}`,
      })

      router.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${event.id}`)
      history.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${event.id}`)
      closeModal()
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
      toastMethods.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <MainModal
      size="md"
      title={title}
      show={props.show}
      onHide={closeModal}
      type="MANAGE_EVENT_MODAL"
      buttons={[
        {
          loading,
          label: 'Next >',
          bgClass: 'bg-white',
          borderClass: '!border-SeabiscuitMainThemeColor border-solid border',
          onClick: handleClick,
          textClass: 'text-SeabiscuitMainThemeColor',
          onHoverClass: 'hover:bg-SeabiscuitMainThemeColor hover:text-white',
        },
      ]}
    >
      <div className={bodyClassName ?? ''}>
        <div className="text-lg my-3">Select action</div>

        <div className="grid grid-cols-2 gap-4">
          {BUTTON_LIST.map((currButton, index) => {
            let active = activeButtonIndex === index
            return (
              <button
                key={`${JSON.stringify(currButton)}${index}`}
                onClick={() => setActiveButtonIndex(index)}
                disabled={
                  isCancelled &&
                  currButton.disableWhenStatusContains.includes(
                    COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CANCEL
                  )
                }
                className={clsx(
                  'flex-col p-3 rounded-lg border border-solid disabled:bg-[#122B460D] disabled:border-[#122B460D]',
                  active
                    ? 'text-white bg-SeabiscuitMainThemeColor border-SeabiscuitMainThemeColor'
                    : 'text-SeabiscuitLightParagraphTextColor text-opacity-5 border-[#122B4633]'
                )}
              >
                <img
                  src={active ? currButton.activeIcon : currButton.icon}
                  alt="createIcon"
                  className="mb-1 w-8 h-8"
                />

                <div className="italic text-[10px] text-left mb-1">
                  {currButton.description !== BUTTON_LIST[3].description
                    ? currButton.description
                    : eventAlreadyClosed
                      ? 'Open event'
                      : currButton.description}
                </div>

                <div className="text-nr text-left">
                  {currButton.title !== BUTTON_LIST[3].title
                    ? currButton.title
                    : eventAlreadyClosed
                      ? 'Officially reopen event'
                      : currButton.title}
                </div>
              </button>
            )
          })}
        </div>
      </div>
    </MainModal>
  )
}

export default ManageEventModal
