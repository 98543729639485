export const Sheet2023USEFFourYearOldDressageTest = {
  id: 'Sheet2023USEFFourYearOldDressageTest',
  name: '2023 USEF FOUR-YEAR-OLD DRESSAGE TEST',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 50,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Trot</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Walk</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Canter</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Submissiveness (willing Cooperation)</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Perspective</h5>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const Sheet2023USEFDevelopingHorsePrixStGeorges = {
  id: 'Sheet2023USEFDevelopingHorsePrixStGeorges',
  name: '2023 USEF Developing Horse Prix St. Georges',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 340,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter collected canter</p><p><span>X •</span> Halt, salute, proceed collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track left</p><p><span>H - E •</span> Shoulder-in left</p><p><span>E •</span> Turn left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>B •</span> Track right</p><p><span>B - F •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>K - X •</span> Half pass right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>X - H •</span> Half pass left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>M - X - K •</span> Extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>N/A •</span> (Transitions at M and K)</p><p><span>K - A •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>A •</span> Halt, rein back 4 steps, proceed collected walk</p><p><span>A - F •</span> Collected walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>F - S •</span> Extended walk</p><p><span>S •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>S - C •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>C •</span> Collected canter right lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>R - I •</span> Half circle right 10m</p><p><span>I - S •</span> Half circle left 10m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>E •</span> Flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>V - L •</span> Half circle left 10m</p><p><span>L - P •</span> Half circle right 10m</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>F •</span> Flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description: '<p><span>K - X - M •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description: '<p><span>M •</span> Collected canter and flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>H •</span> Proceed to X</p><p><span>Before X •</span> Half pirouette left, returning to the track at H</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>H - C •</span> Counter canter</p><p><span>C •</span> Flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>M •</span> Proceed to X</p><p><span>Before X •</span> Half pirouette right, returning to track at M</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>M - C •</span> Counter canter</p><p><span>C •</span> Flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>H - X - F •</span> Five flying changes of lead every fourth stride</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>A •</span> Down centerline</p><p><span>D - B •</span> Half pass right</p><p><span>B •</span> Flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>B - G •</span> Half pass left</p><p><span>G •</span> Flying change of lead</p><p><span>C •</span> Track right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>M - X - K •</span> Five flying changes of lead every third stride</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>A •</span> Collected trot</p><p><span>F - X - H •</span> Extended trot</p><p><span>H •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description:
            '<p><span>N/A •</span> (Transitions at A, F and H)</p><p><span>H - R •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 28,
          description:
            '<p><span>R •</span> Turn right</p><p><span>I •</span> Turn right</p><p><span>G •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'End - Leave arena at A in free walk.',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>The Implementation of General Principles</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Harmony of Presentation</h5>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const Sheet2023USEFDevelopingHorseGrandPrix = {
  id: 'Sheet2023USEFDevelopingHorseGrandPrix',
  name: '2023 USEF Developing Horse Grand Prix',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 340,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter collected canter</p><p><span>X •</span> Halt, salute. Proceed collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track right</p><p><span>M - X - K •</span> Extended trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>K - A - F •</span> (Transitions at M and K) Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>F to 2nd quarterline between X & E •</span> Half pass left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>2nd quarterline between E & X to M •</span> Half pass right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>Between C & H •</span> Develop passage</p><p><span>(C) H-I •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>I •</span> Piaffe 10-12 steps, advancing no more than 1m forward. Proceed passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>N/A •</span> (Transitions passage-piaffe-passage)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>I - B •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>B •</span> Collected walk</p><p><span>B - E •</span> Half circle right 20m in collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>E - I - M •</span> Extended walk</p><p><span>M •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>C •</span> Collected canter left lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>H - X - F •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>F •</span> Collected canter and flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>A •</span> Down centerline, 4 half passes to either side of centerline with flying change of lead at each change of direction, the first to the right and fourth to the left of 4 strides and the second and third of 8 strides</p><p><span>G •</span> Flying change of lead</p><p><span>C •</span> Track right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>M - X - K •</span> Seven flying changes of lead every 2nd stride</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description: '<p><span>F - X - H •</span> Eleven flying changes of lead every stride</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>M - X - K •</span> Change rein</p><p><span>Between M&X •</span> Near first quarterline, pirouette right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description: '<p><span>X •</span> Flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description: '<p><span>Between X&K •</span> Near second quarterline, pirouette left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>A •</span> Collected trot</p><p><span>F - X - H •</span> Extended trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>N/A •</span> (Transitions A, F and H)</p><p><span>H - C - R •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>Between R&B •</span> Develop passage</p><p><span>B - P - L •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>X •</span> Piaffe 10-12 steps, advancing no more than 1m forward. Proceed passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description: '<p><span>N/A•</span> (Transitions passage-piaffe-passage)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description: '<p><span>X - G •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description: '<p><span>G •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'End - Leave arena at A in free walk.',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>The Implementation of General Principles</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Harmony of Presentation</h5>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}
