export const DATA_FIRESTORE_V01_HORSE_LINEAGE = {
  NAME: 'v01_horse_lineage',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },
    FILTER: {
      HORSE_ID: {
        KEY: 'id',
      },
    },
  },
}
