import moment from 'moment'

import EventReviewPublishInput from './EventReviewPublishInput'

import { IEventDetails } from '../../../../../models/event-details/event-details.interface'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import DataNotAvailable from '../../../../common/alerts/data-not-available/DataNotAvailable'

type Props = {
  data: IEventDetails | null
}

const DetailRow = ({ title, data }: { title: any; data: any }) => {
  return (
    <div className="flex flex-col md:flex-row items-start w-full mb-10 md:mb-0">
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-full md:w-80"
        data={title}
      />
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 grow w-full md:w-[initial]"
        data={data}
      />
      <div className="rounded-lg bg-SeabiscuitGrayThemeColor p-1 m-1 h-12 w-full md:w-12 text-center flex items-center justify-center">
        <img className="m-auto" src="/assets/cp_icons/Ok-3.svg" alt="checkIcon" />
      </div>
    </div>
  )
}

const EventDetailsReviewPublishForm = (props: Props) => {
  return (
    <>
      {props?.data ? (
        <>
          {' '}
          <DetailRow title="Name" data={props.data?.competitionName} />
          {props.data?.competitionStartDate ? (
            <DetailRow
              title="Start date"
              data={moment(props.data?.competitionStartDate).format('MM/DD/YYYY')}
            />
          ) : null}
          {props.data?.competitionEndDate ? (
            <DetailRow
              title="End date"
              data={moment(props.data?.competitionEndDate).format('MM/DD/YYYY')}
            />
          ) : null}
          {props.data?.competitionPOCName ? (
            <DetailRow title="POC name" data={props.data?.competitionPOCName} />
          ) : null}
          {props.data?.competitionPOCPhone ? (
            <DetailRow title="POC number" data={props.data?.competitionPOCPhone} />
          ) : null}
          {props.data?.competitionPOCEmail ? (
            <DetailRow title="POC email" data={props.data?.competitionPOCEmail} />
          ) : null}
          {props.data?.registrationAvailableCount ? (
            <DetailRow title="Total registrations" data={props.data?.registrationAvailableCount} />
          ) : null}
          {props.data?.competitionRegistrationOpenDate ? (
            <DetailRow
              title="Registrations open"
              data={`${props?.data?.competitionRegistrationOpenTime && typeof props?.data?.competitionRegistrationOpenTime === 'object' ? `${props?.data?.competitionRegistrationOpenTime?.hour}${props?.data?.competitionRegistrationOpenTime?.minutes ? `:${props?.data?.competitionRegistrationOpenTime?.minutes}` : null}` : null}, ${moment(props.data?.competitionRegistrationOpenDate).format('MM/DD/YYYY')}`}
            />
          ) : null}
          {props.data?.competitionRegistrationCloseDate ? (
            <DetailRow
              title="Registrations close"
              data={`${props?.data?.competitionRegistrationCloseTime && typeof props?.data?.competitionRegistrationCloseTime === 'object' ? `${props?.data?.competitionRegistrationCloseTime?.hour}${props?.data?.competitionRegistrationCloseTime?.minutes ? `:${props?.data?.competitionRegistrationCloseTime?.minutes}` : null}` : null}, ${moment(props.data?.competitionRegistrationCloseDate).format('MM/DD/YYYY')}`}
            />
          ) : null}
          <DetailRow title="Waitlist?" data={props.data?.waitlist ? 'Yes' : 'No'} />
        </>
      ) : (
        <DataNotAvailable
          mode="text"
          containerClassName="text-SeabiscuitDark200ThemeColor justify-center flex"
          text={MESSAGES_CONST.EVENT_DATA_NOT_AVAILABLE}
        />
      )}
    </>
  )
}

export default EventDetailsReviewPublishForm
