// Component imports
import { KeyboardArrowRight } from '@mui/icons-material'
import { HandleModalContext } from '../../layout/mainlayout/MainLayout'
// Constants
import { useContext, useState } from 'react'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import { IUserDocument } from '../../models/user-documents/user-documents.interface'
import { UserDocumentModel } from '../../models/user-documents/user-documents.model'

import FirestoreService from '../../services/firestoreService'

// @ts-ignore
import fontkit from '@pdf-lib/fontkit'
import moment from 'moment'
import { PDFDocument, PDFImage, StandardFonts, cmyk } from 'pdf-lib'
import helpers from '../../commonHelpers/helpers'
import { CustomError, capitalize, createString } from '../../helpers/helpers'
import { getConvertedData } from '../../models/interface.helper'
import { UserDocumentLogsModel } from '../../models/user-document-logs/user-document-logs.model'
import FirebaseStorageService from '../../services/storageService'
import MainModal from './common/MainModal'
import fakeDocumentList, { IDocument } from '../../fakeData/fakeDocumentList'
import { ILightboxProps } from '../../types/competitor_types'
import LightboxCommonComponent from '../common/dialogs/full-screen/LightboxCommonComponent'
import TimeLib from '../../lib/Time'
import { httpService } from '../../services/httpService'
import { cloneDeep } from 'lodash'
import { IUserInterface } from '../../models/users/user.interface'
import { IRiderTeamMemberInterface } from '../../models/rider-team-member/riderTeamMember.interface'
import { UserModel } from '../../models/users/user.model'
import { RiderTeamMemberModel } from '../../models/rider-team-member/riderTeamMember.model'
import { COUNTRIES_LIST } from '../../fakeData/countriesList'
import { setSendMailLoading } from '../../store/registration/registrationSlice'
import { GeneralLiabilityWaiverPDFTeamplate } from '../../templates/pdf/generalLiabilityWaiver/GeneralLiabilityWaiverPDFTeamplate'
import { USDFLiabilityWaiverPDFTemplate } from '../../templates/pdf/USDFLiabilityWaiver/USDFLiabilityWaiverPDFTemplate'
import { USEFLiabilityWaiverPDFTemplate } from '../../templates/pdf/USEFLiabilityWaiver/USEFLiabilityWaiverPDFTemplate'
import { USEALiabilityWaiverPDFTeamplate } from '../../templates/pdf/USEALiabilityWaiver/USEALiabilityWaiverPDFTeamplate'
import { USHJALiabilityWaiverPDFTeamplate } from '../../templates/pdf/USHJALiabilityWaiverPDFTeamplate/USHJALiabilityWaiverPDFTeamplate'
import { IPaperworkTab } from '../../models/event-drafts/event-draft.interface'
import { setRegisterPaperworkData } from '../../store/events/eventsSlice'
import { where } from 'firebase/firestore'
import { useAppSelector } from '../../store/hooks'
import { selectProfileData } from '../../store/user/userSlice'
import { getFilteredPaperworks } from '../../helpers/documents'
import useGetEventData from '../../hooks/users/common/useGetEventData'
import { UserLegalClausesModel } from '../../models/user-legal-clauses/user-legal-clauses.model'
import { USEFEntryAgreementPDFNewTemplate } from '../../templates/pdf/USEFEntryAgreement/USEFEntryAgreementPDFNewTemplate'
import { calculateAge } from '../../helpers/calculateAge'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

// Types
type TCertificate = {
  SX: number
  SY: number
  EX: number
  EY: number
}

type IViewModalProps = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  openLightBox?: any
  setLightBox?: any
  handleDataUpdate?: (row: number, activeTab: string) => void
  activeTab?: string
}
const CLAUSE_TABLE = CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_LEGAL_CLAUSES

const SignDocumentModal = (props: IViewModalProps) => {
  // Hooks and vars
  const [loading, setLoading] = useState(false)
  const [lightBox, setLightBox] = useState<ILightboxProps>({ visible: false, slides: [] })
  const toastFunctions = useToasterHelper()
  const { docIndex, documentUrl, handleDataUpdate, activeTab, owner } = props.dataToPassOn
  const data = props.dataToPassOn?.data as IUserDocument
  const isCalledFromRegistration = props.dataToPassOn?.type === 'Registration'
  const userData = useAppSelector(selectProfileData)
  const { getAllData } = useGetEventData()
  const handleAnnex = () => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.REVIEW_ANNEX, {
      data: {
        eventId: data.eventId ?? '',
        eventName: data.eventName ?? '',
      },
      dataToPassOn: props.dataToPassOn,
    })
    handleModalContext?.handleModal(false, MODAL_CONSTS.SIGN_DOCUMENT)
  }

  const handleModalContext = useContext(HandleModalContext)

  async function getRiderTeamMembersFromDb(registrationId: string) {
    let riderTeamMembers: IRiderTeamMemberInterface[] = []
    try {
      const ticketSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [
          where(
            COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ]
      )

      if (ticketSnapShots.size) {
        ticketSnapShots.forEach((currDoc) => {
          riderTeamMembers.push(
            getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return riderTeamMembers
    }
  }

  const getAllClauses = async (ownerId: string) => {
    const respData: any[] = []
    const clauses = await FirestoreService.filterItems(CLAUSE_TABLE.NAME, [
      where(CLAUSE_TABLE.FIELDS.OWNER_ID.KEY, '==', ownerId),
    ])

    if (clauses.size) {
      clauses?.forEach((doc) => {
        let clauseDataModel = UserLegalClausesModel.fromFirestoreDoc(doc).toObject()
        respData.push(clauseDataModel)
      })
      return respData
    }
  }

  const handleSendMail = async () => {
    try {
      if (!data.eventId) return null
      setLoading(true)
      const ridersTeamMembersInDb__ = await getRiderTeamMembersFromDb(data?.registrationDocId ?? '')

      const currRidersTeamMember = ridersTeamMembersInDb__.find(
        (member) => member.riderId === data.riderId
      )

      if (!currRidersTeamMember) throw new Error('Error get Rider Team Member')

      let age = calculateAge(currRidersTeamMember.teamMemberDob)

      function getDocumentType(): string {
        if (data.documentOriginalName?.includes('USEF Entry')) {
          return 'usefentry'
        }
        return data.documentOriginalName?.split(' ')[0].toLowerCase() || ''
      }
      const paperwork = fakeDocumentList.find((d) => d.document_type === getDocumentType())

      if (!paperwork) throw new Error('Error getting paperwork')

      const eventData = await getAllData(data.eventId, ['v01_event_details'])

      if (!eventData || !eventData.v01_event_details)
        throw new Error('Error getting v01_event_details')

      const doc = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
        owner.ownerId
      )

      if (!doc) throw new Error('Error getting doc')

      const organizerDetails = UserModel.fromFirestoreDoc(doc).toObject()
      const createData = {
        eventId: data.eventId,
        userData,
        paperwork,
        EventDetails: eventData.v01_event_details,
        organizerDetails,
        eventDraftId: '',
      }

      const allClauses = await getAllClauses(owner.ownerId)

      if (!allClauses) throw new Error('Error getting allClauses')

      let RoleArray: IRiderTeamMemberInterface['roles'] = currRidersTeamMember?.roles?.filter(
        (data) => {
          return data.selected
        }
      )

      let stringArray = RoleArray?.map((data) => {
        return data.label
      })

      const index = 0
      // setLoading(false)
      // console.log('=>(SignDocumentModal.tsx:205) data', data.id)
      // if (1) return 1

      switch (getDocumentType()) {
        case 'general':
          await GeneralLiabilityWaiverPDFTeamplate(
            {
              ...createData,
              ridersTeamMember: currRidersTeamMember,
              isMature: age > 18,
              clauses: allClauses,
              isSigned: true,
              stringArray,
              index,
            },
            data.id,
            [
              {
                time: TimeLib.utcTimestamp(),
                audit: `Document signed by ${data.signatoryName}`,
              },
            ]
          )
          break

        case 'usdf':
          console.log('=>(SignDocumentModal.tsx:209) 2', 2)
          await USDFLiabilityWaiverPDFTemplate(
            {
              ...createData,
              ridersTeamMember: currRidersTeamMember,
              isMature: age > 18,
              isSigned: true,
              stringArray,
              index,
            },
            data.id
          )
          break

        case 'usef':
          console.log('=>(SignDocumentModal.tsx:234) 3', 3)
          await USEFLiabilityWaiverPDFTemplate(
            {
              ...createData,
              ridersTeamMember: currRidersTeamMember,
              isMature: age > 18,
              isSigned: true,
              stringArray,
              index,
            },
            data.id
          )
          break

        case 'usea':
          console.log('=>(SignDocumentModal.tsx:246) 4', 4)
          await USEALiabilityWaiverPDFTeamplate(
            {
              ...createData,
              ridersTeamMember: currRidersTeamMember,
              isMature: age > 18,
              isSigned: true,
              stringArray,
              index,
            },
            data.id
          )
          break

        case 'ushja':
          console.log('=>(SignDocumentModal.tsx:258) 5', 5)
          await USHJALiabilityWaiverPDFTeamplate(
            {
              ...createData,
              ridersTeamMember: currRidersTeamMember,
              isMature: age > 18,
              isSigned: true,
              stringArray,
              index,
            },
            data.id
          )
          break

        case 'usefentry':
          await USEFEntryAgreementPDFNewTemplate(
            {
              ...createData,
              ridersTeamMember: currRidersTeamMember,
              isMature: age > 18,
              isSigned: true,
              stringArray,
              index,
            },
            data.id
          )
          break
      }

      handleDataUpdate(docIndex, activeTab)
      setLoading(false)

      const docSnaps = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS.NAME,
        data.id!
      )
      const userDocument = getConvertedData(UserDocumentModel.fromFirestoreDoc(docSnaps).toObject())

      toastFunctions.success({ message: MESSAGES_CONST.SIGNED_SUCCESSFULLY })

      handleModalContext?.handleModal(false, MODAL_CONSTS.SIGN_DOCUMENT, {
        status: true,
        data: userDocument,
        docIndex,
      })
    } catch (error: any) {
      console.log('=>(SignDocumentModal.tsx:151) e', error)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      setLoading(false)
      helpers.logger({
        message: error?.message,
      })
    }
    // if (1) return 1
  }

  const handleSignature = async (docId: string) => {
    if (isCalledFromRegistration) return

    let fileUrl
    let AllLogs: {
      logs: any
      id?: string
      signatoryId?: string
      riderId?: string
      eventId?: string
      u?: boolean
    }

    try {
      const userDocumentLogs = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
        docId
      )
      let userLogsData = getConvertedData(
        UserDocumentLogsModel.fromFirestoreDoc(userDocumentLogs).toObject()
      )

      console.log('=>(SignDocumentModal.tsx:304) userLogsData', userLogsData)
      AllLogs = {
        ...userLogsData,
        logs: [...userLogsData.logs],
      }

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
        docId,
        AllLogs
      ).then(() => {
        handleDataUpdate(docIndex, activeTab)
      })
    } catch (error) {
      console.log('=>(SignDocumentModal.tsx:323) error', error)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
      setLoading(false)
      return
    }

    try {
      const isValidFileUrl = await helpers.firebaseFileCheck(documentUrl)

      if (!isValidFileUrl)
        throw CustomError.somethingWentWrong({
          fileName: 'SignDocumentModal',
          message: MESSAGES_CONST.PDF_NOT_FOUND,
          moduleName: 'handleSignature',
        })

      const existingPdfBytes = await fetch(documentUrl).then((res) => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(existingPdfBytes)
      pdfDoc.registerFontkit(fontkit)
      const pages = pdfDoc.getPages()
      let pageToSignOn =
        pages[data.documentName === 'universalOrganizerWaver' ? 5 : pages.length - 1]

      if (data?.pageNumberToSignOn && data.documentName === 'universalOrganizerWaver')
        pageToSignOn = pages[data.pageNumberToSignOn - 1]

      // Fonts
      const boldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold)
      const fontByte = await fetch('/fonts/signature.ttf').then((res) => res.arrayBuffer())
      const signatureFont = await pdfDoc.embedFont(fontByte)
      const fontPoppingsBytes = await fetch('/fonts/Poppins-Regular.ttf').then((res) =>
        res.arrayBuffer()
      )
      const PoppinsFont = await pdfDoc.embedFont(fontPoppingsBytes)
      const d = new Date()
      let time = d.getTime()
      switch (data.documentName) {
        case 'blank':
          break
        case 'universalOrganizerWaver':
          pageToSignOn.drawText(`${data.signatoryName}`, {
            x: data?.coordinatesToSignOn?.x ?? 45,
            y: data?.coordinatesToSignOn?.y ?? 500,
            font: signatureFont,
            size: 28,
          })
          break
        case 'USEFEntryAgreement':
          pageToSignOn.drawText(`${data.signatoryName}`, {
            x: 45,
            y: 425,
            font: signatureFont,
            size: 28,
          })
          break
        case 'USDFWaiverAndReleaseOfLiability':
          pageToSignOn.drawText(`${data.signatoryName}`, {
            x: 45,
            y: 500,
            font: signatureFont,
            size: 28,
          })
          break
        case 'USEFWaiverAndReleaseOfLiability':
          pageToSignOn.drawText(`${data.signatoryName}`, {
            x: 45,
            y: 430,
            font: signatureFont,
            size: 28,
          })
          break
        case 'USEAWaiverAndReleaseOfLiability':
          pageToSignOn.drawText(`${data.signatoryName}`, {
            x: 45,
            y: 570,
            font: signatureFont,
            size: 28,
          })
          break
        case 'USHJAWaiverAndReleaseOfLiability':
          pageToSignOn.drawText(`${data.signatoryName}`, {
            x: 45,
            y: 570,
            font: signatureFont,
            size: 28,
          })
          break
      }

      // Audit Trail Page Start
      const jpgUrl = '/assets/Pegasus_app_logo.png'
      const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
      const jpgImage = await pdfDoc.embedPng(jpgImageBytes)

      let auditTrailPage = pdfDoc.addPage([550, 760])
      auditTrailPage.drawText('Pegasus App Inc.', { x: 45, y: 720, font: PoppinsFont, size: 14 })
      auditTrailPage.drawLine({
        start: { x: 45, y: 715 },
        end: { x: 480, y: 715 },
        thickness: 1,
        color: cmyk(0, 0, 0, 0.9),
        opacity: 1,
      })

      auditTrailPage.drawImage(jpgImage, {
        x: 480,
        y: 695,
        width: 40,
        height: 40,
        opacity: 1,
      })

      let certificateBorder = [
        { SX: 70, SY: 660, EX: 470, EY: 660 }, // Top Border
        { SX: 70, SY: 660, EX: 70, EY: 60 }, // Left Border
        { SX: 470, SY: 660, EX: 470, EY: 60 }, // Right Border
        { SX: 70, SY: 60, EX: 470, EY: 60 }, // Bottom Border
      ]

      certificateBorder.forEach((item: TCertificate) => {
        auditTrailPage.drawLine({
          start: { x: item.SX, y: item.SY },
          end: { x: item.EX, y: item.EY },
          thickness: 2,
          color: cmyk(0, 0, 0, 0.5),
          opacity: 0.8,
        })
      })

      auditTrailPage.drawText(`Signature Certificate`, {
        x: 80,
        y: 640,
        font: boldFont,
        color: cmyk(0, 0, 0, 0.9),
        size: 14,
      })
      auditTrailPage.drawText(`Document Name: ${data.documentNameAsPerPdf}`, {
        x: 80,
        y: 630,
        font: boldFont,
        color: cmyk(0, 0, 0, 0.9),
        size: 10,
      })
      auditTrailPage.drawText(`Unique Document Id: ${docId}`, {
        x: 80,
        y: 620,
        font: boldFont,
        color: cmyk(0, 0, 0, 0.9),
        size: 8,
      })

      let Image = data?.signatoryProfilePicture
        ? data?.signatoryProfilePicture
        : '/assets/img/Empty user.png'
      let imagetype: string = ''

      const getImageArray = async (imageUrl: string) => {
        return await fetch(Image).then((res) => {
          const contentType = res.headers.get('content-type') ?? ''
          imagetype = contentType?.split('/')[1]
          return res.arrayBuffer()
        })
      }

      let jpgImageBy = await getImageArray(Image)
      console.log({ jpgImageBy })
      let userImage: PDFImage
      if (imagetype === 'jpeg') {
        userImage = await pdfDoc.embedJpg(jpgImageBy)
      } else if (imagetype === 'png') {
        userImage = await pdfDoc.embedPng(jpgImageBy)
      } else {
        const defaultImage = await getImageArray('/assets/img/Empty user.png')
        userImage = await pdfDoc.embedPng(defaultImage)
      }

      console.log({ userImage })
      auditTrailPage.drawImage(userImage, {
        x: 80,
        y: 480,
        width: 80,
        height: 80,
        opacity: 1,
      })

      // //%%%%%%%%%%%
      const capatlize_name: string = capitalize(createString(data.signatoryName), {
        capitalizeAll: true,
      })
      auditTrailPage.drawText(capatlize_name, {
        x: 170,
        y: 550,
        color: cmyk(0, 0, 0, 0.9),
        size: 10,
      })
      auditTrailPage.drawText(`Security Level: Email`, {
        x: 170,
        y: 535,
        color: cmyk(0, 0, 0, 0.9),
        size: 10,
      })

      let signatureBorder = [
        { SX: 330, SY: 560, EX: 460, EY: 560 }, // Top Border
        { SX: 330, SY: 560, EX: 330, EY: 490 }, // Left Border
        { SX: 460, SY: 560, EX: 460, EY: 490 }, // Right Border
        { SX: 330, SY: 490, EX: 460, EY: 490 }, // Bottom Border
      ]

      signatureBorder.forEach((item: TCertificate) => {
        auditTrailPage.drawLine({
          start: { x: item.SX, y: item.SY },
          end: { x: item.EX, y: item.EY },
          thickness: 1,
          color: cmyk(0, 0, 0, 0.5),
          opacity: 0.8,
        })
      })

      auditTrailPage.drawText(`Digital Signature:`, {
        x: 340,
        y: 540,
        color: cmyk(0, 0, 0, 0.9),
        font: boldFont,
        size: 11,
      })
      auditTrailPage.drawText(`${data.signatoryName}`, {
        x: 340,
        y: 510,
        color: cmyk(0, 0, 0, 0.9),
        font: signatureFont,
        size: 28,
      })

      if (AllLogs.logs && AllLogs.logs.length) {
        auditTrailPage.drawText(`Timestamp`, {
          x: 110,
          y: 440,
          color: cmyk(0, 0, 0, 0.9),
          font: boldFont,
          size: 15,
        })
        auditTrailPage.drawText(`Audit`, {
          x: 270,
          y: 440,
          color: cmyk(0, 0, 0, 0.9),
          font: boldFont,
          size: 15,
        })
        let logY = 420
        AllLogs.logs.forEach((log: any) => {
          let parsedDate = Date.parse(getConvertedData({ time: log.time }).time)

          let firstLineText = ''
          let secondLineText = ''
          let thirdLineText = ''

          if (log.audit.length > 40 && log.audit.length < 80) {
            firstLineText = log.audit.slice(0, 40)
            secondLineText = log.audit.slice(41, 80)
          } else if (log.audit.length > 40 && log.audit.length < 120) {
            firstLineText = log.audit.slice(0, 40)
            secondLineText = log.audit.slice(41, 80)
            thirdLineText = log.audit.slice(81, 120)
          } else if (log.audit.length < 40) {
            firstLineText = log.audit
          }

          auditTrailPage.drawText(`${moment(parsedDate).format(`MMMM DD, YYYY h:mm:ss a`)}`, {
            x: 110,
            y: logY,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })

          if (firstLineText && firstLineText !== '') {
            auditTrailPage.drawText(`${firstLineText}`, {
              x: 270,
              y: logY,
              color: cmyk(0, 0, 0, 0.9),
              size: 8,
            })
          }

          if (secondLineText && secondLineText !== '') {
            auditTrailPage.drawText(`${secondLineText}`, {
              x: 270,
              y: logY - 10,
              color: cmyk(0, 0, 0, 0.9),
              size: 8,
            })
          }

          if (thirdLineText && thirdLineText !== '') {
            auditTrailPage.drawText(`${thirdLineText}`, {
              x: 270,
              y: logY - 20,
              color: cmyk(0, 0, 0, 0.9),
              size: 8,
            })
          }

          logY -= 40
          if (logY < 140) {
            logY = 620
            auditTrailPage = pdfDoc.addPage([550, 760])
            auditTrailPage.drawText(
              owner?.ownerLegalPolicyName !== '' ? owner.ownerLegalPolicyName : 'Pegasus App Inc.',
              { x: 45, y: 720, font: PoppinsFont, size: 14 }
            )
            auditTrailPage.drawLine({
              start: { x: 45, y: 715 },
              end: { x: 480, y: 715 },
              thickness: 1,
              color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
              opacity: 1,
            })

            auditTrailPage.drawImage(jpgImage, {
              x: 480,
              y: 695,
              width: 40,
              height: 40,
              opacity: 1,
            })

            let certificateBorder = [
              { SX: 70, SY: 660, EX: 470, EY: 660 }, // Top Border
              { SX: 70, SY: 660, EX: 70, EY: 60 }, // Left Border
              { SX: 470, SY: 660, EX: 470, EY: 60 }, // Right Border
              { SX: 70, SY: 60, EX: 470, EY: 60 }, // Bottom Border
            ]

            certificateBorder.forEach((item: TCertificate) => {
              auditTrailPage.drawLine({
                start: { x: item.SX, y: item.SY },
                end: { x: item.EX, y: item.EY },
                thickness: 2,
                color: cmyk(0, 0, 0, 0.5),
                opacity: 0.8,
              })
            })
          }
        })
      }

      auditTrailPage.drawLine({
        start: { x: 70, y: 140 },
        end: { x: 470, y: 140 },
        thickness: 2,
        color: cmyk(0, 0, 0, 0.5),
        opacity: 0.8,
      })

      auditTrailPage.drawText(`This audit trail report provides a detailed record of the`, {
        x: 160,
        y: 110,
        font: boldFont,
        size: 10,
      })
      auditTrailPage.drawText(`online activity and events recorded for this contact.`, {
        x: 170,
        y: 90,
        font: boldFont,
        size: 10,
      })

      auditTrailPage.drawText(`https://thepegasus.app/`, {
        x: 70,
        y: 50,
        color: cmyk(0, 0, 0, 0.5),
        font: boldFont,
        size: 8,
      })

      const pdfBytes = await pdfDoc.save()

      // Step 1: Convert Uint8Array to Blob
      var blob = new Blob([pdfBytes.buffer], { type: 'application/octet-stream' })

      // Step 2: Convert Blob to File
      var file = new File([blob], `${data.documentOriginalName}.pdf`, { type: 'application/pdf' })

      await FirebaseStorageService.deleteFile(documentUrl)
      fileUrl = await FirebaseStorageService.uploadFile(
        file,
        `/user/documents/${time}/${data.registrationDocId}/${data.signatoryId}/${data.eventId}/${data.documentOriginalName}/${data.riderId}`
      )
    } catch (error: any) {
      // toastFunctions.error({
      //   message: error?.message,
      // })
      // setLoading(false)
      // helpers.logger({
      //   message: error,
      // })
    }

    try {
      let userDocument: IUserDocument = new UserDocumentModel()

      if (!docId) {
        return toastFunctions.error({ message: 'Data not found' })
      }
      const docSnaps = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS.NAME,
        docId
      )
      userDocument = getConvertedData(UserDocumentModel.fromFirestoreDoc(docSnaps).toObject())

      console.log('=>(SignDocumentModal.tsx:755) userDocument', userDocument)
      console.log('=>(SignDocumentModal.tsx:755) fileUrl', fileUrl)
      userDocument = {
        ...userDocument,
        status: 'Signed',
        // documentUrl: fileUrl ? (fileUrl as string) : '',
      }

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS.NAME,
        userDocument.id,
        userDocument
      )

      if (data.registrationDocId) {
        await httpService({
          method: 'get',
          url: `update_registration_status_keys/${data.registrationDocId}`,
        })

        // await httpService({
        //     method: "get",
        //     url: `update_recipient_paperwork_status/${data.signatoryId}/${data.registrationDocId}`,
        // })
      }

      setLoading(false)

      toastFunctions.success({ message: MESSAGES_CONST.SIGNED_SUCCESSFULLY })
      handleModalContext?.handleModal(false, MODAL_CONSTS.SIGN_DOCUMENT, {
        status: true,
        data: userDocument,
        docIndex,
      })
    } catch (error) {
      console.log('=>(SignDocumentModal.tsx:789) error', error)
      // helpers.logger({
      //   isError: true,
      //   message: error,
      // })
      // setLoading(false)
    }
  }

  const downloadPdf = (link: string) => {
    var a = document.createElement('a')
    a.setAttribute('download', 'sample.pdf')
    a.setAttribute('href', link)
    a.setAttribute('target', '_blank')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleLightBox = (props: ILightboxProps) => {
    setLightBox({
      ...props,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param index Index of the paperwork doc whose image needs to be open in lightbox
   * @info Opens lightbox with paperwork document image
   */
  const openLightBox = (documentName: string) => {
    var index = 0
    if (documentName === 'General Liability Waiver') {
      index = 0
    } else if (documentName === '') {
      index = 1
    } else if (documentName === 'USEF Waiver and Release of Liability') {
      index = 2
    } else if (documentName === 'USEF Entry Agreement') {
      index = 3
    } else if (documentName === 'USDF Waiver and Release of Liablity') {
      index = 4
    } else if (documentName === 'USEA Waiver and Release of Liability') {
      index = 5
    } else if (documentName === 'USHJA Waiver and Release of Liability') {
      index = 6
    }

    let paperworkImages = fakeDocumentList[index].document_image

    if (!Array.isArray(paperworkImages)) paperworkImages = [paperworkImages]

    const slides = paperworkImages.reduce((acc: any[], current: string) => {
      acc.push({ src: current, title: documentName })
      return acc
    }, [])

    handleLightBox({
      visible: true,
      slides,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Document this
   */
  // Closes lightbox
  const onLightBoxClose = () => {
    handleLightBox({ visible: false, slides: [] })
  }

  console.log('props', props)

  return (
    <MainModal
      title={isCalledFromRegistration ? 'More' : 'Review and Sign'}
      show={props.show}
      type="SIGN_DOCUMENT"
      size="md"
      footerClassName="justify-between pt-6"
      buttons={[
        ...(isCalledFromRegistration !== true
          ? [
              {
                label: 'Sign',
                className: '',
                bgClass: 'bg-SeabiscuitMainThemeColor',
                fullWidth: true,
                onClick: handleSendMail,
                // onClick: handleSignature,
                onHoverClass: 'hover:bg-SeabiscuitMainThemeColorDark',
                textClass: 'text-white',
                disabled: loading,
                loading,
              },
            ]
          : []),
        {
          label: 'Cancel',
          fullWidth: true,
          bgClass: '!bg-SeabiscuitLightThemeColor',
          borderClass: '!border border-transparent',
          textClass: '!text-SeabiscuitLightTextColor',
          disabled: loading,
          onClick: () => handleModalContext?.handleModal(false, MODAL_CONSTS.SIGN_DOCUMENT),
        },
      ]}
    >
      <div>
        <div className="flex items-center border border-solid border-SeabiscuitGray500ThemeColor rounded-[12px] px-2 py-0.5 mt-2">
          <img
            onError={(e) => ((e.target as any).src = '/assets/Pegasus_app_logo.svg')}
            src={
              data?.eventLogo && data?.eventLogo !== ''
                ? data?.eventLogo
                : '/assets/Pegasus_app_logo.svg'
            }
            className="w-[42px] h-[42px] rounded-lg shrink-0 mr-3  my-1"
            alt="Pegasus app logo"
          />
          <span
            className="w-full text-ellipsis text-SeabiscuitDark200ThemeColor font-normal overflow-hidden capitalize whitespace-wrap"
            title={'hello'}
          >
            {createString(data.eventName)}
          </span>
        </div>
        <div className="flex-col items-center text-SeabiscuitDark200ThemeColor border border-solid border-SeabiscuitGray500ThemeColor rounded-[12px] p-2 mt-2 p-4">
          <div className="font-bold mb-3">Document Details:</div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                <img src="/assets/og_icons/DocumentFlat.svg" className="w-7 h-7" alt="logo" />
              </span>
              <span
                className="w-full text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
                title={'General Liability Waiver'}
              >
                {data.documentOriginalName}
              </span>
            </div>
            <div
              className="underline cursor-pointer"
              onClick={() => {
                if (isCalledFromRegistration) {
                  openLightBox(data.documentOriginalName ?? '')
                } else downloadPdf(props.dataToPassOn.documentUrl)
              }}
            >
              View {'>'}
            </div>
          </div>

          {data.documentOriginalName === 'General Liability Waiver' && (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                  <img src="/assets/og_icons/DocumentFolded.svg" className="w-7 h-7" alt="logo" />
                </span>
                <span
                  className="w-full text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
                  title={'Signing as'}
                >
                  Annex
                </span>
              </div>
              <div className="underline cursor-pointer" onClick={handleAnnex}>
                View {'>'}
              </div>
            </div>
          )}

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                <img src="/assets/og_icons/Quill Pen-1.svg" className="w-7 h-7" alt="logo" />
              </span>
              <span
                className="w-full text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
                title={'Signing as'}
              >
                Signing as:
              </span>
            </div>
            <div>{data.signatoryDefaultRole}</div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span className="p-1 rounded-full shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                <img src="/assets/og_icons/People-1.svg" className="w-7 h-7" alt="logo" />
              </span>
              <span
                className="w-full text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
                title={'On Behalf of'}
              >
                On Behalf of:
              </span>
            </div>
            <div>{data.activityUser}</div>
          </div>
        </div>
        {/* <div className="flex justify-between items-center border border-solid border-SeabiscuitGray500ThemeColor cursor-pointer rounded-[12px] p-2 mt-2"
                    onClick={() => {
                        if (isCalledFromRegistration) {
                            openLightBox(data.documentOriginalName ?? "")
                        } else
                            downloadPdf(props.dataToPassOn.documentUrl)
                    }}
                >
                    <div className="flex items-center">
                        <span className="p-1 rounded-  shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                            <img src="/assets/og_icons/FullScreen-1.svg" className="w-7 h-7" alt="fullscreenIcon" />
                        </span>
                        <span className="w-40 text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap" title={"View Document"}>
                            {"View Document"}
                        </span>
                    </div>
                    <KeyboardArrowRight className="text-SeabiscuitDark200ThemeColor font-normal" />
                </div>

                <div className="flex justify-between items-center border border-solid border-SeabiscuitGray500ThemeColor cursor-pointer rounded-[12px] p-2 mt-2"
                    onClick={handleAnnex}
                >
                    <div className="flex items-center">
                        <span className="p-1 rounded-  shrink-0 mr-3 overflow-hidden flex items-center justify-center">
                            <img src="/assets/og_icons/FullScreen-1.svg" className="w-7 h-7" alt="fullscreenIcon" />
                        </span>
                        <span className="w-40 text-ellipsis  text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap" title={"View Document"}>
                            {"View Annex"}
                        </span>
                    </div>
                    <KeyboardArrowRight className="text-SeabiscuitDark200ThemeColor font-normal" />
                </div> */}

        <div className="flex border border-solid border-SeabiscuitGray500ThemeColor rounded-[12px] p-2 mt-2">
          <span className="max-h-[30px] mt-2 p-1 rounded-  shrink-0 mr-3 overflow-hidden flex items-center justify-center">
            <img src="/assets/og_icons/People-1.svg" className="w-7 h-7" alt="peopleIcon" />
          </span>
          <div className="flex flex-col ">
            <span
              className="w-40 mb-4 mt-2 text-ellipsis text-SeabiscuitDark200ThemeColor font-normal overflow-hidden whitespace-nowrap"
              title={'Who must sign?'}
            >
              {'Who must sign?'}
            </span>

            <span className="font-normal leading-[25px] mb-3  text-SeabiscuitDark200ThemeColor">
              All people attending the event in person, as well as riders, trainers, owners, coaches
              or legal guardians associated with a horse competing in this event.
            </span>
          </div>
        </div>
        <div className="flex  border border-solid border-SeabiscuitGray500ThemeColor rounded-[12px] p-2 mt-2">
          <span className="max-h-[30px] p-1 mt-2 rounded-  shrink-0 mr-3 overflow-hidden flex items-center justify-center">
            <img src="/assets/og_icons/Error-1.svg" className="w-7 h-7" alt="errorIcon" />
          </span>
          <div className="flex flex-col ">
            <span
              className="w-40 mt-2 text-ellipsis text-SeabiscuitDark200ThemeColor font-normal leading-[25px] overflow-hidden mb-4 whitespace-nowrap"
              title={'Disclaimer'}
            >
              {'Disclaimer'}
            </span>
            <span className="font-normal leading-[25px] mb-3 text-SeabiscuitDark200ThemeColor">
              By clicking sign, you confirm you have read and agree to the terms and conditions in
              both this document and annex.
            </span>
          </div>
        </div>
      </div>

      {/* -----------LightBox----------- */}
      <LightboxCommonComponent
        visible={lightBox.visible}
        onClose={onLightBoxClose}
        slides={lightBox.slides}
      />
    </MainModal>
  )
}

export default SignDocumentModal
