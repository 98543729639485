import React from 'react'

interface IProps {}

export const IconSwitch: React.FC<IProps> = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        fill="none"
        viewBox="0 0 14 14"
      >
        <path
          fill="#122b4680"
          className={'group-hover:fill-[#f7074f] opacity-100'}
          d="M10.161.662a.5.5 0 00-.348.859l1.813 1.813H1.166a.5.5 0 100 1h10.46L9.813 6.147a.5.5 0 10.707.707l2.667-2.667a.5.5 0 000-.707L10.52.814a.5.5 0 00-.359-.152zM3.823 6.996a.5.5 0 00-.344.151L.813 9.814a.5.5 0 000 .707l2.666 2.666a.5.5 0 10.707-.707l-1.813-1.813h10.46a.5.5 0 100-1H2.373l1.813-1.813a.5.5 0 00-.363-.858z"
        ></path>
      </svg>
    </>
  )
}
