import { CONST } from '../../../../../../const/const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//  Hunter class A
export const EPL_FB_JUMPER_L6 = {
  vMain: 1,
  vSub: 0,
  vFix: 0,
  disciplineType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DISCIPLINE.TYPE.STANDARD,
  discipline: 'Jumper L6',
  validationStatus: 'unknown',
  divisionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.DIVISION.TYPE.STANDARD,
  divisionItemsRules: null,
  divisions: [
    // ************************************************************
    //  <JUMPER L6> 3321 - Amateur Jumper (All Heights)
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3321',
      divisionTitle: 'Amateur Jumper (All Heights)',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L6> 3301 - Junior Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3301',
      divisionTitle: 'Junior Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L6> 9400 - Young Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '9400',
      divisionTitle: 'Young Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L6> 9450 - USHJA Young Jumper Qualifier
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '9450',
      divisionTitle: 'USHJA Young Jumper Qualifier',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L6> 3500 - Child/Adult Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3500',
      divisionTitle: 'Child/Adult Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L6> 9500 - Pony Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '9500',
      divisionTitle: 'Pony Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L6> 3111 - Thoroughbred Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3111',
      divisionTitle: 'Thoroughbred Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L6> 3425 - U25 Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3425',
      divisionTitle: 'U25 Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L6> 3800 - Miscellaneous Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3800',
      divisionTitle: 'Miscellaneous Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L6> 14000 - USHJA Jumper Classic Series
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '14000',
      divisionTitle: 'USHJA Jumper Classic Series',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L6> 3400 - Young Rider Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3400',
      divisionTitle: 'Young Rider Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L6> 3700 - $25,000 & Up Grand Prix
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3700',
      divisionTitle: '$25,000 & Up Grand Prix',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <JUMPER L6> 3600 - ZONE 9 Jumper
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3600',
      divisionTitle: 'ZONE 9 Jumper',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
  ],
}
