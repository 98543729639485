import { createSlice } from '@reduxjs/toolkit'
import { IUserInterface } from '../../models/users/user.interface'
// IUserInterface

interface IRegisteredUser {
  data: IUserInterface | null
}
const initialSystemState: IRegisteredUser = {
  data: null,
}

const registeredUserSlice = createSlice({
  name: 'registeredUser',
  initialState: initialSystemState,
  reducers: {
    setRegisteredUser(state, action) {
      return { ...state, data: action.payload }
    },
  },
})

export const { setRegisteredUser } = registeredUserSlice.actions

export default registeredUserSlice.reducer
