// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { IonItem, IonList, IonPopover } from '@ionic/react'
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { CONST } from '../../../../const/const'
import { setActiveFilters } from '../../../../store/filters/filterSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { setUserMode } from '../../../../store/system/systemSlice'
import {
  clearUserStoredData,
  selectUserNameAc,
  selectUserProfileImageUrl,
} from '../../../../store/user/userSlice'
import './TopNavigationRightMenuCommonComponent.css'
import FirebaseApp from '../../../../services/firebaseApp'
import { IMAGE_CONSTS } from '../../../../const/image-const'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { useIntercom } from 'react-use-intercom'
import { IconRulebook } from '../../../icons/IconRulebook'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface TopNavigationRightMenuCommonComponentProps {}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const TopNavigationRightMenuCommonComponent: React.FC<
  TopNavigationRightMenuCommonComponentProps
> = (props) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const userName = useAppSelector(selectUserNameAc)
  const userProfileImageUrl = useAppSelector(selectUserProfileImageUrl)

  const handleModal = useContext(HandleModalContext)?.handleModal

  const { show } = useIntercom()
  const [displayMenu, setDisplayMenu] = useState(false)
  const [comingSoon, setComingSoon] = useState(false)
  const [comingSoonMessage, setComingSoonMessage] = useState('Coming Soon...')
  const [helpMessage, setHelpMessage] = useState(false)
  const helpMessageText = (
    <p>
      Found a bug? Help us squash them! Report it in the{' '}
      <a onClick={show} className="underline cursor-pointer">
        Help Center
      </a>
      👇
    </p>
  )

  const onSwithAccountOptionClick = () => {
    setDisplayMenu(false)
    handleModal?.(true, MODAL_CONSTS.SWITCH_ACCOUNT)
  }

  const handleLogout = async () => {
    dispatch(setUserMode(null))
    dispatch(clearUserStoredData(true))
    dispatch(setActiveFilters(null))
    await FirebaseApp.auth.signOut()
    window.location.replace(CONST.ROUTES.LOGOUT.URL)

    if (0) console.log({ onSwithAccountOptionClick })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <div id="" className="flex-shrink-0 group block">
        <div className="flex items-center">
          <>
            <div
              className="mr-4 text-[#9ca2b5] rounded-lg p-1 px-4 border-[1px] border-[#9ca2b5] cursor-pointer"
              onClick={() => {
                setHelpMessage(true)
                setComingSoon(true)
              }}
            >
              BETA
            </div>

            <button
              onClick={() => {
                handleModal && handleModal(true, MODAL_CONSTS.AI_RULE_BOOK)
              }}
              className="group relative cursor-pointer mr-3"
            >
              <IconRulebook />
            </button>

            <div
              className="group relative cursor-pointer hover:text-red-500"
              onClick={() => {
                setComingSoonMessage('Direct messaging coming soon')
                setComingSoon(true)
              }}
            >
              <img
                className="inline-block  rounded-full p-1"
                src={'assets/cp_icons/Messaging.svg'}
                alt=""
              />
              {/*<img
                            className="absolute top-0 right-0"
                            src={'assets/cp_icons/notificationDot.svg'}
                            alt=""
                        />*/}
            </div>
            <div
              className="mx-3 relative cursor-pointer"
              onClick={() => {
                setComingSoonMessage('Push notifications coming soon')
                setComingSoon(true)
              }}
              id="notification-menu"
            >
              <img
                className="inline-block rounded-full p-1 rotate-[25deg]"
                src={'assets/cp_icons/Notification.svg'}
                alt=""
              />
              {/*<img
                                className="absolute top-0 right-0"
                                src={'assets/cp_icons/notificationDot.svg'}
                                alt=""
                            />*/}
            </div>
          </>

          <div
            className="rounded-full"
            onClick={() => {
              setDisplayMenu(true)
            }}
            id="right-side-menu"
          >
            <img
              className="inline-block rounded-full p-1"
              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
              src={!!userProfileImageUrl ? userProfileImageUrl : IMAGE_CONSTS.PLACEHOLDERS.USER}
              onError={(e) => {
                return ((e.target as any).src = IMAGE_CONSTS.PLACEHOLDERS.USER)
              }}
              alt=""
            />
          </div>
          <span
            onClick={() => {
              setDisplayMenu(true)
            }}
          ></span>
        </div>
      </div>

      <IonPopover
        isOpen={displayMenu}
        reference="trigger"
        trigger="right-side-menu"
        side="bottom"
        alignment="end"
        onDidDismiss={() => setDisplayMenu(false)}
        style={{ '--border-radius': '40px' }}
      >
        <IonList style={{ borderRadius: '40px !important' }}>
          <IonItem className="flex items-center capitalize">
            <div className="flex items-center capitalize">
              <div className="rounded-full bg-[#F0F3FA] flex justify-center items-center mr-4">
                <Link to="/allevent" className="cursor-pointer block w-[35px] h-[35px]">
                  <img
                    className="inline-block w-full h-full rounded-full object-cover"
                    src={
                      !!userProfileImageUrl ? userProfileImageUrl : IMAGE_CONSTS.PLACEHOLDERS.USER
                    }
                    onError={(e) => {
                      return ((e.target as any).src = IMAGE_CONSTS.PLACEHOLDERS.USER)
                    }}
                    alt=""
                  />
                </Link>
              </div>
              <div className="cursor-pointer flex item-center">{userName}</div>
            </div>

            <div
              style={{
                content: '""',
                position: 'fixed',
                bottom: '56px',
                left: '20px',
                right: '20px',
                height: '1px',
                background: '#000',
              }}
            ></div>
          </IonItem>

          <IonItem className="cursor-pointer" onClick={onSwithAccountOptionClick}>
            Switch
          </IonItem>

          <IonItem
            className="cursor-pointer"
            style={{ '--border-style': 'none' }}
            onClick={handleLogout}
          >
            Logout
          </IonItem>
        </IonList>
      </IonPopover>

      <IonPopover
        isOpen={comingSoon}
        reference="trigger"
        trigger="notification-menu"
        side="bottom"
        alignment="end"
        onDidDismiss={() => {
          setHelpMessage(false)
          setComingSoon(false)
        }}
      >
        <span>
          <h2 className="text-SeabiscuitDarkMainThemeColor  bold p-4">
            {helpMessage ? helpMessageText : comingSoonMessage}
          </h2>
        </span>
      </IonPopover>
    </>
  )
}

TopNavigationRightMenuCommonComponent.defaultProps = {}

export default TopNavigationRightMenuCommonComponent
