// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { motion } from 'framer-motion'

import ShowsHomePageManagementMetricsSubSection from './ShowsHomePageManagementMetricsSubSection'
import ShowsHomePageManagementMembersSubSection from './ShowsHomePageManagementMembersSubSection'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsHomePageManagementSection: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <motion.div
      className="w-full mx-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.1,
      }}
    >
      <ShowsHomePageManagementMetricsSubSection />

      <ShowsHomePageManagementMembersSubSection />
    </motion.div>
  )
}

export default ShowsHomePageManagementSection
