const basePath = '/assets/placeholders/'

const IMAGE_CONSTS = {
  PLACEHOLDERS: {
    HORSE: basePath.concat('Empty Horse.png'),
    USER: basePath.concat('Emptyuser.png'),
    EVENT: basePath.concat('Empty Event Portrait.png'),
    HORSE_PROFILE_PIC: '/assets/img/light/Horses_Sign.png',
    NEWS: '/assets/placeholders/Empty Event Portrait.png',
  },
}

export { IMAGE_CONSTS }
