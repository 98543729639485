// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { IEventPaperwork } from './event-paperwork.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import * as yup from 'yup'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export class EventPaperworkModel
  extends ModelBaseModel<IEventPaperwork>
  implements IModelBaseModel
{
  public v: number
  public id: string | null
  public owner: string | null
  public entryBlank: boolean
  public universalOrganizerWaver: boolean
  public USEFWaiverAndReleaseOfLiability: boolean
  public USEFEntryAgreement: boolean
  public USDFWaiverAndReleaseOfLiability: boolean
  public USEAWaiverAndReleaseOfLiability: boolean
  public USHJAWaiverAndReleaseOfLiability: boolean
  public created: Date
  public modified: Date

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: IEventPaperwork) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? null
    this.entryBlank = obj?.entryBlank ?? false
    this.universalOrganizerWaver = obj?.universalOrganizerWaver ?? true
    this.USEFWaiverAndReleaseOfLiability = obj?.USEFWaiverAndReleaseOfLiability ?? false
    this.USEFEntryAgreement = obj?.USEFEntryAgreement ?? false
    this.USDFWaiverAndReleaseOfLiability = obj?.USDFWaiverAndReleaseOfLiability ?? false
    this.USEAWaiverAndReleaseOfLiability = obj?.USEAWaiverAndReleaseOfLiability ?? false
    this.USHJAWaiverAndReleaseOfLiability = obj?.USHJAWaiverAndReleaseOfLiability ?? false

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new EventPaperworkModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  static validationSchema() {
    return yup.object({}).required()
  }
}
