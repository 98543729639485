import { createSlice } from '@reduxjs/toolkit'

// Types
import { IFilesSliceTypes } from './filesSlice.types'
import { RootState } from '../store'

const INITIAL_FILES_SLICE_STATE: IFilesSliceTypes['IInitialFilesSliceState'] = {
  fileUpload: {
    type: null,
    filesToUpload: [],
  },
}

const filesSlice = createSlice({
  name: 'files',
  initialState: INITIAL_FILES_SLICE_STATE,
  reducers: {
    setFilesToUpload: (
      state,
      { payload }: { payload: IFilesSliceTypes['ISetFilesToUploadArgs'] | null }
    ) => {
      if (!payload) {
        state.fileUpload = INITIAL_FILES_SLICE_STATE.fileUpload
        return
      }
      state.fileUpload.type = payload.type ?? ''
      state.fileUpload.filesToUpload = payload.downloadUrls
    },
  },
})

export const { setFilesToUpload } = filesSlice.actions

export const selectFilesToUpload = (state: RootState) => state.files.fileUpload

export default filesSlice.reducer
