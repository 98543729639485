import { where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { CONST } from '../../../../../const/const'
import { IFollowingInterface } from '../../../../../models/following/following.interface'
import { FollowingModel } from '../../../../../models/following/following.model'
import { getConvertedData } from '../../../../../models/interface.helper'
import FirestoreService from '../../../../../services/firestoreService'
import { useAppSelector } from '../../../../../store/hooks'
import { selectUserId } from '../../../../../store/user/userSlice'

import helpers from '../../../../../commonHelpers/helpers'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import { CustomError, getUserFullName } from '../../../../../helpers/helpers'
import { TEventRegisteredUsers } from '../../../../../models/event-registered-users/event-registered-users.interface'
import { EventRegisteredUsersModel } from '../../../../../models/event-registered-users/event-registered-users.model'
import { IUserInterface } from '../../../../../models/users/user.interface'
import { UserModel } from '../../../../../models/users/user.model'
import IUserDatatable from './useDatatable.types'
import { IEventInterface } from '../../../../../models/events/event.interface'
import { EventModel } from '../../../../../models/events/event.model'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PER_PAGE = 10
const FILE_NAME = 'useDatatable'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const useDatatable = () => {
  // Hooks and vars
  const userId = useAppSelector(selectUserId)

  const toastFunctions = useToasterHelper()

  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [fetchingMore, setFetchingMore] = useState(false)
  const [following, setFollowing] = useState<IFollowingInterface[]>([])
  const [data, setData] = useState<IUserDatatable['IDatatableRow'][]>([])
  const [blockLoading, setBlockLoading] = useState(false)
  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (!userId) return

    setLoading(true)
    getCompetitorFollowers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */

  const blockUserToggle = async (isFollowedBlocked: boolean, id: string) => {
    if (blockLoading) return null
    setBlockLoading(true)
    try {
      await FirestoreService.updateItem(COLLECTIONS.FOLLOWING.NAME, id, { isFollowedBlocked })

      const newData = [...data]
      const itemIndex = newData.findIndex((itm) => itm.colId === id)
      if (newData[itemIndex]) {
        newData[itemIndex].isFollowedBlocked = isFollowedBlocked
        setData(newData)
      }
    } catch (e) {
      console.log('=>(useDatatable.ts:71) e', e)
    } finally {
      setBlockLoading(false)
    }
  }

  async function getCompetitorFollowers() {
    const userIds: string[] = []
    const following_: IFollowingInterface[] = []

    let followingDoc: IFollowingInterface | null = null

    if (!userId) return

    try {
      followingDoc = following.at(-1) ?? null

      if (followingDoc) setFetchingMore(true)
      else setLoading(true)

      const competitorFollowingSnapshots = await FirestoreService.filterItems(
        COLLECTIONS.FOLLOWING.NAME,
        [where(COLLECTIONS.FOLLOWING.FIELDS.FOLLOWING_USER_ID.KEY, '==', userId)],
        PER_PAGE,
        null,
        null,
        following.at(-1)?.id ?? null
      )

      competitorFollowingSnapshots.docs.forEach((currDoc) => {
        followingDoc = getConvertedData(FollowingModel.fromFirestoreDoc(currDoc).toObject())

        if (followingDoc.followerUserId) {
          userIds.push(followingDoc.followerUserId)
        }

        following_.push(followingDoc)
      })

      setHasMore(following_.length <= PER_PAGE)

      addRequiredDetailsInFollowing({
        following: following_,
      })
    } catch (error: any) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'addRequiredDetailsInFollowing',
          message: error,
          devMessage: error,
        }),
      })

      setLoading(false)
      setFetchingMore(false)
    }
  }

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */
  const getCornerEvent: IUserDatatable['IGetCornerEventFn'] = async (args) => {
    let eventIds: string[] = []
    let event: IEventInterface | null = null
    let registrations: TEventRegisteredUsers[] = []
    let events: Map<string, IEventInterface> = new Map()
    let registration: TEventRegisteredUsers | null = null

    if (args.corner === 'last') {
      const registeredEventSnapshots = await FirestoreService.filterItems(
        COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        [
          where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY, '==', args.riderUserId),
          where(
            COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.KEY,
            '==',
            COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PAID
          ),
          where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.REGISTRATION_DATE.KEY, '<', new Date()),
        ],
        null,
        COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.REGISTRATION_DATE.KEY,
        'desc'
      )

      registeredEventSnapshots.forEach((currRegistrationDoc) => {
        registration = getConvertedData(
          EventRegisteredUsersModel.fromFirestoreDoc(currRegistrationDoc).toObject()
        )

        if (registration.eventId) eventIds.push(registration.eventId)

        registrations.push(registration)
      })

      const eventSnapshots = await FirestoreService.getItemsUsingIds(
        COLLECTIONS.EVENTS.NAME,
        eventIds
      )

      eventSnapshots.forEach((currEventSnapshot) => {
        events.set(
          currEventSnapshot.id,
          getConvertedData(EventModel.fromFirestoreDoc(currEventSnapshot).toObject())
        )
      })
    } else {
      events = args.events
      registrations = args.registrations
    }

    registration =
      registrations.find((currRegistraton) => {
        if (!currRegistraton.eventId) return false

        event = events.get(currRegistraton.eventId) ?? null

        if (!event) return false

        if (
          event.eventEndDate &&
          (args.corner === 'last'
            ? new Date(event.eventEndDate) < new Date()
            : new Date(event.eventEndDate) >= new Date())
        ) {
          return true
        } else {
          event = null
          return false
        }
      }) ?? null

    return {
      event,
      events,
      registrations,
    }
  }

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */
  const addRequiredDetailsInFollowing: IUserDatatable['IAddRequiredDetailsInFollowing'] = async (
    args
  ) => {
    let user: IUserInterface | null = null
    let lastEvent: IEventInterface | null = null
    let nextEvent: IEventInterface | null = null
    let getCornerEventReturnValue: IUserDatatable['IGetCornerEventFnReturnType'] = null as any

    const datatableItems: IUserDatatable['IDatatableRow'][] = []
    const { following } = args

    try {
      await helpers.asyncWhileLoop({
        loopCount: following.length,
        functionToFire: async (index) => {
          if (!following[index].followerUserId) return

          getCornerEventReturnValue = await getCornerEvent({
            riderUserId: following[index].followerUserId!,
            corner: 'last',
            events: new Map(),
            registrations: [],
          })

          lastEvent = getCornerEventReturnValue.event

          getCornerEventReturnValue = await getCornerEvent({
            riderUserId: following[index].followerUserId!,
            corner: 'recent',
            events: getCornerEventReturnValue.events ?? new Map(),
            registrations: getCornerEventReturnValue.registrations,
          })

          nextEvent = getCornerEventReturnValue.event

          const userSnapshot = await FirestoreService.getItem(
            COLLECTIONS.USERS.NAME,
            following[index].followerUserId!
          )

          user = UserModel.fromFirestoreDoc(userSnapshot)

          datatableItems.push({
            followingUserName: getUserFullName(user),
            followingUserProfilePicture: user.userProfilePicture,
            lastEvent: lastEvent
              ? {
                  id: lastEvent?.id ?? 'Unknown',
                  name: lastEvent?.eventName ?? 'Unknown',
                }
              : null,
            nextEvent: nextEvent
              ? {
                  id: nextEvent?.id ?? 'Unknown',
                  name: nextEvent?.eventName ?? 'Unknown',
                }
              : null,
            followingUserId: following[index].followingUserId!,
            followerUserId: following[index].followerUserId!,
            isFollowedBlocked: following[index].isFollowedBlocked,
            colId: following[index].id || undefined,
          })
        },
      })

      setFollowing(following)
      setData(datatableItems)
    } catch (error: any) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'addRequiredDetailsInFollowing',
          message: error,
          devMessage: error,
        }),
      })
    } finally {
      setLoading(false)
      setFetchingMore(false)
    }
  }

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */
  const fetchMore = () => {
    getCompetitorFollowers()
  }

  return {
    fetchMore,
    fetchingMore,
    data,
    hasMore,
    loading,
    blockLoading,
    blockUserToggle,
  }
}

export default useDatatable
