import { FC, useEffect, useState } from 'react'

// Third party
import { useParams } from 'react-router'

// Component
import ManageClinicTableWrapper from '../../ManageClinicTableWrapper'
import HorsesList from './components/HorsesList/HorsesList'

import useHorse from './hooks/useHorse'

// Constants
import { CONST } from '../../../../../../const/const'
import { IHorsesDataTypes } from './data/horses.data.types'

interface HorsesListingRootProps {
  className?: string
  isFullScreen?: boolean
}
const HorsesManage: FC<HorsesListingRootProps> = ({ className, isFullScreen }) => {
  const { eventId } = useParams<{ eventId?: string }>()
  const { loading, horses } = useHorse(eventId)

  const [searchValue, setSearchValue] = useState('')
  const [filteredHorses, setFilteredHorses] = useState<IHorsesDataTypes[]>([])

  const searchVal = (e: any) => {
    const eventKey = e.target.value
    setSearchValue(eventKey)
  }

  useEffect(() => {
    if (searchValue && searchValue.length > 0) {
      setFilteredHorses(
        horses.filter((horse) => horse.horseName?.toLowerCase().includes(searchValue.toLowerCase()))
      )
    } else {
      setFilteredHorses(horses)
    }
  }, [horses, searchValue])

  return (
    <ManageClinicTableWrapper
      title="Horses"
      eventId={eventId}
      className={className}
      isFullScreen={isFullScreen}
      redirectUrl={`${CONST.ROUTES.MANAGE.HORSES.URL}/${eventId}`}
    >
      <>
        {isFullScreen && (
          <div className="search-div-wrapper border relative rounded-xl w-96 border-[#D3DAEE] mb-2">
            <div className="relative rounded-full">
              <div className="absolute inset-y-0 left-0 flex z-50 items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-SeabiscuitDark200ThemeColor"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-2 pl-10 text-sm border-0 text-gray-900 z-40 rounded-full focus:ring-0 focus:outline-0 focus:rounded-full relative placeholder:text-SeabiscuitDark200ThemeColor"
                placeholder="Search horse..."
                onChange={searchVal}
              />
            </div>
          </div>
        )}
        <HorsesList
          isFullScreen={isFullScreen}
          loading={loading}
          searchValue={searchValue}
          horses={filteredHorses}
        />
      </>
    </ManageClinicTableWrapper>
  )
}

export default HorsesManage
