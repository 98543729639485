// Constants
import { CONST } from '../../../../const/const'
import { IMAGE_CONSTS } from '../../../../const/image-const'
import { disciplineData } from '../../../../fakeData/disciplineList'

// Types
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { useIonRouter } from '@ionic/react'
import moment from 'moment'
import { IHorseData } from '../../../../models/horse/horse.interface'
import {useHistory} from "react-router-dom";

type IGetFieldProps = { icon: ReactJSXElement | string; text_to_show?: any }

const ProfileHorseDataTab = ({
  horses,
  setShowHorseData,
  showHorseData,
}: {
  horses: IHorseData[]
  setShowHorseData?: React.Dispatch<React.SetStateAction<IHorseData[]>>
  showHorseData?: IHorseData
}) => {
  // Hooks and vars
  const router = useIonRouter()
  const history = useHistory()

  const getHorseDiscipline = (discipline: any) => {
    const horseBreed = disciplineData.filter((i: any) => {
      return i.value === discipline
    })
    return horseBreed[0]?.label
  }

  const icons = [
    '/assets/cp_icons/Name Tag.svg',
    '/assets/og_icons/Biotech.svg',
    '/assets/og_icons/Ruler Vertical.svg',
    '/assets/og_icons/Time.svg',
    '/assets/og_icons/Gender.svg',
    '/assets/og_icons/YearofHorse.svg',
    '/assets/og_icons/Mind Map.svg',
    '/assets/og_icons/Paint Palette.svg',
    '/assets/og_icons/Paint Brush.svg',
  ]

  const getField = ({ icon, text_to_show }: IGetFieldProps) => {
    return (
      <div className="relative flex mb-2 items-center border-solid border-[#D3DAEE] border overflow-hidden rounded-xl">
        {typeof icon === 'string' ? (
          <span className="mx-4 mr-6 absolute w-6 h-6">
            <img src={icon} alt="icon" className="object-cover w-full h-full" />
          </span>
        ) : (
          icon
        )}

        <input
          type="text"
          disabled={true}
          readOnly={true}
          // {...register(name)}
          value={text_to_show}
          className={`border border-transparent text-[14px] text-SeabiscuitDark200ThemeColor h-[56px] capitalize ${text_to_show ? 'pl-0' : 'pl-[62px]'} w-[100%] pl-[62px]`}
        />
      </div>
    )
  }

  const handleHorseData = async (id: string | undefined) => {
    router.push(`${CONST.ROUTES.HORSE_PUBLIC_PROFILE.URL}/${id}`)
    history.push(`${CONST.ROUTES.HORSE_PUBLIC_PROFILE.URL}/${id}`)
  }

  const handleHorseDatabyIndex = (index: number) => {
    switch (index) {
      case 0:
        return showHorseData?.horseName ?? ''
      case 1:
        return showHorseData?.horseBreed ?? ''
      case 2:
        return showHorseData?.horseHeight
      case 3:
        return moment(showHorseData?.horseDob).fromNow()
      case 4:
        return showHorseData?.horseGender
      case 5:
        return showHorseData?.horseDiscipline
      case 6:
        return showHorseData?.horseZone
      case 7:
        return showHorseData?.horseColor
      case 8:
        return showHorseData?.horseMarking
    }
    // return ""
  }

  return (
    <>
      {!showHorseData && (
        <>
          <div className="heading uppercase mb-3 font-md text-SeabiscuitDark200ThemeColor">
            HORSES
          </div>
          {horses.length ? (
            horses.map((horse, index) => {
              return (
                <div key={`horse_tab_horse${index}`} onClick={() => handleHorseData(horse.id)}>
                  <div
                    className="horse flex flex-row flex-wrap items-center rounded-lg py-1 px-2 cursor-pointer"
                    key={`teamMember${index}`}
                  >
                    <img
                      src={horse?.horseProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.HORSE}
                      className="mr-2 w-[45px] h-[45px] rounded-full"
                      alt="horse_image"
                      onError={(e) => {
                        e.currentTarget.src = IMAGE_CONSTS.PLACEHOLDERS.HORSE
                      }}
                    />

                    <div className="hourseDetails flex flex-col items-start">
                      <div className="hourseTitle text-SeabiscuitDark200ThemeColor text-md font-medium capitalize">
                        {horse?.horseName}
                      </div>
                      <div className="horse_details text-[10px] text-SeabiscuitLightParagraphTextColor flex flex-wrap capitalize">
                        <span className="flex items-center text-sm mr-1">
                          {getHorseDiscipline(horse?.horseDiscipline)}
                          {horse.horseZone && horse.horseZone !== ''
                            ? ` • ${horse.horseZone === 'international' ? '' : 'Zone'} ${horse.horseZone}`
                            : null}
                        </span>
                      </div>
                    </div>
                  </div>

                  {horses.length - 1 > index && <hr className=" md:my-2 my-1" />}
                </div>
              )
            })
          ) : (
            <span className="w-full text-sm text-SeabiscuitLightParagraphTextColor text-center">
              No data found
            </span>
          )}
        </>
      )}

      {showHorseData && (
        <>
          <div className="heading uppercase mb-3 font-md text-SeabiscuitDark200ThemeColor">
            HORSE DETAILS
          </div>
          {icons.map((data, index) => {
            return <>{getField({ icon: data, text_to_show: handleHorseDatabyIndex(index) })}</>
          })}
        </>
      )}
    </>
  )
}

export default ProfileHorseDataTab
