import React, { FC, useContext, useState } from 'react'
import { sumBy } from 'lodash'
import clsx from 'clsx'
import { Skeleton } from '@mui/material'

import InfiniteScrollDataTable from '../../../../../../../components/common/tables/InfiniteScrollDataTable'

import { generateName } from '../../../../../../../helpers/helpers'

import {
  DUMMY_REGISTRATION_FEES_STATS,
  IRegistrationByDayEx,
  RegistrationFeesTypeEx,
  sortByRank,
} from '../../ManageClinicNOtherOperationsTab'

import FirestoreService from '../../../../../../../services/firestoreService'

import { HandleModalContext } from '../../../../../../../layout/mainlayout/MainLayout'

import { IRegistrationByDayInterface } from '../../../../../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationByDayModel } from '../../../../../../../models/registrations-by-day/registrationByDay.model'
import { IHorseData } from '../../../../../../../models/horse/horse.interface'
import { RegistrationFeesType } from '../../../../../../../models/event-fees/event-fees.interface'
import { getConvertedData } from '../../../../../../../models/interface.helper'
import { UserModel } from '../../../../../../../models/users/user.model'
import { IUserInterface } from '../../../../../../../models/users/user.interface'

import { IMAGE_CONSTS } from '../../../../../../../const/image-const'
import { MODAL_CONSTS } from '../../../../../../../const/modal-const'
import { CONST } from '../../../../../../../const/const'

import { customStyles } from '../../../../../../allEvents/root/AllEventsTableStyles'
import { sheets } from '../../../data/sheets'
import { convertTime } from '../../../../../../../helpers/time'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

interface ScoringTableProps {
  loading: boolean
  increment: string
  data: any[]
  setData: (value: any) => void
  updateFeesKeys: (key: object, uuid: string) => void
  horses: IHorseData[]
  getRegistrationsByDay: () => Promise<IRegistrationByDayInterface[]>
  onChangeJudge: (row: RegistrationFeesTypeEx, values: { label: string; value: string }[]) => void
  fee: RegistrationFeesType | null
  selectedClass: { label: string; value: string }
}
export const ScoringTable: FC<ScoringTableProps> = ({
  loading,
  data,
  setData,
  increment,
  updateFeesKeys,
  horses,
  getRegistrationsByDay,
  onChangeJudge,
  fee,
  selectedClass,
}) => {
  const handleModalContext = useContext(HandleModalContext)

  const [scoringLoading, setScoringLoading] = useState(false)

  const onBlurTotalPoints = (event: any, row: IRegistrationByDayInterface) => {
    let registrationsByDay: IRegistrationByDayInterface[] = data.map((registrationByDay) => {
      if (registrationByDay.id === row.id) {
        return {
          ...registrationByDay,
          score: {
            ...registrationByDay.score,
            totalPoints: event.target.value ?? '',
          },
        }
      } else {
        return registrationByDay
      }
    })

    registrationsByDay = sortByRank(registrationsByDay, fee?.isAsc ? 'asc' : 'desc')

    registrationsByDay = registrationsByDay.map((registrationByDay, index) => {
      return {
        ...registrationByDay,
        ...(registrationByDay.score && {
          score: { ...registrationByDay.score, rank: index + 1 },
        }),
      }
    })

    setData(registrationsByDay)

    // Update firestore collection
    registrationsByDay.map(async (registrationByDay) => {
      const item = new RegistrationByDayModel(registrationByDay)

      await FirestoreService.updateItem(
        COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        item.id,
        item.toFirestore()
      )
    })
  }

  const addSheetAndJudges = async (row: IRegistrationByDayInterface) => {
    const sheet = sheets.find((current) => current.id === fee?.scoreCard)
    let judges: IUserInterface[] = []

    const userSnaps = await FirestoreService.getItemsUsingIds(
      COLLECTIONS.USERS.NAME,
      fee?.judges ?? []
    )

    userSnaps.forEach((currDoc) => {
      judges.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
    })

    const updatedRegistrationByDay = {
      ...row,
      score: {
        ...row.score,
        sheet: {
          name: sheet?.name ?? '',
          type: sheet?.type ?? '',
        },
        judges:
          judges?.map((judge) => ({
            id: judge.id,
            name: judge.userFirstName
              ? `${judge.userFirstName} ${judge.userLastName}`
              : judge.userName ?? '',
            furtherRemarks: sheet?.furtherRemarks ?? '',
            sections: sheet?.sections ?? [],
            errors: sheet?.errors ?? 0,
          })) ?? null,
      },
    }

    // Update firestore collection
    const item = new RegistrationByDayModel(updatedRegistrationByDay)

    await FirestoreService.updateItem(
      COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      item.id,
      item.toFirestore()
    )
  }

  const columnsScoring = [
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
          <span className="whitespace-nowrap">Ride Time</span>
        </span>
      ),
      cell: (row: IRegistrationByDayEx, index: number) => (
        <div className="flex items-center">
          {row.loading ? (
            <Skeleton
              variant="rounded"
              width={60}
              height={20}
              className="mb-2"
              style={{ backgroundColor: '#F1F3F8' }}
            />
          ) : (
            <div className="text-SeabiscuitDark200ThemeColor text-nr justify-center w-full flex capitalize">
              {row.orderOfGoScratched ? (
                <span className="text-SeabiscuitDark200ThemeColor/50">Scratched</span>
              ) : increment ? (
                convertTime(
                  `${fee?.startTimeHours}:${fee?.startTimeMinutes} ${fee?.startTimeFormat}`,
                  increment,
                  index
                )
              ) : (
                '00:00:00 AM'
              )}
            </div>
          )}
        </div>
      ),
      width: '10%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
          <span className="whitespace-nowrap">Rider</span>
        </span>
      ),
      cell: (row: IRegistrationByDayEx) => {
        return (
          <div className="flex items-center gap-4">
            <span className="w-14 h-14 p-1 rounded-full block shrink-0 my-2">
              {row.loading ? (
                <Skeleton
                  variant="circular"
                  width={48}
                  height={48}
                  className="mb-2"
                  style={{ backgroundColor: '#F1F3F8' }}
                />
              ) : (
                <img
                  src={row.riderProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
                  alt="icons"
                  className="object-cover w-[48px] h-[48px] rounded-full"
                  onError={(e) =>
                    ((e.target as any).src = `https://ui-avatars.com/api/?name=${generateName(
                      row.riderName ?? ''
                    )}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                  }
                />
              )}
            </span>

            {row.loading ? (
              <Skeleton
                variant="rounded"
                width={100}
                height={20}
                style={{ backgroundColor: '#F1F3F8' }}
              />
            ) : (
              <div className="flex flex-col">
                <span className="text-SeabiscuitDark200ThemeColor font-bold text-base">
                  {row?.riderName}
                </span>
                <span className="text-SeabiscuitDark200ThemeColor/50 text-base">
                  Back number • {Number(row.backNumber) > 0 ? row.backNumber : 'N/A'}
                </span>
              </div>
            )}
          </div>
        )
      },
      width: '30%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
          <span className="whitespace-nowrap">Horse</span>
        </span>
      ),
      cell: (row: IRegistrationByDayEx) => {
        const horse = horses.find((horse) => horse.id === row.horseId)

        return (
          <div className="flex items-center gap-4">
            <span className="w-14 h-14 p-1 rounded-full block shrink-0 my-2">
              {row.loading ? (
                <Skeleton
                  variant="circular"
                  width={48}
                  height={48}
                  className="mb-2"
                  style={{ backgroundColor: '#F1F3F8' }}
                />
              ) : (
                <img
                  src={row.horseProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
                  alt="icons"
                  className="object-cover w-[48px] h-[48px] rounded-full"
                  onError={(e) =>
                    ((e.target as any).src = `https://ui-avatars.com/api/?name=${generateName(
                      row.horseName ?? ''
                    )}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                  }
                />
              )}
            </span>

            {row.loading ? (
              <Skeleton
                variant="rounded"
                width={100}
                height={20}
                style={{ backgroundColor: '#F1F3F8' }}
              />
            ) : (
              <div className="flex flex-col">
                <span className="text-SeabiscuitDark200ThemeColor font-bold text-base">
                  {row?.horseName ?? 'No Horse'}
                </span>
                <span className="text-SeabiscuitDark200ThemeColor/50 text-base">
                  Trainer •{' '}
                  {horse?.trainer ? <span className="underline">{horse?.trainer}</span> : 'N/A'}
                </span>
              </div>
            )}
          </div>
        )
      },
      width: '30%',
    },
    {
      cell: (row: IRegistrationByDayEx) => {
        return (
          <div className="justify-end w-full flex">
            {Number(row.score?.totalPoints) > 0 && (
              <img className="w-5" src="/assets/cp_icons/Lock.svg" alt="" />
            )}
          </div>
        )
      },
      width: '5%',
    },
    {
      name: (
        <div className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center justify-end w-full">
          <button
            className="whitespace-nowrap flex items-center gap-1 hover:opacity-70 transition-all"
            onClick={() => updateFeesKeys({ isAsc: !fee?.isAsc }, selectedClass.value)}
          >
            Score
            <img
              src="/assets/og_icons/arrow.svg"
              alt=""
              className={clsx('w-2', fee?.isAsc ? 'rotate-0' : 'rotate-180')}
            />
          </button>
        </div>
      ),
      cell: (row: IRegistrationByDayEx) => {
        let max = 0
        if (row.score?.judges) {
          max = sumBy(row.score.judges[0]?.sections, (section) => section.max)
        }

        return (
          <div className="justify-end w-full flex">
            {row.loading ? (
              <Skeleton
                variant="rounded"
                width={80}
                height={20}
                style={{ backgroundColor: '#F1F3F8' }}
              />
            ) : (
              <>
                {fee?.scoreCard && fee?.scoreCard !== 'Default' ? (
                  <button
                    className="cursor-pointer text-SeabiscuitDark200ThemeColor text-base text-right hover:opacity-70 transition-all"
                    onClick={() => {
                      handleModalContext?.handleModal(true, MODAL_CONSTS.SCORING, {
                        rider: row,
                        onSaveScoring: getRegistrationsByDay,
                        editable: true,
                      })
                    }}
                  >
                    {Number(row.score?.totalPoints) > 0 ? (
                      <div className="flex items-center gap-2">
                        <span className="font-bold">{row.score?.totalPoints}</span> •{' '}
                        <p>{((Number(row.score?.totalPoints) / Number(max)) * 100).toFixed(3)}%</p>
                      </div>
                    ) : (
                      <span className="opacity-50">Enter number</span>
                    )}
                  </button>
                ) : (
                  <div className="cursor-pointer text-SeabiscuitDark200ThemeColor text-base text-right hover:opacity-70 transition-all">
                    <input
                      style={{ boxShadow: 'none', outline: 'none' }}
                      type="number"
                      className={clsx(
                        'border-0 outline-0 text-SeabiscuitDark200ThemeColor text-base text-right hover:opacity-70 transition-all cursor-pointer'
                      )}
                      placeholder="Enter number"
                      defaultValue={Number(row.score?.totalPoints) ?? ''}
                      onBlur={(event) => onBlurTotalPoints(event, row)}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )
      },
      width: '15%',
    },
    {
      name: (
        <div className="w-full text-SeabiscuitDark200ThemeColor font-semibold text-sm flex justify-center items-center">
          <span className="whitespace-nowrap">Rank</span>
        </div>
      ),
      cell: (row: IRegistrationByDayEx) => {
        return (
          <div className="flex items-center justify-center w-full">
            {row.loading || scoringLoading ? (
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                style={{ backgroundColor: '#F1F3F8' }}
              />
            ) : (
              <button
                className={clsx(
                  'rounded-full text-base flex items-center justify-center w-10 h-10 hover:opacity-70',
                  row.score?.rank === 1 && Number(row.score?.totalPoints)
                    ? 'bg-SeabiscuitGreenThemeColor text-white'
                    : row.score?.rank === 2 && Number(row.score?.totalPoints)
                      ? 'bg-[#FFBD00] text-white'
                      : row.score?.rank === 3 && Number(row.score?.totalPoints)
                        ? 'bg-[#3B6AF6] text-white'
                        : 'bg-SeabiscuitGrayThemeColor text-SeabiscuitDark200ThemeColor'
                )}
                onClick={async () => {
                  setScoringLoading(true)

                  if (fee?.scoreCard && !row.score?.sheet?.name) {
                    await addSheetAndJudges(row)
                    const rows: IRegistrationByDayInterface[] = await getRegistrationsByDay()
                    const currentRow = rows.find((current) => current.id === row.id)
                    if (currentRow?.score) row.score = currentRow.score
                  }

                  setScoringLoading(false)
                  handleModalContext?.handleModal(true, MODAL_CONSTS.SCORING, {
                    rider: row,
                    onSaveScoring: getRegistrationsByDay,
                    onChangeJudge,
                    editable: true,
                  })
                }}
              >
                {row.score?.rank ? row.score.rank : 0}
              </button>
            )}
          </div>
        )
      },
      width: '10%',
    },
  ]

  return (
    <InfiniteScrollDataTable
      hasMore={false}
      className="exhibitorListTable"
      columns={columnsScoring}
      customStyles={customStyles}
      data={loading ? DUMMY_REGISTRATION_FEES_STATS : data}
    />
  )
}
