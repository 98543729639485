import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { DurationList } from '../../../../fakeData/durationList'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { EventFeesModel } from '../../../../models/event-fees/event-fees.model'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import AmountInput from '../../../common/inputs/AmountInput'
import { customStyles } from '../../../customStyles/ReactSelectCustomStyle'
import TogglesElement from '../../../elements/toggles/toggles/TogglesElement'
import EventFormContainerComponent from '../container/EventFormContainerComponent'
import EventSchedulingFormComponentVerticalLine from '../scheduling/components/EventSchedulingFormComponentVerticalLine'

import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import Select, { components } from 'react-select'
import { v4 as uuidv4 } from 'uuid'
import { CONST } from '../../../../const/const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { IEventFees } from '../../../../models/event-fees/event-fees.interface'
import { ModelBaseModel } from '../../../../models/model-base/model-base.model'
import Input from '../../../common/inputs/Input'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import './EventFeesFormComponent.css'

const eventFeesFormDefaultValues = new EventFeesModel().toObject()
const eventFeesFormSchema = EventFeesModel.validationSchema()

interface EventFeesFormComponentProps {
  data: IEventFees | null
  onValid: any
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  handleModal?: any
  eventTab?: any
  nextEventTab?: string
}
const Subheader: React.FC<{ title: string; subtitle: string }> = ({ title, subtitle }) => (
  <>
    <p className="text-lg font-bold text-SeabiscuitDark200ThemeColor mt-5">{title}</p>
    <div className="text-SeabiscuitDark200ThemeColor text-[14px] opacity-50 mb-6">{subtitle}</div>
  </>
)

const EventFeesFormComponent: React.FC<EventFeesFormComponentProps> = ({
  eventTab,
  nextEventTab,
  data,
  handleModal,
  onInvalid,
  onInvalidAndExit,
  onValid,
  onValidAnExit,
}) => {
  // Hooks and vars
  const handleModalContext = useContext(HandleModalContext)

  const {
    watch,
    reset,
    trigger,
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...eventFeesFormDefaultValues },
    resolver: yupResolver(eventFeesFormSchema),
    mode: 'onChange',
  })

  const toastFunctions = useToasterHelper()

  const [dataIncrementAttrProps, setDataIncrementAttrProps] = useState({})

  const extraStallFees = useFieldArray({
    control,
    name: 'extraStallFees',
  })

  const extraBeddingFees = useFieldArray({
    control,
    name: 'extraBeddingFees',
  })

  const extraFeedFees = useFieldArray({
    control,
    name: 'extraFeedFees',
  })

  const extraRefunds = useFieldArray({
    control,
    name: 'extraRefunds',
  })

  const extraGovernanceFees = useFieldArray({
    control,
    name: 'extraGovernanceFees',
  })

  const extraOtherFees = useFieldArray({
    control,
    name: 'extraOtherFees',
  })

  let valid = false

  useEffect(() => {
    if (data) reset(data)
    else reset(eventFeesFormDefaultValues)

    setValue('eventTab' as any, eventTab)

    trigger().then()

    switch (data?.paymentOption) {
      case 'IncreaseAllPrices':
        setDataIncrementAttrProps({
          'data-increment-by': 5,
        })
        break

      case 'ChargeRidersAndSpectators':
        setDataIncrementAttrProps({
          'data-increment-by': 2.9,
        })
        break

      default:
        setDataIncrementAttrProps({})
    }
  }, [eventTab, data, trigger])

  // Functions

  /**
   *
   * @param noteInputRef Input reference
   * @info Opens note modal
   */
  const openAddNotesModal = (noteInputRef: any) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NOTE, {
      noteInputRef,
      setValue,
    })
  }

  const LabelIcon = ({ src }: { src: string }) => {
    return <img src={src} width="16px" height="16px" alt="icons" />
  }

  const getDurationSelectedValue = (value: any) => {
    if (typeof value === 'string')
      return DurationList.find((curr) => curr?.value?.toLowerCase() === value?.toLocaleLowerCase())

    if (typeof value === 'object' && 'label' in value)
      return DurationList.find(
        (curr) => curr?.value?.toLowerCase() === value?.value?.toLocaleLowerCase()
      )
  }

  const invalidFormHandler = (errors: any) => {
    if (errors?.registrationFees?.type === 'Registration fees validation test') {
      toastFunctions.error({
        message: errors?.registrationFees?.message,
      })
    } else {
      console.error(errors)
      toastFunctions.error({
        message: 'Invalid',
      })
    }
  }

  return (
    <EventFormContainerComponent>
      <>
        <EventFormHeaderComponent
          title={
            eventTab && eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
              ? 'Entries'
              : 'Fees'
          }
          description={
            eventTab && eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
              ? 'Add fees and entry details'
              : 'Add fees to your horse show'
          }
        >
          <EventFormFooterCommonComponent
            eventTab={eventTab}
            nextEventTab={nextEventTab}
            onNext={(e, publishEvent) => {
              handleSubmit(
                (data_) =>
                  publishEvent({
                    dataToSave: data_,
                    tabName: 'EventFees',
                    validFormHandler: onValid,
                  }),
                eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
                  ? invalidFormHandler
                  : onInvalid
              )(e)
            }}
            onSaveAndExit={(e, publishEvent) => {
              handleSubmit(
                (data) =>
                  publishEvent({
                    dataToSave: data,
                    publish: false,
                    tabName: 'EventFees',
                    validFormHandler: onValidAnExit,
                  }),
                eventTab === CONST.UI.EVENTS.CREATE_EDIT.TABS.REGISTRATIONS.VALUE
                  ? invalidFormHandler
                  : onInvalidAndExit
              )(e)
            }}
          />
        </EventFormHeaderComponent>
        <Subheader title="Refunds" subtitle="Define refund policies for this event" />
        <div className="mt-4 grid gap-2 grid-cols-[100%] tablet:grid-cols-[200px_120px_auto] laptop:grid-cols-[200px_120px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Before closing date</div>
          </div>

          {
            (valid = !!(
              watch(`beforeClosingDateRefund.status`) &&
              watch(`beforeClosingDateRefund.note`) &&
              new ModelBaseModel().getNum(watch(`beforeClosingDateRefund.percentageAlias`))
            ))
          }

          <div className="flex items-end">
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor"></div>
            <Controller
              name={`beforeClosingDateRefund.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() =>
                    setValue(
                      `beforeClosingDateRefund.status`,
                      !watch(`beforeClosingDateRefund.status`)
                    )
                  }
                />
              )}
            />
          </div>
          <div>
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
              Percentage
            </div>
            <AmountInput
              name="beforeClosingDateRefund.percentageAlias"
              disable={!watch('beforeClosingDateRefund.status')}
              register={register}
              postFix="%"
              placeholder={!watch(`beforeClosingDateRefund.status`) ? 'Eg: 50%' : 'Percent'}
              clear={!watch(`beforeClosingDateRefund.status`)}
              childWidth={24}
              className="!h-[48px] flex"
              inputClassName="px-6"
              valid={valid}
              validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
              invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
              value={watch(`beforeClosingDateRefund.percentageAlias`)}
            />
          </div>
          <div className="tablet:col-start-2 tablet:col-span-2 laptop:col-span-1 laptop:col-start-auto">
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
              Note
            </div>
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <Input
                valid={valid}
                readOnly={true}
                register={register}
                showPostFixIcon={valid}
                placeholder="Enter note"
                className="!h-[48px] px-6 relative"
                name={`beforeClosingDateRefund.note`}
                postFixIcon="/assets/cp_icons/Ok-3.svg"
                clear={!watch(`beforeClosingDateRefund.status`)}
                disabled={!watch(`beforeClosingDateRefund.status`)}
                value={watch(`beforeClosingDateRefund.note`) as any}
                validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
                invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
                onClick={(e) => openAddNotesModal(e.target)}
              />
            </div>
          </div>
        </div>
        {/*<EventFeesFormRefunds*/}
        {/*    header={true}*/}
        {/*    handler={formDataHandle}*/}
        {/*    name="before"*/}
        {/*    values={formData.refunds.before}*/}
        {/*    caption="Before closing date"*/}
        {/*    launchNote={()=>setNoteModal(true)}*/}
        {/*/>*/}
        {/*======================*/}
        {/* After closing date */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_120px_auto] laptop:grid-cols-[200px_120px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">After closing date</div>
          </div>

          {
            (valid = !!(
              watch(`afterClosingDateRefund.status`) &&
              watch(`afterClosingDateRefund.note`) &&
              new ModelBaseModel().getNum(watch(`afterClosingDateRefund.percentageAlias`))
            ))
          }

          <div>
            <Controller
              name={`afterClosingDateRefund.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() =>
                    setValue(
                      `afterClosingDateRefund.status`,
                      !watch(`afterClosingDateRefund.status`)
                    )
                  }
                />
              )}
            />
          </div>
          <div>
            <AmountInput
              name="afterClosingDateRefund.percentageAlias"
              disable={!watch('afterClosingDateRefund.status')}
              register={register}
              postFix="%"
              placeholder={!watch(`afterClosingDateRefund.status`) ? 'Eg: 50%' : 'Percent'}
              clear={!watch(`afterClosingDateRefund.status`)}
              childWidth={24}
              className="!h-[48px] flex"
              inputClassName="px-6"
              valid={valid}
              validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
              invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
              value={watch(`afterClosingDateRefund.percentageAlias`)}
            />
          </div>
          <div className="tablet:col-start-2 tablet:col-span-2 laptop:col-span-1 laptop:col-start-auto">
            <Input
              valid={valid}
              clear={!watch(`afterClosingDateRefund.status`)}
              readOnly={true}
              register={register}
              showPostFixIcon={valid}
              placeholder="Enter note"
              className="!h-[48px] px-6 relative"
              name={`afterClosingDateRefund.note`}
              postFixIcon="/assets/cp_icons/Ok-3.svg"
              disabled={!watch(`afterClosingDateRefund.status`)}
              value={watch(`afterClosingDateRefund.note`) as any}
              validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
              invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
              onClick={(e) => openAddNotesModal(e.target)}
            />
          </div>
        </div>
        {
          (valid = !!(
            watch(`afterClosingDateRefund.status`) &&
            watch(`afterClosingDateRefund.note`) &&
            new ModelBaseModel().getNum(watch(`afterClosingDateRefund.percentageAlias`))
          ))
        }
        {/*<EventFeesFormRefunds*/}
        {/*    header={false}*/}
        {/*    handler={formDataHandle}*/}
        {/*    name="after"*/}
        {/*    values={formData.refunds.after}*/}
        {/*    caption="After closing date"*/}
        {/*    launchNote={()=>setNoteModal(true)}*/}
        {/*/>*/}
        {/*======================*/}
        {/* Show cancellation - weather */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_120px_auto] laptop:grid-cols-[200px_120px_200px_auto]">
          <div className="items-start gap-1">
            <div className="text-SeabiscuitDark200ThemeColor inline">
              Show cancellation - weather
            </div>
          </div>

          {
            (valid = !!(
              watch(`showCancellationRefundWeather.status`) &&
              watch(`showCancellationRefundWeather.note`) &&
              new ModelBaseModel().getNum(watch(`showCancellationRefundWeather.percentageAlias`))
            ))
          }

          <div>
            <Controller
              name={`showCancellationRefundWeather.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() =>
                    setValue(
                      `showCancellationRefundWeather.status`,
                      !watch(`showCancellationRefundWeather.status`)
                    )
                  }
                />
              )}
            />
          </div>
          <div>
            <AmountInput
              name="showCancellationRefundWeather.percentageAlias"
              disable={!watch('showCancellationRefundWeather.status')}
              register={register}
              postFix="%"
              placeholder={!watch(`showCancellationRefundWeather.status`) ? 'Eg: 50%' : 'Percent'}
              clear={!watch(`showCancellationRefundWeather.status`)}
              childWidth={24}
              className="!h-[48px] flex"
              inputClassName="px-6"
              valid={valid}
              validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
              invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
              value={watch(`showCancellationRefundWeather.percentageAlias`)}
            />
          </div>
          <div className="tablet:col-start-2 tablet:col-span-2 laptop:col-span-1 laptop:col-start-auto">
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <Input
                valid={valid}
                showPostFixIcon={valid}
                validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
                invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
                readOnly={true}
                register={register}
                placeholder="Enter note"
                className="!h-[48px] px-6 relative"
                name={`showCancellationRefundWeather.note`}
                postFixIcon="/assets/cp_icons/Ok-3.svg"
                clear={!watch(`showCancellationRefundWeather.status`)}
                disabled={!watch(`showCancellationRefundWeather.status`)}
                value={watch(`showCancellationRefundWeather.note`) as any}
                onClick={(e) => openAddNotesModal(e.target)}
              />
            </div>
          </div>
        </div>
        {
          (valid = !!(
            watch(`showCancellationRefundBioSecurity.status`) &&
            watch(`showCancellationRefundBioSecurity.note`) &&
            new ModelBaseModel().getNum(watch(`showCancellationRefundBioSecurity.percentageAlias`))
          ))
        }
        {/*======================*/}
        {/* Show cancellation - biosecurity */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_120px_auto] laptop:grid-cols-[200px_120px_200px_auto]">
          <div className="items-start gap-1">
            <div className="text-SeabiscuitDark200ThemeColor inline">
              Show cancellation - biosecurity
            </div>
          </div>
          <div>
            <Controller
              name={`showCancellationRefundBioSecurity.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() =>
                    setValue(
                      `showCancellationRefundBioSecurity.status`,
                      !watch(`showCancellationRefundBioSecurity.status`)
                    )
                  }
                />
              )}
            />
          </div>
          <div>
            <AmountInput
              name="showCancellationRefundBioSecurity.percentageAlias"
              disable={!watch('showCancellationRefundBioSecurity.status')}
              register={register}
              postFix="%"
              placeholder={
                !watch(`showCancellationRefundBioSecurity.status`) ? 'Eg: 50%' : 'Percent'
              }
              clear={!watch(`showCancellationRefundBioSecurity.status`)}
              childWidth={24}
              className="!h-[48px] flex"
              inputClassName="px-6"
              valid={valid}
              validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
              invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
              value={watch(`showCancellationRefundBioSecurity.percentageAlias`)}
            />
          </div>
          <div className="tablet:col-start-2 tablet:col-span-2 laptop:col-span-1 laptop:col-start-auto">
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <Input
                valid={valid}
                showPostFixIcon={valid}
                validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
                invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
                readOnly={true}
                register={register}
                placeholder="Enter note"
                className="!h-[48px] px-6 relative"
                name={`showCancellationRefundBioSecurity.note`}
                postFixIcon="/assets/cp_icons/Ok-3.svg"
                clear={!watch(`showCancellationRefundBioSecurity.status`)}
                disabled={!watch(`showCancellationRefundBioSecurity.status`)}
                value={watch(`showCancellationRefundBioSecurity.note`) as any}
                onClick={(e) => openAddNotesModal(e.target)}
              />
            </div>
          </div>
        </div>
        {/*<EventFeesFormRefunds*/}
        {/*    header={false}*/}
        {/*    handler={formDataHandle}*/}
        {/*    name="show"*/}
        {/*    values={formData.refunds.show}*/}
        {/*    caption="Show cancellation"*/}
        {/*    launchNote={()=>setNoteModal(true)}*/}
        {/*/>*/}
        {/*======================*/}
        {/* Extra Refunds */}
        {/*======================*/}
        {extraRefunds.fields.map((field, index) => {
          valid = !!(
            !!watch(`extraRefunds.${index}.name`) &&
            watch(`extraRefunds.${index}.status`) &&
            watch(`extraRefunds.${index}.note`) &&
            new ModelBaseModel().getNum(watch(`extraRefunds.${index}.actualCostAlias`)) &&
            new ModelBaseModel().getNum(watch(`extraRefunds.${index}.available`))
          )

          return (
            <div
              key={field.id}
              className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_120px_auto_auto_25px] laptop:grid-cols-[200px_120px_200px_auto_25px]"
            >
              <div className="flex items-center gap-1">
                <Input
                  className="!h-12 px-6"
                  register={register}
                  placeholder="Add refund"
                  valid={valid}
                  name={`extraRefunds.${index}.name`}
                  clear={!watch(`extraRefunds.${index}.status`)}
                  value={watch(`extraRefunds.${index}.name`) as any}
                  validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
                  invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
                  disabled={!watch(`extraRefunds.${index}.status`)}
                />
              </div>

              {
                (valid = !!(
                  watch(`extraRefunds.${index}.status`) &&
                  watch(`extraRefunds.${index}.note`) &&
                  new ModelBaseModel().getNum(watch(`extraRefunds.${index}.percentageAlias`))
                ))
              }

              <div>
                <Controller
                  name={`extraRefunds.${index}.status`}
                  control={control}
                  render={({ field: { value } }) => {
                    return (
                      <TogglesElement
                        on={value}
                        onToggle={() => {
                          setValue(
                            `extraRefunds.${index}.status`,
                            !watch(`extraRefunds.${index}.status`)
                          )
                        }}
                      />
                    )
                  }}
                />
              </div>
              <div>
                <AmountInput
                  postFix="%"
                  register={register}
                  name={`extraRefunds.${index}.percentageAlias`}
                  placeholder={!watch(`extraRefunds.${index}.status`) ? 'Eg: 50%' : 'Percent'}
                  clear={
                    !watch(`extraRefunds.${index}.status`) ||
                    watch(`extraRefunds.${index}.percentageAlias`) === '$0'
                  }
                  disable={!watch(`extraRefunds.${index}.status`)}
                  value={watch(`extraRefunds.${index}.percentageAlias`)}
                  inputClassName="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor"
                  className={clsx(
                    valid
                      ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                      : 'bg-white border-SeabiscuitLightThemeColorD3',
                    'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
                  )}
                />
              </div>

              <div className="tablet:col-start-2 tablet:col-span-2 laptop:col-span-1 laptop:col-start-auto">
                <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
                  <Input
                    valid={valid}
                    showPostFixIcon={valid}
                    validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
                    invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
                    readOnly={true}
                    register={register}
                    placeholder="Enter note"
                    className="!h-[48px] px-6 relative"
                    name={`extraRefunds.${index}.note`}
                    postFixIcon="/assets/cp_icons/Ok-3.svg"
                    clear={!watch(`extraRefunds.${index}.status`)}
                    disabled={!watch(`extraRefunds.${index}.status`)}
                    value={watch(`extraRefunds.${index}.note`) as any}
                    onClick={(e) => openAddNotesModal(e.target)}
                  />
                </div>
              </div>
              <div>
                <button
                  onClick={() => {
                    handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                      removeItem: function () {
                        extraRefunds.remove(index)
                      },
                    })
                  }}
                >
                  <div className="cursor-pointer">
                    <img
                      src="/assets/og_icons/Cancel.svg"
                      className="h-[24px] w-[24px]"
                      alt="Cancel"
                    />
                  </div>
                </button>
              </div>
            </div>
          )
        })}
        <div className="flex items-center gap-2 justify-between">
          <button
            onClick={() => {
              extraRefunds.append({
                name: '',
                category: 'refund',
                status: false,
                percentage: '',
                note: '',
                uuid: uuidv4(),
              })
            }}
            className="my-3 text-SeabiscuitMainThemeColor font-normal text-[12px] flex items-center gap-2"
          >
            <img src={`/assets/og_icons/Cancel-2.svg`} className=" rotate-45" alt="plus icon" />
            <span>Add refund</span>
          </button>

          <button
            onClick={() => {
              extraRefunds.remove()
              reset({
                ...getValues(),
                beforeClosingDateRefund: eventFeesFormDefaultValues.beforeClosingDateRefund,
                afterClosingDateRefund: eventFeesFormDefaultValues.afterClosingDateRefund,
                showCancellationRefundWeather:
                  eventFeesFormDefaultValues.showCancellationRefundWeather,
                showCancellationRefundBioSecurity:
                  eventFeesFormDefaultValues.showCancellationRefundBioSecurity,
              })
            }}
            className="my-3 text-SeabiscuitDark200ThemeColor font-normal hover:text-SeabiscuitMainThemeColor text-[12px] flex items-center gap-2"
          >
            <span>Clear All</span>
          </button>
        </div>
        <EventSchedulingFormComponentVerticalLine />
        {/*======================*/}
        {/* Stalls fees*/}
        {/*======================*/}
        <Subheader title="Stalls" subtitle="Select the stalls you wish to sell at this event" />
        {/*======================*/}
        {/* Temporary Stalls*/}
        {/*======================*/}
        {/* left */}
        {
          (valid =
            !!(
              !!watch(`temporaryStallsFee.status`) &&
              !!watch(`temporaryStallsFee.note`) &&
              new ModelBaseModel().getNum(watch(`temporaryStallsFee.actualCostAlias`)) &&
              new ModelBaseModel().getNum(watch(`temporaryStallsFee.available`))
            ) && !!watch('temporaryStallsFee.duration'))
        }
        <div className="mt-4 grid gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto] laptop:grid-cols-[200px_120px_150px_150px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Temporary stalls</div>
          </div>

          <div className="flex items-end">
            <div className="text-xs font-bold text-SeabiscuitDark200ThemeColor"></div>
            <Controller
              name={`temporaryStallsFee.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() =>
                    setValue(`temporaryStallsFee.status`, !watch(`temporaryStallsFee.status`))
                  }
                />
              )}
            />
          </div>

          <div>
            <div className="text-xs font-bold w-40 text-SeabiscuitDark200ThemeColor">
              Stalls available
            </div>
            <Input
              type="number"
              className="!h-12 px-6"
              register={register}
              placeholder="# of stalls"
              valid={valid}
              name={`temporaryStallsFee.available`}
              clear={!watch(`temporaryStallsFee.status`)}
              value={watch(`temporaryStallsFee.available`) as any}
              validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
              invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
              disabled={!watch(`temporaryStallsFee.status`)}
            />
          </div>

          <div>
            <div
              className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor"
              {...dataIncrementAttrProps}
            >
              Price per stall
            </div>
            <AmountInput
              name={`temporaryStallsFee.actualCostAlias`}
              disable={!watch(`temporaryStallsFee.status`)}
              register={register}
              prefix="$"
              valid={valid}
              placeholder={!watch(`temporaryStallsFee.status`) ? '$0' : '$0'}
              clear={!watch(`temporaryStallsFee.status`)}
              childWidth={24}
              validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
              invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
              inputClassName="px-6"
              className="h-12"
              value={
                !watch(`temporaryStallsFee.status`)
                  ? ''
                  : watch(`temporaryStallsFee.actualCostAlias`)
              }
            />
          </div>
          <div>
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
                Duration
              </div>
              <div>
                <div
                  className={clsx(
                    valid
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  {watch(`temporaryStallsFee.status`) ? (
                    <Controller
                      control={control}
                      name={`temporaryStallsFee.duration`}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          isMulti={false}
                          onChange={onChange}
                          value={getDurationSelectedValue(value)}
                          onBlur={onBlur}
                          components={{
                            DropdownIndicator: (props) => {
                              return <components.DropdownIndicator {...props} />
                            },
                            IndicatorSeparator: () => null,
                          }}
                          placeholder="Select"
                          isDisabled={!watch(`temporaryStallsFee.status`)}
                          options={DurationList}
                          getOptionLabel={(props: any) => {
                            const { dark_icon, label } = props
                            return (
                              <div className="flex items-center gap-2">
                                {dark_icon && <LabelIcon src={dark_icon} />}
                                <span>{label}</span>
                              </div>
                            ) as unknown as string
                          }}
                          className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                          isClearable={false}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      )}
                    />
                  ) : (
                    <p className="text-[#8D97A1] p-4">Select</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
              Note
            </div>
            <Input
              valid={valid}
              showPostFixIcon={valid}
              validValueClassName="bg-SeabiscuitGrayThemeColor !border-0"
              invalidValueClassName="border border-solid !border-SeabiscuitLightThemeColorD3"
              readOnly={true}
              register={register}
              placeholder="Enter note"
              className="!h-[48px] px-6 relative"
              name={`temporaryStallsFee.note`}
              postFixIcon="/assets/cp_icons/Ok-3.svg"
              clear={!watch(`temporaryStallsFee.status`)}
              disabled={!watch(`temporaryStallsFee.status`)}
              value={watch(`temporaryStallsFee.note`) as any}
              onClick={(e) => openAddNotesModal(e.target)}
            />
          </div>
        </div>
        {/*======================*/}
        {/* Permanent Stalls */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto] laptop:grid-cols-[200px_120px_150px_150px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Permanent Stalls</div>
          </div>
          <div>
            <Controller
              name={`permanentStallsFee.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() =>
                    setValue(`permanentStallsFee.status`, !watch(`permanentStallsFee.status`))
                  }
                />
              )}
            />
          </div>
          <div>
            <div
              className={clsx(
                watch(`permanentStallsFee.status`)
                  ? 'bg-SeabiscuitGrayThemeColor'
                  : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                'text-[14px] rounded-md flex items-center p-4 h-12'
              )}
            >
              <input
                type="number"
                className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                disabled={!watch(`permanentStallsFee.status`)}
                value={
                  !watch(`permanentStallsFee.status`) ? '' : watch(`permanentStallsFee.available`)
                }
                placeholder="# of stalls"
                {...register(`permanentStallsFee.available`)}
              />
              {
                // watch('permanentStallsFee.status') && (watch('permanentStallsFee.available')) ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
              }
            </div>
          </div>

          <AmountInput
            name={`permanentStallsFee.actualCostAlias`}
            disable={!watch(`permanentStallsFee.status`)}
            register={register}
            prefix="$"
            placeholder={!watch(`permanentStallsFee.status`) ? '$0' : 'Price per stall'}
            clear={!watch(`permanentStallsFee.status`)}
            childWidth={24}
            inputClassName={clsx(
              'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
              watch(`permanentStallsFee.status`)
                ? 'text-SeabiscuitDark200ThemeColor'
                : 'text-[#939AA2]'
            )}
            className={clsx(
              watch(`permanentStallsFee.status`)
                ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                : 'bg-white border-SeabiscuitLightThemeColorD3',
              'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
            )}
            value={
              !watch(`permanentStallsFee.status`) ? '' : watch(`permanentStallsFee.actualCostAlias`)
            }
          />

          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div>
              <div
                className={clsx(
                  watch(`permanentStallsFee.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                {watch(`permanentStallsFee.status`) ? (
                  <Controller
                    control={control}
                    name={`permanentStallsFee.duration`}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <Select
                          isMulti={false}
                          onChange={onChange}
                          components={{
                            DropdownIndicator: (props) => {
                              return <components.DropdownIndicator {...props} />
                            },
                            IndicatorSeparator: () => null,
                          }}
                          value={getDurationSelectedValue(value)}
                          onBlur={onBlur}
                          placeholder="Select"
                          options={DurationList}
                          isDisabled={!watch(`permanentStallsFee.status`)}
                          getOptionLabel={(props: any) => {
                            const { dark_icon, label } = props
                            return (
                              <div className="flex items-center gap-2">
                                {dark_icon && <LabelIcon src={dark_icon} />}
                                <span>{label}</span>
                              </div>
                            ) as unknown as string
                          }}
                          className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                          isClearable={false}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      )
                    }}
                  />
                ) : (
                  <p className="text-[#8D97A1] p-4">Select</p>
                )}
              </div>
            </div>
          </div>

          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div className="text-xs font-bold hidden text-SeabiscuitDark200ThemeColor">Note</div>
            <div>
              <div
                className={clsx(
                  watch(`permanentStallsFee.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="text"
                  // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  className={clsx(
                    watch(`permanentStallsFee.status`)
                      ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                      : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                    'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                  )}
                  placeholder="Enter note"
                  value={
                    !watch(`permanentStallsFee.status`) ? '' : watch(`permanentStallsFee.note`)
                  }
                  disabled={!watch(`permanentStallsFee.status`)}
                  onClick={(e) => openAddNotesModal(e.target)}
                  readOnly={true}
                  {...register(`permanentStallsFee.note`)}
                />
                {watch('permanentStallsFee.status') &&
                watch('permanentStallsFee.note') &&
                watch('permanentStallsFee.note') !== '' &&
                watch('permanentStallsFee.note') !== '0' ? (
                  <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/*======================*/}
        {/* Private Stalls */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto] laptop:grid-cols-[200px_120px_150px_150px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Private Stalls</div>
          </div>
          <div>
            <Controller
              name={`privateStallsFee.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() =>
                    setValue(`privateStallsFee.status`, !watch(`privateStallsFee.status`))
                  }
                />
              )}
            />
          </div>
          <div>
            <div
              className={clsx(
                watch(`privateStallsFee.status`)
                  ? 'bg-SeabiscuitGrayThemeColor'
                  : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                'text-[14px] rounded-md flex items-center p-4 h-12'
              )}
            >
              <input
                type="number"
                className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                placeholder="# of stalls"
                disabled={!watch(`privateStallsFee.status`)}
                value={!watch(`privateStallsFee.status`) ? '' : watch(`privateStallsFee.available`)}
                {...register(`privateStallsFee.available`)}
              />
            </div>
          </div>

          <AmountInput
            name={`privateStallsFee.actualCostAlias`}
            disable={!watch(`privateStallsFee.status`)}
            register={register}
            prefix="$"
            placeholder={!watch(`privateStallsFee.status`) ? '$0' : 'Price per stall'}
            clear={!watch(`privateStallsFee.status`)}
            childWidth={24}
            inputClassName={clsx(
              'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
              watch(`privateStallsFee.status`)
                ? 'text-SeabiscuitDark200ThemeColor'
                : 'text-[#939AA2]'
            )}
            // className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
            className={clsx(
              watch(`privateStallsFee.status`)
                ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                : 'bg-white border-SeabiscuitLightThemeColorD3',
              'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
            )}
            value={
              !watch(`privateStallsFee.status`) ? '' : watch(`privateStallsFee.actualCostAlias`)
            }
          />

          <div>
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div>
                <div
                  className={clsx(
                    watch(`privateStallsFee.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  {watch(`privateStallsFee.status`) ? (
                    <Controller
                      control={control}
                      name={`privateStallsFee.duration`}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <Select
                            isMulti={false}
                            isDisabled={!watch(`privateStallsFee.status`)}
                            onChange={onChange}
                            components={{
                              DropdownIndicator: (props) => {
                                return <components.DropdownIndicator {...props} />
                              },
                              IndicatorSeparator: () => null,
                            }}
                            value={getDurationSelectedValue(value)}
                            onBlur={onBlur}
                            placeholder="Select"
                            options={DurationList}
                            getOptionLabel={(props: any) => {
                              const { dark_icon, label } = props
                              return (
                                <div className="flex items-center gap-2">
                                  {dark_icon && <LabelIcon src={dark_icon} />}
                                  <span>{label}</span>
                                </div>
                              ) as unknown as string
                            }}
                            className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                            isClearable={false}
                            styles={customStyles}
                            isSearchable={false}
                          />
                        )
                      }}
                    />
                  ) : (
                    <p className="text-[#8D97A1] p-4">Select</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div className="text-xs font-bold hidden  text-SeabiscuitDark200ThemeColor">Note</div>
            <div>
              <div
                className={clsx(
                  watch(`privateStallsFee.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="text"
                  // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  className={clsx(
                    watch(`privateStallsFee.status`)
                      ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                      : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                    'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                  )}
                  disabled={!watch(`privateStallsFee.status`)}
                  placeholder="Enter note"
                  value={!watch(`privateStallsFee.status`) ? '' : watch(`privateStallsFee.note`)}
                  onClick={(e) => openAddNotesModal(e.target)}
                  readOnly={true}
                  {...register(`privateStallsFee.note`)}
                />
                {watch('privateStallsFee.status') &&
                watch('privateStallsFee.note') &&
                watch('privateStallsFee.note') !== '' &&
                watch('privateStallsFee.note') !== '0' ? (
                  <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/*======================*/}
        {/* Circuit Stalls */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto] laptop:grid-cols-[200px_120px_150px_150px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Circuit Stalls</div>
          </div>
          <div>
            <Controller
              name={`circuitStallsFee.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() =>
                    setValue(`circuitStallsFee.status`, !watch(`circuitStallsFee.status`))
                  }
                />
              )}
            />
          </div>
          <div>
            <div
              className={clsx(
                watch(`circuitStallsFee.status`)
                  ? 'bg-SeabiscuitGrayThemeColor'
                  : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                'text-[14px] rounded-md flex items-center p-4 h-12'
              )}
            >
              <input
                type="number"
                className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                disabled={!watch(`circuitStallsFee.status`)}
                placeholder="# of stalls"
                value={!watch(`circuitStallsFee.status`) ? '' : watch(`circuitStallsFee.available`)}
                {...register(`circuitStallsFee.available`)}
              />
            </div>
          </div>

          <AmountInput
            name={`circuitStallsFee.actualCostAlias`}
            disable={!watch(`circuitStallsFee.status`)}
            register={register}
            prefix="$"
            placeholder={!watch(`circuitStallsFee.status`) ? '$0' : 'Price per stall'}
            clear={!watch(`circuitStallsFee.status`)}
            childWidth={24}
            inputClassName={clsx(
              'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
              watch(`circuitStallsFee.status`)
                ? 'text-SeabiscuitDark200ThemeColor'
                : 'text-[#939AA2]'
            )}
            className={clsx(
              watch(`circuitStallsFee.status`)
                ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                : 'bg-white border-SeabiscuitLightThemeColorD3',
              'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
            )}
            value={
              !watch(`circuitStallsFee.status`) ? '' : watch(`circuitStallsFee.actualCostAlias`)
            }
          />
          <div>
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              {/* New */}
              <div>
                <div
                  className={clsx(
                    watch(`circuitStallsFee.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  {watch(`circuitStallsFee.status`) ? (
                    <Controller
                      control={control}
                      name={`circuitStallsFee.duration`}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <Select
                            isMulti={false}
                            onChange={onChange}
                            isDisabled={!watch(`circuitStallsFee.status`)}
                            components={{
                              DropdownIndicator: (props) => {
                                return <components.DropdownIndicator {...props} />
                              },
                              IndicatorSeparator: () => null,
                            }}
                            value={getDurationSelectedValue(value)}
                            onBlur={onBlur}
                            placeholder="Select"
                            options={DurationList}
                            getOptionLabel={(props: any) => {
                              const { dark_icon, label } = props
                              return (
                                <div className="flex items-center gap-2">
                                  {dark_icon && <LabelIcon src={dark_icon} />}
                                  <span>{label}</span>
                                </div>
                              ) as unknown as string
                            }}
                            className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                            isClearable={false}
                            styles={customStyles}
                            isSearchable={false}
                          />
                        )
                      }}
                    />
                  ) : (
                    <p className="text-[#8D97A1] p-4">Select</p>
                  )}
                </div>
              </div>
              {/* New */}
            </div>
          </div>

          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div className="text-xs font-bold hidden text-SeabiscuitDark200ThemeColor">Note</div>
            <div>
              <div
                className={clsx(
                  watch(`circuitStallsFee.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="text"
                  // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  className={clsx(
                    watch(`circuitStallsFee.status`)
                      ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                      : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                    'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                  )}
                  placeholder="Enter note"
                  disabled={!watch(`circuitStallsFee.status`)}
                  value={!watch(`circuitStallsFee.status`) ? '' : watch(`circuitStallsFee.note`)}
                  onClick={(e) => openAddNotesModal(e.target)}
                  readOnly={true}
                  {...register(`circuitStallsFee.note`)}
                />
                {watch('circuitStallsFee.status') &&
                watch('circuitStallsFee.note') &&
                watch('circuitStallsFee.note') !== '' &&
                watch('circuitStallsFee.note') !== '0' ? (
                  <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/*======================*/}
        {/* Muck Fee */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto] laptop:grid-cols-[200px_120px_150px_150px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Muck Fee</div>
          </div>
          <div>
            <Controller
              name={`muckFee.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() => setValue(`muckFee.status`, !watch(`muckFee.status`))}
                />
              )}
            />
          </div>
          <div>
            <div
              className={clsx(
                watch(`muckFee.status`)
                  ? 'bg-SeabiscuitGrayThemeColor'
                  : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                'text-[14px] rounded-md flex items-center p-4 h-12'
              )}
            >
              <input
                type="number"
                className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                placeholder="# of stalls"
                disabled={!watch(`muckFee.status`)}
                value={!watch(`muckFee.status`) ? '' : watch(`muckFee.available`)}
                {...register(`muckFee.available`)}
              />
              {
                // watch('muckFee.status') && (watch('muckFee.available')) ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
              }
            </div>
          </div>

          <AmountInput
            name={`muckFee.actualCostAlias`}
            disable={!watch(`muckFee.status`)}
            register={register}
            prefix="$"
            placeholder="Price per stall"
            childWidth={24}
            inputClassName={clsx(
              'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
              watch(`muckFee.status`) ? 'text-SeabiscuitDark200ThemeColor' : 'text-[#939AA2]'
            )}
            className={clsx(
              watch(`muckFee.status`)
                ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                : 'bg-white border-SeabiscuitLightThemeColorD3',
              'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
            )}
            value={!watch(`muckFee.status`) ? '' : watch(`muckFee.actualCostAlias`)}
            childrenToAppend={
              <>
                {/* {watch('muckFee.status') && (watch('muckFee.actualCostAlias') && watch('muckFee.actualCostAlias') !== "$" && watch('muckFee.actualCostAlias') !== "$0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null} */}
              </>
            }
          />

          <div>
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              {/* Duration */}
              <div>
                <div
                  className={clsx(
                    watch(`muckFee.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  {watch(`muckFee.status`) ? (
                    <Controller
                      control={control}
                      name={`muckFee.duration`}
                      render={({ field: { onChange, onBlur, value } }) => {
                        return (
                          <Select
                            isMulti={false}
                            onChange={onChange}
                            components={{
                              DropdownIndicator: (props) => {
                                return <components.DropdownIndicator {...props} />
                              },
                              IndicatorSeparator: () => null,
                            }}
                            value={getDurationSelectedValue(value)}
                            onBlur={onBlur}
                            isDisabled={!watch(`muckFee.status`)}
                            placeholder="Select"
                            options={DurationList}
                            getOptionLabel={(props: any) => {
                              const { dark_icon, label } = props
                              return (
                                <div className="flex items-center gap-2">
                                  {dark_icon && <LabelIcon src={dark_icon} />}
                                  <span>{label}</span>
                                </div>
                              ) as unknown as string
                            }}
                            className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                            isClearable={false}
                            styles={customStyles}
                            isSearchable={false}
                          />
                        )
                      }}
                    />
                  ) : (
                    <p className="text-[#8D97A1] p-4">Select</p>
                  )}
                </div>
              </div>
              {/* Duration */}
            </div>
          </div>

          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div className="text-xs font-bold hidden text-SeabiscuitDark200ThemeColor">Note</div>
            <div>
              <div
                className={clsx(
                  watch(`muckFee.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="text"
                  disabled={!watch(`muckFee.status`)}
                  // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  className={clsx(
                    watch(`muckFee.status`)
                      ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                      : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                    'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                  )}
                  placeholder="Enter note"
                  value={!watch(`muckFee.status`) ? '' : watch(`muckFee.note`)}
                  onClick={(e) => openAddNotesModal(e.target)}
                  readOnly={true}
                  {...register(`muckFee.note`)}
                />
                {watch('muckFee.status') &&
                watch('muckFee.note') &&
                watch('muckFee.note') !== '' &&
                watch('muckFee.note') !== '0' ? (
                  <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/*======================*/}
        {/* Extra Stalls fees */}
        {/*======================*/}
        {extraStallFees.fields.map((field: any, index) => (
          <div
            key={field.id}
            className="mt-12 md:mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto_25px] laptop:grid-cols-[200px_120px_150px_150px_200px_auto_25px]"
          >
            <div className="flex items-center gap-1">
              <div className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12">
                <input
                  type="text"
                  className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  placeholder="Add Stalls Fee"
                  disabled={!watch(`extraStallFees.${index}.status`)}
                  value={
                    !watch(`extraStallFees.${index}.status`)
                      ? ''
                      : watch(`extraStallFees.${index}.title`)
                  }
                  {...register(`extraStallFees.${index}.title`)}
                />
              </div>
            </div>
            <div>
              <Controller
                name={`extraStallFees.${index}.status`}
                control={control}
                render={({ field: { value } }) => (
                  <TogglesElement
                    on={value}
                    onToggle={() =>
                      setValue(
                        `extraStallFees.${index}.status`,
                        !watch(`extraStallFees.${index}.status`)
                      )
                    }
                  />
                )}
              />
            </div>
            <div>
              <div
                className={clsx(
                  watch(`extraStallFees.${index}.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="number"
                  className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  placeholder="# of stalls"
                  disabled={!watch(`extraStallFees.${index}.status`)}
                  value={
                    !watch(`extraStallFees.${index}.status`)
                      ? ''
                      : watch(`extraStallFees.${index}.available`)
                  }
                  {...register(`extraStallFees.${index}.available`)}
                />
              </div>
            </div>
            <AmountInput
              name={`extraStallFees.${index}.actualCostAlias`}
              disable={!watch(`extraStallFees.${index}.status`)}
              register={register}
              prefix="$"
              placeholder="Price per stall"
              childWidth={24}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
                watch(`extraStallFees.${index}.status`)
                  ? 'text-SeabiscuitDark200ThemeColor'
                  : 'text-[#939AA2]'
              )}
              // className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
              className={clsx(
                watch(`extraStallFees.${index}.status`)
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white border-SeabiscuitLightThemeColorD3',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={
                !watch(`extraStallFees.${index}.status`)
                  ? ''
                  : watch(`extraStallFees.${index}.actualCostAlias`)
              }
            />
            <div>
              <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
                <div>
                  <div
                    className={clsx(
                      watch(`extraStallFees.${index}.status`)
                        ? 'bg-SeabiscuitGrayThemeColor'
                        : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                      'text-[14px] rounded-md flex items-center p-4 h-12'
                    )}
                  >
                    {watch(`extraStallFees.${index}.status`) ? (
                      <Controller
                        control={control}
                        name={`extraStallFees.${index}.duration`}
                        render={({ field: { onChange, onBlur, value } }) => {
                          return (
                            <Select
                              isMulti={false}
                              onChange={onChange}
                              components={{
                                DropdownIndicator: (props) => {
                                  return <components.DropdownIndicator {...props} />
                                },
                                IndicatorSeparator: () => null,
                              }}
                              value={getDurationSelectedValue(value)}
                              onBlur={onBlur}
                              isDisabled={!watch(`extraStallFees.${index}.status`)}
                              placeholder="Select"
                              options={DurationList}
                              getOptionLabel={(props: any) => {
                                const { dark_icon, label } = props
                                return (
                                  <div className="flex items-center gap-2">
                                    {dark_icon && <LabelIcon src={dark_icon} />}
                                    <span>{label}</span>
                                  </div>
                                ) as unknown as string
                              }}
                              className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                              isClearable={false}
                              styles={customStyles}
                              isSearchable={false}
                            />
                          )
                        }}
                      />
                    ) : (
                      <p className="text-[#8D97A1] p-4">Select</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div className="text-xs font-bold hidden text-SeabiscuitDark200ThemeColor">Note</div>
              <div>
                <div
                  className={clsx(
                    watch(`extraStallFees.${index}.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  <input
                    type="text"
                    // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                    className={clsx(
                      watch(`extraStallFees.${index}.status`)
                        ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                        : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                      'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                    )}
                    placeholder="Enter note"
                    disabled={!watch(`extraStallFees.${index}.status`)}
                    value={
                      !watch(`extraStallFees.${index}.status`)
                        ? ''
                        : watch(`extraStallFees.${index}.note`)
                    }
                    onClick={(e) => openAddNotesModal(e.target)}
                    readOnly={true}
                    {...register(`extraStallFees.${index}.note`)}
                  />
                  {watch(`extraStallFees.${index}.status`) &&
                  watch(`extraStallFees.${index}.note`) &&
                  watch(`extraStallFees.${index}.note`) !== '' &&
                  watch(`extraStallFees.${index}.note`) !== '0' ? (
                    <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                  ) : null}
                </div>
              </div>
            </div>
            <div>
              <button
                onClick={() => {
                  handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                    removeItem: function () {
                      extraStallFees.remove(index)
                    },
                  })
                }}
              >
                <div className="cursor-pointer">
                  <img
                    src="/assets/og_icons/Cancel.svg"
                    className="h-[24px] w-[24px]"
                    alt="Cancel"
                  />
                </div>
              </button>
            </div>
          </div>
        ))}
        <div className="flex items-center gap-2 justify-between">
          <button
            onClick={() => {
              extraStallFees.append({
                status: false,
                title: '',
                category: 'stalls',
                available: '',
                sold: '',
                actualCostAlias: '',
                duration: '',
                note: '',
                uuid: uuidv4(),
              })
            }}
            className="my-3 text-SeabiscuitMainThemeColor font-normal text-[12px] flex items-center gap-2"
          >
            <img src={`/assets/og_icons/Cancel-2.svg`} className=" rotate-45" alt="plus icon" />
            <span>Add Stall Fee</span>
          </button>
          <button
            onClick={() => {
              extraStallFees.remove()
              reset({
                ...getValues(),
                temporaryStallsFee: eventFeesFormDefaultValues.temporaryStallsFee,
                permanentStallsFee: eventFeesFormDefaultValues.permanentStallsFee,
                privateStallsFee: eventFeesFormDefaultValues.privateStallsFee,
                circuitStallsFee: eventFeesFormDefaultValues.circuitStallsFee,
                muckFee: eventFeesFormDefaultValues.muckFee,
              })
            }}
            className="my-3 text-SeabiscuitDark200ThemeColor font-normal hover:text-SeabiscuitMainThemeColor text-[12px] flex items-center gap-2"
          >
            <span>Clear All</span>
          </button>
        </div>
        <EventSchedulingFormComponentVerticalLine />
        {/*======================*/}
        {/* Bedding */}
        {/*======================*/}
        <Subheader title="Bedding" subtitle="Add bedding options for this event" />
        {/*======================*/}
        {/* Shaving */}
        {/*======================*/}
        <div className="mt-4 grid gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto] laptop:grid-cols-[200px_120px_150px_150px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Shaving</div>
          </div>
          <div className="flex items-end">
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor"></div>
            <Controller
              name={`shavingFees.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() => setValue(`shavingFees.status`, !watch(`shavingFees.status`))}
                />
              )}
            />
          </div>
          <div>
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
              Units available
            </div>

            <div
              className={clsx(
                watch(`shavingFees.status`)
                  ? 'bg-SeabiscuitGrayThemeColor'
                  : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                'text-[14px] rounded-md flex items-center p-4 h-12'
              )}
            >
              <input
                type="number"
                disabled={!watch(`shavingFees.status`)}
                className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                placeholder="Units"
                value={!watch(`shavingFees.status`) ? '' : watch(`shavingFees.available`)}
                {...register(`shavingFees.available`)}
              />
            </div>
          </div>

          <div>
            <div
              className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor"
              {...dataIncrementAttrProps}
            >
              Price per unit
            </div>
            <AmountInput
              name={`shavingFees.actualCostAlias`}
              disable={!watch(`shavingFees.status`)}
              register={register}
              clear={!watch(`shavingFees.status`)}
              placeholder={'$0'}
              prefix="$"
              childWidth={24}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
                watch(`shavingFees.status`) ? 'text-SeabiscuitDark200ThemeColor' : 'text-[#939AA2]'
              )}
              // className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
              className={clsx(
                watch(`shavingFees.status`)
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white border-SeabiscuitLightThemeColorD3',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={!watch(`shavingFees.status`) ? '' : watch(`shavingFees.actualCostAlias`)}
            />
          </div>

          <div>
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
                Duration
              </div>
              <div>
                <div
                  className={clsx(
                    watch(`shavingFees.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  {watch(`shavingFees.status`) ? (
                    <Controller
                      control={control}
                      name={`shavingFees.duration`}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          isMulti={false}
                          onChange={onChange}
                          value={getDurationSelectedValue(value)}
                          isDisabled={!watch(`shavingFees.status`)}
                          onBlur={onBlur}
                          components={{
                            DropdownIndicator: (props) => {
                              return <components.DropdownIndicator {...props} />
                            },
                            IndicatorSeparator: () => null,
                          }}
                          placeholder="Select"
                          options={DurationList}
                          getOptionLabel={(props: any) => {
                            const { dark_icon, label } = props
                            return (
                              <div className="flex items-center gap-2">
                                {dark_icon && <LabelIcon src={dark_icon} />}
                                <span>{label}</span>
                              </div>
                            ) as unknown as string
                          }}
                          className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                          isClearable={false}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      )}
                    />
                  ) : (
                    <p className="text-[#8D97A1] p-4">Select</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
              Note
            </div>
            <div>
              <div
                className={clsx(
                  watch(`shavingFees.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="text"
                  // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  className={clsx(
                    watch(`shavingFees.status`)
                      ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                      : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                    'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                  )}
                  value={!watch(`shavingFees.status`) ? '' : watch(`shavingFees.note`)}
                  disabled={!watch(`shavingFees.status`)}
                  placeholder="Enter note"
                  onClick={(e) => openAddNotesModal(e.target)}
                  readOnly={true}
                  {...register(`shavingFees.note`)}
                />
                {watch('shavingFees.status') &&
                watch('shavingFees.note') &&
                watch('shavingFees.note') !== '' &&
                watch('shavingFees.note') !== '0' ? (
                  <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/*======================*/}
        {/* Straw */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto] laptop:grid-cols-[200px_120px_150px_150px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Straw</div>
          </div>
          <div>
            <Controller
              name={`strawFees.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() => setValue(`strawFees.status`, !watch(`strawFees.status`))}
                />
              )}
            />
          </div>
          <div>
            <div
              className={clsx(
                watch(`strawFees.status`)
                  ? 'bg-SeabiscuitGrayThemeColor'
                  : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                'text-[14px] rounded-md flex items-center p-4 h-12'
              )}
            >
              <input
                type="number"
                className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                placeholder="Units"
                disabled={!watch(`strawFees.status`)}
                value={!watch(`strawFees.status`) ? '' : watch(`strawFees.available`)}
                {...register(`strawFees.available`)}
              />
              {
                // watch('strawFees.status') && (watch('strawFees.available')) ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
              }
            </div>
          </div>

          <div>
            <AmountInput
              name={`strawFees.actualCostAlias`}
              disable={!watch(`strawFees.status`)}
              register={register}
              prefix="$"
              clear={!watch(`strawFees.status`)}
              placeholder="$0"
              childWidth={24}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
                watch(`strawFees.status`) ? 'text-SeabiscuitDark200ThemeColor' : 'text-[#939AA2]'
              )}
              // className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
              className={clsx(
                watch(`strawFees.status`)
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white border-SeabiscuitLightThemeColorD3',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={!watch(`strawFees.status`) ? '' : watch(`strawFees.actualCostAlias`)}
            />
          </div>

          <div>
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div>
                <div
                  className={clsx(
                    watch(`strawFees.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  {watch(`strawFees.status`) ? (
                    <Controller
                      control={control}
                      name={`strawFees.duration`}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          isMulti={false}
                          onChange={onChange}
                          value={getDurationSelectedValue(value)}
                          onBlur={onBlur}
                          isDisabled={!watch(`strawFees.status`)}
                          components={{
                            DropdownIndicator: (props) => {
                              return <components.DropdownIndicator {...props} />
                            },
                            IndicatorSeparator: () => null,
                          }}
                          placeholder="Select"
                          options={DurationList}
                          getOptionLabel={(props: any) => {
                            const { dark_icon, label } = props
                            return (
                              <div className="flex items-center gap-2">
                                {dark_icon && <LabelIcon src={dark_icon} />}
                                <span>{label}</span>
                              </div>
                            ) as unknown as string
                          }}
                          className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                          isClearable={false}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      )}
                    />
                  ) : (
                    <p className="text-[#8D97A1] p-4">Select</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div>
              <div
                className={clsx(
                  watch(`strawFees.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="text"
                  // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  className={clsx(
                    watch(`strawFees.status`)
                      ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                      : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                    'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                  )}
                  placeholder="Enter note"
                  disabled={!watch(`strawFees.status`)}
                  onClick={(e) => openAddNotesModal(e.target)}
                  readOnly={true}
                  value={!watch(`strawFees.status`) ? '' : watch(`strawFees.note`)}
                  {...register(`strawFees.note`)}
                />
                {watch('strawFees.status') &&
                watch('strawFees.note') &&
                watch('strawFees.note') !== '' &&
                watch('strawFees.note') !== '0' ? (
                  <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/*======================*/}
        {/* Extra Bedding Fees */}
        {/*======================*/}
        {extraBeddingFees.fields.map((field: any, index) => (
          <div
            key={field.id}
            className="mt-12 md:mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto_25px] laptop:grid-cols-[200px_120px_150px_150px_200px_auto_25px]"
          >
            <div className="flex items-center gap-1">
              <div className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12">
                <input
                  type="text"
                  className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  placeholder="Bedding Fee"
                  {...register(`extraBeddingFees.${index}.title`)}
                  disabled={!watch(`extraBeddingFees.${index}.status`)}
                  value={
                    !watch(`extraBeddingFees.${index}.status`)
                      ? ''
                      : watch(`extraBeddingFees.${index}.title`)
                  }
                />
              </div>
            </div>
            <div>
              <Controller
                name={`extraBeddingFees.${index}.status`}
                control={control}
                render={({ field: { value } }) => (
                  <TogglesElement
                    on={value}
                    onToggle={() =>
                      setValue(
                        `extraBeddingFees.${index}.status`,
                        !watch(`extraBeddingFees.${index}.status`)
                      )
                    }
                  />
                )}
              />
            </div>
            <div>
              <div
                className={clsx(
                  watch(`extraBeddingFees.${index}.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="number"
                  disabled={!watch(`extraBeddingFees.${index}.status`)}
                  className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  placeholder="Amount"
                  value={
                    !watch(`extraBeddingFees.${index}.status`)
                      ? ''
                      : watch(`extraBeddingFees.${index}.available`)
                  }
                  {...register(`extraBeddingFees.${index}.available`)}
                />
              </div>
            </div>
            <AmountInput
              name={`extraBeddingFees.${index}.actualCostAlias`}
              disable={!watch(`extraBeddingFees.${index}.status`)}
              register={register}
              prefix="$"
              clear={!watch(`extraBeddingFees.${index}.status`)}
              placeholder="$0"
              childWidth={24}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
                watch(`extraBeddingFees.${index}.status`)
                  ? 'text-SeabiscuitDark200ThemeColor'
                  : 'text-[#939AA2]'
              )}
              className={clsx(
                watch(`extraBeddingFees.${index}.status`)
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white border-SeabiscuitLightThemeColorD3',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={
                !watch(`extraBeddingFees.${index}.status`)
                  ? ''
                  : watch(`extraBeddingFees.${index}.actualCostAlias`)
              }
              childrenToAppend={
                <>
                  {/* {watch(`extraBeddingFees.${index}.status`) && (watch(`extraBeddingFees.${index}.actualCostAlias`) && watch(`extraBeddingFees.${index}.actualCostAlias`) !== "$" && watch(`extraBeddingFees.${index}.actualCostAlias`) !== "$0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null} */}
                </>
              }
            />
            <div>
              <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
                <div>
                  <div
                    className={clsx(
                      watch(`extraBeddingFees.${index}.status`)
                        ? 'bg-SeabiscuitGrayThemeColor'
                        : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                      'text-[14px] rounded-md flex items-center p-4 h-12'
                    )}
                  >
                    {watch(`extraBeddingFees.${index}.status`) ? (
                      <Controller
                        control={control}
                        name={`extraBeddingFees.${index}.duration`}
                        render={({ field: { onChange, onBlur, value } }) => {
                          return (
                            <Select
                              isMulti={false}
                              onChange={onChange}
                              isDisabled={!watch(`extraBeddingFees.${index}.status`)}
                              components={{
                                DropdownIndicator: (props) => {
                                  return <components.DropdownIndicator {...props} />
                                },
                                IndicatorSeparator: () => null,
                              }}
                              value={getDurationSelectedValue(value)}
                              onBlur={onBlur}
                              placeholder="Select"
                              options={DurationList}
                              getOptionLabel={(props: any) => {
                                const { dark_icon, label } = props
                                return (
                                  <div className="flex items-center gap-2">
                                    {dark_icon && <LabelIcon src={dark_icon} />}
                                    <span>{label}</span>
                                  </div>
                                ) as unknown as string
                              }}
                              className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                              isClearable={false}
                              styles={customStyles}
                              isSearchable={false}
                            />
                          )
                        }}
                      />
                    ) : (
                      <p className="text-[#8D97A1] p-4">Select</p>
                    )}
                    {
                      // watch(`extraBeddingFees.${index}.status`) && (watch(`extraBeddingFees.${index}.duration`) && watch(`extraBeddingFees.${index}.duration`) !== "" && watch(`extraBeddingFees.${index}.duration`) !== "0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div className="text-xs font-bold hidden text-SeabiscuitDark200ThemeColor">Note</div>
              <div>
                <div
                  className={clsx(
                    watch(`extraBeddingFees.${index}.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  <input
                    type="text"
                    // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                    className={clsx(
                      watch(`extraBeddingFees.${index}.status`)
                        ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                        : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                      'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                    )}
                    placeholder="Enter note"
                    value={
                      !watch(`extraBeddingFees.${index}.status`)
                        ? ''
                        : watch(`extraBeddingFees.${index}.note`)
                    }
                    disabled={!watch(`extraBeddingFees.${index}.status`)}
                    onClick={(e) => openAddNotesModal(e.target)}
                    readOnly={true}
                    {...register(`extraBeddingFees.${index}.note`)}
                  />
                  {watch(`extraBeddingFees.${index}.status`) &&
                  watch(`extraBeddingFees.${index}.note`) &&
                  watch(`extraBeddingFees.${index}.note`) !== '' &&
                  watch(`extraBeddingFees.${index}.note`) !== '0' ? (
                    <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                  ) : null}
                </div>
              </div>
            </div>
            <div>
              <button
                onClick={() => {
                  handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                    removeItem: function () {
                      extraBeddingFees.remove(index)
                    },
                  })
                }}
              >
                <div className="cursor-pointer">
                  <img
                    src="/assets/og_icons/Cancel.svg"
                    className="h-[24px] w-[24px]"
                    alt="Cancel"
                  />
                </div>
              </button>
            </div>
          </div>
        ))}
        <div className="flex items-center gap-2 justify-between">
          <button
            onClick={() => {
              extraBeddingFees.append({
                status: false,
                category: 'bedding',
                title: '',
                available: 0,
                sold: '0',
                actualCostAlias: '',
                duration: '',
                note: '',
                uuid: uuidv4(),
              })
            }}
            className="my-3 text-SeabiscuitMainThemeColor font-normal text-[12px] flex items-center gap-2"
          >
            <img src={`/assets/og_icons/Cancel-2.svg`} className=" rotate-45" alt="plus icon" />
            <span>Add bedding fee</span>
          </button>
          <button
            onClick={() => {
              extraBeddingFees.remove()
              reset({
                ...getValues(),
                shavingFees: eventFeesFormDefaultValues.shavingFees,
                strawFees: eventFeesFormDefaultValues.strawFees,
              })
            }}
            className="my-3 text-SeabiscuitDark200ThemeColor font-normal hover:text-SeabiscuitMainThemeColor text-[12px] flex items-center gap-2"
          >
            <span>Clear All</span>
          </button>
        </div>
        <EventSchedulingFormComponentVerticalLine />
        {/*======================*/}
        {/* Feed */}
        {/*======================*/}
        <Subheader title="Feed" subtitle="Add feed options for this event" />
        {/*======================*/}
        {/* Coastal hay */}
        {/*======================*/}
        <div className="mt-4 grid gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto] laptop:grid-cols-[200px_120px_150px_150px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Coastal hay</div>
          </div>
          <div className="flex items-end">
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor"></div>
            <Controller
              name={`coastalHay.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() => setValue(`coastalHay.status`, !watch(`coastalHay.status`))}
                />
              )}
            />
          </div>
          <div>
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
              Units available
            </div>
            <div
              className={clsx(
                watch(`coastalHay.status`)
                  ? 'bg-SeabiscuitGrayThemeColor'
                  : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                'text-[14px] rounded-md flex items-center p-4 h-12'
              )}
            >
              <input
                type="number"
                className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                disabled={!watch(`coastalHay.status`)}
                placeholder="Units"
                {...register(`coastalHay.available`)}
                value={!watch(`coastalHay.status`) ? '' : watch(`coastalHay.available`)}
              />
            </div>
          </div>

          <div>
            <div
              className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor"
              {...dataIncrementAttrProps}
            >
              Price per unit
            </div>
            <AmountInput
              name={`coastalHay.actualCostAlias`}
              disable={!watch(`coastalHay.status`)}
              register={register}
              prefix="$"
              placeholder="$0"
              clear={!watch(`coastalHay.status`)}
              childWidth={24}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
                watch(`coastalHay.status`) ? 'text-SeabiscuitDark200ThemeColor' : 'text-[#939AA2]'
              )}
              // className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
              className={clsx(
                watch(`coastalHay.status`)
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white border-SeabiscuitLightThemeColorD3',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={!watch(`coastalHay.status`) ? '' : watch(`coastalHay.actualCostAlias`)}
              childrenToAppend={
                <>
                  {/* {watch('coastalHay.status') && (watch('coastalHay.actualCostAlias') && watch('coastalHay.actualCostAlias') !== "$" && watch('coastalHay.actualCostAlias') !== "$0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null} */}
                </>
              }
            />
          </div>

          <div>
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
                Duration
              </div>
              <div>
                <div
                  className={clsx(
                    watch(`coastalHay.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  {watch(`coastalHay.status`) ? (
                    <Controller
                      control={control}
                      name={`coastalHay.duration`}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          isMulti={false}
                          onChange={onChange}
                          value={getDurationSelectedValue(value)}
                          isDisabled={!watch(`coastalHay.status`)}
                          onBlur={onBlur}
                          components={{
                            DropdownIndicator: (props) => {
                              return <components.DropdownIndicator {...props} />
                            },
                            IndicatorSeparator: () => null,
                          }}
                          placeholder="Select"
                          options={DurationList}
                          getOptionLabel={(props: any) => {
                            const { dark_icon, label } = props
                            return (
                              <div className="flex items-center gap-2">
                                {dark_icon && <LabelIcon src={dark_icon} />}
                                <span>{label}</span>
                              </div>
                            ) as unknown as string
                          }}
                          className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                          isClearable={false}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      )}
                    />
                  ) : (
                    <p className="text-[#8D97A1] p-4">Select</p>
                  )}

                  {
                    // watch('coastalHay.status') && (watch('coastalHay.duration') && watch('coastalHay.duration') !== "" && watch('coastalHay.duration') !== "0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
              Note
            </div>
            <div>
              <div
                className={clsx(
                  watch(`coastalHay.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="text"
                  // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  className={clsx(
                    watch(`coastalHay.status`)
                      ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                      : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                    'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                  )}
                  value={!watch(`coastalHay.status`) ? '' : watch(`coastalHay.note`)}
                  disabled={!watch(`coastalHay.status`)}
                  placeholder="Enter note"
                  onClick={(e) => openAddNotesModal(e.target)}
                  readOnly={true}
                  {...register(`coastalHay.note`)}
                />
                {watch('coastalHay.status') &&
                watch('coastalHay.note') &&
                watch('coastalHay.note') !== '' &&
                watch('coastalHay.note') !== '0' ? (
                  <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/*======================*/}
        {/* Timothy hay */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto] laptop:grid-cols-[200px_120px_150px_150px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Timothy hay</div>
          </div>
          <div>
            <Controller
              name={`timothyHay.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() => setValue(`timothyHay.status`, !watch(`timothyHay.status`))}
                />
              )}
            />
          </div>
          <div>
            <div
              className={clsx(
                watch(`timothyHay.status`)
                  ? 'bg-SeabiscuitGrayThemeColor'
                  : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                'text-[14px] rounded-md flex items-center p-4 h-12'
              )}
            >
              <input
                type="number"
                className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                disabled={!watch(`timothyHay.status`)}
                placeholder="Units"
                value={!watch(`timothyHay.status`) ? '' : watch(`timothyHay.available`)}
                {...register(`timothyHay.available`)}
              />
            </div>
          </div>

          <div>
            <AmountInput
              name={`timothyHay.actualCostAlias`}
              disable={!watch(`timothyHay.status`)}
              register={register}
              prefix="$"
              clear={!watch(`timothyHay.status`)}
              placeholder="$0"
              childWidth={24}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
                watch(`timothyHay.status`) ? 'text-SeabiscuitDark200ThemeColor' : 'text-[#939AA2]'
              )}
              className={clsx(
                watch(`timothyHay.status`)
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white border-SeabiscuitLightThemeColorD3',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={!watch(`timothyHay.status`) ? '' : watch(`timothyHay.actualCostAlias`)}
            />
          </div>

          <div>
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div>
                <div
                  className={clsx(
                    watch(`timothyHay.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  {watch(`timothyHay.status`) ? (
                    <Controller
                      control={control}
                      name={`timothyHay.duration`}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          isMulti={false}
                          onChange={onChange}
                          value={getDurationSelectedValue(value)}
                          onBlur={onBlur}
                          isDisabled={!watch(`timothyHay.status`)}
                          components={{
                            DropdownIndicator: (props) => {
                              return <components.DropdownIndicator {...props} />
                            },
                            IndicatorSeparator: () => null,
                          }}
                          placeholder="Select"
                          options={DurationList}
                          getOptionLabel={(props: any) => {
                            const { dark_icon, label } = props
                            return (
                              <div className="flex items-center gap-2">
                                {dark_icon && <LabelIcon src={dark_icon} />}
                                <span>{label}</span>
                              </div>
                            ) as unknown as string
                          }}
                          className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                          isClearable={false}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      )}
                    />
                  ) : (
                    <p className="text-[#8D97A1] p-4">Select</p>
                  )}

                  {
                    // watch('timothyHay.status') && (watch('timothyHay.duration') && watch('timothyHay.duration') !== "" && watch('timothyHay.duration') !== "0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div>
              <div
                className={clsx(
                  watch(`timothyHay.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="text"
                  // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  className={clsx(
                    watch(`timothyHay.status`)
                      ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                      : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                    'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                  )}
                  placeholder="Enter note"
                  disabled={!watch(`timothyHay.status`)}
                  onClick={(e) => openAddNotesModal(e.target)}
                  readOnly={true}
                  value={!watch(`timothyHay.status`) ? '' : watch(`timothyHay.note`)}
                  {...register(`timothyHay.note`)}
                />
                {watch('timothyHay.status') &&
                watch('timothyHay.note') &&
                watch('timothyHay.note') !== '' &&
                watch('timothyHay.note') !== '0' ? (
                  <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/*======================*/}
        {/* Alfalfa hay */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto] laptop:grid-cols-[200px_120px_150px_150px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Alfalfa hay</div>
          </div>
          <div>
            <Controller
              name={`alfalfa.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() => setValue(`alfalfa.status`, !watch(`alfalfa.status`))}
                />
              )}
            />
          </div>
          <div>
            <div
              className={clsx(
                watch(`alfalfa.status`)
                  ? 'bg-SeabiscuitGrayThemeColor'
                  : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                'text-[14px] rounded-md flex items-center p-4 h-12'
              )}
            >
              <input
                type="number"
                disabled={!watch(`alfalfa.status`)}
                className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                placeholder="Units"
                {...register(`alfalfa.available`)}
                value={!watch(`alfalfa.status`) ? '' : watch(`alfalfa.available`)}
              />
              {
                // watch('alfalfa.status') && (watch('alfalfa.available')) ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
              }
            </div>
          </div>

          <div>
            <AmountInput
              name={`alfalfa.actualCostAlias`}
              disable={!watch(`alfalfa.status`)}
              register={register}
              prefix="$"
              clear={!watch(`alfalfa.status`)}
              placeholder="$0"
              childWidth={24}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
                watch(`alfalfa.status`) ? 'text-SeabiscuitDark200ThemeColor' : 'text-[#939AA2]'
              )}
              // className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
              value={!watch(`alfalfa.status`) ? '' : watch(`alfalfa.actualCostAlias`)}
              className={clsx(
                watch(`alfalfa.status`)
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white border-SeabiscuitLightThemeColorD3',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              childrenToAppend={
                <>
                  {/* {watch('alfalfa.status') && (watch('alfalfa.actualCostAlias') && watch('alfalfa.actualCostAlias') !== "$" && watch('alfalfa.actualCostAlias') !== "$0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null} */}
                </>
              }
            />
          </div>

          <div>
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div>
                <div
                  className={clsx(
                    watch(`alfalfa.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  {watch(`alfalfa.status`) ? (
                    <Controller
                      control={control}
                      name={`alfalfa.duration`}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          isMulti={false}
                          onChange={onChange}
                          isDisabled={!watch(`alfalfa.status`)}
                          value={getDurationSelectedValue(value)}
                          onBlur={onBlur}
                          components={{
                            DropdownIndicator: (props) => {
                              return <components.DropdownIndicator {...props} />
                            },
                            IndicatorSeparator: () => null,
                          }}
                          placeholder="Select"
                          options={DurationList}
                          getOptionLabel={(props: any) => {
                            const { dark_icon, label } = props
                            return (
                              <div className="flex items-center gap-2">
                                {dark_icon && <LabelIcon src={dark_icon} />}
                                <span>{label}</span>
                              </div>
                            ) as unknown as string
                          }}
                          className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                          isClearable={false}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      )}
                    />
                  ) : (
                    <p className="text-[#8D97A1] p-4">Select</p>
                  )}

                  {
                    // watch('alfalfa.status') && (watch('alfalfa.duration') && watch('alfalfa.duration') !== "" && watch('alfalfa.duration') !== "0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div>
              <div
                className={clsx(
                  watch(`alfalfa.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="text"
                  // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  className={clsx(
                    watch(`alfalfa.status`)
                      ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                      : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                    'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                  )}
                  disabled={!watch(`alfalfa.status`)}
                  placeholder="Enter note"
                  onClick={(e) => openAddNotesModal(e.target)}
                  readOnly={true}
                  value={!watch(`alfalfa.status`) ? '' : watch(`alfalfa.note`)}
                  {...register(`alfalfa.note`)}
                />
                {watch('alfalfa.status') &&
                watch('alfalfa.note') &&
                watch('alfalfa.note') !== '' &&
                watch('alfalfa.note') !== '0' ? (
                  <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/*======================*/}
        {/* TnA hay */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto] laptop:grid-cols-[200px_120px_150px_150px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">TnA hay</div>
          </div>
          <div>
            <Controller
              name={`TnA.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() => setValue(`TnA.status`, !watch(`TnA.status`))}
                />
              )}
            />
          </div>
          <div>
            <div
              className={clsx(
                watch(`TnA.status`)
                  ? 'bg-SeabiscuitGrayThemeColor'
                  : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                'text-[14px] rounded-md flex items-center p-4 h-12'
              )}
            >
              <input
                type="number"
                className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                disabled={!watch(`TnA.status`)}
                placeholder="Units"
                value={!watch(`TnA.status`) ? '' : watch(`TnA.available`)}
                {...register(`TnA.available`)}
              />
              {
                // watch('TnA.status') && (watch('TnA.available')) ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
              }
            </div>
          </div>

          <div>
            <AmountInput
              name={`TnA.actualCostAlias`}
              disable={!watch(`TnA.status`)}
              register={register}
              prefix="$"
              placeholder="$0"
              clear={!watch(`TnA.status`)}
              childWidth={24}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
                watch(`TnA.status`) ? 'text-SeabiscuitDark200ThemeColor' : 'text-[#939AA2]'
              )}
              // className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
              className={clsx(
                watch(`TnA.status`)
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white border-SeabiscuitLightThemeColorD3',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={!watch(`TnA.status`) ? '' : watch(`TnA.actualCostAlias`)}
              childrenToAppend={
                <>
                  {/* {watch('TnA.status') && (watch('TnA.actualCostAlias') && watch('TnA.actualCostAlias') !== "$" && watch('TnA.actualCostAlias') !== "$0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null} */}
                </>
              }
            />
          </div>

          <div>
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div>
                <div
                  className={clsx(
                    watch(`TnA.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  {watch(`TnA.status`) ? (
                    <Controller
                      control={control}
                      name={`TnA.duration`}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          isMulti={false}
                          onChange={onChange}
                          value={getDurationSelectedValue(value)}
                          onBlur={onBlur}
                          components={{
                            DropdownIndicator: (props) => {
                              return <components.DropdownIndicator {...props} />
                            },
                            IndicatorSeparator: () => null,
                          }}
                          placeholder="Select"
                          isDisabled={!watch(`TnA.status`)}
                          options={DurationList}
                          getOptionLabel={(props: any) => {
                            const { dark_icon, label } = props
                            return (
                              <div className="flex items-center gap-2">
                                {dark_icon && <LabelIcon src={dark_icon} />}
                                <span>{label}</span>
                              </div>
                            ) as unknown as string
                          }}
                          className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                          isClearable={false}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      )}
                    />
                  ) : (
                    <p className="text-[#8D97A1] p-4">Select</p>
                  )}

                  {
                    // watch('TnA.status') && (watch('TnA.duration') && watch('TnA.duration') !== "" && watch('TnA.duration') !== "0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div>
              <div
                className={clsx(
                  watch(`TnA.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="text"
                  // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  className={clsx(
                    watch(`TnA.status`)
                      ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                      : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                    'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                  )}
                  value={!watch(`TnA.status`) ? '' : watch(`TnA.note`)}
                  placeholder="Enter note"
                  onClick={(e) => openAddNotesModal(e.target)}
                  readOnly={true}
                  disabled={!watch(`TnA.status`)}
                  {...register(`TnA.note`)}
                />
                {watch('TnA.status') &&
                watch('TnA.note') &&
                watch('TnA.note') !== '' &&
                watch('TnA.note') !== '0' ? (
                  <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/*======================*/}
        {/* Extra Feed Fees */}
        {/*======================*/}
        {extraFeedFees.fields.map((field: any, index) => (
          <div
            key={field.id}
            className="mt-12 md:mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto_25px] laptop:grid-cols-[200px_120px_150px_150px_200px_auto_25px]"
          >
            <div className="flex items-center gap-1">
              <div className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12">
                <input
                  type="text"
                  className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  placeholder="Feed Fee"
                  value={
                    !watch(`extraFeedFees.${index}.status`)
                      ? ''
                      : watch(`extraFeedFees.${index}.title`)
                  }
                  {...register(`extraFeedFees.${index}.title`)}
                  disabled={!watch(`extraFeedFees.${index}.status`)}
                />
                {/* {
                                    watch(`extraFeedFees.${index}.status`) && (watch(`extraFeedFees.${index}.title`) && watch(`extraFeedFees.${index}.title`) !== "" && watch(`extraFeedFees.${index}.title`) !== "0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
                                } */}
              </div>
            </div>
            <div>
              <Controller
                name={`extraFeedFees.${index}.status`}
                control={control}
                render={({ field: { value } }) => (
                  <TogglesElement
                    on={value}
                    onToggle={() =>
                      setValue(
                        `extraFeedFees.${index}.status`,
                        !watch(`extraFeedFees.${index}.status`)
                      )
                    }
                  />
                )}
              />
            </div>
            <div>
              <div
                className={clsx(
                  watch(`extraFeedFees.${index}.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="number"
                  className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  placeholder="Amount"
                  value={
                    !watch(`extraFeedFees.${index}.status`)
                      ? ''
                      : watch(`extraFeedFees.${index}.available`)
                  }
                  disabled={!watch(`extraFeedFees.${index}.status`)}
                  {...register(`extraFeedFees.${index}.available`)}
                />
                {
                  // watch(`extraFeedFees.${index}.status`) && (watch(`extraFeedFees.${index}.available`) && watch(`extraFeedFees.${index}.available`) !== "" && watch(`extraFeedFees.${index}.available`) !== "0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
                }
              </div>
            </div>
            <AmountInput
              name={`extraFeedFees.${index}.actualCostAlias`}
              disable={!watch(`extraFeedFees.${index}.status`)}
              register={register}
              prefix="$"
              clear={!watch(`extraFeedFees.${index}.status`)}
              placeholder="Price per stall"
              childWidth={24}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
                watch(`extraFeedFees.${index}.status`)
                  ? 'text-SeabiscuitDark200ThemeColor'
                  : 'text-[#939AA2]'
              )}
              // className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
              className={clsx(
                watch(`extraFeedFees.${index}.status`)
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white border-SeabiscuitLightThemeColorD3',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={
                !watch(`extraFeedFees.${index}.status`)
                  ? ''
                  : watch(`extraFeedFees.${index}.actualCostAlias`)
              }
              childrenToAppend={
                <>
                  {/* {watch(`extraFeedFees.${index}.status`) && (watch(`extraFeedFees.${index}.actualCostAlias`) && watch(`extraFeedFees.${index}.actualCostAlias`) !== "$" && watch(`extraFeedFees.${index}.actualCostAlias`) !== "$0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null} */}
                </>
              }
            />
            <div>
              <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
                <div>
                  <div
                    className={clsx(
                      watch(`extraFeedFees.${index}.status`)
                        ? 'bg-SeabiscuitGrayThemeColor'
                        : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                      'text-[14px] rounded-md flex items-center p-4 h-12'
                    )}
                  >
                    {watch(`extraFeedFees.${index}.status`) ? (
                      <Controller
                        control={control}
                        name={`extraFeedFees.${index}.duration`}
                        render={({ field: { onChange, onBlur, value } }) => {
                          return (
                            <Select
                              isMulti={false}
                              onChange={onChange}
                              components={{
                                DropdownIndicator: (props) => {
                                  return <components.DropdownIndicator {...props} />
                                },
                                IndicatorSeparator: () => null,
                              }}
                              value={getDurationSelectedValue(value)}
                              onBlur={onBlur}
                              placeholder="Select"
                              options={DurationList}
                              getOptionLabel={(props: any) => {
                                const { dark_icon, label } = props
                                return (
                                  <div className="flex items-center gap-2">
                                    {dark_icon && <LabelIcon src={dark_icon} />}
                                    <span>{label}</span>
                                  </div>
                                ) as unknown as string
                              }}
                              className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                              isClearable={false}
                              styles={customStyles}
                              isSearchable={false}
                              isDisabled={!watch(`extraFeedFees.${index}.status`)}
                            />
                          )
                        }}
                      />
                    ) : (
                      <p className="text-[#8D97A1] p-4">Select</p>
                    )}
                    {
                      // watch(`extraFeedFees.${index}.status`) && (watch(`extraFeedFees.${index}.duration`) && watch(`extraFeedFees.${index}.duration`) !== "" && watch(`extraFeedFees.${index}.duration`) !== "0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div className="text-xs font-bold hidden text-SeabiscuitDark200ThemeColor">Note</div>
              <div>
                <div
                  className={clsx(
                    watch(`extraFeedFees.${index}.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  <input
                    type="text"
                    // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                    className={clsx(
                      watch(`extraFeedFees.${index}.status`)
                        ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                        : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                      'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                    )}
                    placeholder="Enter note"
                    value={
                      !watch(`extraFeedFees.${index}.status`)
                        ? ''
                        : watch(`extraFeedFees.${index}.note`)
                    }
                    disabled={!watch(`extraFeedFees.${index}.status`)}
                    onClick={(e) => openAddNotesModal(e.target)}
                    readOnly={true}
                    {...register(`extraFeedFees.${index}.note`)}
                  />
                  {watch(`extraFeedFees.${index}.status`) &&
                  watch(`extraFeedFees.${index}.note`) &&
                  watch(`extraFeedFees.${index}.note`) !== '' &&
                  watch(`extraFeedFees.${index}.note`) !== '0' ? (
                    <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                  ) : null}
                </div>
              </div>
            </div>

            <div>
              <button
                onClick={() => {
                  handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                    removeItem: function () {
                      extraFeedFees.remove(index)
                    },
                  })
                }}
              >
                <div className="cursor-pointer">
                  <img
                    src="/assets/og_icons/Cancel.svg"
                    className="h-[24px] w-[24px]"
                    alt="Cancel"
                  />
                </div>
              </button>
            </div>
          </div>
        ))}
        <div className="flex items-center gap-2 justify-between">
          <button
            onClick={() => {
              extraFeedFees.append({
                status: false,
                category: 'feed',
                title: '',
                available: 0,
                sold: 0,
                actualCostAlias: '$0',
                duration: '',
                note: '',
                uuid: uuidv4(),
              })
            }}
            className="my-3 text-SeabiscuitMainThemeColor font-normal text-[12px] flex items-center gap-2"
          >
            <img src={`/assets/og_icons/Cancel-2.svg`} className=" rotate-45" alt="plus icon" />
            <span>Add feed fee</span>
          </button>
          <button
            onClick={() => {
              extraFeedFees.remove()
              reset({
                ...getValues(),
                coastalHay: eventFeesFormDefaultValues.coastalHay,
                timothyHay: eventFeesFormDefaultValues.timothyHay,
                alfalfa: eventFeesFormDefaultValues.alfalfa,
                TnA: eventFeesFormDefaultValues.TnA,
              })
            }}
            className="my-3 text-SeabiscuitDark200ThemeColor font-normal hover:text-SeabiscuitMainThemeColor text-[12px] flex items-center gap-2"
          >
            <span>Clear All</span>
          </button>
        </div>
        <EventSchedulingFormComponentVerticalLine />
        {/*======================*/}
        {/* OTHER FEES */}
        {/*======================*/}
        <Subheader title="Other Fees" subtitle="Add other miscellaneous fees to this event" />
        {/*======================*/}
        {/* Paddock */}
        {/*======================*/}
        <div className="mt-4 grid gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto] laptop:grid-cols-[200px_120px_150px_150px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Paddock</div>
          </div>
          <div className="flex items-end">
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor"></div>
            <Controller
              name={`paddockFee.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() => setValue(`paddockFee.status`, !watch(`paddockFee.status`))}
                />
              )}
            />
          </div>
          <div>
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
              Units available
            </div>
            <div
              className={clsx(
                watch(`paddockFee.status`)
                  ? 'bg-SeabiscuitGrayThemeColor'
                  : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                'text-[14px] rounded-md flex items-center p-4 h-12'
              )}
            >
              <input
                type="number"
                disabled={!watch(`paddockFee.status`)}
                className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                placeholder="Units"
                {...register(`paddockFee.available`)}
                value={!watch(`paddockFee.status`) ? '' : watch(`paddockFee.available`)}
              />
            </div>
          </div>

          <div>
            <div
              className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor"
              {...dataIncrementAttrProps}
            >
              Price per unit
            </div>
            <AmountInput
              name={`paddockFee.actualCostAlias`}
              disable={!watch(`paddockFee.status`)}
              register={register}
              prefix="$"
              placeholder="$0"
              childWidth={24}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
                watch(`paddockFee.status`) ? 'text-SeabiscuitDark200ThemeColor' : 'text-[#939AA2]'
              )}
              // className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
              className={clsx(
                watch(`paddockFee.status`)
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white border-SeabiscuitLightThemeColorD3',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={!watch(`paddockFee.status`) ? '' : watch(`paddockFee.actualCostAlias`)}
              childrenToAppend={
                <>
                  {/* {watch('paddockFee.status') && (watch('paddockFee.actualCostAlias') && watch('paddockFee.actualCostAlias') !== "$" && watch('paddockFee.actualCostAlias') !== "$0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null} */}
                </>
              }
            />
          </div>

          <div>
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
                Duration
              </div>
              <div>
                <div
                  className={clsx(
                    watch(`paddockFee.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  {watch(`paddockFee.status`) ? (
                    <Controller
                      control={control}
                      name={`paddockFee.duration`}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          isMulti={false}
                          isDisabled={!watch(`paddockFee.status`)}
                          onChange={onChange}
                          value={getDurationSelectedValue(value)}
                          onBlur={onBlur}
                          components={{
                            DropdownIndicator: (props) => {
                              return <components.DropdownIndicator {...props} />
                            },
                            IndicatorSeparator: () => null,
                          }}
                          placeholder="Select"
                          options={DurationList}
                          getOptionLabel={(props: any) => {
                            const { dark_icon, label } = props
                            return (
                              <div className="flex items-center gap-2">
                                {dark_icon && <LabelIcon src={dark_icon} />}
                                <span>{label}</span>
                              </div>
                            ) as unknown as string
                          }}
                          className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                          isClearable={false}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      )}
                    />
                  ) : (
                    <p className="text-[#8D97A1] p-4">Select</p>
                  )}

                  {
                    // watch('paddockFee.status') && (watch('paddockFee.duration') && watch('paddockFee.duration') !== "" && watch('paddockFee.duration') !== "0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
              Note
            </div>
            <div>
              <div
                className={clsx(
                  watch(`paddockFee.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="text"
                  disabled={!watch(`paddockFee.status`)}
                  // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  className={clsx(
                    watch(`paddockFee.status`)
                      ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                      : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                    'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                  )}
                  placeholder="Enter note"
                  onClick={(e) => openAddNotesModal(e.target)}
                  readOnly={true}
                  {...register(`paddockFee.note`)}
                  value={!watch(`paddockFee.status`) ? '' : watch(`paddockFee.note`)}
                />
                {watch('paddockFee.status') &&
                watch('paddockFee.note') &&
                watch('paddockFee.note') !== '' &&
                watch('paddockFee.note') !== '0' ? (
                  <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/*======================*/}
        {/* RV hookup */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto] laptop:grid-cols-[200px_120px_150px_150px_200px_auto]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">RV hookup</div>
          </div>
          <div>
            <Controller
              name={`RVHookupFee.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() => setValue(`RVHookupFee.status`, !watch(`RVHookupFee.status`))}
                />
              )}
            />
          </div>
          <div
            className={clsx(
              watch(`RVHookupFee.status`)
                ? 'bg-SeabiscuitGrayThemeColor'
                : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
              'text-[14px] rounded-md flex items-center p-4 h-12'
            )}
          >
            <input
              type="number"
              disabled={!watch(`RVHookupFee.status`)}
              className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
              placeholder="Units"
              {...register(`RVHookupFee.available`)}
              value={!watch(`RVHookupFee.status`) ? '' : watch(`RVHookupFee.available`)}
            />

            {
              // watch('RVHookupFee.status') && (watch('RVHookupFee.available')) ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
            }
          </div>

          <AmountInput
            name={`RVHookupFee.actualCostAlias`}
            disable={!watch(`RVHookupFee.status`)}
            register={register}
            prefix="$"
            placeholder="$0"
            childWidth={24}
            inputClassName={clsx(
              'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
              watch(`RVHookupFee.status`) ? 'text-SeabiscuitDark200ThemeColor' : 'text-[#939AA2]'
            )}
            // className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
            className={clsx(
              watch(`RVHookupFee.status`)
                ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                : 'bg-white border-SeabiscuitLightThemeColorD3',
              'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
            )}
            value={!watch(`RVHookupFee.status`) ? '' : watch(`RVHookupFee.actualCostAlias`)}
            childrenToAppend={
              <>
                {/* {watch('RVHookupFee.status') && (watch('RVHookupFee.actualCostAlias') && watch('RVHookupFee.actualCostAlias') !== "$" && watch('RVHookupFee.actualCostAlias') !== "$0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null} */}
              </>
            }
          />

          <div>
            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div>
                <div
                  className={clsx(
                    watch(`RVHookupFee.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  {watch(`RVHookupFee.status`) ? (
                    <Controller
                      control={control}
                      name={`RVHookupFee.duration`}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          isMulti={false}
                          onChange={onChange}
                          value={getDurationSelectedValue(value)}
                          onBlur={onBlur}
                          isDisabled={!watch(`RVHookupFee.status`)}
                          components={{
                            DropdownIndicator: (props) => {
                              return <components.DropdownIndicator {...props} />
                            },
                            IndicatorSeparator: () => null,
                          }}
                          placeholder="Select"
                          options={DurationList}
                          getOptionLabel={(props: any) => {
                            const { dark_icon, label } = props
                            return (
                              <div className="flex items-center gap-2">
                                {dark_icon && <LabelIcon src={dark_icon} />}
                                <span>{label}</span>
                              </div>
                            ) as unknown as string
                          }}
                          className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                          isClearable={false}
                          styles={customStyles}
                          isSearchable={false}
                        />
                      )}
                    />
                  ) : (
                    <p className="text-[#8D97A1] p-4">Select</p>
                  )}

                  {
                    // watch('RVHookupFee.status') && (watch('RVHookupFee.duration') && watch('RVHookupFee.duration') !== "" && watch('RVHookupFee.duration') !== "0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
            <div>
              <div
                className={clsx(
                  watch(`RVHookupFee.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="text"
                  // className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  className={clsx(
                    watch(`RVHookupFee.status`)
                      ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                      : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                    'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                  )}
                  disabled={!watch(`RVHookupFee.status`)}
                  placeholder="Enter note"
                  onClick={(e) => openAddNotesModal(e.target)}
                  readOnly={true}
                  value={!watch(`RVHookupFee.status`) ? '' : watch(`RVHookupFee.note`)}
                  {...register(`RVHookupFee.note`)}
                />
                {watch('RVHookupFee.status') &&
                watch('RVHookupFee.note') &&
                watch('RVHookupFee.note') !== '' &&
                watch('RVHookupFee.note') !== '0' ? (
                  <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {extraOtherFees.fields.map((field: any, index) => (
          <div
            key={field.id}
            className="mt-12 md:mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_auto_auto_25px] laptop:grid-cols-[200px_120px_150px_150px_200px_auto_25px]"
          >
            <div className="flex items-center gap-1">
              <div className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12">
                <input
                  type="text"
                  className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  placeholder="Other Fee"
                  {...register(`extraOtherFees.${index}.title`)}
                  disabled={!watch(`extraOtherFees.${index}.status`)}
                  value={
                    !watch(`extraOtherFees.${index}.status`)
                      ? ''
                      : watch(`extraOtherFees.${index}.title`)
                  }
                />
                {
                  // watch(`extraOtherFees.${index}.status`) && (watch(`extraOtherFees.${index}.title`) && watch(`extraOtherFees.${index}.title`) !== "$" && watch(`extraOtherFees.${index}.title`) !== "$0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
                }
              </div>
            </div>
            <div>
              <Controller
                name={`extraOtherFees.${index}.status`}
                control={control}
                render={({ field: { value } }) => (
                  <TogglesElement
                    on={value}
                    onToggle={() =>
                      setValue(
                        `extraOtherFees.${index}.status`,
                        !watch(`extraOtherFees.${index}.status`)
                      )
                    }
                  />
                )}
              />
            </div>
            <div>
              <div
                className={clsx(
                  watch(`extraOtherFees.${index}.status`)
                    ? 'bg-SeabiscuitGrayThemeColor'
                    : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                  'text-[14px] rounded-md flex items-center p-4 h-12'
                )}
              >
                <input
                  type="number"
                  disabled={!watch(`extraOtherFees.${index}.status`)}
                  className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  placeholder="Units"
                  value={
                    !watch(`extraOtherFees.${index}.status`)
                      ? ''
                      : watch(`extraOtherFees.${index}.available`)
                  }
                  {...register(`extraOtherFees.${index}.available`)}
                />
                {
                  // watch(`extraOtherFees.${index}.status`) && (watch(`extraOtherFees.${index}.available`) && watch(`extraOtherFees.${index}.available`) !== "$" && watch(`extraOtherFees.${index}.available`) !== "$0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
                }
              </div>
            </div>

            <AmountInput
              name={`extraOtherFees.${index}.actualCostAlias`}
              disable={!watch(`extraOtherFees.${index}.status`)}
              register={register}
              prefix="$"
              placeholder="$0"
              childWidth={24}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 ',
                watch(`extraOtherFees.${index}.status`)
                  ? 'text-SeabiscuitDark200ThemeColor'
                  : 'text-[#939AA2]'
              )}
              // className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
              className={clsx(
                watch(`extraOtherFees.${index}.status`)
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white border-SeabiscuitLightThemeColorD3',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={
                !watch(`extraOtherFees.${index}.status`)
                  ? ''
                  : watch(`extraOtherFees.${index}.actualCostAlias`)
              }
              childrenToAppend={
                <>
                  {/* {watch(`extraOtherFees.${index}.status`) && (watch(`extraOtherFees.${index}.actualCostAlias`) && watch(`extraOtherFees.${index}.actualCostAlias`) !== "$" && watch(`extraOtherFees.${index}.actualCostAlias`) !== "$0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null} */}
                </>
              }
            />

            <div>
              <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
                <div>
                  <div
                    className={clsx(
                      watch(`extraOtherFees.${index}.status`)
                        ? 'bg-SeabiscuitGrayThemeColor'
                        : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                      'text-[14px] rounded-md flex items-center p-4 h-12'
                    )}
                  >
                    {watch(`extraOtherFees.${index}.status`) ? (
                      <Controller
                        control={control}
                        name={`extraOtherFees.${index}.duration`}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            isMulti={false}
                            onChange={onChange}
                            value={getDurationSelectedValue(value)}
                            isDisabled={!watch(`extraOtherFees.${index}.status`)}
                            onBlur={onBlur}
                            components={{
                              DropdownIndicator: (props) => {
                                return <components.DropdownIndicator {...props} />
                              },
                              IndicatorSeparator: () => null,
                            }}
                            placeholder="Select"
                            options={DurationList}
                            getOptionLabel={(props: any) => {
                              const { dark_icon, label } = props
                              return (
                                <div className="flex items-center gap-2">
                                  {dark_icon && <LabelIcon src={dark_icon} />}
                                  <span>{label}</span>
                                </div>
                              ) as unknown as string
                            }}
                            className="rounded-xl text-SeabiscuitDark200ThemeColor w-full"
                            isClearable={false}
                            styles={customStyles}
                            isSearchable={false}
                          />
                        )}
                      />
                    ) : (
                      <p className="text-[#8D97A1] p-4">Select</p>
                    )}
                    {
                      // watch(`extraOtherFees.${index}.status`) && (watch(`extraOtherFees.${index}.duration`) && watch(`extraOtherFees.${index}.duration`) !== "" && watch(`extraOtherFees.${index}.duration`) !== "0") ? <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" /> : null
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="tablet:col-start-2 tablet:col-span-3 laptop:col-span-1">
              <div>
                <div
                  className={clsx(
                    watch(`extraOtherFees.${index}.status`)
                      ? 'bg-SeabiscuitGrayThemeColor'
                      : 'bg-[#fff] border border-SeabiscuitLightThemeColorD3',
                    'text-[14px] rounded-md flex items-center p-4 h-12'
                  )}
                >
                  <input
                    type="text"
                    disabled={!watch(`extraOtherFees.${index}.status`)}
                    className={clsx(
                      watch(`extraOtherFees.${index}.status`)
                        ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                        : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor rounded-none',
                      'text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md outline-0 ring-0 focus:outline-0 focus:ring-0 focus:border-0 flex items-center p-4 h-12 border-0 border-solid cursor-pointer overflow-hidden whitespace-nowrap truncate !border-t-[1px] !border-b-[1px]'
                    )}
                    placeholder="Enter note"
                    onClick={(e) => openAddNotesModal(e.target)}
                    readOnly={true}
                    value={
                      !watch(`extraOtherFees.${index}.status`)
                        ? ''
                        : watch(`extraOtherFees.${index}.note`)
                    }
                    {...register(`extraOtherFees.${index}.note`)}
                  />
                  {watch(`extraOtherFees.${index}.status`) &&
                  watch(`extraOtherFees.${index}.note`) &&
                  watch(`extraOtherFees.${index}.note`) !== '' &&
                  watch(`extraOtherFees.${index}.note`) !== '0' ? (
                    <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                  ) : null}
                </div>
              </div>
            </div>
            <div>
              <button
                onClick={() => {
                  handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                    removeItem: function () {
                      extraOtherFees.remove(index)
                    },
                  })
                }}
              >
                <div className="cursor-pointer">
                  <img
                    src="/assets/og_icons/Cancel.svg"
                    className="h-[24px] w-[24px]"
                    alt="Cancel"
                  />
                </div>
              </button>
            </div>
          </div>
        ))}
        <div className="flex items-center gap-2 justify-between">
          <button
            onClick={() => {
              extraOtherFees.append({
                title: '',
                status: false,
                category: 'other',
                available: 0,
                actualCostAlias: '$0',
                sold: 0,
                duration: '',
                note: '',
                uuid: uuidv4(),
              })
            }}
            className="my-3 text-SeabiscuitMainThemeColor font-normal text-[12px] flex items-center gap-2"
          >
            <img src={`/assets/og_icons/Cancel-2.svg`} className=" rotate-45" alt="plus icon" />
            <span>Add other fee</span>
          </button>
          <button
            onClick={() => {
              extraOtherFees.remove()
              reset({
                ...getValues(),
                paddockFee: eventFeesFormDefaultValues.paddockFee,
                RVHookupFee: eventFeesFormDefaultValues.RVHookupFee,
                // shipInGroundFee: eventFeesFormDefaultValues.shipInGroundFee,
              })
            }}
            className="my-3 text-SeabiscuitDark200ThemeColor font-normal hover:text-SeabiscuitMainThemeColor text-[12px] flex items-center gap-2"
          >
            <span>Clear All</span>
          </button>
        </div>
        <EventSchedulingFormComponentVerticalLine />
        <Subheader
          title="Mandatory Fees"
          subtitle="Add mandatory fees that will be charged per horse registered"
        />
        {/*======================*/}
        {/* USEF fee */}
        {/*======================*/}
        <div className="mt-4 grid gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_250px]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Office fee</div>
            {/*<Tooltip*/}
            {/*  title={*/}
            {/*    <h1 className="tooltip_title">{`As of 2023, USEF fee is $8. For competitions held in California, the USEF fee is $22 due to CDFA requirements`}</h1>*/}
            {/*  }*/}
            {/*  placement="top"*/}
            {/*  arrow*/}
            {/*>*/}
            {/*  <button className="">*/}
            {/*    <TooltipIcon color="#122B46" className="w-[16px] h-[16px]" />*/}
            {/*  </button>*/}
            {/*</Tooltip>*/}
          </div>

          <div className="flex items-end">
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor"></div>
            <Controller
              name={`USEFFee.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() => {
                    setValue(`USEFFee.status`, !watch(`USEFFee.status`))
                    trigger('USEFFee.status')
                  }}
                />
              )}
            />
          </div>

          <div className="w-[250px]">
            <div className="text-xs font-bold hidden laptop:block text-SeabiscuitDark200ThemeColor">
              Amount
            </div>
            <AmountInput
              name="USEFFee.actualCostAlias"
              register={register}
              disable={!watch(`USEFFee.status`)}
              prefix={watch('USEFFee.status') ? '$' : undefined}
              placeholder={watch('USEFFee.status') ? 'Amount' : '$0'}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor'
              )}
              className={clsx(
                watch('USEFFee.status')
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor ',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={watch('USEFFee.status') ? watch('USEFFee.actualCostAlias') : 'N/A'}
              required={watch('USEFFee.status')}
              childrenToAppend={
                <>
                  {watch('USEFFee.status') &&
                  watch('USEFFee.actualCostAlias') &&
                  watch('USEFFee.actualCostAlias') !== '$' &&
                  watch('USEFFee.actualCostAlias') !== '$0' ? (
                    <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                  ) : null}
                </>
              }
            />
          </div>
        </div>
        <div className="grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_250px]">
          <div></div>
          <div></div>
          <div>
            {
              <>
                {errors.USEFFee && (errors.USEFFee as any)?.actualCostAlias ? (
                  <p className="text-[11px] text-SeabiscuitMainThemeColor">
                    Please provide a valid price
                  </p>
                ) : null}

                {errors.USEFFee && (errors.USEFFee as any)?.status ? (
                  <p className="text-[11px] text-SeabiscuitMainThemeColor">
                    Office fee is required field
                  </p>
                ) : null}
              </>
            }
          </div>
        </div>
        {/*======================*/}
        {/* USEF Drug fee */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_250px]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Ship in / Ground fee</div>
          </div>
          <div>
            <Controller
              name={`USEFDrugFee.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() => setValue(`USEFDrugFee.status`, !watch(`USEFDrugFee.status`))}
                />
              )}
            />
          </div>
          <div className="w-[250px]">
            <AmountInput
              name="USEFDrugFee.actualCostAlias"
              disable={!watch(`USEFDrugFee.status`)}
              register={register}
              prefix={watch('USEFDrugFee.status') ? '$' : undefined}
              placeholder={watch('USEFDrugFee.status') ? 'Amount' : '$0'}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor'
              )}
              className={clsx(
                watch('USEFDrugFee.status')
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={watch('USEFDrugFee.status') ? watch('USEFDrugFee.actualCostAlias') : 'N/A'}
              required={watch('USEFDrugFee.status')}
              childrenToAppend={
                <>
                  {watch('USEFDrugFee.status') &&
                  watch('USEFDrugFee.actualCostAlias') &&
                  watch('USEFDrugFee.actualCostAlias') !== '$' &&
                  watch('USEFDrugFee.actualCostAlias') !== '$0' ? (
                    <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                  ) : null}
                </>
              }
            />
          </div>
        </div>
        <div className="grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_250px]">
          <div></div>
          <div></div>
          <div>
            {errors.USEFDrugFee && (errors.USEFDrugFee as any)?.actualCostAlias ? (
              <p className="text-[11px] text-SeabiscuitMainThemeColor">
                Please provide a valid price
              </p>
            ) : null}
          </div>
        </div>
        {/*======================*/}
        {/* USEF Show Pass fee */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_250px]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Ambulance fee</div>
          </div>
          <div>
            <Controller
              name={`USEFShowPassFee.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() =>
                    setValue(`USEFShowPassFee.status`, !watch(`USEFShowPassFee.status`))
                  }
                />
              )}
            />
          </div>
          <div className="w-[250px]">
            <AmountInput
              register={register}
              name="USEFShowPassFee.actualCostAlias"
              disable={!watch(`USEFShowPassFee.status`)}
              prefix={watch('USEFShowPassFee.status') ? '$' : undefined}
              placeholder={watch('USEFShowPassFee.status') ? 'Amount' : '$0'}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor'
              )}
              className={clsx(
                watch('USEFShowPassFee.status')
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor ',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={
                watch('USEFShowPassFee.status') ? watch('USEFShowPassFee.actualCostAlias') : 'N/A'
              }
              required={watch('USEFShowPassFee.status')}
              childrenToAppend={
                <>
                  {watch('USEFShowPassFee.status') &&
                  watch('USEFShowPassFee.actualCostAlias') &&
                  watch('USEFShowPassFee.actualCostAlias') !== '$' &&
                  watch('USEFShowPassFee.actualCostAlias') !== '$0' ? (
                    <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                  ) : null}
                </>
              }
            />
          </div>
        </div>
        <div className="grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_250px]">
          <div></div>
          <div></div>
          <div>
            {errors.USEFShowPassFee && (errors.USEFShowPassFee as any)?.actualCostAlias ? (
              <p className="text-[11px] text-SeabiscuitMainThemeColor">
                Please provide a valid price
              </p>
            ) : null}
          </div>
        </div>
        {/*======================*/}
        {/* USDF fee */}
        {/*======================*/}
        <div className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_250px]">
          <div className="flex items-center gap-1">
            <div className="text-SeabiscuitDark200ThemeColor">Night Watch</div>
          </div>
          <div>
            <Controller
              name={`USDFFee.status`}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  on={value}
                  onToggle={() => setValue(`USDFFee.status`, !watch(`USDFFee.status`))}
                />
              )}
            />
          </div>

          <div className="w-[250px]">
            <AmountInput
              name="USDFFee.actualCostAlias"
              disable={!watch(`USDFFee.status`)}
              register={register}
              prefix={watch('USDFFee.status') ? '$' : undefined}
              placeholder={watch('USDFFee.status') ? 'Amount' : '$0'}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor'
              )}
              className={clsx(
                watch('USDFFee.status')
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor ',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={watch('USDFFee.status') ? watch('USDFFee.actualCostAlias') : 'N/A'}
              required={watch('USDFFee.status')}
              childrenToAppend={
                <>
                  {watch('USDFFee.status') &&
                  watch('USDFFee.actualCostAlias') &&
                  watch('USDFFee.actualCostAlias') !== '$' &&
                  watch('USDFFee.actualCostAlias') !== '$0' ? (
                    <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                  ) : null}
                </>
              }
            />
          </div>
        </div>
        <div className="grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_250px]">
          <div></div>
          <div></div>
          <div>
            {errors.USDFFee && (errors.USDFFee as any)?.actualCostAlias ? (
              <p className="text-[11px] text-SeabiscuitMainThemeColor">
                Please provide a valid price
              </p>
            ) : null}
          </div>
        </div>
        {/* Extra governanceFee */}
        {extraGovernanceFees.fields.map((field: any, index) => (
          <div
            key={field.id}
            className="mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_150px_250px_80px]"
          >
            <div className="flex items-center gap-1">
              <div className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12">
                <input
                  type="text"
                  disabled={!watch(`extraGovernanceFees.${index}.status`)}
                  className="w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate"
                  placeholder="Mandatory Fee"
                  {...register(`extraGovernanceFees.${index}.name`)}
                  value={
                    !watch(`extraGovernanceFees.${index}.status`)
                      ? ''
                      : watch(`extraGovernanceFees.${index}.title`)
                  }
                />
                {/* <img src={GreenCheck} alt="green check" /> */}
              </div>
            </div>
            <div>
              <Controller
                name={`extraGovernanceFees.${index}.status`}
                control={control}
                render={({ field: { value } }) => (
                  <TogglesElement
                    on={value}
                    onToggle={() =>
                      setValue(
                        `extraGovernanceFees.${index}.status`,
                        !watch(`extraGovernanceFees.${index}.status`)
                      )
                    }
                  />
                )}
              />
            </div>
            <AmountInput
              name={`extraGovernanceFees.${index}.actualCostAlias`}
              disable={!watch(`extraGovernanceFees.${index}.status`)}
              register={register}
              prefix={watch(`extraGovernanceFees.${index}.status`) ? '$' : undefined}
              placeholder={watch(`extraGovernanceFees.${index}.status`) ? 'Amount' : '$0'}
              inputClassName={clsx(
                'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor'
              )}
              className={clsx(
                watch(`extraGovernanceFees.${index}.status`)
                  ? 'bg-SeabiscuitGrayThemeColor !border-SeabiscuitGrayThemeColor'
                  : 'bg-white !border-SeabiscuitLightThemeColorD3 text-SeabiscuitDark200ThemeColor ',
                'text-[14px] w-full rounded-md flex items-center p-4 h-12 border border-solid'
              )}
              value={
                watch(`extraGovernanceFees.${index}.status`)
                  ? watch(`extraGovernanceFees.${index}.actualCostAlias`)
                  : 'N/A'
              }
              required={watch(`extraGovernanceFees.${index}.status`)}
              childrenToAppend={
                <>
                  {watch(`extraGovernanceFees.${index}.status`) &&
                  watch(`extraGovernanceFees.${index}.actualCostAlias`) &&
                  watch(`extraGovernanceFees.${index}.actualCostAlias`) !== '$' &&
                  watch(`extraGovernanceFees.${index}.actualCostAlias`) !== '$0' ? (
                    <img src="/assets/og_icons/Ok-3.svg" alt="okIcon" />
                  ) : null}
                </>
              }
            />
            <div>
              <button
                onClick={() => {
                  handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                    removeItem: function () {
                      extraGovernanceFees.remove(index)
                    },
                  })
                }}
              >
                <div className="cursor-pointer">
                  <img
                    src="/assets/og_icons/Cancel.svg"
                    className="h-[24px] w-[24px]"
                    alt="Cancel"
                  />
                </div>
              </button>
            </div>
          </div>
        ))}
        {/* Extra governanceFee */}
        <div className="flex items-center gap-2 justify-between">
          <button
            onClick={() => {
              extraGovernanceFees.append({
                name: '',
                status: false,
                category: 'governance',
                actualCostAlias: '$0',
                uuid: uuidv4(),
              })
            }}
            className="my-3 text-SeabiscuitMainThemeColor font-normal text-[12px] flex items-center gap-2"
          >
            <img src={`/assets/og_icons/Cancel-2.svg`} className=" rotate-45" alt="plus icon" />
            <span>Add mandatory fee</span>
          </button>
          <button
            onClick={() => {
              extraGovernanceFees.remove()
              reset({
                ...getValues(),
                USEFFee: eventFeesFormDefaultValues.USEFFee,
                USEFDrugFee: eventFeesFormDefaultValues.USEFDrugFee,
                USEFShowPassFee: eventFeesFormDefaultValues.USEFShowPassFee,
                USDFFee: eventFeesFormDefaultValues.USDFFee,
                USDFShowPassFee: eventFeesFormDefaultValues.USDFShowPassFee,
                USEAFee: eventFeesFormDefaultValues.USEAFee,
                USEAShowPassFee: eventFeesFormDefaultValues.USEAShowPassFee,
                USHJAHoursFee: eventFeesFormDefaultValues.USHJAHoursFee,
                USHJAShowPassFee: eventFeesFormDefaultValues.USHJAShowPassFee,
              })
            }}
            className="my-3 text-SeabiscuitDark200ThemeColor font-normal hover:text-SeabiscuitMainThemeColor text-[12px] flex items-center gap-2"
          >
            <span>Clear All</span>
          </button>
        </div>
        <EventSchedulingFormComponentVerticalLine />
        {/*====== OLD TABLE START ======*/}
        {/*<EventFeesTable setAutofill={()=>setAutofillModal(true)}/>*/}
        {/*====== OLD TABLE END ======*/}
      </>
    </EventFormContainerComponent>
  )
}

export default EventFeesFormComponent
