import { useState } from 'react'

// Third party

// Redux
// import _ from 'lodash'
import { Clear } from '@mui/icons-material'

import { MODAL_CONSTS } from '../../../../../const/modal-const'

type Props = {
  show: boolean
  handleModal: any
}

const ProfileLegalClauseMembershipModal = (props: Props) => {
  // Hooks and vars
  const [opentab, setOpenTab] = useState('')

  return (
    <div
      className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-xl outline-none text-current py-10 px-8">
          <span
            onClick={() => props.handleModal(false, MODAL_CONSTS.MEMBERSHIP_ADD)}
            className="absolute text right-2 top-2 text-[#122B46] cursor-pointer"
          >
            {/* <img
                            src="/assets/og_icons/Close.svg"
                            className="w-6"
                            alt="close icon"
                        /> */}
            <Clear
              fontSize={'small'}
              style={{
                color: '#122B46',
                fontWeight: '400',
              }}
            />
          </span>
          <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
            <h5
              className="text-2xl leading-normal text-[#122B46] font-bold pb-2 mb-2 w-full"
              id="exampleModalScrollableLabel"
            >
              Add membership
            </h5>
          </div>

          <div className="w-full min-h-[300px]">
            <p className="text-[#122B46] text-[20px] w-full">Select portal to open</p>
            <div className="w-full grid grid-cols-2 gap-3 ">
              <div
                role="button"
                onClick={() => setOpenTab('already')}
                className={`w-full mx-auto mt-4 flex flex-col py-2 px-4 border rounded-xl shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor
                                        ${opentab === 'already' ? ' focus:border-SeabiscuitMainThemeColor border-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor' : ' border-SeabiscuitGray500ThemeColor text-SeabiscuitLightTextColor'}
                                        `}
              >
                {opentab === 'already' ? (
                  <img
                    src="/assets/cp_icons/Laurel Wreath-4.svg"
                    className="w-[30px]"
                    alt="Laurel"
                  />
                ) : (
                  <img
                    src="/assets/cp_icons/LaurelWreath.svg"
                    className="w-[30px]"
                    alt="LaurelWreath"
                  />
                )}
                <span
                  className={`text-sm font-normal leading-[25px] italic ${opentab === 'already' ? 'text-[#fff]' : ''}`}
                >
                  Already have an AHHS membership
                </span>
                <span
                  className={`text-base font-normal leading-[24px] ${opentab === 'already' ? 'text-[#fff]' : ''}`}
                >
                  Update your profile with the USEF
                </span>
              </div>

              <div
                role="button"
                onClick={() => setOpenTab('add')}
                className={`w-full mx-auto mt-4 flex flex-col py-2 px-4 border  rounded-xl shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2 focus:ring-[#D3DAEE]
                                        ${opentab === 'add' ? ' focus:border-SeabiscuitMainThemeColor border-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor' : ' border-SeabiscuitGray500ThemeColor text-SeabiscuitLightTextColor'}
                                        `}
              >
                {opentab === 'add' ? (
                  <img
                    src="/assets/cp_icons/Hierarchy-4.svg"
                    className="w-[30px]"
                    alt="Hierarchy-4"
                  />
                ) : (
                  <img src="/assets/cp_icons/Hierarchy.svg" className="w-[30px]" alt="Hierarchy" />
                )}

                <span
                  className={`text-sm font-normal leading-[25px] italic ${opentab === 'add' ? 'text-[#fff]' : ''}`}
                >
                  Do not have an AHHS membership
                </span>
                <span
                  className={`text-base font-normal leading-[24px] ${opentab === 'add' ? 'text-[#fff]' : ''}`}
                >
                  Purchase a membership with AHHS
                </span>
              </div>
            </div>
          </div>

          <button
            type="submit"
            onClick={() => props.handleModal(false, MODAL_CONSTS.MEMBERSHIP_ADD)}
            className="w-1/2 h-12 mx-auto mt-10 block items-center justify-center py-2 px-4 border 
                       border-solid  border-SeabiscuitMainThemeColor rounded-xl shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor bg-transparent hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#D3DAEE]"
          >
            OPEN
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProfileLegalClauseMembershipModal
