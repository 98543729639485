import { useEffect, useState } from 'react'

// Components
import NotifivationsListing from './NotifivationsListing'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'

// Constants
import { PAPERWORK_DATA } from './data/tabs'

// Redux
// import { useAppSelector } from "../../store/hooks"
// import { selectUserId } from "../../store/user/userSlice"

// Services
// import FirestoreService from "../../services/firestoreService"

// Firestore
// import { where } from "firebase/firestore"

// Models
// import { EventDraftModel } from "../../models/event-drafts/event-draft.model"
// import { IPaperworkTab } from "../../models/event-drafts/event-draft.interface"
// import fakeDocumentList, { IDocument } from "../../fakeData/fakeDocumentList"
import ViewsLoader from '../../components/loader/ViewsLoader'
import { PillButtonElement } from '../../components/elements/buttons/pill/PillButtonElement'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants

// Types
export type INotifivationListingDoc = {
  message: string
  sender_name: string
  sender_image: string
}

export type INotifivationSettingsDoc = {
  setting_logo: string
  setting_title: string
  setting_description: string
}

// type ICategorizedDocs = {
//     all: IPaperworkListingDoc[],
//     unRead: IPaperworkListingDoc[]
// }

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

/**
 * @TODO todo
 */
const NotificationsRootPage = () => {
  // Hooks and vars
  const [tabs, setTabs] = useState({ ...PAPERWORK_DATA.TABS })
  const [allData, setAllData] = useState<INotifivationListingDoc[]>([])
  const unReadData = useState<INotifivationListingDoc[]>([])[0]
  const [settingsData, setSettingsData] = useState<INotifivationSettingsDoc[]>([])
  const loading = useState(false)[0]

  // Functions

  /**
   * @info Gets the docs from the drafts table in db
   */
  useEffect(() => {
    setAllData(PAPERWORK_DATA?.listing)
    // setUnReadData(PAPERWORK_DATA?.listing2)
    setSettingsData(PAPERWORK_DATA?.list3)
  }, [])

  return (
    <WrapperContainer title="Notifications">
      <>
        <div className="mb-4">
          {tabs.tablist.map((currTab, tabIndex) => {
            return (
              <PillButtonElement
                key={`${currTab}${tabIndex}`}
                text={currTab}
                Class="h-[45px] w-[120px] border-none inline-block"
                onButtonClicked={() => setTabs({ ...tabs, active: tabIndex })}
                value={tabIndex}
                selected={tabIndex === tabs.active}
              />
            )
          })}
        </div>
        {tabs.tablist[tabs.active] === 'Settings' && (
          <div className="mb-2">
            <h1 className="text-SeabiscuitDark200ThemeColor text-[19px] font-bold mb-1">
              Notification settings
            </h1>
            <div className="text-SeabiscuitLightParagraphTextColor capitalize font-normal">
              {tabs.tabDescriptionList[tabs.active]}
            </div>
          </div>
        )}

        <hr className="my-[30px]" />

        {loading ? (
          <ViewsLoader
            className="flex items-center w-full justify-center min-h-[30vh] mt-3"
            size="lg"
            color="#F7074F"
          />
        ) : (
          <NotifivationsListing
            type={tabs.active === 0 ? 'All' : tabs.active === 1 ? 'Unread' : 'Settings'}
            data={tabs.active === 0 ? allData : tabs.active === 1 ? unReadData : settingsData}
          />
        )}
      </>
    </WrapperContainer>
  )
}

export default NotificationsRootPage
