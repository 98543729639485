// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import './EventDetailFormComponent.css'

import { yupResolver } from '@hookform/resolvers/yup'
import { Add, HighlightOff } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { cloneDeep } from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Prompt } from 'react-router-dom'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { Format, Hours, Minutes } from '../../../../fakeData/TimeList'
import MessageHelperComp from '../../../../helpers/MessageHelper'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { EventDetailsModel } from '../../../../models/event-details/event-details.model'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import TogglesElement from '../../../elements/toggles/toggles/TogglesElement'
import ViewsLoader from '../../../loader/ViewsLoader'
import EventFormContainerComponent from '../container/EventFormContainerComponent'
import GlobalInputComponent from './Components/GlobalInputComponent'
import Modal from './Modal'
import dateIcon2 from './assets/date-icon-2.svg'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import { IFilesToRemove } from '../../../../pages/shows/detail/ShowsDetailPageEventDetailFormSubSection'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const eventDetailFormDefaultValues = new EventDetailsModel().toObject()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventDetailFormComponentProps {
  eventId: string
  data: any
  onValid: any
  allowNavigation: boolean
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  fileChangeHandler: any
  event_error: boolean
  loading?: boolean
  handleExitWithoutSave: () => void
  mainEvent?: any
  nextEventTab?: string
  onSetNextEventTab: any
  eventTab?: string
  seFilesToRemove: (value: IFilesToRemove) => void
}

export interface IReactAutoComplete {
  address_components: [
    { long_name: string; short_name: string; types: [string, string] },
    { long_name: string; short_name: string; types: string[] },
    { long_name: string; short_name: string; types: string[] },
    { long_name: string; short_name: string; types: string[] },
  ]
  formatted_address: string
  geometry: {
    location: {
      lat: () => number
      lng: () => number
    }
  }
  place_id: string
  html_attributions: []
}

export interface IPlaceDetails {
  fulladrress: any
  country?: string
  city?: string
  zipCode?: string
  competitionLong?: number
  competitionLat?: number
  state?: string
  addressLine2?: string
  addressLine1?: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 *
 */
const Subheader: React.FC<{ title: string }> = ({ title }) => (
  <>
    {title === 'Event Name ' ? null : <hr className="mt-8 opacity-50"></hr>}
    <p className="text-base font-bold text-SeabiscuitDark200ThemeColor mt-7 mb-2">{title}</p>
  </>
)

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const InputLabel: React.FC<{ title: string }> = ({ title }) => (
  <label htmlFor="liscenseeName" className="text-xs font-semibold text-SeabiscuitDark200ThemeColor">
    {title}
  </label>
)

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 *
 */
const InputContainer: React.FC<{ className?: string; children: any }> = ({
  children,
  className,
}) => <div className={`flex-1 min-w-[150px] ${className ? className : ''}`}>{children}</div>

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 *
 */
const EventDetailFormComponent: React.FC<EventDetailFormComponentProps> = (props) => {
  // Hooks and vars
  const { data } = props
  const [_, set_] = useState(false)
  const removeOnSubmitValidation = useRef(false)
  const handleModalContext = useContext(HandleModalContext)
  const [originalData, setOriginalData] = useState<any>(null)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [imageModalShow, setImageModalShow] = useState(false)

  const eventDetailFormValidationSchema =
    props.data?.category === 'clinic'
      ? EventDetailsModel.validationClinicOtherSchema()
      : EventDetailsModel.validationLicencedSchema()

  const focusRegistrationDate = handleModalContext?.dataToPassOn?.focusRegistrationDate

  const {
    reset,
    watch,
    control,
    trigger,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...eventDetailFormDefaultValues },
    resolver: yupResolver(eventDetailFormValidationSchema),
    mode: 'onChange',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  useEffect(() => {
    /* Watching changes */
    if (unsavedChanges !== !(JSON.stringify(originalData) === JSON.stringify(getValues())))
      setUnsavedChanges(!(JSON.stringify(originalData) === JSON.stringify(getValues())))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch()])

  // Functions

  /** @info Changes a state to cause a rerender */
  const forceRerender = () => {
    set_(!_)
  }

  /**
   * @info Focus the competetion registration date field, if
   * @param focusRegistrationDate is true
   * @param focusRegistrationDate was set from event publish modal
   *  */
  const forceValidate = () => {
    if (focusRegistrationDate) {
      removeOnSubmitValidation.current = true
      let dataObj = cloneDeep(handleModalContext.dataToPassOn)
      delete dataObj['focusRegistrationDate']
      handleModalContext.setDataToPassOn(dataObj)
      forceRerender()
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  /**
   * @param place Details got from React auto complete library
   * @info Sets from values according to the details got from third party lib
   */
  const handleLocation = (place: IReactAutoComplete | React.ChangeEvent<HTMLInputElement>) => {
    if (!!!place) {
      reset({
        ...getValues(),
        competitionAddressLine2: '',
        competitionCity: '',
        competitionState: '',
        competitionCountry: '',
        competitionZipCode: '',
        competitionAddressLine1: '',
        competitionLat: '',
        competitionLong: '',
      })
    }
    if (typeof ((place ?? {}) as any)?.target?.value === 'string') {
      return setValue('competitionAddressLine1', (place as any).target.value)
    }

    if (typeof place === 'object') {
      let data = place as any as IReactAutoComplete
      let location: IPlaceDetails = data?.address_components?.reduce(
        (accumulator: any, currAddComponent) => {
          switch (true) {
            case currAddComponent?.types.includes('country'):
              accumulator.country = currAddComponent?.long_name
              break
            case currAddComponent?.types.includes('locality'):
              accumulator.city = currAddComponent?.long_name
              break
            case currAddComponent?.types.includes('administrative_area_level_1'):
              accumulator.state = currAddComponent?.long_name
              break
            case currAddComponent?.types.includes('route'):
              accumulator.addressLine1 = currAddComponent?.long_name
              break
            case currAddComponent?.types.includes('postal_code'):
              accumulator.zipCode = currAddComponent?.long_name
              break
          }
          return accumulator
        },
        {}
      )
      if (location) {
        location.competitionLat = data?.geometry?.location?.lat()
        location.competitionLong = data?.geometry?.location?.lng()
        location.fulladrress = (place as any)?.formatted_address
      }
      setValue(
        'competitionAddressLine1',
        (location as any)?.addressLine1 ? (location as any)?.addressLine1 : location?.fulladrress
      )

      reset({
        ...getValues(),
        competitionAddressLine2: location?.addressLine2 ?? 'N/A',
        competitionCity: location?.city ?? 'N/A',
        competitionState: location?.state ?? 'N/A',
        competitionCountry: location?.country ?? 'N/A',
        competitionZipCode: location?.zipCode,
        competitionAddressLine1: location?.addressLine1
          ? location?.addressLine1
          : location?.fulladrress,
        competitionLat: `${location?.competitionLat ?? 'N/A'}`,
        competitionLong: `${location?.competitionLong ?? 'N/A'}`,
      })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @type 1 => eventCardCoverImageUrl, 2 => competitionEventImage, 3 => competitionCourseMapImages, 4 => competitionVideo
   */
  function handleFile(type: '1' | '2' | '3' | '4', url?: string) {
    props.fileChangeHandler(type, url)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (props.data && Object.keys(props.data).length) {
      /* For the first time, setting data as it is, to react hook form */
      if (!originalData) {
        let dataAll = props.data
        reset(dataAll)
        setOriginalData(props.data)
      } else {
        /* For rest of the times, prevent curr state, while overwriting the given ones */
        reset({
          ...getValues(),
          competitionName: props?.data?.competitionName,
          competitionEventImage: props?.data?.competitionEventImage,
          competitionCourseMapImages: props?.data?.competitionCourseMapImages,
          competitionEventVideo: props?.data?.competitionEventVideo,
          eventCardCoverImageUrl: props?.data?.eventCardCoverImageUrl,
        })
      }
    } else {
      reset(eventDetailFormDefaultValues)
    }

    trigger().then()
    forceValidate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, reset, trigger])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /** Prevents reload on unsaved changes */
  useEffect(() => {
    const beforeUnloadCallback = (event: any) => {
      if (unsavedChanges) {
        event.preventDefault()
        event.returnValue = MESSAGES_CONST.YOU_MAY_HAVE_UNSAVED_CHANGES
        return MESSAGES_CONST.YOU_MAY_HAVE_UNSAVED_CHANGES
      }
    }

    if (unsavedChanges === true) window.addEventListener('beforeunload', beforeUnloadCallback)
    else window.removeEventListener('beforeunload', beforeUnloadCallback)

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback)
    }
  }, [unsavedChanges])

  useEffect(() => {
    return () => {
      props.handleExitWithoutSave()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <EventFormContainerComponent>
      {props.loading ? (
        <div className="flex justify-center items-center min-h-[200px]">
          <ViewsLoader size="xl" color="red" />
        </div>
      ) : (
        <>
          <Prompt
            when={unsavedChanges && !props.allowNavigation}
            message={MESSAGES_CONST.YOU_MAY_HAVE_UNSAVED_CHANGES}
          />

          <EventFormHeaderComponent title="Event Details">
            <EventFormFooterCommonComponent
              nextEventTab={props.nextEventTab}
              eventTab={props.eventTab}
              onNext={(e, publishEvent) => {
                props.seFilesToRemove({
                  filesAddedButNotSaved: [],
                  filesRemovedButNotSaved: [],
                })
                handleSubmit(
                  (data) =>
                    publishEvent({
                      dataToSave: data,
                      tabName: 'EventDetails',
                      validFormHandler: props.onValid,
                    }),
                  props.onInvalid
                )(e)
              }}
              onSaveAndExit={(e, publishEvent) =>
                handleSubmit(
                  (data) =>
                    publishEvent({
                      dataToSave: data,
                      tabName: 'EventDetails',
                      publish: false,
                      validFormHandler: props.onValidAnExit,
                    }),
                  props.onInvalidAndExit
                )(e)
              }
            />
          </EventFormHeaderComponent>

          {/*<Subheader title="LICENSEE DETAILS" />*/}
          {/*<div className="flex gap-2 flex-wrap">*/}
          {/*    <InputContainer className="max-w-[100%] md:min-w-[300px] md:max-w-[300px]">*/}
          {/*        <InputLabel title="Licensee Name" />*/}
          {/*        <GlobalInputComponent type="text"*/}
          {/*                              icon="/assets/og_icons/User-4.svg"*/}
          {/*                              placeholder="Jennifer Tankel"*/}
          {/*                              register={register}*/}
          {/*                              inputName="licenseeName"*/}
          {/*                              />*/}
          {/*        {errors.licenseeName && (*/}
          {/*            <ErrorBox error={errors.licenseeName?.message} />*/}
          {/*        )}*/}
          {/*    </InputContainer>*/}
          {/*    <InputContainer className="w-full md:min-w-[250px] md:max-w-[250px]">*/}
          {/*        <InputLabel title="Licensee ID" />*/}
          {/*        <GlobalInputComponent type="text"*/}
          {/*                              icon={licenseIcon}*/}
          {/*                              placeholder="10909"*/}
          {/*                              register={register}*/}
          {/*                              inputName="licenseeId"*/}
          {/*        />*/}
          {/*        {errors.licenseeId && (*/}
          {/*            <ErrorBox error={errors.licenseeId?.message} />*/}
          {/*        )}*/}
          {/*    </InputContainer>*/}
          {/*</div>*/}
          <Subheader title="Event Name " />

          <div className="flex gap-2 flex-wrap flex-col">
            <InputContainer className="md:min-w-[300px]">
              {/*<InputLabel title="Name" />*/}
              <div className="max-w-[350px]">
                <GlobalInputComponent
                  type="text"
                  placeholder="WEF12 - WCHR / Rolex"
                  register={register}
                  inputName="competitionName"
                />
                {props.event_error &&
                  (errors as any)?.competitionName?.message &&
                  typeof (errors as any)?.competitionName?.message === 'string' && (
                    <MessageHelperComp
                      isError={true}
                      message={(errors.competitionName as any).message}
                    />
                  )}
              </div>
            </InputContainer>

            <div>
              <InputLabel title="Gov. Body Comp ID Number" />
              <InputContainer className="md:min-w-[300px]">
                <div className="max-w-[350px]">
                  <GlobalInputComponent
                    type="text"
                    placeholder="Gov. Body Comp ID Number"
                    register={register}
                    inputName="bodyCompId"
                  />
                  {props.event_error &&
                    (errors as any)?.competitionName?.message &&
                    typeof (errors as any)?.competitionName?.message === 'string' && (
                      <MessageHelperComp
                        isError={true}
                        message={(errors.competitionName as any).message}
                      />
                    )}
                </div>
              </InputContainer>
            </div>
            {/*<InputContainer className="min-w-[150px] max-w-[150px]"> */}
            {/*    <InputLabel title="Comp. ID" />*/}
            {/*    <GlobalInputComponent type="text"*/}
            {/*                          icon={licenseIcon}*/}
            {/*                          placeholder="90809"*/}
            {/*                          register={register}*/}
            {/*                          inputName="competitionId"*/}
            {/*    />*/}
            {/*</InputContainer>*/}
            {/*<InputContainer>*/}
            {/*    <InputLabel title="Comp. Year" />*/}
            {/*    <GlobalInputComponent type="text"*/}
            {/*                          icon={dateIcon}*/}
            {/*                          placeholder="2023"*/}
            {/*                          register={register}*/}
            {/*                          inputName="competitionYear"*/}
            {/*    />*/}
            {/*</InputContainer>*/}
            {/*<InputContainer>*/}
            {/*    <InputLabel title="Overall Comp. Rating" />*/}
            {/*    <GlobalInputComponent type="text"*/}
            {/*                          icon={rateIcon}*/}
            {/*                          placeholder="AA"*/}
            {/*                          register={register}*/}
            {/*                          inputName="competitionOverallRating"*/}
            {/*    />*/}
            {/*</InputContainer>*/}
            {/*<InputContainer>*/}
            {/*    <InputLabel title="Zone/Region/Area" />*/}
            {/*    <GlobalInputComponent type="text"*/}
            {/*                          icon={zoneIcon}*/}
            {/*                          placeholder="7"*/}
            {/*                          register={register}*/}
            {/*                          inputName="competitionZone"*/}
            {/*    />*/}
            {/*</InputContainer>*/}
            {/*<InputContainer>*/}
            {/*    <InputLabel title="Comp. Phone" />*/}
            {/*    <GlobalInputComponent type="text"*/}
            {/*                          icon={zoneIcon}*/}
            {/*                          placeholder="437-909-2435"*/}
            {/*                          register={register}*/}
            {/*                          inputName="competitionPhone"*/}
            {/*    />*/}
            {/*</InputContainer>*/}
          </div>

          <Subheader title="Date And Location" />

          <div className="">
            <div className="flex flex-col md:flex-row gap-2">
              <InputContainer className="w-full md:min-w-[250px] md:max-w-[250px]">
                <InputLabel title="Start Date" />
                <GlobalInputComponent
                  type="date"
                  minDate={new Date()}
                  placeholder="Start Date"
                  register={register}
                  control={control}
                  inputName="competitionStartDate"
                />

                {props.event_error &&
                  (errors as any)?.competitionStartDate?.message &&
                  typeof (errors as any)?.competitionStartDate?.message === 'string' && (
                    <MessageHelperComp
                      isError={true}
                      focusOnError={true}
                      message={(errors.competitionStartDate as any).message}
                    />
                  )}
              </InputContainer>
              <InputContainer className="w-full md:min-w-[250px] md:max-w-[250px] mb-3 md:mb-0">
                <InputLabel title="End Date" />
                <GlobalInputComponent
                  type="date"
                  minDate={new Date(watch('competitionStartDate') as any)}
                  placeholder="End Date"
                  register={register}
                  control={control}
                  inputName="competitionEndDate"
                />
                {props.event_error &&
                  (errors as any)?.competitionEndDate?.message &&
                  typeof (errors as any)?.competitionEndDate?.message === 'string' && (
                    <MessageHelperComp
                      isError={true}
                      focusOnError={true}
                      message={(errors.competitionEndDate as any).message}
                    />
                  )}
              </InputContainer>
            </div>
            <div className="flex flex-col md:flex-row gap-2">
              <InputContainer className="w-full md:min-w-[250px] md:max-w-[250px]">
                <InputLabel title="Address" />
                <GlobalInputComponent
                  type="location"
                  control={control}
                  placeholder="Address"
                  onChange={handleLocation}
                  inputName="competitionAddressLine1"
                  register={register}
                />
                {props.event_error &&
                  (errors as any)?.competitionAddressLine1?.message &&
                  typeof (errors as any)?.competitionAddressLine1?.message === 'string' && (
                    <MessageHelperComp
                      isError={true}
                      message={(errors.competitionAddressLine1 as any).message}
                    />
                  )}
              </InputContainer>
              {/* <InputContainer className="w-full md:min-w-[250px] md:max-w-[250px]">
                                <InputLabel title="Address Line 2" />
                                <GlobalInputComponent type="text"
                                    // icon={locationIcon}
                                    placeholder="Address Line 2"
                                    register={register}
                                    inputName="competitionAddressLine2"
                                />
                            </InputContainer>*/}
              <InputContainer className="w-full md:min-w-[250px] md:max-w-[250px]">
                <InputLabel title="City" />
                <GlobalInputComponent
                  type="text"
                  placeholder="Middleburg"
                  register={register}
                  inputName="competitionCity"
                />
                {props.event_error &&
                  (errors as any)?.competitionCity?.message &&
                  typeof (errors as any)?.competitionCity?.message === 'string' && (
                    <MessageHelperComp
                      isError={true}
                      message={(errors.competitionCity as any).message}
                    />
                  )}
              </InputContainer>
              <InputContainer className="w-full md:min-w-[250px] md:max-w-[250px]">
                <InputLabel title="State" />
                <GlobalInputComponent
                  type="text"
                  placeholder="VA"
                  register={register}
                  inputName="competitionState"
                />
                {props.event_error &&
                  (errors as any)?.competitionState?.message &&
                  typeof (errors as any)?.competitionState?.message === 'string' && (
                    <MessageHelperComp
                      isError={true}
                      message={(errors.competitionState as any).message}
                    />
                  )}
              </InputContainer>
              <InputContainer className="w-full md:min-w-[250px] md:max-w-[250px]">
                <InputLabel title="Zip Code" />
                <GlobalInputComponent
                  type="text"
                  placeholder="20117"
                  register={register}
                  inputName="competitionZipCode"
                />
                {props.event_error &&
                  (errors as any)?.competitionZipCode?.message &&
                  typeof (errors as any)?.competitionZipCode?.message === 'string' && (
                    <MessageHelperComp
                      isError={true}
                      message={(errors.competitionZipCode as any).message}
                    />
                  )}
              </InputContainer>
              <InputContainer className="w-full md:min-w-[250px] md:max-w-[250px]">
                <InputLabel title="Country" />
                <div className="max-w-[350px]">
                  <GlobalInputComponent
                    type="text"
                    placeholder="United States"
                    register={register}
                    inputName="competitionCountry"
                  />
                </div>
                {props.event_error &&
                  (errors as any)?.competitionCountry?.message &&
                  typeof (errors as any)?.competitionCountry?.message === 'string' && (
                    <MessageHelperComp
                      isError={true}
                      message={(errors.competitionCountry as any).message}
                    />
                  )}
              </InputContainer>
            </div>
          </div>
          <div>
            <div className="flex items-center mt-3">
              <InputLabel title="Directions" />
              <Tooltip
                title={<h1 className="tooltip_title">{`Add directions if GPS unreliable`}</h1>}
                placement="top"
                arrow
              >
                <button className="">
                  <TooltipIcon color="#122B46" />
                  {/* <img src={hintIcon} alt="icon" /> */}
                </button>
              </Tooltip>
            </div>
            <GlobalInputComponent
              type="textarea"
              placeholder=""
              register={register}
              inputName="competitionLocationNotes"
            />
          </div>

          <Subheader title="Point Of Contact" />

          <div className="flex flex-col md:flex-row gap-2 flex-wrap">
            {/*<InputContainer className="w-full md:min-w-[220px] md:max-w-[220px]">
                                <InputLabel title="POC ID" />
                                <GlobalInputComponent
                                    type="text"
                                    maxLength="6"
                                    // icon={licenseIcon}
                                    placeholder="98089"
                                    register={register}
                                    inputName="competitionPOCId"
                                />
                                {props.event_error && (errors as any)?.competitionPOCId?.message && typeof (errors as any)?.competitionPOCId?.message === "string" && <MessageHelperComp isError={true} message={(errors.competitionPOCId as any).message} />}
                            </InputContainer> */}
            <InputContainer className="w-full md:min-w-[220px] md:max-w-[220px]">
              <InputLabel title="POC Name" />
              <GlobalInputComponent
                type="text"
                // icon="/assets/og_icons/User-4.svg"
                placeholder="Name"
                register={register}
                inputName="competitionPOCName"
              />
              {props.event_error &&
                (errors as any)?.competitionPOCName?.message &&
                typeof (errors as any)?.competitionPOCName?.message === 'string' && (
                  <MessageHelperComp
                    isError={true}
                    message={(errors.competitionPOCName as any).message}
                  />
                )}
            </InputContainer>
            <InputContainer className="w-full md:min-w-[220px] md:max-w-[220px]">
              <InputLabel title="POC Phone" />
              <GlobalInputComponent
                type="text"
                // icon={phoneIcon}
                onChange={(e) => {
                  let val = e?.target?.value
                  if (val) e.target.value = val.replaceAll(/([^0-9])/gi, '')
                  if (val && val.length > 12) e.target.value = val.substring(0, 12)
                }}
                placeholder="123-456-7890"
                register={register}
                inputName="competitionPOCPhone"
              />
              {props.event_error &&
                (errors as any)?.competitionPOCPhone?.message &&
                typeof (errors as any)?.competitionPOCPhone?.message === 'string' && (
                  <MessageHelperComp
                    isError={true}
                    message={(errors.competitionPOCPhone as any).message}
                  />
                )}
            </InputContainer>
            <InputContainer className="min-w-[300px] max-w-[300px]">
              <InputLabel title="POC Email" />
              <GlobalInputComponent
                type="text"
                // icon="/assets/og_icons/Mail-4.svg"
                placeholder="johndoe@email.com"
                register={register}
                inputName="competitionPOCEmail"
              />
              {props.event_error &&
                (errors as any)?.competitionPOCEmail?.message &&
                typeof (errors as any)?.competitionPOCEmail?.message === 'string' && (
                  <MessageHelperComp
                    isError={true}
                    message={(errors.competitionPOCEmail as any).message}
                  />
                )}
            </InputContainer>
          </div>

          <Subheader title="Registrations" />

          <div className="flex gap-2 flex-wrap">
            <InputContainer className="w-full md:min-w-[220px] md:max-w-[220px]">
              <InputLabel title="Registrations Open" />

              <GlobalInputComponent
                type="date"
                icon={dateIcon2}
                placeholder="Open Date"
                control={control}
                register={register}
                minDate={new Date()}
                inputName="competitionRegistrationOpenDate"
                className="h-[51px]"
              />

              {(props.event_error || removeOnSubmitValidation.current) &&
                (errors as any)?.competitionRegistrationOpenDate?.message &&
                typeof (errors as any)?.competitionRegistrationOpenDate?.message === 'string' && (
                  <MessageHelperComp
                    isError={true}
                    focusOnError={true}
                    message={(errors.competitionRegistrationOpenDate as any).message}
                  />
                )}
            </InputContainer>

            <InputContainer className="min-w-[200px] max-w-[200px]">
              <InputLabel title="Time" />
              <div className="flex items-center selectTime">
                <GlobalInputComponent
                  className="flex-1 h-[51px] flex items-center border-0 outline-0 text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-SeabiscuitGrayThemeColor border-r border-r-SeabiscuitWhiteThemeColor !rounded-l-xl !rounded-r-none"
                  placeholder="00"
                  type="select"
                  value={Hours}
                  register={register}
                  control={control}
                  isTimeSelector={true}
                  isdisableInput={true}
                  inputName="competitionRegistrationOpenTime.hour"
                />

                <GlobalInputComponent
                  className="flex-1 border-0 h-[51px] flex items-center outline-0 text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-SeabiscuitGrayThemeColor!rounded-none border-r border-r-SeabiscuitWhiteThemeColor !rounded-none"
                  placeholder="00"
                  type="select"
                  value={Minutes}
                  isTimeSelector={true}
                  isdisableInput={true}
                  register={register}
                  control={control}
                  inputName="competitionRegistrationOpenTime.minutes"
                />

                <GlobalInputComponent
                  placeholder="AM"
                  className="flex-1 border-0 outline-0 h-[51px] flex items-center text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-SeabiscuitGrayThemeColor !rounded-r-xl !rounded-l-none"
                  type="select"
                  isdisableInput={true}
                  isTimeSelector={true}
                  value={Format}
                  register={register}
                  control={control}
                  inputName="competitionRegistrationOpenTime.format"
                />
              </div>

              {(errors as any)?.competitionRegistrationOpenTime?.message &&
                typeof (errors as any)?.competitionRegistrationOpenTime?.message === 'string' && (
                  <MessageHelperComp
                    isError={true}
                    message={(errors.competitionRegistrationOpenTime as any).message}
                  />
                )}
            </InputContainer>

            <InputContainer className="w-full md:min-w-[220px] md:max-w-[220px]">
              <InputLabel title="Registrations Close" />
              <GlobalInputComponent
                icon={dateIcon2}
                placeholder="Close Date"
                type="date"
                minDate={new Date()}
                register={register}
                control={control}
                inputName="competitionRegistrationCloseDate"
                className="h-[51px]"
              />

              {props.event_error &&
                (errors as any)?.competitionRegistrationCloseDate?.message &&
                typeof (errors as any)?.competitionRegistrationCloseDate?.message === 'string' && (
                  <MessageHelperComp
                    isError={true}
                    focusOnError={true}
                    message={(errors.competitionRegistrationCloseDate as any).message}
                  />
                )}
            </InputContainer>

            <InputContainer className="min-w-[200px] max-w-[200px]">
              <InputLabel title="Time" />
              <div className="flex items-center selectTime">
                <GlobalInputComponent
                  className="border-r flex items-center border-r-SeabiscuitWhiteThemeColor h-[51px] !rounded-l-xl !rounded-r-none"
                  placeholder="00"
                  isdisableInput={true}
                  type="select"
                  value={Hours}
                  register={register}
                  isTimeSelector={true}
                  control={control}
                  inputName="competitionRegistrationCloseTime.hour"
                />

                <GlobalInputComponent
                  className="border-r border-r-SeabiscuitWhiteThemeColor h-[51px] flex items-center !rounded-none"
                  placeholder="00"
                  isdisableInput={true}
                  type="select"
                  value={Minutes}
                  isTimeSelector={true}
                  register={register}
                  control={control}
                  inputName="competitionRegistrationCloseTime.minutes"
                />

                <GlobalInputComponent
                  className="flex-1 border-0 outline-0 text-gray-500 w-full flex items-center justify-center focus:ring-0 h-[51px] focus:border-0 focus:outline-0 bg-SeabiscuitGrayThemeColor !rounded-r-xl !rounded-l-none"
                  placeholder="AM"
                  type="select"
                  isTimeSelector={true}
                  isdisableInput={true}
                  value={[
                    { label: 'AM', value: 'AM' },
                    { label: 'PM', value: 'PM' },
                  ]}
                  register={register}
                  control={control}
                  inputName="competitionRegistrationCloseTime.format"
                />
              </div>
              {props.event_error &&
                (errors as any)?.competitionRegistrationCloseTime?.message &&
                typeof (errors as any)?.competitionRegistrationCloseTime?.message === 'string' && (
                  <MessageHelperComp
                    isError={true}
                    message={(errors.competitionRegistrationCloseTime as any).message}
                  />
                )}
            </InputContainer>

            <InputContainer className="!min-w-[120px] max-w-[120px] toggleButton">
              <InputLabel title="Waitlist" />
              <Tooltip
                title={
                  <h1 className="tooltip_title">
                    Allow your event to accept wait list registrations{' '}
                  </h1>
                }
                placement="top"
                arrow
              >
                <button className="mt-[3px]">
                  <TooltipIcon color="#122B46" className="!mb-[-2px]" />
                </button>
              </Tooltip>
              <Controller
                name={`waitlist`}
                control={control}
                render={({ field: { value } }) => {
                  return (
                    <TogglesElement
                      on={value}
                      onToggle={(value: any) => setValue(`waitlist`, !value)}
                    />
                  )
                }}
              />
            </InputContainer>
          </div>

          <Subheader title="Media" />

          <div className="mediaUploadContainer flex flex-col md:flex-row flex-wrap">
            {/* Banner */}
            <div>
              <InputLabel title="Banner" />
              <div className="image-upload-preview-container flex bg-SeabiscuitGrayThemeColor p-2 rounded-xl w-full md:w-fit">
                <div className="flex items-center flex-wrap">
                  {data?.eventCardCoverImageUrl ? (
                    <div className="imageThumbnail m-1">
                      <div className="image-preview relative rounded-md w-[40px] h-[40px] overflow-hidden z-[0]">
                        <img
                          src={data.eventCardCoverImageUrl}
                          alt={data.eventCardCoverImageUrl ?? ''}
                          className="image shrink-0 object-cover w-full h-full"
                        />
                        <button
                          type="button"
                          onClick={() => handleFile('1', data.eventCardCoverImageUrl)}
                          className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer hover:opacity-100 z-[1] transition-all"
                        >
                          <HighlightOff className="text-SeabiscuitMainThemeColor" />
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>

                {!data?.eventCardCoverImageUrl?.length ? (
                  <div
                    className="relative w-full md:w-[200px] h-[26px] flex items-center justify-center m-1 cursor-pointer"
                    onClick={() => handleFile('1')}
                  >
                    <div className="text-[#8590a1] text-[14px]">Add banner</div>
                    <Add
                      fontSize="medium"
                      className="absolute top-[-18px] right-[-18px] cursor-pointer border border-SeabiscuitDark200ThemeColor rounded-full text-SeabiscuitDark200ThemeColor bg-SeabiscuitWhiteThemeColor"
                    />
                  </div>
                ) : null}
              </div>
            </div>
            {/* Banner */}

            {/* Event Images */}
            <div>
              <InputLabel title="Event Images" />
              <div className="image-upload-preview-container flex bg-SeabiscuitGrayThemeColor p-2 rounded-xl w-full items-center">
                <div className="flex items-center max-w-[300px] flex-wrap">
                  {data?.competitionEventImage?.length
                    ? data?.competitionEventImage?.map((currFile: string, index: number) => {
                        if (currFile === '') return null
                        return (
                          <div className="imageThumbnail m-1" key={`${currFile}${index}`}>
                            <div className="image-preview relative rounded-md w-[40px] h-[40px] overflow-hidden z-[0]">
                              <img
                                src={currFile}
                                alt={currFile ?? ''}
                                className="image shrink-0 object-cover w-full h-full"
                              />
                              <button
                                type="button"
                                onClick={() => handleFile('2', currFile)}
                                className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer hover:opacity-100 z-[1] transition-all"
                              >
                                <HighlightOff className="text-SeabiscuitMainThemeColor" />
                              </button>
                            </div>
                          </div>
                        )
                      })
                    : null}
                </div>
                <div
                  className="relative w-full md:w-[200px] h-[26px] flex items-center justify-center m-1 cursor-pointer"
                  onClick={() => handleFile('2')}
                >
                  <div className="text-[#8590a1] text-[14px]">Add images</div>
                  <Add
                    fontSize="medium"
                    className=" absolute top-[-18px] right-[-18px] cursor-pointer border border-SeabiscuitDark200ThemeColor rounded-full text-SeabiscuitDark200ThemeColor bg-SeabiscuitWhiteThemeColor"
                  />
                </div>
              </div>
            </div>
            {/* Event Images */}

            <div>
              <InputLabel title="Event Video" />
              <div className="image-upload-preview-container flex bg-SeabiscuitGrayThemeColor p-2 rounded-xl w-full items-center">
                <div className="flex items-center flex-wrap">
                  {data?.competitionEventVideo?.length
                    ? data?.competitionEventVideo?.map((currUrl: string, index: number) => {
                        if (currUrl === '') return null
                        return (
                          <div className="imageThumbnail m-1" key={`${currUrl}${index}`}>
                            <div className="image-preview relative rounded-md w-[40px] h-[40px] overflow-hidden z-[0]">
                              <video
                                src={currUrl}
                                className="image shrink-0 object-cover w-full h-full"
                              />
                              <button
                                type="button"
                                onClick={() => handleFile('4', currUrl)}
                                className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer hover:opacity-100 z-[1] transition-all"
                              >
                                <HighlightOff className="text-SeabiscuitMainThemeColor" />
                              </button>
                            </div>
                          </div>
                        )
                      })
                    : null}
                </div>
                <div
                  className="relative w-full md:w-[200px] h-[26px] flex items-center justify-center m-1 cursor-pointer"
                  onClick={() => handleFile('4')}
                >
                  <div className="text-[#8590a1] text-[14px]">Add video</div>
                  <Add
                    fontSize="medium"
                    className=" absolute top-[-18px] right-[-18px] cursor-pointer border border-SeabiscuitDark200ThemeColor rounded-full text-SeabiscuitDark200ThemeColor bg-SeabiscuitWhiteThemeColor"
                  />
                </div>
              </div>
            </div>

            {/* Course map */}
            <div>
              <div className="flex mt-[4px] mb-[4px]">
                <InputLabel title="Site Map" />
                <Tooltip
                  title={
                    <h1 className="tooltip_title">
                      {
                        'Switch to ‘Draft’ to let riders know the course design is still being confirmed'
                      }
                    </h1>
                  }
                  placement="top"
                  arrow
                >
                  <button className="">
                    <TooltipIcon color="#122B46" />
                    {/* <img src={hintIcon} alt="icon" /> */}
                  </button>
                </Tooltip>
              </div>
              <div className="image-upload-preview-container flex bg-SeabiscuitGrayThemeColor p-2 rounded-xl w-full items-center">
                <div className="flex items-center flex-wrap">
                  {data?.competitionCourseMapImages?.length
                    ? data?.competitionCourseMapImages?.map((currFile: string, index: number) => {
                        if (currFile === '') return null
                        return (
                          <div className="imageThumbnail m-1" key={`${currFile}${index}`}>
                            <div className="image-preview relative rounded-md w-[40px] h-[40px] overflow-hidden z-[0]">
                              <img
                                src={currFile}
                                alt={currFile ?? ''}
                                className="image shrink-0 object-cover w-full h-full"
                              />
                              <button
                                type="button"
                                onClick={() => handleFile('3', currFile)}
                                className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer hover:opacity-100 z-[1] transition-all"
                              >
                                <HighlightOff className="text-SeabiscuitMainThemeColor" />
                              </button>
                            </div>
                          </div>
                        )
                      })
                    : null}
                </div>
                <div
                  className="relative w-full md:w-[200px] h-[26px] flex items-center justify-center m-1 cursor-pointer"
                  onClick={() => handleFile('3')}
                >
                  <div className="text-[#8590a1] text-[14px]">Add site map</div>
                  <Add
                    fontSize="medium"
                    className=" absolute top-[-18px] right-[-18px] cursor-pointer border border-SeabiscuitDark200ThemeColor rounded-full text-SeabiscuitDark200ThemeColor bg-SeabiscuitWhiteThemeColor"
                  />
                </div>
              </div>
            </div>
            {/* Course map */}

            <InputContainer className="w-28">
              <InputLabel title="Site Map Status" />
              <div className="courseMapBtn">
                <Controller
                  name={`competitionCourseMapStatus`}
                  control={control}
                  render={({ field: { value } }) => (
                    <TogglesElement
                      on={value}
                      checkedMessage="Live"
                      uncheckedMessage="Draft"
                      onToggle={(value: any) => setValue(`competitionCourseMapStatus`, !value)}
                    />
                  )}
                />
              </div>
            </InputContainer>
          </div>
          {/*<Subheader title="IMAGES" />*/}
          {/*<InputLabel title="Event images" />*/}
          {/*<MediaContainer*/}
          {/*    images={images}*/}
          {/*    onAdd={() => setImageModalShow(true)}*/}
          {/*/>*/}

          {/*<Subheader title="VIDEO" />*/}
          {/*<InputLabel title="Event video" />*/}
          {/*<MediaContainer images={images} onAdd={() => {}} />*/}

          {/*<Subheader title="COURSE MAP" />*/}
          {/*<InputLabel title="Course map" />*/}
          {/*<div className="flex gap-2 items-center">*/}
          {/*    <MediaContainer images={images} onAdd={() => {}} />*/}
          {/*    <SwitchElementUpdated*/}
          {/*        on={false}*/}
          {/*        onSwitch={() => {}}*/}
          {/*        onText="Draft"*/}
          {/*        offText="Draft"*/}
          {/*    />*/}
          {/*</div>*/}

          {/*<Subheader title="IMAGES" />*/}
          {/*<InputLabel title="Event images" />*/}
          {/*<MediaContainer*/}
          {/*    images={images}*/}
          {/*    onAdd={() => setImageModalShow(true)}*/}
          {/*/>*/}

          {/*<Subheader title="VIDEO" />*/}
          {/*<InputLabel title="Event video" />*/}
          {/*<MediaContainer images={images} onAdd={() => {}} />*/}

          {/*<Subheader title="COURSE MAP" />*/}
          {/*<InputLabel title="Course map" />*/}
          {/*<div className="flex gap-2 items-center">*/}
          {/*    <MediaContainer images={images} onAdd={() => {}} />*/}
          {/*    <SwitchElementUpdated*/}
          {/*        on={false}*/}
          {/*        onSwitch={() => {}}*/}
          {/*        onText="Draft"*/}
          {/*        offText="Draft"*/}
          {/*    />*/}
          {/*</div>*/}

          <Subheader title="About" />
          <div className="flex items-center my-2">
            <InputLabel title="Event summary" />
            <Tooltip
              title={<h1 className="tooltip_title">{`Enter detailed notes about the event`}</h1>}
              placement="top"
              arrow
            >
              <button className="">
                <TooltipIcon color="#122B46" />
                {/* <img src={hintIcon} alt="icon" /> */}
              </button>
            </Tooltip>
          </div>

          <div
            className={`text-gray-500 flex items-center flex-1 gap-2 w-full py-2 rounded-xl bg-SeabiscuitGrayThemeColor`}
          >
            <textarea
              className="flex-1 text-gray-600 resize-y min-h-[200px] bg-transparent w-full ring-0 border-0 outline-0 focus:ring-0"
              placeholder=""
              {...register('summaryLong')}
            />
          </div>

          <div className="flex items-center my-3">
            <InputLabel title="Event Bi-Line" />
            <Tooltip
              title={<h1 className="tooltip_title">{`Enter a short summary of the event`}</h1>}
              placement="top"
              arrow
            >
              <button className="">
                <TooltipIcon color="#122B46" />
                {/* <img src={hintIcon} alt="icon" /> */}
              </button>
            </Tooltip>
          </div>
          <GlobalInputComponent
            type="text"
            placeholder=""
            register={register}
            inputName="summaryShort"
          />

          <Modal show={imageModalShow} onHide={() => setImageModalShow(false)} />
        </>
      )}
    </EventFormContainerComponent>
  )
}

export default EventDetailFormComponent
