import clsx from 'clsx'

// ############################################################
/**
 * @todo Document this
 */
// ############################################################

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

/**
 * @todo Document this
 */
const RedTitle: React.FC<{ text: string; className?: string; id?: string }> = ({
  text,
  className,
  id = undefined,
}) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <h2
      className={clsx(
        'text-SeabiscuitMainThemeColor text-[20px] 2xl:text-[23px] font-bold',
        className
      )}
      {...(id && { id })}
    >
      {text}
    </h2>
  )
}
export { RedTitle }
