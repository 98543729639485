// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
// Image imports
import Tickets from './assets/RegisterBox/Two Tickets-2.svg'
import CloseDate from './assets/RegisterBox/close-date.svg'
import License from './assets/RegisterBox/license.svg'
import Location from './assets/RegisterBox/location.svg'
import Map from './assets/RegisterBox/map.svg'
import Organizer from './assets/RegisterBox/organizer.png'

// Third party
import moment from 'moment'

// Component imports
import LightboxCommonComponent from '../../../common/dialogs/full-screen/LightboxCommonComponent'

// Constants
import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'

import { IEventReviewPublish } from '../../../../models/event-review-publish/event-review-publish.interface'
import { ILightboxProps } from '../../../../types/competitor_types'

// Helpers
import clsx from 'clsx'
import helpers, { splitFileNameAndExt } from '../../../../commonHelpers/helpers'
import { IMAGE_CONSTS } from '../../../../const/image-const'
import { IHandleModal } from '../../../../layout/mainlayout/MainLayout'
import { IEventInterface } from '../../../../models/events/event.interface'
import { EventModel } from '../../../../models/events/event.model'
import FirestoreService from '../../../../services/firestoreService'
import { useAppSelector } from '../../../../store/hooks'
import { selectedEvent, selectEvents } from '../../../../store/events/eventsSlice'
import { TEventRegisteredUsers } from '../../../../models/event-registered-users/event-registered-users.interface'
import { where } from 'firebase/firestore'
import { getConvertedData } from '../../../../models/interface.helper'
import { EventRegisteredUsersModel } from '../../../../models/event-registered-users/event-registered-users.model'
import { UserModel } from '../../../../models/users/user.model'
import { IUserInterface } from '../../../../models/users/user.interface'

type IRegisterBoxRow = {
  icon: any
  header: string
  desc: string | React.ReactNode
  viewLink?: string
  jumpTo?: string
  handleLightBox?: (props: ILightboxProps) => void
  competitionCourseMapImages?: any[]
  className?: string
  handleModal?: any
}

const DUMMY_IMAGE = IMAGE_CONSTS.PLACEHOLDERS.USER
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

/**
 * @info Downloads the image with the given source
 */
export const downloadFileFromSource = (src: string) => {
  let { fileName, extension } = splitFileNameAndExt(src, false)
  const link = document.createElement('a')
  link.href = src
  link.download = fileName + extension
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 *
 * @param src Image source to be downloaded
 * @info returns jsx with a button to download the image
 */
export const DownloadImage = (args: { src: string; title: string }) => {
  return (
    <div className="w-[300px] font-normal text-md flex flex-col text-white items-center">
      <div className="mb-3">{args?.title}</div>
      <button
        onClick={() => downloadFileFromSource(args.src)}
        className="flex items-center text-sm border border-white rounded-full border-solid py-2 px-3"
      >
        <img
          src={'assets/og_icons/Download from the Cloud-5.svg'}
          className="mr-2"
          alt="downloadIcon"
          width={20}
          height={20}
        />
        Download
      </button>
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */

const RegisterBoxRow: React.FC<IRegisterBoxRow> = ({
  icon,
  header,
  desc,
  viewLink,
  jumpTo,
  className,
  handleModal,
  handleLightBox,
  competitionCourseMapImages,
}) => {
  // Functions
  const scrollToTarget = () => {
    if (typeof handleModal === 'function') {
      handleModal()
    }
    competitionCourseMapImages = competitionCourseMapImages?.filter((currImage) => currImage !== '')
    if (handleLightBox)
      handleLightBox({
        visible: true,
        slides:
          competitionCourseMapImages && competitionCourseMapImages.length
            ? competitionCourseMapImages.map((currImage, index) => {
                return {
                  src: currImage,
                  title: <DownloadImage title={`Site Map ${index + 1}`} src={currImage} />,
                }
              })
            : [],
      })
    if (jumpTo) {
      const elemToFocus = document.querySelector(`#${jumpTo}`)
      if (elemToFocus)
        elemToFocus.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
    }
  }

  if (!header && !desc) return null

  return (
    <div
      className={`flex gap-2 items-center${className ? ' ' + className : ''}`}
      onClick={scrollToTarget}
    >
      <div className="w-[35px] h-[35px] md:w-[48px] md:h-[48px] bg-[#fcebed] rounded-md flex items-center justify-center">
        <img src={icon} alt="Icon" className="w-[70%]" />
      </div>
      <div className="text-SeabiscuitDark200ThemeColor w-[calc(100%-35px)] md:w-[calc(100%-48px)]">
        <h2 className="m-0 text-[14px] md:text-[15px] text-ellipsis overflow-hidden max-w-full font-semibold">
          {header ? header : 'N/A'}{' '}
        </h2>
        <div className="flex items-center gap-2 max-w-full justify-between w-full">
          <div className="m-0 text-[12px] mt-1 text-ellipsis capitalize overflow-hidden max-w-full">
            {desc ? desc : 'N/A'}{' '}
            {viewLink ? (
              <span
                onClick={handleModal}
                className="text-[12px] ml-2 bg-[#fcebed] cursor-pointer rounded-full px-3 py-0 text-SeabiscuitMainThemeColor"
              >
                View
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentRegisterBox: React.FC<{
  exhibitorsModal?: () => void
  ownerId?: string
  isLoggedIn?: boolean
  companyLogo?: string
  waitingListUsers?: any
  companyName?: string | null
  eventTableData?: IEventInterface | null
  handleModal?: IHandleModal['handleModal']
  EventDetails: IEventReviewPublish['EventDetails'] | null
  EventTickets: IEventReviewPublish['EventTickets'] | null
}> = ({
  handleModal,
  eventTableData,
  EventDetails,
  EventTickets,
  companyLogo,
  ownerId,
  companyName,
}) => {
  // Hooks and vars
  const imagesToShow = 3
  const [lightBox, setLightBox] = useState<ILightboxProps>({ visible: false, slides: [] })
  const [registrationCount, setRegistrationCount] = useState<number>(0)
  const [spotsAvailable, setSpotsAvailable] = useState<number>(0)
  const [ticketsRemaining, setTicketsRemaining] = useState<number>(0)
  const [registeredUsersImages, setRegisteredUsersImages] = useState<string[]>([])
  const { eventId } = useParams<{ eventId: string }>()
  const events = useAppSelector(selectEvents).data
  const { EventFees } = useAppSelector(selectedEvent)
  const selectedEventNew = events.find((data) => data.id === eventId)

  const handleregistrationcount = async (eventId: string) => {
    const doc = await FirestoreService.getItem(COLLECTIONS.EVENTS.NAME, eventId)

    let registeredUsers: TEventRegisteredUsers[] = []
    const registeredUsersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', eventId),
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.KEY, 'in', [
          COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PAID,
          COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PENDING,
        ]),
      ]
    )

    registeredUsersSnaps.docs.forEach((currDoc) => {
      registeredUsers.push(
        getConvertedData(EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject())
      )
    })

    if (doc) {
      let eventData = EventModel.fromFirestoreDoc(doc).toObject()

      setSpotsAvailable((EventFees?.registrationLimit?.spots ?? 0) - registeredUsers.length)
      setTicketsRemaining(eventData.ticketItemsCount - eventData.soldTicketItemsCount)
      setRegistrationCount(registeredUsers.length)

      const userIds: string[] = registeredUsers.map((user) => user?.userId ?? '')

      let users: IUserInterface[] = []
      const userSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, userIds)

      userSnaps.forEach((currDoc) => {
        users.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
      })

      const newRegisteredUsersImages: string[] = users.map((user) => user?.userProfilePicture ?? '')

      setRegisteredUsersImages(
        [...newRegisteredUsersImages, ...[...Array(imagesToShow)].map(() => DUMMY_IMAGE)].slice(
          0,
          3
        )
      )
    }
  }

  useEffect(() => {
    if (eventId) {
      handleregistrationcount(eventId)
    }
  }, [eventId])

  // Functions
  const handleLightBox = (props: ILightboxProps) => {
    setLightBox({
      ...props,
    })
  }

  const onLightBoxClose = () => {
    handleLightBox({ visible: false, slides: [] })
  }

  const handleModalFn: IHandleModal['handleModal'] = (show, typeOfModal, data) => {
    if (typeof handleModal !== 'function')
      helpers.logger({
        isError: true,
        message: `EventDetailViewComponentRegisterBox::handleModal is not a function`,
      })
    else handleModal(show, typeOfModal, data)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div className="flex flex-col gap-6">
      <RegisterBoxRow
        icon={License}
        header={
          selectedEventNew?.tags?.find((data: any) => data === 'All')
            ? 'All'
            : [...(selectedEventNew?.tags ?? [])]?.splice(0, 3)?.join(', ') ?? ''
        }
        desc={selectedEventNew?.category ? `${selectedEventNew?.category}` : ''}
      />

      <RegisterBoxRow
        icon={CloseDate}
        header={
          EventDetails?.competitionStartDate === EventDetails?.competitionEndDate
            ? `${moment(EventDetails?.competitionStartDate).format('MMM D, YYYY')}`
            : `${moment(EventDetails?.competitionStartDate).format('MMM D')} - ${moment(EventDetails?.competitionEndDate).format('D, YYYY')}`
        }
        desc={
          eventTableData?.status === 'cancel' ? (
            <button
              type="button"
              disabled
              className="!w-fit text-white bg-[#FC2804] px-2 rounded-2xl text-[10px] 2xl:text-sm py-0.5 mt-2"
            >
              Cancelled
            </button>
          ) : (
            <div style={{ textTransform: 'none' }}>
              Registrations close:{' '}
              {moment(EventDetails?.competitionRegistrationCloseDate).format('MMM D, YYYY')}
            </div>
          )
        }
      />

      {typeof EventDetails === 'object' && Object.keys(EventDetails ?? {}).length ? (
        <RegisterBoxRow
          jumpTo="location_target"
          icon={Location}
          header={`${EventDetails?.competitionAddressLine1 && EventDetails?.competitionAddressLine1 !== '' ? (EventDetails?.competitionAddressLine1.length > 24 ? `${EventDetails?.competitionAddressLine1.slice(0, 24)}...` : EventDetails?.competitionAddressLine1) : ''}`}
          // header={`${EventDetails?.competitionCity ?? ""}`}
          desc={`${EventDetails?.competitionCity}, ${EventDetails?.competitionState}${EventDetails?.competitionCountry ? ', ' + EventDetails?.competitionCountry : ''}`}
          className="cursor-pointer"
        />
      ) : null}

      {EventDetails?.competitionCourseMapImages &&
      EventDetails?.competitionCourseMapImages.length ? (
        <RegisterBoxRow
          icon={Map}
          handleLightBox={handleLightBox}
          competitionCourseMapImages={EventDetails?.competitionCourseMapImages}
          header="Site Map"
          desc="Draft"
          viewLink="https://google.com"
          className="cursor-pointer"
        />
      ) : null}

      {/* <RegisterBoxRow
                    icon={Store}
                    handleModal={() => handleModalFn(true, MODAL_CONSTS.PARTNER)}
                    header="Accepting Partners"
                    desc="Sponsors, Vendors, Nearbye"
                    className="cursor-pointer"
                /> */}

      {EventTickets?.sellSpectatorTickets ? (
        <RegisterBoxRow
          icon={Tickets}
          handleModal={() =>
            handleModalFn(true, MODAL_CONSTS.SPECTATOR_TICKETS, {
              eventTableData,
              EventDetails,
              EventTickets,
            })
          }
          header={`${ticketsRemaining} Tickets Remaining`}
          className="cursor-pointer"
          desc="Spectator Tickets"
          viewLink="https://google.com"
        />
      ) : (
        <RegisterBoxRow
          icon={Tickets}
          header={`Tickets not for sale`}
          className=""
          desc="Spectator Tickets"
        />
      )}

      <div className="flex items-center gap-4">
        <Link to={`${CONST.ROUTES.USER_PROFILE.URL}/${ownerId}`}>
          <div className="flex items-center justify-center w-[35px] h-[35px] md:w-[48px] md:h-[48px] cursor-pointer">
            <img
              src={companyLogo ?? Organizer}
              alt="Icon"
              className="w-full h-full rounded-md object-cover shadow-lg"
              onError={(e) => (e.currentTarget.src = '/assets/placeholders/Emptyuser.png')}
            />
          </div>
        </Link>

        <div className="text-SeabiscuitDark200ThemeColor max-w-[calc(100%-35px)] md:max-w-[calc(100%-48px)] capitalize">
          <h2 className="m-0 text-[14px] md:text-[16px] text-ellipsis overflow-hidden max-w-full font-semibold">
            {companyName}
          </h2>
          <div className="flex items-center gap-2 w-full justify-between">
            <p className="m-0 text-[12px] text-ellipsis overflow-hidden max-w-full">Organizer</p>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4">
        {registeredUsersImages.length > 0 ? (
          <div className="flex items-center justify-center ml-0 pl-0">
            {registeredUsersImages.length
              ? registeredUsersImages.map((currUserImage, index) => {
                  return (
                    <img
                      alt={'UI'}
                      onError={(e) => ((e.target as any).src = DUMMY_IMAGE)}
                      src={currUserImage}
                      key={index}
                      className={clsx(
                        'min-w-[35px] w-[35px] h-[35px] bg-SeabiscuitWhiteThemeColor object-cover rounded-full border-2 border-solid border-[#eaeaea]',
                        !!index && 'ml-[-20px]'
                      )}
                    />
                  )
                })
              : null}
          </div>
        ) : (
          <div className="w-[20%]"></div>
        )}
        <div className="text-SeabiscuitDark200ThemeColor w-full">
          <h2 className="m-0 text-[14px] md:text-[16px] ellipsis max-w-full font-semibold">
            {registrationCount ?? '0'} Attending
          </h2>
          <div className="flex items-center gap-2 max-w-full">
            {spotsAvailable === 0 ? (
              eventTableData && eventTableData?.waitlist ? (
                <p className="m-0 mt-1 text-[12px] ellipsis max-w-full">
                  {eventTableData?.waitlistCount} on waitlist
                </p>
              ) : null
            ) : (
              <p className="m-0 mt-1 text-[12px] ellipsis max-w-full">
                {EventFees?.registrationLimit?.isEnable ? spotsAvailable : 'N/A'} Spots Remaining
              </p>
            )}
            <button
              onClick={() =>
                handleModalFn(true, MODAL_CONSTS.REGISTER_EXHIBITORS, {
                  eventClicked: { eventId: eventId ?? '' },
                })
              }
              className="text-[12px] bg-[#fef5f6] rounded-full px-3 py-0 text-SeabiscuitMainThemeColor border-none outline-none h-[18px]"
            >
              View
            </button>
          </div>
        </div>
      </div>

      {/* LightBox */}
      <LightboxCommonComponent
        visible={lightBox.visible}
        onClose={onLightBoxClose}
        slides={lightBox.slides}
      />
    </div>
  )
}

export default EventDetailViewComponentRegisterBox
