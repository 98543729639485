import React from 'react'

export const IconArrowRight: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" fill="none" viewBox="0 0 8 12">
    <path
      stroke="#122B46"
      strokeLinecap="round"
      strokeWidth="2"
      d="M1.38 1.69l4.285 4.5-4.284 4.5"
    ></path>
  </svg>
)
