import TimeLib from '../../../../lib/Time'
import { IRefundInterace } from '../../../../models/refunds/refund.interface'

export const DATA_FIRESTORE_V01_REFUNDS = {
  NAME: 'v01_refunds',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    ID: {
      KEY: 'id',
      DEFAULT: null,
    },

    RECIPIENT_PROFILE_PICTURE: {
      KEY: 'recipientProfilePicture',
      DEFAULT: null,
    },

    INVOICE_URL: {
      KEY: 'invoiceUrl',
      VALUES: {
        DEFAULT: null,
      },
    },

    REFUNDED_AT: {
      KEY: 'refundedAt',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    PAYER_ID: {
      KEY: 'payerId',
      DEFAULT: null,
    },

    EVENT_ID: {
      KEY: 'eventId',
      DEFAULT: null,
    },

    RECIPIENT_NAME: {
      KEY: 'recipientName',
      DEFAULT: null,
    },

    RECIPIENT_NAME_N_GRAMS: {
      KEY: 'recipientNameNGrams',
      DEFAULT: [],
    },

    CHARGE_ID: {
      KEY: 'chargeId',
      DEFAULT: null,
    },

    AMOUNT_PAID: {
      KEY: 'amountPaid',
      DEFAULT: 0,
    },

    REGISTRATION_ID: {
      KEY: 'registrationId',
      DEFAULT: null,
    },

    EVENT_OWNER_CONNECT_ID: {
      KEY: 'eventOwnerConnectId',
      DEFAULT: null,
    },

    UPDATED_AT: {
      KEY: 'updatedAt',
      DEFAULT: TimeLib.utcTimestamp(),
    },

    CREATED_AT: {
      KEY: 'createdAt',
      DEFAULT: TimeLib.utcTimestamp(),
    },

    EVENT_OWNER_ID: {
      KEY: 'eventOwnerId',
      DEFAULT: null,
    },

    TRANSACTION_ID: {
      KEY: 'transactionId',
      DEFAULT: null,
    },

    TRANSACTION_REF_ID: {
      KEY: 'transactionRefId',
      DEFAULT: null,
    },

    REFUND_ID: {
      KEY: 'refundId',
      DEFAULT: null,
    },

    REFUND_COMMENT: {
      KEY: 'refundId',
      DEFAULT: null,
    },

    REFUND_AMOUNT: {
      KEY: 'refundAmount',
      DEFAULT: null,
    },

    RECIPIENT_DOC_ID: {
      KEY: 'recipientDocId',
      DEFAULT: null,
    },

    RECIPIENT_USER_ID: {
      KEY: 'recipientUserId',
      DEFAULT: null,
    },

    REFUND_REASON: {
      KEY: 'refundReason',
      DEFAULT: null,
    },

    REFUND_STATUS: {
      KEY: 'refundStatus',
      DEFAULT: null,
      VALUES: {
        FAILED: 'failed' as IRefundInterace['refundStatus'],
        PENDING: 'pending' as IRefundInterace['refundStatus'],
        CANCELLED: 'canceled' as IRefundInterace['refundStatus'],
        SUCCEEDED: 'succeeded' as IRefundInterace['refundStatus'],
        REQUIRES_ACTION: 'requires_action' as IRefundInterace['refundStatus'],
      },
    },
  },
}
