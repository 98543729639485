import { ModelBaseModel } from '../model-base/model-base.model'

import { CONST } from '../../const/const'
import { IRegistrationTeamInterface } from './registration-teams.interface'

// Constants
const REGISTRATION_TEAMS_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_TEAMS

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @TODO Document this
 */
export class RegistrationTeamModel extends ModelBaseModel<IRegistrationTeamInterface> {
  // Properties
  public v: IRegistrationTeamInterface['v']
  public id: IRegistrationTeamInterface['id']
  public userId: IRegistrationTeamInterface['userId']
  public eventId: IRegistrationTeamInterface['eventId']
  public eventName: IRegistrationTeamInterface['eventName']
  public userName: IRegistrationTeamInterface['userName']
  public registrationDocId: IRegistrationTeamInterface['registrationDocId']
  public userNameNGram: IRegistrationTeamInterface['userNameNGram']
  public memberProfilePicture: IRegistrationTeamInterface['memberProfilePicture']
  public memberAuthorized: IRegistrationTeamInterface['memberAuthorized']
  public memberDob: IRegistrationTeamInterface['memberDob']
  public memberId: IRegistrationTeamInterface['memberId']
  public memberName: IRegistrationTeamInterface['memberName']
  public memberEmail: IRegistrationTeamInterface['memberEmail']
  public memberRole: IRegistrationTeamInterface['memberRole']
  public selected: IRegistrationTeamInterface['selected']

  public created: IRegistrationTeamInterface['created']
  public modified: IRegistrationTeamInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param obj `IRegistrationTeamInterface`
   */
  public constructor(obj?: IRegistrationTeamInterface) {
    super()
    this.v = obj?.v ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.V.VALUES.DEFAULT
    this.id = obj?.id ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.eventId = obj?.eventId ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.EVENT_ID.VALUES.DEFAULT
    this.eventName =
      obj?.eventName ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.EVENT_NAME.VALUES.DEFAULT
    this.registrationDocId =
      obj?.registrationDocId ??
      REGISTRATION_TEAMS_COLLECTION.FIELDS.REGISTRATION_DOC_ID.VALUES.DEFAULT

    this.userId = obj?.userId ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.USER_ID.VALUES.DEFAULT
    this.userName = obj?.userName ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.EVENT_NAME.VALUES.DEFAULT
    this.userNameNGram =
      obj?.userNameNGram ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.USER_NAME_N_GRAM.VALUES.DEFAULT
    this.memberProfilePicture =
      obj?.memberProfilePicture ??
      REGISTRATION_TEAMS_COLLECTION.FIELDS.USER_PROFILE_PICTURE.VALUES.DEFAULT

    this.memberId = obj?.memberId ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.MEMBER_ID.VALUES.DEFAULT
    this.memberName =
      obj?.memberName ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.MEMBER_NAME.VALUES.DEFAULT
    this.memberEmail =
      obj?.memberEmail ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.MEMBER_EMAIL.VALUES.DEFAULT
    this.memberRole =
      obj?.memberRole ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.MEMBER_ROLE.VALUES.DEFAULT
    this.memberDob =
      obj?.memberDob ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.MEMBER_DOB.VALUES.DEFAULT

    this.selected = obj?.selected ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.SELECTED.VALUES.DEFAULT
    this.memberAuthorized =
      obj?.memberAuthorized ?? REGISTRATION_TEAMS_COLLECTION.FIELDS.MEMBER_AUTHORIZED.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // Methods

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new RegistrationTeamModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
