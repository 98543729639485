import { createSlice } from '@reduxjs/toolkit'
// import { RootState } from '../store';
import { IUserInterface } from '../../models/users/user.interface'
import { RootState } from '../store'

interface IregisterUserInterfaceExtended extends IUserInterface {
  userHorses: string | null
  visitedRegisterExhibitorProfileDetails: string | null
  registerId: string | null
  waitlistId: string | null
  visitedWaitlistExhibitorProfileDetails: string | null
}
type IRegisterExhibitorState = {
  visitedRegisterExhibitorProfileDetails: IregisterUserInterfaceExtended | null
  registerId: IregisterUserInterfaceExtended | null
  waitlistId: IregisterUserInterfaceExtended | null
  visitedWaitlistExhibitorProfileDetails: IregisterUserInterfaceExtended | null
}

const registerState: IRegisterExhibitorState = {
  visitedRegisterExhibitorProfileDetails: null,
  registerId: null,
  waitlistId: null,
  visitedWaitlistExhibitorProfileDetails: null,
}

const registerExhibitorSlice = createSlice({
  name: 'registerexhibitors',
  initialState: registerState,
  reducers: {
    setRegisterExhibitor(state, { payload }: { payload: IUserInterface | any }) {
      state.visitedRegisterExhibitorProfileDetails = payload
    },

    setRegisterId(state, { payload }: { payload: IUserInterface | any }) {
      state.registerId = payload
    },

    setWaitlistId(state, { payload }: { payload: IUserInterface | any }) {
      state.waitlistId = payload
    },

    setWaitlistExhibitor(state, { payload }: { payload: IUserInterface | any }) {
      state.visitedWaitlistExhibitorProfileDetails = payload
    },
  },
})

export const { setRegisterExhibitor, setRegisterId, setWaitlistId, setWaitlistExhibitor } =
  registerExhibitorSlice.actions

export const selectRegisterExhibitor = (state: RootState) => state.registerExhibitors

export default registerExhibitorSlice.reducer
