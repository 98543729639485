import clsx from 'clsx'
import React, { FC } from 'react'

interface DaysTabsProps {
  days: string[]
  setActiveDay: (day: string) => void
  activeDay: string
}

export const DaysTabs: FC<DaysTabsProps> = ({ days, activeDay, setActiveDay }) => {
  return (
    <div className="flex flex-wrap gap-2 text-[14px] mb-6">
      <button
        className={clsx(
          'min-w-[80px] p-2 rounded-lg',
          activeDay === 'All'
            ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor transition-all hover:text-SeabiscuitMainThemeColor'
            : 'bg-transparent text-SeabiscuitDark200ThemeColor'
        )}
        onClick={() => setActiveDay('All')}
      >
        All
      </button>
      {days.map((day) => (
        <button
          className={clsx(
            'min-w-[80px] p-2 rounded-lg transition-all hover:text-SeabiscuitMainThemeColor',
            activeDay === day
              ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor'
              : 'bg-transparent text-SeabiscuitDark200ThemeColor'
          )}
          onClick={() => setActiveDay(day)}
        >
          {day}
        </button>
      ))}
    </div>
  )
}
