import { IonItem } from '@ionic/react'
import { CONST } from '../../../../../const/const'

const ProfileTeamTabCompetitorTabs = ({
  setEventTab,
  eventTab,
}: {
  setEventTab: any
  eventTab: any
}) => {
  const eventFormIndex = (function () {
    return 0
  })()

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getTabStyle = (value: string, sequence: number, event_index: number) => {
    let style = `px-2 sm:px-8 text-center py-2.5 relative min-w-fit text-nr transition ease-in duration-200 focus:outline-none rounded-lg text-center`

    if (eventTab === value || sequence < event_index) {
      style = `${style} `
      if (eventTab === value) {
        return `${style} bg-[#f7074f1a] text-SeabiscuitMainThemeColor`
      } else if (sequence < event_index) {
        return `${style} text-SeabiscuitMainThemeColor`
      }
    }

    return `${style} bg-SeabiscuitWhiteThemeColor`
  }

  return (
    <div className="flex items-center justify-between mb-4 w-full">
      <IonItem lines="none" className="ulfat remove-child-pl">
        <div className="Innrclass w-full">
          <div className="flex overflow-x-auto gap-x-2 tabScroll w-full">
            {/**************************************************************/}
            {/* Authorise */}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.USER.TEAM.TABS.AUTHORIZE.SEQUENCE <= eventFormIndex) {
                  setEventTab(CONST.UI.USER.TEAM.TABS.AUTHORIZE.VALUE)
                }
              }}
              className={`w-full
                        ${getTabStyle(
                          CONST.UI.USER.TEAM.TABS.AUTHORIZE.VALUE,
                          CONST.UI.USER.TEAM.TABS.AUTHORIZE.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center justify-center">
                <div
                  className={`${eventTab === CONST.UI.USER.TEAM.TABS.AUTHORIZE.VALUE ? 'text-SeabiscuitMainThemeColor' : 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap text-[12px]`}
                >
                  People you add
                </div>
              </div>
            </button>

            {/**************************************************************/}
            {/* Authorise Me*/}
            {/**************************************************************/}

            <button
              type="button"
              onClick={() => {
                if (CONST.UI.USER.TEAM.TABS.AUTHORIZED_ME.SEQUENCE <= eventFormIndex) {
                  setEventTab(CONST.UI.USER.TEAM.TABS.AUTHORIZED_ME.VALUE)
                }
              }}
              className={`w-full
                        ${getTabStyle(
                          CONST.UI.USER.TEAM.TABS.AUTHORIZED_ME.VALUE,
                          CONST.UI.USER.TEAM.TABS.AUTHORIZED_ME.SEQUENCE,
                          eventFormIndex
                        )}`}
            >
              <div className="flex ion-align-items-center justify-center">
                <div
                  className={`${eventTab === CONST.UI.USER.TEAM.TABS.AUTHORIZED_ME.VALUE ? 'text-SeabiscuitMainThemeColor' : 'text-SeabiscuitDark200ThemeColor'} pl whitespace-nowrap`}
                >
                  People who added you
                </div>
              </div>
            </button>
          </div>
        </div>
      </IonItem>
    </div>
  )
}

export default ProfileTeamTabCompetitorTabs
