import { useState } from 'react'
import MessageHelperComp from '../../../../../helpers/MessageHelper'

//third-party
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'

import { IUserSignupForm } from '../../../../../types/competitor_types'

import { useContext } from 'react'
import { SignupStages } from './UserSignupViewComponentContextApi'
import UserSignupViewComponentButton from './global/UserSignupViewComponentButton'

import { userSignupSchema } from '../../../../../validations'

import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import { PoliciesData } from '../../../../../pages/help/data/PoliciesData'
import { HelpRootPageDataPassOn } from '../../../../../pages/help/tabs/HelpRootPagePoliciesTab'
import backIcon from '../assets/arrow-left.svg'
import { CountryList } from '../../../../../fakeData/countryList'
import Select from 'react-select'
import { customStyles } from '../../../../customStyles/ReactSelectCustomStyle'

export interface UserSignupViewComponentEventOrganizerSignupFormProps {
  onSignUpButtonPressed?: any
  loading?: boolean
}

const UserSignupViewComponentEventOrganizerSignupForm: React.FC<
  UserSignupViewComponentEventOrganizerSignupFormProps
> = (props) => {
  const termsPolicy = PoliciesData[0]

  const [innerType] = useState('password')
  const [c_innerType] = useState('password')
  const [stage, setStage] = useContext(SignupStages)
  const handleModalContext = useContext(HandleModalContext)

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IUserSignupForm>({
    mode: 'onChange',
    resolver: yupResolver(userSignupSchema),
  })

  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  const openTermsAndCondModal = () => {
    if (!handleModalContext) return

    handleModalContext.handleModal(true, MODAL_CONSTS.HELP_VIDEO, {
      component: (
        <HelpRootPageDataPassOn handleModal={handleModalContext.handleModal} policy={termsPolicy} />
      ),
      small: true,
      positionModel: 'Right',
    })
  }

  return (
    <div>
      <div className="text-[24px] flex items-center gap-2 text-white w-[500px]">
        <button onClick={() => stageHandle('Init')}>
          <img src={backIcon} alt="back" className="" />
        </button>
      </div>
      <div>
        <h1 className="text-[22px] font-normal text-white mt-4 mb-2">Sign up</h1>
      </div>

      <form onSubmit={handleSubmit((e) => props.onSignUpButtonPressed(e, 'organizer'))}>
        <div className="mt-4">
          <label className="mt-3 text-gray-500 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/og_icons/Farm.svg" alt="icon" className="brightness-200" />
            </span>
            <input
              type="text"
              {...register('fullname', {
                onChange: (e) => {
                  e.target.value = e.target.value.replaceAll(/([^a-zA-Z\s])/gi, '')
                },
              })}
              placeholder="Organization name"
              className="flex-1 border-0 outline-0 text-white bg-transparent placeholder:text-white focus:ring-transparent focus:border:none w-full focus:border-0"
            />
          </label>
          {errors.fullname && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mt-10 opacity-80 w-fit"
              isError={true}
              message={errors.fullname.message?.replace('fullname', 'organization name')}
            />
          )}

          <label className="mt-3 !text-white placeholder:text-white flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="assets/og_icons/Globe.svg" alt="icon" className="brightness-200" />
            </span>
            <Controller
              control={control}
              name="nationality"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  onChange={onChange}
                  onBlur={onBlur}
                  value={CountryList.find((c) => c.value === value)}
                  options={CountryList}
                  placeholder="Where is your business located?"
                  className="!text-white w-[420px] selectWithSearch rounded-xl h-[42px] mt-[-3px] !pr-0"
                  styles={
                    {
                      color: 'white !important',
                      ...customStyles,
                      valueContainer: (provided: any) => ({
                        ...provided,
                        textAlign: 'left',
                        justifyContent: 'start',
                      }),
                      singleValue: (provided: any, state: any) => ({
                        ...(customStyles?.singleValue &&
                          customStyles?.singleValue(provided, state)),
                        fontSize: '14px !important',
                        color: 'white',
                        opacity: '1',
                      }),
                      placeholder: (provided: any) => ({
                        ...provided,
                        color: 'white',
                        fontSize: '14px !important',
                      }),
                    } as any
                  }
                  isClearable={false}
                  isMulti={false}
                  isSearchable={true}
                />
              )}
            />
          </label>

          <label className="mt-3 text-gray-500 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/og_icons/userNameIcon.svg" alt="icon" className="brightness-200" />
            </span>
            <input
              type="text"
              {...register('username')}
              placeholder="Username"
              className="flex-1 border-0 outline-0 text-white bg-transparent placeholder:text-white focus:ring-transparent focus:border:none w-full focus:border-0"
            />
          </label>
          {errors.username && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mt-10 opacity-80 w-fit"
              isError={true}
              message={errors.username.message}
            />
          )}

          <label className="mt-3 text-gray-500 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/og_icons/Mail-4.svg" alt="icon" className="brightness-200" />
            </span>
            <input
              type="email"
              {...register('email')}
              placeholder="Email"
              className="flex-1 border-0 outline-0 text-white bg-transparent placeholder:text-white focus:ring-transparent w-full"
            />
          </label>
          {errors.email && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mt-10 opacity-80 w-fit"
              isError={true}
              message={errors.email.message}
            />
          )}

          <label className="mt-3 text-gray-500 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/og_icons/Lock-4.svg" alt="icon" className="brightness-200" />
            </span>
            <input
              type={innerType}
              {...register('newPassword')}
              placeholder="Password"
              className="flex-1 border-0 outline-0 text-white bg-transparent placeholder:text-white focus:ring-transparent w-full"
            />
            {/* <div
                                onClick={() =>
                                    setInnerType(
                                        innerType === "password" ? "text" : "password"
                                    )
                                }
                            >
                                {
                                    innerType === "password" ? <VisibilityOffOutlined className="text-SeabiscuitGray500ThemeColor" /> : <Visibility className="text-SeabiscuitGray500ThemeColor" />
                                }
                            </div> */}
          </label>
          {errors.newPassword && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mt-10 opacity-80 w-fit"
              isError={true}
              message={errors.newPassword.message}
            />
          )}

          <label className="mt-3 text-gray-500 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/og_icons/Lock-4.svg" alt="icon" className="brightness-200" />
            </span>
            <input
              type={c_innerType}
              {...register('c_password')}
              placeholder="Confirm password"
              className="flex-1 border-0 outline-0 text-white bg-transparent placeholder:text-white focus:ring-transparent w-full"
            />
            {/* <div onClick={() => setC_innerType(c_innerType === "password" ? "text" : "password")}>
                                {c_innerType === "password" ? <VisibilityOffOutlined className="text-SeabiscuitGray500ThemeColor" /> : <Visibility className="text-SeabiscuitGray500ThemeColor" />}
                            </div> */}
          </label>
          {errors.c_password && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mt-10 opacity-80 w-fit"
              isError={true}
              message={errors.c_password.message}
            />
          )}
        </div>
        <div className="w-[70%] mt-[40px] mx-auto flex justify-center">
          <UserSignupViewComponentButton
            type="submit"
            loading={props?.loading}
            caption="SIGN UP"
            kind={4}
            disabled={!stage.selector}
            className="w-[290px] py-[17px] text-base"
          />
        </div>
      </form>

      <div className="text-white my-5 text-center text-sm italic">
        By creating an account, you are agreeing to <br />
        Pegasus'
        <button
          type="button"
          onClick={openTermsAndCondModal}
          className="hidden"
          id="openTermsModal"
        >
          Open terms modal
        </button>
        <label htmlFor="openTermsModal" className="text-white underline cursor-pointer pl-1">
          Terms and Conditions
        </label>
      </div>
      {/* <div className="text-center text-white opacity-50 mt-10">OR</div> */}
      <div className="text-white my-5 text-center">
        Already have an account?{' '}
        <a className="text-white underline" href="/login">
          Sign in
        </a>
      </div>
    </div>
  )
}

export default UserSignupViewComponentEventOrganizerSignupForm
