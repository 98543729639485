import React, { useState } from 'react'
import tabsJSon from '../../../ticket-dashboard/components/tabs/tabs.json'
import { PillButtonElement } from '../../../../components/elements/buttons/pill/PillButtonElement'
import TicketHoldersTab from '../../../ticket-dashboard/components/tabs/TicketHoldersTab'
import TicketBuyersTab from '../../../ticket-dashboard/components/tabs/TicketBuyersTab'

interface IProps {
  eventId: string
}
export const ManageClinicSpectators: React.FC<IProps> = ({ eventId }) => {
  const [activeTab, setActiveTab] = React.useState(tabsJSon[0].ticketUrl)
  const [checkedIn, setCheckedIn] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  return (
    <>
      <div className="flex flex-col md:flex-row md:justify-between w-full">
        <div className="flex">
          {tabsJSon.map((currTab) => {
            return (
              <PillButtonElement
                visible={true}
                key={currTab.tabName}
                text={currTab.tabName}
                value={currTab.tabName}
                Class="text-SeabiscuitDark200ThemeColor border-none"
                selected={activeTab === currTab.ticketUrl}
                onButtonClicked={() => setActiveTab(currTab.ticketUrl)}
              />
            )
          })}
        </div>
        <div className="flex items-center">
          <div className="px-2 flex items-center gap-2 py-1 mx-auto rounded-lg">
            <div className="text-sm text-SeabiscuitDark200ThemeColor opacity-50">Checked In:</div>
            <div className="flex justify-center text-[#00B6AA]">
              {checkedIn} / {total}
            </div>
          </div>
        </div>
      </div>

      {tabsJSon[0].ticketUrl === activeTab ? (
        <TicketHoldersTab eventId={eventId} setTotal={setTotal} setCheckedIn={setCheckedIn} />
      ) : (
        <TicketBuyersTab eventId={eventId} />
      )}
    </>
  )
}
