// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { cloneDeep } from 'lodash'
import { useContext, useState } from 'react'
import { CONST } from '../../../../const/const'
import { UserModel } from '../../../../models/users/user.model'
import { SignupStages } from './UserRegistrationViewComponentContextApi'
import eventIcon from './assets/event.svg'
import UserRegistrationViewComponentButton from './global/UserRegistrationViewComponentButton'
import UserRegistrationViewComponentInput from './global/UserRegistrationViewComponentInput'
import { getUserFullName } from '../../../../helpers/helpers'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const Selector: React.FC<{
  caption: string
  selected: string
  onChange: (value: string) => void
  value: string
  icon: string
}> = ({ caption, selected, onChange, value, icon }) => (
  <button
    onClick={() => onChange(value)}
    className={`text-[14px] border-[#D3DAEE] border-solid border-[1px] rounded-xl flex w-full p-4 gap-2 items-center mt-3 text-[#777E85] bg-white ${selected === value ? 'bg-SeabiscuitMainThemeColor !text-white border-SeabiscuitMainThemeColor' : ''}`}
  >
    <span>
      <img src={icon} alt="icon" className={selected === value ? 'brightness-0 invert' : ''} />
    </span>
    <span>{caption}</span>
  </button>
)

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface UserRegistrationViewComponentSignupProps {
  onRegistrationStartNextValid: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const UserRegistrationViewComponentSignup: React.FC<UserRegistrationViewComponentSignupProps> = (
  props
) => {
  const [stage, setStage] = useContext(SignupStages)
  const [userData, setUserData] = useState(new UserModel())

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const selectorHandle = (value: string) => {
    setStage({ ...stage, selector: value })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onUsernameChangeHandle = (e: any) => {
    userData.setUsernameAndCalculateNGram(e.target.value)
    setUserData(cloneDeep(userData))
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const stageWay = async () => {
    if (
      stage.selector ===
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.FIELDS.USER_TYPE.VALUES.ORGANIZER
    ) {
      stageHandle('EventOrg')
    }
    if (
      stage.selector ===
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.FIELDS.USER_TYPE.VALUES.VENDOR
    ) {
      stageHandle('VendorForm')
    }
    if (
      stage.selector ===
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.FIELDS.USER_TYPE.VALUES.COMPETITOR
    ) {
      stageHandle('CompOrgRoleType')
    }

    props.onRegistrationStartNextValid(stage.selector, userData.userName)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div>
      <div>
        <h1 className="text-[24px] font-norma text-[#120D26]">Registration</h1>
      </div>
      <div>
        <p className="text-[18px] text-SeabiscuitDark200ThemeColor mt-4">Set Your Username</p>
      </div>
      <UserRegistrationViewComponentInput
        type="text"
        className="mt-4"
        placeholder="Username"
        value={getUserFullName(userData)}
        onChange={onUsernameChangeHandle}
        icon="/assets/og_icons/User-4.svg"
      />
      <div>
        <p className="text-[18px] text-SeabiscuitDark200ThemeColor mt-4">What are you?</p>
      </div>
      <div>
        <Selector
          caption="Competitor, Spectator, Trainer, etc."
          value={CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.FIELDS.USER_TYPE.VALUES.COMPETITOR}
          icon="/assets/og_icons/User-4.svg"
          selected={stage.selector}
          onChange={selectorHandle}
        />
        <Selector
          caption="Event Organizer"
          value={CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.FIELDS.USER_TYPE.VALUES.ORGANIZER}
          icon={eventIcon}
          selected={stage.selector}
          onChange={selectorHandle}
        />
        <Selector
          caption="Vendor, Sponsor, etc."
          value={CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.FIELDS.USER_TYPE.VALUES.VENDOR}
          icon="/assets/og_icons/Department Shop-4.svg"
          selected={stage.selector}
          onChange={selectorHandle}
        />
      </div>
      <div className="w-[70%] mt-[80px] mx-auto">
        <UserRegistrationViewComponentButton
          caption="NEXT"
          type={1}
          disabled={!stage.selector || userData.userName === ''}
          onClick={stageWay}
        />
      </div>
    </div>
  )
}

UserRegistrationViewComponentSignup.defaultProps = {
  onRegistrationStartNextValid: null,
}

export default UserRegistrationViewComponentSignup
