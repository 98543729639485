import TimeLib from '../../../../lib/Time'

export const DATA_FIRESTORE_V01_EVENT_RESULTS_REPORTS = {
  NAME: 'v01_event_results_reports',
  FIELDS: {
    V: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_ID: {
      KEY: 'eventId',
    },

    EVENT_NAME: {
      KEY: 'eventName',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_ID: {
      KEY: 'userId',
    },

    REPORT_TYPE: {
      KEY: 'reportType',
      VALUES: {
        USEF_RESULTS: {
          KEY: 'USEF Results',
        },
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    MODIFIED: {
      KEY: 'modified',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
