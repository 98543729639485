/* eslint-disable no-useless-escape */
// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import { CONFIG } from '../../config/config'
import { selectObjToString } from '../interface.helper'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import {
  BeddingFeeType,
  FeedFeesType,
  GovernanceFeesType,
  IEventFees,
  OtherFeeType,
  QualifyingClassesType,
  RefundsType,
  RegistrationFeesType,
  RegistrationLimitType,
  RideTimeRequirementType,
  StallFeesType,
} from './event-fees.interface'
import { IEventPaymentSettingsInterface } from '../event-payment-settings/event-payment-settings.interface'
import moment from 'moment/moment'

export class EventFeesModel extends ModelBaseModel<IEventFees> implements IModelBaseModel {
  public v: number
  public id: IEventFees['id']
  public owner: IEventFees['owner']

  public registrationFees: RegistrationFeesType[]
  public paymentOption: IEventPaymentSettingsInterface['paymentOption'] | null

  public showCancellationRefundWeather: RefundsType
  public showCancellationRefundBioSecurity: RefundsType
  public afterClosingDateRefund: RefundsType
  public beforeClosingDateRefund: RefundsType
  public extraRefunds: RefundsType[] | any

  public temporaryStallsFee: StallFeesType
  public permanentStallsFee: StallFeesType
  public privateStallsFee: StallFeesType
  public circuitStallsFee: StallFeesType
  public muckFee: StallFeesType
  public extraStallFees: StallFeesType[] | any

  public USEFFee: GovernanceFeesType
  public USEFDrugFee: GovernanceFeesType
  public USEFShowPassFee: GovernanceFeesType
  public USDFFee: GovernanceFeesType
  public USDFShowPassFee: GovernanceFeesType
  public USEAFee: GovernanceFeesType
  public USEAShowPassFee: GovernanceFeesType
  public USHJAHoursFee: GovernanceFeesType
  public USHJAShowPassFee: GovernanceFeesType
  public extraGovernanceFees: GovernanceFeesType[] | any

  public paddockFee: OtherFeeType
  public RVHookupFee: OtherFeeType
  // public shipInGroundFee: OtherFeeType
  public extraOtherFees: OtherFeeType[] | any

  public shavingFees: BeddingFeeType
  public strawFees: BeddingFeeType
  public extraBeddingFees: BeddingFeeType[] | any

  public coastalHay: FeedFeesType
  public timothyHay: FeedFeesType
  public alfalfa: FeedFeesType
  public TnA: FeedFeesType
  public extraFeedFees: FeedFeesType[] | any

  public prizeListFees: any

  public registrationLimit: RegistrationLimitType
  public rideTimeRequirement: RideTimeRequirementType
  public qualifyingClasses: QualifyingClassesType

  public created: Date
  public modified: Date

  public constructor(obj?: IEventFees) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? null
    this.paymentOption = obj?.paymentOption ?? null

    this.temporaryStallsFee = obj?.temporaryStallsFee
      ? {
          category: this.getString('stalls'),
          title: this.getString('Temporary stalls'),
          sold: this.getNum(obj?.temporaryStallsFee?.sold),
          uuid: this.getUuid(obj?.temporaryStallsFee?.uuid),
          note: this.getString(obj?.temporaryStallsFee?.note),
          status: this.getBool(obj?.temporaryStallsFee?.status),
          duration: selectObjToString(obj?.temporaryStallsFee?.duration),
          available: this.getNum(obj?.temporaryStallsFee?.available),
          cost: this.getNum(obj?.temporaryStallsFee?.actualCostAlias, obj?.paymentOption),
          costAlias: `$${this.getNum(obj?.temporaryStallsFee?.actualCostAlias, obj?.paymentOption)}`,
          actualCostAlias: `$${this.getNum(obj?.temporaryStallsFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.temporaryStallsFee?.actualCostAlias),
        }
      : {
          sold: 0,
          status: false,
          title: 'Temporary stalls',
          category: 'stalls',
          available: 0,
          uuid: this.getUuid(),
          note: this.getString(),
          duration: this.getString(),
          cost: this.getNum(0),
          costAlias: `$0`,
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.temporaryStallsFee?.actualCostAlias)}`,
        }

    this.permanentStallsFee = obj?.permanentStallsFee
      ? {
          category: 'stalls',
          title: this.getString('Permanent stalls'),
          sold: this.getNum(obj?.permanentStallsFee?.sold),
          uuid: this.getUuid(obj?.permanentStallsFee?.uuid),
          note: this.getString(obj?.permanentStallsFee?.note),
          status: this.getBool(obj?.permanentStallsFee?.status),
          duration: this.getString(obj?.permanentStallsFee?.duration),
          available: this.getNum(obj?.permanentStallsFee?.available),
          cost: this.getNum(obj?.permanentStallsFee?.actualCostAlias, obj?.paymentOption),
          costAlias: `$${this.getNum(obj?.permanentStallsFee?.actualCostAlias, obj?.paymentOption)}`,
          actualCostAlias: `$${this.getNum(obj?.permanentStallsFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.permanentStallsFee?.actualCostAlias),
        }
      : {
          status: false,
          title: 'Permanent stalls',
          category: 'stalls',
          sold: 0,
          available: 0,
          cost: this.getNum(0),
          duration: '',
          uuid: uuidv4(),
          note: this.getString(),
          costAlias: `$0`,
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.permanentStallsFee?.actualCostAlias)}`,
        }

    this.privateStallsFee = obj?.privateStallsFee
      ? {
          category: 'stalls',
          note: this.getString(obj?.privateStallsFee?.note),
          title: this.getString('Private stalls'),
          sold: this.getNum(obj?.privateStallsFee?.sold),
          uuid: this.getUuid(obj?.privateStallsFee?.uuid),
          status: this.getBool(obj?.privateStallsFee?.status),
          duration: this.getString(obj?.privateStallsFee?.duration),
          available: this.getNum(obj?.privateStallsFee?.available),
          cost: this.getNum(obj?.privateStallsFee?.actualCostAlias, obj?.paymentOption),
          costAlias: `$${this.getNum(obj?.privateStallsFee?.actualCostAlias, obj?.paymentOption)}`,
          actualCostAlias: `$${this.getNum(obj?.privateStallsFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.privateStallsFee?.actualCostAlias),
        }
      : {
          status: false,
          category: 'stalls',
          title: 'Private stalls',
          sold: 0,
          available: 0,
          cost: this.getNum(0),
          duration: '',
          uuid: uuidv4(),
          note: this.getString(),
          costAlias: `$0`,
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.privateStallsFee?.actualCostAlias)}`,
        }

    this.circuitStallsFee = obj?.circuitStallsFee
      ? {
          category: 'stalls',
          note: this.getString(obj?.circuitStallsFee?.note),
          title: this.getString('Circuit stalls'),
          sold: this.getNum(obj?.circuitStallsFee?.sold),
          uuid: this.getUuid(obj?.circuitStallsFee?.uuid),
          status: this.getBool(obj?.circuitStallsFee?.status),
          duration: this.getString(obj?.circuitStallsFee?.duration),
          available: this.getNum(obj?.circuitStallsFee?.available),
          cost: this.getNum(obj?.circuitStallsFee?.actualCostAlias, obj?.paymentOption),
          costAlias: `$${this.getNum(obj?.circuitStallsFee?.actualCostAlias, obj?.paymentOption)}`,
          actualCostAlias: `$${this.getNum(obj?.circuitStallsFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.circuitStallsFee?.actualCostAlias),
        }
      : {
          status: false,
          category: 'stalls',
          title: 'Circuit stalls',
          sold: 0,
          available: 0,
          cost: this.getNum(0),
          duration: '',
          uuid: uuidv4(),
          note: this.getString(),
          costAlias: `$0`,
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.circuitStallsFee?.actualCostAlias)}`,
        }

    this.muckFee = obj?.muckFee
      ? {
          category: 'stalls',
          note: this.getString(obj?.muckFee?.note),
          title: this.getString('Muck Fee'),
          sold: this.getNum(obj?.muckFee?.sold),
          uuid: this.getUuid(obj?.muckFee?.uuid),
          status: this.getBool(obj?.muckFee?.status),
          duration: this.getString(obj?.muckFee?.duration),
          available: this.getNum(obj?.muckFee?.available),
          cost: this.getNum(obj?.muckFee?.actualCostAlias, obj?.paymentOption),
          costAlias: `$${this.getNum(obj?.muckFee?.actualCostAlias, obj?.paymentOption)}`,
          actualCostAlias: `$${this.getNum(obj?.muckFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.muckFee?.actualCostAlias),
        }
      : {
          status: false,
          category: 'stalls',
          title: 'Muck Fee',
          available: 0,
          sold: 0,
          cost: this.getNum(0),
          duration: '',
          uuid: uuidv4(),
          note: this.getString(),
          costAlias: `$0`,
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.muckFee?.actualCostAlias)}`,
        }

    this.extraStallFees = Array.isArray(obj?.extraStallFees)
      ? obj?.extraStallFees.map((curr) => {
          return {
            category: 'stalls',
            note: this.getString(curr?.note),
            title: this.getString(curr.title),
            sold: this.getNum(curr?.sold),
            uuid: this.getUuid(curr?.uuid),
            status: this.getBool(curr?.status),
            duration: this.getString(curr?.duration),
            available: this.getNum(curr?.available),
            cost: this.getNum(curr?.actualCostAlias, obj?.paymentOption),
            costAlias: `$${this.getNum(curr?.actualCostAlias, obj?.paymentOption)}`,
            actualCostAlias: `$${this.getNum(curr.actualCostAlias)}`,
            actualCost: this.getNum(curr?.actualCostAlias),
          }
        })
      : []

    this.registrationFees =
      Array.isArray(obj?.registrationFees) && !!obj?.registrationFees.length
        ? obj?.registrationFees.map((curr) => {
            return {
              uuid: this.getUuid(curr?.uuid),
              order: curr?.order ?? null,
              name: this.getUuid(curr?.name),
              note: this.getString(curr?.note),
              category: 'registrations',
              actualCost: this.getNum(curr?.actualCostAlias),
              actualCostAlias: `$${this.getNum(curr?.actualCostAlias)}`,
              cost: this.getNum(curr?.actualCostAlias, obj?.paymentOption),
              costAlias: `$${this.getNum(curr?.actualCostAlias, obj?.paymentOption)}`,
              increment: this.getUuid(curr?.increment),
              publishOrder: this.getBool(curr?.publishOrder),
              publishScore: this.getBool(curr?.publishScore),
              publishPrize: this.getBool(curr?.publishPrize),
              scoreCard: this.getString(curr?.scoreCard),
              judges: curr?.judges ?? [],
              prizeMoney: this.getNum(curr?.prizeMoney),
              isAsc: this.getBool(curr?.isAsc),
              qualifyingClass: curr?.qualifyingClass ?? '',
              children: curr?.children ?? [],
              location: curr?.location ?? '',
              startDate: this.utcTimestamp({
                key: 'startDate' as any,
                isTimestamp: true,
                value: curr?.startDate,
              }),
              startTimeHours: curr?.startTimeHours ?? '00',
              startTimeMinutes: curr?.startTimeMinutes ?? '00',
              startTimeFormat: curr?.startTimeFormat ?? 'AM',
              tag: curr?.tag ?? '',
            }
          })!
        : []

    this.beforeClosingDateRefund = obj?.beforeClosingDateRefund
      ? {
          category: this.getString('refund'),
          name: this.getString('Before closing date'),
          uuid: this.getUuid(obj?.beforeClosingDateRefund?.uuid),
          note: this.getString(obj?.beforeClosingDateRefund?.note),
          status: this.getBool(obj?.beforeClosingDateRefund?.status),
          percentage: this.getNum(obj?.beforeClosingDateRefund?.percentageAlias),
          percentageAlias: `${this.getNum(obj?.beforeClosingDateRefund?.percentageAlias)}%`,
        }
      : {
          status: false,
          uuid: uuidv4(),
          category: this.getString('refund'),
          name: this.getString('Before closing date'),
          percentage: 0,
          percentageAlias: '0%',
          note: '',
        }

    this.afterClosingDateRefund = obj?.afterClosingDateRefund
      ? {
          category: this.getString('refund'),
          name: this.getString('After closing date'),
          uuid: this.getUuid(obj?.afterClosingDateRefund?.uuid),
          note: this.getString(obj?.afterClosingDateRefund?.note),
          status: this.getBool(obj?.afterClosingDateRefund?.status),
          percentage: this.getNum(obj?.afterClosingDateRefund?.percentageAlias),
          percentageAlias: `${this.getNum(obj?.afterClosingDateRefund?.percentageAlias)}%`,
        }
      : {
          status: false,
          percentage: 0,
          percentageAlias: '0%',
          uuid: this.getUuid(),
          category: this.getString('refund'),
          name: this.getString('After closing date'),
          note: this.getString(''),
        }

    this.showCancellationRefundWeather = obj?.showCancellationRefundWeather
      ? {
          category: this.getString('refund'),
          name: this.getString('Show cancellation - weather'),
          uuid: this.getUuid(obj?.showCancellationRefundWeather?.uuid),
          note: this.getString(obj?.showCancellationRefundWeather?.note),
          status: this.getBool(obj?.showCancellationRefundWeather?.status),
          percentage: this.getNum(obj?.showCancellationRefundWeather?.percentageAlias),
          percentageAlias: `${this.getNum(obj?.showCancellationRefundWeather?.percentageAlias)}%`,
        }
      : {
          status: false,
          percentage: 0,
          percentageAlias: '0%',
          uuid: this.getUuid(),
          note: this.getString(),
          category: this.getString('refund'),
          name: this.getString('Show cancellation - weather'),
        }

    this.showCancellationRefundBioSecurity = obj?.showCancellationRefundBioSecurity
      ? {
          category: this.getString('refund'),
          name: this.getString('Show cancellation - biosecurity'),
          uuid: this.getUuid(obj?.showCancellationRefundBioSecurity?.uuid),
          note: this.getString(obj?.showCancellationRefundBioSecurity?.note),
          status: this.getBool(obj?.showCancellationRefundBioSecurity?.status),
          percentage: this.getNum(obj?.showCancellationRefundBioSecurity?.percentageAlias),
          percentageAlias: `${this.getNum(obj?.showCancellationRefundBioSecurity?.percentageAlias)}%`,
        }
      : {
          status: false,
          uuid: uuidv4(),
          name: 'Show cancellation - biosecurity',
          category: 'refund',
          percentage: 0,
          percentageAlias: '0%',
          note: this.getString(),
        }

    this.extraRefunds = Array.isArray(obj?.extraRefunds)
      ? obj?.extraRefunds.map((curr) => {
          return {
            category: this.getString('refund'),
            name: this.getString(curr?.name),
            uuid: this.getUuid(curr?.uuid),
            note: this.getString(curr?.note),
            status: this.getBool(curr?.status),
            percentage: this.getNum(curr?.percentageAlias),
            percentageAlias: `${this.getNum(curr?.percentageAlias)}%`,
          }
        })
      : []

    this.USEFFee = obj?.USEFFee
      ? {
          name: this.getString('Office fee'),
          category: this.getString('governance'),
          note: this.getString(obj?.USEFFee?.note),
          sold: this.getNum(obj?.USEFFee?.sold),
          uuid: this.getUuid(obj?.USEFFee?.uuid),
          status: this.getBool(obj?.USEFFee?.status),
          duration: this.getString(obj?.USEFFee?.duration),
          cost: this.getNum(obj?.USEFFee?.actualCostAlias),
          costAlias: `$${this.getNum(obj?.USEFFee?.actualCostAlias)}`,
          available: this.getNum(obj?.USEFFee?.available),
          actualCostAlias: `$${this.getNum(obj?.USEFFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.USEFFee?.actualCostAlias),
        }
      : {
          status: false,
          uuid: this.getUuid(),
          name: this.getString('Office fee'),
          category: this.getString('governance'),
          costAlias: '$0',
          cost: 0,
          available: 0,
          sold: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.USEFFee?.actualCostAlias)}`,
        }

    this.USEFDrugFee = obj?.USEFDrugFee
      ? {
          name: this.getString('Ship in / Ground fee'),
          category: this.getString('governance'),
          note: this.getString(obj?.USEFDrugFee?.note),
          sold: this.getNum(obj?.USEFDrugFee?.sold),
          uuid: this.getUuid(obj?.USEFDrugFee?.uuid),
          status: this.getBool(obj?.USEFDrugFee?.status),
          duration: this.getString(obj?.USEFDrugFee?.duration),
          cost: this.getNum(obj?.USEFDrugFee?.actualCostAlias),
          costAlias: `$${this.getNum(obj?.USEFDrugFee?.actualCostAlias)}`,
          available: this.getNum(obj?.USEFDrugFee?.available),
          actualCostAlias: `$${this.getNum(obj?.USEFDrugFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.USEFDrugFee?.actualCostAlias),
        }
      : {
          status: false,
          uuid: uuidv4(),
          name: this.getString('Ship in / Ground fee'),
          category: this.getString('governance'),
          costAlias: '$0',
          cost: 0,
          available: 0,
          sold: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.USEFDrugFee?.actualCostAlias)}`,
        }

    this.USEFShowPassFee = obj?.USEFShowPassFee
      ? {
          name: this.getString('Ambulance fee'),
          category: this.getString('governance'),
          note: this.getString(obj?.USEFShowPassFee?.note),
          sold: this.getNum(obj?.USEFShowPassFee?.sold),
          uuid: this.getUuid(obj?.USEFShowPassFee?.uuid),
          status: this.getBool(obj?.USEFShowPassFee?.status),
          duration: this.getString(obj?.USEFShowPassFee?.duration),
          cost: this.getNum(obj?.USEFShowPassFee?.actualCostAlias),
          costAlias: `$${this.getNum(obj?.USEFShowPassFee?.actualCostAlias)}`,
          available: this.getNum(obj?.USEFShowPassFee?.available),
          actualCostAlias: `$${this.getNum(obj?.USEFShowPassFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.USEFShowPassFee?.actualCostAlias),
        }
      : {
          status: false,
          uuid: uuidv4(),
          name: 'Ambulance fee',
          category: 'governance',
          costAlias: '$0',
          cost: 0,
          available: 0,
          sold: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.USEFShowPassFee?.actualCostAlias)}`,
        }

    this.USDFFee = obj?.USDFFee
      ? {
          name: this.getString('Night Watch'),
          category: this.getString('governance'),
          note: this.getString(obj?.USDFFee?.note),
          sold: this.getNum(obj?.USDFFee?.sold),
          uuid: this.getUuid(obj?.USDFFee?.uuid),
          status: this.getBool(obj?.USDFFee?.status),
          duration: this.getString(obj?.USDFFee?.duration),
          cost: this.getNum(obj?.USDFFee?.actualCostAlias),
          costAlias: `$${this.getNum(obj?.USDFFee?.actualCostAlias)}`,
          available: this.getNum(obj?.USDFFee?.available),
          actualCostAlias: `$${this.getNum(obj?.USDFFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.USDFFee?.actualCostAlias),
        }
      : {
          status: false,
          uuid: uuidv4(),
          name: this.getString('Night Watch'),
          category: this.getString('governance'),
          costAlias: '$0',
          cost: 0,
          available: 0,
          sold: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.USDFFee?.actualCostAlias)}`,
        }

    this.USDFShowPassFee = obj?.USDFShowPassFee
      ? {
          name: this.getString('USDF Show Pass fee'),
          category: this.getString('governance'),
          note: this.getString(obj?.USDFShowPassFee?.note),
          sold: this.getNum(obj?.USDFShowPassFee?.sold),
          uuid: this.getUuid(obj?.USDFShowPassFee?.uuid),
          status: this.getBool(obj?.USDFShowPassFee?.status),
          duration: this.getString(obj?.USDFShowPassFee?.duration),
          cost: this.getNum(obj?.USDFShowPassFee?.actualCostAlias),
          costAlias: `$${this.getNum(obj?.USDFShowPassFee?.actualCostAlias)}`,
          available: this.getNum(obj?.USDFShowPassFee?.available),
          actualCostAlias: `$${this.getNum(obj?.USDFShowPassFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.USDFShowPassFee?.actualCostAlias),
        }
      : {
          status: false,
          uuid: this.getUuid(),
          name: this.getString('USDF Show Pass fee'),
          category: this.getString('governance'),
          costAlias: '$0',
          cost: 0,
          available: 0,
          sold: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.USDFShowPassFee?.actualCostAlias)}`,
        }

    this.USEAFee = obj?.USEAFee
      ? {
          name: this.getString('USEA fee'),
          category: this.getString('governance'),
          note: this.getString(obj?.USEAFee?.note),
          sold: this.getNum(obj?.USEAFee?.sold),
          uuid: this.getUuid(obj?.USEAFee?.uuid),
          status: this.getBool(obj?.USEAFee?.status),
          duration: this.getString(obj?.USEAFee?.duration),
          cost: this.getNum(obj?.USEAFee?.actualCostAlias),
          costAlias: `$${this.getNum(obj?.USEAFee?.actualCostAlias)}`,
          available: this.getNum(obj?.USEAFee?.available),
          actualCostAlias: `$${this.getNum(obj?.USEAFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.USEAFee?.actualCostAlias),
        }
      : {
          status: false,
          uuid: this.getUuid(),
          name: this.getString('USEA fee'),
          category: this.getString('governance'),
          costAlias: '$0',
          cost: 0,
          available: 0,
          sold: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.USEAFee?.actualCostAlias)}`,
        }

    this.USEAShowPassFee = obj?.USEAShowPassFee
      ? {
          name: this.getString('USEA Show Pass fee'),
          category: this.getString('governance'),
          note: this.getString(obj?.USEAShowPassFee?.note),
          sold: this.getNum(obj?.USEAShowPassFee?.sold),
          uuid: this.getUuid(obj?.USEAShowPassFee?.uuid),
          status: this.getBool(obj?.USEAShowPassFee?.status),
          duration: this.getString(obj?.USEAShowPassFee?.duration),
          cost: this.getNum(obj?.USEAShowPassFee?.actualCostAlias),
          costAlias: `$${this.getNum(obj?.USEAShowPassFee?.actualCostAlias)}`,
          available: this.getNum(obj?.USEAShowPassFee?.available),
          actualCostAlias: `$${this.getNum(obj?.USEAShowPassFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.USEAShowPassFee?.actualCostAlias),
        }
      : {
          status: false,
          uuid: this.getUuid(),
          name: this.getString('USEA Show Pass fee'),
          category: this.getString('governance'),
          costAlias: '$0',
          cost: 0,
          available: 0,
          sold: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.USEAShowPassFee?.actualCostAlias)}`,
        }

    this.USHJAHoursFee = obj?.USHJAHoursFee
      ? {
          name: this.getString('USHJA Horse fee'),
          category: this.getString('governance'),
          note: this.getString(obj?.USHJAHoursFee?.note),
          sold: this.getNum(obj?.USHJAHoursFee?.sold),
          uuid: this.getUuid(obj?.USHJAHoursFee?.uuid),
          status: this.getBool(obj?.USHJAHoursFee?.status),
          duration: this.getString(obj?.USHJAHoursFee?.duration),
          cost: this.getNum(obj?.USHJAHoursFee?.actualCostAlias),
          costAlias: `$${this.getNum(obj?.USHJAHoursFee?.actualCostAlias)}`,
          available: this.getNum(obj?.USHJAHoursFee?.available),
          actualCostAlias: `$${this.getNum(obj?.USHJAHoursFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.USHJAHoursFee?.actualCostAlias),
        }
      : {
          status: false,
          uuid: this.getUuid(),
          name: this.getString('USHJA Horse fee'),
          category: this.getString('governance'),
          costAlias: '$0',
          cost: 0,
          available: 0,
          sold: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.USHJAHoursFee?.actualCostAlias)}`,
        }

    this.USHJAShowPassFee = obj?.USHJAShowPassFee
      ? {
          name: this.getString('USHJA Show Pass fee'),
          category: this.getString('governance'),
          note: this.getString(obj?.USHJAShowPassFee?.note),
          sold: this.getNum(obj?.USHJAShowPassFee?.sold),
          uuid: this.getUuid(obj?.USHJAShowPassFee?.uuid),
          status: this.getBool(obj?.USHJAShowPassFee?.status),
          duration: this.getString(obj?.USHJAShowPassFee?.duration),
          cost: this.getNum(obj?.USHJAShowPassFee?.actualCostAlias),
          costAlias: `$${this.getNum(obj?.USHJAShowPassFee?.actualCostAlias)}`,
          available: this.getNum(obj?.USHJAShowPassFee?.available),
          actualCostAlias: `$${this.getNum(obj?.USHJAShowPassFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.USHJAShowPassFee?.actualCostAlias),
        }
      : {
          status: false,
          uuid: this.getUuid(),
          name: this.getString('USHJA Show Pass fee'),
          category: this.getString('governance'),
          costAlias: '$0',
          cost: 0,
          available: 0,
          sold: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.USHJAShowPassFee?.actualCostAlias)}`,
        }

    this.extraGovernanceFees = Array.isArray(obj?.extraGovernanceFees)
      ? obj?.extraGovernanceFees.map((curr) => {
          return {
            name: this.getString(curr.name),
            category: this.getString('governance'),
            note: this.getString(curr?.note),
            sold: this.getNum(curr?.sold),
            uuid: this.getUuid(curr?.uuid),
            status: this.getBool(curr?.status),
            duration: this.getString(curr?.duration),
            cost: this.getNum(curr?.actualCostAlias),
            costAlias: `$${this.getNum(curr?.actualCostAlias)}`,
            available: this.getNum(curr?.available),
            actualCostAlias: `$${this.getNum(curr?.actualCostAlias)}`,
            actualCost: this.getNum(curr?.actualCostAlias),
          }
        })
      : []

    this.paddockFee = obj?.paddockFee
      ? {
          title: this.getString('Paddock'),
          category: this.getString('other'),
          note: this.getString(obj?.paddockFee?.note),
          sold: this.getNum(obj?.paddockFee?.sold),
          uuid: this.getUuid(obj?.paddockFee?.uuid),
          status: this.getBool(obj?.paddockFee?.status),
          duration: this.getString(obj?.paddockFee?.duration),
          cost: this.getNum(obj?.paddockFee?.actualCostAlias, obj?.paymentOption),
          costAlias: `$${this.getNum(obj?.paddockFee?.actualCostAlias, obj?.paymentOption)}`,
          available: this.getNum(obj?.paddockFee?.available),
          actualCostAlias: `$${this.getNum(obj?.paddockFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.paddockFee?.actualCostAlias),
        }
      : {
          status: false,
          uuid: this.getUuid(),
          title: this.getString('Paddock'),
          category: this.getString('other'),
          available: 0,
          sold: 0,
          costAlias: '$0',
          cost: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.paddockFee?.actualCostAlias)}`,
        }

    this.RVHookupFee = obj?.RVHookupFee
      ? {
          title: this.getString('RV hookup'),
          category: this.getString('other'),
          note: this.getString(obj?.RVHookupFee?.note),
          sold: this.getNum(obj?.RVHookupFee?.sold),
          uuid: this.getUuid(obj?.RVHookupFee?.uuid),
          status: this.getBool(obj?.RVHookupFee?.status),
          duration: this.getString(obj?.RVHookupFee?.duration),
          cost: this.getNum(obj?.RVHookupFee?.actualCostAlias, obj?.paymentOption),
          costAlias: `$${this.getNum(obj?.RVHookupFee?.actualCostAlias, obj?.paymentOption)}`,
          available: this.getNum(obj?.RVHookupFee?.available),
          actualCostAlias: `$${this.getNum(obj?.RVHookupFee?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.RVHookupFee?.actualCostAlias),
        }
      : {
          status: false,
          uuid: uuidv4(),
          category: 'other',
          title: 'RV hookup',
          available: 0,
          sold: 0,
          costAlias: '$0',
          cost: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.RVHookupFee?.actualCostAlias)}`,
        }

    // this.shipInGroundFee = obj?.shipInGroundFee
    //   ? {
    //       title: this.getString('Ship in / Ground fee'),
    //       category: this.getString('other'),
    //       sold: this.getNum(obj?.shipInGroundFee?.sold),
    //       note: this.getString(obj?.shipInGroundFee?.note),
    //       uuid: this.getUuid(obj?.shipInGroundFee?.uuid),
    //       status: this.getBool(obj?.shipInGroundFee?.status),
    //       duration: this.getString(obj?.shipInGroundFee?.duration),
    //       cost: this.getNum(obj?.shipInGroundFee?.actualCostAlias, obj?.paymentOption),
    //       costAlias: `$${this.getNum(obj?.shipInGroundFee?.actualCostAlias, obj?.paymentOption)}`,
    //       available: this.getNum(obj?.shipInGroundFee?.available),
    //       actualCostAlias: `$${this.getNum(obj?.shipInGroundFee?.actualCostAlias)}`,
    //       actualCost: this.getNum(obj?.shipInGroundFee?.actualCostAlias),
    //     }
    //   : {
    //       status: false,
    //       uuid: this.getUuid(),
    //       title: this.getString('Ship in / Ground fee'),
    //       category: this.getString('other'),
    //       available: 0,
    //       sold: 0,
    //       costAlias: '$0',
    //       cost: 0,
    //       duration: '',
    //       note: this.getString(),
    //       actualCost: 0,
    //       actualCostAlias: `$${this.getNum(obj?.shipInGroundFee?.actualCostAlias)}`,
    //     }

    this.extraOtherFees = Array.isArray(obj?.extraOtherFees)
      ? obj?.extraOtherFees.map((curr) => {
          return {
            title: this.getString(curr?.title),
            category: this.getString('other'),
            sold: this.getNum(curr?.sold),
            note: this.getString(curr?.note),
            uuid: this.getUuid(curr?.uuid),
            status: this.getBool(curr?.status),
            duration: this.getString(curr?.duration),
            cost: this.getNum(curr?.actualCostAlias, obj?.paymentOption),
            costAlias: `$${this.getNum(curr?.actualCostAlias, obj?.paymentOption)}`,
            available: this.getNum(curr?.available),
            actualCostAlias: `$${this.getNum(curr?.actualCostAlias)}`,
            actualCost: this.getNum(curr?.actualCostAlias),
          }
        })
      : []

    this.shavingFees = obj?.shavingFees
      ? {
          title: this.getString('Shaving'),
          category: this.getString('bedding'),
          sold: this.getNum(obj?.shavingFees?.sold),
          note: this.getString(obj?.shavingFees?.note),
          uuid: this.getUuid(obj?.shavingFees?.uuid),
          status: this.getBool(obj?.shavingFees?.status),
          duration: this.getString(obj?.shavingFees?.duration),
          cost: this.getNum(obj?.shavingFees?.actualCostAlias, obj?.paymentOption),
          costAlias: `$${this.getNum(obj?.shavingFees?.actualCostAlias, obj?.paymentOption)}`,
          available: this.getNum(obj?.shavingFees?.available),
          actualCostAlias: `$${this.getNum(obj?.shavingFees?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.shavingFees?.actualCostAlias),
        }
      : {
          status: false,
          uuid: this.getUuid(),
          title: this.getString('Shaving'),
          category: this.getString('bedding'),
          available: 0,
          sold: 0,
          costAlias: '$0',
          cost: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.shavingFees?.actualCostAlias)}`,
        }

    this.strawFees = obj?.strawFees
      ? {
          title: this.getString('Straw'),
          category: this.getString('bedding'),
          sold: this.getNum(obj?.strawFees?.sold),
          note: this.getString(obj?.strawFees?.note),
          uuid: this.getUuid(obj?.strawFees?.uuid),
          status: this.getBool(obj?.strawFees?.status),
          duration: this.getString(obj?.strawFees?.duration),
          cost: this.getNum(obj?.strawFees?.actualCostAlias, obj?.paymentOption),
          costAlias: `$${this.getNum(obj?.strawFees?.actualCostAlias, obj?.paymentOption)}`,
          available: this.getNum(obj?.strawFees?.available),
          actualCostAlias: `$${this.getNum(obj?.strawFees?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.strawFees?.actualCostAlias),
        }
      : {
          status: false,
          uuid: this.getUuid(),
          title: this.getString('Straw'),
          category: this.getString('bedding'),
          available: 0,
          sold: 0,
          costAlias: '$0',
          cost: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.strawFees?.actualCostAlias)}`,
        }

    this.extraBeddingFees = Array.isArray(obj?.extraBeddingFees)
      ? obj?.extraBeddingFees.map((curr) => {
          return {
            title: this.getString(curr?.title),
            category: this.getString('bedding'),
            sold: this.getNum(curr?.sold),
            uuid: this.getUuid(curr?.uuid),
            note: this.getString(curr?.note),
            status: this.getBool(curr?.status),
            duration: this.getString(curr?.duration),
            cost: this.getNum(curr?.actualCostAlias, obj?.paymentOption),
            costAlias: `$${this.getNum(curr?.actualCostAlias, obj?.paymentOption)}`,
            available: this.getNum(curr?.available),
            actualCostAlias: `$${this.getNum(curr?.actualCostAlias)}`,
            actualCost: this.getNum(curr?.actualCostAlias),
          }
        })
      : []

    this.coastalHay = obj?.coastalHay
      ? {
          title: this.getString('Coastal hay'),
          category: this.getString('feed'),
          sold: this.getNum(obj?.coastalHay?.sold),
          uuid: this.getUuid(obj?.coastalHay?.uuid),
          note: this.getString(obj?.coastalHay?.note),
          status: this.getBool(obj?.coastalHay?.status),
          duration: this.getString(obj?.coastalHay?.duration),
          cost: this.getNum(obj?.coastalHay?.actualCostAlias, obj?.paymentOption),
          costAlias: `$${this.getNum(obj?.coastalHay?.actualCostAlias, obj?.paymentOption)}`,
          available: this.getNum(obj?.coastalHay?.available),
          actualCostAlias: `$${this.getNum(obj?.coastalHay?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.coastalHay?.actualCostAlias),
        }
      : {
          status: false,
          uuid: uuidv4(),
          category: 'feed',
          title: 'Coastal hay',
          available: 0,
          sold: 0,
          costAlias: '$0',
          cost: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.coastalHay?.actualCostAlias)}`,
        }

    this.timothyHay = obj?.timothyHay
      ? {
          title: this.getString('Timothy hay'),
          category: this.getString('feed'),
          sold: this.getNum(obj?.timothyHay?.sold),
          uuid: this.getUuid(obj?.timothyHay?.uuid),
          note: this.getString(obj?.timothyHay?.note),
          status: this.getBool(obj?.timothyHay?.status),
          duration: this.getString(obj?.timothyHay?.duration),
          cost: this.getNum(obj?.timothyHay?.actualCostAlias, obj?.paymentOption),
          costAlias: `$${this.getNum(obj?.timothyHay?.actualCostAlias, obj?.paymentOption)}`,
          available: this.getNum(obj?.timothyHay?.available),
          actualCostAlias: `$${this.getNum(obj?.timothyHay?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.timothyHay?.actualCostAlias),
        }
      : {
          status: false,
          uuid: this.getUuid(),
          title: this.getString('Timothy hay'),
          category: this.getString('feed'),
          available: 0,
          sold: 0,
          costAlias: '$0',
          cost: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.timothyHay?.actualCostAlias)}`,
        }

    this.alfalfa = obj?.alfalfa
      ? {
          title: this.getString('Alfalfa hay'),
          category: this.getString('feed'),
          sold: this.getNum(obj?.alfalfa?.sold),
          uuid: this.getUuid(obj?.alfalfa?.uuid),
          note: this.getString(obj?.alfalfa?.note),
          status: this.getBool(obj?.alfalfa?.status),
          duration: this.getString(obj?.alfalfa?.duration),
          cost: this.getNum(obj?.alfalfa?.actualCostAlias, obj?.paymentOption),
          costAlias: `$${this.getNum(obj?.alfalfa?.actualCostAlias, obj?.paymentOption)}`,
          available: this.getNum(obj?.alfalfa?.available),
          actualCostAlias: `$${this.getNum(obj?.alfalfa?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.alfalfa?.actualCostAlias),
        }
      : {
          status: false,
          uuid: this.getUuid(),
          title: this.getString('Alfalfa hay'),
          category: this.getString('feed'),
          available: 0,
          sold: 0,
          costAlias: '$0',
          cost: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.alfalfa?.actualCostAlias)}`,
        }

    this.TnA = obj?.TnA
      ? {
          title: this.getString('TnA hay'),
          category: this.getString('feed'),
          sold: this.getNum(obj?.TnA?.sold),
          uuid: this.getUuid(obj?.TnA?.uuid),
          note: this.getString(obj?.TnA?.note),
          status: this.getBool(obj?.TnA?.status),
          duration: this.getString(obj?.TnA?.duration),
          cost: this.getNum(obj?.TnA?.actualCostAlias, obj?.paymentOption),
          costAlias: `$${this.getNum(obj?.TnA?.actualCostAlias, obj?.paymentOption)}`,
          available: this.getNum(obj?.TnA?.available),
          actualCostAlias: `$${this.getNum(obj?.TnA?.actualCostAlias)}`,
          actualCost: this.getNum(obj?.TnA?.actualCostAlias),
        }
      : {
          status: false,
          uuid: this.getUuid(),
          title: this.getString('TnA hay'),
          category: this.getString('feed'),
          available: 0,
          sold: 0,
          costAlias: '$0',
          cost: 0,
          duration: '',
          note: this.getString(),
          actualCost: 0,
          actualCostAlias: `$${this.getNum(obj?.TnA?.actualCostAlias)}`,
        }

    this.extraFeedFees = Array.isArray(obj?.extraFeedFees)
      ? obj?.extraFeedFees.map((curr) => {
          return {
            title: this.getString(curr?.title),
            category: this.getString('feed'),
            sold: this.getNum(curr?.sold),
            uuid: this.getUuid(curr?.uuid),
            note: this.getString(curr?.note),
            status: this.getBool(curr?.status),
            duration: this.getString(curr?.duration),
            cost: this.getNum(curr?.actualCostAlias, obj?.paymentOption),
            costAlias: `$${this.getNum(curr?.actualCostAlias, obj?.paymentOption)}`,
            available: this.getNum(curr?.available),
            actualCostAlias: `$${this.getNum(curr?.actualCostAlias)}`,
            actualCost: this.getNum(curr?.actualCostAlias),
          }
        })
      : []

    this.prizeListFees = obj?.prizeListFees ?? CONFIG.EVENT.FEES.FORM_BUILDER.TEMPLATE.SAMPLE.data

    this.registrationLimit = obj?.registrationLimit ?? { isEnable: false, spots: null }
    this.rideTimeRequirement = obj?.rideTimeRequirement ?? { isEnable: false, minutes: null }
    this.qualifyingClasses = obj?.qualifyingClasses ?? { isEnable: false, items: [] }

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  getBool(bool: any) {
    if (typeof bool !== 'boolean') return false
    return bool
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  getUuid(uuid?: any) {
    let uuid_
    if (typeof uuid !== 'string') uuid_ = uuidv4()
    else uuid_ = uuid
    uuid_ = uuid_.trim()
    return uuid_
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  getString(str?: any) {
    let strToReturn

    if (typeof str === 'object') strToReturn = selectObjToString(str)
    else if (typeof str !== 'string') strToReturn = ''
    else strToReturn = str

    strToReturn = strToReturn.trim()
    return strToReturn
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  getNum(number: any, paymentOption?: IEventFees['paymentOption']) {
    let incrementBy = 0

    switch (paymentOption) {
      case 'IncreaseAllPrices':
        incrementBy = 0.05
        break

      case 'ChargeRidersAndSpectators':
        incrementBy = 0.029
        break
    }

    if (!number) return 0

    let num

    if (typeof number === 'string') num = Number(number.replace(/[^0-9\..]+/gi, ''))
    else num = Number(number)

    if (isNaN(num)) return 0 + (incrementBy ? incrementBy : 0)

    num = num + num * (incrementBy ? incrementBy : 0)

    return Number(num?.toFixed(1))
  }

  static fromFirestoreDoc(doc: any) {
    return new EventFeesModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  static validationSchema() {
    return yup.object().shape({
      registrationFees: yup.array(
        yup.object().shape({
          name: yup.string().required('Name is a required'),
          children: yup.array(
            yup.object().shape({
              name: yup.string().required('Name is a required'),
            })
          ),
        })
      ),
    })
  }

  static validationSchemaScheduling() {
    return yup.object().shape({
      registrationFees: yup.array(
        yup.object().shape({
          actualCostAlias: yup.string().required('Price is a required'),
          name: yup.string().required('Name is a required'),
          note: yup.string().required('Note is a required'),
          children: yup.array(
            yup.object().shape({
              actualCostAlias: yup.string().required('Price is a required'),
              name: yup.string().required('Name is a required'),
              note: yup.string().required('Note is a required'),
            })
          ),
          location: yup
            .string()
            .transform((value) => {
              return value.trim()
            })
            .required('Location is a required'),
          tag: yup
            .string()
            .transform((value) => {
              return value.trim()
            })
            .required('Tag is a required'),
          startDate: yup
            .date()
            .nullable()
            .transform((value, originalValue) => {
              if (!moment(originalValue).isValid()) return null
              return value
            })
            .typeError('Entered date must be valid')
            .required('Day is a required field'),
        })
      ),
    })
  }
}
