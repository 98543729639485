// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useEffect, useState } from 'react'
import EventFormContainerComponent from '../container/EventFormContainerComponent'
import EventPoliciesFormElement from './EventPoliciesFormElement'
import EventPoliciesFormModal from './EventPoliciesFormModal'

import { yupResolver } from '@hookform/resolvers/yup'

import { useFieldArray, useForm } from 'react-hook-form'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { EventPoliciesModel } from '../../../../models/event-policies/event-policies.model'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const eventPoliciesFormDefaultValues = new EventPoliciesModel().toObject()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const eventPoliciesFormValidationSchema = EventPoliciesModel.validationSchema()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventPoliciesFormComponentProps {
  data: any
  onValid: any
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  handleModal?: any
  dataToPassOn?: any
  nextEventTab?: string
  eventTab?: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPoliciesFormComponent: React.FC<EventPoliciesFormComponentProps> = (props) => {
  const [modal, showModal] = useState<boolean>(false)

  const { handleSubmit, register, trigger, reset, control } = useForm({
    defaultValues: { ...eventPoliciesFormDefaultValues },
    resolver: yupResolver(eventPoliciesFormValidationSchema),
    mode: 'onChange',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'extra',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const hidePolicyModal = () => {
    showModal(false)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (props.data) {
      reset(props.data)
    } else {
      reset(eventPoliciesFormDefaultValues)
    }
    trigger().then()
  }, [props.data, reset, trigger])

  useEffect(() => {
    if (props.dataToPassOn?.description) {
      append({
        name: props?.dataToPassOn?.tittle,
        policy: props?.dataToPassOn?.description,
      })

      if (Object.keys(props.dataToPassOn).length)
        props.handleModal(false, MODAL_CONSTS.ADD_POLICIES_MODAL, {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataToPassOn])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <EventFormContainerComponent>
      <>
        <EventPoliciesFormModal show={modal} onHide={hidePolicyModal}>
          <h2 className="m-0 text-[25px] font-normal mt-[-1rem] text-SeabiscuitDark200ThemeColor">
            Add Policy
          </h2>
          <div className="text-center m-1 mt-4">
            <input
              type="text"
              placeholder="Name of policy"
              className="rounded-xl w-full border-1 border-[#D3DAEE]"
            />
            <textarea
              placeholder="Policy details"
              className="rounded-xl w-full border-1 border-[#D3DAEE] mt-4 h-[400px]"
            ></textarea>
            <button className="rounded-lg bg-SeabiscuitMainThemeColor text-white w-[250px] h-9 mt-4">
              Save
            </button>
          </div>
        </EventPoliciesFormModal>
        <div className="w-full flex-1 m-1 pb-4">
          <EventFormHeaderComponent
            title="Policies"
            description="Add policies that will display on your event page"
          >
            <EventFormFooterCommonComponent
              eventTab={props.eventTab}
              nextEventTab={props.nextEventTab}
              onNext={(e, publishEvent) => {
                handleSubmit(
                  (data) =>
                    publishEvent({
                      dataToSave: data,
                      tabName: 'EventPolicies',
                      validFormHandler: props.onValid,
                    }),
                  props.onInvalid
                )(e)
              }}
              onSaveAndExit={(e, publishEvent) => {
                handleSubmit(
                  (data) =>
                    publishEvent({
                      dataToSave: data,
                      publish: false,
                      tabName: 'EventPolicies',
                      validFormHandler: props.onValid,
                    }),
                  props.onInvalid
                )(e)
              }}
            />
          </EventFormHeaderComponent>

          <EventPoliciesFormElement
            title="Attire/Tack"
            placeholder="Enter Attire/Tack information relevant to exhibitors and/or spectators..."
            hint="Enter Attire/Tack information"
            name={'attireTack'}
            register={register}
          />
          <EventPoliciesFormElement
            title="Event rules"
            placeholder="Class division rules, entry requirements, etc."
            hint="Class division rules"
            name={'eventRules'}
            register={register}
          />
          <EventPoliciesFormElement
            title="Order of go"
            placeholder="Enter Order of Go information relevant to exhibitors and/or spectators..."
            hint="Enter Order of Go information"
            name={'orderOfGo'}
            register={register}
          />
          <EventPoliciesFormElement
            title="Parking"
            placeholder="Enter Parking information relevant to exhibitors and/or spectators..."
            hint="Enter parking instructions. Pricing will be entered later."
            name={'parking'}
            register={register}
          />
          <EventPoliciesFormElement
            title="Ribbons"
            placeholder="Enter Ribbons information relevant to exhibitors and/or spectators..."
            hint="Enter Ribbons information"
            name={'ribbons'}
            register={register}
          />
          <EventPoliciesFormElement
            title="Rv’S"
            placeholder="Enter RV information relevant to exhibitors and/or spectators..."
            hint="Enter RV information"
            name={'rvs'}
            register={register}
          />
          <EventPoliciesFormElement
            title="Substitutions"
            placeholder="Enter Substitution information relevant to exhibitors and/or spectators..."
            hint="Enter Substitution information"
            name={'substitutions'}
            register={register}
          />

          {fields.map((field, index) => (
            <div key={field.id}>
              <>
                <div className="flex mt-2 gap-2 w-full mx-auto">
                  <div className="flex items-center w-full justify-between">
                    <input
                      type="text"
                      className="text-[14px] text-SeabiscuitDark200ThemeColor w-[250px] bg-SeabiscuitGrayThemeColor rounded-md outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 capitalize"
                      placeholder="Policy Title"
                      {...register(`extra.${index}.name`)}
                    />
                    <button
                      onClick={() => {
                        props.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                          removeItem: function () {
                            remove(index)
                          },
                        })
                      }}
                    >
                      {/* <img src="/assets/og_icons/Close.svg" className="w-6" alt="close icon" /> */}
                      <div className="cursor-pointer">
                        <img
                          src="/assets/og_icons/Cancel.svg"
                          className="h-[24px] w-[24px]"
                          alt="Cancel"
                        />
                      </div>
                    </button>
                  </div>
                </div>
                <textarea
                  className="bg-SeabiscuitGrayThemeColor mt-1 text-SeabiscuitDark200ThemeColor rounded w-full h-40 text-base EventDetailFormComponent__input  placeholder:text-[16px] placeholder:font-light placeholder:text-gray-400 focus:border-solid focus:ring-0 focus:border-SeabiscuitMainThemeColor border capitalize"
                  placeholder={'Enter Policy'}
                  {...register(`extra.${index}.policy`)}
                />
              </>

              {/*<EventPoliciesFormElement*/}
              {/*    title="Hello"*/}
              {/*    placeholder="Enter Substitution information relevant to exhibitors and/or spectators..."*/}
              {/*    hint="Enter Substitution information"*/}
              {/*    name={'Hello'}*/}
              {/*    register={register}*/}
              {/*/>*/}
            </div>
          ))}

          <div className="flex justify-between">
            <button
              className="text-[#8676FF] flex items-center gap-2 text-xs font-[400] mt-2"
              onClick={() => {
                props.handleModal(true, MODAL_CONSTS.ADD_POLICIES_MODAL)
              }}
            >
              <img src={`/assets/og_icons/Cancel-5.svg`} className=" rotate-45" alt="plus icon" />
              {/* <img src={PlusIcon} alt="plus icon" /> */}
              Add Policy
            </button>

            <button
              onClick={() => {
                remove()
                reset({ ...eventPoliciesFormDefaultValues })
              }}
              className="my-3 text-SeabiscuitDark200ThemeColor font-[400] text-[12px] flex items-center gap-2"
            >
              <span>Clear All</span>
            </button>
          </div>

          {/*<button className="flex items-center gap-1 mt-4 text-[#8676FF] text-sm font-medium" onClick={showPolicyModal}>*/}
          {/*    <img src={PlusIcon} alt="plus icon" />*/}
          {/*    Add policy*/}
          {/*</button>*/}
        </div>
      </>
    </EventFormContainerComponent>
  )
}

export default EventPoliciesFormComponent
