// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import DrawerComponent from './Components/DrawerComponent'
import USEFIcon from './assets/usef-icon.svg'
import GlobalButton from './Components/GlobalButton'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const SendPrizeListUSEFModal: React.FC<{
  show: boolean
  onHide: () => void
  onSend: () => void
}> = ({ show, onHide, onSend }) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <DrawerComponent show={show} onHide={onHide} title="" maxWidth={580}>
      <div className="text-center text-SeabiscuitDark200ThemeColor mt-[50px]">
        <div className="w-[280px] h-[280px] rounded-full overflow-hidden mx-auto">
          <img src={USEFIcon} alt="success" className="w-full h-full object-cover" />
        </div>
        <div className="text-[25px] max-w-[360px] mx-auto">
          <p>Click below to send your prize list to the USEF Show Management Team for review</p>
        </div>
        <div className="flex items-center gap-2 justify-center my-[80px]">
          <GlobalButton
            caption="Send"
            type={1}
            arrowIcon={true}
            className="max-w-[260px]"
            onClick={onSend}
          />
        </div>
      </div>
    </DrawerComponent>
  )
}
export default SendPrizeListUSEFModal
