import { useParams } from 'react-router'
import { useEffect, useState } from 'react'

import EventScoringFormComponent from '../../../components/events/forms/scoring/EventScoringFormComponent'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { EventScoringModel } from '../../../models/event-scoring/event-scoring.model'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface ShowsDetailPageEventScoringFormSubSectionProps {
  onSetEventTab: (tab: string) => void
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsDetailPageEventScoringFormSubSection: React.FC<
  ShowsDetailPageEventScoringFormSubSectionProps
> = () => {
  const { eventId } = useParams<{ eventId: string }>()

  const [data, setData] = useState<any>(null)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const validFormHandler = async (data: any) => {
    const updated_data = new EventScoringModel({
      ...data,
    })

    FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_SCORING.NAME,
      eventId,
      updated_data.toFirestore()
    )
      .then((_: any) => {
        setData(updated_data.toObject())
      })
      .catch((reason) => {
        alert('202206261334')
        console.error('202206261334:' + reason)
      })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidFormHandler = async (data: any) => {
    alert('invalid')
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        const doc = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_SCORING.NAME,
          eventId
        )
        if (doc) {
          setData(EventScoringModel.fromFirestoreDoc(doc).toObject())
        }
      }
      fetchData().catch()
    }
  }, [data, setData, eventId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <EventScoringFormComponent
        data={data}
        onValid={validFormHandler}
        onInvalid={invalidFormHandler}
      />
    </>
  )
}

export default ShowsDetailPageEventScoringFormSubSection
