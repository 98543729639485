import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'

// Third party
import { where } from 'firebase/firestore'
import { useIonRouter } from '@ionic/react'

// Constants
import { CONST } from '../../../const/const'

// Components
import ExhibitorProfileHeadSection from './ExhibitorProfileHeadSection'
import ExhibitorProfilePageWrapper from './ExhibitorProfilePageWrapper'
import ExhibitorProfileDisplayTabNew from './ExhibitorProfileDisplayTabNew'

// Models
import { TEventRegisteredUsers } from '../../../models/event-registered-users/event-registered-users.interface'
import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'
import { UserModel } from '../../../models/users/user.model'

// Redux
import {
  setScratchEventId,
  setScratchRegistrationId,
  setScratchViewVisibility,
} from '../../../store/events/eventsSlice'
import {
  getRecipientThunk,
  resetRecipientAc,
  selectExhibitorDetails,
  selectRecipientR,
  selectRegistrationR,
  setExhibitorDetails,
  setRegistration,
  updateRecipentRefundStatusThunk,
} from '../../../store/exhibitor/exhibitorSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { holdersSliceInitialState } from '../../../store/holders/holdersSlice.data'

// Helpers
import { getConvertedData } from '../../../models/interface.helper'

// Hooks
import useToasterHelper from '../../../helpers/ToasterHelper'
import { CustomError } from '../../../helpers/helpers'
import FirestoreService from '../../../services/firestoreService'

import helpers from '../../../commonHelpers/helpers'
import { MESSAGES_CONST } from '../../../const/messages-const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type Props = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}

type IUserParams = {
  userId: string
  eventId: string
  registrationId: string
}

// Constants

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const FILE_NAME = 'ExhibitorProfileRevenuePage'

const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ExhibitorProfileRevenuePage = (props: Props) => {
  // Hooks and vars
  const Router = useIonRouter()
  const dispatch = useAppDispatch()
  const toastFunction = useToasterHelper()

  const { userId: recipientId, registrationId, eventId } = useParams<IUserParams>()
  const isrecipient = Router.routeInfo.search ? Router.routeInfo.search.split('=')[1] : 'false'

  const [profileLoading, setProfileLoading] = useState(false)
  const [showScratchedView, setShowScratchedView] = useState(false)
  const [loading, setLoading] = useState(true)

  const activeTab = useState<string>(CONST.UI.EXHIBITOR.TABS.CLASSES.VALUE)[0]

  const holderRef = useRef(holdersSliceInitialState)

  const recipientR = useAppSelector(selectRecipientR)
  const holder = useAppSelector((state) => state.holder)
  const registrationR = useAppSelector(selectRegistrationR)
  const recipient = useAppSelector(selectExhibitorDetails)

  const uiLoading = profileLoading || recipientR.status === 'loading'

  useEffect(() => {
    holderRef.current = holder
  }, [holder])

  useEffect(() => {
    return () => {
      dispatch(resetRecipientAc())
      dispatch(setExhibitorDetails(null))
    }
  }, [dispatch])

  const getRegistration = async () => {
    setLoading(true)

    let registeredUser: TEventRegisteredUsers | null = null

    const registeredUsersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', eventId),
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY, '==', recipientId),
      ]
    )

    registeredUsersSnaps.docs.forEach((currDoc) => {
      registeredUser = getConvertedData(
        EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject()
      )
    })

    if (registeredUser) dispatch(setRegistration(registeredUser))

    setLoading(false)
  }

  useEffect(() => {
    dispatch(setScratchEventId(eventId))
    dispatch(setScratchRegistrationId(registrationId))
    getRegistration()
  }, [eventId, registrationId])

  useEffect(() => {
    if (recipientId) {
      setProfileLoading(true)
      getUserData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientId])

  useEffect(() => {
    const registratioIdNRecipietIdPresent = registrationId && recipientId
    const recipientIsDifferent = recipientR.data?.recipientId !== recipientId
    const allowDispatch = registratioIdNRecipietIdPresent && recipientIsDifferent

    if (allowDispatch) {
      dispatch(
        getRecipientThunk({
          recipientId,
          registrationId,
        })
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationId, recipientId, recipient])

  useEffect(() => {
    if (recipientR.data?.refundStatus === 'pending' && recipientR.data.id) {
      dispatch(
        updateRecipentRefundStatusThunk({
          recipientDocId: recipientR.data.id,
        })
      )
    }
  }, [dispatch, recipientR.data])

  // Functions
  const handleScratchView = (visible: boolean) => {
    dispatch(setScratchViewVisibility(visible))
    setShowScratchedView(visible)
  }

  async function getUserData() {
    try {
      const userSnapshot = await FirestoreService.getItem(COLLECTIONS.USERS.NAME, recipientId)

      if (!userSnapshot.exists())
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'getUserData',
          devMessage: `recipient with id: ${recipientId} does not exist`,
        })
      else {
        let user = UserModel.fromFirestoreDoc(userSnapshot).toObject()

        dispatch(setExhibitorDetails(getConvertedData(user)))
      }
    } catch (err) {
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      helpers.logger({
        isError: true,
        message: err,
      })
    } finally {
      setProfileLoading(false)
    }
  }

  if (recipientR.status === 'failed') {
    return (
      <ExhibitorProfilePageWrapper
        title="Back"
        link={`${CONST.ROUTES.MANAGE.REVENUE.SUB_URLS.REGISTRATION.URL}/${eventId}`}
      >
        <div className="p-4 text-sm text-red-600 rounded-lg bg-red-50 font-bold" role="alert">
          {recipientR.message}
        </div>
      </ExhibitorProfilePageWrapper>
    )
  }

  return (
    <>
      <ExhibitorProfilePageWrapper
        title="Back"
        link={`${CONST.ROUTES.MANAGE.REVENUE.SUB_URLS.REGISTRATION.URL}/${eventId}`}
      >
        <ExhibitorProfileHeadSection
          backUrl={`${CONST.ROUTES.MANAGE.REVENUE.SUB_URLS.REGISTRATION.URL}/${eventId}`}
          isRefund={true}
          loading={uiLoading}
          activeTab={activeTab}
          exhibitor={recipient}
          showScratchedView={false}
          recipient={recipientR.data}
          handleModal={props.handleModal}
          exhibitorAllData={registrationR}
          handleScratchView={handleScratchView}
        />
        {!uiLoading || loading ? (
          <>
            <div className="my-4 border-t border-solid border-[#D3DAEE]"></div>
            <ExhibitorProfileDisplayTabNew
              isRefund={true}
              isRecipient={isrecipient === 'true'}
              userId={recipientId}
              registrationId={registrationId}
              eventId={eventId}
              exhibitor={recipient}
              eventTab={activeTab}
              handleModal={props.handleModal}
              exhibitorAllData={registrationR}
              showScratchedView={showScratchedView}
              handleScratchView={handleScratchView}
            />
          </>
        ) : null}
      </ExhibitorProfilePageWrapper>
    </>
  )
}

export default ExhibitorProfileRevenuePage
