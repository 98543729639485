// 2023 Introductory through Fourth Level (SCORE SHEET 1)
import {
  Sheet2023USDFIntroductoryLevelTestAWalkTrot,
  Sheet2023USDFIntroductoryLevelTestB,
  Sheet2023USDFIntroductoryLevelTestCWalkTrotCanter,
  Sheet2023USEFTrainingLevelTest1,
  Sheet2023USEFTrainingLevelTest2,
  Sheet2023USEFTrainingLevelTest3,
  Sheet2023USEFFirstLevelTest1,
  Sheet2023USEFFirstLevelTest2,
  Sheet2023USEFFirstLevelTest3,
  Sheet2023USEFSecondLevelTest1,
  Sheet2023USEFSecondLevelTest2,
  Sheet2023USEFSecondLevelTest3,
  Sheet2023USEFThirdLevelTest1,
  Sheet2023USEFThirdLevelTest2,
  Sheet2023USEFThirdLevelTest3,
  Sheet2023USEFFourthLevelTest1,
  Sheet2023USEFFourthLevelTest2,
  Sheet2023USEFFourthLevelTest3,
} from './2023-introductory-through-fourth-level'

// 2023 Freestyle (SCORE SHEET 2)
import {
  Sheet2023USDFTrainingLevelFreestyle,
  Sheet2023USDF1stLevelFreestyle,
  Sheet2023USDF2stLevelFreestyle,
  Sheet2023USDF3stLevelFreestyle,
  Sheet2023USDF4stLevelFreestyle,
} from './2023-freestyle'

// 2023 Pas De Deux / Quadrille (SCORE SHEET 3)
import {
  Sheet2023USDFPasDeDeux,
  Sheet2023USEFQuadrilleIntroductoryLevel,
  Sheet2023USEFQuadrilleTrainingLevel,
  Sheet2023USEFQuadrille1stLevel,
  Sheet2023USEFQuadrille2stLevel,
  Sheet2023USEFQuadrille3stLevel,
  Sheet2023USEFQuadrilleFreestyle,
} from './2023-pas-de-deux-quadrille'

// 2023 Sport Horse (SCORE SHEET 4)
import {
  Sheet2023USDFIndividualDressageSportHorseProspectInHand,
  Sheet2023USDFDressageSportHorseBreedingStockInHand,
  Sheet2023USDFDressageSportHorseGroupClassInHand,
  Sheet2023USDFDressageSportHorseProspectsUnderSaddle,
  Sheet2023USDFDressageSportHorseProspectsInHandMasterClass,
  Sheet2023USDFDressageSportHorseBreedingStockInHandMasterClass,
  Sheet2023USDFDressageSportHorseGroupMasterClass,
  Sheet2023USDFDressageSportHorseProspectsUnderSaddleMasterClass,
  Sheet2023USDFMaterialeClass,
  SheetUSDFAmateurJuniorYoungRiderHandler,
} from './2023-sport-horse'

// 2023 Dressage Seat Equitation
import {
  Sheet2023USEFUSDFDressageSeatEquitationClass,
  Sheet2023USEFUSDFDressageSeatMedalsClass,
} from './2023-dressage-seat-equitation'

// 2023 Developing Horse / Young Horse
import {
  Sheet2023USEFFourYearOldDressageTest,
  Sheet2023USEFDevelopingHorsePrixStGeorges,
  Sheet2023USEFDevelopingHorseGrandPrix,
} from './2023-developing-horse-young-horse'

// FEI Tests - Dressage Tests-Seniors
import {
  SheetFEIGrandPrix,
  SheetFEIGrandPrixSpecial,
  SheetFEIIntermediate1,
  SheetFEIIntermediate2,
  SheetFEIIntermediateA,
  SheetFEIIntermediateB,
  SheetFEIPrixStGeorge,
  SheetFEIShortGrandPrix,
} from './fei-tests-dressage-tests-seniors'

// FEI Tests - Dressage Tests-Freestyles
import {
  SheetFEIGrandPrixFreestyle,
  SheetFEIIntermediate1Freestyle,
  SheetFEIIntermediateABFreestyle,
  SheetFEIJuniorsFreestyle,
  SheetFEIPonyRiders,
  SheetFEIYoungRidersFreestyle,
} from './fei-tests-dressage-tests-freestyles'

// FEI Tests - Dressage Tests - U25
import { SheetFEIGrandPrix1625 } from './fei-dressagetests-u25'

// FEI Tests - Dressage Tests - Young Riders
import {
  SheetFEIYoungRidersIndividual,
  SheetFEIYoungRidersPreliminary,
  SheetFEIYoungRidersTeam,
} from './fei-dressage-tests-young-riders'

// FEI Tests - Dressage Tests - Juniors
import {
  SheetFEIJuniorsIndividual,
  SheetFEIJuniorsPreliminary,
  SheetFEIJuniorsTeam,
} from './fei-dressage-tests-juniors'

// FEI Tests - Dressage Tests - Pony Riders
import {
  SheetFEIPonyRidersIndividual,
  SheetFEIPonyRidersPreliminary,
  SheetFEIPonyRidersTeam,
} from './fei-dressage-tests-pony-riders'

// FEI Tests - Dressage Tests - Children
import {
  FEIChildrenPreliminaryACompetition,
  SheetFEIChildrenIndividual,
  SheetFEIChildrenPreliminaryBCompetition,
  SheetFEIChildrenTeamCompetition,
} from './fei-dressage-tests-children'

// FEI Tests - Dressage Tests - Young Horses
import {
  SheetFEI4YearOldHorses,
  SheetFEI5YearOldHorsesFinal,
  SheetFEI5YearOldHorsesPreliminary,
  SheetFEI6YearOldHorsesFinal,
  SheetFEI6YearOldHorsesPreliminary,
  SheetFEI7YearOldHorsesFinal,
  SheetFEI7YearOldHorsesPreliminary,
  SheetFEIYoungHorsesGrandPrix810YearsOld,
} from './fei-dressage-tests-young-horses'

// FEI Tests - Dressage Tests - Regional Games
import {
  SheetFEIRegionalGamesIndividual,
  SheetFEIRegionalGamesPreliminary,
  SheetFEIRegionalGamesTeam,
} from './fei-dressage-tests-regional-games'

export const sheets = [
  Sheet2023USDFIntroductoryLevelTestAWalkTrot,
  Sheet2023USDFIntroductoryLevelTestB,
  Sheet2023USDFIntroductoryLevelTestCWalkTrotCanter,
  Sheet2023USEFTrainingLevelTest1,
  Sheet2023USEFTrainingLevelTest2,
  Sheet2023USEFTrainingLevelTest3,
  Sheet2023USEFFirstLevelTest1,
  Sheet2023USEFFirstLevelTest2,
  Sheet2023USEFFirstLevelTest3,
  Sheet2023USEFSecondLevelTest1,
  Sheet2023USEFSecondLevelTest2,
  Sheet2023USEFSecondLevelTest3,
  Sheet2023USEFThirdLevelTest1,
  Sheet2023USEFThirdLevelTest2,
  Sheet2023USEFThirdLevelTest3,
  Sheet2023USEFFourthLevelTest1,
  Sheet2023USEFFourthLevelTest2,
  Sheet2023USEFFourthLevelTest3,
  Sheet2023USDFTrainingLevelFreestyle,
  Sheet2023USDF1stLevelFreestyle,
  Sheet2023USDF2stLevelFreestyle,
  Sheet2023USDF3stLevelFreestyle,
  Sheet2023USDF4stLevelFreestyle,
  Sheet2023USDFPasDeDeux,
  Sheet2023USEFQuadrilleIntroductoryLevel,
  Sheet2023USEFQuadrilleTrainingLevel,
  Sheet2023USEFQuadrille1stLevel,
  Sheet2023USEFQuadrille2stLevel,
  Sheet2023USEFQuadrille3stLevel,
  Sheet2023USEFQuadrilleFreestyle,
  Sheet2023USDFIndividualDressageSportHorseProspectInHand,
  Sheet2023USDFDressageSportHorseBreedingStockInHand,
  Sheet2023USDFDressageSportHorseGroupClassInHand,
  Sheet2023USDFDressageSportHorseProspectsUnderSaddle,
  Sheet2023USDFDressageSportHorseProspectsInHandMasterClass,
  Sheet2023USDFDressageSportHorseBreedingStockInHandMasterClass,
  Sheet2023USDFDressageSportHorseGroupMasterClass,
  Sheet2023USDFDressageSportHorseProspectsUnderSaddleMasterClass,
  Sheet2023USDFMaterialeClass,
  SheetUSDFAmateurJuniorYoungRiderHandler,
  Sheet2023USEFUSDFDressageSeatEquitationClass,
  Sheet2023USEFUSDFDressageSeatMedalsClass,
  Sheet2023USEFFourYearOldDressageTest,
  Sheet2023USEFDevelopingHorsePrixStGeorges,
  Sheet2023USEFDevelopingHorseGrandPrix,
  SheetFEIPrixStGeorge,
  SheetFEIIntermediate1,
  SheetFEIIntermediateA,
  SheetFEIIntermediateB,
  SheetFEIIntermediate2,
  SheetFEIShortGrandPrix,
  SheetFEIGrandPrix,
  SheetFEIGrandPrixSpecial,
  SheetFEIPonyRiders,
  SheetFEIJuniorsFreestyle,
  SheetFEIYoungRidersFreestyle,
  SheetFEIIntermediate1Freestyle,
  SheetFEIIntermediateABFreestyle,
  SheetFEIGrandPrixFreestyle,
  SheetFEIGrandPrix1625,
  SheetFEIYoungRidersPreliminary,
  SheetFEIYoungRidersTeam,
  SheetFEIYoungRidersIndividual,
  SheetFEIJuniorsPreliminary,
  SheetFEIJuniorsTeam,
  SheetFEIJuniorsIndividual,
  SheetFEIPonyRidersPreliminary,
  SheetFEIPonyRidersTeam,
  SheetFEIPonyRidersIndividual,
  FEIChildrenPreliminaryACompetition,
  SheetFEIChildrenPreliminaryBCompetition,
  SheetFEIChildrenTeamCompetition,
  SheetFEIChildrenIndividual,
  SheetFEI4YearOldHorses,
  SheetFEI5YearOldHorsesPreliminary,
  SheetFEI6YearOldHorsesPreliminary,
  SheetFEI7YearOldHorsesPreliminary,
  SheetFEI5YearOldHorsesFinal,
  SheetFEI6YearOldHorsesFinal,
  SheetFEI7YearOldHorsesFinal,
  SheetFEIYoungHorsesGrandPrix810YearsOld,
  SheetFEIRegionalGamesPreliminary,
  SheetFEIRegionalGamesTeam,
  SheetFEIRegionalGamesIndividual,
]
