import React, { CSSProperties, FC } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { IUSEFEntry } from '../../../../pages/organizer/manage/clinic/data/reports/USEF'

interface SelectedClassesProps {
  item: IUSEFEntry
  index: number
}
export const SelectedClasses: FC<SelectedClassesProps> = ({ item, index }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.code,
  })

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
  }

  return (
    <div ref={setNodeRef} style={style} className="flex gap-2 text-SeabiscuitDark200ThemeColor">
      <div className="flex items-center justify-center rounded-lg p-3 bg-SeabiscuitGrayThemeColor min-w-[55px]">
        {index + 1}
      </div>
      <div className="flex-1 rounded-lg p-3 bg-SeabiscuitGrayThemeColor">{item.class}</div>
      <div
        {...attributes}
        {...listeners}
        className="hidden md:flex cursor-pointer opacity-50 hover:opacity-100 w-11 p-3 transition-all"
      >
        <img src="/assets/cp_icons/Move.svg" alt="moveIcon" className="w-full" />
      </div>
    </div>
  )
}
