export const Sheet2023USDFPasDeDeux = {
  id: 'Sheet2023USDFPasDeDeux',
  name: '2023 USDF Pas De Deux',
  furtherRemarks: '',
  type: 'score-sheet-3',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 100,
      type: 'test',
      rows: [
        {
          number: 1,
          description: '<p>Compulsory Elements Technical Execution</p>',
          score: '',
          coefficient: 3,
          remarks: '',
        },
        {
          number: 2,
          description: '<p>Performance as a Pair spacing, alignment, synchrony</p>',
          score: '',
          coefficient: 4,
          remarks: '',
        },
        {
          number: 3,
          description: '<p>Gaits – rhythm & quality 10</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p> Impulsion energy, elasticity, engagement 1</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p> Submission—basic issues of submission technical aspect of the riders</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
    {
      name: 'Artistic Impression',
      max: 100,
      type: 'mark',
      rows: [
        {
          description:
            '<h5>Harmony</h5><p>Harmony Between Horses and Riders, Fluency of Performance</p>',
          score: '',
          coefficient: 3,
        },
        {
          description:
            '<h5>Choreography</h5><p>Choreography design cohesiveness, use of arena balance, creativity, difficulty</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Music</h5><p>Music suitability, seamlessness, cohesiveness</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Interpretation</h5><p>. Interpretation music expresses gaits, use of phrasing and dynamics</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
  ],
}

export const Sheet2023USEFQuadrilleIntroductoryLevel = {
  id: 'Sheet2023USEFQuadrilleIntroductoryLevel',
  name: '2023 USEF Quadrille Introductory Level',
  furtherRemarks: '',
  type: 'score-sheet-3',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 130,
      type: 'test',
      rows: [
        {
          number: 0,
          description:
            '<p><span>N/A •</span> A Enter single file at walk or trot</p><p><span>N/A •</span> L Divide, On half circle to track, 1’s right, 2’s left.</p><p><span>N/A •</span> Between P & F, V & K files trot as one</p><p><span>N/A •</span> A Turn down centerline in pairs</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 1,
          description:
            '<p><span>D •</span> Proceed in pairs at working trot. </p><p><span>X •</span> Halt fan formation, salute. Proceed working trot single file</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track left</p><p><span>V •</span> ½ 10 meter circle to centerline</p><p><span>I •</span> ½ 10 meter circle right to track</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>A •</span> Circle right 20 meter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>Between V & E •</span> Individual right obliques to centerline when lead rider reaches E </p><p><span>C •</span> Track right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>Centered on B •</span> Individual right turns across arena. Track left towards A.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>A •</span> Circle left 20 meters</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>Between P & P •</span> Individual left obliques to centerline when lead rider reaches B Bdtween P & P • Individual left obliques to centerline when lead rider reaches B </p><p><span>C •</span> Divide, 1’s right, 2’s left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>M & H •</span> Files walk as one</p><p><span>R & S •</span> Short oblique to X, forming pairs.</p><p><span>L •</span> Individual right and left obliques to track</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>F & K •</span> Files trot as one</p><p><span>A •</span> Turn down centerline in single file, 1,2,3,4</p><p><span>Centered on X •</span> Individual right and left obliques to track 1’s right, 2’s left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>C •</span> Cross over</p><p><span>B & E •</span> Files ½ circle to centerline, forming single file, 2’s in front of 1’s</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>Between I & G •</span> Individual ½ circles to rail, 1’s right, 2’s left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>E to F and B to K •</span> Thread the needle 3, 4, 1, 2</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>A •</span> Down centerline in pairs</p><p><span>X •</span> Halt fan formation, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
    },
    {
      name: 'Collective remarks',
      max: 60,
      type: 'mark',
      rows: [
        {
          description: '<h5>Spacing</h5><p>(longitudinal and lateral)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Synchrony</h5><p>(timing of turns, circle, transitions, crossovers, pass-throughs)</p><h5>Alignment</h5><p>(riders’ bodies as seen from side and front)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Impulsion</h5><p></p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Submission</h5><p>(obedience to aids in order to perform patterns accurately)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Performance as a Group</h5><p>(uniforms and harmonious)</p>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const Sheet2023USEFQuadrilleTrainingLevel = {
  id: 'Sheet2023USEFQuadrilleTrainingLevel',
  name: '2023 USEF Quadrille Training Level',
  furtherRemarks: '',
  type: 'score-sheet-3',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 140,
      type: 'test',
      rows: [
        {
          number: 0,
          description:
            '<p><span>N/A •</span> A Enter single file at walk or trot</p><p><span>N/A •</span> L Divide, On half circle to track, 1’s right, 2’s left.</p><p><span>N/A •</span> Between P & F, V & K files trot as one</p><p><span>N/A •</span> A Turn down centerline in pairs</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 1,
          description:
            '<p><span>D •</span> Proceed in pairs at working trot</p><p><span>X •</span> Halt fan formation, salute. Proceed in pairs at working trot.</p><p><span>C •</span> Divide, 1’s right, 2’s left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>Centered on B & E •</span> Individual ½ 10 meter circles to centerline  forming single file 4, 3, 2, 1</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>Centered on I & G •</span> Individual ½ 10 meter circles back to opposite long side</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>On reaching track •</span> Individual obliques across area, crossing through on centerline 1’s ahead of 2’s</p><p><span>A •</span> Turn down centerline in single file 1, 2, 3, 4</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>Before X •</span> Individual right obliques to track</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>Between M & C •</span> File canter left lead individually</p><p><span>C •</span> Circle left 20 meters</p><p><span>C •</span> Trot individually</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>Centered on E •</span> Individual left turns across arena. On long side file turn right towards A</p><p><span>C •</span> Divide, 1’s right, 2’s left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>Between F & A •</span> File canter right lead individually</p><p><span>A •</span> Circle right 20 meters</p><p><span>A •</span> Trot individually</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>A to C •</span> Three loop serpentine width of arena</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>R •</span> Walk</p><p><span>Centered on B •</span> Individual right turns to centerline, forming pairs</p><p><span>X •</span> 1 & 2 turn left towards A, 3 & 4 turn right towards C</p><p><span>D and G •</span> Pairs trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>A and C •</span> Pairs divide</p><p><span>F-E, H-B, K-E, M-B •</span> 1’s short diagonal, 2’s straight ahead</p><p><span>B-k, E-M, E-H, B-F •</span> 2’s short diagonal, 1’s straight ahead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>A and C •</span> Turn up centerline in file, 1’s in front of 2’s</p><p><span>Before X •</span> Individual right obliques to track</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>Centered on B & E •</span> Individual turns across arena, passing  through on centerline</p><p><span>On reaching track •</span> Files head toward A</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>A •</span> Turn on centerline, forming pairs</p><p><span>X •</span> Halt fan formation, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
    },
    {
      name: 'Collective remarks',
      max: 60,
      type: 'mark',
      rows: [
        {
          description: '<h5>Spacing</h5><p>(longitudinal and lateral)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Synchrony</h5><p>(timing of turns, circle, transitions, crossovers, pass-throughs)</p><h5>Alignment</h5><p>(riders’ bodies as seen from side and front)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Impulsion</h5><p></p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Submission</h5><p>(obedience to aids in order to perform patterns accurately)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Performance as a Group</h5><p>(uniforms and harmonious)</p>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const Sheet2023USEFQuadrille1stLevel = {
  id: 'Sheet2023USEFQuadrille1stLevel',
  name: '2023 USEF Quadrille 1st Level',
  furtherRemarks: '',
  type: 'score-sheet-3',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 160,
      type: 'test',
      rows: [
        {
          number: 0,
          description:
            '<p><span>N/A •</span> A Enter single file at walk or trot</p><p><span>N/A •</span> L Divide, On half circle to track, 1’s right, 2’s left.</p><p><span>N/A •</span> Between P & F, V & K files trot as one</p><p><span>N/A •</span> A Turn down centerline in pairs</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 1,
          description:
            '<p><span>D •</span> Proceed in pairs at working trot</p><p><span>X •</span> Halt, fan formation, salute. Proceed working trot in pairs.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description: '<p><span>C •</span> Track left, forming single file 2, 1, 4, 3</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>S - P •</span> When leader reaches S, individual left obliques across arena</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>Between F & A •</span> Form pairs in the corner</p><p><span>A •</span> Turn onto centerline in pairs</p><p><span>Centered on L •</span> Individual left and right 10 meter circles, forming a cloverleaf</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>X - F & X - K •</span> Files leg-yield left and right individually, 1’s right, 2’s left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>C •</span> Turn onto centerline, forming pairs</p><p><span>Centered on I •</span> Individual left and right 10 meter circles, forming a cloverleaf</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>X - F & X - K •</span> Files leg-yield left and right individually, 1’s left, 2’s right</p><p><span>A •</span> Cross over</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>FXH •</span> Lengthen trot rising and thread the needle, 1’s ahead</p><p><span>KXM •</span> 1’s ahead of 2’s</p><p><span>C •</span> Cross over, 2’s and 4’s on A side, 1’s and 3’s on C side</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>B & E •</span> Centered on B and E, individual left and right turns across arena, and pass through, centered on X. All turn toward C when reaching track</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>Between B-R & E-S •</span> Individual ½ circles, forming single file on centerline</p><p><span>A •</span> Files track left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>P •</span> Walk as one when leader reaches P</p><p><span>B - X •</span> File ½ circle left, forming pairs in the turn, before reaching the quarterline</p><p><span>X - E •</span> Pairs ½ circle right and form single file upon reaching track</p><p><span>S •</span> Trot as one when leader reaches S</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>H •</span> Canter individually, right lead</p><p><span>C •</span> Circle right 15 meters</p><p><span>M •</span> Trot individually</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>B •</span> Centered on B, individual right turns across arena</p><p><span>E •</span> Centered on E, individual left turns, forming a single file</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>K •</span> Canter individually, left lead</p><p><span>A •</span> Circle left 15 meters</p><p><span>FXH •</span> Change rein</p><p><span>X •</span> Trot individually</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>C •</span> Turn on centerline, forming pairs</p><p><span>X-P & X-V •</span> Individual right and left obliques when leaders reach X, 1’s right, 2’s left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>V & P •</span> When leaders reach V & P, individual ½ circles left and right, forming pairs on centerline</p><p><span>G •</span> Halt, fan formation. Salute.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
    },
    {
      name: 'Collective remarks',
      max: 60,
      type: 'mark',
      rows: [
        {
          description: '<h5>Spacing</h5><p>(longitudinal and lateral)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Synchrony</h5><p>(timing of turns, circle, transitions, crossovers, pass-throughs)</p><h5>Alignment</h5><p>(riders’ bodies as seen from side and front)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Impulsion</h5><p></p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Submission</h5><p>(obedience to aids in order to perform patterns accurately)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Performance as a Group</h5><p>(uniforms and harmonious)</p>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const Sheet2023USEFQuadrille2stLevel = {
  id: 'Sheet2023USEFQuadrille2stLevel',
  name: '2023 USEF Quadrille 2st Level',
  furtherRemarks: '',
  type: 'score-sheet-3',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 180,
      type: 'test',
      rows: [
        {
          number: 0,
          description:
            '<p><span>N/A •</span> A Enter single file at walk or trot</p><p><span>N/A •</span> L Divide, On half circle to track, 1’s right, 2’s left.</p><p><span>N/A •</span> Between P & F, V & K files trot as one</p><p><span>N/A •</span> A Turn down centerline in pairs</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 1,
          description:
            '<p><span>D •</span> Proceed in pairs at collected trot</p><p><span>X •</span> Halt, fan formation, salute. Proceed collected trot in pairs</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Divide 1’s right, 2’s left</p><p><span>H - E & M - B •</span> Travers</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>B & E •</span> Files left and right turn across the arena</p><p><span>X •</span> Cross over</p><p><span>B & E •</span> Files turn right and left and proceed in shoulder-in (1 & 4 towards A, 2 & 3 towards C)</p><p><span>A & C •</span> Cross over</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>MEK, HEF, FBH, KBM •</span> 1 and 2 collected trot F-B/H-E; medium trot B-H & E-F 3 and 4 medium trot M-E/K-B; collected trot B-M/E-K Medium trot arrives at B-E ahead of collected trot</p><p><span>A & C •</span> Cross over</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>F-B/K-E M-B/H-E •</span> Shoulder-in from four corners</p><p><span>E & B •</span> Turn across arena forming files 2 and 4 on A side, 1 and 3 on C side</p><p><span>X •</span> Cross over</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>E & B •</span> Files turn left and right toward A</p><p><span>E-K & B-F •</span> Files travers</p><p><span>A •</span> Cross over, left to left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>F & K •</span> Files walk as one when leaders reach F and K</p><p><span>When Leaders reach B & E •</span> Individual half turn on haunches 1’s left, 2’s right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>When Leaders reach F & K •</span> Individual half turn on haunches 1’s right, 2’s left</p><p><span>B-H & E-M •</span> Files across ½ diagonal threading the needle, 2’s ahead of 1’s</p><p><span>C •</span> Cross over</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>Before H & M •</span> Canter as one when leaders reach corner</p><p><span>R & S •</span> Files 10 meter circle</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>B & E •</span> Files turn across arena, cross over right to right</p><p><span>Centered on X •</span> Simple change of lead as one while crossing over</p><p><span>B & E •</span> Files turn right and left toward A</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>V & P •</span> Files 10 meter circles</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>A •</span> Files turn up centerline to form single file (1, 2, 3, 4)</p><p><span>Centered over X •</span> Simple change of lead as one</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>C •</span> Divide 1’s right, 2’s left</p><p><span>C to A •</span> Three loop serpentine no change of lead, 1 & 3 cross over on A side; 2 & 4 cross over on C side</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>A •</span> Cross over and trot</p><p><span>Between F & P, K & V •</span> Individual obliques to centerline to form pairs</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>Between X & G •</span> Individual 10 meter circles to form cloverleaf</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>C •</span> Track left forming single file 1, 2, 3, 4</p><p><span>Centered between H & E •</span> Individual left turns across arena, upon reaching rail individual left turns and immediately individual 10 meter half circles to centerline forming single file</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>Centered between X & D •</span> Individual 10 meter half circles right, upon reaching rail individual right turns across the arena and upon reaching the rail track right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>F - A - D •</span> Form pairs and turn up centerline</p><p><span>X •</span> Halt, fan formation, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
    },
    {
      name: 'Collective remarks',
      max: 60,
      type: 'mark',
      rows: [
        {
          description: '<h5>Spacing</h5><p>(longitudinal and lateral)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Synchrony</h5><p>(timing of turns, circle, transitions, crossovers, pass-throughs)</p><h5>Alignment</h5><p>(riders’ bodies as seen from side and front)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Impulsion</h5><p></p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Submission</h5><p>(obedience to aids in order to perform patterns accurately)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Performance as a Group</h5><p>(uniforms and harmonious)</p>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const Sheet2023USEFQuadrille3stLevel = {
  id: 'Sheet2023USEFQuadrille3stLevel',
  name: '2023 USEF Quadrille 3st Level',
  furtherRemarks: '',
  type: 'score-sheet-3',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 170,
      type: 'test',
      rows: [
        {
          number: 0,
          description:
            '<p><span>N/A •</span> A Enter single file at walk or trot</p><p><span>N/A •</span> L Divide, On half circle to track, 1’s right, 2’s left.</p><p><span>N/A •</span> Between P & F, V & K files trot as one</p><p><span>N/A •</span> A Turn down centerline in pairs</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 1,
          description:
            '<p><span>D •</span> Proceed in pairs at collected trot</p><p><span>X •</span> Halt, fan formation, salute. Proceed collected trot in pairs</p><p><span>C •</span> Divide.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>MXK & HXF •</span> Medium trot, thread the needle 1’s ahead of 2’s</p><p><span>F & K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>A •</span> Down centerline in pairs</p><p><span>D to X •</span> Left and right shoulder-in, in pairs</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>X •</span> Files circle left and right 10m</p><p><span>X - H •</span> 1’s half-pass left</p><p><span>X - M •</span> 2’s half-pass right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>C •</span> Down centerline in pairs</p><p><span>G - X •</span> Left and right shoulder-in in pairs</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>X •</span> Files circle left and right 10m</p><p><span>X - K •</span> 1’s half-pass right</p><p><span>X - F •</span> 2’s half-pass left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>A •</span> Cross over and form pairs in corner</p><p><span>KXM & FXH •</span> Medium trot in pairs (thread at X), 2 and 4 ahead of 1 and 3</p><p><span>M & H •</span> Collected trot and form single file</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>C •</span> Turn down centerline in pairs</p><p><span>G •</span> Medium walk (when leaders reach G) and immediately oblique to E&B</p><p><span>Before E & B •</span> Shorten steps and half turn on haunches, 1’s left, 2’s right. Proceed medium walk.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>Before G •</span> Shorten steps and half turn on haunches, 1’s right, 2’s left. Proceed medium walk.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>E & B •</span> Files turn left and right.</p><p><span>Centered on X •</span> Cross over and collected canter right lead as one, 1’s on A side, 2’s on C side. Turn right when reaching track.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>C & A •</span> Files turn down centerline.</p><p><span>Centered between G & X and D & X •</span> Individual 10m circles right.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>X •</span> Files half-pass right to M and K.</p><p><span>M & K •</span> Flying change of lead.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>C & A •</span> Files down centerline</p><p><span>Centered between G&X and D&X •</span> Individual 10m circles left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><pan>X •</pan> Files half-pass left to F and H</p><p><span>F & H •</span> Flying change of lead</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>K-H and M-F •</span> Medium Canter, right lead</p><p><span>H & F •</span> Collected Canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>M & K •</span> Collected Canter</p><p><span>M •</span> 1’s on diagonal to ¼ line near E (at transverse centerline)</p><p><span>K •</span> 2’s on diagonal to ¼ line near B (at transverse centerline)</p><p><span>Centered on X •</span> 10M circle ¼ line to ¼ line; #1 completes one circle and leads file diagonally to K</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>A •</span> Turn onto centerline forming fours</p><p><span>X •</span> Halt, salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
    },
    {
      name: 'Collective remarks',
      max: 60,
      type: 'mark',
      rows: [
        {
          description: '<h5>Spacing</h5><p>(longitudinal and lateral)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Synchrony</h5><p>(timing of turns, circle, transitions, crossovers, pass-throughs)</p><h5>Alignment</h5><p>(riders’ bodies as seen from side and front)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Impulsion</h5><p></p>',
          score: '',
          coefficient: 1,
        },
        {
          description:
            '<h5>Submission</h5><p>(obedience to aids in order to perform patterns accurately)</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Performance as a Group</h5><p>(uniforms and harmonious)</p>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const Sheet2023USEFQuadrilleFreestyle = {
  id: 'Sheet2023USEFQuadrilleFreestyle',
  name: '2023 USEF Quadrille Freestyle',
  furtherRemarks: '',
  type: 'score-sheet-3',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 130,
      type: 'test',
      rows: [
        {
          number: 1,
          description: '<p>Compulsory Elements, Technical Execution</p>',
          score: '',
          coefficient: 3,
          remarks: '',
        },
        {
          number: 2,
          description: '<p>Spacing</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 3,
          description: '<p>Synchrony and Alignment</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 4,
          description: '<p>Impulsion</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description: '<p>Submission</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Performance as a Group</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
    {
      name: 'Artistic Impression',
      max: 130,
      type: 'mark',
      rows: [
        {
          description:
            '<h5>Choreography</h5><p>Design, balance, use of space, changes of direction, changes of gait, creativity, degree of difficulty</p>',
          score: '',
          coefficient: 5,
        },
        {
          description:
            '<h5>Choice of Music and Interpretation</h5><p>Music music suggests gaits, cohesiveness phrasing & dynamics, editing</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Harmony</h5><p>Harmony of Presentation, Fluency of Performance</p>',
          score: '',
          coefficient: 3,
        },
        {
          description: '<h5>Team Appearance</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
  ],
}
