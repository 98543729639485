import { CONST } from '../../../const/const'
import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'
import FirestoreService from '../../firestoreService'
import IRegistrationService from './types'

// Constants
const COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @info Event payment settings service
 */
class RegistrationService {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Fetches the doc from the db
   * @param {IRegistrationService["IGetRequest"]} req
   */
  get: IRegistrationService['IGet'] = async (req: IRegistrationService['IGetRequest']) => {
    const documentSnapshot = await FirestoreService.getItem(COLLECTION.NAME, req.docId)

    if (!documentSnapshot.exists())
      throw new Error(`Registration doc where id: ${req.docId} does not exist`)

    const registration = EventRegisteredUsersModel.fromFirestoreDoc(documentSnapshot).toObject()

    return {
      registration,
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Fetches the doc from the db
   * @param {IRegistrationService["ICreateRequest"]} req
   */
  create: IRegistrationService['ICreate'] = async (req: IRegistrationService['ICreateRequest']) => {
    const docToCreate = new EventRegisteredUsersModel(req.data).toFirestore()

    if (req.id) {
      await FirestoreService.createItemWithCustomId(COLLECTION.NAME, req.id, docToCreate)
      return {
        docId: req.id,
        registration: {
          id: req.id,
          docToCreate,
        },
      }
    } else {
      const documentSnapshot = await FirestoreService.createItem(COLLECTION.NAME, docToCreate)
      return {
        docId: documentSnapshot.id,
        registration: docToCreate,
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Fetches the doc from the db
   * @param {IRegistrationService["IUpdateRequest"]} req
   */
  update: IRegistrationService['IUpdate'] = async (req: IRegistrationService['IUpdateRequest']) => {
    let registration = await this.get({
      docId: req.docId,
    })

    await FirestoreService.updateItem(
      COLLECTION.NAME,
      req.docId,
      new EventRegisteredUsersModel({
        ...registration,
        ...req.fieldsToUpdate,
      }).toFirestore()
    )

    return {
      docId: req.docId,
      updatedFields: req.fieldsToUpdate,
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Fetches the doc from the db
   * @param {IRegistrationService["IDeleteRequest"]} req
   */
  delete: IRegistrationService['IDelete'] = async (req: IRegistrationService['IDeleteRequest']) => {
    await FirestoreService.deleteItem(COLLECTION.NAME, req.docId)

    return true
  }
}

const RegistrationService_ = new RegistrationService()

export default RegistrationService_
