import { useEffect, useRef } from 'react'
import { IMessageComp } from '../types/competitor_types'

/**
 *
 * @param param0
 * @returns
 */
const MessageHelperComp = ({
  isError = true,
  message = '',
  className = '',
  focusOnError = false,
}: IMessageComp) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (focusOnError && ref?.current && typeof message === 'string' && message.length && isError) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [message, isError, focusOnError])

  const style = {
    color: isError ? '#f7074f' : 'green',
    fontWeight: 600,
    fontSize: '11px',
    fontFamily: 'Poppins, sans-serif',
    marginTop: '1px',
  }

  return (
    <div {...{ style, className }} ref={ref}>
      {message?.substring(0, 1)?.toUpperCase() + message?.substring(1)}
    </div>
  )
}

export default MessageHelperComp
