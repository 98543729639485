import { useParams } from 'react-router'
import { useState } from 'react'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import Tabs from './components/tabs/Tabs'
import TicketHoldersTab from './components/tabs/TicketHoldersTab'
import useTicketDashboard from './hooks/useTicketDashboard'
import TicketBuyersTab from './components/tabs/TicketBuyersTab'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const TicketDashboard = () => {
  const eventId = useParams<{ eventId: string }>()?.eventId
  const activeTab = useParams<{ activeTab: string }>()?.activeTab
  const ticketDashboard = useTicketDashboard()
  const [checkedIn, setCheckedIn] = useState<number>()
  const [total, setTotal] = useState<number>()

  return (
    <WrapperContainer title=" Tickets" headLink={`/manage/clinic/${eventId}`} isError={false}>
      <>
        <div className="flex mb-6 gap-4">
          <Tabs {...ticketDashboard} checkedIn={checkedIn} total={total} />
        </div>
        {activeTab === 'ticket-buyers' ? (
          <TicketBuyersTab eventId={eventId} />
        ) : (
          <TicketHoldersTab eventId={eventId} setCheckedIn={setCheckedIn} setTotal={setTotal} />
        )}
      </>
    </WrapperContainer>
  )
}

export default TicketDashboard
