import IProfileCard from '../types'
import HorseDetails from './horse-details'
import HorseLineage from './horse-lineage'
import HorseTeamMembers from './horse-team-members'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ActiveTabSection = (props: IProfileCard['IActiveTabSectionProps']) => {
  return (
    <div className="scrollable overflow-y-auto w-full h-[500px] mt-2 pt-2">
      <div className="content px-2">
        {props.activeTab === 'details' ? <HorseDetails /> : null}

        {props.activeTab === 'team' ? (
          <HorseTeamMembers horseTeamMembers={props.horseTeamMembers} />
        ) : null}

        {props.activeTab === 'lineage' ? <HorseLineage horseLineage={props.horseLineage} /> : null}
      </div>
    </div>
  )
}

export default ActiveTabSection
