import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import EventPrizeListFormComponent from '../../../components/events/forms/prize-list/EventPrizeListFormComponent'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { EventPrizeListsModel } from '../../../models/event-prize-lists/event-prize-lists.model'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface ShowsDetailPageEventPrizeListFormSubSectionProps {
  onSetEventTab: (tab: string) => void
  nextEventTab?: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsDetailPageEventPrizeListFormSubSection: React.FC<
  ShowsDetailPageEventPrizeListFormSubSectionProps
> = (props) => {
  const { eventId } = useParams<{ eventId: string }>()

  const [data, setData] = useState<any>(null)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const validFormHandler = async (data: any) => {
    let updated = true

    try {
      const updated_data = new EventPrizeListsModel({
        ...data,
      })

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_PRIZE_LISTS.NAME,
        eventId,
        updated_data.toFirestore()
      )

      setData(updated_data.toObject())
    } catch (error) {
      updated = false
      alert('202206261334')
      console.error('202206261334:' + error)
    } finally {
      return {
        updated,
        onSetEventTab: props.onSetEventTab,
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidFormHandler = async (data: any) => {
    alert('invalid')
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        const doc = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_PRIZE_LISTS.NAME,
          eventId
        )
        if (doc) {
          setData(EventPrizeListsModel.fromFirestoreDoc(doc).toObject())
        }
      }
      fetchData().catch()
    }
  }, [data, setData, eventId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <EventPrizeListFormComponent
        data={data}
        onValid={validFormHandler}
        onInvalid={invalidFormHandler}
        // nextEventTab={props.nextEventTab}
      />
    </>
  )
}

export default ShowsDetailPageEventPrizeListFormSubSection
