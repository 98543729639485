export const SheetFEI4YearOldHorses = {
  id: 'SheetFEI4YearOldHorses',
  name: 'FEI 4 Year Old Horses',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 50,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Trot</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Walk</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Canter</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Submission</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Perspective</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const SheetFEI5YearOldHorsesPreliminary = {
  id: 'SheetFEI5YearOldHorsesPreliminary',
  name: 'FEI 5 Year Old Horses Preliminary',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 50,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Trot</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Walk</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Canter</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Submission</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Perspective</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const SheetFEI6YearOldHorsesPreliminary = {
  id: 'SheetFEI6YearOldHorsesPreliminary',
  name: 'FEI 6 Year Old Horses Preliminary',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 50,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Trot</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Walk</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Canter</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Submission</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Perspective</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const SheetFEI7YearOldHorsesPreliminary = {
  id: 'SheetFEI7YearOldHorsesPreliminary',
  name: 'FEI 7 Year Old Horses Preliminary',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 260,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>MXK •</span> Extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>N/A •</span> Transitions at M and K</p><p><span>KA •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DE •</span> Half pass to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>E •</span> Volte right (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>EH •</span> Shoulder-in right</p><p><span>HC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>CM •</span> Collected walk</p><p><span>M •</span> Turn right</p><p><span>H •</span> Turn left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>HSP •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>Between P&amp;F •</span> Collected walk and proceed in collected trot</p><p><span>FA •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DB •</span> Half pass to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>B •</span> Volte left (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>BM •</span> Shoulder-in left</p><p><span>MC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>C •</span> Proceed in collected canter left</p><p><span>CH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>HK •</span> Medium canter</p><p><span>KAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>F(X) •</span> Proceed towards X in collected canter</p><p><span>Between F&amp;X •</span> Half pirouette to the left (Working pirouette with a radius approx. 1 - 3m allowed)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>F •</span> Flying change of leg</p><p><span>FAK •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>KX •</span> Half-pass to the right</p><p><span>X •</span> Down the center line</p><p><span>I •</span> Flying change</p><p><span>C •</span> Turn left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>HP •</span> On the short diagonal 3 flying changes every 4th stride</p><p><span>PFAK •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>K(X) •</span> Proceed towards X in collected canter</p><p><span>Between K&amp;X •</span> Half pirouette to the right (Working pirouette with a radius approx. 1 - 3m allowed)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>K •</span> Flying change of leg</p><p><span>KAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>FX •</span> Half-pass to the left</p><p><span>X •</span> Down the center line</p><p><span>I •</span> Flying change</p><p><span>C •</span> Turn right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description: '<p><span>MXK •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description: '<p><span>K •</span> Collected canter and flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt – immobility – salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Score',
      max: 50,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Trot</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Walk</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Canter</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Submission</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Perspective</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const SheetFEI5YearOldHorsesFinal = {
  id: 'SheetFEI5YearOldHorsesFinal',
  name: 'FEI 5 Year Old Horses Final',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 50,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Trot</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Walk</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Canter</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Submission</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Perspective</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const SheetFEI6YearOldHorsesFinal = {
  id: 'SheetFEI6YearOldHorsesFinal',
  name: 'FEI 6 Year Old Horses Final',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 50,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Trot</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Walk</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Canter</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Submission</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Perspective</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const SheetFEI7YearOldHorsesFinal = {
  id: 'SheetFEI7YearOldHorsesFinal',
  name: 'FEI 7 Year Old Horses Final',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 330,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>AX •</span> Enter in working trot</p><p><span>X •</span> Halt - immobility - salute</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the left</p><p><span>HXF •</span> Medium trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>N/A •</span> Transitions at H and F</p><p><span>FAK •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>KE •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>EX •</span> Half volte right (10 m Ø)</p><p><span>XB •</span> Half volte left (10 m Ø)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>BG •</span> Half pass to the left</p><p><span>G •</span> On centre line</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>RS •</span> Half circle right (20 m Ø); Let the horse stretch on a long rein.</p><p><span>SHCM •</span> Collected trot</p><p><span>Between S &amp; H •</span> Retake the reins</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>MXK •</span> Extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>N/A •</span> Transitions at M and K</p><p><span>KAF •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>FB •</span> Shoulder-in left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>BX •</span> Half volte left (10 m Ø)</p><p><span>XE •</span> Half volte right (10 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>EG •</span> Half pass to the right</p><p><span>G •</span> On centre line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>Before C •</span> Medium walk</p><p><span>C •</span> Track to the left</p><p><span>CH •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>HIB •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>BPL(V) •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>Between L &amp; V •</span> Half pirouette to the right</p><p><span>L(P) •</span> Proceed in collected walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>Between L &amp; P •</span> Half pirouette to the left</p><p><span>L(V) •</span> Proceed in collected walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>Between L &amp; V •</span> Proceed in collected canter left</p><p><span>VKA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DE •</span> Half pass to the left</p><p><span>E •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>EG •</span> Half pass to the right</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description: '<p><span>MXK •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>K •</span> Collected canter and flying change of leg</p><p><span>KAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>F(X) •</span> Proceed towards X in collected canter</p><p><span>Between F &amp; X •</span> Half pirouette to the left (Working pirouette with a radius approx. 1 - 3m allowed)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>FA •</span> Counter canter</p><p><span>A •</span> Flying change of leg</p><p><span>AK •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>K(X) •</span> Proceed towards X in collected canter</p><p><span>Between K &amp; X •</span> Half pirouette to the right ( (Working pirouette with a radius approx. 1 - 3m allowed)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>KA •</span> Counter canter</p><p><span>A •</span> Flying change of leg</p><p><span>AF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description:
            '<p><span>FS •</span> On the short diagonal 3 flying changes every 4th stride</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 28,
          description:
            '<p><span>SR •</span> Half circle right (20m diameter). Give and retake the reins for 3 canter strides</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 29,
          description:
            '<p><span>RK •</span> On the short diagonal 3 flying changes every 3rd stride</p><p><span>KA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 30,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
    {
      name: 'Quality',
      max: 50,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Trot</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Walk</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Canter</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Submission</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Perspective</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const SheetFEIYoungHorsesGrandPrix810YearsOld = {
  id: 'SheetFEIYoungHorsesGrandPrix810YearsOld',
  name: 'FEI Young Horses Grand Prix (8-10 Years Old)',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 360,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute Proceed in collected trot</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>MXK •</span> Extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>N/A •</span> Transitions at M and K</p><p><span>K A F •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>Between F &amp; P •</span> Transition to passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>P(V) •</span> Half circle in passage (20 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>When crossing the center line •</span> Piaffe 8 to 10 steps</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>When crossing the center line •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>From center line to V, on circle line VA •</span> Passage</p><p><span>VA •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DG •</span> 3 half-passes, 5 m to either side of the centre line,  beginning and ending to the left</p><p><span>C •</span> Track to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>HP •</span> Medium trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>PFD •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>D •</span> Piaffe 8 to 10 steps</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>D •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>DKV •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>VXR •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description: '<p><span>RHC •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>C •</span> Proceed in collected canter right</p><p><span>CM •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description: '<p><span>MXK •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>K •</span> Collected canter; Flying change of leg.</p><p><span>KAFP •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>PX •</span> Half-pass to the left</p><p><span>X •</span> Down the centre line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>I •</span> Pirouette to the left</p><p><span>C •</span> Track to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>HXF •</span> On the diagonal 9 flying changes of leg every 2nd stride</p><p><span>FAKV •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>VX •</span> Half-pass to the right</p><p><span>X •</span> Down the centre line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>I •</span> Pirouette to the right</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>MXK •</span> On the diagonal 15 flying changes of leg every stride</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>A •</span> Down the center line</p><p><span>DL •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description: '<p><span>LX •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 28,
          description: '<p><span>X •</span> Piaffe 8 to 10 steps</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 29,
          description: '<p><span>X •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 30,
          description: '<p><span>XG •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 31,
          description: '<p><span>G •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}
