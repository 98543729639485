import { useIonRouter } from '@ionic/react'
import { CONST } from '../../../../const/const'
import FirebaseApp from '../../../../services/firebaseApp'
import { setActiveFilters } from '../../../../store/filters/filterSlice'
import { useAppDispatch } from '../../../../store/hooks'
import { setUserMode } from '../../../../store/system/systemSlice'
import { clearUserStoredData } from '../../../../store/user/userThunk'
import { useHistory } from 'react-router-dom'

const useLogout = () => {
  const router = useIonRouter()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const logoutFn = async () => {
    try {
      await FirebaseApp.auth.signOut()
      dispatch(setUserMode(null))
      dispatch(clearUserStoredData(true))
      dispatch(setActiveFilters(null))
      router.push(CONST.ROUTES.LOGOUT.URL, 'forward', 'replace', {
        unmount: true,
      })
      history.push(CONST.ROUTES.LOGOUT.URL)
    } catch (error) {
      console.error(error)
    } finally {
      return
    }
  }

  return {
    logout: logoutFn,
  }
}

export default useLogout
