export type IStateClauses = {
  [cityName: string]: {
    WARNING: string
    POINTS: string[]
    POINTS_HEADING: string
  }
}

const STATE_CLAUSES: IStateClauses = {
  ALASKA: {
    WARNING: 'Alaska does not have any equine state warnings and/or provisions.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  ALABAMA: {
    WARNING:
      'WARNING. Under Alabama law, an equine activity sponsor or equine professional is not liable for an injury to or the death of a participant in equine activities resulting from the inherent risks of equine activities, pursuant to the Equine Activities Liability Protection Act.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  ARKANSAS: {
    WARNING:
      'WARNING. Under Arkansas law, an equine activity sponsor is not liable for an injury to, or the death of, a participant in equine activities resulting from the inherent risk of equine activities.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  ARIZONA: {
    WARNING:
      'WARNING. Participant is aware of the inherent risks associated with equine activities and is willing and able to accept full responsibilities for his or her own safety and welfare. Participant releases the Event Sponsor from liability unless Event Sponsor is grossly negligent or commits willful, wanton or intentional acts or omissions.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  CALIFORNIA: {
    WARNING: 'California does not have any equine state warnings and/or provisions.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  COLORADO: {
    WARNING:
      'WARNING. Under Colorado Law, an equine professional is not liable for an injury to or the death of a participant in equine activities resulting from the inherent risks of equine activities resulting from the inherent risks of equine activities resulting from the inherent risks of equine injury, pursuant to section 13-21-119, Colorado Revised Statutes.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  CONNECTICUT: {
    WARNING: 'Connecticut does not have any equine state warnings and/or provisions.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  DELAWARE: {
    WARNING:
      'WARNING. Under Delaware Law, an equine professional is not liable for an injury to or the death of a participant in equine activities resulting from the inherent risks of equine activities, pursuant to 10 Delaware Code Section 8140.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  FLORIDA: {
    WARNING:
      'WARNING. Under Florida law, an equine sponsor or equine professional is not liable for an injury to, or the death of, a participant in equine activities resulting from the inherent risks of equine activities.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  GEORGIA: {
    WARNING:
      'WARNING. Under Georgia law, an equine activity sponsor or equine professional is not liable for an injury to or the death of a participant in equine activities resulting from the inherent risks of equine activities, pursuant to Chapter 12 of Title 4 of the Official Code of Georgia Annotated.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  HAWAII: {
    WARNING: 'Hawaii does not have any equine state warnings and/or provisions.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  IOWA: {
    WARNING:
      'WARNING. ****UNDER IOWA LAW, A DOMESTICATED ANIMAL PROFESSIONAL IS NOT LIABLE FOR DAMAGES SUFFERED BY, AN INJURY TO, OR THE DEATH OF A PARTICIPANT RESULTING FROM THE INHERENT RISKS OF DOMESTICATED ANIMAL ACTIVITIES, PURSUANT TO IOWA CODE CHAPTER 673. YOU ARE ASSUMING INHERENT RISKS OF PARTICIPATING IN THIS DOMESTICATED ANIMAL ACTIVITY.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  IDAHO: {
    WARNING: 'Idaho does not have any equine state warnings and/or provisions.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  ILLINOIS: {
    WARNING:
      'WARNING. Under the Equine Activity Liability Act, each participant who engages in an equine activity expressly assumes the risks of engaging in and legal responsibility for injury, loss, or damage to person or property resulting from the risk of equine activities.',
    POINTS: [],
    POINTS_HEADING: '',
  },

  INDIANA: {
    WARNING:
      'WARNING. Under Indiana law, an equine professional is not liable for an injury to, or the death of, a participant in equine activities resulting from the inherent risks of equine activities.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  KANSAS: {
    WARNING:
      'WARNING. Under Kansas law, there is no liability for an injury to or the death of a participant in domestic animal activities resulting from the inherent risks of domestic animal activities, pursuant to sections 1 through 4. You are assuming the risk of participating in this domestic animal activity.',
    POINTS_HEADING:
      'Kansas Statement of Inherent Risks: Inherent risks of domestic animal activities include, but shall not be limited to: ',
    POINTS: [
      '1) The propensity of a domestic animal to behave in ways i.e., running, bucking, biting, kicking, shying, stumbling, rearing, falling or stepping on, that may result in an injury, harm or death to persons on or around them;',
      '2) The unpredictability of a domestic animal’s reaction to such things as sounds, sudden movement and unfamiliar objects, persons, or other animals;',
      '3) certain hazards such as surface and subsurface conditions;',
      '4) collisions with other domestic animals or objects; and',
      '5) the potential of a participant to act in a negligent manner that may contribute to injury to the participant or others, such as failing to maintain control over the domestic animal or not acting within such participant’s ability.',
    ],
  },

  KENTUCKY: {
    WARNING:
      'WARNING. Under Kentucky law, a farm animal activity sponsor, farm animal professional or other person does not have the duty to eliminate all risks of injury of participation in farm animal activities. There are inherent risks of injury that you voluntarily accept if you participate in farm animal activities.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  LOUISIANA: {
    WARNING:
      'WARNING. Under Louisiana law, a farm animal activity sponsor or farm animal professional is not liable for an injury to or the death of a participant in a farm animal activity resulting from the inherent risks of the farm animal activity, pursuant to R.S. 9:2795.1.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  MASSACHUSETTS: {
    WARNING:
      'WARNING. Under Massachusetts law, an equine professional is not liable for an injury to, or the death of, a participant in equine activities resulting from the inherent risks of equine activities, pursuant to section 2D of chapter 128 of the General Laws.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  MARYLAND: {
    WARNING: 'Maryland does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  MAINE: {
    WARNING:
      'WARNING. UNDER MAINE LAW, AN EQUINE PROFESSIONAL HAS LIMITED LIABILITY FOR AN INJURY OR DEATH RESULTING FROM THE INHERENT RISKS OF EQUINE ACTIVITIES.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  MICHIGAN: {
    WARNING: 'Michigan does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  MINNESOTA: {
    WARNING: 'Minnesota does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  MISSOURI: {
    WARNING:
      'WARNING. Under Missouri law, an equine professional is not liable for an injury to or the death of a participant in equine activities resulting from the inherent risks of equine activities pursuant to the Revised Statutes of Missouri.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  MISSISSIPPI: {
    WARNING:
      'WARNING. Under Mississippi law, an equine or livestock activity sponsor or an equine or livestock professional is not liable for an injury to or the death of a participant in equine activities or livestock shows resulting from the inherent risks of equine activities or livestock shows, pursuant to this chapter.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  MONTANA: {
    WARNING: 'Montana does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  'North Carolin': {
    WARNING:
      'WARNING. Under North Carolina law, an equine activity sponsor or equine professional is not liable for an injury to or the death of a participant in equine activities resulting exclusively from the inherent risks of equine activities. Chapter 99E of the North Carolina General Statutes.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  'NORTH DAKOTA': {
    WARNING: 'North Dakota does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  NEBRASKA: {
    WARNING:
      'WARNING. Under Nebraska law, an equine professional is not liable for an injury to or the death of a participant in equine activities resulting from the inherent risks of equine activities, pursuant to this act [Sections 25-21, 249 to 25-21, 253].',
    POINTS_HEADING: '',
    POINTS: [],
  },

  'NEW HAMPSHIRE': {
    WARNING: 'New Hampshire does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  'NEW JERSEY': {
    WARNING: 'New Jersey does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  'NEW MEXICO': {
    WARNING: 'New Mexico does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  NEVADA: {
    WARNING: 'Nevada does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  'NEW YORK': {
    WARNING: 'New York does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  OHIO: {
    WARNING:
      'WARNING. Ohio Statement of Inherent Risks: Inherent risk of an equine activity” means a danger or condition that is an integral part of an equine activity, including, but not limited to, any of the following:',
    POINTS_HEADING: '',
    POINTS: [
      'The propensity of an equine to behave in ways that may result in injury, death, or loss to persons on or around the equine;',
      'The unpredictability of an equine’s reaction to sounds, sudden movement, unfamiliar objects, persons, or other animals;',
      'Hazards, including, but not limited to, surface or subsurface conditions;',
      'Collision with another equine, another animal, a person, or an object;',
      'The potential of an equine activity participant to act in a negligent manner that may contribute to injury, death, or loss to the person of the participant or to other persons, including but not limited to, failing to maintain control over an equine or failing to act within the ability of the participant.',
    ],
  },

  OKLAHOMA: {
    WARNING: 'Oklahoma does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  OREGON: {
    WARNING:
      'WARNING. In accordance with Oregon Revised Statutes 30.691, Participant (or persons or entities affiliated with Participant), as a condition of participation in The Event, further waives the right to bring an action against the Event Sponsor (as defined in the Assumption of Risk, Waiver, or Release of Liability) for any injury or death arising out of riding, training, grooming or riding as a passenger upon the equine.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  PENNSYLVANIA: {
    WARNING: 'Pennsylvania does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  'RHODE ISLAND': {
    WARNING:
      'WARNING. Under Rhode Island Law, an equine professional, unless he or she can be shown to have failed to be in the exercise of due care, is not liable for an injury to, or the death of, a participant in equine activities resulting from the inherent risks of equine activities, pursuant to this chapter.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  'SOUTH CAROLINA': {
    WARNING:
      'WARNING. Under South Carolina law, an equine activity sponsor or equine professional is not liable for an injury to or the death of a participant in an equine activity resulting from an inherent risk of equine activity, pursuant to Article 7, Chapter 9 of Title 47, Code of Laws of South Carolina, 1976.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  'SOUTH DAKOTA': {
    WARNING:
      'WARNING. Under South Dakota law, an equine professional is not liable for an injury to or the death of a participant in equine activities resulting from the inherent risks of equine activities, pursuant to Section 42-11-2.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  TENNESSEE: {
    WARNING:
      'WARNING. Under Tennessee Law, an equine professional is not liable for an injury to or death of a participant in equine activities from the inherent risks of equine activities, pursuant to Tennessee Code Annotated, title 44, chapter 20.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  TEXAS: {
    WARNING:
      'WARNING. Under Texas law (Chapter 87, Civil Practice and Remedies Code), an equine professional is not liable for an injury to or the death of a participant in equine activities resulting from the inherent risks of equine activities.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  UTAH: {
    WARNING:
      'WARNING. Pursuant to the Utah Equine Activity Liability Act, § 78-27b-101, et seq., it shall be presumed that participants in equine or livestock activities are aware of and understand that there are inherent risks associated with these activities. An equine activity sponsor, equine professional, livestock activity sponsor, or livestock professional is not liable for an injury to or the death of a participant due to the inherent risks associated with these activities. “Inherent risk” with regard to equine or livestock activities means those dangers or conditions which are an integral part of equine or livestock activities, which may include:',
    POINTS_HEADING: '',
    POINTS: [
      '(a) The propensity of the animal to behave in ways that may result in injury, harm, or death to persons on or around them;',
      '(b) the unpredictability of the animal’s reaction to outside stimulation such as sounds, sudden movement, and unfamiliar objects, persons, or other animals;',
      '(c) collisions with other animals or objects;',
      '(d) the potential of a participant to act in a negligent manner that may contribute to injury to the participant or others, such failing to maintain control over the animal or not acting within his or her ability.',
    ],
  },

  VIRGINIA: {
    WARNING:
      'WARNING. **Notice of Intrinsic Dangers of Equine Activities pursuant to Code of Virginia § 3.1-796.130c: “**Intrinsic dangers of equine activities” means those dangers or conditions that are an integral part of equine activities, including but not limited to, ',
    POINTS_HEADING: '',
    POINTS: [
      '(i) the propensity of equines to behave in ways that may result in injury, harm, or death to persons on or around them;',
      '(ii) the unpredictability of an equine’s reaction to such things as sounds, sudden movement, and unfamiliar objects, persons, or other animals; ',
      '(iii) certain hazards such as surface and subsurface conditions;',
      '(iv) collisions with other animals or objects; and',
      '(v) the potential of a participant acting in a negligent manner that may contribute to injury to the participant or others, such as failing to maintain control over the equine or not acting within the participant’s ability.',
    ],
  },

  VERMONT: {
    WARNING:
      'WARNING. Under Vermont Law, an equine activity sponsor is not liable for an injury to, or the death of, a participant in equine activities resulting from the inherent risks of equine activities that are obvious and necessary, pursuant to 12 V.S.A § 1039.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  WASHINGTON: {
    WARNING: 'Washington does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  WISCONSIN: {
    WARNING:
      'NOTICE: A person who is engaged for compensation in the rental of equines or equine equipment or tack or in the instruction of a person in the riding or driving of an equine or in being a passenger upon an equine is not liable for the injury or death of a person involved in equine activities resulting from the inherent risks of equine activities, as defined in section 895.481(1)(e) of the Wisconsin Statute.',
    POINTS_HEADING: '',
    POINTS: [],
  },

  'WEST VIRGINIA': {
    WARNING:
      'WARNING. West Virginia Equestrian Activities Responsibility Act Statement for Horsemen: I acknowledge and agree that I will perform the duties required of all horsemen under the West Virginia Equine Activities Responsibility Act, W. Va. Code § 20-4-1, et seq., which include the following:',
    POINTS_HEADING: '',
    POINTS: [
      '(1) Make reasonable and prudent efforts to determine the ability of a participant to safely engage in the equestrian activity, to determine the ability of the horse to behave safely with the participant, and to determine the ability of the participant to safely manage, care for and control the particular horse involved;',
      '(2) Make known to any participant any dangerous or any physical impairments or conditions related to a particular horse which is involved in the equestrian activity of which the horseman knows or through the exercise of due diligence could know;',
      '(3) Make known to any participant any dangerous condition as to land or facilities under the lawful possession or control of the horseman of which the horseman knows or through the exercise of due diligence could know, by advising the participant in writing or by conspicuously posting warning signs on the premises;',
      '(4) In providing equipment or tack to a participant, make reasonable and prudent efforts to inspect such equipment or tack to assure that it is in proper working condition and safe for use in the equestrian activity;',
      '(5) Prepare and present to each participant or prospective participant, for his or her inspection and signature, a statement which clearly and concisely explains the liability limitations, restrictions, and responsibilities set forth under the West Virginia Equine Activities Responsibility Act.',
    ],
  },

  WYOMING: {
    WARNING: 'Wyoming does not have any equine state warnings and/or provisions.',
    POINTS_HEADING: '',
    POINTS: [],
  },
}

export default STATE_CLAUSES
