// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React from 'react'
import DrawerComponent from './Components/DrawerComponent'
import GlobalButton from './Components/GlobalButton'
import CheckedInput from './Components/CheckedInput'
import SwitchElementUpdated from './SwitchElement'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ModalRow: React.FC<{
  placeholder: string
  value?: string
  onChange?: () => void
  on: boolean
  onSwitch: () => void
}> = ({ placeholder, value, onChange, on, onSwitch }) => (
  <div className="flex gap-2 mb-2">
    <CheckedInput
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      notChecked={!value}
      outlined={!value}
    />
    <div className="overflow-hidden min-w-[145px]">
      <SwitchElementUpdated
        on={on}
        onSwitch={onSwitch}
        onText="For Sale"
        offText="Reserved"
        width={140}
      />
    </div>
  </div>
)

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const SponsorsAutofillModal: React.FC<{ show: boolean; onHide: () => void }> = ({
  show,
  onHide,
}) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <DrawerComponent
      show={show}
      onHide={onHide}
      title="Autofill section sponsor fees "
      maxWidth={640}
    >
      <ModalRow placeholder="Equitation sections" on={false} onSwitch={() => {}} />
      <ModalRow placeholder="Equitation sections" on={true} onSwitch={() => {}} value={'$500'} />
      <ModalRow placeholder="Classic sections" on={false} onSwitch={() => {}} />
      <ModalRow placeholder="Derby sections" on={false} onSwitch={() => {}} />
      <ModalRow placeholder="League sections" on={false} onSwitch={() => {}} />
      <ModalRow placeholder="Jumper sections" on={false} onSwitch={() => {}} />
      <GlobalButton type={1} caption="Fill" className="w-[270px] mx-auto block mt-20" />
    </DrawerComponent>
  )
}

export default SponsorsAutofillModal
