export const SheetFEIPonyRidersPreliminary = {
  id: 'SheetFEIPonyRidersPreliminary',
  name: 'FEI Pony Riders Preliminary',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 340,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>MXK •</span> Medium trot</p><p><span>KAF •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>FB •</span> Shoulder-in left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>B •</span> Volte left (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>BG •</span> Half-pass to the left</p><p><span>G •</span> On centre line</p><p><span>C •</span> Track to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>HXF •</span> Extended trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>N/A •</span> Transitions at H and F</p><p><span>FA •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>A •</span> Halt - immobility; Rein back 4 steps and immediately proceed in collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>KE •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>E •</span> Volte right (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>EG •</span> Half-pass to the right</p><p><span>G •</span> On centre line</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>Between C &amp; M •</span> [Medium walk]</p><p><span>M •</span> [Turn right]</p><p><span>Between G &amp; H •</span> Collected walk and half pirouette to the right, proceed in medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>Between G &amp; M •</span> Collected walk and half pirouette to the left, proceed in medium walk up to H</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>N/A •</span> The medium walk from the transition between C and M up to the transition to extended walk at H</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>HSXP •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description: '<p><span>PFA •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>A •</span> Proceed in collected canter right</p><p><span>AV •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>V •</span> Volte right (8 m Ø)</p><p><span>VE •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>E •</span> Turn right</p><p><span>X •</span> Simple change of leg</p><p><span>B •</span> Track to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>R •</span> Volte left (8 m Ø)</p><p><span>RMCH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>HP •</span> Medium canter</p><p><span>P •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description: '<p><span>PFA •</span> Counter canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description: '<p><span>A •</span> Simple change of leg</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>AKV •</span> Collected canter</p><p><span>VR •</span> Medium canter</p><p><span>R •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description: '<p><span>RMC •</span> Counter canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description: '<p><span>C •</span> Simple change of leg</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 27,
          description:
            '<p><span>HK •</span> Extended canter</p><p><span>K •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 28,
          description:
            '<p><span>N/A •</span> Transitions at H and K</p><p><span>KA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 29,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIPonyRidersTeam = {
  id: 'SheetFEIPonyRidersTeam',
  name: 'FEI Pony Riders Team',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 330,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>RK •</span> Volte right (10 m Ø)</p><p><span>KAF •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>FB •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>BX •</span> Half volte right (10 m Ø)</p><p><span>XE •</span> Half pass to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>EG •</span> Turn left</p><p><span>G •</span> Halt - immobility; Rein back 4 steps and immediately proceed in collected trot</p><p><span>C •</span> Turn left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Between C & M • Medium trot</p><p><span>M •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p>Between G & H • Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p>Between G & H • Extended walk</p><p><span>GHS •</span> [Medium walk]</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>N/A •</span> The medium walk from G up to the transition into extended walk at S</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>SP •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>PF •</span> Medium walk</p><p><span>F •</span> Proceed in collected trot</p><p><span>FAK •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>KE •</span> Proceed in collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>EX •</span> Half volte right (10 m Ø)</p><p><span>XB •</span> Half volte left (10 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>BG •</span> Half volte left (10 m Ø)</p><p><span>G •</span> Half pass to the left</p><p><span>C •</span> Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>HXF •</span> Extended trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>N/A •</span> Transitions at M and K</p><p><span>FA •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>A •</span> Halt – immobility</p><p><span>N/A •</span> Rein back 5 steps and immediately proceed in collected canter</p><p><span>AK •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>KH •</span> [Collected canter]</p><p><span>H •</span> [Turn left]</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description: '<p><span>N/A •</span> The collected canter A-F-P-V-E</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>HG •</span> Half pass to the right</p><p><span>SEVKA •</span> Track to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>A •</span> Simple change of leg</p><p><span>AF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description: '<p><span>FLE •</span> Collected canter with flying change of leg at L</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description: '<p><span>ESHC •</span> [Collected canter]</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>C •</span> Simple change of leg</p><p><span>CMR •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>RI •</span> Half volte right (10 m Ø)</p><p><span>I •</span> Simple change of leg</p><p><span>IS •</span> Half volte left (10 m Ø)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>SV •</span> Extended canter</p><p><span>V •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description:
            '<p><span>VL •</span> Half volte left (10 m Ø)</p><p><span>L •</span> Simple change of leg</p><p><span>LP •</span>Half volte right (10 m Ø)</p><p><span>PFA •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 28,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIPonyRidersIndividual = {
  id: 'SheetFEIPonyRidersIndividual',
  name: 'FEI Pony Riders Individual',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 350,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>AX •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility- salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the left</p><p><span>HB •</span> Medium trot</p><p><span>B •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>B •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>BF •</span> Half volte right (10 m Ø)</p><p><span>FA •</span> Half volte left (10 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>A •</span> Half pass to the left</p><p><span>DB •</span> On centre line</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>BG •</span> Half circle right (20 m Ø). Let the horse stretch on a long rein.</p><p><span>G •</span> Collected trot</p><p><span>C •</span> Retake the reins</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>ME •</span> Extended trot</p><p><span>E •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>E •</span> Transitions at M and K</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>EK •</span> Shoulder-in left</p><p><span>KA •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>A •</span> Half volte left (10 m Ø)</p><p><span>AF •</span> Half volte right (10 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>FXH •</span> Half pass to the right</p><p><span>H •</span> On centre line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>N/A •</span> [Collected walk]</p><p><span>HC •</span> [Track to the left]</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>CM(G) •</span> The collected walk C-H-G-H</p><p><span>Before G •</span> Collected walk</p><p><span>G •</span> Half pirouette to the right, proceed in medium walk up to M</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>N/A •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>MRXV •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>V •</span> Half pirouette to the right</p><p><span>Between V & K •</span> Collected walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description: '<p><span>AX •</span> Proceed in collected canter right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description: '<p><span>X •</span> Half pass to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description: '<p><span>XC •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>CM •</span> Medium canter</p><p><span>MV •</span> Collected canter</p><p><span>VK •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>K •</span> Counter canter</p><p><span>KA •</span>  Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>A •</span> Flying change of leg</p><p><span>L •</span> Volte left (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>LX •</span> Half volte right (10 m Ø)</p><p><span>X •</span> Down the centre line</p><p><span>XI •</span> Simple change of leg</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 24,
          description: '<p><span>I •</span> Half pass to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>IG •</span> Flying change of leg</p><p><span>G •</span> Track to the right</p><p><span>C •</span> Track to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>HP •</span> Extended canter</p><p><span>P •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description:
            '<p><span>N/A •</span> Transitions at H and P</p><p><span>PF •</span> Counter canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 28,
          description:
            '<p><span>F •</span> Flying change of leg</p><p><span>A •</span> Collected canter</p><p><span>DI •</span> Medium trot</p><p><span>IG •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 29,
          description: '<p><span>G •</span> Down the centre line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}
