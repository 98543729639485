import { useEffect, useRef, useState } from 'react'

// Third party
import clsx from 'clsx'
import { capitalize, sumBy } from 'lodash'

// Types
import { IEventDetailData } from '../../../../models/event-drafts/event-draft.interface'

// Redux
import {
  selectRegistrationR,
  selectResetRefundsTotals,
  setExhibitorRefundErrors,
  setExhibitorRefundTotals,
} from '../../../../store/exhibitor/exhibitorSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

import AmountInput from '../../../../components/common/inputs/AmountInput'
import { createString, toFixed } from '../../../../helpers/helpers'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'
import { setScratchEvents } from '../../../../store/events/eventsSlice'
import { IOnRefundAmountChangeFn } from '../ExhibitorProfileDisplayTab'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

type IExhibitorRegistrationRefundAccordionProps = {
  onRefundAmountChange?: IOnRefundAmountChangeFn
  registrationsByDay: IRegistrationByDayInterface[]
}

type IErrorsRef = {
  [key: string]: {
    message: string
    toastMessage: string
  }
}

type IValidate = (
  inputName: string,
  maxCost: number,
  value: number,
  eventDay: string,
  eventDate: string | Date,
  docId: string | null
) => void

type IDetailRowProps = {
  errors: React.MutableRefObject<IErrorsRef>
  registrationByDay: IRegistrationByDayInterface
  validate: IValidate
}

type IEventsToScratch = {
  memberId: string
  eventDate: string | Date
}

type IEventsToScratchList = {
  memberIdList: string[]
  eventDate: string | Date
}

const ExhibitorRegisrationInput: React.FC<{
  className?: string
  data?: any
  icon?: any
}> = ({ className, data, icon }) => {
  return (
    <>
      {icon ? (
        <div className={`${className}`}>
          <img className="absolute left-3 top-3" src={icon} alt="icons" />
          {data}
        </div>
      ) : (
        <div className={`${className}`}>{data}</div>
      )}
    </>
  )
}

const DetailRow = ({ registrationByDay, validate, errors }: IDetailRowProps) => {
  let currAmountFieldName = `${registrationByDay.id}`
  let [currentValue, setCurrentValue] = useState<number | string>(registrationByDay.amountRefunded)

  return (
    <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
      <ExhibitorRegisrationInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6 overflow-hidden whitespace-nowrap text-ellipsis"
        data={capitalize(createString(registrationByDay.registrationByDayName))}
      />

      <ExhibitorRegisrationInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6 text-center relative"
        icon="/assets/og_icons/YearofHorse-1.svg"
        data={capitalize(
          createString(
            registrationByDay.noHorseSelected
              ? 'No Horse'
              : registrationByDay.horseName ?? 'Unknown'
          )
        )}
      />

      <ExhibitorRegisrationInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6 text-center relative"
        icon="/assets/cp_icons/User-1.svg"
        data={capitalize(createString(registrationByDay.riderName))}
      />

      <ExhibitorRegisrationInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6 text-center"
        data={`$${registrationByDay.registrationPrice}`}
      />

      <ExhibitorRegisrationInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6 text-center"
        data={registrationByDay.isSratched ? 'Scratched' : 'No'}
      />
      <AmountInput
        withDecimals
        name={currAmountFieldName}
        prefix="$"
        placeholder="Enter amount..."
        disable={registrationByDay?.refundAmountAdded || !registrationByDay.isSratched}
        inputClassName={clsx(
          'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor',
          !!errors?.current?.[currAmountFieldName] && 'refundError text-[#EB5757]'
        )}
        className={clsx(
          'text-[14px] border !border-solid rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor w-1/6 m-1 focus:bg-white',
          !registrationByDay?.refundAmountAdded
            ? !!errors?.current?.[currAmountFieldName] &&
                'text-[#EB5757] !bg-[#EB57571A] border-red-500'
            : '!bg-SeabiscuitGrayThemeColor',
          registrationByDay?.refundAmountAdded || !registrationByDay.isSratched
            ? 'bg-SeabiscuitGrayThemeColor'
            : 'bg-white'
        )}
        title={errors?.current?.[currAmountFieldName]?.message}
        onChange={(value: string) => {
          if (registrationByDay.refundAmountAdded) return

          const newValue = value.replace('$', '')
          setCurrentValue(newValue)

          validate(
            currAmountFieldName,
            registrationByDay.registrationPrice ?? 0,
            Number(newValue),
            registrationByDay.registrationByDayName ?? '',
            registrationByDay.eventDate as string,
            registrationByDay.id ?? ''
          )
        }}
        value={currentValue}
      />
    </div>
  )
}

const ExhibitorRegistrationRefundAccordion = ({
  onRefundAmountChange,
  registrationsByDay,
}: IExhibitorRegistrationRefundAccordionProps) => {
  // Hooks and vars
  const errors = useRef<IErrorsRef>({})

  const dispatch = useAppDispatch()

  const resetRefundTotals = useAppSelector(selectResetRefundsTotals)
  const regisrationR = useAppSelector(selectRegistrationR)

  const [refundsTotal, setRefundsTotal] = useState(0)
  const [itemsSelectedForRefund, setItemsSelectedForRefund] = useState(0)
  const [listOfItemsToRefund, setListOfItemsToRefund] = useState<Set<string>>(new Set())

  const data = regisrationR?.selectionDetails?.selectedEvents

  useEffect(() => {
    setListOfItemsToRefund(new Set())
  }, [resetRefundTotals])

  useEffect(() => {
    let selectedIds: string[] = []
    let total = 0

    registrationsByDay.forEach((registrationByDay) => {
      if (registrationByDay.amountRefunded > 0 && !registrationByDay.refundAmountAdded) {
        total += registrationByDay.amountRefunded
        selectedIds.push(registrationByDay.id ?? '')
      }
    })

    setRefundsTotal(total)
    setItemsSelectedForRefund(selectedIds.length)

    const selectedRegistrationsByDay = registrationsByDay.filter((registrationByDay) =>
      selectedIds.includes(registrationByDay.id ?? '')
    )

    dispatch(
      setExhibitorRefundTotals({
        keyToUpdate: 'eventsRefundPriceTotal',
        value: selectedRegistrationsByDay,
      })
    )
  }, [registrationsByDay])

  const validate: IValidate = (inputName, maxCost, value, name, eventDate, docId) => {
    let message: string | null = null

    if (!maxCost || !inputName) {
      return delete errors.current?.[inputName]
    }

    if (value > maxCost)
      errors.current = {
        ...errors.current,
        [inputName]: {
          message: `max value that can be refunded is $${maxCost}`,
          toastMessage: `${name} max value that can be refunded is $${maxCost}`,
        },
      }
    else delete errors.current?.[inputName]

    onRefundAmountChange?.({
      type: 'classes',
      itemDetails: {
        itemId: inputName,
        itemRefundAmount: value,
      },
    })

    if (value <= maxCost) handleScratchList(eventDate, docId, value)

    if (Object.keys(errors.current).length)
      message = errors.current[Object.keys(errors.current)[0]]?.toastMessage ?? null

    dispatch(
      setExhibitorRefundErrors({
        keyToUpdate: 'eventsError',
        message,
      })
    )
  }

  const handleScratchList = (
    eventDate: string | Date,
    memberId: string | null,
    refundAmount: null | number
  ) => {
    if (!memberId) return

    let list: IEventsToScratchList[] = []
    let listToScratch_ = new Set(listOfItemsToRefund)
    let listToScratchList: IEventsToScratch[] = []

    let objToAdd: IEventsToScratch = { eventDate, memberId }
    let stringifiedObj = JSON.stringify(objToAdd)

    if (refundAmount) listToScratch_.add(stringifiedObj)
    else listToScratch_.delete(stringifiedObj)

    setListOfItemsToRefund(new Set(listToScratch_))

    listToScratch_.forEach((curr) => {
      listToScratchList.push(JSON.parse(curr))
    })

    list = listToScratchList.reduce((acc: IEventsToScratchList[], currListItem) => {
      let existingItemIndex = acc.findIndex((c) => c.eventDate === currListItem.eventDate)
      if (existingItemIndex !== -1) {
        acc[existingItemIndex] = {
          ...acc[existingItemIndex],
          memberIdList: [...acc[existingItemIndex].memberIdList, currListItem.memberId],
        }
      } else {
        acc.push({
          eventDate: currListItem.eventDate,
          memberIdList: [currListItem.memberId],
        })
      }
      return acc
    }, [])

    let updatedDataList_ = data
      ? data.reduce((acc: IEventDetailData[], ce) => {
          let foundSelectedItem = list.find((csi) => csi.eventDate === ce.event_date)
          acc.push({
            ...ce,
            members: ce.members.map((cm) => {
              if (foundSelectedItem?.memberIdList.includes(cm.memberId ?? '') && refundAmount) {
                return {
                  ...cm,
                  refundAmount: refundAmount,
                }
              }

              return cm
            }),
          })
          return acc
        }, [])
      : []

    dispatch(setScratchEvents(updatedDataList_))
  }

  return (
    <>
      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        <ExhibitorRegisrationInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-1 font-semibold w-1/6')}
          data={`Class`}
        />

        <ExhibitorRegisrationInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/6')}
          data={`Registered horse`}
        />

        <ExhibitorRegisrationInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/6')}
          data={`Registered rider`}
        />

        <ExhibitorRegisrationInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/6')}
          data={`Amount paid`}
        />

        <ExhibitorRegisrationInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/6')}
          data={`Scratched?`}
        />

        <ExhibitorRegisrationInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/6')}
          data={`Enter Refund Amount`}
        />
      </div>
      {registrationsByDay.map((currentItem, index) => {
        return (
          <DetailRow
            key={`${JSON.stringify(currentItem.id)}${index}`}
            errors={errors}
            validate={validate}
            registrationByDay={currentItem}
          />
        )
      })}

      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        <ExhibitorRegisrationInput
          className={clsx(
            'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-normal h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1 w-1/6'
          )}
          data="Total"
        />

        <ExhibitorRegisrationInput
          className={clsx(
            'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-normal h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1 w-5/6 text-right'
          )}
          data={`${itemsSelectedForRefund} item, $${toFixed(refundsTotal)} refunded`}
        />
      </div>
    </>
  )
}

export default ExhibitorRegistrationRefundAccordion
