import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { KeyboardBackspace } from '@mui/icons-material'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { ROUTES_CONST } from '../../../../const/routes-const'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import { useAppSelector } from '../../../../store/hooks'
import { selectEventLoading } from '../../../../store/events/eventsSlice'
import { CONST } from '../../../../const/const'

const ManageEventRevenueWrapper = ({
  children,
  eventId,
}: {
  children: ReactJSXElement
  title: string
  eventId: any
  setEventTab: any
  eventTab: any
}) => {
  // Hooks and vars

  const eventsLoading = useAppSelector(selectEventLoading)

  return (
    <>
      <motion.div
        className="w-full mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 0.1,
        }}
      >
        <div className="mx-8 pt-6">
          <h1 className="text-SeabiscuitDark200ThemeColor text-[20px] font-[400] mb-5">
            <Link
              to={`${ROUTES_CONST.MANAGE.CLINIC_N_OTHER.URL}/${eventId}?${CONST.UI.EVENTS.MANAGE_EVENT.TABS.FINANCES.VALUE}=1`}
            >
              <KeyboardBackspace className="textSeabiscuitDark200ThemeColor" />
            </Link>{' '}
            Back
          </h1>
          {eventsLoading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <ViewsLoader
                className="flex items-center w-full justify-center min-h-[30vh] mt-3"
                size="lg"
                color="#F7074F"
              />
            </div>
          ) : (
            <>
              {/* <ManageClinicNOtherTabs eventId={eventId} setEventTab={setEventTab} eventTab={eventTab} /> */}
              <div className="p-8 rounded-xl bg-SeabiscuitWhiteThemeColor">{children}</div>
            </>
          )}
        </div>
      </motion.div>
    </>
  )
}

export default ManageEventRevenueWrapper
