import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'
import { ClassesList } from '../../../../fakeData/classesList'
import Select from 'react-select'
import { customStyles } from '../../../../components/customStyles/ReactSelectCustomStyle'
import { horseList } from '../../../../fakeData/horseList'

type Props = {
  title?: string
  description?: string
  step?: number
}

const LabelIcon = ({ src }: { src: string }) => {
  return <img src={src} width="16px" height="16px" alt="label" />
}

const CompetitorEventRegisterHorseTab = (props: Props) => {
  return (
    <>
      <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
        {/* //for horse select in event class */}
        {props.step === 0 &&
          ClassesList.length &&
          ClassesList.map((item: any, index: number) => {
            return (
              <>
                <div key={index}>
                  <h3 className="text-SeabiscuitDark200ThemeColor my-4 font-semibold capitalize">
                    {item.key}
                  </h3>
                </div>
                {item.category.map((item1: any) => {
                  return (
                    <>
                      {item1.subCategories.map((subCategory: any, index2: any) => {
                        return (
                          <>
                            {subCategory.subClasses.map((mainClass: any, index4: any) => {
                              return (
                                <>
                                  <div className="flex items-center justify-between w-full mb-2">
                                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-full p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize">
                                      <span className="mr-4">
                                        {mainClass.code
                                          ? mainClass.code.substr(0, 5)
                                          : 1000 + index4}
                                      </span>
                                      <span>{mainClass.name}</span>
                                    </p>

                                    <Select
                                      isMulti={false}
                                      options={horseList}
                                      styles={customStyles}
                                      placeholder={
                                        <div className="flex">
                                          <img
                                            src="/assets/og_icons/YearofHorse-1.svg"
                                            width="16px"
                                            className="mr-2"
                                            alt="horse"
                                          />
                                          Select Horse
                                        </div>
                                      }
                                      getOptionLabel={(props: any) => {
                                        const { icon, label } = props
                                        return (
                                          <div className="flex items-center gap-2">
                                            {icon && <LabelIcon src={icon} />}
                                            <span>{label}</span>
                                          </div>
                                        ) as unknown as string
                                      }}
                                      className="border-SeabiscuitLightThemeColor border w-96 rounded-md searchableComponent focus:ring-0"
                                      isClearable={false}
                                      isSearchable={true}
                                    />
                                  </div>
                                </>
                              )
                            })}
                          </>
                        )
                      })}
                    </>
                  )
                })}
              </>
            )
          })}

        {/* // for horse rider select in event class */}
        {props.step === 1 &&
          ClassesList.length &&
          ClassesList.map((item: any, index: number) => {
            return (
              <>
                <div key={index}>
                  <h3 className="text-SeabiscuitDark200ThemeColor my-4 font-semibold capitalize">
                    {item.key}
                  </h3>
                </div>
                {item.category.map((item1: any, index1: any) => {
                  return (
                    <>
                      {item1.subCategories.map((subCategory: any, index2: any) => {
                        return (
                          <>
                            {subCategory.subClasses.map((mainClass: any, index4: any) => {
                              return (
                                <>
                                  <div className="flex items-center justify-between w-full mb-2">
                                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/3 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize">
                                      <span className="mr-4">
                                        {mainClass.code
                                          ? mainClass.code.substr(0, 5)
                                          : 1000 + index4}
                                      </span>
                                      <span>{mainClass.name}</span>
                                    </p>

                                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/3 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                                      <img
                                        src="/assets/og_icons/YearofHorse-1.svg"
                                        alt="horseIcon"
                                        className="absolute left-4"
                                      />
                                      <span className="mr-4">{mainClass.competitorhorseName}</span>
                                    </p>

                                    <Select
                                      isMulti={false}
                                      options={horseList}
                                      styles={customStyles}
                                      getOptionLabel={(props: any) => {
                                        const { icon, label } = props
                                        return (
                                          <div className="flex items-center gap-2">
                                            {icon && <LabelIcon src="/assets/img/darkuser.png" />}
                                            <span>{label}</span>
                                          </div>
                                        ) as unknown as string
                                      }}
                                      className="border-SeabiscuitLightThemeColor border w-1/3 rounded-md searchableComponent focus:ring-0"
                                      isClearable={false}
                                      isSearchable={true}
                                    />
                                  </div>
                                </>
                              )
                            })}
                          </>
                        )
                      })}
                    </>
                  )
                })}
              </>
            )
          })}
      </CompetitorEventRegisterWrapper>
    </>
  )
}

export default CompetitorEventRegisterHorseTab
