import { IBillsSliceInitialStateInterface } from './billsSlice.types'

const billsSliceInitialState: IBillsSliceInitialStateInterface = {
  holder: {
    events: [],
    eventFees: [],
    users: [],
    horses: [],
  },
  selected: {
    event: null,
    eventFees: null,
    registration: null,
    recipientsTotals: [],
    countDetails: {
      feesCount: 0,
      horsesCount: 0,
      ridersCount: 0,
      ticketsCount: 0,
      feesUnitsCount: 0,
      signatoryCount: 0,
      registrationCount: 0,
      ticketsUnitsCount: 0,
      feesRecipientCount: 0,
      eventRecipientCount: 0,
      ticketsRecipientCount: 0,
      paperworkRecipientCount: 0,
    },
  },
  data: {
    allBills: [],
    paidBills: [],
    unPaidBills: [],
    refundedBills: [],
  },
}

export { billsSliceInitialState }
