import { useEffect, useState } from 'react'

import { CONST } from '../../const/const'
import { useAppSelector } from '../../store/hooks'
import { selectProfileData } from '../../store/user/userSlice'
import { IUserInterface } from '../../models/users/user.interface'
import { selectVisitedUserProfileDetails } from '../../store/exhibitor/exhibitorSlice'

import OrganizerRootSideProfileTabs from './OrganizerRootSideProfileTabs/OrganizerRootSideProfileTabs'
import OrganizerRootSideProfileDisplayTabs from './OrganizerRootSideProfileDisplayTabs/OrganizerRootSideProfileDisplayTabs'
import OrganizerRootSideProfileImageSection from './OrganizerRootSideProfileImageSection/OrganizerRootSideProfileImageSection'
import { selectVisitedUserDetailsR } from '../../store/profilePage/profilePageSlice'

type Props = {
  isMyProfile?: boolean
  handleModal?: any
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const OrganizerRootSideProfileCard = (props: Props) => {
  // Hooks and vars
  const loggedInUserData = useAppSelector(selectProfileData)
  const visitedUserProfileData = useAppSelector(selectVisitedUserProfileDetails)

  const visitedUserDetails = useAppSelector(selectVisitedUserDetailsR)

  const [userData, setUserData] = useState<null | IUserInterface>(null)
  const [activeTab, setActiveTab] = useState<string>(CONST.UI.USER.PROFILE.TABS.INFO.VALUE)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!userData) setUserData(props.isMyProfile ? loggedInUserData : visitedUserProfileData)
  }, [props.isMyProfile, loggedInUserData, visitedUserProfileData, userData])

  return (
    <div className="p-7">
      <div className="w-full pb-[30px]">
        <OrganizerRootSideProfileImageSection />

        <OrganizerRootSideProfileTabs activeTab={activeTab} setActiveTab={setActiveTab} />

        <OrganizerRootSideProfileDisplayTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          handleModal={props?.handleModal}
          isMyProfile={visitedUserDetails?.isMyProfile}
          userData={visitedUserDetails?.profileDetails ?? null}
        />
      </div>
    </div>
  )
}

export default OrganizerRootSideProfileCard
