import React from 'react'

interface IProps {}

export const IconHeightImg: React.FC<IProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="56"
      fill="none"
      viewBox="0 0 24 56"
    >
      <path fill="url(#pattern0_11636_11947)" d="M0 0H24V56H0z"></path>
      <defs>
        <pattern
          id="pattern0_11636_11947"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="matrix(.01042 0 0 .00446 0 .286)" xlinkHref="#image0_11636_11947"></use>
        </pattern>
        <image
          id="image0_11636_11947"
          width="96"
          height="96"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAEQklEQVR4nO2dz29UVRTHv+e9GaZQtTMTf0Q0gZBuaIkhFKLsujBtuiClbE3cmhjFKmFh4oIdib9B/QdcuDFi6wIi0QQ2lgQxhjC4IVojUbBx2io/5gdzj4vBhc279VHnnnt7OZ+km3cn/Z68T3vv3DOTdwFFURRFURRFUe43yFdweedoOW0X9xuiSWJsB/AkgH6h+JsArgK4TMBsp9iaXfr+zJJQ9r8QF7B162jfcn/xIIFeB1CWzrewyISji430OK6cakoGiwp4ePv44ybhzwE8LZmbG8K5lAtTC7WT1+QihejefDMH0BapzLXBP6coPiMlIZEIweBEqfuXH/rNBwDa0sGdExicKEmkiQiolMw0Qp12stlb3WBekghyPgWVd46Wk/aGn2BfcD8zCX9QIvr2+sXTN13XAwCPPTXW32TenRh6BcCU5WV17jPbFi98teyyloLLXw4Aabu4n+03/7V67fR7rmtYyV3RZwGcrQ6NHwLx2xkvqyaNdBLAxy5rcT4FGaLJ7BE+4ePmr6R++ct3mDCTNcYwltp7h3MBxDSUdd0YOuY6Oy9M/H72CA27zhZYhHlz1tVi0vrOfXY+iqZ9wTL0hOtsiXdBD2RdXKiduSGQnYtVasmsvZfI7AMUKyrAMyrAMyrAM843YlJsHtm3qdlovsXAcwAGfNeTl2gENJrNdwG84LuOeyWSKehIAsbzvqtYC5EIWL9EIuCIAbltmrkiEgHApgf/epXBHwLw8uH6WolmEb46N3cbwMt3f+6Z6vAY97aifETzH7BeUQGeUQGeUQGeUQGeUQGeUQGeiWYfYCP0Lmn0AkLvkkY+BYXfJY1cQPhELiD8LmnkAsLvkka/COftkmo39D5FBXhGBXhGBXhGBXhGBXhGBXgmmn1A6F1PG9EICL3raSOSKSj8rqeNSASsXyIREH7X00YkAsLvetqIZhHW74Yqa0IFeEYFeEYFeEYFeEYFeEYFeCaafYCN0Luk0QsIvUsa+RQUfpc0cgHhE7mA8LukkQsIv0sa/SKs3w1VVkUFeEYFeEYFeEZCQOZTaR8ZHnX+VNq8VAcnHrIM/ek6W0AA/Zp1tZ0UR9xn5yPp412Wod+cZ7sOALiWGdw9PCEIOmymLUOXXGe7f3w9MGsZmqoOjR9ynf9fVHaMHSYg85wAIrLV3jOkjjD5EUAla5wJMymSY2lizkseYdIxyZ4Om2nbzYfQESYix1hVdowdJsabElk9ROR4FZG3oYuN9DgI5ySyesQ3dQx8JBEksw+4cqqZcmEKwLxI3v9jvkCdA6h92pIIE9uILdROXmu3W3uoe3pRqMylKOz9/dLX16UCU6kgAGj9MX/r9qO7PtnIrWUQdgPYKJm/CnWA36ij/OKt2qzTRXcl3o6zrYw8O5A00snuUVE0hO5xtlK74xsAfgH4B6JkxpQ6X7h+t6MoiqIoiqIoivIPfwP6lURl9JaHOwAAAABJRU5ErkJggg=="
        ></image>
      </defs>
    </svg>
  )
}
