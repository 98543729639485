const ExhibitorEligabilityAccordion = ({
  problems,
  setProblems,
  data,
}: {
  problems: number
  setProblems: any
  data: any
}) => {
  return (
    <>
      {/* <div className='flex items-center w-full'>
        <ExhibitorRegisrationInput
          className='xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-3/12'
          // data={`Eligibility item`}
          data={<span className="flex items-center text-[14px]">Eligibility item
            </span>}
        />
        <ExhibitorRegisrationInput
          className='xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/12'
          data={<span className="flex items-center text-[14px]">Status</span>}
        />
        <ExhibitorRegisrationInput
          className='xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-5/12'
          data={<span className="flex items-center text-[14px]">Details</span>}
        />
        <ExhibitorRegisrationInput
          className='xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-3/12'
          data={<span className="flex items-center text-[14px]">Date</span>}
        />
      </div>

      {
        data && data.length ? data.map((currentItem: any, index: number) => {
          return (
            <DetailRow
              key={`${JSON.stringify(currentItem)}${index}`}
              title={currentItem.eligabilityType}
              status={currentItem.status}
              details={currentItem.details}
              date={currentItem.date}
              setProblems={setProblems}
              problems={problems}
            />)
        }) : null
      } */}
      <div className="flex justify-center">
        <div className="flex-col">
          <div className="flex justify-center text-SeabiscuitDark200ThemeColor">
            USEF eligibility coming soon
          </div>
          <img src="/assets/placeholders/eligibility.svg" alt="eligibility" />
        </div>
      </div>
    </>
  )
}

export default ExhibitorEligabilityAccordion
