/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import DataTableLoader from '../../pages/myEvent/component/DataTableLoader'
import NoDataAvailable from '../../pages/myEvent/component/NoDataAvailable'
import { getOrganizerPaymentTransactions } from '../../pages/myEvent/data/eventCols'
import { myEventsTableStyles } from '../../pages/myEvent/data/myEventsTableStyles'
import InfiniteScrollDataTable from '../common/tables/InfiniteScrollDataTable'
const NO_EVENTS_IMAGE_BLANK = 'assets/placeholders/insuficientFund.svg'

type Props = {
  connectAccountDetail?: any
  fetMoreData?: any
  hasMore: boolean
  loading?: boolean
  message?: any
  connectAllPayouts?: any
  handleModal?: any
  stripeConnectAccountId?: string
}

const OrganizerStripeTransactions = ({
  hasMore,
  loading,
  fetMoreData,
  connectAllPayouts,
  handleModal,
  connectAccountDetail,
  stripeConnectAccountId,
}: Props) => {
  //hooks and vars
  const cols = getOrganizerPaymentTransactions()

  return (
    <span className="overflow-auto">
      <InfiniteScrollDataTable
        fetchMore={fetMoreData}
        columns={cols}
        hasMore={hasMore}
        fixedHeader={true}
        noTableHead={true}
        data={connectAllPayouts}
        progressPending={loading}
        noDataComponent={
          <NoDataAvailable
            handleModal={handleModal}
            data={
              connectAccountDetail.length && {
                last4: connectAccountDetail[0]?.last4,
                stripeConnectAccountId,
              }
            }
            imgSrc={NO_EVENTS_IMAGE_BLANK}
            bottomText={'Make your first transfer now >'}
            text={'You have not transferred any funds '}
          />
        }
        className={clsx('transition-all', connectAllPayouts?.length && '')}
        customStyles={myEventsTableStyles}
        progressComponent={
          <div className="md:h-[300px] 2xl:h-[500px] flex items-center 12312">
            <DataTableLoader />
          </div>
        }
        noHeader={false}
      />
    </span>
  )
}

export default OrganizerStripeTransactions
