/* eslint-disable no-lone-blocks */
import { useState } from 'react'

// Third party
import { BadgeOutlined } from '@mui/icons-material'

// Constants
import { MODAL_CONSTS } from '../../../const/modal-const'

// Helpers
import { useIonRouter } from '@ionic/react'
import { LOCALSTORAGE_CONST } from '../../../const/local-storage-const'
import MainModal from '../../modals/common/MainModal'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type Props = {
  show: boolean
  handleModal: any
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const UserProfileHorseTabAddHorse = (props: Props) => {
  // Hooks and vars
  const router = useIonRouter()
  const history = useHistory()
  const location = useLocation()

  const [step, setStep] = useState('0')
  const [opentab, setOpenTab] = useState('')

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Adding horse into data
   */
  const handleAddHorse = async () => {
    // let horseCollectionRef,
    //     firestoreQuery,
    //     allDocs,
    //     queryConstraints: QueryConstraint[] = [],
    //     horseAdded: HorseModel[];
    // if (!selectedHorses.length) {
    //     return toastFunctions.success({ message: MESSAGES_CONST.HORSE_SELECT_VALIDATION })
    // }
    // try {
    //     selectedHorses.forEach(async (currSelectedHorse: any) => {
    //         queryConstraints.push(where(
    //             CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.FIELDS.FILTER.HORSE_ID.KEY,
    //             "==",
    //             currSelectedHorse.horseId
    //         ));
    //         horseCollectionRef = collection(FirebaseApp.firestore, CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME);
    //         firestoreQuery = query(horseCollectionRef, ...queryConstraints);
    //         allDocs = await getDocs(firestoreQuery)
    //         horseAdded = allDocs?.docs.map((doc: any) => HorseModel.fromFirestoreDoc(doc))
    //         horseAdded[0].horseStatus = "1"
    //         await FirestoreService
    //             .updateItem(
    //                 CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME,
    //                 horseAdded[0].id,
    //                 horseAdded[0].toFirestore()
    //             )
    //         dispatch(setHorsesSelectedForCompetetion(getConvertedData(horseAdded[0])))
    //         dispatch(
    //             updateEventHorse({
    //                 data: getConvertedData(horseAdded[0]),
    //                 index: horseData.map((e) => {
    //                     return e.horseId
    //                 }).indexOf(horseAdded[0].horseId || "")
    //             })
    //         )
    //     });
    //     props.handleModal(false, MODAL_CONSTS.HORSE)
    //     toastFunctions.success({ message: MESSAGES_CONST.HORSE_ADDED_SUCCESSFULLY })
    // } catch (err) {
    //     helpers.logger({
    //         isError: true,
    //         message: err
    //     })
    // }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handelOpenTab = () => {
    const removeHorse = opentab === 'remove'

    if (opentab === 'add') {
      localStorage.setItem(LOCALSTORAGE_CONST.ADD_HORSE_FROM, location.pathname)
      router.push('/add-horse')
      history.push('/add-horse')
      closeModal()
      return
    }

    closeModal()

    props.handleModal(true, MODAL_CONSTS.SEARCH_HORSE_BY_NAME, {
      removeHorse,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const closeModal = () => {
    props.handleModal(false, MODAL_CONSTS.HORSE)
  }

  return (
    <MainModal
      title={step !== '3' ? 'Add horse' : 'Create new horse'}
      titleClassName="my-4"
      customTitle={
        <div>
          <h6 className="text-lg leading-[24px] text-SeabiscuitDark200ThemeColor font-normal mt-2">
            {step !== '3' && step !== '1' ? 'Select method to add horse' : ''}
          </h6>
        </div>
      }
      size="md"
      type="HORSE"
      show={props.show}
      buttons={[
        ...(step === '0'
          ? [
              {
                label: 'NEXT >',
                bgClass: 'bg-transparent',
                textClass: 'text-SeabiscuitMainThemeColor',
                onClick: () => step === '0' && handelOpenTab(),
                borderClass: 'border !border-SeabiscuitMainThemeColor',
                onHoverClass: 'hover:text-white hover:bg-SeabiscuitMainThemeColor',
              },
            ]
          : []),
        ...(step === '1'
          ? [
              {
                label: 'NEXT >',
                onClick: () => handleAddHorse,
                bgClass: 'bg-SeabiscuitMainThemeColor',
                textClass: 'text-white',
                onHoverClass: 'hover:text-white hover:bg-SeabiscuitMainThemeColor',
              },
            ]
          : []),
        ...(step === '3'
          ? [
              {
                label: 'SEARCH >',
                onClick: () => handleAddHorse,
                bgClass: 'bg-SeabiscuitMainThemeColor',
                textClass: 'text-white',
                onHoverClass: 'hover:text-white hover:bg-SeabiscuitLightThemeColor',
              },
            ]
          : []),
        ...(step !== '0' && step !== '3'
          ? [
              {
                label: 'BACK',
                onClick: () => setStep('0'),
                bgClass: '!bg-SeabiscuitLightThemeColor',
                borderClass: 'border border-transparent',
                textClass: '!text-SeabiscuitLightTextColor',
              },
            ]
          : []),
      ]}
    >
      <>
        {step === '0' && (
          <>
            <div className="w-full min-h-[300px] h-full">
              <div className="w-full grid grid-cols-2 gap-3 ">
                <div
                  role="button"
                  onClick={() => setOpenTab('search')}
                  className={`w-full mx-auto  flex flex-col py-2 px-4 border rounded-xl shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor
                                        ${opentab === 'search' ? ' focus:border-SeabiscuitMainThemeColor border-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor' : ' border-SeabiscuitGray500ThemeColor text-SeabiscuitLightTextColor'}
                                        `}
                >
                  {opentab === 'search' ? (
                    <img src="/assets/cp_icons/Search-4.svg" className="w-[30px]" alt="search" />
                  ) : (
                    <img src="/assets/cp_icons/Search.svg" className="w-[30px]" alt="search" />
                  )}
                  <span
                    className={`text-sm font-normal leading-[25px] italic ${opentab === 'search' ? 'text-[#fff]' : ''}`}
                  >
                    Search horses
                  </span>
                  <span
                    className={`text-base font-normal leading-[24px] ${opentab === 'search' ? 'text-[#fff]' : ''}`}
                  >
                    Search horses for Pegasus
                  </span>
                </div>

                <div
                  role="button"
                  onClick={() => setOpenTab('add')}
                  className={`w-full mx-auto  flex flex-col py-2 px-4 border  rounded-xl shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2 focus:ring-[#D3DAEE]
                                        ${opentab === 'add' ? ' focus:border-SeabiscuitMainThemeColor border-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor' : ' border-SeabiscuitGray500ThemeColor text-SeabiscuitLightTextColor'}
                                        `}
                >
                  {opentab === 'add' ? (
                    <img
                      src="/assets/cp_icons/Cancel-4.svg"
                      className="w-[30px] rotate-45"
                      alt="cancel"
                    />
                  ) : (
                    <img
                      src="/assets/cp_icons/Cancel.svg"
                      className="w-[30px] rotate-45"
                      alt="cancel"
                    />
                  )}

                  <span
                    className={`text-sm font-normal leading-[25px] italic ${opentab === 'add' ? 'text-[#fff]' : ''}`}
                  >
                    Add new horse
                  </span>
                  <span
                    className={`text-base font-normal leading-[24px] ${opentab === 'add' ? 'text-[#fff]' : ''}`}
                  >
                    Create a new horse profile
                  </span>
                </div>

                {/* <div
                                    role="button"
                                    onClick={() => setOpenTab("remove")}
                                    className={`w-full mx-auto flex flex-col py-2 px-4 border  rounded-xl shadow-sm text-sm font-medium focus:ring-2 focus:ring-offset-2 focus:ring-[#D3DAEE]
                                        ${opentab === "remove" ? " focus:border-SeabiscuitMainThemeColor border-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor" : " border-SeabiscuitGray500ThemeColor text-SeabiscuitLightTextColor"}
                                        `}
                                >
                                    {opentab === "remove" ? <img src="/assets/cp_icons/Cancel-4.svg" className="w-[30px] rotate-45" alt="cancel" /> : <img src="/assets/cp_icons/Cancel.svg" className="w-[30px] " alt="cancel" />}

                                    <span className={`text-sm font-normal leading-[25px] italic ${opentab === "remove" ? "text-[#fff]" : ""}`}>Remove horse</span>
                                    <span className={`text-base font-normal leading-[24px] ${opentab === "remove" ? "text-[#fff]" : ""}`}>
                                        Remove horse from your profile
                                    </span>
                                </div> */}
              </div>
            </div>
          </>
        )}

        {/* Horse search */}
        {step === '2' && (
          <>
            <div className="inputSearch mt-6 flex items-center flex-col justify-center">
              <img className="usefLogo" src="/assets/img/usef-logo.svg" alt="user" />

              <div className="usefNumberSearchForm mt-8">
                <p className="mb-2 text-[#120D26] font-normal">Enter horses USEF number</p>
                <div className="usefSearch flex items-center relative">
                  <BadgeOutlined className="absolute text-SeabiscuitLightParagraphTextColor left-2" />
                  <input
                    type="text"
                    placeholder="USEF Number"
                    className="rounded-xl w-full border border-SeabiscuitLightThemeColor focus:ring-[#D3DAEE] pl-10 py-2.5"
                  />
                </div>
              </div>
            </div>
            <p className="text-SeabiscuitLightParagraphTextColor text-center py-12">OR</p>
            <p className="text-center lg:text-sm xl:text-base">
              Don’t have a USEF number?{' '}
              <span className="text-SeabiscuitMainThemeColor"> Continue </span>
            </p>
          </>
        )}
      </>
    </MainModal>
  )
}

export default UserProfileHorseTabAddHorse

{
  /* < div
role = "button"
onClick = {() => setStep("2")}
className = "w-full  mx-auto mt-4 flex py-2 px-4 border border-SeabiscuitGray500ThemeColor rounded-xl shadow-sm text-sm font-medium text-SeabiscuitLightTextColor hover:text-SeabiscuitLightTextColor focus:ring-2 focus:ring-offset-2 focus:ring-[#D3DAEE] "
    >
<span>Add horse from USEF database</span>
<span className="border-2 border-[#78797d] rounded-md">
    <KeyboardArrowRightOutlined className="text-[#78797d]" sx={{ fontSize: 16 }} />
</span>
</div >  */
}
