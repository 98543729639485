import { useContext, useState } from 'react'
import { SignupStages } from './UserSignupViewComponentContextApi'
import UserSignupViewComponentButton from './global/UserSignupViewComponentButton'

//images
import backIcon from '../assets/arrow-left.svg'

type Props = {}

type IRoleDataType = {
  value: number
  role: string
}[]

let data: IRoleDataType = [
  { value: 1, role: 'Show Manager' },
  { value: 2, role: 'Secretary' },
  { value: 3, role: 'Course Designer' },
  { value: 4, role: 'Technical Delegate' },
  { value: 5, role: 'Announcer' },
  { value: 6, role: 'Judges' },
  { value: 7, role: 'Scorers' },
  { value: 8, role: 'Stewards' },
  { value: 9, role: 'Ingates' },
  { value: 10, role: 'Veterinarians' },
  { value: 11, role: 'Farriers' },
  { value: 12, role: 'Volunteers' },
  { value: 13, role: 'Press' },
]

type IData = Number[]

const UserSignupViewComponentCompetitorRoleType = (props: Props) => {
  const [stage, setStage] = useContext(SignupStages)
  const [competitorRoles, setCompetitorRoles] = useState<IData>([])

  const stageHandle = (value: string) => {
    sessionStorage.setItem('competitorRoles', competitorRoles.join())
    setStage({ ...stage, stage: value })
  }

  const handleSelectedCompetitorRole = (e: any, role: number) => {
    const exist = competitorRoles.includes(role)
    if (competitorRoles.length) {
      if (exist && !e.target.checked) {
        const data = competitorRoles.filter((item) => item !== role)
        return setCompetitorRoles(data)
      }
    }
    setCompetitorRoles((prev) => [...prev, role])
  }

  return (
    <>
      <div className="text-[22px] flex items-center gap-2 text-white">
        <button onClick={() => stageHandle('Init')}>
          <img src={backIcon} alt="back" className="invert" />
        </button>
        Choose your roles
      </div>
      <div className="roleChecklist">
        {data.map((item, index) => {
          return (
            <div className="checkbox" key={index}>
              <label className="checkbox-wrapper">
                <input
                  type="checkbox"
                  onClick={(e) => handleSelectedCompetitorRole(e, item.value)}
                  className="checkbox-input"
                />
                <span className="checkbox-tile">
                  <span>{item.role}</span>
                </span>
              </label>
            </div>
          )
        })}
      </div>
      {competitorRoles.length ? (
        <div className="w-[70%] mt-[60px] mx-auto">
          <UserSignupViewComponentButton
            caption="CONTINUE"
            kind={1}
            disabled={competitorRoles.length === 0}
            // onClick={() => stageHandle("CompOrg")}
            onClick={() => stageHandle('SignUp')}
          />
        </div>
      ) : null}
    </>
  )
}

export default UserSignupViewComponentCompetitorRoleType
