import { cloneDeep } from 'lodash'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import AutorenewIcon from '@mui/icons-material/Autorenew'

import useToasterHelper from '../../../../helpers/ToasterHelper'
import { sendSignEmail } from '../../../../helpers/helpers'

import helpers from '../../../../commonHelpers/helpers'

import { selectedEvent, setExhibitorTeamData } from '../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

import { IUserInterface } from '../../../../models/users/user.interface'
import { IUserDocument } from '../../../../models/user-documents/user-documents.interface'
import { UserDocumentModel } from '../../../../models/user-documents/user-documents.model'

import FirestoreService from '../../../../services/firestoreService'

import { MESSAGES_CONST } from '../../../../const/messages-const'
import { CONST } from '../../../../const/const'
import fakeDocumentList from '../../../../fakeData/fakeDocumentList'
import { UserModel } from '../../../../models/users/user.model'
import { IRiderTeamMemberInterface } from '../../../../models/rider-team-member/riderTeamMember.interface'
import { GeneralLiabilityWaiverPDFTeamplate } from '../../../../templates/pdf/generalLiabilityWaiver/GeneralLiabilityWaiverPDFTeamplate'
import TimeLib from '../../../../lib/Time'
import { USDFLiabilityWaiverPDFTemplate } from '../../../../templates/pdf/USDFLiabilityWaiver/USDFLiabilityWaiverPDFTemplate'
import { USEFLiabilityWaiverPDFTemplate } from '../../../../templates/pdf/USEFLiabilityWaiver/USEFLiabilityWaiverPDFTemplate'
import { USEALiabilityWaiverPDFTeamplate } from '../../../../templates/pdf/USEALiabilityWaiver/USEALiabilityWaiverPDFTeamplate'
import { USHJALiabilityWaiverPDFTeamplate } from '../../../../templates/pdf/USHJALiabilityWaiverPDFTeamplate/USHJALiabilityWaiverPDFTeamplate'
import { USEFEntryAgreementPDFNewTemplate } from '../../../../templates/pdf/USEFEntryAgreement/USEFEntryAgreementPDFNewTemplate'
import { getConvertedData } from '../../../../models/interface.helper'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { calculateAge } from '../../../../helpers/calculateAge'
import {
  IPaperworkTeam,
  IPaperworkTeamMemberData,
} from '../../../../store/paperworks/paperworkSlice'
import { collection, documentId, getDocs, query, where } from 'firebase/firestore'
import { RiderTeamMemberModel } from '../../../../models/rider-team-member/riderTeamMember.model'
import useGetEventData from '../../../../hooks/users/common/useGetEventData'
import { UserLegalClausesModel } from '../../../../models/user-legal-clauses/user-legal-clauses.model'
import { IDocumentFromDB, riderActions } from '../../../../store/rider/riderSlice'
import FirebaseApp from '../../../../services/firebaseApp'
import { EventDetailsModel } from '../../../../models/event-details/event-details.model'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const EVENT_DETAILS_CONST = COLLECTIONS.EVENT_DETAILS

const ExhibitorTeamPaperworkInput: React.FC<{
  className?: string
  data?: string | ReactNode
  icon?: string
}> = ({ className, data, icon }) => {
  return (
    <>
      {icon ? (
        <div className={`${className}`}>
          <img className="absolute left-3 top-3" src={icon} alt="icons" />
          {data}
        </div>
      ) : (
        <div className={`${className}`}>{data}</div>
      )}
    </>
  )
}
const CLAUSE_TABLE = CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_LEGAL_CLAUSES

const DetailRow = ({
  document,
  userData,
  getDocumentLink,
  eventId,
  member,
  cbSingSuccess,
}: {
  document: IUserDocument
  userData?: IUserInterface
  getDocumentLink: (eventId: string) => void
  eventId: string | null
  member: IPaperworkTeamMemberData
  cbSingSuccess: () => void
}) => {
  const toasterFunctions = useToasterHelper()
  const { getAllData } = useGetEventData()
  const { resend_email } = useAppSelector((state) => state.events.exhibitor_team)
  const selectedEventR = useAppSelector(selectedEvent)
  const [innerDoc, setInnerDoc] = useState<IUserDocument>(document)
  const [loading, setLoading] = useState(false)
  const storeRider = useAppSelector((state) => state.rider)
  const resend_email_ref = useRef<any[]>(resend_email)
  const dispatch = useAppDispatch()
  useEffect(() => {
    resend_email_ref.current = resend_email
  }, [resend_email])

  function getDocumentType(): string {
    if (document.documentOriginalName?.includes('USEF Entry')) {
      return 'usefentry'
    }
    return document.documentOriginalName?.split(' ')[0].toLowerCase() || ''
  }

  async function getRiderTeamMembersFromDb(registrationId: string) {
    let riderTeamMembers: IRiderTeamMemberInterface[] = []
    try {
      const ticketSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [
          where(
            COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ]
      )

      console.log(
        '=>(ExhibitorTeamPaperworkAccordion.tsx:118) ticketSnapShots.size',
        ticketSnapShots.size
      )
      if (ticketSnapShots.size) {
        ticketSnapShots.forEach((currDoc) => {
          riderTeamMembers.push(
            getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return riderTeamMembers
    }
  }

  const getAllClauses = async (ownerId: string) => {
    const respData: any[] = []
    const clauses = await FirestoreService.filterItems(CLAUSE_TABLE.NAME, [
      where(CLAUSE_TABLE.FIELDS.OWNER_ID.KEY, '==', ownerId),
    ])

    if (clauses.size) {
      clauses?.forEach((doc) => {
        let clauseDataModel = UserLegalClausesModel.fromFirestoreDoc(doc).toObject()
        respData.push(clauseDataModel)
      })
      return respData
    }
  }

  const onSign = async (userDoc?: IUserDocument) => {
    // console.log('=>(ExhibitorTeamPaperworkAccordion.tsx:146)document ', document)
    // console.log('=>(ExhibitorTeamPaperworkAccordion.tsx:146)userData ', userData)
    // console.log('=>(ExhibitorTeamPaperworkAccordion.tsx:147)member ', member)
    // if (1) return null

    setLoading(true)

    try {
      const ridersTeamMembersInDb__ = await getRiderTeamMembersFromDb(
        document?.registrationDocId ?? ''
      )

      let currRidersTeamMember = ridersTeamMembersInDb__.find(
        (m) => m.teamMemberId === document.signatoryId
      )

      if (!selectedEventR) {
        toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        return console.error('Error get selectedEventR')
      }

      if (!currRidersTeamMember) {
        // toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        // return console.error('Error get Rider Team Member')
        currRidersTeamMember = {
          eventId: document.eventId || '',
          registrationDocId: document.registrationDocId,
          registrationByDayDocId: document.registrationByDayDocId,
          userId: document.registrationDocId,
          mailLog: [],
          horseId: '',
          userHorseMappingDocId: '',
          teamMemberId: userData?.id || '',
          teamMemberDob: userData?.userDOB || '',
          riderId: userData?.id || '',
          riderDob: userData?.userDOB || '',
          riderName: document.signatoryName || '',
        }
      }
      if (!eventId) {
        toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        return console.error('Error get eventId')
      }
      if (!userData) return toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      const age = calculateAge(currRidersTeamMember?.teamMemberDob || userData.userDOB)

      if (
        currRidersTeamMember &&
        (!currRidersTeamMember.teamMemberName ||
          !currRidersTeamMember.teamMemberName.replace(/\s+/g, ''))
      ) {
        currRidersTeamMember.teamMemberName = currRidersTeamMember.riderName
        currRidersTeamMember.teamMemberNameNGram = currRidersTeamMember.riderNameNGram
        currRidersTeamMember.teamMemberPhoneCode = currRidersTeamMember.riderPhoneCode
        currRidersTeamMember.teamMemberPhoneNumber = currRidersTeamMember.riderPhoneNumber
        currRidersTeamMember.teamMemberProfilePicture = currRidersTeamMember.riderProfilePicture
      }

      const paperwork = fakeDocumentList.find((d) => d.document_type === getDocumentType())
      if (!paperwork) {
        toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        return console.error('Error getting paperwork')
      }

      if (!paperwork) return null

      console.log('=>(ExhibitorTeamPaperworkAccordion.tsx:191) eventId', eventId)

      // const eventData = await getAllData(eventId, ['v01_event_details'])
      const query_ = query(
        collection(FirebaseApp.firestore, EVENT_DETAILS_CONST.NAME),
        where(documentId(), '==', eventId)
      )
      const docEvent = await getDocs(query_)
      if (!docEvent.size) {
        toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        return console.error('Error getting v01_event_details')
      }
      const eventData = EventDetailsModel.fromFirestoreDoc(docEvent.docs[0]).cloneDeep().toObject()

      if (!eventData) {
        toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        return console.error('Error getting v01_event_details')
      }

      const doc = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
        selectedEventR?.Event?.owner || ''
      )

      if (!doc) {
        toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        throw new Error('Error getting doc')
      }
      const organizerDetails = UserModel.fromFirestoreDoc(doc).toObject()
      const createData = {
        eventId,
        userData,
        paperwork,
        EventDetails: eventData,
        organizerDetails,
        eventDraftId: '',
      }

      const allClauses = await getAllClauses(selectedEventR?.Event?.owner || '')

      if (!allClauses) {
        toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        return console.error('Error getting allClauses')
      }

      let stringArray = [member.signatoryDefaultRole]

      const index = 0
      let newDocIdResp: any = null
      switch (getDocumentType()) {
        case 'general':
          newDocIdResp = await GeneralLiabilityWaiverPDFTeamplate(
            {
              ...createData,
              ridersTeamMember: currRidersTeamMember || {},
              isMature: age > 18,
              clauses: allClauses,
              isSigned: true,
              stringArray,
              index,
            },
            innerDoc.id!,
            [
              {
                time: TimeLib.utcTimestamp(),
                audit: `Document signed by ${document.signatoryName}`,
              },
            ]
          )
          break

        case 'usdf':
          console.log('=>(SignDocumentModal.tsx:209) 2', 2)
          newDocIdResp = await USDFLiabilityWaiverPDFTemplate(
            {
              ...createData,
              ridersTeamMember: currRidersTeamMember,
              isMature: age > 18,
              isSigned: true,
              stringArray,
              index,
            },
            innerDoc.id!
          )
          break

        case 'usef':
          console.log('=>(SignDocumentModal.tsx:234) 3', 3)
          newDocIdResp = await USEFLiabilityWaiverPDFTemplate(
            {
              ...createData,
              ridersTeamMember: currRidersTeamMember,
              isMature: age > 18,
              isSigned: true,
              stringArray,
              index,
            },
            innerDoc.id!
          )
          break

        case 'usea':
          console.log('=>(SignDocumentModal.tsx:246) 4', 4)
          newDocIdResp = await USEALiabilityWaiverPDFTeamplate(
            {
              ...createData,
              ridersTeamMember: currRidersTeamMember,
              isMature: age > 18,
              isSigned: true,
              stringArray,
              index,
            },
            innerDoc.id!
          )
          break

        case 'ushja':
          console.log('=>(SignDocumentModal.tsx:258) 5', 5)
          newDocIdResp = await USHJALiabilityWaiverPDFTeamplate(
            {
              ...createData,
              ridersTeamMember: currRidersTeamMember,
              isMature: age > 18,
              isSigned: true,
              stringArray,
              index,
            },
            innerDoc.id!
          )
          break

        case 'usefentry':
          newDocIdResp = await USEFEntryAgreementPDFNewTemplate(
            {
              ...createData,
              ridersTeamMember: currRidersTeamMember,
              isMature: age > 18,
              isSigned: true,
              stringArray,
              index,
            },
            innerDoc.id!
          )
          break
      }

      const docSnaps = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS.NAME,
        (newDocIdResp && newDocIdResp.docId) || innerDoc.id!
      )
      const userDocument = getConvertedData(UserDocumentModel.fromFirestoreDoc(docSnaps).toObject())

      if (innerDoc.signatoryDefaultRole !== CONST.UI.TEAM_MEMBERS_ROLES.RIDER) {
        await FirestoreService.deleteItem(
          CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS.NAME,
          innerDoc.id!
        )
      }

      toasterFunctions.success({ message: MESSAGES_CONST.SIGNED_SUCCESSFULLY })
      const newDoc = { ...innerDoc }
      newDoc.status = 'Signed'
      newDoc.documentUrl = userDocument.documentUrl
      newDoc.id = userDocument.id
      setInnerDoc(newDoc)

      const newStoreRiders = [...storeRider.documents].filter(
        (itm) => itm.id !== userDocument.id && itm.id !== innerDoc.id
      )
      newStoreRiders.push(newDoc as any)

      const isSigned = !newStoreRiders.some((d) => d.status !== 'Signed')
      dispatch(riderActions.setIsAppPaperworkSign(isSigned))
      dispatch(riderActions.setDocuments(newStoreRiders))

      cbSingSuccess()
    } catch (e) {
      console.log('=>(ExhibitorTeamPaperworkAccordion.tsx:148) e', e)
      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-2 lg:p-0 mt-4 lg:mt-0 flex flex-col lg:flex-row lg:items-start w-full">
      <div className="lg:hidden font-medium text-[12px]">Document</div>
      <ExhibitorTeamPaperworkInput
        className={`text-SeabiscuitDark200ThemeColor ${innerDoc.status !== 'Not Signed' ? 'text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor' : 'text-[#EB5757] bg-[#EB57571A]'} rounded-lg text-[14px] capitalize min-h-12 p-3 lg:m-1 lg:w-8/12`}
        data={
          <div className={'flex items-center gap-2 w-full justify-between'}>
            {innerDoc.documentOriginalName?.toLowerCase()}
            {innerDoc.status !== 'Not Signed' ? (
              <>
                <div
                  className="cursor-pointer py-0.5 px-3 border border-solid border-[#122B46] rounded-full  transition-all"
                  onClick={() => {
                    window.open(innerDoc.documentUrl, '_blank')
                  }}
                >
                  View
                </div>
              </>
            ) : (
              <button
                disabled={loading}
                className="cursor-pointer py-0.5 px-3 border border-solid border-[#EB5757] rounded-full hover:bg-[#EB5757] text-[#EB5757] hover:text-white transition-all"
                onClick={() => onSign(innerDoc)}
              >
                {loading ? <AutorenewIcon fontSize="small" className="animate-spin" /> : 'Sign'}
              </button>
            )}
          </div>
        }
      />

      <div className="lg:hidden mt-2 font-medium text-[12px]">Role</div>
      <ExhibitorTeamPaperworkInput
        className={`text-SeabiscuitDark200ThemeColor ${innerDoc.status !== 'Not Signed' ? 'text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor' : 'text-[#EB5757] bg-[#EB57571A]'} rounded-lg text-[14px] capitalize min-h-12 p-3 lg:m-1 lg:w-2/12`}
        data={<div>{innerDoc.signatoryDefaultRole}</div>}
      />

      <div className="lg:hidden mt-2 font-medium text-[12px]">Status</div>
      <ExhibitorTeamPaperworkInput
        className={`flex items-center ${innerDoc.status !== 'Not Signed' ? 'text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor' : 'text-[#EB5757] bg-[#EB57571A]'} rounded-lg text-[14px] capitalize min-h-12 lg:m-1 lg:w-2/12`}
        data={
          innerDoc.status !== 'Not Signed' ? (
            <div className="flex w-full items-center relative p-3">Signed</div>
          ) : (
            <div className="flex w-full items-center relative px-3 py-1">Pending</div>
          )
        }
      />
    </div>
  )
}

const ExhibitorTeamPaperworkAccordion = ({
  docsList,
  title,
  eventId,
  getDocumentLink,
  member,
  cbSingSuccess,
}: {
  docsList: IUserDocument[]
  title: String
  tooltip: String
  getDocumentLink: (eventId: string) => void
  eventId: string | null
  member: IPaperworkTeamMemberData
  cbSingSuccess: () => void
}) => {
  const userData = useAppSelector((state) => state.user.profileDetails)

  return (
    <>
      <div className="hidden lg:flex items-center w-full">
        <ExhibitorTeamPaperworkInput
          className="text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-8/12"
          data={<span className="flex items-center">{title}</span>}
        />
        <ExhibitorTeamPaperworkInput
          className="text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-2/12"
          data={<span className="flex items-center">Role</span>}
        />
        {/*<ExhibitorTeamPaperworkInput*/}
        {/*  className="text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-2/12"*/}
        {/*  data={<span className="flex items-center">On Behalf of</span>}*/}
        {/*/>*/}
        {/*<ExhibitorTeamPaperworkInput*/}
        {/*  className="text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-2/12"*/}
        {/*  data={<span className="flex items-center">View Document</span>}*/}
        {/*/>*/}
        <ExhibitorTeamPaperworkInput
          className="text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-2/12"
          data={<span className="flex items-center">Status</span>}
        />
      </div>

      {docsList.length
        ? docsList.map((document, index) => {
            return (
              <DetailRow
                key={`${JSON.stringify(document)}${index}`}
                document={document}
                userData={userData}
                getDocumentLink={getDocumentLink}
                eventId={eventId}
                member={member}
                cbSingSuccess={cbSingSuccess}
              />
            )
          })
        : ''}
    </>
  )
}

export default ExhibitorTeamPaperworkAccordion
