// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import * as yup from 'yup'

import { IEventStaffInterface } from './event-staff.interface'
import { CONST } from '../../const/const'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const FIELDS = COLLECTIONS.EVENT_STAFF.FIELDS

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export class EventStaffModel
  extends ModelBaseModel<IEventStaffInterface>
  implements IModelBaseModel
{
  public v: IEventStaffInterface['v']
  public id: IEventStaffInterface['id']
  public title: IEventStaffInterface['title']
  public owner: IEventStaffInterface['owner']
  public otherStaff: IEventStaffInterface['otherStaff']
  public eventStaff: IEventStaffInterface['eventStaff']
  public briefDescription: IEventStaffInterface['briefDescription']

  public created: IEventStaffInterface['created']
  public modified: IEventStaffInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: IEventStaffInterface) {
    super()
    this.v = obj?.v ?? FIELDS.VERSION.DEFAULT

    this.id = obj?.id ?? FIELDS.ID.DEFAULT
    this.title = obj?.title ?? FIELDS.TITLE.DEFAULT
    this.owner = obj?.owner ?? FIELDS.OWNER.DEFAULT
    this.eventStaff = obj?.eventStaff ?? FIELDS.EVENT_STAFF.DEFAULT
    this.otherStaff = obj?.otherStaff ?? FIELDS.OTHER_STAFF.DEFAULT
    this.briefDescription = obj?.briefDescription ?? FIELDS.BRIEF_DESCRIPTION.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new EventStaffModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  static validationSchema() {
    return yup.object({
      // name: yup.number().required(),
      // eventStaff: yup.array().of(yup.object({
      //     id: yup.string().required(),
      //     title: yup.string().required(),
      //     value: yup.array().of(yup.string()),
      //     placeholder: yup.string().required(),
      //     scratchedValues: yup.array().of(yup.string()),
      // })).required()
    })
  }
}
