// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useCallback, useState } from 'react'

import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import RedCheck from '../../../../common/assets/invalid.svg'
import GreenCheck from '../../../../common/assets/valid.svg'
import {
  postEvaluateCheckboxFieldChange,
  preEvaluateCheckboxFieldChange,
} from '../../../evaluation/EventPrizeListFormBuilderUtilEvaluation'
import generateFieldValidation, {
  validateCheckboxFieldChange,
} from '../../../validation/EventPrizeListFormBuilderUtilValidation'
import EventPrizeListFormBuilderUtilEquitationSubSectionRow from '../../subsection/equitation/EventPrizeListFormBuilderUtilEquitationSubSectionRow'
import EventPrizeListFormBuilderUtilEquitationSectionRowAccordionComponent from './EventPrizeListFormBuilderUtilEquitationSectionRowAccordionComponent'
import EventPrizeListFormBuilderUtilEquitationSectionRowAccordionDetailsComponent from './EventPrizeListFormBuilderUtilEquitationSectionRowAccordionDetailsComponent'
import EventPrizeListFormBuilderUtilEquitationSectionRowAccordionSummaryComponent from './EventPrizeListFormBuilderUtilEquitationSectionRowAccordionSummaryComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventPriceListFormBuilderUtilEquitationSectionRowProps {
  section: any
  division: any
  register: any
  disciplineIndex: number
  divisionIndex: number
  sectionIndex: number
  errors: any
  control: any
  setValue: any
  getValues: any
  setError: any
  clearErrors: any
  trigger: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormBuilderUtilEquitationSectionRow: React.FC<
  EventPriceListFormBuilderUtilEquitationSectionRowProps
> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleOnAccordionExpansionToggle = useCallback((_: any) => {
    setIsExpanded((prevState) => !prevState)
  }, [])

  return (
    <>
      <EventPrizeListFormBuilderUtilEquitationSectionRowAccordionComponent
        onChange={handleOnAccordionExpansionToggle}
      >
        <EventPrizeListFormBuilderUtilEquitationSectionRowAccordionSummaryComponent
          isExpanded={isExpanded}
        >
          <div className="scroll-group w-full overflow-auto scrollbar-hide mb-3">
            <div className="grid grid-cols-[70px_260px_repeat(6,1fr)] gap-2 min-w-[1200px] w-full -ml-8">
              <div></div>
              {/*=================================*/}
              {/* Section Selection */}
              {/*=================================*/}
              <div>
                <Controller
                  name={`data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.meta.selected`}
                  control={props.control}
                  render={({ field: { value } }) => (
                    <div
                      className={clsx(
                        `text-[14px] w-full rounded-md flex items-center p-4 h-12`,
                        value
                          ? ` bg-SeabiscuitGrayThemeColor`
                          : `bg-white border-SeabiscuitGrayThemeColor border-2`
                      )}
                    >
                      {value ? (
                        <>
                          <div
                            onClick={() => {
                              const shouldChange = preEvaluateCheckboxFieldChange(
                                props.section.sectionSubCode,
                                props.section,
                                props.division,
                                value,
                                !value,
                                props.setValue,
                                props.getValues,
                                props.setError,
                                props.clearErrors,
                                props.trigger,
                                props.disciplineIndex,
                                props.divisionIndex,
                                props.sectionIndex
                              )
                              if (shouldChange) {
                                props.setValue(
                                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.meta.selected`,
                                  !value
                                )
                              }
                              validateCheckboxFieldChange(
                                props.section.sectionSubCode,
                                props.section,
                                props.division,
                                value,
                                !value,
                                props.setValue,
                                props.getValues,
                                props.setError,
                                props.clearErrors,
                                props.trigger,
                                props.disciplineIndex,
                                props.divisionIndex,
                                props.sectionIndex
                              )
                              postEvaluateCheckboxFieldChange(
                                props.section.sectionSubCode,
                                props.section,
                                props.division,
                                value,
                                !value,
                                props.setValue,
                                props.getValues,
                                props.setError,
                                props.clearErrors,
                                props.trigger,
                                props.disciplineIndex,
                                props.divisionIndex,
                                props.sectionIndex
                              )
                            }}
                            className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                          >
                            {props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.value`
                            )}
                          </div>
                          <img src={GreenCheck} alt="green check" />
                        </>
                      ) : (
                        <>
                          <div
                            onClick={() => {
                              const shouldChange = preEvaluateCheckboxFieldChange(
                                props.section.sectionSubCode,
                                props.section,
                                props.division,
                                value,
                                !value,
                                props.setValue,
                                props.getValues,
                                props.setError,
                                props.clearErrors,
                                props.trigger,
                                props.disciplineIndex,
                                props.divisionIndex,
                                props.sectionIndex
                              )
                              if (shouldChange) {
                                props.setValue(
                                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.meta.selected`,
                                  !value
                                )
                              }
                              validateCheckboxFieldChange(
                                props.section.sectionSubCode,
                                props.section,
                                props.division,
                                value,
                                !value,
                                props.setValue,
                                props.getValues,
                                props.setError,
                                props.clearErrors,
                                props.trigger,
                                props.disciplineIndex,
                                props.divisionIndex,
                                props.sectionIndex
                              )
                              postEvaluateCheckboxFieldChange(
                                props.section.sectionSubCode,
                                props.section,
                                props.division,
                                value,
                                !value,
                                props.setValue,
                                props.getValues,
                                props.setError,
                                props.clearErrors,
                                props.trigger,
                                props.disciplineIndex,
                                props.divisionIndex,
                                props.sectionIndex
                              )
                            }}
                            className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                          >
                            {props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.value`
                            )}
                          </div>
                          <input type="checkbox" checked={value} disabled />
                        </>
                      )}
                    </div>
                  )}
                />
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionSubCode?.value && (
                  <div className="text-red-500">
                    {
                      props.errors?.data?.items[props.disciplineIndex]?.divisions[
                        props.divisionIndex
                      ]?.sections[props.sectionIndex]?.sectionSubCode?.value.message
                    }
                  </div>
                )}
              </div>
              {/*=================================*/}
              {/* Flat Class */}
              {/*=================================*/}
              <div>
                {props.getValues(
                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionFlatClass.meta.enabled`
                ) ? (
                  <div
                    className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Flat classes'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionFlatClass.value`,
                        {
                          validate: generateFieldValidation(
                            props.section.sectionFlatClass,
                            props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionFlatClass.meta.enabled`
                            ),
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          ),
                        }
                      )}
                    />
                    {props.errors?.data?.items[props.disciplineIndex]?.divisions[
                      props.divisionIndex
                    ]?.sections[props.sectionIndex]?.sectionFlatClass?.value ? (
                      <img src={RedCheck} alt="red check" />
                    ) : (
                      <img src={GreenCheck} alt="green check" />
                    )}
                  </div>
                ) : (
                  <div
                    className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Flat class'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionFlatClass.value`
                      )}
                    />
                  </div>
                )}
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionFlatClass?.value && (
                  <div className="text-red-500">
                    {
                      props.errors?.data?.items[props.disciplineIndex]?.divisions[
                        props.divisionIndex
                      ]?.sections[props.sectionIndex]?.sectionFlatClass?.value.message
                    }
                  </div>
                )}
              </div>
              {/*=================================*/}
              {/* Over Fence Classes */}
              {/*=================================*/}
              <div>
                {props.getValues(
                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionOverFenceClasses.meta.enabled`
                ) ? (
                  <div
                    className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Over fence classes'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionOverFenceClasses.value`,
                        {
                          validate: generateFieldValidation(
                            props.section.sectionOverFenceClasses,
                            props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionOverFenceClasses.meta.enabled`
                            ),
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          ),
                        }
                      )}
                    />
                    {props.errors?.data?.items[props.disciplineIndex]?.divisions[
                      props.divisionIndex
                    ]?.sections[props.sectionIndex]?.sectionOverFenceClasses?.value ? (
                      <img src={RedCheck} alt="red check" />
                    ) : (
                      <img src={GreenCheck} alt="green check" />
                    )}
                  </div>
                ) : (
                  <div
                    className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Over fence classes'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionOverFenceClasses.value`
                      )}
                    />
                  </div>
                )}
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionOverFenceClasses?.value && (
                  <div className="text-red-500">
                    {
                      props.errors?.data?.items[props.disciplineIndex]?.divisions[
                        props.divisionIndex
                      ]?.sections[props.sectionIndex]?.sectionOverFenceClasses?.value.message
                    }
                  </div>
                )}
              </div>
              {/*=================================*/}
              {/* Fence Height */}
              {/*=================================*/}
              <div>
                {props.getValues(
                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionFenceHeight.meta.enabled`
                ) ? (
                  <div
                    className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor  rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Judge'}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionFenceHeight.value`,
                        {
                          validate: generateFieldValidation(
                            props.section.sectionFenceHeight,
                            props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionFenceHeight.meta.enabled`
                            ),
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          ),
                        }
                      )}
                    />
                    {props.errors?.data?.items[props.disciplineIndex]?.divisions[
                      props.divisionIndex
                    ]?.sections[props.sectionIndex]?.sectionFenceHeight?.value ? (
                      <img src={RedCheck} alt="red check" />
                    ) : (
                      <img src={GreenCheck} alt="green check" />
                    )}
                  </div>
                ) : (
                  <div
                    className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Height'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionFenceHeight.value`
                      )}
                    />
                  </div>
                )}
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionFenceHeight?.value && (
                  <div className="text-red-500">
                    {
                      props.errors?.data?.items[props.disciplineIndex]?.divisions[
                        props.divisionIndex
                      ]?.sections[props.sectionIndex]?.sectionFenceHeight?.value.message
                    }
                  </div>
                )}
              </div>
              {/*=================================*/}
              {/* Price Money Class */}
              {/*=================================*/}
              <div>
                {props.getValues(
                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.meta.enabled`
                ) ? (
                  <div
                    className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor  rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Money'}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.value`,
                        {
                          validate: generateFieldValidation(
                            props.section.sectionTotalPriceMoney,
                            props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.meta.enabled`
                            ),
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          ),
                        }
                      )}
                    />
                    {props.errors?.data?.items[props.disciplineIndex]?.divisions[
                      props.divisionIndex
                    ]?.sections[props.sectionIndex]?.sectionTotalPriceMoney?.value ? (
                      <img src={RedCheck} alt="red check" />
                    ) : (
                      <img src={GreenCheck} alt="green check" />
                    )}
                  </div>
                ) : (
                  <div
                    className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Money'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.value`
                      )}
                    />
                  </div>
                )}
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionTotalPriceMoney?.value && (
                  <div className="text-red-500">
                    {
                      props.errors?.data?.items[props.disciplineIndex]?.divisions[
                        props.divisionIndex
                      ]?.sections[props.sectionIndex]?.sectionTotalPriceMoney?.value.message
                    }
                  </div>
                )}
              </div>
              {/*=================================*/}
              {/* Judges */}
              {/*=================================*/}
              <div>
                {props.getValues(
                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionJudge.meta.enabled`
                ) ? (
                  <div
                    className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor  rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Judge'}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionJudge.value`,
                        {
                          validate: generateFieldValidation(
                            props.section.sectionJudge,
                            props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionJudge.meta.enabled`
                            ),
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          ),
                        }
                      )}
                    />
                    {props.errors?.data?.items[props.disciplineIndex]?.divisions[
                      props.divisionIndex
                    ]?.sections[props.sectionIndex]?.sectionJudge?.value ? (
                      <img src={RedCheck} alt="red check" />
                    ) : (
                      <img src={GreenCheck} alt="green check" />
                    )}
                  </div>
                ) : (
                  <div
                    className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Judge'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionJudge.value`
                      )}
                    />
                  </div>
                )}
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionJudge?.value && (
                  <div className="text-red-500">
                    {
                      props.errors?.data?.items[props.disciplineIndex]?.divisions[
                        props.divisionIndex
                      ]?.sections[props.sectionIndex]?.sectionJudge?.value.message
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        </EventPrizeListFormBuilderUtilEquitationSectionRowAccordionSummaryComponent>
        <EventPrizeListFormBuilderUtilEquitationSectionRowAccordionDetailsComponent>
          {props.section.subSections.map((subSection: any, index: number) => (
            <EventPrizeListFormBuilderUtilEquitationSubSectionRow
              key={subSection.subSectionTitle}
              subSection={subSection}
              section={props.section}
              register={props.register}
              disciplineIndex={props.disciplineIndex}
              divisionIndex={props.divisionIndex}
              sectionIndex={props.sectionIndex}
              subSectionIndex={index}
              errors={props.errors}
              control={props.control}
              getValues={props.getValues}
              setValue={props.setValue}
              setError={props.setError}
              clearErrors={props.clearErrors}
              trigger={props.trigger}
            />
          ))}
        </EventPrizeListFormBuilderUtilEquitationSectionRowAccordionDetailsComponent>
      </EventPrizeListFormBuilderUtilEquitationSectionRowAccordionComponent>
    </>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
EventPrizeListFormBuilderUtilEquitationSectionRow.defaultProps = {
  section: null,
  register: null,
}

export default EventPrizeListFormBuilderUtilEquitationSectionRow
