// Component imports
import EventClinicConfirmationTab from './EventClinicConfirmationTab'
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'
import { ISignatoryForMail } from '../../../../models/event-registered-users/event-registered-users.interface'
import { IEventsRegisterData } from '../../../../store/events/eventsSlice'
import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'

type Props = {
  step?: number
  title?: string
  description?: string
  activeTab: {
    tab: string
    step: number
  }
  setEventTab: any
  message: string | null
  competitonFull?: boolean
  registerFormData: IEventsRegisterData
  allRiderTeamMembers: ISignatoryForMail[]
  manageInfo: IManageInfo
  isManage?: boolean
}

const ClinicNOtherEventRegisterConfirmationTab = (props: Props) => {
  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      <EventClinicConfirmationTab
        message={props.message}
        activeTab={props.activeTab}
        setEventTab={props.setEventTab}
        competitonFull={props.competitonFull}
        registerFormData={props.registerFormData}
        allRiderTeamMembers={props.allRiderTeamMembers}
        isManage={props.isManage}
        manageInfo={props.manageInfo}
      />
    </CompetitorEventRegisterWrapper>
  )
}

export default ClinicNOtherEventRegisterConfirmationTab
