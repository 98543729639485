import React from 'react'

interface IProps {
  count: number
  title: string
  desc: string
  icon: React.ReactElement
}

export const RequiredFieldsHeader: React.FC<IProps> = ({ count, title, desc, icon }) => {
  return (
    <div className={'flex w-full justify-between'}>
      <div>
        <h2 className={'text-[#122B46] text-[22px] font-bold'}>{title}</h2>
        <p className={'text-[#122B4680] text-[14px]'}>{desc}</p>
      </div>
      <div className={'flex gap-5'}>
        <div className={'text-right'}>
          <span className={'text-[#122B4680] text-[12px] '}>Count</span>
          <p className={'text-[14px]'}>{count} required fields</p>
        </div>
        <div
          className={'flex w-[45px] rounded-md h-[45px] items-center justify-center bg-[#F6F7FB]'}
        >
          {icon}
        </div>
      </div>
    </div>
  )
}
