/* eslint-disable no-useless-concat */
import { ArrowBack } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import helpers from '../../../commonHelpers/helpers'
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { CustomError, getUserFullName } from '../../../helpers/helpers'

import { IEventReviewPublish } from '../../../models/event-review-publish/event-review-publish.interface'
import { getConvertedData } from '../../../models/interface.helper'
import { IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'
import EventService from '../../../services/eventService'
import FirestoreService from '../../../services/firestoreService'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { ticketsReducer } from '../../../store/tickets/ticketslice'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { selectUserReducer } from '../../../store/user/userSlice'
import clsx from 'clsx'

type IViewTicket = { name: string; description: string; className: string; icon: string }

let ticket_main_data1: IViewTicket[] = [
  {
    name: 'Used',
    description: 'Dec 28, 2023',
    className: ' border-2-[#EB5757] bg-[#eb57571a]',
    icon: '/assets/og_icons/Info-1.svg',
  },

  {
    name: 'N/A',
    description: 'Event name',
    className: ' border-2-[#D3DAEE]',
    icon: '/assets/og_icons/Two Tickets-1.svg',
  },

  {
    name: 'December 14-17th, 2022',
    description: 'Event dates',
    className: 'border-2-[#D3DAEE]',
    icon: '/assets/og_icons/Calendar-1.svg',
  },

  {
    name: 'Not yet assigned',
    description: 'Ticket holder',
    className: 'border-2-[#D3DAEE]',
    icon: '/assets/og_icons/User-1.svg',
  },

  {
    name: '$75',
    description: 'Ticket price',
    className: 'border-2-[#D3DAEE]',
    icon: '/assets/og_icons/Us Dollar Circled-1.svg',
  },
  {
    name: '158 Yellow Rd, Middleburg, VA 20117',
    description: 'Event location',
    className: 'border-2-[#D3DAEE]',
    icon: '/assets/og_icons/Location-1.svg',
  },
  {
    name: 'Unknown',
    description: 'Ticket purchaser',
    className: 'border-2-[#D3DAEE]',
    icon: '/assets/og_icons/MagneticCard-1.svg',
  },
]

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

type Props = {
  dataToPassOn: any
  handelTicketStep: (step: number) => void
  assigned_user?: any
}

const ViewTicket = (props: Props) => {
  const [isHovering, setIsHovering] = useState(false)
  const userDataR = useAppSelector(selectUserReducer)
  const [ticketOwner, setTicketOwner] = useState<IUserInterface | null>(null)

  const { handelTicketStep, dataToPassOn } = props
  const [loading, setLoading] = useState(true)
  const contentRef = useRef<HTMLDivElement>(null)
  const [downloadbut, setdownloadbut] = useState(true)
  const [addToCalender, setAddToCalender] = useState(true)
  const [eventLocation, setEventLocation] = useState<string | null>(null)
  const selectedTicket = useAppSelector(ticketsReducer.getSelectedTicketR)
  const [viewTicket, setViewTicket] = useState<IViewTicket[]>(ticket_main_data1)
  const selectedRegistrationTicket = useAppSelector(ticketsReducer.getSelectedRegistrationTicketsR)
  const [eventDetails, setEventDetails] = useState<IEventReviewPublish['EventDetails'] | null>(null)
  const dispatch = useAppDispatch()

  const getEventDetails = async (eventId: string) => {
    try {
      const eventService = new EventService(eventId)
      const eventDetails_ = await eventService.getPublishedEventDetails
      const eventLocation_ = await eventService.getEventLocation

      setEventDetails(eventDetails_)
      setEventLocation(eventLocation_.combinedLocation)
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: error?.devMessage ?? error?.message ?? error,
          moduleName: 'getEventDetails',
          fileName: 'ViewTicket',
        }),
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    if (dataToPassOn?.eventId) getEventDetails(dataToPassOn?.eventId)
  }, [dataToPassOn?.eventId, dispatch])

  useEffect(() => {
    if (selectedTicket?.userId) {
      FirestoreService.getItem(COLLECTIONS.USERS.NAME, selectedTicket?.userId)
        .then((userSnapshot) => {
          if (!userSnapshot.exists()) return

          setTicketOwner(getConvertedData(UserModel.fromFirestoreDoc(userSnapshot).toObject()))
        })
        .catch((error: any) => {
          helpers.logger({
            message: CustomError.somethingWentWrong({
              message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
              devMessage: error?.devMessage ?? error?.message ?? error,
              moduleName: 'getEventDetails',
              fileName: 'ViewTicket',
            }),
          })
        })
    } else {
      setTicketOwner(getConvertedData(new UserModel(userDataR.profileDetails).toObject()))
    }
  }, [selectedTicket?.userId, userDataR])

  useEffect(() => {
    let ticketIsAvailable = false

    if (
      selectedTicket &&
      typeof selectedTicket === 'object' &&
      Object.keys(selectedTicket).length > 0
    ) {
      ticket_main_data1 = ticket_main_data1.map((item, index: number) => {
        ticketIsAvailable =
          selectedTicket.ticketStatus == null || selectedTicket.ticketStatus === 'available'
        switch (index) {
          case 0:
            if (selectedTicket.ticketStatus === 'assigned') {
              item.name = 'Assigned'
              item.className = 'border-2-[#EB5757] bg-[#eb57571a]'
              item.description = `${moment(selectedTicket.assignmentDate).format('MMM DD-YYYY')}`
              setAddToCalender(true)
            } else if (ticketIsAvailable) {
              item.name = 'Unassigned'
              item.className = 'border-2-[#00B6AA] bg-[#fff]'
              item.description = 'Not used yet'
              setAddToCalender(false)
            }
            return item
          case 1:
            item.name = eventDetails?.competitionName ?? 'Unknown'
            item.className = 'capitalize'
            return item
          case 2:
            if (eventDetails?.competitionStartDate || eventDetails?.competitionEndDate) {
              item.name = `${moment(eventDetails?.competitionStartDate).format('MMM DD [-]')} ${moment(eventDetails?.competitionEndDate).format('Do, YYYY')}`
            }
            return item
          case 3:
            item.className = 'capitalize'
            if (ticketIsAvailable) {
              item.name = ticketOwner ? getUserFullName(ticketOwner) : 'Unknown'
            } else {
              item.name = selectedTicket.ticketHolderName ?? 'Unknown'
            }
            return item
          case 4:
            if (selectedRegistrationTicket?.ticketPrice !== undefined) {
              item.name = `$${selectedRegistrationTicket.ticketPrice}`
            }
            return item
          case 5:
            item.name = eventLocation ?? 'Unknown'
            return item
          case 6:
            if (ticketOwner) {
              item.name = ticketOwner?.userEmail ?? ''
            }
            return item
          default:
            return item
        }
      })

      if (eventDetails) setLoading(false)
      setViewTicket(ticket_main_data1)
    }
  }, [
    dispatch,
    eventDetails,
    eventLocation,
    selectedRegistrationTicket,
    selectedTicket,
    ticketOwner,
  ])

  useEffect(() => {
    if (!downloadbut) {
      if (contentRef?.current) {
        const content = contentRef.current
        html2canvas(content, {
          scale: 1, // increase the scale to 2x for higher quality
        }).then((canvas) => {
          const imgData = canvas.toDataURL('image/svg')
          const pdf = new jsPDF()
          pdf.addImage(imgData, 'SVG', 7, 6, 195, 75)
          pdf.save('download.pdf')
        })
      }
      setdownloadbut(true)
    }
  }, [downloadbut])

  const downloadPDF = () => {
    // setdownloadbut(false)
    setTimeout(() => {
      setdownloadbut(false)
    }, 200)
  }

  const getFormattedDate = () => {
    if (!viewTicket?.[2]?.name) return
    let dateString = viewTicket?.[2]?.name
    let parts = dateString.split(/[\s,]+/)
    let month = new Date(Date.parse(parts[0] + ' 1, 2012')).getMonth() + 1
    let dayRange = parts[1].split('-')[0]
    let year = parts[2]
    return `${year}-${month < 10 ? '0' + month : month}-${dayRange.padStart(2, '0')}`
  }

  const getFormattedEndDate = () => {
    if (!viewTicket?.[2]?.name) return
    let dateString = viewTicket?.[2]?.name
    let parts = dateString.split(/[\s,]+/)
    let month = new Date(Date.parse(parts[0] + ' 1, 2012')).getMonth() + 1
    let dayRange = parts[1].split('-')[1]
    let year = parts[2]
    let day = dayRange?.match(/\d+/)?.[0]
    return `${year}-${month < 10 ? '0' + month : month}-${day?.padStart(2, '0')}`
  }

  const getDescription = () => {
    return (
      'To View Event: ' +
      'https://events.thepegasus.app/event-details/' +
      '[br]' +
      '[br]' +
      '****************************************' +
      '[br]' +
      '[br]' +
      'Ticket Holder: ' +
      viewTicket?.[2]?.name +
      '[br]' +
      '[br]' +
      'Ticket Price: ' +
      viewTicket?.[4]?.name +
      '[br]' +
      '[br]' +
      'Ticket Purchaser: ' +
      viewTicket?.[6]?.name +
      '[br]' +
      '[br]' +
      'Directions: ' +
      eventDetails?.competitionLocationNotes +
      '[br]' +
      '[br]' +
      'Event Description: ' +
      eventDetails?.summaryShort
    )
  }

  return (
    <div>
      <div
        className="cursor-pointer"
        onClick={() => {
          handelTicketStep(1)
        }}
      >
        <ArrowBack
          className=""
          style={{
            color: '#122B46',
            fontSize: '18px',
          }}
        />
        <span className="text-nr text-[#122B46] mr-2">Back</span>
      </div>

      {loading ? (
        <ViewsLoader className="flex items-center !w-full justify-center" size="xl" color="red" />
      ) : (
        <div
          className={`w-full flex border rounded-lg justify-between p-4 pt-1 mt-4 ${!downloadbut ? '' : ''}`}
          ref={contentRef}
        >
          <div className={`${downloadbut ? ' w-[70%]' : 'w-[55%]'}`}>
            <div className="modal-header py-2 flex-shrink-0 flex items-center justify-between rounded-t-md">
              <h5
                className={`text-xl font-semibold leading-normal text-[#F7074F]`}
                id="exampleModalScrollableLabel"
              >
                Ticket: {selectedTicket?.ticketTitle ?? 'Unknown'}
              </h5>
            </div>
            <div className="">
              {viewTicket.length > 0 &&
                viewTicket.map((item: any, index: number) => {
                  if (index === 0) return null

                  return (
                    <div
                      key={[JSON.stringify(item), index].join()}
                      className={`${index === 1 ? 'col-span-2' : ''} flex items-center mb-4`}
                    >
                      <img
                        src={item?.icon}
                        className={`w-[40px] mr-2 rounded-md p-1 bg-[#F6F7FB]`}
                        alt="icon"
                      />
                      <div className="flex flex-col ">
                        <p className={clsx('text-[#122B46] text-nr font-normal', item?.className)}>
                          {item?.name}
                        </p>
                        <p className="text-[#122b46] text-opacity-50 text-sm font-normal">
                          {item?.description}
                        </p>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>

          <div className="min-w-[175px] relative">
            <div className="flex ">
              <div className="w-full mr-2 mt-2">
                <div
                  className={`flex flex-col rounded-md max-w-[117px] w-full p-1 ${!downloadbut ? '' : ''} ${viewTicket[0].name === 'Assigned' ? 'bg-[#00b6aa23] ' : 'bg-[#f7074f27]'}`}
                >
                  <p
                    className={`text-nr font-normal justify-center items-center flex ${viewTicket[0].name === 'Assigned' ? 'text-[#00b6aa]' : 'text-SeabiscuitMainThemeColor'}`}
                  >
                    {viewTicket[0]?.name}
                  </p>
                  <p
                    className={` text-opacity-50 text-[10px] flex items-center justify-center font-normal ${viewTicket[0].name === 'Assigned' ? 'text-[#00b6aa]' : 'text-SeabiscuitMainThemeColor'}`}
                  >
                    {viewTicket[0]?.description}
                  </p>
                </div>
              </div>

              <Tooltip
                title={<h1 className="tooltip_title">{`Download Ticket`}</h1>}
                placement="top"
                arrow
              >
                <div>
                  {downloadbut ? (
                    <div
                      className="flex items-center bg-[#F6F7FB] hover:bg-[#feeded] border border-[#F6F7FB] min-w-[50px] justify-center h-[45px] cursor-pointer rounded-lg text-nr font-medium my-2"
                      onClick={downloadPDF}
                      onMouseEnter={() => setIsHovering(true)}
                      onMouseLeave={() => setIsHovering(false)}
                    >
                      <img
                        src={
                          isHovering
                            ? 'assets/og_icons/DownloadfromtheCloud-3.svg'
                            : 'assets/og_icons/DownloadfromtheCloud-1.svg'
                        }
                        width="25px"
                        height="25px"
                        alt="Download"
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Tooltip>
              {/* {addToCalender ? <button className=' border border-solid border-SeabiscuitGray500ThemeColor rounded-[12px] p-3 cursor-pointer !w-full min-w-fit h-[50px] relative text-center my-2'> */}
              {addToCalender ? (
                <Tooltip
                  title={<h1 className="tooltip_title">{`Add to Calendar`}</h1>}
                  placement="top"
                  arrow
                >
                  <div>
                    <AddToCalendarButton
                      name={viewTicket?.[1]?.name}
                      startDate={getFormattedDate()}
                      endDate={getFormattedEndDate()}
                      options={['Apple', 'Google']}
                      location={viewTicket?.[5]?.name}
                      description={getDescription()}
                      hideBackground={true}
                      hideTextLabelButton={true}
                      buttonStyle="round"
                      styleLight="--btn-background: #F6F7FB; --btn-background-hover: #feeded; --btn-shadow: none; --btn-shadow-hover: none; --btn-border: none; --btn-text: #1f4173; --btn-text-hover: #f60a4e;"
                    />
                  </div>
                </Tooltip>
              ) : null}
            </div>

            <div className="absolute bottom-0 right-0 text-black">
              <img src="assets/img/barcode/fakebarcode.jpg" width="100%" alt="barcode" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ViewTicket
