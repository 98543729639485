// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useContext, useEffect } from 'react'
import './EventVendorsFormComponent.css'

import TogglesElement from '../../../elements/toggles/toggles/TogglesElement'
import EventFormContainerComponent from '../container/EventFormContainerComponent'
import CheckedInput from './CheckedInput'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { EventVendorsModel } from '../../../../models/event-vendors/event-vendors.model'

import { Tooltip } from '@mui/material'
import clsx from 'clsx'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import AmountInput from '../../../common/inputs/AmountInput'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const eventVendorsFormDefaultValues = new EventVendorsModel().toObject()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const eventVendorsFormValidationSchema = EventVendorsModel.validationSchema()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventVendorsFormComponentProps {
  data: any
  handleModal?: any
  dataToPassOn?: any
  onValid: any
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  nextEventTab?: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventVendorsFormComponent: React.FC<EventVendorsFormComponentProps> = (props) => {
  // Hooks and vars
  const handleModalContext = useContext(HandleModalContext)

  const { handleSubmit, register, setValue, watch, control, trigger, reset } = useForm({
    defaultValues: { ...eventVendorsFormDefaultValues },
    resolver: yupResolver(eventVendorsFormValidationSchema),
    mode: 'onChange',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'vendorOpportunities',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  useEffect(
    () => {
      if (props.data) {
        reset(props.data)
      } else {
        reset(eventVendorsFormDefaultValues)
      }
      append({
        name: '',
        note: '',
        number: '',
        price: '',
        uuid: uuidv4(),
      })
      trigger().then()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.data, reset, trigger]
  )

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  // const vendorValueHandle = (value: string, key: string, index: number) => {
  //     let temp: { [key: string]: any }[] = [...vendors];
  //     if (temp[index]) {
  //         temp[index][key] = value;
  //     }
  //     setVendors(temp);
  // }

  /**
   * @param noteInputRef Input reference
   * @info Opens note modal
   */
  const openAddNotesModal = (noteInputRef: any) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NOTE, {
      noteInputRef,
      setValue,
      trigger,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  // const addVendorHandle = () => {
  //     setVendors([...vendors, { name: '', note: '', number: '', price: '' }]);
  // }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <EventFormContainerComponent>
      <div className="w-full">
        <EventFormHeaderComponent title="Vendors" description="Sell vendor packages at your event">
          <EventFormFooterCommonComponent
            nextEventTab={props.nextEventTab}
            onNext={(e: any) => handleSubmit(props.onValid, props.onInvalid)(e)}
            onSaveAndExit={(e: any) => handleSubmit(props.onValidAnExit, props.onInvalidAndExit)(e)}
          />
        </EventFormHeaderComponent>

        <div className="tablet:items-center my-8 flex gap-2 flex-col-reverse tablet:flex-row">
          <label className="2xl:text-xl xl:text-base tw-title text-SeabiscuitDark200ThemeColor font-normal mr-2">
            Accept vendors on grounds (clothing, food trucks, etc.) at this event?
          </label>

          <Controller
            name={'acceptVendorsOnGrounds'}
            control={control}
            render={({ field: { value } }) => (
              <TogglesElement
                onToggle={() => {
                  setValue('acceptVendorsOnGrounds', !value)
                }}
                on={value}
              />
            )}
          />
        </div>

        {watch('acceptVendorsOnGrounds') ? (
          <>
            <div className="w-full text-xs overflow-x-auto pb-[20px]">
              <div className="w-[1320px] mt-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_auto_auto] laptop:grid-cols-[200px_auto_200px_200px_30px]">
                <div className="flex items-center">
                  <label className="text-xs font-semibold text-SeabiscuitDark200ThemeColor flex items-center ">
                    Name vendor opportunity
                    <Tooltip
                      title={
                        <h1 className="tooltip_title">{`Eg. ‘Medium size tent’ or ‘Food truck at entrance’`}</h1>
                      }
                      placement="top"
                      arrow
                    >
                      <button>
                        <TooltipIcon color="#122B46" />
                      </button>
                    </Tooltip>
                  </label>
                </div>

                <div>
                  <label className="text-xs font-semibold text-SeabiscuitDark200ThemeColor flex items-center">
                    Note
                    <Tooltip
                      title={
                        <h1 className="tooltip_title">{`Details what’s included in this vendor package`}</h1>
                      }
                      placement="top"
                      arrow
                    >
                      <button>
                        <TooltipIcon color="#122B46" />
                      </button>
                    </Tooltip>
                  </label>
                </div>

                <div>
                  <label className="text-xs font-semibold text-SeabiscuitDark200ThemeColor flex items-center">
                    Number of units
                    <Tooltip
                      title={
                        <h1 className="tooltip_title">{`Number of these vendor packages for sale`}</h1>
                      }
                      placement="top"
                      arrow
                    >
                      <button>
                        <TooltipIcon color="#122B46" />
                        {/* <img className="w-4 h-4 ml-1" src="/assets/cp_icons/Help.svg" alt="icon" /> */}
                      </button>
                    </Tooltip>
                  </label>
                </div>

                <div>
                  <label className="text-xs font-semibold text-SeabiscuitDark200ThemeColor flex items-center">
                    Price per unit
                    <Tooltip
                      title={
                        <h1 className="tooltip_title">{`Price you will charge for this vendor package`}</h1>
                      }
                      placement="top"
                      arrow
                    >
                      <button>
                        <TooltipIcon color="#122B46" />
                        {/* <img className="w-4 h-4 ml-1" src="/assets/cp_icons/Help.svg" alt="icon" /> */}
                      </button>
                    </Tooltip>
                  </label>
                </div>
              </div>

              {fields.map((field, index) => (
                <div
                  className="mt-4 w-[1320px] grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_auto_auto] laptop:grid-cols-[200px_auto_200px_200px_30px]"
                  key={field.id}
                >
                  <div>
                    <div className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12">
                      <input
                        type="text"
                        placeholder="Name..."
                        className="w-full bg-transparent outline-0 ring-0 focus:outline-0 focus:ring-0 border-0 text-SeabiscuitDark200ThemeColor focus:border-0"
                        {...register(`vendorOpportunities.${index}.name`)}
                      />
                    </div>
                  </div>

                  <div className="flex-grow w-full mx-auto tablet:col-span-2 laptop:col-span-1">
                    <div className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12">
                      <input
                        type="text"
                        onClick={(e) => openAddNotesModal(e.target)}
                        readOnly={true}
                        placeholder="Opportunity summary - max 150 characters"
                        className="w-full bg-transparent outline-0 ring-0 focus:outline-0 focus:ring-0 border-0 text-SeabiscuitDark200ThemeColor focus:border-0 cursor-pointer"
                        {...register(`vendorOpportunities.${index}.note`)}
                      />
                    </div>
                  </div>

                  <div className="tablet:col-start-2 laptop:col-span-1">
                    <div className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12">
                      <input
                        type="text"
                        placeholder="Number of units"
                        className="w-full bg-transparent outline-0 ring-0 focus:outline-0 focus:ring-0 border-0 text-SeabiscuitDark200ThemeColor text-center focus:border-0"
                        {...register(`vendorOpportunities.${index}.number`)}
                      />

                      {/* {
                                                !!watch(`vendorOpportunities.${index}.number`).trim() ?
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 2C6.48603 2 2 6.48604 2 12C2 17.514 6.48603 22 12 22C17.514 22 22 17.514 22 12C22 6.48604 17.514 2 12 2ZM12 3.5C16.7033 3.5 20.5 7.2967 20.5 12C20.5 16.7033 16.7033 20.5 12 20.5C7.29669 20.5 3.5 16.7033 3.5 12C3.5 7.2967 7.29669 3.5 12 3.5ZM15.7354 8.99316C15.5406 8.99884 15.3556 9.0801 15.2197 9.21973L10.75 13.6895L8.78027 11.7197C8.71116 11.6477 8.62839 11.5903 8.53679 11.5507C8.4452 11.5111 8.34662 11.4902 8.24684 11.4892C8.14706 11.4882 8.04808 11.5071 7.9557 11.5448C7.86332 11.5825 7.77939 11.6383 7.70883 11.7088C7.63828 11.7794 7.58251 11.8633 7.54479 11.9557C7.50707 12.0481 7.48817 12.1471 7.48918 12.2468C7.4902 12.3466 7.51111 12.4452 7.5507 12.5368C7.59028 12.6284 7.64775 12.7112 7.71973 12.7803L10.2197 15.2803C10.3604 15.4209 10.5511 15.4999 10.75 15.4999C10.9489 15.4999 11.1396 15.4209 11.2803 15.2803L16.2803 10.2803C16.3885 10.1749 16.4623 10.0393 16.4922 9.89126C16.5221 9.7432 16.5066 9.58958 16.4477 9.4505C16.3888 9.31141 16.2893 9.19335 16.1622 9.11175C16.0351 9.03015 15.8863 8.98882 15.7354 8.99316Z" fill="#00B6AA" />
                                                    </svg>
                                                    : null
                                            } */}
                    </div>
                  </div>

                  <AmountInput
                    name={`vendorOpportunities.${index}.price`}
                    register={register}
                    prefix="$"
                    placeholder="Price per unit"
                    inputClassName="w-full bg-transparent outline-0 ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor focus:outline-0 text-center focus:ring-0 border-0 justify-center"
                    className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center justify-center text-center p-4 h-12"
                    childrenToAppend={
                      <img
                        src="/assets/og_icons/Ok-3.svg"
                        alt="okIcon"
                        className={clsx(
                          !!watch(`vendorOpportunities.${index}.price`) &&
                            watch(`vendorOpportunities.${index}.price`) !== '0' &&
                            watch(`vendorOpportunities.${index}.price`) !== '$0'
                            ? 'visible'
                            : 'invisible'
                        )}
                      />
                    }
                    value={watch(`vendorOpportunities.${index}.price`)}
                  />
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      props.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                        removeItem: function () {
                          remove(index)
                        },
                      })
                    }}
                  >
                    <div className="cursor-pointer">
                      <img
                        src="/assets/og_icons/Cancel.svg"
                        className="h-[24px] w-[24px]"
                        alt="Cancel"
                      />
                    </div>
                  </div>
                  <div className="laptop:hidden border-b-[1px] tablet:col-span-3"></div>
                </div>
              ))}
            </div>

            <div className="flex gap-5 justify-between items-center">
              <button
                className="flex items-center gap-1 mt-4 text-[#8676FF] text-sm font-medium"
                onClick={() => {
                  append({
                    name: '',
                    note: '',
                    number: '',
                    price: '',
                    uuid: uuidv4(),
                  })
                }}
              >
                <img
                  src={`/assets/og_icons/Cancel-5.svg`}
                  className="w-5 rotate-45"
                  alt="plus icon"
                />
                Add Vendor Item
              </button>

              <button
                onClick={() => {
                  remove()
                }}
                className="my-3 text-SeabiscuitDark200ThemeColor hover:text-SeabiscuitRedThemeColor font-normal text-[12px] flex items-center gap-2"
              >
                <span>Clear All</span>
              </button>
            </div>
          </>
        ) : null}

        <div className="mt-8 mb-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[200px_auto_auto] laptop:grid-cols-[390px_auto]">
          <div className="xl:text-base 2xl:text-xl tw-title text-SeabiscuitDark200ThemeColor font-normal tablet:col-span-3 laptop:col-span-1 flex">
            Accept virtual vendors at this event?
            <Tooltip
              title={
                <h1 className="tooltip_title">{`Virtual vendors will display as vendors at your event inside the app only. They will not have a physical presence and require minimal management.`}</h1>
              }
              placement="top"
              arrow
            >
              <button className="">
                <TooltipIcon color="#122B46" />
                {/* <img src={hintIcon} alt="icon" /> */}
              </button>
            </Tooltip>
          </div>

          <div>
            {/* <label htmlFor="liscenseeId" className="text-xs font-semibold text-SeabiscuitDark200ThemeColor flex items-center pl-2">
                            Status
                        </label> */}

            <Controller
              name={'acceptVirtualVendors'}
              control={control}
              render={({ field: { value } }) => (
                <TogglesElement
                  onToggle={() => setValue('acceptVirtualVendors', !value)}
                  on={value}
                />
              )}
            />
          </div>
        </div>

        {watch('acceptVirtualVendors') ? (
          <>
            <div className="mt-8 mb-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[120px_auto_auto] laptop:grid-cols-[20%_20%_60%]">
              <div>
                <label
                  htmlFor="liscenseeId"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor flex items-center pl-2"
                >
                  Spots available
                </label>
                <Controller
                  name={'virtualVendorSpotsAvailable'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CheckedInput
                      className="text-center"
                      name={'virtualVendorSpotsAvailable'}
                      type="string"
                      value={value}
                      notChecked={true}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\D/, '')
                        onChange(e)
                      }}
                      disabled={false}
                      placeholder="Number of available spots"
                    />
                  )}
                />
              </div>

              <div>
                <label
                  htmlFor="liscenseeId"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor flex items-center pl-2"
                >
                  Price
                </label>

                <AmountInput
                  name="virtualVendorPrice"
                  register={register}
                  prefix="$"
                  placeholder="Price per unit"
                  inputClassName="w-full bg-transparent outline-0 ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor focus:outline-0 focus:ring-0 border-0 text-[14px] text-center"
                  className="w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
                  value={watch('virtualVendorPrice')}
                  // childrenToAppend={
                  //     <>
                  //         {
                  //             (watch(`virtualVendorPrice`) && watch(`virtualVendorPrice`) !== "$" && watch(`virtualVendorPrice`) !== "$0") ? <img src={GreenCheck} alt="green check" /> : null
                  //         }
                  //     </>}
                />
              </div>
              <div className="tablet:col-span-3 laptop:col-span-1">
                <label
                  htmlFor="liscenseeId"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor flex items-center pl-2"
                >
                  Note
                </label>

                <Controller
                  name={'virtualVendorNote'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CheckedInput
                      type="string"
                      name={'virtualVendorNote'}
                      value={value}
                      trigger={trigger}
                      notChecked={!!!watch(`virtualVendorNote`).trim()}
                      openAddNotesModal={openAddNotesModal}
                      onChange={onChange}
                      placeholder="Enter note..."
                    />
                  )}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </EventFormContainerComponent>
  )
}

export default EventVendorsFormComponent
