import { Skeleton } from '@mui/material'

export const HorseSkeleton = () => {
  return (
    <div className="w-full flex items-center items-left justify-start">
      <Skeleton
        variant="circular"
        width={45}
        height={45}
        className="mb-4 min-w-[45px] mr-20"
        style={{ backgroundColor: '#F1F3F8' }}
      />
      <Skeleton
        variant="rounded"
        width={'24%'}
        height={20}
        className="mb-4 mx-auto"
        style={{ backgroundColor: '#F1F3F8' }}
      />
      <Skeleton
        variant="rounded"
        width={'24%'}
        height={20}
        className="mb-4 mx-auto"
        style={{ backgroundColor: '#F1F3F8' }}
      />
      <Skeleton
        variant="rounded"
        width={'24%'}
        height={20}
        className="mb-4 mx-auto"
        style={{ backgroundColor: '#F1F3F8' }}
      />
    </div>
  )
}
