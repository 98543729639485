// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { Link } from 'react-router-dom'
const FourOFourPage: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <div className="pagenotfound flex flex-col items-center justify-center h-[calc(100vh-56px)] align-middle relative top-[-20px]">
        <img src="/assets/placeholders/404.svg" alt="404" className="w-[200px] h-[200px] mx-auto" />
        <h3 className="text-[1.5rem] text-SeabiscuitDark200ThemeColor mt-8">
          This page doesn't exist
        </h3>
        <Link
          to={'/'}
          className="w-1/4 h-12 mx-auto uppercase flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:bg-[#D70443] focus:outline-none focus:ring-2 focus:ring-offset-2 mt-10"
        >
          Back to Home
        </Link>
      </div>
    </>
  )
}

export default FourOFourPage
