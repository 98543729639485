import React, { useRef } from 'react'

import ICustomReactSelectProps from './customReactSelect.types'
import Select, { StylesConfig, GroupBase } from 'react-select'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const CustomReactSelect = React.forwardRef<any, ICustomReactSelectProps>((props, ref) => {
  // Hooks and vars
  const selectRef = useRef<any>(null)

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleMenuStateChange = async (isOpen: boolean) => {
    await new Promise((resolve) => setTimeout(() => resolve(true), 100))

    if (!!!props?.targetId) return

    let height = 0
    let valueOfCurrAttrubute: any = null
    let incrementBy = props?.incrementBy ?? 80
    let menuElement = ((ref ?? selectRef) as any)?.current?.menuListRef
    let attributes: React.HTMLAttributes<HTMLElement> | null
    let targetElement = document.querySelector(`#${props?.targetId}`) ?? null

    attributes = props?.targetAttributes ?? null
    incrementBy = incrementBy / 100

    if (targetElement) {
      if (attributes)
        Object(attributes).keys((currAttributeKey: keyof typeof attributes) => {
          valueOfCurrAttrubute = attributes?.[currAttributeKey]
          targetElement?.setAttribute?.(currAttributeKey, valueOfCurrAttrubute)
        })

      if (props?.incrementTargetHeight === false) return

      if (isOpen && menuElement) height = menuElement?.clientHeight * incrementBy

      targetElement.setAttribute('style', `margin-bottom: ${height}px`)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onMenuOpen = () => {
    props?.onMenuOpen?.()
    handleMenuStateChange(true)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onMenuClose = () => {
    props?.onMenuClose?.()
    handleMenuStateChange(false)
  }

  return (
    <Select
      ref={ref ?? selectRef}
      {...props}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      menuShouldScrollIntoView={true}
    />
  )
})

export default CustomReactSelect
