import { createAsyncThunk } from '@reduxjs/toolkit'
import helpers from '../../../commonHelpers/helpers'
import { CONST } from '../../../const/const'
import { CustomError } from '../../../helpers/helpers'
import { RecipientModel } from '../../../models/recipients/recipients'
import FirestoreService from '../../../services/firestoreService'
import IExhibitorSlice from '../types'

const updateRecipentRefundStatusThunk = createAsyncThunk<
  IExhibitorSlice['IUpdateRecipientRefundStatusThunkSuccessResponse'],
  IExhibitorSlice['IUpdateRecipientRefundStatusThunkErrorResponse']
>(
  'updateRecipentRefundStatusThunk',
  async (arg: IExhibitorSlice['IIUpdateRecipientThunkPayload'], thunkApi) => {
    try {
      const recipientSnapshot = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.NAME,
        arg.recipientDocId
      )

      if (!recipientSnapshot.exists())
        throw new CustomError({
          moduleName: 'updateRecipentRefundStatusThunk',
          fileName: 'updateRecipentRefundStatusThunk',
          message: 'Registration not found',
          devMessage: `Recipient where ${CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.FIELDS.ID.KEY}: ${arg.recipientDocId} doesn't exist`,
        })

      let recipient = RecipientModel.fromFirestoreDoc(recipientSnapshot).toObject()

      if (recipient.refundStatus === 'pending') {
        recipient.refundStatus = 'seen'
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.NAME,
          arg.recipientDocId,
          new RecipientModel(recipient).toFirestore()
        )

        return {
          message: 'Recipient refund status updated successfully',
        }
      }

      throw new CustomError({
        moduleName: 'updateRecipentRefundStatusThunk',
        fileName: 'updateRecipentRefundStatusThunk',
        message: 'Recipient not found',
        devMessage: `Recipient where ${CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.FIELDS.ID.KEY}: ${arg.recipientDocId} don't have pending in refund status`,
      })
    } catch (error: any) {
      helpers.logger({
        message: error,
      })

      return thunkApi.rejectWithValue({
        message: error?.message,
      })
    }
  }
)

export { updateRecipentRefundStatusThunk }
