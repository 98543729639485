import { useContext, useState } from 'react'
import { SignupStages } from './UserSignupViewComponentContextApi'

import { yupResolver } from '@hookform/resolvers/yup'
import { Clear } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { fileObjToBase64 } from '../../../../../commonHelpers/helpers'
import MessageHelperComp from '../../../../../helpers/MessageHelper'
import { IVendorSignupForm } from '../../../../../types/competitor_types'
import { vendorSignupSchema } from '../../../../../validations'
import locationIcon from '../assets/address.svg'
import backIcon from '../assets/arrow-left.svg'
import businessCat from '../assets/category.svg'
import websiteIcon from '../assets/location.svg'
import passwordIcon from '../assets/password.svg'
import phoneIcon from '../assets/phone-icon.svg'
import uploadIcon from '../assets/upload.svg'
import { UserSignupViewComponentCompetitorSignupFormProps } from './UserSignupViewComponentCompetitorSignupForm'
import { Visibility, VisibilityOffOutlined } from '@mui/icons-material'
import GlobalInputComponent from '../../../../events/forms/detail/Components/GlobalInputComponent'

const UserSignupViewComponentVendorForm: React.FC<
  UserSignupViewComponentCompetitorSignupFormProps
> = (props) => {
  const [stage, setStage] = useContext(SignupStages)
  const [fileUrl, setFileUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPass, setshowPass] = useState(false)
  const [showConfirmPass, setshowConfirmPass] = useState(false)
  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm<IVendorSignupForm>({
    mode: 'onChange',
    resolver: yupResolver(vendorSignupSchema),
  })

  const handleLocation = (values: { formatted_address: string }) => {
    console.log({ values, address: watch('userAddress') })
    if (values.formatted_address) {
      setValue('userAddress', values.formatted_address)
    } else {
      setValue('userAddress', '')
    }
  }

  const handleGetFile = async (e: any) => {
    if (!e.target.files?.[0]) return

    const base64 = await fileObjToBase64(e.target.files?.[0])
    setValue('profilePic', e.target.files?.[0])
    setFileUrl(base64 as string)
  }

  return (
    <div className="w-full">
      <div className="text-[24px] flex items-center gap-2 mt-6">
        <button onClick={() => stageHandle('Init')}>
          <img src={backIcon} alt="back" className="" />
        </button>
        <div className="text-white">VENDOR OR SPONSOR</div>
      </div>
      <form onSubmit={handleSubmit((e) => props.onSignUpButtonPressed(e, stage.selector))}>
        <div>
          <label className="mb-3 text-white flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/og_icons/User-4.svg" alt="icon" className="brightness-200" />
            </span>
            <input
              type="text"
              {...register('userName')}
              placeholder="Business Name"
              className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent focus:border:none w-full focus:border-none"
            />
          </label>
          {errors.userName && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.userName.message}
            />
          )}

          <label className="mb-3 text-white flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src={businessCat} alt="icon" className="brightness-200" />
            </span>
            <input
              type="text"
              {...register('userCategory')}
              placeholder="Business Category"
              className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent focus:border:none w-full focus:border-none"
              autoComplete="off"
            />
          </label>
          {errors.userCategory && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.userCategory.message}
            />
          )}

          <label className="mb-3 text-white flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src={locationIcon} alt="icon" className="brightness-200" />
            </span>

            <GlobalInputComponent
              type="location"
              placeholder="Location"
              onChange={handleLocation}
              value={watch('userAddress') ?? ''}
              className={`bg-transparent rounded-xl !py-[7px] border pl-0 border-transparent text-white placeholder:text-white `}
              inputName="userAddress"
              control={control}
              register={register}
            />
          </label>
          {errors.userAddress && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.userAddress.message}
            />
          )}

          <label className="mb-3 text-white flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src={websiteIcon} alt="icon" className="brightness-200" />
            </span>
            <input
              type="text"
              {...register('website')}
              placeholder="Enter website link..."
              className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent focus:border:none w-full focus:border-none"
            />
          </label>
          {errors.website && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.website.message}
            />
          )}

          <label className="mb-3 text-white flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src={phoneIcon} alt="icon" className="brightness-200" />
            </span>
            <input
              type="tel"
              maxLength={10}
              {...register('userPhoneNumber')}
              placeholder="Phone Number"
              className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent focus:border:none w-full focus:border-none"
              autoComplete="off"
            />
          </label>
          {errors.userPhoneNumber && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.userPhoneNumber.message}
            />
          )}

          <label className="mb-3 text-gray-500 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/og_icons/userNameIcon.svg" alt="icon" className="brightness-200" />
            </span>
            <input
              type="text"
              {...register('vendorname')}
              placeholder="Username"
              className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent focus:border:none w-full focus:border-none"
            />
          </label>
          {errors.vendorname && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.vendorname.message}
            />
          )}

          <label className="mb-3 text-white flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/og_icons/Mail-4.svg" alt="icon" className="brightness-200" />
            </span>
            <input
              type="email"
              {...register('email')}
              placeholder="Business Email"
              className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent focus:border:none w-full focus:border-none"
              autoComplete="off"
            />
          </label>
          {errors.email && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.email.message}
            />
          )}

          <div className="border-solid rounded-xl border-white border-[1px] ">
            <label
              className={`flex  gap-2  w-full justify-center items-center ${
                fileUrl ? 'p-2' : 'py-10 '
              } cursor-pointer`}
            >
              {fileUrl && (
                <span className="relative">
                  <img src={fileUrl} alt="logo" className="w-[50px] h-[50px] border rounded-md" />
                  <Clear
                    className="absolute left-[14px] top-[13px] h-[22px] text-white border border-solid rounded-full"
                    onClick={(e) => {
                      e.preventDefault()
                      setFileUrl('')
                    }}
                  />
                </span>
              )}
              {!fileUrl && (
                <>
                  <div>
                    <img src={uploadIcon} alt="icon" className="brightness-200" />
                  </div>
                  <div className="text-[#D3DAEE]">Upload Logo</div>
                </>
              )}
              <input
                type="file"
                placeholder="Upload Logo"
                onChange={(e) => handleGetFile(e)}
                className="hidden bg-transparent focus:ring-transparent placeholder:text-[#D3DAEE] text-white"
                accept=".jpeg, .png, .jpg"
              />
            </label>
          </div>
          {errors.profilePic && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.profilePic.message}
            />
          )}

          <label className="mb-3 text-white flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent mt-3">
            <span className="w-[22px]">
              <img src={passwordIcon} alt="icon" className="brightness-200" />
            </span>
            <input
              type={showPass ? 'text' : 'password'}
              {...register('c_password')}
              placeholder="Your Password"
              className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent focus:border:none w-full focus:border-none"
              autoComplete="off"
            />
            {showPass ? (
              <VisibilityOffOutlined className="text-white" onClick={() => setshowPass(false)} />
            ) : (
              <Visibility className="text-white" onClick={() => setshowPass(true)} />
            )}
          </label>
          {errors.c_password && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.c_password.message}
            />
          )}

          <label className="mb-3 text-white flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src={passwordIcon} alt="icon" className="brightness-200" />
            </span>
            <input
              type={showConfirmPass ? 'text' : 'password'}
              {...register('newPassword')}
              placeholder="Confirm Password"
              className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent focus:border:none w-full focus:border-none"
            />
            {showConfirmPass ? (
              <VisibilityOffOutlined
                className="text-white"
                onClick={() => setshowConfirmPass(false)}
              />
            ) : (
              <Visibility className="text-white" onClick={() => setshowConfirmPass(true)} />
            )}
          </label>
          {errors.newPassword && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.newPassword.message}
            />
          )}
        </div>
        <div className="w-[70%] my-[60px] mx-auto">
          <button
            // disabled={props?.loading}
            type="submit"
            className="w-full h-12 flex items-center justify-center py-2 px-4 border !border-white border-solid rounded-2xl shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor nextBtnShadow bg-white opacity-80 hover:opacity-100 focus:outline-none focus:ring-0"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  )
}

export default UserSignupViewComponentVendorForm
