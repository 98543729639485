import { ModelBaseModel } from '../model-base/model-base.model'
import { IHorseLineage, THorseTeamMembers } from './horse-lineage.interface'

export class HorseLineageModel extends ModelBaseModel<IHorseLineage> {
  public id: IHorseLineage['id']
  public horseLineageMembers: THorseTeamMembers[]

  public constructor(obj?: IHorseLineage) {
    super()
    this.id = obj?.id ?? ''
    this.horseLineageMembers = obj?.horseLineageMembers ?? []
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  static fromFirestoreDoc(doc: any) {
    return new HorseLineageModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
