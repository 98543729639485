// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import clsx from 'clsx'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const HorizantalDivider = ({ className }: { className?: string }) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div
      className={clsx(
        'border-solid border-[1px] border-transparent border-t-[#D3DAEE] 2xl:my-[30px] my-5',
        className
      )}
    ></div>
  )
}

export default HorizantalDivider
