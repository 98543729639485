import { useAppSelector } from '../../../../../../store/hooks'
import { selectProfileData } from '../../../../../../store/user/userSlice'
import { AboutViewComponentDataKeyElement } from '../../../../../../components/events/views/details/AboutViewComponentDataKeyElement'

const AboutTab = () => {
  // const handleModal = useContext(HandleModalContext);
  const selectProfileDetails = useAppSelector(selectProfileData)
  return (
    <>
      <div
        id="news-section"
        itemScope
        itemType="http://schema.org/NewsArticle"
        className="max-h-[600px] overflow-auto flex gap-2"
      >
        <AboutViewComponentDataKeyElement
          logo="/assets/og_icons/Speech Bubble-1.svg"
          title="Contact"
          children={
            <>
              <div className="flex flex-col text-SeabiscuitDark200ThemeColor text-[10px]">
                <div className="flex flex-row justify-between">
                  <span>Phone : </span>
                  <span>{selectProfileDetails.userPhoneNumber}</span>
                </div>
                <div className="flex flex-row break-all justify-between ">
                  <span>Email : </span>
                  <span className="w-[70px] ellipsis" title={selectProfileDetails.userEmail}>
                    {selectProfileDetails.userEmail}
                  </span>
                </div>
              </div>
            </>
          }
        />
        <AboutViewComponentDataKeyElement
          logo="/assets/og_icons/Location-1.svg"
          title="Location"
          children={
            <>
              <div className="flex flex-col text-SeabiscuitDark200ThemeColor text-[10px]">
                <div className="flex flex-row">{selectProfileDetails.userAddress}</div>
              </div>
            </>
          }
        />
        <AboutViewComponentDataKeyElement
          logo="/assets/og_icons/Globe-1.svg"
          title="Website"
          children={
            <>
              <div className="flex flex-col text-SeabiscuitDark200ThemeColor text-[10px]">
                <div className="flex flex-row">{selectProfileDetails.websiteUrl}</div>
              </div>
            </>
          }
        />
        <AboutViewComponentDataKeyElement
          logo="/assets/og_icons/Add User Male-1.svg"
          title="Socials"
          children={
            <>
              <div className="flex flex-col text-SeabiscuitDark200ThemeColor text-[10px] justify-between">
                <div className="flex flex-row gap-2 justify-between text-[10px]">
                  <span className="flex flex-col">{`Facebook >`}</span>
                  <span className="flex flex-col">{`Instagram >`}</span>
                </div>
                <div className="flex flex-row gap-2 justify-between text-[10px]">
                  <span className="flex flex-col">{`Twitter >`}</span>
                  <span className="flex flex-col">{`LinkedIn >`}</span>
                </div>
              </div>
            </>
          }
        />
      </div>
      <hr className="w-full mt-3" />
      <div>
        <div className="flex items-center gap-2 flex-wrap mb-2 mt-4 text-SeabiscuitDark200ThemeColor text-justify">
          <h3 className="text-base 2xl:text-lg font-bold text-SeabiscuitDark200ThemeColor">
            About
          </h3>
          {selectProfileDetails?.userDescription}
        </div>
      </div>
    </>
  )
}

export default AboutTab
