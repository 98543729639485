// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React from "react";
import SubheadingElement from "../../../elements/headings/subheading/SubheadingElement";

import './ProgressBar.css'

import Ok from '../../../elements/images/Ok.png'
import Cancle from '../../../elements/images/Cancel.png'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ProgressBar = ({imgSrc, imgName, percentage}) => {

    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    return (
        <div style={{
            border: '1px solid #1F417333', display: 'flex',
            justifyContent: 'space-around', alignItems: 'center', padding: '0.5rem', borderRadius: '0.4rem',
            marginBottom: '1rem'
        }}>
            <div style={{background: '', width: '10%', borderRadius: '0.5rem'}}>
                <img src={imgSrc} style={{
                    width: '100%', height: '100%',
                    borderRadius: '0.8rem',
                    boxShadow: '0px 3px 3px #00000040'
                }} alt="progress-bg"/>
            </div>
            <div style={{background: '', width: '80%', margin: '0 1rem'}}>
                <div style={{
                    display: 'flex', background: '', justifyContent: 'space-between',
                    marginBottom: '0.5rem'
                }}>
                    <SubheadingElement text={imgName} classname='text'/>
                    <p className='text-2'>{percentage}%</p>
                </div>
                <div id='my-progress'>
                    <div id='my-bar' style={{width: `${percentage}%`}}
                         className={percentage === 100 ? 'completed' : 'in-progress'}>
                    </div>
                </div>
            </div>
            <div style={{background: '', width: '10%', cursor: 'pointer'}}>
                {percentage === 100 ? <img src={Ok} alt="ok"/> : <img src={Cancle} alt="not-ok"/>}
            </div>
        </div>
    );
}

export default ProgressBar;
