import { useState } from 'react'
import TabList from './tabs/TabList'

const Tabs = () => {
  const [currentTab, setCurrentTab] = useState(0)

  return (
    <div className="w-[calc(70%-15px)]">
      <TabList currentTab={currentTab} setCurrentTab={setCurrentTab} />
    </div>
  )
}

export default Tabs
