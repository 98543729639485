// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useContext, useState } from 'react'

import { MODAL_CONSTS } from '../../../../const/modal-const'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import EventFormContainerComponent from '../container/EventFormContainerComponent'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import './EventSendNotificationsFormComponent.css'
import SendPrizeListUSEFModal from './SendPrizeListUSEFModal'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../../../const/messages-const'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventSendNotificationsFormComponentProps {
  data: any
  onValid: any
  onInvalid: any
  nextEventTab?: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const NotifButton: React.FC<{
  caption: string
  icon: 'mail' | 'seach'
  onClick: () => void
}> = ({ caption, icon, onClick }) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <button
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="flex-1 flex gap-2 items-center bg-gray-100 rounded-xl h-12 px-4 text-[10px] font-semibold hover:text-SeabiscuitMainThemeColor hover:bg-[#FEF5F6]"
    >
      {icon === 'mail' ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.25 4C3.46403 4 2 5.46403 2 7.25V16.75C2 18.536 3.46403 20 5.25 20H18.75C20.536 20 22 18.536 22 16.75V7.25C22 5.46403 20.536 4 18.75 4H5.25ZM5.25 5.5H18.75C19.725 5.5 20.5 6.27497 20.5 7.25V7.80273L12 12.3975L3.5 7.80273V7.25C3.5 6.27497 4.27497 5.5 5.25 5.5ZM3.5 9.50781L11.6436 13.9102C11.7531 13.9693 11.8755 14.0003 12 14.0003C12.1245 14.0003 12.2469 13.9693 12.3564 13.9102L20.5 9.50781V16.75C20.5 17.725 19.725 18.5 18.75 18.5H5.25C4.27497 18.5 3.5 17.725 3.5 16.75V9.50781Z"
            fill={isHovered ? '#F7074F' : '#122B46'}
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.25 3C6.25482 3 3 6.25482 3 10.25C3 14.2452 6.25482 17.5 10.25 17.5C11.9782 17.5 13.5669 16.8895 14.8145 15.875L19.7197 20.7803C19.7888 20.8523 19.8716 20.9097 19.9632 20.9493C20.0548 20.9889 20.1534 21.0098 20.2532 21.0108C20.3529 21.0118 20.4519 20.9929 20.5443 20.9552C20.6367 20.9175 20.7206 20.8617 20.7912 20.7912C20.8617 20.7206 20.9175 20.6367 20.9552 20.5443C20.9929 20.4519 21.0118 20.3529 21.0108 20.2532C21.0098 20.1534 20.9889 20.0548 20.9493 19.9632C20.9097 19.8716 20.8523 19.7888 20.7803 19.7197L15.875 14.8145C16.8895 13.5669 17.5 11.9782 17.5 10.25C17.5 6.25482 14.2452 3 10.25 3ZM10.25 4.5C13.4345 4.5 16 7.06548 16 10.25C16 11.8013 15.3881 13.2029 14.3955 14.2354C14.3343 14.2803 14.2803 14.3343 14.2354 14.3955C13.2029 15.3881 11.8013 16 10.25 16C7.06548 16 4.5 13.4345 4.5 10.25C4.5 7.06548 7.06548 4.5 10.25 4.5Z"
            fill={isHovered ? '#F7074F' : '#122B46'}
          />
        </svg>
      )}
      <span>{caption}</span>
    </button>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventSendNotificationsFormComponent: React.FC<EventSendNotificationsFormComponentProps> = (
  props
) => {
  const rowClass = `grid grid-cols-1 mini:grid-cols-[380px_auto] gap-4  text-[18px] text-SeabiscuitDark200ThemeColor mt-6 items-center rowClass`
  const buttonConClass = `flex gap-2 mini:w-[400px]`

  const handleModalContext = useContext(HandleModalContext)
  const setSuccessModalShow = useState(false)[1]
  const setSuccessModalMessage = useState('Prize list sent')[1]
  const [sendPrizeListUSEFModalShow, setSendPrizeListUSEFModalShow] = useState(false)
  // const [userSearchModalShow, setUserSearchModalShow] = useState(false);
  // const [userSearchModalType, setUserSearchModalType] = useState(1);
  // const [vendroSponserInviteModal, setVendroSponserInviteModal] = useState({ visible: false });
  // const [sendEmailModalShow, setSendEmailModalShow] = useState(false);
  // const [sendEmailModalType, setSendEmailModalType] = useState(1);

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const USEFSendHandle = () => {
    setSendPrizeListUSEFModalShow(false)
    setSuccessModalMessage('Prize list sent')
    setSuccessModalShow(true)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  // const prizeListSearchModalHandle = () => {
  //     setUserSearchModalShow(true);
  //     setUserSearchModalType(1);
  // };

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo open send email modal
   */
  const openSendEmailModal = (type: number) => {
    switch (type) {
      case 1:
        handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL, {
          type: 1,
        })
        break
      case 2:
        handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL, {
          type: 2,
        })
        break
      case 3:
        handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL, {
          type: 3,
        })
        break
      case 4:
        handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL, {
          type: 4,
        })
        break
      case 6:
        handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL, {
          type: 6,
        })
        break
      case 7:
        handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL, {
          type: 7,
        })
        break
    }
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo send notification to search
   */

  const openSearchEmailModal = (type: number) => {
    switch (type) {
      case 1:
        handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS, { type: 1 })
        break
      case 2:
        handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS, { type: 2 })
        break
      case 3:
        handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS, { type: 3 })
        break
      case 4:
        handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS, { type: 4 })
        break
      case 6:
        handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS, { type: 6 })
        break
      case 7:
        handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS, { type: 7 })
        break
    }
  }
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  // const prizeListSendEmailModalHandle = () => {
  //     handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS, { type: 1 })
  // };

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  // const vendorsSearchModalHandle = () => {
  //     handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS, { type: 2 })
  // };

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  // const vendorsSendEmailModalHandle = () => {
  //     handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL, { type: 2 })
  // };

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  // const nearbySearchModalHandle = () => {
  //     handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS, { type: 3 })
  // };

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  // const nearbySendEmailModalHandle = () => {
  //     handleModalContext?.handleModal(true, MODAL_CONSTS.VENDOR_SPONSOR_INVITATIONS_VIA_MAIL, { type: 3 })
  // };

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  const toastFunctions = useToasterHelper()
  const comingSoonMessage = () => {
    toastFunctions.info({
      message: MESSAGES_CONST.COMING_SOON,
    })
  }

  return (
    <EventFormContainerComponent>
      <div className="mr-4 mb-4">
        <SendPrizeListUSEFModal
          show={sendPrizeListUSEFModalShow}
          onHide={() => setSendPrizeListUSEFModalShow(false)}
          onSend={USEFSendHandle}
        />

        <EventFormHeaderComponent title="Send Notifications" />

        <div>
          <div onClick={comingSoonMessage}>
            <div className={rowClass} aria-disabled={true}>
              <div className="2xl:text-xl xl:text-base tw-title text-SeabiscuitDark200ThemeColor font-normal flex items-center group-disabled:bg-red-600">
                Send Prize List to USEF
              </div>
              <div className={buttonConClass}>
                <NotifButton
                  caption="Send"
                  icon="mail"
                  onClick={() => setSendPrizeListUSEFModalShow(true)}
                />
              </div>
            </div>
          </div>
          <div onClick={comingSoonMessage}>
            <div className={rowClass} aria-disabled={true}>
              <div className="2xl:text-xl xl:text-base tw-title text-SeabiscuitDark200ThemeColor font-normal flex items-center">
                Send Prize List
              </div>
              <div className={buttonConClass}>
                <NotifButton
                  caption="Search"
                  icon="seach"
                  onClick={() => openSearchEmailModal(1)}
                />
                <NotifButton
                  caption="Email"
                  icon="mail"
                  onClick={() => {
                    openSendEmailModal(1)
                  }}
                />
              </div>
            </div>
          </div>

          <div className={rowClass} aria-disabled={false}>
            <div className="2xl:text-xl xl:text-base tw-title text-SeabiscuitDark200ThemeColor font-normal flex items-center">
              Invite staff to event
            </div>
            <div className={buttonConClass}>
              <NotifButton caption="Search" icon="seach" onClick={() => openSearchEmailModal(4)} />
              <NotifButton caption="Email" icon="mail" onClick={() => openSendEmailModal(4)} />
            </div>
          </div>

          <div onClick={comingSoonMessage}>
            <div className={rowClass} aria-disabled={true}>
              <div className="2xl:text-xl xl:text-base tw-title text-SeabiscuitDark200ThemeColor font-normal flex items-center">
                Invite vendors & sponsors
              </div>
              <div className={`${buttonConClass} hover:text-SeabiscuitMainThemeColor`}>
                <NotifButton
                  caption="Search"
                  icon="seach"
                  onClick={() => openSearchEmailModal(2)}
                />
                <NotifButton caption="Email" icon="mail" onClick={() => openSendEmailModal(2)} />
              </div>
            </div>
          </div>
          <div onClick={comingSoonMessage}>
            <div className={rowClass} aria-disabled={true}>
              <div className="2xl:text-xl xl:text-base tw-title text-SeabiscuitDark200ThemeColor font-normal flex items-center">
                Invite nearby businesses
              </div>
              <div className={buttonConClass}>
                <NotifButton
                  caption="Search"
                  icon="seach"
                  onClick={() => openSearchEmailModal(3)}
                />
                <NotifButton caption="Email" icon="mail" onClick={() => openSendEmailModal(3)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </EventFormContainerComponent>
  )
}

export default EventSendNotificationsFormComponent
