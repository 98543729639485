import { ModelBaseModel } from '../model-base/model-base.model'

import { CONST } from '../../const/const'
import { IRegistrationFeesStatsInterface } from './registrationFeesStats.interface'

// Constants
const REGISTRATION_FEES_STATS_COLLECTION =
  CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_FEES_STATS

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @TODO Document this
 */
export class RegistrationFeesStatsModel extends ModelBaseModel<IRegistrationFeesStatsInterface> {
  // Properties
  public v: IRegistrationFeesStatsInterface['v']
  public id: IRegistrationFeesStatsInterface['id']
  public paymentStatus: IRegistrationFeesStatsInterface['paymentStatus']
  public userDocId: IRegistrationFeesStatsInterface['userDocId']
  public eventDocId: IRegistrationFeesStatsInterface['eventDocId']
  public userName: IRegistrationFeesStatsInterface['userName']
  public userEmail: IRegistrationFeesStatsInterface['userEmail']
  public userProfilePicture: IRegistrationFeesStatsInterface['userProfilePicture']
  public userNameNGrams: IRegistrationFeesStatsInterface['userNameNGrams']
  public feesItemCategory: IRegistrationFeesStatsInterface['feesItemCategory']
  public boughtItemsCount: IRegistrationFeesStatsInterface['boughtItemsCount']
  public registrationDocId: IRegistrationFeesStatsInterface['registrationDocId']
  public registrationFeesDocIdsDetails: IRegistrationFeesStatsInterface['registrationFeesDocIdsDetails']

  public created: IRegistrationFeesStatsInterface['created']
  public modified: IRegistrationFeesStatsInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param obj `IRegistrationFeesStatsInterface`
   */
  public constructor(obj?: IRegistrationFeesStatsInterface) {
    super()

    this.v = obj?.v ?? REGISTRATION_FEES_STATS_COLLECTION.FIELDS.V.VALUES.DEFAULT
    this.id = obj?.id ?? REGISTRATION_FEES_STATS_COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.paymentStatus =
      obj?.paymentStatus ?? REGISTRATION_FEES_STATS_COLLECTION.FIELDS.PAYMENT_STATUS.VALUES.DEFAULT
    this.userDocId =
      obj?.userDocId ?? REGISTRATION_FEES_STATS_COLLECTION.FIELDS.USER_DOC_ID.VALUES.DEFAULT
    this.eventDocId =
      obj?.eventDocId ?? REGISTRATION_FEES_STATS_COLLECTION.FIELDS.EVENT_DOC_ID.VALUES.DEFAULT
    this.userName =
      obj?.userName ?? REGISTRATION_FEES_STATS_COLLECTION.FIELDS.USER_NAME.VALUES.DEFAULT
    this.userEmail =
      obj?.userEmail ?? REGISTRATION_FEES_STATS_COLLECTION.FIELDS.USER_EMAIL.VALUES.DEFAULT
    this.feesItemCategory =
      obj?.feesItemCategory ??
      REGISTRATION_FEES_STATS_COLLECTION.FIELDS.FEES_ITEM_CATEGORY.VALUES.DEFAULT
    this.userProfilePicture =
      obj?.userProfilePicture ??
      REGISTRATION_FEES_STATS_COLLECTION.FIELDS.USER_PROFILE_PICTURE.VALUES.DEFAULT
    this.boughtItemsCount =
      obj?.boughtItemsCount ??
      REGISTRATION_FEES_STATS_COLLECTION.FIELDS.BOUGHT_ITEMS_COUNT.VALUES.DEFAULT
    this.registrationDocId =
      obj?.registrationDocId ??
      REGISTRATION_FEES_STATS_COLLECTION.FIELDS.REGISTRATION_DOC_ID.VALUES.DEFAULT
    this.registrationFeesDocIdsDetails =
      obj?.registrationFeesDocIdsDetails ??
      REGISTRATION_FEES_STATS_COLLECTION.FIELDS.REGISTRATION_FEES_DOCIDS_DETAILS.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })

    this.userNameNGrams = this.getCalculatedNGrams(this.userName)
  }

  // Methods

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new RegistrationFeesStatsModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
