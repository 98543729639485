import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { createContext, useState } from 'react'

// Hooks
import helpers from '../commonHelpers/helpers'
import { MESSAGES_CONST } from '../const/messages-const'
import { CustomError } from './helpers'

// Types
export type IBackgroundContext = {
  pushToBackground: IPushToBackground
  bgProcessDetails: IBgProgressDetails
  running: boolean
} | null
type IBackgroundContextFunction = () => Promise<any>
type IPushToBackground = ({
  functionToRunInBackground,
  notifyOnFinish,
  messageToShow,
}: IPushToBackgroundArgs) => void
type IPushToBackgroundArgs = {
  functionToRunInBackground: IBackgroundContextFunction
  notifyOnFinish: boolean
  messageToShow?: string | null
  bgProcessDetails?: IBgProgressDetails
}
type IBgProgressDetails = null | { pid: string; processName: string; result?: any }

const FILE_NAME = 'BackgroundProvider'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

export const BackgroundContext = createContext<IBackgroundContext>(null)

const BackgroundProvider = ({ children }: { children: ReactJSXElement }) => {
  // Hooks and vars
  const [running, setRunning] = useState(false)
  const [bgProcessDetails, setBgProcessDetails] = useState<IBgProgressDetails>(null)

  // Functions

  /** @info Is used to pass some function to run in this component */
  const pushToBackground: IPushToBackground = (args) => {
    let result_ = ''
    const { functionToRunInBackground, notifyOnFinish = false, messageToShow } = args
    // if (running && messageToShow !== null)
    if (running) {
      return helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          devMessage:
            'Unable to add process to background, since a background process is already running',
        }),
      })
    }

    if (args?.bgProcessDetails) {
      setBgProcessDetails(args.bgProcessDetails)
    }

    if (typeof functionToRunInBackground !== 'function') return

    setRunning(true)
    functionToRunInBackground()
      .then((result) => {
        result_ = result
        if (notifyOnFinish && messageToShow !== null)
          helpers.logger({
            isError: false,
            message: CustomError.somethingWentWrong({
              ...customErrorProps,
              devMessage: messageToShow || 'File upload successfully',
            }),
          })
      })
      .catch((err) => {
        helpers.logger({
          message: CustomError.somethingWentWrong({
            ...customErrorProps,
            devMessage: `Unable to finish background process${err?.message ? ', Cause: ' + err?.message : ''}`,
          }),
        })
      })
      .finally(() => {
        setRunning(false)
        setBgProcessDetails({
          pid: args?.bgProcessDetails?.pid ?? '',
          processName: args?.bgProcessDetails?.processName ?? '',
          result: result_,
        })
      })
  }

  return (
    <BackgroundContext.Provider value={{ pushToBackground, bgProcessDetails, running }}>
      {children}
    </BackgroundContext.Provider>
  )
}

export default BackgroundProvider
