import { AutorenewRounded } from '@mui/icons-material'
import CallMadeIcon from '@mui/icons-material/CallMade'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import clsx from 'clsx'
import { useRef, useState } from 'react'
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import customImageComponent from '../../../../../../components/common/CustomImageComponent'
import { IMAGE_CONSTS } from '../../../../../../const/image-const'
import { capitalize } from '../../../../../../helpers/helpers'
import { IDealInterface } from '../../../../../../models/deal/deal.interface'

type IProps = {
  index: number
  loading: boolean
  deletingIndex: null | number
  onEditDealButtonClick: (index: number) => void
  onMoreButtonClick: (index: number) => void
  onDeleteButtonClick: (index: number) => Promise<number>
} & IDealInterface & {
    loggedInUserId: string | null
  }

interface ExtendedSwiper extends SwiperCore {
  swiper?: any
}

const DealCard = (props: IProps) => {
  const loading = props.loading
  const ALLOWED_DETAILS_LEGNTH = 120
  const deleting = props?.deletingIndex === props.index

  const swiper = useRef<ExtendedSwiper>(null)

  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const customNavigation = (length: number) => {
    if (length > 1) {
      return (
        <div className={clsx(`flex flex-row  absolute z-10 bottom-[10px]`)}>
          {[...Array(length <= 6 ? length : 6)].map((data, currDotIndex) => {
            return (
              <span
                key={`${JSON.stringify(data)}${currDotIndex}`}
                className={` rounded ${currDotIndex <= currentIndex ? `bg-[white]` : `bg-[white]`} p-[4px] mx-[2px] ${currDotIndex > currentIndex ? `opacity-[0.4]` : ''}`}
              >
                {data}
              </span>
            )
          })}
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div
      className={clsx(
        'flex gap-4 border border-solid border-SeabiscuitLightThemeColorD3 rounded-xl p-4 mt-3 md:flex-row flex-col min-h-[180px] lg:min-h-[190px] text-SeabiscuitDark200ThemeColor relative',
        loading && 'animate-pulse'
      )}
    >
      <div className="flex gap-4 flex-1 md:flex-row flex-col w-[calc(100%-64px)]">
        <div
          className={clsx(
            'w-[160px] h-[160px] rounded-xl border border-solid overflow-hidden bg-SeabiscuitLightThemeColorD3 bg-opacity-[0.5]',
            loading ? '!bg-SeabiscuitSkeletonColor' : 'border-SeabiscuitLightThemeColorD3'
          )}
        >
          <Swiper
            ref={swiper as any}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            className="mb-4 h-[160px] w-[160px] group"
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onActiveIndexChange={(activeIndex_) => {
              setCurrentIndex(activeIndex_.activeIndex)
            }}
          >
            {props?.pictures?.length && !loading ? (
              props?.pictures.map((currImage, index) => {
                return (
                  <SwiperSlide key={`${currImage}${index}`} className="!rounded-sm overflow-hidden">
                    {currentIndex === index && customNavigation(Number(props?.pictures?.length))}
                    {customImageComponent(
                      currImage ?? IMAGE_CONSTS.PLACEHOLDERS.NEWS,
                      '',
                      'object-cover cursor-pointer swiper-lazy w-full h-full rounded-xl'
                    )}
                    <div className="swiper-lazy-preloader"></div>
                  </SwiperSlide>
                )
              })
            ) : (
              <SwiperSlide className="!rounded-sm overflow-hidden">
                {loading
                  ? null
                  : customImageComponent(
                      IMAGE_CONSTS.PLACEHOLDERS.NEWS,
                      '',
                      'object-cover cursor-pointer swiper-lazy w-full h-full rounded-xl'
                    )}
                <div className="swiper-lazy-preloader"></div>
              </SwiperSlide>
            )}
          </Swiper>
        </div>

        <div className="flex-1 flex-col flex max-w-[calc(100%-180px)] justify-between">
          <div className="flex flex-col gap-[2px]">
            <div
              className={clsx(
                'text-sm min-h-[16px] rounded-md ellipsis text-SeabiscuitMainThemeColor',
                loading && '!bg-SeabiscuitSkeletonColor'
              )}
            >
              {loading
                ? null
                : capitalize(props.category, {
                    fullyCapitalize: true,
                  })}
            </div>

            <h2
              role="button"
              onClick={() => props.onMoreButtonClick(props.index)}
              className={clsx(
                'text-base lg:text-[20px] min-h-[24px] font-[600] rounded-md ellipsis',
                loading && '!bg-SeabiscuitSkeletonColor'
              )}
            >
              {loading ? null : props.title}
            </h2>
          </div>

          <p
            className={clsx(
              'opacity-70 min-h-[50px] rounded-md text-nr lg:text-base',
              loading && 'bg-SeabiscuitSkeletonColor'
            )}
          >
            {loading ? null : props.description?.substring(0, ALLOWED_DETAILS_LEGNTH)}
            {!loading && (props?.description?.length ?? 0) > ALLOWED_DETAILS_LEGNTH && (
              <button disabled={deleting} onClick={() => props.onMoreButtonClick(props.index)}>
                <span>...</span>
                {` `}
                <span className="underline">More</span>
              </button>
            )}
          </p>

          <div className="flex justify-between">
            <div className="flex items-center gap-3">
              <div
                className={clsx(
                  'text-base opacity-70 min-w-[30px] rounded-md leading-[1]',
                  typeof props.discountPrice === 'number' && 'line-through',
                  loading && 'bg-SeabiscuitSkeletonColor min-h-[24px]'
                )}
              >
                {loading ? null : `$${props.price}`}
              </div>

              {typeof props.discountPrice === 'number' ? (
                <div
                  className={clsx(
                    'text-SeabiscuitGreenThemeColor text-xl min-w-[70px] rounded-md leading-[1]',
                    loading && 'bg-SeabiscuitSkeletonColor min-h-[30px]'
                  )}
                >
                  {loading ? null : `$${props.discountPrice}`}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between">
        <button
          disabled={deleting}
          onClick={() => props.onMoreButtonClick(props.index)}
          className={clsx(
            'h-6 w-6 lg:h-8 lg:w-8 flex items-center justify-center rounded-md',
            loading ? 'bg-SeabiscuitSkeletonColor' : 'bg-SeabiscuitMainThemeColor bg-opacity-[0.1]'
          )}
        >
          {loading ? null : (
            <img
              src="/assets/og_icons/Full Screen-2.svg"
              className="lg:w-6 lg:h-6 w-5 h-5"
              alt="icon"
            />
          )}
        </button>

        <div className="flex absolute bottom-4 right-4 gap-2">
          {props.url || loading ? (
            <a
              target="_blank"
              title="Visit"
              href={props.url!}
              className={clsx(
                'h-6 w-6 lg:h-8 lg:w-8 flex items-center justify-center rounded-md',
                loading
                  ? 'bg-SeabiscuitSkeletonColor'
                  : 'bg-SeabiscuitMainThemeColor bg-opacity-[0.1]'
              )}
              rel="noreferrer"
            >
              {loading ? null : (
                <CallMadeIcon className="text-SeabiscuitMainThemeColor lg:!w-6 lg:!h-6 !w-5 !h-5" />
              )}
            </a>
          ) : null}

          {(!!props.loggedInUserId && props.creatorId === props.loggedInUserId) || loading ? (
            <>
              <button
                title="Edit"
                disabled={deleting}
                onClick={() => props.onEditDealButtonClick(props.index)}
                className={clsx(
                  'h-6 w-6 lg:h-8 lg:w-8 flex items-center justify-center rounded-md',
                  loading
                    ? 'bg-SeabiscuitSkeletonColor'
                    : 'bg-SeabiscuitMainThemeColor bg-opacity-[0.1]'
                )}
              >
                {loading ? null : (
                  <img
                    src="/assets/cp_icons/Create 2.svg"
                    className="lg:w-6 lg:h-6 w-5 h-5"
                    alt="icon"
                  />
                )}
              </button>

              <button
                title="Delete"
                disabled={deleting}
                onClick={() => props.onDeleteButtonClick(props.index)}
                className={clsx(
                  'h-6 w-6 lg:h-8 lg:w-8 flex items-center justify-center rounded-md',
                  loading
                    ? 'bg-SeabiscuitSkeletonColor'
                    : 'bg-SeabiscuitMainThemeColor bg-opacity-[0.1]'
                )}
              >
                {loading ? null : deleting ? (
                  <AutorenewRounded
                    fontSize="small"
                    className={clsx(
                      deleting && 'pointer-events-none',
                      'animate-spin text-SeabiscuitMainThemeColor lg:!w-6 lg:!h-6 !w-5 !h-5'
                    )}
                  />
                ) : (
                  <DeleteOutlineIcon className="text-SeabiscuitMainThemeColor lg:!w-6 lg:!h-6 !w-5 !h-5" />
                )}
              </button>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default DealCard
