import { createAsyncThunk } from '@reduxjs/toolkit'
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { HorseLineageModel } from '../../../models/horse-lineage/horse-lineage-model'
import { IHorseLineage } from '../../../models/horse-lineage/horse-lineage.interface'
import { getConvertedData } from '../../../models/interface.helper'
import { IUserHorseMappingInterface } from '../../../models/user-horse-mapping/userHorseMapping.interface'
import { UserHorseMappingModel } from '../../../models/user-horse-mapping/userHorseMapping.model'
import FirestoreService from '../../../services/firestoreService'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type IGetHorseLineageThunk = {
  req: {
    userHorseMappingDocId: string
  }
}

const getHorseLineageThunk = createAsyncThunk(
  'getHorseLineageThunk',
  async (payload: IGetHorseLineageThunk['req'], thunkApi) => {
    let message: string | null = null
    let horseLineage: IHorseLineage | null = null
    let mapping: IUserHorseMappingInterface | null = null

    try {
      const horseMappingSnapshot = await FirestoreService.getItem(
        COLLECTIONS.USER_HORSE_MAPPING.NAME,
        payload.userHorseMappingDocId
      )

      if (!horseMappingSnapshot.exists()) {
        throw new Error('Horse not found')
      }

      mapping = getConvertedData(
        UserHorseMappingModel.fromFirestoreDoc(horseMappingSnapshot).toObject()
      )

      const horseSnapshot = await FirestoreService.getItem(
        COLLECTIONS.HORSES.NAME,
        mapping.horseId!!
      )

      if (!horseSnapshot.exists()) {
        throw new Error('Horse not found')
      }

      const horseLineageSnapshot = await FirestoreService.getItem(
        COLLECTIONS.HORSE_LINEAGE.NAME,
        payload.userHorseMappingDocId
      )

      if (!horseLineageSnapshot.exists()) {
        throw new Error('Horse lineage not found')
      }

      horseLineage = HorseLineageModel.fromFirestoreDoc(horseLineageSnapshot).toObject()

      return {
        lineage: getConvertedData(horseLineage),
      }
    } catch (error: any) {
      message = error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG
      return thunkApi.rejectWithValue({
        message,
      })
    }
  }
)

export { getHorseLineageThunk }
