import { DocumentData, QuerySnapshot, where } from 'firebase/firestore'
import moment from 'moment'
import { useEffect, useState } from 'react'
import helpers from '../../../../../commonHelpers/helpers'
import ViewsLoader from '../../../../../components/loader/ViewsLoader'
import MainModal from '../../../../../components/modals/common/MainModal'
import { CONST } from '../../../../../const/const'
import { IMAGE_CONSTS } from '../../../../../const/image-const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import { CustomError } from '../../../../../helpers/helpers'
import { IAssignedTicket } from '../../../../../models/assigned-tickets/assigned-tickets.interface'
import { AssignTicketsModal } from '../../../../../models/assigned-tickets/assigned-tickets.model'
import { IGuestInterface } from '../../../../../models/guests/guest.interface'
import { GuestModel } from '../../../../../models/guests/guest.model'
import { getConvertedData } from '../../../../../models/interface.helper'
import { RecipientModel } from '../../../../../models/recipients/recipients'
import { IRecipientInterface } from '../../../../../models/recipients/recipients.interface'
import { IRegistrationTicketInterface } from '../../../../../models/registration-tickets/registrationTicket.interface'
import { RegistrationTicketModel } from '../../../../../models/registration-tickets/registrationTicket.model'
import { ITicketBuyer } from '../../../../../models/ticket-buyers/ticket-buyers.interface'
import { IUserInterface } from '../../../../../models/users/user.interface'
import { UserModel } from '../../../../../models/users/user.model'
import FirestoreService from '../../../../../services/firestoreService'
import { useAppDispatch } from '../../../../../store/hooks'
import { setSelectedTicket } from '../../../../../store/tickets/ticketslice'
import ViewTicket from '../../../../myEvent/component/ViewTicket'

type IProps = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: ITicketBuyer
}

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const FILE_NAME = 'TicketBuyerDetailsModal'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

type IUser = {
  user: IUserInterface | null
  guest: IGuestInterface | null
  isPlatformUser: boolean
}

const TicketBuyerDetailsModal = (props: IProps) => {
  const dataToPassOn = props.dataToPassOn
  const dispatch = useAppDispatch()
  const toastFunction = useToasterHelper()
  const [ticketStep, setTicketStep] = useState(1)
  const [loading, setLoading] = useState(true)
  const [userLoading, setUserLoading] = useState(true)
  const [user, setUser] = useState<IUser | null>(null)
  const [assignedTickets, setAssignedTickets] = useState<IAssignedTicket[]>([])
  const [assignedCount, setAssignedCount] = useState(0)
  const [assignedTotal, setAssignedTotal] = useState(0)

  const handelTicketStep = (step: number) => {
    setTicketStep(step)
  }

  const loadUser = async (
    userId: ITicketBuyer['userId'],
    isPlatformUser: ITicketBuyer['isPlatformUser']
  ) => {
    let user: IUser | null = null

    try {
      if (!userId) {
        throw new Error(`User id is ${userId}`)
      }

      if (isPlatformUser) {
        const userSnapshot = await FirestoreService.getItem(COLLECTIONS.USERS.NAME, userId)

        user = {
          isPlatformUser,
          guest: null,
          user: userSnapshot.exists()
            ? getConvertedData(UserModel.fromFirestoreDoc(userSnapshot))
            : null,
        }
      } else {
        const guestSnapshot = await FirestoreService.getItem(COLLECTIONS.GUESTS.NAME, userId)

        user = {
          isPlatformUser,
          guest: guestSnapshot.exists()
            ? getConvertedData(GuestModel.fromFirestoreDoc(guestSnapshot))
            : null,
          user: null,
        }
      }
    } catch (error: any) {
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'loadUser',
          devMessage: error,
        }),
      })
    } finally {
      setUser(user)
      setUserLoading(false)
    }
  }

  const getMergedAssignedTicketsWithNotCreated = async ({
    recipient,
    assignedTickets,
  }: {
    recipient: IRecipientInterface
    assignedTickets: IAssignedTicket[]
  }): Promise<IAssignedTicket[]> => {
    let unCreatedTicketCount = 0
    let createdItemsCount = 0
    let assignedTickets_: IAssignedTicket[] = []
    let registrationTickets: IRegistrationTicketInterface[] = []
    let registrationTicket: IRegistrationTicketInterface | null = null

    const registrationTicketSnapshots = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_TICKET.NAME,
      [
        where(COLLECTIONS.REGISTRATION_TICKET.FIELDS.RECIPIENT_ID.KEY, '==', recipient.recipientId),
        where(
          COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
          '==',
          recipient.registrationDocId
        ),
      ]
    )

    registrationTicketSnapshots.forEach((currRegistrationTicketSnapshot) => {
      registrationTicket = RegistrationTicketModel.fromFirestoreDoc(currRegistrationTicketSnapshot)
      registrationTickets.push(registrationTicket)
      unCreatedTicketCount = registrationTicket.remainingTicketsCount

      createdItemsCount = 0

      while (createdItemsCount < unCreatedTicketCount) {
        assignedTickets_.push({
          ...new AssignTicketsModal({
            ...registrationTicket,
            amountRefunded: 0,
            amountScratched: 0,
            scratchedOn: null,
            chargeId: null,
            isPlatformUser: true,
            ticketBuyerDocId: registrationTicket.userId,
            ticketTitle: registrationTicket.ticketTitle,
            userId: recipient?.recipientId ?? null,
            ticketBuyerName: recipient?.recipientName ?? null,
            ticketBuyerEmailId: null,
            paymentStatus: 'pending',
            ticketType: 'spectator',
            ticketHolderEmailId: null,
            registrationTicketDocId: null,
            updated: null,
            created: null,
            receipetUrl: null,
            ticketStatus: 'available',
            assignmentDate: null,
            isScratched: false,
            eventDocId: recipient?.eventId ?? null,
            checkInDate: null,
            spectatorTicketDocId: null,
            registrationDocId: null,
            ticketHolderName: null,
          }).toObject(),
        })
        createdItemsCount++
      }
    })

    assignedTickets_ = [...assignedTickets, ...assignedTickets_]

    return assignedTickets_
  }

  const getAssignedTickets = async () => {
    let assignedTickets_: IAssignedTicket[] = []
    let recipient: IRecipientInterface | null = null
    let assignedTicketSnapshots: QuerySnapshot<DocumentData>

    try {
      if (dataToPassOn.type === 'spectator') {
        assignedTicketSnapshots = await FirestoreService.filterItems(
          COLLECTIONS.ASSIGNED_TICKETS.NAME,
          [
            where(
              COLLECTIONS.ASSIGNED_TICKETS.FIELDS.SEPECTATOR_TICKET_DOC_ID,
              'in',
              dataToPassOn.spectatorTicketsIds
            ),
            where(COLLECTIONS.ASSIGNED_TICKETS.FIELDS.TICKET_TYPE.KEY, '==', dataToPassOn.type),
          ]
        )
      } else {
        if (!dataToPassOn.recipientDocId) {
          throw new Error(`RecipientDocId is ${dataToPassOn.recipientDocId}`)
        }

        const recipientSnapshot = await FirestoreService.getItem(
          COLLECTIONS.RECIPIENT.NAME,
          dataToPassOn.recipientDocId!
        )

        recipient = RecipientModel.fromFirestoreDoc(recipientSnapshot)

        if (!recipientSnapshot.exists()) {
          throw new Error(`Recipient with docId: ${dataToPassOn.recipientDocId} does not exist`)
        }

        assignedTicketSnapshots = await FirestoreService.filterItems(
          COLLECTIONS.ASSIGNED_TICKETS.NAME,
          [
            where(COLLECTIONS.ASSIGNED_TICKETS.FIELDS.USER_ID, '==', recipient.recipientId),
            where(COLLECTIONS.ASSIGNED_TICKETS.FIELDS.TICKET_TYPE.KEY, '==', dataToPassOn.type),
          ]
        )
      }

      assignedTicketSnapshots.docs.forEach((curr) => {
        assignedTickets_.push(getConvertedData(AssignTicketsModal.fromFirestoreDoc(curr)))
      })

      if (dataToPassOn.type === 'register') {
        assignedTickets_ = await getMergedAssignedTicketsWithNotCreated({
          recipient: recipient!,
          assignedTickets: assignedTickets_,
        })
      }
    } catch (error: any) {
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'getAssignedTickets',
          devMessage: error,
        }),
      })
    } finally {
      setLoading(false)
      setAssignedTickets(assignedTickets_)
    }
  }

  useEffect(() => {
    setLoading(true)
    getAssignedTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setUserLoading(true)
    loadUser(dataToPassOn.userId, dataToPassOn.isPlatformUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToPassOn.userId, dataToPassOn.isPlatformUser])

  const handleticketView = (currentTicket: IAssignedTicket) => {
    dispatch(setSelectedTicket(currentTicket))
    handelTicketStep(2)
  }

  useEffect(() => {
    let count = 0
    assignedTickets.forEach((curr) => {
      if (curr.ticketStatus === 'assigned' || curr.ticketStatus === 'used') {
        count++
      }
    })
    setAssignedTotal(assignedTickets.length)
    setAssignedCount(count)
  }, [assignedTickets])

  return (
    <MainModal
      show={true}
      type="TICKET_BUYER_DETAILS"
      setHeightAsPerContent={true}
      modalClassName="p-6"
      className="min-h-[70vh]"
      customPadding={true}
      size="4xl"
    >
      {userLoading || loading ? (
        <ViewsLoader
          className="flex items-center w-full justify-center min-h-[70vh] mt-3"
          size="lg"
          color="red"
        />
      ) : (
        <>
          <div className="flex items-center my-2">
            <img
              className="rounded-md mr-4 h-14 w-14 object-cover"
              src={dataToPassOn.ticketBuyerProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
              alt="user"
            />
            <p className="text-lg font-bold text-SeabiscuitDark200ThemeColor capitalize">
              {dataToPassOn.ticketBuyerName}
            </p>
          </div>
          <div className="mt-4">
            <div className="flex items-center mt-3">
              <img src="/assets/og_icons/Two Tickets-1.svg" alt="icon" className="" />
              <p className="ml-4 text-SeabiscuitDark200ThemeColor text-nr">
                {dataToPassOn.unusedTicketCount} ticket remaining
              </p>
            </div>
            <div className="flex items-center mt-3">
              <img src="/assets/og_icons/Tear-Off Calendar-1.svg" alt="icon" className="" />
              <p className="ml-4 text-SeabiscuitDark200ThemeColor text-nr">
                {dataToPassOn.purchasedOn
                  ? moment(dataToPassOn.purchasedOn).format('MMM DD, YYYY')
                  : 'N/A'}
              </p>
            </div>
            <div className="flex items-center mt-3">
              <img src="/assets/og_icons/Location-1.svg" alt="icon" className="" />
              <p className="ml-4 text-SeabiscuitDark200ThemeColor text-nr">
                {(user?.isPlatformUser ? user?.user?.userAddress : user?.guest?.userAddress) ??
                  'N/A'}
              </p>
            </div>
          </div>

          <hr className="my-5 border border-t-0 border-solid border-[#D3DAEE]" />

          {ticketStep === 1 ? (
            <div className="">
              <div className="flex justify-between mb-5">
                <h5
                  className="text-xl flex items-center font-semibold leading-normal text-SeabiscuitDark200ThemeColor"
                  id="exampleModalScrollableLabel"
                >
                  Tickets
                </h5>
                {/* <div className="text-black">{assignedCount}/{assignedTotal}</div> */}
                <div className=" items-start">
                  <div className="px-2 flex items-center gap-2 py-1 mx-auto rounded-lg">
                    <div className="text-sm text-SeabiscuitDark200ThemeColor opacity-50">
                      Assigned:{' '}
                    </div>
                    <div className="flex justify-center text-[#00B6AA]">
                      {assignedCount} / {assignedTotal}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className={`w-full gap-2 capitalize grid grid-cols-[auto_192px_192px_130px_130px_130px] text-SeabiscuitDark100ThemeColor text-sm font-[600]`}>
                <div className="flex items-center">
                  Ticket item
                  <Tooltip
                    title={<h1 className="tooltip_title">
                      Ticket item
                    </h1>}
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color='#122B46' />
                    </button>
                  </Tooltip>
                </div>
                <div className="flex items-center">
                  Name on ticket
                  <Tooltip
                    title={<h1 className="tooltip_title">
                      Name on ticket
                    </h1>}
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color='#122B46' />
                    </button>
                  </Tooltip>
                </div>
                <div className="flex items-center">
                  Email address
                  <Tooltip
                    title={<h1 className="tooltip_title">
                      Email address
                    </h1>}
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color='#122B46' />
                    </button>
                  </Tooltip>
                </div>
                <div className="flex items-center">
                  View tickets
                  <Tooltip
                    title={<h1 className="tooltip_title">
                      View tickets
                    </h1>}
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color='#122B46' />
                    </button>
                  </Tooltip>
                </div>
                <div className="flex items-center">
                  Send tickets
                  <Tooltip
                    title={<h1 className="tooltip_title">
                      Send tickets
                    </h1>}
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color='#122B46' />
                    </button>
                  </Tooltip>
                </div>
                <div className="flex items-center">
                  Status
                  <Tooltip
                    title={<h1 className="tooltip_title">
                      Status
                    </h1>}
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color='#122B46' />
                    </button>
                  </Tooltip>
                </div>
              </div> */}

              <div className="overflow-auto gap-2 flex flex-col">
                {assignedTickets.length ? (
                  assignedTickets.map((curr, index) => {
                    return (
                      <div
                        key={`${curr.id!}${index}`}
                        className={`w-full flex cursor-pointer`}
                        onClick={() => handleticketView(curr)}
                      >
                        <div
                          className={`flex text-[#122B46] gap-2 w-[50%] text-nr text-[#122B46]-900 border-[#D3DAEE] rounded-r-none border-r-0 rounded-md !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor h-14 p-3.5  items-center ${curr.ticketStatus === 'assigned' || curr.ticketStatus === 'used' ? 'bg-[#F6F7FB]' : 'bg-white border-[#D3DAEE] border border-solid'}`}
                        >
                          <span className="font-medium">
                            {curr?.ticketTitle ? curr?.ticketTitle : 'N/A'}
                          </span>{' '}
                          •{' '}
                          <span
                            className={`${curr.ticketStatus === 'assigned' || curr.ticketStatus === 'used' ? 'text-[#00b6aa]' : 'text-SeabiscuitMainThemeColor'}`}
                          >
                            {' '}
                            {curr.ticketStatus === 'assigned' || curr.ticketStatus === 'used'
                              ? 'Assigned'
                              : 'Unassigned'}
                          </span>
                        </div>

                        <div
                          className="capitalize shrink-0 w-[25%] cursor-pointer"
                          onClick={() => handleticketView(curr)}
                        >
                          <div
                            className={`w-full h-14 text-nr cursor-pointer !text-[#122B46] outline-0 rounded-md rounded-l-none rounded-r-none border-l-0 border-r-0 !ring-0 p-4 ${curr.ticketStatus === 'assigned' || curr.ticketStatus === 'used' ? 'bg-[#F6F7FB]' : 'bg-white border-[#D3DAEE] border border-solid'} text-center ellipsis capitalize`}
                          >
                            {!!curr.ticketHolderName ? curr.ticketHolderName : 'Unassigned'}
                          </div>
                        </div>

                        <div
                          className="shrink-0 w-[25%] cursor-pointer"
                          onClick={() => handleticketView(curr)}
                        >
                          <div
                            className={`h-14 w-full cursor-pointer text-nr !text-[#122B46] outline-0 rounded-md rounded-l-none border-l-0 !ring-0 p-4 ${curr.ticketStatus === 'assigned' || curr.ticketStatus === 'used' ? 'bg-[#F6F7FB]' : 'bg-white border-[#D3DAEE] border border-solid'}  text-center border-[#D3DAEE] focus:border-[#122B46] ellipsis`}
                          >
                            {!!curr.ticketHolderEmailId ? curr.ticketHolderEmailId : 'Unassigned'}
                          </div>
                        </div>

                        {/* <div
                          className={`text-nr w-[10%] h-14 !text-[#122B46] outline-0 rounded-md rounded-l-none border-l-0 !ring-0 p-4 ${(curr.ticketStatus === "assigned" || curr.ticketStatus === "used") ? "bg-[#F6F7FB]" : "bg-white border-[#D3DAEE] border border-solid"}  text-center flex items-center shrink-0 `}
                        >
                          {curr.ticketStatus === "assigned" || "used" ? <img src="/assets/cp_icons/FullScreen-1.svg" alt="full-screen" className="cursor-pointer" /> : <img src="/assets/cp_icons/FullScreen-1.svg" alt="full-screen" onClick={() => toastFunction.info({ message: "This ticket is not yet assigned by now" })} />}
                          {curr.ticketStatus === "assigned" || "used" ? <span className='!text-[#122B46] text-nr ml-3'>
                            View
                          </span> : <span className="pl-2">N/A</span>}
                        </div> */}

                        {/* <div
                          className={`text-nr !text-[#122B46] outline-0 rounded-md !ring-0 p-4 ${curr.ticketStatus === "assigned" ? "bg-[#F6F7FB]" : "bg-white border-[#D3DAEE] border border-solid"}  text-center flex items-center shrink-0`}
                        >
                          <img src="/assets/cp_icons/Mail-1.svg" alt="mail" />
                          {curr.ticketStatus === "assigned" ?
                            <span className='!text-[#122B46] text-nr ml-3'>
                              Send
                            </span> :
                            <span className="pl-2">
                              N/A
                            </span>}
                        </div> */}

                        {/* <div
                          className={`text-nr !text-[#122B46] outline-0 rounded-md !ring-0 p-4 ${curr.ticketStatus === "assigned" ? "bg-[#F6F7FB]" : "bg-white border-[#D3DAEE] border border-solid"} justify-center flex items-center shrink-0`}
                        >
                          {curr.ticketStatus}
                        </div> */}
                      </div>
                    )
                  })
                ) : (
                  <div className="text-[14px] text-SeabiscuitLightParagraphTextColor mt-2">
                    Tickets not found
                  </div>
                )}
              </div>
            </div>
          ) : (
            <ViewTicket
              dataToPassOn={{ eventId: dataToPassOn.eventId }}
              handelTicketStep={handelTicketStep}
            />
          )}
        </>
      )}
    </MainModal>
  )
}

export default TicketBuyerDetailsModal
