import { interfaceHelper, selectObjToString } from '../models/interface.helper'
import { IUserInterface } from '../models/users/user.interface'

export class userProfileDtos {
  id
  userName
  userSummary
  userPhoneCode
  userPhoneNumber
  userFirstName
  userLastName
  userProfilePicture
  userDOB
  userEmail
  userAddress
  userLat
  userLong
  userType
  userState
  userZone
  userDiscipline
  userNationality
  userTeamMembers
  userAccountPrivacy
  userWebsite
  userTwitter
  userFacebook
  userInstagram
  userLinkedin

  constructor(user: IUserInterface) {
    this.id = user.id
    this.userFirstName = user?.userFirstName ?? ''
    this.userLastName = user?.userLastName ?? ''
    this.userName = user?.userName ?? ''
    this.userProfilePicture = user?.userProfilePicture ?? ''
    this.userDOB = interfaceHelper.getFirestoreDate(user?.userDOB as Date, false)
    this.userEmail = user?.userEmail ?? ''
    this.userPhoneCode = user?.userPhoneCode ?? ''
    this.userPhoneNumber = user?.userPhoneNumber ?? ''
    this.userSummary = user?.userSummary ?? ''
    this.userAddress = user?.userAddress ?? ''
    this.userLat = user?.userLat ?? ''
    this.userLong = user?.userLong ?? ''
    this.userType = user?.userType ?? ''
    this.userState = user?.userState ?? ''
    this.userZone = selectObjToString(user?.userZone) ?? ''
    this.userDiscipline = selectObjToString(user?.userDiscipline) ?? ''
    this.userNationality = user?.userNationality ?? ''
    this.userTeamMembers = interfaceHelper.getStructuredTeamMembersList(user?.userTeamMembers ?? [])
    this.userAccountPrivacy = user?.userAccountPrivacy ?? false
    this.userTwitter = user?.userTwitter ?? ''
    this.userWebsite = user?.userWebsite ?? ''
    this.userFacebook = user?.userFacebook ?? ''
    this.userInstagram = user?.userInstagram ?? ''
    this.userLinkedin = user?.userLinkedin ?? ''
  }
}
