// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import ReactApexChart from 'react-apexcharts'

import { MailOpenIcon } from '@heroicons/react/outline'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface ShowsFinancesGraphComponentProps {
  title?: string
  data?: number[]
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventOrganizationFinancesGraphComponent: React.FC<ShowsFinancesGraphComponentProps> = (
  props
) => {
  const graph_data: any = {
    options: {
      chart: {
        type: 'area',
        height: '100%',
        background: 'transparent',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
      },
    },
    series: [
      {
        name: 'Chart',
        data: props.data ? props.data : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
      <dt>
        <div className="absolute bg-indigo-500 rounded-md p-3">
          <MailOpenIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
      </dt>
      <dd className="ml-16 pb-6 pt-3 flex items-baseline sm:pb-7">
        <p className="font-semibold text-gray-900">{props.title}</p>
      </dd>
      <div className="container relative h-200 sm:h-256 pb-16">
        <ReactApexChart
          options={graph_data.options}
          series={graph_data.series}
          type="area"
          height="100%"
        />
      </div>
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
EventOrganizationFinancesGraphComponent.defaultProps = {
  title: 'Some title',
  data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
}

export default EventOrganizationFinancesGraphComponent
