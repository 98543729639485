const amateurList = [
  {
    label: 'JR/YR',
    value: 'Junior',
  },
  {
    label: 'Adult Amateur',
    value: 'Amateur',
  },
  {
    label: 'Open',
    value: 'Professional',
  },
  {
    label: 'Undeclared',
    value: 'Undeclared',
  },
]

export { amateurList }
