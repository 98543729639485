// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { CONST } from '../../../../../../../const/const'
import { EventPrizeListFormBuilderUtilEvaluationHunterStandardSectionMainCheckboxHelper } from './helper/hunter/standard/checkbox/EventPrizeListFormBuilderUtilEvaluationHunterStandardSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionConformationOptionHelper } from './helper/hunter/standard/checkbox/EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionConformationOptionHelper'
import { EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionHandyOptionHelper } from './helper/hunter/standard/checkbox/EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionHandyOptionHelper'
import { EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionMainCheckboxHelper } from './helper/hunter/standard/checkbox/EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionUnderSaddleOptionHelper } from './helper/hunter/standard/checkbox/EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionUnderSaddleOptionHelper'
import { EventPrizeListFormBuilderUtilEvaluationHunterStandardSectionHeightChoiceHelper } from './helper/hunter/standard/choice/EventPrizeListFormBuilderUtilEvaluationHunterStandardSectionHeightChoiceHelper'
import { EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionMainCheckboxHelper } from './helper/hunter/classic/checkbox/EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilEvaluationHunterLeagueSectionMainCheckboxHelper } from './helper/hunter/league/checkbox/EventPrizeListFormBuilderUtilEvaluationHunterLeagueSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilEvaluationHunterDerbySectionMainCheckboxHelper } from './helper/hunter/derby/checkbox/EventPrizeListFormBuilderUtilEvaluationHunterDerbySectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilEvaluationEquitationSectionMainCheckboxHelper } from './helper/equitation/checkbox/EventPrizeListFormBuilderUtilEvaluationEquitationSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilEvaluationEquitationSubSectionMainCheckboxHelper } from './helper/equitation/checkbox/EventPrizeListFormBuilderUtilEvaluationEquitationSubSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilEvaluationEquitationSubSectionFlatOptionHelper } from './helper/equitation/checkbox/EventPrizeListFormBuilderUtilEvaluationEquitationSubSectionFlatOptionHelper'
import { EventPrizeListFormBuilderUtilEvaluationJumperSectionMainCheckboxHelper } from './helper/jumper/checkbox/EventPrizeListFormBuilderUtilEvaluationJumperSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilEvaluationJumperSubSectionMainCheckboxHelper } from './helper/jumper/checkbox/EventPrizeListFormBuilderUtilEvaluationJumperSubSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilEvaluationEquitationSubSectionOverFenceOptionHelper } from './helper/equitation/checkbox/EventPrizeListFormBuilderUtilEvaluationEquitationSubSectionOverFenceOptionHelper'
import { EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionQualifyingChoiceHelper } from './helper/hunter/classic/choice/EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionQualifyingChoiceHelper'
import { EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionHeightChoiceHelper } from './helper/hunter/classic/choice/EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionHeightChoiceHelper'
import { EventPrizeListFormBuilderUtilEvaluationHunterDerbySectionQualifyingChoiceHelper } from './helper/hunter/derby/choice/EventPrizeListFormBuilderUtilEvaluationHunterDerbySectionQualifyingChoiceHelper'
import { EventPrizeListFormBuilderUtilEvaluationHunterLeagueSectionQualifyingChoiceHelper } from './helper/hunter/league/choice/EventPrizeListFormBuilderUtilEvaluationHunterLeagueSectionQualifyingChoiceHelper'
import { EventPrizeListFormBuilderUtilEvaluationJumperSectionTablesChoiceHelper } from './helper/jumper/choice/EventPrizeListFormBuilderUtilEvaluationJumperSectionTablesChoiceHelper'
import { EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionSizeChoiceHelper } from './helper/hunter/classic/choice/EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionSizeChoiceHelper'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export function preEvaluateCheckboxFieldChange(
  field: any,
  parentField: any,
  rootField: any,
  previousValue: any = null,
  newValue: any = null,
  setValue: any = null,
  getValues: any = null,
  setError: any = null,
  clearErrors: any = null,
  trigger: any = null,
  disciplineIndex: any = null,
  divisionIndex: any = null,
  sectionIndex: any = null,
  subSectionIndex: any = null
) {
  const state = {
    field,
    parentField,
    rootField,
    previousValue,
    newValue,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    disciplineIndex,
    divisionIndex,
    sectionIndex,
    subSectionIndex,
  }

  let is_section_main_selected = null
  let is_subsection_main_selected = null

  switch (field.type) {
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_STANDARD_MAIN_CHECKBOX:
      // ____________________________________________________________
      const hunterStandardMainSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterStandardSectionMainCheckboxHelper(state)

      if (newValue !== true) {
        hunterStandardMainSectionHelper.deactivateMainSection()
      } else {
        hunterStandardMainSectionHelper.activateMainSection()
      }
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX:
      // ____________________________________________________________
      const hunterStandardMainSubSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionMainCheckboxHelper(state)

      is_section_main_selected = hunterStandardMainSubSectionHelper.isMainSectionSelected()

      if (!is_section_main_selected) {
        return false
      }
      hunterStandardMainSubSectionHelper.unselectedSubSectionMembers()

      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION:
      // ____________________________________________________________
      const hunterStandardSubSectionUnderSaddleOptionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionUnderSaddleOptionHelper(
          state
        )

      is_subsection_main_selected =
        hunterStandardSubSectionUnderSaddleOptionHelper.isMainSubSectionSelected()
      if (!is_subsection_main_selected) {
        return false
      }
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_HUNTER_STANDARD_HANDY_OPTION:
      // ____________________________________________________________
      const hunterStandardSubSectionHandyOptionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionHandyOptionHelper(state)

      is_subsection_main_selected =
        hunterStandardSubSectionHandyOptionHelper.isMainSubSectionSelected()
      if (!is_subsection_main_selected) {
        return false
      }
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION:
      // ____________________________________________________________
      const hunterStandardSubSectionConformationOptionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionConformationOptionHelper(
          state
        )

      is_subsection_main_selected =
        hunterStandardSubSectionConformationOptionHelper.isMainSubSectionSelected()
      if (!is_subsection_main_selected) {
        return false
      }
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_CLASSIC_MAIN_CHECKBOX:
      // ____________________________________________________________
      const hunterClassicMainSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionMainCheckboxHelper(state)

      if (newValue !== true) {
        hunterClassicMainSectionHelper.deactivateMainSection()
      } else {
        hunterClassicMainSectionHelper.activateMainSection()
      }
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_DERBY_MAIN_CHECKBOX:
      // ____________________________________________________________
      const hunterDerbyMainSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterDerbySectionMainCheckboxHelper(state)
      if (newValue !== true) {
        hunterDerbyMainSectionHelper.deactivateMainSection()
      } else {
        hunterDerbyMainSectionHelper.activateMainSection()
      }
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_LEAGUE_MAIN_CHECKBOX:
      // ____________________________________________________________
      const hunterLeagueMainSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterLeagueSectionMainCheckboxHelper(state)
      if (newValue !== true) {
        hunterLeagueMainSectionHelper.deactivateMainSection()
      } else {
        hunterLeagueMainSectionHelper.activateMainSection()
      }
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_EQUITATION_MAIN_CHECKBOX:
      // ____________________________________________________________
      const equitationMainSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationEquitationSectionMainCheckboxHelper(state)

      if (newValue !== true) {
        equitationMainSectionHelper.deactivateMainSection()
      } else {
        equitationMainSectionHelper.activateMainSection()
      }
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_EQUITATION_MAIN_CHECKBOX:
      // ____________________________________________________________
      const equitationMainSubSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationEquitationSubSectionMainCheckboxHelper(state)

      is_section_main_selected = equitationMainSubSectionHelper.isMainSectionSelected()

      if (!is_section_main_selected) {
        return false
      }
      equitationMainSubSectionHelper.unselectedSubSectionMembers()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_EQUITATION_FLAT_CLASS_OPTION:
      // ____________________________________________________________
      const equitationMainSubSectionFlatOptionHelper =
        new EventPrizeListFormBuilderUtilEvaluationEquitationSubSectionFlatOptionHelper(state)

      is_subsection_main_selected =
        equitationMainSubSectionFlatOptionHelper.isMainSubSectionSelected()
      if (!is_subsection_main_selected) {
        return false
      }
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_EQUITATION_OVER_FENCE_OPTION:
      // ____________________________________________________________
      const equitationMainSubSectionOverFenceOptionHelper =
        new EventPrizeListFormBuilderUtilEvaluationEquitationSubSectionOverFenceOptionHelper(state)

      is_subsection_main_selected =
        equitationMainSubSectionOverFenceOptionHelper.isMainSubSectionSelected()
      if (!is_subsection_main_selected) {
        return false
      }
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX:
      // ____________________________________________________________
      const jumperMainSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationJumperSectionMainCheckboxHelper(state)

      if (newValue !== true) {
        jumperMainSectionHelper.deactivateMainSection()
      } else {
        jumperMainSectionHelper.activateMainSection()
      }
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_JUMPER_MAIN_CHECKBOX:
      // ____________________________________________________________
      const jumperMainSubSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationJumperSubSectionMainCheckboxHelper(state)

      is_section_main_selected = jumperMainSubSectionHelper.isMainSectionSelected()

      if (!is_section_main_selected) {
        return false
      }
      jumperMainSubSectionHelper.unselectedSubSectionMembers()
      break

    default:
      break
  }

  return true
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export function postEvaluateCheckboxFieldChange(
  field: any,
  parentField: any,
  rootField: any,
  previousValue: any = null,
  newValue: any = null,
  setValue: any = null,
  getValues: any = null,
  setError: any = null,
  clearErrors: any = null,
  trigger: any = null,
  disciplineIndex: any = null,
  divisionIndex: any = null,
  sectionIndex: any = null,
  subSectionIndex: any = null
) {
  const state = {
    field,
    parentField,
    rootField,
    previousValue,
    newValue,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    disciplineIndex,
    divisionIndex,
    sectionIndex,
    subSectionIndex,
  }

  switch (field.type) {
    // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_STANDARD_MAIN_CHECKBOX:
    // // ____________________________________________________________
    //     const hunterMainSectionHelper = new EventPrizeListFormBuilderUtilEvaluationHunterStandardSectionMainCheckboxHelper(state);
    //     break;
    // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX:
    // // ____________________________________________________________
    //     const hunterMainSubSectionHelper = new EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionMainCheckboxHelper(state);
    //     break;
    // // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION:
      // ____________________________________________________________
      const hunterSubSectionUnderSaddleOptionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionUnderSaddleOptionHelper(
          state
        )
      hunterSubSectionUnderSaddleOptionHelper.updateParentFieldData()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_HUNTER_STANDARD_HANDY_OPTION:
      // ____________________________________________________________
      const hunterSubSectionHandyOptionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionHandyOptionHelper(state)
      hunterSubSectionHandyOptionHelper.updateParentFieldData()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION:
      // ____________________________________________________________
      const hunterSubSectionConformationOptionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterStandardSubSectionConformationOptionHelper(
          state
        )
      hunterSubSectionConformationOptionHelper.updateParentFieldData()
      break
    // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_CLASSIC_MAIN_CHECKBOX:
    //     const hunterClassicMainSectionHelper = new EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionMainCheckboxHelper(state);
    // // ____________________________________________________________
    //     break;
    // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_DERBY_MAIN_CHECKBOX:
    // // ____________________________________________________________
    //     const hunterDerbyMainSectionHelper = new EventPrizeListFormBuilderUtilEvaluationHunterDerbySectionMainCheckboxHelper(state);
    //     break;
    // // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_LEAGUE_MAIN_CHECKBOX:
    // // ____________________________________________________________
    //     const hunterLeagueMainSectionHelper = new EventPrizeListFormBuilderUtilEvaluationHunterLeagueSectionMainCheckboxHelper(state);
    //     break;
    // // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_EQUITATION_MAIN_CHECKBOX:
    // // ____________________________________________________________
    //     const equitationMainSectionHelper = new EventPrizeListFormBuilderUtilEvaluationEquitationSectionMainCheckboxHelper(state);
    //     break;
    // // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_EQUITATION_MAIN_CHECKBOX:
    // // ____________________________________________________________
    //     const equitationMainSubSectionHelper = new EventPrizeListFormBuilderUtilEvaluationEquitationSubSectionMainCheckboxHelper(state);
    //     break;
    // // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_EQUITATION_FLAT_CLASS_OPTION:
      // ____________________________________________________________
      const equitationMainSubSectionFlatOptionHelper =
        new EventPrizeListFormBuilderUtilEvaluationEquitationSubSectionFlatOptionHelper(state)
      equitationMainSubSectionFlatOptionHelper.updateParentFieldData()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_EQUITATION_OVER_FENCE_OPTION:
      // ____________________________________________________________
      const equitationMainSubSectionOverFenceOptionHelper =
        new EventPrizeListFormBuilderUtilEvaluationEquitationSubSectionOverFenceOptionHelper(state)
      equitationMainSubSectionOverFenceOptionHelper.updateParentFieldData()
      break
    // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX:
    // // ____________________________________________________________
    //     const jumperMainSectionHelper = new EventPrizeListFormBuilderUtilEvaluationJumperSectionMainCheckboxHelper(state);
    //     break;
    // // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_JUMPER_MAIN_CHECKBOX:
    // // ____________________________________________________________
    //     const jumperMainSubSectionHelper = new EventPrizeListFormBuilderUtilEvaluationJumperSubSectionMainCheckboxHelper(state);
    //     break;

    default:
      break
  }
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export function preEvaluateChoiceFieldChange(
  field: any,
  parentField: any,
  rootField: any,
  previousValue: any = null,
  newValue: any = null,
  setValue: any = null,
  getValues: any = null,
  setError: any = null,
  clearErrors: any = null,
  trigger: any = null,
  disciplineIndex: any = null,
  divisionIndex: any = null,
  sectionIndex: any = null,
  subSectionIndex: any = null
) {
  // const state  = { field, parentField, rootField, previousValue, newValue, setValue, getValues, setError,
  //                  clearErrors, trigger, disciplineIndex, divisionIndex, sectionIndex, subSectionIndex }

  switch (field.type) {
    // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE:
    // // ____________________________________________________________
    //     const hunterStandardHeightSectionHelper = new EventPrizeListFormBuilderUtilEvaluationHunterStandardSectionHeightChoiceHelper(state);
    //     break;
    // // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_CLASSIC_SIZE_SINGLE_CHOICE:
    // // ____________________________________________________________
    //     const hunterStandardSizeSectionHelper = new EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionSizeChoiceHelper(state);
    //     break;
    // // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_CLASSIC_QUALIFYING_MULTIPLE_CHOICE:
    // // ____________________________________________________________
    //     const hunterClassicQualifyingSectionHelper =  new EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionQualifyingChoiceHelper(state);
    //     break;
    // // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_CLASSIC_HEIGHT_MULTIPLE_CHOICE:
    // // ____________________________________________________________
    //     const hunterClassicHeightSectionHelper = new EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionHeightChoiceHelper(state);
    //     break;
    // // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_DERBY_QUALIFYING_MULTIPLE_CHOICE:
    // // ____________________________________________________________
    //     const hunterDerbyQualifyingSectionHelper = new EventPrizeListFormBuilderUtilEvaluationHunterDerbySectionQualifyingChoiceHelper(state);
    //     break;
    // // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_LEAGUE_QUALIFYING_MULTIPLE_CHOICE:
    // // ____________________________________________________________
    //     const hunterLeagueQualifyingSectionHelper = new EventPrizeListFormBuilderUtilEvaluationHunterLeagueSectionQualifyingChoiceHelper(state);
    //     break;
    // // ____________________________________________________________
    // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_JUMPER_TABLES_SINGLE_CHOICE:
    // // ____________________________________________________________
    //     const jumperTablesSubSectionHelper = new EventPrizeListFormBuilderUtilEvaluationJumperSectionTablesChoiceHelper(state);
    //     break;

    default:
      break
  }

  return true
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export function postEvaluateChoiceFieldChange(
  field: any,
  parentField: any,
  rootField: any,
  previousValue: any = null,
  newValue: any = null,
  setValue: any = null,
  getValues: any = null,
  setError: any = null,
  clearErrors: any = null,
  trigger: any = null,
  disciplineIndex: any = null,
  divisionIndex: any = null,
  sectionIndex: any = null,
  subSectionIndex: any = null
) {
  const state = {
    field,
    parentField,
    rootField,
    previousValue,
    newValue,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    disciplineIndex,
    divisionIndex,
    sectionIndex,
    subSectionIndex,
  }

  switch (field.type) {
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE:
      // ____________________________________________________________
      const hunterStandardHeightSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterStandardSectionHeightChoiceHelper(state)
      hunterStandardHeightSectionHelper.postEvaluate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_CLASSIC_SIZE_SINGLE_CHOICE:
      // ____________________________________________________________
      const hunterStandardSizeSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionSizeChoiceHelper(state)
      hunterStandardSizeSectionHelper.postEvaluate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SECTION_HUNTER_CLASSIC_QUALIFYING_MULTIPLE_CHOICE:
      // ____________________________________________________________
      const hunterClassicQualifyingSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionQualifyingChoiceHelper(state)
      hunterClassicQualifyingSectionHelper.postEvaluate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SECTION_HUNTER_CLASSIC_HEIGHT_MULTIPLE_CHOICE:
      // ____________________________________________________________
      const hunterClassicHeightSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterClassicSectionHeightChoiceHelper(state)
      hunterClassicHeightSectionHelper.postEvaluate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SECTION_HUNTER_DERBY_QUALIFYING_MULTIPLE_CHOICE:
      // ____________________________________________________________
      const hunterDerbyQualifyingSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterDerbySectionQualifyingChoiceHelper(state)
      hunterDerbyQualifyingSectionHelper.postEvaluate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SECTION_HUNTER_LEAGUE_QUALIFYING_MULTIPLE_CHOICE:
      // ____________________________________________________________
      const hunterLeagueQualifyingSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationHunterLeagueSectionQualifyingChoiceHelper(state)
      hunterLeagueQualifyingSectionHelper.postEvaluate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_JUMPER_TABLES_SINGLE_CHOICE:
      // ____________________________________________________________
      const jumperTablesSubSectionHelper =
        new EventPrizeListFormBuilderUtilEvaluationJumperSectionTablesChoiceHelper(state)
      jumperTablesSubSectionHelper.postEvaluate()
      break

    default:
      break
  }

  return true
}
