import { ModelBaseModel } from '../model-base/model-base.model'

import { CONST } from '../../const/const'
import { IRiderTeamMemberInterface } from './riderTeamMember.interface'
import { cloneDeep } from 'lodash'

// Constants
const RIDER_TEAM_MEMBER_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RIDER_TEAM_MEMBER

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @TODO Document this
 */
export class RiderTeamMemberModel extends ModelBaseModel<IRiderTeamMemberInterface> {
  public v: IRiderTeamMemberInterface['v']
  public id: IRiderTeamMemberInterface['id']
  public eventId: IRiderTeamMemberInterface['eventId']
  public registrationDocId: IRiderTeamMemberInterface['registrationDocId']
  public registrationByDayDocId: IRiderTeamMemberInterface['registrationByDayDocId']
  public userId: IRiderTeamMemberInterface['userId']
  public signedStatus: IRiderTeamMemberInterface['signedStatus']
  public horseId: IRiderTeamMemberInterface['horseId']
  public userHorseMappingDocId: IRiderTeamMemberInterface['userHorseMappingDocId']
  public teamMemberId: IRiderTeamMemberInterface['teamMemberId']
  public teamMemberName: IRiderTeamMemberInterface['teamMemberName']
  public teamMemberEmail: IRiderTeamMemberInterface['teamMemberEmail']
  public teamMemberCountry: IRiderTeamMemberInterface['teamMemberCountry']
  public teamMemberPhoneNumber: IRiderTeamMemberInterface['teamMemberPhoneNumber']
  public teamMemberPhoneCode: IRiderTeamMemberInterface['teamMemberPhoneCode']
  public teamMemberDob: IRiderTeamMemberInterface['teamMemberDob']
  public teamMemberRole: IRiderTeamMemberInterface['teamMemberRole']
  public eventName: IRiderTeamMemberInterface['eventName']
  public horseName: IRiderTeamMemberInterface['horseName']
  public riderId: IRiderTeamMemberInterface['riderId']
  public mailLog: IRiderTeamMemberInterface['mailLog']
  public riderNameNGram: IRiderTeamMemberInterface['riderNameNGram']
  public riderDob: IRiderTeamMemberInterface['riderDob']
  public riderName: IRiderTeamMemberInterface['riderName']
  public riderEmail: IRiderTeamMemberInterface['riderEmail']
  public riderCountry: IRiderTeamMemberInterface['riderCountry']
  public riderPhoneNumber: IRiderTeamMemberInterface['riderPhoneNumber']
  public riderPhoneCode: IRiderTeamMemberInterface['riderPhoneCode']
  public riderProfilePicture: IRiderTeamMemberInterface['riderProfilePicture']
  public teamMemberNameNGram: IRiderTeamMemberInterface['teamMemberNameNGram']
  public horseProfilePicture: IRiderTeamMemberInterface['horseProfilePicture']
  public teamMemberProfilePicture: IRiderTeamMemberInterface['teamMemberProfilePicture']
  public registrationByDayUuid: IRiderTeamMemberInterface['registrationByDayUuid']
  public created: IRiderTeamMemberInterface['created']
  public modified: IRiderTeamMemberInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param obj `IRiderTeamMemberInterface`
   */
  public constructor(obj?: IRiderTeamMemberInterface) {
    super()

    this.v = obj?.v ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.V.VALUES.DEFAULT
    this.id = obj?.id ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.eventId = obj?.eventId ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.EVENT_ID.VALUES.DEFAULT
    this.registrationDocId =
      obj?.registrationDocId ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.REGISTRATION_DOC_ID.VALUES.DEFAULT
    this.registrationByDayDocId =
      obj?.registrationByDayDocId ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.REGISTRATION_BY_DAY_DOC_ID.VALUES.DEFAULT
    this.userId = obj?.userId ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.USER_ID.VALUES.DEFAULT
    this.signedStatus =
      obj?.signedStatus ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.SIGNED_STATUS.VALUES.DEFAULT
    this.horseId = obj?.horseId ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.HORSE_ID.VALUES.DEFAULT
    this.userHorseMappingDocId =
      obj?.userHorseMappingDocId ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.USER_HORSE_MAPPING_DOC_ID.VALUES.DEFAULT
    this.teamMemberId =
      obj?.teamMemberId ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.TEAM_MEMBER_ID.VALUES.DEFAULT
    this.teamMemberName =
      obj?.teamMemberName ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.TEAM_MEMBER_NAME.VALUES.DEFAULT
    this.teamMemberEmail =
      obj?.teamMemberEmail ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.TEAM_MEMBER_EMAIL.VALUES.DEFAULT
    this.teamMemberCountry =
      obj?.teamMemberCountry ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.TEAM_MEMBER_COUNTRY.VALUES.DEFAULT
    this.teamMemberPhoneNumber =
      obj?.teamMemberPhoneNumber ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.TEAM_MEMBER_PHONE_NUMBER.VALUES.DEFAULT
    this.teamMemberPhoneCode =
      obj?.teamMemberPhoneCode ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.TEAM_MEMBER_PHONE_CODE.VALUES.DEFAULT
    this.teamMemberDob =
      obj?.teamMemberDob ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.TEAM_MEMBER_DOB.VALUES.DEFAULT
    this.teamMemberRole =
      obj?.teamMemberRole ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.TEAM_MEMBER_ROLE.VALUES.DEFAULT
    this.eventName = obj?.eventName ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.EVENT_NAME.VALUES.DEFAULT
    this.horseName = obj?.horseName ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.HORSE_NAME.VALUES.DEFAULT
    this.riderId = obj?.riderId ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.RIDER_ID.VALUES.DEFAULT
    this.mailLog = obj?.mailLog ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.MAIL_LOG.VALUES.DEFAULT
    this.riderNameNGram =
      obj?.riderNameNGram ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.RIDER_NAME_N_GRAM.VALUES.DEFAULT
    this.riderDob = obj?.riderDob ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.RIDER_DOB.VALUES.DEFAULT
    this.riderName = obj?.riderName ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.RIDER_NAME.VALUES.DEFAULT
    this.riderEmail =
      obj?.riderEmail ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.RIDER_EMAIL.VALUES.DEFAULT
    this.riderCountry =
      obj?.riderCountry ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.RIDER_COUNTRY.VALUES.DEFAULT
    this.riderPhoneCode =
      obj?.riderPhoneCode ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.RIDER_PHONE_CODE.VALUES.DEFAULT
    this.riderPhoneNumber =
      obj?.riderPhoneNumber ?? RIDER_TEAM_MEMBER_COLLECTION.FIELDS.RIDER_PHONE_NUMBER.VALUES.DEFAULT
    this.teamMemberCountry =
      obj?.teamMemberCountry ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.TEAM_MEMBER_COUNTRY.VALUES.DEFAULT
    this.teamMemberPhoneNumber =
      obj?.teamMemberPhoneNumber ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.TEAM_MEMBER_PHONE_NUMBER.VALUES.DEFAULT
    this.teamMemberPhoneCode =
      obj?.teamMemberPhoneCode ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.TEAM_MEMBER_PHONE_CODE.VALUES.DEFAULT
    this.riderProfilePicture =
      obj?.riderProfilePicture ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.RIDER_PROFILE_PICTURE.VALUES.DEFAULT
    this.teamMemberNameNGram =
      obj?.teamMemberNameNGram ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.TEAM_MEMBER_NAME_N_GRAM.VALUES.DEFAULT
    this.horseProfilePicture =
      obj?.horseProfilePicture ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.HORSE_PROFILE_PICTURE.VALUES.DEFAULT
    this.teamMemberProfilePicture =
      obj?.teamMemberProfilePicture ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.TEAM_MEMBER_PROFILE_PICTURE.VALUES.DEFAULT
    this.registrationByDayUuid =
      obj?.registrationByDayUuid ??
      RIDER_TEAM_MEMBER_COLLECTION.FIELDS.UUID.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })

    this.updateNamesNGramsKeys()
  }

  // Methods

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Documnent this
   */
  public updateNamesNGramsKeys() {
    if (!!this.riderName) this.riderNameNGram = this._calculateNGrams(this.riderName)

    if (!!this.teamMemberName) this.teamMemberNameNGram = this._calculateNGrams(this.teamMemberName)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Documnent this
   */
  private _calculateNGrams(value: string) {
    let nGrams: string[] = []
    let words = value?.split(' ') ?? []
    let ngram_words = new Set<string>()
    let lowercase_words = words.map((word) => word.toLowerCase())

    lowercase_words.forEach((word) => {
      let word_iterator = cloneDeep(word)

      while (word_iterator.length > 0) {
        ngram_words.add(cloneDeep(word_iterator))
        word_iterator = word_iterator.slice(0, -1)
      }
    })

    ngram_words.forEach((value) => {
      nGrams.push(value)
    })

    return nGrams
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new RiderTeamMemberModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
