// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React from 'react'
import { Controller } from 'react-hook-form'

import clsx from 'clsx'
import GreenCheck from '../../../../../common/assets/valid.svg'
import {
  postEvaluateCheckboxFieldChange,
  preEvaluateCheckboxFieldChange,
} from '../../../../evaluation/EventPrizeListFormBuilderUtilEvaluation'
import { validateCheckboxFieldChange } from '../../../../validation/EventPrizeListFormBuilderUtilValidation'

interface EventPriceListFormBuilderUtilHunterSubSectionRowProps {
  subSection: any
  section: any
  register: any
  disciplineIndex: number
  divisionIndex: number
  sectionIndex: number
  subSectionIndex: number
  errors: any
  control: any
  setValue: any
  getValues: any
  setError: any
  clearErrors: any
  trigger: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormBuilderUtilHunterStandardSubSectionRow: React.FC<
  EventPriceListFormBuilderUtilHunterSubSectionRowProps
> = (props) => {
  return (
    <div className="scroll-group w-full overflow-auto scrollbar-hide mb-3">
      <div className="grid grid-cols-[100px_220px_repeat(6,1fr)] gap-2 min-w-[1200px] w-full -ml-8">
        <div></div>

        <div>
          <Controller
            name={`data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionSubCode.meta.selected`}
            control={props.control}
            render={({ field: { value } }) => (
              <div
                className={clsx(
                  `text-[14px] w-full rounded-md flex items-center p-4 h-12`,
                  value
                    ? ` bg-SeabiscuitGrayThemeColor`
                    : `bg-white border-SeabiscuitGrayThemeColor border-2`
                )}
              >
                {value ? (
                  <>
                    <div
                      onClick={() => {
                        const shouldChange = preEvaluateCheckboxFieldChange(
                          props.subSection.subSectionSubCode,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        if (shouldChange) {
                          props.setValue(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionSubCode.meta.selected`,
                            !value
                          )
                        }
                        validateCheckboxFieldChange(
                          props.subSection.subSectionSubCode,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        postEvaluateCheckboxFieldChange(
                          props.subSection.subSectionSubCode,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                      }}
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                    >
                      {props.getValues(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionSubCode.value`
                      )}
                    </div>
                    <img src={GreenCheck} alt="green check" />
                  </>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        const shouldChange = preEvaluateCheckboxFieldChange(
                          props.subSection.subSectionSubCode,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        if (shouldChange) {
                          props.setValue(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionSubCode.meta.selected`,
                            !value
                          )
                        }
                        validateCheckboxFieldChange(
                          props.subSection.subSectionSubCode,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        postEvaluateCheckboxFieldChange(
                          props.subSection.subSectionSubCode,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                      }}
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                    >
                      {props.getValues(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionSubCode.value`
                      )}
                    </div>
                    <input type="checkbox" checked={value} disabled />
                  </>
                )}
              </div>
            )}
          />
          {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
            ?.sections[props.sectionIndex]?.subSections &&
            props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
              ?.sections[props.sectionIndex]?.subSections[props.subSectionIndex]?.subSectionSubCode
              ?.value && (
              <div className="text-red-500">
                {
                  props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                    ?.sections[props.sectionIndex]?.subSections[props.subSectionIndex]
                    ?.subSectionSubCode?.value.message
                }
              </div>
            )}
        </div>

        <div>
          <Controller
            name={`data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionUnderSaddleClass.meta.selected`}
            control={props.control}
            render={({ field: { value } }) => (
              <div
                className={clsx(
                  `text-[14px] w-full rounded-md flex items-center p-4 h-12`,
                  value
                    ? ` bg-SeabiscuitGrayThemeColor`
                    : `bg-white border-SeabiscuitGrayThemeColor border-2`
                )}
              >
                {value ? (
                  <>
                    <div
                      onClick={() => {
                        const shouldChange = preEvaluateCheckboxFieldChange(
                          props.subSection.subSectionUnderSaddleClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        if (shouldChange) {
                          props.setValue(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionUnderSaddleClass.meta.selected`,
                            !value
                          )
                        }
                        validateCheckboxFieldChange(
                          props.subSection.subSectionUnderSaddleClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        postEvaluateCheckboxFieldChange(
                          props.subSection.subSectionUnderSaddleClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                      }}
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                    >
                      {props.getValues(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionUnderSaddleClass.value`
                      )}
                    </div>
                    <img src={GreenCheck} alt="green check" />
                  </>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        const shouldChange = preEvaluateCheckboxFieldChange(
                          props.subSection.subSectionUnderSaddleClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        if (shouldChange) {
                          props.setValue(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionUnderSaddleClass.meta.selected`,
                            !value
                          )
                        }
                        validateCheckboxFieldChange(
                          props.subSection.subSectionUnderSaddleClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        postEvaluateCheckboxFieldChange(
                          props.subSection.subSectionUnderSaddleClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                      }}
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                    >
                      {props.getValues(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionUnderSaddleClass.value`
                      )}
                    </div>
                    <input type="checkbox" checked={value} disabled />
                  </>
                )}
              </div>
            )}
          />
          {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
            ?.sections[props.sectionIndex]?.subSections &&
            props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
              ?.sections[props.sectionIndex]?.subSections[props.subSectionIndex]
              ?.subSectionUnderSaddleClass?.value && (
              <div className="text-red-500">
                {
                  props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                    ?.sections[props.sectionIndex]?.subSections[props.subSectionIndex]
                    ?.subSectionUnderSaddleClass?.value.message
                }
              </div>
            )}
        </div>

        <div>
          <Controller
            name={`data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionHandyClass.meta.selected`}
            control={props.control}
            render={({ field: { value } }) => (
              <div
                className={clsx(
                  `text-[14px] w-full rounded-md flex items-center p-4 h-12`,
                  value
                    ? ` bg-SeabiscuitGrayThemeColor`
                    : `bg-white border-SeabiscuitGrayThemeColor border-2`
                )}
              >
                {value ? (
                  <>
                    <div
                      onClick={() => {
                        const shouldChange = preEvaluateCheckboxFieldChange(
                          props.subSection.subSectionHandyClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        if (shouldChange) {
                          props.setValue(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionHandyClass.meta.selected`,
                            !value
                          )
                        }
                        validateCheckboxFieldChange(
                          props.subSection.subSectionHandyClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        postEvaluateCheckboxFieldChange(
                          props.subSection.subSectionHandyClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                      }}
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                    >
                      {props.getValues(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionHandyClass.value`
                      )}
                    </div>
                    <img src={GreenCheck} alt="green check" />
                  </>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        const shouldChange = preEvaluateCheckboxFieldChange(
                          props.subSection.subSectionHandyClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        if (shouldChange) {
                          props.setValue(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionHandyClass.meta.selected`,
                            !value
                          )
                        }
                        validateCheckboxFieldChange(
                          props.subSection.subSectionHandyClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        postEvaluateCheckboxFieldChange(
                          props.subSection.subSectionHandyClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                      }}
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                    >
                      {props.getValues(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionHandyClass.value`
                      )}
                    </div>
                    <input type="checkbox" checked={value} disabled />
                  </>
                )}
              </div>
            )}
          />
          {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
            ?.sections[props.sectionIndex]?.subSections &&
            props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
              ?.sections[props.sectionIndex]?.subSections[props.subSectionIndex]
              ?.subSectionHandyClass?.value && (
              <div className="text-red-500">
                {
                  props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                    ?.sections[props.sectionIndex]?.subSections[props.subSectionIndex]
                    ?.subSectionHandyClass?.value.message
                }
              </div>
            )}
        </div>

        <div>
          <Controller
            name={`data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionConformationClass.meta.selected`}
            control={props.control}
            render={({ field: { value } }) => (
              <div
                className={clsx(
                  `text-[14px] w-full rounded-md flex items-center p-4 h-12`,
                  value
                    ? ` bg-SeabiscuitGrayThemeColor`
                    : `bg-white border-SeabiscuitGrayThemeColor border-2`
                )}
              >
                {value ? (
                  <>
                    <div
                      onClick={() => {
                        const shouldChange = preEvaluateCheckboxFieldChange(
                          props.subSection.subSectionConformationClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        if (shouldChange) {
                          props.setValue(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionConformationClass.meta.selected`,
                            !value
                          )
                        }
                        validateCheckboxFieldChange(
                          props.subSection.subSectionConformationClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        postEvaluateCheckboxFieldChange(
                          props.subSection.subSectionConformationClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                      }}
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                    >
                      {props.getValues(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionConformationClass.value`
                      )}
                    </div>
                    <img src={GreenCheck} alt="green check" />
                  </>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        const shouldChange = preEvaluateCheckboxFieldChange(
                          props.subSection.subSectionConformationClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        if (shouldChange) {
                          props.setValue(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionConformationClass.meta.selected`,
                            !value
                          )
                        }
                        validateCheckboxFieldChange(
                          props.subSection.subSectionConformationClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        postEvaluateCheckboxFieldChange(
                          props.subSection.subSectionConformationClass,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                      }}
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                    >
                      {props.getValues(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionConformationClass.value`
                      )}
                    </div>
                    <input type="checkbox" checked={value} disabled />
                  </>
                )}
              </div>
            )}
          />
          {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
            ?.sections[props.sectionIndex]?.subSections &&
            props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
              ?.sections[props.sectionIndex]?.subSections[props.subSectionIndex]
              ?.subSectionConformationClass?.value && (
              <div className="text-red-500">
                {
                  props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                    ?.sections[props.sectionIndex]?.subSections[props.subSectionIndex]
                    ?.subSectionConformationClass?.value.message
                }
              </div>
            )}
        </div>

        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

EventPrizeListFormBuilderUtilHunterStandardSubSectionRow.defaultProps = {
  subSection: null,
  register: null,
}

export default EventPrizeListFormBuilderUtilHunterStandardSubSectionRow
