export const Status = [
  {
    label: 'Membership Active',
    value: 'active',
  },
  {
    label: 'Membership Inactive',
    value: 'inactive',
  },
]
