import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import React, { CSSProperties } from 'react'
import clsx from 'clsx'
import { CSS } from '@dnd-kit/utilities'
import { Controller } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import Select from 'react-select'

import Input from '../../../common/inputs/Input'
import AmountInput from '../../../common/inputs/AmountInput'

import MessageHelperComp from '../../../../helpers/MessageHelper'

import { MODAL_CONSTS } from '../../../../const/modal-const'

import { ModelBaseModel } from '../../../../models/model-base/model-base.model'
import { RegistrationFeesType } from '../../../../models/event-fees/event-fees.interface'

interface IProps {
  field: any
  register: any
  watch: any
  showErrors: boolean
  index: number
  errors: any
  openAddNotesModal: (e: any) => void
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  control: any
  selector: any
  type?: 'children'
  getValues: any
  setValue: any
  dataIncrementAttrProps: any
}
export const EventEntriesFormRow: React.FC<IProps> = ({
  field,
  register,
  index,
  watch,
  showErrors,
  errors,
  openAddNotesModal,
  handleModal,
  control,
  selector,
  type,
  getValues,
  setValue,
  dataIncrementAttrProps,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: field.uuid,
  })

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
    transition: transition,
  }

  const valid = !!(
    !!watch(`${selector}.${index}.name`) &&
    watch(`${selector}.${index}.note`) &&
    new ModelBaseModel().getNum(watch(`${selector}.${index}.actualCostAlias`))
  )

  const qualifyingOptions = watch('qualifyingClasses.items')
    ?.filter((option: { name: string; price: string }) => option?.name && option?.price)
    ?.map((option: { name: string }) => ({
      label: option.name,
      value: option.name,
    }))

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={clsx(
        'mt-4 w-full relative border border-SeabiscuitLightThemeColorD3 rounded-lg flex flex-wrap gap-y-2 items-center justify-between p-3 md:pl-10',
        valid ? 'bg-SeabiscuitGrayThemeColor' : 'bg-white border-SeabiscuitLightThemeColorD3'
      )}
    >
      <div
        {...attributes}
        {...listeners}
        className="hidden md:flex cursor-pointer opacity-50 hover:opacity-100 w-11 absolute left-0 p-3 top-1 transition-all"
      >
        <img src="/assets/cp_icons/Move.svg" alt="moveIcon" className="w-full" />
      </div>
      <div className="relative w-full sm:w-[97%] lg:w-[20%]">
        <label>
          <Input
            className="p-0 border-none bg-transparent"
            register={register}
            placeholder={
              watch(`${selector}.${index}`)?.children?.length > 0
                ? 'Enter division name...'
                : 'Enter class name...'
            }
            name={`${selector}.${index}.name`}
            value={watch(`${selector}.${index}.name`)}
          />
        </label>
        {showErrors && !!errors?.[index]?.name?.message ? (
          <MessageHelperComp
            className="absolute z-0 bottom-1 left-0 translate-y-full"
            isError={true}
            message={errors?.[index]?.name?.message}
          />
        ) : null}
      </div>
      <div className="w-full sm:w-[24%] lg:w-[15%]">
        {!watch(`${selector}.${index}`)?.children?.length && (
          <label className="flex items-center justify-end gap-1">
            <Input
              type="number"
              containerClassName="!w-[50px]"
              className="p-0 border-none bg-transparent text-right"
              register={register}
              placeholder="0"
              name={`${selector}.${index}.order`}
              value={watch(`${selector}.${index}.order`)}
            />
            <span className="text-SeabiscuitDark200ThemeColor/50 whitespace-nowrap text-[14px]">
              class #
            </span>
          </label>
        )}
      </div>
      <div className="relative w-full sm:w-[29%] lg:w-[18%]">
        <label className="flex items-center gap-1">
          <AmountInput
            prefix="$"
            register={register}
            name={`${selector}.${index}.actualCostAlias`}
            placeholder="$"
            value={watch(`${selector}.${index}.actualCostAlias`)}
            inputClassName="text-right p-0 w-full bg-transparent text-SeabiscuitDark200ThemeColor"
            className="text-[14px] w-full rounded-md flex items-center"
          />
          <span
            className="text-SeabiscuitDark200ThemeColor/50 whitespace-nowrap text-[14px]"
            {...dataIncrementAttrProps}
          >
            {watch(`${selector}.${index}`)?.children?.length > 0 ? 'division fee' : 'entry fee'}
          </span>
        </label>
      </div>
      <div className="relative w-full sm:w-[38%] lg:w-[20%] flex justify-end text-right">
        <Controller
          control={control}
          name={`${selector}.${index}.qualifyingClass`}
          render={({ field: { value } }) => (
            <Select
              className="w-full p-0 bg-transparent border-none transition-all hover:opacity-70"
              classNamePrefix="entries-select"
              isClearable={false}
              isSearchable={false}
              placeholder="Qualifying class"
              menuPlacement="auto"
              menuPortalTarget={document.body}
              options={qualifyingOptions?.length > 0 ? qualifyingOptions : []}
              value={value?.name ? { label: value.name, value: value.name } : null}
              onChange={(newValue) => {
                if (newValue) {
                  const price = watch('qualifyingClasses.items').find(
                    (qualify: { name: string; price: number }) => qualify.name === newValue.value
                  )
                  setValue(`${selector}.${index}.qualifyingClass`, {
                    name: newValue.label,
                    price: price?.price ?? '0',
                  })
                }
              }}
            />
          )}
        />
      </div>
      <div className="w-full sm:w-[100%] lg:w-[17%]">
        <label className="text-[14px] flex items-center">
          <input
            type="text"
            className="p-0 underline bg-transparent border-none text-[14px] text-SeabiscuitDark200ThemeColor w-full rounded-md flex items-center cursor-pointer hover:no-underline overflow-hidden whitespace-nowrap truncate"
            placeholder="Description"
            value={watch(`${selector}.${index}.note`)}
            onClick={(e) => openAddNotesModal(e.target)}
            readOnly={true}
            {...register(`${selector}.${index}.note`)}
          />
        </label>
      </div>
      <button
        className={clsx(
          'cursor-pointer absolute top-3 translate-x-full flex items-center w-6',
          type === 'children' ? '-right-4' : '-right-1'
        )}
        onClick={() => {
          handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
            removeItem: function () {
              const currentItems = getValues(selector)
              const newItems = currentItems.filter((_: any, i: number) => i !== index)
              setValue(selector, newItems)
            },
          })
        }}
      >
        <img className="w-full" src="/assets/og_icons/Cancel.svg" alt="Cancel" />
      </button>
      {watch(`${selector}.${index}`).children?.length > 0 && (
        <SortableContext
          items={watch(`${selector}.${index}`).children?.map(
            ({ uuid }: RegistrationFeesType) => uuid
          )}
          strategy={verticalListSortingStrategy}
        >
          {watch(`${selector}.${index}`).children.map(
            (fieldChildren: any, childrenIndex: number) => {
              return (
                <EventEntriesFormRow
                  key={fieldChildren.uuid}
                  type="children"
                  field={fieldChildren}
                  register={register}
                  index={childrenIndex}
                  watch={watch}
                  showErrors={showErrors}
                  errors={errors?.[index]?.children}
                  openAddNotesModal={openAddNotesModal}
                  handleModal={handleModal}
                  control={control}
                  selector={`registrationFees.${index}.children`}
                  getValues={getValues}
                  setValue={setValue}
                  dataIncrementAttrProps={dataIncrementAttrProps}
                />
              )
            }
          )}
        </SortableContext>
      )}
      {type !== 'children' && watch(`${selector}.${index}.children`)?.length > 0 && (
        <div className="mt-4 text-[14px] text-SeabiscuitDark200ThemeColor/50 flex items-center gap-2">
          <img src={`/assets/og_icons/Cancel.svg`} className="rotate-45 w-5" alt="plus icon" />
          <button
            type="button"
            className="underline hover:no-underline"
            onClick={() => {
              setValue(`registrationFees.${index}.children`, [
                ...watch(`registrationFees.${index}.children`),
                {
                  order: null,
                  name: '',
                  category: 'registrations',
                  note: '',
                  cost: 0,
                  actualCost: 0,
                  actualCostAlias: '$0',
                  costAlias: '$0',
                  uuid: uuidv4(),
                  increment: '',
                  qualifyingClass: '',
                },
              ])
            }}
          >
            Add class
          </button>
        </div>
      )}
    </div>
  )
}
