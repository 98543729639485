import moment from 'moment'
import { PDFDocument, PDFImage, StandardFonts, cmyk } from 'pdf-lib'
// @ts-ignore
import fontkit from '@pdf-lib/fontkit'
import { collection, doc, where } from 'firebase/firestore'
import { CONST } from '../../../const/const'
import FirestoreService from '../../../services/firestoreService'
import FirebaseStorageService from '../../../services/storageService'
import { sendEmailForSign } from '../sendEmail/sendEmailForSign'

import { IDocument } from '../../../fakeData/fakeDocumentList'
import { IEventDetails } from '../../../models/event-details/event-details.interface'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { UserDocumentModel } from '../../../models/user-documents/user-documents.model'
import { IUserInterface } from '../../../models/users/user.interface'
import { IUserInterfaceExtended } from '../../../store/storeHelpers/userSlice/types'
import { capitalize, createString, getUserFullName } from '../../../helpers/helpers'
import FirebaseApp from '../../../services/firebaseApp'
import TimeLib from '../../../lib/Time'
import { getConvertedData } from '../../../models/interface.helper'

interface IPaperworkTemplateData {
  userData: IUserInterfaceExtended
  EventDetails: IEventDetails
  organizerDetails: IUserInterface | null
  paperwork: IDocument
  eventId: string
  ridersTeamMember: IRiderTeamMemberInterface
  isMature: boolean
  eventDraftId: string
  isStaff?: boolean
  isSigned?: boolean
  stringArray?: string[]
  index?: number
}

type TCertificate = {
  SX: number
  SY: number
  EX: number
  EY: number
}

export const USHJALiabilityWaiverPDFTeamplate = async (
  {
    userData,
    EventDetails,
    organizerDetails,
    paperwork,
    eventId,
    ridersTeamMember,
    isMature,
    isStaff,
    eventDraftId,
    isSigned,
    stringArray,
    index,
  }: IPaperworkTemplateData,
  customDocId?: string
) => {
  let shortLocation =
    EventDetails?.competitionAddressLine1 && EventDetails?.competitionAddressLine1.length < 30
      ? EventDetails.competitionAddressLine1
      : `${EventDetails.competitionCity}, ${EventDetails.competitionState}, ${EventDetails.competitionCountry}`

  const d = new Date()
  let time = d.getTime()

  const pdfDoc = await PDFDocument.create()
  const firstPage = pdfDoc.addPage([550, 760])
  const secondPage = pdfDoc.addPage([550, 780])
  const thirdPage = pdfDoc.addPage([550, 760])
  const fourthPage = pdfDoc.addPage([550, 760])
  const fifthPage = pdfDoc.addPage([550, 760])

  pdfDoc.registerFontkit(fontkit)

  // Fonts
  const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
  const timeRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
  const boldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold)
  const ItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanItalic)
  const timeRomanBoldItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBoldItalic)
  const fontPoppingsBytes = await fetch('/fonts/Poppins-Regular.ttf').then((res) =>
    res.arrayBuffer()
  )
  const PoppinsFont = await pdfDoc.embedFont(fontPoppingsBytes)
  const fontByte = await fetch('/fonts/signature.ttf').then((res) => res.arrayBuffer())
  const signatureFont = await pdfDoc.embedFont(fontByte)
  const jpgUrl = '/assets/Pegasus_app_logo.png'
  const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
  const jpgImage = await pdfDoc.embedPng(jpgImageBytes)

  firstPage.drawText('Pegasus App Inc.', { x: 45, y: 720, font: PoppinsFont, size: 14 })
  firstPage.drawLine({
    start: { x: 45, y: 715 },
    end: { x: 480, y: 715 },
    thickness: 1,
    color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
    opacity: 1,
  })

  firstPage.drawImage(jpgImage, {
    x: 480,
    y: 695,
    width: 40,
    height: 40,
    opacity: 1,
  })

  firstPage.drawText(`USHJA LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'}`, {
    x: 110,
    y: 520,
    font: boldFont,
    size: 20,
  })

  // Event Name
  firstPage.drawText(`Event :`, { x: 230, y: 470, font: boldFont, size: 16 })
  firstPage.drawText(`${EventDetails.competitionName}`, {
    x: 290,
    y: 470,
    font: timeRomanFont,
    size: 16,
  })

  //Organizer Name
  firstPage.drawText(`Host :`, { x: 238, y: 450, font: boldFont, size: 16 })
  firstPage.drawText(
    `${organizerDetails ? getUserFullName(organizerDetails) : 'No organizer on file'}`,
    { x: 290, y: 450, font: timeRomanFont, size: 16 }
  )

  // Event Location
  firstPage.drawText(`Event location :`, { x: 170, y: 430, font: boldFont, size: 16 })
  firstPage.drawText(`${shortLocation}`, { x: 290, y: 430, font: timeRomanFont, size: 16 })

  // Event Date
  firstPage.drawText(`Event date :`, { x: 195, y: 390, font: boldFont, size: 16 })
  firstPage.drawText(`${moment(EventDetails.competitionStartDate).format('MM-DD-YYYY')}`, {
    x: 290,
    y: 390,
    font: timeRomanFont,
    size: 16,
  })

  // Horse Name
  firstPage.drawText(`Horse :`, { x: 229, y: 370, font: boldFont, size: 16 })
  firstPage.drawText(
    `${isStaff ? 'No horse on file' : ridersTeamMember.horseName ? ridersTeamMember.horseName : 'No horse on file'}`,
    { x: 290, y: 370, font: timeRomanFont, size: 16 }
  )

  if (stringArray?.includes(CONST.UI.TEAM_MEMBERS_ROLES.RIDER)) {
    // Rider Name
    firstPage.drawText(`Rider :`, { x: 229, y: 350, font: boldFont, size: 16 })
    firstPage.drawText(`${isStaff ? 'No rider on file' : ridersTeamMember.riderName}`, {
      x: 290,
      y: 350,
      font: timeRomanFont,
      size: 16,
    })

    // Rider nationality ////
    firstPage.drawText(`Rider nationality :`, { x: 150, y: 330, font: boldFont, size: 16 })
    firstPage.drawText(
      `${ridersTeamMember.teamMemberCountry && ridersTeamMember.teamMemberCountry !== '' ? ridersTeamMember.teamMemberCountry : 'No nationality on file'}`,
      { x: 290, y: 330, font: timeRomanFont, size: 16 }
    )
  }
  ////// Signer Role ////////
  firstPage.drawText(`Signer role at event :`, { x: 140, y: 290, font: boldFont, size: 16 })
  firstPage.drawText(`${capitalize(stringArray?.slice(0, 3).toString())}`, {
    x: 290,
    y: 290,
    font: timeRomanFont,
    size: 16,
  })
  firstPage.drawText(`${capitalize(stringArray?.slice(3, 6).toString())}`, {
    x: 290,
    y: 270,
    font: timeRomanFont,
    size: 16,
  })

  ////// Signer name ////////
  firstPage.drawText(`Signer name :`, { x: 185, y: 250, font: boldFont, size: 16 })
  firstPage.drawText(`${ridersTeamMember.teamMemberName}`, {
    x: 290,
    y: 250,
    font: timeRomanFont,
    size: 16,
  })

  ////// Signer Phone number ////////
  firstPage.drawText(`Signer's phone number :`, { x: 118, y: 230, font: boldFont, size: 16 })
  firstPage.drawText(
    `${ridersTeamMember.teamMemberPhoneNumber && ridersTeamMember.teamMemberPhoneNumber !== '' ? ridersTeamMember.teamMemberPhoneNumber : 'No number on file'}`,
    { x: 290, y: 230, font: timeRomanFont, size: 16 }
  )

  ///////////// Second Page ///////////////
  secondPage.drawText('Pegasus App Inc.', { x: 45, y: 740, font: PoppinsFont, size: 14 })
  secondPage.drawLine({
    start: { x: 45, y: 735 },
    end: { x: 480, y: 735 },
    thickness: 1,
    color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
    opacity: 1,
  })
  secondPage.drawImage(jpgImage, {
    x: 480,
    y: 715,
    width: 40,
    height: 40,
    opacity: 1,
  })

  secondPage.drawText('WAIVER AND RELEASE OF LIABILITY, ASSUMPTION', {
    x: 45,
    y: 700,
    font: boldFont,
    size: 16,
  })
  secondPage.drawText(`OF RISK AND INDEMNITY AGREEMENT`, {
    x: 45,
    y: 680,
    font: boldFont,
    size: 16,
  })
  secondPage.drawText(`USEF: 4001 WING COMMANDER WAY: LEXINGTON, KY 40511:`, {
    x: 45,
    y: 640,
    font: boldFont,
    size: 16,
  })
  secondPage.drawText(`859.258.2472 : FAX 859.231.6662 : USEF.ORG`, {
    x: 45,
    y: 620,
    font: boldFont,
    size: 16,
  })
  secondPage.drawText(`${EventDetails.competitionName}`, { x: 45, y: 580, size: 14 })
  secondPage.drawText(`${moment(EventDetails.competitionStartDate).format('MM-DD-YYYY')}`, {
    x: 45,
    y: 560,
    size: 14,
  })
  secondPage.drawText(`${shortLocation}`, { x: 45, y: 540, size: 14 })
  secondPage.drawText(
    `For and in consideration of United States Equestrian Federation, Inc. dba US Equestrian`,
    { x: 45, y: 500, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `(“USEF”) allowing me, the undersigned, to participate in any capacity (including as a`,
    { x: 45, y: 480, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `rider, driver, handler, vaulter, longeur, lessee, owner, agent, coach, official, trainer`,
    { x: 45, y: 460, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `or volunteer) in a USEF sanctioned, licensed or approved event or activity, including `,
    { x: 45, y: 440, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `but not limited to equestrian clinics, practices, shows, competitions and related or`,
    { x: 45, y: 420, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `incidental activities and ${EventDetails.competitionName}(“USEF Event” or “USEF Events”);`,
    { x: 45, y: 400, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `I, for myself, and on behalf of my spouse, children, heirs and next of kin, and any legal`,
    { x: 45, y: 380, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `and personal representatives, executors, administrators, successors, and assigns,`,
    { x: 45, y: 360, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `hereby agree to and make the following contractual representations pursuant to`,
    { x: 45, y: 340, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(`this Agreement (the “Agreement”):`, {
    x: 45,
    y: 320,
    font: timeRomanFont,
    size: 12,
  })
  secondPage.drawText(`A. RULES AND REGULATIONS:`, { x: 45, y: 280, font: fontBold, size: 12 })
  secondPage.drawText(`I hereby agree to be bound and abide by the rules,`, {
    x: 230,
    y: 280,
    font: timeRomanFont,
    size: 12,
  })
  secondPage.drawText(
    `regulations, and policies of USEF as published in the USEF Rule Book and on the website`,
    { x: 45, y: 260, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(`at www.usef.org, as amended from time to time.`, {
    x: 45,
    y: 240,
    font: timeRomanFont,
    size: 12,
  })
  secondPage.drawText(`B. ACKNOWLEDGMENT OF RISK:`, { x: 45, y: 200, font: fontBold, size: 12 })
  secondPage.drawText(`I knowingly, willingly, and voluntarily acknowledge`, {
    x: 240,
    y: 200,
    font: timeRomanFont,
    size: 12,
  })
  secondPage.drawText(
    `the inherent risks associated with the sport of equestrian and know that horseback`,
    { x: 45, y: 180, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `riding and related equestrian activities are inherently dangerous, and that participation in`,
    { x: 45, y: 160, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `any USEF Event involves risks and dangers including, with- out limitation, the potential`,
    { x: 45, y: 140, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `for serious bodily injury (including broken bones, head or neck injuries), sickness and`,
    { x: 45, y: 120, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `disease (including communicable diseases), trauma, pain & suffering, permanent disability,`,
    { x: 45, y: 100, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `paralysis and death; loss of or damage to personal property (including my mount &`,
    { x: 45, y: 80, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `equipment) arising out of the unpredictable behavior of horses; exposure to extreme`,
    { x: 45, y: 60, font: timeRomanFont, size: 12 }
  )
  secondPage.drawText(
    `conditions and circumstances; accidents involving other participants, event staff,`,
    { x: 45, y: 40, font: timeRomanFont, size: 12 }
  )

  ///////////// third Page ///////////////
  thirdPage.drawText('Pegasus App Inc.', { x: 45, y: 720, font: PoppinsFont, size: 14 })
  thirdPage.drawLine({
    start: { x: 45, y: 715 },
    end: { x: 480, y: 715 },
    thickness: 1,
    color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
    opacity: 1,
  })
  thirdPage.drawImage(jpgImage, {
    x: 480,
    y: 695,
    width: 40,
    height: 40,
    opacity: 1,
  })

  thirdPage.drawText(
    `or spectators; contact or collision with other participants and horses, natural or manmade`,
    { x: 45, y: 680, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `objects; adverse weather conditions; facilities issues and premises conditions; failure`,
    { x: 45, y: 660, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `of protective equipment (including helmets); inadequate safety measures; participants`,
    { x: 45, y: 640, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `of varying skill levels; situations beyond the immediate control of the USEF Event`,
    { x: 45, y: 620, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `organizers and competition management; and other undefined, not readily foreseeable`,
    { x: 45, y: 600, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(`and presently unknown risks and dangers (“Risks”).`, {
    x: 45,
    y: 580,
    font: timeRomanFont,
    size: 12,
  })
  thirdPage.drawText(`EQUINE ACTIVITY LIABILITY ACT WARNING:`, {
    x: 140,
    y: 540,
    font: timeRomanBoldItalicFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })
  thirdPage.drawText(`CAUTION: HORSEBACK RIDING AND EQUINE ACTIVITIES CAN BE DANGEROUS. `, {
    x: 50,
    y: 500,
    color: cmyk(0, 0, 0, 0.9),
    font: timeRomanBoldItalicFont,
    size: 12,
  })
  thirdPage.drawText(`RIDE AT YOUR OWN RISK.`, {
    x: 190,
    y: 480,
    font: timeRomanBoldItalicFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })
  thirdPage.drawText(
    `Under the laws of most States, an equine activity sponsor or equine professional is not liable for`,
    { x: 45, y: 460, font: ItalicFont, color: cmyk(0, 0, 0, 0.9), size: 12 }
  )
  thirdPage.drawText(
    `any injury to, or the death of, a participant in equine activities resulting the inherent risks of`,
    { x: 40, y: 440, font: ItalicFont, color: cmyk(0, 0, 0, 0.9), size: 12 }
  )
  thirdPage.drawText(`equine activities.`, {
    x: 220,
    y: 420,
    font: ItalicFont,
    color: cmyk(0, 0, 0, 0.9),
    size: 12,
  })

  thirdPage.drawText(`C. ASSUMPTION OF RISK:`, { x: 45, y: 380, font: fontBold, size: 12 })
  thirdPage.drawText(`I understand that the aforementioned Risks may be caused`, {
    x: 200,
    y: 380,
    font: timeRomanFont,
    size: 12,
  })
  thirdPage.drawText(
    `in whole or in part or result directly or indirectly from the negligence of my own`,
    { x: 45, y: 360, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `actions or inactions, the actions or inactions of others participating in the USEF`,
    { x: 45, y: 340, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `Events, or the negligent acts or omissions of the Released Par- ties defined below,`,
    { x: 45, y: 320, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `and I hereby voluntarily and knowingly assume all such Risks and responsibility for`,
    { x: 45, y: 300, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(`any injury or damage caused by me, my horse, my employees or contractors`, {
    x: 45,
    y: 280,
    font: timeRomanFont,
    size: 12,
  })
  thirdPage.drawText(`under my direction and control at any USEF Event.`, {
    x: 45,
    y: 260,
    font: timeRomanFont,
    size: 12,
  })

  thirdPage.drawText(`D. WAIVER AND RELEASE OF LIABILITY, HOLD HARMLESS AND INDEMNITY:`, {
    x: 45,
    y: 220,
    font: fontBold,
    size: 12,
  })
  thirdPage.drawText(`In conjunction with my participation in any USEF Event, I hereby, release`, {
    x: 45,
    y: 200,
    font: timeRomanFont,
    size: 12,
  })
  thirdPage.drawText(`waive and covenant not to sue, and further agree to indemnify, defend and `, {
    x: 45,
    y: 180,
    font: timeRomanFont,
    size: 12,
  })
  thirdPage.drawText(
    `hold harmless the following parties: USEF, USEF Recognized Affiliate Associations,`,
    { x: 45, y: 160, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `the United States Olympic & Paralympic Committee (USOPC), USEF clubs, members,`,
    { x: 45, y: 140, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `Event participants (including athletes/riders, coaches, trainers, judges/officials,`,
    { x: 45, y: 120, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `and other personnel), the Event owner, licensee, and competition managers; the,`,
    { x: 45, y: 100, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `promoters sponsors, or advertisers of any USEF Event; any charity or other beneficiary`,
    { x: 45, y: 80, font: timeRomanFont, size: 12 }
  )
  thirdPage.drawText(
    `which may benefit from the USEF Event; the owners, managers, or lessors of any`,
    { x: 45, y: 60, font: timeRomanFont, size: 12 }
  )

  ///////////// fourth Page ///////////////
  fourthPage.drawText('Pegasus App Inc.', { x: 45, y: 720, font: PoppinsFont, size: 14 })
  fourthPage.drawLine({
    start: { x: 45, y: 715 },
    end: { x: 480, y: 715 },
    thickness: 1,
    color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
    opacity: 1,
  })

  fourthPage.drawImage(jpgImage, {
    x: 480,
    y: 695,
    width: 40,
    height: 40,
    opacity: 1,
  })

  fourthPage.drawText(
    ` facilities or premises where a USEF Event may be held; and all directors, officers,`,
    { x: 45, y: 680, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `employees, agents, contractors, and volunteers of any of the aforementioned parties`,
    { x: 45, y: 660, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `(Individually and Collectively, the “Released Parties” or “Event Organizers”), with`,
    { x: 45, y: 640, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    ` respect to any liability, claim(s), demand(s), cause(s) of action, damage(s), loss, `,
    { x: 45, y: 620, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `or expense (including court costs and reasonable attorney fees) of any kind or nature`,
    { x: 45, y: 600, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `(“Liability”) which may arise out of, result from, or relate in any way to my participation`,
    { x: 45, y: 580, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `in the USEF Events, including claims for Liability caused in whole or in part by the`,
    { x: 45, y: 560, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(`negligent acts or omissions of the Released Parties.`, {
    x: 45,
    y: 540,
    font: timeRomanFont,
    size: 12,
  })

  fourthPage.drawText(`E. COMPLETE AGREEMENT AND SEVERABILITY CLAUSE:`, {
    x: 45,
    y: 500,
    font: fontBold,
    size: 12,
  })
  fourthPage.drawText(`This Agreement`, { x: 390, y: 500, font: timeRomanFont, size: 12 })
  fourthPage.drawText(
    `represents the complete understanding between the parties regarding these issues`,
    { x: 45, y: 480, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `and no oral representations, statements or inducements have been made apart from`,
    { x: 45, y: 460, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `this Agreement. If any provision of this Agreement is held to be unlawful, void, or`,
    { x: 45, y: 440, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `for any reason unenforceable, then that provision shall be deemed severable from this`,
    { x: 45, y: 420, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `Agreement and shall not affect the validity and enforceability of any remaining provisions.`,
    { x: 45, y: 400, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(`I HAVE CAREFULLY READ THIS DOCUMENT IN ITS ENTIRETY, UNDERSTAND`, {
    x: 45,
    y: 350,
    font: fontBold,
    size: 12,
  })
  fourthPage.drawText(`ALL OF ITS TERMS AND CONDITIONS, AND KNOW IT CONTAINS AN`, {
    x: 45,
    y: 330,
    font: fontBold,
    size: 12,
  })
  fourthPage.drawText(`ASSUMPTION OF RISK, RELEASE AND WAIVER FROM LIABILITY, AS WELL`, {
    x: 45,
    y: 310,
    font: fontBold,
    size: 12,
  })
  fourthPage.drawText(`AS A HOLD HARMLESS AND, INDEMNIFICATION OBLIGATIONS.`, {
    x: 45,
    y: 290,
    font: fontBold,
    size: 12,
  })

  fourthPage.drawText(
    `By signing below, I (as the participant or as the Parent/Legal Guardian of the minor`,
    { x: 45, y: 240, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `identified below) hereby accept and agree to the terms and conditions of this Agreement`,
    { x: 45, y: 220, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `in connection with my (or the minor’s) participation in any USEF Event. If, despite this`,
    { x: 45, y: 200, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `Agreement, I, or anyone on my behalf or the minor’s behalf, makes a claim for Liability`,
    { x: 45, y: 180, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(`against any of the Released Parties, I will indemnify, defend and hold`, {
    x: 45,
    y: 160,
    font: timeRomanFont,
    size: 12,
  })
  fourthPage.drawText(
    `harmless each of the Released Parties from any such Liabilities as the result of such claim.`,
    { x: 45, y: 120, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(
    `The parties agree that this agreement may be electronically signed. The parties agree`,
    { x: 45, y: 100, font: timeRomanFont, size: 12 }
  )
  fourthPage.drawText(`hat the electronic signatures appearing on this agreement are the same as`, {
    x: 45,
    y: 80,
    font: timeRomanFont,
    size: 12,
  })

  ///////////// fifth Page ///////////////
  fifthPage.drawText('Pegasus App Inc.', { x: 45, y: 720, font: PoppinsFont, size: 14 })
  fifthPage.drawLine({
    start: { x: 45, y: 715 },
    end: { x: 480, y: 715 },
    thickness: 1,
    color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
    opacity: 1,
  })

  fifthPage.drawImage(jpgImage, {
    x: 480,
    y: 695,
    width: 40,
    height: 40,
    opacity: 1,
  })

  fifthPage.drawText(
    `handwritten signatures for the purposes of validity, enforceability, and admissibility.`,
    { x: 45, y: 680, font: timeRomanFont, size: 12 }
  )

  fifthPage.drawText(`Signers name :`, { x: 45, y: 640, font: boldFont, size: 12 })
  fifthPage.drawText(`${ridersTeamMember.teamMemberName}`, {
    x: 130,
    y: 640,
    font: timeRomanFont,
    size: 12,
  })

  fifthPage.drawText(`Date :`, { x: 45, y: 620, font: boldFont, size: 12 })
  fifthPage.drawText(
    `${moment(getConvertedData({ date: TimeLib.utcTimestamp() }).date).format('MM-DD-YYYY')}`,
    { x: 130, y: 620, font: timeRomanFont, size: 12 }
  )

  fifthPage.drawText(`Signature:`, { x: 45, y: 600, font: boldFont, size: 12 })

  if (isSigned) {
    let AllLogs: {
      logs: any
      id?: string
      signatoryId?: string
      riderId?: string
      eventId?: string
      u?: boolean
    }

    AllLogs = {
      id: '',
      signatoryId: ridersTeamMember.teamMemberId ?? '',
      riderId: ridersTeamMember.riderId ?? '',
      eventId: ridersTeamMember.eventId ?? '',
      u: false,
      logs: [
        {
          time: TimeLib.utcTimestamp(),
          audit: `Document signed by ${ridersTeamMember.teamMemberName}`,
        },
      ],
    }

    fifthPage.drawText(`${ridersTeamMember.teamMemberName}`, {
      x: 45,
      y: 425,
      font: signatureFont,
      size: 28,
    })

    const auditTrailPage = await pdfDoc.addPage([550, 760])
    auditTrailPage.drawText('Pegasus App Inc.', { x: 45, y: 720, font: PoppinsFont, size: 14 })
    auditTrailPage.drawLine({
      start: { x: 45, y: 715 },
      end: { x: 480, y: 715 },
      thickness: 1,
      color: cmyk(0, 0, 0, 0.9),
      opacity: 1,
    })

    auditTrailPage.drawImage(jpgImage, {
      x: 480,
      y: 695,
      width: 40,
      height: 40,
      opacity: 1,
    })

    let certificateBorder = [
      { SX: 70, SY: 660, EX: 470, EY: 660 }, // Top Border
      { SX: 70, SY: 660, EX: 70, EY: 60 }, // Left Border
      { SX: 470, SY: 660, EX: 470, EY: 60 }, // Right Border
      { SX: 70, SY: 60, EX: 470, EY: 60 }, // Bottom Border
    ]

    certificateBorder.forEach((item: TCertificate) => {
      auditTrailPage.drawLine({
        start: { x: item.SX, y: item.SY },
        end: { x: item.EX, y: item.EY },
        thickness: 2,
        color: cmyk(0, 0, 0, 0.5),
        opacity: 0.8,
      })
    })

    let documentRef = doc(
      collection(FirebaseApp.firestore, CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS_DOCUMENTS.NAME)
    )
    let id = documentRef.id
    let documentNameAsPerPdf = `USHJA LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'}`
    auditTrailPage.drawText(`Signature Certificate`, {
      x: 80,
      y: 640,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 14,
    })
    auditTrailPage.drawText(`Document Name: ${documentNameAsPerPdf}`, {
      x: 80,
      y: 630,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 10,
    })
    auditTrailPage.drawText(`Unique Document Id: ${id}`, {
      x: 80,
      y: 620,
      font: boldFont,
      color: cmyk(0, 0, 0, 0.9),
      size: 8,
    })

    let Image = ridersTeamMember.teamMemberProfilePicture
      ? ridersTeamMember.teamMemberProfilePicture
      : '/assets/img/Empty user.png'
    let imagetype: string = ''

    const getImageArray = async (imageUrl: string) => {
      return await fetch(imageUrl).then((res) => {
        const contentType = res.headers.get('content-type') ?? ''
        imagetype = contentType?.split('/')[1]
        return res.arrayBuffer()
      })
    }

    let jpgImageBy = await getImageArray(Image)

    let userImage: PDFImage
    if (imagetype === 'jpeg') {
      userImage = await pdfDoc.embedJpg(jpgImageBy)
    } else if (imagetype === 'png') {
      userImage = await pdfDoc.embedPng(jpgImageBy)
    } else {
      const defaultImage = await getImageArray('/assets/img/Empty user.png')
      userImage = await pdfDoc.embedPng(defaultImage)
    }

    auditTrailPage.drawImage(userImage, {
      x: 80,
      y: 480,
      width: 80,
      height: 80,
      opacity: 1,
    })

    // //%%%%%%%%%%%
    const capatlize_name: string = capitalize(createString(ridersTeamMember.teamMemberName), {
      capitalizeAll: true,
    })
    auditTrailPage.drawText(capatlize_name, { x: 170, y: 550, color: cmyk(0, 0, 0, 0.9), size: 10 })
    auditTrailPage.drawText(`Security Level: Email`, {
      x: 170,
      y: 535,
      color: cmyk(0, 0, 0, 0.9),
      size: 10,
    })

    let signatureBorder = [
      { SX: 330, SY: 560, EX: 460, EY: 560 }, // Top Border
      { SX: 330, SY: 560, EX: 330, EY: 490 }, // Left Border
      { SX: 460, SY: 560, EX: 460, EY: 490 }, // Right Border
      { SX: 330, SY: 490, EX: 460, EY: 490 }, // Bottom Border
    ]

    signatureBorder.forEach((item: TCertificate) => {
      auditTrailPage.drawLine({
        start: { x: item.SX, y: item.SY },
        end: { x: item.EX, y: item.EY },
        thickness: 1,
        color: cmyk(0, 0, 0, 0.5),
        opacity: 0.8,
      })
    })

    auditTrailPage.drawText(`Digital Signature:`, {
      x: 340,
      y: 540,
      color: cmyk(0, 0, 0, 0.9),
      font: boldFont,
      size: 11,
    })
    auditTrailPage.drawText(`${ridersTeamMember.teamMemberName}`, {
      x: 340,
      y: 510,
      color: cmyk(0, 0, 0, 0.9),
      font: signatureFont,
      size: 28,
    })

    if (AllLogs.logs && AllLogs.logs.length) {
      auditTrailPage.drawText(`Timestamp`, {
        x: 110,
        y: 440,
        color: cmyk(0, 0, 0, 0.9),
        font: boldFont,
        size: 15,
      })
      auditTrailPage.drawText(`Audit Trails`, {
        x: 270,
        y: 440,
        color: cmyk(0, 0, 0, 0.9),
        font: boldFont,
        size: 15,
      })
      let logY = 420

      AllLogs.logs.forEach((log: any) => {
        let parsedDate = Date.parse(getConvertedData({ time: log.time }).time)

        let firstLineText = ''
        let secondLineText = ''
        let thirdLineText = ''

        if (log.audit.length > 40 && log.audit.length < 80) {
          firstLineText = log.audit.slice(0, 40)
          secondLineText = log.audit.slice(41, 80)
        } else if (log.audit.length > 40 && log.audit.length < 120) {
          firstLineText = log.audit.slice(0, 40)
          secondLineText = log.audit.slice(41, 80)
          thirdLineText = log.audit.slice(81, 120)
        } else if (log.audit.length < 40) {
          firstLineText = log.audit
        }

        auditTrailPage.drawText(`${moment(parsedDate).format(`MMMM DD, YYYY h:mm:ss a`)}`, {
          x: 110,
          y: logY,
          color: cmyk(0, 0, 0, 0.9),
          size: 8,
        })

        if (firstLineText && firstLineText !== '') {
          auditTrailPage.drawText(`${firstLineText}`, {
            x: 270,
            y: logY,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        if (secondLineText && secondLineText !== '') {
          auditTrailPage.drawText(`${secondLineText}`, {
            x: 270,
            y: logY - 10,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        if (thirdLineText && thirdLineText !== '') {
          auditTrailPage.drawText(`${thirdLineText}`, {
            x: 270,
            y: logY - 20,
            color: cmyk(0, 0, 0, 0.9),
            size: 8,
          })
        }

        logY -= 40
        if (logY < 140) {
          logY = 620
          // const auditTrailPage2 = await pdfService.addNewPdfPage();
          auditTrailPage.drawText('Pegasus App Inc.', {
            x: 45,
            y: 720,
            font: PoppinsFont,
            size: 14,
          })
          auditTrailPage.drawLine({
            start: { x: 45, y: 715 },
            end: { x: 480, y: 715 },
            thickness: 1,
            color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
            opacity: 1,
          })

          auditTrailPage.drawImage(jpgImage, {
            x: 480,
            y: 695,
            width: 40,
            height: 40,
            opacity: 1,
          })

          let certificateBorder = [
            { SX: 70, SY: 660, EX: 470, EY: 660 }, // Top Border
            { SX: 70, SY: 660, EX: 70, EY: 60 }, // Left Border
            { SX: 470, SY: 660, EX: 470, EY: 60 }, // Right Border
            { SX: 70, SY: 60, EX: 470, EY: 60 }, // Bottom Border
          ]

          certificateBorder.forEach((item: TCertificate) => {
            auditTrailPage.drawLine({
              start: { x: item.SX, y: item.SY },
              end: { x: item.EX, y: item.EY },
              thickness: 2,
              color: cmyk(0, 0, 0, 0.5),
              opacity: 0.8,
            })
          })
        }
      })
    }

    auditTrailPage.drawLine({
      start: { x: 70, y: 140 },
      end: { x: 470, y: 140 },
      thickness: 2,
      color: cmyk(0, 0, 0, 0.5),
      opacity: 0.8,
    })

    auditTrailPage.drawText(`This audit trail report provides a detailed record of the`, {
      x: 160,
      y: 110,
      font: boldFont,
      size: 10,
    })
    auditTrailPage.drawText(`online activity and events recorded for this contact.`, {
      x: 170,
      y: 90,
      font: boldFont,
      size: 10,
    })

    auditTrailPage.drawText(`https://thepegasus.app/`, {
      x: 70,
      y: 50,
      color: cmyk(0, 0, 0, 0.5),
      font: boldFont,
      size: 8,
    })
  }

  const pdfBytes = await pdfDoc.save()

  // Step 1: Convert Uint8Array to Blob
  var blob = new Blob([pdfBytes.buffer], { type: 'application/octet-stream' })

  // const uint8Array = new Uint8Array(pdfBytes);
  // let binaryString = '';
  // for (let i = 0; i < uint8Array.length; i++) {
  //   binaryString += String.fromCharCode(uint8Array[i]);
  // }

  // const base64Data = btoa(binaryString);
  // let baseUrl = "data:application/pdf;base64," + base64Data
  // console.log(baseUrl);
  // const blobUrl = URL.createObjectURL(blob);
  // console.log({  ridersTeamMember, blobUrl })
  // return
  // window.open(blobUrl, "_blank");
  // URL.revokeObjectURL(blobUrl);

  // Step 2: Convert Blob to File
  var file = new File([blob], `${paperwork.document_name}.pdf`, { type: 'application/pdf' })

  const fileUrl = await FirebaseStorageService.uploadFile(
    file,
    `/user/documents/${ridersTeamMember.teamMemberRole}${time}/${ridersTeamMember.registrationDocId}/${ridersTeamMember.teamMemberId}/${eventId}/${paperwork.document_name}/${ridersTeamMember.riderId}-${index}`
  )

  const USER_DOCUMENT = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS_DOCUMENTS
  let retunedData: any = {}

  try {
    const eventsSnapshot = await FirestoreService.filterItems(USER_DOCUMENT.NAME, [
      where(USER_DOCUMENT.FIELDS.SIGNATORY_ID.KEY, '==', ridersTeamMember.teamMemberId),
      where(USER_DOCUMENT.FIELDS.EVENT_ID.KEY, '==', eventId),
      where(USER_DOCUMENT.FIELDS.RIDER_ID.KEY, '==', ridersTeamMember.riderId),
      where(USER_DOCUMENT.FIELDS.DOCUMENT_NAME.KEY, '==', paperwork.document_name),
      where(USER_DOCUMENT.FIELDS.SIGNATORY_DEFAULT_ROLE.KEY, '==', ridersTeamMember.teamMemberRole),
    ])

    let obj = {
      documentNameAsPerPdf: `USEF LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'}`,
      documentName: paperwork.key,
      documentOriginalName: paperwork.document_name,
      documentUrl: (fileUrl as string) ?? '',
      eventId,
      eventLogo: EventDetails.eventLogo,
      eventName: EventDetails.competitionName,
      status: isSigned ? 'Signed' : 'Not Signed',
      u: false,
      riderId: ridersTeamMember.riderId,
      riderName: isStaff ? 'N/A' : ridersTeamMember.riderName,
      signatoryId: ridersTeamMember.teamMemberId,
      signatoryName: ridersTeamMember.teamMemberName,
      signatoryProfilePicture: ridersTeamMember.teamMemberProfilePicture,
      activityUser: getUserFullName(userData),
      documentOwner: ridersTeamMember.teamMemberId,
      competitorId: userData.id,
      signatoryDefaultRole: ridersTeamMember.teamMemberRole,
      signatoryEmail: ridersTeamMember.teamMemberEmail,
      reminder: false,
      registrationDocId: ridersTeamMember.registrationDocId,
      registrationByDayDocId: ridersTeamMember.registrationByDayDocId,
      riderTeamMemberDocId: ridersTeamMember.id,
    }

    if (eventsSnapshot.size) {
      let docId = ''
      eventsSnapshot.forEach((doc) => {
        docId = UserDocumentModel.fromFirestoreDoc(doc).toObject().id
      })

      await FirestoreService.updateItem(USER_DOCUMENT.NAME, customDocId || docId, obj)

      await FirestoreService.createItemWithCustomId(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
        customDocId || docId,
        {
          id: customDocId || docId,
          riderId: ridersTeamMember.riderId,
          eventId,
          signatoryId: ridersTeamMember.teamMemberId,
          logs: [
            {
              time: new Date(),
              audit: `USEF LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'} by ${organizerDetails?.userLegalPolicyName} - ${organizerDetails?.userEmail}`,
            },
          ],
        }
      )
      retunedData = {
        docId: customDocId || docId,
      }
    } else {
      const document = await FirestoreService.createItem(USER_DOCUMENT.NAME, obj)
      retunedData = {
        docId: document.id,
      }
      await FirestoreService.createItemWithCustomId(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_DOCUMENTS_LOGS.NAME,
        document.id,
        {
          id: document.id,
          riderId: ridersTeamMember.riderId,
          eventId,
          signatoryId: ridersTeamMember.teamMemberId,
          logs: [
            {
              time: new Date(),
              audit: `USEF LIABILITY WAIVER, ${isMature ? '18+' : 'Minor'} by ${organizerDetails?.userLegalPolicyName} - ${organizerDetails?.userEmail}`,
            },
          ],
        }
      )
    }
  } catch (err: any) {
    return err
  }

  if (fileUrl && fileUrl !== '' && eventId) {
    sendEmailForSign({
      ridersTeamMember,
      paperwork,
      paperworkUrl: fileUrl as string,
      eventId,
      isMature,
      isStaff,
      eventDraftId,
      senderData: {
        senderName: `${userData.userFirstName} ${userData.userLastName}`,
        senderEmail: userData.userEmail || '',
      },
    })
  }
  return retunedData
}
