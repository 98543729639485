import { useState } from 'react'
import InfiniteScrollDataTable from '../../../../components/common/tables/InfiniteScrollDataTable'
import { PillButtonElement } from '../../../../components/elements/buttons/pill/PillButtonElement'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import { IEventInterface } from '../../../../models/events/event.interface'
import { ALL_EVENT_LISTING_ROOT_PAGE_DATA } from '../../../allEvents/root/AllEventListingRootPage.data'
import NoDataAvailable from '../../../myEvent/component/NoDataAvailable'
import { myEventsTableStyles } from '../../../myEvent/data/myEventsTableStyles'

const TabData = [
  { id: 1, title: 'New events' },
  { id: 2, title: 'Past events' },
]

type IProps = {
  events: IEventInterface[]
  pastEvents: IEventInterface[]
  NewEvents: IEventInterface[]
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const HorseCompetetionDetailsCard = (props: IProps) => {
  const { pastEvents, NewEvents } = props
  // Hooks and vars
  const [activeTab, setActiveTab] = useState(1)

  return (
    <>
      <div className="bg-SeabiscuitWhiteThemeColor h-full event-tab pt-6 pb-2 px-6">
        <div className="max-w-full overflow-y-auto mb-4">
          <ul className="flex w-fit pb-3">
            {TabData.map((item, index) => {
              return (
                <PillButtonElement
                  key={`${JSON.stringify(item)}${index}`}
                  text={item.title}
                  Class="h-[45px] w-[200px] border-none text-SeabiscuitDark200ThemeColor"
                  onButtonClicked={() => setActiveTab(item.id)}
                  value={item.id}
                  selected={activeTab === item.id}
                />
              )
            })}
          </ul>
        </div>

        <div className="relative">
          <span className="overflow-auto">
            <InfiniteScrollDataTable
              // fetchMore={false}
              columns={
                true
                  ? ALL_EVENT_LISTING_ROOT_PAGE_DATA.COLS.HORSE_PROFILE_NEW_COLS
                  : ALL_EVENT_LISTING_ROOT_PAGE_DATA.COLS.HORSE_PROFILE_PAST_COLS
              }
              hasMore={false}
              fixedHeader={true}
              data={activeTab === 1 ? NewEvents : pastEvents}
              // progressPending={datatable.loading}
              progressComponent={<ViewsLoader size="xl" color="red" />}
              noDataComponent={
                <NoDataAvailable
                  imgSrc={'assets/placeholders/NoEventsFoundProfile.svg'}
                  text="No past event exists"
                  // bottomText={"See More >"}
                  textColor="text-[#122B46]"
                />
              }
              className="transition-all"
              customStyles={{
                ...myEventsTableStyles,
                headRow: {
                  style: {
                    minHeight: 'unset',
                    borderColor: '#D3DAEE',
                  },
                },
              }}
              noHeader={true}
            />
          </span>
        </div>
      </div>
    </>
  )
}

export default HorseCompetetionDetailsCard
