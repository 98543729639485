// import { useAppSelector } from "../../store/hooks"
import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setDocSharingMail, sharingMailData } from '../../store/paperworks/paperworkSlice'

type Props = {
  data: sharingMailData[]
}

const SharePaperworkViaEmail = (props: Props) => {
  //Hooks and Vars
  const dispatch = useDispatch()
  const [inputVisible] = useState<boolean>(true)
  const [validEmail, setValidEmail] = useState<boolean>(true)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef(null)

  const handleEmail = (email: string) => {
    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true
    }
    return false
  }

  const handleEmailSelect = ({ mailerIndex, value }: { mailerIndex: number; value: boolean }) => {
    let modifiedData: sharingMailData[] = []

    props.data.map((mailerData: sharingMailData, index: number) => {
      if (index === mailerIndex) {
        modifiedData.push({
          email: mailerData.email,
          selected: value,
        })
      } else {
        modifiedData.push(mailerData)
      }
      return true
    })

    dispatch(setDocSharingMail(modifiedData))
  }

  const handleKeyDown = (e: any) => {
    let email = e.target.value
    if (e.key === 'Enter') {
      if (!email.length) setValidEmail(false)
      let isValidEmail = handleEmail(email)
      if (isValidEmail) {
        dispatch(
          setDocSharingMail([
            ...props.data,
            {
              email,
              selected: true,
            },
          ])
        )
        setValidEmail(true)
        setInputValue('')
      } else {
        setValidEmail(false)
      }
    }
  }

  const handleInputChange = (e: any) => {
    setValidEmail(true)
    setInputValue(e.target.value)
  }

  return (
    <>
      {props.data && props.data.length
        ? props.data.map((sharingData: sharingMailData, index: number) => {
            return (
              <div
                className="flex items-center justify-between my-6"
                onClick={() =>
                  handleEmailSelect({ mailerIndex: index, value: !sharingData.selected })
                }
              >
                <div className="flex items-center">
                  <img
                    src="/assets/og_icons/Mail-1.svg"
                    className="w-6 h-6 object-cover mr-4"
                    alt="envelope"
                  />
                  <p className="text-[#122B46]">{sharingData.email}</p>
                </div>
                {sharingData.selected ? (
                  <img src="/assets/og_icons/Ok-3.svg" className="w-5 h-5 object-cover" alt="ok" />
                ) : (
                  <div className="w-5 h-5 rounded-full border border-[#1F41734D]"></div>
                )}
              </div>
            )
          })
        : null}

      {inputVisible ? (
        <div className="mb-4">
          <div className="input relative">
            <input
              className="border-[#D3DAEE] border-[0.5px] rounded-lg placeholder:text-[#1F41734D] pl-12 w-full relative min-h-[48px] focus:ring-0 focus:border-[#1F41734D] focus:border-[0.5px] focus-visible:outline-0 text-[#122B46]"
              ref={inputRef}
              value={inputValue}
              placeholder="Add email and press enter"
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <img
              src="/assets/og_icons/Mail-1.svg"
              className="opacity-30 w-6 h-6 left-3 top-3 object-cover absolute"
              alt="envelope"
            />
          </div>
          {!validEmail ? (
            <p className="text-[12px] text-[#000] ">Please enter a valid mail</p>
          ) : (
            <p className="text-[12px] text-[#1F417380] "></p>
          )}
        </div>
      ) : null}

      {/* <div onClick={() => { setInputVisible(true) }} className="flex items-center justify-center mt-[-5px] cursor-pointer">
                <AddCircleOutline className="text-[#f7074f]" />
                <p className="text-[#8676FF] font-normal text-[14px] ml-2"></p>
            </div> */}
    </>
  )
}

export default SharePaperworkViaEmail
