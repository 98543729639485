import { where } from 'firebase/firestore'
import { RecipientModel } from '../../../models/recipients/recipients'
import { CONST } from '../../../const/const'
import { createAsyncThunk } from '@reduxjs/toolkit'
import IExhibitorSlice from '../types'
import FirestoreService from '../../../services/firestoreService'
import { CustomError } from '../../../helpers/helpers'
import helpers from '../../../commonHelpers/helpers'
import { getConvertedData } from '../../../models/interface.helper'

const getRecipientThunk = createAsyncThunk<
  IExhibitorSlice['IGetRecipientThunkSuccessResponse'],
  IExhibitorSlice['IGetRecipientThunkErrorResponse']
>('getRecipientThunk', async (arg: IExhibitorSlice['IGetRecipientThunkPayload'], thunkApi: any) => {
  try {
    const docSnapshot = await FirestoreService.filterItems(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.NAME,
      [
        where(
          CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.FIELDS.RECIPIENT_ID.KEY,
          '==',
          arg.recipientId
        ),
        where(
          CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.FIELDS.REGISTRATION_DOC_ID.KEY,
          '==',
          arg.registrationId
        ),
      ],
      1
    )

    if (docSnapshot?.docs?.[0]) {
      let recipient = RecipientModel.fromFirestoreDoc(docSnapshot.docs[0]).toObject()
      return getConvertedData(recipient)
    } else {
      throw new CustomError({
        moduleName: 'getRecipientThunk',
        fileName: 'getRecipientThunk',
        message: 'Recipient not found',
        devMessage: `Recipient where ${CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.FIELDS.RECIPIENT_ID.KEY}: ${arg.recipientId} and ${CONST.DATA.FIRESTORE.V01.COLLECTIONS.RECIPIENT.FIELDS.REGISTRATION_DOC_ID.KEY}: ${arg.registrationId} doesn't exist`,
      })
    }
  } catch (error: any) {
    helpers.logger({
      message: error,
    })

    return thunkApi.rejectWithValue({
      message: error?.message,
    })
  }
})

export { getRecipientThunk }
