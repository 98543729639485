import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import TopNavigationCommonComponent from '../components/common/navigation/top/TopNavigationCommonComponent'
import PegasusLogo from '../icons/logo/Logo.svg'
import { useAppSelector } from '../store/hooks'
import { setMenuCollapse } from '../store/system/systemSlice'
import { selectDisplayName } from '../store/user/userSlice'
import { IonContent, IonPage } from '@ionic/react'

type ICompetitorLayoutProps = {
  children: any
  handleModal?: any
  isLoggedIn?: boolean
  isPublicPage?: boolean
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const CompetitorLayout = (props: ICompetitorLayoutProps) => {
  // Hooks and vars
  const dispatch = useDispatch()
  const displayName = useAppSelector(selectDisplayName)

  const router = useLocation()
  const mainRoute = router.pathname.split('/')[1]

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (props.isPublicPage) return

    if (mainRoute === 'home' && !props.isLoggedIn) {
      dispatch(setMenuCollapse(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IonPage>
      <TopNavigationCommonComponent
        logo={PegasusLogo}
        isLoggedIn={props.isLoggedIn}
        displayName={displayName}
        handleModal={props.handleModal}
      />
      <IonContent
        fullscreen
        style={{ '--ion-background-color': '#F6F7FB', '--offset-top': '56px' }}
      >
        <div className="pt-14">{props.children}</div>
      </IonContent>
    </IonPage>
  )
}

export default CompetitorLayout
