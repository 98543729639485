// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { createSlice, current } from '@reduxjs/toolkit'
import { IEventTickets } from '../../models/event-details/event-details.interface'
import {
  IEventDetailData,
  IEventDraftInterface,
  IFees,
  IPaperworkTab,
  IReviewAndPay,
} from '../../models/event-drafts/event-draft.interface'
import { TEventRegisteredUsers } from '../../models/event-registered-users/event-registered-users.interface'
import { IEventReviewPublish } from '../../models/event-review-publish/event-review-publish.interface'
import { IEventInterface } from '../../models/events/event.interface'
import { EventModel } from '../../models/events/event.model'
import { IHorseData } from '../../models/horse/horse.interface'
import { getConvertedData } from '../../models/interface.helper'
import { IRecipient, ITeamMember } from '../../models/users/user.interface'
import { IEventDetailsUi } from '../../types/competitor_types'
import { IReduxStatus, RootState } from '../store'
import { ISpectatorTickets } from '../../models/spectator-tickets/spectator-tickets.interface'
import { IRegistrationTicketInterface } from '../../models/registration-tickets/registrationTicket.interface'
import { getEventsThunk } from './thunks/getEventsThunk'
import { toastFunctionsObj } from '../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../const/messages-const'
import { IRecipientInterface } from '../../models/recipients/recipients.interface'

export interface IScratch {
  fees: IFees[]
  eventId: string | null
  scratchViewVisibility: boolean
  events: IEventDetailsUi[]
  tickets: IEventTickets[]
  registrationId: string | null
  scratchType: 'fees' | 'event' | 'ticket' | null
}

export interface IEventsRegisterData {
  fees: IFees[]
  sign: IPaperworkTab
  horses: IHorseData[]
  tickets: IEventTickets[]
  paperwork: IPaperworkTab
  events: IEventDetailData[]
  teamMembers: ITeamMember[]
  signatories: ITeamMember[]
  reviewAndPay: IReviewAndPay
}

type EventSteps = {
  step: number
  detailPage: boolean
}

export interface IEventWithDetails extends IEventInterface {}

export interface ISelectedEvent {
  Event: IEventInterface | null
  EventPriceList: any | null
  EventRegisteredUsers: TEventRegisteredUsers[]
  basicEventDetails: {
    id: string | null
    owner: string | null
    status: string | null
    created: Date | string | null
    modified: Date | string | null
    registrationPrice: number | null
    registrationPriceAlias: string | null
    description: IEventReviewPublish['briefDescription'] | null
  }
  EventSpectators: ISpectatorTickets[]
  EventFees: IEventReviewPublish['EventFees'] | null
  EventStaff: IEventReviewPublish['EventStaff'] | null
  EventVendors: IEventReviewPublish['EventVendors'] | null
  EventTickets: IEventReviewPublish['EventTickets'] | null
  EventRegistrationTickets: IRegistrationTicketInterface[]
  EventDetails: IEventReviewPublish['EventDetails'] | null
  EventSchedule: IEventReviewPublish['EventSchedule'] | null
  EventSponsers: IEventReviewPublish['EventSponsors'] | null
  EventPolicies: IEventReviewPublish['EventPolicies'] | null
  EventPaperwork: IEventReviewPublish['EventPaperwork'] | null
  EventNearbyBusines: IEventReviewPublish['EventNearby'] | null
  EventPaymentSettings: IEventReviewPublish['EventPaymentSettings'] | null
  EventRequiredFields: IEventReviewPublish['EventRequiredFields'] | null
  revenueCustomers?: IRecipientInterface[]
}
interface IEventState {
  events: {
    hasMore: boolean
    status: IReduxStatus
    message: string | null
    cursorId: string | null
    data: IEventInterface[]
    followingCursorId: string | null
    fetchMoreStatus: IReduxStatus
  }
  splitPayment: {
    currRecipient: IRecipient | null
    registration: TEventRegisteredUsers | null
  }
  organizer: {
    events: EventModel[]
    filteredEvents: EventModel[]
  }
  scratch: IScratch
  tabs: Array<string>
  filterEvents: Object
  eventSteps: EventSteps
  draft: IEventDraftInterface
  selectedEvent: ISelectedEvent
  editedEvent: IEventInterface | null
  filteredEvents: IEventInterface[]
  registerEvents: IEventsRegisterData
  publishedEvent: IEventReviewPublish | null
  isPublished: boolean
  editEvent: {
    filesToUpload: Array<string>
    eventId: string
    type: string
  }
  paymentDetails: {
    registrations: number
    totalRevenue: number
    completeRevenue: number
  }
  exhibitor_team: {
    loading: boolean
    document_data: any[]
    member_data: any[]
    resend_email: any[]
  }
  loading: boolean
}

export const initialSystemEventsState: IEventState = {
  events: {
    data: [],
    message: null,
    hasMore: true,
    cursorId: null,
    status: 'loading',
    fetchMoreStatus: 'idle',
    followingCursorId: null,
  },
  splitPayment: {
    currRecipient: null,
    registration: null,
  },
  editedEvent: null,
  publishedEvent: null,
  isPublished: false,
  scratch: {
    fees: [],
    events: [],
    tickets: [],
    eventId: null,
    scratchType: null,
    scratchViewVisibility: false,
    registrationId: null,
  },
  filteredEvents: [],
  organizer: { events: [], filteredEvents: [] },
  selectedEvent: {
    revenueCustomers: [],
    basicEventDetails: {
      id: null,
      owner: null,
      status: null,
      created: null,
      modified: null,
      description: null,
      registrationPrice: null,
      registrationPriceAlias: null,
    },
    Event: null,
    EventFees: null,
    EventStaff: null,
    EventVendors: null,
    EventTickets: null,
    EventDetails: null,
    EventSchedule: null,
    EventPolicies: null,
    EventSponsers: null,
    EventSpectators: [],
    EventPaperwork: null,
    EventPriceList: null,
    EventNearbyBusines: null,
    EventRegisteredUsers: [],
    EventPaymentSettings: null,
    EventRequiredFields: {
      requiredFields: [],
      requiredHorseFields: [],
    },
    EventRegistrationTickets: [],
  },
  tabs: [],
  filterEvents: {},
  draft: getConvertedData({
    id: null,
    tab: 'Status',
    userName: null,
    userType: null,
    userProfilePicture: null,
    draft: {
      fees: [],
      sign: [],
      horse: [],
      status: {},
      events: [],
      classes: {},
      paperwork: {},
      confirmation: {},
      reviewAndPay: {
        splitPayment: false,
        recipients: [],
      },
      tickets: [],
      register: {},
      signatories: [],
    },
    eventName: '',
    eventStartDate: null,
    eventCity: '',
    eventState: '',
    eventCountry: '',
    eventCreatedAt: null,
    tags: [],
    eventCategory: '',
    step: 0,
    paymentStatus: false,
    eventId: '',
    userId: '',
    status: 1,
  }),
  registerEvents: {
    fees: [],
    sign: {},
    events: [],
    horses: [],
    tickets: [],
    paperwork: {},
    teamMembers: [],
    signatories: [],
    reviewAndPay: {
      splitPayment: false,
      recipients: [],
    },
  },
  eventSteps: {
    detailPage: false,
    step: 0,
  },
  editEvent: {
    filesToUpload: [],
    eventId: '',
    type: '',
  },
  paymentDetails: {
    registrations: 0,
    totalRevenue: 0,
    completeRevenue: 0,
  },
  exhibitor_team: {
    loading: false,
    member_data: [],
    document_data: [],
    resend_email: [],
  },
  loading: false,
}

const eventSlice = createSlice({
  name: 'events',
  initialState: initialSystemEventsState,
  reducers: {
    setRevenueCustomers: (state, action) => ({
      ...state,
      selectedEvent: { ...state.selectedEvent, revenueCustomers: action.payload || [] },
    }),
    setPublishedEventData(state, { payload }: { payload: any }) {
      state.publishedEvent = payload
    },
    setisPublished(state, { payload }: { payload: any }) {
      state.isPublished = payload
    },
    setSplitPaymentRecipient(state, { payload }: { payload: IRecipient }) {
      state.splitPayment.currRecipient = payload
    },
    setSplitPaymentRegistration(state, { payload }: { payload: TEventRegisteredUsers }) {
      state.splitPayment.registration = payload
    },
    setEvents(state, { payload }: { payload: IEventWithDetails[] }) {
      state.events.data = payload
    },
    setFilteredEvents(state, { payload }: { payload: IEventWithDetails[] }) {
      state.filteredEvents = payload
    },
    setOrganizerFilteredEvents(state, { payload }: { payload: EventModel[] }) {
      state.organizer.filteredEvents = payload
    },
    setEventMailSend(state, { payload }: { payload: IEventReviewPublish['EventStaff'] }) {
      state.selectedEvent.EventStaff = payload
    },
    resetSelectedEventAc(state) {
      state.selectedEvent = initialSystemEventsState.selectedEvent
    },
    updateSplitPayment(state, { payload }: { payload?: boolean }) {
      let splitPayment = state.draft.draft?.reviewAndPay.splitPayment

      if (state.draft.draft) {
        if (payload) state.draft.draft.reviewAndPay.splitPayment = payload
        else state.draft.draft.reviewAndPay.splitPayment = !splitPayment
      }

      if (payload) state.registerEvents.reviewAndPay.splitPayment = payload
      else state.registerEvents.reviewAndPay.splitPayment = !splitPayment
    },
    setRecipients(state, { payload }: { payload: IRecipient[] }) {
      if (state.draft.draft) state.draft.draft.reviewAndPay.recipients = payload
      state.registerEvents.reviewAndPay.recipients = payload
    },
    setMemberRecipient(
      state,
      {
        payload,
      }: {
        payload: {
          memberIndex: number
          recipient: IRecipient
          oneDayRegistrationIndex: number
        }
      }
    ) {
      const { memberIndex, recipient, oneDayRegistrationIndex } = payload
      state.registerEvents.events[oneDayRegistrationIndex].members[memberIndex].recipient =
        recipient
    },
    setFeesRecipient(
      state,
      {
        payload,
      }: {
        payload: {
          feesIndex: number
          recipient: IRecipient
        }
      }
    ) {
      state.registerEvents.fees[payload.feesIndex].recipient = payload.recipient
    },
    setTicketsRecipient(
      state,
      {
        payload,
      }: {
        payload: {
          ticketIndex: number
          recipient: IRecipient
        }
      }
    ) {
      state.registerEvents.tickets[payload.ticketIndex].recipient = payload.recipient
    },
    setOrganizerEvents(state, { payload }: { payload: EventModel[] }) {
      state.organizer.events = payload
    },
    setSelectedEvent(state, { payload }: { payload: ISelectedEvent }) {
      state.selectedEvent = payload
    },

    setEditedEvent(state, { payload }: { payload: IEventInterface }) {
      state.editedEvent = payload
    },
    setRegisterEventData(state, { payload }) {
      if (payload?.reset === true)
        state.registerEvents = { ...initialSystemEventsState.registerEvents }
      else state.registerEvents = payload
    },
    setSelectedEventKey(
      state,
      { payload }: { payload: { key: keyof ISelectedEvent; value: any } }
    ) {
      if (!payload.key) return
      state.selectedEvent[payload.key] = payload.value
    },
    setRegisterTeamMemberData(state, { payload }) {
      state.registerEvents.teamMembers = payload
    },
    setRegisterPaperworkData(state, { payload }) {
      state.registerEvents.paperwork = payload
    },
    setRegisterSignTab(state, { payload }) {
      state.registerEvents.sign = payload
    },
    setRegisterHorseData(state, { payload }) {
      if (payload && Array.isArray(payload)) state.registerEvents.horses = payload
      else state.registerEvents.horses = [...state.registerEvents.horses, payload]
    },
    setBasicEventDetails(state, { payload }: { payload: ISelectedEvent['basicEventDetails'] }) {
      state.selectedEvent.basicEventDetails = payload
    },
    setEventTabs(state, { payload }) {
      state.tabs = payload
    },
    setEventFilter(state, { payload }) {
      state.filterEvents = payload
    },
    setEventSteps(state, { payload }: { payload: { detailPage: boolean; step: number } }) {
      state.eventSteps.detailPage = payload.detailPage
      state.eventSteps.step = payload.step
    },
    setDraft(state, { payload }: { payload: IEventDraftInterface }) {
      state.draft = payload
    },
    setLoading(state, { payload }) {
      state.loading = payload
    },
    setRegisterRevenueValue(state, { payload }) {
      state.paymentDetails.registrations = payload.regitration
      state.paymentDetails.totalRevenue = payload.totalRevenue
      state.paymentDetails.completeRevenue = payload.completeRevenue
    },
    setSignatores(state, { payload }: { payload: ITeamMember[] }) {
      state.registerEvents.signatories = payload
    },
    updateEventHorse(state, { payload }) {
      // Runs when index in not provided
      if (typeof payload?.index !== 'number') {
        let horseIndex
        if (typeof horseIndex === 'number') {
          state.registerEvents.horses[horseIndex] = payload.data
        }
      }
      // Runs when index is provided
      else {
        state.registerEvents.horses[payload.index] = payload.data
      }
    },
    setScratchEvents(state, { payload }: { payload: IScratch['events'] }) {
      state.scratch.events = payload
    },
    setScratchFees(state, { payload }: { payload: IScratch['fees'] }) {
      state.scratch.fees = payload
    },
    setScratchTickets(state, { payload }: { payload: IScratch['tickets'] }) {
      state.scratch.tickets = payload
    },
    setScratchEventId(state, { payload }: { payload: IScratch['eventId'] }) {
      state.scratch.eventId = payload
    },
    setScratchRegistrationId(state, { payload }: { payload: IScratch['registrationId'] }) {
      state.scratch.registrationId = payload
    },
    setScratchType(state, { payload }: { payload: IScratch['scratchType'] }) {
      state.scratch.scratchType = payload
    },
    resetScratchItems(state) {
      state.scratch = {
        ...initialSystemEventsState['scratch'],
        eventId: state.scratch.eventId,
        registrationId: state.scratch.registrationId,
      }
    },
    setScratchViewVisibility(state, { payload }: { payload: boolean }) {
      state.scratch.scratchViewVisibility = payload
    },
    setExhibitorTeamData(state, { payload }: { payload: any }) {
      state.exhibitor_team.loading =
        payload.loading !== undefined ? payload.loading : state.exhibitor_team.loading
      state.exhibitor_team.member_data = payload.member_data
        ? payload.member_data
        : state.exhibitor_team.member_data
      state.exhibitor_team.resend_email = payload.resend_email
        ? payload.resend_email
        : state.exhibitor_team.resend_email
      state.exhibitor_team.document_data = payload.document_data
        ? payload.document_data
        : state.exhibitor_team.document_data
    },
    setGetEventFetchMoreStatus(state, { payload }: { payload: IReduxStatus }) {
      state.events.fetchMoreStatus = payload
    },
    resetEvents(state) {
      state.events = initialSystemEventsState.events
    },
  },
  extraReducers(builder) {
    builder.addCase(getEventsThunk.pending, (state, action) => {
      if (action.meta.arg.fetchMore) {
        state.events.fetchMoreStatus = 'loading'
      } else {
        state.events.status = 'loading'
      }
    })
    builder.addCase(getEventsThunk.fulfilled, (state, { payload }) => {
      const events = current(state).events.data

      state.events.message = payload.message
      state.events.status = 'fulfilled'
      state.events.fetchMoreStatus = 'fulfilled'
      state.events.data = payload.events

      if (payload.fetchMore) {
        state.events.data = [...events, ...payload.events]
      } else {
        state.events.data = payload.events
      }

      state.events.hasMore = payload.hasMore
      state.events.cursorId = payload.cursorId
      state.events.followingCursorId = payload.followingCursorId
    })
    builder.addCase(getEventsThunk.rejected, (state, action) => {
      if ((action.payload as any).code === 'ERR_CANCELED') {
        state.events.message = action.error.message ?? 'Recieved other request'

        if (action.meta.arg.fetchMore) {
          state.events.fetchMoreStatus = 'loading'
        } else {
          state.events.status = 'loading'
        }
      } else {
        state.events.message = action.error.message ?? null

        if (action.meta.arg.fetchMore) {
          state.events.fetchMoreStatus = 'failed'
        } else {
          state.events.status = 'failed'
        }

        toastFunctionsObj.error({
          message: state.events.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })
      }

      state.events.hasMore = false
    })
  },
})

export const {
  setRevenueCustomers,
  setDraft,
  setEvents,
  setLoading,
  setEventTabs,
  setEventSteps,
  setSignatores,
  resetEvents,
  setRecipients,
  setEventFilter,
  setScratchType,
  setScratchFees,
  setEditedEvent,
  updateEventHorse,
  setScratchEvents,
  setSelectedEvent,
  setFeesRecipient,
  resetSelectedEventAc,
  setScratchEventId,
  resetScratchItems,
  setScratchTickets,
  setFilteredEvents,
  setOrganizerEvents,
  setMemberRecipient,
  setRegisterSignTab,
  updateSplitPayment,
  setTicketsRecipient,
  setSelectedEventKey,
  setRegisterEventData,
  setBasicEventDetails,
  setRegisterHorseData,
  setExhibitorTeamData,
  setPublishedEventData,
  setisPublished,
  setRegisterRevenueValue,
  setRegisterPaperworkData,
  setEventMailSend,
  setSplitPaymentRecipient,
  setScratchRegistrationId,
  setRegisterTeamMemberData,
  setScratchViewVisibility,
  setOrganizerFilteredEvents,
  setSplitPaymentRegistration,
} = eventSlice.actions

export const selectScratch = (state: RootState) => state?.events.scratch
export const selectEditedEvent = (state: RootState) => state?.events.editedEvent
export const selectEventBeingEdit = (state: RootState) => state?.events.editEvent
export const selectEventLoading = (state: any): string[] => state?.events.loading
export const selectEventSelectedTab = (state: any): string[] => state?.events.tabs
export const selectOrganizerEvents = (state: RootState) => state?.events?.organizer
export const selectEditedEventID = (state: RootState) => state?.events.editedEvent?.id
export const selectedEvent = (state: RootState) => state?.events?.selectedEvent ?? null
export const selectFilterEvent = (state: RootState) => state?.events.filterEvents ?? {}
export const selectEvents = (state: RootState) => state?.events.events
export const selectSplitPaymentDetails = (state: RootState) => state?.events.splitPayment
export const selectRecipient = (state: RootState) => state.events.splitPayment.currRecipient
export const selectPublishedEvent = (state: RootState) => state?.events.publishedEvent ?? null
export const ispublished = (state: RootState) => state?.events.isPublished ?? false
export const selectDraft = (state: RootState): IEventDraftInterface => state?.events?.draft ?? {}
export const selectEventSteps = (state: RootState): EventSteps => state?.events?.eventSteps ?? {}
export const selectpaymentDetailsValues = (state: RootState): any => state?.events?.paymentDetails
export const selectEventDetails = (state: RootState): any =>
  state?.events?.selectedEvent?.EventDetails
export const selectSignTab = (state: RootState): IPaperworkTab => state?.events?.registerEvents.sign
export const selectFilteredEvents = (state: RootState): IEventInterface[] =>
  state?.events.filteredEvents
export const selectSplitPayment = (state: RootState) =>
  state.events.registerEvents.reviewAndPay.splitPayment
export const selectRegisterEventData = (state: RootState): IEventsRegisterData =>
  state?.events?.registerEvents ?? {}
export const selectEventPaymentSettings = (state: RootState) =>
  state?.events?.selectedEvent?.EventPaymentSettings
export const selectRegisterFormDataEvents = (state: RootState): IEventDetailData[] =>
  state?.events?.registerEvents.events
export const selectRegisterEventHorseData = (state: RootState): IHorseData[] =>
  state?.events?.registerEvents.horses ?? []
export const selectRegisterEventTeamMembers = (state: RootState): ITeamMember[] =>
  state?.events?.registerEvents.teamMembers ?? []
export const selectRegisterEventPaperworksData = (state: RootState): IPaperworkTab =>
  state?.events?.registerEvents.paperwork ?? {}
export const selectBasicEventDetails = (state: RootState): ISelectedEvent['basicEventDetails'] =>
  state?.events.selectedEvent.basicEventDetails

export default eventSlice.reducer
