import { useEffect, useRef, useState } from 'react'

// Third party
import { yupResolver } from '@hookform/resolvers/yup'
import { Autorenew, AutorenewRounded, CameraAlt, HighlightOff } from '@mui/icons-material'
import { useForm } from 'react-hook-form'

// Models
import { UserModel } from '../../../../models/users/user.model'

// Services
import FirestoreService from '../../../../services/firestoreService'
import FirebaseStorageService from '../../../../services/storageService'
import UserService from '../../../../services/userService'

// Constants
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'

// Styles
import './styles/ProfileRootPageProfileTab.css'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectCompetitorProfileData,
  selectUserId,
  setCompetitorProfileData,
} from '../../../../store/user/userSlice'

// Schemas

// Helpers
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { getConvertedData } from '../../../../models/interface.helper'

// Redux
import { RootState } from '../../../../store/store'
import { setProfileDetails } from '../../../../store/user/userSlice'

// Custom hooks
import useCompetitorProfileHook from '../../../../hooks/users/competitor/profile/useCompetitorProfileHook'
import useProfileHook from '../../../../hooks/users/competitor/profile/useProfileHook'

// Utils
import MessageHelperComp from '../../../../helpers/MessageHelper'

// Models

// Types

// Components
import helpers, { asyncWhileLoop, fileObjToBase64 } from '../../../../commonHelpers/helpers'
import { IUserInterface } from '../../../../models/users/user.interface'
import PhoneInput from '../../../common/inputs/PhoneInput'
import GlobalInputComponent from '../../../events/forms/detail/Components/GlobalInputComponent'

import { updateProfile } from 'firebase/auth'
import { cloneDeep } from 'lodash'
import { getUserFullName } from '../../../../helpers/helpers'
import TimeLib from '../../../../lib/Time'
import ImageContainer from '../../../../pages/sponsor-and-vendor/components/body/tabs/news/ImageContainer'
import FirebaseApp from '../../../../services/firebaseApp'
import { vendorProfileSchema } from '../../../../validations'
import formFunctions from '../../../ui/form/form-functions/formFunctions'
import FormHeader from '../../../ui/form/form-header/FormHeader'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants

export const PROFILE_IMAGE_TYPES_CONST = {
  FOREIGN_ENDORESEMENT_LETTER: 'foreignEndorsementLetter',
  PROFILE_PIC_COMPETITOR: 'profilePicCompetitor',
}

// Types

type ICompetitorProfileRootPageProfileTabProps = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}

type IOnChangeArgs = {
  length: number
  isValid: boolean
  phoneNumber: string
  countryCode: string
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const VendorProfileRootPageProfileTab = (props: ICompetitorProfileRootPageProfileTabProps) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const fileInputRef = useRef() as any
  const toastFunctions = useToasterHelper()
  const userId = useAppSelector(selectUserId)
  const errorRef = useRef({ isValid: true, message: '' })
  const phoneInputRef = useRef<HTMLDivElement | null>(null)
  const competitiorProfileData_ = useAppSelector(selectCompetitorProfileData)
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState<IUserInterface | null>(null)
  const [imgLoading, setImgLoading] = useState(false)
  const [imgFiles, setimgFiles] = useState<any[]>([])
  const [saved, isSaved] = useState(true)
  const { updateUserDetails, getModelledUserDetails } = useProfileHook({})
  const { getModelledCompetitorProfileData, updateCompetitorProfile } = useCompetitorProfileHook({})

  const formStyles = formFunctions.getFormStyles({ noPaddingRt: true })
  const userReducer = useAppSelector((state: RootState) => state.user)

  const { profileDetails, competitorProfileData } = userReducer

  const {
    reset,
    watch,
    control,
    register,
    setError,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserInterface>({
    mode: 'onChange',
    resolver: yupResolver(vendorProfileSchema),
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!userData) {
      const getUserData = async () => {
        let user_exists = false
        let user_doc = null

        while (!user_exists) {
          user_doc = await UserService.getUserInfoById(userId)
          user_exists = user_doc.exists()
        }
        reset({
          ...UserModel.fromFirestoreDoc(user_doc).toObject(),
        })
        setUserData(UserModel.fromFirestoreDoc(user_doc).toObject())
      }
      getUserData().then()
    }
  }, [competitorProfileData, profileDetails, reset, userData, userId])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const phoneOnChange = (args: IOnChangeArgs) => {
    let { phoneNumber, countryCode } = args
    setValue('userPhoneCode', countryCode)
    setValue('userPhoneNumber', phoneNumber)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onSubmit = async (data: any) => {
    const savedUrls: string[] = []
    const ImageUrls: string[] = []

    if (!errorRef.current.isValid) {
      setError('userPhoneNumber', {
        message: errorRef.current.message,
      })

      phoneInputRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })

      return 0
    }

    setLoading(true)

    try {
      if (data.userBrandVideos.length) {
        await asyncWhileLoop({
          loopCount: data.userBrandVideos?.length,
          functionToFire: async (index: any) => {
            const currVideo = data.userBrandVideos[index]
            if (typeof currVideo !== 'string') {
              let downloadUrl = (await FirebaseStorageService.uploadFile(
                currVideo,
                `${CONST.DATA.STORAGE.USERS.COMPETITOR_PROFILE_IMAGE_URL.PREFIX}/${data.username}/videos/${index}`
              )) as string
              savedUrls.push(downloadUrl)
            } else {
              savedUrls.push(currVideo)
            }
          },
        })
      }

      if (imgFiles.length) {
        await asyncWhileLoop({
          loopCount: imgFiles?.length,
          functionToFire: async (index: any) => {
            try {
              const currImage = imgFiles[index]
              let downloadUrl = (await FirebaseStorageService.uploadFile(
                currImage,
                `${CONST.DATA.STORAGE.USERS.COMPETITOR_PROFILE_IMAGE_URL.PREFIX}/images/${index}`
              )) as string
              ImageUrls.push(downloadUrl)
            } catch (error) {
              console.log({ error })
            }
          },
        })
      }
      data.userBrandVideos = savedUrls
      if (ImageUrls.length) {
        const removebase64 = data.userBrandImages.filter((value: any) => {
          return value.includes('https://firebasestorage.googleapis.com')
        })
        const mix = [...removebase64, ...ImageUrls]
        data.userBrandImages = mix
      }
      let profileDetails = new UserModel(data).toObject()
      profileDetails = getConvertedData(profileDetails)

      const dataToThrow = getModelledUserDetails({
        ...userReducer,
        ...profileDetails,
      })
      if (FirebaseApp.auth.currentUser) {
        await updateProfile(FirebaseApp.auth.currentUser, {
          displayName: getUserFullName(profileDetails),
          photoURL: profileDetails.userProfilePicture ?? null,
        })
      }

      await updateUserDetails(dataToThrow)

      // Updating competitor profile data
      const dataToUpdate = getModelledCompetitorProfileData({
        ...competitiorProfileData_,
        competitorOwnerId: userId,
      })

      if (userId) {
        dataToUpdate.competitorOwnerId = userId
        dispatch(setCompetitorProfileData(dataToUpdate))
        await updateCompetitorProfile(dataToUpdate)
      }

      dispatch(setProfileDetails(profileDetails))

      toastFunctions.success({ message: MESSAGES_CONST.PROFILE_UPDATED })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setLoading(false)
      isSaved(true)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const updateProfilePic = async (event: any) => {
    const currFormValues = watch()
    const files = event.target.files
    const file = files[0]
    const PATH = CONST.DATA.STORAGE.USERS.COMPETITOR_PROFILE_IMAGE_URL.PREFIX

    if (!file) return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })

    setImgLoading(true)

    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(
        file,
        `${PATH}/${userId}`,
        (percent: number) => {
          if (0) console.log(percent)
        }
      )

      const newUserData = new UserModel({
        ...userData,
        ...currFormValues,
      })

      newUserData.userProfilePicture = (downloadUrl ?? '') as string

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        newUserData.id,
        {
          userProfilePicture: newUserData.userProfilePicture ?? null,
          userModified: TimeLib.utcTimestamp(),
        }
      )

      setUserData(newUserData)
      setValue('userProfilePicture', newUserData.userProfilePicture)
      toastFunctions.success({ message: MESSAGES_CONST.PIC_UPDATED })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setImgLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const utils = {
    allowCharOnly: (e: any) => e.target.value,
    // allowCharOnly: (e: any) => e.target.value = e.target.value.replaceAll(/[^a-zA-Z]/gi, "")
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param place Details got from React auto complete library
   * @info Sets from values according to the details got from third party lib
   */
  const handleLocation = (values: {
    userLat: any
    userLong: any
    userState: any
    userNationality: string
  }) => {
    let stateTypes = ['administrative_area_level_1']
    let values_: any = cloneDeep(values)
    let addressComponents: any =
      values_ && Array.isArray(values_?.address_components) ? values_.address_components : []

    let state = addressComponents.find((currAddComponent: any) => {
      return (
        currAddComponent.types.includes(stateTypes[0]) ||
        currAddComponent.types.includes(stateTypes[1])
      )
    })

    state = state?.long_name || state?.short_name

    reset({
      ...getValues(),
      userState: state ?? '',
      userLat: values?.userLat ?? '',
      userLong: values?.userLong ?? '',
    })
  }

  const onInputChange = async (files: FileList | null) => {
    const base64ImageUrls: string[] = []

    if (!files) return

    const files_ = Object.keys(files).map((currKey: any) => {
      return files[currKey]
    })

    setimgFiles([...imgFiles, ...files_])

    await helpers.asyncWhileLoop({
      loopCount: files_.length,
      functionToFire: async (index) => {
        const result = await fileObjToBase64(files[index])
        base64ImageUrls.push(result as string)
      },
    })
    if (watch('userBrandImages')) {
      setValue('userBrandImages', [...(watch('userBrandImages') ?? [])!, ...base64ImageUrls])
    }

    setValue('files' as any, [...(watch('files' as any) ?? []), ...files_])
  }

  const handleVideoUpload = async (e: any) => {
    if (e?.target?.files?.[0]) {
      setValue('userBrandVideos', [...(watch('userBrandVideos') ?? [])!, e?.target?.files?.[0]])
    }
  }

  const onRemoveImageButtonClick = (index: number) => {
    setValue(
      'userBrandImages',
      (function () {
        return (watch('userBrandImages') ?? [])!.filter((_, index_) => {
          return index_ !== index
        })
      })()
    )
  }

  const RemoveVideos = async (index: number, currUrl: any) => {
    setValue(
      'userBrandVideos',
      (function () {
        return (watch('userBrandVideos') ?? [])!.filter((_, index_) => {
          return index_ !== index
        })
      })()
    )
    if (typeof currUrl === 'string') {
      await FirebaseStorageService.deleteFile(currUrl)
    }
  }

  return (
    <>
      <FormHeader
        title="Profile settings"
        description="Update your company profile"
        headerButtonsContainer={
          <div className="flex items-center gap-2">
            {saved ? (
              <button
                type="button"
                onClick={() => isSaved(false)}
                className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
              >
                Edit
              </button>
            ) : (
              <button
                type="button"
                disabled={loading}
                onClick={handleSubmit(onSubmit)}
                className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
              >
                {loading ? (
                  <AutorenewRounded fontSize="small" className="animate-spin" />
                ) : (
                  'Save changes'
                )}
              </button>
            )}
          </div>
        }
      />

      <div className={formStyles.className} style={formStyles.styles}>
        <div className="relative pb-12 w-full text-SeabiscuitDark200ThemeColor lg:!text-nr 2xl:!text-base profile_tab">
          <div className="grid gap-3">
            {/* Profile Picture */}
            <div className="flex flex-wrap items-center">
              <label className=" w-1/4 flex">
                <img src="/assets/og_icons/Department Shop-1.svg" alt="imgIcon" className="mr-2" />
                Business Logo
              </label>

              <div className="relative p-1 rounded-full cursor-pointer flex items-center justify-center w-[75px] h-[75px] bg-[#F0F3FA] ml-[18px]">
                <input
                  type="file"
                  disabled={imgLoading || saved}
                  accept=".jpeg, .png, .jpg"
                  ref={fileInputRef}
                  onChange={(e: any) => updateProfilePic(e)}
                  className="UserProfilePicture absolute h-full w-full opacity-0 left-0 right-0 top-0 bottom-0 z-40 cursor-pointer"
                />
                {imgLoading ? (
                  <Autorenew className="animate-spin" />
                ) : (
                  <img
                    src={
                      !!!userData?.userProfilePicture
                        ? '/assets/og_icons/Department Shop.svg'
                        : userData?.userProfilePicture
                    }
                    className="object-cover rounded-full h-full w-full"
                    alt="profile_picture"
                  />
                )}

                {!saved ? (
                  <div className="bg-[#bec6dd] p-1.5 rounded-full absolute right-0 bottom-0">
                    <CameraAlt className="text-white text-xs" />
                  </div>
                ) : null}
              </div>
            </div>

            <hr className="mt-2 mb-8" />

            {/* User Personal Details */}

            <div className="flex flex-wrap items-center">
              <label className="w-1/4 flex items-center mr-2">
                <img
                  src="/assets/og_icons/Department Shop-1.svg"
                  alt="userIcons"
                  className="mr-2"
                />
                Business name
              </label>
              <div className="w-2/4 max-w-[520px] min-w-[520px]">
                <input
                  type="text"
                  {...register('userBusinessName', {
                    onChange: (e) => utils.allowCharOnly(e),
                  })}
                  disabled={saved}
                  placeholder="Enter name of organization..."
                  className={` w-full border rounded-xl !ring-0 p-2.5 mr-2 h-[51px] ${
                    saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'
                  }`}
                />
                {errors?.userName?.message && (
                  <MessageHelperComp isError={true} message={errors.userName.message} />
                )}
              </div>
            </div>

            <div className="flex flex-wrap items-center">
              <label className="w-1/4 flex items-center mr-2">
                <img
                  src="/assets/og_icons/Department Shop-1.svg"
                  alt="userIcons"
                  className="w-6 h-6 mr-2"
                />
                Business Category
              </label>
              <div className="w-2/4  max-w-[520px] min-w-[520px]">
                <input
                  type="text"
                  {...register('userCategory', {
                    onChange: (e) => utils.allowCharOnly(e),
                  })}
                  disabled={saved}
                  placeholder="Enter Business Category"
                  className={`w-full border !border-[#D3DAEE] rounded-xl p-3 h-[51px] !ring-0 ${
                    saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'
                  }`}
                />
                {errors?.userCategory?.message && (
                  <MessageHelperComp isError={true} message={errors.userCategory.message} />
                )}
              </div>
            </div>

            <div className="flex flex-wrap items-center">
              <label className="w-1/4 flex items-center mr-2">
                <img src="/assets/og_icons/Location-1.svg" className="w-6 h-6 mr-2" alt="address" />
                Location
              </label>
              <div className="w-2/4  max-w-[520px] min-w-[520px]">
                <GlobalInputComponent
                  type="location"
                  placeholder="Line 1"
                  onChange={handleLocation}
                  value={watch('userAddress') ?? ''}
                  disabled={saved}
                  className={`bg-[#0000] rounded-xl !py-[7px] border pl-0 ${
                    saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'
                  }`}
                  inputName="userAddress"
                  control={control}
                  register={register}
                />

                {errors?.userAddress?.message && (
                  <MessageHelperComp isError={true} message={errors.userAddress.message} />
                )}
              </div>
            </div>

            <div className="flex flex-wrap items-center">
              <label className="w-1/4 flex items-center mr-2">
                <img src="/assets/cp_icons/Phone.svg" className="w-7 h-7 mr-2" alt="phone" />
                Contact number
              </label>
              <div className="w-2/4  max-w-[520px] min-w-[520px]">
                <PhoneInput
                  ref={phoneInputRef}
                  name="userPhoneNumber"
                  onChange={phoneOnChange}
                  saved={saved}
                  setValue={setValue}
                  countryCode={watch('userPhoneCode') ?? ''}
                  phoneNumber={watch('userPhoneNumber') ?? ''}
                />

                {errors?.userPhoneNumber?.message && (
                  <MessageHelperComp isError={true} message={errors?.userPhoneNumber?.message} />
                )}
              </div>
            </div>

            <div className="flex flex-wrap items-center  ">
              <label className="w-1/4 flex items-center mr-2">
                <img src="/assets/og_icons/Mail-1.svg" className="w-6 h-6 mr-2" alt="nationality" />
                Business email address
              </label>

              <div className="w-2/4  max-w-[520px] min-w-[520px]">
                <input
                  type="text"
                  {...register('userEmail', {
                    onChange: (e) => utils.allowCharOnly(e),
                  })}
                  disabled={saved}
                  placeholder="Enter email address..."
                  className={`w-full border !border-[#D3DAEE] rounded-xl p-3 h-[51px] !ring-0 ${
                    saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'
                  }`}
                />
                {errors?.userEmail?.message && (
                  <MessageHelperComp isError={true} message={errors.userEmail.message} />
                )}
              </div>

              {(errors as any)?.userNationality?.message &&
                typeof (errors as any)?.userNationality?.message === 'string' && (
                  <MessageHelperComp
                    isError={true}
                    message={(errors.userNationality as any).message}
                  />
                )}
            </div>

            <hr className="my-4"></hr>

            {/* Zone Details */}

            <div className="flex flex-wrap items-center">
              <label className="w-1/4 flex items-center mr-2">
                {' '}
                <img className="mr-2" src="/assets/og_icons/Regular Document-1.svg" alt="horse" />
                About
              </label>

              <div className="w-2/4  max-w-[520px] min-w-[520px]">
                <textarea
                  rows={3}
                  {...register('userDescription', {
                    onChange: (e) => utils.allowCharOnly(e),
                  })}
                  disabled={saved}
                  placeholder="Share your organizations story..."
                  className={`w-full border !border-[#D3DAEE] rounded-xl p-3 resize-none !ring-0 ${
                    saved ? '!border-white text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'
                  }`}
                />
                {errors?.userDescription?.message && (
                  <MessageHelperComp isError={true} message={errors.userDescription.message} />
                )}
              </div>
              {(errors as any)?.userDiscipline?.message &&
                typeof (errors as any)?.userNationality?.message === 'string' && (
                  <MessageHelperComp
                    isError={true}
                    message={(errors.userDiscipline as any).message}
                  />
                )}
            </div>

            <hr className="my-4"></hr>

            {/* Brand Images */}
            <div className="flex flex-wrap items-center">
              <label className="w-1/4 flex items-center mr-2">
                <img src="/assets/images.svg" alt="userIcons" className="mr-2" />
                Brand Images
              </label>
              <div className="w-2/4 min-w-[520px] max-w-[520px]">
                <ImageContainer
                  removable={true}
                  disabled={saved}
                  className={`${
                    watch('userBrandImages')?.length ? '!justify-between' : '!justify-center'
                  } ${saved ? '!border-none' : ''}`}
                  onInputChange={onInputChange}
                  onRemoveImageButtonClick={onRemoveImageButtonClick}
                  values={(watch('userBrandImages') ?? [])!.filter((image) => !!image)}
                />

                {errors?.userBrandImages?.message && (
                  <MessageHelperComp isError={true} message={errors.userBrandImages.message} />
                )}
              </div>
            </div>

            <div className="flex flex-wrap items-center">
              <label className="w-1/4 flex items-center mr-2">
                <img src="/assets/video.svg" alt="userIcons" className="mr-2" />
                Brand Videos
              </label>
              <div className="w-2/4 min-w-[520px] max-w-[520px]">
                <div
                  className={`flex items-center flex-wrap  rounded-md border-SeabiscuitLightThemeColorD3 border-[1px] h-[60px] justify-center ${
                    saved ? '!border-none' : '!border-solid  border-[1px]'
                  }`}
                >
                  {(watch('userBrandVideos') ?? [])?.length
                    ? (watch('userBrandVideos') ?? [])?.map((currUrl: string, index: number) => {
                        return (
                          <div className="imageThumbnail m-1" key={`${currUrl}${index}`}>
                            <div className="image-preview relative rounded-md w-[40px] h-[40px] overflow-hidden z-[0]">
                              <img
                                src="/assets/placeholders/video.svg"
                                className="image shrink-0 object-cover w-full h-full"
                                alt="images"
                              />
                              <button
                                type="button"
                                onClick={() => RemoveVideos(index, currUrl)}
                                className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer hover:opacity-100 z-[1] transition-all"
                              >
                                <HighlightOff className="text-SeabiscuitMainThemeColor" />
                              </button>
                            </div>
                          </div>
                        )
                      })
                    : null}

                  <label
                    className={`relative w-[138px]  ${
                      !(watch('userBrandVideos') ?? []).length ? 'w-[138px]' : 'w-[40px]'
                    } h-[26px] flex items-center justify-center m-1 cursor-pointer`}
                    htmlFor="videoUpload"
                    // disabled={saved}
                  >
                    <span className="bg-gray-100 rounded-lg p-3 text-gray-500 w-[35px] h-[44px]">
                      +
                    </span>
                    {!(watch('userBrandVideos') ?? []).length && (
                      <div
                        className={`text-[#8590a1] pl-2 ${
                          !(watch('userBrandVideos') ?? []).length ? 'w-[85%]' : 'w-[30%]'
                        }`}
                      >
                        Add Videos
                      </div>
                    )}
                  </label>
                </div>

                {errors?.userBrandImages?.message && (
                  <MessageHelperComp isError={true} message={errors.userBrandImages.message} />
                )}
              </div>
            </div>

            <div>
              <div className="">
                <input
                  type="file"
                  disabled={saved}
                  name="videoUpload"
                  id="videoUpload"
                  accept=".mp4"
                  onChange={(e) => handleVideoUpload(e)}
                  className=" w-[40px] h-[40px] left-0 top-0 opacity-0 cursor-pointer hover:opacity-100 z-[1] transition-all hidden"
                />
              </div>
            </div>

            <hr className="my-4"></hr>

            {/* Website */}
            <div className="flex flex-wrap items-center">
              <label className="w-1/4 flex items-center mr-2">
                <img src="/assets/cp_icons/Globe-1.svg" alt="userIcons" className="mr-2" />
                Website
              </label>
              <div className="w-2/4 min-w-[520px] max-w-[520px]">
                <input
                  type="text"
                  {...register('websiteUrl')}
                  disabled={saved}
                  placeholder="https://www...."
                  className={`w-full border !border-[#D3DAEE] placeholder:text-gray-500 rounded-xl !ring-0 p-2.5 mr-2 h-[51px] ${
                    saved
                      ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-gray-500'
                      : '!border-[#D3DAEE]'
                  }}`}
                />
                {errors?.websiteUrl?.message && (
                  <MessageHelperComp isError={true} message={errors.websiteUrl.message} />
                )}
              </div>
            </div>

            {/* Facebook */}
            <div className="flex flex-wrap items-center">
              <label className="w-1/4 flex items-center mr-2">
                <img src="/assets/img/Facebook.png" alt="userIcons" className="mr-2 max-w-[24px]" />
                Facebook
              </label>
              <div className="w-2/4  min-w-[520px] max-w-[520px]">
                <input
                  type="url"
                  {...register('userFacebook')}
                  disabled={saved}
                  placeholder="https://www.facebook.com/..."
                  className={`w-full border !border-[#D3DAEE] rounded-xl placeholder:text-gray-500 !ring-0 p-2.5 mr-2 h-[51px] ${
                    saved
                      ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-gray-500'
                      : '!border-[#D3DAEE]'
                  }}`}
                />
                {errors?.userFacebook?.message && (
                  <MessageHelperComp isError={true} message={errors.userFacebook.message} />
                )}
              </div>
            </div>

            {/* Instagram */}
            <div className="flex flex-wrap items-center">
              <label className="w-1/4 flex items-center mr-2">
                <img
                  src="/assets/img/Instagram.png"
                  alt="userIcons"
                  className="mr-2 max-w-[24px]"
                />
                Instagram
              </label>
              <div className="w-2/4  min-w-[520px] max-w-[520px]">
                <input
                  type="text"
                  {...register('userInstagram')}
                  disabled={saved}
                  placeholder="https://www.instagram.com/..."
                  className={`w-full border !border-[#D3DAEE] rounded-xl placeholder:text-gray-500 !ring-0 p-2.5 mr-2 h-[51px] ${
                    saved
                      ? '!border-white text-gray-500 placeholder:text-gray-500'
                      : '!border-[#D3DAEE]'
                  }}`}
                />
                {errors?.userInstagram?.message && (
                  <MessageHelperComp isError={true} message={errors.userInstagram.message} />
                )}
              </div>
            </div>

            {/* Twitter */}
            <div className="flex flex-wrap items-center">
              <label className="w-1/4 flex items-center mr-2">
                <img
                  src="/assets/og_icons/Twitter-1.svg"
                  alt="userIcons"
                  className="mr-2 max-w-[24px]"
                />
                Twitter
              </label>
              <div className="w-2/4 min-w-[520px] max-w-[520px]">
                <input
                  type="text"
                  {...register('userTwitter')}
                  disabled={saved}
                  placeholder="https://www.twitter.com/..."
                  className={`w-full border !border-[#D3DAEE] rounded-xl placeholder:text-gray-500 !ring-0 p-2.5 mr-2 h-[51px] ${
                    saved
                      ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-gray-500'
                      : '!border-[#D3DAEE]'
                  }}`}
                />
                {errors?.userTwitter?.message && (
                  <MessageHelperComp isError={true} message={errors.userTwitter.message} />
                )}
              </div>
            </div>

            {/* LinkedIn */}
            <div className="flex flex-wrap items-center">
              <label className="w-1/4 flex items-center mr-2">
                <img
                  src="/assets/og_icons/LinkedIn-1.svg"
                  alt="userIcons"
                  className="mr-2 max-w-[24px]"
                />
                LinkedIn
              </label>
              <div className="w-2/40  min-w-[520px] max-w-[520px]">
                <input
                  type="text"
                  {...register('userLinkedin')}
                  disabled={saved}
                  placeholder="https://www.linkedin.com/..."
                  className={`w-full border !border-[#D3DAEE] rounded-xl placeholder:text-gray-500 !ring-0 p-2.5 mr-2 h-[51px] ${
                    saved
                      ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-gray-500'
                      : '!border-[#D3DAEE]'
                  }}`}
                />
                {errors?.userLinkedin?.message && (
                  <MessageHelperComp isError={true} message={errors.userLinkedin.message} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VendorProfileRootPageProfileTab
