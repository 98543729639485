import { useForm } from 'react-hook-form'
import MainModal from '../../../../../../components/modals/common/MainModal'
import { INewsInterface } from '../../../../../../models/news/news.interface'
import { NewsModel } from '../../../../../../models/news/news.model'
import { useEffect, useState } from 'react'
import ExpandableSelector from '../../../../../../components/elements/select-lists/ExpandableSelector'
import Input from '../../../../../../components/common/inputs/Input'
import ImageContainer from './ImageContainer'
import helpers, { fileObjToBase64 } from '../../../../../../commonHelpers/helpers'
import { MODAL_CONSTS } from '../../../../../../const/modal-const'
import { yupResolver } from '@hookform/resolvers/yup'
import MessageHelperComp from '../../../../../../helpers/MessageHelper'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectProfileData } from '../../../../../../store/user/userSlice'
import { getUserFullName } from '../../../../../../helpers/helpers'
import { IUserMode } from '../../../../../../const/use-mode'
import FirestoreService from '../../../../../../services/firestoreService'
import { CONST } from '../../../../../../const/const'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../../../../../const/messages-const'
import FirebaseStorageService from '../../../../../../services/storageService'
import { v4 } from 'uuid'
import { getConvertedData } from '../../../../../../models/interface.helper'

type ICreateAndUpdateNewsModalProps = {
  show: boolean
  dataToPassOn: {
    newsDoc: INewsInterface
    updateNews?: (updatedNews: INewsInterface) => void
  }
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const CreateAndUpdateNewsModal = (props: ICreateAndUpdateNewsModalProps) => {
  const [loading, setLoading] = useState(false)

  const dataToPassOn = props.dataToPassOn
  const toastFunctions = useToasterHelper()
  const profileDetails = useAppSelector(selectProfileData)

  const {
    reset,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: new NewsModel().toObject(),
    resolver: yupResolver(NewsModel.validationSchema()),
  })

  useEffect(() => {
    reset({
      ...watch(),
      ...(props.dataToPassOn.newsDoc ?? {}),
      status: (!props.dataToPassOn.newsDoc?.id ? null : props.dataToPassOn.newsDoc.status) as any,
    })

    setValue('files' as any, props.dataToPassOn.newsDoc?.pictures ?? [])
    setValue('filesToRemove' as any, [])
  }, [props.dataToPassOn.newsDoc, reset, setValue, watch])

  useEffect(() => {
    setValue('creatorId', profileDetails.id ?? null)
    setValue('creatorName', getUserFullName(profileDetails))
    setValue('creatorType', profileDetails.userType as IUserMode)
    setValue('creatorPicture', profileDetails.userProfilePicture ?? null)
  }, [profileDetails, setValue])

  const onInputChange = async (files: FileList | null) => {
    const base64ImageUrls: string[] = []

    if (!files) return

    const files_ = Object.keys(files).map((currKey: any) => {
      return files[currKey]
    })

    await helpers.asyncWhileLoop({
      loopCount: files_.length,
      functionToFire: async (index) => {
        const result = await fileObjToBase64(files[index])
        base64ImageUrls.push(result as string)
      },
    })

    setValue('pictures', [...watch('pictures'), ...base64ImageUrls])
    setValue('files' as any, [...(watch('files' as any) ?? []), ...files_])
  }

  const closeModal = () => {
    props.handleModal(false, MODAL_CONSTS.CREATE_AND_UPDATE_NEWS)
  }

  const onRemoveImageButtonClick = (index: number) => {
    setValue('filesToRemove' as any, [
      ...(watch('filesToRemove' as any) ?? []),
      watch('files' as any)[index],
    ])
    setValue(
      'pictures',
      (function () {
        return watch('pictures').filter((_, index_) => {
          return index_ !== index
        })
      })()
    )
    setValue(
      'files' as any,
      (function () {
        return watch('files' as any).filter((_: any, index_: number) => {
          return index_ !== index
        })
      })()
    )
  }

  const onSubmit = async (data: any) => {
    let filesToSave: File[] = []
    let imageUrls: string[] = []
    let filesToRemove: string[] = []
    let imageUrl: string | null = null
    let docToSave = new NewsModel(data)

    try {
      filesToRemove = watch('filesToRemove' as any).filter((currFile: string | File) => {
        return typeof currFile === 'string' && currFile.startsWith('http')
      })

      filesToSave = watch('files' as any).filter((currFile: any) => {
        return !!currFile?.name
      })

      docToSave.pictures = docToSave.pictures.filter((currPictureUrl) => {
        return currPictureUrl.startsWith('http')
      })

      if (!docToSave.creatorId) {
        throw new Error(`docToSave.creatorId is ${docToSave.creatorId}`)
      }

      setLoading(true)

      if (filesToRemove.length) {
        await helpers.asyncWhileLoop({
          loopCount: filesToRemove.length,
          functionToFire: async (index) => {
            if (typeof filesToRemove[index] === 'string') {
              await FirebaseStorageService.deleteFile(filesToRemove[index])
            }
          },
        })
      }

      if (filesToSave.length) {
        await helpers.asyncWhileLoop({
          loopCount: filesToSave.length,
          functionToFire: async (index) => {
            imageUrl = (await FirebaseStorageService.uploadFile(
              filesToSave[index],
              `user/news/${docToSave.creatorId}/${v4()}`
            )) as string

            if (typeof imageUrl === 'string') {
              imageUrls.push(imageUrl)
            }
          },
        })
      }

      docToSave.pictures = [...docToSave.pictures, ...imageUrls]

      if (!!docToSave.id) {
        await FirestoreService.updateItem(
          COLLECTIONS.NEWS.NAME,
          docToSave.id,
          docToSave.toFirestore()
        )
      } else {
        docToSave.id = (
          await FirestoreService.createItem(COLLECTIONS.NEWS.NAME, docToSave.toFirestore())
        ).id
      }

      toastFunctions.success({
        message: !!dataToPassOn.newsDoc.id
          ? MESSAGES_CONST.NEWS_UPDATED
          : MESSAGES_CONST.NEWS_ADDED,
      })

      dataToPassOn?.updateNews?.(getConvertedData(docToSave.toObject()))

      closeModal()
    } catch (error) {
      console.error(error)
      setLoading(false)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  return (
    <MainModal
      title={!!dataToPassOn?.newsDoc?.id ? 'Update News' : 'Add News'}
      show={props.show}
      type="CREATE_AND_UPDATE_NEWS"
      className="mt-4 !px-0"
      hideCloseButton={loading}
      setHeightAsPerContent={true}
      size="md"
      buttons={[
        {
          label: 'SAVE',
          loading,
          disabled: loading,
          className: 'md:max-w-[174px]',
          bgClass: '!bg-SeabiscuitMainThemeColor',
          onClick: handleSubmit(onSubmit),
          borderClass: 'unset',
          textClass: '!text-white',
        },
        {
          label: 'CANCEL',
          disabled: loading,
          bgClass: '!bg-SeabiscuitLightThemeColor',
          className: 'md:max-w-[174px]',
          borderClass: 'border border-transparent',
          textClass: '!text-SeabiscuitLightTextColor',
          onClick: closeModal,
          onHoverClass: 'hover:shadow-slate-300',
        },
      ]}
    >
      <div className="flex flex-col gap-2">
        <Input
          type="text"
          name="title"
          placeholder="News title..."
          preFixIcon="/assets/cp_icons/Newspaper-1.svg"
          value={watch('title')}
          className="h-[56px] rounded-2xl border-[1px] border-solid !border-SeabiscuitLightThemeColorD3 !text-nr placeholder:!text-nr"
          register={register}
        />

        {!!errors?.title?.message ? (
          <MessageHelperComp message={errors?.title.message} className="mb-1" />
        ) : null}

        <ExpandableSelector
          placeholder="News status..."
          options={[
            { label: 'News status...', value: 'News status...', isPlaceholder: true },
            { label: 'Live', value: 'live' },
            { label: 'Draft', value: 'draft' },
          ]}
          onChange={(status) => setValue('status', status as INewsInterface['status'])}
          value={watch('status')}
          className="!mb-0 !text-nr"
          icon="/assets/og_icons/signalStream-1.svg"
        />

        {!!errors?.status?.message ? <MessageHelperComp message={errors?.status.message} /> : null}

        <Input
          type="text"
          name="headLine"
          placeholder="News head line..."
          preFixIcon="/assets/cp_icons/Newspaper-1.svg"
          value={watch('headLine')}
          className="h-[56px] rounded-2xl border-[1px] border-solid !border-SeabiscuitLightThemeColorD3 !!text-nr placeholder:!text-nr"
          register={register}
        />

        {!!errors?.headLine?.message ? (
          <MessageHelperComp message={errors?.headLine.message} className="mb-1" />
        ) : null}

        <Input
          rows={6}
          name="description"
          type="textarea"
          placeholder="News description..."
          preFixIcon="/assets/cp_icons/Newspaper-1.svg"
          value={watch('description')}
          className="min-h-[56px] rounded-2xl border-[1px] border-solid py-3 !border-SeabiscuitLightThemeColorD3 !text-nr placeholder:!text-nr placeholder:text-SeabiscuitLightParagraphTextColor"
          preFixIconClassName="my-3.5"
          register={register}
        />

        {!!errors?.description?.message ? (
          <MessageHelperComp message={errors?.description.message} className="mb-1" />
        ) : null}

        <ImageContainer
          removable={true}
          className="min-h-[56px]"
          onInputChange={onInputChange}
          onRemoveImageButtonClick={onRemoveImageButtonClick}
          values={watch('pictures').filter((image) => !!image)}
        />

        <Input
          name="url"
          type="text"
          placeholder="Link to news..."
          preFixIcon="/assets/og_icons/Globe-1.svg"
          value={watch('url')}
          className="min-h-[56px] rounded-2xl border-[1px] border-solid py-3 !border-SeabiscuitLightThemeColorD3 !text-nr placeholder:!text-nr"
          preFixIconClassName="my-3.5"
          register={register}
        />

        {!!errors?.url?.message ? (
          <MessageHelperComp message={errors?.url.message} className="mb-1" />
        ) : null}
      </div>
    </MainModal>
  )
}

export default CreateAndUpdateNewsModal
