import { Tooltip } from '@mui/material'
import clsx from 'clsx'

import { useEffect, useRef, useState } from 'react'
import customImageComponent from '../../../../components/common/CustomImageComponent'
import InfiniteScrollDataTable from '../../../../components/common/tables/InfiniteScrollDataTable'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import { IMAGE_CONSTS } from '../../../../const/image-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import { IHandleModal } from '../../../../layout/mainlayout/MainLayout'

import { selectPaidBills, selectUnPaidBills } from '../../../../store/bills/billsSlice'

import { Link } from 'react-router-dom'
import { IEventInterface } from '../../../../models/events/event.interface'
import { IRecipientInterface } from '../../../../models/recipients/recipients.interface'
import { useAppSelector } from '../../../../store/hooks'
import { customStyles } from '../../../allEvents/root/AllEventsTableStyles'
import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'

type IBillsRef = {
  paidBills: IRecipientInterface[]
  unPaidBills: IRecipientInterface[]
  allBills: IRecipientInterface[]
}
// const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS;

// Constants
const imgSrc = '/assets/placeholders/NoBillsFound.svg'

const INITIAL_BILLS_REF_STATE = {
  paidBills: [],
  unPaidBills: [],
  allBills: [],
}

// const LINKS = {
//   MESSAGE_USER: "/messageuser/",
//   REFUND_TO_EXHIBITOR: ROUTES_CONST.EXHIBITOR_REFUND.URL
// }

const COLS = {
  EVENTS: 'Event',
  PAYMENT_STATUS: 'Status',
  AMOUNT: 'Amount',
  REGISTERED: 'Scratched',
  REFUNDED: 'Refunded',
  SCRATCHED: 'Scratched',
  FROM: 'From',
}

type IDataTableItem = {
  eventName: IEventInterface['eventName']
  paymentStatus: string
  amount: number
  scratchedAmount: number
  refundAmount: number
  from: string
  registrationId: string | null
  eventId: string | null
  competitorId: string
  userId: string | null
  isRegistered: boolean
  registrationDocId?: string | null
  recipientId?: string | null
  recipientName?: string | null
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const CompetitorAllBills = ({
  handleModal,
  bills,
  userId,
  activeTab,
  sortstyle,
  dataTableItems_,
  setSortStyle,
}: {
  bills?: IRecipientInterface[]
  handleModal: IHandleModal['handleModal']
  userId: string | null
  activeTab: string
  setSortStyle: React.Dispatch<React.SetStateAction<string>>
  sortstyle: string
  dataTableItems_: IDataTableItem[]
}) => {
  // const [data, setData] = useState<IRevenueRow[]>([]);
  const paidbills = useAppSelector(selectPaidBills)
  const unpaidbills = useAppSelector(selectUnPaidBills)
  // const basicEventDetails = useAppSelector(selectBasicEventDetails);

  const billsRef = useRef<IBillsRef>(INITIAL_BILLS_REF_STATE)

  useEffect(() => {
    billsRef.current = {
      paidBills: paidbills,
      unPaidBills: unpaidbills,
      allBills: [...paidbills, ...unpaidbills],
    }
  }, [paidbills, unpaidbills])

  const placeholderdata =
    activeTab === 'paid'
      ? 'You have not paid any bills yet'
      : activeTab === 'all'
        ? "You don't have any bills"
        : activeTab === 'refunded'
          ? 'You have no refunds yet'
          : 'No unpaid bills availiable yet'

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handlemodelFunc = (tab: string, row: IRecipientInterface) => {
    if (0) console.log({ tab })
    handleModal(true, MODAL_CONSTS.BILL_BRIEFE, { bill: row, setSortStyle, sortstyle })
  }

  const [columns] = useState<any[]>([
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">{COLS.EVENTS}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Event this bill is associated with</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRecipientInterface) => (
        <div className="flex  text-SeabiscuitDark200ThemeColor font-semibold items-center">
          {!!row?.userId && !!row?.userId && !!row?.registrationDocId ? (
            <div className="flex items-center capitalize">
              <Link to={`/events-details/${row.eventId}`} className="cursor-pointer">
                {' '}
                {customImageComponent(
                  (row as any)?.eventLogo ?? IMAGE_CONSTS.PLACEHOLDERS.USER,
                  (row as any).memberName,
                  'w-[51px] h-[51px] p-1 rounded-full mr-3 shrink-0'
                )}
              </Link>
              {row?.eventName}
            </div>
          ) : (
            <>
              <Link to={`/events-details/${row.eventId}`} className="cursor-pointer">
                {customImageComponent(
                  (row as any)?.eventLogo ?? IMAGE_CONSTS.PLACEHOLDERS.USER,
                  (row as any).memberName,
                  'w-[34px] h-[34px] bg-[#F2F3F8] p-1 rounded-full mr-1 shrink-0'
                )}
              </Link>
              {row?.eventName}
            </>
          )}
        </div>
      ),
      width: '34%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">{COLS.PAYMENT_STATUS}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Bill payment status</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRecipientInterface) => (
        <div className="flex w-full items-center">
          <img src="/assets/cp_icons/MagneticCard.svg" className="mr-2" alt="paidStatus" />
          <span
            className="capitalize text-SeabiscuitDark200ThemeColor font-normal max-w-[90px] ellipsis"
            title={row.paymentStatus}
          >
            {row.paymentStatus === 'pending' ? 'Unpaid' : row.paymentStatus}
          </span>
        </div>
      ),
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">{COLS.AMOUNT}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Bill total</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRecipientInterface) => (
        <div className="flex w-full items-center">
          <img src="/assets/cp_icons/MoneyBag.svg" className="mr-2" alt="paidStatus" />
          <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
            ${row.amountPaid?.toFixed(2)}
          </span>
        </div>
      ),
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">{COLS.SCRATCHED}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Total amount scratched</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRecipientInterface) => (
        <div className="flex w-full items-center">
          <img src="/assets/cp_icons/MoneyBag.svg" className="mr-2" alt="paidStatus" />
          <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
            ${row.amountScratched ? row.amountScratched : 0}
          </span>
        </div>
      ),
      width: '12%',
    },

    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">{COLS.REFUNDED}</span>
          <Tooltip
            title={
              <h1 className="tooltip_title">
                Refund status and amount refunded by the event organizer
              </h1>
            }
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRecipientInterface) => (
        <div className="flex w-full items-center">
          <img src="/assets/cp_icons/MoneyBag.svg" className="mr-2" alt="paidStatus" />
          {row?.refundStatus === 'pending' ? (
            <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">Pending</span>
          ) : (
            <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
              ${row.amountRefunded ? row.amountRefunded : 0}
            </span>
          )}
        </div>
      ),
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex ml-1">
          <span className="whitespace-nowrap">{COLS.FROM}</span>
          <Tooltip
            title={<h1 className="tooltip_title">Who sent you this bill to pay</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRecipientInterface) => (
        <div className="flex w-full items-center">
          <img src="/assets/cp_icons/User.svg" className="mr-2" alt="paidStatus" />

          <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
            {row.isPaidByOwner ? 'You' : row.userName}
          </span>
        </div>
      ),
      width: '12%',
    },

    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex">
          <span className="whitespace-nowrap">View</span>
          <Tooltip
            title={<h1 className="tooltip_title">Click to view and pay bill</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#132b46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRecipientInterface) => (
        <span
          className="flex w-full justify-center cursor-pointer"
          onClick={() => handlemodelFunc(activeTab, row)}
        >
          <p className="bg-[#F2F3F8] min-w-[52px] py-3 flex justify-center w-full font-semibold rounded-xl text-SeabiscuitDark200ThemeColor hover:text-SeabiscuitMainThemeColor hover:bg-[#fef5f6] whitespace-nowrap">
            {row.paymentStatus === 'paid' && row.refundStatus === 'pending'
              ? 'View'
              : row.paymentStatus === 'pending' && !(row.refundStatus === 'pending')
                ? 'Pay'
                : 'View'}
          </p>
        </span>
      ),
      width: '6%',
    },
  ])

  return (
    <InfiniteScrollDataTable
      data={bills ?? dataTableItems_}
      hasMore={false}
      columns={columns}
      noTableHead={bills && bills.length ? false : true}
      fixedHeader={true}
      customStyles={customStyles}
      reloadOnBasedOf={activeTab}
      className={clsx('!rounded-none', bills && bills.length ? 'max-h-[100%]' : '')}
      noDataComponent={
        <DataNotAvailable
          mode="graphic"
          imageAlt="no bills"
          upperText={placeholderdata}
          imageSource={imgSrc}
        />
      }
      progressComponent={
        <ViewsLoader
          className="flex items-center w-full justify-center my-10"
          size="md"
          color="#F7074F"
        />
      }
    />
  )
}

export default CompetitorAllBills
