// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import * as React from 'react'
import EventPrizeListFormBuilderUtilDivisionRowAccordionComponent from './EventPrizeListFormBuilderUtilDivisionRowAccordionComponent'
import EventPrizeListFormBuilderUtilDivisionRowAccordionSummaryComponent from './EventPrizeListFormBuilderUtilDivisionRowAccordionSummaryComponent'
import EventPrizeListFormBuilderUtilDivisionRowAccordionDetailsComponent from './EventPrizeListFormBuilderUtilDivisionRowAccordionDetailsComponent'
import EventPrizeListFormBuilderUtilHunterStandardSectionRow from '../section/hunter/standard/EventPrizeListFormBuilderUtilHunterStandardSectionRow'
import { forwardRef, ReactNode, useCallback, useImperativeHandle, useState } from 'react'
import { CONST } from '../../../../../../../../const/const'
import EventPrizeListFormBuilderUtilEquitationSectionRow from '../section/equitation/EventPrizeListFormBuilderUtilEquitationSectionRow'
import EventPrizeListFormBuilderUtilJumperSectionRow from '../section/jumper/EventPrizeListFormBuilderUtilJumperSectionRow'
import EventPrizeListFormBuilderUtilHunterClassicSectionRow from '../section/hunter/classic/EventPrizeListFormBuilderUtilHunterClassicSectionRow'
import EventPrizeListFormBuilderUtilHunterDerbySectionRow from '../section/hunter/derby/EventPrizeListFormBuilderUtilHunterDerbySectionRow'
import EventPrizeListFormBuilderUtilHunterLeagueSectionRow from '../section/hunter/league/EventPrizeListFormBuilderUtilHunterLeagueSectionRow'

interface EventPriceListFormBuilderUtilDivisionRowProps {
  disciplineIndex: number
  division: any
  divisionIndex: number
  register: any
  children?: ReactNode
  errors: any
  control: any
  setValue: any
  getValues: any
  setError: any
  clearErrors: any
  trigger: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormBuilderUtilDivisionRow: React.FC<EventPriceListFormBuilderUtilDivisionRowProps> =
  forwardRef<any, EventPriceListFormBuilderUtilDivisionRowProps>((props, ref) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const handleOnAccordionExpansionToggle = useCallback((_: any) => {
      setIsExpanded((prevState) => !prevState)
    }, [])

    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    /**
     * @todo Document this
     */
    const expandDivision = () => {
      setIsExpanded(true)
    }

    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    /**
     * @todo Document this
     */
    const collapseDivision = () => {
      setIsExpanded(false)
    }

    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    /**
     * @todo Document this
     */
    useImperativeHandle(ref, () => ({ expandDivision, collapseDivision }), [])

    return (
      <EventPrizeListFormBuilderUtilDivisionRowAccordionComponent
        onChange={handleOnAccordionExpansionToggle}
      >
        <EventPrizeListFormBuilderUtilDivisionRowAccordionSummaryComponent isExpanded={isExpanded}>
          <div className="flex -ml-5">
            <label className="text-[16px] font-black mr-4 text-SeabiscuitDark200ThemeColor w-28">
              {props.division.divisionCode}
            </label>
            <label className="text-[16px] font-black mr-4 text-SeabiscuitDark200ThemeColor w-full">
              {props.division.divisionTitle}
            </label>
          </div>
        </EventPrizeListFormBuilderUtilDivisionRowAccordionSummaryComponent>
        <EventPrizeListFormBuilderUtilDivisionRowAccordionDetailsComponent>
          {(function () {
            switch (props.division.sectionItemsType) {
              case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.EQUITATION_STANDARD:
                return (
                  props.division.sections &&
                  props.division.sections.length > 0 &&
                  props.division.sections.map((section: any, index: number) => (
                    <EventPrizeListFormBuilderUtilEquitationSectionRow
                      key={section.sectionTitle}
                      section={section}
                      division={props.division}
                      register={props.register}
                      disciplineIndex={props.disciplineIndex}
                      divisionIndex={props.divisionIndex}
                      sectionIndex={index}
                      errors={props.errors}
                      control={props.control}
                      setValue={props.setValue}
                      getValues={props.getValues}
                      setError={props.setError}
                      clearErrors={props.clearErrors}
                      trigger={props.trigger}
                    />
                  ))
                )
              case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.JUMPER_STANDARD:
                return (
                  props.division.sections &&
                  props.division.sections.length > 0 &&
                  props.division.sections.map((section: any, index: number) => (
                    <EventPrizeListFormBuilderUtilJumperSectionRow
                      key={section.sectionTitle}
                      section={section}
                      division={props.division}
                      register={props.register}
                      disciplineIndex={props.disciplineIndex}
                      divisionIndex={props.divisionIndex}
                      sectionIndex={index}
                      errors={props.errors}
                      control={props.control}
                      setValue={props.setValue}
                      getValues={props.getValues}
                      setError={props.setError}
                      clearErrors={props.clearErrors}
                      trigger={props.trigger}
                    />
                  ))
                )
              case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_CLASSIC:
                return (
                  props.division.sections &&
                  props.division.sections.length > 0 &&
                  props.division.sections.map((section: any, index: number) => (
                    <EventPrizeListFormBuilderUtilHunterClassicSectionRow
                      key={section.sectionTitle}
                      section={section}
                      division={props.division}
                      register={props.register}
                      disciplineIndex={props.disciplineIndex}
                      divisionIndex={props.divisionIndex}
                      sectionIndex={index}
                      errors={props.errors}
                      control={props.control}
                      setValue={props.setValue}
                      getValues={props.getValues}
                      setError={props.setError}
                      clearErrors={props.clearErrors}
                      trigger={props.trigger}
                    />
                  ))
                )
              case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_DERBY:
                return (
                  props.division.sections &&
                  props.division.sections.length > 0 &&
                  props.division.sections.map((section: any, index: number) => (
                    <EventPrizeListFormBuilderUtilHunterDerbySectionRow
                      key={section.sectionTitle}
                      section={section}
                      division={props.division}
                      register={props.register}
                      disciplineIndex={props.disciplineIndex}
                      divisionIndex={props.divisionIndex}
                      sectionIndex={index}
                      errors={props.errors}
                      control={props.control}
                      setValue={props.setValue}
                      getValues={props.getValues}
                      setError={props.setError}
                      clearErrors={props.clearErrors}
                      trigger={props.trigger}
                    />
                  ))
                )
              case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_LEAGUE:
                return (
                  props.division.sections &&
                  props.division.sections.length > 0 &&
                  props.division.sections.map((section: any, index: number) => (
                    <EventPrizeListFormBuilderUtilHunterLeagueSectionRow
                      key={section.sectionTitle}
                      section={section}
                      division={props.division}
                      register={props.register}
                      disciplineIndex={props.disciplineIndex}
                      divisionIndex={props.divisionIndex}
                      sectionIndex={index}
                      errors={props.errors}
                      control={props.control}
                      setValue={props.setValue}
                      getValues={props.getValues}
                      setError={props.setError}
                      clearErrors={props.clearErrors}
                      trigger={props.trigger}
                    />
                  ))
                )
              default: // case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD:
                return (
                  props.division.sections &&
                  props.division.sections.length > 0 &&
                  props.division.sections.map((section: any, index: number) => (
                    <EventPrizeListFormBuilderUtilHunterStandardSectionRow
                      key={section.sectionTitle}
                      section={section}
                      division={props.division}
                      register={props.register}
                      disciplineIndex={props.disciplineIndex}
                      divisionIndex={props.divisionIndex}
                      sectionIndex={index}
                      errors={props.errors}
                      control={props.control}
                      setValue={props.setValue}
                      getValues={props.getValues}
                      setError={props.setError}
                      clearErrors={props.clearErrors}
                      trigger={props.trigger}
                    />
                  ))
                )
            }
          })()}
        </EventPrizeListFormBuilderUtilDivisionRowAccordionDetailsComponent>
      </EventPrizeListFormBuilderUtilDivisionRowAccordionComponent>
    )
  })

export default EventPrizeListFormBuilderUtilDivisionRow
