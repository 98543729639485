import ExhibitorTeamPaperworkAccordion from './accordions/ExhibitorTeamPaperworkAccordion'
import { IPaperworkTeam } from '../../../store/paperworks/paperworkSlice'
import customImageComponent from '../../../components/common/CustomImageComponent'
import { useEffect, useState } from 'react'

const openNextSibbling = (e: any) => {
  e.target?.closest('.accordion')?.nextElementSibling?.classList.toggle('activeTab')
}

export const AccordionWrapper = ({
  handleToggle,
  openedMenu,
  accordionIndex,
  userData,
  getDocumentLink,
  eventId,
  cbSingSuccess,
}: {
  handleToggle: (value: number) => void
  openedMenu: number[]
  accordionIndex: number
  userData: IPaperworkTeam | null
  getDocumentLink: (eventId: string) => void
  cbSingSuccess: () => void
  eventId: string | null
}) => {
  const [signMissing, setSignMissing] = useState(0)

  useEffect(() => {
    if (userData && userData.member && userData.member.problem_found) {
      console.log('=>(AccordionWrapper.tsx:32) userData.member', userData.member)
      setSignMissing(userData.member.problem_found)
    }
  }, [userData])

  return (
    <>
      <div className="mt-3 border-[#D3DAEE] border px-4 py-2 pl-2 rounded-lg transition-all">
        <div
          onClick={(e: any) => {
            openNextSibbling(e)
            handleToggle(accordionIndex)
          }}
          className="text-SeabiscuitDark200ThemeColor flex flex-col lg:flex-row lg:justify-between lg:items-center font-semibold accordion cursor-pointer"
        >
          <div className="flex items-center">
            <span className="w-10 h-10 rounded-md overflow-hidden mr-4">
              {customImageComponent(
                userData && (userData.riderProfilePicture || userData.teamMemberProfilePicture),
                userData && (userData!.riderName || userData.teamMemberName),
                'w-full h-full object-cover'
              )}
            </span>
            <div className="flex flex-col">
              <span className="text-md text-SeabiscuitDark200ThemeColor font-semibold capitalize">
                {userData && (userData!.riderName || userData.teamMemberName)}{' '}
                <div className="font-normal text-[12px] text-[#3C4B71B2]">
                  {userData && userData.teamMemberRole}
                </div>
              </span>
            </div>
          </div>
          <div className="flex justify-between mt-3 lg:mt-0 items-center">
            <div className="font-normal text-[14px] text-SeabiscuitDark200ThemeColor">
              {userData && userData.member
                ? signMissing === 0
                  ? 'All paperwork signed'
                  : `${signMissing} ${signMissing === 1 ? 'signature' : 'signatures'} missing`
                : ''}
            </div>

            {userData && userData.member ? (
              <div className="status text-SeabiscuitGreenThemeColor rounded-full p px-4 add_icon cursor-pointer">
                {openedMenu.includes(accordionIndex) ? (
                  <div className={'add_icon cursor-pointer'}>
                    <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
                  </div>
                ) : (
                  <div className={'remove_icon cursor-pointer'}>
                    <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
                  </div>
                )}
              </div>
            ) : (
              'No paperwork'
            )}
          </div>
        </div>

        <div className="faqs-content ">
          {userData && userData.member && userData.member.paperwork_data.length > 0 && (
            <div className="faqs-content-inside mt-4">
              <ExhibitorTeamPaperworkAccordion
                docsList={userData.member.paperwork_data}
                member={userData.member}
                title="Document"
                tooltip="Paperwork"
                getDocumentLink={getDocumentLink}
                eventId={eventId}
                cbSingSuccess={() => {
                  cbSingSuccess()
                  setSignMissing((v) => v - 1)
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
