/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'

// Third party
import { Search } from '@mui/icons-material'
import _, { cloneDeep } from 'lodash'

// Types
import { ITeamMember } from '../../../models/users/user.interface'

// Firestore
import FirestoreService from '../../../services/firestoreService'

// Constants
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'

//custom hooks
import useToasterHelper from '../../../helpers/ToasterHelper'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { HorseModel } from '../../../models/horse/horse.model'
import { getConvertedData, getUserAsTeamMember } from '../../../models/interface.helper'
import {
  selectRegisterEventData,
  setRegisterPaperworkData,
} from '../../../store/events/eventsSlice'
import { useAppSelector } from '../../../store/hooks'
import {
  getSelectedHorse,
  getSelectedHorseMapping,
  selectHorses,
  selectHorseReducer,
  setSelectedHorse,
  setSelectedHorseTeam,
  updateHorse,
} from '../../../store/horses/horseSlice'
import {
  selectHorseTeamMembers,
  selectProfileData,
  selectTeamMembers,
  setTeamMembers,
} from '../../../store/user/userSlice'

// Types
import { where } from 'firebase/firestore'
import helpers from '../../../commonHelpers/helpers'
import { CustomError } from '../../../helpers/helpers'
import useProfileHook from '../../../hooks/users/competitor/profile/useProfileHook'
import { IPaperworkTab_ } from '../../../models/event-registered-users/event-registered-users.interface'
import { IHorseTeamInterface } from '../../../models/horse-team/horseTeam.interface'
import { HorseTeamModel } from '../../../models/horse-team/horseTeam.model'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'
import {
  selectPaperworkTabData,
  selectUserTeamMembersR,
} from '../../../store/registration/registrationSlice'
import { RootState } from '../../../store/store'
import customImageComponent from '../../common/CustomImageComponent'
import MainModal from '../../modals/common/MainModal'

type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: any
}

export interface ITeamMembeWithSelected extends ITeamMember {
  selected?: boolean
  newSelected?: boolean
}

type IModalType = {
  HORSE: boolean
  TEAM: boolean
  CLINIC_TABS: boolean
}

type IGetSignatoryFn = (
  registrationByDayDocId: IRiderTeamMemberInterface['registrationByDayDocId']
) => IRiderTeamMemberInterface[]

// Constants
export const ITEAMMEMBER_MODAL_TYPES = {
  HORSE: 'forHorses',
  TEAM: 'forTeam',
  CLINIC_TABS: 'forClinicTabs',
}

const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'

const USERS_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS

const FILE_NAME = 'UserProfileTeamTabAddTeamMember'

const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const UserProfileTeamTabAddTeamMember = (props: Props) => {
  // Hooks and vars
  let membersToRender: ITeamMembeWithSelected[]

  const toastFunctions = useToasterHelper()
  const dispatch = useDispatch()
  const { updateUserDetails } = useProfileHook({ dontFetch: true })

  const types: IModalType = {
    TEAM: props?.dataToPassOn?.modal_type === 'forTeam',
    HORSE: props?.dataToPassOn?.modal_type === 'forHorses',
    CLINIC_TABS: props?.dataToPassOn?.modal_type === 'forClinicTabs',
  }

  const horseData = useAppSelector(selectHorses)
  const profileData = useAppSelector(selectProfileData)
  const selectedHorse = useAppSelector(getSelectedHorse)
  const teamMembersList = useSelector(selectTeamMembers)
  const horseReducer = useAppSelector(selectHorseReducer)
  const HorseTeamList = useAppSelector(selectHorseTeamMembers) as ITeamMember[]
  const paperworkTabData = useAppSelector(selectPaperworkTabData)
  const registerFormData = useAppSelector(selectRegisterEventData)
  const selectedHorseMapping = useAppSelector(getSelectedHorseMapping)
  const teamMembersWithUser = useAppSelector(selectUserTeamMembersR)
  const userReducer = useAppSelector((state: RootState) => state.user)
  let registerDataCloned = cloneDeep(registerFormData)

  const searchBoxRef = useRef<HTMLInputElement>(null)

  const [listToShow, setListToShow] = useState<ITeamMembeWithSelected[]>()
  const [teamMember, setTeamMember] = useState<ITeamMembeWithSelected[]>([])
  const [sameTypeUsers, setSameTypeUsers] = useState<ITeamMembeWithSelected[]>()

  const dataToPassOn = props?.dataToPassOn ?? {}
  const saveToProfile = dataToPassOn?.saveToProfile
  const showSearchList = searchBoxRef?.current?.value !== ''

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Gets the users of same type from db
   */
  useEffect(() => {
    const allUsers = async () => {
      let idsList: string[] = []
      let teamMembers: ITeamMember[] = []
      let usersDetails: IUserInterface | null = null

      if (types.CLINIC_TABS) {
        teamMembersWithUser?.forEach((user) => {
          if (user.memberId) {
            idsList.push(user.memberId)
            teamMembers = [
              ...teamMembers,
              {
                ...user,
                memberRole: CONST.UI.TEAM_MEMBERS_ROLES.TEAM_MEMBER,
              },
            ]
          }
        })

        if (!idsList.includes(profileData.id)) teamMembers.push(getUserAsTeamMember(profileData))

        return teamMembers
      } else {
        const usersList = await FirestoreService.filterItems(USERS_COLLECTION.NAME, [
          where(
            USERS_COLLECTION.FIELDS.USER_TYPE.KEY,
            '==',
            USERS_COLLECTION.FIELDS.USER_TYPE.VALUES.COMPETITOR
          ),
        ])

        usersList.forEach((doc) => {
          idsList.push(doc.data().id)
        })

        const docs = await FirestoreService.getItemsUsingIds(USERS_COLLECTION.NAME, idsList)

        docs.forEach((currDoc) => {
          usersDetails = UserModel.fromFirestoreDoc(currDoc).cloneDeep()
          if (usersDetails.id === profileData.id) return
          teamMembers = [
            ...teamMembers,
            {
              memberCountry: '',
              memberId: usersDetails.id,
              memberprofileSynced: false,
              memberEmail: usersDetails.userEmail,
              memberDob: usersDetails.userDOB ?? '',
              memberUserName: usersDetails.userName,
              memberAddress: usersDetails.userAddress,
              defaultRole: usersDetails.userDefaultRole ?? '',
              memberPhoneNumber: usersDetails.userPhoneNumber,
              memberProfilePicture: usersDetails.userProfilePicture,
              memberName: `${usersDetails.userFirstName} ${usersDetails.userLastName}`,
            },
          ]
        })

        return teamMembers
      }
    }

    allUsers()
      .then((allUsers) => {
        const roles = [CONST.UI.TEAM_MEMBERS_ROLES.TEAM_MEMBER]

        let list: any[] = []
        let alreadyExists = false
        let existsButMarkedDeleted = false
        let user: ITeamMembeWithSelected
        let list_: IRiderTeamMemberInterface[] = []
        let usersOfSameType: ITeamMembeWithSelected[] = []
        let ridersTeamMember: IRiderTeamMemberInterface | null = null
        let riderTeamMembers: IRiderTeamMemberInterface[] = getSignatories(
          dataToPassOn?.registrationByDayDocId
        )

        list =
          (function () {
            if (types.TEAM) return teamMembersList
            if (types.HORSE) return horseReducer.selected.team.data
            return []
          })() ?? []

        list_ = riderTeamMembers

        if (riderTeamMembers.length) return

        allUsers.forEach((curr) => {
          existsButMarkedDeleted = false

          if (types.CLINIC_TABS) {
            ridersTeamMember =
              _.find(list_, {
                teamMemberId: curr?.memberId,
                teamMemberRole: curr.memberRole,
              }) ?? null
            alreadyExists = !!ridersTeamMember
            existsButMarkedDeleted = alreadyExists && ridersTeamMember?.delete === true
          } else
            alreadyExists = !!list.find((currHorseTeamMember) => {
              return curr?.memberId === currHorseTeamMember.memberId
            })

          if (curr?.memberId && (!alreadyExists || existsButMarkedDeleted)) {
            user = getConvertedData({
              ...curr,
              ...(existsButMarkedDeleted && {
                id: ridersTeamMember?.id,
              }),
              selected: false,
              memberStatus: '1',
              memberRole: roles[0],
              memberShipActive: false,
              signedStatus: 'Not sent',
              memberprofileSynced: false,
              memberSafeSupportTraining: false,
            })

            usersOfSameType = [...usersOfSameType, user]
          }
        })

        setSameTypeUsers([...usersOfSameType])
      })
      .catch((error) => {
        helpers.logger({
          isError: true,
          message: error,
        })
      })
  }, [])

  useEffect(() => {
    if (searchBoxRef.current?.value) handleUsers(searchBoxRef.current?.value)
    setTeamMember([...(sameTypeUsers?.filter((curr) => curr?.selected === true) ?? [])])
  }, [sameTypeUsers])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Returns signatories of a subdoc
   */
  const getSignatories: IGetSignatoryFn = (registrationByDayDocId) => {
    if (!registrationByDayDocId) return []

    let paperworkRowIndex = -1
    let foundPaperworkRow: IPaperworkTab_ | null = null
    let ridersTeamMembers: IRiderTeamMemberInterface[] = []

    try {
      foundPaperworkRow =
        paperworkTabData.find((currPaperworkRow, index) => {
          if (currPaperworkRow.registrationByDayDocId === registrationByDayDocId) {
            paperworkRowIndex = index
            return true
          }
          return false
        }) ?? null

      const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        registrationByDayDocId,
        foundPaperworkRow,
      })

      if (emptyVarName)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'getSignatories',
          devMessage: `${emptyVarName} is ${emptyVarValue}`,
        })

      ridersTeamMembers = paperworkTabData?.[paperworkRowIndex]?.ridersTeamMembers ?? []
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return ridersTeamMembers
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Filters the user in actual
   */
  const handleUsers = (strToSearch: string) => {
    if (strToSearch === '') return setListToShow([])
    const filteredUserList = sameTypeUsers?.filter((curr) => {
      return curr.memberName?.toLocaleLowerCase()?.startsWith(strToSearch?.toLocaleLowerCase())
    })
    const furtherFIlter =
      HorseTeamList.length && filteredUserList?.length
        ? filteredUserList.filter((data) =>
            HorseTeamList.find((horse) => horse.memberId !== data.memberId)
          )
        : filteredUserList

    setListToShow([...(furtherFIlter ?? [])])
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Handles click on user
   */
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    team_member: ITeamMembeWithSelected
  ) => {
    const select = e.target.checked
    if (sameTypeUsers) {
      let sameTypeUsersArrCopy = [...sameTypeUsers]
      sameTypeUsersArrCopy = sameTypeUsersArrCopy.map((currUser) => {
        if (currUser.memberId === team_member?.memberId) {
          currUser.selected = select
          currUser.newSelected = true
        }
        return currUser
      })
      setSameTypeUsers([...sameTypeUsersArrCopy])
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Filters users list acc to name
   */
  const filterUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const strToSearch = e.target.value
    handleUsers(strToSearch)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Adds the selected members to redux, but not to db
   */
  const addTeamMembersToViewOnly = async () => {
    let mappingId = selectedHorseMapping.data?.id
    if ((sameTypeUsers ?? [])?.filter((currUser) => currUser.selected).length === 0)
      return toastFunctions.info({ message: MESSAGES_CONST.SELECT_TEAM_MEMBERS_TO_SAVE })

    try {
      if (types.HORSE) {
        let horseTeamMembersList: IHorseTeamInterface[] = []
        let horse = new HorseModel(selectedHorse ?? undefined).toObject()
        let horseIndexInRedux = -1

        if (!mappingId || !horse.id)
          return toastFunctions.error({
            message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          })

        horseTeamMembersList = cloneDeep(horseReducer.selected.team.data)
        teamMember.forEach((currTeamMember) => {
          horseTeamMembersList.push(
            getConvertedData(
              new HorseTeamModel({
                isMyHorse: true,
                horseId: horse.id ?? null,
                horseZone: horse.horseZone,
                horseName: horse.horseName,
                userId: userReducer.userId,
                horseOwnerId: horse.horseOwnerId,
                memberId: currTeamMember.memberId,
                horseNameNGram: horse.horseNameNGram,
                memberName: currTeamMember.memberName,
                userHorseMappingId: mappingId ?? null,
                horseDiscipline: horse.horseDiscipline,
                memberRole: currTeamMember.memberRole ?? null,
                horseProfilePicture: horse.horseProfilePicture,
                memberProfilePicture: currTeamMember.memberProfilePicture,
              }).toObject()
            )
          )
        })

        horse.horseTeamMembers_ = horseTeamMembersList
        horse = getConvertedData(horse)
        horseIndexInRedux = horseData.findIndex((currHorse) => currHorse.horseId === horse.horseId)

        dispatch(setSelectedHorse(horse))
        dispatch(
          updateHorse({
            data: {
              ...horse,
              isMyHorse: userReducer.userId === horse.horseOwnerId,
            },
            index: horseIndexInRedux,
          })
        )
        dispatch(setSelectedHorseTeam(horseTeamMembersList))
      } else if (types.TEAM) {
        let updatedTeamMembersList = [...teamMembersList, ...teamMember]

        let paperworkWithMembers = Object.entries(registerDataCloned.paperwork).reduce(
          (acc: any, current: any) => {
            let key = current[0]
            acc = {
              ...registerDataCloned.paperwork,
              [key]: [...registerDataCloned.paperwork[key], ...teamMember],
            }
            return acc
          },
          {}
        )

        dispatch(setRegisterPaperworkData(paperworkWithMembers))

        if (saveToProfile) {
          let res = await updateUserDetails({
            ...userReducer.profileDetails,
            userTeamMembers: updatedTeamMembersList,
          })

          if (!res.status) {
            toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
          }
        }

        dispatch(setTeamMembers(updatedTeamMembersList.map((utml) => getConvertedData(utml))))
      }

      props?.handleModal(false, MODAL_CONSTS.TEAM)
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  const getMembersToRender = () => {
    let members: ITeamMembeWithSelected[]
    if (types.CLINIC_TABS) members = (showSearchList ? listToShow : sameTypeUsers) ?? []
    else members = (showSearchList ? listToShow : teamMember) ?? []
    return members
  }

  membersToRender = getMembersToRender()

  const focusInput = () => {
    if (searchBoxRef.current) searchBoxRef.current?.focus()
  }

  return (
    <MainModal
      title={types.CLINIC_TABS ? 'Add member to send paperworks' : 'Add team member'}
      show={props.show}
      type="TEAM"
      size="md"
      onTransitionEnd={focusInput}
      titleClassName="!font-normal"
      buttons={[
        {
          label: 'SAVE',
          bgClass: 'bg-SeabiscuitMainThemeColor',
          onClick: addTeamMembersToViewOnly,
          textClass: 'text-white',
        },
        {
          label: 'CANCEL',
          bgClass: 'bg-SeabiscuitLightThemeColor',
          borderClass: 'border border-transparent',
          textClass: 'text-SeabiscuitLightTextColor',
          onClick: () => props.handleModal(false, MODAL_CONSTS.TEAM),
        },
      ]}
    >
      <>
        <div className="inputSearch mt-4 flex items-center relative">
          <input
            ref={searchBoxRef}
            type="text"
            onChange={filterUsers}
            placeholder="Search.."
            className="rounded-full w-full border focus:border-[#D3DAEE] border-[#D3DAEE] text-SeabiscuitDark200ThemeColor focus:ring-0"
          />
          <Search className="absolute text-SeabiscuitMainThemeColor right-4" />
        </div>

        <div className="uselListWarp mt-4 flex flex-col">
          {membersToRender?.length ? (
            membersToRender?.map((item, index) => {
              return (
                <label
                  key={index}
                  className="form-check mb-3 cursor-pointer"
                  htmlFor={'teammember' + index}
                >
                  <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                    <div className="min-w-fit flex items-center">
                      {customImageComponent(
                        item.memberProfilePicture,
                        item.memberName,
                        'w-[40px] h-[40px] rounded-full mr-2 avatarImg object-cover'
                      )}
                      <div className="hourseDetails w-10/12">
                        <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                          <p className="text-SeabiscuitDark200ThemeColor text-lg capitalize">
                            {item?.memberName}
                          </p>
                          <p className="text-xs">@{item?.memberUserName}</p>
                        </div>
                      </div>
                    </div>

                    {item.selected ? (
                      <img
                        src={CHECKED_ICON}
                        className="relative right-[0px] top-[0px]"
                        width={20}
                        height={20}
                        alt="checked"
                      />
                    ) : null}

                    <input
                      onChange={(e) => {
                        handleChange(e, item)
                      }}
                      checked={item?.selected}
                      id={'teammember' + index}
                      className={`ml-2 form-check-input appearance-none mr-[2px] h-4 w-4 border active:bg-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer right-0 checked:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor ${item.selected ? 'hidden' : ''}`}
                      type="checkbox"
                    />
                  </div>
                </label>
              )
            })
          ) : (
            <div className="w-full mt-2 text-center text-SeabiscuitDark200ThemeColor">
              {showSearchList ? 'No result found.' : null}
            </div>
          )}
        </div>
      </>
    </MainModal>
  )
}

export default UserProfileTeamTabAddTeamMember
