// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import * as React from 'react'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventScoringFormBuilderUtilMainRowHeader: React.FC = (props) => {
  return (
    <div className="flex items-center flex-wrap">
      <div className="relative -ml-3">
        <div className="flex">
          <label
            htmlFor="total-divisions-offered"
            className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
          >
            Division
          </label>
        </div>
      </div>

      <div className="relative ml-10">
        <div className="flex">
          <label
            htmlFor="total-divisions-offered"
            className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
          >
            Section
          </label>
          <div className="pl-1 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="relative ml-[420px]">
        <div className="flex">
          <label
            htmlFor="total-divisions-offered"
            className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
          >
            Under Saddle class
          </label>
          <div className="pl-1 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="relative ml-12">
        <div className="flex">
          <label
            htmlFor="total-divisions-offered"
            className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
          >
            Handy Class
          </label>
          <div className="pl-1 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="relative ml-[86px]">
        <div className="flex">
          <label
            htmlFor="total-divisions-offered"
            className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
          >
            Conformation Class
          </label>
          <div className="pl-1 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="relative ml-[52px]">
        <div className="flex">
          <label
            htmlFor="total-divisions-offered"
            className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
          >
            Height
          </label>
          <div className="pl-1 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="relative  ml-[120px]">
        <div className="flex">
          <label
            htmlFor="total-divisions-offered"
            className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
          >
            Total price money
          </label>
          <div className="pl-1 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
      </div>

      <div className="relative ml-[64px]">
        <div className="flex">
          <label
            htmlFor="total-divisions-offered"
            className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
          >
            Judge
          </label>
          <div className="pl-1 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventScoringFormBuilderUtilMainRowHeader
