import React, { useEffect, useRef, useState } from 'react'
import ManageEventRevenueWrapper from '../ManageEventRevenueWrapper'
import { useParams } from 'react-router'
import ManageEventRevenueHeader from '../ManageEventRevenueHeader'
import ManageEventRevenueTabs from '../ManageEventRevenueTabs'
import { CONST } from '../../../../../const/const'
import useGetEventData from '../../../../../hooks/users/common/useGetEventData'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import { selectedEvent, setRevenueCustomers } from '../../../../../store/events/eventsSlice'
import RegistrationRevenueRefundTab from '../Tabs/RegistrationRevenueRefundTab'
import ManageEventRevenueDisplayTabs from '../ManageEventRevenueDisplayTabs'
import { EventModel } from '../../../../../models/events/event.model'
import { useDebounceWithSetter } from '../../../../../hooks/useDebounceWithSetter'

const RegistrationRevenue = () => {
  const { getAllData } = useGetEventData()
  const { EventDetails, Event } = useAppSelector(selectedEvent)
  const { eventId } = useParams<{ eventId: string }>()
  const dispatch = useAppDispatch()
  const event = Event ?? new EventModel().toObject()

  const expectedRevenue =
    event.expectedFeesRevenue + event.expectedRegistrationRevenue + event.expectedTicketRevenue
  const totalRevenue =
    event.currentFeesRevenue + event.currentRegistrationRevenue + event.currentTicketRevenue

  const inputRef = useRef<HTMLInputElement>(null)

  const { debounceSetter } = useDebounceWithSetter({
    delay: 300,
    value: '',
  })
  const [eventTab, setEventTab] = useState(CONST.UI.EVENTS.REVENUE.TABS.ALL.VALUE)

  const revenueDetails = {
    potential: expectedRevenue,
    tillDate: totalRevenue,
    difference: expectedRevenue - totalRevenue,
  }

  useEffect(() => {
    return () => {
      dispatch(setRevenueCustomers([]))
    }
  }, [dispatch])

  useEffect(() => {
    if (eventId) {
      getAllData(eventId, [
        'v01_event_fees',
        'v01_event_details',
        'v01_event_registered_users',
        'v01_event_ticketing',
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  const onSearchValueChange = (updatedValue: string) => {
    debounceSetter(updatedValue)
  }

  return (
    <ManageEventRevenueWrapper
      eventId={eventId}
      setEventTab={setEventTab}
      eventTab={eventTab}
      title={EventDetails?.competitionName ?? 'Unknown'}
    >
      <>
        <div className="w-full flex mb-4 flex-wrap">
          <ManageEventRevenueHeader revenueType={'Registration'} revenueDetails={revenueDetails} />

          <ManageEventRevenueTabs
            ref={inputRef}
            eventTab={eventTab}
            setEventTab={setEventTab}
            setSearchValue={onSearchValueChange}
          />

          {eventTab === 'Refunded' ? (
            <RegistrationRevenueRefundTab
              eventId={eventId}
              searchValue={inputRef.current?.value ?? ''}
            />
          ) : (
            <ManageEventRevenueDisplayTabs
              eventId={eventId}
              eventTab={eventTab}
              searchValue={inputRef.current?.value ?? ''}
            />
          )}
        </div>
      </>
    </ManageEventRevenueWrapper>
  )
}

export default RegistrationRevenue
