// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import EventScoringFormBuilderUtilDivisionRow from '../division/EventScoringFormBuilderUtilDivisionRow'
import EventScoringFormBuilderUtilMainRowAccordionComponent from './EventScoringFormBuilderUtilMainRowAccordionComponent'
import EventScoringFormBuilderUtilMainRowAccordionSummaryComponent from './EventScoringFormBuilderUtilMainRowAccordionSummaryComponent'
import EventScoringFormBuilderUtilMainRowAccordionDetailsComponent from './EventScoringFormBuilderUtilMainRowAccordionDetailsComponent'
import EventScoringFormBuilderUtilMainRowHeader from '../headers/EventScoringFormBuilderUtilMainRowHeader'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventPriceListFormBuilderUtilMainRowProps {
  item: any
  disciplineIndex: number
  register: any
  divisionRowRefs: any
  sectionsRowRefs: any
  classesRowRefs: any
  children?: ReactNode
  errors: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventScoringFormBuilderUtilMainRow = forwardRef<
  any,
  EventPriceListFormBuilderUtilMainRowProps
>((props, mainRef) => {
  const divisionRowRefs: React.MutableRefObject<any[]> = useRef<any[]>([])

  const [isExpanded, setIsExpanded] = useState(false)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const handleOnAccordionExpansionToggle = useCallback(
    (_: any) => {
      setIsExpanded((prevState) => !prevState)
    },
    [setIsExpanded]
  )

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const expandRow = () => {
    setIsExpanded(true)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const collapseRow = () => {
    setIsExpanded(false)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useImperativeHandle(mainRef, () => ({ expandRow, collapseRow }), [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    divisionRowRefs.current = divisionRowRefs.current.slice(0, props.item.divisions?.length)
  }, [props.item.divisions?.length])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <EventScoringFormBuilderUtilMainRowAccordionComponent
        expanded={isExpanded}
        onChange={handleOnAccordionExpansionToggle}
      >
        <EventScoringFormBuilderUtilMainRowAccordionSummaryComponent isExpanded={isExpanded}>
          <label className="text-[22px] font-black mr-4 text-SeabiscuitDark200ThemeColor">
            {props.item.discipline}
          </label>
        </EventScoringFormBuilderUtilMainRowAccordionSummaryComponent>
        <EventScoringFormBuilderUtilMainRowAccordionDetailsComponent>
          <EventScoringFormBuilderUtilMainRowHeader />
          {props.item.divisions.map((division: any, index: any) => {
            return (
              <EventScoringFormBuilderUtilDivisionRow
                key={division.divisionCode}
                disciplineIndex={props.disciplineIndex}
                division={division}
                divisionIndex={index}
                register={props.register}
                errors={props.errors}
              />
            )
          })}
        </EventScoringFormBuilderUtilMainRowAccordionDetailsComponent>
      </EventScoringFormBuilderUtilMainRowAccordionComponent>
    </>
  )
})

export default EventScoringFormBuilderUtilMainRow
