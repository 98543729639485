import { useContext } from 'react'
import EventReviewPublishInput from './EventReviewPublishInput'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { IEventTickets } from '../../../../../models/event-details/event-details.interface'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import DataNotAvailable from '../../../../common/alerts/data-not-available/DataNotAvailable'

const DetailRow = ({
  title,
  note,
  available,
  cost,
  onClick,
}: {
  title: any
  note: any
  available: any
  cost: any
  onClick: (text: string) => void
}) => {
  return (
    <div className="flex w-full">
      <EventReviewPublishInput
        className="rounded-lg flex items-center text-SeabiscuitDark200ThemeColor shrink-0 h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-80"
        data={title}
      />
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 grow ellipsis cursor-pointer"
        onClick={() => onClick(note)}
        data={note}
      />

      <EventReviewPublishInput
        className="rounded-lg flex items-center text-SeabiscuitDark200ThemeColor justify-center shrink-0 h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6"
        data={`${available} for sale`}
      />

      <EventReviewPublishInput
        className="rounded-lg flex items-center text-SeabiscuitDark200ThemeColor justify-center shrink-0 h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6"
        data={`${cost} per unit`}
      />

      <div className="rounded-lg bg-SeabiscuitGrayThemeColor p-1 m-1 shrink-0 h-12 w-12 text-center flex items-center justify-center">
        <img className="m-auto" src="/assets/cp_icons/Ok-3.svg" alt="checkIcon" />
      </div>
    </div>
  )
}

type Props = {
  data?: any
  loading?: boolean
}

const EventTicketsReviewPublishForm = (props: Props) => {
  const { data, loading } = props
  const handleModalContext = useContext(HandleModalContext)

  /**
   * @param text Text to show
   * @info Opens note modal
   */
  const openAddNotesModal = (text: string) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NOTE, {
      noteInputRef: text,
    })
  }

  if (loading) {
    return null
  } else {
    return (
      <>
        {data && data.tickets ? (
          data.tickets.map((current: IEventTickets, index: number) => {
            return (
              <DetailRow
                key={`${JSON.stringify(current)}${index}}`}
                title={current.name}
                note={current.note}
                onClick={openAddNotesModal}
                available={current.available}
                cost={`${current.costAlias}`}
              />
            )
          })
        ) : (
          <DataNotAvailable
            mode="text"
            containerClassName="text-SeabiscuitDark200ThemeColor justify-center flex"
            text={MESSAGES_CONST.TICKETS_DATA_NOT_AVAILABLE}
          />
        )}
      </>
    )
  }
}

export default EventTicketsReviewPublishForm
