// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import * as yup from 'yup'
import { EPL_FB_HUNTER_AA } from '../../config/event/prize-list/form-builder/hunter/aa/epl-fb-hunter-aa'
import formBuilderPrizeListFactory from '../../core/event/form-builder/prize_list/prize_list_form_builder'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IEventPrizeList } from './event-prize-lists.interface'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export class EventPrizeListsModel
  extends ModelBaseModel<IEventPrizeList>
  implements IModelBaseModel
{
  public v: number
  public id: string | null
  public owner: string | null
  public competitionOverallRating: string | null
  public data: any
  public created: Date
  public modified: Date

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: IEventPrizeList) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? null
    this.competitionOverallRating = obj?.competitionOverallRating ?? 'AA'
    this.data =
      obj?.data ??
      formBuilderPrizeListFactory([
        EPL_FB_HUNTER_AA,
        // EPL_FB_EQUITATION_AA,
        // EPL_FB_JUMPER_L1
      ])

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  static fromFirestoreDoc(doc: any) {
    return new EventPrizeListsModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  static validationSchema() {
    return yup.object({}).required()
  }
}
