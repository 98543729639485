import { AddCircleOutline } from '@mui/icons-material'

import { ClassesList } from '../../fakeData/classesList'
import { FeesList } from '../../fakeData/FeesList'
import { TicketList } from '../../fakeData/TicketList'
import { PaperWork } from '../../fakeData/PaperWork'
import { grandTotal } from '../../fakeData/grandTotal'

import './accordion.css'

type Props = {}

const CustomAccordion = (props: Props) => {
  return (
    <>
      <div className="">
        <div
          className="mt-2 border-[#122B464D] border px-4 py-2 rounded-lg"
          onClick={(e: any) => e.target.nextSibling.classList.toggle('activeTab')}
        >
          <div className="text-SeabiscuitDark200ThemeColor flex justify-between items-center font-semibold">
            Classes
            <div className="flex items-center">
              <div className="font-normal text-sm border border-[#00B6AA] text-SeabiscuitGreenThemeColor rounded-full p px-4 mr-2">
                edit
              </div>

              <div className="status text-SeabiscuitGreenThemeColor bg-[#00B6AA1A] w-40 text-center rounded-full py-1 px-4 text-sm">
                37 Classes, $2,365
              </div>

              <div className="status text-SeabiscuitGreenThemeColor rounded-full p px-4">
                <AddCircleOutline />
              </div>
            </div>
          </div>
          <div className="faqs-content">
            <div className="faqs-content-inside mt-4">
              {ClassesList.length && (
                <>
                  <div className="flex">
                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Class
                    </p>

                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Registered horse
                    </p>

                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Registered rider
                    </p>

                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Price
                    </p>
                  </div>
                  {ClassesList.map((item: any) => {
                    return (
                      <>
                        {item.category.map((item1: any) => {
                          return (
                            <>
                              {item1.subCategories.map((subCategory: any, index2: any) => {
                                return (
                                  <>
                                    {subCategory.subClasses.map((mainClass: any, index4: any) => {
                                      return (
                                        <>
                                          <div className="flex items-center justify-between w-full mb-2">
                                            <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize">
                                              <span className="mr-4">
                                                {mainClass.code
                                                  ? mainClass.code.substr(0, 5)
                                                  : 1000 + index4}
                                              </span>
                                              <span>{mainClass.name}</span>
                                            </p>

                                            <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                                              <img
                                                src="/assets/og_icons/YearofHorse-1.svg"
                                                alt="horseIcon"
                                                className="absolute left-4"
                                              />
                                              <span className="mr-4">
                                                {mainClass.competitorhorseName}
                                              </span>
                                            </p>

                                            <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                                              <img
                                                src="/assets/og_icons/YearofHorse-1.svg"
                                                alt="horseIcon"
                                                className="absolute left-4"
                                              />
                                              <span className="mr-4">
                                                {mainClass.competitorName}
                                              </span>
                                            </p>

                                            <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                                              <span className="mr-4">${mainClass.fees}</span>
                                              <img
                                                src="/assets/og_icons/Ok-3.svg"
                                                alt="horseIcon"
                                                className="absolute right-4"
                                              />
                                            </p>
                                          </div>
                                        </>
                                      )
                                    })}
                                  </>
                                )
                              })}
                            </>
                          )
                        })}
                      </>
                    )
                  })}
                </>
              )}
              <div className="flex">
                <p className="text-SeabiscuitGreenThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-md bg-[#00b6aa1a] text-left relative flex items-center font-semibold">
                  Total 37 Classes
                </p>
                <p className="text-SeabiscuitGreenThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-md bg-[#00b6aa1a] text-center relative flex items-center justify-center font-semibold">
                  2 horses
                </p>
                <p className="text-SeabiscuitGreenThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-md bg-[#00b6aa1a] text-center relative flex items-center justify-center font-semibold">
                  2 riders
                </p>
                <p className="text-SeabiscuitGreenThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-md bg-[#00b6aa1a] text-center relative flex items-center justify-center font-semibold">
                  $2,365
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="mt-2"
          onClick={(e: any) => e.target.nextSibling.classList.toggle('activeTab')}
        >
          <div className="text-SeabiscuitDark200ThemeColor border-[#122B464D] border px-4 py-2 rounded-md flex justify-between items-center font-semibold">
            Fees
            <div className="flex items-center">
              <div className="font-normal text-sm border border-[#00B6AA] text-SeabiscuitGreenThemeColor rounded-full p px-4 mr-2">
                edit
              </div>

              <div className="status text-SeabiscuitGreenThemeColor bg-[#00B6AA1A] w-40 text-center rounded-full py-1 px-4 text-sm">
                8 fees, $6,750
              </div>

              <div className="status text-SeabiscuitGreenThemeColor rounded-full p px-4">
                <AddCircleOutline />
              </div>
            </div>
          </div>
          <div className="faqs-content">
            <div className="faqs-content-inside mt-4">
              {FeesList.length && (
                <>
                  <div className="flex">
                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Line item
                    </p>

                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Number of units
                    </p>

                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Price per unit
                    </p>

                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Total price
                    </p>
                  </div>

                  {FeesList.map((item: any, index: number) => {
                    return (
                      <>
                        <div className="flex items-center justify-between w-full mb-2">
                          <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize">
                            <span>{item.lineItem}</span>
                          </p>

                          <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                            <span className="mr-4">{item.available}</span>
                          </p>

                          <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                            <span className="mr-4">${item.cost}</span>
                          </p>

                          <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                            <span className="mr-4">${item.available * item.cost}</span>
                            <img
                              src="/assets/og_icons/Ok-3.svg"
                              alt="horseIcon"
                              className="absolute right-4"
                            />
                          </p>
                        </div>
                      </>
                    )
                  })}
                  <div className="flex">
                    <p className="text-SeabiscuitGreenThemeColor xl:text-sm xxl:text-base w-3/4 p-4 mr-2 rounded-md bg-[#00b6aa1a] text-left relative flex items-center font-semibold">
                      Total
                    </p>
                    <p className="text-SeabiscuitGreenThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-md bg-[#00b6aa1a] capitalize text-center relative flex items-center justify-center font-semibold">
                      $2000
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className="mt-2"
          onClick={(e: any) => e.target.nextSibling.classList.toggle('activeTab')}
        >
          <div className="text-SeabiscuitDark200ThemeColor border-[#122B464D] border px-4 py-2 rounded-md flex justify-between items-center font-semibold">
            Tickets
            <div className="flex items-center">
              <div className="font-normal text-sm border border-[#00B6AA] text-SeabiscuitGreenThemeColor rounded-full p px-4 mr-2">
                edit
              </div>

              <div className="status text-SeabiscuitGreenThemeColor bg-[#00B6AA1A] w-40 text-center rounded-full py-1 px-4 text-sm">
                7 tickets, $2,400
              </div>

              <div className="status text-SeabiscuitGreenThemeColor rounded-full p px-4">
                <AddCircleOutline />
              </div>
            </div>
          </div>
          <div className="faqs-content">
            <div className="faqs-content-inside mt-4">
              {/* data */}
              {TicketList.length && (
                <>
                  <div className="flex">
                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Line item
                    </p>

                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Number of units
                    </p>

                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Price per unit
                    </p>

                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Total price
                    </p>
                  </div>

                  {TicketList.map((item: any, index: number) => {
                    return (
                      <>
                        <div className="flex items-center justify-between w-full mb-2">
                          <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize">
                            <span>{item.lineItem}</span>
                          </p>

                          <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                            <span className="mr-4">{item.available}</span>
                          </p>

                          <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                            <span className="mr-4">${item.cost}</span>
                          </p>

                          <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                            <span className="mr-4">${item.available * item.cost}</span>
                            <img
                              src="/assets/og_icons/Ok-3.svg"
                              alt="horseIcon"
                              className="absolute right-4"
                            />
                          </p>
                        </div>
                      </>
                    )
                  })}
                  <div className="flex">
                    <p className="text-SeabiscuitGreenThemeColor xl:text-sm xxl:text-base w-3/4 p-4 mr-2 rounded-md bg-[#00b6aa1a] text-left relative flex items-center font-semibold">
                      Total
                    </p>
                    <p className="text-SeabiscuitGreenThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-md bg-[#00b6aa1a] capitalize text-center relative flex items-center justify-center font-semibold">
                      $2000
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className="mt-2"
          onClick={(e: any) => e.target.nextSibling.classList.toggle('activeTab')}
        >
          <div className="text-SeabiscuitDark200ThemeColor border-[#122B464D] border px-4 py-2 rounded-md flex justify-between items-center font-semibold">
            Paperwork
            <div className="flex items-center">
              <div className="font-normal text-sm border border-[#00B6AA] text-SeabiscuitGreenThemeColor rounded-full p px-4 mr-2">
                edit
              </div>

              <div className="status text-SeabiscuitGreenThemeColor bg-[#00B6AA1A] w-40 text-center rounded-full py-1 px-4 text-sm">
                4 docs, 5 signers
              </div>

              <div className="status text-SeabiscuitGreenThemeColor rounded-full p px-4">
                <AddCircleOutline />
              </div>
            </div>
          </div>
          <div className="faqs-content">
            <div className="faqs-content-inside mt-4">
              {PaperWork.length && (
                <>
                  <div className="flex">
                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/2 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Document
                    </p>

                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/2 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Signatories
                    </p>
                  </div>

                  {PaperWork.map((item: any, index: number) => {
                    return (
                      <>
                        <div className="flex items-center justify-between w-full mb-2">
                          <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/2 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize">
                            <span>{item.documentPaperwork}</span>
                          </p>

                          <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/2 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                            <span className="mr-4">{item.SignatoriesPaperwork}</span>
                          </p>
                        </div>
                      </>
                    )
                  })}
                  <div className="flex">
                    <p className="text-SeabiscuitGreenThemeColor xl:text-sm xxl:text-base w-1/2 p-4 mr-2 rounded-md bg-[#00b6aa1a] text-left relative flex items-center font-semibold">
                      Total 4 documents need to be signed
                    </p>
                    <p className="text-SeabiscuitGreenThemeColor xl:text-sm xxl:text-base w-1/2 p-4 mr-2 rounded-md bg-[#00b6aa1a] capitalize text-center relative flex items-center justify-center font-semibold">
                      5 signatories
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div
          className="mt-2"
          onClick={(e: any) => e.target.nextSibling.classList.toggle('activeTab')}
        >
          <div className="text-SeabiscuitDark200ThemeColor border-[#122B464D] border px-4 py-2 rounded-md flex justify-between items-center font-semibold">
            Grand total
            <div className="flex items-center">
              <div className="status text-SeabiscuitGreenThemeColor bg-[#00B6AA1A] w-40 text-center rounded-full py-1 px-4 text-sm">
                $11,545
              </div>

              <div className="status text-SeabiscuitGreenThemeColor rounded-full p px-4">
                <AddCircleOutline />
              </div>
            </div>
          </div>
          <div className="faqs-content">
            <div className="faqs-content-inside mt-4">
              {grandTotal.length && (
                <>
                  <div className="flex">
                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-3/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Price group
                    </p>

                    <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 pl-1 pb-1 font-semibold mr-2 rounded-md capitalize">
                      Group total
                    </p>
                  </div>

                  {grandTotal.map((item: any, index: number) => {
                    return (
                      <>
                        <div className="flex items-center justify-between w-full mb-2">
                          <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize">
                            <span>{item.lineItem}</span>
                          </p>

                          <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                            <span className="mr-4">${item.total}</span>
                          </p>
                        </div>
                      </>
                    )
                  })}
                  <div className="flex">
                    <p className="text-SeabiscuitWhiteThemeColor xl:text-sm xxl:text-base w-3/4 p-4 mr-2 rounded-md bg-[#00B6AA] text-left relative flex items-center font-semibold">
                      Grand total
                    </p>
                    <p className="text-SeabiscuitWhiteThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-md bg-[#00B6AA] capitalize text-center relative flex items-center justify-center font-semibold">
                      $2343
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomAccordion
