import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { IRiderTeamMemberInterface } from '../../models/rider-team-member/riderTeamMember.interface'
import { IUserDocument } from '../../models/user-documents/user-documents.interface'

export type sharingMailData = {
  email: string
  selected: boolean
}

export interface IPaperworkTeamMemberData {
  signatoryId: string
  signatoryName: string
  signatoryProfilePicture: string
  signatoryDefaultRole: string
  paperwork_data: IUserDocument[]
  problem_found: number
}
export interface IPaperworkTeam extends IRiderTeamMemberInterface {
  member: IPaperworkTeamMemberData | null
}

type IInitialSystemState = {
  docSharingMails?: sharingMailData[] | []
  riderTeamMembers: IPaperworkTeam[][]
  riderTeamMembersUpdates: number
}

const initialSystemState: IInitialSystemState = {
  docSharingMails: [],
  riderTeamMembers: [],
  riderTeamMembersUpdates: 0,
}

const paperworkSlice = createSlice({
  name: 'paperworks',
  initialState: initialSystemState,
  reducers: {
    setDocSharingMail(state, { payload }) {
      state.docSharingMails = payload
    },
    setPaperworkRiderTeamMembers(state, { payload }) {
      state.riderTeamMembers = payload
    },
    setRidersTeamMembersUpdates(state) {
      state.riderTeamMembersUpdates += 1
    },
  },
})

export const { setDocSharingMail, setPaperworkRiderTeamMembers, setRidersTeamMembersUpdates } =
  paperworkSlice.actions

export const selectDocSharingMails = (state: RootState) => state.paperworks.docSharingMails ?? []
export const selectPaperworkRiderTeamMembers = (state: RootState) =>
  state.paperworks.riderTeamMembers ?? []
export const selectRiderTeamMembersUpdates = (state: RootState) =>
  state.paperworks.riderTeamMembersUpdates ?? 0

export default paperworkSlice.reducer
