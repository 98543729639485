import { AutorenewRounded } from '@mui/icons-material'
import nextIcon from '../../assets/next.svg'

const UserSignupViewComponentButton: React.FC<{
  className?: string
  caption: string
  type?: any
  kind: number
  loading?: boolean
  disabled?: boolean
  onClick?: () => void
  icon?: boolean
}> = ({ className, caption, type, kind, disabled, onClick, icon, loading }) => {
  const buttonTypeClassGenerator = () => {
    switch (kind) {
      case 1:
        return 'items-center py-4 rounded-2xl w-full relative bg-SeabiscuitMainThemeColor text-sm text-white shadow-xl disabled:opacity-[.5] border-1 border-transparent border-solid'
      case 2:
        return 'items-center py-4 rounded-2xl w-full relative bg-white border-[1px] text-sm border-solid border-SeabiscuitMainThemeColor text-SeabiscuitMainThemeColor shadow-xl disabled:opacity-[.5]'
      case 3:
        return 'items-center py-4 rounded-2xl w-full relative bg-[#EEEEEF] text-[#484646] text-sm shadow-xl disabled:opacity-[.5] border-1 border-transparent border-solid'
      case 4:
        return 'w-full h-12 flex items-center justify-center py-2 px-4 border !border-white border-solid rounded-2xl shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor nextBtnShadow bg-white opacity-80 hover:opacity-100 focus:outline-none focus:ring-0'
    }
  }
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${buttonTypeClassGenerator()} ${className} nextBtnShadow`}
      disabled={disabled ?? loading === true}
    >
      {loading === true ? (
        <AutorenewRounded fontSize="small" className="animate-spin" />
      ) : (
        <>
          {caption}
          {icon !== false && kind !== 4 && (
            <img
              src={nextIcon}
              alt="next"
              className="absolute right-2 top-[50%] translate-y-[-50%]"
            />
          )}
        </>
      )}
    </button>
  )
}

UserSignupViewComponentButton.defaultProps = {
  type: 'button',
}

export default UserSignupViewComponentButton
