import { IonButton, IonSpinner } from '@ionic/react'
import clsx from 'clsx'
import { createString } from '../../../helpers/helpers'
import './button.css'
import IButtonProps from './button.types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const Button = (props: IButtonProps) => {
  return (
    <IonButton
      size='small'
      className={clsx(
        'ion-text-wrap',
        props.className,
        props.loading && 'loading',
        props.background && createString(['ion-color', props.background], '-')
      )}
      onClick={props.onClick}
      style={{
        ...(props.textColor && { color: props.textColor }),
      }}
      strong={!!props.bold}
      disabled={(props.disabled || props.loading) ?? false}
      {...{
        ...(props.fullWidth && { expand: 'block' }),
        ...(props.outlined && { fill: 'outline' }),
      }}
    >
      {props.loading ? (
        <IonSpinner name="crescent" className="mx-auto" />
      ) : (
        props?.children ?? props.label
      )}
    </IonButton>
  )
}

export default Button
