import React from 'react'
import { IconLoader } from '../../../../../components/icons/IconLoader'
import ViewsLoader from '../../../../../components/loader/ViewsLoader'
import FirestoreService from '../../../../../services/firestoreService'
import { CONST } from '../../../../../const/const'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import Select from 'react-select'
import {
  customStyles,
  SexRestOptions,
} from '../../../../../components/customStyles/ReactSelectCustomStyle'
import { disciplineData } from '../../../../../fakeData/disciplineList'
import { horseBreedList } from '../../../../../fakeData/horseBreedList'
import { horseHeightList } from '../../../../../fakeData/HorseHeightList'
import { zoneList } from '../../../../../fakeData/ZoneList'
import { horseColorList } from '../../../../../fakeData/horseColorList'
import { COUNTRIES_LIST } from '../../../../../fakeData/countriesList'
import { amateurList } from '../../../../../fakeData/amateurList'
import { getReactPickerDate } from '../../../../../models/interface.helper'
import CustomDatePicker from '../../../../../components/common/inputs/CustomDatePicker'

interface IProps {
  keyValue: string
  userId?: string
  horseId?: string
  isHorse?: boolean
  cb: (value: string) => void
  title: string
}

interface IOption {
  label: string
  value: string
}

export const TextField: React.FC<IProps> = ({ title, keyValue, cb, userId, isHorse, horseId }) => {
  const [value, setValue] = React.useState<string>(title)
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState(false)
  const toastFunctions = useToasterHelper()
  const [selectData, setSelectData] = React.useState<IOption[]>([])
  const [isDate, setIsDate] = React.useState(false)
  React.useEffect(() => {
    switch (keyValue) {
      case 'horseDob':
        setIsDate(true)
        break
      case 'userAmateurProStatus':
        setSelectData(amateurList)
        break
      case 'userDiscipline':
      case 'horseDiscipline':
        setSelectData(disciplineData)
        break
      case 'horseBreed':
        setSelectData(horseBreedList)
        break
      case 'horseHeight':
        setSelectData(horseHeightList)
        break
      case 'horseGender':
        setSelectData(SexRestOptions as IOption[])
        break
      case 'horseZone':
        setSelectData(zoneList)
        break
      case 'horseColor':
        setSelectData(horseColorList)
        break
      case 'userNationality':
      case 'horseCountry':
        setSelectData(COUNTRIES_LIST)
        break
      default:
        break
    }
  }, [keyValue])
  const submitHandler = async () => {
    if (!value) {
      return toastFunctions.error({ message: 'Field is required' })
    }

    setLoading(true)
    const updateData = {
      [keyValue]: value,
    }

    console.log('=>(TextField.tsx:89) keyValue', keyValue)

    console.log('=>(TextField.tsx:91) value', value)

    console.log('=>(TextField.tsx:89) updateData', updateData)

    try {
      if (isHorse) {
        console.log('=>(TextField.tsx:99) horseId', horseId)
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME,
          horseId,
          updateData
        )
      } else {
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
          userId,
          updateData
        )
      }

      cb(value)
    } catch (e) {
      console.log('=>(UpdateUserNumberModal.tsx:314) e', e)
      return toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setLoading(false)
      setEditMode(false)
    }
  }

  // if (selectData[0]) {
  //   return (
  //     <div className={'flex gap-5 items-center'}>
  //       <Select
  //         isMulti={false}
  //         onChange={(item: any) => setValue(item.value)}
  //         isDisabled={false}
  //         onBlur={() => null}
  //         // placeholder={placeholder}
  //         options={disciplineData}
  //         // components={{
  //         //   Option: CustomOption as any,
  //         // }}
  //         className={`border-[#D3DAEE] border py-[1px] !text-sm w-[200px] rounded-xl text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor`}
  //         isClearable={false}
  //         styles={customStyles}
  //         // value={value as IOption}
  //         isSearchable={false}
  //       />
  //     </div>
  //   )
  // }

  if (!editMode && value) {
    return <span>{value}</span>
  }

  if (!editMode && !title) {
    return (
      <div className={'flex gap-2'}>
        <span>{'Missing'}</span>
        <span>•</span>
        <button onClick={() => setEditMode(true)} className={'underline hover:no-underline'}>
          Edit
        </button>
      </div>
    )
  }
  return (
    <div className={'flex gap-5 items-center'}>
      {isDate ? (
        <CustomDatePicker
          onChange={(e) => setValue(e.currentTarget.value)}
          selected={getReactPickerDate(value)}
          peekNextMonth
          disabled={false}
          dropdownMode="select"
          showMonthDropdown
          showYearDropdown
          maxDate={new Date()}
          placeholderText="Select date"
          className={`rounded-xl w-full border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] placeholder:text-[#122B4680] placeholder:text-sm opacity-1 py-3 text-sm border-[#D3DAEE]`}
        />
      ) : selectData[0] ? (
        <Select
          isMulti={false}
          onChange={(item: any) => setValue(item.value)}
          isDisabled={false}
          onBlur={() => null}
          // placeholder={placeholder}
          options={selectData}
          // components={{
          //   Option: CustomOption as any,
          // }}
          className={`border-[#D3DAEE] border py-[1px] !text-sm w-[200px] rounded-xl text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor`}
          isClearable={false}
          styles={customStyles}
          // value={value as IOption}
          isSearchable={false}
        />
      ) : (
        <input
          disabled={loading}
          className={'rounded-lg border-0 text-black disabled:bg-[#F6F7FB]'}
          type="text"
          onChange={(e) => setValue(e.target.value)}
        />
      )}

      {loading ? (
        <span className={'w-[24px]'}>
          <ViewsLoader
            className="flex items-center w-full justify-center min-h-[10px]"
            size="sm"
            color="#F7074F"
          />
        </span>
      ) : (
        <button onClick={submitHandler} className={'text-[#E80000]'}>
          Save
        </button>
      )}
    </div>
  )
}
