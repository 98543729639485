// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React from 'react'

import GreenCheck from '../assets/green-check.svg'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export interface PropTypes {
  type?: string
  value?: string
  onChange?: (value: any) => void
  onClick?: (value: any) => void
  disabled?: boolean
  placeholder?: string
  notChecked?: boolean | undefined
  outlined?: boolean
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const CheckedInput: React.FC<PropTypes> = ({
  type,
  value,
  onChange,
  disabled,
  placeholder,
  notChecked,
  onClick,
  outlined,
}) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div
      className={`text-[14px] w-full ${
        outlined
          ? 'bg-white border-solid border-[1px] border-[#D3DAEE]'
          : 'bg-SeabiscuitGrayThemeColor'
      } rounded-md flex items-center p-4 h-12`}
      onClick={onClick}
    >
      <input
        type={type}
        value={disabled ? 'N/A' : value}
        onChange={onChange ?? (() => {})}
        className="w-full bg-transparent text-SeabiscuitDark200ThemeColor outline-0"
        disabled={disabled}
        placeholder={placeholder}
      />
      {!disabled && !notChecked && <img src={GreenCheck} alt="green check" />}
    </div>
  )
}

export default CheckedInput
