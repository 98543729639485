import { sumBy } from 'lodash'

import BillsHeader from './common/BillsHeader'

import { ModelBaseModel } from '../../../models/model-base/model-base.model'
import { IPaymentStatus } from '../../../models/event-registered-users/event-registered-users.interface'
import { IRegistrationFeesInterface } from '../../../models/registration-fees/registrationFees.interface'

import { CONST } from '../../../const/const'

type IDetailRow = {
  item: string
  numberOfUnit: number
  cost: number
  scratched: number
  refunded: number
  showRefund: boolean
  paymentStatus: IPaymentStatus
}

type ITotalRow = {
  numbersOfUnit: number
  price: string
  modalThemeColor: `#${string}`
  scratchedTotal: number
  refundTotal: number
  showRefund: boolean
  refundFeeTotalState: number
}

type IEventReviewPublishInput = {
  className?: string
  data?: any
  price?: any
  onClick?: any
  moreBtn?: boolean
  icon?: string
  style?: React.CSSProperties
}

type Props = {
  data: IRegistrationFeesInterface[]
  modalThemeColor: `#${string}`
  showRefund: boolean
  refundFeeTotalState: number
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

const EventReviewPublishInput: React.FC<IEventReviewPublishInput> = (args) => {
  const { className, data, price, onClick, moreBtn, icon, style = {} } = args

  return (
    <>
      {onClick ? (
        <div
          style={style}
          onClick={onClick}
          className={`${className} text-SeabiscuitDark200ThemeColor`}
        >
          <div className="text-SeabiscuitDark200ThemeColor mr-1 font-normal">{price}</div>
          <div>{data}</div>
          {moreBtn ? <span className="cursor-pointer underline">more</span> : null}
        </div>
      ) : (
        <div className={`${className} text-SeabiscuitDark200ThemeColor`} style={style}>
          {icon ? <img className="w-5 h-5 absolute left-3" src={icon} alt="icon" /> : null}
          <div className="text-SeabiscuitDark200ThemeColor mr-1 font-normal">{price}</div>
          <div>{data}</div>
          {moreBtn ? (
            <span className="text-SeabiscuitDark200ThemeColor cursor-pointer underline">more</span>
          ) : null}
        </div>
      )}
    </>
  )
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const DetailRow = ({
  item,
  numberOfUnit,
  cost,
  scratched,
  showRefund,
  refunded,
  paymentStatus,
}: IDetailRow) => {
  return (
    <div className="flex flex-col md:flex-row md:items-start w-full">
      <EventReviewPublishInput
        className={`rounded-lg h-12 bg-SeabiscuitGrayThemeColor text-sm p-3 m-1 ml-0 w-full ${!showRefund ? 'md:w-4/12' : ' md:w-3/12'}`}
        data={item}
      />
      <EventReviewPublishInput
        className={`rounded-lg bg-SeabiscuitGrayThemeColor relative flex items-center justify-center text-sm p-3 m-1 w-full ${!showRefund ? 'md:w-4/12' : ' md:w-3/12'} `}
        data={numberOfUnit}
      />
      <EventReviewPublishInput
        className={`rounded-lg bg-SeabiscuitGrayThemeColor relative flex items-center justify-center text-sm p-3 m-1 w-full ${!showRefund ? 'md:w-4/12' : ' md:w-3/12'} `}
        data={`$${cost}`}
        icon={
          paymentStatus === PAYMENT_STATUSES.PAID
            ? '/assets/cp_icons/Ok-3.svg'
            : '/assets/cp_icons/Cancel-2.svg'
        }
      />
      {showRefund && (
        <>
          {' '}
          <EventReviewPublishInput
            className="rounded-lg bg-SeabiscuitGrayThemeColor relative flex items-center justify-center text-sm p-3 m-1 w-full md:w-3/12"
            data={`${scratched > 0 ? 'Scratched' : 'No'}`}
          />
          <EventReviewPublishInput
            className="rounded-lg bg-SeabiscuitGrayThemeColor relative flex items-center justify-center text-sm p-3 m-1 w-full md:w-3/12"
            data={`$${refunded}`}
          />
        </>
      )}
    </div>
  )
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const TotalRow = (args: ITotalRow) => {
  const { numbersOfUnit, price, modalThemeColor, scratchedTotal, showRefund, refundFeeTotalState } =
    args

  return (
    <>
      {showRefund ? (
        <div className="flex flex-col md:flex-row md:items-start w-full">
          <EventReviewPublishInput
            className={
              'rounded-lg text-[#122B46] text-sm p-3 m-1 w-full md:w-[21%] font-semibold bg-SeabiscuitGreenLightThemeColor'
            }
            // style={{
            //     backgroundColor: args.modalThemeColor
            // }}
            data={`Total`}
          />
          <EventReviewPublishInput
            className="rounded-lg text-[#122B46] text-sm p-3 m-1 w-full md:w-10/12 font-semibold flex md:justify-end bg-SeabiscuitGreenLightThemeColor"
            price={`$${price} paid, `}
            data={`$${refundFeeTotalState} refunded`}
            // style={{
            //     backgroundColor: args.modalThemeColor
            // }}
          />
        </div>
      ) : (
        <div className="flex flex-col md:flex-row md:items-start w-full">
          <EventReviewPublishInput
            className={`rounded-lg bg-[#00B6AA1A] text-sm p-3 m-1 ml-0 w-full ${!showRefund ? 'md:w-4/12' : ' md:w-3/12'} font-semibold`}
            data={`Total`}
            style={{
              background: modalThemeColor,
            }}
          />
          <EventReviewPublishInput
            className={`rounded-lg bg-[#00B6AA1A] relative flex items-center justify-center text-sm p-3 m-1 ml-0 w-full ${!showRefund ? 'md:w-4/12' : ' md:w-3/12'} font-semibold`}
            data={`${numbersOfUnit} fees`}
            style={{
              background: modalThemeColor,
            }}
          />
          <EventReviewPublishInput
            className={`rounded-lg bg-[#00B6AA1A] relative flex items-center justify-center text-sm p-3 m-1 ml-0 w-full ${!showRefund ? 'md:w-4/12' : ' md:w-3/12'} font-semibold`}
            data={`$${price}`}
            style={{
              background: modalThemeColor,
            }}
          />
          {showRefund && (
            <>
              <EventReviewPublishInput
                className="rounded-lg bg-[#00B6AA1A] relative flex items-center justify-center text-sm p-3 m-1 ml-0 w-3/12 font-semibold"
                data={`$${scratchedTotal}`}
                style={{
                  background: modalThemeColor,
                }}
              />
              <EventReviewPublishInput
                className="rounded-lg bg-[#00B6AA1A] relative flex items-center justify-center text-sm p-3 m-1 ml-0 w-3/12 font-semibold"
                data={`$${scratchedTotal}`}
                style={{
                  background: modalThemeColor,
                }}
              />
            </>
          )}
        </div>
      )}
    </>
  )
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const BillDetailsFeesData = (props: Props) => {
  let feestotal = 0
  const { refundFeeTotalState } = props

  const selectedUnitsCount = sumBy(props?.data, 'selectedUnitsCount')

  return (
    <>
      {selectedUnitsCount ? (
        <div className="flex items-center">
          <BillsHeader title="Item" tooltipText="Line item" />
          <BillsHeader title="Units" tooltipText="Number of units" />
          <BillsHeader title="Price per unit" tooltipText="Price per unit" />
          {props.showRefund && (
            <>
              <BillsHeader title="Scratched?" tooltipText="Scratched" />
              <BillsHeader title="Refund" tooltipText="Refund" />
            </>
          )}
        </div>
      ) : null}

      {selectedUnitsCount ? (
        props?.data.map((item, index: number) => {
          feestotal += item.feesPrice * item.selectedUnitsCount
          return (
            <DetailRow
              key={`${JSON.stringify(item)}${index}`}
              item={item?.feesTitle ?? ''}
              numberOfUnit={item?.selectedUnitsCount ?? 0}
              cost={item?.feesPrice ?? 0}
              scratched={item?.amountScratched}
              refunded={item?.amountRefunded}
              showRefund={props.showRefund}
              paymentStatus={item.paymentStatus ?? null}
            />
          )
        })
      ) : (
        <span className="w-full text-sm text-SeabiscuitLightParagraphTextColor text-center">
          No fees added
        </span>
      )}

      {selectedUnitsCount ? (
        <TotalRow
          modalThemeColor={props.modalThemeColor}
          numbersOfUnit={
            Array.isArray(props?.data)
              ? props?.data.reduce((accumulator, currentValue) => {
                  return +accumulator + +currentValue.selectedUnitsCount
                }, 0)
              : 0
          }
          price={new ModelBaseModel().getNum(feestotal).toString()}
          scratchedTotal={0}
          refundTotal={0}
          showRefund={props.showRefund}
          refundFeeTotalState={refundFeeTotalState}
        />
      ) : null}
    </>
  )
}

export default BillDetailsFeesData
