import { useEffect, useState } from 'react'

// Types
import { ITeamMember } from '../../../models/users/user.interface'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectProfileData, selectUserId } from '../../../store/user/userSlice'

import moment from 'moment'
// Styles
import './allEventRootPage.css'

// Helpers
import { useIonRouter } from '@ionic/react'
import { getUserFullName } from '../../../helpers/helpers'

import { where } from 'firebase/firestore'
import { useParams } from 'react-router'
import ProfileDetailsCard from '../../../components/pageWise/visitedUserDetails/profile-details-card/ProfileDetailsCard'
import { CONST } from '../../../const/const'
import { IHorseData } from '../../../models/horse/horse.interface'
import { HorseModel } from '../../../models/horse/horse.model'
import { getConvertedData } from '../../../models/interface.helper'
import { IUserHorseMappingInterface } from '../../../models/user-horse-mapping/userHorseMapping.interface'
import { UserHorseMappingModel } from '../../../models/user-horse-mapping/userHorseMapping.model'
import { UserModel } from '../../../models/users/user.model'
import FirestoreService from '../../../services/firestoreService'
import { selectHorses, setHorses } from '../../../store/horses/horseSlice'
import { selectVisitedUserDetailsR } from '../../../store/profilePage/profilePageSlice'
import {
  setRegisterId,
  setWaitlistId,
} from '../../../store/registerExhibitor/registerExhibitorSlice'
import CompetitorRootSideProfileDisplayTabs from './Tabs/CompetitorRootSideProfileDisplayTabs'
import ProfileHorseDataTab from './Tabs/ProfileHorseDataTab'
import TeamMembersTab from './Tabs/ProfileTeamMembersTab'
import ProfileUserProfileDataTab from './Tabs/ProfileUserProfileDataTab'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */

const HORSES_MAP_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_HORSE_MAPPING
const HORSES_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES
const USER_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS

const HorseSidePanel = ({
  isMyProfile,
  showHorseData,
  setShowHorseData,
}: {
  isMyProfile: boolean
  showHorseData: IHorseData[]
  setShowHorseData: any
}) => {
  // Hooks and vars
  const userId = useAppSelector(selectUserId)
  const [activeTab, setActiveTab] = useState(3)
  const router = useIonRouter()

  const dispatch = useAppDispatch()

  const visitedUserDetails = useAppSelector(selectVisitedUserDetailsR)
  const horseData = useAppSelector(selectHorses)
  const userDocumentData = useAppSelector(selectProfileData)
  const [teamMatesListWithMe, setTeamMatesListWithMe] = useState<ITeamMember[]>([])
  // const [userData, setUserData] = useState()
  const params = useParams()
  const profileDetails = visitedUserDetails?.profileDetails ?? null
  // const horseData = profileDetails?.userHorses ?? [];
  const teamMembersList = profileDetails?.userTeamMembers ?? []

  const setImageUrl = useState<any>('')[1]
  const setUploadProgress = useState(-1)[1]

  const handleGetUserData = async (userId: any) => {
    let userDataSnap = await FirestoreService.getItem(USER_COLLECTION.NAME, userId)

    let userDara = getConvertedData(UserModel.fromFirestoreDoc(userDataSnap).toObject())
    if (0) console.log({ userDara })
  }

  const handlegethosedata = async (userId: any) => {
    let horses_map: IUserHorseMappingInterface[] = []
    let horses: IHorseData[] = []
    let horseIds: string[] = []
    let fetchedHorseSnaps = await FirestoreService.filterItems(HORSES_MAP_COLLECTION.NAME, [
      where(HORSES_MAP_COLLECTION.FIELDS.HORSE_OWNER_ID.KEY, '==', userId),
    ])

    fetchedHorseSnaps.forEach((currDoc) => {
      horses_map.push(getConvertedData(UserHorseMappingModel.fromFirestoreDoc(currDoc).toObject()))
    })

    horses_map.forEach((data) => {
      if (data.horseId) horseIds.push(data.horseId)
    })

    let horsesnaps = await FirestoreService.getItemsUsingIds(HORSES_COLLECTION.NAME, horseIds)

    horsesnaps.forEach((currDoc) => {
      horses.push(getConvertedData(HorseModel.fromFirestoreDoc(currDoc).toObject()))
    })

    dispatch(setHorses(horses))
  }

  useEffect(() => {
    if ('userId' in params) {
      if (params.userId) {
        handlegethosedata(params?.userId)
        handleGetUserData(params?.userId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (profileDetails?.userProfilePicture) {
      setImageUrl(profileDetails?.userProfilePicture)
    } else {
      setUploadProgress(-1)
      setImageUrl('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails?.userProfilePicture])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    const UNKNOWN = 'Unknown'
    const owner = (() => {
      const memberName = profileDetails ? getUserFullName(profileDetails) : UNKNOWN
      return {
        memberName: memberName,
        memberId: userId ?? '',
        defaultRole: 'Owner',
        memberProfilePicture: profileDetails?.userProfilePicture ?? '',
        memberStatus: '1',
        memberprofileSynced: false,
        memberAddress: profileDetails?.userAddress ?? '',
        memberEmail: profileDetails?.userEmail ?? '',
        memberShipActive: false,
        memberSafeSupportTraining: false,
        memberCountry: profileDetails?.userNationality ?? '',
      }
    })()

    if (owner) setTeamMatesListWithMe([owner, ...teamMembersList])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMembersList, profileDetails])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    const cond =
      router?.routeInfo?.routeOptions?.as === 'horse'
        ? 3
        : router?.routeInfo?.routeOptions?.as === 'user'
          ? 1
          : router?.routeInfo?.routeOptions?.as === 'team'
            ? 2
            : 1
    if (cond) {
      setActiveTab(3)
    }

    return () => {
      dispatch(setRegisterId(null))
      dispatch(setWaitlistId(null))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const setActiveTabVal = (tabToSwitch: number) => {
    setActiveTab(tabToSwitch)
  }

  return (
    <div className="flex flex-col items-center pt-7 pl-7 pr-7 ">
      <div
        onClick={() => setShowHorseData([])}
        className="absolute left-[37px] top-[37px] cursor-pointer w-[30px]  h-[5px]"
      >
        <svg
          className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium text-SeabiscuitDark200ThemeColor mr-2 css-i4bv87-MuiSvgIcon-root"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="KeyboardBackspaceIcon"
        >
          <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path>
        </svg>
      </div>
      <div className="bg-SeabiscuitLightGrayThemeColor rounded-xl flex flex-col items-center justify-center px-6 py-5 w-[375px]">
        <ProfileDetailsCard showHorseData={showHorseData} />
        <div
          className={`${profileDetails?.userDiscipline || profileDetails?.userZone || profileDetails?.userNationality ? 'bg-white' : ''}  px-4 py-2 flex mt-2 flex-wrap rouded-lg w-full rounded-xl justify-between`}
        >
          {profileDetails?.userDiscipline && profileDetails?.userDiscipline !== '' && (
            <>
              <div className="w-1/3 flex flex-col justify-between items-center px-2 text-center">
                <span className="font-bold text-SeabiscuitDark200ThemeColor capitalize h-full flex items-center break-all leading-[1.2] mb-1 md:text-[14px] 2xl:text-base">
                  {!showHorseData.length && profileDetails?.userDiscipline}
                  {showHorseData && (showHorseData[0]?.horseDiscipline as any)}
                </span>
                <span className="text-sm text-SeabiscuitLightParagraphTextColor">
                  horse Discipline
                </span>
              </div>
            </>
          )}

          {profileDetails?.userZone && profileDetails?.userZone !== '' && (
            <div className="w-1/3 flex flex-col justify-between items-center px-2 text-center">
              <span className="font-bold text-SeabiscuitDark200ThemeColor capitalize h-full flex items-center break-all leading-[1.2] mb-1 md:text-[14px] 2xl:text-base">
                {!showHorseData.length && profileDetails?.userZone}
                {showHorseData && (showHorseData[0]?.horseZone as any)}
              </span>
              <span className="text-sm text-SeabiscuitLightParagraphTextColor">Zone</span>
            </div>
          )}

          {profileDetails?.userNationality && profileDetails?.userNationality !== '' && (
            <div className="w-1/3 flex flex-col justify-between items-center px-2 text-center">
              <span className="font-bold text-SeabiscuitDark200ThemeColor capitalize h-full flex items-center break-all leading-[1.2] mb-1 md:text-[14px] 2xl:text-base break-normal">
                {!showHorseData.length && profileDetails?.userNationality}
                {showHorseData && (showHorseData[0]?.horseCountry as any)}
              </span>
              <span className="text-sm text-SeabiscuitLightParagraphTextColor">Nationality</span>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col items-center pt-[22px] w-full  bg-white">
        <div className="flex flex-wrap rouded-lg w-full items-start justify-center">
          <div
            className={`grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid ${activeTab === 1 ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'}`}
            onClick={() => setActiveTabVal(1)}
          >
            {activeTab === 1 ? (
              <img src="/assets/cp_icons/User-2.svg" alt="userIcons" />
            ) : (
              <img src="/assets/cp_icons/User-1.svg" alt="userIcons" />
            )}
          </div>
          <div
            className={`grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid ${activeTab === 2 ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'}`}
            onClick={() => setActiveTabVal(2)}
          >
            {activeTab === 2 ? (
              <img src="/assets/cp_icons/User_Groups-2.svg" alt="userIcons" />
            ) : (
              <img src="/assets/cp_icons/User_Groups-1.svg" alt="userIcons" />
            )}
          </div>
          <div
            className={`grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid ${activeTab === 3 ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'}`}
            onClick={() => {
              setActiveTabVal(3)
            }}
          >
            {activeTab === 3 ? (
              <img src="/assets/og_icons/YearofHorse-2.svg" alt="userIcons" />
            ) : (
              <img src="/assets/og_icons/YearofHorse-1.svg" alt="userIcons" />
            )}
          </div>
        </div>

        <div className="scrollable overflow-y-auto w-full h-[500px] mt-2 pt-2">
          {isMyProfile ? (
            <div className="content px-2">
              {activeTab === 1 ? (
                <ProfileUserProfileDataTab
                  profileDetails={profileDetails}
                  userDocumentData={userDocumentData}
                />
              ) : null}

              {activeTab === 2 ? (
                <TeamMembersTab
                  tabHeading="Team"
                  team_members={
                    showHorseData.length && showHorseData?.[0]?.horseTeamMembers
                      ? showHorseData?.[0]?.horseTeamMembers
                      : teamMatesListWithMe
                  }
                />
              ) : null}

              {activeTab === 3 ? (
                !!horseData?.length ? (
                  <ProfileHorseDataTab
                    horses={horseData}
                    setShowHorseData={setShowHorseData}
                    showHorseData={showHorseData[0]}
                  />
                ) : (
                  <span className="w-full text-sm text-SeabiscuitLightParagraphTextColor text-center">
                    No data found
                  </span>
                )
              ) : null}
            </div>
          ) : (
            <div className="content px-2">
              {activeTab === 1 ? (
                <>
                  {+moment(profileDetails?.userDOB).fromNow().split(' ')[0] >= 18 ? (
                    !profileDetails?.userAccountPrivacy ? (
                      <ProfileUserProfileDataTab
                        profileDetails={profileDetails}
                        userDocumentData={userDocumentData}
                      />
                    ) : (
                      <CompetitorRootSideProfileDisplayTabs />
                    )
                  ) : null}
                </>
              ) : null}

              {activeTab === 2 ? (
                teamMatesListWithMe && !!teamMatesListWithMe.length ? (
                  <>
                    {' '}
                    {+moment(profileDetails?.userDOB).fromNow().split(' ')[0] >= 18 ? (
                      !profileDetails?.userAccountPrivacy ? (
                        <TeamMembersTab
                          tabHeading="Team"
                          team_members={
                            showHorseData?.[0]?.horseTeamMembers
                              ? showHorseData?.[0]?.horseTeamMembers
                              : teamMatesListWithMe
                          }
                        />
                      ) : (
                        <CompetitorRootSideProfileDisplayTabs />
                      )
                    ) : null}
                  </>
                ) : null
              ) : null}

              {activeTab === 3 ? (
                horseData && !!horseData.length ? (
                  <>
                    {+moment(profileDetails?.userDOB).fromNow().split(' ')[0] >= 18 ? (
                      !profileDetails?.userAccountPrivacy ? (
                        <ProfileHorseDataTab
                          horses={horseData}
                          setShowHorseData={setShowHorseData}
                          showHorseData={showHorseData[0]}
                        />
                      ) : (
                        <CompetitorRootSideProfileDisplayTabs />
                      )
                    ) : null}
                  </>
                ) : null
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HorseSidePanel
