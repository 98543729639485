import { Close } from '@mui/icons-material'
import { MODAL_CONSTS } from '../../const/modal-const'

type Props = {
  show?: boolean
  handleModal?: any
  dataToPassOn?: any
}

const JudgingLicenceInformation = (props: Props) => {
  return (
    <>
      <div
        className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered relative lg:w-3/5 xl:w-1/2 m-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-2xl outline-none text-current pb-10 pt-8 px-8">
            <span
              onClick={() => props.handleModal(false, MODAL_CONSTS.JUDGING_LICENCE)}
              className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
            >
              <Close className="text-[#122B46]" />
            </span>

            <div className="modal-header rounded-t-xl font-Poppins">
              <h5 className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-bold">
                Judging License
              </h5>

              <div className="allInfo text-[#122B46] h-[calc(100vh-40vh)] overflow-y-auto pr-2">
                <h5 className="text-[#122B46] font-semibold my-5">USEF Judge License Levels</h5>
                <p className="mt-4 font-[Poppins]">
                  “Recorded” or “small r” — licensed to judge through Second Level.
                </p>
                <p className="mt-4">
                  “Registered” or “large R” — licensed to judge through Fourth Level.
                </p>
                <p className="mt-4">
                  “Senior” — licensed to judge through Grand Prix at national competitions.
                </p>
                <p className="mt-4">
                  “Dressage Sport Horse”—licensed to judge in-hand classes. This certification is
                  broken into two levels: “r” (DSH-r) and “R” (DSH-R), which are comparable to the
                  recorded and registered titles described above.
                </p>

                <h5 className="text-[#122B46] font-semibold my-5">FEI Judge License Levels</h5>
                <p className="mt-4">
                  2 Star - “new” — licensed to judge a limited range of international competitions
                  through Prix St. Georges and Intermediaire I (including 3* small-tour competitions
                  and CDI 1* and 2* competitions); this rating is for judges whose home countries
                  have no Grand Prix classes.
                </p>
                <p className="mt-4">
                  3 Star - (formerly known as “international candidate” or “C”)—licensed to judge
                  international competitions through Grand Prix, except for Olympic Games, FEI Grand
                  Prix Championships and CDIs above the 3* level.
                </p>
                <p className="mt-4">
                  4 Star - (formerly known as “international” or “I”) — licensed to judge most
                  international competitions, excluding the Olympic Games and World Championships.
                </p>
                <p className="mt-4">
                  5 Star - (formerly known as “official international” or “O”) — qualified to judge
                  all international competitions.
                </p>
              </div>

              <button
                onClick={() => props.handleModal(false, MODAL_CONSTS.JUDGING_LICENCE)}
                type="button"
                className="w-full mt-6 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default JudgingLicenceInformation
