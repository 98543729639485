import React from 'react'

interface IProps {
  title: string
  desc: string
}

export const StepTitle: React.FC<IProps> = ({ title, desc }) => {
  return (
    <div className={'py-5'}>
      <h3 className={'text-[#122B46] text-[18px]'}>{title}</h3>
      {desc && <p className={'text-[#122B4680] text-[14px] mt-3'}>{desc}</p>}
    </div>
  )
}
