import ActiveTabSection from './active-tab-section'
import HorseProfileImageSection from './image-section'
import HorsePublicProfileTabSection from './tab-section'
import IProfileCard from './types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const HorseProfileCard = (props: IProfileCard['IProps']) => {
  return (
    <div className="p-7">
      <div className="w-full pb-[30px]">
        <HorseProfileImageSection {...props} />
        <HorsePublicProfileTabSection {...props} />
        <ActiveTabSection
          activeTab={props.activeTab}
          horseLineage={props.horseLineage}
          horseTeamMembers={props.horseTeamMembers}
        />
      </div>
    </div>
  )
}

export default HorseProfileCard
