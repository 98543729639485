import { where } from 'firebase/firestore'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import InfiniteScrollDataTable from '../../../../components/common/tables/InfiniteScrollDataTable'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { IAssignedTicket } from '../../../../models/assigned-tickets/assigned-tickets.interface'
import { AssignTicketsModal } from '../../../../models/assigned-tickets/assigned-tickets.model'
import { getConvertedData } from '../../../../models/interface.helper'
import FirestoreService from '../../../../services/firestoreService'
import NoDataAvailable from '../../../myEvent/component/NoDataAvailable'

const ASSIGNED_REGISTERED_TICKETS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.ASSIGNED_TICKETS

const TicketHoldersTab = ({
  eventId,
  setCheckedIn,
  setTotal,
}: {
  setCheckedIn: any
  setTotal: any
  eventId: string | null
}) => {
  const toastFunctions = useToasterHelper()

  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [tickets, setTickets] = useState<IAssignedTicket[]>([])

  const Cols = [
    {
      name: 'Ticket Holders',
      draggable: false,
      cell: (row: IAssignedTicket) => (
        <div className="flex gap-2.5 items-center">
          <img src={'assets/og_icons/Two Tickets.svg'} alt="icon" className="w-[20px] h-[20px]" />
          <span className="text-[14px] text-[#122B46] font-bold capitalize">
            {row?.ticketHolderName}
          </span>
        </div>
      ),
      width: 'auto',
    },
    {
      name: 'Tickets',
      draggable: false,
      cell: (row: IAssignedTicket) => (
        <div className="flex gap-2.5 items-center">
          <img src="assets/og_icons/singleTicket.svg" alt="icon" className="w-[20px] h-[20px]" />
          {row?.ticketTitle ? (
            <p className="text-SeabiscuitDark200ThemeColor block capitalize font-normal">
              {row?.ticketTitle}
            </p>
          ) : (
            <p className="text-SeabiscuitDark200ThemeColor block capitalize font-normal">N/A</p>
          )}
        </div>
      ),
      width: '13%',
    },
    {
      name: 'Ticket Buyers',
      draggable: false,
      cell: (row: IAssignedTicket) => (
        <>
          {row.isPlatformUser ? (
            <Link
              to={`${CONST.ROUTES.USER_PROFILE.URL}/${row.userId}`}
              className="flex gap-2.5 items-center underline"
            >
              <img src="assets/og_icons/User.svg" alt="icon" className="w-[22px] h-[22px]" />
              <p className="text-SeabiscuitDark200ThemeColor capitalize font-normal">
                {row?.ticketBuyerName ?? 'N/A'}
              </p>
            </Link>
          ) : (
            <div className="flex gap-2.5 items-center">
              <img src="assets/og_icons/User.svg" alt="icon" className="w-[22px] h-[22px]" />
              <p className="text-SeabiscuitDark200ThemeColor capitalize font-normal">
                {row?.ticketBuyerName ?? 'N/A'}
              </p>
            </div>
          )}
        </>
      ),
      width: '13%',
    },
    {
      name: 'Purchase date',
      draggable: false,
      cell: (row: IAssignedTicket) => (
        <>
          <div className="flex gap-2.5 items-center">
            <img src="assets/img/allevent/Calendar.svg" alt="icon" className="w-[22px] h-[22px]" />
            <p className="text-SeabiscuitDark200ThemeColor capitalize font-normal">
              {row?.created ? moment(row?.created ?? new Date()).format('MMM DD, YYYY') : 'N/A'}
            </p>
          </div>
        </>
      ),
      width: '13%',
    },
    {
      name: 'Check-in time',
      draggable: false,
      cell: (row: IAssignedTicket) => {
        //console.log("the row: ", row)
        return (
          <div className="flex gap-2.5 items-center">
            <img src="assets/og_icons/Clock.svg" alt="icon" className="w-[20px] h-[20px]" />
            <p className="text-SeabiscuitDark200ThemeColor capitalize font-normal">
              {row?.checkInDate ? moment(row?.checkInDate).format('lll') : 'Not checked in'}
            </p>
          </div>
        )
      },
      width: '13%',
    },
    {
      name: 'Check-in',
      draggable: false,
      cell: (row: IAssignedTicket) => (
        <>
          <button
            className={` py-2 ${!!!row?.checkInDate ? ' bg-[#F2F3F8] text-SeabiscuitDark200ThemeColor' : 'text-[#00B6AA] bg-[#00B6AA1A]'} rounded-md min-w-[95px]`}
            onClick={() => handleAssigned(row)}
          >
            {!!!row?.checkInDate ? 'Check In' : 'Checked In'}
          </button>
        </>
      ),
      width: '9%',
    },
  ]

  const handleAssigned = async (row: IAssignedTicket) => {
    if (row.id && row.ticketStatus !== 'used') {
      const mutate = tickets.map((ticket) => {
        if (row.id === ticket.id) {
          return {
            ...ticket,
            ticketStatus: 'used',
            checkInDate: new Date(),
          } as IAssignedTicket
        } else {
          return ticket
        }
      })

      setTickets(mutate)

      await FirestoreService.updateItem(
        ASSIGNED_REGISTERED_TICKETS.NAME,
        row.id,
        new AssignTicketsModal({
          ...row,
          ticketStatus: 'used',
          checkInDate: new Date(),
        }).toFirestore()
      )
    }

    return 1
  }

  const handleTicketHolderData = async (
    eventId: string,
    assignedTickets: IAssignedTicket[] = []
  ) => {
    if (!assignedTickets.length) {
      setLoading(true)
    }

    try {
      const ORDER_DIRECTION = 'asc'
      let assignedTickets_: IAssignedTicket[] = []
      const ORDER_BY_FIELD = ASSIGNED_REGISTERED_TICKETS.FIELDS.CREATED

      const assignedTicketSnapshots = await FirestoreService.filterItems(
        ASSIGNED_REGISTERED_TICKETS.NAME,
        [
          where(ASSIGNED_REGISTERED_TICKETS.FIELDS.EVENT_DOC_ID, '==', eventId),
          where(ASSIGNED_REGISTERED_TICKETS.FIELDS.PAYMENT_STATUS, '==', 'paid'),
          where(ASSIGNED_REGISTERED_TICKETS.FIELDS.TICKET_STATUS.KEY, 'in', [
            ASSIGNED_REGISTERED_TICKETS.FIELDS.TICKET_STATUS.VALUE.ASSIGNED,
            ASSIGNED_REGISTERED_TICKETS.FIELDS.TICKET_STATUS.VALUE.USED,
          ]),
        ],
        20,
        ORDER_BY_FIELD,
        ORDER_DIRECTION,
        assignedTickets?.at(-1)?.id ?? null
      )

      if (!assignedTicketSnapshots.size) {
        setHasMore(false)
      } else {
        setHasMore(true)

        assignedTicketSnapshots?.forEach((currDoc) => {
          assignedTickets_.push(
            getConvertedData(AssignTicketsModal.fromFirestoreDoc(currDoc).toObject())
          )
        })

        setTickets([...assignedTickets, ...assignedTickets_])
      }
    } catch (error) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (eventId) {
      handleTicketHolderData(eventId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  const fetchMore = () => {
    if (eventId) {
      handleTicketHolderData(eventId, tickets)
    } else {
      console.log('eventId is empty')
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  useEffect(() => {
    let count = 0
    let total = 0
    tickets.forEach((checkin: any) => {
      if (checkin.checkInDate) {
        count++
      }
      total++
    })
    setCheckedIn(count)
    setTotal(total)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tickets])

  return (
    <InfiniteScrollDataTable
      fetchMore={fetchMore}
      columns={Cols}
      hasMore={hasMore}
      fixedHeader={true}
      data={tickets}
      progressPending={loading}
      progressComponent={<ViewsLoader size="xl" color="red" />}
      noDataComponent={
        <NoDataAvailable
          imgSrc={'assets/placeholders/NoEventsFoundProfile.svg'}
          text="No tickets exists"
          bottomText={'See More >'}
          textColor="text-SeabiscuitDark200ThemeColor"
        />
      }
      className="h-[700px] transition-all"
      customStyles={{
        rows: {
          style: {
            paddingTop: '15px',
            paddingBottom: '15px',
            '&:not(:last-of-type)': {
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
              borderBottomColor: '#D3DAEE',
            },
          },
        },
        headCells: {
          style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '9px',
            color: '#122B46',
            fontWeight: 'bold',
            fontSize: '12px',
          },
        },
        cells: {
          style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            Text: 'center',
          },
        },
        headRow: {
          style: {
            minHeight: 'unset',
            borderColor: '#D3DAEE',
          },
        },
      }}
      noHeader={true}
    />
  )
}

export default TicketHoldersTab
