// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import * as yup from 'yup'
import { IEventPolicies } from './event-policies.interface'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export class EventPoliciesModel extends ModelBaseModel<IEventPolicies> implements IModelBaseModel {
  public v: number
  public id: string | null
  public owner: string | null

  public attireTack: string
  public eventRules: string
  public orderOfGo: string
  public parking: string
  public ribbons: string
  public rvs: string
  public substitutions: string

  public extra: any

  public created: Date
  public modified: Date

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: IEventPolicies) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? null

    this.attireTack = obj?.attireTack ?? ''
    this.eventRules = obj?.eventRules ?? ''
    this.orderOfGo = obj?.orderOfGo ?? ''
    this.parking = obj?.parking ?? ''
    this.ribbons = obj?.ribbons ?? ''
    this.rvs = obj?.rvs ?? ''
    this.substitutions = obj?.substitutions ?? ''

    this.extra = obj?.extra ?? []

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new EventPoliciesModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  static validationSchema() {
    return yup.object({}).required()
  }
}
