import { useEffect, useState } from 'react'

// Third party
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import { sumBy } from 'lodash'

// Components

// Types
import Accordion from '../../../components/accordion/common/Accordion'
import { IRegistrationFeesInterface } from '../../../models/registration-fees/registrationFees.interface'
import { IScratch } from '../../../store/events/eventsSlice'
import { setExhibitorTotals } from '../../../store/exhibitor/exhibitorSlice'
import { useAppDispatch } from '../../../store/hooks'
import ExhibitorFeesAccordion from './accordions/ExhibitorFeesAccordion'
import ExhibitorFeesRefundAccordion from './accordions/ExhibitorFeesRefundAccordion'
import { IOnRefundAmountChangeFn } from './ExhibitorProfileDisplayTab'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IExhibitorProfileTeamTabProps = {
  isRefund: boolean
  exhibitorAllData?: any
  isRecipient?: boolean
  showScratchedView: boolean
  registrationfeesData: IRegistrationFeesInterface[]
  getScratchView: (scratchType: IScratch['scratchType']) => ReactJSXElement | null
  onRefundAmountChange?: IOnRefundAmountChangeFn
  setListToScratch: React.Dispatch<React.SetStateAction<string[]>>
  listToScratch: string[]
}

const ExhibitorProfileFeesTab = ({
  registrationfeesData,
  getScratchView,
  listToScratch,
  showScratchedView,
  onRefundAmountChange,
  isRefund,
  setListToScratch,
}: IExhibitorProfileTeamTabProps) => {
  // Hooks and vars
  const dispatch = useAppDispatch()

  const [itemsWithoutScratched, setItemsWithoutScratched] = useState<IRegistrationFeesInterface[]>(
    []
  )
  const [priceTotal, setPriceTotal] = useState<number>(0)
  const [listToScratchPriceTotal, setListToScratchPriceTotal] = useState<number>(0)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (registrationfeesData && registrationfeesData.length) {
      const price = parseFloat(
        sumBy(registrationfeesData, (item) => item.selectedUnitsCount * item.feesPrice).toFixed(2)
      )

      setItemsWithoutScratched(registrationfeesData)
      setPriceTotal(price)

      dispatch(
        setExhibitorTotals({
          value: price,
          keyToUpdate: 'feesPriceTotal',
        })
      )
    }
  }, [dispatch, registrationfeesData])

  useEffect(() => {
    if (registrationfeesData && registrationfeesData.length) {
      const listToScratchPrice = parseFloat(
        sumBy(
          registrationfeesData.filter((registration) =>
            listToScratch.includes(registration.id ?? '')
          ),
          (item) => item.selectedUnitsCount * item.feesPrice
        ).toFixed(2)
      )
      setListToScratchPriceTotal(listToScratchPrice)
    }
  }, [listToScratch, registrationfeesData])

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Accordion
      icon={<AddCircleOutline className="add_icon text-[red]" />}
      initialState={true}
      className="mb-2"
      header={
        <div
          className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${isOpen ? 'font-normal' : 'font-bold'}`}
        >
          Fees
          <div className="flex items-center details">
            <div className="font-normal text-[14px] text-SeabiscuitDark200ThemeColor">
              {showScratchedView
                ? `${listToScratch.length} ${listToScratch.length > 1 ? 'items' : 'item'} scratched, $${listToScratchPriceTotal} refund requested`
                : `${listToScratch.length ? listToScratch.length : itemsWithoutScratched.length} Fees, $${priceTotal}`}
            </div>
          </div>
        </div>
      }
      headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
      onOpen={() => {
        setIsOpen(true)
      }}
      onClose={() => {
        setIsOpen(false)
      }}
      activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
    >
      <>
        {isRefund ? (
          <ExhibitorFeesRefundAccordion
            registrationfeesData={registrationfeesData}
            onRefundAmountChange={onRefundAmountChange}
          />
        ) : (
          <ExhibitorFeesAccordion
            data={registrationfeesData}
            showScratchedView={showScratchedView}
            setListToScratch={setListToScratch}
            listToScratch={listToScratch}
          />
        )}
        {getScratchView('fees')}
      </>
    </Accordion>
  )
}

export default ExhibitorProfileFeesTab
