// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useEffect, useState } from 'react'
import EventFormContainerComponent from '../container/EventFormContainerComponent'
import SwitchElement from './SwitchElement'

import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import fakeDocumentList, { DOCUMENT_TYPES } from '../../../../fakeData/fakeDocumentList'
import { Tooltip } from '@mui/material'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import { EventPaperworkModel } from '../../../../models/event-paperwork/event-paperwork.model'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import LightboxCommonComponent from '../../../common/dialogs/full-screen/LightboxCommonComponent'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const eventPaperworkFormDefaultValues = new EventPaperworkModel().toObject()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const eventPaperworkFormValidationSchema = EventPaperworkModel.validationSchema()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventPaperworkFormComponentProps {
  data: any
  onValid: any
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  nextEventTab?: string
  eventTab?: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPaperworkFormComponent: React.FC<EventPaperworkFormComponentProps> = (props) => {
  // Hooks and vars
  const [imagesToShow, setImagesToShow] = useState<string[]>([])
  const [lightBoxIsOpen, setLightBoxIsOpen] = useState(false)

  const { handleSubmit, setValue, trigger, control, reset } = useForm({
    defaultValues: { ...eventPaperworkFormDefaultValues },
    resolver: yupResolver(eventPaperworkFormValidationSchema),
    mode: 'onChange',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (props.data) {
      reset(props.data)
    } else {
      reset(eventPaperworkFormDefaultValues)
    }
    trigger().then()
  }, [props.data, reset, trigger])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onViewDocClick = (docType: string) => {
    let images: string[] = []
    const docImages =
      fakeDocumentList.find((currDoc) => currDoc.document_type === docType)?.document_image ?? []
    if (typeof docImages === 'string') images = [docImages]
    else images = docImages
    setImagesToShow(images)
    setLightBoxIsOpen(true)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <EventFormContainerComponent>
      <>
        <EventFormHeaderComponent
          title="Paperwork"
          description="Select the paperwork you want to add to registrations"
        >
          <EventFormFooterCommonComponent
            eventTab={props.eventTab}
            nextEventTab={props.nextEventTab}
            onNext={(e, publishEvent) => {
              handleSubmit(
                (data) =>
                  publishEvent({
                    dataToSave: data,
                    tabName: 'EventPaperwork',
                    validFormHandler: props.onValid,
                  }),
                props.onInvalid
              )(e)
            }}
            onSaveAndExit={(e, publishEvent) => {
              handleSubmit(
                (data) =>
                  publishEvent({
                    dataToSave: data,
                    publish: false,
                    tabName: 'EventPaperwork',
                    validFormHandler: props.onValid,
                  }),
                props.onInvalidAndExit
              )(e)
            }}
          />
        </EventFormHeaderComponent>

        <div className="relative grid grid-cols-[auto] tablet:grid-cols-[auto_auto] mini:grid-cols-[450px_auto] items-center gap-2 w-full mt-9">
          <div className="xl:text-[16px] flex items-center justify-start 2xl:text-xl tablet:col-span-3 mini:col-span-1 text-SeabiscuitDark200ThemeColor font-normal mr-4">
            Entry Blank
            <Tooltip
              title={
                <h1 className="tooltip_title">
                  This app replaces the need for entry blanks. You no longer need them. You cannot
                  turn this on.
                </h1>
              }
              placement="top"
              arrow
            >
              <button>
                <TooltipIcon color="#122B46" />
              </button>
            </Tooltip>
          </div>
          <Controller
            name="entryBlank"
            control={control}
            render={({ field: { value } }) => (
              <SwitchElement onSwitch={() => setValue('entryBlank', false)} on={value} />
            )}
          />

          <div>
            <div className="xl:text-[16px] 2xl:text-xl flex items-center justify-start tablet:col-span-3 mini:col-span-1 text-SeabiscuitDark200ThemeColor font-normal mr-4">
              General Liability Waiver
              <Tooltip
                title={
                  <h1 className="tooltip_title">
                    This is a mandatory waiver that will provide protection to the show organizer.
                    It cannot be turned off. You can add clauses to this document in your settings.
                  </h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>
            <div>
              <button
                className="underline mt-1.5 text-SeabiscuitGrayLight300ThemeColor text-sm"
                onClick={() => onViewDocClick(DOCUMENT_TYPES.GENERAL)}
              >
                {` View Document >`}
              </button>
            </div>
          </div>

          <Controller
            name="universalOrganizerWaver"
            control={control}
            render={({ field: { value } }) => (
              <SwitchElement
                onSwitch={() => setValue('universalOrganizerWaver', true)}
                on={value}
              />
            )}
          />

          <div>
            <div className="xl:text-[16px] 2xl:text-xl flex items-center justify-start tablet:col-span-3 mini:col-span-1 text-SeabiscuitDark200ThemeColor font-normal mr-4">
              USEF Waiver and Release of Liability
              <Tooltip
                title={
                  <h1 className="tooltip_title">Mandatory for any event licensed by the USEF.</h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>
            <div>
              <button
                className="underline mt-1.5 text-SeabiscuitGrayLight300ThemeColor text-sm"
                onClick={() => onViewDocClick(DOCUMENT_TYPES.USEF)}
              >
                {` View Document >`}
              </button>
            </div>
          </div>
          <Controller
            name="USEFWaiverAndReleaseOfLiability"
            control={control}
            render={({ field: { value } }) => (
              <SwitchElement
                onSwitch={() => setValue('USEFWaiverAndReleaseOfLiability', !value)}
                on={value}
              />
            )}
          />

          <div>
            <div className="xl:text-[16px] 2xl:text-xl flex items-center justify-start tablet:col-span-3 mini:col-span-1 text-SeabiscuitDark200ThemeColor font-normal mr-4">
              USEF Entry Agreement
              <Tooltip
                title={
                  <h1 className="tooltip_title">Mandatory for any event licensed by the USEF.</h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>
            <div>
              <button
                className="underline mt-1.5 text-SeabiscuitGrayLight300ThemeColor text-sm"
                onClick={() => onViewDocClick(DOCUMENT_TYPES.USEFENTRY)}
              >
                {` View Document >`}
              </button>
            </div>
          </div>

          <Controller
            name="USEFEntryAgreement"
            control={control}
            render={({ field: { value } }) => (
              <SwitchElement onSwitch={() => setValue('USEFEntryAgreement', !value)} on={value} />
            )}
          />
          <div>
            <div className="xl:text-[16px] 2xl:text-xl flex items-center justify-start tablet:col-span-3 mini:col-span-1 text-SeabiscuitDark200ThemeColor font-normal mr-4">
              USDF Waiver and Release of Liablity
              <Tooltip
                title={
                  <h1 className="tooltip_title">
                    Include this document if you are hosting a dressage event of any kind, licensed
                    or schooling. Not required for non-competitions.
                  </h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>
            <div>
              <button
                className="underline mt-1.5 text-SeabiscuitGrayLight300ThemeColor text-sm"
                onClick={() => onViewDocClick(DOCUMENT_TYPES.USDF)}
              >
                {` View Document >`}
              </button>
            </div>
          </div>
          <Controller
            name="USDFWaiverAndReleaseOfLiability"
            control={control}
            render={({ field: { value } }) => (
              <SwitchElement
                onSwitch={() => setValue('USDFWaiverAndReleaseOfLiability', !value)}
                on={value}
              />
            )}
          />

          <div>
            <div className="xl:text-[16px] 2xl:text-xl flex items-center justify-start tablet:col-span-3 mini:col-span-1 text-SeabiscuitDark200ThemeColor font-normal mr-4">
              USEA Waiver and Release of Liability
              <Tooltip
                title={
                  <h1 className="tooltip_title">
                    Include this document if you are hosting a eventing event of any kind, licensed
                    or schooling. Not required for non-competitions.
                  </h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>
            <div>
              <button
                className="underline mt-1.5 text-SeabiscuitGrayLight300ThemeColor text-sm"
                onClick={() => onViewDocClick(DOCUMENT_TYPES.USEA)}
              >
                {` View Document >`}
              </button>
            </div>
          </div>
          <Controller
            name="USEAWaiverAndReleaseOfLiability"
            control={control}
            render={({ field: { value } }) => (
              <SwitchElement
                onSwitch={() => setValue('USEAWaiverAndReleaseOfLiability', !value)}
                on={value}
              />
            )}
          />

          <div>
            <div className="xl:text-[16px] 2xl:text-xl flex items-center justify-start tablet:col-span-3 mini:col-span-1 text-SeabiscuitDark200ThemeColor font-normal mr-4">
              USHJA Waiver and Release of Liability
              <Tooltip
                title={
                  <h1 className="tooltip_title">
                    Include this document if you are hosting a hunter, jumper of hunter jumper event
                    of any kind, licensed or schooling. Not required for non-competitions.
                  </h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>
            <div>
              <button
                className="underline mt-1.5 text-SeabiscuitGrayLight300ThemeColor text-sm"
                onClick={() => onViewDocClick(DOCUMENT_TYPES.USHJA)}
              >
                {` View Document >`}
              </button>
            </div>
          </div>
          <Controller
            name="USHJAWaiverAndReleaseOfLiability"
            control={control}
            render={({ field: { value } }) => (
              <SwitchElement
                onSwitch={() => setValue('USHJAWaiverAndReleaseOfLiability', !value)}
                on={value}
              />
            )}
          />
        </div>

        <LightboxCommonComponent
          onClose={() => setLightBoxIsOpen(false)}
          visible={lightBoxIsOpen}
          slides={imagesToShow.map((currImage: string) => {
            return { src: currImage }
          })}
        />
      </>
    </EventFormContainerComponent>
  )
}

export default EventPaperworkFormComponent
