import { CountryList } from '../../../fakeData/countryList'
import { getValueFromSelectList } from '../../../models/interface.helper'
import { useAppSelector } from '../../../store/hooks'

import { selectVisitedUserDetailsR } from '../../../store/profilePage/profilePageSlice'
import ProfileDetailsCard from '../../pageWise/visitedUserDetails/profile-details-card/ProfileDetailsCard'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const OrganizerRootSideProfileImageSection = () => {
  const visitedUserDetails = useAppSelector(selectVisitedUserDetailsR)
  const profileDetails = visitedUserDetails?.profileDetails ?? null

  return (
    <div className="bg-SeabiscuitLightGrayThemeColor rounded-xl flex flex-col items-center justify-center px-6 py-5 w-full md:w-[375px]">
      <ProfileDetailsCard />

      {profileDetails?.userDiscipline &&
      profileDetails?.userDiscipline !== '' &&
      profileDetails?.userZone &&
      profileDetails?.userZone !== '' &&
      getValueFromSelectList({
        list: CountryList,
        valueToGet: 'label',
        valueToFind: profileDetails?.userNationality ?? '',
      }) ? (
        <div className="bg-white px-4 py-2 flex mt-3 flex-wrap rouded-lg w-full rounded-xl justify-between">
          {profileDetails?.userDiscipline && profileDetails?.userDiscipline !== '' && (
            <>
              <div className="w-1/3 flex flex-col justify-between items-center px-2 text-center">
                <span className="font-bold text-SeabiscuitDark200ThemeColor capitalize h-full flex items-center break-all leading-[1.2] mb-1 md:text-[12px] 2xl:text-base line-clamp-1">
                  {profileDetails?.userDiscipline}
                </span>
                <span className="text-sm text-SeabiscuitLightParagraphTextColor">Discipline</span>
              </div>
            </>
          )}

          {profileDetails?.userZone && profileDetails?.userZone !== '' && (
            <div className="w-1/3 flex flex-col justify-between items-center px-2 text-center">
              <span className="font-bold text-SeabiscuitDark200ThemeColor capitalize h-full flex items-center break-all leading-[1.2] mb-1 md:text-[12px] 2xl:text-base">
                {profileDetails?.userZone}
              </span>
              <span className="text-sm text-SeabiscuitLightParagraphTextColor">Zone</span>
            </div>
          )}

          {getValueFromSelectList({
            list: CountryList,
            valueToGet: 'label',
            valueToFind: profileDetails?.userNationality ?? '',
          }) && (
            <div className="w-1/3 flex flex-col justify-between items-center px-2 text-center">
              <span className="font-bold text-SeabiscuitDark200ThemeColor capitalize h-full flex items-center break-all leading-[1.2] mb-1 md:text-[12px] 2xl:text-base break-normal">
                {/* {userData?.userNationality} */}
                {getValueFromSelectList({
                  list: CountryList,
                  valueToGet: 'value',
                  valueToFind: profileDetails?.userNationality ?? '',
                })?.toString() ?? 'unknown'}
              </span>
              <span className="text-sm text-SeabiscuitLightParagraphTextColor">Location</span>
            </div>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default OrganizerRootSideProfileImageSection
