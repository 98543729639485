import { Close } from '@mui/icons-material'
import { MODAL_CONSTS } from '../../const/modal-const'

type Props = {
  show?: boolean
  handleModal?: any
  dataToPassOn?: any
}

const RegistrationHorseModal = (props: Props) => {
  return (
    <>
      <div
        className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered relative lg:w-3/5 xl:w-1/3 m-auto pointer-events-none">
          <div
            className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-2xl outline-none text-current pb-10 pt-8 px-8"
            style={{ width: 'max-content', maxWidth: '146%', height: 'fit-content' }}
          >
            <span
              onClick={() => props.handleModal(false, MODAL_CONSTS.REGISTRATION_HORSES)}
              className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
            >
              <Close className="text-[#122B46]" />
            </span>

            <div className="modal-header rounded-t-xl font-Poppins h-[100%]">
              <h5 className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal">
                Horse Information
              </h5>

              <div className="allInfo text-[#122B46]  overflow-y-auto pr-2 h-[100%]">
                <p className="mt-4 font-[Poppins]">
                  Add all horses you will register for this event.
                </p>
                <p className="mt-4">
                You can register multiple riders simultaneously, so be sure to include the horses of all riders being registered for this event.
                </p>
                <p className="mt-4">
                  When adding a horse, you can search horses already saved in Pegasus, or you can
                  add a new horse.
                </p>
              </div>

              {/* <button
                                onClick={() => props.handleModal(false, MODAL_CONSTS.ACCOUNT_PRIVACY)}
                                type="button"
                                className="w-full mt-6 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor">
                                CLOSE
                            </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegistrationHorseModal
