import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { HorseModel } from '../../../models/horse/horse.model'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { getSelectedHorse, setSelectedHorse } from '../../../store/horses/horseSlice'
import IHorsePublicProfile from '../types'
import { useIonRouter } from '@ionic/react'
import { getConvertedData } from '../../../models/interface.helper'
import { IHorseTeamInterface } from '../../../models/horse-team/horseTeam.interface'
import { where } from 'firebase/firestore'
import { HorseTeamModel } from '../../../models/horse-team/horseTeam.model'
import { IHorseLineage } from '../../../models/horse-lineage/horse-lineage.interface'
import { HorseLineageModel } from '../../../models/horse-lineage/horse-lineage-model'
import {useHistory, useLocation} from 'react-router-dom'
import { MESSAGES_CONST } from '../../../const/messages-const'
import helpers from '../../../commonHelpers/helpers'
import { CustomError } from '../../../helpers/helpers'
import { selectProfileData } from '../../../store/user/userSlice'
import { IFollowingInterface } from '../../../models/following/following.interface'
import { FollowingModel } from '../../../models/following/following.model'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { IHorseData } from '../../../models/horse/horse.interface'

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const FILE_NAME = 'RegisterForCompetitionPayByCardModal'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const useHorsePublicProfile = () => {
  // Hooks and vars
  const location = useLocation()

  const dispatch = useAppDispatch()
  const toastMethods = useToasterHelper()
  const userData = useAppSelector(selectProfileData)
  const selectedHorse = useAppSelector(getSelectedHorse)

  const [loading, setLoading] = useState(true)
  const [horseNotFound, setHorseNotFound] = useState(false)
  const [following, setFollowing] = useState<boolean>(false)
  const [alreadyFollowed, setAlreadyFollowed] = useState<null | boolean>(null)
  const [horseLineage, setHorseLineage] = useState<IHorseLineage | null>(null)
  const [followingDoc, setfollowingDoc] = useState<IFollowingInterface | null>(null)
  const [horseTeamMembers, setHorseTeamMembers] = useState<IHorseTeamInterface[]>([])
  const [activeTab, setActiveTab] = useState<IHorsePublicProfile['IActiveTab']>('details')

  const loggedInUserId = userData.id
  const loggedInUserType = userData.userType

  const router = useIonRouter()
  const history = useHistory()
  const horseDocId = useParams<{ horseDocId: string }>().horseDocId

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!horseDocId) {
      handleHorseNotFound()
      return
    } else {
      setLoading(true)
      boot(horseDocId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horseDocId, loggedInUserId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    const validTabsArray: IHorsePublicProfile['IActiveTab'][] = ['lineage', 'team', 'details']
    const tab = new URLSearchParams(location.search).get('tab') as IHorsePublicProfile['IActiveTab']

    if (tab && validTabsArray.indexOf(tab ?? '') !== -1) {
      setActiveTab(tab)
    }
  }, [location.pathname, location.search, router])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  function handleHorseNotFound() {
    setHorseNotFound(true)
    setLoading(false)
  }

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const loadEventDetails = async (horseDocId: string) => {
    try {
      await FirestoreService.filterItems(COLLECTIONS.REGISTRATION_BY_DAY.NAME, [
        where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.HORSE_ID.KEY, '==', horseDocId),
      ])
    } catch (error) {}
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const checkAlreadyFollowed = async (horseDocId: string) => {
    try {
      if (!loggedInUserId || !horseDocId) return 0

      setLoading(true)

      const docSnaps = await FirestoreService.filterItems(COLLECTIONS.FOLLOWING.NAME, [
        where(COLLECTIONS.FOLLOWING.FIELDS.FOLLOWER_USER_ID.KEY, '==', loggedInUserId),
        where(COLLECTIONS.FOLLOWING.FIELDS.FOLLOWING_HORSE_ID.KEY, '==', horseDocId),
      ])

      setAlreadyFollowed(!!docSnaps.size)

      if (docSnaps.size)
        setfollowingDoc(FollowingModel.fromFirestoreDoc(docSnaps.docs[0]).toObject())
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const loadHorseTeamMembers = async (horseDocId: string) => {
    let horseTeamMembers: IHorseTeamInterface[] = []
    let idOfAlreadyIncludedTeamMembers: string[] = []

    try {
      const teamMembers = await FirestoreService.filterItems(COLLECTIONS.HORSE_TEAM.NAME, [
        where(COLLECTIONS.HORSE_TEAM.FIELDS.HORSE_ID.KEY, '==', horseDocId),
      ])

      teamMembers.docs.forEach((currTeamMemberSnapshot) => {
        horseTeamMembers.push(
          getConvertedData(HorseTeamModel.fromFirestoreDoc(currTeamMemberSnapshot).toObject())
        )
      })
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'loadHorseTeamMembers',
          devMessage: error,
        }),
      })
    } finally {
      horseTeamMembers = horseTeamMembers.filter((currHorseTeamMember) => {
        if (
          currHorseTeamMember.memberId &&
          !idOfAlreadyIncludedTeamMembers.includes(currHorseTeamMember.memberId)
        ) {
          idOfAlreadyIncludedTeamMembers.push(currHorseTeamMember.memberId)
          return true
        } else {
          return false
        }
      })

      setHorseTeamMembers(horseTeamMembers)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const loadHorseLineage = async (horseDocId: string) => {
    let horseLineage: IHorseLineage | null = null

    try {
      const horseLineageSnapshot = await FirestoreService.getItem(
        COLLECTIONS.HORSE_LINEAGE.NAME,
        horseDocId
      )

      if (horseLineageSnapshot.exists()) {
        horseLineage = HorseLineageModel.fromFirestoreDoc(horseLineageSnapshot)
      }
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'loadHorseLineage',
          devMessage: error,
        }),
      })
    } finally {
      setHorseLineage(horseLineage)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const loadHorseDetails = async (horseDocId: string) => {
    try {
      const horseSnapshot = await FirestoreService.getItem(COLLECTIONS.HORSES.NAME, horseDocId)

      if (!horseSnapshot.exists()) {
        setHorseNotFound(!horseSnapshot.exists())
        setHorseTeamMembers([])
      } else {
        await loadEventDetails(horseDocId)
        await loadHorseLineage(horseDocId)
        await loadHorseTeamMembers(horseDocId)
        await checkAlreadyFollowed(horseDocId)
        const horse = HorseModel.fromFirestoreDoc(horseSnapshot).toObject()
        dispatch(setSelectedHorse(getConvertedData(horse)))
      }
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'loadHorseDetails',
          devMessage: error,
        }),
      })
    } finally {
      setLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async function boot(horseDocId: string) {
    await loadHorseDetails(horseDocId)
    setLoading(false)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onTabClick = (activeTab: IHorsePublicProfile['IActiveTab']) => {
    router.push(`${router.routeInfo.pathname}?tab=${activeTab}`)
    history.push(`${router.routeInfo.pathname}?tab=${activeTab}`)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onFollowBtnClick = async (horse: IHorseData) => {
    let docId: null | string = null

    try {
      if (!loggedInUserId || !horseDocId) throw new Error()

      let docToSave = new FollowingModel({
        usedFor: 'horse',
        followingUserId: null,
        followingUserType: null,
        followingHorseId: horseDocId,
        followerUserId: loggedInUserId,
        followerUserType: loggedInUserType as any,
      })

      setFollowing(true)

      if (!alreadyFollowed && !followingDoc) {
        const docRef = await FirestoreService.createItem(
          COLLECTIONS.FOLLOWING.NAME,
          docToSave.toFirestore()
        )

        docId = docRef.id
        docToSave = docToSave.cloneDeep()
        docToSave.id = docId

        setfollowingDoc(docToSave)

        toastMethods.success({
          message: MESSAGES_CONST.FOLLOWED.replace(
            '[USER_NAME]',
            horse.horseName ?? 'Unknown Horse'
          ),
        })

        setAlreadyFollowed(true)
      } else if (followingDoc?.id) {
        await FirestoreService.deleteItem(COLLECTIONS.FOLLOWING.NAME, followingDoc.id)

        toastMethods.success({
          message: MESSAGES_CONST.UNFOLLOWED.replace(
            '[USER_NAME]',
            horse.horseName ?? 'Unknown Horse'
          ),
        })

        setAlreadyFollowed(false)
      } else throw new Error('Already followed but following doc or its id is null')
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'onFollowBtnClick',
          devMessage: error,
        }),
      })
      toastMethods.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setFollowing(false)
    }
  }

  return {
    loading,
    activeTab,
    following,
    onTabClick,
    horseLineage,
    selectedHorse,
    horseNotFound,
    alreadyFollowed,
    horseTeamMembers,
    onFollowBtnClick,
  }
}

export { useHorsePublicProfile }
