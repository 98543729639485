import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { IUserNotificationInterace } from './notification.interface'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @todo Document this
 */

export class UserNotificationModal
  extends ModelBaseModel<IUserNotificationInterace>
  implements IModelBaseModel
{
  // Vars
  public id: IUserNotificationInterace['id']
  public receiverId: IUserNotificationInterace['receiverId']
  public senderId: IUserNotificationInterace['senderId']
  public receiver_type: IUserNotificationInterace['receiver_type']
  public receiver_email: IUserNotificationInterace['receiver_email']
  public notification_type: IUserNotificationInterace['notification_type']
  public status: IUserNotificationInterace['status']
  public eventId: IUserNotificationInterace['eventId']
  public updatedAt: IUserNotificationInterace['updatedAt']
  public createdAt: IUserNotificationInterace['createdAt']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  public constructor(obj: IUserNotificationInterace) {
    super()
    this.id = obj.id ?? null
    this.receiverId = obj.receiverId ?? null
    this.senderId = obj.senderId ?? null
    this.receiver_type = obj.receiver_type ?? null
    this.receiver_email = obj.receiver_email ?? null
    this.notification_type = obj.notification_type ?? null
    this.status = obj.status ?? null
    this.eventId = obj.eventId ?? null

    this.createdAt = this.utcTimestamp({
      key: 'createdAt',
      isTimestamp: true,
      value: obj?.createdAt,
    })

    this.updatedAt = this.utcTimestamp({
      key: 'updatedAt',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.updatedAt,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  static fromFirestoreDoc(doc: any) {
    return new UserNotificationModal({
      id: doc.id,
      ...doc.data(),
    })
  }
}
