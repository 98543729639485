import { cloneDeep } from 'lodash'
import { IEventTickets } from '../event-details/event-details.interface'
import { IFees, IPaperworkTab } from '../event-drafts/event-draft.interface'
import { removeKeysFromObj } from '../interface.helper'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IRecipient, IRiderTeamMember, ITeamMember } from '../users/user.interface'
import { TEventRegisteredUsers } from './event-registered-users.interface'

// Constants

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

// Constants
// const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS;

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export class EventRegisteredUsersModel
  extends ModelBaseModel<TEventRegisteredUsers>
  implements IModelBaseModel
{
  public v: TEventRegisteredUsers['v']
  public id: TEventRegisteredUsers['id']
  public owner: TEventRegisteredUsers['owner']
  public draft: TEventRegisteredUsers['draft']
  public userId: TEventRegisteredUsers['userId']
  public userEmail: TEventRegisteredUsers['userEmail']
  public horses: TEventRegisteredUsers['horses']
  public eventId: TEventRegisteredUsers['eventId']
  public draftId: TEventRegisteredUsers['draftId']
  public eventLat: TEventRegisteredUsers['eventLat']
  public userType: TEventRegisteredUsers['userType']
  public category: TEventRegisteredUsers['category']
  public userName: TEventRegisteredUsers['userName']
  public eventName: TEventRegisteredUsers['eventName']
  public eventCity: TEventRegisteredUsers['eventCity']
  public eventLogo: TEventRegisteredUsers['eventLogo']
  public eventLong: TEventRegisteredUsers['eventLong']
  public discipline: TEventRegisteredUsers['discipline']
  public eventState: TEventRegisteredUsers['eventState']
  public isScratched: TEventRegisteredUsers['isScratched']
  public isRegistered: TEventRegisteredUsers['isRegistered']
  public eventCountry: TEventRegisteredUsers['eventCountry']
  public userNameNGrams: TEventRegisteredUsers['userNameNGrams']
  public paymentStatus: TEventRegisteredUsers['paymentStatus']
  public registrationDate: TEventRegisteredUsers['registrationDate']
  public selectionDetails: TEventRegisteredUsers['selectionDetails']
  public userProfilePicture: TEventRegisteredUsers['userProfilePicture']
  public paperworkstatus: TEventRegisteredUsers['paperworkstatus']
  public paymentstatusall: TEventRegisteredUsers['paymentstatusall']
  public teamstatus: TEventRegisteredUsers['teamstatus']

  public created: TEventRegisteredUsers['created']
  public modified: TEventRegisteredUsers['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  public constructor(obj?: TEventRegisteredUsers) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.horses = obj?.horses ?? []
    this.owner = obj?.owner ?? null
    this.draft = obj?.draft ?? null
    this.userId = obj?.userId ?? null
    this.userEmail = obj?.userEmail ?? null
    this.draftId = obj?.draftId ?? null
    this.eventId = obj?.eventId ?? null
    this.eventLat = obj?.eventLat ?? null
    this.category = obj?.category ?? null
    this.userType = obj?.userType ?? null
    this.userName = obj?.userName ?? null
    this.eventName = obj?.eventName ?? null
    this.eventLogo = obj?.eventLogo ?? null
    this.eventLong = obj?.eventLong ?? null
    this.userNameNGrams = this.getCalculatedNGrams(obj?.userName)
    this.eventCity = obj?.eventCity ?? null
    this.discipline = obj?.discipline ?? []
    this.eventState = obj?.eventState ?? null
    this.eventCountry = obj?.eventCountry ?? null
    this.isRegistered = obj?.isRegistered ?? false
    this.paymentStatus = obj?.paymentStatus ?? null
    this.paymentstatusall = obj?.paymentstatusall ?? false
    this.paperworkstatus = obj?.paperworkstatus ?? false
    this.teamstatus = obj?.teamstatus ?? false
    this.isScratched = obj?.isScratched ?? false
    this.userProfilePicture = obj?.userProfilePicture ?? null
    this.selectionDetails = EventRegisteredUsersModel.getFilteredSelectionDetails(
      obj?.selectionDetails
    )

    this.registrationDate = this.utcTimestamp({
      key: 'registrationDate',
      value: obj?.registrationDate,
    })

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static getFilteredSelectionDetails(selectionDetails?: TEventRegisteredUsers['selectionDetails']) {
    let riderFieldsToSave = ['memberId', 'memberRole', 'signedStatus'] as (keyof IRiderTeamMember)[]

    let memberFielsToSave = [
      'memberId',
      'horses',
      'memberRole',
      'isScratched',
      'refundAmount',
      'riderTeamMembers',
    ] as (keyof ITeamMember)[]

    let recipientFielsToSave: (keyof IRecipient)[] = [
      'recipientId',
      'recipientAmountToPay',
      'recipientPaymentStatus',
    ]

    let feesFieldsToRemove: (keyof IFees)[] = []
    let ticketFieldsToRemove: (keyof IEventTickets)[] = []

    let isSplitPayment = false

    if (!selectionDetails)
      return {
        selectedFees: [],
        selectedEvents: [],
        selectedTickets: [],
        isSplitPayment: false,
        selectedRecipients: [],
        selectedSignatories: [],
        paperwork: {},
      }

    let selectionDetails_ = cloneDeep(selectionDetails)

    let { selectedFees, selectedEvents, selectedTickets, selectedRecipients, selectedSignatories } =
      selectionDetails_

    selectionDetails_ = {
      ...selectionDetails_,
      selectedFees: Array.isArray(selectedFees) ? selectedFees : [],
      selectedEvents: Array.isArray(selectedEvents) ? selectedEvents : [],
      selectedTickets: Array.isArray(selectedTickets) ? selectedTickets : [],
      selectedRecipients: Array.isArray(selectedRecipients) ? selectedRecipients : [],
      selectedSignatories: Array.isArray(selectedSignatories) ? selectedSignatories : [],
    }

    // Set split payment key
    if (selectionDetails_.hasOwnProperty('isSplitPayment'))
      selectionDetails_.isSplitPayment = selectionDetails_.isSplitPayment ?? false
    else selectionDetails_.isSplitPayment = false

    isSplitPayment = selectionDetails_.isSplitPayment

    if (isSplitPayment) memberFielsToSave.push('recipient')
    else {
      feesFieldsToRemove.push('recipient')
      ticketFieldsToRemove.push('recipient')
    }

    // Empty recipients array if split payment is off
    if (isSplitPayment) {
      selectionDetails_.selectedRecipients =
        selectionDetails_?.selectedRecipients?.map((currRecipient) => {
          return {
            ...removeKeysFromObj({ ...currRecipient }, recipientFielsToSave, true),
            recipientPaymentStatus: currRecipient.recipientPaymentStatus ?? 'pending',
          }
        }) ?? []
    } else selectionDetails_.selectedRecipients = []

    // Filter the events that have members with horses
    selectionDetails_.selectedEvents = selectionDetails_.selectedEvents.filter(
      (currEvent: any) =>
        currEvent.members.filter((currMember: any) => currMember.horses.length).length
    )

    // Remove recipient key if split payment is off
    selectionDetails_.selectedEvents = selectionDetails.selectedEvents.map(
      (currOneDayRegistration) => {
        return {
          ...currOneDayRegistration,
          members: currOneDayRegistration.members.map((currTeammember) => {
            return {
              ...removeKeysFromObj({ ...currTeammember }, memberFielsToSave, true),
              riderTeamMembers: currTeammember?.riderTeamMembers?.map((currRiderTeamMember) => {
                return removeKeysFromObj({ ...currRiderTeamMember }, riderFieldsToSave, true)
              }),
              horses: (currTeammember?.horses ?? []).map((currHorse) => {
                return removeKeysFromObj({ ...currHorse }, ['id', 'value'], true)
              }),
            }
          }),
        }
      }
    )

    // Remove recipient key if split payment is off
    if (Array.isArray(selectionDetails_)) {
      selectionDetails_.selectedFees = selectionDetails_.selectedFees.map((currFee) => {
        return removeKeysFromObj({ ...currFee }, feesFieldsToRemove)
      })
    }

    // Remove recipient key if split payment is off
    selectionDetails_.selectedTickets = selectionDetails_.selectedTickets.map((currTicket) => {
      return removeKeysFromObj({ ...currTicket }, ticketFieldsToRemove)
    })

    if (selectionDetails_.paperwork)
      selectionDetails_.paperwork = Object.keys(selectionDetails_.paperwork).reduce(
        (acc: IPaperworkTab, currPaperworkKey) => {
          acc[currPaperworkKey] = (selectionDetails_.paperwork[currPaperworkKey] ?? [])?.map(
            (currSignatory) => {
              return removeKeysFromObj(
                currSignatory,
                ['memberId', 'memberRole', 'signedStatus'],
                true
              )
            }
          )
          return acc
        },
        {}
      )

    return selectionDetails_
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any, registeredUsers?: number) {
    return new EventRegisteredUsersModel({
      id: doc.id,
      registeredUsers,
      ...doc.data(),
    })
  }
}
