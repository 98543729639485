import { IMAGE_CONSTS } from '../../../const/image-const'

const TABS = {
  active: 0,
  tablist: ['All', 'Unread', 'Settings'],
  tabDescriptionList: ['', '', 'Manage the notifications you wish to receive'],
}

let listing = [
  {
    sender_image: IMAGE_CONSTS.PLACEHOLDERS.USER,
    sender_name: 'nitesh',
    message: 'hello',
  },
  {
    sender_image: IMAGE_CONSTS.PLACEHOLDERS.USER,
    sender_name: 'nitesh',
    message: 'hello',
  },
  {
    sender_image: IMAGE_CONSTS.PLACEHOLDERS.USER,
    sender_name: 'atul',
    message: 'mernstack developer',
  },
  {
    sender_image: IMAGE_CONSTS.PLACEHOLDERS.USER,
    sender_name: 'atul',
    message: 'mernstack developer',
  },
]

let listing2 = [
  {
    sender_image: IMAGE_CONSTS.PLACEHOLDERS.USER,
    sender_name: 'atul',
    message: 'mernstack developer',
  },
  {
    sender_image: IMAGE_CONSTS.PLACEHOLDERS.USER,
    sender_name: 'atul',
    message: 'mernstack developer',
  },
]
let list3 = [
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Event updates',
    setting_description:
      'Weather delays, scores published, course map updated, new ride times, etc.',
    on: false,
  },
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Messages',
    setting_description: 'Messages from team members, event organizers, etc.',
    on: true,
  },
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Paperwork',
    setting_description:
      'Requests for signature, document saved, team members signed their paperwork, etc.',
    on: true,
  },
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Billing',
    setting_description: 'New bill to pay, view receipts, payment confirmation, etc.',
    on: true,
  },
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Scoring',
    setting_description: 'Scores published, scores updated, scores contested, etc.',
    on: false,
  },
  {
    setting_logo: '/assets/og_icons/Notification.svg',
    setting_title: 'Team',
    setting_description:
      'Team member added, team member registered, team member signed paperwork, etc.',
    on: true,
  },
]
export const PAPERWORK_DATA = {
  TABS,
  listing,
  listing2,
  list3,
}
