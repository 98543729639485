import React, { useState } from 'react'
import UserSignupViewComponentButton from './global/UserSignupViewComponentButton'
import { useContext } from 'react'
import { SignupStages } from './UserSignupViewComponentContextApi'
import { Link } from 'react-router-dom'

import { Visibility, VisibilityOffOutlined } from '@mui/icons-material'

//third-party
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

//types
import { IUserSignupForm } from '../../../../../types/competitor_types'

//validation schema
import { userSignupSchema } from '../../../../../validations'

import backIcon from '../assets/arrow-left.svg'

import MessageHelperComp from '../../../../../helpers/MessageHelper'
import { PoliciesData } from '../../../../../pages/help/data/PoliciesData'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../../const/modal-const'

import { HelpRootPageDataPassOn } from '../../../../../pages/help/tabs/HelpRootPagePoliciesTab'

export interface UserSignupViewComponentCompetitorSignupFormProps {
  onSignUpButtonPressed?: any
  loading?: boolean
}

const UserSignupViewComponentCompetitorSignupForm: React.FC<
  UserSignupViewComponentCompetitorSignupFormProps
> = (props) => {
  const termsPolicy = PoliciesData[0]
  const [stage, setStage] = useContext(SignupStages)
  const [innerType, setInnerType] = useState('password')
  const [c_innerType, setC_innerType] = useState('password')

  const handleModalContext = useContext(HandleModalContext)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserSignupForm>({
    mode: 'onChange',
    resolver: yupResolver(userSignupSchema),
  })

  // Functions

  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  const openTermsAndCondModal = () => {
    if (!handleModalContext) return

    handleModalContext.handleModal(true, MODAL_CONSTS.HELP_VIDEO, {
      component: (
        <HelpRootPageDataPassOn handleModal={handleModalContext.handleModal} policy={termsPolicy} />
      ),
      small: true,
      positionModel: 'Right',
    })
  }

  return (
    <div>
      <div className="text-[22px] flex items-center gap-2 text-white md:w-[500px]">
        {/* <button onClick={() => stageHandle("CompOrg")}> */}
        <button onClick={() => stageHandle('Init')}>
          <img src={backIcon} alt="back" className="" />
        </button>
        {/* COMPETITOR, TRAINER, SPECTATOR, ETC. */}
      </div>
      <div>
        <h1 className="text-[22px] font-normal text-white mt-4 mb-2">Sign up</h1>
      </div>
      <form onSubmit={handleSubmit((e) => props.onSignUpButtonPressed(e, stage.selector))}>
        <div className="mt-0">
          <label className="mb-3 text-white flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/og_icons/User-4.svg" alt="icon" className="brightness-200" />
            </span>
            <input
              type="text"
              {...register('fullname', {
                onChange: (e) => {
                  e.target.value = e.target.value.replaceAll(/([^a-zA-Z\s])/gi, '')
                },
              })}
              placeholder="Full name"
              className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent focus:border:none w-full focus:border-none"
            />
          </label>
          {errors.fullname && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.fullname.message}
            />
          )}

          <label className="mb-3 text-gray-500 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/og_icons/userNameIcon.svg" alt="icon" className="brightness-200" />
            </span>
            <input
              type="text"
              {...register('username')}
              placeholder="Username"
              className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent focus:border:none w-full focus:border-none"
            />
          </label>
          {errors.username && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.username.message}
            />
          )}

          <label className="mb-3 text-gray-500 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/og_icons/Mail-4.svg" alt="icon" className="brightness-200" />
            </span>
            <input
              type="email"
              {...register('email')}
              placeholder="Email"
              className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent w-full focus:border-none"
            />
          </label>
          {errors.email && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.email.message}
            />
          )}

          <label className="mb-3 text-gray-500 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/og_icons/Lock-4.svg" alt="icon" className="brightness-200" />
            </span>
            <input
              type={innerType}
              {...register('newPassword')}
              placeholder="Password"
              className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent w-full focus:border-none"
            />
            <div onClick={() => setInnerType(innerType === 'password' ? 'text' : 'password')}>
              {innerType === 'password' ? (
                <VisibilityOffOutlined className="text-white" />
              ) : (
                <Visibility className="text-white" />
              )}
            </div>
          </label>
          {errors.newPassword && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.newPassword.message}
            />
          )}

          <label className="mb-3 text-gray-500 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/og_icons/Lock-4.svg" alt="icon" className="brightness-200" />
            </span>
            <input
              type={c_innerType}
              {...register('c_password')}
              placeholder="Confirm password"
              className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent w-full focus:border-none"
            />
            <div onClick={() => setC_innerType(c_innerType === 'password' ? 'text' : 'password')}>
              {c_innerType === 'password' ? (
                <VisibilityOffOutlined className="text-white" />
              ) : (
                <Visibility className="text-white" />
              )}
            </div>
          </label>
          {errors.c_password && (
            <MessageHelperComp
              className="bg-white rounded-lg px-2 mb-3 !mt-0 opacity-80 w-fit"
              isError={true}
              message={errors.c_password.message}
            />
          )}
        </div>
        <div className="w-[70%] mt-[30px] mx-auto">
          <UserSignupViewComponentButton
            type="submit"
            loading={props?.loading}
            caption="SIGN UP"
            kind={4}
            disabled={!stage.selector}
          />
        </div>
      </form>

      <div className="text-white my-6 text-center text-sm italic">
        By creating an account, you are agreeing to <br />
        Pegasus'
        <button
          type="button"
          onClick={openTermsAndCondModal}
          className="hidden"
          id="openTermsModal"
        >
          Open terms modal
        </button>
        <label htmlFor="openTermsModal" className="text-white underline cursor-pointer pl-1">
          Terms and Conditions
        </label>
      </div>
      {/* <div className="text-center text-white opacity-50 mt-8">OR</div> */}
      <div className="text-white my-4 text-center">
        Already have an account?{' '}
        <Link className="text-white underline cursor-pointer" to="/login">
          Sign in
        </Link>
      </div>
    </div>
  )
}

export default UserSignupViewComponentCompetitorSignupForm
