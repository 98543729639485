import { useRef } from 'react'

// Third party
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import Accordion from '../../../../components/accordion/common/Accordion'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ExhibitorCommentAccordion = (props: any) => {
  // Hooks and vars
  const textAreaText = useRef<string>('')
  const { commenttext } = props
  return (
    <Accordion
      icon={<AddCircleOutline className="add_icon text-[red]" />}
      initialState={true}
      className="mb-2"
      header="Comments"
      headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
      activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
    >
      <textarea
        onChange={(e) => (textAreaText.current = e.target.value)}
        rows={6}
        disabled={!!commenttext}
        contentEditable={false}
        placeholder="Add note to customer...(optional)"
        className="w-full border border-solid !ring-0 !border-[white] bg-SeabiscuitGrayThemeColor !border-opacity-30 rounded-lg font-normal text-[14px] text-SeabiscuitDark200ThemeColor"
      >
        {commenttext ? commenttext.comment : null}
      </textarea>
    </Accordion>
  )
}

export default ExhibitorCommentAccordion

// Functions

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
//  const sendEmailtoTeam = async () => {
//     try {
//         const res: any = await httpService({
//             url: "sendEmails",
//             method: "POST",
//             data: {
//                 mail_type: "refund_done",
//                 mail_data: {
//                     reciever_id: allData?.userId,
//                     sender_data: {
//                         senderName: `${userData.userFirstName} ${userData.userLastName}`,
//                         senderEmail: userData.userEmail
//                     }
//                 }
//             }
//         })

//         if (res?.status) {
//             return true
//         }
//     } catch (err) {
//         return false

//     }

// }
