import IExhibitorSlice from './types'

const INITIAL_STATE: IExhibitorSlice['IInitialSystemState'] = {
  totals: {
    feesPriceTotal: 0,
    eventsPriceTotal: 0,
    ticketsPriceTotal: 0,
  },
  refundTotals: {
    feesRefundPriceTotal: null,
    eventsRefundPriceTotal: null,
    ticketsRefundPriceTotal: null,
  },
  refundErrors: {
    feesError: null,
    eventsError: null,
    ticketsError: null,
  },
  resetRefundTotals: false,
  registration: null,
  exhibitorDetails: null,
  visitedUserProfileDetails: null,
  visitedRegisterProfileDetails: undefined,
  exhibitor_paperwork: {
    loading: false,
    button_loading: [],
  },
  refundAmount: {
    message: null,
    status: 'idle',
  },
  recipient: {
    status: 'idle',
    data: null,
    message: null,
  },
  refundRequests: {
    status: 'idle',
    data: {
      requests: [],
      amountRefunded: 0,
      amountRequested: 0,
    },
    message: null,
  },
  updateRecipientRefundStatus: {
    status: 'idle',
    message: null,
  },
}

const EXHIBITOR_SLICE_CONST = {
  INITIAL_STATE,
}

export default EXHIBITOR_SLICE_CONST
