import { useEffect, useRef, useState } from 'react'

// Third party
import clsx from 'clsx'
import DataTable from 'react-data-table-component'

// Components
import ViewsLoader from '../../loader/ViewsLoader'

// Types
import { IInifiniteScrollDataTable } from './InfiniteScrollDataTableTypes'
import helpers from '../../../commonHelpers/helpers'
import { useWindowResize } from '../../../hooks/useWindowResize'
import { customTableStyles } from './customTableStyles'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
interface IProps extends IInifiniteScrollDataTable<any> {
  id?: string
  hasMore: boolean
  fetchMore?: () => any
  reloadOnBasedOf?: any
  disableInfiniteScroll?: boolean
}

// Constants
const SCROLL_DETAILS = {
  dataLength: 0,
  lastScrolledTill: 0,
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const InfiniteScrollDataTable = (props: IProps) => {
  // Hooks and vars
  const { fetchMore } = props

  const loaderRef = useRef<HTMLDivElement | null>(null)
  const tableHolderRef = useRef<HTMLDivElement | null>(null)
  const { windowWidth } = useWindowResize()
  const [id] = useState(props?.id ?? getMyId())
  const scrollDetails = useRef({ ...SCROLL_DETAILS })
  const [loading, setLoading] = useState(false)
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    // disable column dragging by removing the draggable attribute from the th elements
    const thNodes = document.querySelectorAll('.rdt_TableHead th')
    thNodes.forEach((thNode) => {
      thNode.removeAttribute('draggable')
    })
  }, [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (props?.reloadOnBasedOf) {
      setLoading(false)
      scrollDetails.current = { ...SCROLL_DETAILS }
    }
  }, [props?.reloadOnBasedOf])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    let data = props.data
    let { dataLength } = scrollDetails.current

    if (loading && data.length > dataLength) {
      setLoading(false)
      scrollDetails.current.dataLength = data.length
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (props?.disableInfiniteScroll) return

    if (props.hasMore === false && loading) setLoading(false)

    let dataTableRef = tableHolderRef.current?.children[0]
    let padding = (loaderRef.current?.clientHeight ?? 0) + 40

    if (loading) dataTableRef?.setAttribute('style', `padding-bottom: ${[padding]}px`)
    else dataTableRef?.removeAttribute('style')
  }, [props.hasMore, loading, props?.disableInfiniteScroll])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (props?.disableInfiniteScroll) return

    if (fetchMore) {
      if (typeof fetchMore !== 'function')
        helpers.logger({
          isError: true,
          message: 'Prop given as fetchMore is not a function',
        })
    } else if (typeof props?.hasMore !== 'boolean') {
      helpers.logger({
        isError: true,
        message: 'Prop given as hasMore is invalid, hasMore should be of boolean type',
      })
    }

    let fullyScrolled = false
    let dataTableRef = tableHolderRef.current?.children[0]

    const scrollHandler = (e: any) => {
      if (!dataTableRef) return

      let { scrollHeight, clientHeight, scrollTop } = dataTableRef
      fullyScrolled = Math.abs(scrollHeight - clientHeight - scrollTop) < 1

      if (
        fullyScrolled &&
        scrollTop > scrollDetails.current.lastScrolledTill &&
        fetchMore &&
        props.hasMore
      ) {
        setLoading(true)
        scrollDetails.current.dataLength = props.data.length
        scrollDetails.current.lastScrolledTill = scrollTop + 1
        fetchMore()
      }
    }

    if (dataTableRef) {
      dataTableRef.addEventListener('scroll', scrollHandler)
    }

    return () => {
      if (dataTableRef) dataTableRef.removeEventListener('scroll', scrollHandler)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props?.disableInfiniteScroll])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  function getMyId() {
    let id = ''
    let date = Date.now().toString()
    date = date.substring(date.length - 5)
    id = `customDataTable${date}`
    return id
  }

  return (
    <div id={id} ref={tableHolderRef} className="relative customDatatable w-full">
      <DataTable
        {...props}
        className={clsx(props.className, 'transition-all')}
        persistTableHead={false}
        customStyles={customTableStyles(windowWidth)}
        onColumnOrderChange={() => {}}
      />

      {props?.disableInfiniteScroll ? null : (
        <div
          ref={loaderRef}
          className={clsx(
            'flex absolute bottom-[25px] justify-center left-[50%] translate-x-[-50%]',
            (!loading || !props.data?.length) && 'hidden'
          )}
        >
          <ViewsLoader size="sm" color="#F7074F" />
        </div>
      )}
    </div>
  )
}

export default InfiniteScrollDataTable
