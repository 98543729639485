/* eslint-disable react-hooks/exhaustive-deps */
// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { CONST } from '../../../../const/const'
import { getUserFullName } from '../../../../helpers/helpers'
import { IUserInterface } from '../../../../models/users/user.interface'
import customImageComponent from '../../../common/CustomImageComponent'
import { PhotoModal } from './modals/PhotoModal'
import { VideoModal } from './modals/VideoModal'

import FirestoreService from '../../../../services/firestoreService'

import { EventDetailsModel } from '../../../../models/event-details/event-details.model'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
// const ImageComponent: React.FC<{ src: any, alt: string, className?: string }> = ({ src, alt, className }) => (
//     <img src={src} alt={alt} className={`w-full h-full object-cover ${className}`} />
// );

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */

export interface EventDetailsViewComponentHeaderSectionProps {
  ownerId?: string | null
  coverImage?: string
  organizerDetails: IUserInterface | null
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailsViewComponentHeaderSection: React.FC<
  EventDetailsViewComponentHeaderSectionProps
> = (props) => {
  const { organizerDetails } = props
  const companyDetails = !!organizerDetails ? organizerDetails : ({} as IUserInterface)
  const [throattle, setThroattle] = useState(false)
  const [openVideoModal, setOpenVideoModal] = useState(false)
  const [openPhotoModal, setOpenPhotoModal] = useState(false)

  const params: Record<string, string> = useParams()
  const eventId = params.eventId
  const [eventData, setEventData] = useState<any>(null)

  useEffect(() => {
    setTimeout(() => {
      setThroattle(true)
    }, 3000)
  }, [])

  const handleShowBanner = (coverImage: string | undefined) => {
    if (!throattle) {
      return (
        <span className="blink_img ">
          <img src="/assets/background-3.png" alt="header" className="w-full" />{' '}
        </span>
      )
    } else {
      if (coverImage && coverImage !== '') {
        return customImageComponent(props?.coverImage, '', 'w-full')
      } else {
        return <img src="/assets/Banner.png" alt="header" className="w-full" />
      }
    }
  }

  useEffect(() => {
    if (!eventData) {
      const fetchData = async () => {
        const doc = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_DETAILS.NAME,
          eventId
        )
        if (doc) {
          const eventData = EventDetailsModel.fromFirestoreDoc(doc).toObject()

          setEventData(eventData)
        }
      }
      fetchData().catch()
    }
  }, [eventData, setEventData, eventId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div className="relative mb-16 md:mb-[30px]">
      <div className="absolute top-3 md:top-auto md:bottom-3 right-3 flex gap-2">
        {eventData?.competitionEventImage?.length > 0 && (
          <div
            className="text-[black] text-[14px] bg-white bg-opacity-70 flex gap-2 items-center rounded-xl p-2 px-4 cursor-pointer"
            onClick={() => setOpenPhotoModal(true)}
          >
            <img src="/assets/images.svg" alt="Image_" />
            <span className="hidden sm:block">Images</span>
          </div>
        )}
        {eventData?.competitionEventVideo?.length > 0 && (
          <div
            className="text-[black] text-[14px] bg-white bg-opacity-70 flex gap-2 items-center rounded-xl p-2 px-4 cursor-pointer"
            onClick={() => setOpenVideoModal(true)}
          >
            <img src="/assets/video.svg" alt="Video" />
            <span className="hidden sm:block">Videos</span>
          </div>
        )}
      </div>
      <div className="rounded-[20px] overflow-hidden h-[200px] md:h-[400px]  border-0">
        {handleShowBanner(props.coverImage)}
      </div>
      <div className="absolute bottom-[0] left-[50%] translate-x-[-50%] translate-y-[50%] md:translate-x-[0] md:translate-y-[0] rounded-[20px] w-10/12 max-w-[280px] md:bottom-3 md:left-3 bg-white md:rounded-xl p-3 2xl:p-5 drop-shadow-[0_4px_12px_rgba(0,0,0,0.10)] ">
        <Link
          to={`${CONST.ROUTES.USER_PROFILE.URL}/${companyDetails?.id}`}
          className="items-center flex flex-wrap"
        >
          <img
            src={
              organizerDetails?.userProfilePicture
                ? organizerDetails?.userProfilePicture
                : '/assets/placeholders/Emptyuser.png'
            }
            alt="logo"
            className="w-[45px]  h-[45px] object-cover 2xl:w-[60px] 2xl:h-[60px] rounded-xl drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] mr-3"
          />
          <div>
            <h1 className="m-0 text-[16px] font-bold md:text-[18px] text-SeabiscuitDark200ThemeColor mb-1 capitalize">
              {getUserFullName(companyDetails)}
            </h1>
            <p className="m-0 text-SeabiscuitGreyLightThemeColor text-[12px] md:text-[14px]">
              Proudly presents...
            </p>
          </div>
        </Link>
      </div>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          videosURL={eventData?.competitionEventVideo}
          onClose={() => setOpenVideoModal(false)}
        />
      )}
      {openPhotoModal && (
        <PhotoModal
          open={openPhotoModal}
          imageURL={eventData?.competitionEventImage}
          onClose={() => setOpenPhotoModal(false)}
        />
      )}
    </div>
  )
}
export default EventDetailsViewComponentHeaderSection
