import { useContext, useEffect, useState } from 'react'

import clsx from 'clsx'
import { where } from 'firebase/firestore'
import { useParams } from 'react-router'

import InfiniteScrollDataTable from '../../../../components/common/tables/InfiniteScrollDataTable'
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { EventModel } from '../../../../models/events/event.model'
import { getConvertedData } from '../../../../models/interface.helper'
import { IRegistrationFeesDocIdsDetail } from '../../../../models/registration-fees-stats/registrationFeesStats.interface'
import { RegistrationFeesStatsModel } from '../../../../models/registration-fees-stats/registrationFeesStats.model'
import { RegistrationFeesModel } from '../../../../models/registration-fees/registrationFees.model'
import { IRegistrationFeesInterface } from '../../../../models/registration-fees/registrationFees.interface'
import { EventRegisteredUsersModel } from '../../../../models/event-registered-users/event-registered-users.model'
import { TEventRegisteredUsers } from '../../../../models/event-registered-users/event-registered-users.interface'

import FirestoreService from '../../../../services/firestoreService'
import { selectedEvent } from '../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../store/hooks'
import { MANAGE_LOGISTICS_CONSTS } from './data/logistics.data.const'
import { customStyles } from '../../../allEvents/root/AllEventsTableStyles'
import { columns, columnsAll } from './utils/table'

import './ManageClinicNOtherLogisticsTab.css'

import { FEES_CATEGORY_CONST } from '../../../../components/events/views/details/EventDetailsViewComponentFees'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const DUMMY_REGISTRATION_FEES_STATS = [
  { ...new RegistrationFeesStatsModel(), loading: true },
  { ...new RegistrationFeesStatsModel(), loading: true },
  { ...new RegistrationFeesStatsModel(), loading: true },
  { ...new RegistrationFeesStatsModel(), loading: true },
]

interface IItem {
  boughtItemsCount: number
  registrationFeesDocIdsDetails: IRegistrationFeesDocIdsDetail[]
}

export interface IRegistrationFeesStatsAll {
  loading: boolean
  userId: string | null
  userName: string | null
  userEmail: string | null
  userProfilePicture?: string | null
  paymentStatus: 'paid' | 'pending' | 'cash' | 'refunded' | null
  stalls?: IItem | null
  feed?: IItem | null
  bedding?: IItem | null
  other?: IItem | null
}

export interface IRegistrationFeesInterfaceEx extends IRegistrationFeesInterface {
  loading?: boolean
}

export type IStats = 'stalls' | 'feed' | 'bedding' | 'other'

const MCEC = MANAGE_LOGISTICS_CONSTS

const ManageClinicNOtherLogisticsTab = (_: any) => {
  const toastFunctions = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)
  const event = useAppSelector(selectedEvent)?.Event ?? new EventModel().toObject()

  const eventId = useParams<{ eventId: string }>().eventId ?? null

  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState<IStats | string>(MCEC.TAB_NAMES.ALL.value)
  const [data, setData] = useState<IRegistrationFeesStatsAll[] | IRegistrationFeesInterfaceEx[]>([])
  const [unSold, setUnSold] = useState(0)
  const [sold, setSold] = useState(0)

  const handleGetFeesData = async (activeTab: string) => {
    const newData: IRegistrationFeesStatsAll[] = []

    try {
      let queries = [
        where(COLLECTIONS.REGISTRATION_FEES.FIELDS.EVENT_ID.KEY, '==', eventId),
        where(
          COLLECTIONS.REGISTRATION_FEES.FIELDS.PAYMENT_STATUS.KEY,
          '!=',
          COLLECTIONS.REGISTRATION_FEES.FIELDS.PAYMENT_STATUS.VALUES.PENDING
        ),
      ]

      let registrationFees: IRegistrationFeesInterface[] = []
      let registeredUsers: TEventRegisteredUsers[] = []

      setLoading(true)

      if (activeTab !== MCEC.TAB_NAMES.ALL.value) {
        queries.push(
          where(COLLECTIONS.REGISTRATION_FEES.FIELDS.FEES_ITEM_CATEGORY.KEY, '==', activeTab)
        )
      }

      const registrationFeesSnaps = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_FEES.NAME,
        queries
      )

      const registeredUsersSnaps = await FirestoreService.filterItems(
        COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        [where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', eventId)]
      )

      if (registeredUsersSnaps.size) {
        registeredUsersSnaps.docs.forEach((currDoc) => {
          registeredUsers.push(
            getConvertedData(EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }

      if (registrationFeesSnaps.size) {
        registrationFeesSnaps.docs.forEach((currDoc) => {
          registrationFees.push(
            getConvertedData(RegistrationFeesModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }

      registrationFees.forEach((registrationFee) => {
        if (
          registrationFee.selectedUnitsCount > 0 &&
          registrationFee.feesItemCategory !== FEES_CATEGORY_CONST.GOVERNANCE
        ) {
          const index = newData.findIndex((obj) => obj?.userId === registrationFee.userId)
          const user = registeredUsers.find((obj) => obj.userId === registrationFee.userId)

          if (index !== -1) {
            const fee = newData[index][registrationFee.feesItemCategory as IStats]

            // Modify the name property of the object at the found index
            newData[index][registrationFee.feesItemCategory as IStats] = {
              boughtItemsCount:
                Number(fee?.boughtItemsCount ?? 0) + registrationFee.selectedUnitsCount,
              registrationFeesDocIdsDetails:
                [
                  ...(fee?.registrationFeesDocIdsDetails ? fee.registrationFeesDocIdsDetails : []),
                  {
                    registrationFeesDocId: registrationFee.id!,
                    feesItemId: registrationFee.feesItemId,
                    feesTitle: registrationFee.feesTitle,
                    selectedItemCount: registrationFee.selectedUnitsCount,
                    feesItemCategory: registrationFee.feesItemCategory,
                    paymentStatus: registrationFee.paymentStatus,
                  },
                ] ?? [],
            }
          } else {
            newData.push({
              loading: false,
              userId: registrationFee.userId,
              userName: registrationFee.userName,
              userProfilePicture: user?.userProfilePicture,
              userEmail: user?.userEmail ?? null,
              paymentStatus: registrationFee.paymentStatus ?? null,
              [registrationFee.feesItemCategory as IStats]: {
                boughtItemsCount: registrationFee.selectedUnitsCount,
                registrationFeesDocIdsDetails: [
                  {
                    feesItemId: registrationFee.feesItemId,
                    feesTitle: registrationFee.feesTitle,
                    selectedItemCount: registrationFee.selectedUnitsCount,
                    feesItemCategory: registrationFee.feesItemCategory,
                    paymentStatus: registrationFee.paymentStatus,
                  },
                ],
              },
            })
          }
        }
      })

      setData(newData)
    } catch (error) {
      console.error(error)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetFeesData(activeTab).then()
  }, [activeTab])

  useEffect(() => {
    getSoldUnSoldCounts()
  }, [data])

  const getSoldUnSoldCounts = () => {
    let unSold_
    let sold_ = 0

    // @ts-ignore
    sold_ = data.reduce((_total, item) => {
      for (let key in item) {
        if (item[key] && item[key]?.boughtItemsCount && key !== FEES_CATEGORY_CONST.GOVERNANCE) {
          _total += item[key].boughtItemsCount
        }
      }
      return _total
    }, 0)

    switch (activeTab) {
      case MCEC.TAB_NAMES.STALLS.value:
        unSold_ = event.totalStallsCount - sold_
        break
      case MCEC.TAB_NAMES.FEED.value:
        unSold_ = event.totalFeedCount - sold_
        break
      case MCEC.TAB_NAMES.BEDDING.value:
        unSold_ = event.totalBeddingCount - sold_
        break
      case MCEC.TAB_NAMES.OTHER.value:
        unSold_ = event.totalOtherCount - sold_
        break
      default:
        unSold_ =
          event.totalStallsCount +
          event.totalFeedCount +
          event.totalBeddingCount +
          event.totalOtherCount
    }

    setUnSold(unSold_)
    setSold(sold_)
  }

  const handleActiveTab = (item: { tab: string; id: string; disabled?: boolean }) => {
    setActiveTab(item.id)
  }

  return (
    <div className="p-5 rounded-xl bg-SeabiscuitWhiteThemeColor w-[100%]">
      <div className="flex flex-col md:flex-row md:items-center md:h-[60px] justify-between">
        <div className="flex flex-col md:flex-row flex-wrap mb-4 md:mb-0">
          {MCEC.TABS.map((tab, index: number) => {
            return (
              <div
                key={`${tab.tab}b${index}`}
                onClick={() => handleActiveTab(tab)}
                className={`py-2 px-4 text-[14px] cursor-pointer mr-2 rounded-xl ${
                  activeTab === tab.id
                    ? 'bg-[#F7074F0D] text-SeabiscuitMainThemeColor'
                    : 'text-SeabiscuitDark200ThemeColor'
                }`}
              >
                {tab.tab}
              </div>
            )
          })}
        </div>
        <div className="flex flex-col md:flex-row gap-2">
          <div className="text-SeabiscuitGreenThemeColor w-[55px] bg-SeabiscuitGreenLightThemeColor rounded-md font-normal h-[55px] flex items-center justify-center px-2 flex-col">
            <div className="text-lg font-bold">{sold}</div>
            <div className="text-xs leading-[1]">Sold</div>
          </div>
          <div className="text-[#7000FF] w-[55px] bg-[#8B2CF526] rounded-md font-normal h-[55px] flex items-center justify-center px-2 flex-col">
            <div className="text-lg font-bold">{unSold}</div>
            <div className="text-xs leading-[1]">Unsold</div>
          </div>
        </div>
      </div>

      <InfiniteScrollDataTable
        hasMore={false}
        className={clsx(
          '!rounded-none exhibitorListTable !h-[58vh] !overflow-auto mt-8 manage-clinic-table'
        )}
        columns={
          activeTab === MCEC.TAB_NAMES.ALL.value
            ? columnsAll()
            : columns(activeTab as IStats, handleModalContext)
        }
        customStyles={customStyles}
        data={loading ? DUMMY_REGISTRATION_FEES_STATS : data}
      />
    </div>
  )
}

export default ManageClinicNOtherLogisticsTab
