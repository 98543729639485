import { TableStyles } from 'react-data-table-component'
import { LG_WIDTH, MD_WIDTH } from '../../../helpers/mdWidth'

export const customTableStyles = (width: number): TableStyles => {
  if (width >= LG_WIDTH) {
    return {
      table: {
        style: {
          minWidth: '1100px !important',
        },
      },
    }
  }
  return {
    cells: {
      style: {
        width: '100% !important',
        minWidth: '100% !important',
        maxWidth: '100% !important',
      },
    },
    table: {
      style: {
        maxHeight: '100% !important',
        minWidth: 'auto !important',
      },
    },
    head: {
      style: {
        display: 'none',
      },
    },
    header: {
      style: {
        display: 'none',
      },
    },
    rows: {
      style: {
        padding: '20px 0',
        flexDirection: 'column',
        gap: '20px',
      },
    },
  }
}
