export const PoliciesData = [
  {
    title: 'Terms & Conditions',
    scriptName: 'terms',
    dataId: '2bd6272b-f81a-47e5-bf08-6e21bce10d3a',
    policy:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Auctor neque vitae tempus quam pellentesque. Suscipit tellus mauris a diam maecenas. Lectus nulla at volutpat diam ut venenatis tellus in. Condimentum id venenatis a condimentum vitae. Etiam sit amet nisl purus in. Consectetur adipiscing elit ut aliquam purus sit amet. Accumsan sit amet nulla facilisi. Eget gravida cum sociis natoque penatibus et magnis dis. Mi quis hendrerit dolor magna eget est. Venenatis tellus in metus vulputate eu scelerisque felis. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Condimentum mattis pellentesque id nibh tortor id aliquet lectus. At quis risus sed vulputate odio ut enim. Ut placerat orci nulla pellentesque dignissim. Lorem mollis aliquam ut porttitor leo a diam sollicitudin tempor.',
  },
  {
    title: 'Privacy Policy',
    scriptName: 'policy',
    dataId: '5cd2a2c0-8b7f-4c92-8514-80795905f1b9',
    policy:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Auctor neque vitae tempus quam pellentesque. Suscipit tellus mauris a diam maecenas. Lectus nulla at volutpat diam ut venenatis tellus in. Condimentum id venenatis a condimentum vitae. Etiam sit amet nisl purus in. Consectetur adipiscing elit ut aliquam purus sit amet. Accumsan sit amet nulla facilisi. Eget gravida cum sociis natoque penatibus et magnis dis. Mi quis hendrerit dolor magna eget est. Venenatis tellus in metus vulputate eu scelerisque felis. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Condimentum mattis pellentesque id nibh tortor id aliquet lectus. At quis risus sed vulputate odio ut enim. Ut placerat orci nulla pellentesque dignissim. Lorem mollis aliquam ut porttitor leo a diam sollicitudin tempor.',
  },
  {
    title: 'End User License Agreement',
    scriptName: 'endUserAgreement',
    dataId: 'aaeced03-8816-43da-9edc-927a4f2150bd',
    policy:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Auctor neque vitae tempus quam pellentesque. Suscipit tellus mauris a diam maecenas. Lectus nulla at volutpat diam ut venenatis tellus in. Condimentum id venenatis a condimentum vitae. Etiam sit amet nisl purus in. Consectetur adipiscing elit ut aliquam purus sit amet. Accumsan sit amet nulla facilisi. Eget gravida cum sociis natoque penatibus et magnis dis. Mi quis hendrerit dolor magna eget est. Venenatis tellus in metus vulputate eu scelerisque felis. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Condimentum mattis pellentesque id nibh tortor id aliquet lectus. At quis risus sed vulputate odio ut enim. Ut placerat orci nulla pellentesque dignissim. Lorem mollis aliquam ut porttitor leo a diam sollicitudin tempor.',
  },
  {
    title: 'Disclaimer',
    scriptName: 'disclaimer',
    dataId: 'ab434b98-c75d-4bad-af23-3b4cfaf6c273',
    policy:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Auctor neque vitae tempus quam pellentesque. Suscipit tellus mauris a diam maecenas. Lectus nulla at volutpat diam ut venenatis tellus in. Condimentum id venenatis a condimentum vitae. Etiam sit amet nisl purus in. Consectetur adipiscing elit ut aliquam purus sit amet. Accumsan sit amet nulla facilisi. Eget gravida cum sociis natoque penatibus et magnis dis. Mi quis hendrerit dolor magna eget est. Venenatis tellus in metus vulputate eu scelerisque felis. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Condimentum mattis pellentesque id nibh tortor id aliquet lectus. At quis risus sed vulputate odio ut enim. Ut placerat orci nulla pellentesque dignissim. Lorem mollis aliquam ut porttitor leo a diam sollicitudin tempor.',
  },
  {
    title: 'Acceptable Use Policy',
    scriptName: 'acceptableUsePolicy',
    dataId: '47064cad-6876-4b44-8292-ee38b28ea1fb',
    policy:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Auctor neque vitae tempus quam pellentesque. Suscipit tellus mauris a diam maecenas. Lectus nulla at volutpat diam ut venenatis tellus in. Condimentum id venenatis a condimentum vitae. Etiam sit amet nisl purus in. Consectetur adipiscing elit ut aliquam purus sit amet. Accumsan sit amet nulla facilisi. Eget gravida cum sociis natoque penatibus et magnis dis. Mi quis hendrerit dolor magna eget est. Venenatis tellus in metus vulputate eu scelerisque felis. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Condimentum mattis pellentesque id nibh tortor id aliquet lectus. At quis risus sed vulputate odio ut enim. Ut placerat orci nulla pellentesque dignissim. Lorem mollis aliquam ut porttitor leo a diam sollicitudin tempor.',
  },
]
