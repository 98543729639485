// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import * as React from 'react'

import EventScoringFormBuilderUtilMainRow from './rows/main/EventScoringFormBuilderUtilMainRow'
import EventScoringFormBuilderUtilRowDisplayModeControlComponent from './controls/EventScoringFormBuilderUtilRowDisplayModeControlComponent'
import { useEffect, useRef, useState } from 'react'
import { EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST } from './EventScoringFormBuilderUtil.const'
import { CONFIG } from '../../../../../../config/config'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventPriceListFormBuilderProps {
  data?: any
  register?: any
  errors?: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventScoringFormBuilderUtil: React.FC<EventPriceListFormBuilderProps> = (props) => {
  const mainRowRefs: React.MutableRefObject<any[]> = useRef<any[]>([])
  const divisionRowRefs: React.MutableRefObject<any[]> = useRef<any[]>([])
  const sectionsRowRefs: React.MutableRefObject<any[]> = useRef<any[]>([])
  const classesRowRefs: React.MutableRefObject<any[]> = useRef<any[]>([])

  const [rowDisplayMode, setRowDisplayMode] = useState(
    EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.DISCIPLINE
  )

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onRowDisplayModeChangeHandle = (display_mode: any) => {
    // switch (display_mode) {
    //     case  EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.DISCIPLINE:
    //         mainRowRefs.current.forEach(
    //             value => {
    //                 value.collapseRow();
    //             }
    //         );
    //         break;
    //     case  EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.DIVISIONS:
    //         divisionRowRefs.current.forEach(
    //             value => {
    //                 value.collapseRow();
    //             }
    //         );
    //         break;
    //     case  EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.SECTIONS:
    //         sectionsRowRefs.current.forEach(
    //             value => {
    //                 value.collapseRow();
    //             }
    //         );
    //         break;
    //     case  EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.CLASSES:
    //         classesRowRefs.current.forEach(
    //             value => {
    //                 value.collapseRow();
    //             }
    //         );
    //         break;
    //     default:
    //         alert("There has been an error in the generation of the item.")
    // }

    setRowDisplayMode(display_mode)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (props.data) {
      mainRowRefs.current = mainRowRefs.current.slice(0, props.data.items?.length)
    }
  }, [props.data])

  return (
    <>
      <EventScoringFormBuilderUtilRowDisplayModeControlComponent
        rowDisplayMode={rowDisplayMode}
        onRowDisplayModeChanged={onRowDisplayModeChangeHandle}
      />
      <>
        {props.data.items?.map((item: any, index: any) => (
          <EventScoringFormBuilderUtilMainRow
            key={item.mainTitle}
            ref={(el) => (mainRowRefs.current[index] = el)}
            register={props.register}
            item={item}
            disciplineIndex={index}
            divisionRowRefs={divisionRowRefs}
            sectionsRowRefs={sectionsRowRefs}
            classesRowRefs={classesRowRefs}
            errors={props.errors}
          />
        ))}
      </>
    </>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
EventScoringFormBuilderUtil.defaultProps = CONFIG.EVENT.SCORING.FORM_BUILDER.TEMPLATE.SAMPLE

export default EventScoringFormBuilderUtil
