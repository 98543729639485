import { Tooltip } from '@mui/material'
import { capitalize } from '../../../../../helpers/helpers'

const EventReviewPublishInput: React.FC<{
  className?: string
  data?: any
  onClick?: any
  moreBtn?: boolean
  tooltipText?: string
}> = ({ className, data, onClick, moreBtn, tooltipText }) => {
  return (
    <>
      {tooltipText ? (
        <Tooltip title={tooltipText} placement="top" arrow>
          <div onClick={onClick} className={`${className}`}>
            {data}
            {moreBtn ? (
              <span className="text-SeabiscuitDark200ThemeColor cursor-pointer underline">
                more
              </span>
            ) : null}
          </div>
        </Tooltip>
      ) : (
        <div onClick={onClick} className={`${className}`}>
          {typeof data === 'string'
            ? capitalize(data, {
                capitalizeAll: false,
              })
            : data}{' '}
          {moreBtn ? (
            <span className="text-SeabiscuitDark200ThemeColor cursor-pointer underline">more</span>
          ) : null}
        </div>
      )}
    </>
  )
}

export default EventReviewPublishInput
