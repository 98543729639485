import { useState } from 'react'

// Component imports
import WrapperContainer from '../../../components/common/wrappers/WrapperContainer'
import { PillButtonElement } from '../../../components/elements/buttons/pill/PillButtonElement'
import CompetitorBillDisplayTabs from './CompetitorBillDisplayTabs'

type Props = {
  handleModal: any
}

type TBills = {
  id: number
  tab: string
  tabTitle: string
}

const billsTabs: TBills[] = [
  {
    id: 4,
    tab: 'all',
    tabTitle: 'All',
  },
  {
    id: 0,
    tab: 'unpaid',
    tabTitle: 'Unpaid',
  },
  {
    id: 1,
    tab: 'paid',
    tabTitle: 'Paid',
  },
]

const CompetitorBillsRootPage = (props: Props) => {
  const [sortstyle, setSortStyle] = useState('')
  const [selectedTab, setSelectedTab] = useState<TBills>({
    id: 4,
    tab: 'all',
    tabTitle: 'All',
  })

  const selectTab = (item: TBills) => {
    setSelectedTab(item)
  }

  return (
    <WrapperContainer title="My Bills">
      <>
        <div className="max-w-full overflow-y-auto mb-4">
          <ul className="flex pb-3 justify-between w-100">
            <span className="flex">
              {billsTabs.map((item, itemIndex) => {
                return (
                  <PillButtonElement
                    key={`${JSON.stringify(item)}${itemIndex}`}
                    text={item.tabTitle}
                    Class="h-[45px] w-[120px] border-none text-SeabiscuitDark200ThemeColor"
                    onButtonClicked={() => selectTab(item)}
                    value={item.id}
                    selected={selectedTab.tab === item.tab}
                  />
                )
              })}
            </span>
            <select
              className="form-control border rounded-lg !border-white shadow-none text-SeabiscuitDark200ThemeColor mr-1 pr-7 text-[14px]"
              onChange={(e) => setSortStyle(e.currentTarget.value)}
            >
              <option value="">Sort By</option>
              <option value="Newest">Newest</option>
              <option value="Oldest">Oldest</option>
            </select>
          </ul>
        </div>
        <CompetitorBillDisplayTabs
          handleModal={props.handleModal}
          selectedTab={selectedTab.tab}
          sortstyle={sortstyle}
          setSortStyle={setSortStyle}
        />
      </>
    </WrapperContainer>
  )
}

export default CompetitorBillsRootPage
