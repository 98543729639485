import { Modal } from './Modal'

interface Props {
  open: boolean
  url: string
  onClose: () => void | Promise<void>
}
export const VideoModal = ({ open, onClose, url }: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="lg:w-[800px] lg:h-[450px] bg-black transition duration-500 rounded-xl">
        <video
          width="100%"
          height="100%"
          className="max-h-[450px] rounded-xl"
          autoPlay
          loop
          controls
        >
          <source
            src={
              url ??
              'https://firebasestorage.googleapis.com/v0/b/pegasus-ems.appspot.com/o/globals%2Foriginal-4D47D2FA-657D-4B38-9316-FB902BC8B943.mp4?alt=media&token=b8ff214f-2715-4060-b579-c8d2261a2dd5'
            }
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <button className="absolute right-0 top-0 p-[10px]" onClick={onClose}></button>
      </div>
    </Modal>
  )
}
