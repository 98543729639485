export const SheetFEIPonyRiders = {
  id: 'SheetFEIPonyRiders',
  name: 'FEI Pony Riders',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 200,
      type: 'test',
      rows: [
        {
          number: 1,
          description: '<p>Collected walk (20m continuous)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description: '<p>Extended walk (20m continuous)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 3,
          description: '<p>Half pirouette in collected walk to the right and/or to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p>Shoulder-in right (collected trot) (minimum 12 m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p>Shoulder-in left (collected trot) (minimum 12 m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Half-pass right (collected trot)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description: '<p>Half-pass left (collected trot)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description: '<p>Extended trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p>Counter canter right (minimum 20m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p>Counter canter left (minimum 20m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p>Simple change of leg to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description: '<p>Simple change of leg to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p>Collected canter including circle 8m diameter to the left and/or right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p>Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p>The entrance and halts at the beginning and the end of the test</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
    },
    {
      name: 'Artistic Impression',
      max: 200,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rythm</h5><p>Rhythm, energy, and elasticity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Harmony</h5><p>Harmony Between Horse and Rider</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Choreography</h5><p>Choreography. Use of Arena. Inventiveness.</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Difficulty</h5><p>Degree of Difficulty. Calculated risks.</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Music</h5><p>Music and Interpretation of the music</p>',
          score: '',
          coefficient: 4,
        },
      ],
    },
  ],
}

export const SheetFEIJuniorsFreestyle = {
  id: 'SheetFEIJuniorsFreestyle',
  name: 'FEI Juniors Freestyle',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 200,
      type: 'test',
      rows: [
        {
          number: 1,
          description: '<p>Collected walk (minimum 20m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description: '<p>Half pirouette in collected walk to the right and/or to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p>Extended walk (minimum 20 m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p>Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p>Shoulder-in right (collected trot) (minimum 12 m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Shoulder-in left (collected trot) (minimum 12 m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p>Half-pass right (collected trot)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description: '<p>Half-pass left (collected trot)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description: '<p>Extended trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p>Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p>Half-pass right (collected canter)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p>Half-pass left (collected canter)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p>Flying change to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description: '<p>Flying change to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description: '<p>Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description: '<p>The entrance and halts at the beginning and the end of the test</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
    },
    {
      name: 'Artistic Impression',
      max: 200,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rythm</h5><p>Rhythm, energy, and elasticity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Harmony</h5><p>Harmony Between Horse and Rider</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Choreography</h5><p>Choreography. Use of Arena. Inventiveness.</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Difficulty</h5><p>Degree of Difficulty. Calculated risks.</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Music</h5><p>Music and Interpretation of the music</p>',
          score: '',
          coefficient: 4,
        },
      ],
    },
  ],
}

export const SheetFEIYoungRidersFreestyle = {
  id: 'SheetFEIYoungRidersFreestyle',
  name: 'FEI Young Riders Freestyle',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 200,
      type: 'test',
      rows: [
        {
          number: 1,
          description: '<p>Collected walk (minimum 20m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description: '<p>Extended walk (minimum 20m)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 3,
          description: '<p>Shoulder-in right (collected trot) (minimum 12m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p>Shoulder-in left (collected trot) (minimum 12m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p>Half-pass right (collected trot)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Half-pass left (collected trot)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description: '<p>Extended trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p>Half-pass right (collected canter)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p>Half-pass left (collected canter)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p>Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p>Flying changes every fourth stride (minimum 5 times consecutively)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p>Flying changes every third stride (minimum 5 times consecutively)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p>Half pirouette in canter right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description: '<p>Half pirouette in canter left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description: '<p>The entrance and halts at the beginning and the end of the test</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
    },
    {
      name: 'Artistic Impression',
      max: 200,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rythm</h5><p>Rhythm, energy, and elasticity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Harmony</h5><p>Harmony Between Horse and Rider</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Choreography</h5><p>Choreography. Use of Arena. Inventiveness.</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Difficulty</h5><p>Degree of Difficulty. Calculated risks.</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Music</h5><p>Music and Interpretation of the music</p>',
          score: '',
          coefficient: 4,
        },
      ],
    },
  ],
}

export const SheetFEIIntermediate1Freestyle = {
  id: 'SheetFEIIntermediate1Freestyle',
  name: 'FEI Intermediate 1 Freestyle',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 200,
      type: 'test',
      rows: [
        {
          number: 1,
          description: '<p>Collected walk (minimum 20m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description: '<p>Extended walk (minimum 20m)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 3,
          description: '<p>Shoulder-in right (collected trot) (minimum 12m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p>Shoulder-in left (collected trot) (minimum 12m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p>Half-pass right (collected trot)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Half-pass left (collected trot)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description: '<p>Extended trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p>Half-pass right (collected canter)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p>Half-pass left (collected canter)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p>Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p>Flying changes every third stride (minimum 5 times consecutively)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p>Flying changes every second stride (minimum 5 times consecutively)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p>Single pirouette in canter right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description: '<p>Single pirouette in canter left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description: '<p>The entrance and halts at the beginning and the end of the test</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
    },
    {
      name: 'Artistic Impression',
      max: 200,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rythm</h5><p>Rhythm, energy, and elasticity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Harmony</h5><p>Harmony Between Horse and Rider</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Choreography</h5><p>Choreography. Use of Arena. Inventiveness.</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Difficulty</h5><p>Degree of Difficulty. Calculated risks.</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Music</h5><p>Music and Interpretation of the music</p>',
          score: '',
          coefficient: 4,
        },
      ],
    },
  ],
}

export const SheetFEIIntermediateABFreestyle = {
  id: 'SheetFEIIntermediateABFreestyle',
  name: 'FEI Intermediate A/B Freestyle',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 200,
      type: 'test',
      rows: [
        {
          number: 1,
          description: '<p>Collected walk (minimum 20m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description: '<p>Extended walk (minimum 20m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p>Half-pass right (collected trot)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p>Half-pass left (collected trot)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p>Extended trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Half-pass right (collected canter)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p>Half-pass left (collected canter)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p>Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p>Flying changes every second stride (minimum 5 times consecutively)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p>Flying changes every stride (minimum 5 times consecutively)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p>Canter pirouette right (single only allowed)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description: '<p>Canter pirouette left (single only allowed)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description: '<p>Passage (minimum 12m on one track; half passes not permitted)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p>Piaffe (minimum 8 steps straight; half steps 2m forward allowed, pirouettes not permitted)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description: '<p>Transitions in and out of piaffe (from walk, trot, or passage)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description: '<p>The entrance and halts at the beginning and the end of the test</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
    },
    {
      name: 'Artistic Impression',
      max: 200,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rythm</h5><p>Rhythm, energy, and elasticity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Harmony</h5><p>Harmony Between Horse and Rider</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Choreography</h5><p>Choreography. Use of Arena. Inventiveness.</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Difficulty</h5><p>Degree of Difficulty. Calculated risks.</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Music</h5><p>Music and Interpretation of the music</p>',
          score: '',
          coefficient: 4,
        },
      ],
    },
  ],
}

export const SheetFEIGrandPrixFreestyle = {
  id: 'SheetFEIGrandPrixFreestyle',
  name: 'FEI Grand Prix Freestyle',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 200,
      type: 'test',
      rows: [
        {
          number: 1,
          description: '<p>Collected walk (minimum 20m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description: '<p>Extended walk (minimum 20m)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p>Half-pass right (collected trot)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p>Half-pass left (collected trot)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p>Extended trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Half-pass right (collected canter)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p>Half-pass left (collected canter)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p>Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p>Flying changes every second stride (minimum 5 times consecutively)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p>Flying changes every stride (minimum 9 times consecutively)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p>Canter pirouette right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description: '<p>Canter pirouette left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description: '<p>Passage (minimum 15m on one track)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description: '<p>Piaffe (minimum 10 steps straight)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description: '<p>Transitions from passage to piaffe and from piaffe to passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description: '<p>The entrance and halts at the beginning and the end of the test</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
    },
    {
      name: 'Artistic Impression',
      max: 200,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rythm</h5><p>Rhythm, energy, and elasticity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Harmony</h5><p>Harmony Between Horse and Rider</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Choreography</h5><p>Choreography. Use of Arena. Inventiveness.</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Difficulty</h5><p>Degree of Difficulty. Calculated risks.</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Music</h5><p>Music and Interpretation of the music</p>',
          score: '',
          coefficient: 4,
        },
      ],
    },
  ],
}
