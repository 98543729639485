export type IUSEFEntry = {
  code: string
  class: string
}

export type IUSEFESection = {
  'AMERICAN SADDLEBRED': IUSEFEntry[]
  'ANDALUSIAN/LUSITANO & HALF ANDALUSIAN': IUSEFEntry[]
  'ARABIAN & HALF ARABIAN': IUSEFEntry[]
  'CARRIAGE DRIVING': IUSEFEntry[]
  CONNEMARA: IUSEFEntry[]
  DRESSAGE: IUSEFEntry[]
  'PARA-EQUESTRIAN DRESSAGE': IUSEFEntry[]
  'COMBINED DRIVING': IUSEFEntry[]
  ENDURANCE: IUSEFEntry[]
  'ENGLISH PLEASURE': IUSEFEntry[]
  'FRIESIAN & PART FRIESIAN': IUSEFEntry[]
  HACKNEY: IUSEFEntry[]
  'HUNTER/JUMPER': IUSEFEntry[]
  MORGAN: IUSEFEntry[]
  'NATIONAL SHOW HORSE': IUSEFEntry[]
  PARADE: IUSEFEntry[]
  'PASO FINO': IUSEFEntry[]
  EVENTING: IUSEFEntry[]
  REINING: IUSEFEntry[]
  ROADSTER: IUSEFEntry[]
  'OPEN SADDLESEAT EQUITATION': IUSEFEntry[]
  SHETLAND: IUSEFEntry[]
  TRAIL: IUSEFEntry[]
  VAULTING: IUSEFEntry[]
  'WELSH & HALF WELSH': IUSEFEntry[]
  'WESTERN DRESSAGE': IUSEFEntry[]
  'OPEN WESTERN': IUSEFEntry[]
  MISC: IUSEFEntry[]
}

export const sectionCodesByDisciplines: IUSEFESection = {
  'AMERICAN SADDLEBRED': [
    { code: '4601', class: '3-GAITED OPEN' },
    { code: '4602', class: '3-GAITED AMATEUR' },
    { code: '4603', class: '3-GAITED JUNIOR EXHIBITOR' },
    { code: '4605', class: '3-GAITED AMATEUR AND JUNIOR EXHIBITOR COMBINED' },
    { code: '4604', class: '3-GAITED PARK' },
    { code: '4701', class: '5-GAITED OPEN' },
    { code: '4702', class: '5-GAITED AMATEUR' },
    { code: '4703', class: '5-GAITED JUNIOR EXHIBITOR' },
    { code: '4704', class: '5-GAITED AMATEUR AND JUNIOR EXHIBITOR COMBINED' },
    { code: '4802', class: 'SHOW PLEASURE 5-GAITED' },
    { code: '4804', class: 'SHOW PLEASURE DRIVING' },
    { code: '4810', class: 'SHOW PLEASURE HORSE - THREE GAITED ADULT' },
    { code: '4811', class: 'SHOW PLEASURE HORSE - THREE GAITED JUNIOR EXHIBITOR' },
    {
      code: '4801',
      class: 'SHOW PLEASURE HORSE - THREE GAITED COMBINED ADULT AND JUNIOR EXHIBITOR',
    },
    { code: '4805', class: 'COUNTRY PLEASURE ENGLISH' },
    { code: '4806', class: 'COUNTRY PLEASURE WESTERN' },
    { code: '4807', class: 'COUNTRY PLEASURE HUNTER' },
    { code: '4808', class: 'COUNTRY PLEASURE DRIVING' },
    { code: '4809', class: '3-GAITED PARK PLEASURE' },
    { code: '4901', class: 'FINE HARNESS OPEN' },
    { code: '4902', class: 'FINE HARNESS AMATEUR' },
    { code: '4903', class: 'SADDLEBRED' },
  ],
  'ANDALUSIAN/LUSITANO & HALF ANDALUSIAN': [
    { code: '8002', class: 'A/L HALTER HORSE' },
    { code: '8010', class: 'A/L ENGLISH PLEASURE OPEN HORSE' },
    { code: '8011', class: 'A/L ENGLISH PLEASURE AMATEUR HORSE' },
    { code: '8012', class: 'A/L ENGLISH PLEASURE JUNIOR HORSE' },
    { code: '8013', class: 'A/L WESTERN PLEASURE OPEN HORSE' },
    { code: '8014', class: 'A/L WESTERN PLEASURE AMATEUR HORSE' },
    { code: '8015', class: 'A/L WESTERN PLEASURE JUNIOR HORSE' },
    { code: '8017', class: 'A/L WORKING WESTERN' },
    { code: '8005', class: 'A/L DRIVING HORSE' },
    { code: '8016', class: 'A/L SPECIALTY HORSE' },
    { code: '8018', class: 'A/L SPORT HORSE IN HAND' },
    { code: '8019', class: 'A/L SPORT HORSE U/S & SHOW HACK' },
    { code: '8007', class: 'A/L JUNIOR EXHIBITOR HORSE' },
    { code: '8008', class: 'A/L MISC' },
    { code: '8102', class: 'HALF-A/L HALTER HORSE' },
    { code: '8110', class: 'HALF-A/L ENGLISH PLEASURE OPEN HORSE' },
    { code: '8111', class: 'H-A/L ENGLISH PLEASURE AMATEUR HORSE' },
    { code: '8112', class: 'HALF A/L ENGLISH PLEASURE JUNIOR HORSE' },
    { code: '8113', class: 'HALF A/L WESTERN PLEASURE OPEN HORSE' },
    { code: '8114', class: 'HALF A/L WESTERN PLEASURE AMATEUR HORSE' },
    { code: '8115', class: 'HALF A/L WESTERN PLEASURE JUNIOR HORSE' },
    { code: '8105', class: 'HALF A/L DRIVING HORSE' },
    { code: '8116', class: 'HALF A/L SPECIALTY HORSE' },
    { code: '8107', class: 'HALF A/L JUNIOR EXHIBITOR HORSE' },
    { code: '8118', class: 'HALF A/L SPORT HORSE IN-HAND' },
    { code: '8119', class: 'HALF A/L SPORT HORSE U/S & SHOW HACK' },
    { code: '8017', class: 'HALF A/L WORKING WESTERN' },
    { code: '8108', class: 'HALF A/L MISC' },
  ],
  'ARABIAN & HALF ARABIAN': [
    { code: '1001', class: 'ARABIAN ENGLISH PLEASURE/PLEASURE DRIVING OPEN' },
    { code: '1010', class: 'ARABIAN ENGLISH PLEASURE/PLEASURE DRIVING ADULT AMATEUR' },
    { code: '1101', class: 'ARABIAN ENGLISH PLEASURE/PLEASURE DRIVING JUNIOR EXHIBITOR' },
    { code: '1002', class: 'ARABIAN WESTERN PLEASURE OPEN' },
    { code: '1020', class: 'ARABIAN WESTERN PLEASURE ADULT AMATEUR' },
    { code: '1104', class: 'ARABIAN WESTERN PLEASURE JUNIOR EXHIBITOR' },
    { code: '1040', class: 'ARABIAN REGULAR WORKING HUNTER' },
    { code: '1041', class: 'ARABIAN GREEN WORKING HUNTER' },
    { code: '1042', class: 'ARABIAN HUNTER SPECIALTY' },
    { code: '1005', class: 'ARABIAN HALTER' },
    { code: '1011', class: 'ARABIAN COUNTRY PLEASURE/COUNTRY PLEASURE DRIVING OPEN' },
    { code: '1012', class: 'ARABIAN COUNTRY PLEASURE/COUNTRY PLEASURE DRIVING ADULT AMATEUR' },
    { code: '1102', class: 'ARABIAN COUNTRY PLEASURE/COUNTRY PLEASURE DRIVING JUNIOR EXHIBITOR' },
    { code: '1013', class: 'ARABIAN HUNTER PLEASURE OPEN' },
    { code: '1014', class: 'ARABIAN HUNTER PLEASURE ADULT AMATEUR' },
    { code: '1103', class: 'ARABIAN HUNTER PLEASURE JUNIOR EXHIBITOR' },
    { code: '1021', class: 'ARABIAN WORKING WESTERN HORSE' },
    { code: '1023', class: 'ARABIAN SPECIALTY HORSE' },
    { code: '1043', class: 'ARABIAN SPORT HORSE IN-HAND' },
    { code: '1044', class: 'ARABIAN SPORT HORSE UNDER SADDLE/SHOW HACK' },
    { code: '3120', class: 'ARABIAN/HA/AA SADDLE SEAT EQUITATION' },
    { code: '3121', class: 'ARABIAN/HA/AA HUNT SEAT NOT TO JUMP EQUITATION' },
    { code: '3122', class: 'ARABIAN/HA/AA HUNT SEAT OVER FENCES EQUITATION' },
    { code: '3123', class: 'ARABIAN/HA/AA DRESSAGE SEAT EQUITATION' },
    { code: '3124', class: 'ARABIAN/HA/AA WESTERN SEAT EQUITATION' },
    { code: '3125', class: 'ARABIAN/HA/AA REINING SEAT EQUITATION' },
    { code: '3126', class: 'ARABIAN/HA/AA WESTERN HORSEMANSHIP' },
    { code: '3127', class: 'ARABIAN/HA/AA WALK-TROT COMBINED EQUITATION 10 & UNDER' },
    { code: '3128', class: 'ARABIAN/HA/AA ADULT COMBINED EQUITATION 18 & OLDER' },
    { code: '4500', class: 'ARABIAN/HALF-ARABIAN DRESSAGE' },
    { code: '1008', class: 'ARABIAN MISC' },
    { code: '1105', class: 'ARABIAN WESTERN DRESSAGE' },
    { code: '1245', class: 'HALF/ANGLO ARABIAN WESTERN DRESSAGE' },
    { code: '1201', class: 'HALF/ANGLO ARABIAN ENGLISH PLEASURE/PLEASURE DRIVING OPEN' },
    { code: '1210', class: 'HALF/ANGLO ARABIAN ENGLISH PLEASURE/PLEASURE DRIVING ADULT AMATEUR' },
    {
      code: '1401',
      class: 'HALF/ANGLO ARABIAN ENGLISH PLEASURE/PLEASURE DRIVING JUNIOR EXHIBITOR',
    },
    { code: '1202', class: 'HALF/ANGLO ARABIAN WESTERN PLEASURE OPEN' },
    { code: '1220', class: 'HALF/ANGLO ARABIAN WESTERN PLEASURE ADULT AMATEUR' },
    { code: '1404', class: 'HALF/ANGLO ARABIAN WESTERN PLEASURE JUNIOR EXHIBITOR' },
    { code: '1240', class: 'HALF/ANGLO ARABIAN REGULAR WORKING HUNTER' },
    { code: '1241', class: 'HALF/ANGLO ARABIAN GREEN WORKING HUNTER' },
    { code: '1242', class: 'HALF/ANGLO ARABIAN HUNTER SPECIALTY' },
    { code: '1205', class: 'HALF/ANGLO ARABIAN HALTER' },
    { code: '1211', class: 'HALF/ANGLO ARABIAN COUNTRY PLEASURE/PLEASURE DRIVING OPEN' },
    { code: '1212', class: 'HALF/ANGLO ARABIAN COUNTRY PLEASURE/PLEASURE DRIVING ADULT AMATEUR' },
    {
      code: '1402',
      class: 'HALF/ANGLO ARABIAN COUNTRY PLEASURE/COUNTRY PLEASURE DRIVING JUNIOR EXHIBITOR',
    },
    { code: '1213', class: 'HALF/ANGLO ARABIAN HUNTER PLEASURE OPEN' },
    { code: '1214', class: 'HALF/ANGLO ARABIAN HUNTER PLEASURE ADULT AMATEUR' },
    { code: '1403', class: 'HALF/ANGLO ARABIAN HUNTER PLEASURE JUNIOR EXHIBITOR' },
    { code: '1221', class: 'HALF/ANGLO ARABIAN WORKING WESTERN HORSE' },
    { code: '1223', class: 'HALF/ANGLO ARABIAN SPECIALTY HORSE' },
    { code: '1243', class: 'HALF/ANGLO ARABIAN SPORT HORSE IN-HAND' },
    { code: '1244', class: 'HALF/ANGLO ARABIAN SPORT HORSE UNDER SADDLE/SHOW HACK' },
    { code: '1208', class: 'HALF/ANGLO ARABIAN MISC' },
  ],
  'CARRIAGE DRIVING': [
    { code: '1603', class: 'SINGLE HORSE' },
    { code: '1604', class: 'PAIR HORSES' },
    { code: '1605', class: 'MULTIPLE HORSES' },
    { code: '1606', class: 'SINGLE PONY' },
    { code: '1607', class: 'PAIR PONIES' },
    { code: '1608', class: 'MULTIPLE PONIES' },
    { code: '1616', class: 'COACHING (HORSES OR PONIES)' },
    { code: '1617', class: 'DRIVEN DRESSAGE' },
    { code: '1619', class: 'DRIVEN DRESSAGE - TRAINING' },
    { code: '1620', class: 'DRIVEN DRESSAGE - PRELIMINARY' },
    { code: '1621', class: 'DRIVEN DRESSAGE - INTERMEDIATE' },
    { code: '1622', class: 'DRIVEN DRESSAGE - ADVANCED' },
    { code: '1623', class: 'PLEASURE DRV - TANDEM PONIES' },
    { code: '1624', class: 'PLEASURE DRV - TANDEM HORSES' },
    { code: '1618', class: 'MISCELLANEOUS' },
  ],
  CONNEMARA: [
    { code: '7003', class: 'PB CONNEMARA MARE' },
    { code: '7004', class: 'PB CONNEMARA STALLION OR GELDING' },
    { code: '7011', class: 'PB & HALF/BRED CONNEMARA HUNTER COMBINED' },
    { code: '7005', class: 'PB CONNEMARA HUNTER' },
    { code: '7008', class: 'HALF/BRED CONNEMARA HUNTER' },
    { code: '7012', class: 'PB & HALF/BRED CONNEMARA JUMPER COMBINED' },
    { code: '7006', class: 'PB CONNEMARA JUMPER' },
    { code: '7009', class: 'HALF/BRED CONNEMARA JUMPER' },
    { code: '7040', class: 'CONNEMARA MISCELLANEOUS COMBINED' },
    { code: '7020', class: 'PB CONNEMARA MISCELLANEOUS' },
    { code: '7030', class: 'HALF/BRED CONNEMARA MISCELLANEOUS' },
    { code: '7001', class: 'CONNEMARA - JUNIOR EQUITATION 17 & UNDER' },
    { code: '7002', class: 'CONNEMARA - ADULT AMATEUR EQUITATION 18 & OVER' },
  ],
  DRESSAGE: [
    { code: '1500', class: 'DRESSAGE - TOC' },
    { code: '1502', class: 'TRAINING LEVEL DRESSAGE' },
    { code: '1504', class: 'FIRST LEVEL DRESSAGE' },
    { code: '1541', class: 'FIRST LEVEL FREESTYLE' },
    { code: '1507', class: 'SECOND LEVEL DRESSAGE' },
    { code: '1538', class: 'SECOND LEVEL FREESTYLE' },
    { code: '1510', class: 'THIRD LEVEL DRESSAGE' },
    { code: '1539', class: 'THIRD LEVEL FREESTYLE' },
    { code: '1513', class: 'FOURTH LEVEL DRESSAGE' },
    { code: '1540', class: 'FOURTH LEVEL FREESTYLE' },
    { code: '1531', class: 'PRIX ST. GEORGE LEVEL' },
    { code: '1533', class: 'INTERMEDIATE I' },
    { code: '1534', class: 'INTERMEDIATE I FREESTYLE' },
    { code: '1535', class: 'INTERMEDIATE II' },
    { code: '9943', class: 'INTERMEDIATE - A' },
    { code: '9946', class: 'INTERMEDIATE - B' },
    { code: '1537', class: 'GRAND PRIX LEVEL' },
    { code: '1556', class: 'GRAND PRIX SPECIAL' },
    { code: '1526', class: 'GRAND PRIX FREESTYLE' },
    { code: '1557', class: 'CDI - GRAND PRIX OPEN' },
    { code: '1558', class: 'CDI - GRAND PRIX SPECIAL' },
    { code: '1559', class: 'CDI - GRAND PRIX FREESTYLE' },
    { code: '1551', class: 'FEI/USEF DRESSAGE TEST FOR 4 YEAR OLD HORSES' },
    { code: '1542', class: 'FEI DRESSAGE TEST FOR 5 YEAR OLD HORSES FINAL' },
    { code: '1543', class: 'FEI DRESSAGE TEST FOR 6 YEAR OLD HORSES FINAL' },
    { code: '1562', class: 'FEI DRESSAGE TEST FOR 7 YEAR OLD HORSES FINAL' },
    { code: '1554', class: "USEF YOUNG ADULT 'BRENTINA CUP'" },
    { code: '1555', class: 'FEI GRAND PRIX RIDERS AGES 16 - 25' },
    { code: '1596', class: 'DRESSAGE BREEDING-MATERIALE' },
    { code: '5815', class: 'EVENTING DRESSAGE' },
    { code: '5900', class: 'DRESSAGE SPORT HORSE BREEDING' },
    { code: '5901', class: 'YRLG DRESSAGE BREEDING' },
    { code: '5902', class: '2 YR OLD DRESSAGE BREEDING' },
    { code: '5903', class: '3 YR OLD DRESSAGE BREEDING' },
    { code: '5904', class: 'DSHB MATURE HORSE' },
    { code: '5905', class: 'DSHB YOUNG HORSE' },
    { code: '5906', class: 'DSHB 4-YEAR-OLD' },
    { code: '6900', class: 'DRESSAGE SEAT MEDAL' },
    { code: '1598', class: 'MISC DRESSAGE' },
    { code: '1593', class: 'PARA- EQUESTRIAN' },
    { code: '1573', class: 'FEI PONY TEAM TEST' },
    { code: '1574', class: 'FEI PONY INDIVIDUAL TEST' },
    { code: '1572', class: 'FEI JR. TEAM TEST' },
    { code: '1576', class: 'FEI JR. INDIVIDUAL TEST' },
    { code: '1577', class: 'FEI YR. TEAM TEST' },
    { code: '1578', class: 'FEI YR. INDIVIDUAL TEST' },
    { code: '1579', class: 'FEI CHILDREN TEAM TEST' },
    { code: '1580', class: 'FEI CHILDREN INDIVIDUAL TEST' },
    { code: '9980', class: 'FEI CHILDREN PRELIMINARY B TEST' },
    { code: '1584', class: 'USEF DEVELOPING HORSE PRIX ST. GEORGES TEST' },
    { code: '1585', class: 'USEF DEVELOPING HORSE GRAND PRIX TEST' },
    { code: '1581', class: 'FEI PRELIMINARY DRESSAGE TEST FOR 5 YEAR OLD HORSES' },
    { code: '1582', class: 'FEI PRELIMINARY DRESSAGE TEST FOR 6 YEAR OLD HORSES' },
    { code: '1583', class: 'FEI PRELIMINARY DRESSAGE TEST FOR 7 YEAR OLD HORSES' },
    { code: '1589', class: 'INTERMEDIATE A/B FREESTYLE' },
    { code: '1586', class: 'FEI PONY FREESTYLE' },
    { code: '1587', class: 'FEI JUNIOR FREESTYLE' },
    { code: '1588', class: 'FEI YOUNG RIDER FREESTYLE' },
  ],
  'PARA-EQUESTRIAN DRESSAGE': [
    { code: '1590', class: 'FEI PARA TEAM TEST' },
    { code: '1591', class: 'FEI PARA INDIVIDUAL TEST' },
    { code: '1592', class: 'FEI PARA FREESTYLE TEST' },
    { code: '1593', class: 'PARA-EQUESTRIAN DRESSAGE' },
    { code: '9960', class: 'FEI PARA DRESSAGE INTRODUCTORY TEST A' },
    { code: '9961', class: 'FEI PARA DRESSAGE INTRODUCTORY TEST B' },
    { code: '9962', class: 'PARA GRADE I' },
    { code: '9963', class: 'PARA GRADE II' },
    { code: '9964', class: 'PARA GRADE III' },
    { code: '9965', class: 'PARA GRADE IV' },
    { code: '9966', class: 'PARA GRADE V' },
  ],
  'COMBINED DRIVING': [
    { code: '1609', class: 'COMBINED DRIVING ADVANCED - SINGLE HORSE' },
    { code: '1610', class: 'COMBINED DRIVING ADVANCED - PAIR HORSES' },
    { code: '1611', class: 'COMBINED DRIVING ADVANCED - FOUR-IN-HAND HORSES' },
    { code: '1612', class: 'COMBINED DRIVING ADVANCED - SINGLE PONY' },
    { code: '1613', class: 'COMBINED DRIVING ADVANCED - PAIR PONY' },
    { code: '1614', class: 'COMBINED DRIVING ADVANCED - FOUR-IN-HAND PONIES' },
    { code: '1625', class: 'COMBINED DRIVING INTERMEDIATE - SINGLE HORSE' },
    { code: '1626', class: 'COMBINED DRIVING INTERMEDIATE - PAIR HORSES' },
    { code: '1627', class: 'COMBINED DRIVING INTERMEDIATE - MULTIPLE HORSE' },
    { code: '1628', class: 'COMBINED DRIVING INTERMEDIATE - SINGLE PONY' },
    { code: '1629', class: 'COMBINED DRIVING INTERMEDIATE - PAIR PONY' },
    { code: '1630', class: 'COMBINED DRIVING INTERMEDIATE - MULTIPLE PONY' },
    { code: '1631', class: 'COMBINED DRIVING PRELIMINARY - SINGLE HORSE' },
    { code: '1632', class: 'COMBINED DRIVING PRELIMINARY - PAIR HORSES' },
    { code: '1633', class: 'COMBINED DRIVING PRELIMINARY - MULTIPLE HORSE' },
    { code: '1634', class: 'COMBINED DRIVING PRELIMINARY - SINGLE PONY' },
    { code: '1635', class: 'COMBINED DRIVING PRELIMINARY - PAIR PONY' },
    { code: '1636', class: 'COMBINED DRIVING PRELIMINARY - MULTIPLE PONY' },
    { code: '1640', class: 'COMBINED DRIVING TRAINING - SINGLE HORSE' },
    { code: '1641', class: 'COMBINED DRIVING TRAINING - PAIR HORSE' },
    { code: '1642', class: 'COMBINED DRIVING TRAINING - FOUR-IN-HAND' },
    { code: '1643', class: 'COMBINED DRIVING TRAINING - SINGLE PONY' },
    { code: '1644', class: 'COMBINED DRIVING TRAINING - PAIR PONY' },
    { code: '1645', class: 'COMBINED DRIVING TRAINING - FOUR-IN-HAND PONY' },
    { code: '1615', class: 'MISC. DRIVING' },
    { code: '1646', class: 'COMBINED DRIVING - SHORT FORMAT TRAINING' },
    { code: '1653', class: 'COMBINED DRIVING - SHORT FORMAT PRELIMINARY' },
    { code: '1660', class: 'COMBINED DRIVING - SHORT FORMAT INTERMEDIATE' },
    { code: '1667', class: 'COMBINED DRIVING - SHORT FORMAT ADVANCED' },
    { code: '1675', class: 'COMBINED DRIVING - NOVICE ADVANCED - SINGLE HORSE' },
    { code: '1676', class: 'COMBINED DRIVING - NOVICE ADVANCED - PAIR HORSE' },
    { code: '1677', class: 'COMBINED DRIVING - NOVICE ADVANCED - FOUR-IN-HAND HORSE' },
    { code: '1678', class: 'COMBINED DRIVING - NOVICE ADVANCED - SINGLE PONY' },
    { code: '1679', class: 'COMBINED DRIVING - NOVICE ADVANCED - PAIR PONY' },
    { code: '1680', class: 'COMBINED DRIVING - NOVICE ADVANCED - FOUR-IN-HAND PONY' },
    { code: '1681', class: 'COMBINED DRIVING - SHORT FORMAT TRAINING - SMALL SINGLE PONY' },
    { code: '1682', class: 'COMBINED DRIVING - SHORT FORMAT TRAINING - SMALL PAIR PONY' },
    { code: '1683', class: 'COMBINED DRIVING - SHORT FORMAT TRAINING - SMALL FOUR-IN-HAND PONIES' },
    { code: '1684', class: 'COMBINED DRIVING - SHORT FORMAT PRELIMINARY - SMALL SINGLE PONY' },
    { code: '1685', class: 'COMBINED DRIVING - SHORT FORMAT PRELIMINARY - SMALL PAIR PONY' },
    {
      code: '1686',
      class: 'COMBINED DRIVING - SHORT FORMAT PRELIMINARY - SMALL FOUR-IN-HAND PONIES',
    },
    { code: '1687', class: 'COMBINED DRIVING - SHORT FORMAT INTERMEDIATE - SMALL SINGLE PONY' },
    { code: '1688', class: 'COMBINED DRIVING - SHORT FORMAT INTERMEDIATE - SMALL PAIR PONY' },
    {
      code: '1689',
      class: 'COMBINED DRIVING - SHORT FORMAT INTERMEDIATE - SMALL FOUR-IN-HAND PONIES',
    },
    { code: '1750', class: 'COMBINED DRIVING - BEGINNER' },
    { code: '1751', class: 'COMBINED DRIVING - BEGINNER - SINGLE HORSE' },
    { code: '1752', class: 'COMBINED DRIVING - BEGINNER - PAIR HORSE' },
    { code: '1753', class: 'COMBINED DRIVING - BEGINNER - FOUR-IN-HAND HORSE' },
    { code: '1754', class: 'COMBINED DRIVING - BEGINNER - SINGLE PONY' },
    { code: '1755', class: 'COMBINED DRIVING - BEGINNER - PAIR PONY' },
    { code: '1756', class: 'COMBINED DRIVING - BEGINNER - FOUR-IN-HAND PONY' },
    { code: '1757', class: 'COMBINED DRIVING - BEGINNER - SINGLE SMALL PONY' },
    { code: '1758', class: 'COMBINED DRIVING - BEGINNER - PAIR SMALL PONY' },
    { code: '1759', class: 'COMBINED DRIVING - BEGINNER - FOUR-IN-HAND SMALL PONY' },
    { code: '1760', class: 'COMBINED DRIVING - BEGINNER - SINGLE VSE' },
    { code: '1761', class: 'COMBINED DRIVING - BEGINNER - PAIR VSE' },
    { code: '1762', class: 'COMBINED DRIVING - BEGINNER - FOUR-IN-HAND VSE' },
    { code: '1763', class: 'COMBINED DRIVING - TRAINING - SINGLE SMALL PONY' },
    { code: '1764', class: 'COMBINED DRIVING - TRAINING - PAIR SMALL PONY' },
    { code: '1765', class: 'COMBINED DRIVING - TRAINING - FOUR-IN-HAND SMALL PONY' },
    { code: '1766', class: 'COMBINED DRIVING - TRAINING - SINGLE VSE' },
    { code: '1767', class: 'COMBINED DRIVING - TRAINING - PAIR VSE' },
    { code: '1768', class: 'COMBINED DRIVING - TRAINING - FOUR-IN-HAND VSE' },
    { code: '1769', class: 'COMBINED DRIVING - PRELIMINARY - SINGLE SMALL PONY' },
    { code: '1770', class: 'COMBINED DRIVING - PRELIMINARY - PAIR SMALL PONY' },
    { code: '1771', class: 'COMBINED DRIVING - PRELIMINARY - FOUR-IN-HAND SMALL PONY' },
    { code: '1772', class: 'COMBINED DRIVING - PRELIMINARY - SINGLE VSE' },
    { code: '1773', class: 'COMBINED DRIVING - PRELIMINARY - PAIR VSE' },
    { code: '1774', class: 'COMBINED DRIVING - PRELIMINARY - FOUR-IN-HAND VSE' },
    { code: '1775', class: 'COMBINED DRIVING - INTERMEDIATE - SINGLE SMALL PONY' },
    { code: '1776', class: 'COMBINED DRIVING - INTERMEDIATE - PAIR SMALL PONY' },
    { code: '1777', class: 'COMBINED DRIVING - INTERMEDIATE - FOUR-IN-HAND SMALL PONY' },
    { code: '1778', class: 'COMBINED DRIVING - INTERMEDIATE - SINGLE VSE' },
    { code: '1779', class: 'COMBINED DRIVING - INTERMEDIATE - PAIR VSE' },
    { code: '1780', class: 'COMBINED DRIVING - INTERMEDIATE - FOUR-IN-HAND VSE' },
    { code: '1781', class: 'COMBINED DRIVING - SHORT FORMAT - BEGINNER' },
    { code: '1782', class: 'COMBINED DRIVING - SHORT FORMAT - BEGINNER - VSE SINGLE' },
    { code: '1783', class: 'COMBINED DRIVING - SHORT FORMAT - BEGINNER - VSE PAIR' },
    { code: '1784', class: 'COMBINED DRIVING - SHORT FORMAT - BEGINNER - VSE FOUR-IN-HAND' },
    { code: '1785', class: 'COMBINED DRIVING - SHORT FORMAT - BEGINNER - SMALL PONY SINGLE' },
    { code: '1786', class: 'COMBINED DRIVING - SHORT FORMAT - BEGINNER - SMALL PONY PAIR' },
    { code: '1787', class: 'COMBINED DRIVING - SHORT FORMAT - BEGINNER - SMALL PONY FOUR-IN-HAND' },
    { code: '1788', class: 'COMBINED DRIVING - SHORT FORMAT - BEGINNER - PONY SINGLE' },
    { code: '1789', class: 'COMBINED DRIVING - SHORT FORMAT - BEGINNER - PONY PAIR' },
    { code: '1790', class: 'COMBINED DRIVING - SHORT FORMAT - BEGINNER - PONY FOUR-IN-HAND' },
    { code: '1791', class: 'COMBINED DRIVING - SHORT FORMAT - BEGINNER - HORSE SINGLE' },
    { code: '1792', class: 'COMBINED DRIVING - SHORT FORMAT - BEGINNER - HORSE PAIR' },
    { code: '1793', class: 'COMBINED DRIVING - SHORT FORMAT - BEGINNER - HORSE FOUR-IN-HAND' },
    { code: '1690', class: 'COMBINED DRIVING - SHORT FORMAT - TRAINING - SINGLE VSE' },
    { code: '1691', class: 'COMBINED DRIVING - SHORT FORMAT - TRAINING - PAIR VSE' },
    { code: '1692', class: 'COMBINED DRIVING - SHORT FORMAT - TRAINING - FOUR-IN-HAND VSE' },
    { code: '1693', class: 'COMBINED DRIVING - SHORT FORMAT - PRELIMINARY - SINGLE VSE' },
    { code: '1694', class: 'COMBINED DRIVING - SHORT FORMAT - PRELIMINARY - PAIR VSE' },
    { code: '1695', class: 'COMBINED DRIVING - SHORT FORMAT - PRELIMINARY - FOUR-IN-HAND VSE' },
    { code: '1696', class: 'COMBINED DRIVING - SHORT FORMAT - INTERMEDIATE - SINGLE VSE' },
    { code: '1697', class: 'COMBINED DRIVING - SHORT FORMAT - INTERMEDIATE - PAIR VSE' },
    { code: '1698', class: 'COMBINED DRIVING - SHORT FORMAT - INTERMEDIATE - FOUR-IN-HAND VSE' },
  ],
  ENDURANCE: [{ code: '1700', class: 'ENDURANCE' }],
  'ENGLISH PLEASURE': [
    { code: '9991', class: 'ENGLISH PLEASURE SADDLE SEAT' },
    { code: '9992', class: 'ENGLISH PLEASURE HUNTER SEAT' },
    { code: '9993', class: 'ENGLISH PLEASURE DRIVING' },
    { code: '9994', class: 'ENGLISH PLEASURE MISC.' },
  ],
  'FRIESIAN & PART FRIESIAN': [
    { code: '7101', class: 'FRIESIAN IN HAND' },
    { code: '7104', class: 'FRIESIAN WESTERN PLEASURE' },
    { code: '7106', class: 'FRIESIAN DRIVING' },
    { code: '7108', class: 'FRIESIAN DRESSAGE' },
    { code: '7110', class: 'FRIESIAN SPECIALTY HORSE' },
    { code: '7111', class: 'FRIESIAN HUNTER' },
    { code: '7112', class: 'FRIESIAN SADDLE SEAT' },
    { code: '7113', class: 'FRIESIAN JUNIOR EXHIBITOR' },
    { code: '7114', class: 'FRIESIAN MISC' },
    { code: '7201', class: 'PART BRED FRIESIAN IN HAND' },
    { code: '7204', class: 'PART BRED FRIESIAN WESTERN PLEASURE' },
    { code: '7206', class: 'PART BRED FRIESIAN DRIVING' },
    { code: '7208', class: 'PART BRED FRIESIAN DRESSAGE' },
    { code: '7210', class: 'PART BRED FRIESIAN SPECIALTY HORSE' },
    { code: '7211', class: 'PART BRED FRIESIAN HUNTER' },
    { code: '7212', class: 'PART BRED FRIESIAN SADDLE SEAT' },
    { code: '7213', class: 'PART BRED FRIESIAN JUNIOR EXHIBITOR' },
    { code: '7214', class: 'PART BRED FRIESIAN MISC' },
  ],
  HACKNEY: [
    { code: '1801', class: 'HACKNEY PONY OPEN' },
    { code: '1802', class: 'HACKNEY PONY AMATEUR' },
    { code: '1804', class: 'HARNESS PONY OPEN' },
    { code: '1805', class: 'HARNESS PONY AMATEUR' },
    { code: '1806', class: 'HACKNEY PONY PLEASURE DRIVING' },
    { code: '1811', class: 'HACKNEY PONY MISC.' },
    { code: '1820', class: 'HACKNEY HORSE' },
    { code: '1824', class: 'HACKNEY HORSE MISC.' },
    { code: '1807', class: 'HACKNEY ROADSTER PONY OPEN' },
    { code: '1808', class: 'HACKNEY ROADSTER PONY AMATEUR' },
    { code: '1809', class: 'HACKNEY ROADSTER PONY JUNIOR EXHIBITOR' },
  ],
  'HUNTER/JUMPER': [
    { code: '1900', class: 'HUNTER BREEDING' },
    { code: '1901', class: 'HUNTER BREEDING YEARLING' },
    { code: '1902', class: 'HUNTER BREEDING 2 YEAR OLD' },
    { code: '1903', class: 'HUNTER BREEDING 3 YEAR OLD' },
    { code: '1904', class: 'PONY HUNTER BREEDING YEARLING' },
    { code: '1905', class: 'PONY HUNTER BREEDING 2 YEAR OLD' },
    { code: '1906', class: 'PONY HUNTER BREEDING 3 YEAR OLD' },
    { code: '1938', class: 'PONY HUNTER BREEDING' },
    { code: '2001', class: 'HIGH PERFORMANCE HUNTER' },
    { code: '2002', class: 'PERFORMANCE WORKING - 3\'6" - 3\'9"' },
    { code: '2003', class: 'PERFORMANCE WORKING - 3\'3"' },
    { code: '2004', class: 'SMALL HUNTERS' },
    { code: '2005', class: 'PERFORMANCE HUNTER COMBINED' },
    { code: '2006', class: "HIGH PERFORMANCE HUNTER COMBINED WITH GREEN HUNTER 3'9/3'6" },
    { code: '2050', class: 'USHJA HUNTER - HEIGHTS COMBINED' },
    { code: '2051', class: 'USHJA HUNTER - 2\'0"' },
    { code: '2052', class: 'USHJA HUNTER - 2\'3"' },
    { code: '2053', class: 'USHJA HUNTER - 2\'6"' },
    { code: '2054', class: 'USHJA HUNTER - 2\'9"' },
    { code: '2055', class: 'USHJA HUNTER - 3\'0"' },
    { code: '2150', class: 'GREEN HUNTER - HEIGHTS COMBINED' },
    { code: '2151', class: 'GREEN HUNTER 3\'0"' },
    { code: '2152', class: 'GREEN HUNTER 3\'3"' },
    { code: '2153', class: 'GREEN HUNTER 3\'6"' },
    { code: '2154', class: 'GREEN HUNTER 3\'9"' },
    { code: '2180', class: 'YOUNG HUNTER - HEIGHTS COMBINED' },
    { code: '2181', class: "YOUNG HUNTER 5 & Under (2'9)" },
    { code: '2182', class: "YOUNG HUNTER 6 & Under (3')" },
    { code: '2183', class: "YOUNG HUNTER 7 & Under (3'3)" },
    { code: '2200', class: 'AMATEUR OWNER HUNTER 3\'6" - AGES COMBINED' },
    { code: '2201', class: "AMATEUR OWNER HUNTER 3'6 18-35 YEARS" },
    { code: '2202', class: "AMATEUR OWNER HUNTER 3'6 36+ YEARS" },
    { code: '2203', class: "AMATEUR OWNER HUNTER 3'3 - AGES COMBINED" },
    { code: '2204', class: "AMATEUR OWNER HUNTER 3'3 18-35 YEARS" },
    { code: '2205', class: "AMATEUR OWNER HUNTER 3'3 36+ YEARS" },
    { code: '2300', class: 'CONFORMATION HUNTER - COMBINED' },
    { code: '2301', class: 'HIGH PERFORMANCE CONFORMATION HUNTER' },
    { code: '2302', class: 'GREEN CONFORMATION HUNTER' },
    { code: '2400', class: "JUNIOR HUNTER 3'6 - COMBINED AGES" },
    { code: '2401', class: "JUNIOR HUNTER 3'6 - SMALL 15 & UNDER" },
    { code: '2402', class: "JUNIOR HUNTER 3'6 - LARGE 15 & UNDER" },
    { code: '2403', class: "JUNIOR HUNTER 3'6 - SMALL 16-17" },
    { code: '2404', class: "JUNIOR HUNTER 3'6 - LARGE 16-17" },
    { code: '2410', class: 'JUNIOR HUNTER SMALL - COMBINED AGES' },
    { code: '2411', class: 'JUNIOR HUNTER LARGE - COMBINED AGES' },
    { code: '2412', class: "SMALL JUNIOR HUNTER 3'3 - COMBINED AGES" },
    { code: '2413', class: "LARGE JUNIOR HUNTER 3'3 - COMBINED AGES" },
    { code: '2420', class: "JUNIOR HUNTER 3'3 COMBINED AGES" },
    { code: '2421', class: "JUNIOR HUNTER 3'3 - SMALL 15/U" },
    { code: '2422', class: "JUNIOR HUNTER 3'3 - LARGE 15/U" },
    { code: '2423', class: "JUNIOR HUNTER 3'3 - SMALL 16-17" },
    { code: '2424', class: "JUNIOR HUNTER 3'3 - LARGE 16-17" },
    { code: '2500', class: 'PONY HUNTER - COMBINED' },
    { code: '2501', class: 'PONY HUNTER - SMALL' },
    { code: '2502', class: 'PONY HUNTER - MEDIUM' },
    { code: '2503', class: 'PONY HUNTER - LARGE' },
    { code: '2600', class: 'GREEN PONY HUNTER - COMBINED' },
    { code: '2601', class: 'GREEN PONY HUNTER - SMALL/MEDIUM' },
    { code: '2602', class: 'GREEN PONY HUNTER - LARGE' },
    { code: '2603', class: 'GREEN PONY HUNTER - MEDIUM' },
    { code: '2604', class: 'GREEN PONY HUNTER - SMALL' },
    { code: '2700', class: 'CHILDRENS HUNTER - COMBINED' },
    { code: '2701', class: 'CHILDRENS HUNTER - PONY' },
    { code: '2702', class: 'CHILDRENS HUNTER - HORSE' },
    { code: '2716', class: "LOW CHILDREN'S HUNTER 2'6" },
    { code: '2717', class: "LOW CHILDREN'S PONY HUNTER" },
    { code: '2718', class: "LOW CHILDREN'S HUNTER HORSE/PONY COMBINED" },
    { code: '2800', class: 'ADULT AMATEUR HUNTER - COMBINED' },
    { code: '2801', class: 'ADULT AMATEUR HUNTER 18-35 YEARS' },
    { code: '2802', class: 'ADULT AMATEUR HUNTER 36+ YEARS' },
    { code: '2803', class: 'ADULT AMATEUR HUNTER 36-49' },
    { code: '2804', class: 'ADULT AMATEUR HUNTER 50+ YEARS' },
    { code: '2805', class: "LOW ADULT HUNTER 2'6" },
    { code: '9302', class: "LOW CHILDREN'S/ADULT HUNTER COMBINED" },
    { code: '2900', class: 'LADIES SIDE SADDLE' },
    { code: '3000', class: 'JUNIOR HUNTER CLASSIC & JUNIOR/AMATEUR OWNER CLASSIC COMBINED' },
    { code: '3010', class: 'PONY HUNTER CLASSIC' },
    { code: '3011', class: 'GREEN PONY CLASSIC' },
    { code: '3020', class: 'AMATEUR OWNER HUNTER CLASSIC' },
    { code: '3050', class: 'OPEN HUNTER CLASSIC (ELIGIBLE SECTIONS AS STATED IN THE PRIZE LIST)' },
    { code: '3030', class: 'USHJA INTERNATIONAL HUNTER DERBY' },
    { code: '3040', class: 'USHJA NATIONAL HUNTER DERBY' },
    { code: '3070', class: 'USHJA PONY HUNTER DERBY' },
    { code: '3080', class: 'USHJA HUNTER TEAM CHALLENGE' },
    { code: '3102', class: 'HUNTER EQUITATION 14 & UNDER' },
    { code: '3103', class: 'HUNTER EQUITATION 15-17' },
    { code: '3116', class: 'HUNTER EQUITATION 11 & UNDER' },
    { code: '3117', class: 'HUNTER EQUITATION 12-14' },
    { code: '3104', class: 'COMBINED HUNTER EQUITATION 17/U' },
    { code: '3105', class: 'ADULT EQUITATION 18-35' },
    { code: '3106', class: 'ADULT EQUITATION 36+' },
    { code: '3112', class: 'ADULT EQUITATION 36-49' },
    { code: '3107', class: 'ADULT EQUITATION 50+' },
    { code: '3108', class: 'COMBINED ADULT EQUITATION 18+' },
    { code: '3110', class: 'COMBINED CHILD/ADULT EQUITATION' },
    { code: '3199', class: 'MISCELLANEOUS HUNTER EQUITATION' },
    { code: '3109', class: "THOROUGHBRED HUNTER 3'" },
    { code: '3100', class: 'MISCELLANEOUS HUNTER (No points earned)' },
    { code: '9995', class: 'HUNTER BONUS MONEY' },
    { code: '9997', class: 'HUNTER IN-KIND GIFTS' },
    { code: '3111', class: 'THOROUGHBRED JUMPER' },
    { code: '3301', class: 'JUNIOR JUMPER (Junior/All heights)' },
    { code: '3321', class: 'AMATEUR JUMPER (all heights)' },
    { code: '3350', class: 'JUNIOR/AMATEUR (combined ages/heights)' },
    { code: '3325', class: '1.40/1.45M JUNIOR/AMATEUR JUMPER' },
    { code: '3326', class: '1.40/1.45M JUNIOR JUMPER' },
    { code: '3327', class: '1.40/1.45M AMATEUR JUMPER' },
    { code: '3328', class: '1.30/1.35M JUNIOR/AMATEUR JUMPER' },
    { code: '3329', class: '1.30/1.35M JUNIOR JUMPER' },
    { code: '3330', class: '1.30/1.35M AMATEUR JUMPER' },
    { code: '3331', class: '1.20/1.25M JUNIOR/AMATEUR JUMPER' },
    { code: '3332', class: '1.20/1.25M JUNIOR JUMPER' },
    { code: '3333', class: '1.20/1.25M AMATEUR JUMPER' },
    { code: '3334', class: '1.10/1.15M JUNIOR/AMATEUR JUMPER' },
    { code: '3335', class: '1.10/1.15M JUNIOR JUMPER' },
    { code: '3336', class: '1.10/1.15M AMATEUR JUMPER' },
    { code: '3337', class: '1.00/1.05M AND BELOW JUNIOR/AMATEUR JUMPER' },
    { code: '3338', class: '1.00/1.05M AND BELOW JUNIOR JUMPER' },
    { code: '3339', class: '1.00/1.05M AND BELOW AMATEUR JUMPER' },
    { code: '3400', class: 'Y/R JUMPER' },
    { code: '3425', class: 'U25 JUMPER' },
    { code: '3700', class: '$25,000 PLUS (GRAND PRIX)' },
    { code: '3800', class: 'MISC JUMPER' },
    { code: '9996', class: 'JUMPER BONUS MONEY' },
    { code: '9998', class: 'JUMPER IN-KIND GIFTS' },
    { code: '6050', class: 'USEF/NCEA JUNIOR HUNTER SEAT MEDAL' },
    { code: '6100', class: 'DOVER SADDLERY/USEF HUNTER SEAT MEDAL' },
    { code: '6110', class: 'PLATINUM PERFORMANCE/USEF SHOW JUMPING TALENT SEARCH' },
    { code: '6200', class: 'MARSHALL & STERLING/USEF PONY MEDAL' },
    { code: '6300', class: 'USEF ADULT EQUITATION MEDAL' },
    { code: '6349', class: 'USHJA 3\'3" JUMPER SEAT MEDAL (COMBINED)' },
    { code: '6350', class: 'USHJA 3\'3" JUNIOR JUMPER SEAT MEDAL' },
    { code: '6355', class: 'USHJA 3\'3" ADULT JUMPER SEAT MEDAL' },
    { code: '6360', class: 'USHJA HUNTER SEAT MEDAL' },
    { code: '9300', class: "JUNIOR/AMATEUR OWNER HUNTER 3'6 COMBINED" },
    { code: '9301', class: 'CHILDREN/ADULT AMATEUR HUNTER' },
    { code: '9303', class: "JUNIOR/AMATEUR OWNER 3'3 COMBINED" },
    { code: '9400', class: 'YOUNG JUMPERS COMBINED' },
    { code: '9401', class: '5-YEAR-OLD YOUNG JUMPERS' },
    { code: '9402', class: '6-YEAR-OLD YOUNG JUMPERS' },
    { code: '9403', class: '7-YEAR-OLD YOUNG JUMPER' },
    { code: '9500', class: 'PONY JUMPERS' },
    { code: '12001', class: 'USHJA GREEN HUNTER CHALLENGE' },
    { code: '12000', class: 'USHJA GREEN INCENTIVE - COMBINED' },
    { code: '12010', class: "USHJA GREEN INCENTIVE 3'/3'3\"" },
    { code: '12020', class: "USHJA GREEN INCENTIVE 3'6/3'9\"" },
    { code: '14000', class: 'USHJA JUMPER CLASSIC SERIES' },
  ],
  MORGAN: [
    { code: '3901', class: 'MORGAN ENGLISH PLEASURE OPEN' },
    { code: '3912', class: 'MORGAN ENGLISH PLEASURE AMATEUR' },
    { code: '3906', class: 'MORGAN ENGLISH PLEASURE JUNIOR EXHIBITOR' },
    { code: '3902', class: 'MORGAN WESTERN PLEASURE OPEN' },
    { code: '3913', class: 'MORGAN WESTERN PLEASURE AMATEUR' },
    { code: '3907', class: 'MORGAN WESTERN PLEASURE JUNIOR EXHIBITOR' },
    { code: '3903', class: 'MORGAN PLEASURE DRIVING OPEN' },
    { code: '3914', class: 'MORGAN PLEASURE DRIVING AMATEUR' },
    { code: '3943', class: 'MORGAN PLEASURE DRIVING - JUNIOR EXHIBITOR' },
    { code: '3904', class: 'MORGAN PARK SADDLE' },
    { code: '3905', class: 'MORGAN PARK HARNESS' },
    { code: '3909', class: 'MORGAN HUNTER PLEASURE OPEN' },
    { code: '3938', class: 'MORGAN HUNTER PLEASURE AMATEUR' },
    { code: '3934', class: 'MORGAN HUNTER PLEASURE JUNIOR EXHIBITOR' },
    { code: '3925', class: 'MORGAN CLASSIC PLEASURE SADDLE OPEN' },
    { code: '3927', class: 'MORGAN CLASSIC PLEASURE DRIVING OPEN' },
    { code: '3932', class: 'MORGAN DRESSAGE TRAINING LEVEL' },
    { code: '3933', class: 'MORGAN DRESSAGE FIRST LEVEL' },
    { code: '3939', class: 'MORGAN DRESSAGE SECOND LEVEL & ABOVE' },
    { code: '3936', class: 'MORGAN IN-HAND' },
    { code: '3937', class: 'MORGAN CARRIAGE' },
    { code: '3940', class: 'MORGAN ROAD HACK' },
    { code: '3941', class: 'MORGAN VERSATILITY' },
    { code: '3910', class: 'MORGAN MISC' },
    { code: '3160', class: 'MORGAN SADDLE SEAT EQUITATION JR. EX' },
    { code: '3161', class: 'MORGAN HUNT SEAT ON THE FLAT EQUITATION JR. EX' },
    { code: '3162', class: 'MORGAN HUNT SEAT OVER FENCES EQUITATION JR. EX' },
    { code: '3163', class: 'MORGAN DRESSAGE SEAT EQUITATION JR. EX' },
    { code: '3164', class: 'MORGAN WESTERN SEAT EXHIBITOR JR. EX' },
    { code: '3165', class: 'MORGAN REINING SEAT EQUITATION JR. EX' },
    { code: '3166', class: 'MORGAN WALK/TROT COMBINED EQUITATION 12 & UNDER' },
    { code: '3167', class: 'MORGAN ADULT RIDER COMBINED EQUITATION 18 & OVER' },
    { code: '3168', class: 'MORGAN COLLEGIATE COMBINED EQUITATION 18-22' },
    { code: '3944', class: 'MORGAN WESTERN DRESSAGE' },
  ],
  'NATIONAL SHOW HORSE': [
    { code: '4001', class: 'NSH ENGLISH PLEASURE' },
    { code: '4002', class: 'NSH 3-GAITED' },
    { code: '4003', class: 'NSH 5-GAITED' },
    { code: '4004', class: 'NSH PLEASURE DRIVING' },
    { code: '4005', class: 'NSH FINE HARNESS' },
    { code: '4006', class: 'NSH COUNTRY PLEASURE/COUNTRY PLEASURE DRIVING' },
    { code: '4008', class: 'NATIONAL SHOW HORSE HUNTER PLEASURE' },
    { code: '4009', class: 'NSH SHOW HACK' },
    { code: '4010', class: 'NSH WESTERN PLEASURE' },
    { code: '4007', class: 'NSH MISCELLANEOUS' },
    { code: '3170', class: 'NSH SADDLE SEAT EQUITATION 17 & UNDER' },
    { code: '3171', class: 'NSH FIVE GAITED HORSEMANSHIP' },
    { code: '3172', class: 'NSH SADDLE SEAT EQUITATION 18 & OLDER (AMATEUR)' },
    { code: '3173', class: 'NSH WALK-TROT 10 & UNDER' },
  ],
  PARADE: [{ code: '4100', class: 'PARADE' }],
  'PASO FINO': [
    { code: '4202', class: 'PASO FINO - FINO' },
    { code: '4203', class: 'PASO FINO - PERFORMANCE' },
    { code: '4204', class: 'PASO FINO - PLEASURE' },
    { code: '4205', class: 'PASO FINO - SPECIALTY' },
    { code: '4211', class: 'PASO FINO - AMATEUR' },
    { code: '4207', class: 'PASO FINO - JUNIOR EXHIBITOR' },
    { code: '4208', class: 'PASO FINO - SUB-JUNIOR' },
    { code: '4209', class: 'PASO FINO - YOUTH (COMBINED JUNIOR EXHIBITOR AND SUB-JUNIOR)' },
    { code: '4210', class: 'PASO FINO - BELLA FORMA' },
    { code: '4201', class: 'PASO FINO MISC' },
    { code: '3155', class: 'PASO FINO YOUTH EQUITATION 17 & UNDER' },
    { code: '3156', class: 'PASO FINO ADULT EQUITATION 18 & OVER' },
  ],
  EVENTING: [
    { code: '5801', class: 'NOVICE LEVEL' },
    { code: '5802', class: 'TRAINING LEVEL' },
    { code: '5803', class: 'PRELIMINARY LEVEL' },
    { code: '5804', class: 'INTERMEDIATE LEVEL' },
    { code: '5805', class: 'ADVANCED LEVEL' },
    { code: '5806', class: 'JR/YR NOVICE LEVEL' },
    { code: '5807', class: 'JR/YR TRAINING LEVEL' },
    { code: '5808', class: 'JR/YR PRELIMINARY LEVEL' },
    { code: '5809', class: 'JR/YR INTERMEDIATE LEVEL' },
    { code: '5810', class: 'JR/YR ADVANCED LEVEL' },
    { code: '5811', class: 'EVENTING MISC' },
    { code: '5812', class: 'BEGINNER NOVICE LEVEL' },
    { code: '5826', class: 'CCI1' },
    { code: '5825', class: 'CCI2-S' },
    { code: '5824', class: 'CCI2-L' },
    { code: '5823', class: 'CCI3-S' },
    { code: '5822', class: 'CCI3-L' },
    { code: '5821', class: 'CCI4-S' },
    { code: '5820', class: 'CCI4-L' },
    { code: '5819', class: 'CCI5-L' },
  ],
  REINING: [
    { code: '5401', class: 'REINING OPEN' },
    { code: '5402', class: 'REINING ADULT AMATEUR' },
    { code: '5403', class: 'REINING JUNIOR EXHIBITOR' },
    { code: '5404', class: 'YOUTH REINING QUALIFIER' },
    { code: '5405', class: 'OPEN FINAL QUALIFIER - REINING' },
    { code: '5406', class: 'YOUTH REINING QUALIFIER 13 & UNDER' },
    { code: '5407', class: 'YOUTH REINING QUALIFIER 14-18' },
    { code: '5408', class: 'YOUTH REINING QUALIFIER 19-21' },
    { code: '5409', class: 'REINING MISC' },
  ],
  ROADSTER: [
    { code: '4301', class: 'ROADSTER HORSE UNDER SADDLE' },
    { code: '4302', class: 'ROADSTER HORSE TO BIKE' },
    { code: '4303', class: 'ROADSTER HORSE MISC.' },
    { code: '4401', class: 'ROADSTER PONY OPEN' },
    { code: '4402', class: 'ROADSTER PONY AMATEUR' },
    { code: '4403', class: 'ROADSTER PONY JUNIOR EXHIBITOR' },
    { code: '4404', class: 'ROADSTER PONY MISC.' },
  ],
  'OPEN SADDLESEAT EQUITATION': [
    { code: '3130', class: 'OPEN SADDLE SEAT EQUITATION 17 & UNDER' },
    { code: '3131', class: 'OPEN SADDLE SEAT PLEASURE EQUITATION 17 & UNDER' },
    { code: '3132', class: 'OPEN SADDLE SEAT EQUITATION ADULT 18 & OVER' },
    { code: '3133', class: 'OPEN SADDLE SEAT EQUITATION WALK/TROT 10 & UNDER' },
    { code: '6400', class: 'SADDLE SEAT EQUITATION MISC.' },
    { code: '6500', class: 'USEF SADDLE SEAT EQUITATION MEDAL 17 & UNDER' },
    { code: '6800', class: 'USEF ADULT AMATEUR SADDLE SEAT EQUITATION MEDAL 18 & OVER' },
  ],
  SHETLAND: [
    { code: '5000', class: 'SHETLAND PONY' },
    { code: '5001', class: 'SHETLAND HARNESS' },
    { code: '5002', class: 'SHETLAND ROADSTER' },
    { code: '5004', class: 'SHETLAND PLEASURE DRIVING' },
    { code: '5003', class: 'SHETLAND MISC.' },
  ],
  TRAIL: [
    { code: '5501', class: 'TRAIL OPEN' },
    { code: '5502', class: 'TRAIL ADULT AMATEUR' },
    { code: '5503', class: 'TRAIL JUNIOR EXHIBITOR' },
  ],
  VAULTING: [{ code: '5100', class: 'VAULTING' }],
  'WELSH & HALF WELSH': [
    { code: '5200', class: 'WELSH PLEASURE PONY COMBINED' },
    { code: '5220', class: 'WELSH ENGLISH PLEASURE SECTIONS A&B' },
    { code: '5221', class: 'WELSH ENGLISH PLEASURE SECTION B' },
    { code: '5222', class: 'WELSH ENGLISH PLEASURE SECTIONS C&D' },
    { code: '5223', class: 'WELSH ENGLISH PLEASURE SECTIONS A&B - ADULT TO RIDE' },
    { code: '5213', class: 'WELSH PLEASURE DRIVING A & B' },
    { code: '5204', class: 'WELSH PONY HUNTER COMBINED (JUNIOR RIDER)' },
    { code: '5214', class: 'WELSH HUNTER SECTION A & B' },
    { code: '5215', class: 'WELSH HUNTER SECTION B' },
    { code: '5216', class: 'WELSH HUNTER SECTIONS C & D, INCLUDES MODEL CLASSES' },
    { code: '5217', class: 'WELSH HUNTER SECTIONS A & B - ADULT TO RIDE, INCLUDES MODEL CLASSES' },
    { code: '5250', class: 'HALF WELSH ENGLISH PLEASURE' },
    { code: '5251', class: 'HALF WELSH HUNTER, INCLUDES MODEL CLASSES' },
    { code: '5252', class: 'WELSH WESTERN PLEASURE' },
    { code: '5208', class: 'WELSH MISC' },
  ],
  'WESTERN DRESSAGE': [
    { code: '7501', class: 'WESTERN DRESSAGE INTRODUCTORY' },
    { code: '7502', class: 'WESTERN DRESSAGE BASIC' },
    { code: '7503', class: 'WESTERN DRESSAGE LEVEL 1' },
    { code: '7504', class: 'WESTERN DRESSAGE LEVEL 2' },
    { code: '7505', class: 'WESTERN DRESSAGE LEVEL 3' },
    { code: '7506', class: 'WESTERN DRESSAGE LEVEL 4' },
    { code: '7540', class: 'WESTERN DRESSAGE LEVEL 5' },
    { code: '7507', class: 'WESTERN DRESSAGE SUITABILITY OPEN' },
    { code: '7508', class: 'WESTERN DRESSAGE HACK OPEN' },
    { code: '7509', class: 'WESTERN DRESSAGE SEAT HORSEMANSHIP OPEN' },
    { code: '7511', class: 'WESTERN DRESSAGE SEAT ON THE RAIL OPEN' },
    { code: '7520', class: 'WESTERN DRESSAGE INTRODUCTORY AMATEUR' },
    { code: '7521', class: 'WESTERN DRESSAGE BASIC AMATEUR' },
    { code: '7522', class: 'WESTERN DRESSAGE LEVEL 1 AMATEUR' },
    { code: '7523', class: 'WESTERN DRESSAGE LEVEL 2 AMATEUR' },
    { code: '7524', class: 'WESTERN DRESSAGE LEVEL 3 AMATEUR' },
    { code: '7525', class: 'WESTERN DRESSAGE LEVEL 4 AMATEUR' },
    { code: '7541', class: 'WESTERN DRESSAGE LEVEL 5 AMATEUR' },
    { code: '7526', class: 'WESTERN DRESSAGE SUITABILITY AMATEUR' },
    { code: '7527', class: 'WESTERN DRESSAGE HACK AMATEUR' },
    { code: '7528', class: 'WESTERN DRESSAGE SEAT HORSEMANSHIP AMATEUR' },
    { code: '7529', class: 'WESTERN DRESSAGE SEAT ON THE RAIL AMATEUR' },
    { code: '7530', class: 'WESTERN DRESSAGE INTRODUCTORY JUNIOR' },
    { code: '7531', class: 'WESTERN DRESSAGE BASIC JUNIOR' },
    { code: '7532', class: 'WESTERN DRESSAGE LEVEL 1 JUNIOR' },
    { code: '7533', class: 'WESTERN DRESSAGE LEVEL 2 JUNIOR' },
    { code: '7534', class: 'WESTERN DRESSAGE LEVEL 3 JUNIOR' },
    { code: '7535', class: 'WESTERN DRESSAGE LEVEL 4 JUNIOR' },
    { code: '7542', class: 'WESTERN DRESSAGE LEVEL 5 JUNIOR' },
    { code: '7536', class: 'WESTERN DRESSAGE SUITABILITY JUNIOR' },
    { code: '7537', class: 'WESTERN HACK JUNIOR' },
    { code: '7538', class: 'WESTERN DRESSAGE SEAT HORSEMANSHIP JUNIOR' },
    { code: '7539', class: 'WESTERN DRESSAGE SEAT ON THE RAIL JUNIOR' },
  ],
  'OPEN WESTERN': [
    { code: '5601', class: 'WESTERN PLEASURE OPEN' },
    { code: '5602', class: 'WESTERN PLEASURE ADULT AMATEUR' },
    { code: '5603', class: 'WESTERN PLEASURE JUNIOR EXHIBITOR' },
    { code: '5605', class: 'RANCH' },
    { code: '6750', class: 'WESTERN EQUITATION' },
    { code: '6751', class: 'USEF JUNIOR WESTERN EQUITATION MEDAL' },
    { code: '6752', class: 'USEF ADULT AMATEUR WESTERN EQUITATION MEDAL' },
    { code: '3150', class: 'OPEN WESTERN SEAT EQUITATION 17 & UNDER' },
    { code: '3151', class: 'OPEN HORSEMANSHIP 17 & UNDER' },
    { code: '5700', class: 'WESTERN MISC.' },
  ],
  MISC: [
    { code: '5702', class: 'OPPORTUNITY CLASS' },
    { code: '9950', class: 'Non-member exempted classes' },
    { code: '9951', class: 'Walk/Trot classes (exempt non-member fee)' },
    { code: '3950', class: 'MISC. OPEN MULTI-BREED CLASSES' },
    { code: '3199', class: 'MISCELLANEOUS EQUITATION (Hunt, Dressage, Western, Reining)' },
  ],
}
