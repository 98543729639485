import * as yup from 'yup'

export const userSignupSchema = yup
  .object({
    username: yup
      .string()
      .required('Please enter your username')
      .test('no-spaces', 'username should not contain spaces', (value) => {
        if (value) return !/\s/.test(value)
        return true
      })
      .test('no-uppercases', 'username should not contain capital letters', (value) => {
        if (value) return !/[A-Z]/.test(value)
        return true
      }),
    fullname: yup.string().required(),
    email: yup
      .string()
      .email('Please enter the correct email format')
      .required('Please enter your email address.'),
    newPassword: yup
      .string()
      .required('Please enter password')
      .min(6, 'Password length should be at least 6 characters')
      .max(20, 'Password cannot exceed more than 20 characters')
      .trim()
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])/,
        'Password should include atleast 1 upper & lowercase letter, 1 numeric, special character'
      ),
    c_password: yup
      .string()
      .required('Please enter confirm password')
      .oneOf([yup.ref('newPassword')], 'Passwords do not match'),
  })
  .required()

export const vendorSignupSchema = yup
  .object({
    userName: yup.string().required('Please enter your full name.'),
    vendorname: yup
      .string()
      .required('Please enter your username')
      .test('no-spaces', 'vendorname should not contain spaces', (value) => {
        if (value) return !/\s/.test(value)
        return true
      })
      .test('no-uppercases', 'vendorname should not contain capital letters', (value) => {
        if (value) return !/[A-Z]/.test(value)
        return true
      }),
    userCategory: yup.string().required('Please choose your business category.'),
    userAddress: yup.string().required('Please enter your location.'),
    userPhoneNumber: yup.string().required(),
    website: yup
      .string()
      .required('Please enter a website link.')
      .url('Please enter a valid website link'),
    email: yup
      .string()
      .email('Please enter the correct email format')
      .required('Please enter your email address.'),
    newPassword: yup
      .string()
      .required('Please enter your password')
      .min(6, 'Password length should be at least 6 characters')
      .max(20, 'Password cannot exceed more than 20 characters')
      .trim()
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])/,
        'Password should include atleast 1 upper & lowercase letter, 1 numeric, special character'
      ),
    c_password: yup
      .string()
      .required('Please enter your confirm passwordrd')
      .oneOf([yup.ref('newPassword')], 'Both passwords should be same'),
  })
  .required()

export const vendorProfileSchema = yup
  .object({
    userBusinessName: yup.string().required(),
  })
  .required()

export const sendEmailToSponsorVendorSchema = yup.object().shape({
  email: yup.array().of(
    yup.object().shape({
      value: yup.string().email().required("Please enter reciever's email address"),
    })
  ),
})

export const competitorProfileAccountSchema = yup.object({
  userEmail: yup.string().email('Please provide a valid email').required(),
  userAccountNumber: yup.string(),
  newMessages: yup.bool(),
  weatherUpdates: yup.bool(),
  resultUpdates: yup.bool(),
  courseUpdates: yup.bool(),
  paymentUpdates: yup.bool(),
})

export const profileEmailUpdateAccountSchema = yup.object({
  userEmail: yup.string().email('Please provide a valid email').required('User email is required.'),
  // updateUserEmail: yup.string().email('Please provide a valid email').required('To proceed, please enter email & password.'),
  password: yup
    .string()
    .required('To change email, please enter your current password.')
    .min(6, 'Password length should be at least 6 characters')
    .max(20, 'Password cannot exceed more than 20 characters')
    .trim()
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])/,
      'Password should include atleast 1 upper & lowercase letter, 1 numeric, special character'
    ),
})

export const competitorPasswordUpdateSchema = yup.object({
  oldPassword: yup
    .string()
    .min(6, 'Password length should be at least 6 characters')
    .max(20, 'Password cannot exceed more than 20 characters')
    .trim()
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])/,
      'Password should include atleast 1 upper & lowercase letter, 1 numeric, special character'
    ),
  newPassword: yup
    .string()
    .min(6, 'Password length should be at least 6 characters')
    .max(20, 'Password cannot exceed more than 20 characters')
    .trim()
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])/,
      'Password should include atleast 1 upper & lowercase letter, 1 numeric, special character'
    ),
  cPassword: yup
    .string()
    .min(6, 'Password length should be at least 6 characters')
    .max(20, 'Password cannot exceed more than 20 characters')
    .oneOf([yup.ref('newPassword')], 'Passwords do not match'),
})

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const competitorProfileDetailSchema = yup.object({
  userDOB: yup.string().required('Date of birth is Required'),
  userFirstName: yup.string().required('Please provide a valid name'),
  userLastName: yup.string(),
  userEmail: yup
    .string()
    .email('Please enter the correct email format')
    .required('Please provide a valid email'),
  userAddress: yup.string().required('Please enter your address'),
  userPhoneNumber: yup.string().required('Please enter your phone number'),
  userExtraDocument: yup.array(
    yup.object().shape({
      endDate: yup.date().required(),
      documentNumber: yup.string().required(),
      documentFullName: yup.string().required(),
    })
  ),
})

export const OrganizerProfileDetailSchema = yup.object({
  userName: yup.string().required('Please provide a valid name'),
  userSummary: yup.string().required('Please provide a user Summary'),
  userEmail: yup
    .string()
    .email('Please enter the correct email format')
    .required('Please provide a valid email'),
  userAddress: yup.string().required('Please enter your address'),
  userPhoneNumber: yup.string().required('Please enter your phone number'),
  userFacebook: yup.string().url('Please enter a valid URL'),
  userInstagram: yup.string().url('Please enter a valid URL'),
  userTwitter: yup.string().url('Please enter a valid URL'),
  userLinkedin: yup.string().url('Please enter a valid URL'),
  userExtraDocument: yup.array(
    yup.object().shape({
      endDate: yup.date().required(),
      documentNumber: yup.string().required(),
      documentFullName: yup.string().required(),
    })
  ),
})

export const horseSchema = yup.object().shape({
  horseName: yup.string().required('Please provide a valid horse name.'),
  horseDob: yup.date(), //"Please provide a valid date"
})
