// ############################################################
/**
 * @todo Document this
 */
// ############################################################

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */
export class EventPrizeListFormBuilderUtilEvaluationHunterStandardSectionMainCheckboxHelper {
  public field?: any
  public parentField?: any
  public rootField?: any
  public previousValue?: boolean
  public newValue?: boolean
  public setValue?: any
  public getValues?: any
  public setError?: any
  public clearErrors?: any
  public trigger?: any
  public disciplineIndex?: number
  public divisionIndex?: number
  public sectionIndex?: number
  public subSectionIndex?: number

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: any) {
    this.field = obj?.field ?? null
    this.parentField = obj?.parentField ?? null
    this.rootField = obj?.rootField ?? null
    this.previousValue = obj?.previousValue ?? null
    this.newValue = obj?.newValue ?? null
    this.setValue = obj?.setValue ?? null
    this.getValues = obj?.getValues ?? null
    this.setError = obj?.setError ?? null
    this.clearErrors = obj?.clearErrors ?? null
    this.trigger = obj?.trigger ?? null
    this.disciplineIndex = obj?.disciplineIndex ?? null
    this.divisionIndex = obj?.divisionIndex ?? null
    this.sectionIndex = obj?.sectionIndex ?? null
    this.subSectionIndex = obj?.subSectionIndex ?? null
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public deactivateMainSection() {
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionUnderSaddleClass.meta.enabled`,
      false
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionUnderSaddleClass.value`,
      ''
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionHandyClass.meta.enabled`,
      false
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionHandyClass.value`,
      ''
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionConformationClass.meta.enabled`,
      false
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionConformationClass.value`,
      ''
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionHeight.meta.enabled`,
      false
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionHeight.value`,
      ''
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionTotalPriceMoney.meta.enabled`,
      false
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionTotalPriceMoney.value`,
      ''
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionJudge.meta.enabled`,
      false
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionJudge.value`,
      ''
    )

    this.parentField?.subSections?.forEach((value: any, index: any) => {
      this.setValue(
        `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.subSections.${index}.subSectionSubCode.meta.selected`,
        false
      )
      this.setValue(
        `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.subSections.${index}.subSectionUnderSaddleClass.meta.selected`,
        false
      )
      this.setValue(
        `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.subSections.${index}.subSectionHandyClass.meta.selected`,
        false
      )
      this.setValue(
        `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.subSections.${index}.subSectionConformationClass.meta.selected`,
        false
      )
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public activateMainSection() {
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionUnderSaddleClass.meta.enabled`,
      true
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionUnderSaddleClass.value`,
      'N/A'
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionHandyClass.meta.enabled`,
      true
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionHandyClass.value`,
      'N/A'
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionConformationClass.meta.enabled`,
      true
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionConformationClass.value`,
      'N/A'
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionHeight.meta.enabled`,
      true
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionHeight.value`,
      'N/A'
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionHeight.meta.selected`,
      ['N/A']
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionTotalPriceMoney.meta.enabled`,
      true
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionTotalPriceMoney.value`,
      '0'
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionJudge.meta.enabled`,
      true
    )
    this.setValue(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionJudge.value`,
      'N/A'
    )

    this.parentField?.subSections?.forEach((value: any, index: any) => {
      this.setValue(
        `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.subSections.${index}.subSectionSubCode.meta.selected`,
        true
      )
      this.setValue(
        `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.subSections.${index}.subSectionUnderSaddleClass.meta.selected`,
        false
      )
      this.setValue(
        `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.subSections.${index}.subSectionHandyClass.meta.selected`,
        false
      )
      this.setValue(
        `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.subSections.${index}.subSectionConformationClass.meta.selected`,
        false
      )
    })
  }
}
