import React from 'react'

interface IProps {}

export const IconPlusCircle: React.FC<IProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16">
      <path
        fill="#122B46"
        className={'group-hover:opacity-100 group-hover:fill-[#F7074F] opacity-50'}
        d="M8.308 1.334c-3.822 0-6.931 2.99-6.931 6.667 0 3.676 3.11 6.666 6.93 6.666 3.823 0 6.932-2.99 6.932-6.666s-3.11-6.667-6.931-6.667zm0 1c3.26 0 5.891 2.531 5.891 5.667 0 3.135-2.631 5.666-5.891 5.666-3.26 0-5.891-2.53-5.891-5.666 0-3.136 2.631-5.667 5.89-5.667zM8.3 4.66a.53.53 0 00-.365.152.49.49 0 00-.147.355v2.334H5.362a.538.538 0 00-.372.144.5.5 0 00-.155.356.484.484 0 00.155.356.522.522 0 00.372.144h2.426v2.333a.484.484 0 00.15.358.522.522 0 00.37.15.54.54 0 00.37-.15.498.498 0 00.15-.358V8.501h2.426a.538.538 0 00.372-.144.5.5 0 00.155-.356.485.485 0 00-.155-.356.523.523 0 00-.372-.144H8.828V5.167a.483.483 0 00-.153-.36.522.522 0 00-.375-.147z"
      ></path>
    </svg>
  )
}
