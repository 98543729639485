// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

import { where } from 'firebase/firestore'
import { useEffect, useRef, useState } from 'react'
import helpers from '../../../../../../../commonHelpers/helpers'
import { CONST } from '../../../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../../../const/messages-const'
import useToasterHelper from '../../../../../../../helpers/ToasterHelper'
import { CustomError, getUserFullName } from '../../../../../../../helpers/helpers'
import { IEventStaffInterface } from '../../../../../../../models/event-staff/event-staff.interface'
import { EventStaffModel } from '../../../../../../../models/event-staff/event-staff.model'
import { getConvertedData } from '../../../../../../../models/interface.helper'
import { IUserDocument } from '../../../../../../../models/user-documents/user-documents.interface'
import { UserDocumentModel } from '../../../../../../../models/user-documents/user-documents.model'
import { IUserInterface } from '../../../../../../../models/users/user.interface'
import { UserModel } from '../../../../../../../models/users/user.model'
import FirestoreService from '../../../../../../../services/firestoreService'
import ITypes from './useStaffListingRoot.types'
import { useIonRouter } from '@ionic/react'
import { useHistory } from 'react-router-dom'

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const STAFF: ITypes['IStaff'] = {
  staffId: null,
  staffRole: null,
  staffName: null,
  isScratched: false,
  isElligible: false,
  isOtherStaff: false,
  staffPaperwork: null,
  staffProfilePicture: null,
  areAllPaperworksSigned: false,
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const useStaffListingRoot = (props: ITypes['IUseStaffListingRootProps']) => {
  // Hooks and vars
  const toastFunctions = useToasterHelper()

  const router = useIonRouter()
  const history = useHistory()
  const eventIdRef = useRef<string | null>(null)

  const [staffsLoading, setStaffsLoading] = useState(true)
  const [staffDocLoading, setStaffDocLoading] = useState(true)
  const [staffs, setStaffs] = useState<ITypes['IStaff'][]>([])
  const [complaintStaffs, setComplaintStaffs] = useState<ITypes['IStaff'][]>([])
  const [nonComplaintStaffs, setNonComplaintStaffs] = useState<ITypes['IStaff'][]>([])
  const [scratchedStaffs, setScratchedStaffs] = useState<ITypes['IStaff'][]>([])
  const [eventStaffDoc, setEventStaffDoc] = useState<IEventStaffInterface | null>(null)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!eventStaffDoc) return
    populate(eventStaffDoc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventStaffDoc])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    let complaintStaffs_: ITypes['IStaff'][] = []
    let scratchedStaffs_: ITypes['IStaff'][] = []
    let nonComplaintStaffs_: ITypes['IStaff'][] = []

    staffs.forEach((currStaff) => {
      if (currStaff.isElligible && currStaff.areAllPaperworksSigned && !currStaff.isScratched)
        complaintStaffs_.push(currStaff)
      else nonComplaintStaffs_.push(currStaff)

      if (currStaff.isScratched) scratchedStaffs_.push(currStaff)
    })

    setComplaintStaffs(complaintStaffs_)
    setScratchedStaffs(scratchedStaffs_)
    setNonComplaintStaffs(nonComplaintStaffs_)
  }, [staffs])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async function getEventStaffDoc(eventId: string) {
    try {
      if (!eventId)
        throw CustomError.somethingWentWrong({
          lineNumber: 61,
          fileName: 'useStaffListingRoot',
          devMessage: `eventId is ${eventId}`,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      eventIdRef.current = eventId

      const staffSnap = await FirestoreService.getItem(COLLECTIONS.EVENT_STAFF.NAME, eventId)

      if (!staffSnap.exists())
        throw CustomError.somethingWentWrong({
          lineNumber: 84,
          fileName: 'useStaffListingRoot',
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: `Could not find event staff for event ${eventId}`,
        })

      const staffs = EventStaffModel.fromFirestoreDoc(staffSnap).toObject()
      setEventStaffDoc(staffs)
    } catch (error: any) {
      toastFunctions.error({
        message: error?.message,
      })

      helpers.logger({
        message: error,
      })
    } finally {
      setStaffDocLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  function getUserIdsToFetch(eventStaffDoc: IEventStaffInterface) {
    let userIdsToFetch: string[] = []
    let staffsWithRole: ITypes['IStaff'][] = []

    const { eventStaff = [], otherStaff = [] } = eventStaffDoc

    eventStaff.forEach((currStaff) => {
      currStaff.value?.forEach((currStaffId) => {
        staffsWithRole.push({
          ...STAFF,
          isOtherStaff: false,
          staffId: currStaffId,
          staffRole: currStaff.title,
          isScratched: !!currStaff?.scratchedValues?.find(
            (currScratchedValue) => currScratchedValue === currStaffId
          ),
        })
        if (typeof currStaffId === 'string') userIdsToFetch.push(currStaffId)
      })
    })

    otherStaff.forEach((currOtherStaff) => {
      currOtherStaff.value?.forEach((currOtherStaffId) => {
        staffsWithRole.push({
          ...STAFF,
          isOtherStaff: true,
          staffId: currOtherStaffId,
          staffRole: currOtherStaff.title,
          isScratched: !!currOtherStaff?.scratchedValues?.find(
            (currScratchedValue) => currScratchedValue === currOtherStaffId
          ),
        })
        if (typeof currOtherStaffId === 'string') userIdsToFetch.push(currOtherStaffId)
      })
    })

    userIdsToFetch = userIdsToFetch.filter((currId) => !!currId)
    userIdsToFetch = [...new Set(userIdsToFetch)]

    return {
      userIdsToFetch,
      staffsWithRole,
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  function onStaffProfileClick(staff: ITypes['IOnStaffProfileClickFnArgs']) {
    const { staffId, staffRole, isOtherStaff } = staff

    try {
      if (!eventIdRef.current)
        throw CustomError.somethingWentWrong({
          lineNumber: 61,
          fileName: 'useStaffListingRoot',
          devMessage: `eventId is ${eventIdRef.current}`,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      if (!staffId)
        throw CustomError.somethingWentWrong({
          lineNumber: 61,
          fileName: 'useStaffListingRoot',
          devMessage: `staffId is ${staffId}`,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      if (!staffRole)
        throw CustomError.somethingWentWrong({
          lineNumber: 61,
          fileName: 'useStaffListingRoot',
          devMessage: `staffRole is ${staffRole}`,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      router.push(
        `${CONST.ROUTES.STAFF_PROFILE.URL}/${staffId}/${staffRole}/${isOtherStaff ? 1 : 0}/${eventIdRef.current}`
      )
      history.push(
        `${CONST.ROUTES.STAFF_PROFILE.URL}/${staffId}/${staffRole}/${isOtherStaff ? 1 : 0}/${eventIdRef.current}`
      )
    } catch (error: any) {
      toastFunctions.error(error?.message)
      helpers.logger({
        message: error,
      })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const populateStaffsWithNameNPic = async (
    userIdsToFetch: string[],
    staffsWithRole: ITypes['IStaff'][]
  ) => {
    let staffName: string = ''
    let users: IUserInterface[] = []
    let user: IUserInterface | null = null

    try {
      let userSnaps = await FirestoreService.getItemsUsingIds(
        COLLECTIONS.USERS.NAME,
        userIdsToFetch
      )

      userSnaps.forEach((currSnap) => {
        users.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
      })

      staffsWithRole = staffsWithRole.map((currStaff) => {
        user = users.find((currUser) => currUser.id === currStaff.staffId) ?? null
        staffName = user ? getUserFullName(user) : 'Unknown'

        return {
          ...currStaff,
          staffName,
          staffProfilePicture: user?.userProfilePicture,
        }
      })
    } catch (error: any) {
      helpers.logger(
        CustomError.somethingWentWrong({
          lineNumber: 199,
          message: error.message,
          devMessage: error.message,
          fileName: 'useStaffListingRoot',
        })
      )
    } finally {
      return staffsWithRole
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const populateStaffsWithStatuses = async (
    userIdsToFetch: string[],
    staffsWithRole: ITypes['IStaff'][]
  ) => {
    let currIndex = 0
    let areAllPaperworksSigned = false
    let documents: IUserDocument[] = []
    let currUserId: string | null = null
    let currUserDocuments: IUserDocument[] = []

    let staffsWithRole_ = [...staffsWithRole]

    try {
      while (currIndex < userIdsToFetch.length) {
        currUserId = userIdsToFetch[currIndex]

        if (currUserId) {
          const documentSnaps = await FirestoreService.filterItems(
            COLLECTIONS.USERS_DOCUMENTS.NAME,
            [
              where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.DOCUMENT_OWNER.KEY, '==', currUserId),
              where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY, '==', eventIdRef.current),
            ]
          )

          documentSnaps.docs.forEach((currDoc) => {
            documents.push(UserDocumentModel.fromFirestoreDoc(currDoc).toObject())
          })
        }

        currIndex++
      }

      staffsWithRole_ = staffsWithRole_.map((currStaff) => {
        currUserDocuments = []
        currUserDocuments = documents.filter(
          (currDoc) => currDoc.documentOwner === currStaff.staffId
        )
        areAllPaperworksSigned = currUserDocuments.every((currDoc) => currDoc.status === 'Signed')
        return {
          ...currStaff,
          areAllPaperworksSigned,
          isElligible: areAllPaperworksSigned,
        }
      })
    } catch (error: any) {
      helpers.logger(
        CustomError.somethingWentWrong({
          lineNumber: 261,
          message: error.message,
          devMessage: error.message,
          fileName: 'useStaffListingRoot',
        })
      )
    } finally {
      return staffsWithRole_
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async function populate(eventStaffDoc: IEventStaffInterface) {
    let { userIdsToFetch, staffsWithRole } = getUserIdsToFetch(eventStaffDoc)

    try {
      staffsWithRole = await populateStaffsWithNameNPic(userIdsToFetch, staffsWithRole)
      staffsWithRole = await populateStaffsWithStatuses(userIdsToFetch, staffsWithRole)
      setStaffs(staffsWithRole)
    } catch (error: any) {
      helpers.logger(
        CustomError.somethingWentWrong({
          fileName: 'useStaffListingRoot',
          message: error?.message,
        })
      )
    } finally {
      setStaffsLoading(false)
    }
  }

  return {
    staffs,
    complaintStaffs,
    scratchedStaffs,
    getEventStaffDoc,
    nonComplaintStaffs,
    onStaffProfileClick,
    loading: staffsLoading || staffDocLoading,
  }
}

export default useStaffListingRoot
