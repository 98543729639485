// ############################################################
/**
 * @todo Document this
 */
// ############################################################

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */
export class EventPrizeListFormBuilderUtilValidationHunterSubSectionMainCheckboxHelper {
  public field?: any
  public parentField?: any
  public rootField?: any
  public previousValue?: boolean
  public newValue?: boolean
  public setValue?: any
  public getValues?: any
  public setError?: any
  public clearErrors?: any
  public trigger?: any
  public disciplineIndex?: number
  public divisionIndex?: number
  public sectionIndex?: number
  public subSectionIndex?: number

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: any) {
    this.field = obj?.field ?? null
    this.parentField = obj?.parentField ?? null
    this.rootField = obj?.rootField ?? null
    this.previousValue = obj?.previousValue ?? null
    this.newValue = obj?.newValue ?? null
    this.setValue = obj?.setValue ?? null
    this.getValues = obj?.getValues ?? null
    this.setError = obj?.setError ?? null
    this.clearErrors = obj?.clearErrors ?? null
    this.trigger = obj?.trigger ?? null
    this.disciplineIndex = obj?.disciplineIndex ?? null
    this.divisionIndex = obj?.divisionIndex ?? null
    this.sectionIndex = obj?.sectionIndex ?? null
    this.subSectionIndex = obj?.subSectionIndex ?? null
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public validate() {}
}
