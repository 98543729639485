import TimeLib from '../../../../lib/Time'
import { IRegistrationTicketInterface } from '../../../../models/registration-tickets/registrationTicket.interface'

export const DATA_FIRESTORE_V01_REGISTRATION_TICKET = {
  NAME: 'v01_registration_tickets',
  FIELDS: {
    V: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_ID: {
      KEY: 'eventId',
      VALUES: {
        DEFAULT: null,
      },
    },

    INVOICE_ID: {
      KEY: 'invoiceId',
      VALUES: {
        DEFAULT: null,
      },
    },

    INVOICE_URL: {
      KEY: 'invoiceUrl',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_NAME_N_GRAM: {
      KEY: 'recipientNameNGram',
      VALUES: {
        DEFAULT: [],
      },
    },

    REFUND_AMOUNT_ADDED: {
      KEY: 'refundAmountAdded',
      VALUES: {
        DEFAULT: false,
      },
    },

    SCRATCH_AMOUNT_ADDED: {
      KEY: 'scratchAmountAdded',
      VALUES: {
        DEFAULT: false,
      },
    },

    RECIPIENT_NAME: {
      KEY: 'recipientName',
      VALUES: {
        DEFAULT: null,
      },
    },

    SELECTED_UNITS_COUNT: {
      KEY: 'selectedUnitsCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    REMAINING_TICKETS_COUNT: {
      KEY: 'remainingTicketsCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    SHARED_TICKETS_COUNT: {
      KEY: 'sharedTicketsCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    REGISTRATION_DOC_ID: {
      KEY: 'registrationDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    FEES_ITEM_ID: {
      KEY: 'feesItemId',
      VALUES: {
        DEFAULT: null,
      },
    },

    FEES_TITLE: {
      KEY: 'feesTitle',
      VALUES: {
        DEFAULT: null,
      },
    },

    FEES_PRICE: {
      KEY: 'feesPrice',
      VALUES: {
        DEFAULT: 0,
      },
    },

    REFUND_DOC_ID: {
      KEY: 'refundDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    REFUND_ID: {
      KEY: 'refundId',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_PROFILE_PICTURE: {
      KEY: 'recipientProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_ID: {
      KEY: 'userId',
      VALUES: {
        DEFAULT: null,
      },
    },

    PAYMENT_STATUS: {
      KEY: 'paymentStatus',
      VALUES: {
        PAID: 'paid' as IRegistrationTicketInterface['paymentStatus'],
        DEFAULT: 'pending' as IRegistrationTicketInterface['paymentStatus'],
        PENDING: 'pending' as IRegistrationTicketInterface['paymentStatus'],
        REFUNDED: 'refunded' as IRegistrationTicketInterface['paymentStatus'],
      },
    },

    IS_SCRATCHED: {
      KEY: 'isSratched',
      VALUES: {
        YES: true,
        NO: false,
        DEFAULT: false,
      },
    },

    AMOUNT_REFUNDED: {
      KEY: 'amountRefunded',
      VALUES: {
        DEFAULT: 0,
      },
    },

    EVENT_NAME: {
      KEY: 'eventName',
      VALUES: {
        DEFAULT: null,
      },
    },

    REFUND_STATUS: {
      KEY: 'refundStatus',
      VALUES: {
        DEFAULT: null,
        REFUNDED: 'refunded' as IRegistrationTicketInterface['refundStatus'],
      },
    },

    IS_PAID_BY_OWNER: {
      KEY: 'isPaidByOwner',
      VALUES: {
        YES: true,
        NO: false,
        DEFAULT: false,
      },
    },

    RECIPIENT_ID: {
      KEY: 'recipientId',
      VALUES: {
        DEFAULT: null,
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    UPDATED: {
      KEY: 'modified',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
