import { where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { CONST } from '../../../../../const/const'
import { IFollowingInterface } from '../../../../../models/following/following.interface'
import { FollowingModel } from '../../../../../models/following/following.model'
import { getConvertedData } from '../../../../../models/interface.helper'
import FirestoreService from '../../../../../services/firestoreService'
import { useAppSelector } from '../../../../../store/hooks'
import { selectUserId } from '../../../../../store/user/userSlice'

import { cloneDeep } from 'lodash'
import helpers from '../../../../../commonHelpers/helpers'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import { CustomError, getUserFullName } from '../../../../../helpers/helpers'
import { IEventInterface } from '../../../../../models/events/event.interface'
import { EventModel } from '../../../../../models/events/event.model'
import { IUserInterface } from '../../../../../models/users/user.interface'
import { UserModel } from '../../../../../models/users/user.model'
import IUserDatatable from './useDatatable.types'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PER_PAGE = 10
const FILE_NAME = 'useDatatable'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const useDatatable = () => {
  // Hooks and vars
  const userId = useAppSelector(selectUserId)
  const toastFunctions = useToasterHelper()

  const [loading, setLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true)
  const [fetchingMore, setFetchingMore] = useState(false)
  const [following, setFollowing] = useState<IFollowingInterface[]>([])
  const [unfollowingId, setUnfollowingId] = useState<string | null>(null)
  const [data, setData] = useState<IUserDatatable['IDatatableRow'][]>([])

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (!userId) return

    setLoading(true)
    getOrganizerFollowers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */
  const removeFollower = (id: string) => {
    let following_ = cloneDeep(following)
    let datatableItems_ = cloneDeep(data)

    following_ = following_.filter((c) => c.id !== id)
    datatableItems_ = datatableItems_.filter((c) => c.followingDocId !== id)

    setData(datatableItems_)
    setFollowing(following_)
  }

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */
  const onUnFollowButtonClick: IUserDatatable['IOnUnFollowButtonClickFn'] = async (args) => {
    const { id, name } = args

    try {
      setUnfollowingId(id)

      const FOLLOWING = CONST.DATA.FIRESTORE.V01.COLLECTIONS.FOLLOWING
      await FirestoreService.deleteItem(FOLLOWING.NAME, id)

      removeFollower(id)
      setUnfollowingId(id)

      toastFunctions.success({
        message: MESSAGES_CONST.UNFOLLOWED.replace('[USER_NAME]', name),
      })
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'onUnFollowButtonClick',
          message: error,
          devMessage: error,
        }),
      })
    } finally {
      setUnfollowingId(null)
    }
  }

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */
  async function getOrganizerFollowers() {
    const userIds: string[] = []
    const following_: IFollowingInterface[] = []

    let followingDoc: IFollowingInterface | null = null

    if (!userId) return

    try {
      followingDoc = following.at(-1) ?? null

      if (followingDoc) setFetchingMore(true)
      else setLoading(true)

      const competitorFollowerSnapshots = await FirestoreService.filterItems(
        COLLECTIONS.FOLLOWING.NAME,
        [
          where(COLLECTIONS.FOLLOWING.FIELDS.FOLLOWER_USER_ID.KEY, '==', userId),
          where(
            COLLECTIONS.FOLLOWING.FIELDS.FOLLOWING_USER_TYPE.KEY,
            '==',
            COLLECTIONS.FOLLOWING.FIELDS.FOLLOWING_USER_TYPE.VALUES.ORGANIZER
          ),
        ],
        PER_PAGE,
        null,
        null,
        following.at(-1)?.id ?? null
      )

      competitorFollowerSnapshots.docs.forEach((currDoc) => {
        followingDoc = getConvertedData(FollowingModel.fromFirestoreDoc(currDoc).toObject())
        if (followingDoc.followingUserId) userIds.push(followingDoc.followingUserId)
        following_.push(followingDoc)
      })

      setHasMore(following_.length <= PER_PAGE)

      addRequiredDetailsInFollowing({
        following: following_,
      })
    } catch (error: any) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'addRequiredDetailsInFollowing',
          message: error,
          devMessage: error,
        }),
      })

      setLoading(false)
      setFetchingMore(false)
    }
  }

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */
  const addRequiredDetailsInFollowing: IUserDatatable['IAddRequiredDetailsInFollowing'] = async (
    args
  ) => {
    let user: IUserInterface | null = null
    let upcomingEvents: IEventInterface[] = []
    let lastEvent: IEventInterface | null = null

    const datatableItems: IUserDatatable['IDatatableRow'][] = []

    const { following } = args

    try {
      await helpers.asyncWhileLoop({
        loopCount: following.length,
        functionToFire: async (index) => {
          lastEvent = null
          upcomingEvents = []

          if (!following[index].followingUserId) return

          const userSnapshot = await FirestoreService.getItem(
            COLLECTIONS.USERS.NAME,
            following[index].followingUserId!
          )

          const eventSnapshot = await FirestoreService.filterItems(
            COLLECTIONS.EVENTS.NAME,
            [
              where(
                COLLECTIONS.EVENTS.FIELDS.STATUS.KEY,
                '==',
                COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CURRENT
              ),
              where(COLLECTIONS.EVENTS.FIELDS.OWNER.KEY, '==', following[index].followingUserId),
              where(COLLECTIONS.EVENTS.FIELDS.EVENT_END_DATE.KEY, '<', new Date()),
            ],
            1
          )

          const upcomingEventSnapshots = await FirestoreService.filterItems(
            COLLECTIONS.EVENTS.NAME,
            [
              where(
                COLLECTIONS.EVENTS.FIELDS.STATUS.KEY,
                '==',
                COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CURRENT
              ),
              where(COLLECTIONS.EVENTS.FIELDS.OWNER.KEY, '==', following[index].followingUserId),
              where(COLLECTIONS.EVENTS.FIELDS.EVENT_START_DATE.KEY, '>', new Date()),
            ],
            null,
            COLLECTIONS.EVENTS.FIELDS.EVENT_START_DATE.KEY,
            'asc'
          )

          if (eventSnapshot.size) {
            lastEvent = getConvertedData(
              EventModel.fromFirestoreDoc(eventSnapshot.docs[0]).toObject()
            )
          }

          upcomingEventSnapshots.forEach((currEventDoc) => {
            upcomingEvents.push(
              getConvertedData(EventModel.fromFirestoreDoc(currEventDoc).toObject())
            )
          })

          user = UserModel.fromFirestoreDoc(userSnapshot)

          datatableItems.push({
            followingDocId: following[index].id!,
            followingUserName: getUserFullName(user),
            followingUserProfilePicture: user.userProfilePicture,
            lastEvent: lastEvent
              ? {
                  id: lastEvent?.id!,
                  name: lastEvent?.eventName ?? 'Unknown',
                }
              : null,
            nextEvent: upcomingEvents.length
              ? {
                  id: upcomingEvents[0].id!,
                  name: upcomingEvents[0].eventName ?? 'Unknown',
                }
              : null,
            upcomingEvents: {
              count: upcomingEvents.length,
              data: upcomingEvents,
            },
            followingUserId: following[index].followingUserId!,
          })
        },
      })

      setFollowing(following)
      setData(datatableItems)
    } catch (error: any) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'addRequiredDetailsInFollowing',
          message: error,
          devMessage: error,
        }),
      })
    } finally {
      setLoading(false)
      setFetchingMore(false)
    }
  }

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */
  const fetchMore = () => {
    getOrganizerFollowers()
  }

  return {
    fetchMore,
    fetchingMore,
    data,
    hasMore,
    unfollowingId,
    onUnFollowButtonClick,
    loading,
  }
}

export default useDatatable
