import './tooltip.css'
import ITooltipProps from './tooltip.types'
import { Tooltip as MuiTooltip } from '@mui/material'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const Tooltip = (props: ITooltipProps) => {
  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getHtml = () => {
    if (props.hideTooltip) return props.children
    else
      return (
        <MuiTooltip
          title={<h1 className="tooltip_title">{props?.label ?? 'Label not provided'}</h1>}
          placement="top"
          arrow={props?.arrow}
        >
          {props.children as any}
        </MuiTooltip>
      )
  }

  return getHtml()
}

export default Tooltip
