import { IMAGE_CONSTS } from '../../const/image-const'

export type HorseWithStatusType = {
  horseImage?: string
  horseName: string
  horseDiscipline: string[]
  horseUsefSynced: boolean
  horseCogginsActive: boolean
  status: boolean
}

const HorseDetailCardWithStatus = ({ item }: { item: HorseWithStatusType }) => {
  return (
    <>
      <div className="flex items-center justify-between">
        {/* horse section */}

        <div
          className={`horse flex flex-row items-center w-full rounded-xl py-3 mb-4 px-2 mx-2 ${item.status ? 'bg-[#00B6AA1A]' : 'bg-[#EB57571A]'}`}
        >
          <img
            src={IMAGE_CONSTS.PLACEHOLDERS.HORSE}
            className="w-1/6 mr-2 avatarImg rounded-full object-cover"
            alt="horseImage"
          />
          <div className="hourseDetails w-10/12">
            <div className="text-sm text-SeabiscuitDark200ThemeColor capitalize">
              {item.horseName}
            </div>
            {/* {item.horseDiscipline && item.horseDiscipline.length &&
                            <div className='hourseCategory flex'>
                                {item.horseDiscipline.map((item: string, index: number) => {
                                    return <p key={index} className='flex items-center text-sm capitalize text-SeabiscuitDark200ThemeColor'>{item}</p>
                                })}
                            </div>
                        }  */}

            <p>{item.horseDiscipline}</p>
          </div>
        </div>

        {/* horse usef section */}

        <div
          className={`horse flex flex-row items-center w-full rounded-xl py-3 mb-4 px-2 mx-2 ${item.horseUsefSynced ? 'bg-[#00B6AA1A]' : 'bg-[#EB57571A]'}`}
        >
          <img
            src={IMAGE_CONSTS.PLACEHOLDERS.HORSE}
            className="w-1/6 mr-2 avatarImg rounded-full object-cover"
            alt="horseImage"
          />
          <div className="hourseDetails w-10/12">
            <div className="text-sm text-SeabiscuitDark200ThemeColor capitalize">
              {item.horseUsefSynced ? 'USEF profile synchronized' : 'Profile not synchronized'}
            </div>
          </div>
          <img
            src={`assets/og_icons/${item.horseUsefSynced ? 'Ok-3' : 'Synchronize-3'}.svg`}
            alt="horseSync"
          />
        </div>

        {/* coggins status */}

        <div
          className={`horse flex flex-row items-center w-full rounded-xl py-3 mb-4 px-2 mx-2 ${item.horseCogginsActive ? 'bg-[#00B6AA1A]' : 'bg-[#EB57571A]'}`}
        >
          <img
            src={IMAGE_CONSTS.PLACEHOLDERS.HORSE}
            className="w-1/6 mr-2 avatarImg rounded-full object-cover"
            alt="horseImage"
          />
          <div className="hourseDetails w-10/12">
            <div className="text-sm text-SeabiscuitDark200ThemeColor capitalize">
              {item.horseCogginsActive ? 'Coggins valid' : 'Coggins expired'}
            </div>
          </div>
          <img
            src={`assets/og_icons/${item.horseCogginsActive ? 'Ok-3' : 'Synchronize-3'}.svg`}
            alt="horseCogginsActive"
          />
        </div>
      </div>
    </>
  )
}

export default HorseDetailCardWithStatus
