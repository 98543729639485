import { yupResolver } from '@hookform/resolvers/yup'
import { AutorenewRounded } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import helpers from '../../../../commonHelpers/helpers'
import { CONST } from '../../../../const/const'
import MessageHelperComp from '../../../../helpers/MessageHelper'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { IUserInterface } from '../../../../models/users/user.interface'
import FirestoreService from '../../../../services/firestoreService'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectProfileData, selectUserId, setPocDetails } from '../../../../store/user/userSlice'
import FormHeader from '../../../ui/form/form-header/FormHeader'
import formFunctions from '../../../ui/form/form-functions/formFunctions'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IProps = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}

// Schema
const organizerProfileScheema = yup.object({
  userPocDetails: yup.object({
    pocFullName: yup.string().required('This is a required field.'),
    pocPhone: yup
      .number()
      .typeError('Please enter valid phone number')
      .required('This is a required field.'),
    pocEmail: yup.string().email('Please enter valid email.').required('This is a required field.'),
  }),
})

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ProfilePointOfContactTab = (props: IProps) => {
  // Hooks and vars
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const toastFunction = useToasterHelper()
  const userId = useAppSelector(selectUserId)
  const userData = useAppSelector(selectProfileData)

  const formStyles = formFunctions.getFormStyles()

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserInterface>({
    mode: 'onChange',
    resolver: yupResolver(organizerProfileScheema),
  })

  useEffect(() => {
    if (userData) {
      try {
        reset({
          ...userData,
        })
      } catch (error) {
        helpers.logger({
          isError: true,
          message: error,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onSubmit = async (data: IUserInterface) => {
    try {
      setLoading(true)
      let latestData = {
        ...data,
        userPocDetails: {
          ...data.userPocDetails,
          pocFullName: `${data.userPocDetails?.pocFullName}`,
        },
      }
      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
        userId,
        latestData
      )

      dispatch(setPocDetails(latestData.userPocDetails))
      toastFunction.success({ message: 'Data update successfully' })
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
      toastFunction.error({ message: 'something went wrong please try again later!' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <FormHeader
        title="Point of Contact settings"
        description="Enter details for the primary point of contact for this organization"
        headerButtonsContainer={
          <div className="flex items-center gap-2">
            <button
              type="button"
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
              className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
            >
              {loading ? (
                <AutorenewRounded fontSize="small" className="animate-spin" />
              ) : (
                'Save changes'
              )}
            </button>
          </div>
        }
      />

      <div style={formStyles.styles} className={formStyles.className}>
        <div className="text-nr text-SeabiscuitDark200ThemeColor">
          <div className="flex flex-col gap-3 md:gap-0 md:flex-row md:items-center mb-4">
            <label className="text-SeabiscuitDark200ThemeColor flex items-center w-80">
              <img src="assets/og_icons/User-1.svg" alt="UserIcon" className="mr-3" />
              Full name
            </label>
            <div>
              <input
                type="text"
                {...register('userPocDetails.pocFullName')}
                className="border w-full md:w-96 border-solid !border-[#D3DAEE] rounded-xl p-3 h-[51px] !ring-0"
                placeholder="Enter name..."
              />
              {errors?.userPocDetails?.pocFullName?.message ? (
                <MessageHelperComp message={errors?.userPocDetails?.pocFullName?.message} isError />
              ) : (
                ''
              )}
            </div>
          </div>
          {/* <div className='flex items-center mb-4'>
            <label className='text-SeabiscuitDark200ThemeColor flex items-center w-80'>
              <img src="assets/og_icons/User-1.svg" alt="UserIcon" className='mr-1' />
              Last name
            </label>
            <div>
              <input
                type="text"
                {...register("userPocDetails.pocLastName")}
                className='border w-96 border-solid !border-[#D3DAEE] rounded-xl p-3 h-[51px] !ring-0'
                placeholder='Enter name of organization...'
              />
              {errors?.userPocDetails?.pocLastName?.message ? <MessageHelperComp message={errors?.userPocDetails?.pocLastName?.message} isError /> : ""}
            </div>
          </div> */}
          <div className="flex  flex-col gap-3 md:gap-0 md:flex-row md:items-center mb-4">
            <label className="text-SeabiscuitDark200ThemeColor flex items-center w-80">
              <img src="/assets/cp_icons/Phone.svg" alt="PhoneIcon" className="mr-3" />
              Phone number
            </label>
            <div>
              <input
                type="number"
                {...register('userPocDetails.pocPhone')}
                className="border w-full md:w-96 border-solid !border-[#D3DAEE] rounded-xl p-3 h-[51px] !ring-0"
                placeholder="Enter number..."
              />
              {errors?.userPocDetails?.pocPhone?.message ? (
                <MessageHelperComp message={errors?.userPocDetails?.pocPhone?.message} isError />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="flex  flex-col gap-3 md:gap-0 md:flex-row md:items-center mb-4">
            <label className="text-SeabiscuitDark200ThemeColor flex items-center w-80">
              <img src="assets/og_icons/Mail-1.svg" alt="MailIcon" className="mr-3" />
              Email address
            </label>
            <div>
              <input
                type="email"
                {...register('userPocDetails.pocEmail')}
                className="border w-full md:w-96 border-solid !border-[#D3DAEE] rounded-xl p-3 h-[51px] !ring-0"
                placeholder="Enter address..."
              />
              {errors?.userPocDetails?.pocEmail?.message ? (
                <MessageHelperComp message={errors?.userPocDetails?.pocEmail?.message} isError />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfilePointOfContactTab
