// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import ModalComponent from '../global/modalComponent'

import Profile1 from '../assets/Staff/profile-pic-1.png'
import Profile2 from '../assets/Staff/profile-pic-2.png'
import Profile3 from '../assets/Staff/profile-pic-3.png'
import Profile4 from '../assets/Staff/profile-pic-4.png'
import Profile5 from '../assets/Staff/profile-pic-5.png'
import Profile6 from '../assets/Staff/profile-pic-6.png'
import Profile7 from '../assets/Staff/profile-pic-7.png'
import Profile8 from '../assets/Staff/profile-pic-8.png'
import Profile9 from '../assets/Staff/profile-pic-9.png'
import Profile10 from '../assets/Staff/profile-pic-10.png'
import Profile11 from '../assets/Staff/profile-pic-11.png'
import Profile12 from '../assets/Staff/profile-pic-12.png'

import Management from '../assets/Staff/management.svg'
import Judges from '../assets/Staff/judges.svg'
import Stewards from '../assets/Staff/stewards.svg'
import Veterinarians from '../assets/Staff/veterinarians.svg'
import Volunteers from '../assets/Staff/volunteers.svg'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentProfileCart: React.FC<{
  picture: string
  title: string
  name: string
}> = ({ picture, title, name }) => (
  <div className="grid grid-cols-[44px_calc(100%_-_44px)] gap-2">
    <div>
      <img
        src={picture}
        alt="Profile"
        className="rounded-full border-2 border-solid border-white"
      />
    </div>
    <div className="flex flex-col">
      <span className="text-[14px] ellipsis">{title}</span>
      <span className="text-[12px] text-[#3C4B7199] ellipsis">{name}</span>
    </div>
  </div>
)

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentStaffSectionManagement: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div className="items-center md:block border-[1px] border-[#D3DAEE] border-solid rounded-xl p-4">
      <div className="flex gap-2 items-center mb-4 flex-1">
        <img src={Management} alt="management" />
        <div>Management</div>
      </div>
      <div className="grid ml-auto grid-cols-1 gap-x-2 gap-y-6 ">
        <EventDetailViewComponentProfileCart
          picture={Profile1}
          title="Show Manager"
          name="Jennifer Tankel"
        />

        <EventDetailViewComponentProfileCart
          picture={Profile2}
          title="Secretary"
          name="Cindy Holmes"
        />
        <EventDetailViewComponentProfileCart
          picture={Profile3}
          title="Course Designer"
          name="Lori Barton"
        />
      </div>
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentStaffSectionJudges: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div className="items-center md:block border-[1px] border-[#D3DAEE] border-solid rounded-xl p-4">
      <div className="flex gap-2 items-center mb-4 flex-1">
        <img src={Judges} alt="Judges" />
        <div>Judges & scorers</div>
      </div>
      <div className="grid ml-auto grid-cols-1 gap-x-2 gap-y-6 ">
        <EventDetailViewComponentProfileCart picture={Profile1} title="Judge" name="Annie Woods" />
        <EventDetailViewComponentProfileCart
          picture={Profile2}
          title="Judge"
          name="Melanie Wyoming"
        />
        <EventDetailViewComponentProfileCart
          picture={Profile3}
          title="Judge"
          name="Jacinta Burman"
        />
      </div>
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentStaffSectionStewards: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div className="items-center md:block border-[1px] border-[#D3DAEE] border-solid rounded-xl p-4">
      <div className="flex gap-2 items-center mb-4 flex-1">
        <img src={Stewards} alt="Stewards" />
        <div>Stewards & ingates</div>
      </div>
      <div className="grid ml-auto grid-cols-1 gap-x-2 gap-y-6 ">
        <EventDetailViewComponentProfileCart
          picture={Profile1}
          title="Steward"
          name="Kate Wilcocks"
        />
        <EventDetailViewComponentProfileCart picture={Profile2} title="Steward" name="Nacy Bets" />
        <EventDetailViewComponentProfileCart
          picture={Profile3}
          title="Steward"
          name="Rease Yukolo"
        />
      </div>
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentStaffSectionVeterinarians: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div className="items-center md:block border-[1px] border-[#D3DAEE] border-solid rounded-xl p-4">
      <div className="flex gap-2 items-center mb-4 flex-1">
        <img src={Veterinarians} alt="Veterinarians" />
        <div>Veterinarians & farriers</div>
      </div>
      <div className="grid ml-auto grid-cols-1 gap-x-2 gap-y-6 ">
        <EventDetailViewComponentProfileCart
          picture={Profile1}
          title="Farrier"
          name="Jacinta Woods"
        />
        <EventDetailViewComponentProfileCart
          picture={Profile2}
          title="Veterinarian"
          name="Damian Nurol"
        />
      </div>
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentStaffSectionVolunteers: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div className="items-center md:block border-[1px] border-[#D3DAEE] border-solid rounded-xl p-4">
      <div className="flex gap-2 items-center mb-4 flex-1">
        <img src={Volunteers} alt="Volunteers" />
        <div>Volunteers</div>
      </div>
      <div className="grid ml-auto grid-cols-1 gap-x-2 gap-y-6 ">
        <EventDetailViewComponentProfileCart
          picture={Profile1}
          title="Volunteer"
          name="Jenny Williams"
        />
        <EventDetailViewComponentProfileCart
          picture={Profile2}
          title="Volunteer"
          name="Katherine Nute"
        />
        <EventDetailViewComponentProfileCart
          picture={Profile3}
          title="Volunteer"
          name="Jennifer Butts"
        />

        <EventDetailViewComponentProfileCart
          picture={Profile4}
          title="Volunteer"
          name="Emily Spanner"
        />
        <EventDetailViewComponentProfileCart
          picture={Profile5}
          title="Volunteer"
          name="Samantha Jinda"
        />
        <EventDetailViewComponentProfileCart
          picture={Profile6}
          title="Volunteer"
          name="Giorgia Handy"
        />
        <EventDetailViewComponentProfileCart
          picture={Profile7}
          title="Volunteer"
          name="Wilci Yang"
        />
        <EventDetailViewComponentProfileCart
          picture={Profile8}
          title="Volunteer"
          name="Jesse Harper"
        />
        <EventDetailViewComponentProfileCart
          picture={Profile9}
          title="Volunteer"
          name="henry Carr"
        />
        <EventDetailViewComponentProfileCart
          picture={Profile10}
          title="Volunteer"
          name="Liam Yella"
        />
        <EventDetailViewComponentProfileCart
          picture={Profile11}
          title="Volunteer"
          name="Jason Smith"
        />
        <EventDetailViewComponentProfileCart
          picture={Profile12}
          title="Volunteer"
          name="James Burman"
        />
      </div>
    </div>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentAllStaffModal: React.FC<{ show: boolean; onHide: () => void }> = ({
  show,
  onHide,
}) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <ModalComponent show={show} onHide={onHide} type="full-modal" title="All Staff" size="xl">
      <div className="grid gap-4 grid-cols-1 text-[#3C4B7199] text-[14px]">
        <EventDetailViewComponentStaffSectionManagement />
        <EventDetailViewComponentStaffSectionJudges />
        <EventDetailViewComponentStaffSectionStewards />
        <EventDetailViewComponentStaffSectionVeterinarians />
        <EventDetailViewComponentStaffSectionVolunteers />
      </div>
    </ModalComponent>
  )
}

export default EventDetailViewComponentAllStaffModal
