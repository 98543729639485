import { CONST } from '../../../../../../const/const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//  Hunter class AA
export const EPL_FB_HUNTER_AA = {
  vMain: 1,
  vSub: 0,
  vFix: 0,
  disciplineType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DISCIPLINE.TYPE.STANDARD,
  discipline: 'Hunter CLASS AA',
  validationStatus: 'unknown',
  divisionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.DIVISION.TYPE.STANDARD,
  divisionItemsRules: null,
  divisions: [
    // ************************************************************
    //  <HUNTER AA> 1900 - Hunter Breeding
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '1900',
      divisionTitle: 'Hunter Breeding',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 1901 Hunter Breeding Yearling
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '1901',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Hunter Breeding Yearling',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '1901 Hunter Breeding Yearling',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A', 'alpha', 'beta', 'gamma'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class 1901',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Model',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class 1901-Model',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 1902 Hunter Breeding 2 Year Old
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '1902',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Hunter Breeding 2 Year Old',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '1902 Hunter Breeding 2 Year Old',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 1903 Hunter Breeding 3 Year Old
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '1903',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Hunter Breeding 3 Year Old',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '1903 Hunter Breeding 3 Year Old',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <HUNTER AA> 1938 - Pony Hunter Breeding
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '1938',
      divisionTitle: 'Pony Hunter Breeding',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 1904 - Pony Hunter Breeding Yearling
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '1904',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Pony Hunter Breeding Yearling',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '1904 Pony Hunter Breeding Yearling',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 1905 - Pony Hunter Breeding 2 Year Old
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '1905',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Pony Hunter Breeding 2 Year Old',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '1905 Pony Hunter Breeding 2 Year Old',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 1906 - Pony Hunter Breeding 3 Year Old
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '1906',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Pony Hunter Breeding 3 Year Old',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '1906 Pony Hunter Breeding 3 Year Old',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2001 - High Performance Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2001',
      divisionTitle: 'High Performance Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2005 - Performance Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2005',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Performance Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '2005 Performance Combined',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: [
            {
              type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.RULES.HUNTER
                .HUNTER_STANDARD_NO_SADDLE_CLASS,
              errorMessage: 'This Section Does Not Allow Just Place Saddle Classes',
            },
            {
              type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.RULES.HUNTER
                .HUNTER_STANDARD_ONE_HANDY_CLASS,
              errorMessage: 'This Section Does Allow Just One',
            },
            {
              type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.RULES.HUNTER
                .HUNTER_STANDARD_NO_SAME_CLASS,
              errorMessage: 'Cannot be the same class as other',
            },
          ],
          subSections: [
            // ============================================================
            //  <HUNTER AA> 2005 - Performance Combined - Class 2005-A
            //
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class 2005',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'A',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class 2005-A',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
            // ============================================================
            //  <HUNTER AA> 2005 - Performance Combined - Class 2005-B
            //
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class 2005',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'B',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class 2005-B',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
            // ============================================================
            //  <HUNTER AA> 2005 - Performance Combined - Class 2005-C
            //
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class 2005',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'C',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class 2005-C',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
            // ============================================================
            //  <HUNTER AA> 2005 - Performance Combined - Class 2005-D
            //
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class 2005',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'D',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class 2005-D',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
            // ============================================================
            //  <HUNTER AA> 2005 - Performance Combined - Class 2005-E
            //
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class 2005',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'E',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class 2005-E',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2006 - High Performance/Green Hunter 3’6/3’9 Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2006',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'High Performance/Green Hunter 3’6/3’9 Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '2006 High Performance/Green Hunter 3’6/3’9 Combined',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            validation: [],
            value: '',
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    //////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////

    // ************************************************************
    // <HUNTER AA> HUNTER CLASSIC
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2002',
      divisionTitle: 'HUNTER CLASSIC',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_CLASSIC,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2005 - Performance Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_CLASSIC,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2005',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Performance Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_CLASSIC_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '2005 Performance Combined',
            validation: [],
            rules: null,
          },
          sectionSize: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_CLASSIC_SIZE_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A', 'Small', 'Medium', 'Large'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionMinAge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_LESS_THAN_NUMBER,
                value: 18,
                errorMessage: 'Age cannot me less than 18 years',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_MORE_THAN_NUMBER,
                value: 24,
                errorMessage: 'Age cannot me more than 24 years',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION
                  .MIN_MAX_AGE_NUMERICAL_RELATIONSHIP,
                errorMessage: 'Minimum age should be less (or equal) than maximum age.',
              },
            ],
            rules: null,
          },
          sectionMaxAge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_LESS_THAN_NUMBER,
                value: 18,
                errorMessage: 'Age cannot me less than 18 years',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_MORE_THAN_NUMBER,
                value: 24,
                errorMessage: 'Age cannot me more than 24 years',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION
                  .MIN_MAX_AGE_NUMERICAL_RELATIONSHIP,
                errorMessage: 'Minimum age should be less (or equal) than maximum age.',
              },
            ],
            rules: null,
          },
          sectionQualifying: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_CLASSIC_QUALIFYING_MULTIPLE_CHOICE,
            meta: {
              enabled: false,
              multiple: true,
              options: ['N/A', 'Class-A', 'Class-B', 'Class-C'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_CLASSIC_HEIGHT_MULTIPLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType: null,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> HUNTER DERBY
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2002',
      divisionTitle: 'HUNTER DERBY',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_DERBY,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2005 - Performance Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_DERBY,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2005',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Performance Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_DERBY_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '2005 Performance Combined',
            validation: [],
            rules: null,
          },
          sectionQualifying: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_DERBY_QUALIFYING_MULTIPLE_CHOICE,
            meta: {
              enabled: false,
              multiple: true,
              options: ['N/A', 'alpha', 'beta', 'gamma'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType: null,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> HUNTER LEAGUE
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2002',
      divisionTitle: 'HUNTER LEAGUE',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_LEAGUE,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2005 - Performance Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_LEAGUE,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2005',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Performance Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_LEAGUE_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '2005 Performance Combined',
            validation: [],
            rules: null,
          },
          sectionQualifying: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_LEAGUE_QUALIFYING_MULTIPLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          subsectionsItemsType: null,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },

    // // ************************************************************
    // // <HUNTER AA> HUNTER LEAGUE
    // {
    //     divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
    //     divisionCode: "2002",
    //     divisionTitle: "Performance Hunter 3’6",
    //     sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_LEAGUE,
    //     sectionItemsRules: null,
    //     sections: [
    //         // ============================================================
    //         //  <HUNTER AA> 2005 - Performance Combined
    //         //
    //         {
    //             sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_LEAGUE,
    //             sectionCodeId: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
    //                 meta: null,
    //                 value: "2005",
    //                 validation: [],
    //                 rules: null
    //             },
    //             sectionCodeTitle: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
    //                 meta: null,
    //                 value: "Performance Combined",
    //                 validation: [],
    //                 rules: null
    //             },
    //             sectionSubCode: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_LEAGUE_MAIN_CHECKBOX,
    //                 meta: {
    //                     selected: false
    //                 },
    //                 value: "2005 Performance Combined",
    //                 validation: [],
    //                 rules: null
    //             },
    //             sectionQualifying: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_LEAGUE_QUALIFYING_MULTIPLE_CHOICE,
    //                 meta: {
    //                     enabled: false,
    //                     multiple: false,
    //                     options: [
    //                         "N/A",
    //                     ],
    //                     selected: [
    //                         "N/A"
    //                     ]
    //                 },
    //                 value: "",
    //                 validation: [],
    //                 rules: null
    //             },
    //             sectionTotalPriceMoney: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
    //                 meta: {
    //                     enabled: false,
    //                 },
    //                 value: "",
    //                 validation: [
    //                     {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
    //                         errorMessage: "Must be a valid number"
    //                     },
    //                     {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
    //                         errorMessage: "The amount can't be negative"
    //                     },
    //                     {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
    //                         errorMessage: "Field can't be empty"
    //                     },
    //                     {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
    //                         errorMessage: "Value can't Be Zero"
    //                     },
    //                 ],
    //                 rules: null
    //             },
    //             subsectionsItemsType: null,
    //             subsectionItemsRules: null,
    //             subSections: []
    //         },
    //     ],
    //     extraSections: [],
    //     extraSectionTemplate: []
    // },
    // {
    //     divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
    //     divisionCode: "2002",
    //     divisionTitle: "Performance Hunter 3’6",
    //     sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
    //     sectionItemsRules: null,
    //     sections: [
    //         // ============================================================
    //         //  <HUNTER AA> 2005 - Performance Combined
    //         //
    //         {
    //             sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.STANDARD,
    //             sectionCodeId: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
    //                 meta: null,
    //                 value: "2005",
    //                 validation: [],
    //                 rules: null
    //             },
    //             sectionCodeTitle: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
    //                 meta: null,
    //                 value: "Performance Combined",
    //                 validation: [],
    //                 rules: null
    //             },
    //             sectionSubCode: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
    //                 meta: {
    //                     selected: false
    //                 },
    //                 value: "2005 Performance Combined",
    //                 validation: [],
    //                 rules: null
    //             },
    //             sectionUnderSaddleClass: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
    //                 meta: {
    //                     enabled: false,
    //                 },
    //                 value: "",
    //                 validation: [],
    //                 rules: null
    //             },
    //             sectionHandyClass: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
    //                 meta: {
    //                     enabled: false,
    //                 },
    //                 value: "",
    //                 validation: [],
    //                 rules: null
    //             },
    //             sectionConformationClass: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
    //                 meta: {
    //                     enabled: false,
    //                 },
    //                 value: "",
    //                 validation: [],
    //                 rules: null
    //             },
    //             sectionHeight: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
    //                 meta: {
    //                     enabled: false,
    //                     multiple: false,
    //                     options: [
    //                         "N/A",
    //                     ],
    //                     selected: [
    //                         "N/A"
    //                     ]
    //                 },
    //                 value: "",
    //                 validation: [],
    //                 rules: null
    //             },
    //             sectionTotalPriceMoney: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
    //                 meta: {
    //                     enabled: false,
    //                 },
    //                 value: "",
    //                 validation: [
    //                     {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
    //                         errorMessage: "Must be a valid number"
    //                     },
    //                     {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
    //                         errorMessage: "The amount can't be negative"
    //                     },
    //                     {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
    //                         errorMessage: "Field can't be empty"
    //                     },
    //                     {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
    //                         errorMessage: "Value can't Be Zero"
    //                     },
    //                 ],
    //                 rules: null
    //             },
    //             sectionJudge: {
    //                 type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
    //                 meta: {
    //                     enabled: false,
    //                 },
    //                 value: "",
    //                 validation: [],
    //                 rules: null
    //             },
    //             subsectionsItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
    //             subsectionItemsRules: null,
    //             subSections: [
    //                 {
    //                     subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
    //                     subSectionSubCodeId: {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
    //                         meta: null,
    //                         value: "Class FILLCLASS",
    //                         validation: [],
    //                         rules: null
    //                     },
    //                     subSectionCodeTitle: {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
    //                         meta: null,
    //                         value: "FILLPOSTFIX",
    //                         validation: [],
    //                         rules: null
    //                     },
    //                     subSectionSubCode: {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
    //                         meta: {
    //                             selected: false
    //                         },
    //                         value: "Class FILLCLASS-FILLPOSTFIX",
    //                         validation: [],
    //                         rules: null
    //                     },
    //                     subSectionUnderSaddleClass: {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
    //                         meta: {
    //                             selected: false
    //                         },
    //                         value: "Under Saddle",
    //                         validation: [],
    //                         rules: null
    //                     },
    //                     subSectionHandyClass: {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
    //                         meta: {
    //                             selected: false
    //                         },
    //                         value: "Handy",
    //                         validation: [],
    //                         rules: null
    //                     },
    //                     subSectionConformationClass: {
    //                         type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
    //                         meta: {
    //                             selected: false
    //                         },
    //                         value: "Conformation",
    //                         validation: [],
    //                         rules: null
    //                     }
    //                 },
    //             ]
    //         },
    //     ],
    //     extraSections: [],
    //     extraSectionTemplate: []
    // },
    // ************************************************************
    // <HUNTER AA> 2003 - Performance Hunter 3’3
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2003',
      divisionTitle: 'Performance Hunter 3’3',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2005 - Performance Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2005',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Performance Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2005 Performance Combined',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2150 - Green Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2150',
      divisionTitle: 'Green Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2151 - Green Hunter 3’
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2151',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Green Hunter 3’',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2151 Green Hunter 3’',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2152 - Green Hunter 3’3
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2152',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: ' Green Hunter 3’3',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2152  Green Hunter 3’3',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2153 - Green Hunter 3’6
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2153',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Green Hunter 3’6',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2153 Green Hunter 3’6',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2154 - Green Hunter 3’9
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2154',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Green Hunter 3’9',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2154 Green Hunter 3’9',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2006 - High Performance/ Green Hunter 3’6/3’9 Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2006',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'High Performance/ Green Hunter 3’6/3’9 Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2006 High Performance/ Green Hunter 3’6/3’9 Combined',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2180 - Young Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2180',
      divisionTitle: 'Young Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2181 - Young Hunter 5 & Under 2’9
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2181',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Young Hunter 5 & Under 2’9',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2181 Young Hunter 5 & Under 2’9',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2182 - Young Hunter 6 & Under 3’
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2182',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Young Hunter 6 & Under 3’',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2182 Young Hunter 6 & Under 3’',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2183 - Young Hunter 7 & Under 3’3
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2183',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Young Hunter 7 & Under 3’3',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2183 Young Hunter 7 & Under 3’3',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2203 - Amateur Owner 3’3
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2203',
      divisionTitle: 'Amateur Owner 3’3',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2204 - Amateur Owner 3’3 18-35
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2204',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Amateur Owner 3’3 18-35',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2204 Amateur Owner 3’3 18-35',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2205 - Amateur Owner 3’3 36+
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2205',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Amateur Owner 3’3 36+',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2205 Amateur Owner 3’3 36+',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 9303 - Combined A/O Junior Hunter 3’3
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9303',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Combined A/O Junior Hunter 3’3',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '9303 Combined A/O Junior Hunter 3’3',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2200 - Amateur Owner 3’6
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2200',
      divisionTitle: 'Amateur Owner 3’6',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2201 - Amateur Owner Hunter 3’6 18-35
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2201',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Amateur Owner Hunter 3’6 18-35',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2201 Amateur Owner Hunter 3’6 18-35',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2202 - Amateur Owner Hunter 3’6 36+
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2202',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Amateur Owner Hunter 3’6 36+',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2202 Amateur Owner Hunter 3’6 36+',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 9300 - Combined A/O Junior Hunter 3’6
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9300',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Combined A/O Junior Hunter 3’6',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '9300 Combined A/O Junior Hunter 3’6',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2301 - High Performance Conformation
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2301',
      divisionTitle: 'High Performance Conformation',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2300 - Conformation Hunter Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2300',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Conformation Hunter Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2300 Conformation Hunter Combined',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2302 - Green Conformation Hunter 3’6
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2302',
      divisionTitle: 'Green Conformation Hunter 3’6',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2300 - Conformation Hunter Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2302',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Conformation Hunter Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2302 Conformation Hunter Combined',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2420 - Junior Hunter 3’3
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2420',
      divisionTitle: 'Junior Hunter 3’3',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2421 - Junior Hunter 3’3 Small 15/U
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2421',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Junior Hunter 3’3 Small 15/U',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2421 Junior Hunter 3’3 Small 15/U',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2422 - Junior Hunter 3’3 Large 15/U
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2422',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Junior Hunter 3’3 Large 15/U',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2422 Junior Hunter 3’3 Large 15/U',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2423 - Junior Hunter 3’3 Small 16-17
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2423',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Junior Hunter 3’3 Small 16-17',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2423 Junior Hunter 3’3 Small 16-17',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2424 - Junior Hunter 3’3 Large 16-17
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: {
              enabled: false,
            },
            value: '2424',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: {
              enabled: false,
            },
            value: 'Junior Hunter 3’3 Large 16-17',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2424 Junior Hunter 3’3 Large 16-17',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2412 - Small Junior Hunter 3’3 Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2412',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Small Junior Hunter 3’3 Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2412 Small Junior Hunter 3’3 Combined',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2413 - Large Junior Hunter 3’3 Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2413',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Large Junior Hunter 3’3 Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2413 Large Junior Hunter 3’3 Combined',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 9303 - Combined A/O Junior Hunter 3’3
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9303',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Combined A/O Junior Hunter 3’3',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '9303 Combined A/O Junior Hunter 3’3',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2400 - Junior Hunter 3’6
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2400',
      divisionTitle: 'Junior Hunter 3’6',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2401 - Junior Hunter 3’6 Small 15/U
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2401',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Junior Hunter 3’6 Small 15/U',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2401 Junior Hunter 3’6 Small 15/U',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2402 - Junior Hunter 3’6 Large 15/U
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2402',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Junior Hunter 3’6 Large 15/U',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2402 Junior Hunter 3’6 Large 15/U',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2403 - Junior Hunter 3’6 Small 16-17
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2403',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Junior Hunter 3’6 Small 16-17',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2403 Junior Hunter 3’6 Small 16-17',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2404 - Junior Hunter 3’5 Large 16-17
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2404',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Junior Hunter 3’5 Large 16-17',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2404 Junior Hunter 3’5 Large 16-17',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2410 - Small Junior Hunter 3’6 Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2410',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Small Junior Hunter 3’6 Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2410 Small Junior Hunter 3’6 Combined',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2411 - Large Junior Hunter 3’6 Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: {
              enabled: false,
            },
            value: '2411',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: {
              enabled: false,
            },
            value: 'Large Junior Hunter 3’6 Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2411 Large Junior Hunter 3’6 Combined',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 9300 - Combined A/O Junior Hunter 3’6
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9300',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Combined A/O Junior Hunter 3’6',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '9300 Combined A/O Junior Hunter 3’6',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2500 - Pony Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2500',
      divisionTitle: 'Pony Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2501 - Small Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2501',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Small Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2501 Small Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2502 - Medium Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2502',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Medium Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2502 Medium Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2503 - Large Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2503',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Large Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2503 Large Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2600 - Green Pony Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2600',
      divisionTitle: 'Green Pony Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2601 - Small/Medium Green Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2601',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Small/Medium Green Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2601 Small/Medium Green Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2602 - Large Green Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2602',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Large Green Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2602 Large Green Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2603 - Medium Green Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2603',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Medium Green Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2603 Medium Green Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2604 - Small Green Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2604',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Small Green Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2604 Small Green Pony Hunter',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2700 - Children’s Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2700',
      divisionTitle: 'Children’s Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2701 - Children’s Hunter Pony
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2701',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Children’s Hunter Pony',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2701 Children’s Hunter Pony',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2702 - Children’s Hunter Horse
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2702',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Children’s Hunter Horse',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2702 Children’s Hunter Horse',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 9301 - Combined Children and Adults Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9301',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Combined Children and Adults Hunter',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '9301 Combined Children and Adults Hunter',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2800 - Adult Amateur Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2800',
      divisionTitle: 'Adult Amateur Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2801 - Adult Amateur Hunter 18-35
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2801',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Adult Amateur Hunter 18-35',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2801 Adult Amateur Hunter 18-35',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2802 - Adult Amateur Hunter Over 36
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2802',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Adult Amateur Hunter Over 36',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2802 Adult Amateur Hunter Over 36',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 9301 - Combined Children and Adult Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9301',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Combined Children and Adult Hunter',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '9301 Combined Children and Adult Hunter',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2900 - Ladies’ Sidesaddle
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2900',
      divisionTitle: 'Ladies’ Sidesaddle',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 3000 - (Junior) Hunter Classic
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3000',
      divisionTitle: '(Junior) Hunter Classic',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 3010 - Pony Hunter Classic
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3010',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Pony Hunter Classic',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '3010 Pony Hunter Classic',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 3011 - Green Pony Hunter Classic
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3011',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Green Pony Hunter Classic',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '3011 Green Pony Hunter Classic',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 3020 - Amateur Owner Hunter Classic
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3020',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Amateur Owner Hunter Classic',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '3020 Amateur Owner Hunter Classic',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 3050 - Open Hunter Classic
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3050',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Open Hunter Classic',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '3050 Open Hunter Classic',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 3070 - USHJA Pony Hunter Derby
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3070',
      divisionTitle: 'USHJA Pony Hunter Derby',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 3040 - USHJA National Hunter Derby
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3040',
      divisionTitle: 'USHJA National Hunter Derby',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 3030 - USHJA International Hunter Derby
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3030',
      divisionTitle: 'USHJA International Hunter Derby',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 3100 - Miscellaneous Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3100',
      divisionTitle: 'Miscellaneous Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2004 - Small Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2004',
      divisionTitle: 'Small Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 3109 - Thoroughbred Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3109',
      divisionTitle: 'Thoroughbred Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2050 - USHJA Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2050',
      divisionTitle: 'USHJA Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2051 - USHJA 2’
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2051',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'USHJA 2’',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2051 USHJA 2’',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2052 - USHJA 2’3
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2052',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'USHJA 2’3',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2052 USHJA 2’3',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2053 - USHJA 2’6
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2053',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'USHJA 2’6',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2053 USHJA 2’6',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2054 - USHJA 2’9
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2054',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'USHJA 2’9',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2054 USHJA 2’9',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 2055 - USHJA 3’
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2055',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'USHJA 3’',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '2055 USHJA 3’',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 12000 - USHJA Green Hunter Incentive Program
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '12000',
      divisionTitle: 'USHJA Green Hunter Incentive Program',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 12010 - USHJA Green Hunter Incentive 3’/3’3
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '12010',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'USHJA Green Hunter Incentive 3’/3’3',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '12010 USHJA Green Hunter Incentive 3’/3’3',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 12020 - USHJA Green Hunter Incentive 3’6/3’9
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '12020',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'USHJA Green Hunter Incentive 3’6/3’9',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '12020 USHJA Green Hunter Incentive 3’6/3’9',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER AA> 12001 - USHJA Green Hunter Challenge
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '12001',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'USHJA Green Hunter Challenge',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '12001 USHJA Green Hunter Challenge',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2716 - Low Children’s Hunter Horse
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2716',
      divisionTitle: 'Low Children’s Hunter Horse',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2717 - Low Children’s Hunter Pony
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2717',
      divisionTitle: 'Low Children’s Hunter Pony',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> 2805 - Low Adult Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2805',
      divisionTitle: 'Low Adult Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 9302 - Combined Low Children and Low Adult Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '9302',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Combined Low Children and Low Adult Hunter',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              selected: false,
            },
            value: '9302 Combined Low Children and Low Adult Hunter',
            validation: [],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class FILLCLASS',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class FILLCLASS-FILLPOSTFIX',
                validation: [],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Under Saddle',
                validation: [],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_HANDY_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Handy',
                validation: [],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Conformation',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> HUNTER CLASSIC
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2002',
      divisionTitle: 'HUNTER CLASSIC',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_CLASSIC,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2005 - Performance Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_CLASSIC,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2005',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Performance Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_CLASSIC_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '2005 Performance Combined',
            validation: [],
            rules: null,
          },
          sectionSize: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_CLASSIC_SIZE_SINGLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionMinAge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionMaxAge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionQualifying: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_CLASSIC_QUALIFYING_MULTIPLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_CLASSIC_HEIGHT_MULTIPLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType: null,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> HUNTER DERBY
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2002',
      divisionTitle: 'HUNTER DERBY',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_DERBY,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2005 - Performance Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_DERBY,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2005',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Performance Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_DERBY_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '2005 Performance Combined',
            validation: [],
            rules: null,
          },
          sectionQualifying: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_DERBY_QUALIFYING_MULTIPLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType: null,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER AA> HUNTER LEAGUE
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2002',
      divisionTitle: 'HUNTER LEAGUE',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_LEAGUE,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER AA> 2005 - Performance Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_LEAGUE,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '2005',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Performance Combined',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_LEAGUE_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '2005 Performance Combined',
            validation: [],
            rules: null,
          },
          sectionQualifying: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
              .SECTION_HUNTER_LEAGUE_QUALIFYING_MULTIPLE_CHOICE,
            meta: {
              enabled: false,
              multiple: false,
              options: ['N/A'],
              selected: ['N/A'],
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          subsectionsItemsType: null,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
  ],
}
