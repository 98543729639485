import { useEffect } from 'react'
import { CONST } from '../../../const/const'
import useGetEventData from '../../../hooks/users/common/useGetEventData'
import { EventFeesModel } from '../../../models/event-fees/event-fees.model'
import FirestoreService from '../../../services/firestoreService'
import { selectedEvent, setSelectedEvent } from '../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import EventEntriesFormComponent from '../../../components/events/forms/entries/EventEntriesFormComponent'

interface ShowsDetailPageEventFeesFormSubSectionProps {
  onSetEventTab: (tab: string) => void
  onSetNextEventTab: (tab: string) => void
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  eventTab?: string
  nextEventTab: string
}

const ShowsDetailPageEventFeesFormSubSection: React.FC<
  ShowsDetailPageEventFeesFormSubSectionProps
> = (props) => {
  const { eventTab, nextEventTab } = props
  const selectedEventR = useAppSelector(selectedEvent)
  const eventId = selectedEventR.Event?.id ?? ''
  const dispatch = useAppDispatch()

  const { getAllData } = useGetEventData()

  const validFormHandler = async (data: any) => {
    let updated = true

    try {
      const updated_data = new EventFeesModel({
        ...data,
      })
      if (eventId) {
        dispatch(setSelectedEvent({ ...selectedEventR, EventFees: data }))
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_FEES.NAME,
          eventId,
          updated_data.toFirestore()
        )
      }
    } catch (reason) {
      updated = false
      console.error('validFormHandler:' + reason)
    } finally {
      return {
        onSetNextEventTab: props.onSetNextEventTab(''),
        updated,
        onSetEventTab:
          props.nextEventTab === ''
            ? props.onSetEventTab
            : props.onSetEventTab(props?.nextEventTab),
      }
    }
  }

  const validAndExitFormHandler = async (data: any) => {
    return await validFormHandler(data)
  }

  const invalidFormHandler = async () => {
    props.onSetNextEventTab('')
    alert('invalid')
  }

  const invalidAndExitFormHandler = async () => {
    props.onSetNextEventTab('')
    alert('invalid')
  }

  useEffect(() => {
    if (!eventId) return
    getAllData(eventId, ['v01_event_fees'])
  }, [eventId, getAllData])

  return (
    <EventEntriesFormComponent
      onValid={validFormHandler}
      onInvalid={invalidFormHandler}
      data={selectedEventR.EventFees}
      eventTab={eventTab}
      nextEventTab={nextEventTab}
      onValidAnExit={validAndExitFormHandler}
      onInvalidAndExit={invalidAndExitFormHandler}
      handleModal={props.handleModal}
    />
  )
}

export default ShowsDetailPageEventFeesFormSubSection
