// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import EventSchedulingFormComponentButton from '../global/EventSchedulingFormComponentButton'
import EventSchedulingFormComponentDrawerComponent from '../global/EventSchedulingFormComponentDrawerComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventSchedulingFormComponentNoteModal: React.FC<{ show: boolean; onHide: () => void }> = ({
  show,
  onHide,
}) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <EventSchedulingFormComponentDrawerComponent
      show={show}
      onHide={onHide}
      title="Add note"
      maxWidth={600}
    >
      <div className="text-center m-1 mt-4">
        <textarea
          placeholder="Type ..."
          className="min-h-[450px] rounded-xl w-full border-1 border-[#D3DAEE] h-12 mt-2"
        />
        <EventSchedulingFormComponentButton
          caption="SAVE"
          type={1}
          className="mt-6 w-[260px] mx-auto"
        />
      </div>
    </EventSchedulingFormComponentDrawerComponent>
  )
}

export default EventSchedulingFormComponentNoteModal
