import useDebounce from '../../../../../../hooks/debounce'
import PaidSpectatorRevenueTab from './PaidSpectatorRevenueTab'

type IProps = {
  eventId: string
  eventTab: string
  searchValue: string
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const SpectatorRevenueDisplayTabs = (props: IProps) => {
  // Hooks and vars
  const { eventTab, searchValue } = props
  const searchValue_: string = useDebounce(searchValue, 500)

  return <PaidSpectatorRevenueTab activeTab={eventTab} searchValue={searchValue_} />
}

export default SpectatorRevenueDisplayTabs
