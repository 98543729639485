import React from 'react'
import { IRider } from '../../../../types/entryRider'
import customImageComponent from '../../../common/CustomImageComponent'
import { IconBackNumber } from '../../../icons/IconBackNumber'
import { IconClasses } from '../../../icons/IconClasses'

interface IProps {
  rider: IRider
}

export const SwitchHorseHeader: React.FC<IProps> = ({ rider }) => {
  return (
    <>
      <div className={'py-5 w-full border-b border-[#D3DAEE]'}>
        <div className={'flex flex-row w-full justify-between gap-5'}>
          <div className={'flex w-full flex-col gap-3'}>
            <div className={'flex flex-1 w-full gap-2 items-center'}>
              {customImageComponent(
                rider.riderProfilePicture,
                rider.riderName,
                'w-[45px] h-[45px] avatarImg rounded object-cover'
              )}
              <div>
                <p className={'text-[#122B4680] text-[14px]'}>Rider</p>
                <h4 className={'text-[#122B46] font-normal text-[16px]'}>{rider.riderName}</h4>
              </div>
            </div>
            <div className={'flex flex-1 gap-2 items-center'}>
              {customImageComponent(
                rider.horseProfilePicture,
                rider.horseName,
                'w-[45px] h-[45px] avatarImg rounded object-cover'
              )}
              <div>
                <p className={'text-[#122B4680] text-[14px]'}>Horse</p>
                <h4 className={'text-[#122B46] font-normal text-[16px]'}>{rider.horseName}</h4>
              </div>
            </div>
          </div>
          <div className={'flex flex-col gap-3'}>
            <div className={'flex gap-2 justify-end items-center'}>
              <div>
                <p className={'text-[#122B4680] whitespace-nowrap text-right text-[14px]'}>
                  Back Number
                </p>
                <h4
                  className={'text-[#122B46] whitespace-nowrap text-right font-normal text-[16px]'}
                >
                  {rider.backNumber || 0}
                </h4>
              </div>
              <div
                className={
                  'w-[45px] h-[45px] bg-[#F7074F0D] rounded flex items-center justify-center'
                }
              >
                <IconBackNumber />
              </div>
            </div>
            <div className={'flex gap-2 justify-end items-center'}>
              <div>
                <p className={'text-[#122B4680] text-right whitespace-nowrap text-[14px]'}>
                  Entries
                </p>
                <h4
                  className={'text-[#122B46] text-right whitespace-nowrap font-normal text-[16px]'}
                >
                  {rider.classes.length} classes
                </h4>
              </div>
              <div
                className={
                  'w-[45px] h-[45px] bg-[#F7074F0D] rounded flex items-center justify-center'
                }
              >
                <IconClasses />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
