import React from 'react'

export const IconPrize = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="22" fill="none" viewBox="0 0 16 22">
      <path
        fill="#122B46"
        d="M7.984.01a.75.75 0 00-.511.218l-1.23 1.216-1.675-.439a.75.75 0 00-.914.528L3.2 3.203l-1.669.454a.75.75 0 00-.527.913l.438 1.675-1.217 1.23a.75.75 0 000 1.055l1.217 1.23-.44 1.674a.75.75 0 00.53.914l1.668.455.307 1.123a.751.751 0 00-.006.096v7.228a.75.75 0 001.166.624l3.195-2.13a.245.245 0 01.278 0l3.195 2.13a.75.75 0 001.166-.624v-7.228a.747.747 0 00-.006-.096l.307-1.123 1.668-.455a.75.75 0 00.528-.913l-.438-1.674 1.216-1.23a.75.75 0 000-1.055l-1.216-1.23.439-1.675a.75.75 0 00-.528-.914l-1.67-.455-.454-1.669a.75.75 0 00-.915-.528l-1.673.44L8.528.227A.75.75 0 007.983.01zM8 1.817l1.007.996a.75.75 0 00.717.192l1.37-.36.373 1.367a.75.75 0 00.526.525l1.365.373-.36 1.369a.75.75 0 00.193.717l.996 1.008-.996 1.007a.75.75 0 00-.192.718l.36 1.369-1.367.372a.75.75 0 00-.526.526l-.372 1.366-1.37-.359a.75.75 0 00-.717.191L8 14.19l-1.007-.996a.75.75 0 00-.718-.192l-1.369.358-.373-1.365a.75.75 0 00-.526-.526l-1.366-.372.36-1.37a.75.75 0 00-.193-.717l-.996-1.007.996-1.008a.75.75 0 00.193-.718l-.36-1.369 1.367-.372a.75.75 0 00.526-.526l.372-1.365 1.37.36a.75.75 0 00.717-.193L8 1.816zM6.242 14.56l1.23 1.216a.75.75 0 001.055 0l1.23-1.216 1.243.326v4.962l-2.03-1.353a1.754 1.754 0 00-1.94 0L5 19.85v-4.962l1.242-.326z"
      ></path>
    </svg>
  )
}
