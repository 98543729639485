import React, { useState } from 'react'
import { IUserInterface } from '../../models/users/user.interface'
import { MODAL_CONSTS } from '../../const/modal-const'
import MainModal from './common/MainModal'
import { CONST } from '../../const/const'
import { IRider } from '../../types/entryRider'
import {
  SwitchHorseHeader,
  SelectRiderClass,
  SelectNewHorse,
  SetTeamMembers,
  INewMembers,
  THandlerMemberType,
} from './components/switchHorse'
import FirestoreService from '../../services/firestoreService'
import { getConvertedData } from '../../models/interface.helper'
import { EventReviewPublishModel } from '../../models/event-review-publish/event-review-publish.model'
import useToasterHelper from '../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../const/messages-const'
import { IEventReviewPublish } from '../../models/event-review-publish/event-review-publish.interface'
import ViewsLoader from '../loader/ViewsLoader'
import { RegistrationFeesType } from '../../models/event-fees/event-fees.interface'
import { HorseModel } from '../../models/horse/horse.model'
import { IHorseData } from '../../models/horse/horse.interface'
import { UserModel } from '../../models/users/user.model'
import { IOption } from '../inputs/InputNewMemberRow'
import { Confirm } from './components/switchHorse/Confirm'
import { where } from 'firebase/firestore'
import { RegistrationByDayModel } from '../../models/registrations-by-day/registrationByDay.model'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { IRiderTeamMemberInterface } from '../../models/rider-team-member/riderTeamMember.interface'
import { IDocumentList } from '../../fakeData/fakeDocumentList'
import helpers from '../../commonHelpers/helpers'
import { CustomError } from '../../helpers/helpers'
import { RiderTeamMemberModel } from '../../models/rider-team-member/riderTeamMember.model'
import { IUserDocument } from '../../models/user-documents/user-documents.interface'
import { UserDocumentModel } from '../../models/user-documents/user-documents.model'
import { getFilteredPaperworks } from '../../helpers/documents'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const TEAM_MEMBER_ROLES = CONST.UI.TEAM_MEMBERS_ROLES

const FILE_NAME = 'addTeamMemberToPaperwork'

const ROLES = Object.values(TEAM_MEMBER_ROLES)
  .filter((role) => role !== TEAM_MEMBER_ROLES.RIDER)
  .map((value) => ({
    value,
    label: value,
  }))

type IProps = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    rider: IRider
    cb: (horse: IHorseData) => void
  }
}

const HORSES_TABLE = CONST.DATA.FIRESTORE.V01.COLLECTIONS.HORSES

const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

enum EScreens {
  loader,
  entry,
  horse,
  teamMember,
  confirm,
}

export const SwitchHorse: React.FC<IProps> = (props) => {
  const [step, setStep] = useState<EScreens>(EScreens.loader)
  // const [loading, setLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [eventData, setEventData] = useState<IEventReviewPublish | null>(null)
  const [entries, setEntries] = useState<RegistrationFeesType[]>([])
  const [selectedEntries, setSelectedEntries] = useState<RegistrationFeesType[]>([])
  const toastFunctions = useToasterHelper()
  const [isBtnNextDisabled, setIsBtnNextDisabled] = useState(true)
  const [horses, setHorses] = useState<IHorseData[]>([])
  const [selectedHorse, setSelectedHorse] = useState<IHorseData | null>(null)
  const [users, setUsers] = useState<IUserInterface[]>([])
  const { rider, cb } = props.dataToPassOn
  const [exibitor, setExibitor] = useState<IUserInterface | null>(null)
  const [newMembers, setNewMembers] = useState<INewMembers[]>([
    {
      member: null,
      role: null,
    },
  ])
  React.useEffect(() => {
    ;(() => {
      switch (step) {
        case EScreens.horse:
          setIsBtnNextDisabled(!selectedHorse)
          break
        case EScreens.entry:
          setIsBtnNextDisabled(!selectedEntries[0])
          break

        case EScreens.teamMember:
        case EScreens.confirm:
          setIsBtnNextDisabled(false)
          break

        case EScreens.loader:
        default:
          setIsBtnNextDisabled(true)
          break
      }
    })()
  }, [step, selectedEntries, selectedHorse])

  const getEntriesFromEvent = (event: IEventReviewPublish) => {
    const _entries: RegistrationFeesType[] = []

    if (event.EventFees.registrationFees && Array.isArray(event.EventFees.registrationFees)) {
      event.EventFees.registrationFees.forEach((fee) => {
        _entries.push(fee)
      })
    }

    return _entries
  }

  const getUsers = async () => {
    let users_: IUserInterface[] = []

    const userSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS.NAME)

    userSnaps.forEach((currDoc) => {
      users_.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
    })

    const currUser = users_.find((user) => user.id === props.dataToPassOn.rider.userId)
    setExibitor(currUser || null)
    setUsers(users_)
  }

  const getEventFromDb = React.useCallback(async () => {
    if (!props.dataToPassOn.rider) return toastFunctions.error({ message: 'Rider does not exist' })
    try {
      const publishedEventInDbSnapShot = await FirestoreService.getItem(
        COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
        props.dataToPassOn.rider.eventId || ''
      )
      const convertedData = getConvertedData(
        EventReviewPublishModel.fromFirestoreDoc(publishedEventInDbSnapShot).toObject()
      )

      const _horses: IHorseData[] = []

      const allHorse = await FirestoreService.filterItems(HORSES_TABLE.NAME, [])

      allHorse.docs.forEach((doc) => {
        let data = HorseModel.fromFirestoreDoc(doc).toObject()
        _horses.push(data)
      })

      setHorses(_horses)

      const _entries = getEntriesFromEvent(convertedData)
        .filter((entr) => props.dataToPassOn.rider.classes.includes(entr.name || 'Registration'))
        .map((itm) => ({
          ...itm,
          name: itm.name || 'Registration',
        }))

      await getUsers()

      setEntries(_entries)
      setEventData(convertedData)
      if (_entries.length === 1) {
        setSelectedEntries(_entries)
        setStep(EScreens.horse)
      } else {
        setStep(EScreens.entry)
      }
    } catch (e) {
      console.log('=>(SwitchHorse.tsx:56) e', e)
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }
  }, [])

  React.useEffect(() => {
    getEventFromDb().then(() => null)
  }, [getEventFromDb])

  const entrySelectHandler = (entry: RegistrationFeesType) => {
    const _entries: RegistrationFeesType[] = [...selectedEntries]

    const index = _entries.findIndex((itm) => itm.uuid === entry.uuid)

    if (index >= 0) {
      _entries.splice(index, 1)
    } else {
      _entries.push(entry)
    }

    setSelectedEntries(_entries)
  }
  const horseSelectHandler = (item: IHorseData) => {
    let newSelectedHorse = selectedHorse ? { ...selectedHorse } : null

    if (newSelectedHorse?.id === item.id) {
      newSelectedHorse = null
    } else {
      newSelectedHorse = item
    }

    setSelectedHorse(newSelectedHorse)
  }

  const updateHandlerNewMember = (index: number, itm: IOption, type: THandlerMemberType) => {
    const copyNewMembers = [...newMembers]

    if (!copyNewMembers[index]) return null

    copyNewMembers[index][type] = itm

    setNewMembers(copyNewMembers)
  }

  const addHandlerNewMember = () => {
    const copyNewMembers = [...newMembers]

    copyNewMembers.push({
      member: null,
      role: null,
    })

    setNewMembers(copyNewMembers)
  }
  const removeMemberHandler = (index: number) => {
    const copyNewMembers = [...newMembers]
    copyNewMembers.splice(index, 1)
    setNewMembers(copyNewMembers)
  }
  const createRiderTeamMember = async (ridersTeamMember: IRiderTeamMemberInterface) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.RIDER_TEAM_MEMBER.NAME, ridersTeamMember))
        ?.id ?? null
    )
  }

  const createUserDocuments = async (userDocuments: IUserDocument) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.USERS_DOCUMENTS.NAME, userDocuments))?.id ??
      null
    )
  }

  const savePaperworkTabData = async ({
    filteredPaperworkDocuments,
    ridersTeamMembersToAdd,
    registeredUser,
    signatoryDefaultRole,
  }: {
    ridersTeamMembersToAdd: IRiderTeamMemberInterface[]
    filteredPaperworkDocuments: IDocumentList
    registeredUser: IUserInterface
    signatoryDefaultRole: string
  }) => {
    let id: string | null = null

    if (ridersTeamMembersToAdd && ridersTeamMembersToAdd.length > 0) {
      let paperworkTabDataNotFilled = ridersTeamMembersToAdd.filter(
        (ridersTeamMember) => !ridersTeamMember.teamMemberRole || !ridersTeamMember.teamMemberId
      )

      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        paperworkTabDataNotFilled: paperworkTabDataNotFilled && !paperworkTabDataNotFilled.length,
      })

      if (emptyVarName) {
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'savePaperworkTabData',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
          message: 'Please change empty Rider or Role.',
        })
      }
    }

    try {
      // Add
      await helpers.asyncWhileLoop({
        loopCount: ridersTeamMembersToAdd?.length ?? 0,
        functionToFire: async (currIndex) => {
          id = await createRiderTeamMember(
            new RiderTeamMemberModel(ridersTeamMembersToAdd![currIndex]).toFirestore()
          )
          ridersTeamMembersToAdd![currIndex] = getConvertedData({
            ...ridersTeamMembersToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      const userDocumentsToAdd: IUserDocument[] = []

      for (const document of filteredPaperworkDocuments) {
        if (ridersTeamMembersToAdd) {
          for (const ridersTeamMember of ridersTeamMembersToAdd) {
            const eventsSnapshot = await FirestoreService.filterItems(
              COLLECTIONS.USERS_DOCUMENTS.NAME,
              [
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.SIGNATORY_ID.KEY,
                  '==',
                  ridersTeamMember.teamMemberId
                ),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY,
                  '==',
                  props.dataToPassOn.rider!.eventId
                ),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.RIDER_ID.KEY,
                  '==',
                  ridersTeamMember.riderId
                ),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.DOCUMENT_NAME.KEY,
                  '==',
                  document.document_name
                ),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.SIGNATORY_DEFAULT_ROLE.KEY,
                  '==',
                  ridersTeamMember.teamMemberRole
                ),
              ]
            )

            if (eventsSnapshot.size <= 0) {
              userDocumentsToAdd.push(
                getConvertedData({
                  eventId: props.dataToPassOn.rider!.eventId!,
                  u: false,
                  status: 'Not Signed',
                  riderId: registeredUser.id!,
                  activityUser: rider.id!,
                  riderName: registeredUser.userName,
                  signatoryId: registeredUser.id!,
                  signatoryProfilePicture: registeredUser.userProfilePicture!,
                  mailLog: [],
                  roles: ROLES,
                  eventLogo: eventData!.EventDetails.eventLogo!,
                  documentOwner: registeredUser.id!,
                  signatoryName: registeredUser.userName!,
                  documentName: document.key!,
                  documentUrl: document.document_image[0]!,
                  documentOriginalName: document.document_name!,
                  documentNameAsPerPdf: ``!,
                  eventName: eventData!.EventDetails!.competitionName!,
                  competitorId: registeredUser?.id!,
                  signatoryDefaultRole,
                  signatoryEmail: registeredUser.userEmail,
                  reminder: false,
                  registrationDocId: rider.registrationDocId,
                  registrationByDayDocId: rider.registrationDocId,
                  riderTeamMemberDocId: registeredUser.id ?? null,
                  pageNumberToSignOn: null,
                  coordinatesToSignOn: { x: null, y: null },
                })
              )
            }
          }
        }
      }
      if (userDocumentsToAdd.length > 0) {
        await helpers.asyncWhileLoop({
          loopCount: userDocumentsToAdd?.length ?? 0,
          functionToFire: async (currIndex) => {
            id = await createUserDocuments(
              new UserDocumentModel(userDocumentsToAdd![currIndex]).toFirestore()
            )
          },
        })
      }

      return true
    } catch (error: any) {
      helpers.logger({ message: `${error} in savePaperworkTabData` })
    }
  }

  const switchHandler = async () => {
    if (!eventData) return null
    try {
      setLoading(true)
      const eventRegisteredUsers: IRegistrationByDayInterface[] = []

      const registeredUsersSnap = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        [where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', rider.eventId)]
      )

      registeredUsersSnap.forEach((user) => {
        const registrationByDay = getConvertedData(
          RegistrationByDayModel.fromFirestoreDoc(user).toObject()
        )

        const hasClass = selectedEntries.find(
          (entr) => entr.name === registrationByDay.registrationByDayName
        )

        if (
          hasClass &&
          registrationByDay.riderId === rider.riderId &&
          registrationByDay.horseId === rider.horseId
        ) {
          eventRegisteredUsers.push(registrationByDay)
        }
      })

      const universalOrganizerWaver = !!eventData.EventPaperwork.universalOrganizerWaver
      const USEFWaiverAndReleaseOfLiability =
        !!eventData.EventPaperwork.USEFWaiverAndReleaseOfLiability
      const USEFEntryAgreement = !!eventData.EventPaperwork.USEFEntryAgreement
      const USDFWaiverAndReleaseOfLiability =
        !!eventData.EventPaperwork.USDFWaiverAndReleaseOfLiability
      const USEAWaiverAndReleaseOfLiability =
        !!eventData.EventPaperwork.USEAWaiverAndReleaseOfLiability
      const USHJAWaiverAndReleaseOfLiability =
        !!eventData.EventPaperwork.USHJAWaiverAndReleaseOfLiability

      if (exibitor && selectedHorse) {
        if (rider) {
          const user = users.find((u) => u.id === rider.riderId)

          if (!user) return null

          const teamMember: IRiderTeamMemberInterface = new RiderTeamMemberModel({
            mailLog: [],
            userId: exibitor?.id,
            userHorseMappingDocId: null,
            eventId: rider.eventId!,
            horseId: selectedHorse?.id!,
            horseName: selectedHorse.horseName,
            horseProfilePicture: selectedHorse.horseProfilePicture ?? null,
            riderDob: rider.riderDob,
            riderId: rider.riderId,
            riderName: rider.riderName!,
            eventName: rider.eventName ?? null,
            registrationDocId: rider.registrationDocId ?? null,
            registrationByDayDocId: rider.registrationDocId ?? null,
            registrationByDayUuid: '',
            teamMemberId: rider.id ?? null,
            teamMemberDob: rider.riderDob ?? null,
            teamMemberName: rider.riderName!,
            teamMemberRole: TEAM_MEMBER_ROLES.RIDER,
            teamMemberEmail: rider.riderEmail ?? null,
            teamMemberProfilePicture: rider.riderProfilePicture ?? null,
          }).toObject()

          await savePaperworkTabData({
            filteredPaperworkDocuments: getFilteredPaperworks({
              universalOrganizerWaver,
              USEFWaiverAndReleaseOfLiability,
              USEFEntryAgreement,
              USDFWaiverAndReleaseOfLiability,
              USEAWaiverAndReleaseOfLiability,
              USHJAWaiverAndReleaseOfLiability,
            }),
            registeredUser: user,
            ridersTeamMembersToAdd: [teamMember],
            signatoryDefaultRole: teamMember.teamMemberRole || '',
          })
        }

        for (let newTeamMember of newMembers) {
          const user = users.find((u) => u.id === newTeamMember.member?.value)

          if (user && newTeamMember.member && newTeamMember.role) {
            const teamMember: IRiderTeamMemberInterface = new RiderTeamMemberModel({
              mailLog: [],
              userId: exibitor?.id,
              userHorseMappingDocId: null,
              eventId: rider.eventId!,
              horseId: selectedHorse?.id!,
              horseName: selectedHorse.horseName,
              horseProfilePicture: selectedHorse.horseProfilePicture ?? null,
              riderDob: user.userDOB,
              riderId: user.id,
              riderName: user.userName!,
              eventName: rider.eventName ?? null,
              registrationDocId: rider.registrationDocId ?? null,
              registrationByDayDocId: rider.registrationDocId ?? null,
              registrationByDayUuid: '',
              teamMemberId: user.id ?? null,
              teamMemberDob: user.userDOB ?? null,
              teamMemberName: user.userName,
              teamMemberRole: newTeamMember?.role?.value || '',
              teamMemberEmail: user.userEmail ?? null,
              teamMemberProfilePicture: user.userProfilePicture ?? null,
            }).toObject()

            await savePaperworkTabData({
              filteredPaperworkDocuments: getFilteredPaperworks({
                universalOrganizerWaver,
                USEFWaiverAndReleaseOfLiability,
                USEFEntryAgreement,
                USDFWaiverAndReleaseOfLiability,
                USEAWaiverAndReleaseOfLiability,
                USHJAWaiverAndReleaseOfLiability,
              }),
              registeredUser: user,
              ridersTeamMembersToAdd: [teamMember],
              signatoryDefaultRole: teamMember.teamMemberRole || '',
            })
          }
        }
      }
      for (let regUser of eventRegisteredUsers) {
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.V01.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
          regUser.id,
          {
            horseId: selectedHorse?.id,
            horseName: selectedHorse?.horseName,
            horseProfilePicture: selectedHorse?.horseProfilePicture,
          }
        )
      }

      if (selectedHorse) cb(selectedHorse)
      // console.log('=>(SwitchHorse.tsx:295) eventRegisteredUsers', eventRegisteredUsers)
    } catch (e) {
      console.log('=>(SwitchHorse.tsx:321) e', e)
    } finally {
      setLoading(false)
    }
  }

  const renderModalBody = () => {
    switch (step) {
      case EScreens.loader:
        return (
          <>
            <ViewsLoader
              className="flex items-center w-full justify-center !h-[300px]"
              size="xl"
              color="red"
            />
          </>
        )
      case EScreens.entry:
        return (
          <SelectRiderClass
            selectedEntries={selectedEntries}
            entries={entries}
            selectHandler={entrySelectHandler}
          />
        )
      case EScreens.teamMember:
        return (
          <SetTeamMembers
            newMembers={newMembers}
            users={users.map((u) => {
              const label =
                !u.userFirstName && !u.userLastName
                  ? u.userEmail || u.userName
                  : `${u.userFirstName || ''} ${u.userLastName || ''}`

              return {
                value: u.id,
                label: label || '',
              }
            })}
            currentRider={props.dataToPassOn.rider}
            addHandlerNewMember={addHandlerNewMember}
            updateHandlerNewMember={updateHandlerNewMember}
            removeMemberHandler={removeMemberHandler}
            roles={ROLES}
          />
        )

      case EScreens.horse:
        return (
          <SelectNewHorse
            horses={horses}
            selectedHorse={selectedHorse}
            selectHandler={horseSelectHandler}
          />
        )
      case EScreens.confirm:
        return (
          <Confirm
            selectedHorse={selectedHorse!}
            selectedEntries={selectedEntries!}
            rider={props.dataToPassOn.rider}
            newTeamMembers={newMembers}
          />
        )
      default:
        return (
          <>
            <p className={'text-black py-4'}>{MESSAGES_CONST.SOMETHING_WENT_WRONG}</p>
          </>
        )
    }
  }

  return (
    <>
      <MainModal
        title="Switch horse"
        show={true}
        type="SWITCH_HORSE"
        size="xl"
        titleClassName="!font-normal"
        buttons={[
          {
            label: step === EScreens.confirm ? 'SWITCH horse' : 'Next >',
            bgClass: 'bg-SeabiscuitMainThemeColor !w-full',
            loading,
            onClick: () => {
              if (step === EScreens.confirm) {
                switchHandler()
                return null
              }
              setStep((v) => v + 1)
              return null
            },
            className: 'outline-none w-full',
            textClass: 'text-white',
            disabled: isBtnNextDisabled,
          },
          {
            label: 'Back',
            bgClass: 'bordered !w-full',
            loading,
            onClick: () => {
              setStep((v) => v - 1)
            },
            className: 'w-full',
            textClass: 'text-black',
            disabled:
              (entries.length === 1 && step === EScreens.horse) ||
              step === EScreens.entry ||
              loading,
          },
          {
            label: 'CANCEL',
            loading,
            disabled: loading,
            bgClass: 'bg-SeabiscuitLightThemeColor',
            className: 'outline-none',
            borderClass: 'border border-transparent',
            textClass: 'text-SeabiscuitLightTextColor !w-full',
            onClick: () => props.handleModal(false, MODAL_CONSTS.SWITCH_HORSE),
          },
        ]}
      >
        <SwitchHorseHeader rider={props.dataToPassOn.rider} />

        {renderModalBody()}
      </MainModal>
    </>
  )
}
