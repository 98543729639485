import moment from 'moment'
import { useEffect, useState } from 'react'
import { HorseModel } from '../../../../../../models/horse/horse.model'
import { useAppSelector } from '../../../../../../store/hooks'
import { getSelectedHorse } from '../../../../../../store/horses/horseSlice'

import ACTIVE_TAB_SECTION_CONST from '../consts'
import IProfileCard from '../../types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const HorseDetails = (_: IProfileCard['IHorseDetailsTabProps']) => {
  // Hooks and vars
  const selectedHorse = useAppSelector(getSelectedHorse)

  const [structuredHorseData, setStructuredHorseData] = useState<(string | string[])[]>([])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    const selectedHorse_ = new HorseModel(selectedHorse ?? undefined)
    setStructuredHorseData([
      selectedHorse_?.horseName ?? '',
      selectedHorse_?.horseBreed ?? '',
      selectedHorse_?.horseHeight + ' h',
      `${moment().diff(moment(selectedHorse_?.horseDob), 'years')} Years Old`,
      selectedHorse_?.horseGender,
      selectedHorse_?.horseDiscipline,
      'zone ' + selectedHorse_?.horseZone,
      selectedHorse_?.horseColor,
      selectedHorse_?.horseMarking,
    ])
  }, [selectedHorse])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getField = (args: IProfileCard['IGetFieldFnProps']) => {
    const { icon, text_to_show } = args ?? {}

    return (
      <>
        {typeof icon === 'string' ? (
          <span className="mx-4 mr-6 absolute w-6 h-6">
            <img src={icon} alt="icon" className="object-cover w-full h-full" />
          </span>
        ) : (
          icon
        )}

        <input
          type="text"
          disabled={true}
          readOnly={true}
          value={text_to_show ?? ''}
          className={`border border-transparent text-[14px] text-SeabiscuitDark200ThemeColor h-[56px] capitalize ${text_to_show ? 'pl-0' : 'pl-[62px]'} w-[100%] pl-[62px]`}
        />
      </>
    )
  }

  return (
    <>
      <div className="heading mb-3 font-md text-SeabiscuitDark200ThemeColor">HORSE DETAILS</div>
      {ACTIVE_TAB_SECTION_CONST.ICONS.map((data, index) => {
        return (
          <div
            key={`horseDetail${index}`}
            className="relative flex mb-2 items-center border-solid border-[#D3DAEE] border overflow-hidden rounded-xl"
          >
            {getField({ icon: data, text_to_show: structuredHorseData?.[index] })}
          </div>
        )
      })}
    </>
  )
}

export default HorseDetails
