// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React from 'react'
import generateFieldValidation from '../../validation/EventPrizeListFormBuilderUtilValidation'

interface EventPriceListFormBuilderUtilSubSectionRowProps {
  subSection: any
  register: any
  disciplineIndex: number
  divisionIndex: number
  sectionIndex: number
  subSectionIndex: number
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormBuilderUtilSectionRow: React.FC<
  EventPriceListFormBuilderUtilSubSectionRowProps
> = (props) => {
  return (
    <div className="flex items-center">
      <div className="mb-4 ml-12 relative">
        <br />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <input
          type="text"
          className="bg-SeabiscuitGrayThemeColor rounded-lg w-[468px] h-12 pl-8 text-xl border-none"
          placeholder="30"
          {...props.register(
            `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionSubCode.value`,
            {
              validate: generateFieldValidation(props.subSection.subSectionSubCode),
            }
          )}
        />
      </div>

      <div className="mb-4 ml-4 relative">
        <br />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <input
          type="text"
          className="bg-SeabiscuitGrayThemeColor rounded-lg w-[165px] h-12 pl-8 text-xl border-none"
          placeholder="30"
          {...props.register(
            `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionUnderSaddleClass.value`,
            {
              validate: generateFieldValidation(props.subSection.subSectionUnderSaddleClass),
            }
          )}
        />
      </div>

      <div className="mb-4 ml-4 relative">
        <br />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <input
          type="text"
          className="bg-SeabiscuitGrayThemeColor rounded-lg w-[165px] h-12 pl-8 text-xl border-none"
          placeholder="30"
          {...props.register(
            `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionHandyClass.value`,
            {
              validate: generateFieldValidation(props.subSection.subSectionHandyClass),
            }
          )}
        />
      </div>

      <div className="mb-4 ml-4 relative">
        <br />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <input
          type="text"
          className="bg-SeabiscuitGrayThemeColor rounded-lg w-[165px] h-12 pl-8 text-xl border-none"
          placeholder="30"
          {...props.register(
            `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionConformationClass.value`,
            {
              validate: generateFieldValidation(props.subSection.subSectionConformationClass),
            }
          )}
        />
      </div>

      <div className="mb-4 ml-4 relative">
        <br />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <input
          type="text"
          className="bg-SeabiscuitGrayThemeColor rounded-lg w-[165px] h-12 pl-8 text-xl border-none"
          placeholder="30"
          {...props.register(
            `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionHeight.value`,
            {
              validate: generateFieldValidation(props.subSection.subSectionHeight),
            }
          )}
        />
      </div>

      <div className="mb-4 ml-4 relative">
        <br />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <input
          type="text"
          className="bg-SeabiscuitGrayThemeColor rounded-lg w-[165px] h-12 pl-8 text-xl border-none"
          placeholder="30"
          {...props.register(
            `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionTotalPriceMoney.value`,
            {
              validate: generateFieldValidation(props.subSection.subSectionTotalPriceMoney),
            }
          )}
        />
      </div>

      <div className="mb-4 ml-4 relative">
        <br />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <input
          type="text"
          className="bg-SeabiscuitGrayThemeColor rounded-lg w-[165px] h-12 pl-8 text-xl border-none"
          placeholder="30"
          {...props.register(
            `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionJudge.value`,
            {
              validate: generateFieldValidation(props.subSection.subSectionJudge),
            }
          )}
        />
      </div>
    </div>
  )
}

EventPrizeListFormBuilderUtilSectionRow.defaultProps = {
  subSection: null,
  register: null,
}

export default EventPrizeListFormBuilderUtilSectionRow
