// Component imports
import ModalComponent from '../events/views/details/global/modalComponent'

// Constants
import { where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import { EventModel } from '../../models/events/event.model'
import { ILegalClauseInterface } from '../../models/user-legal-clauses/user-legal-clauses.interface'
import { UserLegalClausesModel } from '../../models/user-legal-clauses/user-legal-clauses.model'
import FirestoreService from '../../services/firestoreService'
import ViewsLoader from '../loader/ViewsLoader'
import { UserModel } from '../../models/users/user.model'

const AnnexCard = ({
  icon,
  heading,
  content,
}: {
  icon: string
  heading: string
  content: string | any
}) => {
  return (
    <>
      <div className="border border-[#D3DAEE] rounded-xl flex p-4 my-2 mr-2">
        <div className="w-8 h-8 px-1 py-1 shrink-0">
          <img className="w-full object-cover" src={icon} alt="icon" />
        </div>
        <div className="text-SeabiscuitDark200ThemeColor pl-4 leading-7">
          <span className="font-semibold mr-1">{heading} :</span>
          {content}
        </div>
      </div>
    </>
  )
}

// Types
type IReviewAnnexProps = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const CLAUSE_TABLE = CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_LEGAL_CLAUSES

const ReviewAnnex = ({ show, dataToPassOn, handleModal }: IReviewAnnexProps) => {
  // Hooks and vars
  const toastFunction = useToasterHelper()

  const [allClauses, setAllClauses] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [ownerName, setOwnerName] = useState<string>('')

  const closeModal = () => {
    handleModal(false, MODAL_CONSTS.REVIEW_ANNEX)
    if (dataToPassOn?.dataToPassOn) {
      handleModal(true, MODAL_CONSTS.SIGN_DOCUMENT, dataToPassOn?.dataToPassOn)
    }
  }

  const getAllClauses = async (ownerId: string) => {
    const data: any[] = []
    const clauses = await FirestoreService.filterItems(CLAUSE_TABLE.NAME, [
      where(CLAUSE_TABLE.FIELDS.OWNER_ID.KEY, '==', ownerId),
    ])

    if (clauses.size) {
      clauses?.forEach((doc) => {
        let clauseDataModel = UserLegalClausesModel.fromFirestoreDoc(doc).toObject()
        data.push(clauseDataModel)
      })
      return data
    }
  }

  const handleOwnerInfo = async (eventId: string) => {
    setLoading(true)
    const getownerdoc = await FirestoreService.getItem(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS.NAME,
      eventId
    )
    const getowner = EventModel.fromFirestoreDoc(getownerdoc).toObject()

    if (!getowner.owner) return

    const getownerinfo = await FirestoreService.getItem(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
      getowner.owner
    )
    const getownerdata = UserModel.fromFirestoreDoc(getownerinfo).toObject()
    setOwnerName(getownerdata.userFirstName + getownerdata.userLastName)

    if (getowner) {
      getAllClauses(getowner?.owner as string)
        .then((res) => {
          setLoading(false)
          setAllClauses(res ?? [])
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  useEffect(() => {
    if (!dataToPassOn?.data?.eventId) {
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      return
    }
    handleOwnerInfo(dataToPassOn?.data?.eventId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToPassOn])

  return (
    <ModalComponent
      title={`Annex for ${dataToPassOn?.data?.eventName ?? 'Unknown'}`}
      show={show}
      fullScreen={true}
      onHide={closeModal}
      type={MODAL_CONSTS.REVIEW_ANNEX}
      size="md"
    >
      <div className="h-[650px] overflow-y-auto">
        <p className="text-SeabiscuitDark200ThemeColor leading-7">
          Pegasus generates a General Liability Waiver for all events. Event organizers add clauses
          to this liability waiver that are unique to their event and/or property. These unique
          clauses will be appended to the end of the General Liability Waiver as an annex.
        </p>
        <p className="text-SeabiscuitDark200ThemeColor mb-4 leading-7 mt-4">
          You can review these unique clauses below.
        </p>
        {allClauses && allClauses.length ? (
          allClauses.map((clause: ILegalClauseInterface, index) => {
            return (
              <AnnexCard
                key={`${JSON.stringify(clause)}${index}`}
                icon="/assets/og_icons/JudgeB.svg"
                heading={clause.title}
                content={clause.description}
              />
            )
          })
        ) : (
          <>
            {' '}
            {loading ? (
              <ViewsLoader
                className="flex items-center w-full justify-center min-h-[30vh] mt-3"
                size="lg"
                color="red"
              />
            ) : (
              <span className="w-[100%] mt-4 flex items-center justify-center ">
                No annex is added by an {ownerName}{' '}
              </span>
            )}
          </>
        )}
      </div>
      <div className="flex justify-center">
        <div
          onClick={closeModal}
          className="w-60 bg-[#1F41731A] cursor-pointer text-[#1F417380] p-4 uppercase mx-auto mt-6 rounded-xl text-center"
        >
          Close
        </div>
      </div>
    </ModalComponent>
  )
}

export default ReviewAnnex
