import clsx from 'clsx'
import domToImage from 'dom-to-image'
import { DocumentData, QuerySnapshot, where } from 'firebase/firestore'
import moment from 'moment'
import { StandardFonts } from 'pdf-lib'
import { useCallback, useEffect, useState } from 'react'
import helpers, { urlToBlob } from '../../../../../../commonHelpers/helpers'
import ViewsLoader from '../../../../../../components/loader/ViewsLoader'
import MainModal from '../../../../../../components/modals/common/MainModal'
import { CONST } from '../../../../../../const/const'
import { IMAGE_CONSTS } from '../../../../../../const/image-const'
import { MESSAGES_CONST } from '../../../../../../const/messages-const'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'
import {
  CustomError,
  capitalize,
  createString,
  fileObjToBase64,
  generateName,
  getUserFullName,
} from '../../../../../../helpers/helpers'
import { IAssignedTicket } from '../../../../../../models/assigned-tickets/assigned-tickets.interface'
import { AssignTicketsModal } from '../../../../../../models/assigned-tickets/assigned-tickets.model'
import { IEventDetails } from '../../../../../../models/event-details/event-details.interface'
import { IGuestInterface } from '../../../../../../models/guests/guest.interface'
import { GuestModel } from '../../../../../../models/guests/guest.model'
import { getConvertedData } from '../../../../../../models/interface.helper'
import { ModelBaseModel } from '../../../../../../models/model-base/model-base.model'
import { RecipientModel } from '../../../../../../models/recipients/recipients'
import { IRecipientInterface } from '../../../../../../models/recipients/recipients.interface'
import { IRegistrationTicketInterface } from '../../../../../../models/registration-tickets/registrationTicket.interface'
import { RegistrationTicketModel } from '../../../../../../models/registration-tickets/registrationTicket.model'
import { ITicketBuyer } from '../../../../../../models/ticket-buyers/ticket-buyers.interface'
import { IUserInterface } from '../../../../../../models/users/user.interface'
import { UserModel } from '../../../../../../models/users/user.model'
import EventService from '../../../../../../services/eventService'
import FirestoreService from '../../../../../../services/firestoreService'
import PdfService from '../../../../../../services/pdfService'

type IProps = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: ITicketBuyer
}

type IUser = {
  user: IUserInterface | null
  guest: IGuestInterface | null
  isPlatformUser: boolean
}

const UNKNOWN = 'Unknown'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const FILE_NAME = 'SpectatorTicketReceipt'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}
const total_arr = [
  { tab: 1, title: 'Total', value: '2,860', additional_class: '' },
  { tab: 2, title: 'Sales tax', value: '2,860', additional_class: '' },
  { tab: 3, title: 'Grand total', value: '2,860', additional_class: 'font-bold' },
]

const SpectatorTicketReceiptModal = (props: IProps) => {
  const dataToPassOn = props.dataToPassOn
  const toasterFunctions = useToasterHelper()

  console.log({ dataToPassOn })

  const [loading, setLoading] = useState(true)
  const [salesTax, setSalesTax] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [paymentPrice, setPaymentPrice] = useState(0)
  const [eventLogo, setEventLogo] = useState<string>('')
  const [recieptLoading, setRecieptLoading] = useState(false)
  const [eventLocation, setEventLocation] = useState<string>('')
  const [event, setEvent] = useState<IEventDetails | null>(null)
  const [userProfilePicture, setUserProfilePicture] = useState<string>('')
  const [assignedTickets, setAssignedTickets] = useState<IAssignedTicket[]>([])

  useEffect(() => {
    let cost = 0
    assignedTickets.forEach((data) => {
      cost += data.ticketPrice
    })

    setTotalPrice(new ModelBaseModel().getNum(cost))
    setSalesTax(new ModelBaseModel().getNum((cost / 100) * 2))
    setPaymentPrice(new ModelBaseModel().getNum(cost + (cost / 100) * 2))
  }, [assignedTickets])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const downloadReciept = async () => {
    let imageWidth = 0
    let imageHeight = 0

    setRecieptLoading(true)

    await new Promise((resolve) => {
      setTimeout(resolve, 500)
    })

    try {
      let receiptContainer = document.querySelector('#receipt')

      if (!receiptContainer)
        throw CustomError.somethingWentWrong({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: `receiptContainer is ${receiptContainer}`,
        })

      const base64Code = await domToImage.toPng(receiptContainer, {
        bgcolor: 'white',
      })
      const img = new Image()
      img.src = base64Code

      await new Promise((resolve) => {
        img.onload = function () {
          imageWidth = img.width
          imageHeight = img.height
          resolve(true)
        }
      })

      const pdfService = new PdfService({
        pageWidth: imageWidth + 100,
        pageHeight: imageHeight + 100,
        distanceBetweenLines: 20,
        pageVerticalStartPoint: imageWidth,
        pageHorizontalStartPoint: 45,
        pageHorizontalEndPoint: imageWidth,
        pageVerticalEndPoint: 80,
      })

      await pdfService.embedFont(StandardFonts.TimesRomanBold)
      const pdfPage = await pdfService.addNewPdfPage()
      const png = await pdfService.embedPng(base64Code)

      pdfPage.drawImage(png, {
        x: 50,
        y: 50,
        width: imageWidth,
        height: imageHeight,
      })

      const pdfBytes = await pdfService.generatedPdf
      const blob_ = new Blob([pdfBytes.buffer], { type: 'application/octet-stream' })
      const blobUrl = URL.createObjectURL(blob_)
      const downloadLink = document.createElement('a')

      downloadLink.href = blobUrl
      downloadLink.download = `Pegasus reciept-${dataToPassOn.id}.pdf`
      downloadLink.click()

      document.body.appendChild(img)
      document.body.removeChild(img)
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'downloadReciept',
          devMessage: error?.devMessage ?? error?.message,
        }),
      })

      toasterFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setRecieptLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getEventDetails = useCallback(
    async (user: IUser) => {
      let eventLogo: string | null = null
      let userProfilePicture_: string | null = null
      let user_: IUserInterface | IGuestInterface | null = null
      let userName: string | null = null

      if (user.isPlatformUser) {
        user_ = user.user
        userProfilePicture_ = user_?.userProfilePicture ?? null
        userName = getUserFullName(user_!)
      } else {
        user_ = user.guest
        userName = user.guest?.fullName ?? null
      }

      try {
        if (!dataToPassOn.eventId)
          throw CustomError.somethingWentWrong({
            ...customErrorProps,
            devMessage: `selectedRegistration?.eventId is ${dataToPassOn?.eventId}`,
          })

        const eventService = new EventService(dataToPassOn?.eventId)
        const event = await eventService.getPublishedEventDetails
        const eventLocation = await eventService.getEventLocation
        const eventOwner = await eventService.getEventOwnerDetails

        const eventName = await eventService.getEventKey('competitionName')

        if (dataToPassOn.isPlatformUser) {
          eventLogo = eventOwner?.userProfilePicture ?? null
        }

        let placeHolderImageUrl = !!!eventLogo
          ? `https://ui-avatars.com/api/?name=${generateName(eventName ?? UNKNOWN)}`
          : eventLogo

        let blob = await urlToBlob(placeHolderImageUrl)
        let base64Code = await fileObjToBase64(blob as any)
        eventLogo = (base64Code as any) ?? null

        placeHolderImageUrl = !!!userProfilePicture_
          ? `https://ui-avatars.com/api/?name=${generateName(userName ?? UNKNOWN)}`
          : userProfilePicture_
        blob = await urlToBlob(placeHolderImageUrl)
        base64Code = await fileObjToBase64(blob as any)
        userProfilePicture_ = (base64Code as any) ?? null

        setEventLogo(eventLogo ?? '')
        setUserProfilePicture(userProfilePicture_ ?? '')
        setEventLocation(eventLocation.combinedLocation)
        setEvent(event)
      } catch (error: any) {
        toasterFunctions.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })
        helpers.logger({
          message: error?.devMessage ?? error?.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [dataToPassOn.eventId, dataToPassOn.isPlatformUser, toasterFunctions]
  )

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const loadUser = useCallback(
    async (userId: ITicketBuyer['userId'], isPlatformUser: ITicketBuyer['isPlatformUser']) => {
      let user: IUser | null = null

      try {
        if (!userId) {
          throw new Error(`User id is ${userId}`)
        }

        if (isPlatformUser) {
          const userSnapshot = await FirestoreService.getItem(COLLECTIONS.USERS.NAME, userId)

          user = {
            isPlatformUser,
            guest: null,
            user: userSnapshot.exists()
              ? getConvertedData(UserModel.fromFirestoreDoc(userSnapshot))
              : null,
          }
        } else {
          const guestSnapshot = await FirestoreService.getItem(COLLECTIONS.GUESTS.NAME, userId)

          user = {
            isPlatformUser,
            guest: guestSnapshot.exists()
              ? getConvertedData(GuestModel.fromFirestoreDoc(guestSnapshot))
              : null,
            user: null,
          }
        }
      } catch (error: any) {
        toasterFunctions.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

        helpers.logger({
          message: CustomError.somethingWentWrong({
            ...customErrorProps,
            moduleName: 'loadUser',
            devMessage: error,
          }),
        })
      } finally {
        return user
      }
    },
    [toasterFunctions]
  )

  const getMergedAssignedTicketsWithNotCreated = async ({
    recipient,
    assignedTickets,
  }: {
    recipient: IRecipientInterface
    assignedTickets: IAssignedTicket[]
  }): Promise<IAssignedTicket[]> => {
    let unCreatedTicketCount = 0
    let createdItemsCount = 0
    let assignedTickets_: IAssignedTicket[] = []
    let registrationTickets: IRegistrationTicketInterface[] = []
    let registrationTicket: IRegistrationTicketInterface | null = null

    const registrationTicketSnapshots = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_TICKET.NAME,
      [
        where(COLLECTIONS.REGISTRATION_TICKET.FIELDS.RECIPIENT_ID.KEY, '==', recipient.recipientId),
        where(
          COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
          '==',
          recipient.registrationDocId
        ),
      ]
    )

    registrationTicketSnapshots.forEach((currRegistrationTicketSnapshot) => {
      registrationTicket = RegistrationTicketModel.fromFirestoreDoc(currRegistrationTicketSnapshot)
      registrationTickets.push(registrationTicket)
      unCreatedTicketCount = registrationTicket.remainingTicketsCount

      createdItemsCount = 0

      while (createdItemsCount < unCreatedTicketCount) {
        assignedTickets_.push({
          ...new AssignTicketsModal({
            ...registrationTicket,
            amountRefunded: 0,
            amountScratched: 0,
            scratchedOn: null,
            chargeId: null,
            isPlatformUser: true,
            ticketBuyerDocId: registrationTicket.userId,
            ticketTitle: registrationTicket.ticketTitle,
            userId: recipient?.recipientId ?? null,
            ticketBuyerName: recipient?.recipientName ?? null,
            ticketBuyerEmailId: null,
            paymentStatus: 'pending',
            ticketType: 'spectator',
            ticketHolderEmailId: null,
            registrationTicketDocId: null,
            updated: null,
            created: null,
            receipetUrl: null,
            ticketStatus: 'available',
            assignmentDate: null,
            isScratched: false,
            eventDocId: recipient?.eventId ?? null,
            checkInDate: null,
            spectatorTicketDocId: null,
            registrationDocId: null,
            ticketHolderName: null,
          }).toObject(),
        })
        createdItemsCount++
      }
    })

    assignedTickets_ = [...assignedTickets, ...assignedTickets_]

    return assignedTickets_
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getAssignedTickets = useCallback(async () => {
    let assignedTickets_: IAssignedTicket[] = []
    let recipient: IRecipientInterface | null = null
    let assignedTicketSnapshots: QuerySnapshot<DocumentData>

    try {
      if (dataToPassOn.type === 'spectator') {
        assignedTicketSnapshots = await FirestoreService.filterItems(
          COLLECTIONS.ASSIGNED_TICKETS.NAME,
          [
            where(
              COLLECTIONS.ASSIGNED_TICKETS.FIELDS.SEPECTATOR_TICKET_DOC_ID,
              'in',
              dataToPassOn.spectatorTicketsIds
            ),
            where(COLLECTIONS.ASSIGNED_TICKETS.FIELDS.TICKET_TYPE.KEY, '==', dataToPassOn.type),
          ]
        )
      } else {
        if (!dataToPassOn.recipientDocId) {
          throw new Error(`RecipientDocId is ${dataToPassOn.recipientDocId}`)
        }

        const recipientSnapshot = await FirestoreService.getItem(
          COLLECTIONS.RECIPIENT.NAME,
          dataToPassOn.recipientDocId!
        )

        recipient = RecipientModel.fromFirestoreDoc(recipientSnapshot)

        if (!recipientSnapshot.exists()) {
          throw new Error(`Recipient with docId: ${dataToPassOn.recipientDocId} does not exist`)
        }

        assignedTicketSnapshots = await FirestoreService.filterItems(
          COLLECTIONS.ASSIGNED_TICKETS.NAME,
          [
            where(COLLECTIONS.ASSIGNED_TICKETS.FIELDS.USER_ID, '==', recipient.recipientId),
            where(COLLECTIONS.ASSIGNED_TICKETS.FIELDS.TICKET_TYPE.KEY, '==', dataToPassOn.type),
          ]
        )
      }

      assignedTicketSnapshots.docs.forEach((curr) => {
        assignedTickets_.push(getConvertedData(AssignTicketsModal.fromFirestoreDoc(curr)))
      })

      if (dataToPassOn.type === 'register') {
        assignedTickets_ = await getMergedAssignedTicketsWithNotCreated({
          recipient: recipient!,
          assignedTickets: assignedTickets_,
        })
      }
    } catch (error: any) {
      toasterFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'getAssignedTickets',
          devMessage: error,
        }),
      })
    } finally {
      setAssignedTickets(assignedTickets_)
    }
  }, [
    dataToPassOn.recipientDocId,
    dataToPassOn.spectatorTicketsIds,
    dataToPassOn.type,
    toasterFunctions,
  ])

  const boot = useCallback(async () => {
    try {
      setLoading(true)
      const user = await loadUser(dataToPassOn.userId, dataToPassOn.isPlatformUser)
      if (user) {
        await getEventDetails(user)
      } else {
        toasterFunctions.error({
          message: 'User not found',
        })
      }
    } catch (error) {
      toasterFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setLoading(false)
    }
  }, [
    dataToPassOn.isPlatformUser,
    dataToPassOn.userId,
    getEventDetails,
    loadUser,
    toasterFunctions,
  ])

  useEffect(() => {
    boot()
  }, [dataToPassOn.userId, dataToPassOn.isPlatformUser, boot])

  useEffect(() => {
    getAssignedTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainModal
      show={true}
      title={'Receipt'}
      type="SPECTATOR_TICKET_RECEIPT"
      className={clsx('relative h-[70vh]', (loading || recieptLoading) && 'pointer-events-none')}
      titleClassName="text-base px-2"
      setHeightAsPerContent={true}
      size={'4xl'}
    >
      <>
        {recieptLoading || loading ? (
          <div className="absolute w-full h-full bg-white z-10 flex justify-center items-center">
            <ViewsLoader
              className="flex items-center w-full justify-center"
              size="xl"
              color="red"
            />
          </div>
        ) : null}
        <div id="receipt" className={clsx(recieptLoading && 'py-5')}>
          {!loading ? (
            <>
              {recieptLoading ? (
                <>
                  <div className="relative h-[70px] flex items-center opacity-[.8]">
                    <div className="mb-2 text-lg text-gray-400 absolute top-0">
                      Pegasus App Inc.
                    </div>
                    <img
                      src="/assets/Pegasus_app_logo.png"
                      alt="Pegasus app logo"
                      className="w-[70px] h-[70px] absolute right-0"
                    />
                    <hr className="bg-SeabiscuitMainThemeColor w-full h-[2px]" />
                  </div>

                  <div className="h-[120px]">
                    <h1 className="font-extrabold text-3xl mb-1">Receipt from Pegasus</h1>
                    <div className="text-xl">
                      Receipt ID:{' '}
                      {capitalize(createString(dataToPassOn.id), {
                        fullyCapitalize: true,
                      })}
                      . {moment(dataToPassOn?.purchasedOn).format('MMMM Do, YYYY')}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="flex justify-between mt-4">
                <div>
                  <div className="flex items-center my-2">
                    <img
                      className="rounded-md mr-4 h-9 w-9 object-cover"
                      src={eventLogo ?? IMAGE_CONSTS.PLACEHOLDERS.EVENT}
                      alt="eventImage"
                    />

                    <p className="text-md font-semibold text-SeabiscuitDark200ThemeColor">
                      {' '}
                      <div className="opacity-50 text-sm font-normal">Event</div>
                      {event?.competitionName ?? UNKNOWN}
                    </p>
                  </div>

                  <div className="flex items-center my-2">
                    <img
                      className="rounded-md mr-4 h-9 w-9 object-cover"
                      src={userProfilePicture}
                      alt="userIcon"
                    />

                    <p className="text-md text-SeabiscuitDark200ThemeColor capitalize gap-1">
                      <div className="opacity-50 text-xs">From </div>{' '}
                      {dataToPassOn.ticketBuyerName ?? UNKNOWN}
                    </p>
                  </div>

                  <div className="flex items-center my-3">
                    <span className="mr-2 flex items-center">
                      <img
                        className="bg-[#fef5f6] rounded-lg p-[0.4rem]"
                        src="/assets/cp_icons/Location-2.svg"
                        alt="locationIcon"
                      />
                    </span>
                    <p className="text-md text-SeabiscuitDark200ThemeColor ml-2 gap-1">
                      <div className="opacity-50 text-xs">
                        {moment(dataToPassOn?.purchasedOn).format('MMMM Do, YYYY')}{' '}
                      </div>{' '}
                      {eventLocation}
                    </p>
                  </div>
                </div>

                <div className="mb-4 flex-col justify-end">
                  <p
                    className="text-lg text-[#00B6aa] font-bold rounded-md flex justify-end"
                    // style={{
                    //     background: modalThemeColor
                    // }}>
                  >
                    ${dataToPassOn?.amountPaid?.toFixed(2)} paid
                  </p>

                  {!!dataToPassOn.receipetUrl && !recieptLoading ? (
                    <div className="flex justify-center mt-1 opacity-50">
                      <button
                        onClick={downloadReciept}
                        disabled={dataToPassOn?.paymentStatus !== 'paid'}
                        className="text-SeabiscuitDark200ThemeColor text-sm underline"
                      >
                        {/* <Tooltip
                                                title={<h1 className="tooltip_title">{`Download Reciept`}</h1>}
                                                placement="top"
                                                arrow
                                            >
                                                <button className="">
                                                    <img className="" src={imgSrc} alt="CalendarIcon" /> 
                                                </button>
                                            </Tooltip> */}
                        Download Receipt
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>

              {/* %%%%%%%%%%%%%%%%% */}

              <div className="h-[1px] bg-[#D3DAEE] my-4"></div>

              {assignedTickets.length ? (
                <>
                  {assignedTickets.map((currAssignedTicket, index) => {
                    return (
                      <div key={`displayData${index}`} className={`w-full flex`}>
                        <div
                          className={`w-[25%] text-[#122B46] min-w-[200px] mb-2 mx-1 text-nr text-[#122B46]-900 border-[#D3DAEE] rounded-md !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor p-3.5 bg-[#F6F7FB]`}
                        >
                          {currAssignedTicket?.ticketTitle ?? 'N/A'}
                        </div>

                        <div className="w-[37%] min-w-[270px] mx-1">
                          <input
                            className={`w-full mb-2  text-nr !text-[#122B46] outline-0 rounded-md !ring-0  p-4 disabled:bg-[#F6F7FB] text-start capitalize`}
                            disabled={true}
                            value={currAssignedTicket.ticketHolderName ?? 'N/A'}
                          />
                        </div>

                        <div className="w-[37%] min-w-[270px] mx-1">
                          <input
                            className={`w-full mb-2  text-nr !text-[#122B46] outline-0 rounded-md !ring-0  p-4 disabled:bg-[#F6F7FB] text-start border-[#D3DAEE] focus:border-[#122B46]`}
                            value={currAssignedTicket.ticketHolderEmailId ?? 'N/A'}
                            disabled={true}
                            placeholder="Enter email address..."
                          />
                        </div>

                        <div
                          className={`shrink-0 text-[#122B46] w-[160px] mb-2 mx-1 text-nr text-[#122B46]-900  border-[#D3DAEE] rounded-md !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor p-3.5 text-center bg-[#F6F7FB]`}
                        >
                          ${currAssignedTicket?.ticketPrice}
                        </div>
                      </div>
                    )
                  })}

                  {total_arr &&
                    total_arr.map((item, totalItemItem) => {
                      return (
                        <div className="flex" key={`totalItem${totalItemItem}`}>
                          <div
                            className={`grow text-[#122B46] min-w-[150px] mb-2 mx-1 text-nr text-[#122B46]-900   rounded-md !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor p-3.5 border-2-[#00B6AA] bg-[#00b6aa1a] ${item?.additional_class}`}
                          >
                            {item?.title}
                          </div>

                          <div
                            className={`shrink-0 text-[#122B46] w-[160px] mb-2 mx-1 text-nr text-[#122B46]-900 rounded-md !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor p-3.5 text-center border-2-[#00B6AA] bg-[#00b6aa1a] ${item?.additional_class}`}
                          >
                            {item.tab === 1
                              ? `$${totalPrice.toFixed(2)}`
                              : item.tab === 2
                                ? `$${salesTax.toFixed(2)}`
                                : `$${paymentPrice.toFixed(2)}`}
                          </div>
                        </div>
                      )
                    })}
                </>
              ) : (
                <div className="text-[14px] text-SeabiscuitLightParagraphTextColor mt-2">
                  You dont have any tickets whose amount is not paid
                </div>
              )}
            </>
          ) : null}
        </div>
      </>
    </MainModal>
  )
}

export default SpectatorTicketReceiptModal
