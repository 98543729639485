import TimeLib from '../../../../lib/Time'
import { IRegistrationTeamInterface } from '../../../../models/registeration-teams/registration-teams.interface'

export const DATA_FIRESTORE_V01_REGISTRATION_TEAMS = {
  NAME: 'v01_registration_teams',
  FIELDS: {
    V: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_ID: {
      KEY: 'eventId',
      VALUES: {
        DEFAULT: null,
      },
    },

    REGISTRATION_DOC_ID: {
      KEY: 'registrationDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_NAME: {
      KEY: 'userName',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_NAME_N_GRAM: {
      KEY: 'userNameNGram',
      VALUES: {
        DEFAULT: [],
      },
    },

    USER_ID: {
      KEY: 'userId',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_NAME: {
      KEY: 'eventName',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_PROFILE_PICTURE: {
      KEY: 'userProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    MEMBER_ID: {
      KEY: 'memberId',
      VALUES: {
        DEFAULT: null,
      },
    },

    MEMBER_NAME: {
      KEY: 'memberName',
      VALUES: {
        DEFAULT: null,
      },
    },

    MEMBER_EMAIL: {
      KEY: 'memberEmail',
      VALUES: {
        DEFAULT: null,
      },
    },

    MEMBER_ROLE: {
      KEY: 'memberRole',
      VALUES: {
        DEFAULT: null,
      },
    },

    SELECTED: {
      KEY: 'selected',
      VALUES: {
        DEFAULT: false,
      },
    },

    MEMBER_AUTHORIZED: {
      KEY: 'memberAuthorized',
      VALUES: {
        DEFAULT: '0' as IRegistrationTeamInterface['memberAuthorized'],
        AUTHORIZED: '1' as IRegistrationTeamInterface['memberAuthorized'],
        UNAUTHORIZED: '2' as IRegistrationTeamInterface['memberAuthorized'],
      },
    },

    MEMBER_DOB: {
      KEY: 'memberDob',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    UPDATED: {
      KEY: 'modified',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
