import TimeLib from '../../../../lib/Time'
import { ITransactionInterace } from '../../../../models/transactions/transaction.interface'

export const DATA_FIRESTORE_V01_TRANSACTIONS = {
  NAME: 'v01_transactions',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    ID: {
      KEY: 'id',
      DEFAULT: null,
    },

    PAYER_ID: {
      KEY: 'payerId',
      DEFAULT: null,
    },

    IS_PAID: {
      KEY: 'isPaid',
      DEFAULT: false,
    },

    EVENT_ID: {
      KEY: 'eventId',
      DEFAULT: null,
    },

    CHARGE_ID: {
      KEY: 'chargeId',
      DEFAULT: null,
    },

    AMOUNT_PAID: {
      KEY: 'amountPaid',
      DEFAULT: 0,
    },

    EVENT_OWNER_CONNECT_ID: {
      KEY: 'eventOwnerConnectId',
      DEFAULT: null,
    },

    UPDATED_AT: {
      KEY: 'updatedAt',
      DEFAULT: TimeLib.utcTimestamp(),
    },

    CREATED_AT: {
      KEY: 'createdAt',
      DEFAULT: TimeLib.utcTimestamp(),
    },

    EVENT_OWNER_ID: {
      KEY: 'eventOwnerId',
      DEFAULT: null,
    },

    TRANSACTION_ID: {
      KEY: 'transactionId',
      DEFAULT: null,
    },

    TRANSFER_STATUS: {
      KEY: 'transferStatus',
      VALUES: {
        SENT: 'sent' as ITransactionInterace['transferStatus'],
        ERROR: 'error' as ITransactionInterace['transferStatus'],
        PENDING: 'pending' as ITransactionInterace['transferStatus'],
      },
    },

    ERROR: {
      KEY: 'errorCause',
      VALUES: {
        DEFAULT: null,
      },
    },
  },
}
