import { IUserSliceIniState } from './types'

const InitDocument = {
  membershipStatus: '',
  startDate: null,
  endDate: null,
  validTill: null,
  documentFullName: '',
  documentNumber: '',
  image: '',
}

const userSliceIniState: IUserSliceIniState = {
  v: null,
  guestId: null,
  userId: '',
  userStripeId: '',
  displayName: '',
  userStripeAccountId: null,
  profileCompleted: false,
  isLoggedIn: null,
  isFirstTime: false,
  showRegistration: false,
  userType: '',
  userLegalClauses: [],
  profileDetails: {
    id: '',
    userSummary: '',
    userLastName: '',
    userPhoneCode: '',
    userFirstName: '',
    userPreviousStripeConnectIds: [],
    userStripeAccountStatus: null,
    userEmail: '',
    userProfilePicture: '',
    userType: '',
    userStripeAccountId: null,
    userSubRoles: [],
    userDOB: null,
    userNationality: '',
    userAddress: '',
    userPhoneNumber: '',
    userAlternativeNumber: '',
    userSocialSecurityNumber: '',
    userEndorsementNumberLetter: '',
    userLat: '',
    userName: '',
    userZone: '',
    userLong: '',
    userState: '',
    userCards: [],
    userNameNGram: [],
    userCreated: null,
    userModified: null,
    userDiscipline: '',
    userTeamMembers: [],
    userNotificationsSettings: null,
    userAccountPrivacy: false,
    userHorses: [],
    userStripeId: '',
    userAccountNumber: '',
    userSafeSupportStatus: null,
    userSafeSupportExpiry: null,
    userBackgroundCheckStatus: null,
    userBackgroundCheckExpiry: null,
    userFEI: InitDocument,
    userUSEF: InitDocument,
    userUSDF: InitDocument,
    userUSEA: InitDocument,
    userAMHA: InitDocument,
    userASHA: InitDocument,
    userAHHS: InitDocument,
    userUPHA: InitDocument,
    userWDAA: InitDocument,
    userUSHJA: InitDocument,
    userARHPA: InitDocument,
    userExtraDocument: [],
    userWebsite: '',
    userTwitter: '',
    userFacebook: '',
    userInstagram: '',
    userLinkedin: '',
    userPocDetails: {
      pocFirstName: '',
      pocLastName: '',
      pocFullName: '',
      pocPhone: '',
      pocEmail: '',
    },
  },
  userDocumentsData: {},
  competitorProfileData: {
    competitorJudgingLicense: '',
    competitorOwnerId: '',
  },
  accountDetails: {
    userCards: [],
    userAccountNumber: '',
  },
  vendorProfileData: {
    vendorProfileImageUrl: '',
  },
  organiserProfileData: {
    organizerProfileImageUrl: '',
  },
  userNotificationsSettings: {
    newMessages: false,
    weatherUpdates: false,
    resultUpdates: false,
    courseUpdates: false,
    paymentUpdates: false,
  },
  paymentStatus: false,
}

export { userSliceIniState }
