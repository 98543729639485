type Props = {
  name: string
  icon: string
  activeIcon: string
  filter?: string[]
  handleChangeCard?: any
  type: string
  lastDiv?: boolean
}

const HomeRootFiltersCategoryCard = (props: Props) => {
  // Constants
  const imgProps = {
    width: '24px',
    height: '24px',
    alt: props.name,
  }

  return (
    <>
      <div
        onClick={() => props.handleChangeCard(props.name, props.type)}
        className={`categoryFilterCard border w-[48%] sm:w-[32%] md:w-[19%] hover:border-SeabiscuitMainThemeColor group cursor-pointer p-3 rounded-lg ${props.filter && props.filter.includes(props.name) ? ' border-SeabiscuitMainThemeColor bg-[#fdf2f4]' : 'border-[#122B4633]'}`}
      >
        <img
          src={props.icon}
          {...imgProps}
          alt="inactive"
          className={`mb-4 ${props?.filter?.includes(props.name) ? 'hidden' : 'block'} group-hover:hidden`}
        />
        <img
          src={props.activeIcon}
          {...imgProps}
          alt="active"
          className={`mb-4 ${props?.filter?.includes(props.name) ? 'block' : 'hidden'} group-hover:block`}
        />

        <p
          className={`mt-2 capitalize group-hover:text-SeabiscuitMainThemeColor  font-normal text-sm ${props.filter && props.filter.includes(props.name) ? 'text-SeabiscuitMainThemeColor' : 'text-[#122b4666]'}`}
        >
          {props.name}
        </p>
      </div>
    </>
  )
}

export default HomeRootFiltersCategoryCard
