import { createAsyncThunk } from '@reduxjs/toolkit'
import { where } from 'firebase/firestore'
import helpers from '../../../commonHelpers/helpers'
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { CustomError } from '../../../helpers/helpers'
import {
  IRegistrationExtended,
  TEventRegisteredUsers,
} from '../../../models/event-registered-users/event-registered-users.interface'
import { getConvertedData } from '../../../models/interface.helper'
import FirestoreService from '../../../services/firestoreService'
import { RootState } from '../../store'
import ORGANIZER_SLICE_CONST from '../organizerSlice.const'
import IOrganizerSliceType from '../organizerSlice.types'
import { RegistrationByDayModel } from '../../../models/registrations-by-day/registrationByDay.model'
import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { RiderTeamMemberModel } from '../../../models/rider-team-member/riderTeamMember.model'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const setExhibitorsAc = createAsyncThunk(
  'setExhibitorsAc',
  async (
    payload: IOrganizerSliceType['ISetExhibitorAsyncThunkAcArgs'],
    thunkApi
  ): Promise<IOrganizerSliceType['ISetExhibitorAcReturnType']> => {
    let currState = thunkApi.getState() as RootState
    let docsCountInDb: number = 0
    let searchFilterQuery: any = []
    let registrations: IRegistrationExtended[] = []
    let registrationsByDay: IRegistrationByDayInterface[] = []
    let updatedRegistrationsByDay: IRegistrationExtended[] = []
    let filteredRegistrationsByDay: IRegistrationExtended[] = []
    let registeredUsers: TEventRegisteredUsers[] = []
    let riderTeamMembers: IRiderTeamMemberInterface[] = []

    const riderTeamMember = await FirestoreService.filterItems(COLLECTIONS.RIDER_TEAM_MEMBER.NAME, [
      where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.EVENT_ID.KEY, '==', payload.eventId),
      where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.TEAM_MEMBER_ROLE.KEY, '==', 'Rider'),
    ])

    if (riderTeamMember.size) {
      riderTeamMember.forEach((currDoc) => {
        riderTeamMembers.push(
          getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
        )
      })
    }

    const { perPage, orderBy, orderDirection } =
      ORGANIZER_SLICE_CONST.INITIAL_STATE.manageEvent.lists.exhibitors.tabs.all.query

    let message: string | null = MESSAGES_CONST.SOMETHING_WENT_WRONG
    let query = {
      perPage: (payload?.query?.perPage ?? perPage)!,
      orderBy: (payload?.query?.orderBy ?? orderBy)!,
      orderDirection: (payload?.query?.orderDirection ?? orderDirection)!,
    }

    let queries = [
      where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', payload.eventId),
      where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.KEY, 'in', [
        COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PAID,
        COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PENDING,
      ]),
    ]

    if (!!payload?.query?.search) {
      searchFilterQuery = [
        where(
          COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.RIDER_NAME_N_GRAM.KEY,
          'array-contains',
          payload?.query?.search?.toLowerCase()
        ),
      ]
    }

    const registrationsQuery: any[] = [...queries, ...searchFilterQuery]

    payload.tabName = payload.tabName ?? currState.organizer.manageEvent.lists.exhibitors.activeTab

    if (!payload.eventId)
      return {
        ...payload,
        data: [],
        message: null,
        docsCountInDb: 0,
        filteredData: [],
      }

    try {
      if (!payload.cursorId)
        docsCountInDb = await FirestoreService.getDocumentsCount({
          collectionName: COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
          queries: registrationsQuery,
        })

      const registrationByDaySnaps = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        registrationsQuery
        // payload?.query?.perPage ?? perPage,
        // payload?.query?.orderBy ?? orderBy,
        // payload?.query?.orderDirection ?? orderDirection,
        // payload.cursorId
      )

      registrationByDaySnaps.forEach((currRegistrationSnapshot) => {
        registrationsByDay.push({
          ...getConvertedData(
            RegistrationByDayModel.fromFirestoreDoc(currRegistrationSnapshot).toObject()
          ),
        })
      })

      const userIds = registrationsByDay.map((user) => user.userId ?? '')
      const uniqueUserIds = userIds.filter((user, index) => {
        return index === userIds.findIndex((currentUser) => user === currentUser)
      })
      const registeredUsersQueries = [
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', payload.eventId),
      ]
      if (uniqueUserIds.length > 0)
        registeredUsersQueries.push(
          where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY, 'in', uniqueUserIds)
        )

      const registeredUsersSnaps = await FirestoreService.filterItems(
        COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        registeredUsersQueries
      )
      if (registeredUsersSnaps.size > 0)
        registeredUsersSnaps.docs.forEach((currDoc) => {
          registeredUsers.push(
            getConvertedData(EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject())
          )
        })

      updatedRegistrationsByDay = registrationsByDay.reduce(
        (accumulate: IRegistrationExtended[], current) => {
          const isDuplicated = accumulate.filter(
            (rider) => rider.riderId === current.riderId && rider.userId === current.userId
          )

          if (isDuplicated.length > 0) {
            accumulate = accumulate.map((rider) => {
              let horseUpdated = rider.horses
              if (current.horseId) {
                horseUpdated?.push({
                  horseId: current.horseId,
                  horseName: current.horseName,
                  horseProfilePicture: current.horseProfilePicture,
                })
              }
              return {
                ...rider,
                horses: horseUpdated,
              }
            })
          } else {
            accumulate.push({
              ...current,
              ...(current.horseId && {
                horses: [
                  {
                    horseId: current.horseId,
                    horseName: current.horseName,
                    horseProfilePicture: current.horseProfilePicture,
                  },
                ],
              }),
              paperworkstatus: registeredUsers?.filter(
                (registeredUser) => registeredUser.userId === current.userId
              )[0]?.paperworkstatus,
              teamstatus: registeredUsers?.filter(
                (registeredUser) => registeredUser.userId === current.userId
              )[0]?.teamstatus,
              paymentstatusall: registeredUsers?.filter(
                (registeredUser) => registeredUser.userId === current.userId
              )[0]?.paymentstatusall,
              isScratched: !!registeredUsers?.filter(
                (registeredUser) => registeredUser.userId === current.userId
              )[0]?.isScratched,
              mailLog: riderTeamMembers?.filter(
                (riderTeamMember) => riderTeamMember.riderId === current.riderId
              )[0]?.mailLog,
            })
          }
          return accumulate
        },
        []
      )

      const getPaperwork = (registrationByDay: IRegistrationExtended) => {
        const isSigned = registrationByDay.mailLog?.filter((mailLog) => mailLog.status === 'Signed')
        return isSigned && isSigned.length > 0
      }

      switch (payload.tabName) {
        case 'all':
          filteredRegistrationsByDay = updatedRegistrationsByDay
          break
        case 'complaint':
          filteredRegistrationsByDay = updatedRegistrationsByDay.filter(
            (registrationByDay) =>
              registrationByDay.teamstatus &&
              !registrationByDay.isScratched &&
              getPaperwork(registrationByDay) &&
              registrationByDay.paymentstatusall
          )
          break
        case 'scratch':
          filteredRegistrationsByDay = updatedRegistrationsByDay.filter(
            (registrationByDay) => registrationByDay.isScratched
          )
          break
        case 'non-complaint':
          filteredRegistrationsByDay = updatedRegistrationsByDay.filter(
            (registrationByDay) =>
              !registrationByDay.isScratched &&
              (!registrationByDay.teamstatus || !getPaperwork(registrationByDay))
          )
          break
      }

      message = null
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          moduleName: 'setExhibitorsAc',
          fileName: 'setExhibitorsAcThunk',
          devMessage: error?.message,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        }),
      })
    } finally {
      return {
        ...payload,
        query: query,
        message,
        data: registrations,
        filteredData: filteredRegistrationsByDay,
        docsCountInDb: !payload.cursorId ? docsCountInDb : null,
      }
    }
  }
)

export default setExhibitorsAc
