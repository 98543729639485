import { useContext, useState } from 'react'

// Third party
import { AutorenewRounded } from '@mui/icons-material'

// import FirestoreService from "../../../services/firestoreService"

// Redux
import {
  selectSplitPaymentDetails,
  setSplitPaymentRecipient,
  setSplitPaymentRegistration,
} from '../../../store/events/eventsSlice'
import { useAppSelector } from '../../../store/hooks'
import {
  selectAccountSettings,
  selectUserId,
  selectUserStripeId,
} from '../../../store/user/userSlice'

// Constants
import { MODAL_CONSTS } from '../../../const/modal-const'

// Types
import { IRecipient, IUserCards } from '../../../models/users/user.interface'

// Services
import { Clear } from '@mui/icons-material'
import { cloneDeep } from 'lodash'
import { useDispatch } from 'react-redux'
import helpers from '../../../commonHelpers/helpers'
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { HandleModalContext, IHandleModal } from '../../../layout/mainlayout/MainLayout'
import FirestoreService from '../../../services/firestoreService'
import { httpService } from '../../../services/httpService'

type Props = {
  show: boolean
  handleModal: IHandleModal['handleModal']
  dataToPassOn: {
    eventId: string
    amountToPay: number
  }
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const SplitPaymentByCardModal = (props: Props) => {
  // Hooks and vars

  const dispatch = useDispatch()
  const userId = useAppSelector(selectUserId)
  const stripeId = useAppSelector(selectUserStripeId)
  const [paymentStatus, setPaymentStatus] = useState(false)
  const splitPayment = useAppSelector(selectSplitPaymentDetails)
  const accountSettings = useAppSelector(selectAccountSettings)

  const handleModal = useContext(HandleModalContext)?.handleModal

  const [stripeRes, setStripeRes] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedCard, setSelectedCard] = useState<IUserCards | null>(null)

  const toasterFunctions = useToasterHelper()

  // Functions

  const updateRegistration = async (recipients: IRecipient[], recipient: IRecipient) => {
    try {
      let paid = false
      const registration = cloneDeep(splitPayment.registration)

      if (!registration || !registration.id)
        return toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })

      registration.selectionDetails.selectedRecipients = recipients
      paid = recipients.every((currRecipient) => currRecipient.recipientPaymentStatus === 'paid')

      if (paid) registration.paymentStatus = 'paid'

      await FirestoreService.updateItem(
        COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        registration.id,
        registration
      )

      dispatch(setSplitPaymentRecipient(recipient))
      dispatch(setSplitPaymentRegistration(registration))
      handleModal?.(true, MODAL_CONSTS.SPLIT_PAYMENT_MODAL)
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
      setLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Pays the grand total amount of the grand total
   */
  const payAmount = async () => {
    let amountToPay = 0
    let recipientIndex = -1
    let eventId = splitPayment.registration?.eventId

    setLoading(true)

    try {
      if (!splitPayment.currRecipient || !splitPayment.registration || !eventId)
        throw new Error(MESSAGES_CONST.SOMETHING_WENT_WRONG)

      let registration_ = cloneDeep(splitPayment.registration)
      let selectedRecipients = registration_?.selectionDetails.selectedRecipients ?? []

      const recipient_ =
        selectedRecipients.find((recipient, index) => {
          if (recipient.recipientId === splitPayment.currRecipient?.recipientId) {
            recipientIndex = index
            return true
          }
          return false
        }) ?? null

      if (!recipient_) throw new Error(MESSAGES_CONST.SOMETHING_WENT_WRONG)

      amountToPay = recipient_.recipientAmountToPay

      const res = await httpService({
        url: 'createCharge',
        method: 'POST',
        data: {
          userId,
          eventId,
          customerId: stripeId,
          paymentType: 'spectator_tickets',
          amount: amountToPay ?? 0,
          pmId: accountSettings.cards && accountSettings.cards[0].pmId,
          description: 'Split payment',
        },
      })

      if ('paymentIntent' in (res as any)) {
        setPaymentStatus(true)
        setStripeRes((res as any).paymentIntent)

        if (userId) {
          selectedRecipients[recipientIndex] = {
            ...recipient_,
            recipientPaymentStatus: 'paid',
            invoiceId: (res as any).paymentIntent?.id,
            invoiceUrl: (res as any).paymentIntent.receipt_url,
          }
          return updateRegistration(selectedRecipients, selectedRecipients[recipientIndex])
        }
      } else {
        setLoading(false)
      }

      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } catch (error) {
      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Closes payment tab and opens add new card tab
   */
  const openAddNewCardModal = () => {
    props.handleModal(false, MODAL_CONSTS.SPLIT_PAYMENT_MODAL)
    props.handleModal(true, MODAL_CONSTS.CARD, {
      add_card: true,
      re_open_modal: true,
      modal_name: MODAL_CONSTS.SPLIT_PAYMENT_MODAL,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Closes pay using card modal
   */
  const closePayByCardModal = () => {
    props.handleModal(false, MODAL_CONSTS.SPLIT_PAYMENT_MODAL)
  }

  return (
    <div
      className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pt-10 pb-7 px-8 max-h-[90vh]">
          <span
            onClick={closePayByCardModal}
            className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
          >
            <Clear
              fontSize={'small'}
              style={{
                color: '#122B46',
                fontWeight: '400',
              }}
            />
          </span>
          {!paymentStatus && (
            <>
              <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
                <h5
                  className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2"
                  id="exampleModalScrollableLabel"
                >
                  Pay
                </h5>
              </div>
              <div className="min-h-1/2 overflow-y-auto px-2">
                <div className="mt-3 flex w-full border-solid p-3 border-[#D3DAEE] border rounded-2xl">
                  <div className="">
                    <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                      You will be charged:
                    </p>
                    <div className="flex w-full items-center mt-2">
                      <img src={'assets/img/dark/Dollarcoin.svg'} alt="icons" />
                      <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2">
                        ${splitPayment.currRecipient?.recipientAmountToPay?.toFixed(2)}
                      </p>
                    </div>
                    <div className="flex w-full items-center mt-2">
                      <img src={'assets/img/dark/Error.svg'} alt="icons" />
                      <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2">
                        Payment required to complete registrations
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-3 w-full border-solid p-3 border-[#D3DAEE] border rounded-2xl">
                  <div className="allCards">
                    <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                      Select payment method
                    </p>
                    {accountSettings?.cards && accountSettings?.cards.length
                      ? accountSettings?.cards.map((currCard: any, cardIndex: number) => {
                          return (
                            <div
                              className="mb-2 relative"
                              onClick={() => setSelectedCard(currCard)}
                              key={`${JSON.stringify(currCard)}${cardIndex}`}
                            >
                              <span className="absolute left-2 top-2 cursor-pointer">
                                <img src={'assets/img/dark/credit-card.svg'} alt="icons" />
                              </span>
                              <input
                                type="text"
                                readOnly
                                placeholder={`****-****-****-${currCard?.cardNumber}`}
                                className="w-full pl-10 bg-gray-50 border border-[#D3DAEE] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  p-2.5 cursor-pointer "
                                value={`****-****-****-${currCard.cardNumber}`}
                              />
                              {selectedCard?.pmId === currCard.pmId ? (
                                <span className="px-2 absolute right-2 top-3">
                                  <img alt="icons" src={'assets/og_icons/Ok-1.svg'} />
                                </span>
                              ) : null}
                            </div>
                          )
                        })
                      : null}

                    <div className="mb-2 relative cursor-pointer flex items-center">
                      <span className="absolute left-2">
                        <img src={'assets/img/dark/credit-card.svg'} alt="icons" />
                      </span>
                      <input
                        type="text"
                        readOnly
                        placeholder="Add a new card"
                        className="w-full pl-10 bg-gray-50 border border-[#D3DAEE] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  p-2.5 cursor-pointer"
                        value="Add a new card"
                        onClick={openAddNewCardModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="w-full h-12 mx-auto py-2 px-4 mt-5 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:text-SeabiscuitMainThemeColor hover:bg-SeabiscuitLightThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:text-SeabiscuitLightTextColor disabled:bg-SeabiscuitLightThemeColor focus:ring-SeabiscuitMainThemeColor"
                onClick={payAmount}
              >
                {loading ? <AutorenewRounded fontSize="small" className="animate-spin" /> : 'PAY'}
              </button>

              <button
                onClick={closePayByCardModal}
                type="button"
                className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
              >
                CANCEL
              </button>
            </>
          )}

          {paymentStatus && (
            <>
              <div className="mx-auto w-full">
                <img className="mx-auto" src={'assets/img/light/Ok.svg'} alt="icon" />
                <h6 className="text-center text-SeabiscuitDark200ThemeColor font-semibold mt-3 mb-3">
                  Success!
                </h6>
                <p className="text-center text-SeabiscuitDark200ThemeColor mt-3 mb-3  ">
                  You registered for this event.
                </p>
                <p className="text-center text-SeabiscuitDark200ThemeColor mt-3 mb-3  ">
                  Amount paid: ${(stripeRes?.amount ?? 0) / 100}{' '}
                </p>
              </div>
              <a
                href={stripeRes?.receipt_url}
                rel="noreferrer"
                target="_blank"
                type="submit"
                className="w-full h-12 mx-auto py-2 px-4 mt-5 border-2 border-solid border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium text-[#F7074F] bg-[white]  flex items-center justify-center"
              >
                VIEW RECEIPT
              </a>

              <button
                onClick={closePayByCardModal}
                type="button"
                className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor "
              >
                CLOSE
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SplitPaymentByCardModal
