import { CONST } from '../../../../../../const/const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//  Hunter class B
export const EPL_FB_EQUITATION_SAMPLE = {
  vMain: 1,
  vSub: 0,
  vFix: 0,
  disciplineType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DISCIPLINE.TYPE.STANDARD,
  discipline: 'Hunter CLASS B',
  validationStatus: 'unknown',
  divisionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.DIVISION.TYPE.STANDARD,
  divisionItemsRules: null,
  divisions: [
    {
      // ************************************************************
      //  1900 - Hunter Breeding
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '1900',
      divisionTitle: 'Hunter Breeding',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.EQUITATION_STANDARD,
      sectionItemsRules: null,
      sections: [
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Sub Code 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Sub Code 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Sub Code 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.EQUITATION_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Sub Code 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Sub Code 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Sub Code 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Under Saddle Class 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Handy Class 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Conformation Class 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionHeight: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Height 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionTotalPriceMoney: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Total Price Money 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionJudge: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Judge 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
  ],
}
