import { CONST } from '../../../const/const'

const OrganizerRootSideProfileTabs = ({
  setActiveTab,
  activeTab,
}: {
  setActiveTab?: any
  activeTab?: string
}) => {
  return (
    <>
      <div className="flex flex-col items-center py-[22px] w-full  bg-white">
        <div className="flex flex-wrap rouded-lg w-full items-start justify-center">
          <div
            className={`grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid ${activeTab === CONST.UI.USER.PROFILE.TABS.INFO.VALUE ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'}`}
            onClick={() => setActiveTab(CONST.UI.USER.PROFILE.TABS.INFO.VALUE)}
          >
            {activeTab === CONST.UI.USER.PROFILE.TABS.INFO.VALUE ? (
              <img src="/assets/cp_icons/User-2.svg" alt="userIcons" />
            ) : (
              <img src="/assets/cp_icons/User-1.svg" alt="userIcons" />
            )}
          </div>
          <div
            className={`grow flex flex-col justify-center cursor-pointer py-2 items-center border-b border-solid ${activeTab === CONST.UI.USER.PROFILE.TABS.STAFF.VALUE ? 'border-SeabiscuitMainThemeColor' : 'border-transparent'}`}
            onClick={() => setActiveTab(CONST.UI.USER.PROFILE.TABS.STAFF.VALUE)}
          >
            {activeTab === CONST.UI.USER.PROFILE.TABS.STAFF.VALUE ? (
              <img src="/assets/cp_icons/User_Groups-2.svg" alt="userIcons" />
            ) : (
              <img src="/assets/cp_icons/User_Groups-1.svg" alt="userIcons" />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default OrganizerRootSideProfileTabs
