import { useEffect, useState } from 'react'

// Third party
import { AutorenewRounded } from '@mui/icons-material'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { where } from 'firebase/firestore'
import { useIonRouter } from '@ionic/react'
import { Clear } from '@mui/icons-material'
import { cloneDeep } from 'lodash'

// Redux
import { selectedEvent as selectedEventGetter } from '../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectpaymentStatus, setPaymentStatus } from '../../../store/user/userSlice'
import { userSliceDataHelper } from '../../../store/storeHelpers/userSlice/userSliceHelper'
import { IAccountDetails } from '../../../store/storeHelpers/userSlice/types'
import {
  selectPayTabSummaryAccordion,
  selectRecipientsInDb,
  selectRegistertrationPaying,
  selectRegistration,
  selectRegistrationByDay,
  selectRegistrationFeesR,
  selectRegistrationTicketsR,
  setPaying,
  setRecipientUser,
  setRecipientsInDb,
  setRegistrationAc,
  setRegistrationsByDay,
  setRegistrationsFeesAc,
  setRegistrationsTicketsAc,
} from '../../../store/registration/registrationSlice'
import {
  allDataReset,
  selectSpectatorTicketsR,
  setPaymentDetails,
} from '../../../store/tickets/ticketslice'

// Constants
import { MODAL_CONSTS } from '../../../const/modal-const'

// Types
import { IUserCards, IUserInterface } from '../../../models/users/user.interface'

// Services
import helpers from '../../../commonHelpers/helpers'
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { ICompetitorEventRegisterTypes as ICerhTypes } from '../../../pages/competitor/competitorEventRegister/competitorEventRegisterHelper'
import { httpService } from '../../../services/httpService'

import { CustomError } from '../../../helpers/helpers'
import { TEventRegisteredUsers } from '../../../models/event-registered-users/event-registered-users.interface'
import { IRecipientInterface } from '../../../models/recipients/recipients.interface'
import ViewsLoader from '../../loader/ViewsLoader'
import FirestoreService from '../../../services/firestoreService'
import { getConvertedData } from '../../../models/interface.helper'
import { IRegistrationFeesInterface } from '../../../models/registration-fees/registrationFees.interface'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { IRegistrationTicketInterface } from '../../../models/registration-tickets/registrationTicket.interface'
import { RecipientModel } from '../../../models/recipients/recipients'

type Props = {
  show: boolean
  handleModal: any
  dataToPassOn: {
    saveAllTabs: ICerhTypes['ISaveAllTabsFn']
    type?: any
    eventId: string
    assignedTickets: any
    registeredUser: IUserInterface | null
  }
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

const FILE_NAME = 'RegisterForCompetitionPayByCardModal'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

const RegisterForCompetitionPayByCardModal = (props: Props) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const router = useIonRouter()
  const history = useHistory()
  const toasterFunctions = useToasterHelper()

  const registration = useAppSelector(selectRegistration)
  const selectedEvent = useAppSelector(selectedEventGetter)
  const paying = useAppSelector(selectRegistertrationPaying)
  const recipientsInDb = useAppSelector(selectRecipientsInDb)
  const registrationsFees = useAppSelector(selectRegistrationFeesR)
  const registrationsByDay = useAppSelector(selectRegistrationByDay)
  const registrationsTickets = useAppSelector(selectRegistrationTicketsR)
  const summaryAccordionData = useAppSelector(selectPayTabSummaryAccordion)
  const spectator_ticket = useAppSelector(selectSpectatorTicketsR)
  const paymentStatus = useAppSelector(selectpaymentStatus)

  const { eventMainData, payment_detail } = spectator_ticket
  const isTicketPayment = payment_detail?.type === 'spectator'

  const [amountToPay, setAmountToPay] = useState(0)
  const [stripeRes, setStripeRes] = useState<any>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [totalAmountToPay, setTotalAmountToPay] = useState(0)
  const [selectedCard, setSelectedCard] = useState<IUserCards | null>(null)
  const [isPayByCash, setIsPayByCash] = useState(false)
  const [accountSettings, setAccountSettings] = useState<IAccountDetails | null>(null)
  const registeredUser = useAppSelector((state) => state.registeredUser.data)

  useEffect(() => {
    if (registeredUser) {
      const userDataFromDb = getConvertedData(registeredUser) ?? {}
      const accountDetails: IAccountDetails =
        userSliceDataHelper.getStructuredAccountDetails(userDataFromDb)
      setAccountSettings(accountDetails)
    }

    dispatch(setPaymentStatus(false))
  }, [registeredUser])

  useEffect(() => {
    let amountToPay_

    if (isTicketPayment) {
      amountToPay_ = payment_detail?.price
    } else {
      amountToPay_ = summaryAccordionData.remainingTotalPrice
      setTotalAmountToPay(summaryAccordionData.totalPrice)
    }

    setAmountToPay(amountToPay_)
  }, [
    summaryAccordionData.remainingTotalPrice,
    payment_detail,
    isTicketPayment,
    summaryAccordionData.totalPrice,
  ])

  // Functions
  const saveAllTabs = async (args: ICerhTypes['ISaveAllTabsFnArgs']) => {
    if (props?.dataToPassOn && typeof props?.dataToPassOn?.saveAllTabs === 'function') {
      await props?.dataToPassOn?.saveAllTabs(args)
    }
  }

  const closeModal = () => {
    if (isTicketPayment && paymentStatus) {
      dispatch(allDataReset())
    }
    props.handleModal(false, 'competitonPayByCard')
  }

  const onViewReceiptClick = async () => {
    if (isTicketPayment) {
      let anchorElement = document.createElement('a')
      anchorElement.target = '_blank'
      anchorElement.href = stripeRes?.receipt_url ?? ''
      document.body.appendChild(anchorElement)
      anchorElement.click()
    } else {
      const recipientSnapshots = await FirestoreService.filterItems(COLLECTIONS.RECIPIENT.NAME, [
        where(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.REGISTRATION_DOC_ID.KEY,
          '==',
          registration?.id
        ),
        where(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.RECIPIENT_ID.KEY,
          '==',
          registration?.userId
        ),
      ])

      const recipient = RecipientModel.fromFirestoreDoc(recipientSnapshots.docs[0]).toObject()

      props.handleModal(false, MODAL_CONSTS.REGISTER_FOR_COMPETITION_PAY_BY_CARD)
      props.handleModal(true, MODAL_CONSTS.BILL_BRIEFE, {
        bill: recipient,
      })
    }
  }

  const updateData = async (args: {
    cardNumber: IRecipientInterface['cardNo']
    eventId: TEventRegisteredUsers['eventId']
    isRegistered: TEventRegisteredUsers['isrecipient']
    paymentStatus: TEventRegisteredUsers['paymentStatus']
  }) => {
    let recipientSelected = false
    let isRegistered = args?.isRegistered ?? false
    let userData = cloneDeep(summaryAccordionData.userData)
    let registrationsByDay_: IRegistrationByDayInterface[]
    let registrationsTickets_: IRegistrationTicketInterface[]
    let registrationsFees_: IRegistrationFeesInterface[]

    registrationsByDay_ = registrationsByDay.map((currRegistrationByDay) => {
      recipientSelected = !!currRegistrationByDay?.recipientId
      return {
        ...currRegistrationByDay,
        recipientId: !recipientSelected ? registeredUser?.id : currRegistrationByDay?.recipientId,
        isPaidByOwner: !recipientSelected,
        paymentStatus: recipientSelected ? 'pending' : 'paid',
        update: true,
      }
    })

    registrationsTickets_ = registrationsTickets.map((currRegistrationTicket) => {
      recipientSelected = !!currRegistrationTicket?.recipientId
      return {
        ...currRegistrationTicket,
        recipientId: !recipientSelected
          ? registeredUser?.id ?? null
          : currRegistrationTicket?.recipientId,
        isPaidByOwner: !recipientSelected,
        paymentStatus: recipientSelected ? 'pending' : 'paid',
        update: true,
      }
    })

    registrationsFees_ = registrationsFees.map((currRegistrationFees) => {
      recipientSelected = !!currRegistrationFees?.recipientId
      return {
        ...currRegistrationFees,
        recipientId: !recipientSelected
          ? registeredUser?.id ?? null
          : currRegistrationFees?.recipientId,
        isPaidByOwner: !recipientSelected,
        paymentStatus: recipientSelected ? 'pending' : 'paid',
        update: true,
      }
    })

    userData = {
      ...userData,
      update: true,
      cardNo: args.cardNumber ?? null,
      paymentStatus: args.paymentStatus === 'paid' ? 'paid' : userData.paymentStatus,
    }

    let recipientsInDb_ = cloneDeep(recipientsInDb).map((currRecipientInDb) => {
      if (currRecipientInDb.recipientId === userData.recipientId) {
        currRecipientInDb = {
          ...currRecipientInDb,
          ...userData,
        }
      }

      return currRecipientInDb
    })

    dispatch(setRecipientUser(userData))
    dispatch(setRecipientsInDb(recipientsInDb_))
    dispatch(setRegistrationsFeesAc(registrationsFees_))
    dispatch(setRegistrationsByDay(registrationsByDay_))
    dispatch(setRegistrationsTicketsAc(registrationsTickets_))

    if (!registration)
      throw CustomError.somethingWentWrong({
        ...customErrorProps,
        moduleName: 'updateData',
        devMessage: `${registration} is [${registration}]`,
      })
    else {
      const registrationData = {
        ...registration,
        isRegistered,
        paymentStatus: args.paymentStatus,
      }
      dispatch(setRegistrationAc(registrationData))
    }
  }

  const payByCard = async (eventId: string) => {
    const pmId = selectedCard?.pmId
    const cardNumber = selectedCard?.cardNumber

    const data = {
      userId: registeredUser?.id,
      pmId,
      customerId: registeredUser?.userStripeId,
      registrationId: registration?.id,
      eventId: selectedEvent.basicEventDetails.id,
      ...(!isTicketPayment && {
        recipientDocId: summaryAccordionData.userData.id,
      }),
      paymentType: isTicketPayment ? 'spectator_tickets' : 'event_register',
      description: !registeredUser?.id ? 'Guest User buy tickets' : 'Event Registration',
      amount: amountToPay,
      cardNo: cardNumber,
    }
    const res = await httpService({
      url: 'createCharge',
      method: 'POST',
      data,
    })

    if ('paymentIntent' in (res as any)) {
      setStripeRes((res as any).paymentIntent)
      dispatch(setPaymentStatus(true))
      if (isTicketPayment) {
        dispatch(
          setPaymentDetails({
            payment_detail: {
              ...payment_detail,
              setLoading,
            },
            payment_status: {
              status: true,
              show_payment_done: false,
              data: (res as any).paymentIntent,
            },
          })
        )
        dispatch(setPaying(false))
        setLoading(false)
      } else {
        await updateData({
          isRegistered: true,
          eventId: eventId as any,
          paymentStatus: 'paid',
          cardNumber: cardNumber ?? null,
        })
      }
    } else {
      toasterFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
    if (registration?.id && window.location.href.includes(CONST.ROUTES.MANAGE_REGISTER_EVENT.URL)) {
      await saveAllTabs({
        isManage: true,
        cardNo: cardNumber || undefined,
        isPaid: true,
        invoiceId: (res as any).paymentIntent?.id,
        invoiceUrl: (res as any).paymentIntent.receipt_url,
      })
    } else {
      await saveAllTabs({
        isPaid: true,
        cardNo: cardNumber || undefined,
        invoiceId: (res as any).paymentIntent?.id,
        invoiceUrl: (res as any).paymentIntent.receipt_url,
      })
    }
  }

  const onPayByCash = async () => {
    if (registration?.id && window.location.href.includes(CONST.ROUTES.MANAGE_REGISTER_EVENT.URL)) {
      await saveAllTabs({ isManage: true, isPayByCash: true })
    } else {
      await saveAllTabs({ isPayByCash: true })
    }
    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      registration?.id ?? '',
      {
        isRegistered: true,
        paymentStatus: PAYMENT_STATUSES.PENDING,
      }
    )
    dispatch(setPaymentStatus(true))
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Pays the grand total amount of the grand total
   */
  const onPayAmount = async () => {
    const eventOwnerId = selectedEvent.basicEventDetails.owner
    let eventId: string | null = selectedEvent.basicEventDetails.id ?? null

    if (!amountToPay)
      return toasterFunctions.info({
        message: MESSAGES_CONST.PLEASE_ADD_UNITS,
      })
    else if (!selectedEvent.basicEventDetails.id)
      return toasterFunctions.info({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

    if (!isTicketPayment) {
      if (!registrationsByDay.length)
        return toasterFunctions.info({
          message: MESSAGES_CONST.PLEASE_ADD_TEAM_MEMBERS,
        })
    }

    if (!selectedCard?.pmId && !isPayByCash)
      return toasterFunctions.info({
        message: MESSAGES_CONST.PLEASE_SELECT_CARD,
      })

    const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
      eventId,
      eventOwnerId,
      ...(!isPayByCash && { pmId: selectedCard?.pmId }),
      ...(!isTicketPayment && {
        userId: registeredUser?.id,
        registrationId: registration?.id,
      }),
    })

    if (emptyVarName)
      throw CustomError.somethingWentWrong({
        ...customErrorProps,
        moduleName: 'onPayAmount',
        devMessage: `${emptyVarName} is [${emptyVarValue}]`,
      })

    dispatch(setPaying(true))
    setLoading(true)

    if (isTicketPayment) eventId = eventMainData?.id ?? null

    try {
      if (isPayByCash) {
        await onPayByCash()
      } else {
        await payByCard(eventId ?? '')
      }
    } catch (error: any) {
      toasterFunctions.error({
        message:
          error.response?.data?.message ?? error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        isError: true,
        message: error,
      })
      dispatch(setPaying(false))
      setLoading(false)
    } finally {
      dispatch(setPaying(false))
      setLoading(false)
      const link = `${CONST.ROUTES.COMPETITOR_REGISTERED_EVENT.URL}/${registration?.userId}/${registration?.eventId}/${registration?.id}`
      router.push(link)
      history.push(link)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Closes payment tab and opens add new card tab
   */
  const openAddNewCardModal = () => {
    props.handleModal(false, MODAL_CONSTS.REGISTER_FOR_COMPETITION_PAY_BY_CARD, {
      type: 'ticketPurchase',
    })
    props.handleModal(true, MODAL_CONSTS.CARD, {
      saveAllTabs,
      add_card: !isTicketPayment,
      re_open_modal: true,
      type: 'ticketPurchase',
      modal_name: MODAL_CONSTS.REGISTER_FOR_COMPETITION_PAY_BY_CARD,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Closes pay using card modal
   * @info Closes pay using card modal
   */
  const closePayByCardModal = () => {
    if (isTicketPayment) dispatch(allDataReset())
    props.handleModal(false, MODAL_CONSTS.REGISTER_FOR_COMPETITION_PAY_BY_CARD)
  }

  return (
    <div
      className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pt-10 pb-7 px-8 max-h-[90vh]">
          <span
            onClick={() => {
              if (loading || paying) return

              closeModal()
            }}
            className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
          >
            <Clear
              fontSize={'small'}
              style={{
                color: 'grey',
                fontWeight: '400',
              }}
            />
          </span>
          {!paymentStatus && (
            <>
              <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
                <h5
                  className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-bold pb-2"
                  id="exampleModalScrollableLabel"
                >
                  Pay
                </h5>
              </div>
              <div className="min-h-1/2 overflow-y-auto">
                <div className="mt-3 flex w-full border-solid p-3 border-[#D3DAEE] border rounded-2xl">
                  <div className="">
                    <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                      You will be charged:
                    </p>
                    <div className="flex w-full items-center mt-2">
                      <img src={'assets/img/dark/Dollarcoin.svg'} alt="icons" />
                      <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2">
                        $
                        {isTicketPayment
                          ? payment_detail?.price?.toFixed(2)
                          : amountToPay?.toFixed(2)}
                      </p>
                    </div>
                    <div className="flex w-full items-center mt-2">
                      <img src={'assets/img/dark/Error.svg'} alt="icons" />
                      <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2">
                        Payment required to complete registration
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-3 w-full border-solid p-3 border-[#D3DAEE] border rounded-2xl">
                  <div className="allCards">
                    <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                      Select payment method
                    </p>
                    {accountSettings?.userCards && accountSettings?.userCards.length
                      ? accountSettings?.userCards.map((currCard, cardIndex: number) => {
                          return currCard.pmId ? (
                            <div
                              className="mb-2 relative"
                              onClick={() => {
                                setSelectedCard(currCard)
                                setIsPayByCash(false)
                              }}
                              key={`${JSON.stringify(currCard)}${cardIndex}`}
                            >
                              <span className="absolute left-2 top-2 cursor-pointer">
                                <img src={'assets/img/dark/credit-card.svg'} alt="icons" />
                              </span>
                              <input
                                type="text"
                                readOnly
                                placeholder={`****-****-****-${currCard?.cardNumber}`}
                                className="w-full pl-10 bg-gray-50 border border-[#D3DAEE] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  p-2.5 cursor-pointer "
                                value={`****-****-****-${currCard.cardNumber}`}
                              />
                              {selectedCard?.pmId === currCard.pmId && !isPayByCash ? (
                                <span className="px-2 absolute right-2 top-3">
                                  <img alt="icons" src={'assets/og_icons/Ok-1.svg'} />
                                </span>
                              ) : null}
                            </div>
                          ) : null
                        })
                      : null}
                    <div className="mb-2 relative cursor-pointer flex items-center">
                      <span className="absolute left-2">
                        <img src={'assets/img/dark/credit-card.svg'} alt="icons" />
                      </span>
                      <input
                        type="text"
                        readOnly
                        placeholder="Add a new card"
                        className="w-full pl-10 border border-[#D3DAEE] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  p-2.5 cursor-pointer"
                        value="Add a new card >"
                        onClick={openAddNewCardModal}
                      />
                    </div>
                    {selectedEvent?.EventPaymentSettings?.allowRegistrationWithoutPayment && (
                      <div className="mb-2 relative cursor-pointer flex items-center">
                        <span className="absolute left-2">
                          <img src={'assets/img/dark/cash.svg'} alt="icons" />
                        </span>
                        <input
                          type="text"
                          readOnly
                          placeholder=""
                          className="w-full pl-10 border border-[#D3DAEE] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  p-2.5 cursor-pointer"
                          value="Pay by cash or check at the show"
                          onClick={() => setIsPayByCash(true)}
                        />
                        {isPayByCash ? (
                          <span className="px-2 absolute right-2 top-3">
                            <img alt="icons" src={'assets/og_icons/Ok-1.svg'} />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <button
                disabled={paying || loading}
                type="submit"
                className="w-full h-12 mx-auto py-2 px-4 mt-5 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:bg-[#D70443] focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:text-SeabiscuitLightTextColor disabled:bg-SeabiscuitLightThemeColor focus:ring-SeabiscuitMainThemeColor"
                onClick={onPayAmount}
              >
                {paying || loading ? (
                  <AutorenewRounded fontSize="small" className="animate-spin" />
                ) : isPayByCash ? (
                  'Submit registration'
                ) : (
                  'Pay'
                )}
              </button>

              <button
                disabled={paying || loading}
                onClick={closePayByCardModal}
                type="button"
                className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:bg-[#0b15261a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
              >
                Cancel
              </button>
            </>
          )}

          {paymentStatus && (
            <>
              <div className="mx-auto w-full">
                {!paying ? (
                  <>
                    <img className="mx-auto" src={'assets/img/light/Ok.svg'} alt="icon" />
                    <h6 className="text-center text-SeabiscuitDark200ThemeColor font-semibold mt-3 mb-3">
                      Success!
                    </h6>
                  </>
                ) : (
                  <>
                    <ViewsLoader color="red" size="md" />
                    <h6 className="text-center text-SeabiscuitDark200ThemeColor font-semibold mt-3 mb-3">
                      Processing! please wait
                    </h6>
                  </>
                )}

                {!isTicketPayment ? (
                  <>
                    <p className="text-center text-SeabiscuitDark200ThemeColor mt-3 mb-3">
                      You registered for this event.
                    </p>
                    {isPayByCash && (
                      <p className="text-center text-SeabiscuitDark200ThemeColor mt-3 mb-3">
                        You will be required to pay your bill when you arrive at the show.
                      </p>
                    )}
                    <p className="text-center text-SeabiscuitDark200ThemeColor mt-3 mb-3">
                      Each of your team members were sent paperwork to sign .
                    </p>
                  </>
                ) : null}

                <p className="text-center text-SeabiscuitDark200ThemeColor mt-3 mb-3  ">
                  Amount paid: ${(stripeRes?.amount ?? 0) / 100}
                </p>
              </div>
              <div
                className={clsx(
                  'w-full h-12 mx-auto py-2 px-4 mt-5 border-2 border-solid border-SeabiscuitMainThemeColor hover:bg-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium text-[#F7074F] hover:text-white bg-[white]  flex items-center justify-center',
                  paymentStatus && !paying && 'cursor-pointer'
                )}
                onClick={onViewReceiptClick}
              >
                VIEW RECEIPT
              </div>
              <button
                onClick={closePayByCardModal}
                type="button"
                className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor "
              >
                CLOSE
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default RegisterForCompetitionPayByCardModal
