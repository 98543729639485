// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import EventOrganizationStaffTableComponent from '../../../components/events/tables/organization-staff/EventOrganizationStaffTableComponent'
import EventOrganizationLogisticsTableComponent from '../../../components/events/tables/organization-logistics/EventOrganizationLogisticsTableComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsHomePageManagementMembersSubSection: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-2">
      <EventOrganizationStaffTableComponent />
      <EventOrganizationLogisticsTableComponent />
    </dl>
  )
}

export default ShowsHomePageManagementMembersSubSection
