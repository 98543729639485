// Redux
import { IEventTickets } from '../../../../models/event-details/event-details.interface'
import { IFees } from '../../../../models/event-drafts/event-draft.interface'
import { ISignatoryForMail } from '../../../../models/event-registered-users/event-registered-users.interface'
import {
  IEventsRegisterData,
  selectRegisterEventData,
  selectedEvent,
} from '../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../store/hooks'
import SelectedEventFeeList from './tabComponents/confirmation/SelectedEventFeeList'
import SelectedEventGrandTotal from './tabComponents/confirmation/SelectedEventGrandTotal'
import SelectedEventList from './tabComponents/confirmation/SelectedEventList'
import { SelectedEventPaperworkList } from './tabComponents/confirmation/SelectedEventPaperworkList'
import SelectedEventTicketList from './tabComponents/confirmation/SelectedEventTicketList'

import { competitorEventRegisterHelper as cerh } from '../../competitorEventRegister/competitorEventRegisterHelper'
import SelectedEventGovernanceFeeList from './tabComponents/confirmation/SelectedEventGovernanceFeeList'
import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants

let grandTotal = {
  total: 0,
  tickets: 0,
  salesTax: 0,
  stallFees: 0,
  registrationFees: 0,
}

const EventClinicConfirmationTab = ({
  activeTab,
  setEventTab,
  registerFormData,
  manageInfo,
  isManage,
}: {
  activeTab: {
    tab: string
    step: number
  }
  setEventTab: any
  message: string | null
  competitonFull?: boolean
  registerFormData: IEventsRegisterData
  allRiderTeamMembers: ISignatoryForMail[]
  manageInfo: IManageInfo
  isManage?: boolean
}) => {
  // Hooks and vars
  const eventData = useAppSelector(selectRegisterEventData)
  const selectedEventDetails = useAppSelector(selectedEvent)

  const selectedEvents = registerFormData.events ?? []
  const selectedEventsFees: IFees[] = eventData.fees ?? []
  const selectedEventsTickets: IEventTickets[] = eventData.tickets ?? []

  let { registrationPrice } = selectedEventDetails.basicEventDetails

  const grandTotal_ = cerh.calculateGrandTotal({
    selectedEvents,
    registrationPrice,
    selectedEventsFees,
    selectedEventsTickets,
  })

  const countDetails = grandTotal_?.countDetails
  const categorizedTotals = grandTotal_?.categorizedTotals

  grandTotal = {
    total: grandTotal_?.grandTotal ?? 0,
    salesTax: categorizedTotals?.tax.total ?? 0,
    stallFees: categorizedTotals?.fees.total ?? 0,
    tickets: categorizedTotals?.tickets.total ?? 0,
    registrationFees: categorizedTotals?.registrations.total ?? 0,
  }

  //Function
  const includeSalesTax = () => {
    return {
      ...grandTotal,
      total: grandTotal_?.grandTotal ?? 0,
    }
  }

  return (
    <>
      <SelectedEventList
        activeTab={activeTab}
        setEventTab={setEventTab}
        registrationPrice={registrationPrice}
        manageInfo={manageInfo}
        isManage={isManage}
      />

      <SelectedEventFeeList setEventTab={setEventTab} manageInfo={manageInfo} isManage={isManage} />

      <SelectedEventGovernanceFeeList
        setEventTab={setEventTab}
        manageInfo={manageInfo}
        isManage={isManage}
      />

      <SelectedEventTicketList
        setEventTab={setEventTab}
        selectedEventsTickets={selectedEventsTickets}
        selectedTicketsCount={countDetails?.ticketsCount ?? 0}
        selectedTicketsTotal={categorizedTotals?.tickets.total ?? 0}
        selectedRecipientCount={countDetails?.ticketsRecipientCount ?? 0}
        selectedTicketsUnitsCount={countDetails?.ticketsUnitsCount ?? 0}
        manageInfo={manageInfo}
        isManage={isManage}
      />

      <SelectedEventPaperworkList
        setEventTab={setEventTab}
        manageInfo={manageInfo}
        isManage={isManage}
      />

      <SelectedEventGrandTotal
        toggle={'1'}
        manageInfo={manageInfo}
        isManage={isManage}
        grandTotal={includeSalesTax()}
      />
    </>
  )
}

export default EventClinicConfirmationTab
