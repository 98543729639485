// Third party
import { yupResolver } from '@hookform/resolvers/yup'
import { Visibility, VisibilityOffOutlined } from '@mui/icons-material'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import { CONST } from '../../../const/const'
import FIREBASE_CONST from '../../../const/firebase-const'
import { ROUTES_CONST } from '../../../const/routes-const'
import MessageHelperComp from '../../../helpers/MessageHelper'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { UserModel } from '../../../models/users/user.model'
import FirebaseApp from '../../../services/firebaseApp'
import FirestoreService from '../../../services/firestoreService'
import { useAppDispatch } from '../../../store/hooks'
import { storeUseMode } from '../../../store/system/systemThunk'
import { MODAL_CONSTS } from '../../../const/modal-const'
import {
  setCompetitionProfileImageUrl,
  setDisplayName,
  setEmail,
  setIsLoggedIn,
  setUserId,
  setUsername,
  storeUserId,
} from '../../../store/user/userSlice'
import { CustomError, authHelpers, getUserFullName } from '../../../helpers/helpers'
import { ITEAMMEMBER_MODAL_TYPES } from '../../home/modals/UserProfileTeamTabAddTeamMember'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const userLoginViewComponentDefaultValues = {
  email: '',
  password: '',
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const userLoginViewComponentSchemaValidation = yup
  .object({
    email: yup
      .string()
      .required('email or username is required')
      .test('no-spaces', 'email or username should not contain spaces', (value) => {
        if (value) return !/\s/.test(value)
        return true
      }),
    password: yup.string().required(),
  })
  .required()

type Props = {
  closeAuthModal: () => void
  handleModal: (open: boolean, type: string) => void
  redirectAfterLogin?: string
  registerHandler?: () => void
  eventId?: string
}

const AuthenticationModalLoginComponent = (props: Props) => {
  const [innerType, setInnerType] = useState('password')
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()
  const history = useHistory()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: userLoginViewComponentDefaultValues,
    resolver: yupResolver(userLoginViewComponentSchemaValidation),
    mode: 'onChange',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const handleOnLoginValid = async (data: any) => {
    setLoading(true)
    try {
      const emailOrError = await authHelpers.getEmail(data.email)

      if (emailOrError instanceof CustomError) {
        setLoading(false)
        return toastFunctions.error({ message: emailOrError.message })
      }

      const user = await signInWithEmailAndPassword(
        FirebaseApp.auth,
        emailOrError as any,
        data.password
      )

      if (user) {
        const user_data_doc: any = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
          user.user.uid
        )

        const userData = UserModel.fromFirestoreDoc(user_data_doc).toObject()

        dispatch(setEmail(userData.userEmail?.trim()))
        dispatch(setUsername(userData.userName?.trim()))
        dispatch(setDisplayName(getUserFullName(userData)))
        dispatch(setCompetitionProfileImageUrl(userData.userProfilePicture))

        if (userData.userType === CONST.USE_MODE.COMPETITOR) {
          dispatch(storeUseMode(CONST.USE_MODE.COMPETITOR))
          if (props.eventId) {
            history.replace(`${CONST.ROUTES.REGISTER_EVENT.URL}/${props.eventId}/${userData.id}`, {
              direction: 'none',
            })
          } else {
            history.replace('/home', { direction: 'none' })
          }
        } else if (userData.userType === CONST.USE_MODE.ORGANIZER) {
          dispatch(storeUseMode(CONST.USE_MODE.ORGANIZER))
          history.replace(`${CONST.ROUTES.ORGANIZER_HOME.URL}`, {
            direction: 'none',
          })
        }

        dispatch(setIsLoggedIn(true))
        dispatch(storeUserId(user.user.uid))
        dispatch(setUserId(user.user.uid))

        props.closeAuthModal()
      }
    } catch (error: any) {
      console.log({ error })
      setLoading(false)
      if (error.code === FIREBASE_CONST.USER_NOT_FOUND)
        return toastFunctions.error({
          message: "This account doesn't exist. Please sign up or try again",
        })
      if (error.code === FIREBASE_CONST.WRONG_PASSWORD)
        return toastFunctions.error({ message: 'Credentials are incorrect' })

      toastFunctions.error({ message: 'Something went wrong' })
    } finally {
      setLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const handleLoginInvalid = async (data: any) => {}

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const closeAndNavigate = () => {
    props.closeAuthModal()
    history.push(ROUTES_CONST.SIGNUP.URL)
  }

  return (
    <>
      <form
        className="space-y-8"
        onSubmit={(e) => handleSubmit(handleOnLoginValid, handleLoginInvalid)(e)}
      >
        <div className="my-6">
          <label className="mb-3 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-[#122B4633] border-[1px] bg-transparent">
            <span className="w-[22px]">
              <img src="/assets/cp_icons/Mail-1.svg" alt="icon" />
            </span>
            <input
              {...register('email')}
              placeholder="Email or Username"
              className="flex-1 border-0 outline-0 text-SeabiscuitDark200ThemeColor bg-transparent placeholder:text-[#122B4680] focus:ring-transparent w-full focus:border-none py-[0.5rem] px-[0.75rem]"
            />
          </label>
          {errors.email && (
            <MessageHelperComp
              className="mb-3 !mt-0"
              isError={true}
              message={errors.email.message}
            />
          )}

          <label className="mb-3 flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-[#122B4633] border-[1px] bg-transparent">
            <div>
              <img src="/assets/cp_icons/Lock-1.svg" alt="icon" />
            </div>
            <input
              type={innerType}
              {...register('password')}
              placeholder="Password"
              className="flex-1 border-0 outline-0 text-SeabiscuitDark200ThemeColor bg-transparent placeholder:text-[#122B4680] focus:ring-transparent w-full focus:border-none"
            />
            <div onClick={() => setInnerType(innerType === 'password' ? 'text' : 'password')}>
              {innerType === 'password' ? (
                <VisibilityOffOutlined className="text-[#122B4680]" />
              ) : (
                <Visibility className="text-[#122B4680]" />
              )}
            </div>
          </label>
          {errors.password && (
            <MessageHelperComp
              className="mb-3 !mt-0"
              isError={true}
              message={errors.password.message}
            />
          )}
        </div>

        <div className="w-[70%] mt-[80px] mx-auto">
          <button
            disabled={loading}
            type="submit"
            className="w-full h-12 flex items-center justify-center py-2 px-4 border border-solid border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium nextBtnShadow focus:outline-none focus:ring-0 bg-SeabiscuitMainThemeColor text-SeabiscuitGrayThemeColor hover:bg-[#d70443]"
          >
            {loading ? <AutorenewIcon fontSize="small" className="animate-spin" /> : 'LOG IN'}
          </button>
        </div>

        <div className="text-SeabiscuitDark200ThemeColor text-[14px] text-center">
          Don’t have an account?
          <span
            className="text-SeabiscuitMainThemeColor ml-1 cursor-pointer text-[16px] hover:text-[#d70443]"
            onClick={props.registerHandler ? props.registerHandler : closeAndNavigate}
          >
            {' '}
            Sign up
          </span>
        </div>

        <div className="flex justify-center mt-4">
          <div className="text-sm ">
            <div
              onClick={() => props.handleModal(true, MODAL_CONSTS.FORGOT_PASSWORD)}
              className="font-normal text-SeabiscuitDark200ThemeColor hover:text-SeabiscuitMainThemeColor cursor-pointer"
            >
              Forgot your password?
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default AuthenticationModalLoginComponent
