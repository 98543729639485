// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { IonContent, IonList } from '@ionic/react'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'react-router-dom'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */

type Props = {
  title: string
  children?: any
  clinicPage?: boolean
  discipline?: any[]
  category?: string
  detailPageUrl: string
}

const CompetitorEventRegisterContainer = (props: Props) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <IonContent fullscreen style={{ '--ion-background-color': '#F6F7FB', '--offset-top': '56px' }}>
      <div className="mx-7 pt-6">
        <h1 className="text-SeabiscuitDark200ThemeColor mb-5 event_comp">
          <Link to={props.detailPageUrl}>
            <KeyboardBackspace className="text-SeabiscuitDark200ThemeColor mr-2" />
          </Link>
          <span className="mr-4 text-xl font-bold">{props.title}</span>
          <div className="border border-SeabiscuitDark200ThemeColor capitalize rounded-full py-0.5 px-4 mr-4 inline-block">
            {props.category}
          </div>
          {props.discipline && props.discipline.length
            ? props.discipline.map((currentItem: string, index: number) => {
                return (
                  <div
                    key={`${currentItem}${index}`}
                    className="border border-SeabiscuitDark200ThemeColor capitalize rounded-full py-0.5 px-4 mr-4 inline-block"
                  >
                    {currentItem}
                  </div>
                )
              })
            : null}
        </h1>
        <IonList>{props.children}</IonList>
      </div>
    </IonContent>
  )
}

export default CompetitorEventRegisterContainer
