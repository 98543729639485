/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useContext, useEffect, useState } from 'react'

// Constants
import { CONST } from '../../../const/const'
import { MODAL_CONSTS } from '../../../const/modal-const'

// Redux
import { selectFilesToUpload, setFilesToUpload } from '../../../store/files/filesSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

// Helpers
import { getConvertedData } from '../../../models/interface.helper'

// Services
import FirestoreService from '../../../services/firestoreService'
import FirebaseStorageService from '../../../services/storageService'

// Models
import { EventDetailsModel } from '../../../models/event-details/event-details.model'
import { EventModel } from '../../../models/events/event.model'

// Components
import EventDetailFormComponent from '../../../components/events/forms/detail/EventDetailFormComponent'

// Context
import { useParams } from 'react-router'
import { BackgroundContext } from '../../../helpers/BackgroundProvider'
import { HandleModalContext } from '../../../layout/mainlayout/MainLayout'
import { selectPocDetails, selectUserProfileImageUrl } from '../../../store/user/userSlice'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface ShowsDetailPageEventDetailFormSubSectionProps {
  onSetEventTab: (tab: string) => void
  onSetNextEventTab: (tab: string) => void
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  loading: boolean
  mainEvent?: any
  nextEventTab: string
  eventTab?: string
}

export const EVENT_DOC_TYPES_CONST = {
  COVER_IMAGE: '1',
  UPLOAD_VIDEO: '4',
  EVENT_IMAGES: '2',
  COURSE_MAP_IMAGES: '3',
}

const FILES_TO_REMOVE_INI_VAL = {
  filesAddedButNotSaved: [],
  filesRemovedButNotSaved: [],
}

export type IFilesToRemove = {
  filesAddedButNotSaved: string[]
  filesRemovedButNotSaved: string[]
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsDetailPageEventDetailFormSubSection: React.FC<
  ShowsDetailPageEventDetailFormSubSectionProps
> = (props) => {
  // Hoks and vars
  const dispatch = useAppDispatch()
  const [data, setData] = useState<any>(null)
  const events = useAppSelector((store) => store.events)
  const [eventData, setEventData] = useState<any>(null)
  const [allowNavigation, setAllowNavigation] = useState<boolean>(false)
  const [filesToRemove, seFilesToRemove] = useState<IFilesToRemove>(FILES_TO_REMOVE_INI_VAL)
  const backgroundContext = useContext(BackgroundContext)
  const OrganizerProfilePicture = useAppSelector(selectUserProfileImageUrl)

  const pocDetails = useAppSelector(selectPocDetails)

  const editedEvent = events.editedEvent
  const fileUpload = useAppSelector(selectFilesToUpload)

  const { eventId } = useParams<{ eventId: string }>()

  const [event_error, set_event_error] = useState(false)
  const handleModalContext = useContext(HandleModalContext)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (fileUpload.filesToUpload.length) {
      const newUserData = EventDetailsModel.fromObject(data)
      updateEventTemporarily(fileUpload.type as any, fileUpload.filesToUpload, newUserData)
    }
  }, [fileUpload])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    seFilesToRemove(FILES_TO_REMOVE_INI_VAL)
  }, [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const validFormHandler = async (data_: any) => {
    let updated = true

    seFilesToRemove({ ...filesToRemove, filesAddedButNotSaved: [] })

    const updated_data = new EventDetailsModel({
      ...data_,
      eventLogo: OrganizerProfilePicture,
    })

    const EventDetailObj = updated_data.toObject()

    let eventDataUpdated = {
      ...eventData,
      competitionLong: EventDetailObj.competitionLong,
      competitionLat: EventDetailObj.competitionLat,
    }

    let withLocation = new EventModel(eventDataUpdated)

    try {
      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_DETAILS.NAME,
        eventId,
        updated_data.toFirestore()
      )

      setData(updated_data.toObject())
      seFilesToRemove({ ...filesToRemove, filesAddedButNotSaved: [] })

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
        eventId,
        withLocation.toFirestore()
      )
    } catch (reason) {
      updated = false
      console.error('202206261334:' + reason)
    } finally {
      return {
        updated,
        onSetEventTab:
          props.nextEventTab == '' ? props.onSetEventTab : props.onSetEventTab(props?.nextEventTab),
        onSetNextEventTab: props.onSetNextEventTab(''),
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const validAndExitFormHandler = async (data: any) => {
    seFilesToRemove({ ...filesToRemove, filesAddedButNotSaved: [] })
    setAllowNavigation(true)
    return await validFormHandler(data)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidFormHandler = async (data: any) => {
    props.onSetNextEventTab('')
    console.log(data)
    if (Object.keys(data).length > 0) {
      set_event_error(true)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidAndExitFormHandler = async (data: any) => {
    props.onSetNextEventTab('')
    console.log(data)
    if (Object.keys(data).length > 0) {
      set_event_error(true)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const handleExitWithoutSave = () => {
    backgroundContext?.pushToBackground({
      functionToRunInBackground: async function () {
        await deleteRemovedFiles(filesToRemove.filesAddedButNotSaved)
      },
      notifyOnFinish: true,
      messageToShow: null,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @type 1 => eventCardCoverImageUrl, 2 => competitionEventImage, 3 => competitionCourseMapImages, 4 => competitionVideo
   */
  async function updateEventTemporarily(
    type: '1' | '2' | '3' | '4',
    urls: string[],
    newUserData: EventDetailsModel
  ) {
    seFilesToRemove({
      ...filesToRemove,
      filesAddedButNotSaved: [...filesToRemove.filesAddedButNotSaved, ...urls],
    })

    if (Array.isArray(urls) && urls.length > 0) {
      switch (type) {
        case EVENT_DOC_TYPES_CONST.COVER_IMAGE:
          if (typeof urls[0] !== 'undefined') {
            newUserData.eventCardCoverImageUrl = urls[0]
          }
          break
        case EVENT_DOC_TYPES_CONST.EVENT_IMAGES:
          newUserData.competitionEventImage = [...newUserData.competitionEventImage, ...urls]
          break
        case EVENT_DOC_TYPES_CONST.COURSE_MAP_IMAGES:
          newUserData.competitionCourseMapImages = [
            ...newUserData.competitionCourseMapImages,
            ...urls,
          ]
          break
        case EVENT_DOC_TYPES_CONST.UPLOAD_VIDEO:
          newUserData.competitionEventVideo = [
            ...(Array.isArray(newUserData.competitionEventVideo)
              ? newUserData.competitionEventVideo
              : []),
            ...urls,
          ]
          break
      }

      dispatch(setFilesToUpload({ downloadUrls: [], id: '' }))
      setData(() => newUserData.toObject())
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  async function fileChangeHandler(type: string, url?: string) {
    const newUserData = EventDetailsModel.fromObject(data)
    let indexOfUrl: null | number = null
    const currentFilesToRemove: string[] = []

    if (url) {
      switch (type) {
        case EVENT_DOC_TYPES_CONST.COVER_IMAGE:
          newUserData.eventCardCoverImageUrl = ''
          currentFilesToRemove.push(url)
          break

        case EVENT_DOC_TYPES_CONST.EVENT_IMAGES:
          indexOfUrl = newUserData.competitionEventImage?.indexOf(url)
          if (!isNaN(indexOfUrl) && indexOfUrl !== -1) {
            newUserData.competitionEventImage.splice(indexOfUrl, 1)
            currentFilesToRemove.push(url)
          }
          break

        case EVENT_DOC_TYPES_CONST.COURSE_MAP_IMAGES:
          indexOfUrl = newUserData.competitionCourseMapImages?.indexOf(url)
          if (!isNaN(indexOfUrl) && indexOfUrl !== -1) {
            newUserData.competitionCourseMapImages.splice(indexOfUrl, 1)
            currentFilesToRemove.push(url)
          }
          break

        case EVENT_DOC_TYPES_CONST.UPLOAD_VIDEO:
          indexOfUrl = newUserData.competitionEventVideo?.indexOf(url)
          if (!isNaN(indexOfUrl) && indexOfUrl !== -1) {
            newUserData.competitionEventVideo.splice(indexOfUrl, 1)
            currentFilesToRemove.push(url)
          }
          break
      }

      seFilesToRemove({ ...filesToRemove, filesRemovedButNotSaved: currentFilesToRemove })

      dispatch(setFilesToUpload({ downloadUrls: [], id: '' }))
      setData(() => newUserData.toObject())
    } else
      handleModalContext?.handleModal(true, MODAL_CONSTS.UPLOAD_EVENT_IMAGES, { id: eventId, type })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (!data && pocDetails) {
      const fetchData = async () => {
        const doc = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_DETAILS.NAME,
          eventId
        )

        const eventSnapshot = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
          eventId
        )

        if (doc) {
          const eventData = EventDetailsModel.fromFirestoreDoc(doc).toObject()
          const event = EventModel.fromFirestoreDoc(eventSnapshot).toObject()
          const competitionName = event?.eventName ?? ''

          if (!!!eventData.competitionName) eventData.competitionName = competitionName

          if (!!!eventData?.competitionPOCEmail)
            eventData.competitionPOCEmail = pocDetails?.pocEmail ?? ''

          if (!!!eventData?.competitionPOCName)
            eventData.competitionPOCName = pocDetails?.pocFullName ?? ''

          if (!!!eventData?.competitionPOCPhone)
            eventData.competitionPOCPhone = pocDetails?.pocPhone ?? ''

          setData(eventData)
        }
      }
      fetchData().catch()
    }
  }, [data, setData, eventId, editedEvent, pocDetails])

  const deleteRemovedFiles = async (filesToRemove: string[]) => {
    return await new Promise(async (resolve) => {
      if (!filesToRemove.length) resolve(true)
      let filesToBeRemoved = [...(filesToRemove ?? [])]
      let i = 0
      let maxLoopsToRun = 0

      while (i < filesToBeRemoved?.length && maxLoopsToRun < filesToBeRemoved.length) {
        const removeFile = async () => {
          return await FirebaseStorageService.deleteFile(filesToBeRemoved?.[i])
        }

        try {
          await removeFile()
        } catch (error) {
        } finally {
          i++
          maxLoopsToRun++
        }
      }

      if (i <= filesToBeRemoved.length) {
        resolve(true)
      }
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (!eventData) {
      const fetchData = async () => {
        const doc = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
          eventId
        )
        if (doc) {
          setEventData(EventModel.fromFirestoreDoc(doc).toObject())
        }
      }
      fetchData().catch()
    }
  }, [eventData, setEventData, eventId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <EventDetailFormComponent
      mainEvent={props.mainEvent}
      eventId={eventId}
      loading={props.loading}
      event_error={event_error}
      onValid={validFormHandler}
      data={getConvertedData(data)}
      onInvalid={invalidFormHandler}
      allowNavigation={allowNavigation}
      fileChangeHandler={fileChangeHandler}
      onValidAnExit={validAndExitFormHandler}
      onInvalidAndExit={invalidAndExitFormHandler}
      handleExitWithoutSave={handleExitWithoutSave}
      nextEventTab={props.nextEventTab}
      onSetNextEventTab={props.onSetNextEventTab}
      eventTab={props.eventTab}
      seFilesToRemove={seFilesToRemove}
    />
  )
}

export default ShowsDetailPageEventDetailFormSubSection
