import React from 'react'

interface IProps {}

export const IconPhoto: React.FC<IProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-camera-fill"
      viewBox="0 0 16 16"
    >
      <path d="M10.5 8.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0"></path>
      <path d="M2 4a2 2 0 00-2 2v6a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1.172a2 2 0 01-1.414-.586l-.828-.828A2 2 0 009.172 2H6.828a2 2 0 00-1.414.586l-.828.828A2 2 0 013.172 4zm.5 2a.5.5 0 110-1 .5.5 0 010 1m9 2.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0"></path>
    </svg>
  )
}
