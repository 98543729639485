import { useState } from 'react'

// Third party
import { AutorenewRounded } from '@mui/icons-material'
import moment from 'moment/moment'
import { Clear } from '@mui/icons-material'
import { where } from 'firebase/firestore'

// Redux
import { setSelectedRegistration } from '../../../store/bills/billsSlice'
import { useAppDispatch } from '../../../store/hooks'

// Types
import { ICompetitorEventRegisterTypes as ICerhTypes } from '../../../pages/competitor/competitorEventRegister/competitorEventRegisterHelper'
import { IRecipientInterface } from '../../../models/recipients/recipients.interface'
import { RecipientModel } from '../../../models/recipients/recipients'

// Services
import FirestoreService from '../../../services/firestoreService'

// Constants
import { MODAL_CONSTS } from '../../../const/modal-const'
import { CONST } from '../../../const/const'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'

type OrganizerMarkPaidModalProps = {
  show: boolean
  handleModal: any
  dataToPassOn: {
    saveAllTabs: ICerhTypes['ISaveAllTabsFn']
    bill: IRecipientInterface
    registrationsByDay: IRegistrationByDayInterface[]
  }
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

const OrganizerMarkPaidModal = ({
  handleModal,
  show,
  dataToPassOn,
}: OrganizerMarkPaidModalProps) => {
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState<boolean>(false)
  const [paymentType, setPaymentType] = useState<'check' | 'cash'>('check')

  const closeModal = () => {
    handleModal(false, MODAL_CONSTS.ORGANIZER_MARK_PAID)
  }

  const onSavePayment = async () => {
    setLoading(true)
    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      dataToPassOn.bill.registrationDocId,
      {
        paymentStatus: PAYMENT_STATUSES.PAID,
      }
    )
    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.NAME,
      dataToPassOn.bill.id,
      {
        paymentType: paymentType,
        paymentDate: Date.now(),
        paymentStatus: PAYMENT_STATUSES.PAID,
      }
    )

    for (const registrationByDay of dataToPassOn.registrationsByDay) {
      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        registrationByDay.id,
        {
          paymentStatus: PAYMENT_STATUSES.PAID,
        }
      )
    }

    const recipientSnapshots = await FirestoreService.filterItems(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.NAME,
      [
        where(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.REGISTRATION_DOC_ID.KEY,
          '==',
          dataToPassOn.bill.registrationDocId ?? ''
        ),
        where(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.RECIPIENT_ID.KEY,
          '==',
          dataToPassOn.bill.userId ?? ''
        ),
      ]
    )

    const recipient = RecipientModel.fromFirestoreDoc(recipientSnapshots.docs[0]).toObject()

    if (recipient) dispatch(setSelectedRegistration(recipient))
    handleModal(false, MODAL_CONSTS.ORGANIZER_MARK_PAID)
    handleModal(true, MODAL_CONSTS.BILL_BRIEFE, { bill: recipient })
    setLoading(false)
  }

  return (
    <div
      className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pt-10 pb-7 px-8 max-h-[90vh]">
          <span
            onClick={() => {
              if (loading) return
              closeModal()
            }}
            className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
          >
            <Clear
              fontSize={'small'}
              style={{
                color: 'grey',
                fontWeight: '400',
              }}
            />
          </span>
          <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
            <h5
              className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-bold pb-2"
              id="exampleModalScrollableLabel"
            >
              Mark Paid?
            </h5>
          </div>
          <div className="min-h-1/2 overflow-y-auto">
            <p className="text-sm text-SeabiscuitDark200ThemeColor mb-4">
              This will mark this bill as paid and will no longer be able to paid electronically
              through Pegasus.
            </p>
            <p className="text-sm text-SeabiscuitDark200ThemeColor mb-4">
              Select the payment method used for your records.
            </p>
            <div className="mt-3 w-full border-solid p-3 border-[#D3DAEE] border rounded-2xl">
              <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">Payment details:</p>
              <div className="flex w-full items-center mt-2 h-6">
                <img
                  className="relative w-6 h-6 object-contain -left-[2px]"
                  src={'assets/img/dark/Dollarcoin.svg'}
                  alt="icons"
                />
                <p className="text-sm text-SeabiscuitDark200ThemeColor ml-1">
                  ${dataToPassOn.bill.amountPaid.toFixed(2)}
                </p>
              </div>
              <div className="flex w-full items-center mt-2 h-6">
                <img
                  className="w-5 h-5 object-contain"
                  src={'assets/img/dark/wallet.svg'}
                  alt="icons"
                />
                <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2">
                  Payment date: {moment(Date.now()).format('DD MMM, YYYY')}
                </p>
              </div>
              <div
                className="flex w-full justify-between items-center mt-2 h-6 cursor-pointer hover:opacity-50 transition-all"
                onClick={() => setPaymentType('check')}
              >
                <img
                  className="w-5 h-5 object-contain"
                  src={'assets/img/dark/check.svg'}
                  alt="icons"
                />
                <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2 mr-auto">
                  Paid by Check
                </p>
                <span className="pl-2">
                  {paymentType === 'check' ? (
                    <img
                      className="relative -right-[1px]"
                      alt="icons"
                      src={'assets/og_icons/Ok-1.svg'}
                    />
                  ) : (
                    <img alt="icons" src={'assets/og_icons/circle.svg'} />
                  )}
                </span>
              </div>
              <div
                className="flex w-full justify-between items-center mt-2 h-6 cursor-pointer hover:opacity-50 transition-all"
                onClick={() => setPaymentType('cash')}
              >
                <img className="w-5" src={'assets/img/dark/cash-2.svg'} alt="icons" />
                <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2 mr-auto">
                  Paid by Cash
                </p>
                <span className="pl-2">
                  {paymentType === 'cash' ? (
                    <img
                      className="relative -right-[1px]"
                      alt="icons"
                      src={'assets/og_icons/Ok-1.svg'}
                    />
                  ) : (
                    <img alt="icons" src={'assets/og_icons/circle.svg'} />
                  )}
                </span>
              </div>
            </div>
          </div>

          <button
            disabled={loading}
            type="submit"
            className="w-full h-12 mx-auto py-2 px-4 mt-5 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:bg-[#D70443] focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:text-SeabiscuitLightTextColor disabled:bg-SeabiscuitLightThemeColor focus:ring-SeabiscuitMainThemeColor"
            onClick={onSavePayment}
          >
            {loading ? (
              <AutorenewRounded fontSize="small" className="animate-spin" />
            ) : (
              'SAVE PAYMENT'
            )}
          </button>

          <button
            disabled={loading}
            onClick={() => {
              if (loading) closeModal()
            }}
            type="button"
            className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:bg-[#0b15261a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default OrganizerMarkPaidModal
