import { useCallback, useEffect, useMemo, useState } from 'react'

// Component
import SalesChart from '../common/SalesChart'

// Constants
import { ICONS_SRC } from './ManageClinicNOtherRootPage'

// Redux
import { useAppSelector } from '../../../../store/hooks'

// Types
import { TEventRegisteredUsers } from '../../../../models/event-registered-users/event-registered-users.interface'
import { getConvertedData } from '../../../../models/interface.helper'
import { selectedEvent } from '../../../../store/events/eventsSlice'
import { ISpectatorTickets } from '../../../../models/spectator-tickets/spectator-tickets.interface'
import FirestoreService from '../../../../services/firestoreService'
import { CONST } from '../../../../const/const'
import { where } from 'firebase/firestore'
import { SpectatorTicketModel } from '../../../../models/spectator-tickets/spectator-tickets-model'
import { IRegistrationTicketInterface } from '../../../../models/registration-tickets/registrationTicket.interface'
import { RegistrationTicketModel } from '../../../../models/registration-tickets/registrationTicket.model'
import { IRecipientInterface } from '../../../../models/recipients/recipients.interface'
import { RecipientModel } from '../../../../models/recipients/recipients'

type IRevenueOnMonthBasis = {
  [x: string]: number
}

type IRegistrationOnUserTypeBasis = {
  EXHIBITOR: IRecipientInterface[]
  SPECTATOR: (ISpectatorTickets | IRegistrationTicketInterface)[]
  VENDOR: TEventRegisteredUsers[]
  SPONSOR: TEventRegisteredUsers[]
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants
export const USER_TYPES = {
  EXHIBITOR: 'Exhibitor',
  SPECTATOR: 'Spectator',
  VENDOR: 'Vendor',
  SPONSOR: 'Sponsor',
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const ManageClinicNOtherGraphSection = () => {
  // Hooks and vars
  const selectedEventDetails = useAppSelector(selectedEvent)

  const [spectatorTickets, setSpectatorTickets] = useState<ISpectatorTickets[]>([])
  const [registrationTickets, setRegistrationTickets] = useState<IRegistrationTicketInterface[]>([])
  const [recipients, setRecipients] = useState<IRecipientInterface[]>([])

  useEffect(() => {
    async function getSpectatorTickets() {
      let spectatorTickets_: ISpectatorTickets[] = []
      let registrationTickets_: IRegistrationTicketInterface[] = []
      let recipients_: IRecipientInterface[] = []

      try {
        const spectatorSnapshots = await FirestoreService.filterItems(
          COLLECTIONS.SPECTATOR_TICKETS.NAME,
          [
            where(
              COLLECTIONS.SPECTATOR_TICKETS.FIELDS.EVENT_ID,
              '==',
              selectedEventDetails.basicEventDetails.id
            ),
            where(
              COLLECTIONS.SPECTATOR_TICKETS.FIELDS.PAYMENT_STATUS.KEY,
              '==',
              COLLECTIONS.SPECTATOR_TICKETS.FIELDS.PAYMENT_STATUS.VALUES.PAID
            ),
          ]
        )

        const registrationTicketSnapshots = await FirestoreService.filterItems(
          COLLECTIONS.REGISTRATION_TICKET.NAME,
          [
            where(
              COLLECTIONS.REGISTRATION_TICKET.FIELDS.EVENT_ID.KEY,
              '==',
              selectedEventDetails.basicEventDetails.id
            ),
            where(
              COLLECTIONS.REGISTRATION_TICKET.FIELDS.PAYMENT_STATUS.KEY,
              '==',
              COLLECTIONS.SPECTATOR_TICKETS.FIELDS.PAYMENT_STATUS.VALUES.PAID
            ),
          ]
        )

        const recipientSnapshots = await FirestoreService.filterItems(COLLECTIONS.RECIPIENT.NAME, [
          where(
            COLLECTIONS.RECIPIENT.FIELDS.EVENT_ID.KEY,
            '==',
            selectedEventDetails.basicEventDetails.id
          ),
          where(
            COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.KEY,
            '==',
            COLLECTIONS.SPECTATOR_TICKETS.FIELDS.PAYMENT_STATUS.VALUES.PAID
          ),
        ])

        spectatorSnapshots.forEach((currSpectatorTicketSnapshot) => {
          spectatorTickets_.push(
            SpectatorTicketModel.fromFirestoreDoc(currSpectatorTicketSnapshot).toObject()
          )
        })

        registrationTicketSnapshots.forEach((currRegistrationTicketSnapshot) => {
          registrationTickets_.push(
            RegistrationTicketModel.fromFirestoreDoc(currRegistrationTicketSnapshot).toObject()
          )
        })

        recipientSnapshots.forEach((recipient) => {
          recipients_.push(RecipientModel.fromFirestoreDoc(recipient).toObject())
        })
      } catch (error) {
        console.log(error)
      } finally {
        setSpectatorTickets(spectatorTickets_)
        setRegistrationTickets(registrationTickets_)
        setRecipients(recipients_)
      }
    }

    if (selectedEventDetails.basicEventDetails.id) getSpectatorTickets().then()
    else setSpectatorTickets([])
  }, [selectedEventDetails.basicEventDetails.id])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Getting only the registrations on this year
   */
  const registrationOnThisYear = useMemo(() => {
    return (recipients ?? []).filter((recipient) => {
      let { registrationDate } = getConvertedData({
        registrationDate: recipient?.registrationDate,
      })
      if (!registrationDate || recipient.paymentStatus !== 'paid') return false
      let registrationYear = new Date(registrationDate)?.getUTCFullYear()
      let currentYear = new Date()?.getUTCFullYear()
      return registrationYear === currentYear
    })
  }, [recipients])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Getting only the registrations on this year
   */
  const spectatorPurchaseOnThisYear = useMemo(() => {
    return (spectatorTickets ?? []).filter((currSpectatorTicket) => {
      let { created } = getConvertedData({ created: currSpectatorTicket?.created })
      if (!created || currSpectatorTicket.paymentStatus !== 'paid') return false
      let purchaseYear = new Date(created)?.getUTCFullYear()
      let currentYear = new Date()?.getUTCFullYear()
      return purchaseYear === currentYear
    })
  }, [spectatorTickets])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Getting only the registrations on this year
   */
  const registrationTicketsPurchaseOnThisYear = useMemo(() => {
    return (registrationTickets ?? []).filter((currRegistrationTicket) => {
      let { created } = getConvertedData({ created: currRegistrationTicket?.created })
      if (!created || currRegistrationTicket.paymentStatus !== 'paid') return false
      let purchaseYear = new Date(created)?.getUTCFullYear()
      let currentYear = new Date()?.getUTCFullYear()
      return purchaseYear === currentYear
    })
  }, [registrationTickets])

  const registrationOnUserTypeBasis: IRegistrationOnUserTypeBasis = useMemo(() => {
    let registrations_: IRecipientInterface[] = recipients ?? []

    return {
      EXHIBITOR: registrations_.filter((currRegistration) => {
        return currRegistration.paymentStatus === 'paid'
      }),
      SPECTATOR: [...spectatorTickets, ...registrationTicketsPurchaseOnThisYear].filter(
        (currRegistration) => {
          return currRegistration.paymentStatus === 'paid'
        }
      ),
      VENDOR: [],
      SPONSOR: [],
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationOnThisYear, spectatorPurchaseOnThisYear, registrationTicketsPurchaseOnThisYear])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Splits up the registrations revenue of month basis, from jan to dec
   */
  const getRevenueOnMonthBasis = (typeOf: keyof IRegistrationOnUserTypeBasis) => {
    let divisionType = typeOf?.toLocaleLowerCase()
    let t = (type: string) => type?.toLowerCase()
    switch (true) {
      case divisionType === t(USER_TYPES.EXHIBITOR):
        return registrationOnUserTypeBasis.EXHIBITOR.reduce(
          (allRegistrations: IRevenueOnMonthBasis, currRegistration) => {
            let { registrationDate } = getConvertedData({
              registrationDate: currRegistration?.registrationDate,
            })
            if (!registrationDate) return allRegistrations
            let creationDate = new Date(registrationDate)

            let registrationMonth = creationDate?.getMonth() + 1

            if (isNaN(allRegistrations[`${registrationMonth}`]))
              allRegistrations[`${registrationMonth}`] = 0

            allRegistrations[`${registrationMonth}`] += currRegistration.amountPaid ?? 0

            return allRegistrations
          },
          {}
        )

      case divisionType === t(USER_TYPES.SPECTATOR):
        return registrationOnUserTypeBasis.SPECTATOR.reduce(
          (allSpectatorTickets: IRevenueOnMonthBasis, currSpectatorTicket) => {
            let { created } = getConvertedData({ created: currSpectatorTicket?.created })

            if (!created) return allSpectatorTickets
            let creationDate = new Date(created)

            let registrationMonth = creationDate?.getMonth() + 1

            if (isNaN(allSpectatorTickets[`${registrationMonth}`]))
              allSpectatorTickets[`${registrationMonth}`] = 0

            if ('boughtTicketCount' in currSpectatorTicket) {
              allSpectatorTickets[`${registrationMonth}`] +=
                currSpectatorTicket?.boughtTicketCount * currSpectatorTicket.ticketPrice
            } else {
              allSpectatorTickets[`${registrationMonth}`] +=
                currSpectatorTicket?.selectedUnitsCount * currSpectatorTicket.ticketPrice
            }
            return allSpectatorTickets
          },
          {}
        )

      case divisionType === t(USER_TYPES.VENDOR):
        return {}
      case divisionType === t(USER_TYPES.SPONSOR):
        return {}
    }
  }

  // Gets the data for chart from the db
  const getChartData = useCallback(() => {
    let revenueOnCurrMonth = 0
    const ONE_K = 1000
    const JAN = 1
    const DEC = 12
    let revenueOnMonthBasis: IRevenueOnMonthBasis

    // Exhibitor revenue data
    const exhibitorChartdata = (function () {
      let arr: number[] = []
      for (let i = JAN; i <= DEC; i++) {
        revenueOnMonthBasis = getRevenueOnMonthBasis('EXHIBITOR') ?? {}
        revenueOnCurrMonth = isNaN(revenueOnMonthBasis[i.toString()])
          ? 0
          : revenueOnMonthBasis[i.toString()]
        arr.push(Number((revenueOnCurrMonth / ONE_K).toFixed(1)))
      }
      return arr
    })()

    const spectatorChartData = (function () {
      let arr: number[] = []
      for (let i = JAN; i <= DEC; i++) {
        revenueOnMonthBasis = getRevenueOnMonthBasis('SPECTATOR') ?? {}
        revenueOnCurrMonth = isNaN(revenueOnMonthBasis[i.toString()])
          ? 0
          : revenueOnMonthBasis[i.toString()]
        arr.push(Number((revenueOnCurrMonth / ONE_K).toFixed(1)))
      }
      return arr
    })()

    const othersChartData = (function () {
      let arr: number[] = []
      for (let i = JAN; i <= DEC; i++) {
        revenueOnMonthBasis = getRevenueOnMonthBasis('SPONSOR') ?? {}
        revenueOnCurrMonth = isNaN(revenueOnMonthBasis[i.toString()])
          ? 0
          : revenueOnMonthBasis[i.toString()]
        arr.push(Number((revenueOnCurrMonth / ONE_K).toFixed(1)))
      }
      return arr
    })()

    return {
      EXHIBITOR: exhibitorChartdata,
      SPECTATOR: spectatorChartData,
      VENDOR: othersChartData,
      SPONSOR: othersChartData,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationOnUserTypeBasis])

  return (
    <div className="flex justify-start flex-wrap w-full">
      <SalesChart
        data={getChartData().EXHIBITOR}
        title={USER_TYPES.EXHIBITOR}
        iconSrc={ICONS_SRC.EXHIBITOR}
      />
      <SalesChart
        data={getChartData().SPECTATOR}
        title={USER_TYPES.SPECTATOR}
        iconSrc={ICONS_SRC.SPECTATOR}
      />
      <SalesChart
        data={getChartData().VENDOR}
        title={USER_TYPES.VENDOR}
        iconSrc={ICONS_SRC.VENDOR}
      />
      <SalesChart
        data={getChartData().SPONSOR}
        title={USER_TYPES.SPONSOR}
        iconSrc={ICONS_SRC.SPONSOR}
      />
    </div>
  )
}

export default ManageClinicNOtherGraphSection
