// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import ModalComponent from '../global/modalComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentAllPoliciesModal: React.FC<{
  show: boolean
  onHide?: () => void
  handleModal?: any
  dataToPassOn?: any
}> = ({ show, onHide, handleModal, dataToPassOn }) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  return (
    <ModalComponent
      show={show}
      handleModal={handleModal}
      type="full-modal"
      modalFor="policies"
      title="Policies"
      size="lg"
    >
      <div className="grid grid-2 md:grid-cols-1 gap-2">
        <div className="grid grid-cols-1 gap-2 md:gap-y-3">
          {dataToPassOn?.eventRules && (
            <div className="p-4 grid grid-cols-1 md:grid-cols-[20%_80%] gap-2 border-[1px] border-solid border-transparent md:border-[#D3DAEE] pr-7 rounded-xl">
              <div className="text-[16px] text-SeabiscuitDark200ThemeColor font-normal">
                Event Rules
                <img src="/assets/cp_icons/Scroll-1.svg" alt="event rules" />
              </div>
              <div className="text-SeabiscuitDark200ThemeColor text-[16px]">
                {dataToPassOn?.eventRules}
              </div>
            </div>
          )}
          {dataToPassOn?.attireTack && (
            <div className="p-4 grid grid-cols-1 md:grid-cols-[20%_80%] gap-2 border-[1px] border-solid  border-transparent md:border-[#D3DAEE] pr-7 rounded-xl">
              <div className="text-[16px] text-SeabiscuitDark200ThemeColor font-normal">
                Attire, Tack
                <img src="/assets/cp_icons/Suit-1.svg" alt="event rules" />
              </div>
              <div className="text-SeabiscuitDark200ThemeColor text-[16px]">
                {dataToPassOn?.attireTack}
              </div>
            </div>
          )}
          {dataToPassOn?.orderOfGo && (
            <div className="p-4 grid grid-cols-1 md:grid-cols-[20%_80%] gap-2 border-[1px] border-solid  border-transparent md:border-[#D3DAEE] pr-7 rounded-xl">
              <div className="text-[16px] text-SeabiscuitDark200ThemeColor font-normal">
                Order of go
                <img src="/assets/cp_icons/Shortlist-1.svg" alt="event rules" />
              </div>
              <div className="text-SeabiscuitDark200ThemeColor text-[16px]">
                {dataToPassOn?.orderOfGo}
              </div>
            </div>
          )}
          {dataToPassOn?.parking && (
            <div className="p-4 grid grid-cols-1 md:grid-cols-[20%_80%] gap-2 border-[1px] border-solid  border-transparent md:border-[#D3DAEE] pr-7 rounded-xl">
              <div className="text-[16px] text-SeabiscuitDark200ThemeColor font-normal">
                Parking
                <img src="/assets/cp_icons/Fiat501.svg" alt="event rules" />
              </div>
              <div className="text-SeabiscuitDark200ThemeColor text-[16px]">
                {dataToPassOn?.parking}
              </div>
            </div>
          )}
          {dataToPassOn?.rvs && (
            <div className="p-4 grid grid-cols-1 md:grid-cols-[20%_80%] gap-2 border-[1px] border-solid  border-transparent md:border-[#D3DAEE] pr-7 rounded-xl">
              <div className="text-[16px] text-SeabiscuitDark200ThemeColor font-normal">
                RV's
                <img src="/assets/og_icons/Railcar-1.svg" alt="event rules" />
              </div>
              <div className="text-SeabiscuitDark200ThemeColor text-[16px]">
                {dataToPassOn?.rvs}
              </div>
            </div>
          )}
          {dataToPassOn?.substitutions && (
            <div className="p-4 grid grid-cols-1 md:grid-cols-[20%_80%] gap-2 border-[1px] border-solid  border-transparent md:border-[#D3DAEE] rounded-xl">
              <div className="text-[16px] text-SeabiscuitDark200ThemeColor font-normal">
                Substitutions
                <img src="/assets/og_icons/People-1.svg" alt="event rules" />
              </div>
              <div className="text-SeabiscuitDark200ThemeColor text-[16px]">
                {dataToPassOn?.substitutions}
              </div>
            </div>
          )}
          {dataToPassOn?.ribbons && (
            <div className="p-4 grid grid-cols-1 md:grid-cols-[20%_80%] gap-2 border-[1px] border-solid  border-transparent md:border-[#D3DAEE]  rounded-xl">
              <div className="text-[16px] text-SeabiscuitDark200ThemeColor font-normal">
                Ribbons
                <img src="/assets/cp_icons/Cancer Ribbon-1.svg" alt="event rules" />
              </div>
              <div className="text-SeabiscuitDark200ThemeColor text-[16px]">
                {dataToPassOn?.ribbons}
              </div>
            </div>
          )}
          {dataToPassOn?.extra.map((item: any) => {
            return (
              <div className="p-4 grid grid-cols-1 md:grid-cols-[20%_80%] gap-2 border-[1px] border-solid  border-transparent md:border-[#D3DAEE]  rounded-xl">
                <div className="text-[16px] text-SeabiscuitDark200ThemeColor font-normal">
                  {item?.name}
                  <img src="/assets/cp_icons/Scroll-1.svg" alt="event rules" />
                </div>
                <div className="text-SeabiscuitDark200ThemeColor text-[16px]">{item?.policy}</div>
              </div>
            )
          })}
        </div>
      </div>
    </ModalComponent>
  )
}

export default EventDetailViewComponentAllPoliciesModal
