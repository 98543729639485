import React, { useState } from 'react'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import clsx from 'clsx'
import ModalComponent from '../../../../events/views/details/global/modalComponent'
import { AutorenewRounded } from '@mui/icons-material'

//type
type Props = {
  show?: boolean
  handleModal?: any
  dataToPassOn?: any
}

const DeletecardModal = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const { handleModal, dataToPassOn } = props

  const closeModal = (event: string) => {
    handleModal(false, MODAL_CONSTS.REMOVE_USER_CARD_ACCOUNT_MODAL)
  }

  const handelDelete = () => {
    setLoading(true)
    dataToPassOn.confirmDelete(dataToPassOn.pmId, setLoading)
  }

  const CheckOutFormFooter = () => {
    return (
      <div className="ActionButton mt-5">
        <button
          disabled={loading}
          type="button"
          onClick={() => {
            handelDelete()
          }}
          className="w-52 mb-2 h-12 m-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor focus-visible:outline-none disabled:text-SeabiscuitLightTextColor disabled:bg-SeabiscuitLightThemeColor"
        >
          {loading ? <AutorenewRounded fontSize="small" className="animate-spin" /> : 'REMOVE'}
        </button>

        <button
          type="button"
          onClick={() => {
            closeModal('closeClicked')
          }}
          className="w-52 mb-2 h-12 m-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor"
        >
          CANCEL
        </button>
      </div>
    )
  }

  return (
    <ModalComponent
      size="xs"
      show={true}
      fullScreen={false}
      onHide={() => closeModal('closeClicked')}
      headingClassName={'flex items-center justify-center w-full'}
      ModalBetweenModal="h-fit"
      modaltopclassname={clsx('md:max-h-[240px] !min-h-[100px] pb-0')}
      type={MODAL_CONSTS.CLOSE_EVENT_CONFIRMATION}
    >
      <div className="h-full flex flex-col justify-between text-center pb-10">
        <p className="text-SeabiscuitDark200ThemeColor text-lg font-semibold">
          Are you sure you want to remove?
        </p>
        {CheckOutFormFooter()}
      </div>
    </ModalComponent>
  )
}

export default DeletecardModal
