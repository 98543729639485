/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

// Constants
import { CONST } from '../../../../const/const'
import { UserModel } from '../../../../models/users/user.model'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { setUserMode } from '../../../../store/system/systemSlice'

// Services
import helpers from '../../../../commonHelpers/helpers'
import { getConvertedData } from '../../../../models/interface.helper'
import { IUserInterface } from '../../../../models/users/user.interface'
import FirestoreService from '../../../../services/firestoreService'
import UserService from '../../../../services/userService'
import { RootState } from '../../../../store/store'
import { setProfileDetails } from '../../../../store/user/userSlice'
import useLogout from './useLogout'

type IProps = {
  dontFetch?: boolean
}

const useProfileHook = ({ dontFetch = false }: IProps) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const logout = useLogout().logout
  const userReducer = useAppSelector((state: RootState) => state.user)

  const { userId, isFirstTime } = userReducer
  const [mUserDetails, setMUserDetails] = useState<IUserInterface | undefined>(undefined) // Modelled profile data

  const [loading, setLoading] = useState(false)

  // Functions
  const getModelledProfileDataFn = async (idOfUserToGet?: string) => {
    const user_doc = await UserService.getUserInfoById(idOfUserToGet ?? userId)

    if (!user_doc.exists() && !isFirstTime) {
      await logout()
    }

    return UserModel.fromFirestoreDoc(user_doc).toObject()
  }

  const getModelledUserDetails = (dataToConvert: any) => {
    return new UserModel({
      ...userReducer,
      ...userReducer.profileDetails,
      userNotificationsSettings: userReducer?.userNotificationsSettings,
      ...dataToConvert,
    }).toFirestore()
  }

  const updateUserDetails = async (
    dataToUpdate: any
  ): Promise<{ status: boolean; message?: any }> => {
    let mUserDetails_ = mUserDetails ? { ...mUserDetails } : null
    let updateTeamMembers = false
    let mutated: any[] = []

    setLoading(true)

    if (dataToUpdate?.hasOwnProperty('userTeamMembers')) {
      updateTeamMembers = true
      mutated = Array.isArray(dataToUpdate?.userTeamMembers) ? dataToUpdate?.userTeamMembers : []
      if (mutated.length) {
        mutated = mutated.map((member: any) => {
          if (!('memberAuthorized' in member) || member.memberAuthorized === undefined) {
            return { ...member, memberAuthorized: '0' }
          } else {
            return member
          }
        })
      }
    }

    if (updateTeamMembers) {
      dataToUpdate.userTeamMembers = mutated
    }

    try {
      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        userId,
        dataToUpdate
      )

      if (!mUserDetails_) {
        const data = await getModelledProfileDataFn()
        if (data) {
          mUserDetails_ = data
          setMUserDetails(data)
        }
      }

      dispatch(
        setProfileDetails(
          getConvertedData({
            ...mUserDetails_,
            ...dataToUpdate,
          })
        )
      )

      return { status: true }
    } catch (err) {
      helpers.logger({
        isError: true,
        message: err,
      })

      return {
        status: false,
        message: err,
      }
    } finally {
      setLoading(false)
    }
  }

  const updateStripeConnectDetails = async (
    userStripeAccountId: IUserInterface['userStripeAccountId'],
    userStripeAccountStatus: IUserInterface['userStripeAccountStatus']
  ): Promise<{ status: boolean; error?: any }> => {
    setLoading(true)

    try {
      await updateUserDetails({
        userStripeAccountId: userStripeAccountId ?? null,
        userStripeAccountStatus: userStripeAccountStatus ?? null,
      })

      return { status: true }
    } catch (error) {
      return {
        status: false,
        error,
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (userId && dontFetch === false) {
      const setProfileDataFn = async () => {
        const data = await getModelledProfileDataFn()
        if (data) {
          dispatch(setUserMode(data.userType))
          setMUserDetails(data)
        }
      }
      setProfileDataFn().catch((err) => {
        helpers.logger({
          isError: true,
          message: err,
        })
      })
    }
  }, [userId])

  return {
    loading,
    mUserDetails,
    updateUserDetails,
    getModelledUserDetails,
    getModelledProfileDataFn,
    updateStripeConnectDetails,
  }
}

export default useProfileHook
