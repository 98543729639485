// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { TEventTickets } from '../../../../../models/event-review-publish/event-review-publish.interface'
import ModalComponent from '../global/modalComponent'

// Constants
import { CONST } from '../../../../../const/const'

import { useIonRouter } from '@ionic/react'
import { IHandleModal } from '../../../../../layout/mainlayout/MainLayout'
import { IEventInterface } from '../../../../../models/events/event.interface'
import { IEventDetails } from '../../../../../models/event-details/event-details.interface'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { useHistory } from 'react-router-dom'

type IDataToPassOn = {
  eventTableData: IEventInterface | null
  EventDetails: IEventDetails | null
  EventTickets: TEventTickets | null
}

type IEventDetailViewComponentSpectatorTicketsModalProps = {
  show: boolean
  dataToPassOn?: IDataToPassOn
  handleModal?: IHandleModal['handleModal']
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentSpectatorTicketsModal: React.FC<
  IEventDetailViewComponentSpectatorTicketsModalProps
> = ({ show, handleModal, dataToPassOn }) => {
  // Hooks and vars
  const router = useIonRouter()
  const history = useHistory()

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  return (
    <ModalComponent
      show={show}
      handleModal={handleModal}
      type="full-modal"
      modalFor="spectatorTickets"
      title="Spectator Tickets"
      size="md"
    >
      <div className="grid grid-2 md:grid-cols-1 gap-2 mr-4">
        <div className="grid grid-cols-1 gap-2 md:gap-y-3">
          <div className=" min-h-[300px]">
            {dataToPassOn?.EventTickets?.tickets && dataToPassOn?.EventTickets?.tickets.length
              ? dataToPassOn?.EventTickets?.tickets.map((currentItem) => {
                  return (
                    <div
                      key={JSON.stringify(currentItem)}
                      className="p-4 grid grid-cols-1 md:grid-cols-[20%_80%] gap-2 border-[1px] border-solid border-transparent md:border-[#D3DAEE] rounded-xl mb-4"
                    >
                      <div className="text-[14px] mt-6 md:mt-0 text-SeabiscuitDark200ThemeColor font-semibold">
                        {currentItem.name}
                      </div>
                      <div className="text-SeabiscuitDark200ThemeColor text-[14px]">
                        <ul>
                          <li className="text-SeabiscuitDark200ThemeColor text-nr font-normal">{`${currentItem.available - currentItem.sold} available, $${currentItem.cost} per ticket`}</li>
                          <li className="mt-2 text-SeabiscuitLightParagraphTextColor opacity-[84%]">
                            {currentItem.note}
                          </li>
                        </ul>
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
          <div
            onClick={() => {
              typeof handleModal === 'function' &&
                handleModal(false, MODAL_CONSTS.SPECTATOR_TICKETS)
              router.push(`${CONST.ROUTES.TICKETS.PUSH_URL}/${dataToPassOn?.eventTableData?.id}`)
              history.push(`${CONST.ROUTES.TICKETS.PUSH_URL}/${dataToPassOn?.eventTableData?.id}`)
            }}
          >
            <button
              type="button"
              className="bg-SeabiscuitMainThemeColor !text-[14px] text-SeabiscuitWhiteThemeColor m-auto w-[60%] block mt-4 rounded-xl border border-solid border-SeabiscuitGrayLight100ThemeColor px-4 py-4 mb-2"
            >
              Purchase Tickets
            </button>
          </div>
        </div>
      </div>
    </ModalComponent>
  )
}

export default EventDetailViewComponentSpectatorTicketsModal
