import { FC, useState } from 'react'

import MainModal from './common/MainModal'

import { MODAL_CONSTS } from '../../const/modal-const'

interface ResultsLockedModalProps {
  dataToPassOn: { setIsLocked: (value: boolean) => void }
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}
export const ResultsLockedModal: FC<ResultsLockedModalProps> = ({ handleModal, dataToPassOn }) => {
  const [value, setValue] = useState('')

  const handleSubmit = () => {
    dataToPassOn.setIsLocked(false)
    handleModal(false, MODAL_CONSTS.RESULTS_LOCKED)
  }

  return (
    <MainModal
      size="md"
      title="Results locked"
      titleClassName="!font-normal"
      modalClassName="z-[100] h-max border border-SeabiscuitLightThemeColorD3 shadow-md"
      className="!h-max !p-0"
      type="RESULTS_LOCKED"
      show={true}
      buttons={[
        {
          label: 'Submit',
          fullWidth: true,
          disabled: value !== 'UNLOCK',
          bgClass: 'bg-SeabiscuitMainThemeColor',
          textClass: 'text-white',
          onClick: () => handleSubmit(),
          onHoverClass: 'hover:bg-[#d70443]',
        },
        {
          label: 'Cancel',
          fullWidth: true,
          onClick: () => handleModal(false, MODAL_CONSTS.RESULTS_LOCKED),
          bgClass: 'bg-transparent',
          textClass: 'text-SeabiscuitMainThemeColor',
          borderClass: 'border !border-SeabiscuitMainThemeColor !w-full',
          onHoverClass: 'hover:text-white hover:bg-SeabiscuitMainThemeColor',
        },
      ]}
    >
      <div className="text-SeabiscuitDark200ThemeColor">
        <p className="mb-1">These results have been locked to protect the saved scores.</p>
        <p>Type UNLOCK in the field below to unlock and edit them.</p>
        <label className="block mt-7">
          <input
            className="w-full rounded p-3 border-SeabiscuitLightThemeColorD3"
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </label>
      </div>
    </MainModal>
  )
}
