// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { IEventVendors } from './event-vendors.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import * as yup from 'yup'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export class EventVendorsModel extends ModelBaseModel<IEventVendors> implements IModelBaseModel {
  public v: number
  public id: string | null
  public owner: string | null

  public acceptVendorsOnGrounds: boolean
  public vendorOpportunities: any
  public acceptVirtualVendors: boolean
  public virtualVendorSpotsAvailable: string
  public virtualVendorPrice: string
  public virtualVendorNote: string
  public created: Date
  public modified: Date

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: IEventVendors) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? null

    this.acceptVendorsOnGrounds = obj?.acceptVendorsOnGrounds ?? false
    this.vendorOpportunities = obj?.vendorOpportunities ?? []
    this.acceptVirtualVendors = obj?.acceptVirtualVendors ?? false
    this.virtualVendorSpotsAvailable = obj?.virtualVendorSpotsAvailable ?? ''
    this.virtualVendorPrice = obj?.virtualVendorPrice ?? ''
    this.virtualVendorNote = obj?.virtualVendorNote ?? ''

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new EventVendorsModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  static validationSchema() {
    return yup.object({}).shape({})
  }
}
