import { CONST } from '../../../../../../const/const'

export const EVENT_SPONSORS_FB_TEMPLATE_SAMPLE = {
  data: {
    vMain: 1,
    vSub: 0,
    vFix: 0,
    items: [
      // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
      //  Hunter
      {
        disciplineType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DISCIPLINE.TYPE.STANDARD,
        discipline: 'Hunter',
        validationStatus: 'unknown',
        divisionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.DIVISION.TYPE.STANDARD,
        divisionItemsRules: null,
        divisions: [
          {
            // ************************************************************
            //  1900 - Hunter Breeding
            divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
            divisionCode: '1900',
            divisionTitle: 'Hunter Breeding',
            sectionItemsType:
              CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
            sectionItemsRules: null,
            sections: [
              {
                sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
                sectionSubCode: {
                  type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                  meta: null,
                  value: 'Sub Code 01',
                  validation: [
                    {
                      type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                      errorMessage: "Field can't be empty",
                    },
                  ],
                  rules: null,
                },
                sectionCost: {
                  type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
                  meta: null,
                  value: 0,
                  validation: [
                    {
                      type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                      errorMessage: 'Must be a valid number',
                    },
                    {
                      type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                      errorMessage: "Value Can't Be Zero",
                    },
                    {
                      type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                      errorMessage: "Value Can't Be Negative",
                    },
                  ],
                  rules: null,
                },
                sectionStatus: {
                  type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.BOOLEAN,
                  meta: null,
                  value: true,
                  validation: null,
                  rules: null,
                },
              },
              {
                sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
                sectionSubCode: {
                  type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                  meta: null,
                  value: 'Sub Code 01',
                  validation: [
                    {
                      type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                      errorMessage: "Field can't be empty",
                    },
                  ],
                  rules: null,
                },
                sectionCost: {
                  type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
                  meta: null,
                  value: 0,
                  validation: null,
                  rules: null,
                },
                sectionStatus: {
                  type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.BOOLEAN,
                  meta: null,
                  value: true,
                  validation: null,
                  rules: null,
                },
              },
            ],
            extraSections: [],
            extraSectionTemplate: [],
          },
        ],
      },
    ],
    summaryItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUMMARY.TYPE.STANDARD,
    summary: {
      totalDivisionsOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      totalSectionsOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      totalClassesOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      totalMiscellaneousOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      totalClassicsOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      totalDerbysOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      totalLeaguesOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      equitationDivisionsPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      hunterDivisionsPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      jumperDivisionsPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      miscellaneousPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      classicsPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      derbysPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },

      showTotalPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
    },
  },
}
