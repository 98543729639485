import { Clear } from '@mui/icons-material'
import { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import CustomDatePicker from '../../../components/common/inputs/CustomDatePicker'
import './Modelcss.css'

type Props = {
  show: boolean
  handleModal?: any
}

const Location = [
  { label: 'Alabama', value: 'alabama' },
  { label: 'Alaska', value: 'alaska' },
  { label: 'Alaska', value: 'alaska' },
  { label: 'Arkansas', value: 'arkansas' },
  { label: 'California', value: 'california' },
  { label: 'Colorado', value: 'colorado' },
  { label: 'Connecticut', value: 'connecticut' },
  { label: 'Delaware', value: 'delaware' },
  { label: 'Florida', value: '' },
  { label: 'Georgia', value: '' },
  { label: 'Hawaii', value: '' },
  { label: 'Idaho', value: '' },
  { label: 'Illinois', value: '' },
  { label: 'Indiana', value: '' },
  { label: 'Iowa', value: '' },
  { label: 'Kansas', value: '' },
  { label: 'Kentucky', value: '' },
  { label: 'Louisiana', value: '' },
  { label: 'Maine', value: '' },
  { label: 'Maryland', value: '' },
  { label: 'Massachusetts', value: '' },
  { label: 'Michigan', value: '' },
  { label: 'Minnesota', value: '' },
  { label: 'Mississippi', value: '' },
  { label: 'Missouri', value: '' },
  { label: 'Montana', value: '' },
  { label: 'Nebraska', value: '' },
  { label: 'Nevada', value: '' },
  { label: 'New Hampshire', value: '' },
  { label: 'New Jersey', value: '' },
  { label: 'New Mexico', value: '' },
  { label: 'New York', value: '' },
  { label: 'North Carolina', value: '' },
  { label: 'North Dakota', value: '' },
  { label: 'Oklahoma', value: '' },
  { label: 'Oregon', value: '' },
  { label: 'Pennsylvania', value: '' },
  { label: 'Rhode Island', value: '' },
  { label: 'South Carolina', value: '' },
  { label: 'South Dakota', value: '' },
  { label: 'Tennessee', value: '' },
  { label: 'Texas', value: '' },
  { label: 'Utah', value: '' },
  { label: 'Vermont', value: '' },
  { label: 'Virginia', value: '' },
  { label: 'Washington', value: '' },
  { label: 'West Virginia', value: '' },
  { label: 'Wisconsin', value: '' },
  { label: 'Wyoming', value: '' },
]
let Discipline = [
  { title: 'Eventing' },
  { title: 'Dressage' },
  { title: 'Hunter Jumper' },
  { title: 'Hunter' },
  { title: 'Jumper' },
  { title: 'Equitation' },
  { title: 'All' },
]
let EventType = [
  { title: 'Licensed' },
  { title: 'Schooling' },
  { title: 'Clinic' },
  { title: 'Other' },
]

const ViewFilterModal = (props: Props) => {
  const [locationFilter, setLocationFilter] = useState<string[]>([])
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)

  const handleChangeLocation = (name: any) => {
    const data = locationFilter.filter((item: string) => {
      return item === name
    })

    if (data.length) {
      const remainingData = locationFilter.filter((item: string) => {
        return item !== name
      })
      setLocationFilter(remainingData)
    } else {
      setLocationFilter((prevState) => {
        return [...prevState, name]
      })
    }
  }

  const handleClearFilters = () => {
    setLocationFilter([])
    setStartDate(null)
    setEndDate(null)
    const ele: NodeListOf<HTMLElement> = document.getElementsByName('checkbox')
    for (var i = 0; i < ele.length; i++) {
      if ((ele[i] as HTMLInputElement).type === 'checkbox')
        (ele[i] as HTMLInputElement).checked = false
    }
  }

  const handleSubmit = () => {}

  return (
    <div
      className={`modal fade fixed z-50 w-full top-0 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative w-235 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-lg outline-none text-current">
          <div className="py-2 text-center text-SeabiscuitDark200ThemeColor font-bold border-b border-b-[#1f41734d]">
            Filter Events
          </div>

          <span
            onClick={() => props.handleModal(false)}
            className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
          >
            {/* <img
                            src="/assets/og_icons/Close.svg"
                            className="w-6"
                            alt="close icon"
                        /> */}
            <Clear
              fontSize={'small'}
              style={{
                color: '#122B46',
                fontWeight: '400',
              }}
            />
          </span>
          <div className="px-6 max-min-h-[70vh] overflow-y-auto">
            <div className="py-4 rounded-t-md">
              <h5 className="text-xl font-bold leading-normal text-SeabiscuitDark200ThemeColor">
                Discipline
              </h5>
            </div>

            <div className="flex flex-row flex-wrap justify-start pb-8 border-b border-b-[#1F41731A]">
              {Discipline.map((item) => {
                return (
                  <div className="cstmCheckbox cstmaction border border-[#122B4633] text-[#BCC6D5] whitespace-nowrap hover:bg-SeabiscuitMainThemeColor hover:text-SeabiscuitWhiteThemeColor group cursor-pointer rounded-md w-auto mr-2 mb-2 overflow-hidden float-left">
                    <label className="float-left cursor-pointer">
                      <input
                        type="checkbox"
                        value="1"
                        className="absolute hidden text-[#BCC6D5] cursor-pointer"
                      />
                      <span className="text-center block pt-3 pb-3 pl-4 pr-4 cursor-pointer text-[#BCC6D5] hover:text-SeabiscuitWhiteThemeColor">
                        {item.title}
                      </span>
                    </label>
                  </div>
                )
              })}
            </div>
            <div className="py-4 rounded-t-md">
              <h5 className="text-xl font-bold leading-normal text-SeabiscuitDark200ThemeColor">
                Event Type
              </h5>
            </div>
            <div className="flex flex-row flex-wrap justify-start pb-8 border-b border-b-[#1F41731A]">
              {EventType.map((item) => {
                return (
                  <div className="cstmCheckbox cstmaction border border-[#122B4633] text-[#BCC6D5] whitespace-nowrap hover:bg-SeabiscuitMainThemeColor hover:text-SeabiscuitWhiteThemeColor group cursor-pointer rounded-md w-auto mr-2 mb-2 overflow-hidden float-left">
                    <label className="float-left cursor-pointer">
                      <input
                        type="checkbox"
                        value="1"
                        className="absolute hidden text-[#BCC6D5] cursor-pointer"
                      />
                      <span className="text-center block pt-3 pb-3 pl-4 pr-4 cursor-pointer text-[#BCC6D5] hover:text-SeabiscuitWhiteThemeColor">
                        {item.title}
                      </span>
                    </label>
                  </div>
                )
              })}
            </div>
            <div className="py-4 rounded-t-md">
              <h5 className="text-xl font-bold leading-normal text-SeabiscuitDark200ThemeColor">
                Date
              </h5>
            </div>

            <div className="startEndDateFilter relative grid gap-40 grid-cols-2 border-b border-b-[#1F41731A] pb-6">
              <CustomDatePicker
                peekNextMonth
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                onChange={(date) => setStartDate(date)}
                selected={startDate}
                placeholderText="Start date"
                className={`rounded-md w-full border focus:ring-SeabiscuitMainThemeColor  placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-center placeholder:text-sm opacity-1 py-2.5 text-sm border-[#D3DAEE] pl-0`}
              />

              <CustomDatePicker
                peekNextMonth
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                onChange={(date) => setEndDate(date)}
                selected={endDate}
                placeholderText="End date"
                wrapperClassName="datePickerCustom"
                className={`rounded-md w-full border focus:ring-SeabiscuitMainThemeColor  placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-center placeholder:text-sm opacity-1 py-2.5 text-sm border-[#D3DAEE] ml-auto float-right rightDatePicker`}
              />
            </div>

            <div className="py-4 rounded-t-md">
              <h5 className="text-xl font-bold leading-normal text-SeabiscuitDark200ThemeColor">
                Location
              </h5>
            </div>

            {Location.length && (
              <div className="grid gap grid-cols-3 grid-rows-3 pb-8 border-b border-b-[#1F41731A]">
                {Location.map((item: any, index: number) => {
                  return (
                    <div className="displineCheckBoxfilter">
                      <input
                        className="form-check-input appearance-none h-4 w-4 border border-[#D3DAEE] rounded-sm bg-white checked:bg-SeabiscuitMainThemeColor checked:border-none focus:outline-none focus:ring-0 focus:ring-offset-0 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="checkbox"
                        name="checkbox"
                        value={item.value}
                        id={item.label}
                        onChange={(e) => handleChangeLocation(e.target.value)}
                      />
                      <label
                        htmlFor={item.label}
                        className="labelFilter cursor-pointer text-SeabiscuitLightThemeColorFilter text-sm"
                      >
                        {item.label}
                      </label>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          <div className="flex items-center justify-between px-4 py-4">
            <button
              onClick={handleClearFilters}
              type="button"
              className="block text-sm font-medium underline text-[#1F4173B2]"
            >
              Clear filters
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              className="w-1/3 h-12 block items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:text-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewFilterModal
