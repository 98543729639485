// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { filter } from 'lodash'
import { CONST } from '../../../../../../../../../../../const/const'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */
export class EventPrizeListFormBuilderUtilValidationHunterStandardSubSectionConformationOptionHelper {
  public field?: any
  public parentField?: any
  public rootField?: any
  public previousValue?: boolean
  public newValue?: boolean
  public setValue?: any
  public getValues?: any
  public setError?: any
  public clearErrors?: any
  public trigger?: any
  public disciplineIndex?: number
  public divisionIndex?: number
  public sectionIndex?: number
  public subSectionIndex?: number

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: any) {
    this.field = obj?.field ?? null
    this.parentField = obj?.parentField ?? null
    this.rootField = obj?.rootField ?? null
    this.previousValue = obj?.previousValue ?? null
    this.newValue = obj?.newValue ?? null
    this.setValue = obj?.setValue ?? null
    this.getValues = obj?.getValues ?? null
    this.setError = obj?.setError ?? null
    this.clearErrors = obj?.clearErrors ?? null
    this.trigger = obj?.trigger ?? null
    this.disciplineIndex = obj?.disciplineIndex ?? null
    this.divisionIndex = obj?.divisionIndex ?? null
    this.sectionIndex = obj?.sectionIndex ?? null
    this.subSectionIndex = obj?.subSectionIndex ?? null
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public validate() {
    let errors = false

    let validation_item_check = null

    if (!errors) {
      if (this.rootField?.subsectionItemsRules) {
        validation_item_check = filter(this.rootField?.subsectionItemsRules, {
          type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.RULES.HUNTER
            .HUNTER_STANDARD_NO_CONFORMATION_CLASS,
        })

        if (validation_item_check.length) {
          errors = this._validateNoConformationClass(validation_item_check[0].errorMessage)
        }
      }

      if (!errors) {
        validation_item_check = filter(this.rootField?.subsectionItemsRules, {
          type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.RULES.HUNTER
            .HUNTER_STANDARD_ONE_CONFORMATION_CLASS,
        })

        if (validation_item_check.length) {
          errors = this._validateOneConformationClass(validation_item_check[0].errorMessage)
        }
      }

      if (!errors) {
        validation_item_check = filter(this.rootField?.subsectionItemsRules, {
          type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.RULES.HUNTER
            .HUNTER_STANDARD_NO_SAME_CLASS,
        })

        if (validation_item_check.length) {
          errors = this._validateNoSameClass(validation_item_check[0].errorMessage)
        }
      }
    }

    if (!errors) {
      this.clearErrors(
        `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionConformationClass.value`
      )
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  private _validateNoConformationClass(error_message: string) {
    let items = this.getValues(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.subSections`
    )

    let no_saddle_class_filter_check = filter(items, [
      'subSectionConformationClass.meta.selected',
      true,
    ])

    if (no_saddle_class_filter_check.length) {
      this._setErrorMessage(error_message)
      return true
    }

    return false
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  private _validateOneConformationClass(error_message: string) {
    let items = this.getValues(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.subSections`
    )

    let no_saddle_class_filter_check = filter(items, [
      'subSectionConformationClass.meta.selected',
      true,
    ])

    if (no_saddle_class_filter_check.length > 1) {
      this._setErrorMessage(error_message)
      return true
    }

    return false
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  private _validateNoSameClass(error_message: string) {
    let items = this.getValues(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.subSections`
    )

    let same_class = false

    items.forEach((item: any) => {
      if (item.subSectionConformationClass?.meta?.selected === true) {
        if (!same_class) {
          if (item.subSectionHandyClass?.meta?.selected === true) {
            same_class = true
          }
          if (item.subSectionUnderSaddleClass?.meta?.selected === true) {
            same_class = true
          }
        }
      }
    })

    if (same_class) {
      this._setErrorMessage(error_message)
      return true
    }

    return false
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  private _setErrorMessage(error_message: string) {
    this.setError(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionConformationClass.value`,
      { type: 'custom', message: error_message }
    )
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  private _clearError() {
    this.clearErrors(
      `data.items.${this.disciplineIndex}.divisions.${this.divisionIndex}.sections.${this.sectionIndex}.sectionConformationClass.value`
    )
  }
}
