/* eslint-disable jsx-a11y/anchor-is-valid */
import UserSignupViewComponentButton from './global/UserSignupViewComponentButton'
import { useContext, useState } from 'react'
import { SignupStages } from './UserSignupViewComponentContextApi'
import UserSignupViewComponentInput from './global/UserSignupViewComponentInput'

import backIcon from '../assets/arrow-left.svg'
import USEFIcon from '../assets/usef-icon.svg'
import eventOrgIcon from '../assets/event-org.png'

const UserSignupViewComponentCompetitorOrganizer: React.FC = () => {
  const [stage, setStage] = useContext(SignupStages)

  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  const [USEF, setUSEF] = useState('')

  return (
    <div>
      <div className="text-[22px] flex items-center gap-2 text-white">
        <button onClick={() => stageHandle('CompOrgRoleType')}>
          <img src={backIcon} alt="back" className="invert" />
        </button>
        {/* COMPETITOR, TRAINER, SPECTATOR, ETC. */}
      </div>
      <div className="mt-10">
        <img src={eventOrgIcon} alt="icon" className="rounded-full shadow-xl mx-auto" />
      </div>
      <div className="mt-[60px]">
        <p className="text-[18px] text-white mt-4">Enter your USEF exhibitor number.</p>
        <UserSignupViewComponentInput
          type="text"
          value={USEF}
          onChange={(e: any) => setUSEF(e.target.value)}
          placeholder="USEF number"
          icon={USEFIcon}
        />
      </div>
      <div className="w-[70%] mt-[60px] mx-auto">
        <UserSignupViewComponentButton
          caption="SEARCH"
          kind={1}
          disabled={!stage.selector}
          onClick={() => stageHandle('CompOrgVerify')}
        />
      </div>
      <div className="text-center text-white opacity-50 mt-10">OR</div>
      <div className="my-10 text-center text-white">
        Don’t have a USEF number?{' '}
        <a
          className="text-SeabiscuitMainThemeColor cursor-pointer"
          onClick={() => stageHandle('SignUp')}
        >
          Continue &gt;
        </a>
      </div>
    </div>
  )
}

export default UserSignupViewComponentCompetitorOrganizer
