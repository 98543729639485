import { useState } from 'react'
import helpers from '../../commonHelpers/helpers'
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import { horseBreedList } from '../../fakeData/horseBreedList'
import useToasterHelper from '../../helpers/ToasterHelper'
import useHorses from '../../hooks/users/competitor/profile/useHorses'
import { IHorseData } from '../../models/horse/horse.interface'
import { IUserHorseMappingInterface } from '../../models/user-horse-mapping/userHorseMapping.interface'
import MainModal from './common/MainModal'
import {useAppSelector} from "../../store/hooks";
import {selectUserId} from "../../store/user/userSlice";

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type Props = {
  show?: boolean
  handleModal?: any
  dataToPassOn?: {
    selectedHorses: IHorseData[]
    userHorseMapping: IUserHorseMappingInterface[]
  }
}

// Constants

const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'

const RemoveHorseModel = (props: Props) => {
  // Hooks and vars
  const toastFunctions = useToasterHelper()

  const [loading, setLoading] = useState<boolean>(false)


  const userId = useAppSelector(selectUserId)

  const { removeHorseFromDb, mappings } = useHorses(userId)

  const selectedHorses = props.dataToPassOn?.selectedHorses ?? []

  // Functions

  const handleSubmit = async () => {
    let removed = true
    let removed_ = false
    let isError = false
    let currLoopIndex = 0
    let mapping: IUserHorseMappingInterface | null = null
    let mappingsToRemove: IUserHorseMappingInterface[] = []

    selectedHorses.forEach((currHorse) => {
      mappings.data.find((currMapping) => {
        if (currMapping.horseId === currHorse.id) {
          mappingsToRemove.push(currMapping)
          return true
        }
        return false
      })
    })

    if (selectedHorses.length !== mappingsToRemove.length)
      return toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

    setLoading(true)

    try {
      while (currLoopIndex < mappingsToRemove.length) {
        mapping = mappingsToRemove[currLoopIndex] ?? null

        if (mapping && mapping?.id) {
          removed_ = await removeHorseFromDb({
            mappingDocId: mapping?.id,
            forceRemove: true,
            dontShowToast: true,
          })
          if (!removed_) {
            removed = false
            break
          }
        }

        currLoopIndex++
      }

      if (removed) {
        toastFunctions.success({
          message:
            mappingsToRemove.length === 1
              ? MESSAGES_CONST.HORSE_REMOVED
              : MESSAGES_CONST.HORSES_REMOVED,
        })

        closeThisAndOpenPrev()
      } else isError = true
    } catch (error) {
      isError = true

      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      if (!isError) setLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  function closeThisAndOpenPrev() {
    props?.handleModal?.(false, MODAL_CONSTS.REMOVE_HORSE)
    props?.handleModal?.(true, MODAL_CONSTS.SEARCH_HORSE_BY_NAME, {
      removeHorse: true,
    })
  }

  return (
    <MainModal
      size="md"
      titleClassName="!font-[500]"
      title="Remove horse?"
      type="REMOVE_HORSE"
      show={props.show ?? false}
      buttons={[
        {
          label: 'REMOVE',
          fullWidth: true,
          loading,
          onClick: handleSubmit,
          textClass: '!text-white',
          bgClass: '!bg-SeabiscuitMainThemeColor',
        },
        {
          label: 'CANCEL',
          fullWidth: true,
          className: '!max-w-unset',
          onClick: closeThisAndOpenPrev,
          bgClass: '!bg-SeabiscuitLightThemeColor',
          borderClass: '!border border-transparent',
          textClass: '!text-SeabiscuitLightTextColor',
        },
      ]}
    >
      <>
        <div className="uselListWarp flex flex-col">
          {selectedHorses?.length ? (
            selectedHorses?.map((item: any, index: any) => {
              return (
                <label
                  key={index}
                  className="form-check mb-3 cursor-pointer"
                  htmlFor={'teammember' + index}
                >
                  <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                    <div className="min-w-fit flex items-center">
                      <img
                        src={
                          item.horseProfilePicture && item.horseProfilePicture !== ''
                            ? item.horseProfilePicture
                            : '/assets/og_icons/User-1.svg'
                        }
                        className="w-[40px] h-[40px] rounded-full mr-2 avatarImg object-cover"
                        alt="demoUser"
                      />
                      <div className="hourseDetails w-10/12">
                        <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                          <p className="text-SeabiscuitDark200ThemeColor text-lg">
                            {item?.horseName}
                          </p>
                          <p className="text-xs capitalize">
                            {horseBreedList.find(
                              (currBreed) => currBreed.value === item?.horseBreed
                            )?.label ?? item?.horseBreed}
                            <strong>.</strong> {item?.horseZone}{' '}
                          </p>
                        </div>
                      </div>
                    </div>

                    {item.selected ? (
                      <img
                        src={CHECKED_ICON}
                        className="relative right-[-3px] top-[0px]"
                        width={20}
                        height={20}
                        alt="checked"
                      />
                    ) : null}
                  </div>
                </label>
              )
            })
          ) : (
            <div className="w-full mt-2 text-center text-SeabiscuitDark200ThemeColor">
              No result
            </div>
          )}
        </div>
        <div className="formSection">
          <div className="relative mt-2">
            <img
              src="/assets/og_icons/Error-1.svg"
              className="w-6 absolute top-4 left-4"
              alt="MoneyBag"
            />
            <div className="relative w-full border border-[#122B461A] rounded-xl p-4 text-[13px] pl-12 h-fit overflow-auto">
              <p className="text-[#122B46]">
                When you click ‘remove’, you are removing this horse from yourself and your team.
              </p>
              <ul className="list-disc m-[30px] p-[6px] leading-6 mt-2 pb-0">
                <li className="text-[#122B46]">
                  You will <strong>not</strong> be able to <strong>access</strong> the horses data.
                </li>
                <li className="text-[#122B46]">
                  You will <strong>not</strong> be able to <strong>register</strong> the horse for
                  events.
                </li>
                <li className="text-[#122B46]">
                  Your team will no longer be able to register the horse for events.
                </li>
                <li className="text-[#122B46]">
                  Your team will no longer be able to access the horses data.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    </MainModal>
  )
}

export default RemoveHorseModel
