import { useEffect, useState } from 'react'

// Constants
import { CONST } from '../../../../const/const'
import { CompetitorProfileModel } from '../../../../models/competitor-profile/competitor-profile.model'

// Redux
import { useAppSelector } from '../../../../store/hooks'
import { selectUserId } from '../../../../store/user/userSlice'

// Services
import UserService from '../../../../services/userService'
import FirestoreService from '../../../../services/firestoreService'
import { ICompetitorProfileInterface } from '../../../../models/competitor-profile/competitor-profile.interface'

type IProps = {
  dontFetch?: boolean
}

const useCompetitorProfileHook = ({ dontFetch = false }: IProps) => {
  // Hooks and vars
  const userId = useAppSelector(selectUserId)
  const [mCompetitorProfile, setMCompetitorProfile] = useState<
    ICompetitorProfileInterface | undefined
  >(undefined) // Modelled user document
  const [loading, setLoading] = useState(false)

  // Functions
  const getModelledCompetitorProfileDataFn = async () => {
    const user_doc = await UserService.getCompetitorProfileDataByID(userId)
    return CompetitorProfileModel.fromFirestoreDoc(user_doc).toObject()
  }

  const getModelledCompetitorProfileData = (dataToConvert: any) => {
    return new CompetitorProfileModel(dataToConvert).toFirestore()
  }

  const updateCompetitorProfile = async (
    dataToUpdate: ICompetitorProfileInterface
  ): Promise<{ status: boolean; message?: any }> => {
    setLoading(true)
    try {
      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.COMPETITOR_PROFILE.NAME,
        userId,
        dataToUpdate
      )
      setLoading(false)
      return { status: true }
    } catch (err) {
      setLoading(false)
      return {
        status: false,
        message: err,
      }
    }
  }

  useEffect(() => {
    if (userId && dontFetch === false) {
      const setCompetitorProfileDataFn = async () => {
        const data = await getModelledCompetitorProfileDataFn()
        setMCompetitorProfile(data)
      }
      setCompetitorProfileDataFn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return { mCompetitorProfile, updateCompetitorProfile, loading, getModelledCompetitorProfileData }
}

export default useCompetitorProfileHook
