import { useEffect, useState } from 'react'

// Component imports
import ConfirmationCompsWrapper from './ConfirmationCompsWrapper'

// Redux
import SelectRecipient from './SelectRecipient'

import { CONST } from '../../../../../../const/const'

// Types
import clsx from 'clsx'
import { cloneDeep, size, sumBy, uniqBy } from 'lodash'
import helpers from '../../../../../../commonHelpers/helpers'
import { MESSAGES_CONST } from '../../../../../../const/messages-const'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'
import { CustomError } from '../../../../../../helpers/helpers'
import { IRegistrationByDayInterface } from '../../../../../../models/registrations-by-day/registrationByDay.interface'
import { ITeamMember } from '../../../../../../models/users/user.interface'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import {
  selectPayTabRegistrationAccordion,
  selectRegisterTabData,
  selectRegistrationByDay,
  updateRegistrationByDayInRedux,
} from '../../../../../../store/registration/registrationSlice'
import { IHandlePaymentDivided } from '../../../../competitorEventRegister/Tabs/CompetitorEventRegisterPayTab'
import { EVENT_REGISTERED_CONST } from '../../../../../../models/event-registered-users/event-registered-users.constants'
import { IManageInfo } from '../../../../event-registration-tabs/EventRegistrationTabs'

type IProps = {
  splitPayment?: boolean
  registrationPrice: number | null
  handlePaymentDivided?: IHandlePaymentDivided
  membersAddedInRegistration?: ITeamMember[]
  activeTab: {
    tab: string
    step: number
  }
  setEventTab?: any
  manageInfo: IManageInfo
  isManage?: boolean
}

type IHandleRecipientChange = (args: IHandleRecipientChangeArgs) => void
type IHandleRecipientChangeArgs = {
  /** @info Id of the item that is holding the select list */
  docId: IRegistrationByDayInterface['id']
  /** @info Id of the doc containing selected member */
  selectedDocId: IRegistrationByDayInterface['id']
  memberIndex?: number | null
  itemIndexInList?: number | null
}

// Constants

const UNKNOWN = 'Unknown'
const NO_HORSES_SELECTED = "You may have selected members, but haven't selected horses"
const FILE_NAME = 'SelectedEventList'
const CUSTOM_ERROR_PROPS = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

const SelectedEventList = (props: IProps) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()
  const registerTabData = useAppSelector(selectRegisterTabData)
  const registrationsByDay = useAppSelector(selectRegistrationByDay)
  const registrationAccordionData = useAppSelector(selectPayTabRegistrationAccordion)

  const [open, setOpen] = useState(false)
  const [selectedUnits, setSelectedUnits] = useState<{
    totalItems: number
    totalPrice: number
    uniqueHorseCount: number
    uniqueMemberCount: number
  }>({ totalItems: 0, totalPrice: 0, uniqueHorseCount: 0, uniqueMemberCount: 0 })

  const splitPayment = props?.splitPayment ?? false

  let heading = [
    { title: 'Class', tooltipText: 'The events you entered' },
    { title: 'Registered horse', tooltipText: 'Horses you registered for each event' },
    { title: 'Registered rider', tooltipText: 'Riders you registered for each horse' },
    { title: 'Price', tooltipText: 'Cost per registration' },
  ]

  if (splitPayment) {
    heading = [...heading, { title: 'Invoice recipient', tooltipText: '' }]
  }

  // Functions
  const handleRecipientChange: IHandleRecipientChange = (args) => {
    try {
      let selectedRegistrationByDayIndex
      let mutatedRegistrationByDay: null | IRegistrationByDayInterface
      let selectedRegistrationByDayInDb: null | IRegistrationByDayInterface

      selectedRegistrationByDayIndex = registrationsByDay.findIndex((currRegistrationByDay) => {
        return currRegistrationByDay.id === args.selectedDocId
      })

      let [registrationTabRowIndex, registrationByDayIndex] = (args.docId?.split('-') ?? []).map(
        (c) => Number(c)
      )

      mutatedRegistrationByDay = cloneDeep(
        registerTabData?.[registrationTabRowIndex]?.registrationsByDay?.[registrationByDayIndex]
      )

      selectedRegistrationByDayInDb = cloneDeep(
        registrationsByDay?.[selectedRegistrationByDayIndex]
      )

      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal(
        {
          docId: args.docId,
          mutatedRegistrationByDay,
          selectedRegistrationByDayInDb,
          selectedDocId: args.selectedDocId,
          registrationTabRowIndex,
          registrationByDayIndex,
        },
        [0]
      )

      if (emptyVarName)
        throw CustomError.somethingWentWrong({
          ...CUSTOM_ERROR_PROPS,
          moduleName: 'handleRecipientChange',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
        })

      registrationByDayIndex = Number(registrationByDayIndex) as any
      registrationTabRowIndex = Number(registrationTabRowIndex) as any

      mutatedRegistrationByDay = {
        ...mutatedRegistrationByDay,
        update: true,
        isPaidByOwner: false,
        recipientId: selectedRegistrationByDayInDb.riderId,
        recipientName: selectedRegistrationByDayInDb.riderName,
        recipientNameNGram: selectedRegistrationByDayInDb.riderNameNGram,
        recipientProfilePicture: selectedRegistrationByDayInDb.riderProfilePicture,
      }

      dispatch(
        updateRegistrationByDayInRedux({
          registrationByDayIndex: registrationByDayIndex as any,
          registrationTabRowIndex: registrationTabRowIndex as any,
          dataToUpdate: mutatedRegistrationByDay,
        })
      )
    } catch (error: any) {
      toastFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        message: error,
      })
    }
  }

  useEffect(() => {
    if (props.isManage) {
      const registrationsByDayData: IRegistrationByDayInterface[] = []
      props.manageInfo.register.forEach((register) =>
        register.registrationsByDay.forEach((registrationsByDay) => {
          registrationsByDayData.push({ ...registrationsByDay, paymentStatus: 'paid' })
        })
      )

      let qualifyFee = 0
      registrationsByDayData.forEach((register) => {
        if (register.isQualify) qualifyFee += Number(register.qualifyFee)
      })

      const totalPrice = parseFloat(sumBy(registrationsByDayData, 'registrationPrice').toFixed(2))
      setSelectedUnits({
        totalItems: registrationsByDayData?.length,
        totalPrice: totalPrice + qualifyFee,
        uniqueHorseCount: size(uniqBy(registrationsByDayData, 'horseId')),
        uniqueMemberCount: size(uniqBy(registrationsByDayData, 'riderId')),
      })
    } else {
      setSelectedUnits({
        totalItems: registrationAccordionData.totalItemsCount,
        totalPrice: parseFloat(registrationAccordionData.totalPrice.toFixed(2)),
        uniqueHorseCount: registrationAccordionData.uniqueHorseCount,
        uniqueMemberCount: registrationAccordionData.uniqueMemberCount,
      })
    }
  }, [props.isManage, props.manageInfo.register, registrationAccordionData])

  return (
    <ConfirmationCompsWrapper
      title="Registrations"
      id={FILE_NAME}
      cols={heading}
      activeTab={props.activeTab}
      accordion={{ open, setOpen }}
      redirectTab={CONST.UI.REGISTER.TABS.REGISTER}
      showList={!!registrationsByDay.length}
      emptyListMessage={NO_HORSES_SELECTED}
      setEventTab={props.setEventTab}
      colsClassName={
        splitPayment
          ? {
              0: '!w-1/5 flex items-center',
              1: '!w-1/5 flex items-center',
              2: '!w-1/5 flex items-center',
              3: '!w-[140px] flex items-center',
            }
          : null
      }
      selected_units={`${selectedUnits.totalItems} ${props.isManage ? 'new' : ''} ${selectedUnits.totalItems > 1 ? 'Registrations' : 'Registration'}, $${selectedUnits.totalPrice}`}
    >
      <>
        {[...registerTabData, ...props.manageInfo.register].map((currRow, currRowIndex) => {
          return (
            <div
              key={`${JSON.stringify(currRow)}${currRowIndex}`}
              className={`${currRowIndex !== 0 ? 'mt-10' : ''} lg:mt-0`}
            >
              {currRow.registrationsByDay.map(
                (currRegistrationByDay, currRegistrationByDayIndex) => {
                  return (
                    <div
                      key={`${currRegistrationByDayIndex}${currRowIndex}`}
                      className="flex flex-col lg:flex-row items-start justify-start w-full mb-2"
                    >
                      <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">Class</div>

                      <p
                        className={`text-SeabiscuitDark200ThemeColor w-full lg:w-1/${splitPayment ? '5' : '4'} p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize`}
                      >
                        <span className="line-clamp-1">{currRow?.registrationByDayName}</span>
                      </p>

                      <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">
                        Registered Horse
                      </div>

                      <p
                        className={`text-SeabiscuitDark200ThemeColor w-full lg:w-1/${splitPayment ? '5' : '4'} p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize relative px-12 lg:text-center`}
                        title={!currRegistrationByDay.horseId ? 'Horse not selected' : ''}
                      >
                        <img
                          src="/assets/og_icons/YearofHorse-1.svg"
                          alt="horseIcon"
                          className="absolute left-4"
                        />
                        <span className="line-clamp-1">
                          {currRegistrationByDay.horseId
                            ? currRegistrationByDay.horseName ?? UNKNOWN
                            : 'No Horse'}
                        </span>
                      </p>
                      <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">
                        Registered Rider
                      </div>

                      <p
                        className={`text-SeabiscuitDark200ThemeColor w-full lg:w-1/${splitPayment ? '5' : '4'} p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize relative px-12 lg:text-center`}
                      >
                        <img
                          src="/assets/img/dark/User.svg"
                          alt="horseIcon"
                          className="absolute left-4"
                        />
                        <span className="line-clamp-1">
                          {currRegistrationByDay.riderName ?? UNKNOWN}
                        </span>
                      </p>
                      <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">Price</div>
                      <p
                        className={`text-SeabiscuitDark200ThemeColor ${splitPayment ? 'w-full lg:w-[140px] mr-2' : 'w-full lg:w-1/4'} p-4 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize relative lg:justify-center items-center flex`}
                      >
                        <span>
                          {currRegistrationByDay.paymentStatus ===
                          EVENT_REGISTERED_CONST.PAYMENT_STATUS.PAID ? (
                            EVENT_REGISTERED_CONST.PAYMENT_STATUS.PAID
                          ) : currRegistrationByDay.paymentStatus ===
                            EVENT_REGISTERED_CONST.PAYMENT_STATUS.CASH ? (
                            EVENT_REGISTERED_CONST.PAYMENT_STATUS.CASH
                          ) : (
                            <span>
                              ${currRegistrationByDay.registrationPrice}{' '}
                              {currRegistrationByDay.isQualify && (
                                <span className="normal-case">
                                  {' '}
                                  • ${currRegistrationByDay.qualifyFee} qual
                                </span>
                              )}
                            </span>
                          )}
                        </span>
                      </p>

                      {splitPayment ? (
                        <SelectRecipient
                          id={FILE_NAME}
                          memberIndex={0}
                          itemIndexInList={0}
                          handleRecipientChange={handleRecipientChange}
                          recipientId={currRegistrationByDay.recipientId}
                          handlePaymentDivided={props?.handlePaymentDivided}
                          docId={`${currRowIndex}-${currRegistrationByDayIndex}`}
                          isLastIndex={
                            currRegistrationByDay.eventDate ===
                              registrationsByDay.at(-1)?.eventDate &&
                            registerTabData[currRowIndex].registrationsByDay.at(-1)?.riderId ===
                              currRegistrationByDay.riderId
                          }
                          membersAddedInRegistration={props.membersAddedInRegistration ?? []}
                        />
                      ) : null}
                    </div>
                  )
                }
              )}
            </div>
          )
        })}

        {/* Total */}
        <div className="flex flex-col lg:flex-row items-start justify-start w-full mb-2 font-semibold text-SeabiscuitGreenThemeColor  text-center">
          <p
            className={clsx(
              `p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor text-left w-full`,
              splitPayment ? 'lg:w-1/5' : 'lg:w-1/4'
            )}
          >
            <span className="line-clamp-1">
              {props.isManage && 'New'} Total{' '}
              <span className="pl-1">{`${selectedUnits.totalItems} ${selectedUnits.totalItems > 1 ? 'Registrations' : 'Registration'}`}</span>
            </span>
          </p>

          <p
            className={clsx(
              `p-4 mr-2 rounded-md text-left lg:text-center bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor w-full`,
              splitPayment ? 'lg:w-1/5' : 'lg:w-1/4'
            )}
          >
            <span className="line-clamp-1">
              {`${selectedUnits.uniqueHorseCount} ${selectedUnits.uniqueHorseCount > 1 ? 'horses' : 'horse'}`}
            </span>
          </p>

          <p
            className={clsx(
              `p-4 mr-2 rounded-md text-left lg:text-center bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor w-full`,
              splitPayment ? 'lg:w-1/5' : 'lg:w-1/4'
            )}
          >
            <span className="line-clamp-1">
              {`${selectedUnits.uniqueMemberCount} ${selectedUnits.uniqueMemberCount > 1 ? 'riders' : 'rider'}`}
            </span>
          </p>

          <p
            className={clsx(
              `p-4 rounded-md text-left lg:text-center w-full bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor`,
              splitPayment ? 'lg:w-[140px] lg:mr-2' : 'lg:w-1/4'
            )}
          >
            <span className="line-clamp-1">${selectedUnits.totalPrice}</span>
          </p>

          {splitPayment ? (
            <p
              className={clsx(
                `w-1/4 p-4 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor`
              )}
            >
              <span className="line-clamp-1">
                {`${registrationAccordionData.uniqueRecipientCount} ${(registrationAccordionData.uniqueRecipientCount ?? 0) > 1 ? 'recipients' : 'recipient'}`}
              </span>
            </p>
          ) : null}
        </div>
      </>
    </ConfirmationCompsWrapper>
  )
}

export default SelectedEventList
