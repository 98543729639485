export const DATA_FIRESTORE_V01_USERS = {
  NAME: 'v01_users',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    FIRST_NAME: {
      KEY: 'firstName',
      VALUES: {
        UNKNOWN: 'Unknown',
      },
    },

    USER_EMAIL: {
      KEY: 'userEmail',
      VALUES: {
        UNKNOWN: 'Unknown',
      },
    },

    USER_NAME: {
      KEY: 'userName',
      VALUES: {
        UNKNOWN: 'Unknown',
      },
    },

    USER_CONNECT_ACCOUNT_STATUS_MESSAGE: {
      KEY: 'userConnectAccountStatusMessage',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_NAME_NGRAMS: {
      KEY: 'userNameNGram',
      VALUES: {
        UNKNOWN: 'Unknown',
      },
    },

    LAST_NAME: {
      KEY: 'lastName',
      VALUES: {
        UNKNOWN: 'Unknown',
      },
    },

    USER_DEFAULT_ROLE: {
      KEY: 'userDefaultRole',
      VALUES: {
        OWNER: 'Owner',
      },
    },

    USER_TYPE: {
      KEY: 'userType',
      VALUES: {
        COMPETITOR: 'competitor',
        ORGANIZER: 'organizer',
        VENDOR: 'vendor',
        UNDEFINED: 'undefined',
      },
    },
    ID: {
      KEY: 'id',
    },

    USER_STRIPE_ACCOUNT_ID: {
      KEY: 'userStripeAccountId',
    },

    USER_STRIPE_ACCOUNT_STATUS: {
      KEY: 'userStripeAccountStatus',
      VALUES: {
        CONNECTED: 'connected',
        PENDING: 'pending',
      },
    },
  },
}
