/* eslint-disable no-useless-escape */
import { cloneDeep } from 'lodash'
import { TicketType } from '../../../models/event-ticketing/event-ticketing.interface'
import { selectObjToString } from '../../../models/interface.helper'
import { IRecipient } from '../../../models/users/user.interface'
import { billsSliceInitialState } from '../../../store/bills/billsSlice.data'
import { IBillsSliceInitialStateInterface as ibsisi } from '../../../store/bills/billsSlice.types'
import { IEventsRegisterData } from '../../../store/events/eventsSlice'

type ICalculateGrandTotal = (
  args: ICalculateGrandTotalArgs
) => ICalculateGrandTotalReturnValue | null

type ICalculateGrandTotalReturnValue = {
  grandTotal: number
  recipientsTotals: IRecipient[]
  countDetails: ibsisi['selected']['countDetails']
  categorizedTotals: ICalculateGrandTotalCategorizedTotal
}

type ICalculateGrandTotalCategorizedTotal = {
  registrations: {
    key: 'registrations'
    total: number
  }
  fees: {
    key: 'fees'
    total: number
  }
  tickets: {
    key: 'tickets'
    total: number
  }
  tax: {
    key: 'tax'
    total: number
  }
}

type ICalculateGrandTotalArgs = {
  registrationPrice: number | null
  selectedEvents: IEventsRegisterData['events']
  selectedEventsFees: IEventsRegisterData['fees']
  selectedEventsTickets: IEventsRegisterData['tickets']
}

type IEvaluatedFeeItem = {
  units?: number
  cost: number
  sold: number
  note: string
  name: string
  status: boolean
  duration: string
  category: string
  available: number
  costAlias: string
  uuid: string
} | null

type ISaveAllTabsFn = (args: ISaveAllTabsFnArgs) => Promise<any>

type ISaveAllTabsFnArgs = ISaveAllTabsFnPaidArgs

type ISaveAllTabsFnPaidArgs = {
  isManage?: boolean
  isPayByCash?: boolean
  isPaid?: boolean
  cardNo?: string
  invoiceId?: string
  invoiceUrl?: string
}

type IEventRegistrationContext = {
  saveAllTabs: ISaveAllTabsFn
} | null

export type ICompetitorEventRegisterTypes = {
  IEvaluatedFeeItem: IEvaluatedFeeItem
  IEventRegistrationContext: IEventRegistrationContext
  ISaveAllTabsFn: ISaveAllTabsFn
  ISaveAllTabsFnArgs: ISaveAllTabsFnArgs
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const calculateGrandTotal: ICalculateGrandTotal = (args) => {
  let index = -1
  let unitsIntoPrice = 0
  let horseIds: string[] = []
  let memberCount: number = 0
  let teamMemberIds: string[] = []
  let recipientsTotals: IRecipient[] = []
  let categorizedTotals: ICalculateGrandTotalCategorizedTotal | null = null
  let countDetails: ibsisi['selected']['countDetails'] = cloneDeep(
    billsSliceInitialState.selected.countDetails
  )

  let salesTax =
    process.env.REACT_APP_SALESTAX && typeof process.env.REACT_APP_SALESTAX === 'string'
      ? Number(process.env.REACT_APP_SALESTAX)
      : 1

  if (!args?.registrationPrice) return null

  const { selectedEvents, selectedEventsFees, selectedEventsTickets } = args

  categorizedTotals = {
    registrations: {
      key: 'registrations',
      total: selectedEvents.length
        ? selectedEvents.reduce((acc, currEvent) => {
            memberCount = currEvent.members.filter((member) => member?.horses?.length).length
            countDetails.registrationCount += memberCount
            currEvent.members.forEach((cm) => {
              cm?.horses?.forEach((ch) => horseIds.push(ch.id))

              if (cm?.horses?.length) {
                if (cm.memberId) teamMemberIds.push(cm.memberId)
                if (cm.recipient?.recipientId) {
                  recipientsTotals.push({
                    recipientId: cm.recipient.recipientId,
                    recipientAmountToPay: args?.registrationPrice ?? 0,
                    recipientPaymentStatus: cm.recipient.recipientPaymentStatus ?? 'pending',
                  })
                  countDetails.eventRecipientCount++
                }
              }
            })
            acc = Number(args?.registrationPrice) * countDetails.registrationCount
            return acc
          }, 0)
        : 0,
    },
    fees: {
      key: 'fees',
      total: selectedEventsFees.length
        ? selectedEventsFees.reduce((acc: number, currFee) => {
            unitsIntoPrice = (Number(currFee.cost) ?? 0) * (Number(currFee?.units) ?? 0)
            acc += unitsIntoPrice
            if (currFee.recipient) {
              recipientsTotals.push({
                recipientAmountToPay: unitsIntoPrice,
                recipientId: currFee.recipient.recipientId,
                recipientPaymentStatus: currFee.recipient.recipientPaymentStatus ?? 'pending',
              })
              countDetails.feesRecipientCount++
            }
            countDetails.feesUnitsCount += Number(currFee?.units) ?? 0
            countDetails.feesCount++
            return acc
          }, 0)
        : 0,
    },
    tickets: {
      key: 'tickets',
      total: selectedEventsTickets.reduce((acc: number, currTicket) => {
        unitsIntoPrice = (Number(currTicket.cost) ?? 0) * (Number(currTicket?.units) ?? 0)
        acc += unitsIntoPrice
        if (currTicket.recipient) {
          recipientsTotals.push({
            recipientAmountToPay: unitsIntoPrice,
            recipientId: currTicket.recipient.recipientId,
            recipientPaymentStatus: currTicket.recipient.recipientPaymentStatus ?? 'pending',
          })
          countDetails.ticketsRecipientCount++
        }
        countDetails.ticketsUnitsCount += Number(currTicket?.units) ?? 0
        countDetails.ticketsCount++
        return acc
      }, 0),
    },
    tax: {
      key: 'tax',
      total: 0,
    },
  }

  countDetails.horsesCount = [...new Set(horseIds.filter((currHorseId) => currHorseId))].length
  countDetails.ridersCount = [...new Set(teamMemberIds.filter((currHorseId) => currHorseId))].length

  const grandTotal = [
    categorizedTotals.fees.total,
    categorizedTotals.tickets.total,
    categorizedTotals.registrations.total,
  ]

  categorizedTotals.tax = {
    key: 'tax',
    total:
      ((grandTotal.reduce((acc, currItem) => (acc += currItem ?? 0), 0) ?? 0) * salesTax) / 100,
  }

  grandTotal.push(categorizedTotals.tax.total)

  recipientsTotals = recipientsTotals.reduce(
    (acc, currItem) => {
      index = acc.findIndex((currAccItem) => currAccItem.recipientId === currItem.recipientId)
      if (index === -1) acc.push(currItem)
      else acc[index].recipientAmountToPay += currItem.recipientAmountToPay ?? 0
      return acc
    },
    [] as typeof recipientsTotals
  )

  return {
    countDetails,
    recipientsTotals,
    categorizedTotals,
    grandTotal: Number(
      (grandTotal.reduce((acc, currItem) => (acc += currItem ?? 0), 0) ?? 0)?.toFixed(2)
    ),
  }
}

const getEvaluatedFeeItem = (item: any): IEvaluatedFeeItem => {
  let cost: number = 0
  let sold: number = 0
  let name: string = ''
  let note: string = ''
  let uuid: string = ''
  let duration: string = ''
  let category: string = ''
  let costAlias: string = ''
  let available: number = 0
  let status: boolean = false
  let costMatchesWithAlias: boolean = false

  if (!item) return null

  if (item.hasOwnProperty('category') && typeof item.category === 'string' && !!item.category)
    category = item.category

  if (item.hasOwnProperty('uuid') && typeof item.uuid === 'string' && !!item.uuid) uuid = item.uuid

  if (item.hasOwnProperty('available') && typeof item.available === 'number')
    available = item.available
  else available = 0

  if (item.hasOwnProperty('sold') && typeof item.sold === 'number') sold = item.sold
  else sold = 0

  if (item.hasOwnProperty('status') && typeof item.status === 'boolean') status = item.status

  if (
    (item.hasOwnProperty('name') && typeof item.name === 'string' && !!item.name) ||
    (item.hasOwnProperty('title') && typeof item.title === 'string' && !!item.title)
  ) {
    name = item.name ?? item.title
  } else {
    name = 'N/A'
  }

  if (item.hasOwnProperty('cost') && typeof item.cost === 'number' && !!item.cost) cost = item.cost

  if (item.hasOwnProperty('costAlias') && typeof item.costAlias === 'string')
    costAlias = item.costAlias
  else costAlias = '$0'

  if (item.hasOwnProperty('note') && typeof item.note === 'string' && !!item.note) note = item.note
  else note = ''

  if (item.hasOwnProperty('duration') && !!item.duration) {
    if (typeof item?.duration === 'object') duration = selectObjToString(item.duration)
    else duration = item.duration
  } else duration = 'N/A'

  if (typeof cost === 'number' && !!costAlias)
    costMatchesWithAlias = cost === Number(costAlias.replace(/[^0-9\..]+/gi, ''))

  if (!(!!category && costMatchesWithAlias && status && !!uuid)) {
    return null
  }

  return {
    cost,
    name,
    sold,
    note,
    uuid,
    status,
    category,
    duration,
    costAlias,
    available: available - sold > available ? 0 : available - sold,
  }
}

const getEvaluatedTicketItem = (item: any): TicketType | null => {
  let cost: number = 0
  let sold: number = 0
  let name: string = ''
  let note: string = ''
  let uuid: string = ''
  let costAlias: string = ''
  let actualCostAlias: string = ''
  let actualCost: number = 0
  let available: number = 0
  let costMatchesWithAlias: boolean = false

  if (!item) return null

  if (item.hasOwnProperty('uuid') && typeof item.uuid === 'string' && !!item.uuid) uuid = item.uuid

  if (item.hasOwnProperty('available') && !isNaN(Number(item.available))) available = item.available
  else available = 0

  if (item.hasOwnProperty('sold') && !isNaN(Number(item.sold))) sold = Number(item.sold)
  else sold = 0

  if (item.hasOwnProperty('name') && typeof item.name === 'string' && !!item.name)
    name = item.name ?? item.title

  if (item.hasOwnProperty('cost') && !isNaN(Number(item.cost)) && !!item.cost) cost = item.cost

  if (item.hasOwnProperty('actualCost') && !isNaN(Number(item.actualCost)) && !!item.actualCost)
    actualCost = item.actualCost

  if (
    item.hasOwnProperty('costAlias') &&
    typeof item.costAlias === 'string' &&
    !!item.costAlias.replace(/\D/, '')
  )
    costAlias = item.costAlias
  else costAlias = '$0'

  if (
    item.hasOwnProperty('actualCostAlias') &&
    typeof item.actualCostAlias === 'string' &&
    !!item.actualCostAlias.replace(/\D/, '')
  )
    actualCostAlias = item.actualCostAlias
  else actualCostAlias = '$0'

  if (item.hasOwnProperty('note') && typeof item.note === 'string' && !!item.note) note = item.note
  else note = 'N/A'

  if (!!cost && !!costAlias)
    costMatchesWithAlias = cost === Number(costAlias.replace(/[^0-9\..]+/gi, ''))

  if (!!actualCost && !!actualCostAlias)
    costMatchesWithAlias = actualCost === Number(actualCostAlias.replace(/[^0-9\..]+/gi, ''))

  if (!(cost > 0 && actualCost > 0 && costMatchesWithAlias && !!name && !!uuid)) return null

  return {
    cost,
    name,
    sold,
    note,
    uuid,
    costAlias,
    actualCost,
    actualCostAlias,
    available: available - sold > available ? 0 : available - sold,
  }
}

const getFilteredTickets = (tickets: any[]) => {
  return tickets.reduce((acc, current: any) => {
    acc.push({
      units: current.units,
      cost: current.cost,
      name: current.name,
      note: current.note,
      uuid: current.uuid,
      available: current.available,
      ...(current?.recipient && { recipient: current?.recipient }),
    })

    return acc
  }, [])
}

export const competitorEventRegisterHelper = {
  getFilteredTickets,
  calculateGrandTotal,
  getEvaluatedFeeItem,
  getEvaluatedTicketItem,
}

export { calculateGrandTotal, getEvaluatedFeeItem, getEvaluatedTicketItem, getFilteredTickets }
