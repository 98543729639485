import clsx from 'clsx'
import React, { useState } from 'react'
import { PillButtonElement } from '../../../../../components/elements/buttons/pill/PillButtonElement'

type IProps = {
  tabList: string[]
  emptyEventsMessage: string
  onBrowseEventButtonClick: (activeTabIndex: number) => void
}

const EventsContainer = (props: IProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)

  return (
    <div className="rounded-xl 2xl:mt-6 mt-4 shadow-[0_0_30px_rgba(0,0,0,0.12)] p-5 h-[450px] hidden md:block">
      <div className="flex flex-wrap gap-3 text-nr h-fit">
        {props.tabList.map((currTabName, index) => {
          return (
            <button
              key={`tab${index}`}
              className={clsx(
                'border-b border-solid transition-all',
                activeTabIndex === index
                  ? 'text-SeabiscuitMainThemeColor border-SeabiscuitMainThemeColor font-bold'
                  : 'text-SeabiscuitDark200ThemeColor border-transparent'
              )}
              onClick={() => setActiveTabIndex(index)}
            >
              {currTabName}
            </button>
          )
        })}
      </div>
      <div className="flex gap-2 flex-col h-full">
        <div className="flex gap-2 flex-col h-full justify-center items-center">
          <div className="text-SeabiscuitDark200ThemeColor text-nr text-center">
            {props.emptyEventsMessage}
          </div>
          <PillButtonElement
            onButtonClicked={() => props.onBrowseEventButtonClick(activeTabIndex)}
            Class="max-w-[150px] w-full !rounded-3xl !h-fit p-1.5 m-0"
            text="Browse"
            selected={true}
          />
        </div>
      </div>
    </div>
  )
}

export default EventsContainer
