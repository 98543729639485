import { AutorenewRounded } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import clsx from 'clsx'
import { where } from 'firebase/firestore'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import customImageComponent from '../../../../../components/common/CustomImageComponent'
import InfiniteScrollDataTable from '../../../../../components/common/tables/InfiniteScrollDataTable'
import ViewsLoader from '../../../../../components/loader/ViewsLoader'
import { CONST } from '../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { ROUTES_CONST } from '../../../../../const/routes-const'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import TooltipIcon from '../../../../../helpers/TooltipIcon'
import useGetUserEmailViaDocId from '../../../../../hooks/useGetUserEmailViaDocId'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import { getConvertedData } from '../../../../../models/interface.helper'
import { IRefundInterace } from '../../../../../models/refunds/refund.interface'
import { RefundModel } from '../../../../../models/refunds/refund.model'
import FirestoreService from '../../../../../services/firestoreService'
import { customStyles } from './AllRevenueTableStyle'
import NoDataAvailable from './NoDataAvailable'

type IProps = {
  eventId: string
  searchValue: string
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const REFUNDS_COLLECTION = COLLECTIONS.REFUNDS

const RegistrationRevenueRefundTab = (props: IProps) => {
  // Hooks and vars
  let mounted = true

  const { eventId, searchValue } = props
  const rowIndexToLoadRef = useRef(-1)
  const setRowIndexToLoad = useState(-1)[1]
  const handleModalContext = useContext(HandleModalContext)

  const [hasMore, setHasMore] = useState(true)
  const { getEmail } = useGetUserEmailViaDocId()
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const toastMethods = useToasterHelper()

  useEffect(() => {
    // if (!refundsArr.length)
    getRefunds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const sendEmail = async (index: number, userId: string | null) => {
    setRowIndexToLoad(index)
    rowIndexToLoadRef.current = index

    let emailId = await getEmail({ userId })
    if (emailId) {
      handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, { emailId })
    }

    setRowIndexToLoad(-1)
    rowIndexToLoadRef.current = -1
  }

  const columns = [
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">Customer</span>
          <Tooltip
            title={<h1 className="tooltip_title">Customer</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRefundInterace) => (
        <div className="flex items-center text-SeabiscuitDark200ThemeColor font-semibold capitalize">
          {!!eventId && !!row?.recipientUserId && !!row?.recipientUserId ? (
            <Link
              to={`${ROUTES_CONST.EXHIBITOR_REFUND.URL}/${row?.recipientUserId}/${eventId}/${row?.registrationId}`}
              className="flex items-center"
            >
              {customImageComponent(
                row.recipientProfilePicture,
                row.recipientName as any,
                'w-[45px] h-[45px] rounded-full mr-2 shrink-0 object-cover'
              )}
              {row.recipientName ?? 'Unknown'}
            </Link>
          ) : (
            <>
              {customImageComponent(
                row.recipientProfilePicture,
                row.recipientName as any,
                'w-[34px] h-[34px] bg-[#F2F3F8] p-1 rounded-full mr-2 shrink-0'
              )}
              {row.recipientName ?? 'Unknown'}
            </>
          )}
        </div>
      ),
      width: '30%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">Status</span>
          <Tooltip
            title={<h1 className="tooltip_title">Status</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRefundInterace) => (
        <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
          <img src="/assets/cp_icons/MagneticCard.svg" className="mr-2" alt="paidStatus" />
          <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
            {row?.refundStatus}
          </span>
        </div>
      ),
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">Initial Charge</span>
          <Tooltip
            title={<h1 className="tooltip_title">Initial Charge</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRefundInterace) => (
        <>
          <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
            <img src="/assets/cp_icons/MoneyBag.svg" className="mr-2" alt="paidStatus" />
            <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
              ${row.amountPaid}
            </span>
          </div>
        </>
      ),
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">Refund</span>
          <Tooltip
            title={<h1 className="tooltip_title">Refund</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRefundInterace) => (
        <>
          <div className="flex items-center w-full text-SeabiscuitDark200ThemeColor">
            ${row.refundAmount}
          </div>
        </>
      ),
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">Refund date</span>
          <Tooltip
            title={<h1 className="tooltip_title">Refund date</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRefundInterace) => (
        <>
          <div className="flex items-center  w-full">
            <img src="/assets/cp_icons/Tear-Off Calendar.svg" className="mr-2" alt="paidStatus" />
            <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
              {moment(row.refundedAt).format('MM-DD-YYYY')}
            </span>
          </div>
        </>
      ),
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">Receipt</span>
          <Tooltip
            title={<h1 className="tooltip_title">Receipt</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRefundInterace) => (
        <>
          <div className="underline flex items-center w-full text-SeabiscuitDark200ThemeColor">
            <img src="/assets/cp_icons/Bill.svg" className="mr-2" alt="paidStatus" />
            <a href={row?.invoiceUrl ?? '#'} target="_blank" rel="noreferrer">
              View
            </a>
          </div>
        </>
      ),
      width: '12%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">Contact</span>
          <Tooltip
            title={<h1 className="tooltip_title">Contact</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: IRefundInterace, rowIndex: number) => (
        <div className="w-full justify-center flex cursor-pointer">
          <button
            className="bg-[#F2F3F8] py-3 px-5 font-semibold rounded-xl text-SeabiscuitDark200ThemeColor hover:bg-[#F7074F0D] hover:text-SeabiscuitMainThemeColor w-full"
            onClick={() => {
              sendEmail(rowIndex, row.recipientUserId)
            }}
          >
            {rowIndexToLoadRef.current === rowIndex ? (
              <AutorenewRounded fontSize="small" className="animate-spin" />
            ) : (
              'Message'
            )}
          </button>
        </div>
      ),
      width: '130px',
    },
  ]

  const getRefunds = async (prevRefundsArr: IRefundInterace[] = []) => {
    let cursorId = null
    const PER_PAGE = 10
    const refundDocs: IRefundInterace[] = []

    if (!eventId) {
      setLoading(false)
      return toastMethods.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }

    cursorId = prevRefundsArr.at(-1)?.id ?? null

    if (!cursorId) {
      setLoading(true)
    }

    try {
      const refundSnapshots = await FirestoreService.filterItems(
        REFUNDS_COLLECTION.NAME,
        [
          where(REFUNDS_COLLECTION.FIELDS.EVENT_ID.KEY, '==', eventId),
          ...(searchValue
            ? [
                where(
                  REFUNDS_COLLECTION.FIELDS.RECIPIENT_NAME_N_GRAMS.KEY,
                  'array-contains',
                  searchValue
                ),
              ]
            : []),
        ],
        PER_PAGE,
        null,
        null,
        cursorId
      )

      refundSnapshots.forEach((currDoc) => {
        refundDocs.push(getConvertedData(RefundModel.fromFirestoreDoc(currDoc).toObject()))
      })

      if (refundSnapshots.size) {
        setHasMore(true)
        if (cursorId) {
          setData([...prevRefundsArr, ...refundDocs])
        } else {
          setData(refundDocs)
        }
      } else {
        setHasMore(false)
        setData(prevRefundsArr)
      }
    } catch (error) {
      console.error(error)
    } finally {
      if (mounted) setLoading(false)
      return 0
    }
  }

  return (
    <InfiniteScrollDataTable
      data={data}
      hasMore={hasMore}
      columns={columns}
      fixedHeader={true}
      fetchMore={() => getRefunds(data)}
      progressPending={loading}
      customStyles={customStyles}
      className={clsx('!rounded-none', data.length || loading ? 'h-[50vh]' : '')}
      noDataComponent={
        <NoDataAvailable
          buttonText={'Edit registrations >'}
          imgSrc="/assets/og_icons/People-4.svg"
          text="No exhibitors have registered for this event"
        />
      }
      progressComponent={
        <ViewsLoader
          className="flex items-center w-full justify-center my-10"
          size="md"
          color="#F7074F"
        />
      }
    />
  )
}

export default RegistrationRevenueRefundTab
