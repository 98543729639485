// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventOrganizationMessagesControlComponent: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return <div className="container m-4">Messages coming soon...</div>
}

export default EventOrganizationMessagesControlComponent
