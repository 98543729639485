// ############################################################
/**
 * Contains the model to hold the events (and perform migrations
 * if it comes the case)
 */
// ############################################################

import { cloneDeep } from 'lodash'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IGuestInterface } from './guest.interface'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */

export class GuestModel extends ModelBaseModel<IGuestInterface> implements IModelBaseModel {
  public v: number
  public id: string
  public fullName: IGuestInterface['fullName']
  public userCards: IGuestInterface['userCards']
  public userEmail: IGuestInterface['userEmail']
  public userName: IGuestInterface['userName']
  public userStripeId: IGuestInterface['userStripeId']
  public userNameNGram: IGuestInterface['userNameNGram']
  public userFirstName: IGuestInterface['userFirstName']
  public userLastName: IGuestInterface['userLastName']
  public userAddress: IGuestInterface['userAddress']
  public created: IGuestInterface['created']
  public modified: IGuestInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Constructs an instance of the class with an object that adheres to the
   * IUserInterface interface
   *
   * @param obj
   * Object that adheres to the IUserInterface interface
   */
  public constructor(obj?: IGuestInterface) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? ''
    this.userCards = GuestModel.getCards(obj?.userCards)
    this.fullName = obj?.fullName ?? null
    this.userEmail = obj?.userEmail ?? null
    this.userName = !!obj?.userName ? obj.userName?.toLocaleLowerCase?.() ?? obj?.userName : null
    this.userStripeId = obj?.userStripeId ?? null
    this.userNameNGram = obj?.userNameNGram ?? []
    this.userFirstName = obj?.userFirstName ?? null
    this.userLastName = obj?.userLastName ?? null
    this.userAddress = obj?.userAddress ?? null

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created as Date,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified as Date,
    })

    this._calculateUsernameNGrams()
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Dcocument this
   */
  public setUsernameAndCalculateNGram(username_to_set: string) {
    this.userName = username_to_set
    this._calculateUsernameNGrams()
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Dcocument this
   */
  static getCards(cards?: IGuestInterface['userCards']) {
    let cards_: IGuestInterface['userCards'] = []
    if (!Array.isArray(cards)) {
      return []
    } else {
      cards_ = cards.map((currCard) => {
        return {
          cardNumber: currCard?.cardNumber ?? null,
          cardType: currCard?.cardType ?? null,
          pmId: currCard?.pmId ?? null,
          expiryMonth: currCard?.expiryMonth ?? null,
          expiryYear: currCard?.expiryYear ?? null,
          zipCode: currCard?.zipCode ?? null,
          default: currCard?.default ?? false,
        }
      })
      return cards_
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new GuestModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  private _calculateUsernameNGrams() {
    let userName = !!this.userName ? this?.userName?.toLocaleLowerCase?.() ?? this?.userName : ''

    let reg = new RegExp(/\s+/, 'g')
    userName = userName.replace(reg, '')

    const words = userName?.split(' ') ?? []
    const lowercase_words = words.map((word) => word.toLowerCase())

    let ngram_words = new Set<string>()

    lowercase_words.forEach((word) => {
      let word_iterator = cloneDeep(word)

      while (word_iterator.length > 0) {
        ngram_words.add(cloneDeep(word_iterator))
        word_iterator = word_iterator.slice(0, -1)
      }
    })

    if (this.userNameNGram.length > 0) {
      this.userNameNGram = []
    }

    ngram_words.forEach((value) => {
      this.userNameNGram.push(value)
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  public nGramContainsAllWords(words: string[]) {
    return words.every((value) => this.userNameNGram.includes(value))
  }
}
