/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

// Components
import MessageHelperComp from '../../../../../helpers/MessageHelper'

// Constants
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { ILegalClauseInterface } from '../../../../../models/user-legal-clauses/user-legal-clauses.interface'

// Third party
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

// Models
import { UserLegalClausesModel } from '../../../../../models/user-legal-clauses/user-legal-clauses.model'

// Custom Hooks
import useToasterHelper from '../../../../../helpers/ToasterHelper'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import {
  selectUserId,
  selectUserLegalClauses,
  setUserLegalClauses,
} from '../../../../../store/user/userSlice'

// Services

// Helpers
import { getConvertedData } from '../../../../../models/interface.helper'
import MainModal from '../../../../modals/common/MainModal'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

type IDataToPassOn = {
  title?: string
  index: null | number
  bodyClassName?: string
  headingClassName?: string
}

// Types
type IProps = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const ProfileLegalClauseModal = (props: IProps) => {
  // Hooks and vars
  const toastFunctions = useToasterHelper()

  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const userId = useAppSelector(selectUserId)
  const clauseList = useAppSelector(selectUserLegalClauses)

  const dataToPassOn = props.dataToPassOn as IDataToPassOn
  const bodyClassName = dataToPassOn?.bodyClassName ?? ''
  const headingClassName = dataToPassOn?.headingClassName ?? ''
  const title = dataToPassOn?.title ? dataToPassOn?.title : 'Add Clause'

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<ILegalClauseInterface>({
    mode: 'onChange',
    resolver: yupResolver(UserLegalClausesModel.validationSchema().addClauseSchema),
  })

  useEffect(() => {
    if (typeof dataToPassOn.index === 'number') reset(clauseList[dataToPassOn.index])
  }, [clauseList, dataToPassOn.index])

  // Functions

  /** @info Closes the modal */
  const closeModal = () => props?.handleModal(false, MODAL_CONSTS.HANDLE_LEGAL_CLAUSE)

  /** @info Handles on submit */
  const onSubmit = async (data: ILegalClauseInterface) => {
    let errorOccured = false
    let clause: UserLegalClausesModel
    let index: number | null = typeof dataToPassOn?.index === 'number' ? dataToPassOn.index : null

    if (!userId) throw new Error(MESSAGES_CONST.SOMETHING_WENT_WRONG)

    try {
      if (typeof index === 'number') {
        let clauseInRedux = clauseList[index]
        clause = new UserLegalClausesModel(data)
        dispatch(
          setUserLegalClauses({
            index: dataToPassOn.index,
            clause: getConvertedData({
              ...clauseInRedux,
              ...clause.toObject(),
              add: clauseInRedux?.id ? false : true,
              update: clauseInRedux?.id ? true : false,
            }),
          })
        )
      } else {
        clause = new UserLegalClausesModel({ ...data, ownerId: userId })
        dispatch(
          setUserLegalClauses({
            clause: getConvertedData({
              ...clause.toObject(),
              add: true,
              update: false,
              ownerId: userId,
            }),
          })
        )
      }
    } catch (error) {
      console.log(error)
      errorOccured = true
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setLoading(false)
      if (!errorOccured) closeModal()
    }
  }

  return (
    <MainModal
      title={title}
      show={!!props.show}
      type="HANDLE_LEGAL_CLAUSE"
      className="mt-4 !px-1"
      titleClassName={headingClassName}
      size="md"
      buttons={[
        {
          label: typeof dataToPassOn.index === 'number' ? 'UPDATE' : 'SAVE',
          className: '!w-[75%]',
          bgClass: '!bg-SeabiscuitMainThemeColor',
          onClick: handleSubmit(onSubmit),
          loading,
          textClass: '!text-white',
          onHoverClass: 'hover:shadow-slate-300',
        },
        {
          label: 'CANCEL',
          bgClass: '!bg-SeabiscuitLightThemeColor',
          className: '!w-[75%]',
          borderClass: 'border border-transparent',
          textClass: '!text-SeabiscuitLightTextColor',
          onClick: () => closeModal,
          onHoverClass: 'hover:shadow-slate-300',
        },
      ]}
    >
      <div className={`${bodyClassName} ?? ""`}>
        <p className="mb-5 text-SeabiscuitDark200ThemeColor text-opacity-[.7]">
          Pegasus generates a General Liability Waiver for all events. The text you enter below will
          be inserted in the waiver. Only add text that is very specific to your property/event as
          most horse show clauses are included by default. Please use complete sentences.
        </p>
        <div className="mb-4">
          <input
            type="text"
            {...register('title')}
            placeholder="Name clause..."
            className="w-full border !ring-0 !border-[#D3DAEE] text-gray-900 xl:rounded-xl p-3.5 text-nr"
          />
          {errors?.title?.message ? (
            <MessageHelperComp isError={true} message={errors?.title?.message} />
          ) : null}
        </div>

        <div className="mb-4 ">
          <textarea
            rows={15}
            draggable="true"
            {...register('description')}
            placeholder="Enter clause text using full sentences...."
            className="w-full border !ring-0 !border-[#D3DAEE] text-gray-900 xl:rounded-xl p-3.5 placeholder:text-[#122B4680] placeholder:text-nr text-nr"
          />
          {errors?.description?.message ? (
            <MessageHelperComp isError={true} message={errors?.description?.message} />
          ) : null}
        </div>
      </div>

      {/* <footer className="flex flex-col items-center fixed bottom-5 w-full left-0 bg-white">
                <button type="button" className="w-1/3 h-12 mx-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor mt-3" onClick={handleSubmit(onSubmit)}>
                    {loading === true ?
                        <AutorenewRounded fontSize='small' className='animate-spin mx-auto' /> :
                        typeof dataToPassOn.index === "number" ? "UPDATE" : "SAVE"
                    }
                </button>
                <button type="button" className="w-1/3 h-12 mx-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-SeabiscuitIconThemeColor text-opacity-[.5] bg-opacity-[.1] bg-SeabiscuitIconThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor mt-3" onClick={closeModal}>
                    CANCEL
                </button>
            </footer> */}
    </MainModal>
  )
}

export default ProfileLegalClauseModal
