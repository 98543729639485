import WrapperWithHeader from '../../../components/common/wrappers/WrapperWithHeader'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { Clear } from '@mui/icons-material'

const DataPassOn = ({ faq, handleModal }: any) => {
  const closeModal = () => {
    handleModal(false, MODAL_CONSTS.HELP_VIDEO)
  }

  return (
    <>
      <div className="text-right">
        <button onClick={closeModal}>
          <Clear
            fontSize={'small'}
            style={{
              color: '#122B46',
              fontWeight: '400',
            }}
          />
        </button>
      </div>
      <div className="px-2">
        <h2 className="m-0 text-[22px] font-normal mt-[-1rem] text-SeabiscuitDark200ThemeColor mb-4">
          {faq.question}
        </h2>
        <p className="text-sm text-SeabiscuitDark200ThemeColor mb-2">{faq.answer}</p>
      </div>
    </>
  )
}

const data = [
  {
    question: 'Why can’t I create an event?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Auctor neque vitae tempus quam pellentesque. Suscipit tellus mauris a diam maecenas. Lectus nulla at volutpat diam ut venenatis tellus in. Condimentum id venenatis a condimentum vitae. Etiam sit amet nisl purus in. Consectetur adipiscing elit ut aliquam purus sit amet. Accumsan sit amet nulla facilisi. Eget gravida cum sociis natoque penatibus et magnis dis. Mi quis hendrerit dolor magna eget est. Venenatis tellus in metus vulputate eu scelerisque felis. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Condimentum mattis pellentesque id nibh tortor id aliquet lectus. At quis risus sed vulputate odio ut enim. Ut placerat orci nulla pellentesque dignissim. Lorem mollis aliquam ut porttitor leo a diam sollicitudin tempor.',
  },
  {
    question: 'Where can I view and download the paperwork I signed?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Auctor neque vitae tempus quam pellentesque. Suscipit tellus mauris a diam maecenas. Lectus nulla at volutpat diam ut venenatis tellus in. Condimentum id venenatis a condimentum vitae. Etiam sit amet nisl purus in. Consectetur adipiscing elit ut aliquam purus sit amet. Accumsan sit amet nulla facilisi. Eget gravida cum sociis natoque penatibus et magnis dis. Mi quis hendrerit dolor magna eget est. Venenatis tellus in metus vulputate eu scelerisque felis. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Condimentum mattis pellentesque id nibh tortor id aliquet lectus. At quis risus sed vulputate odio ut enim. Ut placerat orci nulla pellentesque dignissim. Lorem mollis aliquam ut porttitor leo a diam sollicitudin tempor.',
  },
  {
    question: 'How do I accept payments through Pegasus?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Auctor neque vitae tempus quam pellentesque. Suscipit tellus mauris a diam maecenas. Lectus nulla at volutpat diam ut venenatis tellus in. Condimentum id venenatis a condimentum vitae. Etiam sit amet nisl purus in. Consectetur adipiscing elit ut aliquam purus sit amet. Accumsan sit amet nulla facilisi. Eget gravida cum sociis natoque penatibus et magnis dis. Mi quis hendrerit dolor magna eget est. Venenatis tellus in metus vulputate eu scelerisque felis. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Condimentum mattis pellentesque id nibh tortor id aliquet lectus. At quis risus sed vulputate odio ut enim. Ut placerat orci nulla pellentesque dignissim. Lorem mollis aliquam ut porttitor leo a diam sollicitudin tempor.',
  },
]

const HelpRootPageFaqTab = ({
  handleModal,
}: {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}) => {
  return (
    <WrapperWithHeader
      title="Frequently Asked Questions"
      description="Quickly view answer to frequently asked questions"
    >
      <>
        <hr className="mb-4"></hr>
        {data && data.length
          ? data.map((faq: { question: string; answer: string }, index: number) => {
              return (
                <div
                  key={index}
                  className="flex justify-between mb-4 pb-4 border-b border-b-[#D3DAEE]"
                >
                  <div className="flex items-center">
                    <div className="text-SeabiscuitDark200ThemeColor text-[14px]">
                      {' '}
                      {faq.question}
                    </div>
                  </div>
                  <div
                    className="border border-[#D3DAEE] flex items-center justify-between rounded-xl p-4 w-40 cursor-pointer"
                    onClick={() =>
                      handleModal(true, MODAL_CONSTS.HELP_VIDEO, {
                        component: <DataPassOn handleModal={handleModal} faq={faq} />,
                      })
                    }
                  >
                    <span className="text-SeabiscuitDark200ThemeColor text-[14px]">Answer</span>
                    <img src="/assets/cp_icons/FullScreen.svg" alt="FullScreen" />
                  </div>
                </div>
              )
            })
          : null}
      </>
    </WrapperWithHeader>
  )
}

export default HelpRootPageFaqTab
