type IProps = {
  paid: number
  Unpaid: number
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const PaperWorkHeader = (props: IProps) => {
  return (
    <>
      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full"></div>
    </>
  )
}

export default PaperWorkHeader
