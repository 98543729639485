export const TicketList = [
  {
    lineItem: 'General entry - 1 day',
    available: 5,
    cost: 80,
  },
  {
    lineItem: 'VIP Pass - Friday ',
    available: 5,
    cost: 1000,
  },
]
