import { useEffect, useRef, useState } from 'react'

// Third party
import clsx from 'clsx'

// Types
import { IFees } from '../../../../models/event-drafts/event-draft.interface'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

import AmountInput from '../../../../components/common/inputs/AmountInput'
import {
  selectRegistrationR,
  selectResetRefundsTotals,
  setExhibitorRefundErrors,
  setExhibitorRefundTotals,
} from '../../../../store/exhibitor/exhibitorSlice'

import { setScratchFees } from '../../../../store/events/eventsSlice'
import { IRegistrationFeesInterface } from '../../../../models/registration-fees/registrationFees.interface'
import { IOnRefundAmountChangeFn } from '../ExhibitorProfileDisplayTab'
import { toFixed } from '../../../../helpers/helpers'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IExhibitorFeesRefundAccordionProps = {
  onRefundAmountChange?: IOnRefundAmountChangeFn
  registrationfeesData: IRegistrationFeesInterface[]
}

type IErrorsRef = {
  [key: string]: {
    message: string
    toastMessage: string
  }
}

type IValidate = (
  inputName: string,
  maxCost: number,
  value: number,
  feesName: string,
  itemId: string | null
) => void

type IDetailRowProps = {
  validate: IValidate
  refundAmount?: number
  registrationFees: IRegistrationFeesInterface
  errors: React.MutableRefObject<IErrorsRef>
  lastFocused: React.MutableRefObject<string | null>
  title: string
  cost?: number
  units: number
  itemId?: string
  isScratched: boolean
}

const ExhibitorFeesInput: React.FC<{
  className?: string
  data?: any
  icon?: any
}> = ({ className, data, icon }) => {
  return (
    <>
      {icon ? (
        <div className={`${className}`}>
          <img className="absolute left-3 top-3" src={icon} alt="icons" />
          {data}
        </div>
      ) : (
        <div className={`${className}`}>{data}</div>
      )}
    </>
  )
}

const DetailRow = ({
  title,
  cost,
  units,
  isScratched,
  itemId,
  registrationFees,
  errors,
  validate,
}: IDetailRowProps) => {
  let currAmountFieldName = `${itemId}`
  let [currentValue, setCurrentValue] = useState<number | string>(registrationFees.amountRefunded)

  return (
    <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
      <ExhibitorFeesInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1  flex-grow"
        data={title}
      />

      <ExhibitorFeesInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6 text-center"
        data={units}
      />

      <ExhibitorFeesInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6 text-center"
        data={`$${Number((cost ?? 0) * units).toFixed(2)}`}
      />

      <ExhibitorFeesInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6 text-center"
        data={isScratched ? 'Scratched' : 'No'}
      />

      <AmountInput
        withDecimals
        name={currAmountFieldName}
        prefix="$"
        placeholder="Enter amount..."
        inputClassName={clsx(
          'w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor',
          !!errors?.current?.[currAmountFieldName] && 'refundError text-[#EB5757]'
        )}
        disable={!!registrationFees.refundAmountAdded || !isScratched}
        className={clsx(
          'text-[14px] border !border-solid rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor w-1/6 m-1 focus:bg-white',
          !registrationFees.refundAmountAdded
            ? !!errors?.current?.[currAmountFieldName] &&
                'text-[#EB5757] !bg-[#EB57571A] border-red-500'
            : '!bg-SeabiscuitGrayThemeColor',
          !!registrationFees.refundAmountAdded || !isScratched
            ? 'bg-SeabiscuitGrayThemeColor'
            : 'bg-white'
        )}
        title={errors?.current?.[currAmountFieldName]?.message}
        onChange={(value: string) => {
          if (registrationFees.refundAmountAdded) return

          const newValue = value.replace('$', '')
          setCurrentValue(newValue)

          validate(
            currAmountFieldName,
            (cost ?? 0) * units,
            Number(newValue),
            title,
            itemId ?? null
          )
        }}
        value={currentValue}
      />
    </div>
  )
}

const ExhibitorFeesRefundAccordion = ({
  onRefundAmountChange,
  registrationfeesData,
}: IExhibitorFeesRefundAccordionProps) => {
  // Hooks and vars
  const dispatch = useAppDispatch()

  const errors = useRef<IErrorsRef>({})
  const lastFocused = useRef<string | null>(null)

  const [refundsTotal, setRefundsTotal] = useState(0)
  const [itemsSelectedForRefund, setItemsSelectedForRefund] = useState(0)
  const [listOfItemsToRefund, setListOfItemsToRefund] = useState<Set<string>>(new Set())

  const resetRefundTotals = useAppSelector(selectResetRefundsTotals)
  const regisrationR = useAppSelector(selectRegistrationR)

  const data = regisrationR?.selectionDetails?.selectedFees
  useEffect(() => {
    setListOfItemsToRefund(new Set())
  }, [resetRefundTotals])

  useEffect(() => {
    let selectedIds: string[] = []
    let total = 0

    registrationfeesData.forEach((registrationfee) => {
      if (registrationfee.amountRefunded > 0 && !registrationfee.refundAmountAdded) {
        total += registrationfee.amountRefunded
        selectedIds.push(registrationfee.id ?? '')
      }
    })

    setRefundsTotal(total)
    setItemsSelectedForRefund(selectedIds.length)

    const selectedregistrationfeesData = registrationfeesData.filter((registrationfees) =>
      selectedIds.includes(registrationfees.id ?? '')
    )

    dispatch(
      setExhibitorRefundTotals({
        keyToUpdate: 'feesRefundPriceTotal',
        value: selectedregistrationfeesData,
      })
    )
  }, [registrationfeesData])

  // Functions
  const handleScratchList = (itemId: string | null, refundAmount: null | number) => {
    if (!itemId) return

    let itemsToScratchList: string[] = []
    let listOfItemsToRefund_ = new Set(listOfItemsToRefund)

    let alreadyPresent = listOfItemsToRefund_.has(itemId)

    if (refundAmount) {
      if (!alreadyPresent) listOfItemsToRefund_.add(itemId)
    } else {
      listOfItemsToRefund_.delete(itemId)
    }

    setListOfItemsToRefund(new Set(listOfItemsToRefund_))

    listOfItemsToRefund_.forEach((curr) => {
      itemsToScratchList.push(curr)
    })

    let updatedDataList_ = data
      ? data.reduce((acc: IFees[], curr) => {
          if (itemsToScratchList.includes(curr.uuid ?? '') && refundAmount)
            acc.push({
              ...curr,
              refundAmount,
            })
          else acc.push(curr)

          return acc
        }, [])
      : []

    dispatch(setScratchFees(updatedDataList_))
  }

  const validate: IValidate = (inputName, maxCost, value, feesName, itemId) => {
    let message: string | null = null

    if (!maxCost || !inputName) {
      return delete errors.current?.[inputName]
    }

    if (value > maxCost)
      errors.current = {
        ...errors.current,
        [inputName]: {
          message: `max value that can be refunded is $${maxCost}`,
          toastMessage: `${feesName.replace(/\s+/gi, '')}'s max value that can be refunded is $${maxCost}`,
        },
      }
    else delete errors.current?.[inputName]
    onRefundAmountChange?.({
      type: 'fees',
      itemDetails: {
        itemId: inputName,
        itemRefundAmount: value,
      },
    })
    if (Object.keys(errors.current).length)
      message = errors.current[Object.keys(errors.current)[0]]?.toastMessage ?? null

    dispatch(
      setExhibitorRefundErrors({
        keyToUpdate: 'feesError',
        message,
      })
    )

    handleScratchList(itemId, value)
  }

  return (
    <>
      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        <ExhibitorFeesInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-1 font-semibold flex-grow')}
          data={`Line item`}
        />

        <ExhibitorFeesInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/6')}
          data={`Number of units`}
        />

        <ExhibitorFeesInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/6')}
          data={'Amount paid'}
        />

        <ExhibitorFeesInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/6')}
          data={`Scratched?`}
        />

        <ExhibitorFeesInput
          className={clsx('text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/6')}
          data={`Enter Refund Amount`}
        />
      </div>

      {registrationfeesData.map((currentItem, index) => {
        return (
          <DetailRow
            key={`${JSON.stringify(currentItem.id)}${index}`}
            errors={errors}
            validate={validate}
            lastFocused={lastFocused}
            cost={currentItem.feesPrice}
            registrationFees={currentItem}
            title={currentItem.feesTitle ?? ''}
            units={currentItem.selectedUnitsCount}
            itemId={currentItem?.id ?? ''}
            refundAmount={currentItem.amountRefunded}
            isScratched={currentItem?.isSratched ?? false}
          />
        )
      })}

      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        <ExhibitorFeesInput
          className={clsx(
            'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-normal h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1 w-1/6'
          )}
          data="Total"
        />

        <ExhibitorFeesInput
          className={clsx(
            'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-normal h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1 w-5/6 text-right'
          )}
          data={`${itemsSelectedForRefund} item, $${toFixed(refundsTotal)} refunded`}
        />
      </div>
    </>
  )
}

export default ExhibitorFeesRefundAccordion
