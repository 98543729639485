// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { CONST } from '../../../../const/const'

function formBuilderPrizeListFactory(items: any) {
  return {
    vMain: 1,
    vSub: 0,
    vFix: 0,
    items: items,
    summaryItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUMMARY.TYPE.STANDARD,
    summary: {
      totalDivisionsOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      totalSectionsOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      totalClassesOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      totalMiscellaneousOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      totalClassicsOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      totalDerbysOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      totalLeaguesOffered: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      equitationDivisionsPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      hunterDivisionsPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      jumperDivisionsPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      miscellaneousPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      classicsPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
      derbysPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },

      showTotalPrizeMoney: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
    },
  }
}

export default formBuilderPrizeListFactory
