import { Link } from 'react-router-dom'
import IHorseCardTypes from './horseCard.types'
import { IMAGE_CONSTS } from '../../../../../../const/image-const'
import { disciplineData } from '../../../../../../fakeData/disciplineList'
import { CONST } from '../../../../../../const/const'

// Constants
const NA = 'N/A'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const HorseCard = (props: IHorseCardTypes['IHorseCardProps']) => {
  // Hooks and vars
  const {
    horseId,
    horseName,
    horseZone,
    mappingDocId,
    horseDiscipline,
    horseProfilePicture,
    onRemoveHorseClick,
  } = props

  let horseZone_ = horseZone
  let horseDiscipline_ = horseDiscipline

  if (!!horseDiscipline_)
    horseDiscipline_ = disciplineData.find(
      (currDiscipline) => currDiscipline.value === horseDiscipline_
    )?.label

  if (typeof horseDiscipline_ !== 'string') horseDiscipline_ = NA

  if (typeof horseZone_ !== 'string') horseZone_ = NA

  return (
    // please dont add link here
    <div className="flex items-center cursor-pointer">
      <div className="horse flex flex-row items-center w-80 rounded-xl border border-SeabiscuitGray500ThemeColor">
        <Link
          to={`${CONST.ROUTES.HORSES.SUBMENU.SUB_URL}/${mappingDocId}/${horseId}`}
          className="py-1.5 pl-2"
        >
          <img
            src={
              !horseProfilePicture || horseProfilePicture === ''
                ? IMAGE_CONSTS.PLACEHOLDERS.HORSE
                : horseProfilePicture
            }
            className="mr-2 avatarImg rounded-md shrink-0 cursor-pointer object-cover"
            alt="profilePicture"
          />
        </Link>

        <Link
          to={`${CONST.ROUTES.HORSES.SUBMENU.SUB_URL}/${mappingDocId}/${horseId}`}
          className="hourseDetails flex-grow min-w-fit py-1.5"
        >
          <div className="hourseTitle text-SeabiscuitDark200ThemeColor capitalize leading-[1]">
            {horseName}
          </div>
          <div className="hourseCategory flex max-w-full flex-wrap text-sm text-SeabiscuitDark200ThemeColor capitalize">
            <p className="max-w-[80px] ellipsis" title={horseDiscipline_}>
              {horseDiscipline_}
            </p>
            {horseZone && (
              <p title={horseZone_} className="max-w-[110px] ellipsis capitalize pl-1">
                •{' '}
                {horseZone_ === 'international'
                  ? horseZone_
                  : horseZone_ === 'n/a'
                    ? 'N/A'
                    : `Zone ${horseZone_}`}
              </p>
            )}
          </div>
        </Link>

        <div className="settings w-fit flex justify-end items-center gap-2 py-1.5 pr-2">
          {/* {props.isMyHorse ?
                            <span className="p-1 bg-opacity-[0.6] rounded-lg inline-block">
                                <img
                                    src={`/assets/img/light/Settings.svg`}
                                    alt="settings"
                                    className='w-6 h-6' />
                            </span>
                        : null} */}
          <span className="text-SeabiscuitMainThemeColor cursor-pointer">
            <img
              alt="delete"
              className="w-6 h-6"
              src={'assets/og_icons/Cancel.svg'}
              onClick={() => onRemoveHorseClick({ mappingDocId })}
            />
          </span>
        </div>
      </div>
    </div>
  )
}

export default HorseCard
