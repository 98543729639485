import React from 'react'
import { StepTitle } from './StepTitle'
import { IconCheck } from '../../../icons/IconCheck'
import { RegistrationFeesType } from '../../../../models/event-fees/event-fees.interface'

interface IProps {
  entries: RegistrationFeesType[]
  selectedEntries: RegistrationFeesType[]
  selectHandler: (entry: RegistrationFeesType) => void
}

export const SelectRiderClass: React.FC<IProps> = ({ entries, selectedEntries, selectHandler }) => {
  return (
    <>
      <StepTitle title={'Select class'} desc={'Select classes you wish to swap the horse for'} />
      <ul className={'flex flex-col gap-3'}>
        {entries.map((entry) => {
          const isActive = !!selectedEntries.find((itm) => itm.uuid === entry.uuid)
          return (
            <li
              key={entry.uuid}
              onClick={() => selectHandler(entry)}
              className={
                'p-5 flex justify-between gap-3 border border-solid border-[#D3DAEE] rounded-[10px] cursor-pointer'
              }
            >
              <span className={'text-[#122B46] text-[14px]'}>{entry.name}</span>
              <div
                className={`w-[22px] h-[22px] rounded-full border-solid border-[2px] ${isActive ? 'border-[#00B6AA]' : 'border-[#D3DAEE]'}  flex items-center justify-center`}
              >
                {isActive ? <IconCheck color={'fill-[#00B6AA]'} /> : <></>}
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}
