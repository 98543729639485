// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { Dialog } from '@mui/material'
import { forwardRef, ReactElement, Ref } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import Slide from '@mui/material/Slide'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement
  },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />
})

interface FullScreenDialogComponentProps {
  isOpen?: boolean
  onClose?: any
  children: React.ReactElement
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const FullScreenDialogCommonComponent: React.FC<FullScreenDialogComponentProps> = (props) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <Dialog
      fullScreen
      open={props.isOpen as boolean}
      onClose={props.onClose}
      TransitionComponent={Transition}
    >
      {props.children}
    </Dialog>
  )
}

FullScreenDialogCommonComponent.defaultProps = {
  isOpen: false,
  onClose: null,
}

export default FullScreenDialogCommonComponent
