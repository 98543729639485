import { useContext } from 'react'
import { CONST } from '../const/const'
import { MESSAGES_CONST } from '../const/messages-const'
import { MODAL_CONSTS } from '../const/modal-const'
import useToasterHelper from '../helpers/ToasterHelper'
import { HandleModalContext } from '../layout/mainlayout/MainLayout'
import { UserModel } from '../models/users/user.model'
import FirestoreService from '../services/firestoreService'

const useGetUserEmailViaDocId = () => {
  const toastFunctions = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)
  const handleModal = handleModalContext?.handleModal

  const getEmail = async (args: { userId: string | null }) => {
    let emailId: string | null = null

    try {
      if (!args.userId) {
        throw new Error('staffDocId is empty')
      }

      const userSnapshot = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        args.userId
      )

      if (!userSnapshot.exists()) {
        throw new Error(MESSAGES_CONST.USER_NOT_FOUND)
      }

      const user = UserModel.fromFirestoreDoc(userSnapshot)
      emailId = user.userEmail

      handleModal?.(true, MODAL_CONSTS.SEND_MESSAGE, { emailId })
    } catch (error: any) {
      toastFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      console.error(error)
    } finally {
      return emailId
    }
  }

  return {
    getEmail,
  }
}

export default useGetUserEmailViaDocId
