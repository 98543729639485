import TimeLib from '../../../../lib/Time'

export const DATA_FIRESTORE_V01_USER_LEGAL_CLAUSES = {
  NAME: 'v01_user_legal_clauses',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    ID: {
      KEY: 'id',
      VALUE: null,
    },

    TITLE: {
      KEY: 'title',
      VALUE: '',
    },

    OWNER_ID: {
      KEY: 'ownerId',
    },

    DESCRIPTION: {
      KEY: 'description',
      VALUE: '',
    },

    CREATED: {
      KEY: 'created',
      VALUE: TimeLib.utcTimestamp(),
    },

    MODIFIED: {
      KEY: 'modified',
      VALUE: TimeLib.utcTimestamp(),
    },
  },
}
