export const Sheet2023USEFUSDFDressageSeatEquitationClass = {
  id: 'Sheet2023USEFUSDFDressageSeatEquitationClass',
  name: '2023 USEF/USDF Dressage Seat Equitation Class',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 100,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Walk Left</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Walk Right</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Trot Left</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Trot Right</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Canter Left</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 6,
          description: '<h5>Canter Right</h5>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const Sheet2023USEFUSDFDressageSeatMedalsClass = {
  id: 'Sheet2023USEFUSDFDressageSeatMedalsClass',
  name: '2023 USEF/USDF Dressage Seat Equitation Class',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 100,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Walk Left</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Walk Right</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Trot Left</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Trot Right</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Canter Left</h5>',
          score: '',
          remarks: '',
        },
        {
          number: 6,
          description: '<h5>Canter Right</h5>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}
