import React, { FC, useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import { sumBy } from 'lodash'
import clsx from 'clsx'
import { where } from 'firebase/firestore'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import * as XLSX from 'xlsx'

import { formatOptionLabel, ReportsTabWrapper } from '../ReportsTabWrapper/ReportsTabWrapper'
import InfiniteScrollDataTable from '../../../../../../../../components/common/tables/InfiniteScrollDataTable'

import useToasterHelper from '../../../../../../../../helpers/ToasterHelper'
import { capitalize, toFixed } from '../../../../../../../../helpers/helpers'

import { useAppSelector } from '../../../../../../../../store/hooks'
import { selectUserReducer } from '../../../../../../../../store/user/userSlice'

import TimeLib from '../../../../../../../../lib/Time'

import { IUSEFESection, sectionCodesByDisciplines } from '../../../../data/reports/USEF'
import { IReports } from '../../ManageClinicReportsRoot'

import FirestoreService from '../../../../../../../../services/firestoreService'

import { IEventInterface } from '../../../../../../../../models/events/event.interface'
import { IRegistrationByDayInterface } from '../../../../../../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationFeesType } from '../../../../../../../../models/event-fees/event-fees.interface'
import { IHorseData } from '../../../../../../../../models/horse/horse.interface'
import { ITeamMember, IUserInterface } from '../../../../../../../../models/users/user.interface'
import { EventResultsReportsModel } from '../../../../../../../../models/event-results-reports/event-results-reports.model'
import { getConvertedData } from '../../../../../../../../models/interface.helper'

import { MESSAGES_CONST } from '../../../../../../../../const/messages-const'
import { CONST } from '../../../../../../../../const/const'
import { selectEventDetails } from '../../../../../../../../store/events/eventsSlice'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

interface IUSEFResultsReport {
  class: { uuid: string; name: string }
  discipline: string
  USEFSection: { code: string; name: string }
}

const getUserFullName = (user: IUserInterface) => {
  return user?.userFirstName && user?.userLastName
    ? `${user?.userLastName ?? ''} ${user?.userFirstName ?? ''}`
    : capitalize(user?.userName) ?? ''
}

interface USEFResultsReportProps {
  event: IEventInterface | null
  registrationFees: any
  activeOption: IReports
  setActiveOption: (value: IReports) => void
  setActiveResult: (value: string) => void
  registeredUsersByDay: IRegistrationByDayInterface[] | null
  horses: IHorseData[] | null
  users: IUserInterface[] | null
  teamMembers: ITeamMember[] | null
}

function parseAddress(address: string) {
  const addressRegex = /^(.*),\s*(.*),\s*([A-Z]{2})\s*(\d{5})(?:,\s*USA)?$/
  const matches = address.match(addressRegex)

  if (matches) {
    return {
      street: matches[1],
      city: matches[2],
      state: matches[3],
      zip: matches[4],
    }
  } else {
    return null // Address format does not match the expected pattern
  }
}

export const USEFResultsReport: FC<USEFResultsReportProps> = ({
  event,
  registrationFees,
  activeOption,
  setActiveOption,
  setActiveResult,
  registeredUsersByDay,
  horses,
  users,
  teamMembers,
}) => {
  const toastFunctions = useToasterHelper()
  const eventDetails = useAppSelector(selectEventDetails)
  const user = useAppSelector(selectUserReducer)

  const [rows, setRows] = useState<IUSEFResultsReport[]>([])
  const [isReadyToExport, setIsReadyToExport] = useState(false)
  const [discipline, setDiscipline] = useState('')
  const [reportId, setReportId] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const isFilled = rows.filter((row) => row.discipline && row.USEFSection?.code)
    if (isFilled.length >= 1) {
      setIsReadyToExport(true)
    } else {
      setIsReadyToExport(false)
    }
  }, [rows])

  useEffect(() => {
    if (discipline) {
      const newRows = rows.map((row) => ({
        class: row.class,
        discipline: discipline,
        USEFSection: { code: '', name: '' },
      }))
      setRows(newRows)
      saveEventReport(newRows).then()
    }
  }, [discipline])

  useEffect(() => {
    getEventReport().then()
  }, [registrationFees])

  const saveEventReport = async (rows: IUSEFResultsReport[]) => {
    try {
      if (reportId) {
        await FirestoreService.updateItem(COLLECTIONS.EVENT_RESULTS_REPORTS.NAME, reportId, {
          rows,
          modified: TimeLib.utcTimestamp(),
        })
      } else {
        const ref = await FirestoreService.createItem(
          COLLECTIONS.EVENT_RESULTS_REPORTS.NAME,
          new EventResultsReportsModel({
            userId: user.userId,
            eventId: event?.id ?? '',
            eventName: event?.eventName ?? '',
            reportType: 'USEF Results',
            rows,
          }).toFirestore()
        )
        setReportId(ref.id)
      }
    } catch (error) {
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  const getEventReport = async () => {
    setLoading(true)

    const eventResultsReportSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_RESULTS_REPORTS.NAME,
      [
        where(COLLECTIONS.EVENT_RESULTS_REPORTS.FIELDS.EVENT_ID.KEY, '==', event?.id),
        where(COLLECTIONS.EVENT_RESULTS_REPORTS.FIELDS.USER_ID.KEY, '==', user.userId),
        where(COLLECTIONS.EVENT_RESULTS_REPORTS.FIELDS.REPORT_TYPE.KEY, '==', 'USEF Results'),
      ]
    )

    if (eventResultsReportSnaps.size > 0) {
      const eventResultsReport = getConvertedData(
        EventResultsReportsModel.fromFirestoreDoc(eventResultsReportSnaps.docs[0]).toObject()
      )

      setRows(eventResultsReport?.rows ?? [])
      setReportId(eventResultsReport?.id ?? '')
    } else {
      const rows_: IUSEFResultsReport[] = []

      const registrationFee = [...registrationFees?.registrationFees]

      registrationFee.forEach((fee) => {
        rows_.push({
          class: { uuid: fee.uuid, name: fee.name },
          discipline: '',
          USEFSection: { code: '', name: '' },
        })
      })

      setRows(rows_)
      getEventReport().then()
    }

    setLoading(false)
  }

  const onExport = () => {
    if (isReadyToExport) {
      const data: { [key: string]: string | number }[] = []
      let maxJudges = 0
      registeredUsersByDay?.forEach((registeredUserByDay) => {
        const length = registeredUserByDay?.score?.judges?.length ?? 0
        if (maxJudges < length) maxJudges = registeredUserByDay?.score?.judges?.length ?? 0
      })

      registeredUsersByDay?.forEach((registeredUserByDay) => {
        const classes = registeredUsersByDay
          ?.filter(
            (registered) =>
              registered.riderId === registeredUserByDay.riderId &&
              registered.horseId === registeredUserByDay.horseId
          )
          .map((user) => user.registrationByDayName)

        const prizeMoneyTotal = [...registrationFees?.registrationFees].find(
          (registrationFee: RegistrationFeesType) =>
            registrationFee.uuid === registeredUserByDay.uuid
        )?.prizeMoney

        const horse = horses?.find((horse) => horse.id === registeredUserByDay.horseId)

        const rider = users?.find((user) => user.id === registeredUserByDay.riderId)

        const owner = users?.find((user) => user.id === horse?.horseOwnerId)

        const teamMember = teamMembers?.find(
          (member) => member.horseId === horse?.id && member.memberRole === 'Trainer'
        )

        let trainer = users?.find((user) => user.id === teamMember?.memberId)

        // set owner as trainer if we do not have trainer
        if (!trainer?.id) trainer = owner

        const judges: { [x: string]: string | number }[] = []

        registeredUserByDay.score?.judges?.forEach((judge, index) => {
          let totalPoints = 0

          judge.sections.forEach((section) =>
            section.rows.forEach((row) => {
              if (row.coefficient) {
                totalPoints += Number(row.coefficient) * Number(row.score)
              } else {
                totalPoints += Number(row.score)
              }
            })
          )

          const maxPoints = sumBy(judge.sections, (section) => section.max)

          judges.push({
            [`Judge ${index + 1} First Name`]: judge.name.split(' ')[0],
            [`Judge ${index + 1} Last Name`]: judge.name.split(' ')[1],
            [`Judge ${index + 1} Percentage`]: maxPoints
              ? toFixed((totalPoints / maxPoints) * 100, 3)
              : 0,
            [`Judge ${index + 1} Score (Optional except Dressage)`]: totalPoints,
          })
        })

        const combinedJudges: { [key: string]: string | number } = {}

        if (judges.length < maxJudges) {
          const newCombinedJudges = Array.from(
            { length: maxJudges - judges.length },
            (_, index) => ({
              [`Judge ${index + 1 + judges.length} First Name`]: '',
              [`Judge ${index + 1 + judges.length} Last Name`]: '',
              [`Judge ${index + 1 + judges.length} Percentage`]: '',
              [`Judge ${index + 1 + judges.length} Score (Optional except Dressage)`]: '',
            })
          )

          const newJudges = [...judges, ...newCombinedJudges]

          newJudges.forEach((judge) => {
            for (const [key, value] of Object.entries(judge)) {
              combinedJudges[key] = value
            }
          })
        } else {
          judges.forEach((judge) => {
            for (const [key, value] of Object.entries(judge)) {
              combinedJudges[key] = value
            }
          })
        }

        let max = 0
        if (registeredUserByDay.score?.judges) {
          max = sumBy(registeredUserByDay.score.judges[0]?.sections, (section) => section.max)
        }

        const dressagePercentageTotal =
          (Number(registeredUserByDay.score?.totalPoints ?? 0) / Number(max ?? 0)) * 100

        data.push({
          'COMP YEAR': new Date(registeredUserByDay.created as Date).getFullYear(),
          'USEF Comp ID number': eventDetails.bodyCompId,
          'Class Number': '',
          'Class Title': registeredUserByDay.registrationByDayName ?? '',
          'USEF Section Code':
            rows.find((row) => row.class.uuid === registeredUserByDay.uuid)?.USEFSection.code ?? '',
          'Number of entries': classes.length,
          'Prize Money awarded':
            toFixed(
              (prizeMoneyTotal * Number(registeredUserByDay.score?.earning) > 0
                ? Number(registeredUserByDay.score?.earning)
                : 0) / 100,
              3
            ) ?? 0,
          'Prize Money Offered': Number(prizeMoneyTotal) ?? 0,
          'Qualifier Dressage or Eventing (Dressage mandatory)': '',
          Placing: registeredUserByDay.score?.rank ?? '',
          'Exhibitor Number': registeredUserByDay.backNumber,
          'Horse USEF Number': horse?.horseUsefNumber ?? '',
          'Horse Passport Number':
            horse?.horsePassportNumber && horse?.horsePassportNumber !== 'N/A'
              ? horse.horsePassportNumber
              : '',
          'Horse Name': horse?.horseName ?? '',
          'Horse Registration/Breed Affiliate Number': '',
          'Horse FEI Number or Discipline Affiliate Number (USDF, USEA, etc.)':
            horse?.horseFeiNumber ?? '',
          'Horse Year of Birth':
            new Date(horse?.horseDob as Date).getFullYear() > 0
              ? new Date(horse?.horseDob as Date).getFullYear()
              : '',
          'Horse Breed': (horse?.horseBreed as string) ?? '',
          'Rider USEF Number': rider?.userUSEF?.documentNumber ?? '',
          'If No USEF Number, WHY?': '',
          'Rider Name': rider?.id ? getUserFullName(rider) : '',
          'Rider Street Address': parseAddress(owner?.userAddress ?? '')?.street ?? '',
          'Rider City': parseAddress(owner?.userAddress ?? '')?.city ?? '',
          'Rider State': parseAddress(owner?.userAddress ?? '')?.state ?? '',
          'Rider Zip': parseAddress(owner?.userAddress ?? '')?.zip ?? '',
          'Rider GMO Affiliate No.  CDS  (IAHA, AHA,  AMA, etc.)': '',
          'Rider Discipline Number or FEI Number or Additional Affiliate Number (USDF, USEA, etc.) ':
            '',
          'Owner USEF Number': owner?.userUSEF?.documentNumber ?? '',
          'If Owner No USEF Number, WHY?': '',
          'Owner Name': owner?.id ? getUserFullName(owner) : '',
          'Owner Street Address': parseAddress(owner?.userAddress ?? '')?.street ?? '',
          'Owner City': parseAddress(owner?.userAddress ?? '')?.city ?? '',
          'Owner State': parseAddress(owner?.userAddress ?? '')?.state ?? '',
          'Owner Zip': parseAddress(owner?.userAddress ?? '')?.zip ?? '',
          'Owner Breed Affiliate No. (,AHA, IAHA, etc.)': '',
          'Owner Discipline Number or FEI Number or Additional Affiliate Number (USDF, USEA, etc.)':
            '',
          'Trainer USEF Number': trainer?.userUSEF?.documentNumber ?? '',
          'If Trainer No USEF Number, WHY?': '',
          'Trainer Name': trainer?.id ? getUserFullName(trainer) : '',
          'Trainer Address': parseAddress(trainer?.userAddress ?? '')?.street ?? '',
          'Trainer City': parseAddress(trainer?.userAddress ?? '')?.city ?? '',
          'Trainer State': parseAddress(trainer?.userAddress ?? '')?.state ?? '',
          'Trainer Zip': parseAddress(trainer?.userAddress ?? '')?.zip ?? '',
          'Trainer Affiliate Number (USDF, AHA, IAHA, etc.)': '',
          ...combinedJudges,
          'Dressage Score (Total)': registeredUserByDay.score?.totalPoints ?? 0,
          'Dressage Percentage (Total)': `${dressagePercentageTotal > 0 && dressagePercentageTotal !== Infinity ? toFixed(dressagePercentageTotal, 3) : 0}%`,
          'Dressage Level':
            rows.find((row) => row.class.uuid === registeredUserByDay.uuid)?.USEFSection.name ?? '',
          'Dressage Rider Status': rider?.userAmateur ?? '',
          'FEI Rider Nationality': rider?.userNationality ?? '',
          'FEI Horse Nationality': '',
          'Riders Email Address': rider?.userEmail ?? '',
          'Owner Email Address': owner?.userEmail ?? '',
          'Number that Completed': classes.length,
          'Add Back Money': '',
          'Horse/Rider Falls': '',
        })
      })

      // Convert data to a flat array of rows
      const headers = Object.keys(data[0])
      const sheetRows = [headers, ...data.map(Object.values)]

      // Calculate column widths
      const columnWidths = headers.map((header, colIndex) => {
        return {
          wch: Math.max(
            ...sheetRows.map((row) =>
              row[colIndex]
                ? row[colIndex].toString().length > 30
                  ? row[colIndex].toString().length
                  : 30
                : 0
            )
          ),
        }
      })

      const ws = XLSX.utils.aoa_to_sheet(sheetRows)
      ws['!cols'] = columnWidths

      // Create a workbook and add the worksheet
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'USEF Results')

      // Write the workbook to an Excel file
      XLSX.writeFile(wb, `Event Reports -USEF Results - ${event?.eventName}.xlsx`)
    } else {
      toastFunctions.success({
        message: MESSAGES_CONST.REPORT_EXPORT_IS_READY,
      })
    }
  }

  const columns = [
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm">
          <span className="whitespace-nowrap">Class</span>
        </span>
      ),
      cell: (row: IUSEFResultsReport) => (
        <div
          className={clsx(
            'my-2 p-2 min-h-[56px] w-full rounded-md flex items-center border border-SeabiscuitLightThemeColorD3',
            row.discipline && row.USEFSection.code && 'bg-SeabiscuitGrayThemeColor'
          )}
        >
          {row.class.name}
        </div>
      ),
      width: '33.3%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm">
          <span className="whitespace-nowrap">Discipline</span>
        </span>
      ),
      cell: (row: IUSEFResultsReport, index: number) => (
        <div className="flex items-center w-full pl-2">
          <Select
            className="w-full"
            classNamePrefix="clinic-select"
            isClearable={false}
            isSearchable={false}
            placeholder="Select Discipline"
            menuPlacement="auto"
            menuPortalTarget={document.body}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: '#D3DAEE',
                backgroundColor: row.discipline && row.USEFSection.code ? '#F6F7FB' : '#fff',
              }),
            }}
            options={Object.keys(sectionCodesByDisciplines).map((discipline) => ({
              label: discipline,
              value: discipline,
            }))}
            value={row.discipline ? { label: row.discipline, value: row.discipline } : null}
            onChange={async (value) => {
              rows[index].discipline = value?.value ?? ''
              rows[index].USEFSection = { code: '', name: '' }
              setRows([...rows])
              await saveEventReport(rows)
            }}
          />
        </div>
      ),
      width: '33.3%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm">
          <span className="whitespace-nowrap">Section codes</span>
        </span>
      ),
      cell: (row: IUSEFResultsReport, index: number) => {
        let sectionCodesOptions: { class: string; code: string }[]

        if (row.discipline) {
          sectionCodesOptions = sectionCodesByDisciplines[row.discipline as keyof IUSEFESection]
        } else {
          sectionCodesOptions = []
        }
        return (
          <div className="flex items-center w-full pl-2">
            <Select
              className="w-full"
              classNamePrefix="clinic-select"
              isClearable={false}
              isSearchable={false}
              placeholder={row.discipline ? 'Select Section Code' : 'Select a Discipline'}
              menuPlacement="auto"
              menuPortalTarget={document.body}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: '#D3DAEE',
                  backgroundColor: row.discipline && row.USEFSection.code ? '#F6F7FB' : '#fff',
                }),
              }}
              options={
                sectionCodesOptions
                  ? sectionCodesOptions.map((discipline) => ({
                      label: discipline.class,
                      value: discipline.code,
                    }))
                  : []
              }
              value={
                row.USEFSection.code
                  ? { label: row.USEFSection.name, value: row.USEFSection.code }
                  : null
              }
              onChange={async (value) => {
                rows[index].USEFSection = value?.value
                  ? { code: value.value, name: value.label }
                  : { code: '', name: '' }
                setRows([...rows])
                await saveEventReport(rows)
              }}
            />
          </div>
        )
      },
      width: '33.3%',
    },
  ]

  return (
    <ReportsTabWrapper
      title="USEF Results"
      description="Match classes to section codes to print"
      activeOption={activeOption}
      setActiveOption={setActiveOption}
      onBack={() => setActiveResult('')}
      onExport={onExport}
      isReadyToExport={isReadyToExport}
      filledRows={
        rows.length - rows.filter((row) => row.discipline && row.USEFSection?.code).length
      }
      additional={
        <Select
          value={
            discipline
              ? {
                  label: discipline,
                  value: discipline,
                }
              : null
          }
          options={Object.keys(sectionCodesByDisciplines).map((discipline) => ({
            label: discipline,
            value: discipline,
          }))}
          className="min-w-[300px]"
          isSearchable={false}
          placeholder="Discipline"
          formatOptionLabel={formatOptionLabel}
          styles={{
            control: (styles) => ({
              ...styles,
              borderRadius: '8px',
              borderColor: '#D3DAEE',
            }),
          }}
          onChange={(value) => {
            setDiscipline(value?.value as IReports)
          }}
          components={{
            DropdownIndicator: (props_) => {
              return (
                <components.DropdownIndicator {...props_} className="[&>svg>path]:fill-[#122B46]" />
              )
            },
            IndicatorSeparator: () => null,
          }}
        />
      }
    >
      {!loading ? (
        <InfiniteScrollDataTable
          hasMore={false}
          className="exhibitorListTable"
          columns={columns}
          data={rows}
        />
      ) : (
        <div className="flex items-center justify-center p-4">
          <AutorenewIcon fontSize="small" className="animate-spin" />
        </div>
      )}
    </ReportsTabWrapper>
  )
}
