// ############################################################
/**
 * Contains the model to hold the events (and perform migrations
 * if it comes the case)
 */
// ############################################################

import * as yup from 'yup'

import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { IEventPostDetailInterface } from './event-post-detail.interface'
import formBuilderPrizeListFactory from '../../core/event/form-builder/prize_list/prize_list_form_builder'
import { EPL_FB_HUNTER_AA } from '../../config/event/prize-list/form-builder/hunter/aa/epl-fb-hunter-aa'
import { EPL_FB_EQUITATION_AA } from '../../config/event/prize-list/form-builder/equitation/aa/epl-fb-equitation-aa'
import { EPL_FB_JUMPER_L1 } from '../../config/event/prize-list/form-builder/jumper/l1/epl-fb-jumper-l1'
import { CONFIG } from '../../config/config'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */
export class EventPostDetailModel
  extends ModelBaseModel<IEventPostDetailInterface>
  implements IModelBaseModel
{
  public v: number
  public id: string | null
  public owner: string | null

  // ____________________________________________________________
  // Event Details
  public eventName: string

  public licenseeName: string
  public licenseeId: string
  public competitionId: string
  public competitionYear: number
  public competitionOverallRating: string
  public competitionZone: string
  public competitionPhone: string
  public competitionStartDate: Date
  public competitionEndDate: Date
  public competitionAddressLine1: string
  public competitionAddressLine2: string
  public competitionCity: string
  public competitionState: string
  public competitionZipCode: string
  public competitionCountry: string
  public competitionLocationNotes: string
  public competitionPOCId: string
  public competitionPOCName: string
  public competitionPOCPhone: string
  public competitionPOCEmail: string
  public competitionRegistrationOpenDate: Date
  public competitionRegistrationOpenTime: Date
  public competitionRegistrationCloseDate: Date
  public competitionRegistrationCloseTime: Date
  public facilities: any
  public summaryLong: string
  public summaryShort: string

  // ____________________________________________________________
  // Staff
  // FILL

  // ____________________________________________________________
  // Prize List
  public data: any

  // ____________________________________________________________
  // Scoring
  // FILL

  // ____________________________________________________________
  // Scheduling
  // FILL

  // ____________________________________________________________
  // Fees
  public prizeListFees: any

  // ____________________________________________________________
  // Paperwork
  public entryBlank: boolean
  public universalOrganizerWaver: boolean
  public USEFWaiverAndReleaseOfLiability: boolean
  public USEFEntryAgreement: boolean
  public USDFWaiverAndReleaseOfLiability: boolean
  public USEAWaiverAndReleaseOfLiability: boolean
  public USHJAWaiverAndReleaseOfLiability: boolean

  // ____________________________________________________________
  // Sponsors
  public sponsorTitle: string
  public isSponsorTitleForSale: boolean
  public acceptSponsorshipOnGrounds: boolean
  public sponsorOpportunities: any
  public allowSponsorshipPrizeList: boolean
  public prizeListSponsors: any

  // ____________________________________________________________
  // Vendors
  public acceptVendorsOnGrounds: boolean
  public vendorOpportunities: any
  public acceptVirtualVendors: boolean
  public virtualVendorSpotsAvailable: string
  public virtualVendorPrice: string
  public virtualVendorNote: string

  // ____________________________________________________________
  // Nearby Businesses
  public allowNearbyBusinessesToAdvertise: boolean
  public spotsAvailable: number
  public price: number
  public note: string

  public created: Date
  public modified: Date

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Constructs an instance of the class with an object that adheres to the
   * IEventPostInterface interface
   *
   * @param obj
   * Object that adheres to the IEventPostInterface interface
   */
  public constructor(obj?: IEventPostDetailInterface) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? null

    // ____________________________________________________________
    // Event Details
    this.eventName = obj?.eventName ?? ''

    this.licenseeName = obj?.licenseeName ?? ''
    this.licenseeId = obj?.licenseeId ?? ''
    this.competitionId = obj?.competitionId ?? ''
    this.competitionYear = obj?.competitionYear ?? new Date().getFullYear()
    this.competitionOverallRating = obj?.competitionOverallRating ?? ''
    this.competitionZone = obj?.competitionZone ?? ''
    this.competitionPhone = obj?.competitionPhone ?? ''
    this.competitionStartDate = obj?.competitionStartDate ?? new Date()
    this.competitionEndDate = obj?.competitionEndDate ?? new Date()
    this.competitionAddressLine1 = obj?.competitionAddressLine1 ?? ''
    this.competitionAddressLine2 = obj?.competitionAddressLine2 ?? ''
    this.competitionCity = obj?.competitionCity ?? ''
    this.competitionState = obj?.competitionState ?? ''
    this.competitionZipCode = obj?.competitionZipCode ?? ''
    this.competitionCountry = obj?.competitionCountry ?? ''
    this.competitionLocationNotes = obj?.competitionLocationNotes ?? ''
    this.competitionPOCId = obj?.competitionPOCId ?? ''
    this.competitionPOCName = obj?.competitionPOCName ?? ''
    this.competitionPOCPhone = obj?.competitionPOCPhone ?? ''
    this.competitionPOCEmail = obj?.competitionPOCEmail ?? ''
    this.competitionRegistrationOpenDate = obj?.competitionRegistrationOpenDate ?? new Date()
    this.competitionRegistrationOpenTime = obj?.competitionRegistrationOpenTime ?? new Date()
    this.competitionRegistrationCloseDate = obj?.competitionRegistrationCloseDate ?? new Date()
    this.competitionRegistrationCloseTime = obj?.competitionRegistrationCloseTime ?? new Date()
    this.facilities = obj?.facilities ?? []
    this.summaryLong = obj?.summaryLong ?? ''
    this.summaryShort = obj?.summaryShort ?? ''

    // ____________________________________________________________
    // Staff
    // FILL

    // ____________________________________________________________
    // Prize List
    this.data =
      obj?.data ??
      formBuilderPrizeListFactory([EPL_FB_HUNTER_AA, EPL_FB_EQUITATION_AA, EPL_FB_JUMPER_L1])

    // ____________________________________________________________
    // Scoring
    // FILL

    // ____________________________________________________________
    // Scheduling
    // FILL

    // ____________________________________________________________
    // Fees
    this.prizeListFees = obj?.prizeListFees ?? CONFIG.EVENT.FEES.FORM_BUILDER.TEMPLATE.SAMPLE.data

    // ____________________________________________________________
    // Paperwork
    this.entryBlank = obj?.entryBlank ?? false
    this.universalOrganizerWaver = obj?.universalOrganizerWaver ?? false
    this.USEFWaiverAndReleaseOfLiability = obj?.USEFWaiverAndReleaseOfLiability ?? false
    this.USEFEntryAgreement = obj?.USEFEntryAgreement ?? false
    this.USDFWaiverAndReleaseOfLiability = obj?.USDFWaiverAndReleaseOfLiability ?? false
    this.USEAWaiverAndReleaseOfLiability = obj?.USEAWaiverAndReleaseOfLiability ?? false
    this.USHJAWaiverAndReleaseOfLiability = obj?.USHJAWaiverAndReleaseOfLiability ?? false

    // ____________________________________________________________
    // Sponsors
    this.sponsorTitle = obj?.sponsorTitle ?? ''
    this.isSponsorTitleForSale = obj?.isSponsorTitleForSale ?? false
    this.acceptSponsorshipOnGrounds = obj?.acceptSponsorshipOnGrounds ?? false
    this.sponsorOpportunities = obj?.sponsorOpportunities ?? []
    this.allowSponsorshipPrizeList = obj?.allowSponsorshipPrizeList ?? false
    this.prizeListSponsors =
      obj?.prizeListSponsors ?? CONFIG.EVENT.SPONSORS.FORM_BUILDER.TEMPLATE.SAMPLE.data

    // ____________________________________________________________
    // Vendors
    this.acceptVendorsOnGrounds = obj?.acceptVendorsOnGrounds ?? false
    this.vendorOpportunities = obj?.vendorOpportunities ?? []
    this.acceptVirtualVendors = obj?.acceptVirtualVendors ?? false
    this.virtualVendorSpotsAvailable = obj?.virtualVendorSpotsAvailable ?? ''
    this.virtualVendorPrice = obj?.virtualVendorSpotsAvailable ?? ''
    this.virtualVendorNote = obj?.virtualVendorSpotsAvailable ?? ''

    // ____________________________________________________________
    // Nearby Businesses
    this.allowNearbyBusinessesToAdvertise = obj?.allowNearbyBusinessesToAdvertise ?? false
    this.spotsAvailable = obj?.spotsAvailable ?? 0
    this.price = obj?.price ?? 0
    this.note = obj?.note ?? ''

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new EventPostDetailModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static validationSchema() {
    return yup.object({}).required()
  }
}
