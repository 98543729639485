/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'

// Component imports
import WrapperWithHeader from '../../../components/common/wrappers/WrapperWithHeader'

// Styles
import { myEventsTableStyles } from '../data/myEventsTableStyles'

// Types
import { IAssignedTicket } from '../../../models/assigned-tickets/assigned-tickets.interface'
import {
  IEventBookmarkGridInterface,
  IEventBookmarkInterface,
} from '../../../models/event-bookmark/event-bookmark.interface'
import {
  IGetMyEventsFilteredDataItem,
  IMyEvent,
  ITicketBoughtItem,
  TEventRegisteredUsers,
} from '../../../models/event-registered-users/event-registered-users.interface'

import { IEventRegisteredUserInterfaceTypes } from '../../../models/event-registered-users/event-registered-users.interface'

// Helpers
import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'
import { getConvertedData, getMyEventsFilteredData } from '../../../models/interface.helper'

// Firebase
import { collection, documentId, getCountFromServer, query, where } from 'firebase/firestore'

// Services
import FirebaseApp from '../../../services/firebaseApp'
import FirestoreService from '../../../services/firestoreService'

// Constants
import { CONST } from '../../../const/const'

// Models
import { EventBookmarkModel } from '../../../models/event-bookmark/event-bookmark.model'
import { EventDetailsModel } from '../../../models/event-details/event-details.model'

// Redux
import clsx from 'clsx'
import helpers from '../../../commonHelpers/helpers'
import InfiniteScrollDataTable from '../../../components/common/tables/InfiniteScrollDataTable'
import { IMAGE_CONSTS } from '../../../const/image-const'
import { AssignTicketsModal } from '../../../models/assigned-tickets/assigned-tickets.model'
import { IEventInterface } from '../../../models/events/event.interface'
import { EventModel } from '../../../models/events/event.model'
import { IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'
import {
  selectBookmarkedEvents,
  selectBookmarkedEventsGrid,
  setBookmarkedEvents,
  setBookmarksGrid,
} from '../../../store/bookmarks/bookmarksSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectUserId, selectUseremail } from '../../../store/user/userSlice'

import { cloneDeep } from 'lodash'
import DataNotAvailable from '../../../components/common/alerts/data-not-available/DataNotAvailable'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { MESSAGES_CONST } from '../../../const/messages-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { CustomError } from '../../../helpers/helpers'
import { IRegistrationTicketInterface } from '../../../models/registration-tickets/registrationTicket.interface'
import { RegistrationTicketModel } from '../../../models/registration-tickets/registrationTicket.model'
import { ITicketBuyer } from '../../../models/ticket-buyers/ticket-buyers.interface'
import { TicketBuyersModel } from '../../../models/ticket-buyers/ticket-buyers.model'
import { draftEventsActions } from '../../../store/draftEvents/draftEventsSlice'

// Types
type ICol = { name?: string; cell: (row: IMyEvent) => JSX.Element }
type ISelectedTabUS = { tabTitle: string; tab: number; subTitle: string }
type IMyEventsDataTablesProps = { selectedTab: ISelectedTabUS; cols: ICol[] }
type IEventIdWithBookmark = {
  bookmarkId: string | null
  eventId: string
  eventStartDate?: string | Date | null
  bookmarkCreatedAt: string | Date | null
  bookmarkUpdatedAt: string | Date | null
}
type IGetBookmarkEventDetailArgs = {
  eventIdWithBookmarksList: IEventIdWithBookmark[]
  cursorId: string | null
  bookmarkCount: number
}

type IGetBookmarkedEventDetails = (args: IGetBookmarkEventDetailArgs) => Promise<void>

type IEventDetailsHolder = {
  event: IEventInterface
  eventId: string | null
  eventLogo: string | null
}

// Constants
const CHUNK_SIZE = 30
const FETCH_BOOKMARKS_PER_PAGE = 500
const NO_DRAFTS_SAVED = 'You have no draft registrations'
const EMPTY_WAITLIST = 'You are not wait listed for any events'
const NO_BOOKMARKED_EVENTS = 'You have not bookmarked any events'
const NO_TICKET_BOUGHT = 'You have not bought tickets for any event'
const NO_TICKETS_ASSIGNED = 'No tickets have assigned to you yet'
const NO_EVENTS = 'You have not registered for any upcoming events'
const EVENT = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS
const USERS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS
const EVENT_BOOKMARKS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_BOOKMARKS
const EVENT_REGISTERED_USERS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS
const TICKET_BUYERS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.TICKET_BUYERS
const ASSIGNED_REGISTERED_TICKETS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.ASSIGNED_TICKETS
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const NO_EVENTS_IMAGE_BLANK = 'assets/placeholders/noRegister.svg'
const EMPTY_WAITLIST_IMAGE_BLANK = NO_EVENTS_IMAGE_BLANK
const NO_DRAFTS_SAVED_IMAGE_BLANK = NO_EVENTS_IMAGE_BLANK
const NO_BOOKMARKED_EVENTS_IMAGE_BLANK = NO_EVENTS_IMAGE_BLANK

const MyEventsDataTables = ({ selectedTab, cols }: IMyEventsDataTablesProps) => {
  // Hooks and vars
  let mounted = true

  const perPage = 10
  const userId: string = useAppSelector(selectUserId) ?? ''

  const dispatch = useAppDispatch()
  const toastFunction = useToasterHelper()

  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState<string>('')
  const [hasMoreData, setHasMoreData] = useState(true)
  const [imageBlank, setImageBlank] = useState<string>('')
  const [savedEvents, setSavedEvents] = useState<IMyEvent[]>([])
  const [assignedTickets, setAssignedTickets] = useState<ITicketBoughtItem[]>([])
  const [waitListEvents, setWaitlistEvents] = useState<IMyEvent[]>([])
  const [registeredEvents, setRegisteredEvents] = useState<IMyEvent[]>([])
  const [ticketsData, setTicketsData] = useState<ITicketBoughtItem[]>([])
  const draftEvents = useAppSelector((state) => state.draftEvents)
  const ticketsDataRef = useRef<ITicketBoughtItem[]>([])

  const bookmarkedEvents = useAppSelector(selectBookmarkedEvents)
  const bookmarkedEventsGrid = useAppSelector(selectBookmarkedEventsGrid)

  const hasMore =
    bookmarkedEventsGrid.totalBookmarksCountInDb === null ||
    bookmarkedEventsGrid?.totalBookmarksCountInDb > bookmarkedEvents.length

  const userEmail = useAppSelector(selectUseremail)
  const [assigned_has_more, set_assigned_has_more] = useState(true)
  const assignedRef = useRef<ITicketBoughtItem[]>([])
  const assignedLst = useRef()

  const setAssignedLst = (data: any) => {
    assignedLst.current = data
  }

  useEffect(() => {
    return () => {
      mounted = false
    }
  }, [])

  const emptyDataList = async (tab: number) => {
    switch (tab) {
      case 1:
        setAssignedTickets([])
        setTicketsData([])
        break
      case 2:
        setAssignedTickets([])
        setTicketsData([])
        break
      case 3:
        setAssignedTickets([])
        setTicketsData([])
        break
      case 4:
        setAssignedTickets([])
        setTicketsData([])
        break
      case 5:
        setAssignedTickets([])
        break
      case 6:
        setTicketsData([])
        break
    }

    setAssignedLst('')
    assignedRef.current = []
  }

  useEffect(() => {
    setLoading(true)

    switch (true) {
      case selectedTab.tab === 1:
        emptyDataList(1)
        getRegisteredEvents()
        setMessage(NO_EVENTS)
        setImageBlank(NO_EVENTS_IMAGE_BLANK)
        break

      case selectedTab.tab === 2:
        emptyDataList(2)
        getWaitlistUserList()
        setMessage(EMPTY_WAITLIST)
        setImageBlank(EMPTY_WAITLIST_IMAGE_BLANK)
        break

      case selectedTab.tab === 3:
        emptyDataList(3)
        getEventsInDraft()
        setMessage(NO_DRAFTS_SAVED)
        setImageBlank(NO_DRAFTS_SAVED_IMAGE_BLANK)
        break

      case selectedTab.tab === 4:
        emptyDataList(4)
        getBookMarksList()
        setMessage(NO_BOOKMARKED_EVENTS)
        setImageBlank(NO_BOOKMARKED_EVENTS_IMAGE_BLANK)
        break

      case selectedTab.tab === 5:
        emptyDataList(5)
        getTicketsList()
        setMessage(NO_TICKET_BOUGHT)
        setImageBlank(NO_BOOKMARKED_EVENTS_IMAGE_BLANK)
        break

      case selectedTab.tab === 6:
        emptyDataList(6)
        getAssignedTicketsList()
        setMessage(NO_TICKETS_ASSIGNED)
        setImageBlank(NO_BOOKMARKED_EVENTS_IMAGE_BLANK)
        break
    }
  }, [selectedTab])

  useEffect(() => {
    ticketsDataRef.current = ticketsData
  }, [ticketsData])

  // Functions

  /**
   * @returns Returns the count of bookmarks in db
   */
  const getHasMoreStatus = () => {
    switch (true) {
      case selectedTab.tab === 1:
        return hasMoreData
      case selectedTab.tab === 2:
        return hasMoreData
      case selectedTab.tab === 3:
        return hasMoreData
      case selectedTab.tab === 4:
        return hasMoreData
      case selectedTab.tab === 5:
        return hasMoreData
      case selectedTab.tab === 6:
        return assigned_has_more
    }
  }

  if (0) console.log(getHasMoreStatus)

  // Functions

  /**
   * @returns Returns the count of bookmarks in db
   */
  const getBookmarksCount = async (): Promise<number> => {
    let bookmarksTotalCount: number = 0
    let firestoreQuery = query(
      collection(FirebaseApp.firestore, EVENT_BOOKMARKS.NAME),
      where(EVENT_BOOKMARKS.FIELDS.USER_ID.KEY, '==', userId)
    )

    try {
      let regUsersCountSnap = await getCountFromServer(firestoreQuery)
      bookmarksTotalCount = regUsersCountSnap.data()?.count ?? 0
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      return bookmarksTotalCount
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const decrementTicketCountFromTableRow = (tableRowIndex: number) => {
    let ticketsData_ = cloneDeep(ticketsDataRef.current)
    let remainingTicketsCount = 0

    if (!ticketsData_?.[tableRowIndex]) {
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      helpers.logger({
        message: CustomError.somethingWentWrong({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: `ticketsData_?.[tableRowIndex] is ${ticketsData_?.[tableRowIndex]}`,
          fileName: 'MyEventsDataTables',
          moduleName: 'decrementTicketCountFromTableRow',
        }),
      })
      return
    }

    remainingTicketsCount = ticketsData_[tableRowIndex].remainingTicketsCount

    ticketsData_.splice(tableRowIndex, 1, {
      ...ticketsData_[tableRowIndex],
      remainingTicketsCount: remainingTicketsCount > 1 ? remainingTicketsCount - 1 : 0,
    })

    setTicketsData(ticketsData_)
  }

  /**
   * @info Fetches the tickets list from db
   */
  const groupTicketsList: any = async (assignedTickets_: IAssignedTicket[]) => {
    let tableRowIndex = -1
    let eventId: string | null = null
    let datatableRows: ITicketBoughtItem[] = []
    let registration: TEventRegisteredUsers | null = null
    let currAssignedTicket: IAssignedTicket | null = null
    let currentEvent: IEventInterface | null = null

    await helpers.asyncWhileLoop({
      loopCount: assignedTickets_.length,
      functionToFire: async (index) => {
        currAssignedTicket = assignedTickets_[index]

        tableRowIndex = datatableRows.findIndex((currDataTableRow) => {
          return currDataTableRow.registration?.id === currAssignedTicket?.registrationDocId
        })

        registration = null

        if (tableRowIndex === -1) {
          if (currAssignedTicket.registrationDocId) {
            const registeredEventSnapshot = await FirestoreService.getItem(
              COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
              currAssignedTicket.registrationDocId
            )

            if (registeredEventSnapshot.exists())
              registration = getConvertedData(
                EventRegisteredUsersModel.fromFirestoreDoc(registeredEventSnapshot).toObject()
              )
          }

          if (!!currAssignedTicket.eventDocId || !!registration?.eventId) {
            eventId = (currAssignedTicket.eventDocId ?? registration?.eventId)!
            const eventsnap = await FirestoreService.getItem(EVENT.NAME, eventId)
            currentEvent = EventModel.fromFirestoreDoc(eventsnap).toObject()
          }

          datatableRows.push({
            assignedTickets: [currAssignedTicket],
            eventId: currAssignedTicket.eventDocId ?? null,
            userId,
            tableRowIndex: -1,
            registration,
            eventDetails: currentEvent,
            tickets: [],
            assignedTicket: null,
            registrationTicket: null,
            remainingTicketsCount: 1,
            assignedTicketsModal: true,
          })
        } else
          datatableRows[tableRowIndex] = {
            ...datatableRows[tableRowIndex],
            assignedTickets: [...datatableRows[tableRowIndex].assignedTickets, currAssignedTicket],
            remainingTicketsCount: datatableRows[tableRowIndex].remainingTicketsCount + 1,
          }
      },
    })

    setAssignedTickets(datatableRows)
    setLoading(false)
  }

  /**
   * @info Fetches the tickets list from db
   */
  const getAssignedTicketsList = async () => {
    try {
      const ORDER_DIRECTION = 'desc'
      let assignedTickets_: IAssignedTicket[] = []
      const ORDER_BY = ASSIGNED_REGISTERED_TICKETS.FIELDS.REGISTRATION_DOC_ID

      const assigned_ticketsData_db = await FirestoreService.filterItems(
        ASSIGNED_REGISTERED_TICKETS.NAME,
        [
          where(ASSIGNED_REGISTERED_TICKETS.FIELDS.TICKET_HOLDER_EMAIL_ID, '==', userEmail),
          where(
            ASSIGNED_REGISTERED_TICKETS.FIELDS.TICKET_TYPE.KEY,
            '==',
            ASSIGNED_REGISTERED_TICKETS.FIELDS.TICKET_TYPE.VALUE.REGISTERED
          ),
        ],
        FETCH_BOOKMARKS_PER_PAGE,
        ORDER_BY,
        ORDER_DIRECTION,
        assignedLst?.current ? assignedLst?.current || '' : null
      )

      if (assigned_ticketsData_db?.size === 0) {
        set_assigned_has_more(false)
        return setLoading(false)
      }

      if (assigned_ticketsData_db?.size) {
        if (assigned_ticketsData_db?.size === FETCH_BOOKMARKS_PER_PAGE) set_assigned_has_more(true)
        else set_assigned_has_more(false)

        assigned_ticketsData_db?.forEach((currDoc) => {
          let main_data = AssignTicketsModal.fromFirestoreDoc(currDoc).toObject()

          if (main_data) {
            assignedTickets_.push(getConvertedData(main_data))
          }
        })

        await groupTicketsList(assignedTickets_)
      }
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  const getEventLogos = async (eventIds: string[]): Promise<IEventDetailsHolder[]> => {
    let logos: IEventDetailsHolder[] = []
    let eventList: IEventInterface[] = []
    let user: IUserInterface | null = null
    let event: IEventInterface | null = null

    const ownerIdsList: string[] = []
    const usersList: IUserInterface[] = []

    if (!eventIds.length) return logos

    // Fetchs events
    const eventSnaps = await FirestoreService.filterItems(EVENT.NAME, [
      where(documentId(), 'in', eventIds),
    ])

    eventSnaps.forEach((c) => {
      event = EventModel.fromFirestoreDoc(c).toObject()
      if (event.owner) {
        eventList.push(event)
        ownerIdsList.push(event.owner)
      }
    })

    if (!ownerIdsList.length) return logos

    // Fetchs users
    const usersSnaps = await FirestoreService.filterItems(USERS.NAME, [
      where(documentId(), 'in', ownerIdsList),
    ])

    usersSnaps.forEach((c) => {
      user = UserModel.fromFirestoreDoc(c).toObject()
      usersList.push(user)
    })

    // Combines
    eventList.forEach((currEvent) => {
      user = usersList.find((cu) => cu.id === currEvent.owner) ?? null
      if (!user) return
      logos.push({
        event: currEvent,
        eventId: currEvent.id,
        eventLogo: user?.userProfilePicture ?? null,
      })
    })

    return logos
  }

  /**
   * @info Fetches the tickets list from db
   */
  const getTicketsList = async () => {
    let remainingTicketsCount = 0
    let eventId: string | null = null
    let tickets: IRegistrationTicketInterface[] = []
    let tickectsList: IEventRegisteredUserInterfaceTypes['ITicketBoughtItem'][] = []
    let ticket_Buyers: ITicketBuyer[] = []
    let upgradedBuyers: any[] = []
    const ORDER_DIRECTION = 'desc'
    const ORDER_BY = EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY

    const ticketBuyersnaps = await FirestoreService.filterItems(
      TICKET_BUYERS.NAME,
      [where(TICKET_BUYERS.FIELDS.USER_ID.KEY, '==', userId)],
      FETCH_BOOKMARKS_PER_PAGE,
      ORDER_BY,
      ORDER_DIRECTION,
      ticketsData.length ? ticketsData[ticketsData.length - 1]?.registration?.id : null
    )

    if (!ticketBuyersnaps?.size) {
      setLoading(false)
      return
    }

    if (ticketBuyersnaps?.size < FETCH_BOOKMARKS_PER_PAGE) setHasMoreData(false)
    else setHasMoreData(true)

    ticketBuyersnaps.forEach((c) => {
      ticket_Buyers.push(getConvertedData(TicketBuyersModel.fromFirestoreDoc(c).toObject()))
    })

    await helpers.asyncWhileLoop({
      loopCount: ticket_Buyers.length,
      functionToFire: async (index) => {
        let currentEvent: IEventInterface | null = null
        eventId = ticket_Buyers[index].eventId ?? null
        const currentBuyer = ticket_Buyers[index]

        tickets = []

        if (!eventId) {
          helpers.logger({
            message: CustomError.somethingWentWrong({
              message: `registration with id:${ticket_Buyers[index]} don't have event id in it`,
            }),
          })
          return 0
        }

        const ticketsSnapshots = await FirestoreService.filterItems(
          COLLECTIONS.REGISTRATION_TICKET.NAME,
          [
            where(
              COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
              '==',
              ticket_Buyers[index].registrationDocId
            ),
            where(
              COLLECTIONS.REGISTRATION_TICKET.FIELDS.PAYMENT_STATUS.KEY,
              '==',
              COLLECTIONS.REGISTRATION_TICKET.FIELDS.PAYMENT_STATUS.VALUES.PAID
            ),
            where(
              COLLECTIONS.REGISTRATION_TICKET.FIELDS.IS_SCRATCHED.KEY,
              '==',
              COLLECTIONS.REGISTRATION_TICKET.FIELDS.IS_SCRATCHED.VALUES.NO
            ),
          ]
        )

        let ticket: IRegistrationTicketInterface
        remainingTicketsCount = 0

        ticketsSnapshots.forEach((currTicketSnapshot) => {
          ticket = RegistrationTicketModel.fromFirestoreDoc(currTicketSnapshot).toObject()
          tickets.push(ticket)
        })

        const eventIds = ticket_Buyers
          .map((buyer) => buyer.eventId)
          .filter((id): id is string => id !== null)
        const logos = await getEventLogos(eventIds)

        const eventsnap = await FirestoreService.getItem(EVENT.NAME, eventId)
        currentEvent = EventModel.fromFirestoreDoc(eventsnap).toObject()
        const eventLogo =
          logos.find((logo) => logo.eventId === eventId)?.eventLogo ??
          currentEvent.eventCardCoverImageUrl
        const { category, state, tags, eventName, registrationOpenDate } = currentEvent

        remainingTicketsCount = currentBuyer.unusedTicketCount

        let buyerData = {
          category,
          eventId,
          eventName,
          discipline: tags,
          eventState: state,
          registrationOpenDate,
          remainingTicketsCount,
          userId: currentBuyer.userId,
          eventLogo: eventLogo,
          ticket_Buyers: ticket_Buyers[index],
          type: 'spectator',
        }

        upgradedBuyers.push(buyerData)

        tickectsList.push(
          getConvertedData({
            eventId: ticket_Buyers[index].eventId ?? null,
            registration: upgradedBuyers[index] as any,
            tickets,
            userId,
            eventDetails: currentEvent,
            tableRowIndex: -1,
            assignedTicketsModal: false,
            assignedTicket: null,
            registrationTicket: null,
            remainingTicketsCount,
            assignedTickets: [],
            decrementTicketCountFromTableRow,
          })
        )
      },
    })

    setTicketsData(tickectsList)
    setLoading(false)
  }

  /**
   * @info Fetches the bookmarks list from db
   */
  const getBookMarksList = async () => {
    if (mounted) {
      if (!hasMore) return setLoading(false)
      else setLoading(true)
    }

    const ORDER_DIRECTION = 'desc'
    const ORDER_BY = EVENT_BOOKMARKS.FIELDS.CREATED_AT.KEY

    let totalBookmarksCountInDb = 0
    let eventIdsList: string[] = []
    let bookmark: IEventBookmarkInterface | null = null
    let fetchedBookMarkedEvents: IEventBookmarkInterface[] = []
    let currentEvents: IEventInterface[] = []
    try {
      if (bookmarkedEventsGrid.totalBookmarksCountInDb === null)
        totalBookmarksCountInDb = await getBookmarksCount()

      const bookmarkedEventsSnap = await FirestoreService.filterItems(
        EVENT_BOOKMARKS.NAME,
        [where(EVENT_BOOKMARKS.FIELDS.USER_ID.KEY, '==', userId)],
        FETCH_BOOKMARKS_PER_PAGE,
        ORDER_BY,
        ORDER_DIRECTION,
        bookmarkedEventsGrid.cursorId
      )

      if (!bookmarkedEventsSnap.size) return setLoading(false)

      bookmarkedEventsSnap.forEach((currDoc) => {
        bookmark = getConvertedData(EventBookmarkModel.fromFirestoreDoc(currDoc).toObject())
        if (bookmark.eventId) {
          eventIdsList.push(bookmark.eventId)
          fetchedBookMarkedEvents.push(bookmark)
        }
      })

      const eventsnaps = await FirestoreService.getItemsUsingIds(EVENT.NAME, eventIdsList)

      eventsnaps.forEach((currEventDoc) => {
        currentEvents.push(EventModel.fromFirestoreDoc(currEventDoc).toObject())
      })

      const listToAdd = bookmarkedEvents.length
        ? [...bookmarkedEvents, ...fetchedBookMarkedEvents]
        : [...fetchedBookMarkedEvents]

      dispatch(setBookmarkedEvents(listToAdd))

      // Taking out cursorId
      const cursorIndex = listToAdd.length - 1
      const eventsIdsToFetch = [...listToAdd]
        .splice(bookmarkedEvents.length, CHUNK_SIZE)
        .reduce(
          (
            acc: IGetBookmarkEventDetailArgs['eventIdWithBookmarksList'],
            currBookmark: IEventBookmarkInterface
          ) => {
            if (currBookmark.eventId) {
              acc.push({
                bookmarkId: currBookmark.id ?? null,
                eventId: currBookmark.eventId,
                bookmarkCreatedAt: currBookmark.createdAt,
                bookmarkUpdatedAt: currBookmark.updatedAt,
                eventStartDate: currentEvents.find((events) => events.id === currBookmark.eventId)
                  ?.eventStartDate,
              })
            }

            return acc
          },
          []
        )

      // If there the events to fetch fetch them
      if (eventsIdsToFetch.length) {
        const cursorId = listToAdd[cursorIndex].id ?? null
        await getBookmarkedEventDetails({
          eventIdWithBookmarksList: eventsIdsToFetch,
          cursorId,
          bookmarkCount: totalBookmarksCountInDb,
        })
      }
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
      fetchedBookMarkedEvents = []
    } finally {
      setLoading(false)
    }
  }

  /**
   * @info Fetches events that are bookmarked acc to ids
   */
  const getBookmarkedEventDetails: IGetBookmarkedEventDetails = async (args) => {
    let eventLogo: string | null = null
    let currEventDetails: IEventInterface
    let foundBookmark: IEventIdWithBookmark | undefined
    const bookmarkedEventsListing: IEventBookmarkGridInterface[] = []

    let { eventIdWithBookmarksList, bookmarkCount, cursorId } = args
    let eventIdsList = eventIdWithBookmarksList.map((currObj) => currObj.eventId)

    const eventsSnapshot = await FirestoreService.filterItems(EVENT.NAME, [
      where(documentId(), 'in', eventIdsList),
    ])

    const logos = await getEventLogos(eventIdsList)

    if (eventsSnapshot.size) {
      eventsSnapshot.forEach((currEventDoc) => {
        currEventDetails = EventModel.fromFirestoreDoc(currEventDoc).toObject()
        eventLogo =
          logos.find((currLogo) => currLogo.eventId === currEventDoc.id)?.eventLogo ?? null
        currEventDetails.eventCardCoverImageUrl = eventLogo
          ? eventLogo
          : IMAGE_CONSTS.PLACEHOLDERS.EVENT

        foundBookmark = eventIdWithBookmarksList.find((currObj) => {
          return currObj.eventId === currEventDetails.id
        })
        if (foundBookmark) {
          bookmarkedEventsListing.push({
            eventId: foundBookmark.eventId,
            eventStartDate: foundBookmark.eventStartDate,
            id: foundBookmark.bookmarkId,
            bookmarkCreatedAt: foundBookmark.bookmarkCreatedAt,
            bookmarkUpdatedAt: foundBookmark.bookmarkUpdatedAt,
            eventState: currEventDetails.state ?? null,
            discipline: currEventDetails.tags ?? [],
            eventCategory: currEventDetails.category ?? null,
            spotsRemaining:
              (currEventDetails?.registrationAvailableCount ?? 0) -
              (currEventDetails?.registeredCountIncludingUnpaid ?? 0),
            eventName: currEventDetails.eventName ?? '',
            eventLogo: currEventDetails.eventCardCoverImageUrl ?? null,
          })
        }
      })

      const totalBookmarksCountInDb =
        bookmarkedEventsGrid.totalBookmarksCountInDb ?? bookmarkCount ?? 0

      dispatch(
        setBookmarksGrid({
          events: bookmarkedEventsListing,
          cursorId,
          totalBookmarksCountInDb,
        })
      )
    }
  }

  /**
   * @info Fetches the wailist from db
   */
  const getWaitlistUserList = async () => {
    if (mounted) setLoading(true)
    try {
      const myEventIdArray: string[] = []
      const waitListedData: any[] = []

      const result = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_WAITLIST.NAME,
        [where('userId', '==', userId)]
      )

      if (!myEventIdArray.length) return setLoading(false)

      if (result.size) {
        result?.forEach((doc) => myEventIdArray.push(doc.data().eventId))
      }

      const myWaitlistEvent = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_DETAILS.NAME,
        [where(documentId(), 'in', myEventIdArray)]
      )

      if (myWaitlistEvent.size) {
        myWaitlistEvent?.forEach((doc) => {
          const dataTable = EventDetailsModel.fromFirestoreDoc(doc).toObject()
          waitListedData.push(
            getConvertedData({
              eventId: dataTable.id,
              eventName: dataTable.competitionName,
              eventLogo: dataTable.competitionEventImage[0] ?? '',
              eventCountry: dataTable.competitionCity,
              eventCategory: dataTable.category,
              eventState: dataTable.competitionState,
              eventCity: dataTable.competitionCity,
              discipline: dataTable.tags,
              registrationDate: dataTable.competitionRegistrationOpenDate,
              registeredUsers: dataTable.waitlistCount ? dataTable.waitlistCount : 0,
            })
          )
        })
      }

      if (mounted) setWaitlistEvents(waitListedData)
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      if (mounted) setLoading(false)
    }
  }

  // Functions

  /**
   * @info Gets the events in users draft
   */
  const getEventsInDraft = async () => {
    let logosAddedTill = 0
    let eventIdsList: string[] = []
    let logos: IEventDetailsHolder[] = []
    let eventIdsListToFetch: string[] = []
    const drafts_: TEventRegisteredUsers[] = []
    let eventDetails: IEventDetailsHolder | null = null
    let registration: TEventRegisteredUsers | null = null
    let itemsToFilter: IGetMyEventsFilteredDataItem[] = []
    let registrationsOfCurrChunk: TEventRegisteredUsers[] = []

    try {
      const drafts = await FirestoreService.filterItems(
        EVENT_REGISTERED_USERS.NAME,
        [
          where(EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY, '==', userId),
          where(EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.KEY, '==', null),
        ],
        perPage
      )

      drafts.docs.forEach((currDraftDoc) => {
        registration = getConvertedData(
          EventRegisteredUsersModel.fromFirestoreDoc(currDraftDoc).toObject()
        )
        if (registration.eventId) {
          drafts_.push(registration)
          eventIdsList.push(registration.eventId)
        }
      })

      eventIdsList = [...new Set(eventIdsList)]

      while (logosAddedTill <= drafts_.length - 1) {
        eventIdsListToFetch = eventIdsList.splice(logosAddedTill, CHUNK_SIZE)
        registrationsOfCurrChunk = drafts_.splice(logosAddedTill, CHUNK_SIZE)

        logos = await getEventLogos(eventIdsListToFetch)

        // eslint-disable-next-line no-loop-func
        registrationsOfCurrChunk.forEach((currRegistration) => {
          eventDetails =
            logos.find((currLogo) => currLogo.eventId === currRegistration.eventId) ?? null

          if (eventDetails)
            itemsToFilter.push({
              isDraft: true,
              event: eventDetails.event,
              registration: currRegistration,
              eventLogo: eventDetails.eventLogo,
            })
        })

        logosAddedTill += CHUNK_SIZE
      }

      dispatch(draftEventsActions.setEvents(getMyEventsFilteredData(itemsToFilter)))
      setSavedEvents(getMyEventsFilteredData(itemsToFilter))
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setLoading(false)
    }
  }

  /**
   * @info Gets the events in which the user is registered in,
   * and gets the count of the users,
   * that have registered in the event
   */
  const getRegisteredEvents = async () => {
    let logosAddedTill = 0
    let eventIdsList: string[] = []
    let logos: IEventDetailsHolder[] = []
    let eventIdsListToFetch: string[] = []
    let registrations: TEventRegisteredUsers[] = []
    let eventDetails: IEventDetailsHolder | null = null
    let itemsToFilter: IGetMyEventsFilteredDataItem[] = []
    let registrationsOfCurrChunk: TEventRegisteredUsers[] = []
    let eventRegistration: TEventRegisteredUsers | null = null

    const registeredUsersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [
        where(EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY, '==', userId),
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.KEY, 'in', [
          COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PAID,
          COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PENDING,
        ]),
      ]
    )
    registeredUsersSnaps.docs.forEach((currDoc) => {
      eventRegistration = getConvertedData(
        EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject()
      )
      if (eventRegistration.eventId) {
        registrations.push(eventRegistration)
        eventIdsList.push(eventRegistration.eventId)
      }
    })
    eventIdsList = [...new Set(eventIdsList)]

    while (logosAddedTill <= registrations.length - 1) {
      eventIdsListToFetch = eventIdsList.splice(logosAddedTill, CHUNK_SIZE)
      registrationsOfCurrChunk = [...registrations].splice(logosAddedTill, CHUNK_SIZE)

      logos = await getEventLogos(eventIdsListToFetch)

      // eslint-disable-next-line no-loop-func
      registrationsOfCurrChunk.forEach((currRegistration) => {
        eventDetails =
          logos.find((currLogo) => currLogo.eventId === currRegistration.eventId) ?? null

        const registeredCount = registrations.filter(
          (user) => user.eventId === eventDetails?.event.id
        ).length

        if (eventDetails) {
          itemsToFilter.push({
            isDraft: false,
            event: { ...eventDetails.event, registeredCountIncludingUnpaid: registeredCount },
            registration: currRegistration,
            eventLogo: eventDetails.eventLogo,
          })
        }
      })

      logosAddedTill += CHUNK_SIZE
    }

    setRegisteredEvents(getMyEventsFilteredData(itemsToFilter))
    setLoading(false)
  }

  const getDataList = () => {
    return (() => {
      switch (selectedTab.tab) {
        case 1:
          return registeredEvents
        case 2:
          return waitListEvents
        case 3:
          return draftEvents
        case 4:
          return bookmarkedEventsGrid.events
        case 5:
          return ticketsData
        case 6:
          return assignedTickets
      }
    })() as IMyEvent[]
  }

  const fetMoreData = () => {
    switch (true) {
      case selectedTab.tab === 1:
        getRegisteredEvents()
        setMessage(NO_EVENTS)
        setImageBlank(NO_EVENTS_IMAGE_BLANK)
        break
      case selectedTab.tab === 2:
        getWaitlistUserList()
        setMessage(EMPTY_WAITLIST)
        setImageBlank(EMPTY_WAITLIST_IMAGE_BLANK)
        break
      case selectedTab.tab === 3:
        getEventsInDraft()
        setMessage(NO_DRAFTS_SAVED)
        setImageBlank(NO_DRAFTS_SAVED_IMAGE_BLANK)
        break
      case selectedTab.tab === 4:
        getBookMarksList()
        setMessage(NO_BOOKMARKED_EVENTS)
        setImageBlank(NO_BOOKMARKED_EVENTS_IMAGE_BLANK)
        break
      case selectedTab.tab === 5:
        getTicketsList()
        setMessage(NO_TICKET_BOUGHT)
        setImageBlank(NO_BOOKMARKED_EVENTS_IMAGE_BLANK)
        break
      case selectedTab.tab === 6:
        getAssignedTicketsList()
        setMessage(NO_TICKETS_ASSIGNED)
        setImageBlank(NO_BOOKMARKED_EVENTS_IMAGE_BLANK)
        break
    }
  }

  return (
    <WrapperWithHeader
      title={selectedTab.tabTitle}
      minHeight="250px"
      dividerStyles={{
        marginBottom: '8px',
      }}
      description={selectedTab.subTitle}
    >
      <InfiniteScrollDataTable
        fetchMore={() => {
          fetMoreData()
        }}
        // noTableHead={getDataList()?.length ?  false : true}
        fixedHeader={true}
        columns={!getDataList() || getDataList().length === 0 ? [] : cols}
        hasMore={false}
        data={getDataList()}
        progressPending={loading}
        progressComponent={<ViewsLoader size="md" color="red" className="pt-4 pb-4" />}
        noDataComponent={
          <DataNotAvailable
            mode="graphic"
            imageAlt="banner"
            bottomTextLink={CONST.ROUTES.HOME.URL}
            bottomText="Search for events >"
            imageSource={imageBlank}
            upperText={message}
          />
        }
        className={clsx(
          'transition-all',
          getDataList()?.length &&
            'h-full !max-h-[100%] md:max-h-[100vh] md:h-[65vh]  2xl:h-[65vh] !overflow-visible md:!overflow-auto 3333'
        )}
        customStyles={{
          ...myEventsTableStyles,
          headRow: {
            style: {
              minHeight: 'unset',
              borderColor: '#D3DAEE',
            },
          },
        }}
        noHeader={true}
      />
    </WrapperWithHeader>
  )
}

export default MyEventsDataTables
