import { createSlice } from '@reduxjs/toolkit'
import { IUserInterface } from '../../models/users/user.interface'
import { RootState } from '../store'
import IExhibitorSlice from './types'
import EXHIBITOR_SLICE_CONST from './const'
import * as thunks from './thunks'
import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { IRegistrationTicketInterface } from '../../models/registration-tickets/registrationTicket.interface'
import { IRegistrationFeesInterface } from '../../models/registration-fees/registrationFees.interface'

type IInitialSystemState = IExhibitorSlice['IInitialSystemState']

const initialSystemState = EXHIBITOR_SLICE_CONST.INITIAL_STATE

const {
  getRecipientThunk,
  refundAmountThunk,
  updateRecipentRefundStatusThunk,
  getRefundRequestsPerRecipientThunk,
} = thunks

const exhibitorSlice = createSlice({
  name: 'exhibitors',
  initialState: initialSystemState,
  reducers: {
    setRegistration(state, { payload }: { payload: IInitialSystemState['registration'] }) {
      state.registration = payload
    },
    setExhibitorDetails(state, { payload }: { payload: IInitialSystemState['exhibitorDetails'] }) {
      state.exhibitorDetails = payload
    },
    setVisitedUserProfileDetails(state, { payload }: { payload: IUserInterface | null }) {
      state.visitedUserProfileDetails = payload
    },
    setExhibitorTotals(
      state,
      {
        payload,
      }: {
        payload: {
          keyToUpdate: keyof IInitialSystemState['totals']
          value: number
        }
      }
    ) {
      state.totals[payload.keyToUpdate] = payload.value
    },
    setExhibitorRefundTotals(
      state,
      {
        payload,
      }: {
        payload: {
          keyToUpdate: keyof IInitialSystemState['refundTotals']
          value:
            | IRegistrationByDayInterface[]
            | IRegistrationTicketInterface[]
            | IRegistrationFeesInterface[]
        }
      }
    ) {
      if (payload.keyToUpdate === 'eventsRefundPriceTotal') {
        state.refundTotals.eventsRefundPriceTotal = payload.value as IRegistrationByDayInterface[]
      } else if (payload.keyToUpdate === 'ticketsRefundPriceTotal') {
        state.refundTotals.ticketsRefundPriceTotal = payload.value as IRegistrationTicketInterface[]
      } else if (payload.keyToUpdate === 'feesRefundPriceTotal') {
        state.refundTotals.feesRefundPriceTotal = payload.value as IRegistrationFeesInterface[]
      }
    },
    setExhibitorRefundErrors(
      state,
      {
        payload,
      }: {
        payload: {
          keyToUpdate: keyof IInitialSystemState['refundErrors']
          message: IInitialSystemState['refundErrors']['feesError']
        }
      }
    ) {
      state.refundErrors[payload.keyToUpdate] = payload.message
    },
    resetExhibitorRefundFields(state) {
      state.resetRefundTotals = !state.resetRefundTotals
      state.refundErrors = initialSystemState['refundErrors']
      state.refundTotals = initialSystemState['refundTotals']
    },
    setloading(
      state,
      { payload }: { payload: { type: 'button' | 'data'; status: boolean; id?: string } }
    ) {
      if (payload.type === 'button' && payload?.id) {
        if (payload?.status)
          state.exhibitor_paperwork.button_loading = [
            ...state.exhibitor_paperwork.button_loading,
            payload?.id ?? '',
          ]
        else
          state.exhibitor_paperwork.button_loading =
            state.exhibitor_paperwork.button_loading.filter((item) => item !== payload?.id)
      } else if (payload?.type === 'data') {
        state.exhibitor_paperwork.loading = payload.status
      }
    },
    resetRecipientAc(state) {
      state.recipient = initialSystemState.recipient
      state.updateRecipientRefundStatus = initialSystemState.recipient
    },
    resetRegistrationAc(state) {
      state.registration = initialSystemState.registration
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refundAmountThunk.pending, (state) => {
        state.refundAmount.status = 'loading'
      })
      .addCase(refundAmountThunk.rejected, (state, { payload }) => {
        state.refundAmount.message = (payload as any).message
        state.refundAmount.status = 'failed'
      })
      .addCase(refundAmountThunk.fulfilled, (state) => {
        state.refundAmount.message = null
        state.refundAmount.status = 'fulfilled'
      })
      .addCase(getRefundRequestsPerRecipientThunk.pending, (state) => {
        state.refundRequests.status = 'loading'
      })
      .addCase(getRefundRequestsPerRecipientThunk.rejected, (state, { payload }) => {
        state.refundRequests.message = (payload as any).message
        state.refundRequests.status = 'failed'
      })
      .addCase(getRefundRequestsPerRecipientThunk.fulfilled, (state, { payload }) => {
        state.refundRequests.message = null
        state.refundRequests.data = payload
        state.refundRequests.status = 'fulfilled'
      })
      .addCase(getRecipientThunk.pending, (state) => {
        state.refundAmount.message = null
        state.recipient.status = 'loading'
      })
      .addCase(getRecipientThunk.rejected, (state, action) => {
        state.recipient.status = 'failed'
        state.recipient.message = (action.payload as any)?.message
      })
      .addCase(getRecipientThunk.fulfilled, (state, action) => {
        state.recipient.data = action.payload
        state.recipient.status = 'fulfilled'
      })
      .addCase(updateRecipentRefundStatusThunk.pending, (state) => {
        state.updateRecipientRefundStatus.status = 'loading'
      })
      .addCase(updateRecipentRefundStatusThunk.rejected, (state, action) => {
        state.updateRecipientRefundStatus.status = 'failed'
        state.updateRecipientRefundStatus.message = (action.payload as any)?.message
      })
      .addCase(updateRecipentRefundStatusThunk.fulfilled, (state, action) => {
        state.updateRecipientRefundStatus.status = 'fulfilled'
        if (state.recipient.data) state.recipient.data.refundStatus = 'seen'
        state.updateRecipientRefundStatus.message = action.payload.message
      })
  },
})

export const {
  setExhibitorDetails,
  setRegistration,
  setExhibitorTotals,
  setExhibitorRefundTotals,
  setExhibitorRefundErrors,
  resetExhibitorRefundFields,
  setloading,
  resetRegistrationAc,
  resetRecipientAc,
} = exhibitorSlice.actions

export {
  getRecipientThunk,
  refundAmountThunk,
  updateRecipentRefundStatusThunk,
  getRefundRequestsPerRecipientThunk,
}

export const selectExhibitorTotals = (state: RootState) => state.exhibitor.totals
export const selectRecipientR = (state: RootState) => state.exhibitor.recipient
export const selectRefundAmountR = (state: RootState) => state.exhibitor.refundAmount
export const selectRegistrationR = (state: RootState) => state?.exhibitor.registration
export const selectExhibitorRefundTotals = (state: RootState) => state.exhibitor.refundTotals
export const selectExhibitorRefundErrors = (state: RootState) => state.exhibitor.refundErrors
export const selectResetRefundsTotals = (state: RootState) => state.exhibitor.resetRefundTotals
export const selectExhibitorDetails = (state: RootState) =>
  state?.exhibitor.exhibitorDetails ?? null
export const selectVisitedUserProfileDetails = (state: RootState) =>
  state?.exhibitor?.visitedUserProfileDetails

export default exhibitorSlice.reducer
