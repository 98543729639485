import { ITeamMember } from '../../models/users/user.interface'
import customImageComponent from '../common/CustomImageComponent'

const UserDetailCardWithStatus = ({ item }: { item: ITeamMember }) => {
  return (
    <>
      {/* teammate */}

      <div className="flex items-center justify-between">
        <div
          className={`horse flex flex-row items-center w-full rounded-xl py-3 mb-4 px-2 mr-2 ${item.memberStatus ? 'bg-[#00B6AA1A]' : 'bg-[#EB57571A]'} `}
        >
          <span className="w-10 h-10 mr-2 rounded-full">
            {customImageComponent(
              item.memberProfilePicture,
              item.memberName,
              'object-cover w-full h-full'
            )}
          </span>
          <div className="w-10/12">
            <div className="text-SeabiscuitDark200ThemeColor text-sm leading-4 capitalize">
              {item.memberName}
            </div>
            <p className="flex items-center text-sm capitalize text-SeabiscuitDark200ThemeColor">
              {item.defaultRole}
            </p>
          </div>
          <img
            src={`/assets/og_icons/${item.memberStatus ? 'Ok-3' : 'redcancel'}.svg`}
            alt="status"
          />
        </div>

        {/* usef profile */}

        <div
          className={`horse flex flex-row items-center w-full rounded-xl py-3 mb-4 px-2 mx-2 ${item.memberprofileSynced ? 'bg-[#00B6AA1A]' : 'bg-[#EB57571A]'}`}
        >
          <img
            src="/assets/img/usef-logo.svg"
            className="w-1/6 mr-2 avatarImg rounded-full object-cover"
            alt="user-logo"
          />
          <div className="w-10/12">
            <div
              className={`capitalize text-sm leading-4 ${item.memberprofileSynced ? 'text-SeabiscuitDark200ThemeColor' : 'text-[#EB5757]'}`}
            >
              {item.memberprofileSynced ? 'USEF profile synchronized' : 'Profile not synchronized'}
            </div>
          </div>
          <img
            src={`/assets/og_icons/${item.memberprofileSynced ? 'Ok-3' : 'Synchronize-2'}.svg`}
            alt="tick"
          />
        </div>

        {/* usef membership */}
        <div
          className={`horse flex flex-row items-center w-full rounded-xl py-3 mb-4 px-2 mx-2 ${item.memberShipActive ? 'bg-[#00B6AA1A]' : 'bg-[#EB57571A]'} `}
        >
          <img
            src="/assets/img/usef-logo.svg"
            className="w-1/6 mr-2 avatarImg rounded-full object-cover"
            alt="usef-logo"
          />
          <div className="w-10/12">
            <div
              className={`capitalize text-sm leading-4 ${item.memberShipActive ? 'text-SeabiscuitDark200ThemeColor' : 'text-[#EB5757]'}`}
            >
              {item.memberShipActive ? 'USEF membership active' : 'USEF membership expired'}
            </div>
          </div>
          <img
            src={`/assets/og_icons/${item.memberShipActive ? 'Ok-3' : 'Synchronize-2'}.svg`}
            alt="tick or loading"
          />
        </div>

        {/* safe training */}

        <div
          className={`horse flex flex-row items-center w-full rounded-xl py-3 mb-4 px-2 ml-2 ${item.memberSafeSupportTraining ? 'bg-[#00B6AA1A]' : 'bg-[#EB57571A]'} `}
        >
          <img
            src="/assets/img/safetraining.png"
            className="w-1/6 mr-2 avatarImg rounded-full object-cover"
            alt="support"
          />
          <div className="w-10/12">
            <div
              className={`capitalize text-sm leading-4 ${item.memberSafeSupportTraining ? 'text-SeabiscuitDark200ThemeColor' : 'text-[#EB5757]'}`}
            >
              {item.memberSafeSupportTraining
                ? 'SafeSport training current'
                : 'SafeSport SafeSport Training Overdue - allowed on competition grounds'}
            </div>
          </div>
          <img
            src={`/assets/og_icons/${item.memberSafeSupportTraining ? 'Ok-3' : 'Synchronize-2'}.svg`}
            alt="tick or loading"
          />
        </div>
      </div>
    </>
  )
}

export default UserDetailCardWithStatus
