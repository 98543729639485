export const IconLoader = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={23} fill="none">
      <path
        fill="#122B46"
        fillOpacity={0.5}
        d="M9.985-.007A.75.75 0 0 0 9.47.22L7.534 2.155a.75.75 0 0 0-.141.142l-.173.173a.75.75 0 0 0 0 1.06l2.25 2.25a.75.75 0 1 0 1.06-1.06L9.327 3.517A7.481 7.481 0 0 1 16.5 11a7.477 7.477 0 0 1-3.183 6.126.75.75 0 1 0 .866 1.226A8.991 8.991 0 0 0 18 11c0-4.701-3.627-8.568-8.229-8.96l.76-.76a.75.75 0 0 0-.546-1.287ZM4.29 3.506a.75.75 0 0 0-.472.142A8.991 8.991 0 0 0 0 11c0 4.701 3.627 8.567 8.229 8.96l-.76.76a.75.75 0 1 0 1.061 1.06l1.936-1.935a.748.748 0 0 0 .141-.142l.173-.173a.75.75 0 0 0 0-1.06l-2.25-2.25a.75.75 0 1 0-1.06 1.06l1.203 1.203A7.481 7.481 0 0 1 1.5 11a7.477 7.477 0 0 1 3.183-6.126.75.75 0 0 0-.394-1.368Z"
      />
    </svg>
  )
}
