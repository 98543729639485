// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useCallback, useState } from 'react'

import EventScoringFormBuilderUtilSectionRowAccordionComponent from './EventScoringFormBuilderUtilSectionRowAccordionComponent'
import EventScoringFormBuilderUtilSectionRowAccordionSummaryComponent from './EventScoringFormBuilderUtilSectionRowAccordionSummaryComponent'
import EventScoringFormBuilderUtilSectionRowAccordionDetailsComponent from './EventScoringFormBuilderUtilSectionRowAccordionDetailsComponent'
import EventPrizeListFormBuilderUtilSubSectionRow from '../subsection/EventScoringFormBuilderUtilSubSectionRow'
import generateFieldValidation from '../../validation/EventPrizeListFormBuilderUtilValidation'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventPriceListFormBuilderUtilSectionRowProps {
  section: any
  register: any
  disciplineIndex: number
  divisionIndex: number
  sectionIndex: number
  errors: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventScoringFormBuilderUtilSectionRow: React.FC<
  EventPriceListFormBuilderUtilSectionRowProps
> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleOnAccordionExpansionToggle = useCallback(
    (_: any) => {
      setIsExpanded((prevState) => !prevState)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [[isExpanded, setIsExpanded]]
  )

  return (
    <>
      <EventScoringFormBuilderUtilSectionRowAccordionComponent
        onChange={handleOnAccordionExpansionToggle}
      >
        <EventScoringFormBuilderUtilSectionRowAccordionSummaryComponent isExpanded={isExpanded}>
          <div className="flex items-center">
            <div className="mb-4 ml-12 relative">
              <br />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="text"
                className="bg-SeabiscuitGrayThemeColor rounded-lg w-[468px] h-12 pl-8 text-xl border-none"
                placeholder="30"
                {...props.register(
                  `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.sectionSubCode.value`,
                  {
                    validate: generateFieldValidation(props.section.sectionSubCode),
                  }
                )}
              />
              {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                ?.sections[props.sectionIndex]?.sectionSubCode?.value && (
                <p>
                  {
                    props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                      ?.sections[props.sectionIndex]?.sectionSubCode?.value?.message
                  }{' '}
                </p>
              )}
            </div>

            <div className="mb-4 ml-4 relative">
              <br />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="text"
                className="bg-SeabiscuitGrayThemeColor rounded-lg w-[165px] h-12 pl-8 text-xl border-none"
                placeholder="30"
                {...props.register(
                  `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.sectionUnderSaddleClass.value`,
                  {
                    validate: generateFieldValidation(props.section.sectionUnderSaddleClass),
                  }
                )}
              />
            </div>

            <div className="mb-4 ml-4 relative">
              <br />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="text"
                className="bg-SeabiscuitGrayThemeColor rounded-lg w-[165px] h-12 pl-8 text-xl border-none"
                placeholder="30"
                {...props.register(
                  `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.sectionHandyClass.value`,
                  {
                    validate: generateFieldValidation(props.section.sectionHandyClass),
                  }
                )}
              />
            </div>

            <div className="mb-4 ml-4 relative">
              <br />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="text"
                className="bg-SeabiscuitGrayThemeColor rounded-lg w-[165px] h-12 pl-8 text-xl border-none"
                placeholder="30"
                {...props.register(
                  `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.sectionConformationClass.value`,
                  {
                    validate: generateFieldValidation(props.section.sectionConformationClass),
                  }
                )}
              />
            </div>

            <div className="mb-4 ml-4 relative">
              <br />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="text"
                className="bg-SeabiscuitGrayThemeColor rounded-lg w-[165px] h-12 pl-8 text-xl border-none"
                placeholder="30"
                {...props.register(
                  `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.sectionHeight.value`,
                  {
                    validate: generateFieldValidation(props.section.sectionHeight),
                  }
                )}
              />
            </div>

            <div className="mb-4 ml-4 relative">
              <br />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="text"
                className="bg-SeabiscuitGrayThemeColor rounded-lg w-[165px] h-12 pl-8 text-xl border-none"
                placeholder="30"
                {...props.register(
                  `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.value`,
                  {
                    validate: generateFieldValidation(props.section.sectionTotalPriceMoney),
                  }
                )}
              />
            </div>

            <div className="mb-4 ml-4 relative">
              <br />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 right-2 mt-2 text-SeabiscuitGreenThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="text"
                className="bg-SeabiscuitGrayThemeColor rounded-lg w-[165px] h-12 pl-8 text-xl border-none"
                placeholder="30"
                {...props.register(
                  `data.items.${props.disciplineIndex}.divisions.${props.disciplineIndex}.sections.${props.sectionIndex}.sectionJudge.value`,
                  {
                    validate: generateFieldValidation(props.section.sectionJudge),
                  }
                )}
              />
            </div>
          </div>
        </EventScoringFormBuilderUtilSectionRowAccordionSummaryComponent>
        <EventScoringFormBuilderUtilSectionRowAccordionDetailsComponent>
          {props.section.subSections.map((subSection: any, index: number) => (
            <EventPrizeListFormBuilderUtilSubSectionRow
              key={subSection.subSectionTitle}
              subSection={subSection}
              register={props.register}
              disciplineIndex={props.disciplineIndex}
              divisionIndex={props.divisionIndex}
              sectionIndex={props.sectionIndex}
              subSectionIndex={index}
            />
          ))}
        </EventScoringFormBuilderUtilSectionRowAccordionDetailsComponent>
      </EventScoringFormBuilderUtilSectionRowAccordionComponent>
    </>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
EventScoringFormBuilderUtilSectionRow.defaultProps = {
  section: null,
  register: null,
}

export default EventScoringFormBuilderUtilSectionRow
