import React, { cloneElement, ReactElement, useRef, useState } from 'react'

import { AxiosError } from 'axios'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElement } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import { useForm } from 'react-hook-form'
import { AutorenewRounded } from '@mui/icons-material'

import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectUserStripeId, setCompetitorCard, setStripeId } from '../../../store/user/userSlice'

import helpers, { getStripeId } from '../../../commonHelpers/helpers'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import useGuest from '../../../hooks/useGuest'
import useProfileHook from '../../../hooks/users/competitor/profile/useProfileHook'
import { GuestModel } from '../../../models/guests/guest.model'
import { IUserCards } from '../../../models/users/user.interface'
import { httpService } from '../../../services/httpService'
import { RootState } from '../../../store/store'
import MainModal from '../../modals/common/MainModal'
import { setRegisteredUser } from '../../../store/registeredUser/registeredUserSlice'

import { ICompetitorCard, Props } from '../../../types/competitor_types'

interface IAddCardProps extends Props {
  dataToPassOn?: IDataToPassOn
  footer?: ReactElement
  className?: string
  ref?: React.Ref<HTMLButtonElement>
  backToDetails?: () => void
  setaddcardLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

interface IDataToPassOn {
  re_open_modal?: boolean
  modal_name?: string
  add_card?: boolean
  type?: string
}

interface IUserCardWithIsNew extends IUserCards {
  customerId?: string
  cardHolderName?: string
  cardHolderEmail?: string
}

interface ICheckoutForm extends Props {
  dataToPassOn: IDataToPassOn
}

export const AddCard = React.forwardRef<HTMLButtonElement, IAddCardProps>((props, ref) => {
  return (
    <Elements stripe={stripePromise}>{<AddUserCard {...props} ref={ref ? ref : null} />}</Elements>
  )
})

export const AddUserCard = React.forwardRef<HTMLButtonElement, IAddCardProps>((props, ref) => {
  const { setaddcardLoading } = props

  // Hooks and vars
  const stripe = useStripe()
  const dispatch = useAppDispatch()
  const elements = useElements()
  const { createGuest } = useGuest()
  const { updateUserDetails } = useProfileHook({ dontFetch: true })

  const [loading, setLoading] = useState(false)
  const toasterFunctions = useToasterHelper()
  const [manualError, setManualError] = useState<string>('')

  const storeState = useAppSelector((state: RootState) => state)
  const stripeId = useAppSelector(selectUserStripeId)
  const registeredUser = useAppSelector((state) => state.registeredUser.data)
  const { accountDetails, userId, guestId, profileDetails, displayName } = storeState.user
  const { userEmail } = profileDetails

  const { handleSubmit, register } = useForm<ICompetitorCard>({
    mode: 'onChange',
  })

  const form = useRef<HTMLFormElement>(null)

  // Components
  const Footer = props?.footer ? cloneElement(props?.footer, { loading }) : null

  const checkDuplicateCard = (result: any) => {
    const cardlast4 = result?.last4
    let status = false
    if (accountDetails?.userCards?.length) {
      accountDetails?.userCards?.forEach((item: any) => {
        if (Number(item.cardNumber) === Number(cardlast4)) {
          status = true
        }
      })
    }
    return status
  }

  // Functions

  // Is fired on submit
  const onSubmit = async (formData: any) => {
    setLoading(true)
    if (setaddcardLoading) setaddcardLoading(true)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      setLoading(false)
      if (setaddcardLoading) setaddcardLoading(false)
      return
    }

    // Use elements.getElement to get a reference to the CardElement.
    const cardElement = elements.getElement(CardElement) as StripeCardElement

    // Use Stripe.js to handle the payment.
    const { error, paymentMethod } = await stripe?.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      setManualError(error?.message ?? '')

      setTimeout(() => {
        setManualError('')
      }, 3000)

      setLoading(false)
      if (setaddcardLoading) setaddcardLoading(false)
      return
    }

    if (paymentMethod) {
      const isDuplicate = checkDuplicateCard(paymentMethod?.card)
      if (isDuplicate)
        toasterFunctions.info({ message: MESSAGES_CONST.CARD_ALREADY_ADDED, autoClose: 0 })

      let competitorCardData: IUserCardWithIsNew = {
        pmId: paymentMethod?.id ?? '',
        cardNumber: paymentMethod.card?.last4 ?? '',
        expiryMonth: paymentMethod.card?.exp_month?.toString() ?? '',
        expiryYear: paymentMethod.card?.exp_year?.toString() ?? '',
        cardType: paymentMethod.card?.brand ?? '',
        zipCode: paymentMethod.billing_details.address?.postal_code,
        default: true,
        isNew: true,
      }

      if (props.dataToPassOn?.type === 'ticketPurchase') {
        let customerId: string | null = stripeId

        if (!stripeId) {
          const customerData: any = await httpService({
            url: `createStripeCustomer`,
            method: 'POST',
            data: {
              description: '',
              email: formData.cardHolderEmail,
              name: formData.cardHolderName,
              metadata: '',
            },
          })
          customerId = customerData.customer
        }

        competitorCardData = {
          ...competitorCardData,
          cardHolderEmail: formData.cardHolderEmail,
          cardHolderName: formData.cardHolderName,
          customerId: customerId!,
        }

        await addCardToProfile(competitorCardData)
      } else if (props?.dataToPassOn?.add_card) {
        let res = await addCardToProfile(competitorCardData)

        setLoading(false)

        if (setaddcardLoading) setaddcardLoading(false)

        // True means card is created
        if (res) {
          props?.handleModal?.()
          props?.backToDetails?.()
        }
      } else {
        if (typeof props?.handleModal === 'function') props?.handleModal?.()
      }

      setLoading(false)
      if (setaddcardLoading) setaddcardLoading(false)
    }

    setLoading(false)
    if (setaddcardLoading) setaddcardLoading(false)
  }

  // Add card to profile in db
  const addCardToProfile = async (card: IUserCardWithIsNew) => {
    let userId_ = userId
    let cards
    let cardsCreated = false
    let created = false
    let maxLoopCount = 3
    let looped = 0
    let userFoundOnStripe: boolean = true
    let newUserStripeId: null | string = card?.customerId ?? null

    if (!newUserStripeId && !stripeId) {
      const StripeId = await getStripeId(userId || '', profileDetails)
      newUserStripeId = StripeId
      if (StripeId) {
        dispatch(setStripeId(StripeId))
      }
    }

    cards = [...(accountDetails.userCards ?? []), { ...card, isNew: false }]

    try {
      if (userId) {
        const res = await updateUserDetails({
          userCards: GuestModel.getCards(cards),
        })
        if (!res.status) throw new Error(res?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG)
      } else if (props.dataToPassOn?.type === 'ticketPurchase' && !userId) {
        let userOrGuestExits = false

        if (!!userId || !!guestId) {
          userOrGuestExits = true
        }

        const guest = await createGuest(
          new GuestModel({
            ...(userOrGuestExits
              ? {
                  ...(profileDetails as any),
                  fullName: profileDetails.userFirstName ?? null,
                }
              : {
                  userName: null,
                  userNameNGram: [],
                  userLastName: null,
                  userAddress: null,
                  fullName: card.cardHolderName,
                  userStripeId: newUserStripeId,
                  userEmail: card.cardHolderEmail ?? null,
                  userFirstName: card.cardHolderName ?? null,
                }),
            userCards: cards,
          })
        )

        userId_ = guest?.id!
      }

      let data = {
        uid: userId_,
        pmId: card?.pmId,
        stripeId: newUserStripeId ?? stripeId,
        email: userEmail && userEmail !== '' ? userEmail : card.cardHolderEmail,
        displayName: displayName && displayName !== '' ? displayName : card.cardHolderName,
      }

      // Adds the user if it wasn't added to stripe,
      // else adds the card to the user's stripe account
      await new Promise(async (resolve) => {
        const createCard = async () => {
          try {
            let cardData = await httpService({
              url: 'createCard',
              method: 'POST',
              data,
            })

            let localCardData = {
              pmId: (cardData as any)?.card.id,
              cardNumber: card.cardNumber,
              cardType: card.cardType,
              default: false,
              expiryMonth: card.expiryMonth,
              expiryYear: card.expiryYear,
              customerId: newUserStripeId ?? stripeId,
              zipCode: card?.zipCode,
              selectedCard: true,
            }

            if (registeredUser && registeredUser.userCards)
              dispatch(
                setRegisteredUser({
                  ...registeredUser,
                  userCards: Array.isArray(registeredUser.userCards)
                    ? [...registeredUser.userCards, localCardData]
                    : [localCardData],
                })
              )

            dispatch(setCompetitorCard(localCardData))

            if (!userId && newUserStripeId) {
              dispatch(setStripeId(newUserStripeId))
            }

            // toasterFunctions.success({ message: MESSAGES_CONST.CARDADDED })
            props?.handleModal?.(false, MODAL_CONSTS.CARD)
            cardsCreated = true
            return true
          } catch (error: any) {
            if (error instanceof AxiosError) {
              let responseData = error.response?.data

              toasterFunctions.error({
                message: responseData?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
              })

              if (responseData?.retry === true) {
                newUserStripeId = responseData?.data?.userStripeId
                if (newUserStripeId) {
                  data.stripeId = newUserStripeId
                }
              }
            }
            helpers.logger({
              isError: true,
              message: error,
            })
          } finally {
            setLoading(false)
            return cardsCreated
          }
        }

        if (props.dataToPassOn?.type !== 'ticketPurchase') {
          while (!created && looped < maxLoopCount) {
            looped += 1
            created = (await createCard()) ?? true
            if (!created) userFoundOnStripe = false
          }

          if (created) resolve(true)
          else toasterFunctions.error({ message: MESSAGES_CONST.UNABLE_TO_ADDCARD_TO_STRIPE })
        } else {
          created = await createCard()
        }
      })

      props?.handleModal?.(false, MODAL_CONSTS.CARD)

      if (!userFoundOnStripe && newUserStripeId) dispatch(setStripeId(newUserStripeId))
      toasterFunctions.success({ message: MESSAGES_CONST.CARDADDED })

      return true
    } catch (err) {
      helpers.logger({
        isError: true,
        message: err,
      })
      toasterFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      return false
    }
  }

  return (
    <form
      className={`w-full flex flex-col justify-evenly h-full ${props.className ?? ''}`}
      ref={form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="relative py-6 px-4 cardView border border-SeabiscuitGray500ThemeColor rounded-[12px] ml-[-8px]">
        {!userId && !guestId && (
          <>
            <div className="flex flex-col mb-3">
              <input
                type="text"
                required
                className="pt-0 px-0 pb-4 w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 text-black cursor-pointer border-b !border-b-[#D3DAEE]"
                placeholder="Name on Card"
                {...register(`cardHolderName`)}
              />
            </div>
            <div className="flex flex-col mb-6">
              <input
                type="email"
                required
                className="pb-4 px-0 w-full bg-transparent outline-0 ring-0 border-0 focus:outline-0 focus:ring-0 text-black cursor-pointer border-b !border-b-[#D3DAEE]"
                placeholder="Card holder email"
                {...register(`cardHolderEmail`)}
              />
            </div>
          </>
        )}
        <CardElement />
        {manualError && (
          <div className="text-SeabiscuitMainThemeColor text-[12px] mt-2 absolute bottom-0 left-0 translate-y-full">
            {manualError}
          </div>
        )}{' '}
      </div>
      <button className="hidden" type="submit" disabled={!stripe} ref={ref}></button>
      {Footer}
    </form>
  )
})

const CheckOutFormFooter = (props: any) => {
  return (
    <div className="ActionButton mt-7">
      <button
        disabled={props?.loading}
        type="submit"
        className="w-full mb-2 h-12 m-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor focus-visible:outline-none disabled:text-SeabiscuitLightTextColor disabled:bg-SeabiscuitLightThemeColor"
      >
        {props?.loading ? <AutorenewRounded fontSize="small" className="animate-spin" /> : 'SAVE'}
      </button>

      <button
        type="button"
        onClick={props?.handleModal}
        className="w-full h-12 m-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor"
      >
        CANCEL
      </button>
    </div>
  )
}

const CheckoutForm = (props: ICheckoutForm) => {
  return (
    <MainModal
      title="Add credit card"
      show={!!props.show}
      type="CARD"
      className="mt-4"
      setHeightAsPerContent={true}
      size="sm"
    >
      <AddCard {...props} footer={<CheckOutFormFooter {...props} />} />
    </MainModal>
  )
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string)

const UserProfileAccountTabAddCard = (props: ICheckoutForm) => {
  const closeModal = () => {
    if (props?.dataToPassOn?.re_open_modal && props?.dataToPassOn?.modal_name)
      props?.handleModal?.(true, props?.dataToPassOn?.modal_name, props?.dataToPassOn)

    props?.handleModal?.(false, MODAL_CONSTS.CARD, props?.dataToPassOn)
  }

  return (
    <CheckoutForm show={props.show} handleModal={closeModal} dataToPassOn={props.dataToPassOn} />
  )
}

export default UserProfileAccountTabAddCard
