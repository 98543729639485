export const DATA_FIRESTORE_V01_EVENT_BOOKMARKS = {
  NAME: 'v01_event_bookmarks',

  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    EVENT_ID: {
      KEY: 'eventId',
      VALUES: {
        NULL: null,
      },
    },

    USER_ID: {
      KEY: 'userId',
      VALUES: {
        NULL: null,
      },
    },

    BOOKMARKED_BY: {
      KEY: 'bookmarkedBy',
      VALUES: {
        NULL: null,
      },
    },

    EVENT: {
      KEY: 'event',
      VALUES: {
        NULL: null,
      },
    },

    CREATED_AT: {
      KEY: 'createdAt',
      VALUES: {
        NULL: null,
      },
    },

    UPDATED_AT: {
      KEY: 'updatedAt',
      VALUES: {
        NULL: null,
      },
    },

    IS_MAIL_SENT: {
      KEY: 'mailSent',
      DEFAULT: 0,
    },
  },
}
