// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { motion } from 'framer-motion'

import { useState } from 'react'
import { CONST } from '../../../const/const'
import ShowsHomePageManagementSection from './ShowsHomePageManagementSection'
import ShowsHomePageFinancesSection from './ShowsHomePageFinancesSection'
import ShowsHomePageMessagesSection from './ShowsHomePageMessagesSection'

import PegasusLogo from './../../../icons/logo/Logo.svg'
import ShowsHomePageContentHeaderSection from './ShowsHomePageContentHeaderSection'
import TopNavigationCommonComponent from '../../../components/common/navigation/top/TopNavigationCommonComponent'
import {IonContent, IonPage} from '@ionic/react'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsHomePage: React.FC = () => {
  const [currentDashboardTab, setCurrentDashboardTab] = useState(
    CONST.UI.SHOWS.DASHBOARD.SECTION.MANAGEMENT
  )

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const showSectionContent = (current_tab: string) => {
    switch (current_tab) {
      case CONST.UI.SHOWS.DASHBOARD.SECTION.MANAGEMENT:
        return <ShowsHomePageManagementSection />
      case CONST.UI.SHOWS.DASHBOARD.SECTION.FINANCES:
        return <ShowsHomePageFinancesSection />
      case CONST.UI.SHOWS.DASHBOARD.SECTION.MESSAGES:
        return <ShowsHomePageMessagesSection />
      default:
        return <ShowsHomePageManagementSection />
    }
  }

  const handleTabButtonSelected = (selected_tab: string) => {
    setCurrentDashboardTab(selected_tab)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <IonPage>
      <TopNavigationCommonComponent title="My Events" logo={PegasusLogo} />
      <IonContent
        fullscreen
        style={{ '--ion-background-color': '#F6F7FB', '--offset-top': '56px' }}
      >
        {' '}
        <motion.div
          className="w-full mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: 0.1,
          }}
        >
          <ShowsHomePageContentHeaderSection onTabButtonPressed={handleTabButtonSelected} />
          <div className="mx-4 py-8">{showSectionContent(currentDashboardTab)}</div>
        </motion.div>
      </IonContent>
    </IonPage>
  )
}

export default ShowsHomePage
