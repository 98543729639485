import { Skeleton } from '@mui/material'

const CardsLoader = () => {
  return (
    <>
      <div className="w-full flex flex-wrap items-left justify-center">
        {[...Array(8)].map((x, i) => {
          return (
            <div className="flex flex-col flex-wrap items-left justify-start m-10" key={`${x}${i}`}>
              <div className="flex flex-col flex-wrap items-left justify-start">
                <Skeleton
                  variant="rounded"
                  width={297}
                  height={297}
                  className="!h-[270px] !rounded-3xl px-2 py-2 overflow-hidden mb-2"
                />
                <Skeleton variant="rounded" width={200} height={15} className="ml-1 mb-3" />
                <Skeleton variant="rounded" width={100} height={15} className="ml-1 mb-2" />
                <Skeleton variant="rounded" width={100} height={15} className="ml-1 mb-2" />
                <Skeleton variant="rounded" width={150} height={15} className="ml-1 mb-2" />
                <Skeleton variant="rounded" width={160} height={15} className="ml-1 mb-2" />
                <Skeleton variant="rounded" width={200} height={15} className="ml-1 mb-2" />
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default CardsLoader
