import React, { useContext } from 'react'
import { IconSearch } from '../../../../components/icons/IconSearch'
import Select, { components } from 'react-select'
import clsx from 'clsx'
import { IconPrint } from '../../../../components/icons/IconPrint'
import { IconEmpty } from '../../../../components/icons/IconEmpty'
import {
  ALL_CLASSES,
  ETabId,
  MANAGE_CLINIC_TABS,
  useManageClinicNOtherAdminTabNew,
} from './hooks/useManageClinecNOtherAdminTabNew'
import InfiniteScrollDataTable from '../../../../components/common/tables/InfiniteScrollDataTable'
import { ManageClinicSpectators } from './ManageClinicSpectators'
import { ManageClinicHorses } from './ManageClinicHorses'
import { AutorenewRounded } from '@mui/icons-material'
import { ManageExhibitorProfile } from './ManageExhibitorProfile'
import ViewsLoader from '../../../../components/loader/ViewsLoader'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { IconMail } from '../../../../components/icons/IconMail'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { forEach } from 'lodash'
import FirestoreService from '../../../../services/firestoreService'
import { getConvertedData } from '../../../../models/interface.helper'
import { UserModel } from '../../../../models/users/user.model'
import { CONST } from '../../../../const/const'
import { IUserInterface } from '../../../../models/users/user.interface'
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
interface IProps {
  eventId: string
}

export const ManageClinicNOtherAdminTabNew: React.FC<IProps> = ({ eventId }) => {
  const manageClinicHook = useManageClinicNOtherAdminTabNew(eventId)
  const handleModalContext = useContext(HandleModalContext)

  const renderBody = () => {
    if (manageClinicHook.loading) {
      return (
        <div className="h-[50vh] min-h-[400px] flex justify-center items-center">
          <ViewsLoader size="lg" color="#F7074F" />
        </div>
      )
    }

    switch (manageClinicHook.activeTab) {
      case ETabId.horses:
        return <ManageClinicHorses horses={manageClinicHook.horses} eventId={eventId} />
      case ETabId.spectators:
        return <ManageClinicSpectators eventId={eventId} />
      default:
        return (
          <InfiniteScrollDataTable
            hasMore={false}
            columns={manageClinicHook.tableColumns}
            data={manageClinicHook.riders}
            noDataComponent={
              <div className={'min-h-[65vh] flex items-center justify-center flex-col gap-[36px]'}>
                <IconEmpty />
                <p className={'text-center m-0 text-[#122B46] text-[16px]'}>
                  No horses have registered for this event
                </p>
              </div>
            }
          />
        )
    }
  }

  const formatOptionLabel = ({ label }: { label: any; value: string }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div dangerouslySetInnerHTML={{ __html: label }} />
    </div>
  )

  return (
    <div className="p-5 rounded-xl bg-SeabiscuitWhiteThemeColor w-[100%]">
      <div className="flex flex-col md:flex-row md:items-center  md:h-[60px] justify-between">
        <div className="flex flex-wrap mb-4 md:mb-0">
          {MANAGE_CLINIC_TABS.map((currentItem, index) => {
            return (
              <div
                key={`${currentItem}b${index}`}
                onClick={() => manageClinicHook.setActiveTab(currentItem.id)}
                className={`py-2 px-4 text-[14px] cursor-pointer mr-2 rounded-xl ${
                  manageClinicHook.activeTab === currentItem.id
                    ? 'bg-[#F7074F0D] text-SeabiscuitMainThemeColor'
                    : 'text-SeabiscuitDark200ThemeColor'
                }`}
              >
                {currentItem.tab}
              </div>
            )
          })}
        </div>
        {manageClinicHook.activeTab === ETabId.entries && (
          <div className={'flex flex-col md:flex-row gap-4'}>
            <div className="relative rounded-full">
              <div className="search-div-wrapper border relative h-[45px] rounded-[8px] w-full md:w-[272px] border-[#D3DAEE] mb-2">
                <div className="absolute inset-y-0 left-0 flex z-50 items-center pl-3 pointer-events-none">
                  <IconSearch />
                </div>

                <input
                  type="search"
                  id="default-search"
                  className="block w-full bg-transparent p-0 h-[45px] pl-10 text-sm border-0 text-gray-900 z-40 rounded-full focus:ring-0 focus:outline-0 focus:rounded-full relative placeholder:text-SeabiscuitDark200ThemeColor"
                  placeholder="Search name"
                  value={manageClinicHook.searchNameValue}
                  onChange={(event) => manageClinicHook.onchangeSearchRiderName(event.target.value)}
                  onFocus={() => null}
                  onBlur={() => null}
                />
              </div>
            </div>
            <Select
              value={manageClinicHook.activeClass}
              defaultValue={manageClinicHook.activeClass}
              options={manageClinicHook.ridersClasses}
              formatOptionLabel={formatOptionLabel}
              className={'w-full md:w-[20vw]'}
              styles={{
                control: (styles) => ({
                  ...styles,
                  height: '45px',
                  borderRadius: '8px',
                  borderColor: '#D3DAEE',
                  // width: '20vw',
                }),
              }}
              onChange={(selectedOption) =>
                manageClinicHook.selectClassHandler(selectedOption as typeof ALL_CLASSES)
              }
              components={{
                DropdownIndicator: (props_) => {
                  return (
                    <components.DropdownIndicator
                      {...props_}
                      className="[&>svg>path]:fill-[#122B46]"
                    />
                  )
                },
                IndicatorSeparator: () => null,
              }}
            />
            <Select
              isClearable={false}
              isSearchable={false}
              placeholder="All"
              menuShouldScrollIntoView={true}
              value={manageClinicHook.activeStatus}
              options={manageClinicHook.statuses}
              className={'w-full md:w-[123px]'}
              styles={{
                control: (styles) => ({
                  ...styles,
                  height: '45px',
                  borderRadius: '8px',
                  borderColor: '#D3DAEE',
                }),
              }}
              onChange={(selectedOption: any) =>
                manageClinicHook.statusSelectHandler(selectedOption)
              }
              components={{
                DropdownIndicator: (props_) => {
                  return (
                    <components.DropdownIndicator
                      {...props_}
                      className="[&>svg>path]:fill-[#122B46]"
                    />
                  )
                },
                IndicatorSeparator: () => null,
              }}
            />

            {manageClinicHook.riders && manageClinicHook.riders[0] && (
              <div className="flex items-start">
                <button
                  type="button"
                  className="border flex items-center rounded-[8px] justify-center border-solid border-[#D3DAEE] w-[45px] h-[45px] hover:opacity-50"
                  onClick={async () => {
                    const trainersIds: string[] = []
                    manageClinicHook.riders.forEach((rider) => {
                      if (rider.horse && rider.horse.memberId) {
                        trainersIds.push(rider.horse.memberId)
                      }
                    })
                    const usersList = [...new Set(trainersIds)]

                    let usersSnaps = await FirestoreService.getItemsUsingIds(
                      COLLECTIONS.USERS.NAME,
                      usersList
                    )
                    const usersData: IUserInterface[] = []
                    usersSnaps.forEach((currSnap) => {
                      usersData.push(
                        getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject())
                      )
                    })

                    const emails: string[] = manageClinicHook.riders.map(
                      (rider) => rider.riderEmail || ''
                    )

                    usersData.forEach((user) => {
                      const isExist = emails.find((email) => email === user.userEmail)
                      if (!isExist && user.userEmail) {
                        emails.push(user.userEmail)
                      }
                    })

                    handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE_ENTRIES, {
                      emailId: emails.join(','),
                    })
                  }}
                >
                  <IconMail />
                </button>
              </div>
            )}

            {/*<button*/}
            {/*  className={*/}
            {/*    'flex justify-center items-center rounded-[8px] border border-solid border-[#D3DAEE] h-[45px] w-[45px]'*/}
            {/*  }*/}
            {/*>*/}
            {/*  <IconPrint />*/}
            {/*</button>*/}
          </div>
        )}
      </div>
      {renderBody()}
    </div>
  )
}
