import { useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'

import { Modal } from './Modal'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

SwiperCore.use([Navigation])

interface Props {
  open: boolean
  videosURL: string[]
  onClose: () => void | Promise<void>
}
export const VideoModal = ({ open, videosURL, onClose }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <Modal open={open} onClose={onClose}>
      <div className="w-[98vw] max-w-[800px] lg:h-[450px] bg-black transition duration-500 rounded-xl">
        <Swiper
          slidesPerView={1}
          modules={[Navigation]}
          navigation
          onSlideChangeTransitionStart={() => setIsLoaded(true)}
          onSlideChangeTransitionEnd={() => setIsLoaded(false)}
        >
          {videosURL.map((url) => (
            <SwiperSlide key={url}>
              {({ isActive }) =>
                isActive &&
                !isLoaded && (
                  <video
                    className="w-[98vw] max-w-[800px] lg:h-[450px] object-contain rounded-xl"
                    autoPlay
                    loop
                    muted
                    controls
                  >
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )
              }
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Modal>
  )
}
