/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { IonListHeader, IonTitle } from '@ionic/react'

// Component
import EventOrganizationHorizontalCardStackComponent from '../../../components/events/stacks/organization-horizontal-card/EventOrganizationHorizontalCardStackComponent'

// Constants
import { EVENTS_STATUS_CONSTS } from './OrganizerRootPage'
import { MESSAGES_CONST } from '../../../const/messages-const'

// Redux
import { useAppSelector } from '../../../store/hooks'
import { selectedfilters } from '../../../store/filters/filterSlice'
import { IEventInterface } from '../../../models/events/event.interface'
import { selectEvents } from '../../../store/events/eventsSlice'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IProps = {
  events: any
  title: string
  loading: boolean
  activeTab: IEventInterface['status']
  openCreateEventModal: () => void
  fetchMore: (cursorId: string) => void
  hasMore?: boolean
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const OrganizerEventListing = (props: IProps) => {
  // Hooks and vars
  const filters = useAppSelector(selectedfilters)
  const hasMore = useAppSelector(selectEvents).hasMore
  const [showSearchResultsP, setShowSearchResultsP] = useState(false)

  const getNoEventsMessage = () => {
    if (showSearchResultsP) return MESSAGES_CONST.NO_EVENTS_FOUND_AS_PER_SEARCH
    switch (props.activeTab) {
      case EVENTS_STATUS_CONSTS.CURRENT:
        return 'You have not created any events'
      case EVENTS_STATUS_CONSTS.DRAFT:
        return 'You do not have any draft events'
      case EVENTS_STATUS_CONSTS.PAST:
        return 'You do not have any past events'
      default:
        return 'You have not created any events'
    }
  }

  useEffect(() => {
    if (filters.search.status && !props.events.length) setShowSearchResultsP(true)
    else setShowSearchResultsP(false)
  }, [props.events, filters.search.status])

  return (
    <div className="min-h-[70vh]">
      <IonListHeader className="px-0">
        {props.events?.length ? (
          <div className="flex justify-between w-full mx-6">
            <IonTitle className="font-normal pb-4 px-0">{props.title}</IonTitle>
          </div>
        ) : (
          <></>
        )}
      </IonListHeader>

      {!props.events?.length ? (
        <div className="mb-6 mx-5 min-h-[70vh] flex items-center justify-center flex-col">
          <div className="font-[14px]">{getNoEventsMessage()}</div>
          <img
            src={'assets/placeholders/noEventPlaceholder.svg'}
            className="w-80 my-8 placeholder"
            alt="iconNoEvent"
          />
          {props.activeTab === EVENTS_STATUS_CONSTS.CURRENT ? (
            <div
              onClick={() => props?.openCreateEventModal()}
              className="font-[14px] text-SeabiscuitMainThemeColor cursor-pointer"
            >
              {`Create your first event >`}
            </div>
          ) : null}
        </div>
      ) : (
        <EventOrganizationHorizontalCardStackComponent
          events={props?.events}
          fetchMore={props.fetchMore}
          onEventClicked={() => {}}
          hasMore={hasMore}
        />
      )}
    </div>
  )
}

export default OrganizerEventListing
