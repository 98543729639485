import FirebaseApp from './firebaseApp'

import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const uploadFile = async (file: any, fullFilePath: any, progressCallback?: any) => {
  const uploadRef = ref(FirebaseApp.storage, fullFilePath)
  const uploadTask = uploadBytesResumable(uploadRef, file)

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      if (progressCallback) {
        progressCallback(progress)
      }
    },
    (error) => {
      throw error
    }
  )

  return uploadTask.then(async () => {
    const url = await getDownloadURL(uploadTask.snapshot.ref)
    const type = file.type?.split('/')[1]
    return `${url}&format=${type}`
  })
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const deleteFile = (fileDownloadUrl: any) => {
  const decodedUrl = decodeURIComponent(fileDownloadUrl)
  const startIndex = decodedUrl.indexOf('/o/') + 3
  const endIndex = decodedUrl.indexOf('?')
  const filePath = decodedUrl.substring(startIndex, endIndex)

  const fileRef = ref(FirebaseApp.storage, filePath)
  return deleteObject(fileRef)
}

const FirebaseStorageService = {
  uploadFile,
  deleteFile,
}

export default FirebaseStorageService
