import { ModelBaseModel } from '../model-base/model-base.model'

import { CONST } from '../../const/const'
import { IRegistrationByDayInterface } from './registrationByDay.interface'
import { cloneDeep } from 'lodash'

// Constants
const REGISTRATION_BY_DAY_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_BY_DAY

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @TODO Document this
 */

export class RegistrationByDayModel extends ModelBaseModel<IRegistrationByDayInterface> {
  // Properties
  public v: IRegistrationByDayInterface['v']
  public id: IRegistrationByDayInterface['id']
  public userId: IRegistrationByDayInterface['userId']
  public eventId: IRegistrationByDayInterface['eventId']
  public horseId: IRegistrationByDayInterface['horseId']
  public eventName: IRegistrationByDayInterface['eventName']
  public backNumber: IRegistrationByDayInterface['backNumber']
  public eventDate: IRegistrationByDayInterface['eventDate']
  public horseName: IRegistrationByDayInterface['horseName']
  public isSratched: IRegistrationByDayInterface['isSratched']
  public refundId: IRegistrationByDayInterface['refundId']
  public refundDocId: IRegistrationByDayInterface['refundDocId']
  public refundAmountAdded: IRegistrationByDayInterface['refundAmountAdded']
  public scratchAmountAdded: IRegistrationByDayInterface['scratchAmountAdded']
  public recipientId: IRegistrationByDayInterface['recipientId']
  public riderId: IRegistrationByDayInterface['riderId']
  public refundStatus: IRegistrationByDayInterface['refundStatus']
  public score: any
  public order: IRegistrationByDayInterface['order']
  public orderOfGoScratched: IRegistrationByDayInterface['orderOfGoScratched']
  // public score: IRegistrationByDayInterface['score']
  public paymentStatus: IRegistrationByDayInterface['paymentStatus']
  public isPaidByOwner: IRegistrationByDayInterface['isPaidByOwner']
  public recipientName: IRegistrationByDayInterface['recipientName']
  public invoiceId: IRegistrationByDayInterface['invoiceId']
  public invoiceUrl: IRegistrationByDayInterface['invoiceUrl']
  public amountRefunded: IRegistrationByDayInterface['amountRefunded']
  public riderRole: IRegistrationByDayInterface['riderRole']
  public riderName: IRegistrationByDayInterface['riderName']
  public riderDob: IRegistrationByDayInterface['riderDob']
  public riderEmail: IRegistrationByDayInterface['riderEmail']
  public noHorseSelected: IRegistrationByDayInterface['noHorseSelected']
  public registrationDocId: IRegistrationByDayInterface['registrationDocId']
  public registrationPrice: IRegistrationByDayInterface['registrationPrice']
  public qualifyFee: IRegistrationByDayInterface['qualifyFee']
  public breakTime: IRegistrationByDayInterface['breakTime']
  public isQualify: IRegistrationByDayInterface['isQualify']
  public registrationByDayName: IRegistrationByDayInterface['registrationByDayName']
  public recipientNameNGram: IRegistrationByDayInterface['recipientNameNGram']
  public riderNameNGram: IRegistrationByDayInterface['riderNameNGram']
  public amountScratched: IRegistrationByDayInterface['amountScratched']
  public horseProfilePicture: IRegistrationByDayInterface['horseProfilePicture']
  public riderProfilePicture: IRegistrationByDayInterface['riderProfilePicture']
  public userHorseMappingDocId: IRegistrationByDayInterface['userHorseMappingDocId']
  public recipientProfilePicture: IRegistrationByDayInterface['recipientProfilePicture']
  public uuid: IRegistrationByDayInterface['uuid']

  public created: IRegistrationByDayInterface['created']
  public modified: IRegistrationByDayInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param obj `IRegistrationByDayInterface`
   */
  public constructor(obj?: IRegistrationByDayInterface) {
    super()

    this.v = obj?.v ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.V.VALUES.DEFAULT
    this.id = obj?.id ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.userId = obj?.userId ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.USER_ID.VALUES.DEFAULT
    this.eventId = obj?.eventId ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.EVENT_ID.VALUES.DEFAULT
    this.horseId = obj?.horseId ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.HORSE_ID.VALUES.DEFAULT
    this.refundId = obj?.refundId ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.REFUND_ID.VALUES.DEFAULT
    this.score = obj?.score ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.SCORE.VALUES.DEFAULT
    this.order = obj?.order ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.ORDER.VALUES.DEFAULT
    this.orderOfGoScratched =
      obj?.orderOfGoScratched ??
      REGISTRATION_BY_DAY_COLLECTION.FIELDS.ORDER_OF_GO_SCRATCHED.VALUES.DEFAULT
    this.eventName =
      obj?.eventName ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.EVENT_NAME.VALUES.DEFAULT
    this.backNumber =
      obj?.backNumber ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.BACK_NUMBER.VALUES.DEFAULT
    this.eventDate =
      obj?.eventDate ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.EVENT_DATE.VALUES.DEFAULT
    this.horseName =
      obj?.horseName ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.HORSE_NAME.VALUES.DEFAULT
    this.refundDocId =
      obj?.refundDocId ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.REFUND_DOC_ID.VALUES.DEFAULT
    this.noHorseSelected =
      obj?.noHorseSelected ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.NO_HORSE_SELECTED.VALUES.DEFAULT
    this.invoiceId =
      obj?.invoiceId ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.INVOICE_ID.VALUES.DEFAULT
    this.invoiceUrl =
      obj?.invoiceUrl ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.INVOICE_URL.VALUES.DEFAULT
    this.refundAmountAdded =
      obj?.refundAmountAdded ??
      REGISTRATION_BY_DAY_COLLECTION.FIELDS.REFUND_AMOUNT_ADDED.VALUES.DEFAULT
    this.scratchAmountAdded =
      obj?.scratchAmountAdded ??
      REGISTRATION_BY_DAY_COLLECTION.FIELDS.SCRATCH_AMOUNT_ADDED.VALUES.DEFAULT
    this.isSratched =
      obj?.isSratched ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.IS_SCRATCHED.VALUES.DEFAULT
    this.recipientId =
      obj?.recipientId ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.RECIPIENT_ID.VALUES.DEFAULT
    this.riderId = obj?.riderId ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.RIDER_ID.VALUES.DEFAULT
    this.refundStatus =
      obj?.refundStatus ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.REFUND_STATUS.VALUES.DEFAULT
    this.paymentStatus =
      obj?.paymentStatus ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.PAYMENT_STATUS.VALUES.DEFAULT
    this.isPaidByOwner =
      obj?.isPaidByOwner ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.IS_PAID_BY_OWNER.VALUES.DEFAULT
    this.recipientName =
      obj?.recipientName ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.RECIPIENT_NAME.VALUES.DEFAULT
    this.amountRefunded =
      obj?.amountRefunded ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.AMOUNT_REFUNDED.VALUES.DEFAULT
    this.riderEmail =
      obj?.riderEmail ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.RIDER_EMAIL_ID.VALUES.DEFAULT
    this.amountScratched =
      obj?.amountScratched ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.AMOUNT_REFUNDED.VALUES.DEFAULT
    this.riderRole =
      obj?.riderRole ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.RIDER_ROLE.VALUES.DEFAULT
    this.riderName =
      obj?.riderName ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.RIDER_NAME.VALUES.DEFAULT
    this.riderDob = obj?.riderDob ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.RIDER_DOB.VALUES.DEFAULT
    this.registrationDocId =
      obj?.registrationDocId ??
      REGISTRATION_BY_DAY_COLLECTION.FIELDS.REGISTRATION_DOC_ID.VALUES.DEFAULT
    this.registrationPrice =
      obj?.registrationPrice ??
      REGISTRATION_BY_DAY_COLLECTION.FIELDS.REGISTRATION_PRICE.VALUES.DEFAULT
    this.qualifyFee =
      obj?.qualifyFee ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.QUALIFY_FEE.VALUES.DEFAULT
    this.breakTime =
      obj?.breakTime ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.BREAK_TIME.VALUES.DEFAULT
    this.isQualify =
      obj?.isQualify ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.IS_QUALIFY.VALUES.DEFAULT
    this.registrationByDayName =
      obj?.registrationByDayName ??
      REGISTRATION_BY_DAY_COLLECTION.FIELDS.REGISTRATION_BY_DAY_NAME.VALUES.DEFAULT
    this.recipientNameNGram =
      obj?.recipientNameNGram ??
      REGISTRATION_BY_DAY_COLLECTION.FIELDS.RECIPIENT_NAME_N_GRAM.VALUES.DEFAULT
    this.riderNameNGram =
      obj?.riderNameNGram ?? REGISTRATION_BY_DAY_COLLECTION.FIELDS.RIDER_NAME_N_GRAM.VALUES.DEFAULT
    this.userHorseMappingDocId =
      obj?.userHorseMappingDocId ??
      REGISTRATION_BY_DAY_COLLECTION.FIELDS.USER_HORSE_MAPPING_DOC_ID.VALUES.DEFAULT
    this.riderProfilePicture =
      obj?.riderProfilePicture ??
      REGISTRATION_BY_DAY_COLLECTION.FIELDS.RIDER_PROFILE_PICTURE.VALUES.DEFAULT
    this.horseProfilePicture =
      obj?.horseProfilePicture ??
      REGISTRATION_BY_DAY_COLLECTION.FIELDS.HORSE_PROFILE_PICTURE.VALUES.DEFAULT
    this.recipientProfilePicture =
      obj?.recipientProfilePicture ??
      REGISTRATION_BY_DAY_COLLECTION.FIELDS.RECIPIENT_PROFILE_PICTURE.VALUES.DEFAULT
    this.uuid = obj?.uuid ?? null

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })

    this.updateNamesNGramsKeys()
  }

  // Methods

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Documnent this
   */
  public updateNamesNGramsKeys() {
    if (!!this.riderName) this.riderNameNGram = this._calculateNGrams(this.riderName)

    if (!!this.recipientName) this.recipientNameNGram = this._calculateNGrams(this.recipientName)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Documnent this
   */
  private _calculateNGrams(value: string) {
    let nGrams: string[] = []
    let words = value?.split(' ') ?? []
    let ngram_words = new Set<string>()
    let lowercase_words = words.map((word) => word.toLowerCase())

    lowercase_words.forEach((word) => {
      let word_iterator = cloneDeep(word)

      while (word_iterator.length > 0) {
        ngram_words.add(cloneDeep(word_iterator))
        word_iterator = word_iterator.slice(0, -1)
      }
    })

    ngram_words.forEach((value) => {
      nGrams.push(value)
    })

    return nGrams
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new RegistrationByDayModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
