// Component imports

// Constants
import { yupResolver } from '@hookform/resolvers/yup'
import { AutorenewRounded } from '@mui/icons-material'
import {
  EmailAuthProvider,
  User,
  getAuth,
  reauthenticateWithCredential,
  updateEmail,
} from 'firebase/auth'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import helpers from '../../commonHelpers/helpers'
import { CONST } from '../../const/const'
import FIREBASE_CONST from '../../const/firebase-const'
import { MESSAGES_CONST } from '../../const/messages-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import MessageHelperComp from '../../helpers/MessageHelper'
import useToasterHelper from '../../helpers/ToasterHelper'
import useProfileHook from '../../hooks/users/competitor/profile/useProfileHook'
import { getConvertedData } from '../../models/interface.helper'
import { httpService } from '../../services/httpService'
import { useAppSelector } from '../../store/hooks'
import { RootState } from '../../store/store'
import { selectUserStripeAccountId, setUserProfileData } from '../../store/user/userSlice'
import { IUserEmailUpdateAccountForm } from '../../types/competitor_types'
import { profileEmailUpdateAccountSchema } from '../../validations'
import MainModal from './common/MainModal'

// Types
type IUserEmailUpdateProps = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

type IAuthenticateUserFnArgs = {
  password: string
}

type IAuthenticateUserFn = (args: IAuthenticateUserFnArgs) => Promise<User | false>

// Constants

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const UserEmailUpdate = ({ show, handleModal, dataToPassOn }: IUserEmailUpdateProps) => {
  // Hooks and vars
  const dispatch = useDispatch()
  const toastFunctions = useToasterHelper()
  const userReducer = useSelector((state: RootState) => state.user)
  const stripeConnectAccountId = useAppSelector(selectUserStripeAccountId)
  const [notmyemail] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const { updateUserDetails } = useProfileHook({ dontFetch: true })
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IUserEmailUpdateAccountForm>({
    mode: 'onChange',
    resolver: yupResolver(profileEmailUpdateAccountSchema),
  })

  //check user password
  const authenticateUser: IAuthenticateUserFn = async ({ password }) => {
    return new Promise((resolve) => {
      const auth = getAuth()
      const user = auth.currentUser

      if (user) {
        const credential = EmailAuthProvider.credential(user?.email as string, password)

        reauthenticateWithCredential(user, credential)
          .then(() => {
            resolve(user)
          })
          .catch((error) => {
            const message =
              error?.code === FIREBASE_CONST.WRONG_PASSWORD
                ? MESSAGES_CONST.INVALID_PASSWORD
                : MESSAGES_CONST.SOMETHING_WENT_WRONG
            helpers.logger({
              isError: true,
              message: 'ProfileRootPasswordUpdateForm:::authenticateUser:::' + error?.message,
            })
            toastFunctions.error({ message })
            resolve(false)
          })
      } else {
        resolve(false)
      }
    })
  }

  //set value
  useEffect(() => {
    const email = userReducer?.profileDetails?.userEmail
    if (email)
      reset({
        userEmail: email,
      })
  }, [reset, userReducer?.profileDetails?.userEmail])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const closeModal = () => {
    handleModal(false, MODAL_CONSTS.UPDATE_PROFILE_EMAIL)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const disconnectStripeConnectAccount = async (): Promise<boolean> => {
    let status = false
    let message = MESSAGES_CONST.SOMETHING_WENT_WRONG
    const isOrganizer = userReducer.userType === COLLECTIONS.USERS.FIELDS.USER_TYPE.VALUES.ORGANIZER

    if (!!!stripeConnectAccountId || !isOrganizer) return status

    try {
      await httpService({
        method: 'post',
        url: `disconnect_stripe_connect_account/${stripeConnectAccountId}`,
      })

      status = true
    } catch (error: any) {
      if (error?.response?.data?.message) message = error?.response?.data?.message

      toastFunctions.error({
        message,
      })

      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      return status
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const updateStripeCustomerEmail = async (email: string): Promise<boolean> => {
    let stripeCustomerId = userReducer.profileDetails.userStripeId

    let status = false
    let message = MESSAGES_CONST.SOMETHING_WENT_WRONG

    if (!!!email || !!!stripeCustomerId) return status

    try {
      await httpService({
        method: 'post',
        url: `update_stripe_customer/${stripeCustomerId}`,
        data: {
          email,
        },
      })

      status = true
    } catch (error: any) {
      status = false

      if (error?.response?.data?.message) message = error?.response?.data?.message

      toastFunctions.error({
        message,
      })

      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      return status
    }
  }

  const organizerEmail = async (data: any) => {
    if (dataToPassOn?.canAccountBeRemoved === true && dataToPassOn?.checkEvents === 0) {
      await onSubmit(data)

      return 1
    }
    closeModal()
    handleModal(true, MODAL_CONSTS.OPEN_ORGANIZER_MAIL_INFO, dataToPassOn)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onSubmit = async (data: any) => {
    let isError = false
    // let stripeCustomerEmailUpdated = false;
    let stripeConnectAccountDisconnected = false
    let message = MESSAGES_CONST.SOMETHING_WENT_WRONG
    let userPreviousStripeConnectIds = cloneDeep(
      userReducer.profileDetails.userPreviousStripeConnectIds
    )

    // if (userReducer?.profileDetails?.userEmail !== data.updateUserEmail) {
    //     setNotMyEmail(true)
    //     return
    // }

    if (!!stripeConnectAccountId) userPreviousStripeConnectIds.push(stripeConnectAccountId)

    try {
      setLoading(true)

      const user = await authenticateUser({
        password: data?.password,
      })

      if (user) {
        await updateEmail(user, data?.updateUserEmail.toLowerCase())

        const updatedEmail = data?.updateUserEmail.toLowerCase()
        const res = await updateUserDetails({
          userPreviousStripeConnectIds,
          userEmail: updatedEmail,
          userStripeAccountId: null,
          userStripeAccountStatus: null,
        })

        if (!res.status) toastFunctions.error({ message })
        else {
          message = 'Email updated successfully'

          stripeConnectAccountDisconnected = await disconnectStripeConnectAccount()

          if (stripeConnectAccountDisconnected) {
            // message = "Stripe connect account disconnected successfully";
            // toastFunctions.success({ message })
          }

          // message = "Stripe customer email updated successfully";
          await updateStripeCustomerEmail(updatedEmail)
          // stripeCustomerEmailUpdated =

          // if (stripeCustomerEmailUpdated)
          //     toastFunctions.success({ message })

          toastFunctions.success({ message })

          dispatch(
            setUserProfileData(
              getConvertedData({
                ...userReducer.profileDetails,
                userPreviousStripeConnectIds,
                userEmail: updatedEmail,
                userStripeAccountId: null,
                userStripeAccountStatus: null,
              })
            )
          )
        }
      } else isError = true
    } catch (error: any) {
      if (error?.code === FIREBASE_CONST.EMAIL_ALREADY_EXISTS)
        message = MESSAGES_CONST.USER_ALREADY_EXISTS_WITH_EMAIL

      if (error?.code === FIREBASE_CONST.WRONG_PASSWORD) message = MESSAGES_CONST.INVALID_PASSWORD

      toastFunctions.error({ message })

      isError = true
    } finally {
      if (!isError) return closeModal()
      setLoading(false)
    }
  }

  return (
    <MainModal
      type="UPDATE_PROFILE_EMAIL"
      size="md"
      title={notmyemail ? 'Access Denied' : 'Change account email'}
      show={show}
      setHeightAsPerContent={notmyemail}
    >
      <div className=" overflow-y-auto">
        {/* <p>Are you sure to update email address?</p> */}

        {!notmyemail ? (
          <form onSubmit={handleSubmit(organizerEmail)}>
            <div className="AccountEmail flex flex-wrap items-start pb-3">
              <div className="w-full flex flex-col pr-2">
                <span className="text-nr text-SeabiscuitDark200ThemeColor">
                  Changing your account email will disconnect your bank account. You will need to
                  reconnect it once you change your account email.
                </span>
                <span className="mt-[22px] mb-2 text-SeabiscuitDark200ThemeColor">
                  Update email
                </span>
              </div>
              <div className="flex py-2 w-full flex-col">
                <div className="flex items-center w-full bg-gray-50 border !border-[#D3DAEE] text-SeabiscuitDark200ThemeColor rounded-xl p-3 pr-0 h-[51px] !ring-0">
                  <span>
                    <img src="/assets/og_icons/Mail.svg" alt="mail" />
                  </span>
                  <input
                    type="email"
                    placeholder="Enter new email..."
                    {...register('updateUserEmail')}
                    className="!border-0 !ring-0 !shadow-none w-full"
                  />
                </div>
                {errors.updateUserEmail && (
                  <MessageHelperComp isError={true} message={errors.updateUserEmail.message} />
                )}
              </div>
              <div className="flex py-2 w-full flex-col">
                <div className="flex items-center w-full bg-gray-50 border !border-[#D3DAEE] text-SeabiscuitDark200ThemeColor rounded-xl p-3 pr-0 h-[51px] !ring-0">
                  <span>
                    <img src="/assets/og_icons/Lock.svg" alt="lock" />
                  </span>
                  <input
                    type="password"
                    placeholder="Enter password..."
                    {...register('password')}
                    className="!border-0 !ring-0 !shadow-none w-full"
                  />
                </div>
                {errors.password && (
                  <MessageHelperComp isError={true} message={errors.password.message} />
                )}
              </div>
            </div>

            <div className="ActionButton mt-5 absolute bottom-5 left-1/2 transform -translate-x-1/2">
              <button
                type="submit"
                className="w-52 mb-2 h-12 m-auto flex items-center justify-center py-2 px-4 border border-solid border-SeabiscuitMainThemeColor rounded-xl shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor bg-transparent focus-visible:outline-none disabled:text-SeabiscuitLightTextColor disabled:bg-SeabiscuitLightThemeColor"
              >
                {loading ? (
                  <AutorenewRounded fontSize="small" className="animate-spin mx-auto" />
                ) : (
                  'CHANGE EMAIL'
                )}{' '}
              </button>
            </div>
          </form>
        ) : (
          <div>
            <div className="text-nr text-SeabiscuitDark200ThemeColor">
              Please try again, you can only change your own account email
            </div>
          </div>
        )}
      </div>
    </MainModal>
  )
}

export default UserEmailUpdate
