/* eslint-disable jsx-a11y/anchor-is-valid */
import UserSignupViewComponentButton from './global/UserSignupViewComponentButton'
import { useContext } from 'react'
import { SignupStages } from './UserSignupViewComponentContextApi'

import backIcon from '../assets/arrow-left.svg'
import emailIcon from '../assets/email.svg'
import eventOrgIcon from '../assets/event-org.png'

const UserSignupViewComponentEventOrganizerVerify: React.FC = () => {
  const [stage, setStage] = useContext(SignupStages)

  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  const handleSubmit = () => {
    if (stage.stage === 'EventOrgVerify') {
      setStage({ ...stage, stage: 'EventOrgSubmit' })
    }
  }

  const backStage = () => {
    if (stage.stage === 'EventOrgVerify') {
      stageHandle('EventOrg')
    } else {
      stageHandle('EventOrgVerify')
    }
  }

  return (
    <div>
      <div className="text-[24px] flex items-center gap-2 text-white">
        <button onClick={backStage}>
          <img src={backIcon} alt="back" className="invert" />
        </button>
        EVENT ORGANIZER
      </div>
      <div className="mt-10">
        <img src={eventOrgIcon} alt="icon" className="rounded-full shadow-xl mx-auto" />
      </div>
      <div className="mt-10 flex gap-4 items-center border-[#D3DAEE] border-solid border-[1px]  p-3 rounded-xl">
        <div className="min-w-[25px]">
          <img
            src={
              stage.stage === 'EventOrgVerify'
                ? '/assets/cp_icons/Download from the Cloud-4.svg'
                : emailIcon
            }
            alt="email"
            className="w-full"
          />
        </div>
        <div className="text-white">
          {stage.stage === 'EventOrgVerify' ? (
            <>
              <p>
                Pegasus will download your USEF profile data and save it to your profile for use
                such as being offline or in poor internet areas.
              </p>
              <p className="mt-4">
                By clicking ‘Next’ below, you agree to allow Pegasus to download and store your USEF
                data.
              </p>
            </>
          ) : (
            <>
              <p>
                A verification email was sent to the email address on file with the USEF for the
                competiton manmagement membership number you entered.
              </p>
              <p className="mt-4">Click the link the email to continue.</p>
            </>
          )}
        </div>
      </div>
      <div className="w-[70%] mt-20 mx-auto">
        <UserSignupViewComponentButton
          caption={stage.stage === 'EventOrgVerify' ? 'Next' : 'Done'}
          kind={1}
          icon={stage.stage === 'EventOrgVerify'}
          disabled={!stage.selector}
          onClick={handleSubmit}
        />
      </div>
      <div className="text-center text-white opacity-50 mt-10">OR</div>
      <div className="text-white my-10 text-center cursor-pointer">
        Don’t have a USEF number?{' '}
        <a className="text-SeabiscuitMainThemeColor" onClick={() => stageHandle('SignUp')}>
          Continue &gt;
        </a>
      </div>
    </div>
  )
}

export default UserSignupViewComponentEventOrganizerVerify
