// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useEffect, useState } from 'react'
import BackIcon from '../assets/back-icon.svg'
import { Clear } from '@mui/icons-material'
import clsx from 'clsx'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export interface PropTypes {
  show: boolean
  children: any
  onHide?: () => void
  type: string
  title?: string
  headingClassName?: string
  modaltopclassname?: string
  iconclassname?: string
  handleModal?: any
  modalFor?: string
  title_icon?: string
  fullScreen?: boolean
  fullWidth?: boolean
  ModalBetweenModal?: string
  size?: 'md' | 'lg' | 'xl' | 'sm' | 'xs'
  title_error_icon?: string
  mainDivClass?: string
  upDivClass?: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ModalComponent: React.FC<PropTypes> = (props: PropTypes) => {
  // Hooks and vars
  const [transformInfo, setTransformInfo] = useState('')

  const modalBreakpoint = 768
  const headingClassName = props?.headingClassName ?? ''
  const { modaltopclassname, iconclassname, ModalBetweenModal } = props

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const size =
    (function () {
      let sizeToReturn: string = 'w-[calc(100vw-3rem)]'

      if (props?.fullWidth || props?.size === 'xl')
        return `${sizeToReturn} md:w-[80%] md:w-[calc(100%-10rem)]`

      switch (props.size) {
        case 'xs':
          sizeToReturn = 'md:max-w-[400px]'
          break
        case 'sm':
          sizeToReturn = 'md.max-w-[500px]'
          break
        case 'md':
          sizeToReturn = 'md:max-w-[600px]'
          break
        case 'lg':
          sizeToReturn = 'md:max-w-[700px]'
          break
      }

      return sizeToReturn
    })() ?? null

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const transformCalculator = () => {
    if (window.innerWidth > modalBreakpoint) {
      setTransformInfo(
        props.show ? 'translateX(-50%) translateY(-50%)' : 'translateX(-50%) translateY(-30%)'
      )
    } else {
      setTransformInfo(
        props.show ? 'translateX(0%) translateY(0%)' : 'translateX(0%) translateY(10%)'
      )
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    transformCalculator()
    if (props.show) {
      window.addEventListener('resize', transformCalculator, true)
    } else {
      window.removeEventListener('resize', transformCalculator, true)
    }
    return () => window.removeEventListener('resize', transformCalculator, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show])

  return (
    <div
      className={`fixed left-0 right-0 top-0 bottom-0 z-10 transition-all ${props.mainDivClass}`}
      style={{
        visibility: props.show ? 'visible' : 'hidden',
      }}
    >
      <div
        className={clsx(
          `fixed w-full md:w-11/12 h-full md:h-auto bg-white p-4 sm:p-6 max-h-[100vh] md:max-h-[calc(100vh_-_60px)] md:rounded-xl z-10 left-0 md:left-[calc(50%_-_1rem)] flex flex-col top-0 md:top-[calc(50%_-_1rem)] m-0 md:m-2 transition-all ${modaltopclassname}`,
          props?.fullScreen && 'min-h-[80vh]',
          size
        )}
        style={{
          opacity: props.show ? 1 : 0,
          transform: transformInfo,
        }}
      >
        <div className="flex items-center">
          <div className="flex-1 font-normal text-xl sm:text-[25px] flex items-center gap-3">
            <button
              onClick={() =>
                props.handleModal ? props.handleModal(false, props.modalFor) : props.onHide
              }
              className="block md:hidden text-SeabiscuitRedThemeColor"
            >
              <img src={BackIcon} className="w-4" alt="close icon" />
            </button>
            {props.title_icon && (
              <img
                className={`mr-2 w-[40px] ${iconclassname}`}
                src={props.title_icon}
                onError={(e) => {
                  e.currentTarget.src = props?.title_error_icon
                    ? props?.title_error_icon
                    : e.currentTarget.src
                }}
                alt="icon"
              />
            )}
            <div
              className={`text-SeabiscuitDark200ThemeColor font-[400]${headingClassName ? ` ${headingClassName}` : ''}`}
            >
              {props.title}
            </div>
          </div>
          <div className="text-right">
            <span
              onClick={() =>
                props.handleModal
                  ? props.handleModal(false, props.modalFor)
                  : props.onHide && props.onHide()
              }
              className="absolute text right-2 top-1 text-SeabiscuitDark200ThemeColor cursor-pointer hover:bg-[#f7f7f7] p-1 rounded-full"
            >
              <Clear
                fontSize={'small'}
                style={{
                  color: 'grey',
                  fontWeight: '400',
                }}
              />
            </span>
          </div>
        </div>
        <div
          className={clsx(
            'overflow-auto max-h-screen mt-4 flex-1',
            ModalBetweenModal,
            props.upDivClass
          )}
        >
          {props.children}
        </div>
      </div>
      <div
        className={`fixed left-0 right-0 top-0 bottom-0 bg-black/30 transition-all`}
        style={{
          opacity: props.show ? 1 : 0,
        }}
      ></div>
    </div>
  )
}

export default ModalComponent
