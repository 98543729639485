/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import WrapperWithHeader from '../../../components/common/wrappers/WrapperWithHeader'
import { VideoModal } from './modals/VideoModal'
import { useIntercom } from 'react-use-intercom'
import { selectUserType } from '../../../store/user/userSlice'
import { CONST } from '../../../const/const'
import { useAppSelector } from '../../../store/hooks'

const IconComponent = ({
  children,
  icon,
  title,
}: {
  children: any
  icon: string
  title: string
}) => {
  return (
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center w-96">
        <img className="mr-2 w-6 h-6" src={icon} alt="User_Groups-1" />
        <div className="text-SeabiscuitDark200ThemeColor text-[16px]">{title}</div>
      </div>

      {children}
    </div>
  )
}

const HelpRootPageHelpTab = ({
  handleModal,
}: {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}) => {
  const [openVideoModal, setOpenVideoModal] = useState(false)
  const userType = useAppSelector(selectUserType)
  const { show } = useIntercom()

  return (
    <WrapperWithHeader title="Help" description="Select your preferred help method">
      <>
        <hr className="mb-4"></hr>
        <IconComponent icon="/assets/cp_icons/User_Groups-1.svg" title="Help Center">
          <a
            onClick={show}
            className="border border-[#D3DAEE] text-SeabiscuitDark200ThemeColor text-[14px] flex items-center justify-center rounded-xl p-4 w-[11rem] mr-2 cursor-pointer"
            rel="noreferrer"
          >
            <span>Open Center</span>
            {/* <img className="w-6 h-6" src="/assets/cp_icons/edit.svg" alt="editIcon" /> */}
          </a>

          {/* <div
                        className="border border-[#D3DAEE] text-SeabiscuitDark200ThemeColor text-[14px] flex items-center justify-between rounded-xl p-4 w-44 mr-2 cursor-pointer"
                        onClick={() => setOpenVideoModal(true)}
                    >
                        <span>What is this?</span>
                        <img className="w-6 h-6" src="/assets/cp_icons/play.svg" alt="playIcon" />
                    </div> */}
        </IconComponent>
        {(userType === CONST.USE_MODE.ORGANIZER || userType === CONST.USE_MODE.COMPETITOR) && (
          <>
            <hr className="my-4"></hr>
            <IconComponent icon="/assets/cp_icons/Phone.svg" title="Call Pegasus customer service">
              <div
                className="border !cursor-pointer border-[#D3DAEE] text-SeabiscuitDark200ThemeColor text-[14px] flex items-center justify-center rounded-xl p-4 w-[11rem] mr-2"
                onClick={() => (window.location.href = 'tel:+17373009515')}
              >
                +1 (737) 300-9515
              </div>
            </IconComponent>
          </>
        )}
        {/* <hr className="my-4"></hr> */}
        {/* <IconComponent
                    icon="/assets/cp_icons/Mail-1.svg"
                    title="Email Pegasus"
                >
                    <div className="border border-[#D3DAEE] text-SeabiscuitDark200ThemeColor text-[14px] flex items-center justify-between rounded-xl p-4 w-[calc(22.50rem)] mr-2">
                        support@thepegasus.app
                    </div>
                </IconComponent> */}
        {openVideoModal && (
          <VideoModal
            open={openVideoModal}
            videoURL={
              'https://firebasestorage.googleapis.com/v0/b/pegasus-ems.appspot.com/o/event%2Fvideo%2F%40thepegasusapp-6.mp4-1700919992707?alt=media&token=51281e5d-96a4-4d3a-8f91-efce0df60f80'
            }
            onClose={() => setOpenVideoModal(false)}
          />
        )}
      </>
    </WrapperWithHeader>
  )
}

export default HelpRootPageHelpTab
