// ############################################################
/**
 * @todo Document this
 */
// ############################################################

// Styles
import './HomeRootPageContentUpCompetitionsSection.css'

// Constants
import { CONST } from '../../../const/const'

// Component imports
import HomeSectionHeaderComponent from '../../../components/home/headers/HomeSectionHeaderComponent'
import EventPostsHorizontalCardStackComponent from '../../../components/events/stacks/post-horizontal-card/EventPostsHorizontalCardStackComponent'
import { MESSAGES_CONST } from '../../../const/messages-const'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface HomeRootPageContentUpCompetitionsSectionProps {
  events?: any
  title: string
  tags?: string[]
  handleModal: any
  hasMore: boolean
  fetchMore: () => void
  competitionCategory?: string
  filterByStatus: (status: string) => void
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const HomeRootPageContentUpCompetitionsSection: React.FC<
  HomeRootPageContentUpCompetitionsSectionProps
> = (props) => {
  // Hooks and vars
  const events: any = props.events

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      {/*{*/}
      {/*  <HomeSectionHeaderComponent*/}
      {/*    filterByStatus={props.filterByStatus}*/}
      {/*    title={props.title}*/}
      {/*    seeAllRouteUrl={CONST.ROUTES.UPCOMING_COMPETITIONS.URL}*/}
      {/*    events={events}*/}
      {/*  />*/}
      {/*}*/}

      {props.events?.length ? (
        <EventPostsHorizontalCardStackComponent
          tags={props.tags}
          competitions={events}
          hasMore={props.hasMore}
          fetchMore={props.fetchMore}
        />
      ) : (
        <div className="mb-6 px-5 min-h-[70vh] flex items-center justify-center flex-col w-full">
          <div className="w-[70vh] md:max-w-[600px] max-w-full my-8">
            <img
              src={'assets/placeholders/noEventsFoundBanner.svg'}
              className="w-full h-full object-cover"
              alt="no events found"
            />
          </div>
          <div className="text-SeabiscuitDark200ThemeColor text-nr">
            {props.tags && props.tags.length
              ? props.tags[0] === 'other'
                ? 'No ‘other’ events have been created'
                : props.tags[0] === 'clinic'
                  ? MESSAGES_CONST.CLINIC_SCREEN_EMPTY_MSG
                  : props.tags[0] === 'following'
                    ? 'Follow event organizers to view their events'
                    : 'No events have been created. Check back soon!'
              : MESSAGES_CONST.UNLICENCED_LICENCED_MESSAGE}
          </div>
        </div>
      )}
    </>
  )
}

export default HomeRootPageContentUpCompetitionsSection
