import { Tooltip } from '@mui/material'
import clsx from 'clsx'
import { useContext, useEffect, useRef, useState } from 'react'
import InfiniteScrollDataTable from '../../../components/common/tables/InfiniteScrollDataTable'
import { customStyles } from '../../organizer/manage/revenue/Tabs/AllRevenueTableStyle'

import { Link } from 'react-router-dom'
import LightboxCommonComponent from '../../../components/common/dialogs/full-screen/LightboxCommonComponent'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import TooltipIcon from '../../../helpers/TooltipIcon'
import usePdfToImage from '../../../hooks/usePdfToImage'
import { HandleModalContext } from '../../../layout/mainlayout/MainLayout'
import { IUserDocument } from '../../../models/user-documents/user-documents.interface'
import { ILightboxProps } from '../../../types/competitor_types'
import NoDataFoundSkeleton from '../NoPaperworkFoundPage'
import customImageComponent from '../../../components/common/CustomImageComponent'
import { AutorenewRounded } from '@mui/icons-material'
import DataNotAvailable from '../../../components/common/alerts/data-not-available/DataNotAvailable'

type IProps = {
  signedDocs: IUserDocument[]
  unsignedDocs: IUserDocument[]
  activeTab: string
  sharedDocs: IUserDocument[]
  allDocs: IUserDocument[]
  loadingElement: boolean
  ownerList: any[]
  hasMore: boolean
  handleDataUpdate?: (row: number, activeTab: string) => void
  getDocsFromDb?: () => Promise<void>
}

type IdocProps = {
  documentName: string
  shareDoc: any
  downloadPdf: any
  documentUrl: string
}

export const DownloadImage = (props: IdocProps) => {
  return (
    <div className="w-fit font-normal text-md flex flex-col text-white items-center">
      <div className="mb-3">
        {typeof props.documentName === 'string' ? props.documentName : 'unknown'}
      </div>
      <div className="flex ">
        <button
          onClick={() => props.downloadPdf(props.documentUrl)}
          className="flex items-center text-sm border border-white rounded-full border-solid py-2 px-3"
        >
          Download
          <img
            src="assets/og_icons/Download from the Cloud-5.svg"
            className="ml-2"
            alt="downloadIcon"
            width={20}
            height={20}
          />
        </button>
        <button
          onClick={() => props.shareDoc(0)}
          className="flex items-center text-sm border border-white rounded-full border-solid py-2 px-3 mx-3"
        >
          Share
          <img
            src="assets/og_icons/Share Rounded-4.svg"
            className="ml-2"
            alt="downloadIcon"
            width={20}
            height={20}
          />
        </button>
      </div>
    </div>
  )
}

const PaperworkDataList = (props: IProps) => {
  // Hooks and vars
  const { convert } = usePdfToImage()
  const toastFunctions = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)
  const {
    signedDocs,
    unsignedDocs,
    activeTab,
    sharedDocs,
    loadingElement,
    allDocs,
    ownerList,
    handleDataUpdate,
  } = props
  const { hasMore, getDocsFromDb } = props
  const [data, setData] = useState<any[]>([])
  const [lightBox, setLightBox] = useState<ILightboxProps>({ visible: false, slides: [] })
  const buttonLoader = useRef<number[]>([])

  // Functions

  /* Closes lightbox */
  const onLightBoxClose = () => {
    setLightBox({ visible: false, slides: [] })
  }

  /* Opens lightbox */
  const openLightBox = (docIndex: number) => {
    let data =
      props.activeTab === 'All'
        ? allDocs[docIndex]
        : props.activeTab === 'signed'
          ? signedDocs[docIndex]
          : unsignedDocs[docIndex]

    const link = document.createElement('a') as HTMLAnchorElement
    link.href = data.documentUrl && data.documentUrl !== '' ? data.documentUrl : ''
    link.target = '_blank'
    link.download = data.documentOriginalName + '.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const shareDoc = (docIndex?: number) => {
    let data = signedDocs[0]
    onLightBoxClose()
    handleModalContext?.handleModal(true, MODAL_CONSTS.SHARE_DOCUMENT, {
      openLightBox,
      data,
      docIndex,
    })
  }

  /**
   * @info Opens sign doc modal
   */
  const signDoc = ({
    index,
    documentUrl,
    row,
    owner,
  }: {
    index: number
    documentUrl: string
    row: any
    owner: {
      eventId: string | null
      ownerId: string | null | undefined
      ownerName: string | null
      ownerLegalPolicyName: string
    }
  }) => {
    let data = row
    handleModalContext?.handleModal(true, MODAL_CONSTS.SIGN_DOCUMENT, {
      data,
      handleDataUpdate,
      activeTab,
      docIndex: index,
      documentUrl,
      openLightBox,
      setLightBox,
      owner,
    })
  }

  useEffect(() => {
    if (activeTab === 'All') {
      setData(allDocs)
    } else if (activeTab === 'Signed') {
      setData(signedDocs)
    } else if (activeTab === 'Unsigned') {
      setData(unsignedDocs)
    } else if (activeTab === 'Received') {
      setData(sharedDocs)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, signedDocs, unsignedDocs, allDocs])

  const downloadPdf = (link: string) => {
    var a = document.createElement('a')
    a.setAttribute('download', 'sample.pdf')
    a.setAttribute('href', link)
    a.setAttribute('target', '_blank')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleViewMethod = async (row: any, index: number) => {
    try {
      let arr: number[] = [...buttonLoader.current, index]
      buttonLoader.current = arr

      const cond = row.status === 'Signed' || row.status === 'Shared'
      if (!cond) {
        console.log('=>(PaperworkDataList.tsx:203) row', row)
        signDoc({
          index: index,
          documentUrl: row.documentUrl,
          row,
          owner: ownerList.filter((data) => data.eventId === row.eventId)?.[0],
        })
      } else {
        if (!row.documentUrl)
          return toastFunctions.error({
            message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          })

        const result = await convert(row.documentUrl)
        if (!result)
          return toastFunctions.error({
            message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          })

        if (result.imagesList.length) {
          let lightbox_ = { ...lightBox }
          lightbox_.visible = true
          lightbox_.slides = result.imagesList.map((currImageBase64) => ({
            src: currImageBase64,
            title: (
              <DownloadImage
                documentName={row.documentOriginalName}
                shareDoc={shareDoc}
                downloadPdf={downloadPdf}
                documentUrl={row.documentUrl}
              />
            ),
          }))
          setLightBox(lightbox_)
        } else {
          toastFunctions.error({
            message: 'Document uploading process stopped forcefully',
          })
        }
      }

      arr = buttonLoader.current.filter((item) => item !== index)
      buttonLoader.current = arr
    } catch (error) {
      console.log(error)
    }
  }

  const checkButtonLoading = (index: number) => {
    const found = buttonLoader.current.findIndex((item) => item === index)
    return found === -1 ? false : true
  }

  const columns = [
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap text-SeabiscuitDark200ThemeColor">Event</span>
          <Tooltip
            title={<h1 className="tooltip_title">Event this bill is associated with</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: any) => (
        <div className="flex items-center text-SeabiscuitDark200ThemeColor font-semibold capitalize">
          <>
            <Link to={`/events-details/${row.eventId}`}>
              {customImageComponent(
                row.eventLogo,
                row.eventName,
                '!w-[52px] !h-[52px] p-1 rounded-full mr-4 shrink-0'
              )}
            </Link>
            {row?.eventName}
          </>
        </div>
      ),
      width: '36%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex ">
          <span className="whitespace-nowrap">Status</span>
          <Tooltip
            title={<h1 className="tooltip_title">Has this document been signed?</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: any) => (
        <div className="flex flex-row mb-3 md:mb-0 items-center w-full">
          <img
            src="/assets/cp_icons/Quill Pen.svg"
            className="mr-2 w-[24px] h-auto"
            alt="paidStatus"
          />
          <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal whitespace-nowrap">
            {row.status}
          </span>
        </div>
      ),
      width: '13%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">Document</span>
          <Tooltip
            title={<h1 className="tooltip_title">Document name</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: any) => (
        <>
          <div className="flex items-center w-fit">
            <img src="/assets/cp_icons/Google Docs.svg" className="mr-2" alt="paidStatus" />
            <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
              {row.documentOriginalName}
            </span>
          </div>
        </>
      ),
      width: '18%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">Role</span>
          <Tooltip
            title={
              <h1 className="tooltip_title">
                Role you will sign this document as Eg. Rider, Trainer, Owner
              </h1>
            }
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: any) => (
        <div className="flex items-center w-full text-SeabiscuitDark200ThemeColor">
          <img src="/assets/cp_icons/Name Tag.svg" className="mr-2" alt="paidStatus" />
          {row.signatoryDefaultRole}
        </div>
      ),
      width: '13%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center ">
          <span className="whitespace-nowrap">From</span>
          <Tooltip
            title={
              <h1 className="tooltip_title">Person or event that sent you this document to sign</h1>
            }
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: any) => {
        return (
          <div className="flex mb-3 md:mb-0 items-center w-full">
            <img
              src="/assets/cp_icons/User.svg"
              className="mr-2 w-[24px] h-auto"
              alt="paidStatus"
            />
            <span className="capitalize text-SeabiscuitDark200ThemeColor font-normal">
              {ownerList.filter((data) => data.eventId === row.eventId)?.[0]?.ownerName}
              {/* {console.log("ownerlist:", ownerList)} */}
            </span>
          </div>
        )
      },
      width: '13%',
    },
    {
      name: (
        <span className="text-SeabiscuitDark200ThemeColor font-semibold text-sm flex items-center">
          <span className="whitespace-nowrap">View</span>
          <Tooltip
            title={<h1 className="tooltip_title">Click to view and sign document</h1>}
            placement="top"
            arrow
            className="shrink-0"
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      ),
      cell: (row: any, index: number) => (
        <div className="flex justify-center text-SeabiscuitDark200ThemeColor w-full">
          <button
            className="bg-[#F2F3F8] whitespace-nowrap py-3 w-full mr-[-0px] font-semibold hover:text-[#f7074f] hover:bg-[#F7074F0D] rounded-xl text-SeabiscuitDark200ThemeColor"
            disabled={checkButtonLoading(index) ? true : false}
            onClick={() => handleViewMethod(row, index)}
          >
            {checkButtonLoading(index) ? (
              <AutorenewRounded fontSize="small" className="animate-spin mx-auto" />
            ) : row.status === 'Signed' || row.status === 'Shared' ? (
              'View'
            ) : (
              'Sign'
            )}
          </button>
        </div>
      ),
      width: '7%',
    },
  ]
  return (
    <>
      <InfiniteScrollDataTable
        data={data}
        hasMore={hasMore}
        columns={columns}
        fixedHeader={true}
        customStyles={customStyles}
        progressPending={loadingElement}
        // progressPending={true}
        noTableHead={data && data.length ? false : true}
        fetchMore={getDocsFromDb}
        className={clsx('!rounded-none h-full')}
        noDataComponent={
          <DataNotAvailable
            mode="graphic"
            imageAlt="document"
            imageClassName="!w-[40%]"
            imageSource={'/assets/placeholders/paperworkplaceholder.svg'}
            upperText={'No documents found'}
          />
        }
        progressComponent={<NoDataFoundSkeleton />}
      />

      {/* -----------LightBox----------- */}
      <LightboxCommonComponent
        visible={lightBox.visible}
        onClose={onLightBoxClose}
        slides={lightBox.slides}
      />
    </>
  )
}

export default PaperworkDataList
