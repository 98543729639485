import CallMadeIcon from '@mui/icons-material/CallMade'
import React, { useEffect, useState } from 'react'
import customImageComponent from '../../../../../../components/common/CustomImageComponent'
import MainModal from '../../../../../../components/modals/common/MainModal'
import { IMAGE_CONSTS } from '../../../../../../const/image-const'
import { INewsInterface } from '../../../../../../models/news/news.interface'

type INewsModalProps = {
  show: boolean
  dataToPassOn: INewsInterface
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const NewsModal = (props: INewsModalProps) => {
  const dataToPassOn = props.dataToPassOn
  const [description, setDescription] = useState<string[]>([])

  useEffect(() => {
    const description = dataToPassOn?.description ?? ''
    setDescription(description.split('\n'))
  }, [dataToPassOn?.description])

  return (
    <MainModal
      title={dataToPassOn.title ?? 'N/A'}
      show={props.show}
      type="NEWS"
      className="mt-4"
      setHeightAsPerContent={true}
      size="lg"
    >
      <>
        <div className="flex gap-4 rounded-xl mt-3 md:flex-row flex-col min-h-[190px] text-SeabiscuitDark200ThemeColor">
          <div className="flex gap-4 flex-1 md:flex-row flex-col w-[calc(100%-64px)]">
            <div className="flex flex-col gap-4">
              {dataToPassOn?.pictures?.length
                ? dataToPassOn?.pictures.map((currImage, index) => {
                    return (
                      <React.Fragment key={`currImage${index}`}>
                        {customImageComponent(
                          currImage ?? IMAGE_CONSTS.PLACEHOLDERS.NEWS,
                          '',
                          'object-cover rounded-xl !w-[120px] !h-[120px] rounded-xl overflow-hidden'
                        )}
                      </React.Fragment>
                    )
                  })
                : customImageComponent(
                    IMAGE_CONSTS.PLACEHOLDERS.NEWS,
                    '',
                    'object-cover rounded-xl !w-[120px] !h-[120px] rounded-xl overflow-hidden'
                  )}
            </div>

            <div className="flex-1 flex-col flex gap-3.5 max-w-[calc(100%-180px)]">
              <div className="flex flex-wrap">
                <div className="opacity-70 min-h-[24px] rounded-md text-base" data-link={'link'}>
                  {dataToPassOn.headLine}
                  {dataToPassOn.url ? (
                    <a
                      target="_blank"
                      href={dataToPassOn.url}
                      className="bg-SeabiscuitMainThemeColor bg-opacity-[0.1] h-6 w-6 rounded-md ml-2 relative top-[3px] inline-block"
                      rel="noreferrer"
                    >
                      <span className="flex w-full h-full items-center justify-center">
                        <CallMadeIcon fontSize="small" className="text-SeabiscuitMainThemeColor" />
                      </span>
                    </a>
                  ) : null}
                </div>
              </div>

              <div className="opacity-70 min-h-[50px] rounded-md text-base">
                {description.map((curr, index) => {
                  return !!curr ? (
                    <div key={`line${index}`}>{curr}</div>
                  ) : (
                    <br key={`line${index}`} />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    </MainModal>
  )
}

export default NewsModal
