// Component imports
import ConfirmationCompsWrapper from './ConfirmationCompsWrapper'

// Types
import clsx from 'clsx'
import { cloneDeep, sumBy } from 'lodash'
import { useEffect, useState } from 'react'
import helpers from '../../../../../../commonHelpers/helpers'
import { CONST } from '../../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../../const/messages-const'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'
import { CustomError } from '../../../../../../helpers/helpers'
import { IRegistrationFeesInterface } from '../../../../../../models/registration-fees/registrationFees.interface'
import { IRegistrationByDayInterface } from '../../../../../../models/registrations-by-day/registrationByDay.interface'
import { ITeamMember } from '../../../../../../models/users/user.interface'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import {
  selectFeesTabDataR,
  selectPayTabFeesAccordion,
  selectRegistrationByDay,
  selectRegistrationFeesR,
  updateFeesTabRowInReduxAc,
} from '../../../../../../store/registration/registrationSlice'
import { IHandlePaymentDivided } from '../../../../competitorEventRegister/Tabs/CompetitorEventRegisterPayTab'
import SelectRecipient from './SelectRecipient'
import { FEES_CATEGORY_CONST } from '../../../../../../components/events/views/details/EventDetailsViewComponentFees'
import { IManageInfo } from '../../../../event-registration-tabs/EventRegistrationTabs'
import { EVENT_REGISTERED_CONST } from '../../../../../../models/event-registered-users/event-registered-users.constants'

type IProps = {
  setEventTab?: any
  splitPayment?: boolean
  membersAddedInRegistration?: [] | ITeamMember[]
  handlePaymentDivided?: IHandlePaymentDivided
  manageInfo: IManageInfo
  isManage?: boolean
}

type IHandleRecipientChange = (args: IHandleRecipientChangeArgs) => void
type IHandleRecipientChangeArgs = {
  /** @info Id of the item that is holding the select list */
  docId: IRegistrationFeesInterface['id']
  /** @info Id of the doc containing selected member */
  selectedDocId: IRegistrationByDayInterface['id']
  memberId: string | null
  itemIndexInList?: number | null
}

const FILE_NAME = 'SelectedEventFeeList'
const CUSTOM_ERROR_PROPS = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

const SelectedEventFeeList = (props: IProps) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()
  const feesTabData = useAppSelector(selectFeesTabDataR)
  const registrationFees = useAppSelector(selectRegistrationFeesR)
  const registrationsByDay = useAppSelector(selectRegistrationByDay)
  const feesAccordionData = useAppSelector(selectPayTabFeesAccordion)
  const splitPayment = props?.splitPayment ?? false

  const [open, setOpen] = useState(false)
  const [selectedUnits, setSelectedUnits] = useState<{
    totalItems: number
    totalPrice: number
  }>({ totalItems: 0, totalPrice: 0 })

  let heading = [
    { title: 'Line item', tooltipText: 'Fee line items you added during registration' },
    { title: 'Number of units', tooltipText: 'Number of units per line item' },
    { title: 'Price per unit', tooltipText: 'Price per individual line item' },
  ]

  if (splitPayment) {
    heading = [...heading, { title: 'Invoice recipient', tooltipText: '' }]
  }

  // Functions
  const handleRecipientChange: IHandleRecipientChange = (args) => {
    try {
      let registrationFeesRowIndex = -1
      let selectedRegistrationByDayIndex = -1
      let mutatedRegistrationFees: null | IRegistrationFeesInterface = null
      let selectedRegistrationByDayInDb: null | IRegistrationByDayInterface = null

      selectedRegistrationByDayIndex = registrationsByDay.findIndex((currRegistrationByDay) => {
        return currRegistrationByDay.id === args.selectedDocId
      })

      selectedRegistrationByDayInDb = cloneDeep(
        registrationsByDay?.[selectedRegistrationByDayIndex]
      )
      registrationFeesRowIndex = Number(args.docId)
      mutatedRegistrationFees = feesTabData?.[registrationFeesRowIndex]?.registrationFees ?? null

      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal(
        {
          docId: args.docId,
          mutatedRegistrationFees,
          selectedRegistrationByDayInDb,
          selectedDocId: args.selectedDocId,
        },
        ['0']
      )

      if (emptyVarName)
        throw CustomError.somethingWentWrong({
          ...CUSTOM_ERROR_PROPS,
          moduleName: 'handleRecipientChange',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
        })

      mutatedRegistrationFees = {
        ...(mutatedRegistrationFees as any),
        update: true,
        isPaidByOwner: false,
        recipientId: selectedRegistrationByDayInDb.riderId,
        recipientName: selectedRegistrationByDayInDb.riderName,
        recipientNameNGram: selectedRegistrationByDayInDb.riderNameNGram,
        recipientProfilePicture: selectedRegistrationByDayInDb.riderProfilePicture,
      }

      dispatch(
        updateFeesTabRowInReduxAc({
          feesTabRowIndex: registrationFeesRowIndex,
          dataToUpdate: mutatedRegistrationFees,
        })
      )
    } catch (error: any) {
      toastFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        message: error,
      })
    }
  }

  useEffect(() => {
    if (props.isManage) {
      const fees = props.manageInfo.fees

      const totalItems = parseFloat(
        sumBy(fees, (fees) => fees.registrationFees?.selectedUnitsCount ?? 0).toFixed(2)
      )

      const totalPrice = parseFloat(
        sumBy(
          fees,
          (fees) =>
            (fees.registrationFees?.selectedUnitsCount ?? 0) *
            (fees.registrationFees?.feesPrice ?? 0)
        ).toFixed(2)
      )
      setSelectedUnits({
        totalItems,
        totalPrice,
      })
    } else {
      setSelectedUnits({
        totalItems: feesAccordionData.totalItemsCount,
        totalPrice: parseFloat(feesAccordionData.totalPrice.toFixed(2)),
      })
    }
  }, [props.isManage, props.manageInfo.fees, feesAccordionData])

  return (
    <ConfirmationCompsWrapper
      title="Fees"
      id={FILE_NAME}
      setEventTab={props.setEventTab}
      redirectTab={CONST.UI.REGISTER.TABS.FEES}
      selected_units={`${selectedUnits.totalItems} ${props.isManage ? 'new' : ''} ${selectedUnits.totalItems > 1 ? 'Fees' : 'Fee'}, $${selectedUnits.totalPrice}`}
      accordion={{ open, setOpen }}
      showList={!!registrationFees.length}
      colsClassName={
        splitPayment
          ? {
              0: '!w-1/4 flex items-center',
              1: '!w-1/4 flex items-center',
              2: '!w-1/4 flex items-center',
              3: '!w-1/4 flex items-center',
            }
          : {
              0: '!w-1/3 flex items-center',
              1: '!w-1/3 flex items-center',
              2: '!w-1/3 flex items-center',
            }
      }
      cols={heading}
    >
      <>
        {[...feesTabData, ...props.manageInfo.fees]?.map((currRow, index) => {
          if (currRow.registrationFees && currRow.feesCategory !== FEES_CATEGORY_CONST.GOVERNANCE) {
            return (
              <div
                key={`${JSON.stringify(currRow)}${index}FeesList`}
                className="flex flex-col lg:flex-row items-start justify-start w-full mb-2"
              >
                <div className="lg:hidden mb-1 font-medium text-[12px]">Line Item</div>

                <p
                  className={clsx(
                    `w-full text-SeabiscuitDark200ThemeColor line-clamp-1 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize`,
                    splitPayment ? 'lg:w-1/4' : 'lg:w-1/3'
                  )}
                >
                  {currRow.feesTitle}
                </p>
                <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">Number Of Units</div>

                <p
                  className={clsx(
                    `w-full text-SeabiscuitDark200ThemeColor line-clamp-1 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize lg:text-center`,
                    splitPayment ? 'lg:w-1/4' : 'lg:w-1/3'
                  )}
                >
                  {currRow?.registrationFees?.selectedUnitsCount ?? 0}
                </p>
                <div className="lg:hidden mb-1 font-medium text-[12px] mt-2">Price Per Unit</div>

                <p
                  className={clsx(
                    `w-full text-SeabiscuitDark200ThemeColor line-clamp-1 p-4 rounded-md bg-SeabiscuitGrayThemeColor border-solid border border-SeabiscuitGrayThemeColor capitalize lg:text-center`,
                    splitPayment ? 'lg:w-1/4 mr-2' : 'lg:w-1/3'
                  )}
                >
                  {currRow.registrationFees?.paymentStatus ===
                  EVENT_REGISTERED_CONST.PAYMENT_STATUS.PAID
                    ? EVENT_REGISTERED_CONST.PAYMENT_STATUS.PAID
                    : currRow.registrationFees?.paymentStatus ===
                        EVENT_REGISTERED_CONST.PAYMENT_STATUS.CASH
                      ? EVENT_REGISTERED_CONST.PAYMENT_STATUS.CASH
                      : `$${currRow.feesPrice}`}
                </p>

                {splitPayment ? (
                  <SelectRecipient
                    id={FILE_NAME}
                    docId={`${index}`}
                    itemIndexInList={index}
                    handleRecipientChange={handleRecipientChange}
                    recipientId={currRow.registrationFees?.recipientId}
                    isLastIndex={currRow.feesItemId === registrationFees.at(-1)?.feesItemId}
                    membersAddedInRegistration={props.membersAddedInRegistration ?? []}
                  />
                ) : null}
              </div>
            )
          }
        })}

        {/* Total */}
        <div className="flex flex-row items-start justify-start w-full mb-2 font-semibold text-SeabiscuitGreenThemeColor text-center">
          <p
            className={clsx(
              `w-full flex-grow p-4 text-left mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor`,
              splitPayment ? 'lg:w-1/4' : 'lg:w-1/3'
            )}
          >
            {props.isManage && 'New'} Total
          </p>

          <p
            className={clsx(
              `w-full flex-grow p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor`,
              splitPayment ? 'lg:w-1/4' : 'lg:w-1/3'
            )}
          >
            {selectedUnits.totalItems}
          </p>

          <p
            className={clsx(
              `w-full  flex-grow p-4 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor`,
              splitPayment ? 'lg:w-1/4 mr-2' : 'lg:w-1/3'
            )}
          >
            ${selectedUnits.totalPrice ?? 0}
          </p>

          {splitPayment ? (
            <p
              className={`w-[calc(25%-8px)] p-4 rounded-md bg-SeabiscuitGrayThemeColor capitalize border-solid border border-SeabiscuitGreenLightThemeColor bg-SeabiscuitGreenLightThemeColor`}
            >
              {`${feesAccordionData.uniqueRecipientCount} ${(feesAccordionData.uniqueRecipientCount ?? 0) > 1 ? 'recipients' : 'recipient'}`}
            </p>
          ) : null}
        </div>
      </>
    </ConfirmationCompsWrapper>
  )
}

export default SelectedEventFeeList
