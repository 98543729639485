// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

import { Skeleton } from '@mui/material'
import clsx from 'clsx'

// Types
type IProps = {
  message: string
  firstImageSrc?: string
  secondImageSrc?: string
  firstSkeletonColor?: string
  secondSekeletonColor?: string
  changeImageAfter?: number
  rotate?: boolean
  itemsPerRow?: number
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const NoDataFoundSkeleton = (props: IProps) => {
  // Vars
  let {
    firstImageSrc,
    secondImageSrc,
    changeImageAfter,
    rotate,
    firstSkeletonColor,
    secondSekeletonColor,
    itemsPerRow,
  } = props

  let timesToLoop = 15

  // Conditions
  if (!secondImageSrc) secondImageSrc = '/assets/og_icons/Notification-4.svg'
  if (!changeImageAfter) changeImageAfter = 4
  if (!itemsPerRow) itemsPerRow = 3
  if (typeof rotate === 'undefined') rotate = true

  timesToLoop = itemsPerRow * parseInt((timesToLoop / itemsPerRow)?.toString())

  return (
    <>
      <div className="w-full flex items-center justify-center mb-40 mt-24">
        <div className="text-center w-full max-w-[44rem] text-SeabiscuitDark200ThemeColor">
          <h6 className="mb-16">{props?.message}</h6>
          <div
            className={clsx(
              'grid gap-3 items-center w-full text-SeabiscuitDark200ThemeColor',
              `grid-cols-${itemsPerRow}`
            )}
          >
            {[...Array(timesToLoop)].map((x, j) => {
              let i = j + 1
              let imageToShow = firstImageSrc
              let colorToShow = firstSkeletonColor

              if (i % ((changeImageAfter ?? 4) + 1) === 0) {
                imageToShow = imageToShow === firstImageSrc ? secondImageSrc : firstImageSrc
                colorToShow =
                  colorToShow === firstSkeletonColor ? secondSekeletonColor : firstSkeletonColor
              }

              return !imageToShow ? (
                <div key={`${x}${j}`} className="flex w-100 mr-3 mb-6 !mx-auto items-center">
                  <Skeleton
                    animation="pulse"
                    variant="circular"
                    width={45}
                    height={45}
                    style={{ backgroundColor: colorToShow }}
                    className={clsx('mr-2')}
                  />
                  <div className="flex flex-col justify-center">
                    <Skeleton
                      animation="pulse"
                      style={{
                        backgroundColor: colorToShow,
                      }}
                      variant="rounded"
                      width={80}
                      height={10}
                      className="mb-3"
                    />
                    <Skeleton
                      style={{
                        backgroundColor: colorToShow,
                      }}
                      animation="pulse"
                      variant="rounded"
                      width={150}
                      height={10}
                    />
                  </div>
                </div>
              ) : (
                <div key={`${x}${j}`} className="flex w-100 mr-3 mb-6 !mx-auto items-center">
                  <Skeleton
                    animation="pulse"
                    variant="circular"
                    width={45}
                    height={45}
                    style={{
                      backgroundColor: colorToShow,
                    }}
                    className={clsx(
                      'mr-2 !inline-flex items-center justify-center',
                      rotate ? '-rotate-45' : ''
                    )}
                  >
                    <img src={imageToShow} className="w-5 h-5 !visible" alt="skeleton" />
                  </Skeleton>
                  <div>
                    <Skeleton
                      animation="pulse"
                      style={{
                        backgroundColor: colorToShow,
                      }}
                      variant="rounded"
                      width={80}
                      height={10}
                      className="mb-3"
                    />
                    <Skeleton
                      animation="pulse"
                      style={{
                        backgroundColor: colorToShow,
                      }}
                      variant="rounded"
                      width={150}
                      height={10}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default NoDataFoundSkeleton
