/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { VideoModal } from './modals/VideoModals'

// Constants
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'

// Firebase
import {
  DocumentData,
  DocumentReference,
  collection,
  doc,
  where,
  writeBatch,
} from 'firebase/firestore'

// Redux
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  selectUserId,
  selectUserLegalClauses,
  setProfileDetails,
  setUserLegalClauses,
} from '../../../../store/user/userSlice'

// Third party
import { AutorenewRounded } from '@mui/icons-material'
import moment from 'moment'

// Types
import { ILegalClauseInterface } from '../../../../models/user-legal-clauses/user-legal-clauses.interface'

// Services
import FirebaseApp from '../../../../services/firebaseApp'
import FirestoreService from '../../../../services/firestoreService'

// Components
import LightboxCommonComponent from '../../../common/dialogs/full-screen/LightboxCommonComponent'
import ViewsLoader from '../../../loader/ViewsLoader'

// Models
import { UserLegalClausesModel } from '../../../../models/user-legal-clauses/user-legal-clauses.model'

// Custom hooks
import useToasterHelper from '../../../../helpers/ToasterHelper'

// Types
import { ILightboxProps } from '../../../../types/competitor_types'

// Helpers
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import helpers from '../../../../commonHelpers/helpers'
import MessageHelperComp from '../../../../helpers/MessageHelper'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { getConvertedData } from '../../../../models/interface.helper'
import { UserModel } from '../../../../models/users/user.model'
import formFunctions from '../../../ui/form/form-functions/formFunctions'
import FormHeader from '../../../ui/form/form-header/FormHeader'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IProps = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const ProfileLegalTab = (props: IProps) => {
  const handleModalContext = useContext(HandleModalContext)

  // Hooks and variables
  let mounted = true

  const [loading, setLoading] = useState(true)
  const [lightBox, setLightBox] = useState<ILightboxProps>({ visible: false, slides: [] })

  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()
  const userId = useAppSelector(selectUserId)
  const clauses = useAppSelector(selectUserLegalClauses)
  const { profileDetails } = useAppSelector((state) => state.user)
  const [removeClause, setRemoveClause] = useState<string[]>([])
  const USERS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS
  const [openVideoModal, setOpenVideoModal] = useState(false)

  const formStyles = formFunctions.getFormStyles({ noPaddingRt: true })
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object({
        userLegalPolicyName: yup.string().required('This field is required'),
      })
    ),
    mode: 'onChange',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (profileDetails?.userLegalPolicyName !== '') {
      setValue('userLegalPolicyName', profileDetails?.userLegalPolicyName)
    }
  }, [profileDetails?.userLegalPolicyName])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!clauses.length) setClauses()
    else setLoading(false)

    return () => {
      mounted = false
    }
  }, [userId])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleLightBox = (props: ILightboxProps) => {
    if (mounted)
      setLightBox({
        ...props,
      })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
    /* @info Closes lightbox
    */
  const onLightBoxClose = () => {
    handleLightBox({ visible: false, slides: [] })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Opens the template in lightbox
   */
  const viewTemplate = () => {
    handleLightBox({
      visible: true,
      slides: [
        {
          title: 'General Liablity Waiver Template',
          src: '/assets/documents/GeneralLiabilityWaiver18/DEMO_General Liability Waiver, 18+.docx_page-0001.jpg',
        },
        {
          title: 'General Liablity Waiver Template',
          src: '/assets/documents/GeneralLiabilityWaiver18/DEMO_General Liability Waiver, 18+.docx_page-0002.jpg',
        },
        {
          title: 'General Liablity Waiver Template',
          src: '/assets/documents/GeneralLiabilityWaiver18/DEMO_General Liability Waiver, 18+.docx_page-0003.jpg',
        },
        {
          title: 'General Liablity Waiver Template',
          src: '/assets/documents/GeneralLiabilityWaiver18/DEMO_General Liability Waiver, 18+.docx_page-0004.jpg',
        },
        {
          title: 'General Liablity Waiver Template',
          src: '/assets/documents/GeneralLiabilityWaiver18/DEMO_General Liability Waiver, 18+.docx_page-0005.jpg',
        },
        {
          title: 'General Liablity Waiver Template',
          src: '/assets/documents/GeneralLiabilityWaiver18/DEMO_General Liability Waiver, 18+.docx_page-0006.jpg',
        },
        {
          title: 'General Liablity Waiver Template',
          src: '/assets/documents/GeneralLiabilityWaiver18/DEMO_General Liability Waiver, 18+.docx_page-0007.jpg',
        },
      ],
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleClause = (index: null | number) => {
    let add = true
    let clause: ILegalClauseInterface | null = null

    if (typeof index === 'number') {
      add = false
      clause = clauses[index]
    }

    if (typeof props.handleModal === 'function')
      props.handleModal(true, MODAL_CONSTS.HANDLE_LEGAL_CLAUSE, {
        clause,
        index: index,
        title: add ? 'Add clause to general liability waiver' : 'Edit clause',
        headingClassName: '!font-normal !text-SeabiscuitDark200ThemeColor !text-[25px]',
      })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const saveClausesToDb = async (data: any) => {
    let errorOccured = false
    let id: null | string = null
    let unsavedClausesInRedux: ILegalClauseInterface[] = []
    setLoading(true)

    try {
      if (data?.userLegalPolicyName) {
        const userUpdateData = new UserModel({
          ...profileDetails,
          userLegalPolicyName: data?.userLegalPolicyName ?? '',
        }).toFirestore()

        await FirestoreService.updateItem(USERS.NAME, userId, userUpdateData)

        dispatch(setProfileDetails(getConvertedData(userUpdateData)))
      }

      const batch = writeBatch(FirebaseApp.firestore)

      let clausesToSave: ILegalClauseInterface[] = []
      let clause_: ILegalClauseInterface

      unsavedClausesInRedux = clauses.reduce((clausesWithoutAddKey, clause) => {
        clause_ = new UserLegalClausesModel(clause).toFirestore()

        let clauseRef: DocumentReference<DocumentData>
        switch (true) {
          case clause.add:
            clauseRef = doc(
              collection(
                FirebaseApp.firestore,
                CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_LEGAL_CLAUSES.NAME
              )
            )
            id = clauseRef.id
            batch.set(clauseRef, clause_)
            clause_.id = id
            clausesWithoutAddKey.push(clause_)
            break

          case clause.update:
            if (clause.id) {
              clauseRef = doc(
                FirebaseApp.firestore,
                CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_LEGAL_CLAUSES.NAME,
                clause.id
              )
              batch.set(clauseRef, clause_)
              clause_.id = id
              clausesWithoutAddKey.push(clause_)
            }
            break

          default:
            clausesWithoutAddKey.push(clause_)
        }
        return clausesWithoutAddKey
      }, clausesToSave)

      if (removeClause.length > 0) {
        removeClause.forEach((clauseId: string) => {
          batch.delete(
            doc(
              FirebaseApp.firestore,
              CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_LEGAL_CLAUSES.NAME,
              clauseId
            )
          )
        })
      }
      await batch.commit()

      dispatch(
        setUserLegalClauses(
          getConvertedData({
            clause: unsavedClausesInRedux,
          })
        )
      )
    } catch (error) {
      errorOccured = true
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setLoading(false)
      if (!errorOccured)
        toastFunctions.success({
          message: MESSAGES_CONST.UPDATED_CLAUSES,
        })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const setClauses = async () => {
    const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

    if (!userId) return 0

    try {
      const clausesSnapshot = await FirestoreService.filterItems(
        COLLECTIONS.USER_LEGAL_CLAUSES.NAME,
        [where(COLLECTIONS.USER_LEGAL_CLAUSES.FIELDS.OWNER_ID.KEY, '==', userId)]
      )
      if (clausesSnapshot.size) {
        let clauses: ILegalClauseInterface[] = []
        clausesSnapshot.forEach((clauseDoc) => {
          clauses.push(
            getConvertedData(UserLegalClausesModel.fromFirestoreDoc(clauseDoc).toObject())
          )
        })
        dispatch(
          setUserLegalClauses({
            clause: clauses,
          })
        )
      }
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onRemoveClauseClick = (index: number) => {
    if (handleModalContext)
      handleModalContext?.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
        removeItem: () => handleRemoveClause(index),
      })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleRemoveClause = (index: number) => {
    const id = clauses[index].id
    const indexToRemove = index
    const clause_ = [...clauses]
    clause_.splice(indexToRemove, 1)

    if (id) setRemoveClause([...removeClause, id])

    dispatch(
      setUserLegalClauses({
        clause: clause_,
      })
    )
  }

  return (
    <>
      <FormHeader
        title="Legal"
        description="Add custom clauses to the general liability waiver for your events"
        headerButtonsContainer={
          <div className="flex flex-col md:flex-row w-full md:w-[initial] items-center gap-2">
            <button
              type="button"
              className="items-center w-full md:w-w-[125px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
              onClick={() => setOpenVideoModal(true)}
            >
              More info
            </button>
            <button
              type="button"
              className="items-center w-full md:w-w-[125px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
              onClick={viewTemplate}
            >
              View waiver
            </button>
            <button
              type="button"
              className="items-center w-full md:w-w-[125px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
              onClick={() => {
                handleSubmit(saveClausesToDb)()
              }}
            >
              {loading === true ? (
                <AutorenewRounded fontSize="small" className="animate-spin mx-auto" />
              ) : (
                'Save changes'
              )}
            </button>
          </div>
        }
      />

      <div className={formStyles.className} style={formStyles.styles}>
        <div className="w-full flex-col md:flex-row flex md:items-center mb-3 text-SeabiscuitDark200ThemeColor">
          <div className="flex items-center mb-1 w-full md:w-1/4 mr-8 shrink-0">
            <img alt="Law" className="w-6 h-6 mr-3" src="assets/og_icons/Museum.svg" />
            Legal business name
          </div>
          <div className="w-full md:w-[22%] mr-2 flex flex-col">
            <input
              className="w-full p-3 px-6 mb-1 border-solid !border-[#D3DAEE] border overflow-hidden rounded-xl shrink-0 h-[51px] ellipsis outline-none"
              placeholder="Eg. Pegasus Events LLC"
              {...register('userLegalPolicyName')}
            />
            {errors?.userLegalPolicyName ? (
              <MessageHelperComp
                message={
                  typeof errors?.userLegalPolicyName?.message === 'string'
                    ? errors?.userLegalPolicyName?.message
                    : ''
                }
                isError
              />
            ) : (
              ''
            )}
          </div>
        </div>

        <hr className="my-8" />

        {loading ? (
          <div className="flex justify-center items-center min-h-[200px]">
            <ViewsLoader
              className="flex items-center w-full justify-center min-h-[30vh] mt-3"
              size="lg"
              color="#F7074F"
            />
          </div>
        ) : clauses.length ? (
          <div className="w-full overflow-x-auto">
            {clauses.map((currRow, index) => {
              return (
                <div
                  className="w-full flex-col md:flex-row flex mb-10 text-SeabiscuitDark200ThemeColor relative"
                  key={`${JSON.stringify(currRow)}${index}`}
                >
                  <div className="flex mb-1 w-1/4 mr-8 shrink-0">
                    <img alt="Law" className="w-6 h-6 mr-3" src="assets/og_icons/Law-1.svg" />
                    Clause
                  </div>
                  <div>
                    <div className="flex flex-col md:flex-row">
                      <div
                        className=" pb-2 text-md font-semibold cursor-pointer"
                        onClick={() => handleClause(index)}
                      >
                        {currRow.title} -
                      </div>
                      <div
                        className=" pb-2 pl-1 text-md overflow-hidden shrink-0 ellipsis text-gray-400"
                        title={`Last updated: ${moment(currRow.modified).format('MMM DD')}`}
                      >
                        Last updated: {moment(currRow.modified).format('hh:mm A, MMM DD')}
                      </div>
                    </div>
                    <div
                      className="mr-2 mb-3 text-md w-full md:w-[60vw]"
                      title={currRow.description}
                    >
                      <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => handleClause(index)}
                      >
                        <span className="mr-2 line-clamp-3">{currRow.description}</span>
                        {/* <img
                                                        src='assets/og_icons/FullScreen-1.svg'
                                                        alt='Edit clause icon'
                                                        className='w-6 h-6 cursor-pointer'
                                                    /> */}
                      </div>
                    </div>
                  </div>
                  {/* <div className='flex items-center'> */}
                  {/* <div className='w-[22%] mr-2 p-3 px-6 mb-1 border-solid border-[#D3DAEE] border overflow-hidden rounded-xl shrink-0 h-[51px] ellipsis'
                                            title={`Last updated: ${moment(currRow.modified).format("MM.DD.YYYY")}`}>
                                            Last updated: {moment(currRow.modified).format("MM.DD.YYYY")}
                                        </div> */}

                  {/* </div> */}
                  <div
                    className="cursor-pointer w-6 flex items-center absolute md:relative top-0 right-0"
                    onClick={() => onRemoveClauseClick(index)}
                  >
                    <img src="/assets/og_icons/Cancel.svg" alt="Cancel" />
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div className="flex w-[500px] max-w-full text-center mx-auto items-center flex-col text-nr">
            <div className="my-5">You have not add custom clauses to your liability paperwork</div>
            <img
              src="/assets/placeholders/NoLegalClauses.svg"
              alt="No legal clauses"
              className="max-w-300px"
            />
            <label
              className="my-5 block text-SeabiscuitMainThemeColor cursor-pointer"
              onClick={() => handleClause(null)}
            >
              Add your first clause now {`>`}
            </label>
          </div>
        )}
        <div
          id="addClauseDiv"
          className="flex w-fit mb-4 mt-6 items-center text-SeabiscuitMainThemeColor cursor-pointer"
          onClick={() => handleClause(null)}
        >
          <img
            src="assets/og_icons/Cancel-2.svg"
            className="w-6 h-6 mr-2 rotate-[45deg]"
            alt="cancel"
          />
          Add clause
        </div>

        {/* -----------LightBox----------- */}
        <LightboxCommonComponent
          visible={lightBox.visible}
          onClose={onLightBoxClose}
          slides={lightBox.slides}
        />
      </div>
      {openVideoModal && (
        <VideoModal
          open={openVideoModal}
          url={
            'https://firebasestorage.googleapis.com/v0/b/pegasus-ems.appspot.com/o/globals%2Foriginal-4D47D2FA-657D-4B38-9316-FB902BC8B943.mp4?alt=media&token=b8ff214f-2715-4060-b579-c8d2261a2dd5'
          }
          onClose={() => setOpenVideoModal(false)}
        />
      )}
    </>
  )
}

export default ProfileLegalTab
