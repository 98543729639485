import { CONST } from '../../../const/const'
import { RegistrationTicketModel } from '../../../models/registration-tickets/registrationTicket.model'
import FirestoreService from '../../firestoreService'
import IRegistrationTicketService from './types'

// Constants
const COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REGISTRATION_TICKET

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @info Event payment settings service
 */
class RegistrationTicketService {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Fetches the doc from the db
   * @param {IRegistrationTicketService["IGetRequest"]} req
   */
  get: IRegistrationTicketService['IGet'] = async (
    req: IRegistrationTicketService['IGetRequest']
  ) => {
    const documentSnapshot = await FirestoreService.getItem(COLLECTION.NAME, req.docId)

    if (!documentSnapshot.exists())
      throw new Error(`RegistrationTicket doc where id: ${req.docId} does not exist`)

    const registrationTicket = RegistrationTicketModel.fromFirestoreDoc(documentSnapshot).toObject()

    return {
      registrationTicket,
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Fetches the doc from the db
   * @param {IRegistrationTicketService["ICreateRequest"]} req
   */
  create: IRegistrationTicketService['ICreate'] = async (
    req: IRegistrationTicketService['ICreateRequest']
  ) => {
    const docToCreate = new RegistrationTicketModel(req.data).toFirestore()

    if (req.id) {
      await FirestoreService.createItemWithCustomId(COLLECTION.NAME, req.id, docToCreate)
      return {
        docId: req.id,
        registrationTicket: {
          id: req.id,
          docToCreate,
        },
      }
    } else {
      const documentSnapshot = await FirestoreService.createItem(COLLECTION.NAME, docToCreate)
      return {
        docId: documentSnapshot.id,
        registrationTicket: docToCreate,
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Fetches the doc from the db
   * @param {IRegistrationTicketService["IUpdateRequest"]} req
   */
  update: IRegistrationTicketService['IUpdate'] = async (
    req: IRegistrationTicketService['IUpdateRequest']
  ) => {
    let registrationTicket = await this.get({
      docId: req.docId,
    })

    await FirestoreService.updateItem(
      COLLECTION.NAME,
      req.docId,
      new RegistrationTicketModel({
        ...registrationTicket,
        ...req.fieldsToUpdate,
      }).toFirestore()
    )

    return {
      docId: req.docId,
      updatedFields: req.fieldsToUpdate,
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Fetches the doc from the db
   * @param {IRegistrationTicketService["IDeleteRequest"]} req
   */
  delete: IRegistrationTicketService['IDelete'] = async (
    req: IRegistrationTicketService['IDeleteRequest']
  ) => {
    await FirestoreService.deleteItem(COLLECTION.NAME, req.docId)

    return true
  }
}

const registrationTicketService = new RegistrationTicketService()

export default registrationTicketService
