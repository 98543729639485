import { useState, useEffect, useRef, createRef } from 'react'
import bgImage from './assets/main-bg.png'
import HeaderSection from './components/HeaderSection'
import FormSection from './components/FormSection'
import { SignupStages } from './components/UserSignupViewComponentContextApi'
import clsx from 'clsx'

export interface UserSignupViewComponentProps {
  onSignUpButtonPressed?: any
  loading: boolean
}

const UserSignupViewComponent: React.FC<UserSignupViewComponentProps> = (props) => {
  const signupStagesState = useState<{ selector: string; stage: string }>({
    selector: '',
    stage: 'Init',
  })
  // for back button
  const prevStage = useRef<{ selector: string; stage: string }>({
    selector: '',
    stage: 'Init',
  })

  useEffect(() => {
    prevStage.current = signupStagesState[0]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupStagesState[0]])

  // for centering forms when there is no overflow //////////////////
  const mainContainer = createRef<HTMLDivElement>()
  useEffect(() => {
    const marginTopCalculator = () => {
      const windowHeight = window.innerHeight - 118
      const containerHeight = mainContainer.current?.clientHeight || 0
      let marginTop = (windowHeight - containerHeight) / 2
      if (marginTop < 0) marginTop = 0
      if (mainContainer.current) mainContainer.current.style!.marginTop = marginTop + 'px'
    }
    const timeout = setTimeout(marginTopCalculator, 50)
    window.addEventListener('resize', marginTopCalculator, true)
    return () => {
      window.removeEventListener('resize', marginTopCalculator, true)
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupStagesState[0]])

  /////////////////////////////////////////////////////////////////////

  return (
    <SignupStages.Provider value={[...signupStagesState, prevStage]}>
      <div className="w-full mx-auto h-full !bg-white ">
        <div
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
          className="h-screen flex flex-col bg-cover"
        >
          <HeaderSection />
          <div
            className={clsx(
              'flex-1 overflow-auto h-[calc(100vh_-_118px)] px-[80px] flex',
              signupStagesState[0].stage === 'Init' ? 'items-center' : 'flex-start 2xl:py-20'
            )}
          >
            <div
              className="md:min-w-[500px] md:max-w-[30%] md:w-full sm:w-[350px] w-[300px] mx-auto md:mx-0 transition-[all_.3s] !mt-0 block h-fit"
              ref={mainContainer}
            >
              <FormSection
                onSignUpButtonPressed={props.onSignUpButtonPressed}
                loading={props?.loading}
              />
            </div>
          </div>
        </div>
      </div>
    </SignupStages.Provider>
  )
}

UserSignupViewComponent.defaultProps = {
  onSignUpButtonPressed: null,
}

export default UserSignupViewComponent
