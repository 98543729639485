import ITime from './Time.types'
import { Timestamp } from 'firebase/firestore'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * Returns a UTC timestamp,
 */
const utcTimestamp = () => {
  return Timestamp.now() as unknown as Date
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @return `true` if the date is invalid
 */
const utcComps: ITime['IUtcCompsFn'] = (type, value) => {
  let date = new Date()

  let obj: ITime['IUtcCompsReturnTypeMap'] = {} as any

  if (typeof value !== 'undefined') return value as any

  switch (type) {
    case 'month':
      obj['month'] = date.getUTCDate() + 1
      break

    case 'date':
      obj['date'] = date.toUTCString()
      break

    case 'day':
      obj['day'] = date.getUTCDay()
      break

    case 'year':
      obj['year'] = date.getUTCFullYear()
      break

    case 'hour':
      obj['hour'] = date.getUTCHours()
      break

    case 'minute':
      obj['minute'] = date.getUTCMinutes()
      break

    case 'second':
      obj['second'] = date.getUTCSeconds()
      break

    case 'time':
      obj['time'] = date.getTime()
      break
  }

  return obj[type]
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @return `true` if the date is invalid
 */
const isInvalidDate = (date: any) => {
  let date_ = new Date(date)
  return isNaN(date_.getTime())
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
const TimeLib = {
  utcTimestamp,
  utcComps,
  isInvalidDate,
}

export default TimeLib
