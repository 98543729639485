import { ChevronRight } from '@mui/icons-material'
import { IAllEventListingRootPageTypes as iaelrpt } from './AllEventListingRootPage.types'
import { Link } from 'react-router-dom'
import { CONST } from '../../../const/const'
import { MODAL_CONSTS } from '../../../const/modal-const'
import moment from 'moment'
import { IEventInterface } from '../../../models/events/event.interface'

const maxTagsToShow = 2

const NEW_EVENT_COLS = [
  {
    name: 'Event',
    cell: (row: iaelrpt['INewEvent']) => (
      <Link to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.eventId}`}>
        <div className="flex items-center">
          <span className="xl:w-[45px] xl:h-[45px] 2xl:w-[45px] 2xl:h-[45px] h-[45px] block shrink-0">
            <img
              src={row.eventCompanyLogo}
              alt="icons"
              className="object-cover w-full h-full rounded-full"
              onError={(e) => (e.currentTarget.src = '/assets/placeholders/Emptyuser.png')}
            />
          </span>
          <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold ml-4 capitalize">
            {row.eventName}
          </span>
        </div>
      </Link>
    ),
    // width: "265px"
    width: '30%',
  },
  {
    name: 'Discipline',
    cell: (row: iaelrpt['INewEvent']) => (
      <>
        <img src="assets/img/allevent/Horsessign.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {row.eventDisciplines && row.eventDisciplines.length
            ? [...(row.eventDisciplines ?? [])]
                .splice(0, maxTagsToShow)
                .map(
                  (item: string, index: number) =>
                    `${item}${index + 1 === row.eventDisciplines.length || index + 1 === maxTagsToShow ? '' : ', '}`
                )
            : 'N/A'}
        </p>
      </>
    ),
    // width: "180px"
    width: '20%',
  },
  {
    name: 'Event type',
    cell: (row: iaelrpt['INewEvent']) => (
      <>
        <img src="assets/img/allevent/Laurelwreath.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {row.eventType}{' '}
        </p>
      </>
    ),
    // width: "120px"
    width: '13%',
  },
  {
    name: 'Location',
    cell: (row: iaelrpt['INewEvent']) => (
      <>
        <img src="assets/img/allevent/Location.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {row.eventLocation}{' '}
        </p>
      </>
    ),
    // width: "120px"
    width: '13%',
  },
  {
    name: 'Start date',
    cell: (row: iaelrpt['INewEvent']) => (
      <>
        <img src="assets/img/allevent/Calendar.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {row.eventStartDate?.toString()}{' '}
        </p>
      </>
    ),
    // width: "120px"
    width: '13%',
  },
  {
    name: 'Going',
    cell: (row: iaelrpt['INewEvent']) => {
      //  console.log("row: ", row)
      return (
        <>
          <img src="assets/img/allevent/Testpassed.svg" alt="icons" />
          <p
            className="text-SeabiscuitDark200ThemeColor ml-2 font-normal underline cursor-pointer"
            onClick={() => {
              row?.handleModal?.(true, MODAL_CONSTS.REGISTER_EXHIBITORS, {
                eventClicked: {
                  eventId: row?.eventId ?? null,
                },
              })
            }}
          >
            {row.eventAttendees + ' ppl'}
          </p>
        </>
      )
    },
    // width: "100px"
    width: '11%',
  },
  // {
  //     name: '',
  //     cell: (row: iaelrpt["INewEvent"]) => <>
  //         <Link
  //             to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.eventId}`}
  //             className="flex w-[100px] h-[45px] mx-auto items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-[14px] text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
  //         >
  //             More
  //             <ChevronRight sx={{ fontSize: 16 }
  //             } />
  //         </Link>
  //     </>,
  //     width: "100px"
  // },
  // },
]

const GET_EVENT_RESULT_COLS_FN = (props: any) => {
  return [
    {
      name: '',
      cell: (row: any) => (
        <div className="flex items-center">
          <span className="xl:w-[45px] xl:h-[45px] 2xl:w-[45px] 2xl:h-[45px] h-[45px] block shrink-0">
            <img
              alt="icons"
              src={row.image}
              className="object-cover w-full h-full rounded-full"
              onError={(e) => (e.currentTarget.src = '/assets/placeholders/Emptyuser.png')}
            />
          </span>
          <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold ml-2 capitalize">
            {row.title}
          </span>
        </div>
      ),
      width: '220px',
    },
    {
      name: 'Discipline',
      cell: (row: any) => (
        <>
          <img alt="icons" src="assets/img/allevent/Horsessign.svg" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.discipline && row.discipline.length
              ? row.discipline.map(
                  (item: string, index: number) =>
                    `${item}${index + 1 === row.discipline.length ? '' : ', '}`
                )
              : 'N/A'}
          </p>
        </>
      ),
      width: '120px',
    },
    {
      name: 'Event type',
      cell: (row: any) => (
        <>
          <img alt="icons" src="assets/img/allevent/Laurelwreath.svg" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.eventType}
          </p>
        </>
      ),
      width: '120px',
    },
    {
      name: 'Location',
      cell: (row: any) => (
        <>
          <img alt="icons" src="assets/img/allevent/Location.svg" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.location}
          </p>
        </>
      ),
      width: '120px',
    },
    {
      name: 'Date',
      cell: (row: any) => (
        <>
          <img alt="icons" src="assets/img/allevent/Calendar.svg" />
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
            {row.startDate}
          </p>
        </>
      ),
      width: '120px',
    },
    {
      name: 'Results',
      cell: (row: any) => (
        <div
          className="flex items-center justify-center cursor-pointer"
          onClick={() => props.handleModal(true, 'resultViewButton')}
        >
          <div className="rounded-full bg-black text-SeabiscuitWhiteThemeColor text-center w-6 h-6 font-semibold flex items-center justify-center cursor-pointer">
            1
          </div>
          <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal cursor-pointer">
            {row.classesEntered}
          </p>
        </div>
      ),
      width: '120px',
    },
    {
      name: '',
      cell: (row: any) => (
        <>
          <Link
            to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.id}`}
            className="flex w-[100px] h-[45px] mx-auto items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
          >
            {row.eventBtn}
            <ChevronRight sx={{ fontSize: 16 }} />
          </Link>
        </>
      ),
      width: '120px',
    },
  ]
}

const PAST_EVENT_COLS = [
  {
    name: '',
    cell: (row: iaelrpt['IPastEvent']) => (
      <div className="flex items-center">
        <span className="xl:w-[45px] xl:h-[45px] 2xl:w-[45px] 2xl:h-[45px] h-[45px] block shrink-0">
          <img
            alt="icons"
            src={row.eventCompanyLogo}
            className="object-cover w-full h-full rounded-full"
            onError={(e) => (e.currentTarget.src = '/assets/placeholders/Emptyuser.png')}
          />
        </span>
        <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold ml-2 capitalize">
          {row.eventName}
        </span>
      </div>
    ),
    width: '220px',
  },
  {
    name: 'Discipline',
    cell: (row: iaelrpt['IPastEvent']) => (
      <>
        <img alt="icons" src="assets/img/allevent/Horsessign.svg" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {row.eventDisciplines && row.eventDisciplines.length
            ? [...(row.eventDisciplines ?? [])]
                .splice(0, maxTagsToShow)
                .map(
                  (item: string, index: number) =>
                    `${item}${index + 1 === row.eventDisciplines.length || index + 1 === maxTagsToShow ? '' : ', '}`
                )
            : 'N/A'}
        </p>
      </>
    ),
    width: '120px',
  },
  {
    name: 'Event type',
    cell: (row: iaelrpt['IPastEvent']) => (
      <>
        <img alt="icons" src="assets/img/allevent/Laurelwreath.svg" />
        <p className="text-SeabiscuitDark200ThemeColor ml-[10px] capitalize font-normal">
          {row.eventType}
        </p>
      </>
    ),
    width: '120px',
  },
  {
    name: 'Location',
    cell: (row: iaelrpt['IPastEvent']) => (
      <>
        <img alt="icons" src="assets/img/allevent/Location.svg" />
        <p className="text-SeabiscuitDark200ThemeColor ml-[10px] capitalize font-normal">
          {row.eventLocation}
        </p>
      </>
    ),
    width: '120px',
  },
  {
    name: 'Start Date',
    cell: (row: iaelrpt['IPastEvent']) => (
      <>
        <img alt="icons" src="assets/img/allevent/Calendar.svg" />
        <p className="text-SeabiscuitDark200ThemeColor ml-[10px] capitalize font-normal">
          {row.eventStartDate?.toString()}
        </p>
      </>
    ),
    width: '120px',
  },
  {
    name: 'Attendees',
    cell: (row: iaelrpt['IPastEvent']) => (
      <div className="flex items-center justify-center">
        <img alt="icons" src="assets/img/light/People.svg" />
        <p className="text-SeabiscuitDark200ThemeColor ml-[10px] capitalize font-normal">
          {row.eventAttendees}
        </p>
      </div>
    ),
    width: '120px',
  },
  {
    name: '',
    cell: (row: iaelrpt['IPastEvent']) => (
      <>
        <Link
          to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.eventId}`}
          className="flex w-[100px] h-[45px] mx-auto items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
        >
          More
          <ChevronRight sx={{ fontSize: 16 }} />
        </Link>
      </>
    ),
    width: '120px',
  },
]

const ORGANIZER_NEW_EVENT_COLS = [
  {
    name: 'Event',
    cell: (row: iaelrpt['INewEvent']) => (
      <Link to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.eventId}`}>
        <div className="flex items-center">
          <span className="xl:w-[45px] xl:h-[45px] 2xl:w-[45px] 2xl:h-[45px] h-[45px] block shrink-0">
            <img
              src={row.eventCompanyLogo}
              alt="icons"
              className="object-cover w-full h-full rounded-full"
              onError={(e) => (e.currentTarget.src = '/assets/placeholders/Emptyuser.png')}
            />
          </span>
          <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold ml-4 capitalize">
            {row.eventName}
          </span>
        </div>
      </Link>
    ),
    // width: "220px"
    width: '33%',
  },
  {
    name: 'Discipline',
    cell: (row: iaelrpt['INewEvent']) => (
      <>
        <img src="assets/img/allevent/Horsessign.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {row.eventDisciplines && row.eventDisciplines.length
            ? [...(row.eventDisciplines ?? [])]
                .splice(0, maxTagsToShow)
                .map(
                  (item: string, index: number) =>
                    `${item}${index + 1 === row.eventDisciplines.length || index + 1 === maxTagsToShow ? '' : ', '}`
                )
            : 'N/A'}
        </p>
      </>
    ),
    // width: "220px"
    width: '22%',
  },
  {
    name: 'Event type',
    cell: (row: iaelrpt['INewEvent']) => (
      <>
        <img src="assets/img/allevent/Laurelwreath.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {row.eventType}{' '}
        </p>
      </>
    ),
    // width: "150px"
    width: '18%',
  },
  {
    name: 'Start date',
    cell: (row: iaelrpt['INewEvent']) => (
      <>
        <img src="assets/img/allevent/Calendar.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {row.eventStartDate?.toString()}
        </p>
      </>
    ),
    // width: "150px"
    width: '16%',
  },
  // {
  //     name: 'Start date',
  //     cell: (row: iaelrpt["INewEvent"]) => <>
  //         <img src="assets/img/allevent/Calendar.svg" alt="icons" />
  //         <p className='text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal' > {row.eventRegistrationCloseDate?.toString()} </p>
  //     </>,
  //     // width: "150px"
  //     width: '13%'
  // },
  {
    name: 'Spots left',
    cell: (row: iaelrpt['INewEvent']) => (
      <>
        <img src="/assets/og_icons/Two Tickets.svg" alt="icons" />
        <p
          className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal underline cursor-pointer"
          onClick={() => {
            row?.handleModal?.(true, MODAL_CONSTS.REGISTER_EXHIBITORS, {
              eventClicked: {
                eventId: row?.eventId ?? null,
              },
            })
          }}
        >
          {row.spotsAvailable - row.eventAttendees}
        </p>
      </>
    ),
    // width: "150px"
    width: '10%',
  },
  // {
  //     name: '',
  //     cell: (row: iaelrpt["INewEvent"]) => <>
  //         <Link
  //             to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.eventId}`}
  //             className="flex w-[100px] h-[45px] mx-auto items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-[14px] text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
  //         >
  //             More
  //             <ChevronRight sx={{ fontSize: 16 }
  //             } />
  //         </Link>
  //     </>,
  //     width: "150px"
  // },
]

const ORGANIZER_PAST_EVENT_COLS = [
  {
    name: 'Event',
    cell: (row: iaelrpt['INewEvent']) => (
      <div className="flex items-center">
        <span className="xl:w-[45px] xl:h-[45px] 2xl:w-[45px] 2xl:h-[45px] h-[45px] block shrink-0">
          <img
            src={row.eventCompanyLogo}
            alt="icons"
            className="object-cover w-full h-full rounded-full"
            onError={(e) => (e.currentTarget.src = '/assets/placeholders/Emptyuser.png')}
          />
        </span>
        <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold ml-2 capitalize">
          {row.eventName}
        </span>
      </div>
    ),
    width: '220px',
  },
  {
    name: 'Discipline',
    cell: (row: iaelrpt['INewEvent']) => (
      <>
        <img src="assets/img/allevent/Horsessign.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {row.eventDisciplines && row.eventDisciplines.length
            ? [...(row.eventDisciplines ?? [])]
                .splice(0, maxTagsToShow)
                .map(
                  (item: string, index: number) =>
                    `${item}${index + 1 === row.eventDisciplines.length || index + 1 === maxTagsToShow ? '' : ', '}`
                )
            : 'N/A'}
        </p>
      </>
    ),
    width: '220px',
  },
  {
    name: 'Event type',
    cell: (row: iaelrpt['INewEvent']) => (
      <>
        <img src="assets/img/allevent/Laurelwreath.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {row.eventType}{' '}
        </p>
      </>
    ),
    width: '150px',
  },
  {
    name: 'Start date',
    cell: (row: iaelrpt['INewEvent']) => (
      <>
        <img src="assets/img/allevent/Calendar.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {row.eventStartDate?.toString()}{' '}
        </p>
      </>
    ),
    width: '150px',
  },
  {
    name: 'Attendees',
    cell: (row: iaelrpt['INewEvent']) => (
      <>
        <img src="assets/img/allevent/Testpassed.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">View</p>
      </>
    ),
    width: '150px',
  },
  {
    name: '',
    cell: (row: iaelrpt['INewEvent']) => (
      <>
        <Link
          to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.eventId}`}
          className="flex w-[100px] h-[45px] mx-auto items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-[14px] text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
        >
          More
          <ChevronRight sx={{ fontSize: 16 }} />
        </Link>
      </>
    ),
    width: '150px',
  },
]

const HORSE_PROFILE_NEW_COLS = [
  // {
  //     name: 'Event',
  //     cell: (row: IEventInterface) => (
  //         <div className="flex items-center" >
  //             <span className="xl:w-[45px] xl:h-[45px] 2xl:w-[45px] 2xl:h-[45px] h-[45px] block shrink-0">
  //                 <img src={row.eventCardCoverImageUrl} alt="icons" className="object-cover w-full h-full rounded-full" onError={(e) => e.currentTarget.src = "/assets/placeholders/Emptyuser.png"} />
  //             </span>
  //             <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold ml-2 capitalize">
  //                 {row.eventName}
  //             </span>
  //         </div>),
  // },
  // {
  //     name: 'Discipline',
  //     cell: (row: IEventInterface) => <>
  //         <img src="assets/img/allevent/Horsessign.svg" alt="icons" />
  //         <p className='text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal' > {row.tags && row.tags.length ? [...(row.tags ?? [])].splice(0, maxTagsToShow).map((item: string, index: number) => `${item}${((index + 1) === row?.tags?.length || (index + 1) === maxTagsToShow) ? "" : ", "}`) : "N/A"}
  //         </p>
  //     </>,
  // },
  // {
  //     name: 'Event type',
  //     cell: (row: IEventInterface) => <>
  //         <img src="assets/img/allevent/Laurelwreath.svg" alt="icons" />
  //         <p className='text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal' > {row.type} </p>
  //     </>,
  // },
  // {
  //     name: 'Start date',
  //     cell: (row: IEventInterface) => <>
  //         <img src="assets/img/allevent/Calendar.svg" alt="icons" />
  //         <p className='text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal' > {moment(row.eventStartDate).format("MM.DD.YY")} </p>
  //     </>,
  // },
  // {
  //     name: '',
  //     cell: (row: IEventInterface) => <>
  //         <Link
  //             to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.id}`}
  //             className="flex w-[100px] h-[45px] mx-auto items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-[14px] text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
  //         >
  //             More
  //             <ChevronRight sx={{ fontSize: 16 }
  //             } />
  //         </Link>

  //     </>,
  // },

  {
    name: 'Event',
    cell: (row: IEventInterface) => {
      console.log('row: ', row)
      return (
        <Link to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.id}`}>
          <div className="flex items-center">
            <span className="xl:w-[45px] xl:h-[45px] 2xl:w-[45px] 2xl:h-[45px] h-[45px] block shrink-0">
              <img
                src={row.eventCardCoverImageUrl}
                alt="icons"
                className="object-cover w-full h-full rounded-full"
                onError={(e) => (e.currentTarget.src = '/assets/placeholders/Emptyuser.png')}
              />
            </span>
            <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold ml-4 capitalize">
              {row.eventName}
            </span>
          </div>
        </Link>
      )
    },
    // width: "265px"
    width: '30%',
  },
  {
    name: 'Discipline',
    cell: (row: IEventInterface) => (
      <>
        <img src="assets/img/allevent/Horsessign.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {row.tags && row.tags.length
            ? [...(row.tags ?? [])]
                .splice(0, maxTagsToShow)
                .map(
                  (item: string, index: number) =>
                    `${item}${index + 1 === row?.tags?.length || index + 1 === maxTagsToShow ? '' : ', '}`
                )
            : 'N/A'}
        </p>
      </>
    ),
    // width: "180px"
    width: '20%',
  },
  {
    name: 'Event type',
    cell: (row: IEventInterface) => (
      <>
        <img src="assets/img/allevent/Laurelwreath.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {row.category}{' '}
        </p>
      </>
    ),
    // width: "120px"
    width: '13%',
  },
  {
    name: 'Location',
    cell: (row: IEventInterface) => (
      <>
        <img src="assets/img/allevent/Location.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal"> {row.city} </p>
      </>
    ),
    // width: "120px"
    width: '13%',
  },
  {
    name: 'Start date',
    cell: (row: IEventInterface) => (
      <>
        <img src="assets/img/allevent/Calendar.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {moment(row.eventStartDate).format('MMM DD')}{' '}
        </p>
      </>
    ),
    // width: "120px"
    width: '13%',
  },
  {
    name: 'Going',
    cell: (row: IEventInterface) => {
      //  console.log("row: ", row)
      return (
        <>
          <img src="assets/img/allevent/Testpassed.svg" alt="icons" />
          <p
            className="text-SeabiscuitDark200ThemeColor ml-2 font-normal"
            // onClick={() => {
            //     row?.handleModal?.(
            //         true,
            //         MODAL_CONSTS.REGISTER_EXHIBITORS,
            //         {
            //             eventClicked: {
            //                 eventId: row?.id ?? null
            //             }
            //         }
            //     )
            // }}>
          >
            {row.registeredCountIncludingUnpaid + ' ppl'}
          </p>
        </>
      )
    },
    // width: "100px"
    width: '11%',
  },
]

const HORSE_PROFILE_PAST_COLS = [
  {
    name: 'Event',
    cell: (row: IEventInterface) => (
      <div className="flex items-center">
        <span className="xl:w-[45px] xl:h-[45px] 2xl:w-[45px] 2xl:h-[45px] h-[45px] block shrink-0">
          <img
            src={row.eventCardCoverImageUrl}
            alt="icons"
            className="object-cover w-full h-full rounded-full"
            onError={(e) => (e.currentTarget.src = '/assets/placeholders/Emptyuser.png')}
          />
        </span>
        <span className="text-[14px] text-SeabiscuitDark200ThemeColor font-bold ml-2 capitalize">
          {row.eventName}
        </span>
      </div>
    ),
    width: '220px',
  },
  {
    name: 'Discipline',
    cell: (row: IEventInterface) => (
      <>
        <img src="assets/img/allevent/Horsessign.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {row.tags && row.tags.length
            ? [...(row.tags ?? [])]
                .splice(0, maxTagsToShow)
                .map(
                  (item: string, index: number) =>
                    `${item}${index + 1 === row?.tags?.length || index + 1 === maxTagsToShow ? '' : ', '}`
                )
            : 'N/A'}
        </p>
      </>
    ),
    width: '220px',
  },
  {
    name: 'Event type',
    cell: (row: IEventInterface) => (
      <>
        <img src="assets/img/allevent/Laurelwreath.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal"> {row.type} </p>
      </>
    ),
    width: '150px',
  },
  {
    name: 'Start date',
    cell: (row: IEventInterface) => (
      <>
        <img src="assets/img/allevent/Calendar.svg" alt="icons" />
        <p className="text-SeabiscuitDark200ThemeColor ml-2 capitalize font-normal">
          {' '}
          {moment(row.eventStartDate).format('MM.DD.YY')}{' '}
        </p>
      </>
    ),
    width: '150px',
  },
  {
    name: '',
    cell: (row: IEventInterface) => (
      <>
        <Link
          to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${row.id}`}
          className="flex w-[100px] h-[45px] mx-auto items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-[14px] text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
        >
          More
          <ChevronRight sx={{ fontSize: 16 }} />
        </Link>
      </>
    ),
    width: '150px',
  },
]

const COLS = {
  NEW_EVENT_COLS,
  PAST_EVENT_COLS,
  HORSE_PROFILE_PAST_COLS,
  HORSE_PROFILE_NEW_COLS,
  ORGANIZER_PAST_EVENT_COLS,
  ORGANIZER_NEW_EVENT_COLS,
  GET_EVENT_RESULT_COLS_FN,
}

export const ALL_EVENT_LISTING_ROOT_PAGE_DATA = {
  COLS,
}
