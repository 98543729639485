import { TableStyles } from 'react-data-table-component'
import { LG_WIDTH, MD_WIDTH } from '../../../helpers/mdWidth'

export const customStyles: TableStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#D3DAEE',
      },
    },
  },
  headCells: {
    style: {
      paddingLeft: '0px', // override the cell padding for head cells
      paddingRight: '0px',
      color: '#122B46',
      fontWeight: '600',
      fontSize: '12px',
    },
  },
  headRow: {
    style: {
      minHeight: 'unset',
      borderColor: '#D3DAEE',
    },
  },
  cells: {
    style: {
      paddingLeft: '0px', // override the cell padding for data cells
      paddingRight: '0px',
      Text: 'center',
    },
  },
}

export const customResponsiveStyles = (width: number): TableStyles => {
  const cellStyles: any = {
    paddingLeft: '0px', // override the cell padding for data cells
    paddingRight: '0px',
    Text: 'center',
  }

  const tableStyles: any = {}

  const headCells: any = {
    paddingLeft: '0px',
    paddingRight: '0px',
    color: '#122B46',
    fontWeight: '600',
    fontSize: '12px',
  }
  const headRowStyles: any = {
    minHeight: 'unset',
    borderColor: '#D3DAEE',
  }

  const rowsStyles: any = {
    minHeight: '72px',
    '&:not(:last-of-type)': {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: '#D3DAEE',
    },
  }

  if (width < MD_WIDTH) {
    rowsStyles.display = 'block'
    rowsStyles.paddingTop = '10px'
    rowsStyles.paddingBottom = '10px'

    cellStyles.minWidth = '100% !important'
    cellStyles.maxWidth = '100%  !important'
    cellStyles.width = '100%  !important'
    cellStyles.marginTop = '10px'
    cellStyles.marginBottom = '10px'

    headRowStyles.display = 'none'
  }

  if (width > MD_WIDTH) {
    tableStyles.minWidth = '1000px !important'
  }

  return {
    table: {
      style: tableStyles,
    },
    rows: {
      style: rowsStyles,
    },
    headCells: {
      style: headCells,
    },
    headRow: {
      style: headRowStyles,
    },
    cells: {
      style: cellStyles,
    },
  }
}

export const customStylesNoBorder: TableStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
      '&:not(:last-of-type)': {
        border: 'none',
      },
    },
  },
  headCells: {
    style: {
      paddingLeft: '0px', // override the cell padding for head cells
      paddingRight: '0px',
      color: '#122B46',
      fontWeight: '600',
      fontSize: '12px',
    },
  },
  headRow: {
    style: {
      minHeight: 'unset',
      borderColor: '#D3DAEE',
    },
  },
  cells: {
    style: {
      paddingLeft: '0px', // override the cell padding for data cells
      paddingRight: '0px',
      Text: 'center',
    },
  },
}
