import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import Accordion from '../../../../../components/accordion/common/Accordion'
import ITypes from './StaffPaperworkTab.types'
import clsx from 'clsx'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const StaffPaperworkTab = (props: ITypes['StaffPaperworkTabProps']) => {
  // Hooks and vars
  const UNKNOWN = 'Unknown'

  let classNames = ''
  let signed = false
  let commonClasses =
    'bg-SeabiscuitGrayThemeColor text-SeabiscuitDark200ThemeColor px-3 py-3 rounded-md mb-2 text-nr ellipsis flex flex-wrap'
  let documentName = UNKNOWN

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onViewDocumentClick = (documentUrl: string | undefined) => {
    if (!!!documentUrl) return

    props.openLightBox([
      {
        src: documentUrl ?? '',
      },
    ])
  }

  return (
    <Accordion
      icon={<AddCircleOutline className="add_icon text-[red]" />}
      header={`${props.staffName ?? UNKNOWN}'s paperwork`}
      headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize"
      activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
    >
      {props.documents.length ? (
        props.documents.map((currDocument, index) => {
          classNames = currDocument.status === 'Not Signed' ? '!bg-[#EB57571A] !text-[#EB5757]' : ''
          signed = currDocument.status === 'Signed'
          documentName = UNKNOWN

          if (!!currDocument.documentOriginalName) {
            documentName = currDocument.documentOriginalName?.toLocaleLowerCase()
            documentName =
              documentName.substring(0, 1)?.toLocaleUpperCase() + documentName?.substring(1)
          }

          return (
            <div
              key={`${JSON.stringify(currDocument)}${index}`}
              className="flex gap-3 text-base flex-wrap"
            >
              <div className={clsx('flex-grow', classNames, commonClasses)}>{documentName}</div>

              <div
                className={clsx(
                  'min-w-fit md:w-[200px]',
                  classNames,
                  commonClasses,
                  !signed ? 'justify-center' : 'cursor-pointer'
                )}
                onClick={() => {
                  if (currDocument.status === 'Signed') {
                    onViewDocumentClick(currDocument.documentUrl)
                  }
                }}
              >
                {signed ? (
                  <img
                    src="/assets/og_icons/FullScreen-1.svg"
                    className="w-6 h-6 mr-2"
                    alt="fullScreen"
                  />
                ) : null}
                {signed ? 'View Document' : 'N/A'}
              </div>

              <div
                className={clsx(
                  'min-w-fit md:w-[200px]',
                  classNames,
                  commonClasses,
                  'relative pr-8'
                )}
              >
                {currDocument.status ?? UNKNOWN}
                <img
                  src={`/assets/og_icons/${signed ? 'Ok-3' : 'Cancel-2'}.svg`}
                  className="w-6 h-6 mr-2 absolute right-1"
                  alt={'fullScreen'}
                />
              </div>
            </div>
          )
        })
      ) : (
        <div className="text-nr">No paperworks found</div>
      )}
    </Accordion>
  )
}

export default StaffPaperworkTab
