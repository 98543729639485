import { DocumentData, QueryDocumentSnapshot, where } from 'firebase/firestore'
import { cloneDeep, sumBy } from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import helpers from '../../../../../commonHelpers/helpers'
import { CONST } from '../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import { CustomError, getUserFullName } from '../../../../../helpers/helpers'
import useGetBasicEventDetails from '../../../../../hooks/users/common/useGetBasicEventDetails'
import { EventDetailsModel } from '../../../../../models/event-details/event-details.model'
import {
  IFeesTab,
  IPayTab,
  IRegisterTabItem,
  IRegistrationTabs,
  ISummaryRecipient,
  ITicketTab,
  TEventRegisteredUsers,
} from '../../../../../models/event-registered-users/event-registered-users.interface'
import { EventRegisteredUsersModel } from '../../../../../models/event-registered-users/event-registered-users.model'
import { IEventReviewPublish } from '../../../../../models/event-review-publish/event-review-publish.interface'
import { EventReviewPublishModel } from '../../../../../models/event-review-publish/event-review-publish.model'
import {
  getConvertedData,
  getSelectedUserAsTeamMember,
  getSerializedRiderTeamMembers,
  getUserAsTeamMember,
} from '../../../../../models/interface.helper'
import { IRegistrationFeesInterface } from '../../../../../models/registration-fees/registrationFees.interface'
import { RegistrationFeesModel } from '../../../../../models/registration-fees/registrationFees.model'
import { IRegistrationTicketInterface } from '../../../../../models/registration-tickets/registrationTicket.interface'
import { RegistrationTicketModel } from '../../../../../models/registration-tickets/registrationTicket.model'
import { IRegistrationByDayInterface } from '../../../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationByDayModel } from '../../../../../models/registrations-by-day/registrationByDay.model'
import { ITeamMember, IUserInterface } from '../../../../../models/users/user.interface'
import { UserModel } from '../../../../../models/users/user.model'
import FirestoreService from '../../../../../services/firestoreService'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'

import ITypes from '../useEventRegistrationTabs.types'
import IUseEventRegistrationTabsTypes from '../useEventRegistrationTabs.types'

import { useForm } from 'react-hook-form'
import { FEES_CATEGORY_CONST } from '../../../../../components/events/views/details/EventDetailsViewComponentFees'
import fakeDocumentList from '../../../../../fakeData/fakeDocumentList'
import {
  IEventFees,
  RegistrationFeesType,
} from '../../../../../models/event-fees/event-fees.interface'
import { RecipientModel } from '../../../../../models/recipients/recipients'
import { IRecipientInterface } from '../../../../../models/recipients/recipients.interface'
import IRegistrationTeamsTypes, {
  IRegistrationTeamInterface,
} from '../../../../../models/registeration-teams/registration-teams.interface'
import { RegistrationTeamModel } from '../../../../../models/registeration-teams/registration-teams.models'
import { IRiderTeamMemberInterface } from '../../../../../models/rider-team-member/riderTeamMember.interface'
import { RiderTeamMemberModel } from '../../../../../models/rider-team-member/riderTeamMember.model'
import {
  selectedEvent as selectedEventGetter,
  selectEventPaymentSettings,
} from '../../../../../store/events/eventsSlice'
import { updateUsersHolderAc } from '../../../../../store/holders/holdersSlice'
import {
  selectActiveRegistrationTab,
  selectFeesTabDataR,
  selectFilteredPaperworkDocuments,
  selectPaperworkTabData,
  selectPayTabFeesAccordion,
  selectPayTabGovernanceFeesAccordion,
  selectPayTabGrandTotalAccordion,
  selectPayTabRegistrationAccordion,
  selectPayTabSummaryAccordion,
  selectPayTabTicketAccordion,
  selectRecipientsInDb,
  selectRegisterTabData,
  selectRegistration,
  selectRegistrationByDay,
  selectRegistrationFeesR,
  selectRegistrationLoading,
  selectRegistrationTicketsR,
  selectTeamTabData,
  selectTicketTabData,
  selectUserTeamMembersR,
  setFeesTabDataAc,
  setFilteredPaperworkDocuments,
  setPaperworkTabData,
  setRecipientsInDb,
  setRegisterTabData,
  setRegistrationAc,
  setRegistrationLoading,
  setRegistrationsByDay,
  setRegistrationsFeesAc,
  setRegistrationsTicketsAc,
  setRidersTeamMembers,
  setRidersTeamMembersInDb,
  setTeamTabData,
  setTicketTabData,
  setUserTeamMembers,
  updatePayTabFeesAccordion,
  updatePayTabGovernanceFeesAccordion,
  updatePayTabGrandTotalAccordion,
  updatePayTabPaperworkAccordion,
  updatePayTabRegisterAccordion,
  updatePayTabSummaryAccordion,
  updatePayTabTicketAccordion,
  updateTicketTabRowInReduxAc,
} from '../../../../../store/registration/registrationSlice'
import {
  competitorEventRegisterHelper as cerh,
  ICompetitorEventRegisterTypes as ICerhTypes,
} from '../../../competitorEventRegister/competitorEventRegisterHelper'
import { IManageInfo } from '../../EventRegistrationTabs'
import { getFilteredPaperworks } from '../../../../../helpers/documents'
import UserService from '../../../../../services/userService'
import { selectHorses } from '../../../../../store/horses/horseSlice'
import { IUserDocument } from '../../../../../models/user-documents/user-documents.interface'
import { UserDocumentModel } from '../../../../../models/user-documents/user-documents.model'

// Constants
const FILE_NAME = 'useEventRegistrationTabs'
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}
const feesItemsDefaultValue: Record<string, number> = {}
const ticketItemsDefaultValue: Record<string, number> = {}

export interface ISaveTicketTabData {
  currentRow: ITicketTab
  isManage?: boolean
  setManageInfo: (value: IManageInfo) => void
  manageInfo: IManageInfo
}

export interface IOnChangeFees {
  currentRow: IFeesTab
  isManage?: boolean
  setManageInfo: (value: IManageInfo) => void
  manageInfo: IManageInfo
}

const useEventRegistrationTabs = () => {
  // Hooks and vars
  const dispatch = useAppDispatch()

  const ticketItemsForm = useForm({
    mode: 'onChange',
    defaultValues: ticketItemsDefaultValue,
  })

  const feesItemsForm = useForm({
    mode: 'onChange',
    defaultValues: feesItemsDefaultValue,
  })
  const toastFunctions = useToasterHelper()
  const feesTabData = useAppSelector(selectFeesTabDataR)
  const registration = useAppSelector(selectRegistration)
  const ticketTabData = useAppSelector(selectTicketTabData)
  const loading = useAppSelector(selectRegistrationLoading)
  const userHorses = useAppSelector(selectHorses)
  const recipientsInDb = useAppSelector(selectRecipientsInDb)
  const registerTabData = useAppSelector(selectRegisterTabData)
  const registrationFees = useAppSelector(selectRegistrationFeesR)
  const teamMembersWithUser = useAppSelector(selectUserTeamMembersR)
  const teamMembers = useAppSelector(selectTeamTabData)
  const registrationsByDay = useAppSelector(selectRegistrationByDay)
  const feesAccordionData = useAppSelector(selectPayTabFeesAccordion)
  const registrationTickets = useAppSelector(selectRegistrationTicketsR)
  const ticketAccordionData = useAppSelector(selectPayTabTicketAccordion)
  const eventPaymentSettings = useAppSelector(selectEventPaymentSettings)
  const summaryAccordionData = useAppSelector(selectPayTabSummaryAccordion)
  const activeRegistrationTab = useAppSelector(selectActiveRegistrationTab)
  const grandTotalAccordionData = useAppSelector(selectPayTabGrandTotalAccordion)
  const registrationAccordionData = useAppSelector(selectPayTabRegistrationAccordion)
  const governanceFeesAccordionData = useAppSelector(selectPayTabGovernanceFeesAccordion)
  const paperworkTabData = useAppSelector(selectPaperworkTabData)
  const filteredPaperworkDocuments = useAppSelector(selectFilteredPaperworkDocuments)
  const selectedEvent = useAppSelector(selectedEventGetter)

  const { eventId, userId } = useParams<ITypes['IParams']>()

  const basicEventDetails = useGetBasicEventDetails(eventId)

  const [publishedEventInDb, setPublishedEventInDb] = useState<IEventReviewPublish | null>(null)
  const [isManage, setIsManage] = useState<boolean>(false)
  const [registeredUser, setRegisteredUser] = useState<IUserInterface | null>(null)

  const getUniqueTeamMembers = async ({
    currentTeamTabData,
    registeredUser,
  }: {
    currentTeamTabData: IRegistrationTeamInterface[]
    registeredUser: IUserInterface
  }) => {
    let teamMembers_: IRegistrationTeamInterface[] = []

    const owner = getConvertedData(getSelectedUserAsTeamMember(registeredUser, registeredUser))
    if (registration?.id) {
      getTeamMembersFromDb(registration.id, registeredUser).then((currTeamMembers) => {
        teamMembers_ = [...currTeamMembers]
      })
    }

    const teamMembersData = [owner, ...teamMembers_, ...currentTeamTabData]

    dispatch(setUserTeamMembers(teamMembersData))
  }

  const getCurrentUser = async () => {
    const userSnaps = await UserService.getUserInfoById(userId)
    const user = UserModel.fromFirestoreDoc(userSnaps).toObject()
    setRegisteredUser(user)
  }
  useEffect(() => {
    if (userId) getCurrentUser().then()
  }, [userId])

  useEffect(() => {
    if (registeredUser) getUniqueTeamMembers({ currentTeamTabData: teamMembers, registeredUser })
  }, [registration?.id, teamMembers, registeredUser])

  useEffect(() => {
    if (loading || !registeredUser) return

    let owner = getUserAsTeamMember(registeredUser)
    let foundOwner: null | ITeamMember =
      teamMembersWithUser.find((currTeamMember) => {
        return currTeamMember.memberId === owner.memberId
      }) ?? owner

    let updatedOwner: boolean = JSON.stringify(owner) !== JSON.stringify(foundOwner)

    if (!updatedOwner) return
  }, [registeredUser, teamMembersWithUser, loading])

  useEffect(() => {
    let userIdsToFetch: string[] = []

    teamMembersWithUser.forEach((currTeamMember) => {
      if (!!currTeamMember.memberId) userIdsToFetch.push(currTeamMember.memberId)
    })

    dispatch(updateUsersHolderAc(userIdsToFetch))
  }, [dispatch, teamMembersWithUser])

  useEffect(() => {
    dispatch(setRegistrationLoading(true))
    if (!eventId || !userId || !registeredUser) return
    load().then()
  }, [eventId, userId, registeredUser, registration?.id])

  useEffect(() => {
    let totalHorseCount
    let totalMemberCount
    let uniqueHorseCount
    let uniqueMemberCount
    let totalRecipientCount = 0
    let uniqueRecipientCount
    let horseIds: string[] = []
    let memberIds: string[] = []
    let recipientIds: string[] = []
    let totalRegistrationCount = 0
    let registrationPrice: number = 0
    let qualifyFee = 0

    const registrationsByDay_ = (registerTabData ?? []).flatMap((currRegistrationRow) => {
      currRegistrationRow.registrationsByDay.forEach((currRegistrationByDay) => {
        if (currRegistrationByDay.id && currRegistrationByDay.riderId) {
          if (currRegistrationByDay.horseId) horseIds.push(currRegistrationByDay.horseId!)

          memberIds.push(currRegistrationByDay.riderId)
          registrationPrice += currRegistrationByDay.registrationPrice ?? 0
          totalRegistrationCount++

          if (currRegistrationByDay.isQualify)
            qualifyFee += Number(currRegistrationByDay.qualifyFee)

          if (currRegistrationByDay.recipientId) {
            totalRecipientCount++
            recipientIds.push(currRegistrationByDay.recipientId)
          }
        }
      })
      return currRegistrationRow.registrationsByDay
    })

    totalHorseCount = totalMemberCount = totalRegistrationCount
    uniqueHorseCount = new Set(horseIds).size
    uniqueMemberCount = new Set(memberIds).size
    recipientIds = [...new Set(recipientIds)]
    uniqueRecipientCount = recipientIds.length

    dispatch(
      updatePayTabRegisterAccordion({
        totalPrice: registrationPrice + qualifyFee,
        totalHorseCount,
        totalMemberCount,
        uniqueHorseCount,
        uniqueMemberCount,
        recipientsCount: totalRecipientCount,
        totalRegistrationCount,
        totalItemsCount: totalRegistrationCount,
        uniqueRecipientCount,
        recipientIds,
      })
    )

    dispatch(setRegistrationsByDay(registrationsByDay_))
  }, [registerTabData])

  useEffect(() => {
    let count = 0
    let index = -1
    let memberPreAdded = false
    let signatoryIdsList: string[] = []
    let signTabPaperworkAccordion: IPayTab['paperwork']['data'] = []

    filteredPaperworkDocuments.forEach((currPaperworkDocument) => {
      count = 0
      index = signTabPaperworkAccordion.findIndex((curr) => curr.key === currPaperworkDocument.key)

      if (index === -1) {
        signTabPaperworkAccordion.push({
          signatories: [],
          signatoryCount: 0,
          key: currPaperworkDocument.key,
          documentName: currPaperworkDocument.document_name,
        })
        index = signTabPaperworkAccordion.length - 1
      }

      paperworkTabData.forEach((currPaperworkTabRow) => {
        currPaperworkTabRow.ridersTeamMembers.forEach((currRiderTeamMember) => {
          currRiderTeamMember.mailLog.forEach((currMailLog) => {
            if (currMailLog.paperworkKey === currPaperworkDocument.key) {
              if (currRiderTeamMember.teamMemberId) {
                signatoryIdsList.push(currRiderTeamMember.teamMemberId)
              }

              memberPreAdded = !!(signTabPaperworkAccordion[index]?.signatories ?? []).find(
                (currSignatory) => {
                  return currSignatory.teamMemberId === currRiderTeamMember.teamMemberId
                }
              )

              signTabPaperworkAccordion[index] = {
                ...signTabPaperworkAccordion[index],
                signatories: signTabPaperworkAccordion[index]?.signatories ?? [],
              }

              if (!memberPreAdded) {
                signTabPaperworkAccordion[index].signatories.push({
                  teamMemberId: currRiderTeamMember.teamMemberId,
                  teamMemberName: currRiderTeamMember.teamMemberName,
                })
                signTabPaperworkAccordion[index].signatoryCount++
              }
            }
          })
        })
      })
    })

    signatoryIdsList = [...new Set(signatoryIdsList)]
    count = signatoryIdsList.length

    dispatch(
      updatePayTabPaperworkAccordion({
        signatoryCount: count,
        data: signTabPaperworkAccordion,
      })
    )
  }, [paperworkTabData, filteredPaperworkDocuments])

  useEffect(() => {
    let totalFeesPrice = 0
    let totalFeesUnits = 0
    let totalRecipientCount = 0
    let uniqueRecipientCount
    let isGoverananceFees = false
    let recipientIds: string[] = []
    let governanceTotalFeesPrice = 0
    let totalFeesUnitsInGovernance = 0
    let totalRecipientCountInGovernance = 0
    let uniqueRecipientCountInGovernance
    let recipientIdsInGovernance: string[] = []
    let registrationFees_: IRegistrationFeesInterface | null = null

    const registrationFees: IRegistrationFeesInterface[] = []

    ;[...(feesTabData ?? [])].forEach((currRegistrationFeesRow) => {
      if (
        (currRegistrationFeesRow.registrationFees && currRegistrationFeesRow.registrationFees.id) ||
        currRegistrationFeesRow.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE
      ) {
        registrationFees_ = currRegistrationFeesRow.registrationFees
        isGoverananceFees = currRegistrationFeesRow.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE

        if (isGoverananceFees) {
          totalFeesUnitsInGovernance += 1
          governanceTotalFeesPrice +=
            (currRegistrationFeesRow.feesPrice ?? 0) * registrationsByDay.length
        } else if (registrationFees_ && registrationFees_.id) {
          totalFeesUnits += registrationFees_.selectedUnitsCount ?? 0
          totalFeesPrice +=
            (currRegistrationFeesRow.feesPrice ?? 0) * registrationFees_.selectedUnitsCount
        }

        if (registrationFees_?.recipientId) {
          if (isGoverananceFees) {
            totalRecipientCountInGovernance++
            recipientIdsInGovernance.push(registrationFees_.recipientId)
          } else {
            totalRecipientCount++
            recipientIds.push(registrationFees_.recipientId)
          }
        }

        if (registrationFees_ && registrationFees_.id)
          registrationFees.push(getConvertedData(registrationFees_))
      }
    })

    recipientIds = [...new Set(recipientIds)]
    uniqueRecipientCount = recipientIds.length
    recipientIdsInGovernance = [...new Set(recipientIdsInGovernance)]
    uniqueRecipientCountInGovernance = recipientIdsInGovernance.length

    dispatch(setRegistrationsFeesAc(registrationFees))

    dispatch(
      updatePayTabFeesAccordion({
        uniqueRecipientCount,
        totalPrice: totalFeesPrice,
        totalItemsCount: totalFeesUnits,
        recipientsCount: totalRecipientCount,
        recipientIds,
      })
    )

    let governanceFeesItems: IFeesTab[] = []

    feesTabData.forEach((currFeesTabRow) => {
      if (currFeesTabRow.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE)
        governanceFeesItems.push(currFeesTabRow)
    })

    const horsesIds: string[] = []
    registerTabData.forEach((register) => {
      if (register.registrationsByDay)
        register.registrationsByDay.forEach((registrationsByDay) => {
          if (registrationsByDay.horseId && !horsesIds.includes(registrationsByDay.horseId))
            horsesIds.push(registrationsByDay.horseId)
        })
    })

    governanceTotalFeesPrice = parseFloat(
      sumBy(governanceFeesItems, (fees) => horsesIds.length * (fees?.feesPrice ?? 0)).toFixed(2)
    )
    dispatch(
      updatePayTabGovernanceFeesAccordion({
        recipientIds: recipientIdsInGovernance,
        totalItemsCount: totalFeesUnitsInGovernance,
        recipientsCount: totalRecipientCountInGovernance,
        uniqueRecipientCount: uniqueRecipientCountInGovernance,
        totalPrice: governanceTotalFeesPrice,
      })
    )
  }, [feesTabData, registerTabData, registrationsByDay])

  useEffect(() => {
    let totalTicketsPrice = 0
    let totalTicketsUnits = 0
    let totalRecipientCount = 0
    let uniqueRecipientCount
    let recipientIds: string[] = []
    let registrationTicket: IRegistrationTicketInterface | null = null

    const registrationTickets_: IRegistrationTicketInterface[] = []

    ticketTabData.forEach((currTicketRow) => {
      if (currTicketRow.registrationTicket && currTicketRow.registrationTicket.id) {
        registrationTicket = currTicketRow.registrationTicket

        totalTicketsUnits += registrationTicket.selectedUnitsCount ?? 0
        totalTicketsPrice +=
          (currTicketRow.ticketPrice ?? 0) * (registrationTicket.selectedUnitsCount ?? 0)

        if (registrationTicket?.recipientId) {
          totalRecipientCount++
          recipientIds.push(registrationTicket.recipientId)
        }

        registrationTickets_.push(getConvertedData(currTicketRow.registrationTicket))
      }
    })

    recipientIds = [...new Set(recipientIds)]
    uniqueRecipientCount = recipientIds.length

    dispatch(setRegistrationsTicketsAc(registrationTickets_))
    dispatch(
      updatePayTabTicketAccordion({
        uniqueRecipientCount,
        totalPrice: totalTicketsPrice,
        totalItemsCount: totalTicketsUnits,
        recipientsCount: totalRecipientCount,
        recipientIds,
      })
    )
  }, [dispatch, ticketTabData])

  useEffect(() => {
    let totalPrice = 0
    let totalUnits
    let salesTax
    let totalPriceWithoutSalesTax
    let totalRecipientCount
    let uniqueRecipientCount
    let recipientIds: string[] = []

    totalPrice += feesAccordionData.totalPrice
    totalPrice += ticketAccordionData.totalPrice
    totalPrice += registrationAccordionData.totalPrice
    totalPrice += governanceFeesAccordionData.totalPrice

    totalUnits = [
      feesAccordionData,
      ticketAccordionData,
      registrationAccordionData,
      governanceFeesAccordionData,
    ].length

    recipientIds = [
      ...feesAccordionData.recipientIds,
      ...ticketAccordionData.recipientIds,
      ...registrationAccordionData.recipientIds,
      ...governanceFeesAccordionData.recipientIds,
    ]

    totalRecipientCount = recipientIds.length
    recipientIds = [...new Set(recipientIds)]
    uniqueRecipientCount = recipientIds.length

    salesTax = totalPrice * (selectedEvent?.EventPaymentSettings?.chargeSalesTax ? 0.02 : 0)
    totalPriceWithoutSalesTax = totalPrice
    totalPrice = totalPrice + salesTax

    dispatch(
      updatePayTabGrandTotalAccordion({
        recipientIds,
        uniqueRecipientCount,
        totalItemsCount: totalUnits,
        salesTax: Number(salesTax.toFixed(2)),
        totalPrice: Number(totalPrice.toFixed(2)),
        recipientsCount: Number(totalRecipientCount.toFixed(2)),
        feesTotal: Number(feesAccordionData.totalPrice.toFixed(2)),
        ticketTotal: Number(ticketAccordionData.totalPrice.toFixed(2)),
        totalPriceWithoutSalesTax: Number(totalPriceWithoutSalesTax.toFixed(2)),
        governanceTotal: Number(governanceFeesAccordionData.totalPrice.toFixed(2)),
        registrationTotal: Number(registrationAccordionData.totalPrice.toFixed(2)),
      })
    )
  }, [
    feesAccordionData,
    ticketAccordionData,
    registrationAccordionData,
    dispatch,
    governanceFeesAccordionData,
    eventPaymentSettings,
  ])

  useEffect(() => {
    if (!registeredUser) return
    const UNKNOWN = 'Unknown'
    const INVALID_FIREBASE_ID = CONST.FIREBASE.INVALID_FIREBASE_ID

    let haveId = false
    let totalPrice
    let foundOnIndex = -1
    let recipientsCount
    let totalItemsCount
    let skipIdCheck = false
    let remainingTotalPrice
    let isGovernanceFees = false
    let uniqueRecipientCount
    let recipientsTotalPrice = 0
    let recipientIndex: number = -1
    let recipientIds: string[] = []
    let recipientsList: ISummaryRecipient[] = []
    let userAsRecipient: IRecipientInterface | null = null

    totalPrice = isManage
      ? grandTotalAccordionData.newTotalPrice ?? 0
      : grandTotalAccordionData.totalPrice
    recipientIds = grandTotalAccordionData.recipientIds
    recipientsCount =
      totalItemsCount =
      uniqueRecipientCount =
        grandTotalAccordionData.uniqueRecipientCount

    // Takeout recipients and do total from fees tab
    feesTabData.forEach((currRow) => {
      isGovernanceFees =
        currRow.registrationFees?.feesItemCategory === FEES_CATEGORY_CONST.GOVERNANCE
      skipIdCheck = isGovernanceFees

      if (skipIdCheck) haveId = true
      else haveId = !!currRow.registrationFees?.id

      if (currRow.registrationFees && haveId && !currRow.registrationFees?.delete) {
        if (recipientIds.includes(currRow.registrationFees.recipientId ?? INVALID_FIREBASE_ID)) {
          foundOnIndex = recipientsList.findIndex((curr) => {
            return curr.recipientId === currRow.registrationFees?.recipientId
          })

          if (foundOnIndex !== -1)
            recipientsList[foundOnIndex].amountPaid +=
              currRow.registrationFees.selectedUnitsCount * (currRow.feesPrice ?? 0)
          else if (currRow.registrationFees?.recipientId)
            recipientsList.push({
              comments: [],
              hasRefundDocs: false,
              paperworkStatus: false,
              recipientId: currRow.registrationFees?.recipientId,
              amountPaid: currRow.registrationFees.selectedUnitsCount * (currRow.feesPrice ?? 0),
              registrationDocId: currRow.registrationFees.registrationDocId,
              recipientName:
                teamMembersWithUser.find(
                  (currMember) => currMember.memberId === currRow.registrationFees?.recipientId
                )?.memberName ?? UNKNOWN,
              userName: getUserFullName(registeredUser),
              userId,
              isPaidByOwner: !currRow.registrationFees?.recipientId,
              eventId: currRow.registrationFees.eventId,
              amountRefunded: 0,
              eventName: currRow.registrationFees.eventName,
              amountScratched: 0,
              refundStatus: null,
              paymentStatus: 'pending',
              registrationDate: null,
              recipientProfilePicture: currRow.registrationFees.recipientProfilePicture ?? null,
              u: false,
            })
        }
      }
    })

    // Takeout recipients and do total from tickets tab
    ticketTabData.forEach((currRow) => {
      if (
        currRow.registrationTicket &&
        currRow.registrationTicket.id &&
        !currRow.registrationTicket?.delete
      ) {
        if (recipientIds.includes(currRow.registrationTicket.recipientId ?? INVALID_FIREBASE_ID)) {
          foundOnIndex = recipientsList.findIndex((curr) => {
            return curr.recipientId === currRow.registrationTicket?.recipientId
          })

          if (foundOnIndex !== -1)
            recipientsList[foundOnIndex].amountPaid +=
              currRow.registrationTicket.selectedUnitsCount * (currRow.ticketPrice ?? 0)
          else if (currRow.registrationTicket?.recipientId)
            recipientsList.push({
              hasRefundDocs: false,
              comments: [],
              paperworkStatus: false,
              recipientId: currRow.registrationTicket?.recipientId,
              amountPaid:
                currRow.registrationTicket.selectedUnitsCount * (currRow.ticketPrice ?? 0),
              registrationDocId: currRow.registrationTicket.registrationDocId,
              recipientName:
                teamMembersWithUser.find(
                  (currMember) => currMember.memberId === currRow.registrationTicket?.recipientId
                )?.memberName ?? UNKNOWN,
              userName: getUserFullName(registeredUser),
              userId,
              refundStatus: null,
              isPaidByOwner: !currRow.registrationTicket?.recipientId,
              eventId: currRow.registrationTicket.eventId,
              amountRefunded: 0,
              eventName: currRow.registrationTicket.eventName,
              amountScratched: 0,
              paymentStatus: 'pending',
              registrationDate: null,
              recipientProfilePicture: currRow.registrationTicket.recipientProfilePicture ?? null,
              u: false,
            })
        }
      }
    })

    // Takeout recipients and do total from register tab
    registerTabData.forEach((currRow) => {
      currRow.registrationsByDay.forEach((currRegistrationByDay) => {
        if (
          currRegistrationByDay &&
          currRegistrationByDay.id &&
          currRegistrationByDay.id &&
          !currRegistrationByDay?.delete
        ) {
          if (recipientIds.includes(currRegistrationByDay.recipientId ?? INVALID_FIREBASE_ID)) {
            foundOnIndex = recipientsList.findIndex((curr) => {
              return curr.recipientId === currRegistrationByDay?.recipientId
            })

            if (foundOnIndex !== -1)
              recipientsList[foundOnIndex].amountPaid +=
                currRegistrationByDay.registrationPrice ?? 0
            else if (currRegistrationByDay?.recipientId)
              recipientsList.push({
                hasRefundDocs: false,
                comments: [],
                paperworkStatus: false,
                recipientId: currRegistrationByDay?.recipientId,
                amountPaid: currRegistrationByDay.registrationPrice ?? 0,
                registrationDocId: currRegistrationByDay.registrationDocId,
                recipientName:
                  teamMembersWithUser.find(
                    (currMember) => currMember.memberId === currRegistrationByDay?.recipientId
                  )?.memberName ?? UNKNOWN,
                userName: getUserFullName(registeredUser),
                userId,
                isPaidByOwner: !currRegistrationByDay?.recipientId,
                eventId: currRegistrationByDay.eventId,
                amountRefunded: 0,
                eventName: currRegistrationByDay.eventName ?? null,
                amountScratched: 0,
                refundStatus: null,
                paymentStatus: 'pending',
                u: false,
                registrationDate: null,
                recipientProfilePicture: currRegistrationByDay?.recipientProfilePicture ?? null,
              })
          }
        }
      })
    })

    recipientsList.forEach((currRecipientItem) => {
      if (!currRecipientItem.delete && currRecipientItem.recipientId! !== userId)
        recipientsTotalPrice += currRecipientItem.amountPaid
    })

    recipientsList = [...recipientsList].reduce((acc: IRecipientInterface[], currRecipient) => {
      // Create
      recipientIndex = recipientsInDb.findIndex((currRecipientInDb) => {
        return currRecipientInDb.recipientId === currRecipient.recipientId
      })

      if (recipientIndex === -1) {
        acc.push({
          ...getConvertedData({
            ...new RecipientModel(currRecipient).toObject(),
          }),
          create: true,
          update: false,
          delete: false,
        })

        return acc
      } else if (!!recipientsInDb[recipientIndex].id) {
        acc.push({
          ...getConvertedData({
            ...new RecipientModel(currRecipient).toObject(),
          }),
          refundStatus: null,
          amountScratched: recipientsInDb[recipientIndex].amountScratched,
          amountRefunded: recipientsInDb[recipientIndex].amountRefunded,
          paymentStatus: recipientsInDb[recipientIndex].paymentStatus,
          create: false,
          update: recipientsInDb[recipientIndex].amountPaid !== currRecipient.amountPaid,
          delete: false,
          id: recipientsInDb[recipientIndex].id,
        })

        return acc
      }

      acc.push(currRecipient)

      return acc
    }, [])

    remainingTotalPrice = totalPrice - recipientsTotalPrice

    userAsRecipient =
      recipientsInDb.find((currRecipient) => {
        return currRecipient.recipientId === userId
      }) ?? null

    recipientsList = recipientsList.filter((currRecipient) => {
      return currRecipient.recipientId !== userId
    })

    if (!userAsRecipient)
      userAsRecipient = {
        hasRefundDocs: false,
        recipientId: userId ?? null,
        paperworkStatus: false,
        comments: [],
        amountPaid: remainingTotalPrice,
        registrationDocId: registration?.id ?? null,
        recipientName:
          [registeredUser.userFirstName, registeredUser.userLastName].join(' ') ?? null,
        userName: getUserFullName(registeredUser),
        userId,
        isPaidByOwner: true,
        eventId: basicEventDetails.id,
        amountRefunded: 0,
        eventName: null,
        amountScratched: 0,
        refundStatus: null,
        paymentStatus: 'pending',
        u: false,
        create: true,
        registrationDate: null,
        recipientProfilePicture: registeredUser.userProfilePicture ?? null,
      }
    else
      userAsRecipient = {
        ...userAsRecipient,
        amountPaid: remainingTotalPrice,
        update: userAsRecipient.amountPaid !== remainingTotalPrice,
        create: false,
        delete: false,
      }

    dispatch(
      updatePayTabSummaryAccordion({
        totalPrice,
        recipientIds,
        recipientsCount,
        totalItemsCount,
        recipientsTotalPrice,
        remainingTotalPrice,
        uniqueRecipientCount,
        data: recipientsList,
        userData: userAsRecipient!,
      })
    )
  }, [
    grandTotalAccordionData,
    feesTabData,
    ticketTabData,
    registerTabData,
    activeRegistrationTab,
    teamMembersWithUser,
    userId,
    registeredUser,
    basicEventDetails,
    registration?.id,
    recipientsInDb,
  ])

  function getBasicQueries() {
    const { EVENT_ID, USER_ID } = COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS
    return [where(USER_ID.KEY, '==', userId), where(EVENT_ID.KEY, '==', eventId)]
  }

  const createTeamTabData = async (
    recipient: IRegistrationTeamsTypes['IRegistrationTeamInterface']
  ) => {
    return (
      (
        await FirestoreService.createItem(
          COLLECTIONS.REGISTRATION_TEAMS.NAME,
          new RegistrationTeamModel(recipient).toFirestore()
        )
      )?.id ?? null
    )
  }

  const saveTeamTabData = async (registrationId: string) => {
    let id: string | null = null
    if (!teamMembers) return

    const mutated = teamMembers.map((teamItem) => {
      const team = {
        ...teamItem,
        registrationDocId: registrationId,
        eventName: publishedEventInDb?.EventDetails.competitionName,
        eventId,
      }
      return team as IRegistrationTeamsTypes['IRegistrationTeamInterface']
    })

    await helpers.asyncWhileLoop({
      loopCount: mutated.length,
      functionToFire: async (currIndex) => {
        if (!mutated[currIndex].id) {
          id = await createTeamTabData({
            ...new RegistrationTeamModel(mutated[currIndex]).toFirestore(),
          })
          mutated[currIndex].id = id ?? null
        }
      },
    })

    dispatch(setTeamTabData(mutated))
  }

  const onRegistrationByDayHorseChange: ITypes['IOnRegistrationByDayHorseChangeFn'] = async ({
    currentRow,
    riderId,
    horseId,
    isManage,
    manageInfo,
    setManageInfo,
  }) => {
    const INVALID_FIREBASE_ID = CONST.FIREBASE.INVALID_FIREBASE_ID
    const noHorseSelected = horseId === INVALID_FIREBASE_ID

    if (!publishedEventInDb) return null

    try {
      const { EventDetails, EventFees } = publishedEventInDb

      const horse = userHorses.find((currHorse) => currHorse.id === horseId)

      const currentMember = cloneDeep(currentRow)

      const registrationsByDayIndex = currentMember.registrationsByDay.findIndex(
        (registrationsByDay) => registrationsByDay.riderId === riderId
      )

      const teamMember = teamMembersWithUser.find(
        (currTeamMember) =>
          currTeamMember.memberId ===
          currentMember.registrationsByDay[registrationsByDayIndex].riderId
      )

      const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        horseId: noHorseSelected ? true : horseId,
        eventDate: EventDetails.competitionStartDate,
        riderId: currentMember.registrationsByDay[registrationsByDayIndex].riderId,
        horse: noHorseSelected ? true : horse,
        teamMember,
        EventDetails,
      })

      if (emptyVarName) {
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'onRegistrationByDayHorseChange',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
        })
      }

      if (registrationsByDayIndex === -1) {
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'onRegistrationByDayHorseChange',
          devMessage: `As per eventDate row not found in registerTabData`,
        })
      }

      currentMember.registrationsByDay[registrationsByDayIndex] = getConvertedData({
        ...new RegistrationByDayModel({
          userId,
          refundId: null,
          horseId: null,
          horseName: null,
          isSratched: false,
          refundDocId: null,
          recipientId: null,
          amountRefunded: 0,
          backNumber: 0,
          refundStatus: null,
          isPaidByOwner: true,
          score: {
            rank: null,
            totalPoints: null,
            sheet: null,
            judges: [],
            details: null,
            file: '',
          },
          order: currentMember.order ?? 0,
          riderId: currentMember.registrationsByDay[registrationsByDayIndex].riderId,
          paymentStatus: 'pending',
          eventDate: EventDetails.competitionStartDate,
          horseProfilePicture: null,
          userHorseMappingDocId: null,
          eventId,
          recipientProfilePicture: null,
          riderDob: teamMember?.memberDob ?? null,
          riderRole: teamMember?.memberRole ?? null,
          riderName: teamMember?.memberName ?? null,
          riderEmail: teamMember?.memberEmail ?? null,
          registrationDocId: registration?.id ?? null,
          eventName: EventDetails?.competitionName ?? null,
          amountScratched: 0,
          riderProfilePicture: teamMember?.memberProfilePicture,
          registrationByDayName: currentMember?.registrationByDayName,
          registrationPrice: currentMember?.price ?? 0,
          qualifyFee: currentRow?.qualifyFee ?? null,
          breakTime: EventFees.rideTimeRequirement?.minutes ?? null,
          uuid: currentMember.registrationsByDay[registrationsByDayIndex].uuid,
          id: currentMember.registrationsByDay[registrationsByDayIndex].id,
        }).toObject(),
      })

      if (currentMember.registrationsByDay[registrationsByDayIndex]?.paymentStatus !== 'paid') {
        currentMember.registrationsByDay[registrationsByDayIndex] = {
          ...currentMember.registrationsByDay[registrationsByDayIndex],
          horseId: horse?.id ?? null,
          horseName: horse?.horseName,
          noHorseSelected: noHorseSelected,
          horseProfilePicture: horse?.horseProfilePicture,
        }
      } else {
        return toastFunctions.info({
          message: 'This member is already registered for this event',
        })
      }

      let registerTabData_

      if (isManage) {
        registerTabData_ = cloneDeep(manageInfo.register)
      } else {
        registerTabData_ = cloneDeep(registerTabData)
      }

      const registerTabDataIndex = registerTabData_.findIndex(
        (data) => data.uuid === currentMember.uuid
      )

      registerTabData_[registerTabDataIndex].registrationsByDay = currentMember.registrationsByDay

      if (isManage) {
        setManageInfo({ ...manageInfo, register: registerTabData_ })
      } else {
        await saveRegisterTabData({
          registrationsByDayToUpdate: registerTabData_[registerTabDataIndex].registrationsByDay,
        })
      }
    } catch (error: any) {
      toastFunctions.error({
        message:
          error?.message ??
          `${MESSAGES_CONST.SOMETHING_WENT_WRONG} in onRegistrationByDayHorseChange`,
      })
      helpers.logger({
        message: error,
      })
    }
  }

  const onRegistrationMemberChange: ITypes['IOnRegistrationMemberChangeFn'] = async ({
    currentRow,
    member,
    memberId,
    remove,
    isManage,
    manageInfo,
    setManageInfo,
    isChildren,
  }) => {
    if (!publishedEventInDb) return null

    try {
      const { EventDetails, EventFees } = publishedEventInDb

      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        memberId,
        EventDetails,
        ...(!remove && {
          member,
        }),
      })

      if (emptyVarName) {
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'onRegistrationMemberChange',
          devMessage: `${emptyVarName} is ${emptyVarValue}`,
        })
      }

      const currentMember = cloneDeep(currentRow)
      const registrationsByDayIndex = currentMember.registrationsByDay.findIndex(
        (registrationsByDay) => registrationsByDay.riderId === memberId
      )

      if (remove) {
        if (currentMember.registrationsByDay[registrationsByDayIndex].paymentStatus !== 'paid') {
          currentMember.registrationsByDay.splice(registrationsByDayIndex, 1)
        } else {
          toastFunctions.info({ message: 'This member is already registered for this event' })
        }
      } else {
        currentMember.registrationsByDay.push(
          getConvertedData({
            ...new RegistrationByDayModel({
              userId,
              eventDate: EventDetails.competitionStartDate,
              horseId: null,
              refundId: null,
              horseName: null,
              recipientId: null,
              backNumber: 0,
              score: {
                rank: null,
                totalPoints: null,
                sheet: null,
                judges: [],
                details: null,
                file: '',
              },
              order: currentMember.order ?? 0,
              refundDocId: null,
              amountRefunded: 0,
              isSratched: false,
              refundStatus: null,
              amountScratched: 0,
              isPaidByOwner: true,
              paymentStatus: 'pending',
              horseProfilePicture: null,
              eventId,
              userHorseMappingDocId: null,
              recipientProfilePicture: null,
              riderId: member?.memberId ?? null,
              riderDob: member?.memberDob ?? null,
              riderRole: member?.memberRole ?? null,
              riderName: member?.memberName ?? null,
              riderEmail: member?.memberEmail ?? null,
              registrationDocId: registration?.id ?? null,
              eventName: EventDetails?.competitionName ?? null,
              riderProfilePicture: member?.memberProfilePicture,
              registrationPrice: currentRow.price ?? 0,
              qualifyFee: currentRow?.qualifyFee ?? null,
              breakTime: EventFees.rideTimeRequirement?.minutes ?? null,
              registrationByDayName: currentRow.registrationByDayName,
              uuid: currentMember.uuid,
            }).toObject(),
          })
        )
      }

      let registerTabData_

      if (isManage) {
        registerTabData_ = cloneDeep(manageInfo.register)
      } else {
        registerTabData_ = cloneDeep(registerTabData)
      }

      if (isChildren) {
        let registerTabDataIndex = -1
        let registerTabDataChildrenIndex = -1

        registerTabData_.forEach((data, index) => {
          registerTabDataIndex = index
          ;(data?.children as IRegisterTabItem[]).forEach((children, childrenIndex) => {
            if (children.uuid === currentMember.uuid) {
              registerTabDataChildrenIndex = childrenIndex
            }
          })
        })

        if (registerTabDataIndex !== -1) {
          if (registerTabDataChildrenIndex !== -1) {
            ;(registerTabData_[registerTabDataIndex].children as IRegisterTabItem[])[
              registerTabDataChildrenIndex
            ].registrationsByDay = currentMember.registrationsByDay
          } else {
            ;(registerTabData_[registerTabDataIndex].children as IRegisterTabItem[]).push(
              currentMember
            )
          }
        } else {
          registerTabData.forEach((data) =>
            (data?.children as IRegisterTabItem[]).forEach((children, childrenIndex) => {
              if (children.uuid === currentMember.uuid) {
                currentRow = data
              }
            })
          )
          registerTabData_.push({ ...currentRow, children: [currentMember] })
        }
      } else {
        const registerTabDataIndex = registerTabData_.findIndex(
          (data) => data.uuid === currentMember.uuid
        )

        if (registerTabDataIndex === -1) {
          registerTabData_.push(currentMember)
        } else {
          registerTabData_[registerTabDataIndex].registrationsByDay =
            currentMember.registrationsByDay
        }
      }

      if (isManage) {
        setManageInfo({ ...manageInfo, register: registerTabData_ })
      } else {
        await saveRegisterTabData({
          ...(remove && {
            registrationsByDayToDelete: [
              cloneDeep(currentRow.registrationsByDay[registrationsByDayIndex]),
            ],
          }),
          ...(!remove && {
            registrationsByDayToAdd: cloneDeep(currentMember.registrationsByDay.slice(-1)),
          }),
        })
      }
    } catch (error: any) {
      helpers.logger({
        message: `${error?.message}  in onRegistrationMemberChange function`,
      })
    }
  }

  const saveRecipient = async (recipient: IRecipientInterface) => {
    let id = (await FirestoreService.createItem(COLLECTIONS.RECIPIENT.NAME, recipient))?.id ?? null
    return id
  }

  const updateRecipient = async (recipient: IRecipientInterface) => {
    await FirestoreService.updateItem(
      COLLECTIONS.RECIPIENT.NAME,
      recipient.id,
      new RecipientModel(recipient).toFirestore()
    )
  }

  const deleteRecipient = async (idOfDocToRemove: string) => {
    await FirestoreService.deleteItem(COLLECTIONS.RECIPIENT.NAME, idOfDocToRemove)
  }

  const updateRidersTeamMember = async (ridersTeamMember: IRiderTeamMemberInterface) => {
    await FirestoreService.updateItem(
      COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
      ridersTeamMember.id,
      new RiderTeamMemberModel(ridersTeamMember).toFirestore()
    )
  }

  const deleteRiderTeamMember = async (idOfDocToRemove: string) => {
    await FirestoreService.deleteItem(COLLECTIONS.RIDER_TEAM_MEMBER.NAME, idOfDocToRemove)
  }

  const createRiderTeamMember = async (ridersTeamMember: IRiderTeamMemberInterface) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.RIDER_TEAM_MEMBER.NAME, ridersTeamMember))
        ?.id ?? null
    )
  }

  const createUserDocuments = async (userDocuments: IUserDocument) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.USERS_DOCUMENTS.NAME, userDocuments))?.id ??
      null
    )
  }

  const saveRegisterTabData = async ({
    registrationsByDayToAdd,
    registrationsByDayToUpdate,
    registrationsByDayToDelete,
  }: {
    registrationsByDayToAdd?: IRegistrationByDayInterface[]
    registrationsByDayToUpdate?: IRegistrationByDayInterface[]
    registrationsByDayToDelete?: IRegistrationByDayInterface[]
  }) => {
    let id: string | null = null
    let dontHaveRegistrationDocId
    let registrationsByDayUpdated: IRegistrationByDayInterface[] = []
    const registrationsByDay_ = cloneDeep(registrationsByDay)

    dontHaveRegistrationDocId = !!registrationsByDayToAdd?.find(
      (currRegistrationByDay) => !currRegistrationByDay.registrationDocId
    )

    if (dontHaveRegistrationDocId)
      throw CustomError.somethingWentWrong({
        ...customErrorProps,
        message: "one of the registrationsByDay don't have registrationDocId in it",
        moduleName: 'saveRegisterTabData',
      })

    try {
      // Add
      await helpers.asyncWhileLoop({
        loopCount: registrationsByDayToAdd?.length ?? 0,
        functionToFire: async (currIndex) => {
          id = await saveRegistrationByDay(
            new RegistrationByDayModel(registrationsByDayToAdd![currIndex]).toFirestore()
          )
          registrationsByDayToAdd![currIndex] = getConvertedData({
            ...registrationsByDayToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      // Delete
      await helpers.asyncWhileLoop({
        loopCount: registrationsByDayToDelete?.length ?? 0,
        functionToFire: async (currIndex) => {
          if (!registrationsByDayToDelete![currIndex].id) return
          await deleteRegistrationByDay(registrationsByDayToDelete![currIndex].id as any)
        },
      })

      // Update
      await helpers.asyncWhileLoop({
        loopCount: registrationsByDayToUpdate?.length ?? 0,
        functionToFire: async (currIndex) => {
          await updateRegistrationByDay({
            ...new RegistrationByDayModel(registrationsByDayToUpdate![currIndex]).toFirestore(),
            id: registrationsByDayToUpdate![currIndex].id,
          })
        },
      })

      if (registrationsByDayToDelete) {
        registrationsByDayUpdated = registrationsByDay.filter(
          (registration) => registration.id !== registrationsByDayToDelete[0].id
        )
      } else if (registrationsByDayToUpdate) {
        registrationsByDayUpdated = registrationsByDay_.map((registrationByDay) => {
          const filtered = registrationsByDayToUpdate.filter(
            (registrationByDayToUpdate) => registrationByDayToUpdate.id === registrationByDay.id
          )
          if (filtered.length > 0) {
            return filtered[0]
          } else {
            return registrationByDay
          }
        })
      } else {
        registrationsByDayUpdated = getConvertedData([
          ...registrationsByDay,
          ...(registrationsByDayToAdd ?? []),
        ])
      }
      dispatch(setRegistrationsByDay(registrationsByDayUpdated))

      const registerTabData_ = mergeRegistrationWithTabData(
        registerTabData,
        registrationsByDayUpdated
      )

      dispatch(setRegisterTabData(registerTabData_))
    } catch (error: any) {
      helpers.logger({ message: `${error} in saveRegisterTabData` })
    }
  }

  const saveRegistrationByDay = async (registrationsByDay: IRegistrationByDayInterface) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.REGISTRATION_BY_DAY.NAME, registrationsByDay))
        ?.id ?? null
    )
  }

  const deleteRegistrationByDay = async (idOfDocToRemove: string) => {
    await FirestoreService.deleteItem(COLLECTIONS.REGISTRATION_BY_DAY.NAME, idOfDocToRemove)
  }

  const updateRegistrationByDay = async (registrationsByDay: IRegistrationByDayInterface) => {
    await FirestoreService.updateItem(
      COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      registrationsByDay.id,
      new RegistrationByDayModel(registrationsByDay).toFirestore()
    )
  }

  const savePaperworkTabData = async ({
    ridersTeamMembersToAdd,
    ridersTeamMembersToDelete,
    ridersTeamMembersToUpdate,
  }: {
    ridersTeamMembersToAdd?: IRiderTeamMemberInterface[]
    ridersTeamMembersToDelete?: IRiderTeamMemberInterface[]
    ridersTeamMembersToUpdate?: IRiderTeamMemberInterface[]
  }) => {
    let id: string | null = null
    const userDocumentsToAdd: IUserDocument[] = []
    let EventDetails = publishedEventInDb?.EventDetails ?? null

    if (ridersTeamMembersToAdd && ridersTeamMembersToAdd.length > 0) {
      let paperworkTabDataNotFilled = ridersTeamMembersToAdd.filter(
        (ridersTeamMember) => !ridersTeamMember.teamMemberRole || !ridersTeamMember.teamMemberId
      )

      console.log(
        '=>(useEventRegistrationTabs.tsx:1353) paperworkTabDataNotFilled',
        paperworkTabDataNotFilled
      )
      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        paperworkTabDataNotFilled: paperworkTabDataNotFilled && !paperworkTabDataNotFilled.length,
      })

      if (emptyVarName) {
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'savePaperworkTabData',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
          message: 'Please change empty Rider or Role.',
        })
      }
    }

    try {
      // Add
      await helpers.asyncWhileLoop({
        loopCount: ridersTeamMembersToAdd?.length ?? 0,
        functionToFire: async (currIndex) => {
          console.log('=>(useEventRegistrationTabs.tsx:1384) currIndex', currIndex)
          id = await createRiderTeamMember(
            new RiderTeamMemberModel(ridersTeamMembersToAdd![currIndex]).toFirestore()
          )
          console.log('=>(useEventRegistrationTabs.tsx:1388) id', id)
          console.log(
            '=>(useEventRegistrationTabs.tsx:1385) ridersTeamMembersToAdd',
            ridersTeamMembersToAdd
          )
          ridersTeamMembersToAdd![currIndex] = getConvertedData({
            ...ridersTeamMembersToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      for (const document of filteredPaperworkDocuments) {
        if (ridersTeamMembersToAdd) {
          for (const ridersTeamMember of ridersTeamMembersToAdd) {
            const eventsSnapshot = await FirestoreService.filterItems(
              COLLECTIONS.USERS_DOCUMENTS.NAME,
              [
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.SIGNATORY_ID.KEY,
                  '==',
                  ridersTeamMember.teamMemberId
                ),
                where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY, '==', eventId),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.RIDER_ID.KEY,
                  '==',
                  ridersTeamMember.riderId
                ),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.DOCUMENT_NAME.KEY,
                  '==',
                  document.document_name
                ),
                where(
                  COLLECTIONS.USERS_DOCUMENTS.FIELDS.SIGNATORY_DEFAULT_ROLE.KEY,
                  '==',
                  ridersTeamMember.teamMemberRole
                ),
              ]
            )

            console.log('=>(useEventRegistrationTabs.tsx:1431) eventsSnapshot', eventsSnapshot)
            console.log(
              '=>(useEventRegistrationTabs.tsx:1431) eventsSnapshot.size',
              eventsSnapshot.size
            )

            if (eventsSnapshot.size <= 0) {
              userDocumentsToAdd.push(
                getConvertedData({
                  eventId,
                  u: false,
                  status: 'Not Signed',
                  riderId: ridersTeamMember.riderId,
                  activityUser: registeredUser ? getUserFullName(registeredUser) : '',
                  riderName: ridersTeamMember.riderName,
                  signatoryId: ridersTeamMember.teamMemberId,
                  eventLogo: EventDetails?.eventLogo,
                  documentOwner: ridersTeamMember.teamMemberId,
                  signatoryName: ridersTeamMember.teamMemberName,
                  documentName: document.key,
                  documentUrl: document.document_image[0],
                  documentOriginalName: document.document_name,
                  documentNameAsPerPdf: ``,
                  eventName: EventDetails?.competitionName,
                  signatoryProfilePicture: ridersTeamMember.teamMemberProfilePicture,
                  competitorId: registeredUser?.id,
                  signatoryDefaultRole: ridersTeamMember.teamMemberRole,
                  signatoryEmail: ridersTeamMember.teamMemberEmail,
                  reminder: false,
                  registrationDocId: ridersTeamMember.registrationDocId,
                  registrationByDayDocId: ridersTeamMember.registrationByDayDocId,
                  riderTeamMemberDocId: ridersTeamMember.id ?? null,
                  pageNumberToSignOn: null,
                  coordinatesToSignOn: { x: null, y: null },
                })
              )
            }
          }
        }
      }
      console.log('=>(useEventRegistrationTabs.tsx:1471) userDocumentsToAdd', userDocumentsToAdd)
      if (userDocumentsToAdd.length > 0) {
        await helpers.asyncWhileLoop({
          loopCount: userDocumentsToAdd?.length ?? 0,
          functionToFire: async (currIndex) => {
            id = await createUserDocuments(
              new UserDocumentModel(userDocumentsToAdd![currIndex]).toFirestore()
            )
            userDocumentsToAdd![currIndex] = getConvertedData({
              ...userDocumentsToAdd![currIndex],
              id: id ?? null,
            })
          },
        })
      }

      // Delete
      await helpers.asyncWhileLoop({
        loopCount: ridersTeamMembersToDelete?.length ?? 0,
        functionToFire: async (currIndex) => {
          if (!ridersTeamMembersToDelete![currIndex].id) return
          await deleteRiderTeamMember(ridersTeamMembersToDelete![currIndex].id as any)
        },
      })

      // Update
      await helpers.asyncWhileLoop({
        loopCount: ridersTeamMembersToUpdate?.length ?? 0,
        functionToFire: async (currIndex) => {
          await updateRidersTeamMember({
            ...new RiderTeamMemberModel(ridersTeamMembersToUpdate![currIndex]).toFirestore(),
            id: ridersTeamMembersToUpdate![currIndex].id,
          })
        },
      })

      const ridersTeamMembersInDb__ = await getRiderTeamMembersFromDb(registration?.id ?? '')

      const paperworkTabData__ = mergeRidersTeamMembersWithPaperworkTabData(
        paperworkTabData,
        ridersTeamMembersInDb__
      )

      dispatch(setRidersTeamMembers(ridersTeamMembersInDb__))
      dispatch(setRidersTeamMembersInDb(ridersTeamMembersInDb__))
      dispatch(setPaperworkTabData(paperworkTabData__))

      return true
    } catch (error: any) {
      helpers.logger({ message: `${error} in savePaperworkTabData` })
    }
  }

  const onInputChangeTicket = async ({
    currentRow,
    setManageInfo,
    manageInfo,
    isManage,
  }: ISaveTicketTabData) => {
    let data = ticketItemsForm.watch() ?? {}
    const currentTicket = cloneDeep(currentRow)
    if (!publishedEventInDb) return null

    const value = isNaN(data[currentTicket.ticketItemId])
      ? '0'
      : `${data[currentTicket.ticketItemId]}`

    try {
      const { EventDetails } = publishedEventInDb
      currentTicket.registrationTicket = getConvertedData({
        ...new RegistrationTicketModel({
          userId,
          ticketItemId: currentTicket.ticketItemId,
          refundId: null,
          recipientId: null,
          refundDocId: null,
          amountRefunded: 0,
          eventId,
          recipientProfilePicture: null,
          userName: registeredUser ? getUserFullName(registeredUser) : null,
          selectedUnitsCount: Number(value),
          remainingTicketsCount: Number(value),
          registrationDocId: registration?.id ?? null,
          eventName: EventDetails?.competitionName ?? null,
          amountScratched: 0,
          ticketTitle: currentTicket.ticketTitle,
          ticketPrice: currentTicket.ticketPrice ?? 0,
          id: currentTicket.registrationTicket?.id ?? null,
        }).toObject(),
      })

      let ticketTabData_

      if (isManage) {
        ticketTabData_ = cloneDeep(manageInfo.tickets)
      } else {
        ticketTabData_ = cloneDeep(ticketTabData)
      }

      const ticketTabDataIndex = ticketTabData_.findIndex(
        (ticket) => ticket.ticketItemId === currentTicket.ticketItemId
      )

      if (ticketTabDataIndex === -1) {
        ticketTabData_.push(currentTicket)
      } else {
        ticketTabData_[ticketTabDataIndex].registrationTicket = currentTicket.registrationTicket
      }

      if (isManage) {
        setManageInfo({ ...manageInfo, tickets: ticketTabData_ })
      } else {
        await saveTicketTabData({
          ...(currentTicket.registrationTicket?.id && {
            registrationTicketsToUpdate: [currentTicket.registrationTicket],
          }),
          ...(!currentTicket.registrationTicket?.id && {
            registrationTicketsToAdd: [currentTicket.registrationTicket],
          }),
        })
      }
    } catch (error: any) {
      helpers.logger({ message: `${error} in onInputChangeTicket` })
    }
  }

  const saveTicketTabData = async ({
    registrationTicketsToAdd,
    registrationTicketsToDelete,
    registrationTicketsToUpdate,
  }: {
    registrationTicketsToAdd?: IRegistrationTicketInterface[]
    registrationTicketsToDelete?: IRegistrationTicketInterface[]
    registrationTicketsToUpdate?: IRegistrationTicketInterface[]
  }) => {
    let id: string | null = null
    let registrationTicketsUpdated: IRegistrationTicketInterface[] = []

    const dontHaveRegistrationDocId = !!registrationTicketsToAdd?.find(
      (currRegistrationTicket) => !currRegistrationTicket.registrationDocId
    )

    try {
      if (dontHaveRegistrationDocId)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          message: "one of the registrationsByDay don't have registrationDocId in it",
          moduleName: 'saveTicketTabData',
        })

      // Add
      await helpers.asyncWhileLoop({
        loopCount: registrationTicketsToAdd?.length ?? 0,
        functionToFire: async (currIndex) => {
          id = await saveRegistrationTicket(
            new RegistrationTicketModel(registrationTicketsToAdd![currIndex]).toFirestore()
          )
          registrationTicketsToAdd![currIndex] = getConvertedData({
            ...registrationTicketsToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      // Delete
      await helpers.asyncWhileLoop({
        loopCount: registrationTicketsToDelete?.length ?? 0,
        functionToFire: async (currIndex) => {
          if (!registrationTicketsToDelete![currIndex].id) return
          await deleteRegistrationTicket(registrationTicketsToDelete![currIndex].id as any)
        },
      })

      // Update
      await helpers.asyncWhileLoop({
        loopCount: registrationTicketsToUpdate?.length ?? 0,
        functionToFire: async (currIndex) => {
          await updateRegistrationTicket({
            ...new RegistrationTicketModel(registrationTicketsToUpdate![currIndex]).toFirestore(),
            id: registrationTicketsToUpdate![currIndex].id,
          })
        },
      })

      if (registrationTicketsToUpdate) {
        const newTickets = cloneDeep(registrationTickets)
        const index = newTickets.findIndex(
          (ticket) => ticket.id === registrationTicketsToUpdate[0].id
        )
        if (index !== -1) {
          newTickets[index] = registrationTicketsToUpdate[0]
        }
        registrationTicketsUpdated = newTickets
      } else {
        registrationTicketsUpdated = getConvertedData([
          ...registrationTickets,
          ...(registrationTicketsToAdd ?? []),
        ])
      }

      const ticketTabData_ = mergeTicketsWithTabData(ticketTabData, registrationTicketsUpdated)
      dispatch(setTicketTabData(ticketTabData_))
      dispatch(setRegistrationsTicketsAc(registrationTicketsUpdated))
    } catch (error: any) {
      helpers.logger({ message: `${error} in saveTicketTabData` })
    }
  }

  const saveRegistrationTicket = async (registrationTicket: IRegistrationTicketInterface) => {
    return (
      (await FirestoreService.createItem(COLLECTIONS.REGISTRATION_TICKET.NAME, registrationTicket))
        ?.id ?? null
    )
  }

  const deleteRegistrationTicket = async (idOfDocToRemove: string) => {
    await FirestoreService.deleteItem(COLLECTIONS.REGISTRATION_TICKET.NAME, idOfDocToRemove)
  }

  const updateRegistrationTicket = async (registrationTicket: IRegistrationTicketInterface) => {
    await FirestoreService.updateItem(
      COLLECTIONS.REGISTRATION_TICKET.NAME,
      registrationTicket.id,
      new RegistrationTicketModel(registrationTicket).toFirestore()
    )
  }

  const onChangeFees = async ({
    currentRow,
    setManageInfo,
    manageInfo,
    isManage,
  }: IOnChangeFees) => {
    const currentFees = cloneDeep(currentRow)
    const registrationsByDay_ = cloneDeep(registrationsByDay)
    const horsesIds: string[] = []

    if (!publishedEventInDb) return null

    registrationsByDay_.forEach((registrationsByDay) => {
      if (registrationsByDay.horseId && !horsesIds.includes(registrationsByDay.horseId))
        horsesIds.push(registrationsByDay.horseId)
    })

    try {
      const { EventDetails } = publishedEventInDb

      currentFees.registrationFees = getConvertedData({
        ...new RegistrationFeesModel({
          userId,
          feesItemId: currentFees.feesItemId,
          refundId: null,
          refundDocId: null,
          amountRefunded: 0,
          amountScratched: 0,
          eventId,
          selectedUnitsCount: currentFees.registrationFees?.selectedUnitsCount ?? 0,
          userName: registeredUser ? getUserFullName(registeredUser) : null,
          registrationDocId: registration?.id ?? null,
          eventName: EventDetails?.competitionName ?? null,
          recipientId: currentFees.registrationFees?.recipientId ?? null,
          feesTitle: currentFees.feesTitle,
          recipientName: currentFees.registrationFees?.recipientName ?? null,
          feesPrice: currentFees.feesPrice ?? 0,
          recipientProfilePicture: currentFees.registrationFees?.recipientProfilePicture ?? null,
          feesItemCategory: currentFees.feesCategory ?? null,
          id: currentFees.registrationFees?.id ?? null,
        }).toObject(),
      })

      let feesTabData_

      if (isManage) {
        feesTabData_ = cloneDeep(manageInfo.fees)
      } else {
        feesTabData_ = cloneDeep(feesTabData)
      }

      const feesTabDataIndex = feesTabData_.findIndex(
        (fees) => fees.feesItemId === currentFees.feesItemId
      )

      if (feesTabDataIndex === -1) {
        feesTabData_.push(currentFees)
      } else {
        feesTabData_[feesTabDataIndex].registrationFees = currentFees.registrationFees
      }

      if (isManage) {
        setManageInfo({ ...manageInfo, fees: feesTabData_ })
      } else {
        await saveFeesTabData({
          ...(currentFees.registrationFees?.id && {
            registrationFeesToUpdate: [currentFees.registrationFees],
          }),
          ...(!currentFees.registrationFees?.id && {
            registrationFeesToAdd: [currentFees.registrationFees],
          }),
        })
      }
    } catch (error: any) {
      helpers.logger({ message: `${error} in onChangeFees` })
    }
  }

  const saveFeesTabData = async ({
    registrationFeesToAdd,
    registrationFeesToUpdate,
  }: {
    registrationFeesToAdd?: IRegistrationFeesInterface[]
    registrationFeesToUpdate?: IRegistrationFeesInterface[]
  }) => {
    let id: string | null = null
    let dontHaveRegistrationDocId = false
    let registrationFeesUpdated: IRegistrationFeesInterface[] = []

    dontHaveRegistrationDocId = !!registrationFeesToAdd?.find((fees) => !fees.registrationDocId)

    try {
      if (dontHaveRegistrationDocId)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          message: "one of the registrationsByDay don't have registrationDocId in it",
          moduleName: 'saveFeesTabData',
        })

      // Add
      await helpers.asyncWhileLoop({
        loopCount: registrationFeesToAdd?.length ?? 0,
        functionToFire: async (currIndex) => {
          id = await saveRegistrationFees(
            new RegistrationFeesModel(registrationFeesToAdd![currIndex]).toFirestore()
          )
          registrationFeesToAdd![currIndex] = getConvertedData({
            ...registrationFeesToAdd![currIndex],
            id: id ?? null,
          })
        },
      })

      // Update
      await helpers.asyncWhileLoop({
        loopCount: registrationFeesToUpdate?.length ?? 0,
        functionToFire: async (currIndex) => {
          await updateRegistrationFees({
            ...new RegistrationFeesModel(registrationFeesToUpdate![currIndex]).toFirestore(),
            id: registrationFeesToUpdate![currIndex].id,
          })
        },
      })
      if (registrationFeesToUpdate) {
        const newRegistrationFees = cloneDeep(registrationFees)
        const index = registrationFees.findIndex(
          (fees) => fees.id === registrationFeesToUpdate[0].id
        )
        if (index !== -1) {
          newRegistrationFees[index] = registrationFeesToUpdate[0]
        }
        registrationFeesUpdated = newRegistrationFees
      } else {
        registrationFeesUpdated = getConvertedData([
          ...registrationFees,
          ...(registrationFeesToAdd ?? []),
        ])
      }

      const feesTabData_ = mergeFeesWithTabData(feesTabData, registrationFeesUpdated)
      dispatch(setFeesTabDataAc(feesTabData_))
      dispatch(setRegistrationsFeesAc(registrationFeesUpdated))
    } catch (error: any) {
      helpers.logger({ message: `${error} in saveFeesTabData` })
    }
  }

  const saveRegistrationFees = async (registrationFees: IRegistrationFeesInterface) => {
    let id =
      (await FirestoreService.createItem(COLLECTIONS.REGISTRATION_FEES.NAME, registrationFees))
        ?.id ?? null
    return id
  }

  const updateRegistrationFees = async (registratonFees: IRegistrationFeesInterface) => {
    await FirestoreService.updateItem(
      COLLECTIONS.REGISTRATION_FEES.NAME,
      registratonFees.id,
      new RegistrationFeesModel(registratonFees).toFirestore()
    )
  }

  const createRegistration = async () => {
    let EventDetails = publishedEventInDb?.EventDetails ?? null
    let registrationCreated = false
    let registration_ = registration

    try {
      if (registration_?.id) return registration

      registration_ = await getRegistrationFromDb()

      if (registration_?.id) return

      if (registeredUser) {
        registration_ = new EventRegisteredUsersModel({
          ...registeredUser,
          eventId,
          eventName: EventDetails?.competitionName,
          eventLogo: EventDetails?.eventLogo,
          userId,
          draft: null,
          horses: [],
          paymentStatus: registration?.paymentStatus ?? null,
          selectionDetails: {
            paperwork: {},
            selectedFees: [],
            selectedEvents: [],
            selectedTickets: [],
            isSplitPayment: false,
            selectedRecipients: [],
            selectedSignatories: [],
          },
        })
      } else {
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'createRegistration',
          devMessage: `Current registration not found`,
          message: `Current registration not found`,
        })
      }

      let createdDoc = await FirestoreService.createItem(
        COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        (registration_ as any).toFirestore()
      )

      if (registration_) registration_.id = createdDoc.id

      dispatch(
        setRegistrationAc(
          registration_
            ? getConvertedData(new EventRegisteredUsersModel(registration_).toObject())
            : registration_
        )
      )

      registrationCreated = true
    } catch (error: any) {
      throw CustomError.somethingWentWrong({
        ...customErrorProps,
        message: error?.message,
        devMessage: error?.message,
        moduleName: 'createRegistration',
      })
    } finally {
      if (registrationCreated || !registration) return registration_
    }
  }

  const saveRecipients = async ({ isPaid, cardNo }: { isPaid?: boolean; cardNo?: string }) => {
    let id: string | null = null
    let deleteRecipient_ = false
    let haveZeroAmountToPay = false
    let error_: CustomError | null = null
    let dontHaveRegistrationDocId = false
    let recipientsToAdd: IRecipientInterface[] = []
    let recipientsUpdated: IRecipientInterface[] = []
    let recipient_: IRecipientInterface | null = null
    let recipientsToDelete: IRecipientInterface[] = []
    let recipientsToUpdate: IRecipientInterface[] = []
    let recipientsUntouched: IRecipientInterface[] = []
    let recipients: IRecipientInterface[] = cloneDeep(summaryAccordionData.data)
    let eventDetails = new EventDetailsModel((publishedEventInDb as any)?.EventDetails)
    let amountForRegistrationIsPaid = summaryAccordionData.userData.paymentStatus === 'paid'
    recipients.push(summaryAccordionData.userData)
    recipientsInDb.forEach((currRecipientInDb) => {
      deleteRecipient_ = recipients.every((currRecipient) => {
        return (
          currRecipient.recipientId !== currRecipientInDb.recipientId &&
          currRecipientInDb.recipientId !== userId &&
          !currRecipientInDb.isPaidByOwner
        )
      })

      if (deleteRecipient_ && !!currRecipientInDb.id)
        recipients.push({
          ...currRecipientInDb,
          delete: true,
          update: false,
          create: false,
        })
    })

    recipients.forEach((currRecipient) => {
      if (amountForRegistrationIsPaid) {
        currRecipient = {
          ...currRecipient,
          registrationDate: new Date(),
          update: true,
        }
      }

      recipient_ =
        recipientsInDb.filter((currRecipientInDb) => {
          return currRecipient.recipientId === currRecipientInDb.recipientId
        })[0] ?? null

      if (!currRecipient.id) {
        if (recipient_) {
          currRecipient = {
            ...currRecipient,
            create: false,
            id: recipient_.id,
          }
        }
      }

      if (currRecipient.delete && !!currRecipient.id)
        recipientsToDelete.push({
          ...currRecipient,
          registrationDocId: registration?.id ?? null,
        })
      else if (!currRecipient.id)
        recipientsToAdd.push({
          ...currRecipient,
          cardNo,
          registrationDocId: registration?.id ?? null,
        })
      else if (!!currRecipient.id)
        recipientsToUpdate.push({
          ...currRecipient,
          amountPaid: (recipient_?.amountPaid ?? 0) + currRecipient.amountPaid,
          cardNo,
          registrationDocId: registration?.id ?? null,
        })
      else
        recipientsUntouched.push({
          ...currRecipient,
          cardNo,
          registrationDocId: registration?.id ?? null,
        })
    })

    try {
      dontHaveRegistrationDocId = !!recipientsToAdd.find(
        (currRecipient) => !currRecipient.registrationDocId
      )

      haveZeroAmountToPay = !!recipientsToAdd.find(
        (currRecipient) => currRecipient.amountPaid === 0
      )

      if (haveZeroAmountToPay && recipients.length < 2) return

      recipientsToAdd = recipientsToAdd.map((currRecipient) => {
        return {
          ...currRecipient,
          eventName: eventDetails?.competitionName,
          eventId: basicEventDetails.id,
          cardNo,
          ...(isPaid && { paymentStatus: 'paid' }),
        }
      })

      recipientsToUpdate = recipientsToUpdate.map((currRecipient) => {
        return {
          ...currRecipient,
          eventName: eventDetails?.competitionName,
          eventId: basicEventDetails.id,
          cardNo,
          ...(isPaid && { paymentStatus: 'paid' }),
        }
      })

      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        eventDetails,
        eventId: basicEventDetails.id,
        haveZeroAmountToPay: !(haveZeroAmountToPay && recipients.length > 1),
        dontHaveRegistrationDocId: !dontHaveRegistrationDocId,
      })

      if (emptyVarName)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'saveRecipients',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
        })

      // Add
      await helpers.asyncWhileLoop({
        loopCount: recipientsToAdd.length,
        functionToFire: async (currIndex) => {
          id = await saveRecipient(new RecipientModel(recipientsToAdd[currIndex]).toFirestore())
          recipientsToAdd[currIndex] = getConvertedData({
            ...recipientsToAdd[currIndex],
            id: id ?? null,
          })
        },
      })

      // Delete
      await helpers.asyncWhileLoop({
        loopCount: recipientsToDelete.length,
        functionToFire: async (currIndex) => {
          if (!recipientsToDelete[currIndex].id) return
          await deleteRecipient(recipientsToDelete[currIndex].id as any)
        },
      })

      // Update
      await helpers.asyncWhileLoop({
        loopCount: recipientsToUpdate.length,
        functionToFire: async (currIndex) => {
          await updateRecipient({
            ...new RecipientModel(recipientsToUpdate[currIndex]).toFirestore(),
            id: recipientsToUpdate[currIndex].id,
          })
          recipientsToUpdate[currIndex].update = false
        },
      })

      recipientsUpdated = getConvertedData([
        ...recipientsToAdd,
        ...recipientsToUpdate,
        ...recipientsUntouched,
      ])

      // Remove redundant keys
      recipientsUpdated = recipientsUpdated.map((c) => {
        return getConvertedData(new RecipientModel(c).toObject())
      })

      dispatch(setRecipientsInDb(recipientsUpdated ?? []))
    } catch (error: any) {
      error_ = CustomError.somethingWentWrong({
        ...customErrorProps,
        devMessage: error?.devMessage ?? error?.message,
        moduleName: 'saveRecipients',
      })

      helpers.logger(error_)
    } finally {
      if (error_) throw error_
      return 0
    }
  }

  function validateDataForRegisterTab(publishedEventInDb_: IEventReviewPublish | null) {
    let eventEndDate: Date | null = null
    let eventStartDate: string | Date | null = null
    let eventDescription: string | null = null
    let eventDetails: EventDetailsModel | null = null

    if (!publishedEventInDb_)
      throw CustomError.somethingWentWrong({
        ...customErrorProps,
        moduleName: 'validateDataForRegisterTab',
        devMessage: `publishedEventInDb is [${publishedEventInDb_}]`,
      })

    eventDetails = new EventDetailsModel(publishedEventInDb_.EventDetails)

    eventEndDate = eventDetails?.competitionEndDate
    eventDescription = eventDetails?.summaryLong ?? ''
    eventStartDate = eventDetails?.competitionStartDate

    const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
      eventEndDate,
      eventStartDate,
    })

    if (emptyVarName)
      throw CustomError.somethingWentWrong({
        ...customErrorProps,
        moduleName: 'validateDataForRegisterTab',
        devMessage: `${emptyVarName} is [${emptyVarValue}]`,
      })

    return {
      eventEndDate,
      eventStartDate,
      eventDescription,
    }
  }

  function getRegisterTabData(publishedEventInDb_: IEventReviewPublish | null) {
    let registerTabData_: IRegistrationTabs['IRegisterTab'][] = []
    let registrationFees_: RegistrationFeesType[] = []
    try {
      if (publishedEventInDb_) {
        const { EventFees } = publishedEventInDb_
        if ('registrationFees' in EventFees) {
          const { registrationFees } = EventFees
          if (registrationFees && registrationFees.length) {
            registrationFees_ = registrationFees
          }
        }
      }

      registrationFees_.forEach((fees) => {
        registerTabData_.push({
          feesNote: fees.note,
          registrationsByDay: [],
          price: fees.cost,
          startDate: fees.startDate,
          startTimeHours: fees.startTimeHours,
          startTimeMinutes: fees.startTimeMinutes,
          startTimeFormat: fees.startTimeFormat,
          priceAlias: fees.costAlias,
          registrationByDayName: fees.name ? (fees.name === '' ? 'Custom' : fees.name) : 'Custom',
          uuid: fees.uuid,
          order: fees.order,
          qualifyFee: fees.qualifyingClass?.price ?? null,
          children:
            fees.children?.map((children) => ({
              feesNote: fees.note,
              registrationsByDay: [],
              price: children.cost,
              startDate: fees.startDate,
              startTimeHours: fees.startTimeHours,
              startTimeMinutes: fees.startTimeMinutes,
              startTimeFormat: fees.startTimeFormat,
              priceAlias: children.costAlias,
              registrationByDayName: children.name
                ? children.name === ''
                  ? 'Custom'
                  : children.name
                : 'Custom',
              uuid: children.uuid,
              order: children.order,
              qualifyFee: children.qualifyingClass?.price ?? null,
            })) ?? [],
        })
      })
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return registerTabData_
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  function getTicketTabData(publishedEventInDb_: IEventReviewPublish | null) {
    let ticketTabData_: IRegistrationTabs['ITicketTab'][] = []

    try {
      const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        publishedEventInDb_,
        EventTickets: publishedEventInDb_?.EventTickets ?? null,
      })

      if (emptyVarName)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'getTicketTabData',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
        })

      const { EventTickets } = publishedEventInDb_ as IEventReviewPublish
      const { tickets, sellSpectatorTickets } = EventTickets

      if (!sellSpectatorTickets || !tickets?.length) return ticketTabData_

      tickets.forEach((ticketItemId) => {
        ticketTabData_.push({
          ticketItemId: ticketItemId.uuid,
          avaliability: ticketItemId.available,
          ticketPrice: ticketItemId.cost,
          ticketTitle: ticketItemId.name,
          ticketDescription: ticketItemId.note,
          registrationTicket: null,
        })
      })
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return ticketTabData_
    }
  }

  function getFeesTabData(publishedEventInDb_: IEventReviewPublish | null) {
    let availablity = 0
    let isExtraFees = false
    let feesItem: any = null
    let availabilityExists: boolean = false
    let feesTabData_: IRegistrationTabs['IFeesTab'][] = []
    let keyofeesItemKeyName_: keyof IEventFees | null = null
    let feesItem_: ICerhTypes['IEvaluatedFeeItem'] | null = null

    try {
      const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        publishedEventInDb_,
        EventFees: publishedEventInDb_?.EventFees ?? null,
      })

      if (emptyVarName)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'getFeesTabData',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
        })

      const { EventFees } = publishedEventInDb_ as IEventReviewPublish

      feesTabData_ = Object.keys(EventFees).reduce(
        (acc, feesItemKeyName) => {
          feesItem_ = null
          isExtraFees = false
          keyofeesItemKeyName_ = feesItemKeyName as any

          if (!keyofeesItemKeyName_) return acc

          feesItem = EventFees[keyofeesItemKeyName_]

          if (!feesItem) return acc
          if (feesItem && typeof feesItem === 'object' && !Array.isArray(feesItem))
            feesItem_ = cerh.getEvaluatedFeeItem(feesItem)
          else if (feesItem && Array.isArray(feesItem)) isExtraFees = true

          if (!feesItem) return acc

          if (isExtraFees) {
            feesItem.forEach((currentExtraFees: any) => {
              feesItem_ = null
              availabilityExists = false
              feesItem_ = cerh.getEvaluatedFeeItem(currentExtraFees)

              if (!feesItem_) return acc

              availabilityExists = (feesItem_?.available ?? 0) > 0
              availablity = availabilityExists ? feesItem_.available : 0

              if (availablity < 0) availablity = 0

              acc.push({
                registrationFees: null,
                feesCategory: feesItem_.category,
                feesDescription: feesItem_.note,
                feesPrice: feesItem_.cost,
                feesItemId: feesItem_.uuid,
                avaliability: availablity,
                feesTitle: feesItem_.name,
                feesDuration: feesItem_.duration,
              })
            })
          } else if (feesItem_ && feesItem.category !== 'refund') {
            if (Object.values(FEES_CATEGORY_CONST).includes(feesItem_.category)) {
              availabilityExists = (feesItem_?.available ?? 0) > 0
              availablity = availabilityExists ? feesItem_.available : 0

              if (availablity < 0) availablity = 0

              if (FEES_CATEGORY_CONST.GOVERNANCE === feesItem_?.category) {
                feesItem_.duration =
                  FEES_CATEGORY_CONST.GOVERNANCE === feesItem_.category
                    ? 'Per horse'
                    : feesItem_.duration
              }

              acc.push({
                registrationFees: null,
                feesCategory: feesItem_.category,
                feesDescription: feesItem_.note,
                feesPrice: feesItem_.cost,
                feesItemId: feesItem_.uuid,
                avaliability: availablity,
                feesTitle: feesItem_.name,
                feesDuration: feesItem_.duration,
              })
            }
          }

          return acc
        },
        [] as IRegistrationTabs['IFeesTab'][]
      )
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return feesTabData_
    }
  }

  async function getPublishedEventFromDb() {
    let publishedEventInDb_: IEventReviewPublish | null = null

    try {
      const publishedEventInDbSnapShot = await FirestoreService.getItem(
        COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
        eventId
      )

      if (publishedEventInDbSnapShot.exists())
        publishedEventInDb_ = getConvertedData(
          EventReviewPublishModel.fromFirestoreDoc(publishedEventInDbSnapShot).toObject()
        )
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return publishedEventInDb_
    }
  }

  async function getTeamMembersFromDb(
    registrationId: string | null,
    registeredUser: IUserInterface
  ) {
    let memberName: string = ''
    let userIdsList: string[] = []
    let usersList: IUserInterface[] = []
    let currUser: IUserInterface | null = null
    let usersSnaps: QueryDocumentSnapshot<DocumentData>[] = []
    let owner: IRegistrationTeamsTypes['IRegistrationTeamInterface'] | null = null
    let registrationTeams: IRegistrationTeamsTypes['IRegistrationTeamInterface'][] = []

    try {
      owner = getConvertedData(getSelectedUserAsTeamMember(registeredUser, registeredUser))
      owner.eventId = eventId
      registrationTeams.push(owner)

      if (!registrationId) return registrationTeams

      const registrationTeamMemberSnapshot = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_TEAMS.NAME,
        [where(COLLECTIONS.REGISTRATION_TEAMS.FIELDS.REGISTRATION_DOC_ID.KEY, '==', registrationId)]
      )

      registrationTeamMemberSnapshot.docs.forEach((currDoc) => {
        registrationTeams.push(
          getConvertedData(RegistrationTeamModel.fromFirestoreDoc(currDoc).toObject())
        )
      })

      registrationTeams.forEach((currMember) => {
        if (currMember?.memberId) userIdsList.push(currMember.memberId)
      })

      userIdsList = [...new Set(userIdsList)]

      usersSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, userIdsList)

      usersSnaps.forEach((curr) => {
        usersList.push(getConvertedData(UserModel.fromFirestoreDoc(curr).toObject()))
      })

      registrationTeams = registrationTeams.map((currMember) => {
        currUser = usersList.find((currUser_) => currUser_?.id === currMember.memberId) ?? null

        if (currUser) {
          if (!!currUser.userFirstName) memberName = currUser.userFirstName

          if (!!memberName && !!currUser.userLastName)
            memberName = `${currUser.userFirstName} ${currUser.userLastName}`
        } else {
          memberName = currMember.memberName ?? 'Unknown'
        }

        if (!currUser) return getConvertedData(currMember)
        else
          return getConvertedData({
            ...currMember,
            memberName,
            memberEmail: currUser?.userEmail,
            memberProfilePicture: currUser?.userProfilePicture,
          })
      })
    } catch (e) {
      helpers.logger({
        isError: true,
        message: e,
      })
    } finally {
      return registrationTeams
    }
  }

  async function getRegistrationsByDayFromDb(registrationId: string) {
    let registrationsByDay_: IRegistrationByDayInterface[] = []

    try {
      const registrationByDaySnapShots = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        [
          where(
            COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ]
      )

      registrationByDaySnapShots.forEach((currDoc) => {
        registrationsByDay_.push(
          getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
        )
      })
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return registrationsByDay_
    }
  }

  async function getRegistrationFromDb() {
    let queries = getBasicQueries()
    let registration: null | TEventRegisteredUsers = null

    try {
      const registrationSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        queries,
        1
      )

      if (registrationSnapShots.size)
        registration = getConvertedData(
          EventRegisteredUsersModel.fromFirestoreDoc(registrationSnapShots.docs[0]).toObject()
        )
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return registration
    }
  }

  async function getRiderTeamMembersFromDb(registrationId: string) {
    let riderTeamMembers: IRiderTeamMemberInterface[] = []
    try {
      const ticketSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [
          where(
            COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ]
      )

      if (ticketSnapShots.size) {
        ticketSnapShots.forEach((currDoc) => {
          riderTeamMembers.push(
            getConvertedData(RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return riderTeamMembers
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async function getRecipientsInDb(registrationId: string) {
    let recipients: IRecipientInterface[] = []

    try {
      const recipientSnapShots = await FirestoreService.filterItems(COLLECTIONS.RECIPIENT.NAME, [
        where(COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY, '==', registrationId),
      ])

      if (recipientSnapShots.size) {
        recipientSnapShots.forEach((currDoc) => {
          recipients.push(getConvertedData(RecipientModel.fromFirestoreDoc(currDoc).toObject()))
        })
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return recipients
    }
  }

  async function getTicketTabDataFromDb(registrationId: string) {
    let tickets_: IRegistrationTicketInterface[] = []
    try {
      const ticketSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_TICKET.NAME,
        [
          where(
            COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            registrationId
          ),
        ]
      )

      if (ticketSnapShots.size) {
        ticketSnapShots.forEach((currDoc) => {
          tickets_.push(
            getConvertedData(RegistrationTicketModel.fromFirestoreDoc(currDoc).toObject())
          )
        })
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return tickets_
    }
  }

  async function getFeesFromDb() {
    let queries = getBasicQueries()
    let registionFees: IRegistrationFeesInterface[] = []

    try {
      const registrationFeesSnapShots = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_FEES.NAME,
        queries
      )

      registrationFeesSnapShots.forEach((currDoc) => {
        registionFees.push(
          getConvertedData(RegistrationFeesModel.fromFirestoreDoc(currDoc).toObject())
        )
      })
    } catch (error: any) {
      helpers.logger(
        CustomError.somethingWentWrong({
          message: error?.message,
          devMessage: error?.message,
          fileName: FILE_NAME,
        })
      )
    } finally {
      return registionFees
    }
  }

  const onRegistrationTicketCountChange: ITypes['IOnRegistrationTicketCountChangeFn'] = (args) => {
    let update = false
    let ticketTabRowIndex = -1
    let ticketTabData_ = cloneDeep(ticketTabData)
    let EventFees = publishedEventInDb?.EventFees ?? null
    let EventDetails = publishedEventInDb?.EventDetails ?? null
    let registrationTicket_: null | IRegistrationTicketInterface = null
    let registrationTicketInRedux: null | IRegistrationTicketInterface = null

    const { ticketItemId, value } = args

    try {
      const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        value,
        EventFees,
        EventDetails,
        ticketItemId,
      })

      if (emptyVarName) {
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'onRegistrationTicketCountChange',
          devMessage: `${emptyVarName} is [${emptyVarValue}]`,
        })
      }

      ticketTabRowIndex = ticketTabData_.findIndex(
        (currRow) => currRow.ticketItemId === ticketItemId
      )

      if (ticketTabRowIndex === -1)
        throw CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'onRegistrationTicketCountChange',
          devMessage: `Ticket row having ticketItemId:${ticketItemId} not found`,
        })

      update = !!ticketTabData_[ticketTabRowIndex]?.registrationTicket
      registrationTicket_ = ticketTabData_?.[ticketTabRowIndex]?.registrationTicket ?? null

      if (update && registrationTicket_) {
        registrationTicketInRedux =
          registrationTickets.find((currRegistrationTicket) => {
            return currRegistrationTicket.ticketItemId === registrationTicket_?.ticketItemId
          }) ?? null

        if (!registrationTicketInRedux) {
          throw CustomError.somethingWentWrong({
            ...customErrorProps,
            moduleName: 'onRegistrationTicketCountChange',
            devMessage: `registrationTicketInRedux is ${registrationTicketInRedux}`,
          })
        }

        registrationTicket_ = {
          ...registrationTicket_,
          ...registrationTicketInRedux,
          selectedUnitsCount: Number(value),
          remainingTicketsCount: Number(value),
          update: true,
        }
      } else {
        registrationTicket_ = getConvertedData({
          ...new RegistrationTicketModel({
            userId,
            ticketItemId,
            refundId: null,
            recipientId: null,
            refundDocId: null,
            amountRefunded: 0,
            eventId,
            recipientProfilePicture: null,
            userName: registeredUser ? getUserFullName(registeredUser) : null,
            selectedUnitsCount: Number(value),
            remainingTicketsCount: Number(value),
            registrationDocId: registration?.id ?? null,
            eventName: EventDetails?.competitionName ?? null,
            amountScratched: 0,
            ticketTitle: ticketTabData_?.[ticketTabRowIndex].ticketTitle,
            ticketPrice: ticketTabData_?.[ticketTabRowIndex].ticketPrice ?? 0,
          }).toObject(),
        })
      }

      ticketTabData_[ticketTabRowIndex].registrationTicket = registrationTicket_

      if (!args.notSave) {
        dispatch(
          updateTicketTabRowInReduxAc({
            ticketTabRowIndex,
            dataToUpdate: registrationTicket_,
          })
        )
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      if (ticketTabData_[ticketTabRowIndex]) return ticketTabData_[ticketTabRowIndex]
      return null
    }
  }

  function mergeRegistrationWithTabData(
    registerTabData_: IRegistrationTabs['IRegisterTab'][],
    registrationsByDay_: IRegistrationByDayInterface[]
  ) {
    let registerTabData__ = cloneDeep(registerTabData_)
    let registrationsByDay__ = cloneDeep(registrationsByDay_)

    registerTabData__ = registerTabData__.map((currRow) => {
      return {
        ...currRow,
        registrationsByDay: [],
      }
    })

    registerTabData__ = registerTabData__.reduce(
      (acc, currRow) => {
        acc.push(currRow)
        registrationsByDay__.forEach((currRegistrationsByDay) => {
          if (currRegistrationsByDay.uuid === currRow.uuid) {
            acc.find((currAccObj, index) => {
              if (currAccObj.uuid === currRow.uuid) {
                acc[index].registrationsByDay.push(
                  getConvertedData(new RegistrationByDayModel(currRegistrationsByDay).toObject())
                )
                return true
              }
              return false
            })
          }
        })
        return acc
      },
      [] as IRegistrationTabs['IRegisterTab'][]
    )

    return registerTabData__
  }

  function mergeTicketsWithTabData(
    ticketTabData_: IRegistrationTabs['ITicketTab'][],
    registrationTickets_: IRegistrationTicketInterface[]
  ) {
    let foundRegistrationTicket: null | IRegistrationTicketInterface = null
    let ticketTabData__ = cloneDeep(ticketTabData_)
    let registrationTickets__ = cloneDeep(registrationTickets_)

    // Remove all registrationTickets
    ticketTabData__ = ticketTabData__.map((currRow) => {
      return {
        ...currRow,
        registrationTicket: null,
      }
    })

    ticketTabData__ = ticketTabData__.map((currRow) => {
      foundRegistrationTicket =
        registrationTickets__.find((currRegistrationTicket) => {
          return currRegistrationTicket.ticketItemId === currRow.ticketItemId
        }) ?? null

      return {
        ...currRow,
        registrationTicket: foundRegistrationTicket,
      }
    })

    return ticketTabData__
  }

  const onAddRiderButtonClick: IUseEventRegistrationTabsTypes['IOnAddRiderButtonClickFn'] = ({
    paperworkIndex,
    row,
    currentPaperworkTabData,
    manageInfo,
    setManageInfo,
    isManage,
  }) => {
    let paperworkTabData_ = cloneDeep(currentPaperworkTabData)
    let registrationByDay_

    if (isManage) {
      const currentRegistrationsByDay: IRegistrationByDayInterface[] = []

      manageInfo.register.forEach((register) =>
        register.registrationsByDay.forEach((registrationByDay) =>
          currentRegistrationsByDay.push(registrationByDay)
        )
      )

      registrationByDay_ = currentRegistrationsByDay.find((currRegistrationByDayInDb) => {
        return currRegistrationByDayInDb.uuid === row?.registrationByDayUuid
      })
    } else {
      registrationByDay_ = registrationsByDay.find((currRegistrationByDayInDb) => {
        return currRegistrationByDayInDb.uuid === row?.registrationByDayUuid
      })
    }

    paperworkTabData_[paperworkIndex].ridersTeamMembers.push(
      getConvertedData({
        ...new RiderTeamMemberModel({
          riderDob: registrationByDay_?.riderDob ?? null,
          riderName: registrationByDay_?.riderName ?? null,
          riderId: registrationByDay_?.riderId ?? null,
          mailLog: [],
          horseId: registrationByDay_?.horseId ?? null,
          horseName: registrationByDay_?.horseName ?? null,
          horseProfilePicture: registrationByDay_?.horseProfilePicture ?? null,
          userHorseMappingDocId: registrationByDay_?.userHorseMappingDocId ?? null,
          teamMemberId: null,
          teamMemberName: null,
          teamMemberProfilePicture: null,
          teamMemberRole: null,
          teamMemberDob: null,
          teamMemberEmail: null,
          userId: registrationByDay_?.userId ?? null,
          eventId: registrationByDay_?.eventId ?? null,
          eventName: registrationByDay_?.eventName ?? null,
          registrationDocId: registrationByDay_?.registrationDocId ?? null,
          registrationByDayDocId: registrationByDay_?.id ?? null,
          registrationByDayUuid: registrationByDay_?.uuid ?? null,
        }).toObject(),
        roles: CONST.UI.ROLES,
      })
    )

    let ridersTeamMembers_: IRiderTeamMemberInterface[] = []
    paperworkTabData_.forEach((paperwork) => {
      if (paperwork.ridersTeamMembers.length > 0) {
        ridersTeamMembers_.push(...paperwork.ridersTeamMembers)
      }
    })

    dispatch(setRidersTeamMembers(ridersTeamMembers_))

    if (isManage) {
      setManageInfo({ ...manageInfo, paperwork: paperworkTabData_ })
    } else {
      dispatch(setPaperworkTabData(paperworkTabData_))
    }
  }

  const onRiderTeamMemberChange: ITypes['IOnRiderTeamMemberChangeFn'] = ({
    paperworkIndex,
    currentPaperworkTabData,
    currentTeamMember,
    ridersTeamMemberIndex,
    isManage,
    manageInfo,
    setManageInfo,
  }) => {
    let paperworkTabData_ = cloneDeep(currentPaperworkTabData)
    const ridersTeamMember =
      paperworkTabData_[paperworkIndex].ridersTeamMembers[ridersTeamMemberIndex]

    paperworkTabData_[paperworkIndex].ridersTeamMembers[ridersTeamMemberIndex] = {
      ...ridersTeamMember,
      teamMemberEmail: currentTeamMember.memberEmail,
      teamMemberRole: currentTeamMember.memberRole,
      teamMemberId: currentTeamMember.memberId,
      teamMemberName: currentTeamMember.memberName,
      teamMemberProfilePicture: currentTeamMember.memberProfilePicture,
      teamMemberDob: currentTeamMember.memberDob,
    }

    let ridersTeamMembers_: IRiderTeamMemberInterface[] = []
    paperworkTabData_.forEach((paperwork) => {
      if (paperwork.ridersTeamMembers.length > 0) {
        ridersTeamMembers_.push(...paperwork.ridersTeamMembers)
      }
    })

    dispatch(setRidersTeamMembers(ridersTeamMembers_))

    if (isManage) {
      setManageInfo({ ...manageInfo, paperwork: paperworkTabData_ })
    } else {
      dispatch(setPaperworkTabData(paperworkTabData_))
    }
  }

  const onRiderTeamMemberRoleChange: ITypes['IOnRiderTeamMemberRoleChangeFn'] = async ({
    teamMemberId,
    teamMemberRole,
    paperworkIndex,
    currentPaperworkTabData,
    ridersTeamMemberIndex,
    manageInfo,
    setManageInfo,
    isManage,
  }) => {
    try {
      let paperworkTabData_ = cloneDeep(currentPaperworkTabData)

      if (!teamMemberId) {
        toastFunctions.info({
          message: `Please select team member first`,
        })
        return
      }

      let { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        teamMemberId,
      })

      if (emptyVarName) {
        helpers.logger({
          message: CustomError.somethingWentWrong({
            ...customErrorProps,
            moduleName: 'onRiderTeamMemberRoleChange',
            devMessage: `${emptyVarName} is ${emptyVarValue}`,
          }),
        })
        return
      }

      paperworkTabData_[paperworkIndex].ridersTeamMembers[ridersTeamMemberIndex].teamMemberRole =
        teamMemberRole

      let ridersTeamMembers_: IRiderTeamMemberInterface[] = []
      paperworkTabData_.forEach((paperwork) => {
        if (paperwork.ridersTeamMembers.length > 0) {
          ridersTeamMembers_.push(...paperwork.ridersTeamMembers)
        }
      })

      dispatch(setRidersTeamMembers(ridersTeamMembers_))

      if (isManage) {
        setManageInfo({ ...manageInfo, paperwork: paperworkTabData_ })
      } else {
        dispatch(setPaperworkTabData(paperworkTabData_))
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    }
  }

  const onRemoveRiderTeamMemberClick: ITypes['IOnRemoveRiderTeamMemberClickFn'] = async ({
    paperworkIndex,
    currentPaperworkTabData,
    teamMemberIndex,
    manageInfo,
    setManageInfo,
    isManage,
  }) => {
    let paperworkTabData_ = cloneDeep(currentPaperworkTabData)

    try {
      paperworkTabData_[paperworkIndex].ridersTeamMembers.splice(teamMemberIndex, 1)

      let ridersTeamMembers_: IRiderTeamMemberInterface[] = []
      paperworkTabData_.forEach((paperwork) => {
        if (paperwork.ridersTeamMembers.length > 0) {
          ridersTeamMembers_.push(...paperwork.ridersTeamMembers)
        }
      })

      dispatch(setRidersTeamMembers(ridersTeamMembers_))

      if (isManage) {
        setManageInfo({ ...manageInfo, paperwork: paperworkTabData_ })
      } else {
        dispatch(setPaperworkTabData(paperworkTabData_))
      }
    } catch (error) {
      helpers.logger({
        message: error,
      })
    }
  }

  function mergeFeesWithTabData(
    feesTabData_: IRegistrationTabs['IFeesTab'][],
    registrationFees_: IRegistrationFeesInterface[]
  ) {
    let foundRegistrationFees: null | IRegistrationFeesInterface = null
    let feesTabData__ = cloneDeep(feesTabData_)
    let registrationFees__ = cloneDeep(registrationFees_)

    // Remove all registrationFees
    feesTabData__ = feesTabData__.map((currRow) => {
      return {
        ...currRow,
        registrationFees: null,
      }
    })

    feesTabData__ = feesTabData__.map((currRow) => {
      foundRegistrationFees =
        registrationFees__.find((currRegistrationFees) => {
          return currRegistrationFees.feesItemId === currRow.feesItemId
        }) ?? null

      if (!foundRegistrationFees && currRow.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE) {
        foundRegistrationFees = new RegistrationFeesModel({
          userId,
          create: true,
          refundId: null,
          eventName: null,
          recipientId: null,
          amountRefunded: 0,
          refundDocId: null,
          amountScratched: 0,
          eventId,
          recipientProfilePicture: null,
          feesItemId: currRow.feesItemId,
          selectedUnitsCount: currRow.registrationFees?.selectedUnitsCount ?? 0,
          registrationDocId: registration?.id ?? null,
          feesTitle: currRow.feesTitle,
          feesPrice: currRow.feesPrice ?? 0,
          userName: registeredUser ? getUserFullName(registeredUser) : null,
          feesItemCategory: currRow.feesCategory ?? null,
        }).toObject()
      }

      if (foundRegistrationFees && currRow.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE) {
        const horsesIds: string[] = []

        registrationsByDay.forEach((registrationByDay) => {
          if (
            registrationByDay.horseId &&
            !horsesIds.includes(registrationByDay.horseId) &&
            !registrationByDay.isSratched
          ) {
            horsesIds.push(registrationByDay.horseId)
          }
        })

        foundRegistrationFees.selectedUnitsCount = horsesIds.length
      }

      return {
        ...currRow,
        registrationFees: foundRegistrationFees ? getConvertedData(foundRegistrationFees) : null,
      }
    })

    return feesTabData__
  }

  function mergeRidersTeamMembersWithPaperworkTabData(
    paperworkTabData_: IRegistrationTabs['IPaperworkTab'][],
    ridersTeamMembers: IRiderTeamMemberInterface[],
    keysToNotRemove: (keyof IRiderTeamMemberInterface)[] = []
  ) {
    let foundRiderTeamMembers: IRiderTeamMemberInterface[] = []
    let paperworkTabData__ = cloneDeep(paperworkTabData_)
    let ridersTeamMembers_ = cloneDeep(ridersTeamMembers)

    ridersTeamMembers_ = ridersTeamMembers_.map((currRidersTeamMember) => {
      return getConvertedData({
        ...new RiderTeamMemberModel(currRidersTeamMember).toObject(),
        ...keysToNotRemove.reduce(
          (
            acc: (keyof IRiderTeamMemberInterface)[],
            currKeyToNotRemove: keyof IRiderTeamMemberInterface
          ) => {
            acc = {
              ...acc,
              [currKeyToNotRemove]: currRidersTeamMember[currKeyToNotRemove],
            }
            return acc
          },
          []
        ),
      })
    })

    paperworkTabData__ = paperworkTabData__.map((currPaperworkRow) => {
      foundRiderTeamMembers = ridersTeamMembers_.filter((currRidersTeamMember) => {
        return (
          currRidersTeamMember.registrationByDayDocId === currPaperworkRow.registrationByDayDocId
        )
      })
      return {
        ...currPaperworkRow,
        ridersTeamMembers: getSerializedRiderTeamMembers(foundRiderTeamMembers),
      }
    })

    return paperworkTabData__
  }

  async function load() {
    let registrationId: string | null = null
    let recipientsInDb: IRecipientInterface[] = []
    let registionFees_: IRegistrationFeesInterface[] = []
    let registration_: null | TEventRegisteredUsers = null
    let publishedEventInDb_: IEventReviewPublish | null = null
    let registrationsByDay_: IRegistrationByDayInterface[] = []
    let registerTabData_: IRegistrationTabs['IRegisterTab'][] = []
    let registrationTickets: IRegistrationTicketInterface[] = []
    let ticketTabData_: IRegistrationTabs['ITicketTab'][] = []
    let feesTabData_: IRegistrationTabs['IFeesTab'][] = []
    let filteredPaperworkDocuments: typeof fakeDocumentList = []
    let ridersTeamMembersInDb: IRiderTeamMemberInterface[] = []

    try {
      publishedEventInDb_ = await getPublishedEventFromDb()

      registration_ = await getRegistrationFromDb()
      registrationId = registration_?.id ?? null

      if (registrationId) {
        registionFees_ = await getFeesFromDb()
        registrationsByDay_ = await getRegistrationsByDayFromDb(registrationId)
        registrationTickets = await getTicketTabDataFromDb(registrationId)
        ridersTeamMembersInDb = await getRiderTeamMembersFromDb(registrationId)
        recipientsInDb = await getRecipientsInDb(registrationId)
      }

      feesTabData_ = getFeesTabData(publishedEventInDb_)
      registerTabData_ = getRegisterTabData(publishedEventInDb_)
      filteredPaperworkDocuments = getFilteredPaperworks(
        publishedEventInDb_?.EventPaperwork ?? null
      )
      registerTabData_ = mergeRegistrationWithTabData(registerTabData_, registrationsByDay_)
      ticketTabData_ = getTicketTabData(publishedEventInDb_)
      ticketTabData_ = mergeTicketsWithTabData(ticketTabData_, registrationTickets)
      feesTabData_ = mergeFeesWithTabData(feesTabData_, registionFees_)
    } catch (error: any) {
      helpers.logger({
        message: `${error?.message} ${FILE_NAME}`,
      })
    } finally {
      setPublishedEventInDb(publishedEventInDb_)
      dispatch(setRegistrationAc(registration_))
      dispatch(setRecipientsInDb(recipientsInDb))
      dispatch(setRidersTeamMembersInDb(ridersTeamMembersInDb))
      dispatch(setRegistrationsFeesAc(registionFees_))
      dispatch(setRegistrationsTicketsAc(registrationTickets))
      dispatch(setRegisterTabData(registerTabData_))
      dispatch(setTicketTabData(ticketTabData_))
      dispatch(setFeesTabDataAc(feesTabData_))
      dispatch(setFilteredPaperworkDocuments(filteredPaperworkDocuments))
      dispatch(setRegistrationLoading(false))
    }
  }

  useEffect(() => {
    // we need to update the "selectedUnitsCount" key in "governance" when changing the horse
    const feesTabData_ = mergeFeesWithTabData(feesTabData, registrationFees)
    dispatch(setFeesTabDataAc(feesTabData_))
  }, [registrationsByDay])

  return {
    loading,
    tickets: [],
    registration,
    ticketTabData,
    registerTabData,
    registrationFees,
    createRegistration,
    saveTeamTabData,
    registrationsByDay,
    savePaperworkTabData,
    onAddRiderButtonClick,
    onRiderTeamMemberChange,
    onRegistrationMemberChange,
    onRemoveRiderTeamMemberClick,
    onRegistrationByDayHorseChange,
    onRegistrationTicketCountChange,
    onRiderTeamMemberRoleChange,
    feesItemsForm: feesItemsForm,
    ticketItemsForm: ticketItemsForm,
    mergeRidersTeamMembersWithPaperworkTabData,
    onInputChangeTicket,
    onChangeFees,
    saveRegisterTabData,
    saveFeesTabData,
    saveTicketTabData,
    saveRecipients,
    setIsManage,
    registeredUser,
  }
}

export default useEventRegistrationTabs
