// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

import { Skeleton } from '@mui/material'

// Types
type IProps = {
  message?: string
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const NoDataFoundSkeleton = (props: IProps) => {
  // Vars

  return (
    <>
      <div className="w-full flex items-center justify-center mb-40 mt-24">
        <div className="text-center max-w-[230px] text-[#f6f7fb]">
          {props?.message ? <h6 className="mb-8">{props?.message}</h6> : null}
          <div className="flex flex-wrap flex-col gap-3 items-center w-full text-[#f6f7fb] border border-solid-50 border-[#f6f7fb] relative p-3">
            <div className="flex gap-2 w-full">
              <Skeleton
                animation="pulse"
                variant="rounded"
                width={80}
                height={80}
                className="mb-5 !bg-white border-[6px] border-solid border-[#E2E2E2] !flex justify-center items-center"
              >
                <Skeleton
                  animation={false}
                  variant="rounded"
                  width={60}
                  height={60}
                  className="!visible"
                />
              </Skeleton>
              <div className="flex flex-col justify-center gap-2">
                <Skeleton animation="pulse" variant="rounded" className="w-[90px] !h-1.5" />
              </div>
            </div>
            <div className="w-full flex flex-col items-start">
              <Skeleton animation="pulse" variant="rounded" className="mb-2.5 w-[180px] !h-1.5" />
              <Skeleton animation="pulse" variant="rounded" className="mb-2.5 w-[160px] !h-1.5" />
              <Skeleton animation="pulse" variant="rounded" className="mb-2.5 w-[140px] !h-1.5" />
              <Skeleton animation="pulse" variant="rounded" className="mb-2.5 w-[160px] !h-1.5" />

              <Skeleton animation="pulse" variant="rounded" className="mb-2.5 w-[180px] !h-1.5" />
              <Skeleton animation="pulse" variant="rounded" className="mb-4 w-[100px] !h-1.5" />
              <div className="w-[180px] flex items-center justify-end animate-pulse space-x-20">
                <img
                  className="w-[4rem] h-[1.5rem] ml-1"
                  src="/assets/og_icons/signature.svg"
                  alt="icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoDataFoundSkeleton
