import TimeLib from '../../../../lib/Time'

export const DATA_FIRESTORE_V01_USERS_DOCUMENTS = {
  NAME: 'v01_users_documents',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    TYPE: {
      KEY: 'type',
      VALUE: {
        REGULAR: 'regular',
        COMPETITION: 'competition',
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    ID: {
      KEY: 'id',
    },

    IS_RIDER: {
      KEY: 'isrider',
    },

    USER_ID: {
      KEY: 'userId',
    },

    DOCUMENT_URL: {
      KEY: 'documentUrl',
    },

    RIDER_ID: {
      KEY: 'riderId',
    },

    SIGNATORY_ID: {
      KEY: 'signatoryId',
    },

    SIGNATORY_DEFAULT_ROLE: {
      KEY: 'signatoryDefaultRole',
    },

    DOCUMENT_OWNER: {
      KEY: 'documentOwner',
    },

    REGISTRATION_DOC_ID: {
      KEY: 'registrationDocId',
      VALUES: {
        DEFAULT: null,
      },
    },
    EVENT_ID: {
      KEY: 'eventId',
    },

    DOCUMENT_NAME: {
      KEY: 'documentOriginalName',
    },
    DOCUMENT_SEARCH_NAME: {
      KEY: 'documentName',
    },
    COMPITETOR_ID: {
      KEY: 'competitorId',
    },
    TAB: {
      KEY: 'tab',
      VALUE: {
        SIGNED: 'Signed',
        UNSIGNED: 'Unsigned',
      },
    },

    STEP: {
      KEY: 'step',
    },
  },
}
