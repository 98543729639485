// Components
import ManageClinicNOtherCard from '../../../common/card/ManageClinicNOtherCard'
import ManageClinicNOtherGraphSection from '../../ManageClinicNOtherGraphSection'

// Constants
import { ICONS_SRC } from '../../ManageClinicNOtherRootPage'

// Redux
import { useAppSelector } from '../../../../../../store/hooks'

// Util
import { EventModel } from '../../../../../../models/events/event.model'
import { selectedEvent } from '../../../../../../store/events/eventsSlice'
import { ROUTES_CONST } from '../../../../../../const/routes-const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%\

// Types
type Props = {
  eventId: string
}

const ManageClinicNOtherFinancesTab = (props: Props) => {
  // Hooks and vars
  const event = useAppSelector(selectedEvent)?.Event ?? new EventModel().toObject()

  const expectedRevenue =
    event.expectedFeesRevenue + event.expectedRegistrationRevenue + event.expectedTicketRevenue
  const totalRevenue =
    event.currentFeesRevenue + event.currentRegistrationRevenue + event.currentTicketRevenue

  return (
    <>
      <ManageClinicNOtherCard
        title={'Exhibitor revenue >'}
        category="finance"
        url={`${ROUTES_CONST.MANAGE.REVENUE.URL}/registration/${props.eventId}`}
        icon={ICONS_SRC.EXHIBITOR}
        value1={'$' + totalRevenue?.toLocaleString() ?? '$0'}
        value2={'$' + `${expectedRevenue?.toLocaleString()}`.toString() ?? '$0'}
        className={`w-full md:w-1/4`}
      />
      <ManageClinicNOtherCard
        disabled={false}
        title={'Spectator revenue >'}
        url={`${ROUTES_CONST.MANAGE.REVENUE.URL}/spectator/${props.eventId}`}
        category="finance"
        icon={ICONS_SRC.SPECTATOR}
        value1={'$' + event.currentTicketRevenue?.toLocaleString() ?? '$0'}
        value2={'$' + `${event.expectedTicketRevenue?.toLocaleString()}`.toString() ?? '$0'}
        className={`w-full md:w-1/4`}
      />

      <ManageClinicNOtherCard
        disabled={true}
        title={'Vendor revenue >'}
        icon={ICONS_SRC.VENDOR}
        category="finance"
        url={`${ROUTES_CONST.MANAGE.REVENUE.URL}/vendors/${props.eventId}`}
        value1={'$0'}
        value2={'$0'}
        className={`w-full md:w-1/4`}
      />
      <ManageClinicNOtherCard
        disabled={true}
        title={'Sponsor revenue >'}
        category="finance"
        url={`${ROUTES_CONST.MANAGE.REVENUE.URL}/sponsors/${props.eventId}`}
        icon={ICONS_SRC.SPONSOR}
        value1={'$0'}
        value2={'$0'}
        className={`w-full md:w-1/4`}
      />

      <ManageClinicNOtherGraphSection />
    </>
  )
}

export default ManageClinicNOtherFinancesTab
