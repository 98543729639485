export const IconMail = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={16} fill="none">
      <path
        fill="#8795a2"
        d="M3.25 0A3.262 3.262 0 0 0 0 3.25v9.5A3.262 3.262 0 0 0 3.25 16h13.5A3.261 3.261 0 0 0 20 12.75v-9.5A3.262 3.262 0 0 0 16.75 0H3.25Zm0 1.5h13.5c.975 0 1.75.775 1.75 1.75v.553L10 8.397 1.5 3.803V3.25c0-.975.775-1.75 1.75-1.75ZM1.5 5.508 9.644 9.91a.75.75 0 0 0 .712 0L18.5 5.508v7.242a1.74 1.74 0 0 1-1.75 1.75H3.25a1.74 1.74 0 0 1-1.75-1.75V5.508Z"
      />
    </svg>
  )
}
