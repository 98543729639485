const IMAGE_URLS = {
  OK: '/assets/cp_icons/Ok-3.svg',
  NOT_OK: '/assets/cp_icons/Cancel-2.svg',
}

const TAB_NAMES = {
  STALLS: { name: 'Stalls', value: 'stalls' },
  FEED: { name: 'Feed', value: 'feed' },
  BEDDING: { name: 'Bedding', value: 'bedding' },
  OTHER: { name: 'Other', value: 'other' },
  ALL: { name: 'All', value: 'all' },
}

const TABS = [
  { tab: TAB_NAMES.ALL.name, id: TAB_NAMES.ALL.value, disabled: true },
  { tab: TAB_NAMES.STALLS.name, id: TAB_NAMES.STALLS.value },
  { tab: TAB_NAMES.FEED.name, id: TAB_NAMES.FEED.value, disabled: true },
  { tab: TAB_NAMES.BEDDING.name, id: TAB_NAMES.BEDDING.value, disabled: true },
  { tab: TAB_NAMES.OTHER.name, id: TAB_NAMES.OTHER.value, disabled: true },
]

export const MANAGE_LOGISTICS_CONSTS = {
  TABS,
  TAB_NAMES,
  IMAGE_URLS,
}
