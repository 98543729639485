// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React from 'react'

import GreenCheck from './assets/green-check.svg'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export interface PropTypes {
  type?: string
  value?: string
  onChange?: (value: any) => void
  onClick?: (value: any) => void
  setValue?: any
  trigger?: any
  disabled?: boolean
  placeholder?: string
  register?: any
  name?: any
  notChecked?: boolean | undefined
  Icon?: any
  className?: string
  openAddNotesModal?: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const CheckedInput: React.FC<PropTypes> = ({
  type,
  value,
  onChange,
  disabled,
  placeholder,
  notChecked,
  onClick,
  name,
  register,
  Icon,
  className,
  openAddNotesModal,
}) => {
  const openAddNotesModal_ = typeof openAddNotesModal === 'function' ? openAddNotesModal : () => {}
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div
      className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12"
      onClick={onClick}
    >
      {Icon && <Icon className="text-SeabiscuitDark200ThemeColor" />}

      <input
        type={type}
        onChange={onChange}
        className={`${className} w-full bg-transparent outline-0 text-SeabiscuitDark200ThemeColor pr-2`}
        disabled={disabled}
        {...(typeof register === 'function'
          ? (function () {
              return register(name)
            })()
          : { name })}
        onClick={(e: any) => openAddNotesModal_(e.target)}
        placeholder={placeholder}
        defaultValue={disabled ? 'N/A' : value}
      />

      {!disabled && !notChecked && <img src={GreenCheck} alt="green check" />}
    </div>
  )
}

export default CheckedInput
