import CallMadeIcon from '@mui/icons-material/CallMade'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import customImageComponent from '../../../../../../components/common/CustomImageComponent'
import MainModal from '../../../../../../components/modals/common/MainModal'
import { IMAGE_CONSTS } from '../../../../../../const/image-const'
import { IDealInterface } from '../../../../../../models/deal/deal.interface'

type IDealModalProps = {
  show: boolean
  dataToPassOn: IDealInterface
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const DealModal = (props: IDealModalProps) => {
  const dataToPassOn = props.dataToPassOn
  const [description, setDescription] = useState<string[]>([])

  useEffect(() => {
    const description = dataToPassOn?.description ?? ''
    setDescription(description.split('\n'))
  }, [dataToPassOn?.description])

  return (
    <MainModal
      title={dataToPassOn.title ?? 'N/A'}
      show={props.show}
      type="DEAL"
      className="mt-4"
      setHeightAsPerContent={true}
      size="lg"
    >
      <>
        <div className="flex gap-4 rounded-xl mt-3 md:flex-row flex-col min-h-[190px] text-SeabiscuitDark200ThemeColor">
          <div className="flex flex-col gap-4">
            {dataToPassOn?.pictures?.length
              ? dataToPassOn?.pictures.map((currImage, index) => {
                  return (
                    <React.Fragment key={`currImage${index}`}>
                      {customImageComponent(
                        currImage ?? IMAGE_CONSTS.PLACEHOLDERS.NEWS,
                        '',
                        'object-cover rounded-xl !w-[120px] !h-[120px] rounded-xl overflow-hidden'
                      )}
                    </React.Fragment>
                  )
                })
              : customImageComponent(
                  IMAGE_CONSTS.PLACEHOLDERS.NEWS,
                  '',
                  'object-cover rounded-xl !w-[120px] !h-[120px] rounded-xl overflow-hidden'
                )}
          </div>

          <div className="flex-1 flex-col flex gap-3.5 max-w-[calc(100%-120px)] max-h-[300px] overflow-auto">
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <div
                  className={clsx(
                    'text-base opacity-70 min-h-[24px] min-w-[30px] rounded-md',
                    typeof dataToPassOn.discountPrice === 'number' && 'line-through'
                  )}
                >
                  ${dataToPassOn.price}
                </div>

                {typeof dataToPassOn.discountPrice === 'number' ? (
                  <div
                    className={clsx(
                      'text-SeabiscuitGreenThemeColor text-2xl min-h-[30px] min-w-[70px] rounded-md'
                    )}
                  >
                    ${dataToPassOn.discountPrice}
                  </div>
                ) : null}
              </div>
              {dataToPassOn.url ? (
                <a
                  target="_blank"
                  href={dataToPassOn.url}
                  className="h-8 w-8 flex items-center justify-center rounded-md bg-SeabiscuitMainThemeColor bg-opacity-[0.1]"
                  rel="noreferrer"
                >
                  <span className="flex w-full h-full items-center justify-center">
                    <CallMadeIcon fontSize="small" className="text-SeabiscuitMainThemeColor" />
                  </span>
                </a>
              ) : null}
            </div>

            <div className="opacity-70 min-h-[50px] rounded-md text-base">
              {description.map((curr, index) => {
                return !!curr ? <div key={`line${index}`}>{curr}</div> : <br key={`line${index}`} />
              })}
            </div>
          </div>
        </div>
      </>
    </MainModal>
  )
}

export default DealModal
