import React from 'react'
import { MODAL_CONSTS } from '../../const/modal-const'
import MainModal from './common/MainModal'

type IConfirmRemoveDraftEventProps = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

export const ConfirmRemoveDraftEventModal: React.FC<IConfirmRemoveDraftEventProps> = (props) => {
  const title = props?.dataToPassOn?.title ? props?.dataToPassOn?.title : 'Confirmation'

  const closeModal = () => {
    props?.handleModal(false, MODAL_CONSTS.CONFIRM_REMOVE_DRAFT_EVENT)
  }

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <MainModal
      size="sm"
      title={title}
      show={props.show}
      onHide={closeModal}
      modalClassName="!h-[290px]"
      type="CONFIRM_REMOVE_DRAFT_EVENT"
      setHeightAsPerContent={true}
      buttons={[
        {
          loading: false,
          label: 'Ok',
          bgClass: 'bg-white',
          borderClass: '!border-SeabiscuitMainThemeColor border-solid border',
          onClick: () => {
            closeModal()
            props.dataToPassOn.cb()
          },
          textClass: 'text-SeabiscuitMainThemeColor',
          onHoverClass: 'hover:bg-SeabiscuitMainThemeColor hover:text-white',
        },
        {
          loading: false,
          label: 'Cancel',
          bgClass: 'bg-[#1f41731a] hover:bg-[#00000029]',
          borderClass: 'border-transparent',
          onClick: closeModal,
          textClass: '!text-SeabiscuitLightTextColor',
          onHoverClass: 'hover:bg-SeabiscuitMainThemeColor hover:text-white',
        },
      ]}
    >
      <div className={'py-5'}>
        <p className={'text-black'}>Are you sure you want to delete this event?</p>
      </div>
    </MainModal>
  )
}
