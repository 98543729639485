import TimeLib from '../../../../lib/Time'
import { INewsInterface } from '../../../../models/news/news.interface'

export const DATA_FIRESTORE_V01_NEWS = {
  NAME: 'v01_news',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    UPDATE: {
      KEY: 'u',
      VALUES: {
        DEFAULT: false,
      },
    },

    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },

    CREATOR_ID: {
      KEY: 'creatorId',
      VALUES: {
        DEFAULT: null as unknown as string,
      },
    },

    URL: {
      KEY: 'url',
      VALUES: {
        DEFAULT: null,
      },
    },

    PICTURES: {
      KEY: 'pictures',
      VALUES: {
        DEFAULT: [],
      },
    },

    TITLE: {
      KEY: 'title',
      VALUES: {
        DEFAULT: null,
      },
    },

    TITLE_N_GRAMS: {
      KEY: 'titleNGrams',
      VALUES: {
        DEFAULT: [],
      },
    },

    CREATOR_TYPE: {
      KEY: 'creatorType',
      VALUES: {
        DEFAULT: null,
        VENDOR: 'vendor' as INewsInterface['creatorType'],
        ORGANIZER: 'organizer' as INewsInterface['creatorType'],
        COMPETITOR: 'competitor' as INewsInterface['creatorType'],
      },
    },

    HEAD_LINE: {
      KEY: 'headLine',
      VALUES: {
        DEFAULT: null,
      },
    },

    STATUS: {
      KEY: 'status',
      VALUES: {
        LIVE: 'Live' as INewsInterface['status'],
        DRAFT: 'Draft' as INewsInterface['status'],
        DEFAULT: 'Draft' as INewsInterface['status'],
      },
    },

    DESCRIPTION: {
      KEY: 'description',
      VALUES: {
        DEFAULT: null,
      },
    },

    CREATOR_NAME: {
      KEY: 'creatorName',
      VALUES: {
        DEFAULT: null,
      },
    },

    CREATOR_NAME_N_GRAMS: {
      KEY: 'creatorNameNGrams',
      VALUES: {
        DEFAULT: [],
      },
    },

    CREATOR_PICTURE: {
      KEY: 'creatorPicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    MODIFIED: {
      KEY: 'modified',
      VALUES: {
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
