// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import React, { Fragment } from 'react'
import { Controller } from 'react-hook-form'
import RedCheck from '../../../../common/assets/invalid.svg'
import GreenCheck from '../../../../common/assets/valid.svg'
import {
  postEvaluateCheckboxFieldChange,
  postEvaluateChoiceFieldChange,
  preEvaluateCheckboxFieldChange,
  preEvaluateChoiceFieldChange,
} from '../../../evaluation/EventPrizeListFormBuilderUtilEvaluation'
import {
  validateCheckboxFieldChange,
  validateChoiceFieldChange,
} from '../../../validation/EventPrizeListFormBuilderUtilValidation'

interface EventPriceListFormBuilderUtilJumperSubSectionRowProps {
  subSection: any
  section: any
  register: any
  disciplineIndex: number
  divisionIndex: number
  sectionIndex: number
  subSectionIndex: number
  errors: any
  control: any
  setValue: any
  getValues: any
  setError: any
  clearErrors: any
  trigger: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormBuilderUtilJumperSubSectionRow: React.FC<
  EventPriceListFormBuilderUtilJumperSubSectionRowProps
> = (props) => {
  return (
    <div className="scroll-group w-full overflow-auto scrollbar-hide mb-3">
      <div className="grid grid-cols-[100px_230px_150px_repeat(6,1fr)] gap-2 min-w-[1200px] w-full -ml-8">
        <div></div>
        {/*=================================*/}
        {/* Sub Section Selection */}
        {/*=================================*/}
        <div>
          <Controller
            name={`data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionSubCode.meta.selected`}
            control={props.control}
            render={({ field: { value } }) => (
              <div
                className={clsx(
                  `text-[14px] w-full rounded-md flex items-center p-4 h-12`,
                  value
                    ? ` bg-SeabiscuitGrayThemeColor`
                    : `bg-white border-SeabiscuitGrayThemeColor border-2`
                )}
              >
                {value ? (
                  <>
                    <div
                      onClick={() => {
                        const shouldChange = preEvaluateCheckboxFieldChange(
                          props.subSection.subSectionSubCode,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        if (shouldChange) {
                          props.setValue(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionSubCode.meta.selected`,
                            !value
                          )
                        }
                        validateCheckboxFieldChange(
                          props.subSection.subSectionSubCode,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        postEvaluateCheckboxFieldChange(
                          props.subSection.subSectionSubCode,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                      }}
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                    >
                      {props.getValues(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionSubCode.value`
                      )}
                    </div>
                    <img src={GreenCheck} alt="green check" />
                  </>
                ) : (
                  <>
                    <div
                      onClick={() => {
                        const shouldChange = preEvaluateCheckboxFieldChange(
                          props.subSection.subSectionSubCode,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        if (shouldChange) {
                          props.setValue(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionSubCode.meta.selected`,
                            !value
                          )
                        }
                        validateCheckboxFieldChange(
                          props.subSection.subSectionSubCode,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                        postEvaluateCheckboxFieldChange(
                          props.subSection.subSectionSubCode,
                          props.subSection,
                          props.section,
                          value,
                          !value,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex,
                          props.subSectionIndex
                        )
                      }}
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                    >
                      {props.getValues(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionSubCode.value`
                      )}
                    </div>
                    <input type="checkbox" checked={value} disabled />
                  </>
                )}
              </div>
            )}
          />
          {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
            ?.sections[props.sectionIndex]?.subSections &&
            props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
              ?.sections[props.sectionIndex]?.subSections[props.subSectionIndex]?.subSectionSubCode
              ?.value && (
              <div className="text-red-500">
                {
                  props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                    ?.sections[props.sectionIndex]?.subSections[props.subSectionIndex]
                    ?.subSectionSubCode?.value.message
                }
              </div>
            )}
        </div>
        {/*=================================*/}
        {/* Tables Selection  */}
        {/*=================================*/}
        <div>
          <Controller
            name={`data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionTables.meta.enabled`}
            control={props.control}
            render={({ field: { value } }) => (
              <>
                {value ? (
                  <Listbox
                    value={props.getValues(
                      `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionTables.meta.selected`
                    )}
                    multiple={props.getValues(
                      `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSectionTables.meta.multiple`
                    )}
                    onChange={(values: any) => {
                      const previousValue = props.getValues(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionTables.meta.selected`
                      )

                      const shouldChange = preEvaluateChoiceFieldChange(
                        props.subSection.subSectionTables,
                        props.subSection,
                        props.section,
                        previousValue,
                        values,
                        props.setValue,
                        props.getValues,
                        props.setError,
                        props.clearErrors,
                        props.trigger,
                        props.disciplineIndex,
                        props.divisionIndex,
                        props.sectionIndex,
                        props.subSectionIndex
                      )

                      if (shouldChange) {
                        if (
                          props.getValues(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionTables.meta.multiple`
                          )
                        ) {
                          props.setValue(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionTables.value`,
                            values.join(',')
                          )
                        } else {
                          props.setValue(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionTables.value`,
                            values
                          )
                        }
                        props.setValue(
                          `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionTables.meta.selected`,
                          values
                        )
                      }

                      validateChoiceFieldChange(
                        props.subSection.subSectionTables,
                        props.subSection,
                        props.section,
                        previousValue,
                        values,
                        props.setValue,
                        props.getValues,
                        props.setError,
                        props.clearErrors,
                        props.trigger,
                        props.disciplineIndex,
                        props.divisionIndex,
                        props.sectionIndex,
                        props.subSectionIndex
                      )

                      postEvaluateChoiceFieldChange(
                        props.subSection.subSectionTables,
                        props.subSection,
                        props.section,
                        previousValue,
                        values,
                        props.setValue,
                        props.getValues,
                        props.setError,
                        props.clearErrors,
                        props.trigger,
                        props.disciplineIndex,
                        props.divisionIndex,
                        props.sectionIndex,
                        props.subSectionIndex
                      )
                    }}
                  >
                    <Listbox.Button
                      className={clsx(
                        `text-[14px] w-full rounded-md flex items-center p-4 h-12`,
                        value
                          ? ` bg-SeabiscuitGrayThemeColor`
                          : `bg-white border-SeabiscuitGrayThemeColor border-2`
                      )}
                    >
                      <div className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer flex items-between">
                        {props.getValues(
                          `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionTables.value`
                        )}
                      </div>
                      <ChevronDownIcon
                        className={`text-[14px] w-full rounded-md flex items-center p-4 h-16`}
                      />
                      {(function () {
                        return (
                          props.errors?.data?.items[props.disciplineIndex]?.divisions[
                            props.divisionIndex
                          ]?.sections[props.sectionIndex]?.subSections &&
                          props.errors?.data?.items[props.disciplineIndex]?.divisions[
                            props.divisionIndex
                          ]?.sections[props.sectionIndex]?.subSections[props.subSectionIndex]
                            ?.subSectionTables?.value
                        )
                      })() ? (
                        <img src={RedCheck} alt="red check" />
                      ) : (
                        <img src={GreenCheck} alt="green check" />
                      )}
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {props
                          .getValues(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionTables.meta.options`
                          )
                          .map((item: any) => (
                            <Listbox.Option
                              key={item}
                              value={item}
                              className="text-[14px] w-full rounded-md flex items-center p-4 h-12 bg-SeabiscuitGrayThemeColor"
                            >
                              {item}
                              <>
                                {props
                                  .getValues(
                                    `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionTables.meta.selected`
                                  )
                                  .includes(item) && <img src={GreenCheck} alt="green check" />}
                              </>
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </Listbox>
                ) : (
                  <div
                    className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Tables'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.subSections.${props.subSectionIndex}.subSectionTables.value`
                      )}
                    />
                  </div>
                )}
              </>
            )}
          />
          {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
            ?.sections[props.sectionIndex]?.subSections &&
            props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
              ?.sections[props.sectionIndex]?.subSections[props.subSectionIndex]?.subSectionTables
              ?.value && (
              <div className="text-red-500">
                {
                  props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                    ?.sections[props.sectionIndex]?.subSections[props.subSectionIndex]
                    ?.subSectionTables?.value.message
                }
              </div>
            )}
        </div>

        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

EventPrizeListFormBuilderUtilJumperSubSectionRow.defaultProps = {
  subSection: null,
  register: null,
}

export default EventPrizeListFormBuilderUtilJumperSubSectionRow
