import { useEffect, useRef, useState } from 'react'
import { Clear } from '@mui/icons-material'

// Types
type Props = {
  show: boolean
  handleModal?: any
  dataToPassOn?: {
    noteInputRef?: HTMLInputElement
    trigger?: (nameOfInput: string) => void
    setValue?: (name: string, value: string) => void
  }
  constValue: string
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const CommonTabNoteModal = (props: Props) => {
  // Hooks and vars
  const inputRef = props.dataToPassOn?.noteInputRef
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [saveBtn, setSavebtn] = useState<boolean>(false)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (textareaRef && textareaRef?.current && inputRef) {
      if (typeof inputRef === 'string') {
        textareaRef.current.value = inputRef
      } else {
        setSavebtn(true)
        textareaRef.current.value = inputRef?.value
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (saveBtn) textareaRef.current?.focus?.()
  }, [saveBtn])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Closes the modal while setting the value of the inputref passed
   */
  const closeSaveModal = () => {
    props.handleModal(false, props.constValue)
    if (textareaRef && textareaRef?.current && inputRef && props?.dataToPassOn) {
      if (typeof props?.dataToPassOn?.setValue === 'function') {
        if (inputRef?.name) {
          props.dataToPassOn.setValue(inputRef?.name, textareaRef.current.value)
          inputRef.value = textareaRef.current.value
          if (typeof props?.dataToPassOn?.trigger === 'function') {
            props.dataToPassOn.trigger(inputRef?.name)
          }
        }
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const closeModal = () => {
    props.handleModal(false, props.constValue)
  }

  return (
    <div
      className={`fixed left-0 right-0 top-0 bottom-0 z-10  transition-all ${props.show ? 'visible' : 'invisible'}`}
    >
      <div
        className={`fixed bg-white p-4 rounded-xl z-10 left-[calc(50%_-_1rem)] flex flex-col top-[calc(50%_-_1rem)] translate-x-[-50%] translate-y-[-60%] ${props.show ? '!translate-y-[-50%]' : ''} w-[calc(100%_-_2rem)] max-w-md m-2 opacity-${props.show ? '1' : '0'} transition-all`}
      >
        <div className="overflow-auto max-h-screen">
          <div className="text-right">
            {/* <img src="/assets/og_icons/Close.svg" onClick={closeModal}  className="w-8 absolute text right-2 top-3 text-SeabiscuitDark200ThemeColor cursor-pointer hover:bg-[#f7f7f7] p-1 rounded-full" alt="close icon"/> */}
            <Clear
              fontSize="small"
              onClick={closeModal}
              className="text-SeabiscuitDark200ThemeColor cursor-pointer"
            />
          </div>
          {/* {
                        saveBtn ? <h2 className="m-0 text-[25px] mt-6 font-normal text-SeabiscuitDark200ThemeColor">Add Notes</h2> : null
                    } */}

          <div className="text-center m-1 mt-4">
            <div className="text-black absolute top-0 left-0 mt-6 ml-8 font-semibold">Details</div>
            <textarea
              placeholder="Add notes..."
              disabled={!saveBtn}
              ref={textareaRef}
              className="min-h-[400px] text-[#122B46] rounded-xl w-full border-1 !border-white h-12 mt-2 focus:outline-none focus:ring-0"
            />

            {saveBtn ? (
              <button
                onClick={closeSaveModal}
                className="w-52 mb-2 m-auto flex items-center justify-center py-3 px-4 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor focus-visible:outline-none disabled:text-SeabiscuitLightTextColor disabled:bg-SeabiscuitLightThemeColor mt-4"
              >
                SAVE
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div
        className={`fixed left-0 right-0 top-0 bottom-0 bg-black/30 opacity-${props.show ? '1' : '0'} transition-all`}
      ></div>
    </div>
  )
}

export default CommonTabNoteModal
