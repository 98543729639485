// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { Clear } from '@mui/icons-material'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export interface PropTypes {
  show: boolean
  children: any
  onHide: () => void
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPoliciesFormModal: React.FC<PropTypes> = (props: PropTypes) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <div
        className={`fixed left-0 right-0 top-0 bottom-0 z-10 transition-all ${props.show ? 'visible' : 'invisible'}`}
      >
        <div
          className={`fixed bg-white p-4 rounded-xl z-10 left-[calc(50%_-_1rem)] flex flex-col top-[calc(50%_-_1rem)] translate-x-[-50%] translate-y-[-60%] ${props.show ? '!translate-y-[-50%]' : ''} w-[calc(100%_-_2rem)] max-w-md m-2 opacity-${props.show ? '1' : '0'} transition-all`}
        >
          <div className="overflow-auto max-h-screen">
            <div className="text-right">
              <button onClick={props.onHide}>
                {/* <img src="/assets/og_icons/Close.svg" className="w-6" alt="close icon" /> */}
                <Clear
                  fontSize={'small'}
                  style={{
                    color: 'grey',
                    fontWeight: '400',
                  }}
                />
              </button>
            </div>
            {props.children}
          </div>
        </div>
        <div
          className={`fixed left-0 right-0 top-0 bottom-0 bg-black/30 opacity-${props.show ? '1' : '0'} transition-all`}
        ></div>
      </div>
    </>
  )
}

export default EventPoliciesFormModal
