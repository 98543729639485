import { useState } from 'react'

// Component imports
import ConfirmationCompsWrapper from './ConfirmationCompsWrapper'

// Redux
import { IRecipient } from '../../../../../../models/users/user.interface'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectPayTabSummaryAccordion } from '../../../../../../store/registration/registrationSlice'

// Types
type IProps = {
  recipientTotals: IRecipient[]
}

let heading = [
  { title: 'Recipient', tooltipText: '' },
  { title: 'Payment Total', tooltipText: '' },
  { title: 'Pay And Notify', tooltipText: '' },
]

const SelectedEventPaymentSummary = (props: IProps) => {
  // Hooks and vars
  const summaryAccordionData = useAppSelector(selectPayTabSummaryAccordion)

  const [open, setOpen] = useState(false)

  return (
    <ConfirmationCompsWrapper
      title="Summary"
      accordion={{ open, setOpen }}
      showList={summaryAccordionData.data.length ? true : false}
      cols={heading}
      colsClassName={{
        0: '!w-2/4  flex items-center',
      }}
    >
      <>
        {summaryAccordionData.data.map((currRecipient, index) => {
          return (
            <div
              key={`${JSON.stringify(currRecipient)}${index}`}
              className="flex items-center justify-start w-full mb-2"
            >
              <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-2/4 p-4 mr-2 rounded-md bg-SeabiscuitGrayThemeColor capitalize">
                <span>{currRecipient?.recipientName ?? 'Unknown'}</span>
              </p>

              <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                <span className="mr-4">${currRecipient.amountPaid}</span>
              </p>

              <p className="text-SeabiscuitDark200ThemeColor xl:text-sm xxl:text-base w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center relative flex items-center justify-center">
                <span className="mr-4">Send Invoice</span>
                <img src="/assets/og_icons/Ok-3.svg" alt="horseIcon" className="absolute right-4" />
              </p>
            </div>
          )
        })}
      </>
    </ConfirmationCompsWrapper>
  )
}

export { SelectedEventPaymentSummary }
