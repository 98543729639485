// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import * as React from 'react'

import { useEffect, useRef, useState } from 'react'
import { CONFIG } from '../../../../../../config/config'
import { EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST } from './EventPrizeListFormBuilderUtil.const'
import EventPrizeListFormBuilderUtilRowDisplayModeControlComponent from './controls/EventPrizeListFormBuilderUtilRowDisplayModeControlComponent'
import EventPrizeListFormBuilderUtilMainRow from './rows/main/EventPrizeListFormBuilderUtilMainRow'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventPriceListFormBuilderProps {
  data?: any
  register?: any
  errors?: any
  control: any
  setValue: any
  getValues: any
  setError: any
  clearErrors: any
  trigger: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormBuilderUtil: React.FC<EventPriceListFormBuilderProps> = (props) => {
  const mainRowRefs: React.MutableRefObject<any[]> = useRef<any[]>([])
  const divisionRowRefs: React.MutableRefObject<any[]> = useRef<any[]>([])
  const sectionsRowRefs: React.MutableRefObject<any[]> = useRef<any[]>([])
  const classesRowRefs: React.MutableRefObject<any[]> = useRef<any[]>([])

  const [rowDisplayMode, setRowDisplayMode] = useState(
    EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.DISCIPLINE
  )

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onRowDisplayModeChangeHandle = (display_mode: any) => {
    // switch (display_mode) {
    //     case  EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.DISCIPLINE:
    //         mainRowRefs.current.forEach(
    //             value => {
    //                 value.collapseRow();
    //             }
    //         );
    //         break;
    //     case  EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.DIVISIONS:
    //         divisionRowRefs.current.forEach(
    //             value => {
    //                 value.collapseRow();
    //             }
    //         );
    //         break;
    //     case  EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.SECTIONS:
    //         sectionsRowRefs.current.forEach(
    //             value => {
    //                 value.collapseRow();
    //             }
    //         );
    //         break;
    //     case  EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.CLASSES:
    //         classesRowRefs.current.forEach(
    //             value => {
    //                 value.collapseRow();
    //             }
    //         );
    //         break;
    //     default:
    //         alert("There has been an error in the generation of the item.")
    // }

    setRowDisplayMode(display_mode)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (props.data) {
      mainRowRefs.current = mainRowRefs.current.slice(0, props.data.items?.length)
    }
  }, [props.data])

  return (
    <>
      <EventPrizeListFormBuilderUtilRowDisplayModeControlComponent
        rowDisplayMode={rowDisplayMode}
        onRowDisplayModeChanged={onRowDisplayModeChangeHandle}
      />
      <>
        {props.data.items?.map((item: any, index: any) => (
          <EventPrizeListFormBuilderUtilMainRow
            key={item.mainTitle}
            ref={(el) => (mainRowRefs.current[index] = el)}
            register={props.register}
            item={item}
            disciplineIndex={index}
            divisionRowRefs={divisionRowRefs}
            sectionsRowRefs={sectionsRowRefs}
            classesRowRefs={classesRowRefs}
            errors={props.errors}
            control={props.control}
            setValue={props.setValue}
            getValues={props.getValues}
            setError={props.setError}
            clearErrors={props.clearErrors}
            trigger={props.trigger}
          />
        ))}
      </>
    </>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
EventPrizeListFormBuilderUtil.defaultProps = CONFIG.EVENT.PRIZE_LIST.FORM_BUILDER.TEMPLATE.SAMPLE

export default EventPrizeListFormBuilderUtil
