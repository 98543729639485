/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from 'react'
import { SignupStages } from './UserSignupViewComponentContextApi'
import UserSignupViewComponentButton from './global/UserSignupViewComponentButton'
import UserSignupViewComponentInput from './global/UserSignupViewComponentInput'

import { useAppDispatch } from '../../../../../store/hooks'
import { getUserUsefDetails } from '../../../../../store/thirdPartyUser/thirdPartyUserSlice'
import backIcon from '../assets/arrow-left.svg'
import eventOrgIcon from '../assets/event-org.png'
import USEFIcon from '../assets/usef-icon.svg'

const UserSignupViewComponentEventOrganizer: React.FC = () => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const [stage, setStage] = useContext(SignupStages)
  const [error, setError] = useState<string>('')
  const [loading] = useState<boolean>(false)

  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  const [USEF, setUSEF] = useState(0)

  const handleSearch = () => {
    if (error !== '') {
      return
    }

    dispatch(
      getUserUsefDetails({
        id: USEF,
        EntityType: 'A',
      })
    )

    // setLoading(true)
    // stageHandle("EventOrgVerify")
  }

  const handleChange = (e: any) => {
    setUSEF(e.target.value)
    if (e.target.value.length < 7) {
      return setError('Please provide a valid 7 digit USEF id')
    } else if (e.target.value && e.target.value.length === 7) {
      setError('')
    }
  }

  return (
    <div>
      <div className="text-[24px] flex items-center gap-2 text-white">
        <button onClick={() => stageHandle('Init')}>
          <img src={backIcon} alt="back" className="invert" />
        </button>
        EVENT ORGANIZER
      </div>
      <div className="mt-10">
        <img src={eventOrgIcon} alt="icon" className="rounded-full shadow-xl mx-auto" />
      </div>
      <div className="mt-[60px]">
        <p className="text-[18px] text-white mt-4">Enter your USEF number</p>
        <UserSignupViewComponentInput
          type="number"
          value={USEF}
          onChange={handleChange}
          placeholder="USEF number"
          icon={USEFIcon}
        />
        {error !== '' ? (
          <div className="text-[#ff0000] text-[11px] font-semibold">{error}</div>
        ) : null}
      </div>
      <div className="w-[70%] mt-[60px] mx-auto">
        <UserSignupViewComponentButton
          caption="SEARCH"
          kind={1}
          loading={loading}
          disabled={!stage.selector}
          onClick={handleSearch}
        />
      </div>
      <div className="text-center text-white opacity-50 mt-10">OR</div>
      <div className="text-white my-10 text-center">
        Don’t have a USEF number?{' '}
        <a
          className="text-SeabiscuitMainThemeColor cursor-pointer"
          onClick={() => stageHandle('SignUp')}
        >
          Continue &gt;
        </a>
      </div>
    </div>
  )
}

export default UserSignupViewComponentEventOrganizer
