import UserDetailCard from '../../../../components/cards/userDetailCard'
import UserDetailCardWithStatus from '../../../../components/cards/userDetailCardWithStatus'
import HorseDetailCard from '../../../../components/cards/horseDetailCard'
import HorseDetailCardWithStatus, {
  HorseWithStatusType,
} from '../../../../components/cards/horseDetailCardWithStatus'
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'
//fake data
import { useAppSelector } from '../../../../store/hooks'
import { selectTeamMembers } from '../../../../store/user/userSlice'
import { ITeamMember } from '../../../../models/organiser-profile/orgnaiser-profile.interface'

type Props = {
  title?: string
  description?: string
  step?: number
  horseData?: any
}

const CompetitorEventsRegisterStatusTab = (props: Props) => {
  const teamMates = useAppSelector(selectTeamMembers) as ITeamMember[]
  const horseData = props?.horseData ?? []

  return (
    <>
      <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
        {/* people section */}
        <h3 className="text-SeabiscuitDark200ThemeColor my-4 font-semibold">People</h3>

        {
          // step 0 team members
          teamMates.length && props.step === 0
            ? teamMates.map((item, index) => {
                return (
                  <UserDetailCard
                    key={JSON.stringify(item) + `${index}`}
                    teamMateName={item.memberName}
                    teamMateImage={item.memberProfilePicture}
                    teamMateRole={item.defaultRole}
                  />
                )
              })
            : // step 1 status
              teamMates.length &&
              props.step === 1 &&
              teamMates.map((item: any, index) => {
                return (
                  <UserDetailCardWithStatus key={JSON.stringify(item) + `${index}`} item={item} />
                )
              })
        }
        <hr className="mt-4 h-2 w-full"></hr>

        {/* horse section */}

        <h3 className="text-SeabiscuitDark200ThemeColor my-4 font-semibold">Horse</h3>

        {horseData.length && props.step === 0
          ? horseData.map((item: any, index: number) => {
              return (
                <HorseDetailCard
                  key={JSON.stringify(item) + `${index}`}
                  horseName={item.horseName}
                  horseDiscipline={item.horseDiscipline}
                  horseZone={item.horseZone}
                />
              )
            })
          : horseData.length &&
            props.step === 1 &&
            horseData.map((item: HorseWithStatusType, index: number) => {
              return (
                <HorseDetailCardWithStatus key={JSON.stringify(item) + `${index}`} item={item} />
              )
            })}
      </CompetitorEventRegisterWrapper>
    </>
  )
}

export default CompetitorEventsRegisterStatusTab
