import { TEventRegisteredUsers } from './event-registered-users.interface'

const PAYMENT_STATUS = {
  PAID: 'paid' as TEventRegisteredUsers['paymentStatus'],
  CASH: 'cash' as TEventRegisteredUsers['paymentStatus'],
  PENDING: 'pending' as TEventRegisteredUsers['paymentStatus'],
  REFUNDED: 'refunded' as TEventRegisteredUsers['paymentStatus'],
}

export const EVENT_REGISTERED_CONST = {
  PAYMENT_STATUS,
}
