import { ModelBaseModel } from '../model-base/model-base.model'

import { CONST } from '../../const/const'
import { IHorseTeamInterface } from './horseTeam.interface'

// Constants
const HORSE_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES
const HORSE_TEAM_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSE_TEAM

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @TODO Document this
 */
export class HorseTeamModel extends ModelBaseModel<IHorseTeamInterface> {
  // Properties
  public v: IHorseTeamInterface['v']
  public id: IHorseTeamInterface['id']
  public userId: IHorseTeamInterface['userId']
  public horseId: IHorseTeamInterface['horseId']
  public memberId: IHorseTeamInterface['memberId']
  public isMyHorse: IHorseTeamInterface['isMyHorse']
  public horseZone: IHorseTeamInterface['horseZone']
  public horseName: IHorseTeamInterface['horseName']
  public horseOwnerId: IHorseTeamInterface['horseOwnerId']
  public horseNameNGram: IHorseTeamInterface['horseNameNGram']
  public memberName: IHorseTeamInterface['memberName']
  public memberRole: IHorseTeamInterface['memberRole']
  public horseDiscipline: IHorseTeamInterface['horseDiscipline']
  public userHorseMappingId: IHorseTeamInterface['userHorseMappingId']
  public horseProfilePicture: IHorseTeamInterface['horseProfilePicture']
  public memberProfilePicture: IHorseTeamInterface['memberProfilePicture']
  public horseSelectedForCompeletion: IHorseTeamInterface['horseSelectedForCompeletion']
  public memberAuthorized?: IHorseTeamInterface['memberAuthorized']
  public authorized?: IHorseTeamInterface['authorized']

  public created: IHorseTeamInterface['created']
  public modified: IHorseTeamInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param obj `IHorseTeamInterface`
   */
  public constructor(obj?: IHorseTeamInterface) {
    super()
    this.v = obj?.v ?? HORSE_TEAM_COLLECTION.FIELDS.V.VALUES.DEFAULT
    this.id = obj?.id ?? HORSE_TEAM_COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.userId = obj?.userId ?? HORSE_TEAM_COLLECTION.FIELDS.USER_ID.VALUES.DEFAULT
    this.horseId = obj?.horseId ?? HORSE_TEAM_COLLECTION.FIELDS.HORSE_ID.VALUES.DEFAULT
    this.isMyHorse = obj?.isMyHorse ?? HORSE_TEAM_COLLECTION.FIELDS.IS_MY_HORSE.VALUES.DEFAULT
    this.memberId = obj?.memberId ?? HORSE_TEAM_COLLECTION.FIELDS.TEAM_MEMBER_ID.VALUES.DEFAULT
    this.horseName = obj?.horseName ?? HORSE_COLLECTION.FIELDS.HORSE_NAME.VALUES.DEFAULT
    this.horseNameNGram =
      obj?.horseNameNGram ?? HORSE_COLLECTION.FIELDS.HORSE_NAME_N_GRAM.VALUES.DEFAULT
    this.horseOwnerId =
      obj?.horseOwnerId ?? HORSE_TEAM_COLLECTION.FIELDS.HORSE_OWNER_ID.VALUES.DEFAULT
    this.horseDiscipline =
      obj?.horseDiscipline ?? HORSE_COLLECTION.FIELDS.HORSE_DISCIPLINE.VALUES.DEFAULT
    this.horseZone = obj?.horseZone ?? HORSE_COLLECTION.FIELDS.HORSE_ZONE.VALUES.DEFAULT
    this.memberName =
      obj?.memberName ?? HORSE_TEAM_COLLECTION.FIELDS.TEAM_MEMBER_NAME.VALUES.DEFAULT
    this.memberRole =
      obj?.memberRole ?? HORSE_TEAM_COLLECTION.FIELDS.TEAM_MEMBER_ROLE.VALUES.DEFAULT
    this.horseProfilePicture =
      obj?.horseProfilePicture ?? HORSE_COLLECTION.FIELDS.HORSE_PROFILE_PICTURE.VALUES.DEFAULT
    this.userHorseMappingId =
      obj?.userHorseMappingId ?? HORSE_TEAM_COLLECTION.FIELDS.USER_HORSE_MAPPING_ID.VALUES.DEFAULT
    this.memberProfilePicture =
      obj?.memberProfilePicture ??
      HORSE_TEAM_COLLECTION.FIELDS.TEAM_MEMBER_PROFILE_PICTURE.VALUES.DEFAULT
    this.horseSelectedForCompeletion =
      obj?.horseSelectedForCompeletion ??
      HORSE_TEAM_COLLECTION.FIELDS.HORSE_SELECTED_FOR_COMPETETION.VALUES.DEFAULT
    this.memberAuthorized =
      obj?.memberAuthorized ??
      (HORSE_TEAM_COLLECTION.FIELDS.MEMBER_AUTHORIZED.VALUES.DEFAULT as any)
    this.authorized = obj?.authorized ?? HORSE_TEAM_COLLECTION.FIELDS.AUTHORIZED.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // Methods

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new HorseTeamModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
