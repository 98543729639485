import { filter } from 'lodash'
import { CONST } from '../../../../../../../const/const'

function generateFieldValidation(field: any) {
  return {
    notEmpty: (value: any) => {
      if (field?.type !== CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT) return true

      if (field?.validation === null || field?.validation.length === 0) return true

      const validation_result = filter(field?.validation, {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
      })

      if (validation_result.length) {
        if (value.length > 0) {
          return true
        } else {
          return validation_result[0].errorMessage
        }
      }
      return true
    },
  }
}

export default generateFieldValidation
