import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { where } from 'firebase/firestore'
import { IUserHorseMappingInterface } from '../../../models/user-horse-mapping/userHorseMapping.interface'
import { UserHorseMappingModel } from '../../../models/user-horse-mapping/userHorseMapping.model'
import { IHorseData } from '../../../models/horse/horse.interface'
import { HorseModel } from '../../../models/horse/horse.model'
import { getConvertedData } from '../../../models/interface.helper'
import { MESSAGES_CONST } from '../../../const/messages-const'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type IGetMyHorsesThunk = {
  req: {}
}

const getMyHorsesThunk = createAsyncThunk(
  'getMyHorsesThunk',
  async (payload: IGetMyHorsesThunk['req'], thunkApi) => {
    let horseIds: string[] = []
    let horses: IHorseData[] = []
    let message: string | null = null
    let horseMapping: IUserHorseMappingInterface | null = null

    const horseMappings: IUserHorseMappingInterface[] = []
    const userId = (thunkApi.getState() as RootState).user.userId

    try {
      const horseMappingSnapshots = await FirestoreService.filterItems(
        COLLECTIONS.USER_HORSE_MAPPING.NAME,
        [where(COLLECTIONS.USER_HORSE_MAPPING.FIELDS.USER_ID.KEY, '==', userId)]
      )

      horseMappingSnapshots.forEach((currHorseMappingSnapshot) => {
        horseMapping = UserHorseMappingModel.fromFirestoreDoc(currHorseMappingSnapshot).toObject()
        if (!!horseMapping.horseId) {
          horseIds.push(horseMapping.horseId)
        }
        horseMappings.push(getConvertedData(horseMapping))
      })

      horseIds = [...new Set(horseIds)]

      const horseSnapshots = await FirestoreService.getItemsUsingIds(
        COLLECTIONS.HORSES.NAME,
        horseIds
      )

      horseSnapshots.forEach((currHorseSnapshot) => {
        horses.push(getConvertedData(HorseModel.fromFirestoreDoc(currHorseSnapshot).toObject()))
      })

      return {
        horses,
        horseMappings,
        message,
      }
    } catch (error: any) {
      message = error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG
      return thunkApi.rejectWithValue({
        horses,
        horseMappings,
        message,
      })
    }
  }
)

export { getMyHorsesThunk }
