// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { thirdPartyHttpService } from '../../services/httpService'

// Get comments
export const getUserUsefDetails = createAsyncThunk('getUserUsefDetails', async (dataMain: any) => {
  try {
    await thirdPartyHttpService({
      url: 'https://api.usef.org/api/cscp/production/indivorg/getbyid',
      method: 'POST',
      data: dataMain,
    })
    // if (response.status === 200) {
    //     return { response: response.data, dataMain }
    // }
  } catch (err) {
    console.log(err)
    // if (!err.response) {
    //     throw err
    // }
    // return rejectWithValue(err.response.data)
  }
})

export interface IInitialSystemState {
  data: any
  loading: boolean
}

const initialSystemState: IInitialSystemState = {
  data: {},
  loading: false,
}

const thirdPartyUserSlice = createSlice({
  name: 'THIRD_PARTY_USER',
  initialState: initialSystemState,
  reducers: {
    setLoading(state) {
      state.loading = true
    },
  },
})

export const { setLoading } = thirdPartyUserSlice.actions
export default thirdPartyUserSlice.reducer
