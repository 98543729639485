import { useEffect, useRef, useState } from 'react'

// Constants
import { CONST } from '../../../const/const'

// Components
import ViewsLoader from '../../../components/loader/ViewsLoader'

// Services
import FirebaseApp from '../../../services/firebaseApp'

// Firebase
import {
  DocumentData,
  QueryConstraint,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { selectUserId } from '../../../store/user/userSlice'

// Third party
import { cloneDeep } from 'lodash'

import { IEventInterface } from '../../../models/events/event.interface'
import { EventModel } from '../../../models/events/event.model'
import { getConvertedData } from '../../../models/interface.helper'

import { IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'
import FirestoreService from '../../../services/firestoreService'
import { setEvents, setPaidBills, setUnPaidBills, setUsers } from '../../../store/bills/billsSlice'

import { holdersSliceInitialState } from '../../../store/holders/holdersSlice.data'
import helpers from '../../../commonHelpers/helpers'
import CompetitorAllBills from './tabs/CompetitorAllBills'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationByDayModel } from '../../../models/registrations-by-day/registrationByDay.model'
import { IRegistrationTicketInterface } from '../../../models/registration-tickets/registrationTicket.interface'
import { RegistrationTicketModel } from '../../../models/registration-tickets/registrationTicket.model'
import { IRegistrationFeesInterface } from '../../../models/registration-fees/registrationFees.interface'
import { RegistrationFeesModel } from '../../../models/registration-fees/registrationFees.model'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { RecipientModel } from '../../../models/recipients/recipients'
import { IRecipientInterface } from '../../../models/recipients/recipients.interface'
import { useIonRouter } from '@ionic/react'
import { useParams } from 'react-router'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { CustomError } from '../../../helpers/helpers'
import { MODAL_CONSTS } from '../../../const/modal-const'

// Models

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type Props = {
  selectedTab?: any
  handleModal?: any
  sortstyle: string
  setSortStyle: React.Dispatch<React.SetStateAction<string>>
}

type IDataTableItem = {
  eventName: IEventInterface['eventName']
  paymentStatus: string
  amount: number
  scratchedAmount: number
  refundAmount: number
  from: string
  registrationId: string | null
  eventId: string | null
  competitorId: string
  userId: string | null
  isRegistered: boolean
}

// Constants
const FILE_NAME = 'CompetitorBillDisplayTabs'
const PAYMENT_STATUSES =
  CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const CompetitorBillDisplayTabs = (props: Props) => {
  // Hooks and vars
  const params = useParams()
  const router = useIonRouter()
  const dispatch = useAppDispatch()
  const toastFunction = useToasterHelper()

  const holder = useAppSelector((state) => state.holder)
  const userId = useAppSelector(selectUserId)
  const holderRef = useRef(holdersSliceInitialState)

  const { sortstyle, setSortStyle } = props
  const { paidBills, unPaidBills } = useAppSelector((state) => state.bills.data)

  const [loading, setLoading] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const [registrationId, setRegistrationId] = useState<null | string>(null)
  const [dataTableItems, setDataTableItems] = useState<IDataTableItem[]>([])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    holderRef.current = holder
  }, [holder])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (modalOpened) return

    if (registrationId) {
      if (userId) loadRecieptOnRegistrationBasis(registrationId, userId)
      return
    }

    try {
      const searchParams = new URLSearchParams(router.routeInfo?.search ?? '')

      if (searchParams.has('registrationId')) {
        const registrationId = searchParams.get('registrationId')
        setRegistrationId(registrationId)
      }
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'useEffect',
          devMessage: error?.message ?? error,
        }),
      })
      console.log(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.routeInfo, params, registrationId, userId, modalOpened])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  // Common Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handlecommonbills = (unpaidbills: IRecipientInterface[], ispaidbills: boolean) => {
    const finalbills = cloneDeep(unpaidbills)
    if (ispaidbills) {
      dispatch(setPaidBills(finalbills))
    } else {
      dispatch(setUnPaidBills(finalbills))
    }
  }

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const loadRecieptOnRegistrationBasis = async (registrationId: string, userId: string) => {
    try {
      let recipient: IRecipientInterface | null = null

      const recipientSnapshots = await FirestoreService.filterItems(COLLECTIONS.RECIPIENT.NAME, [
        where(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.REGISTRATION_DOC_ID.KEY,
          '==',
          registrationId
        ),
        where(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.RECIPIENT_ID.KEY,
          '==',
          userId
        ),
      ])

      if (!recipientSnapshots.size)
        throw Error(
          `document not found with registrationId: ${registrationId} and recipientId: ${userId}`
        )

      recipient = RecipientModel.fromFirestoreDoc(recipientSnapshots.docs[0]).toObject()

      props?.handleModal?.(true, MODAL_CONSTS.BILL_BRIEFE, { bill: recipient })
      setModalOpened(true)
    } catch (error: any) {
      toastFunction.error({
        message: MESSAGES_CONST.UNABLE_TO_OPEN_RECIEPT,
      })

      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'loadRecieptOnRegistrationBasis',
          devMessage: error?.message ?? error,
        }),
      })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleModifybills = (
    paidbills: IRecipientInterface[],
    unpaidbills: IRecipientInterface[]
  ) => {
    if (paidbills.length) {
      handlecommonbills(paidbills, true)
    }

    if (unpaidbills.length) {
      handlecommonbills(unpaidbills, false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getData = async () => {
    let lastIndex = 0
    let currentIndex = 0
    let ownerIdList: string[] = []
    let eventIdsList: string[] = []
    let users: IUserInterface[] = []
    let events: IEventInterface[] = []
    let user: IUserInterface | null = null
    let event: IEventInterface | null = null
    let paidBillsDocs: IRecipientInterface[] = []
    let unpaidBillsDocs: IRecipientInterface[] = []
    const paymentStatusQuery: QueryConstraint[] = []
    let userSnaps: QueryDocumentSnapshot<DocumentData>[] = []
    let eventSnaps: QueryDocumentSnapshot<DocumentData>[] = []

    const registeredUsersRef = collection(FirebaseApp.firestore, COLLECTIONS.RECIPIENT.NAME)
    const orderByField = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.UPDATED.KEY
    const orderByDirection = sortstyle === 'Oldest' ? 'desc' : 'asc'

    if ([PAYMENT_STATUSES.PAID, PAYMENT_STATUSES.PENDING].includes(props.selectedTab)) {
      paymentStatusQuery.push(
        where(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.KEY,
          '==',
          props.selectedTab
        )
      )
    }

    let q = query(
      registeredUsersRef,
      where(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.RECIPIENT.FIELDS.RECIPIENT_ID.KEY,
        '==',
        userId
      ),
      ...paymentStatusQuery,
      orderBy(orderByField, orderByDirection)
    )

    const querySnapshot = await getDocs(q)

    lastIndex = querySnapshot.size

    // Paid bills data
    while (currentIndex < lastIndex) {
      let currentRegisteredEvent = RecipientModel.fromFirestoreDoc(
        querySnapshot.docs[currentIndex]
      ).toObject()

      if (currentRegisteredEvent.paymentStatus === 'paid')
        paidBillsDocs = [...paidBillsDocs, currentRegisteredEvent]
      else unpaidBillsDocs = [...unpaidBillsDocs, currentRegisteredEvent]

      currentIndex++
    }

    ;[...paidBillsDocs, ...unpaidBillsDocs].forEach((currRegistration) => {
      if (currRegistration.eventId) eventIdsList.push(currRegistration.eventId)
    })

    eventIdsList = [...new Set(eventIdsList)]

    eventSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.EVENTS.NAME, eventIdsList)

    eventSnaps.forEach((currDoc) => {
      event = getConvertedData(EventModel.fromFirestoreDoc(currDoc).toObject())
      if (event.owner) ownerIdList.push(event.owner)
      events.push(event)
    })

    userSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, ownerIdList)

    userSnaps.forEach((currDoc) => {
      users.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
    })

    paidBillsDocs = paidBillsDocs.map((currBill) => {
      event = events.find((currEvent) => currEvent.id === currBill.eventId) ?? null
      user = users.find((user) => user.id === event?.owner) ?? null
      return getConvertedData({
        ...currBill,
        eventName: event?.eventName,
        eventLogo: user?.userProfilePicture,
      }) as IRecipientInterface
    })

    unpaidBillsDocs = unpaidBillsDocs.map((currBill) => {
      event = events.find((currEvent) => currEvent.id === currBill.eventId) ?? null
      user = users.find((user) => user.id === event?.owner) ?? null
      return getConvertedData({
        ...currBill,
        eventName: event?.eventName,
        eventLogo: user?.userProfilePicture,
      }) as IRecipientInterface
    })

    setLoading(false)
    dispatch(setUsers(users))
    dispatch(setEvents(events))
    dispatch(setPaidBills(paidBillsDocs))
    dispatch(setUnPaidBills(unpaidBillsDocs))
    handleModifybills(paidBillsDocs, unpaidBillsDocs)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async function load() {
    let dataTableItems_: IDataTableItem[] = []
    let registrationFees: IRegistrationFeesInterface[] = []
    let registrationsByDay: IRegistrationByDayInterface[] = []
    let registrationTickets: IRegistrationTicketInterface[] = []

    if (!userId)
      return helpers.logger({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

    const registrationByDaySnaps = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      [where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.USER_ID.KEY, '==', userId)]
    )

    const registrationTicketSnaps = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_TICKET.NAME,
      [where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.USER_ID.KEY, '==', userId)]
    )

    const registrationFeesSnaps = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_FEES.NAME,
      [where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.USER_ID.KEY, '==', userId)]
    )

    registrationByDaySnaps.docs.forEach((currDoc) => {
      registrationsByDay.push(
        getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
      )
    })

    registrationTicketSnaps.docs.forEach((currDoc) => {
      registrationTickets.push(
        getConvertedData(RegistrationTicketModel.fromFirestoreDoc(currDoc).toObject())
      )
    })

    registrationFeesSnaps.docs.forEach((currDoc) => {
      registrationFees.push(
        getConvertedData(RegistrationFeesModel.fromFirestoreDoc(currDoc).toObject())
      )
    })

    registrationsByDay.forEach((currRegistrationsByDay) => {
      dataTableItems_.push({
        amount: currRegistrationsByDay.registrationPrice ?? 0,
        eventName: currRegistrationsByDay.eventName ?? 'Unknown',
        refundAmount: currRegistrationsByDay.amountRefunded ?? 0,
        paymentStatus: currRegistrationsByDay.paymentStatus === 'paid' ? 'paid' : 'pending',
        scratchedAmount: currRegistrationsByDay.amountScratched ?? 0,
        from:
          currRegistrationsByDay.riderId === userId
            ? 'You'
            : currRegistrationsByDay.recipientName ?? 'Unknown',
        competitorId: userId,
        userId: currRegistrationsByDay.riderId,
        isRegistered: currRegistrationsByDay.riderId === userId,
        registrationId: currRegistrationsByDay.registrationDocId,
        eventId: currRegistrationsByDay.eventId,
      })
    })

    registrationTickets.forEach((currRegistrationTicket) => {
      dataTableItems_.push({
        eventName: currRegistrationTicket.eventName ?? 'Unknown',
        amount:
          (currRegistrationTicket.ticketPrice ?? 0) * currRegistrationTicket.selectedUnitsCount,
        refundAmount: currRegistrationTicket.amountRefunded ?? 0,
        paymentStatus: currRegistrationTicket.paymentStatus === 'paid' ? 'paid' : 'pending',
        scratchedAmount: currRegistrationTicket.amountScratched ?? 0,
        from: currRegistrationTicket.recipientId === userId ? 'You' : 'Unknown',
        competitorId: userId,
        userId: currRegistrationTicket.recipientId ?? userId,
        isRegistered: currRegistrationTicket.recipientId === userId,
        registrationId: currRegistrationTicket.registrationDocId,
        eventId: currRegistrationTicket.eventId,
      })
    })

    registrationFees.forEach((currRegistrationFees) => {
      dataTableItems_.push({
        eventName: currRegistrationFees.eventName ?? 'Unknown',
        amount: (currRegistrationFees.feesPrice ?? 0) * currRegistrationFees.selectedUnitsCount,
        refundAmount: currRegistrationFees.amountRefunded ?? 0,
        paymentStatus: currRegistrationFees.paymentStatus === 'paid' ? 'paid' : 'pending',
        scratchedAmount: currRegistrationFees.amountScratched ?? 0,
        from: currRegistrationFees.recipientId === userId ? 'You' : 'Unknown',
        competitorId: userId,
        userId: currRegistrationFees.recipientId ?? userId,
        isRegistered: currRegistrationFees.recipientId === userId,
        registrationId: currRegistrationFees.registrationDocId,
        eventId: currRegistrationFees.eventId,
      })
    })

    setDataTableItems(dataTableItems_)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    setLoading(true)

    getData()

    return () => {
      dispatch(setPaidBills([]))
      dispatch(setUnPaidBills([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortstyle])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const selectTab = (selectedTab: string) => {
    switch (selectedTab) {
      case CONST.UI.USER.BILLS.TABS.PAID.VALUE:
        return (
          <CompetitorAllBills
            dataTableItems_={dataTableItems}
            bills={paidBills}
            userId={userId}
            sortstyle={sortstyle}
            setSortStyle={setSortStyle}
            activeTab="paid"
            handleModal={props.handleModal}
          />
        )
      case CONST.UI.USER.BILLS.TABS.ALL.VALUE:
        return (
          <CompetitorAllBills
            bills={[...paidBills, ...unPaidBills]}
            dataTableItems_={dataTableItems}
            sortstyle={sortstyle}
            userId={userId}
            setSortStyle={setSortStyle}
            activeTab="all"
            handleModal={props.handleModal}
          />
        )
      case CONST.UI.USER.BILLS.TABS.UNPAID.VALUE:
        return (
          <CompetitorAllBills
            bills={unPaidBills}
            dataTableItems_={dataTableItems}
            userId={userId}
            sortstyle={sortstyle}
            activeTab="null"
            setSortStyle={setSortStyle}
            handleModal={props.handleModal}
          />
        )
      // case CONST.UI.USER.BILLS.TABS.REFUNDS.VALUE:
      //     return <CompetitorAllBills
      //         bills={[]}
      //         dataTableItems_={dataTableItems}
      //         userId={userId}
      //         sortstyle={sortstyle}
      //         activeTab="refunded"
      //         handleModal={props.handleModal}
      //     />
    }
  }

  return (
    <div className="overflow-auto max-h-[calc(100%-270px)]">
      {loading ? (
        <div className="flex justify-center items-center min-h-[200px]">
          <ViewsLoader
            className="flex items-center w-full justify-center min-h-[30vh] mt-3"
            size="lg"
            color="#F7074F"
          />
        </div>
      ) : (
        selectTab(props.selectedTab)
      )}
    </div>
  )
}

export default CompetitorBillDisplayTabs
