import { CONST } from '../../../../../../const/const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//  Hunter class B
export const EPL_FB_HUNTER_B = {
  vMain: 1,
  vSub: 0,
  vFix: 0,
  disciplineType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DISCIPLINE.TYPE.STANDARD,
  discipline: 'Hunter CLASS B',
  validationStatus: 'unknown',
  divisionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.DIVISION.TYPE.STANDARD,
  divisionItemsRules: null,
  divisions: [
    // ************************************************************
    //  <HUNTER B> 1900 - Hunter Breeding
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '1900',
      divisionTitle: 'Hunter Breeding',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 1901 Hunter Breeding Yearling
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '1901',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Hunter Breeding Yearling',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '1901 Hunter Breeding Yearling',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.STANDARD,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Sub Code 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Sub Code 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Sub Code 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionUnderSaddleClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Under Saddle Class 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionHandyClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Handy Class 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionConformationClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Conformation Class 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionHeight: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Height 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionTotalPriceMoney: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Total Price Money 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
              subSectionJudge: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
                meta: null,
                value: 'Judge 01',
                validation: [
                  {
                    type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                    errorMessage: "Field can't be empty",
                  },
                ],
                rules: null,
              },
            },
          ],
        },
        // ============================================================
        //  <HUNTER B> 1902 Hunter Breeding 2 Year Old
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '1902',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Hunter Breeding 2 Year Old',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '1902 Hunter Breeding 2 Year Old ',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 1903 Hunter Breeding 3 Year Old
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '1903',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Hunter Breeding 3 Year Old',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '1903 Hunter Breeding 3 Year Old',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <HUNTER B> 1938 - Pony Hunter Breeding
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '1938',
      divisionTitle: 'Pony Hunter Breeding',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 1904 - Pony Hunter Breeding Yearling
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '1904',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Pony Hunter Breeding Yearling',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '1904 Pony Hunter Breeding Yearling',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 1905 - Pony Hunter Breeding 2 Year Old
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '1905',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Pony Hunter Breeding 2 Year Old',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '1905 Pony Hunter Breeding 2 Year Old',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 1906 - Pony Hunter Breeding 3 Year Old
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '1906',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Pony Hunter Breeding 3 Year Old',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '1906 Pony Hunter Breeding 3 Year Old',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2001 - High Performance Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2001',
      divisionTitle: 'High Performance Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2005 - Performance Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2005',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Performance Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2005 Performance Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2006 - High Performance/ Green Hunter 3’6/3’9 Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2006',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'High Performance/ Green Hunter 3’6/3’9 Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2006 High Performance/ Green Hunter 3’6/3’9 Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2002 - Performance Hunter 3’6
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2002',
      divisionTitle: 'Performance Hunter 3’6',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2005 - Performance Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2005',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Performance Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2005 Performance Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2003 - Performance Hunter 3’3
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2003',
      divisionTitle: 'Performance Hunter 3’3',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2005 - Performance Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2005',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Performance Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2005 Performance Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2150 - Green Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2150',
      divisionTitle: 'Green Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2151 - Green Hunter 3’
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2151',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Green Hunter 3’',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2151 Green Hunter 3’',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2152 - Green Hunter 3’3
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2152',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: ' Green Hunter 3’3',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2152  Green Hunter 3’3',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2153 - Green Hunter 3’6
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2153',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Green Hunter 3’6',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2153 Green Hunter 3’6',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2154 - Green Hunter 3’9
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2154',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Green Hunter 3’9',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2154 Green Hunter 3’9',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2006 - High Performance/ Green Hunter 3’6/3’9 Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2006',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'High Performance/ Green Hunter 3’6/3’9 Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2006 High Performance/ Green Hunter 3’6/3’9 Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2180 - Young Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2180',
      divisionTitle: 'Young Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2181 - Young Hunter 5 & Under 2’9
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2181',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Young Hunter 5 & Under 2’9',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2181 Young Hunter 5 & Under 2’9',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2182 - Young Hunter 6 & Under 3’
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2182',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Young Hunter 6 & Under 3’',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2182 Young Hunter 6 & Under 3’',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2183 - Young Hunter 7 & Under 3’3
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2183',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Young Hunter 7 & Under 3’3',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2183 Young Hunter 7 & Under 3’3',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2203 - Amateur Owner 3’3
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2203',
      divisionTitle: 'Amateur Owner 3’3',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2204 - Amateur Owner 3’3 18-35
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2204',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Amateur Owner 3’3 18-35',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2204 Amateur Owner 3’3 18-35',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2205 - Amateur Owner 3’3 36+
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2205',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Amateur Owner 3’3 36+',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2205 Amateur Owner 3’3 36+',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 9303 - Combined A/O Junior Hunter 3’3
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9303',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Combined A/O Junior Hunter 3’3',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9303 Combined A/O Junior Hunter 3’3',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2200 - Amateur Owner 3’6
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2200',
      divisionTitle: 'Amateur Owner 3’6',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2201 - Amateur Owner Hunter 3’6 18-35
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2201',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Amateur Owner Hunter 3’6 18-35',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2201 Amateur Owner Hunter 3’6 18-35',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2202 - Amateur Owner Hunter 3’6 36+
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2202',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Amateur Owner Hunter 3’6 36+',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2202 Amateur Owner Hunter 3’6 36+',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 9300 - Combined A/O Junior Hunter 3’6
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9300',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Combined A/O Junior Hunter 3’6',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9300 Combined A/O Junior Hunter 3’6',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2301 - High Performance Conformation
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2301',
      divisionTitle: 'High Performance Conformation',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2300 - Conformation Hunter Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2300',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Hunter Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2300 Conformation Hunter Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2302 - Green Conformation Hunter 3’6
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2302',
      divisionTitle: 'Green Conformation Hunter 3’6',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2300 - Conformation Hunter Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2302',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Hunter Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2302 Conformation Hunter Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2420 - Junior Hunter 3’3
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2420',
      divisionTitle: 'Junior Hunter 3’3',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2421 - Junior Hunter 3’3 Small 15/U
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2421',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Junior Hunter 3’3 Small 15/U',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2421 Junior Hunter 3’3 Small 15/U',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2422 - Junior Hunter 3’3 Large 15/U
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2422',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Junior Hunter 3’3 Large 15/U',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2422 Junior Hunter 3’3 Large 15/U',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2423 - Junior Hunter 3’3 Small 16-17
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2423',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Junior Hunter 3’3 Small 16-17',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2423 Junior Hunter 3’3 Small 16-17',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2424 - Junior Hunter 3’3 Large 16-17
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2424',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Junior Hunter 3’3 Large 16-17',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2424 Junior Hunter 3’3 Large 16-17',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2412 - Small Junior Hunter 3’3 Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2412',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Small Junior Hunter 3’3 Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2412 Small Junior Hunter 3’3 Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2413 - Large Junior Hunter 3’3 Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2413',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Large Junior Hunter 3’3 Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2413 Large Junior Hunter 3’3 Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 9303 - Combined A/O Junior Hunter 3’3
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9303',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Combined A/O Junior Hunter 3’3',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9303 Combined A/O Junior Hunter 3’3',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2400 - Junior Hunter 3’6
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2400',
      divisionTitle: 'Junior Hunter 3’6',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2401 - Junior Hunter 3’6 Small 15/U
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2401',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Junior Hunter 3’6 Small 15/U',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2401 Junior Hunter 3’6 Small 15/U',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2402 - Junior Hunter 3’6 Large 15/U
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2402',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Junior Hunter 3’6 Large 15/U',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2402 Junior Hunter 3’6 Large 15/U',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2403 - Junior Hunter 3’6 Small 16-17
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2403',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Junior Hunter 3’6 Small 16-17',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2403 Junior Hunter 3’6 Small 16-17',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2404 - Junior Hunter 3’5 Large 16-17
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2404',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Junior Hunter 3’5 Large 16-17',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2404 Junior Hunter 3’5 Large 16-17',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2410 - Small Junior Hunter 3’6 Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2410',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Small Junior Hunter 3’6 Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2410 Small Junior Hunter 3’6 Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2411 - Large Junior Hunter 3’6 Combined
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2411',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Large Junior Hunter 3’6 Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2411 Large Junior Hunter 3’6 Combined',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 9300 - Combined A/O Junior Hunter 3’6
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9300',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Combined A/O Junior Hunter 3’6',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9300 Combined A/O Junior Hunter 3’6',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2500 - Pony Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2500',
      divisionTitle: 'Pony Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2501 - Small Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2501',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Small Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2501 Small Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2502 - Medium Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2502',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Medium Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2502 Medium Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2503 - Large Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2503',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Large Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Large Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2600 - Green Pony Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2600',
      divisionTitle: 'Green Pony Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2601 - Small/Medium Green Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2601',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Small/Medium Green Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2601 Small/Medium Green Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2602 - Large Green Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2602',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Large Green Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2602 Large Green Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2603 - Medium Green Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2603',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Medium Green Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2603 Medium Green Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2604 - Small Green Pony Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2604',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Small Green Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2604 Small Green Pony Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2700 - Children’s Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2700',
      divisionTitle: 'Children’s Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2701 - Children’s Hunter Pony
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2701',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Children’s Hunter Pony',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2701 Children’s Hunter Pony',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2702 - Children’s Hunter Horse
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2702',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Children’s Hunter Horse',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2702 Children’s Hunter Horse',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 9301 - Combined Children and Adults Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9301',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Combined Children and Adults Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9301 Combined Children and Adults Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2800 - Adult Amateur Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2800',
      divisionTitle: 'Adult Amateur Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2801 - Adult Amateur Hunter 18-35
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2801',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Adult Amateur Hunter 18-35',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2801 Adult Amateur Hunter 18-35',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2802 - Adult Amateur Hunter Over 36
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2802',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Adult Amateur Hunter Over 36',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2802 Adult Amateur Hunter Over 36',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 9301 - Combined Children and Adult Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9301',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Combined Children and Adult Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9301 Combined Children and Adult Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2900 - Ladies’ Sidesaddle
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2900',
      divisionTitle: 'Ladies’ Sidesaddle',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 3000 - (Junior) Hunter Classic
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3000',
      divisionTitle: '(Junior) Hunter Classic',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 3010 - Pony Hunter Classic
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3010',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Pony Hunter Classic',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3010 Pony Hunter Classic',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 3011 - Green Pony Hunter Classic
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3011',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Green Pony Hunter Classic',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3011 Green Pony Hunter Classic',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 3020 - Amateur Owner Hunter Classic
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3020',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Amateur Owner Hunter Classic',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3020 Amateur Owner Hunter Classic',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 3050 - Open Hunter Classic
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3050',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Open Hunter Classic',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '3050 Open Hunter Classic',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 3070 - USHJA Pony Hunter Derby
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3070',
      divisionTitle: 'USHJA Pony Hunter Derby',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 3040 - USHJA National Hunter Derby
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3040',
      divisionTitle: 'USHJA National Hunter Derby',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 3030 - USHJA International Hunter Derby
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3030',
      divisionTitle: 'USHJA International Hunter Derby',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 3100 - Miscellaneous Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3100',
      divisionTitle: 'Miscellaneous Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2004 - Small Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2004',
      divisionTitle: 'Small Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 3109 - Thoroughbred Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3109',
      divisionTitle: 'Thoroughbred Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2050 - USHJA Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2050',
      divisionTitle: 'USHJA Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 2051 - USHJA 2’
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2051',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'USHJA 2’',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2051 USHJA 2’',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2052 - USHJA 2’3
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2052',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'USHJA 2’3',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2052 USHJA 2’3',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2053 - USHJA 2’6
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2053',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'USHJA 2’6',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2053 USHJA 2’6',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2054 - USHJA 2’9
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2054',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'USHJA 2’9',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2054 USHJA 2’9',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 2055 - USHJA 3’
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2055',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'USHJA 3’',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '2055 USHJA 3’',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 12000 - USHJA Green Hunter Incentive Program
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '12000',
      divisionTitle: 'USHJA Green Hunter Incentive Program',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 12010 - USHJA Green Hunter Incentive 3’/3’3
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '12010',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'USHJA Green Hunter Incentive 3’/3’3',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'USHJA Green Hunter Incentive 3’/3’3',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 12020 - USHJA Green Hunter Incentive 3’6/3’9
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '12020',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'USHJA Green Hunter Incentive 3’6/3’9',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '12020 USHJA Green Hunter Incentive 3’6/3’9',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
        // ============================================================
        //  <HUNTER B> 12001 - USHJA Green Hunter Challenge
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '12001',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'USHJA Green Hunter Challenge',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '12001 USHJA Green Hunter Challenge',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2716 - Low Children’s Hunter Horse
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2716',
      divisionTitle: 'Low Children’s Hunter Horse',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2717 - Low Children’s Hunter Pony
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2717',
      divisionTitle: 'Low Children’s Hunter Pony',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    // <HUNTER B> 2805 - Low Adult Hunter
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '2805',
      divisionTitle: 'Low Adult Hunter',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <HUNTER B> 9302 - Combined Low Children and Low Adult Hunter
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.HUNTER_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9302',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Combined Low Children and Low Adult Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: '9302 Combined Low Children and Low Adult Hunter',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionUnderSaddleClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Under Saddle Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHandyClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Handy Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionConformationClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Conformation Class 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Height 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Total Price Money 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: null,
            value: 'Judge 01',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
            ],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.HUNTER_STANDARD,
          subsectionItemsRules: null,
          subSections: [],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
  ],
}
