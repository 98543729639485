import { CONST } from '../../../const/const'

export const HELP_PAGE_DATA = {
  tabs: [
    {
      tab: 1,
      tabTitle: 'Help',
      url: CONST.ROUTES.HELP.URL,
    },
    // {
    //     tab: 2,
    //     tabTitle: "FAQ",
    //     url: CONST.ROUTES.FAQ.URL
    // },
    {
      tab: 3,
      tabTitle: 'Policies',
      url: CONST.ROUTES.POLICIES.URL,
    },
  ],
}
