// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { motion } from 'framer-motion'
import ShowsHomePageMessagesMetricsSubSection from './ShowsHomePageMessagesMetricsSubSection'
import ShowsHomePageMessagesCorrespondenceSubSection from './ShowsHomePageMessagesCorrespondenceSubSection'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsHomePageMessagesSection: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <motion.div
      className="w-full mx-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.1,
      }}
    >
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <ShowsHomePageMessagesMetricsSubSection />
      </dl>
      <ShowsHomePageMessagesCorrespondenceSubSection />
    </motion.div>
  )
}

export default ShowsHomePageMessagesSection
