// Component imports

// Constants
import { MODAL_CONSTS } from '../../const/modal-const'
import { useEffect, useRef, useState } from 'react'
import { Search } from '@mui/icons-material'
import FirestoreService from '../../services/firestoreService'
import { CONST } from '../../const/const'
import { IHorseData } from '../../models/horse/horse.interface'
import { HorseModel } from '../../models/horse/horse.model'
import {
  IHorseLineage,
  THorseTeamMembers,
} from '../../models/horse-lineage/horse-lineage.interface'
import { selectObjToString } from '../../models/interface.helper'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { getSelectedHorseLineage, setSelectedHorseLineage } from '../../store/horses/horseSlice'
import { Clear } from '@mui/icons-material'
import helpers from '../../commonHelpers/helpers'
import { HorseLineageModel } from '../../models/horse-lineage/horse-lineage-model'

// images
const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'
// Types
type ILineageHorseAdd = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

interface IWithSelectHorseData extends THorseTeamMembers {
  selected: boolean
}

const LineageHorseAdd = (props: ILineageHorseAdd) => {
  //Hooks and Vars
  const searchBoxRef = useRef<HTMLInputElement>(null)
  const [listToShow, setListToShow] = useState<any[]>([])
  const [allHorsesData, setAllHorsesData] = useState<IHorseLineage['horseLineageMembers']>()
  const [teamMember, setTeamMember] = useState<any[]>([])
  const dispatch = useAppDispatch()
  const SelectedHorseLineage = useAppSelector(getSelectedHorseLineage)
  const showSearchList = searchBoxRef?.current?.value !== ''

  const getAllHorseData = async () => {
    let horses: IHorseData[] = []

    try {
      let selectHorseForLineage: string[] =
        SelectedHorseLineage?.horseLineageMembers?.reduce((acc: string[], currentHorse) => {
          acc.push(currentHorse.id)
          return acc
        }, []) ?? []

      const allHorse = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.HORSES.NAME,
        []
      )

      allHorse.docs.forEach((doc) => {
        let data = HorseModel.fromFirestoreDoc(doc).toObject()
        horses.push(data)
      })

      let data = horses.reduce((acc: IWithSelectHorseData[], currentHorse: IHorseData) => {
        acc.push({
          id: currentHorse?.id ?? '',
          horseName: currentHorse?.horseName ?? '',
          horseProfile: currentHorse?.horseProfilePicture ?? '',
          decipline: currentHorse?.horseDiscipline ?? '',
          horseZone: selectObjToString(currentHorse?.horseZone),
          horseCountry: selectObjToString(currentHorse?.horseCountry),
          lineageName: '',
          selected: selectHorseForLineage.includes(currentHorse?.id ?? ''),
        })
        return acc
      }, [])

      setAllHorsesData(data)
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    }
  }

  useEffect(() => {
    getAllHorseData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Filters the user in actual
  const handleHorse = (strToSearch: string) => {
    if (strToSearch === '') return setListToShow([])
    const filteredUserList = allHorsesData?.filter((curr) => {
      return curr.horseName?.toLocaleLowerCase()?.startsWith(strToSearch?.toLocaleLowerCase())
    })
    setListToShow([...(filteredUserList ?? [])])
  }

  useEffect(() => {
    if (searchBoxRef.current?.value) handleHorse(searchBoxRef.current?.value)
    setTeamMember([...(allHorsesData?.filter((curr) => curr?.selected === true) ?? [])])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allHorsesData])

  const filterUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const strToSearch = e.target.value
    handleHorse(strToSearch)
  }

  const handleChange = ({ e, item }: { e: any; item: any }) => {
    const select = e.target.checked
    if (allHorsesData) {
      let allHorsesDataArrCopy = [...allHorsesData]
      allHorsesDataArrCopy = allHorsesDataArrCopy.map((currUser) => {
        if (currUser.id === item?.id) {
          currUser.selected = select
          currUser.newSelected = true
        }
        return currUser
      })
      setAllHorsesData([...allHorsesDataArrCopy])
    }
  }

  const HandleHorsesLineage = () => {
    const selectHorses = allHorsesData?.filter((current: any) => {
      return current.selected
    })

    dispatch(
      setSelectedHorseLineage({
        data: {
          ...(SelectedHorseLineage ?? new HorseLineageModel().toObject()),
          horseLineageMembers: selectHorses ?? [],
        },
      })
    )

    props.handleModal(false, MODAL_CONSTS.LINEAGE_ADDING_HORSES)
  }

  return (
    <div
      className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto show d-block backShadow`}
      id="exampleModalCenter"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-xl outline-none text-current py-10 px-8">
          <span
            onClick={() => props.handleModal(false, MODAL_CONSTS.LINEAGE_ADDING_HORSES)}
            className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
          >
            {/* <img
                            src="/assets/og_icons/Close.svg"
                            className="w-6"
                            alt="close icon"
                        /> */}
            <Clear
              fontSize={'small'}
              style={{
                color: 'grey',
                fontWeight: '400',
              }}
            />
          </span>
          <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
            <h5
              className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal"
              id="exampleModalScrollableLabel"
            >
              Add horse to lineage
            </h5>
          </div>
          <div className="inputSearch mt-4 flex items-center text-SeabiscuitDark200ThemeColor">
            <input
              ref={searchBoxRef}
              type="text"
              onChange={filterUsers}
              placeholder="Search.."
              className="rounded-full w-full border focus:border-[#D3DAEE] text-SeabiscuitDark200ThemeColor border-[#D3DAEE] focus:ring-0"
            />
            <Search className="absolute text-SeabiscuitMainThemeColor right-12" />
          </div>

          <div className="uselListWarp mt-4 flex flex-col">
            {(showSearchList ? listToShow : teamMember)?.length ? (
              (showSearchList ? listToShow : teamMember)?.map((item, index) => {
                return (
                  <label
                    key={index}
                    className="form-check mb-3 cursor-pointer"
                    htmlFor={'teammember' + index}
                  >
                    <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                      <div className="min-w-fit flex items-center">
                        <img
                          src={
                            item.horseProfile && item.horseProfile !== ''
                              ? item.horseProfile
                              : '/assets/og_icons/User-1.svg'
                          }
                          className="h-[40px] w-[40px] rounded-full object-cover mr-4"
                          alt="demoUser"
                        />
                        <div className="hourseDetails w-10/12">
                          <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                            <p className="text-SeabiscuitDark200ThemeColor text-md">
                              {item?.horseName}
                            </p>
                            <p className="text-xs">
                              {item?.defaultRole}{' '}
                              {item?.memberEmail ? `, ${item?.memberEmail}` : ''}
                            </p>
                          </div>
                        </div>
                      </div>
                      {item.selected ? (
                        <img
                          src={CHECKED_ICON}
                          className="relative right-[0px] top-[0px]"
                          width={20}
                          height={20}
                          alt="tick icon"
                        />
                      ) : null}
                      <input
                        onChange={(e) => {
                          handleChange({ e, item })
                        }}
                        checked={item?.selected}
                        id={'teammember' + index}
                        className={`ml-2 form-check-input appearance-none h-4 w-4 border active:bg-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer checked:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor ${item.selected ? 'hidden' : ''}`}
                        type="checkbox"
                      />
                    </div>
                  </label>
                )
              })
            ) : (
              <div className="w-full mt-2 text-center text-SeabiscuitDark200ThemeColor">
                {showSearchList
                  ? 'No result found.'
                  : // `Please search to add a ${types.CLINIC_TABS ? "signatory" : "team member"}.`
                    null}
              </div>
            )}
          </div>

          <button
            type="button"
            className="w-1/2 h-12 mx-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-xl  shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-0 mt-32"
            onClick={HandleHorsesLineage}
          >
            SAVE
          </button>

          <button
            onClick={() => props.handleModal(false, MODAL_CONSTS.LINEAGE_ADDING_HORSES)}
            type="button"
            className="w-1/2 h-12 mx-auto mt-2 block items-center justify-center py-2 px-4 border border-transparent rounded-xl  shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
          >
            CANCEL
          </button>
        </div>
      </div>
    </div>
  )
}

export default LineageHorseAdd
