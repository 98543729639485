// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import EventPaperworkFormComponent from '../../../components/events/forms/paperwork/EventPaperworkFormComponent'
import { useIonRouter } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { EventPaperworkModel } from '../../../models/event-paperwork/event-paperwork.model'
import { useAppSelector } from '../../../store/hooks'
import { selectedEvent } from '../../../store/events/eventsSlice'

interface ShowsDetailPageEventPaperworkFormSubSectionProps {
  onSetEventTab: (tab: string) => void
  onSetNextEventTab: (tab: string) => void
  nextEventTab: string
  eventTab?: string
}

const ShowsDetailPageEventPaperworkFormSubSection: React.FC<
  ShowsDetailPageEventPaperworkFormSubSectionProps
> = (props) => {
  const router = useIonRouter()
  const history = useHistory()

  const selectedEventR = useAppSelector(selectedEvent)

  const eventId = selectedEventR.Event?.id ?? ''

  const [data, setData] = useState<any>(null)

  const validFormHandler = async (data: any) => {
    let updated = true

    const updated_data = new EventPaperworkModel({
      ...data,
    })

    await new Promise((resolve) => {
      FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_PAPERWORK.NAME,
        eventId,
        updated_data.toFirestore()
      )
        .then((_: any) => {
          setData(updated_data.toObject())
        })
        .catch((reason) => {
          updated = false
          console.error('validFormHandler:' + reason)
        })
        .finally(() => {
          resolve(true)
        })
    })

    return {
      updated,
      onSetEventTab: props.nextEventTab == '' ? props.onSetEventTab : props.onSetEventTab(props?.nextEventTab),
      onSetNextEventTab: props.onSetNextEventTab('')
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const validAndValidFormHandler = async (data: any) => {
    const valueToReturn = await validFormHandler(data)
    return valueToReturn
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidFormHandler = async (data: any) => {
    props.onSetNextEventTab('')
    alert('invalid')
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidAndValidFormHandler = async (data: any) => {
    props.onSetNextEventTab('')
    alert('invalid')
    router.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
    history.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        const doc = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_PAPERWORK.NAME,
          eventId
        )
        if (doc) {
          setData(EventPaperworkModel.fromFirestoreDoc(doc).toObject())
        }
      }
      fetchData().catch()
    }
  }, [data, setData, eventId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <EventPaperworkFormComponent
        data={data}
        onValid={validFormHandler}
        onInvalid={invalidFormHandler}
        onValidAnExit={validAndValidFormHandler}
        onInvalidAndExit={invalidAndValidFormHandler}
        nextEventTab={props.nextEventTab}
        eventTab={props.eventTab}
      />
    </>
  )
}

export default ShowsDetailPageEventPaperworkFormSubSection
