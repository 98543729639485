/* eslint-disable react-hooks/exhaustive-deps */
// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { EventSponsorsModel } from '../../../../models/event-sponsors/event-sponsors.model'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import AmountInput from '../../../common/inputs/AmountInput'
import EventFormContainerComponent from '../container/EventFormContainerComponent'
import '../fees/EventFeesFormComponent.css'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import SponsorsAutofillModal from './SponsorsAutofillModal'
import SwitchElement from './SwitchElement'
import GreenCheck from './assets/green-check.svg'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const eventSponsorsFormDefaultValues = new EventSponsorsModel().toObject()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventSponsorsFormComponentProps {
  data: any
  onValid: any
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  handleModal?: any
  dataToPassOn?: any
  nextEventTab: string
  eventTab?: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventSponsorsFormComponent: React.FC<EventSponsorsFormComponentProps> = (props) => {
  const handleModalContext = useContext(HandleModalContext)
  const [sponsorsAutofillModalShow, setSponsorsAutofillModalShow] = useState(false)

  const { handleSubmit, register, setValue, watch, trigger, reset, control } = useForm({
    defaultValues: { ...eventSponsorsFormDefaultValues },
    mode: 'onChange',
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sponsorOpportunities',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const TableTitle = ({ children, tooltipText }: { children: string; tooltipText: string }) => (
    <div className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mini:block !flex !items-center">
      {children}
      <Tooltip title={<h1 className="tooltip_title">{tooltipText}</h1>} placement="top" arrow>
        <button className="ml-1">
          <TooltipIcon color="#122B46" />
          {/* <img className="w-3 h-3" src="/assets/cp_icons/Help.svg" alt="icon" /> */}
        </button>
      </Tooltip>
    </div>
  )

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  // const sponsorsTableDataHandle = (e: any, index: number, key: string) => {
  //     const temp = [...acceptSponsers];
  //     temp[index][key] = e.target.value;
  //     setAcceptSponsers(temp);
  // };

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  // const addSponserHandle = () => {
  //     setAcceptSponsers([
  //         ...acceptSponsers,
  //         {
  //             name: "",
  //             summary: "",
  //             number: "",
  //             price: "",
  //             uuid: uuidv4()
  //         },
  //     ]);
  // };

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (props.data) {
      reset(props.data)
    } else {
      reset(eventSponsorsFormDefaultValues)
    }
    append({
      sponsorOpportunityName: '',
      summary: '',
      available: '',
      cost: '',
      uuid: uuidv4(),
      isEmpty: true,
    })
    trigger().then()
  }, [props.data, reset, trigger])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  /**
   * @param noteInputRef Input reference
   * @info Opens note modal
   */
  const openAddNotesModal = (noteInputRef: any) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NOTE, {
      noteInputRef,
      setValue,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <EventFormContainerComponent>
      <div>
        <SponsorsAutofillModal
          show={sponsorsAutofillModalShow}
          onHide={() => setSponsorsAutofillModalShow(false)}
        />
        <div className="w-full">
          <EventFormHeaderComponent
            title="Sponsors"
            description="Sell sponsor packages at your event"
          >
            <EventFormFooterCommonComponent
              nextEventTab={props.nextEventTab}
              onNext={(e: any) => handleSubmit(props.onValid, props.onInvalid)(e)}
              onSaveAndExit={(e: any) =>
                handleSubmit(props.onValidAnExit, props.onInvalidAndExit)(e)
              }
            />
          </EventFormHeaderComponent>

          <div className="flex gap-2 mini:items-center flex-col mini:flex-row pt-5">
            <div className="text-SeabiscuitDark200ThemeColor flex items-center justify-end mr-2">
              Title sponsor
              <Tooltip
                title={
                  <h1 className="tooltip_title">{`This sponsor will display at the top of your event page. It is the most valuable sponsor product you can sell`}</h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                  {/* <img className="w-4 h-4" src="/assets/cp_icons/Help.svg" alt="icon" /> */}
                </button>
              </Tooltip>
            </div>

            {/* <div className="mini:w-[192px] relative">
                            <input type="text"
                                {...register(`sponsorTitle`)}
                                className="w-full outline-0 ring-0 focus:outline-0 focus:ring-0 border-0 focus:border-0 text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor rounded-md py-3"
                                placeholder="$10,000"
                            />
                            {
                                (watch(`sponsorTitle`) && `watch(sponsorTitle) !== ""`) ? <img src={GreenCheck} className="absolute right-1 top-2" alt="green check" /> : null
                            }
                        </div> */}

            {/*public isSponsorTitleForSale: boolean;*/}
            {/*public acceptSponsorshipOnGrounds: boolean;*/}
            {/*public allowSponsorshipPrizeList: boolean;*/}
            <div className="mini:w-[192px]">
              {watch('isSponsorTitleForSale') ? (
                <AmountInput
                  name={'sponsorTitle'}
                  register={register}
                  prefix="$"
                  placeholder="Price per unit"
                  inputClassName="w-full outline-0 ring-0 focus:outline-0 focus:ring-0 border-0 focus:border-0 text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor rounded-md py-3 text-center"
                  className="mini:w-[192px] relative text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12"
                  value={watch('sponsorTitle')}
                  childrenToAppend={
                    <>
                      {watch(`sponsorTitle`) &&
                      watch(`sponsorTitle`) !== '' &&
                      watch(`sponsorTitle`) !== '$' &&
                      watch(`sponsorTitle`) !== '$0' ? (
                        <img src={GreenCheck} className="right-1 top-2" alt="green check" />
                      ) : null}
                    </>
                  }
                />
              ) : null}
            </div>
            <Controller
              name="isSponsorTitleForSale"
              control={control}
              render={({ field: { value } }) => (
                <SwitchElement
                  onSwitch={() => setValue('isSponsorTitleForSale', !value)}
                  on={value}
                  onText="For Sale"
                  offText="No"
                  width={130}
                  text_size="text-base"
                />
              )}
            />
          </div>

          <div className="flex mini:items-center my-8 gap-2 mini:gap-10 flex-col mini:flex-row">
            <label className="2xl:text-xl xl:text-[16px] text-SeabiscuitDark200ThemeColor font-normal">
              Accept sponsors (signage, jumps, etc.) at this event?
            </label>

            <Controller
              name="acceptSponsorshipOnGrounds"
              control={control}
              render={({ field: { value } }) => (
                <SwitchElement
                  onSwitch={() => {
                    setValue('acceptSponsorshipOnGrounds', !value)
                  }}
                  on={value}
                  width={100}
                />
              )}
            />
          </div>

          {watch('acceptSponsorshipOnGrounds') ? (
            <>
              <div className="w-full text-xs overflow-x-auto pb-[20px]">
                <div className="w-[1320px] grid grid-cols-[auto] mini:grid-cols-[270px_minmax(300px,auto)_170px_170px_50px] gap-2">
                  <TableTitle tooltipText="Eg. ‘Medium Sign at Park’ or ‘Email banner’">
                    Name sponsor opportunity
                  </TableTitle>
                  <TableTitle tooltipText="Details what’s included in this sponsorship package">
                    Summary - max 150 characters
                  </TableTitle>
                  <TableTitle tooltipText="Number of these sponsorship packages for sale.">
                    Number of units
                  </TableTitle>
                  <TableTitle tooltipText="Price you will charge for this sponsorship package">
                    Price per unit
                  </TableTitle>
                  <div></div>
                  {fields.map((field, index) => (
                    <React.Fragment key={field.id}>
                      <div
                        className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 outline-0 ring-0`}
                      >
                        <input
                          type="text"
                          {...register(`sponsorOpportunities.${index}.sponsorOpportunityName`)}
                          placeholder="Name sponsor opportunity"
                          className="w-full bg-transparent outline-0 ring-0 focus:outline-0 focus:ring-0 border-0 focus:border-0 text-SeabiscuitDark200ThemeColor"
                        />
                      </div>

                      <div
                        className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12`}
                      >
                        <input
                          type="text"
                          {...register(`sponsorOpportunities.${index}.summary`)}
                          readOnly={true}
                          onClick={(e) => openAddNotesModal(e.target)}
                          placeholder="Opportunity summary - max 150 characters"
                          className="w-full bg-transparent outline-0 ring-0 focus:outline-0 focus:ring-0 border-0 focus:border-0 text-SeabiscuitDark200ThemeColor cursor-pointer"
                        />
                      </div>

                      <div
                        className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12`}
                      >
                        <input
                          type="number"
                          {...register(`sponsorOpportunities.${index}.available`)}
                          placeholder="Number of units"
                          className="w-full bg-transparent outline-0 ring-0 focus:outline-0 focus:ring-0 border-0 focus:border-0 text-SeabiscuitDark200ThemeColor text-center"
                        />
                      </div>

                      <AmountInput
                        name={`sponsorOpportunities.${index}.cost`}
                        register={register}
                        prefix="$"
                        placeholder="Price per unit"
                        inputClassName="w-full bg-transparent outline-0 ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor focus:outline-0 focus:ring-0 border-0 text-center"
                        className="text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12"
                        value={watch(`sponsorOpportunities.${index}.cost`)}
                        childrenToAppend={
                          <>
                            {watch(`sponsorOpportunities.${index}.sponsorOpportunityName`) &&
                            watch(`sponsorOpportunities.${index}.sponsorOpportunityName`) !== '' &&
                            watch(`sponsorOpportunities.${index}.summary`) &&
                            watch(`sponsorOpportunities.${index}.summary`) !== '' &&
                            watch(`sponsorOpportunities.${index}.available`) &&
                            watch(`sponsorOpportunities.${index}.available`) !== '' &&
                            watch(`sponsorOpportunities.${index}.available`) !== '0' &&
                            watch(`sponsorOpportunities.${index}.cost`) &&
                            watch(`sponsorOpportunities.${index}.cost`) !== '' &&
                            watch(`sponsorOpportunities.${index}.cost`) !== '$' &&
                            watch(`sponsorOpportunities.${index}.cost`) !== '$0' ? (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 2C6.48603 2 2 6.48604 2 12C2 17.514 6.48603 22 12 22C17.514 22 22 17.514 22 12C22 6.48604 17.514 2 12 2ZM12 3.5C16.7033 3.5 20.5 7.2967 20.5 12C20.5 16.7033 16.7033 20.5 12 20.5C7.29669 20.5 3.5 16.7033 3.5 12C3.5 7.2967 7.29669 3.5 12 3.5ZM15.7354 8.99316C15.5406 8.99884 15.3556 9.0801 15.2197 9.21973L10.75 13.6895L8.78027 11.7197C8.71116 11.6477 8.62839 11.5903 8.53679 11.5507C8.4452 11.5111 8.34662 11.4902 8.24684 11.4892C8.14706 11.4882 8.04808 11.5071 7.9557 11.5448C7.86332 11.5825 7.77939 11.6383 7.70883 11.7088C7.63828 11.7794 7.58251 11.8633 7.54479 11.9557C7.50707 12.0481 7.48817 12.1471 7.48918 12.2468C7.4902 12.3466 7.51111 12.4452 7.5507 12.5368C7.59028 12.6284 7.64775 12.7112 7.71973 12.7803L10.2197 15.2803C10.3604 15.4209 10.5511 15.4999 10.75 15.4999C10.9489 15.4999 11.1396 15.4209 11.2803 15.2803L16.2803 10.2803C16.3885 10.1749 16.4623 10.0393 16.4922 9.89126C16.5221 9.7432 16.5066 9.58958 16.4477 9.4505C16.3888 9.31141 16.2893 9.19335 16.1622 9.11175C16.0351 9.03015 15.8863 8.98882 15.7354 8.99316Z"
                                  fill="#00B6AA"
                                />
                              </svg>
                            ) : null}
                          </>
                        }
                      />
                      <div className={`text-[14px] w-full flex items-center h-12`}>
                        <button
                          onClick={() => {
                            props.handleModal(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
                              removeItem: function () {
                                remove(index)
                              },
                            })
                          }}
                        >
                          {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12 2C6.48603 2 2 6.48604 2 12C2 17.514 6.48603 22 12 22C17.514 22 22 17.514 22 12C22 6.48604 17.514 2 12 2ZM12 3.5C16.7033 3.5 20.5 7.2967 20.5 12C20.5 16.7033 16.7033 20.5 12 20.5C7.29669 20.5 3.5 16.7033 3.5 12C3.5 7.2967 7.29669 3.5 12 3.5ZM15.2432 7.98926C15.0451 7.99394 14.8569 8.07679 14.7197 8.21973L12 10.9395L9.28027 8.21973C9.21036 8.14773 9.12672 8.0905 9.03429 8.05142C8.94187 8.01233 8.84254 7.99219 8.74219 7.99219C8.59293 7.99222 8.44707 8.03679 8.32328 8.12019C8.19949 8.20358 8.1034 8.32202 8.0473 8.46033C7.99119 8.59865 7.97763 8.75056 8.00835 8.89662C8.03907 9.04269 8.11267 9.17627 8.21973 9.28027L10.9395 12L8.21973 14.7197C8.14775 14.7888 8.09028 14.8716 8.0507 14.9632C8.01111 15.0548 7.9902 15.1534 7.98918 15.2532C7.98817 15.3529 8.00707 15.4519 8.04479 15.5443C8.08251 15.6367 8.13828 15.7206 8.20883 15.7912C8.27939 15.8617 8.36332 15.9175 8.4557 15.9552C8.54808 15.9929 8.64706 16.0118 8.74684 16.0108C8.84662 16.0098 8.9452 15.9889 9.03679 15.9493C9.12839 15.9097 9.21116 15.8523 9.28027 15.7803L12 13.0605L14.7197 15.7803C14.7888 15.8523 14.8716 15.9097 14.9632 15.9493C15.0548 15.9889 15.1534 16.0098 15.2532 16.0108C15.3529 16.0118 15.4519 15.9929 15.5443 15.9552C15.6367 15.9175 15.7206 15.8617 15.7912 15.7912C15.8617 15.7206 15.9175 15.6367 15.9552 15.5443C15.9929 15.4519 16.0118 15.3529 16.0108 15.2532C16.0098 15.1534 15.9889 15.0548 15.9493 14.9632C15.9097 14.8716 15.8523 14.7888 15.7803 14.7197L13.0605 12L15.7803 9.28027C15.8893 9.17559 15.9642 9.0404 15.9951 8.89242C16.026 8.74444 16.0115 8.59058 15.9534 8.451C15.8953 8.31143 15.7965 8.19266 15.6697 8.11026C15.543 8.02787 15.3943 7.98569 15.2432 7.98926Z" fill="#00B6AA" />
                                                </svg> */}
                          <div className="cursor-pointer">
                            <img
                              src="/assets/og_icons/Cancel.svg"
                              className="h-[24px] w-[24px]"
                              alt="Cancel"
                            />
                          </div>
                        </button>
                      </div>
                      <div className="row-seperator mt-6 mini:hidden"></div>
                    </React.Fragment>
                  ))}
                </div>
              </div>

              <div className="flex gap-5 justify-between mt-4">
                <button
                  onClick={() => {
                    append({
                      sponsorOpportunityName: '',
                      summary: '',
                      available: '',
                      cost: '',
                      uuid: uuidv4(),
                    })
                  }}
                  className="my-3 text-[#8676FF] font-bold text-[12px] flex items-center gap-2"
                >
                  <img
                    src={`/assets/og_icons/Cancel-5.svg`}
                    className=" rotate-45"
                    alt="plus icon"
                  />
                  <span>Add Sponsor Item</span>
                </button>
                <button
                  onClick={() => {
                    remove()
                  }}
                  className="my-3 text-SeabiscuitDark200ThemeColor hover:text-SeabiscuitRedThemeColor font-normal text-[12px] flex items-center gap-2"
                >
                  <span>Clear All</span>
                </button>
              </div>
            </>
          ) : null}

          {/*<button*/}
          {/*    onClick={addSponserHandle}*/}
          {/*    className="my-3 text-[#8676FF] font-bold text-[12px] flex items-center gap-2"*/}
          {/*>*/}
          {/*    <img src={plusIcon} alt="plus" />*/}
          {/*    <span>Add Sponsor Item</span>*/}
          {/*</button>*/}
          {/*<div className="flex mini:items-center my-6 gap-2 mini:gap-10 flex-col mini:flex-row">*/}
          {/*    <label className="text-xl text-SeabiscuitDark200ThemeColor font-normal">*/}
          {/*        Allow sponsorship of the prize list?*/}
          {/*    </label>*/}

          {/*    <Controller name="allowSponsorshipPrizeList"*/}
          {/*                control={control}*/}
          {/*                render={({ field: { value} }) => (*/}
          {/*                    <SwitchElement onSwitch={() => setValue('allowSponsorshipPrizeList',!value)}*/}
          {/*                                   on={value} />*/}
          {/*                )}/>*/}

          {/*</div>*/}

          {/*<div className="pt-4 pb-12 px-4 w-full bg-white">*/}
          {/*    <EventSponsorsFormBuilderUtil register={register}*/}
          {/*                                  data={props.data?.prizeListSponsors}*/}
          {/*                                  errors={errors}*/}
          {/*                                  setValue={setValue}*/}
          {/*                                  getValues={getValues}*/}
          {/*                                  control={control}/>*/}
          {/*</div>*/}

          {/*====== OLD TABLE START ======*/}
          {/*<SponsorsTable*/}
          {/*    setAutofill={() => setSponsorsAutofillModalShow(true)}*/}
          {/*/>*/}

          {/*====== OLD TABLE END ======*/}
        </div>
      </div>
    </EventFormContainerComponent>
  )
}

export default EventSponsorsFormComponent
