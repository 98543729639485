import customImageComponent from '../../../../../components/common/CustomImageComponent'
import PROFILE_DETAIL_CARD_CONST from '../../../../../components/pageWise/visitedUserDetails/profile-details-card/profileDetailCard.const'
import { HorseModel } from '../../../../../models/horse/horse.model'
import IProfileCard from '../types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const HorsePublicProfileImageSection = (props: IProfileCard['IProps']) => {
  const selectedHorse = new HorseModel(props.selectedHorse ?? undefined).toObject()

  return (
    <div className="bg-SeabiscuitLightGrayThemeColor rounded-xl flex flex-col items-center justify-center px-6 py-5 w-[375px]">
      <span className="w-[110px] h-[110px] rounded-full border-[3px] border-white overflow-hidden">
        {customImageComponent(
          selectedHorse?.horseProfilePicture
            ? selectedHorse.horseProfilePicture
            : PROFILE_DETAIL_CARD_CONST.DEFAULT_USER_PIC,
          selectedHorse.horseName,
          'object-cover w-full bg-white h-full'
        )}
      </span>

      <span className="font-bold text-xl my-3 text-SeabiscuitDark200ThemeColor capitalize">
        {selectedHorse?.horseName ?? 'Horse'}
      </span>

      {/* <div className="flex justify-center w-full">
                <button
                    type="button"
                    onClick={() => props.onFollowBtnClick(selectedHorse)}
                    className={
                        clsx(
                            "h-[40px] border mr-1 border-solid rounded-xl flex items-center text-[12px] px-4 capitalize text-SeabiscuitDark200ThemeColor border-SeabiscuitDark200ThemeColor w-[34%] justify-around bg-SeabiscuitWhiteThemeColor",
                            props.loading && "pointer-events-none"
                        )} >
                    {props?.following ?
                        <AutorenewRounded fontSize='small' className='animate-spin mx-auto w-[30px]' />
                        : <>
                    <img src="/assets/og_icons/Add User Male-1.svg" alt="add user" className="h-[20px] w-[20px]" />
                    {props?.alreadyFollowed ? "Unfollow" : "Follow"}
                    </>}
                </button>

                <button
                    type="button"
                    className="h-[40px] ml-1 border border-solid rounded-xl text-[12px] flex items-center justify-around px-4 capitalize text-SeabiscuitDark200ThemeColor border-SeabiscuitDark200ThemeColor w-[34%]  bg-SeabiscuitWhiteThemeColor">
                    <img src="/assets/og_icons/Speech Bubble-1.svg" alt="message" className="h-[20px] w-[20px]" />
                    <span className="flex flex-col justify-end">
                        <span>Message</span>
                    </span>
                </button>
            </div> */}

      {(selectedHorse?.horseDiscipline ||
        selectedHorse?.horseZone ||
        selectedHorse?.horseCountry) && (
        <div className="px-4 py-2 flex mt-4 flex-wrap rouded-lg w-full rounded-xl justify-between bg-white">
          {!!selectedHorse.horseDiscipline && (
            <>
              <div className="w-1/3 flex flex-col justify-between items-center px-2 text-center">
                <span className="font-bold text-SeabiscuitDark200ThemeColor capitalize h-full flex items-center break-all leading-[1.2] mb-1 md:text-[14px] 2xl:text-base">
                  {selectedHorse.horseDiscipline}
                </span>
                <span className="text-sm text-SeabiscuitLightParagraphTextColor">Discipline</span>
              </div>
            </>
          )}

          {!!selectedHorse?.horseZone && (
            <div className="w-1/3 flex flex-col justify-between items-center px-2 text-center">
              <span className="font-bold text-SeabiscuitDark200ThemeColor capitalize h-full flex items-center break-all leading-[1.2] mb-1 md:text-[14px] 2xl:text-base">
                {selectedHorse?.horseZone}
              </span>
              <span className="text-sm text-SeabiscuitLightParagraphTextColor">Zone</span>
            </div>
          )}

          {!!selectedHorse?.horseCountry && (
            <div className="w-1/3 flex flex-col justify-between items-center px-2 text-center">
              <span className="font-bold text-SeabiscuitDark200ThemeColor capitalize h-full flex items-center break-all leading-[1.2] mb-1 md:text-[14px] 2xl:text-base break-normal">
                {selectedHorse?.horseCountry}
              </span>
              <span className="text-sm text-SeabiscuitLightParagraphTextColor">Nationality</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default HorsePublicProfileImageSection
