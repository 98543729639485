// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import EventMetricsCardComponent, {
  ShowsMetricDisplayCardComponentProps,
} from '../../cards/metrics/EventMetricsCardComponent'
import { VariableIcon } from '@heroicons/react/outline'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventMetricsDisplayCardStackProps {
  metrics?: ShowsMetricDisplayCardComponentProps[]
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventMetricsDisplayCardStackComponent: React.FC<EventMetricsDisplayCardStackProps> = (
  props
) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      {props.metrics?.map((item) => (
        <EventMetricsCardComponent
          id={item.id.toString()}
          name={item.name}
          icon={item.icon}
          mainMetric={item.mainMetric}
          subMetric={item.subMetric}
        />
      ))}
    </>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
EventMetricsDisplayCardStackComponent.defaultProps = {
  metrics: [
    { id: 'alpha', name: 'Metric 1', mainMetric: '1', icon: VariableIcon, subMetric: '1' },
    { id: 'beta', name: ' Metric 2', mainMetric: '32', icon: VariableIcon, subMetric: '2' },
    { id: 'gama', name: 'Metric 3', mainMetric: '100', icon: VariableIcon, subMetric: '3' },
    { id: 'iota', name: 'Metric 4', mainMetric: '123', icon: VariableIcon, subMetric: '4' },
  ],
}

export default EventMetricsDisplayCardStackComponent
