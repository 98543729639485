import { where } from 'firebase/firestore'
import helpers from '../commonHelpers/helpers'
import { CONST } from '../const/const'
import { MESSAGES_CONST } from '../const/messages-const'
import { CustomError } from '../helpers/helpers'
import { TEventRegisteredUsers } from '../models/event-registered-users/event-registered-users.interface'
import { IEventReviewPublish } from '../models/event-review-publish/event-review-publish.interface'
import { EventReviewPublishModel } from '../models/event-review-publish/event-review-publish.model'
import FirestoreService from './firestoreService'
import { getConvertedData } from '../models/interface.helper'
import { EventRegisteredUsersModel } from '../models/event-registered-users/event-registered-users.model'
import UserService from './userService'
import { UserModel } from '../models/users/user.model'
import { IUserInterface } from '../models/users/user.interface'
import { EventDetailsModel } from '../models/event-details/event-details.model'

const CUSTOM_ERROR_PROPS = {
  fileName: 'eventService',
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

// Types

type IEventLocation = {
  city: string
  state: string
  country: string
  combinedLocation: string
}

export type IRegisteredUser = {
  horseIds: string[]
  memberId: string
  horseName: string
  memberName: string
  description: string
  memberAddress: string
  horseNameList: string[]
  memberProfilePicture: string | null
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
class EventService {
  eventId: string
  private registrations: TEventRegisteredUsers[] = []
  private publishedEvent: IEventReviewPublish | null = null

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  constructor(eventId: string) {
    if (!this.isValidEventId<string>(eventId))
      throw helpers.logger({
        message: CustomError.somethingWentWrong({
          devMessage: `eventId is ${eventId}`,
          ...CUSTOM_ERROR_PROPS,
        }),
      })

    this.eventId = eventId
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  private isValidEventId<T>(eventId: T): eventId is T {
    return !!eventId && typeof eventId === 'string'
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  private isPublishedEventNotEmpty<T>(publishedEvent: T): publishedEvent is T {
    if (!!publishedEvent) return true
    else throw new Error(`Publish event ${this.publishedEvent}`)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get getPublishedEvent(): Promise<IEventReviewPublish> {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.publishedEvent) resolve(this.publishedEvent)

        const publishedEventSnapShot = await FirestoreService.getItem(
          COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
          this.eventId
        )

        if (!publishedEventSnapShot.exists())
          throw new Error(`Publish event with id: ${this.eventId} doesn't exist`)

        this.publishedEvent =
          EventReviewPublishModel.fromFirestoreDoc(publishedEventSnapShot).toObject()
        this.isPublishedEventNotEmpty(this.publishedEvent)
        return resolve(this.publishedEvent)
      } catch (error: any) {
        helpers.logger({
          message: CustomError.somethingWentWrong({
            devMessage: error?.message ?? error,
            ...CUSTOM_ERROR_PROPS,
          }),
        })
        reject(error)
      }
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get getPublishedEventDetails(): Promise<IEventReviewPublish['EventDetails'] | null> {
    let eventDetails: IEventReviewPublish['EventDetails'] | null = null

    return new Promise(async (resolve) => {
      const publishedEvent = await this.getPublishedEvent
      eventDetails = getConvertedData(new EventDetailsModel(publishedEvent.EventDetails).toObject())
      resolve(eventDetails)
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get getEventOwnerDetails(): Promise<IUserInterface | null> {
    let user: IUserInterface | null = null

    return new Promise<IUserInterface | null>(async (resolve) => {
      try {
        const eventDetails = await this.getPublishedEventDetails
        const eventOwnerId = eventDetails?.owner

        const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
          eventDetails,
          eventOwnerId,
        })

        if (!eventDetails || !eventDetails?.owner)
          throw helpers.logger({
            message: CustomError.somethingWentWrong({
              devMessage: `${emptyVarName} is ${emptyVarValue}`,
              moduleName: 'getEventOwnerDetails',
              ...CUSTOM_ERROR_PROPS,
            }),
          })

        const userSnapShot = await UserService.getUserInfoById(eventOwnerId)

        if (!userSnapShot.exists())
          throw helpers.logger({
            message: CustomError.somethingWentWrong({
              devMessage: `user with id: ${eventOwnerId} does not exist`,
              moduleName: 'getEventOwnerDetails',
              ...CUSTOM_ERROR_PROPS,
            }),
          })

        user = UserModel.fromFirestoreDoc(userSnapShot).toObject()
      } catch (error: any) {
        helpers.logger({
          message: error,
        })
      } finally {
        resolve(user)
      }
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get getEventLocation(): Promise<IEventLocation> {
    let seperator = ''

    let eventLocation: IEventLocation = {
      city: '',
      state: '',
      country: '',
      combinedLocation: '',
    }

    return new Promise(async (resolve) => {
      const eventDetails = await this.getPublishedEventDetails

      if (!eventDetails) return ''

      eventLocation = {
        ...eventLocation,
        city: eventDetails.competitionCity ?? '',
        state: eventDetails.competitionState ?? '',
        country: eventDetails.competitionCountry ?? '',
      }

      if (!!eventLocation?.city) {
        eventLocation.combinedLocation += eventLocation.city
        seperator = ', '
      } else seperator = ''

      if (!!eventLocation?.state) {
        eventLocation.combinedLocation += seperator + eventLocation.state
        seperator = ', '
      } else seperator = ''

      if (!!eventLocation?.country) {
        eventLocation.combinedLocation += seperator + eventLocation.country
        seperator = ', '
      } else seperator = ''

      resolve(eventLocation)
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  getEventKey<K extends keyof IEventReviewPublish['EventDetails']>(
    keyToGet: K
  ): Promise<IEventReviewPublish['EventDetails'][K]> {
    let eventDetails: IEventReviewPublish['EventDetails'] | null = null

    return new Promise(async (resolve) => {
      eventDetails = await this.getPublishedEventKey('EventDetails')

      if (!eventDetails) return ''

      resolve(eventDetails[keyToGet])
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  getPublishedEventKey<K extends keyof IEventReviewPublish>(
    keyToGet: K
  ): Promise<IEventReviewPublish[K]> {
    let publishedEvent = this.publishedEvent

    return new Promise(async (resolve) => {
      publishedEvent = await this.getPublishedEvent
      this.isPublishedEventNotEmpty<IEventReviewPublish>(publishedEvent)
      resolve(publishedEvent[keyToGet])
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get getRegistrations(): Promise<TEventRegisteredUsers[]> {
    return new Promise(async (resolve) => {
      this.registrations = []

      const registrationSnaps = await FirestoreService.filterItems(
        COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
        [
          where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', this.eventId),
          where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.KEY, 'in', [
            COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PAID,
            COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PENDING,
          ]),
        ]
      )

      registrationSnaps.docs.forEach((currDoc) => {
        this.registrations.push(
          getConvertedData(EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject())
        )
      })

      resolve(this.registrations)
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get getRegisteredUsersListForModal(): Promise<IRegisteredUser[]> {
    return new Promise(async (resolve) => {
      let registrations: IRegisteredUser[] = []

      if (!this.registrations.length) this.registrations = await this.getRegistrations

      this.registrations.forEach((currRegistration) => {
        /**
         * Update - Now the person who haven't paid the amount that will also be considered as a registered person
         */
        // if (currRegistration.paymentStatus === "paid") {
        registrations.push({
          horseIds: [],
          horseName: '',
          description: '',
          horseNameList: [],
          memberAddress: '',
          memberId: currRegistration.userId!,
          memberName: currRegistration.userName ?? '',
          memberProfilePicture: currRegistration.userProfilePicture ?? null,
        })
        // }
      })

      resolve(registrations)
    })
  }
}

export default EventService
