export const AboutViewComponentDataKeyElement: React.FC<{
  logo: any
  title: string
  children: React.ReactNode
}> = ({ logo, title, children }) => {
  return (
    <div className="border border-[#D3DAEE] rounded-lg p-4 2xl:p-5 !w-[30%]">
      <div className="flex items-center gap-2 flex-wrap mb-2">
        <img src={logo} alt="logo" />
        <h6 className=" font-bold text-SeabiscuitDark200ThemeColor">{title}</h6>
      </div>
      {children}
    </div>
  )
}

export default AboutViewComponentDataKeyElement
