import { useEffect, useState } from 'react'
import { sumBy } from 'lodash'

// Component imports
import ConfirmationCompsWrapper from './ConfirmationCompsWrapper'

import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import {
  selectFeesTabDataR,
  selectPayTabGrandTotalAccordion,
  selectRegistrationByDay,
  updatePayTabGrandTotalAccordion,
} from '../../../../../../store/registration/registrationSlice'

import { IManageInfo } from '../../../../event-registration-tabs/EventRegistrationTabs'
import { IRegistrationByDayInterface } from '../../../../../../models/registrations-by-day/registrationByDay.interface'
import { IFeesTab } from '../../../../../../models/event-registered-users/event-registered-users.interface'

import { FEES_CATEGORY_CONST } from '../../../../../../components/events/views/details/EventDetailsViewComponentFees'
import { selectedEvent as selectedEventGetter } from '../../../../../../store/events/eventsSlice'

export type TGrandTotal = {
  total: number
  tickets: number
  stallFees: number
  registrationFees: number
  salesTax: number
}

const SelectedEventGrandTotal = ({
  toggle,
  splitPayment,
  manageInfo,
  isManage,
}: {
  toggle: string
  grandTotal: TGrandTotal
  splitPayment?: boolean
  manageInfo: IManageInfo
  isManage?: boolean
}) => {
  const dispatch = useAppDispatch()

  // Hooks and vars
  const [open, setOpen] = useState(false)
  const grandTotalAccordionData = useAppSelector(selectPayTabGrandTotalAccordion)
  const feesTabData = useAppSelector(selectFeesTabDataR)
  const registrationsByDay_ = useAppSelector(selectRegistrationByDay)
  const selectedEvent = useAppSelector(selectedEventGetter)

  useEffect(() => {
    if (splitPayment || !splitPayment) {
      setOpen(true)
    }
  }, [splitPayment])

  useEffect(() => {
    if (isManage) {
      const currentRegister: IRegistrationByDayInterface[] = []
      manageInfo.register.forEach((register) =>
        register.registrationsByDay.forEach((registrationsByDay) => {
          currentRegister.push(registrationsByDay)
        })
      )

      const ticketTotal = parseFloat(
        sumBy(
          manageInfo.tickets,
          (ticket) =>
            (ticket.registrationTicket?.selectedUnitsCount ?? 0) *
            (ticket.registrationTicket?.ticketPrice ?? 0)
        ).toFixed(2)
      )

      const feesTotal = parseFloat(
        sumBy(
          manageInfo.fees,
          (ticket) =>
            (ticket.registrationFees?.selectedUnitsCount ?? 0) *
            (ticket.registrationFees?.feesPrice ?? 0)
        ).toFixed(2)
      )

      let governanceFeesItems: IFeesTab[] = []
      feesTabData.forEach((currFeesTabRow) => {
        if (currFeesTabRow.feesCategory === FEES_CATEGORY_CONST.GOVERNANCE)
          governanceFeesItems.push(currFeesTabRow)
      })

      const horsesIds: string[] = []
      const registrationsByDayHorsesIds: string[] = []

      registrationsByDay_.forEach((registrationByDay) => {
        if (registrationByDay.horseId && !registrationByDay.isSratched) {
          registrationsByDayHorsesIds.push(registrationByDay.horseId)
        }
      })

      manageInfo.register.forEach((register) => {
        if (register.registrationsByDay)
          register.registrationsByDay.forEach((registrationsByDay) => {
            if (
              registrationsByDay.horseId &&
              !horsesIds.includes(registrationsByDay.horseId) &&
              !registrationsByDayHorsesIds.includes(registrationsByDay.horseId)
            )
              horsesIds.push(registrationsByDay.horseId)
          })
      })

      const governanceTotal = parseFloat(
        sumBy(governanceFeesItems, (fees) => horsesIds.length * (fees?.feesPrice ?? 0)).toFixed(2)
      )

      let qualifyFee = 0
      currentRegister.forEach((register) => {
        if (register.isQualify) qualifyFee += Number(register.qualifyFee)
      })

      const registrationTotal = sumBy(currentRegister, 'registrationPrice') + qualifyFee

      let totalPrice = ticketTotal + registrationTotal + feesTotal + governanceTotal
      const salesTax = totalPrice * (selectedEvent?.EventPaymentSettings?.chargeSalesTax ? 0.02 : 0)

      const newTotal = {
        ticketTotal,
        feesTotal,
        registrationTotal,
        governanceTotal,
        salesTax,
        newTotalPrice: parseFloat((totalPrice + salesTax).toFixed(2)),
      }
      dispatch(
        updatePayTabGrandTotalAccordion({
          ...grandTotalAccordionData,
          ...newTotal,
        })
      )
    }
  }, [isManage, manageInfo.fees, manageInfo.register, manageInfo.tickets])

  return (
    <ConfirmationCompsWrapper
      title="Grand Total"
      accordion={{ open, setOpen }}
      disableEditBtn={true}
      toggleAccordion={toggle}
      grandTotal={
        isManage ? grandTotalAccordionData?.newTotalPrice : grandTotalAccordionData?.totalPrice
      }
      selected_units={`$${grandTotalAccordionData?.totalItemsCount}`}
      showList={!!grandTotalAccordionData?.totalPriceWithoutSalesTax}
      colsClassName={{
        0: '!w-3/4  flex items-center',
        1: '!w-1/4  flex items-center',
      }}
      cols={[
        { title: '', tooltipText: '' },
        { title: '', tooltipText: '' },
      ]}
      hideToggleIcon={false}
    >
      <div className="flex items-start justify-start flex-col w-full mb-2">
        {isManage && (
          <div className="flex items-start justify-start w-full mb-2">
            <p
              className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
            >
              <span>Previous Payment</span>
            </p>
            <p
              className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center`}
            >
              <span>${grandTotalAccordionData?.totalPrice}</span>
            </p>
          </div>
        )}
        <div className="flex items-start justify-start w-full mb-2">
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-4/6 lg:w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
          >
            <span>{isManage ? 'New' : ''} Registrations</span>
          </p>
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-2/6 lg:w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center`}
          >
            <span>${grandTotalAccordionData?.registrationTotal}</span>
          </p>
        </div>

        {/* Fees total */}
        <div className="flex items-start justify-start w-full mb-2">
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-4/6 lg:w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
          >
            <span>{isManage ? 'New' : ''} Fees</span>
          </p>

          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-2/6 lg:w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center`}
          >
            <span>${grandTotalAccordionData?.feesTotal}</span>
          </p>
        </div>

        {/* governanceTotal total */}
        <div className="flex items-start justify-start w-full mb-2">
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-4/6 lg:w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
          >
            <span>{isManage ? 'New' : ''} Mandatory Fees</span>
          </p>

          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-2/6 lg:w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center`}
          >
            <span>${grandTotalAccordionData?.governanceTotal}</span>
          </p>
        </div>

        {/* Tickets total */}
        <div className="flex items-start justify-start w-full mb-2">
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-4/6 lg:w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
          >
            <span>{isManage ? 'New' : ''} Tickets</span>
          </p>
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-2/6 lg:w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center`}
          >
            <span>${grandTotalAccordionData?.ticketTotal}</span>
          </p>
        </div>

        {/* Sales Taxs */}
        <div className="flex items-start justify-start w-full mb-2">
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-4/6 lg:w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize`}
          >
            <span>{isManage ? 'New' : ''} Sales Tax</span>
          </p>{' '}
          <p
            className={`text-SeabiscuitDark200ThemeColor border-solid border border-SeabiscuitGrayThemeColor w-2/6 lg:w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGrayThemeColor capitalize text-center`}
          >
            <span>${grandTotalAccordionData?.salesTax?.toFixed(2)}</span>
          </p>
        </div>

        {/* Great grand total */}

        <div className="flex items-start justify-start w-full mb-2 font-semibold">
          <p
            className={`text-white border-solid border border-SeabiscuitGrayThemeColor w-4/6 lg:w-3/4 p-4 mr-2 rounded-lg bg-SeabiscuitGreenThemeColor capitalize`}
          >
            <span>{isManage ? 'New' : ''} Grand total</span>
          </p>{' '}
          <p
            className={`text-white border-solid border border-SeabiscuitGrayThemeColor w-2/6 lg:w-1/4 p-4 mr-2 rounded-lg bg-SeabiscuitGreenThemeColor capitalize text-center`}
          >
            {isManage ? (
              <span className={'whitespace-nowrap'}>
                ${grandTotalAccordionData?.newTotalPrice}
              </span>
            ) : (
              <span className={'whitespace-nowrap'}>${grandTotalAccordionData?.totalPrice}</span>
            )}
          </p>
        </div>
      </div>
    </ConfirmationCompsWrapper>
  )
}

export default SelectedEventGrandTotal
