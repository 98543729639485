import { IEventPaymentSettingsInterface } from '../../../../models/event-payment-settings/event-payment-settings.interface'

export const DATA_FIRESTORE_V01_EVENT_FEES = {
  NAME: 'v01_event_fees',
  FIELDS: {
    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },
    PAYMENT_OPTION: {
      KEY: 'paymentOption',
      VALUES: {
        /** @info `LeavePricesAsIs` - Leave prices as they are. Organizer will pay 5% fee. */
        LEAVE_PRICE_AS_IS: 'LeavePricesAsIs' as IEventPaymentSettingsInterface['paymentOption'],
        /** @info `ChargeRidersAndSpectators` - Charge riders & spectators 2.9% + 30c credit card processing fee. Organizer pays remaining 2.1%. */
        DEFAULT: 'ChargeRidersAndSpectators' as IEventPaymentSettingsInterface['paymentOption'],
        /** @info `IncreaseAllPrices` - Increase all prices by 5% to cover Pegasus’ fee.  */
        INCREASE_ALL_PRICES: 'IncreaseAllPrices' as IEventPaymentSettingsInterface['paymentOption'],
        /** @info `ChargeRidersAndSpectators` - Charge riders & spectators 2.9% + 30c credit card processing fee. Organizer pays remaining 2.1%. */
        CHARGE_RIDERS_AND_SPECTORORS:
          'ChargeRidersAndSpectators' as IEventPaymentSettingsInterface['paymentOption'],
      },
    },
  },
}
