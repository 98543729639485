import { CONST } from '../../../../../const/const'
import { ITeamMember } from '../../../../../models/users/user.interface'

import ProfileTeamTabCompetitorAuthorisedMeTab from './ProfileTeamTabCompetitorAuthorisedMeTab'
import ProfileTeamTabCompetitorAuthorisedTab from './ProfileTeamTabCompetitorAuthorisedTab'

const ProfileTeamTabCompetitorDisplayTabs = ({
  eventTab,
  handleModal,
  teamMembers,
  teamMembersLoading,
  loading,
}: {
  handleModal: any
  eventTab: string
  teamMembers: ITeamMember[]
  teamMembersLoading: boolean
  loading: boolean
}) => {
  // Funtions
  const selectTab = (selected_tab: string) => {
    switch (selected_tab) {
      case CONST.UI.USER.TEAM.TABS.AUTHORIZE.VALUE:
        return (
          <ProfileTeamTabCompetitorAuthorisedTab
            handleModal={handleModal}
            teamMembers={teamMembers}
            teamMembersLoading={teamMembersLoading}
            loading={loading}
          />
        )
      case CONST.UI.USER.TEAM.TABS.AUTHORIZED_ME.VALUE:
        return <ProfileTeamTabCompetitorAuthorisedMeTab handleModal={handleModal} />
    }
  }

  return <>{selectTab(eventTab as any)}</>
}

export default ProfileTeamTabCompetitorDisplayTabs
