import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { IFollowingInterface } from './following.interface'
import { CONST } from '../../const/const'

const FOLLOWING_COLLECTION = CONST.DATA.FIRESTORE.V01.COLLECTIONS.FOLLOWING

export class FollowingModel extends ModelBaseModel<IFollowingInterface> implements IModelBaseModel {
  // Vars
  public id: IFollowingInterface['id']

  public usedFor: IFollowingInterface['usedFor']
  public followerUserId: IFollowingInterface['followerUserId']
  public followingUserId: IFollowingInterface['followingUserId']
  public followingHorseId: IFollowingInterface['followingHorseId']
  public followerUserType: IFollowingInterface['followerUserType']
  public followingUserType: IFollowingInterface['followingUserType']
  public isFollowedBlocked: IFollowingInterface['isFollowedBlocked']

  public updatedAt: IFollowingInterface['updatedAt']
  public createdAt: IFollowingInterface['createdAt']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  public constructor(obj: IFollowingInterface) {
    super()
    this.followerUserType = obj.followerUserType ?? null
    this.id = obj.id ?? FOLLOWING_COLLECTION.FIELDS.ID.VALUES.NULL
    this.usedFor = obj.usedFor ?? FOLLOWING_COLLECTION.FIELDS.ID.VALUES.NULL
    this.followerUserId =
      obj.followerUserId ?? FOLLOWING_COLLECTION.FIELDS.FOLLOWER_USER_ID.VALUES.NULL
    this.isFollowedBlocked =
      obj.isFollowedBlocked ?? FOLLOWING_COLLECTION.FIELDS.IS_FOLLOWED_BLOCKED.VALUES.DEFAULT
    this.followingUserId =
      obj.followingUserId ?? FOLLOWING_COLLECTION.FIELDS.FOLLOWING_USER_ID.VALUES.NULL
    this.followingUserType = obj.usedFor === 'horse' ? null : obj.followingUserType ?? null
    this.followingHorseId =
      obj.usedFor === 'user'
        ? null
        : obj.followingHorseId ?? FOLLOWING_COLLECTION.FIELDS.FOLLOWING_HORSE_ID.VALUES.NULL

    this.createdAt = this.utcTimestamp({
      key: 'createdAt',
      isTimestamp: true,
      value: obj?.createdAt,
    })

    this.updatedAt = this.utcTimestamp({
      key: 'updatedAt',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.updatedAt,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new FollowingModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
