import React from 'react'
import { IconDropDown } from '../../../../../components/icons/IconDropDown'
import { toast } from 'react-toastify'
import isActive = toast.isActive

interface IProps {
  btnHandler?: () => void
  isActive?: boolean
  titleAdds?: string | JSX.Element | JSX.Element[]
  title: string
  centered?: boolean
}
export const TableName: React.FC<IProps> = ({
  title,
  btnHandler,
  titleAdds,
  centered,
  isActive,
}) => {
  return (
    <div
      className={`flex items-center gap-2 text-[#122B46] w-[100%] text-[12px] ${centered && 'justify-center'}`}
    >
      <span>
        {title} {titleAdds}
      </span>
      {btnHandler && (
        <button onClick={btnHandler}>
          <span className={`${isActive && 'rotate-180'}`}></span> <IconDropDown />
        </button>
      )}
    </div>
  )
}
