// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { IEventNearby } from './event-nearby.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import * as yup from 'yup'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export class EventNearbyModel extends ModelBaseModel<IEventNearby> implements IModelBaseModel {
  public v: number
  public id: string | null
  public owner: string | null

  public allowNearbyBusinessesToAdvertise: boolean
  public spotsAvailable: number
  public price: number
  public note: string

  public created: Date
  public modified: Date

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: IEventNearby) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? null

    this.allowNearbyBusinessesToAdvertise = obj?.allowNearbyBusinessesToAdvertise ?? false
    this.spotsAvailable = obj?.spotsAvailable ?? 0
    this.price = obj?.price ?? 0
    this.note = obj?.note ?? ''

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new EventNearbyModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  static validationSchema() {
    return yup.object().shape({})
  }
}
