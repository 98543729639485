/* eslint-disable react-hooks/exhaustive-deps */
// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { Fragment, useCallback, useState } from 'react'

import { Listbox, Transition } from '@headlessui/react'

import { ChevronDownIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import RedCheck from '../../../../../common/assets/invalid.svg'
import GreenCheck from '../../../../../common/assets/valid.svg'
import {
  postEvaluateCheckboxFieldChange,
  postEvaluateChoiceFieldChange,
  preEvaluateCheckboxFieldChange,
  preEvaluateChoiceFieldChange,
} from '../../../../evaluation/EventPrizeListFormBuilderUtilEvaluation'
import generateFieldValidation, {
  validateCheckboxFieldChange,
  validateChoiceFieldChange,
} from '../../../../validation/EventPrizeListFormBuilderUtilValidation'
import EventPrizeListFormBuilderUtilHunterStandardSubSectionRow from '../../../subsection/hunter/standard/EventPrizeListFormBuilderUtilHunterStandardSubSectionRow'
import EventPrizeListFormBuilderUtilHunterStandardSectionRowAccordionComponent from './EventPrizeListFormBuilderUtilHunterStandardSectionRowAccordionComponent'
import EventPrizeListFormBuilderUtilHunterStandardSectionRowAccordionDetailsComponent from './EventPrizeListFormBuilderUtilHunterStandardSectionRowAccordionDetailsComponent'
import EventPrizeListFormBuilderUtilHunterStandardSectionRowAccordionSummaryComponent from './EventPrizeListFormBuilderUtilHunterStandardSectionRowAccordionSummaryComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventPriceListFormBuilderUtilSectionRowProps {
  section: any
  division: any
  register: any
  disciplineIndex: number
  divisionIndex: number
  sectionIndex: number
  errors: any
  control: any
  setValue: any
  getValues: any
  setError: any
  clearErrors: any
  trigger: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormBuilderUtilHunterStandardSectionRow: React.FC<
  EventPriceListFormBuilderUtilSectionRowProps
> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleOnAccordionExpansionToggle = useCallback(
    (_: any) => {
      setIsExpanded((prevState) => !prevState)
    },
    [[]]
  )

  return (
    <>
      <EventPrizeListFormBuilderUtilHunterStandardSectionRowAccordionComponent
        onChange={handleOnAccordionExpansionToggle}
      >
        <EventPrizeListFormBuilderUtilHunterStandardSectionRowAccordionSummaryComponent
          isExpanded={isExpanded}
        >
          <div className="scroll-group w-full overflow-auto scrollbar-hide mb-3">
            <div className="grid grid-cols-[70px_260px_repeat(6,1fr)] gap-2 min-w-[1200px] w-full -ml-8">
              <div></div>
              {/*=================================*/}
              {/* Section Selection */}
              {/*=================================*/}
              <div>
                <Controller
                  name={`data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.meta.selected`}
                  control={props.control}
                  render={({ field: { value } }) => (
                    <div
                      className={clsx(
                        `text-[14px] w-full rounded-md flex items-center p-4 h-12`,
                        value
                          ? ` bg-SeabiscuitGrayThemeColor`
                          : `bg-white border-SeabiscuitGrayThemeColor border-2`
                      )}
                    >
                      {value ? (
                        <>
                          <div
                            onClick={() => {
                              const shouldChange = preEvaluateCheckboxFieldChange(
                                props.section.sectionSubCode,
                                props.section,
                                props.division,
                                value,
                                !value,
                                props.setValue,
                                props.getValues,
                                props.setError,
                                props.clearErrors,
                                props.trigger,
                                props.disciplineIndex,
                                props.divisionIndex,
                                props.sectionIndex
                              )
                              if (shouldChange) {
                                props.setValue(
                                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.meta.selected`,
                                  !value
                                )
                              }
                              validateCheckboxFieldChange(
                                props.section.sectionSubCode,
                                props.section,
                                props.division,
                                value,
                                !value,
                                props.setValue,
                                props.getValues,
                                props.setError,
                                props.clearErrors,
                                props.trigger,
                                props.disciplineIndex,
                                props.divisionIndex,
                                props.sectionIndex
                              )
                              postEvaluateCheckboxFieldChange(
                                props.section.sectionSubCode,
                                props.section,
                                props.division,
                                value,
                                !value,
                                props.setValue,
                                props.getValues,
                                props.setError,
                                props.clearErrors,
                                props.trigger,
                                props.disciplineIndex,
                                props.divisionIndex,
                                props.sectionIndex
                              )
                            }}
                            className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                          >
                            {props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.value`
                            )}
                          </div>
                          <img src={GreenCheck} alt="green check" />
                        </>
                      ) : (
                        <>
                          <div
                            onClick={() => {
                              const shouldChange = preEvaluateCheckboxFieldChange(
                                props.section.sectionSubCode,
                                props.section,
                                props.division,
                                value,
                                !value,
                                props.setValue,
                                props.getValues,
                                props.setError,
                                props.clearErrors,
                                props.trigger,
                                props.disciplineIndex,
                                props.divisionIndex,
                                props.sectionIndex
                              )
                              if (shouldChange) {
                                props.setValue(
                                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.meta.selected`,
                                  !value
                                )
                              }
                              validateCheckboxFieldChange(
                                props.section.sectionSubCode,
                                props.section,
                                props.division,
                                value,
                                !value,
                                props.setValue,
                                props.getValues,
                                props.setError,
                                props.clearErrors,
                                props.trigger,
                                props.disciplineIndex,
                                props.divisionIndex,
                                props.sectionIndex
                              )
                              postEvaluateCheckboxFieldChange(
                                props.section.sectionSubCode,
                                props.section,
                                props.division,
                                value,
                                !value,
                                props.setValue,
                                props.getValues,
                                props.setError,
                                props.clearErrors,
                                props.trigger,
                                props.disciplineIndex,
                                props.divisionIndex,
                                props.sectionIndex
                              )
                            }}
                            className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                          >
                            {props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.value`
                            )}
                          </div>
                          <input type="checkbox" checked={value} disabled />
                        </>
                      )}
                    </div>
                  )}
                />
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionSubCode?.value && (
                  <div className="text-red-500">
                    {
                      props.errors?.data?.items[props.disciplineIndex]?.divisions[
                        props.divisionIndex
                      ]?.sections[props.sectionIndex]?.sectionSubCode?.value.message
                    }
                  </div>
                )}
              </div>
              {/*=================================*/}
              {/* Under Saddle */}
              {/*=================================*/}
              <div>
                {props.getValues(
                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionUnderSaddleClass.meta.enabled`
                ) ? (
                  <div
                    className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Under Saddle Class'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionUnderSaddleClass.value`,
                        {
                          validate: generateFieldValidation(
                            props.section.sectionUnderSaddleClass,
                            props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionUnderSaddleClass.meta.enabled`
                            ),
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          ),
                        }
                      )}
                    />
                    {props.errors?.data?.items[props.disciplineIndex]?.divisions[
                      props.divisionIndex
                    ]?.sections[props.sectionIndex]?.sectionUnderSaddleClass?.value ? (
                      <img src={RedCheck} alt="red check" />
                    ) : (
                      <img src={GreenCheck} alt="green check" />
                    )}
                  </div>
                ) : (
                  <div
                    className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Under Saddle'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionUnderSaddleClass.value`
                      )}
                    />
                  </div>
                )}
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionUnderSaddleClass?.value && (
                  <div className="text-red-500">
                    {
                      props.errors?.data?.items[props.disciplineIndex]?.divisions[
                        props.divisionIndex
                      ]?.sections[props.sectionIndex]?.sectionUnderSaddleClass?.value.message
                    }
                  </div>
                )}
              </div>
              {/*=================================*/}
              {/* Handy */}
              {/*=================================*/}
              <div>
                {props.getValues(
                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHandyClass.meta.enabled`
                ) ? (
                  <div
                    className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Handy'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHandyClass.value`,
                        {
                          validate: generateFieldValidation(
                            props.section.sectionHandyClass,
                            props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHandyClass.meta.enabled`
                            ),
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          ),
                        }
                      )}
                    />
                    {props.errors?.data?.items[props.disciplineIndex]?.divisions[
                      props.divisionIndex
                    ]?.sections[props.sectionIndex]?.sectionHandyClass?.value ? (
                      <img src={RedCheck} alt="red check" />
                    ) : (
                      <img src={GreenCheck} alt="green check" />
                    )}
                  </div>
                ) : (
                  <div
                    className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Handy'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHandyClass.value`
                      )}
                    />
                  </div>
                )}
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionHandyClass?.value && (
                  <div className="text-red-500">
                    {
                      props.errors?.data?.items[props.disciplineIndex]?.divisions[
                        props.divisionIndex
                      ]?.sections[props.sectionIndex]?.sectionHandyClass?.value.message
                    }
                  </div>
                )}
              </div>
              {/*=================================*/}
              {/* Conformation */}
              {/*=================================*/}
              <div>
                {props.getValues(
                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionConformationClass.meta.enabled`
                ) ? (
                  <div
                    className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Conformation'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionConformationClass.value`,
                        {
                          validate: generateFieldValidation(
                            props.section.sectionConformationClass,
                            props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionConformationClass.meta.enabled`
                            ),
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          ),
                        }
                      )}
                    />
                    {props.errors?.data?.items[props.disciplineIndex]?.divisions[
                      props.divisionIndex
                    ]?.sections[props.sectionIndex]?.sectionConformationClass?.value ? (
                      <img src={RedCheck} alt="red check" />
                    ) : (
                      <img src={GreenCheck} alt="green check" />
                    )}
                  </div>
                ) : (
                  <div
                    className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Conformation'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionConformationClass.value`
                      )}
                    />
                  </div>
                )}
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionConformationClass?.value && (
                  <div className="text-red-500">
                    {
                      props.errors?.data?.items[props.disciplineIndex]?.divisions[
                        props.divisionIndex
                      ]?.sections[props.sectionIndex]?.sectionConformationClass?.value.message
                    }
                  </div>
                )}
              </div>
              {/*=================================*/}
              {/* Height */}
              {/*=================================*/}
              <div>
                <Controller
                  name={`data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHeight.meta.enabled`}
                  control={props.control}
                  render={({ field: { value } }) => (
                    <>
                      {value ? (
                        <Listbox
                          value={props.getValues(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHeight.meta.selected`
                          )}
                          multiple={props.getValues(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHeight.meta.multiple`
                          )}
                          onChange={(values: any) => {
                            const previousValue = props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHeight.meta.selected`
                            )

                            const shouldChange = preEvaluateChoiceFieldChange(
                              props.section.sectionHeight,
                              props.section,
                              props.division,
                              previousValue,
                              values,
                              props.setValue,
                              props.getValues,
                              props.setError,
                              props.clearErrors,
                              props.trigger,
                              props.disciplineIndex,
                              props.divisionIndex,
                              props.sectionIndex
                            )

                            if (shouldChange) {
                              if (
                                props.getValues(
                                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHeight.meta.multiple`
                                )
                              ) {
                                props.setValue(
                                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHeight.value`,
                                  values.join(',')
                                )
                              } else {
                                props.setValue(
                                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHeight.value`,
                                  values
                                )
                              }
                              props.setValue(
                                `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHeight.meta.selected`,
                                values
                              )
                            }

                            validateChoiceFieldChange(
                              props.section.sectionHeight,
                              props.section,
                              props.division,
                              previousValue,
                              values,
                              props.setValue,
                              props.getValues,
                              props.setError,
                              props.clearErrors,
                              props.trigger,
                              props.disciplineIndex,
                              props.divisionIndex,
                              props.sectionIndex
                            )

                            postEvaluateChoiceFieldChange(
                              props.section.sectionHeight,
                              props.section,
                              props.division,
                              previousValue,
                              values,
                              props.setValue,
                              props.getValues,
                              props.setError,
                              props.clearErrors,
                              props.trigger,
                              props.disciplineIndex,
                              props.divisionIndex,
                              props.sectionIndex
                            )
                          }}
                        >
                          <Listbox.Button
                            className={clsx(
                              `text-[14px] w-full rounded-md flex items-center p-4 h-12`,
                              value
                                ? ` bg-SeabiscuitGrayThemeColor`
                                : `bg-white border-SeabiscuitGrayThemeColor border-2`
                            )}
                          >
                            <div className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer flex items-between">
                              {props.getValues(
                                `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHeight.value`
                              )}
                            </div>
                            <ChevronDownIcon
                              className={`text-[14px] w-full rounded-md flex items-center p-4 h-16`}
                            />
                            {props.errors?.data?.items[props.disciplineIndex]?.divisions[
                              props.divisionIndex
                            ]?.sections[props.sectionIndex]?.sectionHeight?.value ? (
                              <img src={RedCheck} alt="red check" />
                            ) : (
                              <img src={GreenCheck} alt="green check" />
                            )}
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {props
                                .getValues(
                                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHeight.meta.options`
                                )
                                .map((item: any) => (
                                  <Listbox.Option
                                    key={item}
                                    value={item}
                                    className="text-[14px] w-full rounded-md flex items-center p-4 h-12 bg-SeabiscuitGrayThemeColor"
                                  >
                                    {item}
                                    <>
                                      {props
                                        .getValues(
                                          `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHeight.meta.selected`
                                        )
                                        .includes(item) && (
                                        <img src={GreenCheck} alt="green check" />
                                      )}
                                    </>
                                  </Listbox.Option>
                                ))}
                            </Listbox.Options>
                          </Transition>
                        </Listbox>
                      ) : (
                        <div
                          className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
                        >
                          <input
                            type="text"
                            className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                            placeholder={'Height'}
                            disabled={true}
                            {...props.register(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionHeight.value`
                            )}
                          />
                        </div>
                      )}
                    </>
                  )}
                />
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionHeight?.value && (
                  <div className="text-red-500">
                    {
                      props.errors?.data?.items[props.disciplineIndex]?.divisions[
                        props.divisionIndex
                      ]?.sections[props.sectionIndex]?.sectionHeight?.value.message
                    }
                  </div>
                )}
              </div>
              {/*=================================*/}
              {/* Price Money */}
              {/*=================================*/}
              <div>
                {props.getValues(
                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.meta.enabled`
                ) ? (
                  <div
                    className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Total Price Money'}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.value`,
                        {
                          validate: generateFieldValidation(
                            props.section.sectionTotalPriceMoney,
                            props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.meta.enabled`
                            ),
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          ),
                        }
                      )}
                    />
                    {props.errors?.data?.items[props.disciplineIndex]?.divisions[
                      props.divisionIndex
                    ]?.sections[props.sectionIndex]?.sectionTotalPriceMoney?.value ? (
                      <img src={RedCheck} alt="red check" />
                    ) : (
                      <img src={GreenCheck} alt="green check" />
                    )}
                  </div>
                ) : (
                  <div
                    className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Price Money'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.value`,
                        {
                          validate: generateFieldValidation(
                            props.section.sectionTotalPriceMoney,
                            props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.meta.enabled`
                            ),
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          ),
                        }
                      )}
                    />
                  </div>
                )}
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionTotalPriceMoney?.value && (
                  <div className="text-red-500">
                    {
                      props.errors?.data?.items[props.disciplineIndex]?.divisions[
                        props.divisionIndex
                      ]?.sections[props.sectionIndex]?.sectionTotalPriceMoney?.value.message
                    }
                  </div>
                )}
              </div>
              {/*=================================*/}
              {/* Judge */}
              {/*=================================*/}
              <div>
                {props.getValues(
                  `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionJudge.meta.enabled`
                ) ? (
                  <div
                    className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor  rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Judge'}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionJudge.value`,
                        {
                          validate: generateFieldValidation(
                            props.section.sectionJudge,
                            props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionJudge.meta.enabled`
                            ),
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          ),
                        }
                      )}
                    />
                    {props.errors?.data?.items[props.disciplineIndex]?.divisions[
                      props.divisionIndex
                    ]?.sections[props.sectionIndex]?.sectionJudge?.value ? (
                      <img src={RedCheck} alt="red check" />
                    ) : (
                      <img src={GreenCheck} alt="green check" />
                    )}
                  </div>
                ) : (
                  <div
                    className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
                  >
                    <input
                      type="text"
                      className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                      placeholder={'Judge'}
                      disabled={true}
                      {...props.register(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionJudge.value`
                      )}
                    />
                  </div>
                )}
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionJudge?.value && (
                  <div className="text-red-500">
                    {
                      props.errors?.data?.items[props.disciplineIndex]?.divisions[
                        props.divisionIndex
                      ]?.sections[props.sectionIndex]?.sectionJudge?.value.message
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        </EventPrizeListFormBuilderUtilHunterStandardSectionRowAccordionSummaryComponent>
        <EventPrizeListFormBuilderUtilHunterStandardSectionRowAccordionDetailsComponent>
          {props.section.subSections.map((subSection: any, index: number) => (
            <EventPrizeListFormBuilderUtilHunterStandardSubSectionRow
              key={subSection.subSectionTitle}
              subSection={subSection}
              section={props.section}
              register={props.register}
              disciplineIndex={props.disciplineIndex}
              divisionIndex={props.divisionIndex}
              sectionIndex={props.sectionIndex}
              subSectionIndex={index}
              errors={props.errors}
              control={props.control}
              getValues={props.getValues}
              setValue={props.setValue}
              setError={props.setError}
              clearErrors={props.clearErrors}
              trigger={props.trigger}
            />
          ))}
        </EventPrizeListFormBuilderUtilHunterStandardSectionRowAccordionDetailsComponent>
      </EventPrizeListFormBuilderUtilHunterStandardSectionRowAccordionComponent>
    </>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
EventPrizeListFormBuilderUtilHunterStandardSectionRow.defaultProps = {
  section: null,
  register: null,
}

export default EventPrizeListFormBuilderUtilHunterStandardSectionRow
