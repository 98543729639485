// import USDFImg from 'assets'
export interface IField {
  title: string
  value: string
  img?: string
}

const IMG_PATH = '/assets/images/logos/'

const REQUERED_NUMBERS: IField[] = [
  {
    title: 'USDF number',
    value: 'USDF',
    img: IMG_PATH + 'usdf-logo.png',
  },
  {
    title: 'USEA number',
    value: 'USEA',
    img: IMG_PATH + 'usea-logo.png',
  },
  {
    title: 'USHJA number',
    value: 'USHJA',
    img: IMG_PATH + 'USHJA-logo.png',
  },
  {
    title: 'AHHS number',
    value: 'AHHS',
    img: IMG_PATH + 'AHHS-logo.png',
  },
  {
    title: 'AMHA number',
    value: 'AMHA',
    img: IMG_PATH + 'AMHA-logo.png',
  },
  {
    title: 'ARHPA number',
    value: 'ARHPA',
    img: IMG_PATH + 'ARHPA-logo.png',
  },
  {
    title: 'ASHA number',
    value: 'ASHA',
    img: IMG_PATH + 'AHSHA-logo.png',
  },
  {
    title: 'FEI number',
    value: 'FEI',
    img: IMG_PATH + 'FEI-logo.png',
  },
  {
    title: 'NSBA number',
    value: 'NSBA',
    img: IMG_PATH + 'NSBA-logo.png',
  },
  {
    title: 'UPHA number',
    value: 'UPHA',
    img: IMG_PATH + 'UPHA-logo.png',
  },
  {
    title: 'USEF number',
    value: 'USEF',
    img: IMG_PATH + 'USEF-logo.png',
  },
  {
    title: 'WDAA number',
    value: 'WDAA',
    img: IMG_PATH + 'WDAA-logo.png',
  },
]

interface IRField {
  people: IField[]
  peopleNumbers: IField[]
  horseMain: IField[]
  horseSecondary: IField[]
  horseNumbers: IField[]
}

function capitalizeFirstLetter(str: string): string {
  const lowerCaseStr = str.toLowerCase()
  return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1)
}
export const REQUIERED_FILEDS: IRField = {
  people: [
    {
      title: 'Nationality',
      value: 'userNationality',
    },
    {
      title: 'Phone number',
      value: 'userPhoneNumber',
    },
    {
      title: 'Discipline',
      value: 'userDiscipline',
    },
    {
      title: 'Zone, Region, Area',
      value: 'userZone',
    },
    {
      title: 'Amateur Pro Status',
      value: 'userAmateur',
    },
  ],

  peopleNumbers: REQUERED_NUMBERS.map((itm) => {
    return { ...itm, value: 'user' + itm.value }
  }),

  horseMain: [
    {
      title: 'Breed',
      value: 'horseBreed',
    },
    {
      title: 'Height',
      value: 'horseHeight',
    },
    {
      title: 'Gender',
      value: 'horseGender',
    },
    {
      title: 'Date of Birth',
      value: 'horseDob',
    },
    {
      title: 'Discipline',
      value: 'horseDiscipline',
    },
    {
      title: 'Zone, Region, Area',
      value: 'horseZone',
    },
    {
      title: 'Coloring',
      value: 'horseColor',
    },
    // {
    //   title: 'Markings',
    //   value: 'horseMarking',
    // },
    {
      title: 'Country of Origin',
      value: 'horseCountry',
    },
    {
      title: 'Microchip number',
      value: 'horseMicrochipNumber',
    },
    {
      title: 'DNA case number',
      value: 'horseDNACaseNumber',
    },
    {
      title: 'Passport number',
      value: 'horsePassportNumber',
    },
  ],

  horseSecondary: [
    {
      title: 'Measurement card',
      value: 'horseMeasurementCard',
    },
    {
      title: 'Coggins',
      value: 'horseCoggins',
    },
    {
      title: 'Health certificate',
      value: 'horseHealthCertificate',
    },
    {
      title: 'Rhinopneumonitis',
      value: 'horseRhinopneumonitis',
    },
    {
      title: 'Influenza',
      value: 'horseInfluenza',
    },
  ],

  horseNumbers: REQUERED_NUMBERS.map((itm) => {
    return { ...itm, value: 'horse' + capitalizeFirstLetter(itm.value) + 'Number' }
  }),
}
