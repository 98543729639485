import { createSlice } from '@reduxjs/toolkit'
import { IMyEvent } from '../../models/event-registered-users/event-registered-users.interface'

const INITIAL_STATE: IMyEvent[] = []

const draftEventsSlice = createSlice({
  name: 'draft-events',
  initialState: INITIAL_STATE,
  reducers: {
    setEvents: (_, action) => action.payload,
  },
})

export const draftEventsActions = draftEventsSlice.actions
export default draftEventsSlice.reducer
