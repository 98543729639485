import TimeLib from '../../../../lib/Time'

export const DATA_FIRESTORE_V01_REFUND_REQUESTS = {
  NAME: 'v01_refund_requests',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_ID: {
      KEY: 'userId',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_DOC_ID: {
      KEY: 'recipientDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_USER_ID: {
      KEY: 'recipientUserId',
      VALUES: {
        DEFAULT: null,
      },
    },

    REFUND_STATUS: {
      KEY: 'refundStatus',
      VALUES: {
        DEFAULT: null,
      },
    },

    CARD_NUMBER: {
      KEY: 'cardNo',
      VALUES: {
        DEFAULT: null,
      },
    },

    REGISTRATION_DATE: {
      KEY: 'registrationDate',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_PROFILE_PICTURE: {
      KEY: 'recipientProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    INVOICE_ID: {
      KEY: 'invoiceId',
      VALUES: {
        DEFAULT: null,
      },
    },

    REFUND_DOC_ID: {
      KEY: 'refundDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    REFUND_ID: {
      KEY: 'refundId',
      VALUES: {
        DEFAULT: null,
      },
    },

    INVOICE_URL: {
      KEY: 'invoiceUrl',
      VALUES: {
        DEFAULT: null,
      },
    },

    REGISTRATION_DOC_ID: {
      KEY: 'registrationDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_NAME: {
      KEY: 'recipientName',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_NAME_N_GRAMS: {
      KEY: 'recipientNameNGram',
      VALUES: {
        DEFAULT: null,
      },
    },

    RECIPIENT_NAME_N_GRAM: {
      KEY: 'recipientNameNGram',
      VALUES: {
        DEFAULT: [],
      },
    },

    IS_PAID_BY_OWNER: {
      KEY: 'isPaidByOwner',
      VALUES: {
        YES: true,
        NO: false,
        DEFAULT: true,
      },
    },

    EVENT_ID: {
      KEY: 'eventId',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_NAME: {
      KEY: 'eventName',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_LOGO: {
      KEY: 'eventLogo',
      VALUES: {
        DEFAULT: null,
      },
    },

    AMOUNT_REFUNDED: {
      KEY: 'amountRefunded',
      VALUES: {
        DEFAULT: 0,
      },
    },

    AMOUNT_REQUESTED: {
      KEY: 'amountRequested',
      VALUES: {
        DEFAULT: 0,
      },
    },

    AMOUNT_PAID: {
      KEY: 'amountPaid',
      VALUES: {
        DEFAULT: 0,
      },
    },

    REFUND_REQUESTED_FOR: {
      KEY: 'refundRequestedFor',
      VALUES: {
        DEFAULT: {
          registrationFeesDocIds: [],
          registrationByDayDocIds: [],
          registrationTicketDocIds: [],
        },
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    UPDATED: {
      KEY: 'modified',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    UPDATE: {
      KEY: 'u',
      VALUES: {
        DEFAULT: false,
      },
    },

    REFUNDED_AT: {
      KEY: 'refundedAt',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
