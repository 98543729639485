import { CONST } from '../../../../../../const/const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//  Hunter class B
export const EPL_FB_JUMPER_C = {
  vMain: 1,
  vSub: 0,
  vFix: 0,
  disciplineType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DISCIPLINE.TYPE.STANDARD,
  discipline: 'Jumper CLASS C',
  validationStatus: 'unknown',
  divisionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.DIVISION.TYPE.STANDARD,
  divisionItemsRules: null,
  divisions: [
    // ************************************************************
    //  <JUMPER C> CODEID - CODETITLE
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '1938',
      divisionTitle: 'Pony Hunter Breeding',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.HUNTER_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // FILL
  ],
}
