// Third party
import { SingleValue } from 'react-select'

// Styles
import { customStyles } from '../../../../../../components/customStyles/ReactSelectCustomStyle'

// Types
import { useEffect, useState } from 'react'
import CustomReactSelect from '../../../../../../components/common/select/react-select/CustomReactSelect'
import { IRegistrationByDayInterface } from '../../../../../../models/registrations-by-day/registrationByDay.interface'
import { IRecipient, ITeamMember } from '../../../../../../models/users/user.interface'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectRegistrationByDay } from '../../../../../../store/registration/registrationSlice'
import { IHandlePaymentDivided } from '../../../../competitorEventRegister/Tabs/CompetitorEventRegisterPayTab'
import { selectUserId } from '../../../../../../store/user/userSlice'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types

type IProps = {
  id?: string
  className?: string
  memberIndex?: number
  docId?: string | null
  isLastIndex?: boolean
  recipient?: IRecipient
  itemIndexInList?: number
  membersAddedInRegistration: ITeamMember[]
  handlePaymentDivided?: IHandlePaymentDivided
  recipientId?: IRegistrationByDayInterface['recipientId']
  handleRecipientChange?: (args: IHandleRecipientChangeArgs) => void
}

type IHandleRecipientChangeArgs = {
  memberId: string | null
  memberIndex?: number | null
  itemIndexInList?: number | null
  /** @info Id of the item that is holding the select list */
  docId: string | null
  /** @info Id of the doc containing selected member */
  selectedDocId: IRegistrationByDayInterface['id']
}

type IHandleMemberChange = (
  value: SingleValue<{
    label: string
    value: string
    /** @info Id of the item that is holding the select list */
    docId: IRegistrationByDayInterface['id']
    /** @info Id of the doc containing selected member */
    selectedDocId: IRegistrationByDayInterface['id']
  }>
) => void

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

/**
 *
 * @param props IProps
 * @returns Jsx
 */
const SelectRecipient = (props: IProps) => {
  // Hooks and vars
  const userId = useAppSelector(selectUserId)
  const registrationsByDay_ = useAppSelector(selectRegistrationByDay)

  const [isOpen, setIsOpen] = useState(false)
  const [registrationsByDay, setRegistrationsByDay] = useState<IRegistrationByDayInterface[]>([])

  let targetId: string | null = props.id ?? null

  if (targetId && !props.isLastIndex) targetId = null

  useEffect(() => {
    let found = false
    let alreadyAddedMemberIds: string[] = []

    const registrationsByDayUpdated = registrationsByDay_.reduce(
      (acc, currRegistrationByDayInDb) => {
        if (!currRegistrationByDayInDb.id) return acc

        if (currRegistrationByDayInDb.riderId === userId) return acc

        found = !!alreadyAddedMemberIds.find(
          (cid) => cid === currRegistrationByDayInDb.riderId ?? ''
        )

        if (!found && currRegistrationByDayInDb.riderId) {
          alreadyAddedMemberIds.push(currRegistrationByDayInDb.riderId)
          acc.push(currRegistrationByDayInDb)
        }

        return acc
      },
      [] as IRegistrationByDayInterface[]
    )

    setRegistrationsByDay(registrationsByDayUpdated)
  }, [registrationsByDay_, userId])

  // Functions
  const handleMemberChange: IHandleMemberChange = (keyValuePair) => {
    props.handleRecipientChange?.({
      docId: props.docId ?? null,
      memberIndex: props.memberIndex ?? null,
      selectedDocId: keyValuePair?.selectedDocId,
      memberId: keyValuePair?.selectedDocId ?? null,
      itemIndexInList: props.itemIndexInList ?? null,
    })
  }

  const getSelectedRecipient = () => {
    if (props.recipientId === userId) return ''

    let foundMember = registrationsByDay_.find((currMember) => {
      return currMember.riderId === props.recipientId
    })

    if (!foundMember) return ''

    return {
      label: foundMember?.riderName ?? '',
      value: foundMember?.riderId ?? '',
    }
  }

  let selectedRecipient = getSelectedRecipient() as any

  return (
    <div className="relative w-1/4">
      <img
        src={'/assets/og_icons/Bill-1.svg'}
        className="mr-2 z-[1] avatarImg23 absolute top-3 left-2 rounded-full object-cover"
        alt="horse"
      />
      <CustomReactSelect
        value={(() => {
          return selectedRecipient
        })()}
        {...(targetId && {
          targetId,
        })}
        onChange={(newValue) => handleMemberChange(newValue as any)}
        options={
          registrationsByDay.map((currRegistrationByDay) => ({
            docId: props.docId,
            value: currRegistrationByDay.id,
            selectedDocId: currRegistrationByDay.id,
            label: currRegistrationByDay.riderName ?? 'Unknown',
          })) as any
        }
        isSearchable={true}
        incrementBy={60}
        // menuIsOpen={true}
        styles={{
          ...customStyles,
          valueContainer: (provided: any) => ({
            ...provided,
            textAlign: 'center',
            justifyContent: 'center',
            display: 'grid',
            textTransform: 'capitalize',
            margin: '0px 25px',
          }),
          control: (styles: any, state: any) => ({
            ...customStyles['control']?.(styles, state),
            height: '100%',
            ...(typeof selectedRecipient === 'object' && {
              backgroundColor: '#F6F7FB',
            }),
          }),
          menu: (provided: any, state: any) =>
            ({
              ...(typeof customStyles['menu'] === 'function' && {
                ...customStyles['menu'](provided, state),
                ...(isOpen && {
                  borderTop: 'none',
                }),
              }),
            }) as any,
          option: (provided: any, state: any) =>
            ({
              ...(typeof customStyles['option'] === 'function' && {
                ...customStyles['option'](provided, state),
                textAlign: 'center',
              }),
            }) as any,
          dropdownIndicator: (provided: any, state: any) => ({
            ...customStyles?.dropdownIndicator?.(provided, state),
            padding: 0,
            position: 'absolute',
            right: 5,
            pointerEvents: 'none',
            top: '50%',
            transform: 'translateY(-50%)',
          }),
          placeholder: (provided: any, state: any) => ({
            ...customStyles?.placeholder?.(provided, state),
            ...provided,
            color: '#122B46',
            fontSize: 14,
          }),
        }}
        // isClearable={true}
        placeholder="Select member..."
        getOptionLabel={(props: any) => {
          const { label } = props
          return (<span>{label}</span>) as unknown as string
        }}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        className={`border-SeabiscuitLightThemeColor border rounded-md searchableComponent focus:ring-0 p-0 h-[55px] focus:ring-transparent${props?.className ? ` ${props.className}` : ''}`}
      />
    </div>
  )
}

export default SelectRecipient
