import { IMAGE_CONSTS } from '../../../../const/image-const'
import { getUserFullName } from '../../../../helpers/helpers'
import { useAppSelector } from '../../../../store/hooks'
import { selectProfileData } from '../../../../store/user/userSlice'

const BussinessDetails = () => {
  const profileDetails = useAppSelector(selectProfileData)
  const displayName = getUserFullName(profileDetails)
  const profilePicture = !!profileDetails.userProfilePicture
    ? profileDetails.userProfilePicture
    : IMAGE_CONSTS.PLACEHOLDERS.USER
  const type = profileDetails?.userCategory ?? 'N/A'

  return (
    <div className="flex gap-2 items-center">
      <div className="overflow-hidden rounded-full w-[70px] h-[70px]">
        {
          <img
            src={profilePicture}
            onError={(e) => {
              return ((e.target as any).src = IMAGE_CONSTS.PLACEHOLDERS.USER)
            }}
            alt="profile"
            className="object-cover w-full h-full"
          />
        }
      </div>
      <div className="flex flex-col">
        <h1 className="text-SeabiscuitDark200ThemeColor font-bold text-[30px] 2xl:text-[40px] mr-3 2xl:mr-4 capitalize">
          {!!displayName ? displayName : 'Unknown'}
        </h1>
        {<p className="m-0 opacity-50 text-nr capitalize">{type}</p>}
      </div>
    </div>
  )
}

export default BussinessDetails
