/* eslint-disable jsx-a11y/anchor-is-valid */
// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import UserRegistrationViewComponentButton from './global/UserRegistrationViewComponentButton'
import { useContext, useState } from 'react'
import { SignupStages } from './UserRegistrationViewComponentContextApi'
import UserRegistrationViewComponentInput from './global/UserRegistrationViewComponentInput'

import backIcon from './assets/arrow-left.svg'
import USEFIcon from './assets/usef-icon.svg'
import eventOrgIcon from './assets/event-org.png'

interface UserRegistrationViewComponentCompetitorOrganizerProps {
  onCompetitorRegistrationUSEFNumberValid: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const UserRegistrationViewComponentCompetitorOrganizer: React.FC<
  UserRegistrationViewComponentCompetitorOrganizerProps
> = (props) => {
  const [stage, setStage] = useContext(SignupStages)
  const [USEF, setUSEF] = useState('')

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const stageHandle = (value: string) => {
    props.onCompetitorRegistrationUSEFNumberValid(USEF)
    setStage({ ...stage, stage: value })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div>
      <div className="text-[24px] flex items-center gap-2">
        <button onClick={() => stageHandle('Init')}>
          <img src={backIcon} alt="back" />
        </button>
        Competitor, etc.
      </div>
      <div className="mt-10">
        <img src={eventOrgIcon} alt="icon" className="rounded-full shadow-xl mx-auto" />
      </div>
      <div className="mt-[60px]">
        <p className="text-[18px] text-SeabiscuitDark200ThemeColor mt-4">Enter your USEF number</p>
        <UserRegistrationViewComponentInput
          type="text"
          value={USEF}
          onChange={(e: any) => setUSEF(e.target.value)}
          placeholder="USEF number"
          icon={USEFIcon}
        />
      </div>
      <div className="w-[70%] mt-[60px] mx-auto">
        <UserRegistrationViewComponentButton
          caption="SEARCH"
          type={1}
          disabled={!stage.selector}
          onClick={() => stageHandle('CompOrgVerify')}
        />
      </div>
      <div className="text-center text-[#777E85] mt-10">OR</div>
      <div className="text-[#120D26] my-10 text-center">
        Don’t have a USEF number?{' '}
        <a
          className="text-SeabiscuitMainThemeColor cursor-pointer"
          onClick={() => stageHandle('SignUp')}
        >
          Continue &gt;
        </a>
      </div>
    </div>
  )
}

UserRegistrationViewComponentCompetitorOrganizer.defaultProps = {
  onCompetitorRegistrationUSEFNumberValid: null,
}

export default UserRegistrationViewComponentCompetitorOrganizer
