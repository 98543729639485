export interface ICountryList {
  label: string
  code: string
  value: string
  light_icon?: string
  ccode?: string
}

export const CountryList: ICountryList[] = [
  {
    label: 'Afghanistan',
    code: '004',
    ccode: 'AF',
    value: 'AFG',
  },
  {
    label: 'Albania',
    code: '008',
    ccode: 'AL',
    value: 'ALB',
  },
  {
    label: 'Algeria',
    code: '012',
    ccode: 'DZ',
    value: 'DZA',
  },
  {
    label: 'American Samoa',
    code: '016',
    ccode: 'AS',
    value: 'ASM',
  },
  {
    label: 'Andorra',
    code: '020',
    ccode: 'AD',
    value: 'AND',
  },
  {
    label: 'Angola',
    code: '024',
    ccode: 'AO',
    value: 'AGO',
  },
  {
    label: 'Anguilla',
    code: '660',
    ccode: 'AI',
    value: 'AIA',
  },
  {
    label: 'Antarctica',
    code: '010',
    ccode: 'AQ',
    value: 'ATA',
  },
  {
    label: 'Antigua and Barbuda',
    code: '028',
    ccode: 'AG',
    value: 'ATG',
  },
  {
    label: 'Argentina',
    code: '032',
    ccode: 'AR',
    value: 'ARG',
  },
  {
    label: 'Armenia',
    code: '051',
    ccode: 'AM',
    value: 'ARM',
  },
  {
    label: 'Aruba',
    code: '533',
    ccode: 'AW',
    value: 'ABW',
  },
  {
    label: 'Australia',
    code: '036',
    ccode: 'AU',
    value: 'AUS',
  },
  {
    label: 'Austria',
    code: '040',
    ccode: 'AT',
    value: 'AUT',
  },
  {
    label: 'Azerbaijan',
    code: '031',
    ccode: 'AZ',
    value: 'AZE',
  },
  {
    label: 'Bahamas',
    code: '044',
    ccode: 'BS',
    value: 'BHS',
  },
  {
    label: 'Bahrain',
    code: '048',
    ccode: 'BH',
    value: 'BHR',
  },
  {
    label: 'Bangladesh',
    code: '050',
    ccode: 'BD',
    value: 'BGD',
  },
  {
    label: 'Barbados',
    code: '052',
    ccode: 'BB',
    value: 'BRB',
  },
  {
    label: 'Belarus',
    code: '112',
    ccode: 'BY',
    value: 'BLR',
  },
  {
    label: 'Belgium',
    code: '056',
    ccode: 'BE',
    value: 'BEL',
  },
  {
    label: 'Belize',
    code: '084',
    ccode: 'BZ',
    value: 'BLZ',
  },
  {
    label: 'Benin',
    code: '204',
    ccode: 'BJ',
    value: 'BEN',
  },
  {
    label: 'Bermuda',
    code: '060',
    ccode: 'BM',
    value: 'BMU',
  },
  {
    label: 'Bhutan',
    code: '064',
    ccode: 'BT',
    value: 'BTN',
  },
  {
    label: 'Bolivia',
    code: '068',
    ccode: 'BO',
    value: 'BOL',
  },
  {
    label: 'Bosnia and Herzegovina',
    code: '070',
    ccode: 'BA',
    value: 'BIH',
  },
  {
    label: 'Botswana',
    code: '072',
    ccode: 'BW',
    value: 'BWA',
  },
  {
    label: 'Bouvet Island',
    code: '074',
    ccode: 'BV',
    value: 'BVT',
  },
  {
    label: 'Brazil',
    code: '076',
    ccode: 'BR',
    value: 'BRA',
  },
  {
    label: 'British Indian Ocean Territory',
    code: '086',
    ccode: 'IO',
    value: 'IOT',
  },
  {
    label: 'British Virgin Islands',
    code: '092',
    ccode: 'VG',
    value: 'VGB',
  },
  {
    label: 'Brunei',
    code: '096',
    ccode: 'BN',
    value: 'BRN',
  },
  {
    label: 'Bulgaria',
    code: '100',
    ccode: 'BG',
    value: 'BGR',
  },
  {
    label: 'Burkina Faso',
    code: '854',
    ccode: 'BF',
    value: 'BFA',
  },
  {
    label: 'Burundi',
    code: '108',
    ccode: 'BI',
    value: 'BDI',
  },
  {
    label: 'Cambodia',
    code: '116',
    ccode: 'KH',
    value: 'KHM',
  },
  {
    label: 'Cameroon',
    code: '120',
    ccode: 'CM',
    value: 'CMR',
  },
  {
    label: 'Canada',
    code: '124',
    ccode: 'CA',
    value: 'CAN',
  },
  {
    label: 'Cape Verde',
    code: '132',
    ccode: 'CV',
    value: 'CPV',
  },
  {
    label: 'Caribbean Netherlands',
    code: '535',
    ccode: 'BQ',
    value: 'BES',
  },
  {
    label: 'Cayman Islands',
    code: '136',
    ccode: 'KY',
    value: 'CYM',
  },
  {
    label: 'Central African Republic',
    code: '140',
    ccode: 'CF',
    value: 'CAF',
  },
  {
    label: 'Chad',
    code: '148',
    ccode: 'TD',
    value: 'TCD',
  },
  {
    label: 'Chile',
    code: '152',
    ccode: 'CL',
    value: 'CHL',
  },
  {
    label: 'China',
    code: '156',
    ccode: 'CN',
    value: 'CHN',
  },
  {
    label: 'Christmas Island',
    code: '162',
    ccode: 'CX',
    value: 'CXR',
  },
  {
    label: 'Cocos (Keeling) Islands',
    code: '166',
    ccode: 'CC',
    value: 'CCK',
  },
  {
    label: 'Colombia',
    code: '170',
    ccode: 'CO',
    value: 'COL',
  },
  {
    label: 'Comoros',
    code: '174',
    ccode: 'KM',
    value: 'COM',
  },
  {
    label: 'Cook Islands',
    code: '184',
    ccode: 'CK',
    value: 'COK',
  },
  {
    label: 'Costa Rica',
    code: '188',
    ccode: 'CR',
    value: 'CRI',
  },
  {
    label: 'Croatia',
    code: '191',
    ccode: 'HR',
    value: 'HRV',
  },
  {
    label: 'Cuba',
    code: '192',
    ccode: 'CU',
    value: 'CUB',
  },
  {
    label: 'Curaçao',
    code: '531',
    ccode: 'CW',
    value: 'CUW',
  },
  {
    label: 'Cyprus',
    code: '196',
    ccode: 'CY',
    value: 'CYP',
  },
  {
    label: 'Czechia',
    code: '203',
    ccode: 'CZ',
    value: 'CZE',
  },
  {
    label: 'DR Congo',
    code: '180',
    ccode: 'CD',
    value: 'COD',
  },
  {
    label: 'Denmark',
    code: '208',
    ccode: 'DK',
    value: 'DNK',
  },
  {
    label: 'Djibouti',
    code: '262',
    ccode: 'DJ',
    value: 'DJI',
  },
  {
    label: 'Dominica',
    code: '212',
    ccode: 'DM',
    value: 'DMA',
  },
  {
    label: 'Dominican Republic',
    code: '214',
    ccode: 'DO',
    value: 'DOM',
  },
  {
    label: 'Ecuador',
    code: '218',
    ccode: 'EC',
    value: 'ECU',
  },
  {
    label: 'Egypt',
    code: '818',
    ccode: 'EG',
    value: 'EGY',
  },
  {
    label: 'El Salvador',
    code: '222',
    ccode: 'SV',
    value: 'SLV',
  },
  {
    label: 'Equatorial Guinea',
    code: '226',
    ccode: 'GQ',
    value: 'GNQ',
  },
  {
    label: 'Eritrea',
    code: '232',
    ccode: 'ER',
    value: 'ERI',
  },
  {
    label: 'Estonia',
    code: '233',
    ccode: 'EE',
    value: 'EST',
  },
  {
    label: 'Eswatini',
    code: '748',
    ccode: 'SZ',
    value: 'SWZ',
  },
  {
    label: 'Ethiopia',
    code: '231',
    ccode: 'ET',
    value: 'ETH',
  },
  {
    label: 'Falkland Islands',
    code: '238',
    ccode: 'FK',
    value: 'FLK',
  },
  {
    label: 'Faroe Islands',
    code: '234',
    ccode: 'FO',
    value: 'FRO',
  },
  {
    label: 'Fiji',
    code: '242',
    ccode: 'FJ',
    value: 'FJI',
  },
  {
    label: 'Finland',
    code: '246',
    ccode: 'FI',
    value: 'FIN',
  },
  {
    label: 'France',
    code: '250',
    ccode: 'FR',
    value: 'FRA',
  },
  {
    label: 'French Guiana',
    code: '254',
    ccode: 'GF',
    value: 'GUF',
  },
  {
    label: 'French Polynesia',
    code: '258',
    ccode: 'PF',
    value: 'PYF',
  },
  {
    label: 'French Southern and Antarctic Lands',
    code: '260',
    ccode: 'TF',
    value: 'ATF',
  },
  {
    label: 'Gabon',
    code: '266',
    ccode: 'GA',
    value: 'GAB',
  },
  {
    label: 'Gambia',
    code: '270',
    ccode: 'GM',
    value: 'GMB',
  },
  {
    label: 'Georgia',
    code: '268',
    ccode: 'GE',
    value: 'GEO',
  },
  {
    label: 'Germany',
    code: '276',
    ccode: 'DE',
    value: 'DEU',
  },
  {
    label: 'Ghana',
    code: '288',
    ccode: 'GH',
    value: 'GHA',
  },
  {
    label: 'Gibraltar',
    code: '292',
    ccode: 'GI',
    value: 'GIB',
  },
  {
    label: 'Greece',
    code: '300',
    ccode: 'GR',
    value: 'GRC',
  },
  {
    label: 'Greenland',
    code: '304',
    ccode: 'GL',
    value: 'GRL',
  },
  {
    label: 'Grenada',
    code: '308',
    ccode: 'GD',
    value: 'GRD',
  },
  {
    label: 'Guadeloupe',
    code: '312',
    ccode: 'GP',
    value: 'GLP',
  },
  {
    label: 'Guam',
    code: '316',
    ccode: 'GU',
    value: 'GUM',
  },
  {
    label: 'Guatemala',
    code: '320',
    ccode: 'GT',
    value: 'GTM',
  },
  {
    label: 'Guernsey',
    code: '831',
    ccode: 'GG',
    value: 'GGY',
  },
  {
    label: 'Guinea',
    code: '324',
    ccode: 'GN',
    value: 'GIN',
  },
  {
    label: 'Guinea-Bissau',
    code: '624',
    ccode: 'GW',
    value: 'GNB',
  },
  {
    label: 'Guyana',
    code: '328',
    ccode: 'GY',
    value: 'GUY',
  },
  {
    label: 'Haiti',
    code: '332',
    ccode: 'HT',
    value: 'HTI',
  },
  {
    label: 'Heard Island and McDonald Islands',
    code: '334',
    ccode: 'HM',
    value: 'HMD',
  },
  {
    label: 'Honduras',
    code: '340',
    ccode: 'HN',
    value: 'HND',
  },
  {
    label: 'Hong Kong',
    code: '344',
    ccode: 'HK',
    value: 'HKG',
  },
  {
    label: 'Hungary',
    code: '348',
    ccode: 'HU',
    value: 'HUN',
  },
  {
    label: 'Iceland',
    code: '352',
    ccode: 'IS',
    value: 'ISL',
  },
  {
    label: 'India',
    code: '356',
    ccode: 'IN',
    value: 'IND',
  },
  {
    label: 'Indonesia',
    code: '360',
    ccode: 'ID',
    value: 'IDN',
  },
  {
    label: 'Iran',
    code: '364',
    ccode: 'IR',
    value: 'IRN',
  },
  {
    label: 'Iraq',
    code: '368',
    ccode: 'IQ',
    value: 'IRQ',
  },
  {
    label: 'Ireland',
    code: '372',
    ccode: 'IE',
    value: 'IRL',
  },
  {
    label: 'Isle of Man',
    code: '833',
    ccode: 'IM',
    value: 'IMN',
  },
  {
    label: 'Israel',
    code: '376',
    ccode: 'IL',
    value: 'ISR',
  },
  {
    label: 'Italy',
    code: '380',
    ccode: 'IT',
    value: 'ITA',
  },
  {
    label: 'Ivory Coast',
    code: '384',
    ccode: 'CI',
    value: 'CIV',
  },
  {
    label: 'Jamaica',
    code: '388',
    ccode: 'JM',
    value: 'JAM',
  },
  {
    label: 'Japan',
    code: '392',
    ccode: 'JP',
    value: 'JPN',
  },
  {
    label: 'Jersey',
    code: '832',
    ccode: 'JE',
    value: 'JEY',
  },
  {
    label: 'Jordan',
    code: '400',
    ccode: 'JO',
    value: 'JOR',
  },
  {
    label: 'Kazakhstan',
    code: '398',
    ccode: 'KZ',
    value: 'KAZ',
  },
  {
    label: 'Kenya',
    code: '404',
    ccode: 'KE',
    value: 'KEN',
  },
  {
    label: 'Kiribati',
    code: '296',
    ccode: 'KI',
    value: 'KIR',
  },
  {
    label: 'Kosovo',
    code: '383',
    ccode: 'XK',
    value: 'UNK',
  },
  {
    label: 'Kuwait',
    code: '414',
    ccode: 'KW',
    value: 'KWT',
  },
  {
    label: 'Kyrgyzstan',
    code: '417',
    ccode: 'KG',
    value: 'KGZ',
  },
  {
    label: 'Laos',
    code: '418',
    ccode: 'LA',
    value: 'LAO',
  },
  {
    label: 'Latvia',
    code: '428',
    ccode: 'LV',
    value: 'LVA',
  },
  {
    label: 'Lebanon',
    code: '422',
    ccode: 'LB',
    value: 'LBN',
  },
  {
    label: 'Lesotho',
    code: '426',
    ccode: 'LS',
    value: 'LSO',
  },
  {
    label: 'Liberia',
    code: '430',
    ccode: 'LR',
    value: 'LBR',
  },
  {
    label: 'Libya',
    code: '434',
    ccode: 'LY',
    value: 'LBY',
  },
  {
    label: 'Liechtenstein',
    code: '438',
    ccode: 'LI',
    value: 'LIE',
  },
  {
    label: 'Lithuania',
    code: '440',
    ccode: 'LT',
    value: 'LTU',
  },
  {
    label: 'Luxembourg',
    code: '442',
    ccode: 'LU',
    value: 'LUX',
  },
  {
    label: 'Macau',
    code: '446',
    ccode: 'MO',
    value: 'MAC',
  },
  {
    label: 'Madagascar',
    code: '450',
    ccode: 'MG',
    value: 'MDG',
  },
  {
    label: 'Malawi',
    code: '454',
    ccode: 'MW',
    value: 'MWI',
  },
  {
    label: 'Malaysia',
    code: '458',
    ccode: 'MY',
    value: 'MYS',
  },
  {
    label: 'Maldives',
    code: '462',
    ccode: 'MV',
    value: 'MDV',
  },
  {
    label: 'Mali',
    code: '466',
    ccode: 'ML',
    value: 'MLI',
  },
  {
    label: 'Malta',
    code: '470',
    ccode: 'MT',
    value: 'MLT',
  },
  {
    label: 'Marshall Islands',
    code: '584',
    ccode: 'MH',
    value: 'MHL',
  },
  {
    label: 'Martinique',
    code: '474',
    ccode: 'MQ',
    value: 'MTQ',
  },
  {
    label: 'Mauritania',
    code: '478',
    ccode: 'MR',
    value: 'MRT',
  },
  {
    label: 'Mauritius',
    code: '480',
    ccode: 'MU',
    value: 'MUS',
  },
  {
    label: 'Mayotte',
    code: '175',
    ccode: 'YT',
    value: 'MYT',
  },
  {
    label: 'Mexico',
    code: '484',
    ccode: 'MX',
    value: 'MEX',
  },
  {
    label: 'Micronesia',
    code: '583',
    ccode: 'FM',
    value: 'FSM',
  },
  {
    label: 'Moldova',
    code: '498',
    ccode: 'MD',
    value: 'MDA',
  },
  {
    label: 'Monaco',
    code: '492',
    ccode: 'MC',
    value: 'MCO',
  },
  {
    label: 'Mongolia',
    code: '496',
    ccode: 'MN',
    value: 'MNG',
  },
  {
    label: 'Montenegro',
    code: '499',
    ccode: 'ME',
    value: 'MNE',
  },
  {
    label: 'Montserrat',
    code: '500',
    ccode: 'MS',
    value: 'MSR',
  },
  {
    label: 'Morocco',
    code: '504',
    ccode: 'MA',
    value: 'MAR',
  },
  {
    label: 'Mozambique',
    code: '508',
    ccode: 'MZ',
    value: 'MOZ',
  },
  {
    label: 'Myanmar',
    code: '104',
    ccode: 'MM',
    value: 'MMR',
  },
  {
    label: 'Namibia',
    code: '516',
    ccode: 'NA',
    value: 'NAM',
  },
  {
    label: 'Nauru',
    code: '520',
    ccode: 'NR',
    value: 'NRU',
  },
  {
    label: 'Nepal',
    code: '524',
    ccode: 'NP',
    value: 'NPL',
  },
  {
    label: 'Netherlands',
    code: '528',
    ccode: 'NL',
    value: 'NLD',
  },
  {
    label: 'New Caledonia',
    code: '540',
    ccode: 'NC',
    value: 'NCL',
  },
  {
    label: 'New Zealand',
    code: '554',
    ccode: 'NZ',
    value: 'NZL',
  },
  {
    label: 'Nicaragua',
    code: '558',
    ccode: 'NI',
    value: 'NIC',
  },
  {
    label: 'Niger',
    code: '562',
    ccode: 'NE',
    value: 'NER',
  },
  {
    label: 'Nigeria',
    code: '566',
    ccode: 'NG',
    value: 'NGA',
  },
  {
    label: 'Niue',
    code: '570',
    ccode: 'NU',
    value: 'NIU',
  },
  {
    label: 'Norfolk Island',
    code: '574',
    ccode: 'NF',
    value: 'NFK',
  },
  {
    label: 'North Korea',
    code: '408',
    ccode: 'KP',
    value: 'PRK',
  },
  {
    label: 'North Macedonia',
    code: '807',
    ccode: 'MK',
    value: 'MKD',
  },
  {
    label: 'Northern Mariana Islands',
    code: '580',
    ccode: 'MP',
    value: 'MNP',
  },
  {
    label: 'Norway',
    code: '578',
    ccode: 'NO',
    value: 'NOR',
  },
  {
    label: 'Oman',
    code: '512',
    ccode: 'OM',
    value: 'OMN',
  },
  {
    label: 'Pakistan',
    code: '586',
    ccode: 'PK',
    value: 'PAK',
  },
  {
    label: 'Palau',
    code: '585',
    ccode: 'PW',
    value: 'PLW',
  },
  {
    label: 'Palestine',
    code: '275',
    ccode: 'PS',
    value: 'PSE',
  },
  {
    label: 'Panama',
    code: '591',
    ccode: 'PA',
    value: 'PAN',
  },
  {
    label: 'Papua New Guinea',
    code: '598',
    ccode: 'PG',
    value: 'PNG',
  },
  {
    label: 'Paraguay',
    code: '600',
    ccode: 'PY',
    value: 'PRY',
  },
  {
    label: 'Peru',
    code: '604',
    ccode: 'PE',
    value: 'PER',
  },
  {
    label: 'Philippines',
    code: '608',
    ccode: 'PH',
    value: 'PHL',
  },
  {
    label: 'Pitcairn Islands',
    code: '612',
    ccode: 'PN',
    value: 'PCN',
  },
  {
    label: 'Poland',
    code: '616',
    ccode: 'PL',
    value: 'POL',
  },
  {
    label: 'Portugal',
    code: '620',
    ccode: 'PT',
    value: 'PRT',
  },
  {
    label: 'Puerto Rico',
    code: '630',
    ccode: 'PR',
    value: 'PRI',
  },
  {
    label: 'Qatar',
    code: '634',
    ccode: 'QA',
    value: 'QAT',
  },
  {
    label: 'Republic of the Congo',
    code: '178',
    ccode: 'CG',
    value: 'COG',
  },
  {
    label: 'Romania',
    code: '642',
    ccode: 'RO',
    value: 'ROU',
  },
  {
    label: 'Russia',
    code: '643',
    ccode: 'RU',
    value: 'RUS',
  },
  {
    label: 'Rwanda',
    code: '646',
    ccode: 'RW',
    value: 'RWA',
  },
  {
    label: 'Réunion',
    code: '638',
    ccode: 'RE',
    value: 'REU',
  },
  {
    label: 'Saint Barthélemy',
    code: '652',
    ccode: 'BL',
    value: 'BLM',
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    code: '654',
    ccode: 'SH',
    value: 'SHN',
  },
  {
    label: 'Saint Kitts and Nevis',
    code: '659',
    ccode: 'KN',
    value: 'KNA',
  },
  {
    label: 'Saint Lucia',
    code: '662',
    ccode: 'LC',
    value: 'LCA',
  },
  {
    label: 'Saint Martin',
    code: '663',
    ccode: 'MF',
    value: 'MAF',
  },
  {
    label: 'Saint Pierre and Miquelon',
    code: '666',
    ccode: 'PM',
    value: 'SPM',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    code: '670',
    ccode: 'VC',
    value: 'VCT',
  },
  {
    label: 'Samoa',
    code: '882',
    ccode: 'WS',
    value: 'WSM',
  },
  {
    label: 'San Marino',
    code: '674',
    ccode: 'SM',
    value: 'SMR',
  },
  {
    label: 'Saudi Arabia',
    code: '682',
    ccode: 'SA',
    value: 'SAU',
  },
  {
    label: 'Senegal',
    code: '686',
    ccode: 'SN',
    value: 'SEN',
  },
  {
    label: 'Serbia',
    code: '688',
    ccode: 'RS',
    value: 'SRB',
  },
  {
    label: 'Seychelles',
    code: '690',
    ccode: 'SC',
    value: 'SYC',
  },
  {
    label: 'Sierra Leone',
    code: '694',
    ccode: 'SL',
    value: 'SLE',
  },
  {
    label: 'Singapore',
    code: '702',
    ccode: 'SG',
    value: 'SGP',
  },
  {
    label: 'Sint Maarten',
    code: '534',
    ccode: 'SX',
    value: 'SXM',
  },
  {
    label: 'Slovakia',
    code: '703',
    ccode: 'SK',
    value: 'SVK',
  },
  {
    label: 'Slovenia',
    code: '705',
    ccode: 'SI',
    value: 'SVN',
  },
  {
    label: 'Solomon Islands',
    code: '090',
    ccode: 'SB',
    value: 'SLB',
  },
  {
    label: 'Somalia',
    code: '706',
    ccode: 'SO',
    value: 'SOM',
  },
  {
    label: 'South Africa',
    code: '710',
    ccode: 'ZA',
    value: 'ZAF',
  },
  {
    label: 'South Georgia',
    code: '239',
    ccode: 'GS',
    value: 'SGS',
  },
  {
    label: 'South Korea',
    code: '410',
    ccode: 'KR',
    value: 'KOR',
  },
  {
    label: 'South Sudan',
    code: '728',
    ccode: 'SS',
    value: 'SSD',
  },
  {
    label: 'Spain',
    code: '724',
    ccode: 'ES',
    value: 'ESP',
  },
  {
    label: 'Sri Lanka',
    code: '144',
    ccode: 'LK',
    value: 'LKA',
  },
  {
    label: 'Sudan',
    code: '729',
    ccode: 'SD',
    value: 'SDN',
  },
  {
    label: 'Suriname',
    code: '740',
    ccode: 'SR',
    value: 'SUR',
  },
  {
    label: 'Svalbard and Jan Mayen',
    code: '744',
    ccode: 'SJ',
    value: 'SJM',
  },
  {
    label: 'Sweden',
    code: '752',
    ccode: 'SE',
    value: 'SWE',
  },
  {
    label: 'Switzerland',
    code: '756',
    ccode: 'CH',
    value: 'CHE',
  },
  {
    label: 'Syria',
    code: '760',
    ccode: 'SY',
    value: 'SYR',
  },
  {
    label: 'São Tomé and Príncipe',
    code: '678',
    ccode: 'ST',
    value: 'STP',
  },
  {
    label: 'Taiwan',
    code: '158',
    ccode: 'TW',
    value: 'TWN',
  },
  {
    label: 'Tajikistan',
    code: '762',
    ccode: 'TJ',
    value: 'TJK',
  },
  {
    label: 'Tanzania',
    code: '834',
    ccode: 'TZ',
    value: 'TZA',
  },
  {
    label: 'Thailand',
    code: '764',
    ccode: 'TH',
    value: 'THA',
  },
  {
    label: 'Timor-Leste',
    code: '626',
    ccode: 'TL',
    value: 'TLS',
  },
  {
    label: 'Togo',
    code: '768',
    ccode: 'TG',
    value: 'TGO',
  },
  {
    label: 'Tokelau',
    code: '772',
    ccode: 'TK',
    value: 'TKL',
  },
  {
    label: 'Tonga',
    code: '776',
    ccode: 'TO',
    value: 'TON',
  },
  {
    label: 'Trinidad and Tobago',
    code: '780',
    ccode: 'TT',
    value: 'TTO',
  },
  {
    label: 'Tunisia',
    code: '788',
    ccode: 'TN',
    value: 'TUN',
  },
  {
    label: 'Turkey',
    code: '792',
    ccode: 'TR',
    value: 'TUR',
  },
  {
    label: 'Turkmenistan',
    code: '795',
    ccode: 'TM',
    value: 'TKM',
  },
  {
    label: 'Turks and Caicos Islands',
    code: '796',
    ccode: 'TC',
    value: 'TCA',
  },
  {
    label: 'Tuvalu',
    code: '798',
    ccode: 'TV',
    value: 'TUV',
  },
  {
    label: 'Uganda',
    code: '800',
    ccode: 'UG',
    value: 'UGA',
  },
  {
    label: 'Ukraine',
    code: '804',
    ccode: 'UA',
    value: 'UKR',
  },
  {
    label: 'United Arab Emirates',
    code: '784',
    ccode: 'AE',
    value: 'ARE',
  },
  {
    label: 'United Kingdom',
    code: '826',
    ccode: 'GB',
    value: 'GBR',
  },
  {
    label: 'United States',
    code: '840',
    ccode: 'US',
    value: 'USA',
  },
  {
    label: 'United States Minor Outlying Islands',
    code: '581',
    ccode: 'UM',
    value: 'UMI',
  },
  {
    label: 'United States Virgin Islands',
    code: '850',
    ccode: 'VI',
    value: 'VIR',
  },
  {
    label: 'Uruguay',
    code: '858',
    ccode: 'UY',
    value: 'URY',
  },
  {
    label: 'Uzbekistan',
    code: '860',
    ccode: 'UZ',
    value: 'UZB',
  },
  {
    label: 'Vanuatu',
    code: '548',
    ccode: 'VU',
    value: 'VUT',
  },
  {
    label: 'Vatican City',
    code: '336',
    ccode: 'VA',
    value: 'VAT',
  },
  {
    label: 'Venezuela',
    code: '862',
    ccode: 'VE',
    value: 'VEN',
  },
  {
    label: 'Vietnam',
    code: '704',
    ccode: 'VN',
    value: 'VNM',
  },
  {
    label: 'Wallis and Futuna',
    code: '876',
    ccode: 'WF',
    value: 'WLF',
  },
  {
    label: 'Western Sahara',
    code: '732',
    ccode: 'EH',
    value: 'ESH',
  },
  {
    label: 'Yemen',
    code: '887',
    ccode: 'YE',
    value: 'YEM',
  },
  {
    label: 'Zambia',
    code: '894',
    ccode: 'ZM',
    value: 'ZMB',
  },
  {
    label: 'Zimbabwe',
    code: '716',
    ccode: 'ZW',
    value: 'ZWE',
  },
  {
    label: 'Åland Islands',
    code: '248',
    ccode: 'AX',
    value: 'ALA',
  },
]
