// ############################################################
/**
 * @todo Document this
 */
// ############################################################

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventSchedulingFormComponentVerticalLine: React.FC = () => (
  <div className="my-4 border-t border-solid border-[#e4e4e4]"></div>
)

export default EventSchedulingFormComponentVerticalLine
