// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useIonRouter } from '@ionic/react'
import { useEffect } from 'react'
import EventTicketingFormComponent from '../../../components/events/forms/ticketing/EventTicketingFormComponent'
import { CONST } from '../../../const/const'
import useGetEventData from '../../../hooks/users/common/useGetEventData'
import { EventTicketingModel } from '../../../models/event-ticketing/event-ticketing.model'
import FirestoreService from '../../../services/firestoreService'
import { selectedEvent } from '../../../store/events/eventsSlice'
import { useAppSelector } from '../../../store/hooks'
import { useHistory } from 'react-router-dom'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface ShowsDetailPageEventTicketingFormSubSectionProps {
  onSetEventTab: (tab: string) => void
  onSetNextEventTab: (tab: string) => void
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  nextEventTab: string
  eventTab?: string
}

const ShowsDetailPageEventTicketingFormSubSection: React.FC<
  ShowsDetailPageEventTicketingFormSubSectionProps
> = (props) => {
  const router = useIonRouter()
  const history = useHistory()

  const { getAllData } = useGetEventData()

  const selectedEventR = useAppSelector(selectedEvent)

  const eventId = selectedEventR.Event?.id ?? ''

  const validFormHandler = async (data: any) => {
    let updated = true
    const updated_data = new EventTicketingModel({
      ...data,
    })

    await new Promise((resolve) => {
      FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_TICKETING.NAME,
        eventId,
        updated_data.toFirestore()
      )
        .catch((reason) => {
          console.error('validFormHandler:' + reason)
          updated = false
        })
        .finally(() => {
          resolve(true)
        })
    })

    return {
      updated,
      onSetEventTab:
        props.nextEventTab == '' ? props.onSetEventTab : props.onSetEventTab(props?.nextEventTab),
      onSetNextEventTab: props.onSetNextEventTab(''),
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const validAndExitFormHandler = async (data: any) => {
    const valueToReturn = await validFormHandler(data)
    return valueToReturn
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidFormHandler = async (data: any) => {
    props.onSetNextEventTab('')
    alert('invalid')
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidAndExitFormHandler = async (data: any) => {
    props.onSetNextEventTab('')
    alert('invalid')
    router.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
    history.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!eventId) return

    getAllData(eventId, ['v01_event_ticketing'])
  }, [eventId, getAllData])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <EventTicketingFormComponent
        onValid={validFormHandler}
        onInvalid={invalidFormHandler}
        data={selectedEventR.EventTickets}
        onValidAnExit={validAndExitFormHandler}
        onInvalidAndExit={invalidAndExitFormHandler}
        handleModal={props.handleModal}
        nextEventTab={props.nextEventTab}
        eventTab={props.eventTab}
      />
    </>
  )
}

export default ShowsDetailPageEventTicketingFormSubSection
