import IFormFunctionTypes from './formFunctions.types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const getFormStyles = (args?: IFormFunctionTypes['IGetSylesFnArgs']) => {
  const { title, noPaddingRt } = args ?? {}

  let className = ''
  let styles: React.CSSProperties = {}

  className = `${['Paid bills', 'Unpaid bills', 'Refunds'].find((currKey) => currKey === title) ? 'h-[85%]' : ''} text-SeabiscuitDark200ThemeColor`
  styles = { ...(noPaddingRt ? {} : { paddingRight: '8px' }) }

  return {
    styles,
    className,
  }
}

const formFunctions = {
  getFormStyles,
}

export default formFunctions
