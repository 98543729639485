export const COMPONENTS_CONST = {
  UTILS: {
    FORMS: {
      BUILDERS: {
        EVENT_PRICE_LIST_FORM_BUILDER: {
          VERSION: {},
        },
      },
    },
  },
}
