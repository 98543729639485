import { useIonRouter } from '@ionic/react'
import React, { useContext, useImperativeHandle, useState } from 'react'
import LightboxCommonComponent from '../../../../components/common/dialogs/full-screen/LightboxCommonComponent'
import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { useAppSelector } from '../../../../store/hooks'
import { selectProfileData } from '../../../../store/user/userSlice'
import { SETTINGS_TABS_OBJ } from '../../../profile/root/ProfileRootPageEditProfile'
import {useHistory} from "react-router-dom";

const ImageAndVideoButtons = React.forwardRef((_, ref) => {
  const IMAGE_COUNT_TO_SHOW = 7
  const router = useIonRouter()
  const history = useHistory()
  const profileDetails = useAppSelector(selectProfileData)

  const handleModalContext = useContext(HandleModalContext)

  const userBrandVideos: string[] = profileDetails?.userBrandVideos ?? []
  const userBrandImages_: string[] = profileDetails?.userBrandImages ?? []

  const [showImageGallery, setShowImageGallery] = useState(false)

  console.log({ userBrandVideos })

  useImperativeHandle(
    ref,
    () => {
      return {
        setShowImageGallery: setShowImageGallery,
      }
    },
    []
  )

  const viewOrAddButtonClick = (type: 'image' | 'video') => {
    if (
      (type === 'image' && !userBrandImages_.length) ||
      (type === 'video' && !userBrandVideos.length)
    ) {
      router.push(`${CONST.ROUTES.PROFILE_SETTINGS.URL}?tab=${SETTINGS_TABS_OBJ.PROFILE}`)
      history.push(`${CONST.ROUTES.PROFILE_SETTINGS.URL}?tab=${SETTINGS_TABS_OBJ.PROFILE}`)
      return
    }

    if (type === 'image') {
      setShowImageGallery(true)
    } else {
      handleModalContext?.handleModal?.(true, MODAL_CONSTS.VIDEO_PLAYER, {
        videos: userBrandVideos,
      })
    }
  }

  return (
    <div className="flex gap-4 text-nr">
      <button
        onClick={() => viewOrAddButtonClick('image')}
        className="flex items-center gap-2 bg-[#000000B2] w-fit px-3 py-2 text-white rounded-xl justify-center"
      >
        <img src="/assets/og_icons/Image-4.svg" alt="icon" className="w-7 h-7" />
        {userBrandImages_?.length > IMAGE_COUNT_TO_SHOW ? 'More' : 'Add Images'}
      </button>
      <button
        onClick={() => viewOrAddButtonClick('video')}
        className="flex items-center gap-2 bg-[#000000B2] w-fit px-3 py-2 text-white rounded-xl justify-center"
      >
        <img src="/assets/og_icons/Video-4.svg" alt="icon" className="w-7 h-7" />
        {userBrandVideos?.length ? 'Video' : 'Add Video'}
      </button>

      {showImageGallery && (
        <LightboxCommonComponent
          onClose={() => setShowImageGallery(false)}
          visible={true}
          slides={userBrandImages_.map((currImage) => {
            return { src: currImage }
          })}
        />
      )}
    </div>
  )
})

export default ImageAndVideoButtons
