const BillsHeader = ({ title, tooltipText }: { title: string; tooltipText?: string }) => {
  return (
    <div
      className={`hidden text-SeabiscuitDark200ThemeColor font-semibold text-xs ${title === 'Class' ? 'w-[45%]' : ' w-4/12'}  md:flex items-center`}
    >
      {title}
      {/* {
                tooltipText ? <Tooltip
                    title={<div className="tooltip_title">{tooltipText}</div>}
                    placement="top"
                    arrow
                >
                    <button className="ml-1">
                        <img className="w-[10px] h-[10px]" src="/assets/cp_icons/Help.svg" alt="icon" />
                    </button>
                </Tooltip> : null
            } */}
    </div>
  )
}

export default BillsHeader
