import { Link } from 'react-router-dom'
import { ITeamMember } from '../../../../models/users/user.interface'

const TeamMembersTab = ({
  tabHeading,
  team_members,
}: {
  tabHeading: string
  team_members: ITeamMember[]
}) => {
  return (
    <>
      <div className="heading uppercase mb-3 font-md text-SeabiscuitDark200ThemeColor">
        {tabHeading}
      </div>
      {team_members.map((currTeamMember: ITeamMember, index) => {
        return (
          <div key={`team_member_tab_team_member${index}`}>
            <div
              className="user flex flex-row flex-wrap items-center rounded-lg py-1"
              key={`teamMember${index}`}
            >
              <Link to={`/teamProfile/${currTeamMember?.memberId}`}>
                <img
                  src={
                    currTeamMember?.memberProfilePicture &&
                    currTeamMember?.memberProfilePicture !== ''
                      ? currTeamMember?.memberProfilePicture
                      : '/assets/og_icons/User.svg'
                  }
                  className="mr-3 rounded-full bg-SeabiscuitLightGrayThemeColor horseImg"
                  style={{ width: '45px', height: '45px', objectFit: 'cover' }}
                  alt="team_member"
                  onError={(e) => {
                    e.currentTarget.src = '/assets/og_icons/User.svg'
                  }}
                />
              </Link>
              <div className="flex flex-col items-start">
                <div className='"team_member_name text-SeabiscuitDark200ThemeColor text-[14px] font-medium capitalize'>
                  {currTeamMember.memberName}
                </div>
                <div className="team_member_details text-[12px] text-SeabiscuitDark200ThemeColor opacity-[.5] capitalize">
                  {currTeamMember?.defaultRole ?? 'Unknown'}
                  {/* {
                                        currTeamMember?.memberAddress && currTeamMember?.memberAddress !== "" ? `• ${currTeamMember?.memberAddress?.split(',')[0]}${currTeamMember?.memberCountry && currTeamMember?.memberCountry !== "" ?  `, ${currTeamMember?.memberCountry}` : null }` : null
                                    } */}
                </div>
              </div>
            </div>
            {team_members.length - 1 > index && <hr className=" md:my-2 my-1" />}
          </div>
        )
      })}
    </>
  )
}

export default TeamMembersTab
