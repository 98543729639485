export const UI_USER_CONST = {
  HELP: {
    TABS: {
      HELP: {
        VALUE: 'Help',
        SEQUENCE: 0,
      },
      FAQ: {
        VALUE: 'FAQ',
        SEQUENCE: 0,
      },
      POLICIES: {
        VALUE: 'Policies',
        SEQUENCE: 0,
      },
    },
  },
  BILLS: {
    TABS: {
      PAID: {
        VALUE: 'paid',
        SEQUENCE: 0,
      },
      ALL: {
        VALUE: 'all',
        SEQUENCE: 0,
      },
      UNPAID: {
        VALUE: 'unpaid',
        SEQUENCE: 0,
      },
      REFUNDS: {
        VALUE: 'refunds',
        SEQUENCE: 0,
      },
    },
  },
  PROFILE: {
    TABS: {
      INFO: {
        VALUE: 'info',
        SEQUENCE: 0,
      },
      STAFF: {
        VALUE: 'staff',
        SEQUENCE: 0,
      },
    },
  },
  TEAM: {
    TABS: {
      AUTHORIZE: {
        VALUE: 'Authorize',
        SEQUENCE: 0,
      },
      AUTHORIZED_ME: {
        VALUE: 'Authorized Me',
        SEQUENCE: 0,
      },
    },
  },
}
