import { Link } from 'react-router-dom'
import customImageComponent from '../common/CustomImageComponent'
type Props = {
  teamMateImage?: string | null
  teamMateName: string
  teamMateId?: string
  teamMateRole: string
  step?: number
}

const UserDetailCard = (props: Props) => {
  return (
    <>
      <div className="horse flex flex-row items-center max-w-[100%] w-[22rem] h-[56px] rounded-lg py-1.5 mb-2 px-1.5 border border-SeabiscuitLightThemeColor">
        <span className="w-11 h-11 mr-2 rounded-md overflow-hidden">
          <Link to={`/teamProfile/${props?.teamMateId}`} className="cursor-pointer">
            {customImageComponent(
              props.teamMateImage,
              props.teamMateName,
              'w-full h-full object-cover'
            )}
          </Link>
        </span>
        <div className="hourseDetails w-10/12">
          <div className="hourseTitle text-SeabiscuitDark200ThemeColor capitalize">
            {props.teamMateName}
          </div>
          <p className="flex items-center text-sm capitalize text-SeabiscuitDark200ThemeColor">
            {props.teamMateRole}
          </p>
        </div>
      </div>
    </>
  )
}

export default UserDetailCard
