import { AddCircleOutline, AutorenewRounded } from '@mui/icons-material'
import { where } from 'firebase/firestore'
import { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import helpers from '../../../../commonHelpers/helpers'
import FormHeader from '../../../../components/ui/form/form-header/FormHeader'
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { getConvertedData } from '../../../../models/interface.helper'
import { IProductInterface } from '../../../../models/product/product.interface'
import { ProductModel } from '../../../../models/product/product.model'
import FirestoreService from '../../../../services/firestoreService'
import { useAppSelector } from '../../../../store/hooks'
import { selectUserId } from '../../../../store/user/userSlice'
import TABS from '../tabs/tabs.json'
import Product from './Product'
import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const DraftProducts = () => {
  const userId = useAppSelector(selectUserId)

  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)

  const toastFunctions = useToasterHelper()
  const handleModal = useContext(HandleModalContext)

  const { control, handleSubmit, register, watch, reset, setValue } = useForm({
    mode: 'onChange',
  })

  const productsDataArray = useFieldArray({
    control,
    name: 'products',
  })

  const VENDOR_AND_SPONSOR = CONST.UI.VENDOR_AND_SPONSOR
  const DUMMY_PRODUCTS = VENDOR_AND_SPONSOR.DUMMY_DATA.PRODUCTS

  useEffect(() => {
    fetchProducts(userId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  async function fetchProducts(userId: string | null) {
    if (userId) {
      FirestoreService.filterItems(COLLECTIONS.PRODUCTS.NAME, [
        where(
          COLLECTIONS.PRODUCTS.FIELDS.CREATOR_TYPE.KEY,
          '==',
          COLLECTIONS.PRODUCTS.FIELDS.CREATOR_TYPE.VALUES.VENDOR
        ),
        where(
          COLLECTIONS.PRODUCTS.FIELDS.STATUS.KEY,
          '==',
          COLLECTIONS.PRODUCTS.FIELDS.STATUS.VALUES.DRAFT
        ),
        where(COLLECTIONS.PRODUCTS.FIELDS.CREATOR_ID.KEY, '==', userId),
      ])
        .then((productSnapshots) => {
          const products: IProductInterface[] = []
          productSnapshots.forEach((currProductSnapshot) => {
            products.push(
              getConvertedData(
                ProductModel.fromFirestoreDoc(currProductSnapshot).clearUpdatedMark()
              )
            )
          })
          reset({
            products,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  const onAddButtonClick = () => {
    handleModal?.handleModal?.(true, MODAL_CONSTS.CREATE_AND_UPDATE_PRODUCT, {
      productDoc: new ProductModel().toObject(),
      updateProduct: (createdProduct: IProductInterface) => {
        if (createdProduct?.status === COLLECTIONS.PRODUCTS.FIELDS.STATUS.VALUES.DRAFT) {
          productsDataArray.append(createdProduct)
        }
      },
    })
  }

  const onSubmit = async (data: any) => {
    try {
      setSaving(true)

      let product: IProductInterface | null = null
      let updatedProducts: IProductInterface[] = []

      const products = data.products as IProductInterface[]

      const productsToUpdate = products.filter((product) => {
        if (
          product.delete !== true &&
          product.status === COLLECTIONS.PRODUCTS.FIELDS.STATUS.VALUES.DRAFT
        ) {
          updatedProducts.push(
            getConvertedData({
              ...new ProductModel(product).toFirestore(),
              u: false,
              id: product.id,
            })
          )
        }
        return !!product.u && product.delete !== true
      })
      const productsToDelete = products.filter((product) => {
        return !!product.delete && !!product.id
      })

      await helpers.asyncWhileLoop({
        loopCount: productsToUpdate.length,
        functionToFire: async (index) => {
          product = productsToUpdate[index]

          await FirestoreService.updateItem(
            COLLECTIONS.PRODUCTS.NAME,
            product.id,
            new ProductModel(product).toFirestore()
          )
        },
      })

      await helpers.asyncWhileLoop({
        loopCount: productsToDelete.length,
        functionToFire: async (index) => {
          product = productsToDelete[index]

          await FirestoreService.deleteItem(COLLECTIONS.PRODUCTS.NAME, product.id!)
        },
      })

      reset({
        products: updatedProducts,
      })

      toastFunctions.success({
        message: MESSAGES_CONST.PRODUCTS_UPDATED,
      })
    } catch (error) {
      console.error(error)
      setLoading(false)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setSaving(false)
    }
  }

  const onEditButtonClick = (index: number) => {
    handleModal?.handleModal?.(true, MODAL_CONSTS.CREATE_AND_UPDATE_PRODUCT, {
      productDoc: watch(`products.${index}`),
      updateProduct: (updatedProduct: IProductInterface) => {
        if (updatedProduct?.status === COLLECTIONS.PRODUCTS.FIELDS.STATUS.VALUES.LIVE) {
          productsDataArray.remove(index)
        } else {
          productsDataArray.update(index, updatedProduct)
        }
      },
    })
  }

  const onRemoveButtonClick = (index: number, bypass = false) => {
    if (bypass !== true) {
      handleModal?.handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
        removeItem: function () {
          //   productsDataArray.update(index, {
          //     ...watch(`products.${index}`),
          //     delete: true,
          //   });
          productsDataArray.remove(index)
        },
      })
      return 0
    }
  }

  return (
    <>
      <div>
        <FormHeader
          title={TABS[1].title}
          description={TABS[1].description}
          headerButtonsContainer={
            <div className="flex items-center gap-2">
              <button
                type="button"
                onClick={() => {
                  handleSubmit(onSubmit)()
                }}
                disabled={saving || loading}
                className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn min-w-[150px]"
              >
                {saving ? (
                  <AutorenewRounded fontSize="small" className="animate-spin" />
                ) : (
                  'Save changes'
                )}
              </button>
            </div>
          }
        />

        <div className="flex flex-col gap-4">
          {productsDataArray.fields.length ? (
            (loading ? DUMMY_PRODUCTS : productsDataArray.fields).map(
              (currProduct: any, currIndex) => {
                if (currProduct?.delete) {
                  return null
                }

                return (
                  <Product
                    watch={watch}
                    loading={loading}
                    index={currIndex}
                    register={register}
                    setValue={setValue}
                    product={currProduct}
                    name={`products.${currIndex}`}
                    onEditButtonClick={onEditButtonClick}
                    onRemoveButtonClick={onRemoveButtonClick}
                    key={`product${currIndex}`}
                  />
                )
              }
            )
          ) : (
            <>
              <DataNotAvailable
                mode="graphic"
                imageSource={'assets/placeholders/noResultsForSearch.svg'}
                imageAlt="persons in round frames"
                upperText={'You do not added any Products yet'}
                // bottomText={"not its not"}
              />
            </>
          )}
        </div>
        <button
          className="text-SeabiscuitMainThemeColor flex items-center gap-2 text-sm font-[400] mt-2"
          onClick={onAddButtonClick}
        >
          <AddCircleOutline />
          Add product
        </button>
      </div>
    </>
  )
}

export default DraftProducts
