/* eslint-disable jsx-a11y/anchor-is-valid */
// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useContext } from 'react'
import { SignupStages } from './UserRegistrationViewComponentContextApi'
import UserRegistrationViewComponentButton from './global/UserRegistrationViewComponentButton'

import backIcon from './assets/arrow-left.svg'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const UserRegistrationViewComponentCompetitorOrganizerVerify: React.FC = () => {
  const [stage, setStage] = useContext(SignupStages)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div>
      <div className="text-[24px] flex items-center gap-2">
        <button onClick={() => stageHandle('Init')}>
          <img src={backIcon} alt="back" />
        </button>
        Competitor, etc.
      </div>
      <div className="mt-10 flex gap-4 items-center border-[#D3DAEE] border-solid border-[1px] bg-white p-3 rounded-xl">
        <div className="min-w-[25px]">
          <img src="/assets/og_icons/Mail-4.svg" alt="email" className="w-full" />
        </div>
        <div className="text-[#777E85]">
          A verification email was sent to the email address on file with the USEF for the
          membership number you entered.
        </div>
      </div>
      <div className="w-[70%] mt-[180px] mx-auto">
        <UserRegistrationViewComponentButton
          caption="OPEN MAIL APP"
          type={1}
          disabled={!stage.selector}
          onClick={() => stageHandle('Profile')}
        />
      </div>
      <div className="text-center text-[#777E85] mt-10">OR</div>
      <div className="text-[#120D26] my-10 text-center">
        Don’t have a USEF number?{' '}
        <a
          className="text-SeabiscuitMainThemeColor cursor-pointer"
          onClick={() => stageHandle('Profile')}
        >
          Continue &gt;
        </a>
      </div>
    </div>
  )
}

export default UserRegistrationViewComponentCompetitorOrganizerVerify
