import INotFoundAlert from './types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

import { useIonRouter } from '@ionic/react'
import Button from '../../buttons/Button'
import { useHistory } from 'react-router-dom'

/**
 * @TODO Document this
 */
const NotFoundAlert = (props: INotFoundAlert['IProps']) => {
  const router = useIonRouter()
  const history = useHistory()

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const goBack = () => {
    if (props.goBackUrl) {
      router.push(props.goBackUrl)
      history.push(props.goBackUrl)
    } else if (router.canGoBack()) {
      router.goBack()
      history.goBack()
    } else {
      router.push('/')
      history.push('/')
    }
  }

  return (
    <section className="h-full w-full inline-flex items-center justify-center mt-40">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center ">
          <h1 className="mb-4 text-lg tracking-tight font-extrabold text-SeabiscuitBlackThemeColor">
            {props.title ?? "This page doesn't exist"}
          </h1>
          <p className="mb-4 text-md font-light text-SeabiscuitGrayLight300ThemeColor">
            {props.description ?? ''}
          </p>
          <Button onClick={goBack} className="min-w-[200px]">
            {router.canGoBack() || props.goBackUrl ? 'Go Back' : 'Go Back to Home'}
          </Button>
        </div>
      </div>
    </section>
  )
}

export default NotFoundAlert
