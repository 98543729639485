import { PDFDocument, PDFFont, PDFImage, PDFPage } from 'pdf-lib'
import helpers from '../commonHelpers/helpers'
import { MESSAGES_CONST } from '../const/messages-const'
import { CustomError } from '../helpers/helpers'
// @ts-ignore
import fontkit from '@pdf-lib/fontkit'
import { cmyk } from 'pdf-lib'

const CUSTOM_ERROR_PROPS = {
  fileName: 'pdfService',
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

type IAddNewPdfPageArgs = {
  /** @info Width of the page to set */
  width: number
  /** @info Height of the page to set */
  height: number
}

type IWriteLineArgs = {
  /** @info The x coordinate from where to start writing the line */
  x: number
  /** @info The y coordinate from where to start writing the line */
  y: number
  /** @info The fontsize for letters */
  fontSize: number
  /** @info The string to write */
  content: string
  /** @info The font family for letters */
  font?: PDFFont
  /** @info Number between 0 to 10 */
  factor?: number
  /** @info If `true` then the line content passed is written on a new page */
  stateFromNewPage?: boolean
}

type IPdfServiceArgs = {
  /** @info Max area in width till where content is writable horizontally */
  pageWidth: number
  /** @info Max area in width till where content is writable veritically */
  pageHeight: number
  /** @info X1 coordinate from where the lines to write */
  pageHorizontalStartPoint: number
  /** @info Vertical distance between lines */
  distanceBetweenLines: number
  /** @info Y1 coordinate from where the lines to write */
  pageVerticalStartPoint: number
  /** @info Y2 coordinate from where the lines to write */
  pageVerticalEndPoint?: number
  /** @info Y2 coordinate from where the lines to write */
  pageHorizontalEndPoint?: number
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
class PdfService {
  private currentX: number = 45
  private currentY: number = 720
  private pdfPages: PDFPage[] = []
  readonly pageWidth: number = 550
  private pdfDoc: PDFDocument | null = null
  readonly pageHeight: number = 760
  private currPageNumber: number = 1
  readonly distanceBetweenLines: number = 20
  private currPdfPage: PDFPage | null = null
  readonly pageVerticalEndPoint: number = 80
  readonly pageVerticalStartPoint: number = 720
  readonly pageHorizontalEndPoint: number = 715
  readonly pageHorizontalStartPoint: number = 45

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  constructor(args: IPdfServiceArgs) {
    this.pageWidth = args.pageWidth
    this.pageHeight = args.pageHeight
    this.distanceBetweenLines = args.distanceBetweenLines
    this.pageVerticalStartPoint = args.pageVerticalStartPoint
    this.pageHorizontalStartPoint = args.pageHorizontalStartPoint
    this.pageVerticalEndPoint =
      args.pageVerticalEndPoint ?? this.pageHeight - this.pageVerticalStartPoint
    this.pageHorizontalEndPoint =
      args.pageHorizontalEndPoint ?? this.pageWidth - this.pageHorizontalStartPoint
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get getPdfInstance(): Promise<PDFDocument> {
    return new Promise(async (resolve) => {
      let created = false
      let pdfDoc: typeof this.pdfDoc = null

      if (this.pdfDoc) pdfDoc = this.pdfDoc
      else {
        pdfDoc = await PDFDocument.create()
        created = true
      }

      if (!pdfDoc) {
        let error = CustomError.somethingWentWrong({
          ...CUSTOM_ERROR_PROPS,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: `this.pdfDoc is null`,
          moduleName: 'addNewPdfPage',
        })

        helpers.logger({
          message: error,
        })

        throw error
      }

      this.pdfDoc = pdfDoc

      if (created) this.registerFontkit()

      resolve(pdfDoc)
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get getPdfPagesCount(): number {
    return this.pdfPages.length
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get getPdfPages(): PDFPage[] {
    return this.pdfPages
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get getCurrentX(): number {
    return this.currentX
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get getCurrentY(): number {
    return this.currentY
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get getCurrentPdfPage(): Promise<PDFPage> {
    return new Promise(async (resolve) => {
      if (this.currPdfPage) resolve(this.currPdfPage)
      else if (this.pdfPages.length) resolve(this.pdfPages.at(-1) as PDFPage)
      else {
        this.currPdfPage = await this.addNewPdfPage()
        resolve(this.currPdfPage)
      }
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  addNewPdfPage(args?: IAddNewPdfPageArgs): Promise<PDFPage> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance

      let { width = this.pageWidth, height = this.pageHeight } = args ?? {}

      this.pdfDoc = pdfDoc
      this.currPdfPage = this.pdfDoc.addPage([width, height])
      this.pdfPages.push(this.currPdfPage)
      this.currPageNumber = this.getPdfPagesCount
      resolve(this.currPdfPage)
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get getCurrentPageNumber(): number {
    return this.currPageNumber
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  get generatedPdf(): Promise<Uint8Array> {
    return new Promise(async (resolve) => {
      this.pdfDoc = await this.getPdfInstance
      let pdfBytes = await this.pdfDoc.save()
      resolve(pdfBytes)
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  embedFont(font_: ArrayBuffer | string): Promise<PDFFont> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance
      const font = await pdfDoc.embedFont(font_)
      resolve(font)
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  embedPng(png: string | Uint8Array | ArrayBuffer): Promise<PDFImage> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance
      const image = await pdfDoc.embedPng(png)
      resolve(image)
    })
  }

  embedJpeg(jpg: string | Uint8Array | ArrayBuffer): Promise<PDFImage> {
    return new Promise(async (resolve) => {
      let pdfDoc = await this.getPdfInstance
      const image = await pdfDoc.embedJpg(jpg)
      resolve(image)
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  registerFontkit(): void {
    this.pdfDoc?.registerFontkit(fontkit)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  addHeader(): Promise<void> {
    return new Promise(async (resolve) => {
      if (!this.currPdfPage) return resolve()

      const HEADER_HEIGHT = 35
      const jpgUrl = '/assets/Pegasus_app_logo.png'
      const fontPoppingsBytes = await fetch('/fonts/Poppins-Regular.ttf').then((res) =>
        res.arrayBuffer()
      )
      const poppinsFont = await this.embedFont(fontPoppingsBytes)
      const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
      const jpgImage = await this.embedPng(jpgImageBytes)

      await this.writeLine({
        x: this.pageHorizontalStartPoint,
        y: this.pageVerticalStartPoint + 5,
        font: poppinsFont,
        content: 'Pegasus App Inc.',
        fontSize: 12,
      })

      this.currPdfPage.drawLine({
        start: { x: this.pageHorizontalStartPoint, y: this.pageVerticalStartPoint },
        end: { x: this.pageHorizontalEndPoint, y: this.pageVerticalStartPoint },
        thickness: 1,
        color: cmyk(0.0, 0.9717, 0.6802, 0.0314),
        opacity: 1,
      })

      this.currPdfPage.drawImage(jpgImage, {
        x: this.pageHorizontalEndPoint,
        y: this.pageVerticalStartPoint - 20,
        width: 40,
        height: 40,
        opacity: 1,
      })

      this.currentY = this.pageVerticalStartPoint - HEADER_HEIGHT

      resolve()
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async writeLine(args: IWriteLineArgs): Promise<void> {
    let {
      content,
      x,
      y,
      fontSize,
      factor = 0.55,
      font = undefined,
      stateFromNewPage = false,
    } = args

    await new Promise(async (resolve) => {
      let workGettingBreak = false

      const charWidth = fontSize * factor
      let charactersPerLine = Math.floor(this.pageWidth / charWidth)
      let strToWrite = content.substring(0, charactersPerLine)
      content = content.substring(charactersPerLine)

      if (!strToWrite.endsWith(' ') && !content.startsWith(' ')) workGettingBreak = true

      if (workGettingBreak && !!content) {
        content = `${strToWrite.split(' ').at(-1)}${content}`
        strToWrite = strToWrite
          .split(' ')
          .splice(0, strToWrite.split(' ').length - 1)
          .join(' ')
      }

      const totalWidthToWriteContent =
        (strToWrite.length > 10 ? strToWrite.length - 10 : strToWrite.length) * charWidth

      this.currentX = x
      this.currentY = y

      if (this.getPdfPagesCount === 0) this.currPdfPage = await this.addNewPdfPage()

      if (this.currPdfPage) {
        if (this.currentY < this.pageVerticalEndPoint || stateFromNewPage) {
          this.currPdfPage = await this.addNewPdfPage()
          await this.addHeader()
          this.currentX = this.pageHorizontalStartPoint
        }

        if (this.currentX + strToWrite.length > this.pageHorizontalEndPoint)
          this.currentX = this.pageHorizontalStartPoint

        this.currPdfPage.drawText(strToWrite, {
          x: this.currentX,
          y: this.currentY,
          font,
          size: fontSize,
        })

        this.currentX = this.currentX + totalWidthToWriteContent
        this.currentY = this.currentY - this.distanceBetweenLines

        resolve(true)
      }
    })

    if (!!content)
      await this.writeLine({
        content,
        x: this.currentX,
        y: this.currentY,
        font,
        fontSize,
        factor,
      })
  }
}

export default PdfService
