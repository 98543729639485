import { useEffect, useMemo, useRef, useState } from 'react'

// Third party
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'

// Types
import {
  IEventDetailData,
  IPaperworkTab,
} from '../../../../models/event-drafts/event-draft.interface'
import { ILightboxProps } from '../../../../types/competitor_types'

// Constants
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { Tooltip } from '@mui/material'
import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import fakeDocumentList, { IDocument } from '../../../../fakeData/fakeDocumentList'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import { TEventPaperwork } from '../../../../models/event-review-publish/event-review-publish.interface'
import { ITeamMember, IUserInterface } from '../../../../models/users/user.interface'
import {
  IEventsRegisterData,
  ISelectedEvent,
  selectRegisterEventPaperworksData,
  selectedEvent,
  setRegisterPaperworkData,
} from '../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

import { AutorenewRounded } from '@mui/icons-material'
import { useLocation, useParams } from 'react-router'

import { useIonRouter } from '@ionic/react'
import { where } from 'firebase/firestore'
import helpers from '../../../../commonHelpers/helpers'
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { COUNTRIES_LIST } from '../../../../fakeData/countriesList'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { CustomError } from '../../../../helpers/helpers'
import {
  IPaperworkTab_,
  IRegistrationTabs,
  ISignatoryForMail as isfm,
} from '../../../../models/event-registered-users/event-registered-users.interface'
import { EventModel } from '../../../../models/events/event.model'
import { getConvertedData } from '../../../../models/interface.helper'
import {
  IRiderTeamMemberInterface,
  ISignedStatuses,
} from '../../../../models/rider-team-member/riderTeamMember.interface'
import { RiderTeamMemberModel } from '../../../../models/rider-team-member/riderTeamMember.model'
import { UserLegalClausesModel } from '../../../../models/user-legal-clauses/user-legal-clauses.model'
import { UserModel } from '../../../../models/users/user.model'
import FirestoreService from '../../../../services/firestoreService'
import {
  selectFilteredPaperworkDocuments,
  selectPaperworkTabData,
  selectRidersTeamMembersR,
  selectSendMailLoadingR,
  selectSignTabDataR,
  setSendMailLoading,
} from '../../../../store/registration/registrationSlice'
import { selectProfileData } from '../../../../store/user/userSlice'
import { USDFLiabilityWaiverPDFTemplate } from '../../../../templates/pdf/USDFLiabilityWaiver/USDFLiabilityWaiverPDFTemplate'
import { USEALiabilityWaiverPDFTeamplate } from '../../../../templates/pdf/USEALiabilityWaiver/USEALiabilityWaiverPDFTeamplate'
import { USEFLiabilityWaiverPDFTemplate } from '../../../../templates/pdf/USEFLiabilityWaiver/USEFLiabilityWaiverPDFTemplate'
import { USHJALiabilityWaiverPDFTeamplate } from '../../../../templates/pdf/USHJALiabilityWaiverPDFTeamplate/USHJALiabilityWaiverPDFTeamplate'
import { GeneralLiabilityWaiverPDFTeamplate } from '../../../../templates/pdf/generalLiabilityWaiver/GeneralLiabilityWaiverPDFTeamplate'
import { DOCUMENT_TYPES } from './CompetitorEventsRegisterPaperworkTab'
import DataNotAvailable from '../../../../components/common/alerts/data-not-available/DataNotAvailable'
import { IManageInfo } from '../../event-registration-tabs/EventRegistrationTabs'
import { USEFEntryAgreementPDFNewTemplate } from '../../../../templates/pdf/USEFEntryAgreement/USEFEntryAgreementPDFNewTemplate'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// Constants

/**
 * @param SIGNED Means user have got email and have signed
 * @param MAIL_SENT Means user have sent email
 * @param NOT_SIGNED Means user have sent email but the user haven't signed yet
 * @param REMOVED Means user have sent email, but have been removed from list on paperwork tab
 */

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const SIGNED_STATUSES = COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.SIGNED_STATUS

type ICompetitorEventRegisterSignTabProps = {
  step?: number
  title?: string
  eventName?: string
  description?: string
  message: string | null
  signTab?: IPaperworkTab
  documents: IPaperworkTab
  paperworkTab: IPaperworkTab
  usersHolder: IUserInterface[]
  savePaperworkTabData: (args: {
    ridersTeamMembersToAdd?: IRiderTeamMemberInterface[]
    ridersTeamMembersToDelete?: IRiderTeamMemberInterface[]
    ridersTeamMembersToUpdate?: IRiderTeamMemberInterface[]
  }) => void
  registerFormData?: IEventsRegisterData
  allRiderTeamMembers: ISignatoryForMail[]
  mergeRidersTeamMembersWithTabData: (
    paperworkTabData_: IRegistrationTabs['IPaperworkTab'][],
    ridersTeamMembers: IRiderTeamMemberInterface[]
  ) => IPaperworkTab_[]
  handleLightBox: (props: ILightboxProps) => void
  EventPaperwork?: ISelectedEvent['EventPaperwork']
  handleDocument: (args: { index: number; signedStatus: ISignedStatuses }) => void
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  isManage?: boolean
  manageInfo: IManageInfo
  setManageInfo: (value: IManageInfo) => void
}

export interface ISignatoryForMail extends isfm {}

// Local types
export type IPaperwork = {
  paperworkName: string
  paperworkLogo: string
  paperworkImage: string[] | any
  paperworkDivisionKey: string // [ Like USEF, USEA, USDF ]
  relatedPaperworks: IRelatedPaperwork[]
}

type IRelatedPaperwork = {
  paperworkName: string
  paperworkKey: string
  signatories: ITeamMember[]
}

// Local types

// Constants
const widths = ['w-[300px] shrink-0', 'w-[135px] shrink-0', 'flex-grow', 'w-[160px] shrink-0']
const CLAUSE_TABLE = CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_LEGAL_CLAUSES

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

const CompetitorEventRegisterSignTab = (props: ICompetitorEventRegisterSignTabProps) => {
  // Hooks and vars
  const signTabData = useAppSelector(selectSignTabDataR)
  const ridersTeamMembers = useAppSelector(selectRidersTeamMembersR)
  const paperworkTabData = useAppSelector(selectPaperworkTabData)
  const filteredPaperworksDocuments = useAppSelector(selectFilteredPaperworkDocuments)
  const sendMailLoading = useAppSelector(selectSendMailLoadingR)
  const { eventId: eventIds } = useParams<{ eventId: string }>()
  const [message, setMessage] = useState<string | null>(null)
  const paperworksAddedByOrganizer = !!filteredPaperworksDocuments.length

  const router = useIonRouter()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectProfileData)
  const [allClauses, setAllClauses] = useState<any[]>([])
  const { EventDetails } = useAppSelector(selectedEvent)
  const eventId = useAppSelector(selectedEvent).basicEventDetails.id
  const paperworkTabDataInRedux: IPaperworkTab = useAppSelector(selectRegisterEventPaperworksData)

  const toastShown = useRef(false)
  const toastMethods = useToasterHelper()

  const { handleLightBox, EventPaperwork = {} } = props

  const setClauses = useState<any[]>([])[1]
  const [organizerDetails, setOrganizerDetails] = useState<IUserInterface | null>(null)
  const [registerFormData, setRegisterFormData] = useState(() => props.registerFormData)

  useEffect(() => {
    let message_ = null

    switch (true) {
      case signTabData.showCompetitorIsMinorMessage:
        message_ =
          'Your date of birth identifies you as a minor. Your paperwork will be sent to the person you identified as your guardian in the previous section. If you did not identify a guardian, go back and add one now.'
        break

      case signTabData.showCompetitorNotListedMessage:
        message_ =
          'You have no paperwork to sign because you have not listed yourself as a rider or team member. If you are attending this event, please list yourself as a team member to receive paperwork to sign.'
        break

      case signTabData.showGuardianMessage:
        message_ =
          'Your one rider date of birth identifies you as a minor. so paperwork will be sent to the person you identified as minor rider guardian in the previous section. If you did not identify a guardian, go back and add one now.'
        break
    }

    setMessage(message_)
  }, [signTabData])

  async function getUserData(ownerId: string) {
    const doc = await FirestoreService.getItem(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
      ownerId
    )
    let owner = UserModel.fromFirestoreDoc(doc).toObject()
    setOrganizerDetails(owner)
  }

  function calculateAge(birthday: any) {
    // birthday is a date
    let currentTime = new Date().getTime()
    let birthDateTime = new Date(birthday).getTime()
    let difference = currentTime - birthDateTime
    var ageInYears = difference / (1000 * 60 * 60 * 24 * 365)
    return Math.floor(ageInYears)
  }

  useEffect(() => {
    let membersOfOneDayRegistration: ITeamMember[] = []
    let localRegisterFormData: IEventsRegisterData | null = null

    if (props.registerFormData?.events) localRegisterFormData = { ...props.registerFormData }

    if (!localRegisterFormData) return

    localRegisterFormData.events = localRegisterFormData.events?.reduce(
      (acc: IEventDetailData[], currOneDayRegistration) => {
        membersOfOneDayRegistration = []
        currOneDayRegistration.members.forEach((currMember) => {
          if (currMember?.horses?.length) membersOfOneDayRegistration.push(currMember)
        })

        if (membersOfOneDayRegistration.length)
          acc.push({
            ...currOneDayRegistration,
            members: membersOfOneDayRegistration,
          })

        return acc
      },
      []
    )

    setRegisterFormData(localRegisterFormData)
  }, [props.registerFormData])

  useEffect(() => {
    let count: number = 0
    if (!registerFormData?.events || toastShown.current) return

    registerFormData.events?.forEach((currOneDayRegistration: IEventDetailData) => {
      currOneDayRegistration.members.forEach((currMember) => {
        if (!currMember?.horses?.length) count++
      })
    }, [])

    if (!count) return

    toastShown.current = true
    toastMethods.error({
      message: MESSAGES_CONST.MEMBERS_WITHOUT_HORSE.replace('[COUNT]', `${count}`).replace(
        '[MEMBER]',
        count > 1 ? 'members' : 'member'
      ),
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getClauseData(ownerId: string) {
    const data: any[] = []
    const result = await FirestoreService.filterItems(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.USER_LEGAL_CLAUSES.NAME,
      [where('ownerId', '==', ownerId)]
    )

    if (result.size) {
      result?.forEach((doc) => {
        let clauseModelData = UserLegalClausesModel.fromFirestoreDoc(doc).toObject()
        data.push(clauseModelData)
      })
    }
    setClauses(data)
  }

  useEffect(() => {
    if (EventDetails && EventDetails.owner) {
      // getUserData(EventDetails.owner)
    }
  }, [EventDetails])

  useEffect(() => {
    if (organizerDetails) {
      getClauseData(organizerDetails.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizerDetails])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Categorieses all the documents that are enabled by the organizer according to their key
   */
  const paperworks: IPaperwork[] = useMemo(() => {
    let paperworksCopy: IPaperwork[] = []
    let paperworksWithSameDivisionExist: IPaperwork[]
    let filteredPaperworkIndex: number | null = null
    let paperworkToInsert: IRelatedPaperwork

    paperworksCopy = fakeDocumentList.reduce(
      (paperworksAcc, currPaperwork) => {
        filteredPaperworkIndex = null

        const selectedByOrganizer =
          EventPaperwork?.hasOwnProperty(currPaperwork.key) &&
          EventPaperwork[currPaperwork.key as keyof TEventPaperwork] === true

        if (selectedByOrganizer) {
          paperworksWithSameDivisionExist = paperworksAcc.filter((curr, index) => {
            if (curr.paperworkDivisionKey === currPaperwork.document_type) {
              filteredPaperworkIndex = index
              return true
            }
            return curr.paperworkDivisionKey === currPaperwork.document_type
          })

          paperworkToInsert = {
            paperworkName: currPaperwork.document_name,
            paperworkKey: currPaperwork.key,
            signatories: [],
          }

          if (filteredPaperworkIndex && paperworksWithSameDivisionExist.length > 0) {
            paperworksAcc.splice(filteredPaperworkIndex, 1, {
              ...paperworksWithSameDivisionExist[0],
              relatedPaperworks: [
                ...paperworksWithSameDivisionExist[0].relatedPaperworks,
                paperworkToInsert,
              ],
            })
          } else {
            paperworksAcc = [
              ...paperworksAcc,
              {
                paperworkName: `${currPaperwork.document_name?.toUpperCase()}`,
                paperworkLogo: 'assets/Pegasus_app_logo.svg',
                /**
                 * @change as per the client requirements, using general liability images for all docs for demo
                 * @prev paperworkImage: currPaperwork.document_image,
                 * @new paperworkImage: fakeDocumentList[1]?.document_image,
                 */
                paperworkImage: fakeDocumentList[1]?.document_image,
                paperworkDivisionKey: currPaperwork.document_type,
                relatedPaperworks: [paperworkToInsert],
              },
            ]
          }
        }

        return paperworksAcc
      },
      [...paperworksCopy]
    )

    return paperworksCopy

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paperworkTabDataInRedux])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param index Index of the paperwork doc whose image needs to be open in lightbox
   * @info Opens lightbox with paperwork document image
   */
  const openLightBox = (index: number) => {
    let paperworkImages = fakeDocumentList[1].document_image

    if (!Array.isArray(paperworkImages)) paperworkImages = [paperworkImages]

    const slides = paperworkImages.reduce((acc: any[], current: string) => {
      acc.push({ src: current, title: filteredPaperworksDocuments[index]?.document_name })
      return acc
    }, [])

    handleLightBox({
      visible: true,
      slides,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Sends mail by taking out the signatories from the document
   */
  const handleSendMail = async (filteredPaperworkIndex: number, paperwork: IDocument) => {
    let paperworkTabData_ = cloneDeep(paperworkTabData)
    if (props.isManage) {
      paperworkTabData_ = props.manageInfo.paperwork
    }

    if (EventDetails && eventId) {
      try {
        let paperworkDocument = filteredPaperworksDocuments[filteredPaperworkIndex]

        const data = {
          eventId,
          userData,
          paperwork,
          EventDetails,
          organizerDetails,
          eventDraftId: '',
        }

        let mailLogItemIndex = -1
        let userIds_: string[] = []
        let users: IUserInterface[] = []
        let rider: IUserInterface | null = null
        let ridersTeamMember: IUserInterface | null = null
        let mailLog: IRiderTeamMemberInterface['mailLog'] = []
        let ridersTeamMembers_ = cloneDeep(ridersTeamMembers)

        if (props.isManage) {
          const ridersTeamMembersManage: IRiderTeamMemberInterface[] = []
          props.manageInfo.paperwork.forEach((paperwork) => {
            if (paperwork.ridersTeamMembers.length > 0)
              ridersTeamMembersManage.push(...paperwork.ridersTeamMembers)
          })

          ridersTeamMembers_ = ridersTeamMembersManage
        }

        let userIds = ridersTeamMembers_.flatMap((currRiderTeamMember) => {
          return [currRiderTeamMember.riderId, currRiderTeamMember.teamMemberId]
        })

        userIds.forEach((currUserId) => {
          if (currUserId) userIds_.push(currUserId)
        })
        userIds_ = [...new Set(userIds_)]

        let userSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, userIds_)

        userSnaps.forEach((currUserDoc) => {
          users.push(getConvertedData(UserModel.fromFirestoreDoc(currUserDoc).toObject()))
        })

        ridersTeamMembers_ = ridersTeamMembers_.map((currRiderTeamMember) => {
          rider = users.find((currUser) => currUser.id === currRiderTeamMember.riderId) ?? null
          ridersTeamMember =
            users.find((currUser) => currUser.id === currRiderTeamMember.teamMemberId) ?? null
          mailLog = cloneDeep(currRiderTeamMember.mailLog)

          mailLogItemIndex = mailLog.findIndex((curMailLog) => {
            return curMailLog.paperworkKey === paperworkDocument.key
          })

          if (mailLogItemIndex === -1) {
            mailLog.push({
              paperworkKey: paperworkDocument.key,
              status: SIGNED_STATUSES.VALUES.SIGNED ?? 'Not Signed',
            })
          } else {
            mailLog[mailLogItemIndex] = {
              ...mailLog[mailLogItemIndex],
              paperworkKey: paperworkDocument.key,
              status: SIGNED_STATUSES.VALUES.SIGNED ?? 'Not Signed',
            }
          }

          return getConvertedData({
            ...new RiderTeamMemberModel({
              ...currRiderTeamMember,
              mailLog,
              riderEmail: rider?.userEmail,
              riderCountry: rider?.userNationality,
              riderPhoneNumber: rider?.userPhoneNumber,
              riderProfilePicture: rider?.userProfilePicture,
              teamMemberCountry: ridersTeamMember?.userNationality,
              teamMemberPhoneNumber: ridersTeamMember?.userPhoneNumber,
              riderPhoneCode: COUNTRIES_LIST.find((cc) => cc.value === rider?.userPhoneCode)
                ?.phoneCode,
              teamMemberName: [
                ridersTeamMember?.userFirstName,
                ridersTeamMember?.userLastName,
              ].join(' '),
              teamMemberPhoneCode: COUNTRIES_LIST.find(
                (cc) => cc.value === ridersTeamMember?.userPhoneCode
              )?.phoneCode,
            }).toObject(),
          })
        })

        dispatch(
          setSendMailLoading({
            sendMailLoading: true,
            sendMailIndex: filteredPaperworkIndex,
          })
        )

        for (const currRidersTeamMember of ridersTeamMembers) {
          const index = ridersTeamMembers.indexOf(currRidersTeamMember)
          let RoleArray: IRiderTeamMemberInterface['roles'] = currRidersTeamMember?.roles?.filter(
            (data) => {
              return data.selected
            }
          )

          let stringArray = RoleArray?.map((data) => {
            return data.label
          })

          let age = calculateAge(currRidersTeamMember.teamMemberDob)

          switch (paperwork.document_type) {
            case 'general':
              await GeneralLiabilityWaiverPDFTeamplate({
                ...data,
                ridersTeamMember: currRidersTeamMember,
                isMature: age > 18,
                clauses: allClauses,
                isSigned: true,
                stringArray,
                index,
              })
              break

            case 'usdf':
              await USDFLiabilityWaiverPDFTemplate({
                ...data,
                ridersTeamMember: currRidersTeamMember,
                isMature: age > 18,
                isSigned: true,
                stringArray,
                index,
              })
              break

            case 'usef':
              await USEFLiabilityWaiverPDFTemplate({
                ...data,
                ridersTeamMember: currRidersTeamMember,
                isMature: age > 18,
                isSigned: true,
                stringArray,
                index,
              })
              break

            case 'usea':
              await USEALiabilityWaiverPDFTeamplate({
                ...data,
                ridersTeamMember: currRidersTeamMember,
                isMature: age > 18,
                isSigned: true,
                stringArray,
                index,
              })
              break

            case 'ushja':
              await USHJALiabilityWaiverPDFTeamplate({
                ...data,
                ridersTeamMember: currRidersTeamMember,
                isMature: age > 18,
                isSigned: true,
                stringArray,
                index,
              })
              break

            case 'usefentry':
              await USEFEntryAgreementPDFNewTemplate({
                ...data,
                ridersTeamMember: currRidersTeamMember,
                isMature: age > 18,
                isSigned: true,
                stringArray,
                index,
              })
              break

            // case "usefentry":
            // promises.push(
            //   USEFEntryAgreementPDFTemplate({
            //     ...data,
            //     ridersTeamMember: currRidersTeamMember,
            //     isMature: age > 18,
            //     isowner
            //   })
            // );
            // break;
          }
        }

        const paperworkTabData__ = props.mergeRidersTeamMembersWithTabData(
          paperworkTabData_,
          ridersTeamMembers_
        )

        if (props.isManage) {
          props.setManageInfo({ ...props.manageInfo, paperwork: paperworkTabData__ })
        } else {
          props.savePaperworkTabData({ ridersTeamMembersToUpdate: ridersTeamMembers_ })
        }

        let ageOfMaturity = 18
        let dateEighteenYearsAgo = new Date()
        let paperworksToUpdate: string[] = []
        let updatedPaperworkTab: IPaperworkTab = {}

        dateEighteenYearsAgo.setFullYear(dateEighteenYearsAgo.getFullYear() - ageOfMaturity)

        paperworks?.[filteredPaperworkIndex]?.relatedPaperworks.forEach((currPaperwork) => {
          paperworksToUpdate.push(currPaperwork.paperworkKey)
        })

        updatedPaperworkTab = Object.keys(paperworkTabDataInRedux).reduce(
          (paperworkTabAcc, currPaperworkName) => {
            if (paperworksToUpdate.indexOf(currPaperworkName) !== -1) {
              paperworkTabAcc[currPaperworkName] = paperworkTabDataInRedux?.[currPaperworkName].map(
                (curr) => {
                  let curr_ = cloneDeep(curr)
                  if (curr_.signedStatus === SIGNED_STATUSES.VALUES.NOT_SENT) {
                    curr_.signedStatus = SIGNED_STATUSES.VALUES.MAIL_SENT as any
                    return curr_
                  } else return curr
                }
              )
            } else {
              paperworkTabAcc[currPaperworkName] = paperworkTabDataInRedux[currPaperworkName]
            }
            return paperworkTabAcc
          },
          updatedPaperworkTab
        )

        dispatch(setRegisterPaperworkData(updatedPaperworkTab))
        dispatch(
          setSendMailLoading({
            sendMailLoading: false,
            sendMailIndex: null,
          })
        )
      } catch (error: any) {
        helpers.logger({
          message: CustomError.somethingWentWrong({
            message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
            devMessage: error?.devMessage ?? error?.message,
            moduleName: 'handleSendMail',
            fileName: 'CompetitorEventRegisterSignTab',
          }),
        })
        dispatch(
          setSendMailLoading({
            sendMailLoading: false,
            sendMailIndex: null,
          })
        )
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /** @info Returns bool on the basis of eligibility */
  const eligibleToSign = (filteredPaperworkIndex: number) => {
    let valueToReturn
    let signatoriesPresent
    let mailSentToEveryOne
    let mailStatuses: ISignedStatuses[] = []
    let mailStatus: ISignedStatuses | null = null
    let paperworkDocument = filteredPaperworksDocuments[filteredPaperworkIndex]
    let ridersTeamMembers_ = cloneDeep(ridersTeamMembers)

    if (props.isManage) {
      const ridersTeamMembersManage: IRiderTeamMemberInterface[] = []
      props.manageInfo.paperwork.forEach((paperwork) => {
        if (paperwork.ridersTeamMembers.length > 0)
          ridersTeamMembersManage.push(...paperwork.ridersTeamMembers)
      })

      ridersTeamMembers_ = ridersTeamMembersManage
    }

    ridersTeamMembers_.forEach((currRiderTeamMember) => {
      mailStatus =
        currRiderTeamMember.mailLog.find((currMailLogItem) => {
          return currMailLogItem.paperworkKey === paperworkDocument.key
        })?.status ?? null

      if (mailStatus) mailStatuses.push(mailStatus)
      else
        mailStatuses.push(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.SIGNED_STATUS.VALUES.NOT_SENT as any)
    })

    mailSentToEveryOne = !mailStatuses.find((currMailStatus) => {
      return currMailStatus === SIGNED_STATUSES.VALUES.NOT_SENT
    })

    signatoriesPresent = !!ridersTeamMembers_.length
    valueToReturn = !mailSentToEveryOne

    return { valueToReturn, signatoriesPresent }
  }

  const getSignColumn = (
    filteredfilteredPaperworkIndex: number,
    paperworkDocument: IDocument
  ): ReactJSXElement => {
    let textToShow
    let canBeSigned = false
    let resultFromElegibleToSign = eligibleToSign(filteredfilteredPaperworkIndex)
    let loading =
      sendMailLoading.sendMailLoading &&
      sendMailLoading.sendMailIndex === filteredfilteredPaperworkIndex
    if (!resultFromElegibleToSign.signatoriesPresent) {
      textToShow = "You may haven't added signatories to sign"
    } else if (resultFromElegibleToSign.valueToReturn) {
      textToShow =
        'By clicking sign, you confirm you have read and agree to the terms and conditions in this document and annex.'
      canBeSigned = true
    } else {
      textToShow = 'Signed'
    }

    return (
      <div
        className={clsx(
          'mt-2 py-4 px-4 bg-SeabiscuitGrayThemeColor rounded-lg text-SeabiscuitDark200ThemeColor font-normal items-center mb-1 flex relative',
          resultFromElegibleToSign ? 'pr-[30px] justify-between' : 'justify-center',
          widths[2]
        )}
      >
        <div title={textToShow}>{textToShow}</div>

        {loading ? <AutorenewRounded fontSize="small" className="animate-spin mx-auto" /> : null}

        {canBeSigned && !loading ? (
          <button
            disabled={!resultFromElegibleToSign || sendMailLoading.sendMailLoading}
            className="border border-solid rounded-2xl bg-transparent border-SeabiscuitDark200ThemeColor ml-4 py-1 px-3 hover:text-white hover:bg-SeabiscuitDark200ThemeColor transition-all disabled:bg-[#ccc] disabled:text-white disabled:border-transparent"
            onClick={() => handleSendMail(filteredfilteredPaperworkIndex, paperworkDocument)}
          >
            Sign
          </button>
        ) : null}
      </div>
    )
  }

  const getAllClauses = async (ownerId: string) => {
    const data: any[] = []
    const clauses = await FirestoreService.filterItems(CLAUSE_TABLE.NAME, [
      where(CLAUSE_TABLE.FIELDS.OWNER_ID.KEY, '==', ownerId),
    ])

    if (clauses.size) {
      clauses?.forEach((doc) => {
        let clauseDataModel = UserLegalClausesModel.fromFirestoreDoc(doc).toObject()
        data.push(clauseDataModel)
      })
      return data
    }
  }

  const handleOwnerInfo = async () => {
    const getownerdoc = await FirestoreService.getItem(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS.NAME,
      eventIds
    )
    const getowner = EventModel.fromFirestoreDoc(getownerdoc).toObject()

    if (getowner) {
      if (getowner.owner) {
        getUserData(getowner.owner)
      } else {
        if (EventDetails?.owner) getUserData(EventDetails.owner)
      }
      getAllClauses(getowner?.owner as string)
        .then((res) => {
          setAllClauses(res ?? [])
        })
        .catch((err) => {
          console.log(err, 'ownerId')
        })
    } else {
      toastMethods.error({ message: 'owner not found' })
    }
  }

  useEffect(() => {
    if (router) handleOwnerInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  function addClassToDocumentTypes(text: any) {
    const words = text.split(' ')
    const updatedWords = words.map((word: any) => {
      if (DOCUMENT_TYPES.includes(word.toUpperCase())) {
        return word.toUpperCase()
      }
      return word.toLowerCase()
    })
    return updatedWords.join(' ')
  }

  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      {/* Render when the organizer do not add any paperwork in the event */}
      {!paperworksAddedByOrganizer ? (
        <DataNotAvailable
          mode="text"
          containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
          text="No Paperworks have been added to this event"
        />
      ) : null}

      {paperworksAddedByOrganizer && message ? (
        <div className="text-[#122B46]">{message}</div>
      ) : null}

      {paperworksAddedByOrganizer && !message ? (
        <>
          <header className="hidden lg:flex shrink-0 gap-3 flex-wrap mt-4">
            <div className={clsx('registration_tabs_column_title', widths[0])}>
              Document
              <Tooltip
                title={
                  <h1 className="tooltip_title">
                    Documents the event organizer requires you to sign
                  </h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>

            <div className={clsx('registration_tabs_column_title', widths[1])}>
              Review Document
              <Tooltip
                title={
                  <h1 className="tooltip_title">Click to review the document before signing</h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>

            <div className={clsx('registration_tabs_column_title', widths[1])}>
              Review Annex
              <Tooltip
                title={
                  <h1 className="tooltip_title">Click to review the document before signing</h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>

            <div className={clsx('registration_tabs_column_title', widths[2])}>
              Sign
              <Tooltip
                title={
                  <h1 className="tooltip_title">
                    Click ‘sign’ to sign electronically sign and submit this document
                  </h1>
                }
                placement="top"
                arrow
              >
                <button>
                  <TooltipIcon color="#122B46" />
                </button>
              </Tooltip>
            </div>
          </header>

          {filteredPaperworksDocuments.map((paperwork, index) => {
            return (
              <div
                key={`${JSON.stringify(paperwork)}${index}`}
                className={`border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-5 lg:p-0 flex flex-col lg:flex-row shrink-0 gap-3 text-sm text-SeabiscuitDark200ThemeColor ${index !== 0 ? 'mt-5' : ''} lg:mt-0`}
              >
                <div className="lg:hidden mb-[-15px] ml-1 font-medium text-[12px]">Document</div>
                <div
                  className={clsx(
                    'w-full lg:w-[300px] mt-2 py-4 px-4 bg-SeabiscuitGrayThemeColor font-normal rounded-lg flex flex-wrap items-center mb-1 capitalize',
                    widths[0]
                  )}
                >
                  {addClassToDocumentTypes(paperwork.document_name)}
                </div>
                <div className="lg:hidden mb-[-15px] ml-1 font-medium text-[12px]">
                  Review Document
                </div>
                <div
                  className={clsx(
                    'w-full lg:w-[135px] mt-2 py-4 px-4 bg-SeabiscuitGrayThemeColor font-normal rounded-lg flex flex-wrap items-center mb-1 cursor-pointer',
                    widths[1]
                  )}
                  title="Click to view the document"
                  onClick={() => openLightBox(index)}
                >
                  <img
                    src={'assets/og_icons/FullScreen-1.svg'}
                    alt="fullScreenIcon"
                    className="w-6 h-6 mr-3"
                  ></img>
                  View doc
                </div>
                <div className="lg:hidden mb-[-15px] ml-1 font-medium text-[12px]">
                  Review Annex
                </div>
                <div
                  className={clsx(
                    'w-full lg:w-[135px] mt-2 py-4 px-4 bg-SeabiscuitGrayThemeColor font-normal rounded-lg flex flex-wrap items-center mb-1 cursor-pointer',
                    widths[3]
                  )}
                  title="Click to view the document"
                  onClick={() =>
                    allClauses.length
                      ? props.handleModal(true, MODAL_CONSTS.REVIEW_ANNEX, {
                          ...EventDetails,
                          data: {
                            eventId: location.pathname.replace('/register/', ''),
                            eventName: EventDetails?.competitionName,
                          },
                        })
                      : null
                  }
                >
                  {allClauses && !!allClauses.length && (
                    <img
                      src={'assets/og_icons/FullScreen-1.svg'}
                      alt="fullScreenIcon"
                      className="w-6 h-6 mr-3"
                    ></img>
                  )}
                  {allClauses && allClauses.length ? 'View Annex' : 'No Annex'}
                </div>
                <div className="lg:hidden mb-[-15px] ml-1 font-medium text-[12px]">Sign</div>
                {getSignColumn(index, paperwork)}
              </div>
            )
          })}
        </>
      ) : null}
    </CompetitorEventRegisterWrapper>
  )
}

export default CompetitorEventRegisterSignTab
