import { useState } from 'react'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import TabList from './components/tabs/TabList'
import DraftNews from './components/news/DraftNews'
import LiveNews from './components/news/LiveNews'

const VendorNews = () => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <WrapperContainer title="News" className="">
      <div className="flex flex-col gap-[30px]">
        <TabList activeTab={activeTab} setActiveTab={setActiveTab} />

        {activeTab === 0 ? <LiveNews /> : <DraftNews />}
      </div>
    </WrapperContainer>
  )
}

export default VendorNews
