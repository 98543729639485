import { TableStyles } from 'react-data-table-component'

export const tableStyles: TableStyles = {
  rows: {
    style: {
      // minHeight: '72px', // override the row height
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  headCells: {
    style: {
      paddingLeft: '0px', // override the cell padding for head cells
      paddingRight: '0px',
      color: '#122B46',
      fontWeight: '600',
      fontSize: '12px',
    },
  },
  headRow: {
    style: {
      minHeight: 'unset',
      borderColor: '#D3DAEE',
      paddingBottom: 0,
    },
  },
  cells: {
    style: {
      paddingLeft: '0px', // override the cell padding for data cells
      paddingRight: '0px',
      Text: 'center',
    },
  },
}
