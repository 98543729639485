export const grandTotal = [
  {
    lineItem: 'Classes',
    total: 75,
  },
  {
    lineItem: 'Fees',
    total: 125,
  },
  {
    lineItem: 'Tickets',
    total: 554,
  },
  {
    lineItem: 'Sales tax',
    total: 435,
  },
]
