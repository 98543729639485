import { where } from 'firebase/firestore'
import { useContext, useEffect, useRef, useState } from 'react'
import helpers from '../../../../commonHelpers/helpers'
import { CONST } from '../../../../const/const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { CustomError, getUserFullName } from '../../../../helpers/helpers'
import { IUserDocument } from '../../../../models/user-documents/user-documents.interface'
import { UserDocumentModel } from '../../../../models/user-documents/user-documents.model'
import { IUserInterface } from '../../../../models/users/user.interface'
import { UserModel } from '../../../../models/users/user.model'
import FirestoreService from '../../../../services/firestoreService'
import { EventStaffModel } from '../../../../models/event-staff/event-staff.model'
import { IEventStaffInterface } from '../../../../models/event-staff/event-staff.interface'
import { cloneDeep } from 'lodash'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { ILightboxProps } from '../../../../types/competitor_types'
import usePdfToImage from '../../../../hooks/usePdfToImage'

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

type IFetchPageDataArgs = {
  staffId: string
  eventId: string
  staffRole: string
  isOtherStaff: boolean
}

type IStaffDetails = {
  staffId: string
  eventId: string
  staffRole: string
  isOtherStaff: boolean
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const useStaffProfile = () => {
  // Hooks and vars
  const toastFunctions = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)

  const { convert } = usePdfToImage()
  const eventIdRef = useRef<string | null>(null)

  const [loading, setLoading] = useState(false)
  const [isElligible, setIsElligible] = useState(false)
  const [showLightBox, setShowLightBox] = useState(false)
  const [isStaffScratched, setIsStaffScratched] = useState(false)
  const [documents, setDocuments] = useState<IUserDocument[]>([])
  const [profile, setProfile] = useState<IUserInterface | null>(null)
  const [activeTab, setActiveTab] = useState<string>(CONST.UI.STAFF.TABS[0])
  const [areAllPaperworksSigned, setareAllPaperworksSigned] = useState(false)
  const [staffDetails, setStaffDetails] = useState<IStaffDetails | null>(null)
  const [lightBoxImages, setLightBoxImages] = useState<ILightboxProps['slides']>([])
  const [eventStaffDoc, setEventStaffDoc] = useState<IEventStaffInterface | null>(null)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    let areareAllPaperworksSigned = documents.every((currDoc) => currDoc.status === 'Signed')
    setIsElligible(areareAllPaperworksSigned)
    setareAllPaperworksSigned(areareAllPaperworksSigned)
  }, [documents])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!eventStaffDoc || !staffDetails) return setIsStaffScratched(false)

    onStaffDocUpdate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventStaffDoc, staffDetails])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  function onStaffDocUpdate() {
    let scratched = false
    let scratchedValues: string[] = []

    if (!staffDetails) return

    if (staffDetails?.isOtherStaff && eventStaffDoc?.otherStaff)
      eventStaffDoc?.otherStaff?.forEach((currStaff) => {
        scratchedValues = currStaff?.scratchedValues ?? []
        if (currStaff.title === staffDetails.staffRole)
          scratched = !!scratchedValues.find(
            (currScratchedStaffId) => currScratchedStaffId === staffDetails.staffId
          )
      })
    else if (!staffDetails?.isOtherStaff && eventStaffDoc?.eventStaff)
      eventStaffDoc?.eventStaff?.forEach((currStaff) => {
        scratchedValues = currStaff?.scratchedValues ?? []
        if (currStaff.title === staffDetails.staffRole)
          scratched = !!scratchedValues.find(
            (currScratchedStaffId) => currScratchedStaffId === staffDetails.staffId
          )
      })

    setIsStaffScratched(scratched)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const updateStaffDocState = (eventStaffDoc_: IEventStaffInterface | null) => {
    setEventStaffDoc(eventStaffDoc_)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async function getEventStaffDoc(eventId: string) {
    let eventStaffDoc_ = cloneDeep(eventStaffDoc)

    try {
      if (!eventId)
        throw CustomError.somethingWentWrong({
          lineNumber: 67,
          fileName: 'useStaffListingRoot',
          devMessage: `eventId is ${eventId}`,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      eventIdRef.current = eventId

      const staffSnap = await FirestoreService.getItem(COLLECTIONS.EVENT_STAFF.NAME, eventId)

      if (!staffSnap.exists())
        throw CustomError.somethingWentWrong({
          lineNumber: 82,
          fileName: 'useStaffListingRoot',
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: `Could not find event staff for event ${eventId}`,
        })

      const staffs = EventStaffModel.fromFirestoreDoc(staffSnap).toObject()
      eventStaffDoc_ = staffs
    } catch (error: any) {
      toastFunctions.error({
        message: error?.message,
      })

      helpers.logger({
        message: error,
      })
    } finally {
      return eventStaffDoc_
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const closeLightBox = () => {
    setLightBoxImages([])
    setShowLightBox(false)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const openLightBox = async (images: ILightboxProps['slides']) => {
    if (!images?.[0]?.src)
      return toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    const images_ = (await convert(images?.[0]?.src))?.imagesList ?? []
    setLightBoxImages(
      images_.map((currImage, index) => ({
        src: currImage,
        title: `Image ${index + 1}`,
      }))
    )
    setShowLightBox(true)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getStaffProfile = async (staffId: string) => {
    let user: IUserInterface | null = null

    try {
      if (!staffId)
        throw CustomError.somethingWentWrong({
          lineNumber: 120,
          fileName: 'useStaffProfile',
          devMessage: `staffId is ${staffId}`,
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })

      const userSnap = await FirestoreService.getItem(COLLECTIONS.USERS.NAME, staffId)

      if (!userSnap.exists())
        throw CustomError.somethingWentWrong({
          lineNumber: 61,
          fileName: 'useStaffProfile',
          message: 'Staff not found',
          devMessage: `Staff with id:${staffId} not found`,
        })

      user = UserModel.fromFirestoreDoc(userSnap).toObject()
    } catch (error) {
      throw error
    } finally {
      return user
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onTabClick = (tabName: string) => {
    setActiveTab(tabName)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getUserPaperworks = async (staffId: string, eventId: string) => {
    let documents: IUserDocument[] = []

    try {
      const documentSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS_DOCUMENTS.NAME, [
        where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.DOCUMENT_OWNER.KEY, '==', staffId),
        where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.EVENT_ID.KEY, '==', eventId),
      ])

      documentSnaps.docs.forEach((currDoc) => {
        documents.push(UserDocumentModel.fromFirestoreDoc(currDoc).toObject())
      })
    } catch (error: any) {
      helpers.logger(
        CustomError.somethingWentWrong({
          lineNumber: 187,
          message: error.message,
          devMessage: error.message,
          fileName: 'useStaffProfile',
        })
      )
    } finally {
      return documents
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const checkDoesExistsInStaffDoc = (
    staffDoc: IEventStaffInterface | null,
    staffDetails: IFetchPageDataArgs
  ) => {}

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const fetchPageData = async (args: IFetchPageDataArgs) => {
    let { staffId, eventId } = args

    setStaffDetails(args)
    setLoading(true)

    try {
      const staffDoc = await getEventStaffDoc(eventId)
      checkDoesExistsInStaffDoc(staffDoc, args)
      const profile = await getStaffProfile(staffId)
      const documents = await getUserPaperworks(staffId, eventId)

      setProfile(profile)
      setDocuments(documents)
      setEventStaffDoc(staffDoc)
    } catch (error: any) {
      toastFunctions.error({
        message: error,
      })
    }

    setLoading(false)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onScratchButtonClick = () => {
    handleModalContext?.handleModal?.(true, MODAL_CONSTS.SCRATCH_STAFF_MODAL, {
      eventStaffDoc,
      user: {
        ...(staffDetails ?? {}),
        userProfilePicture: profile?.userProfilePicture,
        staffName: profile ? getUserFullName(profile) : 'Unknown',
      },
      updateStaffDocState,
    })
  }

  return {
    profile,
    loading,
    documents,
    activeTab,
    isElligible,
    onTabClick,
    openLightBox,
    eventStaffDoc,
    closeLightBox,
    showLightBox,
    fetchPageData,
    lightBoxImages,
    isStaffScratched,
    updateStaffDocState,
    onScratchButtonClick,
    areAllPaperworksSigned,
  }
}

export default useStaffProfile
