// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const Fading = () => {
  return (
    <div className="md:h-[250px] h-[200px] w-full absolute z-[2] bg-gradient-to-t from-white to-transparent to-opacity-50 pointer-events-none opacity-80 bottom-0"></div>
  )
}

export default Fading
