import React, { useState } from 'react'
import { MODAL_CONSTS } from '../../../const/modal-const'
import MainModal from '../../modals/common/MainModal'
import { IconUser } from '../../icons/IconUser'
import { IconEmailImg } from '../../icons/IconEmailImg'
import { IconDate } from '../../icons/IconDate'
import { NationalityIconImg } from '../../icons/NationalityIconImg'
import { IconHorseImg } from '../../icons/IconHorseImg'
import { IconError } from '../../icons/IconError'
import { InputUploadMemberPictureRow } from '../../inputs/InputUploadMemberPictureRow'
import { InputNewMemberRow, IOption } from '../../inputs/InputNewMemberRow'
import { CountryList } from '../../../fakeData/countryList'
import { disciplineData } from '../../../fakeData/disciplineList'
import { InputCheckMemberInfo } from '../../inputs/InputCheckMemberInfo'
import { isValidEmail } from '../../../helpers/isValidEmail'
import { IInputData } from '../types/inputData'
import { CONST, USER_DEF_PASS } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import FirebaseStorageService from '../../../services/storageService'
import FirestoreService from '../../../services/firestoreService'
import helpers from '../../../commonHelpers/helpers'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { httpService } from '../../../services/httpService'
import { IRegistrationTeamInterface } from '../../../models/registeration-teams/registration-teams.interface'
import { getConvertedData, getSelectedUserAsTeamMember } from '../../../models/interface.helper'
import { selectTeamTabData, setTeamTabData } from '../../../store/registration/registrationSlice'
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
  getAuth,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { RootState } from '../../../store/store'
import useProfileHook from '../../../hooks/users/competitor/profile/useProfileHook'
import { authHelpers, getUserFullName } from '../../../helpers/helpers'
import { UserModel } from '../../../models/users/user.model'
import {
  setCompetitionProfileImageUrl,
  setDisplayName,
  setEmail,
  setIsLoggedIn,
  setUserId,
  setUsername,
  storeUserId,
} from '../../../store/user/userSlice'
import { storeUseMode } from '../../../store/system/systemThunk'
import FIREBASE_CONST from '../../../const/firebase-const'
import { useHistory } from 'react-router'
import { selectEventDetails } from '../../../store/events/eventsSlice'

const auth = getAuth()

type Props = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: any
}

export const AddNewTeamMemberModal = (props: Props) => {
  const userIcon = '/assets/img/User2.png'
  const [picture, setPicture] = React.useState('')
  const [inputData, setInputDate] = React.useState<IInputData>({
    name: {
      name: 'name',
      value: '',
      required: true,
      placeholder: 'Enter name...',
      type: 'text',
      label: 'Name',
      icon: <IconUser />,
    },
    email: {
      name: 'email',
      value: '',
      required: true,
      placeholder: 'Enter address...',
      type: 'email',
      label: 'Email address',
      icon: <IconEmailImg />,
    },
    date: {
      name: 'date',
      value: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      required: true,
      placeholder: 'Select date...',
      type: 'date',
      icon: <IconDate />,
      label: 'Date of birth',
    },
    nationality: {
      name: 'nationality',
      value: '',
      required: false,
      placeholder: 'Select nationality...',
      type: 'select',
      label: 'Nationality',
      icon: <NationalityIconImg />,
      selectData: CountryList,
    },
    discipline: {
      name: 'discipline',
      value: '',
      required: false,
      placeholder: 'Select discipline....',
      type: 'select',
      label: 'Discipline',
      icon: <IconHorseImg />,
      selectData: disciplineData,
    },
  })
  const [isValid, setIsValid] = React.useState(false)
  const [isConfirmed, setIsConfirmed] = React.useState(false)
  const toastFunctions = useToasterHelper()
  const [imgLoading, setImgLoading] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const { profileDetails } = useAppSelector((state: RootState) => state.user)
  const { getModelledUserDetails } = useProfileHook({ dontFetch: true })
  const teamMembers = useAppSelector(selectTeamTabData)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const event = useAppSelector(selectEventDetails)

  React.useEffect(() => {
    const checkValidity = () => {
      for (const key in inputData) {
        const field = inputData[key]
        if (field.required && !field.value) {
          return false
        }
      }
      return true
    }

    setIsValid(isConfirmed && checkValidity())
  }, [inputData, isConfirmed])

  const onChangeHandler = (key: string, value: string | Date | IOption) => {
    const newInputData = { ...inputData }
    if (newInputData[key]) {
      newInputData[key].value = value
      newInputData[key].hasError = false
      setInputDate(newInputData)
    }
  }

  const handleOnLoginValid = async (data: { email: string; password: string }) => {
    try {
      const emailOrError = await authHelpers.getEmail(data.email)

      // if (emailOrError instanceof CustomError) {
      //   // setLoading(false)
      //   // return toastFunctions.error({ message: emailOrError.message })
      // }

      const user = await signInWithEmailAndPassword(auth, emailOrError as any, USER_DEF_PASS)

      if (user) {
        const user_data_doc: any = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
          user.user.uid
        )

        const userData = UserModel.fromFirestoreDoc(user_data_doc).toObject()

        dispatch(setEmail(userData.userEmail?.trim()))
        dispatch(setUsername(userData.userName?.trim()))
        dispatch(setDisplayName(getUserFullName(userData)))
        dispatch(setCompetitionProfileImageUrl(userData.userProfilePicture))

        if (userData.userType === CONST.USE_MODE.COMPETITOR) {
          dispatch(storeUseMode(CONST.USE_MODE.COMPETITOR))
          if (event.id) {
            history.replace(`${CONST.ROUTES.REGISTER_EVENT.URL}/${event.id}/${userData.id}`, {
              direction: 'none',
            })
          } else {
            history.replace('/home', { direction: 'none' })
          }
        } else if (userData.userType === CONST.USE_MODE.ORGANIZER) {
          dispatch(storeUseMode(CONST.USE_MODE.ORGANIZER))
          history.replace(`${CONST.ROUTES.ORGANIZER_HOME.URL}`, {
            direction: 'none',
          })
        }

        dispatch(setIsLoggedIn(true))
        dispatch(storeUserId(user.user.uid))
        dispatch(setUserId(user.user.uid))
      }
    } catch (error: any) {
      console.log({ error })
      setLoading(false)
      if (error.code === FIREBASE_CONST.USER_NOT_FOUND)
        return toastFunctions.error({
          message: "This account doesn't exist. Please sign up or try again",
        })
      if (error.code === FIREBASE_CONST.WRONG_PASSWORD)
        return toastFunctions.error({ message: 'Credentials are incorrect' })

      // toastFunctions.error({ message: 'Something went wrong' })
    } finally {
      setLoading(false)
    }
  }

  const submitOnlyMemberHandler = async (
    isSetLoading?: boolean,
    fromHorse?: boolean
  ): Promise<{
    id: string | null
    email: string | null
  } | null> => {
    const { email, name, date, nationality, discipline } = inputData

    setLoading(true)

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        `${email.value}`,
        USER_DEF_PASS
      )
      if (props.dataToPassOn.shouldBeLoggedIn) await signOut(auth)

      const user = userCredential.user

      await updateProfile(userCredential.user, {
        displayName: `${name.value}`,
      })

      const resp = await httpService({
        url: `on_auth_user_create`,
        method: 'POST',
        data: {
          uuid: user.uid,
          userName: `${name.value}`,
          email: `${email.value}`,
          userType: 'competitor',
        },
      })

      if (!resp.data.user) {
        alert('No User')
        return null
      }
      const respUser = resp.data.user

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        respUser.id,
        {
          userProfilePicture: picture,
          userName: `${name.value}`,
          userDOB: date.value,
          userNationality: (nationality?.value as IOption).label || '',
          userDiscipline: (discipline?.value as IOption).label || '',
        }
      )

      const dataTOUpdate = {
        defaultRole: profileDetails.userDefaultRole,
        memberAddress: profileDetails.userAddress,
        memberAuthorized: '1',
        memberCountry: profileDetails.userNationality,
        memberDob: profileDetails.userDOB,
        memberEmail: profileDetails.userEmail,
        memberId: profileDetails.id,
        memberName: profileDetails.userFirstName,
        memberPhoneNumber: profileDetails.userPhoneNumber,
        memberProfilePicture: profileDetails.userProfilePicture,
        memberRole: CONST.UI.TEAM_MEMBERS_ROLES.TEAM_MEMBER,
        memberSafeSupportTraining: false,
        memberShipActive: false,
        memberStatus: '1',
        memberUserName: profileDetails.userName,
        memberprofileSynced: false,
        newSelected: true,
        selected: true,
        signedStatus: 'Not sent',
      }

      const structuredData = getModelledUserDetails(respUser)

      if (Array.isArray(structuredData.userTeamMembers)) {
        structuredData.userTeamMembers.push(dataTOUpdate)
      } else {
        structuredData.userTeamMembers = [dataTOUpdate]
      }

      const data = {
        // ...structuredData,
        userProfilePicture: props.dataToPassOn.userPicture || '',
        userName: `${name.value}`,
        userDOB: date.value || '',
        userNationality: (nationality?.value as IOption).label || '',
        userDiscipline: (discipline?.value as IOption).label || '',
      }

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        respUser.id,
        data
      )

      respUser.userProfilePicture = props.dataToPassOn.userPicture
      const teamData: IRegistrationTeamInterface[] = []
      teamData.push(getConvertedData(getSelectedUserAsTeamMember(respUser, profileDetails)))
      dispatch(setTeamTabData([...teamMembers, ...teamData]))
      props.handleModal(false, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER)
      isSetLoading && toastFunctions.success({ message: MESSAGES_CONST.TEAM_MEMBER_CREATED })
      props.handleModal(false, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)
      if (props.dataToPassOn.shouldBeLoggedIn && !fromHorse)
        await handleOnLoginValid({
          email: `${email.value}`,
          password: USER_DEF_PASS,
        })

      return {
        email: `${email.value}`,
        id: respUser.id || null,
      }
    } catch (e) {
      console.log('=>(AddNewTeamMemberModal.tsx:122) e', e)
      // toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })

      return null
    } finally {
      isSetLoading && setLoading(false)
    }
  }

  const submitHandler = () => {
    const { email } = inputData
    if (!email || !email.value || !isValidEmail(`${email.value}`)) {
      const newInputData = { ...inputData }
      newInputData['email'].hasError = true
      setInputDate(newInputData)
      toastFunctions.error({
        message: 'Invalid email address',
      })
      return null
    }

    if (props.dataToPassOn.shouldBeLoggedIn) {
      submitOnlyMemberHandler().then(() => null)
    } else {
      props.handleModal(false, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER)
      props.handleModal(true, MODAL_CONSTS.ADD_NEW_HORSE_MEMBER, {
        inputData,
        userPicture: picture,
        submitOnlyMemberHandler,
        createCb: props.dataToPassOn.createCb,
      })
    }
  }

  const updateProfilePic = async (event: any) => {
    const files = event.target.files
    const file = files[0]
    const PATH = CONST.DATA.STORAGE.USERS.COMPETITOR_PROFILE_IMAGE_URL.PREFIX

    if (!file) return toastFunctions.info({ message: MESSAGES_CONST.NO_FILE_SELECTED })

    setImgLoading(true)

    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(
        file,
        `${PATH}/${new Date().getTime()}`,
        (percent: number) => {
          if (0) console.log(percent)
        }
      )
      if (downloadUrl) {
        setPicture(`${downloadUrl}`)
      }

      toastFunctions.success({ message: MESSAGES_CONST.PIC_UPDATED })
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setImgLoading(false)
    }
  }

  return (
    <>
      <MainModal
        title="Add new team member"
        show={props.show}
        type="ADD_NEW_TEAM_MEMBER"
        size="md"
        onTransitionEnd={() => null}
        titleClassName="!font-normal"
        buttons={[
          {
            loading,
            label: 'NEXT >',
            bgClass: 'bg-SeabiscuitMainThemeColor',
            onClick: submitHandler,
            className: 'outline-none !w-full',
            textClass: 'text-white',
            disabled: !isValid,
          },
          {
            label: 'CANCEL',
            bgClass: 'bg-SeabiscuitLightThemeColor',
            className: 'outline-none !w-full',
            borderClass: 'border border-transparent',
            textClass: 'text-SeabiscuitLightTextColor',
            onClick: () => props.handleModal(false, MODAL_CONSTS.ADD_NEW_TEAM_MEMBER),
          },
        ]}
      >
        <div className={'flex flex-col gap-4 mt-4'}>
          <div className={'flex flex-col gap-2 border-b border-[#D3DAEE] pb-4'}>
            <InputUploadMemberPictureRow
              title={'Profile picture'}
              isLoading={imgLoading}
              picture={picture}
              onchangeHandler={updateProfilePic}
              userIcon={userIcon}
            />
            {Object.keys(inputData).map((key) => (
              <InputNewMemberRow
                key={key}
                value={inputData[key].value}
                onChangeHandler={onChangeHandler}
                placeholder={inputData[key].placeholder}
                inputName={inputData[key].name}
                name={inputData[key].label}
                isError={inputData[key].hasError}
                icon={inputData[key].icon}
                isRequired={inputData[key].required}
                type={inputData[key].type}
                selectData={inputData[key].selectData || []}
              />
            ))}
          </div>

          <InputCheckMemberInfo
            isChecked={isConfirmed}
            onClick={() => setIsConfirmed(!isConfirmed)}
            text={
              'By ticking this box I confirm that I have been given permission to create a Pegasus\n' +
              '                account on behalf of this person, and I am authorized to agree to Pegasus’ terms and\n' +
              '                conditions on their behalf, as well as register them for events.'
            }
          />

          <div className={'flex mt-4 gap-4 bg-[#F6F7FB] py-[12px] px-[20px] rounded-[12px]'}>
            <div className={'mt-1'}>
              <IconError />
            </div>

            <p className={'flex-1 text-[#122B46] text-[14px] m-0'}>
              The email address listed above will be notified of this account creation and provided
              with login details to join Pegasus and complete their profile.
            </p>
          </div>
        </div>
      </MainModal>
    </>
  )
}
