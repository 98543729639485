export const horseMarkingList = [
  { label: 'Bald Face', value: 'baldface' },
  { label: 'Blaze', value: 'blaze' },
  { label: 'Coronet', value: 'coronet' },
  { label: 'Pastern', value: 'pastern' },
  { label: 'Snip', value: 'snip' },
  { label: 'Sock', value: 'sock' },
  { label: 'Star', value: 'star' },
  { label: 'Strip', value: 'strip' },
  { label: 'Stocking', value: 'stocking' },
]
