import { CONST } from '../../const/const'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IEventPaymentSettingsInterface } from './event-payment-settings.interface'
import * as yup from 'yup'

// Constants
const COLLECTION = CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_PAYMENT_SETTINGS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
class EventPaymentSettingsModel
  extends ModelBaseModel<IEventPaymentSettingsInterface>
  implements IModelBaseModel
{
  public u: IEventPaymentSettingsInterface['u']
  public v: IEventPaymentSettingsInterface['v']
  public id: IEventPaymentSettingsInterface['id']
  public paymentOption: IEventPaymentSettingsInterface['paymentOption']
  public allowRegistrationWithoutPayment: IEventPaymentSettingsInterface['allowRegistrationWithoutPayment']
  public chargeSalesTax: IEventPaymentSettingsInterface['chargeSalesTax']

  public created: IEventPaymentSettingsInterface['created']
  public modified: IEventPaymentSettingsInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  constructor(object?: IEventPaymentSettingsInterface) {
    super()
    this.id = object?.id ?? COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.u = object?.u ?? COLLECTION.FIELDS.UPDATE.VALUES.DEFAULT
    this.v = object?.v ?? COLLECTION.FIELDS.VERSION.VALUES.DEFAULT
    this.paymentOption = object?.paymentOption ?? COLLECTION.FIELDS.PAYMENT_OPTION.VALUES.DEFAULT
    this.allowRegistrationWithoutPayment =
      object?.allowRegistrationWithoutPayment ??
      COLLECTION.FIELDS.ALLOW_REGISTRATION_WITHOUT_PAYMENT.VALUES.DEFAULT
    this.chargeSalesTax =
      object?.chargeSalesTax ?? COLLECTION.FIELDS.CHARGE_SALES_TAX.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: object?.created!,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: object?.modified!,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new EventPaymentSettingsModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static validationSchema() {
    return yup.object({
      paymentOption: yup
        .string()
        .oneOf(['LeavePricesAsIs', 'IncreaseAllPrices', 'ChargeRidersAndSpectators', null])
        .nullable(),
      allowRegistrationWithoutPayment: yup.bool().required(),
      chargeSalesTax: yup.bool().required(),
    })
  }
}

export default EventPaymentSettingsModel
