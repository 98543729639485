// ############################################################
/**
 * @todo Document this
 */
// ############################################################

export const EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST = {
  DISPLAY_CONTROL: {
    VALUES: {
      DISCIPLINE: 'Discipline',
      DIVISIONS: 'Division',
      SECTIONS: 'Sections',
      CLASSES: 'Classes',
    },
  },
  ROW: {
    TYPE: {
      HUNTER: 'Hunter',
    },
  },
}
