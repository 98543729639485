export const myEventsDTTabs = [
  {
    tab: 1,
    tabTitle: 'Registered',
    subTitle: 'Events you have registered to attend',
  },
  {
    tab: 2,
    tabTitle: 'Wait List',
    subTitle: 'Events you are wait listed for',
  },
  {
    tab: 3,
    tabTitle: 'Drafts',
    subTitle: 'Events you started, but did not complete registration for',
  },
  {
    tab: 4,
    tabTitle: 'Bookmarked',
    subTitle: 'Events you have bookmarked for future consideration',
  },
  {
    tab: 5,
    tabTitle: 'Tickets',
    subTitle: 'View and share purchased tickets',
  },
  // {
  //     tab: 6,
  //     tabTitle: "Tickets Assigned",
  //     subTitle: "View tickets assigned to you",
  // }
]
