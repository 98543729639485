import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { IRefundInterace } from './refund.interface'
import { CONST } from '../../const/const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const REFUNDS_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.REFUNDS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @todo Document this
 */

export class RefundModel extends ModelBaseModel<IRefundInterace> implements IModelBaseModel {
  // Vars
  public id: IRefundInterace['id']
  public comment: IRefundInterace['comment']
  public payerId: IRefundInterace['payerId']
  public eventId: IRefundInterace['eventId']
  public chargeId: IRefundInterace['chargeId']
  public refundId: IRefundInterace['refundId']
  public updatedAt: IRefundInterace['updatedAt']
  public createdAt: IRefundInterace['createdAt']
  public amountPaid: IRefundInterace['amountPaid']
  public eventOwnerId: IRefundInterace['eventOwnerId']
  public refundReason: IRefundInterace['refundReason']
  public refundedAt: IRefundInterace['refundedAt']
  public invoiceUrl: IRefundInterace['invoiceUrl']
  public refundAmount: IRefundInterace['refundAmount']
  public refundStatus: IRefundInterace['refundStatus']
  public recipientName: IRefundInterace['recipientName']
  public transactionId: IRefundInterace['transactionId']
  public registrationId: IRefundInterace['registrationId']
  public recipientDocId: IRefundInterace['recipientDocId']
  public recipientUserId: IRefundInterace['recipientUserId']
  public transactionRefId: IRefundInterace['transactionRefId']
  public recipientNameNGrams: IRefundInterace['recipientNameNGrams']
  public eventOwnerConnectId: IRefundInterace['eventOwnerConnectId']
  public recipientProfilePicture: IRefundInterace['recipientProfilePicture']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  public constructor(obj: IRefundInterace) {
    super()
    this.id = obj.id ?? null
    this.chargeId = obj.chargeId ?? REFUNDS_COLLECTION.FIELDS.CHARGE_ID.DEFAULT
    this.payerId = obj.payerId ?? REFUNDS_COLLECTION.FIELDS.PAYER_ID.DEFAULT
    this.eventId = obj.eventId ?? REFUNDS_COLLECTION.FIELDS.EVENT_ID.DEFAULT
    this.refundId = obj.refundId ?? REFUNDS_COLLECTION.FIELDS.REFUND_ID.DEFAULT
    this.comment = obj?.comment ?? REFUNDS_COLLECTION.FIELDS.REFUND_COMMENT.DEFAULT
    this.amountPaid = this.getNum(obj.amountPaid ?? REFUNDS_COLLECTION.FIELDS.AMOUNT_PAID.DEFAULT)
    this.refundAmount = this.getNum(
      obj.refundAmount ?? REFUNDS_COLLECTION.FIELDS.REFUND_AMOUNT.DEFAULT
    )
    this.refundStatus = obj.refundStatus ?? REFUNDS_COLLECTION.FIELDS.REFUND_STATUS.DEFAULT
    this.refundReason = obj.refundReason ?? REFUNDS_COLLECTION.FIELDS.REFUND_REASON.DEFAULT
    this.recipientName = obj.recipientName ?? REFUNDS_COLLECTION.FIELDS.RECIPIENT_NAME.DEFAULT
    this.eventOwnerId = obj.eventOwnerId ?? REFUNDS_COLLECTION.FIELDS.EVENT_OWNER_ID.DEFAULT
    this.transactionId = obj.transactionId ?? REFUNDS_COLLECTION.FIELDS.TRANSACTION_ID.DEFAULT
    this.refundedAt = obj.refundedAt ?? REFUNDS_COLLECTION.FIELDS.REFUNDED_AT.VALUES.DEFAULT
    this.invoiceUrl = obj.invoiceUrl ?? REFUNDS_COLLECTION.FIELDS.INVOICE_URL.VALUES.DEFAULT
    this.recipientDocId = obj.recipientDocId ?? REFUNDS_COLLECTION.FIELDS.RECIPIENT_DOC_ID.DEFAULT
    this.registrationId = obj.registrationId ?? REFUNDS_COLLECTION.FIELDS.REGISTRATION_ID.DEFAULT
    this.recipientUserId =
      obj.recipientUserId ?? REFUNDS_COLLECTION.FIELDS.RECIPIENT_USER_ID.DEFAULT
    this.transactionRefId =
      obj.transactionRefId ?? REFUNDS_COLLECTION.FIELDS.TRANSACTION_REF_ID.DEFAULT
    this.recipientNameNGrams =
      obj.recipientNameNGrams ?? REFUNDS_COLLECTION.FIELDS.RECIPIENT_NAME_N_GRAMS.DEFAULT
    this.eventOwnerConnectId =
      obj.eventOwnerConnectId ?? REFUNDS_COLLECTION.FIELDS.EVENT_OWNER_CONNECT_ID.DEFAULT
    this.recipientProfilePicture =
      obj.recipientProfilePicture ?? REFUNDS_COLLECTION.FIELDS.RECIPIENT_PROFILE_PICTURE.DEFAULT

    this.createdAt = this.utcTimestamp({
      key: 'createdAt',
      isTimestamp: true,
      value: obj?.createdAt,
    })

    this.updatedAt = this.utcTimestamp({
      key: 'updatedAt',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.updatedAt,
    })
  }

  handleArrays(value: any, defaultValue: any) {
    if (!value || !Array.isArray(value)) return defaultValue
    return value
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  static fromFirestoreDoc(doc: any) {
    return new RefundModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
