import { v4 as uuidv4 } from 'uuid'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { ISpectatorTickets } from './spectator-tickets.interface'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @todo Document this
 */

export class SpectatorTicketModel
  extends ModelBaseModel<ISpectatorTickets>
  implements IModelBaseModel
{
  // Vars
  public id: ISpectatorTickets['id']
  public userId: ISpectatorTickets['userId']
  public userName: ISpectatorTickets['userName']
  public eventDocId: ISpectatorTickets['eventDocId']
  public ticketPrice: ISpectatorTickets['ticketPrice']
  public isPlatformUser: ISpectatorTickets['isPlatformUser']
  public userEmailId: ISpectatorTickets['userEmailId']
  public recipientProfilePicture: ISpectatorTickets['recipientProfilePicture']
  public ticketsCountAgainstSpectatorDoc: ISpectatorTickets['ticketsCountAgainstSpectatorDoc']
  public selectedUnitsCount: ISpectatorTickets['selectedUnitsCount']
  public sharedTicketsCount: ISpectatorTickets['sharedTicketsCount']
  public boughtTicketCount: ISpectatorTickets['boughtTicketCount']
  public ticketItemId: ISpectatorTickets['ticketItemId']
  public ticketTitle: ISpectatorTickets['ticketTitle']
  public ticketBuyerDocId: ISpectatorTickets['ticketBuyerDocId']
  public paymentStatus: ISpectatorTickets['paymentStatus']
  public transactionDocId: ISpectatorTickets['transactionDocId']
  public purchasedOn: ISpectatorTickets['purchasedOn']
  public created: ISpectatorTickets['created']
  public modified: ISpectatorTickets['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  public constructor(obj?: ISpectatorTickets) {
    super()
    this.id = obj?.id
    this.userId = obj?.userId ?? null
    this.userName = obj?.userName ?? null
    this.ticketPrice = obj?.ticketPrice ?? 0
    this.eventDocId = obj?.eventDocId ?? null
    this.userEmailId = obj?.userEmailId ?? null
    this.purchasedOn = obj?.purchasedOn ?? null
    this.ticketTitle = obj?.ticketTitle ?? null
    this.ticketItemId = obj?.ticketItemId ?? null
    this.isPlatformUser = obj?.isPlatformUser ?? false
    this.ticketBuyerDocId = obj?.ticketBuyerDocId ?? null
    this.paymentStatus = obj?.paymentStatus ?? 'pending'
    this.boughtTicketCount = obj?.boughtTicketCount ?? 0
    this.selectedUnitsCount = obj?.selectedUnitsCount ?? 0
    this.sharedTicketsCount = obj?.sharedTicketsCount ?? 0
    this.transactionDocId = obj?.transactionDocId ?? null
    this.recipientProfilePicture = obj?.recipientProfilePicture ?? null
    this.ticketsCountAgainstSpectatorDoc = obj?.ticketsCountAgainstSpectatorDoc ?? 0

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  static fromFirestoreDoc(doc: any) {
    return new SpectatorTicketModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static getUuid(uuid?: any) {
    let uuid_ = ''
    if (typeof uuid !== 'string') uuid_ = uuidv4()
    else uuid_ = uuid
    uuid_ = uuid_.trim()
    return uuid_
  }
}
