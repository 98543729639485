import React from 'react'
import { Clear } from '@mui/icons-material'
import AuthenticationModalLoginComponent from '../modals/authenticationModalComponent/AuthenticationModalLoginComponent'
import AuthenticationModalSignupComponent from '../modals/authenticationModalComponent/AuthenticationModalSignupComponent'
import { useParams } from 'react-router-dom'
import ITypes from '../../pages/competitor/event-registration-tabs/hooks/useEventRegistrationTabs.types'
import { CONST } from '../../const/const'
import { useAppSelector } from '../../store/hooks'
import { selectUserId } from '../../store/user/userSlice'

interface IProps {
  registerHandler: () => void
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
}

export const Authentication: React.FC<IProps> = ({ registerHandler, handleModal }) => {
  const { eventId } = useParams<ITypes['IParams']>()
  const userId = useAppSelector(selectUserId)

  return (
    <>
      <div className={`max-h-[463px] bg-white px-4 pt-4 rounded-xl z-10 flex flex-col`}>
        <div className="max-h-screen min-h-[calc(100vh-200px)]">
          <h2 className="m-0 text-[25px] text-center text-SeabiscuitDark200ThemeColor font-bold">
            Log in to your account
          </h2>

          <div className="p-4">
            <AuthenticationModalLoginComponent
              closeAuthModal={() => null}
              handleModal={handleModal}
              registerHandler={registerHandler}
              eventId={eventId}
              redirectAfterLogin={`${CONST.ROUTES.REGISTER_EVENT.URL}/${eventId}/${userId}`}
            />
          </div>
        </div>
      </div>
    </>
  )
}
