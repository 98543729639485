// Constants
import { IMAGE_CONSTS } from '../../const/image-const'

// Types
type Props = {
  horseImage?: string
  horseName: string
  horseDiscipline: string
  horseZone: any
}

const HorseDetailCard = (props: Props) => {
  return (
    <div className="horse flex flex-row items-center max-w-[100%] w-[22rem] rounded-lg py-1.5 mb-2 px-1.5 border border-SeabiscuitLightThemeColor">
      <span className="w-11 h-11 mr-2 rounded-md overflow-hidden">
        <img
          src={!!!props.horseImage ? IMAGE_CONSTS.PLACEHOLDERS.HORSE : props.horseImage}
          className="w-full h-full object-cover"
          alt="horse"
        />
      </span>
      <div className="hourseDetails w-10/12">
        <div className="hourseTitle text-SeabiscuitDark200ThemeColor capitalize">
          {props.horseName}
        </div>
        <div className="hourseCategory flex">
          <p className="flex items-center text-sm capitalize text-SeabiscuitDark200ThemeColor">
            <span className="w-fit overflow-hidden text-ellipsis whitespace-nowrap">
              {props.horseDiscipline} &nbsp;&nbsp;
            </span>
          </p>
          {props.horseZone && (
            <span className="w-[120px] overflow-hidden text-ellipsis whitespace-nowrap">
              <p className="flex items-center text-sm capitalize text-SeabiscuitDark200ThemeColor">
                • Zone {props.horseZone}
              </p>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default HorseDetailCard
