export type IProfileEligibilityTabCols = {
  DATE: IKeyOfIProfileEligibilityTabCols
  STATUS: IKeyOfIProfileEligibilityTabCols
  MANAGE: IKeyOfIProfileEligibilityTabCols
  DETAILS: IKeyOfIProfileEligibilityTabCols
  ELIGIBILITY_ITEM: IKeyOfIProfileEligibilityTabCols
}

type IKeyOfIProfileEligibilityTabCols = {
  KEY: string
  TOOLTIP_TEXT: string | null
}

type IProfileEligibilityTabData = {
  COLS: IProfileEligibilityTabCols
  DATA: {
    date: string
    link?: string | 'BackToProfile'
    added: boolean
    details: string
    item_name: string
    status: 'Current' | 'Active' | 'Suspend' | 'Expired' | 'Registered' | 'N/A' | 'Yes'
  }[]
}

const PROFILE_ELIGIBILITY_TAB_DATA: IProfileEligibilityTabData = {
  COLS: {
    ELIGIBILITY_ITEM: {
      KEY: 'EligIbility item',
      TOOLTIP_TEXT:
        'Items USEF tracks to determine your eligibility to participate in USEF licensed events.',
    },
    STATUS: {
      KEY: 'Status',
      TOOLTIP_TEXT:
        'Status of each USEF item. White fields are not tracked by the USEF, or, the USEF does not have data on file for you for this item.',
    },
    DETAILS: {
      KEY: 'Details',
      TOOLTIP_TEXT: 'Details in the USEF database for your profile.',
    },
    DATE: {
      KEY: 'Date',
      TOOLTIP_TEXT: 'Date this item expires, expired or is suspended until.',
    },
    MANAGE: {
      KEY: 'Manage',
      TOOLTIP_TEXT:
        'Click to open the login screen to manage or add this item. Once managed, click ‘Refresh’ to display the updated records here. Updates made on Pegasus or the USEF website will refresh immediately. Updates made with other organizations (eg. USDF), may take a few days to update in our system.',
    },
  },
  DATA: [
    {
      item_name: 'USEF membership status',
      status: 'Current',
      details: 'Active',
      date: 'Expires: 2023-11-31',
      added: true,
      link: 'https://members.usef.org/',
    },
    {
      item_name: 'FEI registration status',
      status: 'Registered',
      details: 'Background check is current',
      date: 'Expires: 2023-11-31',
      added: true,
      link: 'https://members.usef.org/fei/rider-registration',
    },
    {
      item_name: 'Background check',
      status: 'Expired',
      details: 'N/A',
      date: 'Expired: 2021-11-31',
      added: true,
      link: 'https://members.usef.org/',
    },
    {
      item_name: 'SafeSport status',
      status: 'Current',
      details: 'No',
      date: 'Expires: 2023-11-31',
      added: true,
      link: 'https://members.usef.org/safe-sport/training',
    },
    {
      item_name: 'Foreign Endorsement Letter',
      status: 'N/A',
      details: 'No latter saved in profile',
      date: 'N/A',
      added: false,
      link: 'BackToProfile',
    },
    {
      item_name: 'Suspention',
      status: 'Suspend',
      details: 'Safesport Training Overdue-allowed on competition grounds',
      date: 'Suspension ends: 2023-09-28',
      added: true,
      link: 'https://members.usef.org/',
    },
    {
      item_name: 'Suspention',
      status: 'Suspend',
      details: 'FEI Provisional Suspension--Equine Anti-Doping',
      date: 'Suspension ends: 2023-09-28',
      added: true,
      link: 'https://members.usef.org/',
    },
    {
      item_name: 'Suspention',
      status: 'Suspend',
      details: 'Non-Compliance with Hearing Committee Ruling',
      date: 'Suspension ends: 2023-09-28',
      added: true,
      link: 'https://members.usef.org/',
    },
    {
      item_name: 'USEF membership status',
      status: 'Current',
      details: 'Active',
      date: 'Expires:2023-11-31',
      added: true,
      link: '',
    },
    {
      item_name: 'USEA membership status',
      status: 'N/A',
      details: 'No Record on file with USEF',
      date: 'N/A',
      added: false,
      link: '',
    },
    {
      item_name: 'USHJA membership status',
      status: 'N/A',
      details: 'No Record on file with USEF',
      date: 'N/A',
      added: false,
      link: '',
    },
    {
      item_name: 'AHHS membership status',
      status: 'N/A',
      details: 'No Record on file with USEF',
      date: 'N/A',
      added: false,
      link: '',
    },
    {
      item_name: 'AMHA membership status',
      status: 'N/A',
      details: 'No Record on file with USEF',
      date: 'N/A',
      added: false,
      link: '',
    },

    {
      item_name: 'ARHPA membership status',
      status: 'N/A',
      details: 'No Record on file with USEF',
      date: 'N/A',
      added: false,
      link: '',
    },
    {
      item_name: 'ASHA membership status',
      status: 'Current',
      details: 'Charge Show Pass Fee',
      date: 'Expires:2023-11-31',
      added: true,
      link: '',
    },

    {
      item_name: 'UPHA membership status',
      status: 'N/A',
      details: 'No Record on file with USEF',
      date: 'N/A',
      added: false,
      link: '',
    },

    {
      item_name: 'WDAA membership status',
      status: 'N/A',
      details: 'No Record on file with USEF',
      date: 'N/A',
      added: false,
      link: '',
    },
  ],
}

export { PROFILE_ELIGIBILITY_TAB_DATA }
