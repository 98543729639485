import { useCallback, useEffect, useState } from 'react'
import { useIonRouter } from '@ionic/react'

import HelpPageTabs from './HelpPageTabs'
import HelpPageDisplayTabs from './HelpPageDisplayTabs'
import WrapperContainer from '../../../components/common/wrappers/WrapperContainer'

// Constants
import { CONST } from '../../../const/const'
import { HELP_PAGE_DATA } from './helpPageData'
import { useHistory } from 'react-router-dom'

// Constants
const SELECTED_TAB_INI_VALUES = {
  tab: 1,
  tabTitle: 'Help',
}

type Props = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
  tabToOpen?: string
}

const HelpRootPage = ({ handleModal, ...rest }: Props) => {
  // Hooks and vars
  const router = useIonRouter()
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState<any>({ ...SELECTED_TAB_INI_VALUES })

  /** Sets the active tab details */
  const selectTab = useCallback(
    (tabDetails: any, switchRoute = false) => {
      const switchRouteFn = (urlToSwitchOn: string) => {
        router.push(urlToSwitchOn, 'forward')
        history.push(urlToSwitchOn)
      }

      setSelectedTab(tabDetails)
      if (switchRoute) switchRouteFn(tabDetails.url)
    },
    [router]
  )

  useEffect(() => {
    if (!(rest?.tabToOpen && typeof rest?.tabToOpen === 'string')) return

    let toFind = 2

    if (rest?.tabToOpen === CONST.ROUTES.FAQ.URL) toFind = 2
    else if (rest?.tabToOpen === CONST.ROUTES.POLICIES.URL) toFind = 3
    else return

    const foundTab = HELP_PAGE_DATA.tabs.find((c) => c.tab === toFind)
    if (foundTab) selectTab(foundTab)
  }, [rest?.tabToOpen, selectTab])

  return (
    <WrapperContainer title="Help" removePadding={true}>
      <>
        <HelpPageTabs selectedTab={selectedTab} selectTab={selectTab} />

        <div className="p-6 mt-1">
          <HelpPageDisplayTabs handleModal={handleModal} selectedTab={selectedTab.tabTitle} />
        </div>
      </>
    </WrapperContainer>
  )
}

export default HelpRootPage
