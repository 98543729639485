// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import EventFormContainerComponent from '../container/EventFormContainerComponent'
import HeadingElement from '../../../elements/headings/heading/HeadingElement'
import EventPrizeListFormBuilderUtil from '../../../utils/forms/builders/events/prize_list/EventPrizeListFormBuilderUtil'
import React, { useEffect } from 'react'
import { Divider } from '@mui/material'

import { useForm } from 'react-hook-form'
import { EventPrizeListsModel } from '../../../../models/event-prize-lists/event-prize-lists.model'
import generateFieldValidation from '../../../utils/forms/builders/events/prize_list/validation/EventPrizeListFormBuilderUtilValidation'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const eventPrizeListFormDefaultValues = new EventPrizeListsModel().toObject()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventPrizeListFormComponentProps {
  data: any
  onValid: any
  onInvalid: any
  // nextEventTab?: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormComponent: React.FC<EventPrizeListFormComponentProps> = (props) => {
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { ...eventPrizeListFormDefaultValues },
    mode: 'onChange',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (props.data) {
      reset(props.data)
    } else {
      reset(eventPrizeListFormDefaultValues)
    }
    trigger().then()
  }, [props.data, reset, trigger])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <EventFormContainerComponent>
      <form>
        <div className="pt-4 pb-12 px-4 w-full">
          <HeadingElement
            classname="tw-title text-SeabiscuitMainThemeColor font-bold"
            title="Prize List"
          />

          <div className="flex items-center mb-6">
            <label className="text-xl tw-title text-SeabiscuitDark200ThemeColor font-normal w-full">
              Add classes to Prize List - you can only select divisions and classes permitted by
              your license.
            </label>
          </div>

          <EventPrizeListFormBuilderUtil
            register={register}
            data={props.data?.data}
            errors={errors}
            control={control}
            getValues={getValues}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            trigger={trigger}
          />

          <Divider className="bg-SeabiscuitMainThemeColor h-[1px]" />

          <HeadingElement
            classname="tw-title pt-6 pb-4 text-SeabiscuitMainThemeColor font-bold"
            title="Summary"
          />

          <div
            className="text-[16px] text-SeabiscuitDark200ThemeColor mb-2"
            style={{
              fontWeight: 700,
              lineHeight: '25px',
            }}
          >
            Total Classes Offered
          </div>

          <div className="flex items-center mb-6 flex-wrap">
            <div className="mb-4 relative pb-2">
              <div className="flex">
                <label
                  htmlFor="total-divisions-offered"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
                >
                  Total Divisions Offered
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <input
                type="text"
                className="bg-SeabiscuitWhiteThemeColor rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor"
                {...register(`data.summary.totalDivisionsOffered.value`, {
                  validate: generateFieldValidation(
                    props.data?.data?.summary?.totalDivisionsOffered
                  ),
                })}
                style={{
                  border: '1px solid #BCC6D5',
                }}
                placeholder="No classes added"
              />
            </div>

            <div className="mb-4 ml-2 relative pb-2">
              <div className="flex">
                <label
                  htmlFor="total-sections-offered"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
                >
                  Total Sections Offered
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <input
                type="number"
                className="bg-SeabiscuitWhiteThemeColor rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor"
                {...register(`data.summary.totalSectionsOffered.value`, {
                  validate: generateFieldValidation(
                    props.data?.data?.summary?.totalSectionsOffered
                  ),
                })}
              />
            </div>

            <div className="mb-4 ml-2 relative pb-2">
              <div className="flex">
                <label
                  htmlFor="total-classes-offered"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
                >
                  Total Classes Offered
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <input
                type="number"
                className="bg-SeabiscuitWhiteThemeColor rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor"
                style={{
                  border: '1px solid #BCC6D5',
                }}
                {...register(`data.summary.totalClassesOffered.value`, {
                  validate: generateFieldValidation(props.data?.data?.summary?.totalClassesOffered),
                })}
              />
            </div>

            <div className="mb-4 ml-2 relative pb-2">
              <div className="flex">
                <label
                  htmlFor="total-miscellaneous-offered"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
                >
                  Total Miscellaneous Offered
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <input
                type="number"
                className="bg-SeabiscuitWhiteThemeColor rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor"
                style={{
                  border: '1px solid #BCC6D5',
                }}
                {...register(`data.summary.totalMiscellaneousOffered.value`, {
                  validate: generateFieldValidation(
                    props.data?.data?.summary?.totalMiscellaneousOffered
                  ),
                })}
              />
            </div>

            <div className="mb-4 ml-2 relative pb-2">
              <div className="flex">
                <label
                  htmlFor="total-classics-offered"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
                >
                  Total Classics Offered
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <input
                type="number"
                className="bg-SeabiscuitWhiteThemeColor rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor"
                style={{
                  border: '1px solid #BCC6D5',
                }}
                {...register(`data.summary.totalClassicsOffered.value`, {
                  validate: generateFieldValidation(
                    props.data?.data?.summary?.totalClassicsOffered
                  ),
                })}
              />
            </div>

            <div className="mb-4 ml-2 relative pb-2">
              <div className="flex">
                <label
                  htmlFor="total-derbys-offered"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
                >
                  Total Derbys Offered
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <input
                type="number"
                className="bg-SeabiscuitWhiteThemeColor rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor"
                style={{
                  border: '1px solid #BCC6D5',
                }}
                {...register(`data.summary.totalDerbysOffered.value`, {
                  validate: generateFieldValidation(props.data?.data?.summary?.totalDerbysOffered),
                })}
              />
            </div>

            <div className="mb-4 ml-2 relative pb-2">
              <div className="flex">
                <label
                  htmlFor="total-leagues-offered"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor mb-1"
                >
                  Total Leagues Offered
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <input
                type="number"
                className="bg-SeabiscuitWhiteThemeColor rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor"
                style={{
                  border: '1px solid #BCC6D5',
                }}
                {...register(`data.summary.totalLeaguesOffered.value`, {
                  validate: generateFieldValidation(props.data?.data?.summary?.totalLeaguesOffered),
                })}
              />
            </div>
          </div>

          <div
            className="text-[16px] text-SeabiscuitDark200ThemeColor mb-2"
            style={{
              fontWeight: 700,
              lineHeight: '25px',
            }}
          >
            Total Prize Money Offered
          </div>

          <div className="flex items-center mb-6 flex-wrap">
            <div className="mb-4 relative">
              <div className="flex">
                <label
                  htmlFor="equitation-divisions"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor"
                >
                  Equitation Divisions
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 left-2 mt-4 text-SeabiscuitGrayLightThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="number"
                className="bg-SeabiscuitWhiteThemeColor mt-1 rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor placeholder:pl-3 "
                style={{
                  border: '1px solid #BCC6D5',
                }}
                {...register(`data.summary.equitationDivisionsPrizeMoney.value`, {
                  validate: generateFieldValidation(
                    props.data?.data?.summary?.equitationDivisionsPrizeMoney
                  ),
                })}
              />
            </div>

            <div className="mb-4 ml-2 relative">
              <div className="flex">
                <label
                  htmlFor="equitation-divisions"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor"
                >
                  Hunter Divisions
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 left-2 mt-4 text-SeabiscuitGrayLightThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="number"
                className="bg-SeabiscuitWhiteThemeColor mt-1 rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor placeholder:pl-3 "
                style={{
                  border: '1px solid #BCC6D5',
                }}
                {...register(`data.summary.hunterDivisionsPrizeMoney.value`, {
                  validate: generateFieldValidation(
                    props.data?.data?.summary?.hunterDivisionsPrizeMoney
                  ),
                })}
              />
            </div>

            <div className="mb-4 ml-2 relative">
              <div className="flex">
                <label
                  htmlFor="jumper-divisions"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor"
                >
                  Jumper Divisions
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 left-2 mt-4 text-SeabiscuitGrayLightThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="number"
                className="bg-SeabiscuitWhiteThemeColor mt-1 rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor placeholder:pl-3 "
                style={{
                  border: '1px solid #BCC6D5',
                }}
                {...register(`data.summary.jumperDivisionsPrizeMoney.value`, {
                  validate: generateFieldValidation(
                    props.data?.data?.summary?.jumperDivisionsPrizeMoney
                  ),
                })}
              />
            </div>

            <div className="mb-4 ml-2 relative">
              <div className="flex">
                <label
                  htmlFor="miscellaneous-divisions"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor"
                >
                  Miscellaneous
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 left-2 mt-4 text-SeabiscuitGrayLightThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="number"
                className="bg-SeabiscuitWhiteThemeColor mt-1 rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor placeholder:pl-3 "
                style={{
                  border: '1px solid #BCC6D5',
                }}
                {...register(`data.summary.miscellaneousPrizeMoney.value`, {
                  validate: generateFieldValidation(
                    props.data?.data?.summary?.miscellaneousPrizeMoney
                  ),
                })}
              />
            </div>

            <div className="mb-4 ml-2 relative">
              <div className="flex">
                <label
                  htmlFor="equitation-divisions"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor"
                >
                  Classic's
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 left-2 mt-4 text-SeabiscuitGrayLightThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="number"
                className="bg-SeabiscuitWhiteThemeColor mt-1 rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor placeholder:pl-3 "
                style={{
                  border: '1px solid #BCC6D5',
                }}
                {...register(`data.summary.classicsPrizeMoney.value`, {
                  validate: generateFieldValidation(props.data?.data?.summary?.classicsPrizeMoney),
                })}
              />
            </div>

            <div className="mb-4 ml-2 relative">
              <div className="flex">
                <label
                  htmlFor="equitation-divisions"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor"
                >
                  Derby's
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 left-2 mt-4 text-SeabiscuitGrayLightThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="number"
                className="bg-SeabiscuitWhiteThemeColor mt-1 rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor placeholder:pl-3 "
                style={{
                  border: '1px solid #BCC6D5',
                }}
                {...register(`data.summary.derbysPrizeMoney.value`, {
                  validate: generateFieldValidation(props.data?.data?.summary?.derbysPrizeMoney),
                })}
              />
            </div>

            <div className="mb-4 ml-2 relative">
              <div className="flex">
                <label
                  htmlFor="equitation-divisions"
                  className="text-xs font-semibold text-SeabiscuitDark200ThemeColor"
                >
                  Show Total
                </label>
                <div className="pl-1 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute h-6 w-6 left-2 mt-4 text-SeabiscuitGrayLightThemeColor"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <input
                type="number"
                className="bg-SeabiscuitWhiteThemeColor mt-1 rounded-lg w-[214px] h-12 pl-8 text-lg placeholder-SeabiscuitGrayLightThemeColor placeholder:pl-3 "
                style={{
                  border: '1px solid #BCC6D5',
                }}
                {...register(`data.summary.showTotalPrizeMoney.value`, {
                  validate: generateFieldValidation(props.data?.data?.summary?.showTotalPrizeMoney),
                })}
              />
            </div>
          </div>
        </div>
        <button
          className="rounded-full bg-SeabiscuitMainThemeColor text-white w-24 h-10 my-5 ml-auto"
          onClick={(e) => handleSubmit(props.onValid, props.onInvalid)(e)}
        >
          {' '}
          Next &#62;
        </button>
        <br />
        <br />
        <br />
      </form>
    </EventFormContainerComponent>
  )
}

EventPrizeListFormComponent.defaultProps = {
  data: new EventPrizeListsModel(),
  onValid: null,
  onInvalid: null,
}

export default EventPrizeListFormComponent
