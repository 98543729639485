// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { filter } from 'lodash'
import { CONST } from '../../../../../../../const/const'
import { EventPrizeListFormBuilderUtilValidationHunterStandardSectionMainCheckboxHelper } from './helper/hunter/standard/checkbox/EventPrizeListFormBuilderUtilValidationHunterStandardSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilValidationHunterStandardSubSectionConformationOptionHelper } from './helper/hunter/standard/checkbox/EventPrizeListFormBuilderUtilValidationHunterStandardSubSectionConformationOptionHelper'
import { EventPrizeListFormBuilderUtilValidationHunterStandardSubSectionHandyOptionHelper } from './helper/hunter/standard/checkbox/EventPrizeListFormBuilderUtilValidationHunterStandardSubSectionHandyOptionHelper'
import { EventPrizeListFormBuilderUtilValidationHunterStandardSubSectionMainCheckboxHelper } from './helper/hunter/standard/checkbox/EventPrizeListFormBuilderUtilValidationHunterStandardSubSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilValidationHunterStandardSubSectionUnderSaddleOptionHelper } from './helper/hunter/standard/checkbox/EventPrizeListFormBuilderUtilValidationHunterStandardSubSectionUnderSaddleOptionHelper'
import { EventPrizeListFormBuilderUtilValidationHunterStandardSectionHeightChoiceHelper } from './helper/hunter/standard/choice/EventPrizeListFormBuilderUtilValidationHunterStandardSectionHeightChoiceHelper'
import { EventPrizeListFormBuilderUtilValidationHunterClassicSectionMainCheckboxHelper } from './helper/hunter/classic/checkbox/EventPrizeListFormBuilderUtilValidationHunterClassicSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilValidationHunterDerbySectionMainCheckboxHelper } from './helper/hunter/derby/checkbox/EventPrizeListFormBuilderUtilValidationHunterDerbySectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilValidationHunterLeagueSectionMainCheckboxHelper } from './helper/hunter/league/checkbox/EventPrizeListFormBuilderUtilValidationHunterLeagueSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilValidationEquitationSectionMainCheckboxHelper } from './helper/equitation/checkbox/EventPrizeListFormBuilderUtilValidationEquitationSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilValidationEquitationSubSectionMainCheckboxHelper } from './helper/equitation/checkbox/EventPrizeListFormBuilderUtilValidationEquitationSubSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilValidationEquitationSubSectionFlatOptionHelper } from './helper/equitation/checkbox/EventPrizeListFormBuilderUtilValidationEquitationSubSectionFlatOptionHelper'
import { EventPrizeListFormBuilderUtilValidationEquitationSubSectionOverFenceOptionHelper } from './helper/equitation/checkbox/EventPrizeListFormBuilderUtilValidationEquitationSubSectionOverFenceOptionHelper'
import { EventPrizeListFormBuilderUtilValidationJumperSectionMainCheckboxHelper } from './helper/jumper/checkbox/EventPrizeListFormBuilderUtilValidationJumperSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilValidationHunterSubSectionMainCheckboxHelper } from './helper/jumper/checkbox/EventPrizeListFormBuilderUtilValidationJumperSubSectionMainCheckboxHelper'
import { EventPrizeListFormBuilderUtilValidationHunterClassicSectionHeightChoiceHelper } from './helper/hunter/classic/choice/EventPrizeListFormBuilderUtilValidationHunterClassicSectionHeightChoiceHelper'
import { EventPrizeListFormBuilderUtilValidationHunterClassicSectionQualifyingChoiceHelper } from './helper/hunter/classic/choice/EventPrizeListFormBuilderUtilValidationHunterClassicSectionQualifyingChoiceHelper'
import { EventPrizeListFormBuilderUtilValidationHunterDerbySectionQualifyingChoiceHelper } from './helper/hunter/derby/choice/EventPrizeListFormBuilderUtilValidationHunterDerbySectionQualifyingChoiceHelper'
import { EventPrizeListFormBuilderUtilValidationHunterLeagueSectionQualifyingChoiceHelper } from './helper/hunter/league/choice/EventPrizeListFormBuilderUtilValidationHunterLeagueSectionQualifyingChoiceHelper'
import { EventPrizeListFormBuilderUtilValidationJumperSectionTablesChoiceHelper } from './helper/jumper/choice/EventPrizeListFormBuilderUtilValidationJumperSectionTablesChoiceHelper'
import { EventPrizeListFormBuilderUtilValidationHunterClassicSectionSizeChoiceHelper } from './helper/hunter/classic/choice/EventPrizeListFormBuilderUtilValidationHunterClassicSectionSizeChoiceHelper'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
function generateFieldValidation(
  field: any,
  enabled: any = true,
  setValue: any = null,
  getValues: any = null,
  setError: any = null,
  clearErrors: any = null,
  trigger: any = null,
  disciplineIndex: any = null,
  divisionIndex: any = null,
  sectionIndex: any = null,
  subSectionIndex: any = null
) {
  return {
    notEmpty: (value: any) => {
      if (!enabled) {
        return true
      }

      if (field?.type !== CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT) return true

      if (field?.validation === null || field?.validation.length === 0) return true

      const validation_result = filter(field?.validation, {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
      })

      if (validation_result.length) {
        if (value.length > 0) {
          return true
        } else {
          return validation_result[0].errorMessage
        }
      }
      return true
    },
    notZero: (value: any) => {
      if (!enabled) {
        return true
      }

      const validation_result = filter(field?.validation, {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
      })

      if (validation_result.length) {
        value = parseInt(value)

        if (value !== 0) {
          return true
        } else {
          return validation_result[0].errorMessage
        }
      }
      return true
    },
    validNumber: (value: any) => {
      if (!enabled) {
        return true
      }

      const validation_result = filter(field?.validation, {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
      })

      if (validation_result.length) {
        if (field?.type !== CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER)
          return validation_result[0].errorMessage

        value = parseInt(value)

        if (isNaN(value)) {
          return validation_result[0].errorMessage
        }

        return true
      }
      return true
    },
    noNegative: (value: any) => {
      if (!enabled) {
        return true
      }

      const validation_result = filter(field?.validation, {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
      })

      if (validation_result.length) {
        if (field?.type !== CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER)
          return validation_result[0].errorMessage

        value = parseInt(value)

        if (value >= 0) {
          return true
        } else {
          return validation_result[0].errorMessage
        }
      }
      return true
    },
    noLessThan: (value: any) => {
      if (!enabled) {
        return true
      }

      const validation_result = filter(field?.validation, {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_LESS_THAN_NUMBER,
      })

      if (validation_result.length) {
        if (field?.type !== CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER)
          return validation_result[0].errorMessage

        value = parseInt(value)

        if (value >= validation_result[0].value) {
          return true
        } else {
          return validation_result[0].errorMessage
        }
      }

      return true
    },
    noMoreThan: (value: any) => {
      if (!enabled) {
        return true
      }

      const validation_result = filter(field?.validation, {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_MORE_THAN_NUMBER,
      })

      if (validation_result.length) {
        if (field?.type !== CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER)
          return validation_result[0].errorMessage

        value = parseInt(value)

        if (value <= validation_result[0].value) {
          return true
        } else {
          return validation_result[0].errorMessage
        }
      }

      return true
    },
    minMaxAgeNumericalRelationship: (value: any) => {
      if (!enabled) {
        return true
      }

      const validation_result = filter(field?.validation, {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION
          .MIN_MAX_AGE_NUMERICAL_RELATIONSHIP,
      })

      if (validation_result.length) {
        if (field?.type !== CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER)
          return validation_result[0].errorMessage

        const min_age_extracted = getValues(
          `data.items.${disciplineIndex}.divisions.${divisionIndex}.sections.${sectionIndex}.sectionMinAge.value`
        )
        const max_age_extracted = getValues(
          `data.items.${disciplineIndex}.divisions.${divisionIndex}.sections.${sectionIndex}.sectionMaxAge.value`
        )

        const min_age = parseInt(min_age_extracted)
        const max_age = parseInt(max_age_extracted)

        if (min_age <= max_age) {
          return true
        } else {
          return validation_result[0].errorMessage
        }
      }
      return true
    },
  }
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export function validateCheckboxFieldChange(
  field: any,
  parentField: any,
  rootField: any,
  previousValue: any = null,
  newValue: any = null,
  setValue: any = null,
  getValues: any = null,
  setError: any = null,
  clearErrors: any = null,
  trigger: any = null,
  disciplineIndex: any = null,
  divisionIndex: any = null,
  sectionIndex: any = null,
  subSectionIndex: any = null
) {
  const state = {
    field,
    parentField,
    rootField,
    previousValue,
    newValue,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    disciplineIndex,
    divisionIndex,
    sectionIndex,
    subSectionIndex,
  }

  const hunterStandardMainSectionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterStandardSectionMainCheckboxHelper(state)
  const hunterStandardMainSubSectionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterStandardSubSectionMainCheckboxHelper(state)
  const hunterStandardSubSectionUnderSaddleOptionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterStandardSubSectionUnderSaddleOptionHelper(
      state
    )
  const hunterStandardSubSectionHandyOptionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterStandardSubSectionHandyOptionHelper(state)
  const hunterStandardSubSectionConformationOptionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterStandardSubSectionConformationOptionHelper(
      state
    )

  const hunterClassicMainSectionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterClassicSectionMainCheckboxHelper(state)
  const hunterDerbyMainSectionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterDerbySectionMainCheckboxHelper(state)
  const hunterLeagueMainSectionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterLeagueSectionMainCheckboxHelper(state)

  const equitationMainSectionHelper =
    new EventPrizeListFormBuilderUtilValidationEquitationSectionMainCheckboxHelper(state)
  const equitationMainSubSectionHelper =
    new EventPrizeListFormBuilderUtilValidationEquitationSubSectionMainCheckboxHelper(state)
  const equitationMainSubSectionFlatOptionHelper =
    new EventPrizeListFormBuilderUtilValidationEquitationSubSectionFlatOptionHelper(state)
  const equitationMainSubSectionOverFenceOptionHelper =
    new EventPrizeListFormBuilderUtilValidationEquitationSubSectionOverFenceOptionHelper(state)

  const jumperMainSectionHelper =
    new EventPrizeListFormBuilderUtilValidationJumperSectionMainCheckboxHelper(state)
  const jumperMainSubSectionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterSubSectionMainCheckboxHelper(state)

  switch (field.type) {
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_STANDARD_MAIN_CHECKBOX:
      // ____________________________________________________________
      hunterStandardMainSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_HUNTER_STANDARD_MAIN_CHECKBOX:
      // ____________________________________________________________
      hunterStandardMainSubSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SUBSECTION_HUNTER_STANDARD_UNDERSADDLE_OPTION:
      // ____________________________________________________________
      hunterStandardSubSectionUnderSaddleOptionHelper.validate()
      hunterStandardSubSectionHandyOptionHelper.validate()
      hunterStandardSubSectionConformationOptionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_HUNTER_STANDARD_HANDY_OPTION:
      // ____________________________________________________________
      hunterStandardSubSectionUnderSaddleOptionHelper.validate()
      hunterStandardSubSectionHandyOptionHelper.validate()
      hunterStandardSubSectionConformationOptionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SUBSECTION_HUNTER_STANDARD_CONFORMATION_OPTION:
      // ____________________________________________________________
      hunterStandardSubSectionUnderSaddleOptionHelper.validate()
      hunterStandardSubSectionHandyOptionHelper.validate()
      hunterStandardSubSectionConformationOptionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_CLASSIC_MAIN_CHECKBOX:
      // ____________________________________________________________
      hunterClassicMainSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_DERBY_MAIN_CHECKBOX:
      // ____________________________________________________________
      hunterDerbyMainSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_LEAGUE_MAIN_CHECKBOX:
      // ____________________________________________________________
      hunterLeagueMainSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_EQUITATION_MAIN_CHECKBOX:
      // ____________________________________________________________
      equitationMainSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_EQUITATION_MAIN_CHECKBOX:
      // ____________________________________________________________
      equitationMainSubSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_EQUITATION_FLAT_CLASS_OPTION:
      // ____________________________________________________________
      equitationMainSubSectionFlatOptionHelper.validate()
      equitationMainSubSectionOverFenceOptionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_EQUITATION_OVER_FENCE_OPTION:
      // ____________________________________________________________
      equitationMainSubSectionFlatOptionHelper.validate()
      equitationMainSubSectionOverFenceOptionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_JUMPER_MAIN_CHECKBOX:
      // ____________________________________________________________
      jumperMainSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_JUMPER_MAIN_CHECKBOX:
      // ____________________________________________________________
      jumperMainSubSectionHelper.validate()
      break

    default:
      break
  }

  return true
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export function validateChoiceFieldChange(
  field: any,
  parentField: any,
  rootField: any,
  previousValue: any = null,
  newValue: any = null,
  setValue: any = null,
  getValues: any = null,
  setError: any = null,
  clearErrors: any = null,
  trigger: any = null,
  disciplineIndex: any = null,
  divisionIndex: any = null,
  sectionIndex: any = null,
  subSectionIndex: any = null
) {
  const state = {
    field,
    parentField,
    rootField,
    previousValue,
    newValue,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    disciplineIndex,
    divisionIndex,
    sectionIndex,
    subSectionIndex,
  }

  const hunterStandardHeightSectionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterStandardSectionHeightChoiceHelper(state)

  const hunterClassicSizeSectionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterClassicSectionSizeChoiceHelper()
  const hunterClassicQualifyingSectionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterClassicSectionQualifyingChoiceHelper(state)
  const hunterClassicHeightSectionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterClassicSectionHeightChoiceHelper(state)

  const hunterDerbyQualifyingSectionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterDerbySectionQualifyingChoiceHelper(state)

  const hunterLeagueQualifyingSectionHelper =
    new EventPrizeListFormBuilderUtilValidationHunterLeagueSectionQualifyingChoiceHelper(state)

  const jumperTablesSubSectionHelper =
    new EventPrizeListFormBuilderUtilValidationJumperSectionTablesChoiceHelper(state)

  switch (field.type) {
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SECTION_HUNTER_STANDARD_HEIGHT_SINGLE_CHOICE:
      // ____________________________________________________________
      hunterStandardHeightSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_HUNTER_CLASSIC_SIZE_SINGLE_CHOICE:
      // ____________________________________________________________
      hunterClassicSizeSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SECTION_HUNTER_CLASSIC_QUALIFYING_MULTIPLE_CHOICE:
      // ____________________________________________________________
      hunterClassicQualifyingSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SECTION_HUNTER_CLASSIC_HEIGHT_MULTIPLE_CHOICE:
      // ____________________________________________________________
      hunterClassicHeightSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SECTION_HUNTER_DERBY_QUALIFYING_MULTIPLE_CHOICE:
      // ____________________________________________________________
      hunterDerbyQualifyingSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
      .SECTION_HUNTER_LEAGUE_QUALIFYING_MULTIPLE_CHOICE:
      // ____________________________________________________________
      hunterLeagueQualifyingSectionHelper.validate()
      break
    // ____________________________________________________________
    case CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SUBSECTION_JUMPER_TABLES_SINGLE_CHOICE:
      // ____________________________________________________________
      jumperTablesSubSectionHelper.validate()
      break
    default:
      break
  }

  return true
}

export default generateFieldValidation
