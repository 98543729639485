import { useState } from 'react'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import TabList from './components/tabs/TabList'
import DraftProducts from './components/products/DraftProducts'
import LiveProducts from './components/products/LiveProducts'

const VendorProducts = () => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <WrapperContainer title="Products" className="">
      <div className="flex flex-col gap-[30px]">
        <TabList activeTab={activeTab} setActiveTab={setActiveTab} />

        {activeTab === 0 ? <LiveProducts /> : <DraftProducts />}
      </div>
    </WrapperContainer>
  )
}

export default VendorProducts
