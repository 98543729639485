import React, { FC, useEffect, useState } from 'react'
import { where } from 'firebase/firestore'
import moment from 'moment/moment'

import MainModal from './common/MainModal'

import FirestoreService from '../../services/firestoreService'

import { IRegisterTab } from '../../models/event-registered-users/event-registered-users.interface'

import { MODAL_CONSTS } from '../../const/modal-const'
import { CONST } from '../../const/const'

import { getConvertedData } from '../../models/interface.helper'

import { IRegistrationByDayInterface } from '../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationByDayModel } from '../../models/registrations-by-day/registrationByDay.model'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

interface ClassMoreProps {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string) => void
  dataToPassOn: { row: IRegisterTab; eventId: string }
}
export const ClassMore: FC<ClassMoreProps> = ({ show, handleModal, dataToPassOn }) => {
  const [registeredRiders, setRegisteredRiders] = useState<IRegistrationByDayInterface[]>([])

  const getRegisteredRiders = async () => {
    const registeredRiders_: IRegistrationByDayInterface[] = []
    const registeredRidersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      [
        where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', dataToPassOn.eventId),
        where(
          COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.REGISTRATION_BY_DAY_NAME.KEY,
          '==',
          dataToPassOn.row.registrationByDayName
        ),
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.KEY, 'in', [
          COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PAID,
          COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.PAYMENT_STATUS.VALUE.PENDING,
        ]),
      ]
    )

    registeredRidersSnaps.docs.forEach((currDoc) => {
      registeredRiders_.push(
        getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
      )
    })

    const uniqueRegisteredRiders: IRegistrationByDayInterface[] = []

    registeredRiders_.forEach((rider) => {
      const isFind = uniqueRegisteredRiders.find(
        (unique) => unique.riderId === rider.riderId && unique.horseId === rider.horseId
      )

      if (!isFind?.id) {
        uniqueRegisteredRiders.push(rider)
      }
    })

    setRegisteredRiders(uniqueRegisteredRiders)
  }

  useEffect(() => {
    getRegisteredRiders().then()
  }, [dataToPassOn.row])

  return (
    <MainModal
      title="More"
      show={show}
      type="CLASS_MORE"
      size="md"
      buttons={[
        {
          label: 'CLOSE',
          fullWidth: true,
          bgClass: 'bg-SeabiscuitDark100ThemeColor/10',
          borderClass: 'border-SeabiscuitDark100ThemeColor/50',
          textClass: 'text-SeabiscuitDark100ThemeColor/50',
          onClick: () => handleModal(false, MODAL_CONSTS.CLASS_MORE),
        },
      ]}
    >
      <div className="text-SeabiscuitDark200ThemeColor">
        <div className="flex flex-wrap gap-4 justify-between mb-6 pb-6 border-b border-SeabiscuitLightThemeColorD3">
          <div className="flex flex-col gap-2 justify-between">
            <div className="flex items-center gap-2">
              <div className="flex items-center justify-center w-[45px] h-[45px] rounded-md bg-SeabiscuitMainThemeColor/5">
                <img src="/assets/og_icons/Prize-2.svg" className="w-6" alt="" />
              </div>
              <div>
                <div className="text-xs opacity-50">Class • Number</div>
                <span>{dataToPassOn.row.registrationByDayName ?? 'N/A'}</span>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div className="flex items-center justify-center w-[45px] h-[45px] rounded-md bg-SeabiscuitMainThemeColor/5">
                <img src="/assets/og_icons/Clock-2.svg" className="w-6" alt="" />
              </div>
              <div>
                <div className="text-xs opacity-50">Date • Time</div>
                <span>
                  {moment(dataToPassOn.row.startDate as Date).format('dddd, D MMM ')} •{' '}
                  {dataToPassOn.row.startTimeHours}:{dataToPassOn.row.startTimeMinutes}{' '}
                  {dataToPassOn.row.startTimeFormat}
                </span>
              </div>
            </div>
          </div>
          <div className="ml-auto flex flex-col gap-2 justify-between items-end text-right">
            <div className="flex items-center gap-2">
              <div>
                <div className="text-xs opacity-50">Entry fee • Qual fee</div>
                <span>
                  ${dataToPassOn.row.price ?? 0} • ${dataToPassOn.row.qualifyFee ?? 0}
                </span>
              </div>
              <div className="flex items-center justify-center w-[45px] h-[45px] rounded-md bg-SeabiscuitMainThemeColor/5">
                <img src="/assets/og_icons/Bill-2.svg" className="w-6" alt="" />
              </div>
            </div>
            <div className="flex items-center gap-2">
              <div>
                <div className="text-xs opacity-50">Registered</div>
                <span>{registeredRiders.length} rides</span>
              </div>
              <div className="flex items-center justify-center w-[45px] h-[45px] rounded-md bg-SeabiscuitMainThemeColor/5">
                <img src="/assets/og_icons/People-2.svg" className="w-6" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="border boder-SeabiscuitLightThemeColorD3 rounded-md p-4">
          {dataToPassOn.row.feesNote ?? 'No Description'}
        </div>
      </div>
    </MainModal>
  )
}
