import { CONST } from '../../../../../../const/const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//  Equitation Class AA
export const EPL_FB_EQUITATION_AA = {
  vMain: 1,
  vSub: 0,
  vFix: 0,
  disciplineType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DISCIPLINE.TYPE.STANDARD,
  discipline: 'Equitation CLASS AA',
  validationStatus: 'unknown',
  divisionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.DIVISION.TYPE.STANDARD,
  divisionItemsRules: null,
  divisions: [
    // ************************************************************
    //  <EQUITATION AA> 6000 - Hunter Seat Equitation
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '6000',
      divisionTitle: 'Hunter Seat Equitation',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.EQUITATION_STANDARD,
      sectionItemsRules: null,
      sections: [
        // ============================================================
        //  <EQUITATION AA> CODEID CODETITLE
        //
        {
          sectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SECTION.TYPE.EQUITATION_STANDARD,
          sectionCodeId: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: '3102',
            validation: [],
            rules: null,
          },
          sectionCodeTitle: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
            meta: null,
            value: 'Hunter Equitation 14/U',
            validation: [],
            rules: null,
          },
          sectionSubCode: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.SECTION_EQUITATION_MAIN_CHECKBOX,
            meta: {
              selected: false,
            },
            value: '3102 Hunter Equitation 14/U',
            validation: [],
            rules: null,
          },
          sectionFlatClass: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionOverFenceClasses: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionFenceHeight: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          sectionTotalPriceMoney: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.VALID_NUMBER,
                errorMessage: 'Must be a valid number',
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NO_NEGATIVE,
                errorMessage: "The amount can't be negative",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_EMPTY,
                errorMessage: "Field can't be empty",
              },
              {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.VALIDATION.NOT_ZERO,
                errorMessage: "Value can't Be Zero",
              },
            ],
            rules: null,
          },
          sectionJudge: {
            type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
            meta: {
              enabled: false,
            },
            value: '',
            validation: [],
            rules: null,
          },
          subsectionsItemsType:
            CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SUB_SECTION.TYPE.EQUITATION_STANDARD,
          subsectionItemsRules: null,
          subSections: [
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.EQUITATION,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class 3102',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'A',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_EQUITATION_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class 3102-A',
                validation: [],
                rules: null,
              },
              subSectionFlatClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_EQUITATION_FLAT_CLASS_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Flat Class',
                validation: [],
                rules: null,
              },
              subSectionOverFenceClasses: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_EQUITATION_OVER_FENCE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Over fence',
                validation: [],
                rules: null,
              },
            },
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.EQUITATION,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class 3102',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'B',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_EQUITATION_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class 3102-C',
                validation: [],
                rules: null,
              },
              subSectionFlatClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_EQUITATION_FLAT_CLASS_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Flat Class',
                validation: [],
                rules: null,
              },
              subSectionOverFenceClasses: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_EQUITATION_OVER_FENCE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Over fence',
                validation: [],
                rules: null,
              },
            },
            {
              subSectionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.SUB_SECTION.TYPE.EQUITATION,
              subSectionSubCodeId: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'Class 3102',
                validation: [],
                rules: null,
              },
              subSectionCodeTitle: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.DATA,
                meta: null,
                value: 'C',
                validation: [],
                rules: null,
              },
              subSectionSubCode: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_EQUITATION_MAIN_CHECKBOX,
                meta: {
                  selected: false,
                },
                value: 'Class 3102-C',
                validation: [],
                rules: null,
              },
              subSectionFlatClass: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_EQUITATION_FLAT_CLASS_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Flat Class',
                validation: [],
                rules: null,
              },
              subSectionOverFenceClasses: {
                type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE
                  .SUBSECTION_EQUITATION_OVER_FENCE_OPTION,
                meta: {
                  selected: false,
                },
                value: 'Over fence',
                validation: [],
                rules: null,
              },
            },
          ],
        },
      ],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <EQUITATION AA> 6100 - USEF Hunter Seat Medal
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '6100',
      divisionTitle: 'USEF Hunter Seat Medal',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.EQUITATION_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <EQUITATION AA> 6200 - USEF Pony Medal
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '6200',
      divisionTitle: 'USEF Pony Medal',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.EQUITATION_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <EQUITATION AA> 6300 - USEF Adult Hunter Seat
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '6300',
      divisionTitle: 'USEF Adult Hunter Seat',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.EQUITATION_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <EQUITATION AA> 6050 - USEF/NCEA Junior Hunter Seat Medal
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '6050',
      divisionTitle: 'USEF/NCEA Junior Hunter Seat Medal',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.EQUITATION_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <EQUITATION AA> 6110 - USEF Show Jumping Talent Search
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '6110',
      divisionTitle: 'USEF Show Jumping Talent Search',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.EQUITATION_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <EQUITATION AA> 6349 - USHJA Jumping Seat Medal
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '6349',
      divisionTitle: 'USHJA Jumping Seat Medal',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.EQUITATION_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <EQUITATION AA> 3199 - Equitation Miscellaneous
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: '3199',
      divisionTitle: 'Equitation Miscellaneous',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.EQUITATION_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
    // ************************************************************
    //  <EQUITATION AA> N/A - Short Stirrup
    {
      divisionType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.DIVISION.TYPE.STANDARD,
      divisionCode: 'N/A',
      divisionTitle: 'Short Stirrup',
      sectionItemsType: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.ITEMS.SECTION.TYPE.EQUITATION_STANDARD,
      sectionItemsRules: null,
      sections: [],
      extraSections: [],
      extraSectionTemplate: [],
    },
  ],
}
