// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import './ProfileRootPage.css'
import ProfileRootPageEditProfile from './ProfileRootPageEditProfile'
import WrapperContainer from '../../../components/common/wrappers/WrapperContainer'
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */

type Props = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
  settingsOnly?: boolean
}

const ProfileRootPage = (props: Props) => {
  return (
    <>
      <WrapperContainer title="Settings">
        <ProfileRootPageEditProfile className="mb-8" handleModal={props.handleModal} />
      </WrapperContainer>
    </>
  )
}

export default ProfileRootPage
