import moment from 'moment'
import { rgb } from 'pdf-lib'
// @ts-ignore
import { IAssignedTicket } from '../../../models/assigned-tickets/assigned-tickets.interface'
import EventService from '../../../services/eventService'
import PdfService from '../../../services/pdfService'
import FirebaseStorageService from '../../../services/storageService'

export const ticketTemplate = async (
  assigner_name: string,
  assigner_email: string,
  registrationTicket: any,
  assignedTicket: IAssignedTicket
) => {
  if (!registrationTicket?.eventId) return

  const eventService = new EventService(registrationTicket?.eventId)
  const eventLocation_ = await eventService.getEventLocation
  const eventName = await eventService.getEventKey('competitionName')
  const d = new Date()
  let time = d.getTime()

  const pdfService = new PdfService({
    pageWidth: 550,
    pageHeight: 760,
    distanceBetweenLines: 20,
    pageVerticalStartPoint: 720,
    pageHorizontalStartPoint: 35,
    pageHorizontalEndPoint: 480,
    pageVerticalEndPoint: 80,
  })

  const firstPage = await pdfService.addNewPdfPage()

  // Fonts
  const fontPoppingsBytes = await fetch('/fonts/Poppins-Regular.ttf').then((res) =>
    res.arrayBuffer()
  )
  const BoldPoppingsBytes = await fetch('/fonts/Poppins-Bold.ttf').then((res) => res.arrayBuffer())
  const PoppinsFont = await pdfService.embedFont(fontPoppingsBytes)
  const BoldPoppinsFont = await pdfService.embedFont(BoldPoppingsBytes)
  //   const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer());
  //   const jpgImage = await pdfService.embedPng(jpgImageBytes);

  const twoTicketsIcon = '/assets/template/Two Tickets.png'
  const twoticketsBytes = await fetch(twoTicketsIcon).then((res) => res.arrayBuffer())
  const twoTicketImage = await pdfService.embedPng(twoticketsBytes)

  const calenderIcon = '/assets/template/Calendar.png'
  const calenderBytes = await fetch(calenderIcon).then((res) => res.arrayBuffer())
  const calenderImage = await pdfService.embedPng(calenderBytes)

  const userIcon = '/assets/template/User.png'
  const userBytes = await fetch(userIcon).then((res) => res.arrayBuffer())
  const userImage = await pdfService.embedPng(userBytes)

  const dollerIcon = '/assets/template/Us Dollar Circled.png'
  const dollerBytes = await fetch(dollerIcon).then((res) => res.arrayBuffer())
  const dollerImage = await pdfService.embedPng(dollerBytes)

  const locationIcon = '/assets/template/Location.png'
  const locationBytes = await fetch(locationIcon).then((res) => res.arrayBuffer())
  const locationImage = await pdfService.embedPng(locationBytes)

  const cardIcon = '/assets/template/MagneticCard.png'
  const cardBytes = await fetch(cardIcon).then((res) => res.arrayBuffer())
  const cardImage = await pdfService.embedPng(cardBytes)

  const barcodeIcon = 'assets/img/barcode/fakebarcode.jpg'
  const barcodeBytes = await fetch(barcodeIcon).then((res) => res.arrayBuffer())
  const barcodeImage = await pdfService.embedJpeg(barcodeBytes)

  firstPage.drawText(`Ticket : ${registrationTicket?.ticketTitle}`, {
    x: pdfService.pageHorizontalStartPoint,
    y: 720,
    font: BoldPoppinsFont,
    size: 16,
    color: rgb(247 / 255, 7 / 255, 70 / 255),
  })

  firstPage.drawText(`Used`, {
    x: pdfService.pageHorizontalEndPoint - 13,
    y: 720,
    font: BoldPoppinsFont,
    size: 13,
    color: rgb(247 / 255, 7 / 255, 70 / 255),
  })

  firstPage.drawText(`${moment(assignedTicket.assignmentDate).format('MMM DD-YYYY')}`, {
    x: pdfService.pageHorizontalEndPoint - 27,
    y: 705,
    font: PoppinsFont,
    size: 10,
    color: rgb(247 / 255, 7 / 255, 70 / 255),
  })

  firstPage.drawText(`${eventName}`, {
    x: pdfService.pageHorizontalStartPoint + 40,
    y: 695,
    font: PoppinsFont,
    size: 11,
    color: rgb(18 / 255, 55 / 255, 70 / 255),
  })

  firstPage.drawText(`Event Name`, {
    x: pdfService.pageHorizontalStartPoint + 40,
    y: 680,
    font: PoppinsFont,
    size: 10,
    color: rgb(18 / 255, 43 / 255, 70 / 255),
  })

  firstPage.drawImage(twoTicketImage, {
    x: pdfService.pageHorizontalStartPoint,
    y: 680,
    width: 27,
    height: 27,
    opacity: 1,
  })

  firstPage.drawText(
    `${moment(registrationTicket?.created).format('MMM DD [-]')} ${moment(registrationTicket?.created).format('Do, YYYY')}`,
    {
      x: pdfService.pageHorizontalStartPoint + 40,
      y: 645,
      font: PoppinsFont,
      size: 11,
      color: rgb(18 / 255, 55 / 255, 70 / 255),
    }
  )

  firstPage.drawText(`Event Dates`, {
    x: pdfService.pageHorizontalStartPoint + 40,
    y: 630,
    font: PoppinsFont,
    size: 10,
    color: rgb(18 / 255, 43 / 255, 70 / 255),
  })

  firstPage.drawImage(calenderImage, {
    x: pdfService.pageHorizontalStartPoint,
    y: 630,
    width: 27,
    height: 27,
    opacity: 1,
  })

  firstPage.drawText(`${assigner_name}`, {
    x: pdfService.pageHorizontalStartPoint + 40,
    y: 595,
    font: PoppinsFont,
    size: 11,
    color: rgb(18 / 255, 55 / 255, 70 / 255),
  })

  firstPage.drawText(`Ticket Holder`, {
    x: pdfService.pageHorizontalStartPoint + 40,
    y: 580,
    font: PoppinsFont,
    size: 10,
    color: rgb(18 / 255, 43 / 255, 70 / 255),
  })

  firstPage.drawImage(userImage, {
    x: pdfService.pageHorizontalStartPoint,
    y: 580,
    width: 27,
    height: 27,
    opacity: 1,
  })

  firstPage.drawText(`${registrationTicket?.ticketPrice}`, {
    x: pdfService.pageHorizontalStartPoint + 40,
    y: 545,
    font: PoppinsFont,
    size: 11,
    color: rgb(18 / 255, 55 / 255, 70 / 255),
  })

  firstPage.drawText(`Ticket Price`, {
    x: pdfService.pageHorizontalStartPoint + 40,
    y: 530,
    font: PoppinsFont,
    size: 10,
    color: rgb(18 / 255, 43 / 255, 70 / 255),
  })

  firstPage.drawImage(dollerImage, {
    x: pdfService.pageHorizontalStartPoint,
    y: 530,
    width: 27,
    height: 27,
    opacity: 1,
  })

  firstPage.drawText(`${eventLocation_.combinedLocation}`, {
    x: pdfService.pageHorizontalStartPoint + 40,
    y: 495,
    font: PoppinsFont,
    size: 11,
    color: rgb(18 / 255, 55 / 255, 70 / 255),
  })

  firstPage.drawText(`Event Location`, {
    x: pdfService.pageHorizontalStartPoint + 40,
    y: 480,
    font: PoppinsFont,
    size: 10,
    color: rgb(18 / 255, 43 / 255, 70 / 255),
  })

  firstPage.drawImage(locationImage, {
    x: pdfService.pageHorizontalStartPoint,
    y: 480,
    width: 27,
    height: 27,
    opacity: 1,
  })

  firstPage.drawText(`${assigner_email}`, {
    x: pdfService.pageHorizontalStartPoint + 40,
    y: 445,
    font: PoppinsFont,
    size: 11,
    color: rgb(18 / 255, 55 / 255, 70 / 255),
  })

  firstPage.drawText(`Ticket Purchaser`, {
    x: pdfService.pageHorizontalStartPoint + 40,
    y: 430,
    font: PoppinsFont,
    size: 10,
    color: rgb(18 / 255, 43 / 255, 70 / 255),
  })

  firstPage.drawImage(cardImage, {
    x: pdfService.pageHorizontalStartPoint,
    y: 430,
    width: 27,
    height: 27,
    opacity: 1,
  })

  firstPage.drawImage(barcodeImage, {
    x: pdfService.pageHorizontalEndPoint - 45,
    y: 430,
    width: 100,
    height: 27,
    opacity: 1,
  })

  const pdfBytes = await pdfService.generatedPdf

  // Step 1: Convert Uint8Array to Blob
  var blob = new Blob([pdfBytes.buffer], { type: 'application/octet-stream' })

  // const blobUrl = URL.createObjectURL(blob);
  // window.open(blobUrl, "_blank");
  // URL.revokeObjectURL(blobUrl);

  // Step 2: Convert Blob to File
  var file = new File([blob], `${eventName}.pdf`, { type: 'application/pdf' })

  const fileUrl = await FirebaseStorageService.uploadFile(
    file,
    `/user/${time}/${assigner_name}/${assigner_email}`
  )

  return fileUrl as string
}
