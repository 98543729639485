import { Tooltip } from '@mui/material'
import clsx from 'clsx'
import TooltipIcon from '../../../../../helpers/TooltipIcon'

// Constants
const widths = [
  'shrink-0 w-[170px]',
  'shrink-0 w-[100px]',
  'shrink-0 w-[150px]',
  'shrink-0 w-[130px]',
  'grow min-w-[230px]',
  'shrink-0 w-[130px] mr-3',
]

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const TicketsHeader = () => {
  return (
    <>
      <div className="hidden lg:flex items-center justify-between w-full mt-4 gap-[10px]">
        <span className={clsx('registration_tabs_column_title', widths[0])}>
          Ticket Item
          <Tooltip
            title={<h1 className="tooltip_title">{`Ticket items added by event organizer`}</h1>}
            placement="top"
            arrow
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
        <span className={clsx('registration_tabs_column_title', widths[1])}>
          Price
          <Tooltip
            title={<h1 className="tooltip_title">{`Cost per ticket`}</h1>}
            placement="top"
            arrow
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
        <span className={clsx('registration_tabs_column_title', widths[3])}>
          Availability
          <Tooltip
            title={<h1 className="tooltip_title">{`Number of tickets remaining`}</h1>}
            placement="top"
            arrow
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
        <span className={clsx('registration_tabs_column_title !justify-start', widths[4])}>
          Details
          <Tooltip
            title={<h1 className="tooltip_title">{`Click for more details about the ticket`}</h1>}
            placement="top"
            arrow
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
        <span className={clsx('registration_tabs_column_title', widths[5])}>
          Select units
          <Tooltip
            title={
              <h1 className="tooltip_title">{`Select the number of tickets you wish to purchase`}</h1>
            }
            placement="top"
            arrow
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      </div>
    </>
  )
}

export default TicketsHeader
