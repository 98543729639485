import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { holdersSliceInitialState } from './holdersSlice.data'
import { IHoldersSliceInitialStateInterface as ihsisi } from './holdersSlice.types'
import { RootState } from '../store'
import FirestoreService from '../../services/firestoreService'
import { CONST } from '../../const/const'
import { IUserInterface } from '../../models/users/user.interface'
import { getConvertedData } from '../../models/interface.helper'
import { UserModel } from '../../models/users/user.model'

const updateUsersHolderAc = createAsyncThunk(
  'holders/updateUsersHolderAc',
  async (userIds: string[], thunkApi) => {
    let userIds_: string[] = []
    let users: IUserInterface[] = []
    let alreadyFetchedUserIds: string[] = []
    const state = thunkApi.getState() as RootState

    const usersHolder = state.holder.usersHolder

    alreadyFetchedUserIds = usersHolder.data.map((currUser) => {
      return currUser.id
    })

    userIds.forEach((currUserId) => {
      if (!!currUserId && !alreadyFetchedUserIds.includes(currUserId)) userIds_.push(currUserId)
    })

    const userSnapShots = await FirestoreService.getItemsUsingIds(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS.NAME,
      userIds_
    )

    userSnapShots.forEach((currSnapShot) => {
      users.push(getConvertedData(UserModel.fromFirestoreDoc(currSnapShot).toObject()))
    })

    return users
  }
)

const holdersSlice = createSlice({
  name: 'holders',
  initialState: holdersSliceInitialState,
  reducers: {
    setUsersHolder(state, { payload }: { payload: ihsisi['usersHolder']['data'] }) {
      state.usersHolder.data = payload
      state.usersHolder.status = 'fulfilled'
    },
    setHorsesHolder(state, { payload }: { payload: ihsisi['horsesHolder']['data'] }) {
      state.horsesHolder.data = payload
      state.usersHolder.status = 'fulfilled'
    },
    setRegistrationsHolder(state, { payload }: { payload: ihsisi['registrationHolder']['data'] }) {
      state.registrationHolder.data = payload
      state.usersHolder.status = 'fulfilled'
    },
    setEventFeesHolder(
      state,
      { payload }: { payload: ihsisi['eventsHolder']['eventFees']['data'] }
    ) {
      state.eventsHolder.eventFees.data = payload
      state.usersHolder.status = 'fulfilled'
    },
    setEventsHolder(
      state,
      { payload }: { payload: ihsisi['eventsHolder']['baseEventHolder']['data'] }
    ) {
      state.eventsHolder.baseEventHolder.data = payload
      state.usersHolder.status = 'fulfilled'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUsersHolderAc.pending, (state, action) => {
        state.usersHolder.status = 'loading'
      })
      .addCase(updateUsersHolderAc.rejected, (state, action) => {
        state.usersHolder.status = 'failed'
      })
      .addCase(updateUsersHolderAc.fulfilled, (state, action) => {
        state.usersHolder.status = 'fulfilled'
        state.usersHolder.data = [...current(state.usersHolder.data), ...action.payload]
      })
  },
})

// Redux selectors
const selectHolder = (state: RootState) => state.holder
const selectUsersHolder = (state: RootState) => state.holder.usersHolder.data
const selectUsersHolderStatus = (state: RootState) => state.holder.usersHolder.status
const selectHorsesHolder = (state: RootState) => state.holder.horsesHolder.data
const selectRegistrationsHolder = (state: RootState) => state.holder.registrationHolder.data
const selectEventFeesHolder = (state: RootState) => state.holder.eventsHolder.eventFees.data
const selectEventsHolder = (state: RootState) => state.holder.eventsHolder.baseEventHolder.data

export {
  selectHolder,
  selectUsersHolder,
  selectHorsesHolder,
  selectUsersHolderStatus,
  selectEventsHolder,
  selectEventFeesHolder,
  selectRegistrationsHolder,
  updateUsersHolderAc,
}

export const {
  setUsersHolder,
  setHorsesHolder,
  setEventsHolder,
  setEventFeesHolder,
  setRegistrationsHolder,
} = holdersSlice.actions
export default holdersSlice.reducer
