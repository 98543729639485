const IMAGE_URLS = {
  OK: '/assets/cp_icons/Ok-3.svg',
  NOT_OK: '/assets/cp_icons/Cancel-2.svg',
}

const COLS = {
  PROFILE: 'Profile',
  NAME: 'Name',
  RIDER: 'Rider',
  TRAINER: 'Trainer',
  ELIGIBILITY: 'Eligibility',
}

export const MANAGE_CLINIC_HORSES_CONSTANTS = {
  COLS,
  IMAGE_URLS,
}
