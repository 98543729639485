import ITypes from './StaffProfileTabsIndex.types'
import { CONST } from '../../../../const/const'
import StaffPaperworkTab from './paperwork/StaffPaperworkTab'
import StaffEligibilityTab from './eligibility/StaffEligibilityTab'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const StaffProfileTabsIndex = (props: ITypes['IStaffProfileTabsIndexProps']) => {
  // Hooks and vars

  if (props.activeTab === CONST.UI.STAFF.TABS[0])
    return (
      <StaffPaperworkTab
        openLightBox={props.openLightBox}
        documents={props.documents}
        staffName={props.staffName}
      />
    )

  return <StaffEligibilityTab />
}

export default StaffProfileTabsIndex
