import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import Select from 'react-select'
import { customStyles } from '../../customStyles/ReactSelectCustomStyle'

type IProps = {
  options: any[]
  onChange: (value: string) => void
  placeholder: string
  icon?: string
  value: string
  loading?: boolean
  className?: string
  onOpen?: () => void
  onClose?: () => void
  hasBackdrop?: boolean
}

const ExpandableSelector = React.forwardRef<any, IProps>(
  ({ options, onChange, placeholder, icon, value, className, onOpen, onClose, loading }, _) => {
    const [expand, setExpand] = useState(false)

    useEffect(() => {
      if (expand && onOpen) onOpen()
      if (!expand && onClose) onClose()
    }, [expand, onClose, onOpen])

    const changeHandle = (value: string) => {
      onChange(value)
      setExpand(false)
      if (onClose) onClose()
    }

    return (
      <>
        <div className="w-full relative flex">
          {loading ? (
            <div className="!bg-SeabiscuitSkeletonColor w-8 h-8 rounded-md absolute left-3 my-3 z-[1]"></div>
          ) : (
            <img src={icon} alt="icon" className="absolute left-3 w-6 h-6 my-4 z-[1]" />
          )}

          <Select
            onChange={(newValue) => {
              changeHandle(newValue?.value ?? '')
            }}
            value={
              !!value
                ? [
                    {
                      label: value,
                      value: value,
                    },
                  ]
                : []
            }
            options={options}
            isDisabled={loading}
            placeholder={placeholder}
            className={clsx('h-[56px]', className)}
            classNames={{
              container: () => 'rounded-md border-[1px] border-solid w-full',
              control: () => 'h-full !pl-11',
              valueContainer: () =>
                loading
                  ? 'h-8 bg-SeabiscuitSkeletonColor rounded-md'
                  : '!px-1 !text-SeabiscuitDark200ThemeColor',
              option: (props) => {
                return clsx((props.data as any).isPlaceholder && '!hidden !p-0')
              },
              placeholder: () =>
                loading
                  ? '!text-SeabiscuitSkeletonColor'
                  : '!text-SeabiscuitLightParagraphTextColor !text-nr',
              singleValue: () =>
                clsx('!px-0 !mx-0 !text-SeabiscuitDark200ThemeColor !capitalize', className),
            }}
            styles={
              {
                ...customStyles,
                valueContainer: (provided: any) => ({
                  ...provided,
                  textAlign: 'left',
                  justifyContent: 'start',
                }),
                singleValue: (provided: any, state: any) => ({
                  ...(customStyles?.singleValue && customStyles?.singleValue(provided, state)),
                  fontSize: '14px !important',
                  color: state.isDisabled ? '#122b46' : provided.color,
                  opacity: state.isDisabled ? '1' : provided.opacity,
                }),
                dropdownIndicator: (provided: any, state: any) => ({
                  ...provided,
                  display: state.isDisabled ? 'none' : '',
                }),
              } as any
            }
            isClearable={false}
            isMulti={false}
            isSearchable={false}
          />
        </div>
      </>
    )
  }
)

export default ExpandableSelector
