// Third party
import Lightbox from 'yet-another-react-lightbox'

import { Zoom, Captions, Video, Inline } from 'yet-another-react-lightbox/plugins'

// Styles
import 'yet-another-react-lightbox/plugins/captions.css'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/styles.css'
import './lightboxCommonComponent.css'
// Types
import { ILightboxProps } from '../../../../types/competitor_types'

const LightboxCommonComponent: React.FC<ILightboxProps> = (props) => {
  return (
    <Lightbox
      inline={props.inlineStyles ?? {}}
      carousel={
        props.carouselSettings ?? {
          finite: true,
        }
      }
      close={props?.onClose}
      open={props.visible}
      slides={props.slides}
      plugins={[Zoom, Captions, Video, ...(props.inline ? [Inline] : [])]}
    />
  )
}

export default LightboxCommonComponent
