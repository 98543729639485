export const FEIChildrenPreliminaryACompetition = {
  id: 'FEIChildrenPreliminaryACompetition',
  name: 'FEI Children Preliminary A Competition',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 200,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in working trot</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>X •</span> Proceed in working trot</p><p><span>XC •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>CMR •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>RXV •</span> Change rein on the short diagonal</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>VKA •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>AC •</span> Serpentine four loops</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>Between C & M •</span> Proceed in working canter</p><p><span>MRB •</span> Working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>B •</span> Circle (15 m Ø)</p><p><span>BPFAK •</span> Working canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>KXM •</span> On the diagonal, at X working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>MC •</span> Between M and C working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>CHSE •</span> Working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>E •</span> Circle (15 m Ø)</p><p><span>EVKA •</span> Working canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>A •</span> Working trot</p><p><span>AF •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>F •</span> Medium walk</p><p><span>FV •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>VP •</span> Half circle in medium walk (20 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>P •</span> Proceed in working trot</p><p><span>PFA •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt – immobility – salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Quality',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rider’s position and seat</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Effectiveness of aids</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Precision</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>General impression</h5>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const SheetFEIChildrenPreliminaryBCompetition = {
  id: 'SheetFEIChildrenPreliminaryBCompetition',
  name: 'FEI Children Preliminary B Competition',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 280,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in working trot</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>X •</span> Proceed in working trot</p><p><span>XC •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>HS •</span> Working trot</p><p><span>SI •</span> Half volte left (10 m Ø)</p><p><span>IR •</span> Half volte right (10 m Ø)</p><p><span>RBP •</span> Working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>PL •</span> Change rein on the short diagonal</p><p><span>LV •</span> Half volte left (10 m Ø)</p><p><span>VKAF •</span> Working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>FH •</span> Working trot</p><p><span>H •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>N/A •</span></p><p><span>HC •</span> Serpentine four loops</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>C •</span> Proceed in working canter</p><p><span>CMSE •</span> Working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>E •</span> Circle (15 m Ø)</p><p><span>EVK •</span> Working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>K •</span> On the diagonal, at X working trot</p><p><span>KAF •</span> Working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>FP •</span> Between M and C working canter</p><p><span>PV •</span> Half circle (20 m Ø) </p><p><span>N/A •</span> Between P & V give and retake the reins for 3 canter strides</p><p><span>VK •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>KD •</span> Working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>EHC •</span> Circle (15 m Ø)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>CM •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>M •</span> Medium walk</p><p><span>MR •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>RBF •</span> Half circle in medium walk (20 m Ø)</p><p><span>F •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>N/A •</span></p> Transitions at R and F<p><span>FAK •</span> Proceed in working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description: '<p><span>KLB •</span> Down the centre line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description: '<p><span>BRMC •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description: '<p><span>C •</span> Half circle in medium walk (20 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description: '<p><span>CHRB •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>B •</span> Down the centre line</p><p><span>PVP •</span> Working trot (rising trot)</p><p><span>N/A •</span> Circle (20 m Ø)</p><p><span>VP •</span>  Let the horse stretch on a long rein</p><p><span>PF •</span> Sitting trot and retake the reins</p><p><span>FA •</span> Working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt – immobility – salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Quality',
      max: 40,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rider’s position and seat</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Effectiveness of aids</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Precision</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>General impression</h5>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const SheetFEIChildrenTeamCompetition = {
  id: 'SheetFEIChildrenTeamCompetition',
  name: 'FEI Children Team Competition',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 250,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in working trot</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>X •</span> Proceed in working trot</p><p><span>XC •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>MV •</span> Medium trot</p><p><span>V •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>N/A •</span> Transitions at M and V</p><p><span>VK •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>KD •</span> Half volte (10m Ø)</p><p><span>DE •</span> Leg-yielding</p><p><span>ES •</span> Working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>SR •</span> Half circle (20 m Ø), give and retake the reins for 3-4 steps</p><p><span>RBPF •</span> Working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>FD •</span> Half volte (10m Ø)</p><p><span>DB •</span> Leg-yielding</p><p><span>BR •</span> Working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>R •</span> Medium walk</p><p><span>RS •</span> Half circle (20 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>S(E) •</span> [Medium walk]</p><p><span>Between S & E •</span> Turn on the haunches. Proceed in medium walk.</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>SH •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>H •</span> Proceed in working canter</p><p><span>HCM •</span> Working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>MRBPF •</span> Medium canter</p><p><span>F •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>N/A •</span> Transitions at M and F</p><p><span>FAK •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>KXH •</span> One loop of 10 m</p><p><span>HCMR •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>RX[V] •</span> On the short diagonal</p><p><span>X •</span> Simple change of leg</p><p><span>XVKAF •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>FXM •</span> One loop of 10 m</p><p><span>MCH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>HSEX •</span> Collected canter</p><p><span>X •</span> Simple change of leg</p><p><span>XBP •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 18,
          description: '<p><span>P •</span> Working trot</p><p><span>PFA •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Quality',
      max: 40,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rider’s position and seat</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Effectiveness of aids</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Precision</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>General impression</h5>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}

export const SheetFEIChildrenIndividual = {
  id: 'SheetFEIChildrenIndividual',
  name: 'FEI Children Individual',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 270,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in working trot</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>X •</span> Proceed in working trot</p><p><span>XC •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>HSE •</span> Medium trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>EX •</span> Transitions at M and V</p><p><span>XB •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>BPF •</span> Half volte (10m Ø)</p><p><span>FAK •</span> Leg-yielding</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>KXM •</span> Half circle (20 m Ø), give and retake the reins for 3-4 steps</p><p><span>M •</span> Working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>N/A •</span> Half volte (10m Ø)</p><p><span>MC •</span> Leg-yielding</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>CHG •</span> Medium walk</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>Between G & M •</span> [Medium walk]</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>Between G & H •</span> Medium walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>GMC •</span> Proceed in working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>C •</span> Medium canter</p><p><span>CH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>HEK •</span> Transitions at M and F</p><p><span>K •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>N/A •</span> One loop of 10 m</p><p><span>KA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>AX •</span> On the short diagonal</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description: '<p><span>X •</span> One loop of 10 m</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>XC •</span> Collected canter</p><p><span>CMR •</span> Simple change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>RI •</span> Working trot</p><p><span>I •</span> Working trot</p><p><span>IS •</span>  Half volte left (10 m Ø)</p><p><span>SE •</span>  Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>E •</span> Down the centre line</p><p><span>VPV •</span> Halt - immobility - salute</p><p><span>VP •</span> Let the horse stretch on a long rein</p><p><span>PV •</span> Retake the reins</p><p><span>VKA •</span> Working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salut</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Quality',
      max: 40,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rider’s position and seat</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Effectiveness of aids</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Precision</h5>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>General impression</h5>',
          score: '',
          coefficient: 1,
        },
      ],
    },
  ],
}
