import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

const registerState: { isOpen: boolean } = {
  isOpen: false,
}

const registerMobileMenuSlice = createSlice({
  name: 'registerMobileMenu',
  initialState: registerState,
  reducers: {
    toggleMobileMenu(state, { payload }: { payload: boolean }) {
      state.isOpen = payload
    },
  },
})

export const registerMobileMenu = registerMobileMenuSlice.actions

export const selectMenuToggle = (state: RootState) => state.mobileMenu.isOpen

export default registerMobileMenuSlice.reducer
