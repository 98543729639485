import clsx from 'clsx'
import { useState } from 'react'
import ExhibitorEligabilityAccordion from './accordions/ExhibitorEligabilityAccordion'

const Eligability = [
  {
    eligabilityType: 'Membership',
    status: 'valid',
    details: 'Membership is current',
    date: 'Thu Feb 28 2023 09:50:55 GMT+0530 (India Standard Time)',
  },
  {
    eligabilityType: 'Background check',
    status: 'valid',
    details: 'Background check is current',
    date: 'Thu Feb 27 2023 09:50:55 GMT+0530 (India Standard Time)',
  },
  {
    eligabilityType: 'Suspension',
    status: 'yes',
    details: 'SafeSport Training Overdue - allowed on competition grounds',
    date: 'Thu Feb 2 2023 09:50:55 GMT+0530 (India Standard Time)',
  },
  {
    eligabilityType: 'Suspension',
    status: 'Med',
    details: 'USADA Provisional Suspension - Human Anti-Doping',
    date: 'Thu Feb 21 2023 09:50:55 GMT+0530 (India Standard Time)',
  },
]

type IExhibitorProfileEligibilityTabProps = {
  isRefund: boolean
  isRecipient?: boolean
  exhibitorAllData?: any
}
// @@@@@@@@@@@@@@@@@@
const ExhibitorProfileEligabilityTab = ({
  exhibitorAllData,
  isRecipient,
}: {
  exhibitorAllData?: IExhibitorProfileEligibilityTabProps['exhibitorAllData']
  isRecipient: IExhibitorProfileEligibilityTabProps['isRecipient']
}) => {
  //hooks and vars
  const [problems, setProblems] = useState<number>(1)
  const [openedMenu, setOpenedMenu] = useState(true)

  const handleToggle = () => {
    setOpenedMenu(!openedMenu)
  }

  return (
    <div className="mt-2 border-[#D3DAEE] border px-4 py-2 rounded-lg">
      {/* Eligibility Accordion */}
      <div
        className={clsx(
          'text-SeabiscuitDark200ThemeColor flex justify-between items-center font-semibold cursor-pointer',
          openedMenu && 'activeAccordion'
        )}
        onClick={handleToggle}
      >
        Eligibility
        <div className="flex items-center">
          <div className="font-normal text-[14px] text-SeabiscuitDark200ThemeColor">
            {/* {`${problems} problems found`} */}
          </div>
          <div className="status text-SeabiscuitGreenThemeColor rounded-full p px-4">
            {openedMenu ? (
              <div className={'add_icon cursor-pointer'}>
                <img src={'/assets/og_icons/CloseArrow.svg'} alt="Close" />
              </div>
            ) : (
              <div className={'remove_icon cursor-pointer'}>
                <img src={'/assets/og_icons/OpenArrow.svg'} alt="Open" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={clsx('faqs-content', openedMenu && 'activeTab')}>
        <div className="faqs-content-inside mt-4">
          <ExhibitorEligabilityAccordion
            data={Eligability}
            setProblems={setProblems}
            problems={problems}
          />
        </div>
      </div>
    </div>
  )
}

export default ExhibitorProfileEligabilityTab
