// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { FIREBASE_CONFIG } from './firebase-config'
import { EVENT_PRIZE_LIST_CONFIG } from './event/prize-list/event-prize-list-config'
import { EVENT_FEES_CONFIG } from './event/fees/event-fees-config'
import { EVENT_SCORING_CONFIG } from './event/scoring/event-scoring-config'
import { EVENT_SPONSORS_CONFIG } from './event/sponsors/event-sponsors-config'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export const CONFIG = {
  APP_NAME: 'Pegasus',
  RUN_MODE: process.env.NODE_ENV,
  FIREBASE: FIREBASE_CONFIG,
  EVENT: {
    BYPASS_FORM_SEQUENCING: true,
    FEES: EVENT_FEES_CONFIG,
    PRIZE_LIST: EVENT_PRIZE_LIST_CONFIG,
    SCORING: EVENT_SCORING_CONFIG,
    SPONSORS: EVENT_SPONSORS_CONFIG,
  },
}
