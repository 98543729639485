import { PayloadAction, createSlice, current } from '@reduxjs/toolkit'
import { RootState } from '../store'
import ORGANIZER_SLICE_CONST from './organizerSlice.const'
import IOrganizerSliceType from './organizerSlice.types'
import setExhibitorsAc from './thunks/setExhibitorsAcThunk'

const organizerSlice = createSlice({
  name: 'organizer',
  initialState: ORGANIZER_SLICE_CONST.INITIAL_STATE,
  reducers: {
    setRegistrationByDayId: (state, action) => ({
      ...state,
      registrationByDayId: action.payload,
    }),
    setCurrentEntries: (state, action) => ({
      ...state,
      currentEntries: action.payload,
    }),
    resetExhibitorAc: (state) => {
      state.manageEvent.lists.exhibitors =
        ORGANIZER_SLICE_CONST.INITIAL_STATE.manageEvent.lists.exhibitors
    },
    setActionListingTab: (
      state,
      action: PayloadAction<IOrganizerSliceType['ISetActionListingTabPayload']>
    ) => {
      const { tabName } = action.payload
      state.manageEvent.lists.exhibitors = {
        ...ORGANIZER_SLICE_CONST.INITIAL_STATE.manageEvent.lists.exhibitors,
        activeTab: tabName,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setExhibitorsAc.pending, (state) => {
        let exhibitorsState = current(state).manageEvent.lists.exhibitors
        state.manageEvent.lists.exhibitors.tabs[exhibitorsState.activeTab].status = 'loading'
      })
      .addCase(setExhibitorsAc.rejected, (state, action) => {
        let exhibitorsState = current(state).manageEvent.lists.exhibitors
        state.manageEvent.lists.exhibitors.tabs[exhibitorsState.activeTab].status = 'failed'
        state.manageEvent.lists.exhibitors.tabs[exhibitorsState.activeTab].message = (
          action.payload as any
        ).message
      })
      .addCase(setExhibitorsAc.fulfilled, (state, action) => {
        const { cursorId, query, filteredData, tabName } = action.payload

        let hasMore = false
        let exhibitorsState = current(state).manageEvent.lists.exhibitors
        let perPage = exhibitorsState.tabs[tabName].query.perPage
        let docsCountInDb = exhibitorsState.tabs[tabName].docsCountInDb
        let fetchedDocsCount = exhibitorsState.tabs[tabName].fetchedDocsCount

        if (fetchedDocsCount < perPage) {
          hasMore = false
        }

        if (!cursorId && action.payload.docsCountInDb) docsCountInDb = action.payload.docsCountInDb

        state.manageEvent.lists.exhibitors.tabs[tabName].data = cursorId
          ? [...state.manageEvent.lists.exhibitors.tabs[tabName].data, ...filteredData]
          : filteredData
        state.manageEvent.lists.exhibitors.tabs[tabName].temp = []
        state.manageEvent.lists.exhibitors.tabs[tabName].batch = []
        state.manageEvent.lists.exhibitors.tabs[tabName].message = null
        state.manageEvent.lists.exhibitors.tabs[tabName].hasMore = hasMore
        state.manageEvent.lists.exhibitors.tabs[tabName].status = 'fulfilled'
        state.manageEvent.lists.exhibitors.tabs[tabName].cursorId = cursorId
        state.manageEvent.lists.exhibitors.tabs[tabName].batchRunning = false
        state.manageEvent.lists.exhibitors.tabs[tabName].docsCountInDb = docsCountInDb
        state.manageEvent.lists.exhibitors.tabs[tabName].fetchedDocsCount = fetchedDocsCount
        state.manageEvent.lists.exhibitors.activeTab = tabName

        if (query) {
          state.manageEvent.lists.exhibitors.tabs[tabName] = {
            ...state.manageEvent.lists.exhibitors.tabs[tabName],
            query: {
              ...(state.manageEvent.lists.exhibitors.tabs[tabName].query ?? {}),
              ...query,
            },
          }
        }
      })
  },
})

const organizerReducers = {
  exhibitors: (rootState: RootState) => rootState.organizer.manageEvent.lists.exhibitors,
}

const organizerSliceAcs = {
  setExhibitorsAc,
  ...organizerSlice.actions,
}

export { organizerSliceAcs }

export { organizerReducers }

export default organizerSlice.reducer
