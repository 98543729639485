// Third party
import { collection, documentId, getDocs, query, where } from 'firebase/firestore'

// Services
import FirebaseApp from '../../../services/firebaseApp'

// Helpers
import { getConvertedData } from '../../../models/interface.helper'

// Redux
import {
  setLoading,
  setPublishedEventData,
  setSelectedEventKey,
} from '../../../store/events/eventsSlice'
import { useAppDispatch } from '../../../store/hooks'

// Constants
import { useCallback } from 'react'
import { CONST } from '../../../const/const'
import { EventDetailsModel } from '../../../models/event-details/event-details.model'
import { EventFeesModel } from '../../../models/event-fees/event-fees.model'
import { EventReviewPublishModel } from '../../../models/event-review-publish/event-review-publish.model'
import { EventModel } from '../../../models/events/event.model'
import IUserGetEventDataTypes from './types'
import { ISpectatorTickets } from '../../../models/spectator-tickets/spectator-tickets.interface'
import { SpectatorTicketModel } from '../../../models/spectator-tickets/spectator-tickets-model'
import { EventSchedulingModel } from '../../../models/event-scheduling/event-scheduling.model'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants

const EVENT_PRICE_POST_CONST = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_PRIZE_LISTS
const EVENT_DETAILS_CONST = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_DETAILS
const EVENT_STAFF_POST_CONST = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_STAFF
const EVENT_SCHEDULING = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_SCHEDULING
const EVENT_FEE_POST_CONST = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_FEES
const EVENT_POLICIES_POST_CONST = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_POLICIES
const EVENT_PAPERWORK_POST_CONST = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_PAPERWORK
const EVENT_TICKETING = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_TICKETING
const EVENT_SPONSER_POST_CONST = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_SPONSORS
const EVENT_VENDORS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_VENDORS
const EVENT_NEARBY_BUSINESS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_NEARBY
const EVENT_PAYMENT_SETTINGS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_PAYMENT_SETTINGS
const EVENT_USERS_REGISTERED = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS
const EVENT = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS
const PUBLISHED_EVENT = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REVIEW_PUBLISH
const SPECTATOR_TICKET = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.SPECTATOR_TICKETS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const useGetEventData = () => {
  const dispatch = useAppDispatch()

  const getAllData: IUserGetEventDataTypes['IGetAllDataFn'] = useCallback(
    async (eventId, getTableData) => {
      let valueToReturn: IUserGetEventDataTypes['IGetAllDataFnRes'] = {} as any

      dispatch(setLoading(true))

      let event = null
      let priceList = null
      let Staff = null
      let Schedule = null
      let Fee = null
      let policies = null
      let paperWork = null
      let Tickets = null
      let Sponser = null
      let Vendors = null
      let Nearby = null
      let PaymentSnapshot = null
      let Registeredusers = null
      let spectatorTicket: ISpectatorTickets[] = []

      //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Events details @@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_review-publish')) {
        const query_ = query(
          collection(FirebaseApp.firestore, PUBLISHED_EVENT.NAME),
          where(documentId(), '==', eventId)
        )

        const document = await getDocs(query_)
        if (document.size) {
          event = EventReviewPublishModel.fromFirestoreDoc(document.docs[0]).toObject()
          valueToReturn['v01_event_review-publish'] = event
          dispatch(setPublishedEventData(getConvertedData(event)))
        }
      }

      //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Events details @@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_details')) {
        const query_ = query(
          collection(FirebaseApp.firestore, 'v01_event_details'),
          where(documentId(), '==', eventId)
        )
        const document = await getDocs(query_)

        if (document.size) {
          event = EventDetailsModel.fromFirestoreDoc(document.docs[0]).cloneDeep().toObject()
          console.log('=>(useGetEventData.tsx:102) event', event)
          valueToReturn['v01_event_details'] = event
          event.competitionId = document.docs[0].id

          console.log(
            '=>(useGetEventData.tsx:106) getConvertedData(event)',
            getConvertedData(event)
          )
          dispatch(
            setSelectedEventKey({
              key: 'EventDetails',
              value: getConvertedData(event),
            })
          )
        }
      }

      //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Events details @@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_registered_users')) {
        const query_ = query(
          collection(FirebaseApp.firestore, EVENT_USERS_REGISTERED.NAME),
          where(EVENT_USERS_REGISTERED.FIELDS.EVENT_ID.KEY, '==', eventId)
        )
        const document = await getDocs(query_)
        Registeredusers = document.docs.map((currDoc) => getConvertedData(currDoc.data()))

        valueToReturn['v01_event_registered_users'] = Registeredusers

        dispatch(
          setSelectedEventKey({
            key: 'EventRegisteredUsers',
            value: getConvertedData(Registeredusers),
          })
        )
      }

      //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Spectator tickets @@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_spectator_tickets')) {
        const query_ = query(
          collection(FirebaseApp.firestore, SPECTATOR_TICKET.NAME),
          where(SPECTATOR_TICKET.FIELDS.EVENT_ID, '==', eventId)
        )
        const document = await getDocs(query_)
        spectatorTicket = document.docs.map((currDoc) =>
          getConvertedData(SpectatorTicketModel.fromFirestoreDoc(currDoc).toObject())
        )

        valueToReturn['v01_spectator_tickets'] = spectatorTicket

        dispatch(
          setSelectedEventKey({
            key: 'EventSpectators',
            value: spectatorTicket,
          })
        )
      }

      //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Print event @@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_prize_lists')) {
        const query_Price = query(
          collection(FirebaseApp.firestore, EVENT_PRICE_POST_CONST.NAME),
          where(documentId(), '==', eventId)
        )
        const document_price = await getDocs(query_Price)
        priceList = document_price.docs.map((currDoc) => currDoc.data())[0]

        valueToReturn['v01_event_prize_lists'] = priceList

        dispatch(
          setSelectedEventKey({
            key: 'EventPriceList',
            value: getConvertedData(priceList),
          })
        )
      }

      //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EVENT_STAFF_POST_CONST @@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_staff')) {
        const query_Staff = query(
          collection(FirebaseApp.firestore, EVENT_STAFF_POST_CONST.NAME),
          where(documentId(), '==', eventId)
        )
        const document_Staff = await getDocs(query_Staff)
        Staff = document_Staff.docs.map((currDoc) => currDoc.data())[0]

        valueToReturn['v01_event_staff'] = Staff

        dispatch(
          setSelectedEventKey({
            key: 'EventStaff',
            value: getConvertedData(Staff),
          })
        )
      }

      //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EVENT_STAFF_POST_CONST @@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_events')) {
        const query_Staff = query(
          collection(FirebaseApp.firestore, EVENT.NAME),
          where(documentId(), '==', eventId)
        )
        const document_Staff = await getDocs(query_Staff)
        Staff = document_Staff.docs.map((currDoc) =>
          EventModel.fromFirestoreDoc(currDoc).toObject()
        )[0]

        valueToReturn['v01_events'] = Staff

        dispatch(
          setSelectedEventKey({
            key: 'Event',
            value: getConvertedData(Staff),
          })
        )
      }

      //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Events details @@@@@@@@@@@@@@@@@@@@@@@

      //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EVENT_FEE_POST_CONST @@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_fees')) {
        const query_Fee = query(
          collection(FirebaseApp.firestore, EVENT_FEE_POST_CONST.NAME),
          where(documentId(), '==', eventId)
        )
        const document_Fee = await getDocs(query_Fee)
        Fee = document_Fee.docs.map((currDoc) =>
          EventFeesModel.fromFirestoreDoc(currDoc).toObject()
        )[0]
        valueToReturn['v01_event_fees'] = Fee

        dispatch(
          setSelectedEventKey({
            key: 'EventFees',
            value: getConvertedData(Fee),
          })
        )
      }

      //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ EVENT_SCHEDULING_POST_CONST @@@@@@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_scheduling')) {
        const query_Scheduling = query(
          collection(FirebaseApp.firestore, EVENT_SCHEDULING.NAME),
          where(documentId(), '==', eventId)
        )
        const document_Scheduling = await getDocs(query_Scheduling)
        Schedule = document_Scheduling.docs.map((currDoc) =>
          EventSchedulingModel.fromFirestoreDoc(currDoc).toObject()
        )[0]
        valueToReturn['v01_event_scheduling'] = Schedule

        dispatch(
          setSelectedEventKey({
            key: 'EventSchedule',
            value: getConvertedData(Schedule),
          })
        )
      }

      // @@@@@@@@@@@@@@@@@@ EVENT_POLICIES_POST_CONST @@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_policies')) {
        const query_policies = query(
          collection(FirebaseApp.firestore, EVENT_POLICIES_POST_CONST.NAME),
          where(documentId(), '==', eventId)
        )
        const document_Policies = await getDocs(query_policies)
        policies = document_Policies.docs.map((currDoc) => currDoc.data())[0]
        valueToReturn['v01_event_policies'] = policies

        dispatch(
          setSelectedEventKey({
            key: 'EventPolicies',
            value: getConvertedData(policies),
          })
        )
      }

      // @@@@@@@@@@@@@@@@@@ EVENT_PAPERWORK_POST_CONST @@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_paperwork')) {
        const query_PaperWork = query(
          collection(FirebaseApp.firestore, EVENT_PAPERWORK_POST_CONST.NAME),
          where(documentId(), '==', eventId)
        )
        const document_paper = await getDocs(query_PaperWork)
        paperWork = document_paper.docs.map((currDoc) => currDoc.data())[0]
        valueToReturn['v01_event_paperwork'] = paperWork

        dispatch(
          setSelectedEventKey({
            key: 'EventPaperwork',
            value: getConvertedData(paperWork),
          })
        )
      }

      // @@@@@@@@@@@@@@@@@@ EVENT_TICKETING @@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_ticketing')) {
        const query_Tickets = query(
          collection(FirebaseApp.firestore, EVENT_TICKETING.NAME),
          where(documentId(), '==', eventId)
        )

        const document_Tickets = await getDocs(query_Tickets)
        Tickets = document_Tickets.docs.map((currDoc) => currDoc.data())[0]
        valueToReturn['v01_event_ticketing'] = Tickets

        dispatch(
          setSelectedEventKey({
            key: 'EventTickets',
            value: getConvertedData(Tickets),
          })
        )
      }

      // @@@@@@@@@@@@@@@@@@ EVENT_SPONSER_POST_CONST @@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_sponsors')) {
        const query_Sponser = query(
          collection(FirebaseApp.firestore, EVENT_SPONSER_POST_CONST.NAME),
          where(documentId(), '==', eventId)
        )
        const document_Sponser = await getDocs(query_Sponser)
        Sponser = document_Sponser.docs.map((currDoc) => currDoc.data())[0]
        valueToReturn['v01_event_sponsors'] = Sponser

        dispatch(
          setSelectedEventKey({
            key: 'EventSponsers',
            value: getConvertedData(Sponser),
          })
        )
      }

      // @@@@@@@@@@@@@@@@@@ EVENT_VENDORS @@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_vendors')) {
        const query_vendor = query(
          collection(FirebaseApp.firestore, EVENT_VENDORS.NAME),
          where(documentId(), '==', eventId)
        )
        const document_Vendors = await getDocs(query_vendor)
        Vendors = document_Vendors.docs.map((currDoc) => currDoc.data())[0]
        valueToReturn['v01_event_vendors'] = Vendors

        dispatch(
          setSelectedEventKey({
            key: 'EventVendors',
            value: getConvertedData(Vendors),
          })
        )
      }

      // @@@@@@@@@@@@@@@@@@ EVENT_NEARBY_BUSINESS @@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_nearby')) {
        const query_Nearby = query(
          collection(FirebaseApp.firestore, EVENT_NEARBY_BUSINESS.NAME),
          where(documentId(), '==', eventId)
        )
        const document_Nearby = await getDocs(query_Nearby)
        Nearby = document_Nearby.docs.map((currDoc) => currDoc.data())[0]
        valueToReturn['v01_event_nearby'] = Nearby

        dispatch(
          setSelectedEventKey({
            key: 'EventNearbyBusines',
            value: getConvertedData(Nearby),
          })
        )
      }

      // @@@@@@@@@@@@@@@@@@ EVENT_PAYMENT_SETTINGS @@@@@@@@@@@@@@@@@@@@@@@@
      if (getTableData?.includes('v01_event_payments_settings') && eventId) {
        const paymentSettingsQuery = query(
          collection(FirebaseApp.firestore, EVENT_PAYMENT_SETTINGS.NAME),
          where(documentId(), '==', eventId)
        )

        const paymentSetttingsSnapshots = await getDocs(paymentSettingsQuery)
        PaymentSnapshot = paymentSetttingsSnapshots.docs.map((currDoc) => currDoc.data())[0]
        valueToReturn['v01_event_payments_settings'] = PaymentSnapshot

        dispatch(
          setSelectedEventKey({
            key: 'EventPaymentSettings',
            value: getConvertedData(PaymentSnapshot),
          })
        )
      }

      dispatch(setLoading(false))

      return valueToReturn
    },
    [dispatch]
  )

  return { getAllData }
}

export default useGetEventData
