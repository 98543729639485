import * as yup from 'yup'

import { CONST } from '../../const/const'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'

import { IEventResultsReports } from './event-results-reports.interface'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

export class EventResultsReportsModel
  extends ModelBaseModel<IEventResultsReports>
  implements IModelBaseModel
{
  public v: IEventResultsReports['v']
  public id: IEventResultsReports['id']

  public eventId: IEventResultsReports['eventId']
  public eventName: IEventResultsReports['eventName']

  public reportType: IEventResultsReports['reportType']

  public userId: IEventResultsReports['userId']

  public rows: IEventResultsReports['rows']

  public created: IEventResultsReports['created']
  public modified: IEventResultsReports['modified']

  public constructor(obj?: IEventResultsReports) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null

    this.eventId = obj?.eventId ?? ''
    this.eventName = obj?.eventName ?? ''

    this.reportType = obj?.reportType ?? null

    this.userId = obj?.userId ?? null

    this.rows = obj?.rows ?? []

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  static fromObject(obj: any) {
    const item = new EventResultsReportsModel()

    item.v = obj?.v ?? 1
    item.id = obj?.id ?? null

    item.eventId = obj?.eventId ?? ''
    item.eventName = obj?.eventName ?? ''

    item.reportType =
      obj?.reportType ?? COLLECTIONS.EVENT_RESULTS_REPORTS.FIELDS.REPORT_TYPE.VALUES.USEF_RESULTS

    item.userId = obj?.userId ?? null

    item.rows = obj?.rows ?? []

    item.created = obj?.created ?? new Date()
    item.modified = obj?.created ?? new Date()

    return item
  }

  static fromFirestoreDoc(doc: any) {
    return new EventResultsReportsModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  static validationSchema() {
    return yup.object({}).required()
  }
}
