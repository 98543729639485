import CustomAccordion from '../../../../components/accordion/accordion'
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'

type Props = {
  title?: string
  description?: string
  step?: number
  activeTab: string
  setEventTab: any
  competitonFull?: boolean
}

const CompetitorEventRegisterConfirmationTab = (props: Props) => {
  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      <CustomAccordion />
    </CompetitorEventRegisterWrapper>
  )
}

export default CompetitorEventRegisterConfirmationTab
