import { createSlice, current } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { CONST } from '../../const/const'
import { PayloadAction } from '@reduxjs/toolkit'
import { IEventInterface } from '../../models/events/event.interface'
import { cloneDeep } from 'lodash'

export const DEFAULT_CATEGORY =
  CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.FIELDS.CATEGORIES.VALUES.CLINIC
export const DEFAULT_STATUS_ORGANIZER =
  CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CURRENT
export const FILTERS_TO_APPLY = {
  SEARCH: 'search',
  LOCATION: 'location',
  CATEGORY_FILTER: 'categoriesFilter',
  DISCIPLINE_FILTER: 'disciplineFilter',
  DATE_CATEGORY_FILTER: 'dateCategoryFilter',
  DATE_FILTER: 'dateFilter',
  PRICE_RANGE: 'priceRange',
  STATUS_FILTER: 'statusFilter',
}

type IInitialSystemState = {
  activeFilters: {
    search: {
      status: boolean
      searchValue: string
    }
    location: {
      status: boolean
      location: string
      lat: number
      lng: number
      radius: number
    }
    map: {
      status: boolean
      data: IEventInterface[]
      lat: number
      lng: number
      loading: boolean
      focusedMarkerId: string | null
      focusOnSearchedLocation: boolean
      panelZoomLevel: number
      locationModalZoomLevel: number
    }
    groupFilters: {
      status: boolean
      endDate: null | Date
      startDate: null | Date
      categoriesFilter: string[]
      disciplineFilter: string[]
      dateCategoryFilter: string
      priceRange: null | string
    }
    statusFilter: null | string
  }
  filtersNotApplied: string[]
}

export const initialSystemStateFilters: IInitialSystemState = {
  activeFilters: {
    search: {
      status: false,
      searchValue: '',
    },
    location: {
      status: false,
      location: '',
      lat: 0,
      lng: 0,
      radius: 0,
    },
    map: {
      data: [],
      status: false,
      lat: CONST.UI.MAP.DEFAULT_LOCATION.lat,
      lng: CONST.UI.MAP.DEFAULT_LOCATION.lng,
      loading: false,
      focusedMarkerId: null,
      focusOnSearchedLocation: false,
      panelZoomLevel: 12,
      locationModalZoomLevel: 12,
    },
    groupFilters: {
      status: false,
      endDate: null,
      startDate: null,
      priceRange: null,
      categoriesFilter: [],
      disciplineFilter: [],
      dateCategoryFilter: '',
    },
    statusFilter: null,
  },
  filtersNotApplied: [],
}

const filterSlice = createSlice({
  name: 'filters',
  initialState: initialSystemStateFilters,
  reducers: {
    setActiveFilters(state, { payload }: { payload: IInitialSystemState['activeFilters'] | null }) {
      if (!payload) state.activeFilters = initialSystemStateFilters.activeFilters
      else state.activeFilters = payload
    },
    setFiltersNotApplied(state, { payload }: { payload: string[] }) {
      state.filtersNotApplied = payload
    },
    setMapVisibilityAc(state, action: PayloadAction<boolean>) {
      state.activeFilters.map.status = action.payload
    },
    setMapCenterAc(state, action: PayloadAction<{ lat: number; lng: number }>) {
      const { lat, lng } = action.payload
      state.activeFilters.map.lat = lat
      state.activeFilters.map.lng = lng
    },
    setMapLoadingAc(state, action: PayloadAction<boolean>) {
      state.activeFilters.map.loading = action.payload
    },
    setMapFocusedMarkedIdAc(
      state,
      action: PayloadAction<IInitialSystemState['activeFilters']['map']['focusedMarkerId']>
    ) {
      state.activeFilters.map.focusedMarkerId = action.payload
    },
    setMapDataAc(
      state,
      action: PayloadAction<IInitialSystemState['activeFilters']['map']['data']>
    ) {
      state.activeFilters.map.data = action.payload
    },
    setZoomLevelAc(
      state,
      action: PayloadAction<{
        level: IInitialSystemState['activeFilters']['map']['panelZoomLevel']
        forPanel: boolean
      }>
    ) {
      if (action.payload.forPanel) state.activeFilters.map.panelZoomLevel = action.payload.level
      else state.activeFilters.map.locationModalZoomLevel = action.payload.level
    },
    resetLocationModalDataAc(state) {
      let clone = cloneDeep(current(state.activeFilters))
      clone.map = {
        ...clone.map,
        focusedMarkerId: initialSystemStateFilters.activeFilters.map.focusedMarkerId,
        locationModalZoomLevel: initialSystemStateFilters.activeFilters.map.locationModalZoomLevel,
      }
      clone.location = {
        ...clone.location,
        status: false,
        location: initialSystemStateFilters.activeFilters.location.location,
      }
      state.activeFilters = clone
    },
    setMapFocusOnSearchedLocationAc(
      state,
      action: PayloadAction<IInitialSystemState['activeFilters']['map']['focusOnSearchedLocation']>
    ) {
      state.activeFilters.map.focusOnSearchedLocation = action.payload
    },
  },
})

export const {
  setActiveFilters,
  setFiltersNotApplied,
  setMapVisibilityAc,
  setZoomLevelAc,
  setMapCenterAc,
  resetLocationModalDataAc,
  setMapLoadingAc,
  setMapDataAc,
  setMapFocusedMarkedIdAc,
  setMapFocusOnSearchedLocationAc,
} = filterSlice.actions

export const selectMapFilters = (state: RootState) => state?.filters.activeFilters.map
export const selectMapDataR = (state: RootState) => state?.filters.activeFilters.map.data
export const selectMapFocusOnSearchedLocationR = (state: RootState) =>
  state?.filters.activeFilters.map.focusOnSearchedLocation
export const selectMapLoadingR = (state: RootState) => state?.filters.activeFilters.map.loading
export const selectMapFocusedMarkerIdR = (state: RootState) =>
  state?.filters.activeFilters.map.focusedMarkerId
export const selectMapCenterR = (state: RootState) => ({
  lat: state?.filters.activeFilters.map.lat,
  lng: state?.filters.activeFilters.map.lng,
})
export const selectedfilters = (state: RootState) => state?.filters.activeFilters ?? []
export const selectFiltersNotApplied = (state: RootState) => state?.filters.filtersNotApplied ?? []

export default filterSlice.reducer
