export const UI_SPECTATOR_CONST = {
  TABS: {
    TICKETS: 'Tickets',
    EMAIL: 'Email',
    RECEIPT: 'Receipt',
    PAY: 'Pay',
  },
}

const SPECTATOR_TICKETS = [
  {
    tabName: UI_SPECTATOR_CONST.TABS.TICKETS,
    activeIcon: '/assets/og_icons/Two Tickets-2.svg',
    icon: '/assets/og_icons/Two Tickets-1.svg',
    max_steps: 2,
  },
  {
    tabName: UI_SPECTATOR_CONST.TABS.EMAIL,
    activeIcon: '/assets/og_icons/Mail-2.svg',
    icon: '/assets/og_icons/Mail-1.svg',
    max_steps: 2,
  },
  {
    tabName: UI_SPECTATOR_CONST.TABS.PAY,
    activeIcon: '/assets/og_icons/Us Dollar Circled-2.svg',
    icon: '/assets/og_icons/Us Dollar Circled-1.svg',
    max_steps: 2,
  },
]

export { SPECTATOR_TICKETS }
