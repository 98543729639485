const TABS = {
  active: 0,
  tablist: ['Unsigned', 'Signed', 'Shared'],
  tabDescriptionList: [
    'Review and sign the paperwork below',
    'View, download and share your signed documents',
    'View and download share documents',
  ],
}

export const PAPERWORK_DATA = {
  TABS,
}
