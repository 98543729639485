import clsx from 'clsx'

type IProps = {
  src: string
  alt: string
  className?: string
}

const Image = ({ src, alt, className }: IProps) => {
  return (
    <img
      src={src}
      alt={alt}
      className={clsx(
        `object-cover border border-solid border-SeabiscuitLightThemeColorD3 rounded-xl`,
        className
      )}
    />
  )
}

export default Image
