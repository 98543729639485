export const horseList = [
  {
    label: 'Baroque',
    name: 'Boroque',
    value: 'baroque',
    icon: '/assets/og_icons/YearofHorse-1.svg',
    id: '1',
  },
  {
    label: 'Barrel Racing',
    name: 'Barrel Racing',
    value: 'barrelRacing',
    icon: '/assets/og_icons/YearofHorse-1.svg',
    id: '2',
  },
  {
    label: 'Breeding',
    name: 'Breeding',
    value: 'breeding',
    icon: '/assets/og_icons/YearofHorse-1.svg',
    id: '3',
  },
  {
    label: 'Hunter',
    name: 'Hunter',
    value: 'hunter',
    icon: '/assets/og_icons/YearofHorse-1.svg',
    id: '4',
  },
  {
    label: 'Mr Tobins',
    name: 'Mr Tobins',
    value: 'Mr Tobins',
    icon: '/assets/og_icons/YearofHorse-1.svg',
    id: '5',
  },
]
