import { CONST } from '../../../../../../../const/const'
import { IExhibitorDataTypes } from './exhibitor.data.types'

const IMAGE_URLS = {
  OK: '/assets/cp_icons/Ok-3.svg',
  NOT_OK: '/assets/cp_icons/Cancel-2.svg',
}

const TABS = [
  { tab: 'All', id: '1' },
  { tab: 'Compliant', id: '2', disabled: true },
  { tab: 'Non-Compliant', id: '3', disabled: true },
  { tab: 'Scratched', id: '4', disabled: true },
]

const COLS = {
  NAME: 'Name',
  ROLE: 'Role',
  TEAM: 'Team',
  HORSE: 'Horse',
  PAYMENT: 'Payment',
  PROFILE: 'Profile',
  CONTACT: 'Contact',
  EXHIBITOR: 'Staff',
  SCRATCHED: 'Scratched',
  PAPERWORK: 'Paperwork',
  ELIGIBILITY: 'Eligibility',
}

const EVENT_USERS_REGISTERED = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REGISTERED_USERS

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const REGISTER_TABLE_FIELDS = EVENT_USERS_REGISTERED.FIELDS

const PAYMENT_STATUS = { PAID: 'paid' }

const DEFAULT_PROFILE_PIC = '/assets/og_icons/User-1.svg'

const DEFAULT_VALUES = {
  EMPTY: '',
  UNKNOWN: 'Unknown',
  PROFILE_PIC: DEFAULT_PROFILE_PIC,
}

const QUEUEDDATA_INI_VAL: IExhibitorDataTypes['IQueuedData'] = {
  rehit: false,
  registrations: [],
  processingOn: null,
  batchStatus: 'stop',
  scrollWasCancelled: false,
  registrationsWithTeamMembers: [],
}

export const MANAGE_CLINIC_EXHIBITOR_CONSTS = {
  COLS,
  TABS,
  IMAGE_URLS,
  COLLECTIONS,
  DEFAULT_VALUES,
  PAYMENT_STATUS,
  QUEUEDDATA_INI_VAL,
  DEFAULT_PROFILE_PIC,
  EVENT_USERS_REGISTERED,
  REGISTER_TABLE_FIELDS,
}
