export const DATA_FIRESTORE_V01_EVENT_DRAFTS = {
  NAME: 'v01_event_drafts',

  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    TYPE: {
      KEY: 'type',
      VALUE: {
        REGULAR: 'regular',
        COMPETITION: 'competition',
      },
    },

    ID: {
      KEY: 'id',
    },

    TAB: {
      KEY: 'tab',
      VALUE: {
        STATUS: 'Status',
        CLASSES: 'Classes',
        HORSES: 'Horse',
        FEES: 'Fees',
        PAPERWORK: 'Paperwork',
        SIGN: 'Sign',
        CONFIRMATION: 'Confirmation',
        REVIEWANDPAY: 'Review And Pay',
        REGISTER: 'Register',
        TICKETS: 'Tickets',
      },
    },

    STEP: {
      KEY: 'step',
    },

    DRAFT: {
      KEY: 'draft',
      VALUE: {
        STATUS: 'Status',
        CLASSES: 'Classes',
        HORSES: 'Horse',
        FEES: 'Fees',
        PAPERWORK: 'Paperwork',
        SIGN: 'Sign',
        CONFIRMATION: 'Confirmation',
        REVIEWANDPAY: 'ReviewAndPay',
        REGISTER: 'Register',
        TICKETS: 'Tickets',
        EMPTY_OBJECT: {},
        EMPTY_ARRAY: [],
      },
    },

    USER_ID: {
      KEY: 'userId',
    },

    EVENT_ID: {
      KEY: 'eventId',
    },

    STATUS: {
      KEY: 'status',
    },

    PAYMENT_STATUS: {
      KEY: 'paymentStatus',
    },
  },
}
