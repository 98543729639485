import { useState } from 'react'

import { Clear } from '@mui/icons-material'

type Props = {
  show: boolean
  handleModal: any
}

const RegisterForCompetitionPayModal = (props: Props) => {
  const [paymentStatus, setPaymentStatus] = useState(false)
  const sendPayment = () => {
    setPaymentStatus(true)
  }

  return (
    <div
      className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pt-10 pb-7 px-8">
          <span
            onClick={() => props.handleModal(false, 'competitonPay')}
            className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
          >
            {/* <img
                            src="/assets/og_icons/Close.svg"
                            className="w-6"
                            alt="close icon"
                        /> */}
            <Clear
              fontSize={'small'}
              style={{
                color: 'grey',
                fontWeight: '400',
              }}
            />
          </span>
          {!paymentStatus && (
            <>
              <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
                <h5
                  className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2"
                  id="exampleModalScrollableLabel"
                >
                  Send invoice
                </h5>
              </div>
              <div className="min-h-1/2">
                <div className="mt-3 flex w-full border-solid p-3 border-[#D3DAEE] border rounded-2xl">
                  <div className="">
                    <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                      Send an inovice to:
                    </p>
                    <div className="flex w-full items-center mt-2">
                      <img src="assets/img/User.svg" alt="user" />
                      <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2">
                        Jennery Trotter
                      </p>
                    </div>
                    <div className="flex w-full items-center mt-2">
                      <img src="assets/img/Dollarcoin.svg" alt="dollar" />
                      <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2">$2,000</p>
                    </div>
                    <div className="flex w-full items-center mt-2">
                      <img src="assets/img/Error.svg" alt="error" />
                      <p className="text-sm text-SeabiscuitDark200ThemeColor ml-2">$2,000</p>
                      <p className="text-sm text-SeabiscuitDark200ThemeColor ">
                        Payment required to complete registration
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="w-full h-12 mx-auto py-2 px-4 mt-5 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:text-SeabiscuitMainThemeColor hover:bg-SeabiscuitLightThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
                onClick={sendPayment}
              >
                SEND
              </button>

              <button
                onClick={() => props.handleModal(false, 'competitonPay')}
                type="button"
                className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
              >
                CANCAL
              </button>
            </>
          )}
          {paymentStatus && (
            <>
              <div className="mx-auto">
                <img src="assets/img/Ok.svg" alt="tick" />
                <h6 className="text-center text-SeabiscuitDark200ThemeColor font-semibold mt-3 mb-3">
                  Success!
                </h6>
                <p className="text-center text-SeabiscuitDark200ThemeColor mt-3 mb-3  ">
                  Invoice sent
                </p>
              </div>
              <button
                onClick={() => props.handleModal(false, 'competitonPay')}
                type="button"
                className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
              >
                CLOSE
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default RegisterForCompetitionPayModal
