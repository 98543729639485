import React from 'react'
import FirebaseApp from '../services/firebaseApp'
import { selectUserType } from '../store/user/userSlice'
import { useAppSelector } from '../store/hooks'
import { IUserMode } from '../const/use-mode'
import { CONST } from '../const/const'
import { Redirect } from 'react-router'
import ViewsLoader from '../components/loader/ViewsLoader'

type IProps = {
  children: React.ReactElement | null
  scope: IUserMode[]
}

const ProtectedRoute = (props: IProps) => {
  const currentUser = FirebaseApp.auth.currentUser

  let unauthorize = false
  let redirectPath: string | null = null
  let userType = useAppSelector(selectUserType) as IUserMode

  if (!!!userType) userType = null

  if (currentUser && !userType)
    return (
      <ViewsLoader
        isPageLoader={true}
        className="flex items-center w-full justify-center h-screen"
        size="xl"
        color="red"
      />
    )

  if (!props.scope.includes(userType)) {
    unauthorize = true
  }

  if (unauthorize) {
    switch (userType) {
      case 'competitor':
        redirectPath = CONST.ROUTES.HOME.URL
        break
      case 'organizer':
        redirectPath = CONST.ROUTES.ORGANIZER_HOME.URL
        break
      case 'vendor':
        redirectPath = CONST.ROUTES.SPONSOR_AND_VENDOR.URL
        break
      default:
        redirectPath = CONST.ROUTES.SEARCH.URL
    }
  }

  if (unauthorize && redirectPath) return <Redirect to={redirectPath} />

  return props.children
}

export default ProtectedRoute
