import React, { useEffect } from 'react'

// Third party
import clsx from 'clsx'
import { v4 } from 'uuid'

// Types
import { IEventDetailsUi } from '../../../../types/competitor_types'

// Redux
import { setScratchEvents } from '../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { selectRegistrationR } from '../../../../store/exhibitor/exhibitorSlice'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import { Tooltip } from '@mui/material'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { CONST } from '../../../../const/const'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES

type IExhibitorRegistrationAccordionProps = {
  registrationsByDay: IRegistrationByDayInterface[]
  registrationPrice: number
  showScratchedView: boolean
  setListToScratch: React.Dispatch<React.SetStateAction<string[]>>
  listToScratch: string[]
}

type IDetailRowProps = {
  title: string
  cost?: number | null
  showScratchedView: boolean
  listToScratch: string[]
  eventDate: Date | string | null
  member: IRegistrationByDayInterface
  handleScratchList: (eventDate: string | Date, memberId: string) => void
}

type IEventsToScratch = {
  memberId: string
  eventDate: string | Date
}

const ExhibitorRegisrationInput: React.FC<{
  className?: string
  data?: any
  data1?: any
  icon?: string
}> = ({ className, icon, data }) => {
  return (
    <div className={`${className}`}>
      {icon ? <img className="w-5 h-5 absolute left-3" src={icon} alt="icon" /> : null}
      <div className="ml-2">{data}</div>
    </div>
  )
}

const DetailRow = (props: IDetailRowProps) => {
  let id = `${v4()}${Date.now()}`
  let { title, cost, member, showScratchedView, listToScratch } = props
  let checked = member.id ? listToScratch.find((data) => data === member.id) : false

  if (isNaN(Number(cost))) return null

  return (
    <div className="border rounded-md shadow-sm border-[#D3DAEE] lg:border-0 lg:shadow-none p-2 lg:p-0 mt-4 lg:mt-0 flex flex-col lg:flex-row w-full">
      <div className="lg:hidden font-medium text-[12px]">Class</div>
      <ExhibitorRegisrationInput
        className="rounded-lg flex items-center text-sm text-SeabiscuitDark200ThemeColor min-h-[48px] bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4"
        data={
          <div className="flex flex-wrap items-center gap-1">
            {title}
            {member.isSratched ? (
              <span
                className={'bg-[#122B461A] text-[#122B46] text-[10px] px-2 py-1 rounded-[20px]'}
              >
                Scratched
              </span>
            ) : (
              ''
            )}
            {member.isQualify ? (
              <span
                className={
                  'border border-SeabiscuitDark200ThemeColor text-[#122B46] text-[10px] px-2 py-1 rounded-[20px]'
                }
              >
                Qualifying
              </span>
            ) : (
              ''
            )}
          </div>
        }
      />

      <div className="lg:hidden mt-2 font-medium text-[12px]">Horse</div>
      <ExhibitorRegisrationInput
        className="rounded-lg flex items-center justify-center text-sm text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4 text-center relative"
        data={member.horseName ? member.horseName : 'N/A'}
        icon={'/assets/og_icons/YearofHorse-1.svg'}
      />

      <div className="lg:hidden mt-2 font-medium text-[12px]">Rider</div>
      <ExhibitorRegisrationInput
        className="rounded-lg flex items-center justify-center text-sm text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4 text-center relative"
        data={member ? member?.riderName : 'N/A'}
      />

      <div className="lg:hidden mt-2 font-medium text-[12px]">Price</div>
      <ExhibitorRegisrationInput
        className="relative rounded-lg flex items-center justify-center text-sm text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 lg:m-1 w-full lg:w-1/4 text-center"
        data={
          member?.isSratched ? (
            'Scratched'
          ) : (
            <span>
              ${cost} {member.isQualify && <span> • ${member.qualifyFee} qual</span>}
            </span>
          )
        }
        icon={
          member.paymentStatus === PAYMENT_STATUSES.PAID
            ? '/assets/cp_icons/Ok-3.svg'
            : '/assets/cp_icons/Cancel-2.svg'
        }
      />

      {showScratchedView ? (
        <label
          htmlFor={id}
          className={clsx(
            'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor bg-transparent border border-[#d3daee] p-3 m-1 w-1/4 text-center relative flex items-center justify-center',
            !member?.isSratched && 'cursor-pointer'
          )}
        >
          {member?.isSratched ? (
            'Scratched'
          ) : (
            <>
              Scratch
              <div
                className={clsx(
                  'ml-2 !ring-0 !shadow-none absolute shrink-0 flex items-center justify-center',
                  !checked
                    ? 'border-2 border-SeabiscuitDark200ThemeColor rounded-full w-5 h-5 right-[14px]'
                    : 'w-6 h-6 right-3'
                )}
              >
                {checked ? (
                  <img
                    src="/assets/og_icons/Ok-3.svg"
                    alt="Ok"
                    className="w-full h-full object-cover"
                  />
                ) : null}
              </div>
              <input
                type="checkbox"
                className="hidden"
                id={id}
                checked={!!checked}
                onChange={() =>
                  props.handleScratchList(
                    props?.eventDate ?? '',
                    member?.id ? member?.id.toString() : ''
                  )
                }
              />
            </>
          )}
        </label>
      ) : null}
    </div>
  )
}

const ExhibitorRegistrationAccordion = ({
  registrationsByDay,
  showScratchedView,
  setListToScratch,
  listToScratch,
}: IExhibitorRegistrationAccordionProps) => {
  // Hooks and vars
  const toastMethods = useToasterHelper()

  const dispatch = useAppDispatch()
  const registrationR = useAppSelector(selectRegistrationR)

  const handleShowToast = () => {
    return toastMethods.info({ message: 'Selected member Bill has not yet paid' })
  }

  useEffect(() => {
    setListToScratch([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showScratchedView, registrationR])

  const handleScratchList = (eventDate: string | Date, memberId: string) => {
    let updatedDataList_: IEventDetailsUi[] = []
    let listToScratch_ = new Set(listToScratch)
    let newstr = [...listToScratch]
    let objToAdd: IEventsToScratch = { eventDate, memberId }
    let stringifiedObj = JSON.stringify(objToAdd)
    let alreadyPresent = listToScratch_.has(stringifiedObj)

    if (alreadyPresent) listToScratch_.delete(stringifiedObj)
    else listToScratch_.add(stringifiedObj)

    if (listToScratch.find((data) => data === memberId)) {
      let filter = listToScratch.filter((data) => data !== memberId)
      setListToScratch(filter)
    } else {
      let memberData = registrationsByDay.filter((registration) => registration.id === memberId)
      if (memberData.length && memberData[0].paymentStatus !== 'paid') {
        handleShowToast()
      } else {
        setListToScratch([...newstr, memberId])
      }
    }

    dispatch(setScratchEvents(updatedDataList_))
  }
  return (
    <>
      <div className="hidden lg:flex items-center w-full">
        <ExhibitorRegisrationInput
          className={clsx(
            'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center text-xs">Class</span>}
        />

        <ExhibitorRegisrationInput
          className={clsx(
            'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center text-xs">Horse</span>}
        />

        <ExhibitorRegisrationInput
          className={clsx(
            'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center text-xs">Rider</span>}
        />

        <ExhibitorRegisrationInput
          className={clsx(
            'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold',
            showScratchedView ? 'w-1/5' : 'w-1/4'
          )}
          data={<span className="flex items-center text-xs">Price</span>}
        />

        {showScratchedView ? (
          <ExhibitorRegisrationInput
            className={clsx(
              'xl:text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor font-semibold w-1/5'
            )}
            data={
              <span className="flex items-center">
                Scratch
                <Tooltip title={<h1 className="tooltip_title">Scratch</h1>} placement="top" arrow>
                  <button className="">
                    <TooltipIcon color="#122B46" />
                  </button>
                </Tooltip>
              </span>
            }
          />
        ) : null}
      </div>

      {registrationsByDay && registrationsByDay.length
        ? registrationsByDay.map((currentItem, index) => {
            return (
              <DetailRow
                key={`${JSON.stringify(currentItem)}${index}`}
                title={currentItem.registrationByDayName ?? 'N/A'}
                listToScratch={listToScratch}
                member={currentItem}
                eventDate={currentItem?.eventDate ? currentItem?.eventDate : ''}
                handleScratchList={handleScratchList}
                showScratchedView={showScratchedView}
                cost={currentItem.registrationPrice}
              />
            )
          })
        : null}

      {/* <div className='flex items-center w-full'>

                <ExhibitorRegisrationInput
                    className={
                        clsx(
                            'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-bold h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1',
                            showScratchedView ? "w-1/5" : "w-1/4"
                        )}
                    data={showScratchedView ? "Total" : `Total`}
                    data1={!showScratchedView ? `${props.registrationCounts.registrationCount} registrations` : ''}
                />

                {!showScratchedView ?
                    <>
                        <ExhibitorRegisrationInput
                            className={
                                clsx(
                                    'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-bold h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1 text-center',
                                    showScratchedView ? "w-1/5" : "w-1/4"
                                )}
                            data={`${data.length} horses`}
                        />

                        <ExhibitorRegisrationInput
                            className={
                                clsx(
                                    'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-bold h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1 text-center',
                                    showScratchedView ? "w-1/5" : "w-1/4"
                                )}
                            data={`${data.length} riders`}
                        />

                    </> : null}

                <ExhibitorRegisrationInput
                    className={
                        clsx(
                            'rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-bold h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1',
                            showScratchedView ? "w-[calc(80%+24px)] text-right" : "w-1/4 text-center"
                        )}
                    data={(() => {
                        if (!showScratchedView)
                            return `$${registrationTotal}`
                        else
                            return `${listToScratch.length} ${listToScratch.length > 1 ? "items" : "item"} scratched, $${registrationTotal} refund requested`
                    })()}
                />

            </div> */}
    </>
  )
}

export default ExhibitorRegistrationAccordion
