import { useState } from 'react'
import { toast, Id } from 'react-toastify'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
interface IToastArgs {
  message: string
  autoClose?: number
}

interface IToastArgsWithErrorAndToastId extends IToastArgs {
  loadingToastId: Id
  isError: boolean
}

const toastFunctionsObj = {
  loading: ({ message }: IToastArgs) =>
    toast.loading(message, {
      autoClose: 5000,
      position: 'top-center',
      hideProgressBar: true,
      theme: 'light',
    }),
  info: ({ message, autoClose = 5000 }: IToastArgs) =>
    toast.info(message, {
      autoClose,
      position: 'top-center',
      hideProgressBar: true,
      theme: 'light',
    }),
  error: ({ message, autoClose = 5000 }: IToastArgs) =>
    toast.error(message, {
      autoClose,
      position: 'top-center',
      hideProgressBar: true,
      theme: 'light',
    }),
  success: ({ message, autoClose = 5000 }: IToastArgs) =>
    toast.success(message, {
      autoClose,
      position: 'top-center',
      hideProgressBar: true,
      theme: 'light',
    }),
  updateLoading: ({ loadingToastId, message, isError }: IToastArgsWithErrorAndToastId) => {
    toast.update(loadingToastId, {
      type: isError ? 'error' : 'success',
      render: message,
      position: 'top-center',
      hideProgressBar: true,
      theme: 'light',
    })
  },
  stopLoading: ({
    loadingToastId,
    isError,
    message,
    autoClose = 5000,
  }: IToastArgsWithErrorAndToastId) =>
    toast.update(loadingToastId, {
      autoClose,
      type: isError ? 'error' : 'success',
      isLoading: false,
      render: message,
      position: 'top-center',
      hideProgressBar: true,
      theme: 'light',
    }),
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const useToasterHelper = () => {
  const toastFunctions = useState(toastFunctionsObj)[0]
  return toastFunctions
}

export { toastFunctionsObj }
export default useToasterHelper
