import React, { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import { sumBy } from 'lodash'

import {
  IJudge,
  IRegistrationByDayInterface,
} from '../../../../models/registrations-by-day/registrationByDay.interface'
import { toFixed } from '../../../../helpers/helpers'

import styles from './JudgeItem.module.css'

interface JudgeItemProps {
  judge: IJudge
  judgeIndex: number
  results: IRegistrationByDayInterface['score']
  setResults: (value: IRegistrationByDayInterface['score']) => void
  editable: boolean
  maxPoints: number
  isLocked: boolean
  showLockedModal: () => void
  isRemarks: boolean
}

export const JudgeItem: FC<JudgeItemProps> = ({
  judge,
  judgeIndex,
  results,
  setResults,
  editable,
  maxPoints,
  isLocked,
  showLockedModal,
  isRemarks,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [totalJudgePoints, setTotalJudgePoints] = useState(0)

  useEffect(() => {
    if (results?.judges && results.judges[judgeIndex]?.sections?.length > 0) {
      let totalPoints_ = 0

      results.judges[judgeIndex].sections.forEach((section) =>
        section.rows.forEach((row) => {
          if (row.coefficient) {
            totalPoints_ += Number(row.coefficient) * Number(row.score)
          } else if (row.fraction === 'minus') {
            totalPoints_ -= Number(row.score)
          } else {
            totalPoints_ += Number(row.score)
          }
        })
      )

      totalPoints_ -= results.judges[judgeIndex].errors || 0

      setTotalJudgePoints(totalPoints_)
    } else {
      setTotalJudgePoints(0)
    }
  }, [judge.sections, judgeIndex, results])

  return (
    <div className={clsx('p-1 border border-SeabiscuitLightThemeColorD3 rounded-md', styles.judge)}>
      <div
        className="flex gap-2 cursor-pointer transition-all hover:opacity-70"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img className="w-[45px] bg-SeabiscuitGrayThemeColor rounded-md" src="" alt="" />
        <div>
          <p className="text-[12px] text-SeabiscuitDark200ThemeColor/50">Judge {judgeIndex + 1}</p>
          {judge.name}
        </div>
        <div className="ml-auto flex items-center gap-2">
          <p>
            {totalJudgePoints} / {maxPoints}
          </p>
          <p className="bg-SeabiscuitGrayThemeColor rounded-full p-2 min-w-[72px] text-center">
            {toFixed((totalJudgePoints / maxPoints) * 100, 3)}%
          </p>
        </div>
      </div>
      {isOpen && (
        <div className="mt-1 px-2 border-t border-SeabiscuitLightThemeColorD3">
          {judge.sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mt-4 mb-4">
              {sectionIndex !== 0 && <hr />}
              <div className="my-4 flex justify-between items-center">
                <h4 className="text-[20px]">{section.name}</h4>
                <p className="bg-SeabiscuitGrayThemeColor rounded-full p-2 min-w-[102px] text-center">
                  Max: {section.max}
                </p>
              </div>
              <div className="flex gap-2">
                {section.type === 'test' ? (
                  <>
                    <div className="text-[12px] text-SeabiscuitDark200ThemeColor/50 w-[56px]">
                      Mvmt.
                    </div>
                    <div className="text-[12px] text-SeabiscuitDark200ThemeColor/50 flex-1">
                      Test
                    </div>
                    <div className="text-[12px] text-SeabiscuitDark200ThemeColor/50 w-[56px]">
                      Score
                    </div>
                    <div className="text-[12px] text-SeabiscuitDark200ThemeColor/50 w-[56px]">
                      Coeffic.
                    </div>
                    <div className="text-[12px] text-SeabiscuitDark200ThemeColor/50 w-[56px]">
                      Final Sc.
                    </div>
                  </>
                ) : section.type === 'score' ? (
                  <>
                    <div className="text-[12px] text-SeabiscuitDark200ThemeColor/50 w-[56px]">
                      Mvmt.
                    </div>
                    <div className="text-[12px] text-SeabiscuitDark200ThemeColor/50 flex-1">
                      Test
                    </div>
                    <div className="text-[12px] text-SeabiscuitDark200ThemeColor/50 w-[56px]">
                      Score
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-[12px] text-SeabiscuitDark200ThemeColor/50 flex-1">
                      Mark
                    </div>
                    <div className="text-[12px] text-SeabiscuitDark200ThemeColor/50 w-[56px]">
                      Score
                    </div>
                    <div className="text-[12px] text-SeabiscuitDark200ThemeColor/50 w-[56px]">
                      Coeffic.
                    </div>
                    <div className="text-[12px] text-SeabiscuitDark200ThemeColor/50 w-[56px]">
                      Final Sc.
                    </div>
                  </>
                )}
              </div>
              <div className="flex flex-col gap-2">
                {section.rows.map((row, rowIndex) => (
                  <React.Fragment key={rowIndex}>
                    <div className={clsx('flex gap-2 min-h-[56px]', styles.item)}>
                      {Number(row.number) >= 0 && (
                        <div className="p-2 w-[56px] bg-SeabiscuitGrayThemeColor rounded-md flex items-center justify-center font-bold">
                          {row.number}
                        </div>
                      )}
                      <div
                        className="p-2 flex-1 bg-SeabiscuitGrayThemeColor rounded-md flex flex-col justify-center"
                        dangerouslySetInnerHTML={{ __html: row.description }}
                      />
                      <input
                        disabled={!editable}
                        className={clsx(
                          'p-2 border rounded-md flex items-center justify-center text-center',
                          row.coefficient || section.type === 'score'
                            ? 'w-[56px]'
                            : 'w-[184px] font-bold',
                          Number(row.score) || !editable
                            ? row.fraction === 'minus'
                              ? 'border-none text-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor/5'
                              : 'border-SeabiscuitGrayThemeColor bg-SeabiscuitGrayThemeColor'
                            : 'border-SeabiscuitLightThemeColorD3 bg-white'
                        )}
                        type="number"
                        placeholder="0"
                        value={row.score}
                        onChange={(e) => {
                          if (isLocked) {
                            showLockedModal()
                          } else if (results?.judges && editable) {
                            results.judges[judgeIndex].sections[sectionIndex].rows[rowIndex].score =
                              e.target.value
                            setResults({ ...results })
                          }
                        }}
                        onWheel={(e: any) => e.target.blur()}
                      />
                      {row.coefficient && (
                        <>
                          <div
                            className={clsx(
                              'p-2 w-[56px] border rounded-md flex items-center justify-center',
                              Number(row.score) || !editable
                                ? 'border-SeabiscuitGrayThemeColor bg-SeabiscuitGrayThemeColor text-SeabiscuitDark200ThemeColor'
                                : 'border-SeabiscuitLightThemeColorD3 bg-white text-SeabiscuitDark200ThemeColor/50'
                            )}
                          >
                            {row.coefficient}
                          </div>
                          <div
                            className={clsx(
                              'overflow-auto p-2 w-[56px] border rounded-md flex items-center justify-center font-bold',
                              Number(row.score) || !editable
                                ? 'bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor border-SeabiscuitMainThemeColor/5'
                                : 'text-SeabiscuitDark200ThemeColor/50 border-SeabiscuitLightThemeColorD3'
                            )}
                          >
                            {row.coefficient * Number(row.score)}
                          </div>
                        </>
                      )}
                    </div>
                    {isRemarks && (
                      <input
                        disabled={!editable}
                        className={clsx(
                          'p-3 min-h-[56px] w-full border border-SeabiscuitLightThemeColorD3 rounded-md',
                          row.remarks || !editable ? 'bg-SeabiscuitGrayThemeColor' : 'bg-white'
                        )}
                        type="text"
                        placeholder={editable ? 'Enter remarks...' : 'No remarks'}
                        value={row.remarks}
                        onChange={(e) => {
                          if (isLocked) {
                            showLockedModal()
                          } else if (results?.judges && editable) {
                            results.judges[judgeIndex].sections[sectionIndex].rows[
                              rowIndex
                            ].remarks = e.target.value
                            setResults({ ...results })
                          }
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
              <div>
                {section.notice && (
                  <div className="mt-2 flex flex-col justify-center p-4 bg-SeabiscuitGrayThemeColor min-h-[56px] text-[12px] rounded-md">
                    {section.notice}
                  </div>
                )}
              </div>
            </div>
          ))}
          <div className="my-4 pt-4 border-t border-SeabiscuitLightThemeColorD3 flex flex-col gap-2">
            {judge.sections.map((section, sectionIndex) => (
              <div key={sectionIndex} className={clsx('flex gap-2 min-h-[56px]', styles.item)}>
                <div className="p-2 flex-1 bg-SeabiscuitGrayThemeColor rounded-md flex flex-col justify-center">
                  <h4 className="font-bold">{section.name}</h4>
                  <p>Max {section.max}</p>
                </div>
                <div className="flex flex-col text-center justify-center items-center p-2 w-3/12 bg-SeabiscuitGrayThemeColor rounded-md font-bold">
                  {toFixed(
                    sumBy(section.rows, (row) =>
                      row.coefficient
                        ? Number(row.coefficient) * Number(row.score)
                        : row.fraction === 'minus'
                          ? -Number(row.score)
                          : Number(row.score)
                    ),
                    3
                  )}
                </div>
              </div>
            ))}
            {results?.sheet?.type === 'score-sheet-1' && (
              <div className={clsx('flex gap-2 min-h-[56px]', styles.item)}>
                <div className="p-2 flex-1 bg-SeabiscuitGrayThemeColor rounded-md flex flex-col justify-center">
                  <h4 className="font-bold">Errors</h4>
                  <p>1st time = 2pts • 2nd time = 4pts • 3rd time = Elimination</p>
                </div>
                <div className="flex flex-col text-center justify-center items-center w-3/12 rounded-md">
                  <input
                    disabled={!editable}
                    className={clsx(
                      'font-bold w-full h-full p-2 border rounded-md flex items-center justify-center text-center',
                      Number(judge.errors) || !editable
                        ? 'border-none text-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor/5'
                        : 'border-SeabiscuitLightThemeColorD3 bg-white'
                    )}
                    type="number"
                    placeholder="0"
                    value={judge.errors > 0 ? judge.errors : ''}
                    onChange={(e) => {
                      if (isLocked) {
                        showLockedModal()
                      } else if (results?.judges && editable) {
                        results.judges[judgeIndex].errors = Number(e.target.value)
                        setResults({ ...results })
                      }
                    }}
                    onWheel={(e: any) => e.target.blur()}
                  />
                </div>
              </div>
            )}
            <div className={clsx('flex gap-2 min-h-[56px]', styles.item)}>
              <div className="p-2 flex-1 bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor rounded-md flex flex-col justify-center">
                <h4 className="font-bold">Total Score</h4>
                <p>
                  {judge.sections.map((section) => section.name).join(' + ')} • Max {maxPoints}
                </p>
              </div>
              <div className="flex gap-2 text-center justify-center items-center p-2 w-3/12 bg-SeabiscuitMainThemeColor/5 text-SeabiscuitMainThemeColor rounded-md font-bold">
                <span className="font-bold">{toFixed(totalJudgePoints, 3)}</span>•
                <span>{toFixed((totalJudgePoints / maxPoints) * 100, 3)}%</span>
              </div>
            </div>
          </div>
          <h4 className="text-[20px] mt-6 mb-4 pt-4 border-t border-SeabiscuitLightThemeColorD3">
            Further remarks
          </h4>
          <textarea
            disabled={!editable}
            placeholder={editable ? 'Enter remarks...' : 'No remarks'}
            className={clsx(
              'bg-SeabiscuitGrayThemeColor rounded-md w-full h-72 border border-SeabiscuitLightThemeColorD3',
              judge.furtherRemarks || !editable ? 'bg-SeabiscuitGrayThemeColor' : 'bg-white'
            )}
            value={judge.furtherRemarks}
            onChange={(e) => {
              if (isLocked) {
                showLockedModal()
              } else if (results?.judges) {
                results.judges[judgeIndex].furtherRemarks = e.target.value
                setResults({ ...results })
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
