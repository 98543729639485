import { IUserNotificationInterace } from '../../../../models/user_notifications/notification.interface'

export const DATA_FIRESTORE_V01_USER_NOTIFICATION = {
  NAME: 'v01_user_notification',
  FIELDS: {
    SENDER_ID: 'senderId',
    RECEIVER_ID: 'receiverId',
    RECEIVER_EMAIL: 'receiver_email',
    EVENT_ID: 'eventId',
    NOTIFICATION_TYPE: {
      KEY: 'notification_type',
      VALUE: {
        SHARE_EVENT: 'share_event' as IUserNotificationInterace['notification_type'],
        INVITE_MEMBER: 'invite_member' as IUserNotificationInterace['notification_type'],
        ADD_MEMBER: 'add_member' as IUserNotificationInterace['notification_type'],
      },
    },
    STATUS: {
      KEY: 'status',
      VALUE: {
        SEEN: 0 as IUserNotificationInterace['status'],
        UNSEEN: 1 as IUserNotificationInterace['status'],
      },
    },
    RECEIVER_TYPE: {
      KEY: 'receiver_type',
      VALUE: {
        LOGGED_IN: 'loggedIn' as IUserNotificationInterace['receiver_type'],
        NON_LOGGED_IN: 'nonLoggedIn' as IUserNotificationInterace['receiver_type'],
      },
    },
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt',
  },
}
