// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { IEventScoring } from './event-scoring.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import * as yup from 'yup'
import { CONFIG } from '../../config/config'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export class EventScoringModel extends ModelBaseModel<IEventScoring> implements IModelBaseModel {
  public v: number
  public id: string | null
  public owner: string | null
  public title: string
  public briefDescription: string | null
  public scores: any
  public created: Date
  public modified: Date

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: IEventScoring) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? null
    this.title = obj?.title ?? 'Sample Title'
    this.briefDescription = obj?.briefDescription ?? 'Sample brief description'
    this.scores = obj?.scores ?? CONFIG.EVENT.SCORING.FORM_BUILDER.TEMPLATE.SAMPLE.data

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new EventScoringModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  static validationSchema() {
    return yup.object({}).required()
  }
}
