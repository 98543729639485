export const DATA_FIRESTORE_V01_HORSE_COMPETITION_PAPERWORK = {
  NAME: 'v01_horse_competition_paperwork',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },
    FILTER: {
      OWNER_ID: {
        KEY: 'horseOwnerId',
      },
      HORSE_ID: {
        KEY: 'horseId',
      },
    },
  },
}
