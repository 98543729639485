export interface IViewLoaderProps {
  bgColor?: string
  size?: 'sm' | 'lg' | 'md' | 'xsm' | 'xl'
  isPageLoader?: boolean
  color?: string
  height?: string
  className?: any
}

const ViewsLoader = ({
  color = '#0d8ffb',
  size = 'sm',
  isPageLoader = false,
  className = null,
}: IViewLoaderProps) => {
  // Functions
  const sizeFn = () => {
    if (isPageLoader) return '60'
    switch (size) {
      case 'xsm':
        return '15'
      case 'sm':
        return '20'
      case 'md':
        return '25'
      case 'lg':
        return '30'
      case 'xl':
        return '40'
    }
  }

  if (0) console.log(sizeFn, className, color)

  return (
    <div
      className={className}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: isPageLoader ? '100vw' : '100%', // 100% viewport width
        height: isPageLoader ? '100vh' : '100%', // 100% viewport height
      }}
    >
      <svg
        width="100"
        height="100"
        viewBox="-10 -10 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <pattern
            id="pattern0"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          ></pattern>
          <clipPath id="clip0_5097_21597">
            <rect width="100" height="100" rx="50" fill="white" />
          </clipPath>

          <linearGradient id="cometGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#f7074f" stopOpacity="0" />
            <stop offset="100%" stopColor="#f7074f" stopOpacity="1" />
          </linearGradient>
        </defs>

        <g clipPath="url(#clip0_5097_21597)">
          <rect x="4.32568" y="3.92645" width="93.2831" height="93.9167" fill="url(#pattern0)" />
          <path d="M0 0H100V100H0V0Z" fill="transparent" />
          <path
            id="redBar"
            d="M50 10A40 40 0 0 1 90 50"
            stroke="red"
            strokeWidth="5"
            strokeLinecap="round"
          />
          <path
            d="M39.4265 35.2451C39.4149 32.9712 38.7359 30.7517 37.4749 28.8667C36.214 26.9817 34.4277 25.5156 32.3414 24.6535C30.2552 23.7913 27.9625 23.5718 25.7527 24.0225C23.5428 24.4732 21.5149 25.574 19.9247 27.186C18.3346 28.798 17.2535 30.849 16.8178 33.08C16.3822 35.3111 16.6116 37.6223 17.477 39.722C18.3424 41.8217 19.8051 43.6159 21.6805 44.878C23.5559 46.1401 25.76 46.8137 28.0146 46.8137H28.2819L28.243 41.0539H28.034C26.907 41.053 25.8057 40.7151 24.869 40.0832C23.9323 39.4512 23.2023 38.5534 22.7713 37.5032C22.3402 36.453 22.2275 35.2976 22.4472 34.1828C22.6669 33.068 23.2093 32.0439 24.0059 31.2398C24.8024 30.4358 25.8173 29.8878 26.9225 29.6653C28.0276 29.4427 29.1733 29.5554 30.215 29.9893C31.2566 30.4231 32.1474 31.1586 32.7747 32.1028C33.4021 33.047 33.738 34.1575 33.7399 35.2941V64.2157H39.4945V46.0245H69.0109V64.2794H74.9113V40.2598H56.4423C56.4423 40.2598 58.7071 31.25 76.7485 28.5833L75.9416 21.7794C75.9416 21.7794 65.9781 22.8382 58.1385 28.152C49.8809 33.7206 49.4532 40.2353 49.4532 40.2353L39.5334 40.299L39.4265 35.2451Z"
            fill="#231F20"
            transform="translate(9,16) scale(0.8)"
          />
        </g>
        <defs>
          <pattern
            id="pattern0"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          ></pattern>
          <clipPath id="clip0_5097_21597">
            <rect width="100" height="100" rx="50" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default ViewsLoader
