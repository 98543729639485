// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useContext, useEffect } from 'react'
import HeadingElement from '../../../elements/headings/heading/HeadingElement'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { EventNearbyModel } from '../../../../models/event-nearby/event-nearby.model'
import SwitchElement from './SwitchElement'
import CheckedInput from './CheckedInput'
import EventFormContainerComponent from '../container/EventFormContainerComponent'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'

import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { Tooltip } from '@mui/material'
import AmountInput from '../../../common/inputs/AmountInput'
import TooltipIcon from '../../../../helpers/TooltipIcon'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const eventNearbyBusinessesFormDefaultValues = new EventNearbyModel().toObject()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const eventNearbyBusinessesFormValidationSchema = EventNearbyModel.validationSchema()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventNearbyBusinessesFormComponentProps {
  data: any
  onValid: any
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  nextEventTab?: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const InputLabel = ({ children }: { children: React.ReactElement | null }) => (
  <label className="text-sm text-SeabiscuitDark200ThemeColor font-bold -mt-5 flex items-center">
    {children}
  </label>
)

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventNearbyBusinessesFormComponent: React.FC<EventNearbyBusinessesFormComponentProps> = (
  props
) => {
  const { watch, reset, control, trigger, register, setValue, handleSubmit } = useForm({
    defaultValues: { ...eventNearbyBusinessesFormDefaultValues },
    resolver: yupResolver(eventNearbyBusinessesFormValidationSchema),
    mode: 'onChange',
  } as any)

  const handleModalContext = useContext(HandleModalContext)
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (props.data) {
      reset(props.data)
    } else {
      reset(eventNearbyBusinessesFormDefaultValues)
    }
    trigger().then()
  }, [props.data, reset, trigger])

  /**
   * @param noteInputRef Input reference
   * @info Opens note modal
   */
  const openAddNotesModal = (noteInputRef: any) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NEARBY_NOTE, {
      noteInputRef,
      setValue,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <EventFormContainerComponent>
      <div className="w-full">
        <div className="flex justify-between items-center">
          <div className="flex flex-col">
            <HeadingElement
              classname="tw-title text-SeabiscuitMainThemeColor font-bold text-2xl mb-0.5"
              title="Nearby Businesses"
            />
            <p className="text-SeabiscuitDark200ThemeColor text-[14px]">
              Allow nearby businesses to advertise at your event
            </p>
          </div>

          <EventFormFooterCommonComponent
            nextEventTab={props.nextEventTab}
            onNext={(e: any) => handleSubmit(props.onValid, props.onInvalid)(e)}
            onSaveAndExit={(e: any) => handleSubmit(props.onValidAnExit, props.onInvalidAndExit)(e)}
          />
        </div>
        <hr className="my-4" />

        <div className="tablet:items-center my-8 flex gap-2 flex-col-reverse tablet:flex-row">
          <label className="2xl:text-xl xl:text-base my-6 tw-title text-SeabiscuitDark200ThemeColor font-normal flex items-center">
            Allow nearby businesses to advertise at this event?
            <Tooltip
              title={
                <h1 className="tooltip_title">{`This will allow nearby business like coffee shops to advertise at your event, allowing you to monetize the foot traffic your show brings to the local economy`}</h1>
              }
              placement="top"
              arrow
            >
              <button>
                <TooltipIcon color="#122B46" />
                {/* <img className="w-4 h-4 ml-1" src="/assets/cp_icons/Help.svg" alt="icon" /> */}
              </button>
            </Tooltip>
          </label>

          <Controller
            name={'allowNearbyBusinessesToAdvertise'}
            control={control}
            render={({ field: { value } }) => (
              <SwitchElement
                onSwitch={() => setValue('allowNearbyBusinessesToAdvertise', !value)}
                on={value}
              />
            )}
          />
        </div>
        {watch('allowNearbyBusinessesToAdvertise') ? (
          <div className="mt-8 mb-4 grid items-center gap-2 grid-cols-[100%] tablet:grid-cols-[120px_auto_auto] laptop:grid-cols-[20%_20%_59%]">
            <div className="flex-1">
              <InputLabel>
                <>
                  Spots available
                  <Tooltip
                    title={
                      <h1 className="tooltip_title">{`Number of business you want to advertise at your event`}</h1>
                    }
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color="#122B46" />
                    </button>
                  </Tooltip>
                </>
              </InputLabel>

              <AmountInput
                name="spotsAvailable"
                register={register}
                disable={!watch('allowNearbyBusinessesToAdvertise')}
                inputClassName="w-full bg-transparent outline-0 ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor focus:outline-0 focus:ring-0 border-0 text-center"
                className="w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
                value={watch('allowNearbyBusinessesToAdvertise') ? watch('spotsAvailable') : '0'}
                // childrenToAppend={
                //     <>
                //         {
                //             (watch('allowNearbyBusinessesToAdvertise') && !!Number(watch(`allowNearbyBusinessesToAdvertise`).replace(/\D/, ""))) ? <img src={"assets/og_icons/Ok-3.svg"} alt="green check" /> : null
                //         }
                //     </>}
              />
            </div>
            <div className="flex-1">
              <InputLabel>
                <>
                  Price
                  <Tooltip
                    title={
                      <h1 className="tooltip_title">{`The price you will charge business to advertise`}</h1>
                    }
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color="#122B46" />
                      {/* <img className="w-4 h-4 ml-1" src="/assets/cp_icons/Help.svg" alt="icon" /> */}
                    </button>
                  </Tooltip>
                </>
              </InputLabel>

              <AmountInput
                name="price"
                register={register}
                prefix="$"
                disable={!watch('allowNearbyBusinessesToAdvertise')}
                placeholder="Price per unit"
                inputClassName="w-full bg-transparent outline-0 text-center ring-0 focus:border-0 text-SeabiscuitDark200ThemeColor focus:outline-0 focus:ring-0 border-0"
                className="w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12 text-SeabiscuitDark200ThemeColor"
                value={watch('allowNearbyBusinessesToAdvertise') ? watch('price') : '0'}
                // childrenToAppend={
                //     <>
                //         {
                //             (watch('allowNearbyBusinessesToAdvertise') && watch(`price`) && watch(`price`)?.toString() !== "$" && watch(`price`)?.toString() !== "$0") ? <img src={"assets/og_icons/Ok-3.svg"} alt="green check" /> : null
                //         }
                //     </>}
              />
            </div>
            <div className="flex-[2]">
              <InputLabel>
                <>
                  Note
                  <Tooltip
                    title={
                      <h1 className="tooltip_title">{`Detail why nearby businesses should advertise with you`}</h1>
                    }
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color="#122B46" />
                    </button>
                  </Tooltip>
                </>
              </InputLabel>

              <Controller
                name={'note'}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CheckedInput
                    notChecked={true}
                    placeholder="Enter note..."
                    name={'note'}
                    disabled={!watch('allowNearbyBusinessesToAdvertise')}
                    type="string"
                    value={value}
                    openAddNotesModal={openAddNotesModal}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>
        ) : null}
      </div>
    </EventFormContainerComponent>
  )
}

export default EventNearbyBusinessesFormComponent
