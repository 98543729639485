// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { AddSharp, RemoveSharp } from '@mui/icons-material'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormBuilderUtilEquitationSectionRowAccordionSummaryComponent = styled(
  (props: any) => (
    <MuiAccordionSummary
      expandIcon={
        props.isExpanded ? (
          <RemoveSharp sx={{ fontSize: '0.9rem', color: 'red' }} />
        ) : (
          <AddSharp sx={{ fontSize: '0.9rem', color: 'black' }} />
        )
      }
      {...props}
    />
  )
)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'color',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(-1),
  },
}))

export default EventPrizeListFormBuilderUtilEquitationSectionRowAccordionSummaryComponent
