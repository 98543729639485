import React, { useState } from 'react'

// Styles
import './UserLoginViewComponent.css'
import './UserLoginFormComponent.css'

// Third party
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Visibility, VisibilityOffOutlined } from '@mui/icons-material'

// Images imports
// import emailIcon from "./assets/email.svg";
// import passIcon from "./assets/password.svg";

// Custom components
import MessageHelperComp from '../../../../helpers/MessageHelper'
import { MODAL_CONSTS } from '../../../../const/modal-const'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const userLoginViewComponentDefaultValues = {
  email: '',
  password: '',
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const userLoginViewComponentSchemaValidation = yup
  .object({
    email: yup
      .string()
      .required('email or username is required')
      .test('no-spaces', 'email or username should not contain spaces', (value) => {
        if (value) return !/\s/.test(value)
        return true
      }),
    password: yup.string().required(),
  })
  .required()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface UserLoginViewComponentProps {
  onLoginValid: (data: any) => any
  onLoginInvalid: (data: any) => any
  onSignInWithGoogleButtonPressed: () => Promise<void>
  onSignUpButtonPressed: () => void
  logo: any
  loading: boolean
  logoAlt: string
  handleModal: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export const UserLoginFormComponent: React.FC<UserLoginViewComponentProps> = (props) => {
  const [innerType, setInnerType] = useState('password')
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: userLoginViewComponentDefaultValues,
    resolver: yupResolver(userLoginViewComponentSchemaValidation),
    mode: 'onChange',
  })

  return (
    <div className="min-h-full flex flex-col justify-center py-2 text-white">
      <div className="flex justify-center sm:mx-auto md:mx-0 sm:w-full sm:max-w-md">
        <div className="transition-[all_.3s] w-full">
          <form
            className="space-y-6"
            onSubmit={(e) => handleSubmit(props.onLoginValid, props.onLoginInvalid)(e)}
          >
            <div className="mb-6">
              <label className="mb-3 !text-white flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
                <span className="w-[22px]">
                  <img src="/assets/og_icons/Mail-4.svg" alt="icon" className="" />
                </span>
                <input
                  {...register('email')}
                  placeholder="Email or Username"
                  className="flex-1 border-0 outline-0 !text-white !bg-transparent placeholder:text-white w-full focus:border-none p-2"
                />
              </label>

              {errors.email && (
                <MessageHelperComp
                  className="mb-3 !mt-0 bg-white rounded-lg px-2 opacity-80 w-fit"
                  isError={true}
                  message={errors.email.message}
                />
              )}

              <label className="mb-3 text-white flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
                <div>
                  <img src="/assets/og_icons/Lock-4.svg" alt="icon" className="" />
                </div>
                <input
                  type={innerType}
                  {...register('password')}
                  placeholder="Password"
                  className="flex-1 border-0 outline-0 !text-white bg-transparent placeholder:text-white focus:ring-transparent w-full focus:border-none"
                />
                <div onClick={() => setInnerType(innerType === 'password' ? 'text' : 'password')}>
                  {innerType === 'password' ? (
                    <VisibilityOffOutlined className="text-white" />
                  ) : (
                    <Visibility className="text-white" />
                  )}
                </div>
              </label>
              {errors.password && (
                <MessageHelperComp
                  className="mb-3 !mt-0 bg-white rounded-lg px-2 opacity-80 w-fit"
                  isError={true}
                  message={errors.password.message}
                />
              )}
            </div>

            <div className="w-[70%] mt-[80px] mx-auto">
              <button
                disabled={props?.loading}
                type="submit"
                className="w-full h-12 flex items-center justify-center py-2 px-4 border !border-white border-solid rounded-2xl shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor nextBtnShadow bg-white opacity-80 hover:opacity-100 focus:outline-none focus:ring-0"
              >
                {props?.loading ? (
                  <AutorenewIcon fontSize="small" className="animate-spin" />
                ) : (
                  'Sign In'
                )}
              </button>
            </div>
          </form>

          <div className="flex justify-center pt-8">
            <div className="text-sm text-white">
              Don't have an account?
              <span className="pl-1" />
              <span
                className="text-white cursor-pointer underline"
                onClick={props.onSignUpButtonPressed}
              >
                Sign Up
              </span>
            </div>
          </div>

          <div className="flex justify-center mt-4">
            <div className="text-sm">
              <div
                onClick={() => props.handleModal(true, MODAL_CONSTS.FORGOT_PASSWORD)}
                className="font-medium text-white cursor-pointer"
              >
                Forgot your password?
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserLoginFormComponent
