import clsx from 'clsx'

import { toFixed } from '../../../../helpers/helpers'

// Types
type IExhibitorProfileRefundAccordionProps = {
  total: number
  refundTotal: number
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ExhibitorFeesInput: React.FC<{
  className?: string
  data?: any
  icon?: any
}> = ({ className, data, icon }) => {
  return (
    <>
      {icon ? (
        <div className={`${className}`}>
          <img className="absolute left-3 top-3" src={icon} alt="icons" />
          {data}
        </div>
      ) : (
        <div className={`${className}`}>{data}</div>
      )}
    </>
  )
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ExhibitorProfileRefundAccordion = (props: IExhibitorProfileRefundAccordionProps) => {
  return (
    <>
      <div className="text-SeabiscuitDark200ThemeColor flex justify-between items-center font-semibold mb-3 ml-1">
        Refund Details
      </div>
      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        <ExhibitorFeesInput
          className="text-xs text-SeabiscuitDark200ThemeColor ml-1 font-semibold w-3/4"
          data="Items"
        />

        <ExhibitorFeesInput
          className="text-xs text-SeabiscuitDark200ThemeColor ml-2 font-semibold w-1/4"
          data="Total"
        />
      </div>

      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        <ExhibitorFeesInput
          className="rounded-lg flex items-center text-sm text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-3/4"
          data="Total paid"
        />

        <ExhibitorFeesInput
          className="rounded-lg flex justify-center items-center text-sm text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/4 text-center"
          data={`$${toFixed(props.total)}`}
        />
      </div>

      <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
        <ExhibitorFeesInput
          className="rounded-lg flex font-bold items-center text-sm text-SeabiscuitDark200ThemeColor text-opacity-[.85] h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1 w-3/4"
          data={`Total amount refunded`}
        />

        <ExhibitorFeesInput
          className={clsx(
            'rounded-lg flex justify-center items-center text-sm text-SeabiscuitDark200ThemeColor text-opacity-[.85] font-bold h-12 bg-SeabiscuitGreenLightThemeColor bg-opacity-10 p-3 m-1 w-1/4 text-center',
            props.refundTotal > props.total && 'text-[#EB5757] !bg-[#EB57571A] border-red-500'
          )}
          data={`$${toFixed(props.refundTotal)}`}
        />
      </div>
    </>
  )
}

export default ExhibitorProfileRefundAccordion
