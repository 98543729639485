import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router'

// Third party
import { Link, useLocation } from 'react-router-dom'
import { where } from 'firebase/firestore'

// Constants
import { CONST } from '../../../const/const'

// Components
import ViewsLoader from '../../../components/loader/ViewsLoader'
import ExhibitorProfileHeadSection from '../../organizer/exhibitorProfile/ExhibitorProfileHeadSection'
import ExhibitorProfilePageWrapper from '../../organizer/exhibitorProfile/ExhibitorProfilePageWrapper'
import NotFoundAlert from '../../../components/common/alerts/not-found/NotFoundAlert'

// Models
import { UserModel } from '../../../models/users/user.model'
import { IEventReviewPublish } from '../../../models/event-review-publish/event-review-publish.interface'
import { TEventRegisteredUsers } from '../../../models/event-registered-users/event-registered-users.interface'
import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'

// Redux
import {
  setScratchEventId,
  setScratchRegistrationId,
  setScratchViewVisibility,
} from '../../../store/events/eventsSlice'
import {
  getRecipientThunk,
  resetRecipientAc,
  resetRegistrationAc,
  selectExhibitorDetails,
  selectRecipientR,
  selectRegistrationR,
  setExhibitorDetails,
  setRegistration,
} from '../../../store/exhibitor/exhibitorSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

// Helpers
import { getConvertedData } from '../../../models/interface.helper'
import helpers from '../../../commonHelpers/helpers'

// Hooks
import useGetBasicEventDetails from '../../../hooks/users/common/useGetBasicEventDetails'
import FirestoreService from '../../../services/firestoreService'

import { IUiExhibitorConst, UI_EXHIBITOR_CONST } from '../../../const/ui/ui-exhibitor-const'

// Types
import { selectUserId } from '../../../store/user/userSlice'
import { MESSAGES_CONST } from '../../../const/messages-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import ExhibitorProfileDisplayTab from '../../organizer/exhibitorProfile/ExhibitorProfileDisplayTab'

type Props = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}

type IUserParams = {
  userId: string
  eventId: string
  registrationId: string
}

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const SCRATCHABLE = [
  CONST.UI.EXHIBITOR.TABS.CLASSES.VALUE,
  CONST.UI.EXHIBITOR.TABS.TICKETS.VALUE,
  CONST.UI.EXHIBITOR.TABS.FEES.VALUE,
]

const AllTabs = Object.keys(CONST.UI.EXHIBITOR.TABS).map((currTabName, index) => {
  if (UI_EXHIBITOR_CONST.TABS.ENTRIES.VALUE === currTabName) {
    return <Fragment key={currTabName} />
  }
  return {
    tab: CONST.UI.EXHIBITOR.TABS[currTabName as keyof IUiExhibitorConst['TABS']].VALUE,
    id: index + 1,
  }
})

const CompetitorRootRegisteredEvent = (props: Props) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()

  const [eventData, setEventData] = useState<IEventReviewPublish | null>(null)
  const [loading, setLoading] = useState(true)

  const { userId, registrationId, eventId } = useParams<IUserParams>()
  const location = useLocation()
  useGetBasicEventDetails(eventId)

  const recipientR = useAppSelector(selectRecipientR)
  const exhibitor = useAppSelector(selectExhibitorDetails)
  const registrationR = useAppSelector(selectRegistrationR)
  const loggedUserId = useAppSelector(selectUserId)

  const [profileLoading, setProfileLoading] = useState(true)
  const [showScratchedView, setShowScratchedView] = useState(false)

  const [activeTab, setActiveTab] = useState(CONST.UI.EXHIBITOR.TABS.CLASSES.VALUE)

  useEffect(() => {
    return () => {
      dispatch(resetRecipientAc())
      dispatch(resetRegistrationAc())
      dispatch(setExhibitorDetails(null))
    }
  }, [dispatch])

  const getRegistration = async () => {
    setLoading(true)

    let registeredUser: TEventRegisteredUsers | null = null

    const registeredUsersSnaps = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.EVENT_ID.KEY, '==', eventId),
        where(COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY, '==', userId),
      ]
    )

    registeredUsersSnaps.docs.forEach((currDoc) => {
      registeredUser = getConvertedData(
        EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject()
      )
    })

    if (registeredUser) dispatch(setRegistration(registeredUser))

    setLoading(false)
  }

  useEffect(() => {
    dispatch(setScratchEventId(eventId))
    dispatch(setScratchRegistrationId(registrationId))
    getRegistration().then()
  }, [eventId, registrationId])

  useEffect(() => {
    if (registrationId && userId) {
      setProfileLoading(true)
      getUserDetails().then()
    }
  }, [userId, registrationId])

  useEffect(() => {
    const registratioIdNRecipietIdPresent = registrationId && userId
    const recipientIsDifferent = recipientR.data?.recipientId !== userId
    const allowDispatch = registratioIdNRecipietIdPresent && recipientIsDifferent

    if (allowDispatch) {
      dispatch(
        getRecipientThunk({
          recipientId: userId,
          registrationId,
        })
      )
    }
  }, [registrationId, userId, recipientR.data?.recipientId, dispatch])

  // Functions
  const handleScratchView = (visible: boolean) => {
    dispatch(setScratchViewVisibility(visible))
    setShowScratchedView(visible)
  }

  async function getUserDetails() {
    try {
      const docSnapshot = await FirestoreService.getItem(COLLECTIONS.USERS.NAME, userId)
      setProfileLoading(false)

      let document = UserModel.fromFirestoreDoc(docSnapshot).toObject()
      if (docSnapshot.exists()) {
        dispatch(setExhibitorDetails(getConvertedData(document)))
      }
    } catch (err) {
      helpers.logger({
        isError: true,
        message: err,
      })
    }
  }

  useEffect(() => {
    if (location.hash === '#horses') {
      setActiveTab(CONST.UI.EXHIBITOR.TABS.HORSES.VALUE)
    }
  }, [location.hash])

  const handleActiveTab = (id: string) => {
    if (showScratchedView)
      return toastFunctions.info({
        message: MESSAGES_CONST.SINGLE_CAN_BE_SCRATCHED,
      })
    else setActiveTab(id)
  }

  if (!loading && !registrationR?.id) {
    return (
      <ExhibitorProfilePageWrapper
        title="Back"
        link={
          loggedUserId === userId
            ? CONST.ROUTES.MY_EVENT.URL
            : `${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`
        }
      >
        <NotFoundAlert description="Registration not found" />
      </ExhibitorProfilePageWrapper>
    )
  }

  return (
    <>
      <ExhibitorProfilePageWrapper
        title="Back"
        link={
          loggedUserId === userId
            ? CONST.ROUTES.MY_EVENT.URL
            : `${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`
        }
      >
        {profileLoading || loading || !registrationR?.id ? (
          <div className="h-[50vh] min-h-[400px] flex justify-center items-center">
            <ViewsLoader size="lg" color="#F7074F" />
          </div>
        ) : (
          <>
            <ExhibitorProfileHeadSection
              backUrl={`${CONST.ROUTES.MY_EVENT.URL}`}
              isCompetitor={loggedUserId === userId}
              activeTab={activeTab}
              exhibitor={exhibitor}
              eventData={eventData}
              setEventData={setEventData}
              recipient={recipientR.data}
              handleModal={props.handleModal}
              exhibitorAllData={registrationR}
              showScratchedView={showScratchedView}
              handleScratchView={handleScratchView}
            />
            <div className="my-4 border-t border-solid border-[#D3DAEE]"></div>
            <div className="flex flex-col md:flex-row md:items-center mb-8">
              {AllTabs.map((currentItem: any, index: number) => {
                if (UI_EXHIBITOR_CONST.TABS.ENTRIES.VALUE === currentItem.tab) {
                  return <Fragment key={`${index}${JSON.stringify(currentItem)}`} />
                }
                return (
                  <div
                    key={`${index}${JSON.stringify(currentItem)}`}
                    onClick={() => handleActiveTab(currentItem.tab)}
                    className={`py-2 px-4 cursor-pointer mr-2 rounded-xl ${activeTab === currentItem.tab ? 'bg-[#F7074F0D] text-SeabiscuitMainThemeColor' : 'text-SeabiscuitDark200ThemeColor'}`}
                  >
                    {currentItem.tab}
                  </div>
                )
              })}
              {!showScratchedView && SCRATCHABLE.includes(activeTab ?? '') ? (
                <div className="flex w-full justify-end">
                  <Link
                    to={`${CONST.ROUTES.MANAGE_REGISTER_EVENT.URL}/${eventId}/${userId}`}
                    className="text-center text-SeabiscuitDark200ThemeColor bg-[#F6F7FB] cursor-pointer hover:text-[#f7074f] hover:bg-[#F7074F0D] text-sm py-2 px-4 rounded-lg w-24 mr-2"
                  >
                    Add
                  </Link>
                  <button
                    type="button"
                    onClick={() => handleScratchView(true)}
                    className={`${eventData && eventData?.status === 'cancel' ? 'capitalize rounded-full py-1 px-4 mr-4 inline-block whitespace-nowrap overflow-hidden mt-1  md:min-w-[unset] text-nr md:text-base cursor-pointer bg-[#FC2804] text-white' : 'text-SeabiscuitDark200ThemeColor bg-[#F6F7FB] hover:text-[#f7074f] hover:bg-[#F7074F0D] cursor-pointer text-sm py-2 px-4 rounded-lg w-24'}`}
                  >
                    {eventData && eventData?.status === 'cancel' ? 'Request Refunded' : 'Scratch'}
                  </button>
                </div>
              ) : null}
            </div>
            <ExhibitorProfileDisplayTab
              userId={userId}
              eventId={eventId}
              registrationId={registrationId}
              exhibitor={exhibitor}
              eventTab={activeTab}
              eventData={eventData}
              handleModal={props?.handleModal}
              exhibitorAllData={registrationR}
              showScratchedView={showScratchedView}
              handleScratchView={handleScratchView}
            />
          </>
        )}
      </ExhibitorProfilePageWrapper>
    </>
  )
}

export default CompetitorRootRegisteredEvent
