import { createAsyncThunk } from '@reduxjs/toolkit'
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { IHorseCompetitorDocument } from '../../../models/horse-competiton-paperwork/horse-competiton-paperwork-interface'
import { HorseCompetitonPaperworkModel } from '../../../models/horse-competiton-paperwork/horse-competiton-paperwork.model'
import { getConvertedData } from '../../../models/interface.helper'
import FirestoreService from '../../../services/firestoreService'
import { IUserHorseMappingInterface } from '../../../models/user-horse-mapping/userHorseMapping.interface'
import { UserHorseMappingModel } from '../../../models/user-horse-mapping/userHorseMapping.model'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type IGetHorsePaperworkThunk = {
  req: {
    userHorseMappingDocId: string
  }
}

const getHorsePaperworkThunk = createAsyncThunk(
  'getHorsePaperworkThunk',
  async (payload: IGetHorsePaperworkThunk['req'], thunkApi) => {
    let message: string | null = null
    let horseCompetetionPaperwork: IHorseCompetitorDocument | null = null
    let mapping: IUserHorseMappingInterface | null = null

    try {
      const horseMappingSnapshot = await FirestoreService.getItem(
        COLLECTIONS.USER_HORSE_MAPPING.NAME,
        payload.userHorseMappingDocId
      )

      if (!horseMappingSnapshot.exists()) {
        throw new Error('Horse not found')
      }

      mapping = getConvertedData(
        UserHorseMappingModel.fromFirestoreDoc(horseMappingSnapshot).toObject()
      )

      const horseSnapshot = await FirestoreService.getItem(
        COLLECTIONS.HORSES.NAME,
        mapping.horseId!!
      )

      if (!horseSnapshot.exists()) {
        throw new Error('Horse not found')
      }

      const horseCompetitionPaperworkSnapshot = await FirestoreService.getItem(
        COLLECTIONS.HORSE_COMPETITION_PAPERWORK.NAME,
        payload.userHorseMappingDocId
      )

      if (!horseCompetitionPaperworkSnapshot.exists()) {
        throw new Error('Horse competition paperwork not found')
      }

      horseCompetetionPaperwork = HorseCompetitonPaperworkModel.fromFirestoreDoc(
        horseCompetitionPaperworkSnapshot
      ).toObject()

      return {
        paperwork: getConvertedData(horseCompetetionPaperwork),
      }
    } catch (error: any) {
      message = error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG
      return thunkApi.rejectWithValue({
        message,
      })
    }
  }
)

export { getHorsePaperworkThunk }
