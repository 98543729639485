/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'

// Third party
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { FieldValues, RegisterOptions, UseFormRegister, UseFormSetValue } from 'react-hook-form'

// css
import clsx from 'clsx'
import './AmountInput.css'
import { IBasicInputProps } from './Input'

type IAmountInputProps = {
  name: string
  onFocus?: any
  title?: string
  clear?: boolean
  prefix?: string
  postFix?: string
  onChange?: any
  disable?: boolean
  required?: boolean
  readonly?: boolean
  className?: string
  postFixIcon?: string
  childWidth?: number
  preFixIcon?: string
  placeholder?: string
  value: string | number
  inputClassName?: string
  showPreFixIcon?: boolean
  showPostFixIcon?: boolean
  postFixIconClassName?: string
  preFixIconClassName?: string
  textColorOnDisabled?: boolean
  setValue?: UseFormSetValue<any>
  register?: UseFormRegister<any>
  childrenToAppend?: ReactJSXElement
  rules?: RegisterOptions<FieldValues, string> | undefined
  withDecimals?: boolean
} & IBasicInputProps

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const AmountInput = (props: IAmountInputProps) => {
  // Hooks and vars
  let regex = /\D/gi
  if (props.withDecimals) regex = /[^\d.]/g

  const showIcon =
    (props.postFixIcon && props.showPostFixIcon !== false) ||
    (props.preFixIcon && props.showPreFixIcon !== false)
  const inputRef = useRef<string>('0')
  let setRerender = useState(false)[1]
  const inputParentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    inputRef.current = transpile(props.value)
    setRerender((prev) => !prev)
  }, [props?.value, props?.prefix, props?.postFix])

  // Functions

  const transpile = (value: string | number, cameValueHandler?: boolean): string => {
    if (props.clear && !cameValueHandler) return ''

    let valueToSet = value

    valueToSet = (`${valueToSet}` ?? '').trim() ?? ''
    if (props.prefix || props.postFix) valueToSet = valueToSet.replace(regex, '')

    if (props.prefix && valueToSet.startsWith('0')) valueToSet = valueToSet.substring(1)
    if (props.postFix && valueToSet.startsWith('0')) valueToSet = valueToSet.substring(1)
    if (!props.prefix && !props.postFix && valueToSet.startsWith('0'))
      valueToSet = valueToSet.substring(1)

    if (props.prefix) {
      if (valueToSet.length > 0) valueToSet = `${props.prefix}${valueToSet}`
      else valueToSet = `${props.prefix}${0}`
    }

    if (props.postFix) {
      if (valueToSet.length > 0) valueToSet = `${valueToSet}${props.postFix}`
      else valueToSet = `${0}${props.postFix}`
    }

    if (!props.prefix && !props.postFix) {
      if (valueToSet.length === 0) valueToSet = `0`
      valueToSet = valueToSet.replace(regex, '')
    }

    return valueToSet
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    inputRef.current = transpile(e.target.value, true)
    e.target.value = transpile(e.target.value, true)
  }

  const focusInput = () => {
    let ref = inputParentRef.current?.children.namedItem(props.name)
    if (ref) (ref as any).focus()
  }

  const inputProps = {
    name: props.name,
    value: transpile(props.value),
    placeholder: props.placeholder,
    onChange: function (e: React.ChangeEvent<HTMLInputElement>) {
      onChangeHandler(e)
      props?.onChange?.(e.target.value)
    },
    onFocus: () => {
      if (typeof props?.onFocus === 'function') {
        props.onFocus()
      }
    },
    ...(props?.register && {
      ...props.register(props.name, {
        required: props?.required,
        ...(props?.rules && props?.rules),
      }),
      onChange: async function (e: any) {
        if (typeof props.onChange === 'function') {
          props.onChange(e.target.value)
        }
        onChangeHandler(e)
        props?.register &&
          props
            ?.register(props.name, {
              required: props?.required,
            })
            .onChange(e)
      },
      onFocus: () => {
        if (typeof props?.onFocus === 'function') {
          props.onFocus()
        }
      },
    }),
    className: clsx(
      'w-full outline-0 ring-0 rounded-md focus:outline-0 focus:ring-0 text-SeabiscuitDark200ThemeColor cursor-pointer overflow-hidden whitespace-nowrap truncate border-none bg-transparent h-full',
      props?.inputClassName,
      showIcon && 'px-2',
      props?.disable && (props?.textColorOnDisabled ?? 'text-SeabiscuitDark200ThemeColor')
    ),
    title: props?.title,
    readOnly: props?.readonly,
    disabled: props?.disable ?? false,
  }

  const parentProps = {
    title: props?.placeholder,
    className: clsx(
      'relative overflow-hidden rounded-md',
      props?.className,
      props.valid ? props.validValueClassName : props.invalidValueClassName
    ),
  }

  return (
    <div {...parentProps} onClick={focusInput}>
      <span
        className={clsx(
          showIcon
            ? 'px-3 flex h-full items-center'
            : `${props?.childrenToAppend && props.valid !== false ? `w-[calc(100%-${props.childWidth ?? 24}px)]` : 'w-full'} relative flex items-center h-full`
        )}
        ref={inputParentRef}
        onClick={focusInput}
        style={{
          width: `${props?.childrenToAppend && props.valid !== false ? `calc(100% - ${props.childWidth ?? 24}px)` : '100%'} `,
        }}
      >
        {props.preFixIcon && props?.showPreFixIcon !== false ? (
          <img
            src={props.preFixIcon}
            alt="icon"
            className={clsx('w-6 h-6', props.preFixIconClassName)}
          />
        ) : null}

        <input type="text" {...inputProps} />

        {props.postFixIcon && props?.showPostFixIcon !== false ? (
          <img
            src={props.postFixIcon}
            alt="icon"
            className={clsx('w-6 h-6', props.postFixIconClassName)}
          />
        ) : null}
      </span>
      {props.valid !== false && props.childrenToAppend}
    </div>
  )
}

export default AmountInput
