// Component imports

// Constants
import MainModal from './common/MainModal'

// Types
type IViewModalProps = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const ViewModal = (props: IViewModalProps) => {
  const bodyClassName = props?.dataToPassOn?.bodyClassName ?? ''
  const headingClassName = props?.dataToPassOn?.headingClassName ?? ''
  const title = props?.dataToPassOn?.title ? props?.dataToPassOn?.title : 'See More'

  return (
    <MainModal
      title={title}
      titleClassName={headingClassName}
      setHeightAsPerContent={true}
      size="md"
      type="SEE_MORE"
      show={props.show}
    >
      <div className={bodyClassName ?? ''}>{props?.dataToPassOn?.dataToShow}</div>
    </MainModal>
  )
}

export default ViewModal
