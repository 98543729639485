import { useContext, useEffect, useState } from 'react'
import { CONST } from '../../../../../../const/const'
import EmptyList from '../EmptyList'
import DealCard from './DealCard'
import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../../../const/modal-const'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectUserId } from '../../../../../../store/user/userSlice'
import FirestoreService from '../../../../../../services/firestoreService'
import { where } from 'firebase/firestore'
import { getConvertedData } from '../../../../../../models/interface.helper'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../../../../../const/messages-const'
import { IDealInterface } from '../../../../../../models/deal/deal.interface'
import { DealModel } from '../../../../../../models/deal/deal.model'
import { httpService } from '../../../../../../services/httpService'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const DealsTab = () => {
  const userId = useAppSelector(selectUserId)
  const [loading, setLoading] = useState(true)
  const [deletingIndex, setDeletingIndex] = useState(-1)
  const [deals, setDeals] = useState<IDealInterface[]>([])

  const toastFunctions = useToasterHelper()

  const VENDOR_AND_SPONSOR = CONST.UI.VENDOR_AND_SPONSOR
  const DUMMY_DEALS = VENDOR_AND_SPONSOR.DUMMY_DATA.DEALS

  const handleModal = useContext(HandleModalContext)

  useEffect(() => {
    fetchDeals(userId)
  }, [userId])

  async function fetchDeals(userId: string | null) {
    if (userId) {
      FirestoreService.filterItems(COLLECTIONS.DEALS.NAME, [
        where(
          COLLECTIONS.DEALS.FIELDS.CREATOR_TYPE.KEY,
          '==',
          COLLECTIONS.DEALS.FIELDS.CREATOR_TYPE.VALUES.VENDOR
        ),
        where(
          COLLECTIONS.DEALS.FIELDS.STATUS.KEY,
          '==',
          COLLECTIONS.DEALS.FIELDS.STATUS.VALUES.LIVE
        ),
        where(COLLECTIONS.DEALS.FIELDS.CREATOR_ID.KEY, '==', userId),
      ])
        .then((dealSnapshots) => {
          const deals: IDealInterface[] = []
          dealSnapshots.forEach((currDealSnapshot) => {
            deals.push(getConvertedData(DealModel.fromFirestoreDoc(currDealSnapshot)))
          })
          setDeals(deals)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }

  const onAddDealButtonClick = () => {
    handleModal?.handleModal?.(true, MODAL_CONSTS.CREATE_AND_UPDATE_DEAL, {
      dealDoc: new DealModel().toObject(),
      updateDeal: (createdDeal: IDealInterface) => {
        let createdDeal_ = [...deals]
        if (createdDeal?.status === 'live') {
          createdDeal_.unshift(createdDeal)
          setDeals([...createdDeal_])
        }
      },
    })
  }

  const onEditDealButtonClick = (index: number) => {
    handleModal?.handleModal?.(true, MODAL_CONSTS.CREATE_AND_UPDATE_DEAL, {
      dealDoc: deals[index],
      updateDeal: (updatedDeal: IDealInterface) => {
        let updatedDeal_ = [...deals]

        if (updatedDeal?.status === 'draft') {
          updatedDeal_.splice(index, 1)
        } else {
          updatedDeal_.splice(index, 1, updatedDeal)
        }

        setDeals([...updatedDeal_])
      },
    })
  }

  const onMoreButtonClick = (dealIndex: number) => {
    handleModal?.handleModal?.(true, MODAL_CONSTS.DEAL, deals[dealIndex])
  }

  const onDeleteButtonClick = async (dealIndex: number, bypass = false) => {
    if (bypass !== true) {
      handleModal?.handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
        removeItem: function () {
          onDeleteButtonClick(dealIndex, true)
        },
      })
      return 0
    }

    try {
      setDeletingIndex(dealIndex)

      if (deals[dealIndex].pictures?.length) {
        await httpService({
          url: 'remove_images',
          method: 'post',
          data: {
            urls: deals[dealIndex].pictures,
          },
        })
      }

      await FirestoreService.deleteItem(COLLECTIONS.DEALS.NAME, deals[dealIndex]?.id!)

      let updatedDeal_ = [...deals]
      updatedDeal_.splice(dealIndex, 1)

      setDeals([...updatedDeal_])
    } catch (error) {
      console.error(error)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setDeletingIndex(-1)
      return 0
    }
  }

  if (!deals.length && !loading) {
    return (
      <EmptyList text={VENDOR_AND_SPONSOR.MESSAGES.DEALS.MESSAGE} onClick={onAddDealButtonClick} />
    )
  }

  return (
    <div
      id="deals-section"
      itemScope
      itemType="http://schema.org/DEALSArticle"
      className="max-h-[600px] overflow-auto"
    >
      {(loading ? DUMMY_DEALS : deals).map((currDeal: any, currIndex) => {
        return (
          <DealCard
            {...currDeal}
            loading={loading}
            index={currIndex}
            loggedInUserId={userId}
            key={`deal${currIndex}`}
            deletingIndex={deletingIndex}
            onMoreButtonClick={onMoreButtonClick}
            onDeleteButtonClick={onDeleteButtonClick}
            onEditDealButtonClick={onEditDealButtonClick}
          />
        )
      })}

      {!loading ? (
        <div className="mt-4 text-center">
          <button onClick={onAddDealButtonClick}>
            <img src="/assets/og_icons/plus-icon-2.svg" alt="add" />
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default DealsTab
