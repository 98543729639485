// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { CursorClickIcon, MailOpenIcon, UsersIcon } from '@heroicons/react/outline'
import EventMetricsDisplayCardStackComponent from '../../../components/events/stacks/metrics-display-card/EventMetricsDisplayCardStackComponent'

const metrics = [
  { id: 'alpha', name: 'Riders Registered', mainMetric: '123', icon: UsersIcon, subMetric: '122' },
  {
    id: 'beta',
    name: 'Vendors Registered',
    mainMetric: '32',
    icon: MailOpenIcon,
    subMetric: '5.4%',
  },
  {
    id: 'gama',
    name: 'Sponsors Registered',
    mainMetric: '100',
    icon: CursorClickIcon,
    subMetric: '3.2%',
  },
  {
    id: 'iota',
    name: 'Spectators Registered',
    mainMetric: '123',
    icon: CursorClickIcon,
    subMetric: '3.2%',
  },
]

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsHomePageMessagesMetricsSubSection: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return <EventMetricsDisplayCardStackComponent metrics={metrics} />
}

export default ShowsHomePageMessagesMetricsSubSection
