// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import ModalComponent from '../global/modalComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentAboutModal: React.FC<{
  show: boolean
  onHide?: () => void
  handleModal?: any
  dataToPassOn?: {
    summary: string
  }
}> = ({ show, onHide, handleModal, dataToPassOn }) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <ModalComponent
      show={show}
      onHide={onHide}
      handleModal={handleModal}
      type="full-modal"
      title="About"
      size="xl"
    >
      <div className="text-[#3C4B7199] text-[14px]">
        {dataToPassOn?.summary ? <p>{dataToPassOn?.summary}</p> : null}
      </div>
    </ModalComponent>
  )
}

export default EventDetailViewComponentAboutModal
