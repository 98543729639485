import { IonList } from '@ionic/react'
import { useParams } from 'react-router'

// Components
import AllEventList from '../root/AllEventList'
import AllEventListingRootPage from '../root/AllEventListingRootPage'

// Redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

// Images
import { useEffect, useState } from 'react'
import WrapperContainer from '../../../components/common/wrappers/WrapperContainer'
import OrganizerRootSideProfileCard from '../../../components/organizerSideProfileCard/OrganizerRootSideProfileCard'
import { CONST } from '../../../const/const'
import { userProfileDtos } from '../../../dtos/userProfileDtos'
import useProfileHook from '../../../hooks/users/competitor/profile/useProfileHook'
import { getConvertedData } from '../../../models/interface.helper'
import {
  selectVisitedUserDetailsR,
  selectVisitedUserProfileDetailsR,
  setVisitedUserDetailsAc,
  setVisitedUserProfileDetailsAc,
} from '../../../store/profilePage/profilePageSlice'
import { IUserInterfaceExtended } from '../../../store/storeHelpers/userSlice/types'
import { selectProfileData } from '../../../store/user/userSlice'
import { IHorseData } from '../../../models/horse/horse.interface'
import HorseSidePanel from '../root/HorseSidePanel'
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type Props = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
  title: string
  activeTab: number
  isOthersProfile?: boolean
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const AllEventPageWrapper = ({ handleModal }: Props) => {
  // Hooks and vars
  const { userId: visitedUserProfileId } = useParams<{ userId: string }>()
  const { userId: teamMemberId } = useParams<{ userId: string }>()
  const [showHorseData, setShowHorseData] = useState<IHorseData[]>([])

  const dispatch = useAppDispatch()

  const { getModelledProfileDataFn } = useProfileHook({ dontFetch: false })

  const visitedUserDetails = useAppSelector(selectVisitedUserDetailsR)
  const loggedInUserProfileDetailsR = useAppSelector(selectProfileData)
  const visitedUserProfileDetailsR = useAppSelector(selectVisitedUserProfileDetailsR)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (visitedUserProfileId) getUserDetails(visitedUserProfileId)

    if (teamMemberId) getUserDetails(teamMemberId)

    return () => {
      dispatch(setVisitedUserProfileDetailsAc(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitedUserProfileId, teamMemberId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (
      (!loggedInUserProfileDetailsR && !visitedUserProfileDetailsR) ||
      (visitedUserProfileId && !visitedUserProfileDetailsR)
    )
      return

    let isOthersProfile = false
    let profileDetails: IUserInterfaceExtended | null = null
    let isOrganizerProfileView = false

    if (
      (visitedUserProfileDetailsR &&
        visitedUserProfileDetailsR.id !== loggedInUserProfileDetailsR.id) ||
      (visitedUserProfileId && visitedUserProfileId !== loggedInUserProfileDetailsR?.id)
    )
      isOthersProfile = true

    if (
      (visitedUserProfileDetailsR?.userType === CONST.USE_MODE.ORGANIZER && isOthersProfile) ||
      (loggedInUserProfileDetailsR?.userType === CONST.USE_MODE.ORGANIZER && !isOthersProfile)
    )
      isOrganizerProfileView = true

    if (isOthersProfile && visitedUserProfileDetailsR) profileDetails = visitedUserProfileDetailsR
    else if (loggedInUserProfileDetailsR) profileDetails = loggedInUserProfileDetailsR

    if (profileDetails)
      dispatch(
        setVisitedUserDetailsAc({
          isMyProfile: isOthersProfile === false,
          isOrganizerProfileView,
          profileDetails,
        })
      )
    else dispatch(setVisitedUserDetailsAc(null))

    return () => {
      dispatch(setVisitedUserDetailsAc(null))
    }
  }, [dispatch, loggedInUserProfileDetailsR, visitedUserProfileDetailsR, visitedUserProfileId])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async function getUserDetails(userId: string) {
    let res = await getModelledProfileDataFn(userId)
    let data = new userProfileDtos(res)
    dispatch(setVisitedUserProfileDetailsAc(getConvertedData(data) as any))
  }

  return (
    <WrapperContainer
      title="Profile"
      noBg={true}
      removeShadow={true}
      removePadding={true}
      hideTitle={false}
    >
      <>
        <div className="flex lg:flex-row flex-col gap-6">
          <div className="bg-white top-[20px] rounded-[20px]">
            <IonList className="bg-white shadow-none home_root_ion_list pb-[25px] rounded-[20px] pt-0 md list-md sticky lg:max-w-[420px]">
              {visitedUserDetails?.isOrganizerProfileView ? (
                <OrganizerRootSideProfileCard
                  isMyProfile={!!visitedUserDetails?.isMyProfile}
                  handleModal={handleModal}
                />
              ) : (
                <>
                  {' '}
                  {!showHorseData.length ? (
                    <AllEventList
                      showHorseData={showHorseData}
                      isMyProfile={!!visitedUserDetails?.isMyProfile}
                      setShowHorseData={setShowHorseData}
                    />
                  ) : (
                    <HorseSidePanel
                      showHorseData={showHorseData}
                      setShowHorseData={setShowHorseData}
                      isMyProfile={!!visitedUserDetails?.isMyProfile}
                    />
                  )}
                </>
              )}
            </IonList>
          </div>
          <div className="grow !w-[unset] overflow-auto top-[20px]">
            <IonList className="bg-white home_root_ion_list min-h-full rounded-[20px] mb-0 pb-0 pt-0 md list-md sticky w-full h-full">
              <AllEventListingRootPage handleModal={handleModal} />
            </IonList>
          </div>
        </div>
      </>
    </WrapperContainer>
  )
}

export default AllEventPageWrapper
