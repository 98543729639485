// Constants
import { CONST } from '../../../const/const'
import HelpRootPageFaqTab from '../tabs/HelpRootPageFaqTab'
import HelpRootPageHelpTab from '../tabs/HelpRootPageHelpTab'
import HelpRootPagePoliciesTab from '../tabs/HelpRootPagePoliciesTab'

// Components

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%\

// Types

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

/**
 *
 * @TODO todo
 */
const HelpPageDisplayTabs = ({
  handleModal,
  selectedTab,
}: {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
  selectedTab: string
}) => {
  // Funtions
  const selectTab = (selected_tab: string) => {
    switch (selected_tab) {
      case CONST.UI.USER.HELP.TABS.HELP.VALUE:
        return <HelpRootPageHelpTab handleModal={handleModal} />
      case CONST.UI.USER.HELP.TABS.FAQ.VALUE:
        return <HelpRootPageFaqTab handleModal={handleModal} />
      case CONST.UI.USER.HELP.TABS.POLICIES.VALUE:
        return <HelpRootPagePoliciesTab handleModal={handleModal} />
    }
  }

  return <>{selectTab(selectedTab)}</>
}

export default HelpPageDisplayTabs
