// ############################################################
/**
 * @todo Document this
 */
// ####################################################

import MoreButton from './global/MoreButton'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import DataNotAvailable from '../../../common/alerts/data-not-available/DataNotAvailable'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentPolicies: React.FC<{ handleModal?: any; policiesData?: any }> = ({
  handleModal,
  policiesData,
}) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  if (typeof policiesData !== 'object' || !Object.keys(policiesData ?? {}).length)
    return (
      <DataNotAvailable
        mode="text"
        containerClassName="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base"
        text="Nothing to show"
      />
    )

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-[20%_80%] md:gap-y-10 2xl:gap-y-10 text-sm 2xl:text-base text-SeabiscuitDark200ThemeColor">
        {policiesData?.eventRules && (
          <>
            <div className="mt-6 md:mt-0 flex ">
              <img
                src="assets/og_icons/Scroll-1.svg"
                alt="event rules"
                className="w-5 h-5 2xl:w-6 2xl:h-6 mr-2"
              />
              <span className="font-normal">Event Rules</span>
            </div>
            <div className="text-opacity-[.85] text-SeabiscuitDark200ThemeColor">
              {policiesData?.eventRules.length > 320
                ? `${policiesData?.eventRules.slice(0, 320)}${policiesData?.eventRules.length > 320 ? '...' : null}`
                : policiesData?.eventRules}
            </div>
          </>
        )}
        {policiesData?.attireTack && (
          <>
            <div className="mt-6 md:mt-0 flex">
              <img
                src="assets/og_icons/Suit-1.svg"
                alt="event rules"
                className="w-5 h-5 2xl:w-6 2xl:h-6 mr-2"
              />
              <span className="font-normal">Attire, Tack</span>
            </div>
            <div className="text-opacity-[.85] text-SeabiscuitDark200ThemeColor">
              {policiesData?.attireTack.length > 320
                ? `${policiesData?.attireTack.slice(0, 320)}${policiesData?.attireTack.length > 320 ? '...' : null}`
                : policiesData?.attireTack}
            </div>
          </>
        )}
        {policiesData?.orderOfGo && (
          <>
            <div className="mt-6 md:mt-0 flex">
              <img
                src="assets/og_icons/Shortlist-1.svg"
                alt="event rules"
                className="w-5 h-5 2xl:w-6 2xl:h-6 mr-2"
              />
              <span className="font-normal">Order of go</span>
            </div>
            <div className="text-opacity-[.85] text-SeabiscuitDark200ThemeColor">
              {policiesData?.orderOfGo.length > 320
                ? `${policiesData?.orderOfGo.slice(0, 320)}${policiesData?.orderOfGo.length > 320 ? '...' : null}`
                : policiesData?.orderOfGo}
            </div>
          </>
        )}
        {policiesData?.parking && (
          <>
            <div className="mt-6 md:mt-0 flex">
              <img
                src="assets/og_icons/Fiat 501.svg"
                alt="event rules"
                className="w-5 h-5 2xl:w-6 2xl:h-6 mr-2"
              />
              <span className="font-normal">Parking</span>
            </div>
            <div className="text-opacity-[.85] text-SeabiscuitDark200ThemeColor">
              {policiesData?.parking.length > 320
                ? `${policiesData?.parking.slice(0, 320)}${policiesData?.parking.length > 320 ? '...' : null}`
                : policiesData?.parking}
            </div>
          </>
        )}
        {policiesData?.rvs && (
          <>
            <div className="mt-6 md:mt-0 flex">
              <img
                src="assets/og_icons/Railcar-1.svg"
                alt="event rules"
                className="w-5 h-5 2xl:w-6 2xl:h-6 mr-2"
              />
              <span className="font-normal">RV's</span>
            </div>
            <div className="text-opacity-[.85] text-SeabiscuitDark200ThemeColor">
              {policiesData?.rvs.length > 320
                ? `${policiesData?.rvs.slice(0, 320)}${policiesData?.rvs.length > 320 ? '...' : null}`
                : policiesData?.rvs}
            </div>
          </>
        )}
        <div className="text-[14px]">&nbsp;</div>
      </div>
      <MoreButton onClick={() => handleModal(true, MODAL_CONSTS.POLICIES, policiesData)} />
    </>
  )
}

export default EventDetailViewComponentPolicies
