// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormBuilderUtilJumperSectionRowAccordionComponent = styled(
  (props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />
)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

export default EventPrizeListFormBuilderUtilJumperSectionRowAccordionComponent
