import React from 'react'

type IProps = {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: {
    // userId: string
  }
}

export const AddTeamMemberToPaperworkStep3: React.FC<IProps> = () => {
  return <></>
}
