import { MODAL_CONSTS } from '../../../../const/modal-const'
import { Clear } from '@mui/icons-material'

// Types
type Props = {
  show: boolean
  handleModal?: any
  dataToPassOn?: {
    noteInputRef?: HTMLInputElement
    setValue?: (name: string, value: string) => void
  }
}

const ExhibitorMessageUserModal = (props: Props) => {
  // Functions

  /** @info Closes the modal while setting the value of the inputref passed */
  const closeModal = () => {
    props.handleModal(false, MODAL_CONSTS.MESSAGE_USER)
  }

  return (
    <div
      className={`fixed left-0 right-0 top-0 bottom-0 z-10  transition-all ${props.show ? 'visible' : 'invisible'}`}
    >
      <div
        className={`fixed bg-white p-4 rounded-xl z-10 left-[calc(50%_-_1rem)] flex flex-col top-[calc(50%_-_1rem)] translate-x-[-50%] translate-y-[-60%] ${props.show ? '!translate-y-[-50%]' : ''} w-[calc(100%_-_2rem)] max-w-md m-2 opacity-${props.show ? '1' : '0'} transition-all`}
      >
        <div className="overflow-auto max-h-screen">
          <div className="text-right">
            <button onClick={closeModal}>
              {/* <img
                                src="/assets/og_icons/Close.svg"
                                className="w-6"
                                alt="close icon"
                            /> */}
              <Clear
                fontSize={'small'}
                style={{
                  color: '#122B46',
                  fontWeight: '400',
                }}
              />
            </button>
          </div>
          <h2 className="m-0 text-[25px] font-normal mt-[-1rem] text-SeabiscuitDark200ThemeColor">
            Message
          </h2>
          <div className="text-center m-1 mt-4">
            <textarea
              placeholder="Type message...."
              className="min-h-[400px] rounded-xl w-full border-1 !border-[#D3DAEE] h-12 mt-2 focus:outline-none focus:ring-0"
            />
            <button
              onClick={closeModal}
              className="rounded-lg bg-SeabiscuitMainThemeColor text-white w-[250px] h-9 mt-4"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div
        className={`fixed left-0 right-0 top-0 bottom-0 bg-black/30 opacity-${props.show ? '1' : '0'} transition-all`}
      ></div>
    </div>
  )
}

export default ExhibitorMessageUserModal
