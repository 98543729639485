import { FC, useState } from 'react'

type DataItem = {
  title: string
  content: string
  endDate: string
  membershipStatus: string
  image: string
}

type IDetailRowProps = {
  number: DataItem
}

const ExhibitorFeesInput: FC<{
  className?: string
  data?: string
  icon?: string
}> = ({ className, data, icon }) => {
  return (
    <>
      {icon ? (
        <div className={`${className}`}>
          <img className="absolute left-3 top-3" src={icon} alt="icons" />
          {data}
        </div>
      ) : (
        <div className={`${className}`}>{data}</div>
      )}
    </>
  )
}

const DetailRow = ({ number }: IDetailRowProps) => {
  const [error, setError] = useState(false)
  return (
    <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
      <ExhibitorFeesInput
        className="max-h-[15px] md:max-h-[initial] text-[12px] md:text-[14px] rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor md:h-12 md:bg-SeabiscuitGrayThemeColor p-3 m-1 w-full md:w-1/2"
        data={number.title}
      />
      <ExhibitorFeesInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-full md:w-1/4 text-center"
        data={number.content ? number.content : 'N/A'}
      />
      <ExhibitorFeesInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-full md:w-1/4 text-center"
        data={number.endDate ? number.endDate : 'N/A'}
      />
      <ExhibitorFeesInput
        className="rounded-lg xl:text-[14px] 2xl:text-base text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-full md:w-1/4 text-center"
        data={number.membershipStatus ? number.membershipStatus : 'N/A'}
      />
      <div className="rounded-lg h-12 w-full md:w-1/4 min-w-[100px] flex justify-center items-center bg-SeabiscuitGrayThemeColor">
        {number.image ? (
          <img
            className={'w-auto h-1/2 rounded cursor-pointer hover:opacity-70 transition-all'}
            src={!error ? number.image : 'assets/og_icons/FullScreen-1.svg'}
            onError={() => setError(true)}
            alt=""
            onClick={() => window.open(number.image, '_blank')}
          />
        ) : (
          'N/A'
        )}
      </div>
    </div>
  )
}

type Props = {
  dataNumbers: DataItem[]
}

const ExhibitorCompetitionNumbersAccordion: React.FC<Props> = ({ dataNumbers }) => {
  return (
    <>
      {dataNumbers && dataNumbers.length
        ? dataNumbers.map((number, index) => {
            return <DetailRow key={index} number={number} />
          })
        : null}
    </>
  )
}

export default ExhibitorCompetitionNumbersAccordion
