import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { KeyboardArrowRight, KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { ROUTES_CONST } from '../../../../const/routes-const'

const SmallTableLayout = ({
  children,
  title,
  className,
  redirectUrl,
}: {
  children: ReactJSXElement
  title?: string
  eventId?: string
  className?: string
  redirectUrl: string
}) => {
  return (
    <>
      <div
        className={`flex flex-col mt-6 p-6 2xl:px-8 rounded-[20px] bg-SeabiscuitWhiteThemeColor shadow-lg mb-[25px] ${className}`}
      >
        <div className="flex items-center mb-4 justify-between">
          <div className="flex mr-2 items-center">
            <h1 className="text-SeabiscuitDark200ThemeColor text-[20px] font-semibold">{title}</h1>
          </div>
          <Link
            className="text-nr flex items-center hover:opacity-70 transition-all"
            to={redirectUrl}
          >
            See all <KeyboardArrowRight />
          </Link>
        </div>
        {children}
      </div>
    </>
  )
}

const FullScreenTableLayout = ({
  children,
  title,
  className,
  eventId,
}: {
  children: ReactJSXElement
  title?: string
  eventId?: string
  className?: string
}) => {
  return (
    <div className="px-8">
      <div className={`mt-6 ${className}`}>
        <div className="flex items-center mb-6 justify-between">
          <div className="flex items-center">
            <h1 className="text-SeabiscuitDark200ThemeColor text-[20px] font-bold">
              <Link to={`${ROUTES_CONST.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`}>
                <KeyboardBackspace className="textSeabiscuitDark200ThemeColor mr-2" />
              </Link>
              {title}
            </h1>
          </div>
        </div>
      </div>
      <div className="p-8 rounded-xl bg-SeabiscuitWhiteThemeColor">{children}</div>
    </div>
  )
}

const ManageClinicTableWrapper = ({
  children,
  title,
  className,
  eventId,
  redirectUrl,
  isFullScreen,
}: {
  children: ReactJSXElement
  title?: string
  eventId?: string
  className?: string
  redirectUrl: string
  isFullScreen?: boolean
  tooltipText?: string
}) => {
  return (
    <>
      {!isFullScreen ? (
        <SmallTableLayout
          title={title}
          redirectUrl={redirectUrl}
          eventId={eventId}
          className={className}
        >
          {children}
        </SmallTableLayout>
      ) : (
        <FullScreenTableLayout title={title} className={className} eventId={eventId}>
          {children}
        </FullScreenTableLayout>
      )}
    </>
  )
}

export default ManageClinicTableWrapper
