import { CONST } from '../../../../const/const'
import { EVENT_SCORING_FB_TEMPLATE_SAMPLE } from './template/sample/event-scoring-template-sample'

export const EVENT_SCORING_FORM_BUILDER = {
  FIELD: {
    DEFAULT: {
      TEXT: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
        meta: null,
        value: '',
        validation: null,
        rules: null,
      },
      NUMBER: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
    },
  },
  TEMPLATE: {
    SAMPLE: EVENT_SCORING_FB_TEMPLATE_SAMPLE,
  },
}
