/* eslint-disable react/jsx-no-target-blank */
import { Tooltip } from '@mui/material'

// Constants
import { PROFILE_ELIGIBILITY_TAB_DATA } from './data'

import { IProfileEligibilityTabCols } from './data'

// Styles
import { AutorenewRounded } from '@mui/icons-material'
import clsx from 'clsx'
import { useRef, useState } from 'react'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import TooltipIcon from '../../../../helpers/TooltipIcon'
import DataTableLoader from '../../../../pages/myEvent/component/DataTableLoader'
import NoDataFoundSkeleton from '../../../common/banners/NoDataFoundSkeleton'
import HorizantalDivider from '../../../events/views/details/global/HorizantalDivider'
import FormHeader from '../../../ui/form/form-header/FormHeader'
import './styles/ProfileEligibilityTab.css'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'

import formFunctions from '../../../ui/form/form-functions/formFunctions'
import { toast } from 'react-toastify'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IProps = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
  activeTab: string
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
}

// Constants
const widths = ['w-[18%]', 'w-[16%]', 'w-[36%]', 'w-[18%]', 'w-[12%]']

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const ProfileEligibilityTab = (props: IProps) => {
  // Hooks and vars
  const dashStatusRef = useRef(false)

  const toastFunctions = useToasterHelper()

  const [showData] = useState(false)
  const [refreshButton, setRefreshButton] = useState(false)

  let key: string | null = null
  let tooltip: null | string = null

  const notDataFound = false
  const { setActiveTab } = props
  const formStyles = formFunctions.getFormStyles({ noPaddingRt: true })

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleTabs = () => {
    setActiveTab('profile')
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const refreshHandle = () => {
    setRefreshButton(true)
    setTimeout(() => {
      setRefreshButton(false)
    }, 3000)
  }

  const commingSoonMessage = () => {
    toastFunctions.info({
      message: MESSAGES_CONST.COMING_SOON,
    })
  }

  return (
    <>
      <FormHeader
        title="Eligibility status"
        description="View eligibility status and rectify issues."
        headerButtonsContainer={
          <div className="flex items-center gap-2">
            <button
              //onClick={refreshHandle}
              onClick={commingSoonMessage}
              className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
            >
              {refreshButton ? (
                <AutorenewRounded fontSize="small" className="animate-spin" />
              ) : (
                'Refresh'
              )}
            </button>
          </div>
        }
      />

      <div className={formStyles.className} style={formStyles.styles}>
        {refreshButton ? (
          <div className="flex items-center justify-center h-[50vh] text-SeabiscuitMainThemeColor">
            <DataTableLoader />
          </div>
        ) : notDataFound ? (
          <NoDataFoundSkeleton
            firstImageSrc="assets/img/SkeletonTick.svg"
            secondImageSrc="assets/img/SkeletonClose.svg"
            firstSkeletonColor="#E6EAF2"
            secondSekeletonColor="#D3DAEE"
            changeImageAfter={2}
            itemsPerRow={2}
            rotate={false}
            message={'Sync your profile with the USEF to view eligibility status'}
          />
        ) : (
          <div className="max-w-full overflow-auto">
            {showData && (
              <div className="flex text-SeabiscuitDark200ThemeColor text-sm font-bold mb-3 gap-3">
                {Object.keys(PROFILE_ELIGIBILITY_TAB_DATA.COLS).map((curr: string, index) => {
                  key =
                    PROFILE_ELIGIBILITY_TAB_DATA.COLS[curr as keyof IProfileEligibilityTabCols].KEY
                  tooltip =
                    PROFILE_ELIGIBILITY_TAB_DATA.COLS[curr as keyof IProfileEligibilityTabCols]
                      .TOOLTIP_TEXT
                  return (
                    <div
                      key={`${JSON.stringify(curr)}${index}`}
                      className={clsx('flex items-center h-[51px]', widths?.[index] ?? '')}
                    >
                      <span>
                        {
                          PROFILE_ELIGIBILITY_TAB_DATA.COLS[
                            curr as keyof IProfileEligibilityTabCols
                          ].KEY
                        }
                      </span>
                      <div className="inline-flex items-center justify-end h-6">
                        <Tooltip
                          title={<span className="tooltip_title">{tooltip ? tooltip : key}</span>}
                          placement="top"
                          arrow
                        >
                          <button className="" onClick={(e) => e.preventDefault()}>
                            <TooltipIcon />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}

            {showData ? (
              PROFILE_ELIGIBILITY_TAB_DATA.DATA.map((curr, index) => {
                if (
                  !dashStatusRef.current &&
                  (curr.status === 'Expired' || curr.status === 'Suspend')
                ) {
                  dashStatusRef.current = true
                } else if (
                  dashStatusRef.current &&
                  (curr.status === 'Expired' || curr.status === 'Suspend')
                ) {
                  dashStatusRef.current = false
                }
                return (
                  <>
                    {dashStatusRef.current ? <HorizantalDivider /> : null}

                    <div
                      key={`${JSON.stringify(curr)}${index}`}
                      className={`flex h-[51px] cols_container gap-3${!curr.added ? ' not_added' : ' added'} ${curr.status === 'Expired' || curr.status === 'Suspend' ? ' expired' : ''}`}
                    >
                      <span
                        className={widths?.[0]}
                        title={curr.status !== 'Suspend' ? curr.item_name : ''}
                      >
                        {curr.status === 'Suspend' ? 'Suspension' : curr.item_name}
                      </span>
                      <span className={widths?.[1]}>
                        {curr.status === 'Suspend' ? 'Yes' : curr.status}
                      </span>

                      <span className={widths?.[2]} title={curr.details}>
                        {curr.details}
                      </span>

                      <span className={widths?.[3]} title={curr.added ? curr.date : ''}>
                        {curr.added ? curr.date : 'N/A'}
                      </span>
                      {!curr.added && curr.link === 'BackToProfile' ? (
                        <span
                          className={`text-center cursor-pointer ${widths?.[4]}`}
                          onClick={handleTabs}
                        >
                          Add
                        </span>
                      ) : curr.added ? (
                        curr.link ? (
                          <a
                            id="goToLink"
                            target="_blank"
                            href={`${curr.link}`}
                            className={`text-center cursor-pointer ${widths?.[4]}`}
                          >
                            Manage
                          </a>
                        ) : (
                          <span
                            className={`text-center cursor-pointer ${widths?.[4]}`}
                            onClick={() => props.handleModal(true, MODAL_CONSTS.MEMBERSHIP_MANAGE)}
                          >
                            Manage
                          </span>
                        )
                      ) : (
                        <span
                          className={`text-center cursor-pointer ${widths?.[4]}`}
                          onClick={() => props.handleModal(true, MODAL_CONSTS.MEMBERSHIP_ADD)}
                        >
                          Add
                        </span>
                      )}
                      <span className="hidden">
                        {
                          (dashStatusRef.current =
                            curr.status === 'Expired' || curr.status === 'Suspend' ? true : false)
                        }
                      </span>
                    </div>
                  </>
                )
              })
            ) : (
              <>
                <div className="flex items-center flex-col">
                  <p>Sync your profile with the USEF to view eligibility status</p>
                  <img
                    src="/assets/placeholders/eligibility.svg"
                    alt="eligibility"
                    className="w-[40%]"
                  />
                  {/*Was setShowData(true) inside of the onClick*/}
                  <p
                    className="text-SeabiscuitMainThemeColor cursor-pointer"
                    onClick={commingSoonMessage}
                  >
                    Sync with USEF &gt;
                  </p>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default ProfileEligibilityTab
