// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { Fragment } from 'react'

import { Listbox, Transition } from '@headlessui/react'

import generateFieldValidation, {
  validateCheckboxFieldChange,
  validateChoiceFieldChange,
} from '../../../../validation/EventPrizeListFormBuilderUtilValidation'
import RedCheck from '../../../../../common/assets/invalid.svg'
import GreenCheck from '../../../../../common/assets/valid.svg'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import {
  postEvaluateCheckboxFieldChange,
  postEvaluateChoiceFieldChange,
  preEvaluateCheckboxFieldChange,
  preEvaluateChoiceFieldChange,
} from '../../../../evaluation/EventPrizeListFormBuilderUtilEvaluation'
import { ChevronDownIcon } from '@heroicons/react/solid'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface IEventPrizeListFormBuilderUtilHunterLeagueSectionRow {
  section: any
  division: any
  register: any
  disciplineIndex: number
  divisionIndex: number
  sectionIndex: number
  errors: any
  control: any
  setValue: any
  getValues: any
  setError: any
  clearErrors: any
  trigger: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormBuilderUtilHunterLeagueSectionRow: React.FC<
  IEventPrizeListFormBuilderUtilHunterLeagueSectionRow
> = (props) => {
  // const [isExpanded, setIsExpanded] = useState(false)

  // const handleOnAccordionExpansionToggle = useCallback(
  //     _ => {
  //         setIsExpanded(
  //             prevState => !prevState
  //         )
  //     }
  //     ,[[isExpanded, setIsExpanded]])

  return (
    <>
      <div className="scroll-group w-full overflow-auto scrollbar-hide mb-3">
        <div className="grid grid-cols-[70px_260px_repeat(3,1fr)] gap-2 min-w-[1200px] w-full -ml-8">
          <div></div>

          {/*=================================*/}
          {/* Section Selection */}
          {/*=================================*/}
          <div>
            <Controller
              name={`data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.meta.selected`}
              control={props.control}
              render={({ field: { value } }) => (
                <div
                  className={clsx(
                    `text-[14px] w-full rounded-md flex items-center p-4 h-12`,
                    value
                      ? ` bg-SeabiscuitGrayThemeColor`
                      : `bg-white border-SeabiscuitGrayThemeColor border-2`
                  )}
                >
                  {value ? (
                    <>
                      <div
                        onClick={() => {
                          const shouldChange = preEvaluateCheckboxFieldChange(
                            props.section.sectionSubCode,
                            props.section,
                            props.division,
                            value,
                            !value,
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          )
                          if (shouldChange) {
                            props.setValue(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.meta.selected`,
                              !value
                            )
                          }
                          validateCheckboxFieldChange(
                            props.section.sectionSubCode,
                            props.section,
                            props.division,
                            value,
                            !value,
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          )
                          postEvaluateCheckboxFieldChange(
                            props.section.sectionSubCode,
                            props.section,
                            props.division,
                            value,
                            !value,
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          )
                        }}
                        className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                      >
                        {props.getValues(
                          `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.value`
                        )}
                      </div>
                      <img src={GreenCheck} alt="green check" />
                    </>
                  ) : (
                    <>
                      <div
                        onClick={() => {
                          const shouldChange = preEvaluateCheckboxFieldChange(
                            props.section.sectionSubCode,
                            props.section,
                            props.division,
                            value,
                            !value,
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          )
                          if (shouldChange) {
                            props.setValue(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.meta.selected`,
                              !value
                            )
                          }
                          validateCheckboxFieldChange(
                            props.section.sectionSubCode,
                            props.section,
                            props.division,
                            value,
                            !value,
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          )
                          postEvaluateCheckboxFieldChange(
                            props.section.sectionSubCode,
                            props.section,
                            props.division,
                            value,
                            !value,
                            props.setValue,
                            props.getValues,
                            props.setError,
                            props.clearErrors,
                            props.trigger,
                            props.disciplineIndex,
                            props.divisionIndex,
                            props.sectionIndex
                          )
                        }}
                        className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer"
                      >
                        {props.getValues(
                          `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionSubCode.value`
                        )}
                      </div>
                      <input type="checkbox" checked={value} disabled />
                    </>
                  )}
                </div>
              )}
            />
            {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
              ?.sections[props.sectionIndex]?.sectionSubCode?.value && (
              <div className="text-red-500">
                {
                  props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                    ?.sections[props.sectionIndex]?.sectionSubCode?.value.message
                }
              </div>
            )}
          </div>

          {/*=================================*/}
          {/* Qualifying Class */}
          {/*=================================*/}
          <div>
            <Controller
              name={`data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionQualifying.meta.enabled`}
              control={props.control}
              render={({ field: { value } }) => (
                <>
                  {value ? (
                    <Listbox
                      value={props.getValues(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionQualifying.meta.selected`
                      )}
                      multiple={props.getValues(
                        `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionQualifying.meta.multiple`
                      )}
                      onChange={(values: any) => {
                        const previousValue = props.getValues(
                          `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionQualifying.meta.selected`
                        )

                        const shouldChange = preEvaluateChoiceFieldChange(
                          props.section.sectionQualifying,
                          props.section,
                          props.division,
                          previousValue,
                          values,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex
                        )

                        if (shouldChange) {
                          if (
                            props.getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionQualifying.meta.multiple`
                            )
                          ) {
                            props.setValue(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionQualifying.value`,
                              values.join(',')
                            )
                          } else {
                            props.setValue(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionQualifying.value`,
                              values
                            )
                          }
                          props.setValue(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionQualifying.meta.selected`,
                            values
                          )
                        }

                        validateChoiceFieldChange(
                          props.section.sectionQualifying,
                          props.section,
                          props.division,
                          previousValue,
                          values,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex
                        )

                        postEvaluateChoiceFieldChange(
                          props.section.sectionQualifying,
                          props.section,
                          props.division,
                          previousValue,
                          values,
                          props.setValue,
                          props.getValues,
                          props.setError,
                          props.clearErrors,
                          props.trigger,
                          props.disciplineIndex,
                          props.divisionIndex,
                          props.sectionIndex
                        )
                      }}
                    >
                      <Listbox.Button
                        className={clsx(
                          `text-[14px] w-full rounded-md flex items-center p-4 h-12`,
                          value
                            ? ` bg-SeabiscuitGrayThemeColor`
                            : `bg-white border-SeabiscuitGrayThemeColor border-2`
                        )}
                      >
                        <div className="w-full bg-transparent border-0 focus:ring-0 bg-white cursor-pointer flex items-between">
                          {props.getValues(
                            `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionQualifying.value`
                          )}
                        </div>
                        <ChevronDownIcon
                          className={`text-[14px] w-full rounded-md flex items-center p-4 h-16`}
                        />
                        {props.errors?.data?.items[props.disciplineIndex]?.divisions[
                          props.divisionIndex
                        ]?.sections[props.sectionIndex]?.sectionQualifying?.value ? (
                          <img src={RedCheck} alt="red check" />
                        ) : (
                          <img src={GreenCheck} alt="green check" />
                        )}
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {props
                            .getValues(
                              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionQualifying.meta.options`
                            )
                            .map((item: any) => (
                              <Listbox.Option
                                key={item}
                                value={item}
                                className="text-[14px] w-full rounded-md flex items-center p-4 h-12 bg-SeabiscuitGrayThemeColor"
                              >
                                {item}
                                <>
                                  {props
                                    .getValues(
                                      `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionQualifying.meta.selected`
                                    )
                                    .includes(item) && <img src={GreenCheck} alt="green check" />}
                                </>
                              </Listbox.Option>
                            ))}
                        </Listbox.Options>
                      </Transition>
                    </Listbox>
                  ) : (
                    <div
                      className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
                    >
                      <input
                        type="text"
                        className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                        placeholder={'Qualifying Class'}
                        disabled={true}
                        {...props.register(
                          `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionQualifying.value`
                        )}
                      />
                    </div>
                  )}
                </>
              )}
            />
            {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
              ?.sections[props.sectionIndex]?.sectionQualifying?.value && (
              <div className="text-red-500">
                {
                  props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                    ?.sections[props.sectionIndex]?.sectionQualifying?.value.message
                }
              </div>
            )}
          </div>

          {/*=================================*/}
          {/* Price */}
          {/*=================================*/}
          <div>
            {props.getValues(
              `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.meta.enabled`
            ) ? (
              <div
                className={`text-[14px] w-full bg-SeabiscuitGrayThemeColor rounded-md flex items-center p-4 h-12`}
              >
                <input
                  type="text"
                  className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                  placeholder={'Total Price Money'}
                  {...props.register(
                    `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.value`,
                    {
                      validate: generateFieldValidation(
                        props.section.sectionTotalPriceMoney,
                        props.getValues(
                          `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.meta.enabled`
                        ),
                        props.setValue,
                        props.getValues,
                        props.setError,
                        props.clearErrors,
                        props.trigger,
                        props.disciplineIndex,
                        props.divisionIndex,
                        props.sectionIndex
                      ),
                    }
                  )}
                />
                {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                  ?.sections[props.sectionIndex]?.sectionTotalPriceMoney?.value ? (
                  <img src={RedCheck} alt="red check" />
                ) : (
                  <img src={GreenCheck} alt="green check" />
                )}
              </div>
            ) : (
              <div
                className={`text-[14px] w-full bg-white border-SeabiscuitGrayThemeColor border-2 rounded-md flex items-center p-4 h-12`}
              >
                <input
                  type="text"
                  className="w-full bg-transparent border-0 focus:ring-0 bg-white text-[14px]"
                  placeholder={'Price Money'}
                  disabled={true}
                  {...props.register(
                    `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.value`,
                    {
                      validate: generateFieldValidation(
                        props.section.sectionTotalPriceMoney,
                        props.getValues(
                          `data.items.${props.disciplineIndex}.divisions.${props.divisionIndex}.sections.${props.sectionIndex}.sectionTotalPriceMoney.meta.enabled`
                        ),
                        props.setValue,
                        props.getValues,
                        props.setError,
                        props.clearErrors,
                        props.trigger,
                        props.disciplineIndex,
                        props.divisionIndex,
                        props.sectionIndex
                      ),
                    }
                  )}
                />
              </div>
            )}
            {props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
              ?.sections[props.sectionIndex]?.sectionTotalPriceMoney?.value && (
              <div className="text-red-500">
                {
                  props.errors?.data?.items[props.disciplineIndex]?.divisions[props.divisionIndex]
                    ?.sections[props.sectionIndex]?.sectionTotalPriceMoney?.value.message
                }
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
EventPrizeListFormBuilderUtilHunterLeagueSectionRow.defaultProps = {
  section: null,
  register: null,
}

export default EventPrizeListFormBuilderUtilHunterLeagueSectionRow
