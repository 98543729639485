// Third party
import { Link } from 'react-router-dom'
import clsx from 'clsx'

const ManageClinicNOtherCard = ({
  className,
  title,
  value1,
  value2,
  icon,
  disabled,
  isFilledTextDisabled,
  isUnfilledDisabled,
  url,
}: {
  icon: string
  title?: string
  category: string
  disabled?: boolean
  isFilledTextDisabled?: boolean
  isUnfilledDisabled?: boolean
  className?: string
  value1?: string | number
  value2?: string | number
  url?: string
}) => {
  return (
    <div className={clsx(`flex flex-col p-2`, className)}>
      <div
        className={clsx(
          'p-6 2xl:px-8 rounded-[20px] shadow-lg h-full flex-col justify-between',
          disabled ? 'bg-[#bec5cc0d]' : 'bg-SeabiscuitWhiteThemeColor'
        )}
      >
        <div className="flex items-center mb-8">
          <div className="icons bg-[#00B6AA26] p-1 rounded-md 2xl:rounded-xl w-10 h-10 2xl:w-12 2xl:h-12 flex items-center justify-center mr-3 2xl:mr-4 shrink-0">
            <img src={icon} alt="usersGroup" />
          </div>

          <div
            className={clsx(
              'title text-[14px] 2xl:text-lg font-semibold',
              disabled ? 'text-[#122B4680]' : ' cursor-pointer text-SeabiscuitDark200ThemeColor'
            )}
          >
            {!disabled && url ? (
              <Link className={'text-[14px]'} to={url}>
                {title}
              </Link>
            ) : (
              <span className={'text-[14px]'}>{title}</span>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between flex-wrap gap-5">
          <div className="text-SeabiscuitDark200ThemeColor text-[22px] 2xl:text-[30px]">
            {typeof value1 === 'number' ? (isNaN(Number(value1)) ? 0 : value1) : value1}
            {/*{!isFilledTextDisabled && (*/}
            {/*  <div className="text-[10px] text-SeabiscuitGray200ThemeColor">filled</div>*/}
            {/*)}*/}
          </div>

          {/*{!isUnfilledDisabled && (*/}
          {/*  <div>*/}
          {/*    <div className="text-[#7000FF] mb-1 text-base 2xl:text-[23px] bg-[#8B2CF526] rounded-md font-normal min-w-fit h-8 flex items-center justify-center px-2">*/}
          {/*      {typeof value2 === 'number' ? (isNaN(Number(value2)) ? 0 : value2) : value2}*/}
          {/*    </div>*/}
          {/*    /!*<div className="text-[10px] text-SeabiscuitGray200ThemeColor text-end">unfilled</div>*!/*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </div>
    </div>
  )
}

export default ManageClinicNOtherCard
