import { useContext } from 'react'
import EventReviewPublishInput from './EventReviewPublishInput'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import DataNotAvailable from '../../../../common/alerts/data-not-available/DataNotAvailable'

const DetailRow = ({
  title,
  note,
  onClick,
}: {
  title: any
  note: any
  onClick: (text: any) => void
}) => {
  return (
    <div className="flex w-full max-w-full">
      <EventReviewPublishInput
        className="rounded-lg flex items-center text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 w-80 whitespace-nowrap overflow-hidden text-ellipsis h-12 shrink-0"
        data={title}
      />
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 grow overflow-hidden text-ellipsis whitespace-nowrap cursor-pointer"
        onClick={() => onClick(note)}
        data={note}
      />
      <div className="rounded-lg bg-SeabiscuitGrayThemeColor p-1 m-1 h-12 w-12 shrink-0 text-center flex items-center  justify-center">
        <img className="m-auto" src="/assets/cp_icons/Ok-3.svg" alt="checkIcon" />
      </div>
    </div>
  )
}

type Props = {
  data?: any
  loading?: boolean
}

const EventPoliciesReviewPublishForm = (props: Props) => {
  const handleModalContext = useContext(HandleModalContext)

  /**
   * @param text Text to show
   * @info Opens note modal
   */
  const openAddNotesModal = (text: string) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NOTE, {
      noteInputRef: text,
    })
  }

  const { data, loading } = props
  if (loading) {
    return null
  } else {
    return data ? (
      <>
        {data.attireTack && data.attireTack !== '' ? (
          <DetailRow title="Attire / Tack" note={data.attireTack} onClick={openAddNotesModal} />
        ) : null}

        {data.eventRules && data.eventRules !== '' ? (
          <DetailRow title="Event rules" note={data.eventRules} onClick={openAddNotesModal} />
        ) : null}

        {data.orderOfGo && data.orderOfGo !== '' ? (
          <DetailRow title="Order of Go" note={data.orderOfGo} onClick={openAddNotesModal} />
        ) : null}
        {data.parking && data.parking !== '' ? (
          <DetailRow title="Parking" note={data.parking} onClick={openAddNotesModal} />
        ) : null}
        {data.ribbons && data.ribbons !== '' ? (
          <DetailRow title="Ribbons" note={data.ribbons} onClick={openAddNotesModal} />
        ) : null}

        {data.rvs && data.rvs !== '' ? (
          <DetailRow title="RV’s" note={data.rvs} onClick={openAddNotesModal} />
        ) : null}

        {data.substitutions && data.substitutions !== '' ? (
          <DetailRow title="Substitutions" note={data.substitutions} onClick={openAddNotesModal} />
        ) : null}

        {data.extra && Array.isArray(data.extra)
          ? data.extra.map((curr: any, index: number) => {
              if (!!!curr?.name || !!!curr.policy) return null
              return (
                <DetailRow
                  key={`extra${index}`}
                  title={curr.name}
                  note={curr.policy}
                  onClick={openAddNotesModal}
                />
              )
            })
          : null}
      </>
    ) : (
      <DataNotAvailable
        mode="text"
        containerClassName="text-SeabiscuitDark200ThemeColor justify-center flex"
        text={MESSAGES_CONST.POLICIES_DATA_NOT_AVAILABLE}
      />
    )
  }
}

export default EventPoliciesReviewPublishForm
