import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import clsx from 'clsx'

// Components
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import EventSchedulingFormComponentNoteModal from './components/EventSchedulingFormComponentNoteModal'
import EventSchedulingFormComponentScheduleModal from './components/EventSchedulingFormComponentScheduleModal'
import { Facilities } from './components/Facilities'
import { EntriesSchedules } from './components/EntriesSchedules'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import { CustomSchedules } from './components/CustomSchedules'

// Third party
import { AddCircleOutline } from '@mui/icons-material'
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams } from 'react-router'

import FirestoreService from '../../../../services/firestoreService'

import useGetEventData from '../../../../hooks/users/common/useGetEventData'

import { clearAllArr } from '../../../../commonHelpers/helpers'

import { getConvertedData } from '../../../../models/interface.helper'
import {
  IEventScheduleInterface,
  TCustomSchedule,
} from '../../../../models/event-scheduling/event-scheduling.interface'
import { EventSchedulingModel } from '../../../../models/event-scheduling/event-scheduling.model'
import { EventReviewPublishModel } from '../../../../models/event-review-publish/event-review-publish.model'
import {
  IEventFees,
  RegistrationFeesType,
} from '../../../../models/event-fees/event-fees.interface'
import { EventFeesModel } from '../../../../models/event-fees/event-fees.model'

import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'

import './assets/scheduling.css'
import './SchedulingScreenEventSchedulingFormComponent.css'
import moment from 'moment'
import Select from 'react-select'
import { daysOfWeek, sortScheduling } from '../../../../helpers/time'
import { Tooltip } from '@mui/material'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../../../const/messages-const'

interface SchedulingScreenEventSchedulingFormComponentProps {
  onValid: any
  onInvalid: any
  onValidAndExit: any
  onInvalidAndExit: any
  handleModal: (showHide: boolean, typeOfModal: string, dataToPassOn?: any) => void
  nextEventTab?: string
  eventTab?: string
}

const eventSchedulingFormDefaultValues = new EventSchedulingModel().toObject()
const eventSchedulingFormValidationSchema = EventSchedulingModel.validationSchema()

const eventFeesFormDefaultValues = new EventFeesModel().toObject()
const eventFeesFormSchema = EventFeesModel.validationSchemaScheduling()

export interface IFilters {
  facility: string
  day: string
}
const SchedulingScreenEventSchedulingFormComponent: React.FC<
  SchedulingScreenEventSchedulingFormComponentProps
> = ({
  onValid,
  onValidAndExit,
  onInvalid,
  onInvalidAndExit,
  handleModal,
  eventTab,
  nextEventTab,
}) => {
  // const dispatch = useAppDispatch()

  const { getAllData } = useGetEventData()
  const toastFunctions = useToasterHelper()

  const { eventId } = useParams<{ eventId: string }>()

  const [noteModalShow, setNoteModalShow] = useState(false)
  const [scheduleModalShow, setScheduleModalShow] = useState(false)
  const [locationOptions, setLocationOptions] = useState<{ label: string; value: string }[]>([])
  const [filters, setFilters] = useState<IFilters>({ facility: '', day: '' })

  const {
    reset: resetSchedule,
    watch: watchSchedule,
    control: controlSchedule,
    trigger: triggerSchedule,
    setValue: setValueSchedule,
    register: registerSchedule,
    getValues: getValuesSchedule,
    handleSubmit: handleSubmitSchedule,
    formState: { errors: errorsSchedule },
  } = useForm<IEventScheduleInterface>({
    defaultValues: { ...eventSchedulingFormDefaultValues },
    resolver: yupResolver(eventSchedulingFormValidationSchema),
    mode: 'onSubmit',
  })

  const facilities = useFieldArray({
    control: controlSchedule,
    name: 'facilities',
  })

  const { fields, append, remove, update } = useFieldArray({
    control: controlSchedule,
    name: 'custom',
  })

  const {
    reset: resetEntries,
    watch: watchEntries,
    trigger: triggerEntries,
    control: controlEntries,
    setValue: setValueEntries,
    register: registerEntries,
    getValues: getValuesEntries,
    handleSubmit: handleSubmitEntries,
    formState: { errors: errorsEntries },
  } = useForm<IEventFees>({
    defaultValues: { ...eventFeesFormDefaultValues },
    resolver: yupResolver(eventFeesFormSchema),
    mode: 'onChange',
  })

  useEffect(() => {
    if (eventId) {
      getAllData(eventId, ['v01_event_scheduling', 'v01_event_fees'])
    }
  }, [eventId])

  const custom = watchSchedule('custom')
  const registrationFees = watchEntries('registrationFees')

  let pendingItems = 0

  registrationFees?.forEach((schedule) => {
    if (
      !schedule.name?.trim() ||
      !schedule.note?.trim() ||
      !schedule.tag ||
      !schedule.location ||
      !moment(schedule.startDate).isValid()
    ) {
      pendingItems += 1
    }
  })

  custom?.forEach((schedule) => {
    if (
      !schedule.name?.trim() ||
      !schedule.note?.trim() ||
      !schedule.tag ||
      !schedule.location ||
      !moment(schedule.startDate).isValid()
    ) {
      pendingItems += 1
    }
  })

  const handleSchedule = () => {
    const values = getConvertedData([
      ...getValuesSchedule('custom')!,
      ...getValuesEntries('registrationFees')!,
    ])

    handleModal(true, MODAL_CONSTS.VIEW_SCHEDULE_ORGANIZER, {
      data: values,
    })
  }

  const daysOptions: string[] = []
  ;[...(registrationFees ?? []), ...(custom ?? [])].forEach((fee) => {
    const day = daysOfWeek[new Date(fee.startDate)?.getDay()]
    if (!daysOptions.includes(day)) daysOptions.push(day)
  })

  daysOptions.sort((a, b) => {
    return daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b)
  })

  const onUpdate = () => {
    const schedulesSorted = sortScheduling(watchSchedule('custom') as TCustomSchedule[])
    const entriesSorted = sortScheduling(watchEntries('registrationFees') as RegistrationFeesType[])

    setValueSchedule('custom', schedulesSorted as TCustomSchedule[])
    setValueEntries('registrationFees', entriesSorted as RegistrationFeesType[])

    toastFunctions.success({ message: MESSAGES_CONST.EVENT_SCHEDULE_UPDATED })
  }

  const onAddCustomTime = () => {
    append({
      uuid: uuidv4(),
      name: '',
      startDate: '',
      location: '',
      tag: '',
      note: '',
      startTimeHours: '00',
      startTimeMinutes: '00',
      startTimeFormat: 'AM',
    })
  }

  const saveFees = async (dataFees: IEventFees) => {
    const dataToSave = new EventFeesModel(dataFees).toFirestore()

    const publishedEventSnapShot = await FirestoreService.getItem(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
      eventId
    )

    const publishedEvent =
      EventReviewPublishModel.fromFirestoreDoc(publishedEventSnapShot).toObject()

    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
      eventId,
      { ...publishedEvent, EventFees: dataToSave }
    )

    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_FEES.NAME,
      eventId,
      dataToSave
    )
  }

  return (
    <div className="pr-5 md:pr-0">
      <EventSchedulingFormComponentNoteModal
        show={noteModalShow}
        onHide={() => setNoteModalShow(false)}
      />

      <EventSchedulingFormComponentScheduleModal
        show={scheduleModalShow}
        onHide={() => setScheduleModalShow(false)}
      />

      <EventFormHeaderComponent
        title="Scheduling"
        description="Add facilities and then build your show schedule"
      >
        <EventFormFooterCommonComponent
          eventTab={eventTab}
          nextEventTab={nextEventTab}
          onNext={async (e, publishEvent, setLoading) => {
            await handleSubmitEntries(async (dataFees) => {
              try {
                setLoading(true)
                await saveFees(dataFees)
                await handleSubmitSchedule(async (dataSchedule) => {
                  await publishEvent({
                    dataToSave: dataSchedule,
                    tabName: 'EventSchedule',
                    validFormHandler: onValid,
                  })
                }, onInvalid)(e)
                setLoading(false)
              } catch (error) {
                setLoading(false)
                console.log(error, 'error')
              }
            }, onInvalid)(e).then(async () => {})
          }}
          onSaveAndExit={async (e, publishEvent, setSaveAndExitLoading) => {
            await handleSubmitEntries(async (dataFees) => {
              try {
                setSaveAndExitLoading(true)
                await saveFees(dataFees)
                await handleSubmitSchedule(
                  (dataSchedule) =>
                    publishEvent({
                      dataToSave: dataSchedule,
                      publish: false,
                      tabName: 'EventSchedule',
                      validFormHandler: onValidAndExit,
                    }),
                  onInvalidAndExit
                )(e)
                setSaveAndExitLoading(false)
              } catch (error) {
                setSaveAndExitLoading(false)
                console.log(error, 'error')
              }
            }, onInvalidAndExit)(e)
          }}
        />
      </EventFormHeaderComponent>
      <Facilities
        watch={watchSchedule}
        handleModal={handleModal}
        facilities={facilities}
        update={update}
        fields={fields}
        setLocationOptions={setLocationOptions}
        errors={errorsSchedule}
        setValue={setValueSchedule}
        register={registerSchedule}
      />
      <div className="flex flex-wrap gap-4 items-center justify-between mt-5 py-5 border-t border-solid border-[#e4e4e440]">
        <div>
          <p className="text-lg font-bold text-SeabiscuitDark200ThemeColor">Schedule</p>
          <div className="text-sm text-SeabiscuitDark200ThemeColor/50">
            Add times to classes and build a schedule
          </div>
        </div>
        <div className="flex flex-wrap gap-4">
          <div className="w-[140px]">
            <Select
              isDisabled={locationOptions?.[0]?.label?.length <= 0}
              className="p-0 bg-transparent border-none transition-all hover:opacity-70"
              classNamePrefix="entries-select"
              isClearable={false}
              isSearchable={false}
              placeholder="Facility"
              value={filters.facility ? { label: filters.facility, value: filters.facility } : null}
              options={locationOptions}
              onChange={(newValue) => {
                if (newValue) setFilters({ ...filters, facility: newValue.value })
              }}
              menuPlacement="auto"
              menuPortalTarget={document.body}
            />
          </div>
          <div className="w-[120px]">
            <Select
              isDisabled={locationOptions?.[0]?.label?.length <= 0}
              className="p-0 bg-transparent border-none transition-all hover:opacity-70"
              classNamePrefix="entries-select"
              isClearable={false}
              isSearchable={false}
              placeholder="Day"
              value={filters.day ? { label: filters.day, value: filters.day } : null}
              options={daysOptions ? daysOptions.map((day) => ({ label: day, value: day })) : []}
              onChange={(newValue) => {
                if (newValue) setFilters({ ...filters, day: newValue.value })
              }}
              menuPlacement="auto"
              menuPortalTarget={document.body}
            />
          </div>
          {(filters.facility || filters.day) && (
            <button
              className="text-[14px] text-SeabiscuitDark200ThemeColor/50 underline hover:no-underline"
              onClick={() => setFilters({ facility: '', day: '' })}
            >
              Clear filters
            </button>
          )}
        </div>
        <div className="flex items-center gap-2">
          <div
            className={clsx(
              'text-center rounded-lg py-2.5 px-4 transition-all hover:opacity-70',
              pendingItems > 0
                ? 'text-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor/5'
                : 'text-SeabiscuitGreenThemeColor bg-SeabiscuitGreenThemeColor/10'
            )}
          >
            {pendingItems > 0 ? `${pendingItems} items pending` : ' All scheduled'}
          </div>
          <Tooltip placement="top" arrow title={<h4 className="tooltip_title">Sort by time</h4>}>
            <button
              className="w-11 p-2.5 bg-SeabiscuitGrayThemeColor rounded-lg transition-all hover:opacity-70"
              onClick={onUpdate}
            >
              <img className="w-full" src="/assets/og_icons/Synchronize-1.svg" alt="" />
            </button>
          </Tooltip>
          <Tooltip placement="top" arrow title={<h4 className="tooltip_title">Show preview</h4>}>
            <button
              className="w-11 p-2.5 bg-SeabiscuitGrayThemeColor rounded-lg transition-all hover:opacity-70"
              onClick={handleSchedule}
            >
              <img className="w-full" src="/assets/og_icons/FullScreen-1.svg" alt="" />
            </button>
          </Tooltip>
        </div>
      </div>

      <div id="fieldContainer" className="w-full text-xs wrapper-schedule">
        <EntriesSchedules
          handleModal={handleModal}
          trigger={triggerEntries}
          register={registerEntries}
          setValue={setValueEntries}
          control={controlEntries}
          watch={watchEntries}
          reset={resetEntries}
          errors={errorsEntries}
          locationOptions={locationOptions}
          filters={filters}
        />
        <CustomSchedules
          handleModal={handleModal}
          register={registerSchedule}
          control={controlSchedule}
          watch={watchSchedule}
          trigger={triggerSchedule}
          setValue={setValueSchedule}
          reset={resetSchedule}
          errors={errorsSchedule}
          locationOptions={locationOptions}
          remove={remove}
          filters={filters}
        />
      </div>
      <div className="mt-2 flex gap-4 items-center justify-between">
        <button
          className="text-SeabiscuitDark200ThemeColor/50 flex items-center gap-2 text-sm group"
          onClick={onAddCustomTime}
        >
          <AddCircleOutline className="!w-5" />
          <span className="underline group-hover:no-underline">Add custom time</span>
        </button>

        {watchSchedule('custom')!.length > 0 ? (
          <button
            onClick={() => remove(clearAllArr(watchSchedule('custom')!.length, 0))}
            className="text-SeabiscuitDark200ThemeColor/50 text-sm underline hover:no-underline"
          >
            Clear All
          </button>
        ) : null}
      </div>
    </div>
  )
}

export default SchedulingScreenEventSchedulingFormComponent
