// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useContext, useEffect, useRef, useState } from 'react'

import clsx from 'clsx'
import moment from 'moment'
import DataTable from 'react-data-table-component'

import { QueryConstraint, and, documentId, or, where } from 'firebase/firestore'
import { PillButtonElement } from '../../../components/elements/buttons/pill/PillButtonElement'
import { CONST } from '../../../const/const'
import { getConvertedData } from '../../../models/interface.helper'
import { customResponsiveStyles } from './AllEventsTableStyles'

//css
import './AllEventCss.css'
import './allEventRootPage.css'

import { Link } from 'react-router-dom'
import { EventModel } from '../../../models/events/event.model'

// Constants
import helpers from '../../../commonHelpers/helpers'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { IMAGE_CONSTS } from '../../../const/image-const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { CustomError, capitalize, createString, getUserFullName } from '../../../helpers/helpers'
import { HandleModalContext } from '../../../layout/mainlayout/MainLayout'
import { TEventRegisteredUsers } from '../../../models/event-registered-users/event-registered-users.interface'
import { EventRegisteredUsersModel } from '../../../models/event-registered-users/event-registered-users.model'
import { IEventInterface } from '../../../models/events/event.interface'
import { IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'
import FirestoreService from '../../../services/firestoreService'
import { useAppSelector } from '../../../store/hooks'
import { selectVisitedUserDetailsR } from '../../../store/profilePage/profilePageSlice'
import { ALL_EVENT_LISTING_ROOT_PAGE_DATA as aelrptd } from './AllEventListingRootPage.data'
import { IAllEventListingRootPageTypes as iaelrpt } from './AllEventListingRootPage.types'
import DataNotAvailable from '../../../components/common/alerts/data-not-available/DataNotAvailable'
import { useWindowResize } from '../../../hooks/useWindowResize'

// Types
type Props = {
  handleModal: any
}

// Constants
const PER_PAGE = 10
const CHUNK_SIZE = 10
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const USERS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS

const TabData = [
  { id: 1, title: 'New events' },
  { id: 2, title: 'Past events' },
]

const FILE_NAME = 'AllEventListingRootPage'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const AllEventListingRootPage = (props: Props) => {
  // Hooks and vars
  const [searching] = useState(false)
  const [searchOpen] = useState(false)
  const [eventListToShow] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [competitionResults] = useState<any[]>([])
  const [selectedTab, setSelectedTab] = useState(1)
  const [isPrivateProfile, setIsPrivateProfile] = useState(false)
  const [newEvents, setNewEvents] = useState<iaelrpt['INewEvent'][]>([])
  const [pastEvents, setPastEvents] = useState<iaelrpt['IPastEvent'][]>([])
  const { windowWidth } = useWindowResize()
  const handleModal = useContext(HandleModalContext)?.handleModal ?? null

  const userDetails = useRef<iaelrpt['IUserDetails'] | null>(null)
  const eventsImageHolder = useRef<iaelrpt['IEventsImageHolder']>(new Set())

  const visitedUserDetailsR = useAppSelector(selectVisitedUserDetailsR)

  let isNewEventsTab = selectedTab === 1

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(getWindowWidth())
  //   }
  //
  //   window.addEventListener('resize', handleResize)
  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!visitedUserDetailsR?.profileDetails?.id) return

    setNewEvents([])
    setPastEvents([])
    setLoading(true)
    selectedTabval(TabData[0].id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitedUserDetailsR?.profileDetails.id])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    let profileDetails = visitedUserDetailsR?.profileDetails ?? null
    let userIsMinor = false
    let accountIsPrivate = false

    if (!profileDetails) return

    let ageInYearsFromNow = +moment(profileDetails?.userDOB ?? null)
      .fromNow()
      .split(' ')[0]

    userIsMinor = ageInYearsFromNow < 18
    accountIsPrivate = profileDetails?.userAccountPrivacy

    if (userIsMinor || visitedUserDetailsR?.isMyProfile === true) accountIsPrivate = false

    setIsPrivateProfile(accountIsPrivate)
  }, [visitedUserDetailsR])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const selectedTabval = async (id: number) => {
    if (isPrivateProfile) return

    isNewEventsTab = id === 1

    setSelectedTab(id)
    setLoading(true)

    if (isNewEventsTab && !visitedUserDetailsR?.isOrganizerProfileView) {
      await getRegisteredInEvents(null, false)
    } else if (!isNewEventsTab && !visitedUserDetailsR?.isOrganizerProfileView) {
      await getRegisteredInEvents(null, true)
    } else if (isNewEventsTab && visitedUserDetailsR?.isOrganizerProfileView) {
      await getOrganizerEvents(null, false)
    } else if (!isNewEventsTab && visitedUserDetailsR?.isOrganizerProfileView) {
      await getOrganizerEvents(null, true)
    }
    setLoading(false)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getOrganizerEvents = async (lastEventDocId: string | null, forPasEvents = false) => {
    let events: IEventInterface[] = []
    let eventStartDate: string | null = null
    let registrationCloseDate: string | null = null
    let eventCompanyLogo: string | null = null
    let queryConstraints: any[] = []

    if (visitedUserDetailsR?.isMyProfile && visitedUserDetailsR?.isOrganizerProfileView)
      eventCompanyLogo = visitedUserDetailsR.profileDetails.userProfilePicture ?? ''

    if (forPasEvents)
      queryConstraints = [
        and(
          where(COLLECTIONS.EVENTS.FIELDS.OWNER.KEY, '==', visitedUserDetailsR?.profileDetails?.id),
          or(
            where(
              COLLECTIONS.EVENTS.FIELDS.STATUS.KEY,
              '==',
              COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.PAST
            ),
            where(
              COLLECTIONS.EVENTS.FIELDS.STATUS.KEY,
              '==',
              COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CANCEL
            )
          )
        ),
      ]
    else
      queryConstraints = [
        where(
          COLLECTIONS.EVENTS.FIELDS.STATUS.KEY,
          '==',
          COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CURRENT
        ),
        where(COLLECTIONS.EVENTS.FIELDS.OWNER.KEY, '==', visitedUserDetailsR?.profileDetails?.id),
      ]

    const eventSnapshots = await FirestoreService.filterItems(
      COLLECTIONS.EVENTS.NAME,
      queryConstraints,
      PER_PAGE,
      null,
      null,
      lastEventDocId
    )

    eventSnapshots.forEach((currEvent) => {
      events.push(getConvertedData(EventModel.fromFirestoreDoc(currEvent).toObject()))
    })

    if (eventCompanyLogo === null) events = await addEventLogosToEvent(events)

    const dataToRender = events.map((currEvent) => {
      eventStartDate = (currEvent.eventStartDate ? new Date(currEvent.eventStartDate) : null)
        ? moment(currEvent.eventStartDate).format('MMM DD')
        : 'N/A'

      registrationCloseDate = (
        currEvent.registrationCloseDate ? new Date(currEvent.registrationCloseDate) : null
      )
        ? moment(currEvent.registrationCloseDate).format('MM.DD.YY')
        : 'N/A'

      return {
        eventId: currEvent.id,
        eventName: capitalize(createString(currEvent.eventName)),
        eventAttendees: currEvent.registeredCountIncludingUnpaid ?? 0,
        eventCompanyLogo: eventCompanyLogo
          ? eventCompanyLogo
          : currEvent.eventCardCoverImageUrl ?? '',
        eventStartDate,
        eventDisciplines: currEvent.tags ?? [],
        eventLocation:
          currEvent.state && currEvent.state !== '' && currEvent.state !== 'unknown'
            ? currEvent.state
            : currEvent.country && currEvent.country !== '' && currEvent.country !== 'unknown'
              ? currEvent.country
              : '',
        eventType: currEvent.category ?? 'N/A',
        spotsAvailable: currEvent.registrationAvailableCount ?? 0,
        eventRegistrationCloseDate: registrationCloseDate,
        handleModal,
      }
    })

    setNewEvents((prev) => {
      let events_ = forPasEvents ? [] : dataToRender
      events_ = [...events_].reduce(
        (acc, currEvent) => {
          if (!acc.find((c) => c.eventId === currEvent.eventId)) {
            acc.push(currEvent)
          }
          return acc
        },
        [] as typeof events_
      )
      return events_
    })

    setPastEvents((prev) => {
      let events_ = !forPasEvents ? [] : dataToRender
      events_ = [...events_].reduce(
        (acc, currEvent) => {
          if (!acc.find((c) => c.eventId === currEvent.eventId)) {
            acc.push(currEvent)
          }
          return acc
        },
        [] as typeof events_
      )
      return events_
    })

    return 0
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getEventAgainstRegistrations = async (
    registrations: TEventRegisteredUsers[],
    forPasEvents = false
  ) => {
    let events: IEventInterface[] = []

    try {
      let eventIds_ = registrations
        .map((currRegistration) => {
          return currRegistration.eventId
        })
        .filter((currObjectWithEventId) => {
          return !!currObjectWithEventId
        })

      eventIds_ = [...new Set(eventIds_)]

      let queryConstraints: QueryConstraint[] = []

      if (forPasEvents)
        queryConstraints.push(
          where(
            COLLECTIONS.EVENTS.FIELDS.STATUS.KEY,
            '==',
            COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.PAST
          )
        )
      else
        queryConstraints.push(
          where(
            COLLECTIONS.EVENTS.FIELDS.STATUS.KEY,
            '==',
            COLLECTIONS.EVENTS.FIELDS.STATUS.VALUE.CURRENT
          )
        )

      const eventSnapshots = await FirestoreService.filterItems(COLLECTIONS.EVENTS.NAME, [
        where(documentId(), 'in', eventIds_),
        ...queryConstraints,
      ])

      eventSnapshots.docs.forEach((currDoc) => {
        events.push(getConvertedData(EventModel.fromFirestoreDoc(currDoc).toObject()))
      })
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'getEventAgainstRegistrations',
          devMessage: error?.message ?? error,
        }),
      })
    } finally {
      return events
    }
  }

  // new

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getRegisteredInEvents = async (
    lastRegistrationByDayDocId: string | null,
    forPasEvents = false,
    fetchedRegistrations = [] as TEventRegisteredUsers[],
    fetchedEvents = [] as IEventInterface[],
    hasMore = false,
    perPage = 10
  ) => {
    const LAST_REGISTRATION_ID = lastRegistrationByDayDocId
    const registrations: TEventRegisteredUsers[] = []

    let refetch = true
    let events: IEventInterface[] = []
    let eventStartDate: string | null = null
    let registrationCloseDate: string | null = null

    const registrationSnapshots = await FirestoreService.filterItems(
      COLLECTIONS.EVENT_REGISTERED_USERS.NAME,
      [
        where(
          COLLECTIONS.EVENT_REGISTERED_USERS.FIELDS.USER_ID.KEY,
          '==',
          visitedUserDetailsR?.profileDetails?.id
        ),
      ],
      perPage,
      null,
      null,
      LAST_REGISTRATION_ID
    )

    registrationSnapshots.forEach((currDoc) => {
      registrations.push(
        getConvertedData(EventRegisteredUsersModel.fromFirestoreDoc(currDoc).toObject())
      )
    })

    fetchedRegistrations = [...fetchedRegistrations, ...registrations]

    if (!registrations?.length || registrations?.length < PER_PAGE) {
      hasMore = false
      refetch = false
    } else {
      hasMore = true
    }

    if (registrations?.length) {
      const events_ = await getEventAgainstRegistrations(registrations, forPasEvents)
      events = await addEventLogosToEvent(events_)
      fetchedEvents = [...fetchedEvents, ...events]

      if (events.length < PER_PAGE && refetch !== false) {
        refetch = true
      } else {
        refetch = false
      }
    }

    if (refetch) {
      perPage = PER_PAGE - fetchedEvents.length
      perPage = perPage > PER_PAGE ? PER_PAGE : perPage

      await getRegisteredInEvents(
        fetchedRegistrations.at(-1)?.id ?? null,
        forPasEvents,
        fetchedRegistrations,
        fetchedEvents,
        hasMore,
        perPage
      )
    } else {
      const dataToRender = fetchedEvents.map((currEvent) => {
        eventStartDate = (currEvent.eventStartDate ? new Date(currEvent.eventStartDate) : null)
          ? moment(currEvent.eventStartDate).format('MMM DD')
          : 'N/A'

        registrationCloseDate = (
          currEvent.registrationCloseDate ? new Date(currEvent.registrationCloseDate) : null
        )
          ? moment(currEvent.registrationCloseDate).format('MM.DD.YY')
          : 'N/A'

        return {
          eventId: currEvent.id,
          eventName: capitalize(createString(currEvent.eventName)),
          eventAttendees: currEvent.registeredCountIncludingUnpaid ?? 0,
          eventCompanyLogo: currEvent.eventCardCoverImageUrl ?? '',
          eventStartDate,
          eventDisciplines: currEvent.tags ?? [],
          eventLocation:
            currEvent.state && currEvent.state !== '' && currEvent.state !== 'unknown'
              ? currEvent.state
              : currEvent.country && currEvent.country !== '' && currEvent.country !== 'unknown'
                ? currEvent.country
                : '',
          eventType: currEvent.category ?? 'N/A',
          eventRegistrationCloseDate: registrationCloseDate,
          spotsAvailable: currEvent.registrationAvailableCount ?? 0,
          handleModal,
        }
      })

      setNewEvents((prev) => {
        let events_ = forPasEvents ? [] : dataToRender
        events_ = [...events_].reduce(
          (acc, currEvent) => {
            if (!acc.find((c) => c.eventId === currEvent.eventId)) {
              acc.push(currEvent)
            }
            return acc
          },
          [] as typeof events_
        )
        return events_
      })

      setPastEvents((prev) => {
        let events_ = !forPasEvents ? [] : dataToRender
        events_ = [...events_].reduce(
          (acc, currEvent) => {
            if (!acc.find((c) => c.eventId === currEvent.eventId)) {
              acc.push(currEvent)
            }
            return acc
          },
          [] as typeof events_
        )
        return events_
      })

      return 0
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getEventLogos = async (
    ownerIdsList: string[],
    eventList: IEventInterface[]
  ): Promise<iaelrpt['IEventLogo'][]> => {
    let logos: iaelrpt['IEventLogo'][] = []
    let user: IUserInterface | null = null

    const usersList: IUserInterface[] = []

    if (!ownerIdsList.length) return logos

    // Fetchs users
    const usersSnaps = await FirestoreService.filterItems(USERS.NAME, [
      where(documentId(), 'in', ownerIdsList),
    ])

    usersSnaps.forEach((c) => {
      user = UserModel.fromFirestoreDoc(c).toObject()
      usersList.push(user)
    })

    // Combines
    eventList.forEach((currEvent) => {
      user = usersList.find((cu) => cu.id === currEvent.owner) ?? null
      if (!!!user) return
      logos.push({
        eventId: currEvent.id,
        eventLogo: user?.userProfilePicture ?? null,
      })
    })

    return logos
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const addEventLogosToEvent = async (fetched_events: IEventInterface[]) => {
    let currentIndex = 0
    let ownerIdsList: string[] = []
    let ownerId: string | null = null
    let eventId: string | null = null
    let eventLogo: string | null = null
    let eventsList: IEventInterface[] = []
    let logos: iaelrpt['IEventLogo'][] = []
    let logosSet: Set<iaelrpt['IEventLogo']> = new Set()
    let updatedEventsImageHolder: Set<iaelrpt['IEventLogo']> = new Set()

    let localFetchedEvents = [...fetched_events]

    if (!userDetails.current?.isOranizer) {
      while (currentIndex < fetched_events.length) {
        eventId = fetched_events?.[currentIndex]?.id
        ownerId = fetched_events?.[currentIndex]?.owner ?? null

        if (eventId && ownerId) {
          ownerIdsList.push(ownerId)
          eventsList.push(fetched_events?.[currentIndex])
        }

        if (ownerIdsList.length === CHUNK_SIZE || currentIndex === localFetchedEvents.length - 1) {
          logos = await getEventLogos(ownerIdsList, eventsList)

          // Saving the logos so that i can later be used in pagination etc
          logosSet = new Set(logos)
          updatedEventsImageHolder = new Set([...eventsImageHolder.current, ...logosSet])
          eventsImageHolder.current = updatedEventsImageHolder

          // eslint-disable-next-line no-loop-func
          localFetchedEvents = localFetchedEvents.map((currEvent) => {
            eventLogo =
              logos.find((currLogo) => currLogo.eventId === currEvent.id)?.eventLogo ?? null
            if (!!!eventLogo) eventLogo = IMAGE_CONSTS.PLACEHOLDERS.EVENT
            return {
              ...currEvent,
              eventCardCoverImageUrl: eventLogo,
            }
          })
          ownerIdsList = []
        }
        currentIndex++
      }
    }

    return localFetchedEvents
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const noEventFoundJSX = () => {
    let link: string | null = null
    let message: string | null = null
    let subTextToShow: string | null = null

    if (!visitedUserDetailsR) return null

    switch (true) {
      case visitedUserDetailsR?.isMyProfile === true && visitedUserDetailsR?.isOrganizerProfileView:
        if (isNewEventsTab) {
          message = 'You have no upcoming events'
          subTextToShow = 'Create an event >'
        } else {
          message = 'You have not hosted any events'
          subTextToShow = 'Create an event >'
        }
        link = CONST.ROUTES.ORGANIZER_HOME.URL
        break

      case visitedUserDetailsR?.isMyProfile === true &&
        !visitedUserDetailsR?.isOrganizerProfileView:
        if (isNewEventsTab) {
          message = 'You have not registered for any upcoming events'
          subTextToShow = 'Search events >'
        } else {
          message = 'You have not competed in any competitions'
          subTextToShow = 'Search competitions >'
        }
        link = CONST.ROUTES.HOME.URL
        break

      case visitedUserDetailsR?.isMyProfile === false &&
        visitedUserDetailsR?.isOrganizerProfileView:
        message = `${capitalize(getUserFullName(visitedUserDetailsR?.profileDetails))} have not hosted any events`
        break

      case visitedUserDetailsR?.isMyProfile === false &&
        !visitedUserDetailsR?.isOrganizerProfileView:
        if (isNewEventsTab) {
          message = `${capitalize(getUserFullName(visitedUserDetailsR?.profileDetails))} have not registered for any upcoming events`
        } else {
          message = `${capitalize(getUserFullName(visitedUserDetailsR.profileDetails))} have not competed in any competitions yet`
        }
        break
    }

    return (
      <DataNotAvailable
        mode="graphic"
        imageAlt="house on mountain with round balloon like tree"
        imageSource={'assets/placeholders/NoEventsFoundProfile.svg'}
        imageClassName="!w-[40%]"
        {...(link && {
          bottomTextLink: link,
        })}
        upperText={message ?? ''}
        bottomText={subTextToShow ?? ''}
      />
    )
  }

  return (
    <>
      <div
        className={`${searchOpen ? 'overlayCstm' : 'bg-SeabiscuitWhiteThemeColor'} h-full event-tab pt-6 pb-2 px-6`}
      >
        <div className="max-w-full overflow-y-auto mb-4">
          <ul className="flex w-fit pb-3">
            {TabData.map((item, index) => {
              return (
                <PillButtonElement
                  key={`${JSON.stringify(item)}${index}`}
                  text={item.title}
                  Class="h-[45px] md:w-[200px] border-none text-SeabiscuitDark200ThemeColor"
                  onButtonClicked={() => selectedTabval(item.id)}
                  value={item.id}
                  selected={selectedTab === item.id}
                />
              )
            })}
          </ul>
        </div>

        <div className={clsx('relative', searchOpen && '!z-[1]')}>
          <div
            className={`
                    ${
                      !searchOpen || (!eventListToShow?.length && !searching)
                        ? 'hidden'
                        : 'bg-white top-6 z-[1] pt-8 w-full left-0 rounded-b-lg absolute'
                    }
                     `}
          >
            <ul>
              {!eventListToShow?.length && searching ? (
                <div className="text-gray-400 text-sm p-4">No result</div>
              ) : null}
              {eventListToShow?.map((event: any) => {
                return (
                  <li className="flex items-center cursor-pointer p-2 hover:bg-[#F2F3F8]">
                    <Link
                      className="flex items-center"
                      to={`${CONST.ROUTES.EVENT_DETAILS.URL}/${event.id}`}
                    >
                      <img src={event.image} alt="event" />
                      <p className="ml-4 text-[14px] text-SeabiscuitDark200ThemeColor font-bold capitalize">
                        {event.title}
                      </p>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        {isPrivateProfile ? (
          <div className="flex w-full h-[300px] items-center justify-center flex-col">
            <span className="w-fit">This account is Private</span>
            <img
              src="/assets/modals/privatetab.svg"
              alt="privatetab"
              className="h-[140px] mt-3 mb-3"
            />
            <span className="text-SeabiscuitMainThemeColor w-fit">
              Connect With this member to view their profile
            </span>
          </div>
        ) : (
          <>
            {selectedTab === 1 ? (
              <>
                {loading || newEvents?.length ? (
                  <div className="allEventTabs mt-8 full overflow-auto w-[98%]">
                    <DataTable
                      data={newEvents}
                      progressPending={loading}
                      progressComponent={
                        <div className="flex justify-center items-center min-h-[200px]">
                          <ViewsLoader size="xl" color="red" />
                        </div>
                      }
                      customStyles={customResponsiveStyles(windowWidth)}
                      columns={
                        visitedUserDetailsR?.isOrganizerProfileView
                          ? aelrptd.COLS.ORGANIZER_NEW_EVENT_COLS
                          : aelrptd.COLS.NEW_EVENT_COLS
                      }
                      className="h-full !overflow-y-auto pr-2"
                    />
                  </div>
                ) : (
                  <div className="mx-5 px-0 md text-sm pt-28 flex items-center">
                    {noEventFoundJSX()}
                  </div>
                )}
              </>
            ) : null}

            {selectedTab === 3 ? (
              <>
                {loading || competitionResults?.length ? (
                  <div className="allEventTabs mt-8 full overflow-auto w-[98%]">
                    <DataTable
                      data={pastEvents}
                      className="h-full"
                      progressPending={loading}
                      progressComponent={
                        <div className="flex justify-center items-center min-h-[200px]">
                          <ViewsLoader size="xl" color="red" />
                        </div>
                      }
                      customStyles={customResponsiveStyles(windowWidth)}
                      columns={aelrptd.COLS.GET_EVENT_RESULT_COLS_FN(props)}
                    />
                  </div>
                ) : (
                  <div className="mx-5 px-0 md mt-3 text-sm">{noEventFoundJSX()}</div>
                )}
              </>
            ) : null}

            {selectedTab === 2 ? (
              <>
                {loading || pastEvents?.length ? (
                  <div className="allEventTabs mt-8 full overflow-auto w-[98%]">
                    <DataTable
                      data={pastEvents}
                      className="h-full"
                      progressPending={loading}
                      progressComponent={
                        <div className="flex justify-center items-center min-h-[200px]">
                          <ViewsLoader size="xl" color="red" />
                        </div>
                      }
                      customStyles={customResponsiveStyles(windowWidth)}
                      columns={
                        visitedUserDetailsR?.isOrganizerProfileView
                          ? aelrptd.COLS.ORGANIZER_PAST_EVENT_COLS
                          : aelrptd.COLS.PAST_EVENT_COLS
                      }
                    />
                  </div>
                ) : (
                  <div className="mx-5 px-0 md pt-28 text-sm flex items-center">
                    {noEventFoundJSX()}
                  </div>
                )}
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  )
}

export default AllEventListingRootPage
