// Third party
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { Tooltip } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { CONST } from '../../../../../../const/const'
import TooltipIcon from '../../../../../../helpers/TooltipIcon'
import { Close } from '../arrows/Close'
import { Open } from '../arrows/Open'

// Constants
const ADD_ICON_CLASS = 'add_icon'
const REMOVE_ICON_CLASS = 'remove_icon'
const MAIN_DIV_CLASS = 'parent_div'

// Types
type IProps = {
  title:
    | 'Tickets'
    | 'Registrations'
    | 'Fees'
    | 'Mandatory Fees'
    | 'Grand total'
    | 'Paperwork'
    | 'Summary'
    | 'Grand Total'
  tooltipText?: string
  id?: string
  selected_units?: string
  children: ReactJSXElement
  showList: boolean
  accordion?: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }
  cols: any[]
  hideToggleIcon?: boolean
  emptyListMessage?: string
  grandTotal?: number
  splitPayment?: boolean
  colsClassName?: {
    [zeroBasedColNo: number]: string
  } | null
  activeTab?: {
    tab: string
    step: number
  }
  disableEditBtn?: boolean
  setEventTab?: any
  redirectTab?: string
  toggleAccordion?: string
}

const ConfirmationCompsWrapper = (props: IProps) => {
  // Hooks and vars
  const colsClassName = props?.colsClassName
  const NO_CLASSES_SELECTED = `No ${props.title} added to this event`
  const accordion = props?.accordion ?? { open: false, setOpen: () => null }

  // Functions

  // Opens/Closes the next sibbling div
  const openNextSibbling = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let target = e.target as HTMLElement | null

    // If the target is a path or SVG, find the SVG to handle the click properly.
    if (target?.tagName.toLowerCase() === 'path' || target?.tagName.toLowerCase() === 'svg') {
      target = (target.closest('svg') as HTMLElement | null) ?? target
    }

    // Check if any of the target's parent elements has one of the specified classes.
    if (
      target &&
      (target.classList.contains(MAIN_DIV_CLASS) ||
        target.closest(`.${ADD_ICON_CLASS}`) ||
        target.closest(`.${REMOVE_ICON_CLASS}`))
    ) {
      accordion.setOpen(!accordion.open)
    }
  }

  // Navigates to the tab
  const navigate = () => {
    switch (props.redirectTab) {
      case CONST.UI.REGISTER.TABS.REGISTER:
        return props.setEventTab({
          tab: CONST.UI.REGISTER.TABS.REGISTER,
          step: 0,
        })
      case CONST.UI.REGISTER.TABS.FEES:
        return props.setEventTab({
          tab: CONST.UI.REGISTER.TABS.FEES,
          step: 0,
        })

      case CONST.UI.REGISTER.TABS.PAPERWORK:
        return props.setEventTab({
          tab: CONST.UI.REGISTER.TABS.PAPERWORK,
          step: 0,
        })

      case CONST.UI.REGISTER.TABS.TICKETS:
        return props.setEventTab({
          tab: CONST.UI.REGISTER.TABS.FEES,
          step: 1,
        })
    }
  }

  return (
    <div
      className={`mt-2 border-[#E6EAF2] border px-[15px] py-2 md:py-0 rounded-lg`}
      onClick={openNextSibbling}
    >
      <header
        className={`text-SeabiscuitDark200ThemeColor flex flex-wrap w-full justify-between h-[56px] items-center font-semibold cursor-pointer ${MAIN_DIV_CLASS}`}
      >
        <div className="flex items-center">
          <span className={`${!accordion?.open ? 'font-normal' : 'font-bold'}`}>{props.title}</span>

          {props.activeTab && props.activeTab.tab !== 'Confirmation' && props.tooltipText ? (
            <Tooltip
              title={<h1 className="tooltip_title">{props.tooltipText}</h1>}
              placement="top"
              arrow
            >
              <button>
                <TooltipIcon color="#122B46" />
              </button>
            </Tooltip>
          ) : null}
        </div>

        {!props.toggleAccordion || props.toggleAccordion !== '0' ? (
          <div className="flex items-center text-nr justify-between  w-full md:w-auto">
            {!props.disableEditBtn ? (
              <>
                <span
                  className="font-normal cursor-pointer text-SeabiscuitLightParagraphTextColor rounded-full py-0.5 px-4 pr-0 transition-all hover:opacity-70"
                  onClick={navigate}
                >
                  Edit
                </span>
                {props?.selected_units && (
                  <span className="font-bold cursor-auto text-SeabiscuitGreenThemeColor bg-SeabiscuitGreenThemeColor/10 rounded-full ml-4 py-0.5 px-4">
                    {props?.selected_units}
                  </span>
                )}
              </>
            ) : (
              <span className="font-bold cursor-auto text-SeabiscuitGreenThemeColor bg-SeabiscuitGreenThemeColor/10 rounded-full ml-4 py-0.5 px-4">
                ${props.grandTotal}
              </span>
            )}
            {props?.hideToggleIcon !== true ? (
              <div
                className={`status ${!accordion?.open ? 'text-SeabiscuitDark200ThemeColor' : 'text-SeabiscuitMainThemeColor'} rounded-full pl-4 icons_holder`}
              >
                {!accordion?.open ? (
                  <div className={ADD_ICON_CLASS}>
                    <Open />
                  </div>
                ) : (
                  <div className={REMOVE_ICON_CLASS}>
                    <Close />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </header>

      <section className={`faqs-content ${accordion.open ? 'activeTab' : ''}`}>
        <div className="faqs-content-inside my-4 transition-all" id={props?.id}>
          {props.showList ? (
            <>
              <div className="hidden lg:flex">
                {props.cols.map((currCol, index) => {
                  return (
                    <div
                      key={currCol + index.toString()}
                      className={`registration_tabs_column_title w-1/4 pl-1 pb-1mr-2 rounded-md capitalize flex items-center ${colsClassName?.[index] ? ` ${colsClassName[index]}` : ''}`}
                    >
                      {currCol.title}
                      {currCol.tooltipText !== '' ? (
                        <Tooltip
                          title={<h1 className="tooltip_title">{currCol.tooltipText}</h1>}
                          placement="top"
                          arrow
                        >
                          <button>
                            <TooltipIcon color="#122B46" />
                            {/* <img className="w-3 h-3 ml-1" src="/assets/cp_icons/Help.svg" alt="icon" /> */}
                          </button>
                        </Tooltip>
                      ) : null}
                    </div>
                  )
                })}
              </div>

              <div className="text-nr">{props.children}</div>
            </>
          ) : (
            <div className="text-sm text-SeabiscuitDark200ThemeColor flex items-center justify-center">
              {NO_CLASSES_SELECTED}
            </div>
          )}
        </div>
      </section>
    </div>
  )
}

export default ConfirmationCompsWrapper
