// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import * as React from 'react'
import EventScoringFormBuilderUtilDivisionRowAccordionComponent from './EventScoringFormBuilderUtilDivisionRowAccordionComponent'
import EventScoringFormBuilderUtilDivisionRowAccordionSummaryComponent from './EventScoringFormBuilderUtilDivisionRowAccordionSummaryComponent'
import EventScoringFormBuilderUtilDivisionRowAccordionDetailsComponent from './EventScoringFormBuilderUtilDivisionRowAccordionDetailsComponent'
import EventScoringFormBuilderUtilSectionRow from '../section/EventScoringFormBuilderUtilSectionRow'
import { forwardRef, ReactNode, useCallback, useImperativeHandle, useState } from 'react'

interface EventPriceListFormBuilderUtilDivisionRowProps {
  disciplineIndex: number
  division: any
  divisionIndex: number
  register: any
  children?: ReactNode
  errors: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventScoringFormBuilderUtilDivisionRow: React.FC<EventPriceListFormBuilderUtilDivisionRowProps> =
  forwardRef<any, EventPriceListFormBuilderUtilDivisionRowProps>((props, ref) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const handleOnAccordionExpansionToggle = useCallback(
      (_: any) => {
        setIsExpanded((prevState) => !prevState)
      },
      [setIsExpanded]
    )

    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    /**
     * @todo Document this
     */
    const expandDivision = () => {
      setIsExpanded(true)
    }

    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    /**
     * @todo Document this
     */
    const collapseDivision = () => {
      setIsExpanded(false)
    }

    // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    /**
     * @todo Document this
     */
    useImperativeHandle(
      ref,
      () => ({ expandDivision, collapseDivision }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isExpanded, setIsExpanded]
    )

    return (
      <EventScoringFormBuilderUtilDivisionRowAccordionComponent
        onChange={handleOnAccordionExpansionToggle}
      >
        <EventScoringFormBuilderUtilDivisionRowAccordionSummaryComponent isExpanded={isExpanded}>
          <div className="flex -ml-5">
            <label className="text-[16px] font-black mr-4 text-SeabiscuitDark200ThemeColor w-28">
              {props.division.divisionCode}
            </label>
            <label className="text-[16px] font-black mr-4 text-SeabiscuitDark200ThemeColor w-full">
              {props.division.divisionTitle}
            </label>
          </div>
        </EventScoringFormBuilderUtilDivisionRowAccordionSummaryComponent>
        <EventScoringFormBuilderUtilDivisionRowAccordionDetailsComponent>
          {props.division.sections.map((section: any, index: number) => (
            <EventScoringFormBuilderUtilSectionRow
              key={section.sectionTitle}
              section={section}
              register={props.register}
              disciplineIndex={props.disciplineIndex}
              divisionIndex={props.divisionIndex}
              sectionIndex={index}
              errors={props.errors}
            />
          ))}
        </EventScoringFormBuilderUtilDivisionRowAccordionDetailsComponent>
      </EventScoringFormBuilderUtilDivisionRowAccordionComponent>
    )
  })

export default EventScoringFormBuilderUtilDivisionRow
