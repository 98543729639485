import { where } from 'firebase/firestore'
import { useEffect, useRef } from 'react'
import helpers from '../../../../commonHelpers/helpers'
import { CONST } from '../../../../const/const'
import { IHorseTeamInterface } from '../../../../models/horse-team/horseTeam.interface'
import { HorseTeamModel } from '../../../../models/horse-team/horseTeam.model'
import { getConvertedData } from '../../../../models/interface.helper'
import { IUserHorseMappingInterface } from '../../../../models/user-horse-mapping/userHorseMapping.interface'
import { UserHorseMappingModel } from '../../../../models/user-horse-mapping/userHorseMapping.model'
import FirestoreService from '../../../../services/firestoreService'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import {
  removeHorse,
  selectHorses,
  selectMappings,
  setHorseLoaded,
  setHorses,
  setMappings,
} from '../../../../store/horses/horseSlice'
import { cloneDeep } from 'lodash'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { IHorseData } from '../../../../models/horse/horse.interface'
import { HorseModel } from '../../../../models/horse/horse.model'

// Types
type IHorsesTeamMembers = {
  [horseId: string]: IHorseTeamInterface[]
}

type IRemoveHorseFromDbFnArgs = {
  forceRemove?: boolean
  dontShowToast?: boolean
  mappingDocId: IUserHorseMappingInterface['id']
}

type IRemoveHorseTeamMemberFnArgs = {
  teamMemberDocId: IHorseTeamInterface['id']
  mappingDocId: IUserHorseMappingInterface['id']
  dontShowToast?: boolean
}

type IRemoveHorseTeamMembersByIdArgs = {
  teamMemberDocIds: IHorseTeamInterface['id'][]
  mappingDocId: IUserHorseMappingInterface['id']
}

// Constants
const HORSES_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES
const HORSES_TEAM_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSE_TEAM
const HORSES_MAPPING_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_HORSE_MAPPING

const useHorses = (userId?: string | null) => {
  // Hooks and vars
  const dispatch = useAppDispatch()

  const mappings = useAppSelector(selectMappings)
  const horsesInRedux = useAppSelector(selectHorses)

  const toastFunction = useToasterHelper()

  const mappingRef = useRef(mappings)

  useEffect(() => {
    mappingRef.current = mappings
  }, [mappings])

  useEffect(() => {
    if (!userId) return

    async function loadHorsesFromDb() {
      if (!userId) return 0

      const mapping = await getMapping(userId)
      const horsesTeams = await getHorseTeams()
      const myHorses = await getMyHorses(mapping, horsesTeams)

      dispatch(setMappings(myHorses))
      dispatch(setHorseLoaded(true))
    }

    if (userId) loadHorsesFromDb().then()
  }, [userId])

  const updateHorses = async () => {
    const horses = await getHorseAsPerMapping(mappings.data, horsesInRedux)
    const uniqHorses: IHorseData[] = []
    for (const horse of horses) {
      const isExist = horsesInRedux.find((h) => h.id === horse.id)
      if (!isExist?.id) uniqHorses.push(horse)
    }

    dispatch(setHorses(uniqHorses))
  }

  useEffect(() => {
    if (mappings.data.length) updateHorses().then()
  }, [mappings])

  // Functions
  const getMergedHorsesTeams = (horsesTeams: IHorseTeamInterface[]) => {
    let horsesTeams_ = [...horsesTeams]
    let horseId: string | null = null
    let combinedHorsesTeamMembers: IHorsesTeamMembers = {}

    combinedHorsesTeamMembers = horsesTeams_.reduce((acc, currHorseTeam) => {
      horseId = currHorseTeam.horseId

      if (!horseId) return acc

      acc[horseId] = [...(acc[horseId] ?? []), currHorseTeam]
      return acc
    }, {} as IHorsesTeamMembers)

    return combinedHorsesTeamMembers
  }

  async function getHorseTeams() {
    const horsesTeams: IHorseTeamInterface[] = []

    try {
      const horsesTeamSnaps = await FirestoreService.filterItems(HORSES_TEAM_COLLECTION.NAME, [
        where(HORSES_TEAM_COLLECTION.FIELDS.USER_ID.KEY, '==', userId),
      ])

      horsesTeamSnaps.forEach((currDoc) => {
        horsesTeams.push(HorseTeamModel.fromFirestoreDoc(currDoc).toObject())
      })
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      return horsesTeams
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Returns a list of mapping which is docs, where the userId is same as the `userId` param passed
   */
  async function getMapping(userId: string): Promise<IUserHorseMappingInterface[]> {
    let mapping: IUserHorseMappingInterface[] = []

    try {
      let fetchMappingSnaps = await FirestoreService.filterItems(HORSES_MAPPING_COLLECTION.NAME, [
        where(HORSES_MAPPING_COLLECTION.FIELDS.USER_ID.KEY, '==', userId),
        where(
          HORSES_MAPPING_COLLECTION.FIELDS.HORSE_SELECTED_FOR_COMPETETION.KEY,
          '==',
          HORSES_MAPPING_COLLECTION.FIELDS.HORSE_SELECTED_FOR_COMPETETION.VALUES.YES
        ),
      ])

      fetchMappingSnaps.forEach((currDoc) => {
        mapping.push(UserHorseMappingModel.fromFirestoreDoc(currDoc).toObject())
      })
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      return mapping
    }
  }

  const updateHorseTeamMember = async (args: { teamData: IHorseTeamInterface }) => {
    const { teamData } = args
    try {
      if (!teamData)
        throw new Error(`${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`, {
          cause: `data is empty`,
        })

      await FirestoreService.updateItem(HORSES_TEAM_COLLECTION.NAME, teamData.id, teamData)
    } catch (error) {
      toastFunction.success({
        message: `${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`,
      })

      helpers.logger({
        message: error,
      })
    }
  }

  async function getMyHorses(
    mapping: IUserHorseMappingInterface[],
    horsesTeams: IHorseTeamInterface[]
  ): Promise<IUserHorseMappingInterface[]> {
    let horses: IUserHorseMappingInterface[] = []
    let horsesTeams_ = [...horsesTeams]

    mapping.forEach((currMapping) => {
      horses.push({
        ...currMapping,
        teamMembers: [],
      })
    })

    const mergedHorsesTeams = getMergedHorsesTeams(horsesTeams_)

    horses = horses.map((currHorse) => {
      return getConvertedData({
        ...currHorse,
        teamMembers: currHorse.horseId ? mergedHorsesTeams?.[currHorse.horseId] ?? [] : [],
      })
    })

    return horses
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const removeHorseTeamMember = async (args: IRemoveHorseTeamMemberFnArgs) => {
    const { mappingDocId, teamMemberDocId, dontShowToast } = args

    let mappings_ = cloneDeep([...mappings.data])
    let mappingIndexInRedux: number = -1
    let updatedTeamMembers: IHorseTeamInterface[]

    mappingIndexInRedux = mappings_.findIndex((mapping_) => mapping_.id === mappingDocId)
    updatedTeamMembers = cloneDeep(mappings_[mappingIndexInRedux].teamMembers) ?? []

    try {
      if (!mappings_[mappingIndexInRedux])
        throw new Error(`${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`, {
          cause: `mappings_[${mappingIndexInRedux}] is empty`,
        })

      if (!teamMemberDocId)
        throw new Error(`${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`, {
          cause: `teamMemberDocId is empty`,
        })

      await FirestoreService.deleteItem(HORSES_TEAM_COLLECTION.NAME, teamMemberDocId)

      updatedTeamMembers = updatedTeamMembers?.filter(
        (teamMember) => teamMember.id !== teamMemberDocId
      )
      mappings_[mappingIndexInRedux].teamMembers = updatedTeamMembers ?? []

      if (!dontShowToast)
        toastFunction.success({
          message: MESSAGES_CONST.REMOVED_HORSE_TEAM_MEMBER,
        })
    } catch (error) {
      toastFunction.success({
        message: `${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`,
      })

      helpers.logger({
        message: error,
      })
    } finally {
      dispatch(setMappings(mappings_))
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const removeHorseTeamMembersById = async (args: IRemoveHorseTeamMembersByIdArgs) => {
    const { mappingDocId, teamMemberDocIds } = args

    let isError = false
    let idsOfMembersRemoved: string[] = []
    let currMemberId: string | null = null

    try {
      let currIndex = 0

      while (currIndex < teamMemberDocIds.length) {
        await removeHorseTeamMember({
          mappingDocId,
          teamMemberDocId: teamMemberDocIds?.[currIndex],
          dontShowToast: true,
        })

        if (typeof teamMemberDocIds?.[currIndex] === 'string') {
          currMemberId = teamMemberDocIds?.[currIndex] as any
          if (currMemberId) idsOfMembersRemoved.push(currMemberId)
        }

        currIndex++
      }
    } catch (error) {
      isError = true
      helpers.logger({
        message: error,
      })
    } finally {
      return {
        isError,
        idsOfMembersRemoved,
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const removeHorseFromDb = async (args: IRemoveHorseFromDbFnArgs) => {
    const { mappingDocId, forceRemove = false, dontShowToast = false } = args

    let removed = false
    let mappings_ = cloneDeep([...mappingRef.current.data])
    let horseToRemove: IUserHorseMappingInterface | null =
      mappings_.find((currMapping) => currMapping.id === mappingDocId) ?? null
    let horseId = horseToRemove?.horseId
    let teamMembers = horseToRemove?.teamMembers ?? []

    try {
      if (!horseToRemove)
        throw new Error(`${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`, {
          cause: `mappings_[horseIndex] is empty`,
        })

      if (!mappingDocId)
        throw new Error(`${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`, {
          cause: `mappingDocId is empty`,
        })

      if (teamMembers.length && forceRemove === false)
        throw new Error(MESSAGES_CONST.REMOVE_TEAM_MEMBERS)

      if (forceRemove && teamMembers.length)
        await removeHorseTeamMembersById({
          mappingDocId,
          teamMemberDocIds: teamMembers.map((currMember) => currMember.id),
        })

      await FirestoreService.deleteItem(HORSES_MAPPING_COLLECTION.NAME, mappingDocId)

      mappings_ = mappings_?.filter((mapping) => mapping.id !== mappingDocId)

      if (!dontShowToast)
        toastFunction.success({
          message: MESSAGES_CONST.HORSE_REMOVED,
        })

      removed = true
    } catch (error: any) {
      toastFunction.error({
        message: error?.message ?? `${MESSAGES_CONST.SOMETHING_WENT_WRONG} useHorses.tsx`,
      })

      helpers.logger({
        message: error?.cause ?? error,
      })

      removed = false
    } finally {
      if (horseId)
        dispatch(
          removeHorse({
            horseId,
          })
        )

      dispatch(setMappings(mappings_))
      return removed
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async function getHorseAsPerMapping(
    mapping: IUserHorseMappingInterface[],
    horsesInRedux: IHorseData[]
  ): Promise<IHorseData[]> {
    let horseIds: string[] = []
    let horses: IHorseData[] = []
    let prefetchedHorses: IHorseData[] = []
    let prefetchedHorse: null | IHorseData = null

    mapping.forEach((currMapping) => {
      if (currMapping.horseId) {
        prefetchedHorse =
          horsesInRedux.find((currHorseInRedux) => currHorseInRedux.id === currMapping.horseId) ??
          null
        if (prefetchedHorse === null) horseIds.push(currMapping.horseId)
        else prefetchedHorses.push(prefetchedHorse)
      }
    })

    horseIds = [...new Set(horseIds)]

    if (!horseIds.length) return [...horses, ...prefetchedHorses]
    try {
      let fetchedHorseSnaps = await FirestoreService.getItemsUsingIds(
        HORSES_COLLECTION.NAME,
        horseIds
      )

      fetchedHorseSnaps.forEach((currDoc) => {
        horses.push(getConvertedData(HorseModel.fromFirestoreDoc(currDoc).toObject()))
      })
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      return [...horses, ...prefetchedHorses]
    }
  }

  return {
    mappings,
    horsesInRedux,
    removeHorseFromDb,
    removeHorseTeamMember,
    removeHorseTeamMembersById,
    updateHorseTeamMember,
    getMapping,
  }
}

export default useHorses
