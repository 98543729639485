export const DATA_FIRESTORE_V01_EVENT_DRAFT_PAPERWORK = {
  NAME: 'v01_eventDraftPaperwork',

  FIELDS: {
    PAPERWORK_NAME: 'paperworkName',

    DRAFT_ID: 'draftId',

    ID: 'id',

    SIGNATORY_DETAILS: 'signatory_details',

    EVENT_ID: 'eventId',
  },
}
