export const earnCommissionDTTabs = [
  {
    tab: 1,
    tabTitle: 'Account Details',
    subTitle: 'Share and save your details to earn commissions from new users',
  },
  {
    tab: 2,
    tabTitle: 'Activity',
    subTitle: 'Events you are wait listed for',
  },
]
