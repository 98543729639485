// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useEffect, useState } from 'react'
import EventFormContainerComponent from '../container/EventFormContainerComponent'
import './EventStaffFormComponent.css'

import { yupResolver } from '@hookform/resolvers/yup'
import { AddCircleOutline } from '@mui/icons-material'
import clsx from 'clsx'
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore'
import { cloneDeep } from 'lodash'
import { useFieldArray, useForm } from 'react-hook-form'
import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { EventStaffModel } from '../../../../models/event-staff/event-staff.model'
import { getConvertedData } from '../../../../models/interface.helper'
import { IUserInterface } from '../../../../models/users/user.interface'
import { UserModel } from '../../../../models/users/user.model'
import FirestoreService from '../../../../services/firestoreService'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'

// import MessageHelperComp from "../../../../helpers/MessageHelper";

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const eventStaffFormDefaultValues = new EventStaffModel().toObject()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const eventStaffFormValidationSchema = EventStaffModel.validationSchema()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventStaffFormComponentProps {
  data: any
  onValid: any
  onInvalid: any
  onValidAnExit: any
  onInvalidAndExit: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn?: any
  nextEventTab?: string
  onSetNextEventTab: any
  eventTab?: string
}

type IOtherStaffItem = {
  title: any
  value: IUserInterface[]
}

type IOtherStaffList = IOtherStaffItem[]

type IStaffItem = {
  id: number
  title: string
  placeholder: string
  value: IUserInterface[]
}

type IOtherStaffItemToSave = {
  title: any
  value: string[]
}

type IStaffItemToSave = {
  id: number
  title: string
  value: string[]
  placeholder: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 *
 */
// const InputContainer: React.FC<{ className?: string; children: any }> = ({
//     children,
//     className,
// }) => (
//     <div className={`flex-1 min-w-[150px] ${className ? className : ""}`}>
//         {children}
//     </div>
// );

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
// const InputLabel: React.FC<{ title: string }> = ({ title }) => (
//     <label
//         htmlFor="liscenseeName"
//         className="text-xs font-semibold text-SeabiscuitDark200ThemeColor"
//     >
//         {title}
//     </label>
// );

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventStaffFormComponent: React.FC<EventStaffFormComponentProps> = (props) => {
  const [currentDataEdit, setCurrentDataEdit] = useState<string | number>(-1)
  const [selectedStaffType, setSelectedStaffType] = useState<string>('')
  const [otherStaffEditedElemIndex, setOtherStaffEditedElemIndex] = useState(-1)
  // const [otherStaffMembers, setOtherStaffMembers] = useState<IOtherStaffList>([])
  const [editEventStaffData, setEditEventStaffData] = useState<IStaffItem[]>([
    {
      id: 1,
      title: 'Show manager',
      placeholder: 'Show manager',
      value: [],
    },
    {
      id: 2,
      title: 'Secretary',
      placeholder: 'Secretary',
      value: [],
    },
    {
      id: 3,
      title: 'Technical Delegate',
      placeholder: 'Technical Delegate',
      value: [],
    },
    {
      id: 4,
      title: 'Course Designers',
      placeholder: 'Course Designers',
      value: [],
    },
    {
      id: 5,
      title: 'Judges',
      placeholder: 'Judges',
      value: [],
    },
    {
      id: 6,
      title: 'Scorers',
      placeholder: 'Scorers',
      value: [],
    },
    {
      id: 7,
      title: 'Stewards',
      placeholder: 'Stewards',
      value: [],
    },
    {
      id: 8,
      title: 'Ingates',
      placeholder: 'Ingates',
      value: [],
    },
    {
      id: 9,
      title: 'Announcers',
      placeholder: 'Announcers',
      value: [],
    },
    {
      id: 10,
      title: 'Farriers',
      placeholder: 'Farriers',
      value: [],
    },
    {
      id: 11,
      title: 'Veterinarians',
      placeholder: 'Veterinarians',
      value: [],
    },
    {
      id: 12,
      title: 'Volunteers',
      placeholder: 'Volunteers',
      value: [],
    },
    {
      id: 13,
      title: 'Press',
      placeholder: 'Press',
      value: [],
    },
  ])

  const {
    reset,
    trigger,
    control,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...eventStaffFormDefaultValues },
    resolver: yupResolver(eventStaffFormValidationSchema),
    mode: 'onChange',
  })

  const otherStaff = useFieldArray({
    control,
    name: 'otherStaff',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const handleOpenModal = (id: number | string, index: number, stafftype: string) => {
    setSelectedStaffType(stafftype)
    setCurrentDataEdit(id)
    if (stafftype === 'other') {
      props.handleModal(true, MODAL_CONSTS.ORGNAIZER_EVENT_STAFF_ADD, {
        id,
        row: otherStaff.fields?.[index]?.value,
        onSaveStaff: (data: any) => {
          const currFormValues = watch()
          console.log('=>(EventStaffFormComponent.tsx:230) 1312312', 1312312)
          if (currFormValues && currFormValues.otherStaff && currFormValues.otherStaff[index]) {
            otherStaff.update(index, {
              ...currFormValues.otherStaff[index],
              value: data.selection,
            })
          }

          props.handleModal(false, MODAL_CONSTS.ORGNAIZER_EVENT_STAFF_ADD)
        },
      })
    }

    if (stafftype === 'eventstaff') {
      props.handleModal(true, MODAL_CONSTS.ORGNAIZER_EVENT_STAFF_ADD, {
        id,
        row: editEventStaffData?.[index]?.value,
        onSaveStaff: (data: any) => {
          console.log('=>(EventStaffFormComponent.tsx:248) 1231231', 1231231)
          // const currFormValues = watch()
          const newEditEventStaffData = [...editEventStaffData]
          newEditEventStaffData[index].value = data.selection

          setEditEventStaffData(newEditEventStaffData)

          props.handleModal(false, MODAL_CONSTS.ORGNAIZER_EVENT_STAFF_ADD)
        },
      })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    const temp = [...editEventStaffData]
    if (selectedStaffType === 'eventstaff' && Array.isArray(props.dataToPassOn.selection)) {
      const dataCameFromModal = props.dataToPassOn.selection
      const previousData = temp.filter((item) => item.id === currentDataEdit)

      if (previousData.length) {
        const mutated = editEventStaffData.map((data) => {
          if (data.id === currentDataEdit) {
            let updatedValuesArray = [...dataCameFromModal, ...previousData[0].value]

            updatedValuesArray = updatedValuesArray.filter((currValue) => {
              return (
                updatedValuesArray.filter((currValue_) => {
                  return currValue_.id === currValue.id
                }).length < 2
              )
            })

            const temp = { ...data, value: updatedValuesArray }
            return temp
          } else {
            return data
          }
        })
        setEditEventStaffData(mutated)
      }
    } else if (selectedStaffType === 'other' && Array.isArray(props.dataToPassOn.selection)) {
      const temp = [...otherStaff.fields]
      const dataCameFromModal = props.dataToPassOn.selection
      const previousData = temp.filter((item, index) => index === Number(otherStaffEditedElemIndex))
      const mutated = otherStaff.fields.length
        ? otherStaff.fields.map((data, index) => {
            if (index === Number(otherStaffEditedElemIndex)) {
              let updatedValuesArray = [...dataCameFromModal, ...previousData[0].value]

              updatedValuesArray = updatedValuesArray.filter((currValue) => {
                return (
                  updatedValuesArray.filter((currValue_) => {
                    return currValue_.id === currValue.id
                  }).length < 2
                )
              })

              const temp = { ...data, value: updatedValuesArray }
              return temp
            } else {
              return data
            }
          })
        : props.dataToPassOn.selection.map((data: any) => {
            return { title: 'other', value: [data] }
          })

      // setOtherStaffMembers(mutated)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataToPassOn.selection])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    async function setStaffsData() {
      if (props.data) {
        let userIdsList: string[] = []
        let filtered: IStaffItem[] = []
        let usersList: IUserInterface[] = []
        let currUser: IUserInterface | null = null
        let currOtherStaffItem: IOtherStaffItem | null
        let usersSnaps: QueryDocumentSnapshot<DocumentData>[] = []

        const savedData: IStaffItemToSave[] = props?.data?.eventStaff ?? []
        const savedOtherStaffs: IOtherStaffItemToSave[] = props?.data?.otherStaff ?? []

        // reset(props.data)

        let tempStaffList = [...editEventStaffData]
        let tempOtherStaffList: IOtherStaffItem[] = []

        // Gathering user ids
        savedData.forEach((curr) => {
          curr.value.forEach((currValue) => {
            if (typeof currValue === 'string' && !!currValue) userIdsList.push(currValue)
          })
        })

        savedOtherStaffs?.forEach((curr) => {
          curr.value.forEach((currValue) => {
            if (typeof currValue === 'string' && !!currValue) userIdsList.push(currValue)
          })
        })

        // Fetching ther users and replacing them with their ids
        if (userIdsList.length) {
          usersList = [...new Set(usersList)]
          usersSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, userIdsList)
          usersSnaps.forEach((currSnap) =>
            usersList.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
          )

          savedData.forEach((item1: any, index: number) => {
            filtered = tempStaffList.filter((item) => item.id === item1.id)
            if (filtered.length) {
              tempStaffList.filter((item) => item.id === item1.id)[0].value = savedData[
                index
              ].value.reduce((acc: IUserInterface[], currId) => {
                currUser = null
                if (typeof currId === 'string')
                  currUser = usersList.find((currUser_) => currUser_.id === currId) ?? null
                if (currUser) acc.push(currUser)
                return acc
              }, [])
            }
          })

          tempOtherStaffList = savedOtherStaffs.map((curr, index) => {
            currOtherStaffItem = {
              title: curr?.title ?? '',
              value: curr.value.reduce((acc: IUserInterface[], currId) => {
                currUser = null
                if (typeof currId === 'string')
                  currUser = usersList.find((currUser_) => currUser_.id === currId) ?? null
                if (currUser) acc.push(currUser)
                return acc
              }, []),
            }
            return currOtherStaffItem
          })
          setEditEventStaffData(tempStaffList)
          tempOtherStaffList.forEach((itm: any) => otherStaff.append(itm))
          // otherStaff.insert(tempOtherStaffList);
          // setOtherStaffMembers(tempOtherStaffList)
        }
      } else {
        reset(eventStaffFormDefaultValues)
      }
      trigger().then()

      return 0
    }

    setStaffsData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, setValue, trigger])

  const getDataToSave = () => {
    let otherStaffToSend: IOtherStaffItemToSave[] = []
    let currStaffLocal: IUserInterface | null = null
    let currStaffItemLocal: IStaffItem | null = null
    let currOtherStaffItemLocal: IOtherStaffItem | null = null

    let eventStaff: IStaffItem[] = editEventStaffData.filter((item: any) => {
      return item?.value?.length
    })

    // Replacing user models with their ids
    eventStaff = eventStaff.map((currStaffItem) => {
      currStaffItemLocal = cloneDeep(currStaffItem)
      currStaffItemLocal.value = currStaffItemLocal.value.map((currStaff) => {
        currStaffLocal = new UserModel(currStaff)
        return currStaffLocal.id
      }) as any
      return currStaffItemLocal
    })

    // Replacing user models with their ids
    otherStaffToSend = otherStaff.fields.map((currStaffItem: any) => {
      currOtherStaffItemLocal = cloneDeep(currStaffItem)
      if (currOtherStaffItemLocal) {
        currOtherStaffItemLocal.value = currOtherStaffItemLocal.value.map((currStaff) => {
          currStaffLocal = new UserModel(currStaff)
          return currStaffLocal.id
        }) as any
      }

      return currOtherStaffItemLocal as any
    })

    return {
      eventStaff,
      otherStaff: otherStaffToSend,
    }
  }

  const removeOtherStaffField = (index: number) => {
    otherStaff.remove(index)
    // setOtherStaffMembers((prev) => prev.filter((c, i) => i !== index))
  }

  const onSaveAndExit = async (data: any) => {
    return await props.onValidAnExit(data)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <EventFormContainerComponent>
      <div className="editEventStaff h-auto w-100 md:w-70  bg-SeabiscuitWhiteThemeColor">
        <EventFormHeaderComponent title="Staff">
          <EventFormFooterCommonComponent
            nextEventTab={props.nextEventTab}
            eventTab={props.eventTab}
            onNext={(e, publishEvent) => {
              handleSubmit(
                () =>
                  publishEvent({
                    dataToSave: getDataToSave(),
                    tabName: 'EventStaff',
                    validFormHandler: props.onValid,
                  }),
                props.onInvalid
              )(e)
            }}
            onSaveAndExit={(e, publishEvent) => {
              handleSubmit(
                () =>
                  publishEvent({
                    publish: false,
                    dataToSave: getDataToSave(),
                    tabName: 'EventStaff',
                    validFormHandler: onSaveAndExit as any,
                  }),
                props.onInvalidAndExit
              )(e)
            }}
          />
        </EventFormHeaderComponent>

        <div className="relative flex flex-wrap w-full pt-4">
          {editEventStaffData.map((val, ind) => {
            return (
              <div key={ind} className="flex mini:items-center w-full mb-6 flex-col mini:flex-row">
                <label className="text-base tw-form-title text-SeabiscuitDark200ThemeColor mr-6 mb-[14px]">
                  {val.title}
                </label>
                <div className="pt-0 tw-form-input w-full flex items-center relative">
                  <div className="w-[calc(100%-32px)]">
                    <input
                      type="text"
                      placeholder={val.placeholder}
                      onClick={() => handleOpenModal(val?.id, ind, 'eventstaff')}
                      readOnly
                      value={
                        val.value.length
                          ? val.value
                              .map((item: any) => item.userFirstName + ' ' + item.userLastName)
                              .join(', ')
                          : ''
                      }
                      className=" border-none capitalize text-base px-6 pr-12 py-3 placeholder-SeabiscuitGrayLightThemeColor text-SeabiscuitDark200ThemeColor relative bg-SeabiscuitGrayThemeColor w-full rounded-lg"
                    />
                    {
                      //  ((errors as any)?.editEventStaffData[ind]?.input_field?.message !== undefined || val.title === "Judges") &&(errors as any)?.editEventStaffData[ind]?.input_field?.message && typeof (errors as any)?.staff_text?.message === "string" ?
                      // //  <MessageHelperComp isError={true} message={(errors. as any).message} />
                      // ""
                      //  : <div className="mt-[16px]" ></div>
                    }
                  </div>
                  {val.value.length ? (
                    <img
                      src={'assets/og_icons/Ok-3.svg'}
                      alt="ok_icon"
                      className="w-[24px] absolute right-[2.75rem] top-[11px]"
                    />
                  ) : null}
                </div>
              </div>
            )
          })}

          {otherStaff.fields.map((currField, index) => {
            return (
              <div
                key={currField.id}
                className="flex mini:items-center w-full mb-6 flex-col mini:flex-row"
              >
                <input
                  type="text"
                  placeholder="Title"
                  {...register(`otherStaff.${index}.title`)}
                  className="w-full mb-3 md:mb-0 md:w-[246px] mr-6 text-base px-6 pr-12 py-3 placeholder-SeabiscuitGrayLightThemeColor text-SeabiscuitDark200ThemeColor relative bg-SeabiscuitGrayThemeColor rounded-lg !ring-0 !border-0"
                />

                <div className="pt-0 tw-form-input w-full flex items-center relative">
                  <div className="w-full">
                    <input
                      type="text"
                      placeholder="Other Staff"
                      onClick={() => {
                        setOtherStaffEditedElemIndex(index)
                        handleOpenModal(currField?.id, index, 'other')
                      }}
                      readOnly
                      value={
                        otherStaff.fields[index]?.value && otherStaff.fields[index]?.value[0]
                          ? otherStaff.fields[index]?.value
                              ?.map((c: any) => c.userFirstName + ' ' + c.userLastName)
                              .join(', ')
                          : ''
                      }
                      className="text-base px-6 pr-12 py-3 placeholder-SeabiscuitGrayLightThemeColor text-SeabiscuitDark200ThemeColor relative bg-SeabiscuitGrayThemeColor w-full rounded-lg"
                    />
                  </div>
                  {otherStaff.fields?.[index]?.value?.length ? (
                    <img
                      src={'assets/og_icons/Ok-3.svg'}
                      alt="ok_icon"
                      className={clsx(
                        'w-[24px] absolute top-[9px]',
                        otherStaff.fields?.[index]?.value?.length ? 'right-10' : 'right-3'
                      )}
                    />
                  ) : null}
                  {
                    <img
                      onClick={() => removeOtherStaffField(index)}
                      src={'assets/og_icons/Cancel.svg'}
                      alt="remove"
                      className="w-[24px] ml-2 right-3 top-[9px] cursor-pointer"
                    />
                  }
                </div>
              </div>
            )
          })}

          <div className="flex text-SeabiscuitMainThemeColor items-center gap-2 justify-between text-nr">
            <button
              type="button"
              onClick={() => {
                if (errors && typeof errors === 'object' && errors?.otherStaff) return
                // setOtherStaffMembers([
                //   ...otherStaffMembers,
                //   {
                //     title: '',
                //     value: [],
                //   },
                // ])
                otherStaff.append({
                  title: '',
                  value: [],
                  scratchedValues: [],
                })
              }}
              className="flex items-center cursor-pointer"
            >
              <AddCircleOutline className="text-SeabiscuitMainThemeColor" />
              <p className="ml-2 text-SeabiscuitMainThemeColor">Add Other</p>
            </button>
          </div>
        </div>
      </div>
    </EventFormContainerComponent>
  )
}

export default EventStaffFormComponent
