import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ICompetitorProfileInterface } from './competitor-profile.interface'
import { selectObjToString } from '../interface.helper'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @todo Document this
 */

export class CompetitorProfileModel
  extends ModelBaseModel<ICompetitorProfileInterface>
  implements IModelBaseModel
{
  // Vars
  public competitorOwnerId: ICompetitorProfileInterface['competitorOwnerId']
  public competitorJudgingLicense: ICompetitorProfileInterface['competitorJudgingLicense']
  public competitorEndorsementNumberLetter: ICompetitorProfileInterface['competitorEndorsementNumberLetter']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  public constructor(obj: ICompetitorProfileInterface) {
    super()

    this.competitorOwnerId = obj?.competitorOwnerId ?? ''
    this.competitorJudgingLicense = selectObjToString(obj?.competitorJudgingLicense) ?? ''
    this.competitorEndorsementNumberLetter = this.handleArrayTypeFeildsFn(
      obj?.competitorEndorsementNumberLetter
    )
  }

  public handleArrayTypeFeildsFn(arrayTypeValue: any) {
    let localArrayTypeValue = []
    if (!arrayTypeValue) return []

    if (typeof arrayTypeValue === 'string') localArrayTypeValue = [arrayTypeValue]
    else if (Array.isArray(arrayTypeValue)) localArrayTypeValue = arrayTypeValue

    return localArrayTypeValue
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  static fromFirestoreDoc(doc: any) {
    return new CompetitorProfileModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
