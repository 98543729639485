import { ITicketBuyer } from '../../../../models/ticket-buyers/ticket-buyers.interface'

export const DATA_FIRESTORE_V01_TICKET_BUYERS = {
  NAME: 'v01_ticket_buyers',
  FIELDS: {
    ID: {
      KEY: 'id',
    },

    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    AMOUNT_PAID: {
      KEY: 'amountPaid',
      VALUES: {
        DEFAULT: 0,
      },
    },

    PAYMENT_STATUS: {
      KEY: 'paymentStatus',
      VALUES: {
        PAID: 'paid' as ITicketBuyer['paymentStatus'],
        DEFAULT: 'pending' as ITicketBuyer['paymentStatus'],
        PENDING: 'pending' as ITicketBuyer['paymentStatus'],
        REFUNDED: 'refunded' as ITicketBuyer['paymentStatus'],
      },
    },

    REFUND_STATUS: {
      KEY: 'refundStatus',
      VALUES: {
        SEEN: 'seen' as ITicketBuyer['refundStatus'],
        DEFAULT: null as ITicketBuyer['refundStatus'],
        PENDING: 'pending' as ITicketBuyer['refundStatus'],
      },
    },

    TICKET_BUYER_NAME_N_GRAMS: {
      KEY: 'ticketBuyerNameNGrams',
      VALUES: {
        DEFAULT: [],
      },
    },

    AMOUNT_REFUNDED: {
      KEY: 'amountRefunded',
      VALUES: {
        DEFAULT: 0,
      },
    },

    TICKET_BUYER_EMAIL_ID: {
      KEY: 'ticketBuyerEmailId',
      VALUES: {
        DEFAULT: 0,
      },
    },

    USER_ID: {
      KEY: 'userId',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_DOC_ID: {
      KEY: 'eventId',
      VALUES: {
        DEFAULT: null,
      },
    },

    SPECTATOR_TICKET_IDS: {
      KEY: 'spectatorTicketsIds',
      VALUES: {
        DEFAULT: null,
      },
    },

    IS_PLATFORM_USER: {
      KEY: 'isPlatformUser',
      VALUES: {
        DEFAULT: false,
        YES: true,
        NO: false,
      },
    },

    USED_TICKETS_COUNT: {
      KEY: 'usedTicketsCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    PURCHASED_ON: {
      KEY: 'purchasedOn',
      VALUES: {
        DEFAULT: null,
      },
    },

    UNUSED_TICKET_COUNT: {
      KEY: 'unusedTicketCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    TICKET_BOUGHT_COUNT: {
      KEY: 'ticketBoughtCount',
      VALUES: {
        DEFAULT: 0,
      },
    },

    TICKET_BUYER_NAME: {
      KEY: 'ticketBuyerName',
      VALUES: {
        DEFAULT: null,
      },
    },

    TICKET_BUYER_PROFILE_PICTURE: {
      KEY: 'ticketBuyerProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    TYPE: {
      KEY: 'type',
      VALUES: {
        DEFAULT: null,
      },
    },

    CREATED: {
      KEY: 'created',
    },

    MODIFIED: {
      KEY: 'modified',
    },
  },
}
