import React, { useState } from 'react'

// Third party
import { useForm, SubmitHandler } from 'react-hook-form'
import { signInWithCustomToken } from 'firebase/auth'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { useHistory } from 'react-router'

// Redux
import {
  setCompetitionProfileImageUrl,
  setDisplayName,
  setEmail,
  setIsLoggedIn,
  setUserId,
  setUsername,
  storeUserId,
} from '../../../store/user/userSlice'
import { storeUseMode } from '../../../store/system/systemThunk'
import { useAppDispatch } from '../../../store/hooks'

// Components
import bgImage from '../../../components/user/views/login/assets/main-bg.png'
import HeaderSection from '../../../components/user/views/login/HeaderSection'
import MainModal from '../../../components/modals/common/MainModal'

import { UserModel } from '../../../models/users/user.model'

// Services
import { httpService } from '../../../services/httpService'
import FirebaseApp from '../../../services/firebaseApp'
import firestoreService from '../../../services/firestoreService'

// Helpers
import MessageHelperComp from '../../../helpers/MessageHelper'
import helpers from '../../../commonHelpers/helpers'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../helpers/helpers'

// Constants
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { IonContent, IonPage } from '@ionic/react'

type Inputs = {
  email: string
}

const correctPassword = 'RFP6AMB7N5HugpYifWFb' // Replace 'your_password' with the actual password
export const GenerateTokenPage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const toasterFunctions = useToasterHelper()

  const [loading, setLoading] = useState(false)
  const [isShowPage, setIsShowPage] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [error, setError] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async ({ email }) => {
    setLoading(true)

    try {
      const res = await httpService({
        url: `generate_token`,
        method: 'POST',
        data: { email: email },
      })

      const user = await signInWithCustomToken(FirebaseApp.auth, res)

      if (user) {
        const user_data_doc = await firestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
          user.user.uid
        )

        const userData = UserModel.fromFirestoreDoc(user_data_doc).toObject()

        if (userData.userType) {
          dispatch(setEmail(userData.userEmail?.trim()))
          dispatch(setUsername(userData.userName?.trim()))
          dispatch(setDisplayName(getUserFullName(userData)))
          dispatch(setCompetitionProfileImageUrl(userData.userProfilePicture))

          if (userData.userType === CONST.USE_MODE.COMPETITOR) {
            dispatch(storeUseMode(CONST.USE_MODE.COMPETITOR))
            history.replace('/home', { direction: 'none' })
          } else if (userData.userType === CONST.USE_MODE.ORGANIZER) {
            dispatch(storeUseMode(CONST.USE_MODE.ORGANIZER))
            history.replace(`${CONST.ROUTES.ORGANIZER_HOME.URL}`, { direction: 'none' })
          }

          dispatch(setIsLoggedIn(true))
          dispatch(storeUserId(user.user.uid))
          dispatch(setUserId(user.user.uid))
        } else {
          toasterFunctions.error({
            message: "This account doesn't exist.",
          })
        }
      }
      setLoading(false)
    } catch (error: any) {
      toasterFunctions.error({
        message: error?.response?.data?.error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        isError: true,
        message: error,
      })

      setLoading(false)
    }
  }

  return (
    <IonPage>
      <IonContent>
        {isShowPage ? (
          <div
            style={{
              backgroundImage: `url(${bgImage})`,
            }}
            className="h-screen flex flex-col bg-cover"
          >
            <HeaderSection />
            <div className="flex-1 removeScroll overflow-auto pb-10 min-h-[calc(100vh_-_118px)] text-white flex items-center">
              <div className="md:w-[470px] sm:w-[350px] w-[300px] mx-auto md:ml-[100px] transition-[all_.3s]">
                <div>
                  <h1 className="text-[22px] font-normal text-white mt-4">
                    {CONST.ROUTES.GENERATE_TOKEN.TITLE}
                  </h1>
                  <div className="min-h-full flex flex-col justify-center py-2 text-white">
                    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                      <div className="mb-6">
                        <label className="mb-3 !text-white flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-white border-[1px] bg-transparent">
                          <span className="w-[22px]">
                            <img src="/assets/og_icons/Mail-4.svg" alt="icon" className="" />
                          </span>
                          <input
                            {...register('email', { required: 'Email is required' })}
                            placeholder="Email"
                            className="flex-1 border-0 outline-0 text-white bg-transparent placeholder:text-white w-full focus:border-none p-2"
                          />
                        </label>
                        {errors.email && (
                          <MessageHelperComp
                            className="mb-3 !mt-0 bg-white rounded-lg px-2 opacity-80 w-fit"
                            isError={true}
                            message={errors.email.message}
                          />
                        )}
                      </div>
                      <div className="w-[70%] mt-[80px] mx-auto">
                        <button
                          disabled={loading}
                          type="submit"
                          className="w-full h-12 flex items-center justify-center py-2 px-4 border !border-white border-solid rounded-2xl shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor nextBtnShadow bg-white opacity-80 hover:opacity-100 focus:outline-none focus:ring-0"
                        >
                          {loading ? (
                            <AutorenewIcon fontSize="small" className="animate-spin" />
                          ) : (
                            'Generate'
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <MainModal
            show={!isShowPage}
            title="Please enter password"
            type="GENERATE_TOKEN"
            hideCloseButton={true}
            setHeightAsPerContent={true}
            size="md"
            buttons={[
              {
                disabled: !currentPassword,
                label: 'SUBMIT',
                textClass: 'text-white',
                onClick: () => {
                  if (correctPassword === currentPassword) {
                    setIsShowPage(true)
                  } else {
                    setError('Password is not correct')
                    setTimeout(() => {
                      setError('')
                    }, 2000)
                  }
                },
              },
            ]}
          >
            <form className="py-3" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex-grow mx-14">
                <label className="mb-3 text-SeabiscuitDark200ThemeColor flex items-center w-full px-4 py-2 rounded-xl border border-SeabiscuitLightThemeColorD3 hover:border-black bg-transparent transition-all">
                  <span className="w-[22px]">
                    <img src="/assets/og_icons/Lock-1.svg" alt="icon" className="" />
                  </span>
                  <input
                    placeholder="Enter Password"
                    type="password"
                    className="flex-1 border-0 outline-0 text-SeabiscuitDark200ThemeColor bg-white placeholder:opacity-50 w-full focus:border-none p-2"
                    onChange={(e: any) => setCurrentPassword(e.target.value)}
                  />
                </label>
                {error && <MessageHelperComp isError={true} message={error} />}
              </div>
            </form>
          </MainModal>
        )}
      </IonContent>
    </IonPage>
  )
}
