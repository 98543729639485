import UserSignupViewComponentSignup from './UserSignupViewComponentSignup'
import { useContext } from 'react'
import { SignupStages } from './UserSignupViewComponentContextApi'
import UserSignupViewComponentVendorForm from './UserSignupViewComponentVendorForm'
import UserSignupViewComponentSigninForm from './UserSignupViewComponentSigninForm'
import UserSignupViewComponentCompetitorRoleType from './UserSignupViewComponentCompetitorRoleType'
import UserSignupViewComponentCompetitorOrganizer from './UserSignupViewComponentCompetitorOrganizer'
import UserSignupViewComponentCompetitorOrganizerVerify from './UserSignupViewComponentCompetitorOrganizerVerify'
import UserSignupViewComponentCompetitorSignupForm from './UserSignupViewComponentCompetitorSignupForm'
import UserSignupViewComponentEventOrganizer from './UserSignupViewComponentEventOrganizer'
import UserSignupViewComponentEventOrganizerVerify from './UserSignupViewComponentEventOrganizerVerify'
import UserSignupViewComponentEventOrganizerSignupForm from './UserSignupViewComponentEventOrganizerSignupForm'
import { USE_MODE_CONST } from '../../../../../const/use-mode'

export interface FormSectionProps {
  onSignUpButtonPressed?: any
  loading?: boolean
}

const FormSection: React.FC<FormSectionProps> = (props) => {
  const [stageObj] = useContext(SignupStages)

  return (
    <div>
      {stageObj.stage === 'Init' && <UserSignupViewComponentSignup />}
      {stageObj.selector === 'event' &&
        (function () {
          switch (stageObj.stage) {
            case 'EventOrg':
              return <UserSignupViewComponentEventOrganizer />
            case 'EventOrgVerify':
              return <UserSignupViewComponentEventOrganizerVerify />
            case 'EventOrgSubmit':
              return <UserSignupViewComponentEventOrganizerVerify />
            case 'SignUp':
              return (
                <UserSignupViewComponentEventOrganizerSignupForm
                  loading={props?.loading}
                  onSignUpButtonPressed={props.onSignUpButtonPressed}
                />
              )
          }
        })()}
      {(stageObj.selector === 'competitor' || stageObj.selector === USE_MODE_CONST.STAFF) &&
        (function () {
          switch (stageObj.stage) {
            case 'CompOrgRoleType':
              return <UserSignupViewComponentCompetitorRoleType />
            case 'CompOrg':
              return <UserSignupViewComponentCompetitorOrganizer />
            case 'CompOrgVerify':
              return <UserSignupViewComponentCompetitorOrganizerVerify />
            case 'SignUp':
              return (
                <UserSignupViewComponentCompetitorSignupForm
                  onSignUpButtonPressed={props.onSignUpButtonPressed}
                  loading={props?.loading}
                />
              )
          }
        })()}
      {stageObj.selector === 'vendor' &&
        (function () {
          switch (stageObj.stage) {
            case 'VendorForm':
              return (
                <UserSignupViewComponentVendorForm
                  onSignUpButtonPressed={props.onSignUpButtonPressed}
                  loading={props?.loading}
                />
              )
            case 'SignIn':
              return <UserSignupViewComponentSigninForm />
          }
        })()}
    </div>
  )
}
export default FormSection
