// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { MODAL_CONSTS } from '../../../../const/modal-const'
import DrawerComponent from './Components/DrawerComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const SuccessModal: React.FC<{
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}> = ({ dataToPassOn, handleModal }) => {
  // Close modal

  const onHide = () => {
    handleModal(false, MODAL_CONSTS.SUCCESS)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <DrawerComponent show={true} onHide={onHide} title="" maxWidth={540}>
      <div className="text-center text-SeabiscuitDark200ThemeColor mt-[50px] mb-[50px]">
        <img
          src="/assets/og_icons/sentemail.png"
          alt="success"
          className="mx-auto mb-5"
          width={400}
          height={400}
        />
        <div className="text-[30px]">
          <h2 className="text-[30px] font-bold mb-0">Email Sent</h2>
        </div>
      </div>
    </DrawerComponent>
  )
}
export default SuccessModal
