// ############################################################
/**
 * @todo Document this
 */
// ############################################################

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
import { Link } from 'react-router-dom'
const ComingSoon: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <div className="pagenotfound flex flex-col items-center justify-center h-[calc(100vh-56px)] align-middle relative top-[-20px]">
        <h1 className="text-SeabiscuitMainThemeColor text-[4rem] bold">Coming Soon.</h1>
        <h3 className="text-[1.5rem] mt-[20px]">
          We're Working on something{' '}
          <span className="text-SeabiscuitMainThemeColor uppercase font-bold"> cool!</span>
        </h3>
        <Link
          to={'/'}
          className="w-1/4 h-12 mx-auto uppercase flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:text-SeabiscuitMainThemeColor hover:bg-SeabiscuitLightThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor mt-10"
        >
          Back to Home
        </Link>
      </div>
    </>
  )
}

export default ComingSoon
