export const Sheet2023USDFIndividualDressageSportHorseProspectInHand = {
  id: 'Sheet2023USDFIndividualDressageSportHorseProspectInHand',
  name: '2023 USDF Individual Dressage Sport Horse Prospect (In Hand)',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 100,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Conformation</h5><p>Frame / Topline / Balance / Proport • 7.5%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Conformation</h5><p>Head / Neck / Shoulder • 7.5%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Conformation</h5><p>Back / Loin / Croup • 7.5%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Conformation</h5><p>Legs / Feet • 7.5%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Movement Walk</h5><p>30%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 6,
          description: '<h5>Trot</h5><p>30%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 7,
          description: '<h5>General Impression</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const Sheet2023USDFDressageSportHorseBreedingStockInHand = {
  id: 'Sheet2023USDFDressageSportHorseBreedingStockInHand',
  name: '2023 USDF Dressage Sport Horse Breeding Stock (In Hand)',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 100,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Conformation</h5><p>Frame / Topline / Balance / Proport • 10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Conformation</h5><p>Head / Neck / Shoulder • 10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Conformation</h5><p>Back / Loin / Croup • 10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Conformation</h5><p>Legs / Feet • 10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Movement Walk</h5><p>25%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 6,
          description: '<h5>Trot</h5><p>25%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 7,
          description: '<h5>General Impression</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const Sheet2023USDFDressageSportHorseGroupClassInHand = {
  id: 'Sheet2023USDFDressageSportHorseGroupClassInHand',
  name: '2023 USDF Dressage Sport Horse Group Class (In Hand)',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 100,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Conformation</h5><p>40%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Movement Walk</h5><p>25%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Trot</h5><p>25%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>General Impression</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const Sheet2023USDFDressageSportHorseProspectsUnderSaddle = {
  id: 'Sheet2023USDFDressageSportHorseProspectsUnderSaddle',
  name: '2023 USDF Dressage Sport Horse Prospects (Under Saddle)',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 100,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Conformation</h5><p>30%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Movement Walk</h5><p>20%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Trot</h5><p>20%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Canter</h5><p>20%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>General Impression</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const Sheet2023USDFDressageSportHorseProspectsInHandMasterClass = {
  id: 'Sheet2023USDFDressageSportHorseProspectsInHandMasterClass',
  name: '2023 USDF Dressage Sport Horse Prospects (In Hand) Master Class',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 100,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Conformation</h5><p>Frame / Topline / Balance / Proport • 7.5%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Conformation</h5><p>Head / Neck / Shoulder • 7.5%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Conformation</h5><p>Back / Loin / Croup • 7.5%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Conformation</h5><p>Legs / Feet • 7.5%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Movement Walk</h5><p>30%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 6,
          description: '<h5>Movement Trot</h5><p>30%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 7,
          description: '<h5>General Impression</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const Sheet2023USDFDressageSportHorseBreedingStockInHandMasterClass = {
  id: 'Sheet2023USDFDressageSportHorseBreedingStockInHandMasterClass',
  name: '2023 USDF Dressage Sport Horse Breeding Stock (In Hand) Master Class',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 100,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Conformation</h5><p>Frame / Topline / Balance / Proport • 10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Conformation</h5><p>Head / Neck / Shoulder • 10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Conformation</h5><p>Back / Loin / Croup • 10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Conformation</h5><p>Legs / Feet • 10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Movement Walk</h5><p>25%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 6,
          description: '<h5>Movement Trot</h5><p>25%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 7,
          description: '<h5>General Impression</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const Sheet2023USDFDressageSportHorseGroupMasterClass = {
  id: 'Sheet2023USDFDressageSportHorseGroupMasterClass',
  name: '2023 USDF Dressage Sport Horse Group Master Class',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 100,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Conformation</h5><p>40%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Movement Walk</h5><p>25%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Movement Trot</h5><p>25%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>General Impression</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const Sheet2023USDFDressageSportHorseProspectsUnderSaddleMasterClass = {
  id: 'Sheet2023USDFDressageSportHorseProspectsUnderSaddleMasterClass',
  name: '2023 USDF Dressage Sport Horse Prospects (Under Saddle) Master Class',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 100,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Conformation</h5><p>30%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Movement Walk</h5><p>20%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Movement Trot</h5><p>20%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Movement Canter</h5><p>20%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>General Impression</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const Sheet2023USDFMaterialeClass = {
  id: 'Sheet2023USDFMaterialeClass',
  name: '2023 USDF Materiale Class',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 100,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Walk</h5><p>30%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Trot</h5><p>30%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Canter</h5><p>30%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>General Impression</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}

export const SheetUSDFAmateurJuniorYoungRiderHandler = {
  id: 'Sheet2023USDFMaterialeClass',
  name: 'USDF Amateur/Junior/Young Rider Handler',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Score',
      max: 100,
      type: 'score',
      rows: [
        {
          number: 1,
          description: '<h5>Grooming and Turnout</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 2,
          description: '<h5>Conformation</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 3,
          description: '<h5>Walk Presentation</h5><p>20%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 4,
          description: '<h5>Trot Presentation</h5><p>20%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 5,
          description: '<h5>Presentation and Handling</h5><p>30%</p>',
          score: '',
          remarks: '',
        },
        {
          number: 6,
          description: '<h5>General Impression(1)</h5><p>10%</p>',
          score: '',
          remarks: '',
        },
      ],
    },
  ],
}
