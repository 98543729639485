import { useEffect, useState } from 'react'
import { MODAL_CONSTS } from '../../../const/modal-const'
import MainModal from '../../modals/common/MainModal'
import LightboxCommonComponent from '../dialogs/full-screen/LightboxCommonComponent'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5

// Types
type IProps = {
  show: boolean
  dataToPassOn: {
    videos: string[]
  }
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const VideoPlayer = (props: IProps) => {
  const [showLightBox, setShowLightBox] = useState(false)

  useEffect(() => {
    setTimeout(() => setShowLightBox(true), 2000)
  }, [])

  const closeModal = () => {
    props.handleModal(false, MODAL_CONSTS.VIDEO_PLAYER, null)
  }

  return (
    <MainModal
      show={!!props.show}
      type="VIDEO_PLAYER"
      hideCloseButton={true}
      customPadding={true}
      setHeightAsPerContent={true}
      modalClassName="!py-0 overflow-hidden h-[300px] !transition-none"
      footerClassName={'!p-0'}
      size="md"
    >
      <>
        {showLightBox ? (
          <LightboxCommonComponent
            onClose={closeModal}
            visible={showLightBox}
            inline={true}
            inlineStyles={{
              style: {
                width: '100%',
                height: '100%',
                aspectRatio: '3 / 2',
                margin: '0 auto',
              },
            }}
            carouselSettings={{
              spacing: 0,
              padding: 0,
              imageFit: 'cover',
              finite: true,
            }}
            slides={(props.dataToPassOn?.videos ?? []).map((currVideo) => {
              return {
                type: 'video',
                src: currVideo,
                sources: [
                  {
                    src: currVideo,
                    type: 'video/mp4',
                  },
                ],
              }
            })}
          />
        ) : null}
      </>
    </MainModal>
  )
}

export default VideoPlayer
