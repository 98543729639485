import { useContext } from 'react'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import EventReviewPublishInput from './EventReviewPublishInput'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import DataNotAvailable from '../../../../common/alerts/data-not-available/DataNotAvailable'

const DetailRow = ({
  title,
  data,
  price,
  duration,
  onClick,
}: {
  title: any
  data?: any
  price?: any
  duration?: string
  onClick: (text: string) => void
}) => {
  const isGovernanceField = title?.toLowerCase() === 'governance'

  return (
    <div className="flex flex-col md:flex-row md:items-start w-full">
      {/* <EventReviewPublishInput
                className='rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-80 capitalize'
                data={title}
            /> */}

      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 min-w-[320px]"
        data={data}
      />

      {!isGovernanceField ? (
        <EventReviewPublishInput
          className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 grow whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer"
          data={duration}
          onClick={() => onClick(duration ?? '')}
        />
      ) : null}

      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 min-w-[14%] text-center"
        data={price}
      />

      <div className="rounded-lg bg-SeabiscuitGrayThemeColor p-1 m-1 h-12 w-12 min-w-[50px] text-center flex items-center justify-center">
        <img className="m-auto" src="/assets/cp_icons/Ok-3.svg" alt="checkIcon" />
      </div>
    </div>
  )
}

type Props = {
  data?: any
  loading?: boolean
}

const EventRegistrationReviewPublishForm = (props: Props) => {
  const { data, loading } = props
  const handleModalContext = useContext(HandleModalContext)

  /**
   * @param text Text to show
   * @info Opens note modal
   */
  const openAddNotesModal = (text: string) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NOTE, {
      noteInputRef: text,
    })
  }

  if (loading) return null

  return (
    <>
      {data ? (
        <>
          {data.registrationFees && data.registrationFees.length
            ? data.registrationFees.map((item: any, index: number) => {
                if (!item?.status) return null

                return (
                  <DetailRow
                    key={`registrationFees${index}`}
                    title={`Registrations`}
                    onClick={openAddNotesModal}
                    data={item.name}
                    price={`${item.costAlias}`}
                    duration={item.note && item.note !== '' ? item.note : 'Notes'}
                  />
                )
              })
            : null}
        </>
      ) : (
        <DataNotAvailable
          mode="text"
          containerClassName="text-SeabiscuitDark200ThemeColor justify-center flex"
          text={MESSAGES_CONST.FEES_DATA_NOT_AVAILABLE}
        />
      )}
    </>
  )
}

export default EventRegistrationReviewPublishForm
