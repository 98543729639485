import { Link } from 'react-router-dom'
import appLogo from './assets/app-logo.svg'
import { CONST } from '../../../../const/const'

const HeaderLink: React.FC<{ children: any; href: string }> = ({ children, href }) => (
  <Link className="text-white text-[16px] px-8" to={href}>
    {children}
  </Link>
)

const HeaderSection: React.FC = () => {
  return (
    <div className="flex items-center text-white py-6 px-12 gap-6 h-[118px] justify-between">
      <Link className="flex items-center  gap-6 h-[118px]" to={CONST.ROUTES.SEARCH.URL}>
        <div className="w-[55px] h-[55px] min-w-[55px]">
          <img src={appLogo} alt="logo" />
        </div>
        <div className="md:flex-1 hidden md:flex">
          <h1 className="text-[22px] font-normal text-white">Pegasus App</h1>
        </div>
      </Link>
      <div>
        <ul>
          <li>
            <HeaderLink href={CONST.ROUTES.HELP.URL}>HELP</HeaderLink>
            {/* <HeaderLink href={CONST.ROUTES.FAQ.URL}>FAQ</HeaderLink> */}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default HeaderSection
