export const FeesList = [
  {
    lineItem: 'Coastal hay',
    available: 4,
    cost: 15,
  },
  {
    lineItem: 'Shaving',
    available: 4,
    cost: 15,
  },
  {
    lineItem: 'Temproary stall',
    available: 4,
    cost: 15,
  },
  {
    lineItem: 'Private stall',
    available: 4,
    cost: 15,
  },
  {
    lineItem: 'USEF fee',
    available: 4,
    cost: 15,
  },
  {
    lineItem: 'USEF drug fee',
    available: 4,
    cost: 15,
  },
  {
    lineItem: 'USHJA horse fee',
    available: 4,
    cost: 15,
  },
  {
    lineItem: 'Paddock fee',
    available: 4,
    cost: 15,
  },
]
