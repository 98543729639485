export const SheetFEIPrixStGeorge = {
  id: 'SheetFEIPrixStGeorge',
  name: 'FEI Prix St. George',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 320,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>M - X - K •</span> Medium trot</p><p><span>K •</span> Collected trot</p><p><span>KAF •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>FB •</span> Shoulder-in left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>B •</span> Volte left (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>BG •</span> Half-pass to the left</p><p><span>G •</span> On centre line</p><p><span>C •</span> Track to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>HXF •</span> Extended trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>N/A •</span> Transitions at H and F</p><p><span>FAK •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>KE •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>E •</span> Volte right (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>EG •</span> Half-pass to the right</p><p><span>G •</span> On centre line</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>Before C •</span> [Collected walk]</p><p><span>C •</span> [Track to the left]</p><p><span>H •</span> [Turn left]</p><p><span>Between G&M •</span> Half pirouette to the left</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>Between G&H •</span> Half pirouette to the right</p><p><span>GM •</span> [Collected walk]</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>N/A •</span> The collected walk C-H-G-(M)-G-(H)-G-M</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>MRXV(K) •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>Before K •</span> Collected walk</p><p><span>K •</span> Proceed in collected canter left</p><p><span>KAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>FX •</span> Half-pass to the left</p><p><span>X •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>XM •</span> Half-pass to the right</p><p><span>M •</span> Flying change of leg</p><p><span>MCH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>H •</span> Proceed towards X in collected canter</p><p><span>Between H&X •</span> Half pirouette to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>HC •</span> Counter canter</p><p><span>C •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>M •</span> Proceed towards X in collected canter</p><p><span>Between M&X •</span> Half pirouette to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>MC •</span> Counter canter</p><p><span>C •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>HXF •</span> On the diagonal 5 flying changes of leg every 4th stride</p><p><span>FAK •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>KXM •</span> On the diagonal 5 flying changes of leg every 3rd stride</p><p><span>MCH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description: '<p><span>HXF •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>F •</span> Collected canter and flying change of leg</p><p><span>FA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIIntermediate1 = {
  id: 'SheetFEIIntermediate1',
  name: 'FEI Intermediate 1',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 320,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the left</p><p><span>HXF •</span> Extended trot</p><p><span>F •</span> Collected trot</p><p><span>FA •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DX •</span> Shoulder-in right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>X •</span> Volte right (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>XM •</span> Half-pass to the right</p><p><span>MC •</span> Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>C •</span> Halt - immobility; Rein back 5 steps and immediately proceed in collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>HX •</span> Half-pass to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>X •</span> Volte left (8 m Ø)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description:
            '<p><span>XD •</span> Shoulder-in left</p><p><span>D •</span> On centre line</p><p><span>A •</span> Track to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>KR •</span> Medium trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>R •</span> Collected walk</p><p><span>RMGH •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>HB(P) •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>Before P •</span> Collected walk</p><p><span>P •</span> Proceed in collected canter right</p><p><span>PFA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>Between D & G •</span> 3 half-passes 5 m to either side of the centre line with flying change of leg at each change of direction starting and ending to the right</p><p><span>G •</span> Flying change of leg</p><p><span>C •</span> Track to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>HXF •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>F •</span> Collected canter and flying change of leg</p><p><span>FAK •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>KXM •</span> On the diagonal 5 flying changes of leg every 3rd stride</p><p><span>MCH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>H(B) •</span> On the diagonal</p><p><span>I •</span> Pirouette to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>B •</span> Flying change of leg</p><p><span>Before and after B •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>B(K) •</span> On the diagonal</p><p><span>L •</span> Pirouette to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>K •</span> Flying change of leg</p><p><span>KAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>FXH •</span> On the diagonal 7 flying changes of leg every 2nd stride</p><p><span>HC •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>C •</span> Collected trot</p><p><span>CM •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>MXK •</span> Extended trot</p><p><span>K •</span> Collected trot</p><p><span>KA •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility – salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIIntermediateA = {
  id: 'SheetFEIIntermediateA',
  name: 'FEI Intermediate A',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 320,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the left</p><p><span>HXF •</span> Extended trot</p><p><span>F •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>N/A •</span> Transitions at H and F</p><p><span>FA •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DG •</span> 3 half-passes, 5 m to either side of the centre line, beginning and ending to the right</p><p><span>C •</span> Track to the left</p><p><span>CHS •</span> Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>SR •</span> Half circle (20 m Ø)</p><p><span>Between centre line and R •</span> Transition to passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>RMC •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>C •</span> Piaffe 7-10 steps (half steps 2 m forward allowed)</p><p><span>N/A •</span> [Proceed in collected trot]</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>N/A •</span> Transitions passage - piaffe - trot</p><p><span>(C)HS •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>SXP •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>P •</span> Collected walk</p><p><span>PF(A) •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description:
            '<p><span>On approaching A •</span> Piaffe, 7-10 steps (half steps 2 m forward allowed)</p><p><span>N/A •</span> [Proceed in collected trot]</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description:
            '<p><span>N/A •</span> Transitions walk - piaffe - trot</p><p><span>A(K) •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>KXM •</span> Extended trot</p><p><span>M •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>N/A •</span> Transitions at K and M</p><p><span>MC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>C •</span> Proceed in collected canter left</p><p><span>CH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>HXF •</span> Extended canter</p><p><span>F •</span> Collected canter and flying change of leg</p><p><span>FA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DP •</span> Half-pass to the right</p><p><span>Between P & B •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>BIH •</span> On the short diagonal</p><p><span>I •</span> Pirouette to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>H •</span> Flying change of leg</p><p><span>HCM •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>MXK •</span> 7 flying changes of leg every 2nd stride</p><p><span>KA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DV •</span> Half-pass to the left</p><p><span>Between V & E •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>EIM •</span> On the short diagonal</p><p><span>I •</span> Pirouette to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>M •</span> Flying change of leg</p><p><span>MCH •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>HXF •</span> 7 flying changes of leg every stride</p><p><span>FA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>D •</span> Collected trot</p><p><span>L •</span> Transition to passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description: '<p><span>LI •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description:
            '<p><span>IG •</span> Collected trot</p><p><span>G •</span> Halt - immobility – salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIIntermediateB = {
  id: 'SheetFEIIntermediateB',
  name: 'FEI Intermediate B',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 350,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>MXK •</span> Extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>N/A •</span> Transitions at M and K</p><p><span>KAF •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p>Starting between F &amp; P to S • Half-pass to the left</p><p>S • Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description: '<p>Between S &amp; H • Transition to passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Between S &amp; H to C • Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>C •</span> Piaffe 7-10 steps (half steps 2 m forward allowed)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>C •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>(C)M •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description:
            '<p><span>M •</span> Collected trot</p><p>Stating between M &amp;R to V • Half pass to the right</p><p>VKA • Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description: '<p>AFL • Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p>L • Piaffe 7-10 steps (half steps 2 m forward allowed)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description: '<p>L • Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p>LE • Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p>EIM • Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description: '<p>MCHG • Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>G •</span> Halt - immobility; Rein back 4 steps and immediately proceed in collected canter right</p><p>GMR • Half-pass to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p>RK • On the diagonal 7 flying changes of leg every 2nd stride</p><p>KA • Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>A •</span> Down the centre line</p><p>Between D &amp; G • 4 half-passes to either side of centre line with flying change of leg at each change of direction, the first half- pass to the left and the last to the right of 4 strides, the others of 8 strides</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p>MXK • Extended canter</p><p><span>K •</span> Collected canter and flying change of leg</p><p>KAFP • Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p>PH • On the diagonal 9 flying changes of leg every stride</p><p>HCM • Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description: '<p>MIE • On the short diagonal</p><p>I • Pirouette to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>E •</span> Flying change of leg</p><p>Before &amp; after E • Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description: '<p>ELF • On the short diagonal</p><p>L • Pirouette to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>F •</span> Flying change of leg</p><p>Before &amp; after F • Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>X •</span> Halt - immobility – salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIIntermediate2 = {
  id: 'SheetFEIIntermediate2',
  name: 'FEI Intermediate 2',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 320,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the left</p><p><span>HXF •</span> Extended trot</p><p><span>F •</span> Collected trot</p><p><span>FA •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DG •</span> 3 half-passes, 5 m to either side of the centre line, beginning and ending to the right</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>Between R &amp; B •</span> Transition to Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>B(V) •</span> On the diagonal. Passage.</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>Crossing the center line •</span> Piaffe 8 to 10 steps (1 m forward permitted)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>In/out of the piaffe •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>From piaffe VKA •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>A •</span> Piaffe 8 to 10 steps (1 m forward permitted)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>A •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>AFP •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>PS •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>S •</span> Collected walk</p><p><span>SHC •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>C •</span> Proceed in collected canter right</p><p><span>CM •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>MXK •</span> Medium canter</p><p><span>X •</span> Flying change of leg</p><p><span>KAFP •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>PX •</span> Half-pass to the left</p><p><span>X •</span> Down the centre line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>I •</span> Pirouette to the left</p><p><span>C •</span> Track to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 18,
          description: '<p><span>HXF •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>F •</span> Collected canter and flying change of leg</p><p><span>FAKV •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>VX •</span> Half-pass to the right</p><p><span>X •</span> Down the centre line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>I •</span> Pirouette to the right</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>MXK •</span> On the diagonal 7 flying changes of leg every 2nd stride</p><p><span>KAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>FXH •</span> On the diagonal 11 flying changes of leg every stride</p><p><span>HC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>C •</span> Collected trot</p><p><span>CM •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>MXK •</span> Extended trot</p><p><span>K •</span> Collected trot</p><p><span>KA •</span> The collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>Before D&amp;L •</span> Transition to passage</p><p><span>LI •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description: '<p><span>I •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIShortGrandPrix = {
  id: 'SheetFEIShortGrandPrix',
  name: 'FEI Short Grand Prix',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 360,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>MXK •</span> Extended trot</p><p><span>K •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>N/A •</span> Transitions at M and K</p><p><span>KAF •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>FE •</span> Half-pass to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>EM •</span> Half-pass to the right</p><p><span>MC •</span> Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description:
            '<p><span>C •</span> Halt - immobility; Rein back 5 steps and immediately proceed in collected trot</p><p><span>CH •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description:
            '<p><span>H •</span> Proceed in passage; Transition collected trot - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>HSI •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>I •</span> Piaffe 12 to 15 steps</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>I •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>IRB •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>BV •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>VKD •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description:
            '<p><span>D •</span> Proceed in collected canter right</p><p><span>DFA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>Between D &amp; G •</span> 5 half-passes to either side of centre line with flying change of leg at each change of direction, the first half-pass to the right and the last to the right of 3 strides, the others of 6 strides</p><p><span>G •</span> Flying change of leg</p><p><span>C •</span> Track to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description:
            '<p><span>HXF •</span> On the diagonal 9 flying changes of leg every 2nd stride</p><p><span>FA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>D •</span> Pirouette to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>Between D &amp; G •</span> On the centre line 11 flying changes of leg every stride</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>G •</span> Pirouette to the left</p><p><span>C •</span> Turn left</p><p><span>CH •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 20,
          description:
            '<p><span>HP •</span> Extended canter</p><p><span>P •</span> Collected canter and flying change of leg</p><p><span>PF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>F •</span> Collected trot</p><p><span>FA •</span> Collected trot</p><p><span>A •</span> Down the centre line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description: '<p><span>DX •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description: '<p><span>X •</span> Piaffe 12 to 15 steps</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 24,
          description: '<p><span>X •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description: '<p><span>XG •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description: '<p><span>G •</span> Halt - immobility - salute</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIGrandPrix = {
  id: 'SheetFEIGrandPrix',
  name: 'FEI Grand Prix',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 440,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the left</p><p><span>HXF •</span> Extended trot</p><p><span>FAK •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description: '<p><span>KB •</span> Half-pass to the right</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 4,
          description:
            '<p><span>BH •</span> Half-pass to the left</p><p><span>HC •</span> Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p><span>C •</span> Halt - immobility; Rein back 5 steps and immediately proceed in collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>MV •</span> Extended trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>VKD •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p><span>D •</span> Piaffe 12 to 15 steps</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>D •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>DFP •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>PH •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>HCM •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description:
            '<p><span>M •</span> Proceed in passage; Transition collected walk - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>MRI •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>I •</span> Piaffe 12 to 15 steps</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description: '<p><span>I •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description: '<p><span>ISE •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description:
            '<p><span>E •</span> Proceed in collected canter left</p><p><span>EKAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 19,
          description:
            '<p><span>FXH •</span> On the diagonal 9 flying changes of leg every 2nd stride</p><p><span>HCM •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description: '<p><span>MXK •</span> Extended canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>K •</span> Collected canter and flying change of leg</p><p><span>KA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>Between D&amp;G •</span> 5 half-passes to either side of centre line with flying change of leg at each change of direction, the first half-pass to the left and the last to the left of 3 strides, the others of 6 strides</p><p><span>G •</span> Flying change of leg</p><p><span>C •</span> Track to the right</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>MXK •</span> On the diagonal 15 flying changes of leg every stride</p><p><span>KA •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>L •</span> Pirouette to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 25,
          description: '<p><span>X •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>I •</span> Pirouette to the right</p><p><span>C •</span> Track to the right</p><p><span>CM •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 27,
          description:
            '<p><span>M •</span> Collected trot</p><p><span>MR •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 28,
          description:
            '<p><span>RK •</span> Extended trot</p><p><span>K •</span> Collected trot</p><p><span>KA •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 29,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DX •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 30,
          description: '<p><span>X •</span> Piaffe 12 to 15 steps</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 31,
          description: '<p><span>X •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 32,
          description: '<p><span>XG •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 33,
          description: '<p><span>G •</span> Halt - immobility - salut</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}

export const SheetFEIGrandPrixSpecial = {
  id: 'SheetFEIGrandPrixSpecial',
  name: 'FEI Grand Prix Special',
  furtherRemarks: '',
  type: 'score-sheet-4',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 450,
      type: 'test',
      rows: [
        {
          number: 1,
          description:
            '<p><span>A •</span> Enter in collected canter</p><p><span>X •</span> Halt - immobility - salute. Proceed in collected trot.</p><p><span>XC •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description:
            '<p><span>C •</span> Track to the right</p><p><span>MXK •</span> Extended trot</p><p><span>KAFP •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 3,
          description:
            '<p><span>PS •</span> Half-pass to the left</p><p><span>SHC •</span> Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 4,
          description: '<p><span>CMR •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p><span>RF •</span> Extended trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p><span>N/A •</span> Transitions passage - extended trot -passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p><span>FAK •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description:
            '<p><span>KV •</span> Collected trot</p><p><span>VR •</span> Half-pass to the right</p><p><span>RMC •</span> Collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description: '<p><span>CHS •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p><span>SK •</span> Extended trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p><span>N/A •</span> Transitions passage - extended trot passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p><span>KAF •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p><span>FS •</span> Extended walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description: '<p><span>SHG •</span> Collected walk</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 15,
          description: '<p><span>G •</span> Piaffe 12 to 15 steps</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 16,
          description: '<p><span>G •</span> Transitions collected walk - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 17,
          description: '<p><span>GMRI •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 18,
          description: '<p><span>I •</span> Piaffe 12 to 15 steps</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 19,
          description: '<p><span>I •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 20,
          description: '<p><span>ISEX •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 21,
          description:
            '<p><span>X •</span> Proceed in collected canter right</p><p><span>XBFAK •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 22,
          description:
            '<p><span>KB •</span> Half-pass to the right</p><p><span>B •</span> Flying change of leg</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 23,
          description:
            '<p><span>BH •</span> Half-pass to the left</p><p><span>H •</span> Flying change of leg</p><p><span>HCM •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 24,
          description:
            '<p><span>MXK •</span> On the diagonal 9 flying changes of leg every 2nd stride</p><p><span>KAF •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 25,
          description:
            '<p><span>FXH •</span> On the diagonal 15 flying changes of leg every stride</p><p><span>HCM •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 26,
          description:
            '<p><span>MXK •</span> Extended canter</p><p><span>K •</span> Collected canter and flying change of leg</p><p><span>KA •</span> Collected canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 27,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>D •</span> Pirouette to the left</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 28,
          description:
            '<p><span>Between D&amp;G •</span> On the centre line 9 flying changes of leg every stride</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 29,
          description:
            '<p><span>G •</span> Pirouette to the right</p><p><span>C •</span> Track to the right</p><p><span>CM •</span> Collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 30,
          description:
            '<p><span>M •</span> Collected trot</p><p><span>MR •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 31,
          description:
            '<p><span>RF •</span> Extended trot</p><p><span>F •</span> Collected trot</p><p><span>FA •</span> Collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 32,
          description:
            '<p><span>A •</span> Down the centre line</p><p><span>DX •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 33,
          description: '<p><span>X •</span> Piaffe 12 to 15 steps</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 34,
          description: '<p><span>N/A •</span> Transitions passage - piaffe - passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 35,
          description: '<p><span>XG •</span> Passage</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 36,
          description: '<p><span>G •</span> Halt - immobility - salut</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
      ],
      notice: 'Leave arena at A in walk on a long rein',
    },
    {
      name: 'Collective remarks',
      max: 20,
      type: 'mark',
      rows: [
        {
          description: '<h5>General Impression</h5>',
          score: '',
          coefficient: 2,
        },
      ],
    },
  ],
}
