import RiderDatatable from './tabs/rider/Datatable'
import OrganizerDatatable from './tabs/organizer/Datatable'
import FollowersDatatable from './tabs/follower/Datatable'

enum ETypes {
  rider,
  organizer,
  followers,
}
// Types
type IProps = {
  type: ETypes
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const FollowingListing = (props: IProps) => {
  switch (props.type) {
    case ETypes.organizer:
      return <OrganizerDatatable />
    case ETypes.followers:
      return <FollowersDatatable />
    default:
      return <RiderDatatable />
  }
}

export default FollowingListing
