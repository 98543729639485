import { Modal } from './Modal'
import ImageGallery from 'react-image-gallery'
import './PhotoModal.css'

interface Props {
  open: boolean
  imageURL: []
  onClose: () => void | Promise<void>
}
export const PhotoModal = ({ open, imageURL, onClose }: Props) => {
  const images = imageURL.map((url) => ({ original: url }))

  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex lg:w-[800px] lg:h-[450px transition duration-500 items-center">
        <ImageGallery items={images} showBullets={true} showPlayButton={false} showNav={true} />

        <button className="absolute right-0 top-0 p-[10px]" onClick={onClose}></button>
      </div>
    </Modal>
  )
}
