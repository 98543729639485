import { Modal } from './Modal'

interface Props {
  open: boolean
  videoURL: string
  onClose: () => void | Promise<void>
}
export const VideoModal = ({ open, videoURL, onClose }: Props) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="lg:w-[800px] lg:h-[450px] bg-black transition duration-500 rounded-xl">
        <video
          width="100%"
          height="100%"
          className="max-h-[450px] rounded-xl"
          autoPlay
          loop
          muted
          controls
        >
          <source src={videoURL} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <button className="absolute right-0 top-0 p-[10px]" onClick={onClose}></button>
      </div>
    </Modal>
  )
}
