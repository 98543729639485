import { useEffect, useState } from 'react'

import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import { sumBy } from 'lodash'

import ExhibitorTicketsAccordion from './accordions/ExhibitorTicketsAccordion'

// Types
import { IScratch } from '../../../store/events/eventsSlice'
import { setExhibitorTotals } from '../../../store/exhibitor/exhibitorSlice'
import { useAppDispatch } from '../../../store/hooks'
import ExhibitorTicketsRefundAccordion from './accordions/ExhibitorTicketsRefundAccordion'

import Accordion from '../../../components/accordion/common/Accordion'
import { IRegistrationTicketInterface } from '../../../models/registration-tickets/registrationTicket.interface'
import { IOnRefundAmountChangeFn } from './ExhibitorProfileDisplayTab'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IExhibitorProfileTeamTabProps = {
  isRefund: boolean
  exhibitorAllData?: any
  showScratchedView: boolean
  isRecipient?: boolean
  getScratchView: (scratchType: IScratch['scratchType']) => ReactJSXElement | null
  onRefundAmountChange?: IOnRefundAmountChangeFn
  setListToScratch: React.Dispatch<React.SetStateAction<string[]>>
  listToScratch: string[]
  registrationticketData: IRegistrationTicketInterface[]
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ExhibitorProfileTicketsTab = (props: IExhibitorProfileTeamTabProps) => {
  // Hooks and vars
  const dispatch = useAppDispatch()

  const {
    getScratchView,
    setListToScratch,
    listToScratch,
    showScratchedView,
    registrationticketData,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [itemsWithoutScratched, setItemsWithoutScratched] = useState<
    IRegistrationTicketInterface[]
  >([])
  const [priceTotal, setPriceTotal] = useState<number>(0)
  const [listToScratchPriceTotal, setListToScratchPriceTotal] = useState<number>(0)

  useEffect(() => {
    if (registrationticketData && registrationticketData.length) {
      const price = parseFloat(
        sumBy(registrationticketData, (item) => item.selectedUnitsCount * item.ticketPrice).toFixed(
          2
        )
      )

      setItemsWithoutScratched(registrationticketData)
      setPriceTotal(price)

      dispatch(
        setExhibitorTotals({
          value: price,
          keyToUpdate: 'ticketsPriceTotal',
        })
      )
    }
  }, [dispatch, registrationticketData])

  useEffect(() => {
    if (registrationticketData && registrationticketData.length) {
      const listToScratchPrice = parseFloat(
        sumBy(
          registrationticketData.filter((registration) =>
            listToScratch.includes(registration.id ?? '')
          ),
          (item) => item.selectedUnitsCount * item.ticketPrice
        ).toFixed(2)
      )
      setListToScratchPriceTotal(listToScratchPrice)
    }
  }, [listToScratch, registrationticketData])

  return (
    <Accordion
      icon={<AddCircleOutline className="add_icon text-[red]" />}
      initialState={true}
      className="mb-2"
      header={
        <div
          className={`text-SeabiscuitDark200ThemeColor flex justify-between items-center ${isOpen ? 'font-normal' : 'font-bold'}`}
        >
          Tickets
          <div className="flex items-center details">
            <div className="font-normal text-[14px] text-SeabiscuitDark200ThemeColor">
              {showScratchedView
                ? `${listToScratch.length} ${listToScratch.length > 1 ? 'items' : 'item'} scratched, $${listToScratchPriceTotal} refund requested`
                : `${listToScratch.length ? listToScratch.length : itemsWithoutScratched.length} Ticket${itemsWithoutScratched.length > 1 ? 's' : ''}, $${priceTotal}`}
            </div>
          </div>
        </div>
      }
      headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
      onOpen={() => {
        setIsOpen(true)
      }}
      onClose={() => {
        setIsOpen(false)
      }}
      activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
    >
      <>
        {props.isRefund ? (
          <ExhibitorTicketsRefundAccordion
            onRefundAmountChange={props.onRefundAmountChange}
            registrationticketData={registrationticketData}
          />
        ) : (
          <ExhibitorTicketsAccordion
            data={registrationticketData}
            showScratchedView={props.showScratchedView}
            setListToScratch={setListToScratch}
            listToScratch={listToScratch}
            registrationticketData={registrationticketData}
          />
        )}
        {getScratchView('ticket')}
      </>
    </Accordion>
  )
}

export default ExhibitorProfileTicketsTab
