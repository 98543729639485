import { Close } from '@mui/icons-material'

type Props = {
  show?: boolean
  handleModal?: any
}

const ZoneInfoModal = (props: Props) => {
  return (
    <>
      <div
        className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
        id="exampleModalCenter"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-2xl outline-none text-current pt-10 pb-7 px-8">
            <span
              onClick={() => props.handleModal(false, 'zone')}
              className="absolute text right-2 top-2 text-SeabiscuitMainThemeColor cursor-pointer"
            >
              <Close className="text-[#122B46]" />
            </span>

            <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-2xl font-Poppins">
              <h5
                className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-bold pb-2"
                id="exampleModalScrollableLabel"
              >
                Zone
              </h5>
            </div>

            <div className="">
              <p className="text-[#122B46] mt-2 text-[14px] font-Poppins">
                Zones are used by governing bodies to award points.
              </p>
              <p className="text-[#122B46] mt-2 text-[14px] font-Poppins">
                Riders and Owners register their zone with the governing bodies based on their
                residential address.
              </p>
              <p className="text-[#122B46] mt-2 text-[14px] font-Poppins">
                Horses do not register a zone.{' '}
              </p>
              <p className="text-[#122B46] mt-2 text-[14px] font-Poppins">
                Instead, horses receive the zone desingated to either their Rider or their Owner.
              </p>
              <p className="text-[#122B46] mt-2 text-[14px] font-Poppins">
                Select a zone for this horse based on the below rules:
              </p>

              <ul className="mt-3 list-disc ml-4 mb-4">
                <li className="text-[#122B46] text-[14px] font-Poppins">
                  <span className="font-semibold"> Dressage. </span> Horse zone equal to Owners
                  zone.
                </li>
                <li className="text-[#122B46] text-[14px] font-Poppins">
                  <span className="font-semibold"> Equitation. </span> Horses zone equal to Riders
                  zone.
                </li>
                <li className="text-[#122B46] text-[14px] font-Poppins">
                  <span className="font-semibold"> Eventing. </span> Horse zone equal to Owners
                  zone.
                </li>
                <li className="text-[#122B46] text-[14px] font-Poppins">
                  <span className="font-semibold"> Hunter. </span> Horses zone equal to Owners zone.
                </li>
                <li className="text-[#122B46] text-[14px] font-Poppins">
                  <span className="font-semibold"> Jumper. </span> Horses zone equal to Owners zone.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ZoneInfoModal
