export const DATA_FIRESTORE_V01_GUESTS = {
  NAME: 'v01_guests',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    FIRST_NAME: {
      KEY: 'firstName',
      VALUES: {
        UNKNOWN: 'Unknown',
      },
    },

    USER_EMAIL: {
      KEY: 'userEmail',
      VALUES: {
        UNKNOWN: 'Unknown',
      },
    },

    USER_NAME: {
      KEY: 'userName',
      VALUES: {
        UNKNOWN: 'Unknown',
      },
    },

    USER_NAME_NGRAMS: {
      KEY: 'userNameNGram',
      VALUES: {
        UNKNOWN: 'Unknown',
      },
    },

    LAST_NAME: {
      KEY: 'userLastName',
      VALUES: {
        UNKNOWN: 'Unknown',
      },
    },

    USER_CARDS: {
      KEY: 'userCards',
      VALUES: {
        DEFAULT: [],
      },
    },

    ID: {
      KEY: 'id',
    },

    USER_STRIPE_ID: {
      KEY: 'userStripeId',
    },

    CREATED: {
      KEY: 'created',
    },

    MODIFIED: {
      KEY: 'modified',
    },
  },
}
