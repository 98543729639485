import { Close } from '@mui/icons-material'
import { MODAL_CONSTS } from '../../const/modal-const'

type Props = {
  show?: boolean
  handleModal?: any
  dataToPassOn?: any
}

const AccountPrivacyInformation = (props: Props) => {
  return (
    <>
      <div
        className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered relative lg:w-3/5 xl:w-1/3 m-auto pointer-events-none">
          <div
            className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-2xl outline-none text-current pb-10 pt-8 px-8"
            style={{ width: 'max-content', maxWidth: '146%', height: 'fit-content' }}
          >
            <span
              onClick={() => props.handleModal(false, MODAL_CONSTS.ACCOUNT_PRIVACY)}
              className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
            >
              <Close className="text-[#122B46]" />
            </span>

            <div className="modal-header rounded-t-xl font-Poppins h-[100%]">
              <h5 className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal">
                Account Privacy
              </h5>

              <div className="allInfo text-[#122B46]  overflow-y-auto pr-2 h-[100%]">
                <p className="mt-4 font-[Poppins]">
                  When your account is <b>Public,</b> your profile, future events and past events
                  can be seen by other members with Pegasus accounts. Sensitive profile information
                  such as your address will be hidden.
                </p>
                <p className="mt-4">
                  When your account is <b>Private,</b> only your followers can view your profile
                  information and future events. Your profile picture, name, nationality and past
                  events will remain visible to non-followers.
                </p>

                <p className="mt-4">
                  {' '}
                  <b>If you are under eighteen (18) years of age,</b> your profile will be set to
                  Private automatically and all event participation details will be hidden.
                </p>

                <p className="mt-4">
                  Regardless of whether your account is set to Public or Private,{' '}
                  <b>members of the public</b> will view your profile in Private mode.
                </p>
                <p className="mt-4">
                  Note. If you have registered for an event, the <b>event organizer</b> will have
                  access to your full profile to support management of your participation in the
                  event.
                </p>
              </div>

              {/* <button
                                onClick={() => props.handleModal(false, MODAL_CONSTS.ACCOUNT_PRIVACY)}
                                type="button"
                                className="w-full mt-6 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor">
                                CLOSE
                            </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountPrivacyInformation
