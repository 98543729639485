import { Tooltip } from '@mui/material'
import clsx from 'clsx'
import TooltipIcon from '../../../../../helpers/TooltipIcon'
import { IFeesTab } from '../../../../../models/event-registered-users/event-registered-users.interface'

// Constants

const widths = [
  'shrink-0 w-[170px]',
  'shrink-0 w-[100px]',
  'shrink-0 w-[150px]',
  'shrink-0 w-[130px]',
  'grow min-w-[230px]',
  'shrink-0 w-[130px] mr-3',
]

// Types

type IFeesHeaderProps = {
  feesCategory: IFeesTab['feesCategory']
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const FeesHeader = (props: IFeesHeaderProps) => {
  return (
    <>
      <div className="capitalize pb-2 font-semibold">{props.feesCategory}</div>
      <div className="hidden lg:flex items-end w-full pt-4 gap-[10px]">
        <span className={clsx('registration_tabs_column_title', widths[0])}>
          Item
          <Tooltip
            title={<h1 className="tooltip_title">Fees added by the event organizer</h1>}
            placement="top"
            arrow
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>

        <span className={clsx('registration_tabs_column_title', widths[1])}>
          Price
          <Tooltip
            title={<h1 className="tooltip_title">Price per fee set by event organizer</h1>}
            placement="top"
            arrow
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>

        <span className={clsx('registration_tabs_column_title', widths[2])}>
          Duration
          <Tooltip
            title={<h1 className="tooltip_title">Frequency you will be charged the price</h1>}
            placement="top"
            arrow
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
        <span className={clsx('registration_tabs_column_title', widths[3])}>
          Availability
          <Tooltip
            title={<h1 className="tooltip_title">Units of this item remaining</h1>}
            placement="top"
            arrow
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
        <span className={clsx('px-2 registration_tabs_column_title', widths[4])}>
          Details
          <Tooltip
            title={<h1 className="tooltip_title">Click for more details on this fee</h1>}
            placement="top"
            arrow
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
        <span className={clsx('registration_tabs_column_title', widths[5])}>
          Enter Amount
          <Tooltip
            title={
              <h1 className="tooltip_title">
                Select how many of these items you would like to purchase
              </h1>
            }
            placement="top"
            arrow
          >
            <button>
              <TooltipIcon color="#122B46" />
            </button>
          </Tooltip>
        </span>
      </div>
    </>
  )
}

export default FeesHeader
