import helpers from '../commonHelpers/helpers'
import { CONFIG } from '../config/config'
import FirebaseApp from './firebaseApp'

import {
  DocumentData,
  QueryDocumentSnapshot,
  QueryFieldFilterConstraint,
  QuerySnapshot,
  addDoc,
  collection,
  deleteDoc,
  doc,
  documentId,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const createItem = async (collection_name: string, document: any) => {
  return addDoc(collection(FirebaseApp.firestore, collection_name), document)
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const getItem = async (collection_name: string, id: string) => {
  return getDoc(doc(collection(FirebaseApp.firestore, collection_name), id))
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const getItemsUsingIds = async (
  collection_name: string,
  idsList: string[]
): Promise<QueryDocumentSnapshot<DocumentData>[]> => {
  let idsLocalList: string[] = []
  let fetchedDocsCount: number = 0
  let idsOfCurrentChunk: string[] = []
  let alreadyFetchDocsIdSet: Set<string> = new Set()
  let currDocsHolder: QuerySnapshot<DocumentData> = [] as any
  let allDocsHolder: QueryDocumentSnapshot<DocumentData>[] = []

  const CHUNK_SIZE = 10

  try {
    while (allDocsHolder.length !== idsList.length || allDocsHolder.length > idsList.length) {
      idsLocalList = [...idsList]
      idsOfCurrentChunk = idsLocalList.splice(fetchedDocsCount, CHUNK_SIZE)

      if (!idsOfCurrentChunk.length) break

      currDocsHolder = await filterItems(collection_name, [
        where(documentId(), 'in', idsOfCurrentChunk),
      ])

      currDocsHolder.forEach((doc) => {
        allDocsHolder.push(doc)
        alreadyFetchDocsIdSet.add(doc.data().id)
      })

      fetchedDocsCount += idsOfCurrentChunk.length
    }
  } catch (err) {
    if (CONFIG.RUN_MODE !== 'production')
      helpers.logger({
        isError: true,
        message: err,
      })
  } finally {
    return allDocsHolder
  }
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const listItems = async (
  collection_name: string,
  orderByField?: string,
  orderByDirection?: any,
  perPage?: number,
  cursorId?: any
) => {
  const collectionRef = collection(FirebaseApp.firestore, collection_name)

  const queryConstraints = []

  if (orderByField) {
    queryConstraints.push(orderBy(orderByField, 'asc'))
  }

  if (orderByField && orderByDirection) {
    queryConstraints.push(orderBy(orderByField, orderByDirection))
  }

  if (perPage) {
    queryConstraints.push(limit(2))
  }

  if (cursorId) {
    const cursorDocument = await getItem(collection_name, cursorId)
    queryConstraints.push(startAfter(cursorDocument))
  }

  const firestoreQuery = query(collectionRef, ...queryConstraints)

  return getDocs(firestoreQuery)
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const filterItems = async (
  collection_name: string,
  queries?: any[],
  perPage?: number | null,
  orderByField?: string | null,
  orderByDirection?: any | null,
  cursorId?: any
) => {
  const collectionRef = collection(FirebaseApp.firestore, collection_name)

  let queryConstraints: any[] = []
  queries = queries ?? []

  if (orderByField && !orderByDirection) {
    queryConstraints.push(orderBy(orderByField, 'asc'))
  }

  if (orderByField && orderByDirection) {
    queryConstraints.push(orderBy(orderByField, orderByDirection))
  }

  if (perPage) {
    queryConstraints.push(limit(perPage))
  }

  if (cursorId) {
    const cursorDocument = await getItem(collection_name, cursorId)
    queryConstraints.push(startAfter(cursorDocument))
  }

  const firestoreQuery = query(collectionRef, ...queryConstraints, ...(queries as any))

  return getDocs(firestoreQuery)
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const updateItem = async (collection_name: string, collection_id: any, data: any) => {
  return updateDoc(doc(FirebaseApp.firestore, collection_name, collection_id), data)
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const deleteItem = async (collection_name: string, collection_id: string) => {
  return deleteDoc(doc(FirebaseApp.firestore, collection_name, collection_id))
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const createItemWithCustomId = async (collection_name: string, custom_id: string, data: any) => {
  await setDoc(doc(FirebaseApp.firestore, collection_name, custom_id), data)
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @TODO Document this
 */
const getDocumentsCount = async (args: {
  collectionName: string
  queries: QueryFieldFilterConstraint[]
}) => {
  const collectionRef = collection(FirebaseApp.firestore, args.collectionName)
  const query_ = query(collectionRef, ...args.queries)
  const snapshot = await getCountFromServer(query_)
  return snapshot.data().count
}

const FirestoreService = {
  getItem,
  listItems,
  deleteItem,
  createItem,
  updateItem,
  filterItems,
  getItemsUsingIds,
  getDocumentsCount,
  createItemWithCustomId,
}

export default FirestoreService
