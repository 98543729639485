import { useContext, useEffect, useState } from 'react'

// Components
// Constants
import { DATA_FIRESTORE_V01_CONST } from '../../const/data/v01/firestore-v01-const'
import { PAPERWORK_DATA } from './data/tabs'

// Redux
import { useAppSelector } from '../../store/hooks'
import { selectUserId } from '../../store/user/userSlice'

// Services
import FirestoreService from '../../services/firestoreService'

// Firestore
import { documentId, getDocs, where } from 'firebase/firestore'

// Models
import helpers from '../../commonHelpers/helpers'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import { IMAGE_CONSTS } from '../../const/image-const'
import { HandleModalContext } from '../../layout/mainlayout/MainLayout'
import { IEventInterface } from '../../models/events/event.interface'
import { EventModel } from '../../models/events/event.model'
import { getConvertedData } from '../../models/interface.helper'
import { IUserDocument } from '../../models/user-documents/user-documents.interface'
import { UserDocumentModel } from '../../models/user-documents/user-documents.model'
import { IUserInterface } from '../../models/users/user.interface'
import { UserModel } from '../../models/users/user.model'
import PaperWorkHeader from './PaperWorkHeader'
import PaperWorkTabs from './PaperWorkTabs'
import PaperworkDataList from './component/PaperworkDataList'
import { cloneDeep } from 'lodash'
import { getUserFullName } from '../../helpers/helpers'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants
const COLLECTIONS = DATA_FIRESTORE_V01_CONST.COLLECTIONS

// Types
export type IPaperworkListingDoc = {
  document_name: string
  document_type: string
  event_name: string
  document_image: string
  downloadLink: string
  share: boolean
  sign: boolean
  document_logo: string
}

type IEventLogoHolder = {
  eventId: string | null
  eventLogo: string | null
  eventName: string | null
}

const PaperworkRootPage = () => {
  // Hooks and vars
  const userId = useAppSelector(selectUserId)
  const [tabs, setTabs] = useState({ ...PAPERWORK_DATA.TABS })
  const [signedDocs, setSignedDocs] = useState<IUserDocument[]>([])
  const [unSignedDocs, setUnSignedDocs] = useState<IUserDocument[]>([])
  const [ownerList, setownerList] = useState<any[]>([])
  const [allDocs, setAllDocs] = useState<IUserDocument[]>([])
  const [sharedDocs, setSharedDocs] = useState<IUserDocument[]>([])
  const [loading, setLoading] = useState(false)
  const [hasMore, sethasMore] = useState(false)
  const [eventTab, setEventTab] = useState('All')
  const [sortStyle, setSortStyle] = useState('Newest')
  const handleModalContext = useContext(HandleModalContext)

  const getDocsFromDb = async () => {
    try {
      setLoading(true)

      let userIdsList: string[] = []
      let EventIdsList: string[] = []
      let EventDocList: string[] = []
      let holder: {
        eventId: string | null
        ownerName: string | null
        ownerLegalPolicyName: string
        ownerId: string | null | undefined
      }[] = []
      let users: IUserInterface[] = []
      let shared: IUserDocument[] = []
      let signed: IUserDocument[] = []
      let owners: IUserInterface[] = []
      let events: IEventInterface[] = []
      let docsList: IUserDocument[] = []
      let unsighned: IUserDocument[] = []
      let user: IUserInterface | null = null
      let updatedDocsList: IUserDocument[]

      const perPage = 20
      const queryConstraints = (function () {
        return [where(COLLECTIONS.USERS_DOCUMENTS.FIELDS.COMPITETOR_ID.KEY, '==', userId)]
      })()

      const orderByDirection = sortStyle === 'Oldest' ? 'asc' : 'desc'

      let docs = await FirestoreService.filterItems(
        COLLECTIONS.USERS_DOCUMENTS.NAME,
        queryConstraints,
        perPage,
        COLLECTIONS.USERS_DOCUMENTS.FIELDS.CREATED.KEY,
        orderByDirection
      )

      if (!docs.size || docs.size < perPage) {
        sethasMore(false)
      } else {
        if (docs.size) {
          sethasMore(true)
        }
      }

      docs.forEach((currDoc) => {
        let document = UserDocumentModel.fromFirestoreDoc(currDoc).toObject()
        docsList.push(document)
      })

      updatedDocsList = await getDocsWithEventLogos(docsList)

      updatedDocsList.forEach((currDoc) => {
        if (currDoc.signatoryId) userIdsList.push(currDoc.signatoryId)

        if (currDoc.eventId) EventIdsList.push(currDoc.eventId)
      })

      const userSnaps = await FirestoreService.getItemsUsingIds(
        COLLECTIONS.USERS_DOCUMENTS.NAME,
        userIdsList
      )

      const eventSnaps = await FirestoreService.getItemsUsingIds(
        COLLECTIONS.EVENTS.NAME,
        EventIdsList
      )

      userSnaps.forEach((currDoc) => {
        users.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
      })

      eventSnaps.forEach((currDoc) => {
        events.push(getConvertedData(EventModel.fromFirestoreDoc(currDoc).toObject()))
      })

      events.forEach((event) => {
        if (event.owner) {
          EventDocList.push(event.owner)
        }
        holder.push({
          eventId: event.id,
          ownerId: event.owner,
          ownerName: null,
          ownerLegalPolicyName: '',
        })
      })

      const ownerSnaps = await FirestoreService.getItemsUsingIds(
        COLLECTIONS.USERS.NAME,
        EventDocList
      )

      ownerSnaps.forEach((currDoc) => {
        owners.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
      })

      const updated = holder.map((currdata) => {
        let match = owners.filter((data) => data.id === currdata.ownerId)
        if (match) {
          return {
            ...currdata,
            ownerName: match[0].userFirstName,
            ownerLegalPolicyName: match[0].userLegalPolicyName,
          }
        } else {
          return currdata
        }
      })

      if (!ownerList.length) {
        setownerList(updated)
      }

      updatedDocsList = updatedDocsList.map((currDoc) => {
        user = users.find((currUser) => currUser.id === currDoc.signatoryId) ?? null
        return {
          ...currDoc,
          ...(user && {
            signatoryName: user ? getUserFullName(user) : 'Unknown',
            signatoryProfilePicture: user.userProfilePicture,
          }),
        }
      })

      const registeredUsersByDaySnaps = await FirestoreService.filterItems(
        COLLECTIONS.REGISTRATION_BY_DAY.NAME,
        [where('eventId', 'in', EventIdsList), where('userId', '==', userId)]
      )

      const result = registeredUsersByDaySnaps.docs.map((doc) => doc.data())

      const filteredUpdatedDocList = updatedDocsList.filter((itm) => {
        const inItm = result.find(
          (resultItm) => itm.registrationDocId === resultItm.registrationDocId
        )
        if (inItm) {
          return inItm.paymentStatus === 'paid' || inItm.paymentStatus === 'cash'
        }
        return null
      })

      filteredUpdatedDocList.forEach((currDoc) => {
        if (currDoc.status === 'Signed') {
          signed.push(currDoc)
        } else if (currDoc.status === 'Not Signed') {
          unsighned.push(currDoc)
        } else if (currDoc.status === 'Shared') {
          shared.push(currDoc)
        }
      })

      setSignedDocs([...signed])
      setAllDocs([...filteredUpdatedDocList])
      setUnSignedDocs([...unsighned])
      setSharedDocs([...shared])
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      setLoading(false)
    }
  }
  /**
   * @info Gets the docs from the drafts table in db
   */
  useEffect(() => {
    getDocsFromDb().then()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs.active, userId, sortStyle])

  useEffect(() => {
    if (handleModalContext?.dataToPassOn?.status) {
      let foundOnIndex = -1
      let allDocs_ = cloneDeep(allDocs)
      let unSignedDocs_ = cloneDeep(unSignedDocs)
      let updatedData = handleModalContext?.dataToPassOn?.data as IUserDocument

      if (!updatedData) return

      foundOnIndex = unSignedDocs.findIndex((curr) => {
        return curr.id === updatedData.id
      })

      if (foundOnIndex !== -1)
        unSignedDocs_.splice(foundOnIndex, 1, {
          ...unSignedDocs_[foundOnIndex],
          status: updatedData.status,
          documentUrl: updatedData.documentUrl,
        })

      foundOnIndex = allDocs_.findIndex((curr) => {
        return curr.id === updatedData.id
      })

      if (foundOnIndex !== -1)
        allDocs_.splice(foundOnIndex, 1, {
          ...allDocs_[foundOnIndex],
          status: updatedData.status,
          documentUrl: updatedData.documentUrl,
        })

      setAllDocs(allDocs_)
      setUnSignedDocs(unSignedDocs_)
      setSignedDocs([...signedDocs, handleModalContext?.dataToPassOn?.data])
      handleModalContext.setDataToPassOn({ status: false })
    }
  }, [handleModalContext?.dataToPassOn, allDocs, signedDocs, unSignedDocs, handleModalContext])

  // Functions

  const handleActiveTab = (tabIndex: number) => {
    setTabs({ ...tabs, active: tabIndex })
  }

  const getDocsWithEventLogos = async (docs: IUserDocument[]) => {
    const CHUNK_SIZE = 10
    const MAX_LOOP_COUNT = docs.length

    let currLoopIndex = 0
    let updatedDocsCount = 0
    let eventIds: string[] = []
    let localEventIds: string[] = []
    let logos: IEventLogoHolder[] = []
    let localDocs: IUserDocument[] = []
    let updatedDocs: IUserDocument[] = []
    let foundEventHolder: IEventLogoHolder | null = null

    let eventLogo: string | null = null
    let localDoc: IUserDocument | null = null

    try {
      docs.forEach((currDoc) => {
        if (currDoc.eventId) eventIds.push(currDoc.eventId)
      })

      eventIds = [...new Set(eventIds)]

      while (updatedDocsCount < docs.length && updatedDocs.length !== docs.length) {
        if (currLoopIndex >= MAX_LOOP_COUNT) break

        localDocs = [...docs]
        localEventIds = [...eventIds]
        logos = await getEventLogos(localEventIds)

        localDocs = localDocs.splice(updatedDocsCount, CHUNK_SIZE)

        // eslint-disable-next-line no-loop-func
        localDocs.forEach((currLocalDoc) => {
          localDoc = { ...currLocalDoc }
          foundEventHolder =
            logos.find((currLogo) => currLogo.eventId === currLocalDoc.eventId) ?? null
          eventLogo = foundEventHolder?.eventLogo ?? IMAGE_CONSTS.PLACEHOLDERS.EVENT
          localDoc = {
            ...localDoc,
            eventLogo,
            eventName: foundEventHolder?.eventName ?? '',
          }
          updatedDocs.push(localDoc)
        })

        updatedDocsCount += CHUNK_SIZE
        localEventIds.splice(updatedDocsCount, CHUNK_SIZE)
        currLoopIndex++
      }

      // updatedDocs = updatedDocs
    } catch (error) {
      helpers.logger({
        isError: true,
        message: error,
      })
    } finally {
      return updatedDocs ?? []
    }
  }

  const getEventLogos = async (eventIds: string[]): Promise<IEventLogoHolder[]> => {
    let logos: IEventLogoHolder[] = []
    let eventList: IEventInterface[] = []
    let user: IUserInterface | null = null
    let event: IEventInterface | null = null

    const ownerIdsList: string[] = []
    const usersList: IUserInterface[] = []

    if (!eventIds.length) return logos

    // Fetchs events
    const eventSnaps = await FirestoreService.filterItems(COLLECTIONS.EVENTS.NAME, [
      where(documentId(), 'in', eventIds),
    ])

    eventSnaps.forEach((c) => {
      event = EventModel.fromFirestoreDoc(c).toObject()
      if (event.owner) {
        eventList.push(event)
        ownerIdsList.push(event.owner)
      }
    })

    if (!ownerIdsList.length) return logos

    // Fetchs users
    const usersSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS.NAME, [
      where(documentId(), 'in', ownerIdsList),
    ])

    usersSnaps.forEach((c) => {
      user = UserModel.fromFirestoreDoc(c).toObject()
      usersList.push(user)
    })

    // Combines
    eventList.forEach((currEvent) => {
      user = usersList.find((cu) => cu.id === currEvent.owner) ?? null
      if (!user) return
      logos.push({
        eventId: currEvent.id,
        eventName: currEvent.eventName ?? '',
        eventLogo: user?.userProfilePicture ?? null,
      })
    })

    return logos
  }

  const handleDataUpdate = (row: number, activeTab: string) => {
    switch (activeTab) {
      case 'All':
        setAllDocs((prev) => {
          let prev_ = [...prev]
          prev_.splice(row, 1, {
            ...prev[row],
            status: 'Signed',
          })
          return prev_
        })
        break
      case 'Unsigned':
        setUnSignedDocs((prev) => {
          let prev_ = [...prev]
          prev_.splice(row, 1, {
            ...prev[row],
            status: 'Not Signed',
          })
          return prev_
        })
        break
      case 'Received':
        setSharedDocs((prev) => {
          let prev_ = [...prev]
          prev_.splice(row, 1, {
            ...prev[row],
            status: 'Shared',
          })
          return prev_
        })
        break
    }
  }

  return (
    <WrapperContainer title="My Paperwork">
      <>
        <div className="w-full flex mb-4 flex-wrap">
          <PaperWorkHeader paid={signedDocs.length} Unpaid={unSignedDocs.length} />

          <PaperWorkTabs
            eventTab={eventTab}
            setEventTab={setEventTab}
            sortStyle={sortStyle}
            handleActiveTab={handleActiveTab}
            setSortStyle={setSortStyle}
          />

          <PaperworkDataList
            signedDocs={signedDocs}
            sharedDocs={sharedDocs}
            handleDataUpdate={handleDataUpdate}
            allDocs={allDocs}
            hasMore={hasMore}
            unsignedDocs={unSignedDocs}
            activeTab={eventTab}
            ownerList={ownerList}
            loadingElement={loading}
            getDocsFromDb={getDocsFromDb}
          />
        </div>
      </>
    </WrapperContainer>
  )
}

export default PaperworkRootPage
