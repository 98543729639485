// Third party
import * as yup from 'yup'

import { ILegalClauseInterface } from './user-legal-clauses.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export class UserLegalClausesModel
  extends ModelBaseModel<ILegalClauseInterface>
  implements IModelBaseModel
{
  public v: number
  public id: string | null
  public title: string
  public description: string
  public ownerId: string | null
  public created: Date | string | null
  public modified: Date | string | null

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: ILegalClauseInterface) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.ownerId = obj?.ownerId ?? null
    this.title = obj?.title ?? 'Sample Title'
    this.description = obj?.description ?? 'Clause Description'

    this.created = this.utcTimestamp({
      key: 'created',
      value: obj?.created ?? null,
      isTimestamp: true,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified ?? null,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new UserLegalClausesModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  public static validationSchema() {
    return {
      addClauseSchema: yup.object().shape({
        title: yup.string().required(),
        description: yup.string().required(),
      }),
    }
  }
}
