import React, { useContext, useState } from 'react'
import useToasterHelper from '../../helpers/ToasterHelper'
import MainModal from './common/MainModal'
import { HandleModalContext } from '../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../const/modal-const'
import { IRider } from '../../types/entryRider'
import { IconError } from '../icons/IconError'
import helpers from '../../commonHelpers/helpers'
import { IconClasses } from '../icons/IconClasses'
import { generateName } from '../../helpers/helpers'
import { IconPaid } from '../icons/IconPaid'
import { CONST } from '../../const/const'
import FirestoreService from '../../services/firestoreService'
import { httpService } from '../../services/httpService'
import { where } from 'firebase/firestore'
const UNKNOWN = 'Unknown'
const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS
const PAYMENT_STATUSES = COLLECTIONS.RECIPIENT.FIELDS.PAYMENT_STATUS.VALUES
type IProps = {
  dataToPassOn: {
    rider: IRider
    cb: (success: boolean) => void
  }
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const ScratchEntryModal = ({ dataToPassOn }: IProps) => {
  const { rider, cb } = dataToPassOn
  const [loading, setLoading] = useState(false)
  const handleModalContext = useContext(HandleModalContext)
  const toastFunctions = useToasterHelper()

  return (
    <MainModal
      customTitle={
        <div className="flex-1 mini:font-bold text-[25px] flex items-center gap-3 px-0">
          <div className={`!text-SeabiscuitDark200ThemeColor !font-normal`}>
            Scratch entry from show
          </div>
        </div>
      }
      show={true}
      closeButtonClassName="!right-[-20px]"
      modalClassName="!px-[38px] min-h-[60vh] !max-w-[639px]"
      type="SCRATCH_ENTRY"
      customPadding={true}
      className="mt-4"
      hideCloseButton={loading}
      setHeightAsPerContent={true}
      size="lg"
      buttons={[
        {
          label: 'REMOVE',
          loading: loading,
          disabled: loading,
          className: '!w-[100%]',
          bgClass: '!bg-SeabiscuitMainThemeColor !mt-0',
          onClick: async () => {
            setLoading(true)
            try {
              const customerData: any = await httpService({
                url: 'scratch_items',
                method: 'POST',
                data: {
                  userDocId: rider.userId,
                  recipientId: rider.recipientId,
                  registrationDocId: rider.registrationDocId,
                  scratchedRegistrationByDayItems: [
                    {
                      docId: rider.id,
                    },
                  ],
                },
              })
              console.log('=>(ScratchEntryModal.tsx:72) customerData', customerData)

              const snapshot = await FirestoreService.filterItems(
                CONST.DATA.FIRESTORE.V01.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
                [
                  where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', rider.eventId),
                  where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.RIDER_ID.KEY, '==', rider.riderId),
                  where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.HORSE_ID.KEY, '==', rider.horseId),
                ]
              )

              const promises: any = []

              // Проходим по каждому документу и добавляем его в батч для обновления
              snapshot.forEach((doc) => {
                console.log('=>(ScratchEntryModal.tsx:96) doc.id', doc.id)
                promises.push(
                  FirestoreService.updateItem(
                    CONST.DATA.FIRESTORE.V01.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
                    doc.id,
                    {
                      isScratched: true,
                      isSratched: true,
                    }
                  )
                )
              })

              await Promise.all(promises)

              cb(true)
              handleModalContext?.handleModal(false, MODAL_CONSTS.SCRATCH_ENTRY)
            } catch (e: any) {
              toastFunctions.error({
                message: e?.message || '',
              })
              cb(false)
              console.log('=>(BackNumber.tsx:34) e', e)
            } finally {
              setLoading(false)
            }
          },
          textClass: '!text-white uppercase',
          onHoverClass: 'hover:shadow-slate-300',
        },
        {
          label: 'CLOSE',
          className: '!w-[100%]',
          bgClass: '!bg-[#e8ecf2] border-0 !mt-2',
          onClick: () => handleModalContext?.handleModal(false, MODAL_CONSTS.SCRATCH_ENTRY),
          textClass: '!text-[#8597b3] uppercase',
          onHoverClass: 'hover:shadow-slate-300',
        },
      ]}
    >
      <div className={'flex flex-col gap-[30px] min-h-[41vh]'}>
        <div className={'flex flex-col gap-[10px]'}>
          <div className={'flex gap-[10px] items-center'}>
            <img
              alt="icons"
              onError={(e) =>
                ((e.target as any).src =
                  `https://ui-avatars.com/api/?name=${helpers.generateName(rider.riderName ?? UNKNOWN)}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
              }
              src={rider?.riderProfilePicture ?? ''}
              className="w-[45px] h-[45px] object-cover rounded-[6px]"
            />
            <div className={'flex-1 items-center'}>
              <p className={'text-[#8795a2] text-[12px]'}>Rider</p>
              <p className={'text-[#122B46] text-[16px]'}>{rider.riderName ?? UNKNOWN}</p>
            </div>
            <div className={'flex gap-[10px]'}>
              <div className={'text-right'}>
                <p className={'text-[#8795a2] text-[12px]'}>Classes</p>
                <p className={'text-[#122B46] text-[16px]  '}>
                  {rider.classes.length} class
                  {rider.classes.length > 1 || rider.classes.length === 0 ? 'es' : ''}
                </p>
              </div>
              <div
                className={
                  'flex bg-[#fff3f6] w-[45px] h-[45px] items-center justify-center rounded-[6px]'
                }
              >
                <IconClasses />
              </div>
            </div>
          </div>

          <div className={'flex gap-[10px] items-center'}>
            <img
              src={rider.horseProfilePicture ?? ''}
              alt="icons"
              className="w-[45px] h-[45px] object-cover rounded-[6px]"
              onError={(e) =>
                ((e.target as any).src =
                  `https://ui-avatars.com/api/?name=${generateName(rider.horseName ?? '')}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
              }
            />
            <div className={'flex-1 items-center'}>
              <p className={'text-[#8795a2] text-[12px]'}>Horse</p>
              <p className={'text-[#122B46] text-[16px]'}>{rider.horseName ?? 'N/A'}</p>
            </div>
            <div className={'flex gap-[10px]'}>
              <div className={'text-right'}>
                <p className={'text-[#8795a2] text-[12px]'}>Paid</p>
                <p className={'text-[#122B46] text-[16px]'}>
                  {rider.registrationPrice}
                  {/*${rider.paymentStatus === PAYMENT_STATUSES.PAID ? rider.registrationPrice : 0}*/}
                </p>
              </div>
              <div
                className={
                  'flex bg-[#fff3f6] w-[45px] h-[45px] items-center justify-center rounded-[6px]'
                }
              >
                <IconPaid />
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            'border-[#D3DAEE] flex gap-[14px] border-solid border py-[13px] px-[16px] rounded-[8px]'
          }
        >
          <div>
            <IconError />
          </div>
          <div className={'text-[#122B46] test-[14px] flex flex-col gap-[30px]'}>
            <p>
              When you click ‘remove’, this entry will be scratched from all registered classes.
              This includes their back number, Order of Go, and Scores.
            </p>
            <p>Other entry’s involving this rider and horse will not be affected.</p>
            <p>
              Any paperwork submitted during the registration process for this entry will not be
              deleted and will remain accessible.
            </p>
            <p>
              Refunds for this entry will not be automatically processed. You can refund the
              payments manually in the finances tab in line with your refund policy.
            </p>
          </div>
        </div>
      </div>
    </MainModal>
  )
}

export default ScratchEntryModal
