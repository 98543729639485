import { useEffect, useState } from 'react'

// Constants
import { UserDocumentModel } from '../../../../models/user-documents/user-documents.model'
import { CONST } from '../../../../const/const'

// Redux
import { useAppSelector } from '../../../../store/hooks'
import { selectUserId } from '../../../../store/user/userSlice'

// Services
import UserService from '../../../../services/userService'
import FirestoreService from '../../../../services/firestoreService'
import { IUserDocument } from '../../../../models/user-documents/user-documents.interface'

const useDocumentHook = () => {
  // Hooks and vars
  const userId = useAppSelector(selectUserId)
  const [mUserDocData, setMUserDocData] = useState<IUserDocument | undefined>(undefined) // Modelled user document
  const [loading, setLoading] = useState(false)

  // Functions
  const getModelledUserDocument = async () => {
    const user_doc = await UserService.getUserDocumentDataByID(userId)
    const structuredData = UserDocumentModel.fromFirestoreDoc(user_doc).toObject()
    return structuredData
  }

  const updateUserDocument = async (
    dataToUpdate: any
  ): Promise<{ status: boolean; message?: any }> => {
    setLoading(true)
    try {
      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS_DOCUMENTS.NAME,
        userId,
        dataToUpdate
      )
      setLoading(false)
      return { status: true }
    } catch (err) {
      setLoading(false)
      return {
        status: false,
        message: err,
      }
    }
  }

  useEffect(() => {
    if (userId) {
      const setUserDocumentFn = async () => {
        const data = await getModelledUserDocument()
        setMUserDocData(data)
      }
      setUserDocumentFn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return { mUserDocData, updateUserDocument, loading }
}

export default useDocumentHook
