import { Close } from '@mui/icons-material'
import clsx from 'clsx'
import { sendPasswordResetEmail } from 'firebase/auth'
import { useState } from 'react'
import FIREBASE_CONST from '../../const/firebase-const'
import { MODAL_CONSTS } from '../../const/modal-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import FirebaseApp from '../../services/firebaseApp'

type Props = {
  show?: boolean
  handleModal?: any
}

const ForgotPassword = (props: Props) => {
  //hooks and vars
  const [email, setEmail] = useState<string>('')
  const setLoading = useState<boolean>(false)[1]
  const [err, setErr] = useState<boolean>(false)
  const toastFunction = useToasterHelper()

  const handleChange = (e: any) => {
    setEmail(e.target.value)

    // eslint-disable-next-line no-useless-escape
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
      return setErr(false)
    }
    return setErr(true)
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      if (!err) {
        await sendPasswordResetEmail(FirebaseApp.auth, email)
        props.handleModal(false, MODAL_CONSTS.FORGOT_PASSWORD)
        toastFunction.success({ message: 'Email sent successfully.' })
      }
      setLoading(false)
    } catch (error: any) {
      if (error.code === FIREBASE_CONST.USER_NOT_FOUND) {
        setLoading(false)
        return toastFunction.error({
          message: "This account doesn't exist. Please sign up or try again",
        })
      }
      setLoading(false)

      return toastFunction.error({ message: 'Failed to send password reset email.' })
    }
  }

  return (
    <>
      <div
        className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${props.show ? 'show d-block backShadow' : 'hidden'}`}
        id="exampleModalCenter"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered relative lg:w-2/5 xl:w-1/3 m-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pb-10 pt-4 px-8">
            <span
              onClick={() => props.handleModal(false, MODAL_CONSTS.FORGOT_PASSWORD)}
              className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
            >
              <Close className="text-[#122B46]" />
            </span>

            <div className="modal-header rounded-t-md font-Poppins">
              <h5
                className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal"
                id="exampleModalScrollableLabel"
              >
                Reset Your Password
              </h5>
              <p className="text-sm leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2">
                {' '}
                The verification email will be sent to the mailbox.
              </p>

              <input
                name="email"
                value={email}
                className={clsx(
                  'flex-1  outline-0 !text-[#122B46] bg-transparent mt-2 placeholder:text-SeabiscuitGray500ThemeColor focus:ring-transparent w-full focus:border-SeabiscuitGray500ThemeColor rounded-lg',
                  err ? 'border-SeabiscuitMainThemeColor' : 'border-SeabiscuitGray500ThemeColor'
                )}
                type="text"
                onChange={handleChange}
                placeholder="Enter email address..."
              />

              {err ? (
                <p className="text-SeabiscuitMainThemeColor">Email must be a valid email</p>
              ) : null}

              <div className="mt-20">
                <button
                  onClick={handleSubmit}
                  className="w-1/2 h-12 mx-auto flex items-center justify-center py-2 border border-transparent rounded-xl shadow-sm text-sm font-medium text-white bg-[#F7074F] focus:outline-none mt-2"
                >
                  Send Email
                </button>

                <button
                  onClick={() => {
                    props?.handleModal(false, MODAL_CONSTS.FORGOT_PASSWORD)
                  }}
                  className="w-1/2 h-12 mx-auto flex items-center justify-center py-2 border border-transparent rounded-xl shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor focus:outline-none mt-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
