import { createAsyncThunk } from '@reduxjs/toolkit'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { CONST } from '../../../const/const'
import { IHorseData } from '../../../models/horse/horse.interface'
import FirestoreService from '../../../services/firestoreService'
import { HorseModel } from '../../../models/horse/horse.model'
import { IUserHorseMappingInterface } from '../../../models/user-horse-mapping/userHorseMapping.interface'
import { UserHorseMappingModel } from '../../../models/user-horse-mapping/userHorseMapping.model'
import { getConvertedData } from '../../../models/interface.helper'
import { RootState } from '../../store'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

type IGetHorseViaMappingDocIdThunk = {
  req: {
    horseMappingDocId: string
  }
}

const getHorseViaMappingDocIdThunk = createAsyncThunk(
  'getHorseViaMappingDocIdThunk',
  async (payload: IGetHorseViaMappingDocIdThunk['req'], thunkApi) => {
    let message: string | null = null
    let horse: IHorseData | null = null
    let mapping: IUserHorseMappingInterface | null = null
    let state = thunkApi.getState() as RootState

    try {
      const horseMappingSnapshot = await FirestoreService.getItem(
        COLLECTIONS.USER_HORSE_MAPPING.NAME,
        payload.horseMappingDocId
      )

      if (!horseMappingSnapshot.exists()) {
        throw new Error('Horse not found')
      }

      mapping = getConvertedData(
        UserHorseMappingModel.fromFirestoreDoc(horseMappingSnapshot).toObject()
      )

      if (!mapping.horseId) {
        throw new Error('Horse not found')
      }

      const horseSnapshot = await FirestoreService.getItem(COLLECTIONS.HORSES.NAME, mapping.horseId)

      if (horseSnapshot.exists()) {
        horse = getConvertedData(HorseModel.fromFirestoreDoc(horseSnapshot).toObject())
      } else {
        throw new Error('Horse not found')
      }

      return {
        horse,
        mapping,
        isMyHorse: state.user.userId === horse.horseOwnerId,
      }
    } catch (error: any) {
      message = error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG
      return thunkApi.rejectWithValue({
        message,
      })
    }
  }
)

export { getHorseViaMappingDocIdThunk }
