import { CONST } from '../../const/const'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'

import { IUserHorseMappingInterface } from './userHorseMapping.interface'

// Constants
const HORSE_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES
const MAPPING_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_HORSE_MAPPING

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @info The doc specifies that which horse is linked with which persons
 */
export class UserHorseMappingModel
  extends ModelBaseModel<IUserHorseMappingInterface>
  implements IModelBaseModel
{
  // Properties
  public v: IUserHorseMappingInterface['v']
  public id: IUserHorseMappingInterface['id']
  public userId: IUserHorseMappingInterface['userId']
  public horseId: IUserHorseMappingInterface['horseId']
  public isMyHorse: IUserHorseMappingInterface['isMyHorse']
  public horseName: IUserHorseMappingInterface['horseName']
  public horseZone: IUserHorseMappingInterface['horseZone']
  public horseOwnerId: IUserHorseMappingInterface['horseOwnerId']
  public horseNameNGram: IUserHorseMappingInterface['horseNameNGram']
  public horseDiscipline: IUserHorseMappingInterface['horseDiscipline']
  public horseProfilePicture: IUserHorseMappingInterface['horseProfilePicture']
  public horseSelectedForCompeletion: IUserHorseMappingInterface['horseSelectedForCompeletion']

  public created: IUserHorseMappingInterface['created']
  public modified: IUserHorseMappingInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @param obj `IUserHorseMappingInterface`
   */
  constructor(obj?: IUserHorseMappingInterface) {
    super()
    this.v = obj?.v ?? MAPPING_COLLECTION.FIELDS.V.VALUES.DEFAULT
    this.id = obj?.id ?? MAPPING_COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.userId = obj?.userId ?? MAPPING_COLLECTION.FIELDS.USER_ID.VALUES.DEFAULT
    this.horseId = obj?.horseId ?? MAPPING_COLLECTION.FIELDS.HORSE_ID.VALUES.DEFAULT
    this.isMyHorse = obj?.isMyHorse ?? MAPPING_COLLECTION.FIELDS.IS_MY_HORSE.VALUES.DEFAULT
    this.horseName = obj?.horseName ?? HORSE_COLLECTION.FIELDS.HORSE_NAME.VALUES.DEFAULT
    this.horseZone = obj?.horseZone ?? HORSE_COLLECTION.FIELDS.HORSE_ZONE.VALUES.DEFAULT
    this.horseZone = obj?.horseZone ?? HORSE_COLLECTION.FIELDS.HORSE_ZONE.VALUES.DEFAULT
    this.horseDiscipline =
      obj?.horseDiscipline ?? HORSE_COLLECTION.FIELDS.HORSE_DISCIPLINE.VALUES.DEFAULT
    this.horseNameNGram =
      obj?.horseNameNGram ?? HORSE_COLLECTION.FIELDS.HORSE_NAME_N_GRAM.VALUES.DEFAULT
    this.horseOwnerId = obj?.horseOwnerId ?? MAPPING_COLLECTION.FIELDS.HORSE_OWNER_ID.VALUES.DEFAULT
    this.horseProfilePicture =
      obj?.horseProfilePicture ?? HORSE_COLLECTION.FIELDS.HORSE_PROFILE_PICTURE.VALUES.DEFAULT
    this.horseSelectedForCompeletion =
      obj?.horseSelectedForCompeletion ??
      MAPPING_COLLECTION.FIELDS.HORSE_SELECTED_FOR_COMPETETION.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // Methods

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new UserHorseMappingModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
