import { useState } from 'react'

import { useIonRouter } from '@ionic/react'

// Components

// Constants

// Third party
import clsx from 'clsx'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { SETTINGS_TABS_OBJ } from '../../../pages/profile/root/ProfileRootPageEditProfile'
import MainModal from './MainModal'
import { useHistory } from 'react-router-dom'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IOrganizerEmailInfo = {
  show: boolean
  dataToPassOn: any
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

// Constanst
const BUTTON_LIST = [
  {
    description: 'Close events',
    alt: 'View event icon',
    icon: 'assets/cp_icons/Calendar.svg',
    activeIcon: 'assets/cp_icons/Calendar-4.svg',
    title: 'Close your live events',
  },
  {
    description: 'Transfer cash',
    alt: 'Create event icon',
    icon: 'assets/og_icons/MagneticCard.svg',
    activeIcon: 'assets/cp_icons/Magnetic Card-4.svg',
    title: 'Transfer cash to your bank account',
  },
]

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const OrganizerEmailInfo = (props: IOrganizerEmailInfo) => {
  // Hooks and vars
  const { dataToPassOn } = props

  const router = useIonRouter()
  const history = useHistory()
  const loading = useState(false)[0]
  const [activeButtonIndex, setActiveButtonIndex] = useState(0)

  const title = 'Cannot change account email'

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const closeModal = () => {
    props?.handleModal(false, MODAL_CONSTS.OPEN_ORGANIZER_MAIL_INFO)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleClick = () => {
    if (activeButtonIndex === 0) {
      router.push('/')
      history.push('/')
    } else if (activeButtonIndex === 1) {
      props.dataToPassOn.setActiveTab(SETTINGS_TABS_OBJ.FINANCE)
    }
    closeModal()
    return 0
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */

  return (
    <MainModal
      size="md"
      title={title}
      show={props.show}
      onHide={closeModal}
      type="MANAGE_EVENT_MODAL"
      // parentClasses="h-full"
      buttons={[
        {
          loading,
          label: 'Next >',
          onClick: handleClick,
          bgClass: 'bg-white',
          textClass: 'text-SeabiscuitMainThemeColor',
          onHoverClass: 'hover:bg-SeabiscuitMainThemeColor hover:text-white',
        },
      ]}
    >
      <div className={'h-full'}>
        <div className="text-nr my-3 mb-[25px]">
          Close all your events and transfer all funds out of your Pegasus account to update your
          account email
        </div>

        <div className="grid grid-cols-2 gap-4">
          {BUTTON_LIST.map((currButton, index) => {
            let active = activeButtonIndex === index

            if (dataToPassOn?.canAccountBeRemoved === true && index === 1) {
              return ''
            } else if (dataToPassOn?.checkEvents === 0 && index === 0) {
              return ''
            }

            return (
              <button
                key={JSON.stringify(currButton)}
                onClick={() => setActiveButtonIndex(index)}
                className={clsx(
                  'flex-col p-3 rounded-lg border border-solid',
                  active
                    ? 'text-white bg-SeabiscuitMainThemeColor border-SeabiscuitMainThemeColor'
                    : 'text-SeabiscuitLightParagraphTextColor text-opacity-5 border-SeabiscuitLightParagraphTextColor border-opacity-[20%] '
                )}
              >
                <img
                  src={active ? currButton.activeIcon : currButton.icon}
                  alt="createIcon"
                  className="mb-1 w-8 h-8"
                />

                <div className="italic text-[10px] mb-1 text-start">{currButton.description}</div>

                <div className="text-nr text-start">{currButton.title}</div>
              </button>
            )
          })}
        </div>
      </div>
    </MainModal>
  )
}

export default OrganizerEmailInfo
