export const myEventsTableStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
      paddingTop: '15px',
      paddingBottom: '15px',
      '&:not(:last-of-type)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#D3DAEE',
      },
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '9px',
      color: '#122B46',
      fontWeight: 'bold',
      fontSize: '12px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
      Text: 'center',
    },
  },
}
