/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from 'react'
import { SignupStages } from './UserSignupViewComponentContextApi'
import UserSignupViewComponentButton from './global/UserSignupViewComponentButton'

import backIcon from '../assets/arrow-left.svg'
import eventOrgIcon from '../assets/event-org.png'

const UserSignupViewComponentCompetitorOrganizerVerify: React.FC = () => {
  const [stage, setStage] = useContext(SignupStages)

  const stageHandle = (value: string) => {
    setStage({ ...stage, stage: value })
  }

  return (
    <div>
      <div className="text-[24px] flex items-center gap-2 text-white w-[500px]">
        <button onClick={() => stageHandle('Init')}>
          <img src={backIcon} alt="back" className="invert" />
        </button>
        {/* COMPETITOR, TRAINER, SPECTATOR, ETC. */}
      </div>
      <div className="mt-10">
        <img src={eventOrgIcon} alt="icon" className="rounded-full shadow-xl mx-auto" />
      </div>
      <div className="mt-10 flex gap-4 items-center border-[#D3DAEE] border-solid border-[1px] p-3 rounded-xl">
        <div className="min-w-[25px]">
          <img src="/assets/og_icons/Mail-4.svg" alt="email" className="w-full" />
        </div>
        <div className="text-white">
          <p>
            A verification email was sent to the email address on file with the USEF for the
            membership number you entered.
          </p>
          <p className="mt-4">Click the link the email to continue.</p>
        </div>
      </div>
      <div className="w-[70%] mt-16 mx-auto">
        <UserSignupViewComponentButton
          caption="OPEN MAIL APP"
          kind={1}
          disabled={!stage.selector}
        />
      </div>
      <div className="text-center text-white opacity-50 mt-10">OR</div>
      <div className="my-10 text-center text-white">
        Don’t have a USEF number?{' '}
        <a
          className="text-SeabiscuitMainThemeColor cursor-pointer"
          onClick={() => stageHandle('SignUp')}
        >
          Continue &gt;
        </a>
      </div>
    </div>
  )
}

export default UserSignupViewComponentCompetitorOrganizerVerify
