// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import EventOrganizationFinancesGraphComponent from '../../../components/events/graphs/organization-finances/EventOrganizationFinancesGraphComponent'

const exhibitor_sales_history = [1.9, 2, 4.3, 1.5, 2, 3.1, 1.5, 2, 2, 2, 0, 3]
const spectator_sales_history = [1.9, 3, 3.4, 0, 0, 0, 1, 1.5, 2.0, 3.0, 3.4, 4]
const vendor_sales_history = [1.9, 3, 3.4, 2.2, 2.9, 3.9, 2.5, 3.8, 4.1, 3.8, 3.2, 2.9]
const sponsor_sales_history = [0, 0, 0, 0, 0, 1.0, 3.5, 3.8, 4.1, 3.0, 1.0, 0.7]

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsHomePageFinancesGraphsSubSection: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-2">
      <EventOrganizationFinancesGraphComponent
        title="Exhibitor Sales History"
        data={exhibitor_sales_history}
      />

      <EventOrganizationFinancesGraphComponent
        title="Spectator Sales History"
        data={spectator_sales_history}
      />

      <EventOrganizationFinancesGraphComponent
        title="Vendor Sales History"
        data={vendor_sales_history}
      />

      <EventOrganizationFinancesGraphComponent
        title="Sponsor Sales History"
        data={sponsor_sales_history}
      />
    </dl>
  )
}

export default ShowsHomePageFinancesGraphsSubSection
