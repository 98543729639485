import React, { useEffect, useState } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useParams } from 'react-router'
import helpers from '../../../commonHelpers/helpers'
import EventPaymentFormComponent from '../../../components/events/forms/payments/EventPaymentFormComponent'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { CustomError } from '../../../helpers/helpers'
import { IEventPaymentSettingsInterface } from '../../../models/event-payment-settings/event-payment-settings.interface'
import EventPaymentSettingsModel from '../../../models/event-payment-settings/event-payment-settings.model'
import FirebaseService from '../../../services/firebase-service'

interface IShowDetailPageEventPaymentSettingsSubSection {
  onSetEventTab: (tab: string) => void
  onSetNextEventTab: (tab: string) => void
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn?: any
  mainEvent?: any
  nextEventTab: string
  eventTab: string
}

const FILE_NAME = 'ShowDetailPageEventPaymentSettingsSubSection'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @info Payment settings sub section
 * @param {IShowDetailPageEventPaymentSettingsSubSection} props
 */
const ShowDetailPageEventPaymentSettingsSubSection: React.FC<
  IShowDetailPageEventPaymentSettingsSubSection
> = (props: IShowDetailPageEventPaymentSettingsSubSection) => {
  const eventId = useParams<{ eventId: string }>().eventId

  const [loading, setLoading] = useState(true)
  const [showErrors, setShowErrors] = useState(false)
  const [data, setData] = useState<IEventPaymentSettingsInterface | null>(null)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    getEventPaymentSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setData, eventId])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async function getEventPaymentSettings() {
    try {
      if (data) return

      const { eventPaymentSettings } = await FirebaseService.EventPaymentSettingsService.get({
        docId: eventId,
      })

      setData(eventPaymentSettings)
    } catch (error: any) {
      const { eventPaymentSettings } = await FirebaseService.EventPaymentSettingsService.create({
        id: eventId,
        data: new EventPaymentSettingsModel().toFirestore(),
      })

      setData(eventPaymentSettings)

      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'getEventPaymentSettings',
          devMessage: error,
        }),
      })
    } finally {
      setLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onInvalidAndExit = () => {
    setShowErrors(!!Object.keys(data ?? {}).length)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const validFormHandler = async (data: IEventPaymentSettingsInterface) => {
    let updated = true
    try {
      await FirebaseService.EventPaymentSettingsService.update({
        docId: eventId,
        fieldsToUpdate: data,
      })
    } catch (error: any) {
      updated = false
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'validFormHandler',
          devMessage: error,
        }),
      })
    } finally {
      return {
        onSetEventTab: props.onSetEventTab,
        updated,
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const invalidFormHandler = (errors: FieldErrors<IEventPaymentSettingsInterface>) => {
    if (Object.keys(errors).length > 0) {
      setShowErrors(true)
    }
  }

  return (
    <EventPaymentFormComponent
      data={data}
      loading={loading}
      showErrors={showErrors}
      onInvalidAndExit={onInvalidAndExit}
      validFormHandler={validFormHandler}
      invalidFormHandler={invalidFormHandler}
      nextEventTab={props.nextEventTab}
      eventTab={props.eventTab}
    />
  )
}

export default ShowDetailPageEventPaymentSettingsSubSection
