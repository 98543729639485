const ICONS = [
  '/assets/cp_icons/Name Tag.svg',
  '/assets/og_icons/Biotech.svg',
  '/assets/og_icons/Ruler Vertical.svg',
  '/assets/og_icons/Time.svg',
  '/assets/og_icons/Gender.svg',
  '/assets/og_icons/YearofHorse.svg',
  '/assets/og_icons/Mind Map.svg',
  '/assets/og_icons/Paint Palette.svg',
  '/assets/og_icons/Paint Brush.svg',
]

const ACTIVE_TAB_SECTION_CONST = {
  ICONS,
}

export default ACTIVE_TAB_SECTION_CONST
