import React from 'react'
import HorsesList from '../common/table/HorsesManage/components/HorsesList/HorsesList'
import { IHorseData } from '../../../../models/horse/horse.interface'

interface IProps {
  eventId: string
  horses: IHorseData[]
}
export const ManageClinicHorses: React.FC<IProps> = ({ horses }) => {
  // const { loading, horses } = useHorse(eventId)

  return <HorsesList isFullScreen={false} loading={false} searchValue={''} horses={horses} />
}
