import { HighlightOff } from '@mui/icons-material'
import clsx from 'clsx'
import { useRef } from 'react'

type IProps = {
  icon?: string
  values: string[]
  removable: boolean
  className?: string
  disabled?: boolean
  onClick?: (e?: any) => void
  onInputChange: (files: FileList | null) => any
  onRemoveImageButtonClick?: (index: number) => void
}

const ImageContainer: React.FC<IProps> = ({
  icon,
  values,
  onClick,
  disabled,
  removable,
  className,
  onInputChange,
  onRemoveImageButtonClick = (index: number) => {},
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const onAddImageButtonClick = (e: any) => {
    fileInputRef.current?.click()
    onClick?.(e)
  }

  return (
    <div
      className={clsx(
        `h-fit text-gray-500 flex items-center gap-2 w-full px-3 py-2 border-solid rounded-md border-SeabiscuitLightThemeColorD3 border-[1px] bg-white`,
        className
      )}
    >
      {values?.length || icon ? (
        <div className="flex gap-2 items-center max-w-[calc(100%_-_57px)]">
          {icon ? (
            <span className="min-w-[22px]">
              <img src={icon} alt="icon" />
            </span>
          ) : null}

          {values?.length ? (
            <span className="flex flex-wrap gap-2 items-center">
              {values?.map((item, index) => (
                <div
                  key={`${item}${index}`}
                  className="w-[39px] h-[39px] overflow-hidden rounded-lg inline-block relative"
                >
                  {removable ? (
                    <button
                      type="button"
                      onClick={() => onRemoveImageButtonClick(index)}
                      className="absolute w-full h-full left-0 top-0 opacity-0 cursor-pointer hover:opacity-100 z-[1] transition-all"
                    >
                      <HighlightOff className="text-SeabiscuitMainThemeColor" />
                    </button>
                  ) : null}
                  <img src={item} alt="cover" className="w-full h-full object-cover" />
                </div>
              ))}
            </span>
          ) : null}
        </div>
      ) : null}

      <input
        type="file"
        onChange={(e) => onInputChange?.(e.target.files)}
        multiple={true}
        accept="image/*"
        ref={fileInputRef}
        className="hidden"
        disabled={disabled}
      />

      {values?.length ? (
        <button
          onClick={onAddImageButtonClick}
          className="bg-gray-100 rounded-lg w-[39px] h-[39px]"
        >
          +
        </button>
      ) : (
        <div
          role="button"
          onClick={onAddImageButtonClick}
          className="flex items-center gap-2 justify-center w-full cursor-pointer"
        >
          <div className="relative">
            <img
              alt="tag"
              src="/assets/og_icons/Image.svg"
              className="w-7 h-7 shrink-0 rounded-md"
            />
            <div className="absolute right-[3px] bottom-[3px]">
              <div className="bg-SeabiscuitLightParagraphTextColor relative text-[10px] text-white rounded-full block h-[10px] w-[10px]">
                <span className="absolute top-[-2px] right-[2px]">+</span>
              </div>
            </div>
          </div>
          <div className="text-SeabiscuitLightParagraphTextColor text-nr">Add images</div>
        </div>
      )}
    </div>
  )
}

export default ImageContainer
