// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import EventPrizeListFormBuilderUtilDivisionRow from '../division/EventPrizeListFormBuilderUtilDivisionRow'
import EventPrizeListFormBuilderUtilMainRowAccordionComponent from './EventPrizeListFormBuilderUtilMainRowAccordionComponent'
import EventPrizeListFormBuilderUtilMainRowAccordionSummaryComponent from './EventPrizeListFormBuilderUtilMainRowAccordionSummaryComponent'
import EventPrizeListFormBuilderUtilMainRowAccordionDetailsComponent from './EventPrizeListFormBuilderUtilMainRowAccordionDetailsComponent'
import EventPrizeListFormBuilderUtilMainRowHeader from '../headers/EventPrizeListFormBuilderUtilMainRowHeader'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventPriceListFormBuilderUtilMainRowProps {
  item: any
  disciplineIndex: number
  register: any
  divisionRowRefs: any
  sectionsRowRefs: any
  classesRowRefs: any
  children?: ReactNode
  errors: any
  control: any
  setValue: any
  getValues: any
  setError: any
  clearErrors: any
  trigger: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormBuilderUtilMainRow = forwardRef<
  any,
  EventPriceListFormBuilderUtilMainRowProps
>((props, mainRef) => {
  const divisionRowRefs: React.MutableRefObject<any[]> = useRef<any[]>([])

  const [isExpanded, setIsExpanded] = useState(false)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const handleOnAccordionExpansionToggle = useCallback((_: any) => {
    setIsExpanded((prevState) => !prevState)
  }, [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const expandRow = () => {
    setIsExpanded(true)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const collapseRow = () => {
    setIsExpanded(false)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useImperativeHandle(mainRef, () => ({ expandRow, collapseRow }), [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    divisionRowRefs.current = divisionRowRefs.current.slice(0, props.item.divisions?.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <EventPrizeListFormBuilderUtilMainRowAccordionComponent
        expanded={isExpanded}
        onChange={handleOnAccordionExpansionToggle}
      >
        <EventPrizeListFormBuilderUtilMainRowAccordionSummaryComponent isExpanded={isExpanded}>
          <label className="text-[22px] font-black mr-4 text-SeabiscuitDark200ThemeColor">
            {props.item.discipline}
          </label>
        </EventPrizeListFormBuilderUtilMainRowAccordionSummaryComponent>
        <EventPrizeListFormBuilderUtilMainRowAccordionDetailsComponent>
          <EventPrizeListFormBuilderUtilMainRowHeader />
          {props.item.divisions.map((division: any, index: any) => {
            return (
              <EventPrizeListFormBuilderUtilDivisionRow
                key={division.divisionCode}
                disciplineIndex={props.disciplineIndex}
                division={division}
                divisionIndex={index}
                register={props.register}
                errors={props.errors}
                control={props.control}
                getValues={props.getValues}
                setValue={props.setValue}
                setError={props.setError}
                clearErrors={props.clearErrors}
                trigger={props.trigger}
              />
            )
          })}
        </EventPrizeListFormBuilderUtilMainRowAccordionDetailsComponent>
      </EventPrizeListFormBuilderUtilMainRowAccordionComponent>
    </>
  )
})

export default EventPrizeListFormBuilderUtilMainRow
