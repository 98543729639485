import { yupResolver } from '@hookform/resolvers/yup'
import { useIonRouter } from '@ionic/react'
import { Tooltip } from '@mui/material'
import clsx from 'clsx'
import { where } from 'firebase/firestore'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import helpers from '../../../commonHelpers/helpers'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import MessageHelperComp from '../../../helpers/MessageHelper'
import useToasterHelper from '../../../helpers/ToasterHelper'
import TooltipIcon from '../../../helpers/TooltipIcon'
import { IAssignedTicket } from '../../../models/assigned-tickets/assigned-tickets.interface'
import { AssignTicketsModal } from '../../../models/assigned-tickets/assigned-tickets.model'
import { IEventDetails } from '../../../models/event-details/event-details.interface'
import { TicketType } from '../../../models/event-ticketing/event-ticketing.interface'
import { getConvertedData } from '../../../models/interface.helper'
import { ISpectatorTickets } from '../../../models/spectator-tickets/spectator-tickets.interface'
import FirestoreService from '../../../services/firestoreService'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  selectSpectatorTickets,
  setSpectatorAssignTickets,
  setSpectatorEventData,
  setSpectatorPayTabTickets,
} from '../../../store/tickets/ticketslice'
import { selectIsLoggedIn, selectUserId } from '../../../store/user/userSlice'
import SpectatorTickesPurchaseWrapper from '../SpectatorTickesPurchaseWrapper'
import { cloneDeep } from 'lodash'
import { SpectatorTicketModel } from '../../../models/spectator-tickets/spectator-tickets-model'
import {useHistory} from "react-router-dom";

type Props = {
  EventDetails: IEventDetails
  EventTickets: TicketType[]
  saveRef: any
  nextRef: any
  onSetEventTab: any
  setLoading: any
}

type IDataToRenderItem = IAssignedTicket & {
  id_: IAssignedTicket['id']
}

const table_headings = [
  {
    col_id: 1,
    name: 'Ticket item',
    tooltip: 'Tickets you have selected to purchase',
    styleclass: 'w-[25%] min-w-[290px] makegloballabelcolor',
  },
  {
    col_id: 2,
    name: 'Name on ticket',
    tooltip: 'Enter the full name of the individual that will use this ticket',
    styleclass: 'w-[37.5%] min-w-[270px] makegloballabelcolor',
  },
  {
    col_id: 3,
    name: 'Email address',
    tooltip: 'Enter the email address you want this ticket to be sent too',
    styleclass: 'w-[37.5%] min-w-[270px] makegloballabelcolor',
  },
]

const nonLoggedInUserSchema = yup.object({
  assignedTickets: yup.array().of(
    yup
      .object()
      .shape({
        ticketHolderName: yup
          .string()
          .transform((value) => {
            return value?.trim()
          })
          .required('Name is required for guests')
          .typeError('Name is required for guests'),
        ticketHolderEmailId: yup
          .string()
          .email('Invalid email')
          .required('Email is required for guests')
          .typeError('Email is required for guests'),
      })
      .required('Assigned tickets are required')
  ),
})

const loggedInUserSchema = yup.object({
  assignedTickets: yup
    .array()
    .of(
      yup.object().shape({
        ticketHolderName: yup
          .string()
          .transform((value) => {
            return value?.trim()
          })
          .test('name-condition', 'Name is required', function (value) {
            const email = this.parent.ticketHolderEmailId
            return !!email ? !!value : true
          })
          .nullable(),
        ticketHolderEmailId: yup
          .string()
          .email('Invalid email')
          .test('email-condition', 'Email is required', function (value) {
            const name = this.parent.ticketHolderName
            return !!name ? !!value : true
          })
          .nullable(),
      })
    )
    .required(),
})

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const ASSIGNED_TICKETS = COLLECTIONS.ASSIGNED_TICKETS

const SpeactatorTicketsPuchaseEmailTab = (props: Props) => {
  const { saveRef, nextRef, onSetEventTab } = props

  const router = useIonRouter()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const userId = useAppSelector(selectUserId)
  const guest_id = localStorage.getItem('guest_id')
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const spectatorTickets = useAppSelector(selectSpectatorTickets)

  const [assigned_user] = useState(false)
  const [loading, setLoading] = useState(false)

  const toastFunction = useToasterHelper()
  const {
    reset,
    setValue,
    formState: { errors },
    watch,
    control,
    register,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      assignedTickets: [] as IDataToRenderItem[],
      isGuest: false,
    },
    resolver: yupResolver(isLoggedIn ? loggedInUserSchema : nonLoggedInUserSchema),
  })

  const assignedTicketFieldArray = useFieldArray({
    name: 'assignedTickets',
    control,
  })

  const generateTickets = useCallback(
    ({
      spectatorTickets,
      assignedTickets,
    }: {
      spectatorTickets: ISpectatorTickets[]
      assignedTickets: IAssignedTicket[]
    }) => {
      reset({
        assignedTickets: assignedTickets,
      })
      dispatch(setSpectatorAssignTickets(assignedTickets))
    },
    [dispatch, reset]
  )

  const fetchTickets = useCallback(
    async (spectatorTickets: ISpectatorTickets[]) => {
      setLoading(true)
      const assignedTickets: IAssignedTicket[] = []

      let finaltickets: IAssignedTicket[] = []
      try {
        await helpers.asyncWhileLoop({
          loopCount: spectatorTickets.length,
          functionToFire: async (index) => {
            const assignedTicketSnapshots = await FirestoreService.filterItems(
              COLLECTIONS.ASSIGNED_TICKETS.NAME,
              [
                where(
                  COLLECTIONS.ASSIGNED_TICKETS.FIELDS.SEPECTATOR_TICKET_DOC_ID,
                  '==',
                  spectatorTickets[index].id!
                ),
              ]
            )

            assignedTicketSnapshots.forEach((currAssignedTicketSnapshot) => {
              assignedTickets.push(
                getConvertedData(
                  AssignTicketsModal.fromFirestoreDoc(currAssignedTicketSnapshot).toObject()
                )
              )
            })
          },
        })

        const NewassignedTickets: IAssignedTicket[] = assignedTickets.length
          ? assignedTickets.map((data) => {
              return { ...data }
            })
          : []
        if (!finaltickets.length) {
          spectatorTickets.forEach((currSpectatorTicket) => {
            let currcount = currSpectatorTicket.selectedUnitsCount

            if (NewassignedTickets.length) {
              NewassignedTickets.forEach((data) => {
                if (data.ticketItemId === currSpectatorTicket.ticketItemId) {
                  currcount--
                }
              })
            }

            Array.from(Array(currcount)).forEach(() => {
              let assignedticket = new AssignTicketsModal({
                ticketBuyerName: null,
                ticketTemplateUrl: '',
                updated: new Date(),
                created: new Date(),
                ticketPrice: currSpectatorTicket.ticketPrice,
                ticketType: 'spectator',
                chargeId: null,
                assignmentDate: null,
                spectatorTicketDocId: currSpectatorTicket.id ?? null,
                assignedStatus: false,
                ticketStatus: 'available',
                isScratched: false,
                ticketTitle: currSpectatorTicket.ticketTitle,
                ticketHolderEmailId: null,
                ticketHolderName: null,
                ticketItemId: currSpectatorTicket.ticketItemId,
                scratchedOn: null,
                amountScratched: 0,
                amountRefunded: 0,
                paymentStatus: 'pending',
                ticketBuyerEmailId: currSpectatorTicket.userEmailId,
                ticketBuyerDocId: currSpectatorTicket.ticketBuyerDocId,
                checkInDate: null,
                userId: currSpectatorTicket.userId,
                registrationTicketDocId: null,
                registrationDocId: null,
                eventDocId: currSpectatorTicket.eventDocId,
                isPlatformUser: true,
                receipetUrl: null,
                create: true,
              }).toObject()

              NewassignedTickets.push(assignedticket)
            })
          })
          finaltickets = NewassignedTickets
        }
      } catch (error) {
        console.error(error)
      } finally {
        generateTickets({
          spectatorTickets,
          assignedTickets: finaltickets,
        })
        setLoading(false)
      }
    },
    [generateTickets]
  )

  /**
   * @returns Update tickets firebase function
   */
  const updateTicket_function = useCallback(
    async (id: string, data: IAssignedTicket) => {
      try {
        await FirestoreService.updateItem(ASSIGNED_TICKETS.NAME, id, data)
        return true
      } catch (error) {
        toastFunction.info({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
        return false
      }
    },
    [toastFunction]
  )

  useEffect(() => {
    if (spectatorTickets.length) fetchTickets(spectatorTickets)
  }, [fetchTickets, spectatorTickets])

  const save_fieldData = useCallback(
    async (submit_data: any) => {
      try {
        const tickets_data = submit_data?.tickets
        const valid_item: IAssignedTicket[] = []
        const check_error: any = {}
        if (!check_error?.status) {
          if (check_error?.message !== '') {
            toastFunction.info({ message: check_error?.message })
          }
          return null
        }

        let i = 0

        while (i < tickets_data.length) {
          let update_item = tickets_data[i]
          const new_data = new AssignTicketsModal(update_item).toObject()
          if (update_item?.id) await updateTicket_function(update_item?.id, new_data)
          i++
        }

        return { status: true, data: valid_item }
      } catch (error) {
        return { status: false }
      }
    },
    [toastFunction, updateTicket_function]
  )

  const handleSave = useCallback(
    async (submit_data: any) => {
      setLoading(true)
      let complete_status = await save_fieldData(submit_data)
      if (complete_status?.status) {
        router.push('/')
        history.push('/')
      }

      setLoading(false)
    },
    [router, save_fieldData]
  )

  const handleNext: SubmitHandler<{
    assignedTickets: IDataToRenderItem[]
  }> = useCallback(
    async ({ assignedTickets }) => {
      try {
        props.setLoading(true)

        let spectatorTickets_: ISpectatorTickets[] = []
        let spectatorTicket: ISpectatorTickets | null = null
        const assignedTicketsToBuy: IAssignedTicket[] = []
        const assignedTicketsToCreate: IAssignedTicket[] = []
        const assignedTicketsToUpdate: IAssignedTicket[] = []
        const assignedTicketsUntouched: IAssignedTicket[] = []

        if (!assignedTickets.length) {
          return toastFunction.error({
            message: 'Please add at least one ticket',
          })
        }

        const spectatorTicketMap = new Map<string, ISpectatorTickets>()

        spectatorTickets.forEach((currSpectatorTicket) => {
          if (!!currSpectatorTicket.id && currSpectatorTicket.ticketItemId) {
            spectatorTicketMap.set(currSpectatorTicket.id!, currSpectatorTicket)
          }
        })

        assignedTickets.forEach((currAssignedTicket) => {
          if (
            currAssignedTicket.ticketHolderEmailId &&
            currAssignedTicket.ticketHolderEmailId &&
            'create' in currAssignedTicket
          ) {
            if (currAssignedTicket.spectatorTicketDocId) {
              spectatorTicket =
                spectatorTicketMap.get(currAssignedTicket.spectatorTicketDocId) ?? null
              if (spectatorTicket) {
                spectatorTicket = cloneDeep(spectatorTicket)
                spectatorTicket.ticketsCountAgainstSpectatorDoc++
                // spectatorTicket.update = true;
                spectatorTicketMap.set(currAssignedTicket.spectatorTicketDocId, spectatorTicket)
              }
            }

            assignedTicketsToCreate.push(
              new AssignTicketsModal({
                ...currAssignedTicket,
                ticketHolderName: !!currAssignedTicket.ticketHolderName
                  ? currAssignedTicket.ticketHolderName
                  : null,
                ticketHolderEmailId: !!currAssignedTicket.ticketHolderEmailId
                  ? currAssignedTicket.ticketHolderEmailId
                  : null,
              }).toFirestore()
            )
          } else if ('update' in currAssignedTicket) {
            assignedTicketsToUpdate.push(currAssignedTicket)
          } else {
            assignedTicketsUntouched.push(
              getConvertedData(new AssignTicketsModal(currAssignedTicket).toObject())
            )
          }
        })

        if (!isLoggedIn && ![...assignedTicketsToCreate, ...assignedTicketsToUpdate].length) {
          return toastFunction.error({
            message: "Please fill at least one ticket's detail",
          })
        }

        // Create - Assigned tickets

        await helpers.asyncWhileLoop({
          loopCount: assignedTicketsToCreate.length,
          functionToFire: async (index) => {
            assignedTicketsToCreate[index].id = (
              await FirestoreService.createItem(
                COLLECTIONS.ASSIGNED_TICKETS.NAME,
                assignedTicketsToCreate[index]
              )
            ).id
          },
        })

        // Update - Assigned tickets

        await helpers.asyncWhileLoop({
          loopCount: assignedTicketsToUpdate.length,
          functionToFire: async (index) => {
            await FirestoreService.updateItem(
              COLLECTIONS.ASSIGNED_TICKETS.NAME,
              (assignedTicketsToUpdate[index] as IDataToRenderItem).id_!,
              new AssignTicketsModal(assignedTicketsToUpdate[index]).toFirestore()
            )
          },
        })

        await helpers.asyncWhileLoop({
          loopCount: Array.from(spectatorTicketMap).length,
          functionToFire: async (index) => {
            const entries = Array.from(spectatorTicketMap)
            spectatorTicket = entries[index][1]

            spectatorTickets_.push(spectatorTicket)

            if (!spectatorTicket.update || !spectatorTicket.id) return 0

            await FirestoreService.updateItem(
              COLLECTIONS.SPECTATOR_TICKETS.NAME,
              spectatorTicket.id,
              new SpectatorTicketModel(spectatorTicket).toFirestore()
            )
          },
        })

        reset({
          assignedTickets: [
            ...assignedTicketsToUpdate,
            ...assignedTicketsToCreate,
            ...assignedTicketsUntouched,
          ],
        })

        ;[...assignedTicketsToUpdate, ...assignedTicketsToCreate].forEach((currTicket) => {
          if (currTicket.paymentStatus === 'pending') {
            assignedTicketsToBuy.push(getConvertedData(currTicket))
          }
        })

        dispatch(
          setSpectatorEventData({
            spectator_tickets: spectatorTickets_,
          })
        )
        dispatch(
          setSpectatorPayTabTickets([
            ...assignedTicketsToUpdate,
            ...assignedTicketsToCreate,
            ...assignedTicketsUntouched,
          ])
        )
        dispatch(setSpectatorAssignTickets(assignedTickets))
        onSetEventTab(CONST.UI.SPECTATOR_TABS.TABS.PAY)
      } catch (error) {
        toastFunction.error({
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
        })
        console.log(error)
      } finally {
        props.setLoading(false)
      }
    },
    [dispatch, isLoggedIn, onSetEventTab, props, reset, spectatorTickets, toastFunction]
  )

  useEffect(() => {
    saveRef.current = handleSubmit(handleSave)
    nextRef.current = handleSubmit(handleNext)
  }, [guest_id, handleNext, handleSave, handleSubmit, nextRef, saveRef, userId])

  return (
    <SpectatorTickesPurchaseWrapper
      title="Email tickets"
      description="Assign a name and email to each ticket. Leave empty to assign tickets later."
      over_flow={true}
    >
      <div className="w-full">
        <div className="w-full flex sticky top-[-19px]">
          {table_headings.map((item) => {
            if (item?.col_id === 5 && assigned_user === true) return null

            return (
              <div
                key={JSON.stringify(item)}
                className={`${item?.styleclass} mx-1 flex items-center bg-[#fff]`}
              >
                <span className="text-[12px] font-semibold makegloballabelcolor mr-1">
                  {item?.name}
                </span>
                <Tooltip
                  title={<h1 className="tooltip_title">{item?.tooltip}</h1>}
                  placement="top"
                  arrow
                >
                  <button>
                    <TooltipIcon color="#3C4B71" />
                  </button>
                </Tooltip>
              </div>
            )
          })}
        </div>
        <div className="w-full">
          {loading ? (
            <div className="flex justify-center pt-[40px]">
              <ViewsLoader color="#ff2d55" size="lg" />
            </div>
          ) : assignedTicketFieldArray.fields.length ? (
            assignedTicketFieldArray.fields.map((currAssignedTicketField, index) => {
              let cond: boolean = false
              if (
                watch(`assignedTickets.${index}.ticketHolderName`) &&
                watch(`assignedTickets.${index}.ticketHolderEmailId`)
              ) {
                cond = true
              }
              return (
                <div key={currAssignedTicketField.id} className={`w-full flex`}>
                  <div
                    className={`w-[25%] text-[#122B46] min-w-[290px] mb-2 mx-1 text-nr text-[#122B46]-900 border border-[#D3DAEE] rounded-md !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor p-3.5 h-[55px] ${cond ? 'bg-[#F6F7FB] border-[#F6F7FB]' : ''}`}
                  >
                    {currAssignedTicketField.ticketTitle ?? 'N/A'}
                  </div>

                  <div className="w-[37%] min-w-[270px] mx-1">
                    <input
                      className={clsx(
                        `w-full mb-2  text-nr !text-[#122B46] outline-0 rounded-md !ring-0  p-4 disabled:bg-[#F6F7FB] text-start border border-[#D3DAEE] focus:border-[#122B46] h-[55px] capitalize`,
                        !!errors?.assignedTickets?.[index]?.ticketHolderName
                          ? 'mb-0'
                          : cond && 'bg-[#F6F7FB] border-[#F6F7FB]'
                      )}
                      {...register(`assignedTickets.${index}.ticketHolderName`, {
                        onChange: () => {
                          setValue(`assignedTickets.${index}.create`, true)
                        },
                      })}
                      disabled={cond}
                      placeholder="Enter name..."
                    />
                    {!!errors?.assignedTickets?.[index]?.ticketHolderName ? (
                      <MessageHelperComp
                        isError={true}
                        message={errors?.assignedTickets?.[index]?.ticketHolderName?.message}
                        className="ml-1"
                      />
                    ) : null}
                  </div>

                  <div className="w-[37%] min-w-[270px] mx-1">
                    <input
                      className={clsx(
                        `w-full mb-2  text-nr !text-[#122B46] outline-0 rounded-md !ring-0  p-4 disabled:bg-[#F6F7FB] text-start border border-[#D3DAEE] focus:border-[#122B46] h-[55px]`,
                        !!errors?.assignedTickets?.[index]?.ticketHolderEmailId
                          ? 'mb-0'
                          : cond && 'bg-[#F6F7FB] border-[#F6F7FB]'
                      )}
                      {...register(`assignedTickets.${index}.ticketHolderEmailId`, {
                        onChange: () => {
                          if (watch(`assignedTickets.${index}`).id_)
                            setValue(`assignedTickets.${index}.create`, true)
                        },
                      })}
                      disabled={
                        watch(`assignedTickets.${index}.ticketStatus`) === 'assigned' ||
                        watch(`assignedTickets.${index}`).paymentStatus === 'paid'
                      }
                      placeholder="Enter email address..."
                    />
                    {!!errors?.assignedTickets?.[index]?.ticketHolderEmailId ? (
                      <MessageHelperComp
                        isError={true}
                        message={errors?.assignedTickets?.[index]?.ticketHolderEmailId?.message}
                        className="ml-1"
                      />
                    ) : null}
                  </div>
                </div>
              )
            })
          ) : (
            <div className="text-[14px] text-SeabiscuitLightParagraphTextColor mt-2">
              Please add tickets
            </div>
          )}
        </div>
      </div>
    </SpectatorTickesPurchaseWrapper>
  )
}

export default SpeactatorTicketsPuchaseEmailTab
