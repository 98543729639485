export const DATA_FIRESTORE_V01_HORSE_DOCUMENT = {
  NAME: 'v01_horses',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    HORSE_NAME: {
      KEY: 'horseName',
      VALUES: {
        DEFAULT: null,
      },
    },

    HORSE_PROFILE_PICTURE: {
      KEY: 'horseProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    HORSE_DISCIPLINE: {
      KEY: 'horseDiscipline',
      VALUES: {
        DEFAULT: null,
      },
    },

    HORSE_ZONE: {
      KEY: 'horseZone',
      VALUES: {
        DEFAULT: null,
      },
    },

    FILTER: {
      OWNER_ID: {
        KEY: 'horseOwnerId',
      },
      HORSE_ID: {
        KEY: 'horseId',
      },
    },

    HORSE_NAME_N_GRAM: {
      KEY: 'horseNameNGram',
      VALUES: {
        DEFAULT: [],
      },
    },

    OWNER_ID: {
      KEY: 'horseOwnerId',
      VALUES: {
        DEFAULT: null,
      },
    },
  },
}
