export const Sheet2023USDFTrainingLevelFreestyle = {
  id: 'Sheet2023USDFTrainingLevelFreestyle',
  name: '2023 USDF Training Level Freestyle',
  furtherRemarks: '',
  type: 'score-sheet-2',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 170,
      type: 'test',
      rows: [
        {
          number: 1,
          description: '<p>Medium walk (20m continuous)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 2,
          description: '<p>Free walk (20m continuous)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 3,
          description: '<p>20-meter circle RIGHT in working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p>20-meter circle LEFT in working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description:
            '<p>Serpentine in working trot two or more changes of direction with loops no smaller than 15 meters</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Stretch forward & downward on a 20-meter trot circle in rising trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description: '<p>20-meter circle RIGHT in working canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 8,
          description: '<p>20-meter circle LEFT in working canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description: '<p>All transitions between gaits (Halts not included)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 10,
          description: '<p>Halt with salute on centerline facing C, first and final</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
        {
          description: '<h5>Errors</h5><p>2pts for each error • Not cumulative</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
    {
      name: 'Artistic Impression',
      max: 170,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rythm</h5><p>Rhythm, energy, and elasticity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Harmony</h5><p>Harmony Between Horse and Rider</p>',
          score: '',
          coefficient: 4,
        },
        {
          description:
            '<h5>Choreography</h5><p>Design cohesiveness, use of arena, balance, creativity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Difficulty</h5><p>Degree of difficulty</p>',
          score: '',
          coefficient: 2,
        },
        {
          description: '<h5>Music</h5><p>Suitability, cohesiveness, seamlessness</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Interpretation</h5><p>Music expresses gaits, use of phrasing and dynamics</p>',
          score: '',
          coefficient: 2,
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
  ],
}

export const Sheet2023USDF1stLevelFreestyle = {
  id: 'Sheet2023USDF1stLevelFreestyle',
  name: '2023 USDF 1st Level Freestyle',
  furtherRemarks: '',
  type: 'score-sheet-2',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 180,
      type: 'test',
      rows: [
        {
          number: 1,
          description: '<p>Medium walk (20m continuous)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description: '<p>Free walk (20m continuous)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 3,
          description: '<p>10-meter circle RIGHT in working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p>10-meter circle LEFT in working trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p>Leg-yield RIGHT in working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Leg-yield LEFT in working trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description: '<p>Lengthen stride in trot on a straight line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p>15-meter circle RIGHT in working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p>15-meter circle LEFT in working canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p>Change of lead through trot RIGHT</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description: '<p>Change of lead through trot LEFT</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description: '<p>Lengthen stride in canter on a straight line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p>Halt with salute on centerline, first and final</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
        {
          description: '<h5>Errors</h5><p>2pts for each error • Not cumulative</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
    {
      name: 'Artistic Impression',
      max: 180,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rythm</h5><p>Rhythm, energy, and elasticity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Harmony</h5><p>Harmony Between Horse and Rider</p>',
          score: '',
          coefficient: 4,
        },
        {
          description:
            '<h5>Choreography</h5><p>Design cohesiveness, use of arena, balance, creativity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Difficulty</h5><p>Degree of difficulty</p>',
          score: '',
          coefficient: 1,
        },
        {
          description: '<h5>Music</h5><p>Suitability, cohesiveness, seamlessness</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Interpretation</h5><p>Music expresses gaits, use of phrasing and dynamics</p>',
          score: '',
          coefficient: 2,
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
  ],
}

export const Sheet2023USDF2stLevelFreestyle = {
  id: 'Sheet2023USDF2stLevelFreestyle',
  name: '2023 USDF 2st Level Freestyle',
  furtherRemarks: '',
  type: 'score-sheet-2',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 190,
      type: 'test',
      rows: [
        {
          number: 1,
          description: '<p>Medium walk (20m continuous)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 2,
          description: '<p>Free walk (20m continuous)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 3,
          description: '<p>Shoulder-in RIGHT in collected trot (12m min)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p>Shoulder-in LEFT in collected trot (12m min)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p>Travers RIGHT in collected trot (12m min)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Travers LEFT in collected trot (12m min)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description: '<p>Medium trot on straight line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p>Transitions in and out of medium trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 9,
          description: '<p>Simple change of lead RIGHT</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 10,
          description: '<p>Simple change of lead LEFT</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 11,
          description: '<p>Counter-canter in collected canter RIGHT</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description: '<p>Counter-canter in collected canter LEFT</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 13,
          description: '<p>Medium canter on straight line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 14,
          description: '<p>Transitions in and out of the medium canter</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p>Halt with salute on centerline, first and final</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
        {
          description: '<h5>Errors</h5><p>2pts for each error • Not cumulative</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
    {
      name: 'Artistic Impression',
      max: 190,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rythm</h5><p>Rhythm, energy, and elasticity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Harmony</h5><p>Harmony Between Horse and Rider</p>',
          score: '',
          coefficient: 4,
        },
        {
          description:
            '<h5>Choreography</h5><p>Design cohesiveness, use of arena, balance, creativity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Difficulty</h5><p>Degree of difficulty</p>',
          score: '',
          coefficient: 3,
        },
        {
          description: '<h5>Music</h5><p>Suitability, cohesiveness, seamlessness</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Interpretation</h5><p>Music expresses gaits, use of phrasing and dynamics</p>',
          score: '',
          coefficient: 2,
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
  ],
}

export const Sheet2023USDF3stLevelFreestyle = {
  id: 'Sheet2023USDF3stLevelFreestyle',
  name: '2023 USDF 3st Level Freestyle',
  furtherRemarks: '',
  type: 'score-sheet-2',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 200,
      type: 'test',
      rows: [
        {
          number: 1,
          description: '<p>Medium walk (20m continuous)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 2,
          description: '<p>Extended walk (20m continuous)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 3,
          description: '<p>Shoulder-in RIGHT in collected trot (12m min)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p>Shoulder-in LEFT in collected trot (12m min)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p>Trot half-pass RIGHT in collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Trot half-pass LEFT in collected trot</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 7,
          description: '<p>Extended trot on straight line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p>Canter half-pass RIGHT in collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description: '<p>Canter half-pass LEFT in collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 10,
          description: '<p>Flying change of lead RIGHT</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p>Flying change of lead LEFT</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 12,
          description: '<p>Extended canter on straight line</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description: '<p>Halt with salute on centerline, first and final</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
        {
          description: '<h5>Errors</h5><p>2pts for each error • Not cumulative</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
    {
      name: 'Artistic Impression',
      max: 200,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rythm</h5><p>Rhythm, energy, and elasticity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Harmony</h5><p>Harmony Between Horse and Rider</p>',
          score: '',
          coefficient: 4,
        },
        {
          description:
            '<h5>Choreography</h5><p>Design cohesiveness, use of arena, balance, creativity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Difficulty</h5><p>Degree of difficulty</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Music</h5><p>Suitability, cohesiveness, seamlessness</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Interpretation</h5><p>Music expresses gaits, use of phrasing and dynamics</p>',
          score: '',
          coefficient: 2,
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
  ],
}

export const Sheet2023USDF4stLevelFreestyle = {
  id: 'Sheet2023USDF4stLevelFreestyle',
  name: '2023 USDF 4st Level Freestyle',
  furtherRemarks: '',
  type: 'score-sheet-2',
  errors: 0,
  sections: [
    {
      name: 'Technical Execution',
      max: 200,
      type: 'test',
      rows: [
        {
          number: 1,
          description: '<p>Collected walk (20m continuous)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 2,
          description: '<p>Extended walk (20m continuous)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 3,
          description: '<p>Shoulder-in RIGHT in collected trot (12m min)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 4,
          description: '<p>Shoulder-in LEFT in collected trot (12m min)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 5,
          description: '<p>Trot half-pass RIGHT in collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 6,
          description: '<p>Trot half-pass LEFT in collected trot</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 7,
          description: '<p>Extended trot on straight line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 8,
          description: '<p>Canter half-pass RIGHT in collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 9,
          description: '<p>Canter half-pass LEFT in collected canter</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 10,
          description: '<p>Flying changes of lead, every 4th stride (min. 3)</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 11,
          description: '<p>Flying changes of lead, every 3rd stride (min. 3)</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 12,
          description: '<p>Canter working half-pirouette RIGHT</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 13,
          description: '<p>Canter working half-pirouette LEFT</p>',
          score: '',
          coefficient: 2,
          remarks: '',
        },
        {
          number: 14,
          description: '<p>Extended canter on straight line</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          number: 15,
          description: '<p>Halt with salute on centerline, first and final</p>',
          score: '',
          coefficient: 1,
          remarks: '',
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
        {
          description: '<h5>Errors</h5><p>2pts for each error • Not cumulative</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
    {
      name: 'Artistic Impression',
      max: 200,
      type: 'mark',
      rows: [
        {
          description: '<h5>Rythm</h5><p>Rhythm, energy, and elasticity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Harmony</h5><p>Harmony Between Horse and Rider</p>',
          score: '',
          coefficient: 4,
        },
        {
          description:
            '<h5>Choreography</h5><p>Design cohesiveness, use of arena, balance, creativity</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Difficulty</h5><p>Degree of difficulty</p>',
          score: '',
          coefficient: 4,
        },
        {
          description: '<h5>Music</h5><p>Suitability, cohesiveness, seamlessness</p>',
          score: '',
          coefficient: 2,
        },
        {
          description:
            '<h5>Interpretation</h5><p>Music expresses gaits, use of phrasing and dynamics</p>',
          score: '',
          coefficient: 2,
        },
        {
          description: '<h5>Deductions</h5><p>4pts deducted for each forbidden movement</p>',
          score: '',
          fraction:'minus',
        },
      ],
    },
  ],
}
