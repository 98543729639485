// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { motion } from 'framer-motion'

import ShowsHomePageFinancesMetricsSubSection from './ShowsHomePageFinancesMetricsSubSection'
import ShowsHomePageFinancesGraphsSubSection from './ShowsHomePageFinancesGraphsSubSection'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsHomePageFinancesSection: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <motion.div
      className="w-full mx-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: 0.1,
      }}
    >
      <ShowsHomePageFinancesMetricsSubSection />

      <ShowsHomePageFinancesGraphsSubSection />
    </motion.div>
  )
}

export default ShowsHomePageFinancesSection
