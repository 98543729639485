import { useIonRouter } from '@ionic/react'
import { CONST } from '../../../../../const/const'
import EventsContainer from './EventsContainer'
import { useHistory } from 'react-router-dom'

const SponsorsAndVendors = () => {
  const router = useIonRouter()
  const history = useHistory()

  const onBrowseEventButtonClick = () => {
    router.push(CONST.ROUTES.BROWSE_EVENTS.URL)
    history.push(CONST.ROUTES.BROWSE_EVENTS.URL)
  }

  return (
    <div className="flex-col gap-4 sticky top-4 hidden md:flex w-[calc(30%-15px)]">
      <EventsContainer
        tabList={['Sponsored events', 'Past events']}
        onBrowseEventButtonClick={onBrowseEventButtonClick}
        emptyEventsMessage="You have not sponsorsed any events."
      />
      <EventsContainer
        tabList={['Vendored events', 'Past events']}
        onBrowseEventButtonClick={onBrowseEventButtonClick}
        emptyEventsMessage="You have not vendored at any events."
      />
    </div>
  )
}

export default SponsorsAndVendors
