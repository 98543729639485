import React from 'react'
import MainModal from './common/MainModal'
import Select from 'react-select'
import { IconRulebookBook } from '../icons/IconRulebookBook'
import { IOptions } from 'dependency-cruiser'
import { MODAL_CONSTS } from '../../const/modal-const'

interface IProps {
  show: boolean
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: any
}

const OPTIONS = [
  { label: 'USEF Rule Book', value: 'I8-OW5UAGiHTLHojfTP6s', shortDesc: 'USEF' },
  { label: 'FEI Rulebook', value: 'lSX0XTutOxzRENBXX6Kgc', shortDesc: 'FEI' },
  { label: 'Pony Club', value: 'xdXQ3D5u4IGcVWcVOwWuO', shortDesc: 'USPC' },
  { label: 'Western Rulebook ', value: 's5Ivq3nzehvt_ho8ThVdW', shortDesc: 'Western' },
  // { label: 'United States Equestrian Federation (USEF)', value: 'USEF' },
  // { label: 'Federation Equestrian International (FEI)', value: 'FEI' },
  // { label: 'American Paint Horse Association (APHA)', value: 'APHA' },
  // { label: 'American Quater Horse Association (AQHA)', value: 'AQHA' },
  // { label: 'National Reining Cow Horse Association (NRCHA)', value: 'NRCHA' },
  // { label: 'National Reining Horse Association (NRHA)', value: 'NRHA' },
  // { label: 'Professional Rodeo Cowboy Association (PRCA)', value: 'PRCA' },
  // { label: 'Professional Bull Riders (PBR)', value: 'PBR' },
  // { label: 'National Collegiate Equestrian Association (NCEA)', value: 'NCEA' },
  // { label: 'United States Pony Club - Dressage', value: 'USPC - Dressage' },
  // { label: 'United States Pony Club - Eventing', value: 'USPC - Eventing' },
  // { label: 'United States Pony Club - Gymkhana', value: 'USPC - Gymkhana' },
  // { label: 'United States Pony Club - Horse Management', value: 'USPC - Horse Management' },
  // { label: 'United States Pony Club - Mounted Games', value: 'USPC - Mounted Games' },
  // { label: 'United States Pony Club - Polocross', value: 'USPC - Polocross' },
  // { label: 'United States Pony Club - Quizz', value: 'USPC - Quizz' },
  // { label: 'United States Pony Club - Show Jumping', value: 'USPC - Show Jumping' },
  // { label: 'United States Pony Club - Tetrathlon', value: 'USPC - Tetrathlon' },
  // { label: 'United States Pony Club - Trail', value: 'USPC - Trail' },
  // { label: 'United States Pony Club - Western Dressage', value: 'USPC - Western Dressage' },
]

export const AiRuleBookModal: React.FC<IProps> = (props) => {
  const [currentOption, setCurrentOptions] = React.useState<(typeof OPTIONS)[0] | null>(null)
  return (
    <MainModal
      // title="Ai Rule Book"
      hideCloseButton
      show={props.show}
      type="AI_RULE_BOOK"
      size="4xl"
      onTransitionEnd={() => null}
      titleClassName="text-[#F7074F]"
      className={'text-[#122B46] overflow-hidden'}
      customPadding
      buttons={[]}
    >
      <div className={'flex flex-col h-[88vh]'}>
        <div
          className={
            'shadow-[0px_2px_10px_0px_#A3ABB933] flex justify-between items-center text-[#122B46] px-5 pb-5 gap-5 flex-col md:flex-row'
          }
        >
          <div className={'text-center md:text-left'}>
            <h2 className={'text-[#F7074F] font-[700] text-[25px]'}>
              {currentOption ? currentOption.shortDesc : 'Ai'} Rule Book
            </h2>
            <p className={'text-[#122B46] text-[15px]'}>
              {currentOption
                ? `Ask me anything about 2023 and 2024 ${currentOption.shortDesc} rules`
                : 'Select a rule book and then ask me anything'}
            </p>
          </div>
          <div className={'flex items-center gap-[10px]'}>
            <Select
              className={'flex-1 md:w-[465px] h-[60px]'}
              options={OPTIONS}
              onChange={setCurrentOptions}
              styles={{
                control: (base, { isFocused }) => ({
                  ...base,
                  borderColor: isFocused ? '#122B461A' : '#122B461A',
                  boxShadow: isFocused ? 'none' : 'none',
                  borderRadius: '12px',
                  outline: 0,
                }),
                valueContainer: (base) => ({
                  ...base,
                  height: '60px',
                  outline: 0,
                }),
                input: (provided, state) => ({
                  ...provided,
                }),
              }}
              placeholder={'Select a rule book...'}
            />
            <button
              onClick={() => props.handleModal(false, MODAL_CONSTS.AI_RULE_BOOK)}
              className={
                'w-[120px] h-[60px] border-solid !border !border-[#122B461A] hover:!border-[#F7074F] hover:text-[#F7074F] rounded-[12px] text-[16px]'
              }
            >
              Close
            </button>
          </div>
        </div>

        <div className={'p-4 flex flex-1 flex-col'}>
          {currentOption ? (
            <iframe
              title={currentOption.shortDesc}
              className={'h-[100%]'}
              src={`https://www.chatbase.co/chatbot-iframe/${currentOption.value}`}
            />
          ) : (
            <div className={'flex flex-1 flex-col gap-4 items-center justify-center'}>
              <IconRulebookBook />
              <p>Select a Rule Book to start</p>
            </div>
          )}
        </div>
      </div>
    </MainModal>
  )
}
