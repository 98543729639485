import { EPL_FB_TEMPLATE_SAMPLE } from './template/sample/epl-fb-template-sample'
import { CONST } from '../../../../const/const'
import { EPL_FB_TEMPLATE_A } from './template/a/epl-fb-template-a'
import { EPL_FB_TEMPLATE_AA } from './template/aa/epl-fb-template-aa'
import { EPL_FB_TEMPLATE_B } from './template/b/epl-fb-template-b'
import { EPL_FB_TEMPLATE_C } from './template/c/epl-fb-template-c'
import { EPL_FB_HUNTER_A } from './hunter/a/epl-fb-hunter-a'
import { EPL_FB_HUNTER_AA } from './hunter/aa/epl-fb-hunter-aa'
import { EPL_FB_HUNTER_B } from './hunter/b/epl-fb-hunter-b'
import { EPL_FB_HUNTER_C } from './hunter/c/epl-fb-hunter-c'
import { EPL_FB_HUNTER_SAMPLE } from './hunter/sample/epl-fb-hunter-sample'
import { EPL_FB_JUMPER_A } from './jumper/a/epl-fb-jumper-a'
import { EPL_FB_JUMPER_AA } from './jumper/aa/epl-fb-jumper-aa'
import { EPL_FB_JUMPER_B } from './jumper/b/epl-fb-jumper-b'
import { EPL_FB_JUMPER_C } from './jumper/c/epl-fb-jumper-c'
import { EPL_FB_JUMPER_SAMPLE } from './jumper/sample/epl-fb-jumper-sample'
import { EPL_FB_EQUITATION_A } from './equitation/a/epl-fb-equitation-a'
import { EPL_FB_EQUITATION_B } from './equitation/b/epl-fb-equitation-b'
import { EPL_FB_EQUITATION_AA } from './equitation/aa/epl-fb-equitation-aa'
import { EPL_FB_EQUITATION_C } from './equitation/c/epl-fb-equitation-c'
import { EPL_FB_EQUITATION_SAMPLE } from './equitation/sample/epl-fb-equitation-sample'
import { EPL_FB_JUMPER_L1 } from './jumper/l1/epl-fb-jumper-l1'
import { EPL_FB_JUMPER_L2 } from './jumper/l2/epl-fb-jumper-l2'
import { EPL_FB_JUMPER_L3 } from './jumper/l3/epl-fb-jumper-l3'
import { EPL_FB_JUMPER_L4 } from './jumper/l4/epl-fb-jumper-l4'
import { EPL_FB_JUMPER_L5 } from './jumper/l5/epl-fb-jumper-l5'
import { EPL_FB_JUMPER_L6 } from './jumper/l6/epl-fb-jumper-l6'

export const EVENT_PRIZE_LIST_FORM_BUILDER = {
  FIELD: {
    DEFAULT: {
      TEXT: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.TEXT,
        meta: null,
        value: '',
        validation: null,
        rules: null,
      },
      NUMBER: {
        type: CONST.EVENT.PRIZE_LIST.FORM_BUILDER.FIELD.TYPE.NUMBER,
        meta: null,
        value: 0,
        validation: null,
        rules: null,
      },
    },
  },
  HUNTER: {
    A: EPL_FB_HUNTER_A,
    AA: EPL_FB_HUNTER_AA,
    B: EPL_FB_HUNTER_B,
    C: EPL_FB_HUNTER_C,
    SAMPLE: EPL_FB_HUNTER_SAMPLE,
  },
  JUMPER: {
    A: EPL_FB_JUMPER_A,
    AA: EPL_FB_JUMPER_AA,
    B: EPL_FB_JUMPER_B,
    C: EPL_FB_JUMPER_C,
    L1: EPL_FB_JUMPER_L1,
    L2: EPL_FB_JUMPER_L2,
    L3: EPL_FB_JUMPER_L3,
    L4: EPL_FB_JUMPER_L4,
    L5: EPL_FB_JUMPER_L5,
    L6: EPL_FB_JUMPER_L6,
    SAMPLE: EPL_FB_JUMPER_SAMPLE,
  },
  EQUITATION: {
    A: EPL_FB_EQUITATION_A,
    AA: EPL_FB_EQUITATION_AA,
    B: EPL_FB_EQUITATION_B,
    C: EPL_FB_EQUITATION_C,
    SAMPLE: EPL_FB_EQUITATION_SAMPLE,
  },
  TEMPLATE: {
    SAMPLE: EPL_FB_TEMPLATE_SAMPLE,
    A: EPL_FB_TEMPLATE_A,
    AA: EPL_FB_TEMPLATE_AA,
    B: EPL_FB_TEMPLATE_B,
    C: EPL_FB_TEMPLATE_C,
  },
}
