import { useEffect } from 'react'
import { useLocation, useParams } from 'react-router'
import './TopNavigationSearchComponent.css'
import useGetEventData from '../../../../hooks/users/common/useGetEventData'
import { IconMenu } from '../../../icons/IconMenu'
import { useDispatch } from 'react-redux'
import { registerMobileMenu } from '../../../../store/mobileMenu/mobileMenuSlice'

const TopNavigationSearchComponent = () => {
  // Hooks and vars
  const location = useLocation()
  const { getAllData } = useGetEventData()
  const params = useParams<{ eventId: string }>()
  const mainRoute = location.pathname.split('/')[1]
  const dispatch = useDispatch()

  useEffect(() => {
    if (params.eventId) {
      getAllData(params.eventId, ['v01_events'])
    }
  }, [params.eventId, getAllData])

  useEffect(() => {
    dispatch(registerMobileMenu.toggleMobileMenu(false))
  }, [mainRoute])

  return (
    <>
      <div className="flex items-center justify-between w-[calc(100%-300px)] shrink-0">
        <div className={`flex items-center md:hidden gap-4`}>
          <div className={'w-[40px] h-auto'}>
            <img src="/assets/Pegasus_app_logo.png" alt={'mobile-logo'} />
          </div>
          <button
            onClick={() => dispatch(registerMobileMenu.toggleMobileMenu(true))}
            className={`openMenu rounded-lg  h-[30px] w-[30px] flex items-center justify-center bg-white`}
          >
            <IconMenu />
          </button>
        </div>
      </div>
    </>
  )
}

export default TopNavigationSearchComponent
