export const COLORS = [
  '#e11d48',
  '#9333ea',
  '#2563eb',
  '#0e7490',
  '#33691e',
  '#db2777',
  '#eab308',
  '#9333ea',
  '#b91c1c',
  '#172554',
  '#065f46',
  '#92400e',
  '#22c55e',
  '#083344',
  '#ad1457',
  '#ad1457',
  '#2563eb',
  '#ef5350',
  '#1a237e',
  '#006064',
]
