import TimeLib from '../../../../lib/Time'

export const DATA_FIRESTORE_V01_EVENT_STAFF = {
  NAME: 'v01_event_staff',

  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    ID: {
      KEY: 'id',
      DEFAULT: null,
    },

    TITLE: {
      KEY: 'title',
      DEFAULT: 'Other',
    },

    OWNER: {
      KEY: 'owner',
      DEFAULT: null,
    },

    EVENT_STAFF: {
      KEY: 'eventStaff',
      DEFAULT: [],
    },

    OTHER_STAFF: {
      KEY: 'otherStaff',
      DEFAULT: [],
    },

    CREATED: {
      KEY: 'created',
      DEFAULT: TimeLib.utcTimestamp(),
    },

    MODIFIED: {
      KEY: 'modified',
      DEFAULT: TimeLib.utcTimestamp(),
    },

    BRIEF_DESCRIPTION: {
      KEY: 'briefDescription',
      DEFAULT: '',
    },
  },
}
