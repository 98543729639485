// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { CursorClickIcon, MailOpenIcon, UsersIcon } from '@heroicons/react/outline'
import EventMetricsDisplayCardStackComponent from '../../../components/events/stacks/metrics-display-card/EventMetricsDisplayCardStackComponent'

const metrics = [
  {
    id: 'alpha',
    name: 'Exhibitors Revenue',
    mainMetric: '$153,080',
    icon: UsersIcon,
    subMetric: '$26,290',
  },
  {
    id: 'alpha',
    name: 'Riders Revenue',
    mainMetric: '$153,080',
    icon: UsersIcon,
    subMetric: '$26,290',
  },
  {
    id: 'beta',
    name: 'Vendors Registered',
    mainMetric: '32',
    icon: MailOpenIcon,
    subMetric: '5.4%',
  },
  {
    id: 'gama',
    name: 'Sponsors Registered',
    mainMetric: '100',
    icon: CursorClickIcon,
    subMetric: '3.2%',
  },
  {
    id: 'iota',
    name: 'Spectators Registered',
    mainMetric: '123',
    icon: CursorClickIcon,
    subMetric: '3.2%',
  },
]

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsHomePageFinancesMetricsSubSection: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
      <EventMetricsDisplayCardStackComponent metrics={metrics} />
    </dl>
  )
}

export default ShowsHomePageFinancesMetricsSubSection
