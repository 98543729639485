import TimeLib from '../../../../lib/Time'

export const DATA_FIRESTORE_V01_AMOUNT_TRANSFERS = {
  NAME: 'v01_amount_transfers',
  FIELDS: {
    VERSION: {
      KEY: 'v',
      DEFAULT: 1,
    },

    ID: {
      KEY: 'id',
      DEFAULT: null,
    },

    EVENT_ID: {
      KEY: 'eventId',
      DEFAULT: null,
    },

    TRANSACTION_REF_ID: {
      KEY: 'transactioRefId',
      DEFAULT: null,
    },

    TOTAL_AMOUNT: {
      KEY: 'totalAmount',
      DEFAULT: null,
    },

    EVENT_OWNER_CONNECT_ID: {
      KEY: 'eventOwnerConnectId',
      DEFAULT: null,
    },

    UPDATED_AT: {
      KEY: 'updatedAt',
      DEFAULT: TimeLib.utcTimestamp(),
    },

    CREATED_AT: {
      KEY: 'createdAt',
      DEFAULT: TimeLib.utcTimestamp(),
    },

    TRANSFER_ID: {
      KEY: 'transferId',
      DEFAULT: null,
    },

    AMOUNT_SENT: {
      KEY: 'amountSent',
      DEFAULT: 0,
    },

    IS_PAID: {
      KEY: 'isPaid',
      DEFAULT: false,
    },

    TRANSACTION_ID: {
      KEY: 'transactionId',
      DEFAULT: null,
    },

    SENT_TO_CONNECT_ID: {
      KEY: 'sentToConnectId',
      DEFAULT: null,
    },

    EVENT_OWNER_ID: {
      KEY: 'eventOwnerId',
      DEFAULT: null,
    },

    DEDUCED_PLATFORM_FEE: {
      KEY: 'deducedPlatformFee',
      DEFAULT: 0,
    },

    DEDUCED_PLATFORM_FEE_IN_PERCENT: {
      KEY: 'deducedPlatformFee',
      DEFAULT: 30,
    },
  },
}
