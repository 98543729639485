import React, { FC, useState, useContext } from 'react'

import clsx from 'clsx'

import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { getIconColor } from '../../../../../helpers/getIconColor'
import { IRegistrationByDayInterface } from '../../../../../models/registrations-by-day/registrationByDay.interface'
import { sortByRank } from '../../../../../pages/organizer/manage/clinic/ManageClinicNOtherOperationsTab/ManageClinicNOtherOperationsTab'
import { IMAGE_CONSTS } from '../../../../../const/image-const'
import { IHorseData } from '../../../../../models/horse/horse.interface'
import { sumBy } from 'lodash'

type Data = {
  data: IRegistrationByDayInterface[]
  publishScore: boolean
  publishPrize: boolean
  prizeMoney: string
  isAsc: boolean
  horses: IHorseData[]
}

interface DetailRowProps {
  color: string
  row: IRegistrationByDayInterface
  publishScore: boolean
  publishPrize: boolean
  prizeMoney: string
  horse?: IHorseData
}

const DetailRow: FC<DetailRowProps> = ({
  row,
  horse,
  publishScore,
  publishPrize,
  prizeMoney,
  color,
}) => {
  const [errorImg, setErrorImg] = useState(false)

  const handleModalContext = useContext(HandleModalContext)

  const handleModalOpen = () => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.SCORING, {
      rider: row,
      prizeMoney: prizeMoney,
    })
  }

  let max = 0
  if (row.score?.judges) {
    max = sumBy(row.score.judges[0]?.sections, (section) => section.max)
  }

  const arrayName = row.riderName?.split(' ') || []

  return (
    <>
      <div className="flex flex-wrap items-center justify-between w-full my-2 gap-4 md:gap-2">
        <div className="w-full md:w-1/3 flex items-center">
          {row.riderProfilePicture && !errorImg ? (
            <img
              src={row.riderProfilePicture}
              onError={() => setErrorImg(true)}
              alt="avatar"
              className="w-[45px] h-[45px] rounded-full mt-1"
            />
          ) : (
            <div
              style={{ background: color }}
              className={`w-[45px] h-[45px] rounded-full mt-1 flex items-center justify-center text-white`}
            >
              {arrayName[0][0]}
              {arrayName.length > 1 ? arrayName[arrayName.length - 1][0] : ''}
            </div>
          )}
          <div className="ml-3">
            <div className="text-SeabiscuitDark200ThemeColor font-bold text-base">
              {row.riderName}
            </div>
            <span className="text-base text-SeabiscuitDark200ThemeColor/50">
              {row.backNumber ? `Back number • ${row.backNumber}` : null}
            </span>
          </div>
        </div>
        <div className="w-full md:w-1/3 flex items-center">
          <img
            src={row.horseProfilePicture ? row.horseProfilePicture : IMAGE_CONSTS.PLACEHOLDERS.USER}
            alt="avatar"
            className="w-[45px] h-[45px] rounded-full mt-1"
          />
          <div className="ml-3">
            <div className="text-SeabiscuitDark200ThemeColor font-bold text-base">
              {row.horseName ?? 'No Horse'}
            </div>
            <span className="text-base text-SeabiscuitDark200ThemeColor/50">
              {horse?.trainer ? `Trainer • ${horse?.trainer}` : null}
            </span>
          </div>
        </div>
        {publishScore ? (
          Number(row.score?.totalPoints) ? (
            <div className="md:ml-auto flex gap-2.5 justify-center items-center">
              <div className="md:text-right">
                <div className="flex gap-2">
                  {row.isQualify && (
                    <>
                      <span className={'font-semibold '}>Q •</span>
                    </>
                  )}
                  <span className={'font-semibold '}> {row.score?.totalPoints}</span>
                  <span>•</span>
                  <span>
                    {Number(row.score?.totalPoints) > 0 && max > 0
                      ? ((Number(row.score?.totalPoints) / Number(max)) * 100).toFixed(2)
                      : 0}
                    %
                  </span>
                </div>
                {publishPrize ? (
                  <div className="opacity-50 cursor-pointer text-[14px]" onClick={handleModalOpen}>
                    {prizeMoney && row.score?.earning ? (
                      <span className="underline">
                        ${(Number(prizeMoney) * Number(row.score.earning)) / 100}
                      </span>
                    ) : (
                      <span className="underline text-SeabiscuitDark200ThemeColor/50">$0</span>
                    )}
                  </div>
                ) : (
                  <div
                    className="opacity-50 underline cursor-pointer text-[14px]"
                    onClick={handleModalOpen}
                  >
                    More
                  </div>
                )}
              </div>
              <div
                className={clsx(
                  `p-1 rounded-full w-[45px] h-[45px] flex items-center justify-center`,
                  row.score?.rank === 1 && Number(row.score?.totalPoints)
                    ? 'bg-SeabiscuitGreenThemeColor text-white'
                    : row.score?.rank === 2 && Number(row.score?.totalPoints)
                      ? 'bg-yellow-500 text-white'
                      : row.score?.rank === 3 && Number(row.score?.totalPoints)
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-200 text-black'
                )}
              >
                {row.score?.rank || 'N/A'}
              </div>
            </div>
          ) : (
            <div className="md:ml-auto md:text-right">
              <div className="font-bold text-SeabiscuitDark200ThemeColor">Feedback</div>
              <div
                className="opacity-50 underline cursor-pointer text-[14px]"
                onClick={handleModalOpen}
              >
                More
              </div>
            </div>
          )
        ) : (
          <div className="ml-auto">
            <div className="md:text-right text-SeabiscuitDark200ThemeColor">Results</div>
            <div className="md:text-right text-[14px] text-SeabiscuitDark200ThemeColor/50">
              Not yet published
            </div>
          </div>
        )}
      </div>
      <hr></hr>
    </>
  )
}

const ExhibitorResultsAccordion: FC<Data> = ({
  data,
  horses,
  publishScore,
  publishPrize,
  prizeMoney,
  isAsc,
}) => {
  return (
    <>
      {data?.length > 0 &&
        sortByRank(data, isAsc ? 'asc' : 'desc').map(
          (currentItem: IRegistrationByDayInterface, index: number) => {
            if (currentItem.score?.rank) currentItem.score.rank = index + 1
            return (
              <DetailRow
                key={`${JSON.stringify(currentItem)}${index}`}
                row={currentItem}
                horse={horses.find((horse) => horse.id === currentItem.horseId)}
                publishScore={publishScore}
                publishPrize={publishPrize}
                prizeMoney={prizeMoney}
                color={getIconColor(data.length, index)}
              />
            )
          }
        )}
    </>
  )
}

export default ExhibitorResultsAccordion
