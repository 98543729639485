import { useState } from 'react'

// Third party
import { yupResolver } from '@hookform/resolvers/yup'
import { AutorenewRounded } from '@mui/icons-material'
import {
  EmailAuthProvider,
  User,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth'
import { useForm } from 'react-hook-form'

// Custom hooks
import useToasterHelper from '../../../../helpers/ToasterHelper'

// Custom components
import MessageHelperComp from '../../../../helpers/MessageHelper'

// Schemas
import { competitorPasswordUpdateSchema } from '../../../../validations'

// Constants
import FIREBASE_CONST from '../../../../const/firebase-const'
import { MESSAGES_CONST } from '../../../../const/messages-const'
import { httpService } from '../../../../services/httpService'

interface ICompetitorProfileAccountForm {
  oldPassword: string
  newPassword: string
  cPassword: string
}

const ProfileRootPasswordUpdateForm = ({ saved }: { saved: boolean }) => {
  // Hooks and vars
  const [loading, setLoading] = useState(false)
  const [old_Password, setOld_Password] = useState('password')
  const [new_Passowrd, setNew_Passowrd] = useState('password')
  const [c_Password, setc_Password] = useState('password')
  const toastfunction = useToasterHelper()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ICompetitorProfileAccountForm>({
    mode: 'onChange',
    resolver: yupResolver(competitorPasswordUpdateSchema),
  })
  const [editPassword, setEditPassword] = useState(false)

  // Functions
  const authenticateUser = async ({
    user,
    password,
  }: {
    user: User
    password: string
  }): Promise<boolean> => {
    return new Promise((resolve) => {
      if (user) {
        const credential = EmailAuthProvider.credential(user?.email as string, password)

        reauthenticateWithCredential(user, credential)
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            const message =
              error?.code === FIREBASE_CONST.WRONG_PASSWORD
                ? MESSAGES_CONST.INVALID_PASSWORD
                : MESSAGES_CONST.SOMETHING_WENT_WRONG
            console.log('ProfileRootPasswordUpdateForm:::authenticateUser:::' + error?.message)
            toastfunction.error({ message })
            resolve(false)
          })
      } else {
        resolve(false)
      }
    })
  }

  const handleresetpswdmail = async (user: User) => {
    const res = await httpService({
      url: `reset_pswd_template/${user?.email}`,
      method: 'GET',
    })

    if (res) {
      toastfunction.info({ message: 'Maximum limits mail send' })
    }
  }

  const onSubmit = async (data: ICompetitorProfileAccountForm) => {
    setLoading(true)
    const auth = getAuth()
    const user = auth.currentUser
    let userAuthenticated

    if (user) {
      userAuthenticated = await authenticateUser({ user, password: data.oldPassword })
      if (userAuthenticated) handleresetpswdmail(user)
      updatePassword(user, data.newPassword)
        .then(() => {
          reset({
            oldPassword: '',
            newPassword: '',
            cPassword: '',
          })
          toastfunction.success({ message: MESSAGES_CONST.PASSWORD_UPDATED })
        })
        .catch((error) => {
          toastfunction.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
          console.log('ProfileRootPasswordUpdateForm:::onSubmit:::' + error?.code)
          return setLoading(false)
        })
    }

    setLoading(false)
  }

  return (
    <>
      <div className="AccountEmail flex flex-wrap items-center ">
        <label className="mr-8 lg:w-1/4 flex">
          <img src="assets/og_icons/Lock-1.svg" className="w-6 h-6 mr-2" alt="lockIcon" />
          Current password
        </label>
        <div className="w-full md:w-[initial] md:mr-5">
          <div className="w-full md:w-[initial] passFiled relative w-fit flex items-center">
            <input
              type={old_Password}
              placeholder="**********"
              disabled={saved}
              {...register('oldPassword')}
              className={`w-full md:w-96 mt-3 md:mt-0 border rounded-xl placeholder:text-SeabiscuitDark200ThemeColor p-3 h-[51px] !ring-0 ${saved ? 'border-white !bg-white' : 'border-[#D3DAEE]'}`}
            />
            {!saved && (
              <div
                onClick={() => setOld_Password(old_Password === 'password' ? 'text' : 'password')}
                className="absolute right-2"
              >
                <img
                  src="/assets/icon/showPassword.svg"
                  alt="icon"
                  className={`${old_Password === 'password' ? 'brightness-300' : 'brightness-150'}`}
                />
              </div>
            )}
          </div>
          {errors.oldPassword && (
            <MessageHelperComp isError={true} message={errors.oldPassword.message} />
          )}
        </div>

        {!saved && !editPassword && (
          <button
            onClick={() => {
              setEditPassword(true)
            }}
            className="flex mt-2 lg:mt-0 items-center w-[200px] justify-center px-4 font-normal  border border-solid border-SeabiscuitGray500ThemeColor hover:border-SeabiscuitMainThemeColor xl:text-nr 2xl:text-base rounded-xl shadow-sm text-nr text-SeabiscuitDark200ThemeColor hover:text-SeabiscuitMainThemeColor !hover:bg-transparent py-3"
          >
            Change Password
          </button>
        )}
      </div>

      {editPassword && (
        <>
          <div className="AccountEmail flex flex-wrap items-center ">
            <label className="mr-8 w-1/4 flex">
              <img src="assets/og_icons/Lock-1.svg" className="w-6 h-6 mr-2" alt="lockIcon" />
              New password
            </label>
            <div>
              <div className="passFiled relative w-fit flex items-center">
                <input
                  type={new_Passowrd}
                  {...register('newPassword')}
                  placeholder="Enter new password"
                  className="w-96 border !border-[#D3DAEE] text-gray-900 rounded-xl p-3 h-[51px] !ring-0"
                  autoComplete="on"
                />

                <div
                  onClick={() => setNew_Passowrd(new_Passowrd === 'password' ? 'text' : 'password')}
                  className="absolute right-2"
                >
                  <img
                    src="/assets/icon/showPassword.svg"
                    alt="icon"
                    className={`${
                      new_Passowrd === 'password' ? 'brightness-300' : 'brightness-150'
                    }`}
                  />
                </div>
              </div>
              {errors.newPassword && (
                <MessageHelperComp isError={true} message={errors.newPassword.message} />
              )}
            </div>
          </div>

          <div className="AccountEmail flex flex-wrap items-center">
            <label className="mr-8 w-1/4 flex">
              <img src="assets/og_icons/Lock-1.svg" className="w-6 h-6 mr-2" alt="lockIcon" />
              Confirm password
            </label>
            <div className="mr-5">
              <div className="passFiled relative w-fit flex items-center">
                <input
                  type={c_Password}
                  {...register('cPassword')}
                  placeholder="Re-enter new password"
                  className="w-96 border !border-[#D3DAEE] text-gray-900 rounded-xl p-3 h-[51px] !ring-0"
                  autoComplete="on"
                />

                <div
                  onClick={() => setc_Password(c_Password === 'password' ? 'text' : 'password')}
                  className="absolute right-2"
                >
                  <img
                    src="/assets/icon/showPassword.svg"
                    alt="icon"
                    className={`${c_Password === 'password' ? 'brightness-300' : 'brightness-150'}`}
                  />
                </div>
              </div>
              {errors.cPassword && (
                <MessageHelperComp isError={true} message={errors.cPassword.message} />
              )}
            </div>
            <button
              onClick={handleSubmit((d) => {
                setEditPassword(false)
                onSubmit(d)
              })}
              disabled={loading}
              type="submit"
              className="flex items-center w-[200px] justify-center px-4 font-normal  border border-solid border-SeabiscuitGray500ThemeColor hover:border-SeabiscuitMainThemeColor xl:text-nr 2xl:text-base rounded-xl shadow-sm text-nr text-SeabiscuitDark200ThemeColor hover:text-SeabiscuitMainThemeColor !hover:bg-transparent py-3"
            >
              {loading ? (
                <AutorenewRounded fontSize="small" className="animate-spin" />
              ) : (
                'Save new password'
              )}
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default ProfileRootPasswordUpdateForm
