import React, { FC, useState } from 'react'

import { EventResultItem } from '../../../../components/EventResultItem'
import { USEFResultsReport } from '../USEFResultsReport/USEFResultsReport'
import { ReportsTabWrapper } from '../ReportsTabWrapper/ReportsTabWrapper'

import { IReports } from '../../ManageClinicReportsRoot'
import { IEventInterface } from '../../../../../../../../models/events/event.interface'
import { IRegistrationByDayInterface } from '../../../../../../../../models/registrations-by-day/registrationByDay.interface'
import { IHorseData } from '../../../../../../../../models/horse/horse.interface'
import { ITeamMember, IUserInterface } from '../../../../../../../../models/users/user.interface'

interface EventResultsOptions {
  event: IEventInterface | null
  registrationFees: any
  activeOption: IReports
  setActiveOption: (value: IReports) => void
  registeredUsersByDay: IRegistrationByDayInterface[] | null
  horses: IHorseData[] | null
  users: IUserInterface[] | null
  teamMembers: ITeamMember[] | null
}
export const EventResultsReport: FC<EventResultsOptions> = ({
  registrationFees,
  setActiveOption,
  activeOption,
  event,
  registeredUsersByDay,
  horses,
  users,
  teamMembers,
}) => {
  const [activeResult, setActiveResult] = useState('')

  return activeResult === 'USEF Results' ? (
    <USEFResultsReport
      registrationFees={registrationFees}
      activeOption={activeOption}
      setActiveOption={setActiveOption}
      setActiveResult={setActiveResult}
      event={event}
      registeredUsersByDay={registeredUsersByDay}
      horses={horses}
      users={users}
      teamMembers={teamMembers}
    />
  ) : (
    <ReportsTabWrapper
      title="Event Results"
      description="Match classes to section codes to print"
      activeOption={activeOption}
      setActiveOption={setActiveOption}
    >
      <div className="flex flex-wrap gap-x-[2%] gap-y-5">
        <EventResultItem
          isAvailable
          title="USEF Results"
          logo="assets/og_icons/usef.svg"
          onClick={() => setActiveResult('USEF Results')}
        />
        <EventResultItem title="AQHA Results" logo="assets/img/aqha.png" onClick={() => {}} />
      </div>
    </ReportsTabWrapper>
  )
}
