import ITypes from './StaffEligibilityTab.types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const StaffEligibilityTab = (props: ITypes['StaffEligibilityTabProps']) => {
  return (
    <div className="flex justify-center">
      <div className="flex-col">
        <div className="flex justify-center text-SeabiscuitDark200ThemeColor">
          USEF and SafeSport compliance coming soon
        </div>
        <img src="/assets/placeholders/eligibility.svg" alt="eligibility" />
      </div>
    </div>
  )
}

export default StaffEligibilityTab
