import { useState } from 'react'
import { MODAL_CONSTS } from '../../const/modal-const'
import MainModal from './common/MainModal'
import useToasterHelper from '../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../const/messages-const'

// Types

type Props = {
  show: boolean
  handleModal?: any
  dataToPassOn?: {
    alertText?: string
    removeItem: Function
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ListRemoveModal = (props: Props) => {
  // Hooks and vars
  const [loading, setLoading] = useState(false)

  const toastFunctions = useToasterHelper()

  const dataToRender = props?.dataToPassOn?.alertText

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const closeModal = () => {
    props.handleModal(false, MODAL_CONSTS.REMOVE_CONFIRMATION)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const remove = async () => {
    try {
      setLoading(true)
      if (typeof props.dataToPassOn?.removeItem === 'function') {
        await props.dataToPassOn.removeItem?.()
      }
      closeModal()
    } catch (error) {
      setLoading(false)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      return 0
    }
  }

  return (
    <MainModal
      type="REMOVE_CONFIRMATION"
      size="sm"
      show={props.show}
      setHeightAsPerContent={true}
      buttons={[
        {
          label: 'Remove',
          className: 'md:!w-[70%] mb-2',
          bgClass: '!bg-SeabiscuitMainThemeColor',
          onClick: remove,
          loading,
          textClass: '!text-white',
          onHoverClass: 'hover:shadow-slate-300',
        },
        {
          label: 'Cancel',
          onClick: closeModal,
          borderClass: 'border-transparent',
          className: 'md:!mb-0 md:!w-[70%]',
          bgClass: '!bg-SeabiscuitLightThemeColor',
          textClass: '!text-SeabiscuitLightTextColor',
          onHoverClass: 'hover:shadow-slate-300',
        },
      ]}
    >
      <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
        <div className=" pb-2 mx-auto !mt-5 text-SeabiscuitDark200ThemeColor">
          {typeof dataToRender === 'string' ? (
            <h5 className="text-xl leading-normal font-normal">
              {dataToRender ?? 'Are you sure you want to remove?'}
            </h5>
          ) : (
            dataToRender ?? 'Are you sure you want to remove?'
          )}
        </div>
      </div>
    </MainModal>
  )
}

export default ListRemoveModal
