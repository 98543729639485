import { DATA_FIRESTORE_V01_AMOUNT_TRANSFERS } from './amount-transfers/firestore-v01-amount-transfers-const'
import { DATA_FIRESTORE_V01_ASSIGNED_TICKETS } from './assigned-tickets/firestore-v01-assigned-tickets'
import { DATA_FIRESTORE_V01_COMPETITOR_ACCOUNT } from './competitor-account/firestore-v01-competitor-account-const'
import { DATA_FIRESTORE_V01_COMPETITOR_PROFILE } from './competitor-profile/firestore-v01-competitor-profile-const'
import { DATA_FIRESTORE_V01_EVENT_BOOKMARKS } from './event-bookmark/firestore-v01-event-bookmark-const'
import { DATA_FIRESTORE_V01_EVENT_DETAILS } from './event-details/firestore-v01-event-details-const'
import { DATA_FIRESTORE_V01_EVENT_DRAFT_PAPERWORK } from './event-draft-paperwork/firestore-v01-event-draft-paperwork'
import { DATA_FIRESTORE_V01_EVENT_DRAFTS } from './event-drafts/firestore-v01-event-drafts-const'
import { DATA_FIRESTORE_V01_EVENT_FEES } from './event-fees/firestore-v01-event-fees-const'
import { DATA_FIRESTORE_V01_EVENT_NEARBY } from './event-nearby/firestore-v01-event-nearby-const'
import { DATA_FIRESTORE_V01_EVENT_NOTIFICATIONS } from './event-notifications/firestore-v01-event-notifications-const'
import { DATA_FIRESTORE_V01_EVENT_PAPERWORK } from './event-paperwork/firestore-v01-event-paperwork-const'
import { DATA_FIRESTORE_V01_EVENT_POLICIES } from './event-policies/firestore-v01-event-policies-const'
import { DATA_FIRESTORE_V01_EVENT_POST_DETAILS } from './event-post-details/firestore-v01-event-post-details-const'
import { DATA_FIRESTORE_V01_EVENT_POSTS } from './event-posts/firestore-v01-event-posts-const'
import { DATA_FIRESTORE_V01_EVENT_PRIZE_LIST } from './event-prize-list/firestore-v01-event-prize-list-const'
import { DATA_FIRESTORE_V01_EVENT_REGISTERED_USERS } from './event-registered-users/firestore-v01-event-registered-users-const'
import { DATA_FIRESTORE_V01_EVENT_REVIEW_PUBLISH } from './event-review-publish/firestore-v01-event-review-publish-const'
import { DATA_FIRESTORE_V01_EVENT_SCHEDULING } from './event-scheduling/firestore-v01-event-scheduling-const'
import { DATA_FIRESTORE_V01_EVENT_SCORING } from './event-scoring/firestore-v01-event-scoring-const'
import { DATA_FIRESTORE_V01_EVENT_SPONSORS } from './event-sponsors/firestore-v01-event-sponsors-const'
import { DATA_FIRESTORE_V01_EVENT_STAFF } from './event-staff/firestore-v01-event-staff-const'
import { DATA_FIRESTORE_V01_EVENT_TICKETING } from './event-ticketing/firestore-v01-event-ticketing-const'
import { DATA_FIRESTORE_V01_EVENT_VENDORS } from './event-vendors/firestore-v01-event-vendors-const'
import { DATA_FIRESTORE_V01_EVENT_WAITLIST } from './event-waitlist/firestore-v01-event-waitlist-const'
import { DATA_FIRESTORE_V01_EVENTS } from './events/firestore-v01-events-const'
import { DATA_FIRESTORE_V01_FOLLOWING } from './following/firestore-v01-following-const'
import { DATA_FIRESTORE_V01_HORSE_COMPETITION_PAPERWORK } from './horse-competition-paperwork/firestore-v01-horse-competitor-paperwork-const'
import { DATA_FIRESTORE_V01_HORSE_LINEAGE } from './horse-lineage/firestore-v01-horse-lineage-const'
import { DATA_FIRESTORE_V01_HORSE_TEAM } from './horse-team/firestore-v01-users-horse-team-const'
import { DATA_FIRESTORE_V01_HORSE_DOCUMENT } from './horses/firestore-v01-events-const'
import { DATA_FIRESTORE_V01_REFUNDS } from './refunds/firestore-v01-refund-const'
import { DATA_FIRESTORE_V01_SPECTATOR_TICKETS } from './specator-tickets/firestore-v01-spectator-tickets-const'
import { DATA_FIRESTORE_V01_TRANSACTIONS } from './transactions/firestore-v01-transactions-const'
import { DATA_FIRESTORE_V01_USERS_DOCUMENTS_LOGS } from './user-document-logs/firestore-v01-users-documents-const'
import { DATA_FIRESTORE_V01_USERS_DOCUMENTS } from './user-documents/firestore-v01-users-documents-const'
import { DATA_FIRESTORE_V01_USER_HORSE_MAPPING } from './user-horse-mapping/firestore-v01-users-horse-mapping-const'
import { DATA_FIRESTORE_V01_USER_LEGAL_CLAUSES } from './user-legal-clauses/firestore-v01-user-legal-clause-const'
import { DATA_FIRESTORE_V01_USER_NOTIFICATION } from './user-notifications/firestore-v01-user-notifications'
import { DATA_FIRESTORE_V01_USER_PROFILES } from './user-profiles/firestore-v01-user-profiles-const'
import { DATA_FIRESTORE_V01_USERS } from './users/firestore-v01-users-const'
import { DATA_FIRESTORE_V01_REGISTRATIONS_BY_DAY } from './registrations-by-day/firestore-v01-registrations-by-day-const'
import { DATA_FIRESTORE_V01_REGISTRATION_FEES } from './registration-fees/firestore-v01-registrations-fees-const'
import { DATA_FIRESTORE_V01_REGISTRATION_TEAMS } from './registration-teams/firestore-v01-registrations-teams-const'
import { DATA_FIRESTORE_V01_REGISTRATION_TICKET } from './registration-tickets/firestore-v01-registration-tickets-const'
import { DATA_FIRESTORE_V01_RIDER_TEAM_MEMBER } from './rider-team-members/firestore-v01-rider-team-member-const'
import { DATA_FIRESTORE_V01_RECEPIENTS } from './recepients/firestore-v01-recpient-const'
import { DATA_FIRESTORE_V01_REFUND_REQUESTS } from './refund-requests/firestore-v01-refund-request-const'
import { DATA_FIRESTORE_V01_EVENT_PAYMENT_SETTINGS } from './event-payment-settings/firestore-v01-event-payment-setttings-const'
import { DATA_FIRESTORE_V01_GUESTS } from './guests/firestore-v01-guests-const'
import { DATA_FIRESTORE_V01_TICKET_BUYERS } from './ticket-buyers/firestore-v01-ticket-buyers-const'
import { DATA_FIRESTORE_V01_REGISTRATION_FEES_STATS } from './registration-fees-stats/firestore-v01-registrations-fees-stats-const'
import { DATA_FIRESTORE_V01_NEWS } from './news/firestore-v01-news'
import { DATA_FIRESTORE_V01_DEALS } from './deals/firestore-v01-deals'
import { DATA_FIRESTORE_V01_PRODUCTS } from './products/firestore-v01-products'
import { DATA_FIRESTORE_V01_PROFILES } from './profiles/firestore-v01-profile-const'
import { DATA_FIRESTORE_V01_EVENT_RESULTS_REPORTS } from './event-reports/firestore-v01-event-results-reports-const'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export const DATA_FIRESTORE_V01_CONST = {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  COLLECTIONS: {
    COMPETITOR_PROFILE: {
      ...DATA_FIRESTORE_V01_COMPETITOR_PROFILE,
    },

    REGISTRATION_FEES_STATS: {
      ...DATA_FIRESTORE_V01_REGISTRATION_FEES_STATS,
    },

    NEWS: {
      ...DATA_FIRESTORE_V01_NEWS,
    },

    DEALS: {
      ...DATA_FIRESTORE_V01_DEALS,
    },

    PRODUCTS: {
      ...DATA_FIRESTORE_V01_PRODUCTS,
    },

    EVENT_PAYMENT_SETTINGS: {
      ...DATA_FIRESTORE_V01_EVENT_PAYMENT_SETTINGS,
    },

    RECIPIENT: {
      ...DATA_FIRESTORE_V01_RECEPIENTS,
    },

    PROFILES: {
      ...DATA_FIRESTORE_V01_PROFILES,
    },

    TICKET_BUYERS: {
      ...DATA_FIRESTORE_V01_TICKET_BUYERS,
    },

    REFUND_REQUEST: {
      ...DATA_FIRESTORE_V01_REFUND_REQUESTS,
    },

    USER_HORSE_MAPPING: {
      ...DATA_FIRESTORE_V01_USER_HORSE_MAPPING,
    },

    HORSE_TEAM: {
      ...DATA_FIRESTORE_V01_HORSE_TEAM,
    },

    REGISTRATION_BY_DAY: {
      ...DATA_FIRESTORE_V01_REGISTRATIONS_BY_DAY,
    },

    RIDER_TEAM_MEMBER: {
      ...DATA_FIRESTORE_V01_RIDER_TEAM_MEMBER,
    },

    REGISTRATION_FEES: {
      ...DATA_FIRESTORE_V01_REGISTRATION_FEES,
    },

    REGISTRATION_TEAMS: {
      ...DATA_FIRESTORE_V01_REGISTRATION_TEAMS,
    },

    REGISTRATION_TICKET: {
      ...DATA_FIRESTORE_V01_REGISTRATION_TICKET,
    },

    FOLLOWING: {
      ...DATA_FIRESTORE_V01_FOLLOWING,
    },

    REFUNDS: {
      ...DATA_FIRESTORE_V01_REFUNDS,
    },

    EVENT_DETAILS: {
      ...DATA_FIRESTORE_V01_EVENT_DETAILS,
    },

    USER_LEGAL_CLAUSES: {
      ...DATA_FIRESTORE_V01_USER_LEGAL_CLAUSES,
    },

    EVENT_FEES: {
      ...DATA_FIRESTORE_V01_EVENT_FEES,
    },

    EVENT_BOOKMARKS: {
      ...DATA_FIRESTORE_V01_EVENT_BOOKMARKS,
    },

    EVENT_POLICIES: {
      ...DATA_FIRESTORE_V01_EVENT_POLICIES,
    },

    EVENT_TICKETING: {
      ...DATA_FIRESTORE_V01_EVENT_TICKETING,
    },

    TRANSACTIONS: {
      ...DATA_FIRESTORE_V01_TRANSACTIONS,
    },

    TRANSFERS: {
      ...DATA_FIRESTORE_V01_AMOUNT_TRANSFERS,
    },

    SPECTATOR_TICKETS: {
      ...DATA_FIRESTORE_V01_SPECTATOR_TICKETS,
    },

    EVENT_NEARBY: {
      ...DATA_FIRESTORE_V01_EVENT_NEARBY,
    },

    EVENT_NOTIFICATIONS: {
      ...DATA_FIRESTORE_V01_EVENT_NOTIFICATIONS,
    },

    EVENT_PAPERWORK: {
      ...DATA_FIRESTORE_V01_EVENT_PAPERWORK,
    },

    EVENT_DRAFT_PAPERWORK: {
      ...DATA_FIRESTORE_V01_EVENT_DRAFT_PAPERWORK,
    },

    EVENT_POSTS: {
      ...DATA_FIRESTORE_V01_EVENT_POSTS,
    },

    EVENT_POST_DETAILS: {
      ...DATA_FIRESTORE_V01_EVENT_POST_DETAILS,
    },

    EVENT_PRIZE_LISTS: {
      ...DATA_FIRESTORE_V01_EVENT_PRIZE_LIST,
    },

    EVENT_SCHEDULING: {
      ...DATA_FIRESTORE_V01_EVENT_SCHEDULING,
    },

    EVENT_SCORING: {
      ...DATA_FIRESTORE_V01_EVENT_SCORING,
    },

    EVENT_SPONSORS: {
      ...DATA_FIRESTORE_V01_EVENT_SPONSORS,
    },

    EVENT_STAFF: {
      ...DATA_FIRESTORE_V01_EVENT_STAFF,
    },

    EVENT_VENDORS: {
      ...DATA_FIRESTORE_V01_EVENT_VENDORS,
    },

    EVENT_REVIEW_PUBLISH: {
      ...DATA_FIRESTORE_V01_EVENT_REVIEW_PUBLISH,
    },

    EVENTS: {
      ...DATA_FIRESTORE_V01_EVENTS,
    },

    EVENT_REGISTERED_USERS: {
      ...DATA_FIRESTORE_V01_EVENT_REGISTERED_USERS,
    },

    EVENT_DRAFTS: {
      ...DATA_FIRESTORE_V01_EVENT_DRAFTS,
    },

    EVENT_WAITLIST: {
      ...DATA_FIRESTORE_V01_EVENT_WAITLIST,
    },

    USER_PROFILES: {
      ...DATA_FIRESTORE_V01_USER_PROFILES,
    },

    GUESTS: {
      ...DATA_FIRESTORE_V01_GUESTS,
    },

    USERS: {
      ...DATA_FIRESTORE_V01_USERS,
    },

    USERS_DOCUMENTS: {
      ...DATA_FIRESTORE_V01_USERS_DOCUMENTS,
    },

    USER_DOCUMENTS_LOGS: {
      ...DATA_FIRESTORE_V01_USERS_DOCUMENTS_LOGS,
    },

    USERS_ACCOUNT: {
      ...DATA_FIRESTORE_V01_COMPETITOR_ACCOUNT,
    },

    HORSES: {
      ...DATA_FIRESTORE_V01_HORSE_DOCUMENT,
    },

    HORSE_COMPETITION_PAPERWORK: {
      ...DATA_FIRESTORE_V01_HORSE_COMPETITION_PAPERWORK,
    },

    ASSIGNED_TICKETS: {
      ...DATA_FIRESTORE_V01_ASSIGNED_TICKETS,
    },

    HORSE_LINEAGE: {
      ...DATA_FIRESTORE_V01_HORSE_LINEAGE,
    },

    USER_NOTIFICATION: {
      ...DATA_FIRESTORE_V01_USER_NOTIFICATION,
    },

    EVENT_RESULTS_REPORTS: {
      ...DATA_FIRESTORE_V01_EVENT_RESULTS_REPORTS,
    },
  },
}
