import { Timestamp } from 'firebase/firestore'
import TimeLib from '../../lib/Time'
import { IFieldToSaveInUTC } from './model-base.interface'

// ############################################################
/**
 * Contains the model base
 */
// ############################################################

import { cloneDeep } from 'lodash'

type IType<T> = Omit<
  T & {
    u?: boolean
    fieldsToSaveInUTC?: Map<keyof T, IFieldToSaveInUTC<T, keyof T>>
  },
  'toFirestore' | 'toObject' | 'fieldsToSaveInUTC' | 'cloneDeep' | 'clearUpdatedMark' | 'removeKey'
>

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */
export class ModelBaseModel<T> {
  public u: boolean = true
  public fieldsToSaveInUTC = new Map<keyof T, IFieldToSaveInUTC<T, keyof T>>()

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Adds fields that needs to be saved in UTC
   */
  private addUTCFields<K extends keyof T>(utcField: IFieldToSaveInUTC<T, K>) {
    if (utcField?.key) this.fieldsToSaveInUTC.set(utcField.key, utcField)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Takes a string and returns splitted array of it
   */
  getCalculatedNGrams(String: any) {
    let nGrams: string[] = []
    let reg = new RegExp(/\s{2,}/, 'g')
    let nGramWords = new Set<string>()
    let stringWithSingleSpaces: string = (
      typeof String !== 'string' ? '' : (String ?? []).replace(reg, '')
    ).trim()
    let lowercaseWords = stringWithSingleSpaces.toLowerCase()

    ;[lowercaseWords].forEach((word) => {
      let word_iterator = cloneDeep(word)
      while (word_iterator.length > 0) {
        nGramWords.add(cloneDeep(word_iterator))
        word_iterator = word_iterator.slice(0, -1)
      }
    })

    nGramWords.forEach((value) => {
      nGrams.push(value)
    })

    return nGrams
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Returns a UTC timestamp for the given value,
   * which can be a Date or a timestamp with seconds.
   */
  utcTimestamp<K extends keyof T>(keyDetails: IFieldToSaveInUTC<T, K>, toUtc = false) {
    let dateStringToReturn: string = ''
    let value = keyDetails?.value
    let tempValue = value as any
    let options = keyDetails?.options ?? {}

    if (!keyDetails?.key) return keyDetails as any

    this.addUTCFields(keyDetails)

    // Check if the input value is a Date or has a 'seconds' property.
    const isDate = value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)))
    const isTimeStamp = tempValue?.seconds || tempValue?._seconds

    // If the value is nullable and not a Date or timestamp, return it as is.
    if (options.nullable && !isDate && !isTimeStamp) {
      return value as Exclude<T[K], undefined>
    }

    // Convert various input formats to a Date object.
    if (!isDate) {
      if (!value) {
        tempValue = new Date()
      } else if (isTimeStamp) {
        tempValue = tempValue?.toDate?.()
      } else {
        tempValue = new Date(tempValue)
      }
    }

    let value_ = new Date(tempValue)

    if (TimeLib.isInvalidDate(value_)) {
      value_ = new Date()
    }

    if (toUtc) {
      if (keyDetails.changeOnUpdate) dateStringToReturn = Timestamp.now() as any
      else dateStringToReturn = Timestamp.fromDate(value_) as any
    } else {
      dateStringToReturn = value_ as any
    }

    return dateStringToReturn as unknown as Exclude<T[K], undefined>
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Makes a copy of the object (with an id field that contains
   * the firebase ID.
   */
  toObject() {
    let keys = Object.keys(this)
    const objCopy = { ...this } as any

    this.fieldsToSaveInUTC.forEach((value, key) => {
      if (keys.includes(key as any)) {
        objCopy[value.key] = this.utcTimestamp(value)?.toString?.()
      }
    })

    delete (objCopy as any).fieldsToSaveInUTC
    return objCopy as IType<typeof this>
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Returns a copy of the element to write in Firebase.
   */
  toFirestore() {
    let obj = Object.assign({}, this)
    let keys = Object.keys(obj)
    let objCopy = Object.assign({}, this) as any

    obj.fieldsToSaveInUTC.forEach((value, key) => {
      if (keys.includes(key as any)) {
        objCopy[value.key] = this.utcTimestamp(value, true)
      }
    })

    delete (objCopy as any).fieldsToSaveInUTC
    // @ts-ignore
    delete objCopy.id
    return objCopy
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Returns the number
   */
  getNum(number: any) {
    if (!number) return 0

    let num = 0

    if (typeof number === 'string')
      // eslint-disable-next-line no-useless-escape
      num = Number(number.replace(/[^0-9\..]+/gi, ''))
    else num = Number(number)

    if (isNaN(num)) return 0

    return Number(num?.toFixed(1))
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Makes a copy of the object.
   */
  cloneDeep() {
    return cloneDeep(this)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Makes a copy of the object.
   */
  clearUpdatedMark() {
    this.u = false
    return this
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Removes any type of key passed and returns the copy of the object
   */
  removeKey(keyName: keyof typeof this) {
    let obj = Object.assign({}, this)
    let keysToSkip = Object.keys(new ModelBaseModel())
    if (obj.hasOwnProperty(keyName) && !keysToSkip.includes(keyName as any)) {
      // @ts-ignore
      delete obj[keyName]
    }
    // @ts-ignore
    delete obj.id
    return obj as IType<typeof this>
  }
}
