import BussinessDetails from './BussinessDetails'
import SponsorsAndVendors from './sponsor-and-vendor/SponsorsAndVendors'
import Tabs from './Tabs'

const Body = () => {
  return (
    <section className="flex flex-col gap-7">
      <BussinessDetails />
      <div className="flex gap-x-[30px]">
        <Tabs />
        <SponsorsAndVendors />
      </div>
    </section>
  )
}

export default Body
