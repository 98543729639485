import { PDFDocument } from 'pdf-lib'

import { convertDateString, createTable, savePdf } from '../../../../helpers/pdf'
import { IRegistrationByDayInterface } from '../../../../models/registrations-by-day/registrationByDay.interface'
import { IEventInterface } from '../../../../models/events/event.interface'

export const createEntriesMasterListPdf = async (
  event: IEventInterface,
  rows: string[][],
  riders: IRegistrationByDayInterface[],
  horses: string[],
  trainers: string[],
  activeClasses: string[]
) => {
  // Create a new PDF document
  const pdfDoc = await PDFDocument.create()

  const headers = ['Back #', 'Rider • Coach', 'Horse • Trainer', 'Classes', '# Classes']
  const colWidths = [10, 20, 20, 38, 12] // in %

  const printedText = `^^Printed ${convertDateString(new Date(), 'tdm')}^^`
  const titleText = 'Entries'
  const urlText = 'www.thepegasus.app'

  const textAboveTheFirstTable = `**${riders.length} riders** • ${horses.length} horses • ${trainers.length} trainers • ^^${activeClasses.length} active classes^^`

  await createTable({
    pdfDoc,
    headers,
    rows,
    colWidths,
    textAboveTheFirstTable,
    printedText,
    titleText,
    urlText,
    event,
  })

  await savePdf(pdfDoc, `Event Reports - Entries master list - ${event?.eventName}.pdf`)
}
