// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { IonButtons, IonHeader, IonToolbar } from '@ionic/react'
import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { ROUTES_CONST } from '../../../../const/routes-const'
import useGuest from '../../../../hooks/useGuest'
import { useAppSelector } from '../../../../store/hooks'
import { setMenuCollapse } from '../../../../store/system/systemSlice'
import { selectIsLoggedIn } from '../../../../store/user/userSlice'
import TopNavigationRightMenuCommonComponent from './TopNavigationRightMenuCommonComponent'
import TopNavigationSearchComponent from './TopNavigationSearchComponent'
import { Link } from 'react-router-dom'
import { HandleModalContext } from '../../../../layout/mainlayout/MainLayout'

interface CommonNavigationComponentProps {
  title?: string | null
  logo?: any
  backButtonDefaultHref?: string | null
  displayName?: string | null
  handleClick?: any
  menuToggle?: any
  isLoggedIn?: boolean
  handleModal?: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @TODO Document this
 */
const TopNavigationCommonComponent: React.FC<CommonNavigationComponentProps> = (props) => {
  // Hooks and vars
  const dispatch = useDispatch()
  const location = useLocation()
  const loggedIn = useAppSelector(selectIsLoggedIn)

  const guestId = useGuest().guestId
  const handleModal = useContext(HandleModalContext)?.handleModal

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (location.pathname === CONST.ROUTES.ORGANIZER_HOME.URL) {
      dispatch(setMenuCollapse(true))
    }
  }, [dispatch, location.pathname])

  // Functions

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @TODO Document this
   */
  const openLoginRegisterModalFn = () => {
    let confirmed = true
    let isSpectorTicketPage = location.pathname
      .split('/')
      .includes(ROUTES_CONST.TICKETS.PUSH_URL.replace('/', ''))

    if ((guestId || !loggedIn) && isSpectorTicketPage)
      confirmed = window.confirm(
        'Are you sure you want to leave this page? Your changes may not be saved.'
      )

    if (confirmed) handleModal?.(true, MODAL_CONSTS.AUTHENTICATION)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      {loggedIn ? (
        <IonHeader class="ion-no-border fixed top-0 bg-white">
          <IonToolbar>
            <IonButtons className="px-4 justify-between">
              <TopNavigationSearchComponent />
              <TopNavigationRightMenuCommonComponent />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      ) : (
        <IonHeader class="ion-no-border fixed top-0 bg-white">
          <IonToolbar>
            <div className="text-right flex justify-end">
              <div
                onClick={openLoginRegisterModalFn}
                className="flex items-center cursor-pointer w-20 px-2 py-1 right-2 relative rounded-lg justify-center"
              >
                <span className="text-SeabiscuitMainThemeColor hover:text-[#d70443] text-sm">
                  Login
                </span>
              </div>
              <Link to="/signup">
                <div className="flex items-center cursor-pointer border border-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor hover:bg-[#d70443] w-20 py-1.5 px-2 ml-2 right-3 relative rounded-xl justify-center">
                  {/* <img src="/assets/cp_icons/User-4.svg" className="mr-2 w-4 h-4" alt="userIcon" /> */}
                  <span className="text-SeabiscuitWhiteThemeColor text-sm">Sign up</span>
                </div>
              </Link>
            </div>
          </IonToolbar>
        </IonHeader>
      )}
    </>
  )
}

TopNavigationCommonComponent.defaultProps = {
  title: null,
  logo: null,
  backButtonDefaultHref: null,
}

export default TopNavigationCommonComponent
