import { Clear } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
// import { MODAL_CONSTS } from '../../../const/modal-const';
import { CONST } from '../../../const/const'

//redux
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

import { selectProfileCompleted, setUserProfileComplete } from '../../../store/user/userSlice'
import { selectUseMode } from '../../../store/system/systemSlice'

type Props = {}

const UserProfileCompleteConfirmation = (props: Props) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const history = useHistory()
  const profileCompleted = useSelector(selectProfileCompleted)
  const userMode = useAppSelector(selectUseMode)
  const handleCompleteProfile = async (data: Boolean) => {
    if (data) history.push(`${CONST.ROUTES.PROFILE_SETTINGS.URL}?tab=profile`)

    return dispatch(setUserProfileComplete(false))
  }

  const handleteaminvite = () => {}

  return (
    <>
      <div
        className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${
          profileCompleted ? 'show d-block backShadow' : 'hidden'
        }`}
        id="exampleModalCenter"
        aria-labelledby="exampleModalCenterTitle"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered relative w-1/2 m-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col md:max-w-[500px] w-full pointer-events-auto bg-white bg-clip-padding rounded-2xl outline-none text-current mx-auto py-10 px-4">
            <span
              onClick={() => handleCompleteProfile(false)}
              className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
            >
              <Clear
                fontSize={'small'}
                style={{
                  color: 'grey',
                  fontWeight: '400',
                }}
              />
            </span>
            <div className="modal-header flex flex-shrink-0 items-center justify-between pt-0 p-4 rounded-t-md">
              <h5
                className="text-2xl font-semibold leading-normal text-SeabiscuitDark200ThemeColor"
                id="exampleModalScrollableLabel"
              >
                Success!
              </h5>
            </div>
            <div className="modal-body text-SeabiscuitDark200ThemeColor relative px-4 text-nr mb-4 font-poppins">
              <div className="mb-2">Your account is now live.</div>
              <div>
                {userMode === 'competitor'
                  ? 'Complete your profile to streamline event registrations.'
                  : 'Your event organizer account has been created. Complete your profile to start creating events.'}
              </div>
            </div>
            <button
              type="submit"
              onClick={() => handleCompleteProfile(true)}
              className="w-full md:max-w-[60%] md:min-w-fit h-12 rounded-md shadow-sm text-sm mt-8 mx-auto block items-center justify-center py-2 px-4 border border-transparent text-white bg-SeabiscuitMainThemeColor hover:bg-[#d70443] focus:outline-none focus:ring-0 "
            >
              Complete Profile
            </button>
            <button
              type="submit"
              // onClick={() => handleteaminvite()}
              className="w-full md:max-w-[60%] h-12 mx-auto mt-2 block items-center justify-center py-2 px-4 border border-solid border-SeabiscuitMainThemeColor rounded-md shadow-sm text-sm text-SeabiscuitMainThemeColor bg-transparent focus:outline-none focus:ring-0 "
            >
              Invite Team Members
            </button>

            <button
              onClick={() => handleCompleteProfile(false)}
              type="submit"
              className="w-full md:max-w-[60%] md:min-w-fit h-12 rounded-md  shadow-sm text-sm mx-auto transition-all mt-2 block items-center justify-center py-2 px-4 border border-transparent text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor focus:outline-none focus:ring-0"
            >
              Later
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserProfileCompleteConfirmation
