// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useEffect, useState, useRef } from 'react'
import UserRegistrationViewComponentSignup from './UserRegistrationViewComponentSignup'
import { SignupStages } from './UserRegistrationViewComponentContextApi'
import UserRegistrationViewComponentEventOrganizer from './UserRegistrationViewComponentEventOrganizer'
import UserRegistrationViewComponentEventOrganizerVerify from './UserRegistrationViewComponentEventOrganizerVerify'
import UserRegistrationViewComponentSignupForm from './UserRegistrationViewComponentSignupForm'
import appBg from './assets/bg.svg'
import UserRegistrationViewComponentProfileForm from './UserRegistrationViewComponentEventProfileForm'
import UserRegistrationViewComponentLandingContainingDrawer from './UserRegistrationViewComponentLandingContainingDrawer'
import UserRegistrationViewComponentInvite from './UserRegistrationViewComponentEventInvite'
import UserRegistrationViewComponentVendorForm from './UserRegistrationViewComponentVendorForm'
import UserRegistrationViewComponentVendorProfileForm from './UserRegistrationViewComponentVendorProfileForm'
import UserRegistrationViewComponentCompetitorOrganizer from './UserRegistrationViewComponentCompetitorOrganizer'
import UserRegistrationViewComponentCompetitorProfileForm from './UserRegistrationViewComponentCompetitorProfileForm'
import UserRegistrationViewComponentVendorProducts from './UserRegistrationViewComponentVendorProducts'
import UserRegistrationViewComponentCompetitorOrganizerVerify from './UserRegistrationViewComponentCompetitorOrganizerVerify'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface UserRegistrationViewComponentProps {
  userData: any
  onRegistrationStartNextValid: any
  onCompetitorRegistrationUSEFNumberValid: any
  onCompetitorRegistrationProfileValid: any
  onCompetitorRegistrationImageChanged: any
  onCompetitorRegistrationCancelImageClick: any
  onOrganizerRegistrationUSEFNumberValid: any
  onOrganizerRegistrationProfileValid: any
  onVendorRegistrationVendorFormValid: any
  onVendorRegistrationProfileValid: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const UserRegistrationViewComponent: React.FC<UserRegistrationViewComponentProps> = (props) => {
  // handle stages and pages at each moment:
  const signupStagesState = useState<{ selector: string; stage: string }>({
    selector: '',
    stage: 'Init',
  })

  // for back button
  const prevStage = useRef<{ selector: string; stage: string }>({ selector: '', stage: 'Init' })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    prevStage.current = signupStagesState[0]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupStagesState[0]])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <SignupStages.Provider value={[...signupStagesState, prevStage]}>
      <div className="p-6 min-h-full bg-cover" style={{ backgroundImage: `url(${appBg})` }}>
        {/* <img src={appBg} /> */}
        {signupStagesState[0]?.stage === 'Init' && (
          <UserRegistrationViewComponentSignup
            onRegistrationStartNextValid={props.onRegistrationStartNextValid}
          />
        )}
        {signupStagesState[0]?.selector === 'organizer' &&
          (function () {
            switch (signupStagesState[0]?.stage) {
              case 'EventOrg':
                return (
                  <UserRegistrationViewComponentEventOrganizer
                    onOrganizerRegistrationUSEFNumberValid={
                      props.onOrganizerRegistrationUSEFNumberValid
                    }
                  />
                )
              case 'EventOrgVerify':
                return <UserRegistrationViewComponentEventOrganizerVerify />
              case 'SignUp':
                return <UserRegistrationViewComponentSignupForm />
              case 'Profile':
                return (
                  <UserRegistrationViewComponentProfileForm
                    onOrganizerRegistrationProfileValid={props.onOrganizerRegistrationProfileValid}
                  />
                )
              case 'Invite':
                return <UserRegistrationViewComponentInvite />
              case 'Landing':
                return <UserRegistrationViewComponentLandingContainingDrawer />
            }
          })()}
        {signupStagesState[0]?.selector === 'competitor' &&
          (function () {
            switch (signupStagesState[0]?.stage) {
              case 'CompOrg':
                return (
                  <UserRegistrationViewComponentCompetitorOrganizer
                    onCompetitorRegistrationUSEFNumberValid={
                      props.onCompetitorRegistrationUSEFNumberValid
                    }
                  />
                )
              case 'CompOrgVerify':
                return <UserRegistrationViewComponentCompetitorOrganizerVerify />
              case 'SignUp':
                return <UserRegistrationViewComponentSignupForm />
              case 'Profile':
                return (
                  <UserRegistrationViewComponentCompetitorProfileForm
                    userData={props.userData}
                    onCompetitorRegistrationImageChanged={
                      props.onCompetitorRegistrationImageChanged
                    }
                    onCompetitionRegistrationCancelImageClick={
                      props.onCompetitorRegistrationCancelImageClick
                    }
                    onCompetitorRegistrationProfileValid={
                      props.onCompetitorRegistrationProfileValid
                    }
                  />
                )
              case 'Landing':
                return <UserRegistrationViewComponentLandingContainingDrawer />
            }
          })()}
        {signupStagesState[0]?.selector === 'vendor' &&
          (function () {
            switch (signupStagesState[0]?.stage) {
              case 'VendorForm':
                return (
                  <UserRegistrationViewComponentVendorForm
                    onVendorRegistrationVendorFormValid={props.onVendorRegistrationVendorFormValid}
                  />
                )
              case 'Profile':
                return (
                  <UserRegistrationViewComponentVendorProfileForm
                    userData={props.userData}
                    onVendorRegistrationProfileValid={props.onVendorRegistrationProfileValid}
                  />
                )
              case 'Landing':
                return <UserRegistrationViewComponentLandingContainingDrawer />
              case 'Products':
                return <UserRegistrationViewComponentVendorProducts />
            }
          })()}
      </div>
    </SignupStages.Provider>
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
UserRegistrationViewComponent.defaultProps = {
  userData: null,
  onRegistrationStartNextValid: null,
  onCompetitorRegistrationUSEFNumberValid: null,
  onCompetitorRegistrationProfileValid: null,
  onCompetitorRegistrationImageChanged: null,
  onCompetitorRegistrationCancelImageClick: null,
  onOrganizerRegistrationUSEFNumberValid: null,
  onOrganizerRegistrationProfileValid: null,
  onVendorRegistrationVendorFormValid: null,
  onVendorRegistrationProfileValid: null,
}

export default UserRegistrationViewComponent
