import React from 'react';
import { IonContent, IonPage, IonText } from '@ionic/react';

const OfflineScreen: React.FC = () => {
  return (
    <IonPage>
      <IonContent className="ion-padding">
        <div className="flex flex-col items-center justify-center h-full">
          <IonText color="danger">
            <h2>You are offline</h2>
          </IonText>
          <p>Please check your internet connection and try again.</p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default OfflineScreen;