type IProps = {
  text: string
  imgSrc: string
  buttonText: string
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const NoDataAvailable = (props: IProps) => {
  return (
    <div className="h-[500px] justify-center flex items-center w-full flex-col">
      <div className="mb-10 text-nr">{props.text}</div>
      <div className="border-[3px] border-solid border-[#E6E6E6] rounded-xl px-16 py-3 flex flex-col items-center">
        <div className="flex w-full justify-center my-1">
          {[...Array(3)].map((c, i) => {
            return (
              <div className="w-[40px] h-1.5 rounded-2xl bg-[#E6E6E6] mx-2" key={`${c}${i}`}></div>
            )
          })}
        </div>
        <div className="my-6 rounded-full bg-SeabiscuitMainThemeColor flex justify-center items-center p-3">
          <img className="w-10 h-10" src={props.imgSrc} alt="alt" />
        </div>
        <button className="bg-SeabiscuitMainThemeColor text-white w-fit px-8 py-2.5 rounded-xl mb-8 text-sm">
          {props.buttonText}
        </button>
      </div>
    </div>
  )
}

export default NoDataAvailable
