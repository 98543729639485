// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import L from 'leaflet'

// import { useMap } from 'react-leaflet';
import marker from 'leaflet/dist/images/marker-icon.png'
import marker2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/leaflet.css'
import { GoogleMap, Marker } from '@react-google-maps/api'

// const ResizeMap = () => {
//     const map = useMap();
//     setTimeout(() => map.invalidateSize(), 100);
//     return null;
// };

delete L.Icon.Default.prototype['_getIconUrl' as any as keyof L.Icon.Default]

L.Icon.Default.mergeOptions({
  iconRetinaUrl: marker2x,
  iconUrl: marker,
  shadowUrl: markerShadow,
})

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const OrganizerSideProfileMinimap: React.FC<{ lat?: any; long?: any; title: string }> = ({
  lat,
  long,
  title,
}) => {
  const center = (function () {
    if (!lat || !long) return
    return {
      lat,
      lng: long,
    }
  })()

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  return (
    <div className="overflow-hidden">
      <div className="overflow-hidden rounded-xl">
        <div className="h-[400px] w-full">
          <GoogleMap zoom={10} center={center} mapContainerClassName="h-[100%]">
            {center ? (
              <Marker position={center} title={title} icon="/assets/cp_icons/FinalMapPin.svg" />
            ) : null}
          </GoogleMap>
        </div>
      </div>
    </div>
  )
}

export default OrganizerSideProfileMinimap
