import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { useIonRouter } from '@ionic/react'
import { cloneDeep } from 'lodash'

import EventReviewPublishFormComponent from '../../../components/events/forms/review-publish/EventReviewPublishFormComponent'

import { selectPublishedEvent } from '../../../store/events/eventsSlice'
import { useAppSelector } from '../../../store/hooks'
import { selectUserId } from '../../../store/user/userSlice'

import useToasterHelper from '../../../helpers/ToasterHelper'

import { EventReviewPublishModel } from '../../../models/event-review-publish/event-review-publish.model'

import FirestoreService from '../../../services/firestoreService'

import { CONST } from '../../../const/const'
import { MODAL_CONSTS } from '../../../const/modal-const'

interface ShowsDetailPageEventNearbyBusinessesFormSubSectionProps {
  onSetEventTab: (tab: string) => void
  onSetNextEventTab: (tab: string) => void
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn?: any
  mainEvent?: any
  nextEventTab: string
  eventTab: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsDetailPageEventNearbyBusinessesFormSubSection: React.FC<
  ShowsDetailPageEventNearbyBusinessesFormSubSectionProps
> = (props) => {
  const userId = useAppSelector(selectUserId)
  const publishedEvent = useAppSelector(selectPublishedEvent)
  const router = useIonRouter()
  const history = useHistory()
  const { eventId } = useParams<{ eventId: string }>()

  const [data, setData] = useState<any>(null)
  const toasterFunctions = useToasterHelper()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (props.nextEventTab != '') {
      props.onSetEventTab(props.nextEventTab)
    }
  }, [props.nextEventTab])
  const validFormHandler = async (data: any) => {
    const updated_data = new EventReviewPublishModel({
      ...data,
      owner: userId,
      id: eventId,
    })

    props?.handleModal(true, MODAL_CONSTS.EVENT_PUBLISH_DONE_MODAL, {
      eventId,
      updated_data,
      submissionType: 'publish',
      mainEvent: props.mainEvent,
      onSetEventTab: props.onSetEventTab,
    })
  }

  // // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // /**
  //  * @todo Document this
  //  */
  const updateEventStatusExistAndSave = () => {
    try {
      let eventDetails = cloneDeep(props.mainEvent)
      eventDetails.status = 'current'

      FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME,
        eventId,
        eventDetails.toFirestore()
      )

      router.push(CONST.ROUTES.ORGANIZER_HOME.URL)
      history.push(CONST.ROUTES.ORGANIZER_HOME.URL)
    } catch (err) {
      toasterFunctions.info({ message: 'Something went wrong! please try again later' })
      console.error('202206261335:' + err)
    } finally {
      setLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const validAndExitFormHandler = async (data: any) => {
    updateEventStatusExistAndSave()
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const eventDeleteHandler = async (data: any) => {
    await FirestoreService.deleteItem(CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS.NAME, eventId)

    router.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
    history.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidFormHandler = async (data: any) => {
    alert('invalid')
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const invalidAndExitFormHandler = async (data: any) => {
    alert('invalid')
    router.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
    history.push(`${CONST.ROUTES.MANAGE.CLINIC_N_OTHER.URL}/${eventId}`)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onViewEventButtonPressedHandler = () => {
    router.push(CONST.ROUTES.ORGANIZER_HOME.URL)
    history.push(CONST.ROUTES.ORGANIZER_HOME.URL)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        const doc = await FirestoreService.getItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
          eventId
        )
        if (doc) {
          setData(EventReviewPublishModel.fromFirestoreDoc(doc).toObject())
        }
      }
      fetchData().catch()
    }
  }, [data, setData, eventId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <>
      <EventReviewPublishFormComponent
        publishedEvent={publishedEvent}
        data={data}
        onSetEventTab={props.onSetEventTab}
        onInvalid={invalidFormHandler}
        loading={loading}
        onInvalidAndExit={invalidAndExitFormHandler}
        onValid={validFormHandler}
        onValidAnExit={validAndExitFormHandler}
        onViewEventButtonPressed={onViewEventButtonPressedHandler}
        onDeleteEventButtonPressed={eventDeleteHandler}
      />
    </>
  )
}

export default ShowsDetailPageEventNearbyBusinessesFormSubSection
