import React, { useEffect, useState } from 'react'

// Third party
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material'
import { where } from 'firebase/firestore'

// Components
import ViewsLoader from '../../../components/loader/ViewsLoader'
import Accordion from '../../../components/accordion/common/Accordion'
import ExhibitorRidingDetailsAccordion from './accordions/ExhibitorRidingDetailsAccordion'
import ExhibitorCompetitionNumbersAccordion from './accordions/ExhibitorCompetitionNumbersAccordion'
import ExhibitorBiographicalInformation from './accordions/ExhibitorBiographicalInformation'

import { formatDate } from '../../../helpers/helpers'
import helpers from '../../../commonHelpers/helpers'

import FirestoreService from '../../../services/firestoreService'

import { getConvertedData, getReactPickerDate } from '../../../models/interface.helper'
import { RiderTeamMemberModel } from '../../../models/rider-team-member/riderTeamMember.model'
import { IRiderTeamMemberInterface } from '../../../models/rider-team-member/riderTeamMember.interface'
import { TEventRegisteredUsers } from '../../../models/event-registered-users/event-registered-users.interface'

import { CONST } from '../../../const/const'
import { IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'

// Types
type IExhibitorProfileTeamTabProps = {
  exhibitorAllData?: TEventRegisteredUsers | null
  isRecipient?: boolean
}

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const ExhibitorProfileTeamTab = ({ exhibitorAllData }: IExhibitorProfileTeamTabProps) => {
  const [usersProfilesData, setUsersProfilesData] = useState<IUserInterface[]>([])
  const [riderTeamMembers, setRiderTeamMembers] = useState<IRiderTeamMemberInterface[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchUserProfileData = async () => {
      let users: IUserInterface[] = []
      let riderTeamMembers_: IRiderTeamMemberInterface[] = []
      let usersProfilesData_: IUserInterface[] = []

      const usersSnaps = await FirestoreService.filterItems(COLLECTIONS.USERS.NAME)

      if (usersSnaps.size) {
        usersSnaps.forEach((currDoc) => {
          users.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
        })
      }

      const riderTeamMembersSnaps = await FirestoreService.filterItems(
        COLLECTIONS.RIDER_TEAM_MEMBER.NAME,
        [
          where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.EVENT_ID.KEY, '==', exhibitorAllData?.eventId),
          where(COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.USER_ID.KEY, '==', exhibitorAllData?.userId),
          where(
            COLLECTIONS.RIDER_TEAM_MEMBER.FIELDS.REGISTRATION_DOC_ID.KEY,
            '==',
            exhibitorAllData?.id
          ),
        ]
      )

      if (riderTeamMembersSnaps.size) {
        riderTeamMembersSnaps.forEach((currDoc) => {
          const riderTeamMember = getConvertedData(
            RiderTeamMemberModel.fromFirestoreDoc(currDoc).toObject()
          )
          const isExist = riderTeamMembers_.find(
            (member) => member.teamMemberId === riderTeamMember.teamMemberId
          )
          if (!isExist?.id) riderTeamMembers_.push(riderTeamMember)
        })
      }

      riderTeamMembers_.forEach((riderTeamMember) => {
        const currentUSer = users.find((user) => user.id === riderTeamMember.teamMemberId)
        if (currentUSer?.id) usersProfilesData_.push(currentUSer)
      })

      setUsersProfilesData(usersProfilesData_)
      setRiderTeamMembers(riderTeamMembers_)
    }

    fetchUserProfileData()
      .then(() => null)
      .finally(() => setLoading(false))
  }, [exhibitorAllData?.userId])

  const [isOpen, setIsOpen] = useState(false)

  if (loading) {
    return (
      <>
        <div className="h-[50vh] min-h-[200px] flex justify-center items-center">
          <ViewsLoader size="lg" color="#F7074F" />
        </div>
      </>
    )
  }
  if (!usersProfilesData[0]) {
    return <></>
  }

  return (
    <>
      {usersProfilesData.map((userProfileData, i) => {
        const data = [
          {
            title: 'Name',
            content: userProfileData?.userFirstName + ' ' + userProfileData?.userLastName || '',
          },
          {
            title: 'Nationality',
            content: userProfileData?.userNationality || '',
          },
          {
            title: 'Date of Birth',
            content: userProfileData
              ? formatDate(getReactPickerDate(userProfileData?.userDOB))
              : '',
          },
          {
            title: 'Phone',
            content: userProfileData?.userPhoneNumber || '',
          },
          {
            title: 'Email',
            content: userProfileData?.userEmail || '',
          },
        ]

        const dataRider = [
          {
            title: 'Discipline',
            content: userProfileData?.userDiscipline || '',
          },
          {
            title: 'Zone',
            content: userProfileData?.userZone || '',
          },
          {
            title: 'Amateur Pro Status',
            content: userProfileData?.userAmateur || '',
          },
          {
            title: 'Judging License',
            content: userProfileData?.userJudgingLicense
              ? (userProfileData.userJudgingLicense as string)
              : 'No License',
          },
          {
            title: 'Foreign Endorsement Letter',
            content: 'View Document',
          },
          {
            title: 'Background Check',
            content: userProfileData ? formatDate(userProfileData.userBackgroundCheckExpiry) : '',
          },
          {
            title: 'SafeSport Status',
            content: userProfileData ? formatDate(userProfileData.userSafeSupportExpiry) : '',
          },
        ]

        const dataNumbers = [
          {
            title: 'USEF number',
            content: userProfileData?.userUSEF?.documentNumber || 'No number on file',
            endDate: userProfileData?.userUSEF?.endDate
              ? formatDate(userProfileData?.userUSEF.endDate)
              : '',
            membershipStatus: userProfileData?.userUSEF?.membershipStatus || '',
            image: userProfileData?.userUSEF?.image || '',
          },
          {
            title: 'FEI number',
            content: userProfileData?.userFEI?.documentNumber || 'No number on file',
            endDate: userProfileData?.userFEI?.endDate
              ? formatDate(userProfileData?.userFEI.endDate)
              : '',
            membershipStatus: userProfileData?.userFEI?.membershipStatus || '',
            image: userProfileData?.userFEI?.image || '',
          },
          {
            title: 'USDF number',
            content: userProfileData?.userUSDF?.documentNumber || 'No number on file',
            endDate: userProfileData?.userUSDF?.endDate
              ? formatDate(userProfileData?.userUSDF.endDate)
              : '',
            membershipStatus: userProfileData?.userUSDF?.membershipStatus || '',
            image: userProfileData?.userUSDF?.image || '',
          },
          {
            title: 'USEA number',
            content: userProfileData?.userUSEA?.documentNumber || 'No number on file',
            endDate: userProfileData?.userUSEA?.endDate
              ? formatDate(userProfileData?.userUSEA.endDate)
              : '',
            membershipStatus: userProfileData?.userUSEA?.membershipStatus || '',
            image: userProfileData?.userUSEA?.image || '',
          },
          {
            title: 'USHJA number',
            content: userProfileData?.userUSHJA?.documentNumber || 'No number on file',
            endDate: userProfileData?.userUSHJA?.endDate
              ? formatDate(userProfileData?.userUSHJA.endDate)
              : '',
            membershipStatus: userProfileData?.userUSHJA?.membershipStatus || '',
            image: userProfileData?.userUSHJA?.image || '',
          },
          {
            title: 'AHHS number',
            content: userProfileData?.userAHHS?.documentNumber || 'No number on file',
            endDate: userProfileData?.userAHHS?.endDate
              ? formatDate(userProfileData?.userAHHS.endDate)
              : '',
            membershipStatus: userProfileData?.userAHHS?.membershipStatus || '',
            image: userProfileData?.userAHHS?.image || '',
          },
          {
            title: 'AMHA number',
            content: userProfileData?.userAMHA?.documentNumber || 'No number on file',
            endDate: userProfileData?.userAMHA?.endDate
              ? formatDate(userProfileData?.userAMHA?.endDate)
              : '',
            membershipStatus: userProfileData?.userAMHA?.membershipStatus || '',
            image: userProfileData?.userAMHA?.image || '',
          },
          {
            title: 'ARHPA number',
            content: userProfileData?.userARHPA?.documentNumber || 'No number on file',
            endDate: userProfileData?.userARHPA?.endDate
              ? formatDate(userProfileData?.userARHPA?.endDate)
              : '',
            membershipStatus: userProfileData?.userARHPA?.membershipStatus || '',
            image: userProfileData?.userARHPA?.image || '',
          },
          {
            title: 'ASHA number',
            content: userProfileData?.userASHA?.documentNumber || 'No number on file',
            endDate: userProfileData?.userASHA?.endDate
              ? formatDate(userProfileData?.userASHA.endDate)
              : '',
            membershipStatus: userProfileData?.userASHA?.membershipStatus || '',
            image: userProfileData?.userASHA?.image || '',
          },
          {
            title: 'UPHA number',
            content: userProfileData?.userUPHA?.documentNumber || 'No number on file',
            endDate: userProfileData?.userUPHA?.endDate
              ? formatDate(userProfileData?.userUPHA.endDate)
              : '',
            membershipStatus: userProfileData?.userUPHA?.membershipStatus || '',
            image: userProfileData?.userUPHA?.image || '',
          },
          {
            title: 'WDAA number',
            content: userProfileData?.userWDAA?.documentNumber || 'No number on file',
            endDate: userProfileData?.userWDAA?.endDate
              ? formatDate(userProfileData?.userWDAA.endDate)
              : '',
            membershipStatus: userProfileData?.userWDAA?.membershipStatus || '',
            image: userProfileData?.userWDAA?.image || '',
          },
        ]

        if (userProfileData?.userExtraDocument && userProfileData.userExtraDocument?.length > 0) {
          const extraNumbers = userProfileData.userExtraDocument?.map((extraNumber) => ({
            title: extraNumber.documentFullName,
            content: extraNumber?.documentNumber || 'No number on file',
            endDate: extraNumber?.endDate ? formatDate(extraNumber.endDate) : '',
            membershipStatus: extraNumber?.membershipStatus || '',
            image: extraNumber?.image || '',
          }))

          if (extraNumbers && extraNumbers.length > 0) dataNumbers.push(...extraNumbers)
        }

        const role = riderTeamMembers.find(
          (riderTeamMember) => riderTeamMember.teamMemberId === userProfileData.id
        )

        return (
          <React.Fragment key={i}>
            <Accordion
              icon={<AddCircleOutline className="add_icon text-[red]" />}
              initialState={false}
              className="mb-2"
              header={
                <div className={'flex gap-2'}>
                  <div>
                    <img
                      alt="icons"
                      onError={(e) =>
                        ((e.target as any).src =
                          `https://ui-avatars.com/api/?name=${helpers.generateName(`${userProfileData?.userFirstName + ' ' + userProfileData?.userLastName}`)}&background=FFFFFF&format=svg&bold=true&color=BCC6D5&rounded=true`)
                      }
                      src={userProfileData.userProfilePicture || ''}
                      className="w-10 h-10 mr-2 border border-solid object-cover rounded-md"
                    />
                  </div>
                  <div>
                    <p className={'text-[#122B46] font-normal text-[14px]'}>
                      {userProfileData.userFirstName} {userProfileData.userLastName}
                    </p>
                    {role?.teamMemberRole && (
                      <p className={'text-[#77809c] font-normal text-[12px]'}>
                        Role • {role.teamMemberRole}
                      </p>
                    )}
                  </div>
                </div>
              }
              headerTextClassName="text-SeabiscuitDark200ThemeColor capitalize w-full pr-2"
              onOpen={() => {
                setIsOpen(true)
              }}
              onClose={() => {
                setIsOpen(false)
              }}
              activeIcon={<RemoveCircleOutline className="add_icon text-[red]" />}
            >
              <>
                <div className={'pt-4 mt-4'}>
                  <div
                    className={`text-SeabiscuitDark200ThemeColor mb-2 flex justify-between items-center ${isOpen ? 'font-normal' : 'font-bold'}`}
                  >
                    Biography
                    <div className="flex items-center details"></div>
                  </div>

                  <ExhibitorBiographicalInformation data={data} />
                </div>
                <div className={'pt-4 mt-4 border-t'}>
                  <div
                    className={`text-SeabiscuitDark200ThemeColor mb-2 flex justify-between items-center ${isOpen ? 'font-normal' : 'font-bold'}`}
                  >
                    Riding Details
                    <div className="flex items-center details"></div>
                  </div>

                  <ExhibitorRidingDetailsAccordion data={dataRider} />
                </div>
                <div className={'pt-4 mt-4 border-t'}>
                  <div
                    className={`text-SeabiscuitDark200ThemeColor mb-2 flex justify-between items-center ${isOpen ? 'font-normal' : 'font-bold'}`}
                  >
                    Competition Numbers
                    <div className="flex items-center details"></div>
                  </div>
                  <ExhibitorCompetitionNumbersAccordion dataNumbers={dataNumbers} />
                </div>
              </>
            </Accordion>
          </React.Fragment>
        )
      })}
    </>
  )
}

export default ExhibitorProfileTeamTab
