// import React from "react";
import './SwitchElement.css'
import clsx from 'clsx'

const SwitchElement = ({
  on,
  onSwitch,
  buttonClassName,
  textClassName,
  switchClassName,
  checkedMessage,
  uncheckedMessage,
  disabled,
}) => {
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onSwitch}
      className={clsx(
        buttonClassName,
        'relative tw-button',
        on ? 'bg-SeabiscuitGreenThemeColor yes' : 'bg-gray-200 no'
      )}
      value={on ? 'Yes' : 'No'}
    >
      <span className={clsx('bg-SeabiscuitWhiteThemeColor', switchClassName)}></span>
      <div
        className={clsx(
          'btn-text text-bas',
          on
            ? `yes ${textClassName} text-SeabiscuitWhiteThemeColor`
            : `no ${textClassName} text-[#1F417380]`
        )}
      >
        {on ? checkedMessage : uncheckedMessage}
      </div>
    </button>
  )
}

export default SwitchElement
