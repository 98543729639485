import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import './tailwind.css'
import { Provider } from 'react-redux'
import { IonReactRouter } from '@ionic/react-router'
import store from './store/store'
import { IonApp } from '@ionic/react'

/**
 * Entry point of the application.
 *
 * Wraps the `App` component with Redux `Provider` for state management.
 * Renders the application root using `createRoot` for concurrent rendering.
 */
function AppRoot() {
  return (
    <React.StrictMode>
      <IonReactRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </IonReactRouter>
    </React.StrictMode>
  )
}

// Use `createRoot` for concurrent rendering
const rootElement = document.getElementById('root')
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(<AppRoot />)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note that this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
