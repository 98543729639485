import clsx from 'clsx'
import { Link } from 'react-router-dom'

// Types
type IProps =
  | {
      mode: 'text'
      text: string
      containerClassName?: string
      upperTextLink?: string
      upperTextClassName?: string
      lowerTextClassName?: string
      upperText?: string
      imageSource?: string
      imageClassName?: string
      imageAlt?: string
      bottomTextLink?: string
      bottomText?: string
    }
  | {
      mode: 'graphic'
      imageAlt: string
      handleModal?: any
      textColor?: string
      upperText?: string
      imageSource: string
      bottomText?: string
      upperTextLink?: string
      imageClassName?: string
      bottomTextLink?: string
      containerClassName?: string
      upperTextClassName?: string
      lowerTextClassName?: string
    }

/**
 * @TODO Document this
 */
const DataNotAvailable = (props: IProps) => {
  if (props.mode === 'text') {
    return <div className={clsx('w-full', props.containerClassName)}>{props.text}</div>
  } else if (props.mode === 'graphic') {
    return (
      <div
        className={clsx(
          'my-10 mt-36 mx-5 flex items-center justify-center flex-col w-full',
          props.containerClassName
        )}
      >
        {props.upperTextLink ? (
          <Link
            to={props.upperTextLink}
            className={clsx(
              'text-SeabiscuitMainThemeColor cursor-pointer text-nr',
              props.upperTextClassName
            )}
          >
            {props.upperText}
          </Link>
        ) : (
          <div className="text-SeabiscuitDark200ThemeColor text-nr">{props.upperText}</div>
        )}

        <img
          src={props.imageSource}
          className={clsx(
            'w-[30%] md:max-w-[400px] md:max-h-[2000px] lg:max-h-[250px] max-w-full my-8',
            props.imageClassName
          )}
          alt={props.imageAlt}
        />

        {props.bottomTextLink ? (
          <Link
            to={props.bottomTextLink}
            className={clsx(
              'text-SeabiscuitMainThemeColor cursor-pointer text-nr',
              props.lowerTextClassName
            )}
          >
            {props.bottomText}
          </Link>
        ) : (
          <div className="text-SeabiscuitDark200ThemeColor text-nr">{props.bottomText}</div>
        )}
      </div>
    )
  } else return null
}

export default DataNotAvailable
