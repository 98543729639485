import clsx from 'clsx'
import customImageComponent from '../../../../components/common/CustomImageComponent'
import ExpandableSelector from '../../../../components/elements/select-lists/ExpandableSelector'
import { IMAGE_CONSTS } from '../../../../const/image-const'
import { IProductInterface } from '../../../../models/product/product.interface'
import React from 'react'

type IProps = {
  watch: any
  name: string
  register: any
  setValue: any
  index: number
  loading: boolean
  product: IProductInterface
  onEditButtonClick: (index: number) => void
  onRemoveButtonClick: (index: number) => void
}

const Product = React.forwardRef<any, IProps>((props: IProps, _) => {
  const loading = props.loading
  const product = props.product

  return (
    <div className="flex gap-2 text-nr text-SeabiscuitLightParagraphTextColor">
      <div className="flex gap-2 items-center w-60 text-SeabiscuitDark200ThemeColor h-[56px] shrink-0">
        <div
          className={clsx(
            'shrink-0 rounded-md',
            loading ? '!bg-SeabiscuitSkeletonColor w-8 h-8' : 'w-6 h-6'
          )}
        >
          {loading ? null : (
            <img
              alt="tag"
              src="/assets/og_icons/Tags-1.svg"
              className="w-6 h-6 shrink-0 rounded-md"
            />
          )}
        </div>

        <div
          className={clsx(
            'text-SeabiscuitDark200ThemeColor capitalize leading-[1] w-24 ellipsis rounded-md',
            loading && '!bg-SeabiscuitSkeletonColor !h-[32px]'
          )}
        >
          {loading ? null : `Product ${props.index + 1}`}
        </div>
      </div>

      <div className={clsx('flex flex-wrap gap-2', loading && 'animate-pulse')}>
        <div
          role="button"
          onClick={() => props.onEditButtonClick(props.index)}
          className="flex items-center w-60 rounded-xl border border-SeabiscuitGray500ThemeColor gap-3 px-2 py-1.5 text-SeabiscuitDark200ThemeColor h-[56px] shrink-0"
        >
          <div
            className={clsx(
              'w-8 h-8 shrink-0 rounded-md',
              loading && '!bg-SeabiscuitSkeletonColor'
            )}
          >
            {loading
              ? null
              : customImageComponent(
                  product.pictures?.[0] ?? IMAGE_CONSTS.PLACEHOLDERS.NEWS,
                  '',
                  'object-cover w-full h-full rounded-md'
                )}
          </div>

          <div
            className={clsx(
              'text-SeabiscuitDark200ThemeColor capitalize w-[calc(100%-55px)] ellipsis rounded-md',
              loading && '!bg-SeabiscuitSkeletonColor !h-[32px]'
            )}
          >
            {loading ? null : product.name}
          </div>
        </div>

        <div className="flex items-center w-[130px] rounded-xl border border-SeabiscuitGray500ThemeColor gap-3 px-2 py-1.5 h-[56px] shrink-0">
          <div
            className={clsx(
              'shrink-0 rounded-md',
              loading ? '!bg-SeabiscuitSkeletonColor w-8 h-8' : 'w-6 h-6'
            )}
          >
            {loading ? null : (
              <img
                alt="tag"
                src="/assets/og_icons/Price Tag.svg"
                className="w-6 h-6 shrink-0 rounded-md"
              />
            )}
          </div>

          <div
            className={clsx(
              'text-SeabiscuitLightParagraphTextColor capitalize leading-[1] w-[calc(100%-40px)] ellipsis rounded-md',
              loading && '!bg-SeabiscuitSkeletonColor !h-[32px]'
            )}
          >
            {loading ? null : `$${product.discountPrice ?? product.price}`}
          </div>
        </div>

        <div className="flex items-center w-[130px] rounded-xl border border-SeabiscuitGray500ThemeColor gap-3 px-2 py-1.5 h-[56px] shrink-0">
          <div
            className={clsx(
              'shrink-0 rounded-md',
              loading ? '!bg-SeabiscuitSkeletonColor w-8 h-8' : 'w-6 h-6'
            )}
          >
            {loading ? null : (
              <img
                alt="tag"
                src="/assets/og_icons/Sale Price Tag.svg"
                className="w-6 h-6 shrink-0 rounded-md"
              />
            )}
          </div>
          <div
            className={clsx(
              'text-SeabiscuitLightParagraphTextColor capitalize leading-[1] w-[calc(100%-40px)] ellipsis rounded-md',
              loading && '!bg-SeabiscuitSkeletonColor !h-[32px]'
            )}
          >
            {loading ? null : !!product?.couponCode ? product?.couponCode : 'N/A'}
          </div>
        </div>

        <div className="flex items-center w-56 rounded-xl border border-SeabiscuitGray500ThemeColor gap-3 px-2 py-1.5 h-[56px] shrink-0">
          <div
            className={clsx(
              'shrink-0 rounded-md',
              loading ? '!bg-SeabiscuitSkeletonColor w-8 h-8' : 'w-6 h-6'
            )}
          >
            {loading ? null : (
              <img
                alt="tag"
                src="/assets/og_icons/Globe.svg"
                className="w-6 h-6 shrink-0 rounded-md"
              />
            )}
          </div>
          <div
            className={clsx(
              'text-SeabiscuitLightParagraphTextColor w-[calc(100%-40px)] ellipsis rounded-md',
              loading && '!bg-SeabiscuitSkeletonColor !h-[32px]'
            )}
          >
            {loading ? null : !!product.url ? product.url : 'N/A'}
          </div>
        </div>

        <div className="flex shrink-0 gap-2">
          <ExpandableSelector
            loading={loading}
            placeholder="Status"
            {...props?.register(`${props?.name}.status`)}
            options={[
              { label: 'Live', value: 'live' },
              { label: 'Draft', value: 'draft' },
            ]}
            onChange={(status) => {
              props?.setValue(`${props?.name}.status`, status)
              props?.setValue(`${props?.name}.u`, true)
            }}
            value={props?.watch(`${props?.name}.status`)}
            className="!mb-0 !text-nr !text-SeabiscuitLightParagraphTextColor rounded-xl"
            icon="/assets/og_icons/signalStream.svg"
          />

          <div className="flex items-center h-[56px]">
            <button
              onClick={() => props.onRemoveButtonClick(props.index)}
              className={clsx(
                loading ? '!bg-SeabiscuitSkeletonColor w-8 h-8 rounded-md' : 'w-6 h-6'
              )}
            >
              {loading ? null : (
                <img
                  alt="cancel"
                  src="/assets/og_icons/Cancel.svg"
                  className="w-6 h-6 shrink-0 rounded-md"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Product
