// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import Universal from './assets/Paperwork/Pegasus_app_logo.png'
import USEF from './assets/Paperwork/USEF.png'
import USHJA from './assets/Paperwork/USHJA.png'
import USEA from './assets/Paperwork/USEA-LOGO.jpg'
import USDF from './assets/Paperwork/USDF_logo.png'
import { TEventPaperwork } from '../../../../models/event-review-publish/event-review-publish.interface'
import { TEventDetails } from '../../../../models/event-review-publish/event-review-publish.interface'

import fakeDocumentList from '../../../../fakeData/fakeDocumentList'
import LightboxCommonComponent from '../../../common/dialogs/full-screen/LightboxCommonComponent'
import { useState } from 'react'
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentPaperworkElement: React.FC<{
  icon: string
  title: string
  description: string
}> = ({ icon, title, description }) => (
  <div className="w-[150px] grid grid-rows-[auto,1fr,auto]">
    <img className="h-10 mb-2.5 object-cover" src={icon} alt="icon" />
    <h3 className="row-start-2 m-0 text-[15px] font-bold text-SeabiscuitDark200ThemeColor">
      {title}
    </h3>
    <p className="row-start-3 md:mt-2.5 text-[14px] text-[#122B4680]">{description}</p>
  </div>
)

// const EventDetailViewComponentPointOfContactElement: React.FC<{ icon: string, title: string, type?: string, details: string }> = ({ icon, title, type, details }) => (
//     <div className="flex items-center gap-10 md:block border border-[#122B461A] p-5 rounded-lg">
//         <div className="flex mb-4 items-center">
//             <img className="mr-2 w-6 h-6" src={icon} alt="icon" />
//         </div>
//         {type === "phone" ? <a className="whitespace-nowrap text-SeabiscuitDark200ThemeColor" href={`tel:${details}`}>{details}</a> : <p className="whitespace-nowrap text-SeabiscuitDark200ThemeColor">{details}</p>}
//     </div>
// )

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */

const EventDetailViewComponentPaperwork: React.FC<{
  EventDetails: TEventDetails
  EventPaperwork: TEventPaperwork
}> = (props) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  const [showPaperworkPdf, setShowPaperworkPdf] = useState(false)
  const [showPaperworkImages, setShowPaperworkImages] = useState<any>([])

  const openLightboxPaper = (paperworkName: string) => {
    setShowPaperworkImages([])
    const imagesPaperWork = fakeDocumentList?.filter((document) => {
      return document.key === paperworkName
    })
    setShowPaperworkImages(imagesPaperWork[0]?.document_image)
    setShowPaperworkPdf(true)
  }

  return (
    <div>
      <p className=" text-SeabiscuitDark200ThemeColor">
        All paperwork will be completed online during registration
      </p>
      <div className="grid sm:grid-cols-1 xl:grid-cols-4 2xl:grid-cols-6 gap-x-6 gap-y-4 mt-5">
        {props.EventPaperwork?.universalOrganizerWaver ? (
          <div
            className="cursor-pointer"
            onClick={() => openLightboxPaper('universalOrganizerWaver')}
          >
            <EventDetailViewComponentPaperworkElement
              icon={Universal}
              title="Universal Entry Waiver"
              description="All personnel on grounds must sign"
            />
          </div>
        ) : null}
        {props.EventPaperwork?.USEFWaiverAndReleaseOfLiability ? (
          <div
            className="cursor-pointer"
            onClick={() => openLightboxPaper('USEFWaiverAndReleaseOfLiability')}
          >
            <EventDetailViewComponentPaperworkElement
              icon={USEF}
              title="USEF Waiver & Release of Liability"
              description="Exhibitor, Trainer, Owner, Coach"
            />
          </div>
        ) : null}

        {props.EventPaperwork?.USEFEntryAgreement ? (
          <div className="cursor-pointer" onClick={() => openLightboxPaper('USEFEntryAgreement')}>
            <EventDetailViewComponentPaperworkElement
              icon={USEF}
              title="USEF Entry Agreement "
              description="Exhibitor, Trainer, Owner, Coach"
            />
          </div>
        ) : null}

        {props.EventPaperwork?.USHJAWaiverAndReleaseOfLiability ? (
          <div
            className="cursor-pointer"
            onClick={() => openLightboxPaper('USHJAWaiverAndReleaseOfLiability')}
          >
            <EventDetailViewComponentPaperworkElement
              icon={USHJA}
              title="USHJA Waiver & Release of Liablity "
              description="Exhibitor, Trainer, Owner, Coach"
            />
          </div>
        ) : null}

        {props.EventPaperwork?.USDFWaiverAndReleaseOfLiability ? (
          <div
            className="cursor-pointer"
            onClick={() => openLightboxPaper('USDFWaiverAndReleaseOfLiability')}
          >
            <EventDetailViewComponentPaperworkElement
              icon={USDF}
              title="USDF Waiver & Release of Liablity "
              description="Exhibitor, Trainer, Owner, Coach"
            />
          </div>
        ) : null}

        {props.EventPaperwork?.USEAWaiverAndReleaseOfLiability ? (
          <div
            className="cursor-pointer"
            onClick={() => openLightboxPaper('USEAWaiverAndReleaseOfLiability')}
          >
            <EventDetailViewComponentPaperworkElement
              icon={USEA}
              title="USEA Waiver & Release of Liablity "
              description="Exhibitor, Trainer, Owner, Coach"
            />
          </div>
        ) : null}
        {/* {
                    props.EventDetails?.competitionPOCName ? <EventDetailViewComponentPointOfContactElement
                        title="Point of Contact"
                        icon={User}
                        details={props.EventDetails?.competitionPOCName ?? "N/A"}
                    /> : null
                }

                {
                    props.EventDetails?.competitionPOCPhone ? <EventDetailViewComponentPointOfContactElement
                        title="Phone"
                        icon={Phone}
                        type="phone"
                        details={props.EventDetails?.competitionPOCPhone ?? "N/A"}
                    /> : null
                } */}
        <LightboxCommonComponent
          onClose={() => setShowPaperworkPdf(false)}
          visible={showPaperworkPdf}
          slides={showPaperworkImages.map((showPaperwork: string) => {
            return { src: showPaperwork }
          })}
        />
      </div>
    </div>
  )
}

export default EventDetailViewComponentPaperwork
