import { yupResolver } from '@hookform/resolvers/yup'
import { Tooltip } from '@mui/material'
import { DocumentData, QuerySnapshot, where } from 'firebase/firestore'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import helpers from '../../../../../commonHelpers/helpers'
import ViewsLoader from '../../../../../components/loader/ViewsLoader'
import MainModal from '../../../../../components/modals/common/MainModal'
import { CONST } from '../../../../../const/const'
import { IMAGE_CONSTS } from '../../../../../const/image-const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import MessageHelperComp from '../../../../../helpers/MessageHelper'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import TooltipIcon from '../../../../../helpers/TooltipIcon'
import { CustomError } from '../../../../../helpers/helpers'
import { IAssignedTicket } from '../../../../../models/assigned-tickets/assigned-tickets.interface'
import { AssignTicketsModal } from '../../../../../models/assigned-tickets/assigned-tickets.model'
import { IGuestInterface } from '../../../../../models/guests/guest.interface'
import { GuestModel } from '../../../../../models/guests/guest.model'
import { getConvertedData } from '../../../../../models/interface.helper'
import { RecipientModel } from '../../../../../models/recipients/recipients'
import { IRecipientInterface } from '../../../../../models/recipients/recipients.interface'
import { IRegistrationTicketInterface } from '../../../../../models/registration-tickets/registrationTicket.interface'
import { RegistrationTicketModel } from '../../../../../models/registration-tickets/registrationTicket.model'
import { ITicketBuyer } from '../../../../../models/ticket-buyers/ticket-buyers.interface'
import { IUserInterface } from '../../../../../models/users/user.interface'
import { UserModel } from '../../../../../models/users/user.model'
import FirestoreService from '../../../../../services/firestoreService'
import { useAppDispatch } from '../../../../../store/hooks'
import { setSelectedTicket } from '../../../../../store/tickets/ticketslice'
import ViewTicket from '../../../../myEvent/component/ViewTicket'

type IProps = {
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  dataToPassOn: ITicketBuyer
}

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS
const FILE_NAME = 'TicketBuyerDetailsModal'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

type IUser = {
  user: IUserInterface | null
  guest: IGuestInterface | null
  isPlatformUser: boolean
}

type fieldNameError = {
  index: number
  name_message: any
}

type fieldEmailError = {
  index: number
  email_message: any
}

const TicketBuyerDetailsModalCompSide = (props: IProps) => {
  const dataToPassOn = props.dataToPassOn
  const dispatch = useAppDispatch()
  const toastFunction = useToasterHelper()
  const [ticketStep, setTicketStep] = useState(1)
  const [loading, setLoading] = useState(true)
  const [userLoading, setUserLoading] = useState(true)
  const [user, setUser] = useState<IUser | null>(null)
  const [assignedTickets, setAssignedTickets] = useState<IAssignedTicket[]>([])
  const [nameFieldError, setNameFieldError] = useState<fieldNameError[]>([])
  const [emailFieldError, setEmailFieldError] = useState<fieldEmailError[]>([])

  const handelTicketStep = (step: number) => {
    setTicketStep(step)
  }

  const isDisabled = (index: number) => {
    if (assignedTickets[index]?.ticketStatus === 'assigned') {
      return false
    } else {
      return false
    }
  }

  const eventFeesFormSchema = AssignTicketsModal.validationSchema()

  const { setValue, register } = useForm({
    resolver: yupResolver(eventFeesFormSchema),
    mode: 'onChange',
  })

  const loadUser = async (
    userId: ITicketBuyer['userId'],
    isPlatformUser: ITicketBuyer['isPlatformUser']
  ) => {
    let user: IUser | null = null

    try {
      if (!userId) {
        throw new Error(`User id is ${userId}`)
      }

      if (isPlatformUser) {
        const userSnapshot = await FirestoreService.getItem(COLLECTIONS.USERS.NAME, userId)

        user = {
          isPlatformUser,
          guest: null,
          user: userSnapshot.exists()
            ? getConvertedData(UserModel.fromFirestoreDoc(userSnapshot))
            : null,
        }
      } else {
        const guestSnapshot = await FirestoreService.getItem(COLLECTIONS.GUESTS.NAME, userId)

        user = {
          isPlatformUser,
          guest: guestSnapshot.exists()
            ? getConvertedData(GuestModel.fromFirestoreDoc(guestSnapshot))
            : null,
          user: null,
        }
      }
    } catch (error: any) {
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })

      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'loadUser',
          devMessage: error,
        }),
      })
    } finally {
      setUser(user)
      setUserLoading(false)
    }
  }

  const getMergedAssignedTicketsWithNotCreated = async ({
    recipient,
    assignedTickets,
  }: {
    recipient: IRecipientInterface
    assignedTickets: IAssignedTicket[]
  }): Promise<IAssignedTicket[]> => {
    let unCreatedTicketCount = 0
    let createdItemsCount = 0
    let assignedTickets_: IAssignedTicket[] = []
    let registrationTickets: IRegistrationTicketInterface[] = []
    let registrationTicket: IRegistrationTicketInterface | null = null

    const registrationTicketSnapshots = await FirestoreService.filterItems(
      COLLECTIONS.REGISTRATION_TICKET.NAME,
      [
        where(COLLECTIONS.REGISTRATION_TICKET.FIELDS.RECIPIENT_ID.KEY, '==', recipient.recipientId),
        where(
          COLLECTIONS.REGISTRATION_TICKET.FIELDS.REGISTRATION_DOC_ID.KEY,
          '==',
          recipient.registrationDocId
        ),
      ]
    )

    registrationTicketSnapshots.forEach((currRegistrationTicketSnapshot) => {
      registrationTicket = RegistrationTicketModel.fromFirestoreDoc(currRegistrationTicketSnapshot)
      registrationTickets.push(registrationTicket)
      unCreatedTicketCount = registrationTicket.remainingTicketsCount

      createdItemsCount = 0

      while (createdItemsCount < unCreatedTicketCount) {
        assignedTickets_.push({
          ...new AssignTicketsModal({
            ...registrationTicket,
            amountRefunded: 0,
            amountScratched: 0,
            scratchedOn: null,
            chargeId: null,
            isPlatformUser: true,
            ticketBuyerDocId: registrationTicket.userId,
            ticketTitle: registrationTicket.ticketTitle,
            userId: recipient?.recipientId ?? null,
            ticketBuyerName: recipient?.recipientName ?? null,
            ticketBuyerEmailId: null,
            paymentStatus: 'pending',
            ticketType: 'spectator',
            ticketHolderEmailId: null,
            registrationTicketDocId: null,
            updated: null,
            created: null,
            receipetUrl: null,
            ticketStatus: 'available',
            assignmentDate: null,
            isScratched: false,
            eventDocId: recipient?.eventId ?? null,
            checkInDate: null,
            spectatorTicketDocId: null,
            registrationDocId: null,
            ticketHolderName: null,
          }).toObject(),
        })
        createdItemsCount++
      }
    })

    assignedTickets_ = [...assignedTickets, ...assignedTickets_]

    return assignedTickets_
  }

  const getAssignedTickets = async () => {
    let assignedTickets_: IAssignedTicket[] = []
    let recipient: IRecipientInterface | null = null
    let assignedTicketSnapshots: QuerySnapshot<DocumentData>

    try {
      if (dataToPassOn.type === 'spectator') {
        assignedTicketSnapshots = await FirestoreService.filterItems(
          COLLECTIONS.ASSIGNED_TICKETS.NAME,
          [
            where(
              COLLECTIONS.ASSIGNED_TICKETS.FIELDS.SEPECTATOR_TICKET_DOC_ID,
              'in',
              dataToPassOn.spectatorTicketsIds
            ),
            where(COLLECTIONS.ASSIGNED_TICKETS.FIELDS.TICKET_TYPE.KEY, '==', dataToPassOn.type),
          ]
        )
      } else {
        if (!dataToPassOn.recipientDocId) {
          throw new Error(`RecipientDocId is ${dataToPassOn.recipientDocId}`)
        }

        const recipientSnapshot = await FirestoreService.getItem(
          COLLECTIONS.RECIPIENT.NAME,
          dataToPassOn.recipientDocId!
        )

        recipient = RecipientModel.fromFirestoreDoc(recipientSnapshot)

        if (!recipientSnapshot.exists()) {
          throw new Error(`Recipient with docId: ${dataToPassOn.recipientDocId} does not exist`)
        }

        assignedTicketSnapshots = await FirestoreService.filterItems(
          COLLECTIONS.ASSIGNED_TICKETS.NAME,
          [
            where(COLLECTIONS.ASSIGNED_TICKETS.FIELDS.USER_ID, '==', recipient.recipientId),
            where(COLLECTIONS.ASSIGNED_TICKETS.FIELDS.TICKET_TYPE.KEY, '==', dataToPassOn.type),
          ]
        )
      }

      assignedTicketSnapshots.docs.forEach((curr) => {
        assignedTickets_.push(getConvertedData(AssignTicketsModal.fromFirestoreDoc(curr)))
      })

      if (dataToPassOn.type === 'register') {
        assignedTickets_ = await getMergedAssignedTicketsWithNotCreated({
          recipient: recipient!,
          assignedTickets: assignedTickets_,
        })
      }
    } catch (error: any) {
      toastFunction.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'getAssignedTickets',
          devMessage: error,
        }),
      })
    } finally {
      setLoading(false)

      setAssignedTickets(assignedTickets_)
    }
  }

  useEffect(() => {
    setLoading(true)
    getAssignedTickets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setUserLoading(true)
    loadUser(dataToPassOn.userId, dataToPassOn.isPlatformUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToPassOn.userId, dataToPassOn.isPlatformUser])

  const handleticketView = (currentTicket: IAssignedTicket) => {
    dispatch(setSelectedTicket(currentTicket))
    handelTicketStep(2)
  }

  const validate_name_function = (index: number, { name }: { name: string }) => {
    const set_error: fieldNameError = {
      index,
      name_message: null,
    }
    let error_status = false

    if (name === '' || name == null) {
      error_status = true
      set_error.name_message = 'This is required Field'
    } else {
      set_error.name_message = ''
    }

    let include_status = false
    if (nameFieldError.length) {
      let err_arr = nameFieldError.map((item, index) => {
        if (index === set_error.index) {
          include_status = true
          return set_error
        } else {
          return item
        }
      })
      setNameFieldError(err_arr)
    }

    if (!include_status) {
      nameFieldError[set_error?.index] = set_error
      setNameFieldError([...nameFieldError])
    }

    return { status: error_status, set_error }
  }

  const validate_email_function = (index: number, { email }: { email: string }) => {
    const set_error: fieldEmailError = {
      index,
      email_message: null,
    }

    let error_status = false

    const email_regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    if (email === '' || email == null) {
      error_status = true
      set_error.email_message = 'This is required Field'
    } else if (!email_regex.test(email)) {
      error_status = true
      set_error.email_message = 'Please enter valid email'
    } else {
      set_error.email_message = ''
    }
    let include_status = false
    if (emailFieldError.length) {
      let err_arr = emailFieldError.map((item, index) => {
        if (index === set_error.index) {
          include_status = true
          return set_error
        } else {
          return item
        }
      })
      setEmailFieldError(err_arr)
    }

    if (!include_status) {
      emailFieldError[set_error?.index] = set_error
      setEmailFieldError([...emailFieldError])
    }

    return { status: error_status, set_error }
  }

  return (
    <MainModal
      show={true}
      type="TICKET_BUYER_DETAILS_COMPSIDE"
      setHeightAsPerContent={true}
      modalClassName="p-6"
      className="min-h-[70vh]"
      customPadding={true}
      size="4xl"
    >
      {userLoading || loading ? (
        <ViewsLoader
          className="flex items-center w-full justify-center min-h-[70vh] mt-3"
          size="lg"
          color="red"
        />
      ) : (
        <>
          <div className="flex items-center my-2">
            <img
              className="rounded-md mr-4 h-14 w-14 object-cover"
              src={dataToPassOn.ticketBuyerProfilePicture ?? IMAGE_CONSTS.PLACEHOLDERS.USER}
              alt="user"
            />
            <p className="text-lg font-bold text-SeabiscuitDark200ThemeColor capitalize">
              {dataToPassOn.ticketBuyerName}
            </p>
          </div>
          <div className="mt-4">
            <div className="flex items-center mt-3">
              <img src="/assets/og_icons/Two Tickets-1.svg" alt="icon" className="" />
              <p className="ml-4 text-SeabiscuitDark200ThemeColor text-nr">
                {dataToPassOn.unusedTicketCount} ticket remaining
              </p>
            </div>
            <div className="flex items-center mt-3">
              <img src="/assets/og_icons/Tear-Off Calendar-1.svg" alt="icon" className="" />
              <p className="ml-4 text-SeabiscuitDark200ThemeColor text-nr">
                {dataToPassOn.purchasedOn
                  ? moment(dataToPassOn.purchasedOn).format('MMM DD, YYYY')
                  : 'N/A'}
              </p>
            </div>
            <div className="flex items-center mt-3">
              <img src="/assets/og_icons/Location-1.svg" alt="icon" className="" />
              <p className="ml-4 text-SeabiscuitDark200ThemeColor text-nr">
                {(user?.isPlatformUser ? user?.user?.userAddress : user?.guest?.userAddress) ??
                  'N/A'}
              </p>
            </div>
          </div>

          <hr className="my-7 border border-t-0 border-solid border-[#D3DAEE]" />

          {ticketStep === 1 ? (
            <div className="border-[#D3DAEE] border p-4 rounded-lg">
              <div className="text-SeabiscuitDark200ThemeColor font-bold text-nr mb-4">Tickets</div>

              <div
                className={`w-full gap-2 capitalize grid grid-cols-[auto_192px_192px_130px_130px_130px] text-SeabiscuitDark100ThemeColor text-sm font-[600]`}
              >
                <div className="flex items-center">
                  Ticket item
                  <Tooltip
                    title={<h1 className="tooltip_title">Ticket item</h1>}
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color="#122B46" />
                    </button>
                  </Tooltip>
                </div>
                <div className="flex items-center">
                  Name on ticket
                  <Tooltip
                    title={<h1 className="tooltip_title">Name on ticket</h1>}
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color="#122B46" />
                    </button>
                  </Tooltip>
                </div>
                <div className="flex items-center">
                  Email address
                  <Tooltip
                    title={<h1 className="tooltip_title">Email address</h1>}
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color="#122B46" />
                    </button>
                  </Tooltip>
                </div>
                <div className="flex items-center">
                  View tickets
                  <Tooltip
                    title={<h1 className="tooltip_title">View tickets</h1>}
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color="#122B46" />
                    </button>
                  </Tooltip>
                </div>
                <div className="flex items-center">
                  Send tickets
                  <Tooltip
                    title={<h1 className="tooltip_title">Send tickets</h1>}
                    placement="top"
                    arrow
                  >
                    <button>
                      <TooltipIcon color="#122B46" />
                    </button>
                  </Tooltip>
                </div>
                <div className="flex items-center">
                  Status
                  <Tooltip title={<h1 className="tooltip_title">Status</h1>} placement="top" arrow>
                    <button>
                      <TooltipIcon color="#122B46" />
                    </button>
                  </Tooltip>
                </div>
              </div>

              <div className="overflow-auto gap-2 flex flex-col">
                {assignedTickets.length ? (
                  assignedTickets.map((curr, index) => {
                    return (
                      <div
                        key={`${curr.id!}${index}`}
                        className={`w-full gap-2 capitalize grid grid-cols-[auto_192px_192px_130px_130px_130px]`}
                      >
                        <div
                          className={`flex-grow text-[#122B46] min-w-[200px] text-nr text-[#122B46]-900 border-[#D3DAEE] rounded-md !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor p-3.5 flex items-center ${curr.ticketStatus === 'assigned' ? 'bg-[#F6F7FB]' : 'bg-white border-[#D3DAEE] border border-solid'}`}
                        >
                          {!!curr.ticketTitle ? curr.ticketTitle : 'N/A'}
                        </div>

                        <div className="shrink-0">
                          {curr.ticketHolderEmailId ? (
                            <input
                              className={`w-full text-nr !text-[#122B46] outline-0 rounded-md !ring-0 p-4 ${curr.ticketStatus === 'assigned' ? 'bg-[#F6F7FB]' : 'bg-white border-[#D3DAEE] border border-solid'}  text-center border-[#D3DAEE] focus:border-[#122B46] ellipsis`}
                              value={
                                !!curr.ticketHolderEmailId ? curr.ticketHolderEmailId : 'Unassigned'
                              }
                              disabled={true}
                            />
                          ) : (
                            <div className="w-[20%] min-w-[200px] mx-1">
                              <input
                                className={`w-full mb-2 text-nr !text-[#122B46] outline-0 border rounded-md !ring-0  p-4 disabled:bg-[#F6F7FB] disabled:border-none text-center border-[#D3DAEE] focus:border-[#122B46] capitalize ${nameFieldError.length && nameFieldError?.[index]?.name_message != null ? 'mb-0' : ''}`}
                                {...register(`tickets[${index}].assignerName`, {
                                  onChange: (e) => {
                                    validate_name_function(index, { name: e.target.value })
                                    setValue(`tickets[${index}].assignerName`, e.target.value)
                                  },
                                })}
                                disabled={isDisabled(index)}
                                placeholder="Recipient name..."
                                defaultValue={curr.ticketHolderName as any}
                              />
                              {nameFieldError.length &&
                              nameFieldError?.[index]?.name_message != null ? (
                                <MessageHelperComp
                                  isError={true}
                                  message={nameFieldError?.[index]?.name_message || ''}
                                  className="ml-1"
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          )}
                        </div>

                        <div className="capitalize shrink-0">
                          {curr.ticketHolderName ? (
                            <input
                              className={`w-full text-nr !text-[#122B46] outline-0 rounded-md !ring-0 p-4 ${curr.ticketStatus === 'assigned' ? 'bg-[#F6F7FB]' : 'bg-white border-[#D3DAEE] border border-solid'} text-center ellipsis capitalize`}
                              disabled={true}
                              value={!!curr.ticketHolderName ? curr.ticketHolderName : 'Unassigned'}
                            />
                          ) : (
                            <span>
                              <div className="w-[20%] min-w-[200px] mx-1">
                                <input
                                  className={`w-full mb-2 text-nr !text-[#122B46] outline-0 border rounded-md !ring-0  p-4 disabled:bg-[#F6F7FB] disabled:border-none text-center border-[#D3DAEE] focus:border-[#122B46]
                                                                    ${emailFieldError.length && emailFieldError?.[index]?.email_message != null ? 'mb-0 ' : ''}`}
                                  {...register(`tickets[${index}].assignerEmail`, {
                                    onChange: (e) => {
                                      validate_email_function(index, { email: e.target.value })
                                      setValue(`tickets[${index}].assignerEmail`, e.target.value)
                                    },
                                  })}
                                  disabled={isDisabled(index)}
                                  defaultValue={curr.ticketHolderEmailId as any}
                                  placeholder="Recipient email..."
                                />
                                {emailFieldError.length &&
                                emailFieldError?.[index]?.email_message != null ? (
                                  <MessageHelperComp
                                    isError={true}
                                    message={emailFieldError?.[index]?.email_message || ''}
                                    className="ml-1"
                                  />
                                ) : (
                                  ''
                                )}
                              </div>
                            </span>
                          )}
                        </div>

                        {curr.ticketStatus === 'assigned' && (
                          <div
                            className={`text-nr !text-[#122B46] outline-0 rounded-md !ring-0 p-4 ${curr.ticketStatus === 'assigned' ? 'bg-[#F6F7FB]' : 'bg-white border-[#D3DAEE] border border-solid'}  text-center flex items-center shrink-0 `}
                          >
                            {curr.ticketStatus === 'assigned' ? (
                              <img
                                src="/assets/cp_icons/FullScreen-1.svg"
                                alt="full-screen"
                                onClick={() => handleticketView(curr)}
                                className="cursor-pointer"
                              />
                            ) : (
                              <img
                                src="/assets/cp_icons/FullScreen-1.svg"
                                alt="full-screen"
                                onClick={() =>
                                  toastFunction.info({
                                    message: 'This ticket is not yet assigned by now',
                                  })
                                }
                              />
                            )}
                            {curr.ticketStatus === 'assigned' ? (
                              <span className="!text-[#122B46] text-nr ml-3">View</span>
                            ) : (
                              <span className="pl-2">N/A</span>
                            )}
                          </div>
                        )}

                        {curr.ticketStatus === 'available' && (
                          <div
                            className={`text-nr !text-[#122B46] outline-0 rounded-md !ring-0 p-4 ${curr.ticketStatus === 'available' ? 'bg-[#F6F7FB]' : 'bg-white border-[#D3DAEE] border border-solid'}  text-center flex items-center shrink-0`}
                          >
                            <img src="/assets/cp_icons/Mail-1.svg" alt="mail" />
                            {curr.ticketStatus === 'available' ? (
                              <span className="!text-[#122B46] text-nr ml-3">Send</span>
                            ) : (
                              <span className="pl-2">N/A</span>
                            )}
                          </div>
                        )}

                        <div
                          className={`text-nr !text-[#122B46] outline-0 rounded-md !ring-0 p-4 ${curr.ticketStatus === 'assigned' ? 'bg-[#F6F7FB]' : 'bg-white border-[#D3DAEE] border border-solid'} justify-center flex items-center shrink-0`}
                        >
                          {curr.ticketStatus}
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="text-[14px] text-SeabiscuitLightParagraphTextColor mt-2">
                    Tickets not found
                  </div>
                )}
              </div>
            </div>
          ) : (
            <ViewTicket
              dataToPassOn={{ eventId: dataToPassOn.eventId }}
              handelTicketStep={handelTicketStep}
            />
          )}
        </>
      )}
    </MainModal>
  )
}

export default TicketBuyerDetailsModalCompSide
