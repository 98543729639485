import { CONST } from '../../../const/const'
import EventPaymentSettingsModel from '../../../models/event-payment-settings/event-payment-settings.model'
import FirestoreService from '../../firestoreService'
import IEventPaymentSettingsService from './types'

// Constants
const COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_PAYMENT_SETTINGS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @info Event payment settings service
 */
class EventPaymentSettingsService {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Fetches the doc from the db
   * @param {IEventPaymentSettingsService["IGetRequest"]} req
   */
  get: IEventPaymentSettingsService['IGet'] = async (
    req: IEventPaymentSettingsService['IGetRequest']
  ) => {
    const documentSnapshot = await FirestoreService.getItem(COLLECTION.NAME, req.docId)

    if (!documentSnapshot.exists())
      throw new Error(`EventPaymentSettings doc where id: ${req.docId} does not exist`)

    const eventPaymentSettings =
      EventPaymentSettingsModel.fromFirestoreDoc(documentSnapshot).toObject()
    return {
      eventPaymentSettings,
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Fetches the doc from the db
   * @param {IEventPaymentSettingsService["ICreateRequest"]} req
   */
  create: IEventPaymentSettingsService['ICreate'] = async (
    req: IEventPaymentSettingsService['ICreateRequest']
  ) => {
    const docToCreate = new EventPaymentSettingsModel(req.data).toFirestore()

    if (req.id) {
      await FirestoreService.createItemWithCustomId(COLLECTION.NAME, req.id, docToCreate)
      return {
        docId: req.id,
        eventPaymentSettings: {
          id: req.id,
          docToCreate,
        },
      }
    } else {
      const documentSnapshot = await FirestoreService.createItem(COLLECTION.NAME, docToCreate)
      return {
        docId: documentSnapshot.id,
        eventPaymentSettings: docToCreate,
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Fetches the doc from the db
   * @param {IEventPaymentSettingsService["IUpdateRequest"]} req
   */
  update: IEventPaymentSettingsService['IUpdate'] = async (
    req: IEventPaymentSettingsService['IUpdateRequest']
  ) => {
    let eventPaymentSettings = await this.get({
      docId: req.docId,
    })
    await FirestoreService.updateItem(
      COLLECTION.NAME,
      req.docId,
      new EventPaymentSettingsModel({
        ...eventPaymentSettings,
        ...req.fieldsToUpdate,
      }).toFirestore()
    )

    return {
      docId: req.docId,
      updatedFields: req.fieldsToUpdate,
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Fetches the doc from the db
   * @param {IEventPaymentSettingsService["IDeleteRequest"]} req
   */
  delete: IEventPaymentSettingsService['IDelete'] = async (
    req: IEventPaymentSettingsService['IDeleteRequest']
  ) => {
    await FirestoreService.deleteItem(COLLECTION.NAME, req.docId)

    return true
  }
}

const eventPaymentSettingsService = new EventPaymentSettingsService()

export default eventPaymentSettingsService
