import { IEventScheduleInterface, TCustomSchedule } from './event-scheduling.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { v4 as uuidv4 } from 'uuid'
import * as yup from 'yup'
import moment from 'moment'

export class EventSchedulingModel
  extends ModelBaseModel<IEventScheduleInterface>
  implements IModelBaseModel
{
  public v: number
  public id: string | null
  public owner: string | null
  public title: string
  public briefDescription: string | null
  public created: Date
  public modified: Date
  public custom: TCustomSchedule[]
  public facilities: any[]

  public constructor(obj?: IEventScheduleInterface) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? null
    this.title = obj?.title ?? 'Sample Title'
    this.briefDescription = obj?.briefDescription ?? 'Sample brief description'
    this.custom =
      obj?.custom && obj?.custom.length > 0
        ? obj?.custom.map((custom) => ({
            ...custom,
            startDate: this.utcTimestamp({
              key: 'startDate' as any,
              isTimestamp: true,
              value: custom?.startDate,
            }),
          }))
        : []
    this.facilities = obj?.facilities ?? [
      {
        facility: '',
        uuid: uuidv4(),
      },
    ]

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  static fromFirestoreDoc(doc: any) {
    return new EventSchedulingModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  static validationSchema() {
    return yup.object().shape({
      facilities: yup
        .array()
        .of(
          yup.object().shape({
            facility: yup.string().required('Facility is a required field'),
          })
        )
        .test('uniqueValues', 'Facilities should be unique', (facilities) => {
          if (!facilities || !facilities?.length) return true
          let uniquesValuesSet = new Set(...[facilities?.map((c) => c?.facility)])
          return uniquesValuesSet.size === facilities?.length
        }),
      custom: yup.array().of(
        yup.object().shape({
          startDate: yup
            .date()
            .nullable()
            .transform((value, originalValue) => {
              if (!moment(originalValue).isValid()) return null
              return value
            })
            .typeError('Entered date must be valid')
            .required('Day is a required field'),
          location: yup
            .string()
            .transform((value) => {
              return value.trim()
            })
            .required('Location is a required'),
          name: yup
            .string()
            .transform((value) => {
              return value.trim()
            })
            .required('Name is a required'),
          tag: yup
            .string()
            .transform((value) => {
              return value.trim()
            })
            .required('Tag is a required'),
          note: yup
            .string()
            .transform((value) => {
              return value.trim()
            })
            .required('Note is a required'),
        })
      ),
    })
  }
}
