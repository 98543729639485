/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import React, { useEffect } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { Tooltip } from '@mui/material'
import clsx from 'clsx'

import {
  selectedEvent as selectedEventGetter,
  selectEventLoading,
} from '../../../../store/events/eventsSlice'
import { useAppSelector } from '../../../../store/hooks'
import EventFormContainerComponent from '../container/EventFormContainerComponent'

//components
import { IEventPaymentSettingsInterface } from '../../../../models/event-payment-settings/event-payment-settings.interface'
import EventPaymentSettingsModel from '../../../../models/event-payment-settings/event-payment-settings.model'
import EventFormFooterCommonComponent from '../../../common/buttons/EventFormFooterCommonComponent'
import ViewsLoader from '../../../loader/ViewsLoader'
import EventFormHeaderComponent from '../header/EventFormHeaderComponent'
import SwitchElementUpdated from '../paperwork/SwitchElement'
import options from './data/json/options.json'
import TooltipIcon from '../../../../helpers/TooltipIcon'

const EventPaymentSettingsDefaultValues = new EventPaymentSettingsModel().toObject()
const eventReviewPublishFormValidationSchema = EventPaymentSettingsModel.validationSchema()

interface EventReviewPublishFormComponentProps {
  onInvalidAndExit: () => void
  data: IEventPaymentSettingsInterface | null
  validFormHandler: (data: IEventPaymentSettingsInterface) => Promise<{
    onSetEventTab: (tab: string) => void
    updated: boolean
  }>
  invalidFormHandler: (errors: FieldErrors<IEventPaymentSettingsInterface>) => void
  loading: boolean
  showErrors: boolean
  nextEventTab?: string
  eventTab?: string
}

const EventPaymentFormComponent: React.FC<EventReviewPublishFormComponentProps> = (props) => {
  const loading = useAppSelector(selectEventLoading)
  const selectedEvent = useAppSelector(selectedEventGetter)

  const { handleSubmit, trigger, reset, watch, setValue } = useForm({
    defaultValues: EventPaymentSettingsDefaultValues,
    resolver: yupResolver(eventReviewPublishFormValidationSchema),
    mode: 'onChange',
  })

  useEffect(() => {
    if (props.data) {
      reset(props.data)
    } else {
      reset(EventPaymentSettingsDefaultValues)
    }
  }, [props.data, reset, trigger])

  // Functions
  const onTogglePaymentOption = (keyToToggle: IEventPaymentSettingsInterface['paymentOption']) => {
    if (keyToToggle === watch('paymentOption')) {
      if (watch('paymentOption') === keyToToggle) setValue('paymentOption', null)
    } else {
      setValue('paymentOption', keyToToggle)
    }
    trigger().then()
  }

  const handleToggleClick = (name: 'chargeSalesTax' | 'allowRegistrationWithoutPayment') => {
    setValue(name, !watch(name))
    trigger().then()
  }

  return (
    <EventFormContainerComponent>
      {loading || props.loading ? (
        <div className="flex justify-center items-center min-h-[300px]">
          <ViewsLoader
            className="flex items-center w-full justify-center min-h-[30vh] mt-3"
            size="lg"
            color="red"
          />
        </div>
      ) : (
        <div className="mr-4 mb-4 w-full mx-auto">
          <EventFormHeaderComponent
            title="Payments"
            description="Pegasus charges organizers 5% on total sales at event close. Select your preferred payment option."
          >
            <EventFormFooterCommonComponent
              eventTab={props.eventTab}
              nextEventTab={props.nextEventTab}
              publishButton={false}
              onSaveAndExit={(e, publishEvent) => {
                handleSubmit(
                  (data) =>
                    publishEvent({
                      dataToSave: data,
                      publish: false,
                      tabName: 'EventPaymentSettings',
                      validFormHandler: props.validFormHandler,
                    }),
                  props.onInvalidAndExit
                )(e)
              }}
              onNext={(e, publishEvent) => {
                handleSubmit(
                  (data) =>
                    publishEvent({
                      dataToSave: data,
                      tabName: 'EventPaymentSettings',
                      validFormHandler: props.validFormHandler,
                    }),
                  props.invalidFormHandler
                )(e)
              }}
            />
          </EventFormHeaderComponent>

          <div className="flex flex-col">
            {options.map((currentOption, index) => {
              return (
                <div
                  key={`paymentOption${index}`}
                  className="flex gap-2 flex-row items-center justify-between py-3 text-SeabiscuitDark200ThemeColor flex-wrap"
                >
                  <div className="flex-grow">
                    <div className="text-xl font-semibold">{currentOption['title']}</div>
                    {currentOption['subtitle'] ? (
                      <div className="text-base mt-1 opacity-50 flex">
                        {currentOption['subtitle']}
                        <Tooltip
                          title={<h1 className="tooltip_title">{currentOption['tooltiptitle']}</h1>}
                          placement="top"
                          arrow
                          className="shrink-0"
                        >
                          <button>
                            <TooltipIcon color="#122B46" />
                          </button>
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                  <SwitchElementUpdated
                    onSwitch={() => {
                      onTogglePaymentOption(
                        currentOption['key'] as IEventPaymentSettingsInterface['paymentOption']
                      )
                    }}
                    on={watch('paymentOption') === currentOption['key']}
                  />
                </div>
              )
            })}
            <div
              className={clsx(
                'flex gap-2 flex-row items-center justify-between py-3 text-SeabiscuitDark200ThemeColor flex-wrap',
                selectedEvent?.Event?.isPublished && 'opacity-50'
              )}
            >
              <hr className="pb-6 w-full" />
              <div className="flex-grow max-w-[85%]">
                <h4 className="text-xl font-semibold">Sales Tax</h4>
                <div className="flex">
                  <p className="text-base opacity-50">
                    Charge your customers sales tax on this event.
                  </p>
                  <Tooltip
                    title={
                      <h6 className="tooltip_title">
                        You cannot change this after you publish your event.
                      </h6>
                    }
                    placement="top"
                    arrow
                    className="shrink-0"
                  >
                    <button>
                      <TooltipIcon color="#122B46" />
                    </button>
                  </Tooltip>
                </div>
              </div>
              <SwitchElementUpdated
                onSwitch={() =>
                  !selectedEvent?.Event?.isPublished && handleToggleClick('chargeSalesTax')
                }
                on={watch('chargeSalesTax')}
                className={clsx(selectedEvent?.Event?.isPublished && 'pointer-events-none')}
              />
            </div>
            <div className="flex gap-2 flex-row items-center justify-between py-3 text-SeabiscuitDark200ThemeColor flex-wrap">
              <div className="flex-grow max-w-[85%]">
                <h4 className="text-xl">
                  By default, customers must pay to register. Do you want to allow customers to
                  register without paying?
                </h4>
                <p className="text-base opacity-50">
                  This is for events that accommodate non-digital payments during the event.
                  Customers can still pay with credit card during registration if they prefer.
                </p>
              </div>
              <SwitchElementUpdated
                onSwitch={() => handleToggleClick('allowRegistrationWithoutPayment')}
                on={watch('allowRegistrationWithoutPayment')}
              />
            </div>
          </div>
        </div>
      )}
    </EventFormContainerComponent>
  )
}

export default EventPaymentFormComponent
