// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import styles from './EventScoringFormComponent.module.css'

import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { EventScoringModel } from '../../../../models/event-scoring/event-scoring.model'
import EventScoringFormBuilderUtil from '../../../utils/forms/builders/events/scoring/EventScoringFormBuilderUtil'
import EventFormContainerComponent from '../container/EventFormContainerComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const eventScoringFormDefaultValues = new EventScoringModel().toObject()

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
interface EventScoringFormComponentProps {
  data: any
  onValid: any
  onInvalid: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventScoringFormComponent: React.FC<EventScoringFormComponentProps> = (props) => {
  const {
    register,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...eventScoringFormDefaultValues },
    mode: 'onChange',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (props.data) {
      reset(props.data)
    } else {
      reset(eventScoringFormDefaultValues)
    }
    trigger().then()
  }, [props.data, reset, trigger])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <EventFormContainerComponent>
      <div className={styles.main_div}>
        <div className="pt-4 pb-12 px-4 w-full bg-white">
          <EventScoringFormBuilderUtil
            register={register}
            data={props.data?.scores}
            errors={errors}
          />
        </div>

        {/* -------------------- Start Header Section ----------------  */}

        <div className={styles.score}>
          <span>Scoring</span>
        </div>

        <div className={styles.dressage_div}>
          <div className={styles.dressage}>
            <small>Dressage</small>
          </div>
          <div className={styles.expand}>
            <small>Expand all+</small>
          </div>
        </div>

        <div className={styles.dressage_div}>
          <div>
            <small className={styles.secound_dev_small}>1500</small>
            <small>Dressage</small>
          </div>
          <div>
            <small>-</small>
          </div>
        </div>

        {/* ----------------------  End Header Section ------------------ */}

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>1502</small>
              <small>Traning Level</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
            </div>
            <div className={styles.col_2_select_div}>
              <select name="" id="">
                <option value="">Select Test</option>
              </select>
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1504</small>
              <small>First Level</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>First Leval Test 1 (2019) </small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1507</small>
              <small>Second Level</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Second Leval Test 3 (2019) </small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1510</small>
              <small>Third Level</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Third Leval Test 2 (2019) </small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>1513</small>
              <small>Fourth Level</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
            </div>
            <div>
              <select name="" id="">
                <option value="">Select Test</option>
              </select>
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1533</small>
              <small>Intermediate I</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Intermediate I (Ed 2009, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>1535</small>
              <small>Intermediate II</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
            </div>
            <div>
              <select name="" id="">
                <option value="">Select Test</option>
              </select>
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>9943</small>
              <small>Intermediate A</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Intermediate A (Ed 2015, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>9946</small>
              <small>Intermediate B</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Intermediate B (Ed 2020, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1531</small>
              <small>Prix St. Georges</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Prix St. Georges (Ed 2009, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>1537</small>
              <small>Grand Prix</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
            </div>
            <div>
              <select name="" id="">
                <option value="">Select Test</option>
              </select>
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1526</small>
              <small>Grand Prix Freestyle</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Grand Prix Freestyle (Ed 2009, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>1555</small>
              <small>Grand Prix 16-25</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
            </div>
            <div>
              <select name="" id="">
                <option value="">Select Test</option>
              </select>
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1573</small>
              <small>FEI Pony Team</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Pony Riders - Team Competition (Ed 2012, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1574</small>
              <small>FEI Pony Individual</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Pony Riders - Individual Competition (Ed 2012, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1575</small>
              <small>Junior Team</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Junior Riders - Team Competition (Ed 2009, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>1576</small>
              <small>Junior Individual</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
            </div>
            <div>
              <select name="" id="">
                <option value="">Select Test</option>
              </select>
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1577</small>
              <small>Young Rider Team</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Young Riders - Team Competition (Ed 2009, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>1578</small>
              <small>Young Rider Individual</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
            </div>
            <div>
              <select name="" id="">
                <option value="">Select Test</option>
              </select>
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1588</small>
              <small>Young Rider Freestyle</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Young Riders - Freestyle (Ed 2007, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>1579</small>
              <small>Children’s Team</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
            </div>
            <div>
              <select name="" id="">
                <option value="">Select Test</option>
              </select>
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1580</small>
              <small>Children’s Individual</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Children - Individual Competition (Ed 2020, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        {/* ----------------- Custom Label ---------------- */}
        <div className={styles.custom_label}>
          <span>1598</span>
          <span>Miscellanaous Dressage</span>
        </div>

        {/* ---------------------------------------------- */}

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>XXXX</small>
              <small>Dressage Classic KJL</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Young Riders - Freestyle (Ed 2007, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>XXXX</small>
              <small>Enter class name here...</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
            </div>
            <div>
              <select name="" id="">
                <option value="">Select Test</option>
              </select>
            </div>
          </div>
        </div>

        {/* ----------------- Custom Label ---------------- */}
        <div className={styles.custom_label}>
          <span>1596</span>
          <span>Materiale Classes</span>
        </div>

        {/* ---------------------------------------------- */}

        {/* ----------------- custom_label_2 ---------------- */}
        <div className={styles.custom_label_2}>
          <span>5900</span>
          <span>Dressage Sport Horse Breeding (In-hand)</span>
        </div>

        {/* ---------------------------------------------- */}

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>5901</small>
              <small>Yearling</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>First Level Test 1 (2019)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>5902</small>
              <small>2 Year Old</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Second Level Test 3 (2019)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>5903</small>
              <small>3 Year Old</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Third Level Test 2 (2019)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>5906</small>
              <small>4 Year Old</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Junior Riders - Team Competition (Ed 2009, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>5904</small>
              <small>Mature Horse (Mare/Stallion)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
            </div>
            <div>
              <select name="" id="">
                <option value="">Select Test</option>
              </select>
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>5905</small>
              <small>Young Horse (Filly/Colt) Current Year</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Young Riders - Team Competition (Ed 2009, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        {/* ----------------- Custom Label ---------------- */}
        <div className={styles.custom_label}>
          <span>1561</span>
          <span>Young Horse</span>
        </div>

        {/* ---------------------------------------------- */}

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1551</small>
              <small>4 Year Old</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>First Level Test 1 (2019)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1581</small>
              <small>5 Year Old Preliminary</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Second Level Test 3 (2019)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1542</small>
              <small>5 Year Old Final</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Third Level Test 2 (2019)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>1582</small>
              <small>6 Year Old Preliminary</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>unior Riders - Team Competition (Ed 2009, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1543</small>
              <small>6 Year Old Final</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Junior Riders - Team Competition (Ed 2009, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>1583</small>
              <small>Year Old Preliminary</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
            </div>
            <div>
              <select name="" id="">
                <option value="">Select Test</option>
              </select>
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1}>
            <div>
              <small className={styles.sub_div_span}>1562</small>
              <small>Year Old Final</small>
            </div>
            <div>
              <input type="checkbox" />
            </div>
          </div>
          <div className={styles.col_2}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
            </div>
            <div>
              <select name="" id="">
                <option value="">Select Test</option>
              </select>
            </div>
          </div>
        </div>

        {/* ----------------- Custom Label ---------------- */}
        <div className={styles.custom_label}>
          <span>6900</span>
          <span>Dressage Equitation</span>
        </div>

        {/* ---------------------------------------------- */}

        {/* ----------------- custom_label_2 ---------------- */}
        <div className={styles.custom_label_2}>
          <span>1593</span>
          <span>Paraequestrian</span>
        </div>

        {/* ---------------------------------------------- */}

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1592</small>
              <small> FEI Para Freestyle</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Third Level Test 2 (2019)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1591</small>
              <small> FEI Para Individual</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>unior Riders - Team Competition (Ed 2009, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1590</small>
              <small>FEI Para Team</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Junior Riders - Team Competition (Ed 2009, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        {/* ----------------- Custom Label ---------------- */}
        <div className={styles.custom_label}>
          <span>1557</span>
          <span> CDI Grand Prix</span>
        </div>

        {/* ---------------------------------------------- */}

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1558</small>
              <small>CDI Grand Prix Special </small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Junior Riders - Team Competition (Ed 2009, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.col_1_active}>
            <div>
              <small className={styles.sub_div_span}>1559</small>
              <small>CDI Grand Prix Freestyle</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
          <div className={styles.col_2_active}>
            <div className={styles.img_box}>
              <img src={require('./icon.png')} alt="icon" />
              <small>Junior Riders - Team Competition (Ed 2009, Up 2022)</small>
            </div>
            <div>
              <input type="checkbox" checked />
            </div>
          </div>
        </div>
      </div>
    </EventFormContainerComponent>
  )
}

export default EventScoringFormComponent
