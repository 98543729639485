import React, { FC, useContext, useEffect, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import GlobalInputComponent from '../../../../../../../components/events/forms/detail/Components/GlobalInputComponent'

import useToasterHelper from '../../../../../../../helpers/ToasterHelper'

import { EventSchedulingModel } from '../../../../../../../models/operations-tab/operations-tab.model'

import { Hours, Minutes, Seconds } from '../../../../../../../fakeData/TimeList'
import { DateEnum, MANAGE_OPERATIONS_CONSTS } from '../../../data/operations.data.const'
import { IOperationsTabs } from '../../ManageClinicNOtherOperationsTab'
import { HandleModalContext } from '../../../../../../../layout/mainlayout/MainLayout'
import { RegistrationFeesType } from '../../../../../../../models/event-fees/event-fees.interface'
import FirestoreService from '../../../../../../../services/firestoreService'

import { MODAL_CONSTS } from '../../../../../../../const/modal-const'
import { MESSAGES_CONST } from '../../../../../../../const/messages-const'
import { CONST } from '../../../../../../../const/const'

interface OperationsTabsProps {
  activeTab: IOperationsTabs
  setActiveTab: (value: IOperationsTabs) => void
  selectedClass: { label: string; value: string }
  setSelectedClass: (value: { label: string; value: string }) => void
  classOptions: any
  registrationFees: any
  setIncrement: (value: string) => void
  updateFeesKeys: (keys: object, uuid: string) => void
  fee: RegistrationFeesType | null
  eventId: string
}

interface IPublish {
  'order-of-go': boolean
  scoring: boolean
  'prize-money': boolean
}

export const OperationsHeader: FC<OperationsTabsProps> = ({
  activeTab,
  setActiveTab,
  selectedClass,
  setSelectedClass,
  classOptions,
  registrationFees,
  updateFeesKeys,
  setIncrement,
  eventId,
  fee,
}) => {
  const history = useHistory()
  const toastFunctions = useToasterHelper()
  const handleModalContext = useContext(HandleModalContext)

  const eventSchedulingFormValidationSchema = EventSchedulingModel.validationSchema()

  const { control } = useForm({
    defaultValues: { title: '' },
    resolver: yupResolver(eventSchedulingFormValidationSchema),
    mode: 'onSubmit',
  })

  const [incrementHours, setIncrementHours] = useState(Hours[0].value)
  const [incrementMinutes, setIncrementMinutes] = useState(Minutes[0].value)
  const [incrementSeconds, setIncrementSeconds] = useState(Seconds[0].value)
  const [isPublish, setIsPublish] = useState<{
    'order-of-go': boolean
    scoring: boolean
    'prize-money': boolean
  }>({
    'order-of-go': false,
    scoring: false,
    'prize-money': false,
  })
  const [prizeMoney, setPrizeMoney] = useState(0)
  const [editLoading, setEditLoading] = useState(false)

  const onChangeClass = (data: any) => {
    setSelectedClass(data)
  }

  const onChangeTogglePublish = async (
    type: 'order-of-go' | 'scoring' | 'prize-money',
    isPublish: IPublish
  ) => {
    const newPublish = { ...isPublish, [type]: !isPublish[type] }
    setIsPublish({ ...newPublish })

    handleModalContext?.handleModal(true, MODAL_CONSTS.PUBLISH_OPERATIONS_MODAL, {
      onChangeTogglePublish,
      onSavePublish,
      isPublish: newPublish,
      selectedClass,
    })
  }

  const onSavePublish = (currentIsPublish: any) => {
    try {
      updateFeesKeys(
        {
          publishOrder: currentIsPublish['order-of-go'],
          publishScore: currentIsPublish['scoring'],
          publishPrize: currentIsPublish['prize-money'],
        },
        selectedClass.value
      )

      toastFunctions.success({ message: MESSAGES_CONST.VISIBILITY_UPDATED })
    } catch (error) {
      console.error(error)
      toastFunctions.error({
        message: MESSAGES_CONST.EVENT_INCREMENT_NOT_UPDATED,
      })
    }
  }

  const onChangeMoneyPrize = (event: any) => {
    try {
      updateFeesKeys({ prizeMoney: event.target.value }, selectedClass.value)
    } catch (error) {
      console.error(error)
      toastFunctions.error({
        message: MESSAGES_CONST.EVENT_INCREMENT_NOT_UPDATED,
      })
    }
  }

  const onchangeIncrementHandler = async (value: string, type: DateEnum) => {
    let time

    try {
      switch (type) {
        case DateEnum.h:
          setIncrementHours(value)
          time = `${value}:${incrementMinutes}:${incrementSeconds}`
          break
        case DateEnum.m:
          setIncrementMinutes(value)
          time = `${incrementHours}:${value}:${incrementSeconds}`
          break
        default:
          setIncrementSeconds(value)
          time = `${incrementHours}:${incrementMinutes}:${value}`
      }

      setIncrement(time)
      updateFeesKeys({ increment: time }, selectedClass.value)
      toastFunctions.success({ message: MESSAGES_CONST.EVENT_INCREMENT_UPDATED })
    } catch (error) {
      console.error(error)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  useEffect(() => {
    if (selectedClass.value && registrationFees) {
      let registrationFees_
      const indexToUpdateFees = registrationFees.registrationFees.findIndex(
        (item: any) => item.uuid === selectedClass.value
      )

      if (indexToUpdateFees !== -1) {
        registrationFees_ = registrationFees.registrationFees[indexToUpdateFees]
      }

      const incrementTime =
        registrationFees_.increment.length === 8 ? registrationFees_.increment : '00:00:00'

      const [incrementBaseHours, incrementBaseMinutes, incrementBaseSeconds] =
        incrementTime.split(/[:\s]/)
      setIncrementHours(
        incrementBaseHours?.length === 2 && incrementBaseHours > 0 ? incrementBaseHours : '00'
      )
      setIncrementMinutes(
        incrementBaseMinutes?.length === 2 && incrementBaseMinutes > 0 ? incrementBaseMinutes : '00'
      )
      setIncrementSeconds(
        incrementBaseSeconds?.length === 2 && incrementBaseSeconds > 0 ? incrementBaseSeconds : '00'
      )

      setIncrement(incrementTime)

      setIsPublish({
        'order-of-go': registrationFees_[MANAGE_OPERATIONS_CONSTS.PUBLISH_KEYS['order-of-go']],
        scoring: registrationFees_[MANAGE_OPERATIONS_CONSTS.PUBLISH_KEYS['scoring']],
        'prize-money': registrationFees_[MANAGE_OPERATIONS_CONSTS.PUBLISH_KEYS['prize-money']],
      })
    }
  }, [registrationFees, selectedClass.value, activeTab])

  useEffect(() => {
    if (registrationFees)
      setPrizeMoney(
        [...registrationFees?.registrationFees].find(
          (registrationFee: RegistrationFeesType) => registrationFee.uuid === selectedClass.value
        )?.prizeMoney
      )
  }, [registrationFees, selectedClass.value])

  let isPublished = false

  return (
    <div className="flex flex-wrap gap-2 items-center justify-between">
      <div className="flex flex-wrap">
        {MANAGE_OPERATIONS_CONSTS.TABS.map((currentItem: any, index: number) => {
          return (
            <div
              key={`${currentItem}b${index}`}
              onClick={() => setActiveTab(currentItem.id)}
              className={`py-2 px-4 text-[14px] cursor-pointer mr-2 rounded-xl ${
                activeTab === currentItem.id
                  ? 'bg-[#F7074F0D] text-SeabiscuitMainThemeColor'
                  : 'text-SeabiscuitDark200ThemeColor'
              }`}
            >
              {currentItem.tab}
            </div>
          )
        })}
      </div>
      <div className="relative flex gap-4 ml-auto">
        {activeTab !== 'judging' && (
          <Controller
            name="title"
            control={control}
            render={() => {
              return (
                <Select
                  isClearable={false}
                  isSearchable={false}
                  placeholder="Select Class"
                  menuShouldScrollIntoView={true}
                  value={selectedClass}
                  options={classOptions}
                  onChange={onChangeClass}
                  className={clsx('min-w-[15vw] rounded-lg')}
                />
              )
            }}
          />
        )}
        {activeTab === 'order-of-go' && selectedClass.value && (
          <>
            <Controller
              name="title"
              control={control}
              render={() => {
                return (
                  <>
                    <div className="flex gap-2 start-time-wrapper items-center border border-solid border-[#ccc] rounded-lg px-2">
                      <p>Class Start:</p>
                      <span>{`${fee?.startTimeHours}:${fee?.startTimeMinutes} ${fee?.startTimeFormat}`}</span>
                      <button
                        type="button"
                        className="w-4 transition-all hover:opacity-70"
                        onClick={async () => {
                          setEditLoading(true)
                          await FirestoreService.updateItem(
                            CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENTS.NAME,
                            eventId,
                            {
                              eventFormState: CONST.UI.EVENTS.CREATE_EDIT.TABS.SCHEDULING.VALUE,
                            }
                          )
                          history.push(`${CONST.ROUTES.ORGANIZER_EDIT.URL}/${eventId}`)
                          setEditLoading(false)
                        }}
                      >
                        {editLoading ? (
                          <img
                            className="w-full animate-spin"
                            src="/assets/og_icons/Synchronize.svg"
                            alt=""
                          />
                        ) : (
                          <img className="w-full" src="/assets/cp_icons/edit.svg" alt="" />
                        )}
                      </button>
                    </div>
                  </>
                )
              }}
            />

            <Controller
              name="title"
              control={control}
              render={() => {
                return (
                  <>
                    <div className="flex start-time-wrapper items-center border border-solid border-[#ccc] rounded-lg px-2">
                      <p className={'mr-2'}>Increment:</p>
                      <GlobalInputComponent
                        type="select"
                        className="flex-1 px-[0!important] h-[40px] flex items-center border-none outline-0 text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-white"
                        placeholder="00"
                        defaultValue={incrementHours}
                        onChange={(value) => onchangeIncrementHandler(value, DateEnum.h)}
                        value={Hours}
                        control={control}
                        isTimeSelector={true}
                        isdisableInput={true}
                      />
                      :
                      <GlobalInputComponent
                        type="select"
                        className="flex-1 px-[0!important] h-[40px] border-none flex items-center outline-0 text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-white !rounded-none"
                        placeholder="00"
                        defaultValue={incrementMinutes}
                        value={Minutes}
                        onChange={(value) => onchangeIncrementHandler(value, DateEnum.m)}
                        isTimeSelector={true}
                        isdisableInput={true}
                        control={control}
                      />
                      :
                      <GlobalInputComponent
                        type="select"
                        className="flex-1 px-[0!important] h-[40px] outline-0 flex items-center text-gray-500 w-full focus:ring-0 focus:border-0 focus:outline-0 bg-white !rounded-r-[4px] !rounded-l-non"
                        placeholder="00"
                        defaultValue={incrementSeconds}
                        value={Seconds}
                        onChange={(value) => onchangeIncrementHandler(value, DateEnum.s)}
                        isTimeSelector={true}
                        isdisableInput={true}
                        control={control}
                      />
                    </div>
                  </>
                )
              }}
            />
          </>
        )}
        {activeTab === 'prize-money' && (
          <div className="flex items-center rounded-md border border-solid border-SeabiscuitGray500ThemeColor px-2.5 py-0.5">
            Prize money:
            <input
              value={prizeMoney > 0 ? prizeMoney : ''}
              type="number"
              className="p-0 ml-2 w-[100px] outline-0 border-0 text-SeabiscuitDark200ThemeColor text-base text-right hover:opacity-70 transition-all"
              placeholder="$12,750"
              onChange={(event) => setPrizeMoney(Number(event.target.value))}
              onBlur={onChangeMoneyPrize}
            />
          </div>
        )}
        {selectedClass.value && activeTab !== 'judging' && (
          <button
            type="button"
            className={clsx(
              'p-1.5 min-w-[104px] text-base rounded-lg hover:text-SeabiscuitMainThemeColor hover:bg-[#fef5f6] transition-all',
              isPublished
                ? 'bg-SeabiscuitGreenThemeColor/10 text-SeabiscuitGreenThemeColor'
                : 'bg-SeabiscuitGrayThemeColor text-SeabiscuitDark200ThemeColor'
            )}
            onClick={() => {
              handleModalContext?.handleModal(true, MODAL_CONSTS.PUBLISH_OPERATIONS_MODAL, {
                onChangeTogglePublish,
                onSavePublish,
                isPublish,
                selectedClass,
              })
            }}
          >
            {isPublished ? 'Live' : 'Publish'}
          </button>
        )}
      </div>
    </div>
  )
}
