/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef } from 'react'

import Select from 'react-select'

import { COUNTRIES_LIST } from '../../../fakeData/countriesList'

import { customStyles } from '../../customStyles/ReactSelectCustomStyle'

import { UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { ICountry } from '../../../fakeData/countriesList'
import { IUserInterface } from '../../../models/users/user.interface'

type IOnChange = (args: IOnChangeArgs) => void
type IOnChangeArgs = {
  length: number
  isValid: boolean
  phoneNumber: string
  countryCode: string
}

interface IPhoneInputProps {
  name: string
  onFocus?: any
  title?: string
  disable?: boolean
  validate?: boolean
  className?: string
  phoneNumber: string
  setValue?: UseFormSetValue<IUserInterface>
  countryCode: string
  onChange?: IOnChange
  placeholder?: string
  inputClassName?: string
  selectClassName?: string
  saved?: boolean
  register?: UseFormRegister<any>
  ref?: React.MutableRefObject<HTMLDivElement | null>
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const PhoneInput = React.forwardRef<HTMLDivElement, IPhoneInputProps>((props, ref) => {
  const countryCode = useRef(props.countryCode)
  const phoneNumber = useRef(props.phoneNumber)
  const { setValue } = props

  useEffect(() => {
    countryCode.current = props.countryCode
    phoneNumber.current = props.phoneNumber
    // let { phoneNumber, countryCode } = props
    transpile()
  }, [props.phoneNumber, props.countryCode])

  const selectList = useMemo(() => {
    let oldValue: ICountry | null = null
    let updatedArray = []

    updatedArray = COUNTRIES_LIST.reduce((acc, curr) => {
      oldValue = { ...curr }
      acc.push({
        ...curr,
        value: oldValue.phoneCode,
        phoneCode: oldValue.value,
      })
      return acc
    }, [] as ICountry[])

    return updatedArray
  }, [])

  const transpile = () => {
    handleChange('transpile')
  }

  const getSelectedValueDetails = () => {
    let selectedValueLength = COUNTRIES_LIST.find(
      (c) => c.value === countryCode.current
    )?.phoneNumberLength

    if (!selectedValueLength) selectedValueLength = COUNTRIES_LIST[0].phoneNumberLength

    return {
      isValid: selectedValueLength === phoneNumber.current?.length,
      phoneNumberLength: selectedValueLength,
    }
  }

  const getTrimmedValue = (maxLength: number) => {
    if (phoneNumber.current?.length > maxLength) return phoneNumber.current.substring(0, maxLength)
    else return phoneNumber.current
  }

  const handleChange = (callerName?: string) => {
    let details = getSelectedValueDetails()

    if (typeof props.onChange === 'function')
      props.onChange({
        isValid: details?.isValid ?? false,
        phoneNumber: phoneNumber.current ?? '',
        length: details?.phoneNumberLength ?? 0,
        countryCode: countryCode.current?.toString() ?? '',
      })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    phoneNumber.current = e.target.value ?? ''
    phoneNumber.current = phoneNumber.current.replace(/\D/, '')
    handleChange()
  }

  const handleFlagChange = (newValue: ICountry) => {
    countryCode.current = newValue.phoneCode
    phoneNumber.current = getTrimmedValue(newValue.phoneNumberLength)
    handleChange()
  }

  const getSelectValue = () => {
    let valueToReturn = COUNTRIES_LIST[0]
    let foundValue = COUNTRIES_LIST.find((c) => c.value === props?.countryCode)
    if (foundValue) valueToReturn = foundValue
    return {
      ...valueToReturn,
      label: valueToReturn.flag,
      value: valueToReturn.phoneCode,
    }
  }

  const handleRegexNumber = (phoneNumber: string) => {
    let formattedNumber = phoneNumber.replace(/^(\d{3})(\d{3})(\d{3})/, '($1)-$2-$3')
    return formattedNumber
  }

  const inputProps = {
    type: 'tel',
    name: props.name,
    pattern: '[0-9]{3}-[0-9]{2}-[0-9]{3}',
    value: props.phoneNumber,
    maxLength: getSelectedValueDetails()?.phoneNumberLength ?? undefined,
    onChange: handleInputChange,
    onFocus: () => {
      if (typeof props?.onFocus === 'function') {
        props.onFocus()
      }
    },
    onBlur: (e: any) => {
      if (setValue && !props.phoneNumber.includes('-')) {
        setValue('userPhoneNumber', handleRegexNumber(props.phoneNumber))
      }
    },

    placeholder: 'Enter your phone number...',
    className: `!p-3 !border-0 outline-0 !ring-0 ${props?.inputClassName ?? ''} ${'text-SeabiscuitDark200ThemeColor'}`,
    disabled: props?.saved ? props.saved : props?.disable ?? false,
    title: props?.title,
  }

  const getOptionLabel = (data: ICountry) => {
    return (
      <div className="flex">
        <img src={data.flag} alt={data.value} style={{ width: '20px', marginRight: '5px' }} />
        <span>{data.value}</span>
      </div>
    ) as unknown as string
  }

  return (
    <div
      className={`flex border rounded-xl p-0 h-[51px] items-center px-3 ${props.saved ? '!border-white text-gray-400' : '!border-[#D3DAEE]'} `}
      ref={ref}
    >
      <Select
        options={selectList}
        value={getSelectValue()}
        isDisabled={props?.saved ?? false}
        onChange={handleFlagChange as any}
        className={`selectWithSearch rounded-xl focus:ring-[#D3DAEE] focus:border-[#D3DAEE] w-[90px] `}
        styles={
          {
            ...customStyles,
            valueContainer: (provided: any) => ({
              ...provided,
              textAlign: 'left',
              justifyContent: 'start',
              fontSize: '14px',
              padding: 0,
            }),
            singleValue: (provided: any, state: any) => ({
              ...(customStyles?.singleValue && customStyles?.singleValue(provided, state)),
              fontSize: '14px !important',
              color: state.isDisabled ? '#122b46' : provided.color,
              opacity: state.isDisabled ? '1' : provided.opacity,
              ...((props?.saved || props?.disable) && {
                color: 'none',
              }),
            }),
            control: (styles: any, state: any) => ({
              ...customStyles['control']?.(styles, state),
              padding: 0,
            }),
            input: (styles: any, state: any) => ({
              ...customStyles['input']?.(styles, state),
              width: 'fit-content',
            }),
            dropdownIndicator: (styles: any, state: any) => ({
              ...customStyles['dropdownIndicator']?.(styles, state),
              padding: 0,
              display: state.isDisabled ? 'none' : '',
            }),
          } as any
        }
        getOptionLabel={getOptionLabel}
        isClearable={false}
        isSearchable={true}
      />
      <input {...inputProps} />
    </div>
  )
})

export default PhoneInput
