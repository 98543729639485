import * as EventPaymentSettingsService from './event-payment-settings/EventPaymentSettingsService'
import * as RegistrationTicketService from './registration-tickets/RegistrationTicketService'
import * as EventService from './event/EventService'
import * as RegistrationService from './registration/RegistrationService'
// import * as RegistrationByDayService from './registration-by-day/RegistrationByDayService'

const FirebaseService = {
  EventPaymentSettingsService: EventPaymentSettingsService.default,
  RegistrationTicketService: RegistrationTicketService.default,
  RegistrationService: RegistrationService.default,
  EventService: EventService.default,
  // RegistrationByDayService: RegistrationByDayService.default
}

export default FirebaseService
