// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import * as yup from 'yup'

import moment from 'moment'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IEventDetails } from './event-details.interface'
import TimeLib from '../../lib/Time'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export class EventDetailsModel extends ModelBaseModel<IEventDetails> implements IModelBaseModel {
  public v: number
  public id: string
  public owner: string
  public licenseeName?: string
  public licenseeId?: string
  public eventLogo?: string
  public competitionName: string
  public competitionId: string
  public bodyCompId: string
  public competitionYear: number
  public competitionOverallRating: string
  public competitionZone: string
  public competitionPhone: string
  public competitionStartDate: string | Date
  public competitionEndDate: Date
  public competitionAddressLine1: string
  public competitionAddressLine2: string
  public competitionCity: string
  public competitionState: string
  public competitionZipCode: string
  public competitionCountry: string
  public competitionLocationNotes: string
  public competitionPOCId: string
  public competitionPOCName: string
  public competitionPOCPhone: string
  public competitionPOCEmail: string
  public competitionRegistrationOpenDate: Date
  public competitionRegistrationOpenTime: {
    hour: string
    minutes: string
    format: string
  }
  public competitionRegistrationCloseDate: Date
  public competitionRegistrationCloseTime: {
    hour: string
    minutes: string
    format: string
  }
  public competitionEventImage: string[]
  public competitionCourseMapImages: string[]
  public competitionEventVideo: string[]
  public waitlist: boolean
  public competitionCourseMapStatus: boolean
  public category: string
  public competitionLat: string
  public competitionLong: string
  public summaryLong: string
  public summaryShort: string
  public registrationAvailableCount: string
  public eventCardCoverImageUrl: string
  public tags: string[]
  public waitlistCount: number
  public created: Date
  public modified: Date

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  public constructor(obj?: IEventDetails) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? ''
    this.owner = obj?.owner ?? ''

    this.licenseeName = obj?.licenseeName ?? ''
    this.licenseeId = obj?.licenseeId ?? ''
    this.eventLogo = obj?.eventLogo ?? ''
    this.competitionName = obj?.competitionName ?? ''
    this.competitionId = obj?.competitionId ?? ''
    this.bodyCompId = obj?.bodyCompId ?? ''
    this.competitionYear = TimeLib.utcComps('year', obj?.competitionYear)
    this.competitionOverallRating = obj?.competitionOverallRating ?? ''
    this.competitionZone = obj?.competitionZone ?? ''
    this.competitionPhone = obj?.competitionPhone ?? ''
    this.competitionAddressLine1 = obj?.competitionAddressLine1 ?? ''
    this.competitionAddressLine2 = obj?.competitionAddressLine2 ?? ''
    this.competitionLat = obj?.competitionLat ?? ''
    this.competitionLong = obj?.competitionLong ?? ''
    this.competitionCity = obj?.competitionCity ?? ''
    this.competitionState = obj?.competitionState ?? ''
    this.competitionZipCode = obj?.competitionZipCode ?? ''
    this.competitionCountry = obj?.competitionCountry ?? ''
    this.competitionLocationNotes = obj?.competitionLocationNotes ?? ''
    this.competitionPOCId = obj?.competitionPOCId ?? ''
    this.competitionPOCName = obj?.competitionPOCName ?? ''
    this.competitionCourseMapStatus = obj?.competitionCourseMapStatus ?? false
    this.competitionPOCPhone = obj?.competitionPOCPhone ?? ''
    this.competitionPOCEmail = obj?.competitionPOCEmail ?? ''
    this.competitionRegistrationOpenTime = obj?.competitionRegistrationOpenTime ?? {
      hour: '00',
      minutes: '00',
      format: 'AM',
    }
    this.competitionRegistrationCloseDate = obj?.competitionRegistrationCloseDate ?? new Date()
    this.competitionRegistrationCloseTime = obj?.competitionRegistrationCloseTime ?? {
      hour: '00',
      minutes: '00',
      format: 'AM',
    }

    this.summaryLong = obj?.summaryLong ?? ''
    this.summaryShort = obj?.summaryShort ?? ''
    this.waitlist = obj?.waitlist ?? false
    this.category = obj?.category ?? 'clinic'

    this.competitionEventImage = obj?.competitionEventImage ?? []
    this.competitionCourseMapImages = obj?.competitionCourseMapImages ?? []
    this.competitionEventVideo = (function () {
      let obj_ = obj?.competitionEventVideo
      if (obj_) {
        if (Array.isArray(obj_)) {
          return obj_
        } else return []
      } else return []
    })()

    this.eventCardCoverImageUrl = obj?.eventCardCoverImageUrl ?? ''
    this.registrationAvailableCount = obj?.registrationAvailableCount ?? ''
    this.tags = obj?.tags ?? []
    this.waitlistCount = obj?.waitlistCount ?? 0

    this.competitionStartDate = this.utcTimestamp({
      key: 'competitionStartDate',
      value: obj?.competitionStartDate,
    })

    this.competitionRegistrationCloseDate = this.utcTimestamp({
      key: 'competitionRegistrationCloseDate',
      value: obj?.competitionRegistrationCloseDate,
    })

    this.competitionEndDate = this.utcTimestamp({
      key: 'competitionEndDate',
      value: obj?.competitionEndDate,
    })

    this.competitionRegistrationOpenDate = this.utcTimestamp({
      key: 'competitionRegistrationOpenDate',
      value: obj?.competitionRegistrationOpenDate,
    })

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    let data = new EventDetailsModel({
      id: doc.id,
      ...doc.data(),
    })
    return data
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromObject(obj: any) {
    let classObj = new EventDetailsModel()

    const item = new EventDetailsModel()

    item.v = obj?.v ?? 1
    item.id = obj?.id ?? null
    item.owner = obj?.owner ?? null

    item.licenseeName = obj?.licenseeName ?? ''
    item.licenseeId = obj?.licenseeId ?? ''
    item.eventLogo = obj?.eventLogo ?? ''
    item.competitionName = obj?.competitionName ?? ''
    item.competitionId = obj?.competitionId ?? ''
    item.competitionYear = obj?.competitionYear ?? new Date().getFullYear()
    item.competitionOverallRating = obj?.competitionOverallRating ?? ''
    item.competitionZone = obj?.competitionZone ?? ''
    item.competitionPhone = obj?.competitionPhone ?? ''
    item.competitionAddressLine1 = obj?.competitionAddressLine1 ?? ''
    item.competitionAddressLine2 = obj?.competitionAddressLine2 ?? ''
    item.competitionCity = obj?.competitionCity ?? ''
    item.competitionState = obj?.competitionState ?? ''
    item.competitionZipCode = obj?.competitionZipCode ?? ''
    item.competitionCountry = obj?.competitionCountry ?? ''
    item.competitionLocationNotes = obj?.competitionLocationNotes ?? ''
    item.competitionPOCId = obj?.competitionPOCId ?? ''
    item.competitionPOCName = obj?.competitionPOCName ?? ''
    item.competitionPOCPhone = obj?.competitionPOCPhone ?? ''
    item.competitionPOCEmail = obj?.competitionPOCEmail ?? ''
    item.competitionRegistrationOpenDate = obj?.competitionRegistrationOpenDate ?? new Date()
    item.competitionRegistrationOpenTime = obj?.competitionRegistrationOpenTime ?? new Date()
    item.competitionRegistrationCloseDate = obj?.competitionRegistrationCloseDate ?? new Date()
    item.competitionRegistrationCloseTime = obj?.competitionRegistrationCloseTime ?? new Date()
    item.waitlist = obj?.waitlist ?? false
    item.competitionEventImage = obj?.competitionEventImage ?? []
    item.competitionCourseMapImages = obj?.competitionCourseMapImages ?? []
    item.tags = obj?.tags ?? []

    item.eventCardCoverImageUrl = obj?.eventCardCoverImageUrl ?? ''
    item.competitionEventVideo = obj?.competitionEventVideo ?? ''

    item.competitionRegistrationOpenDate = classObj.utcTimestamp({
      key: 'competitionRegistrationOpenDate',
      value: obj?.competitionRegistrationOpenDate,
    })

    item.created = classObj.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created,
    })

    item.modified = classObj.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified,
    })

    return item
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  static validationLicencedSchema() {
    return yup
      .object({
        competitionName: yup.string().required(),
      })
      .required()
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  static validationClinicOtherSchema() {
    return yup.object({
      competitionName: yup.string().required('This field is required!'),
      competitionStartDate: yup
        .date()
        // .max(yup.ref('competitionEndDate'), "Start date can't be after end date")
        .min(moment(new Date()), 'Ensure the Start Date is after today')
        .required('This field is required!'),
      competitionEndDate: yup
        .date()
        .min(yup.ref('competitionStartDate'), "End date can't be before start date")
        .required('This field is required!'),
      competitionAddressLine1: yup.string().required('This field is required!'),
      competitionCity: yup.string().required('This field is required!'),
      competitionState: yup.string().required('This field is required!'),
      competitionZipCode: yup
        .string()
        .nullable()
        .transform((value, originalValue) => (isNaN(value) && originalValue === '' ? null : value))
        .required('This field is required!'),
      competitionCountry: yup.string().required('This field is required!'),
      competitionPOCId: yup
        .number()
        .transform((curr, orig) => (orig !== '' ? curr : undefined))
        .typeError('Entered value must be a number'),
      competitionPOCName: yup.string().required('This field is required!'),
      competitionPOCPhone: yup
        .number()
        .transform((curr, orig) => (orig !== '' ? curr : undefined))
        .typeError('Entered value must be a number'),
      competitionPOCEmail: yup
        .string()
        .email('Please enter a valid email')
        .required('This field is required!'),
      // registrationAvailableCount: yup.number(),
      // .typeError('Entered value must be a postive number')
      // .required("This field is required!")
      // .positive(),
      competitionRegistrationOpenDate: yup
        .date()
        .required('This field is required!')
        // .min(moment(new Date()), "Ensure the Start Date is after today")
        .when('competitionStartDate', (competitionStartDate, schema) => {
          if (competitionStartDate) {
            return schema.max(
              moment(competitionStartDate),
              'Registration open date should be before competition start date'
            )
          }
          return schema
        }),
      competitionRegistrationCloseDate: yup
        .date()
        .required('This field is required!')
        .when('competitionEndtDate', (competitionEndDate, schema) => {
          if (competitionEndDate) {
            return schema.max(
              moment(competitionEndDate).subtract('1', 'day'),
              'Entered date must be before event end date'
            )
          }
          return schema
        })
        .when('competitionRegistrationOpenDate', (competitionRegistrationOpenDate, schema) => {
          if (competitionRegistrationOpenDate) {
            return schema.min(
              moment(competitionRegistrationOpenDate).add(1, 'day'),
              'Registration close date should be after registration open date'
            )
          }
          return schema
        }),
    })
  }
}
