import { useIonRouter } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { CONST } from '../../../const/const'
import ITicketDashboard from './useTicketDashboard.types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const useTicketDashboard = () => {
  // Hooks and vars
  const [loading, setLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const router = useIonRouter()
  const history = useHistory()
  const params = useParams<ITicketDashboard['IParams']>()
  const [activeTab, setActiveTab] = useState<ITicketDashboard['ITabs']>('ticket-buyers')

  if (0)
    console.log({
      setLoading,
      setIsError,
    })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    const validTabsArray: ITicketDashboard['ITabs'][] = ['ticket-buyers', 'ticket-holders']
    const tab = params.activeTab

    if (tab && validTabsArray.indexOf(tab) !== -1) {
      setActiveTab(tab)
    } else {
      router.push(CONST.ROUTES.NOT_FOUND.URL, undefined, 'replace')
      history.push(CONST.ROUTES.NOT_FOUND.URL)
    }
  }, [params, router])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const onTabClick = (activeTab: ITicketDashboard['ITabs']) => {
    router.push(`${CONST.ROUTES.TICKET_DASHBOARD.URL}/${params.eventId}/${activeTab}`)
    history.push(`${CONST.ROUTES.TICKET_DASHBOARD.URL}/${params.eventId}/${activeTab}`)
  }

  return {
    activeTab,
    loading,
    isError,
    onTabClick,
  }
}

export default useTicketDashboard
