import React, { FC } from 'react'

import { EventReportItem } from '../../../../components/EventReportItem'
import { ReportsTabWrapper } from '../ReportsTabWrapper/ReportsTabWrapper'

import { IRegistrationByDayInterface } from '../../../../../../../../models/registrations-by-day/registrationByDay.interface'
import { TEventRegisteredUsers } from '../../../../../../../../models/event-registered-users/event-registered-users.interface'
import { ITeamMember } from '../../../../../../../../models/users/user.interface'
import { IEventInterface } from '../../../../../../../../models/events/event.interface'
import { IReports } from '../../ManageClinicReportsRoot'

import { createEntriesMasterListPdf } from '../../../../../../../../templates/pdf/reports/event-reports/entries-master-list'
import { createOrderOfGoByRiderPdf } from '../../../../../../../../templates/pdf/reports/event-reports/entries-order-of-go-by-rider'
import { createOrderOfGoByClassPdf } from '../../../../../../../../templates/pdf/reports/event-reports/entries-order-of-go-by-class'
import { createOrderOfGoByDayPdf } from '../../../../../../../../templates/pdf/reports/event-reports/entries-order-of-go-by-day'

import { MESSAGES_CONST } from '../../../../../../../../const/messages-const'

import useToasterHelper from '../../../../../../../../helpers/ToasterHelper'
import helpers from '../../../../../../../../commonHelpers/helpers'
import { convertTime } from '../../../../../../../../helpers/time'
import {
  convertDateString,
  getDayOfWeek,
  groupByKey,
  mergeObjectsByKeys,
  sortByKey,
} from '../../../../../../../../helpers/pdf'

interface EventReportsTabProps {
  event: IEventInterface | null
  registeredUsersByDay: IRegistrationByDayInterface[] | null
  uniqueRegisteredUsersByDay: IRegistrationByDayInterface[] | null
  registrationFees: any
  eventRegisteredUsers: TEventRegisteredUsers[] | null
  teamMembers: ITeamMember[] | null
  activeOption: IReports
  setActiveOption: (value: IReports) => void
}
const EventReportsReport: FC<EventReportsTabProps> = ({
  event,
  registeredUsersByDay,
  uniqueRegisteredUsersByDay,
  registrationFees,
  eventRegisteredUsers,
  teamMembers,
  activeOption,
  setActiveOption,
}) => {
  const toastFunctions = useToasterHelper()

  const onDownloadEntriesPdf = async () => {
    const rows: string[][] = []
    const trainers: string[] = []
    const horses: string[] = []
    const activeClasses: string[] = []

    try {
      // create rows
      uniqueRegisteredUsersByDay?.forEach((registeredUserByDay) => {
        const owner = eventRegisteredUsers?.find(
          (eventRegisteredUser) => eventRegisteredUser.id === registeredUserByDay.registrationDocId
        )

        const teamMember = teamMembers?.find(
          (member) =>
            member.horseId === registeredUserByDay.horseId && member.memberRole === 'Trainer'
        )

        const classes = registeredUsersByDay
          ?.filter(
            (registered) =>
              registered.riderId === registeredUserByDay.riderId &&
              registered.horseId === registeredUserByDay.horseId
          )
          .map((user) => user.registrationByDayName)

        const row = [
          `**${registeredUserByDay.backNumber}**`,
          `${registeredUserByDay.riderName} | ^^${owner?.userName}^^`,
          `${registeredUserByDay.horseName ?? 'N/A'} | ^^${teamMember?.memberName ?? 'N/A'}^^`,
          `${classes?.join(' | ')}`,
          `${classes?.length}`,
        ]

        rows.push(row)

        if (teamMember?.memberName) trainers.push(teamMember.memberName)
        if (registeredUserByDay?.horseName) horses.push(registeredUserByDay.horseName)

        if (classes && classes.length > 0) {
          classes?.forEach((currentClass) => {
            if (currentClass) {
              if (!activeClasses.includes(currentClass)) activeClasses.push(currentClass)
            }
          })
        }
      })

      if (event && uniqueRegisteredUsersByDay) {
        createEntriesMasterListPdf(
          event,
          rows,
          uniqueRegisteredUsersByDay,
          horses,
          trainers,
          activeClasses
        ).then()
      }

      toastFunctions.success({
        message: MESSAGES_CONST.REPORT_SUCCESSFULLY_DOWNLOADED,
      })
    } catch (error: any) {
      helpers.logger({
        isError: true,
        message: error,
      })
      toastFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  const onDownloadOrderOfGoByRiderPdf = async () => {
    const rows: string[][] = []

    try {
      // create rows
      const sortedUniqueRegisteredUsersByDay: IRegistrationByDayInterface[] = sortByKey(
        uniqueRegisteredUsersByDay,
        'riderName'
      )

      sortedUniqueRegisteredUsersByDay?.forEach((registeredUserByDay) => {
        const owner = eventRegisteredUsers?.find(
          (eventRegisteredUser) => eventRegisteredUser.id === registeredUserByDay.registrationDocId
        )

        const teamMember = teamMembers?.find(
          (member) =>
            member.horseId === registeredUserByDay.horseId && member.memberRole === 'Trainer'
        )

        const classes: { uuid: string; registrationByDayName: string }[] | null =
          registeredUsersByDay
            ?.filter(
              (registered) =>
                registered.riderId === registeredUserByDay.riderId &&
                registered.horseId === registeredUserByDay.horseId
            )
            .map((user) => ({
              uuid: user.uuid ?? '',
              registrationByDayName: user.registrationByDayName ?? '',
            })) ?? null

        classes?.forEach((currentClass, classesIndex) => {
          const registrationFee = [...registrationFees?.registrationFees]?.find(
            (fee) => fee.uuid === currentClass.uuid
          )

          const fullTime = `${registrationFee.startTimeHours}:${registrationFee.startTimeMinutes} ${registrationFee.startTimeFormat}`

          const row = [
            `${registeredUserByDay.riderName} | ^^${owner?.userName}^^`,
            `${registeredUserByDay.horseName ?? 'N/A'} | ^^${teamMember?.memberName ?? 'N/A'}^^`,
            `${currentClass.registrationByDayName}`,
            `${getDayOfWeek(registeredUserByDay?.created as Date)} | ^^${convertDateString(registeredUserByDay?.created as Date)}^^`,
            `${registeredUserByDay?.orderOfGoScratched ? 'Scratched' : registrationFee.increment < 20 ? convertTime(fullTime, registrationFee.increment, classesIndex) : fullTime}`,
          ]

          rows.push(row)
        })
      })

      if (event && uniqueRegisteredUsersByDay) {
        createOrderOfGoByRiderPdf(event, rows).then()
      }

      toastFunctions.success({
        message: MESSAGES_CONST.REPORT_SUCCESSFULLY_DOWNLOADED,
      })
    } catch (error: any) {
      helpers.logger({
        isError: true,
        message: error,
      })
      toastFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  const onDownloadOrderOfGoByClassPdf = async () => {
    const rows: string[][] = []

    try {
      // create rows
      const sortedUniqueRegisteredUsersByDay: IRegistrationByDayInterface[] = sortByKey(
        uniqueRegisteredUsersByDay,
        'riderName'
      )

      sortedUniqueRegisteredUsersByDay?.forEach((registeredUserByDay) => {
        const owner = eventRegisteredUsers?.find(
          (eventRegisteredUser) => eventRegisteredUser.id === registeredUserByDay.registrationDocId
        )

        const teamMember = teamMembers?.find(
          (member) =>
            member.horseId === registeredUserByDay.horseId && member.memberRole === 'Trainer'
        )

        const classes: { uuid: string; registrationByDayName: string }[] | null =
          registeredUsersByDay
            ?.filter(
              (registered) =>
                registered.riderId === registeredUserByDay.riderId &&
                registered.horseId === registeredUserByDay.horseId
            )
            .map((user) => ({
              uuid: user.uuid ?? '',
              registrationByDayName: user.registrationByDayName ?? '',
            })) ?? null

        classes?.forEach((currentClass, classesIndex) => {
          const registrationFee = [...registrationFees?.registrationFees]?.find(
            (fee) => fee.uuid === currentClass.uuid
          )

          const fullTime = `${registrationFee.startTimeHours}:${registrationFee.startTimeMinutes} ${registrationFee.startTimeFormat}`

          const row = [
            `${registeredUserByDay.riderName} | ^^${owner?.userName}^^`,
            `${registeredUserByDay.horseName ?? 'N/A'} | ^^${teamMember?.memberName ?? 'N/A'}^^`,
            `${currentClass.registrationByDayName}`,
            `${registeredUserByDay?.orderOfGoScratched ? 'Scratched' : registrationFee.increment < 20 ? convertTime(fullTime, registrationFee.increment, classesIndex) : fullTime}`,
          ]

          rows.push(row)
        })
      })

      if (event && uniqueRegisteredUsersByDay) {
        createOrderOfGoByClassPdf(event, groupByKey(rows, 2)).then()
      }

      toastFunctions.success({
        message: MESSAGES_CONST.REPORT_SUCCESSFULLY_DOWNLOADED,
      })
    } catch (error: any) {
      helpers.logger({
        isError: true,
        message: error,
      })
      toastFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  const onDownloadOrderOfGoByDayPdf = async () => {
    const rows: { [key: string]: string[][] }[] = []

    try {
      // create rows
      const sortedUniqueRegisteredUsersByDay: IRegistrationByDayInterface[] = sortByKey(
        uniqueRegisteredUsersByDay,
        'riderName'
      )

      sortedUniqueRegisteredUsersByDay?.forEach((registeredUserByDay) => {
        const owner = eventRegisteredUsers?.find(
          (eventRegisteredUser) => eventRegisteredUser.id === registeredUserByDay.registrationDocId
        )

        const teamMember = teamMembers?.find(
          (member) =>
            member.horseId === registeredUserByDay.horseId && member.memberRole === 'Trainer'
        )

        const classes: { uuid: string; registrationByDayName: string }[] | null =
          registeredUsersByDay
            ?.filter(
              (registered) =>
                registered.riderId === registeredUserByDay.riderId &&
                registered.horseId === registeredUserByDay.horseId
            )
            .map((user) => ({
              uuid: user.uuid ?? '',
              registrationByDayName: user.registrationByDayName ?? '',
            })) ?? null

        const currentRows: string[][] = []
        classes?.forEach((currentClass, classesIndex) => {
          const registrationFee = [...registrationFees?.registrationFees]?.find(
            (fee) => fee.uuid === currentClass.uuid
          )

          const fullTime = `${registrationFee.startTimeHours}:${registrationFee.startTimeMinutes} ${registrationFee.startTimeFormat}`

          const row = [
            `${registeredUserByDay.riderName} | ^^${owner?.userName}^^`,
            `${registeredUserByDay.horseName ?? 'N/A'} | ^^${teamMember?.memberName ?? 'N/A'}^^`,
            `${currentClass.registrationByDayName}`,
            `${registeredUserByDay?.orderOfGoScratched ? 'Scratched' : registrationFee.increment < 20 ? convertTime(fullTime, registrationFee.increment, classesIndex) : fullTime}`,
          ]

          currentRows.push(row)
        })

        const day = `${getDayOfWeek(registeredUserByDay.created as Date)} • ${convertDateString(registeredUserByDay.created as Date, 'dm')}`

        rows.push({ [day]: currentRows })
      })

      if (event && uniqueRegisteredUsersByDay) {
        createOrderOfGoByDayPdf(event, mergeObjectsByKeys(rows)).then()
      }

      toastFunctions.success({
        message: MESSAGES_CONST.REPORT_SUCCESSFULLY_DOWNLOADED,
      })
    } catch (error: any) {
      helpers.logger({
        isError: true,
        message: error,
      })
      toastFunctions.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  return (
    <ReportsTabWrapper
      title="Event Reports"
      description="Export and print event reports"
      activeOption={activeOption}
      setActiveOption={setActiveOption}
    >
      <div className="flex flex-wrap gap-x-[2%] gap-y-5">
        <EventReportItem
          isAvailable
          title="Entries"
          description="master list"
          onClick={onDownloadEntriesPdf}
        />
        <EventReportItem
          isAvailable
          title="Order of Go"
          description="by class"
          onClick={onDownloadOrderOfGoByClassPdf}
        />
        <EventReportItem
          title="Order of Go"
          description="by day"
          onClick={onDownloadOrderOfGoByDayPdf}
        />
        <EventReportItem title="Order of Go" description="by ring" onClick={async () => {}} />
        <EventReportItem
          isAvailable
          title="Order of Go"
          description="by rider"
          onClick={onDownloadOrderOfGoByRiderPdf}
        />
        <EventReportItem title="Show Schedule" description="by day" onClick={async () => {}} />
      </div>
    </ReportsTabWrapper>
  )
}

export default EventReportsReport
