// Constants
import { CONST } from '../const/const'

// Redux
import { useEffect, useState } from 'react'
import { getEventsThunk } from '../store/events/thunks/getEventsThunk'
import {
  DEFAULT_STATUS_ORGANIZER,
  initialSystemStateFilters,
  selectedfilters,
  setActiveFilters,
} from '../store/filters/filterSlice'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import { selectUseMode } from '../store/system/systemSlice'
import { selectUserId } from '../store/user/userSlice'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants
const EVENT = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENTS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @info Is used to handle filters
 * @returns created query from filters
 */
const useHomeFilters = (args: { modify?: boolean; isPublished?: boolean }) => {
  // Hooks and vars
  const dispatch = useAppDispatch()
  const userId = useAppSelector(selectUserId)
  const userMode = useAppSelector(selectUseMode)
  const existFilters = useAppSelector(selectedfilters)
  const [status, setStatus] = useState('')
  const isOrganizerLoggedIn = userMode === CONST.USE_MODE.ORGANIZER

  useEffect(() => {
    if (args.modify !== false) {
      dispatch(
        setActiveFilters({
          ...initialSystemStateFilters.activeFilters,
          groupFilters: {
            ...existFilters.groupFilters,
            categoriesFilter: isOrganizerLoggedIn ? [] : [EVENT.FIELDS.CATEGORIES.VALUES.CLINIC],
          },
          statusFilter: DEFAULT_STATUS_ORGANIZER,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [args.modify, dispatch, isOrganizerLoggedIn])

  useEffect(() => {
    if (existFilters.statusFilter || existFilters.groupFilters.categoriesFilter.length > 0) {
      dispatch(
        getEventsThunk({
          fetchMore: false,
          isPublished: !!args.isPublished,
          status,
        })
      )
    }
  }, [existFilters, isOrganizerLoggedIn, userId, args.isPublished, dispatch, status])

  const fetchMore = () => {
    dispatch(
      getEventsThunk({
        fetchMore: true,
        isPublished: !!args.isPublished,
        status,
      })
    )
  }

  const filterByStatus = (statusProp: string) => {
    setStatus(statusProp)
    // dispatch(
    //   getEventsThunk({
    //     fetchMore: false,
    //     isPublished: !!args.isPublished,
    //     status: statusProp,
    //   })
    // )
  }

  return {
    isOrganizerLoggedIn,
    fetchMore,
    filterByStatus,
  }
}

export default useHomeFilters
