export const PaperWork = [
  {
    documentPaperwork: 'Universal entry waiver',
    SignatoriesPaperwork:
      'Jaylin Williams, Jennifer Tankel, Cindy Holmes, Jason Smith, James Williams',
  },
  {
    documentPaperwork: 'USEF Waiver & Releae of Liability',
    SignatoriesPaperwork:
      'Jaylin Williams, Jennifer Tankel, Cindy Holmes, Jason Smith, James Williams',
  },
  {
    documentPaperwork: 'USEF Univeral Entry Agreement',
    SignatoriesPaperwork:
      'Jaylin Williams, Jennifer Tankel, Cindy Holmes, Jason Smith, James Williams',
  },
  {
    documentPaperwork: 'USHJA Waiver & Release of Liability',
    SignatoriesPaperwork:
      'Jaylin Williams, Jennifer Tankel, Cindy Holmes, Jason Smith, James Williams',
  },
]
