import ViewsLoader from '../../../../components/loader/ViewsLoader'
import { selectMapLoadingR } from '../../../../store/filters/filterSlice'
import { useAppSelector } from '../../../../store/hooks'

// Types
type IProps = {}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%5
/**
 * @TODO Document this
 */
const MapLoader = (props: IProps) => {
  const mapLoading = useAppSelector(selectMapLoadingR)

  if (!mapLoading) return null

  return (
    <div className="absolute p-2 rounded-lg top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-[1] bg-transparent">
      <ViewsLoader size="lg" color="red" />
    </div>
  )
}

export default MapLoader
