import { IHoldersSliceInitialStateInterface } from './holdersSlice.types'

const holdersSliceInitialState: IHoldersSliceInitialStateInterface = {
  usersHolder: {
    data: [],
    status: 'idle',
  },
  horsesHolder: {
    data: [],
    status: 'idle',
  },
  registrationHolder: {
    data: [],
    status: 'idle',
  },
  eventsHolder: {
    baseEventHolder: {
      data: [],
      status: 'idle',
    },
    eventFees: {
      data: [],
      status: 'idle',
    },
  },
}

export { holdersSliceInitialState }
