import React from 'react'

export const IconDropDown: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" fill="none" viewBox="0 0 9 6">
      <path
        fill="#122B46"
        d="M8.26 0H.406a.406.406 0 00-.323.653L4.01 5.799a.407.407 0 00.646 0L8.583.653A.406.406 0 008.26 0z"
      ></path>
    </svg>
  )
}
