import { LockOutlined } from '@mui/icons-material'
const CompetitorRootSideProfileDisplayTabs = () => {
  return (
    <div className="border border-[#D3DAEE] rounded-2xl p-3">
      <div className="flex items-start">
        <LockOutlined className="text-[#122B46] mr-3" />
        <div className="flex flex-col">
          <h4 className="text-[#122B46]">This account is private</h4>
          <p className="text-[#122B4680] text-[13px] mt-1">
            David has chosen to keep his account private
          </p>
        </div>
      </div>
    </div>
  )
}

export default CompetitorRootSideProfileDisplayTabs
