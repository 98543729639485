import { PDFDocument, PDFFont, PDFPage, rgb, StandardFonts } from 'pdf-lib'
import { IEventInterface } from '../models/events/event.interface'

interface createTableProps {
  pdfDoc: PDFDocument
  headers: string[]
  rows: string[][]
  colWidths: number[]
  textAboveTheFirstTable?: string
  textAboveTheAllTable?: string
  event: IEventInterface
  printedText?: string
  titleText?: string
  urlText?: string
}

const getColWidth = (width: number, percent: number) => (width * percent) / 100

export function convertDateString(dateString: Date, type?: 'tdm' | 'dm') {
  const date = new Date(dateString)

  const time = date
    .toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    .toLowerCase()
  const day = String(date.getDate()).padStart(2, '0')
  const month = date.toLocaleString('en-US', { month: 'short' })
  const year = date.getFullYear()

  if (type === 'tdm') {
    return `${time}, ${day} ${month}`
  } else if (type === 'dm') {
    return `${day} ${month}`
  } else {
    return `${day} ${month}, ${year}`
  }
}

export function getDayOfWeek(dateString: Date) {
  const date = new Date(dateString)

  return date.toLocaleDateString('en-US', { weekday: 'long' })
}

export function sortByKey(array: any, key: string) {
  return array.sort((a: any, b: any) => {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  })
}

export function groupByKey(array: any, key: string | number) {
  return array.reduce((result: any, item: any) => {
    const groupKey = item[key]
    if (!result[groupKey]) {
      result[groupKey] = []
    }
    result[groupKey].push(item)
    return result
  }, {})
}

export function mergeObjectsByKeys(array: any) {
  return array.reduce((result: any, item: any) => {
    const key = Object.keys(item)[0] // Получаем ключ текущего объекта
    if (!result[key]) {
      result[key] = [] // Инициализируем пустой массив, если ключ не существует в результате
    }
    result[key] = result[key].concat(item[key]) // Объединяем массивы
    return result
  }, {})
}

function wrapText(text: string, maxWidth: number, font: PDFFont, fontSize: number): string[] {
  const segments = text.split('|').map((segment) => segment.trim())
  const wrappedLines: string[] = []

  segments.forEach((segment) => {
    const words = segment.split(' ')
    let currentLine = words[0]

    for (let i = 1; i < words.length; i++) {
      const word = words[i]
      const width = font.widthOfTextAtSize(currentLine + ' ' + word, fontSize)
      if (width < maxWidth) {
        currentLine += ' ' + word
      } else {
        wrappedLines.push(currentLine)
        currentLine = word
      }
    }
    wrappedLines.push(currentLine)
  })

  return wrappedLines
}

function drawTextWithStyles(
  page: PDFPage,
  text: string,
  x: number,
  y: number,
  font: PDFFont,
  boldFont: PDFFont,
  fontSize: number
) {
  const segments = text.split(/(\*\*.*?\*\*|\^\^.*?\^\^)/)
  let currentX = x

  segments.forEach((segment) => {
    let fontToUse = font
    let colorToUse = rgb(0, 0, 0)
    let segmentText = segment

    if (segment.startsWith('**') && segment.endsWith('**')) {
      fontToUse = boldFont
      segmentText = segment.slice(2, -2)
    } else if (segment.startsWith('^^') && segment.endsWith('^^')) {
      colorToUse = rgb(0.75, 0.75, 0.75)
      segmentText = segment.slice(2, -2)
    }

    page.drawText(segmentText, {
      x: currentX,
      y,
      size: fontSize,
      font: fontToUse,
      color: colorToUse,
    })

    currentX += fontToUse.widthOfTextAtSize(segmentText, fontSize)
  })
}

function drawCell(
  page: PDFPage,
  text: string,
  x: number,
  y: number,
  width: number,
  height: number,
  font: PDFFont,
  boldFont: PDFFont,
  fontSize: number,
  cellPadding: number
) {
  const lines = wrapText(text, width - cellPadding * 2, font, fontSize)
  const textY =
    y +
    height -
    cellPadding -
    (height - lines.length * (fontSize + cellPadding / 2)) / 2 -
    fontSize +
    4

  lines.forEach((line, lineIndex) => {
    drawTextWithStyles(
      page,
      line,
      x + cellPadding,
      textY - (fontSize + cellPadding / 2) * lineIndex,
      font,
      boldFont,
      fontSize
    )
  })

  page.drawRectangle({
    x,
    y,
    width,
    height,
    borderColor: rgb(0.75, 0.75, 0.75),
    borderWidth: 1,
  })
}

function calculateRowHeights(
  row: string[],
  tableWidth: number,
  colWidths: number[],
  cellPadding: number,
  font: PDFFont,
  fontSize: number
): number[] {
  return row.map((cell, index) => {
    const lines = wrapText(
      cell,
      getColWidth(tableWidth, colWidths[index]) - cellPadding * 2,
      font,
      fontSize
    )
    return lines.length * (fontSize + cellPadding / 2) + cellPadding * 2
  })
}

function drawHeaders(
  page: PDFPage,
  headers: string[],
  tableX: number,
  startY: number,
  headerHeight: number,
  cellPadding: number,
  fontSize: number,
  tableWidth: number,
  boldFont: PDFFont,
  colWidths: number[]
) {
  headers.forEach((header, index) => {
    const x =
      tableX +
      colWidths.slice(0, index).reduce((acc, width) => acc + getColWidth(tableWidth, width), 0)
    drawCell(
      page,
      header,
      x,
      startY - headerHeight,
      getColWidth(tableWidth, colWidths[index]),
      headerHeight,
      boldFont,
      boldFont,
      fontSize,
      cellPadding
    )
  })
}

function drawRows(
  page: PDFPage,
  rows: string[][],
  tableX: number,
  startY: number,
  fontSize: number,
  cellPadding: number,
  tableWidth: number,
  font: PDFFont,
  boldFont: PDFFont,
  colWidths: number[]
): number {
  let currentY = startY

  rows.forEach((row) => {
    const rowHeights = calculateRowHeights(row, tableWidth, colWidths, cellPadding, font, fontSize)
    const maxRowHeight = Math.max(...rowHeights)
    currentY -= maxRowHeight

    row.forEach((cell, colIndex) => {
      const x =
        tableX +
        colWidths.slice(0, colIndex).reduce((acc, width) => acc + getColWidth(tableWidth, width), 0)
      drawCell(
        page,
        cell,
        x,
        currentY,
        getColWidth(tableWidth, colWidths[colIndex]),
        maxRowHeight,
        font,
        boldFont,
        fontSize,
        cellPadding
      )
    })
  })

  return currentY
}

async function drawLogo(
  page: PDFPage,
  pdfDoc: PDFDocument,
  y: number,
  pageWidth: number,
  pageHeight: number
) {
  const jpgUrl = '/assets/Pegasus_app_logo.png'
  const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
  const jpgImage = await pdfDoc.embedPng(jpgImageBytes)
  const logoSize = 40

  page.drawLine({
    start: { x: 0, y: pageHeight - logoSize },
    end: { x: pageWidth, y: pageHeight - logoSize },
    thickness: 1,
    color: rgb(0.75, 0.75, 0.75),
    opacity: 1,
  })

  page.drawImage(jpgImage, {
    x: pageWidth / 2 - logoSize / 2,
    y,
    width: logoSize,
    height: logoSize,
    opacity: 1,
  })
}

function drawHeader(
  page: PDFPage,
  font: PDFFont,
  boldFont: PDFFont,
  lightFont: PDFFont,
  pageWidth: number,
  logoY: number,
  printedText?: string,
  titleText?: string,
  urlText?: string
) {
  const printedTextFontSize = 11
  const titleTextSize = 26
  const urlTextSize = 11

  if (printedText) {
    const printedTextWidth = lightFont.widthOfTextAtSize(printedText, printedTextFontSize)
    drawTextWithStyles(
      page,
      printedText,
      (pageWidth - printedTextWidth) / 2,
      logoY - 20,
      font,
      boldFont,
      printedTextFontSize
    )
  }

  if (titleText) {
    const titleTextWidth = boldFont.widthOfTextAtSize(titleText, titleTextSize)
    page.drawText(titleText, {
      x: (pageWidth - titleTextWidth) / 2,
      y: logoY - 50,
      size: titleTextSize,
      font: boldFont,
      color: rgb(0, 0, 0),
    })
  }

  if (urlText) {
    const urlTextWidth = font.widthOfTextAtSize(urlText, urlTextSize)

    page.drawText(urlText, {
      x: (pageWidth - urlTextWidth) / 2,
      y: logoY - 70,
      size: urlTextSize,
      font: font,
      color: rgb(0, 0, 0),
    })
  }
}

function drawFooter(
  page: PDFPage,
  pageNumber: number,
  font: PDFFont,
  fontSize: number,
  cellPadding: number,
  footerHeight: number,
  tableX: number,
  event: IEventInterface
) {
  const footerText = `${event.eventName} • ${convertDateString(event?.eventStartDate as Date)} - ${convertDateString(event?.eventEndDate as Date)} • ${pageNumber}`
  const textWidth = font.widthOfTextAtSize(footerText, fontSize)
  const footerX = page.getWidth() - textWidth - tableX

  page.drawText(footerText, {
    x: footerX,
    y: footerHeight - fontSize - cellPadding,
    size: fontSize,
    font: font,
    color: rgb(0, 0, 0),
  })
}

export const createTable: ({
  pdfDoc,
  headers,
  rows,
  colWidths,
  textAboveTheFirstTable,
  textAboveTheAllTable,
  printedText,
  titleText,
  urlText,
  event,
}: createTableProps) => Promise<void> = async ({
  pdfDoc,
  headers,
  rows,
  colWidths,
  textAboveTheFirstTable,
  textAboveTheAllTable,
  printedText,
  titleText,
  urlText,
  event,
}) => {
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica)
  const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
  const lightFont = await pdfDoc.embedFont(StandardFonts.HelveticaOblique)

  const fontSize = 11
  const cellPadding = 5
  const pageWidth = 841.89
  const pageHeight = 595.28
  const tableWidth = pageWidth * 0.9
  const tableX = (pageWidth - tableWidth) / 2
  const headerHeight = fontSize + cellPadding * 2
  const logoHeight = 50 // Adjust as needed
  const footerHeight = 30 // Adjust as needed
  let headerSpace = 0 // Adjust as needed for the height of the header

  if (printedText) headerSpace += 20

  if (titleText) headerSpace += 40

  if (urlText) headerSpace += 20

  let pageNumber = 1
  let page = pdfDoc.addPage([pageWidth, pageHeight])
  await drawLogo(page, pdfDoc, pageHeight - logoHeight - 10, pageWidth, pageHeight)
  drawHeader(
    page,
    font,
    boldFont,
    lightFont,
    pageWidth,
    pageHeight - 80,
    printedText,
    titleText,
    urlText
  ) // Draw header only on the first page
  drawFooter(page, pageNumber, font, fontSize, cellPadding, footerHeight, tableX, event)

  let startY = pageHeight - 30 - logoHeight - headerSpace // Adjust startY to account for header space and text above table

  if (textAboveTheFirstTable) {
    startY -= 20 // Additional space for the text above the text
    drawTextWithStyles(page, textAboveTheFirstTable, tableX, startY, font, boldFont, 12)
    startY -= 20 // Additional space for the text above the table
  }

  if (textAboveTheAllTable) {
    startY -= 20
    drawTextWithStyles(page, textAboveTheAllTable, tableX, startY, font, boldFont, 12)
    startY -= 20
  }

  while (rows.length > 0) {
    drawHeaders(
      page,
      headers,
      tableX,
      startY,
      headerHeight,
      cellPadding,
      fontSize,
      tableWidth,
      boldFont,
      colWidths
    )
    startY -= headerHeight

    const rowsForPage = []
    let currentY = startY

    for (const row of rows) {
      const rowHeights = calculateRowHeights(
        row,
        tableWidth,
        colWidths,
        cellPadding,
        font,
        fontSize
      )
      const maxRowHeight = Math.max(...rowHeights)
      if (currentY - maxRowHeight < cellPadding + footerHeight) break

      rowsForPage.push(row)
      currentY -= maxRowHeight
    }

    drawRows(
      page,
      rowsForPage,
      tableX,
      startY,
      fontSize,
      cellPadding,
      tableWidth,
      font,
      boldFont,
      colWidths
    )
    rows = rows.slice(rowsForPage.length)

    if (rows.length > 0) {
      page = pdfDoc.addPage([pageWidth, pageHeight])
      pageNumber++
      await drawLogo(page, pdfDoc, pageHeight - logoHeight - 10, pageWidth, pageHeight)
      drawFooter(page, pageNumber, font, fontSize, cellPadding, footerHeight, tableX, event)
      startY = pageHeight - 30 - logoHeight // Reset startY for subsequent pages
    }
  }
}

export const savePdf = async (pdfDoc: PDFDocument, pdfName: string) => {
  const pdfBytes = await pdfDoc.save()
  const blob = new Blob([pdfBytes.buffer], { type: 'application/octet-stream' })
  const blobUrl = URL.createObjectURL(blob)
  const downloadLink = document.createElement('a')
  downloadLink.href = blobUrl
  downloadLink.download = pdfName
  downloadLink.click()
}
