import FirestoreService from './firestoreService'
import { CONST } from '../const/const'
import { doc, setDoc } from 'firebase/firestore'
import FirebaseApp from './firebaseApp'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const getUserInfo = async (user: any) => {
  return FirestoreService.getItem(CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME, user.uid)
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const getUserInfoById = async (id_to_use: any) => {
  return FirestoreService.getItem(CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME, id_to_use)
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const getUserDocumentDataByID = async (id_to_use: any) => {
  return FirestoreService.getItem(
    CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS_DOCUMENTS.NAME,
    id_to_use
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const createCompetitorAccount = async (id_to_use: any, document: any) => {
  await setDoc(
    doc(
      FirebaseApp.firestore,
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS_ACCOUNT.NAME,
      id_to_use
    ),
    document
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const createCompetitorProfile = async (id_to_use: any, document: any) => {
  await setDoc(
    doc(
      FirebaseApp.firestore,
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.COMPETITOR_PROFILE.NAME,
      id_to_use
    ),
    document
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const getCompetitorAccountDataByID = async (id_to_use: any) => {
  return FirestoreService.getItem(
    CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS_ACCOUNT.NAME,
    id_to_use
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const getCompetitorProfileDataByID = async (id_to_use: any) => {
  return FirestoreService.getItem(
    CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.COMPETITOR_PROFILE.NAME,
    id_to_use
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const getUserDocumentsDataByID = async (id_to_use: any) => {
  return FirestoreService.getItem(
    CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS_DOCUMENTS.NAME,
    id_to_use
  )
}

const UserService = {
  getUserInfo,
  getUserInfoById,
  getUserDocumentDataByID,
  createCompetitorAccount,
  createCompetitorProfile,
  getCompetitorAccountDataByID,
  getCompetitorProfileDataByID,
  getUserDocumentsDataByID,
}

export default UserService
