import React from 'react'

interface IProps {}

export const IconMicrochipImg: React.FC<IProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="56"
      fill="none"
      viewBox="0 0 24 56"
    >
      <path fill="url(#pattern0_11636_11989)" d="M0 0H24V56H0z"></path>
      <defs>
        <pattern
          id="pattern0_11636_11989"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="matrix(.01042 0 0 .00446 0 .286)" xlinkHref="#image0_11636_11989"></use>
        </pattern>
        <image
          id="image0_11636_11989"
          width="96"
          height="96"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAAFWElEQVR4nO2dTUxcVRTH/+dNGQs4Q/kQm8bEfhBtO8qmgVjjRouARva1VrdVi43VogtjO1oTF9TGiAtNXFFI477aMrSJcdGmXYJj0JRalU0/GGCGAsMwc12ADSlTeB/3vnvfcH7JLLjJ+Z878+e+O++8mzMAwzAMwzAMwzAMU6rELZeBtPQyPc4VyhM9sXdv+b10tJsgDgKAgDhTGZ3+aOzKldnV4rbs6aiYy2ZPQ+CtpZn2VkQyR02L88oGleIAMJOOfkkQh///m0CdM+lIDsAHq8Vl57LdAA7dHxA4dC8dXQDQaVKcV9xeFhwg9hcZfH3NKODAg2MEsWJMd5xXfDAAjxcZ22wjblORsWoD4zzhhwHMKrABmmEDNMMGaEbpfUBdY/vThXxhRGUO1Vgha+fdoQt/qNJXYkBk577acCj0jQD2I/irrECEszlBR9LJgZRscekG1D7TsksI6xyA7bK1dULAKCy8Nj6ckLqipRoQjbXVlEFcE8AOmboGcTOXm2/K/PnLXVmCUi8PZRA9JfzhA8DWcDj8tUxBaSvgsVhbQx5iBEBIlqah5K2QFZO1Mds2YMuejorsXLZbAG8AqHKQIweIk/kQ9U0NJf5yPkX/qWps3RbKi4MAfQqgzEHopAD1V0bTXXarqLb/W8tqt/YAeAfARgcTAgHxVHLwi+yt0UkncTrJ3hqdnL1z49fy+gYC8KKD0I0ENOfmH6mbvT16zk6AzT0gbt2vkzskn7f63MSZQD4kzrgKFHjT7gMo5d/RJ0ee+0d1DlVMDT3/t+ocdldAAYRedyniBXdxJuBu7iREr91Y2yugIpI5KiC+BRCYa7kGJgShp7xqetWnfcuR9jW0JtYqio2nkgnfHnCrQPX7CnqdJvCwAZphAzSj/FiKU6p3t75ChGMAmgE8Kkl2GqCrAE6lkgMXJGlKwagVULO79QQRfgbwEuR9+FjUEvsAcb4m1nZcoq5njDGgetfLr4IQV59JfFYTa2lTn8cexhhAFn3oY7JjvuVaA9t7gIdqqF2aFGgWR6DZq8RD7g8cV0NtGzCXzZ7G8rOT8oko1H6QqCLdTQRxeCYT2QDgbTsBNg2IWxCXXVVDveL1jvNhd7JKWayGvmunHmTMHrBe8aEauv7gaqg+HFdDbW/CS7v6e0uvFWi51mpktb1pwoEO7wGaYQM0wwZohg3QDBugGTZAM2yAZkyqhgaKUqyGlgKOq6HKz4auS0w6G8qsjt3nAQVQay+E/5egINaYgloNzSjSLUZakW6Qq6F0bfHoiC9c9SpQctVQIcRXviUjccq3XGtgjAETvyfOA3RCfSZxPPXbYEJ9HnsYYwAApJIDnwOFdhAuQu6ekAEwCBJtqeTgSYm6njHubGgqeXEAwIDuefiFUStgPcIGaIYN0AxXQ13C1VAzMbEa6rplsQG4nLtJ1dCqxstPqs6hCj/mrvxs6GLXkWASWnC36p1UQ213S6ndUX9pPhuuItBTcNQxhV4or2+g8OZt/2Zv33BSp9JG1bMt2yvrGo6A8Amc9T+aEIQfKqLTXemxsQU7AdywyTlSGzZJ2wPuJAeuE/CjLD1TIYGzMttYSt2EF6yyTgFcl6lpGDfnC/n3ZQpKNWBq+KcJy0IHAaMydU2AgFErZLVnRi6Ny9SV/jV0fDgxkgM1E6EfQF62vgbyJNC3YJU1qeigy62L1yCQrYuX47bfTlDivBLgMkFpwAZohg3QDBugGT8MKHaSzk5NKChxnlBugAD1rxgjrNlNNyhxXlFuQGU03QXC9wBmAMyQEN9VRjIfl0pckAjKj3L6+mOeDMMwDMMwDMMwjM/8B5m9OwIEQOHuAAAAAElFTkSuQmCC"
        ></image>
      </defs>
    </svg>
  )
}
