export const disciplineData = [
  {
    label: 'American Saddlebred',
    value: 'americansaddlebred',
  },
  {
    label: 'Andalusian/Lusitano',
    value: 'andalusianlusitano',
  },
  {
    label: 'Arabian Baroque',
    value: 'arabianbaroque',
  },
  {
    label: 'Barrel Racing',
    value: 'barrelracing',
  },
  {
    label: 'Breeding',
    value: 'breeding',
  },
  {
    label: 'Carriage',
    value: 'carriage',
  },
  {
    label: 'Connemara',
    value: 'connemara',
  },
  {
    label: 'Cutting',
    value: 'cutting',
  },
  {
    label: 'Dressage',
    value: 'dressage',
  },
  {
    label: 'Driving',
    value: 'driving',
  },
  {
    label: 'Endurance',
    value: 'endurance',
  },
  {
    label: 'Eventing',
    value: 'eventing',
  },
  {
    label: 'Field Hunter',
    value: 'fieldhunter',
  },
  {
    label: 'Fox Hunting',
    value: 'foxhunting',
  },
  {
    label: 'Friesian',
    value: 'friesian',
  },
  {
    label: 'Gaited',
    value: 'gaited',
  },
  {
    label: 'Gymkhana',
    value: 'gymkhana',
  },
  {
    label: 'Hackney',
    value: 'hackney',
  },
  {
    label: 'Horsemanship',
    value: 'horsemanship',
  },
  {
    label: 'Hunter',
    value: 'hunter',
  },
  {
    label: 'Hunter Jumper',
    value: 'hunterjumper',
  },
  {
    label: 'Leisure',
    value: 'leisure',
  },
  {
    label: 'Morgan',
    value: 'morgan',
  },
  {
    label: 'Mounted Shooting',
    value: 'mountedshooting',
  },
  {
    label: 'National Show Horse',
    value: 'nationalshowhorse',
  },
  {
    label: 'Paso Fino',
    value: 'pasofino',
  },
  {
    label: 'Pleasure',
    value: 'pleasure',
  },
  {
    label: 'Polo',
    value: 'polo',
  },
  {
    label: 'Polocrosse',
    value: 'polocrosse',
  },
  {
    label: 'Pony Club',
    value: 'ponyclub',
  },
  {
    label: 'Racing',
    value: 'racing',
  },
  {
    label: 'Ranch Riding',
    value: 'ranchriding',
  },
  {
    label: 'Reining',
    value: 'reining',
  },
  {
    label: 'Rodeo',
    value: 'rodeo',
  },
  {
    label: 'Roping',
    value: 'roping',
  },
  {
    label: 'Saddle Seat',
    value: 'saddleseat',
  },
  {
    label: 'Shetland',
    value: 'shetland',
  },
  {
    label: 'Show Jumping',
    value: 'showjumping',
  },
  {
    label: 'Stallion at Stud',
    value: 'stallionatstud',
  },
  {
    label: 'Team Penning',
    value: 'teampenning',
  },
  {
    label: 'Trail',
    value: 'trail',
  },
  {
    label: 'Trail Riding',
    value: 'trailriding',
  },
  {
    label: 'Trotting',
    value: 'trotting',
  },
  {
    label: 'Vaulting',
    value: 'vaulting',
  },
  {
    label: 'Welsh Pony/Cob',
    value: 'welshponycob',
  },
  {
    label: 'Western Hunter',
    value: 'westernhunter',
  },
  {
    label: 'Western Other',
    value: 'westernother',
  },
  {
    label: 'Western Pleasure',
    value: 'westernpleasure',
  },
  {
    label: 'Western Riding',
    value: 'westernriding',
  },
  {
    label: 'Working Cow Horse',
    value: 'workingcowhorse',
  },
  {
    label: 'Working Equitation',
    value: 'workingequitation',
  },
  {
    label: 'English - General',
    value: 'englishgeneral',
  },
  {
    label: 'Western - General',
    value: 'westerngeneral',
  },
  {
    label: 'Other',
    value: 'other',
  },
]
