// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

import { TEventRegisteredUsers } from '../../../../../models/event-registered-users/event-registered-users.interface'

/**
 * @TODO Document this
 */
const calculateRevenueOfSingleRegistration = (
  currRegistration: TEventRegisteredUsers,
  eventCost: number
) => {
  let totalRevenue: number = 0
  let cost = 0

  if (!currRegistration?.selectionDetails) return 0

  let ticketsRevenue = 0

  cost = 0

  let feesRevenue = 0
  cost = 0

  let eventRevenue = currRegistration?.selectionDetails?.selectedEvents?.reduce(
    (totalEventsRevenue: number, currEvent: any) => {
      if (typeof totalEventsRevenue !== 'number') totalEventsRevenue = 0
      cost = Number(eventCost)
      totalEventsRevenue =
        totalEventsRevenue +
        cost *
          Number(
            currEvent?.members?.filter((currMember: any) => {
              return Array.isArray(currMember?.horses) && currMember?.horses?.length
            })?.length ?? 0
          )
      return totalEventsRevenue
    },
    0
  )

  totalRevenue += ticketsRevenue + feesRevenue + eventRevenue
  return totalRevenue
}

export { calculateRevenueOfSingleRegistration }
