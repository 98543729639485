// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React, { useEffect, useState } from 'react'
import UserRegistrationDialogComponent from '../../../components/user/dialogs/user-registration/UserRegistrationDialogComponent'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  selectShowRegistration,
  selectUserId,
  setCompetitionProfileImageUrl,
  setShowRegistration,
  setUsername,
} from '../../../store/user/userSlice'
import { UserModel } from '../../../models/users/user.model'
import UserService from '../../../services/userService'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { cloneDeep } from 'lodash'
import FirebaseStorageService from '../../../services/storageService'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const UserRegistrationContainer: React.FC = (props) => {
  // Hooks and vars
  const dispatch = useAppDispatch()

  const showRegistration = useAppSelector(selectShowRegistration)
  const userId = useAppSelector(selectUserId)

  const [userData, setUserData] = useState<any>(null)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onRegistrationStartNextValidHandler = async (user_type: string, username: string) => {
    const newUserData = cloneDeep(userData)

    newUserData.userType = user_type
    newUserData.setUsernameAndCalculateNGram(username)
    dispatch(setUsername(newUserData.username))

    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
      newUserData.id,
      newUserData.toFirestore()
    )

    setUserData(newUserData)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onCompetitorRegistrationUSEFNumberValidHandler = async (USEF_number: any) => {
    const newUserData = cloneDeep(userData)
    newUserData.competitorUSEFNumber = USEF_number

    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
      newUserData.id,
      newUserData.toFirestore()
    )

    setUserData(newUserData)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onCompetitorRegistrationProfileValidHandler = async (profile_data: any) => {
    const newUserData = cloneDeep(userData)
    newUserData.competitorFirstName = profile_data.firstName
    newUserData.competitorLastName = profile_data.lastName
    newUserData.competitorDOB = profile_data.birthday
    newUserData.competitorAddress = profile_data.address
    newUserData.competitorPhoneNumber = profile_data.phoneNumber
    newUserData.competitorNumber1 = profile_data.num1
    newUserData.competitorNumber2 = profile_data.num2
    newUserData.competitorNumber3 = profile_data.num3
    newUserData.competitorUSEANumber = profile_data.num4
    newUserData.competitorUSHJANumber = profile_data.num5
    newUserData.competitorOtherNumber = profile_data.num6
    newUserData.competitorCompetitionDetail1 = profile_data.dressage
    newUserData.competitorCompetitionDetail2 = profile_data.dressage2
    newUserData.competitorCreditCardNumber = profile_data.card
    newUserData.competitorSocialSecurityNumber = profile_data.socialSecurity

    newUserData.registrationCompleted = true

    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
      newUserData.id,
      newUserData.toFirestore()
    )

    setUserData(newUserData)

    dispatch(setShowRegistration(false))
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  async function fileChangedHandler(event: any, fileInputRef: any, uploadProgressCallback: any) {
    const newUserData = cloneDeep(userData)
    const files = event.target.files
    const file = files[0]

    if (!file) {
      alert('File Select Failed. Please try again.')
      return
    }
    try {
      const downloadUrl = await FirebaseStorageService.uploadFile(
        file,
        `${CONST.DATA.STORAGE.USERS.COMPETITOR_PROFILE_IMAGE_URL.PREFIX}/${userId}`,
        uploadProgressCallback
      )

      newUserData.competitorProfilePictureUrl = downloadUrl
      dispatch(setCompetitionProfileImageUrl(downloadUrl))

      await FirestoreService.updateItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        newUserData.id,
        newUserData.toFirestore()
      )

      setUserData(newUserData)
    } catch (error) {
      fileInputRef.current.value = ''
      throw error
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  async function handleCancelImageClick() {
    const newUserData = cloneDeep(userData)

    await FirebaseStorageService.deleteFile(newUserData.competitorProfilePictureUrl)

    newUserData.competitorProfilePictureUrl = ''
    dispatch(setCompetitionProfileImageUrl(null))

    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
      newUserData.id,
      newUserData.toFirestore()
    )

    setUserData(newUserData)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onOrganizerRegistrationUSEFNumberValidHandler = async (USEF_number: any) => {
    const newUserData = cloneDeep(userData)
    newUserData.organizerUSEFNumber = USEF_number

    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
      newUserData.id,
      newUserData.toFirestore()
    )

    setUserData(newUserData)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onOrganizerRegistrationProfileValidHandler = async (organizer_data: any) => {
    const newUserData = cloneDeep(userData)

    newUserData.organizerFirstName = organizer_data.firstName
    newUserData.organizerLastName = organizer_data.lastName
    newUserData.organizerDOB = organizer_data.birthday
    newUserData.organizerAddress = organizer_data.address
    newUserData.organizerNumber1 = organizer_data.num1
    newUserData.organizerNumber2 = organizer_data.num2
    newUserData.organizerNumber3 = organizer_data.num3
    newUserData.organizerUSEANumber = organizer_data.num4
    newUserData.organizerUSHJANumber = organizer_data.num5
    newUserData.organizerOtherNumber = organizer_data.num6
    newUserData.organizerCompetitionDetail1 = organizer_data.dressage
    newUserData.organizerCompetitionDetail2 = organizer_data.dressage2
    newUserData.organizerCreditCardNumber = organizer_data.card
    newUserData.organizerSocialSecurityNumber = organizer_data.socialSecurity

    newUserData.registrationCompleted = true

    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
      newUserData.id,
      newUserData.toFirestore()
    )

    setUserData(newUserData)

    dispatch(setShowRegistration(false))
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onVendorRegistrationVendorFormValidHandler = async (vendor_data: any) => {
    const newUserData = cloneDeep(userData)

    newUserData.vendorBusinessName = vendor_data.businessName
    newUserData.vendorBusinessCategory = vendor_data.businessCat
    newUserData.vendorLocation = vendor_data.location
    newUserData.vendorWebsite = vendor_data.website
    newUserData.vendorPhoneNumber = vendor_data.phoneNumber
    newUserData.vendorBusinessEmail = vendor_data.email
    newUserData.vendorLogoUrl = vendor_data.logo

    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
      newUserData.id,
      newUserData.toFirestore()
    )

    setUserData(newUserData)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  const onVendorRegistrationProfileValidHandler = async (vendor_data: any) => {
    const newUserData = cloneDeep(userData)

    newUserData.vendorBusinessName = vendor_data.ariat
    newUserData.vendorBusinessCategory = vendor_data.apparrel
    newUserData.vendorLocation = vendor_data.location
    newUserData.vendorWebsite = vendor_data.website
    newUserData.vendorPhoneNumber = vendor_data.phoneNumber
    newUserData.vendorBusinessEmail = vendor_data.email
    newUserData.vendorLogoUrl = vendor_data.aboutImage

    newUserData.vendorAbout = vendor_data.about
    newUserData.vendorFacebook = vendor_data.facebook
    newUserData.vendorInstagram = vendor_data.instagram
    newUserData.vendorTwitter = vendor_data.twitter
    newUserData.vendorLinkedin = vendor_data.linkedin

    newUserData.registrationCompleted = true

    await FirestoreService.updateItem(
      CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
      newUserData.id,
      newUserData.toFirestore()
    )

    setUserData(newUserData)

    dispatch(setShowRegistration(false))
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  useEffect(() => {
    if (!userId) return

    if (userData === null) {
      const getUserData = async () => {
        let user_exists = false
        let user_doc = null
        while (!user_exists) {
          // if (userId) {
          user_doc = await UserService.getUserInfoById(userId)
          user_exists = user_doc.exists()
          // }
        }
        if (user_doc) setUserData(UserModel.fromFirestoreDoc(user_doc).toObject())
      }
      getUserData().then()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <UserRegistrationDialogComponent
      isOpen={showRegistration}
      userData={userData}
      onRegistrationStartNextValid={onRegistrationStartNextValidHandler}
      onCompetitorRegistrationUSEFNumberValid={onCompetitorRegistrationUSEFNumberValidHandler}
      onCompetitorRegistrationProfileValid={onCompetitorRegistrationProfileValidHandler}
      onCompetitorRegistrationImageChanged={fileChangedHandler}
      onCompetitionRegistrationCancelImageClick={handleCancelImageClick}
      onOrganizerRegistrationUSEFNumberValid={onOrganizerRegistrationUSEFNumberValidHandler}
      onOrganizerRegistrationProfileValid={onOrganizerRegistrationProfileValidHandler}
      onVendorRegistrationVendorFormValid={onVendorRegistrationVendorFormValidHandler}
      onVendorRegistrationProfileValid={onVendorRegistrationProfileValidHandler}
    />
  )
}

export default UserRegistrationContainer
