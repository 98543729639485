import React, { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../../../store/hooks'
import { selectProfileData } from '../../../../store/user/userSlice'
import Image from './Image'
import clsx from 'clsx'
import ImageAndVideoButtons from './ImageAndVideoButtons'

const Header = () => {
  let dualImageJSX = <></>
  const IMAGE_COUNT_TO_SHOW = 7
  const image = 'assets/placeholders/Empty Event Portrait.png'

  const profileDetails = useAppSelector(selectProfileData)

  const toggleImageGallery = useRef<any>()
  const [userBrandImages, setUserBrandImages] = useState<string[]>([])

  useEffect(() => {
    let userBrandImages = [...(profileDetails?.userBrandImages ?? [])]

    if (userBrandImages.length) {
      if (userBrandImages.length < IMAGE_COUNT_TO_SHOW) {
        userBrandImages = [
          ...userBrandImages,
          ...new Array(IMAGE_COUNT_TO_SHOW - userBrandImages.length),
        ]
      } else {
        userBrandImages = userBrandImages.slice(0, IMAGE_COUNT_TO_SHOW)
      }
    }

    setUserBrandImages(userBrandImages)
  }, [profileDetails?.userBrandImages])

  const getDualImageJSX = (index: number, imageSrc: string) => {
    let userBrandImages = profileDetails?.userBrandImages ?? []

    let remainingImageCount = userBrandImages.length - IMAGE_COUNT_TO_SHOW
    let isLastElement = index === IMAGE_COUNT_TO_SHOW - 1 && remainingImageCount > 0

    const className = clsx(
      !isLastElement
        ? index % 3 === 0
          ? 'h-[unset] w-1/5'
          : '!h-[calc(50%-4px)]'
        : 'w-full h-full',
      imageSrc === image && '!object-cover'
    )

    const wrapperClassName = clsx(
      isLastElement && 'w-1/5 shrink-0 relative overflow-hidden rounded-xl'
    )

    if (index % 3 === 0) {
      return isLastElement ? (
        <div className={wrapperClassName}>
          <Image src={imageSrc} alt="header image" className={className} />
          <div
            className="w-full h-full absolute bg-[#000000b0] bg-opacity-5 flex items-center justify-center text-SeabiscuitMainThemeColor font-medium text-5xl 3xl:text-7xl xxl:text-9xl cursor-pointer top-0 left-0 select-none"
            onClick={() => {
              toggleImageGallery.current?.setShowImageGallery?.(true)
            }}
          >
            +{remainingImageCount}
          </div>
        </div>
      ) : (
        <Image src={imageSrc} alt="header image" className={className} />
      )
    } else if ((index + 1) % 3 === 0) {
      return (
        <div className="block w-1/5">
          <div className="flex flex-col gap-2 shrink-0 h-full">
            {dualImageJSX}
            <Image src={imageSrc} alt="header image" className={className} />
          </div>
        </div>
      )
    } else {
      dualImageJSX = <Image src={imageSrc} alt="header image" className={className} />
    }

    return null
  }

  return (
    <div className="relative">
      <div
        className={clsx(
          'rounded-xl overflow-hidden h-full min-h-[200px] max-h-[200px] lg:min-h-[250px] lg:max-h-[250px] xl:min-h-[300px] xl:max-h-[300px] 2xl:min-h-[350px] 2xl:max-h-[350px] xxl:min-h-[800px] xxl:max-h-[800px] flex gap-2',
          !userBrandImages.length && 'bg-SeabiscuitGrayThemeColor'
        )}
      >
        {userBrandImages?.map((currBrandImage, index) => {
          return (
            <React.Fragment key={`brandImage${index}`}>
              {getDualImageJSX(index, currBrandImage ?? image)}
            </React.Fragment>
          )
        })}
      </div>
      <div className="absolute right-4 bottom-4">
        <ImageAndVideoButtons ref={toggleImageGallery} />
      </div>
    </div>
  )
}

export default Header
