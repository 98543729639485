/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
//third-party
import { yupResolver } from '@hookform/resolvers/yup'
import { Autorenew, AutorenewRounded, CameraAlt, HelpOutline } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import { CONST } from '../../const/const'
import { MESSAGES_CONST } from '../../const/messages-const'
import useToasterHelper from '../../helpers/ToasterHelper'
import { IHorseData } from '../../models/horse/horse.interface'
import FirestoreService from '../../services/firestoreService'
import { selectUserId } from '../../store/user/userSlice'
import { horseSchema } from '../../validations'

//Fake Data
import Select, { GroupBase, OptionProps, components } from 'react-select'
import {
  HeightRestOptions,
  SexRestOptions,
  customStyles,
} from '../../components/customStyles/ReactSelectCustomStyle'

//fakeData
import { cloneDeep } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import CustomDatePicker from '../../components/common/inputs/CustomDatePicker'
import formFunctions from '../../components/ui/form/form-functions/formFunctions'
import FormHeader from '../../components/ui/form/form-header/FormHeader'
import { IMAGE_CONSTS } from '../../const/image-const'
import { LOCALSTORAGE_CONST } from '../../const/local-storage-const'
import { ROUTES_CONST } from '../../const/routes-const'
import { zoneList } from '../../fakeData/ZoneList'
import { CountryList } from '../../fakeData/countryList'
import { disciplineData } from '../../fakeData/disciplineList'
import { horseBreedList } from '../../fakeData/horseBreedList'
import { horseColorList } from '../../fakeData/horseColorList'
import { horseMarkingList } from '../../fakeData/horseMarkingList'
import { IHandleModal } from '../../layout/mainlayout/MainLayout'
import { HorseModel } from '../../models/horse/horse.model'
import { getConvertedData, getReactPickerDate } from '../../models/interface.helper'
import { IUserHorseMappingInterface } from '../../models/user-horse-mapping/userHorseMapping.interface'
import { UserHorseMappingModel } from '../../models/user-horse-mapping/userHorseMapping.model'
import FirebaseStorageService from '../../services/storageService'
import { setRegisterHorseData } from '../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  selectHorseReducer,
  selectMappings,
  setHorses,
  setHorsesSelectedForCompetetion,
  setIsMyHorse,
  setMappings,
  setSelectedHorse,
  setSelectedHorseMapping,
  setSelectedHorseStatus,
  updateHorse,
  updateHorseProfileImage,
  updateKeysInMappings,
} from '../../store/horses/horseSlice'
// import { useIonRouter } from '@ionic/react';

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
interface IProps {
  show?: boolean
  horseId: string | null
  selectedHorseIndex: number | null
  handleModal?: IHandleModal['handleModal']
}

type MyOptionType = {
  dark_icon: string
  label: string
  light_icon: string
  value: string
}

type ILocalImage = { src: string; event: any }

// Constants
const HORSES_MAPPING_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USER_HORSE_MAPPING

const DEFAULT_HORSE_PIC = IMAGE_CONSTS.PLACEHOLDERS.HORSE_PROFILE_PIC
const LOCAL_IMAGE = { src: DEFAULT_HORSE_PIC, event: null }
const AgeVerifiedOption = [
  { label: 'Age Verified', value: 'Yes' },
  { label: 'Age Not Verified', value: 'No' },
]

const FIEOptions = [
  { label: 'Membership Active', value: 'Active' },
  { label: 'Membership Inactive', value: 'Inactive' },
]

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const ManageHorseForm = (props: IProps) => {
  // Hooks and vars
  // const router = useIonRouter();
  const dispatch = useAppDispatch()
  const mappings = useAppSelector(selectMappings)
  const toastFunctions = useToasterHelper()
  const setUploadProgress = useState(-1)[1]
  const { id } = useParams<{ id: string }>()
  const userId = useAppSelector(selectUserId)
  const fileInputRef = useRef() as React.MutableRefObject<HTMLInputElement>

  const {
    register,
    formState: { errors },
    reset,
    control,
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(horseSchema),
  })

  const { fields, append } = useFieldArray({
    control,
    name: 'horsesOther',
  })

  const [loading, setLoading] = useState(false)
  const [markings, setMarkings] = useState<string[]>([])
  const [horsePicLoading, setHorsePicLoading] = useState(false)
  const [localImage, setLocalImage] = useState<ILocalImage>({ ...LOCAL_IMAGE })

  const formStyles = formFunctions.getFormStyles()
  const [saved, isSaved] = useState(true)
  const horseReducer = useAppSelector(selectHorseReducer)
  const horse = horseReducer.selected.horse
  const addHorse = horseReducer.isAddHorsePage
  const selectedHorse = horse.data

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (horse.status === 'fulfilled' && horse.data) {
      let horseData = getConvertedData(new HorseModel(horse.data).toObject())
      reset({
        ...horse,
        ...horseData,
      })
      setMarkings([...(horseData.horseMarking ?? [])])
    }
  }, [horse.status])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handleHorseMarkings = (args: { newValue: any; actionMeta: any }) => {
    setMarkings([...args.newValue.map((curr: any) => curr.value)])
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Updates horse in redux
   */
  const updateHorseInRedux = (data: IHorseData) => {
    const dataToThrow = { ...getConvertedData(data) }
    dispatch(
      updateHorse({
        data: {
          ...dataToThrow,
          isMyHorse: userId === dataToThrow.horseOwnerId,
        },
      })
    )
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Updates the horse in db
   */
  const onSubmit = async (data: IHorseData) => {
    let errorOccured = false
    let horseImageUrl: null | string = null
    let mapping: IUserHorseMappingInterface | null = null
    let createdMappings: IUserHorseMappingInterface[] = []

    setLoading(true)

    try {
      if (!data.horseId) data.horseId = uuidv4()
      if (id) data.id = horse.data?.id
      else data.horseStatus = '0'

      data.horseMarking = markings

      let userFilterData = new HorseModel(data).toObject()

      if (addHorse && !horse.data) {
        let horseToCreate: IHorseData = {
          ...userFilterData,
          horseStatus: '1',
          horseOwnerId: userId,
        }

        const ref = await FirestoreService.createItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME,
          new HorseModel(horseToCreate).toFirestore()
        )

        horseToCreate.id = ref.id
        horseToCreate = getConvertedData(horseToCreate)

        if (localImage.event) {
          setLocalImage({ ...LOCAL_IMAGE })
          horseImageUrl = await fileChangedHandler(
            localImage.event,
            setUploadProgress,
            ref.id,
            horseToCreate
          )
          if (horseImageUrl) {
            horseToCreate.horseProfilePicture = horseImageUrl
          }
        }

        let docToSave = new UserHorseMappingModel({
          userId,
          horseId: (horseToCreate ? horseToCreate.id : null) ?? null,
          isMyHorse: true,
          horseOwnerId: userId,
          horseName: horseToCreate.horseName,
          horseZone: horseToCreate.horseZone,
          horseSelectedForCompeletion: true,
          horseNameNGram: horseToCreate?.horseNameNGram,
          horseDiscipline: horseToCreate.horseDiscipline,
          horseProfilePicture: horseToCreate.horseProfilePicture,
        })

        mapping = docToSave.cloneDeep().toFirestore()

        mapping!.id = (
          await FirestoreService.createItem(HORSES_MAPPING_COLLECTION.NAME, docToSave.toFirestore())
        )?.id

        mapping = getConvertedData(mapping!)

        createdMappings.push(mapping)

        dispatch(setMappings([...mappings.data, ...createdMappings]))
        dispatch(setSelectedHorseMapping(mapping!))
        dispatch(setHorses(horseToCreate))
        dispatch(setRegisterHorseData(horseToCreate))
        dispatch(setHorsesSelectedForCompetetion(horseToCreate))
        dispatch(
          setSelectedHorse({
            ...horseToCreate,
          })
        )
        dispatch(setIsMyHorse(true))
        dispatch(setSelectedHorseStatus('fulfilled'))

        toastFunctions.success({ message: MESSAGES_CONST.HORSE_CREATED })
      } else {
        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME,
          userFilterData.id,
          userFilterData
        )
        updateHorseInRedux(userFilterData)
        props?.handleModal?.(false, 'horse')
        toastFunctions.success({ message: MESSAGES_CONST.HORSE_UPDATE_SUCCESSFULLY })
      }
    } catch (err: any) {
      errorOccured = true
      console.log(err?.message ?? err, 'err')

      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      isSaved(true)
      setLoading(false)

      if (errorOccured) return

      /* Redirecting the user to, from where he have came */
      //let cameFrom = localStorage.getItem(LOCALSTORAGE_CONST.ADD_HORSE_FROM);
      let cameFrom = '/add-horse'

      if (cameFrom && !cameFrom.includes(ROUTES_CONST.REGISTER_EVENT.URL)) {
        cameFrom = ROUTES_CONST.ACCOUNT_SETTINGS.URL
        localStorage.setItem('tab_type', '2')
      }

      if (cameFrom) {
        localStorage.removeItem(LOCALSTORAGE_CONST.ADD_HORSE_FROM)
        localStorage.setItem('tab_type', '2')
      }
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Uploads the file in firebase storage
   */
  async function fileChangedHandler(
    event: any,
    uploadProgressCallback: any,
    id?: string,
    selectedHorseFromArg?: IHorseData
  ) {
    let downloadUrl: null | string = null
    const files = event.target.files
    const file = files[0]
    let selectedHorse_ = cloneDeep(selectedHorseFromArg ?? selectedHorse)
    const id_ = horseReducer?.selected.horse.data?.id ?? id
    const mappingDocId = horseReducer?.selected.mapping.data?.id

    setHorsePicLoading(true)

    if (!file) {
      toastFunctions.info({ message: 'No file selected.' })
      return null
    }

    if (!id_) {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        setHorsePicLoading(false)
        setLocalImage({
          event,
          src: e.target.result,
        })
      }
      reader.readAsDataURL(file)
      return null
    }

    try {
      downloadUrl =
        ((await FirebaseStorageService.uploadFile(
          file,
          `${CONST.DATA.STORAGE.HORSE.HORSE_PROFILE_IMAGE_URL.PREFIX}/${id_}`,
          uploadProgressCallback
        )) as string) ?? null

      if (selectedHorse_ && typeof downloadUrl === 'string') {
        selectedHorse_.horseProfilePicture = downloadUrl as any

        await FirestoreService.updateItem(
          CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.HORSES.NAME,
          id_,
          selectedHorse_
        )

        dispatch(setSelectedHorse(getConvertedData(selectedHorse_)))

        if (!addHorse)
          dispatch(
            updateHorseProfileImage({
              image: downloadUrl,
              index: props.selectedHorseIndex,
            })
          )

        setValue('horseProfilePicture', downloadUrl)

        if (mappingDocId) {
          dispatch(
            updateKeysInMappings({
              mappingDocId: mappingDocId,
              keysToUpdate: {
                horseProfilePicture: downloadUrl,
              },
            })
          )
        }

        toastFunctions.success({ message: MESSAGES_CONST.HORSE_PIC_UPDATED })
      }
    } catch (error) {
      toastFunctions.error({ message: MESSAGES_CONST.SOMETHING_WENT_WRONG })
      console.log({ error })
    } finally {
      setHorsePicLoading(false)
      return downloadUrl
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const CustomOption: React.ComponentType<
    OptionProps<MyOptionType, false, GroupBase<MyOptionType>>
  > = (props) => {
    const { label, isDisabled } = props
    return isDisabled ? (
      <div className="border border-b-[1px] border-dashed px-2"></div>
    ) : (
      <components.Option {...props}>{label}</components.Option>
    )
  }

  return (
    <>
      <FormHeader
        title="Horse data"
        description={`${addHorse ? 'Add' : 'Update'} your horses data`}
        headerButtonsContainer={
          <div className="flex items-center gap-2">
            {saved ? (
              <button
                type="button"
                onClick={() => isSaved(false)}
                className="items-center w-[150px] h-[45px] bg-white border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-nr font-[400] text-SeabiscuitMainThemeColor saveBtn"
              >
                Edit
              </button>
            ) : (
              <button
                type="button"
                disabled={loading}
                onClick={handleSubmit(onSubmit as any)}
                className="items-center w-[150px] h-[45px] border border-SeabiscuitMainThemeColor bg-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium text-white saveBtn"
              >
                {loading ? (
                  <AutorenewRounded fontSize="small" className="animate-spin" />
                ) : (
                  'Save Changes'
                )}
              </button>
            )}
          </div>
        }
      />

      <div className={formStyles.className} style={formStyles.styles}>
        <div className="horseEditForm">
          <div className="flex items-center">
            <div className="w-1/4 flex items-center">
              <img src="/assets/img/dark/Image.png" alt="imgIcon" />
              <p className="ml-2">Profile picture</p>
            </div>

            <div className="relative p-1 bg-[#d3daee54] rounded-full cursor-pointer flex items-center justify-center w-[90px] h-[90px]">
              <input
                disabled={horsePicLoading || saved}
                type="file"
                accept=".jpeg, .png, .jpg"
                ref={fileInputRef}
                onChange={(e: any) => fileChangedHandler(e, setUploadProgress)}
                className="UserProfilePicture absolute h-full w-full opacity-0 left-0 right-0 top-0 bottom-0 z-40 cursor-pointer"
              />

              {horsePicLoading ? (
                <Autorenew className="animate-spin" />
              ) : (
                <img
                  src={
                    selectedHorse?.horseProfilePicture === '' || !selectedHorse?.horseProfilePicture
                      ? localImage.src
                      : selectedHorse?.horseProfilePicture
                  }
                  className="object-cover rounded-full h-full w-full"
                  alt="profile_picture"
                />
              )}

              {!saved && (
                <div className="border border-SeabiscuitGray500ThemeColor text-[#8C95AC]  bg-[#8e95aa]  p-1.5 pl-2 pr-2 rounded-full absolute right-0 bottom-0  w-[45%]">
                  <CameraAlt className="text-white text-xs" />
                </div>
              )}
            </div>
          </div>

          <hr className="w-full my-4" />

          {/* USEF number */}

          <div className="flex flex-wrap items-center mb-2">
            <label className="text-[#122B46] w-1/4 flex items-center">
              <img src="/assets/img/dark/Parliament.svg" className="mr-2" alt="Parliament" />
              USEF number
            </label>

            <div className="flex w-1/1 gap-[6px]">
              <input
                type="text"
                placeholder="Enter number..."
                disabled={saved}
                {...register('horseUsefNumber')}
                className={`w-[288px] mr-2 border border-[#D3DAEE] text-gray-900 text-sm rounded-xl focus:border-[#D3DAEE] focus:ring-0 p-3 ${saved ? '!border-white text-SeabiscuitDark200ThemeDark' : '!border-[#D3DAEE]'}`}
              />

              {/* <div className="border border-SeabiscuitGray500ThemeColor text-dark rounded-xl w-[260px] flex items-center relative justify-center bg-[#d3d3d3] ">
                                <img src="/assets/og_icons/Synchronize.svg" className="mr-2 absolute left-2" alt="circle" />
                                Sync profile with USEF
                            </div> */}

              <div
                onClick={() => window.open('https://www.usef.org/log-in', '_blank')}
                className=" text-[#8C95AC] rounded-xl w-[50%] flex items-center cursor-pointer relative p px-4 mr-2"
              >
                <img src="/assets/og_icons/USEF.svg" className="relative mr-1" alt="circle" />
                Open USEF portal {'>'}
              </div>
            </div>
          </div>

          <hr className="my-4"></hr>

          {/* FEI number */}
          <div className="flex flex-wrap items-center ">
            <label className="w-1/4 flex items-center">
              <img src="/assets/img/dark/Parliament.svg" className="mr-2" alt="parliamenticons" />
              FEI number
            </label>
            <div className="flex gap-[6px] w-[535px]">
              <input
                type="number"
                disabled={saved}
                placeholder="Enter number..."
                {...register('horseFeiNumber')}
                className={`w-full mr-2 border border-[#D3DAEE] text-sm rounded-xl focus:border-[#D3DAEE] focus:ring-0 p-3 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:opacity-50 placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
              />

              <Controller
                control={control}
                name="horseFeiStatus"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <Select
                      onChange={onChange}
                      value={FIEOptions.find((c) => c.value === value || c.value === value)}
                      onBlur={onBlur}
                      isDisabled={saved}
                      options={FIEOptions}
                      placeholder="FEI Status"
                      className={`w-full border-solid border-[#D3DAEE] border rounded-xl selectWithSearch  focus:ring-[#D3DAEE] focus:border-[#D3DAEE] selectWithSearch p-[2px] h-[51px]  ${saved ? '!border-white !text-SeabiscuitDark200ThemeColor !placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                      styles={
                        {
                          ...customStyles,
                          valueContainer: (provided: any) => ({
                            ...provided,
                            textAlign: 'left',
                            justifyContent: 'start',
                          }),
                          singleValue: (provided: any, state: any) => ({
                            ...(customStyles?.singleValue &&
                              customStyles?.singleValue(provided, state)),
                            fontSize: '14px !important',
                            color: state.isDisabled ? '#122b46' : provided.color,
                            opacity: state.isDisabled ? '1' : provided.opacity,
                          }),
                          dropdownIndicator: (provided: any, state: any) => ({
                            ...provided,
                            display: state.isDisabled ? 'none' : '',
                          }),
                        } as any
                      }
                      isClearable={false}
                      isMulti={false}
                      isSearchable={false}
                    />
                  )
                }}
              />
            </div>
          </div>

          <hr className="w-full my-4" />

          <div className="flex items-center">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/img/dark/NameTag.png"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Name
            </label>
            <div className="wrapper">
              <div className="usefSearch flex items-center relative">
                <input
                  type="text"
                  {...register('horseName')}
                  disabled={saved}
                  placeholder="Enter horses name..."
                  className={`rounded-xl w-[535px] border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 px-5 text-sm placeholder:text-[#122B4680] placeholder:text-sm customborder h-[51px]  ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                />
              </div>
              {errors.horseName && (
                <p className="text-sm text-SeabiscuitMainThemeColor">
                  {errors.horseName.message?.toString()}
                </p>
              )}
            </div>
          </div>

          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/cp_icons/Biotech-1.svg"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Breed
            </label>
            <div className="wrapper w-[535px] text-sm">
              <Controller
                control={control}
                name="horseBreed"
                render={({ field: { onChange, onBlur, value } }) => {
                  return (
                    <Select
                      isMulti={false}
                      onChange={onChange}
                      isDisabled={saved}
                      value={horseBreedList.find((c) => c.value === (value?.value ?? value))}
                      onBlur={onBlur}
                      placeholder="Select Breed..."
                      options={horseBreedList}
                      components={{
                        Option: CustomOption as any,
                      }}
                      className={`border-[#D3DAEE] border py-[1px] !text-sm w-full rounded-xl  ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                      isClearable={false}
                      styles={customStyles}
                      isSearchable={false}
                    />
                  )
                }}
              />
              {errors.horseBreed && (
                <p className="text-sm text-SeabiscuitMainThemeColor">
                  {errors.horseBreed.message?.toString()}
                </p>
              )}
            </div>
          </div>

          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/og_icons/User-1.svg"
                width="24px"
                className="mr-4"
                alt="breeder_name"
              />
              Breeder
            </label>
            <div className="wrapper w-[535px]">
              <input
                type="text"
                {...register('horseBreeder')}
                disabled={saved}
                placeholder="Enter Breeder"
                className={`rounded-xl w-[535px] border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 px-5 text-sm placeholder:text-[#122B4680] placeholder:text-sm h-[51px]  ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
              />
              {errors.horseBreed && (
                <p className="text-sm text-SeabiscuitMainThemeColor">
                  {errors.horseBreed.message?.toString()}
                </p>
              )}
            </div>
          </div>

          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/img/dark/Ruler.png"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Height
            </label>
            <div className="wrapper w-[535px]">
              <Controller
                name="horseHeight"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    isMulti={false}
                    onChange={onChange}
                    isDisabled={saved}
                    value={HeightRestOptions.find((c) => c.value === (value?.value ?? value))}
                    onBlur={onBlur}
                    placeholder="Select height..."
                    options={HeightRestOptions}
                    className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl  ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={false}
                  />
                )}
              />
              {errors.horseHeight && (
                <p className="text-sm text-SeabiscuitMainThemeColor">
                  {errors.horseHeight.message?.toString()}
                </p>
              )}
            </div>
          </div>

          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/og_icons/Clock-1.svg"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Date of birth
            </label>
            <div
              className={`wrapper flex justify-between w-[535px] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
            >
              <div className="horseDob w-[260px]">
                <Controller
                  name="horseDob"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <CustomDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={getReactPickerDate(value)}
                        peekNextMonth
                        disabled={saved}
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        maxDate={new Date()}
                        placeholderText="Select date of birth..."
                        className={`rounded-xl w-full border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] placeholder:text-[#122B4680] placeholder:text-sm opacity-1 py-3 text-sm border-[#D3DAEE] ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                      />
                    )
                  }}
                />
                {errors.horseDob && (
                  <p className="text-sm text-SeabiscuitMainThemeColor">
                    {errors.horseDob.message?.toString()}
                  </p>
                )}
              </div>

              <div className="horseAgeVerified w-[260px]">
                <Controller
                  name="horseAgeVerified"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      isMulti={false}
                      onChange={onChange}
                      value={AgeVerifiedOption.find((c) => c.value === (value?.value ?? value))}
                      onBlur={onBlur}
                      placeholder="Age verified?"
                      isDisabled={saved}
                      options={AgeVerifiedOption}
                      className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                      isClearable={false}
                      styles={customStyles}
                      isSearchable={false}
                    />
                  )}
                />
                {errors.horseAgeVerified && (
                  <p className="text-sm text-SeabiscuitMainThemeColor">
                    {errors.horseAgeVerified.message?.toString()}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Gender */}

          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/og_icons/Gender-1.svg"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Gender
            </label>
            <div className="wrapper w-[535px]">
              <Controller
                name="horseGender"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    isMulti={false}
                    onChange={onChange}
                    value={SexRestOptions.find((c) => c.value === (value?.value ?? value))}
                    onBlur={onBlur}
                    isDisabled={saved}
                    placeholder="Select Gender..."
                    options={SexRestOptions}
                    className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={false}
                  />
                )}
              />
              {errors.horseGender && (
                <p className="text-sm text-SeabiscuitMainThemeColor">
                  {errors.horseGender.message?.toString()}
                </p>
              )}
            </div>
          </div>

          {/* Discipline */}

          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/og_icons/YearofHorse-1.svg"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Disciplines
            </label>
            <div className="wrapper w-[535px]">
              <Controller
                name="horseDiscipline"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    onChange={onChange}
                    value={disciplineData.find((c) => c.value === (value?.value ?? value))}
                    onBlur={onBlur}
                    isDisabled={saved}
                    isMulti={false}
                    placeholder="Select Discipline..."
                    options={disciplineData}
                    className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={false}
                  />
                )}
              />
              {errors.horseDiscipline && (
                <p className="text-sm text-SeabiscuitMainThemeColor">
                  {errors.horseDiscipline.message?.toString()}
                </p>
              )}
            </div>
          </div>

          {/* Zone */}

          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/img/dark/MindMap.png"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Zone
              <span
                onClick={() => props?.handleModal?.(true, 'zone')}
                className="cursor-pointer flex items-center"
              >
                <HelpOutline
                  className="ml-2 text-SeabiscuitDark200ThemeColor relative -bottom-0.5"
                  viewBox="0 0 30 30"
                  fontSize="small"
                />
              </span>
            </label>
            <div className="wrapper w-[535px]">
              <Controller
                name="horseZone"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    onChange={onChange}
                    value={zoneList.find((c) => c.value === (value?.value ?? value))}
                    onBlur={onBlur}
                    isMulti={false}
                    isDisabled={saved}
                    placeholder="Select Zone..."
                    options={zoneList}
                    className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={false}
                  />
                )}
              />
              {errors.horseZone && (
                <p className="text-sm text-SeabiscuitMainThemeColor">
                  {errors.horseZone.message?.toString()}
                </p>
              )}
            </div>
          </div>

          {/* color */}

          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/img/dark/PaintPalette.png"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Coloring
            </label>
            <div className="wrapper w-[535px]">
              <Controller
                name="horseColor"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    onChange={onChange}
                    value={horseColorList.find((c) => c.value === (value?.value ?? value))}
                    onBlur={onBlur}
                    isMulti={false}
                    isDisabled={saved}
                    placeholder="Select Color..."
                    options={horseColorList}
                    className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={false}
                  />
                )}
              />
              {errors.horseColor && (
                <p className="text-sm text-SeabiscuitMainThemeColor">
                  {errors.horseColor.message?.toString()}
                </p>
              )}
            </div>
          </div>

          {/*  Markings*/}

          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/img/dark/PaintBrush.png"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Markings
            </label>
            <div className="wrapper w-[535px]">
              <Controller
                name="horseMarking"
                control={control}
                render={({ field: { onBlur } }) => {
                  return (
                    <Select
                      onChange={(newValue, actionMeta) =>
                        handleHorseMarkings({ newValue, actionMeta })
                      }
                      value={horseMarkingList
                        .filter((c) => {
                          return markings?.includes(c.value)
                        })
                        .map((c) => ({ ...c, isClearable: false }))}
                      onBlur={onBlur}
                      placeholder="Select Marking..."
                      isDisabled={saved}
                      isMulti
                      options={horseMarkingList}
                      className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                      isClearable={false}
                      styles={customStyles}
                      isSearchable={false}
                    />
                  )
                }}
              />
              {errors.horseMarking && (
                <p className="text-sm text-SeabiscuitMainThemeColor">
                  {errors.horseMarking.message?.toString()}
                </p>
              )}
            </div>
          </div>

          {/* Country */}

          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/img/dark/Globe.png"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Country of Origin
            </label>
            <div className="wrapper w-[535px]">
              <Controller
                name="horseCountry"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    onChange={onChange}
                    value={CountryList.find((c) => c.value === (value?.value ?? value))}
                    onBlur={onBlur}
                    isMulti={false}
                    isDisabled={saved}
                    options={CountryList}
                    placeholder="Select Country..."
                    className={`border-[#D3DAEE] border py-[1px] w-full rounded-xl ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    isClearable={false}
                    styles={customStyles}
                    isSearchable={true}
                  />
                )}
              />
              {errors.horseCountry && (
                <p className="text-sm text-SeabiscuitMainThemeColor">
                  {errors.horseCountry.message?.toString()}
                </p>
              )}
            </div>
          </div>

          <hr className="my-8"></hr>

          {/* Microchip number */}
          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/img/dark/Electronics.png"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Microchip number
            </label>
            <div className="wrapper w-[535px]">
              <div className="flex items-center justify-between ">
                <div className="w-[260px]">
                  <input
                    type="text"
                    {...register('horseMicrochipNumber')}
                    placeholder="Enter number..."
                    disabled={saved}
                    className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                {/* <div className='w-[260px]'>
                                <Controller
                                    name="horseMicrochipExpiration"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (<CustomDatePicker
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={getReactPickerDate(value)}
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select Exp Date"
                                            className={`rounded-xl w-full border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] placeholder:text-[#122B4680] placeholder:text-sm opacity-1 py-3 pl-10 text-sm border-[#D3DAEE]`}
                                        />
                                        )
                                    }
                                    }
                                />
                                {
                                    errors.horseMicrochipExpiration && <p className='text-sm text-SeabiscuitMainThemeColor'>
                                        {errors.horseMicrochipExpiration.message?.toString()}
                                    </p>
                                }
                            </div> */}

                <div
                  className={`relative flex items-center w-[260px] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <Controller
                    name="horseMicrochipExpiration"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <div className="pl-4 text-[14px]">Expires: </div>
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Exp Date"
                          disabled={saved}
                          className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px]`}
                        />
                      </>
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors.horseMicrochipExpiration && (
                    <p className="text-SeabiscuitMainThemeColor">
                      {errors.horseMicrochipExpiration.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* DSA Number */}
          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/og_icons/Biotech-1.svg"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              DNA Case Number
            </label>
            <div className="wrapper w-[535px]">
              <div className="flex items-center justify-between">
                <div className="w-[260px]">
                  <input
                    type="text"
                    {...register('horseDNACaseNumber')}
                    placeholder="Enter number..."
                    disabled={saved}
                    className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>
                {/* <div className='horseDNACaseExpiration w-[260px]'>
                                <Controller
                                    name="horseDNACaseExpiration"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (<CustomDatePicker
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={getReactPickerDate(value)}
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select Exp Date"
                                            className={`rounded-xl w-full border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] placeholder:text-[#122B4680] placeholder:text-sm opacity-1 py-3 pl-10 text-sm border-[#D3DAEE]`}
                                        />
                                        )
                                    }
                                    }
                                />
                                {
                                    errors.horseDNACaseExpiration && <p className='text-sm text-SeabiscuitMainThemeColor'>
                                        {errors.horseDNACaseExpiration.message?.toString()}
                                    </p>
                                }
                            </div> */}

                <div
                  className={`relative flex items-center w-[260px] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <Controller
                    name="horseDNACaseExpiration"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <div className="pl-4 text-[14px]">Expires: </div>
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Exp Date"
                          disabled={saved}
                          className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px]`}
                        />
                      </>
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors.horseDNACaseExpiration && (
                    <p className="text-SeabiscuitMainThemeColor">
                      {errors.horseDNACaseExpiration.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Passport number */}
          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/og_icons/Airport-1.svg"
                width="24px"
                className="mr-4"
                alt="horse_name"
              />
              Passport number
            </label>
            <div className="wrapper w-[535px]">
              <div className="flex items-center justify-between">
                <div className="w-[260px]">
                  <input
                    type="text"
                    {...register('horsePassportNumber')}
                    placeholder="Enter number..."
                    disabled={saved}
                    className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                {/* <div className='horsePassportExpiration w-[260px]'>
                                <Controller
                                    name="horsePassportExpiration"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (<CustomDatePicker
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={getReactPickerDate(value)}
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select Exp Date"
                                            className={`rounded-xl w-full border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] placeholder:text-[#122B4680] placeholder:text-sm opacity-1 py-3 pl-10 text-sm border-[#D3DAEE]`}
                                        />
                                        )
                                    }
                                    }
                                />
                                {
                                    errors.horsePassportExpiration && <p className='text-sm text-SeabiscuitMainThemeColor'>
                                        {errors.horsePassportExpiration.message?.toString()}
                                    </p>
                                }
                            </div> */}

                <div
                  className={`relative flex items-center w-[260px] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <Controller
                    name="horsePassportExpiration"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <div className="pl-4 text-[14px]">Expires: </div>
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Exp Date"
                          disabled={saved}
                          className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px]`}
                        />
                      </>
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors.horsePassportExpiration && (
                    <p className="text-SeabiscuitMainThemeColor">
                      {errors.horsePassportExpiration.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <hr className="my-8"></hr>

          {/* horseUsdfNumber */}
          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/cp_icons/Hierarchy-1.svg"
                width="24px"
                className="mr-4"
                alt="horseUsdfNumber"
              />
              USDF number
            </label>
            <div className="wrapper w-[535px]">
              <div className="flex items-center justify-between">
                <div className="w-[260px]">
                  <input
                    type="text"
                    {...register('horseUsdfNumber')}
                    placeholder="Enter USDF number..."
                    disabled={saved}
                    className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                <div
                  className={`relative flex items-center w-[260px] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <div className="pl-4 text-[14px]">Expires: </div>
                  <Controller
                    name="horseUsdfExpiration"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={getReactPickerDate(value)}
                        placeholderText="Select Exp Date"
                        disabled={saved}
                        className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px]`}
                      />
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors.horseUsdfExpiration && (
                    <p className="text-SeabiscuitMainThemeColor">
                      {errors.horseUsdfExpiration.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* horseUseaNumber */}
          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/cp_icons/Hierarchy-1.svg"
                width="24px"
                className="mr-4"
                alt="horseUseaNumber"
              />{' '}
              USEA number
            </label>
            <div className="wrapper w-[535px]">
              <div className="usefSearch flex items-center justify-between relative">
                <div className="w-[260px]">
                  <input
                    type="text"
                    {...register('horseUseaNumber')}
                    placeholder="Enter USEA number..."
                    disabled={saved}
                    className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                {/* <div className='w-[260px]'>
                                <Controller
                                    name="horseUseaExpiration"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (<CustomDatePicker
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={getReactPickerDate(value)}
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select Exp Date"
                                            className={`rounded-xl w-full border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] placeholder:text-[#122B4680] placeholder:text-sm opacity-1 py-3 pl-10 text-sm border-[#D3DAEE]`}
                                        />
                                        )
                                    }
                                    }
                                />
                                {
                                    errors.horseUseaExpiration && <p className='text-sm text-SeabiscuitMainThemeColor'>
                                        {errors.horseUseaExpiration.message?.toString()}
                                    </p>
                                }
                            </div> */}
                <div
                  className={`relative flex items-center w-[260px] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <div className="pl-4 text-[14px]">Expires: </div>
                  <Controller
                    name="horseUseaExpiration"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={getReactPickerDate(value)}
                        placeholderText="Select Exp Date"
                        disabled={saved}
                        className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px]`}
                      />
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors.horseUseaExpiration && (
                    <p className="text-SeabiscuitMainThemeColor">
                      {errors.horseUseaExpiration.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* horseUshjaNumber */}
          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/cp_icons/Hierarchy-1.svg"
                width="24px"
                className="mr-4"
                alt="horseUshjaNumber"
              />{' '}
              USHJA number
            </label>
            <div className="wrapper w-[535px]">
              <div className="flex items-center justify-between relative">
                <div className="w-[260px]">
                  <input
                    type="text"
                    {...register('horseUshjaNumber')}
                    placeholder="Enter USHJA number..."
                    disabled={saved}
                    className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>
                {/*
                            <div className='w-[260px]'>
                                <Controller
                                    name="horseUshjaExpiration"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (<CustomDatePicker
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={getReactPickerDate(value)}
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select Exp Date"
                                            className={`rounded-xl w-full border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] placeholder:text-[#122B4680] placeholder:text-sm opacity-1 py-3 pl-10 text-sm border-[#D3DAEE]`}
                                        />
                                        )
                                    }
                                    }
                                />
                                {
                                    errors.horseUshjaExpiration && <p className='text-sm text-SeabiscuitMainThemeColor'>
                                        {errors.horseUshjaExpiration.message?.toString()}
                                    </p>
                                }
                            </div> */}

                <div
                  className={`relative flex items-center w-[260px] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <div className="pl-4 text-[14px]">Expires: </div>
                  <Controller
                    name="horseUshjaExpiration"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={getReactPickerDate(value)}
                        placeholderText="Select Exp Date"
                        disabled={saved}
                        className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px]`}
                      />
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors.horseUshjaExpiration && (
                    <p className="text-SeabiscuitMainThemeColor">
                      {errors.horseUshjaExpiration.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* horseAhhsNumber */}
          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/cp_icons/Hierarchy-1.svg"
                width="24px"
                className="mr-4"
                alt="horseAhhsNumber"
              />{' '}
              AHHS number
            </label>
            <div className="wrapper w-[535px]">
              <div className="flex items-center justify-between relative">
                <div className="w-[260px]">
                  <input
                    type="text"
                    {...register('horseAhhsNumber')}
                    placeholder="Enter AHHS number..."
                    disabled={saved}
                    className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                {/* <div className='w-[260px]'>
                                <Controller
                                    name="horseAhhsExpiration"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (<CustomDatePicker
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={getReactPickerDate(value)}
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select Exp Date"
                                            className={`rounded-xl w-full border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] placeholder:text-[#122B4680] placeholder:text-sm opacity-1 py-3 pl-10 text-sm border-[#D3DAEE]`}
                                        />
                                        )
                                    }
                                    }
                                />
                                {
                                    errors.horseAhhsExpiration && <p className='text-sm text-SeabiscuitMainThemeColor'>
                                        {errors.horseAhhsExpiration.message?.toString()}
                                    </p>
                                }
                            </div> */}

                <div
                  className={`relative flex items-center w-[260px] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <div className="pl-4 text-[14px]">Expires: </div>
                  <Controller
                    name="horseAhhsExpiration"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={getReactPickerDate(value)}
                        placeholderText="Select Exp Date"
                        disabled={saved}
                        className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px]`}
                      />
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors.horseAhhsExpiration && (
                    <p className="text-SeabiscuitMainThemeColor">
                      {errors.horseAhhsExpiration.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* horseAmhaNumber */}
          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/cp_icons/Hierarchy-1.svg"
                width="24px"
                className="mr-4"
                alt="horseAmhaNumber"
              />{' '}
              AMHA number
            </label>
            <div className="wrapper w-[535px]">
              <div className="flex items-center justify-between relative">
                <div className="w-[260px]">
                  <input
                    type="text"
                    {...register('horseAmhaNumber')}
                    placeholder="Enter AMHA number..."
                    disabled={saved}
                    className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                {/* <div className='w-[260px]'>
                                <Controller
                                    name="horseAmhaExpiration"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (<CustomDatePicker
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={getReactPickerDate(value)}
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select Exp Date"
                                            className={`rounded-xl w-full border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] placeholder:text-[#122B4680] placeholder:text-sm opacity-1 py-3 pl-10 text-sm border-[#D3DAEE]`}
                                        />
                                        )
                                    }
                                    }
                                />
                                {
                                    errors.horseAmhaExpiration && <p className='text-sm text-SeabiscuitMainThemeColor'>
                                        {errors.horseAmhaExpiration.message?.toString()}
                                    </p>
                                }
                            </div> */}

                <div
                  className={`relative flex items-center w-[260px] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <div className="pl-4 text-[14px]">Expires: </div>
                  <Controller
                    name="horseAmhaExpiration"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={getReactPickerDate(value)}
                        placeholderText="Select Exp Date"
                        disabled={saved}
                        className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px]`}
                      />
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors.horseAmhaExpiration && (
                    <p className="text-SeabiscuitMainThemeColor">
                      {errors.horseAmhaExpiration.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* horseArhpaNumber */}
          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/cp_icons/Hierarchy-1.svg"
                width="24px"
                className="mr-4"
                alt="horseArhpaNumber"
              />{' '}
              ARHPA number
            </label>
            <div className="wrapper w-[535px]">
              <div className="flex items-center justify-between relative">
                <div className="w-[260px]">
                  <input
                    type="text"
                    {...register('horseArhpaNumber')}
                    placeholder="Enter ARHPA number..."
                    disabled={saved}
                    className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                {/* <div className='w-[260px]'>
                                <Controller
                                    name="horseArhpaExpiration"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (<CustomDatePicker
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={getReactPickerDate(value)}
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select Exp Date"
                                            className={`rounded-xl w-full border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] placeholder:text-[#122B4680] placeholder:text-sm opacity-1 py-3 pl-10 text-sm border-[#D3DAEE]`}
                                        />
                                        )
                                    }
                                    }
                                />
                                {
                                    errors.horseArhpaExpiration && <p className='text-sm text-SeabiscuitMainThemeColor'>
                                        {errors.horseArhpaExpiration.message?.toString()}
                                    </p>
                                }
                            </div> */}

                <div
                  className={`relative flex items-center w-[260px] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <div className="pl-4 text-[14px]">Expires: </div>
                  <Controller
                    name="horseArhpaExpiration"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={getReactPickerDate(value)}
                        placeholderText="Select Exp Date"
                        disabled={saved}
                        className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px]`}
                      />
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors.horseArhpaExpiration && (
                    <p className="text-SeabiscuitMainThemeColor">
                      {errors.horseArhpaExpiration.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* horseAshaNumber */}
          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/cp_icons/Hierarchy-1.svg"
                width="24px"
                className="mr-4"
                alt="horseAshaNumber"
              />{' '}
              ASHA number
            </label>
            <div className="wrapper w-[535px]">
              <div className="flex items-center justify-between relative">
                <div className="w-[260px]">
                  <input
                    type="text"
                    {...register('horseAshaNumber')}
                    placeholder="Enter ASHA number..."
                    disabled={saved}
                    className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                {/* <div className='w-[260px]'>
                                <Controller
                                    name="horseAshaExpiration"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (<CustomDatePicker
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={getReactPickerDate(value)}
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select Exp Date"
                                            className={`rounded-xl w-full border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] placeholder:text-[#122B4680] placeholder:text-sm opacity-1 py-3 pl-10 text-sm border-[#D3DAEE]`}
                                        />
                                        )
                                    }
                                    }
                                />
                                {
                                    errors.horseAshaExpiration && <p className='text-sm text-SeabiscuitMainThemeColor'>
                                        {errors.horseAshaExpiration.message?.toString()}
                                    </p>
                                }
                            </div> */}

                <div
                  className={`relative flex items-center w-[260px] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <div className="pl-4 text-[14px]">Expires: </div>
                  <Controller
                    name="horseAshaExpiration"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={getReactPickerDate(value)}
                        placeholderText="Select Exp Date"
                        disabled={saved}
                        className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px]`}
                      />
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors.horseAshaExpiration && (
                    <p className="text-SeabiscuitMainThemeColor">
                      {errors.horseAshaExpiration.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* horseUphaNumber */}
          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/cp_icons/Hierarchy-1.svg"
                width="24px"
                className="mr-4"
                alt="horseUphaNumber"
              />{' '}
              UPHA number
            </label>
            <div className="wrapper w-[535px]">
              <div className="flex items-center justify-between relative">
                <div className="w-[260px]">
                  <input
                    type="text"
                    {...register('horseUphaNumber')}
                    placeholder="Enter UPHA number..."
                    disabled={saved}
                    className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>

                {/* <div className='w-[260px]'>
                                <Controller
                                    name="horseUphaExpiration"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (<CustomDatePicker
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={getReactPickerDate(value)}
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select Exp Date"
                                            className={`rounded-xl w-full border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] placeholder:text-[#122B4680] placeholder:text-sm opacity-1 py-3 pl-10 text-sm border-[#D3DAEE]`}
                                        />
                                        )
                                    }
                                    }
                                />
                                {
                                    errors.horseUphaExpiration && <p className='text-sm text-SeabiscuitMainThemeColor'>
                                        {errors.horseUphaExpiration.message?.toString()}
                                    </p>
                                }
                            </div> */}

                <div
                  className={`relative flex items-center w-[260px] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <div className="pl-4 text-[14px]">Expires: </div>
                  <Controller
                    name="horseUphaExpiration"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={getReactPickerDate(value)}
                        placeholderText="Select Exp Date"
                        disabled={saved}
                        className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px]`}
                      />
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors.horseUphaExpiration && (
                    <p className="text-SeabiscuitMainThemeColor">
                      {errors.horseUphaExpiration.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* horseWdaaNumber */}
          <div className="flex items-center mt-2">
            <label className="flex items-center text-[#122B46] w-1/4">
              <img
                src="/assets/cp_icons/Hierarchy-1.svg"
                width="24px"
                className="mr-4"
                alt="horseWdaaNumber"
              />{' '}
              WDAA number
            </label>
            <div className="wrapper w-[535px]">
              <div className="flex items-center justify-between relative">
                <div className="w-[260px]">
                  <input
                    type="text"
                    {...register('horseWdaaNumber')}
                    placeholder="Enter WDAA number..."
                    disabled={saved}
                    className={`rounded-xl w-full border-[#D3DAEE] border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] py-3 placeholder:text-[#122B4680] placeholder:text-sm text-sm ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>
                {/*
                            <div className='w-[260px]'>
                                <Controller
                                    name="horseWdaaExpiration"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (<CustomDatePicker
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={getReactPickerDate(value)}
                                            peekNextMonth
                                            dropdownMode="select"
                                            showMonthDropdown
                                            showYearDropdown
                                            placeholderText="Select Exp Date"
                                            className={`rounded-xl w-full border focus:ring-[#D3DAEE] focus:border-[#D3DAEE] placeholder:text-[#122B4680] placeholder:text-sm opacity-1 py-3 pl-10 text-sm border-[#D3DAEE]`}
                                        />
                                        )
                                    }
                                    }
                                />
                                {
                                    errors.horseWdaaExpiration && <p className='text-sm text-SeabiscuitMainThemeColor'>
                                        {errors.horseWdaaExpiration.message?.toString()}
                                    </p>
                                }
                            </div> */}

                <div
                  className={`relative flex items-center w-[260px] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                >
                  <div className="pl-4 text-[14px]">Expires: </div>
                  <Controller
                    name="horseWdaaExpiration"
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <CustomDatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={getReactPickerDate(value)}
                        placeholderText="Select Exp Date"
                        disabled={saved}
                        className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px]`}
                      />
                    )}
                  />
                  {!saved && (
                    <KeyboardArrowDownIcon
                      fontSize="medium"
                      className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                    />
                  )}
                  {errors.horseWdaaExpiration && (
                    <p className="text-SeabiscuitMainThemeColor">
                      {errors.horseWdaaExpiration.message?.toString()}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* horseOtherNumber */}
          {fields.map((field, index) => (
            <React.Fragment key={field.id}>
              <div className="flex flex-wrap items-center mt-2">
                <div className="w-1/4 flex items-center text-[#122B46]">
                  <input
                    type="text"
                    {...register(`horsesOther.${index}.name`)}
                    placeholder="Enter name..."
                    disabled={saved}
                    className={` w-[230px]  border border-[#D3DAEE] text-gray-900 text-sm rounded-xl focus:border-[#D3DAEE] focus:ring-0 p-3 mr-4 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                  />
                </div>
                <div className="flex items-center">
                  <div>
                    <input
                      type="number"
                      {...register(`horsesOther.${index}.number`)}
                      placeholder="Enter number..."
                      disabled={saved}
                      className={`w-[260px] border border-[#D3DAEE] text-gray-900 text-sm rounded-xl focus:border-[#D3DAEE] focus:ring-0 p-3 mr-4 ${saved ? '!border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : '!border-[#D3DAEE]'}`}
                    />
                  </div>
                  {/* <div>
                                    <Controller
                                        name={`horsesOther.${index}.date`}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value } }) => {
                                            return (<CustomDatePicker
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                selected={getReactPickerDate(value)}
                                                peekNextMonth
                                                dropdownMode="select"
                                                portalId="root-portal"
                                                showMonthDropdown
                                                showYearDropdown
                                                placeholderText="Expirtation Date..."
                                                className={`w-[260px] border border-[#D3DAEE] text-gray-900 text-sm rounded-xl focus:border-[#D3DAEE] focus:ring-0 p-3 mr-2`}
                                            />
                                            )
                                        }
                                        }
                                    />
                                    {errors.horseOtherDate && <p className='text-sm text-SeabiscuitMainThemeColor'>{errors.horseOtherDate.message?.toString()}</p>}

                                </div> */}

                  <div
                    className={`relative flex items-center w-[260px] border ${saved ? 'border-white text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor' : 'border-[#D3DAEE] rounded-xl'}`}
                  >
                    <Controller
                      name={`horsesOther.${index}.date`}
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <CustomDatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={getReactPickerDate(value)}
                          placeholderText="Select Exp Date"
                          disabled={saved}
                          className={`rounded-xl w-[100%] selectWithSearch  border border-solid placeholder:text-SeabiscuitLightParagraphTextColor placeholder:text-[14px] placeholder:opacity-1 py-3 pl-10 !border-white h-[51px]`}
                        />
                      )}
                    />
                    {!saved && (
                      <KeyboardArrowDownIcon
                        fontSize="medium"
                        className="absolute pointer-events-none right-2 text-SeabiscuitLightParagraphTextColor"
                      />
                    )}
                    {errors.horseOtherDate && (
                      <p className="text-sm text-SeabiscuitMainThemeColor">
                        {errors.horseOtherDate.message?.toString()}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}

          <div className="w-100 flex items-center justify-start">
            {!saved && (
              <div
                className="flex items-center justify-start mt-4 cursor-pointer"
                onClick={() => {
                  append({
                    name: '',
                    number: '',
                    date: '',
                    uuid: uuidv4(),
                  })
                }}
              >
                <img
                  src="/assets/cp_icons/Cancel-2.svg"
                  className="w-[20px] rotate-45"
                  alt="cancelIcon"
                />
                <label className="ml-2 text-sm text-SeabiscuitMainThemeColor cursor-pointer">
                  Add number
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ManageHorseForm

// {
//     !addHorse ?
//     (<>
//         <hr className="my-8"></hr>

//         <div className="AccountEmail flex flex-wrap items-center">
//             <label className="mr-8 w-1/4 text-sm">
//                 <Autorenew className="mr-2" />
//                 Save all updates
//             </label>
//             <button
//                 type="submit"
//                 className="flex items-center w-[150px] justify-center h-[45px] border border-SeabiscuitMainThemeColor rounded-lg shadow-sm text-sm font-medium text-SeabiscuitMainThemeColor saveBtn"
//             >
//                 {loading ? <AutorenewRounded fontSize='small' className='animate-spin' /> : "Save"}
//             </button>
//         </div>
//     </>) :
//     (<div className='w-100 flex items-center justify-start'>
//         <div className='flex items-center justify-start mt-4'>
//             <img src="/assets/cp_icons/Cancel-2.svg" className="w-[20px] rotate-45" />
//             <label className="ml-2 text-sm text-SeabiscuitMainThemeColor">
//                 Add number
//             </label>
//         </div>
//     </div>)
// }
// </form >
