import { CONST } from '../../../const/const'
import { IUserInterface } from '../../../models/users/user.interface'
import OrganizerSideProfileUserInfoTab from './Tabs/OrganizerSideProfileUserInfoTab'
import OrganizerSideProfileUserStaffTab from './Tabs/OrganizerSideProfileUserStaffTab'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const OrganizerRootSideProfileDisplayTabs = ({
  userData,
  isMyProfile,
  activeTab,
  handleModal,
}: {
  userData: IUserInterface | null
  isMyProfile?: boolean
  setActiveTab?: any
  activeTab: string
  handleModal: any
}) => {
  // Funtions
  const selectTab = (selected_tab: string) => {
    switch (selected_tab) {
      case CONST.UI.USER.PROFILE.TABS.INFO.VALUE:
        return (
          <OrganizerSideProfileUserInfoTab
            userData={userData}
            isMyProfile={isMyProfile}
            handleModal={handleModal}
          />
        )
      case CONST.UI.USER.PROFILE.TABS.STAFF.VALUE:
        return <OrganizerSideProfileUserStaffTab isMyProfile={isMyProfile} />
    }

    return null
  }

  return selectTab(activeTab)
}

export default OrganizerRootSideProfileDisplayTabs
