// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React from 'react'

import Popover from '@mui/material/Popover'
import { Box } from '@mui/material'
import { EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST } from '../EventPrizeListFormBuilderUtil.const'

interface EventPrizeListFormBuilderUtilRowDisplayModeControlComponentProps {
  rowDisplayMode: string
  onRowDisplayModeChanged?: any
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventPrizeListFormBuilderUtilRowDisplayModeControlComponent: React.FC<
  EventPrizeListFormBuilderUtilRowDisplayModeControlComponentProps
> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  /**
   * @todo Document this
   */
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div className="relative">
      <div className="flex w-full mx-auto justify-end">
        <label
          htmlFor="total-divisions-offered"
          className="text-[16px] font-semibold text-SeabiscuitMainThemeColor mb-1"
          aria-describedby={id}
          onClick={handleClick}
        ></label>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box className="bg-SeabiscuitMainThemeColor">
            <div
              className="text-SeabiscuitWhiteThemeColor"
              onClick={(_) => {
                props.onRowDisplayModeChanged(
                  EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.DISCIPLINE
                )
                handleClose()
              }}
            >
              {EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.DISCIPLINE}
            </div>
            <div
              className="text-SeabiscuitWhiteThemeColor"
              onClick={(_) => {
                props.onRowDisplayModeChanged(
                  EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.DIVISIONS
                )
                handleClose()
              }}
            >
              {EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.DIVISIONS}
            </div>
            <div
              className="text-SeabiscuitWhiteThemeColor"
              onClick={(_) => {
                props.onRowDisplayModeChanged(
                  EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.SECTIONS
                )
                handleClose()
              }}
            >
              {EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.SECTIONS}
            </div>
            <div
              className="text-SeabiscuitWhiteThemeColor"
              onClick={(_) => {
                props.onRowDisplayModeChanged(
                  EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.CLASSES
                )
                handleClose()
              }}
            >
              {EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.CLASSES}
            </div>
          </Box>
        </Popover>
      </div>
    </div>
  )
}

EventPrizeListFormBuilderUtilRowDisplayModeControlComponent.defaultProps = {
  rowDisplayMode: EVENT_PRIZE_LIST_FORM_BUILDER_UTIL_CONST.DISPLAY_CONTROL.VALUES.DISCIPLINE,
}

export default EventPrizeListFormBuilderUtilRowDisplayModeControlComponent
