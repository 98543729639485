import { useContext } from 'react'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import { IEventReviewPublish } from '../../../../../models/event-review-publish/event-review-publish.interface'
import { getConvertedData } from '../../../../../models/interface.helper'
import EventReviewPublishInput from './EventReviewPublishInput'
import { TCustomSchedule } from '../../../../../models/event-scheduling/event-scheduling.interface'
import DataNotAvailable from '../../../../common/alerts/data-not-available/DataNotAvailable'
import { daysOfWeek } from '../../../../../helpers/time'

const DetailRow = ({
  index,
  note,
  onClick,
  getValueToShow,
}: {
  index: number
  note: TCustomSchedule['note']
  onClick: (text: string) => void
  getValueToShow: <K extends keyof TCustomSchedule>(
    keyToGet: K,
    index: number
  ) => TCustomSchedule[K]
}) => {
  return (
    <div className="flex flex-col md:flex-row md:items-start w-full">
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 w-72 shrink-0 whitespace-nowrap overflow-hidden text-ellipsis capitalize"
        data={getValueToShow('name', index)}
      />
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 w-36 shrink-0 whitespace-nowrap overflow-hidden text-ellipsis capitalize"
        data={getValueToShow('startDate', index)}
      />
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 w-36 shrink-0 whitespace-nowrap overflow-hidden text-ellipsis capitalize"
        data={`${getValueToShow('startTimeHours', index)}:${getValueToShow('startTimeMinutes', index)} ${getValueToShow('startTimeFormat', index)}`}
      />
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 w-36 shrink-0 whitespace-nowrap overflow-hidden text-ellipsis capitalize"
        data={getValueToShow('tag', index)}
      />
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 w-36 shrink-0 whitespace-nowrap overflow-hidden text-ellipsis capitalize"
        data={getValueToShow('location', index)}
      />
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 grow cursor-pointer text-ellipsis capitalize overflow-hidden whitespace-nowrap"
        onClick={() => onClick(note)}
        moreBtn={false}
        data={!note.length ? 'N/A' : note}
      />
      <div className="rounded-lg bg-SeabiscuitGrayThemeColor p-1 m-1 h-12 shrink-0 w-12 text-center flex items-center justify-center">
        <img className="m-auto" src="/assets/cp_icons/Ok-3.svg" alt="checkIcon" />
      </div>
    </div>
  )
}

type Props = {
  data: IEventReviewPublish['EventSchedule'] | null
}

const EventScheduleReviewPublishForm = (props: Props) => {
  // Vars
  const { data } = props

  const handleModalContext = useContext(HandleModalContext)

  const openAddNotesModal = (text: string) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NOTE, {
      noteInputRef: text,
    })
  }

  const getValueToShow = <K extends keyof TCustomSchedule>(
    keyToGet: K,
    index: number
  ): TCustomSchedule[K] => {
    let custom_ = data?.custom ?? []
    if (custom_?.[index]) {
      if (keyToGet === 'startDate') {
        const startDate = getConvertedData(custom_?.[index]).startDate
        return daysOfWeek[new Date(startDate).getDay()]
      }
      return !!custom_?.[index]?.[keyToGet] ? custom_?.[index]?.[keyToGet] : 'N/A'
    }
    return 'N/A'
  }

  return (
    <>
      {data && data.custom && data.custom.length ? (
        data.custom.map((custom, index) => {
          return (
            <DetailRow
              index={index}
              key={`${JSON.stringify(custom)}${index}`}
              note={custom.note}
              onClick={openAddNotesModal}
              getValueToShow={getValueToShow}
            />
          )
        })
      ) : (
        <DataNotAvailable
          mode="text"
          containerClassName="text-SeabiscuitDark200ThemeColor justify-center flex"
          text={MESSAGES_CONST.SCHEDULE_DATA_NOT_AVAILABLE}
        />
      )}
    </>
  )
}

export default EventScheduleReviewPublishForm
