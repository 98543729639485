import clsx from 'clsx'

type Props = {
  title?: string
  children?: any
  className?: string
  description?: string
}

const CompetitorEventRegisterWrapper = (props: Props) => {
  return (
    <>
      <div className="py-[20px] px-[15px] lg:px-[30px]">
        <div className="px-4 lg:px-0 headerSection">
          <div className="inline-flex items-center justify-end h-6">
            <h2 className="text-SeabiscuitMainThemeColor text-xl 2xl:text-2xl font-semibold mr-1">
              {props.title}
            </h2>
          </div>
          <p className="text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base">
            {props.description}
          </p>
        </div>
        <hr className="mt-4 h-1 w-full"></hr>
        <div
          className={clsx(
            'lg:max-h-[calc(100vh-420px)] min-h-[248px] overflow-y-auto lg:pr-3 mt-4 pb-5',
            // "min-h-[248px] overflow-y-auto pr-3 pt-[17px] pb-5",
            props?.className && props?.className
          )}
        >
          {props.children}
        </div>
      </div>
    </>
  )
}

export default CompetitorEventRegisterWrapper
