import React from 'react'
import { IconCheck } from '../../../../components/icons/IconCheck'

interface IProps {
  title: string
  src?: string
  isActive?: boolean
  onClick: () => void
}

export const RequiredFieldRow: React.FC<IProps> = ({ title, src, isActive, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`border cursor-pointer px-3 h-[55px] flex justify-between items-center rounded-lg ${isActive ? 'bg-[#F6F7FB] border-[#F6F7FB]' : 'border-[#D3DAEE]'} hover:bg-[#F6F7FB] hover:border-[#F6F7FB]`}
    >
      <div className={'flex items-center gap-3'}>
        {src && <img className={'w-[40px] h-[40px]'} src={src} alt={title} />}
        <span>{title}</span>
      </div>

      <div
        className={`border w-[20px] h-[20px] ${isActive ? 'border-[#00B6AA]' : 'border-[#D3DAEE]'} text-[#00B6AA] rounded-full flex items-center justify-center`}
      >
        {isActive && <IconCheck />}
      </div>
    </div>
  )
}
