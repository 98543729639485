import { IAccountDetails, ICompetitorProfileData, IProfileDetails } from './types'
import { selectObjToString } from '../../../models/interface.helper'

const getStructuredProfileData = (profileDetails: IProfileDetails): IProfileDetails => {
  return {
    id: profileDetails.id ?? '',
    userDOB: profileDetails.userDOB,
    userName: profileDetails.userName ?? '',
    userEmail: profileDetails.userEmail ?? '',
    userSubRoles: profileDetails.userSubRoles,
    userTeamMembers: profileDetails.userTeamMembers,
    userLastName: profileDetails.userLastName ?? '',
    userFirstName: profileDetails.userFirstName ?? '',
    userZone: selectObjToString(profileDetails.userZone) ?? '',
    userProfilePicture: profileDetails.userProfilePicture ?? '',
    userDiscipline: selectObjToString(profileDetails.userDiscipline) ?? '',
    userSocialSecurityNumber: profileDetails.userSocialSecurityNumber ?? '',
    userStripeAccountStatus: profileDetails?.userStripeAccountStatus ?? null,
    userEndorsementNumberLetter: profileDetails.userEndorsementNumberLetter ?? '',
    userNationality: selectObjToString(profileDetails.userNationality) ?? '',
    userAddress: profileDetails.userAddress,
    userPhoneNumber: profileDetails.userPhoneNumber,
    userAlternativeNumber: profileDetails.userAlternativeNumber,
    userCreated: profileDetails.userCreated,
    userModified: profileDetails.userModified,
    userNameNGram: profileDetails.userNameNGram,
    userHorses: profileDetails.userHorses,
  }
}

const getStructuredCompetitorProfileData = (
  competitorDetails: ICompetitorProfileData
): ICompetitorProfileData => {
  return {
    competitorOwnerId: competitorDetails?.competitorOwnerId ?? '',
    competitorJudgingLicense: selectObjToString(competitorDetails?.competitorJudgingLicense) ?? '',
  }
}

const getStructuredAccountDetails = (accountDetails: IAccountDetails): IAccountDetails => {
  return {
    userCards: accountDetails?.userCards ?? [],
    userAccountNumber: accountDetails?.userAccountNumber ?? '',
  }
}

const userSliceDataHelper = {
  getStructuredProfileData,
  getStructuredCompetitorProfileData,
  getStructuredAccountDetails,
}

export { userSliceDataHelper }
