// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import EventOrganizationMessagesControlComponent from '../../../components/events/controls/organization-messages/EventOrganizationMessagesControlComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const ShowsHomePageMessagesCorrespondenceSubSection: React.FC = () => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return <EventOrganizationMessagesControlComponent />
}

export default ShowsHomePageMessagesCorrespondenceSubSection
