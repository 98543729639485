import { useForm } from 'react-hook-form'

// Schemas
import { competitorProfileDetailSchema } from '../../../../validations'

// Third party
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { yupResolver } from '@hookform/resolvers/yup'
// import _ from "lodash"
// import { LocationOnOutlined } from "@mui/icons-material"

import { ICompetitorProfileDetailForm } from '../../../../types/competitor_types'
import { useEffect, useState } from 'react'

// Helpers
import { getValueFromSelectList } from '../../../../models/interface.helper'
import { CountryList } from '../../../../fakeData/countryList'
import { disciplineData } from '../../../../fakeData/disciplineList'
import { zoneList } from '../../../../fakeData/ZoneList'
import moment from 'moment'

type IShowFields = {
  profile: boolean | ReactJSXElement
  documents: boolean | ReactJSXElement
  profileData: boolean | ReactJSXElement
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants
const profileData = [
  'userDiscipline',
  'userZone',
  'competitorJudging_license',
  'userForeignEndorsement',
]
const usefFields = ['competitorFEI', 'competitorUSEF', 'competitorUSEA', 'competitorUSHJA']
const profileFields = [
  'userName',
  'userNationality',
  'userDOB',
  'userAddress',
  'userPhoneNumber',
  'userEmail',
]

// Types
type IGetFieldProps = { name: any; icon: ReactJSXElement | string; text_to_show?: string }

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const ProfileUserProfileDataTab = ({
  profileDetails,
  userDocumentData,
}: {
  profileDetails: any
  userDocumentData: any
}) => {
  // Hooks and vars
  let presentAndNotEmpty, typeOfUsefAndNotEmpty, userDOB
  let numbersArray = [
    'userUSDF',
    'userUSEA',
    'userUSHJA',
    'userAHHS',
    'userAMHA',
    'userARHPA',
    'userASHA',
    'userUPHA',
    'userWDAA',
  ]

  const { register, reset } = useForm<ICompetitorProfileDetailForm>({
    mode: 'onChange',
    resolver: yupResolver(competitorProfileDetailSchema),
  })
  const [showFields, setShowFields] = useState<IShowFields>({
    profile: false,
    profileData: false,
    documents: false,
  })
  useEffect(() => {
    if (profileDetails !== null) {
      try {
        reset(getResetFormValues())
        setShowFields(getFieldsToShow())
      } catch (err) {
        console.log(err)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileDetails, userDocumentData])

  // Functions

  // Return jsx or false if the field with the given name has not value
  const getField = ({ name, icon, text_to_show }: IGetFieldProps) => {
    let combinedValues = getResetFormValues()

    presentAndNotEmpty =
      combinedValues && combinedValues[`${name}`] && combinedValues[`${name}`] !== ''
    typeOfUsefAndNotEmpty =
      text_to_show &&
      combinedValues &&
      combinedValues[`${name}`] &&
      combinedValues[`${name}`] !== 0 &&
      combinedValues[`${name}`]?.number !== ''

    if (presentAndNotEmpty || typeOfUsefAndNotEmpty)
      return (
        <div className="relative flex mb-2 items-center border-solid border-[#D3DAEE] border overflow-hidden rounded-xl">
          {typeof icon === 'string' ? (
            <span className="mx-4 mr-6 absolute w-6 h-6">
              <img src={icon} alt="icon" className="object-cover w-full h-full" />
            </span>
          ) : (
            icon
          )}

          {text_to_show && <span className="pl-[40px] text-sm">{text_to_show}: </span>}

          <input
            type="text"
            disabled={true}
            readOnly={true}
            {...register(name)}
            className={`border border-transparent text-[14px] text-SeabiscuitDark200ThemeColor h-[56px] capitalize ${text_to_show ? 'pl-0' : 'pl-[62px]'} w-[100%]`}
          />
        </div>
      )
    else return false
  }

  function calculateAge(birthday: string) {
    // birthday is a date
    let currentTime = new Date().getTime()
    let birthDateTime = new Date(birthday).getTime()
    let difference = currentTime - birthDateTime
    var ageInYears = difference / (1000 * 60 * 60 * 24 * 365)
    return Math.floor(ageInYears)
  }
  // Returns combination of all values like userdoc, userdetails, useraccoutdetails, etc...
  const getResetFormValues = () => {
    let userZone = ''
    let userForeignEndorsement = profileDetails?.userForeignEndorsement
    let userDiscipline = ''
    let userNationality = ''
    let competitorDocUpdated = {}
    let competitorJudgingLicense = ''
    let competitorDocument = userDocumentData ? { ...userDocumentData } : {}

    userNationality =
      getValueFromSelectList({
        list: CountryList,
        valueToFind: profileDetails?.userNationality,
      })?.label ?? ''

    competitorJudgingLicense =
      getValueFromSelectList({
        list: CountryList,
        valueToFind: profileDetails?.competitorJudgingLicense,
      })?.label ?? ''

    userDiscipline =
      getValueFromSelectList({
        list: disciplineData,
        valueToFind: profileDetails?.userDiscipline,
      })?.label ?? ''

    userDiscipline =
      getValueFromSelectList({
        list: disciplineData,
        valueToFind: profileDetails?.userDiscipline,
      })?.label ?? ''

    userZone =
      getValueFromSelectList({
        list: zoneList,
        valueToFind: profileDetails?.userZone,
      })?.label ?? ''

    Object.keys(competitorDocument).forEach((curr) => {
      if (typeof userDocumentData[curr] === 'object')
        competitorDocUpdated = { ...competitorDocUpdated, [curr]: userDocumentData[curr]?.number }
    })

    if (profileDetails?.userDOB === '') userDOB = null
    else userDOB = `${calculateAge(profileDetails?.userDOB)} years old`

    return {
      ...profileDetails,
      ...competitorDocUpdated,
      userDOB,
      userZone: `Zone ${userZone}`,
      userDiscipline,
      userNationality,
      competitorJudgingLicense,
      email: profileDetails?.userEmail,
      userForeignEndorsement: `FE Letter${userForeignEndorsement?.validTill ? ' Expires: ' + moment(userForeignEndorsement?.validTill).format('MMMM, DD YYYY') : ': No Letter on File'}`,
    }
  }

  // Returns a object with the name of the cards to show or hide the card
  const getFieldsToShow = (): IShowFields => {
    return {
      profile: profileFields
        .map((curr) => {
          let value = getField({ name: curr, icon: '' })
          return value
        })
        .reduce((prevState, currValue) => {
          return prevState || currValue
        }),
      documents: usefFields
        .map((curr) => getField({ name: curr, text_to_show: 'some', icon: '' }))
        .reduce((prevState, currValue) => {
          return prevState || currValue
        }),
      profileData: profileData
        .map((curr) => getField({ name: curr, icon: '' }))
        .reduce((prevState, currValue) => {
          return prevState || currValue
        }),
    }
  }

  return (
    <>
      <div className="mb-5 text-SeabiscuitLightParagraphTextColor">
        {showFields.profile && (
          <>
            <div className="heading uppercase mb-3 font-md text-SeabiscuitDark200ThemeColor">
              PERSONAL DETAILS
            </div>

            {getField({
              name: 'userName',
              icon: 'assets/og_icons/User.svg',
            })}

            {/* {getField({
                            name: "userEmail",
                            icon: "assets/og_icons/Mail.svg"
                        })} */}

            {getField({
              name: 'userDOB',
              icon: 'assets/og_icons/Calendar.svg',
            })}

            {getField({
              name: 'userNationality',
              icon: 'assets/og_icons/Globe.svg',
            })}

            {/* {getField({
                            name: "userAddress",
                            icon: <LocationOnOutlined className="mx-2 absolute w-[30px]" fontSize="medium" />
                        })} */}

            {/* {getField({
                            name: "userPhoneNumber",
                            icon: <LocalPhoneOutlined className="mx-2 absolute w-[30px]" fontSize="medium" />
                        })} */}
          </>
        )}
      </div>

      <div className="mb-5 text-SeabiscuitLightParagraphTextColor">
        {showFields.profileData && (
          <>
            <div className="heading uppercase mb-3 font-md text-SeabiscuitDark200ThemeColor">
              COMPETITION DETAILS
            </div>
            {getField({
              name: 'userDiscipline',
              icon: '/assets/og_icons/YearofHorse.svg',
            })}
            {getField({
              name: 'userZone',
              icon: '/assets/img/light/Zone.svg',
            })}
            {getField({
              name: 'userAmateur',
              icon: '/assets/og_icons/ProfessionalStatus.svg',
            })}
            {getField({
              name: 'userForeignEndorsement',
              icon: '/assets/cp_icons/letter-1.svg',
            })}
            {/* {getField({
                            name: "competitorJudgingLicense",
                            icon: <FeaturedPlayListOutlined className="mx-2 absolute w-[30px]" fontSize="medium" />
                        })} */}
          </>
        )}
      </div>

      <div className="text-SeabiscuitLightParagraphTextColor">
        {
          <>
            {profileDetails.length > 0 && (
              <div className="heading uppercase mb-3 font-md text-SeabiscuitDark200ThemeColor">
                COMPETITION NUMBERS
              </div>
            )}

            {Object.keys(profileDetails).map((data, index) => {
              if (numbersArray.find((num) => num === data)) {
                return (
                  <div
                    key={`${JSON.stringify(data)}${index}`}
                    className="relative flex mb-2 items-center border-[#D3DAEE] border-solid border overflow-hidden rounded-xl"
                  >
                    {
                      <span className="p-[5px] w-[58px] h-[52px] ml-1 rounded-[60%]">
                        <img
                          src={`/assets/${data.replace('user', '').toLowerCase()}.svg`}
                          alt="icon"
                          className="object-cover"
                        />
                      </span>
                    }
                    {profileDetails[data].documentNumber !== '' ? (
                      <input
                        type="text"
                        disabled={true}
                        readOnly={true}
                        value={`${data.replace('user', '')} : ${profileDetails[data].documentNumber}`}
                        // {...register(name)}
                        className={`border border-transparent text-[14px] text-SeabiscuitDark200ThemeColor h-[56px] pl-3 w-[100%]`}
                      />
                    ) : (
                      <>
                        <span
                          className={`border border-transparent text-[14px] text-SeabiscuitDark200ThemeColor opacity-50 h-[56px] pl-3 w-[100%] flex items-center`}
                        >
                          No {data.replace('user', '')} number
                        </span>
                      </>
                    )}
                  </div>
                )
              }
              return null
            })}
            {Object.keys(profileDetails).map((detail, index) => {
              if (detail === 'userExtraDocument') {
                return profileDetails[detail].map((data: any) => {
                  return (
                    <div
                      key={`${JSON.stringify(data)}${index}`}
                      className="relative flex mb-2 items-center border-[#D3DAEE] border-solid border overflow-hidden rounded-xl"
                    >
                      {
                        <span className="p-[5px] w-[58px] h-[52px] ml-1 rounded-[60%]">
                          <img
                            src={`/assets/defaultcompetitionlogo.svg`}
                            alt="icon"
                            className="object-cover"
                          />
                        </span>
                      }
                      {data.documentNumber !== '' ? (
                        <input
                          type="text"
                          disabled={true}
                          readOnly={true}
                          value={` ${data.documentFullName} : ${data.documentNumber}`}
                          // {...register(name)}
                          className={`border border-transparent text-[14px] text-SeabiscuitDark200ThemeColor h-[56px] pl-3 w-[100%]`}
                        />
                      ) : (
                        <>
                          <span
                            className={`border border-transparent text-[14px] text-SeabiscuitDark200ThemeColor opacity-50 h-[56px] pl-3 w-[100%] flex items-center`}
                          >
                            No number
                          </span>
                        </>
                      )}
                    </div>
                  )
                })
              }
              return null
            })}
          </>
        }
      </div>
    </>
  )
}

export default ProfileUserProfileDataTab
