import { useContext } from 'react'
import { HandleModalContext } from '../../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../../const/modal-const'
import EventReviewPublishInput from './EventReviewPublishInput'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import DataNotAvailable from '../../../../common/alerts/data-not-available/DataNotAvailable'

const DetailRow = ({
  title,
  data,
  price,
  duration,
  onClick,
}: {
  title: any
  data?: any
  price?: any
  duration?: string
  onClick: (text: string) => void
}) => {
  const isGovernanceField = title?.toLowerCase() === 'governance'

  return (
    <div className="flex flex-col md:flex-row md:items-start w-full">
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-80 capitalize"
        data={title}
      />

      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 grow"
        data={data}
      />

      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6 text-center"
        data={price}
      />

      {!isGovernanceField ? (
        <EventReviewPublishInput
          className="rounded-lg text-SeabiscuitDark200ThemeColor bg-SeabiscuitGrayThemeColor p-3 m-1 w-1/6 whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer"
          data={duration}
          onClick={() => onClick(duration ?? '')}
        />
      ) : null}

      <div className="rounded-lg bg-SeabiscuitGrayThemeColor p-1 m-1 h-12 w-12 text-center flex items-center justify-center">
        <img className="m-auto" src="/assets/cp_icons/Ok-3.svg" alt="checkIcon" />
      </div>
    </div>
  )
}

type Props = {
  data?: any
  loading?: boolean
}

const EventFeesReviewPublishForm = (props: Props) => {
  const { data, loading } = props
  const handleModalContext = useContext(HandleModalContext)

  /**
   * @param text Text to show
   * @info Opens note modal
   */
  const openAddNotesModal = (text: string) => {
    handleModalContext?.handleModal(true, MODAL_CONSTS.ADD_NOTE, {
      noteInputRef: text,
    })
  }

  if (loading) return null

  return (
    <>
      {data ? (
        <>
          {data?.beforeClosingDateRefund?.status ? (
            <DetailRow
              title="Refund"
              onClick={openAddNotesModal}
              data="Before closing date"
              price={`${data.beforeClosingDateRefund?.percentageAlias}`}
              duration={
                data?.beforeClosingDateRefund?.note && data?.beforeClosingDateRefund?.note !== ''
                  ? data?.beforeClosingDateRefund?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data?.afterClosingDateRefund?.status ? (
            <DetailRow
              title="Refund"
              onClick={openAddNotesModal}
              data="After closing date"
              price={`${data.afterClosingDateRefund?.percentageAlias}`}
              duration={
                data?.afterClosingDateRefund?.note && data?.afterClosingDateRefund?.note !== ''
                  ? data?.afterClosingDateRefund?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data?.showCancellationRefundWeather?.status ? (
            <DetailRow
              title="Refund"
              onClick={openAddNotesModal}
              data="Show cancelled - weather"
              price={`${data.showCancellationRefundWeather?.percentageAlias}`}
              duration={
                data?.showCancellationRefundWeather?.note &&
                data?.showCancellationRefundWeather?.note !== ''
                  ? data?.showCancellationRefundWeather?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data?.showCancellationRefundBioSecurity?.status ? (
            <DetailRow
              title="Refund"
              onClick={openAddNotesModal}
              data="Show cancelled - biosecurity"
              price={`${data.showCancellationRefundBioSecurity?.percentageAlias}`}
              duration={
                data?.showCancellationRefundBioSecurity?.note &&
                data?.showCancellationRefundBioSecurity?.note !== ''
                  ? data?.showCancellationRefundBioSecurity?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data.temporaryStallsFee?.costAlias &&
          data.temporaryStallsFee?.costAlias !== '' &&
          data.temporaryStallsFee?.status ? (
            <DetailRow
              title="Stalls"
              onClick={openAddNotesModal}
              data="Temporary stall"
              price={`${data.temporaryStallsFee?.costAlias}`}
              duration={
                data?.temporaryStallsFee?.note && data?.temporaryStallsFee?.note !== ''
                  ? data?.temporaryStallsFee?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data.permanentStallsFee?.costAlias &&
          data.permanentStallsFee?.costAlias !== '' &&
          data.permanentStallsFee?.status ? (
            <DetailRow
              title="Stalls"
              onClick={openAddNotesModal}
              data="Permanent stall"
              price={`${data.permanentStallsFee?.costAlias}`}
              duration={
                data?.permanentStallsFee?.note && data?.permanentStallsFee?.note !== ''
                  ? data?.permanentStallsFee?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data.privateStallsFee?.costAlias &&
          data.privateStallsFee?.costAlias !== '' &&
          data.privateStallsFee?.status ? (
            <DetailRow
              title="Stalls"
              onClick={openAddNotesModal}
              data="Private stall"
              price={`${data.privateStallsFee?.costAlias}`}
              duration={
                data?.privateStallsFee?.note && data?.privateStallsFee?.note !== ''
                  ? data?.privateStallsFee?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data.circuitStallsFee?.costAlias &&
          data.circuitStallsFee?.costAlias !== '' &&
          data.circuitStallsFee?.status ? (
            <DetailRow
              title="Stalls"
              onClick={openAddNotesModal}
              data="Circuit stall"
              price={`${data.circuitStallsFee?.costAlias}`}
              duration={
                data?.circuitStallsFee?.note && data?.circuitStallsFee?.note !== ''
                  ? data?.circuitStallsFee?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data.muckFee?.costAlias && data.muckFee?.costAlias !== '' && data.muckFee?.status ? (
            <DetailRow
              title="Stalls"
              onClick={openAddNotesModal}
              data="Muck fee"
              price={`${data.muckFee?.costAlias}`}
              duration={
                data?.muckFee?.note && data?.muckFee?.note !== '' ? data?.muckFee?.note : 'Notes'
              }
            />
          ) : null}

          {data.shavingFees?.costAlias &&
          data.shavingFees?.costAlias !== '' &&
          data.shavingFees?.status ? (
            <DetailRow
              title={data.shavingFees?.category}
              onClick={openAddNotesModal}
              data={data.shavingFees?.title}
              price={`${data.shavingFees?.costAlias}`}
              duration={
                data?.shavingFees?.note && data?.shavingFees?.note !== ''
                  ? data?.shavingFees?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data.strawFees?.costAlias &&
          data.strawFees?.costAlias !== '' &&
          data.strawFees?.status ? (
            <DetailRow
              title={data.strawFees?.category}
              onClick={openAddNotesModal}
              data={data.strawFees?.title}
              price={`${data.strawFees?.costAlias}`}
              duration={
                data?.strawFees?.note && data?.strawFees?.note !== ''
                  ? data?.strawFees?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data.extraBeddingFees && data.extraBeddingFees.length
            ? data.extraBeddingFees.map((item: any, index: number) => {
                if (!item?.status) return null

                return (
                  <DetailRow
                    key={`extraBeddingFees${index}`}
                    title={item.category}
                    onClick={openAddNotesModal}
                    data={item.title}
                    price={`${item.costAlias}`}
                    duration={item.note && item.note !== '' ? item.note : 'Notes'}
                  />
                )
              })
            : null}

          {data.coastalHay?.costAlias &&
          data.coastalHay?.costAlias !== '' &&
          data.coastalHay?.status ? (
            <DetailRow
              title={data.coastalHay?.category}
              onClick={openAddNotesModal}
              data={data.coastalHay?.title}
              price={`${data.coastalHay?.costAlias}`}
              duration={
                data?.coastalHay?.note && data?.coastalHay?.note !== ''
                  ? data?.coastalHay?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data.timothyHay?.costAlias &&
          data.timothyHay?.costAlias !== '' &&
          data.timothyHay?.status ? (
            <DetailRow
              title={data.timothyHay?.category}
              onClick={openAddNotesModal}
              data={data.timothyHay?.title}
              price={`${data.timothyHay?.costAlias}`}
              duration={
                data?.timothyHay?.note && data?.timothyHay?.note !== ''
                  ? data?.timothyHay?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data.alfalfa?.costAlias && data.alfalfa?.costAlias !== '' && data.alfalfa?.status ? (
            <DetailRow
              title={data.alfalfa?.category}
              onClick={openAddNotesModal}
              data={data.alfalfa?.title}
              price={`${data.alfalfa?.costAlias}`}
              duration={
                data?.alfalfa?.note && data?.alfalfa?.note !== '' ? data?.alfalfa?.note : 'Notes'
              }
            />
          ) : null}

          {data.TnA?.costAlias && data.TnA?.costAlias !== '' && data.TnA?.status ? (
            <DetailRow
              title={data.TnA?.category}
              onClick={openAddNotesModal}
              data={data.TnA?.title}
              price={`${data.TnA?.costAlias}`}
              duration={data?.TnA?.note && data?.TnA?.note !== '' ? data?.TnA?.note : 'Notes'}
            />
          ) : null}

          {data.extraFeedFees && data.extraFeedFees.length
            ? data.extraFeedFees.map((item: any) => {
                if (!item?.status) return null

                return (
                  <DetailRow
                    key={JSON.stringify(item)}
                    title={item.category}
                    onClick={openAddNotesModal}
                    data={item.title}
                    price={`${item.costAlias}`}
                    duration={item.note && item.note !== '' ? item.note : 'Notes'}
                  />
                )
              })
            : null}

          {data.paddockFee?.costAlias &&
          data.paddockFee?.costAlias !== '' &&
          data.paddockFee?.status ? (
            <DetailRow
              title="Other"
              onClick={openAddNotesModal}
              data="Paddock fees"
              price={`${data.paddockFee?.costAlias}`}
              duration={
                data?.paddockFee?.note && data?.paddockFee?.note !== ''
                  ? data?.paddockFee?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data.RVHookupFee?.costAlias &&
          data.RVHookupFee?.costAlias !== '' &&
          data.RVHookupFee?.status ? (
            <DetailRow
              title="Other"
              onClick={openAddNotesModal}
              data="RV Hookup"
              price={`${data.RVHookupFee?.costAlias}`}
              duration={
                data?.RVHookupFee?.note && data?.RVHookupFee?.note !== ''
                  ? data?.RVHookupFee?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data.USEFFee?.costAlias && data.USEFFee?.costAlias !== '' && data.USEFFee?.status ? (
            <DetailRow
              title="Mandatory"
              onClick={openAddNotesModal}
              data="Office fee"
              price={`${data.USEFFee?.costAlias}`}
              duration={
                data?.USEFFee?.note && data?.USEFFee?.note !== '' ? data?.USEFFee?.note : 'Notes'
              }
            />
          ) : null}

          {data.USEFDrugFee?.costAlias &&
          data.USEFDrugFee?.costAlias !== '' &&
          data.USEFDrugFee?.status ? (
            <DetailRow
              title="Mandatory"
              onClick={openAddNotesModal}
              data="Ship in / Ground fee"
              price={`${data.USEFDrugFee?.costAlias}`}
              duration={
                data?.USEFDrugFee?.note && data?.USEFDrugFee?.note !== ''
                  ? data?.USEFDrugFee?.note
                  : 'Notes'
              }
            />
          ) : null}

          {data.USEFShowPassFee?.costAlias &&
          data.USEFShowPassFee?.costAlias !== '' &&
          data.USEFShowPassFee?.status ? (
            <DetailRow
              title="Mandatory"
              onClick={openAddNotesModal}
              data="Ambulance fee"
              price={`${data.USEFShowPassFee?.costAlias}`}
              duration={
                data?.USEFShowPassFee?.note && data?.USEFShowPassFee?.note !== ''
                  ? data?.USEFShowPassFee?.note
                  : 'Notes'
              }
            />
          ) : null}

          {/*{data.USHJAHoursFee?.costAlias &&*/}
          {/*data.USHJAHoursFee?.costAlias !== '' &&*/}
          {/*data.USHJAHoursFee?.status ? (*/}
          {/*  <DetailRow*/}
          {/*    title="Mandatory"*/}
          {/*    onClick={openAddNotesModal}*/}
          {/*    data="USHJA Horse Fee"*/}
          {/*    price={`${data.USHJAHoursFee?.costAlias}`}*/}
          {/*    duration={*/}
          {/*      data?.USHJAHoursFee?.note && data?.USHJAHoursFee?.note !== ''*/}
          {/*        ? data?.USHJAHoursFee?.note*/}
          {/*        : 'Notes'*/}
          {/*    }*/}
          {/*  />*/}
          {/*) : null}*/}

          {/*{data.USHJAShowPassFee?.costAlias &&*/}
          {/*data.USHJAShowPassFee?.costAlias !== '' &&*/}
          {/*data.USHJAShowPassFee?.status ? (*/}
          {/*  <DetailRow*/}
          {/*    title="Mandatory"*/}
          {/*    onClick={openAddNotesModal}*/}
          {/*    data="USHJA Show Pass Fee"*/}
          {/*    price={`${data.USHJAShowPassFee?.costAlias}`}*/}
          {/*    duration={*/}
          {/*      data?.USHJAShowPassFee?.note && data?.USHJAShowPassFee?.note !== ''*/}
          {/*        ? data?.USHJAShowPassFee?.note*/}
          {/*        : 'Notes'*/}
          {/*    }*/}
          {/*  />*/}
          {/*) : null}*/}

          {data.USDFFee?.costAlias && data.USDFFee?.costAlias !== '' && data.USDFFee?.status ? (
            <DetailRow
              title="Mandatory"
              onClick={openAddNotesModal}
              data="Night Watch"
              price={`${data.USDFFee?.costAlias}`}
              duration={
                data?.USDFFee?.note && data?.USDFFee?.note !== '' ? data?.USDFFee?.note : 'Notes'
              }
            />
          ) : null}

          {/*{data.USDFShowPassFee?.costAlias &&*/}
          {/*data.USDFShowPassFee?.costAlias !== '' &&*/}
          {/*data.USDFShowPassFee?.status ? (*/}
          {/*  <DetailRow*/}
          {/*    title="Mandatory"*/}
          {/*    onClick={openAddNotesModal}*/}
          {/*    data="USDF Show Pass Fee"*/}
          {/*    price={`${data.USDFShowPassFee?.costAlias}`}*/}
          {/*    duration={*/}
          {/*      data?.USDFShowPassFee?.note && data?.USDFShowPassFee?.note !== ''*/}
          {/*        ? data?.USDFShowPassFee?.note*/}
          {/*        : 'Notes'*/}
          {/*    }*/}
          {/*  />*/}
          {/*) : null}*/}

          {/*{data.USEAFee?.costAlias && data.USEAFee?.costAlias !== '' && data.USEAFee?.status ? (*/}
          {/*  <DetailRow*/}
          {/*    title="Mandatory"*/}
          {/*    onClick={openAddNotesModal}*/}
          {/*    data="USEA Fee"*/}
          {/*    price={`${data.USEAFee?.costAlias}`}*/}
          {/*    duration={*/}
          {/*      data?.USEAFee?.note && data?.USEAFee?.note !== '' ? data?.USEAFee?.note : 'Notes'*/}
          {/*    }*/}
          {/*  />*/}
          {/*) : null}*/}

          {/*{data.USEAShowPassFee?.costAlias &&*/}
          {/*data.USEAShowPassFee?.costAlias !== '' &&*/}
          {/*data.USEAShowPassFee?.status ? (*/}
          {/*  <DetailRow*/}
          {/*    title="Mandatory"*/}
          {/*    onClick={openAddNotesModal}*/}
          {/*    data="USEA Show Pass Fee"*/}
          {/*    price={`${data.USEAShowPassFee?.costAlias}`}*/}
          {/*    duration={*/}
          {/*      data?.USEAShowPassFee?.note && data?.USEAShowPassFee?.note !== ''*/}
          {/*        ? data?.USEAShowPassFee?.note*/}
          {/*        : 'Notes'*/}
          {/*    }*/}
          {/*  />*/}
          {/*) : null}*/}

          {data.extraStallFees && data.extraStallFees.length
            ? data.extraStallFees.map((current: any, index: number) => {
                if (!current?.status) return null

                return (
                  <DetailRow
                    key={`extraStallFees${index}`}
                    title="Stalls"
                    onClick={openAddNotesModal}
                    data="Extra Stalls fees"
                    price={`${current?.costAlias}`}
                    duration={
                      current?.costAlias.note && current?.costAlias.note !== ''
                        ? current?.costAlias.note
                        : 'Notes'
                    }
                  />
                )
              })
            : null}
        </>
      ) : (
        <DataNotAvailable
          mode="text"
          containerClassName="text-SeabiscuitDark200ThemeColor justify-center flex"
          text={MESSAGES_CONST.FEES_DATA_NOT_AVAILABLE}
        />
      )}
    </>
  )
}

export default EventFeesReviewPublishForm
