import FirestoreService from './firestoreService'
import { CONST } from '../const/const'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const getEventWaitlist = async (data: any) => {
  return FirestoreService.getItem(
    CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_WAITLIST.NAME,
    data.id
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const createEventWaitlist = async (document: any) => {
  return await FirestoreService.createItem(
    CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_WAITLIST.NAME,
    document
  )
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const updateEventWaitlist = async (id: string, document: any) => {
  return await FirestoreService.updateItem(
    CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.EVENT_WAITLIST.NAME,
    id,
    document
  )
}

const EventWaitlistService = {
  getEventWaitlist,
  createEventWaitlist,
  updateEventWaitlist,
}

export default EventWaitlistService
