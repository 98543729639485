import { useContext, useEffect, useState } from 'react'

import clsx from 'clsx'
import { useParams } from 'react-router'
import { CONST } from '../../../../../../../const/const'
import ManageClinicTableWrapper from '../../../ManageClinicTableWrapper'
import StaffAllList from './all/StaffAllList'
import StaffComplaintList from './compliant/StaffComplaintList'
import StaffNonComplaintList from './nonCompliant/StaffNonComplaintList'
import StaffScratchedList from './scratched/StaffScratchedList'
import { MANAGE_CLINIC_EXHIBITOR_CONSTS } from '../data/exhibitor.data.const'
import { IExhibitorDataTypes } from '../data/exhibitor.data.types'
import useStaffListingRoot from '../hooks/useStaffListingRoot'
import { MODAL_CONSTS } from '../../../../../../../const/modal-const'
import { HandleModalContext } from '../../../../../../../layout/mainlayout/MainLayout'

import { IStaff } from '../hooks/useStaffListingRoot.types'
import UserService from '../../../../../../../services/userService'
import { UserModel } from '../../../../../../../models/users/user.model'
import { Tooltip } from '@mui/material'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants

const MCEC = MANAGE_CLINIC_EXHIBITOR_CONSTS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const StaffListing = (props: IExhibitorDataTypes['IExhitiborClinicExhibitorsListProps']) => {
  // Hooks and vars
  const { className, isFullScreen = false } = props
  const { eventId } = useParams<{ eventId?: string }>()
  const [Emails, setEmails] = useState<string[]>([])
  const handleModalContext = useContext(HandleModalContext)
  const { loading, getEventStaffDoc, onStaffProfileClick, ...categorizedStaffs } =
    useStaffListingRoot(eventId as any)

  const [searching, setSearching] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchOpen, setSearchOpen] = useState(false)
  const [activeTab, setActiveTab] = useState<string>(MCEC.TABS[0].id)
  const [imgSrc, setImgSrc] = useState('/assets/og_icons/Mail.svg')

  if (0) console.log(searching, searchOpen)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!eventId) return

    getEventStaffDoc(eventId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId])

  const handleMailSendAll = async (tempData: IStaff[]) => {
    let emails: string[] = []
    let staffids: string[] = []
    if (tempData.length) {
      tempData.forEach((data) => {
        if (data.staffId) {
          staffids.push(data.staffId)
        }
      })
    }

    if (staffids.length) {
      await asyncWhileLoop({
        loopCount: staffids?.length,
        functionToFire: async (index: any) => {
          const currId = staffids[index]
          if (currId) {
            const user_doc = await UserService.getUserInfoById(currId)

            if (user_doc.exists()) {
              const userData = UserModel.fromFirestoreDoc(user_doc).toObject()
              emails.push(userData.userEmail)
            }
          }
        },
      })
    }
    setEmails(emails)
  }

  useEffect(() => {
    let tempData: IStaff[] = []
    if (categorizedStaffs) {
      switch (activeTab) {
        case '1':
          tempData = categorizedStaffs.staffs
          break
        case '2':
          tempData = categorizedStaffs.complaintStaffs
          break

        case '3':
          tempData = categorizedStaffs.nonComplaintStaffs
          break

        case '4':
          tempData = categorizedStaffs.scratchedStaffs
          break
      }
    }

    if (tempData.length) {
      handleMailSendAll(tempData)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorizedStaffs.staffs, categorizedStaffs.complaintStaffs])
  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Handles data table tabs,
   */
  const handleActiveTab = (item: { tab: string; id: string; disabled?: boolean }) => {
    setActiveTab(item.id)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getActiveDataTable = () => {
    switch (activeTab) {
      case MCEC.TABS[0].id:
        return (
          <StaffAllList
            {...props}
            loading={loading}
            searchValue={searchValue}
            onStaffProfileClick={onStaffProfileClick}
            staffs={categorizedStaffs.staffs.filter((c) =>
              c.staffName?.toLowerCase()?.startsWith(searchValue?.toLowerCase())
            )}
          />
        )

      case MCEC.TABS[3].id:
        return (
          <StaffScratchedList
            {...props}
            loading={loading}
            searchValue={searchValue}
            onStaffProfileClick={onStaffProfileClick}
            staffs={categorizedStaffs.scratchedStaffs.filter((c) =>
              c.staffName?.toLowerCase()?.startsWith(searchValue?.toLowerCase())
            )}
          />
        )

      case MCEC.TABS[1].id:
        return (
          <StaffComplaintList
            {...props}
            loading={loading}
            searchValue={searchValue}
            onStaffProfileClick={onStaffProfileClick}
            staffs={categorizedStaffs.complaintStaffs.filter((c) =>
              c.staffName?.toLowerCase()?.startsWith(searchValue?.toLowerCase())
            )}
          />
        )

      case MCEC.TABS[2].id:
        return (
          <StaffNonComplaintList
            {...props}
            loading={loading}
            searchValue={searchValue}
            onStaffProfileClick={onStaffProfileClick}
            staffs={categorizedStaffs.nonComplaintStaffs.filter((c) =>
              c.staffName?.toLowerCase()?.startsWith(searchValue?.toLowerCase())
            )}
          />
        )
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Filters the list of users
   */
  const searchval = (e: any) => {
    setSearching(true)
    const eventKey = e.target.value
    setSearchValue(eventKey)
    if (eventKey === '') {
      setSearching(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Opens the search option
   */
  const searchForm = () => {
    setSearchOpen(true)
    setSearching(true)
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @info Closes the search option
   */
  const removeSearchForm = () => {
    setSearchOpen(false)
    setSearching(false)
  }

  return (
    <div className="p-5 rounded-xl bg-SeabiscuitWhiteThemeColor w-[100%] min-h-[40vh]">
      <div className={clsx('flex items-center justify-between', isFullScreen ? 'mb-5' : 'mb-6')}>
        <div className="flex items-center mb-0 mt-2">
          {MCEC.TABS?.map((currentItem, index) => {
            return (
              <div
                key={`${JSON.stringify(currentItem)}a${index}`}
                onClick={() => handleActiveTab(currentItem)}
                className={`py-3 px-3 min-w-[110px] h-[50px] text-[15px] text-center pt-3.5 relative cursor-pointer mr-2 rounded-xl ease-in duration-200 ${activeTab === currentItem.id ? 'bg-[#F7074F0D] text-SeabiscuitMainThemeColor' : 'text-SeabiscuitDark200ThemeColor'}`}
              >
                {currentItem.tab}
              </div>
            )
          })}
        </div>

        {isFullScreen ? (
          <div className="flex  ">
            <div className="search-div-wrapper border relative rounded-xl w-96 border-[#D3DAEE] mb-2">
              <div className="relative rounded-full">
                <div className="absolute inset-y-0 left-0 flex z-50 items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-SeabiscuitDark200ThemeColor"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </div>

                <input
                  type="search"
                  id="default-search"
                  className="block w-full p-2 pl-10 text-sm border-0 text-gray-900 z-40 rounded-full focus:ring-0 focus:outline-0 focus:rounded-full relative placeholder:text-SeabiscuitDark200ThemeColor"
                  placeholder="Search staff..."
                  onChange={searchval}
                  onFocus={searchForm}
                  onBlur={removeSearchForm}
                />
              </div>
            </div>
            <Tooltip title={<h1 className="tooltip_title">{`Email All`}</h1>} placement="top" arrow>
              <button
                className="bg-[#F2F5F6] text-SeabiscuitDark200ThemeColor hover:bg-[#F7074F0D] hover:text-SeabiscuitMainThemeColor rounded-lg shadow-sm p-2 ml-3 !h-[40px]"
                onClick={() => {
                  handleModalContext?.handleModal(true, MODAL_CONSTS.SEND_MESSAGE, {
                    emailId: Emails.toString(),
                  })
                }}
                onMouseEnter={() => setImgSrc('/assets/og_icons/Mail-2.svg')}
                onMouseLeave={() => setImgSrc('/assets/og_icons/Mail-1.svg')}
              >
                <img src={imgSrc} className="" alt="edit" />
              </button>
            </Tooltip>
          </div>
        ) : null}
      </div>
      <div className="w-full">{getActiveDataTable()}</div>
    </div>
  )
}

export default StaffListing

type IAsyncWhileLoopArgs = {
  loopCount: number
  functionToFire: (index: number) => Promise<any>
}

type IAsyncWhileLoopFn = (args: IAsyncWhileLoopArgs) => Promise<void>

export const asyncWhileLoop: IAsyncWhileLoopFn = async (args) => {
  let currLoopIndex = 0

  let { loopCount, functionToFire } = args

  while (currLoopIndex < loopCount) {
    await functionToFire(currLoopIndex)
    currLoopIndex++
  }
}
