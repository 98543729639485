import HorseCard from '../horse-card/HorseCard'
import UserCard from '../user-card/UserCard'
import IHorseProfileTypes from './horseProfile.types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
export default function HorseProfile(props: IHorseProfileTypes['IHorseProfileProps']) {
  // Hooks and vars
  const {
    horseId,
    horseName,
    horseZone,
    mappingId,
    horseTeams,
    horseIndex,
    horseDiscipline,
    onProfilePicClick,
    onRemoveHorseClick,
    horseProfilePicture,
  } = props

  const horseCardProps = {
    horseId,
    horseName,
    horseZone,
    horseTeams,
    horseIndex,
    horseDiscipline,
    onProfilePicClick,
    onRemoveHorseClick,
    horseProfilePicture,
    mappingDocId: mappingId,
    isMyHorse: props.isMyHorse,
  }

  return (
    <div className="flex flex-wrap items-center mb-3 md:mb-0">
      <div className="hourseList flex flex-col md:flex-row items-center md:mr-2">
        <div className="flex items-center mb-3 md:mb-0 w-full md:w-60">
          <div className="flex items-center">
            <img className="mr-2" src="/assets/og_icons/YearofHorse-1.svg" alt="hourseIcon" />
            <p className="text-SeabiscuitDark200ThemeColor">Horse</p>
          </div>
        </div>

        <HorseCard {...horseCardProps} />

        <div className="flex flex-col">
          {horseTeams && horseTeams.length
            ? horseTeams.map((currentRider, index) => {
                return (
                  <UserCard
                    memberIndex={index}
                    currentRider={currentRider}
                    className={index > 0 ? 'mt-2' : ''}
                    onProfilePicClick={onProfilePicClick}
                    key={`${JSON.stringify(currentRider)}${index}`}
                    onRemoveMemberClick={props.onRemoveMemberClick}
                  />
                )
              })
            : null}
        </div>

        {/* Horse User Card */}
      </div>
    </div>
  )
}
