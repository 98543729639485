// Consts
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../store/hooks'
import { selectProfileData, selectUserId } from '../../../store/user/userSlice'
import EventWaitlistService from '../../../services/eventWaitlistService'
import { MODAL_CONSTS } from '../../../const/modal-const'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../../const/messages-const'
import FirestoreService from '../../../services/firestoreService'
import { CONST } from '../../../const/const'
import { where } from 'firebase/firestore'
import { Clear } from '@mui/icons-material'
import helpers from '../../../commonHelpers/helpers'
import customImageComponent from '../../common/CustomImageComponent'

const CHECKED_ICON = 'assets/cp_icons/Ok-3.svg'

type Props = {
  show: boolean
  handleModal: any
  dataToPassOn: any
}

type TTeamMember = {
  memberId: string
  memberName: string
  memberEmail: string
  memberAddress: string
  memberProfilePicture: string
  memberProfileSynced: boolean
  memberShipActive: boolean
  memberSafeSupportTraining: boolean
  memberStatus: string
  defaultRole: string
  authorized: string
  memberCountry: string
  selected?: boolean
}

const RegisterForCompetitionModal = ({ show, handleModal, dataToPassOn }: Props) => {
  const [userList, setUserList] = useState<any>([])

  const existOrNot = async () => {
    try {
      let data: any[] = []
      const result = await FirestoreService.filterItems(
        CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_WAITLIST.NAME,
        [where('eventId', '==', (dataToPassOn as any)?.id), where('userId', '==', userId)]
      )
      if (result.size) {
        result?.forEach((doc: any) => data.push(doc))
        return data
      }

      return data
    } catch (err) {
      throw err
    }
  }

  const getChange = async (arr: any[]) => {
    let data = arr.map((item: any) => {
      return {
        ...item,
        selected: false,
      }
    })
    // let allAddedWaitlist = (await existOrNot()).map((item) => item.data())
    // dont uncommet

    // data = data.reduce((acc: any, current: any) => {
    //     // eslint-disable-next-line array-callback-return
    //     allAddedWaitlist.map((waitListUser: any) => {
    //         // eslint-disable-next-line array-callback-return
    //         waitListUser.waitlist.map((waitlistMember: any) => {
    //             if (current.memberId !== waitlistMember.memberId) {
    //                 return acc.push(current)
    //             }
    //         })
    //     })
    //     return acc
    // }, [])

    setUserList(data)
  }

  const { userTeamMembers } = useAppSelector(selectProfileData)

  useEffect(() => {
    getChange(userTeamMembers ?? [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTeamMembers])

  const toastFunction = useToasterHelper()
  const userId = useAppSelector(selectUserId)
  const [step, setStep] = useState<number>(1)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, teamMember: TTeamMember) => {
    const select = e.target.checked
    if (userList) {
      let addedWaitListMembersArrCopy = [...userList]
      addedWaitListMembersArrCopy = addedWaitListMembersArrCopy.map((currUser) => {
        currUser = {
          ...currUser,
          selected: false,
        }
        if (currUser.selected) currUser.selected = false
        if (currUser.memberId === teamMember?.memberId) currUser.selected = select
        return currUser
      })
      setUserList([...addedWaitListMembersArrCopy])
    }
  }

  const handleAddMemberWaitlist = async () => {
    try {
      const data = {
        eventId: (dataToPassOn as any)?.id,
        id: '',
        userId,
        owner: dataToPassOn.owner,
        waitlist: userList.filter((item: any) => {
          return item.selected
        }),
      }

      if (!data.waitlist || !data.waitlist.length) {
        return toastFunction.error({ message: MESSAGES_CONST.ERROR_MEMBER_ADD_TO_WAITLIST })
      }

      const exist = await existOrNot()

      if (!exist.length) {
        await EventWaitlistService.createEventWaitlist(data)
      } else {
        await EventWaitlistService.updateEventWaitlist(exist[0].id, data)
      }
      setStep(step + 1)
      toastFunction.success({ message: MESSAGES_CONST.MEMBER_ADDED_TO_WAITLIST })
      handleModal(true, MODAL_CONSTS.REGISTER_FOR_COMPETETION, data.waitlist.length)
    } catch (err: any) {
      helpers.logger({
        isError: true,
        message: err,
      })
    }
  }

  // Hooks and v
  return (
    <div
      className={`modal fade fixed z-50 w-full top-15 h-screen outline-none flex items-center justify-center overflow-x-hidden overflow-y-auto ${show ? 'show d-block backShadow' : 'hidden'}`}
      id="exampleModalCenter01"
      aria-labelledby="exampleModalCenterTitle"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered relative lg:w-3/5 xl:w-1/3 m-auto pointer-events-none">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current pt-10 pb-7 px-8">
          <span
            onClick={() => handleModal(false, MODAL_CONSTS.REGISTER_FOR_COMPETETION)}
            className="absolute text right-2 top-2 text-SeabiscuitDark200ThemeColor cursor-pointer"
          >
            {/* <img
                            src="/assets/og_icons/Close.svg"
                            className="w-6" 
                            alt="close icon changed"
                        /> */}
            <Clear
              fontSize={'small'}
              style={{
                color: 'grey',
                fontWeight: '400',
              }}
            />
          </span>

          {step === 1 && (
            <>
              <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
                <h5
                  className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2"
                  id="exampleModalScrollableLabel"
                >
                  Event full
                </h5>
              </div>
              <div className="detailModal">
                <p className="text-SeabiscuitDark200ThemeColor text-sm py-4">
                  This event has reached its registration limit.
                </p>
                <p className="text-SeabiscuitDark200ThemeColor text-sm pb-4">
                  Those that join the waitlist will be added to the event as registered exhibitors
                  scratch (in order of registration).
                </p>

                <div className="registeredUser border border-SeabiscuitGray500ThemeColor rounded-md p-1 flex items-center">
                  <span className="text-SeabiscuitMainThemeColor p-2 w-12 text-center bg-[#F7074F1A] rounded-md">
                    {dataToPassOn.registeredCountIncludingUnpaid}
                  </span>
                  <span className="ml-2 text-SeabiscuitDark200ThemeColor">registered</span>
                </div>

                {dataToPassOn.waitlist ? (
                  <div className="registeredUser border border-SeabiscuitGray500ThemeColor rounded-md p-1 flex items-center mt-2">
                    <span className="text-SeabiscuitMainThemeColor p-2 w-12 text-center bg-[#F7074F1A] rounded-md">
                      {dataToPassOn.waitlistCount}
                    </span>
                    <span className="ml-2 text-SeabiscuitDark200ThemeColor">on the waitlist</span>
                  </div>
                ) : null}

                {dataToPassOn.waitlist ? (
                  <button
                    type="button"
                    onClick={() => setStep(step + 1)}
                    className="w-full h-12 mt-24 mx-auto py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:text-SeabiscuitMainThemeColor hover:bg-SeabiscuitLightThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
                  >
                    JOIN WAITLIST
                  </button>
                ) : null}
              </div>
            </>
          )}

          {step === 2 && (
            <>
              <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
                <h5
                  className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2"
                  id="exampleModalScrollableLabel2"
                >
                  Add team members to waitlist
                </h5>
              </div>

              <div className="uselListWarp mt-4 flex flex-col min-h-[250px] overflow-y-auto">
                <div className="form-check mb-3">
                  {userList && userList.length ? (
                    userList.map((item: any, index: number) => {
                      return (
                        <label
                          key={index}
                          className="form-check mb-3 cursor-pointer"
                          htmlFor={'teammember' + index}
                        >
                          <div className="user flex items-center rounded-lg py-1 w-full justify-between">
                            <div className="min-w-fit flex items-center">
                              {customImageComponent(
                                item.memberProfilePicture,
                                item.memberName,
                                '!w-[40px] !h-[40px] rounded-full mr-2 avatarImg'
                              )}
                              <div className="hourseDetails w-10/12">
                                <div className="hourseTitle text-SeabiscuitDark200ThemeColor">
                                  <p className="text-SeabiscuitDark200ThemeColor text-lg">
                                    {item?.memberName}
                                  </p>
                                  <p className="text-xs">
                                    {item?.defaultRole}, @{item?.userName}
                                  </p>
                                </div>
                              </div>
                            </div>

                            {item?.selected ? (
                              <img
                                src={CHECKED_ICON}
                                className="relative right-[0px] top-[0px]"
                                width={20}
                                height={20}
                                alt="checked"
                              />
                            ) : null}
                            <input
                              onChange={(e) => {
                                handleChange(e, item)
                              }}
                              checked={item?.selected}
                              id={'teammember' + index}
                              className={`mr-[2px] form-check-input appearance-none h-4 w-4 border active:bg-SeabiscuitMainThemeColor focus:border-SeabiscuitMainThemeColor rounded-full focus:ring-0 focus:ring-offset-0 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer checked:bg-SeabiscuitMainThemeColor checked:border-SeabiscuitMainThemeColor ${item?.selected ? 'hidden' : ''}`}
                              type="checkbox"
                            />
                          </div>
                        </label>
                      )
                    })
                  ) : (
                    <div className="min-h-[200px] flex items-center justify-center">
                      No team member data found for join waitlist
                    </div>
                  )}
                </div>
              </div>

              <button
                type="button"
                onClick={handleAddMemberWaitlist}
                className="w-full h-12 mt-24 mx-auto py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:text-SeabiscuitMainThemeColor hover:bg-SeabiscuitLightThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
              >
                SAVE
              </button>
            </>
          )}

          {step === 3 && (
            <>
              <div className="modal-header flex flex-shrink-0 items-center justify-between rounded-t-md">
                <h5
                  className="text-xl leading-normal text-SeabiscuitDark200ThemeColor font-normal pb-2"
                  id="exampleModalScrollable3"
                >
                  Success!
                </h5>
              </div>

              <p className="text-SeabiscuitDark200ThemeColor text-sm py-4">
                Selected members were added to the waitlist.
              </p>
              <p className="text-SeabiscuitDark200ThemeColor text-sm pb-4 mb-32">
                Waitlisted exhibitors will be added to the event as people scratch, on a first come,
                first served basis.
              </p>
            </>
          )}

          <button
            onClick={() => handleModal(false, MODAL_CONSTS.REGISTER_FOR_COMPETETION)}
            type="button"
            className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
          >
            {step !== 3 ? 'CANCEL' : 'CLOSE'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default RegisterForCompetitionModal
