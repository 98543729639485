import { useContext, useEffect, useState } from 'react'
import { CONST } from '../../../../../../const/const'
import EmptyList from '../EmptyList'
import NewsCard from './NewsCard'
import { HandleModalContext } from '../../../../../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../../../../../const/modal-const'
import { NewsModel } from '../../../../../../models/news/news.model'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectUserId } from '../../../../../../store/user/userSlice'
import FirestoreService from '../../../../../../services/firestoreService'
import { where } from 'firebase/firestore'
import { INewsInterface } from '../../../../../../models/news/news.interface'
import { getConvertedData } from '../../../../../../models/interface.helper'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'
import { MESSAGES_CONST } from '../../../../../../const/messages-const'
import { httpService } from '../../../../../../services/httpService'

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const NewsTab = () => {
  const userId = useAppSelector(selectUserId)
  const [loading, setLoading] = useState(true)
  const [deletingIndex, setDeletingIndex] = useState(-1)
  const [news, setNews] = useState<INewsInterface[]>([])

  const toastFunctions = useToasterHelper()

  const VENDOR_AND_SPONSOR = CONST.UI.VENDOR_AND_SPONSOR
  const DUMMY_NEWS = VENDOR_AND_SPONSOR.DUMMY_DATA.NEWS

  const handleModal = useContext(HandleModalContext)

  useEffect(() => {
    if (userId) {
      FirestoreService.filterItems(COLLECTIONS.NEWS.NAME, [
        where(
          COLLECTIONS.NEWS.FIELDS.CREATOR_TYPE.KEY,
          '==',
          COLLECTIONS.NEWS.FIELDS.CREATOR_TYPE.VALUES.VENDOR
        ),
        where(COLLECTIONS.NEWS.FIELDS.STATUS.KEY, '==', COLLECTIONS.NEWS.FIELDS.STATUS.VALUES.LIVE),
        where(COLLECTIONS.NEWS.FIELDS.CREATOR_ID.KEY, '==', userId),
      ])
        .then((newsSnapshots) => {
          const news: INewsInterface[] = []
          newsSnapshots.forEach((currNewsSnapshot) => {
            news.push(getConvertedData(NewsModel.fromFirestoreDoc(currNewsSnapshot)))
          })
          setNews(news)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [userId])

  const onAddNewsButtonClick = () => {
    handleModal?.handleModal?.(true, MODAL_CONSTS.CREATE_AND_UPDATE_NEWS, {
      newsDoc: new NewsModel().toObject(),
      updateNews: (createdNews: INewsInterface) => {
        let createdNews_ = [...news]
        if (createdNews?.status === 'live') {
          createdNews_.unshift(createdNews)
          setNews([...createdNews_])
        }
      },
    })
  }

  const onEditNewsButtonClick = (index: number) => {
    handleModal?.handleModal?.(true, MODAL_CONSTS.CREATE_AND_UPDATE_NEWS, {
      newsDoc: news[index],
      updateNews: (updatedNews: INewsInterface) => {
        let updatedNews_ = [...news]

        if (updatedNews?.status === 'draft') {
          updatedNews_.splice(index, 1)
        } else {
          updatedNews_.splice(index, 1, updatedNews)
        }

        setNews([...updatedNews_])
      },
    })
  }

  const onMoreButtonClick = (newsIndex: number) => {
    handleModal?.handleModal?.(true, MODAL_CONSTS.NEWS, news[newsIndex])
  }

  const onDeleteButtonClick = async (newsIndex: number, bypass = false) => {
    if (bypass !== true) {
      handleModal?.handleModal?.(true, MODAL_CONSTS.REMOVE_CONFIRMATION, {
        removeItem: function () {
          onDeleteButtonClick(newsIndex, true)
        },
      })
      return 0
    }

    try {
      setDeletingIndex(newsIndex)

      if (news[newsIndex].pictures?.length) {
        await httpService({
          url: 'remove_images',
          method: 'post',
          data: {
            urls: news[newsIndex].pictures,
          },
        })
      }

      await FirestoreService.deleteItem(COLLECTIONS.NEWS.NAME, news[newsIndex]?.id!)

      let updatedNews_ = [...news]
      updatedNews_.splice(newsIndex, 1)

      setNews([...updatedNews_])
    } catch (error) {
      console.error(error)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    } finally {
      setDeletingIndex(-1)
      return 0
    }
  }

  if (!news.length && !loading) {
    return (
      <EmptyList text={VENDOR_AND_SPONSOR.MESSAGES.NEWS.MESSAGE} onClick={onAddNewsButtonClick} />
    )
  }

  return (
    <div
      id="news-section"
      itemScope
      itemType="http://schema.org/NewsArticle"
      className="max-h-[600px] overflow-auto"
    >
      {(loading ? DUMMY_NEWS : news).map((currNews: any, currIndex) => {
        return (
          <NewsCard
            {...currNews}
            loading={loading}
            index={currIndex}
            loggedInUserId={userId}
            key={`news${currIndex}`}
            deletingIndex={deletingIndex}
            onMoreButtonClick={onMoreButtonClick}
            onDeleteButtonClick={onDeleteButtonClick}
            onEditNewsButtonClick={onEditNewsButtonClick}
          />
        )
      })}

      {!loading ? (
        <div className="mt-4 text-center">
          <button onClick={onAddNewsButtonClick}>
            <img src="/assets/og_icons/plus-icon-2.svg" alt="add" />
          </button>
        </div>
      ) : null}
    </div>
  )
}

export default NewsTab
