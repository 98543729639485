import React from 'react'
import { PillButtonElement } from '../../../../../components/elements/buttons/pill/PillButtonElement'

type IProps = {
  text: string
  onClick: (args?: any) => any
}

const EmptyList = (props: IProps) => {
  return (
    <div className="h-[300px] flex flex-col justify-center gap-3 items-center">
      {props.text}
      <PillButtonElement
        onButtonClicked={(e: any) => props.onClick?.(e)}
        Class="rounded-[50px] !bg-SeabiscuitMainThemeColor !bg-opacity-[0.1] max-w-[180px] w-full text-SeabiscuitMainThemeColor"
        text="Post"
      />
    </div>
  )
}

export default EmptyList
