import { Link } from 'react-router-dom'
import { MODAL_CONSTS } from '../../../const/modal-const'
import { useLocation } from 'react-router-dom'

// Types
type IProps = {
  url?: string
  imgSrc: string
  text: string
  height?: number | null
  bottomText?: string
  textColor?: string
  handleModal?: any
  data?: any
  link?: string | null
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const NoDataAvailable = (props: IProps) => {
  const location = useLocation()
  const urlPath = location.pathname

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const getHtml = () => {
    let jsx = (
      <>
        {props.bottomText && props.bottomText !== '' ? (
          <div
            className={`${props.textColor ? props.textColor : 'text-[#F7074F]'} text-nr cursor-pointer`}
            onClick={() => {
              if (props.handleModal) {
                return props.handleModal(true, MODAL_CONSTS.NEW_TRANSFER, props.data)
              } else {
                return null
              }
            }}
          >
            {props.bottomText}
          </div>
        ) : null}
      </>
    )

    if (props.link)
      jsx = (
        <Link to={props.link} className="text-SeabiscuitMainThemeColor">
          {jsx}
        </Link>
      )

    return jsx
  }

  return (
    <div
      className="my-10 mx-5 flex items-center justify-center flex-col w-full"
      style={{
        height: props.height ? `${props.height}px` : '60vh',
      }}
    >
      <div className="text-black text-nr">{props.text}</div>
      <div className="w-[30%] md:max-w-[400px] md:max-h-[2000px] lg:max-h-[250px] max-w-full my-8">
        <img src={props.imgSrc} className="w-full h-full object-contain" alt="img" />
      </div>
      {urlPath === '/myevent' && (
        <Link to={'/home'} className="text-SeabiscuitMainThemeColor cursor-pointer text-nr">
          Search for events {'>'}
        </Link>
      )}

      {getHtml()}
    </div>
  )
}

export default NoDataAvailable
