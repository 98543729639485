import React from 'react'

export const IconBack: React.FC = () => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium textSeabiscuitDark200ThemeColor css-i4bv87-MuiSvgIcon-root"
      data-testid="KeyboardBackspaceIcon"
      viewBox="0 0 24 24"
      style={{
        width: '20px',
        height: 'auto',
      }}
    >
      <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"></path>
    </svg>
  )
}
