export type IDocumentList = IDocument[]

export type IDocument = {
  document_name: string
  document_image: string | string[]
  document_logo: string
  signed: boolean
  document_type: string
  key: string
}

export const DOCUMENT_TYPES = {
  USEF: 'usef',
  USEA: 'usea',
  GENERAL: 'general',
  USHJA: 'ushja',
  USDF: 'usdf',
  USEFENTRY: 'usefentry',
  BLANK: 'blank',
}

let fakeDocumentList: IDocumentList = [
  {
    document_name: 'Entry Blank',
    document_image: [
      '/assets/documents/GeneralLiabilityWaiver/GeneralLiabilityWaiver18+001.png',
      '/assets/documents/GeneralLiabilityWaiver/GeneralLiabilityWaiver18+002.png',
      '/assets/documents/GeneralLiabilityWaiver/GeneralLiabilityWaiver18+003.png',
      '/assets/documents/GeneralLiabilityWaiver/GeneralLiabilityWaiver18+004.png',
      '/assets/documents/GeneralLiabilityWaiver/GeneralLiabilityWaiver18+005.png',
      '/assets/documents/GeneralLiabilityWaiver/GeneralLiabilityWaiver18+006.png',
    ],
    document_logo: '/assets/Pegasus_app_logo.svg',
    signed: false,
    document_type: DOCUMENT_TYPES.BLANK,
    key: 'entryBlank',
  },
  {
    document_name: 'General Liability Waiver',
    document_image: [
      '/assets/documents/UniversalLiabilityWaiver/DEMO_EMPTY_General Liability Waiver, 18+-page1.jpg',
      '/assets/documents/UniversalLiabilityWaiver/DEMO_EMPTY_General Liability Waiver, 18+-page2.jpg',
      '/assets/documents/UniversalLiabilityWaiver/DEMO_EMPTY_General Liability Waiver, 18+-page3.jpg',
      '/assets/documents/UniversalLiabilityWaiver/DEMO_EMPTY_General Liability Waiver, 18+-page4.jpg',
      '/assets/documents/UniversalLiabilityWaiver/DEMO_EMPTY_General Liability Waiver, 18+-page5.jpg',
      '/assets/documents/UniversalLiabilityWaiver/DEMO_EMPTY_General Liability Waiver, 18+-page6.jpg',
    ],
    document_logo: '/assets/Pegasus_app_logo.svg',
    document_type: DOCUMENT_TYPES.GENERAL,
    signed: false,
    key: 'universalOrganizerWaver',
  },
  {
    document_name: 'USEF Waiver and Release of Liability',
    document_image: [
      '/assets/documents/USEF-LiabilityWaiver/USEF-LiabilityWaiver,18+.001.png',
      '/assets/documents/USEF-LiabilityWaiver/USEF-LiabilityWaiver,18+.002.png',
      '/assets/documents/USEF-LiabilityWaiver/USEF-LiabilityWaiver,18+.003.png',
      '/assets/documents/USEF-LiabilityWaiver/USEF-LiabilityWaiver,18+.004.png',
      '/assets/documents/USEF-LiabilityWaiver/USEF-LiabilityWaiver,18+.005.png',
    ],
    document_logo: '/assets/img/USEF.png',

    signed: false,
    document_type: DOCUMENT_TYPES.USEF,
    key: 'USEFWaiverAndReleaseOfLiability',
  },
  {
    document_name: 'USEF Entry Agreement',
    document_image: [
      '/assets/documents/USEF-EntryAgreement/USEF-EntryAgreement,18+001.png',
      '/assets/documents/USEF-EntryAgreement/USEF-EntryAgreement,18+002.png',
      '/assets/documents/USEF-EntryAgreement/USEF-EntryAgreement,18+003.png',
    ],
    document_logo: '/assets/img/USEF.png',
    document_type: DOCUMENT_TYPES.USEFENTRY,
    signed: false,
    key: 'USEFEntryAgreement',
  },
  {
    document_name: 'USDF Waiver and Release of Liablity',
    document_image: [
      '/assets/documents/USDF-Liability-Waiver/USDF-Liability-Waiver-0001.png',
      '/assets/documents/USDF-Liability-Waiver/USDF-Liability-Waiver-0002.png',
      '/assets/documents/USDF-Liability-Waiver/USDF-Liability-Waiver-0003.png',
      '/assets/documents/USDF-Liability-Waiver/USDF-Liability-Waiver-0004.png',
    ],
    document_logo: '/assets/Pegasus_app_logo.svg',
    document_type: DOCUMENT_TYPES.USDF,
    signed: false,
    key: 'USDFWaiverAndReleaseOfLiability',
  },
  {
    document_name: 'USEA Waiver and Release of Liability',
    document_image: [
      '/assets/documents/USEA-Entry-Agreement/USEA-Entry-Agreement-0001.png',
      '/assets/documents/USEA-Entry-Agreement/USEA-Entry-Agreement-0002.png',
      '/assets/documents/USEA-Entry-Agreement/USEA-Entry-Agreement-0003.png',
      '/assets/documents/USEA-Entry-Agreement/USEA-Entry-Agreement-0004.png',
      '/assets/documents/USEA-Entry-Agreement/USEA-Entry-Agreement-0005.png',
    ],
    document_logo: '/assets/Pegasus_app_logo.svg',
    document_type: DOCUMENT_TYPES.USEA,
    signed: false,
    key: 'USEAWaiverAndReleaseOfLiability',
  },
  {
    document_name: 'USHJA Waiver and Release of Liability',
    document_image: [
      '/assets/documents/USHJA-Liability-Waiver/USHJA-Liability-Waiver-0001.jpg',
      '/assets/documents/USHJA-Liability-Waiver/USHJA-Liability-Waiver-0002.jpg',
      '/assets/documents/USHJA-Liability-Waiver/USHJA-Liability-Waiver-0003.jpg',
      '/assets/documents/USHJA-Liability-Waiver/USHJA-Liability-Waiver-0004.jpg',
      '/assets/documents/USHJA-Liability-Waiver/USHJA-Liability-Waiver-0005.jpg',
    ],
    document_logo: '/assets/og_icons/USHJA.svg',
    document_type: DOCUMENT_TYPES.USHJA,
    signed: false,
    key: 'USHJAWaiverAndReleaseOfLiability',
  },
]

export default fakeDocumentList
