import { IMAGE_CONSTS } from '../../../../../../const/image-const'
import IUserCardTypes from './userCard.types'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const UserCard = (props: IUserCardTypes['IUserCardProps']) => {
  // Hooks and vars
  const { currentRider, className } = props

  return (
    <>
      <div className={`horse flex flex-row items-center  ${className}`}>
        <div className="flex flex-row items-center w-80 rounded-xl py-1.5 px-2 ml-2 border border-SeabiscuitGray500ThemeColor">
          <img
            src={
              !currentRider.memberProfilePicture || currentRider.memberProfilePicture === ''
                ? IMAGE_CONSTS.PLACEHOLDERS.HORSE
                : currentRider.memberProfilePicture
            }
            onClick={() => props.onProfilePicClick('user', currentRider.memberId)}
            className="mr-2 avatarImg rounded-md shrink-0 cursor-pointer object-cover"
            alt="member profile"
          />
          <div className="hourseDetails w-10/12">
            <div className="hourseTitle text-SeabiscuitDark200ThemeColor capitalize leading-[1]">
              {currentRider.memberName}
            </div>
            <div className="hourseCategory flex max-w-full flex-wrap">
              <p className="flex items-center text-sm text-SeabiscuitDark200ThemeColor">
                {' '}
                {currentRider.memberRole}
              </p>
            </div>
          </div>
          <span className="text-SeabiscuitMainThemeColor cursor-pointer ml-2">
            <img
              alt="delete"
              src="assets/og_icons/Cancel.svg"
              onClick={() =>
                props.onRemoveMemberClick({
                  currentRider,
                  bypass: false,
                  mappingDocId: currentRider.userHorseMappingId,
                })
              }
            />
          </span>
        </div>
      </div>
    </>
  )
}

export default UserCard
