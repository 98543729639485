export const horseHeightList = [
  {
    label: '5.0 hh',
    value: '5.0',
  },
  {
    label: '5.1 hh',
    value: '5.1',
  },
  {
    label: '5.2 hh',
    value: '5.2',
  },
  {
    label: '5.3 hh',
    value: '5.3',
  },
  {
    label: '6.0 hh',
    value: '6.0',
  },
  {
    label: '6.1 hh',
    value: '6.1',
  },
  {
    label: '6.2 hh',
    value: '6.2',
  },
  {
    label: '6.3 hh',
    value: '6.3',
  },
  {
    label: '7.0 hh',
    value: '7.0',
  },
  {
    label: '7.1 hh',
    value: '7.1',
  },
  {
    label: '7.2 hh',
    value: '7.2',
  },
  {
    label: '7.3 hh',
    value: '7.3',
  },
  {
    label: '8.0 hh',
    value: '8.0',
  },
  {
    label: '8.1 hh',
    value: '8.1',
  },
  {
    label: '8.2 hh',
    value: '8.2',
  },
  {
    label: '8.3 hh',
    value: '8.3',
  },
  {
    label: '9.0 hh',
    value: '9.0',
  },
  {
    label: '9.1 hh',
    value: '9.1',
  },
  {
    label: '9.2 hh',
    value: '9.2',
  },
  {
    label: '9.3 hh',
    value: '9.3',
  },
  {
    label: '10.0 hh',
    value: '10.0',
  },
  {
    label: '10.1 hh',
    value: '10.1',
  },
  {
    label: '10.2 hh',
    value: '10.2',
  },
  {
    label: '10.3 hh',
    value: '10.3',
  },
  {
    label: '11.0 hh',
    value: '11.0',
  },
  {
    label: '11.1 hh',
    value: '11.1',
  },
  {
    label: '11.2 hh',
    value: '11.2',
  },
  {
    label: '11.3 hh',
    value: '11.3',
  },
  {
    label: '12.0 hh',
    value: '12.0',
  },
  {
    label: '12.1 hh',
    value: '12.1',
  },
  {
    label: '12.2 hh',
    value: '12.2',
  },
  {
    label: '12.3 hh',
    value: '12.3',
  },
  {
    label: '13.0 hh',
    value: '13.0',
  },
  {
    label: '13.1 hh',
    value: '13.1',
  },
  {
    label: '13.2 hh',
    value: '13.2',
  },
  {
    label: '13.3 hh',
    value: '13.3',
  },
  {
    label: '14.0 hh',
    value: '14.0',
  },
  {
    label: '14.1 hh',
    value: '14.1',
  },
  {
    label: '14.2 hh',
    value: '14.2',
  },
  {
    label: '14.3 hh',
    value: '14.3',
  },
  {
    label: '15.0 hh',
    value: '15.0',
  },
  {
    label: '15.1 hh',
    value: '15.1',
  },
  {
    label: '15.2 hh',
    value: '15.2',
  },
  {
    label: '15.3 hh',
    value: '15.3',
  },
  {
    label: '16.0 hh',
    value: '16.0',
  },
  {
    label: '15.1 hh',
    value: '15.1',
  },
  {
    label: '15.2 hh',
    value: '15.2',
  },
  {
    label: '15.3 hh',
    value: '15.3',
  },
  {
    label: '16.0 hh',
    value: '16.0',
  },
  {
    label: '16.1 hh',
    value: '16.1',
  },
  {
    label: '16.2 hh',
    value: '16.2',
  },
  {
    label: '16.3 hh',
    value: '16.3',
  },
  {
    label: '17.0 hh',
    value: '17.0',
  },
  {
    label: '17.1 hh',
    value: '17.1',
  },
  {
    label: '17.2 hh',
    value: '17.2',
  },
  {
    label: '17.3 hh',
    value: '17.3',
  },
  {
    label: '18.0 hh',
    value: '18.0',
  },
  {
    label: '18.1 hh',
    value: '18.1',
  },
  {
    label: '18.2 hh',
    value: '18.2',
  },
  {
    label: '18.3 hh',
    value: '18.3',
  },
  {
    label: '19.0 hh',
    value: '19.0',
  },
  {
    label: '19.1 hh',
    value: '19.1',
  },
  {
    label: '19.2 hh',
    value: '19.2',
  },
  {
    label: '19.3 hh',
    value: '19.3',
  },
  {
    label: '20+ hh',
    value: '20+',
  },
]
