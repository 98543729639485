// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { createSlice } from '@reduxjs/toolkit'
import { IFollowingInterface } from '../../models/following/following.interface'
import { RootState } from '../store'

interface IFollowingState {
  organizer: {
    querry_data: IFollowingInterface[]
    following_doc: IFollowingInterface[]
    show_table_data: any[]
    unfollowLoading: { status: boolean; id: string }[]
  }
  loading: boolean
}

export const initialSystemFollowingState: IFollowingState = {
  organizer: {
    querry_data: [],
    following_doc: [],
    show_table_data: [],
    unfollowLoading: [],
  },

  loading: false,
}

const followingSlice = createSlice({
  name: 'following',
  initialState: initialSystemFollowingState,
  reducers: {
    setOrganizerData(
      state,
      {
        payload,
      }: {
        payload: {
          querry_data?: IFollowingInterface[]
          following_doc?: IFollowingInterface[]
          show_table_data?: any[]
        }
      }
    ) {
      state.organizer.querry_data = payload.querry_data?.length
        ? payload.querry_data
        : state.organizer.querry_data
      state.organizer.following_doc = payload.following_doc?.length
        ? payload.following_doc
        : state.organizer.following_doc
      state.organizer.show_table_data = payload.show_table_data?.length
        ? payload.show_table_data
        : state.organizer.show_table_data
    },
    setFollowingLoading(state, { payload }: { payload: boolean }) {
      state.loading = payload
    },
    emptyOrganizerstate(state) {
      state.organizer = initialSystemFollowingState.organizer
    },
    removeFollower(state, { payload }: { payload: { id: string; type: string } }) {
      if (payload.type === 'organizer') {
        state.organizer.show_table_data = state.organizer.show_table_data.filter((item) => {
          return item.follow_doc_id !== payload.id
        })

        state.organizer.following_doc = state.organizer.following_doc.filter((curr) => {
          return curr.id !== payload.id
        })
      } else {
      }
    },
    setUnfollowLoading(state, { payload }: { payload: { id: string; status: boolean } }) {
      if (state.organizer.unfollowLoading.length) {
        state.organizer.unfollowLoading = state.organizer.unfollowLoading.map((item) => {
          if (item.id === payload.id) {
            item.status = payload.status
          }
          return item
        })
      } else {
        state.organizer.unfollowLoading = [
          {
            status: payload.status,
            id: payload.id,
          },
        ]
      }
    },
  },
})

export const selectFollowingR = (state: RootState) => state.following

export const {
  setOrganizerData,
  setFollowingLoading,
  emptyOrganizerstate,
  removeFollower,
  setUnfollowLoading,
} = followingSlice.actions

export default followingSlice.reducer
