// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { styled } from '@mui/material/styles'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventScoringFormBuilderUtilDivisionRowAccordionDetailsComponent = styled(MuiAccordionDetails)(
  ({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  })
)

export default EventScoringFormBuilderUtilDivisionRowAccordionDetailsComponent
