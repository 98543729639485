import { useEffect, useState } from 'react'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import { IEventStaffInterface } from '../../../../../models/event-staff/event-staff.interface'
import EventReviewPublishInput from './EventReviewPublishInput'
import { getConvertedData } from '../../../../../models/interface.helper'
import { UserModel } from '../../../../../models/users/user.model'
import FirestoreService from '../../../../../services/firestoreService'
import { CONST } from '../../../../../const/const'
import { IUserInterface } from '../../../../../models/users/user.interface'
import DataNotAvailable from '../../../../common/alerts/data-not-available/DataNotAvailable'

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

// Types
type IDataToRender = {
  key: string
  value: string
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const DetailRow = ({ title, data }: { title: any; data: any }) => {
  return (
    <div className="flex flex-col md:flex-row mb-3 md:mb-0 md:items-center w-full">
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 w-80 shrink-0"
        data={title}
      />
      <EventReviewPublishInput
        className="rounded-lg text-SeabiscuitDark200ThemeColor h-12 bg-SeabiscuitGrayThemeColor p-3 m-1 grow"
        data={data}
      />
      <div className="rounded-lg bg-SeabiscuitGrayThemeColor p-1 m-1 h-12 w-12 text-center flex items-center justify-center shrink-0">
        <img className="m-auto" src="/assets/cp_icons/Ok-3.svg" alt="checkIcon" />
      </div>
    </div>
  )
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @TODO Document this
 */
const EventStaffReviewPublishForm = ({ data }: { data: IEventStaffInterface | null }) => {
  // Hooks and vars
  const [dataToRender, setDatatoRender] = useState<IDataToRender[]>([])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const handlegetdatausingId = async (userIds: string[]) => {
    let userData: IUserInterface[] = []
    let usersList = [...new Set(userIds)]
    let usersSnaps = await FirestoreService.getItemsUsingIds(COLLECTIONS.USERS.NAME, usersList)

    usersSnaps.forEach((currSnap) => {
      userData.push(getConvertedData(UserModel.fromFirestoreDoc(currSnap).toObject()))
    })

    if (!data) return

    if (!data.eventStaff || !data.otherStaff) return

    const staffData = data.eventStaff.map((staff) => {
      let temp = staff.value
      let mutated = temp.map((data) => {
        let filterData = userData.find((user) => user.id === data)
        return {
          staffName: filterData
            ? ' ' + filterData.userFirstName + ' ' + filterData.userLastName
            : '',
          id: data,
        }
      })

      return { ...staff, value: mutated }
    })

    const otherstaffData = data?.otherStaff.map((staff) => {
      let temp = staff.value
      let mutated = temp.map((data) => {
        let filterData = userData.find((user) => user.id === data)
        return {
          staffName: filterData
            ? ' ' + filterData.userFirstName + ' ' + filterData.userLastName
            : '',
          id: data,
        }
      })

      return { ...staff, value: mutated }
    })

    if (!staffData) return

    // setDatatoRender(staffData)
    let updated: IDataToRender[] = []

    staffData.forEach((data) => {
      let nameData = data.value.map((value) => {
        return value.staffName
      })
      let namekey = nameData.toString()
      updated.push({ key: data.title, value: namekey })
    })

    otherstaffData.forEach((data) => {
      let nameData = data.value.map((value) => {
        return value.staffName
      })
      const currtitle = data.title
      let namekey = nameData.toString()
      updated.push({ key: currtitle === '' ? 'Other Staff' : currtitle, value: namekey })
    })

    setDatatoRender(updated)
  }

  useEffect(() => {
    if (!data) return

    if (!data.eventStaff) return

    let arrayOfUsers: string[] = []
    data.eventStaff.forEach((staff) => {
      staff.value.forEach((data) => {
        arrayOfUsers.push(data)
      })
    })

    if (data.otherStaff) {
      data.otherStaff.forEach((staff) => {
        staff.value.forEach((data) => {
          arrayOfUsers.push(data)
        })
      })
    }

    handlegetdatausingId(arrayOfUsers)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  // Left

  return (
    <>
      {dataToRender.length ? (
        dataToRender.map((currData, index) => {
          return (
            <DetailRow
              key={`${JSON.stringify(currData)}${index}`}
              title={currData.key}
              data={currData.value}
            />
          )
        })
      ) : (
        <DataNotAvailable
          mode="text"
          containerClassName="text-SeabiscuitDark200ThemeColor justify-center flex"
          text={MESSAGES_CONST.STAFF_DATA_NOT_AVAILABLE}
        />
      )}
    </>
  )
}

export default EventStaffReviewPublishForm
