import React, { useEffect, useRef, useState } from 'react'
import DrawerComponent from './Components/DrawerComponent'

import { where } from 'firebase/firestore'
import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import { useDebounceWithSetter } from '../../../../hooks/useDebounceWithSetter'
import { getConvertedData } from '../../../../models/interface.helper'
import { IUserInterface } from '../../../../models/users/user.interface'
import { UserModel } from '../../../../models/users/user.model'
import FirestoreService from '../../../../services/firestoreService'
import { useAppSelector } from '../../../../store/hooks'
import { selectTeamMembers } from '../../../../store/user/userSlice'
import ViewsLoader from '../../../loader/ViewsLoader'
import searchIcon from './assets/search-icon.svg'
import verifiedIcon from './assets/verified-icon.svg'
import { IconPlus } from '../../../icons/IconPlus'
import AutorenewIcon from '@mui/icons-material/Autorenew'

export const STAFF_TYPES = [
  { id: 1, title: 'Show manager' },
  { id: 2, title: 'Secretary' },
  { id: 3, title: 'Technical Delegate' },
  { id: 4, title: 'Course Designers' },
  { id: 5, title: 'Judges' },
  { id: 6, title: 'Scorers' },
  { id: 7, title: 'Stewards' },
  { id: 8, title: 'Ingates' },
  { id: 9, title: 'Announcers' },
  { id: 10, title: 'Farriers' },
  { id: 11, title: 'Veterinarians' },
  { id: 12, title: 'Volunteers' },
  { id: 13, title: 'Other' },
]

const ButtonContainer: React.FC<{ children: any; className?: string }> = ({
  children,
  className,
}) => <div className={`w-[80%] flex flex-col gap-3 p-4 mx-auto ${className}`}>{children}</div>

const SelectBox: React.FC<{
  member: any
  selected: boolean
  onClick: (caption: string) => void
}> = ({ member, selected, onClick }) => (
  <div
    className={`p-4 cursor-pointer mb-2 rounded-2xl flex items-center gap-4 text-[14px] border-[#D3DAEE]`}
    onClick={() => onClick(member)}
  >
    <div className="rounded-full overflow-hidden w-15 h-15">
      <img
        src={
          member.userProfilePicture && member.userProfilePicture !== ''
            ? member.userProfilePicture
            : '/assets/cp_icons/User-4.svg'
        }
        alt="icon"
        className="h-[40px] bg-SeabiscuitGrayThemeColor rounded-full object-cover w-[40px]"
      />
    </div>
    <div className="flex-1">
      <div className="capitalize">
        {member.userFirstName
          ? `${member.userFirstName} ${member.userLastName}`
          : member.userFirstName}
      </div>
      <div className="opacity-50">{member.userEmail}</div>
    </div>
    <div>{selected && <img src={verifiedIcon} alt="verified" />}</div>
  </div>
)

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const StaffModal: React.FC<{
  show: boolean
  onHide?: () => void
  onChange?: (location: any) => void
  dataToPassOn: {
    row?: any
    id: number
    onSaveStaff: (value: { selection: IUserInterface[] }) => Promise<void>
    addNewPersonFeature?: boolean
    withoutSelection?: boolean
    onSaveJudge?: (user: IUserInterface) => Promise<void>
  }
  title?: string
  handleModal?: any
}> = ({ show, dataToPassOn, handleModal }) => {
  // Hooks and vars
  const { debouncedValue, debounceSetter } = useDebounceWithSetter({
    delay: 2000,
    value: null,
  })
  const [loading, setLoading] = useState(0)
  const [buttonLoading, setButtonLoading] = useState(false)
  // const userId = useAppSelector(selectUserId)
  const userTeam = useAppSelector(selectTeamMembers)
  const USER = CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { row } = dataToPassOn
  const [selection, setSelection] = useState<any[]>([])
  const [startSearching, setStartSearching] = useState<string>('')
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [userTeamData, setUserTeamData] = useState<any[]>([])

  useEffect(() => {
    if (debouncedValue !== null && debouncedValue !== '') {
      getUser(debouncedValue ? debouncedValue : null)
    } else {
      setFilteredData([])
      setLoading(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  async function getUser(value: string | null) {
    try {
      const fetch_user = await FirestoreService.filterItems(USER.NAME, [
        where(USER.FIELDS.USER_TYPE.KEY, '==', USER.FIELDS.USER_TYPE.VALUES.COMPETITOR),
        ...(value === null
          ? []
          : [where(USER.FIELDS.USER_NAME_NGRAMS.KEY, 'array-contains', value)]),
      ])

      if (fetch_user.size) {
        const users: IUserInterface[] = []
        fetch_user.forEach((curr) => {
          users.push(getConvertedData(UserModel.fromFirestoreDoc(curr).toObject()))
        })
        if (startSearching === '') {
          setFilteredData([])
          return 0
        }
        let mutated: any

        if (row) {
          mutated = users.map((user) => {
            const current = row.find((value: any) => value.id === user.id)
            if (dataToPassOn.withoutSelection) {
              return { ...user, selection: current?.selection ?? false }
            } else if (current) {
              return { ...user, selection: true }
            } else {
              return { ...user, selection: false }
            }
          })
        } else {
          mutated = users
        }
        setFilteredData(mutated)
      } else {
        setFilteredData([])
      }

      setLoading((prev) => (prev < 1 ? 0 : prev - 1))
    } catch (error) {
      setLoading((prev) => (prev < 1 ? 0 : prev - 1))
    }
  }

  const title =
    dataToPassOn.id < 14 && dataToPassOn.id > 0
      ? STAFF_TYPES[dataToPassOn.id - 1].title
      : STAFF_TYPES.at(-1)?.title

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%flex-1 font-normal text-[25px] flex items-center gap-3%%%%%

  useEffect(() => {
    inputRef.current?.focus?.()
  }, [])

  const handleFilter = (e: any) => {
    setStartSearching(e.target.value)
    let valueToSearch = e.target.value

    setLoading((prev) => prev + 1)

    debounceSetter(valueToSearch)
  }

  // memberId

  useEffect(() => {
    if (row) {
      const mutated = row.map((data: any) => {
        if (dataToPassOn.withoutSelection) {
          return data
        } else {
          return { ...data, selection: true }
        }
      })
      setUserTeamData(mutated)
      setSelection(row)
    }
  }, [userTeam, row])

  const changeHandle = (value: any) => {
    const updated = filteredData.map((filter) => {
      if (filter.id === value.id) {
        return { ...filter, selection: !filter.selection }
      } else {
        return { ...filter }
      }
    })
    const updatedrow = userTeamData.map((filter: any) => {
      if (filter.id === value.id) {
        return { ...filter, selection: !filter.selection }
      } else {
        return { ...filter, selection: filter.selection }
      }
    })
    setFilteredData(updated)
    setUserTeamData(updatedrow)

    if (Array.isArray(selection)) {
      if (selection?.find((obj: any) => obj.id === value.id)) {
        setSelection(selection?.filter((item: any) => item.id !== value.id))
      } else {
        let temp = [...selection, value]
        setSelection(temp)
      }
    }
  }

  const optionExistanceCheckHandle = (value: any) => {
    return value?.selection
  }

  const saveHandle = async () => {
    setButtonLoading(true)
    await dataToPassOn.onSaveStaff({ selection })
    setButtonLoading(false)
  }

  return (
    <DrawerComponent
      show={show}
      onHide={() => handleModal(false, MODAL_CONSTS.ORGNAIZER_EVENT_STAFF_ADD)}
      title={`Select members to add to ${title}`}
      maxWidth={640}
    >
      <div className="pr-2 max-h-[calc(100vh_-_250px)] overflow-auto">
        <div className="relative flex gap-2 border-[#1F417315] border-solid border-[1px] rounded-full px-6 py-1">
          <input
            type="text"
            ref={inputRef}
            className="border-none outline-none flex-1 focus:border-0"
            onChange={handleFilter}
          />
          {dataToPassOn.addNewPersonFeature ? (
            <div className="absolute right-0 top-0 h-full w-[135px] flex justify-center items-center">
              <span className="absolute left-0 h-[60%] top-[50%] translate-y-[-50%] w-[1px] bg-[#122B4680]" />
              <button
                onClick={() => {
                  handleModal(false, MODAL_CONSTS.ORGNAIZER_EVENT_STAFF_ADD)
                  handleModal(true, MODAL_CONSTS.ADD_NEW_USER, {
                    onSaveAddUser: dataToPassOn.onSaveJudge,
                  })
                }}
                className={
                  'group text-[#122B4680] text-[14px] flex items-center gap-1 hover:text-[#f7074f]'
                }
              >
                <IconPlus /> New Person
              </button>
            </div>
          ) : (
            <img src={searchIcon} alt="search" />
          )}
        </div>
        <div className="mt-6 h-[300px] overflow-y-auto">
          {loading ? (
            <div className="w-full h-full flex justify-center items-center">
              <ViewsLoader size="xl" color="red" />
            </div>
          ) : startSearching !== '' ? (
            filteredData.length > 0 ? (
              filteredData.map((member, index) => {
                return (
                  <React.Fragment key={index}>
                    <SelectBox
                      member={member}
                      onClick={changeHandle}
                      selected={optionExistanceCheckHandle(member)}
                    />
                  </React.Fragment>
                )
              })
            ) : (
              startSearching !== '' && <p className="p-4 text-center opacity-50">Not Found</p>
            )
          ) : userTeamData.length > 0 ? (
            userTeamData.map((member, index) => {
              return (
                <React.Fragment key={index}>
                  <SelectBox
                    key={`${JSON.stringify(member)}-${index}`}
                    member={member}
                    onClick={changeHandle}
                    selected={optionExistanceCheckHandle(member)}
                  />
                </React.Fragment>
              )
            })
          ) : (
            startSearching !== '' && <p className="p-4 text-center opacity-50">Not Found</p>
          )}
        </div>
      </div>
      <ButtonContainer className="mt-2">
        <button
          onClick={saveHandle}
          disabled={buttonLoading}
          className={`rounded-lg border border-SeabiscuitMainThemeColor border-solid text-white p-3 w-full bg-SeabiscuitMainThemeColor hover:bg-SeabiscuitMainThemeColorDark`}
        >
          {buttonLoading ? <AutorenewIcon fontSize="small" className="animate-spin" /> : 'SAVE'}
        </button>
      </ButtonContainer>
    </DrawerComponent>
  )
}

export default StaffModal
