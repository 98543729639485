// Constants
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'

// Components
import ExhibitorProfileEligabilityTab from './ExhibitorProfileEligabilityTab'
import ExhibitorProfileFeesTab from './ExhibitorProfileFeesTab'
import ExhibitorProfileTeamTab from './ExhibitorProfileTeamTab'
import ExhibitorProfileHorsesTab from './ExhibitorProfileHorsesTab'
import ExhibitorProfileRegistrationTab from './ExhibitorProfileRegistrationTab'
import ExhibitorProfilePaperworkTab from './ExhibitorProfilePaperworkTab'
import ExhibitorProfileTicketsTab from './ExhibitorProfileTicketsTab'

// Third party
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

// Models
import { TEventRegisteredUsers } from '../../../models/event-registered-users/event-registered-users.interface'

// Redux
import {
  IScratch,
  resetScratchItems,
  selectScratch,
  setScratchType,
} from '../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'

// Helpers
import { QueryFieldFilterConstraint, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import helpers from '../../../commonHelpers/helpers'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { CustomError } from '../../../helpers/helpers'
import { getConvertedData } from '../../../models/interface.helper'
import { IRegistrationFeesInterface } from '../../../models/registration-fees/registrationFees.interface'
import { RegistrationFeesModel } from '../../../models/registration-fees/registrationFees.model'
import { IRegistrationTicketInterface } from '../../../models/registration-tickets/registrationTicket.interface'
import { RegistrationTicketModel } from '../../../models/registration-tickets/registrationTicket.model'
import { IRegistrationByDayInterface } from '../../../models/registrations-by-day/registrationByDay.interface'
import { RegistrationByDayModel } from '../../../models/registrations-by-day/registrationByDay.model'
import FirestoreService from '../../../services/firestoreService'
import { httpService } from '../../../services/httpService'
import { selectRecipientR } from '../../../store/exhibitor/exhibitorSlice'
import { IEventReviewPublish } from '../../../models/event-review-publish/event-review-publish.interface'
import { AutorenewRounded } from '@mui/icons-material'
import { EventReviewPublishModel } from '../../../models/event-review-publish/event-review-publish.model'
import { RegistrationFeesType } from '../../../models/event-fees/event-fees.interface'
import { IUserInterface } from '../../../models/users/user.interface'
import { organizerSliceAcs } from '../../../store/organizer/organizerSlice'

const FILE_NAME = 'ExhibitorProfileDisplayTab'
const customErrorProps = {
  fileName: FILE_NAME,
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
type IProps = {
  userId: string
  eventId: string
  registrationId: string
  eventTab: string
  exhibitor: IUserInterface | null
  isRecipient?: boolean
  eventData?: IEventReviewPublish | null
  showScratchedView: boolean
  exhibitorAllData?: TEventRegisteredUsers | null
  handleScratchView: (visible: boolean) => void
  handleModal: (showHide: boolean, typeOfModal: string, data?: Object) => void
}

type IOnRefundAmountChangeFnArgs = {
  type: 'classes' | 'tickets' | 'fees'
  itemDetails: {
    itemId: string
    itemRefundAmount: number
  }
}

export type IOnRefundAmountChangeFn = (args: IOnRefundAmountChangeFnArgs) => void

type IUserParams = {
  userId: string
  eventId: string
  registrationId: string
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS

const ExhibitorProfileDisplayTab = (props: IProps) => {
  // Hooks and vars
  const isRecipient = props?.isRecipient ?? false
  const { eventData } = props
  const dispatch = useAppDispatch()
  const toastFunction = useToasterHelper()
  const recipientR = useAppSelector(selectRecipientR)
  const scratchDetails = useAppSelector(selectScratch)
  const handleModal = typeof props.handleModal === 'function' ? props.handleModal : () => {}

  const [uiLoading, setUiLoading] = useState(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [listToScratch, setListToScratch] = useState<string[]>([])
  const [resetState, setresetState] = useState<boolean>(false)
  const [registrationsByDay, setRegistrationsByDay] = useState<IRegistrationByDayInterface[]>([])
  const [registrationfeesData, setRegistrationfeesData] = useState<IRegistrationFeesInterface[]>([])
  const [registrationticketData, setRegistrationTicketData] = useState<
    IRegistrationTicketInterface[]
  >([])

  useEffect(() => {
    if (registrationsByDay[0]) {
      const groupedByRiderId = registrationsByDay.reduce<
        Record<string, IRegistrationByDayInterface[]>
      >((acc: any, item) => {
        if (!item.riderId) return null
        if (!acc[item.riderId]) {
          acc[item.riderId] = []
        }
        acc[item.riderId].push(item)
        return acc
      }, {})
      const result = Object.values(groupedByRiderId)
      dispatch(organizerSliceAcs.setCurrentEntries(result))
    }
  }, [registrationsByDay])

  const handleGetRegistrationData = async (
    userId: IUserParams['userId'],
    registrationId: IUserParams['registrationId'],
    eventId: IUserParams['eventId']
  ) => {
    let registrationsfees: IRegistrationFeesInterface[] = []
    let registrationsByDay_: IRegistrationByDayInterface[] = []
    let registrationsTickets: IRegistrationTicketInterface[] = []
    let publishedEventInDb: IEventReviewPublish | null = null

    let commonQueries = [
      where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.EVENT_ID.KEY, '==', eventId),
      where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.REGISTRATION_DOC_ID.KEY, '==', registrationId),
    ]

    // TODO: need to fix it
    commonQueries.push(where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.USER_ID.KEY, '==', userId))
    // } else {
    // commonQueries.push(
    //   where(COLLECTIONS.REGISTRATION_BY_DAY.FIELDS.RECIPIENT_ID.KEY, '==', userId)
    // )
    // }

    let queryConstraint = {
      Fees: {
        query: [] as QueryFieldFilterConstraint[],
        collection: COLLECTIONS.REGISTRATION_FEES.NAME,
      },
      Tickets: {
        query: [] as QueryFieldFilterConstraint[],
        collection: COLLECTIONS.REGISTRATION_TICKET.NAME,
      },
      Classes: {
        query: [] as QueryFieldFilterConstraint[],
        collection: COLLECTIONS.REGISTRATION_BY_DAY.NAME,
      },
    }

    if ('Classes' === props.eventTab) {
      queryConstraint.Classes.query = commonQueries
    }

    if ('Fees' === props.eventTab) {
      queryConstraint.Fees.query = commonQueries
    }

    if ('Tickets' === props.eventTab) {
      queryConstraint.Tickets.query = commonQueries
    }

    if (queryConstraint.Classes.query.length) {
      const commonSnaps = await FirestoreService.filterItems(
        queryConstraint.Classes.collection,
        queryConstraint.Classes.query
      )

      commonSnaps.docs.forEach((currDoc) => {
        registrationsByDay_.push(
          getConvertedData(RegistrationByDayModel.fromFirestoreDoc(currDoc).toObject())
        )
      })
    }

    const publishedEventInDbSnapShot = await FirestoreService.getItem(
      COLLECTIONS.EVENT_REVIEW_PUBLISH.NAME,
      eventId
    )

    if (publishedEventInDbSnapShot.exists()) {
      publishedEventInDb = getConvertedData(
        EventReviewPublishModel.fromFirestoreDoc(publishedEventInDbSnapShot).toObject()
      )

      const { EventFees: { registrationFees } = {} } = publishedEventInDb
      registrationsByDay_ = registrationsByDay_.map((registrationByDay) => {
        const filtered = (registrationFees as RegistrationFeesType[]).filter(
          (fee) => fee.uuid === registrationByDay.uuid
        )
        if (filtered.length > 0) {
          return { ...registrationByDay, registrationByDayName: filtered[0].name ?? null }
        } else {
          return registrationByDay
        }
      })
    }

    if (queryConstraint.Tickets.query.length) {
      const commonSnaps = await FirestoreService.filterItems(
        queryConstraint.Tickets.collection,
        queryConstraint.Tickets.query
      )

      commonSnaps.docs.forEach((currDoc) => {
        registrationsTickets.push(
          getConvertedData(RegistrationTicketModel.fromFirestoreDoc(currDoc).toObject())
        )
      })
    }

    if (queryConstraint.Fees.query.length) {
      const commonSnaps = await FirestoreService.filterItems(
        queryConstraint.Fees.collection,
        queryConstraint.Fees.query
      )

      commonSnaps.docs.forEach((currDoc) => {
        registrationsfees.push(
          getConvertedData(RegistrationFeesModel.fromFirestoreDoc(currDoc).toObject())
        )
      })
    }

    setRegistrationsByDay(registrationsByDay_)
    setRegistrationfeesData(registrationsfees)
    setRegistrationTicketData(registrationsTickets)
    setUiLoading(false)
  }

  useEffect(() => {
    if (props.userId && props.registrationId && props.eventId) {
      setUiLoading(true)
      handleGetRegistrationData(props.userId, props.registrationId, props.eventId).then()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userId, props.registrationId, props.eventId, props.eventTab, resetState])

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!scratchDetails.scratchViewVisibility) closeScratchView(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scratchDetails.scratchViewVisibility])

  // Functions

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const closeScratchView = async (scratchType: IScratch['scratchType']) => {
    try {
      let scratchTotal = 0

      if (!scratchType) {
        dispatch(resetScratchItems())
        return props.handleScratchView(false)
      }

      setLoading(true)

      let newArray: (
        | IRegistrationTicketInterface
        | IRegistrationFeesInterface
        | IRegistrationByDayInterface
      )[] = []
      const updateData: (
        | IRegistrationTicketInterface
        | IRegistrationFeesInterface
        | IRegistrationByDayInterface
      )[] = []

      let scratchedTabName =
        props.eventTab === 'Classes'
          ? 'scratchedRegistrationByDayItems'
          : props.eventTab === 'Fees'
            ? 'scratchedFeesItems'
            : 'scratchedTicketItems'

      switch (props.eventTab) {
        case 'Classes':
          newArray = registrationsByDay
          break
        case 'Fees':
          newArray = registrationfeesData
          break
        case 'Tickets':
          newArray = registrationticketData
          break
      }
      newArray.forEach((member) => {
        if (listToScratch.find((data) => data === member.id)) {
          if ('registrationPrice' in member) {
            scratchTotal += member.registrationPrice ?? 0
            updateData.push({
              ...member,
              amountScratched: member.registrationPrice ? member.registrationPrice : 0,
              isSratched: true,
              scratchAmountAdded: true,
            })
          } else if ('ticketPrice' in member) {
            scratchTotal += (member.ticketPrice ?? 0) * member.selectedUnitsCount
            updateData.push({
              ...member,
              amountScratched: member.ticketPrice,
              isSratched: true,
              scratchAmountAdded: true,
            })
          } else if ('feesPrice' in member) {
            scratchTotal += (member.feesPrice ?? 0) * member.selectedUnitsCount
            updateData.push({
              ...member,
              amountScratched: member.feesPrice,
              isSratched: true,
              scratchAmountAdded: true,
            })
          }
        }
      })

      const modifydata = listToScratch.map((data) => {
        return { docId: data }
      })

      if (listToScratch.length < 1)
        return toastFunction.info({
          message: MESSAGES_CONST.NO_ITEM_SELECTED_TO_SCRATCH,
        })

      if (modifydata.length && scratchedTabName && updateData && updateData.length) {
        const customerData: any = await httpService({
          url: 'scratch_items',
          method: 'POST',
          data: {
            userDocId: props.userId,
            recipientId: updateData ? updateData[0].recipientId : null,
            registrationDocId: updateData ? updateData[0].registrationDocId : [],
            [scratchedTabName]: modifydata,
          },
        })

        await httpService({
          method: 'get',
          url: `update_registration_status_keys/${updateData[0].registrationDocId}`,
        })

        if (customerData.status) {
          setresetState(!resetState)
        }
      }

      dispatch(setScratchType(scratchType))
      setLoading(false)
      props.handleScratchView(false)
      handleModal(true, MODAL_CONSTS.EXHIBITOR_SCRATCH_CONFIRM_MODAL, {
        ...recipientR.data,
        scratchTotal,
        tab: props.eventTab,
      })
    } catch (error: any) {
      toastFunction.error({
        message: error?.response?.data?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      helpers.logger({
        message: CustomError.somethingWentWrong({
          ...customErrorProps,
          moduleName: 'closeScratchView',
          devMessage: error?.response?.data?.devMessage ?? error?.message,
        }),
      })
    } finally {
      setLoading(false)
    }
  }

  const getScratchView = (scratchType: IScratch['scratchType']): ReactJSXElement | null => {
    let disabled = false
    if (registrationfeesData.length) {
      let count = 0
      registrationfeesData.forEach((data) => {
        if (data.isSratched) {
          count++
        }
      })
      if (count === registrationfeesData.length) {
        disabled = true
      }
    } else if (registrationticketData.length) {
      let count = 0
      registrationticketData.forEach((data) => {
        if (data.isSratched) {
          count++
        }
      })
      if (count === registrationticketData.length) {
        disabled = true
      }
    } else if (registrationsByDay.length) {
      let count = 0
      registrationsByDay.forEach((data) => {
        if (data.isSratched) {
          count++
        }
      })
      if (count === registrationsByDay.length) {
        disabled = true
      }
    }

    if (!props.showScratchedView) return null

    return (
      <div className="w-full">
        <button
          onClick={() => closeScratchView(scratchType)}
          type="button"
          disabled={disabled}
          className={`${disabled ? 'opacity-40' : ''} w-full h-12 mx-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor  mt-2 uppercase`}
        >
          {loading ? (
            <AutorenewRounded fontSize="small" className="animate-spin" />
          ) : (
            <>{eventData && eventData?.status === 'cancel' ? 'Submit' : 'Scratch'}</>
          )}
        </button>
        <button
          onClick={() => closeScratchView(null)}
          type="button"
          // disabled={disabled}
          className="w-full h-12 mx-auto flex items-center justify-center py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-SeabiscuitDark200ThemeColor bg-SeabiscuitLightThemeColor mt-2 uppercase"
        >
          Cancel
        </button>
      </div>
    )
  }

  const selectTab = (event_tab: string) => {
    switch (event_tab) {
      case CONST.UI.EXHIBITOR.TABS.PAPERWORK.VALUE:
        return (
          <ExhibitorProfilePaperworkTab
            handleModal={handleModal}
            exhibitorAllData={props?.exhibitorAllData}
            isRecipient={isRecipient}
          />
        )

      case CONST.UI.EXHIBITOR.TABS.FEES.VALUE:
        return (
          <ExhibitorProfileFeesTab
            isRefund={false}
            isRecipient={isRecipient}
            getScratchView={getScratchView}
            exhibitorAllData={props?.exhibitorAllData}
            showScratchedView={props.showScratchedView}
            setListToScratch={setListToScratch}
            listToScratch={listToScratch}
            registrationfeesData={registrationfeesData}
          />
        )

      case CONST.UI.EXHIBITOR.TABS.TICKETS.VALUE:
        return (
          <ExhibitorProfileTicketsTab
            isRefund={false}
            isRecipient={isRecipient}
            getScratchView={getScratchView}
            exhibitorAllData={props?.exhibitorAllData}
            showScratchedView={props.showScratchedView}
            registrationticketData={registrationticketData}
            setListToScratch={setListToScratch}
            listToScratch={listToScratch}
          />
        )

      case CONST.UI.EXHIBITOR.TABS.ELIGABILITY.VALUE:
        return (
          <ExhibitorProfileEligabilityTab
            isRecipient={isRecipient}
            exhibitorAllData={props?.exhibitorAllData}
          />
        )

      case CONST.UI.EXHIBITOR.TABS.CLASSES.VALUE:
        return (
          <ExhibitorProfileRegistrationTab
            isRefund={false}
            getScratchView={getScratchView}
            showScratchedView={props.showScratchedView}
            registrationsByDay={registrationsByDay}
            setListToScratch={setListToScratch}
            listToScratch={listToScratch}
          />
        )

      case CONST.UI.EXHIBITOR.TABS.TEAM.VALUE:
        return (
          <ExhibitorProfileTeamTab
            isRecipient={isRecipient}
            exhibitorAllData={props?.exhibitorAllData}
          />
        )

      case CONST.UI.EXHIBITOR.TABS.HORSES.VALUE:
        return (
          <ExhibitorProfileHorsesTab
            isRefund={false}
            isRecipient={isRecipient}
            getScratchView={getScratchView}
            exhibitorAllData={props?.exhibitorAllData}
            showScratchedView={props.showScratchedView}
            setListToScratch={setListToScratch}
            listToScratch={listToScratch}
            registrationfeesData={registrationfeesData}
          />
        )
    }
  }

  if (uiLoading) {
    return (
      <div className="h-fit my-10 flex justify-center items-center">
        <ViewsLoader size="lg" color="#F7074F" />
      </div>
    )
  }

  return <>{selectTab(props.eventTab)}</>
}

export default ExhibitorProfileDisplayTab
