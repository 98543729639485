import { useEffect, useState } from 'react'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @info Custom Hook to use matchMedia
 */
function useMedia(query: string) {
  // Hooks and vars
  const [isMatched, setIsMatched] = useState(() => window.matchMedia(query).matches)

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    const mediaQuery = window.matchMedia(query)

    const updateMatched = (event: any) => {
      setIsMatched(event.matches)
    }

    // Add listener for changes in media query matching
    mediaQuery.addEventListener('change', updateMatched)

    // Clean up the listener when the component is unmounted
    return () => {
      mediaQuery.removeEventListener('change', updateMatched)
    }
  }, [query])

  return isMatched
}

export default useMedia
