import { COLORS } from '../const/ui/ui-colors.const'

export const getIconColor = (num: number, index: number): string => {
  const colors: string[] = []
  const chunkSize = COLORS.length
  while (num > 0) {
    let chunk = Math.min(chunkSize, num)
    colors.push(...COLORS)
    num -= chunk
  }
  return colors[index] || '#388af4'
}
