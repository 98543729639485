import { IJudgingLicence } from '../types/competitor_types'

export const JudgingLicence: IJudgingLicence[] = [
  {
    label: 'Recorded/Small r',
    value: 'recorded_or_small_r',
  },
  {
    label: 'Registered/Large R',
    value: 'registered_or_large_r',
  },
  {
    label: 'Senior',
    value: 'senior',
  },
  {
    label: 'DSH-r',
    value: 'dsh-r',
  },
  {
    label: 'DSH-R',
    value: 'dsh-R',
  },
  {
    label: '2 Star / New',
    value: '2_start_new',
  },
  {
    label: '3 Star / C',
    value: '3_star_C',
  },
  {
    label: '4 Star / I',
    value: '4_star_I',
  },
  {
    label: '5 Star / O',
    value: '5_star_O',
  },
]
