// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import ModalComponent from '../../events/views/details/global/modalComponent'

// Consts
import { MODAL_CONSTS } from '../../../const/modal-const'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
type IProps = {
  handleModal: (showHide: boolean, typeOfModal: string) => void
  show: boolean
}

const RegisterForCompetitionPaperworkModal: React.FC<IProps> = ({ show, handleModal }) => {
  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <ModalComponent
      show={show}
      handleModal={handleModal}
      type="full-modal"
      modalFor={MODAL_CONSTS.REGISTER_FOR_PAPERWORK}
      title="Universal Entry Waiver"
      size="xl"
    >
      <div>
        <div className="flex flex-col items-center gap-3 pr-3">
          {/* Box one */}
          <div className="flex w-full border-solid p-5 border-[#D3DAEE] border rounded-2xl">
            <div className="mr-2">
              <img src="assets/img/dark/NewDocument.svg" alt="document" />
            </div>
            <div className="">
              <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">What is this?</p>
              <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                A universal waiver that provides general event liability protection to the event
                organizer.
              </p>
            </div>
          </div>
          {/* Box one */}
          {/* Box two */}
          <div className="flex w-full border-solid p-5 border-[#D3DAEE] border rounded-2xl">
            <div className="mr-2">
              <img src="assets/img/dark/user-groups.svg" alt="document" />
            </div>
            <div className="">
              <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">Who must sign?</p>
              <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                Anyone who will be physcially present on the show grounds.
              </p>
              <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                Anyone who is a rider, trainer, owner or coach of an exhibiting horse.
              </p>
              <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                Guardians of exhibitors under 18 years of age.
              </p>
            </div>
          </div>
          {/* Box two */}
          {/* Box three */}
          <div className="flex w-full border-solid p-5 border-[#D3DAEE] border rounded-2xl">
            <div className="mr-2">
              <img src="assets/img/dark/Quillpen.svg" alt="document" />
            </div>
            <div className="">
              <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">Signatories</p>
              <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                Pegasus will send this document to the signatories to review, sign and submit to the
                event organizer, in support of your registration.
              </p>
              <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                We will notify you once they have signed and submitted thier paperwork.
              </p>
              <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                The signatories that are automatcially added are those that you have saved as
                connected to the horses you have registered or this event.
              </p>
              <p className="mb-2 text-sm text-SeabiscuitDark200ThemeColor">
                You can add and remove signatories.{' '}
              </p>
            </div>
          </div>
          {/* Box four */}
          <button
            className="w-full mt-2 h-12 mx-auto block items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor hover:text-white hover:bg-SeabiscuitMainThemeColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor"
            onClick={() => handleModal(false, 'paperwork')}
          >
            CLOSE
          </button>
        </div>
      </div>
    </ModalComponent>
  )
}

export default RegisterForCompetitionPaperworkModal
