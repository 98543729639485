import TimeLib from '../../../../lib/Time'
import { IRiderTeamMemberInterface } from '../../../../models/rider-team-member/riderTeamMember.interface'

export const DATA_FIRESTORE_V01_RIDER_TEAM_MEMBER = {
  NAME: 'v01_rider_team_member',
  FIELDS: {
    V: {
      KEY: 'v',
      VALUES: {
        DEFAULT: 1,
      },
    },

    ID: {
      KEY: 'id',
      VALUES: {
        DEFAULT: null,
      },
    },
    UUID: {
      KEY: 'uuid',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_ID: {
      KEY: 'eventId',
      VALUES: {
        DEFAULT: null,
      },
    },

    REGISTRATION_DOC_ID: {
      KEY: 'registrationDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    REGISTRATION_BY_DAY_DOC_ID: {
      KEY: 'registrationByDayDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_ID: {
      KEY: 'userId',
      VALUES: {
        DEFAULT: null,
      },
    },

    SIGNED_STATUS: {
      KEY: 'signedStatus',
      VALUES: {
        SIGNED: 'Signed' as IRiderTeamMemberInterface['signedStatus'],
        NOT_SIGNED: 'Not Signed' as IRiderTeamMemberInterface['signedStatus'],
        REMOVED: 'Removed' as IRiderTeamMemberInterface['signedStatus'],
        DEFAULT: 'Not sent' as IRiderTeamMemberInterface['signedStatus'],
        NOT_SENT: 'Not sent' as IRiderTeamMemberInterface['signedStatus'],
        MAIL_SENT: 'Mail sent' as IRiderTeamMemberInterface['signedStatus'],
      },
    },

    HORSE_ID: {
      KEY: 'horseId',
      VALUES: {
        DEFAULT: null,
      },
    },

    USER_HORSE_MAPPING_DOC_ID: {
      KEY: 'userHorseMappingDocId',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_ID: {
      KEY: 'teamMemberId',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_NAME: {
      KEY: 'teamMemberName',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_EMAIL: {
      KEY: 'teamMemberEmail',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_COUNTRY: {
      KEY: 'teamMemberCountry',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_PHONE_NUMBER: {
      KEY: 'teamMemberPhoneNumber',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_PHONE_CODE: {
      KEY: 'teamMemberPhoneCode',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_DOB: {
      KEY: 'teamMemberDob',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_ROLE: {
      KEY: 'teamMemberRole',
      VALUES: {
        DEFAULT: null,
      },
    },

    EVENT_NAME: {
      KEY: 'eventName',
      VALUES: {
        DEFAULT: null,
      },
    },

    HORSE_NAME: {
      KEY: 'horseName',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_ID: {
      KEY: 'riderId',
      VALUES: {
        DEFAULT: null,
      },
    },

    MAIL_LOG: {
      KEY: 'mailLog',
      VALUES: {
        DEFAULT: [],
      },
    },

    RIDER_NAME_N_GRAM: {
      KEY: 'riderNameNGram',
      VALUES: {
        DEFAULT: [],
      },
    },

    RIDER_DOB: {
      KEY: 'riderDob',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_NAME: {
      KEY: 'riderName',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_EMAIL: {
      KEY: 'riderEmail',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_COUNTRY: {
      KEY: 'riderCountry',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_PHONE_NUMBER: {
      KEY: 'riderPhoneNumber',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_PHONE_CODE: {
      KEY: 'riderPhoneCode',
      VALUES: {
        DEFAULT: null,
      },
    },

    RIDER_PROFILE_PICTURE: {
      KEY: 'riderProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_NAME_N_GRAM: {
      KEY: 'teamMemberNameNGram',
      VALUES: {
        DEFAULT: [],
      },
    },

    HORSE_PROFILE_PICTURE: {
      KEY: 'horseProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    TEAM_MEMBER_PROFILE_PICTURE: {
      KEY: 'teamMemberProfilePicture',
      VALUES: {
        DEFAULT: null,
      },
    },

    CREATED: {
      KEY: 'created',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },

    UPDATED: {
      KEY: 'modified',
      VALUES: {
        NULL: null,
        DEFAULT: TimeLib.utcTimestamp(),
      },
    },
  },
}
