import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { MESSAGES_CONST } from '../../const/messages-const'
import MessageHelperComp from '../../helpers/MessageHelper'
import useToasterHelper from '../../helpers/ToasterHelper'
import { capitalize, getUserFullName } from '../../helpers/helpers'
import { httpService } from '../../services/httpService'
import { useAppSelector } from '../../store/hooks'
import { selectProfileData } from '../../store/user/userSlice'
import MainModal from './common/MainModal'
import { AxiosError } from 'axios'
import { HandleModalContext } from '../../layout/mainlayout/MainLayout'
import { MODAL_CONSTS } from '../../const/modal-const'

type IProps = {
  dataToPassOn: {
    emailId?: string | null
    user_name?: string | null
    profilePicture?: string | null
  }
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const schema = yup.object().shape({
  email: yup.string().required(),
  message: yup
    .string()
    .transform((value) => {
      return value?.trim()
    })
    .required(),
})

const SendMessageModal = (props: IProps) => {
  const [loading, setLoading] = useState(false)
  const senderName = getUserFullName(useAppSelector(selectProfileData))
  const handleModalContext = useContext(HandleModalContext)

  const toastFunctions = useToasterHelper()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset({
      email: props.dataToPassOn.emailId,
      message: '',
    })
  }, [props.dataToPassOn.emailId, reset])

  const onSubmit = async (data: any) => {
    try {
      setLoading(true)

      const res = await httpService({
        url: 'send_message_via_email',
        method: 'POST',
        data: {
          ...data,
          email: data.email.split(','),
          senderName: capitalize(!!!senderName ? 'Unknown' : senderName),
        },
      })

      if (res.status === false) {
        throw new Error(res?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG)
      } else {
        reset({
          email: props.dataToPassOn.emailId,
          message: '',
        })

        toastFunctions.success({
          message: res.message,
        })
      }
    } catch (error: any) {
      console.log(error)
      let message = MESSAGES_CONST.SOMETHING_WENT_WRONG
      if (error instanceof AxiosError) {
        message = (error.response?.data?.message ?? message)?.replace(/\[\d+\]/gi, '')
      } else {
        message = error?.message ?? message
      }
      toastFunctions.error({
        message,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <MainModal
      customTitle={
        <div className="flex-1 mini:font-bold text-[25px] flex items-center gap-3 px-0">
          <div className={`!text-SeabiscuitDark200ThemeColor !font-normal`}>Message</div>
        </div>
      }
      show={true}
      closeButtonClassName="!right-3"
      modalClassName="!px-[38px] min-h-[60vh] !max-w-[639px]"
      type="SEND_MESSAGE"
      customPadding={true}
      className="mt-4"
      hideCloseButton={loading}
      setHeightAsPerContent={true}
      size="lg"
      buttons={[
        {
          label: 'Send',
          loading: loading,
          disabled: loading,
          className: '!w-[100%]',
          bgClass: '!bg-SeabiscuitMainThemeColor !mt-0',
          onClick: handleSubmit(onSubmit),
          textClass: '!text-white uppercase',
          onHoverClass: 'hover:shadow-slate-300',
        },
        {
          label: 'cancel',
          className: '!w-[100%]',
          bgClass: '!bg-[#e8ecf2] border-0 !mt-2',
          onClick: () => handleModalContext?.handleModal(false, MODAL_CONSTS.SEND_MESSAGE),
          textClass: '!text-[#8597b3] uppercase',
          onHoverClass: 'hover:shadow-slate-300',
        },
      ]}
    >
      <form action="#" onSubmit={handleSubmit(onSubmit)} className="flex gap-2 flex-col">
        <div
          className={`text-[14px] w-full ${!!!errors?.email ? 'bg-white' : 'bg-white'} border-solid border-[1px] border-[#D3DAEE] rounded-md flex items-center p-4 pl-0.5 h-12 gap-2`}
        >
          {/* <img src={(!!watch(`email`) ?
                        "/assets/og_icons/UserGroups-1.svg" :
                        "/assets/og_icons/UserGroups.svg")}
                        alt="icon"
                        className={watch(`email`) !== "" ?
                            "/assets/og_icons/UserGroups-1.svg" :
                            "/assets/og_icons/UserGroups.svg"}
                            src={props.dataToPassOn.profilePicture ?? "assets/img/EmailAll.png"}
                    /> */}
          <div className="rounded-md" id="right-side-menu" data-ion-popover-trigger="true">
            <img
              className="inline-block rounded-lg p-1 object-cover w-[45px] h-[45px]"
              src={props.dataToPassOn.profilePicture ?? 'assets/img/EmailAll.png'}
              alt=""
            />
          </div>
          <input
            className="w-full bg-transparent !outline-0 !border-none text-SeabiscuitDark200ThemeColor placeholder:text-SeabiscuitDark200ThemeColor "
            placeholder={props.dataToPassOn.user_name ?? 'Email All'}
            disabled
            //  {...register(`email`)}
          />

          {/* {!!!errors?.email?.message ? (
                        <img src="/assets/og_icons/Ok-3.svg" alt="green check" title="Email is valid" />
                    ) : <Clear fontSize={'small'} style={{
                        color: "grey",
                        fontWeight: "400"
                    }} className="cursor-pointer"
                        onClick={() => setValue("email", "")} />
                    } */}
        </div>

        <div className="flex flex-col h-100vh gap-2 text-SeabiscuitDark200ThemeColor">
          <div className={`text-[14px] w-full min-h-100vh  flex items-center gap-2`}>
            <textarea
              className="w-full h-[50vh] border-solid border-[1px] bg-white outline-none !border-[#D3DAEE] rounded-[8px]"
              placeholder="Enter Message..."
              style={{
                boxShadow: 'none',
              }}
              {...register(`message`)}
            />
          </div>
        </div>

        {!!errors?.message?.message ? (
          <MessageHelperComp message={errors?.message.message} />
        ) : null}
      </form>
    </MainModal>
  )
}

export default SendMessageModal
