import React from 'react'
import WrapperContainer from '../../components/common/wrappers/WrapperContainer'
import Header from './components/header/Header'
import Body from './components/body/Body'

const SponsorAndVendor = () => {
  return (
    <WrapperContainer>
      <div className="flex flex-col gap-7">
        <Header />
        <Body />
      </div>
    </WrapperContainer>
  )
}

export default SponsorAndVendor
