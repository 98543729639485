import { RootState } from '../store'
import { createSlice } from '@reduxjs/toolkit'
import {
  IEventBookmarkGridInterface,
  IEventBookmarkInterface,
} from '../../models/event-bookmark/event-bookmark.interface'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Types
export interface IInitialBookmarksState {
  bookmarkedEvents: IEventBookmarkInterface[]
  bookmarksGrid: {
    cursorId: string | null
    events: IEventBookmarkGridInterface[]
    totalBookmarksCountInDb: null | number
  }
}

// Constants
const initialSystemState: IInitialBookmarksState = {
  bookmarkedEvents: [],
  bookmarksGrid: {
    cursorId: null,
    events: [],
    totalBookmarksCountInDb: null,
  },
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState: initialSystemState,
  reducers: {
    setBookmarkedEvents(
      state,
      { payload }: { payload: IInitialBookmarksState['bookmarkedEvents'] | null }
    ) {
      if (payload === null) {
        state.bookmarkedEvents = {
          ...initialSystemState.bookmarkedEvents,
        }
      } else state.bookmarkedEvents = payload
    },

    setBookmarksGrid(state, { payload }: { payload: IInitialBookmarksState['bookmarksGrid'] }) {
      state.bookmarksGrid.cursorId = payload.cursorId
      state.bookmarksGrid.events = payload.events
      state.bookmarksGrid.totalBookmarksCountInDb = payload.totalBookmarksCountInDb
    },

    resetBookmarksGrid(state) {
      state.bookmarksGrid = initialSystemState.bookmarksGrid
    },
  },
})

export const { setBookmarksGrid, setBookmarkedEvents, resetBookmarksGrid } = bookmarksSlice.actions

export const selectBookmarkedEvents = (state: RootState) => state?.bookmarks.bookmarkedEvents ?? []
export const selectBookmarkedEventsGrid = (state: RootState) => state?.bookmarks.bookmarksGrid ?? []

export default bookmarksSlice.reducer
