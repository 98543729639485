import { yupResolver } from '@hookform/resolvers/yup'
import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import helpers from '../../../commonHelpers/helpers'
import ViewsLoader from '../../../components/loader/ViewsLoader'
import { CONST } from '../../../const/const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import MessageHelperComp from '../../../helpers/MessageHelper'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { CustomError } from '../../../helpers/helpers'
import { IAssignedTicket } from '../../../models/assigned-tickets/assigned-tickets.interface'
import { AssignTicketsModal } from '../../../models/assigned-tickets/assigned-tickets.model'
import { getConvertedData } from '../../../models/interface.helper'

import { RegistrationTicketModel } from '../../../models/registration-tickets/registrationTicket.model'
import EventService from '../../../services/eventService'
import FirestoreService from '../../../services/firestoreService'
import { httpService } from '../../../services/httpService'
import { useAppSelector } from '../../../store/hooks'
import {
  setAllTickets,
  setSelectedRegistrationTicket,
  setSelectedTicket,
  updateKeysTickets,
} from '../../../store/tickets/ticketslice'
import { selectProfileData } from '../../../store/user/userSlice'
import { ticketTemplate } from '../../../templates/pdf/ticketShare/ticketTemplate'
import { IRegistrationTicketInterface } from '../../../models/registration-tickets/registrationTicket.interface'
import { ITicketBuyer } from '../../../models/ticket-buyers/ticket-buyers.interface'
import { ISpectatorTickets } from '../../../models/spectator-tickets/spectator-tickets.interface'
import { SpectatorTicketModel } from '../../../models/spectator-tickets/spectator-tickets-model'
import { TicketBuyersModel } from '../../../models/ticket-buyers/ticket-buyers.model'

export const handleTicketTemplate = async (
  assigner_name: string,
  assigner_email: string,
  registrationTicket: any,
  assignedTicket: IAssignedTicket
) => {
  const template_url = await ticketTemplate(
    assigner_name,
    assigner_email,
    registrationTicket,
    assignedTicket
  )
  return template_url
}

// Types

type Props = {
  dataToPassOn: any
  handelTicketStep: (step: number) => void
  loading?: boolean
  check_validation?: any
  assigned_user?: any
  assignTicket: IAssignedTicket[]
  SavedTicket: IAssignedTicket[]
  setaAssignTicket: React.Dispatch<React.SetStateAction<IAssignedTicket[]>>
  decrementUnusedTicketCount?: () => void
}

type fieldNameError = {
  index: number
  name_message: any
}

type fieldEmailError = {
  index: number
  email_message: any
}

// Constants
const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const CUSTOM_ERROR_PROPS = {
  fileName: 'SendTicketComponent',
  message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
}

const SendTicketComponent = (props: Props) => {
  const dispatch = useDispatch()
  const toastFunction = useToasterHelper()

  const setEventStartDate = useState<string | Date | null>(new Date())[1]
  const user = useAppSelector(selectProfileData)

  const [emailSenting, setEmailSenting] = useState<number[]>([])
  const [nameFieldError, setNameFieldError] = useState<fieldNameError[]>([])
  const [emailFieldError, setEmailFieldError] = useState<fieldEmailError[]>([])
  const [listView, setListView] = useState<boolean>(false)

  const { selected_ticket } = useAppSelector((state) => state.tickets.assigned_tickets)
  const {
    dataToPassOn,
    handelTicketStep,
    loading,
    check_validation,
    assigned_user,
    assignTicket,
    SavedTicket,
    setaAssignTicket,
  } = props
  const eventFeesFormSchema = AssignTicketsModal.validationSchema()

  const { reset, setValue, register, getValues } = useForm({
    resolver: yupResolver(eventFeesFormSchema),
    mode: 'onChange',
  })

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    try {
      if (!dataToPassOn.eventId) throw new Error(`event id is ${dataToPassOn.eventId}`)

      const eventService = new EventService(dataToPassOn.eventId as string)

      eventService
        .getEventKey('competitionStartDate')
        .then((competitionStartDate) => {
          setEventStartDate(competitionStartDate ?? null)
        })
        .catch((error) => {
          helpers.logger({
            message: CustomError.somethingWentWrong({
              devMessage: error,
              ...CUSTOM_ERROR_PROPS,
              moduleName: 'useEffect~getEventKey',
            }),
          })
        })
    } catch (error: any) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          devMessage: error?.message,
          ...CUSTOM_ERROR_PROPS,
          moduleName: 'useEffect',
        }),
      })
    }
  }, [dataToPassOn.eventId, setEventStartDate])

  const isDisabled = (index: number) => {
    if (assignTicket[index]?.ticketStatus === 'assigned') {
      return true
    } else {
      return false
    }
  }

  // const compareDate = () => {
  //     if (eventStartDate)
  //         return moment(eventStartDate)
  //     return false;
  // }

  const send_field = (index: number) => {
    return ((getValues()?.tickets?.[index]?.assignerEmail !== '' &&
      getValues()?.tickets?.[index]?.assignerEmail !== null) ||
      emailFieldError?.[index]?.email_message === '') &&
      ((getValues()?.tickets?.[index]?.assignerName !== '' &&
        getValues()?.tickets?.[index]?.assignerName !== null) ||
        nameFieldError?.[index]?.name_message === '')
      ? true
      : false
  }

  useEffect(() => {
    if (SavedTicket.length) {
      reset({
        tickets: [...SavedTicket],
      })
    }
  }, [reset, SavedTicket])

  useEffect(() => {
    if (check_validation.checkValidation) {
      const values = getValues()?.tickets ?? []
      values.forEach((curr: any, index: number) => {
        if ((selected_ticket as any)?.index === index) {
          validate_name_function(index, {
            name: curr?.assignerName ?? null,
          })
          validate_email_function(index, {
            email: curr?.assignerEmail ?? null,
          })
        }
      })
    }
    check_validation.setCheckValidation(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendTicket = async (
    index: number,
    ticket_data: IAssignedTicket,
    ticketTemplateUrl: any,
    registrationTicketId: any,
    sendTicket_id: any
  ) => {
    let registrationTicket: IRegistrationTicketInterface | null = null
    let spectatorTicket: ISpectatorTickets | null = null

    const isSpectatorTypeTicket = ticket_data.ticketType === 'spectator'
    const assignerName: string | null = getValues()?.tickets?.[index]?.assignerName
    const assignerEmail: string | null = getValues()?.tickets?.[index]?.assignerEmail

    let ticketBuyer: ITicketBuyer = props?.dataToPassOn?.registration?.ticket_Buyers

    try {
      const send_ticket_data = {
        assignerName,
        assignerEmail,
        ticket_status: 'assigned',
        eventId: dataToPassOn.eventId,
        sendTicket_id,
        ticketTemplateUrl,
      }

      const response = await httpService({
        data: send_ticket_data,
        url: 'sendtickets',
        method: 'post',
      })

      const { emptyVarName, emptyVarValue } = helpers.findEmptyVal({
        ...(!isSpectatorTypeTicket && { registrationTicketId }),
        eventId: dataToPassOn.eventId,
      })

      if (emptyVarName) {
        throw new Error(`${emptyVarName} is ${emptyVarValue}`)
      }

      if (response?.status) {
        if (ticket_data.ticketStatus === 'available') {
          if (isSpectatorTypeTicket === false) {
            const ticketSnapshot = await FirestoreService.getItem(
              COLLECTIONS.REGISTRATION_TICKET.NAME,
              registrationTicketId as any
            )

            if (!ticketSnapshot.exists())
              throw new Error(`registration ticket doesn't exist with id: ${registrationTicketId}`)

            registrationTicket = RegistrationTicketModel.fromFirestoreDoc(ticketSnapshot).toObject()

            await FirestoreService.updateItem(
              COLLECTIONS.REGISTRATION_TICKET.NAME,
              registrationTicketId as string,
              {
                sharedTicketsCount: (registrationTicket.sharedTicketsCount ?? 0) + 1,
                remainingTicketsCount: registrationTicket.remainingTicketsCount - 1,
              }
            )
          } else {
            const spectatorTicketSnapshot = await FirestoreService.getItem(
              COLLECTIONS.SPECTATOR_TICKETS.NAME,
              ticket_data.spectatorTicketDocId!
            )

            if (spectatorTicketSnapshot.exists()) {
              spectatorTicket =
                SpectatorTicketModel.fromFirestoreDoc(spectatorTicketSnapshot).cloneDeep()
              spectatorTicket.sharedTicketsCount = spectatorTicket.sharedTicketsCount + 1

              await FirestoreService.updateItem(
                COLLECTIONS.SPECTATOR_TICKETS.NAME,
                ticket_data.spectatorTicketDocId!,
                new SpectatorTicketModel(spectatorTicket).toFirestore()
              )
            }
          }

          const ticketBuyerSnapshot = await FirestoreService.getItem(
            COLLECTIONS.TICKET_BUYERS.NAME,
            ticketBuyer?.id!
          )

          ticketBuyer = TicketBuyersModel.fromFirestoreDoc(ticketBuyerSnapshot)

          await FirestoreService.updateItem(COLLECTIONS.TICKET_BUYERS.NAME, ticketBuyer?.id!, {
            unusedTicketCount: (ticketBuyer.unusedTicketCount ?? 0) - 1,
            usedTicketsCount: (ticketBuyer.usedTicketsCount ?? 0) + 1,
          })

          dispatch(
            updateKeysTickets({
              index,
              assignerName,
              assignerEmail,
              key_value: 'used',
              key_name: 'ticket_status',
            })
          )

          dispatch(
            updateKeysTickets(
              getConvertedData({
                index,
                key_name: 'assignDate',
                key_value: new Date(),
              })
            )
          )

          dataToPassOn?.decrementTicketCountFromTableRow?.(dataToPassOn.tableRowIndex)
          props?.decrementUnusedTicketCount?.()
        }

        toastFunction.success({
          message: MESSAGES_CONST.TICKET_SENT,
        })

        return true
      }
    } catch (error: any) {
      console.error(error)
      toastFunction.error({
        message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      return false
    }
  }

  const handelchangetab = async (args: {
    index: number
    data_index: number
    data: { registrationTicket: any; assignedTicket: IAssignedTicket }
  }) => {
    const { index, data_index, data } = args

    let sendin_finish: any[] = []
    let ticketTemplateUrl: string | null = null
    let assignedTicket = data.assignedTicket
    let registrationTicket = data.registrationTicket
    let assigner_name = getValues()?.tickets?.[data_index]?.assignerName
    let assigner_email = getValues()?.tickets?.[data_index]?.assignerEmail

    if (index === 3) {
      setEmailSenting([data_index])
    }

    if (index === 2) {
      check_validation.setCheckValidation(false)
    }

    if (index === 3) {
      let currentTicket = cloneDeep(assignTicket[data_index])

      if (assignedTicket.ticketType === 'register') {
        ticketTemplateUrl = (await handleTicketTemplate(
          assigner_name,
          assigner_email,
          registrationTicket,
          assignedTicket
        ))!
      }

      if (assignedTicket.ticketType === 'spectator') {
        ticketTemplateUrl = (await handleTicketTemplate(
          assigner_name,
          assigner_email,
          { ...assignedTicket, eventId: assignedTicket.eventDocId },
          assignedTicket
        ))!
      }

      const check_email_validation = validate_email_function(data_index, { email: assigner_email })
      const check_name_validation = validate_name_function(data_index, { name: assigner_name })

      if (check_email_validation?.status || check_name_validation?.status) {
        sendin_finish = emailSenting.filter((item) => {
          return item !== data_index
        })
        setEmailSenting([...sendin_finish])
        return null
      }

      currentTicket = {
        ...currentTicket,
        ticketHolderEmailId: assigner_email,
        ticketHolderName: assigner_name,
        ticketStatus: 'assigned',
        paymentStatus: 'paid',
        ticketBuyerName: user.userName ?? null,
        ticketBuyerEmailId: user.userEmail ?? null,
        ticketTemplateUrl: ticketTemplateUrl ?? null,
      }

      let docId: string | null = currentTicket.id ?? null

      if (docId) {
        await FirestoreService.updateItem(
          COLLECTIONS.ASSIGNED_TICKETS.NAME,
          docId,
          new AssignTicketsModal(currentTicket).toFirestore()
        )
      } else {
        docId = (
          await FirestoreService.createItem(
            COLLECTIONS.ASSIGNED_TICKETS.NAME,
            new AssignTicketsModal(currentTicket).toFirestore()
          )
        ).id
        currentTicket.id = docId
      }

      assignTicket[data_index] = currentTicket
      setaAssignTicket([...assignTicket])

      const send_status = await sendTicket(
        data_index,
        assignedTicket,
        ticketTemplateUrl,
        registrationTicket?.id,
        docId
      )

      if (!send_status) {
        let emailSenting_ = cloneDeep(emailSenting)
        emailSenting_.splice(index, 1)
        setEmailSenting(emailSenting_)
        return null
      } else {
        sendin_finish = emailSenting.filter((item) => {
          return item !== data_index
        })
        setEmailSenting([...sendin_finish])

        if (assignedTicket.ticketStatus === 'available') {
          assignedTicket = {
            ...assignedTicket,
            ticketHolderEmailId: assigner_email,
            ticketHolderName: assigner_name,
            ticketStatus: 'used',
            assignmentDate: new Date(),
          }
        }
      }
    }

    dispatch(setSelectedTicket(getConvertedData(assignedTicket)))

    dispatch(
      setAllTickets([
        ...assignTicket.map((item, index) => {
          return getConvertedData({
            ...item,
            ...getValues()?.tickets[index],
            ...(item.id === args.data.assignedTicket.id &&
              index === 3 && {
                ...assignedTicket,
              }),
          })
        }),
      ])
    )

    if (data.registrationTicket)
      dispatch(setSelectedRegistrationTicket(getConvertedData(data.registrationTicket)))

    handelTicketStep(2)
  }

  const validate_name_function = (index: number, { name }: { name: string }) => {
    const set_error: fieldNameError = {
      index,
      name_message: null,
    }
    let error_status = false

    if (name === '' || name == null) {
      error_status = true
      set_error.name_message = 'This is required Field'
    } else {
      set_error.name_message = ''
    }

    let include_status = false
    if (nameFieldError.length) {
      let err_arr = nameFieldError.map((item, index) => {
        if (index === set_error.index) {
          include_status = true
          return set_error
        } else {
          return item
        }
      })
      setNameFieldError(err_arr)
    }

    if (!include_status) {
      nameFieldError[set_error?.index] = set_error
      setNameFieldError([...nameFieldError])
    }

    return { status: error_status, set_error }
  }

  const validate_email_function = (index: number, { email }: { email: string }) => {
    const set_error: fieldEmailError = {
      index,
      email_message: null,
    }

    let error_status = false

    const email_regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    if (email === '' || email == null) {
      error_status = true
      set_error.email_message = 'This is required Field'
    } else if (!email_regex.test(email)) {
      error_status = true
      set_error.email_message = 'Please enter valid email'
    } else {
      set_error.email_message = ''
    }
    let include_status = false
    if (emailFieldError.length) {
      let err_arr = emailFieldError.map((item, index) => {
        if (index === set_error.index) {
          include_status = true
          return set_error
        } else {
          return item
        }
      })
      setEmailFieldError(err_arr)
    }

    if (!include_status) {
      emailFieldError[set_error?.index] = set_error
      setEmailFieldError([...emailFieldError])
    }

    return { status: error_status, set_error }
  }

  useEffect(() => {
    assignTicket?.forEach((data, index) => {
      setValue(`tickets[${index}].assignerName`, data.ticketHolderName)
      setValue(`tickets[${index}].assignerEmail`, data.ticketHolderEmailId)
    })
  }, [assignTicket, setValue])

  const toastFunctions = useToasterHelper()
  const fillOutAllFields = () => {
    toastFunctions.info({
      message: 'Must assign ticket first',
    })
  }

  return (
    <>
      <div className="modal-header flex flex-shrink-0 justify-between rounded-t-md">
        <h5
          className="text-xl font-semibold leading-normal text-SeabiscuitDark200ThemeColor pb-6"
          id="exampleModalScrollableLabel"
        >
          Tickets
        </h5>
        <div
          className="text-SeabiscuitDark200ThemeColor cursor-pointer opacity-40 pt-1"
          onClick={() => setListView(!listView)}
        >
          {!listView ? 'List View' : 'Card View'}{' '}
          <span style={{ display: 'inline-block', transform: 'rotate(90deg)' }}>&gt;</span>
        </div>
      </div>
      <div className="w-full">
        <div className="w-full flex sticky top-[-19px]"></div>
        {!listView ? (
          <div className="grid grid-cols-4 gap-4">
            {loading ? (
              <div className="flex justify-center pt-[40px]">
                <ViewsLoader color="#ff2d55" size="lg" />
              </div>
            ) : (
              <>
                {assignTicket?.map((data, index) => {
                  return (
                    <div
                      key={[JSON.stringify(data), index].join()}
                      className={`text-SeabiscuitDark200ThemeColor text-[14px] border border-solid border-[#D3DAEE] rounded-lg px-4 ${isDisabled(index) || data.ticketStatus === 'assigned' || data.ticketStatus === 'used' ? 'bg-[#F6F7FB] ' : ''}`}
                    >
                      <div className="font-semibold text-[16px] leading-9 mt-2 mb-1">
                        {data?.ticketTitle ? data?.ticketTitle : 'N/A'}
                      </div>
                      <div className="flex gap-6 mb-2 items-center">
                        <img src="/assets/cp_icons/User-1.svg" alt="User" />
                        <div className="flex gap-1">
                          <div>
                            {data?.ticketBuyerName} • <span className="text-gray-400">Buyer</span>
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-6 mb-2 items-center">
                        <img src="/assets/cp_icons/MagneticCard-1.svg" alt="creditcard" />
                        <div>
                          ${data?.ticketPrice} • <span className="text-gray-400">Price</span>{' '}
                        </div>
                      </div>
                      <div className="flex gap-6 mb-2 items-center">
                        <img src="/assets/cp_icons/Info-1.svg" alt="calendar" />
                        <div
                          className="underline cursor-pointer"
                          onClick={() => {
                            handelchangetab({
                              index: 2,
                              data_index: index,
                              data: {
                                registrationTicket:
                                  props.dataToPassOn.tickets.find(
                                    (Tdata: any) => Tdata.ticketItemId === data.ticketItemId
                                  ) ?? null,
                                assignedTicket: data,
                              },
                            })
                          }}
                        >
                          More Info / Print Ticket {'>'}
                        </div>
                      </div>
                      <div className="flex justify-center text-[#D3DAEE]">
                        ------------------------------
                      </div>
                      <div className="flex justify-between mb-3 mt-1">
                        <div className="font-medium">Ticket Holder</div>
                        <div
                          className={`${data.ticketStatus === 'assigned' || data.ticketStatus === 'used' ? 'text-[#00b6aa]' : 'text-SeabiscuitMainThemeColor'}`}
                        >
                          {' '}
                          {data.ticketStatus === 'assigned' || data.ticketStatus === 'used'
                            ? 'Assigned'
                            : 'Unassigned'}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div className="flex gap-2">
                          {/* <div>Name: </div> */}
                          <div className="w-full">
                            <input
                              className={`w-full border-b pl-2 capitalize`}
                              {...register(`tickets[${index}].assignerName`, {
                                onChange: (e) => {
                                  validate_name_function(index, { name: e.target.value })
                                  setValue(`tickets[${index}].assignerName`, e.target.value)
                                },
                              })}
                              disabled={isDisabled(index)}
                              placeholder="Enter name"
                              defaultValue={data.ticketHolderName as any}
                              style={{ outline: 'none' }}
                            />
                            {nameFieldError.length &&
                            nameFieldError?.[index]?.name_message != null ? (
                              <MessageHelperComp
                                isError={true}
                                message={nameFieldError?.[index]?.name_message || ''}
                                className="ml-1"
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mb-5">
                        <div className="flex gap-2">
                          {/* <div className="pr-1">Email: </div> */}
                          <div className="w-full">
                            <input
                              className={`w-full border-b pl-2`}
                              {...register(`tickets[${index}].assignerEmail`, {
                                onChange: (e) => {
                                  validate_email_function(index, { email: e.target.value })
                                  setValue(`tickets[${index}].assignerEmail`, e.target.value)
                                },
                              })}
                              disabled={isDisabled(index)}
                              defaultValue={data.ticketHolderEmailId as any}
                              style={{ outline: 'none' }}
                              placeholder="Enter Email"
                            />
                            {emailFieldError.length &&
                            emailFieldError?.[index]?.email_message != null ? (
                              <MessageHelperComp
                                isError={true}
                                message={emailFieldError?.[index]?.email_message || ''}
                                className="ml-1"
                              />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>

                      {assigned_user !== true ? (
                        <div
                          className={`w-full mb-5 relative cursor-pointer text-nr text-[#122B46]-900  border rounded-md !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor p-2 py-3 border-solid flex items-center justify-center ${isDisabled(index) || data.ticketStatus === 'assigned' || data.ticketStatus === 'used' ? 'bg-[#F6F7FB] border-SeabiscuitDark200ThemeColor' : ''}`}
                          onClick={() => {
                            if (send_field(index) && emailSenting.indexOf(index) === -1) {
                              handelchangetab({
                                index: 3,
                                data_index: index,
                                data: {
                                  registrationTicket:
                                    props.dataToPassOn.tickets.find(
                                      (Tdata: any) => Tdata.ticketItemId === data.ticketItemId
                                    ) ?? null,
                                  assignedTicket: data,
                                },
                              })
                            } else if (emailSenting.indexOf(index) === -1) {
                              fillOutAllFields()
                            }
                          }}
                        >
                          <div className="flex items-center justify-center">
                            {send_field(index) ||
                            isDisabled(index) ||
                            data.ticketStatus === 'used' ? (
                              <img
                                className="absolute left-2"
                                src="/assets/cp_icons/Mail-1.svg"
                                alt="mail"
                              />
                            ) : (
                              <img
                                className="absolute left-2"
                                src="/assets/cp_icons/Mail.svg"
                                alt="mail"
                              />
                            )}
                            <span className="!text-[#122B46] text-nr">
                              {emailSenting.indexOf(index) !== -1 ? (
                                'Sending...'
                              ) : send_field(index) || isDisabled(index) ? (
                                <>
                                  {isDisabled(index) ||
                                  data.ticketStatus === 'assigned' ||
                                  data.ticketStatus === 'used'
                                    ? 'Resend Ticket'
                                    : 'Assign & Send'}
                                </>
                              ) : (
                                <div className="text-[#939da8]">Assign & Send</div>
                              )}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )
                })}
              </>
            )}
          </div>
        ) : (
          <div className="w-full">
            {loading ? (
              <div className="flex justify-center pt-[40px]">
                <ViewsLoader color="#ff2d55" size="lg" />
              </div>
            ) : (
              <>
                {assignTicket?.map((data, index) => {
                  return (
                    <div
                      key={[JSON.stringify(data), index].join()}
                      className={`w-full flex ${(nameFieldError.length || emailFieldError.length) && (emailFieldError?.[index] || nameFieldError?.[index]) ? ' items-start' : ''}`}
                    >
                      <div
                        className={`flex h-[3.3rem] justify-between cursor-pointer text-[#122B46] border-r-0 min-w-[200px] w-[48%] mb-2 text-nr text-[#122B46]-900  border border-[#D3DAEE] rounded-l-md !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor p-3.5 ${isDisabled(index) || data.ticketStatus === 'assigned' || data.ticketStatus === 'used' ? 'bg-[#F6F7FB] ' : ''}`}
                        onClick={() => {
                          handelchangetab({
                            index: 2,
                            data_index: index,
                            data: {
                              registrationTicket:
                                props.dataToPassOn.tickets.find(
                                  (Tdata: any) => Tdata.ticketItemId === data.ticketItemId
                                ) ?? null,
                              assignedTicket: data,
                            },
                          })
                        }}
                      >
                        <div>
                          <span className="font-medium">
                            {data?.ticketTitle ? data?.ticketTitle : 'N/A'}
                          </span>{' '}
                          •{' '}
                          <span
                            className={`${data.ticketStatus === 'assigned' || data.ticketStatus === 'used' ? 'text-[#00b6aa]' : 'text-SeabiscuitMainThemeColor'}`}
                          >
                            {' '}
                            {data.ticketStatus === 'assigned' || data.ticketStatus === 'used'
                              ? 'Assigned'
                              : 'Unassigned'}
                          </span>
                        </div>
                      </div>

                      <div
                        className={`h-[3.3rem] mb-2 text-nr !text-[#122B46] border-l-0 border-r-0 outline-0 border !ring-0 flex items-center disabled:bg-[#F6F7FB] pr-2 text-center border-[#D3DAEE] focus:border-[#122B46] capitalize ${isDisabled(index) || data.ticketStatus === 'assigned' || data.ticketStatus === 'used' ? 'bg-[#F6F7FB]' : ''}`}
                      >
                        Recipient:{' '}
                      </div>
                      <div className="flex-col h-11 w-[22%]">
                        <input
                          className={`w-full h-[3.3rem] mb-2 text-nr !text-[#122B46] border-l-0 border-r-0 outline-0 border !ring-0 disabled:bg-[#F6F7FB] !border-[#D3DAEE] focus:border-[#122B46] capitalize ${nameFieldError.length && nameFieldError?.[index]?.name_message != null ? 'mb-0' : ''}`}
                          {...register(`tickets[${index}].assignerName`, {
                            onChange: (e) => {
                              validate_name_function(index, { name: e.target.value })
                              setValue(`tickets[${index}].assignerName`, e.target.value)
                            },
                          })}
                          disabled={
                            isDisabled(index) ||
                            data.ticketStatus === 'assigned' ||
                            data.ticketStatus === 'used'
                          }
                          placeholder="Enter name"
                          style={{ outline: 'none' }}
                          defaultValue={data.ticketHolderName as any}
                        />
                        {nameFieldError.length && nameFieldError?.[index]?.name_message != null ? (
                          <MessageHelperComp
                            isError={true}
                            message={nameFieldError?.[index]?.name_message || ''}
                            className="ml-1"
                          />
                        ) : (
                          ''
                        )}
                      </div>

                      {/* <div className="w-[20%] min-w-[200px] flex"> */}

                      <div
                        className={`h-[3.3rem] mb-2 text-nr !text-[#122B46] border-l-0 border-r-0 outline-0 border !ring-0 flex items-center disabled:bg-[#F6F7FB] pr-2 text-center border-[#D3DAEE] focus:border-[#122B46] capitalize ${isDisabled(index) || data.ticketStatus === 'assigned' || data.ticketStatus === 'used' ? 'bg-[#F6F7FB]' : ''}`}
                      >
                        Email:{' '}
                      </div>
                      <div className="flex-col w-[22%]">
                        <input
                          className={`w-full flex-col h-[3.3rem] outline-none mb-2 text-nr !text-[#122B46] outline-0 border border-l-0 border-r-0 !ring-0  p-4 disabled:bg-[#F6F7FB] !border-[#D3DAEE] focus:border-[#122B46]
                                                    ${emailFieldError.length && emailFieldError?.[index]?.email_message != null ? 'mb-0 ' : ''}`}
                          {...register(`tickets[${index}].assignerEmail`, {
                            onChange: (e) => {
                              validate_email_function(index, { email: e.target.value })
                              setValue(`tickets[${index}].assignerEmail`, e.target.value)
                            },
                          })}
                          disabled={
                            isDisabled(index) ||
                            data.ticketStatus === 'assigned' ||
                            data.ticketStatus === 'used'
                          }
                          defaultValue={data.ticketHolderEmailId as any}
                          style={{ outline: '#D3DAEE' }}
                          placeholder="Enter email"
                        />
                        {emailFieldError.length &&
                        emailFieldError?.[index]?.email_message != null ? (
                          <MessageHelperComp
                            isError={true}
                            message={emailFieldError?.[index]?.email_message || ''}
                            className="ml-1"
                          />
                        ) : (
                          ''
                        )}
                      </div>

                      {/* </div> */}

                      {assigned_user !== true ? (
                        <div
                          className={`w-[12%] h-[3.3rem] min-w-[150px] mb-2 cursor-pointer text-nr text-[#122B46]-900  border border-[#D3DAEE] border-l-0 rounded-md rounded-l-none !focus:ring-SeabiscuitMainThemeColor !focus:border-SeabiscuitMainThemeColor p-3.5 border-solid flex items-center justify-start ${isDisabled(index) || data.ticketStatus === 'assigned' || data.ticketStatus === 'used' ? 'bg-[#F6F7FB]' : ''}`}
                          onClick={() => {
                            if (
                              send_field(index) &&
                              !isDisabled(index) &&
                              emailSenting.indexOf(index) === -1
                            ) {
                              handelchangetab({
                                index: 3,
                                data_index: index,
                                data: {
                                  registrationTicket:
                                    props.dataToPassOn.tickets.find(
                                      (Tdata: any) => Tdata.ticketItemId === data.ticketItemId
                                    ) ?? null,
                                  assignedTicket: data,
                                },
                              })
                            } else if (emailSenting.indexOf(index) === -1) {
                              handelchangetab({
                                index: 2,
                                data_index: index,
                                data: {
                                  registrationTicket:
                                    props.dataToPassOn.tickets.find(
                                      (Tdata: any) => Tdata.ticketItemId === data.ticketItemId
                                    ) ?? null,
                                  assignedTicket: data,
                                },
                              })
                            }
                          }}
                        >
                          {send_field(index) || isDisabled(index) ? (
                            <img src="/assets/cp_icons/Mail-1.svg" alt="mail" />
                          ) : (
                            <img src="/assets/cp_icons/Mail.svg" alt="mail" />
                          )}
                          <span className="!text-[#122B46] text-nr ml-3">
                            {emailSenting.indexOf(index) !== -1 ? (
                              'Sending...'
                            ) : send_field(index) || isDisabled(index) ? (
                              <>
                                {isDisabled(index) ||
                                data.ticketStatus === 'assigned' ||
                                data.ticketStatus === 'used'
                                  ? 'Resend Tix'
                                  : 'Send Tix'}
                              </>
                            ) : (
                              <div className="text-[#939da8]">Send Tix</div>
                            )}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  )
                })}
              </>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default SendTicketComponent
