// ############################################################
/**
 * Contains the model to hold the events (and perform migrations
 * if it comes the case)
 */
// ############################################################

import { CONST } from '../../const/const'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { ITicketBuyer } from './ticket-buyers.interface'

const COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.TICKET_BUYERS

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */
export class TicketBuyersModel extends ModelBaseModel<ITicketBuyer> implements IModelBaseModel {
  public v: number
  public id: string
  public eventId: ITicketBuyer['eventId']
  public amountPaid: ITicketBuyer['amountPaid']
  public isPlatformUser: ITicketBuyer['isPlatformUser']
  public spectatorTicketsIds: ITicketBuyer['spectatorTicketsIds']
  public deletedSpectatorTicketsIds: ITicketBuyer['deletedSpectatorTicketsIds']
  public usedTicketsCount: ITicketBuyer['usedTicketsCount']
  public ticketBuyerNameNGrams: ITicketBuyer['ticketBuyerNameNGrams']
  public refundStatus: ITicketBuyer['refundStatus']
  public paymentStatus: ITicketBuyer['paymentStatus']
  public receipetUrl: ITicketBuyer['receipetUrl']
  public ticketBuyerEmailId: ITicketBuyer['ticketBuyerEmailId']
  public purchasedOn: ITicketBuyer['purchasedOn']
  public registrationDocId: ITicketBuyer['registrationDocId']
  public recipientDocId: ITicketBuyer['recipientDocId']
  public unusedTicketCount: ITicketBuyer['unusedTicketCount']
  public ticketBoughtCount: ITicketBuyer['ticketBoughtCount']
  public userId: ITicketBuyer['userId']
  public ticketBuyerName: ITicketBuyer['ticketBuyerName']
  public amountRefunded: ITicketBuyer['amountRefunded']
  public ticketBuyerProfilePicture: ITicketBuyer['ticketBuyerProfilePicture']
  public type: ITicketBuyer['type']
  public created: ITicketBuyer['created']
  public modified: ITicketBuyer['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * Constructs an instance of the class with an object that adheres to the
   * IUserInterface interface
   *
   * @param obj
   * Object that adheres to the IUserInterface interface
   */
  public constructor(obj?: ITicketBuyer) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? ''
    this.eventId = obj?.eventId ?? null
    this.amountPaid = this.getNum(obj?.amountPaid)
    this.isPlatformUser = obj?.isPlatformUser ?? false
    this.registrationDocId = obj?.registrationDocId ?? null
    this.ticketBuyerEmailId = obj?.ticketBuyerEmailId ?? null
    this.recipientDocId = obj?.recipientDocId ?? null
    this.paymentStatus = obj?.paymentStatus ?? COLLECTION.FIELDS.PAYMENT_STATUS.VALUES.DEFAULT
    this.refundStatus = obj?.refundStatus ?? COLLECTION.FIELDS.REFUND_STATUS.VALUES.DEFAULT
    this.amountRefunded = obj?.amountRefunded ?? COLLECTION.FIELDS.AMOUNT_REFUNDED.VALUES.DEFAULT
    this.usedTicketsCount = obj?.usedTicketsCount ?? 0
    this.purchasedOn = obj?.purchasedOn ?? null
    this.receipetUrl = obj?.receipetUrl ?? null
    this.spectatorTicketsIds = obj?.spectatorTicketsIds ?? []
    this.ticketBuyerNameNGrams = this.getCalculatedNGrams(obj?.ticketBuyerName)
    this.deletedSpectatorTicketsIds = obj?.deletedSpectatorTicketsIds ?? []
    this.unusedTicketCount = obj?.unusedTicketCount ?? 0
    this.ticketBoughtCount = obj?.ticketBoughtCount ?? 0
    this.userId = obj?.userId ?? null
    this.ticketBuyerName = obj?.ticketBuyerName ?? null
    this.ticketBuyerProfilePicture = !!obj?.ticketBuyerProfilePicture
      ? obj?.ticketBuyerProfilePicture
      : null
    this.type = obj?.type ?? null

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      value: obj?.created as Date,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.modified as Date,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new TicketBuyersModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
