import { PDFDocument } from 'pdf-lib'

import { convertDateString, createTable, savePdf } from '../../../../helpers/pdf'
import { IEventInterface } from '../../../../models/events/event.interface'

export const createOrderOfGoByDayPdf = async (event: IEventInterface, rows: string[][]) => {
  // Create a new PDF document
  const pdfDoc = await PDFDocument.create()

  const headers = ['Rider • Coach', 'Horse • Trainer', 'Class', 'Ride time']
  const colWidths = [20, 20, 40, 20] // in %

  const printedText = `^^By Day • Printed ${convertDateString(new Date(), 'tdm')}^^`
  const titleText = 'Order of Go'
  const urlText = 'www.thepegasus.app'

  for (const [index, key] of Object.keys(rows).entries()) {
    const textAboveTheFirstTable = `**${key}** • ${rows[key as any].length} rides`

    await createTable({
      pdfDoc,
      headers,
      rows: rows[key as any] as unknown as string[][],
      colWidths,
      printedText: index === 0 ? printedText : undefined,
      titleText: index === 0 ? titleText : undefined,
      urlText: index === 0 ? urlText : undefined,
      textAboveTheFirstTable,
      event,
    })
  }

  await savePdf(pdfDoc, `Event Reports - Order of Go by day - ${event?.eventName}.pdf`)
}
