import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import {
  EmailAuthProvider,
  getAuth,
  signInWithCredential,
  signInWithCustomToken,
} from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import { CONST } from '../../../const/const'
import FIREBASE_CONST from '../../../const/firebase-const'
import { MESSAGES_CONST } from '../../../const/messages-const'
import { MODAL_CONSTS } from '../../../const/modal-const'
import MessageHelperComp from '../../../helpers/MessageHelper'
import useToasterHelper from '../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../helpers/helpers'
import { IUserInterface } from '../../../models/users/user.interface'
import { UserModel } from '../../../models/users/user.model'
import FirestoreService from '../../../services/firestoreService'
import { httpService } from '../../../services/httpService'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { storeUseMode } from '../../../store/system/systemThunk'
import {
  clearUserStoredData,
  selectUserId,
  setCompetitionProfileImageUrl,
  setDisplayName,
  setEmail,
  setUserId,
  setUsername,
  storeUserId,
} from '../../../store/user/userSlice'
import customImageComponent from '../../common/CustomImageComponent'
import DataNotAvailable from '../../common/alerts/data-not-available/DataNotAvailable'
import MainModal from '../common/MainModal'
import { setUserMode } from '../../../store/system/systemSlice'
import { setActiveFilters } from '../../../store/filters/filterSlice'
import FirebaseApp from '../../../services/firebaseApp'
import { Visibility, VisibilityOffOutlined } from '@mui/icons-material'

type IProps = {
  show: boolean
  handleModal: (show: boolean, modal_name: string, data?: any) => void
  dataToPassOn: any
}

type IProfile = {
  name: string
  userDocId: string
  userProfilePicture: string | null
  userType: IUserInterface['userType'] | null
}

const formschema = yup.object().shape({
  formData: yup.object().shape({
    email: yup.string().email('provide valid email').required('This field is required'),
    password: yup.string().required('This field is required'),
  }),
  message: yup.string().nullable(),
  selectedProfileIndex: yup.number().nullable(),
  profiles: yup
    .array(
      yup.object().shape({
        userDocId: yup.string(),
        name: yup.string().nullable(),
        userType: yup.string().nullable(),
        userProfilePicture: yup.string().nullable(),
      })
    )
    .nullable(),
})

const FORM_DEFAULT = {
  formData: {
    email: '',
    password: '',
  },
  message: null,
  selectedProfileIndex: null as number | null,
  profiles: [] as IProfile[],
}

const SwitchAccounts = (props: IProps) => {
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(true)
  const [addingAccount, setAddingAccount] = useState(false)
  const [switchingAccount, setSwitchingAccount] = useState(false)

  const userId = useAppSelector(selectUserId)
  const [innerType, setInnerType] = useState('password')

  const history = useHistory()
  const dispatch = useAppDispatch()
  const toastFunctions = useToasterHelper()

  const {
    watch,
    register,
    setValue,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(formschema),
    defaultValues: { ...FORM_DEFAULT },
  })

  const { append, fields } = useFieldArray({
    control,
    name: 'profiles',
  })

  useEffect(() => {
    if (userId) fetchProfiles()
    else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  const fetchProfiles = async () => {
    setLoading(true)

    try {
      const res = await httpService({
        url: `get_user_profiles`,
        method: 'POST',
        data: {},
      })

      if (res.status) {
        setValue('message', res.message ?? null)
        setValue('profiles', res.data?.profiles ?? [])
      }
    } catch (error: any) {
      toastFunctions.error({ message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG })
    }

    setLoading(false)
  }

  const onSwitchAccountButtonClick = async () => {
    try {
      if (typeof watch('selectedProfileIndex') !== 'number') {
        throw new Error('Please select a profile to proceed further')
      }

      setSwitchingAccount(true)

      const res = await httpService({
        url: `get_switch_profile_token`,
        method: 'POST',
        data: {
          uid: fields[watch('selectedProfileIndex')!].userDocId,
        },
      })

      const auth = getAuth()
      const userCredentials = await signInWithCustomToken(auth, res.data.token)

      const user_data_doc = await FirestoreService.getItem(
        CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.USERS.NAME,
        userCredentials.user.uid
      )

      const userData = UserModel.fromFirestoreDoc(user_data_doc).toObject()

      dispatch(setEmail(userData.userEmail?.trim()))
      dispatch(setUsername(userData.userName?.trim()))
      dispatch(setDisplayName(getUserFullName(userData)))
      dispatch(setCompetitionProfileImageUrl(userData.userProfilePicture))

      if (userData.userType === CONST.USE_MODE.COMPETITOR) {
        dispatch(storeUseMode(CONST.USE_MODE.COMPETITOR))
        history.replace('/home', { direction: 'none' })
      } else if (userData.userType === CONST.USE_MODE.ORGANIZER) {
        dispatch(storeUseMode(CONST.USE_MODE.ORGANIZER))
        history.replace(`${CONST.ROUTES.ORGANIZER_HOME.URL}`, { direction: 'none' })
      }

      dispatch(storeUserId(userCredentials.user.uid))
      dispatch(setUserId(userCredentials.user.uid))

      await auth.updateCurrentUser(userCredentials.user)

      props.handleModal(false, MODAL_CONSTS.SWITCH_ACCOUNT)
    } catch (error: any) {
      toastFunctions.error({ message: error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG })
    } finally {
      setSwitchingAccount(false)
    }
  }

  const handleLogout = async () => {
    dispatch(setUserMode(null))
    dispatch(clearUserStoredData(true))
    dispatch(setActiveFilters(null))
    await FirebaseApp.auth.signOut()
    window.location.replace(CONST.ROUTES.LOGOUT.URL)
  }

  const onSubmit = async (data: any) => {
    data = data.formData

    setAddingAccount(true)

    try {
      const auth = getAuth()
      const user = auth.currentUser
      const credential = EmailAuthProvider.credential(data.email, data.password)

      const updatedUser = await signInWithCredential(auth, credential)

      await auth.updateCurrentUser(user)

      const res = await httpService({
        url: `add_profile`,
        method: 'POST',
        data: {
          uid: updatedUser.user.uid,
        },
      })

      if (res.status) {
        append(res?.data?.profile)

        toastFunctions.success({
          message: MESSAGES_CONST.ACCOUNT_ADDED,
        })

        setStep(0)
      }
    } catch (error: any) {
      console.error(error)

      let message = MESSAGES_CONST.SOMETHING_WENT_WRONG

      if (error.code === FIREBASE_CONST.USER_NOT_FOUND) {
        message = "This account doesn't exists. Please sign up or try again"
      } else if (error.code === FIREBASE_CONST.WRONG_PASSWORD) {
        message = 'Credentials are incorrect'
      } else if (error.code === FIREBASE_CONST.TOO_MANY_REQUESTS) {
        message = 'Firebase login Maximum attempts reached'
      }
      if (error instanceof AxiosError) {
        message = error?.response?.data?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG
      } else {
        message = error?.message ?? MESSAGES_CONST.SOMETHING_WENT_WRONG
      }

      toastFunctions.error({ message })
    } finally {
      setAddingAccount(false)
    }
  }

  const [selected, setSelected] = useState(false)

  return (
    <MainModal
      show={props.show}
      title="Switch accounts"
      type="SWITCH_ACCOUNT"
      setHeightAsPerContent={true}
      className="!px-0 !my-8"
      size="md"
      buttons={[
        ...(step === 0
          ? [
              {
                label: 'SWITCH',
                bgClass: 'bg-SeabiscuitMainThemeColor',
                textClass: 'text-white',
                className: '!md:max-w-[400px] !w-[50%] m-4 !h-[48px]',
                onClick: onSwitchAccountButtonClick,
                loading: switchingAccount,
                disabled: typeof watch('selectedProfileIndex') !== 'number',
              },
              // {
              //     label: "SIGN OUT",
              //     bgClass: "!bg-transparent",
              //     textClass: "!text-SeabiscuitMainThemeColor",
              //     borderClass: "border border-solid !border-SeabiscuitMainThemeColor",
              //     className: "!md:max-w-[400px] !w-[80%] m-4 !h-[48px]",
              //     onClick: handleLogout,
              //     loading: addingAccount
              // },
            ]
          : []),
        ...(step === 1 && selected === true
          ? [
              {
                label: 'ADD ACCOUNT',
                bgClass: '!bg-SeabiscuitMainThemeColor',
                textClass: 'text-white',
                borderClass: 'border border-solid !border-SeabiscuitMainThemeColor',
                className: '!md:max-w-[400px] !w-[50%] m-4 !h-[48px]',
                onClick: () => {
                  handleSubmit(onSubmit)()
                },
                loading: addingAccount,
              },
            ]
          : []),
        {
          label: 'CANCEL',
          className: '!md:max-w-[400px] !w-[50%] m-4 !h-[48px]',
          bgClass: '!bg-SeabiscuitLightThemeColor',
          borderClass: 'border border-transparent',
          textClass: '!text-SeabiscuitLightTextColor',
          onClick: () =>
            step === 1 ? setStep(0) : props.handleModal(false, MODAL_CONSTS.SWITCH_ACCOUNT),
        },
      ]}
    >
      <div>
        {step === 0 ? (
          <div
            className={clsx(
              'flex flex-col h-[250px] overflow-auto gap-5',
              loading && 'animate-pulse'
            )}
          >
            {fields.length || loading ? (
              [...(loading ? new Array(3) : fields)].map((currProfile: IProfile, index) => {
                return (
                  <div
                    role="button"
                    onClick={() => {
                      setValue(
                        'selectedProfileIndex',
                        watch('selectedProfileIndex') === index ? null : index
                      )
                    }}
                    className="w-full flex gap-3 min-h-[40px] cursor-pointer items-center"
                    key={`profile${index}`}
                  >
                    <div
                      className={clsx(
                        'w-10 h-10 shrik-0 rounded-full',
                        loading && 'rounded-md bg-SeabiscuitSkeletonColor'
                      )}
                    >
                      {!loading
                        ? customImageComponent(
                            currProfile?.userProfilePicture,
                            currProfile?.name,
                            'w-full h-full object-cover rounded-full'
                          )
                        : null}
                    </div>
                    <div
                      className={clsx(
                        'flex flex-col gap-[2px]',
                        loading ? 'w-[calc(100%-53px)]' : 'w-[calc(100%-90px)]'
                      )}
                    >
                      <div
                        className={clsx(
                          'text-nr text-SeabiscuitDarkThemeColor capitalize min-h-[21px]',
                          loading && 'rounded-md bg-SeabiscuitSkeletonColor'
                        )}
                      >
                        {currProfile?.name}
                      </div>
                      <div
                        className={clsx(
                          'text-sm text-SeabiscuitGray600ThemeColor capitalize min-h-[20px]',
                          loading && 'rounded-md bg-SeabiscuitSkeletonColor'
                        )}
                      >
                        {currProfile?.userType}
                      </div>
                    </div>
                    {watch('selectedProfileIndex') === index && !loading ? (
                      <img src="/assets/og_icons/Ok-3.svg" className="w-6 h-6" alt="tick" />
                    ) : null}
                  </div>
                )
              })
            ) : (
              <DataNotAvailable mode="text" text={watch('message') ?? 'N/A'} />
            )}

            {!loading ? (
              <button
                onClick={() => {
                  setStep(1)
                }}
                className="my-3 text-SeabiscuitMainThemeColor font-normal text-sm flex justify-center items-center gap-2"
              >
                <img
                  src={`/assets/og_icons/Cancel-2.svg`}
                  className="w-5 h-5 rotate-45"
                  alt="plus icon"
                />
                <span>Add Account</span>
              </button>
            ) : null}
          </div>
        ) : null}

        {step === 1 ? (
          <form className="flex flex-col justify-between py-3" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="AccountEmail flex flex-wrap items-center ">
                <div className="flex-grow mx-14">
                  <label className="mb-3 !text-SeabiscuitDark200ThemeColor flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-SeabiscuitLightThemeColorD3 border-[1px] bg-transparent">
                    <span className="w-[22px]">
                      <img src="/assets/og_icons/Mail-1.svg" alt="icon" className="" />
                    </span>
                    <input
                      {...register('formData.email')}
                      placeholder="Enter Email"
                      className="flex-1 border-0 outline-0 !text-SeabiscuitDark200ThemeColor !bg-white placeholder:text-SeabiscuitDark200ThemeColor w-full focus:border-none p-2"
                    />
                  </label>
                  {errors.formData?.email && (
                    <MessageHelperComp isError={true} message={errors.formData?.email.message} />
                  )}
                </div>
              </div>

              <div className="AccountEmail flex flex-wrap items-center">
                <div className="flex-grow mx-14">
                  <label className="mb-3 text-SeabiscuitDark200ThemeColor flex items-center gap-2 w-full px-4 py-2 border-solid rounded-xl border-SeabiscuitLightThemeColorD3 border-[1px] bg-transparent">
                    <span>
                      <img src="/assets/og_icons/Lock-1.svg" alt="icon" className="" />
                    </span>
                    <input
                      type={innerType}
                      {...register('formData.password')}
                      placeholder="Enter Password"
                      className="flex-1 border-0 outline-0 !text-SeabiscuitDark200ThemeColor bg-transparent placeholder:text-SeabiscuitDark200ThemeColor focus:ring-transparent w-full focus:border-none p-2"
                    />
                    <div
                      onClick={() => setInnerType(innerType === 'password' ? 'text' : 'password')}
                    >
                      {innerType === 'password' ? (
                        <VisibilityOffOutlined className="text-SeabiscuitDark200ThemeColor" />
                      ) : (
                        <Visibility className="text-SeabiscuitDark200ThemeColor" />
                      )}
                    </div>
                  </label>
                  {errors.formData?.password && (
                    <MessageHelperComp isError={true} message={errors.formData?.password.message} />
                  )}
                </div>
              </div>
            </div>
            {/* <div className="text-SeabiscuitDark200ThemeColor ">
                                    <input type="checkbox" id="checkbox" />
                                    <label htmlFor="checkbox" className="pl-4 text-[12px]">By ticking this box I confirm that I have been given permission to access this account by the account owner, and I accept that I am personally responsible for this accounts activity.</label>
                                </div> */}

            <div className="displineCheckBoxfilter mx-14 flex">
              <input
                className="form-check-input rounded_checkboxes appearance-none h-4 w-4 border border-[#D3DAEE] bg-white checked:bg-pink-600 checked:border-none focus:outline-none focus:ring-0 focus:ring-offset-0 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                name="checkbox"
                checked={selected}
                id="checkbox"
                onChange={(e) => setSelected(e.target.checked)}
              />
              <label
                htmlFor="checkbox"
                className="labelFilter cursor-pointer text-SeabiscuitDark200ThemeColor text-sm"
              >
                By ticking this box I confirm that I have been given permission to access this
                account by the account owner, and I accept that I am personally responsible for this
                accounts activity.
              </label>
            </div>
          </form>
        ) : null}
      </div>
    </MainModal>
  )
}

export default SwitchAccounts
