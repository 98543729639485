import IOrganizerSliceType from './organizerSlice.types'

const EXHIBITOR_TAB: IOrganizerSliceType['IExhibitorTab'] = {
  data: [],
  temp: [],
  batch: [],
  message: null,
  status: 'idle',
  hasMore: false,
  cursorId: null,
  docsCountInDb: 0,
  batchRunning: false,
  fetchedDocsCount: 0,
  query: {
    perPage: 20,
    orderBy: null,
    orderDirection: null,
  },
}

const INITIAL_STATE: IOrganizerSliceType['IInitialState'] = {
  manageEvent: {
    lists: {
      exhibitors: {
        activeTab: 'all',
        tabs: {
          all: { ...EXHIBITOR_TAB },
          scratch: { ...EXHIBITOR_TAB },
          complaint: { ...EXHIBITOR_TAB },
          'non-complaint': { ...EXHIBITOR_TAB },
        },
      },
      staffs: [],
    },
  },
  registrationByDayId: null,
  currentEntries: [],
}

const ORGANIZER_SLICE_CONST = {
  INITIAL_STATE,
}

export default ORGANIZER_SLICE_CONST
