import React, { useContext, useState } from 'react'
import { MODAL_CONSTS } from '../../const/modal-const'
import helpers from '../../commonHelpers/helpers'
import { IconClasses } from '../icons/IconClasses'
import { generateName } from '../../helpers/helpers'
import { IconPaid } from '../icons/IconPaid'
import { IconError } from '../icons/IconError'
import MainModal from './common/MainModal'
import { HandleModalContext } from '../../layout/mainlayout/MainLayout'
import { IRider } from '../../types/entryRider'
import { IconPrize } from '../icons/IconPrize'
import FirestoreService from '../../services/firestoreService'
import { CONST } from '../../const/const'

type IProps = {
  dataToPassOn: {
    riders: IRider[]
    cb: (arrValues: number[]) => void
  }
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

interface InputPops {
  value: string
  onChange: (value: string) => void
  type: 'start' | 'end'
}
const Input: React.FC<InputPops> = ({ type, value, onChange }) => {
  const onChangeHandler = (value: string) => {
    const regex = /^[0-9\b]+$/

    if (value === '' || regex.test(value)) {
      onChange(value)
    }
  }
  return (
    <div
      className={
        'border border-solid border-[#D3DAEE] box-border px-[15px] flex items-center h-[55px] flex-1 rounded-[8px]'
      }
    >
      <label htmlFor={type} className={'text-[#122B46] text-[16px]'}>
        {type === 'start' ? 'Start' : 'End'}
      </label>
      <input
        onChange={(e) => onChangeHandler(e.target.value)}
        type="text"
        id={type}
        className={'border-none text-right text-[#122B46] bg-transparent w-[100%]'}
        value={value}
      />
    </div>
  )
}

const AutoAssignModal = ({ dataToPassOn }: IProps) => {
  const [loading, setLoading] = useState(false)
  const handleModalContext = useContext(HandleModalContext)
  const [start, setStart] = useState('1')
  const [end, setEnd] = useState(`${dataToPassOn.riders.length}`)

  const saveHandler = async () => {
    let s = +start
    let e = +end

    if (s >= e) {
      return null
    }

    const promises: Promise<void>[] = []
    const numberValues: number[] = []

    for (const rider of dataToPassOn.riders) {
      if (s > e) {
        console.log('Found 3, stopping the loop')
        break
      }

      if (rider.id) {
        numberValues.push(s)
        promises.push(
          FirestoreService.updateItem(
            CONST.DATA.FIRESTORE.V01.COLLECTIONS.REGISTRATION_BY_DAY.NAME,
            rider.id,
            {
              backNumber: s,
            }
          )
        )
      }

      s++
    }

    setLoading(true)
    try {
      await Promise.all(promises)
      handleModalContext?.handleModal(false, MODAL_CONSTS.AUTO_ASSIGN_MODAL)
      dataToPassOn.cb(numberValues)
    } catch (e) {
      console.log('=>(AutoAssignModal.tsx:96) e', e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <MainModal
      customTitle={
        <div className="flex-1 mini:font-bold text-[25px] flex items-center gap-3 px-0">
          <div className={`!text-SeabiscuitDark200ThemeColor !font-normal`}>Auto-assign</div>
        </div>
      }
      show={true}
      closeButtonClassName="!right-[-20px]"
      modalClassName="!px-[38px] !pb-[20px] !max-w-[500px]"
      type="AUTO_ASSIGN_MODAL"
      customPadding={true}
      className="mt-4"
      hideCloseButton={false}
      setHeightAsPerContent={true}
      size="lg"
      buttons={[
        {
          label: 'Auto-assign',
          loading: loading,
          disabled: loading || +start === 0 || +end === 0 || +start >= +end,
          className: '!w-[271px]  !mt-[45px]',
          bgClass: '!bg-SeabiscuitMainThemeColor',
          onClick: saveHandler,
          textClass: '!text-white uppercase',
          onHoverClass: 'hover:shadow-slate-300',
        },
        {
          label: 'CANCEL',
          loading: false,
          className: '!w-[271px]',
          disabled: false,
          bgClass: '!bg-[#e8ecf2] border-0 !mt-2',
          onClick: () => handleModalContext?.handleModal(false, MODAL_CONSTS.AUTO_ASSIGN_MODAL),
          textClass: '!text-[#8597b3] uppercase',
          onHoverClass: 'hover:shadow-slate-300',
        },
      ]}
    >
      <div>
        <p className={'text-[#122B46] text-[16px]'}>
          Auto-assign back numbers to all entries registered for this show.
        </p>
        <div
          className={
            'mt-[22px] border border-solid border-[#D3DAEE] px-[14px] flex items-center gap-[17px] h-[55px] rounded-[8px]'
          }
        >
          <IconPrize />

          <p className={'text-[#122B46] text-[16px]'}>
            All Entries <span className={'opacity-50'}>• {dataToPassOn.riders.length} entries</span>
          </p>
        </div>

        <div className={'flex gap-[17px] mt-[10px]'}>
          <Input onChange={setStart} type={'start'} value={start} />
          <Input onChange={setEnd} type={'end'} value={end} />
        </div>

        <div
          className={
            'mt-[10px] border-[#D3DAEE] flex gap-[14px] border-solid border py-[13px] px-[16px] rounded-[8px]'
          }
        >
          <div>
            <IconError />
          </div>
          <div className={'text-[#122B46]  test-[14px] flex flex-col gap-[30px]'}>
            <p>Auto-assigning back numbers will overwrite all manually entered back numbers.</p>
            <p>This cannot be undone.</p>
          </div>
        </div>
      </div>
    </MainModal>
  )
}

export default AutoAssignModal
