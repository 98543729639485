import { ModelBaseModel } from '../model-base/model-base.model'
import { IModelBaseModel } from '../model-base/model-base.interface'
import { IDealInterface } from './deal.interface'
import * as yup from 'yup'

import { CONST } from '../../const/const'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

const DEALS_COLLECTION = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS.DEALS

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
/**
 * @todo Document this
 */

export class DealModel extends ModelBaseModel<IDealInterface> implements IModelBaseModel {
  // Vars
  public v: IDealInterface['v']
  public u: IDealInterface['u']
  public id: IDealInterface['id']
  public creatorId: IDealInterface['creatorId']
  public category: IDealInterface['category']
  public pictures: IDealInterface['pictures']
  public title: IDealInterface['title']
  public url: IDealInterface['url']
  public titleNGrams: IDealInterface['titleNGrams']
  public creatorType: IDealInterface['creatorType']
  public price: IDealInterface['price']
  public discountPrice: IDealInterface['discountPrice']
  public status: IDealInterface['status']
  public description: IDealInterface['description']
  public creatorName: IDealInterface['creatorName']
  public creatorNameNGrams: IDealInterface['creatorNameNGrams']
  public creatorPicture: IDealInterface['creatorPicture']
  public created: IDealInterface['created']
  public modified: IDealInterface['modified']

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  public constructor(obj?: IDealInterface) {
    super()
    this.id = obj?.id ?? DEALS_COLLECTION.FIELDS.ID.VALUES.DEFAULT
    this.v = obj?.v ?? DEALS_COLLECTION.FIELDS.VERSION.VALUES.DEFAULT
    this.u = obj?.u ?? DEALS_COLLECTION.FIELDS.UPDATE.VALUES.DEFAULT
    this.creatorId = obj?.creatorId ?? DEALS_COLLECTION.FIELDS.CREATOR_ID.VALUES.DEFAULT
    this.category = obj?.category ?? DEALS_COLLECTION.FIELDS.CATEGORY.VALUES.DEFAULT
    this.pictures = obj?.pictures ?? DEALS_COLLECTION.FIELDS.PICTURES.VALUES.DEFAULT
    this.title = obj?.title ?? DEALS_COLLECTION.FIELDS.TITLE.VALUES.DEFAULT
    this.url = obj?.url ?? DEALS_COLLECTION.FIELDS.URL.VALUES.DEFAULT
    this.titleNGrams = this.getCalculatedNGrams(obj?.title)
    this.creatorType = obj?.creatorType ?? DEALS_COLLECTION.FIELDS.CREATOR_TYPE.VALUES.DEFAULT
    this.price = this.getNum(obj?.price)
    this.discountPrice =
      this.getNum(obj?.discountPrice) === 0 ? null : this.getNum(obj?.discountPrice)
    this.status = obj?.status ?? DEALS_COLLECTION.FIELDS.STATUS.VALUES.DEFAULT
    this.description = obj?.description ?? DEALS_COLLECTION.FIELDS.DESCRIPTION.VALUES.DEFAULT
    this.creatorName = obj?.creatorName ?? DEALS_COLLECTION.FIELDS.CREATOR_NAME.VALUES.DEFAULT
    this.creatorNameNGrams = this.getCalculatedNGrams(obj?.creatorName)
    this.creatorPicture =
      obj?.creatorPicture ?? DEALS_COLLECTION.FIELDS.CREATOR_PICTURE.VALUES.DEFAULT

    this.created = this.utcTimestamp({
      key: 'created',
      isTimestamp: true,
      changeOnUpdate: true,
      value: obj?.created!,
    })

    this.modified = this.utcTimestamp({
      key: 'modified',
      isTimestamp: true,
      value: obj?.modified!,
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */

  static fromFirestoreDoc(doc: any) {
    return new DealModel({
      id: doc.id,
      ...doc.data(),
    })
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Dcocument this
   */
  static validationSchema() {
    return yup.object().shape({
      url: yup.string().url().nullable(),
      discountPrice: yup.string().nullable(),
      pictures: yup.array(yup.string()).required(),
      title: yup.string().typeError('title is a required field').required(),
      category: yup.string().typeError('category is a required field').required(),
      description: yup.string().typeError('description is a required field').required(),
      price: yup
        .string()
        .notOneOf(['0', '$0', '', null, undefined], 'price should be more than $0'),
      status: yup
        .string()
        .typeError('status is a required field')
        .oneOf(['live', 'draft'])
        .required(),
    })
  }
}
