import { useContext, useEffect, useRef, useState } from 'react'

// Redux
import {
  IEventsRegisterData,
  selectDraft,
  selectRegisterEventData,
  selectSplitPayment,
  selectedEvent,
  setRecipients,
} from '../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

// Custom components
import CompetitorEventRegisterWrapper from '../../CompetitorEventRegisterWrapper'
import SelectedEventFeeList from '../../clinicAndOther/Tabs/tabComponents/confirmation/SelectedEventFeeList'
import SelectedEventList from '../../clinicAndOther/Tabs/tabComponents/confirmation/SelectedEventList'
import SelectedEventTicketList from '../../clinicAndOther/Tabs/tabComponents/confirmation/SelectedEventTicketList'

// Constants
import { cloneDeep } from 'lodash'
import { CONST } from '../../../../const/const'
import { MODAL_CONSTS } from '../../../../const/modal-const'
import useToasterHelper from '../../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../../helpers/helpers'
import { IEventTickets } from '../../../../models/event-details/event-details.interface'
import { IFees } from '../../../../models/event-drafts/event-draft.interface'
import { getCategorizedEventDetails, getConvertedData } from '../../../../models/interface.helper'
import { IRecipient, ITeamMember, IUserInterface } from '../../../../models/users/user.interface'
import { UserModel } from '../../../../models/users/user.model'
import FirestoreService from '../../../../services/firestoreService'
import { selectHolder, setUsersHolder } from '../../../../store/holders/holdersSlice'
import { holdersSliceInitialState } from '../../../../store/holders/holdersSlice.data'
import { selectUserId } from '../../../../store/user/userSlice'
import SelectedEventGovernanceFeeList from '../../clinicAndOther/Tabs/tabComponents/confirmation/SelectedEventGovernanceFeeList'
import SelectedEventGrandTotal from '../../clinicAndOther/Tabs/tabComponents/confirmation/SelectedEventGrandTotal'
import { SelectedEventPaymentSummary } from '../../clinicAndOther/Tabs/tabComponents/confirmation/SelectedEventPaymentSummary'
import { competitorEventRegisterHelper as cerh } from '../competitorEventRegisterHelper'
import {
  EventRegistrationContext,
  IManageInfo,
} from '../../event-registration-tabs/EventRegistrationTabs'
import { ROUTES_CONST } from '../../../../const/routes-const'
import { useIonRouter } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { selectPayTabGrandTotalAccordion } from '../../../../store/registration/registrationSlice'

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

// Constants

let grandTotal = {
  total: 0,
  tickets: 0,
  salesTax: 0,
  stallFees: 0,
  registrationFees: 0,
}

const USERS_COLLECTIONS = CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS

// Types

type Props = {
  title?: string
  step?: number
  description?: string
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
  activeTab: {
    tab: string
    step: number
  }
  setEventTab?: any
  competitonFull?: boolean
  registerFormData: IEventsRegisterData
  isManage?: boolean
  manageInfo: IManageInfo
  registeredUser: IUserInterface | null
  registrationHasErrors: boolean
}

type IPayer = { teamMember: ITeamMember; payment: number; unit_name: string }
export type IHandlePaymentDivided = (payer: IPayer) => void

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

/**
 *
 * @param props Props
 * @returns JSX
 */
const CompetitorEventRegisterPayTab = (props: Props) => {
  // Hooks and vars
  const toastFunction = useToasterHelper()
  const router = useIonRouter()
  const history = useHistory()

  const draft = useAppSelector(selectDraft)
  const holder = useAppSelector(selectHolder)
  const userId = useAppSelector(selectUserId)
  const selectedEvent_ = useAppSelector(selectedEvent)
  const eventData = useAppSelector(selectRegisterEventData)
  const [recipientTotals, setRecipientTotals] = useState<IRecipient[]>([])

  const dispatch = useAppDispatch()
  const saveAllTabs = useContext(EventRegistrationContext)?.saveAllTabs ?? (() => {})

  const splitPayment = useAppSelector(selectSplitPayment)
  const [membersAddedInRegistration, setMembersAddedInRegistration] = useState<ITeamMember[]>([])
  const grandTotalAccordionData = useAppSelector(selectPayTabGrandTotalAccordion)

  const selectedEventsFees: IFees[] = eventData.fees ?? []
  const selectedEvents = props.registerFormData.events ?? []
  const selectedEventsTickets: IEventTickets[] = eventData.tickets ?? []

  const holderRef = useRef(holdersSliceInitialState)

  let { registrationPrice } = selectedEvent_.basicEventDetails

  const grandTotal_ = cerh.calculateGrandTotal({
    selectedEvents,
    registrationPrice,
    selectedEventsFees,
    selectedEventsTickets,
  })

  const countDetails = grandTotal_?.countDetails
  const categorizedTotals = grandTotal_?.categorizedTotals
  const stringifiedRecipientTotals = JSON.stringify(grandTotal_?.recipientsTotals ?? [])

  useEffect(() => {
    let recipientTotals_: IRecipient[] = JSON.parse(stringifiedRecipientTotals)?.map(
      (currItem: IRecipient) => {
        return {
          ...currItem,
          recipientName:
            membersAddedInRegistration.find(
              (currMember) => currMember.memberId === currItem.recipientId
            )?.memberName ?? 'Unknown',
        } as IRecipient
      }
    )
    dispatch(setRecipients(recipientTotals_))
    setRecipientTotals(recipientTotals_ ?? [])
  }, [stringifiedRecipientTotals, membersAddedInRegistration, dispatch])

  grandTotal = {
    total: grandTotal_?.grandTotal ?? 0,
    salesTax: categorizedTotals?.tax.total ?? 0,
    stallFees: categorizedTotals?.fees.total ?? 0,
    tickets: categorizedTotals?.tickets.total ?? 0,
    registrationFees: categorizedTotals?.registrations.total ?? 0,
  }

  useEffect(() => {
    holderRef.current = holder
  }, [holder])

  useEffect(() => {
    const categorizedEventDetails = getCategorizedEventDetails(eventData.events)
    const members = categorizedEventDetails.members.filter((member) => member.memberId !== userId)
    getSyncedMembers(members).then((members_) => {
      setMembersAddedInRegistration(members_)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData.events])

  //Function
  async function getSyncedMembers(members: ITeamMember[]) {
    let members_ = cloneDeep(members)

    let memberIds: string[] = []
    let users: IUserInterface[] = []
    let user: IUserInterface | null = null
    let alreadyFetchedUsers = holderRef.current.usersHolder.data

    // Remove already fetched users
    members.forEach((currentMember) => {
      if (!alreadyFetchedUsers.find((currentUser) => currentUser.id === currentMember.memberId))
        if (currentMember.memberId) memberIds.push(currentMember.memberId)
    })

    // Remove duplicate ids
    const uniqueMemberIds = [...new Set(memberIds)]

    const userSnaps = await FirestoreService.getItemsUsingIds(
      USERS_COLLECTIONS.NAME,
      uniqueMemberIds
    )

    userSnaps.forEach((currUserDoc) => {
      users.push(getConvertedData(UserModel.fromFirestoreDoc(currUserDoc).toObject()))
    })

    alreadyFetchedUsers = [...alreadyFetchedUsers, ...users]

    members_ = members_.map((currMember) => {
      user = alreadyFetchedUsers.find((currUser) => currUser.id === currMember.memberId) ?? null
      if (user)
        return {
          ...currMember,
          memberDob: user.userDOB,
          memberName: getUserFullName(user),
        }
      else return currMember
    })

    if (users.length) dispatch(setUsersHolder(alreadyFetchedUsers))

    return members_
  }

  const includeSalesTax = () => {
    return {
      ...grandTotal,
      total: grandTotal_?.grandTotal ?? 0,
    }
  }

  // Functions
  /** @info Opens payment modal */
  const openPaymentModal = () => {
    if (props.competitonFull) {
      return toastFunction.info({
        message: 'We are sorry! Event registration full...',
      })
    }
    props.handleModal(true, MODAL_CONSTS.REGISTER_FOR_COMPETITION_PAY_BY_CARD, {
      saveAllTabs,
      registeredUser: props.registeredUser,
    })
  }

  /**
   * @info Handles payment divided
   */
  const handlePaymentDivided: IHandlePaymentDivided = () => {
    // console.log(payer)
  }

  // Props to pass
  const commonProps = {
    splitPayment,
    handlePaymentDivided,
    membersAddedInRegistration,
    setEventTab: props.setEventTab,
  }

  return (
    <CompetitorEventRegisterWrapper title={props.title} description={props.description}>
      <div>
        {/* <label className="flex relative items-center cursor-pointer">
          <span className="ml-3 text-SeabiscuitDark200ThemeColor text-nr 2xl:text-base flex items-center mr-4">
            Would you like to split payments between team members?
            <Tooltip
              title={<h1 className="tooltip_title">This will allow you to split the bill between registered team members</h1>}
              placement="top"
              arrow
            >
              <button>
                <TooltipIcon color='#122B46' />
              </button>
            </Tooltip>
          </span>

          <div className="relative ml-2 py-3 pb-2">
            <TogglesElement on={splitPayment}
              onToggle={() => dispatch(updateSplitPayment())} />
          </div>
        </label>

        <hr className="w-full mt-2" /> */}

        <SelectedEventGrandTotal
          isManage={props.isManage}
          manageInfo={props.manageInfo}
          grandTotal={includeSalesTax()}
          toggle={'1'}
          splitPayment={true}
        />

        {splitPayment && (
          <>
            <SelectedEventList
              manageInfo={props.manageInfo}
              activeTab={props.activeTab}
              registrationPrice={registrationPrice}
              {...commonProps}
            />

            <SelectedEventFeeList manageInfo={props.manageInfo} {...commonProps} />

            <SelectedEventGovernanceFeeList
              {...commonProps}
              manageInfo={props.manageInfo}
              isManage={props.isManage}
            />

            <SelectedEventTicketList
              selectedEventsTickets={selectedEventsTickets}
              selectedTicketsCount={countDetails?.ticketsCount ?? 0}
              selectedTicketsTotal={categorizedTotals?.tickets.total ?? 0}
              selectedRecipientCount={countDetails?.ticketsRecipientCount ?? 0}
              selectedTicketsUnitsCount={countDetails?.ticketsUnitsCount ?? 0}
              manageInfo={props.manageInfo}
              {...commonProps}
            />
          </>
        )}

        {splitPayment ? <SelectedEventPaymentSummary recipientTotals={recipientTotals} /> : null}

        <button
          type="button"
          onClick={openPaymentModal}
          disabled={
            (!!draft?.paymentStatus && grandTotalAccordionData?.newTotalPrice! <= 0) ||
            props.registrationHasErrors
          }
          className={clsx(
            'w-full h-12 mx-auto flex items-center justify-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-SeabiscuitMainThemeColor hover:bg-[#D70443] hover:bg-SeabiscuitLightThemeColor focus:outline-none !ring-0 focus:ring-offset-2 focus:ring-SeabiscuitMainThemeColor mt-2',
            (!!draft?.paymentStatus && grandTotalAccordionData?.newTotalPrice! <= 0) ||
              (props.registrationHasErrors && 'opacity-50 pointer-events-none')
          )}
        >
          {draft?.paymentStatus && grandTotalAccordionData?.newTotalPrice! <= 0
            ? 'Paid'
            : splitPayment
              ? 'PAY & SEND BILLS'
              : 'Pay'}
        </button>

        {!props.isManage && (
          <button
            onClick={() => {
              router.push(ROUTES_CONST.MY_EVENT.URL)
              history.push(ROUTES_CONST.MY_EVENT.URL)
            }}
            type="button"
            className="w-full h-12 mx-auto flex items-center justify-center py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-SeabiscuitLightTextColor bg-SeabiscuitLightThemeColor  !ring-0 mt-2"
          >
            Save and Exit
          </button>
        )}
      </div>
    </CompetitorEventRegisterWrapper>
  )
}

export default CompetitorEventRegisterPayTab
