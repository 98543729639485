// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import React from 'react'

import GreenCheck from './assets/green-check.svg'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
export interface PropTypes {
  type?: string
  value?: string
  onChange?: (value: any) => void
  onClick?: (value: any) => void
  disabled?: boolean
  placeholder?: string
  notChecked?: boolean | undefined
  outlined?: boolean
  Icon?: any
  openAddNotesModal?: any
  name: string
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const CheckedInput: React.FC<PropTypes> = ({
  type,
  value,
  onChange,
  disabled,
  placeholder,
  notChecked,
  onClick,
  outlined,
  Icon,
  openAddNotesModal,
  name,
}) => {
  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <div
      className={`text-[14px] w-full ${
        outlined
          ? 'bg-white border-solid border-[1px] border-[#D3DAEE]'
          : 'bg-SeabiscuitGrayThemeColor'
      } rounded-md flex items-center p-4 h-12`}
      onClick={onClick}
    >
      {Icon && <Icon className="text-SeabiscuitDark200ThemeColor" />}

      <input
        type={type}
        value={disabled ? 'N/A' : value}
        onChange={onChange}
        readOnly={true}
        onClick={(e: any) =>
          typeof openAddNotesModal === 'function' ? openAddNotesModal(e.target) : {}
        }
        className="w-full bg-transparent outline-0 border-0 focus:ring-0 focus:outline-0 focus:border-0 text-SeabiscuitDark200ThemeColor overflow-hidden whitespace-nowrap truncate"
        disabled={disabled}
        placeholder={placeholder}
        name={name}
      />
      {!disabled && !notChecked && <img src={GreenCheck} alt="green check" />}
    </div>
  )
}

export default CheckedInput
