// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * Holds the models to store events
 */

import { IModelBaseModel } from '../model-base/model-base.interface'
import { ModelBaseModel } from '../model-base/model-base.model'
import { IEventWaitlistInterface, TWaitlist } from './eventWaitlist.interface'

export class EventWailistModel
  extends ModelBaseModel<IEventWaitlistInterface>
  implements IModelBaseModel
{
  public v: number
  public owner: string
  public eventId: string
  public id: string | null
  public waitlist: TWaitlist[]

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  public constructor(obj?: IEventWaitlistInterface) {
    super()
    this.v = obj?.v ?? 1
    this.id = obj?.id ?? null
    this.owner = obj?.owner ?? ''
    this.eventId = obj?.eventId ?? ''
    this.waitlist = obj?.waitlist ?? []
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @todo Document this
   */
  static fromFirestoreDoc(doc: any) {
    return new EventWailistModel({
      id: doc.id,
      ...doc.data(),
    })
  }
}
