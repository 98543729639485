import React from 'react'
import TABS from './tabs.json'
import { PillButtonElement } from '../../../../components/elements/buttons/pill/PillButtonElement'

type IProps = {
  activeTab: number
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
}

const TabList = (props: IProps) => {
  return (
    <div className="flex gap-5">
      {TABS.map((currTab, index) => {
        return (
          <PillButtonElement
            key={`currTab${index}`}
            text={currTab.tabName}
            onButtonClicked={props.setActiveTab}
            value={index}
            selected={index === props.activeTab}
            Class="text-SeabiscuitDark200ThemeColor !mr-0 !min-w-[110px] border-none !rounded-3xl !m-0"
          />
        )
      })}
    </div>
  )
}

export default TabList
