// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import { useEffect, useState } from 'react'
import { CONST } from '../../../../../const/const'
import { EventWailistModel } from '../../../../../models/event-waitlist/eventWaitlist.model'
import { IUserInterface } from '../../../../../models/users/user.interface'
import FirestoreService from '../../../../../services/firestoreService'
import ViewsLoader from '../../../../loader/ViewsLoader'
import Location from '../assets/location.svg'

import { useIonRouter } from '@ionic/react'
import { where } from 'firebase/firestore'
import helpers from '../../../../../commonHelpers/helpers'
import { DATA_FIRESTORE_V01_CONST } from '../../../../../const/data/v01/firestore-v01-const'
import { MESSAGES_CONST } from '../../../../../const/messages-const'
import useToasterHelper from '../../../../../helpers/ToasterHelper'
import { CustomError } from '../../../../../helpers/helpers'
import { IHorseData } from '../../../../../models/horse/horse.interface'
import { HorseModel } from '../../../../../models/horse/horse.model'
import { getConvertedData } from '../../../../../models/interface.helper'
import { UserModel } from '../../../../../models/users/user.model'
import EventService from '../../../../../services/eventService'
import { selectBasicEventDetails } from '../../../../../store/events/eventsSlice'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks'
import {
  setRegisterExhibitor,
  setRegisterId,
  setWaitlistExhibitor,
  setWaitlistId,
} from '../../../../../store/registerExhibitor/registerExhibitorSlice'
import MainModal from '../../../../modals/common/MainModal'
import { IEventWaitlistInterface } from '../../../../../models/event-waitlist/eventWaitlist.interface'
import { useHistory } from 'react-router-dom'

export const DUMMY_PROFILE_PIC = '/assets/cp_icons/User-4.svg'

// Constants
const UNKNOWN = 'Unknown'
const USERS_COLLECTION = CONST.DATA.FIRESTORE.V01.COLLECTIONS.USERS
const HORSES_COLLECTION = CONST.DATA.FIRESTORE.V01.COLLECTIONS.HORSES
const EVENT_WAITLIST = CONST.DATA.FIRESTORE.V01.COLLECTIONS.EVENT_WAITLIST

// Types
type IRiderToRender = {
  horseIds: string[]
  memberId: string
  horseName: string
  memberName: string
  description: string
  memberAddress: string
  horseNameList: string[]
  memberProfilePicture: string | null
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @TODO Document this
 */
const TabSection: React.FC<{ currentTab: string; onChange: (e: string) => void }> = ({
  currentTab,
  onChange,
}) => {
  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  const activeTabHandle = (active: string) => {
    if (currentTab === active) {
      return {
        color: '#F7074F',
        background: '#F7074F0D',
      }
    } else {
      return {
        color: '#122B46',
      }
    }
  }

  return (
    <div className="flex mt-4 gap-2 bg-gray-100 md:bg-transparent rounded-full md:rounded-none p-1 md:p-0 mb-3">
      <button
        onClick={() => onChange('1')}
        className="md:flex-none flex-1 bg-transparent border-none outline-none uppercase md:normal-case py-2 px-3 text-[14px] mr-1 rounded-lg"
        style={{
          ...activeTabHandle('1'),
        }}
      >
        Registered
      </button>
      <button
        onClick={() => onChange('2')}
        className="md:flex-none flex-1 bg-transparent border-none outline-none uppercase md:normal-case text-[14px] py-2 px-3 mr-1 rounded-lg"
        style={{
          ...activeTabHandle('2'),
        }}
      >
        Wait List
      </button>
    </div>
  )
}

const handleUserProfile = async (
  id: any,
  router: any,
  history: any,
  dispatch: any,
  handleModal: any,
  rider: boolean
) => {
  const usersSnaps = await FirestoreService.getItem(
    DATA_FIRESTORE_V01_CONST.COLLECTIONS.USERS.NAME,
    id
  )
  const userData = getConvertedData(UserModel.fromFirestoreDoc(usersSnaps).toObject())
  if (rider) {
    dispatch(setRegisterExhibitor(userData))
    dispatch(setRegisterId(id))
  } else {
    dispatch(setWaitlistExhibitor(userData))
    dispatch(setWaitlistId(id))
  }
  // dispatch(setUserId(id))
  handleModal(false, 'registerExhibitors')
  router.push(`${CONST.ROUTES.USER_PROFILE.URL}/${id}`)
  history.push(`${CONST.ROUTES.USER_PROFILE.URL}/${id}`)
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const Cart: React.FC<{
  title: string
  description?: string
  profile: string
  category: string
  id: string | null
  router: any
  history: any
  dispatch: any
  handleModal: any
  rider: boolean
  row: any
}> = ({
  title,
  description,
  profile,
  category,
  id,
  router,
  history,
  dispatch,
  handleModal,
  rider,
}) => (
  <div className="flex gap-2 items-center my-4">
    <div
      className={`bg-[#122B4699] w-[45px] h-[45px] mr-4 rounded-full overflow-hidden ${profile === DUMMY_PROFILE_PIC ? 'p-1' : ''}`}
    >
      <span
        onClick={() => handleUserProfile(id, router, history, dispatch, handleModal, rider)}
        className="cursor-pointer"
      >
        <img
          src={profile}
          alt="profile"
          className="object-cover w-full h-full"
          onError={(e) => (e.currentTarget.src = '/assets/placeholders/Emptyuser.png')}
        />
      </span>
    </div>
    <div>
      <h3 className="m-0 text-[14px] font-regular text-SeabiscuitDark200ThemeColor capitalize">
        {title}
      </h3>
      <div className="flex gap-1 items-center  text-[14px] font-regular text-[#122B4699]">
        {category === 'registered' ? <img src={Location} alt="horse" className="w-[16px]" /> : null}
        <div className="whitespace-nowrap text-ellipsis text-overflow-hidden overflow-hidden">
          {description}
        </div>
      </div>
    </div>
  </div>
)

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventDetailViewComponentExhibitorsModal: React.FC<{
  show: boolean
  onHide?: () => void
  handleModal?: any
  allRegisteredRiders?: any
  dataToPassOn?: {
    id: {
      tab: number
      tabTitle: string
      subTitle: string
    }
    eventClicked: {
      eventId: string
    }
  }
}> = ({ show, handleModal, dataToPassOn }) => {
  // Hooks and vars
  const router = useIonRouter()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const toastFunctions = useToasterHelper()
  const basicEventDetails = useAppSelector(selectBasicEventDetails)

  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState('1')
  const [waitlist, setWaitlist] = useState<any[]>([])
  const [registeredRiders, setRegisteredRiders] = useState<IRiderToRender[]>([])

  const eventId = dataToPassOn?.eventClicked?.eventId ?? basicEventDetails.id

  // Functions

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  useEffect(() => {
    if (!eventId) {
      helpers.logger({
        message: CustomError.somethingWentWrong({
          moduleName: 'useEffect',
          fileName: 'EventDetailViewComponentExhibitorsModal',
          message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
          devMessage: `eventId is ${eventId}`,
        }),
      })
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
      return
    }

    if (currentTab === '2') getWaitlistUserList()
    else getRegisteredRiders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async function getWaitlistUserList() {
    const EVENT_ID_KEY = 'eventId'

    try {
      setLoading(true)

      const result = await FirestoreService.filterItems(EVENT_WAITLIST.NAME, [
        where(EVENT_ID_KEY, '==', eventId),
      ])

      if (result.size) {
        const events: IEventWaitlistInterface[] = []
        result?.forEach((doc) => events.push(EventWailistModel.fromFirestoreDoc(doc).toObject()))
        const data = events.reduce((acc: any, current) => {
          current.waitlist.forEach((item) => {
            acc.push(item)
          })
          return acc
        }, [])

        setWaitlist(data)
      }

      setLoading(false)
    } catch (err) {
      helpers.logger({
        isError: true,
        message: err,
      })
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async function getRegisteredRiders() {
    try {
      let riders: IRiderToRender[] = []
      let eventService = new EventService(eventId!)

      setLoading(true)

      riders = await eventService.getRegisteredUsersListForModal
      const riders_ = await updateRiders(riders)

      setRegisteredRiders(riders_)
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      setLoading(false)
    }
  }

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  /**
   * @TODO Document this
   */
  async function updateRiders(riders: IRiderToRender[]) {
    let description: string = ''
    let userState: string = ''
    let horses: IHorseData[] = []
    let horseNameList: string[] = []
    let userNationality: string = ''
    let users: IUserInterface[] = []
    let riders_: IRiderToRender[] = []
    let horse: IHorseData | null = null
    let user: IUserInterface | null = null

    let userIds: string[] = riders.map((currRider) => currRider.memberId)
    let horseIds: string[] = riders.flatMap((currRider) => currRider.horseIds)

    userIds = userIds.filter((userId) => !!userId)
    horseIds = horseIds.filter((horseId) => !!horseId)

    userIds = [...new Set(userIds)]
    horseIds = [...new Set(horseIds)]

    try {
      const userSnaps = await FirestoreService.getItemsUsingIds(USERS_COLLECTION.NAME, userIds)

      const horseSnaps = await FirestoreService.getItemsUsingIds(HORSES_COLLECTION.NAME, horseIds)

      userSnaps.forEach((currDoc) => {
        users.push(getConvertedData(UserModel.fromFirestoreDoc(currDoc).toObject()))
      })

      horseSnaps.forEach((currDoc) => {
        horses.push(getConvertedData(HorseModel.fromFirestoreDoc(currDoc).toObject()))
      })

      riders.forEach((rider) => {
        description = ''
        horseNameList = []
        user = users.find((currUser) => currUser.id === rider.memberId) ?? null
        userState = user?.userState ?? ''
        userNationality = user?.userNationality ?? ''

        if (!!userState) userState = ` ${userState}`

        if (userNationality && !!user?.userState) userNationality = `, ${userNationality}`
        else userNationality = ` ${userNationality}`

        rider.horseIds.forEach((currHorseId) => {
          horse = horses.find((currHorse) => currHorse.id === currHorseId) ?? null
          if (horse) horseNameList.push(horse.horseName ?? UNKNOWN)
        })

        if (user) {
          description += `${horseNameList.join(', ')}${userState}${userNationality}`
          riders_.push({
            ...rider,
            description,
            horseNameList,
            memberAddress: user.userAddress ?? UNKNOWN,
            memberName: [user.userFirstName, user.userLastName].filter((c) => !!c).join(' '),
            memberProfilePicture: user.userProfilePicture ?? null,
          })
        }
      })
    } catch (error) {
      helpers.logger({
        message: error,
      })
    } finally {
      return riders_
    }
  }

  return (
    <MainModal
      size="md"
      show={show}
      loading={loading}
      type="REGISTER_EXHIBITORS"
      title="Registered riders"
      titleClassName="text-SeabiscuitDark200ThemeColor"
      customTitle={
        <>
          <TabSection currentTab={currentTab} onChange={(e) => setCurrentTab(e)} />
        </>
      }
    >
      <>
        {currentTab === '1' ? (
          loading ? null : (
            <>
              {registeredRiders && registeredRiders.length ? (
                registeredRiders.map((item, index) => {
                  return (
                    <Cart
                      key={`${JSON.stringify(item)}${index}`}
                      row={item}
                      rider={true}
                      router={router}
                      history={history}
                      id={item.memberId}
                      dispatch={dispatch}
                      category="registered"
                      handleModal={handleModal}
                      description={item.description}
                      title={item.memberName ?? UNKNOWN}
                      profile={item?.memberProfilePicture ?? DUMMY_PROFILE_PIC}
                    />
                  )
                })
              ) : (
                <div className="text-SeabiscuitDark200ThemeColor text-nr h-[200px] flex justify-center items-center w-full">
                  No users have registered yet.
                </div>
              )}
            </>
          )
        ) : null}

        {currentTab === '2' ? (
          <>
            {loading ? (
              <div className="h-[200px] flex justify-center items-center w-full">
                <ViewsLoader size="xl" color="red" />
              </div>
            ) : (
              <>
                {waitlist && waitlist.length ? (
                  waitlist.map((item: any, index: number) => {
                    return (
                      <Cart
                        key={index}
                        title={item.memberName}
                        description={`${item.defaultRole} ${item.memberAddress && item.memberAddress !== '' ? `• ${item.memberAddress},` : ''} ${item.memberCountry}`}
                        profile={
                          item?.memberProfilePicture && item.memberProfilePicture !== ''
                            ? item.memberProfilePicture
                            : DUMMY_PROFILE_PIC
                        }
                        category="waitlist"
                        id={item.memberId}
                        router={router}
                        history={history}
                        dispatch={dispatch}
                        rider={false}
                        handleModal={handleModal}
                        row={item}
                      />
                    )
                  })
                ) : (
                  <div className="text-SeabiscuitDark200ThemeColor text-nr h-[200px] flex justify-center items-center w-full">
                    No one is on the waitlist yet
                  </div>
                )}
              </>
            )}
          </>
        ) : null}
      </>
    </MainModal>
  )
}

export default EventDetailViewComponentExhibitorsModal
