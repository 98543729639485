import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Creatable from 'react-select/creatable'
import { v4 } from 'uuid'
import helpers, { fileObjToBase64 } from '../../../../../../commonHelpers/helpers'
import AmountInput from '../../../../../../components/common/inputs/AmountInput'
import Input from '../../../../../../components/common/inputs/Input'
import ExpandableSelector from '../../../../../../components/elements/select-lists/ExpandableSelector'
import MainModal from '../../../../../../components/modals/common/MainModal'
import { CONST } from '../../../../../../const/const'
import { MESSAGES_CONST } from '../../../../../../const/messages-const'
import { MODAL_CONSTS } from '../../../../../../const/modal-const'
import { IUserMode } from '../../../../../../const/use-mode'
import MessageHelperComp from '../../../../../../helpers/MessageHelper'
import useToasterHelper from '../../../../../../helpers/ToasterHelper'
import { getUserFullName } from '../../../../../../helpers/helpers'
import { getConvertedData } from '../../../../../../models/interface.helper'
import { IProductInterface } from '../../../../../../models/product/product.interface'
import { ProductModel } from '../../../../../../models/product/product.model'
import FirestoreService from '../../../../../../services/firestoreService'
import FirebaseStorageService from '../../../../../../services/storageService'
import { useAppSelector } from '../../../../../../store/hooks'
import { selectProfileData } from '../../../../../../store/user/userSlice'
import ImageContainer from './ImageContainer'

type CreateAndUpdateProdutModal = {
  show: boolean
  dataToPassOn: {
    productDoc: IProductInterface
    updateProduct?: (updatedProduct: IProductInterface) => void
  }
  handleModal: (showHide: boolean, typeOfModal: string, data?: any) => void
}

const COLLECTIONS = CONST.DATA.FIRESTORE.LATEST.COLLECTIONS

const CreateAndUpdateProductModal = (props: CreateAndUpdateProdutModal) => {
  const [loading, setLoading] = useState(false)

  const dataToPassOn = props.dataToPassOn
  const toastFunctions = useToasterHelper()
  const profileDetails = useAppSelector(selectProfileData)

  const {
    reset,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: new ProductModel().toObject(),
    resolver: yupResolver(ProductModel.validationSchema()),
  })

  useEffect(() => {
    reset({
      ...watch(),
      ...(props.dataToPassOn.productDoc ?? {}),
      tags: (props.dataToPassOn.productDoc?.tags ?? []).map((currentValue) => {
        return {
          label: currentValue,
          value: currentValue,
        }
      }) as any[],
      status: (!props.dataToPassOn.productDoc?.id
        ? null
        : props.dataToPassOn.productDoc.status) as any,
    })

    setValue('files' as any, props.dataToPassOn.productDoc?.pictures ?? [])
    setValue('filesToRemove' as any, [])
  }, [props.dataToPassOn.productDoc, reset, setValue, watch])

  useEffect(() => {
    setValue('creatorId', profileDetails.id ?? null)
    setValue('creatorName', getUserFullName(profileDetails))
    setValue('creatorType', profileDetails.userType as IUserMode)
    setValue('creatorPicture', profileDetails.userProfilePicture ?? null)
  }, [profileDetails, setValue])

  const onInputChange = async (files: FileList | null) => {
    const base64ImageUrls: string[] = []

    if (!files) return

    const files_ = Object.keys(files).map((currKey: any) => {
      return files[currKey]
    })

    await helpers.asyncWhileLoop({
      loopCount: files_.length,
      functionToFire: async (index) => {
        const result = await fileObjToBase64(files[index])
        base64ImageUrls.push(result as string)
      },
    })

    setValue('pictures', [...watch('pictures'), ...base64ImageUrls])
    setValue('files' as any, [...(watch('files' as any) ?? []), ...files_])
  }

  const closeModal = () => {
    props.handleModal(false, MODAL_CONSTS.CREATE_AND_UPDATE_PRODUCT)
  }

  const onRemoveImageButtonClick = (index: number) => {
    setValue('filesToRemove' as any, [
      ...(watch('filesToRemove' as any) ?? []),
      watch('files' as any)[index],
    ])
    setValue(
      'pictures',
      (function () {
        return watch('pictures').filter((_, index_) => {
          return index_ !== index
        })
      })()
    )
    setValue(
      'files' as any,
      (function () {
        return watch('files' as any).filter((_: any, index_: number) => {
          return index_ !== index
        })
      })()
    )
  }

  const onSubmit = async (data: any) => {
    let filesToSave: File[] = []
    let imageUrls: string[] = []
    let filesToRemove: string[] = []
    let imageUrl: string | null = null
    let docToSave = new ProductModel(data)

    if (docToSave.discountPrice === 0) {
      docToSave.discountPrice = null
    }

    docToSave.couponCode = docToSave.couponCode?.toUpperCase() ?? null
    docToSave.tags = docToSave.tags.map((currTag) => (currTag as any).value)

    try {
      filesToRemove = watch('filesToRemove' as any).filter((currFile: string | File) => {
        return typeof currFile === 'string' && currFile.startsWith('http')
      })

      filesToSave = watch('files' as any).filter((currFile: any) => {
        return !!currFile?.name
      })

      docToSave.pictures = docToSave.pictures.filter((currPictureUrl) => {
        return currPictureUrl.startsWith('http')
      })

      if (!docToSave.creatorId) {
        throw new Error(`docToSave.creatorId is ${docToSave.creatorId}`)
      }

      setLoading(true)

      if (filesToRemove.length) {
        await helpers.asyncWhileLoop({
          loopCount: filesToRemove.length,
          functionToFire: async (index) => {
            if (typeof filesToRemove[index] === 'string') {
              await FirebaseStorageService.deleteFile(filesToRemove[index])
            }
          },
        })
      }

      if (filesToSave.length) {
        await helpers.asyncWhileLoop({
          loopCount: filesToSave.length,
          functionToFire: async (index) => {
            imageUrl = (await FirebaseStorageService.uploadFile(
              filesToSave[index],
              `user/products/${docToSave.creatorId}/${v4()}`
            )) as string

            if (typeof imageUrl === 'string') {
              imageUrls.push(imageUrl)
            }
          },
        })
      }

      docToSave.pictures = [...docToSave.pictures, ...imageUrls]

      if (!!docToSave.id) {
        await FirestoreService.updateItem(
          COLLECTIONS.PRODUCTS.NAME,
          docToSave.id,
          docToSave.toFirestore()
        )
      } else {
        docToSave.id = (
          await FirestoreService.createItem(COLLECTIONS.PRODUCTS.NAME, docToSave.toFirestore())
        ).id
      }

      toastFunctions.success({
        message: !!dataToPassOn.productDoc.id
          ? MESSAGES_CONST.PRODUCT_UPDATED
          : MESSAGES_CONST.PRODUCT_ADDED,
      })

      dataToPassOn?.updateProduct?.(getConvertedData(docToSave.toObject()))

      closeModal()
    } catch (error) {
      console.error(error)
      setLoading(false)
      toastFunctions.error({
        message: MESSAGES_CONST.SOMETHING_WENT_WRONG,
      })
    }
  }

  return (
    <MainModal
      title={!!dataToPassOn?.productDoc?.id ? 'Update Product' : 'Add Product'}
      show={props.show}
      type="CREATE_AND_UPDATE_PRODUCT"
      className="mt-4 !px-0"
      hideCloseButton={loading}
      setHeightAsPerContent={true}
      size="md"
      buttons={[
        {
          label: 'SAVE',
          loading,
          disabled: loading,
          className: 'md:max-w-[174px]',
          bgClass: '!bg-SeabiscuitMainThemeColor',
          onClick: handleSubmit(onSubmit),
          borderClass: 'unset',
          textClass: '!text-white',
        },
        {
          label: 'CANCEL',
          disabled: loading,
          bgClass: '!bg-SeabiscuitLightThemeColor',
          className: 'md:max-w-[174px]',
          borderClass: 'border border-transparent',
          textClass: '!text-SeabiscuitLightTextColor',
          onClick: closeModal,
          onHoverClass: 'hover:shadow-slate-300',
        },
      ]}
    >
      <div className="flex flex-col gap-2">
        <ExpandableSelector
          placeholder="Product status..."
          options={[
            { label: 'Live', value: 'live' },
            { label: 'Draft', value: 'draft' },
          ]}
          onChange={(status) => setValue('status', status as IProductInterface['status'])}
          value={watch('status')}
          className="!mb-0 !text-nr"
          icon="/assets/og_icons/signalStream-1.svg"
        />

        {!!errors?.status?.message ? <MessageHelperComp message={errors?.status.message} /> : null}

        <Input
          type="text"
          name="name"
          placeholder="Product title..."
          preFixIcon="/assets/og_icons/Price Tag-1.svg"
          value={watch('name')}
          className="h-[56px] rounded-2xl border-[1px] border-solid !border-SeabiscuitLightThemeColorD3 !text-nr placeholder:!text-nr"
          register={register}
        />

        {!!errors?.name?.message ? (
          <MessageHelperComp message={errors?.name.message} className="mb-1" />
        ) : null}

        <Input
          rows={6}
          name="description"
          type="textarea"
          placeholder="Product description..."
          preFixIcon="/assets/cp_icons/Price Tag-1.svg"
          value={watch('description')}
          className="min-h-[56px] rounded-2xl border-[1px] border-solid py-3 !border-SeabiscuitLightThemeColorD3 !text-nr placeholder:!text-nr placeholder:text-SeabiscuitLightParagraphTextColor"
          preFixIconClassName="my-3.5"
          register={register}
        />

        {!!errors?.description?.message ? (
          <MessageHelperComp message={errors?.description.message} className="mb-1" />
        ) : null}

        <ImageContainer
          removable={true}
          onInputChange={onInputChange}
          className="min-h-[56px]"
          onRemoveImageButtonClick={onRemoveImageButtonClick}
          values={watch('pictures').filter((image) => !!image)}
        />

        <Input
          type="text"
          name="category"
          placeholder="Product category..."
          preFixIcon="/assets/og_icons/Price Tag-1.svg"
          value={watch('category')}
          className="h-[56px] rounded-2xl border-[1px] border-solid !border-SeabiscuitLightThemeColorD3 !text-nr placeholder:!text-nr"
          register={register}
        />

        {!!errors?.category?.message ? (
          <MessageHelperComp message={errors?.category.message} className="mb-1" />
        ) : null}

        <div className="w-full relative flex">
          <img
            src="/assets/og_icons/Price Tag-1.svg"
            alt="icon"
            className="absolute left-3 w-6 h-6 my-4 z-[1]"
          />
          <Creatable
            onChange={(newValue) => {
              setValue('tags', newValue as any[])
            }}
            value={watch('tags') ?? []}
            classNames={{
              container: () => 'min-h-[56px] w-full',
              control: () =>
                'h-full !border-[1px] !border-SeabiscuitLightThemeColorD3 !outline-none !shadow-none !rounded-md w-full !pl-11',
              valueContainer: () => '!py-2 !px-0',
              indicatorsContainer: () => '!h-[54px]',
              placeholder: () => '!text-SeabiscuitLightParagraphTextColor !text-nr',
              multiValue: () => '!bg-[#FEF5F6] !text-SeabiscuitMainThemeColor !py-[3px] !px-2',
              multiValueLabel: () =>
                '!text-SeabiscuitMainThemeColor !rounded-[5px] !py-[3px] !px-2',
              option: () => '!bg-transparent !text-nr',
              noOptionsMessage: () => '!text-nr',
            }}
            placeholder="Tags..."
            isClearable={false}
            isMulti={true}
          />
        </div>

        {!!errors?.tags?.message ? (
          <MessageHelperComp message={errors?.tags.message} className="mb-1" />
        ) : null}

        <AmountInput
          prefix="$"
          name="price"
          placeholder="Product price..."
          value={watch('price')}
          preFixIcon="/assets/og_icons/Us Dollar Circled-1.svg"
          clear={(watch('price') as any) === '$0' || !watch('price')}
          className="h-[56px] rounded-2xl border-[1px] border-solid !border-SeabiscuitLightThemeColorD3 !text-nr placeholder:!text-nr"
          register={register}
        />

        {!!errors?.price?.message ? (
          <MessageHelperComp message={errors?.price.message} className="mb-1" />
        ) : null}

        <AmountInput
          prefix="$"
          name="discountPrice"
          placeholder="Product discount price..."
          value={watch('discountPrice') ?? '$0'}
          preFixIcon="/assets/og_icons/Us Dollar Circled-1.svg"
          clear={(watch('discountPrice') as any) === '$0' || !watch('discountPrice')}
          className="h-[56px] rounded-2xl border-[1px] border-solid !border-SeabiscuitLightThemeColorD3 !text-nr placeholder:!text-nr"
          register={register}
        />

        {!!errors?.discountPrice?.message ? (
          <MessageHelperComp message={errors?.discountPrice.message} className="mb-1" />
        ) : null}

        <Input
          name="couponCode"
          type="text"
          placeholder="Product coupon code (optional)..."
          preFixIcon="/assets/og_icons/Sale Price Tag-1.svg"
          value={watch('couponCode')}
          className="min-h-[56px] rounded-2xl border-[1px] border-solid py-3 !border-SeabiscuitLightThemeColorD3 !text-nr placeholder:!text-nr text-uppercase"
          preFixIconClassName="my-3.5"
          register={register}
        />

        {!!errors?.couponCode?.message ? (
          <MessageHelperComp message={errors?.couponCode.message} className="mb-1" />
        ) : null}

        <Input
          name="url"
          type="text"
          placeholder="Link to product..."
          preFixIcon="/assets/og_icons/Globe-1.svg"
          value={watch('url')}
          className="min-h-[56px] rounded-2xl border-[1px] border-solid py-3 !border-SeabiscuitLightThemeColorD3 !text-nr placeholder:!text-nr"
          preFixIconClassName="my-3.5"
          register={register}
        />

        {!!errors?.url?.message ? (
          <MessageHelperComp message={errors?.url.message} className="mb-1" />
        ) : null}
      </div>
    </MainModal>
  )
}

export default CreateAndUpdateProductModal
