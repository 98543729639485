import { StylesConfig } from 'react-select'

type MyOptionType = {
  label: JSX.Element | string
  value: string
}

type IsMulti = boolean

export const CompetitorReactSelectCustomStyle: StylesConfig<MyOptionType, IsMulti> = {
  option: (provided: any, state: any) => {
    return {
      ...provided,
      padding: '10px',
      ':hover': {
        background: '#F6F7FB',
        color: '#122B46',
      },
      width: '100%',
      backgroundColor: state.isSelected ? '#122B46' : state.data.selected ? '#F6F7FB' : 'white',
      color: state.isSelected ? '#fff' : state.data.selected ? '#122B46' : '#122B46',
      textTransform: 'capitalize',
      fontSize: '13px',
      display: state.data.selected === 'hidden-option' ? 'none' : 'block',
    }
  },
  control: (styles: any) => ({
    ...styles,
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
    padding: 5,
    border: 'none',
    color: '#122B4680',
    borderRadius: '0.5rem',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '0.5rem',
    border: 'none',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    fontSize: 14,
    fontWeight: 400,
    color: '#122B4680',
  }),
  // selector view
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = 'opacity 300ms'
    return {
      ...provided,
      opacity,
      transition,
      color: '#122B46',
      fontSize: 13,
      fontWeight: 400,
      display: 'inline',
    }
  },
  menuList: (provided: any, state: any) => ({
    ...provided,
    overflowY: 'scroll',
    zIndex: '2',
    fontSize: 16,
    border: `none`,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    zIndex: '9999',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    fontSize: 16,
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: '#122B46',
    display: state.isDisabled ? 'none' : provided['display'],
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    display: 'none',
  }),
}
