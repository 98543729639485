// ############################################################
/**
 * @todo Document this
 */
// ############################################################

import EventSchedulingFormComponentDrawerComponent from '../global/EventSchedulingFormComponentDrawerComponent'

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const GrayContainer: React.FC<{ caption: string; year?: string }> = ({ caption, year }) => (
  <div className="text-SeabiscuitDark200ThemeColor p-4 rounded-lg bg-SeabiscuitGrayThemeColor flex gap-6 text-[16px]">
    {year && <span>{year}</span>}
    <span>{caption}</span>
  </div>
)

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const BlueContainer: React.FC<{ caption: string }> = ({ caption }) => (
  <div className="text-white p-4 rounded-lg bg-SeabiscuitDark200ThemeColor flex gap-6 justify-center text-[16px]">
    <span>{caption}</span>
  </div>
)

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
/**
 * @todo Document this
 */
const EventSchedulingFormComponentScheduleModal: React.FC<{
  show: boolean
  onHide: () => void
}> = ({ show, onHide }) => {
  const tableHeaderClasses = 'text-xs translate-y-2 font-semibold text-SeabiscuitDark200ThemeColor'

  // %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  return (
    <EventSchedulingFormComponentDrawerComponent
      show={show}
      onHide={onHide}
      title="Schedule"
      maxWidth={1366}
    >
      <h2 className="m-0 text-[22px] text-SeabiscuitDark200ThemeColor font-bold">Friday</h2>
      <div className="grid gap-2 grid-cols-[10%_20%_auto_10%_10%] mt-2">
        <div className={tableHeaderClasses}>Time</div>
        <div className={tableHeaderClasses}>Location</div>
        <div className={tableHeaderClasses}>Section</div>
        <div className={tableHeaderClasses}>Tag</div>
        <div className={tableHeaderClasses}>Discipline</div>
        <div className="col-span-5 border-t border-solid border-[#eee]"></div>
        <BlueContainer caption="09:00" />
        <GrayContainer caption="Secretary’s office" />
        <GrayContainer year="XXXX" caption="Check-in" />
        <GrayContainer caption="Fixed" />
        <GrayContainer caption="Jumper" />

        <BlueContainer caption="09:00" />
        <GrayContainer caption="Secretary’s office" />
        <GrayContainer year="XXXX" caption="Check-in" />
        <GrayContainer caption="Fixed" />
        <GrayContainer caption="Jumper" />

        <BlueContainer caption="09:00" />
        <GrayContainer caption="Secretary’s office" />
        <GrayContainer year="XXXX" caption="Check-in" />
        <GrayContainer caption="Fixed" />
        <GrayContainer caption="Jumper" />
      </div>

      <h2 className="m-0 text-[22px] text-SeabiscuitDark200ThemeColor mt-8 font-bold">Saturday</h2>
      <div className="grid gap-2 grid-cols-[10%_20%_auto_10%_10%] mt-2">
        <div className={tableHeaderClasses}>Time</div>
        <div className={tableHeaderClasses}>Location</div>
        <div className={tableHeaderClasses}>Section</div>
        <div className={tableHeaderClasses}>Tag</div>
        <div className={tableHeaderClasses}>Discipline</div>
        <div className="col-span-5 border-t border-solid border-[#eee]"></div>
        <BlueContainer caption="09:00" />
        <GrayContainer caption="Secretary’s office" />
        <GrayContainer year="XXXX" caption="Check-in" />
        <GrayContainer caption="Fixed" />
        <GrayContainer caption="Jumper" />

        <BlueContainer caption="09:00" />
        <GrayContainer caption="Secretary’s office" />
        <GrayContainer year="XXXX" caption="Check-in" />
        <GrayContainer caption="Fixed" />
        <GrayContainer caption="Jumper" />

        <BlueContainer caption="09:00" />
        <GrayContainer caption="Secretary’s office" />
        <GrayContainer year="XXXX" caption="Check-in" />
        <GrayContainer caption="Fixed" />
        <GrayContainer caption="Jumper" />
      </div>

      <h2 className="m-0 text-[22px] text-SeabiscuitDark200ThemeColor mt-8 font-bold">Saturday</h2>
      <div className="grid gap-2 grid-cols-[10%_20%_auto_10%_10%] mt-2">
        <div className={tableHeaderClasses}>Time</div>
        <div className={tableHeaderClasses}>Location</div>
        <div className={tableHeaderClasses}>Section</div>
        <div className={tableHeaderClasses}>Tag</div>
        <div className={tableHeaderClasses}>Discipline</div>
        <div className="col-span-5 border-t border-solid border-[#eee]"></div>
        <BlueContainer caption="09:00" />
        <GrayContainer caption="Secretary’s office" />
        <GrayContainer year="XXXX" caption="Check-in" />
        <GrayContainer caption="Fixed" />
        <GrayContainer caption="Jumper" />

        <BlueContainer caption="09:00" />
        <GrayContainer caption="Secretary’s office" />
        <GrayContainer year="XXXX" caption="Check-in" />
        <GrayContainer caption="Fixed" />
        <GrayContainer caption="Jumper" />

        <BlueContainer caption="09:00" />
        <GrayContainer caption="Secretary’s office" />
        <GrayContainer year="XXXX" caption="Check-in" />
        <GrayContainer caption="Fixed" />
        <GrayContainer caption="Jumper" />
      </div>
    </EventSchedulingFormComponentDrawerComponent>
  )
}

export default EventSchedulingFormComponentScheduleModal
