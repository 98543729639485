export const DATA_FIRESTORE_V01_ASSIGNED_TICKETS = {
  NAME: 'v01_assigned_tickets',

  FIELDS: {
    ID: 'id',

    USER_ID: 'userId',

    EVENT_DOC_ID: 'eventDocId',

    REGISTRATION_DOC_ID: 'registrationDocId',

    PAYMENT_STATUS: 'paymentStatus',

    SEPECTATOR_TICKET_DOC_ID: 'spectatorTicketDocId',

    REGISTRATION_TICKET_DOC_ID: 'registrationTicketDocId',

    CHECK_IN_DATE: 'registrationTicketDocId',

    TICKET_BUYER_NAME: 'ticketBuyerName',

    TICKET_BUYER_EMAIL_ID: 'ticketBuyerEmailId',

    TICKET_ITEM_ID: 'ticketItemId',

    TICKET_HOLDER_NAME: 'ticketHolderName',

    TICKET_HOLDER_EMAIL_ID: 'ticketHolderEmailId',

    TICKET_HOLDER_ID: 'ticketHolderId',

    TICKET_TITLE: 'ticketTitle',

    IS_SCRATCHED: 'isScratched',

    ASSIGNMENT_DATE: 'assignmentDate',

    TICKET_BUYER_DOC_ID: 'ticketBuyerDocId',

    TICKET_STATUS: {
      KEY: 'ticketStatus',
      VALUE: {
        AVAILABLE: 'available',
        USED: 'used',
        ASSIGNED: 'assigned',
      },
    },

    TICKET_TYPE: {
      KEY: 'ticketType',
      VALUE: {
        REGISTERED: 'register',
        STECTATOR: 'spectator',
      },
    },

    TICKET_PRICE: 'ticketPrice',

    CREATED: 'created',

    MODIFIED: 'modified',
  },
}
